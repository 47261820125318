import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';

import { businessesPropTypes } from './propTypes';

const AddEditBusinessForm = ({
  businessGroups,
  customers,

  values,
  isSubmitting,
  onCancelClick,
  submitForm,
  isEditMode,
}) => (
  <form>
    <div>
      <Field
        className="form-text"
        label="Business"
        name="businessId"
        component={Input}
        value={values.businessId}
        disabled={isEditMode}
        maxLength={10}
      />
      <Field
        className="form-text"
        label="Description"
        name="businessDescription"
        component={Input}
        value={values.businessDescription}
        maxLength={100}
      />
      <Select
        wrapperClassName="form-text"
        appkitLabel="Lead"
        name="leadCustomerId"
        options={customers}
        value={values.leadCustomerId}
      />
      <Select
        wrapperClassName="form-text"
        appkitLabel="Business Group"
        name="businessGroup"
        options={businessGroups}
        value={values.businessGroup}
      />
      <Field
        className="form-text"
        label="Tier"
        name="tier"
        component={Input}
        autoComplete="off"
        type="number"
      />
      <Field
        className="form-text"
        label="Parent"
        name="sirRollupBusId"
        component={Input}
        value={values.sirRollupBusId}
        maxLength={10}
      />
      <Field wrapperClassName="form-text" label="SIR" name="sirYN" component={Checkbox} />
    </div>
    <div>
      <ButtonsGroup
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        onCancelClick={onCancelClick}
        submitButtonLabel="Save"
      />
    </div>
  </form>
);

AddEditBusinessForm.propTypes = {
  businessGroups: PropTypes.arrayOf(SelectOptionPropTypes),
  customers: PropTypes.arrayOf(SelectOptionPropTypes),

  values: businessesPropTypes.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
  isEditMode: PropTypes.bool.isRequired,
};

export default AddEditBusinessForm;
