import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';

import { fetchIncomeTaxLiabilityTypes } from './types';

const homePageReducer = reducerFactory({
  initialState: {
    isFetchingIncomeTaxLiability: false,
    incomeTaxLiability: [],
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchIncomeTaxLiabilityTypes,
      payloadKey: 'incomeTaxLiability',
      fetchingKey: 'isFetchingIncomeTaxLiability',
    }),
  },
});

export default homePageReducer;
