import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchTaxFormsTypes,
  addEditTaxFormTypes,
  deleteFormTypes,
  SELECT_FORM_ID,
  CHANGE_IS_ADDING_FORM,
  PULL_GENERATE_PDF_SUCCESS,
} from './types';

export const fetchTaxForms = apiAction({
  types: fetchTaxFormsTypes,
  apiAction: api.fetchTaxForms,
});

export const selectFormId = formId => ({
  type: SELECT_FORM_ID,
  payload: formId,
});

export const changeIsAddingForm = isAddingForm => ({
  type: CHANGE_IS_ADDING_FORM,
  payload: isAddingForm,
});

export const addTaxForm = apiAction(
  {
    types: addEditTaxFormTypes,
    apiAction: api.addTaxForm,
  },
  {
    successNotificationMessage: () => 'Adding tax form succeeded',
    errorNotificationMessage: () => 'Adding tax form failed',
  },
);

export const updateTaxForm = apiAction(
  {
    types: addEditTaxFormTypes,
    apiAction: api.updateTaxForm,
  },
  {
    successNotificationMessage: () => 'Updating tax form succeeded',
    errorNotificationMessage: () => 'Updating tax form failed',
  },
);

export const deleteForm = apiAction({
  types: deleteFormTypes,
  apiAction: api.deleteForm,
});

export const pullGeneratePdfSuccess = jobData => ({
  type: PULL_GENERATE_PDF_SUCCESS,
  payload: jobData,
});
