import { TAX_YEAR_2021 } from '@common-packages/shared-constants';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { dataTypes } from '../../shared/constants';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';

import styles from './editInfoAccountForm.module.scss';
import { editInfoAccountPropTypes } from './propTypes';

const dataTypesOptions = [
  { label: 'Text', value: dataTypes.TEXT },
  { label: 'Number', value: dataTypes.NUMBER },
  { label: 'Date', value: dataTypes.DATE },
  { label: 'Amount', value: dataTypes.AMOUNT },
  { label: 'Checkbox', value: dataTypes.CHECKBOX },
];

const EditInfoAccountForm = ({
  isSubmitting,
  submitForm,
  resetForm,
  values,
  isEditMode,
  dirty,
  taxYear,
}) => {
  const onCancelClick = useCallback(() => {
    resetForm();
  }, [resetForm]);

  return (
    <form className={styles.editInfoAccountForm}>
      <div className={styles.inputsSection}>
        <Field
          className="form-text"
          label="Info account Id"
          name="infoAccountId"
          component={Input}
          autoComplete="off"
          disabled={isEditMode}
        />
        <Field
          className="form-text"
          label="Info account information type"
          name="infoType"
          component={Input}
          autoComplete="off"
        />
        <Field
          className="form-text"
          label="Info account description"
          name="infoAccountDescription"
          component={Input}
          autoComplete="off"
        />
        <Field wrapperClassName="form-text" label="Active" name="activeYn" component={Checkbox} />
        <Field
          wrapperClassName="form-text"
          label="Global Ind"
          name="globalInd"
          component={Checkbox}
        />
        <Field
          wrapperClassName="form-text"
          label="Pship Global Ind"
          name="pshipGlobalInd"
          component={Checkbox}
        />
        {taxYear >= TAX_YEAR_2021 && (
          <div className="font-italic info-message">
            Editing info accounts only supported for globals on this page for 2021 and after.
          </div>
        )}
        <Select
          wrapperClassName="form-text"
          appkitLabel="Data type"
          name="dataType"
          options={dataTypesOptions}
          value={values.dataType}
        />
      </div>
      <div>
        {taxYear >= TAX_YEAR_2021 &&
          values.pshipGlobalInd === false &&
          values.globalInd === false && (
            <div className={`invalid-feedback ${styles.invalidGlobalIndicatorWarning}`}>
              Select either Pship Global Ind or Global Ind
            </div>
          )}
        <ButtonsGroup
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={onCancelClick}
          submitButtonLabel="Save"
          disabled={
            !dirty ||
            (taxYear >= TAX_YEAR_2021 &&
              values.pshipGlobalInd === false &&
              values.globalInd === false)
          }
        />
        {values.pshipGlobalInd}
        {values.globalInd}
      </div>
    </form>
  );
};

EditInfoAccountForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape(editInfoAccountPropTypes),
  isEditMode: PropTypes.bool.isRequired,
  dirty: PropTypes.bool,
  taxYear: PropTypes.string,
};

export default EditInfoAccountForm;
