import React from 'react';

import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';

import styles from './taxRatesAndConstantsMaintenance.module.scss';

interface Errors {
  crossFields: string | undefined;
}
interface TaxRatesAndConstantsFormHeaderProps {
  dirty: boolean;
  isSubmitting: boolean;
  onCancelClick: () => void;
  submitForm: () => void;
  errors?: Errors;
  title: string;
}

const TaxRatesAndConstantsFormHeader = ({
  dirty,
  isSubmitting,
  onCancelClick,
  submitForm,
  errors,
  title,
}: TaxRatesAndConstantsFormHeaderProps) => {
  return (
    <>
      <div className={styles.taxRatesAndConstantsHeaderContainer}>
        <div className={styles.taxRatesAndConstantsHeaderForm}>
          <p className={styles.taxRatesAndConstantsTitle}>{title}</p>
          <div className={styles.taxRatesAndConstantsButtons}>
            {errors?.crossFields && (
              <p className={`${styles.taxRatesAndConstantsErrorMessage} a-form-error`}>
                {errors.crossFields}
              </p>
            )}
            <ButtonsGroup
              className={styles.taxRatesAndConstantsButtonsGroup}
              disabled={!dirty}
              fullWidth={false}
              isSubmitting={isSubmitting}
              onCancelClick={onCancelClick}
              submitButtonLabel="Save"
              submitForm={submitForm}
            />
          </div>
        </div>
        <hr />
      </div>
    </>
  );
};

export default TaxRatesAndConstantsFormHeader;
