import React from 'react';
import PropTypes from 'prop-types';
import { taxTypes } from '@common-packages/routes-definitions';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import SelectContextDataInfo from '../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import globalContextPropTypes from '../../shared/propTypes/globalContext';

import columnDefinitions from './taxRateDetails.columnDefinitions';
import useTaxRateDetails from './useTaxRateDetails.hook';

const TaxRateDetails = ({
  match,

  globalContext,
}) => {
  const { taxName } = match.params;
  const { taxYear, period, businessEntityId, jurisdictionId } = globalContext.params;
  const isContextReady = taxYear && taxName && businessEntityId && jurisdictionId;
  const { taxRateDetails, isFetchingTaxRateDetails } = useTaxRateDetails({
    taxYear,
    period,
    taxType: taxTypes[taxName],
    entityId: businessEntityId,
    jurisdictionId,
    isContextReady,
  });

  const isLoading = isFetchingTaxRateDetails;

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid isGridLoading={isLoading} columnDefs={columnDefinitions} rowData={taxRateDetails} />
      </div>
    </div>
  );
};
TaxRateDetails.propTypes = {
  globalContext: globalContextPropTypes,

  match: PropTypes.shape({
    params: PropTypes.shape({
      taxName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TaxRateDetails;
