const { dataTypes, AliasTypes, TAX_YEAR_2021 } = require('@common-packages/shared-constants');
/* eslint-disable babel/no-invalid-this */
const Yup = require('yup');

const testIfNumericStringIsInteger = value => {
  const isInteger = Number.isInteger(Number(value));
  return isInteger;
};

const testNumericStringMin = minValue => value => {
  const isValid = Number(value) >= minValue;
  return isValid;
};

const insertDataModelSchema = Yup.object().shape({
  name: Yup.string().label('Name').max(80, 'Name cannot exceed 80 characters').required(),
  description: Yup.string()
    .label('Description')
    .max(100, 'Description cannot exceed 100 characters')
    .nullable(),
  contextTypeId: Yup.number().label('Context Type').required(),
  isCommon: Yup.bool().label('Common'),
  shouldDisplayWorkpaperOnNavigator: Yup.bool().label('Display Workpaper on Navigator'),
});

const updateDataModelFieldsSchema = Yup.object().shape({
  description: Yup.string()
    .label('Description')
    .max(100, 'Description cannot exceed 100 characters')
    .nullable(),
  contextTypeId: Yup.number().label('Context Type').required(),
  isCommon: Yup.bool().label('Common'),
  shouldDisplayWorkpaperOnNavigator: Yup.bool().label('Display Workpaper on Navigator'),
  inactive: Yup.bool().label('Inactive'),
});

const datasetCommonSchema = Yup.object().shape({
  description: Yup.string()
    .label('Description')
    .nullable()
    .max(200, 'Description cannot exceed 200 characters'),
  instanceName: Yup.string()
    .label('Instance Name')
    .nullable()
    .max(40, 'Instance Name cannot exceed 40 characters'),
  instanceIdentifier: Yup.string()
    .label('Description')
    .nullable()
    .max(200, 'Instance Description cannot exceed 200 characters'),
  maxInstances: Yup.string()
    .label('Max. Instances')
    .test('oneOf', 'Max. Instances must be an integer number', testIfNumericStringIsInteger)
    .test('oneOf', 'Max. Instances must not be lower that -1', testNumericStringMin(-1)),
  instanceFilteringExpression: Yup.string()
    .nullable()
    .label('Instance Filtering Expressions')
    .max(4000, 'Instance Filtering Expressions cannot exceed 4000 characters'),
});

const getAddEditDatasetSchema = ({ allDatasets = [], isEditMode = false }) =>
  datasetCommonSchema.concat(
    Yup.object().shape({
      name: Yup.string()
        .label('Name')
        .max(80, 'Name cannot exceed 80 characters')
        .uniqueInList({
          message: 'Dataset name already exists, a unique name is required before saving',
          list: allDatasets,
          mapper: ({ name }) => name,
          shouldSkipTest: isEditMode,
        })
        .required(),
    }),
  );

const dataItemsSchema = ({ taxYear }) =>
  Yup.array().of(
    Yup.object().shape({
      name: Yup.string().label('Name').required().max(150, 'Name cannot exceed 150 characters'),
      aliasName: Yup.string()
        .label('Alias Name')
        .nullable()
        .max(100, 'Alias Name cannot exceed 100 characters')
        .matches(/^([A-Za-z0-9-_]*)$/g, "Alias name can only contain alpha, numeral, '-', or '-'")
        .test(
          'descriptionAndAliasTypeRequired',
          'Description and Alias Type are required with a Alias Name',
          (value, context) => {
            return (
              (!value && !context.parent.aliasType) ||
              (value && context.parent.aliasType && context.parent.description)
            );
          },
        )
        .test('aliasNameRequired', 'Alias Name is a required field', (value, context) => {
          return !(
            !value &&
            context.parent.checkboxGroupId &&
            context.parent.description &&
            taxYear > TAX_YEAR_2021
          );
        }),
      description: Yup.string()
        .label('Description')
        .nullable()
        .max(500, 'Description cannot exceed 500 characters')
        .test('descriptionRequired', 'Description is a required field', (value, context) => {
          return !(
            !value &&
            context.parent.checkboxGroupId &&
            context.parent.aliasName &&
            taxYear > TAX_YEAR_2021
          );
        })
        .test(
          'descriptionAndAliasNameRequired',
          'Description and Alias Name are required fields',
          (value, context) => {
            return !(
              !value &&
              context.parent.checkboxGroupId &&
              !context.parent.aliasName &&
              taxYear > TAX_YEAR_2021
            );
          },
        ),
      displayName: Yup.string()
        .label('Display Name')
        .nullable()
        .max(80, 'Display Name cannot exceed 80 characters'),
      dataType: Yup.string().label('Data Type').required(),
      dataLength: Yup.number().label('Data Length').nullable(),
      displayOrder: Yup.number().label('Display Order').required(),
      selectionListId: Yup.string().label('Selection List').nullable(),
    }),
  );

const aliasesSchema = Yup.array().of(
  Yup.object().shape({
    AliasName: Yup.string()
      .label('Alias Name')
      .max(100, 'Alias Name cannot exceed 100 characters')
      .test(
        'descriptionAndAliasTypeRequired',
        'Description and Alias Type are required with a Alias Name',
        (value, context) => {
          return (
            (!value && !context.parent.AliasType) ||
            (value && context.parent.AliasType && context.parent.Description)
          );
        },
      ),
    AliasType: Yup.string()
      .label('Alias Type')
      .oneOf([AliasTypes.Account, AliasTypes.InfoAccount, AliasTypes.Category])
      .required(),
    Description: Yup.string()
      .label('Description')
      .max(200, 'Description cannot exceed 200 characters')
      .required(),
  }),
);

const setOptionalIfEmptyObject = schema =>
  Yup.lazy(value => {
    if (Object.keys(value).length) {
      return schema;
    }
    return Yup.mixed().notRequired();
  });

const updateDataModelSchema = ({ taxYear }) =>
  Yup.object()
    .shape({
      dataModel: setOptionalIfEmptyObject(updateDataModelFieldsSchema),
      dataset: setOptionalIfEmptyObject(datasetCommonSchema),
      dataItems: dataItemsSchema({ taxYear }),
      rows: dataItemsSchema({ taxYear }),
      aliaes: aliasesSchema,
    })
    .test(
      'IsCheckBoxGroupHasAtLeastTwoItems',
      'Checkbox Groups must be associated with at least two dataitems.',
      ({ rows }, { path, createError }) => {
        const checkBoxGroupCount = rows.reduce((count, row) => {
          if (row.dataType === dataTypes.BOOLEAN.toString() && row.checkboxGroupId) {
            if (!count[row.checkboxGroupId]) {
              count[row.checkboxGroupId] = 0;
            }
            count[row.checkboxGroupId] += 1;
          }
          return count;
        }, {});

        if (Object.values(checkBoxGroupCount).some(count => count < 2)) {
          throw createError({ path });
        }

        return true;
      },
    );

const datasetUsageSchema = Yup.object().shape({
  instancesPerPage: Yup.string().label('Instances Per Page').required(),
  instToSkip: Yup.string().label('Instances to Skip').required(),
  overflowAction: Yup.string().label('Overflow Action').required(),
  printOrientationLandscape: Yup.string().label('Orientation').nullable(),
  fontSize: Yup.string().label('Font Size').nullable(),
  pageWidthPercentage: Yup.string().label('Page Width Percentage').nullable(),
  pdfStmtRefFieldName: Yup.string().max(100).label('Stmt. Reference Field').nullable(),
  uiLocationFieldName: Yup.string().max(100).label('Drill UI Field').nullable(),
  calcPreferenceName: Yup.string().max(50).label('Calc Preference Name').nullable(),
});

module.exports = {
  getAddEditDatasetSchema,
  dataItemsSchema,
  updateDataModelSchema,
  insertDataModelSchema,
  updateDataModelFieldsSchema,
  datasetUsageSchema,
  aliasesSchema,
};
