import { ICellRendererParams } from 'ag-grid-community';

import { dueDateFormatter } from '../../formatters/dueDateFormatter';
import { FilingMethods } from '../../enums';

export const dueDateCellRenderer = ({ value, data }: ICellRendererParams) => {
  return !value ||
    data.filingMethod === FilingMethods.NON_FILING ||
    data.taxReturn?.filingMethod === FilingMethods.NON_FILING
    ? ''
    : dueDateFormatter(value);
};
