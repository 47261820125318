import PropTypes from 'prop-types';
import { taxNames } from '@common-packages/routes-definitions';

import globalContextParamsPropTypes from './globalContextParams';

export default PropTypes.shape({
  taxYear: PropTypes.string,
  period: PropTypes.string,
  filingTypeId: PropTypes.string,
  entityId: PropTypes.string,
  consolidationId: PropTypes.string,
  businessEntityId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  taxName: PropTypes.oneOf(Object.values(taxNames)),
  params: globalContextParamsPropTypes,
  getOverridenApiRouteParamString: PropTypes.func.isRequired,
  apiRouteParamString: PropTypes.string.isRequired,
  isConsolidated: PropTypes.bool.isRequired,
  isConsolidatedYN: PropTypes.oneOf(['Y', 'N']),
  isSeparate: PropTypes.bool.isRequired,
  isSeparateYN: PropTypes.oneOf(['Y', 'N']),
  isReady: PropTypes.bool.isRequired,
});
