import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchParamOptionsTypes,
  runTrgReportTypes,
  fetchTrgReportTypes,
  CLEAN_AFTER_TRG_REPORT,
} from './types';

export const initialState = {
  consolidatedReportResults: null,
  consolidatedReportIsRunning: false,
  entityBusiness: null,
  isFetchingTrgReport: false,
  isFetchingTrgReportResult: false,
  trgReportResult: null,
  trgReport: null,
  paramFields: {},
};

const estimateSummaryReportReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchTrgReportTypes,
      payloadKey: 'trgReport',
      fetchingKey: 'isFetchingTrgReport',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: runTrgReportTypes,
      payloadKey: 'trgReportResult',
      fetchingKey: 'isFetchingTrgReportResult',
    }),
    [CLEAN_AFTER_TRG_REPORT]: state => ({
      ...state,
      trgReportResult: null,
    }),
    [fetchParamOptionsTypes.START]: (state, [payload]) => ({
      ...state,
      paramFields: {
        ...state.paramFields,
        [payload.paramName]: {
          isFetching: true,
          options: [],
        },
      },
    }),
    [fetchParamOptionsTypes.FAILURE]: (state, [payload]) => ({
      ...state,
      paramFields: {
        ...state.paramFields,
        [payload.paramName]: {
          isFetching: false,
          options: [],
        },
      },
    }),
    [fetchParamOptionsTypes.SUCCESS]: (state, payload) => ({
      ...state,
      paramFields: {
        ...state.paramFields,
        [payload.paramName]: {
          isFetching: false,
          result: payload.result,
        },
      },
    }),
  },
});

export default estimateSummaryReportReducer;
