import PropTypes from 'prop-types';

export const partnershipAllocationPercentagesPropTypes = PropTypes.shape({
  ownerName: PropTypes.string.isRequired,
  ownershipPercentage: PropTypes.number.isRequired,
  partnerId: PropTypes.string.isRequired,
  percentageType: PropTypes.string.isRequired,
  plugOwner: PropTypes.oneOf(['Y', 'N']).isRequired,
  rowId: PropTypes.number.isRequired,
});

export const formValuesPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  entityName: PropTypes.string,
  legalClassification: PropTypes.string,
  plugOwner: PropTypes.string,
  percentageType: PropTypes.string,
  percentage: PropTypes.string,
  partnerId: PropTypes.string,
});
