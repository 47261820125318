import { getApiActionTypes } from '../../shared/store/storeHelpers';

export const fetchTasksTypes = getApiActionTypes('TASKS/FETCH_TASKS');
export const fetchSourceCustomersTypes = getApiActionTypes('TASKS/FETCH_SOURCE_CUSTOMERS');
export const fetchDestinationCustomersTypes = getApiActionTypes(
  'TASKS/FETCH_DESTINATION_CUSTOMERS',
);
export const fetchTasksForReassignmentTypes = getApiActionTypes(
  'TASKS/FETCH_TASKS_FOR_REASSIGNEMNT',
);
export const updateTasksAssignmentsTypes = getApiActionTypes('TASKS/UPDATE_TASKS_ASSIGNMENTS');
