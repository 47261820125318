import React from 'react';
import { MenuItem, ChosenEvent } from 'react-menu-list';

import styles from './nestedMenuItem.module.scss';

interface NestedMenuItemProps {
  menuItemKey: string;
  handleRedirect: (event: ChosenEvent) => void;
  menuItemHighlightedStyle: string;
  children: React.ReactNode;
  customActions?: {
    path: string;
    customAction: (event: ChosenEvent) => void;
  }[];
  hasBottomSeparator?: boolean;
  route?: string | null;
}

const preventDefaultEvent = (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault();

const NestedMenuItem = ({
  menuItemKey,
  handleRedirect,
  children,
  menuItemHighlightedStyle,
  customActions = [],
  hasBottomSeparator = false,
  route = null,
}: NestedMenuItemProps) => {
  const { customAction } = customActions.find(({ path }) => path === menuItemKey) || {};

  return (
    <>
      <MenuItem
        highlightedStyle={menuItemHighlightedStyle}
        onItemChosen={customAction || handleRedirect}
        className="a-header-wrapper nested-menu"
        data-testid="nested-menu-item"
      >
        {route ? (
          <a href={route} className={styles.plainAnchor} onClick={preventDefaultEvent}>
            {children}
          </a>
        ) : (
          children
        )}
      </MenuItem>
      {hasBottomSeparator && <hr />}
    </>
  );
};

export default NestedMenuItem;
