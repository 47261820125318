import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';
import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../shared/editMode';
import useModal from '../../shared/hooks/useModal.hook';

import { fetchBusinessGroups, updateBusinessGroups, deleteBusinessGroup } from './store/actions';
import {
  businessGroupsSelector,
  isFetchingBusinessGroupsSelector,
  isUpdatingBusinessGroupsSelector,
} from './store/selectors';
import AddBusinessGroupModal from './addBusinessGroupModal.container';
import getColumnDefinitions from './businessGroups.columnDefinitions';
import { BusinessGroupsPropTypes } from './propTypes';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const BusinessGroups = ({
  businessGroups,
  fetchBusinessGroups,
  isFetchingBusinessGroups,
  updateBusinessGroups,
  isUpdatingBusinessGroups,
  deleteBusinessGroup,
  showConfirmModal,
}) => {
  useEffect(() => {
    fetchBusinessGroups();
  }, [fetchBusinessGroups]);

  const openModalForDelete = useCallback(
    ({ businessGroup }) => {
      showConfirmModal({
        title: 'Delete Business User',
        text: 'Are you sure you want to delete this business group?',
        confirmCallback: async () => {
          await deleteBusinessGroup({ businessGroup });
          fetchBusinessGroups();
        },
      });
    },
    [fetchBusinessGroups, showConfirmModal, deleteBusinessGroup],
  );

  const editBusinessGroups = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        businessGroup: oldRow.businessGroup,
        description: newRow.description,
        activeInd: newRow.activeInd,
      }));

      await updateBusinessGroups({ rowsToUpdate });
      fetchBusinessGroups();
    },
    [fetchBusinessGroups, updateBusinessGroups],
  );

  const isLoading = isFetchingBusinessGroups || isUpdatingBusinessGroups;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: editBusinessGroups,
    rowData: businessGroups,
    getUniqueRowId,
    editButtonDisabled: isLoading || !businessGroups.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        onDeleteIconClick: openModalForDelete,
        updateRow,
      }),
    [isInEditMode, openModalForDelete, updateRow],
  );

  const { showModal, modalProps } = useModal();

  const openModalForAdd = useCallback(() => {
    showModal();
  }, [showModal]);

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col add-button-column">
          <div className="col add-button-column">
            <Button size="lg" className="add-button" onClick={openModalForAdd}>
              Add Business Group
            </Button>
          </div>
          <div className="add-button-column">{editModeButtons}</div>
        </div>
      </div>

      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            stopEditingWhenCellsLoseFocus
          />
        </div>
      </div>

      <AddBusinessGroupModal {...modalProps} businessGroups={clonedRowData} />
    </>
  );
};

BusinessGroups.propTypes = {
  businessGroups: PropTypes.arrayOf(BusinessGroupsPropTypes).isRequired,
  isFetchingBusinessGroups: PropTypes.bool,
  isUpdatingBusinessGroups: PropTypes.bool,
  fetchBusinessGroups: PropTypes.func,
  updateBusinessGroups: PropTypes.func,
  deleteBusinessGroup: PropTypes.func,
  showConfirmModal: PropTypes.func,
};

export default connect(
  state => ({
    businessGroups: businessGroupsSelector(state),
    isFetchingBusinessGroups: isFetchingBusinessGroupsSelector(state),
    isUpdatingBusinessGroups: isUpdatingBusinessGroupsSelector(state),
  }),
  {
    fetchBusinessGroups,
    updateBusinessGroups,
    showConfirmModal,
    deleteBusinessGroup,
  },
)(BusinessGroups);
