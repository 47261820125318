import {
  adminColumnDefinition,
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  TextCellEditor,
} from '../../../../shared/columnDefinitions/cellRenderers';

import validateSelectionListItemValue from './validateSelectionListItemValue';
import validateSelectionListItemDescription from './validateSelectionListItemDescription';

const ColumnsFields = {
  ITEM: 'item',
  SORT_ORDER: 'sortOrder',
  DESCRIPTION: 'description',
};

const getColumnDefinitions = ({ onDeleteIconClick, updateRow }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: () => false,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
      rowDrag: true,
      rowDragText: ({ rowNode }) => rowNode.data.item,
      width: 80,
      maxWidth: 80,
      sortable: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Row',
      field: ColumnsFields.SORT_ORDER,
      colId: ColumnsFields.SORT_ORDER,
      width: 60,
      sortable: false,
      onCellValueChanged,
      valueGetter: ({ node: { rowIndex } }) => rowIndex + 1,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: ColumnsFields.DESCRIPTION,
      colId: ColumnsFields.DESCRIPTION,
      flex: 3,
      editable: true,
      cellEditor: TextCellEditor,
      cellEditorParams: {
        inputProps: {
          maxLength: 255,
        },
        validate: value => validateSelectionListItemDescription(value),
      },
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Value',
      field: ColumnsFields.ITEM,
      colId: ColumnsFields.ITEM,
      flex: 2,
      editable: true,
      cellEditor: TextCellEditor,
      cellEditorParams: {
        inputProps: {
          maxLength: 255,
        },
        validate: (value, data, gridApi) =>
          validateSelectionListItemValue(value, data, gridApi.getModel().rowsToDisplay),
      },
      onCellValueChanged,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
