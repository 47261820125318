import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchBusinessGroupsTypes,
  updateBusinessGroupsTypes,
  deleteBusinessGroupsTypes,
  createBusinessGroupsTypes,
} from './types';

export const fetchBusinessGroups = apiAction({
  types: fetchBusinessGroupsTypes,
  apiAction: api.fetchBusinessGroups,
});

export const updateBusinessGroups = apiAction(
  {
    types: updateBusinessGroupsTypes,
    apiAction: api.updateBusinessGroups,
  },
  { successNotificationMessage: () => 'Business Group has been successfully updated' },
);

export const deleteBusinessGroup = apiAction(
  {
    types: deleteBusinessGroupsTypes,
    apiAction: api.deleteBusinessGroup,
  },
  { successNotificationMessage: () => 'Business Group has been successfully deleted' },
);

export const createBusinessGroup = apiAction(
  {
    types: createBusinessGroupsTypes,
    apiAction: api.createBusinessGroup,
  },
  { successNotificationMessage: () => 'Business Group has been successfully Created' },
);
