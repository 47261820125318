import React, { createContext, useContext } from 'react';

import { ReturnDefinitionFilingAttributeOptions } from '../../../../../common/types/apiShapes/taxReturns';

type Option = { label: string; value: string };

interface AddEditReturnFormWrapperContextValues {
  isFilingMethodDisabled: boolean;
  filingMethodInfoMessage?: string;
  filingMethodOptions: Option[];
  filingTypeOptions: ReturnDefinitionFilingAttributeOptions;
  isFilingTypeSelectDisabled: boolean;
  returnTypeOptions: ReturnDefinitionFilingAttributeOptions;
  isReturnTypeSelectDisabled: boolean;
  businessTypeOptions: ReturnDefinitionFilingAttributeOptions;
  isBusinessTypeSelectDisabled: boolean;
  areTaxTypeCheckboxesDisabled: boolean;
}

interface AddEditReturnWrapperContextProviderParams {
  values: AddEditReturnFormWrapperContextValues;
  children: JSX.Element;
}

const AddEditReturnFormWrapperContext = createContext<AddEditReturnFormWrapperContextValues>({
  isFilingMethodDisabled: false,
  filingMethodOptions: [],
  filingTypeOptions: [],
  isFilingTypeSelectDisabled: false,
  returnTypeOptions: [],
  isReturnTypeSelectDisabled: false,
  businessTypeOptions: [],
  isBusinessTypeSelectDisabled: false,
  areTaxTypeCheckboxesDisabled: false,
});

export const AddEditReturnWrapperContextProvider = ({
  values,
  children,
}: AddEditReturnWrapperContextProviderParams) => {
  return (
    <AddEditReturnFormWrapperContext.Provider
      value={{
        ...values,
      }}
    >
      {children}
    </AddEditReturnFormWrapperContext.Provider>
  );
};

export const useAddEditReturnWrapperContext = () => useContext(AddEditReturnFormWrapperContext);
