import React, { Fragment, useCallback } from 'react';
import { Field, FieldConfig, FormikErrors } from 'formik';
import classNames from 'classnames';

import Checkbox from '../checkboxFormik/checkboxFormik.component';
import Radio from '../radioFormik/radioFormik.component';
import styles from '../forms.module.scss';
import { FilingAttributes as CommonFilingAttributes } from '../../../../../common/types';

type CheckboxFieldWithTitleProps = FieldConfig<boolean> & {
  label: string;
  [key: string]: unknown;
};

export const CheckboxFieldWithTitle = (props: CheckboxFieldWithTitleProps) => (
  <div title={props.label}>
    <Field {...props} />
  </div>
);

interface FilingAttribute {
  displayName: string;
  children: {
    displayName: string;
    field: string;
    hide?: boolean;
  }[];
  field?: string;
}

interface FilingAttributesProps {
  filingAttributes: FilingAttribute[];
  exclusiveFilingAttributes?: FilingAttribute[];
  defaultRadioButtonsValue?: string;
  disabledFilingAttributes?: string[];
  isLoading?: boolean;
  compact?: boolean;
  isColumnAligned?: boolean;
  hasHorizontalLayout?: boolean;
  errors?: FormikErrors<CommonFilingAttributes>;
}

const FilingAttributes = ({
  filingAttributes,
  exclusiveFilingAttributes = [],
  defaultRadioButtonsValue,
  disabledFilingAttributes = [],
  isLoading = false,
  compact = false,
  isColumnAligned = false,
  hasHorizontalLayout = false,
  errors = {},
}: FilingAttributesProps) => {
  const renderFilingAttributesGroup = useCallback(
    (filingAttribute: FilingAttribute) =>
      filingAttribute.children.map(item => (
        <CheckboxFieldWithTitle
          key={item.field}
          label={item.displayName}
          name={item.field}
          component={Checkbox}
          disabled={item.hide || isLoading || disabledFilingAttributes.includes(item.field)}
        />
      )),
    [isLoading, disabledFilingAttributes],
  );

  const renderErrorMessage = (filingAttribute: FilingAttribute) => {
    if (filingAttribute?.field && errors[filingAttribute?.field as keyof CommonFilingAttributes])
      return (
        <div
          className={classNames(styles.filingAttributesInvalidFeedback, {
            [styles.vertical]: hasHorizontalLayout,
          })}
        >
          {errors[filingAttribute?.field as keyof CommonFilingAttributes] as string}
        </div>
      );
  };

  if (isColumnAligned) {
    return (
      <div
        className={
          hasHorizontalLayout
            ? styles.verticalFilingAttributesGridWider
            : styles.verticalFilingAttributesGrid
        }
      >
        {filingAttributes.map((filingAttribute, index) => (
          <div
            className={styles[`filingAttributesGroup${index + 1}`]}
            key={filingAttribute.displayName}
          >
            <label className="a-form-label">{filingAttribute.displayName}</label>
            <div className={styles.verticalCheckboxesGroup}>
              {renderFilingAttributesGroup(filingAttribute)}
            </div>
            {renderErrorMessage(filingAttribute)}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.checkboxesGroupWithInlineLabel}>
      {exclusiveFilingAttributes.map(filingAttribute => (
        <Fragment key={filingAttribute.displayName}>
          <label className="a-form-label">{filingAttribute.displayName}</label>
          <div className={classNames(styles.checkboxesGroup, { [styles.compact]: compact })}>
            <Field
              name={filingAttribute.displayName}
              component={Radio}
              options={filingAttribute.children.map(el => ({
                label: el.displayName,
                value: el.field,
                disabled: disabledFilingAttributes.includes(el.field),
              }))}
              disabled={isLoading}
              className={styles.radioButtonsGroup}
              defaultValue={defaultRadioButtonsValue}
            />
          </div>
        </Fragment>
      ))}
      {filingAttributes.map(filingAttribute => (
        <Fragment key={filingAttribute.displayName}>
          <label className="a-form-label">{filingAttribute.displayName}</label>
          <div className={classNames(styles.checkboxesGroup, { [styles.compact]: compact })}>
            {renderFilingAttributesGroup(filingAttribute)}
          </div>
          {renderErrorMessage(filingAttribute)}
        </Fragment>
      ))}
    </div>
  );
};

export default FilingAttributes;
