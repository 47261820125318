import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';

const getColumnDefinitions = ({ isSeparate }) => [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: isSeparate ? 'Entity ID' : 'Consolidation ID',
    field: isSeparate ? 'orgID' : 'consolID',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Tax Year',
    field: 'taxYear',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Period',
    field: 'shortYearID',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction ID',
    field: 'jurisdictionID',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Form Name',
    field: 'formName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Form Description',
    field: 'formDescription',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Field Name',
    field: 'fieldName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Page Number',
    field: 'pageNumber',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'DataItem Name',
    field: 'dataItemName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'DataSet Name',
    field: 'dataSetName',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'DataType Name',
    field: 'dataTypeName',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Override Value',
    field: 'overrideValue',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Calculated Value',
    field: 'systemValueCurrent',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Historical Value',
    field: 'systemValueWhenOverridden',
    hidden: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Is Common DataSet',
    field: 'isCommonDataSet',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Overridden By',
    field: 'overriddenBy',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Overridden Date',
    field: 'overriddenDate',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Updated By',
    field: 'updatedBy',
    hide: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Updated Date',
    field: 'updatedDate',
    hide: true,
  },
];

export default getColumnDefinitions;
