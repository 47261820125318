import React, { useMemo, useCallback, useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  useEditModeButtons,
  useCloneDeep,
  useEditModeNavigationPrompt,
} from '../../shared/editMode';
import useGridApi from '../../shared/hooks/useGridApi.hook';
import useFetch from '../../shared/hooks/useFetch.hook';

import getColumnDefinitions from './permissions.columnDefinitions';
import routesDataTree from './routesTree';
import * as api from './store/api';
import flattenTree from './flattenTree';
import treeAfterPermissionsApplied from './treeAfterPermissionsApplied';

const Permissions = () => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [rowsPairsWithChanges, setRowsPairsWithChanges] = useState([]);
  const { gridApi, onGridReady } = useGridApi();

  const { isFetching: isUpdatingPermissions, fetch: updatePermissions } = useFetch({
    action: api.updatePermissions,
    successNotificationMessage: 'Permissions updated successfully',
  });

  const {
    data: permissions,
    isFetching: isFetchingPermissions,
    fetch: fetchPermissions,
  } = useFetch({
    action: api.fetchPermissions,
  });

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  const rowData = useMemo(
    () =>
      permissions
        ? treeAfterPermissionsApplied({
            tree: flattenTree(routesDataTree),
            permissions,
          }).map(item => ({
            ...item,
            name: item.displayMainNameStatic
              ? item.displayMainNameStatic()
              : item.displayMainName(),
          }))
        : [],
    [permissions],
  );

  const onSave = useCallback(async () => {
    let updatedPermissions = {};

    gridApi.forEachNode(({ data }) => {
      if (!data) {
        return;
      }

      updatedPermissions = permissions.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: {
            read: [
              ...(acc[curr.id] ? acc[curr.id].read : []),
              ...(data[`${curr.id}Read`] ? [data.MAIN] : []),
            ],
            edit: [
              ...(acc[curr.id] ? acc[curr.id].edit : []),
              ...(data[`${curr.id}Edit`] ? [data.MAIN] : []),
            ],
          },
        }),
        updatedPermissions,
      );
    });

    await updatePermissions({ permissions: updatedPermissions });
    fetchPermissions();
    setRowsPairsWithChanges([]);
  }, [updatePermissions, fetchPermissions, setRowsPairsWithChanges, permissions, gridApi]);

  const onCancel = useCallback(() => {
    gridApi.setRowData(cloneDeep(rowData));
    setRowsPairsWithChanges([]);
  }, [setRowsPairsWithChanges, gridApi, rowData]);

  const buttonsDisabled = isFetchingPermissions || isUpdatingPermissions;
  const { editModeButtons } = useEditModeButtons({
    isInEditMode,
    setIsInEditMode,
    cancelButtonDisabled: buttonsDisabled,
    saveButtonDisabled: buttonsDisabled,
    editButtonDisabled: buttonsDisabled,
    onCancel,
    onSave,
  });

  const clonedRowData = useCloneDeep(rowData);

  const updateRow = useCallback(() => {
    setRowsPairsWithChanges([true]);
  }, [setRowsPairsWithChanges]);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        updateRow,
        isInEditMode,
        permissions: permissions || [],
      }),
    [updateRow, isInEditMode, permissions],
  );

  const isDataDirty = Boolean(rowsPairsWithChanges?.length);

  const { navigationPrompt } = useEditModeNavigationPrompt(isDataDirty);

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col add-button-column">
          <div className="add-button-column">{editModeButtons}</div>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            treeData
            withSearchBar
            isGridLoading={isFetchingPermissions}
            onGridReady={onGridReady}
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            autoGroupColumnDef={{
              headerName: 'Name',
              field: 'name',
              minWidth: 300,
              cellRendererParams: { suppressCount: true },
            }}
            groupDefaultExpanded={2}
            getDataPath={({ dataPath }) => dataPath}
          />
        </div>
      </div>
    </>
  );
};

export default Permissions;
