import {
  defaultColumnDefinitionWithFilter,
  mapValueFormatterFactory,
} from '../../shared/columnDefinitions';

const getIndividualsColumnDefinitions = jurisdictionsOptions => {
  const columnDefinitions = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Individual ID',
      field: 'orgId',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Business',
      field: 'businessDescription',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'First Name',
      field: 'firstName',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Middle Initial',
      field: 'middleName',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Last Name',
      field: 'lastName',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name Suffix',
      field: 'suffix',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Member Number',
      field: 'membership',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'State of Residency',
      field: 'partnerStateOfResidency',
      valueFormatter: mapValueFormatterFactory(jurisdictionsOptions),
      filterParams: {
        valueFormatter: mapValueFormatterFactory(jurisdictionsOptions),
      },
    },
  ];

  return columnDefinitions;
};

export default getIndividualsColumnDefinitions;
