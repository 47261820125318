import { HttpMethods } from '../enums';
import { QueryError, QueryKeys } from '../queries';
import { BusinessEntityId, JurisdictionId, TaxYear, Period } from '../../../../common/types';

import { useCustomMutation } from '.';

type MutationDeleteDatasetInstancesBody = {
  datasetDefinitionIds: string[];
  datasetInstanceIds: string[];
};

type MutationDeleteDatasetInstancesParams = {
  taxYear: TaxYear | null;
  period: Period | null;
  businessEntityId: BusinessEntityId | null;
  jurisdictionId: JurisdictionId | null;
} & MutationDeleteDatasetInstancesBody;

export const useMutationDeleteDatasetInstances = () =>
  useCustomMutation<
    null,
    QueryError,
    MutationDeleteDatasetInstancesParams,
    MutationDeleteDatasetInstancesBody
  >({
    handleData: ({
      taxYear,
      period,
      jurisdictionId,
      businessEntityId,
      datasetDefinitionIds,
      datasetInstanceIds,
    }) => ({
      url: `/api/admin/support/instance-data-cleanup/${taxYear}/${period}/${jurisdictionId}/${businessEntityId}`,
      method: HttpMethods.DELETE,
      body: { datasetDefinitionIds, datasetInstanceIds },
    }),
    resetKey: query =>
      query.queryKey?.[0] === QueryKeys.InstanceDataCleanup.DataModels ||
      query.queryKey?.[0] === QueryKeys.InstanceDataCleanup.DatasetDefinitions ||
      query.queryKey?.[0] === QueryKeys.InstanceDataCleanup.DatasetInstances,
    successMessage: 'Deleting dataset instances succeeded',
    errorMessage: 'Deleting dataset instances failed',
  });
