import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import useFetch from '../../shared/hooks/useFetch.hook';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import Loading from '../../shared/displayComponents/loading.component';
import withPartnershipGuard from '../../shared/displayComponents/partnershipGuard/withPartnershipGuardHoc.container';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useRowEditMode } from '../../shared/editMode';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import {
  taxYearSelector,
  periodSelector,
  entityIdSelector,
  jurisdictionIdSelector,
  globalContextSelector,
} from '../../shared/store/selectors';
import globalContextPropTypes from '../../shared/propTypes/globalContext';

import {
  insertInformationData,
  fetchFilterData,
  fetchFormFields,
  updateFormFields,
} from './store/actions';
import {
  formListOptionsSelector,
  isFetchingFilterData,
  partnerListOptionsSelector,
  formFieldsSelector,
  isFetchingFormFieldsSelector,
  isUpdatingFormFieldsSelector,
} from './store/selectors';
import getColumnDefinitions from './k1Information.columnDefinitions';
import styles from './k1Information.module.scss';
import * as api from './store/api';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const K1Information = ({
  taxYear,
  period,
  entityId,
  jurisdictionId,

  globalContext,

  insertInformationData,

  fetchFilterData,
  isFetchingFilterData,
  partnerListOptions,

  fetchFormFields,
  isFetchingFormFields,
  formFields,

  updateFormFields,
  isUpdatingFormFields,

  hasUserPermissionsToEdit,
}) => {
  const [selectedForm, setSelectedForm] = useState();
  const [selectedPartner, setSelectedPartner] = useState();

  const { data: formListOptions, isFetching: isFetchingFormList, fetch: fetchForms } = useFetch({
    action: api.fetchForms,
  });

  useEffect(() => {
    if (globalContext.isReady) {
      fetchForms(globalContext.params);
    }
  }, [fetchForms, globalContext.isReady, globalContext.params]);

  useEffect(() => {
    const onContextChange = async () => {
      await insertInformationData({ taxYear, period, entityId, jurisdictionId });
      fetchFilterData({ taxYear, period, entityId, jurisdictionId });
    };

    if (globalContext.isReady) {
      onContextChange();
    }
  }, [
    globalContext.isReady,
    taxYear,
    period,
    entityId,
    jurisdictionId,
    fetchFilterData,
    insertInformationData,
  ]);

  useEffect(() => {
    if (formListOptions?.length) {
      setSelectedForm(formListOptions[0]);
    }
  }, [setSelectedForm, formListOptions]);

  useEffect(() => {
    if (partnerListOptions && partnerListOptions.length) {
      setSelectedPartner(partnerListOptions[0]);
    }
  }, [setSelectedPartner, partnerListOptions]);

  useEffect(() => {
    if (globalContext.isReady && selectedForm && selectedPartner) {
      const { value: pshipFormId } = selectedForm;
      const { value: partnerId } = selectedPartner;
      fetchFormFields({ taxYear, period, entityId, jurisdictionId, pshipFormId, partnerId });
    }
  }, [
    fetchFormFields,
    selectedForm,
    selectedPartner,
    globalContext.isReady,
    taxYear,
    period,
    entityId,
    jurisdictionId,
  ]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const { value: pshipFormId } = selectedForm;
      const { value: partnerId } = selectedPartner;

      if (!rowsPairsWithChanges.length) {
        return;
      }

      const rows = rowsPairsWithChanges.map(({ newRow }) => newRow);

      await updateFormFields({ rows });
      fetchFormFields({ taxYear, period, entityId, jurisdictionId, pshipFormId, partnerId });
    },
    [
      updateFormFields,
      fetchFormFields,
      selectedForm,
      selectedPartner,
      taxYear,
      period,
      entityId,
      jurisdictionId,
    ],
  );

  const isLoading = isFetchingFilterData || isFetchingFormFields || isUpdatingFormFields;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: formFields,
    getUniqueRowId,
    editButtonDisabled: isLoading || !formFields.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        updateRow,
      }),
    [isInEditMode, updateRow],
  );

  return (
    <>
      {navigationPrompt}
      <div className={styles.flexSpaceBetween}>
        <div className={styles.formListDropdown}>
          <div className={styles.editSpinner}>
            <Loading isLoading={isFetchingFormList}>
              <SDKCustomSelect
                className="sdk-custom-select"
                virtualized
                appkitLabel="Form Name"
                options={formListOptions}
                value={selectedForm && selectedForm.value}
                onChange={useCallback(form => setSelectedForm(form), [setSelectedForm])}
              />
            </Loading>
          </div>
        </div>
        <div className={styles.formListDropdown}>
          <div className={styles.editSpinner}>
            <Loading isLoading={isFetchingFilterData}>
              <SDKCustomSelect
                className="sdk-custom-select"
                virtualized
                appkitLabel="Partner"
                options={partnerListOptions}
                value={selectedPartner && selectedPartner.value}
                onChange={useCallback(partner => setSelectedPartner(partner), [setSelectedPartner])}
              />
            </Loading>
          </div>
        </div>
        {hasUserPermissionsToEdit && <div className="add-button-column">{editModeButtons}</div>}
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            withSearchBar
            stopEditingWhenCellsLoseFocus
          />
        </div>
      </div>
    </>
  );
};

const formFieldsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    taxYear: PropTypes.number,
    period: PropTypes.number,
    infoAcctId: PropTypes.string,
    logicalPageNo: PropTypes.number,
    accountDescription: PropTypes.string,
    entityId: PropTypes.string,
    jurisdictionId: PropTypes.string,
    acctJurisdictionId: PropTypes.string,
    formId: PropTypes.string,
    displayOrder: PropTypes.number,
    dataType: PropTypes.string,
    globalInd: PropTypes.string,
    mapId: PropTypes.number,
    evValue: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    dispValue: PropTypes.string,
  }).isRequired,
).isRequired;

const optionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
);

K1Information.propTypes = {
  globalContext: globalContextPropTypes,
  taxYear: PropTypes.string,
  period: PropTypes.string,

  entityId: PropTypes.string,
  jurisdictionId: PropTypes.string,

  insertInformationData: PropTypes.func.isRequired,

  fetchFilterData: PropTypes.func.isRequired,
  isFetchingFilterData: PropTypes.bool.isRequired,
  formListOptions: optionsPropTypes.isRequired,
  partnerListOptions: optionsPropTypes.isRequired,

  fetchFormFields: PropTypes.func.isRequired,
  isFetchingFormFields: PropTypes.bool.isRequired,
  formFields: formFieldsPropTypes,

  updateFormFields: PropTypes.func.isRequired,
  isUpdatingFormFields: PropTypes.bool.isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default withPartnershipGuard(
  connect(
    state => ({
      globalContext: globalContextSelector(state),
      taxYear: taxYearSelector(state),
      period: periodSelector(state),
      entityId: entityIdSelector(state),
      jurisdictionId: jurisdictionIdSelector(state),

      isFetchingFilterData: isFetchingFilterData(state),
      formListOptions: formListOptionsSelector(state),
      partnerListOptions: partnerListOptionsSelector(state),

      isFetchingFormFields: isFetchingFormFieldsSelector(state),
      formFields: formFieldsSelector(state),

      isUpdatingFormFields: isUpdatingFormFieldsSelector(state),
    }),
    {
      insertInformationData,
      fetchFilterData,
      fetchFormFields,
      updateFormFields,
    },
  )(K1Information),
);
