export const CATEGORY_NAMES = [
  { key: 'PROPERTY', field: 'property', name: 'Property' },
  { key: 'RENT', field: 'rent', name: 'Rent' },
  { key: 'PAYROLL', field: 'payroll', name: 'Payroll' },
  { key: 'SALES', field: 'salesOfServiceDestination', name: 'Sales of service destination' },
  { key: 'SALES_ORIGIN', field: 'salesOfServiceOrigin', name: 'Sales of service origin' },
  { key: 'SALES_TANG', field: 'tangibleSales', name: 'Tangible sales' },
  { key: 'GOVT_COP', field: 'govtSalesCop', name: 'Government Sales COP' },
  { key: 'GOVT_MARKET', field: 'govtSalesMarket', name: 'Government Sales Market' },
];
