import React from 'react';
import { useSelector } from 'react-redux';

import { useQueryRecentEntityInformationRefreshes } from '../../../../../shared/queries/entityInformationDiagnostics';
import { taxYearSelector } from '../../../../../shared/store/selectors';
import AgGrid from '../../../../../shared/displayComponents/agGrid/agGrid.component';

import ColumnDefinitions from './recentRefreshes.columnDefinitions';

const RecentRefreshes = () => {
  const columnDefinitions = ColumnDefinitions;

  const taxYear = useSelector(taxYearSelector);

  const {
    data: recentRefreshes,
    isFetching: isFetchingEntities,
  } = useQueryRecentEntityInformationRefreshes({
    taxYear,
    enabled: Boolean(taxYear),
  });

  return (
    <>
      <AgGrid
        rowData={recentRefreshes}
        columnDefs={columnDefinitions}
        withSearchBar
        autoMaxWidth
        isGridLoading={isFetchingEntities}
      />
    </>
  );
};

export default RecentRefreshes;
