import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationUpdateFilingMembers = () =>
  useCustomMutation({
    handleData: ({ rowsToUpdate, filingGroupId, globalContext }) => ({
      url: `/api/filing-groups/filing-members/${globalContext.apiRouteParamString}/${filingGroupId}`,
      method: HttpMethods.PUT,
      body: {
        payload: rowsToUpdate,
      },
    }),
    resetKey: QueryKeys.FilingGroups.FilingMembers,
    successMessage: 'Filing Members successfully updated',
    errorMessage: 'Updating Filing Members failed',
  });

export const useMutationCreateFilingGroup = ({ onSuccess } = {}) =>
  useCustomMutation({
    handleData: ({
      taxYear,
      period,
      filingGroupId,
      description,
      federalReturnType,
      filingGroupIdOfCopiedFilingGroup,
    }) => ({
      url: `/api/filing-groups/${taxYear}/${period}/${filingGroupId}`,
      method: HttpMethods.PUT,
      body: {
        description,
        filingGroupIdOfCopiedFilingGroup,
        federalReturnType,
      },
    }),
    resetKey: QueryKeys.FilingGroups.FilingGroups,
    successMessage: 'Filing Group successfully added',
    errorMessage: 'Adding Filing Group failed',
    onSuccess,
  });

export const useMutationUpdateFilingGroup = () =>
  useCustomMutation({
    handleData: ({ taxYear, period, filingGroupId, description, federalReturnType }) => ({
      url: `/api/filing-groups/${taxYear}/${period}/${filingGroupId}`,
      method: HttpMethods.PUT,
      body: {
        description,
        federalReturnType,
      },
    }),
    resetKey: QueryKeys.FilingGroups.FilingGroups,
    successMessage: 'Filing Group successfully updated',
    errorMessage: 'Updating Filing Group failed',
  });

export const useMutationDeleteFilingGroup = () =>
  useCustomMutation({
    handleData: ({ taxYear, period, filingGroupId }) => ({
      url: `/api/filing-groups/${taxYear}/${period}/${filingGroupId}`,
      method: HttpMethods.DELETE,
    }),
    resetKey: QueryKeys.FilingGroups.FilingGroups,
    successMessage: 'Filing Group successfully deleted',
    errorMessage: 'Deleting Filing Group failed',
  });
