const Yup = require('yup');

const { alphanumericChar } = require('../validationRegex');

const createOrUpdateFilingGroupSchema = Yup.object().shape({
  taxYear: Yup.string().required().label('Tax Year'),
  period: Yup.string().optional().label('Period'),
  filingGroupId: Yup.string()
    .required()
    .label('Filing Group Id')
    .matches(alphanumericChar.regex, alphanumericChar.message)
    .max(10, 'Max is 10 Characters'),
  description: Yup.string().nullable().max(100, 'Max is 100 Characters'),
});

module.exports = {
  createOrUpdateFilingGroupSchema,
};
