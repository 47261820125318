import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';

import CellTooltip from './cellTooltip.component';
import { handleUpOrDownArrowKeyDown } from './utils';
import styles from './textCellEditor.module.scss';

export default class TextCellEditor extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.any]),
    validate: PropTypes.func,
    data: PropTypes.object.isRequired,
    api: PropTypes.object.isRequired,
    inputProps: PropTypes.object,
    /* eslint-enable */
  };

  static defaultProps = {
    validate: () => null,
  };

  state = {
    value: this.props.value,
  };

  componentDidMount() {
    this.inputRef.current.select();
    this.inputRef.current.focus();
  }

  inputRef = createRef();

  afterGuiAttached = () => {
    this.inputRef.current.focus();
  };

  getValue = () => this.state.value;

  validate = () => {
    const { value } = this.state;
    const { validate, data, api } = this.props;
    return validate(value, data, api);
  };

  isCancelAfterEnd() {
    const isInvalid = Boolean(this.validate());
    return isInvalid;
  }

  handleChange = event => {
    const value = event.target.value;
    this.setState({ value });
  };

  handleKeyDown = event => {
    const { api } = this.props;
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      handleUpOrDownArrowKeyDown(api, event.key);
    }
  };

  render() {
    const { inputProps } = this.props;
    const tooltipMessage = this.validate();

    return (
      <div className={styles.wrapper}>
        <input
          type="text"
          ref={this.inputRef}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          {...inputProps}
        />
        <CellTooltip message={tooltipMessage} />
      </div>
    );
  }
}
