import reducerFactory from '../reducerFactory';

import { fetchCalcStatusTypes, SET_CALC_STATUS } from './types';

export const initialState = {
  calcStatusData: {},
  isFetchingCalcStatus: false,
};

const calcStatusReducer = reducerFactory({
  initialState,
  actionHandlers: {
    [SET_CALC_STATUS]: (state, payload) => ({
      ...state,
      calcStatusData: payload,
    }),

    [fetchCalcStatusTypes.START]: state => ({ ...state, isFetchingCalcStatus: true }),
    [fetchCalcStatusTypes.FAILURE]: state => ({
      ...state,
      isFetchingCalcStatus: false,
      calcStatusData: {},
    }),
    [fetchCalcStatusTypes.SUCCESS]: (state, payload) => ({
      ...state,
      calcStatusData: payload,
      isFetchingCalcStatus: false,
    }),
  },
});

export default calcStatusReducer;
