import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import {
  fetchTasksTypes,
  fetchSourceCustomersTypes,
  fetchDestinationCustomersTypes,
  fetchTasksForReassignmentTypes,
  updateTasksAssignmentsTypes,
} from './types';

export const fetchTasks = apiAction({
  types: fetchTasksTypes,
  apiAction: api.fetchTasks,
});

export const fetchSourceCustomers = apiAction({
  types: fetchSourceCustomersTypes,
  apiAction: api.fetchSourceCustomers,
});

export const fetchDestinationCustomers = apiAction({
  types: fetchDestinationCustomersTypes,
  apiAction: api.fetchDestinationCustomers,
});

export const fetchTasksForReassignment = apiAction({
  types: fetchTasksForReassignmentTypes,
  apiAction: api.fetchTasksForReassignment,
});

export const updateTasksAssignments = apiAction(
  {
    types: updateTasksAssignmentsTypes,
    apiAction: api.updateTasksAssignments,
    throwApiErrors: true,
  },
  {
    successNotificationMessage: () => 'Saving reassignments succeeded.',
    errorNotificationMessage: () => 'Saving reassignments failed.',
  },
);
