import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Modal from '@tls/ui-modal';
import { federalProFormaFilingGroupsSchemas } from '@common-packages/validators';
import { Routes } from '@common-packages/routes-definitions';

import { periodNameSelector, periodSelector, taxYearSelector } from '../shared/store/selectors';
import { useQueryFilingGroups } from '../shared/queries/filingGroups';
import {
  useMutationCreateFilingGroup,
  useMutationUpdateFilingGroup,
} from '../shared/mutations/filingGroups';

import AddEditFilingGroupForm from './addEditFilingGroupForm.component';
import { setFilingGroupId } from './store';

const AddEditFilingGroupModal = ({
  hideModal,
  visible,

  filingGroupToEdit,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const periodName = useSelector(periodNameSelector);

  const filingGroups = useQueryFilingGroups({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });

  const redirectToFilingMembers = useCallback(
    (_, { filingGroupId }) => {
      dispatch(setFilingGroupId({ filingGroupId }));
      history.push({
        pathname: Routes.filingGroupMembers.MAIN,
        state: { fromDashboard: true },
      });
    },
    [dispatch, history],
  );

  const createFilingGroupMutation = useMutationCreateFilingGroup({
    onSuccess: redirectToFilingMembers,
  });
  const updateFilingGroupMutation = useMutationUpdateFilingGroup();

  const formInitialValues = {
    taxYear: taxYear ? taxYear : '',
    periodName: periodName ? periodName : '',
    filingGroupId: '',
    description: '',
    federalReturnType: '1120',
  };

  const saveFilingGroup = useCallback(
    async ({ filingGroupId, description, federalReturnType, filingGroupIdOfCopiedFilingGroup }) => {
      const data = {
        taxYear,
        period,
        filingGroupId,
        description,
        filingGroupIdOfCopiedFilingGroup,
        federalReturnType,
      };
      if (!filingGroupToEdit || filingGroupToEdit?.isCopy) {
        await createFilingGroupMutation.mutateAsync(data);
      } else {
        await updateFilingGroupMutation.mutateAsync(data);
      }
      hideModal();
    },
    [
      createFilingGroupMutation,
      updateFilingGroupMutation,
      hideModal,
      filingGroupToEdit,
      taxYear,
      period,
    ],
  );

  const onClose = useCallback(
    ({ resetForm }) => () => {
      hideModal();
      resetForm();
    },
    [hideModal],
  );

  const renderModal = useCallback(
    formikProps => (
      <Modal
        title={`${
          filingGroupToEdit && filingGroupToEdit?.isCopy !== true ? 'Edit' : 'Add'
        } Filing Group`}
        closeAction={onClose(formikProps)}
        visible={visible}
        submitText={filingGroupToEdit ? 'Save' : 'Add'}
        submitAction={formikProps.submitForm}
        dismissText="Cancel"
        dismissAction={onClose(formikProps)}
      >
        <AddEditFilingGroupForm
          {...formikProps}
          filingGroups={filingGroups.data || []}
          filingGroupToEdit={filingGroupToEdit}
          period={period}
        />
      </Modal>
    ),
    [onClose, filingGroupToEdit, visible, filingGroups, period],
  );
  return (
    <Formik
      initialValues={
        filingGroupToEdit ? { ...filingGroupToEdit, taxYear, periodName } : formInitialValues
      }
      enableReinitialize
      onSubmit={saveFilingGroup}
      validationSchema={federalProFormaFilingGroupsSchemas.createOrUpdateFilingGroupSchema}
    >
      {renderModal}
    </Formik>
  );
};

AddEditFilingGroupModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  filingGroupToEdit: PropTypes.shape({
    filingGroupId: PropTypes.string.isRequired,
    description: PropTypes.string,
    filingGroupIdOfCopiedFilingGroup: PropTypes.string,
    isCopy: PropTypes.bool,
  }),
};
export default AddEditFilingGroupModal;
