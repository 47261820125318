import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';

import { fetchGenericSourceDataTypes } from './types';

export const initialState = {
  genericSourceData: {
    screenTitle: '',
    genericSourceDataColumns: [],
    genericSourceData: [],
  },
  isFetchingGenericSourceData: false,
};

const genericSourceDataReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchGenericSourceDataTypes,
      payloadKey: 'genericSourceData',
      fetchingKey: 'isFetchingGenericSourceData',
    }),
  },
});

export default genericSourceDataReducer;
