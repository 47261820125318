const Yup = require('yup');

const { BUSINESS_TYPES } = require('../sharedConstants/filingAttributes');

const getBusinessTypeSchema = () => {
  return {
    BUSINESS_TYPE_GENERAL: Yup.bool().required(),
    BUSINESS_TYPE_FINANCIAL: Yup.bool().required(),
    BUSINESS_TYPE_INSURANCE: Yup.bool().required(),
    BUSINESS_TYPE_OIL_AND_GAS: Yup.bool().required(),
    BUSINESS_TYPE_REGULATED_EXCHANGE: Yup.bool().required(),
    BUSINESS_TYPE_TRANSPORTATION: Yup.bool().required(),
    BUSINESS_TYPE: Yup.mixed().when(BUSINESS_TYPES, {
      is: (
        BUSINESS_TYPE_GENERAL,
        BUSINESS_TYPE_FINANCIAL,
        BUSINESS_TYPE_INSURANCE,
        BUSINESS_TYPE_OIL_AND_GAS,
        BUSINESS_TYPE_REGULATED_EXCHANGE,
        BUSINESS_TYPE_TRANSPORTATION,
      ) =>
        !(
          BUSINESS_TYPE_GENERAL ||
          BUSINESS_TYPE_FINANCIAL ||
          BUSINESS_TYPE_INSURANCE ||
          BUSINESS_TYPE_OIL_AND_GAS ||
          BUSINESS_TYPE_REGULATED_EXCHANGE ||
          BUSINESS_TYPE_TRANSPORTATION
        ),
      // eslint-disable-next-line no-undefined
      then: Yup.string().notOneOf([null, undefined, ''], 'Must have a Business Type'),
    }),
  };
};

module.exports = { getBusinessTypeSchema };
