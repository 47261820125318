import React from 'react';
import PropTypes from 'prop-types';

import globalContextPropTypes from '../shared/propTypes/globalContext';

import ConsolidationFilingDecisions from './consolidation/filingDecisions.container';
import EntityFilingDecisions from './entity/filingDecisions/filingDecisions.container';

const FilingDecisions = ({ globalContext, hasUserPermissionsToEdit }) =>
  globalContext.isSeparate ? (
    <EntityFilingDecisions hasUserPermissionsToEdit={hasUserPermissionsToEdit} />
  ) : (
    <ConsolidationFilingDecisions hasUserPermissionsToEdit={hasUserPermissionsToEdit} />
  );

FilingDecisions.propTypes = {
  globalContext: globalContextPropTypes,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default FilingDecisions;
