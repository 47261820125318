import React from 'react';

import ErrorMessage from './errorMessage.component';

interface ErrorsListProps {
  errors: string[];
}

const ErrorsList = ({ errors }: ErrorsListProps) =>
  errors.map(error => <ErrorMessage key={error} error={error} />);

export default ErrorsList;
