import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { globalContextSelector } from '../../../shared/store/selectors';
import { useQueryGetFederalReturn } from '../../../shared/queries/eFileV2';
import { GlobalContext, FindFederalReturnResponse } from '../../../../../common/types';
import { FederalReturnAttachments } from '../../../shared/enums';

interface UseGetFederalReturnParams {
  isInitialWizardState?: boolean;
}

/**
 *  isInitialWizardState (defaults to 'true'): Used for when the user first visits the efile wizard
 *
 *  isEnabled (defaults to 'true')
 */

const useGetFederalReturn = ({ isInitialWizardState = false }: UseGetFederalReturnParams = {}) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const { returnId = null } = useParams<{ returnId?: string }>();

  const {
    data: federalReturn = {
      isFileAvailable: null,
      efileReady: null,
      federalReturnAttachment: FederalReturnAttachments.UNLINKED,
      isAsFiledFederalReturnOptionAvailable: false,
      isProformaFederalReturnOptionAvailable: false,
      isPdfFederalReturnOptionAvailable: false,
      isConsolAsFiledFederalReturnOptionAvailable: false,
      isConsolProformaFederalReturnOptionAvailable: false,
      isManualFederalReturnOptionAvailable: false,
      federalSubmissionId: null,
      proformaSubmissionId: null,
      federalReturnOption: null,
      filingFormName: null,
      consolidatedAsFiledSubmissionIdForEntity: null,
      consolidatedProformaSubmissionIdForEntity: null,
      isEntityInConsolidation: false,
      manualFederalProformaSubmissionId: null,
      isFederalXmlRequired: null,
      isFederalPdfRequired: null,
      isFederalAsFiledRequested: null,
      isLockedInd: false,
      isActive: true,
      isValidationReRunRequired: null,
      isPreparerInfoUpToDate: false,
      isXmlFileAvailable: false,
      isZipFileAvailable: false,
      stateEFileXmlFileLocator: null,
      stateEFileZipFileLocator: null,
    } as FindFederalReturnResponse,
    isFetching: isFetchingFederalReturn,
  } = useQueryGetFederalReturn({
    params: {
      globalContextApiRouteParamString: globalContext.apiRouteParamString,
      returnId,
    },
    enabled: Boolean(globalContext.isReady && returnId),
    isInitialWizardState,
  });

  return {
    federalReturn,
    isFetchingFederalReturn,
  };
};

export default useGetFederalReturn;
