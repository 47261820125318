import isEqual from 'lodash.isequal';

import isSameRow from './isSameRow';

const getChangedRows = ({ getUniqueRowId, modifiedRows, originalRows }) => {
  const rowsPairs = modifiedRows.map(row => ({
    oldRow: originalRows?.find(originalRow => isSameRow(originalRow, row, getUniqueRowId)),
    newRow: row,
  }));

  const rowsPairsWithChanges = rowsPairs.filter(({ oldRow, newRow }) => !isEqual(oldRow, newRow));

  return rowsPairsWithChanges;
};

export default getChangedRows;
