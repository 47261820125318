import { httpPost } from '../../../utils/fetchService';

export const logClientError = async ({ error, info }) =>
  httpPost({
    route: '/api/shared/log-client-error',
    errorMessage: 'Logging client error failed',
    body: {
      error,
      info,
    },
  });
