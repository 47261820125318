import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';
import { Period, BusinessEntityId, JurisdictionId, TaxYear } from '../../../../common/types';

import { useCustomMutation } from '.';

interface PopulateDREDataParams {
  taxYear: TaxYear;
  period: Period;
  jurisdictionId: JurisdictionId;
  businessEntityId: BusinessEntityId;
}

export const useMutationPopulateDREData = () =>
  useCustomMutation<null, QueryError, PopulateDREDataParams, null>({
    handleData: ({ taxYear, period, businessEntityId, jurisdictionId }) => ({
      url: `/api/manual-calculations/dre/${taxYear}/${period}/${businessEntityId}/${jurisdictionId}`,
      method: HttpMethods.POST,
    }),
    successMessage: 'Finished populating DRE data',
    errorMessage: 'Populating DRE data fmn ailed',
    resetKey: QueryKeys.ManualCalculations.DREMap,
  });
