import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import useModal from '../../../hooks/useModal.hook';

import ReorderDatasetInstancesModal from './reorderDatasetInstancesModal.container';

const noop = () => null;

const ReorderDatasetInstancesButton = ({
  datasetDefinitionId,
  refetchParentGrid = noop,

  ...buttonProps
}) => {
  const { showModal, modalProps } = useModal();

  return (
    <>
      <Button onClick={showModal} {...buttonProps}>
        Reorder Instances
      </Button>
      <ReorderDatasetInstancesModal
        {...modalProps}
        datasetDefinitionId={datasetDefinitionId}
        refetchParentGrid={refetchParentGrid}
      />
    </>
  );
};

ReorderDatasetInstancesButton.propTypes = {
  datasetDefinitionId: PropTypes.string.isRequired,
  refetchParentGrid: PropTypes.func,
};

export default ReorderDatasetInstancesButton;
