import { Routes } from '@common-packages/routes-definitions';

const data = [
  {
    title: 'Category Detail',
    icon: 'table-data',
    description: 'See category detail',
    route: Routes.categoryDetail.MAIN,
  },
  {
    title: 'Everywhere (EV) Breakdown',
    icon: 'table-data',
    description: '(EV) breakdown',
    route: Routes.everywhereBreakdown.MAIN,
  },
  {
    title: 'Consolidated Everywhere (EV) Breakdown',
    icon: 'table-data',
    description: 'Consolidated (EV) Breakdown',
    route: Routes.consolidatedEverywhereBreakdown.MAIN,
  },
  {
    title: 'Dividend Data',
    icon: 'table-data',
    description: 'See dividend',
    route: Routes.dividendData.MAIN,
  },
  {
    title: 'Override Report',
    icon: 'table-data',
    description: 'Override Report',
    route: Routes.overrideReport.MAIN,
  },
  {
    title: 'Watermark Usage',
    icon: 'table-data',
    description: 'Watermark Usage',
    route: Routes.watermarkUsage.MAIN,
  },
];

export default data;
