import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import customerPropTypes from '../../shared/propTypes/customerPropTypes';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import TextArea from '../../shared/forms/textareaFormik/textareaFormik.component';
import CheckBox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';

import { jurisdictionsPropTypes, statusesPropTypes, tasksPropTypes } from './propTypes';

const AddEditNoteFormComponent = ({
  closeSlideIn,
  jurisdictions,
  tasks,
  statuses,
  setFieldValue,
  values,
  submitForm,
  isSubmitting,
  isEditMode,
  customerData,
}) => {
  useEffect(() => {
    if (values.response && !values.respondedBy) {
      setFieldValue('respondedBy', `${customerData.firstName} ${customerData.lastName}`);
    }
    if (!values.response) {
      setFieldValue('respondedBy', '');
    }
  }, [setFieldValue, values, customerData]);
  return (
    <form>
      <Field
        label="Prepared by"
        name="preparedBy"
        className="slide-in-field"
        disabled
        component={Input}
        autoComplete="off"
      />
      <Field
        className="slide-in-field"
        label="Description"
        name="description"
        component={TextArea}
      />
      <div className="slide-in-field">
        <Select
          appkitLabel="Jurisdiction (Optional)"
          name="jurisdictionId"
          options={jurisdictions.map(({ jurisdictionId, noteJurisdiction }) => ({
            value: jurisdictionId,
            label: noteJurisdiction,
          }))}
          value={values.jurisdictionId}
        />
      </div>
      <div className="slide-in-field">
        <Select
          appkitLabel="Task (Optional)"
          name="taskId"
          options={tasks.map(({ noteTask, taskId }) => ({ value: taskId, label: noteTask }))}
          value={values.taskId}
        />
      </div>
      <Field
        className="slide-in-field"
        label="Rollover to next year"
        name="rolloverToNextYear"
        component={CheckBox}
      />
      <Field className="slide-in-field" label="Note" name="note" component={TextArea} />
      <Field className="slide-in-field" label="Response" name="response" component={TextArea} />
      <Field
        label="Responded by"
        name="respondedBy"
        className="slide-in-field"
        component={Input}
        autoComplete="off"
        disabled
      />
      <div className="slide-in-field">
        <Select
          appkitLabel="Status"
          name="status"
          options={statuses.map(({ noteStatus, noteStatusType }) => ({
            value: noteStatus,
            label: noteStatusType,
          }))}
          value={values.status}
        />
      </div>
      <ButtonsGroup
        isSubmitting={isSubmitting}
        onCancelClick={closeSlideIn}
        submitButtonLabel={isEditMode ? 'Save' : 'Add'}
        submitForm={submitForm}
      />
    </form>
  );
};

AddEditNoteFormComponent.propTypes = {
  closeSlideIn: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    preparedBy: PropTypes.string.isRequired,
    status: PropTypes.string,
    response: PropTypes.string,
    respondedBy: PropTypes.string,
    jurisdictionId: PropTypes.string,
    taskId: PropTypes.string,
  }),
  jurisdictions: jurisdictionsPropTypes.isRequired,
  statuses: statusesPropTypes.isRequired,
  tasks: tasksPropTypes.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  customerData: customerPropTypes,
};

export default AddEditNoteFormComponent;
