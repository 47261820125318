import {
  TaxYear,
  Period,
  JurisdictionId,
  ConsolidationId,
  FindConsolidatedMemberReturnsResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

interface FindConsolidatedMemberReturnsParams {
  taxYear: TaxYear;
  period: Period;
  jurisdictionId: JurisdictionId;
  consolidationId: ConsolidationId | null | undefined;
}

export const useQueryConsolidatedMemberReturns = ({
  params,
  enabled,
}: {
  params: FindConsolidatedMemberReturnsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindConsolidatedMemberReturnsResponse>([QueryKeys.MemberReturns.Data, params], {
    url: `/api/member-returns/consolidations/${params.taxYear}/${params.period}/${params.jurisdictionId}/${params.consolidationId}`,
    enabled,
    errorMessage: 'Error fetching consolidations tax returns',
  });
