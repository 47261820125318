import PropTypes from 'prop-types';

export const contextDebugInfoPropTypes = PropTypes.shape({
  stepName: PropTypes.string,
  globalContext: PropTypes.shape({
    taxYear: PropTypes.string,
    period: PropTypes.string,
    filingTypeId: PropTypes.string,
    businessEntityId: PropTypes.string,
  }),
  clientId: PropTypes.string,
  submissionId: PropTypes.string,
  caseId: PropTypes.string,
  yearId: PropTypes.string,
  binderId: PropTypes.number,
});

export const stepDataPropTypes = PropTypes.shape({
  status: PropTypes.string,
  stepName: PropTypes.string,
  efSubmissionId: PropTypes.string,
  ttiCaseId: PropTypes.number,
  ttiYearId: PropTypes.number,
  binderId: PropTypes.number,
  createdOn: PropTypes.string,
  updatedOn: PropTypes.string,
});

export const overallStatusPropTypes = PropTypes.shape({
  description: PropTypes.string,
  name: PropTypes.string,
});
