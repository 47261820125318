import { getApiActionTypes } from '../../shared/store/storeHelpers';

export const fetchNonFilingStatesTypes = getApiActionTypes('FETCH_NON_FILING_STATES');
export const ENTITIES_NON_FILING_STATES_SELECT_JURISDICTION =
  'ENTITIES_NON_FILING_STATES_SELECT_JURISDICTION';
export const checkIfNotesExistTypes = getApiActionTypes('CHECK_IF_NOTES_EXIST');
export const checkIfPartnershipInformationExistTypes = getApiActionTypes(
  'CHECK_IF_PARTNERSHIP_INFORMATION_EXIST',
);
export const getFederalDetailsLinkTypes = getApiActionTypes('GET_FEDERAL_DETAILS_LINK');
export const checkIsPartnershipTypeTypes = getApiActionTypes('CHECK_IS_PARTNERSHIP_TYPE');
export const fetchEntitiesDataTypes = getApiActionTypes('FETCH_ENTITIES_DATA_TYPE');
export const checkIfLegalEntityExistTypes = getApiActionTypes('CHECK_IF_LEGAL_ENTITY_EXIST');
