import { combineReducers } from 'redux';

import genericScreenReducer from './genericScreen/reducer';
import genericSourceDataReducer from './genericSourceData/reducer';
import genericCategoryReducer from './genericCategory/reducer';
import accountDetailsReducer from './accountDetails/reducer';
import accountLineItemDetailReducer from './accountLineItemDetail/reducer';
import stateSpecificInformationReducer from './stateSpecificInformation/reducer';
import formsReducer from './viewTaxReturn/reducer';
import sharedReducer from './shared.reducer';
import dataModelsReducer from './dataModels/reducer';
import viewPrintAttachmentDetailsReducer from './viewPrintAttachmentDetails/reducer';
import allReturnDataReducer from './allReturnData/reducer';
import pdfAttachmentsReducer from './pdfAttachments/reducer';
import calcStatusReducer from './calcStatus/reducer';
import calculationConstantsReducer from './calculationConstants/reducer';

export default combineReducers({
  root: sharedReducer,
  forms: formsReducer,
  genericScreen: genericScreenReducer,
  genericSourceData: genericSourceDataReducer,
  genericCategory: genericCategoryReducer,
  accountDetails: accountDetailsReducer,
  accountLineItemDetail: accountLineItemDetailReducer,
  stateSpecificInformation: stateSpecificInformationReducer,
  dataModels: dataModelsReducer,
  viewPrintAttachmentDetails: viewPrintAttachmentDetailsReducer,
  allReturnData: allReturnDataReducer,
  pdfAttachments: pdfAttachmentsReducer,
  calcStatus: calcStatusReducer,
  calculationConstants: calculationConstantsReducer,
});
