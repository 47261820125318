import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@pwc/appkit-react';

import styles from './textTooltip.module.scss';

const ParagraphTooltipRender = ({ value, containerid = '', placement = 'top' }) => {
  const displayText = Array.isArray(value) ? value.join(', ') : value;

  return (
    Boolean(displayText) && (
      <Tooltip content={<div>{displayText}</div>} placement={placement} containerid={containerid}>
        <p className={styles.pText}>{displayText}</p>
      </Tooltip>
    )
  );
};

ParagraphTooltipRender.propTypes = {
  value: PropTypes.string,
  placement: PropTypes.string,
  containerid: PropTypes.string,
};

export default ParagraphTooltipRender;
