import { httpGetAndParse } from '../../utils/fetchService';
import stringify from '../../utils/stringify';

export const fetchJobs = () =>
  httpGetAndParse({
    route: `/api/jim/jobs`,
  });

export const fetchJob = async ({ jobType, jobId, errorMessage, shouldCreateNotification }) =>
  httpGetAndParse({
    route: `/api/jim/job/${jobType}/${jobId}?${stringify({
      shouldCreateNotification,
    })}`,
    errorMessage,
  });
