import React from 'react';
import { Field, FieldProps } from 'formik';
import { Tooltip } from '@pwc/appkit-react';

import OverridableFormInputWrapper from '../overridableFormInputs/overridableFormInputWrapper.container';
import { OverridableField } from '../types';
import { CHECKBOX_CHECKED, CHECKBOX_UNCHECKED } from '../../constants';
import styles from '../overridableForm.module.scss';

export interface OnGroupValueChangeFunc {
  (
    {
      newValue,
      additionalPropsToChange,
      valueKey,
    }: {
      newValue: typeof CHECKBOX_CHECKED | typeof CHECKBOX_UNCHECKED;
      additionalPropsToChange: Record<string, unknown>;
      valueKey?: keyof OverridableField;
    },
    triggerBlockId: string,
  ): void;
}

export const getMapFunction = ({
  isUpdatingFormValues,
  setFocusedElementName,
  onGroupValueChange,
  isGroupSuppressed = false,
}: {
  isUpdatingFormValues: boolean;
  setFocusedElementName: React.Dispatch<React.SetStateAction<null | string>>;
  onGroupValueChange: OnGroupValueChangeFunc;
  isGroupSuppressed?: boolean;
}) =>
  function Component(input: OverridableField) {
    return (
      <>
        {isGroupSuppressed || (input.printSuppress && !input.isOverridden) ? (
          <div className={styles.suppressTooltip} style={input.styles}>
            <Tooltip
              tooltipTheme="light"
              content="You cannot override this value because the field is not applicable in this situation"
              placement="top"
            >
              <div style={{ visibility: 'hidden' }}>Print Suppressed</div>
            </Tooltip>
          </div>
        ) : (
          <Field key={input.blockId} name={`["${input.blockId}"]`}>
            {({ field, form }: FieldProps<OverridableField>) => (
              <OverridableFormInputWrapper
                field={field}
                form={form}
                wrapperStyles={input.styles}
                isUpdatingFormValues={isUpdatingFormValues}
                setFocusedElementName={setFocusedElementName}
                onGroupValueChange={onGroupValueChange}
              />
            )}
          </Field>
        )}
      </>
    );
  };
