import { createSelector } from 'reselect';
import { TOTAL_DESC } from '@common-packages/shared-constants';

export const sltAmountDetailsSelector = ({ entity }) =>
  entity.federalReconciliations.sltAmountDetails.sltAmountDetails;

export const isFetchingSltAmountDetailsSelector = ({ entity }) =>
  entity.federalReconciliations.sltAmountDetails.isFetchingSltAmountDetails;

export const sltAmountDetailsWithTotalSelector = createSelector(
  [sltAmountDetailsSelector],
  sltAmountDetails =>
    sltAmountDetails.length
      ? [
          ...sltAmountDetails,
          {
            componentId: TOTAL_DESC,
            isTotalRow: true,
            sltTaxAmount: sltAmountDetails.reduce((acc, curr) => acc + curr.sltTaxAmount, 0),
          },
        ]
      : [],
);
