import { NumeralFormats, numeralFormatter } from '../../formatters';
import formInputThousandsSeparatorFormatter from '../../formatters/formInputThousandsSeparatorFormatter';

import inputFormikFactory from './inputFormikFactory';

export default inputFormikFactory({
  displayFormatter: value =>
    numeralFormatter({
      value: formInputThousandsSeparatorFormatter(value, true),
      format: NumeralFormats.NUMBER_5_OPTIONAL_DECIMALS,
    }),
  valueFormatter: value => formInputThousandsSeparatorFormatter(value, false),
});
