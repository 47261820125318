import React, { useMemo, useCallback, useState, Dispatch, SetStateAction } from 'react';
import { useFormikContext, Field } from 'formik';
import { Button } from '@pwc/appkit-react';
import { useDispatch, useSelector } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import { useRowEditMode } from '../../shared/editMode';
import useModal from '../../shared/hooks/useModal.hook';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import {
  useQuerySelectionListsByTaxYear,
  useQuerySelectionListDefaultValues,
} from '../../shared/queries/selectionLists';
import { taxYearSelector } from '../store/selectors';
import { customerPermissionsSelector } from '../../shared/store/selectors';
import {
  useMutationUpdateCalcPreference,
  useMutationAddCalcPreference,
} from '../../shared/mutations/calcPreferences';
import { EVERYWHERE_JURISDICTION_ID } from '../../shared/constants';
import { CalcPreferenceByJurisdictions, CalcPreference } from '../../../../common/types';

import AddJurisdictionsModal from './addJurisdictionsModal/addJurisdictionsModal.container';
import getDetailedColumnDefinitions from './detailedCalcOptions.columnDefinitions';
import styles from './calcPreferences.module.scss';
import { calcPreferencesDataTypesOptions, CalcPreferencesDataTypes } from './constants';
import hasPermissionToDeleteCalcPreferenceJurisdiction from './hasPermissionToDeleteCalcPreferenceJurisdiction';

type EditCalcPreferencesParams = {
  setSelectedCalcPreference: Dispatch<SetStateAction<CalcPreferenceByJurisdictions | null>>;
  selectedCalcPreference: CalcPreferenceByJurisdictions | null;
  isEditing: boolean;
};

const getRowNodeId = ({ data }: { data: { jurisdictionId: string } }) => data.jurisdictionId;

const EditCalcPreferences = ({
  setSelectedCalcPreference,
  selectedCalcPreference,
  isEditing,
}: EditCalcPreferencesParams) => {
  const dispatch = useDispatch();
  const [isGridDataValid, setIsGridDataValid] = useState(true);
  const { mutateAsync: updateCalcPreference } = useMutationUpdateCalcPreference();
  const { mutateAsync: addCalcPreference } = useMutationAddCalcPreference();
  const {
    setSubmitting,
    isSubmitting,
    dirty: isFormDirty,
    isValid,
    values,
    resetForm,
  } = useFormikContext<CalcPreference>();
  const customerPermissions = useSelector(customerPermissionsSelector);
  const taxYear = useSelector(taxYearSelector);

  const hasPermissionToDelete = hasPermissionToDeleteCalcPreferenceJurisdiction(
    customerPermissions,
  );

  const {
    data: selectionListsByTaxYear,
    isLoading: isFetchingSelectionLists,
  } = useQuerySelectionListsByTaxYear({
    params: { taxYear },
    enabled: Boolean(taxYear),
  });

  const {
    data: selectionListDefaultValues,
    isLoading: isFetchingSelectionListDefaultValues,
  } = useQuerySelectionListDefaultValues({
    params: { taxYear },
    enabled: Boolean(taxYear),
  });

  const isGridLoading = isFetchingSelectionListDefaultValues || isFetchingSelectionLists;

  const selectionListsByTaxYearMapped = useMemo(
    () =>
      selectionListsByTaxYear?.map(listByTaxYear => ({
        ...listByTaxYear,
        selectionLists: listByTaxYear.selectionLists.map(({ value, label, jurisdictionId }) => ({
          value,
          label: jurisdictionId === EVERYWHERE_JURISDICTION_ID ? `EV - ${label}` : label,
        })),
      })) || [],
    [selectionListsByTaxYear],
  );

  const rowData = useMemo(() => selectedCalcPreference?.jurisdictions || [], [
    selectedCalcPreference,
  ]);

  const {
    onGridReady,
    clonedRowData,
    addRow,
    deleteRow,
    updateRow,
    isDirty: isGridDirty,
    findChanges,
    revertChanges: resetGrid,
    gridApi,
  } = useRowEditMode({
    rowData,
    getUniqueRowId: getRowNodeId,
    saveButtonDisabled: false,
  });

  const openModalForDelete = useCallback(
    (row: { jurisdictionDescription: string; name: string }) => {
      dispatch(
        showConfirmModal({
          title: 'Delete Calc Preference Jurisdiction?',
          text: `Jurisdictions for Calc Preference should not be deleted if used in expressions. Are you sure ${row.jurisdictionDescription} for ${row.name} should be deleted?`,
          confirmCallback: () => {
            deleteRow(row);
          },
        }),
      );
    },
    [deleteRow, dispatch],
  );

  const {
    showModal: showAddJurisdictionsModal,
    modalProps: addJurisdictionsModalProps,
  } = useModal();

  const detailedColumnDefinitions = useMemo(
    () =>
      getDetailedColumnDefinitions({
        onDeleteIconClick: openModalForDelete,
        updateRow,
        hasPermissionToDelete,
        selectionListsByTaxYear: selectionListsByTaxYearMapped,
        isFetchingSelectionLists,
        selectionListDefaultValues,
        isFetchingSelectionListDefaultValues,
        dataType: values.dataType,
      }),
    [
      openModalForDelete,
      updateRow,
      hasPermissionToDelete,
      selectionListsByTaxYearMapped,
      isFetchingSelectionLists,
      selectionListDefaultValues,
      isFetchingSelectionListDefaultValues,
      values.dataType,
    ],
  );

  const onAddJurisdictionsClick = useCallback(() => {
    showAddJurisdictionsModal(selectedCalcPreference);
  }, [showAddJurisdictionsModal, selectedCalcPreference]);

  const onCancelFormClick = useCallback(() => {
    resetForm();
    resetGrid();
  }, [resetForm, resetGrid]);

  const onSubmitFormClick = useCallback(async () => {
    setSubmitting(true);
    const { rowsPairsWithChanges, rowsToAdd, rowsToDelete } = findChanges();
    if (isEditing) {
      await updateCalcPreference({
        values,
        rowsPairsWithChanges,
        rowsToAdd,
        rowsToDelete,
      });
    } else {
      await addCalcPreference({ values, rowsToAdd });
    }
    resetForm();
    resetGrid();
    setSelectedCalcPreference(null);
    setSubmitting(false);
  }, [
    setSubmitting,
    findChanges,
    updateCalcPreference,
    addCalcPreference,
    setSelectedCalcPreference,
    resetGrid,
    resetForm,
    isEditing,
    values,
  ]);

  const hasChanges = isGridDirty || isFormDirty;

  const handleRowDataUpdated = useCallback(() => {
    let isValid = true;
    gridApi?.forEachNode(({ data }) => {
      if (
        (values?.dataType === CalcPreferencesDataTypes.SELECTION_LIST &&
          (!data.selectionListId || !data.defaultValue)) ||
        (values?.dataType === CalcPreferencesDataTypes.BOOLEAN && !data.defaultValue)
      ) {
        isValid = false;
      }
    });
    setIsGridDataValid(isValid);
  }, [setIsGridDataValid, values?.dataType, gridApi]);

  const onSelectDataType = useCallback(
    (_: string, dataType: string | { label: string; value: string | undefined } | undefined) => {
      if (dataType === selectedCalcPreference?.dataType) {
        resetGrid();
      } else {
        gridApi?.forEachNode(({ data }) => {
          data.defaultValue = null;
          data.selectionListId = null;
          updateRow(data);
          gridApi.refreshCells({ force: true });
        });
      }
      handleRowDataUpdated();
    },
    [handleRowDataUpdated, resetGrid, selectedCalcPreference?.dataType, updateRow, gridApi],
  );

  return (
    <>
      <div className={styles.detailsRow}>
        <Field
          className={`form-text ${styles.detailsInput}`}
          label="Name"
          name="name"
          component={Input}
          autoComplete="off"
          disabled={isEditing}
        />
        <Field
          className={`form-text ${styles.detailsInput}`}
          label="Description"
          name="description"
          component={Input}
          autoComplete="off"
        />
      </div>
      <Select
        appkitLabel="Data Type"
        name="dataType"
        options={calcPreferencesDataTypesOptions}
        value={values.dataType}
        customChangeHandler={onSelectDataType}
      />
      <Button
        size="lg"
        className={`add-button ${styles.addJurisdictionButton}`}
        onClick={onAddJurisdictionsClick}
        disabled={!values.dataType}
      >
        Add Jurisdiction
      </Button>
      <div className={styles.detailsGrid}>
        <AgGrid
          isGridLoading={isGridLoading}
          rowData={clonedRowData}
          columnDefs={detailedColumnDefinitions}
          areHeaderCellBordersEnabled
          autoMaxWidth
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onGridReady={onGridReady}
          onRowDataUpdated={handleRowDataUpdated}
        />
      </div>
      <div className={styles.buttonsGroup}>
        <Button
          size="lg"
          onClick={onSubmitFormClick}
          disabled={isSubmitting || !hasChanges || !isValid || !isGridDataValid}
        >
          Save
        </Button>
        <Button size="lg" onClick={onCancelFormClick} gray disabled={!hasChanges}>
          Cancel
        </Button>
      </div>
      {addJurisdictionsModalProps.visible && (
        <AddJurisdictionsModal {...addJurisdictionsModalProps} addJusrisdictionRow={addRow} />
      )}
    </>
  );
};

export default EditCalcPreferences;
