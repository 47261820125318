import { defaultColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'orgId',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
    width: 350,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'FEIN',
    field: 'fein',
    width: 90,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Date of Acquisition',
    field: 'dateAcquired',
    width: 90,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Date of Formation',
    field: 'dateFormed',
    width: 90,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Company Type',
    field: 'companyType',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Federal Filing Type',
    field: 'filingType',
    width: 300,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Nexus',
    field: 'nexusInd',
    width: 50,
  },
];

export default columnDefinitions;
