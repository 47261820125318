import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { taxYearSelector, periodSelector } from '../../shared/store/selectors';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import styles from '../../shared/reports/reportParam.module.scss';

import { fetchColumnHeaders } from './store/actions';
import { columnHeadersSelector } from './store/selectors';
import getColumnDefinitions from './estimateSummary.columnDefinitions';

const EstimateSummary = ({
  columnHeaders,
  contextTaxYear,
  contextPeriod,

  fetchColumnHeaders,
}) => {
  const endpointBasePath = '/api/reports/estimate-summary';

  const taxYearParam = useReportParam({
    endpointBasePath,
    paramName: 'taxYear',
    params: useMemo(() => ({}), []),
    defaultParamValue: contextTaxYear,
  });

  const periodsParam = useReportParam({
    endpointBasePath,
    paramName: 'periods',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
      }),
      [taxYearParam.paramValue],
    ),
    defaultParamValue: contextPeriod,
  });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        periods: periodsParam.paramValue,
      }),
      [taxYearParam.paramValue, periodsParam.paramValue],
    ),
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([taxYearParam, periodsParam, entityIdParam]);
  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        periods: periodsParam.paramValue,
        orgId: entityIdParam.paramValue,
      }),
      [taxYearParam.paramValue, periodsParam.paramValue, entityIdParam.paramValue],
    ),
    fetchOnMount: true,
  });

  useEffect(() => {
    fetchColumnHeaders();
  }, [fetchColumnHeaders]);

  const { props, onGridReady } = useActionMenuForExport({ reportData });

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamMultiSelect label="Periods" param={periodsParam} />
          <ReportParamDropdown label="Entity" param={entityIdParam} autoSelectFirstValue />
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={getColumnDefinitions(columnHeaders)}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
        </div>
      </div>
    </>
  );
};

EstimateSummary.propTypes = {
  columnHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      lookupCode: PropTypes.string.isRequired,
      lookupDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  contextTaxYear: PropTypes.string.isRequired,
  contextPeriod: PropTypes.string.isRequired,
  fetchColumnHeaders: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    columnHeaders: columnHeadersSelector(state),
    contextTaxYear: taxYearSelector(state),
    contextPeriod: periodSelector(state),
  }),
  {
    fetchColumnHeaders,
  },
)(EstimateSummary);
