import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import {
  fetchNonFilingStatesTypes,
  ENTITIES_NON_FILING_STATES_SELECT_JURISDICTION,
  checkIfNotesExistTypes,
  checkIfPartnershipInformationExistTypes,
  getFederalDetailsLinkTypes,
  checkIsPartnershipTypeTypes,
  fetchEntitiesDataTypes,
  checkIfLegalEntityExistTypes,
} from './types';

export const fetchNonFilingStates = apiAction({
  types: fetchNonFilingStatesTypes,
  apiAction: api.fetchNonFilingStates,
});

export const selectNonFilingStatesJurisdiction = jurisdictionId => ({
  type: ENTITIES_NON_FILING_STATES_SELECT_JURISDICTION,
  payload: jurisdictionId,
});

export const checkIfNotesExist = apiAction({
  types: checkIfNotesExistTypes,
  apiAction: api.checkIfNotesExist,
});

export const checkIfPartnershipInformationExist = apiAction({
  types: checkIfPartnershipInformationExistTypes,
  apiAction: api.checkIfPartnershipInformationExist,
});

export const getFederalDetailsLink = apiAction({
  types: getFederalDetailsLinkTypes,
  apiAction: api.getFederalDetailsLink,
});

export const checkIsPartnershipType = apiAction({
  types: checkIsPartnershipTypeTypes,
  apiAction: api.checkIsPartnershipType,
});

export const fetchEntitiesData = apiAction({
  types: fetchEntitiesDataTypes,
  apiAction: api.fetchEntitiesData,
});

export const checkIfLegalEntityExist = apiAction({
  types: checkIfLegalEntityExistTypes,
  apiAction: api.checkIfLegalEntityExist,
});
