import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import { BreadcrumbRoute } from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const ProformaBreadcrumbRoutes = () => (
  <>
    <BreadcrumbRoute path={Routes.proformaProcess} />
  </>
);

export default ProformaBreadcrumbRoutes;
