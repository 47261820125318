import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedRoute from '../../shared/authorization/authorizedRoute';
import ViewPrintTaxReturn from '../../viewPrintTaxReturn/viewPrintTaxReturn.container';
import ViewPrintAttachmentDetails from '../../viewPrintAttachmentDetails/viewPrintAttachmentDetails.container';
import ViewFormWorkpaper from '../../viewFormWorkpaper/viewFormWorkpaper.container';
import WorkpaperInstance from '../../workpaperInstance/workpaperInstance.container';

import { SUBPATH_TO_NAMESPACE } from './constants';
import ReturnWorkspaceDashboard from './returnWorkspaceDashboard.container';
import TaxSummaries from './taxSummaries/taxSummaries.container';
import GenericCategory from './genericCategory/genericCategory.container';
import TaxRateDetails from './taxRateDetails/taxRateDetails.container';
import ApportionmentRateDetails from './apportionmentRateDetails/apportionmentRateDetails.container';
import PropertySummary from './propertySummary/propertySummary.container';
import AllReturnData from './allReturnData/allReturnData.component';

const ReturnWorkspaceRoutes = ({
  match: { path },
  orgId,
  profileId,
  jurisdictionId,
  isFetchingContext,
}) => {
  const namespace = SUBPATH_TO_NAMESPACE[path];

  const ConnectedReturnWorkspaceDashboard = useCallback(
    props => (
      <ReturnWorkspaceDashboard
        orgId={orgId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        {...props}
      />
    ),
    [orgId, jurisdictionId, isFetchingContext],
  );

  const ConnectedTaxSummaries = useCallback(
    props => (
      <TaxSummaries
        orgId={orgId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        {...props}
      />
    ),
    [orgId, jurisdictionId, isFetchingContext],
  );

  const ConnectedAllReturnData = useCallback(
    props => (
      <AllReturnData
        orgId={orgId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        {...props}
      />
    ),
    [orgId, jurisdictionId, isFetchingContext],
  );

  const ConnectedGenericCategory = useCallback(
    props => (
      <GenericCategory
        orgId={orgId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        namespace={namespace}
        {...props}
      />
    ),
    [orgId, jurisdictionId, isFetchingContext, namespace],
  );

  const ConnectedTaxRateDetails = useCallback(
    props => (
      <TaxRateDetails
        orgId={orgId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        {...props}
      />
    ),
    [orgId, jurisdictionId, isFetchingContext],
  );

  const ConnectedApportionmentRateDetails = useCallback(
    props => (
      <ApportionmentRateDetails
        orgId={orgId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        {...props}
      />
    ),
    [orgId, jurisdictionId, isFetchingContext],
  );

  const ConnectedViewPrintTaxReturn = useCallback(props => <ViewPrintTaxReturn {...props} />, []);

  const ConnectedViewPrintAttachmentDetails = useCallback(
    props => (
      <ViewPrintAttachmentDetails
        jurisdictionId={jurisdictionId}
        profileId={profileId}
        isFetchingContext={isFetchingContext}
        entityId={orgId}
        {...props}
      />
    ),
    [orgId, isFetchingContext, jurisdictionId, profileId],
  );

  const ConnectedPropertySummary = useCallback(
    props => (
      <PropertySummary
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        orgId={orgId}
        {...props}
      />
    ),
    [orgId, isFetchingContext, jurisdictionId],
  );

  const ConnectedViewFormWorkpaper = useCallback(
    props => (
      <ViewFormWorkpaper
        jurisdictionId={jurisdictionId}
        profileId={profileId}
        isFetchingContext={isFetchingContext}
        entityId={orgId}
        {...props}
      />
    ),
    [orgId, isFetchingContext, jurisdictionId, profileId],
  );

  const ConnectedWorkpaperInstance = useCallback(
    props => (
      <WorkpaperInstance
        isFetchingContext={isFetchingContext}
        jurisdictionId={jurisdictionId}
        entityId={orgId}
        {...props}
      />
    ),
    [orgId, isFetchingContext, jurisdictionId],
  );

  return (
    <Switch>
      <AuthorizedRoute
        exact
        path={Routes[namespace].MAIN}
        render={ConnectedReturnWorkspaceDashboard}
      />
      <AuthorizedRoute
        exact
        path={Routes[`${namespace}TaxSummaries`].MAIN}
        render={ConnectedTaxSummaries}
      />
      <AuthorizedRoute
        exact
        path={Routes[`${namespace}AllReturnData`].MAIN}
        render={ConnectedAllReturnData}
      />
      <AuthorizedRoute
        path={Routes[`${namespace}GenericCategory`].MAIN}
        render={ConnectedGenericCategory}
      />
      <AuthorizedRoute
        path={Routes[`${namespace}GenericCategoryFromApportionmentRateDetails`].MAIN}
        component={ConnectedGenericCategory}
      />
      <AuthorizedRoute
        path={Routes[`${namespace}GenericCategoryFromPropertySummary`].MAIN}
        component={ConnectedGenericCategory}
      />
      <AuthorizedRoute
        exact
        path={Routes[`${namespace}TaxRateDetails`].MAIN}
        render={ConnectedTaxRateDetails}
      />
      <AuthorizedRoute
        exact
        path={Routes[`${namespace}ApportionmentRateDetails`].MAIN}
        render={ConnectedApportionmentRateDetails}
      />
      <AuthorizedRoute
        exact
        path={Routes[`${namespace}PropertySummary`].MAIN}
        render={ConnectedPropertySummary}
      />
      <AuthorizedRoute
        exact
        path={Routes.viewPrintTaxReturn.MAIN}
        render={ConnectedViewPrintTaxReturn}
      />
      <AuthorizedRoute
        exact
        path={Routes.viewPrintAttachmentDetails.MAIN}
        component={ConnectedViewPrintAttachmentDetails}
      />
      <AuthorizedRoute
        exact
        path={Routes.viewFormWorkpaper.MAIN}
        render={ConnectedViewFormWorkpaper}
      />
      <AuthorizedRoute
        exact
        path={Routes[`${namespace}WorkpaperInstance`].MAIN}
        render={ConnectedWorkpaperInstance}
      />
    </Switch>
  );
};

ReturnWorkspaceRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }),
  orgId: PropTypes.string,
  profileId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool,
};

export default ReturnWorkspaceRoutes;
