import { useDispatch } from 'react-redux';

import { httpPostFileAndParse } from '../../utils/fetchService';
import { startUserJobsPolling } from '../../jobs/store/actions';
import { QueryError } from '../queries';

import { useCustomMutation } from '.';

interface UploadOwnershipAllocationsMutationVariable {
  file: Blob;
  taxYear: string;
  period: string;
  shouldReplaceAccountData: string;
  shouldAggregateData: string;
}

const uploadOwnershipAllocations = ({
  taxYear,
  period,
  file,
  shouldReplaceAccountData,
  shouldAggregateData,
}: UploadOwnershipAllocationsMutationVariable) => {
  const body = new FormData();
  body.append('file', file);
  body.append('taxYear', taxYear);
  body.append('period', period);
  body.append('shouldReplaceAccountData', shouldReplaceAccountData);
  body.append('shouldAggregateData', shouldAggregateData);

  return httpPostFileAndParse({
    route: '/api/jim/ownership-allocations-upload',
    body,
  });
};

export const useMutationUploadOwnershipAllocations = () => {
  const dispatch = useDispatch();

  return useCustomMutation<null, QueryError, UploadOwnershipAllocationsMutationVariable>({
    customMutate: uploadOwnershipAllocations,
    onSuccess: () => dispatch(startUserJobsPolling()),
    successMessage: 'Uploaded file successfully',
    errorMessage: 'Uploading file failed',
  });
};
