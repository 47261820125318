import { defaultColumnDefinitionWithFilter } from '../../../../../shared/columnDefinitions';
import { dateFormatter } from '../../../../../shared/formatters';
import config from '../../../../../config';
import { refreshStatusSourceMap } from '../../../../../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';
import { entityInformationDetailsLinkRenderer } from '../../entityInformationDetails/entityInformationDetailsLinkRenderer';

const getRefreshStatusSourceLabel = (params: { data: { entityOpsRefreshStatus: number } }) =>
  refreshStatusSourceMap.get(params.data.entityOpsRefreshStatus) || null;

const columnDefinition = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Entity ID',
    field: 'orgId',
    width: 150,
    cellRenderer: entityInformationDetailsLinkRenderer,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Name',
    field: 'orgName',
    width: 500,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Updated On',
    field: 'updatedOn',
    width: 200,
    valueFormatter: ({ value }: { value: null | string }) =>
      dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Source Update On',
    field: 'entityOpsUpdatedDate',
    width: 200,
    valueFormatter: ({ value }: { value: null | string }) =>
      dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Refresh Status',
    field: 'entityOpsRefreshStatus',
    width: 150,
    valueGetter: getRefreshStatusSourceLabel,
  },
];

export default columnDefinition;
