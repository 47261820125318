import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import Input from '../../../../shared/forms/inputFormik/inputFormik.component';
import { ParamTypeValue, ContextItemValue } from '../../../../shared/reports/enums';
import { globalContextSelector } from '../../../../shared/store/selectors';
import getQueryParams from '../../../../utils/getQueryParams';

import DropdownField from './dropdownField.container';

const extractDependentParamsValues = (currentParams, values, query) => {
  const queryParams = getQueryParams(query);
  const dependentParams = currentParams.filter(param => queryParams.includes(param.mappingName));

  return dependentParams.reduce(
    (params, param) => Object.assign(params, { [param.mappingName]: values[param.mappingName] }),
    {},
  );
};

const CustomReportParam = ({
  reportId,
  queryParams,
  onChange,
  values,
  setFieldValue,
  setValues,
  actionMenu,
}) => {
  const globalContext = useSelector(globalContextSelector);

  useEffect(() => {
    const initialValues = queryParams.reduce(
      (acc, { mappingName, type, contextItem }) => ({
        ...acc,
        [mappingName]:
          acc[mappingName] || (type === ParamTypeValue.INPUT && contextValues[contextItem]) || null,
      }),
      {},
    );
    setValues(initialValues);
  }, []);

  useEffect(() => {
    const areValuesInitialized = Object.keys(values).length === Object.keys(queryParams).length;
    const isContextReady =
      areValuesInitialized && Object.values(values).every(value => value !== null);
    if (isContextReady) {
      onChange(values);
    }
  }, [onChange, values, queryParams, globalContext]);

  const contextValues = {
    [ContextItemValue.TAX_YEAR]: globalContext.taxYear,
    [ContextItemValue.PERIOD]: globalContext.period,
    [ContextItemValue.JURISDICTION_ID]: globalContext.jurisdictionId,
    [ContextItemValue.ENTITY_ID]: globalContext.isSeparate ? globalContext.entityId : null,
    [ContextItemValue.CONSOLIDATION_ID]: globalContext.isConsolidated
      ? globalContext.consolidationId
      : null,
  };

  return (
    <div className="global-context global-context-nav">
      {queryParams.map(({ name, mappingName, type, isMultiSelectDropdown, contextItem, query }) =>
        type === ParamTypeValue.INPUT ? (
          <Field
            key={name}
            label={mappingName}
            name={name}
            component={Input}
            autoComplete="off"
            placeholder="Type..."
          />
        ) : (
          <DropdownField
            key={name}
            reportId={reportId}
            name={name}
            label={mappingName}
            value={values[name]}
            handleChange={setFieldValue}
            isMultiSelectDropdown={isMultiSelectDropdown}
            defaultValue={contextValues[contextItem]}
            dependentParams={extractDependentParamsValues(queryParams, values, query)}
          />
        ),
      )}
      {actionMenu}
    </div>
  );
};

CustomReportParam.propTypes = {
  reportId: PropTypes.string.isRequired,
  queryParams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      mappingName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  actionMenu: PropTypes.node.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({}),
})(CustomReportParam);
