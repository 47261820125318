import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

const hasPermissionToManageAliases = customerPermissions =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.MANAGE_ALIASES],
  });

export default hasPermissionToManageAliases;
