import { connect } from 'react-redux';

import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import { isFetchingFilingGroupsSelector, filingGroupIdSelector } from '../store/selectors';

const FilingGroupDescriptionValue = connect(state => ({
  label: 'Federal Pro Forma Filing Group',
  value: filingGroupIdSelector(state),
  isBusy: isFetchingFilingGroupsSelector(state),
}))(GlobalContextValue);

export default FilingGroupDescriptionValue;
