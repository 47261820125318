import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';

import { fetchCustomScreenTypes, updateCustomScreenTypes } from './types';

export const initialState = {
  customScreen: {
    columnsBlueprint: [],
    rowData: [],
  },
  isFetchingCustomScreen: false,
  isUpdatingCustomScreen: false,
};

const customScreenReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchCustomScreenTypes,
      payloadKey: 'customScreen',
      fetchingKey: 'isFetchingCustomScreen',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateCustomScreenTypes,
      fetchingKey: 'isUpdatingCustomScreen',
    }),
    [fetchCustomScreenTypes.FAILURE]: state => ({
      ...state,
      customScreen: {
        columnsBlueprint: [],
        rowData: [],
      },
      isFetchingCustomScreen: false,
    }),
  },
});

export default customScreenReducer;
