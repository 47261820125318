import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, CLEAR_NOTIFICATION } from './types';

const addNotification = (state, { payload }) => {
  const { type, message, closeable, duration } = payload;
  return {
    ...state,
    notification: {
      type,
      message,
      closeable,
      duration: closeable ? null : duration,
    },
  };
};

function notificationReducer(
  state = {
    notification: null,
  },
  action,
) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return addNotification(state, action);
    case REMOVE_NOTIFICATION:
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: null,
      };
    default:
      return state;
  }
}

export default notificationReducer;
