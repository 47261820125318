import { httpPost, httpPostAndParse } from '../../../utils/fetchService';

const baseURL = '/api/tools';

export const fetchValidatedMultData = ({ taxYear, period, rows }) =>
  httpPostAndParse({
    route: `${baseURL}/mult-data/validate`,
    errorMessage: 'Validating mult data failed',
    body: {
      taxYear,
      period,
      rows,
    },
  });

export const uploadMultData = ({ taxYear, period, rows }) =>
  httpPost({
    route: `${baseURL}/mult-data/upload`,
    errorMessage: 'Uploading mult data failed',
    body: {
      taxYear,
      period,
      rows,
    },
  });
