import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchPartnershipAllocationPercentagesTypes,
  updatePartnershipAllocationPercentagesTypes,
  deletePartnershipAllocationTypes,
  fetchPartnershipPercentageTypesTypes,
  fetchPartnersTypes,
  updateOwnershipTypes,
} from './types';

export const fetchPartnershipAllocationPercentages = apiAction({
  types: fetchPartnershipAllocationPercentagesTypes,
  apiAction: api.fetchPartnershipAllocationPercentages,
});

export const updatePartnershipAllocationPercentages = apiAction(
  {
    types: updatePartnershipAllocationPercentagesTypes,
    apiAction: api.updatePartnershipAllocationPercentages,
  },
  {
    successNotificationMessage: () =>
      'Partnership allocation percentages has been successfully updated',
  },
);

export const deletePartnershipAllocation = apiAction(
  {
    types: deletePartnershipAllocationTypes,
    apiAction: api.deletePartnershipAllocation,
  },
  {
    successNotificationMessage: () => 'Partnership allocation has been successfully deleted',
  },
);

export const fetchPartnershipPercentageTypes = apiAction({
  types: fetchPartnershipPercentageTypesTypes,
  apiAction: api.fetchPartnershipPercentageTypes,
});

export const fetchPartners = apiAction({
  types: fetchPartnersTypes,
  apiAction: api.fetchPartners,
});

export const updateOwnership = apiAction(
  {
    types: updateOwnershipTypes,
    apiAction: api.updateOwnership,
  },
  {
    successNotificationMessage: () => 'Ownership and allocation percentages updated successfully.',
  },
);
