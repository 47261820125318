import { combineReducers } from 'redux';

import stateAccountNumbersReducer from '../stateAccountNumbers/store/reducer';
import partnershipInformationReducer from '../partnershipInformation/store/reducer';
import partnershipFlowThroughAmountsReducer from '../partnershipFlowThrough/store/reducer';
import reviewerNotesReducer from '../reviewerNotes/store/reducer';
import federalReconciliationsReducer from '../federalReconciliations/store/reducer';
import entityInformationReducer from '../entityInformation/store/reducer';
import manuallyEnteredAccountDataReducer from '../manuallyEnteredAccountData/store/reducer';
import partnershipAllocationPercentagesReducer from '../partnershipAllocationPercentages/store/reducer';
import k1InformationReducer from '../k1Information/store/reducer';

import entityReducer from './entity.reducer';

export default combineReducers({
  root: entityReducer,
  stateAccountNumbers: stateAccountNumbersReducer,
  partnershipInformation: partnershipInformationReducer,
  partnershipFlowThroughAmounts: partnershipFlowThroughAmountsReducer,
  k1Information: k1InformationReducer,
  reviewerNotes: reviewerNotesReducer,
  federalReconciliations: federalReconciliationsReducer,
  entityInformation: entityInformationReducer,
  manuallyEnteredAccountData: manuallyEnteredAccountDataReducer,
  partnershipAllocationPercentages: partnershipAllocationPercentagesReducer,
});
