const findChildrenOfSelectedDataset = (dataset, assignedDatasets) => {
  const filteredChildrenDatasets = assignedDatasets?.filter(
    assignedDataset => assignedDataset.treeHierarchyParentId === dataset?.treeHierarchyId,
  );

  if (!filteredChildrenDatasets.length) {
    return [];
  }

  return filteredChildrenDatasets.reduce(
    (acc, filteredDataset) => [
      ...acc,
      ...findChildrenOfSelectedDataset(filteredDataset, assignedDatasets),
    ],
    filteredChildrenDatasets,
  );
};

export default findChildrenOfSelectedDataset;
