import React, { useMemo } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import Loading from '../../../../shared/displayComponents/loading.component';
import { SelectOptionPropTypes } from '../../../../shared/propTypes/selectOption';
import Select from '../../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Input from '../../../../shared/forms/inputFormik/inputFormik.component';
import Checkbox from '../../../../shared/forms/checkboxFormik/checkboxFormik.component';
import ButtonsGroup from '../../../../shared/forms/buttonsGroup/buttonsGroup.component';

import getColumnDefinitions from './PDFAttachmentColumnProperties.columnDefinitions';
import styles from './DatasetUsageForm.module.scss';
import { orientationOptions, OVERFLOW_ACTION_ATTACHMENT, PORTRAIT } from './constants';

const stmtReferenceField = () => (
  <Field label="Stmt. Reference Field" name="pdfStmtRefFieldName" component={Input} />
);

const DatasetUsageForm = ({
  values,
  overflowActionTypes,
  submitForm,
  isSubmitting,
  onCancelClick,
  PDFAttachmentColumnProperties,
  isFetchingPDFAttachmentColumnProperties,

  addRow,
  deleteRow,
  updateRow,
  onGridReady,
}) => {
  const isAttachmentOverflowAction = values && values.overflowAction === OVERFLOW_ACTION_ATTACHMENT;

  const columnDefinitions = useMemo(() => getColumnDefinitions({ deleteRow, updateRow }), [
    deleteRow,
    updateRow,
  ]);

  const attachmentOverflowData = () => (
    <>
      <Select
        appkitLabel="Orientation"
        name="printOrientationLandscape"
        options={orientationOptions}
        value={
          values && values.printOrientationLandscape ? values.printOrientationLandscape : PORTRAIT
        }
      />
      <Field label="Font Size" name="fontSize" type="number" component={Input} />
      <Field
        label="Page Width Percentage"
        name="pageWidthPercentage"
        type="number"
        component={Input}
      />
    </>
  );

  const attachmentOverflowDataGrid = () => (
    <div className={styles.gridSection}>
      <div className={styles.aboveGridRow}>
        <p>Attachment Column Properties</p>
        <Button
          size="lg"
          className="add-button"
          onClick={addRow}
          // TODO handle addition in a story related to SLT-2425
          style={{ display: 'none' }}
          disabled
        >
          Add Property
        </Button>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={PDFAttachmentColumnProperties}
            isGridLoading={isFetchingPDFAttachmentColumnProperties}
            columnDefs={columnDefinitions}
            onGridReady={onGridReady}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
    </div>
  );

  return (
    <Loading isLoading={isSubmitting}>
      <form>
        <div className={styles.formInputs}>
          <Field
            label="Instances Per Page"
            name="instancesPerPage"
            type="number"
            component={Input}
          />
          <Field label="Instances to Skip" name="instToSkip" type="number" component={Input} />
          <Select
            appkitLabel="Overflow Action"
            name="overflowAction"
            options={overflowActionTypes}
            value={values ? values.overflowAction : null}
          />
          <Field label="Drill UI Field" name="uiLocationFieldName" component={Input} />
          <Field label="Include Cons Members" name="includeConsMembers" component={Checkbox} />
          <Field
            label="Calc Preference Name"
            name="calcPreferenceName"
            component={Input}
            disabled
          />
          <Field label="Calc Preference On" name="calcPreferenceOn" component={Checkbox} disabled />
          {isAttachmentOverflowAction && stmtReferenceField()}
          {isAttachmentOverflowAction && attachmentOverflowData()}
        </div>
        {isAttachmentOverflowAction && attachmentOverflowDataGrid()}
        <div>
          <ButtonsGroup
            fullWidth={false}
            isSubmitting={isSubmitting}
            onCancelClick={onCancelClick}
            submitForm={submitForm}
          />
        </div>
      </form>
    </Loading>
  );
};

DatasetUsageForm.propTypes = {
  overflowActionTypes: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    datasetId: PropTypes.string.isRequired,
    overflowAction: PropTypes.string,
    printOrientationLandscape: PropTypes.string,
  }),
  onCancelClick: PropTypes.func.isRequired,
  PDFAttachmentColumnProperties: PropTypes.arrayOf(
    PropTypes.shape({
      dataItemName: PropTypes.string,
      description: PropTypes.string,
      displayName: PropTypes.string,
      itemDisplayOrder: PropTypes.number,
      columnDisplayOrder: PropTypes.number,
      screenDisplay: PropTypes.bool,
      printDisplay: PropTypes.bool,
      printColumnTotal: PropTypes.bool,
      relativeWidth: PropTypes.number,
      fontSize: PropTypes.number,
    }).isRequired,
  ).isRequired,
  isFetchingPDFAttachmentColumnProperties: PropTypes.bool.isRequired,

  onGridReady: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
};

export default DatasetUsageForm;
