import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@tls/ui-spinner';

import FaIcon from '../faIcon/faIcon.component';

import './dataInfo.style.scss';

const DataInfo = ({ textToRender, showIcon = true, showSpinner = false }) => (
  <div className="data-info">
    <div className="data-info-container">
      {showIcon && <FaIcon className="info-circle-icon" icon="info-circle" size={32} />}
      {showSpinner && (
        <span className="info-spinner">
          <Spinner small />
        </span>
      )}
      <p className="data-info-paragraph">{textToRender}</p>
    </div>
  </div>
);

DataInfo.propTypes = {
  textToRender: PropTypes.node,
  showIcon: PropTypes.bool,
  showSpinner: PropTypes.bool,
};

export default DataInfo;
