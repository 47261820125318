import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';

import { fetchFederalReconciliationVariancesTypes } from './types';

const federalReconciliationsVariancesReducer = reducerFactory({
  initialState: {
    variances: [],
    isFetchingVariances: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFederalReconciliationVariancesTypes,
      payloadKey: 'variances',
      fetchingKey: 'isFetchingVariances',
    }),
  },
});

export default federalReconciliationsVariancesReducer;
