import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SDKCustomSelect from '../forms/sdkCustomSelect/sdkCustomSelect.component';
import Loading from '../displayComponents/loading.component';

import { SELECT_ALL_OPTION_VALUE } from './constants';
import styles from './reportParam.module.scss';

const ReportParamDropdown = ({
  label,
  param,
  selectAllLabel = '',
  autoSelectFirstValue = false,
  showSelectAllOption = false,
}) => {
  const { isFetchingParamData, paramData, paramValue, setParamValue } = param;
  const options = showSelectAllOption
    ? [
        { value: SELECT_ALL_OPTION_VALUE, label: selectAllLabel || 'Select All' },
        ...(paramData || []),
      ]
    : paramData;

  useEffect(() => {
    if (autoSelectFirstValue && paramData && paramData.length) {
      setParamValue(paramData[0].value);
    }
  }, [setParamValue, autoSelectFirstValue, paramData]);

  return (
    <div className={classNames('global-context-dropdown', styles.reportParamDropdown)}>
      <Loading isLoading={isFetchingParamData}>
        <SDKCustomSelect
          className="sdk-custom-select"
          options={options}
          onChange={useCallback(({ value }) => setParamValue(value), [setParamValue])}
          value={paramValue}
          virtualized
          appkitLabel={label}
        />
      </Loading>
    </div>
  );
};

ReportParamDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  param: PropTypes.shape({
    isFetchingParamData: PropTypes.bool.isRequired,
    paramData: PropTypes.array,
    paramValue: PropTypes.any,
    setParamValue: PropTypes.func.isRequired,
  }).isRequired,
  autoSelectFirstValue: PropTypes.bool,
  showSelectAllOption: PropTypes.bool,
  onChange: PropTypes.func,
  selectAllLabel: PropTypes.string,
};

export default ReportParamDropdown;
