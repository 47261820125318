import {
  ICellEditorParams,
  ValueGetterParams,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community';
import { Routes } from '@common-packages/routes-definitions';

import downloadFile from '../../utils/downloadFile';
import {
  defaultColumnDefinitionWithFilter,
  selectCellEditorParamsFactory,
} from '../../shared/columnDefinitions';
import {
  ReturnStatusTrackerDetailsResultRow,
  FilingTypeId,
  BusinessEntityId,
  JurisdictionId,
  FindReturnStatusDefinition,
  ReturnStatusTrackerRow,
} from '../../../../common/types';
import config from '../../config';
import { dateFormatter } from '../../shared/formatters';
import { UpdateRowFunc } from '../../shared/editMode/types';
import { FilingMethods, TaxReturnStatus } from '../../shared/enums';
import dateComparator from '../../shared/americanDateComparator';
import { dueDateFormatter } from '../../shared/formatters/dueDateFormatter';
import { LinkCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import { defaultAgRichSelectCellEditorSelector } from '../../shared/columnDefinitions/cellEditor';
import { cellRenderer } from '../../shared/columnDefinitions/cellRenderers/cellRenderer.component';
import {
  getNonFilingDisplayCellClassRules,
  getDropDownCellClassRules,
  isReturnStatusEditable,
} from '../../taxReturns/separate/setupTaxReturns.columnDefinitions';

type SetContextParams = {
  filingType: FilingTypeId | null;
  businessEntityId: BusinessEntityId | null;
  jurisdictionId: JurisdictionId | null;
};

const defaultColumnDefinition = {
  ...defaultColumnDefinitionWithFilter,
  width: 120,
  lockPinned: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDateValueGetter = (data: any, field: string) => {
  return data?.[field] ? dateFormatter(data?.[field], config.AMERICAN_DATE_FORMAT) : '';
};

const getColumnDefinitions = ({
  setContext,
  handleUpdateRow,
  isTaxYearAfterOr2023,
  returnStatusOptions,
}: {
  setContext: (params: SetContextParams) => void;
  handleUpdateRow: UpdateRowFunc<ReturnStatusTrackerRow>;
  returnStatusOptions?: FindReturnStatusDefinition;
  isTaxYearAfterOr2023: boolean;
}) => {
  const onCellValueChanged = ({ data }: RowNode) => {
    handleUpdateRow(data);
  };

  const LinkRenderer = LinkCellRendererFactory({
    getText: ({ data }) => data.businessEntityId,
    getPathName: (_, { id }) =>
      Routes.returnMaintenance.compiledRoute({
        returnId: id,
      }),
    clickHandler: (_, { businessEntityId, jurisdictionId, filingType }) => {
      setContext({ businessEntityId, jurisdictionId, filingType });
    },
  });

  const acknowledgedFileDownloadLinkRenderer = () =>
    LinkCellRendererFactory({
      getText: ({ value }) => value,
      getShowAsLink: ({ value, data }) =>
        value === 'Accepted' && isTaxYearAfterOr2023 && Boolean(data.acknowledgedFile),
      clickHandler: (_, data) =>
        downloadFile({
          route: '',
          fileLocator: data.acknowledgedFile,
          fileName: `${data.taxYear}_${data.stateCode}_${data.businessEntityId}_${data.stateSubmissionId}_Ack.xml`,
        }),
    });

  return [
    {
      ...defaultColumnDefinition,
      headerName: 'Return Type',
      field: 'returnType',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Business',
      field: 'businessUnit',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity ID / Consolidation ID',
      field: 'businessEntityId',
      width: 140,
      pinned: 'left',
      lockPosition: 'left',
      cellRendererFramework: LinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Name',
      field: 'name',
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdiction',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Return Status',
      field: 'returnStatus',
      editable: isReturnStatusEditable(),
      cellRenderer: (params: ICellRendererParams) =>
        cellRenderer({
          params,
          renderer: ({ data, value }: ICellRendererParams) => {
            if (data.filingMethod === FilingMethods.NON_FILING) {
              return '';
            }
            return (returnStatusOptions?.returnStatusOptions || []).find(
              option => option.value === value,
            )?.label;
          },
        }),
      cellEditorSelector: ({
        data,
      }: Omit<ICellEditorParams, 'data' | 'value'> & {
        data: ReturnStatusTrackerDetailsResultRow;
        value: TaxReturnStatus | null;
      }) => {
        return {
          ...defaultAgRichSelectCellEditorSelector,
          params: selectCellEditorParamsFactory(
            data?.filingMethod === FilingMethods.EFILE
              ? returnStatusOptions?.efileReturnStatusOptions
              : returnStatusOptions?.returnStatusOptions,
            {},
            false,
          )(),
        };
      },
      filterParams: {
        valueFormatter: ({
          value,
        }: Omit<ICellEditorParams, 'value'> & {
          value: string;
        }) =>
          (returnStatusOptions?.returnStatusOptions || []).find(
            option => option.value === parseInt(value, 10),
          )?.label,
      },
      cellClassRules: {
        ...getNonFilingDisplayCellClassRules('filingMethod'),
        ...getDropDownCellClassRules(),
      },
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Filing Form',
      field: 'filingForm',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Due Date',
      field: 'dueDate',
      valueGetter: ({
        data,
      }: Omit<ICellEditorParams, 'data' | 'value'> & {
        data: ReturnStatusTrackerDetailsResultRow;
      }) => (data.dueDate ? dueDateFormatter(data.dueDate) : ''),
      comparator: dateComparator,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Filing Method',
      field: 'filingMethod',
      width: 100,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Filing Type',
      field: 'filingType',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Filing Group(s)',
      field: 'filingGroups',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'E-File Status',
      field: 'efileStatusName',
      cellRenderer: acknowledgedFileDownloadLinkRenderer(),
    },
    {
      ...defaultColumnDefinition,
      headerName: 'E-File Status Last Updated',
      field: 'eFileStatusLastUpdated',
      valueGetter: ({ data }: ValueGetterParams<ReturnStatusTrackerDetailsResultRow>) =>
        getDateValueGetter(data, 'eFileStatusLastUpdated'),
      comparator: dateComparator,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'E-File Status Last Updated by',
      field: 'eFileStatusLastUpdatedby',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'State Submission ID',
      field: 'submissionId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Authorized Signature Name',
      field: 'authorizedSignatureName',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Authorized Date',
      field: 'authorizedDate',
      valueGetter: ({ data }: ValueGetterParams<ReturnStatusTrackerDetailsResultRow>) =>
        getDateValueGetter(data, 'authorizedDate'),
      comparator: dateComparator,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Transmission Date',
      field: 'transmissionDate',
      valueGetter: ({ data }: ValueGetterParams<ReturnStatusTrackerDetailsResultRow>) =>
        getDateValueGetter(data, 'transmissionDate'),
      comparator: dateComparator,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Transmitted By',
      field: 'transmittedBy',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Acknowledged Date',
      field: 'acknowledgedDate',
      valueGetter: ({ data }: ValueGetterParams<ReturnStatusTrackerDetailsResultRow>) =>
        getDateValueGetter(data, 'acknowledgedDate'),
      comparator: dateComparator,
    },
  ];
};

export default getColumnDefinitions;
