import { GlobalContext } from '../../../../common/types';
import { httpGetAndParse } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from './index';

type EFileXmlComparisonReportResponse =
  | {
      htmlContent: string;
    }
  | Record<string, never>;

const fetchEFileXmlComparisonReport = (apiRouteParamString: string) =>
  httpGetAndParse({
    route: `/api/proforma/xml-comparison/${apiRouteParamString}`,
    parseResponseErrorMessage: true,
  });

export const useQueryEFileXmlComparisonReport = ({
  params,
  enabled,
}: {
  params: {
    apiRouteParamString: GlobalContext['apiRouteParamString'];
  };
  enabled: boolean;
}) =>
  useCustomQuery<EFileXmlComparisonReportResponse>(
    [QueryKeys.Reports.EFileXmlComparisonReport, params],
    {
      enabled,
      initialData: {},
      defaultData: {},
      displayMessageFromError: true,
      customFetch: () => fetchEFileXmlComparisonReport(params.apiRouteParamString),
    },
  );
