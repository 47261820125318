import { useMemo } from 'react';

import { TaxReturnItem } from '../../../common/types';

import { ReturnItemTypes } from './enums';

const useFilteredReturnItemIds = (taxReturnItems: TaxReturnItem[]) => {
  const returnFormItemAndOrgIds = useMemo(
    () =>
      taxReturnItems
        .filter(
          // Double assertion is needed because `itemType` from `TaxReturnItem` uses a different enum.
          // We can't use the same enum because it has to be used at runtime and those from `common`
          // directory can't be used at runtime because they are out of the project scope
          ({ itemType }) => ((itemType as unknown) as ReturnItemTypes) === ReturnItemTypes.FORM,
        )
        .map(({ itemId, orgId }) => ({
          itemId,
          orgId,
        })) || [],
    [taxReturnItems],
  );

  const returnPdfIds = useMemo(
    () =>
      taxReturnItems
        .filter(
          ({ itemType }) =>
            ((itemType as unknown) as ReturnItemTypes) === ReturnItemTypes.PDF_ATTACHMENT,
        )
        .map(({ itemId }) => itemId) || [],
    [taxReturnItems],
  );

  return {
    returnFormItemAndOrgIds,
    returnPdfIds,
  };
};

export default useFilteredReturnItemIds;
