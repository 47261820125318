import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';
import { filingGroupsSchemasV2 } from '@common-packages/validators';

import { periodNameSelector, periodSelector, taxYearSelector } from '../shared/store/selectors';

import { updateFilingGroup, fetchFilingGroups, createFilingGroup } from './store/actions';
import AddEditFilingGroupForm from './addEditFilingGroupForm.component';
import { filingGroupPropTypes } from './propTypes';

const AddEditFilingGroupModal = ({
  hideModal,
  visible,

  taxYear,
  periodName,
  period,
  filingGroupToEdit,
  filingGroups,
  updateFilingGroup,
  createFilingGroup,
  fetchFilingGroups,
}) => {
  const formInitialValues = {
    taxYear: taxYear ? taxYear : '',
    period: periodName ? periodName : '',
    filingGroupId: '',
    description: '',
  };

  const saveFilingGroup = useCallback(
    async ({ filingGroupId, description }) => {
      const data = {
        taxYear,
        period,
        filingGroupId,
        description,
      };
      if (!filingGroupToEdit) {
        await createFilingGroup(data);
      } else {
        await updateFilingGroup(data);
      }
      hideModal();
      fetchFilingGroups({
        taxYear,
        period,
      });
    },
    [
      hideModal,
      updateFilingGroup,
      createFilingGroup,
      fetchFilingGroups,
      filingGroupToEdit,
      taxYear,
      period,
    ],
  );

  const onClose = useCallback(
    ({ resetForm }) => () => {
      hideModal();
      resetForm();
    },
    [hideModal],
  );

  const renderModal = useCallback(
    formikProps => (
      <Modal
        title={`${filingGroupToEdit ? 'Edit' : 'Add'} Filing Group`}
        closeAction={onClose(formikProps)}
        visible={visible}
        submitText={filingGroupToEdit ? 'Save' : 'Add'}
        submitAction={formikProps.submitForm}
        dismissText="Cancel"
        dismissAction={onClose(formikProps)}
      >
        <AddEditFilingGroupForm
          {...formikProps}
          filingGroups={filingGroups}
          filingGroupToEdit={filingGroupToEdit}
        />
      </Modal>
    ),
    [onClose, filingGroupToEdit, visible, filingGroups],
  );
  return (
    <Formik
      initialValues={
        filingGroupToEdit
          ? { ...filingGroupToEdit, taxYear, period: periodName }
          : formInitialValues
      }
      enableReinitialize
      validateOnBlur={false}
      onSubmit={saveFilingGroup}
      validationSchema={filingGroupsSchemasV2.getCreateOrUpdateFilingGroupUISchema({
        filingGroups,
        isEditMode: Boolean(filingGroupToEdit),
      })}
    >
      {renderModal}
    </Formik>
  );
};

AddEditFilingGroupModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  taxYear: PropTypes.string,
  periodName: PropTypes.string,
  period: PropTypes.string,
  filingGroupToEdit: PropTypes.shape({
    filingGroupId: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  filingGroups: PropTypes.arrayOf(filingGroupPropTypes).isRequired,
  fetchFilingGroups: PropTypes.func.isRequired,
  updateFilingGroup: PropTypes.func.isRequired,
  createFilingGroup: PropTypes.func.isRequired,
};
export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    periodName: periodNameSelector(state),
    period: periodSelector(state),
  }),
  {
    updateFilingGroup,
    fetchFilingGroups,
    createFilingGroup,
  },
)(AddEditFilingGroupModal);
