import { FormatDataValueResponse, FormatDataValueBody, FieldToUpdate } from '@tls/slt-types';

import { HttpMethods } from '../enums';
import { QueryError } from '../queries';

import { useCustomMutation } from '.';

export interface GroupFieldToUpdate {
  [blockId: string]: FieldToUpdate[];
}

interface FormatGroupDataValueBody {
  fields: GroupFieldToUpdate;
}

interface FormatGroupDataValueResponse {
  formattedFields: {
    [blockId: string]: {
      [key: string]: string;
    };
  };
}

export const useMutationFormatDataValue = () =>
  useCustomMutation<FormatDataValueResponse, QueryError, FieldToUpdate[], FormatDataValueBody>({
    handleData: fields => ({
      url: '/api/shared/view-tax-return/form-data/format-data-value',
      method: HttpMethods.POST,
      body: {
        fields,
      },
    }),
    errorMessage: 'Value formatting failed',
    parseResponseAsJson: true,
  });

export const useMutationFormatGroupDataValue = () =>
  useCustomMutation<
    FormatGroupDataValueResponse,
    QueryError,
    GroupFieldToUpdate,
    FormatGroupDataValueBody
  >({
    handleData: fields => ({
      url: '/api/shared/view-tax-return/form-data/format-group-data-value',
      method: HttpMethods.POST,
      body: {
        fields,
      },
    }),
    errorMessage: 'Value formatting failed',
    parseResponseAsJson: true,
  });
