import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { watermarkSchemas } from '@common-packages/validators';

import {
  useMutationInsertWatermark,
  useMutationUpdateWatermark,
} from '../../shared/mutations/watermarks';
import { watermarkToEditPropTypes } from '../../shared/propTypes/watermark.propTypes';

import AddEditWatermarkForm from './addEditWatermarkForm.component';

const newWatermark = {
  name: '',
  rotation: '50',
  fontSize: '70',
  verticalOffset: '0',
  line1: '',
  line2: '',
  line3: '',
};

const AddEditWatermark = ({ watermarkToEdit, clearForm, watermarksNames = [] }) => {
  const insertWatermark = useMutationInsertWatermark();
  const updateWatermark = useMutationUpdateWatermark();

  const isEditMode = Boolean(watermarkToEdit);

  const validationSchema = isEditMode
    ? watermarkSchemas.editWatermarkSchema
    : watermarkSchemas.getAddWatermarkSchema(watermarksNames);

  const saveWatermark = useCallback(
    async (
      { id, name, rotation, fontSize, verticalOffset, line1, line2, line3 },
      { resetForm },
    ) => {
      const watermark = {
        name,
        rotation: Number(rotation),
        fontSize: Number(fontSize),
        verticalOffset: Number(verticalOffset),
        line1,
        line2,
        line3,
      };
      if (!isEditMode) {
        await insertWatermark.mutateAsync(watermark);
        resetForm(newWatermark);
      } else {
        await updateWatermark.mutateAsync({ ...watermark, watermarkId: id });
      }
      clearForm();
    },
    [clearForm, insertWatermark, updateWatermark, isEditMode],
  );

  return (
    <Formik
      initialValues={watermarkToEdit || newWatermark}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={saveWatermark}
    >
      <AddEditWatermarkForm isEditMode={isEditMode} />
    </Formik>
  );
};

AddEditWatermark.propTypes = {
  watermarkToEdit: watermarkToEditPropTypes,
  clearForm: PropTypes.func.isRequired,
  watermarksNames: PropTypes.arrayOf(PropTypes.string),
};

export default AddEditWatermark;
