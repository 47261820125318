import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchDirectPartnershipInfoTypes,
  fetchIndirectPartnershipInfoTypes,
  fetchPartnershipsTypes,
  fetchPartnershipInfoTypes,
  updateOrDeletePartnershipsInfoTypes,
} from './types';

export const initialState = {
  directPartnershipInfo: [],
  isFetchingDirectPartnershipInfo: false,

  indirectPartnershipInfo: [],
  isFetchingIndirectPartnershipInfo: false,

  partnerships: [],
  isFetchingPartnerships: false,

  partnershipInfoTypes: [],
  isFetchingPartnershipInfoTypes: false,

  isUpdatingPartnershipsInfo: false,
};

const partnershipInformationReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchDirectPartnershipInfoTypes,
      payloadKey: 'directPartnershipInfo',
      fetchingKey: 'isFetchingDirectPartnershipInfo',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchIndirectPartnershipInfoTypes,
      payloadKey: 'indirectPartnershipInfo',
      fetchingKey: 'isFetchingIndirectPartnershipInfo',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnershipsTypes,
      payloadKey: 'partnerships',
      fetchingKey: 'isFetchingPartnerships',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnershipInfoTypes,
      payloadKey: 'partnershipInfoTypes',
      fetchingKey: 'isFetchingPartnershipInfoTypes',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateOrDeletePartnershipsInfoTypes,
      fetchingKey: 'isUpdatingPartnershipsInfo',
    }),
  },
});

export default partnershipInformationReducer;
