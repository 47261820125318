import React from 'react';
import { Button } from '@pwc/appkit-react';

import config from '../../config';

import styles from './downloadPdfPanel.module.scss';

interface PdfReadyParams {
  downloadPdf: () => void;
  isDownloading: boolean;
}

const PdfReady = ({ downloadPdf, isDownloading }: PdfReadyParams) => {
  return (
    <div className={styles.panelWrapper}>
      <div className={styles.panelItemWrapper}>
        <img
          alt="pdf-ready"
          src={`${config.BASE_PATH}/assets/pdf-ready.png`}
          className={styles.illustration}
        />
      </div>
      <span className={styles.text}>
        Your PDF Is ready. If the file did not automatically download, click the Download button.
      </span>
      <div className={styles.downloadButtonWrapper}>
        <Button
          size="xl"
          kind="secondary"
          className={styles.downloadButton}
          onClick={downloadPdf}
          isLoading={isDownloading}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default PdfReady;
