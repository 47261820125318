export const PORTRAIT = '0';

export const orientationOptions = [
  {
    label: 'Portrait',
    value: PORTRAIT,
  },
  {
    label: 'Landscape',
    value: '1',
  },
];

export const OVERFLOW_ACTION_DATA_ERROR = '0';
export const OVERFLOW_ACTION_ATTACHMENT = '1';
