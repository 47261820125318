import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { ReportToggleRenderer } from '../../shared/columnDefinitions/cellRenderers';
import { currencyCellFormatter, amountFormatter } from '../../shared/formatters';

import { DisplayLevel, LinesToFollow } from './constants';

const DATA_FORMAT_PERCENT = 'PERCENT';
const DATA_4_DECIMAL_PLACES = 4;

const descriptionCellClassRules = {
  'bold-cell': ({ data }) => data.displayLevel === DisplayLevel.HEADER,
  'cell-with-indent': ({ data }) => data.indentLevel > 0,
};

const amountCellClassRules = {
  'underlined-cell': ({ data }) => data.linesToFollow === LinesToFollow.SINGLE,
  'double-underlined-cell underlined-cell-top': ({ data }) =>
    data.linesToFollow === LinesToFollow.TOTAL,
};
const valueFormatter = props => {
  if (props.data.displayLevel === DisplayLevel.HEADER || props.data.isEmptyRow) {
    return '';
  }

  return props.data.dataFormat === DATA_FORMAT_PERCENT
    ? amountFormatter({
        value: props.value,
        props: {
          ...props.data,
          percentage: true,
          decimalPlaces: DATA_4_DECIMAL_PLACES,
        },
      })
    : currencyCellFormatter(props);
};

const getColumnDefinitions = ({ columnsBlueprint }) => {
  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
      width: 500,
      pinned: 'left',
      lockPinned: true,
      cellClassRules: descriptionCellClassRules,
      sortable: false,
      cellRenderer: ReportToggleRenderer,
    },
    ...columnsBlueprint.map(({ field, defaultHeaderName }) => {
      return {
        ...defaultColumnDefinition,
        headerName: defaultHeaderName,
        valueFormatter,
        field,
        cellClassRules: amountCellClassRules,
        valueGetter: ({ data, colDef }) => data[colDef.field] && data[colDef.field].amount,
        sortable: false,
      };
    }),
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
