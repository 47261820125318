import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import { taxYearSelector, periodSelector } from '../store/selectors';
import DevelopmentRoute from '../routeConfiguration/developmentRoute.container';

import GenericFunctions from './genericFunctions.container';
import GenericFunctionsAddEdit from './genericFunctionsAddEdit.container';
import { fetchGenericFunctions } from './store/actions';
import { genericFunctionsSelector, isFetchingGenericFunctionsSelector } from './store/selectors';

const GenericFunctionsRoutes = () => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const genericFunctions = useSelector(genericFunctionsSelector);
  const isFetchingGenericFunctions = useSelector(isFetchingGenericFunctionsSelector);

  useEffect(() => {
    const isContextReady = taxYear && period;

    if (isContextReady) {
      dispatch(fetchGenericFunctions({ taxYear, period }));
    }
  }, [dispatch, taxYear, period]);

  return (
    <Switch>
      <DevelopmentRoute
        exact
        path={Routes.developmentGenericFunctions.MAIN}
        component={GenericFunctions}
        hideGlobalContext
        additionalProps={{
          genericFunctions,
          isFetchingGenericFunctions,
        }}
      />
      <DevelopmentRoute
        exact
        path={Routes.developmentGenericFunctionsAddEdit.MAIN}
        component={GenericFunctionsAddEdit}
        hideGlobalContext
        additionalProps={{
          genericFunctions,
          isFetchingGenericFunctions,
        }}
      />
    </Switch>
  );
};

export default GenericFunctionsRoutes;
