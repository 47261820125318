import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchCustomReportsTypes = getApiActionTypes('FETCH_CUSTOM_REPORTS');
export const fetchCustomReportToEditTypes = getApiActionTypes('FETCH_CUSTOM_REPORT_TO_EDIT');
export const addCustomReportTypes = getApiActionTypes('ADD_CUSTOM_REPORT');
export const updateCustomReportTypes = getApiActionTypes('UPDATE_CUSTOM_REPORT');
export const runCustomQueryTypes = getApiActionTypes('RUN_CUSTOM_QUERY');
export const deleteCustomReportTypes = getApiActionTypes('DELETE_CUSTOM_REPORT');
export const runCustomQueryForParamOptionsTypes = getApiActionTypes(
  'RUN_CUSTOM_QUERY_FOR_PARAM_OPTIONS',
);
export const fetchParamOptionsTypes = getApiActionTypes('FETCH_PARAM_OPTIONS');
