import { StepStatuses } from '@common-packages/shared-constants';

import { getTopLevelStepsWithContent } from './getStepsData';

const getUsersLastStep = ({
  stepsData,
  taxYear = null,
  jurisdictionId = null,
  filingTypeId = null,
}) => {
  if (!stepsData.length) {
    return 1;
  }

  // steps are ordered by stepNumber ascending
  const lastExecutedStepName = stepsData[stepsData.length - 1].stepName;
  const isLastExecutedStepCompleted =
    stepsData[stepsData.length - 1].status === StepStatuses.COMPLETED;

  const topLevelStepsWithContent = getTopLevelStepsWithContent({
    taxYear,
    jurisdictionId,
    filingTypeId,
  });

  const wizardsStepWithLastExecution = Object.entries(topLevelStepsWithContent)
    .map(([key, value]) => ({ stepNumber: Number(key), steps: value }))
    .find(wizardsStep => wizardsStep.steps.includes(lastExecutedStepName));

  const isExecutedAndCompletedSubStepLastOnWizardsStep =
    wizardsStepWithLastExecution.steps.indexOf(lastExecutedStepName) + 1 ===
      wizardsStepWithLastExecution.steps.length && isLastExecutedStepCompleted;

  const numberOfStepsInWizard = Object.keys(topLevelStepsWithContent).length;
  const isUserOnLastWizardsStep = wizardsStepWithLastExecution.stepNumber === numberOfStepsInWizard;

  if (isUserOnLastWizardsStep || !isExecutedAndCompletedSubStepLastOnWizardsStep) {
    return wizardsStepWithLastExecution.stepNumber;
  }

  return wizardsStepWithLastExecution.stepNumber + 1;
};

export default getUsersLastStep;
