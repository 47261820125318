import { apiAction } from '../../shared/store/apiAction';

import { fetchCustomScreenTypes, updateCustomScreenTypes } from './types';
import * as api from './api';

export const fetchCustomScreen = apiAction({
  types: fetchCustomScreenTypes,
  apiAction: api.fetchCustomScreen,
});

export const updateCustomScreen = apiAction({
  types: updateCustomScreenTypes,
  apiAction: api.updateCustomScreen,
});
