export const TABS_TYPES = {
  FORM_VIEW: 'FORM_VIEW',
  FIELDS_VIEW: 'FIELDS_VIEW',
};

export const tabsDefinitions = [
  { type: TABS_TYPES.FORM_VIEW, label: 'Form View' },
  { type: TABS_TYPES.FIELDS_VIEW, label: 'Fields View' },
];

export const displayDropdownEntries = [
  {
    value: 'Field Name',
    label: 'Field Name',
  },
  {
    value: 'Data Item',
    label: 'Data Item',
  },
];

export const defaultDisplayDropdownValue = displayDropdownEntries[0].value;
