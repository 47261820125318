import { httpGetAndParse, httpPut, httpDelete, httpPost } from '../../../utils/fetchService';

const baseURL = '/api/admin/maintenance';

export const fetchJurisdictions = ({ taxYear }) =>
  httpGetAndParse({
    route: `${baseURL}/shared/jurisdictions/${taxYear}`,
    errorMessage: 'Fetching jurisdictions failed',
  });

export const fetchTaxRates = ({ taxYear, period, jurisdictionId, filingTypeId }) =>
  httpGetAndParse({
    route: `${baseURL}/tax-rates/${taxYear}/${period}/${filingTypeId}/${jurisdictionId}`,
    errorMessage: 'Fetching tax rates failed',
  });

export const deleteTaxRate = ({
  taxYear,
  period,
  jurisdictionId,
  filingTypeId,
  taxType,
  startRange,
  stopRange,
  filingAttributes,
  calcOptions,
}) =>
  httpDelete({
    route: `${baseURL}/tax-rates/`,
    errorMessage: 'Deleting tax rates failed',
    body: {
      taxYear,
      period,
      jurisdictionId,
      filingTypeId,
      taxType,
      startRange,
      stopRange,
      filingAttributes,
      calcOptions,
    },
  });

export const createTaxRate = ({
  taxYear,
  period,
  jurisdictionId,
  filingTypeId,
  startRange,
  stopRange,
  taxType,
  rate,
  filingAttributesValues,
}) =>
  httpPost({
    route: `${baseURL}/tax-rates`,
    errorMessage: 'Creating tax rates failed',
    body: {
      taxYear,
      period,
      jurisdictionId,
      filingTypeId,
      startRange,
      stopRange,
      taxType,
      rate,
      filingAttributesValues,
    },
  });

export const updateTaxRate = ({
  taxYear,
  period,
  jurisdictionId,
  filingTypeId,
  taxType,
  oldStartRange,
  startRange,
  stopRange,
  oldStopRange,
  rate,
  filingAttributes,
  filingAttributesValues,
  exclusionRate,
  exclusionAmt,
}) =>
  httpPut({
    route: `${baseURL}/tax-rates`,
    errorMessage: 'Updating tax rates failed',
    body: {
      taxYear,
      period,
      jurisdictionId,
      filingTypeId,
      taxType,
      oldStartRange,
      startRange,
      stopRange,
      oldStopRange,
      rate,
      filingAttributes,
      filingAttributesValues,
      exclusionRate,
      exclusionAmt,
    },
  });
