import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import { BreadcrumbRoute } from '../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';
import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';

import WorkpaperInstance from './workpaperInstance.container';

const WorkpaperInstanceWithContext = withContextWrapper()(WorkpaperInstance);

const WorkpaperInstanceBreadcrumbRoute = () => <BreadcrumbRoute path={Routes.workpaperInstance} />;

const WorkpaperInstancePage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: WorkpaperInstanceBreadcrumbRoute,
}))(GlobalContextPage);

const WorkpaperInstanceRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.workpaperInstance.MAIN}
    wrapper={WorkpaperInstancePage}
    component={WorkpaperInstanceWithContext}
    hideGlobalContext
  />
);

export default WorkpaperInstanceRoute;
