import {
  AdminCellRendererFactory,
  EditableCheckboxCellRenderer,
} from '../shared/columnDefinitions/cellRenderers';
import { adminColumnDefinition } from '../shared/columnDefinitions';

const defaultColumnDefinition = {
  cellStyle: {
    textAlign: 'left',
  },
  suppressMenu: false,
};

const checkboxColumnDefinition = {
  cellStyle: {
    textAlign: 'center',
  },
  suppressMenu: false,
  width: 75,
  cellRenderer: EditableCheckboxCellRenderer,
  cellRendererParams: {
    disabled: true,
  },
};

const getColumnDefinitions = ({ onEditIconClick, shouldShowEditIcon }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onEditIconClick,
    shouldShowEditIcon,
    shouldShowDeleteIcon: false,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdiction',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'State Filing Group',
      field: 'filingGroupId',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
      width: 500,
      maxWidth: 500,
    },
    {
      ...checkboxColumnDefinition,
      headerName: 'Separate Limitation',
      field: 'separateLimit',
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
