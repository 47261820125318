import {
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
  TaxReturnDefinitionWithFilingAttributes,
  FilingAttributes,
} from '../../../../../common/types/apiShapes';

const areReturnDefinitionFilingAttributesMatching = (
  returnDefinition: TaxReturnDefinitionWithFilingAttributes,
  allTaxReturns: TaxReturnResultRowWithCalcOptionsAndFilingAttributes[],
) => {
  const existingReturn = allTaxReturns.find(
    ({ jurisdictionId }) => jurisdictionId === returnDefinition.jurisdictionId,
  );

  if (!existingReturn || !existingReturn.taxReturn) {
    // we need matching attributes only if there is an existing return for this jurisdiction
    return true;
  }

  const { returnTypeKey, businessTypeKey } = Object.entries(existingReturn).reduce(
    (acc, [key, value]) => {
      if (key.startsWith('RETURN_TYPE_') && typeof value === 'boolean' && value) {
        return {
          ...acc,
          returnTypeKey: key,
        };
      }
      if (key.startsWith('BUSINESS_TYPE_') && typeof value === 'boolean' && value) {
        return {
          ...acc,
          businessTypeKey: key,
        };
      }
      return acc;
    },
    { returnTypeKey: '', businessTypeKey: '' },
  );

  return (
    returnDefinition.filingAttributes[returnTypeKey as keyof FilingAttributes] &&
    returnDefinition.filingAttributes[businessTypeKey as keyof FilingAttributes]
  );
};

export default areReturnDefinitionFilingAttributesMatching;
