import React from 'react';
import { Avatar, Badge } from '@pwc/appkit-react';
import classNames from 'classnames';

import styles from './notificationIcon.module.scss';

interface NotificationIconProps {
  count?: number;
  isActive: boolean;
  onClick: () => void;
}

const NotificationIcon = ({ count, isActive, onClick }: NotificationIconProps) => (
  <span className={styles.notificationIconContainer} onMouseDown={onClick}>
    <Avatar
      className={classNames([styles.notificationAvatar], {
        [styles.active]: isActive,
      })}
      icon={<span className="appkiticon icon-notification-outline" />}
    />
    {count ? (
      <Badge className={styles.notificationCount} size="small">
        {count}
      </Badge>
    ) : null}
  </span>
);

export default NotificationIcon;
