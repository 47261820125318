const InfoAccountScreenType = {
  COMMON: 1,
  STATE_SPECIFIC: 2,
  K1_OR_MEMBER: 3,
  GLOBAL: 4,
  GLOBAL_K1_OR_MEMBER: 5,
};

module.exports = {
  InfoAccountScreenType,
};
