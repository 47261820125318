export const highlightRowAndClearAccountIdQueryParam = ({ gridApi, setParams, accountId }) => {
  if (!gridApi || !accountId || !setParams) {
    return;
  }

  gridApi.forEachNode(node => {
    if (node.data?.accountId === accountId) {
      node.setSelected(true);
      gridApi.ensureNodeVisible(node);
      setParams({ queryParams: { accountId: null } });
    }
  });
};
