import React from 'react';
import { Field, useFormikContext } from 'formik';

import thousandsSeparatorInputFormik from '../../inputFormik/thousandsSeparatorInputFormik.component';
import { CalcPreferencesFormikValues } from '../../../../../../common/types';

import styles from './numericCalcPreference.module.scss';

const NumericCalcPreference = ({
  label,
  name,
  optionsKey,
  className = '',
}: {
  label: string;
  name: string;
  optionsKey?: string;
  className?: string;
}) => {
  const { errors } = useFormikContext<Record<string, CalcPreferencesFormikValues>>();

  const errorMessage = optionsKey ? errors[optionsKey]?.[label] : errors[label];
  return (
    <>
      <Field
        className={`${styles.numericCalcWrapper} ${className}`}
        label={label}
        name={name}
        component={thousandsSeparatorInputFormik}
      />
      {errorMessage && <p className="a-form-error">{errorMessage as string}</p>}
    </>
  );
};

export default NumericCalcPreference;
