import { ColDef } from 'ag-grid-community';

import {
  defaultColumnDefinitionWithFilter,
  disabledReadOnlyCheckboxColumnDefinitions,
} from '../../../../shared/columnDefinitions';

const columnDefinitions: ColDef[] = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
    width: 120,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'XML Element',
    field: 'elementPath',
    flex: 1,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Name/EIN',
    field: 'nameOrEin',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Is Partner?',
    field: 'isPartner',
    width: 100,
  },
];

export default columnDefinitions;
