import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';

import { SelectOptionPropTypes } from '../../../shared/forms/propTypes';
import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Loading from '../../../shared/displayComponents/loading.component';

const AddComponentAdjustmentForm = ({
  genericCategoryAccountOptions,
  isFetchingComponentOptions,
  componentOptions,
}) => {
  const { values } = useFormikContext();

  return (
    <form>
      <Select
        wrapperClassName="modal-field"
        appkitLabel="Account"
        name="accountId"
        options={genericCategoryAccountOptions}
        value={values.accountId}
      />
      <Loading small isLoading={isFetchingComponentOptions}>
        <Select
          wrapperClassName="modal-field"
          appkitLabel="Component"
          name="componentId"
          options={componentOptions}
          value={values.componentId}
        />
      </Loading>
      <Field
        label="Adj. Amount"
        name="value"
        component={Input}
        autoComplete="off"
        type="number"
        value={values.value}
      />
    </form>
  );
};

AddComponentAdjustmentForm.propTypes = {
  genericCategoryAccountOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  componentOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingComponentOptions: PropTypes.bool.isRequired,
};
export default AddComponentAdjustmentForm;
