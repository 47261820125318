const americanDateComparator = (dateStr1: string, dateStr2: string) => {
  if (dateStr1 === dateStr2) return 0;

  if (!dateStr1) return -1;

  if (!dateStr2 || new Date(dateStr1) > new Date(dateStr2)) return 1;

  return -1;
};

export default americanDateComparator;
