import { createSelector } from 'reselect';

export const isFetchingConsolidationsSelector = ({ shared }) =>
  shared.root.isFetchingConsolidations;

export const consolidationIdSelector = ({ shared }) => shared.root.consolidationId;

export const allConsolidationsSelector = ({ shared }) => shared.root.consolidations;

export const consolidationsSelector = createSelector(
  [({ shared }) => shared.root.consolidations, ({ shared }) => shared.root.jurisdictionId],
  (consolidations, selectedJurisdictionId) =>
    consolidations.filter(({ jurisdictionId }) => jurisdictionId === selectedJurisdictionId),
);

export const globalOrFirstConsolidationIdSelector = createSelector(
  consolidationIdSelector,
  consolidationsSelector,
  (consolidationId, consolidations) =>
    consolidationId || (consolidations.length > 0 && consolidations[0].consolidationId) || null,
);

export const consolidationsOptionsSelector = createSelector(
  [consolidationsSelector],
  consolidations =>
    consolidations.map(({ consolidationId, description }) => ({
      label: `${consolidationId}${description ? ` - ${description}` : ''}`,
      value: consolidationId,
    })),
);

export const consolidationSelector = createSelector(
  [consolidationsSelector, consolidationIdSelector],
  (consolidations, consolidationId) =>
    consolidations.find(consolidation => consolidation.consolidationId === consolidationId) || null,
);

export const consolidationOrgIdSelector = createSelector(
  [consolidationsSelector, consolidationIdSelector],
  (consolidations, consolidationId) => {
    const consolidation = consolidations.find(
      consolidation => consolidation.consolidationId === consolidationId,
    );
    return consolidation && consolidation.orgId;
  },
);

export const consolidationNameSelector = createSelector([consolidationSelector], consolidation =>
  consolidation ? consolidation.description || consolidation.consolidationId : null,
);

export const consolidationFullNameSelector = createSelector(
  [consolidationSelector],
  consolidation =>
    consolidation
      ? `${consolidation.consolidationId}${
          consolidation.description ? ` - ${consolidation.description}` : ''
        }`
      : '',
);

export const consolidationJurisdictionDescriptionSelector = createSelector(
  [consolidationSelector],
  consolidation => (consolidation ? consolidation.jurisdictionDescription : null),
);

export const consolidationProfileIdSelector = createSelector(
  [consolidationSelector],
  consolidation => (consolidation ? consolidation.profileId : null),
);

export const consolidationProFormaFilingGroupIdSelector = createSelector(
  [consolidationSelector],
  consolidation => consolidation?.proformaFilingGroupId || null,
);

export const consolidationsForValidationSelector = createSelector(
  [allConsolidationsSelector],
  consolidations => consolidations.map(({ consolidationId }) => consolidationId),
);
