import React from 'react';
import PropTypes from 'prop-types';

import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import Loading from '../../../shared/displayComponents/loading.component';
import GenericSourceData from '../genericSourceData/genericSourceData.container';
import globalContextPropTypes from '../../../shared/propTypes/globalContext';
import ManualAccountsGrid from '../../../shared/displayComponents/customizedScreen/manualAccounts/manualAccountsGrid.container';

import useCustomizedScreen from './useCustomizedScreen.hook';
import { screenTypes } from './constants';

const CustomizedScreenForComponentDrill = ({
  match,

  globalContext,
  isFetchingContext,
}) => {
  const { taxYear, period, businessEntityId, jurisdictionId } = globalContext.params;
  const { params } = match;
  const { categoryId, accountId, componentId } = params;
  const isComponentDataReady = categoryId && accountId && componentId;

  const { customizedScreen, isFetchingCustomizedScreen } = useCustomizedScreen({
    taxYear,
    period,
    orgId: businessEntityId,
    jurisdictionId,
    categoryId,
    accountId,
    componentId,
  });

  const isLoading = isFetchingContext || isFetchingCustomizedScreen;

  if (isLoading) {
    return (
      <div className="grid-row">
        <div className="col">
          <Loading isLoading />
        </div>
      </div>
    );
  }

  if (!isComponentDataReady || !customizedScreen.type) {
    return <SelectContextDataInfo />;
  }

  if (customizedScreen.type === screenTypes.SOURCE_SYSTEM_DATA) {
    return (
      <GenericSourceData
        globalContext={globalContext}
        categoryId={categoryId}
        accountId={accountId}
        componentId={componentId}
      />
    );
  }
  return <ManualAccountsGrid manualAccounts={customizedScreen.manualAccounts} />;
};

CustomizedScreenForComponentDrill.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      componentId: PropTypes.string.isRequired,
      categoryId: PropTypes.string,
      accountId: PropTypes.string,
    }).isRequired,
  }).isRequired,

  globalContext: globalContextPropTypes,
  isFetchingContext: PropTypes.bool.isRequired,
  SourceSystemDataScreenComponent: PropTypes.elementType,
};

export default CustomizedScreenForComponentDrill;
