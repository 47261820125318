import React, { useCallback } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { Input } from '@pwc/appkit-react';

import Select from '../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Loading from '../shared/displayComponents/loading.component';
import InputFormik from '../shared/forms/inputFormik/inputFormik.component';
import { useQueryFilingGroupReturnOptions } from '../shared/queries/filingGroups';

import { filingGroupPropTypes } from './propTypes';

const uniqueErrorMessage =
  'The Filing Group Id already exists, a unique Id is required before saving';

const unchangedFilingGroupValidationMessage =
  'The Filing Group Id has not been changed from the copied Filing Group, a new value is required';

const defaultFederalReturnTypeOptions = [
  {
    value: '1120',
    label: '1120',
  },
];

const AddEditFilingGroupForm = ({ values, filingGroups, filingGroupToEdit, period }) => {
  const checkIsFilingGroupUnique = useCallback(
    filingGroupId => filingGroups.some(filingGroup => filingGroup.filingGroupId === filingGroupId),
    [filingGroups],
  );

  const filingGroupIdValidation = useCallback(
    filingGroupId => {
      if (filingGroupId && filingGroupId === filingGroupToEdit?.filingGroupIdOfCopiedFilingGroup) {
        return unchangedFilingGroupValidationMessage;
      }

      // Validate for copying and creating a new group
      if (
        (!filingGroupToEdit || filingGroupToEdit?.isCopy) &&
        checkIsFilingGroupUnique(filingGroupId)
      ) {
        return uniqueErrorMessage;
      }

      return null;
    },
    [checkIsFilingGroupUnique, filingGroupToEdit],
  );

  const {
    data: federalReturnTypesOptions,
    isLoading: isLoadingFederalReturnTypeOptions,
  } = useQueryFilingGroupReturnOptions({
    params: {
      taxYear: values.taxYear,
      period,
      filingGroupId: filingGroupToEdit?.filingGroupId,
    },
    enabled: Boolean(values.taxYear && period && filingGroupToEdit?.filingGroupId),
  });

  return (
    <form>
      <Input className="modal-field" label="Tax Year" value={values.taxYear} disabled />
      <Input className="modal-field" label="Period" value={values.periodName} disabled />
      <Field
        label="Filing Group Id"
        name="filingGroupId"
        className="modal-field"
        component={InputFormik}
        autoComplete="off"
        validate={filingGroupIdValidation}
        disabled={Boolean(filingGroupToEdit) && !filingGroupToEdit.isCopy}
      />
      <Field
        label="Description"
        name="description"
        className="modal-field"
        component={InputFormik}
        autoComplete="off"
      />
      <Loading small isLoading={isLoadingFederalReturnTypeOptions}>
        <Select
          appkitLabel="Federal Return Type"
          name="federalReturnType"
          options={federalReturnTypesOptions || defaultFederalReturnTypeOptions}
          value={values.federalReturnType}
        />
      </Loading>
    </form>
  );
};

AddEditFilingGroupForm.propTypes = {
  values: PropTypes.shape({
    taxYear: PropTypes.string,
    periodName: PropTypes.string,
    filingGroupId: PropTypes.string,
    description: PropTypes.string,
    federalReturnType: PropTypes.string,
  }),
  filingGroups: PropTypes.arrayOf(filingGroupPropTypes).isRequired,
  filingGroupToEdit: PropTypes.shape({
    filingGroupId: PropTypes.string.isRequired,
    filingGroupIdOfCopiedFilingGroup: PropTypes.string,
    isCopy: PropTypes.bool,
  }),
  period: PropTypes.string,
};

export default AddEditFilingGroupForm;
