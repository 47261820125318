import { apiAction } from '../../shared/store/apiAction';
import * as api from '../store/api';

import {
  fetchEFileStepsDataTypes,
  invokeEFilePre2StepsTypes,
  runTTIStepTypes,
  fetchValidationMessagesTypes,
  fetchSignatureDeclarationTypes,
  fetchFederalProformaSubmissionIdTypes,
  RESET_CONTEXT_SPECIFIC_WIZARDS_STATE,
} from './types';

export const fetchEFileStepsData = apiAction({
  types: fetchEFileStepsDataTypes,
  apiAction: api.fetchEFileStepsData,
});

export const invokeEFileSetupStepAction = apiAction({
  types: invokeEFilePre2StepsTypes,
  apiAction: api.invokeEFileSetupStepAction,
});

export const runTTIStep = apiAction({
  types: runTTIStepTypes,
  apiAction: api.runTTIStep,
});

export const runInitiateTTIStep = apiAction({
  types: runTTIStepTypes,
  apiAction: api.runTTIStep,
  throwApiErrors: true,
});

export const fetchValidationMessages = apiAction({
  types: fetchValidationMessagesTypes,
  apiAction: api.fetchValidationMessages,
});

export const fetchSignatureDeclaration = apiAction({
  types: fetchSignatureDeclarationTypes,
  apiAction: api.fetchSignatureDeclaration,
});

export const fetchFederalProformaSubmissionId = apiAction({
  types: fetchFederalProformaSubmissionIdTypes,
  apiAction: api.fetchFederalProformaSubmissionId,
});

export const resetContextSpecificWizardsState = () => ({
  type: RESET_CONTEXT_SPECIFIC_WIZARDS_STATE,
});
