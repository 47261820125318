import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip, Button } from '@pwc/appkit-react';
import { useLocation } from 'react-router-dom';
import groupBy from 'lodash.groupby';
import sumBy from 'lodash.sumby';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../shared/editMode';
import {
  genericCategorySelector,
  isFetchingGenericCategorySelector,
  isUpdatingGenericCategorySelector,
  isAnyGenericSubcategorySelector,
} from '../../shared/store/genericCategory/selectors';
import {
  fetchGenericCategory,
  updateGenericCategory,
} from '../../shared/store/genericCategory/actions';
import useModal from '../../shared/hooks/useModal.hook';
import useGroupingGrid from '../../shared/hooks/useGroupingGrid.hook';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { globalContextSelector } from '../../shared/store/selectors';
import { highlightRowAndClearAccountIdQueryParam } from '../utils/highlightRowAndClearAccountIdQueryParam';

import AddComponentAdjustmentModal from './addComponentAdjustmentModal/addComponentAdjustmentModal.container';
import getColumnDefinitions from './genericCategory.columnDefinitions';
import styles from './genericCategory.module.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const isExternalFilterPresent = () => true;

const GenericCategory = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();

  const {
    queryParams: { accountId: rowToHighlightAccountId },
    routeParams: { categoryId, taxName },
    setParams,
  } = useUrlParams();
  const location = useLocation();

  const {
    showModal: showAddComponentAdjustmentModal,
    modalProps: addComponentAdjustmentProps,
  } = useModal();

  const [suppressZeros, setSuppressZeros] = useState(false);
  const [isComponentDetail, setIsComponentDetail] = useState(false);
  const agGridFilter = useRef(null);

  const globalContext = useSelector(globalContextSelector);
  const genericCategory = useSelector(genericCategorySelector);
  const isAnyGenericSubcategory = useSelector(isAnyGenericSubcategorySelector);
  const isFetchingGenericCategory = useSelector(isFetchingGenericCategorySelector);
  const isUpdatingGenericCategory = useSelector(isUpdatingGenericCategorySelector);

  const {
    isReady: isContextReady,
    params: { taxYear, period, filingTypeId, businessEntityId, jurisdictionId },
  } = globalContext;

  const fetchGenericCategoryData = useCallback(() => {
    dispatch(
      fetchGenericCategory({
        taxYear,
        period,
        entityId: businessEntityId,
        jurisdictionId,
        categoryId,
      }),
    );
  }, [dispatch, taxYear, period, businessEntityId, jurisdictionId, categoryId]);

  useEffect(() => {
    setSuppressZeros(false);
  }, [categoryId]);

  useEffect(() => {
    fetchGenericCategoryData();
  }, [fetchGenericCategoryData]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        accountJurisdictionId: oldRow.accountJurisdictionId,
        accountId: oldRow.accountId,
        begEndFlag: oldRow.begEndFlag,
        componentId: oldRow.componentId,
        value: newRow.adjustments,
      }));
      await dispatch(
        updateGenericCategory({
          period,
          orgId: businessEntityId,
          taxYear,
          rowsToUpdate,
          jurisdictionId,
        }),
      );
      fetchGenericCategoryData();
    },
    [dispatch, fetchGenericCategoryData, taxYear, period, jurisdictionId, businessEntityId],
  );

  const isLoading = !isContextReady || isFetchingGenericCategory || isUpdatingGenericCategory;
  const isDisabled = isLoading || !genericCategory.length;

  const {
    gridApi,
    navigationPrompt,
    setIsInEditMode,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: genericCategory,
    getUniqueRowId,
    editButtonDisabled: isDisabled,
  });

  const mappedRowData = useMemo(() => {
    if (isComponentDetail) {
      return clonedRowData;
    }

    return Object.values(groupBy(clonedRowData, 'accountId')).map(elementsGroupedByAccountId => {
      const restValues = elementsGroupedByAccountId[0];
      return {
        ...restValues,
        currentYearValue: sumBy(elementsGroupedByAccountId, 'currentYearValue'),
        priorYearValue: sumBy(elementsGroupedByAccountId, 'priorYearValue'),
        adjustments: sumBy(elementsGroupedByAccountId, 'adjustments'),
        dataCollection: sumBy(elementsGroupedByAccountId, 'dataCollection'),
      };
    });
  }, [clonedRowData, isComponentDetail]);

  const addComponentAdjustment = useCallback(() => {
    setIsInEditMode(false);
    showAddComponentAdjustmentModal();
  }, [showAddComponentAdjustmentModal, setIsInEditMode]);

  useEffect(() => {
    if (isInEditMode) {
      setIsComponentDetail(true);
    }
  }, [isInEditMode]);

  const onRowDataUpdated = useCallback(() => {
    highlightRowAndClearAccountIdQueryParam({
      gridApi,
      setParams,
      accountId: rowToHighlightAccountId,
    });
  }, [setParams, gridApi, rowToHighlightAccountId]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    agGridFilter.current = ({
      accountId,
      dataCollection,
      adjustments,
      currentYearValue,
      priorYearValue,
    }) => {
      const hasRowZeros = !dataCollection && !adjustments && !currentYearValue && !priorYearValue;

      const isNotTotalRow = accountId !== 'TOTAL';

      const hide = hasRowZeros && isNotTotalRow && suppressZeros;

      return !hide;
    };

    gridApi.onFilterChanged();
  }, [gridApi, suppressZeros]);

  const {
    gridKey,
    showCategorySubtotals,
    rowClassRules,
    aggFuncs,
    GroupRowRenderer,
    getGroupingCellClassRules,
    isTotalRow,
    toggleShowCategorySubtotals,
    getRowHeight,
    initialGroupOrderComparator,
  } = useGroupingGrid({ aggColumnKey: 'accountDescription' });

  const toggleSuppressZeros = useCallback(() => {
    setSuppressZeros(prevValue => !prevValue);
  }, []);

  const toggleComponentDetail = useCallback(() => {
    setIsComponentDetail(prevValue => !prevValue);
  }, []);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
        categoryId,
        taxName,
        isTotalRow,

        getGroupingCellClassRules,
        isInEditMode,
        updateRow,
        location,
        isComponentDetail,
      }),
    [
      updateRow,
      getGroupingCellClassRules,
      isTotalRow,
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
      taxName,
      categoryId,
      isInEditMode,
      location,
      isComponentDetail,
    ],
  );

  const doesExternalFilterPass = useCallback(({ data }) => {
    if (!agGridFilter.current) {
      return true;
    }

    return agGridFilter.current(data);
  }, []);

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className={`col ${styles.gridOptions}`}>
          {isAnyGenericSubcategory && (
            <Tooltip content="Show subcategories for displayed accounts" placement="top">
              <Checkbox
                className={styles.showCategorySubtotalsCheckbox}
                checked={showCategorySubtotals}
                onChange={toggleShowCategorySubtotals}
                disabled={isDisabled}
              >
                Show Category Subtotals
              </Checkbox>
            </Tooltip>
          )}
          <Tooltip content="Hides account rows that contain all zero values" placement="top">
            <Checkbox
              className={styles.suppressZerosCheckbox}
              checked={suppressZeros}
              onChange={toggleSuppressZeros}
              disabled={isDisabled}
            >
              Suppress Zeros
            </Checkbox>
          </Tooltip>
          <Tooltip content="Show Component Detail View" placement="top">
            <Checkbox
              className={styles.componentDetailCheckbox}
              checked={isComponentDetail}
              onChange={toggleComponentDetail}
              disabled={isDisabled || isInEditMode}
            >
              Show Component Detail
            </Checkbox>
          </Tooltip>
        </div>
        {hasUserPermissionsToEdit && (
          <div className="col add-button-column">
            <Button
              size="lg"
              className="add-button"
              onClick={addComponentAdjustment}
              disabled={isLoading}
            >
              Add Component Adj
            </Button>
            {editModeButtons}
          </div>
        )}
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            className="generic-category-grid"
            key={gridKey}
            rowData={mappedRowData}
            columnDefs={columnDefinitions}
            groupDisplayType={'groupRows'}
            groupRowRenderer={GroupRowRenderer}
            groupDefaultExpanded={-1}
            groupIncludeFooter={showCategorySubtotals}
            suppressAggFuncInHeader
            aggFuncs={aggFuncs}
            initialGroupOrderComparator={initialGroupOrderComparator}
            getRowHeight={getRowHeight}
            rowClassRules={rowClassRules}
            isGridLoading={isLoading}
            singleClickEdit
            withSearchBar
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            stopEditingWhenCellsLoseFocus
            animateRows
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            onRowDataUpdated={onRowDataUpdated}
          />
        </div>
      </div>
      <AddComponentAdjustmentModal
        {...addComponentAdjustmentProps}
        genericCategory={genericCategory}
        submitCallback={fetchGenericCategoryData}
      />
    </>
  );
};

GenericCategory.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default GenericCategory;
