import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  PULL_GENERATE_PDF_SUCCESS,
  fetchTaxFormTypes,
  updateTaxFormTypes,
  deleteTaxFormTypes,
  fetchDataModelsWithGroupedDatasetsAndDataItemsTypes,
  fetchPdfPageDataUsageTypes,
  RESET_PDF_PAGE_DATA_USAGE,
} from './types';

export const fetchTaxForm = apiAction({
  types: fetchTaxFormTypes,
  apiAction: api.fetchTaxForm,
});

export const pullGeneratePdfSuccess = jobData => ({
  type: PULL_GENERATE_PDF_SUCCESS,
  payload: jobData,
});

export const updateTaxForm = apiAction(
  {
    types: updateTaxFormTypes,
    apiAction: api.updateTaxForm,
  },
  {
    successNotificationMessage: () => 'Updating tax form succeeded',
    errorNotificationMessage: () => 'Updating tax form failed',
  },
);

export const deleteTaxForm = apiAction({
  types: deleteTaxFormTypes,
  apiAction: api.deleteTaxForm,
});

export const fetchDataModelsWithGroupedDatasetsAndDataItems = apiAction({
  types: fetchDataModelsWithGroupedDatasetsAndDataItemsTypes,
  apiAction: api.fetchDataModelsWithGroupedDatasetsAndDataItems,
});

export const fetchPdfPageDataUsage = apiAction({
  types: fetchPdfPageDataUsageTypes,
  apiAction: api.fetchPdfPageDataUsage,
});

export const resetPdfPageDataUsage = () => ({
  type: RESET_PDF_PAGE_DATA_USAGE,
});
