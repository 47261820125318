import React from 'react';
import { Badge } from '@pwc/appkit-react';

import Loading from '../../shared/displayComponents/loading.component';
import SearchBar from '../../shared/displayComponents/searchBar/searchBar.component';
import TransitionList from '../../shared/displayComponents/transitionList/transitionList.component';

import styles from './addReturnItems.module.scss';

interface AddReturnItemsProps {
  items: {
    id: string;
    label: string;
  }[];
  isLoadingItems: boolean;
  noResultsMessage?: string;
  filterText?: string;
  handleSearch: (searchText: string) => unknown;
  shouldShowSelectedItems: boolean;
  handleToggleShowSelectedItems: () => unknown;
  isListItemSelected: (id: string) => boolean;
  handleCheckboxChange: (id: string) => unknown;
}

const AddReturnItems = ({
  items,
  isLoadingItems,
  noResultsMessage = 'No results',
  filterText = 'Show selected items',
  handleSearch,
  shouldShowSelectedItems,
  handleToggleShowSelectedItems,
  isListItemSelected,
  handleCheckboxChange,
}: AddReturnItemsProps) => (
  <>
    <div className={styles.control}>
      <SearchBar onSearch={handleSearch} />
    </div>
    <div className={styles.filters}>
      <button className={styles.filter} onClick={handleToggleShowSelectedItems}>
        <Badge name={filterText} type={shouldShowSelectedItems ? 'primary' : 'secondary'}>
          {filterText}
        </Badge>
      </button>
    </div>
    <Loading isLoading={isLoadingItems}>
      {items.length > 0 ? (
        <TransitionList
          listClassName={styles.list}
          listItemClassName={styles.listItem}
          items={items}
          isItemSelected={isListItemSelected}
          onItemSelectionChange={handleCheckboxChange}
        />
      ) : (
        <p className={styles.noResultsMessage}>{noResultsMessage}</p>
      )}
    </Loading>
  </>
);

export default AddReturnItems;
