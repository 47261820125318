import React from 'react';
import { useSelector } from 'react-redux';

import {
  taxYearSelector,
  isFetchingTaxYearsDataSelector,
  periodNameSelector,
  isFetchingPeriodsSelector,
} from '../../store/selectors';
import Loading from '../../displayComponents/loading.component';

interface GlobalContextValuePros {
  label?: string;
  value?: string | null;
  isBusy?: boolean;
}

export const GlobalContextValue = ({
  label = '',
  value = null,
  isBusy = false,
}: GlobalContextValuePros) => (
  <div className="global-context-value-wrapper">
    <label className="a-form-label">{label}:</label>
    <div className="global-context-value">
      <Loading small isLoading={isBusy}>
        {value}
      </Loading>
    </div>
  </div>
);

export const TaxYearValue = () => {
  const value = useSelector(taxYearSelector);
  const isBusy = useSelector(isFetchingTaxYearsDataSelector);
  return <GlobalContextValue label="Tax Year" isBusy={isBusy} value={value} />;
};

export const PeriodValue = () => {
  const value = useSelector(periodNameSelector);
  const isBusy = useSelector(isFetchingPeriodsSelector);
  return <GlobalContextValue label="Period" isBusy={isBusy} value={value} />;
};
