import { ColumnApi, GridApi } from 'ag-grid-community';

import { getClientLocalStorageItem } from '../../localStorage';

export const setColumnStateByLocalStorage = ({
  clientId,
  saveGridStateId,
  columnApi,
  gridApi,
}: {
  clientId: string;
  saveGridStateId?: string;
  columnApi: ColumnApi | null;
  gridApi: GridApi | null;
}) => {
  const gridState = getClientLocalStorageItem({ clientId, clientDetailKey: saveGridStateId });

  if (!gridState || !columnApi || !gridApi) {
    return;
  }

  if (gridState.filters) {
    gridApi.setFilterModel(gridState.filters);
  }

  if (gridState.columns) {
    columnApi.applyColumnState({
      state: gridState.columns,
      applyOrder: true,
    });
  }
};
