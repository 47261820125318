import {
  httpGetAndParse,
  httpPostAndParse,
  httpDelete,
  httpPut,
  httpGetBlob,
  httpPost,
} from '../../utils/fetchService';
import stringify from '../../utils/stringify';

export const fetchBinderItems = ({ globalContext, binderId, paramsToOverride = {} }) =>
  httpGetAndParse({
    route: `/api/binder-maintenance/binder-items/${binderId}/${globalContext.getOverridenApiRouteParamString(
      paramsToOverride,
    )}`,
    errorMessage: 'Fetching binder items failed',
  });

export const fetchEntities = ({ globalContext }) =>
  httpGetAndParse({
    route: `/api/binder-maintenance/entities/${globalContext.apiRouteParamString}`,
    errorMessage: 'Fetching binder items failed',
  });

export const copyBinder = ({ binderId, name }) =>
  httpPostAndParse({
    route: `/api/binder-maintenance/copy/binder/${binderId}`,
    errorMessage: 'Copying binder failed',
    body: { name },
  });

export const updateBinderItems = ({
  globalContext,
  binderId,
  businessEntityId,
  documentType,
  documentLevel,
  binderItemIds,
  areAllEntitiesInConsolidation,
}) =>
  httpPut({
    route: `/api/binder-maintenance/binder-items/${binderId}/${globalContext.apiRouteParamString}`,
    errorMessage: 'Updating binder items failed',
    body: {
      businessEntityId,
      documentType,
      documentLevel,
      binderItemIds,
      areAllEntitiesInConsolidation,
    },
  });

export const updateBinderItemsOrder = binderItems =>
  httpPut({
    route: '/api/binder-maintenance',
    errorMessage: 'Updating binder items order failed',
    body: { binderItems },
  });

export const fetchBinderForms = (globalContext, params = {}) =>
  httpGetAndParse({
    route: `/api/binder-maintenance/forms/${globalContext.getOverridenApiRouteParamString(
      params,
    )}?${stringify({
      areAllEntitiesInConsolidationQuery: params.areAllEntitiesInConsolidation,
    })}`,
    errorMessage: 'Fetching binder forms failed',
  });

export const fetchBinderAttachments = ({ binderId, taxYear, period, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/binder-maintenance/attachments/${binderId}/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Fetching binder attachments failed',
  });

export const deleteBinderItems = binderItemIds =>
  httpDelete({
    route: `/api/binder-maintenance`,
    errorMessage: 'Deleting binder items failed',
    body: binderItemIds,
  });

export const fetchBinderPdfLastGenerationDate = ({ binderId }) =>
  httpGetAndParse({
    route: `/api/binder-maintenance/binder-pdf-last-generation-date/${binderId}`,
    errorMessage: 'Fetching binder pdf last generation date failed',
  });

export const generateBinderPdf = ({ binderId }) =>
  httpPost({
    route: `/api/binder-maintenance/generate-binder-pdf/${binderId}`,
    errorMessage: 'Generating binder pdf failed',
  });

export const downloadBinderPdf = ({ binderId }) =>
  httpGetBlob({
    route: `/api/binder-maintenance/download-binder-pdf/${binderId}`,
    errorMessage: 'Downloading binder pdf failed',
  });
