import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@pwc/appkit-react';

import { FORMATTING_ERROR, InputDataKeys } from '../../constants';
import { DataTypeNames } from '../../enums';
import { OverridableFieldPropTypes } from '../overridableForm.propTypes';
import { HoverIcon, EFileValidationErrorIcon } from '../icons';
import useContextMenu from '../../../shared/hooks/useContextMenu.hook';
import FieldPopupMenu from '../fieldPopupMenu/fieldPopupMenu.component';
import useHover from '../../../shared/hooks/useHover.hook';
import useFocus from '../../../shared/hooks/useFocus.hook';
import { useMutationFormatDataValue } from '../../../shared/mutations/viewTaxReturn';
import { errorNotification } from '../../../shared/notification/store/actions';
import { getInputValueKey } from '../utils/getInputValueKey';

import OverridableFormCheckbox from './overridableFormCheckbox/overridableFormCheckbox.component';
import OverridableFormInput from './overridableFormInput/overridableFormInput.component';
import OverridableDatePicker from './overridableDatePicker/overridableDatePicker.component';
import OverridableFormSelect from './overridableFormSelect/overridableFormSelect.component';
import styles from './overridableFormInputWrapper.module.scss';

const getInputComponent = (dataType, selectionListId = null) => {
  if (selectionListId) {
    return OverridableFormSelect;
  }

  switch (dataType) {
    case DataTypeNames.Date: {
      return OverridableDatePicker;
    }
    case DataTypeNames.Boolean: {
      return OverridableFormCheckbox;
    }
    default: {
      return OverridableFormInput;
    }
  }
};

const OverridableFormInputWrapper = ({
  field,
  form,
  wrapperStyles,
  isUpdatingFormValues,
  setFocusedElementName,
  onGroupValueChange,
}) => {
  const dispatch = useDispatch();
  const inputWrapperRef = useRef(null);
  const { handleContextMenu, isContextMenuActive, menuPosition } = useContextMenu({
    disabled: isUpdatingFormValues,
    inputWrapperRef,
  });
  const { handleMouseEnter, handleMouseLeave, isHovered } = useHover({
    disabled: isUpdatingFormValues,
  });

  const {
    value,
    value: {
      dataType,
      checkboxGroupId,
      isOverridden,
      blockId,
      isEditable,
      mappingID,
      siblings = [],
      selectionListId,
      selectionListItems,
      eFileValidation,
    },
  } = field;

  const isGroupField = useMemo(
    () => dataType === DataTypeNames.Boolean && Boolean(checkboxGroupId),
    [checkboxGroupId, dataType],
  );

  const { setValues, values } = form;

  const formatDataValue = useMutationFormatDataValue();

  const displayFormattingError = useCallback(() => {
    dispatch(errorNotification(`Formatting ${blockId} failed.`));
  }, [dispatch, blockId]);

  const { isFocused, handleBlur, handleFocus } = useFocus({
    disabled: isUpdatingFormValues,
    setFocusedElementName,
  });

  const Component = useMemo(() => getInputComponent(dataType, selectionListId), [
    dataType,
    selectionListId,
  ]);

  const updateFields = useCallback(
    ({
      fieldsToUpdate,
      formattedFields,
      keyToSet,
      newValue,
      formattingError = false,
      additionalPropsToChange = {},
    }) => {
      const newValues = fieldsToUpdate.reduce(
        (acc, { blockId, mappingID }) => ({
          ...acc,
          [blockId]: {
            ...values[blockId],
            formattedData: formattedFields
              ? formattedFields[mappingID]
              : (formattingError && FORMATTING_ERROR) || newValue,
            [keyToSet]: newValue,
            ...additionalPropsToChange,
          },
        }),
        {},
      );

      setValues(previousValues => ({
        ...previousValues,
        ...newValues,
      }));
    },
    [setValues, values],
  );

  const callSingleFieldUpdateAndFormatting = useCallback(
    async ({ newValue, additionalPropsToChange, valueKey }) => {
      const keyToSet = valueKey || getInputValueKey({ isOverridden, isEditable });
      const fieldsToUpdate = [{ blockId, mappingID }, ...siblings];
      try {
        const formatDataValueResponse = await formatDataValue.mutateAsync(
          fieldsToUpdate.map(({ mappingID }) => ({
            mappingID,
            dataInstanceValue: newValue || '',
          })),
        );
        updateFields({
          fieldsToUpdate,
          formattedFields: formatDataValueResponse?.formattedFields,
          keyToSet,
          newValue,
          additionalPropsToChange,
        });
      } catch (error) {
        updateFields({
          fieldsToUpdate,
          keyToSet,
          newValue,
          formattingError: true,
          additionalPropsToChange,
        });
        displayFormattingError();
      }
    },
    [
      displayFormattingError,
      updateFields,
      formatDataValue,
      isOverridden,
      isEditable,
      blockId,
      mappingID,
      siblings,
    ],
  );

  const handleFieldUpdateAndFormatting = useCallback(
    ({ newValue, additionalPropsToChange, valueKey }) => {
      handleBlur();

      if (isGroupField) {
        if (onGroupValueChange) {
          onGroupValueChange({ newValue, additionalPropsToChange, valueKey }, blockId);
        }
      } else {
        callSingleFieldUpdateAndFormatting({
          newValue,
          additionalPropsToChange,
          valueKey,
        });
      }
    },
    [handleBlur, callSingleFieldUpdateAndFormatting, onGroupValueChange, isGroupField, blockId],
  );

  const handleSetOverride = useCallback(
    ({ overrideValue }) => {
      const newValues = [{ blockId, mappingID }, ...siblings].reduce(
        (acc, { blockId }) => ({
          ...acc,
          [blockId]: {
            ...values[blockId],
            isOverridden: true,
            overrideValue,
          },
        }),
        {},
      );
      setValues(previousValues => ({
        ...previousValues,
        ...newValues,
      }));
    },
    [setValues, blockId, mappingID, siblings, values],
  );

  const renderFormInputComponent = useMemo(
    () => (
      <Component
        options={selectionListItems}
        field={field}
        form={form}
        className={classNames({
          [styles.overridenField]: isOverridden,
          [styles.eFileValidationBaseField]: eFileValidation && dataType !== DataTypeNames.Boolean,
        })}
        disabled={isEditable ? false : !isOverridden}
        isFocused={isFocused}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        handleFieldUpdateAndFormatting={handleFieldUpdateAndFormatting}
        handleMouseLeave={handleMouseLeave}
        wrapperStyles={wrapperStyles}
        isContextMenuActive={isContextMenuActive}
      />
    ),
    [
      handleBlur,
      handleFocus,
      handleFieldUpdateAndFormatting,
      handleMouseLeave,
      selectionListItems,
      field,
      form,
      isOverridden,
      eFileValidation,
      dataType,
      isEditable,
      isFocused,
      wrapperStyles,
      isContextMenuActive,
    ],
  );

  const handleClick = useCallback(() => {
    if (isOverridden) {
      return handleFieldUpdateAndFormatting({
        newValue: value.defaultValue,
        valueKey: InputDataKeys.DefaultValue,
        additionalPropsToChange: {
          isOverridden: false,
          overrideValue: '',
          isClearingOverride: true,
          ...(value.printSuppress ? { formattedData: '' } : {}),
        },
      });
    }
    handleSetOverride({
      overrideValue: value.defaultValue,
    });
  }, [
    handleFieldUpdateAndFormatting,
    handleSetOverride,
    value.defaultValue,
    value.printSuppress,
    isOverridden,
  ]);

  return (
    <div
      ref={inputWrapperRef}
      style={wrapperStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onContextMenu={handleContextMenu}
      className={classNames(styles.fieldWrapper, {
        [styles.checkboxWrapper]: dataType === DataTypeNames.Boolean,
        [styles.overriddenInput]: isOverridden && dataType !== DataTypeNames.Boolean,
        [styles.overridableWrapper]: !eFileValidation,
        [styles.overridableWrapperEFileValidationError]: eFileValidation,
      })}
      id={blockId}
    >
      <>
        {eFileValidation?.description ? (
          <Tooltip
            content={eFileValidation.description}
            disabled={isContextMenuActive}
            placement="top"
            mouseEnterDelay={500}
            tooltipTheme="light"
            hasArrow
          >
            {renderFormInputComponent}
          </Tooltip>
        ) : (
          renderFormInputComponent
        )}
        {eFileValidation && <EFileValidationErrorIcon dataType={dataType} />}
        {isHovered && !eFileValidation && (
          <HoverIcon isOverridden={isOverridden} dataType={dataType} />
        )}
        {isContextMenuActive && (
          <FieldPopupMenu
            clickPosition={menuPosition}
            fieldValue={value}
            handleClick={handleClick}
            handleFieldUpdateAndFormatting={handleFieldUpdateAndFormatting}
            handleSetOverride={handleSetOverride}
          />
        )}
      </>
    </div>
  );
};

OverridableFormInputWrapper.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: OverridableFieldPropTypes.isRequired,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.objectOf(OverridableFieldPropTypes),
  }),
  wrapperStyles: PropTypes.shape(CSSStyleDeclaration).isRequired,
  isUpdatingFormValues: PropTypes.bool.isRequired,
  setFocusedElementName: PropTypes.func.isRequired,
  onGroupValueChange: PropTypes.func,
};

export default OverridableFormInputWrapper;
