import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';
import { GetRowIdParams } from 'ag-grid-community';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useGridWithEditForm from '../../shared/hooks/useGridWithEditForm.hook';
import { periodSelector, taxYearSelector } from '../../shared/store/selectors';
import { useQueryMaintenanceIndividuals } from '../../shared/queries/individualMaintenance';
import { useQueryStaticDataForForm } from '../../shared/queries/entitiesMaintenance';

import getIndividualsColumnDefinitions from './individualsMaintenance.columnDefinitions';
import IndividualForm from './individualForm.container';
import styles from './entityMaintenance.module.scss';

const getRowId = ({ data: { orgId } }: GetRowIdParams) => orgId;

const IndividualMaintenance = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const { data: formData } = useQueryStaticDataForForm({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });
  const columnDefinitions = useMemo(
    () => getIndividualsColumnDefinitions(formData?.jurisdictionOptions),
    [formData?.jurisdictionOptions],
  );

  const { data: individuals, isFetching: isFetchingIndividuals } = useQueryMaintenanceIndividuals({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });

  // Values for the form to add and edit individuals and entities
  const [individualToEdit, setIndividualToEdit] = useState(null);
  const { agGridProps, clearSelection } = useGridWithEditForm(
    individuals,
    setIndividualToEdit,
    getRowId,
  );

  return (
    <>
      <div className={styles.addButtonGroup}>
        <Button
          size="lg"
          className="add-button"
          disabled={!(taxYear && period)}
          onClick={clearSelection}
        >
          Add New Individual
        </Button>
      </div>
      <div className="grid-row h-100">
        <div className="col-8">
          <AgGrid
            rowData={individuals}
            isGridLoading={isFetchingIndividuals}
            columnDefs={columnDefinitions}
            {...agGridProps}
            withSearchBar
          />
        </div>
        <div className="col-4 right-column-edit-form">
          <IndividualForm
            entityToEdit={individualToEdit}
            entities={individuals}
            isFetchingEntities={isFetchingIndividuals}
          />
        </div>
      </div>
    </>
  );
};

export default IndividualMaintenance;
