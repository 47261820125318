import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@pwc/appkit-react';

import { periodSelector, taxYearSelector } from '../../shared/store/selectors';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import Loading from '../../shared/displayComponents/loading.component';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import Dropzone from '../../shared/forms/dropzone/dropzone.container';
import { useMutationUploadOwnershipAllocations } from '../../shared/mutations/ownershipAllocationsUpload';
import styles from '../shared/dataImportPage.module.scss';
import UploadStatus from '../uploadStatus/uploadStatus.component';
import { REPLACE_DATA, REJECT_OPTION_VALUE, DOCUMENT_ACCEPTED_TYPES } from '../constants';

import scopedStyles from './ownershipAllocationsUpload.module.scss';

const DATA_OPTIONS = [
  {
    label: 'Replace Data',
    value: REPLACE_DATA,
  },
];

const DUPLICATE_OPTIONS = [{ value: REJECT_OPTION_VALUE, label: 'Reject' }];

const OwnershipAllocationsUpload = () => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const [file, setFile] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [dataOption, setDataOption] = useState(REPLACE_DATA);
  const [duplicateOption, setDuplicateOption] = useState(REJECT_OPTION_VALUE);

  const {
    mutateAsync: uploadOwnershipAllocations,
    isLoading: isUploadingFile,
    data: triggeredJob,
  } = useMutationUploadOwnershipAllocations();

  const lastOwnershipAllocationsUploadJob = useSelector(({ jobs: { jobs } }) => {
    if (!triggeredJob) {
      const lastJob = jobs.find(({ jobType }) => jobType === 'ownership-allocations-upload');
      return lastJob || null;
    }
    const updatedTriggeredJobStatus = jobs.find(({ jobId }) => jobId === triggeredJob.jobId);
    return updatedTriggeredJobStatus || triggeredJob;
  });

  const onDrop = useCallback(file => {
    setFile(file);
    setIsFileLoading(true);
  }, []);

  const onFileBufferLoaded = useCallback(() => setIsFileLoading(false), []);

  const uploadFile = useCallback(() => {
    const shouldReplaceAccountData = dataOption === REPLACE_DATA;
    const shouldAggregateData = duplicateOption !== REJECT_OPTION_VALUE;
    const onDataUpload = async () => {
      await uploadOwnershipAllocations({
        taxYear,
        period,
        file,
        shouldAggregateData,
        shouldReplaceAccountData,
      });
      setFile(null);
    };

    if (shouldReplaceAccountData) {
      dispatch(
        showConfirmModal({
          title: 'Replace Data?',
          text:
            'This option will delete all existing K1 calc data overrides for tax year, period, entity and jurisdiction combinations!',
          confirmCallback: onDataUpload,
        }),
      );
      return;
    }
    onDataUpload();
  }, [uploadOwnershipAllocations, dispatch, dataOption, duplicateOption, taxYear, period, file]);

  const handleDataOptionsChange = useCallback(
    ({ value }) => {
      setDataOption(value);
    },
    [setDataOption],
  );

  const handleDuplicateOptionsChange = useCallback(
    ({ value }) => {
      setDuplicateOption(value);
    },
    [setDuplicateOption],
  );

  return (
    <div className={styles.uploadFormSection}>
      <div className="row">
        <div className={`col ${styles.fileFormat}`}>
          <p className={styles.fileFormatHeader}>File Format:</p>
          <p>org_id, partner_id, K1_calc_id, jurisdiction_id, beg_end_flag, amount</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Dropzone
            className={styles.dropzone}
            value={file}
            onDropCallback={onDrop}
            onFileBufferLoaded={onFileBufferLoaded}
            acceptTypes={DOCUMENT_ACCEPTED_TYPES}
            disabled={isFileLoading}
          />
        </div>
      </div>
      <div className={`row ${styles.uploadFormDropdown}`}>
        <div className="col">
          <SDKCustomSelect
            appkitLabel="Data Options"
            className="sdk-custom-select"
            options={DATA_OPTIONS}
            value={dataOption}
            onChange={handleDataOptionsChange}
            virtualized
          />
        </div>
        <div className="col">
          <SDKCustomSelect
            appkitLabel="Duplicate Options"
            className="sdk-custom-select"
            options={DUPLICATE_OPTIONS}
            value={duplicateOption}
            onChange={handleDuplicateOptionsChange}
            virtualized
          />
        </div>
        <div className="col">
          <Button
            size="lg"
            disabled={!file || isFileLoading || isUploadingFile}
            onClick={uploadFile}
          >
            Upload
          </Button>
        </div>
      </div>
      {(lastOwnershipAllocationsUploadJob || isUploadingFile) && (
        <div className={scopedStyles.uploadSection}>
          {isUploadingFile ? (
            <Loading isLoading />
          ) : (
            <UploadStatus job={lastOwnershipAllocationsUploadJob} />
          )}
        </div>
      )}
    </div>
  );
};

export default OwnershipAllocationsUpload;
