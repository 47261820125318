import React from 'react';
import get from 'lodash.get';

import { dateFormatter } from '../../shared/formatters';
import Loading from '../../shared/displayComponents/loading.component';
import config from '../../config';

export const getLastUpdateDate = ({ updatedOn, createdOn }) =>
  dateFormatter(updatedOn || createdOn, config.AMERICAN_DATE_TIME_FORMAT);

export const getActionStatus = (data, isSubmitting) => {
  if (isSubmitting) {
    return (
      <span>
        In Progress <Loading isLoading small />
      </span>
    );
  }

  if (!data) {
    return 'Not Started';
  }

  const message = get(data, 'ttiResponse.validation.status.message', null);

  return `${data.status.toLowerCase()}${message ? `, ${message}` : ''}`;
};
