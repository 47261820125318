import { connect } from 'react-redux';

import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import { isFetchingFilingGroupsSelector, filingGroupNameSelector } from '../store/selectors';

const FilingGroupDescriptionValue = connect(state => ({
  label: 'State Filing Group',
  value: filingGroupNameSelector(state),
  isBusy: isFetchingFilingGroupsSelector(state),
}))(GlobalContextValue);

export default FilingGroupDescriptionValue;
