import { httpGetAndParse, httpPost } from '../../utils/fetchService';

export const fetchConsolidationFilingAttributes = ({
  taxYear,
  period,
  consolidationId,
  jurisdictionId,
}) =>
  httpGetAndParse({
    route: `/api/filing-attributes/consolidation/${taxYear}/${period}/${consolidationId}/${jurisdictionId}`,
    errorMessage: 'Finding filing attributes failed',
  });

export const fetchEntityFilingAttributes = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/filing-attributes/entity/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Finding filing attributes failed',
  });

export const saveFilingAttributes = ({ taxYear, period, entityId, jurisdictionId, ...values }) =>
  httpPost({
    route: `/api/filing-attributes/${taxYear}/${period}/${entityId}/${jurisdictionId}`,
    errorMessage: 'Saving organization profile filing attributes failed',
    body: values,
  });
