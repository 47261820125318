import React, { useCallback } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { Input as ReadOnlyInput } from '@pwc/appkit-react/lib/Input';

import Input from '../shared/forms/inputFormik/inputFormik.component';
import Select from '../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import CheckBox from '../shared/forms/checkboxFormik/checkboxFormik.component';
import { SelectOptionPropTypes } from '../shared/forms/propTypes';

import { filingGroupPropTypes } from './propTypes';

const AddEditFilingGroupForm = ({
  values,
  jurisdictionsOptions,
  filingGroups,
  filingGroupToEdit,
}) => {
  const checkIsFilingGroupUnique = useCallback(
    filingGroupId => filingGroups.every(filingGroup => filingGroup.filingGroupId !== filingGroupId),
    [filingGroups],
  );

  const filingGroupIdValidation = useCallback(
    values => {
      if (!filingGroupToEdit && !checkIsFilingGroupUnique(values.filingGroupId)) {
        return 'The Filing Group Id already exists, a unique Id is required before saving';
      }
    },
    [checkIsFilingGroupUnique, filingGroupToEdit],
  );

  return (
    <form>
      <div className="modal-field">
        <ReadOnlyInput className="modal-field" label="Tax Year" value={values.taxYear} disabled />
      </div>
      <div className="modal-field">
        <ReadOnlyInput className="modal-field" label="Period" value={values.period} disabled />
      </div>
      <div className="modal-field">
        <Select
          appkitLabel="Jurisdiction"
          name="jurisdictionId"
          options={jurisdictionsOptions}
          value={values.jurisdictionId}
          showSearchOnList
        />
      </div>
      <Field
        label="State Filing Group"
        name="filingGroupId"
        className="modal-field"
        component={Input}
        autoComplete="off"
        validate={() => filingGroupIdValidation(values)}
        disabled={Boolean(filingGroupToEdit)}
      />
      <Field
        label="Description"
        name="description"
        className="modal-field"
        component={Input}
        autoComplete="off"
      />
      <div className="modal-field">
        <Field label="Return Task" name="returnTask" component={CheckBox} />
      </div>
      <div className="modal-field">
        <Field label="Separate Limitation" name="separateLimit" component={CheckBox} />
      </div>
      <div className="modal-field">
        <Field label="eFile" name="efile" component={CheckBox} />
      </div>
    </form>
  );
};

AddEditFilingGroupForm.propTypes = {
  values: PropTypes.shape({
    taxYear: PropTypes.string,
    period: PropTypes.string,
    filingGroupId: PropTypes.string,
    jurisdictionId: PropTypes.string,
    returnTask: PropTypes.bool,
    separateLimit: PropTypes.bool,
    efile: PropTypes.bool,
  }),
  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  filingGroups: PropTypes.arrayOf(filingGroupPropTypes).isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
  filingGroupToEdit: PropTypes.shape({
    filingGroupId: PropTypes.string.isRequired,
    jurisdictionId: PropTypes.string.isRequired,
    returnTask: PropTypes.bool.isRequired,
    separateLimit: PropTypes.bool.isRequired,
    efile: PropTypes.bool.isRequired,
  }),
};

export default AddEditFilingGroupForm;
