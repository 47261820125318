const Yup = require('yup');

const { notNegativeInteger } = require('../validationRegex');

const editTaxFormDetailsSchema = Yup.object().shape({
  formId: Yup.string().label('formId').required(),
  inactive: Yup.bool().label('Inactive').required(),
  taxYear: Yup.number().label('Tax Year').required(),
  formName: Yup.string().label('Form ID').required(),
  formDescription: Yup.string().label('Form Description').required(),
  swpFormId: Yup.string().when('taxYear', {
    is: taxYear => taxYear < 2020,
    then: Yup.string().required('SWP Form ID is a required field for TY 2019 and older'),
  }),
  swpCalcIteration: Yup.string()
    .label('SWP Calc Iterations')
    .required()
    .matches(notNegativeInteger.regex, notNegativeInteger.message),
  efileSupported: Yup.bool().label('E-file supported').required(),
});

const editTaxFormFieldSchema = Yup.object().shape({
  datasetId: Yup.string().label('Dataset').nullable(),
  dataItemId: Yup.string().when('datasetId', {
    is: value => Boolean(value),
    then: Yup.string().required('DataItem is required when Dataset is selected').nullable(),
    otherwise: Yup.string().nullable(),
  }),
  suppressCommas: Yup.bool().label('Suppress Commas'),
  decimalPlaces: Yup.number().max(9).min(0).label('Decimal Places'),
  appendText: Yup.string().max(20).label('Append Text').nullable(),
  parensIfNegative: Yup.bool().label('Negative()'),
  absoluteValue: Yup.bool().label('Absolute Value'),
  ratioAsPercentage: Yup.bool().label('Ratio As Percent'),
  wrapText: Yup.bool().label('Wrap Text').required(),
  wrapFirstRowIndt: Yup.number().label('First Row Indent Chrs').required(),
  wrapFullRowcount: Yup.number().label('Full Row Count').nullable(),
  characterSpacing: Yup.string()
    .matches(/^[0-9.,]*$/, 'Only numbers, dots and commas are allowed')
    .label('Character Spacing')
    .nullable(),
  fullfieldPattern: Yup.string().label('Fullfield Pattern').nullable(),
  fullfieldLength: Yup.number().label('Fullfield Length').nullable(),
  filterRegExp: Yup.string().max(100).label('Regular Expression Filter').nullable(),
  suppressFieldDatasetDefId: Yup.string()
    .min(32)
    .max(32)
    .label('Suppress Field DataSet')
    .nullable(),
  suppressFieldDataItemDefId: Yup.string().when('suppressFieldDatasetDefId', {
    is: value => Boolean(value),
    then: Yup.string()
      .min(32)
      .max(32)
      .required('Suppress Field Data Item is required when DataSet is selected')
      .nullable(),
    otherwise: Yup.string().min(32).max(32).nullable(),
  }),
  valueIfZero: Yup.number().label('Value If Zero'),
  wrapRowspacingIncrement: Yup.string().label('Row Spacing Increment').nullable(),
  printFirstCopyOnly: Yup.bool().label('Print First Copy Only').required(),
  printLastCopyOnly: Yup.bool().label('Print Last Copy Only').required(),
  upperCase: Yup.bool().label('Upper Case').required(),
  formattingString: Yup.string().label('Formatting String').nullable(),
  checkbox: Yup.string().label('Checkbox').nullable(),
  flipSign: Yup.bool().label('Flip Sign').required(),
});

const datasetMappingSchema = Yup.object().shape({
  instancesPerPage: Yup.string().label('Instances Per Page').required(),
  instToSkip: Yup.string().label('Instances to Skip').required(),
  overflowAction: Yup.string().label('Overflow Action').required(),
  uiLocationFieldName: Yup.string().max(100).label('Drill UI Field').nullable(),

  printOrientationLandscape: Yup.string().label('Orientation').nullable(),
  fontSize: Yup.string().label('Font Size').nullable(),
  pdfStmtRefFieldName: Yup.string().max(100).label('Stmt. Reference Field').nullable(),
  pageWidthPercentage: Yup.string().label('Page Width Percentage').nullable(),

  pdfAttachmentConfigId: Yup.string()
    .when('overflowAction', {
      is: 'Attachment',
      then: Yup.string().required(),
      otherwise: Yup.string().nullable(),
    })
    .label('Pdf Attachment Config Id'),
  id: Yup.string().required(),
});

const editOverflowActionsSchema = Yup.lazy(obj =>
  Yup.object(
    Object.keys(obj || {}).map(key =>
      Yup.object({
        [key]: datasetMappingSchema,
      }),
    ),
  ),
);

const attachmentColumnConfig = Yup.object().shape({
  columnDisplayOrder: Yup.number().label('Column Display Order').required(),
  fontSize: Yup.number().label('Font Size').required(),
  printColumnTotal: Yup.bool().label('Print Column Total').required(),
  printDisplay: Yup.bool().label('Print Display').required(),
  relativeWidth: Yup.number().label('Relative Width').required(),
  screenDisplay: Yup.bool().label('Screen Display').required(),

  columnId: Yup.string().label('Column').required(),
  pdfAttachmentConfigId: Yup.string().label('Pdf Attachment Config Id').required(),
  id: Yup.string().label('Id').required(),
});

const editAttachmentColumnProperties = Yup.lazy(obj =>
  Yup.object(
    Object.keys(obj || {}).map(key =>
      Yup.object({
        [key]: Yup.array().of(attachmentColumnConfig),
      }),
    ),
  ),
);

const taxFormUpdateSchema = Yup.object().shape({
  formDetails: editTaxFormDetailsSchema.nullable().default(null),
  fieldsToUpdate: Yup.array()
    .transform((_, originalValue) => Object.values(originalValue))
    .of(editTaxFormFieldSchema)
    .notRequired(),
  fieldsToUnmap: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      pdfFieldMappingId: Yup.string().required(),
    }),
  ),
  overflowActions: Yup.array()
    .transform((_, originalValue) => Object.values(originalValue))
    .of(datasetMappingSchema)
    .notRequired(),
});

const taxFormAPIUpdateSchema = Yup.object().shape({
  formDetails: editTaxFormDetailsSchema.nullable().default(null),
  fieldsToUpdate: Yup.lazy(obj =>
    Yup.object(
      Object.keys(obj || {}).map(key =>
        Yup.object({
          [key]: editTaxFormFieldSchema,
        }),
      ),
    ).nullable(),
  ),
  fieldsToUnmap: Yup.array().of(Yup.string()).nullable(),
  formPdfId: Yup.string().required(),
  datasetsToAssign: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        datasetId: Yup.string().required(),
        pageNumber: Yup.number().required(),
      }),
    )
    .required(),
  datasetsToUnassign: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
      }),
    )
    .required(),
  overflowActions: editOverflowActionsSchema,
  pdfAttachmentColumnProperties: editAttachmentColumnProperties,
});

const getTaxFormAttachmentSchema = ({ taxForms }) =>
  Yup.object().shape({
    formName: Yup.string()
      .label('Form Name')
      .required()
      .uniqueInList({
        message: 'There already is a tax form with this name for selected jurisdiction',
        list: taxForms,
        checkDuplicatesFunction({ formName, formId }, value) {
          // we need to use `this` to access `parent` property
          // eslint-disable-next-line babel/no-invalid-this
          return value === formName && this.parent.formId !== formId;
        },
      }),
    formDescription: Yup.string().label('Form Description').required(),
    swpFormId: Yup.string().when('taxYear', {
      is: taxYear => taxYear < 2020,
      then: Yup.string().required('SWP Form ID is a required field for TY 2019 and older'),
    }),
    taxYear: Yup.number().label('Tax Year').required(),
    jurisdictionId: Yup.string().label('Tax Year').required(),
    pdfLibPdfFileName: Yup.string().label('Lib Pdf File Name').nullable(),
  });

const taxFromAttachmentValidationSchema = Yup.object().shape({
  pdfLibPdfFileName: Yup.string().label('Lib Pdf File Name').required(),
});

module.exports = {
  editTaxFormDetailsSchema,
  editTaxFormFieldSchema,
  taxFormUpdateSchema,
  taxFormAPIUpdateSchema,
  getTaxFormAttachmentSchema,
  taxFromAttachmentValidationSchema,
  datasetMappingSchema,
};
