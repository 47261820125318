import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';
import { GlobalK1InfoData } from '../../../../common/types';

import { useCustomMutation } from '.';

interface UpdateGlobalK1InfoRequest {
  values: GlobalK1InfoData[];
  taxYear: string | null;
  period: string | null;
  businessEntityId: string | null;
  jurisdictionId: string | null;
}

export const useMutationUpdateGlobalK1Info = () =>
  useCustomMutation<null, QueryError, UpdateGlobalK1InfoRequest, GlobalK1InfoData[]>({
    handleData: ({
      taxYear,
      period,
      businessEntityId,
      values,
      jurisdictionId,
    }: UpdateGlobalK1InfoRequest) => ({
      url: `/api/entities/global-k1-information/${taxYear}/${period}/${businessEntityId}/${jurisdictionId}`,
      method: HttpMethods.PUT,
      body: values,
    }),
    resetKey: QueryKeys.GlobalK1Information.FormData,
    successMessage: 'Updating global k1 information data succeeded',
    errorMessage: 'Updating global k1 information data failed',
  });
