import { httpGetAndParse } from '../../../utils/fetchService';

export const fetchGenericSourceData = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  categoryId,
  accountId,
  componentId,
}) =>
  httpGetAndParse({
    route: `/api/shared/generic-source-data/${taxYear}/${period}/${orgId}/${jurisdictionId}/${categoryId}/${accountId}/${componentId}`,
    errorMessage: 'Fetching generic source data failed',
    parseResponseErrorMessage: true,
  });
