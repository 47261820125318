import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectEntity as selectOrgId, selectFilingType } from '../../shared/store/actions';
import { useQueryConsolidatedFilingEntities } from '../../shared/queries/consolidationFilingEntities';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  taxYearSelector,
  periodSelector,
  jurisdictionIdSelector,
  globalOrFirstConsolidationIdSelector,
} from '../../shared/store/selectors';

import getColumnDefinitions from './filingEntities.columnDefinitions';

const FilingEntities = () => {
  const dispatch = useDispatch();
  const handleSelectOrgId = useCallback(
    (orgId: string) => {
      dispatch(selectOrgId(orgId));
    },
    [dispatch],
  );
  const handleSelectFilingType = useCallback(
    (filingType: string) => {
      dispatch(selectFilingType(filingType));
    },
    [dispatch],
  );
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const consolidationId = useSelector(globalOrFirstConsolidationIdSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const { data, isFetching } = useQueryConsolidatedFilingEntities({
    enabled: Boolean(taxYear && period && consolidationId && jurisdictionId),
    params: {
      taxYear,
      period,
      consolidationId,
      jurisdictionId,
    },
  });
  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        selectOrgId: handleSelectOrgId,
        selectFilingType: handleSelectFilingType,
        taxYear,
        period,
      }),
    [handleSelectOrgId, handleSelectFilingType, taxYear, period],
  );

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={data}
          columnDefs={columnDefinitions}
          isGridLoading={isFetching}
          withSearchBar
        />
      </div>
    </div>
  );
};

export default FilingEntities;
