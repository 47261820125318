import { getApiActionTypes } from '../../shared/store/storeHelpers';

export const fetchProfilesOptionsTypes = getApiActionTypes(
  'FILING_DECISIONS/FETCH_PROFILES_OPTIONS',
);

export const fetchEntityFilingDecisionsTypes = getApiActionTypes(
  'FILING_DECISIONS/FETCH_ENTITY_FILING_DECISIONS',
);
export const updateEntityFilingDecisionsTypes = getApiActionTypes(
  'ENTITIES/UPDATE_ENTITY_FILING_DECISIONS',
);

export const fetchConsolidationFilingGroupsOptionsTypes = getApiActionTypes(
  'FILING_DECISIONS/FETCH_CONSOLIDATION_FILING_GROUPS_OPTIONS',
);
export const fetchConsolidationFilingDecisionsTypes = getApiActionTypes(
  'FILING_DECISIONS/FETCH_CONSOLIDATION_FILING_DECISIONS',
);
export const updateConsolidationFilingDecisionsTypes = getApiActionTypes(
  'ENTITIES/UPDATE_CONSOLIDATION_FILING_DECISIONS',
);

export const fetchFilingDecisionsAmountDetailsTypes = getApiActionTypes(
  'FILING_DECISIONS/FETCH_FILING_DECISIONS_AMOUNT_DETAILS',
);

export const CLEAR_NEXUS_NOTE = 'FILING_DECISIONS/CLEAR_NEXUS_NOTE';
export const createNexusNoteTypes = getApiActionTypes('FILING_DECISIONS/ADD_NEXUS_NOTE');
export const fetchNexusNoteTypes = getApiActionTypes('FILING_DECISIONS/FETCH_NEXUS_NOTE');
export const updateNexusNoteTypes = getApiActionTypes('FILING_DECISIONS/UPDATE_NEXUS_NOTE');
