import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../shared/store/reducerFactory';

import {
  fetchEFileStepsDataTypes,
  fetchValidationMessagesTypes,
  fetchSignatureDeclarationTypes,
  fetchFederalProformaSubmissionIdTypes,
  RESET_CONTEXT_SPECIFIC_WIZARDS_STATE,
} from './types';

export const initialState = {
  data: {
    lastSubmissionId: null,
    stepsData: [],
    overallStatus: null,
    persistedWizardFieldsValues: {
      persistedStateSubmissionType: null,
      persistedIncludeFederal: false,
      persistedFederalProformaSubmissionId: null,
      persistedSignatureValue: '',
      persistedIsNewYorkFormConfirmed: false,
    },
  },
  isFetchingStepsData: false,

  validationMessages: [],
  isFetchingValidationMessages: false,

  signatureDeclaration: null,
  isFetchingSignatureDeclaration: false,

  federalProformaSubmissionId: null,
  isFetchingFederalProformaSubmissionId: false,
};

const eFileReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchEFileStepsDataTypes,
      payloadKey: 'data',
      fetchingKey: 'isFetchingStepsData',
    }),
    /*
      When data is an empty array, a user is moved to the first step of the wizard.
      We need to override default behavior of cleaning data when starting a new request
      to keep the user on the same wizard's step when loading the newest data.
    */
    [fetchEFileStepsDataTypes.START]: state => ({
      ...state,
      isFetchingStepsData: true,
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchValidationMessagesTypes,
      payloadKey: 'validationMessages',
      fetchingKey: 'isFetchingValidationMessages',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchSignatureDeclarationTypes,
      payloadKey: 'signatureDeclaration',
      fetchingKey: 'isFetchingSignatureDeclaration',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFederalProformaSubmissionIdTypes,
      fetchingKey: 'isFetchingFederalProformaSubmissionId',
    }),
    [fetchFederalProformaSubmissionIdTypes.SUCCESS]: (state, { federalProformaSubmissionId }) => ({
      ...state,
      federalProformaSubmissionId,
      isFetchingFederalProformaSubmissionId: false,
    }),
    [RESET_CONTEXT_SPECIFIC_WIZARDS_STATE]: ({ caseList, yearSubPeriodList }) => ({
      ...initialState,
      caseList,
      yearSubPeriodList,
    }),
  },
});

export default eFileReducer;
