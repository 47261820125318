import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import getContextAwareUrlParams from '../../../utils/getContextAwareUrlParams';
import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import ParamDropdown from '../../displayComponents/paramDropdown/paramDropdown.component';
import { selectFilingType } from '../../store/actions';
import { filingTypeIdSelector, globalContextSelector } from '../../store/selectors';
import { filingTypesOptions, NotApplicableContextValue } from '../../constants';

const FilingTypeDropdown = ({ isDropdownBusy = false, isFetchingContext = false }) => {
  const dispatch = useDispatch();
  const { routeParams, routeUrl } = useUrlParams();

  const history = useHistory();
  const filingTypeId = useSelector(filingTypeIdSelector);
  const globalContext = useSelector(globalContextSelector);

  const handleSelect = useCallback(
    newFilingTypeId => {
      if (filingTypeId !== newFilingTypeId) {
        /**
         * If the filing type is changed we send the user back to the 'home' screen -
         * the only exception to this rule is if the current screen is the 'Current Return Filing' screen
         */
        if (newFilingTypeId === NotApplicableContextValue) {
          return;
        }
        if (!routeUrl.includes('current-return-filing')) {
          history.push(
            Routes.setupTaxReturns.compiledRoute(
              getContextAwareUrlParams({
                ...globalContext.params,
                businessEntityId: globalContext.entityId,
                filingTypeId: newFilingTypeId,
              }),
            ),
          );
        }
        dispatch(selectFilingType(newFilingTypeId));
      }
    },
    [dispatch, filingTypeId, routeUrl, history, globalContext.params, globalContext.entityId],
  );

  useEffect(() => {
    if (!filingTypesOptions.find(({ value }) => value === routeParams.filingTypeId)) {
      return;
    }
    if (filingTypeId !== routeParams.filingTypeId) {
      /**
       * If a user is in a screen with a filing type url param ('CONSOLIDATED' or 'SEPARATE') and
       * then navigates to a different filing type ('CONSOLIDATED' -> 'SEPARATE' or vice-versa
       * either by clicking the browser back button or on a link ) we take into account the
       * filing type url param and set it
       */
      dispatch(selectFilingType(routeParams.filingTypeId.toUpperCase()));
    }
  }, [dispatch, routeParams.filingTypeId, isDropdownBusy, filingTypeId]);

  return (
    <ParamDropdown
      label="Filing Type"
      value={filingTypeId}
      options={filingTypesOptions}
      handleChange={handleSelect}
      isBusy={isDropdownBusy}
      isFetchingContext={isFetchingContext}
    />
  );
};

FilingTypeDropdown.propTypes = {
  isDropdownBusy: PropTypes.bool,
  isFetchingContext: PropTypes.bool,
};

export default FilingTypeDropdown;
