import { CompileExpressionResponse } from '@tls/slt-types';

import { HttpMethods } from '../enums';
import { QueryError } from '../queries';

import { useCustomMutation } from '.';

interface CompileExpressionVariable {
  taxYear: string;
  jurisdictionId: string;
}

export const useMutationCompileExpression = () =>
  useCustomMutation<CompileExpressionResponse, QueryError, CompileExpressionVariable, undefined>({
    handleData: ({ taxYear, jurisdictionId }) => ({
      url: `/api/development/expression-builder/compile-expression/${taxYear}/${jurisdictionId}`,
      method: HttpMethods.POST,
    }),
    errorMessage: 'Expressions compilation failed',
    parseResponseAsJson: true,
  });
