import {
  RecentEntityRefreshesResponse,
  AllEntities,
  RefreshStatus,
  TaxYear,
  EntityId,
  Period,
  EntityInformationDetailsResponse,
  EntitySyncLogs,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export interface EntityInformationDetailsParams {
  entityId: EntityId | null;
  taxYear: TaxYear | null;
  reportingPeriodId: Period | null;
}

export const useQueryRecentEntityInformationRefreshes = ({
  taxYear,
  enabled,
}: {
  taxYear: TaxYear | null;
  enabled: boolean;
}) =>
  useCustomQuery<RecentEntityRefreshesResponse[]>(
    [QueryKeys.EntityInformationDiagnostics.RecentRefreshes],
    {
      url: `/api/admin/support/entity-information-diagnostics/recent/${taxYear}`,
      enabled,
      initialData: [],
      errorMessage: 'error fetching recent entity information refreshes',
    },
  );

export const useQueryFindAllEntities = ({
  taxYear,
  enabled,
}: {
  taxYear: TaxYear | null;
  enabled: boolean;
}) =>
  useCustomQuery<AllEntities[]>([QueryKeys.EntityInformationDiagnostics.AllEntities], {
    url: `/api/admin/support/entity-information-diagnostics/entities/${taxYear}`,
    enabled,
    initialData: [],
    errorMessage: 'error fetching all entities',
  });

export const useQueryStatusEntityInformationDiagnostics = ({
  taxYear,
  enabled,
}: {
  taxYear: TaxYear | null;
  enabled: boolean;
}) =>
  useCustomQuery<RefreshStatus>([QueryKeys.EntityInformationDiagnostics.Status], {
    url: `/api/admin/support/entity-information-diagnostics/status/${taxYear}`,
    enabled,
    initialData: {
      total: 0,
      inProgress: 0,
      completed: 0,
      unsuccessful: 0,
    },
    errorMessage: 'error fetching overall entity information refresh status',
  });

export const useQueryEntityInformationDetails = ({
  params,
  enabled,
}: {
  params: EntityInformationDetailsParams;
  enabled: boolean;
}) =>
  useCustomQuery<EntityInformationDetailsResponse>(
    [QueryKeys.EntityInformationDiagnostics.Details],
    {
      url: `/api/admin/support/entity-information-diagnostics/details/${params.entityId}/${params.taxYear}/${params.reportingPeriodId}`,
      enabled,
      errorMessage: 'error fetching entity information details',
    },
  );

export const useQueryDataSyncLogs = ({
  params,
  enabled,
}: {
  params: EntityInformationDetailsParams;
  enabled: boolean;
}) =>
  useCustomQuery<EntitySyncLogs>([QueryKeys.EntityInformationDiagnostics.DetailsLogs], {
    url: `/api/admin/support/entity-information-diagnostics/detailsLogs/${params.entityId}/${params.taxYear}/${params.reportingPeriodId}`,
    enabled,
    errorMessage: 'error fetching entity information details logs',
  });
