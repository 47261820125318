import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import ComingSoonPage from '../comingSoonPage/comingSoonPage.component';

import FilingEntities from './filingEntities/filingEntities.container';
import FilingGroups from './filingGroups/filingGroups.container';
import Consolidation from './consolidation.container';
import InformationalDataV2 from './informationalDataV2/informationalData.container';
import ManualAdjustmentReport from './manualAdjustmentReport/manualAdjustmentReport.container';
import consolidationInfo from './consolidationInfo/consolidationInfo.container';
import FilingEntitiesReturnWorkspaceRoutes from './filingEntities/filingEntitiesReturnWorkspaceRoutes.component';
import FilingGroupsDataProvider from './filingGroups/dataProviders/filingGroupsDataProvider.container';
import ConsolidatedReport from './consolidatedReport/consolidatedReport.container';
import { AuthorizedConsolidationRoute } from './routeConfiguration/consolidationRoute.component';
import JurisdictionContextValue from './routeConfiguration/jurisdictionContextValue.container';
import ConsolidationJurisdictionContextValue from './routeConfiguration/consolidationJurisdictionContextValue.container';
import ConsolidationContextValue from './routeConfiguration/consolidationContextValue.container';
import ParentOrgIdContextValue from './routeConfiguration/parentOrgIdContextValue.container';
import FilingGroupContextValue from './filingGroups/routeConfiguration/filingGroupContextValue.container';

const jurisdictionContextValue = <JurisdictionContextValue />;
const consolidationJurisdictionContextValue = <ConsolidationJurisdictionContextValue />;

const additionalValues = (
  <>
    <ParentOrgIdContextValue />
    {consolidationJurisdictionContextValue}
    <ConsolidationContextValue />
  </>
);

const ConsolidationRoutes = () => (
  <Switch>
    <AuthorizedConsolidationRoute
      exact
      path={Routes.consolidation.MAIN}
      additionalValues={additionalValues}
      component={Consolidation}
    />
    <AuthorizedConsolidationRoute
      exact
      path={Routes.consolidationFilingEntities.MAIN}
      additionalValues={additionalValues}
      component={FilingEntities}
    />
    <FilingGroupsDataProvider path={Routes.consolidationReturnWorkspace.MAIN}>
      <AuthorizedConsolidationRoute
        path={Routes.consolidationReturnWorkspace.MAIN}
        component={FilingEntitiesReturnWorkspaceRoutes}
        additionalValues={
          <>
            <ParentOrgIdContextValue />
            {jurisdictionContextValue}
            <FilingGroupContextValue />
          </>
        }
      />
    </FilingGroupsDataProvider>
    <FilingGroupsDataProvider exact path={Routes.consolidationFilingGroups.MAIN}>
      <AuthorizedConsolidationRoute
        exact
        path={Routes.consolidationFilingGroups.MAIN}
        additionalValues={additionalValues}
        component={FilingGroups}
      />
    </FilingGroupsDataProvider>
    <AuthorizedConsolidationRoute
      exact
      path={Routes.consolidatedReport.MAIN}
      additionalValues={additionalValues}
      component={ConsolidatedReport}
    />
    <AuthorizedConsolidationRoute
      exact
      path={Routes.informationalDataV2.MAIN}
      additionalValues={additionalValues}
      component={InformationalDataV2}
    />
    <AuthorizedConsolidationRoute
      exact
      path={Routes.manualAdjustmentReport.MAIN}
      additionalValues={additionalValues}
      component={ManualAdjustmentReport}
    />
    <AuthorizedConsolidationRoute
      exact
      path={Routes.consolidationInfo.MAIN}
      additionalValues={additionalValues}
      component={consolidationInfo}
    />
    <AuthorizedConsolidationRoute
      exact
      path={Routes.federalDetailsByLineNumberAndEntity.MAIN}
      component={ComingSoonPage} // TODO: SLT-78
    />
  </Switch>
);

export default ConsolidationRoutes;
