import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  globalContextSelector,
  jurisdictionDescriptionSelector,
  isGlobalContextReadySelector,
} from '../../../../shared/store/selectors';
import { useQueryGetSubmissionFiles } from '../../../../shared/queries/eFileV2';
import {
  FederalReturnOption,
  Link,
  GlobalContext,
  BusinessEntityId,
  FederalSubmissionId,
  SubmissionId,
  JurisdictionDescription,
} from '../../../../../../common/types';
import { generateStateFileUrl } from '../../utils/generateFileUrls';
import { getFederalSubmissions } from '../utils';

interface EFileReviewContext {
  isLoading: boolean;
  isMissingFederalReturn: boolean;
  isSeparate: boolean;
  jurisdictionDescription: JurisdictionDescription | null;
  businessEntityId: BusinessEntityId | null;
  federalSubmissions: Link[];
  federalSubmissionId: FederalSubmissionId | null;
  stateSubmissions: Link[];
  stateSubmissionId: SubmissionId | null;
}

/*
 * This hook delivers a component context, based upon global context.
 * Cases are now hardcoded (based upon test cases in ticket https://jira.ist.pwc.com/browse/SLT-10591). We need to rewrite it,
 * once we will connect it to the backend
 */
const useReviewStepContext = ({
  stateSubmissionId,
  federalSubmissionId,
  federalReturnOption,
  isFetchingTaxReturnOrTransmitterProviderTR,
}: {
  stateSubmissionId: string | null;
  federalSubmissionId: string | null;
  federalReturnOption: FederalReturnOption | null;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
}): EFileReviewContext => {
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const isGlobalContextReady =
    useSelector(isGlobalContextReadySelector) &&
    Boolean(isFetchingTaxReturnOrTransmitterProviderTR || stateSubmissionId);

  const { returnId } = useParams<{ returnId?: string }>();

  const { data, isFetching } = useQueryGetSubmissionFiles({
    params: {
      globalContext,
      stateSubmissionId,
      federalSubmissionId,
      federalReturnOption,
      returnId: Number(returnId),
      binderFilingId: null,
    },
    enabled: isGlobalContextReady,
  });

  return {
    isMissingFederalReturn: data?.federalFiles.length === 0,
    jurisdictionDescription,
    isSeparate: globalContext.isSeparate,
    businessEntityId: globalContext.params.businessEntityId,
    isLoading: !isGlobalContextReady || isFetching,
    federalSubmissions:
      data && federalReturnOption && globalContext.params.taxYear
        ? getFederalSubmissions({
            submissionFiles: data,
            federalReturnOption,
            taxYear: globalContext.params.taxYear || '', // this is just to satisfy ts, above we only go inhere if value is non-null
          })
        : [],
    stateSubmissions: data
      ? data.stateFiles.map(({ fileName, id, path, fileLocator, downloadFileName }) => ({
          label: fileName,
          url: generateStateFileUrl({
            id,
            path,
          }),
          fileLocator,
          fileName: downloadFileName,
        }))
      : [],
    federalSubmissionId,
    stateSubmissionId,
  };
};

export default useReviewStepContext;
