import { defaultColumnDefinition } from '../shared/columnDefinitions';
import StateOverrideRendererFramework from '../shared/displayComponents/stateOverrideRenderer/stateOverrideRendererFramework.component';
import EditorSelectorFactory from '../shared/displayComponents/editorSelector/editorSelector.factory';

const getColumnDefinitions = ({ isInEditMode, updateRow }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Page No',
      field: 'logicalPageNo',
      width: 70,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'infoAcctId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'accountDescription',
      width: 500,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Global Value',
      field: 'evValue',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'State Override',
      field: 'value',
      width: 200,
      editable: isInEditMode,
      cellRenderer: StateOverrideRendererFramework,
      cellEditorSelector: EditorSelectorFactory(),
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Display Value',
      field: 'dispValue',
      width: 200,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
