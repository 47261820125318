import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  taxYearSelector,
  periodSelector,
  consolidationIdSelector,
  jurisdictionIdSelector,
} from '../../shared/store/selectors';

import { fetchManualAdjustmentReportData } from './store/actions';
import {
  manualAdjustmentReportDataSelector,
  isFetchingManualAdjustmentReportDataSelector,
} from './store/selectors';
import columnDefinitions from './manualAdjustmentReport.columnDefinitions';
import styles from './manualAdjustmentReport.module.scss';

const ManualAdjustmentReport = ({
  taxYear,
  period,
  consolidationId,
  jurisdictionId,
  manualAdjustmentReportData,
  isFetchingManualAdjustmentReportData,
  fetchManualAdjustmentReportData,
}) => {
  const isContextReady = taxYear && period && jurisdictionId && consolidationId;

  useEffect(() => {
    if (isContextReady) {
      fetchManualAdjustmentReportData({ taxYear, period, jurisdictionId, consolidationId });
    }
  }, [
    fetchManualAdjustmentReportData,
    isContextReady,
    taxYear,
    period,
    jurisdictionId,
    consolidationId,
  ]);

  return (
    <div className={`row grid-row ${styles.row}`}>
      <div className="col">
        <AgGrid
          rowData={manualAdjustmentReportData}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingManualAdjustmentReportData}
        />
      </div>
    </div>
  );
};

ManualAdjustmentReport.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  jurisdictionId: PropTypes.string,
  consolidationId: PropTypes.string,
  manualAdjustmentReportData: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
      accountId: PropTypes.string.isRequired,
      amt: PropTypes.number.isRequired,
    }),
  ),
  isFetchingManualAdjustmentReportData: PropTypes.bool.isRequired,
  fetchManualAdjustmentReportData: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    consolidationId: consolidationIdSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    manualAdjustmentReportData: manualAdjustmentReportDataSelector(state),
    isFetchingManualAdjustmentReportData: isFetchingManualAdjustmentReportDataSelector(state),
  }),
  {
    fetchManualAdjustmentReportData,
  },
)(ManualAdjustmentReport);
