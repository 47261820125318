import { connect } from 'react-redux';

import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import {
  consolidationNameSelector,
  isFetchingConsolidationsSelector,
} from '../../shared/store/selectors';

export default connect(
  state => ({
    label: 'Consolidation',
    value: consolidationNameSelector(state),
    isBusy: isFetchingConsolidationsSelector(state),
  }),
  null,
)(GlobalContextValue);
