import { saveAs } from 'file-saver';
import camelcase from 'lodash.camelcase';

const saveReportDefinitionFile = ({
  name,
  trgId,
  query,
  queryParams,
  reportType,
  chart,
  serverSide,
  defaultOrderBy,
}) => {
  const reportDefinition = {
    name,
    trgId,
    query,
    queryParams,
    reportType,
    chart,
    serverSide,
    defaultOrderBy,
  };

  const data = new Blob([JSON.stringify(reportDefinition, null, 2)]);
  const fileName = `${camelcase(name)}.json`;
  saveAs(data, fileName);
};

export default saveReportDefinitionFile;
