import { apiAction } from '../apiAction';

import {
  fetchConstantsTypes,
  createConstantTypes,
  updateConstantTypes,
  deleteConstantsTypes,
  SELECT_CONSTANT_TYPE,
  SELECT_CONSTANTS_PERIOD,
} from './types';
import * as api from './api';

export const fetchConstants = apiAction({
  types: fetchConstantsTypes,
  apiAction: api.fetchConstants,
});

export const createConstant = apiAction(
  {
    types: createConstantTypes,
    apiAction: api.createConstant,
  },
  {
    successNotificationMessage: () => 'Constant has been successfully created',
  },
);

export const updateConstant = apiAction(
  {
    types: updateConstantTypes,
    apiAction: api.updateConstant,
  },
  {
    successNotificationMessage: () => 'Constant has been successfully updated',
  },
);

export const deleteConstant = apiAction(
  {
    types: deleteConstantsTypes,
    apiAction: api.deleteConstant,
  },
  { successNotificationMessage: () => 'Constant successfully deleted' },
);

export const selectConstantType = constantType => ({
  type: SELECT_CONSTANT_TYPE,
  payload: constantType,
});

export const selectConstantsPeriod = constantsPeriod => ({
  type: SELECT_CONSTANTS_PERIOD,
  payload: constantsPeriod,
});
