import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

import { isSomeDataItemUsedInGfunction } from '../utils';
import { dataItemsPropTypes } from '../propTypes';

import styles from './warningDeleteDatasetModal.module.scss';

const WarningDeleteDatasetModal = ({
  hideModal,
  visible,
  modalData: datasetWithChildDatasets = [],
}) => (
  <Modal
    className={styles.deleteDatasetModal}
    title="An error occurred while deleting data"
    dismissText="Ok"
    dismissAction={hideModal}
    closeAction={hideModal}
    visible={visible}
  >
    {datasetWithChildDatasets?.map(({ id: datasetId, name, dataItems, forms }) => (
      <React.Fragment key={datasetId}>
        {Boolean(forms || isSomeDataItemUsedInGfunction(dataItems)) && (
          <strong>{`This dataset "${name}" can't be deleted:`}</strong>
        )}
        {forms && <p>{`It is mapped to the following forms: ${forms}`}</p>}
        {isSomeDataItemUsedInGfunction(dataItems) && <p>It has data items used in a Gfunction:</p>}
        <div className={styles.dataItemsUsedInGfunctions}>
          {dataItems.map(({ id: dataItemId, name, gfunctions }) =>
            gfunctions.length ? (
              <React.Fragment key={dataItemId}>
                <p>{name}</p>
                <div>
                  {gfunctions.map(({ description }) => (
                    <p className="mb-0" key={`${dataItemId}${description}`}>
                      {description}
                    </p>
                  ))}
                </div>
              </React.Fragment>
            ) : null,
          )}
        </div>
      </React.Fragment>
    ))}
  </Modal>
);

WarningDeleteDatasetModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  modalData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      forms: PropTypes.string,
      dataItems: dataItemsPropTypes,
    }),
  ),
};

export default WarningDeleteDatasetModal;
