import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText, Collapse, Tooltip } from '@pwc/appkit-react';
import Spinner from '@tls/ui-spinner';
import classNames from 'classnames';

import { ROOT_NAVIGATOR_TAB } from '../../shared/constants';
import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { reduceQueryString } from '../../utils/routerUtils';
import ReturnsTree from '../returnsTree/returnsTree.container';
import { activeTabSelector } from '../store';

import AttachmentPreview from './attachmentPreview.container';
import styles from './tree.module.scss';

const dynamicListItems = {
  AttachmentPreview,
};

const Item = ({ item, ...props }) => {
  const {
    itemsLoading,
    showAttachmentModal,
    renderTree,
    applyPermissions,
    defaultOpen,
    setLocalStorage,
  } = props;
  const activeTab = useSelector(activeTabSelector);
  const [isOpen, toggleOpen] = useState(item.isOpen ?? defaultOpen);

  const toggle = useCallback(
    event => {
      event.stopPropagation();
      toggleOpen(value => !value);

      if (activeTab === ROOT_NAVIGATOR_TAB.NAVIGATOR) {
        setLocalStorage(item);
      }
    },
    [activeTab, item, setLocalStorage, toggleOpen],
  );

  const isActive = useCallback(isActive => () => isActive, []);

  if (item.type === 'folder') {
    return (
      <>
        <ListItem
          className={classNames({
            [styles.listItem]: true,
            [styles.activeFolder]: item.isActive,
          })}
          onClick={toggle}
        >
          <AppkitIcon
            className={styles.listIcon}
            icon={isOpen ? 'folder-opened' : 'folder-closed'}
            size={14}
            onClick={null}
          />
          <ListItemText primary={item.name} />
          {itemsLoading[item.loadingKey] && (
            <i className={styles.listIcon}>
              <Spinner small />
            </i>
          )}
        </ListItem>
        <Collapse isOpen={isOpen}>
          {item.children.map((child, i) =>
            child.isDisabled ? (
              <Tooltip
                key={i}
                content={child?.disabledToolTipContent || 'Navigation is disabled'}
                placement="top"
              >
                <Item key={i} item={child} {...props} />
              </Tooltip>
            ) : (
              <Item key={i} item={child} {...props} />
            ),
          )}
        </Collapse>
      </>
    );
  }

  if (item.type === 'binder') {
    return (
      <>
        <ListItem key={item.id} className={styles.listItem} onClick={toggle}>
          <NavLink
            className={`${styles.listItemNavLink} d-flex align-items-center`}
            exact
            to={{ pathname: item.route }}
          >
            <AppkitIcon
              className={`${styles.listItemIcon} mr-2`}
              type="outline"
              icon={isOpen ? 'down-chevron-circle' : 'right-chevron-circle'}
              size={14}
            />
            <ListItemText primary={item.name} />
          </NavLink>
        </ListItem>
        <Collapse isOpen={isOpen}>
          <ReturnsTree
            binder={{ value: item.id, label: item.name }}
            applyPermissions={applyPermissions}
            renderTree={renderTree}
            isOpen={isOpen}
          />
        </Collapse>
      </>
    );
  }

  if (item.isDisabled) {
    return (
      <ListItem className={styles.listItemDisabled}>
        <AppkitIcon className={styles.listIcon} icon="right-chevron-circle" size={14} />
        <ListItemText primary={item.name} />
      </ListItem>
    );
  }

  const url = item.route.compiledRoute
    ? item.route.compiledRoute(item.routeParams)
    : item.route.MAIN;
  const queryString = item.routeQueryString ? reduceQueryString(item.routeQueryString) : '';

  if (item.type === 'component') {
    const TagName = dynamicListItems[item.component];

    switch (item.component) {
      case 'AttachmentPreview':
        return <AttachmentPreview showAttachmentModal={showAttachmentModal} {...item.props} />;
      default:
        return <TagName {...item.props} />;
    }
  }

  return (
    <ListItem className={styles.listItem} onClick={toggle}>
      <AppkitIcon
        className={styles.listIcon}
        icon="right-chevron-circle"
        size={14}
        onClick={null}
      />
      <NavLink
        className={styles.listItemNavLink}
        exact
        to={{
          pathname: url,
          search: queryString,
        }}
        isActive={isActive(item.isActive)}
      >
        <ListItemText primary={item.name} />
      </NavLink>
    </ListItem>
  );
};

const itemShape = {
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
};
itemShape.children = PropTypes.arrayOf(PropTypes.shape(itemShape).isRequired); // Recursive propType requires mutation

export const ItemPropTypes = PropTypes.shape(itemShape).isRequired;

Item.defaultProps = {
  itemsLoading: {},
  showAttachmentModal: () => null,
  applyPermissions: () => null,
  renderTree: () => null,
  defaultOpen: true,
};

Item.propTypes = {
  item: ItemPropTypes,
  showAttachmentModal: PropTypes.func,
  applyPermissions: PropTypes.func,
  renderTree: PropTypes.func,
  defaultOpen: PropTypes.bool,
  itemsLoading: PropTypes.object, // eslint-disable-line
  setLocalStorage: PropTypes.func,
};

export default Item;
