import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchUsersTypes,
  updateUserTypes,
  createUserTypes,
  deleteUserTypes,
  validateUserEmailTypes,
  RESET_VALIDATION_RESULT,
  fetchBusinessesTypes,
  updateUserBusinessesTypes,
  RESET_BUSINESSES,
  fetchRolesTypes,
} from './types';

export const fetchUsers = apiAction({
  types: fetchUsersTypes,
  apiAction: api.fetchUsers,
});

export const validateUserEmail = apiAction({
  types: validateUserEmailTypes,
  apiAction: api.validateUserEmail,
});

export const updateUser = apiAction(
  {
    types: updateUserTypes,
    apiAction: api.updateUser,
  },
  { successNotificationMessage: () => 'User has been successfully updated' },
);

export const createUser = apiAction(
  {
    types: createUserTypes,
    apiAction: api.createUser,
  },
  { successNotificationMessage: () => 'User has been successfully created' },
);

export const deleteUser = apiAction(
  {
    types: deleteUserTypes,
    apiAction: api.deleteUser,
  },
  { successNotificationMessage: () => 'User has been deleted' },
);

export const resetValidationResult = () => ({
  type: RESET_VALIDATION_RESULT,
});

export const fetchBusinesses = apiAction({
  types: fetchBusinessesTypes,
  apiAction: api.fetchBusinesses,
});

export const updateUserBusinesses = apiAction(
  {
    types: updateUserBusinessesTypes,
    apiAction: api.updateUserBusinesses,
  },
  { successNotificationMessage: () => 'User businesses has been successfully updated' },
);

export const resetBusinesses = () => ({
  type: RESET_BUSINESSES,
});

export const fetchRoles = apiAction({
  types: fetchRolesTypes,
  apiAction: api.fetchRoles,
});
