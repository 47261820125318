import { createSelector } from 'reselect';

export const isFetchingFilingMembersSelector = ({ stateFilingGroups }) =>
  stateFilingGroups.filingMembers.isFetchingFilingMembers;

export const filingMembersSelector = createSelector(
  [({ stateFilingGroups }) => stateFilingGroups.filingMembers.filingMembers],
  filingGroups =>
    filingGroups.map((filingGroup, rowId) => ({
      ...filingGroup,
      rowId,
    })),
);

export const isUpdatingFilingMembersSelector = ({ stateFilingGroups }) =>
  stateFilingGroups.filingMembers.isUpdatingFilingMembers;
