import { httpGetAndParse, httpPut, httpGetBlob, httpPostFile } from '../../../utils/fetchService';

export const fetchPdfAttachments = ({ taxYear, period, entityId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/pdf-attachments/${taxYear}/${period}/${entityId}/${jurisdictionId}`,
    errorMessage: 'Fetching pdf attachments failed',
  });

export const fetchPdfImage = ({ taxYear, period, entityId, jurisdictionId, documentId }) =>
  httpGetBlob({
    route: `/api/shared/pdf-attachments/${taxYear}/${period}/${entityId}/${jurisdictionId}/${documentId}`,
    errorMessage: 'Fetching pdf attachment failed',
  });

export const updateOrDeletePdfAttachments = ({
  taxYear,
  period,
  entityId,
  jurisdictionId,
  rowsToUpdate,
  rowsToDelete,
}) =>
  httpPut({
    route: `/api/shared/pdf-attachments/${taxYear}/${period}/${entityId}/${jurisdictionId}`,
    errorMessage: 'Updating PDF attachments failed',
    body: { rowsToUpdate, rowsToDelete },
  });

export const createPdfAttachment = ({ formData }) =>
  httpPostFile({
    route: '/api/shared/pdf-attachments',
    errorMessage: 'Creating pdf attachment failed',
    body: formData,
  });

export const fetchBinderAttachment = ({ id }) =>
  httpGetBlob({
    route: `/api/shared/binder/pdf-attachments/${id}`,
    errorMessage: 'Fetching pdf attachment failed',
  });
