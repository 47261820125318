import { getApiActionTypes } from '../../shared/store/storeHelpers';

export const fetchBinderItemsTypes = getApiActionTypes('BINDER_MAINTENANCE/FETCH_BINDER_ITEMS');
export const updateBinderItemsTypes = getApiActionTypes('BINDER_MAINTENANCE/UPDATE_BINDER_ITEMS');
export const updateBinderItemsOrderTypes = getApiActionTypes(
  'BINDER_MAINTENANCE/UPDATE_BINDER_ITEMS_ORDER',
);
export const fetchBinderFormsTypes = getApiActionTypes('BINDER_MAINTENANCE/FETCH_BINDER_FORMS');
export const fetchBinderAttachmentsTypes = getApiActionTypes(
  'BINDER_MAINTENANCE/FETCH_BINDER_ATTACHMENTS',
);
export const deleteBinderItemsTypes = getApiActionTypes('BINDER_MAINTENANCE/DELETE_BINDER_ITEMS');
export const copyBinderTypes = getApiActionTypes('BINDER_MAINTENANCE/COPY_BINDER');
export const fetchBinderPdfLastGenerationDateTypes = getApiActionTypes(
  'BINDER_MAINTENANCE/FETCH_BINDER_PDF_LAST_GENERATION_DATE',
);
export const startProcessToGenerateBinderPdfTypes = getApiActionTypes(
  'BINDER_MAINTENANCE/START_PROCESS_TO_GENERATE_BINDER_PDF',
);
export const downloadBinderPdfTypes = getApiActionTypes('BINDER_MAINTENANCE/DOWNLOAD_BINDER_PDF');
export const RESET_COPIED_BINDER_TYPE = 'BINDER_MAINTENANCE/RESET_COPIED_BINDER_ID';
