import { SelectOption } from '@tls/slt-types';

interface GenerateExpressionProps {
  dataset?: {
    id: string;
    name: string;
  } | null;
  expressionCalculationDataset?: SelectOption | null;
  dataItemName?: string | null;
}

const generateExpression = ({
  dataset,
  expressionCalculationDataset,
  dataItemName,
}: GenerateExpressionProps) => {
  if (!dataItemName || !expressionCalculationDataset?.value || !dataset?.id) {
    return;
  }

  if (dataset.id !== expressionCalculationDataset.value) {
    return `${dataset.name}/${dataItemName}`;
  }

  return dataItemName;
};

export default generateExpression;
