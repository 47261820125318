import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';

import { globalContextSelector } from '../../shared/store/selectors';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { QueryKeys } from '../../shared/queries';
import { useQueryEFileErrors } from '../../shared/queries/eFile';

import columnDefinitions from './eFileErrors.columnDefinitions';
import styles from './eFileErrors.module.scss';

const WarningTypes = {
  ERROR: 'Error',
  WARNING: 'Warning',
};

const getSearchBarComponent = warningType => <h5>{warningType}</h5>;

const EFileErrors = () => {
  const queryClient = useQueryClient();
  const globalContext = useSelector(globalContextSelector);

  const { isReady: isGlobalContextReady } = globalContext;

  const {
    data: transmissionErrors,
    isFetching: isFetchingEFileTranmissionErrors,
  } = useQueryEFileErrors({
    params: { globalContext },
    enabled: isGlobalContextReady,
  });

  const onRefreshClick = useCallback(() => {
    queryClient.resetQueries(QueryKeys.EFile.TransmissionErrors);
  }, [queryClient]);

  const errorRowData = transmissionErrors.filter(
    message => message.ValidationType === WarningTypes.ERROR,
  );

  const warningRowData = transmissionErrors.filter(
    message => message.ValidationType === WarningTypes.WARNING,
  );

  return (
    <>
      <div className="row">
        <div className="col add-button-column">
          <Button
            size="lg"
            className="add-button"
            onClick={onRefreshClick}
            disabled={!isGlobalContextReady || isFetchingEFileTranmissionErrors}
          >
            Refresh
          </Button>
        </div>
      </div>

      <div className={styles.gridsContainer}>
        <div className="row grid-row">
          <div className="col">
            <AgGrid
              componentToRenderOnSearchBar={getSearchBarComponent('Errors')}
              rowData={errorRowData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingEFileTranmissionErrors}
              withSearchBar
            />
          </div>
        </div>
        <br />
        <div className="row grid-row">
          <div className="col">
            <AgGrid
              componentToRenderOnSearchBar={getSearchBarComponent('Warnings')}
              rowData={warningRowData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingEFileTranmissionErrors}
              withSearchBar
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EFileErrors;
