const Yup = require('yup');

const updateFilingDecisionForConsolidationAndFilingGroupSchema = Yup.object()
  .shape({
    rowsToUpdate: Yup.array()
      .of(
        Yup.object().shape({
          orgId: Yup.string().required(),
          oldProfileId: Yup.string().required(),
          nexusInd: Yup.bool().required(),
          processInd: Yup.string().required(),
          newProfileId: Yup.string().required(),
          oldFinalFilingGroupId: Yup.string().required(),
          newFinalFilingGroupId: Yup.string().required(),
          incomeTaxYn: Yup.bool().required(),
          franchiseTaxYn: Yup.bool().required(),
        }),
      )
      .notOneOf([undefined, null]), // eslint-disable-line no-undefined
  })
  .strict();

const updateFilingDecisionForEntitySchema = Yup.object()
  .shape({
    rowsToUpdate: Yup.array()
      .of(
        Yup.object().shape({
          jurisdictionId: Yup.string().required(),
          oldProfileId: Yup.string().required(),
          newProfileId: Yup.string().required(),
          oldFinalFilingGroupId: Yup.string().nullable(),
          newFinalFilingGroupId: Yup.string().nullable(),
          incomeTaxYn: Yup.bool().required(),
          franchiseTaxYn: Yup.bool().required(),
          nexusIndicatorYn: Yup.bool().required(),
        }),
      )
      .notOneOf([undefined, null]), // eslint-disable-line no-undefined
  })
  .strict();

module.exports = {
  updateFilingDecisionForConsolidationAndFilingGroupSchema,
  updateFilingDecisionForEntitySchema,
};
