import React, { useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '@tls/ui-modal';
import { useQueryClient } from 'react-query';

import AgGrid from '../../../displayComponents/agGrid/agGrid.component';
import { globalContextSelector } from '../../../store/selectors';
import { useRowEditMode } from '../../../editMode';
import { QueryKeys } from '../../../queries';
import { useQueryDatasetInstances } from '../../../queries/dataModels';
import { useMutationUpdateDatasetInstancesSortOrder } from '../../../mutations/dataModels';

import getColumnDefinitions from './reorderDatasetInstances.columnDefinitions';
import styles from './reorderModal.module.scss';

const getUniqueRowId = ({ data: { datasetInstId } }) => datasetInstId;
const noop = () => null;

const ReorderDatasetInstancesModal = ({
  hideModal,
  visible: isReorderDatasetInstancesModalVisible,

  datasetDefinitionId,

  refetchParentGrid = noop,
}) => {
  const queryClient = useQueryClient();
  const globalContext = useSelector(globalContextSelector);
  const [hasUserClickedSaveOnlyButton, setHasUserClickedSaveOnlyButton] = useState(false);

  const { isReady: isGlobalContextReady } = globalContext;

  const {
    data: datasetInstances,
    isFetching: isFetchingDatasetInstances,
  } = useQueryDatasetInstances({
    params: { globalContext, datasetDefinitionId },
    enabled: isGlobalContextReady && isReorderDatasetInstancesModalVisible,
  });

  const {
    mutateAsync: updateDatasetInstancesSortOrder,
    isLoading: isUpdatingDatasetInstancesSortOrder,
  } = useMutationUpdateDatasetInstancesSortOrder();

  useEffect(() => {
    if (hasUserClickedSaveOnlyButton && !isReorderDatasetInstancesModalVisible) {
      setHasUserClickedSaveOnlyButton(false);
      refetchParentGrid();
    }
    if (isGlobalContextReady && isReorderDatasetInstancesModalVisible) {
      queryClient.resetQueries(QueryKeys.DataModels.DatasetInstances);
    }
  }, [
    hasUserClickedSaveOnlyButton,
    isGlobalContextReady,
    isReorderDatasetInstancesModalVisible,
    queryClient,
    refetchParentGrid,
  ]);

  const rowData = useMemo(() => datasetInstances || [], [datasetInstances]);

  const { navigationPrompt, updateRow, clonedRowData, onGridReady } = useRowEditMode({
    rowData,
    getUniqueRowId,
    isPermanentlyInEditMode: true,
  });

  const handleSave = useCallback(async () => {
    await updateDatasetInstancesSortOrder({ datasetInstances: clonedRowData });
    setHasUserClickedSaveOnlyButton(true);
  }, [updateDatasetInstancesSortOrder, clonedRowData]);

  const handleSaveAndGoBack = useCallback(async () => {
    await updateDatasetInstancesSortOrder({ datasetInstances: clonedRowData });
    setHasUserClickedSaveOnlyButton(false);
    hideModal();
    refetchParentGrid();
  }, [refetchParentGrid, updateDatasetInstancesSortOrder, clonedRowData, hideModal]);

  const columnDefinitions = useMemo(() => getColumnDefinitions({ updateRow }), [updateRow]);

  return (
    <Modal
      className={styles.reorderModal}
      actionButtonsSize="lg"
      isBusy={isUpdatingDatasetInstancesSortOrder}
      title="Reorder Dataset Instances"
      closeAction={hideModal}
      visible={isReorderDatasetInstancesModalVisible}
      submitText="SAVE AND CLOSE"
      submitAction={handleSaveAndGoBack}
      submitAndAddAnotherText="SAVE"
      submitAndAddAnotherAction={handleSave}
      dismissText="CANCEL"
      dismissAction={hideModal}
    >
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingDatasetInstances}
            onGridReady={onGridReady}
            stopEditingWhenCellsLoseFocus
            autoMaxWidth
            singleClickEdit
            suppressCellFocus={false}
          />
        </div>
      </div>
      {navigationPrompt}
    </Modal>
  );
};

ReorderDatasetInstancesModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,

  datasetDefinitionId: PropTypes.string.isRequired,

  refetchParentGrid: PropTypes.func,
};

export default ReorderDatasetInstancesModal;
