import { useState, useCallback } from 'react';

import sseServiceFactory, { Events } from '../utils/sseService';

const useServerSentEventSubscription = <TData>(
  subscribeUrl: string,
  callback: (data: TData) => void,
  event?: Events,
) => {
  const [isListening, setIsListening] = useState(false);

  const subscribe = useCallback(() => {
    if (!isListening) {
      sseServiceFactory(
        { route: subscribeUrl, event, autoReconnect: true },
        {
          onMessage: (newData: TData) => callback(newData),
          onClose: () => setIsListening(false),
          onOpen: () => setIsListening(true),
        },
      );
    }
  }, [callback, isListening, subscribeUrl, event]);

  return {
    subscribe,
    isListening,
  };
};

export default useServerSentEventSubscription;
