import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import { genericFunctionsSchemas } from '@common-packages/validators';
import { Routes } from '@common-packages/routes-definitions';

import Loading from '../../shared/displayComponents/loading.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { SelectOptionPropTypes } from '../../shared/forms/propTypes';
import useGridApi from '../../shared/hooks/useGridApi.hook';
import useEditModeErrorColumn from '../../shared/editMode/useEditModeErrorColumn.hook';
import { selectGenericFunction } from '../store/actions';
import { periodSelector, taxYearSelector, genericFunctionSelector } from '../store/selectors';

import {
  genericFunctionParamsSelector,
  isFetchingGenericFunctionParamsSelector,
  dataTypesOptionsSelector,
  constantsOptionsSelector,
  categoriesOptionsSelector,
  accountsOptionsSelector,
  jurisdictionsOptionsSelector,
  isFetchingFunctionParamsGridDropdownOptionsSelector,
  isSavingGenericFunctionChangesSelector,
  isFetchingAccountsSelector,
  isFetchingJurisdictionsSelector,
} from './store/selectors';
import {
  fetchGenericFunctions,
  fetchGenericFunctionParams,
  fetchDataTypes,
  fetchCategories,
  fetchAccounts,
  fetchJurisdictions,
  createGenericFunctionWithParams,
  updateGenericFunctionWithParams,
} from './store/actions';
import getColumnDefinitions from './genericFunctionsAddEdit.columnDefinitions';
import GenericFunctionForm from './genericFunctionForm.component';
import { parameterTypes } from './constants';
import {
  preparePayloadForCreateOrUpdateAction,
  getNewRowDefaultValues,
  getFormInitialValues,
  useGenericFunctionData,
} from './genericFunctionsAddEdit.utils';
import styles from './genericFunctions.module.scss';

const validateForm = ({ genericFunctions }) => async values => {
  let errors = [];
  try {
    await genericFunctionsSchemas.genericFunctionFormSchema.validate(values, {
      abortEarly: false,
    });
  } catch (e) {
    errors = e.inner;
  }
  const errorsInFormikFormat = errors.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.path]: curr.message,
    }),
    {},
  );
  const doesFunctionAlreadyExist = genericFunctions.some(
    ({ functionId, functionType }) =>
      functionId.toUpperCase() === values.functionId.trim().toUpperCase() &&
      functionType === values.functionType,
  );
  throw doesFunctionAlreadyExist
    ? {
        ...errorsInFormikFormat,
        functionId: 'The Function Id already exists, a unique Id is required before saving',
      }
    : errorsInFormikFormat;
};

const emptyArray = [];

const GenericFunctionsAddEdit = ({
  genericFunctions,
  isFetchingGenericFunctions,

  taxYear,
  period,

  genericFunction,
  fetchGenericFunctions,
  fetchGenericFunctionParams,
  genericFunctionParams,
  isFetchingGenericFunctionParams,

  dataTypesOptions,
  constantsOptions,
  categoriesOptions,
  accountsOptions,
  jurisdictionsOptions,
  isFetchingFunctionParamsGridDropdownOptions,
  isSavingGenericFunctionChanges,

  fetchDataTypes,
  fetchCategories,
  fetchAccounts,
  fetchJurisdictions,
  createGenericFunctionWithParams,
  updateGenericFunctionWithParams,
  selectGenericFunction,
  isFetchingAccounts,
  isFetchingJurisdictions,

  match: {
    params: { editType },
  },
  history,
}) => {
  const isEditingExistingFunction =
    editType === Routes.developmentGenericFunctionsAddEdit.EDIT_TYPES.EDIT;
  const isGridLoading =
    isFetchingGenericFunctions ||
    isFetchingGenericFunctionParams ||
    isFetchingFunctionParamsGridDropdownOptions ||
    isFetchingAccounts ||
    isFetchingJurisdictions;

  useGenericFunctionData({
    taxYear,
    period,
    genericFunction,
    isEditingExistingFunction,
    fetchGenericFunctionParams,
    fetchDataTypes,
    fetchCategories,
    fetchAccounts,
    fetchJurisdictions,
  });
  const { columnApi, gridApi, onGridReady } = useGridApi();

  const rowData = isEditingExistingFunction && !isGridLoading ? genericFunctionParams : emptyArray;
  const [clonedRowData, setClonedRowData] = useState(rowData);

  const refreshClonedRowData = useCallback(() => {
    if (gridApi) {
      const newClonedRowData = cloneDeep(rowData);
      setClonedRowData(newClonedRowData);
      gridApi.setRowData(newClonedRowData);
    }
  }, [rowData, gridApi]);

  useEffect(() => {
    if (!isSavingGenericFunctionChanges) {
      refreshClonedRowData();
    }
  }, [refreshClonedRowData, isSavingGenericFunctionChanges]);

  const { validateEditModeColumnsAndShowPossibleErrors } = useEditModeErrorColumn({
    validationSchema: genericFunctionsSchemas.genericFunctionParamOnGridSchema,
    gridApi,
    columnApi,
  });

  const saveChanges = useCallback(
    async formValues => {
      const rows = gridApi.getModel().rowsToDisplay.map(node => node.data);
      const valid = await validateEditModeColumnsAndShowPossibleErrors();

      if (!valid) {
        return;
      }

      const newGenericFunctionObject = {
        functionId: formValues.functionId.trim(),
        description: formValues.description,
        functionType: formValues.functionType,
        operationType: formValues.operationType,
      };

      const payload = preparePayloadForCreateOrUpdateAction({
        taxYear,
        period,
        newGenericFunctionObject,
        rows,
      });

      if (isEditingExistingFunction) {
        await updateGenericFunctionWithParams(payload);
        selectGenericFunction(newGenericFunctionObject);
        fetchGenericFunctionParams({
          taxYear,
          period,
          functionType: newGenericFunctionObject.functionType,
          functionId: newGenericFunctionObject.functionId,
        });
      } else {
        await createGenericFunctionWithParams(payload);
        selectGenericFunction(newGenericFunctionObject);
        history.push(Routes.developmentGenericFunctionsAddEdit.EDIT);
      }
      fetchGenericFunctions({ taxYear, period });
    },
    [
      createGenericFunctionWithParams,
      validateEditModeColumnsAndShowPossibleErrors,
      updateGenericFunctionWithParams,
      selectGenericFunction,
      fetchGenericFunctions,
      fetchGenericFunctionParams,
      isEditingExistingFunction,
      gridApi,
      taxYear,
      period,
      history,
    ],
  );

  const addParameter = useCallback(() => {
    const newRow = getNewRowDefaultValues({ dataTypesOptions, parameterTypes });
    gridApi.applyTransaction({
      add: [newRow],
    });
  }, [gridApi, dataTypesOptions]);

  const removeParameter = useCallback(
    row => {
      gridApi.applyTransaction({
        remove: [row],
      });
    },
    [gridApi],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        accountsOptions,
        categoriesOptions,
        constantsOptions,
        dataTypesOptions,
        jurisdictionsOptions,
        period,
        removeParameter,
        taxYear,
      }),
    [
      accountsOptions,
      categoriesOptions,
      constantsOptions,
      dataTypesOptions,
      jurisdictionsOptions,
      period,
      removeParameter,
      taxYear,
    ],
  );

  if (isEditingExistingFunction && !genericFunction) {
    return <Redirect to={Routes.developmentGenericFunctions.MAIN} />;
  }

  return (
    <>
      <HeaderWithParamDropdownsWrapper showHeaderTaxYearDropdown showHeaderPeriodDropdown />
      <div className={styles.formContainer}>
        <Loading isLoading={isFetchingGenericFunctions} small>
          <Formik
            initialValues={getFormInitialValues({ isEditingExistingFunction, genericFunction })}
            enableReinitialize
            validateOnBlur
            validate={() =>
              validateForm({ genericFunctions: isEditingExistingFunction ? [] : genericFunctions })
            }
            onSubmit={saveChanges}
          >
            {formikProps => (
              <GenericFunctionForm
                {...formikProps}
                isEditingExistingFunction={isEditingExistingFunction}
                addParameter={addParameter}
                isGridLoading={isGridLoading}
                isSavingChanges={isSavingGenericFunctionChanges}
                resetParams={refreshClonedRowData}
              />
            )}
          </Formik>
        </Loading>
      </div>
      <div className="row">
        <div className={`col ${styles.gridLabel}`}>Function Parameters</div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            isGridLoading={isGridLoading}
            withSearchBar
            columnDefs={columnDefinitions}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            suppressCellFocus={false}
            rowDragManaged
            onGridReady={onGridReady}
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
    </>
  );
};

GenericFunctionsAddEdit.propTypes = {
  genericFunctions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      functionId: PropTypes.string,
      functionType: PropTypes.string,
      operationType: PropTypes.string,
      dateModified: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingGenericFunctions: PropTypes.bool.isRequired,
  taxYear: PropTypes.string,
  period: PropTypes.string,
  genericFunction: PropTypes.shape({
    functionId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    functionType: PropTypes.string.isRequired,
    operationType: PropTypes.string.isRequired,
  }),
  fetchGenericFunctions: PropTypes.func.isRequired,
  fetchGenericFunctionParams: PropTypes.func.isRequired,
  genericFunctionParams: PropTypes.arrayOf(
    PropTypes.shape({
      parameterId: PropTypes.number,
      parameterType: PropTypes.string,
      dataType: PropTypes.string,
      multiplier: PropTypes.number.isRequired,
      roundPlaces: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isFetchingGenericFunctionParams: PropTypes.bool.isRequired,
  dataTypesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  constantsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  categoriesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  accountsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingFunctionParamsGridDropdownOptions: PropTypes.bool.isRequired,
  isSavingGenericFunctionChanges: PropTypes.bool.isRequired,
  isFetchingAccounts: PropTypes.bool.isRequired,
  isFetchingJurisdictions: PropTypes.bool.isRequired,
  fetchDataTypes: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  fetchAccounts: PropTypes.func.isRequired,
  fetchJurisdictions: PropTypes.func.isRequired,
  createGenericFunctionWithParams: PropTypes.func.isRequired,
  updateGenericFunctionWithParams: PropTypes.func.isRequired,
  selectGenericFunction: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      editType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    genericFunction: genericFunctionSelector(state),
    genericFunctionParams: genericFunctionParamsSelector(state),
    isFetchingGenericFunctionParams: isFetchingGenericFunctionParamsSelector(state),
    dataTypesOptions: dataTypesOptionsSelector(state),
    constantsOptions: constantsOptionsSelector(state),
    categoriesOptions: categoriesOptionsSelector(state),
    accountsOptions: accountsOptionsSelector(state),
    jurisdictionsOptions: jurisdictionsOptionsSelector(state),
    isFetchingFunctionParamsGridDropdownOptions: isFetchingFunctionParamsGridDropdownOptionsSelector(
      state,
    ),
    isSavingGenericFunctionChanges: isSavingGenericFunctionChangesSelector(state),
    isFetchingAccounts: isFetchingAccountsSelector(state),
    isFetchingJurisdictions: isFetchingJurisdictionsSelector(state),
  }),
  {
    fetchGenericFunctions,
    fetchGenericFunctionParams,
    fetchDataTypes,
    fetchCategories,
    fetchAccounts,
    fetchJurisdictions,
    createGenericFunctionWithParams,
    updateGenericFunctionWithParams,
    selectGenericFunction,
  },
)(GenericFunctionsAddEdit);
