import camelCase from 'lodash.camelcase';

import {
  defaultColumnDefinition,
  currencyColumnDefinition,
  percentageColumnDefinition,
} from '../../../shared/columnDefinitions';

const formatFlags = {
  PERCENTAGE: 'P',
  DATE: 'D',
  TEXT: 'T',
  JURISDICTION: 'J',
  AMOUNT: 'A',
};
const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  width: 120,
  maxWidth: 200,
  sortable: false,
  cellStyle: {
    textAlign: 'center',
  },
};

const getColumnFormat = formatFlag => {
  switch (formatFlag) {
    case formatFlags.PERCENTAGE:
      return {
        ...extendedDefaultColumnDefinition,
        ...percentageColumnDefinition,
      };
    case formatFlags.DATE:
      return {
        ...extendedDefaultColumnDefinition,
        // TODO SLT-1368: Define what is desired format for date value
        // valueFormatter: ({ value }) => new Date(value).toString(),
      };
    case formatFlags.JURISDICTION:
      return {
        ...extendedDefaultColumnDefinition,
        // TODO SLT-1368: Define what is desired source of jurisdiction list to match ids with labels
      };
    case formatFlags.AMOUNT:
      return {
        ...extendedDefaultColumnDefinition,
        ...currencyColumnDefinition,
      };
    default:
      return {
        ...extendedDefaultColumnDefinition,
      };
  }
};

const getColumnDefinitions = ({ genericSourceDataColumns }) => {
  const columnDefinitions = [
    ...genericSourceDataColumns.map(({ columnName, columnDesc: headerName, formatFlag }) => {
      const columnFormatDefinition = getColumnFormat(formatFlag);

      return {
        ...columnFormatDefinition,
        headerName,
        field: camelCase(columnName),
      };
    }),
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
