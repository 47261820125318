export const TabsTypes = {
  ACCOUNTS: 'ACCOUNTS',
  CATEGORIES: 'CATEGORIES',
  COMPILE: 'COMPILE',
  CONSTANTS: 'CONSTANTS',
  DATA_ITEMS: 'DATA_ITEMS',
  INFO_ACCOUNTS: 'INFO_ACCOUNTS',
  FORM: 'FORM',
  FUNCTIONS: 'FUNCTIONS',
};

export const TABS_DEFINITIONS = [
  { type: TabsTypes.ACCOUNTS, label: 'Accounts' },
  { type: TabsTypes.CATEGORIES, label: 'Categories' },
  { type: TabsTypes.COMPILE, label: 'Compile' },
  { type: TabsTypes.CONSTANTS, label: 'Constants' },
  { type: TabsTypes.DATA_ITEMS, label: 'DataItems' },
  { type: TabsTypes.INFO_ACCOUNTS, label: 'Info Accounts' },
  { type: TabsTypes.FORM, label: 'Form' },
  { type: TabsTypes.FUNCTIONS, label: 'Functions' },
];

export const CalcSpecTypes = {
  BASE: 'BASE',
  CONSOL: 'CONSOL',
  CONSPB: 'CONSPB',
};

export const FunctionNames = {
  GET_ACCOUNT: 'GetAccount',
  GET_CATEGORY: 'GetCategory',
  GET_CONSTANT: 'GetConstant',
  GET_INFO_ACCOUNT: 'GetInfoAccount',
};

export const AgGridIds = {
  ACCOUNTS: 'ExpressionBuilderAccountsDataGrid',
  CATEGORIES: 'ExpressionBuilderCategoriesDataGrid',
  CONSTANTS: 'ExpressionBuilderConstantsDataGrid',
  FUNCTIONS: 'ExpressionBuilderFunctionsDataGrid',
};
