import {
  InsertCheckboxGroupVariable,
  UpdateCheckboxGroupVariable,
  DeleteCheckboxGroupVariable,
} from '../../../../common/types';
import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';

import { useCustomMutation } from '.';

export const useMutationInsertCheckboxGroup = () =>
  useCustomMutation<null, QueryError, InsertCheckboxGroupVariable>({
    handleData: ({
      taxYear,
      jurisdictionId,
      name,
      description,
      checkboxGroupsNames,
      isEditMode,
    }) => ({
      url: '/api/development/checkbox-groups/insert-checkbox-group',
      method: HttpMethods.POST,
      body: {
        taxYear,
        jurisdictionId,
        name,
        description,
        checkboxGroupsNames,
        isEditMode,
      },
    }),
    resetKey: QueryKeys.CheckboxGroups.Data,
    successMessage: 'Checkbox group added successfully',
    errorMessage: 'An error occurred while trying to add a checkbox group',
  });

export const useMutationUpdateCheckboxGroup = () =>
  useCustomMutation<null, QueryError, UpdateCheckboxGroupVariable>({
    handleData: ({ id, name, description, checkboxGroupsNames, isEditMode }) => ({
      url: '/api/development/checkbox-groups/update-checkbox-group',
      method: HttpMethods.PUT,
      body: {
        id,
        name,
        description,
        checkboxGroupsNames,
        isEditMode,
      },
    }),
    resetKey: QueryKeys.CheckboxGroups.Data,
    successMessage: 'Checkbox group updated successfully',
    errorMessage: 'An error occurred while trying to update a checkbox group',
  });

export const useMutationDeleteCheckboxGroup = () =>
  useCustomMutation<null, QueryError, DeleteCheckboxGroupVariable>({
    handleData: ({ id }) => ({
      url: `/api/development/checkbox-groups/delete-checkbox-group/${id}`,
      method: HttpMethods.DELETE,
    }),
    resetKey: QueryKeys.CheckboxGroups.Data,
    successMessage: 'Checkbox group deleted successfully',
    errorMessage: 'An error occurred while trying to delete a checkbox group',
  });
