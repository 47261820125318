import React from 'react';

import EditReport from '../../shared/editReport.component';
import { REPORT_TYPES, uiLabels } from '../../shared/constants';

import getColumnDefinitions from './reports.columnDefinitions';

const EditCustomReports = () => (
  <EditReport
    reportType={REPORT_TYPES.REPORT}
    getColumnDefinitions={getColumnDefinitions}
    uiLabels={uiLabels.report}
  />
);

export default EditCustomReports;
