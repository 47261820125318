import React, { useCallback } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';
import { useSelector, useDispatch } from 'react-redux';
import { dataModelSchemas } from '@common-packages/validators';

import oracleSysGuidUuid from '../../../utils/oracleSysGuidUuid';
import { taxYearSelector, jurisdictionIdSelector } from '../../store/selectors';
import { addDataModel } from '../../../shared/store/dataModels/actions';
import { contextTypeOptions } from '../constants';

import ModelForm from './ModelForm.component';

const defaultContextType = contextTypeOptions[0].value;

const AddModelModal = ({ hideModal, visible, modalData, submitCallback }) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const add = useCallback(
    async values => {
      hideModal();
      const dataModel = {
        id: oracleSysGuidUuid(),
        jurisdictionId,
        taxYear,
        ...values,
      };
      // wait for a success, if error submitCallback is not called
      await dispatch(addDataModel(dataModel));
      submitCallback(dataModel.id);
    },
    [dispatch, submitCallback, hideModal, taxYear, jurisdictionId],
  );

  const renderModal = useCallback(
    formikProps => (
      <Modal
        title="Add Model"
        closeAction={hideModal}
        visible={visible}
        submitText="Submit"
        submitAction={formikProps.submitForm}
        dismissText="Cancel"
        dismissAction={hideModal}
      >
        <ModelForm {...formikProps} />
      </Modal>
    ),
    [hideModal, visible],
  );

  const initialModalData = {
    ...modalData,
    name: '',
    description: '',
    contextTypeId: defaultContextType,
    isCommon: false,
    shouldDisplayWorkpaperOnNavigator: false,
  };

  return (
    <Formik
      initialValues={initialModalData}
      enableReinitialize
      validateOnBlur={false}
      validationSchema={dataModelSchemas.insertDataModelSchema}
      onSubmit={add}
    >
      {renderModal}
    </Formik>
  );
};

AddModelModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  submitCallback: PropTypes.func.isRequired,
  modalData: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default AddModelModal;
