import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';

import styles from './fields.module.scss';

const WrappedField = ({ type = 'text', className = '', name, disabled = false, ...restProps }) => {
  const isDisabled = typeof disabled === 'function' ? disabled(name) : disabled;

  return (
    <Field
      className={classNames(className, {
        [styles.fieldDisabled]: isDisabled,
        [styles.numberField]: type === 'number',
      })}
      name={name}
      disabled={isDisabled}
      type={type}
      {...restProps}
    />
  );
};

WrappedField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  className: PropTypes.string,
  type: PropTypes.string,
};

export default WrappedField;
