import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';

import { fetchSltAmountDetailsTypes } from './types';

const sltAmountDetailsReducer = reducerFactory({
  initialState: {
    accountGroupHeader: '',
    sltAmountDetails: [],
    isFetchingSltAmountDetails: false,
    isFetchingAccountGroupHeader: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchSltAmountDetailsTypes,
      payloadKey: 'sltAmountDetails',
      fetchingKey: 'isFetchingSltAmountDetails',
    }),
  },
});

export default sltAmountDetailsReducer;
