import { defaultColumnDefinition } from '../../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'EntityName',
    width: 150,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Type',
    field: 'ValidationType',
    width: 50,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Rule',
    field: 'BusinessRuleNo',
    width: 50,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Severity',
    field: 'BusinessRuleSeverity',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Error',
    field: 'Message',
    width: 350,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Description',
    field: 'AdditionalText',
    width: 1050,
  },
];

export default columnDefinitions;
