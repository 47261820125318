import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import promiseWaterfall from 'promise.waterfall';
import { RequestError } from '@tls/ui-request';
import { Button, Checkbox } from '@pwc/appkit-react';

import useFetch from '../../shared/hooks/useFetch.hook';
import { successNotification } from '../../shared/notification/store/actions';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';
import { clientIdSelector } from '../../shared/store/context';

import { fetchProformaStepsDatApiAction, runProformaStepApiAction } from './api';
import { PROFORMA_STEPS } from './constants';
import Action from './action.component';
import getStepData from './getStepData';
import styles from './styles.module.scss';

const submittingActionsInitialState = PROFORMA_STEPS.reduce((acc, { action }) => {
  acc[action] = false;
  return acc;
}, {});
const stepSkipInitialState = PROFORMA_STEPS.reduce((acc, { action }) => {
  acc[action] = true;
  return acc;
}, {});

const ProformaSteps = ({
  globalContext,
  isFetchingGlobalContext,
  clientId,

  ttiYearId,
  selectedTtiCaseId,
  selectedReturnBinderId,

  successNotification,

  hasUserPermissionsToEdit,
}) => {
  const [isSubmittingActions, setIsSubmittingActions] = useState(submittingActionsInitialState);
  const [stepSkip, setStepSkip] = useState(stepSkipInitialState);

  const toggleStepSkip = useCallback(
    step => setStepSkip(prevState => ({ ...prevState, [step]: !prevState[step] })),
    [setStepSkip],
  );

  const contextDebugInfo = {
    globalContext: globalContext.params,
    clientId,
    caseId: selectedTtiCaseId,
    yearId: ttiYearId,
    binderId: selectedReturnBinderId,
  };

  const {
    data: proformaStepsData,
    isFetching: isFetchingProformaStepsData,
    fetch: fetchProformaStepsData,
  } = useFetch({ action: fetchProformaStepsDatApiAction, throwError: true });

  const { fetch: runProformaStep } = useFetch({
    action: runProformaStepApiAction,
    throwError: true,
  });

  useEffect(() => {
    if (globalContext.isReady && selectedTtiCaseId && selectedReturnBinderId && ttiYearId) {
      fetchProformaStepsData({
        globalContext,
        ttiCaseId: selectedTtiCaseId,
        ttiBinderId: selectedReturnBinderId,
        ttiYearId,
      });
    }
  }, [fetchProformaStepsData, globalContext, selectedTtiCaseId, selectedReturnBinderId, ttiYearId]);

  const callRunAllProformaSteps = useCallback(async () => {
    const runStep = async step => {
      setIsSubmittingActions(prevState => ({ ...prevState, [step]: true }));
      await runProformaStep({
        step,
        globalContext,
        ttiYearId,
        caseId: selectedTtiCaseId,
        binderId: selectedReturnBinderId,
      });
      setIsSubmittingActions(prevState => ({ ...prevState, [step]: false }));
      await fetchProformaStepsData({
        globalContext,
        ttiCaseId: selectedTtiCaseId,
        ttiBinderId: selectedReturnBinderId,
        ttiYearId,
      });
    };

    try {
      await promiseWaterfall(
        PROFORMA_STEPS.filter(({ action }) => !stepSkip[action]).map(({ action }) => () =>
          runStep(action),
        ),
      );
      successNotification('All Pro-Forma steps succeeded');
    } catch (error) {
      await fetchProformaStepsData({
        globalContext,
        ttiCaseId: selectedTtiCaseId,
        ttiBinderId: selectedReturnBinderId,
        ttiYearId,
      });
      setIsSubmittingActions(submittingActionsInitialState);

      if (!(error instanceof RequestError)) {
        // Rethrowing not request related errors. Request error has error message that is handled
        // by errorNotification by default.
        throw error;
      }
    }
  }, [
    stepSkip,
    runProformaStep,
    fetchProformaStepsData,
    successNotification,
    globalContext,
    ttiYearId,
    selectedTtiCaseId,
    selectedReturnBinderId,
  ]);

  const isSubmittingAnyAction = useMemo(
    () => Object.values(isSubmittingActions).some(status => status),
    [isSubmittingActions],
  );

  const disabled =
    isFetchingGlobalContext ||
    isFetchingProformaStepsData ||
    !hasUserPermissionsToEdit ||
    !ttiYearId ||
    // || !selectedTtiCaseId we have a step that does not require neither case nor binder
    // || isNil(selectedReturnBinderId) we have a step that does not require neither case nor binder
    isSubmittingAnyAction;

  return (
    <>
      {PROFORMA_STEPS.map((step, i) => (
        <div className={styles.subStepBox} key={i}>
          <Checkbox
            checked={stepSkip[step.action]}
            // eslint-disable-next-line
            onChange={() => toggleStepSkip(step.action)}
          >
            Skip
          </Checkbox>
          {Object.keys(step.subSteps).map(subStep => (
            <Action
              key={subStep}
              actionName={subStep}
              contextDebugInfo={contextDebugInfo}
              title={step.subSteps[subStep]}
              data={getStepData(proformaStepsData, subStep)}
              isFetchingProformaStepsData={isFetchingProformaStepsData}
              isSubmitting={isSubmittingActions[step.action]}
            />
          ))}
        </div>
      ))}
      <Button size="lg" onClick={callRunAllProformaSteps} disabled={disabled}>
        Run
      </Button>
    </>
  );
};

ProformaSteps.propTypes = {
  clientId: PropTypes.string,
  globalContext: globalContextPropTypes,
  isFetchingGlobalContext: PropTypes.bool.isRequired,

  ttiYearId: PropTypes.string,
  selectedTtiCaseId: PropTypes.string,
  selectedReturnBinderId: PropTypes.string,

  successNotification: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    clientId: clientIdSelector(state),
    globalContext: globalContextSelector(state),
    isFetchingGlobalContext: isFetchingGlobalContextSelector(state),
  }),
  {
    successNotification,
  },
)(ProformaSteps);
