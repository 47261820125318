import React from 'react';

interface ErrorMessageProps {
  error?: string | null;
}

const ErrorMessage = ({ error = null }: ErrorMessageProps) => {
  if (!error) {
    return null;
  }

  return <div className="invalid-feedback">{error}</div>;
};

export default ErrorMessage;
