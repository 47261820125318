import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

const activeWorkspaceStyle = { borderBottom: '2px solid #d04a02' };

const pathMatches = (path, pathname) => pathname.includes(path);

const activeButtonOrNavLinkProps = {
  label: PropTypes.string.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  route: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

const ActiveButtonOrNavLink = ({
  label,
  path,
  pathname,
  route,

  domRef,
  style,
  ...props
}) => {
  if (label === Routes.root.displayMainName()) {
    return (
      <NavLink activeStyle={activeWorkspaceStyle} className="navLink" to={route} exact>
        {label}
      </NavLink>
    );
  }

  const isActive =
    path &&
    ((Array.isArray(path) && pathname.startsWith('/development')) || pathMatches(path, pathname));

  const combinedStyles = isActive
    ? {
        ...style,
        ...activeWorkspaceStyle,
      }
    : style;

  return (
    <button style={combinedStyles} className="navLink" ref={domRef} {...props}>
      {label}
    </button>
  );
};

ActiveButtonOrNavLink.propTypes = {
  ...activeButtonOrNavLinkProps,
  domRef: PropTypes.func.isRequired,
  style: PropTypes.object,
};

ActiveButtonOrNavLink.defaultProps = {
  style: {},
};

const topLevelMenuButtonFactory = ({ label, path, route, pathname }) => {
  const TopLevelMenuButton = props => (
    <ActiveButtonOrNavLink label={label} path={path} route={route} pathname={pathname} {...props} />
  );

  TopLevelMenuButton.propTypes = {};

  return TopLevelMenuButton;
};

export default topLevelMenuButtonFactory;
