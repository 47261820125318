import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { taxTypes } from '@common-packages/routes-definitions';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import { taxYearSelector, periodSelector } from '../../../shared/store/selectors';
import useFetchApportionmentRateDetails from '../../../taxSummaries/apportionmentRateDetails/useFetchApportionmentRateDetails.hook';
import getApportionmentRateDetailsWithSections from '../../../taxSummaries/apportionmentRateDetails/apportionmentRateDetails.sections';

import getColumnDefinitions from './apportionmentRateDetails.columnDefinitions';

const sections = ['PROPERTY', 'PAYROLL', 'SALES', ''];

const ApportionmentRateDetails = ({
  match,

  taxYear,
  period,
  orgId: entityId,
  jurisdictionId,
  isFetchingContext,
}) => {
  const { taxName } = match.params;
  const isContextReady = taxYear && period && jurisdictionId && entityId && taxName;

  const {
    apportionmentRateDetails,
    isFetchingApportionmentRateDetails,
  } = useFetchApportionmentRateDetails({
    taxYear,
    period,
    entityId,
    jurisdictionId,
    taxType: taxTypes[taxName],
    isContextReady,
  });

  const isLoading = isFetchingContext || isFetchingApportionmentRateDetails;

  const columnDefinitions = useMemo(() => getColumnDefinitions({ match }), [match]);
  const rowData = useMemo(
    () => getApportionmentRateDetailsWithSections(sections, apportionmentRateDetails),
    [apportionmentRateDetails],
  );

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <div className="grid-borderless row grid-row">
      <div className="col">
        <AgGrid isGridLoading={isLoading} columnDefs={columnDefinitions} rowData={rowData} />
      </div>
    </div>
  );
};
ApportionmentRateDetails.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool,

  match: PropTypes.shape({
    params: PropTypes.shape({
      taxName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default connect(state => ({
  taxYear: taxYearSelector(state),
  period: periodSelector(state),
}))(ApportionmentRateDetails);
