import React, { PureComponent } from 'react';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isNil from 'lodash.isnil';

import { paramFieldOptionsSelector, isFetchingParamFieldSelector } from '../store/selectors';
import { fetchParamOptions } from '../store/actions';
import dependentParamsAreSet from '../../../shared/reports/utils/dependentParamsAreSet';
import Loading from '../../../shared/displayComponents/loading.component';
import ReportParamMultiSelect from '../../../shared/reports/reportParamMultiSelect.component';
import SDKCustomSelect from '../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { SelectOptionPropTypes } from '../../../shared/forms/propTypes';

class DropdownField extends PureComponent {
  componentDidMount() {
    this.fetchParamOptions();
  }

  componentDidUpdate(prevProps) {
    const { param, dependentParams, paramFieldOptions, defaultValue } = this.props;

    if (param !== prevProps.param || !isEqual(dependentParams, prevProps.dependentParams)) {
      this.fetchParamOptions();
    }

    if (!isEqual(paramFieldOptions, prevProps.paramFieldOptions) && !isNil(defaultValue)) {
      const stringDefaultValue = `${defaultValue}`;
      if (paramFieldOptions.find(({ value }) => value === stringDefaultValue)) {
        this.handleSelectChange({ value: stringDefaultValue });
      }
    }
  }

  fetchParamOptions() {
    const { reportId, fetchParamOptions, param, dependentParams } = this.props;
    const dependentParamsReady = dependentParamsAreSet(dependentParams);

    if (dependentParamsReady) {
      fetchParamOptions({ reportId, paramName: param.mappingName, params: dependentParams });
    }
  }

  handleSelectChange = option => {
    const { handleChange, param } = this.props;
    handleChange(param.mappingName, option.value);
  };

  render() {
    const { isFetchingParamField, paramFieldOptions, value, param, dependentParams } = this.props;

    return (
      <div className="global-context-dropdown">
        {param.isMultiSelectDropdown ? (
          <ReportParamMultiSelect
            label={param.name}
            param={{
              isFetchingParamData: isFetchingParamField,
              paramData: paramFieldOptions,
              paramValue: value,
              setParamValue: paramValue => {
                this.handleSelectChange({ value: paramValue });
              },
            }}
          />
        ) : (
          <Loading isLoading={isFetchingParamField}>
            <div className="global-context-dropdown-wrapper">
              <SDKCustomSelect
                appkitLabel={param.name}
                className="global-context-select sdk-custom-select custom-report-param-select"
                options={paramFieldOptions}
                onChange={this.handleSelectChange}
                value={value}
                virtualized
                disabled={!dependentParamsAreSet(dependentParams)}
              />
            </div>
          </Loading>
        )}
      </div>
    );
  }
}

DropdownField.propTypes = {
  reportId: PropTypes.number.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  fetchParamOptions: PropTypes.func.isRequired,
  paramFieldOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingParamField: PropTypes.bool.isRequired,
  param: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mappingName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    isMultiSelectDropdown: PropTypes.bool,
  }).isRequired,
  dependentParams: PropTypes.object, // eslint-disable-line
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default connect(
  (state, { param }) => ({
    paramFieldOptions: paramFieldOptionsSelector(state, { param }),
    isFetchingParamField: isFetchingParamFieldSelector(state, { param }),
  }),
  {
    fetchParamOptions,
  },
)(DropdownField);
