import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';

const Tools = () => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Test E-File Configuration"
      icon="file"
      description="Configure E-File calls"
      route={Routes.efileConfiguration.MAIN}
    />
    <AuthorizedCard
      title="Test E-File Submission"
      icon="file"
      description="Process E-File calls"
      route={Routes.efileSubmission.MAIN}
    />
  </div>
);

export default Tools;
