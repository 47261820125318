import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Modal from '@tls/ui-modal';
import { usersMaintenanceSchemas } from '@common-packages/validators';

import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';

import { createUser, updateUser, fetchUsers, validateUserEmail, fetchRoles } from './store/actions';
import { isValidatingUserSelector, rolesSelector, validationResult } from './store/selectors';
import AddUserForm from './addUserForm.component';

const formInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  activeInd: false,
  roleId: '',
};
const AddUserFormModal = ({
  createUser,
  hideModal,
  visible,
  userToEdit,
  updateUser,
  fetchUsers,
  roles,
  fetchRoles,
  validateUserEmail,
  isValidatingUserEmail,
  validationResult,
}) => {
  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const saveUser = useCallback(
    async ({ firstName, lastName, email, activeInd, roleId }, { resetForm }) => {
      const data = {
        roleId,
        firstName,
        lastName,
        email,
        activeInd,
      };

      if (!userToEdit) {
        const users = await fetchUsers();
        const isUserEmailRecordFound = users.some(user => user.email === email);
        if (!isUserEmailRecordFound) {
          await createUser(data);
        }
      } else {
        await updateUser({ ...data, userId: userToEdit.userId });
      }

      fetchUsers();
      hideModal();
      resetForm();
    },
    [userToEdit, fetchUsers, hideModal, createUser, updateUser],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        title={`${userToEdit ? 'Edit' : 'Add'} User`}
        closeAction={hideModal}
        visible={visible}
        submitAction={formikProps.submitForm}
        dismissAction={hideModal}
      >
        <AddUserForm
          {...formikProps}
          userToEdit
          rolesOptions={roles}
          onCancelClick={hideModal}
          isEditMode={Boolean(userToEdit)}
          isValidatingUserEmail={isValidatingUserEmail}
          validateUserEmail={validateUserEmail}
          validationResult={validationResult}
          visible={visible}
        />
      </Modal>
    ),
    [
      userToEdit,
      roles,
      hideModal,
      visible,
      isValidatingUserEmail,
      validateUserEmail,
      validationResult,
    ],
  );
  return (
    <>
      <Formik
        initialValues={userToEdit ? userToEdit : formInitialValues}
        validationSchema={usersMaintenanceSchemas.createUserSchema}
        enableReinitialize
        validateOnBlur={false}
        onSubmit={saveUser}
      >
        {renderForm}
      </Formik>
    </>
  );
};

AddUserFormModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  userToEdit: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    roleId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string,
    activeInd: PropTypes.bool.isRequired,
  }),
  validateUserEmail: PropTypes.func.isRequired,
  isValidatingUserEmail: PropTypes.bool.isRequired,
  validationResult: PropTypes.shape({
    status: PropTypes.string,
    user: PropTypes.shape({
      roleId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      activeInd: PropTypes.bool,
    }),
  }).isRequired,
  fetchRoles: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(SelectOptionPropTypes),
};

export default connect(
  state => ({
    validationResult: validationResult(state),
    isValidatingUserEmail: isValidatingUserSelector(state),
    roles: rolesSelector(state),
  }),
  {
    createUser,
    updateUser,
    fetchUsers,
    validateUserEmail,
    fetchRoles,
  },
)(AddUserFormModal);
