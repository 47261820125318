import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchEntities = ({ taxYear, period, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/admin/maintenance/lock-return/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Fetching entities failed',
  });

export const updateEntities = ({ taxYear, period, jurisdictionId, entitiesToUpdate }) =>
  httpPut({
    route: `/api/admin/maintenance/lock-return/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Updating lock states failed',
    body: { entitiesToUpdate },
  });
