import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';

import config from '../../config';

import styles from './addReturnItems.module.scss';

const UploadPdfs = () => {
  const location = useLocation();

  return (
    <div className={styles.uploadPdfs}>
      <img
        alt="Upload PDF"
        src={`${config.BASE_PATH}/assets/download-regenerate-pdf-graphic.png`}
      />
      <span className={styles.uploadAttachmentInformation}>
        Select Upload Attachment to upload PDFs.
      </span>
      <Link
        to={{ pathname: Routes.attachments.MAIN, state: { returnLocation: location } }}
        className={styles.uploadAttachmentsLink}
      >
        <Button size="xl" kind="secondary" className={styles.uploadAttachmentButton}>
          upload attachment
        </Button>
      </Link>
    </div>
  );
};

export default UploadPdfs;
