import React from 'react';

import AgGrid from '../../agGrid/agGrid.component';

import { manualAccountsPropType } from './propTypes';
import columnDefinitions from './manualAccountsGrid.columnDefinitions';

const ManualAccountsGrid = ({ manualAccounts }) => (
  <div className="row grid-row">
    <div className="col">
      <AgGrid rowData={manualAccounts} columnDefs={columnDefinitions} isLoading={false} />
    </div>
  </div>
);

ManualAccountsGrid.propTypes = {
  manualAccounts: manualAccountsPropType.isRequired,
};

export default ManualAccountsGrid;
