import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchSubmissionsTypes = getApiActionTypes('FETCH_SUBMISSIONS');

export const fetchCaseListTypes = getApiActionTypes('FETCH_CASE_LIST');

export const fetchYearSubPeriodListTypes = getApiActionTypes('FETCH_YEAR_SUB_PERIOD_LIST');

export const validateAndGenerateMappingForStateTransmissionTypes = getApiActionTypes(
  'VALIDATE_AND_GENERATE_MAPPING_FOR_STATE_TRANSMISSION',
);

export const stateTransmissionImportTypes = getApiActionTypes('STATE_TRANSMISSION_IMPORT');

export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION';
