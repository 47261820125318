import { ColDef } from 'ag-grid-community';
import { Routes } from '@common-packages/routes-definitions';

import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../../../shared/columnDefinitions';
import { LinkCellRendererFactory } from '../../../../shared/columnDefinitions/cellRenderers';
import downloadFile from '../../../../utils/downloadFile';

const getColumnDefinitions = (): ColDef[] => {
  const downloadLinkRenderer = () =>
    LinkCellRendererFactory({
      getText: ({ value }) => (value ? 'Download' : ''),
      clickHandler: (_, data) =>
        downloadFile({
          route: `${Routes.eFileSupportDownloadTaxProcessFile.compiledRoute({
            stepId: data.stepId,
          })}`,
        }),
    });

  return [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Step Name',
      field: 'stepName',
      width: 250,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Status',
      field: 'status',
      width: 90,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Created On',
      field: 'createdOn',
      width: 160,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Response',
      field: 'ttiResponse',
      tooltipField: 'ttiResponse',
      flex: 1,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Input File',
      field: 'isFileAvailable',
      width: 80,
      cellRenderer: downloadLinkRenderer(),
    },
  ];
};

export default getColumnDefinitions;
