import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import Spinner from '@tls/ui-spinner';

import { SelectOptionPropTypes } from '../../propTypes/selectOption';

import styles from './virtualizedSelect.module.scss';
import VirtualizedSelectListRenderer from './virtualizedSelectListRenderer.component';

const itemToString = item => (item ? item.label : '');

const VirtualizedSelect = ({
  options,
  initialSelectedValue,
  setSelectedValue,
  isLoading,
  inputRef,
}) => {
  const handleInputChange = useCallback(
    option => {
      if (option) {
        const { value } = option;
        setSelectedValue(value);
      }
    },
    [setSelectedValue],
  );

  const filterOptions = inputValue =>
    options.filter(item => item?.label?.toLowerCase().includes(inputValue.toLowerCase()));

  const spinnerCell = (
    <div className={`ag-grid-select-editor-spinner ${styles.spinnerWrapper}`}>
      <Spinner small />
    </div>
  );

  const onStateChange = useCallback(
    changes => {
      if (changes.inputValue && changes.selectedItem) {
        setSelectedValue(changes.selectedItem?.value);
      }
    },
    [setSelectedValue],
  );

  return (
    <Downshift
      onChange={handleInputChange}
      itemCount={options.length}
      itemToString={itemToString}
      onStateChange={onStateChange}
    >
      {({ getInputProps, getItemProps, inputValue, selectedItem, highlightedIndex }) => (
        <div className={`ag-rich-select ${styles.virtualizedListContainer}`}>
          <div className={styles.selectInputWrapper}>
            <input
              placeholder="Select..."
              className={styles.selectInput}
              {...getInputProps({
                ref: inputRef,
              })}
              disabled={isLoading}
            />
          </div>
          {isLoading ? (
            spinnerCell
          ) : (
            <VirtualizedSelectListRenderer
              downshiftProps={{ getItemProps, selectedItem, highlightedIndex }}
              options={filterOptions(inputValue)}
              initialSelectedValue={initialSelectedValue}
            />
          )}
        </div>
      )}
    </Downshift>
  );
};

VirtualizedSelect.propTypes = {
  options: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  initialSelectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default VirtualizedSelect;
