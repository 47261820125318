import React from 'react';
import PropTypes from 'prop-types';

import CellTooltip from './cellTooltip.component';
import { formatDate } from './utils';

const DateCellRendererFactory = () => {
  const DateRenderer = ({ value, data, valueFormatted, colDef, validators = {} }) => {
    const dateString = valueFormatted ? valueFormatted : value;
    const formattedDate = formatDate(dateString);

    const validate = validators[colDef.field];

    const tooltipMessage = validate ? validate(value, data) : '';

    if (formattedDate !== null) {
      return (
        <div>
          <span>
            {formattedDate}
            <CellTooltip message={tooltipMessage} />
          </span>
        </div>
      );
    }

    return null;
  };

  DateRenderer.propTypes = {
    value: PropTypes.string,
    valueFormatted: PropTypes.string,
    colDef: PropTypes.shape({
      field: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.object.isRequired,
    validators: PropTypes.object,
  };

  return DateRenderer;
};

export default DateCellRendererFactory;
