import {
  defaultColumnDefinition,
  adminColumnDefinition,
  centeredColumnDefinition,
  mapValueFormatterFactory,
} from '../../shared/columnDefinitions';
import {
  EditableCheckboxCellRenderer,
  AdminCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({
  onEditIconClick,
  onDeleteIconClick,
  businessGroups,
  parents,
  customers,
}) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onEditIconClick,
    onDeleteIconClick,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Business',
      field: 'businessId',
      maxWidth: 80,
      width: 80,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'businessDescription',
      maxWidth: 650,
      width: 250,
    },
    {
      ...defaultColumnDefinition,
      ...centeredColumnDefinition,
      headerName: 'Lead',
      field: 'leadCustomerId',
      valueFormatter: mapValueFormatterFactory(customers),
    },
    {
      ...defaultColumnDefinition,
      ...centeredColumnDefinition,
      headerName: 'Business Group',
      field: 'businessGroup',
      valueFormatter: mapValueFormatterFactory(businessGroups),
    },
    {
      ...defaultColumnDefinition,
      ...centeredColumnDefinition,
      headerName: 'Tier',
      field: 'tier',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'SIR',
      field: 'sirYN',
      width: 60,
      cellStyle: {
        textAlign: 'center',
      },
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: true,
      },
    },
    {
      ...defaultColumnDefinition,
      ...centeredColumnDefinition,
      headerName: 'Parent',
      field: 'sirRollupBusId',
      valueFormatter: mapValueFormatterFactory(parents),
    },
  ];

  return columnDefinitions;
};
export default getColumnDefinitions;
