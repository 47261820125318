import 'core-js/es/object';
import 'intersection-observer'; // polyfill
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import persistStore from 'redux-phoenix';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LicenseManager } from 'ag-grid-enterprise';
import { library } from '@fortawesome/fontawesome-svg-core';
import { pdfjs } from 'react-pdf';
import '@common-packages/validators/customValidators'; // registers custom validators

import store from './store';
import App from './App';
import config from './config';
import {
  faThumbtack,
  faLock,
  faLockOpen,
  faInfoCircle,
  faSync,
  faPaperclip,
  faPlusCircle,
} from './icons/fontawesome/fontawesome-pro-light/shakable.es';
import { faCalculator, faCircle } from './icons/fontawesome/fontawesome-pro-solid/shakable.es';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

library.add(faThumbtack);
library.add(faLock);
library.add(faLockOpen);
library.add(faInfoCircle);
library.add(faPaperclip);
library.add(faPlusCircle);
library.add(faSync);
library.add(faCalculator);
library.add(faCircle);

// We use react-pdf which uses PDF.js to render the pdf attachments.
// PDF.js worker ensures that PDF files will be rendered in a separate thread without affecting page performance. According to https://github.com/wojtekmaj/react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = config.PDF_JS_WORKER_SOURCE;

LicenseManager.setLicenseKey(config.AG_GRID_LICENSE);

persistStore(store, {
  whitelist: [
    'entity.manuallyEnteredAccountData.previousSelectedAccountGroup',

    'development.shared.taxYear',
    'development.shared.previousTaxYear',
    'development.shared.period',
    'development.shared.previousPeriod',
    'development.shared.jurisdictionId',
    'development.shared.previousJurisdictionId',
    'development.shared.formId',
    'development.shared.previousFormId',
    'development.shared.selectedPage.pdfId',
    'development.shared.selectedPage.previousPageNumber',
    'development.defaultBinders.jurisdictionId',
    'development.defaultBinders.previousJurisdictionId',
    'development.attachmentDefinitions.jurisdictionId',
    'development.attachmentDefinitions.previousJurisdictionId',
    'development.expressionBuilder.activeTab',
    'development.expressionBuilder.splitPaneLeftWidth',

    'admin.taxRatesAndConstantsMaintenance.shared.previousJurisdictionId',
    'admin.taxRatesAndConstantsMaintenance.shared.jurisdictionId',
  ],
}).then(store => {
  render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>,
    document.querySelector('#root'),
  );
});
