import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from '@pwc/appkit-react/lib/Checkbox';
import { Input } from '@pwc/appkit-react/lib/Input';
import isNil from 'lodash.isnil';
import { DOMAIN_TYPE, filingStatus, TAX_YEAR_2020 } from '@common-packages/shared-constants';
import { NEW_YORK_JURISDICTION_ID } from '@common-packages/tti';

import useFetch from '../../shared/hooks/useFetch.hook';
import Loading from '../../shared/displayComponents/loading.component';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import { globalContextSelector } from '../../shared/store/selectors';
import { runTTIStep } from '../store/actions';
import { hasPermissionToEfileRunSelector } from '../../shared/store/customerData.selectors';
import {
  eFileStepsDataSelector,
  isFetchingEFileStepsDataSelector,
  eFileOverallStatusSelector,
  persistedWizardFieldsValuesSelector,
} from '../store/selectors';
import styles from '../styles.module.scss';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { fetchFederalAttachmentOptions as fetchFederalAttachmentOptionsApiAction } from '../store/api';

import { stateSubmissionTypes, stateSubmissionOptions } from './constants';
import { getGenerateTransmissionSteps } from './getStepsData';
import SubStep from './subStep.component';
import {
  stepDataPropTypes,
  contextDebugInfoPropTypes,
  overallStatusPropTypes,
} from './currentReturnFiling.propTypes';
import useTransmitStepAndTTISubmissionId from './useTransmitStepAndTTISubmissionId';
import { wizardStatePropTypes } from './useWizard.hook';

const NEW_YORK_CASE_FILED_ENTITIES_INFO = 'All Entities have filed Form CT-3-A/BC';

const GenerateTransmissionStep = ({
  binderFilingId,
  globalContext,

  ttiYearId,
  ttiCaseId,
  selectedTtiBinderId,
  efServiceSubmissionId,

  eFileStepsData,
  fetchStepsData,
  isFetchingEFileStepsData,
  runTTIStep,

  contextDebugInfo,
  hasPermissionToRun,

  isContextLocked,
  isFetchingTtiBinders,
  isLoading,

  wizardState,
  setStepStatus,
  overallStatus,

  stateSubmissionType,
  setStateSubmissionType,
  includeFederal,
  setIncludeFederal,

  showConfirmModal,

  persistedWizardFieldsValues,

  federalProformaSubmissionId,
  setFederalProformaSubmissionId,

  getDownloadFileComponent,

  sltBinderId,
}) => {
  const { currentStep, isWizardActionRunning } = wizardState;
  const { ttiSubmissionId, transmitStepsData } = useTransmitStepAndTTISubmissionId({
    ttiYearId,
    ttiCaseId,
    selectedTtiBinderId,
    efServiceSubmissionId,
    eFileStepsData,
    stateSubmissionType,
  });

  const {
    data: federalAttachmentOptions,
    fetch: fetchFederalAttachmentOptions,
    isFetching: isFetchingFederalAttachmentOptions,
  } = useFetch({
    action: fetchFederalAttachmentOptionsApiAction,
  });

  useEffect(() => {
    if (globalContext.isReady) {
      fetchFederalAttachmentOptions({ globalContext });
    }
  }, [fetchFederalAttachmentOptions, globalContext]);

  const { persistedIsNewYorkFormConfirmed } = persistedWizardFieldsValues;

  const [areFiledEntitiesConfirmed, setAreFiledEntitiesConfirmed] = useState(
    persistedIsNewYorkFormConfirmed,
  );
  const [isRunButtonClicked, setIsRunButtonClicked] = useState(false);

  const toggleFiledEntitiesConfirm = useCallback(
    () =>
      setAreFiledEntitiesConfirmed(prevAreFiledEntitiesConfirmed => !prevAreFiledEntitiesConfirmed),
    [],
  );

  const onStateSubmissionTypeChange = useCallback(
    value => {
      setStateSubmissionType(value);
      if (value === stateSubmissionTypes.LINKED) {
        setIncludeFederal(true);
      }
    },
    [setStateSubmissionType, setIncludeFederal],
  );

  useEffect(() => {
    if (globalContext.isConsolidated) {
      // in this case there should only be two options and we want to select the first one with a value See AC 3.2.3 in SLT-9222
      setFederalProformaSubmissionId(
        federalAttachmentOptions?.[0].value || federalAttachmentOptions?.[1].value,
      );
    } else {
      setFederalProformaSubmissionId(federalAttachmentOptions?.[0].value);
    }
  }, [setFederalProformaSubmissionId, globalContext.isConsolidated, federalAttachmentOptions]);

  const toggleIncludeFederal = useCallback(() => setIncludeFederal(!includeFederal), [
    setIncludeFederal,
    includeFederal,
  ]);

  const setIsActionRunning = useCallback(
    value => {
      setStepStatus({
        currentStep,
        payload: {
          isStepActionRunning: value,
        },
      });
    },
    [setStepStatus, currentStep],
  );

  const isNewYorkConsolidationCase = useMemo(
    () =>
      globalContext?.isReady &&
      globalContext.isConsolidated &&
      globalContext.jurisdictionId === NEW_YORK_JURISDICTION_ID &&
      Number(globalContext.taxYear) >= TAX_YEAR_2020,
    [globalContext],
  );

  const callTransmitStepsAction = useCallback(
    async ({ action }) => {
      setIsActionRunning(true);
      await runTTIStep({
        action,
        globalContext,
        binderFilingId,
        ttiYearId,
        ttiCaseId,
        binderId: selectedTtiBinderId,
        submissionId: efServiceSubmissionId,
        domainType: DOMAIN_TYPE,
        stateSubmissionType,
        includeFederal,
        federalProformaSubmissionId,
        sltBinderId,
      });
      setIsActionRunning(false);
      fetchStepsData();
    },
    [
      runTTIStep,
      fetchStepsData,
      setIsActionRunning,
      globalContext,
      binderFilingId,
      ttiYearId,
      ttiCaseId,
      selectedTtiBinderId,
      efServiceSubmissionId,
      stateSubmissionType,
      includeFederal,
      federalProformaSubmissionId,
      sltBinderId,
    ],
  );

  const onRunButtonClick = useCallback(
    async ({ action }) => {
      if (isNewYorkConsolidationCase && !areFiledEntitiesConfirmed) {
        setIsRunButtonClicked(true);
        return;
      }
      if (overallStatus.name === filingStatus.Accepted) {
        showConfirmModal({
          title: 'Return has already been accepted by the state agency',
          text:
            'Are you sure you want to regenerate a new return as you should only do so for states that support superseded returns?',
          confirmCallback: () => callTransmitStepsAction({ action }),
        });
      } else {
        await callTransmitStepsAction({ action });
      }
    },
    [
      showConfirmModal,
      callTransmitStepsAction,
      overallStatus,
      isNewYorkConsolidationCase,
      areFiledEntitiesConfirmed,
    ],
  );

  const isMissingFederalProforma = includeFederal && !federalProformaSubmissionId;
  const subStepWarning = useMemo(() => {
    if (overallStatus?.name === filingStatus.Submitted) {
      return 'Return submitted and awaiting acknowledgement';
    } else if (isNewYorkConsolidationCase && !areFiledEntitiesConfirmed && isRunButtonClicked) {
      return `You must select the '${NEW_YORK_CASE_FILED_ENTITIES_INFO}' checkbox before continuing.
        This checkbox attests to the fact that all single entity Form CT-3-A/BC have been transmitted
        to the state prior to filing this Form CT-3-A.`;
    }
    return '';
  }, [overallStatus, isNewYorkConsolidationCase, areFiledEntitiesConfirmed, isRunButtonClicked]);

  const disabled =
    isFetchingFederalAttachmentOptions ||
    isWizardActionRunning ||
    isFetchingEFileStepsData ||
    !ttiCaseId ||
    Boolean(subStepWarning) ||
    isNil(selectedTtiBinderId) ||
    isMissingFederalProforma;

  return (
    <>
      <div className={styles.returnContext}>
        <div className={styles.ttiSubmissionIdField}>
          <label className="a-form-label">State Submission ID</label>
          <input className="a-input-text" value={ttiSubmissionId || ''} disabled />
        </div>
        <ParamDropdown
          label="State Submission Type"
          options={stateSubmissionOptions}
          value={stateSubmissionType}
          handleChange={onStateSubmissionTypeChange}
          isFetchingContext={isWizardActionRunning}
        />
        <div className={`${styles.align} ${styles.includeFederal}`}>
          {isNewYorkConsolidationCase && (
            <Checkbox
              className={styles.nyCaseFiledEntitiesInfoCheckbox}
              checked={areFiledEntitiesConfirmed}
              onChange={toggleFiledEntitiesConfirm}
              disabled={isWizardActionRunning || isFetchingTtiBinders}
            >
              <span className="a-form-label">{NEW_YORK_CASE_FILED_ENTITIES_INFO}</span>
            </Checkbox>
          )}
          <Checkbox
            checked={includeFederal}
            onChange={toggleIncludeFederal}
            disabled={
              stateSubmissionType === stateSubmissionTypes.LINKED ||
              isWizardActionRunning ||
              isFetchingTtiBinders
            }
          >
            <span className="a-form-label">Include Federal</span>
          </Checkbox>
          {includeFederal && (
            <>
              <div className={styles.federalAttachmentDropdown}>
                <ParamDropdown
                  label="Federal Attachment"
                  options={federalAttachmentOptions}
                  value={federalProformaSubmissionId}
                  handleChange={setFederalProformaSubmissionId}
                  isFetchingContext={isWizardActionRunning || isFetchingFederalAttachmentOptions}
                />
              </div>
              <div className={styles.federalProformaSubmissionId}>
                <label className="a-form-label">Federal Submission ID</label>
                <div className={styles.federalProformaSubmissionIdInput}>
                  <Loading small isLoading={isFetchingFederalAttachmentOptions}>
                    <Input
                      disabled
                      value={federalProformaSubmissionId}
                      hasError={isMissingFederalProforma}
                      errMsg="Federal Submission ID is required"
                    />
                  </Loading>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <SubStep
        contextDebugInfo={contextDebugInfo}
        steps={getGenerateTransmissionSteps({
          taxYear: globalContext.taxYear,
          jurisdictionId: globalContext.jurisdictionId,
          filingTypeId: globalContext.filingTypeId,
        })}
        isSubmittingAnyAction={wizardState[currentStep].isStepActionRunning}
        isLoading={isLoading || isFetchingTtiBinders}
        disableNextStep={disabled}
        hasPermissionToRun={hasPermissionToRun}
        actionsBatch="create-state-transmission-file"
        stepData={transmitStepsData}
        callAction={onRunButtonClick}
        isLocked={isContextLocked}
        warning={subStepWarning}
        getDownloadFileComponent={getDownloadFileComponent}
      />
    </>
  );
};

GenerateTransmissionStep.propTypes = {
  binderFilingId: PropTypes.number,
  globalContext: globalContextPropTypes,

  ttiYearId: PropTypes.string,
  ttiCaseId: PropTypes.string,
  selectedTtiBinderId: PropTypes.number,
  efServiceSubmissionId: PropTypes.string,

  eFileStepsData: PropTypes.arrayOf(stepDataPropTypes).isRequired,
  fetchStepsData: PropTypes.func.isRequired,
  isFetchingEFileStepsData: PropTypes.bool.isRequired,
  runTTIStep: PropTypes.func.isRequired,
  hasPermissionToRun: PropTypes.bool.isRequired,

  contextDebugInfo: contextDebugInfoPropTypes,

  isContextLocked: PropTypes.bool.isRequired,
  isFetchingTtiBinders: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,

  wizardState: wizardStatePropTypes,
  setStepStatus: PropTypes.func.isRequired,
  overallStatus: overallStatusPropTypes,

  stateSubmissionType: PropTypes.string.isRequired,
  setStateSubmissionType: PropTypes.func.isRequired,
  includeFederal: PropTypes.bool.isRequired,
  setIncludeFederal: PropTypes.func.isRequired,

  showConfirmModal: PropTypes.func.isRequired,

  federalProformaSubmissionId: PropTypes.string.isRequired,
  setFederalProformaSubmissionId: PropTypes.func.isRequired,

  persistedWizardFieldsValues: PropTypes.shape({
    persistedStateSubmissionType: PropTypes.string,
    persistedIncludeFederal: PropTypes.bool,
    persistedFederalProformaSubmissionId: PropTypes.string,
    persistedSignatureValue: PropTypes.string,
    persistedIsNewYorkFormConfirmed: PropTypes.bool,
  }).isRequired,

  getDownloadFileComponent: PropTypes.func.isRequired,

  sltBinderId: PropTypes.number,
};

export default connect(
  state => ({
    globalContext: globalContextSelector(state),

    eFileStepsData: eFileStepsDataSelector(state),
    isFetchingEFileStepsData: isFetchingEFileStepsDataSelector(state),

    hasPermissionToRun: hasPermissionToEfileRunSelector(state),

    overallStatus: eFileOverallStatusSelector(state),

    persistedWizardFieldsValues: persistedWizardFieldsValuesSelector(state),
  }),
  {
    runTTIStep,
    showConfirmModal,
  },
)(GenerateTransmissionStep);
