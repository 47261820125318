const Yup = require('yup');

const getFilingFormschema = ({ stateFilingFormOptions }) => {
  return {
    returnDefinitionId: Yup.string().when('jurisdictionId', {
      is: value => Boolean(value),
      then: Yup.string().test(
        'hasStateFilingFormOptions',
        'All possible filing forms for this jurisdiction are already used by other returns',
        () => stateFilingFormOptions?.length,
      ),
    }),
  };
};

module.exports = { getFilingFormschema };
