const Yup = require('yup');

const { dataTypes } = require('../constants');

const { stateOverrideValueSchema } = require('./stateOverrideValue.validation');

const updateInformationalDataFormsField = Yup.object()
  .shape({
    rowsToUpdate: Yup.array()
      .of(
        Yup.object()
          .shape({
            taxYear: Yup.number().required(),
            period: Yup.number().required(),
            consolidationId: Yup.string().required(),
            infoAcctId: Yup.string().required(),
            dataType: Yup.string().nullable().oneOf(Object.values(dataTypes)),
            value: stateOverrideValueSchema,
          })
          .required(),
      )
      .notOneOf([undefined, null]), // eslint-disable-line no-undefined
  })
  .strict();

module.exports = { updateInformationalDataFormsField };
