import {
  defaultColumnDefinition,
  adminColumnDefinition,
  selectCellEditorParamsFactory,
  mapValueFormatterFactory,
} from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  NumberCellEditor,
  EditableCheckboxCellRenderer,
} from '../../shared/columnDefinitions/cellRenderers';
import { defaultAgRichSelectCellEditor } from '../../shared/columnDefinitions/cellEditor';

const getColumnDefinitions = ({
  isInEditMode,
  updateRow,
  deleteRow,
  formOptions,
  binderItemTypeOptions,
}) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: () => false,
    onDeleteIconClick: deleteRow,
  });

  const checkboxCellClassRules = {
    ...defaultColumnDefinition.cellClassRules,
    'editable-cell': ({ data }) => isInEditMode && data.isSeparate,
  };

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Binder Item Type',
      field: 'binderItemType',
      cellStyle: {
        textAlign: 'center',
      },
      valueFormatter: mapValueFormatterFactory(binderItemTypeOptions),
      editable: isInEditMode,
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(binderItemTypeOptions),
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Add for Each Consolidated Member',
      field: 'addForEachMember',
      cellStyle: {
        textAlign: 'center',
      },
      cellRendererParams: {
        isDisabled: ({ data }) => !isInEditMode || !data.isSeparate,
      },
      cellRenderer: EditableCheckboxCellRenderer,
      cellClassRules: checkboxCellClassRules,
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Binder Item',
      field: 'formId',
      valueFormatter: mapValueFormatterFactory(formOptions),
      editable: isInEditMode,
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(formOptions),
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Display Order',
      field: 'displayOrder',
      editable: isInEditMode,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        formatValue: value => Number.parseInt(value, 10),
      },
      onCellValueChanged,
    },
  ];
};

export default getColumnDefinitions;
