import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Button } from '@pwc/appkit-react';
import isEqual from 'lodash.isequal';
import isEqualWith from 'lodash.isequalwith';
import differenceBy from 'lodash.differenceby';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';
import { Routes } from '@common-packages/routes-definitions';
import { dataModelSchemas } from '@common-packages/validators';
import omit from 'lodash.omit';

import withPermissions from '../../shared/authorization/withPermissionsHoc.container';
import { customerPermissionsPropTypes } from '../../shared/propTypes/customerPropTypes';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import FaIcon from '../../shared/displayComponents/faIcon/faIcon.component';
import Loading from '../../shared/displayComponents/loading.component';
import ListHierarchy from '../../shared/displayComponents/listHierarchy/listHierarchy';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { setActiveTab } from '../expressionBuilder/store/actions';
import { TABS_DEFINITIONS } from '../expressionBuilder/functions/constants';
import {
  dataModelsSelector,
  isFetchingDataModelsSelector,
  dataModelSelector,
  fullDataModelSelector,
  datasetsTreeSelector,
  isFetchingDatasetsSelector,
  datasetSelector,
  dataItemsSelector,
  isFetchingDataItemsSelector,
  dataTypesSelector,
  isFetchingDataTypesSelector,
  isUpdatingDataModelSelector,
  isAddingDatasetSelector,
  datasetsSelector,
  allDatasetsSelector,
} from '../../shared/store/dataModels/selectors';
import {
  validateInstanceFilteringExpression,
  fetchChildDatasets,
} from '../../shared/store/dataModels/api';
import {
  taxYearSelector,
  jurisdictionIdSelector,
  isFetchingJurisdictionsSelector,
} from '../store/selectors';
import useFetch from '../../shared/hooks/useFetch.hook';
import {
  fetchDataModels,
  fetchDatasets,
  fetchDataItems,
  fetchDataTypes,
  updateDataModel,
  selectDataModel,
  selectDataset,
  selectDataItem,
  fetchAllDatasets,
  deleteDataItemInstancesAndDef,
  deleteDatasetDef,
} from '../../shared/store/dataModels/actions';
import { useRowEditMode } from '../../shared/editMode';
import { infoNotification, errorNotification } from '../../shared/notification/store/actions';
import useEditModeErrorColumn from '../../shared/editMode/useEditModeErrorColumn.hook';
import useModal from '../../shared/hooks/useModal.hook';
import { fetchDataModelLinks } from '../../shared/store/actions';
import { useQuerySelectionLists } from '../../shared/queries/selectionLists';
import { useQueryFindCheckboxGroups } from '../../shared/queries/checkboxGroups';
import { QueryKeys } from '../../shared/queries';
import WarningModal from '../../shared/displayComponents/warningModal.component';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import { useUpdateDevelopmentContextFromQueryParams } from '../store/hooks';
import useOpenInNewTab from '../../shared/hooks/useOpenInNewTab';
import { EVERYWHERE_JURISDICTION_ID } from '../../shared/constants';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import WarningDeleteDatasetModal from './warningDeleteDatasetModal/warningDeleteDatasetModal.component';
import WarningEditDatasetModal from './warningEditDatasetModal/warningEditDatasetModal.component';
import AddModelModal from './addDataModelModal/addModelModal.container';
import AddDatasetModal from './addDatasetModal/addDatasetModal.container';
import DatasetForm from './DatasetForm.component';
import DataModelForm from './dataModelForm.component';
import getColumnDefinitions from './dataModels.columnDefinitions';
import {
  isSomeDataItemUsedInGfunction,
  getChildDatasetsDataItems,
  areChildDatasetsUsedInForms,
} from './utils';
import hasPermissionToPropagateTool from './hasPermissionToPropagateTool';
import styles from './dataModels.module.scss';
import PropagateModelModal from './propagateModelModal/propagateModelModal.container';
import {
  CURRENCY_DATA_TYPE,
  WARNING_MODAL_TITLE,
  WARNING_MODAL_MESSAGE,
  WARNING_MODAL_DATA_MESSAGE,
  WARNING_EDIT_DATASET_MODAL_TITLE,
  WARNING_EDIT_DATASET_MODAL_MESSAGE,
  WARNING_EDIT_DATASET_PRECONFIRM_MODAL_MESSAGE,
  WARNING_EDIT_DATASET_CONFIRM_MODAL_MESSAGE,
  CalculationTypes,
  DISPLAY_ORDER_VAL_TO_INCREMENT_BY,
  STRING_DATA_TYPE,
  BOOLEAN_DATA_TYPE,
  DEFAULT_DATA_ITEM_DATA_LENGTH_VAL_FOR_STRING_DATA_TYPE,
  DEFAULT_DATA_ITEM_DATA_LENGTH_VAL,
  WARNING_CHECKBOX_GROUP_MODAL_TITLE,
  WARNING_EDIT_CHECKBOX_GROUP_MODAL_MESSAGE,
  WARNING_EDIT_DATASET_CHECKBOX_GROUP_MODAL_MESSAGE,
} from './constants';
import hasPermissionToManageAliases from './hasPermissionToManageAliases';
import useDeleteDataModel from './useDeleteDataModel';
import useValidateCheckboxGroup from './hooks/useValidateCheckboxGroup.hook';

const UNSAVED_EDITS_NOTIFICATION = 'Unsaved edits pending, please cancel or save';
const NEW_DATA_ITEM_PREFIX_INDICATOR = 'NEW_';

const getRowId = params => params?.data?.id;

const parseDatasetValues = dataset => {
  if (!dataset) {
    return;
  }

  return {
    ...dataset,
    calculation: dataset.aggregateSeparateInstances
      ? CalculationTypes.AGG_SEP_INSTANCES
      : CalculationTypes.ALLOW_CONS,
    description: dataset.description || '',
    instanceName: dataset.instanceName || '',
    instanceIdentifier: dataset.instanceIdentifier || '',
    instanceFilteringExpression: dataset.instanceFilteringExpression || '',
  };
};

const DataModels = ({ customerPermissions }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const datasetFormEl = useRef(null);
  const dataModelEl = useRef(null);

  useUpdateDevelopmentContextFromQueryParams();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const isFetchingJurisdictions = useSelector(isFetchingJurisdictionsSelector);
  const dataModels = useSelector(dataModelsSelector);
  const isFetchingDataModels = useSelector(isFetchingDataModelsSelector);
  const dataModel = useSelector(dataModelSelector);
  const fullDataModel = useSelector(fullDataModelSelector);
  const datasets = useSelector(datasetsSelector);
  const allDatasets = useSelector(allDatasetsSelector);
  const datasetsTree = useSelector(datasetsTreeSelector);
  const isFetchingDatasets = useSelector(isFetchingDatasetsSelector);
  const dataset = parseDatasetValues(useSelector(datasetSelector));
  const dataItems = useSelector(dataItemsSelector);
  const isFetchingDataItems = useSelector(isFetchingDataItemsSelector);
  const dataTypes = useSelector(dataTypesSelector);
  const isFetchingDataTypes = useSelector(isFetchingDataTypesSelector);
  const isUpdatingDataModel = useSelector(isUpdatingDataModelSelector);
  const isAddingDataset = useSelector(isAddingDatasetSelector);

  const hasPermissionToManage = hasPermissionToManageAliases(customerPermissions);
  const [isDatasetDirty, setIsDatasetDirty] = useState(false);
  const [isDataModelDirty, setIsDataModelDirty] = useState(false);
  const [areDataItemsDirty, setAreDataItemsDirty] = useState(false);

  const { queryParams, setParams } = useUrlParams();

  const { showModal: showAddModelModal, modalProps: addModelModalProp } = useModal();
  const { showModal: showAddDatasetModal, modalProps: addDatasetModalProps } = useModal();
  const { showModal: showWarningModal, modalProps: warningModalProps } = useModal();
  const { showModal: showPropagateModelModal, modalProps: propagateModelModalProps } = useModal();

  const {
    showModal: showWarningDeleteDatasetModal,
    modalProps: warningDeleteDatasetModalProps,
  } = useModal();

  const {
    showModal: showWarningEditDatasetModal,
    modalProps: warningEditDatasetModalProps,
  } = useModal();

  const {
    showModal: showWarningCheckboxGroupModal,
    modalProps: warningCheckboxGroupModalProps,
  } = useModal();

  const {
    data: validationResults,
    fetch: validateExpression,
    isFetching: isValidatingExpression,
    resetData: resetValidationResults,
  } = useFetch({ action: validateInstanceFilteringExpression });

  const {
    data: childDatasets,
    fetch: fetchChildDatasetsData,
    isFetching: isFetchingChildDatasetsData,
  } = useFetch({ action: fetchChildDatasets });

  const { data: selectionLists, isFetching: isFetchingSelectionLists } = useQuerySelectionLists({
    params: { taxYear, jurisdictionId, shouldIncludeEverywhereJurisdiction: true },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const selectionListsOptions = useMemo(
    () =>
      selectionLists.map(({ id, name, jurisdictionId }) => ({
        value: id,
        label: jurisdictionId === EVERYWHERE_JURISDICTION_ID ? `EV - ${name}` : name,
      })),
    [selectionLists],
  );

  const {
    data: checkboxGroupsData,
    isFetching: isFetchingCheckboxGroups,
  } = useQueryFindCheckboxGroups({
    params: { taxYear, jurisdictionId, shouldIncludeEverywhereJurisdiction: true },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const checkboxGroupsOptions = useMemo(
    () =>
      checkboxGroupsData.checkboxGroups.map(({ id, name, jurisdictionId }) => ({
        value: id,
        label: jurisdictionId === EVERYWHERE_JURISDICTION_ID ? `EV - ${name}` : name,
      })),
    [checkboxGroupsData.checkboxGroups],
  );

  const {
    gridApi,
    columnApi,
    clonedRowData,
    navigationPrompt,
    onGridReady,
    updateRow,
    deleteRow,
    addRow,
    findChanges,
  } = useRowEditMode({
    appendNewRows: true,
    rowData: dataItems,
    getUniqueRowId: getRowId,
  });

  const { validateEditModeColumnsAndShowPossibleErrors } = useEditModeErrorColumn({
    validationSchema: dataModelSchemas.dataItemsSchema({ taxYear }),
    gridApi,
    columnApi,
  });

  const { validateCheckboxGroupHasMoreThanOneDataItem } = useValidateCheckboxGroup({
    gridApi,
  });

  const hasEdits = isDatasetDirty || isDataModelDirty;
  const dataModelValue = dataModel?.value;
  const datasetId = dataset?.id;
  const datasetWithChildDatasets = useMemo(
    () => [{ ...dataset, dataItems }, ...(childDatasets || [])],
    [childDatasets, dataItems, dataset],
  );

  const isGridLoading = isFetchingDatasets || isFetchingDataItems || isFetchingJurisdictions;

  const onSelectionListsRefreshClick = useCallback(() => {
    queryClient.resetQueries(QueryKeys.SelectionLists.SelectionLists);
  }, [queryClient]);

  const onCheckboxGroupRefreshClick = useCallback(() => {
    queryClient.resetQueries(QueryKeys.CheckboxGroups.Data);
  }, [queryClient]);

  const removeHasEdits = useCallback(() => {
    setIsDatasetDirty(false);
    setIsDataModelDirty(false);
    setAreDataItemsDirty(false);
  }, []);

  const onDataModelDelete = useCallback(() => {
    removeHasEdits();
    resetValidationResults();
    setAreDataItemsDirty(false);
    warningDeleteDatasetModalProps.hideModal();
  }, [removeHasEdits, resetValidationResults, warningDeleteDatasetModalProps]);

  const { deleteDataModel, isLoading: isDeleteDataModelButtonLoading } = useDeleteDataModel({
    showWarningDeleteDatasetModal,
    onDelete: onDataModelDelete,
  });

  const checkForEditsAndNotifyUser = useCallback(() => {
    if (hasEdits) {
      dispatch(infoNotification(UNSAVED_EDITS_NOTIFICATION));
      return true;
    }
  }, [dispatch, hasEdits]);

  const updateDataItemRow = useCallback(
    ({ data }) => {
      // when switching data type to string, we want a different default value (in this case 0)
      if (data.dataType === STRING_DATA_TYPE && !data.dataLength) {
        data.dataLength = DEFAULT_DATA_ITEM_DATA_LENGTH_VAL_FOR_STRING_DATA_TYPE;
      } else if (data.dataType !== STRING_DATA_TYPE) {
        // if switching to non-string data type, reset to null
        data.dataLength = DEFAULT_DATA_ITEM_DATA_LENGTH_VAL;
      }

      if (!data.aliasName && data.aliasType) {
        data.aliasType = null;
      }

      updateRow(data);

      setAreDataItemsDirty(true);
    },
    [updateRow, setAreDataItemsDirty],
  );

  const getMaxDisplayOrder = gridApi => {
    let maxDisplayOrder = 0;
    gridApi.forEachNode(({ data }) => {
      maxDisplayOrder = Math.max(maxDisplayOrder, data.displayOrder);
    });
    return maxDisplayOrder;
  };

  const addDataItemRow = useCallback(() => {
    const selectedNode = gridApi.getSelectedNodes()[0];

    if (selectedNode) {
      const currentDataItems = gridApi.getModel().rowsToDisplay;
      for (const dataItemNode of currentDataItems) {
        // if selected node and current node (that's not selected) have the same displayOrder,
        // increment current node
        if (
          (!dataItemNode.selected &&
            dataItemNode.data.displayOrder === selectedNode.data.displayOrder) ||
          dataItemNode.data.displayOrder > selectedNode.data.displayOrder
        ) {
          dataItemNode.data.displayOrder += DISPLAY_ORDER_VAL_TO_INCREMENT_BY;
          updateRow(dataItemNode.data);
        }
      }
    }

    // we're setting default dataLength to 'null' for all data types because '0' implies no limit
    // the only dataType that can have no limit is a string datatype
    // (this is how it's set in the print-service)
    addRow({
      id: NEW_DATA_ITEM_PREFIX_INDICATOR + uuid(),
      name: '',
      description: '',
      displayName: '',
      dataType: CURRENCY_DATA_TYPE,
      dataLength: DEFAULT_DATA_ITEM_DATA_LENGTH_VAL,
      displayOrder: selectedNode
        ? selectedNode.data.displayOrder + DISPLAY_ORDER_VAL_TO_INCREMENT_BY
        : getMaxDisplayOrder(gridApi) + DISPLAY_ORDER_VAL_TO_INCREMENT_BY,
      gfunctions: [],
      isHidden: 0,
      inactive: 0,
    });

    if (selectedNode) {
      const currentDataItems = gridApi.getModel().rowsToDisplay;
      let selectedNodeDisplayOrder = null;
      for (const dataItemNode of currentDataItems) {
        // once we find the selected node, store its displayOrder val
        if (dataItemNode.selected) {
          selectedNodeDisplayOrder = dataItemNode.data.displayOrder;
          continue;
        }
        // this logic helps use make sure if the selected row and the
        // next rows have the same display order, select the new dataItem
        // that was added
        if (
          selectedNodeDisplayOrder &&
          dataItemNode.data.displayOrder > selectedNodeDisplayOrder &&
          dataItemNode.data.id.startsWith(NEW_DATA_ITEM_PREFIX_INDICATOR)
        ) {
          dataItemNode.setSelected(true);
          gridApi.ensureNodeVisible(dataItemNode);
          break;
        }
      }
    } else {
      const lastRowNode = gridApi.getModel().rowsToDisplay.slice(-1)[0];
      lastRowNode.setSelected(true);
      gridApi.ensureNodeVisible(lastRowNode);
    }
  }, [addRow, updateRow, gridApi]);

  const onDataModelChange = useCallback(
    selectedDataModelId => {
      if (!selectedDataModelId || dataModel?.value === selectedDataModelId) {
        return;
      }

      const hasEdits = checkForEditsAndNotifyUser();
      if (!hasEdits) {
        resetValidationResults();
        dispatch(selectDataModel(selectedDataModelId));
      }
    },
    [dispatch, resetValidationResults, checkForEditsAndNotifyUser, dataModel],
  );

  const onDatasetClick = useCallback(
    ({ id }) => {
      if (datasetId === id) {
        return;
      }
      const hasEdits = checkForEditsAndNotifyUser();
      if (!hasEdits) {
        resetValidationResults();
        dispatch(selectDataset(id));
      }
    },
    [dispatch, checkForEditsAndNotifyUser, resetValidationResults, datasetId],
  );

  const onValidateClick = useCallback(() => {
    validateExpression({
      datasetDefId: dataset?.id,
      instanceFilteringExpression: datasetFormEl.current?.values?.instanceFilteringExpression,
    });
  }, [validateExpression, dataset]);

  const addModel = useCallback(() => {
    const hasEdits = checkForEditsAndNotifyUser();
    if (!hasEdits) {
      showAddModelModal();
    }
  }, [showAddModelModal, checkForEditsAndNotifyUser]);

  const addDataset = useCallback(() => {
    const hasEdits = checkForEditsAndNotifyUser();
    if (!hasEdits) {
      showAddDatasetModal({ dataModel, dataset, datasets });
    }
  }, [checkForEditsAndNotifyUser, showAddDatasetModal, dataModel, dataset, datasets]);

  const someDataItemIsUsedInGfunction = isSomeDataItemUsedInGfunction([
    ...dataItems,
    ...getChildDatasetsDataItems(childDatasets),
  ]);

  const deleteDataset = useCallback(() => {
    if (
      dataset.forms ||
      someDataItemIsUsedInGfunction ||
      areChildDatasetsUsedInForms(childDatasets)
    ) {
      showWarningDeleteDatasetModal(datasetWithChildDatasets);
      return;
    }

    dispatch(
      showConfirmModal({
        title: 'Delete dataset?',
        text: `Datasets should not be deleted if used in client data. Deleting the dataset will also
         remove all child datasets and any dataset items that have been created. Are you sure ${dataset.name} dataset should be deleted?`,
        confirmCallback: async () => {
          await dispatch(
            deleteDatasetDef({
              datasetDefId: dataset.id,
              taxYear,
            }),
          );

          dispatch(fetchDatasets({ taxYear, jurisdictionId, dataModelId: dataModel.value }));
        },
      }),
    );
  }, [
    dispatch,
    showWarningDeleteDatasetModal,
    dataset,
    datasetWithChildDatasets,
    someDataItemIsUsedInGfunction,
    taxYear,
    jurisdictionId,
    dataModel,
    childDatasets,
  ]);

  const renderEditDatasetWarningMessage = useCallback(
    () => (
      <div>
        <p>{WARNING_EDIT_DATASET_MODAL_MESSAGE}</p>
        <p>{WARNING_EDIT_DATASET_PRECONFIRM_MODAL_MESSAGE}</p>
        <p>{WARNING_EDIT_DATASET_CONFIRM_MODAL_MESSAGE}</p>
      </div>
    ),
    [],
  );

  const datasetAddedCallback = useCallback(
    async addedDatasetId => {
      await dispatch(fetchDatasets({ taxYear, jurisdictionId, dataModelId: dataModel.value }));
      dispatch(selectDataset(addedDatasetId));
    },
    [dispatch, taxYear, jurisdictionId, dataModel],
  );

  const modelAddedCallback = useCallback(
    async dataModelId => {
      await dispatch(fetchDataModels({ taxYear, jurisdictionId }));
      dispatch(selectDataModel(dataModelId));
    },
    [dispatch, taxYear, jurisdictionId],
  );

  const reset = useCallback(async () => {
    removeHasEdits();
    dataModelEl.current?.resetForm();

    await Promise.all([
      dispatch(fetchDataModels({ taxYear, jurisdictionId })),
      dispatch(fetchDatasets({ taxYear, jurisdictionId, dataModelId: dataModel.value })),
      dispatch(fetchDataItems({ datasetId, taxYear })),
    ]);

    dispatch(selectDataModel(dataModel.value));
    dispatch(selectDataset(datasetId));
    resetValidationResults();
    setAreDataItemsDirty(false);
  }, [
    dispatch,
    resetValidationResults,
    dataModel,
    datasetId,
    jurisdictionId,
    taxYear,
    removeHasEdits,
  ]);

  const getDataItemsChanges = useCallback(() => {
    const { rowsPairsWithChanges, rowsToAdd } = findChanges();

    const filterRow = rows =>
      rows.map(row => ({
        ...omit(row, 'gfunctions'),
        isHidden: row.isHidden ? 1 : 0,
        inactive: row.inactive ? 1 : 0,
        selectionListId: row.dataType === STRING_DATA_TYPE ? row.selectionListId : null,
        checkboxGroupId: row.dataType === BOOLEAN_DATA_TYPE ? row.checkboxGroupId : null,
      }));

    return [
      ...filterRow(rowsPairsWithChanges.map(({ newRow }) => newRow)),
      ...filterRow(rowsToAdd),
    ];
  }, [findChanges]);

  const onSubmit = useCallback(
    async ({
      description,
      instanceName,
      instanceIdentifier,
      maxInstances,
      calculation,
      instanceFilteringExpression,
    }) => {
      const datasetData = {
        description,
        instanceName,
        instanceIdentifier,
        maxInstances,
        allowCons: calculation === CalculationTypes.ALLOW_CONS,
        aggregateSeparateInstances: calculation === CalculationTypes.AGG_SEP_INSTANCES,
        instanceFilteringExpression:
          calculation === CalculationTypes.ALLOW_CONS ? null : instanceFilteringExpression,
      };
      const {
        id: dataModelId,
        description: dataModelDescription,
        contextTypeId,
        isCommon,
        shouldDisplayWorkpaperOnNavigator,
        inactive,
      } = dataModelEl.current?.values;
      const dataModelData = {
        description: dataModelDescription,
        contextTypeId,
        isCommon,
        shouldDisplayWorkpaperOnNavigator,
        inactive,
      };
      await dispatch(
        updateDataModel({
          taxYear,
          jurisdictionId,
          dataModelId,
          datasetId,
          dataModel: isDataModelDirty ? dataModelData : {},
          dataset: isDatasetDirty ? datasetData : {},
          dataItems: getDataItemsChanges(),
        }),
      );
      reset();
    },
    [
      dispatch,
      reset,
      datasetId,
      taxYear,
      jurisdictionId,
      isDatasetDirty,
      isDataModelDirty,
      getDataItemsChanges,
    ],
  );

  const validateDataModel = useCallback(
    data => {
      setIsDataModelDirty(
        !isEqualWith(data, fullDataModel, (val1, val2, key) => {
          if (key === 'description') {
            // for description we treat `null` and empty strings as the same value
            return (val1 || '') === (val2 || '');
          }
        }),
      );
    },
    [fullDataModel],
  );

  const renderDatasetForm = useCallback(
    formikProps => {
      const aggregateSepInstances =
        formikProps?.values?.calculation === CalculationTypes.AGG_SEP_INSTANCES;

      const expression = formikProps?.values?.instanceFilteringExpression;

      return (
        <DatasetForm
          disabled={!dataset}
          aggregateSepInstances={aggregateSepInstances}
          onValidateClick={onValidateClick}
          validationResults={validationResults}
          isValidatingExpression={isValidatingExpression}
          instanceFilteringExpression={expression}
          isEditing
        />
      );
    },
    [onValidateClick, validationResults, dataset, isValidatingExpression],
  );

  const deleteDataItem = useCallback(
    dataItemRow => {
      const isNewDataItem = dataItemRow.id?.startsWith(NEW_DATA_ITEM_PREFIX_INDICATOR);
      const currentDataItems = gridApi.getModel().rowsToDisplay.map(({ data }) => data);
      const newDataItems = differenceBy(currentDataItems, dataItems, 'id');
      const areExistingDataItemsEdited = !isEqual(clonedRowData, dataItems);

      if (isNewDataItem) {
        deleteRow(dataItemRow);
        return;
      }

      if (newDataItems.length || areExistingDataItemsEdited) {
        dispatch(infoNotification(UNSAVED_EDITS_NOTIFICATION));
        return true;
      }

      if (dataItemRow.gfunctions?.length) {
        showWarningModal(dataItemRow.gfunctions);
        return;
      }

      dispatch(
        showConfirmModal({
          title: 'Delete data item?',
          text:
            'This delete is only intended for cases in which client data item instances have not been created. Are you sure you want to delete?',
          confirmCallback: async () => {
            await dispatch(
              deleteDataItemInstancesAndDef({
                dataItemDefId: dataItemRow.id,
                dataItemDefName: dataItemRow.name,
                datasetDefId: datasetId,
                taxYear,
              }),
            );
            dispatch(fetchDataItems({ datasetId, taxYear }));
            if (taxYear && jurisdictionId) {
              dispatch(fetchDataModelLinks({ taxYear, jurisdictionId }));
            }
          },
        }),
      );
    },
    [
      dispatch,
      deleteRow,
      showWarningModal,
      gridApi,
      dataItems,
      clonedRowData,
      datasetId,
      taxYear,
      jurisdictionId,
    ],
  );

  const navigateToExpressionBuilder = useCallback(
    ({ id }) => {
      dispatch(selectDataItem(id));
      dispatch(setActiveTab(TABS_DEFINITIONS[3].type));
      history.push(Routes.expressionBuilder.MAIN);
    },
    [dispatch, history],
  );

  const openInNewTab = useOpenInNewTab();

  const handleSelectionListsButtonClick = useCallback(() => {
    openInNewTab(Routes.selectionLists.MAIN, { taxYear, jurisdictionId });
  }, [openInNewTab, taxYear, jurisdictionId]);

  const handleCheckboxGroupButtonClick = useCallback(() => {
    openInNewTab(Routes.checkboxGroups.MAIN, { taxYear, jurisdictionId });
  }, [openInNewTab, taxYear, jurisdictionId]);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: deleteDataItem,
        onExpressionBuilderIconClick: navigateToExpressionBuilder,
        dataTypes,
        isFetchingDataTypes,
        selectionLists: selectionListsOptions,
        isFetchingCheckboxGroups,
        checkboxGroups: checkboxGroupsOptions,
        isFetchingSelectionLists,
        hasPermissionToManageAliases: hasPermissionToManage,
      }),
    [
      deleteDataItem,
      navigateToExpressionBuilder,
      dataTypes,
      isFetchingDataTypes,
      selectionListsOptions,
      isFetchingSelectionLists,
      checkboxGroupsOptions,
      isFetchingCheckboxGroups,
      hasPermissionToManage,
    ],
  );

  useEffect(() => {
    if (
      dataModels.length &&
      queryParams?.dataModelId &&
      queryParams?.datasetId &&
      queryParams?.dataItemId
    ) {
      dispatch(selectDataModel(queryParams.dataModelId));
      dispatch(selectDataset(queryParams.datasetId));
      dispatch(selectDataItem(queryParams.dataItemId));
      setParams({ queryParams: { dataModelId: null, datasetId: null, dataItemId: null } });
    }
  }, [
    dispatch,
    setParams,
    dataModels,
    datasets,
    queryParams.dataItemId,
    queryParams.dataModelId,
    queryParams.datasetId,
  ]);

  useEffect(() => {
    if (datasetId && taxYear) {
      dispatch(fetchDataItems({ datasetId, taxYear }));
      fetchChildDatasetsData({ datasetDefId: datasetId, taxYear });
      if (jurisdictionId) {
        dispatch(fetchDataModelLinks({ taxYear, jurisdictionId }));
      }
    }
  }, [dispatch, fetchChildDatasetsData, datasetId, taxYear, jurisdictionId]);

  useEffect(() => {
    dispatch(fetchDataTypes());
  }, [dispatch]);

  useEffect(() => {
    if (taxYear && jurisdictionId) {
      dispatch(fetchAllDatasets({ taxYear, jurisdictionId }));
    }
  }, [dispatch, jurisdictionId, taxYear]);

  useEffect(() => {
    if (taxYear && jurisdictionId && !dataModelValue) {
      removeHasEdits();
      dispatch(fetchDataModels({ taxYear, jurisdictionId }));
    }
  }, [dispatch, removeHasEdits, taxYear, jurisdictionId, dataModelValue]);

  useEffect(() => {
    if (taxYear && jurisdictionId && dataModelValue && !dataset) {
      dispatch(fetchDatasets({ taxYear, jurisdictionId, dataModelId: dataModelValue }));
    }
  }, [dispatch, dataModelValue, taxYear, jurisdictionId, dataset]);

  const renderAdditionalHeaderElements = useCallback(
    () => (
      <div className={styles.renderAdditionalHeaderElementsContainer}>
        <div className={styles.renderAdditionalHeaderElement}>
          <Button size="lg" onClick={handleSelectionListsButtonClick}>
            Selection Lists...
          </Button>
          <button title="Refresh Selection Lists" onClick={onSelectionListsRefreshClick}>
            <FaIcon icon="sync" size={16} />
          </button>
        </div>
        <div className={styles.renderAdditionalHeaderElement}>
          <Button size="lg" onClick={handleCheckboxGroupButtonClick}>
            Checkbox Groups...
          </Button>
          <button title="Refresh checkbox groups list" onClick={onCheckboxGroupRefreshClick}>
            <FaIcon icon="sync" size={16} />
          </button>
        </div>
        {customerPermissions && hasPermissionToPropagateTool(customerPermissions) ? (
          <Button
            size="lg"
            onClick={showPropagateModelModal}
            disabled={!dataModel}
            className={styles.renderAdditionalHeaderElement}
          >
            Propagate Data Model...
          </Button>
        ) : null}
      </div>
    ),
    [
      handleCheckboxGroupButtonClick,
      onCheckboxGroupRefreshClick,
      showPropagateModelModal,
      handleSelectionListsButtonClick,
      onSelectionListsRefreshClick,
      customerPermissions,
      dataModel,
    ],
  );

  const save = useCallback(async () => {
    if (!dataset) {
      return dispatch(errorNotification(`Can't save a data model without any dataset`));
    }
    const areDataItemsValid = await validateEditModeColumnsAndShowPossibleErrors();
    const isDataModelValid = dataModelEl.current?.isValid;
    const checkboxGroupsWithOneItem = validateCheckboxGroupHasMoreThanOneDataItem(
      checkboxGroupsOptions,
    );
    const areCheckboxGroupValid = !checkboxGroupsWithOneItem.length;

    if (!areCheckboxGroupValid) {
      showWarningCheckboxGroupModal(checkboxGroupsWithOneItem);
    }

    if (areDataItemsValid && isDataModelValid && areCheckboxGroupValid) {
      datasetFormEl.current?.handleSubmit();
    }
  }, [
    dispatch,
    validateEditModeColumnsAndShowPossibleErrors,
    dataset,
    showWarningCheckboxGroupModal,
    validateCheckboxGroupHasMoreThanOneDataItem,
    checkboxGroupsOptions,
  ]);

  const editDataset = useCallback(async () => {
    const exisitingDataItemsNames = dataItems.map(({ name }) => name);
    const isExistingDataItemNameChanged = gridApi
      ?.getModel()
      ?.rowsToDisplay?.some(
        ({ data }) =>
          !data?.id?.startsWith(NEW_DATA_ITEM_PREFIX_INDICATOR) &&
          !exisitingDataItemsNames.includes(data?.name),
      );
    const isCommon = dataModelEl.current?.values?.isCommon;

    if (isExistingDataItemNameChanged && isCommon) {
      showWarningEditDatasetModal();
    } else {
      await save();
    }
  }, [showWarningEditDatasetModal, save, dataItems, gridApi, dataModelEl]);

  const validateDataset = useCallback(
    data => {
      setIsDatasetDirty(
        data.calculation === CalculationTypes.AGG_SEP_INSTANCES
          ? !isEqual(data, dataset)
          : !isEqualWith(
              data,
              dataset,
              // Turned off undefined error here because of the way isEqualWith works - we don't want to compare objects by this key
              // eslint-disable-next-line no-undefined
              (_, __, key) => key === 'instanceFilteringExpression' || undefined,
            ),
      );
    },
    [dataset],
  );

  return (
    <>
      <HeaderWithParamDropdownsWrapper
        showHeaderTaxYearDropdown
        showHeaderJurisdictionDropdown
        renderAdditionalElements={renderAdditionalHeaderElements}
        enableEverywhereJurisdiction
      />
      <div className={`row grid-row ${styles.dataModelsContainer}`}>
        <div className="col-4">
          <div className="row mb-sm-3">
            <ParamDropdown
              label="Data Model:"
              value={dataModel}
              options={dataModels}
              handleChange={onDataModelChange}
              isBusy={isFetchingDataModels}
              className={`col ${styles.dataModelsDropdown}`}
            />
          </div>
          <div className="row">
            <div className="col add-button-column align-center">
              <Button
                size="lg"
                disabled={!taxYear || !jurisdictionId || isFetchingDataModels}
                className="add-button"
                onClick={addModel}
              >
                New Model
              </Button>
              <Button
                size="lg"
                disabled={
                  !dataModel ||
                  isFetchingDataModels ||
                  isFetchingChildDatasetsData ||
                  isFetchingDataItems
                }
                className="add-button"
                kind="secondary"
                onClick={deleteDataModel}
                isLoading={isDeleteDataModelButtonLoading}
              >
                Delete Model
              </Button>
            </div>
          </div>
          <div>
            <Loading isLoading={isFetchingDataModels}>
              <Formik
                innerRef={dataModelEl}
                initialValues={fullDataModel}
                enableReinitialize
                validate={validateDataModel}
                validateOnChange
                validationSchema={dataModelSchemas.updateDataModelFieldsSchema}
              >
                <DataModelForm />
              </Formik>
            </Loading>
          </div>
          <div className={`row ${styles.datasetsHeader}`}>
            <div className={`col ${styles.labelCol}`}>Datasets:</div>
            <div className="col add-button-column align-center">
              <Button
                size="lg"
                disabled={isFetchingDatasets || isFetchingDataItems || !dataModel}
                className="add-button"
                onClick={addDataset}
              >
                New Dataset
              </Button>
              <Button
                size="lg"
                onClick={deleteDataset}
                disabled={
                  !dataset ||
                  isFetchingDatasets ||
                  isFetchingDataItems ||
                  isFetchingChildDatasetsData ||
                  !dataModel
                }
                className="add-button"
                kind="secondary"
              >
                Delete Dataset
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ListHierarchy
                isLoading={
                  isFetchingDatasets ||
                  isAddingDataset ||
                  isFetchingDataItems ||
                  isFetchingChildDatasetsData
                }
                items={datasetsTree}
                selectedItemIds={[datasetId]}
                itemOnClick={onDatasetClick}
              />
            </div>
          </div>
        </div>
        <div className={`col-8 ${styles.gridContainer}`}>
          <div className="row">
            <div className="col add-button-column align-center">
              <Loading isLoading={isUpdatingDataModel}>
                <Button
                  size="lg"
                  disabled={!(hasEdits || areDataItemsDirty) || isFetchingDataItems}
                  className="add-button"
                  onClick={editDataset}
                >
                  Save
                </Button>
                <Button
                  size="lg"
                  disabled={!(hasEdits || areDataItemsDirty) || isFetchingDataItems}
                  className="add-button"
                  onClick={reset}
                  kind="secondary"
                >
                  Cancel
                </Button>
              </Loading>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Loading isLoading={isFetchingDatasets}>
                <Formik
                  innerRef={datasetFormEl}
                  validate={validateDataset}
                  onSubmit={onSubmit}
                  initialValues={dataset}
                  enableReinitialize
                  validateOnChange
                  validationSchema={dataModelSchemas.getAddEditDatasetSchema({
                    allDatasets,
                    isEditMode: true,
                  })}
                >
                  {renderDatasetForm}
                </Formik>
              </Loading>
            </div>
          </div>

          <div className="row">
            <div className={`col ${styles.labelCol}`}>Dataset Items:</div>
            <div className="col add-button-column align-center">
              <Button
                size="lg"
                className="add-button"
                onClick={addDataItemRow}
                disabled={!dataset || isFetchingDataItems || isUpdatingDataModel}
              >
                Add Item
              </Button>
            </div>
          </div>
          <div className={`row ${styles.dataItemsGrid}`}>
            <div className="col">
              <AgGrid
                rowData={clonedRowData}
                isGridLoading={isGridLoading}
                columnDefs={columnDefinitions}
                onCellValueChanged={updateDataItemRow}
                onGridReady={onGridReady}
                sideBar={defaultSideBarWithColumnsToolPanel}
                suppressCellFocus={false}
                getRowId={getRowId}
                singleClickEdit
                stopEditingWhenCellsLoseFocus
                areHeaderCellBordersEnabled
              />
            </div>
          </div>
        </div>
      </div>
      {navigationPrompt}
      <AddModelModal {...addModelModalProp} submitCallback={modelAddedCallback} />
      <AddDatasetModal {...addDatasetModalProps} submitCallback={datasetAddedCallback} />
      <WarningModal
        title={WARNING_MODAL_TITLE}
        warningMessage={WARNING_MODAL_MESSAGE}
        modalDataMessage={WARNING_MODAL_DATA_MESSAGE}
        {...warningModalProps}
      />
      <WarningEditDatasetModal
        title={WARNING_EDIT_DATASET_MODAL_TITLE}
        warningMessage={renderEditDatasetWarningMessage}
        submitAction={save}
        {...warningEditDatasetModalProps}
      />
      <WarningDeleteDatasetModal {...warningDeleteDatasetModalProps} />
      <WarningModal
        title={WARNING_CHECKBOX_GROUP_MODAL_TITLE}
        dismissText="OK"
        warningMessage={WARNING_EDIT_CHECKBOX_GROUP_MODAL_MESSAGE}
        modalDataMessage={WARNING_EDIT_DATASET_CHECKBOX_GROUP_MODAL_MESSAGE}
        {...warningCheckboxGroupModalProps}
      />
      {propagateModelModalProps.visible && <PropagateModelModal {...propagateModelModalProps} />}
    </>
  );
};

DataModels.propTypes = {
  customerPermissions: customerPermissionsPropTypes,
};

export default withPermissions(DataModels);
