module.exports = {
  EV_NONBUSINESS_INCOME: 'EV_NONBUSINESS_INCOME',
  INCOME_MODIFICATIONS: 'INCOME_MODIFICATIONS',
  FEDERAL_TAXABLE_INCOME: 'FEDERAL_TAXABLE_INCOME',
  FTAX_MODIFICATIONS: 'FTAX_MODIFICATIONS',
  ADJUSTMENTS_TO_STI: 'ADJUSTMENTS_TO_STI',
  STATE_NONBUSINESS_INCOME: 'STATE_NONBUSINESS_INCOME',
  ADJ_TO_TAX_BEFORE_CREDITS: 'ADJ_TO_TAX_BEFORE_CREDITS',
  CREDITS: 'CREDITS',
  ADJ_TO_TAX_AFTER_CREDITS: 'ADJ_TO_TAX_AFTER_CREDITS',
  TAX_PAYMENTS: 'TAX_PAYMENTS',

  FTAX_PAYMENTS: 'FTAX_PAYMENTS',
  FTAX_ADJ_TO_TAX_AFTER_CRED: 'FTAX_ADJ_TO_TAX_AFTER_CRED',
  FTAX_CREDITS: 'FTAX_CREDITS',
  FTAX_ADJ_TO_TAX_BEFORE_CRED: 'FTAX_ADJ_TO_TAX_BEFORE_CRED',
  FTAX_POST_APP_ADJ: 'FTAX_POST_APP_ADJ',
  FTAX_BASE: 'FTAX_BASE',

  PAYROLL_WITHIN: 'PAYROLL_WITHIN',
  PAYROLL_EV: 'PAYROLL_EV',
  SALES_WITHIN: 'SALES_WITHIN',
  SALES_EV: 'SALES_EV',
  FTAX_PAYROLL_WITHIN: 'FTAX_PAYROLL_WITHIN',
  FTAX_PAYROLL_EV: 'FTAX_PAYROLL_EV',
  FTAX_SALES_WITHIN: 'FTAX_SALES_WITHIN',
  FTAX_SALES_EV: 'FTAX_SALES_EV',

  FTAX_BEG_PROPERTY_WI: 'FTAX_BEG_PROPERTY_WI',
  FTAX_END_PROPERTY_WI: 'FTAX_END_PROPERTY_WI',
  FTAX_BEG_PROPERTY_EV: 'FTAX_BEG_PROPERTY_EV',
  FTAX_END_PROPERTY_EV: 'FTAX_END_PROPERTY_EV',
  FTAX_RENT_WI: 'FTAX_RENT_WI',
  FTAX_RENT_EV: 'FTAX_RENT_EV',

  BEGINNING_PROPERTY_WITHIN: 'BEGINNING_PROPERTY_WITHIN',
  END_PROPERTY_WITHIN: 'END_PROPERTY_WITHIN',
  RENT_WITHIN: 'RENT_WITHIN',
  BEGINNING_PROPERTY_EV: 'BEGINNING_PROPERTY_EV',
  END_PROPERTY_EV: 'END_PROPERTY_EV',
  RENT_EV: 'RENT_EV',
};
