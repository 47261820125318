import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@pwc/appkit-react';
import { Formik } from 'formik';
import { taxFormsSchemas } from '@common-packages/validators';
import Spinner from '@tls/ui-spinner';

import { SelectOptionPropTypes } from '../../shared/forms/propTypes';
import { globalContextSelector } from '../../shared/store/selectors';
import { fetchFormsByPeriod } from '../../shared/store/actions';
import { taxYearSelector, jurisdictionIdSelector } from '../store/selectors';
import {
  fetchDataModels,
  fetchOverflowActionTypes,
  selectDataItem,
} from '../../shared/store/dataModels/actions';
import { dataModelSelector } from '../../shared/store/dataModels/selectors';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import { startUserJobPolling, cancelUserJobPolling } from '../../jobs/store/actions';

import { selectFormId, pullGeneratePdfSuccess } from './store/actions';
import {
  taxFormSelector,
  formIdSelector,
  isAddingFormSelector,
  filingAttributesSelector,
  isAddingOrEditingTaxFormSelector,
} from './store/selectors';
import PDFPagesMapping from './pages/PDFPageMapping.container';
import { taxFormType, filingAttributesProps } from './propTypes';
import TaxFormForm from './taxFormForm.component';
import { TABS_TYPES, tabsDefinitions } from './constants';
import styles from './addEditForm.module.scss';

const jobType = 'generate-tax-form-pdf';

const isConstantJobState = ({ jobId, finishedOn, returnedError }) =>
  !jobId || finishedOn || returnedError;

const AddEditForm = ({
  compactMode,
  activeTab,
  setActiveTab,
  fetchGridData,
  formId,
  selectFormId,
  taxForm,
  updateTaxForm,
  addTaxForm,
  globalContext,
  taxYear,
  jurisdictionId,
  fetchDataModels,
  dataModel,
  fetchOverflowActionTypes,
  filingAttributes,
  isAddingForm,
  isAddingOrEditingTaxForm,
  startUserJobPolling,
  cancelUserJobPolling,
  fetchFormsByPeriod,
}) => {
  const formPDFId = taxForm && taxForm.pdfId;

  const isPDFProcessed = isConstantJobState(taxForm);

  const [enableReinitializeForm, setEnableReinitializeForm] = useState(true);

  useEffect(() => {
    if (!isPDFProcessed) {
      startUserJobPolling({
        jobType,
        jobId: taxForm.jobId,
        successAction: pullGeneratePdfSuccess,
        errorMessage: 'There was an error getting pdf generation status',
      });
      return () => {
        cancelUserJobPolling(taxForm.jobId);
      };
    }
  }, [isPDFProcessed, startUserJobPolling, cancelUserJobPolling, taxForm.jobId]);

  useEffect(() => {
    setEnableReinitializeForm(!isAddingForm);
  }, [isAddingForm, setEnableReinitializeForm]);

  const handleTabChange = useCallback(
    (_, tab) => {
      if (formId) {
        setActiveTab(tab);
      }
    },
    [formId, setActiveTab],
  );

  useEffect(() => {
    fetchOverflowActionTypes();
  }, [fetchOverflowActionTypes]);

  const dataModelValue = dataModel && dataModel.value;
  useEffect(() => {
    const isContextReady = taxYear && jurisdictionId;

    if (isContextReady && !dataModelValue) {
      fetchDataModels({ taxYear, jurisdictionId });
    }
  }, [fetchDataModels, taxYear, jurisdictionId, dataModelValue]);

  const renderForm = useCallback(
    formikProps => {
      const onCancelClick = () => {
        formikProps.resetForm();
      };

      return (
        <TaxFormForm
          onCancelClick={onCancelClick}
          isEditing={Boolean(formId)}
          filingAttributes={filingAttributes}
          isAddingOrEditingTaxForm={isAddingOrEditingTaxForm}
          {...formikProps}
        />
      );
    },
    [formId, filingAttributes, isAddingOrEditingTaxForm],
  );

  useEffect(() => {
    if (isAddingForm && formId && formPDFId) {
      setActiveTab(TABS_TYPES.PAGES);
    }
  }, [formPDFId, isAddingForm, formId, setActiveTab]);

  const submitForm = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      const formData = {
        formValues: values,
        pdfLibPdfFile: values.pdfLibPdfFile,
        bnaPdfFile: values.bnaPdfFile,
      };

      if (formId) {
        await updateTaxForm(formData);
        fetchGridData();
      } else {
        await addTaxForm(formData);
        await fetchGridData();
        selectFormId(formData.formValues.formId);
      }
      if (globalContext.isReady) {
        await fetchFormsByPeriod(globalContext.params);
      }
      setSubmitting(false);
    },
    [
      updateTaxForm,
      addTaxForm,
      fetchGridData,
      fetchFormsByPeriod,
      globalContext.isReady,
      globalContext.params,
      formId,
      selectFormId,
    ],
  );

  const PdfStatusMessage = () =>
    taxForm.isPollingGeneratePdfJob ? (
      <span>
        <Spinner small /> PDF generation processing...
      </span>
    ) : (
      Boolean(formPDFId) || (
        <span>This form does not have a PDF file, please upload a PDF to use the pages tab.</span>
      )
    );

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.FORM:
        return (
          <div className={`row ${styles.bottomSlideInContent}`}>
            <div className="col">
              <Formik
                onSubmit={submitForm}
                initialValues={taxForm}
                enableReinitialize={enableReinitializeForm}
                validationSchema={taxFormsSchemas.addEditTaxFormCommonSchema}
                validateOnBlur={false}
              >
                {renderForm}
              </Formik>
            </div>
          </div>
        );
      case TABS_TYPES.PAGES:
        return formPDFId && isPDFProcessed ? (
          <div className={`row ${styles.bottomSlideInContent}`}>
            <div className="col">
              <PDFPagesMapping formPDFId={formPDFId} />
            </div>
          </div>
        ) : (
          PdfStatusMessage()
        );
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }, index) => (
              <Tab
                disabled={Boolean(!formId && type === TABS_TYPES.PAGES)}
                id={type}
                value={type}
                label={label}
                key={index}
              />
            ))}
          </Tabs>
        </div>
      </div>
      {compactMode ? null : getActiveTabComponent()}
    </>
  );
};

AddEditForm.propTypes = {
  compactMode: PropTypes.bool.isRequired,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  fetchGridData: PropTypes.func,
  formId: PropTypes.string,
  taxForm: taxFormType,
  updateTaxForm: PropTypes.func.isRequired,
  addTaxForm: PropTypes.func.isRequired,
  fetchFormsByPeriod: PropTypes.func.isRequired,
  globalContext: globalContextPropTypes,
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,
  selectFormId: PropTypes.func.isRequired,
  fetchDataModels: PropTypes.func,
  dataModel: SelectOptionPropTypes,
  fetchOverflowActionTypes: PropTypes.func,
  filingAttributes: filingAttributesProps.isRequired,
  isAddingForm: PropTypes.bool.isRequired,
  isAddingOrEditingTaxForm: PropTypes.bool.isRequired,
  startUserJobPolling: PropTypes.func.isRequired,
  cancelUserJobPolling: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    globalContext: globalContextSelector(state),
    taxYear: taxYearSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    taxForm: taxFormSelector(state),
    formId: formIdSelector(state),
    dataModel: dataModelSelector(state),
    filingAttributes: filingAttributesSelector(state),
    isAddingForm: isAddingFormSelector(state),
    isAddingOrEditingTaxForm: isAddingOrEditingTaxFormSelector(state),
  }),
  {
    fetchDataModels,
    fetchOverflowActionTypes,
    fetchFormsByPeriod,
    selectDataItem,
    selectFormId,
    startUserJobPolling,
    cancelUserJobPolling,
  },
)(AddEditForm);
