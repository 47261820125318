import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { reviewerNotesSchemas } from '@common-packages/validators';

import customerPropTypes from '../../shared/propTypes/customerPropTypes';
import { customerDataSelector } from '../../shared/store/selectors';
import Loading from '../../shared/displayComponents/loading.component';
import SlideIn from '../../shared/displayComponents/slideIn/slideIn.component';

import AddEditNoteFormComponent from './AddEditNoteForm.component';
import {
  jurisdictionsPropTypes,
  statusesPropTypes,
  tasksPropTypes,
  noteToEditPropTypes,
} from './propTypes';

const NOTE_STATUS_OPEN_ITEM = 'O';

const AddEditNoteSlideIn = ({
  closeSlideIn,
  isFetchingData,
  isOpen,
  jurisdictions,
  tasks,
  statuses,
  createNote,
  noteToEdit,
  editNote,
  customerData,
}) => {
  const formInitialValues = {
    preparedBy:
      customerData.firstName && customerData.lastName
        ? `${customerData.firstName} ${customerData.lastName}`
        : '',
    respondedBy: null,
    status: NOTE_STATUS_OPEN_ITEM,
    response: '',
    note: '',
    description: '',
    rolloverToNextYear: false,
    noteId: null,
    customerId: null,
    taskId: null,
    jurisdictionId: null,
  };

  const saveNote = useCallback(
    async (
      {
        description,
        jurisdictionId,
        taskId,
        rolloverToNextYear,
        note,
        response,
        respondedBy,
        status,
        noteId,
      },
      { setSubmitting },
    ) => {
      const data = {
        preparedBy: customerData.customerId,
        description,
        jurisdictionId,
        taskId,
        rolloverToNextYear,
        note,
        response,
        respondedBy: respondedBy ? customerData.customerId : null,
        status,
      };

      if (!noteToEdit) {
        await createNote(data);
      } else {
        await editNote({ ...data, noteId });
      }

      setSubmitting(false);
      closeSlideIn();
    },
    [noteToEdit, closeSlideIn, createNote, editNote, customerData],
  );

  const renderForm = useCallback(
    formikProps => {
      const isEditMode = Boolean(noteToEdit);
      return (
        <AddEditNoteFormComponent
          {...formikProps}
          closeSlideIn={closeSlideIn}
          jurisdictions={jurisdictions}
          tasks={tasks}
          statuses={statuses}
          isEditMode={isEditMode}
          customerData={customerData}
        />
      );
    },
    [noteToEdit, closeSlideIn, jurisdictions, tasks, statuses, customerData],
  );

  const slideInTitle = `Preparer/Reviewer Notes - ${noteToEdit ? 'Edit' : 'Add'}`;

  return (
    <SlideIn
      title={slideInTitle}
      isOpen={isOpen}
      width="420px"
      onRequestClose={closeSlideIn}
      ariaHideApp={false}
    >
      <Loading isLoading={isFetchingData}>
        <Formik
          initialValues={noteToEdit ? noteToEdit : formInitialValues}
          validateOnBlur={false}
          validationSchema={reviewerNotesSchemas.updateNoteSchema}
          onSubmit={saveNote}
        >
          {renderForm}
        </Formik>
      </Loading>
    </SlideIn>
  );
};

AddEditNoteSlideIn.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeSlideIn: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  jurisdictions: jurisdictionsPropTypes.isRequired,
  statuses: statusesPropTypes.isRequired,
  tasks: tasksPropTypes.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  noteToEdit: noteToEditPropTypes,
  editNote: PropTypes.func.isRequired,
  customerData: customerPropTypes,
};

export default connect(state => ({
  customerData: customerDataSelector(state),
}))(AddEditNoteSlideIn);
