import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { infoNotification } from '../../../shared/notification/store/actions';
import useFetch from '../../../shared/hooks/useFetch.hook';
import { httpGetAndParse } from '../../../utils/fetchService';

import { screenTypes } from './constants';

const fetchCustomizedScreenEndpoint = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  categoryId,
  accountId,
}) =>
  httpGetAndParse({
    route: `/api/shared/customized-screen-for-amount/${taxYear}/${period}/${orgId}/${jurisdictionId}/${categoryId}/${accountId}`,
    errorMessage: `Fetching check customized screen failed`,
  });

const fetchCustomizedScreenForComponentDrillEndpoint = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  categoryId,
  accountId,
  componentId = null,
}) =>
  httpGetAndParse({
    route: `/api/shared/customized-screen-for-amount/${taxYear}/${period}/${orgId}/${jurisdictionId}/${categoryId}/${accountId}/${componentId}`,
    errorMessage: `Fetching check customized screen for component drill failed`,
  });

export const createUseCustomizedScreenHook = useFetch => {
  const useCustomizedScreen = ({
    taxYear,
    period,
    orgId,
    jurisdictionId,
    categoryId,
    accountId,
    componentId,
  }) => {
    const dispatchReduxAction = useDispatch();
    const action = componentId
      ? fetchCustomizedScreenForComponentDrillEndpoint
      : fetchCustomizedScreenEndpoint;
    const {
      data,
      isFetching: isFetchingCustomizedScreen,
      fetch: fetchCustomizedScreenType,
    } = useFetch({ action });

    const getScreenType = useCallback(() => {
      const isContextReady =
        taxYear && period && orgId && jurisdictionId && categoryId && accountId;
      if (isContextReady) {
        fetchCustomizedScreenType({
          taxYear,
          period,
          orgId,
          jurisdictionId,
          categoryId,
          accountId,
          componentId,
        });
      }
    }, [
      fetchCustomizedScreenType,
      taxYear,
      period,
      orgId,
      jurisdictionId,
      categoryId,
      accountId,
      componentId,
    ]);

    useEffect(() => {
      getScreenType();
    }, [getScreenType]);

    useEffect(() => {
      if (!data) {
        return;
      }

      const shouldDisplayNotification = [screenTypes.RETURN_TO_INCOME_TAX_SUMMARY].includes(
        data.customizedScreenType,
      );

      if (shouldDisplayNotification) {
        dispatchReduxAction(infoNotification(data.message));
      }
    }, [data, dispatchReduxAction]);

    const screenType = (data || { customizedScreenType: null }).customizedScreenType;
    const swpDrillTargetMessage = (data || { message: '' }).message;
    const customScreenName = (data || { customScreenName: null }).customScreenName;
    const manualAccounts = (data || { manualAccounts: null }).manualAccounts;

    const customizedScreen = {
      type: screenType,
      message: swpDrillTargetMessage,
      customScreenName,
      manualAccounts,
    };

    return {
      customizedScreen,
      isFetchingCustomizedScreen,
    };
  };
  return useCustomizedScreen;
};

export default createUseCustomizedScreenHook(useFetch);
