import { Routes } from '@common-packages/routes-definitions';

import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';
import { LinkCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import { filingTypes } from '../../shared/constants';

const defaultColumnDefinition = {
  ...defaultColumnDefinitionWithFilter,
  width: 120,
  cellStyle: {
    textAlign: 'left',
  },
};

export const getColumnDefinitions = ({ selectFilingType, selectMaintenanceConsolidation }) => {
  const ConsolidationLinkRenderer = LinkCellRendererFactory({
    getText: ({ value }) => value,
    getPathName: () => Routes.consolidation.MAIN,
    getRouterState: () => ({ fromDashboard: true }),
    clickHandler: (_, data) => {
      selectFilingType(filingTypes.CONSOLIDATED);
      selectMaintenanceConsolidation(data);
    },
  });

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Cons Id',
      field: 'consolidationId',
      cellRenderer: ConsolidationLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Consolidation Description',
      field: 'description',
      width: 700,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Parent Entity',
      field: 'parentName',
      width: 300,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Parent FEIN',
      field: 'parentFein',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Federal As-Filed Filing Group',
      field: 'federalFilingGroupId',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Federal Proforma Filing Group',
      field: 'proformaFilingGroupId',
      width: 200,
    },
  ];

  return columnDefinitions;
};
