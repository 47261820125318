import React, { forwardRef, useCallback } from 'react';
import { ICellEditorParams } from 'ag-grid-enterprise';
import classNames from 'classnames';

import { useCellEditorSelect } from '../../hooks/useCellEditorSelect';

import style from './customOptionSelector.module.scss';

interface ICellEditorParamsWithValues extends ICellEditorParams {
  values?: string[];
}

const CustomOptionSelector = forwardRef(
  (props: ICellEditorParamsWithValues, ref: React.ForwardedRef<unknown>) => {
    const { stopEditing, value } = props;

    const { setShouldClearValue, setSelectedValue, inputRef } = useCellEditorSelect({
      ref,
      stopEditing,
      value,
    });

    const handleChange = useCallback(
      (option: string | boolean) => () => {
        if (option === null) {
          setShouldClearValue(true);
        }

        setSelectedValue(option);
      },
      [setSelectedValue, setShouldClearValue],
    );

    return (
      <div ref={inputRef} className={style.optionsWrapper}>
        {props.values?.map((option, index) => {
          const hasClearAction =
            option === null && props.formatValue(option) === 'Clear selected value';
          return (
            <div
              key={`select-option-${index}`}
              className={classNames({
                [style.selectOptionContainer]: true,
                [style.clearOption]: hasClearAction,
              })}
              onMouseDown={handleChange(option)}
            >
              {props.formatValue(option)}
            </div>
          );
        })}
      </div>
    );
  },
);

CustomOptionSelector.displayName = 'CustomOptionSelector';

export default CustomOptionSelector;
