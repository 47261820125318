import React, { useState, useCallback, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Tabs, Tab } from '@pwc/appkit-react';

import SlideIn from '../../shared/displayComponents/slideIn/slideIn.component';
import { useQueryReturnAttachments } from '../../shared/queries/binderMaintenance';
import { ReturnItemTypes } from '../enums';

import useAddReturnItemsLogic from './useAddReturnItemsLogic';
import AddReturnItems from './addReturnItems.component';
import UploadPdfs from './uploadPdfs.component';
import styles from './addReturnItems.module.scss';

enum TabsTypes {
  PDFS = 'pdfs',
  UPLOAD = 'upload',
}

const tabsDefinitions = [
  { type: TabsTypes.PDFS, label: 'PDFS' },
  { type: TabsTypes.UPLOAD, label: 'UPLOAD' },
];

interface AddPdfsPanelProps {
  isPanelVisible: boolean;
  hidePanel: () => void;
  returnPdfIds?: string[];
  taxYear: string;
  period: string;
  jurisdictionId: string;
}

const AddPdfsPanel = ({
  hidePanel,
  isPanelVisible,
  returnPdfIds = [],
  taxYear,
  period,
  jurisdictionId,
}: AddPdfsPanelProps) => {
  const { returnId } = useParams<{ returnId: string }>();

  const [activeTab, setActiveTab] = useState(TabsTypes.PDFS);

  const handleTabChange = useCallback((_: ChangeEvent, tab: TabsTypes) => setActiveTab(tab), []);

  const { data: pdfs, isLoading: isLoadingPdfs } = useQueryReturnAttachments({
    params: {
      returnId,
      taxYear,
      period,
      jurisdictionId,
    },
    enabled: Boolean(isPanelVisible && taxYear && period && jurisdictionId),
  });

  const {
    visibleItems,
    selectedItemIds,
    shouldShowSelectedItems,
    isAddingReturnItems,
    isListItemSelected,
    handleSearch,
    handleToggleShowSelectedItems,
    handleCheckboxChange,
    handleHidePanel,
    handleAddReturnItems,
  } = useAddReturnItemsLogic({
    returnId,
    hidePanel,
    returnItemType: ReturnItemTypes.PDF_ATTACHMENT,
    existingReturnItemsIds: returnPdfIds,
    items: pdfs || [],
    itemIdKey: 'id',
    itemLabelKey: 'fileName',
  });

  const getActiveTab = () => {
    switch (activeTab) {
      case TabsTypes.PDFS:
        return (
          <AddReturnItems
            items={visibleItems}
            isLoadingItems={isLoadingPdfs}
            noResultsMessage="All available attachments have already been added."
            filterText="Show selected PDFs"
            handleSearch={handleSearch}
            shouldShowSelectedItems={shouldShowSelectedItems}
            handleToggleShowSelectedItems={handleToggleShowSelectedItems}
            isListItemSelected={isListItemSelected}
            handleCheckboxChange={handleCheckboxChange}
          />
        );
      case TabsTypes.UPLOAD:
        return <UploadPdfs />;
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <SlideIn
      className={styles.panel}
      isOpen={isPanelVisible}
      onRequestClose={handleHidePanel}
      width="480px"
      closeIconName="close"
      title="Add PDFs"
    >
      <div className={styles.content}>
        <Tabs className={styles.tabs} value={activeTab} size="md" onChange={handleTabChange}>
          {tabsDefinitions.map(({ label, type }) => (
            <Tab id={type} value={type} label={label} key={type} />
          ))}
        </Tabs>
        {getActiveTab()}
      </div>
      <div className={styles.footer}>
        {selectedItemIds.length ? (
          <span className={styles.selectedItemsInformation}>
            Selected {selectedItemIds.length} PDF{selectedItemIds.length > 1 && 's'}
          </span>
        ) : null}
        <Button
          className={styles.footerButton}
          size="lg"
          kind="secondary"
          onClick={handleHidePanel}
        >
          cancel
        </Button>
        <Button
          className={styles.footerButton}
          size="lg"
          disabled={!selectedItemIds.length || activeTab === TabsTypes.UPLOAD}
          isLoading={isAddingReturnItems}
          onClick={handleAddReturnItems}
        >
          add
        </Button>
      </div>
    </SlideIn>
  );
};

export default AddPdfsPanel;
