import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';
import { nexusNotesSchemas } from '@common-packages/validators';

import customerPropTypes from '../../shared/propTypes/customerPropTypes';
import { customerDataSelector } from '../../shared/store/selectors';
import Loading from '../../shared/displayComponents/loading.component';

import AddEditNexusNoteFormComponent from './addEditNexusNoteForm.component';
import {
  filteredOrgIdOptionsPropTypes,
  orgIdOptionsPropTypes,
  nexusNoteToEditPropTypes,
} from './propTypes';

const AddNexusNotesModal = ({
  hideModal,
  visible,
  jurisdictionDescription,
  createNexusNote,
  orgIdOptions,
  filteredOrgIdOptions,
  nexusNoteToEdit,
  isFetchingNexusNote,
  editNexusNote,
  customerData,
}) => {
  const formInitialValues = {
    preparedBy:
      customerData.firstName && customerData.lastName
        ? `${customerData.firstName} ${customerData.lastName}`
        : '',
    note: '',
    rolloverToNextYear: false,
    orgId: '',
    noteId: null,
    shortDescription: null,
  };

  const saveNote = useCallback(
    async ({ rolloverToNextYear, note, orgId, noteId, shortDescription }) => {
      const data = {
        preparedBy: customerData.customerId,
        rolloverToNextYear,
        note,
        orgId,
      };
      if (!nexusNoteToEdit) {
        await createNexusNote(data);
      } else {
        await editNexusNote({
          ...data,
          noteId,
          shortDescription,
        });
      }
      hideModal();
    },
    [createNexusNote, hideModal, editNexusNote, nexusNoteToEdit, customerData],
  );
  const renderModal = useCallback(
    formikProps => {
      const isEditMode = Boolean(nexusNoteToEdit);
      return (
        <Modal
          title={`Nexus Notes - ${nexusNoteToEdit ? 'Edit' : 'Add'}`}
          closeAction={hideModal}
          visible={visible}
          submitText={nexusNoteToEdit ? 'Save' : 'Add'}
          submitAction={formikProps.submitForm}
          dismissText="Cancel"
          dismissAction={hideModal}
        >
          <Loading isLoading={isFetchingNexusNote}>
            <AddEditNexusNoteFormComponent
              {...formikProps}
              jurisdictionDescription={jurisdictionDescription}
              orgIdOptions={orgIdOptions}
              filteredOrgIdOptions={filteredOrgIdOptions}
              isEditMode={isEditMode}
            />
          </Loading>
        </Modal>
      );
    },
    [
      nexusNoteToEdit,
      isFetchingNexusNote,
      hideModal,
      visible,
      jurisdictionDescription,
      orgIdOptions,
      filteredOrgIdOptions,
    ],
  );
  return (
    <Formik
      initialValues={nexusNoteToEdit ? nexusNoteToEdit : formInitialValues}
      enableReinitialize
      validateOnBlur={false}
      validationSchema={nexusNotesSchemas.commonSchema}
      onSubmit={saveNote}
    >
      {renderModal}
    </Formik>
  );
};

AddNexusNotesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  jurisdictionDescription: PropTypes.string,
  createNexusNote: PropTypes.func.isRequired,
  filteredOrgIdOptions: filteredOrgIdOptionsPropTypes,
  orgIdOptions: orgIdOptionsPropTypes,
  isFetchingNexusNote: PropTypes.bool.isRequired,
  editNexusNote: PropTypes.func.isRequired,
  nexusNoteToEdit: nexusNoteToEditPropTypes,
  customerData: customerPropTypes,
};

export default connect(state => ({
  customerData: customerDataSelector(state),
}))(AddNexusNotesModal);
