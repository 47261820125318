import React, { useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  taxYearSelector,
  periodSelector,
  entityIdSelector,
  isFetchingEntitiesSelector,
} from '../../shared/store/selectors';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';

import AddEditNoteSlideIn from './AddEditNoteSlideIn.component';
import getColumnDefinitions from './federalReconciliations.columnDefinitions';
import getFederalReconciliationsSections from './federalReconciliations.sections';
import {
  selectAccountGroup,
  fetchFederalReconciliations,
  createFederalReconciliationsNote,
} from './store/actions';
import {
  federalReconciliationsSelector,
  isFetchingFederalReconciliationSelector,
} from './store/selectors';

const sectionsOrder = ['RECEIPTS', 'PAYROLL', 'PROPERTY'];

const initSlideInFederalReconciliation = {
  accountGroup: '',
  categoryDescription: '',
};

const FederalReconciliations = ({
  taxYear,
  period,
  entityId,
  isFetchingEntities,
  federalReconciliations,
  fetchFederalReconciliations,
  isFetchingFederalReconciliation,
  createFederalReconciliationsNote,
  selectAccountGroup,
}) => {
  const isContextReady = taxYear && period && entityId;

  const [isSlideInOpen, setIsSlideInOpen] = useState(false);
  const [slideInFederalReconciliation, setSlideInFederalReconciliationCategory] = useState(
    initSlideInFederalReconciliation,
  );

  const openSlideInForAdd = useCallback(federalReconciliationCategory => {
    setIsSlideInOpen(true);
    setSlideInFederalReconciliationCategory(federalReconciliationCategory);
  }, []);
  const closeSlideIn = useCallback(() => {
    setIsSlideInOpen(false);
  }, []);

  const reloadGrid = useCallback(() => {
    if (isContextReady) {
      fetchFederalReconciliations({
        taxYear,
        period,
        entityId,
      });
    }
  }, [period, taxYear, entityId, isContextReady, fetchFederalReconciliations]);

  const addNote = useCallback(
    async ({ noteMessage, accountGroup }) => {
      await createFederalReconciliationsNote({
        taxYear,
        period,
        entityId,
        noteMessage,
        accountGroup,
      });
      reloadGrid();
    },
    [createFederalReconciliationsNote, reloadGrid, period, taxYear, entityId],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        openSlideInForAdd,
        selectAccountGroup,
      }),
    [openSlideInForAdd, selectAccountGroup],
  );

  return (
    <>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            isGridLoading={isFetchingEntities || isFetchingFederalReconciliation}
            columnDefs={columnDefinitions}
            rowData={getFederalReconciliationsSections(sectionsOrder, federalReconciliations)}
          />
        </div>
      </div>
      <AddEditNoteSlideIn
        createNote={addNote}
        isOpen={isSlideInOpen}
        closeSlideIn={closeSlideIn}
        isFetchingData={isFetchingEntities}
        federalReconciliationCategory={slideInFederalReconciliation}
      />
    </>
  );
};

FederalReconciliations.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  isFetchingEntities: PropTypes.bool.isRequired,
  fetchFederalReconciliations: PropTypes.func.isRequired,
  isFetchingFederalReconciliation: PropTypes.bool.isRequired,
  createFederalReconciliationsNote: PropTypes.func.isRequired,
  federalReconciliations: PropTypes.arrayOf(
    PropTypes.shape({
      sltAmount: PropTypes.number.isRequired,
      federalAmount: PropTypes.string.isRequired,
      reconVariance: PropTypes.string.isRequired,
      accountGroup: PropTypes.string.isRequired,
      lookupDisplayOrder: PropTypes.number.isRequired,
      categoryDescription: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
      hasNote: PropTypes.bool.isRequired,
      isFederalAmountLinkVisible: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  selectAccountGroup: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    isFetchingEntities: isFetchingEntitiesSelector(state),
    federalReconciliations: federalReconciliationsSelector(state),
    isFetchingFederalReconciliation: isFetchingFederalReconciliationSelector(state),
  }),
  {
    selectAccountGroup,
    fetchFederalReconciliations,
    createFederalReconciliationsNote,
  },
)(FederalReconciliations);
