import PropTypes from 'prop-types';

export const consolidationType = PropTypes.shape({
  jurisdictionDescription: PropTypes.string.isRequired,
  consolidationId: PropTypes.string.isRequired,
  description: PropTypes.string,
  parentName: PropTypes.string.isRequired,
  parentFein: PropTypes.string,
  federalFilingGroupId: PropTypes.string,
  proformaFilingGroupId: PropTypes.string,
});

export const consolidationPropType = PropTypes.shape({
  businessEntityId: PropTypes.string,
  consolidationId: PropTypes.string,
});
