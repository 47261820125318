const getXAxisValues = (data, definition) =>
  data.map(dataRow => (dataRow[definition.key] ? dataRow[definition.key] : ''));

const getValueFromData = (dataRow, definition) =>
  dataRow[definition.value] && !isNaN(dataRow[definition.value]) ? dataRow[definition.value] : 0;

const CHART_OPTIONS_CREATORS = {
  BarChart: {
    createOptions: (data, chartParams) => {
      const categories = getXAxisValues(data, chartParams);
      const series = [
        {
          name: chartParams.value,
          data: data.map(dataRow => getValueFromData(dataRow, chartParams)),
        },
      ];

      return {
        chart: {
          type: 'column',
        },
        xAxis: {
          categories,
          crosshair: true,
        },
        title: {
          text: chartParams.name,
        },
        series,
      };
    },
  },
  PieChart: {
    createOptions: (data, chartParams) => {
      const series = [
        {
          name: chartParams.value,
          data: data.map(dataRow => ({
            name: dataRow[chartParams.key],
            y: getValueFromData(dataRow, chartParams),
          })),
        },
      ];

      return {
        chart: {
          type: 'pie',
        },
        title: {
          text: chartParams.name,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
        },
        series,
      };
    },
  },
};

export const createOptions = (data, chartParams) =>
  CHART_OPTIONS_CREATORS[chartParams.type].createOptions(data, chartParams);
