import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.module.scss';

const CustomHeader = ({ params, columnWidth }) => (
  <div
    style={{
      width: `${columnWidth}px`,
    }}
  >
    {params.map((param, index) =>
      param ? (
        <div key={`${param}_${index}`} className={styles.headerLine}>
          {param}
        </div>
      ) : (
        <div key={`${param}_${index}`}>
          <br />
        </div>
      ),
    )}
  </div>
);

CustomHeader.propTypes = {
  params: PropTypes.arrayOf(PropTypes.string),
  columnWidth: PropTypes.number.isRequired,
};

export default CustomHeader;
