import { SelectOption } from '@tls/slt-types';

import { TaxRatesDescription } from '../constants';

const getRateTypesOptions = (): SelectOption[] =>
  Object.entries(TaxRatesDescription).map(([key, value]) => ({
    label: value,
    value: key,
  }));

export default getRateTypesOptions;
