import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchDefaultBinderItems = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/development/default-binders/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching default binder items failed',
  });

export const fetchBinderItemTypes = () =>
  httpGetAndParse({
    route: '/api/development/default-binders/types',
    errorMessage: 'Fetching binder item types failed',
  });

export const fetchForms = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/development/default-binders/forms/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching forms failed',
  });

export const updateBinderItems = ({ taxYear, jurisdictionId, changes }) =>
  httpPut({
    route: `/api/development/default-binders/${taxYear}/${jurisdictionId}`,
    body: changes,
    errorMessage: 'Updating binder items failed',
  });
