import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';

const CustomReports = () => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Edit Custom Reports"
      icon="edit"
      description="Create, edit and remove Custom Reports"
      route={Routes.editCustomReports.MAIN}
    />
    <AuthorizedCard
      title="Run Custom Reports"
      icon="table-data"
      description="View Custom Report data"
      route={Routes.runCustomReports.MAIN}
    />
    <AuthorizedCard
      title="Edit TRG Reports"
      icon="edit"
      description="Create, edit and remove TRG Reports"
      route={Routes.editTrgReports.MAIN}
    />
    <AuthorizedCard
      title="Run TRG Reports"
      icon="table-data"
      description="View TRG Report data"
      route={Routes.runTrgReports.MAIN}
    />
    <AuthorizedCard
      title="Edit Data Visualizations"
      icon="edit"
      description="Create, edit and remove Data Visualizations"
      route={Routes.editDataVisualizations.MAIN}
    />
    <AuthorizedCard
      title="Run Data Visualizations"
      icon="table-data"
      description="View Data Visualizations"
      route={Routes.runDataVisualizations.MAIN}
    />
    <AuthorizedCard
      title="Job Queue Status Reports"
      icon="table-data"
      description="Job Queue Status Reports"
      route={Routes.jobQueueStatusReport.MAIN}
    />
  </div>
);

export default CustomReports;
