import React from 'react';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import {
  numeralFormatter,
  currencyCellFormatter,
  amountFormatter,
} from '../../../shared/formatters';
import {
  currencyColumnDefinition,
  defaultColumnDefinition,
} from '../../../shared/columnDefinitions';
import {
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../../shared/columnDefinitions/cellRenderers';
import {
  categoriesForGenericCategory,
  categoriesForPropertySummary,
} from '../../../taxSummaries/apportionmentRateDetails/constants';
import { findRouteNamespace } from '../utils';

const boldCellCellClassRules = {
  'bold-cell': ({ data }) => data.isSectionHeader || data.isInSummarySection,
};

const cellClassRulesForDataColumn = {
  'underlined-cell': ({ data }) => data.dataUnderlined,
  'double-underlined-cell': ({ data }) => data.dataDoubleUnderlined,
};

const cellClassRulesForFactorColumn = {
  'underlined-cell': ({ data }) => data.factorUnderlined,
  'double-underlined-cell': ({ data }) => data.factorDoubleUnderlined,
};

const weightsColumnValueFormatter = ({ value }) =>
  value || value === 0 ? numeralFormatter({ value }) : null;

const valueFormatter = ({ value, data, optionalFormatter, ...props }) => {
  if (value || value === 0) {
    return data.formatAsPercentage
      ? amountFormatter({
          value,
          props: {
            ...props,
            percentage: true,
            decimalPlaces: data.dataDecimalPlaces,
          },
        })
      : optionalFormatter({ value });
  }
  return null;
};

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  sortable: false,
};

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted || '',
});

const getColumnDefinitions = ({ match }) => {
  const { path, params } = match;
  const namespace = findRouteNamespace(path);

  const CatAmountCellRenderer = props => {
    const isCatDescInCategoriesForPropertySummary = Object.values(
      categoriesForPropertySummary,
    ).some(category => category === props.data.categoryId);

    if (isCatDescInCategoriesForPropertySummary) {
      const PropertySummaryLink = LinkCellRendererFactory({
        getText: ({ valueFormatted }) => valueFormatted,
        getPathName: () =>
          Routes[`${namespace}PropertySummary`].compiledRoute({
            ...params,
          }),
      });
      return <PropertySummaryLink {...props} />;
    }

    const isCatDescInCategoriesForGenericCategory = Object.values(
      categoriesForGenericCategory,
    ).some(category => category === props.data.categoryId);

    if (isCatDescInCategoriesForGenericCategory) {
      const GenericCategoryLink = LinkCellRendererFactory({
        getText: ({ valueFormatted }) => valueFormatted,
        getPathName: (value, data) =>
          Routes[`${namespace}GenericCategoryFromApportionmentRateDetails`].compiledRoute({
            categoryId: data.categoryId,
            taxName: params.taxName,
          }),
      });
      return <GenericCategoryLink {...props} />;
    }

    return <TextRenderer {...props} />;
  };

  CatAmountCellRenderer.propTypes = {
    data: PropTypes.shape({
      categoryId: PropTypes.string,
    }).isRequired,
  };

  const columnDefinitions = [
    {
      ...extendedDefaultColumnDefinition,
      headerName: '',
      field: 'catDesc',
      cellClassRules: { ...boldCellCellClassRules },
      maxWidth: 300,
    },
    {
      ...extendedDefaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Data',
      field: 'catAmount',
      valueFormatter: props =>
        valueFormatter({ ...props, optionalFormatter: currencyCellFormatter }),
      cellClassRules: cellClassRulesForDataColumn,
      cellRenderer: CatAmountCellRenderer,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Weights',
      field: 'catWeight',
      valueFormatter: weightsColumnValueFormatter,
      cellStyle: {
        textAlign: 'right',
      },
      width: 100,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Factor',
      field: 'catFactor',
      valueFormatter: props => valueFormatter({ ...props, optionalFormatter: numeralFormatter }),
      cellClassRules: cellClassRulesForFactorColumn,
      cellStyle: {
        textAlign: 'right',
      },
      width: 100,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
