import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';

import Loading from '../../../shared/displayComponents/loading.component';
import SDKCustomSelect from '../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { taxYearSelector, jurisdictionIdSelector } from '../../store/selectors';
import {
  activeFormDataItemIdSelector,
  isFetchingDataModelsSelector,
  dataModelsSelector,
  dataModelSelector,
  isFetchingDatasetsSelector,
  datasetsOptionsSelector,
  datasetsOptionSelector,
  isFetchingDataItemsSelector,
  dataItemsOptionsSelector,
  dataItemsOptionSelector,
  dataItemDataTypeNameSelector,
  isIgnoringCalculationDataChangesSelector,
  pdfFieldsByPageSelector,
  dataItemAliasNameSelector,
} from '../store/selectors';
import { dataModelIdSelector } from '../../../shared/store/dataModels/selectors';
import {
  selectDataModel,
  selectDataset,
  selectDataItem,
  setActiveFormDataItemId,
  setIsIgnoringCalculationDataChanges,
} from '../store/actions';

import styles from './styles.module.scss';

const noop = () => null;

const ExpressionCalculation = ({
  resetExpression,
  children,
  onValidateClick = noop,
  isValidateDisabled = false,
  updateDirtyItems = noop,
}) => {
  const dispatch = useDispatch();

  const [activeField, setActiveField] = useState(null);
  const [activeFormDataModel, setActiveFormDataModel] = useState(null);
  const [activeFormDataset, setActiveFormDataset] = useState(null);
  const [activeFormDataItem, setActiveFormDataItem] = useState(null);

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const isFetchingDataModels = useSelector(isFetchingDataModelsSelector);
  const dataModelsOptions = useSelector(dataModelsSelector);
  const dataModelOption = useSelector(dataModelSelector);
  const sharedDataModelId = useSelector(dataModelIdSelector);
  const isFetchingDatasets = useSelector(isFetchingDatasetsSelector);
  const datasetsOptions = useSelector(datasetsOptionsSelector);
  const datasetOption = useSelector(datasetsOptionSelector);
  const isFetchingDataItems = useSelector(isFetchingDataItemsSelector);
  const dataItemsOptions = useSelector(dataItemsOptionsSelector);
  const dataItemOption = useSelector(dataItemsOptionSelector);
  const dataItemAliasName = useSelector(dataItemAliasNameSelector);
  const dataTypeName = useSelector(dataItemDataTypeNameSelector);
  const pdfFieldsByPage = useSelector(pdfFieldsByPageSelector);
  const activeFormDataItemId = useSelector(activeFormDataItemIdSelector);
  const isIgnoringCalculationDataChanges = useSelector(isIgnoringCalculationDataChangesSelector);

  const dataModelValue = dataModelOption?.value;
  const datasetValue = datasetOption?.value;

  const isContextReady = taxYear && jurisdictionId;

  const cleanFormSelection = useCallback(() => {
    setActiveField(null);
    setActiveFormDataModel(null);
    setActiveFormDataset(null);
    setActiveFormDataItem(null);
  }, []);

  useEffect(() => {
    cleanFormSelection();
  }, [cleanFormSelection, jurisdictionId, taxYear]);

  useEffect(() => {
    if (isContextReady || dataModelValue || datasetValue) {
      resetExpression();
    }
  }, [resetExpression, isContextReady, dataModelValue, datasetValue]);

  const onDataModelChange = useCallback(
    ({ value }) => {
      updateDirtyItems();
      cleanFormSelection();
      if (value === sharedDataModelId) {
        dispatch(setIsIgnoringCalculationDataChanges(false));
      } else if (value !== dataModelValue) {
        dispatch(selectDataModel(value));
      }
    },
    [updateDirtyItems, cleanFormSelection, dispatch, sharedDataModelId, dataModelValue],
  );

  const onDatasetChange = useCallback(
    ({ value }) => {
      updateDirtyItems();
      setActiveFormDataset(null);
      setActiveFormDataItem(null);
      if (value !== datasetValue) {
        dispatch(selectDataset(value));
      }
    },
    [dispatch, updateDirtyItems, datasetValue],
  );

  const onDataItemChange = useCallback(
    ({ value }) => {
      updateDirtyItems();
      dispatch(selectDataItem(value));
      dispatch(setActiveFormDataItemId(value));
    },
    [updateDirtyItems, dispatch],
  );

  useEffect(() => {
    cleanFormSelection();
    if (!activeFormDataItemId || isIgnoringCalculationDataChanges) {
      return;
    }
    const activeField = pdfFieldsByPage.find(
      ({ dataItemId }) => dataItemId === activeFormDataItemId,
    );
    if (activeField) {
      setActiveField(activeField);
      const dataModel = dataModelsOptions.find(({ value }) => value === activeField.dataModelId);
      const dataset = datasetsOptions.find(({ value }) => value === activeField.datasetId);
      const dataItem = dataItemsOptions.find(({ value }) => value === activeField.dataItemId);
      setActiveFormDataModel(dataModel);
      if (!dataModelOption || dataModelOption.value !== dataModel?.value) {
        dispatch(selectDataModel(dataModel?.value));
      } else if (!datasetOption || datasetOption.value !== dataset?.value) {
        setActiveFormDataset(dataset);
        dispatch(selectDataset(dataset?.value));
      } else if (!dataItemOption || dataItemOption.value !== dataItem?.value) {
        setActiveFormDataItem(dataItem);
        dispatch(selectDataItem(dataItem?.value));
      }
    }
  }, [activeFormDataItemId, isIgnoringCalculationDataChanges]);

  useEffect(() => {
    if (activeFormDataModel) {
      const dataset = datasetsOptions.find(({ value }) => value === activeField.datasetId);
      setActiveFormDataset(dataset);
      if (datasetOption && dataset && datasetOption.value !== dataset.value) {
        dispatch(selectDataset(dataset.value));
      }
    }
  }, [datasetsOptions]);

  useEffect(() => {
    if (activeFormDataset) {
      const dataItem = dataItemsOptions.find(({ value }) => value === activeField.dataItemId);
      setActiveFormDataItem(dataItem);
      if (dataItemOption && dataItem && dataItemOption.value !== dataItem.value) {
        dispatch(selectDataItem(dataItem.value));
      }
    }
  }, [dataItemsOptions]);

  return (
    <div className={styles.expressionWrapper}>
      <div className={styles.expressionView}>
        <div className={`row ${styles.paramsRow}`}>
          <div className={`col ${styles.paramsContainer}`}>
            <label className="a-form-label">Data Model:</label>
            <Loading small isLoading={isFetchingDataModels}>
              <SDKCustomSelect
                className="sdk-custom-select"
                hideLabel
                options={dataModelsOptions}
                value={activeFormDataModel ? activeFormDataModel : dataModelOption}
                virtualized
                onChange={onDataModelChange}
              />
            </Loading>
            <label className="a-form-label">DataSet:</label>
            <Loading small isLoading={isFetchingDatasets || isFetchingDataModels}>
              <SDKCustomSelect
                className="sdk-custom-select"
                hideLabel
                options={datasetsOptions}
                value={activeFormDataset ? activeFormDataset : datasetOption}
                virtualized
                onChange={onDatasetChange}
              />
            </Loading>
            <label className="a-form-label">DataItem:</label>
            <Loading
              small
              isLoading={isFetchingDataItems || isFetchingDatasets || isFetchingDataModels}
            >
              <SDKCustomSelect
                className="sdk-custom-select"
                hideLabel
                options={dataItemsOptions}
                value={activeFormDataItem ? activeFormDataItem : dataItemOption}
                virtualized
                onChange={onDataItemChange}
              />
            </Loading>
            <div className={styles.paramsLastRow}>
              <div className={styles.paramsLastRowText}>
                <span className={styles.dataType}>Data Type: {dataTypeName || 'N/A'}</span>
                <span className={styles.inlineText}>Alias: {dataItemAliasName || 'N/A'}</span>
              </div>
              <div className={styles.formButtons}>
                <Button
                  size="lg"
                  onClick={onValidateClick}
                  className="add-button"
                  disabled={isValidateDisabled}
                >
                  Validate
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={`row ${styles.expressionRows}`}>{children}</div>
      </div>
    </div>
  );
};

ExpressionCalculation.propTypes = {
  resetExpression: PropTypes.func.isRequired,

  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  onValidateClick: PropTypes.func,
  isValidateDisabled: PropTypes.bool,
  editModeButtons: PropTypes.node.isRequired,
  updateDirtyItems: PropTypes.func.isRequired,
};

export default ExpressionCalculation;
