import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchTaxFormsTypes,
  addEditTaxFormTypes,
  SELECT_FORM_ID,
  CHANGE_IS_ADDING_FORM,
  PULL_GENERATE_PDF_SUCCESS,
} from './types';

export const initialState = {
  taxForms: {
    columnsBlueprint: [],
    data: [],
  },
  isFetchingTaxForms: false,

  isAddingOrEditingTaxForm: false,

  formId: null,
  isAddingForm: false,
};

const taxFormsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchTaxFormsTypes,
      payloadKey: 'taxForms',
      fetchingKey: 'isFetchingTaxForms',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: addEditTaxFormTypes,
      fetchingKey: 'isAddingOrEditingTaxForm',
    }),
    [SELECT_FORM_ID]: (state, payload) => ({
      ...state,
      formId: payload,
    }),
    [PULL_GENERATE_PDF_SUCCESS]: (state, payload) => ({
      ...state,
      taxForms: {
        ...state.taxForms,
        data: state.taxForms.data.map(form =>
          form.jobId === payload.jobId ? { ...form, ...payload } : form,
        ),
      },
    }),
    [CHANGE_IS_ADDING_FORM]: (state, payload) => ({
      ...state,
      isAddingForm: payload,
    }),
  },
});

export default taxFormsReducer;
