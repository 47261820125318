import { PdfPageDataUsageTypes } from '../constants';

const addDatasetParentsToArray = (dataset, allDatasets, parentsDatasets) => {
  const datasetParent = allDatasets.find(parent => parent.id === dataset.parentId);
  if (datasetParent) {
    parentsDatasets.push({ ...datasetParent, type: PdfPageDataUsageTypes.DATASET_USAGE });
    addDatasetParentsToArray(datasetParent, allDatasets, parentsDatasets);
  }
};

export default addDatasetParentsToArray;
