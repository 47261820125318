import { WatermarksResponse, WatermarkPreviewBody, WatermarkPreviewResponse } from '@tls/slt-types';

import { httpPostAndParse } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery, OnQuerySuccessCallback } from '.';

export const useQueryWatermarks = () =>
  useCustomQuery<WatermarksResponse>(QueryKeys.Watermarks.Watermarks, {
    url: '/api/development/watermarks',
    defaultData: [],
    errorMessage: 'Error fetching watermarks',
  });

const fetchWatermarkPreview = (params: WatermarkPreviewBody) =>
  httpPostAndParse({
    route: '/api/development/watermarks/preview-watermark-pdf',
    body: params,
  });

export const useQueryWatermarkPreview = ({
  params,
  enabled,
  onSuccess,
}: {
  params: WatermarkPreviewBody;
  enabled: boolean;
  onSuccess: OnQuerySuccessCallback<WatermarkPreviewResponse>;
}) =>
  useCustomQuery<WatermarkPreviewResponse>([QueryKeys.Watermarks.Preview, params], {
    enabled,
    onSuccess,
    errorMessage: 'An error occurred while fetching a watermark preview',
    defaultData: { pdfPreviewData: '' },
    customFetch: () => fetchWatermarkPreview(params),
  });
