import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';

const defaultColumnDefinitionWithFilterAndPivot = {
  ...defaultColumnDefinitionWithFilter,
  enablePivot: true,
  enableRowGroup: true,
};

const columnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Account Description',
    field: 'accountDescription',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Account Id',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Source',
    field: 'source',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Category',
    field: 'category',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Category Type',
    field: 'categoryType',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Filing Attributes',
    field: 'filingAttributes',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Multiplier',
    field: 'multiplier',
    width: 80,
    cellStyle: {
      textAlign: 'right',
    },
    aggFunc: 'sum',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Jurisdiction Description',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Jurisdiction Id',
    field: 'jurisdictionId',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Return Types',
    field: 'returnTypes',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Business Type',
    field: 'businessType',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Filing Type',
    field: 'filingType',
  },
  {
    ...defaultColumnDefinitionWithFilterAndPivot,
    headerName: 'Tax Type',
    field: 'taxType',
  },
];

export default columnDefinitions;
