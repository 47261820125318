import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@pwc/appkit-react/lib/Input';

import styles from './reportParam.module.scss';

const ReportNumberInput = ({ label, value, setValue, min, max }) => (
  <div className={`global-context-dropdown ${styles.reportParamDropdown}`}>
    <label className="a-form-label">{label}</label>
    <Input
      type="number"
      min={min}
      max={max}
      value={value}
      onChange={useCallback(value => setValue(value), [setValue])}
    />
  </div>
);

ReportNumberInput.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ReportNumberInput;
