import { httpPostFileAndParse, httpGetAndParse } from '../../../utils/fetchService';

export const IMPORT_ACTIONS = {
  'import-entity-permanent-information': 'Process Import Entity Permanent Information',
  'import-entity-information': 'Process Import Entity Information',
  'import-state-filing-jurisdictions': 'Process State Filing Jurisdictions',
  'import-state-transmission': 'Process State Transmission XML',
};

export const fetchCaseList = () =>
  httpGetAndParse({
    route: `/api/admin/efile/case-list`,
    errorMessage: 'Fetching case list failed',
  });

export const fetchYearSubPeriodList = () =>
  httpGetAndParse({
    route: `/api/admin/efile/year-sub-period-list`,
    errorMessage: 'Fetching year sub period list failed',
  });

export const fetchSubmissions = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/admin/efile/submissions/${taxYear}/${period}`,
    errorMessage: 'Fetching submissions failed',
  });

export const validateAndGenerateMappingForStateTransmission = ({
  file,
  submissionId,
  ttiCaseId,
  ttiYearId,
}) => {
  const body = new FormData();
  body.append('file', file);
  body.append('caseId', ttiCaseId);
  body.append('submissionId', submissionId);
  body.append('yearOrSubPeriodId', ttiYearId);

  return httpPostFileAndParse({
    route: `/api/admin/efile/validate-and-generate-mapping-for-state-transmission`,
    errorMessage: `Processing Validate and Generate Mapping For State Transmission failed`,
    body,
  });
};

export const stateTransmissionImport = ({ file, submissionId }) => {
  const body = new FormData();
  body.append('file', file);
  body.append('submissionId', submissionId);

  return httpPostFileAndParse({
    route: `/api/admin/efile/state-transmission-import`,
    errorMessage: `Processing State Transmission Import failed`,
    body,
  });
};

export const importAction = ({ action, file }) => {
  const body = new FormData();
  body.append('file', file);

  return httpPostFileAndParse({
    route: `/api/admin/efile/${action}`,
    errorMessage: `Processing '${IMPORT_ACTIONS[action]}' failed`,
    body,
  });
};

export const validateAction = ({ action, operationId }) =>
  httpGetAndParse({
    route: `/api/admin/efile/${action}/${operationId}`,
    errorMessage: `Validating '${IMPORT_ACTIONS[action]}' failed`,
  });
