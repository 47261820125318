import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';

import { fetchEntitiesTypes, updateEntitiesTypes } from './types';

export const initialState = {
  entities: [],
  isFetchingEntities: false,
  isUpdatingEntities: false,
};

const lockReturnReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchEntitiesTypes,
      payloadKey: 'entities',
      fetchingKey: 'isFetchingEntities',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateEntitiesTypes,
      fetchingKey: 'isUpdatingEntities',
    }),
  },
});

export default lockReturnReducer;
