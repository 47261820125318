import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import { BreadcrumbRoute } from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const EntityBreadcrumbRoutes = () => (
  <>
    <BreadcrumbRoute path={Routes.taxSummaries} />
    <BreadcrumbRoute path={Routes.taxRateDetails} />
    <BreadcrumbRoute path={Routes.apportionmentRateDetails} />
    <BreadcrumbRoute path={Routes.propertySummary} />
    <BreadcrumbRoute path={Routes.genericDrillCategory} />
  </>
);

export default EntityBreadcrumbRoutes;
