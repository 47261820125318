const Yup = require('yup');
const { dataTypes: dataTypesPrintService } = require('@common-packages/shared-constants');

const { dataTypes } = require('../constants');

const { stateOverrideValueSchema } = require('./stateOverrideValue.validation');
const dataTypeSchemaPart = require('./dataTypeSchemaPart');

const updateFormFieldsSchema = Yup.object()
  .shape({
    rows: Yup.array().of(
      Yup.object()
        .shape({
          dataType: Yup.string().required().oneOf(Object.values(dataTypes)),
          dataItemDataType: Yup.number().required().oneOf(Object.values(dataTypesPrintService)),
          description: Yup.string().nullable(),
          aliasName: Yup.string().required(),
          checkboxGroupId: Yup.string().nullable(),
          dataItemDefinitionId: Yup.string().nullable(),
          datasetDefinitionId: Yup.string().nullable(),
          datasetInstanceId: Yup.string().nullable(),
          page: Yup.number().nullable(),
          selectionListId: Yup.string().nullable(),
          sortOrder: Yup.number().nullable(),
          value: stateOverrideValueSchema,
          context: Yup.object().nullable().shape({
            taxYear: Yup.number().nullable(),
            shortYearId: Yup.number().nullable(),
            jurisdictionId: Yup.string().nullable(),
            orgId: Yup.string().nullable(),
            consolId: Yup.string().nullable(),
            partnerId: Yup.string().nullable(),
            binderId: Yup.number().nullable(),
            jobQueueId: Yup.number().nullable(),
            requestInitiatedBy: Yup.string().nullable(),
            options: Yup.string().nullable(),
          }),
        })
        .required(),
    ),
  })
  .strict();

const updateFormFieldsSchemaConsol = Yup.object()
  .shape({
    rows: Yup.array().of(
      Yup.object()
        .shape({
          dataType: Yup.string().nullable().oneOf(Object.values(dataTypes)),
          dataItemDataType: Yup.number().required().oneOf(Object.values(dataTypesPrintService)),
          description: Yup.string().nullable(),
          aliasName: Yup.string().required(),
          checkboxGroupId: Yup.string().nullable(),
          dataItemDefinitionId: Yup.string().nullable(),
          datasetDefinitionId: Yup.string().nullable(),
          datasetInstanceId: Yup.string().nullable(),
          page: Yup.number().nullable(),
          selectionListId: Yup.string().nullable(),
          sortOrder: Yup.number().nullable(),
          context: Yup.object().nullable().shape({
            taxYear: Yup.number().nullable(),
            shortYearId: Yup.number().nullable(),
            jurisdictionId: Yup.string().nullable(),
            orgId: Yup.string().nullable(),
            consolId: Yup.string().nullable(),
            partnerId: Yup.string().nullable(),
            binderId: Yup.number().nullable(),
            jobQueueId: Yup.number().nullable(),
            requestInitiatedBy: Yup.string().nullable(),
            options: Yup.string().nullable(),
          }),
          ...dataTypeSchemaPart,
        })
        .required(),
    ),
  })
  .strict();

module.exports = { updateFormFieldsSchema, updateFormFieldsSchemaConsol };
