import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchGlobalInformation = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/entity-information/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching global information failed',
  });

export const fetchPartnershipInformation = ({ taxYear, period, entityId, partnershipId }) =>
  httpGetAndParse({
    route: `/api/entities/entity-information/partnership-type/${taxYear}/${period}/${entityId}/${partnershipId}`,
    errorMessage: 'Fetching partnership information failed',
  });

export const updateGlobalInformation = ({
  rowsToUpdate,
  taxYear,
  jurisdictionId,
  period,
  entityId,
}) =>
  httpPut({
    route: `/api/entities/entity-information/${taxYear}/${jurisdictionId}/${period}/${entityId}`,
    errorMessage: 'Updating global information failed',
    body: { rowsToUpdate },
  });

export const fetchAllPartnerships = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/entity-information/find-all-partnerships/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching global information failed',
  });

export const updatePartnershipInformation = ({
  rowsToUpdate,
  taxYear,
  jurisdictionId,
  period,
  entityId,
}) =>
  httpPut({
    route: `/api/entities/entity-information/partnership-type/${taxYear}/${jurisdictionId}/${period}/${entityId}`,
    errorMessage: 'Updating partnership information failed',
    body: { rowsToUpdate },
  });
