import { apiAction } from '../apiAction';

import { fetchCalcStatusTypes, SET_CALC_STATUS } from './types';
import { isFetchingCalcStatusSelector } from './selectors';
import * as api from './api';

export const fetchCalcStatus = apiAction({
  types: fetchCalcStatusTypes,
  apiAction: api.fetchCalcStatus,
  isFetchingSelector: isFetchingCalcStatusSelector,
  suppressErrorNotificationWhenFetchingError: true,
});

export const setCalcStatus = calcStatusData => ({
  type: SET_CALC_STATUS,
  payload: calcStatusData,
});
