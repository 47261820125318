import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchGlobalInformationTypes,
  updateGlobalInformationTypes,
  fetchAllPartnershipsTypes,
  SELECT_PARTNERSHIP_ID,
  fetchPartnershipInformationTypes,
  updatePartnershipInformationTypes,
} from './types';

export const fetchGlobalInformation = apiAction({
  types: fetchGlobalInformationTypes,
  apiAction: api.fetchGlobalInformation,
});

export const fetchPartnershipInformation = apiAction({
  types: fetchPartnershipInformationTypes,
  apiAction: api.fetchPartnershipInformation,
});
export const updateGlobalInformation = apiAction(
  {
    types: updateGlobalInformationTypes,
    apiAction: api.updateGlobalInformation,
  },
  {
    successNotificationMessage: () => 'Global information has been successfully updated',
  },
);

export const fetchAllPartnerships = apiAction({
  types: fetchAllPartnershipsTypes,
  apiAction: api.fetchAllPartnerships,
});

export const selectPartnershipId = partnershipId => ({
  type: SELECT_PARTNERSHIP_ID,
  payload: partnershipId,
});

export const updatePartnershipInformation = apiAction(
  {
    types: updatePartnershipInformationTypes,
    apiAction: api.updatePartnershipInformation,
  },
  {
    successNotificationMessage: () => 'Partnership information has been successfully updated',
  },
);
