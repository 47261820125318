import { TOTAL_DESC } from '@common-packages/shared-constants';

import {
  currencyColumnDefinition,
  percentageColumnDefinition,
  booleanColumnDefinition,
} from '../../../shared/columnDefinitions';

const overlinedCellClassRules = {
  'overlined-cell': ({ data }) => data.orgId === TOTAL_DESC,
};

const accountCellClassRules = {
  'bold-cell': ({ value }) => value === TOTAL_DESC,
};

const defaultColumnDefinition = {
  width: 120,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
  cellClassRules: { ...overlinedCellClassRules },
};

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Org Id',
    field: 'orgId',
    cellClassRules: { ...accountCellClassRules, ...overlinedCellClassRules },
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Partnership Id',
    field: 'partnershipId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Parent Id',
    field: 'parentOrgId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Component Id',
    field: 'componentId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Id',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: 'Parent % Owned',
    field: 'ownerPercentage',
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: '% Owned',
    field: 'percentOwned',
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: '% of Year Owned',
    field: 'percentYearOwned',
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: 'Effective % Owned',
    field: 'percentEffectiveOwnership',
  },
  {
    ...defaultColumnDefinition,
    ...booleanColumnDefinition,
    headerName: 'Nexus',
    field: 'nexusYn',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Amount',
    field: 'partnershipAmount',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Flow-through Amount',
    field: 'flowthroughAmount',
  },
];

export default columnDefinitions;
