import PropTypes from 'prop-types';

export const businessesPropTypes = PropTypes.shape({
  businessId: PropTypes.string.isRequired,
  businessDescription: PropTypes.string.isRequired,
  leadCustomerId: PropTypes.string.isRequired,
  businessGroup: PropTypes.string.isRequired,
  tier: PropTypes.string,
  sirYN: PropTypes.bool.isRequired,
});
