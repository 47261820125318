import { FILING_TYPE_SEPARATE } from './constants';

// TODO: update dataType with enum when added to slt-packages
interface FilingAttributes {
  displayName: string;
  field: string;
  dataType: number;
  hide?: boolean;
}

// TODO: update columnType with enum when added to slt-packages
interface FilingAttributesDefinition {
  displayName: string;
  columnType: string;
  filingAttribute: boolean;
  children: FilingAttributes[];
}

const getFilingAttributesInitialValues = (
  filingAttributesDefinition: FilingAttributesDefinition[],
) => {
  const keys = filingAttributesDefinition
    .map(({ children }) => children.map(({ field }) => field))
    .flat();

  return keys.reduce(
    (values, currentKey) => ({
      ...values,
      [currentKey]: currentKey === FILING_TYPE_SEPARATE,
    }),
    [],
  );
};

export default getFilingAttributesInitialValues;
