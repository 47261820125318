import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';

import { dateFormatter } from '../../../../shared/formatters';
import config from '../../../../config';
import SharedHeader from '../../../../shared/displayComponents/header/header.component';
import { useQueryEntityInformationDetails } from '../../../../shared/queries/entityInformationDiagnostics';
import { useMutationRefreshEntityInformation } from '../../../../shared/mutations/entityInformationDiagnostics';
import { taxYearSelector } from '../../../../shared/store/selectors';
import { EntityInformationRefreshRequest } from '../../../../../../common/types';
import Loading from '../../../../shared/displayComponents/loading.component';
import { refreshStatusSourceMap } from '../../../../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { QueryKeys } from '../../../../shared/queries';
import { showConfirmModal } from '../../../../shared/confirmModal/store/actions';

import { PageTypes } from './enums';
import styles from './entityInformationDetails.module.scss';
import ColumnDefinitions from './entityInformationDetails.columnDefinitions';
import ReturnToFederalDataDiagnostics from './returnToEntityInformatonDiagnostics';
import EntityInformationDetailsColumn from './entityInformationDetailsColumn.component';

const EntityInformationDetails = () => {
  const dispatch = useDispatch();
  const { groupOrEntityId, reportingPeriodId, correlationId, pageType } = useParams<{
    pageType: string;
    groupOrEntityId: string;
    reportingPeriodId: string;
    correlationId: string;
  }>();
  const columnDefinitions = useMemo(() => ColumnDefinitions, []);
  const queryClient = useQueryClient();
  const taxYear = useSelector(taxYearSelector);
  const {
    mutateAsync: refreshEntityInformation,
    isLoading: isRefreshingEntityInformation,
  } = useMutationRefreshEntityInformation();

  const { data: details, isFetching: isFetchingDetails } = useQueryEntityInformationDetails({
    params: {
      entityId: pageType === PageTypes.Entity ? groupOrEntityId : null,
      reportingPeriodId,
      taxYear,
    },
    enabled: Boolean(taxYear && groupOrEntityId && pageType && reportingPeriodId),
  });

  const fullSyncClick = useCallback(() => {
    const entityInformationRefreshRequest: EntityInformationRefreshRequest = {
      taxYear,
      entityId: details?.entityId,
      entityOpsUpdatedDate: details?.entityOpsUpdatedDate,
      reportingPeriodId,
      filingGroupId: details?.filingGroupId,
    };

    dispatch(
      showConfirmModal({
        title: 'Refresh Entity Ops Information',
        text: `Are you sure you want to sync this entity with Entity Ops?`,
        confirmCallback: async () => {
          await refreshEntityInformation({
            entity: details?.entityId,
            entityInformationRefreshRequest,
          });
        },
      }),
    );
  }, [dispatch, refreshEntityInformation, reportingPeriodId, details, taxYear]);

  const realoadTableClick = useCallback(() => {
    queryClient.resetQueries(QueryKeys.EntityInformationDiagnostics.Details);
  }, [queryClient]);

  return (
    <>
      <SharedHeader
        title={details?.entityName}
        preHeaderContent={<ReturnToFederalDataDiagnostics />}
      >
        <div className={styles.subheader}>
          <span> Entity Information Diagnostics Details </span>
        </div>
      </SharedHeader>
      <div className={styles.informationPanel}>
        <div className={styles.informationPanelHeader}>
          <h4>Refresh Information</h4>
        </div>
        <Loading small isLoading={isFetchingDetails}>
          <div className="row">
            <EntityInformationDetailsColumn
              label="Updated On:"
              value={
                details?.sltUpdatedOn
                  ? dateFormatter(details.sltUpdatedOn, config.AMERICAN_DATE_TIME_FORMAT)
                  : 'Not Recorded'
              }
            />
            <EntityInformationDetailsColumn
              label="Source Updated On"
              value={
                details?.entityOpsUpdatedDate
                  ? dateFormatter(details.entityOpsUpdatedDate, config.AMERICAN_DATE_TIME_FORMAT)
                  : 'Not Recorded'
              }
            />
            <EntityInformationDetailsColumn
              label="Refresh Status:"
              value={refreshStatusSourceMap.get(details?.refreshStatus ?? 0)}
            />
          </div>
        </Loading>
        <div className={styles.buttons}>
          <Button
            size="md"
            onClick={fullSyncClick}
            className={styles.button}
            kind="secondary"
            isLoading={isRefreshingEntityInformation}
          >
            Sync Entity Information
          </Button>
          <Button
            size="md"
            onClick={realoadTableClick}
            disabled={isFetchingDetails}
            className={styles.button}
            kind="secondary"
          >
            Reload Table
          </Button>
        </div>
      </div>
      <div className={styles.tableFormat}>
        <AgGrid
          rowData={details?.logs}
          columnDefs={columnDefinitions}
          withSearchBar
          initialQuickFilterValue={correlationId || ''}
          autoMaxWidth
          isGridLoading={isFetchingDetails}
        />
      </div>
    </>
  );
};

export default EntityInformationDetails;
