import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const genericFunctionsSelector = ({ development }) =>
  development.genericFunctions.genericFunctions;
export const isFetchingGenericFunctionsSelector = ({ development }) =>
  development.genericFunctions.isFetchingGenericFunctions;

export const dataTypesSelector = ({ development }) => development.genericFunctions.dataTypes;
export const dataTypesOptionsSelector = optionsSelectorFactory({
  listSelector: dataTypesSelector,
  labelKey: 'dataType',
  valueKey: 'dataType',
});
export const isFetchingDataTypesSelector = ({ development }) =>
  development.genericFunctions.isFetchingDataTypes;

export const constantsOptionsSelector = optionsSelectorFactory({
  listSelector: ({ development }) => development.genericFunctions.constants,
  labelKey: 'constantId',
  valueKey: 'constantId',
});
export const isFetchingConstantsSelector = ({ development }) =>
  development.genericFunctions.isFetchingConstants;

export const categoriesOptionsSelector = optionsSelectorFactory({
  listSelector: ({ development }) => development.genericFunctions.categories,
  labelKey: 'categoryId',
  valueKey: 'categoryId',
});
export const isFetchingCategoriesSelector = ({ development }) =>
  development.genericFunctions.isFetchingCategories;

export const accountsOptionsSelector = optionsSelectorFactory({
  listSelector: ({ development }) => development.genericFunctions.accounts,
  labelKey: 'accountId',
  valueKey: 'accountId',
});
export const isFetchingAccountsSelector = ({ development }) =>
  development.genericFunctions.isFetchingAccounts;

export const jurisdictionsOptionsSelector = optionsSelectorFactory({
  listSelector: ({ development }) => development.genericFunctions.jurisdictions,
  labelKey: 'jurisdictionDescription',
  valueKey: 'jurisdictionId',
});
export const isFetchingJurisdictionsSelector = ({ development }) =>
  development.genericFunctions.isFetchingJurisdictions;

export const isFetchingFunctionParamsGridDropdownOptionsSelector = createSelector(
  [
    isFetchingDataTypesSelector,
    isFetchingConstantsSelector,
    isFetchingCategoriesSelector,
    isFetchingAccountsSelector,
  ],
  (...fetchingStates) => fetchingStates.some(isFetching => isFetching),
);

export const genericFunctionParamsSelector = createSelector(
  [({ development }) => development.genericFunctions.genericFunctionParams],
  genericFunctionParams =>
    genericFunctionParams.map(item => ({
      ...item,
      multiplier: parseFloat(item.multiplier),
      roundPlaces: parseInt(item.roundPlaces, 10),
    })),
);
export const isFetchingGenericFunctionParamsSelector = ({ development }) =>
  development.genericFunctions.isFetchingGenericFunctionParams;

const isCreatingGenericFunctionWithParamsSelector = ({ development }) =>
  development.genericFunctions.isCreatingGenericFunctionWithParams;
const isUpdatingGenericFunctionWithParamsSelector = ({ development }) =>
  development.genericFunctions.isUpdatingGenericFunctionWithParams;

export const isSavingGenericFunctionChangesSelector = createSelector(
  [isCreatingGenericFunctionWithParamsSelector, isUpdatingGenericFunctionWithParamsSelector],
  (isCreatingGenericFunctionWithParams, isUpdatingGenericFunctionWithParams) =>
    isCreatingGenericFunctionWithParams || isUpdatingGenericFunctionWithParams,
);
