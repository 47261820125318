import React, { ComponentType, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CLIENT_STATUS_KEY,
  getClientLocalStorageItem,
  setClientLocalStorageItem,
} from '../../localStorage';
import {
  clientIdSelector,
  isClientStatusInitializedSelector,
  setIsClientStatusInitialized,
} from '../../store/context';
import { setGlobalContext } from '../../store/actions';
import { globalContextSelector } from '../../store/selectors';

export const LOCAL_STORAGE_CONTEXT_KEYS = {
  FILING_TYPE_ID: 'filingTypeId',
  TAX_YEAR: 'taxYear',
  PERIOD: 'period',
  ENTITY: 'entity',
  JURISDICTION_ID: 'jurisdictionId',
  CONSOLIDATION_ID: 'consolidationId',
};

export const localStorageContextWrapper = (WrappedComponent: ComponentType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const LocalStorageContextWrapper = (props: any) => {
    const dispatch = useDispatch();

    const clientId = useSelector(clientIdSelector);
    const isClientStatusInitialized = useSelector(isClientStatusInitializedSelector);
    const globalContext = useSelector(globalContextSelector);
    const { params: globalContextParams } = globalContext;

    const setContextFromLocalStorage = useCallback(() => {
      const context = getClientLocalStorageItem({
        clientId,
        clientDetailKey: CLIENT_STATUS_KEY.CONTEXT,
      });
      if (context) {
        const newGlobalContext = {
          ...globalContext,
          entity: globalContext.entityId,
          ...context,
          dispatch,
          globalContext,
        };
        setGlobalContext(newGlobalContext);
      }

      dispatch(setIsClientStatusInitialized(true));
    }, [dispatch, clientId, globalContext]);

    useEffect(() => {
      if (!isClientStatusInitialized || !clientId) {
        // don't need set context before initialized
        return;
      }

      const context = getClientLocalStorageItem({
        clientId,
        clientDetailKey: CLIENT_STATUS_KEY.CONTEXT,
      });

      setClientLocalStorageItem({
        clientId,
        clientDetailKey: CLIENT_STATUS_KEY.CONTEXT,
        data: {
          ...globalContextParams,
          entity: globalContextParams.businessEntityId,
          consolidationId: globalContextParams.businessEntityId,
          isContextPinned: context?.isContextPinned,
        },
      });
    }, [clientId, globalContextParams, isClientStatusInitialized]);

    useEffect(() => {
      // only run if client id changed or enter page first time
      // we need use isClientStatusInitialized here because dropdown component will be re-rendered
      // every time navigate to a new page, and if we use useEffect(() => {...}, [])
      // to let initialization do every time we refresh page, it will be called every time navigate to a new page.
      if (isClientStatusInitialized) {
        return;
      }

      setContextFromLocalStorage();
    }, [isClientStatusInitialized, setContextFromLocalStorage]);

    return <WrappedComponent {...props} />;
  };

  return LocalStorageContextWrapper;
};
