import { Routes, taxNames } from '@common-packages/routes-definitions';

import { LinkCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import {
  currencyColumnDefinition,
  defaultColumnDefinitionWithFilter,
  disabledReadOnlyCheckboxColumnDefinitions,
} from '../../shared/columnDefinitions';
import { filingTypes } from '../../shared/constants';
import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';
import { CATEGORY_NAMES } from '../../shared/enums/filingDecisions';

const getColumnDefinitions = ({ taxYear, period }) => {
  const orgNameRenderer = LinkCellRendererFactory({
    getText: ({ value }) => value,
    getPathName: (value, data) =>
      Routes.taxSummaries.compiledRoute({
        ...getContextAwareUrlParams({
          taxYear,
          period,
          filingTypeId: filingTypes.SEPARATE,
          businessEntityId: data.orgId,
          jurisdictionId: data.jurisdictionId,
          taxName: taxNames.INCOME_TAX,
        }),
      }),
  });

  const AmountLinkRenderer = categoryNameKey =>
    LinkCellRendererFactory({
      getText: ({ valueFormatted }) => valueFormatted,
      getPathName: (value, data) =>
        Routes.filingDecisionAmountDetailsV2.compiledRoute({
          taxYear,
          period,
          businessEntityId: data.orgId,
          jurisdictionId: data.jurisdictionId,
          filingTypeId: filingTypes.SEPARATE,
          categoryName: categoryNameKey,
        }),
      getRouterState: () => ({
        categoryNameForDisplay: CATEGORY_NAMES.find(({ key }) => key === categoryNameKey).name,
      }),
    });

  const columnDefinitions = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Org ID',
      field: 'orgId',
      width: 100,
      pinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Org Name',
      field: 'orgName',
      width: 205,
      cellRenderer: orgNameRenderer,
      pinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Filing Group(s)',
      field: 'filingGroupsIds',
      width: 175,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Filing In State',
      field: 'filingInSt',
      width: 80,
      hide: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Nexus',
      field: 'nexusInd',
      width: 65,
    },
    ...CATEGORY_NAMES.map(({ key, field, name }) => ({
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: name,
      field,
      cellRenderer: AmountLinkRenderer(key),
      hide: key === 'GOVT_COP' || key === 'GOVT_MARKET',
    })),
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
