import { getApiActionTypes } from '../../shared/store/storeHelpers';

export const fetchEFileStepsDataTypes = getApiActionTypes('EFILE/FETCH_STEPS_DATA');
export const invokeEFilePre2StepsTypes = getApiActionTypes('EFILE/INVOKE_PRE2_STEP');
export const runTTIStepTypes = getApiActionTypes('EFILE/RUN_TTI_STEP');
export const fetchValidationMessagesTypes = getApiActionTypes('EFILE/FETCH_VALIDATION_MESSAGES');
export const fetchSignatureDeclarationTypes = getApiActionTypes('EFILE/GET_SIGNATURE_DECLARATION');
export const fetchFederalProformaProcessesTypes = getApiActionTypes(
  'EFILE/FETCH_FEDERAL_PROFORMA_PROCESSES',
);
export const fetchFederalProformaSubmissionIdTypes = getApiActionTypes(
  'EFILE/FETCH_FEDERAL_PROFORMA_SUBMISSION_ID',
);
export const RESET_CONTEXT_SPECIFIC_WIZARDS_STATE = 'RESET_CONTEXT_SPECIFIC_WIZARDS_STATE';
