import { httpGetAndParse } from '../../utils/fetchService';

export const fetchIncomeTaxSummary = ({ taxYear, period, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/income-tax-summary/${taxYear}/${period}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Fetching income tax summary failed',
  });

export const fetchFranchiseTaxSummary = ({ taxYear, period, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/franchise-tax-summary/${taxYear}/${period}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Fetching franchise tax summary failed',
  });

export const fetchEstimatedTaxSummary = ({ taxYear, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/estimated-tax-summary/${taxYear}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Fetching estimated tax summary failed',
  });
