import { useMemo } from 'react';

import {
  TaxYear,
  Period,
  EntityId,
  ConsolidationId,
  CalcPreferencesFormikValues,
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
  FilingAttributes,
  TaxReturnResultRow,
} from '../../../../../common/types/apiShapes';
import { DueDateOptionValuesEnum } from '../../../../../common/types/apiShapes/taxReturns';
import { BASE_CONSOLIDATION_FILING_ATTRIBUTES_VALUE } from '../../consolidations/constants';
import { FilingMethods, TaxReturnStatus, TaxReturnEFileStatus } from '../../enums';

export interface FormValues {
  taxReturn: TaxReturnResultRow | null;
  jurisdictionId: string;
  returnDefinitionId: string;
  filingMethod: FilingMethods;
  returnType: string;
  businessType: string;
  filingType: string;
  taxType: {
    incomeTax: boolean;
    franchiseTax: boolean;
  };
  calcOptions: CalcPreferencesFormikValues;
  taxYear: TaxYear;
  period: Period;
  nexusIndicator: boolean;
  entityId: EntityId;
  consolidationId: ConsolidationId;
  filingAttributes: number;
  returnStatus: TaxReturnStatus | null;
  efileStatus: TaxReturnEFileStatus | null;
  dueDateType: DueDateOptionValuesEnum | null;
  filingGroupsIds?: string[];
}

export interface ConsolidationFormValues
  extends Omit<FormValues, 'nexusIndicator' | 'entityId' | 'taxReturn'> {
  description: string;
  filingGroupIds: string[];
  proformaFilingGroupId: string | null;
  proformaRefresh: string;
  parentOrgId: string;
  active: boolean;
  lockedInd: boolean;
  taxYearEnding?: string;
}

export type ConsolidationAddEditFormValues = ConsolidationFormValues &
  {
    [filingAttributesKey in keyof FilingAttributes]: boolean;
  } & {
    consolidationMethod: string;
    oldParentOrgId: string | null;
  };

const ConsolidationEmptyFormValues: ConsolidationFormValues = {
  jurisdictionId: '',
  returnDefinitionId: '',
  filingMethod: FilingMethods.NONE,
  returnType: '',
  businessType: '',
  filingType: '',
  taxType: {
    incomeTax: false,
    franchiseTax: false,
  },
  calcOptions: {},
  taxYear: '',
  period: '',
  consolidationId: '',
  description: '',
  filingAttributes: BASE_CONSOLIDATION_FILING_ATTRIBUTES_VALUE,
  filingGroupIds: [],
  proformaFilingGroupId: null,
  proformaRefresh: 'N',
  parentOrgId: '',
  active: true,
  lockedInd: false,
  returnStatus: TaxReturnStatus.NOT_STARTED,
  efileStatus: null,
  dueDateType: null,
};

const EmptyFormValues: FormValues = {
  taxReturn: null,
  jurisdictionId: '',
  returnDefinitionId: '',
  filingMethod: FilingMethods.NONE,
  returnType: '',
  businessType: '',
  filingType: '',
  taxType: {
    incomeTax: false,
    franchiseTax: false,
  },
  nexusIndicator: false,
  calcOptions: {},
  taxYear: '',
  period: '',
  entityId: '',
  consolidationId: '',
  filingAttributes: 0,
  returnStatus: TaxReturnStatus.NOT_STARTED,
  efileStatus: null,
  dueDateType: null,
};

interface UseFormInitialValuesProps {
  taxReturnToEdit: TaxReturnResultRowWithCalcOptionsAndFilingAttributes | null;
  taxYear: string | null;
  period: string | null;
  entityId?: string;
  isConsolidation?: boolean;
}

export const useFormInitialValues = ({
  taxReturnToEdit,
  taxYear,
  period,
  entityId,
  isConsolidation = false,
}: UseFormInitialValuesProps) => {
  const initialValues: FormValues | ConsolidationFormValues = useMemo(() => {
    const defaultValues = {
      filingType: '',
      businessType: '',
      returnType: '',
      taxType: { incomeTax: false, franchiseTax: false },
    };

    const { filingType, businessType, taxType, returnType } = taxReturnToEdit
      ? Object.entries(taxReturnToEdit).reduce((acc, [key, value]) => {
          if (key.startsWith('FILING_TYPE_') && value) {
            return {
              ...acc,
              filingType: key,
            };
          }
          if (key.startsWith('BUSINESS_TYPE_') && value) {
            return {
              ...acc,
              businessType: key,
            };
          }
          if (key.startsWith('RETURN_TYPE_') && value) {
            return {
              ...acc,
              returnType: key,
            };
          }
          if (key === 'TAX_TYPE_INCOME' && value) {
            return {
              ...acc,
              taxType: {
                ...acc.taxType,
                incomeTax: true,
              },
            };
          }
          if (key === 'TAX_TYPE_FRANCHISE' && value) {
            return {
              ...acc,
              taxType: {
                ...acc.taxType,
                franchiseTax: true,
              },
            };
          }
          return acc;
        }, defaultValues)
      : defaultValues;

    return {
      ...(isConsolidation ? ConsolidationEmptyFormValues : EmptyFormValues),
      ...taxReturnToEdit,
      filingType,
      businessType,
      returnType,
      taxType,
      active:
        typeof taxReturnToEdit?.active !== 'undefined' ? Boolean(taxReturnToEdit?.active) : true,
      lockedInd: Boolean(taxReturnToEdit?.lockedInd),
      taxYear,
      period,
      calcOptions: taxReturnToEdit?.calcOptions ? JSON.parse(taxReturnToEdit.calcOptions) : {},
      ...(taxReturnToEdit && !isConsolidation
        ? {
            efileStatus: taxReturnToEdit?.taxReturn?.efileStatus,
            returnDefinitionId: taxReturnToEdit.taxReturn?.returnDefinitionId,
            returnStatus: taxReturnToEdit.taxReturn?.returnStatus || TaxReturnStatus.NOT_STARTED,
            filingMethod: taxReturnToEdit.taxReturn?.filingMethod || FilingMethods.NONE,
            dueDateType: taxReturnToEdit.taxReturn?.dueDateType,
          }
        : {}),
      ...(isConsolidation
        ? { filingMethod: taxReturnToEdit?.filingMethod || FilingMethods.NONE } // when filingMethod is null, radio component will not show values, make '' instead of null
        : {
            entityId,
          }),
    } as FormValues | ConsolidationFormValues;
  }, [taxReturnToEdit, taxYear, period, isConsolidation, entityId]);

  return initialValues;
};
