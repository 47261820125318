import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@tls/ui-modal';

import { useMutationDeleteBranch } from '../../queries/utilities';
import { globalContextSelector } from '../../store/selectors';

interface deleteBranchModalProps {
  hideModal: () => void;
  visible: boolean;
}

const DeleteBranchModal = ({ hideModal, visible }: deleteBranchModalProps) => {
  const globalContext = useSelector(globalContextSelector);
  const { taxYear, period } = globalContext;
  const { mutateAsync: deleteBranch } = useMutationDeleteBranch();

  const onSubmitAction = useCallback(() => {
    deleteBranch({ taxYear, period });
    hideModal();
  }, [taxYear, period, deleteBranch, hideModal]);

  return (
    <Modal
      title="Delete Branch Entity Returns"
      visible={visible}
      closeAction={hideModal}
      dismissText="Cancel"
      dismissAction={hideModal}
      submitText="Yes"
      submitAction={onSubmitAction}
    >
      <p>
        Are you sure you want to delete all returns associated with branch entities in this
        instance?
      </p>
    </Modal>
  );
};

export default DeleteBranchModal;
