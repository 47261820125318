import { useCallback, useEffect } from 'react';
import { useFormikContext, FieldConfig } from 'formik';

import { ReturnFilingValues } from '../../types';
import { WizardStateMachineSetFederalSubmissionId } from '../../../../../wizardStateMachine/types';
import { FederalReturnOption } from '../../../../../../../shared/enums/eFileV2';

interface UseManual {
  setFederalSubmissionId: (submissionId: WizardStateMachineSetFederalSubmissionId) => void;
  wizardStateMachineSetFederalSubmissionId: WizardStateMachineSetFederalSubmissionId;
  selectedFederalReturnOption: FieldConfig['value'];
}

const useManualFederalProformaSubmission = ({
  setFederalSubmissionId,
  wizardStateMachineSetFederalSubmissionId,
  selectedFederalReturnOption,
}: UseManual) => {
  const {
    setFieldValue,
    initialValues,
    values,
    setFieldTouched,
  } = useFormikContext<ReturnFilingValues>();

  const handleManualFederalSubmissionIdChange = useCallback(
    (_: unknown, newValue: string | { label: string; value: string }) => {
      setFieldValue('manualFederalProformaSubmissionId', newValue);
      setFederalSubmissionId(newValue as string);
    },
    [setFieldValue, setFederalSubmissionId],
  );

  const handleManualFederalProformaSubmissionIdOptionsClick = useCallback(
    (event: { stopPropagation: () => void; preventDefault: () => void }) => {
      event.stopPropagation();
      event.preventDefault();
    },
    [],
  );

  useEffect(() => {
    if (selectedFederalReturnOption !== FederalReturnOption.FED_MANUAL_PROFORMA) {
      return;
    }

    setFederalSubmissionId(values?.manualFederalProformaSubmissionId || '');
  }, [
    selectedFederalReturnOption,
    setFederalSubmissionId,
    values?.manualFederalProformaSubmissionId,
  ]);

  useEffect(() => {
    if (
      !values.manualFederalProformaSubmissionId &&
      selectedFederalReturnOption === FederalReturnOption.FED_MANUAL_PROFORMA
    ) {
      setFieldTouched('manualFederalProformaSubmissionId', true);
    }
  }, [selectedFederalReturnOption, setFieldTouched, values.manualFederalProformaSubmissionId]);

  useEffect(() => {
    if (selectedFederalReturnOption === FederalReturnOption.FED_MANUAL_PROFORMA) {
      // determine whether to show manualFederalProformaSubmissionId value from DB
      // versus when the user changes the value of manualFederalProformaSubmissionId
      const isManualFederalSubmissionIdFieldDirty =
        initialValues?.manualFederalProformaSubmissionId !==
        values?.manualFederalProformaSubmissionId;

      setFederalSubmissionId(
        isManualFederalSubmissionIdFieldDirty
          ? wizardStateMachineSetFederalSubmissionId
          : initialValues?.manualFederalProformaSubmissionId || '',
      );
    }
  }, [
    selectedFederalReturnOption,
    initialValues?.manualFederalProformaSubmissionId,
    setFederalSubmissionId,
    values?.manualFederalProformaSubmissionId,
    wizardStateMachineSetFederalSubmissionId,
  ]);

  return {
    handleManualFederalSubmissionIdChange,
    handleManualFederalProformaSubmissionIdOptionsClick,
  };
};

export default useManualFederalProformaSubmission;
