import PropTypes from 'prop-types';

export interface CustomerPermissionsProps {
  read: string[];
  edit: string[];
}

export const customerPermissionsPropTypes = PropTypes.shape({
  read: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  edit: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});

export interface CustomerProps {
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
}

const customerPropTypes = PropTypes.shape({
  customerId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});

export default customerPropTypes;
