export const genericScreenSelector = ({ shared }) => shared.genericScreen.genericScreen.data;
export const lockIndicatorSelector = ({ shared }) =>
  shared.genericScreen.genericScreen.lockIndicator;
export const genericScreenHeaderSelector = ({ shared }) =>
  shared.genericScreen.genericScreenHeader.header;
export const isFetchingGenericScreenSelector = ({ shared }) =>
  shared.genericScreen.isFetchingGenericScreen;
export const isFetchingGenericScreenHeaderSelector = ({ shared }) =>
  shared.genericScreen.isFetchingGenericScreenHeader;
export const isUpdatingGenericScreenSelector = ({ shared }) =>
  shared.genericScreen.isUpdatingGenericScreen;
