import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { genericScreenDefinitionSchemas } from '@common-packages/validators';

import Loading from '../../shared/displayComponents/loading.component';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';

import {
  fetchGenericScreenDefinition,
  createGenericScreenDefinition,
  updateGenericScreenDefinition,
  fetchJurisdictions,
} from './store/actions';
import {
  accountsOptionsSelector,
  categoriesOptionsSelector,
  isFetchingCategoriesSelector,
  isFetchingAccountsSelector,
  isFetchingAllJurisdictionsSelector,
  allJurisdictionsOptionsSelector,
} from './store/selectors';
import EditGenericScreenDefinitionForm from './editGenericScreenDefinitionForm.component';
import { genericScreenDefinitionTypes } from './propTypes';

const formIntitialValues = {
  jurisdictionId: '',
  screenId: '',
  title: '',
  categoryId: '',
  accountId: '',
  screenType: '',
  saveButton: '',
};

const EditGenericScreenDefinition = ({
  taxYear,
  period,

  isFetchingCategories,
  categoriesOptions,

  isFetchingAccounts,
  accountsOptions,

  fetchJurisdictions,
  isFetchingJurisdictions,
  jurisdictionsOptions,

  createGenericScreenDefinition,
  updateGenericScreenDefinition,
  fetchGenericScreenDefinition,

  clearForm,
  screenToEdit,

  redirectToRowDetailsPage,
}) => {
  const isEditMode = Boolean(screenToEdit);

  useEffect(() => {
    if (taxYear) {
      fetchJurisdictions({ taxYear });
    }
  }, [fetchJurisdictions, taxYear]);

  const saveGenericScreenDefinition = useCallback(
    async ({ screenId, jurisdictionId, categoryId, accountId, title, screenType, saveButton }) => {
      const data = {
        taxYear,
        period,
        screenId,
        jurisdictionId,
        categoryId,
        accountId,
        title,
        screenType,
        saveButton,
      };

      if (!isEditMode) {
        await createGenericScreenDefinition(data);
        await fetchGenericScreenDefinition({ taxYear, period });
        redirectToRowDetailsPage(screenId);
      } else {
        await updateGenericScreenDefinition(data);
        clearForm();
        fetchGenericScreenDefinition({ taxYear, period });
      }
    },
    [
      createGenericScreenDefinition,
      updateGenericScreenDefinition,
      fetchGenericScreenDefinition,
      redirectToRowDetailsPage,
      clearForm,
      taxYear,
      period,
      isEditMode,
    ],
  );

  const renderForm = useCallback(
    formikProps => (
      <Loading isLoading={isFetchingAccounts || isFetchingCategories || isFetchingJurisdictions}>
        <EditGenericScreenDefinitionForm
          {...formikProps}
          onCancelClick={formikProps.resetForm}
          accountsOptions={accountsOptions}
          categoriesOptions={categoriesOptions}
          jurisdictionsOptions={jurisdictionsOptions}
          isEditMode={isEditMode}
        />
      </Loading>
    ),
    [
      isFetchingAccounts,
      isFetchingCategories,
      isFetchingJurisdictions,
      accountsOptions,
      categoriesOptions,
      jurisdictionsOptions,
      isEditMode,
    ],
  );

  return (
    <Formik
      initialValues={screenToEdit ? screenToEdit : formIntitialValues}
      enableReinitialize
      validationSchema={genericScreenDefinitionSchemas.commonSchema}
      validateOnBlur={false}
      onSubmit={saveGenericScreenDefinition}
    >
      {renderForm}
    </Formik>
  );
};

EditGenericScreenDefinition.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  accountsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  categoriesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingCategories: PropTypes.bool.isRequired,
  isFetchingAccounts: PropTypes.bool.isRequired,
  createGenericScreenDefinition: PropTypes.func.isRequired,
  fetchGenericScreenDefinition: PropTypes.func.isRequired,
  updateGenericScreenDefinition: PropTypes.func.isRequired,
  screenToEdit: PropTypes.shape(genericScreenDefinitionTypes),
  fetchJurisdictions: PropTypes.func.isRequired,
  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingJurisdictions: PropTypes.bool.isRequired,
  clearForm: PropTypes.func.isRequired,
  redirectToRowDetailsPage: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    accountsOptions: accountsOptionsSelector(state),
    categoriesOptions: categoriesOptionsSelector(state),
    jurisdictionsOptions: allJurisdictionsOptionsSelector(state),
    isFetchingAccounts: isFetchingAccountsSelector(state),
    isFetchingCategories: isFetchingCategoriesSelector(state),
    isFetchingJurisdictions: isFetchingAllJurisdictionsSelector(state),
  }),
  {
    createGenericScreenDefinition,
    updateGenericScreenDefinition,
    fetchGenericScreenDefinition,
    fetchJurisdictions,
  },
)(EditGenericScreenDefinition);
