import PropTypes from 'prop-types';

export const SelectValuePropTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const SelectOptionPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: SelectValuePropTypes.isRequired,
});

export const SelectOptionPropTypesAllowNullValue = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: SelectValuePropTypes,
});

export const SelectTabPropTypes = PropTypes.shape({
  text: PropTypes.string,
  value: PropTypes.string,
});
