export const ENTITY_ID = 'ENTITY_ID';
export const ENTITY_NAME = 'ENTITY_NAME';
export const ENTITY_EIN = 'ENTITY_EIN';
export const ORG = 'ORG';

export const EntityDisplayOptions = [
  { value: ENTITY_ID, label: 'Entity ID' },
  { value: ENTITY_NAME, label: 'Entity Name' },
  { value: ENTITY_EIN, label: 'Entity EIN' },
];

export const DisplayLevelTypes = {
  HEADER: 'HEADER',
  DATA: 'DATA',
  CALC: 'CALC',
};

export const LinesToFollowTypes = {
  SINGLE: 'SINGLE',
  TOTAL: 'TOTAL',
  NONE: 'NONE',
  DOUBLE: 'DOUBLE',
};
