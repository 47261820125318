import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchBusinessOptionsTypes,
  fetchAttachmentDefinitionsTypes,
  createAttachmentTypes,
  deleteAttachmentTypes,
  updateAttachmentTypes,
} from './types';

export const fetchBusinessOptions = apiAction({
  types: fetchBusinessOptionsTypes,
  apiAction: api.fetchBusinessOptions,
});

export const fetchAttachmentDefinitions = apiAction({
  types: fetchAttachmentDefinitionsTypes,
  apiAction: api.fetchAttachmentDefinitions,
});

export const createAttachment = apiAction(
  {
    types: createAttachmentTypes,
    apiAction: api.createAttachment,
  },
  {
    successNotificationMessage: () => 'Attachment has been successfully created',
  },
);

export const deleteAttachment = apiAction(
  {
    types: deleteAttachmentTypes,
    apiAction: api.deleteAttachment,
  },
  {
    successNotificationMessage: () => 'Attachment has been successfully deleted',
  },
);

export const updateAttachment = apiAction(
  {
    types: updateAttachmentTypes,
    apiAction: api.updateAttachment,
  },
  {
    successNotificationMessage: () => 'Attachment has been successfully updated',
  },
);
