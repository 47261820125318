import { useEffect, useRef } from 'react';
import { JobStatus } from '@common-packages/shared-constants';

import { isJobInProgress } from './utils';

const noop = () => null;

const useJobFinishWatcher = ({
  job,
  onObservableSuccess = noop,
  onObservableError = noop,
  onObservableFinish = noop,
}) => {
  const watchedJobs = useRef(new Map());

  useEffect(() => {
    if (!job) {
      return;
    }

    const isWatchedJob = watchedJobs.current.has(job.jobId);

    if (!isWatchedJob) {
      watchedJobs.current.set(job.jobId, job.status);
      return;
    }

    const lastStatus = watchedJobs.current.get(job.jobId);
    const currentStatus = job.status;

    if (lastStatus === currentStatus) {
      return;
    }

    if (isJobInProgress(lastStatus) && !isJobInProgress(currentStatus)) {
      onObservableFinish();

      switch (currentStatus) {
        case JobStatus.FAILED:
          onObservableError(job);
          break;
        case JobStatus.SUCCESS:
          onObservableSuccess(job);
          break;
        default:
          break;
      }
    }

    watchedJobs.current.set(job.jobId, job.status);
  }, [onObservableSuccess, onObservableError, onObservableFinish, job]);
};

export default useJobFinishWatcher;
