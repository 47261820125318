import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { defaultSideBarWithColumnsToolPanel } from '../../../../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../../../../shared/displayComponents/agGrid/agGrid.component';
import { columnsBlueprintPropTypes } from '../../../../../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import { FunctionNames, AgGridIds } from '../../constants';
import { columnBlueprintHeaderOptions } from '../../../../../shared/columnDefinitions/constants';

import getColumnDefinitions from './constantsGrid.columnDefinitions';

const ConstantsGrid = ({
  columnsBlueprint,
  constants,
  isLoading,
  onGridReady,
  onFilterChanged,
  addFunctionSepExpression,
  addFunctionConsolExpression,
  generateContextMenuItems,
}) => {
  const columnDefinitions = useMemo(() => getColumnDefinitions(columnsBlueprint), [
    columnsBlueprint,
  ]);

  const onConstantSelect = useCallback(
    ({ data }) => {
      if (data) {
        addFunctionSepExpression({
          functionName: FunctionNames.GET_CONSTANT,
          paramsArray: [data.constantId],
        });
      }
    },
    [addFunctionSepExpression],
  );

  const onConsolExpressionSelect = useCallback(
    ({ data, event }) => {
      if (data && (event.metaKey || event.ctrlKey)) {
        addFunctionConsolExpression({
          functionName: FunctionNames.GET_CONSTANT,
          paramsArray: [data.constantId],
        });
      }
    },
    [addFunctionConsolExpression],
  );

  const contextMenuItems = useMemo(
    () =>
      generateContextMenuItems(
        ({ data }) => {
          addFunctionSepExpression({
            functionName: FunctionNames.GET_CONSTANT,
            paramsArray: [data.constantId],
          });
        },
        ({ data }) => {
          addFunctionConsolExpression({
            functionName: FunctionNames.GET_CONSTANT,
            paramsArray: [data.constantId],
          });
        },
      ),
    [generateContextMenuItems, addFunctionSepExpression, addFunctionConsolExpression],
  );

  return (
    <AgGrid
      columnDefs={columnDefinitions}
      isGridLoading={isLoading}
      rowData={constants}
      onCellClicked={onConsolExpressionSelect}
      onCellDoubleClicked={onConstantSelect}
      withSearchBar
      onGridReady={onGridReady}
      onFilterChanged={onFilterChanged}
      sideBar={defaultSideBarWithColumnsToolPanel}
      {...columnBlueprintHeaderOptions}
      areHeaderCellBordersEnabled
      getContextMenuItems={contextMenuItems}
      saveGridStateId={AgGridIds.CONSTANTS}
    />
  );
};

ConstantsGrid.propTypes = {
  columnsBlueprint: columnsBlueprintPropTypes,
  constants: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      constantId: PropTypes.string.isRequired,
      filingAttributes: PropTypes.number.isRequired,
      value: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onGridReady: PropTypes.func.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  addFunctionSepExpression: PropTypes.func.isRequired,
  addFunctionConsolExpression: PropTypes.func.isRequired,
  generateContextMenuItems: PropTypes.func.isRequired,
};

export default ConstantsGrid;
