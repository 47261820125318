const FORBIDDEN_CHARACTERS = [
  '/',
  '\\',
  ';',
  '|',
  '[',
  ']',
  '<',
  '>',
  '^',
  '`',
  '&',
  '"',
  "'",
  ':',
  '?',
  '*',
];

const attachementFileNameValidation = fileName => {
  const containsForbiddenCharacter = Array.from(fileName).some(character =>
    FORBIDDEN_CHARACTERS.includes(character),
  );
  const containsCharactersOutsideAllowedRange = RegExp('[^\x20-\x7E]').test(fileName);

  return !(
    containsForbiddenCharacter ||
    containsCharactersOutsideAllowedRange ||
    fileName.includes('..')
  );
};

export default attachementFileNameValidation;
