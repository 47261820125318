import React, { useState, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import debounce from 'lodash.debounce';
import { Input } from '@pwc/appkit-react';

import AppkitIcon from '../appkitIcon/appkitIcon.component';

interface SearchBarProps {
  className?: string;
  placeholder?: string;
  onSearch?: (newValue: string) => unknown;
}

interface SearchBarHandle {
  clearSearchText: () => void;
}

// disabling because when using `forwardRef`, it isn't automatically detected that we don't need
// prop types when using TS types/interfaces
const SearchBar = forwardRef<SearchBarHandle, SearchBarProps>(
  ({ className = '', placeholder = 'Search...', onSearch = () => null }, ref) => {
    const [searchText, setSearchText] = useState('');

    const searchDebounced = useMemo(
      () =>
        debounce((newValue: string) => {
          onSearch(newValue);
        }, 500),
      [onSearch],
    );

    const handleChange = useCallback(
      (newValue: string) => {
        setSearchText(newValue);
        searchDebounced(newValue);
      },
      [searchDebounced],
    );

    const clearSearchText = useCallback(() => {
      setSearchText('');
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        clearSearchText,
      }),
      [clearSearchText],
    );

    return (
      <Input
        className={className}
        value={searchText}
        onChange={handleChange}
        placeholder={placeholder}
        prefix={<AppkitIcon className="" type="fill" size={12} icon="search" />}
      />
    );
  },
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;
