import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import omit from 'lodash.omit';
import { Button } from '@pwc/appkit-react';

import { selectJurisdiction } from '../store/actions';
import {
  taxYearSelector,
  jurisdictionsOptionsSelector,
  jurisdictionIdSelector,
  isFetchingJurisdictionsSelector,
} from '../store/selectors';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import DevelopmentTaxYearDropdown from '../developmentTaxYearDropdown.container';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import headerStyles from '../../shared/displayComponents/headerWithParamDropdowns/styles.module.scss';
import { useRowEditMode } from '../../shared/editMode';

import { fetchAttachmentDefinitions, updateAttachmentDefinitions } from './store/actions';
import {
  attachmentDefinitionsSelector,
  isFetchingAttachmentDefinitionsSelector,
  isUpdatingAttachmentDefinitionsSelector,
} from './store/selectors';
import getColumnDefinitions from './attachmentDefinitions.columnsDefinitions';
import { agGridSomeRows } from './utils';

const getMaxDisplayOrder = gridApi => {
  let maxDisplayOrder = 0;
  gridApi.forEachNode(({ data }) => {
    maxDisplayOrder = Math.max(maxDisplayOrder, data.displayOrder);
  });
  return maxDisplayOrder;
};

const getUniqueTempName = gridApi => {
  let index = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const newName = index === 0 ? '<name>' : `<name${index}>`;
    const isNewNameTaken = agGridSomeRows(gridApi, ({ data }) => data.name === newName);
    if (!isNewNameTaken) {
      return newName;
    }
    index = index + 1;
  }
};

const getUniqueRowId = ({ data: { attachmentDefinitionId } }) => attachmentDefinitionId;
const newRowDefaultValues = {
  submissionFileName: '',
  attachmentDescription: '',
  xPathReference: '',
};

const AttachmentDefinitions = ({
  taxYear,

  jurisdictionId,
  selectJurisdiction,

  jurisdictionsOptions,
  isFetchingJurisdictions,

  attachmentDefinitions,
  isFetchingAttachmentDefinitions,
  isUpdatingAttachmentDefinitions,
  fetchAttachmentDefinitions,
  updateAttachmentDefinitions,

  hasUserPermissionsToEdit,
}) => {
  const isContextReady = taxYear && jurisdictionId;

  useEffect(() => {
    if (isContextReady) {
      fetchAttachmentDefinitions({ taxYear, jurisdictionId });
    }
  }, [fetchAttachmentDefinitions, taxYear, jurisdictionId, isContextReady]);

  const isLoading =
    !isContextReady ||
    isFetchingAttachmentDefinitions ||
    isUpdatingAttachmentDefinitions ||
    isFetchingJurisdictions;

  const onSave = useCallback(
    async ({ rowsPairsWithChanges, rowsToAdd, rowsToDelete }) => {
      await updateAttachmentDefinitions({
        taxYear,
        jurisdictionId,
        changes: {
          rowsPairsWithChanges: rowsPairsWithChanges.map(({ newRow }) => newRow),
          rowsToAdd: rowsToAdd.map(row => omit(row, ['attachmentDefinitionId'])),
          rowsToDelete,
        },
      });

      fetchAttachmentDefinitions({ taxYear, jurisdictionId });
    },
    [updateAttachmentDefinitions, fetchAttachmentDefinitions, taxYear, jurisdictionId],
  );

  const {
    navigationPrompt,
    isInEditMode,
    setIsInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    addRow,
    deleteRow,
    onGridReady,
    gridApi,
  } = useRowEditMode({
    onSave,
    rowData: attachmentDefinitions,
    getUniqueRowId,
    editButtonDisabled: isLoading,
  });

  const addAttachmentDefinition = useCallback(() => {
    const displayOrder = getMaxDisplayOrder(gridApi) + 10;
    const name = getUniqueTempName(gridApi);
    const tempId = uuid();
    addRow({
      ...newRowDefaultValues,
      displayOrder,
      attachmentDefinitionId: tempId,
      name,
    });
    setIsInEditMode(true);
  }, [addRow, setIsInEditMode, gridApi]);

  const removeAttachmentDefinition = useCallback(
    (...args) => {
      setIsInEditMode(true);
      deleteRow(...args);
    },
    [setIsInEditMode, deleteRow],
  );

  const columnDefinitions = useMemo(
    () => getColumnDefinitions({ isInEditMode, updateRow, deleteRow: removeAttachmentDefinition }),
    [updateRow, removeAttachmentDefinition, isInEditMode],
  );

  return (
    <>
      {navigationPrompt}
      <div className={headerStyles.flexSpaceBetween}>
        <div className={headerStyles.headerParam}>
          <DevelopmentTaxYearDropdown />
          <ParamDropdown
            label="Jurisdiction"
            options={jurisdictionsOptions}
            value={jurisdictionId}
            handleChange={selectJurisdiction}
            isBusy={isFetchingJurisdictions}
          />
        </div>
      </div>
      <div className="add-button-column">
        {hasUserPermissionsToEdit && (
          <>
            <Button
              size="lg"
              className="add-button"
              onClick={addAttachmentDefinition}
              disabled={isLoading}
            >
              Add New Definition
            </Button>
            {editModeButtons}
          </>
        )}
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            isGridLoading={isLoading}
            columnDefs={columnDefinitions}
            onGridReady={onGridReady}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            suppressCellFocus={!isInEditMode}
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
    </>
  );
};

AttachmentDefinitions.propTypes = {
  taxYear: PropTypes.string,

  jurisdictionId: PropTypes.string,
  selectJurisdiction: PropTypes.func.isRequired,

  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingJurisdictions: PropTypes.bool.isRequired,

  attachmentDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      attachmentDefinitionId: PropTypes.number.isRequired,
      displayOrder: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      submissionFileName: PropTypes.string,
      attachmentDescription: PropTypes.string,
      xPathReference: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isFetchingAttachmentDefinitions: PropTypes.bool.isRequired,
  isUpdatingAttachmentDefinitions: PropTypes.bool.isRequired,
  fetchAttachmentDefinitions: PropTypes.func.isRequired,
  updateAttachmentDefinitions: PropTypes.func.isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),

    jurisdictionId: jurisdictionIdSelector(state),

    jurisdictionsOptions: jurisdictionsOptionsSelector(state),
    isFetchingJurisdictions: isFetchingJurisdictionsSelector(state),

    attachmentDefinitions: attachmentDefinitionsSelector(state),
    isFetchingAttachmentDefinitions: isFetchingAttachmentDefinitionsSelector(state),
    isUpdatingAttachmentDefinitions: isUpdatingAttachmentDefinitionsSelector(state),
  }),
  {
    selectJurisdiction,
    fetchAttachmentDefinitions,
    updateAttachmentDefinitions,
  },
)(AttachmentDefinitions);
