import { useCallback } from 'react';

const nonIdPartsFromPath = /(^.*\[|\].*$)/g;

const useEditModeErrorColumn = ({
  validationSchema,
  gridApi,
  columnApi,
  errorCol = 'errorCol',
} = {}) => {
  const validateEditModeColumnsAndShowPossibleErrors = useCallback(async () => {
    try {
      const rows = [];
      gridApi.forEachNode(({ data }) => rows.push(data));
      await validationSchema.validate(rows, { abortEarly: false });

      columnApi.setColumnVisible(errorCol, false);

      return true;
    } catch (errors) {
      const errorsIndexMessage =
        errors?.inner?.reduce((acc, curr) => {
          const id = parseInt(curr.path.replace(nonIdPartsFromPath, ''), 10);
          const count = acc[id] ? acc[id].count + 1 : 1;
          return {
            ...acc,
            [id]: {
              count,
              message: acc[id]
                ? `${acc[id].message}. ${count}: ${curr.message}`
                : `${count}: ${curr.message}`,
            },
          };
        }, {}) || {};

      const itemsToUpdate = [];
      gridApi?.forEachNode((rowNode, index) => {
        const error = errorsIndexMessage[index];
        const data = rowNode.data;

        if (error || data.error) {
          data.error = error ? `${error.message}` : null;
          itemsToUpdate.push(data);
        }
      });
      columnApi?.setColumnVisible(errorCol, true);
      gridApi?.applyTransaction({ update: itemsToUpdate });

      return false;
    }
  }, [validationSchema, gridApi, columnApi, errorCol]);

  return { validateEditModeColumnsAndShowPossibleErrors };
};

export default useEditModeErrorColumn;
