const getOptionsListFromObjectKeys = (inputObject, capitalize) =>
  Object.keys(inputObject).map(objectKey => {
    const value = inputObject[objectKey];
    return {
      value,
      label: capitalize ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : value,
    };
  });

export default getOptionsListFromObjectKeys;
