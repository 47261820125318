import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';
import { InfoAccountScreenType } from '@common-packages/shared-constants';

import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { useQueryFieldDrillInfo } from '../../../shared/queries/viewTaxReturn';
import {
  globalContextSelector,
  jurisdictionDescriptionSelector,
  formsByPeriodOptionsWithFormIdSelector,
} from '../../../shared/store/selectors';
import { formDataInstancesSelector } from '../../../shared/store/viewTaxReturn/selectors';
import Loading from '../../../shared/displayComponents/loading.component';
import { OverridableFieldPropTypes } from '../overridableForm.propTypes';
import { DataTypeNames } from '../../enums';

import FieldDrillLink from './fieldDrillLink.component';
import styles from './fieldPopupMenu.module.scss';

const CLEAR_OVERRIDE_LABEL = 'Clear override';
const SET_OVERRIDE_LABEL = 'Set override';
const NOT_OVERRIDABLE_LABEL = `You cannot override SSNs. Use the Entity Maintenance screen to edit the individual's SSN.`;

const getInfoDataDrillRoute = ({ data, isConsolidated, member }) => {
  switch (data.infoAccountScreenType) {
    case InfoAccountScreenType.COMMON:
      return Routes.stateCommonInformation;
    case InfoAccountScreenType.STATE_SPECIFIC:
      if (isConsolidated) {
        if (data.infoAccountPartnerOrMember) {
          return Routes.stateSpecificInformationV2;
        } else {
          return member ? Routes.memberInformationalData : Routes.informationalDataV2;
        }
      }
      return Routes.stateSpecificInformationV2;
    case InfoAccountScreenType.K1_OR_MEMBER:
      return isConsolidated ? Routes.memberInformationalData : Routes.scheduleK1InformationV2;
    case InfoAccountScreenType.GLOBAL:
      return isConsolidated ? Routes.consolidationInfo : Routes.entityInformation;
    case InfoAccountScreenType.GLOBAL_K1_OR_MEMBER:
      return Routes.entityInformation;
    default:
      throw new Error('Unsupported Info Account Screen Type');
  }
};

const getPopupStyles = clickPosition => {
  const { x, y } = clickPosition;

  return {
    top: `${y}px`,
    left: `${x}px`,
    maxHeight: `calc(100% - ${y}px - 50px - 10px)`,
    // 50px is footer height, 10 for margin
  };
};

const checkIfShouldDisplayClearValueButton = ({
  dataType,
  formattedData,
  isOverridden,
  isEditable,
}) => dataType === DataTypeNames.Date && (isOverridden || isEditable) && Boolean(formattedData);

const FieldPopupMenu = ({
  clickPosition,
  fieldValue,
  handleClick,
  handleFieldUpdateAndFormatting,
}) => {
  const { queryParams } = useUrlParams();
  const { period, entityId, filingTypeId, isConsolidated, params, consolidationId } = useSelector(
    globalContextSelector,
  );
  const formsOptions = useSelector(formsByPeriodOptionsWithFormIdSelector);
  const formDataInstances = useSelector(formDataInstancesSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);

  const { formId, member } = queryParams;

  const memberByAggregatedSourceOrgID = formDataInstances.filter(i => i.id === member)[0]
    ?.aggregatedSourceOrgID;

  const formLabel = useMemo(() => formsOptions.find(({ value }) => value === formId)?.label, [
    formId,
    formsOptions,
  ]);

  const {
    isOverridden,
    isEditable,
    isEncryptedAndHasEncryptedValue,
    dataType,
    formattedData,
    didID,
    dsiID,
  } = fieldValue;

  const { data: fieldDrillInfo, isFetching: isFetchingFieldDrillInfo } = useQueryFieldDrillInfo({
    params: {
      formId,
      dataItemDefId: didID,
      dataSetInstanceId: dsiID,
      period,
      entityId,
      consolidationId: isConsolidated ? consolidationId : null,
    },
    enabled: Boolean(
      formId &&
        didID &&
        dsiID &&
        (period || period === 0) &&
        entityId &&
        filingTypeId &&
        !isEncryptedAndHasEncryptedValue,
    ),
  });

  const handleClearValueClick = useCallback(() => {
    handleFieldUpdateAndFormatting({
      newValue: '',
    });
  }, [handleFieldUpdateAndFormatting]);

  const hasFieldDrillInfo = fieldDrillInfo && fieldDrillInfo.length > 0;

  const overrideLabel = useMemo(() => {
    if (fieldValue.isOverridden) {
      return CLEAR_OVERRIDE_LABEL;
    }
    if (!hasFieldDrillInfo) {
      return SET_OVERRIDE_LABEL;
    }
    if (
      [
        InfoAccountScreenType.GLOBAL,
        InfoAccountScreenType.GLOBAL_K1_OR_MEMBER,
        InfoAccountScreenType.COMMON,
      ].includes(fieldDrillInfo[0].infoAccountScreenType)
    ) {
      return `Override ${fieldDrillInfo[0].description} in all ${jurisdictionDescription} forms`;
    }
    return `Override ${fieldDrillInfo[0].description} in ${
      isConsolidated &&
      (fieldDrillInfo[0].infoAccountScreenType === InfoAccountScreenType.K1_OR_MEMBER ||
        (fieldDrillInfo[0].infoAccountPartnerOrMember &&
          fieldDrillInfo[0].infoAccountScreenType === InfoAccountScreenType.STATE_SPECIFIC))
        ? fieldDrillInfo[0].formName
        : formLabel
    }`;
  }, [
    fieldValue.isOverridden,
    hasFieldDrillInfo,
    fieldDrillInfo,
    isConsolidated,
    formLabel,
    jurisdictionDescription,
  ]);

  const displayClearValueOption = checkIfShouldDisplayClearValueButton({
    dataType,
    formattedData,
    isOverridden,
    isEditable,
  });

  const popupMenuOverrideBlock = useMemo(
    () =>
      fieldValue.isOverrideable || (fieldValue.isOverridden && fieldValue.isEditable) ? (
        <li onClick={handleClick}>
          <span className={styles.link}>{overrideLabel}</span>
        </li>
      ) : (
        <li disabled className={styles.itemDisabled}>
          <span>Editing {fieldValue.blockId} in form updates the workpaper</span>
        </li>
      ),
    [
      fieldValue.isOverrideable,
      fieldValue.isOverridden,
      fieldValue.isEditable,
      fieldValue.blockId,
      handleClick,
      overrideLabel,
    ],
  );

  return (
    <div className={styles.popupWrapper} style={getPopupStyles(clickPosition)}>
      <ul>
        {hasFieldDrillInfo ? (
          <>
            {fieldDrillInfo[0].infoAccountScreenType ? (
              <>
                <li className={styles.popupInfo}>
                  <span className={styles.popupInfoSource}>Source:</span>
                  <span className={styles.popupInfoHeader}>
                    {`${getInfoDataDrillRoute({
                      data: fieldDrillInfo[0],
                      isConsolidated,
                      member: memberByAggregatedSourceOrgID,
                    })?.displayMainName()}`}
                  </span>
                </li>
                <hr />
              </>
            ) : null}
            {fieldDrillInfo.map((data, index) => (
              <li key={index}>
                <FieldDrillLink
                  data={data}
                  params={params}
                  isConsolidated={isConsolidated}
                  formId={formId}
                  member={memberByAggregatedSourceOrgID}
                />
              </li>
            ))}
          </>
        ) : null}

        {/* need to guarantee the user can remove the override even if it not overridable any more */}
        {isEncryptedAndHasEncryptedValue ? (
          <li disabled className={styles.itemDisabled}>
            <span>{NOT_OVERRIDABLE_LABEL}</span>
          </li>
        ) : (
          popupMenuOverrideBlock
        )}

        {displayClearValueOption && (
          <li onClick={handleClearValueClick}>
            <span>Clear value</span>
          </li>
        )}

        {isFetchingFieldDrillInfo && (
          <span className={styles.loadingFieldDrillInfoWrapper}>
            <span>Loading drill info</span>
            <Loading className={styles.loadingFieldDrillInfoLoading} small isLoading />
          </span>
        )}
      </ul>
    </div>
  );
};

FieldPopupMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  clickPosition: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  fieldValue: OverridableFieldPropTypes.isRequired,
  handleFieldUpdateAndFormatting: PropTypes.func.isRequired,
};

export default FieldPopupMenu;
