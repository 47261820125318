import React, { useState, useCallback, useMemo } from 'react';
import { Button } from '@pwc/appkit-react';
import { useSelector } from 'react-redux';
import { SelectionChangedEvent } from 'ag-grid-community';

import { CheckboxGroupsData } from '../../../../common/types';
import useGridApi from '../../shared/hooks/useGridApi.hook';
import { useQueryFindCheckboxGroups } from '../../shared/queries/checkboxGroups';
import { useMutationDeleteCheckboxGroup } from '../../shared/mutations/checkboxGroups';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../../shared/hooks/useModal.hook';
import { taxYearSelector, jurisdictionIdSelector } from '../store/selectors';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';

import { CheckboxGroupToEdit } from './types';
import AddEditCheckboxGroup from './editCheckboxGroups/addEditCheckboxGroup.container';
import DeleteCheckboxGroupModal from './editCheckboxGroups/deleteModal/deleteCheckboxGroupModal.component';
import getColumnDefinitions from './checkboxGroups.columnDefinitions';

const CheckboxGroups = () => {
  const [checkboxGroupToEdit, setCheckboxGroupToEdit] = useState<CheckboxGroupToEdit | null>(null);

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const { gridApi, onGridReady } = useGridApi();

  const {
    data: checkboxGroupsData,
    isFetching: isFetchingCheckboxGroups,
  } = useQueryFindCheckboxGroups({
    enabled: Boolean(taxYear && jurisdictionId),
    params: { taxYear, jurisdictionId },
  });

  const { mutateAsync: deleteCheckboxGroup } = useMutationDeleteCheckboxGroup();

  const {
    showModal: showDeleteCheckboxGroupModal,
    modalProps: deleteCheckboxGroupModalProps,
  } = useModal();

  const clearForm = useCallback(() => {
    gridApi?.deselectAll();
    setCheckboxGroupToEdit(null);
  }, [gridApi]);

  const selectCheckboxGroupToEdit = useCallback(
    ({ api }: SelectionChangedEvent<CheckboxGroupsData>) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        setCheckboxGroupToEdit({ ...selectedRow, taxYear, jurisdictionId });
      }
    },
    [jurisdictionId, taxYear],
  );

  const handleDeleteIconClick = useCallback(
    (checkboxGroup: CheckboxGroupsData) => {
      showDeleteCheckboxGroupModal(checkboxGroup);
    },
    [showDeleteCheckboxGroupModal],
  );

  const handleDelete = useCallback(
    async (data: { id: string } | null) => {
      await deleteCheckboxGroup({ id: data?.id || '' });
      clearForm();
    },
    [deleteCheckboxGroup, clearForm],
  );

  const renderAdditionalHeaderElements = useCallback(
    () => (
      <Button size="lg" kind="secondary" onClick={clearForm}>
        Add Checkbox Group
      </Button>
    ),
    [clearForm],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: handleDeleteIconClick,
      }),
    [handleDeleteIconClick],
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <HeaderWithParamDropdownsWrapper
            renderAdditionalElements={renderAdditionalHeaderElements}
            showHeaderTaxYearDropdown
            showHeaderJurisdictionDropdown
            enableEverywhereJurisdiction
          />
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={checkboxGroupsData?.checkboxGroups || []}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingCheckboxGroups}
            onGridReady={onGridReady}
            onSelectionChanged={selectCheckboxGroupToEdit}
            withSearchBar
            areHeaderCellBordersEnabled
            autoMaxWidth
          />
        </div>
        <div className="col-4 right-column-edit-form">
          <AddEditCheckboxGroup
            checkboxGroupToEdit={checkboxGroupToEdit}
            checkboxGroupsNames={checkboxGroupsData?.checkboxGroupsNames}
            clearForm={clearForm}
          />
        </div>
      </div>
      {deleteCheckboxGroupModalProps.visible && (
        <DeleteCheckboxGroupModal {...deleteCheckboxGroupModalProps} onDelete={handleDelete} />
      )}
    </>
  );
};

export default CheckboxGroups;
