import { httpGetAndParse, httpPost, httpDelete } from '../../../utils/fetchService';

export const fetchFilingGroups = ({ taxYear, period, consolidationId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/consolidations/filing-groups/data/${consolidationId}/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Fetching filing groups in this consolidation failed',
  });

export const fetchFilingGroupsOptions = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/consolidations/filing-groups/filing-groups-options/${taxYear}/${period}`,
    errorMessage: 'Fetching filing groups options in this consolidation failed',
  });

export const addFilingGroupToConsolidation = ({
  taxYear,
  period,
  consolidationId,
  filingGroupId,
}) =>
  httpPost({
    route: `/api/consolidations/filing-groups/${consolidationId}/${taxYear}/${period}`,
    body: { filingGroupId },
    errorMessage: 'Adding filing group to consolidation failed',
  });

export const removeFilingGroupFromConsolidation = ({
  taxYear,
  period,
  consolidationId,
  filingGroupId,
  body,
}) =>
  httpDelete({
    route: `/api/consolidations/filing-groups/${consolidationId}/${filingGroupId}/${taxYear}/${period}`,
    errorMessage: 'Deleting filing group from consolidation failed',
    body,
  });
