import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import ReportNumberInput from '../../shared/reports/reportNumberInput.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import styles from '../../shared/reports/reportParam.module.scss';
import { entityIdSelector } from '../../shared/store/selectors';

import getColumnDefinitions from './priorYearComparisonReportDetail.columnDefinitions';

const SUPPRESS_COMPONENTS_OPTIONS = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

const PriorYearComparisonReportDetail = ({ contextEntityId }) => {
  const endpointBasePath = '/api/entities/prior-year-comparison-report-detail';

  const [suppressComponents, setSuppressComponents] = useState(
    SUPPRESS_COMPONENTS_OPTIONS[0].value,
  );

  const [pctVariance, setPctVariance] = useState('0');

  const taxYearParam = useReportParam({
    endpointBasePath,
    paramName: 'taxYear',
    params: useMemo(() => ({}), []),
  });

  const periodParam = useReportParam({
    endpointBasePath,
    paramName: 'period',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
      }),
      [taxYearParam.paramValue],
    ),
  });

  const legalEntityParam = useReportParam({
    endpointBasePath,
    paramName: 'entity',
    params: useMemo(
      () => ({
        orgId: contextEntityId,
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [contextEntityId, taxYearParam.paramValue, periodParam.paramValue],
    ),
    defaultParamValue: contextEntityId,
  });

  const accountGroupParam = useReportParam({
    endpointBasePath,
    paramName: 'accountGroup',
    params: useMemo(() => ({}), []),
  });

  const accountParam = useReportParam({
    endpointBasePath,
    paramName: 'account',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        accountGroup: accountGroupParam.paramValue,
      }),
      [taxYearParam.paramValue, accountGroupParam.paramValue],
    ),
  });

  const jurisdictionParam = useReportParam({
    endpointBasePath,
    paramName: 'jurisdiction',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        accountId: accountParam.paramValue,
        legalEntityId: legalEntityParam.paramValue,
        orgId: contextEntityId,
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        accountParam.paramValue,
        legalEntityParam.paramValue,
        contextEntityId,
      ],
    ),
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([
    taxYearParam,
    periodParam,
    legalEntityParam,
    accountGroupParam,
    accountParam,
    jurisdictionParam,
  ]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        accountId: accountParam.paramValue,
        jurisdictionId: jurisdictionParam.paramValue,
        legalEntityId: legalEntityParam.paramValue,
        orgId: contextEntityId,
        pctVariance:
          pctVariance.length && parseInt(pctVariance, 10) >= 0
            ? parseInt(pctVariance, 10)
            : // eslint-disable-next-line no-undefined
              undefined,
        suppressComponents,
      }),
      [
        taxYearParam.paramValue,
        accountParam.paramValue,
        jurisdictionParam.paramValue,
        legalEntityParam.paramValue,
        contextEntityId,
        pctVariance,
        suppressComponents,
      ],
    ),
  });

  const columnDefinitions = useMemo(
    () => getColumnDefinitions({ taxYear: taxYearParam.paramValue }),
    [taxYearParam.paramValue],
  );

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown label="Entity Id" param={legalEntityParam} />
          <ReportParamMultiSelect label="Account Group" param={accountGroupParam} />
          <ReportParamMultiSelect label="Account" param={accountParam} />
          <ReportParamMultiSelect label="Jurisdiction" param={jurisdictionParam} />
          <ReportParamDropdown
            label="Suppress Components"
            param={useMemo(
              () => ({
                isFetchingParamData: false,
                paramData: SUPPRESS_COMPONENTS_OPTIONS,
                paramValue: suppressComponents,
                setParamValue: setSuppressComponents,
              }),
              [suppressComponents],
            )}
          />
          <ReportNumberInput
            min="0"
            max="100"
            label="Pct Variance"
            value={pctVariance}
            setValue={setPctVariance}
          />
          {runReportButton}
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
            />
          )}
        </div>
      </div>
    </>
  );
};

PriorYearComparisonReportDetail.propTypes = {
  contextEntityId: PropTypes.string.isRequired,
};

export default connect(state => ({ contextEntityId: entityIdSelector(state) }))(
  PriorYearComparisonReportDetail,
);
