import { apiAction } from '../../shared/store/apiAction';
import * as api from '../store/api';

import {
  fetchIncomeTaxSummaryTypes,
  fetchFranchiseTaxSummaryTypes,
  fetchEstimatedTaxSummaryTypes,
} from './types';

export const fetchIncomeTaxSummary = apiAction({
  types: fetchIncomeTaxSummaryTypes,
  apiAction: api.fetchIncomeTaxSummary,
});

export const fetchFranchiseTaxSummary = apiAction({
  types: fetchFranchiseTaxSummaryTypes,
  apiAction: api.fetchFranchiseTaxSummary,
});

export const fetchEstimatedTaxSummary = apiAction({
  types: fetchEstimatedTaxSummaryTypes,
  apiAction: api.fetchEstimatedTaxSummary,
});
