import { ColDef } from 'ag-grid-community';

import { defaultColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions: ColDef[] = [
  {
    ...defaultColumnDefinition,
    headerName: 'Org Id',
    field: 'orgId',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Org Name',
    field: 'orgName',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Id',
    field: 'accountId',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 300,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Amount',
    field: 'amt',
    width: 100,
  },
];

export default columnDefinitions;
