export const defaultRunLevel = 100;

export const operationTypeOptions = [
  { value: 'ADD', label: 'ADD' },
  { value: 'PROD', label: 'PROD' },
  { value: 'DIV', label: 'DIV' },
  { value: 'MIN', label: 'MIN' },
  { value: 'MAX', label: 'MAX' },
  { value: 'K1SUM', label: 'K1SUM' },
  { value: 'COUNT', label: 'COUNT' },
];

export const functionTypeOptions = [
  { value: 'SEPARATE', label: 'SEPARATE' },
  { value: 'CONSOL', label: 'CONSOL' },
];

export const parameterTypeNames = {
  DATA: 'DATA',
  DESTINATION: 'DESTINATION',
  CASE: 'CASE',
};

export const parameterTypes = [
  { value: parameterTypeNames.DATA, label: parameterTypeNames.DATA },
  { value: parameterTypeNames.DESTINATION, label: parameterTypeNames.DESTINATION },
  { value: parameterTypeNames.CASE, label: parameterTypeNames.CASE },
];
