// This formatter is used to format a given string value as a number
// and optionally add thousands separators (commas). Negative values
// are not allowed.
//
// The regular expressions below work in four steps:
// 1. Remove all characters besides numbers and periods.
// 2. Remove all periods that appear before the last.
// 3. If the thousandsSeparators flag is true:
//   3a. Match the integer portion of the number (i.e. perform the following
//    on the non-decimal portion of the number)...
//   3b. ...and add commas where necessary as thousands-place separators.
// 4. Remove leading zeros while still allowing for the value '0'
const formInputThousandsSeparatorFormatter = (
  number: string | null | undefined,
  hasThousandsSeparators: boolean,
) => {
  if (!number) {
    return '';
  }
  let formattedNumber = number.replace(/[^0-9.]/g, '').replace(/[.](?=.*[.])/g, '');
  if (hasThousandsSeparators) {
    formattedNumber = formattedNumber.replace(/^[+-]?\d+/, integer =>
      integer.replace(/(\d)(?=(\d{3})+$)/g, '$1,'),
    );
  }
  return formattedNumber.replace(/^0+(\d)/, '$1');
};

export default formInputThousandsSeparatorFormatter;
