import { PdfPageDataUsageTypes } from '../constants';

const getAvailableDatasetsForPage = (pdfPageDataUsage, page) => {
  if (page && pdfPageDataUsage) {
    return pdfPageDataUsage
      .filter(row => row.type === PdfPageDataUsageTypes.DATASET_USAGE && row.pageNumber === page)
      .map(({ name, datasetId }) => ({ label: name, value: datasetId }));
  }
};

export default getAvailableDatasetsForPage;
