import React from 'react';
import { connect } from 'react-redux';

import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';
import { isFetchingContextSelector } from '../../development/store/selectors';
import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';

import AdminBreadcrumbRoutes from './adminBreadcrumbRoutes.component';

const AdminPage = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
  breadcrumbRoutes: AdminBreadcrumbRoutes,
}))(GlobalContextPage);

const AdminRoute = props => (
  <AuthorizedCustomRoute wrapper={AdminPage} hideGlobalContext {...props} />
);

export default AdminRoute;
