export const isFetchingRootEntitiesCountByStateCodeSelector = ({ homePage }) =>
  homePage.isFetchingRootEntitiesCountByStateCode;

export const rootEntitiesCountByStateCodeSelector = ({ homePage }) =>
  homePage.rootEntitiesCountByStateCode;

export const isFetchingIncomeTaxLiabilitySelector = ({ homePage }) =>
  homePage.isFetchingIncomeTaxLiability;

export const incomeTaxLiabilitySelector = ({ homePage }) => homePage.incomeTaxLiability;
