const {
  Routes,
  routesArray,

  homeRoute,

  complianceWorkspaceRoutes,
  entityRoutes,
  consolidationRoutes,
  stateFilingGroupRoutes,
  federalProFormaFilingGroupRoutes,
  filingGroupRoutes,

  reportRoutes,

  adminRoutes,

  developmentRoutes,

  toolsRoutes,

  efileRoutes,

  proformaRoutes,

  taxSummariesRoutes,

  returnWorkspaceRoutes,

  otherRoutes,
} = require('./routes');
const {
  WORKSPACES_NAMES,
  WORKSPACE,
  taxNames,
  isValidTaxName,
  taxTypes,
  taxSummariesMainNames,
  workspaceBaseRoutes,
  baseRoutes,
} = require('./constants');

module.exports = {
  Routes,
  routesArray,

  WORKSPACES_NAMES,
  WORKSPACE,
  taxNames,
  isValidTaxName,
  taxTypes,
  taxSummariesMainNames,
  workspaceBaseRoutes,
  baseRoutes,

  homeRoute,

  complianceWorkspaceRoutes,
  entityRoutes,
  consolidationRoutes,
  stateFilingGroupRoutes,
  federalProFormaFilingGroupRoutes,
  filingGroupRoutes,

  reportRoutes,

  adminRoutes,

  developmentRoutes,

  toolsRoutes,

  efileRoutes,

  proformaRoutes,

  taxSummariesRoutes,

  returnWorkspaceRoutes,

  otherRoutes,
};
