import React from 'react';
import PropTypes from 'prop-types';

import { TextCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import { dataTypes } from '../../shared/constants';
import {
  defaultColumnDefinition,
  selectCellEditorParamsFactory,
} from '../../shared/columnDefinitions';
import {
  defaultAgRichSelectCellEditorSelector,
  defaultDateCellEditor,
} from '../../shared/columnDefinitions/cellEditor';

const SELECT_OPTIONS = [
  { value: 'X', label: 'Checked' },
  { value: ' ', label: 'Unchecked' },
  { value: null, label: 'Use Global Value' },
];

const TextRenderer = TextCellRendererFactory({
  getText: ({ value }) => (value ? value : ''),
});

const StateOverrideRendererFramework = props => {
  if (props.data.dataType === dataTypes.CHECKBOX) {
    const selectValue = SELECT_OPTIONS.find(({ value }) => value === props.data.value);
    return <TextRenderer {...props} value={selectValue ? selectValue.label : ''} />;
  }
  return <TextRenderer {...props} />;
};

StateOverrideRendererFramework.propTypes = {
  data: PropTypes.shape({
    dataType: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

const EditorSelector = props => {
  switch (props.data.dataType) {
    case dataTypes.DATE:
      return {
        ...defaultDateCellEditor,
        params: {
          value: props.data.value,
        },
      };
    case dataTypes.NUMBER:
      return {
        component: 'NumberCellEditor',
        params: {
          nullOnEmptyString: true,
          value: props.data.value,
        },
      };
    case dataTypes.CHECKBOX:
      return {
        ...defaultAgRichSelectCellEditorSelector,
        params: selectCellEditorParamsFactory(SELECT_OPTIONS)(),
      };
    default:
      return;
  }
};

EditorSelector.propTypes = {
  data: PropTypes.shape({
    dataType: PropTypes.string.isRequired,
  }).isRequired,
};
const getColumnDefinitions = ({ isInEditMode, updateRow }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Partner id',
      field: 'partnerId',
      width: 50,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Page No',
      field: 'pageNo',
      width: 50,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'infoAcctId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'descr',
      width: 500,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Global Value',
      field: 'ev',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'State Override',
      field: 'value',
      width: 200,
      editable: isInEditMode,
      cellRenderer: StateOverrideRendererFramework,
      cellEditorSelector: EditorSelector,
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Display Value',
      field: 'dispValue',
      width: 200,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
