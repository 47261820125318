import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { showConfirmModal } from '../shared/confirmModal/store/actions';
import useGridApi from '../shared/hooks/useGridApi.hook';
import { useQueryFilingGroups } from '../shared/queries/filingGroups';
import { useMutationDeleteFilingGroup } from '../shared/mutations/filingGroups';
import { filingTypes } from '../shared/constants';
import {
  globalContextSelector,
  gtwClientKeySelector,
  customerPermissionsSelector,
} from '../shared/store/selectors';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../shared/hooks/useModal.hook';
import {
  ActionMenu,
  ActionMenuItem,
} from '../shared/displayComponents/actionMenu/actionMenu.component';
import config from '../config';
import useOpenInNewTab from '../shared/hooks/useOpenInNewTab';
import downloadFile from '../utils/downloadFile';

import getColumnDefinitions from './filingGroups.columnDefinitions';
import AddEditFilingGroupModal from './addEditFilingGroupModal.container';
import { setFilingGroupId } from './store';
import hasPermissionToDeleteFilingGroup from './hasPermissionToDeleteFilingGroup';

const FilingGroups = ({ hasUserPermissionsToEdit }) => {
  const customerPermissions = useSelector(customerPermissionsSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const { gridApi, onGridReady } = useGridApi();

  const { showModal, modalProps } = useModal();
  const [isRowSelected, setIsRowSelected] = useState(false);
  const history = useHistory();

  const [filingGroupToEdit, setFilingGroupToEdit] = useState(null);
  const [
    shouldFilterFilingGroupsByBusinessEntityId,
    setShouldFilterFilingGroupsByBusinessEntityId,
  ] = useState(false);

  const toggleShouldFilterFilingGroupsByBusinessEntityId = useCallback(() => {
    setShouldFilterFilingGroupsByBusinessEntityId(!shouldFilterFilingGroupsByBusinessEntityId);
  }, [shouldFilterFilingGroupsByBusinessEntityId]);

  const globalContext = useSelector(globalContextSelector);
  const { taxYear, period, businessEntityId, filingTypeId, jurisdictionId } = globalContext.params;

  const gtwClientKey = useSelector(gtwClientKeySelector);
  const hasPermissionToDelete = hasPermissionToDeleteFilingGroup(customerPermissions);

  const downloadReportXlsxUrl = `/api/filing-groups/download-report/${taxYear}/${period}`;
  const sltToGtwFilingGroupComparisonReportUrl = `/reports/trg/922`;

  const filingGroups = useQueryFilingGroups({
    params: {
      taxYear,
      period,
      ...(shouldFilterFilingGroupsByBusinessEntityId
        ? {
            filingTypeId,
            businessEntityId,
            jurisdictionId,
          }
        : {}),
    },
    enabled: Boolean(taxYear && period),
  });

  const deleteFilingGroupMutation = useMutationDeleteFilingGroup();

  const openModalForEdit = useCallback(
    row => {
      setFilingGroupToEdit(row);
      showModal();
    },
    [showModal],
  );
  const openModalForAdd = useCallback(() => {
    setFilingGroupToEdit(null);
    showModal();
  }, [showModal]);

  const openModelForCopy = useCallback(() => {
    const selectedRow = gridApi.getSelectedRows()[0];
    const copyOfFilingGroup = {
      description: selectedRow.description,
      filingGroupId: selectedRow.filingGroupId,
      federalReturnType: selectedRow.federalReturnType,
      filingGroupIdOfCopiedFilingGroup: selectedRow.filingGroupId,
      isCopy: true,
    };
    setFilingGroupToEdit(copyOfFilingGroup);
    showModal();
  }, [showModal, gridApi]);

  const openModalForDelete = useCallback(
    ({ filingGroupId, memberCount }) => {
      dispatch(
        showConfirmModal({
          title: 'Delete Filing Group',
          text: `${filingGroupId} has ${memberCount} ${
            memberCount > 1 ? `members` : `member`
          } in the group.\u00A0\u00A0Are you sure you want to delete this filing group?`,
          confirmCallback: async () => {
            await deleteFilingGroupMutation.mutateAsync({ taxYear, period, filingGroupId });
          },
        }),
      );
    },
    [dispatch, taxYear, period, globalContext, deleteFilingGroupMutation],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onEditIconClick: openModalForEdit,
        onDeleteIconClick: openModalForDelete,
        shouldShowEditIcon: hasUserPermissionsToEdit,
        shouldShowDeleteIcon: hasPermissionToDelete,
      }),
    [
      globalContext,
      hasUserPermissionsToEdit,
      hasPermissionToDelete,
      openModalForEdit,
      openModalForDelete,
    ],
  );

  const selectFilingGroupToEditMembers = useCallback(
    ({ api }) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        dispatch(setFilingGroupId({ filingGroupId: selectedRow.filingGroupId }));
      }
      setIsRowSelected(Boolean(selectedRow));
    },
    [dispatch],
  );

  const redirectToEditMembers = useCallback(
    () =>
      history.push({
        pathname: Routes.filingGroupMembers.MAIN,
        state: { fromDashboard: true },
      }),
    [history],
  );

  const openInNewTab = useOpenInNewTab();

  const handleGTWFilingGroupsClick = useCallback(() => {
    /* eslint-disable-next-line camelcase */
    openInNewTab(config.GTW_FILING_GROUPS_LINK, { client_key: gtwClientKey });
  }, [openInNewTab, gtwClientKey]);

  const onRowDataUpdated = useCallback(({ api }) => {
    /*  When saving after editing a filing group or copying a filing group isRowSelected remains true
    even though the grid unselects rows. Double check when the data is updated there is a or a selection */
    const selectedRow = api.getSelectedRows()[0];
    setIsRowSelected(Boolean(selectedRow));
  }, []);

  useEffect(() => {
    if (gridApi && location.state?.filingGroupId && !filingGroups.isLoading) {
      const allFilingGroups = gridApi.getModel().rowsToDisplay;
      const filingGroupNode = allFilingGroups.find(
        ({ data }) => data?.filingGroupId === location.state?.filingGroupId,
      );
      if (filingGroupNode) {
        filingGroupNode.setSelected(true);
      }
    }
  }, [gridApi, location.state?.filingGroupId, filingGroups.isLoading]);

  const downloadGTWImportReport = useCallback(
    () => downloadFile({ route: downloadReportXlsxUrl }),
    [downloadReportXlsxUrl],
  );

  return (
    <>
      {hasUserPermissionsToEdit && (
        <div className="row">
          <div className="col">
            <Checkbox
              checked={shouldFilterFilingGroupsByBusinessEntityId}
              onChange={toggleShouldFilterFilingGroupsByBusinessEntityId}
            >
              {`Show Only ${
                filingTypeId === filingTypes.CONSOLIDATED ? 'Consolidation' : 'Entity'
              }'s Filing Groups`}
            </Checkbox>
          </div>
          <div className="col add-button-column">
            <Button
              size="lg"
              className="add-button"
              onClick={openModelForCopy}
              disabled={filingGroups.isLoading || !isRowSelected}
            >
              Copy Filing Group
            </Button>
            <Button
              size="lg"
              className="add-button"
              onClick={redirectToEditMembers}
              disabled={filingGroups.isLoading || !isRowSelected}
            >
              Edit Members
            </Button>
            <Button
              size="lg"
              className="add-button"
              onClick={openModalForAdd}
              disabled={filingGroups.isLoading}
            >
              Add Filing Group
            </Button>
            <ActionMenu>
              <ActionMenuItem
                text="SLT to GTW Filing Group Comparison"
                link={sltToGtwFilingGroupComparisonReportUrl}
              />
              <ActionMenuItem text="Generate GTW Import" onClick={downloadGTWImportReport} />
              <ActionMenuItem onClick={handleGTWFilingGroupsClick} text="GTW Filing Groups" />
            </ActionMenu>
          </div>
        </div>
      )}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            columnDefs={columnDefinitions}
            rowData={filingGroups.data || []}
            isGridLoading={filingGroups.isLoading}
            withSearchBar
            onSelectionChanged={selectFilingGroupToEditMembers}
            onGridReady={onGridReady}
            autoScrollPosition="top"
            onRowDataUpdated={onRowDataUpdated}
          />
        </div>
      </div>
      <AddEditFilingGroupModal {...modalProps} filingGroupToEdit={filingGroupToEdit} />
    </>
  );
};
FilingGroups.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default FilingGroups;
