import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from '@pwc/appkit-react/lib/Checkbox';
import { Input } from '@pwc/appkit-react/lib/Input';
import isNil from 'lodash.isnil';
import { DOMAIN_TYPE } from '@common-packages/shared-constants';

import Loading from '../../shared/displayComponents/loading.component';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import { globalContextSelector } from '../../shared/store/selectors';
import { runTTIStep } from '../store/actions';
import { hasPermissionToEfileRunSelector } from '../../shared/store/customerData.selectors';
import {
  eFileStepsDataSelector,
  isFetchingEFileStepsDataSelector,
  isFetchingFederalProformaSubmissionIdSelector,
} from '../store/selectors';
import styles from '../styles.module.scss';

import { EFILE_TRANSMIT_STEPS } from './constants';
import SubStep from './subStep.component';
import { stepDataPropTypes, contextDebugInfoPropTypes } from './currentReturnFiling.propTypes';
import useTransmitStepAndTTISubmissionId from './useTransmitStepAndTTISubmissionId';
import { wizardStatePropTypes } from './useWizard.hook';

const stateSubmissionTypes = {
  UNLINKED: 'UnLinked',
  LINKED: 'Linked',
};

const stateSubmissionOptions = [
  { label: stateSubmissionTypes.UNLINKED, value: stateSubmissionTypes.UNLINKED },
  { label: stateSubmissionTypes.LINKED, value: stateSubmissionTypes.LINKED },
];

const TransmitStep = ({
  binderFilingId,
  globalContext,

  ttiYearId,
  ttiCaseId,
  selectedTtiBinderId,
  efServiceSubmissionId,

  eFileStepsData,
  fetchStepsData,
  isFetchingEFileStepsData,
  runTTIStep,

  contextDebugInfo,
  hasPermissionToRun,

  isContextLocked,

  isFetchingTtiBinders,
  wizardState,
  setStepStatus,

  stateSubmissionType,
  includeFederal,
  federalProformaSubmissionId,
  isFetchingProformaSubmissionId,
}) => {
  const { currentStep, isWizardActionRunning } = wizardState;
  const { ttiSubmissionId, transmitStepsData } = useTransmitStepAndTTISubmissionId({
    ttiYearId,
    ttiCaseId,
    selectedTtiBinderId,
    efServiceSubmissionId,
    eFileStepsData,
    stateSubmissionType,
  });

  const setIsActionRunning = useCallback(
    value => {
      setStepStatus({
        currentStep,
        payload: {
          isStepActionRunning: value,
        },
      });
    },
    [setStepStatus, currentStep],
  );

  const callTransmitAction = useCallback(
    async ({ action }) => {
      setIsActionRunning(true);
      await runTTIStep({
        action,
        globalContext,
        binderFilingId,
        ttiYearId,
        ttiCaseId,
        binderId: selectedTtiBinderId,
        submissionId: efServiceSubmissionId,
        domainType: DOMAIN_TYPE,
        stateSubmissionType,
      });
      setIsActionRunning(false);
      fetchStepsData();
    },
    [
      runTTIStep,
      fetchStepsData,
      setIsActionRunning,
      globalContext,
      binderFilingId,
      ttiYearId,
      ttiCaseId,
      selectedTtiBinderId,
      efServiceSubmissionId,
      stateSubmissionType,
    ],
  );

  const disabled =
    isWizardActionRunning || isFetchingEFileStepsData || !ttiCaseId || isNil(selectedTtiBinderId);

  return (
    <>
      <div className={styles.returnContext}>
        <div className={styles.ttiSubmissionIdField}>
          <label className="a-form-label">State Submission ID</label>
          <input className="a-input-text" value={ttiSubmissionId || ''} disabled />
        </div>
        <ParamDropdown
          disabled
          label="State Submission Type"
          options={stateSubmissionOptions}
          value={stateSubmissionType}
          isFetchingContext={isWizardActionRunning}
        />
        <div className={styles.align}>
          <Checkbox checked={includeFederal} disabled>
            <span className="a-form-label">Include Federal</span>
          </Checkbox>
          {includeFederal && (
            <div className={styles.federalProformaSubmissionId}>
              <label className="a-form-label">Federal Submission ID</label>
              <Loading small isLoading={isFetchingProformaSubmissionId}>
                <Input disabled value={federalProformaSubmissionId} />
              </Loading>
            </div>
          )}
        </div>
      </div>

      <SubStep
        contextDebugInfo={contextDebugInfo}
        steps={EFILE_TRANSMIT_STEPS}
        isLoading={isFetchingEFileStepsData || isFetchingTtiBinders}
        isSubmittingAnyAction={wizardState[currentStep].isStepActionRunning}
        isFetchingEFileStepsData={isFetchingEFileStepsData}
        disableNextStep={disabled}
        hasPermissionToRun={hasPermissionToRun}
        actionsBatch="transmit"
        stepData={transmitStepsData}
        callAction={callTransmitAction}
        actionButtonText="Transmit"
        isLocked={isContextLocked}
      />
    </>
  );
};

TransmitStep.propTypes = {
  binderFilingId: PropTypes.number,
  globalContext: globalContextPropTypes,

  ttiYearId: PropTypes.string,
  ttiCaseId: PropTypes.string,
  selectedTtiBinderId: PropTypes.number,
  efServiceSubmissionId: PropTypes.string,

  eFileStepsData: PropTypes.arrayOf(stepDataPropTypes).isRequired,
  fetchStepsData: PropTypes.func.isRequired,
  isFetchingEFileStepsData: PropTypes.bool.isRequired,
  runTTIStep: PropTypes.func.isRequired,
  hasPermissionToRun: PropTypes.bool.isRequired,

  contextDebugInfo: contextDebugInfoPropTypes,

  isContextLocked: PropTypes.bool.isRequired,

  isFetchingTtiBinders: PropTypes.bool.isRequired,
  wizardState: wizardStatePropTypes,
  setStepStatus: PropTypes.func.isRequired,

  stateSubmissionType: PropTypes.string.isRequired,
  includeFederal: PropTypes.bool.isRequired,
  federalProformaSubmissionId: PropTypes.string.isRequired,
  isFetchingProformaSubmissionId: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    globalContext: globalContextSelector(state),

    eFileStepsData: eFileStepsDataSelector(state),
    isFetchingEFileStepsData: isFetchingEFileStepsDataSelector(state),

    hasPermissionToRun: hasPermissionToEfileRunSelector(state),

    isFetchingProformaSubmissionId: isFetchingFederalProformaSubmissionIdSelector(state),
  }),
  {
    runTTIStep,
  },
)(TransmitStep);
