import stringify from '../../utils/stringify';

import { QueryKeys, useCustomQuery } from '.';

interface DueDateOptionsParams {
  taxYear: string;
  period: string;
  businessEntityId: string;
  jurisdictionId: string;
  returnDefinitionId: string;
  formattedFiscalYearEnd?: string;
}

export type DueDateOptionsResponse = {
  label: string;
  value: number;
  description: string;
}[];

export const useQueryDueDateOptions = ({
  params,
  enabled,
}: {
  params: DueDateOptionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<DueDateOptionsResponse>([QueryKeys.DueDates.DueDateOptions, params], {
    url: `/api/shared/due-date/due-date-options/${params.jurisdictionId}/${
      params.returnDefinitionId
    }?${stringify({
      taxYear: params.taxYear,
      period: params.period,
      businessEntityId: params.businessEntityId,
      formattedFiscalYearEnd: encodeURIComponent(params.formattedFiscalYearEnd || ''),
    })}`,
    enabled,
    errorMessage: 'Fetching due date options failed',
  });
