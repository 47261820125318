import { PopAlert } from '@pwc/appkit-react/lib/Alert';

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, CLEAR_NOTIFICATION } from './types';

const defaultDuration = 3500;

const notification = type => (
  message,
  { closeable = false, duration = defaultDuration } = {
    closeable: false,
    duration: defaultDuration,
  },
) => dispatch => {
  PopAlert({
    oneLineDisplay: true,
    duration,
    status: type,
    message,
    closeable,
    className: 'appkit-animation animated bounceIn appkit appkit-alert-custom-wrapper',
    onClose: () =>
      dispatch({
        type: REMOVE_NOTIFICATION,
      }),
  });

  dispatch({
    type: CLEAR_NOTIFICATION,
  });

  dispatch({
    type: ADD_NOTIFICATION,
    payload: {
      type,
      message,
      closeable,
      duration,
    },
  });
};

export const infoNotification = notification('info');

export const successNotification = notification('success');

export const errorNotification = notification('error');
