import { httpGetAndParse } from '../../../utils/fetchService';

export const fetchFilingGroups = ({ taxYear, period, parentEntityId }) =>
  httpGetAndParse({
    route: `/api/admin/maintenance/consolidations/filing-groups/${taxYear}/${period}/${parentEntityId}`,
    errorMessage: 'Fetching filing groups failed',
  });

export const fetchDefaultConsolidationMethod = ({ taxYear, period, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/admin/maintenance/consolidations/default-consolidation-method/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Fetching default consolidation method failed',
  });
