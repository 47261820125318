import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { IconCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Ind.',
    field: 'isValid',
    width: 40,
    cellRenderer: IconCellRendererFactory({
      getName: isValid => (isValid ? 'check-mark' : 'close'),
    }),
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Dataset Name',
    field: 'datasetDefinitionName',
    width: 160,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction Id',
    field: 'jurisdictionId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Org Id',
    field: 'orgId',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Cons Id',
    field: 'consolId',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Mult Id',
    field: 'multId',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Parent Mult Id',
    field: 'parentMultId',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Data Item Name',
    field: 'dataItemName',
    width: 160,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Value',
    field: 'value',
    width: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Result',
    field: 'result',
    width: 400,
  },
];

export default columnDefinitions;
