import { Routes } from '@common-packages/routes-definitions';

import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithSuppressedFilter,
} from '../../shared/columnDefinitions';
import {
  LinkCellRendererFactory,
  EditableCheckboxCellRenderer,
} from '../../shared/columnDefinitions/cellRenderers';
import { dateFormatter } from '../../shared/formatters';
import config from '../../config';
import downloadFile from '../../utils/downloadFile';

import styles from './federalProformaProcesses.module.scss';

const fileSizeComparator = (fileSize1, fileSize2) => {
  const size1 = parseInt(fileSize1, 10);
  const size2 = parseInt(fileSize2, 10);

  if (size1 === size2) {
    return 0;
  }

  if (!size2 || size1 > size2) {
    return 1;
  }

  return -1;
};

const getColumnDefinitions = () => {
  const LinkRenderer = LinkCellRendererFactory({
    getText: ({ value }) => (value ? 'Download' : ''),
    clickHandler: (_, data) =>
      downloadFile({
        route: `${Routes.federalAndProformaProcessesDownload.compiledRoute({
          fileId: data.fileId,
        })}`,
      }),
  });

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: '',
      width: 40,
      maxWidth: 40,
      checkboxSelection: true,
      cellClass: 'custom-ag-grid-checkbox',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Entity ID',
      field: 'entityId',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Consol ID',
      field: 'consolId',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Source ID',
      field: 'sourceId',
      cellStyle: {
        textAlign: 'center',
      },
      suppressMenu: false,
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Document Type',
      field: 'documentType',
      cellStyle: {
        textAlign: 'center',
      },
      suppressMenu: false,
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Federal As-Filed Filing Group',
      field: 'federalFilingGroup',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Federal Proforma Filing Group',
      field: 'proformaFilingGroup',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Document',
      field: 'fileUpload',
      cellRenderer: LinkRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Federal As-Filed Submission ID',
      field: 'asfiledSubmissionId',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Federal Proforma Submission ID ',
      field: 'proformaSubmissionId',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'File Size',
      field: 'fileSize',
      suppressMenu: false,
      valueGetter: ({ data }) => `${data.fileSize ? (data.fileSize / 1000).toFixed(0) : 0}k`,
      comparator: fileSizeComparator,
      cellClassRules: {
        [styles.redFlag]: ({ data }) => !data.fileSize,
      },
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Created',
      field: 'createdOn',
      suppressMenu: false,
      valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'Updated',
      field: 'updatedOn',
      suppressMenu: false,
      valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'IRS Submission ID',
      field: 'IRSSubmissionId',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'IRS Submission ID Updated',
      field: 'IRSSubmissionIdUpdatedOn',
      suppressMenu: false,
      valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_FORMAT),
      cellClassRules: {
        [styles.redFlag]: ({ data }) => data.redFlag,
      },
    },
    {
      ...defaultColumnDefinitionWithSuppressedFilter,
      headerName: 'IRS Accepted',
      field: 'IRSAccepted',
      suppressMenu: false,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: true,
      },
      filterParams: {
        valueFormatter: params => (params.value === '1' ? 'Yes' : 'No'),
      },
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
