import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchValidatedInformationalDataTypes = getApiActionTypes(
  'DATA_COLLECTION/FETCH_VALIDATED_INFORMATIONAL_DATA',
);
export const uploadInformationalDataTypes = getApiActionTypes(
  'DATA_COLLECTION/UPLOAD_INFORMATIONAL_DATA',
);
export const RESET_VALIDATED_INFORMATIONAL_DATA =
  'DATA_COLLECTION/RESET_VALIDATED_INFORMATIONAL_DATA';
