import { combineReducers } from 'redux';

import accountsReducer from '../accounts/store/reducer';
import infoAccountsReducer from '../infoAccounts/store/reducer';
import sharedReducer from '../shared/store/reducer';
import runCustomReportsReducer from '../customReports/runCustomReports/store/reducer';
import runDataVisualizationsReducer from '../dataVisualization/runDataVisualization/store/reducer';
import efileReducer from '../efile/store/reducer';
import taxRatesAndConstantsMaintenanceReducer from '../taxRatesAndConstantsMaintenance/store/reducer';
import usersMaintenanceReducer from '../usersMaintenance/store/reducer';
import lockReturnReducer from '../lockReturn/store/reducer';
import businessGroupsReducer from '../businessGroups/store/reducer';
import businessesReducer from '../businesses/store/reducer';
import jobQueueStatusReportsReducer from '../jobQueueStatusReport/store/reducer';

export default combineReducers({
  shared: sharedReducer,
  runCustomReports: runCustomReportsReducer,
  runDataVisualizations: runDataVisualizationsReducer,
  accounts: accountsReducer,
  infoAccounts: infoAccountsReducer,
  efile: efileReducer,
  taxRatesAndConstantsMaintenance: taxRatesAndConstantsMaintenanceReducer,
  usersMaintenance: usersMaintenanceReducer,
  lockReturn: lockReturnReducer,
  businessGroups: businessGroupsReducer,
  businesses: businessesReducer,
  jobQueueStatusReports: jobQueueStatusReportsReducer,
});
