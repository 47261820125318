import { TAX_YEAR_ENDING_INFO_ACCOUNT_ID } from '../../../shared/constants';
import { EntityInformationResultRow } from '../../../../../common/types/apiShapes';
import { GlobalConsolidationData } from '../../../../../common/types';

const hasTaxYearEnding = (dataRows: EntityInformationResultRow[] | GlobalConsolidationData[]) =>
  !(
    dataRows.length > 0 &&
    dataRows.some(item => item?.infoAccountId === TAX_YEAR_ENDING_INFO_ACCOUNT_ID && item?.value)
  );

export default hasTaxYearEnding;
