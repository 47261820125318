import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { REPORT_TYPES } from '../../shared/constants';

import { fetchCustomReports } from './store/actions';
import { customReportsSelector, isFetchingCustomReportsSelector } from './store/selectors';
import columnDefinitions from './reports.columnDefinitions';
import './runCustomReports.style.scss';

const RunCustomReports = ({ customReports, isFetchingCustomReports, fetchCustomReports }) => {
  useEffect(() => {
    fetchCustomReports({
      reportType: REPORT_TYPES.REPORT,
    });
  }, [fetchCustomReports]);

  return (
    <>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={customReports}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingCustomReports}
          />
        </div>
      </div>
    </>
  );
};

RunCustomReports.propTypes = {
  customReports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  isFetchingCustomReports: PropTypes.bool.isRequired,
  fetchCustomReports: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    customReports: customReportsSelector(state),
    isFetchingCustomReports: isFetchingCustomReportsSelector(state),
  }),
  {
    fetchCustomReports,
  },
)(RunCustomReports);
