import React from 'react';
import PropTypes from 'prop-types';

import styles from './tooltipContent.module.scss';

const TooltipContent = ({ lockedJurisdictions }) => (
  <>
    <p className={styles.info}>Locked for: </p>
    <ul>
      {lockedJurisdictions.map(({ description }, index) => (
        <li key={index}>{description}</li>
      ))}
    </ul>
  </>
);

TooltipContent.propTypes = {
  lockedJurisdictions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
  ),
};

export default TooltipContent;
