import React from 'react';
import PropTypes from 'prop-types';

import { ActionMenu, ActionMenuItem } from '../actionMenu/actionMenu.component';

const ActionMenuForExport = ({
  isExportDisabled,
  exportCSV,
  exportExcelInXLSX,
  exportExcelInXML,
}) => (
  <div className="global-context global-context-nav">
    <ActionMenu>
      <ActionMenuItem text="CSV Export" disabled={isExportDisabled} onClick={exportCSV} />
      <ActionMenuItem
        text="Excel Export (.xlsx)"
        disabled={isExportDisabled}
        onClick={exportExcelInXLSX}
      />
      <ActionMenuItem
        text="Excel Export (.xml)"
        disabled={isExportDisabled}
        onClick={exportExcelInXML}
      />
    </ActionMenu>
  </div>
);

ActionMenuForExport.propTypes = {
  isExportDisabled: PropTypes.bool.isRequired,
  exportCSV: PropTypes.func.isRequired,
  exportExcelInXLSX: PropTypes.func.isRequired,
  exportExcelInXML: PropTypes.func.isRequired,
};

export default ActionMenuForExport;
