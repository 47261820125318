import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';

const AddEditNoteForm = ({ closeSlideIn, submitForm, isSubmitting }) => (
  <form>
    <Field
      label="Note"
      name="noteMessage"
      className="slide-in-field"
      component={Input}
      autoComplete="off"
    />
    <ButtonsGroup
      isSubmitting={isSubmitting}
      onCancelClick={closeSlideIn}
      submitButtonLabel="Add"
      submitForm={submitForm}
      fixed
    />
  </form>
);

AddEditNoteForm.propTypes = {
  closeSlideIn: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default AddEditNoteForm;
