import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import promiseWaterfall from 'promise.waterfall';
import { DOMAIN_TYPE, filingStatus, StepStatuses } from '@common-packages/shared-constants';
import { NEW_JERSEY_JURISDICTION_ID } from '@common-packages/tti';
import { RequestError } from '@tls/ui-request';

import useFetch from '../../../shared/hooks/useFetch.hook';
import globalContextPropTypes from '../../../shared/propTypes/globalContext';
import {
  globalContextSelector,
  jurisdictionDescriptionSelector,
} from '../../../shared/store/selectors';
import { successNotification } from '../../../shared/notification/store/actions';
import { runInitiateTTIStep } from '../../store/actions';
import {
  eFileStepsDataSelector,
  isFetchingEFileStepsDataSelector,
  eFileOverallStatusSelector,
} from '../../store/selectors';
import { runEFStep, createTtiBinder as createTtiBinderApiAction } from '../../store/api';
import {
  EFILE_ACTION,
  EFILE_GENERATE_XML_STEPS,
  EFILE_INITIATE_SKIPPABLE_STEPS,
} from '../constants';
import {
  getEFServiceStepsData,
  getInitiateStepsData,
  getGenerateEFileXmlStepData,
} from '../getStepsData';
import {
  stepDataPropTypes,
  contextDebugInfoPropTypes,
  overallStatusPropTypes,
} from '../currentReturnFiling.propTypes';
import { wizardStatePropTypes } from '../useWizard.hook';
import styles from '../../styles.module.scss';
import { isActionCompleted } from '../stepValidation';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';

import GenerateWithSchemaValidations from './generateWithSchemaValidations.component';
import UploadWithBusinessRuleValidations from './uploadWithBusinessRuleValidations.component';
import Validation from './validation.container';

const NEBRASKA_JURISDICTION_ID = '3100000000';

const isStepFullyCompleted = ({ actionsBatch, steps, stepData }) =>
  Object.keys(steps[actionsBatch]).every(
    batchStepName =>
      stepData
        .find(data => data.actionsBatch === actionsBatch)
        ?.stepData.find(({ stepName }) => stepName === batchStepName)?.status ===
      StepStatuses.COMPLETED,
  );

const submittingActionsInitialState = Object.keys(EFILE_GENERATE_XML_STEPS).reduce(
  (acc, action) => {
    acc[action] = false;
    return acc;
  },
  {},
);

const isStepSkippable = actionsBatch =>
  EFILE_INITIATE_SKIPPABLE_STEPS.some(step => step === actionsBatch);

const getSubStepsData = ({
  ttiYearId,
  ttiCaseId,
  selectedTtiBinderId,
  efServiceSubmissionId,
  eFileStepsData,
}) => {
  const initiateValues = {
    ttiYearId,
    ttiCaseId,
    domainType: DOMAIN_TYPE,
  };

  const efServiceStepsData = getEFServiceStepsData({
    initiateStepsValues: { efServiceSubmissionId },
    eFileStepsData,
  });

  const initiateStepsData = getInitiateStepsData({
    initiateValues,
    initiateStepsValues: { efServiceSubmissionId },
    eFileStepsData,
  });

  const generateXmlStepsData = getGenerateEFileXmlStepData({
    initiateValues,
    initiateStepsValues: { efServiceSubmissionId },
    binderId: selectedTtiBinderId,
    eFileStepsData,
  });

  const efServiceStepData = {
    actionsBatch: 'ef-service',
    stepData: efServiceStepsData,
  };

  const generateXmlSectionStepData = [
    {
      actionsBatch: 'state-transmission-validation',
      stepData: initiateStepsData,
    },
  ];

  const uploadXmlSectionStepData = [
    {
      actionsBatch: 'validate-and-build-index-for-state-transmission',
      stepData: initiateStepsData,
    },
    {
      actionsBatch: 'state-transmission-import',
      stepData: initiateStepsData,
    },
    {
      actionsBatch: 'perform-calculation-profile',
      stepData: initiateStepsData,
    },
    {
      actionsBatch: 'generate-efile-xml',
      stepData: generateXmlStepsData,
    },
  ];

  const initiateSubSteps = Object.entries(EFILE_GENERATE_XML_STEPS).map(([key, value]) => ({
    actionsBatch: key,
    stepData: eFileStepsData.filter(({ stepName }) => Object.keys(value).includes(stepName)),
  }));

  return {
    efServiceStepData,
    initiateSubSteps,
    generateXmlSectionStepData,
    uploadXmlSectionStepData,
  };
};

const GenerateXmlStep = ({
  globalContext,
  reFetchTtiBinders,
  eFileStepsData,
  fetchStepsData,
  isFetchingEFileStepsData,
  runInitiateTTIStep,
  wizardState,
  setStepStatus,
  isLoading,
  isContextReady,
  successNotification,
  contextDebugInfo,
  getDownloadFileComponent,
  isContextLocked,
  isFetchingTtiBinders,
  overallStatus,
  showConfirmModal,
  jurisdictionDescription,
  jurisdictionStateCode = null,
  binderFilingId = null,
  ttiYearId = null,
  ttiCaseId = null,
  ttiBinderName = null,
  selectedTtiBinderId = null,
  selectedSltBinderId = null,
  efServiceSubmissionId = null,
  sltBinderName = null,
}) => {
  const [isSubmittingActions, setIsSubmittingActions] = useState(submittingActionsInitialState);
  const [stepsToSkip, setStepsToSkip] = useState({});
  const [efServiceId, setEfServiceId] = useState(efServiceSubmissionId);

  useEffect(() => {
    setStepsToSkip(
      Object.keys(EFILE_GENERATE_XML_STEPS).reduce(
        (acc, actionsBatch) => ({
          ...acc,
          [actionsBatch]:
            !(
              globalContext.jurisdictionId === NEW_JERSEY_JURISDICTION_ID ||
              globalContext.jurisdictionId === NEBRASKA_JURISDICTION_ID
            ) && isStepSkippable(actionsBatch),
        }),
        {},
      ),
    );
  }, [setStepsToSkip, globalContext.jurisdictionId]);

  useEffect(() => {
    setEfServiceId(efServiceSubmissionId);
  }, [efServiceSubmissionId]);

  const { currentStep, isWizardActionRunning } = wizardState;

  const toggleSkipCheckbox = useCallback(
    ({ target: { name } }) =>
      setStepsToSkip(prevState => ({ ...prevState, [name]: !prevState[name] })),
    [setStepsToSkip],
  );

  const { fetch: createSubmission } = useFetch({ action: runEFStep });

  const { fetch: createTtiBinder, isFetching: isCreatingBinder } = useFetch({
    action: createTtiBinderApiAction,
    throwError: true,
  });

  const {
    efServiceStepData,
    generateXmlSectionStepData,
    uploadXmlSectionStepData,
    initiateSubSteps,
  } = useMemo(
    () =>
      getSubStepsData({
        ttiYearId,
        ttiCaseId,
        selectedTtiBinderId,
        efServiceSubmissionId,
        eFileStepsData,
      }),
    [ttiYearId, ttiCaseId, selectedTtiBinderId, efServiceSubmissionId, eFileStepsData],
  );

  const setIsActionRunning = useCallback(
    ({ action, value, setInitialState }) => {
      setStepStatus({
        currentStep,
        payload: {
          isStepActionRunning: value,
        },
      });
      setIsSubmittingActions(prevState =>
        setInitialState ? submittingActionsInitialState : { ...prevState, [action]: value },
      );
    },
    [setStepStatus, currentStep],
  );

  const setIsCompleted = useCallback(
    value => {
      setStepStatus({
        currentStep,
        payload: {
          isStepCompleted: value,
        },
      });
    },
    [setStepStatus, currentStep],
  );

  const handleBinderCreation = useCallback(async () => {
    const binderData = await createTtiBinder({
      globalContext,
      ttiYearId,
      ttiCaseId,
      jurisdictionStateCode,
      jurisdictionDescription,
      sltBinderId: selectedSltBinderId,
      sltBinderName,
    });

    if (
      selectedTtiBinderId !== binderData.ttiBinderId ||
      binderFilingId !== binderData.binderFilingId ||
      ttiBinderName !== binderData.ttiBinderName
    ) {
      await reFetchTtiBinders({ globalContext });
    }

    return binderData;
  }, [
    reFetchTtiBinders,
    createTtiBinder,
    globalContext,
    jurisdictionDescription,
    jurisdictionStateCode,
    selectedSltBinderId,
    ttiCaseId,
    ttiYearId,
    binderFilingId,
    selectedTtiBinderId,
    ttiBinderName,
    sltBinderName,
  ]);

  const runTTIStep = useCallback(
    async ({ action, createdSubmissionId, binderFilingId, ttiBinderId = selectedTtiBinderId }) => {
      setIsActionRunning({ action, value: true });

      await runInitiateTTIStep({
        action,
        globalContext,
        binderFilingId,
        ttiYearId,
        ttiCaseId,
        binderId: ttiBinderId,
        domainType: DOMAIN_TYPE,
        submissionId: createdSubmissionId,
        jurisdictionStateCode,
      });

      setIsActionRunning({ action, value: false });
      await fetchStepsData(binderFilingId);
    },
    [
      fetchStepsData,
      globalContext,
      jurisdictionStateCode,
      runInitiateTTIStep,
      ttiCaseId,
      setIsActionRunning,
      ttiYearId,
      selectedTtiBinderId,
    ],
  );

  const uploadAndGenerateBusinessRuleValidations = useCallback(async () => {
    try {
      const createdSubmissionId = efServiceId;

      const areAllStepsCompleted = uploadXmlSectionStepData.every(({ actionsBatch }) =>
        isStepFullyCompleted({
          actionsBatch,
          steps: EFILE_GENERATE_XML_STEPS,
          stepData: uploadXmlSectionStepData,
        }),
      );

      await promiseWaterfall(
        uploadXmlSectionStepData
          .filter(
            ({ actionsBatch }) =>
              (areAllStepsCompleted ||
                !isStepFullyCompleted({
                  actionsBatch,
                  steps: EFILE_GENERATE_XML_STEPS,
                  stepData: uploadXmlSectionStepData,
                })) &&
              (globalContext.isSeparate || !stepsToSkip[actionsBatch]),
          )
          .map(({ actionsBatch }) => () =>
            runTTIStep({ action: actionsBatch, createdSubmissionId, binderFilingId }),
          ),
      );

      successNotification('Uploaded XML and generated business rule validations');
    } catch (error) {
      setIsActionRunning({ value: false, setInitialState: true });

      await fetchStepsData();

      if (!(error instanceof RequestError)) {
        // Rethrowing not request related errors. Request error has error message that is handled
        // by errorNotification by default.
        throw error;
      }
    }
  }, [
    runTTIStep,
    stepsToSkip,
    fetchStepsData,
    successNotification,
    setIsActionRunning,
    globalContext,
    uploadXmlSectionStepData,
    efServiceId,
    binderFilingId,
  ]);

  const generateWithValidations = useCallback(async () => {
    const runEFServiceStep = async binderData => {
      const action = efServiceStepData.actionsBatch;
      setIsActionRunning({ action, value: true });

      const { createdSubmissionId } =
        (await createSubmission({
          globalContext,
          sltBinderId: selectedSltBinderId,
          ttiBinderId: binderData.ttiBinderId,
          binderFilingId: binderData.binderFilingId,
        })) || {};

      setIsActionRunning({ action, value: false });
      await fetchStepsData(binderData.binderFilingId);

      return createdSubmissionId;
    };

    try {
      const binderData = await handleBinderCreation();
      const createdSubmissionId = await runEFServiceStep(binderData);
      if (!createdSubmissionId) {
        // TODO should we not show an error here?
        return;
      }

      setEfServiceId(createdSubmissionId);

      // run state-transmission-validation
      await promiseWaterfall(
        generateXmlSectionStepData
          .filter(({ actionsBatch }) => globalContext.isSeparate || !stepsToSkip[actionsBatch])
          .map(({ actionsBatch }) => () =>
            runTTIStep({
              action: actionsBatch,
              createdSubmissionId,
              binderFilingId: binderData.binderFilingId,
              ttiBinderId: binderData.ttiBinderId,
            }),
          ),
      );

      successNotification('Generated XML and Schema Validations');
    } catch (error) {
      setIsActionRunning({ value: false, setInitialState: true });

      await fetchStepsData();

      if (!(error instanceof RequestError)) {
        // Rethrowing not request related errors. Request error has error message that is handled
        // by errorNotification by default.
        throw error;
      }
    }
  }, [
    handleBinderCreation,
    setEfServiceId,
    createSubmission,
    generateXmlSectionStepData,
    efServiceStepData.actionsBatch,
    fetchStepsData,
    globalContext,
    runTTIStep,
    setIsActionRunning,
    stepsToSkip,
    successNotification,
    selectedSltBinderId,
  ]);

  const onGenerateWithValidations = useCallback(() => {
    if (overallStatus?.name === filingStatus.Submitted) {
      showConfirmModal({
        title: 'Return has been submitted and is awaiting acknowledgement',
        text:
          'Are you sure you want to generate XML as you will not be able to see the response from the state agency?',
        confirmCallback: generateWithValidations,
      });
    } else if (overallStatus?.name === filingStatus.Accepted) {
      showConfirmModal({
        title: 'Return has already been accepted by the state agency',
        text:
          'Are you sure you want to generate XML as you should only do so for states that support superseded returns?',
        confirmCallback: generateWithValidations,
      });
    } else {
      generateWithValidations();
    }
  }, [showConfirmModal, generateWithValidations, overallStatus]);

  const onRunButtonClick = useCallback(() => {
    uploadAndGenerateBusinessRuleValidations();
  }, [uploadAndGenerateBusinessRuleValidations]);

  const downloadEFileXMLStepData = eFileStepsData.find(
    ({ stepName }) => stepName === EFILE_ACTION.DOWNLOAD_EFILE_XML,
  );

  return (
    <>
      <GenerateWithSchemaValidations
        subStepsData={initiateSubSteps}
        globalContext={globalContext}
        isStepSkippable={isStepSkippable}
        stepsToSkip={stepsToSkip}
        toggleStepSkip={toggleSkipCheckbox}
        contextDebugInfo={contextDebugInfo}
        isFetchingEFileStepsData={isFetchingEFileStepsData}
        isSubmittingActions={isSubmittingActions}
        getDownloadFileComponent={getDownloadFileComponent}
        onRunButtonClick={onGenerateWithValidations}
        disabled={
          isWizardActionRunning ||
          isLoading ||
          isCreatingBinder ||
          !isContextReady ||
          isContextLocked
        }
        isContextLocked={isContextLocked}
        isFetchingTtiBinders={isFetchingTtiBinders}
        isCreatingBinder={isCreatingBinder}
      />
      <UploadWithBusinessRuleValidations
        subStepsData={initiateSubSteps}
        globalContext={globalContext}
        isStepSkippable={isStepSkippable}
        stepsToSkip={stepsToSkip}
        contextDebugInfo={contextDebugInfo}
        isFetchingEFileStepsData={isFetchingEFileStepsData}
        isSubmittingActions={isSubmittingActions}
        getDownloadFileComponent={getDownloadFileComponent}
        onRunButtonClick={onRunButtonClick}
        disabled={
          isWizardActionRunning ||
          isLoading ||
          !isContextReady ||
          isContextLocked ||
          !isActionCompleted(
            eFileStepsData.find(
              ({ stepName }) => stepName === EFILE_ACTION.GET_STATE_TRANSMISSION_SCHEMA_VALIDATION,
            ),
          )
        }
        isContextLocked={isContextLocked}
        isFetchingTtiBinders={isFetchingTtiBinders}
        setIsCompleted={setIsCompleted}
      />
      {downloadEFileXMLStepData && downloadEFileXMLStepData.status === StepStatuses.COMPLETED && (
        <div className={styles.validationContainer}>
          <Validation
            ttiYearId={ttiYearId}
            ttiCaseId={ttiCaseId}
            selectedTtiBinderId={selectedTtiBinderId}
            efServiceSubmissionId={efServiceId}
            isLoading={isLoading}
            isContextReady={isContextReady}
            isFetchingTtiBinders={isFetchingTtiBinders}
          />
        </div>
      )}
    </>
  );
};

GenerateXmlStep.propTypes = {
  binderFilingId: PropTypes.number,
  globalContext: globalContextPropTypes,

  ttiYearId: PropTypes.string,
  ttiCaseId: PropTypes.string,
  ttiBinderName: PropTypes.string,
  reFetchTtiBinders: PropTypes.func.isRequired,
  jurisdictionStateCode: PropTypes.string,
  selectedTtiBinderId: PropTypes.number,
  selectedSltBinderId: PropTypes.number,
  efServiceSubmissionId: PropTypes.string,
  sltBinderName: PropTypes.string,

  eFileStepsData: PropTypes.arrayOf(stepDataPropTypes).isRequired,
  fetchStepsData: PropTypes.func.isRequired,
  isFetchingEFileStepsData: PropTypes.bool.isRequired,
  runInitiateTTIStep: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,

  contextDebugInfo: contextDebugInfoPropTypes,
  getDownloadFileComponent: PropTypes.func.isRequired,

  isContextLocked: PropTypes.bool.isRequired,
  isFetchingTtiBinders: PropTypes.bool.isRequired,

  wizardState: wizardStatePropTypes,
  setStepStatus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isContextReady: PropTypes.bool.isRequired,

  overallStatus: overallStatusPropTypes,

  showConfirmModal: PropTypes.func.isRequired,
  jurisdictionDescription: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    globalContext: globalContextSelector(state),

    eFileStepsData: eFileStepsDataSelector(state),
    isFetchingEFileStepsData: isFetchingEFileStepsDataSelector(state),

    overallStatus: eFileOverallStatusSelector(state),

    jurisdictionDescription: jurisdictionDescriptionSelector(state),
  }),
  {
    runInitiateTTIStep,
    successNotification,
    showConfirmModal,
  },
)(GenerateXmlStep);
