import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ isInEditMode, updateRow, ToggleAllSelectionsHeader }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerComponent: ToggleAllSelectionsHeader,
      headerName: 'Included',
      field: 'includedFlag',
      colId: 'includedFlag',
      width: 60,
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellStyle: {
        textAlign: 'center',
      },
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity ID',
      field: 'entityId',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Name',
      field: 'entityName',
      width: 300,
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'FEIN',
      field: 'fein',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Business',
      field: 'businessDescription',
      width: 300,
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Federal Filing Type',
      field: 'fedFilingtype',
      width: 200,
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Country of Incorporation',
      field: 'countryOfInc',
      cellStyle: {
        textAlign: 'center',
      },
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Acquisition Date',
      field: 'dateAcquired',
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Incorporated Date ',
      field: 'dateIncorporated',
      suppressMenu: false,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
