import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { GridApi } from 'ag-grid-community';

import QuickFilterBadgeWrapper from './quickFilterBadgeWrapper.component';
import styles from './styles.module.scss';

export interface QuickFilterBadgeGroupProps {
  filterModels: Record<string, Record<string, Record<string, string[] | string>>>;
  gridApi: GridApi | null;
  showResetFilters?: boolean;
}

const QuickFilterBadgeGroup = ({
  filterModels,
  gridApi,
  showResetFilters = false,
}: QuickFilterBadgeGroupProps) => {
  const [toggledBadge, setToggledBadge] = useState('');

  const onResetFiltersClick = useCallback(() => {
    gridApi?.setFilterModel(null);
  }, [gridApi]);

  return (
    <div className={styles.quickFilterBadgeGroupContainer}>
      <div className={styles.quickFilterBadgeGroupHeader}>
        <span>QUICK FILTERS</span>
        {showResetFilters ? (
          <Link to={{}} onClick={onResetFiltersClick}>
            Reset Filters
          </Link>
        ) : null}
      </div>
      <div className={`${styles.quickFilterBadgeWrapper}`}>
        {Object.keys(filterModels).map(badgeLabel => (
          <QuickFilterBadgeWrapper
            key={badgeLabel}
            badgeLabel={badgeLabel}
            toggledBadge={toggledBadge}
            setToggledBadge={setToggledBadge}
            filterModels={filterModels}
            gridApi={gridApi}
          />
        ))}
      </div>
    </div>
  );
};

export default QuickFilterBadgeGroup;
