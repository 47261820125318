import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  GetEFileStepsResponse,
  FindFederalReturnResponse,
  SltBinderId,
} from '../../../../../common/types';
import useServerSentEventSubscription from '../../../utils/useServerSentEventSubscription';
import { Events } from '../../../utils/sseService';
import { globalContextSelector } from '../../../shared/store/selectors';
import {
  useQueryGetEFileSteps,
  useQueryGetEFileStepsStatus,
} from '../../../shared/queries/eFileV2';
import setFederalSubmissionIdBasedOnFederalReturnOption from '../utils/setFederalSubmissionIdBasedOnFederalReturnOption';
import { POLLING_STEPS, WIZARD_MACHINE_EVENTS } from '../constants';
import { StepStatus, WizardStepName } from '../enums';
import config from '../../../config';

interface UseActiveStepHookParams {
  sendMachineEvent: ({ type, value }: { type: string; value: unknown }) => void;
  sltBinderId: SltBinderId | null;
  stepName: WizardStepName | null;
  isRunningValidation: boolean;
  federalReturn: FindFederalReturnResponse;
}

interface EFileStepStatusParams {
  sltBinderId: SltBinderId;
  status: StepStatus;
  stepName: WizardStepName;
  payload: Record<string, unknown>;
}

const SSE_SUBSCRIBE_URL = '/api/efile-v2/subscribe';

const useActiveStep = ({
  sendMachineEvent,
  sltBinderId,
  stepName,
  isRunningValidation,
  federalReturn,
}: UseActiveStepHookParams) => {
  const globalContext = useSelector(globalContextSelector);

  const initSetEFileSteps = useCallback(
    (eFileSteps: GetEFileStepsResponse) => {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_STATE_SUBMISSION_ID,
        value: eFileSteps.stateSubmissionId,
      });
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_INIT_EFILE_STEPS,
        value: eFileSteps.steps,
      });
    },
    [sendMachineEvent],
  );

  const setEFileStepStatus = useCallback(
    (eFileStepStatus: EFileStepStatusParams) => {
      if (eFileStepStatus?.sltBinderId === sltBinderId) {
        sendMachineEvent({
          type: WIZARD_MACHINE_EVENTS.SET_STEP_STATUS,
          value: eFileStepStatus,
        });
        if (
          eFileStepStatus.stepName === WizardStepName.STEP_4_AUTHORIZE_AND_TRANSMIT &&
          eFileStepStatus.status === StepStatus.COMPLETED
        ) {
          sendMachineEvent({ type: WIZARD_MACHINE_EVENTS.NEXT, value: null });
        }
      }
    },
    [sendMachineEvent, sltBinderId],
  );

  const { subscribe } = useServerSentEventSubscription(
    SSE_SUBSCRIBE_URL,
    setEFileStepStatus,
    Events.EFILEV2STEPSTATUS,
  );

  const {
    data: eFileStepsData,
    isFetching: isFetchingSteps,
    isFetched: areStepsFetched,
  } = useQueryGetEFileSteps({
    params: { globalContext, sltBinderId },
    enabled: Boolean(sltBinderId),
    onSuccess: newData => {
      initSetEFileSteps(newData);
      if (config.USE_SERVER_SIDE_EVENTS) {
        subscribe();
      }
    },
  });

  useQueryGetEFileStepsStatus({
    params: { globalContext, sltBinderId },
    enabled: Boolean(sltBinderId),
    refetchInterval:
      !config.USE_SERVER_SIDE_EVENTS &&
      !isRunningValidation &&
      stepName &&
      POLLING_STEPS.includes(stepName)
        ? config.EFILE_WIZARD_POLLING_INTERVAL
        : false,
    onSuccess: newData => {
      if (!sltBinderId || !newData || !stepName) {
        return;
      }
      const stepStatus = newData?.steps[stepName];
      if (!stepStatus) {
        return;
      }
      setEFileStepStatus({
        status: stepStatus,
        sltBinderId,
        stepName,
        payload: {
          stateSubmissionId: newData.stateSubmissionId,
          efSubmissionId: newData.eFileServiceSubmissionId,
        },
      });
    },
  });

  useEffect(() => {
    const federalReturnOption = federalReturn?.federalReturnOption || null;

    const federalSubmissionId = setFederalSubmissionIdBasedOnFederalReturnOption({
      federalReturnOption,
      federalAsFiledSubmissionId: federalReturn?.federalSubmissionId || null,
      federalProformaSubmissionId: federalReturn?.proformaSubmissionId || null,
      manualFederalProformaSubmissionId: federalReturn?.manualFederalProformaSubmissionId || null,
      consolidatedAsFiledSubmissionIdForEntity:
        federalReturn?.consolidatedAsFiledSubmissionIdForEntity || null,
      consolidatedProformaSubmissionIdForEntity:
        federalReturn?.consolidatedProformaSubmissionIdForEntity || null,
    });

    if (federalReturnOption) {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_FEDERAL_RETURN_OPTION,
        value: federalReturn?.federalReturnOption,
      });
    }

    if (federalSubmissionId) {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_FEDERAL_SUBMISSION_ID,
        value: federalSubmissionId,
      });
    }
  }, [sendMachineEvent, federalReturn]);

  useEffect(() => {
    if (eFileStepsData?.eFileServiceSubmissionId) {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_EFILE_SERVICE_SUBMISSION_ID,
        value: eFileStepsData?.eFileServiceSubmissionId,
      });
    }
  }, [eFileStepsData?.eFileServiceSubmissionId, sendMachineEvent]);

  return {
    isFetchingSteps,
    areStepsFetched,
  };
};

export default useActiveStep;
