import React, { useCallback, useMemo, useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { FillOperationParams } from 'ag-grid-community';
import { Tooltip, Tabs, Tab } from '@pwc/appkit-react';

import {
  ConsolidationFilingDecisionsData,
  FindFilingOrNONFilingMemberData,
} from '../../../common/types';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import { useQueryReturnStatuses } from '../shared/queries/taxReturns';
import { useQueryConsolidatedMemberReturns } from '../shared/queries/memberReturns';
import { ColumnsBlueprint } from '../../../routes/shared/types';
import useGridApi from '../shared/hooks/useGridApi.hook';
import { useRowEditMode } from '../shared/editMode';
import { NumberCellEditor, TextCellEditor } from '../shared/columnDefinitions/cellRenderers';
import AppkitIcon from '../shared/displayComponents/appkitIcon/appkitIcon.component';
import Header from '../shared/displayComponents/header/header.component';
import { GlobalContext } from '../../../common/types/apiShapes';
import { globalContextSelector } from '../shared/store/selectors';
import columnBlueprintStyles from '../shared/styles/columnBlueprintStyles.module.scss';
import { columnBlueprintHeaderOptions } from '../shared/columnDefinitions/constants';
import { useMutationUpdateMemberReturns } from '../shared/mutations/memberReturns';
import SelectContextDataInfo from '../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import { useShouldDisplayReturnsDueDates } from '../shared/hooks/useShouldDisplayReturnsDueDates.hook';
import useSetContext from '../shared/hooks/useSetContext.hook';

import styles from './memberReturns.module.scss';
import { getConsolidationDetailsColumnDefinitions } from './consolidationDetails.columnDefinitions';
import { getMemberReturnsColumnDefinitions } from './memberReturnDetails.columnDefinitions';

interface MemberReturnsUpdates {
  rowsPairsWithChanges: [
    { oldRow: FindFilingOrNONFilingMemberData; newRow: FindFilingOrNONFilingMemberData },
  ];
}

enum TabsTypes {
  Filing = 'Filing',
  NonFiling = 'NonFiling',
}

const getUniqueRowId = ({ data: { returnId } }: { data: ConsolidationFilingDecisionsData }) =>
  returnId;

const tooltipTextItems = [
  '- Double click the cell to select from the dropdown menu.',
  '- Single click a cell then use the fill handle to drag and drop data within the column.',
];

const MemberReturns = () => {
  const {
    gridApi: consolidationDetailsGridApi,
    columnApi: consolidationDetailsColumnApi,
    onGridReady: onConsolidationDetailsGridReady,
  } = useGridApi();

  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const {
    taxYear,
    period,
    jurisdictionId,
    consolidationId,
    isReady: isGlobalContextReady,
  } = globalContext;

  const [activeTab, setActiveTab] = useState(TabsTypes.Filing);

  const shouldDisplayReturnsDueDates = useShouldDisplayReturnsDueDates();

  const {
    data: taxReturnStatusOptions,
    isLoading: isFetchingTaxReturnStatusOptions,
  } = useQueryReturnStatuses();

  const {
    data: consolidationMemberReturns,
    isLoading: isLoadingConsolidationTaxReturns,
  } = useQueryConsolidatedMemberReturns({
    params: {
      taxYear: taxYear || '',
      period: period || '',
      jurisdictionId: jurisdictionId || '',
      consolidationId: consolidationId || '',
    },
    enabled: Boolean(taxYear && period && jurisdictionId && consolidationId),
  });

  const {
    mutateAsync: updateMemberReturns,
    isLoading: isUpdatingMemberReturns,
  } = useMutationUpdateMemberReturns();

  const allMemberReturnsData = useMemo(
    () => [
      ...(consolidationMemberReturns?.filingMemberData?.returns || []),
      ...(consolidationMemberReturns?.nonFilingMemberData?.returns || []),
    ],
    [
      consolidationMemberReturns?.filingMemberData?.returns,
      consolidationMemberReturns?.nonFilingMemberData?.returns,
    ],
  );

  // create tabDefinitions based on presence of filing separate form and/or non-filing separate form
  // in the consol return's return definition
  const tabsDefinitions = useMemo(() => {
    const definitions = [];
    if (consolidationMemberReturns?.filingMemberData) {
      definitions.push({
        type: TabsTypes.Filing,
        label: consolidationMemberReturns?.filingMemberData?.formName,
      });
    }
    if (consolidationMemberReturns?.nonFilingMemberData) {
      definitions.push({
        type: TabsTypes.NonFiling,
        label: consolidationMemberReturns?.nonFilingMemberData?.formName,
      });
    }
    return definitions;
  }, [consolidationMemberReturns]);

  useEffect(() => {
    if (tabsDefinitions[0]) {
      setActiveTab(tabsDefinitions[0].type);
    }
  }, [tabsDefinitions]);

  const isLoading =
    isFetchingTaxReturnStatusOptions || isLoadingConsolidationTaxReturns || isUpdatingMemberReturns;

  const onSave = useCallback(
    async (updates: MemberReturnsUpdates) =>
      await updateMemberReturns({
        taxYear: taxYear || '',
        period: period || '',
        jurisdictionId: jurisdictionId || '',
        consolidationId: consolidationId || '',
        memberReturns: updates.rowsPairsWithChanges
          .map(rowPair => rowPair.newRow)
          .filter(memberReturn => memberReturn.taxYearEnding),
      }),
    [updateMemberReturns, consolidationId, jurisdictionId, period, taxYear],
  );

  const {
    updateRow,
    clonedRowData: clonedMemberReturnsData,
    gridApi: memberReturnsGridApi,
    columnApi: memberReturnsColumnApi,
    onGridReady: onMemberReturnsGridReady,
    isInEditMode: isMemberGridInEditMode,
    editModeButtons,
  } = useRowEditMode({
    onSave,
    rowData: allMemberReturnsData,
    getUniqueRowId,
    editButtonDisabled: isLoading || !isGlobalContextReady,
    saveButtonDisabled: false,
  });

  const consolidationDetailsColumnDefinitions = useMemo(
    () =>
      getConsolidationDetailsColumnDefinitions({
        columnsBlueprint: (consolidationMemberReturns?.columnsBlueprint as ColumnsBlueprint) || [],
        taxReturnStatusOptions,
        shouldDisplayReturnsDueDates,
      }),
    [
      consolidationMemberReturns?.columnsBlueprint,
      taxReturnStatusOptions,
      shouldDisplayReturnsDueDates,
    ],
  );

  const { setContext } = useSetContext();

  const memberColumnDefinitions = useMemo(
    () =>
      getMemberReturnsColumnDefinitions({
        updateRow,
        setContext,
        taxYear: taxYear || '',
        period: period || '',
        filingFormName:
          activeTab === TabsTypes.Filing
            ? consolidationMemberReturns?.filingMemberData?.formName
            : consolidationMemberReturns?.nonFilingMemberData?.formName,
        columnsBlueprint: (consolidationMemberReturns?.columnsBlueprint as ColumnsBlueprint) || [],
        taxReturnStatusOptions,
        isInEditMode: isMemberGridInEditMode,
        shouldDisplayReturnsDueDates,
      }),
    [
      updateRow,
      setContext,
      taxYear,
      period,
      activeTab,
      consolidationMemberReturns?.filingMemberData?.formName,
      consolidationMemberReturns?.nonFilingMemberData?.formName,
      consolidationMemberReturns?.columnsBlueprint,
      taxReturnStatusOptions,
      isMemberGridInEditMode,
      shouldDisplayReturnsDueDates,
    ],
  );

  const consolidationDetailsGridOptions = {
    defaultColDef: {
      editable: false,
    },
    rowData: consolidationMemberReturns?.consolReturnData
      ? [consolidationMemberReturns?.consolReturnData]
      : [],
    columnDefs: consolidationDetailsColumnDefinitions,
    alignedGrids: [{ api: memberReturnsGridApi, columnApi: memberReturnsColumnApi }],
  };

  const memberReturnsGridOptions = {
    defaultColDef: {
      editable: false,
    },
    rowData: clonedMemberReturnsData,
    columnDefs: memberColumnDefinitions,
    alignedGrids: [{ api: consolidationDetailsGridApi, columnApi: consolidationDetailsColumnApi }],
    fillHandleDirection: 'y',
  };

  const tooltipContent = useMemo(
    () => (
      <ul>
        {tooltipTextItems.map((text, index) => (
          <li key={index}>{text}</li>
        ))}
      </ul>
    ),
    [],
  );

  const fillCells = useCallback(({ initialValues }: FillOperationParams) => initialValues[0], []);

  const handleTabChange = useCallback((_: ChangeEvent, tab: TabsTypes) => setActiveTab(tab), []);

  const isExternalFilterPresent = useCallback(() => true, []);

  const doesExternalFilterPass = useCallback(
    ({ data }: { data: FindFilingOrNONFilingMemberData }) =>
      activeTab === TabsTypes.Filing ? data?.isFilingMember : !data?.isFilingMember,
    [activeTab],
  );

  return (
    <>
      <Header title="Member Returns" />
      <div
        className={`page-with-grid ${styles.memberReturnsGrids} ${columnBlueprintStyles.gridContainer}`}
      >
        <div className={styles.consolGridHeader}>
          <span className={styles.gridTitle}>Consolidation Details</span>
          <span className={styles.consolFormName}>
            {consolidationMemberReturns?.consolReturnData?.filingFormName}
          </span>
        </div>
        <AgGrid
          className={styles.consolidationDetailsGridWrapper}
          isGridLoading={isLoading}
          autoMaxWidth
          {...consolidationDetailsGridOptions}
          onGridReady={onConsolidationDetailsGridReady}
          {...columnBlueprintHeaderOptions}
        />
        <div className={styles.memberDetailsHelp}>
          <div className={styles.memberDetailsTooltip}>
            <span className={styles.text}>Use the grid below to change return settings.</span>
            <span className={styles.tooltip}>
              <Tooltip content={tooltipContent} placement="top">
                <AppkitIcon icon="information" type="outline" size={14} />
              </Tooltip>
            </span>
          </div>
          {editModeButtons}
        </div>
        <div className={styles.memberGridContainer}>
          <div className={styles.memberGridHeader}>
            <span className={styles.gridTitle}>Member Return Details</span>
            {Boolean(tabsDefinitions?.length) && (
              <div className={styles.tabsWrapper}>
                {consolidationMemberReturns && (
                  <Tabs
                    className={styles.tabsContainer}
                    value={activeTab}
                    size="md"
                    onChange={handleTabChange}
                  >
                    {tabsDefinitions.map(({ label, type }) => (
                      <Tab id={type} value={type} label={label} key={type} />
                    ))}
                  </Tabs>
                )}
              </div>
            )}
          </div>
          <AgGrid
            className={styles.memberReturnDetailsGridWrapper}
            isGridLoading={isLoading}
            autoMaxWidth
            {...memberReturnsGridOptions}
            onGridReady={onMemberReturnsGridReady}
            enableRangeSelection
            enableFillHandle={isMemberGridInEditMode}
            suppressMultiRangeSelection
            fillOperation={fillCells}
            suppressClearOnFillReduction
            stopEditingWhenCellsLoseFocus
            components={{
              NumberCellEditor,
              TextCellEditor,
            }}
            getRowId={getUniqueRowId}
            withSearchBar
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            {...columnBlueprintHeaderOptions}
          />
        </div>
      </div>
    </>
  );
};

const MemberReturnsContainer = () => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const { taxYear, period, jurisdictionId, consolidationId } = globalContext;
  const isContextReady = Boolean(taxYear && period && jurisdictionId && consolidationId);

  if (!isContextReady) {
    return <SelectContextDataInfo />;
  }

  return <MemberReturns />;
};

export default MemberReturnsContainer;
