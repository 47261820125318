import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListHierarchy from '../../../shared/displayComponents/listHierarchy/listHierarchy';
import { listHierarchyItemsTypes } from '../../../shared/displayComponents/listHierarchy/propTypes';
import {
  selectPDFPageDataUsageAndAttachmentConfig,
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
  selectDataItem,
  selectDataModel,
} from '../../../shared/store/dataModels/actions';
import {
  mappingFormPDFPageDataUsageAndAttachmentConfigsTreeSelector,
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
  mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector,
} from '../../../shared/store/dataModels/selectors';

const PDFPagesFieldMappingTree = ({
  formPDFId,
  onPageSelected,
  onDatasetSelected,

  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
  mappingFormPDFPageDataUsageAndAttachmentConfigsTree,
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs,
  mappingFormPDFPageDataUsageAndAttachmentConfigId,
  selectPDFPageDataUsageAndAttachmentConfig,
  selectDataItem,
  selectDataModel,
}) => {
  useEffect(() => {
    if (formPDFId) {
      fetchMappingFormPDFPageDataUsageAndAttachmentConfigs({ pdfId: formPDFId });
    }
  }, [fetchMappingFormPDFPageDataUsageAndAttachmentConfigs, formPDFId]);

  const onPDFPageDataUsageAndAttachmentConfigClick = useCallback(
    ({ id, type, dataModelId }) => {
      selectDataModel(dataModelId);
      selectDataItem(null);
      selectPDFPageDataUsageAndAttachmentConfig(id);

      if (type === 'dataset_usage') {
        onDatasetSelected();

        return;
      }

      if (type === 'page') {
        onPageSelected();

        return;
      }
    },
    [
      selectDataModel,
      onPageSelected,
      onDatasetSelected,
      selectPDFPageDataUsageAndAttachmentConfig,
      selectDataItem,
    ],
  );

  return (
    <ListHierarchy
      isLoading={isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs}
      items={mappingFormPDFPageDataUsageAndAttachmentConfigsTree}
      selectedItemIds={[mappingFormPDFPageDataUsageAndAttachmentConfigId]}
      itemOnClick={onPDFPageDataUsageAndAttachmentConfigClick}
    />
  );
};

PDFPagesFieldMappingTree.propTypes = {
  formPDFId: PropTypes.string,
  onDatasetSelected: PropTypes.func.isRequired,
  onPageSelected: PropTypes.func.isRequired,

  expandCollapseHierarchyListItem: PropTypes.func.isRequired,
  collapsedHierarchyListItems: PropTypes.objectOf(PropTypes.bool),
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs: PropTypes.func.isRequired,
  mappingFormPDFPageDataUsageAndAttachmentConfigsTree: listHierarchyItemsTypes,
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs: PropTypes.bool.isRequired,
  mappingFormPDFPageDataUsageAndAttachmentConfigId: PropTypes.string,
  selectPDFPageDataUsageAndAttachmentConfig: PropTypes.func.isRequired,
  selectDataItem: PropTypes.func.isRequired,
  selectDataModel: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    mappingFormPDFPageDataUsageAndAttachmentConfigsTree: mappingFormPDFPageDataUsageAndAttachmentConfigsTreeSelector(
      state,
    ),
    isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs: isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector(
      state,
    ),
    mappingFormPDFPageDataUsageAndAttachmentConfigId: mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector(
      state,
    ),
  }),
  {
    fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
    selectPDFPageDataUsageAndAttachmentConfig,
    selectDataItem,
    selectDataModel,
  },
)(PDFPagesFieldMappingTree);
