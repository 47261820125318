import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import {
  taxYearSelector,
  taxYearOptionsSelector,
  periodSelector,
  previousDevelopmentPathnameSelector,
  defaultTaxYearSelector,
} from './selectors';
import {
  fetchTaxYears,
  fetchPeriods,
  fetchConsolidations,
  fetchJurisdictions,
  fetchJurisdictionsForAttachmentsAndBinders,
  setPreviousPathname,
  selectTaxYear,
  selectJurisdiction,
  selectPeriod,
  selectForm,
} from './actions';

const pathsWithAllJurisdictionsOptions = [
  Routes.developmentTaxForms.MAIN,
  Routes.developmentTaxFormsV2.MAIN,
  Routes.developmentTaxFormEditV2.MAIN,
  Routes.developmentGenericFunctions.MAIN,
  Routes.genericScreenDefinition.MAIN,
  Routes.developmentDataModels.MAIN,
  Routes.developmentCalcSpecs.MAIN,
  Routes.expressionBuilder.MAIN,
  Routes.compileExpressions.MAIN,
  Routes.watermarks.MAIN,
  Routes.selectionLists.MAIN,
  Routes.checkboxGroups.MAIN,
  // NOTE: SLT-14465 Turn Off E-file Element Mapping Tool
  // Routes.eFileElementMappings.MAIN,
  Routes.calcPreferences.MAIN,
  Routes.returnDefinitions.MAIN,
];

const pathsWithLimitedJurisdictionsOptions = [
  Routes.defaultBinders.MAIN,
  Routes.attachmentDefinitions.MAIN,
];

const isPathInPaths = (pathname, paths) =>
  paths.some(path =>
    matchPath(pathname, {
      path,
      exact: true,
    }),
  );

export const useFetchTaxYears = () => {
  const dispatch = useDispatch();

  const taxYears = useSelector(taxYearOptionsSelector);
  const taxYear = useSelector(taxYearSelector);
  const defaultTaxYear = useSelector(defaultTaxYearSelector);

  useEffect(() => {
    if (taxYears.length === 0) {
      dispatch(fetchTaxYears());
    }
  }, [dispatch, taxYears]);

  useEffect(() => {
    if (!taxYear) {
      dispatch(selectTaxYear(defaultTaxYear));
    }
  }, [dispatch, defaultTaxYear, taxYear]);
};

export const useFetchPeriods = () => {
  const taxYear = useSelector(taxYearSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taxYear) {
      dispatch(fetchPeriods(taxYear));
    }
  }, [dispatch, taxYear]);
};

export const useFetchConsolidations = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taxYear && period) {
      dispatch(fetchConsolidations(taxYear, period));
    }
  }, [dispatch, taxYear, period]);
};

export const useFetchJurisdictions = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const previousPathname = useSelector(previousDevelopmentPathnameSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (taxYear && period) {
      if (
        (isPathInPaths(previousPathname, pathsWithLimitedJurisdictionsOptions) ||
          previousPathname === null) &&
        isPathInPaths(pathname, pathsWithAllJurisdictionsOptions)
      ) {
        dispatch(fetchJurisdictions({ taxYear, period }));
        dispatch(setPreviousPathname(pathname));
      } else if (
        (isPathInPaths(previousPathname, pathsWithAllJurisdictionsOptions) ||
          previousPathname === null) &&
        isPathInPaths(pathname, pathsWithLimitedJurisdictionsOptions)
      ) {
        dispatch(fetchJurisdictionsForAttachmentsAndBinders({ taxYear }));
        dispatch(setPreviousPathname(pathname));
      }
    }
  }, [dispatch, taxYear, period, pathname, previousPathname]);
};

export const useUpdateDevelopmentContextFromQueryParams = () => {
  const dispatch = useDispatch();
  const { queryParams, setParams } = useUrlParams();

  useEffect(() => {
    if (queryParams) {
      // Keep the same order of selection as in context as prior params resets latter ones to null
      if (queryParams.taxYear) {
        dispatch(selectTaxYear(queryParams.taxYear));
        setParams({ queryParams: { taxYear: null } });
      }
      if (queryParams.period) {
        dispatch(selectPeriod(queryParams.period));
        setParams({ queryParams: { period: null } });
      }
      if (queryParams.jurisdictionId) {
        dispatch(selectJurisdiction(queryParams.jurisdictionId));
        setParams({ queryParams: { jurisdictionId: null } });
      }
      if (queryParams.formId) {
        dispatch(selectForm(queryParams.formId));
        setParams({ queryParams: { formId: null } });
      }
    }
  }, [dispatch, setParams, queryParams]);
};
