const Yup = require('yup');

const insertNotificationSchema = Yup.object().shape({
  title: Yup.string().required(),
  content: Yup.string().required(),
});

const dismissBulkNotificationsSchema = Yup.array().of(Yup.string().required());

module.exports = {
  insertNotificationSchema,
  dismissBulkNotificationsSchema,
};
