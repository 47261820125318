import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import withPermissions from '../authorization/withPermissionsHoc.container';
import { customerPermissionsPropTypes } from '../propTypes/customerPropTypes';
import NestedMenu from '../displayComponents/nestedMenu/nestedMenu.component';
import getNestedMenuSchemaDefinition from '../displayComponents/nestedMenu/nestedMenuSchemaDefinition';
import customActions from '../displayComponents/nestedMenu/customActions';
import '../../shared/displayComponents/paramDropdown/paramDropdown.style.scss';
import {
  globalContextSelector,
  isClientTtiAvailableSelector,
  trgReportsSelector,
} from '../../shared/store/selectors';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import { clientIdSelector } from '../store/context';

import filterMenuSchemaByPermissions from './filterMenuSchemaByPermissions';
import topLevelMenuButtonFactory from './topLevelMenuButton.factory';

const MenuTabs = ({
  customerPermissions,
  pathname,

  globalContext,
  trgReports,
}) => {
  const clientId = useSelector(clientIdSelector);
  const isClientTtiAvailable = useSelector(isClientTtiAvailableSelector);
  const menuTopButtonComponent = useCallback(
    ({ label, path, route }) => topLevelMenuButtonFactory({ label, path, route, pathname }),
    [pathname],
  );

  const nestedMenuSchemaDefinition = getNestedMenuSchemaDefinition(globalContext, trgReports);

  if (customerPermissions) {
    return (
      <div className="menu-container">
        {filterMenuSchemaByPermissions(
          customerPermissions,
          nestedMenuSchemaDefinition,
          isClientTtiAvailable,
        ).map((item, index) => (
          <NestedMenu
            ButtonComponent={menuTopButtonComponent({
              label: item.label,
              path: item.access,
              route: item.route,
            })}
            key={index}
            label={item.label}
            options={item.options}
            customActions={customActions}
            clientId={clientId}
            menuItemHighlightedStyle={{ backgroundColor: '#f2f2f2' }}
            subMenuIcon={<span className="appkiticon icon-right-chevron-outline sub-menu-icon" />}
          />
        ))}
      </div>
    );
  }

  return null;
};

MenuTabs.propTypes = {
  customerPermissions: customerPermissionsPropTypes,
  pathname: PropTypes.string.isRequired,

  globalContext: globalContextPropTypes,
  trgReports: PropTypes.arrayOf(
    PropTypes.shape({
      reportId: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
};

export default withPermissions(
  connect(state => ({
    globalContext: globalContextSelector(state),
    trgReports: trgReportsSelector(state),
  }))(MenuTabs),
);
