import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Select, SelectOption } from '@pwc/appkit-react';

import ErrorMessage from '../error/errorMessage.component';
import Loading from '../../displayComponents/loading.component';

import SDKCustomSelect from './sdkCustomSelect.component';
import InlineSelect from './inlineSelect.component';

const noop = () => null;
const initialValue = { label: '', value: '' };

interface FormikSDKCustomSelectProps<TValue> {
  name: string;
  wrapperClassName?: string;
  appkitLabel?: string;
  appkitPlaceholder?: string;
  inline?: boolean;
  useAppkit?: boolean;
  isClearable?: boolean;
  isAutoClearable?: boolean; // should clear field when value not included in options
  isLoading?: boolean;
  customChangeHandler?: (
    name: string,
    newValue: TValue | { label: string; value: TValue },
  ) => unknown;
  overrideDefaultOnChange?: boolean;
  options?: Array<{ label: string | null; value: TValue | string; description?: string }>;
  [unknownProp: string]: unknown;
  shouldDisplayErrorMessage?: boolean;
  shouldUnsetValueWhenNotInOptions?: boolean;
}

function FormikSDKCustomSelect<TValue = string | number | boolean | null>({
  name,
  isAutoClearable = false,
  isClearable = false,
  isLoading = false,
  appkitLabel = '',
  appkitPlaceholder = '',
  inline = false,
  useAppkit = false,
  wrapperClassName = '',
  customChangeHandler = noop,
  overrideDefaultOnChange,
  options = [],
  shouldDisplayErrorMessage = true,
  shouldUnsetValueWhenNotInOptions = false,
  ...props
}: FormikSDKCustomSelectProps<TValue | string>) {
  const { setFieldValue, errors, values } = useFormikContext<Record<string, unknown>>();

  const [lastestAvailableValue, setLastestAvailableValue] = useState<TValue | string | null>(null);

  const error = errors[name];

  const shouldAutoClear = useMemo(
    () => isAutoClearable && !options.find(option => option.value === values[name]) && !isLoading,
    [options, name, values, isAutoClearable, isLoading],
  );

  const onChange = useCallback(
    async (field: { label: string; value: TValue | string }) => {
      if (!field && !isClearable) {
        return;
      }

      if (!overrideDefaultOnChange) {
        // TS definition for `setFieldValue` is wrong cause it actually returns a promise.
        // Using `await` here ensures that the field gets touched which doesn't always happen
        // when there is more `setFieldValue` in `customChangeHandler`
        await ((setFieldValue(name, field?.value ?? field) as unknown) as Promise<void>);
      }

      customChangeHandler(name, field?.value ?? field);
    },
    [customChangeHandler, setFieldValue, name, overrideDefaultOnChange, isClearable],
  );

  useEffect(() => {
    if (shouldAutoClear && values[name]) {
      setLastestAvailableValue(values[name] as string);
      onChange(initialValue);
    }
  }, [onChange, shouldAutoClear, values, name]);

  useEffect(() => {
    if (
      lastestAvailableValue &&
      !values[name] &&
      options.find(option => option.value === lastestAvailableValue)
    ) {
      onChange({ label: '', value: lastestAvailableValue });
      setLastestAvailableValue(null);
    }
  }, [onChange, lastestAvailableValue, values, options, name]);

  useEffect(() => {
    if (
      !shouldUnsetValueWhenNotInOptions ||
      options.find(option => option.value === values[name])
    ) {
      return;
    }
    setFieldValue(name, null);
  }, [name, options, setFieldValue, shouldUnsetValueWhenNotInOptions, values]);

  const selectComp = useMemo(() => {
    if (inline) {
      return (
        <InlineSelect
          {...{ ...props, options }}
          name={name}
          error={error}
          onChange={onChange}
          appkitLabel={appkitLabel}
          isClearable={isClearable}
          shouldDisplayErrorMessage
        />
      );
    }
    if (useAppkit) {
      return (
        <Loading small isLoading={isLoading}>
          <div>
            {appkitLabel && <label className="a-form-label">{appkitLabel}</label>}
            <Select
              placeholder={appkitPlaceholder || 'Select...'}
              onSelect={onChange}
              defaultValue={values[name]}
              value={values[name]}
            >
              {options?.length ? (
                options.map(({ value, label, description }) => (
                  <SelectOption key={value} value={value} selectedDisplay={label}>
                    <div>
                      <div className="select-option-label">{label}</div>
                      {description && <div className="a-select-comment">{description}</div>}
                    </div>
                  </SelectOption>
                ))
              ) : (
                <SelectOption disabled>No results found</SelectOption>
              )}
            </Select>
          </div>
          {shouldDisplayErrorMessage && <ErrorMessage error={error} />}
        </Loading>
      );
    }

    return (
      <>
        <SDKCustomSelect
          {...props}
          options={options}
          formikClassName={`${error ? 'with-error' : ''}`}
          name={name}
          appkitLabel={appkitLabel}
          onChange={onChange}
          virtualized
          isClearable={isClearable}
          hideLabel={!appkitLabel}
        />
        {shouldDisplayErrorMessage && !isLoading && <ErrorMessage error={error} />}
      </>
    );
  }, [
    onChange,
    appkitLabel,
    appkitPlaceholder,
    error,
    inline,
    isClearable,
    options,
    isLoading,
    name,
    props,
    useAppkit,
    values,
    shouldDisplayErrorMessage,
  ]);

  return useMemo(() => {
    return <div className={wrapperClassName}>{selectComp}</div>;
  }, [wrapperClassName, selectComp]);
}

export default FormikSDKCustomSelect;
