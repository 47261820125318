import { Routes } from '@common-packages/routes-definitions';

const data = [
  {
    title: 'Entity Analytics Review',
    icon: 'table-data',
    description: 'See Entity Analytics Review',
    route: Routes.entityAnalyticsReview.MAIN,
  },
  {
    title: 'High Level Planning Data',
    icon: 'table-data',
    description: 'See high level planning data',
    route: Routes.highLevelPlanningData.MAIN,
  },
  {
    title: 'Detailed Planning Data',
    icon: 'table-data',
    description: 'See detailed planning data',
    route: Routes.detailedPlanningData.MAIN,
  },
  {
    title: 'Federal Dividend To State Amounts',
    icon: 'table-data',
    description: 'See federal dividend to state amounts',
    route: Routes.dividendToStateAmounts.MAIN,
  },
  {
    title: 'State Taxable Income Reconciliation',
    icon: 'table-data',
    description: 'See state taxable income reconciliation',
    route: Routes.stateTaxableIncomeReconciliation.MAIN,
  },
  {
    title: 'State 851 Affiliate Data',
    icon: 'table-data',
    description: 'State 851',
    route: Routes.state851AffiliateData.MAIN,
  },
  {
    title: 'Population Analysis',
    icon: 'table-data',
    description: 'Population analysis',
    route: Routes.populationAnalysis.MAIN,
  },
  {
    title: 'Raw Data',
    icon: 'table-data',
    description: 'Raw data',
    route: Routes.rawData.MAIN,
  },
  {
    title: 'Raw Data for an Entity',
    icon: 'table-data',
    description: 'Raw data for an entity',
    route: Routes.rawDataForAnEntity.MAIN,
  },
  {
    title: 'Locality Data',
    icon: 'table-data',
    description: 'Locality',
    route: Routes.localityData.MAIN,
  },
  {
    title: 'Effective Tax Rate',
    icon: 'table-data',
    description: 'Effective tax rate',
    route: Routes.effectiveTaxRate.MAIN,
  },
  {
    title: 'Effective Tax Rate - Tax Accounting',
    icon: 'table-data',
    description: 'Tax accounting',
    route: Routes.effectiveTaxRateTaxAccounting.MAIN,
  },
  {
    title: 'Entity Data',
    icon: 'table-data',
    description: 'Entity',
    route: Routes.entityData.MAIN,
  },
  {
    title: 'Task Waiting',
    icon: 'table-data',
    description: 'Task waiting',
    route: Routes.taskWaiting.MAIN,
  },
  {
    title: 'Estimate Summary',
    icon: 'table-data',
    description: 'Estimate summary',
    route: Routes.estimateSummary.MAIN,
  },
  {
    title: 'GECS Prior Year Comparison',
    icon: 'table-data',
    description: 'GECS',
    route: Routes.GECSPriorYearComparison.MAIN,
  },
  {
    title: 'Info Data Search by Value',
    icon: 'table-data',
    description: 'Info data search by value',
    route: Routes.infoDataSearchByValue.MAIN,
  },
  {
    title: 'Filing Positions',
    icon: 'table-data',
    description: 'Filing positions',
    route: Routes.filingPositions.MAIN,
  },
];

export default data;
