import { Routes } from '@common-packages/routes-definitions';

import { adminColumnDefinition } from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  LinkCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';

const defaultColumnDefinition = {
  width: 120,
  minWidth: 120,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
};

const getColumnDefinitions = ({ onDeleteIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: true,
    onDeleteIconClick,
  });

  const LinkCellRenderer = LinkCellRendererFactory({
    getText: ({ value }) => (value ? value : ''),
    getPathName: () => Routes.filingGroups.MAIN,
    getRouterState: value => ({
      filingGroupId: value,
    }),
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Filing Group Id',
      field: 'filingGroupId',
      maxWidth: 350,
      cellRenderer: LinkCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'filingGroupDesc',
      width: 650,
    },
  ];
};

export default getColumnDefinitions;
