import { ColDef } from 'ag-grid-community';

import { defaultColumnDefinitionWithFilter } from '../../../../../shared/columnDefinitions';
import { dateFormatter } from '../../../../../shared/formatters';
import config from '../../../../../config';
import { refreshStatusSourceMap } from '../../../../../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';
import { federalDataDetailsLinkRenderer } from '../../federalDataDetails/federalDataDetailsLinkRenderer';

const getRefreshStatusSourceLabel = (params: { data: { gtwRefreshStatus: number } }) =>
  refreshStatusSourceMap.get(params.data.gtwRefreshStatus) || null;

const getColumnDefinitions = (): ColDef[] => {
  const columnDefinition = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Correlation Id',
      field: 'correlationId',
      width: 300,
      cellRendererFramework: federalDataDetailsLinkRenderer(true),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Entity ID',
      field: 'orgId',
      width: 100,
      cellRendererFramework: federalDataDetailsLinkRenderer(),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Filing Group ID',
      field: 'filingGroupId',
      width: 100,
      cellRendererFramework: federalDataDetailsLinkRenderer(),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Start Time',
      field: 'firstUpdate',
      width: 150,
      valueFormatter: ({ value }: { value: null | string }) =>
        dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Last Log Time',
      field: 'lastUpdate',
      width: 150,
      valueFormatter: ({ value }: { value: null | string }) =>
        dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Refresh Status',
      field: 'refreshStatus',
      width: 100,
      valueGetter: getRefreshStatusSourceLabel,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Log Message',
      field: 'message',
      flex: 1,
    },
  ];

  return columnDefinition;
};

export default getColumnDefinitions;
