import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import { fetchFilingMembersTypes, updateFilingMembersTypes } from './types';

const filingMembersReducer = reducerFactory({
  initialState: {
    isFetchingFilingMembers: false,
    filingMembers: [],
    isUpdatingFilingMembers: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFilingMembersTypes,
      payloadKey: 'filingMembers',
      fetchingKey: 'isFetchingFilingMembers',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateFilingMembersTypes,
      fetchingKey: 'isUpdatingFilingMembers',
    }),
  },
});

export default filingMembersReducer;
