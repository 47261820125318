import PropTypes from 'prop-types';

export const flowThroughK1DetailsPropTypes = PropTypes.shape({
  taxYear: PropTypes.number,
  period: PropTypes.number,
  entityId: PropTypes.string,
  partnerId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  accountJurisdictionId: PropTypes.string,
  accountId: PropTypes.string,
  k1ItemDescription: PropTypes.string,
  begEndFlag: PropTypes.string,
  partnershipAmount: PropTypes.number,
  ownershipPercentage: PropTypes.number,
  apportionmentPercentage: PropTypes.number,
  calculatedAmount: PropTypes.number,
  adjustment: PropTypes.number,
  finalAmount: PropTypes.number,
  displayOrder: PropTypes.number,
  subCategory: PropTypes.string,
});
