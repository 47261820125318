import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdiction',
    width: 120,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Tax Year',
    field: 'taxYear',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Constant Name',
    field: 'constantId',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Constant Description',
    field: 'constantDescription',
    width: 200,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Value',
    field: 'constantValue',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'For Return Types',
    field: 'forReturnTypes',
    width: 300,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'When Calc Preference (Or if multiple, All preferences if null)',
    field: 'whenCalcPreferences',
    width: 300,
  },
];

export default columnDefinitions;
