import React from 'react';
import classNames from 'classnames';

import { CompileInfoTypes } from '../constants';

import styles from './compileInfoStatus.module.scss';

interface CompileInfoStatusProps {
  compileInfoText?: string;
}

const CompileInfoStatus = ({ compileInfoText }: CompileInfoStatusProps) => (
  <span
    className={classNames({
      [styles.infoStatus]: compileInfoText === CompileInfoTypes.EXPRESSION_NEEDED,
      [styles.successStatus]: compileInfoText === CompileInfoTypes.COMPILE_UP_TO_DATE,
      [styles.dangerStatus]:
        compileInfoText === CompileInfoTypes.COMPILE_NEEDED ||
        compileInfoText === CompileInfoTypes.FIX_COMPILE_ERRORS,
      [styles.warningStatus]: compileInfoText === CompileInfoTypes.FIX_COMPILE_WARNINGS,
    })}
  >
    {compileInfoText}
  </span>
);

export default CompileInfoStatus;
