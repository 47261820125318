import { Column, ColDef, GridApi, HeaderClassParams, ColumnApi } from 'ag-grid-community';

import getClassStringWithoutClassName from './getClassStringWithoutClassName';

const headerClassToString = (
  headerClassToTransform: ColDef['headerClass'],
  headerClassParams: HeaderClassParams,
) => {
  let headerClass = headerClassToTransform;
  if (typeof headerClass === 'function') {
    headerClass = headerClass(headerClassParams);
  }
  if (Array.isArray(headerClass)) {
    headerClass = headerClass.join(' ');
  }
  return headerClass || '';
};

const getHeaderClassParams = (
  gridApi: GridApi,
  column: Column,
  columnApi: ColumnApi,
): HeaderClassParams => ({
  api: gridApi,
  colDef: column.getColDef(),
  columnGroup: column.getParent(),
  column,
  columnApi,
  context: gridApi.__getContext(),
});

// We can't use pure css solution because:
// 1. Columns in HTML are split into different groups - pinned columns, normal columns
// 2. Columns are virtualized and only visible columns are in HTML
// 3. Columns in HTML are not sorted
// The function appends given `className` to the first visible column so that it can be identified
// as the one that shouldn't have a border on the left
const appendClassOnlyToFirstVisibleColumn = (
  gridColumns: Column[],
  className: string,
  gridApi: GridApi,
  columnApi: ColumnApi,
) => {
  const firstVisibleColumn = gridColumns.find(column => column.isVisible());
  const columnsToRemoveClass = gridColumns.filter(column => {
    if (column === firstVisibleColumn) {
      return false;
    }
    const headerClass = headerClassToString(
      column.getColDef().headerClass,
      getHeaderClassParams(gridApi, column, columnApi),
    );
    return headerClass.includes(className);
  });
  columnsToRemoveClass.forEach(column => {
    const colDef = column.getColDef();
    const headerClass = headerClassToString(
      colDef.headerClass,
      getHeaderClassParams(gridApi, column, columnApi),
    );
    colDef.headerClass = getClassStringWithoutClassName(headerClass, className);
    column.setColDef(colDef, null);
  });

  if (firstVisibleColumn) {
    const firstVisibleColumnDef = firstVisibleColumn.getColDef();
    const firstVisibleColumnDefHeaderClass = headerClassToString(
      firstVisibleColumnDef.headerClass,
      getHeaderClassParams(gridApi, firstVisibleColumn, columnApi),
    );

    // only append the class name if it isn't already there
    if (!firstVisibleColumnDefHeaderClass.includes(className)) {
      firstVisibleColumnDef.headerClass = firstVisibleColumnDefHeaderClass
        .split(' ')
        .concat([className])
        .join(' ')
        .trim();
      firstVisibleColumn.setColDef(firstVisibleColumnDef, null);
    }
  }
};

export default appendClassOnlyToFirstVisibleColumn;
