import React, { useCallback } from 'react';

import ResizableGrid from '../../../shared/displayComponents/resizableGrid/resizableGrid.component';

import columnDefinitions from './diagnosticGrid.columnDefinitions';
import { messages } from './propTypes';
import styles from './styles.module.scss';

const DiagnosticGrid = ({ messages = [] }) => {
  const resetRowHeights = useCallback(params => params.api.resetRowHeights(), []);

  return (
    <ResizableGrid
      className={styles.diagnosticGrid}
      autoMaxWidth
      shadeRows
      rowData={messages}
      columnDefs={columnDefinitions}
      onColumnVisible={resetRowHeights}
      areHeaderCellBordersEnabled
    />
  );
};

DiagnosticGrid.propTypes = {
  messages,
};

export default DiagnosticGrid;
