import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectFilingGroupId } from './store/actions';
import { filingGroupsSelector, isFetchingFilingGroupsSelector } from './store/selectors';
import { useFetchFilingGroups } from './store/hooks';
import { filingGroupPropTypes } from './propTypes';
import FilingGroupGrid from './filingGroupGrid.container';

const FilingGroupDashboard = ({
  isFetchingFilingGroups,
  filingGroups,
  selectFilingGroupId,

  hasUserPermissionsToEdit,
}) => {
  useFetchFilingGroups();

  return (
    <FilingGroupGrid
      isFetchingFilingGroups={isFetchingFilingGroups}
      filingGroups={filingGroups}
      selectFilingGroupId={selectFilingGroupId}
      hasUserPermissionsToEdit={hasUserPermissionsToEdit}
    />
  );
};

FilingGroupDashboard.propTypes = {
  isFetchingFilingGroups: PropTypes.bool.isRequired,
  filingGroups: PropTypes.arrayOf(filingGroupPropTypes).isRequired,
  selectFilingGroupId: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool,
};

export default connect(
  state => ({
    filingGroups: filingGroupsSelector(state),
    isFetchingFilingGroups: isFetchingFilingGroupsSelector(state),
  }),
  {
    selectFilingGroupId,
  },
)(FilingGroupDashboard);
