import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const formListOptionsSelector = optionsSelectorFactory({
  listSelector: ({ entity }) => entity.k1Information.filterData.forms,
  labelKey: 'pshipFormDescr',
  valueKey: 'pshipFormId',
});
export const partnerListOptionsSelector = optionsSelectorFactory({
  listSelector: ({ entity }) => entity.k1Information.filterData.partners,
  labelKey: 'partnerId',
  valueKey: 'partnerId',
});
export const isFetchingFilterData = ({ entity }) => entity.k1Information.isFetchingFilterData;

export const formFieldsSelector = createSelector(
  [({ entity }) => entity.k1Information.formFields],
  formFields => formFields.map((field, rowId) => ({ ...field, rowId })),
);

export const isFetchingFormFieldsSelector = ({ entity }) =>
  entity.k1Information.isFetchingFormFields;
export const isUpdatingFormFieldsSelector = ({ entity }) =>
  entity.k1Information.isUpdatingFormFields;
