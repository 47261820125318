import { apiAction } from '../../../shared/store/apiAction';
import * as api from '../store/api';

import {
  fetchSubmissionsTypes,
  fetchCaseListTypes,
  fetchYearSubPeriodListTypes,
  validateAndGenerateMappingForStateTransmissionTypes,
  stateTransmissionImportTypes,
} from './types';

export const fetchSubmissions = apiAction({
  types: fetchSubmissionsTypes,
  apiAction: api.fetchSubmissions,
});

export const fetchCaseList = apiAction({
  types: fetchCaseListTypes,
  apiAction: api.fetchCaseList,
});

export const fetchYearSubPeriodList = apiAction({
  types: fetchYearSubPeriodListTypes,
  apiAction: api.fetchYearSubPeriodList,
});

export const validateAndGenerateMappingForStateTransmission = ({
  file,
  submissionId,
  ttiCaseId,
  ttiYearId,
}) =>
  apiAction(
    {
      types: validateAndGenerateMappingForStateTransmissionTypes,
      apiAction: async ({ file, submissionId, ttiCaseId, ttiYearId }) => {
        const result = await api.validateAndGenerateMappingForStateTransmission({
          file,
          submissionId,
          ttiCaseId,
          ttiYearId,
        });
        return { submissionId, result };
      },
    },
    { successNotificationMessage: () => 'Mapping for state transmission successfully uploaded' },
  )({ file, submissionId, ttiCaseId, ttiYearId });

export const stateTransmissionImport = ({ file, submissionId }) =>
  apiAction(
    {
      types: stateTransmissionImportTypes,
      apiAction: async ({ file, submissionId }) => {
        const result = await api.stateTransmissionImport({ file, submissionId });
        return { submissionId, result };
      },
    },
    { successNotificationMessage: () => 'State transmission import successfully uploaded' },
  )({ file, submissionId });
