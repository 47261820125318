import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import Loading from '../../shared/displayComponents/loading.component';

class SlideInButtons extends PureComponent {
  static propTypes = {
    onTest: PropTypes.func,
    onAddParam: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    disableSubmit: PropTypes.bool.isRequired,
    submitText: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
  };

  render() {
    const {
      onTest,
      onSubmit,
      onCancel,
      onDownload,
      onAddParam,
      isBusy,
      submitText,
      disableSubmit,
      isSaving,
    } = this.props;

    return (
      <div className="slide-in-footer custom-reports-slide-in-footer fixed">
        {onAddParam && (
          <div className="buttons-row">
            <Button size="lg" kind="secondary" disabled={isBusy} onClick={onAddParam}>
              Add Parameter
            </Button>
          </div>
        )}
        {onTest && (
          <div className="buttons-row">
            <Button size="lg" kind="secondary" disabled={isBusy} onClick={onTest}>
              Test Query
            </Button>
          </div>
        )}
        <div className="buttons-row">
          <Button size="lg" kind="secondary" disabled={isBusy} onClick={onDownload}>
            Download Report Definition
          </Button>
        </div>
        <div className="buttons-row">
          <Button size="lg" disabled={isBusy || disableSubmit} onClick={onSubmit}>
            <Loading small isLoading={isSaving}>
              {submitText}
            </Loading>
          </Button>
          <Button size="lg" gray onClick={onCancel} disabled={isBusy}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default SlideInButtons;
