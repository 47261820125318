import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import getFieldValue from '../../../utils/getFieldValue';
import { SELECT_PERIOD, SELECT_TAX_YEAR } from '../../../shared/store/types';

import {
  fetchGlobalInformationTypes,
  updateGlobalInformationTypes,
  fetchAllPartnershipsTypes,
  SELECT_PARTNERSHIP_ID,
  fetchPartnershipInformationTypes,
  updatePartnershipInformationTypes,
} from './types';

export const initialState = {
  globalInformation: [],
  isFetchingGlobalInformation: false,
  isUpdatingGlobalInformation: false,

  partnerships: [],
  isFetchingPartnerships: false,
  partnershipId: null,
};

const entityInformationReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchGlobalInformationTypes,
      payloadKey: 'globalInformation',
      fetchingKey: 'isFetchingGlobalInformation',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnershipInformationTypes,
      payloadKey: 'globalInformation',
      fetchingKey: 'isFetchingGlobalInformation',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateGlobalInformationTypes,
      fetchingKey: 'isUpdatingGlobalInformation',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updatePartnershipInformationTypes,
      fetchingKey: 'isUpdatingGlobalInformation',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAllPartnershipsTypes,
      payloadKey: 'partnerships',
      fetchingKey: 'isFetchingPartnerships',
      getAdditionalState: (state, payload) => ({
        // Set initial partnershipId
        partnershipId: getFieldValue({
          currentValueFieldName: 'partnershipId',
          payloadFieldName: 'partnershipId',
          state,
          payload,
        }),
      }),
      initialAdditionalState: {
        partnershipId: null,
      },
    }),

    [SELECT_PARTNERSHIP_ID]: (state, payload) => ({
      ...state,
      partnershipId: payload,
    }),
    [SELECT_TAX_YEAR]: state => ({
      ...state,
      globalInformation: [],
    }),
    [SELECT_PERIOD]: state => ({
      ...state,
      globalInformation: [],
    }),
  },
});

export default entityInformationReducer;
