export const Colors = {
  LIGHT: '#FEB791',
  MEDIUM: '#FD9359',
  DARK: '#FD6412',

  BLUE: '#4DACF1',
  GREEN: '#86DB4F',
  YELLOW: '#FFC83D',
  PURPLE: '#B056F6',

  GRAD_LIGHT: '#B3DCF9',
  GRAD_MID1: '#9DCDF5',
  GRAD_MID2: '#86BDF1',
  GRAD_MID3: '#70AEEC',
  GRAD_MID4: '#5A9EE8',
  GRAD_MID5: '#438FE4',
  GRAD_MID6: '#2D7FE0',
  GRAD_MID7: '#1670DB',
  GRAD_DARK: '#0060D7',
};

export const SELECT_OPTIONS = [
  { value: null, label: 'No Selections' },
  { value: true, label: 'Checked' },
  { value: false, label: 'Unchecked' },
];
