import React from 'react';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import { currencyCellFormatter } from '../../../shared/formatters';
import { defaultColumnDefinition } from '../../../shared/columnDefinitions';
import {
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../../shared/columnDefinitions/cellRenderers';
import { categoriesForGenericCategory } from '../../../taxSummaries/propertySummary/constants';
import { findRouteNamespace } from '../utils';

const amountFormatter = props => {
  if (props.data.rowInfo.isEmptyRow) {
    return '';
  }

  return currencyCellFormatter({ value: props.value });
};

const amountColumnsDefinition = {
  cellClassRules: {
    'underlined-cell': ({ data }) => data.rowInfo.dataUnderlined,
    'double-underlined-cell': ({ data }) => data.rowInfo.dataDoubleUnderlined,
  },
  cellStyle: {
    textAlign: 'right',
  },
  valueFormatter: amountFormatter,
  width: 150,
};

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  sortable: false,
};

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted || '',
});

const getColumnDefinitions = ({ match }) => {
  const { path, params } = match;
  const namespace = findRouteNamespace(path);

  const categoryRendererFactory = categoryIdKey => {
    const Link = props => {
      const isCatDescInCategoriesForGenericCategory = Object.values(
        categoriesForGenericCategory,
      ).some(category => category === props.data.catDesc);

      if (isCatDescInCategoriesForGenericCategory) {
        const GenericCategoryLink = LinkCellRendererFactory({
          getText: ({ valueFormatted }) => valueFormatted,
          getPathName: (value, data) =>
            Routes[`${namespace}GenericCategoryFromPropertySummary`].compiledRoute({
              categoryId: data[categoryIdKey],
              taxName: params.taxName,
            }),
        });
        return <GenericCategoryLink {...props} />;
      }
      return <TextRenderer {...props} />;
    };

    Link.propTypes = {
      data: PropTypes.shape({
        catDesc: PropTypes.string,
      }).isRequired,
    };

    return Link;
  };

  const columnDefinitions = [
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Account',
      field: 'catDesc',
      width: 200,
    },
    {
      ...extendedDefaultColumnDefinition,
      ...amountColumnsDefinition,
      headerName: 'Within',
      field: 'catWithin',
      cellRenderer: categoryRendererFactory('categoryId'),
    },
    {
      ...extendedDefaultColumnDefinition,
      ...amountColumnsDefinition,
      headerName: 'Everywhere',
      field: 'catEverywhere',
      cellRenderer: categoryRendererFactory('everywhereCategoryId'),
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
