import { FilingMethods } from '../../enums';

export const getIsReturnStatusEditable = ({
  filingMethod,
  efileStatus,
}: {
  filingMethod?: FilingMethods | null;
  efileStatus?: number | null;
}) =>
  filingMethod &&
  (filingMethod === FilingMethods.PAPER || (filingMethod === FilingMethods.EFILE && !efileStatus));
