import React, { useCallback } from 'react';
import { Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { globalContextSelector } from '../../../shared/store/selectors';
import { QueryKeys } from '../../../shared/queries';
import { BinderFilingId, GlobalContext, SltBinderId } from '../../../../../common/types';

type RefreshButtonProps = {
  isDisabled: boolean;
  binderFilingId: BinderFilingId | null;
  setIsFailSelectedSubstepButtonDisabled: (isDisabled: boolean) => void;
  sltBinderId?: SltBinderId;
};

const RefreshButton = ({
  isDisabled,
  binderFilingId,
  setIsFailSelectedSubstepButtonDisabled,
  sltBinderId,
}: RefreshButtonProps) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const queryClient = useQueryClient();

  const handleRefreshButtonClick = useCallback(() => {
    setIsFailSelectedSubstepButtonDisabled(true);
    queryClient.resetQueries([QueryKeys.EFileSupport.BinderSteps, { binderFilingId }]);
    if (sltBinderId) {
      queryClient.resetQueries([QueryKeys.EFileV2.GetSteps, { globalContext, sltBinderId }]);
    }
  }, [
    queryClient,
    binderFilingId,
    setIsFailSelectedSubstepButtonDisabled,
    globalContext,
    sltBinderId,
  ]);

  return (
    <Button size="lg" disabled={isDisabled} onClick={handleRefreshButtonClick} kind="secondary">
      REFRESH SUBSTEP DATA
    </Button>
  );
};

export default RefreshButton;
