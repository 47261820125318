import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';

import {
  fetchCustomReportsTypes,
  fetchCustomReportTypes,
  runCustomReportTypes,
  CLEAN_AFTER_CUSTOM_REPORT,
  CLEAN_AFTER_RUN_CUSTOM_REPORT,
} from './types';

const runCustomReportsReducer = reducerFactory({
  initialState: {
    customReports: [],
    isFetchingCustomReports: false,
    customReport: null,
    isFetchingCustomReport: false,
    paramFields: {},
    customReportResult: null,
    isFetchingCustomReportResult: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchCustomReportsTypes,
      payloadKey: 'customReports',
      fetchingKey: 'isFetchingCustomReports',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchCustomReportTypes,
      payloadKey: 'customReport',
      fetchingKey: 'isFetchingCustomReport',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: runCustomReportTypes,
      payloadKey: 'customReportResult',
      fetchingKey: 'isFetchingCustomReportResult',
    }),
    [CLEAN_AFTER_RUN_CUSTOM_REPORT]: state => ({
      ...state,
      customReportResult: null,
    }),
    [CLEAN_AFTER_CUSTOM_REPORT]: state => ({
      ...state,
      paramFields: {},
      customReportResult: null,
      customReport: null,
    }),
  },
});

export default runCustomReportsReducer;
