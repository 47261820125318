import { AdminCellRendererFactory } from '../shared/columnDefinitions/cellRenderers';
import { adminColumnDefinition } from '../shared/columnDefinitions';
import { defaultGridWrapperId } from '../shared/displayComponents/agGrid/agGrid.component';
import ParagraphTooltipRender from '../shared/columnDefinitions/cellRenderers/ParagraphTooltip.component';

const defaultColumnDefinition = {
  cellStyle: {
    textAlign: 'left',
  },
  suppressMenu: false,
};

const consolidationIdsRender = ({ value }) => {
  return ParagraphTooltipRender({ value: value.join(', '), containerid: defaultGridWrapperId });
};

const getColumnDefinitions = ({
  onEditIconClick,
  onDeleteIconClick,
  shouldShowEditIcon,
  shouldShowDeleteIcon,
}) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onEditIconClick,
    onDeleteIconClick,
    shouldShowEditIcon,
    shouldShowDeleteIcon,
    shouldDisableDeleteIcon: ({ consolidationId }) => consolidationId.length,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Filing Group',
      field: 'filingGroupId',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
      cellRenderer: ParagraphTooltipRender,
      width: 350,
      maxWidth: 350,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Consolidation ID',
      field: 'consolidationId',
      cellRenderer: consolidationIdsRender,
      width: 350,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
