import { defaultColumnDefinitionWithFilter } from '../../../../../shared/columnDefinitions';
import { dateFormatter } from '../../../../../shared/formatters';
import config from '../../../../../config';
import { refreshStatusSourceMap } from '../../../../../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';

const getRefreshStatusSourceLabel = (params: { data: { refreshStatus: number } }) =>
  refreshStatusSourceMap.get(params.data.refreshStatus) || null;

const ColumnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Correlation Id',
    field: 'correlationId',
    width: 300,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Entity ID',
    field: 'orgId',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Updated Time',
    field: 'updatedOn',
    width: 150,
    valueFormatter: ({ value }: { value: null | string }) =>
      dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Refresh Status',
    field: 'refreshStatus',
    width: 100,
    valueGetter: getRefreshStatusSourceLabel,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Last Message',
    field: 'message',
    flex: 1,
  },
];

export default ColumnDefinitions;
