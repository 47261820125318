import { ColDef, NewValueParams } from 'ag-grid-community';

import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../shared/columnDefinitions';
import StateOverrideRendererFramework from '../../shared/displayComponents/stateOverrideRenderer/stateOverrideRendererFramework.component';
import ConditionalSelectionListEditorSelectorFactory from '../../shared/displayComponents/editorSelector/conditionalSelectionListEditorSelector.factory';
import { GlobalK1InfoData } from '../../../../common/types';
import { IconCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({
  isInEditMode,
  updateRow,
}: {
  isInEditMode: boolean;
  updateRow: (data: GlobalK1InfoData) => void;
}): ColDef[] => {
  const onCellValueChanged = ({ data }: NewValueParams) => updateRow(data);

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Page No',
      field: 'pageNumber',
      width: 50,
      cellStyle: {
        'text-align': 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'infoAccountId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'accountDescription',
      width: 500,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Value',
      field: 'value',
      width: 200,
      editable: isInEditMode,
      cellRenderer: StateOverrideRendererFramework,
      cellEditorSelector: ConditionalSelectionListEditorSelectorFactory(),
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Is State Override',
      field: 'isStateOverride',
      width: 100,
      cellRendererFramework: IconCellRendererFactory({
        getName: isAccountGlobal => (isAccountGlobal ? 'check-mark' : ''),
      }),
      cellStyle: {
        'text-align': 'center',
      },
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
