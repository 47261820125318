import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../shared/store/storeHelpers';

export const profileOptionsSelector = optionsSelectorFactory({
  listSelector: ({ filingDecisions }) => filingDecisions.profileOptions,
  labelKey: 'profileId',
  valueKey: 'profileId',
});
export const isFetchingProfileOptionsSelector = ({ filingDecisions }) =>
  filingDecisions.isFetchingProfileOptions;

export const consolidationFilingGroupOptionsSelector = optionsSelectorFactory({
  listSelector: ({ filingDecisions }) => filingDecisions.consolidationFilingGroupOptions,
  labelKey: 'groupDescription',
  valueKey: 'filingGroupId',
});
export const isFetchingConsolidationFilingGroupOptionsSelector = ({ filingDecisions }) =>
  filingDecisions.isFetchingConsolidationFilingGroupOptions;

const mapFilingDecisionsToOrgIdOptions = value => ({ value: value.orgId, label: value.orgName });

const consolidationFilingDecisions = ({ filingDecisions }) =>
  filingDecisions.consolidationFilingDecisions;
export const consolidationFilingDecisionsSelector = createSelector(
  [consolidationFilingDecisions],
  filingDecisions => filingDecisions.map((filingDecision, rowId) => ({ rowId, ...filingDecision })),
);
export const isFetchingConsolidationFilingDecisionsSelector = ({ filingDecisions }) =>
  filingDecisions.isFetchingConsolidationFilingDecisions;

export const orgIdAsOptionsSelector = createSelector(
  [consolidationFilingDecisions],
  filingDecisions => filingDecisions.map(mapFilingDecisionsToOrgIdOptions),
);

export const orgIdsWithoutNexusNotesAsOptionsSelector = createSelector(
  [consolidationFilingDecisions],
  filingDecisions =>
    filingDecisions.filter(value => !value.hasNexusNotes).map(mapFilingDecisionsToOrgIdOptions),
);

export const isUpdatingConsolidationFilingDecisionsSelector = ({ filingDecisions }) =>
  filingDecisions.isUpdatingConsolidationFilingDecisions;

export const entityFilingDecisionsSelector = ({ filingDecisions }) =>
  filingDecisions.entityFilingDecisions;
export const isFetchingEntityFilingDecisionsSelector = ({ filingDecisions }) =>
  filingDecisions.isFetchingEntityFilingDecisions;
export const isUpdatingEntityFilingDecisionsSelector = ({ filingDecisions }) =>
  filingDecisions.isUpdatingEntityFilingDecisions;

export const isFetchingAmountDetailsSelector = ({ filingDecisions }) =>
  filingDecisions.isFetchingAmountDetails;
export const amountDetailsSelector = ({ filingDecisions }) => filingDecisions.amountDetails;

export const nexusNoteSelector = ({ filingDecisions }) => filingDecisions.nexusNote;
export const isFetchingNexusNoteSelector = ({ filingDecisions }) =>
  filingDecisions.isFetchingNexusNote;
