import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Collapse, Checkbox } from '@pwc/appkit-react';
import { JobStatus } from '@common-packages/shared-constants';

import AppkitIcon from '../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import Loading from '../../../shared/displayComponents/loading.component';

import ModalButtonsGroup from './modalButtonsGroup.component';
import styles from './propagateModelModal.module.scss';

const PropagationForm = ({
  taxYear,
  sourceJurisdiction,
  dataModel,
  includeExpressions,
  toggleIncludeExpressions,
  handleCheckboxClick,
  propagationData,
  data,
  hideModal,
  isSubmitting,
  handlePropagateSubmit,
  isThereNoDestinationJurisdictionsSelected,
  jobData,
}) => {
  const [collapsedItems, setCollapsedItems] = useState({});

  const handleCollapse = useCallback(
    id => () => {
      setCollapsedItems(currentCollapsedItems => ({
        ...currentCollapsedItems,
        [id]: !currentCollapsedItems[id],
      }));
    },
    [],
  );

  const renderListItem = useCallback(
    ({ id, label, children, checked, disabled, indeterminate }, keys = []) => {
      const hasChildren = Boolean(Object.keys(children || {}).length);

      return (
        <React.Fragment key={id}>
          <ListItem>
            <Checkbox
              id={`propagate-checkbox-${id}`}
              className={indeterminate ? styles.checkboxIndeterminate : ''}
              checked={checked}
              disabled={disabled}
              onChange={handleCheckboxClick(keys)}
            />
            <div
              className={`${styles.listItemHeader} ${
                hasChildren ? styles.listItemHeaderClickable : ''
              }`}
              onClick={hasChildren ? handleCollapse(id) : null}
            >
              <ListItemText
                className={`${styles.listItemLabel} ${
                  disabled ? styles.listItemLabelDisabled : ''
                }`}
                primary={label}
              />
              {hasChildren && (
                <AppkitIcon
                  className={styles.chevronIcon}
                  icon={collapsedItems[id] ? 'down-chevron' : 'up-chevron'}
                  size={14}
                />
              )}
            </div>
          </ListItem>
          {hasChildren && (
            <>
              <div className={styles.separator} />
              <Collapse className={styles.listCollapse} isOpen={!collapsedItems[id]}>
                {Object.entries(children)
                  .sort(
                    ([, jurisdiction1], [, jurisdiction2]) =>
                      jurisdiction1.order - jurisdiction2.order,
                  )
                  .map(([key, value]) => renderListItem(value, [...keys, 'children', key]))}
              </Collapse>
            </>
          )}
        </React.Fragment>
      );
    },
    [handleCheckboxClick, handleCollapse, collapsedItems],
  );

  const getProgressInfo = () => {
    if (!propagationData || isSubmitting) {
      return <Loading isLoading small />;
    }
    if (!propagationData.data) {
      return 'Not Propagated';
    }

    if (propagationData.data.status === JobStatus.SUCCESS) {
      return '100%';
    }

    if (propagationData.data.status === JobStatus.FAILED) {
      return 'Failed';
    }

    return `${parseInt(jobData?.progress || 0, 10)} %`;
  };

  return (
    <div className={styles.content}>
      <div className={styles.modelParams}>
        <span>Source Tax Year</span>
        <span>{taxYear}</span>
        <span>Source Jurisdiction</span>
        <span>{sourceJurisdiction}</span>
        <span>Source Data Model</span>
        <span>{dataModel?.label}</span>
        <span className={styles.progressInfo}>Last Propagation Progress</span>
        <span className={styles.progressInfo}>{getProgressInfo()}</span>
      </div>
      <Checkbox
        className={styles.includeExpressions}
        checked={includeExpressions}
        onChange={toggleIncludeExpressions}
      >
        Include Expressions
      </Checkbox>
      <span className={styles.jurisdictionsLabel}>Destination Jurisdictions</span>
      <List className={styles.jurisdictionsList}>
        {Object.entries(data)
          .sort(([, jurisdiction1], [, jurisdiction2]) => jurisdiction1.order - jurisdiction2.order)
          .map(([key, value]) => renderListItem(value, [key]))}
      </List>
      <ModalButtonsGroup
        className={styles.buttons}
        handleSubmit={handlePropagateSubmit}
        handleCancel={hideModal}
        submitButtonLabel="Propagate"
        disabled={isThereNoDestinationJurisdictionsSelected || isSubmitting}
      />
    </div>
  );
};

PropagationForm.propTypes = {
  taxYear: PropTypes.string,
  sourceJurisdiction: PropTypes.string,
  dataModel: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  includeExpressions: PropTypes.bool.isRequired,
  toggleIncludeExpressions: PropTypes.func.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  propagationData: PropTypes.shape({
    data: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }),
  jobData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    progress: PropTypes.number,
  }),
  data: PropTypes.object, // eslint-disable-line
  hideModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handlePropagateSubmit: PropTypes.func.isRequired,
  isThereNoDestinationJurisdictionsSelected: PropTypes.bool.isRequired,
};

export default PropagationForm;
