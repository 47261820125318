import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { filingTypes } from '@tls/state-helpers';
import { ConsolidationMethodFilingTypes } from '@common-packages/shared-constants';
import { Routes } from '@common-packages/routes-definitions';
import { useSelector } from 'react-redux';
import { consolidationsSchemas } from '@common-packages/validators';

import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import Radio from '../../shared/forms/radioFormik/radioFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import DatePicker from '../../shared/forms/datePickerFormik/datePickerFormik.component';
import Loading from '../../shared/displayComponents/loading.component';
import { SelectOptionPropTypes } from '../../shared/forms/propTypes';
import {
  taxYearSelector,
  periodSelector,
  periodNameSelector,
  entitiesOptionsSelector,
  isFetchingEntitiesSelector,
  isFetchingConsolidationsSelector,
  customerPermissionsSelector,
} from '../../shared/store/selectors';
import { useQueryTaxReturnsReturnDefinitions } from '../../shared/queries/taxReturns';
import { useQueryDueDateOptions } from '../../shared/queries/dueDates';
import { useQueryFindEntityFiscalYearEnd } from '../../shared/queries/consolidations';
import { getDefaultDueDateType } from '../../shared/taxReturns/utils/getDefaultDueDateType';
import useSetFilingMethod from '../../shared/hooks/useSetFilingMethod.hook';
import hasRequiredPermissionsToEdit from '../../shared/authorization/hasRequiredPermissionsToEdit';
import ErrorsList from '../../shared/forms/error/errorsList.component';
import { useShouldDisplayReturnsDueDates } from '../../shared/hooks/useShouldDisplayReturnsDueDates.hook';
import { useManualValidateForm } from '../../shared/hooks/useManualValidateForm';

import styles from './consolidationForm.module.scss';

const noop = () => null;

const AddConsolidationForm = ({
  consolidationIdsForValidation,
  jurisdictionsOptions,
  onParentEntityChange = noop,
  isSavingConsolidation,
  isFetchingJurisdictions,
  filingGroups,
  isFetchingFilingGroups,
  onCancelClick,
  fetchDefaultConsolidationMethod,
  isFetchingDefaultConsolidationMethod,
  formErrors,
}) => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const periodName = useSelector(periodNameSelector);

  const entitiesOptions = useSelector(entitiesOptionsSelector);
  const isFetchingEntities = useSelector(isFetchingEntitiesSelector);
  const isFetchingConsolidationsForValidation = useSelector(isFetchingConsolidationsSelector);

  const customerPermissions = useSelector(customerPermissionsSelector);
  const hasTaxReturnsPermissions = hasRequiredPermissionsToEdit(
    customerPermissions,
    Routes.setupTaxReturns.MAIN,
  );
  const { isSubmitting, submitForm, values, setFieldValue, isValid, errors } = useFormikContext();

  const shouldDisplayDueDate = useShouldDisplayReturnsDueDates();

  const {
    data: returnDefinitions,
    isFetching: isFetchingReturnDefinitions,
  } = useQueryTaxReturnsReturnDefinitions({
    params: {
      taxYear,
      period,
      filingTypeId: filingTypes.CONSOLIDATED,
      jurisdictionId: values.jurisdictionId,
    },
    enabled: Boolean(taxYear && values.jurisdictionId && hasTaxReturnsPermissions),
  });

  const { data: entityFiscalYearEnd } = useQueryFindEntityFiscalYearEnd({
    params: {
      taxYear,
      period,
      businessEntityId: values?.parentOrgId,
    },
    enabled: Boolean(taxYear && period && values?.parentOrgId),
  });

  const {
    data: dueDateOptionsForConsolidation,
    isFetching: isLoadingDueDateOptionsForConsolidation,
  } = useQueryDueDateOptions({
    params: {
      taxYear,
      period,
      businessEntityId: values?.parentOrgId,
      jurisdictionId: values?.jurisdictionId,
      returnDefinitionId: values?.returnDefinitionId,
      formattedFiscalYearEnd: values?.taxYearEnding,
    },
    enabled: Boolean(
      shouldDisplayDueDate &&
        taxYear &&
        period &&
        values?.parentOrgId &&
        values?.jurisdictionId &&
        values?.returnDefinitionId &&
        values?.taxYearEnding &&
        !errors?.taxYearEnding,
    ),
  });

  useManualValidateForm(
    consolidationsSchemas.getAddConsolidationSchema({
      consolidationIds: consolidationIdsForValidation,
      shouldDisplayDueDate,
      dueDateOption: dueDateOptionsForConsolidation,
    }),
  );

  useEffect(() => {
    if (entityFiscalYearEnd) {
      setFieldValue('taxYearEnding', entityFiscalYearEnd?.fiscalYearEnd);
    }
  }, [setFieldValue, entityFiscalYearEnd]);

  const returnDefinitionsOptions = useMemo(
    () => returnDefinitions.map(({ id, name }) => ({ label: name, value: id })),
    [returnDefinitions],
  );

  const selectedReturnDefinition = useMemo(
    () => returnDefinitions.find(({ id }) => id === values.returnDefinitionId),
    [returnDefinitions, values.returnDefinitionId],
  );

  useEffect(() => {
    if (!dueDateOptionsForConsolidation?.length || !shouldDisplayDueDate) {
      return;
    }
    setFieldValue('dueDateType', getDefaultDueDateType(dueDateOptionsForConsolidation));
  }, [setFieldValue, dueDateOptionsForConsolidation, shouldDisplayDueDate]);

  const {
    isFilingMethodDisabled,
    filingMethodInfoMessage,
    filingMethodOptions,
  } = useSetFilingMethod({
    taxReturnsDefinition: selectedReturnDefinition,
  });

  const onEntityChange = useCallback(
    entity => {
      if (!entity) {
        return;
      }

      setFieldValue('parentOrgId', entity.value);
      setFieldValue('jurisdictionId', '');
      setFieldValue('proformaFilingGroupId', '');

      onParentEntityChange(entity);
    },
    [setFieldValue, onParentEntityChange],
  );

  const onJurisdictionChange = useCallback(
    async jurisdictionId => {
      setFieldValue('jurisdictionId', jurisdictionId.value);
      const { defaultConsolidationMethod } = await fetchDefaultConsolidationMethod({
        taxYear,
        period,
        jurisdictionId: jurisdictionId.value,
      });
      setFieldValue('consolidationMethod', defaultConsolidationMethod);
    },
    [setFieldValue, fetchDefaultConsolidationMethod, period, taxYear],
  );

  const buttonsDisabled =
    isFetchingConsolidationsForValidation || isSubmitting || isSavingConsolidation || !isValid;

  const consolidationMethodOptions = Object.keys(ConsolidationMethodFilingTypes).map(method => ({
    label: method,
    value: method,
  }));

  return (
    <form>
      <div>
        <Field
          className="form-text"
          label="Tax Year"
          name="taxYear"
          component={Input}
          value={taxYear}
          disabled
        />
        <Field
          className="form-text"
          label="Period"
          name="periodName"
          component={Input}
          value={periodName}
          disabled
        />
        <Loading small isLoading={isFetchingConsolidationsForValidation}>
          <Field
            className="form-text"
            label="Consolidation ID"
            name="consolidationId"
            component={Input}
          />
        </Loading>
        <Loading small isLoading={isFetchingEntities}>
          <Select
            wrapperClassName="form-text"
            appkitLabel="Parent entity"
            name="parentOrgId"
            onChange={onEntityChange}
            options={entitiesOptions}
            value={values.parentOrgId}
            virtualized
          />
        </Loading>
        <Loading small isLoading={isFetchingJurisdictions}>
          <Select
            wrapperClassName="form-text"
            appkitLabel="Jurisdiction"
            name="jurisdictionId"
            onChange={onJurisdictionChange}
            options={jurisdictionsOptions}
            value={values.jurisdictionId}
            virtualized
          />
        </Loading>
        <Field className="form-text" label="Description" name="description" component={Input} />
        <Field
          className="form-text"
          label="Tax Year Ending"
          name="taxYearEnding"
          component={DatePicker}
        />
        <div className={styles.formRow}>
          <Field
            className={`form-text ${styles.rightMargin}`}
            label="Federal As-Filed Filing Group"
            name="federalFilingGroupId"
            component={Input}
          />
          <Loading small isLoading={isFetchingFilingGroups}>
            <Select
              wrapperClassName={`form-text ${styles.rightMargin}`}
              appkitLabel="Federal Proforma Filing Group"
              name="proformaFilingGroupId"
              options={filingGroups}
              value={values.proformaFilingGroupId}
            />
          </Loading>
          <Loading small isLoading={isFetchingDefaultConsolidationMethod}>
            <Select
              wrapperClassName="form-text"
              appkitLabel="Consolidation method"
              name="consolidationMethod"
              options={consolidationMethodOptions}
              value={values.consolidationMethod}
            />
          </Loading>
        </div>
        <Field wrapperClassName="form-text" label="Active" name="active" component={Checkbox} />
        <Field
          wrapperClassName="form-text"
          label="Allow Interco."
          name="allowInterCompany"
          component={Checkbox}
        />
        {hasTaxReturnsPermissions && (
          <Loading small isLoading={isFetchingReturnDefinitions}>
            <Select
              wrapperClassName="form-text"
              appkitLabel="Filing Form Description"
              name="returnDefinitionId"
              options={returnDefinitionsOptions}
              value={values.returnDefinitionId}
            />
            <Field
              label="Filing Method"
              name="filingMethod"
              component={Radio}
              options={filingMethodOptions}
              disabled={isFilingMethodDisabled}
              infoMessage={filingMethodInfoMessage}
            />
            {shouldDisplayDueDate && (
              <Select
                appkitLabel="Set Due Date"
                appkitPlaceholder="Select a Date"
                name="dueDateType"
                options={dueDateOptionsForConsolidation || []}
                isLoading={isLoadingDueDateOptionsForConsolidation}
                useAppkit
                isAutoClearable
                shouldDisplayErrorMessage={Boolean(values?.parentOrgId)}
              />
            )}
          </Loading>
        )}
      </div>
      {formErrors?.length && <ErrorsList errors={formErrors} />}
      <div>
        <ButtonsGroup
          className={styles.spaceAbove}
          disabled={buttonsDisabled}
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={onCancelClick}
          submitButtonLabel="Save"
        />
      </div>
    </form>
  );
};

AddConsolidationForm.propTypes = {
  consolidationIdsForValidation: PropTypes.arrayOf(PropTypes.string).isRequired,
  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  onParentEntityChange: PropTypes.func,
  isSavingConsolidation: PropTypes.bool.isRequired,
  isFetchingJurisdictions: PropTypes.bool.isRequired,
  filingGroups: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingFilingGroups: PropTypes.bool.isRequired,
  fetchDefaultConsolidationMethod: PropTypes.func.isRequired,
  isFetchingDefaultConsolidationMethod: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  formErrors: PropTypes.arrayOf(PropTypes.string),
};

export default AddConsolidationForm;
