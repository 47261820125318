export enum CalcPreferencesDataTypes {
  SELECTION_LIST = 'SELECTION_LIST',
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
}

export const calcPreferencesDataTypesOptions = [
  { label: 'Selection List', value: CalcPreferencesDataTypes.SELECTION_LIST },
  { label: 'Boolean', value: CalcPreferencesDataTypes.BOOLEAN },
  { label: 'Number', value: CalcPreferencesDataTypes.NUMBER },
];
