import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '@tls/ui-modal';
import ServerSideAgGrid, { useGridServerSideDataSource } from '@tls/ui-server-side-aggrid';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { gridPivotOptions } from '../../shared/displayComponents/agGrid/constants';
import getDynamicColumnDefinitions from '../customReports/getDynamicColumnDefinitions';
import { defaultServerSideParams } from '../customReports/constants';
import { runCustomQuery as runCustomQueryApiAction } from '../store/api';

import { runCustomQuery } from './store/actions';
import {
  customQueryResultSelector,
  isFetchingCustomQueryResultSelector,
  fetchingCustomQueryResultErrorSelector,
} from './store/selectors';

const TestQueryModal = ({
  modalData,
  hideModal,
  visible,
  customQueryResult,
  isFetchingCustomQueryResult,
  fetchingCustomQueryResultError,
  runCustomQuery,
}) => {
  useEffect(() => {
    if (visible) {
      runCustomQuery({
        query: modalData.query,
        params: {
          ...(modalData.serverSide ? defaultServerSideParams : {}),
          ...modalData.params,
        },
      });
    }
  }, [modalData, runCustomQuery, visible]);

  const { rows, columns, types } = customQueryResult || {};

  const { onGridReady } = useGridServerSideDataSource({
    fetchData: async ({ limit, offset, sortModel, filterModel }) => {
      const { rows } = await runCustomQueryApiAction({
        query: modalData.query,
        serverSide: modalData.serverSide,
        params: {
          ...modalData.params,
          limit,
          offset,
          sortModel,
          filterModel,
          defaultOrderBy: modalData.defaultOrderBy,
        },
      });
      return rows;
    },
    initialRows: rows,
  });

  const columnDefinitions = useMemo(() => getDynamicColumnDefinitions(columns, types), [
    columns,
    types,
  ]);

  const renderGrid = () =>
    modalData && modalData.serverSide ? (
      <ServerSideAgGrid columnDefs={columnDefinitions} onGridReady={onGridReady} />
    ) : (
      <AgGrid
        rowData={rows}
        columnDefs={columnDefinitions}
        isGridLoading={isFetchingCustomQueryResult}
        {...gridPivotOptions}
      />
    );

  return (
    <Modal
      className="test-query-modal"
      title="Query Test"
      closeAction={hideModal}
      visible={visible}
    >
      {fetchingCustomQueryResultError
        ? 'Error occured when running the query. Make sure that the query is correct and all parameters are valid.'
        : customQueryResult && !isFetchingCustomQueryResult && renderGrid()}
    </Modal>
  );
};

TestQueryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalData: PropTypes.shape({
    query: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.string.isRequired),
    serverSide: PropTypes.bool.isRequired,
    defaultOrderBy: PropTypes.string,
  }),
  runCustomQuery: PropTypes.func.isRequired,
  customQueryResult: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  isFetchingCustomQueryResult: PropTypes.bool.isRequired,
  fetchingCustomQueryResultError: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    customQueryResult: customQueryResultSelector(state),
    isFetchingCustomQueryResult: isFetchingCustomQueryResultSelector(state),
    fetchingCustomQueryResultError: fetchingCustomQueryResultErrorSelector(state),
  }),
  {
    runCustomQuery,
  },
)(TestQueryModal);
