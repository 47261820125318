import { useCallback } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';
import { useSelector } from 'react-redux';

import { TaxReturnOption, useQueryTaxReturnOptions } from '../queries/taxReturns';
import { globalContextSelector } from '../store/selectors';

interface UseReturnsParams {
  routePath: string;
}

const useReturns = ({ routePath }: UseReturnsParams) => {
  const globalContext = useSelector(globalContextSelector);
  const location = useLocation();
  const history = useHistory();
  const pathMatched = matchPath<{ returnId: string }>(location.pathname, {
    path: routePath,
    exact: true,
  });

  const redirectToReturn = useCallback(
    (taxReturns: TaxReturnOption[]) => {
      /**
       * If a user changes the context, we want to redirect them to the first
       * return in the new list as long as the currently-being-viewed return is not in the list of returns
       */
      if (
        pathMatched &&
        !taxReturns.find(taxReturn => taxReturn.returnId.toString() === pathMatched.params.returnId)
      ) {
        const newRoute = taxReturns[0]?.returnId
          ? Routes.returnMaintenance.compiledRoute({ returnId: taxReturns[0].returnId })
          : '/';

        history.push(newRoute);
      }
    },
    [history, pathMatched],
  );

  const { data: returns, isFetching: isFetchingReturns } = useQueryTaxReturnOptions({
    params: globalContext.apiRouteParamString,
    enabled: globalContext.isReady,
    onSuccess: redirectToReturn,
  });

  return {
    returns,
    isFetchingReturns,
  };
};

export default useReturns;
