import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { ROOT_NAVIGATOR_TAB } from '../../shared/constants';

import * as api from './api';

export const fetchNavigatorGenericScreens = createAsyncThunk(
  'navigator/fetchNavigatorGenericScreens',
  api.fetchNavigatorGenericScreens,
);

export const fetchNavigatorBinderData = createAsyncThunk(
  'navigator/fetchNavigatorBinderData',
  api.fetchNavigatorBinderData,
);

export const fetchNavigatorEFileData = createAsyncThunk(
  'navigator/fetchNavigatorEFileData',
  api.fetchNavigatorEFileData,
);

const navigatorSlice = createSlice({
  name: 'navigator',
  initialState: {
    genericScreens: [],
    isFetchingGenericScreens: false,

    binderData: {
      reports: {
        label: '',
        items: [],
      },
      forms: {
        label: '',
        items: [],
      },
      attachments: {
        label: '',
        items: [],
      },
    },
    isFetchingBinderData: false,

    eFileData: {
      isEFiling: null,
      isEFileReady: null,
    },
    isFetchingEFileData: false,

    activeTab: ROOT_NAVIGATOR_TAB.NAVIGATOR,
  },
  reducers: {
    clearNavigatorGenericScreens(state) {
      state.genericScreens = [];
    },
    setActiveTab(state, { payload }) {
      state.activeTab = payload;
    },
  },
  extraReducers: {
    [fetchNavigatorGenericScreens.fulfilled]: (state, { payload }) => {
      state.genericScreens = payload;
      state.isFetchingGenericScreens = false;
    },
    [fetchNavigatorGenericScreens.pending]: state => {
      state.isFetchingGenericScreens = true;
    },
    [fetchNavigatorGenericScreens.rejected]: state => {
      state.isFetchingGenericScreens = false;
    },

    [fetchNavigatorBinderData.fulfilled]: (state, { payload }) => {
      state.binderData = payload;
      state.isFetchingBinderData = false;
    },
    [fetchNavigatorBinderData.pending]: state => {
      state.isFetchingBinderData = true;
    },
    [fetchNavigatorBinderData.rejected]: state => {
      state.isFetchingBinderData = false;
    },

    [fetchNavigatorEFileData.fulfilled]: (state, { payload }) => {
      state.eFileData = payload;
      state.isFetchingEFileData = false;
    },
    [fetchNavigatorEFileData.pending]: state => {
      state.isFetchingEFileData = true;
    },
    [fetchNavigatorEFileData.rejected]: state => {
      state.isFetchingEFileData = false;
    },
  },
});

const { actions, reducer } = navigatorSlice;

export const { clearNavigatorGenericScreens, setActiveTab } = actions;

export const navigatorGenericScreensSelector = ({ navigator }) => navigator.genericScreens;
export const isFetchingNavigatorGenericScreensSelector = ({ navigator }) =>
  navigator.isFetchingGenericScreens;

export const navigatorBinderDataSelector = ({ navigator }) => navigator.binderData;
export const isFetchingNavigatorBinderDataSelector = ({ navigator }) =>
  navigator.isFetchingBinderData;

export const navigatorEFileDataSelector = ({ navigator }) => navigator.eFileData;
export const isFetchingNavigatorEFileDataSelector = ({ navigator }) =>
  navigator.isFetchingEFileData;

export const activeTabSelector = ({ navigator }) => navigator.activeTab;

export default reducer;
