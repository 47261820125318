import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import { fetchConsolidationFilingAttributesTypes, fetchEntityFilingAttributesTypes } from './types';

export const fetchEntityFilingAttributes = apiAction({
  types: fetchEntityFilingAttributesTypes,
  apiAction: api.fetchEntityFilingAttributes,
});

export const fetchConsolidationFilingAttributes = apiAction({
  types: fetchConsolidationFilingAttributesTypes,
  apiAction: api.fetchConsolidationFilingAttributes,
});
