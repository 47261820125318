import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import getFieldValue from '../../utils/getFieldValue';

import {
  fetchFlowThroughK1DetailsTypes,
  fetchPartnersTypes,
  SELECT_PARTNER_ID,
  updateFlowThroughK1DetailsTypes,
} from './types';

export const initialState = {
  flowThroughK1Details: [],
  isFetchingFlowThroughK1Details: false,

  partners: [],
  isFetchingPartners: false,
  partnerId: null,
  isUpdatingFlowThroughK1Details: false,
};

const flowThroughK1DetailsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFlowThroughK1DetailsTypes,
      payloadKey: 'flowThroughK1Details',
      fetchingKey: 'isFetchingFlowThroughK1Details',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnersTypes,
      payloadKey: 'partners',
      fetchingKey: 'isFetchingPartners',
      getAdditionalState: (state, payload) => ({
        partnerId: getFieldValue({
          currentValueFieldName: 'partnerId',
          payloadFieldName: 'partnerId',
          state,
          payload,
        }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateFlowThroughK1DetailsTypes,
      fetchingKey: 'isUpdatingFlowThroughK1Details',
    }),
    [SELECT_PARTNER_ID]: (state, payload) => ({
      ...state,
      partnerId: payload,
    }),
  },
});

export default flowThroughK1DetailsReducer;
