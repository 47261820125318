import { httpGetAndParse } from '../../../utils/fetchService';

export const fetchCustomReports = ({ reportType }) =>
  httpGetAndParse({
    route: `/api/admin/custom-reports/by-report-type/${reportType}`,
    errorMessage: 'Fetching custom reports failed',
  });

export const fetchCustomReport = ({ id }) =>
  httpGetAndParse({
    route: `/api/admin/custom-reports/${id}`,
    errorMessage: 'Fetching custom report failed',
  });

export const runCustomReport = ({ reportId, params }) => {
  const encodedParams = encodeURIComponent(JSON.stringify({ params }));
  return httpGetAndParse({
    route: `/api/admin/custom-reports/run/${reportId}/${encodedParams}`,
    errorMessage: 'Running report failed',
  });
};
