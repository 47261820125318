import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import { BreadcrumbRoute } from '../../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';
import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

const GenericScreenDefinitionBreadcrumbRoutes = () => {
  const { queryParams } = useUrlParams();
  const search = new URLSearchParams(queryParams).toString();
  return (
    <>
      <BreadcrumbRoute search={search} path={Routes.genericScreenDefinition} />
      <BreadcrumbRoute path={Routes.genericScreenDefinitionRowDetails} />
    </>
  );
};

export default GenericScreenDefinitionBreadcrumbRoutes;
