import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import omit from 'lodash.omit';
import { Routes } from '@common-packages/routes-definitions';
import { Button } from '@pwc/appkit-react';

import Loading from '../../shared/displayComponents/loading.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import Dropzone from '../../shared/forms/dropzone/dropzone.container';
import { taxYearSelector, periodSelector } from '../../shared/store/selectors';
import { loadDataFromExcelFileBuffer } from '../shared/excel.utils';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import {
  useMutationUploadFilingGroupMembers,
  useMutationValidateFilingGroupMembers,
} from '../../shared/mutations/filingGroupMembersUpload';
import styles from '../shared/dataImportPage.module.scss';

import columnDefinitions from './filingGroupMembersUpload.columnDefinitions';

const DOCUMENT_ACCEPTED_TYPES = ['.xlsx'];
const EXCEL_COLUMN_NAMES = ['filingGroupId', 'orgId'];

const convertRowDataToStrings = rowData =>
  rowData.map(({ orgId, filingGroupId }) => ({
    orgId: orgId && `${orgId}`,
    filingGroupId: filingGroupId && `${filingGroupId}`,
  }));

const FilingGroupMembersUpload = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [isParsingExcelFile, setIsParsingExcelFile] = useState(false);
  const [rowData, setRowData] = useState([]);

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const isUploadValid = rowData.length && rowData.every(({ isValid }) => isValid);

  const {
    mutateAsync: uploadFilingGroupMembers,
    isLoading: isUploadingFilingGroupMembers,
  } = useMutationUploadFilingGroupMembers();

  const {
    mutateAsync: validateFilingGroupMembers,
    isLoading: isValidatingFilingGroupMembers,
  } = useMutationValidateFilingGroupMembers();

  const onDrop = useCallback(file => {
    setFile(file);
    setIsParsingExcelFile(true);
  }, []);

  const onFileBufferLoaded = useCallback(
    async fileBuffer => {
      const rows = await loadDataFromExcelFileBuffer({
        fileBuffer,
        excelColumnNames: EXCEL_COLUMN_NAMES,
      });
      setIsParsingExcelFile(false);
      const validatedRows = await validateFilingGroupMembers({
        rows: convertRowDataToStrings(rows),
        taxYear,
        period,
      });
      setRowData(validatedRows);
    },
    [validateFilingGroupMembers, taxYear, period],
  );

  const handleUpload = useCallback(async () => {
    dispatch(
      showConfirmModal({
        title: 'Replace Filing Group Members',
        text: `Members included in the upload file will replace the existing filing group member list. Are you sure you want to continue?`,
        confirmCallback: async () => {
          await uploadFilingGroupMembers({
            rows: convertRowDataToStrings(rowData).map(row => omit(row, ['isValid', 'result'])),
            taxYear,
            period,
          });
          setRowData([]);
          setFile(null);
        },
      }),
    );
  }, [uploadFilingGroupMembers, dispatch, rowData, taxYear, period]);

  return (
    <>
      <div className="row">
        <div className="col">
          <Dropzone
            className={styles.dropzone}
            value={file}
            onDropCallback={onDrop}
            onFileBufferLoaded={onFileBufferLoaded}
            acceptTypes={DOCUMENT_ACCEPTED_TYPES}
          />
        </div>
      </div>
      <div className="row">
        <div className={`col ${styles.fileFormat}`}>
          <div className={styles.fileFormatHeader}>File Format:</div>
          <div>filing_group_id, org_id</div>
        </div>
      </div>
      <div className="row">
        <div className="col add-button-column">
          <Link to={{ pathname: Routes.complianceTools.MAIN }} className={styles.cancelLink}>
            <Button size="lg" className="add-button" kind="secondary">
              Cancel
            </Button>
          </Link>
          <Button
            size="lg"
            className="add-button"
            disabled={
              !isUploadValid ||
              isParsingExcelFile ||
              isValidatingFilingGroupMembers ||
              isUploadingFilingGroupMembers
            }
            onClick={handleUpload}
          >
            <Loading isLoading={isUploadingFilingGroupMembers} small>
              Upload
            </Loading>
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={rowData}
            columnDefs={columnDefinitions}
            isGridLoading={isParsingExcelFile || isValidatingFilingGroupMembers}
          />
        </div>
      </div>
    </>
  );
};

export default FilingGroupMembersUpload;
