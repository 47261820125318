import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { defaultSideBarWithColumnsToolPanel } from '../../../../shared/displayComponents/agGrid/constants';

import getColumnDefinitions from './compileExpressionsGrid.columnDefinitions';
import { compilationExpressionMessage } from './compileExpressionsGrid.propTypes';

const CompileExpressionsGrid = ({
  compilationResult = null,
  isLoading,
  areAllJurisdictionsSelected = false,
  onCellClicked: handleCellClicked = () => null,
  openDataItem,
}) => {
  const columnDefinitions = useMemo(() => getColumnDefinitions(areAllJurisdictionsSelected), [
    areAllJurisdictionsSelected,
  ]);

  const getContextMenuItems = useCallback(
    params => [
      {
        name: 'Open DataItem',
        disabled: isLoading,
        action: () => {
          openDataItem(params.node.data);
        },
      },
      'separator',
      'copy',
      'copyWithHeaders',
      'export',
    ],
    [isLoading, openDataItem],
  );

  return (
    <AgGrid
      rowData={compilationResult || []}
      columnDefs={columnDefinitions}
      isGridLoading={isLoading}
      sideBar={defaultSideBarWithColumnsToolPanel}
      areHeaderCellBordersEnabled
      autoMaxWidth
      onCellClicked={handleCellClicked}
      getContextMenuItems={getContextMenuItems}
    />
  );
};

CompileExpressionsGrid.propTypes = {
  compilationResult: PropTypes.arrayOf(compilationExpressionMessage),
  isLoading: PropTypes.bool.isRequired,
  areAllJurisdictionsSelected: PropTypes.bool,
  onCellClicked: PropTypes.func,
  openDataItem: PropTypes.func.isRequired,
};

export default CompileExpressionsGrid;
