import React from 'react';
import { Button } from '@pwc/appkit-react/lib/Button';
import PropTypes from 'prop-types';

import { REFRESH_PAGE_MESSAGE } from './constants';

const refreshApp = () => {
  window.location.reload();
};

const InitializationError = ({ errorMessage }) => (
  <div className="general-info-page page-without-secondary-menu page-content-container">
    <div className="general-info-text">{`${errorMessage} ${REFRESH_PAGE_MESSAGE}`}</div>
    <div className="general-button-wrapper">
      <Button size="lg" onClick={refreshApp}>
        REFRESH
      </Button>
    </div>
  </div>
);

InitializationError.propTypes = {
  errorMessage: PropTypes.string,
};

export default InitializationError;
