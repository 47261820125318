import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { partnershipInformationSchemas } from '@common-packages/validators';
import { Routes } from '@common-packages/routes-definitions';

import Loading from '../../shared/displayComponents/loading.component';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import { entityIdSelector, periodSelector, taxYearSelector } from '../../shared/store/selectors';
import { isFetchingContextSelector } from '../store/selectors';

import { addPartnershipInfo, fetchPartnerships } from './store/actions';
import {
  partnershipInfoTypesSelector,
  partnershipsSelector,
  isFetchingPartnershipsSelector,
} from './store/selectors';
import AddPartnershipInformationForm from './AddPartnershipInformationForm.component';
import './AddPartnershipInformation.style.scss';

const formIntitialValues = {
  partnershipId: '',
  partnershipTypeCode: 'PSHIP',
  partnershipName: '',
  parentId: '',
  ownerPercentage: '0',
  percentOwned: '0',
  percentYearOwned: '0',
  percentEffectiveOwnership: '0',
};

const AddPartnershipInformation = ({
  history,
  taxYear,
  period,
  entityId,
  isFetchingContext,
  partnershipInfoTypes,
  addPartnershipInfo,
  partnerships,
  isFetchingPartnerships,
  fetchPartnerships,
}) => {
  const redirectToReviewPartnership = useCallback(
    () => history.push(Routes.reviewPartnershipInformation.MAIN),
    [history],
  );

  const savePartnershipInfo = useCallback(
    async (
      {
        partnershipId,
        ownerPercentage,
        percentYearOwned,
        percentOwned,
        percentEffectiveOwnership,
        partnershipTypeCode,
      },
      { setSubmitting },
    ) => {
      await addPartnershipInfo({
        taxYear,
        period,
        ownerId: entityId,
        partnershipId,
        ownerPercentage,
        percentYearOwned,
        percentOwned,
        percentEffectiveOwnership,
        partnershipTypeCode,
      });
      setSubmitting(false);
      redirectToReviewPartnership();
    },
    [taxYear, period, entityId, addPartnershipInfo, redirectToReviewPartnership],
  );

  const renderForm = useCallback(
    formikProps => (
      <AddPartnershipInformationForm
        {...formikProps}
        ownerId={entityId}
        onCancelClick={redirectToReviewPartnership}
        partnershipInfoTypes={partnershipInfoTypes}
        fetchPartnerships={fetchPartnerships}
        partnerships={partnerships}
        isFetchingPartnerships={isFetchingPartnerships}
        taxYear={taxYear}
        period={period}
      />
    ),
    [
      entityId,
      fetchPartnerships,
      redirectToReviewPartnership,
      isFetchingPartnerships,
      partnershipInfoTypes,
      partnerships,
      period,
      taxYear,
    ],
  );

  return (
    <div className="row">
      <div className="col-xl-4 col-lg-6 offset-xl-4 offset-lg-3 add-partnership-information">
        <Loading isLoading={isFetchingContext}>
          <Formik
            initialValues={formIntitialValues}
            validateOnBlur={false}
            validationSchema={partnershipInformationSchemas.commonSchema}
            onSubmit={savePartnershipInfo}
          >
            {renderForm}
          </Formik>
        </Loading>
      </div>
    </div>
  );
};

AddPartnershipInformation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  isFetchingContext: PropTypes.bool.isRequired,
  partnershipInfoTypes: PropTypes.arrayOf(SelectOptionPropTypes),
  addPartnershipInfo: PropTypes.func.isRequired,
  partnerships: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
      entityTypeDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingPartnerships: PropTypes.bool.isRequired,
  fetchPartnerships: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    isFetchingContext: isFetchingContextSelector(state),
    partnershipInfoTypes: partnershipInfoTypesSelector(state),
    partnerships: partnershipsSelector(state),
    isFetchingPartnerships: isFetchingPartnershipsSelector(state),
  }),
  {
    addPartnershipInfo,
    fetchPartnerships,
  },
)(AddPartnershipInformation);
