import React, { useCallback, ReactNode } from 'react';

import downloadFile from '../../../utils/downloadFile';

interface DownloadLinkProps {
  url: string;
  children?: ReactNode;
  fileLocator?: string;
  fileName?: string;
}

const DownloadLink = ({
  fileLocator,
  url,
  fileName,
  children = null,
  ...props
}: DownloadLinkProps) => {
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      downloadFile({
        route: url,
        fileLocator,
        fileName,
      });
    },
    [url, fileLocator],
  );

  return (
    <a href="/" onClick={onClick} {...props}>
      {children}
    </a>
  );
};

export default DownloadLink;
