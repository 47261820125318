import { createSelector } from 'reselect';

export const directPartnershipInfoSelector = createSelector(
  [({ entity }) => entity.partnershipInformation.directPartnershipInfo],
  directPartnershipInfo =>
    directPartnershipInfo.map((directPartnership, rowId) => ({
      ...directPartnership,
      rowId,
    })),
);

export const isFetchingDirectPartnershipInfoSelector = ({ entity }) =>
  entity.partnershipInformation.isFetchingDirectPartnershipInfo;

export const indirectPartnershipInfoSelector = ({ entity }) =>
  entity.partnershipInformation.indirectPartnershipInfo;

export const isFetchingIndirectPartnershipInfoSelector = ({ entity }) =>
  entity.partnershipInformation.isFetchingIndirectPartnershipInfo;

export const partnershipsSelector = ({ entity }) => entity.partnershipInformation.partnerships;

export const isFetchingPartnershipsSelector = ({ entity }) =>
  entity.partnershipInformation.isFetchingPartnerships;

export const partnershipInfoTypesSelector = ({ entity }) =>
  entity.partnershipInformation.partnershipInfoTypes;

export const isUpdatingPartnershipsInfoSelector = ({ entity }) =>
  entity.partnershipInformation.isUpdatingPartnershipsInfo;
