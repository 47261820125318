import { FindReturnDuedateOptionsResponse } from '../../../../../common/types';
import { DueDateOptionsDesc } from '../../enums';

const emptyOptionValue = null;

export const getDefaultDueDateType = (dueDateOptions?: FindReturnDuedateOptionsResponse) => {
  if (!dueDateOptions) {
    return emptyOptionValue;
  }
  const extendedDueDateOption = dueDateOptions?.find(
    option => option.description === DueDateOptionsDesc.ExtendedDueDate,
  );
  const dueDateOption = dueDateOptions?.find(
    option => option.description === DueDateOptionsDesc.DueDate,
  );
  const defaultOption = extendedDueDateOption || dueDateOption;

  return defaultOption?.value || emptyOptionValue;
};
