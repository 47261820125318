import React, { useMemo } from 'react';

import { FederalEntity } from '../../../../../../../common/types';
import AgGrid from '../../../../../shared/displayComponents/agGrid/agGrid.component';
import { TabProps } from '../../types';

import getColumnDefinitions from './allFederalEntities.columnDefinitions';

const AllFederalEntities = ({ entities, isFetchingEntities, onSelectionChanged }: TabProps) => {
  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);
  const getRowId = useMemo(() => ({ data }: { data: FederalEntity }) => data?.orgId, []);

  return (
    <>
      <AgGrid
        rowData={entities}
        columnDefs={columnDefinitions}
        withSearchBar
        autoMaxWidth
        isGridLoading={isFetchingEntities}
        rowSelection={'multiple'}
        onSelectionChanged={onSelectionChanged}
        getRowId={getRowId}
      />
    </>
  );
};

export default AllFederalEntities;
