import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../../../shared/hooks/useFetch.hook';
import { fetchCalculationFunctions as fetchCalculationFunctionsApiAction } from '../../store/api';
import { defaultSideBarWithColumnsToolPanel } from '../../../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import styles from '../../styles.module.scss';
import { AgGridIds } from '../constants';

import columnDefinitions from './calculationFunctions.columnDefinitions';

const CalculationFunctions = ({
  addFunctionSepExpression,
  addFunctionConsolExpression,
  generateContextMenuItems,
}) => {
  const {
    data: calculationFunctions,
    fetch: fetchCalculationFunctions,
    isFetching: isFetchingCalculationFunctions,
  } = useFetch({
    action: fetchCalculationFunctionsApiAction,
  });

  useEffect(() => {
    fetchCalculationFunctions();
  }, [fetchCalculationFunctions]);

  const onFunctionSelect = useCallback(
    ({ data }) => {
      if (data) {
        addFunctionSepExpression({
          functionName: data.functionName,
        });
      }
    },
    [addFunctionSepExpression],
  );

  const onConsolFunctionSelect = useCallback(
    ({ data, event }) => {
      if (event.metaKey || event.ctrlKey) {
        addFunctionConsolExpression({
          functionName: data.functionName,
        });
      }
    },
    [addFunctionConsolExpression],
  );

  const contextMenuItems = useMemo(
    () =>
      generateContextMenuItems(
        ({ data }) => {
          addFunctionSepExpression({
            functionName: data.functionName,
          });
        },
        ({ data }) => {
          addFunctionConsolExpression({
            functionName: data.functionName,
          });
        },
      ),
    [generateContextMenuItems, addFunctionSepExpression, addFunctionConsolExpression],
  );

  return (
    <div className={`row grid-row ${styles.calculationGridContainer}`}>
      <div className="col">
        <AgGrid
          rowData={calculationFunctions}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingCalculationFunctions}
          onCellClicked={onConsolFunctionSelect}
          onCellDoubleClicked={onFunctionSelect}
          withSearchBar
          sideBar={defaultSideBarWithColumnsToolPanel}
          areHeaderCellBordersEnabled
          getContextMenuItems={contextMenuItems}
          saveGridStateId={AgGridIds.FUNCTIONS}
        />
      </div>
    </div>
  );
};

CalculationFunctions.propTypes = {
  addFunctionSepExpression: PropTypes.func.isRequired,
  addFunctionConsolExpression: PropTypes.func.isRequired,
  generateContextMenuItems: PropTypes.func.isRequired,
};

export default CalculationFunctions;
