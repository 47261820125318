import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import globalContextPropTypes from '../../../shared/propTypes/globalContext';
import { ParamTypeValue, ContextItemValue } from '../../../shared/reports/enums';
import getQueryParams from '../../../utils/getQueryParams';
import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import styles from '../../../shared/reports/reportParam.module.scss';

import DropdownField from './dropdownField.container';

const extractDependentParamsValues = (currentParams, values, query) => {
  const queryParams = getQueryParams(query);
  const dependentParams = currentParams.filter(param => queryParams.includes(param.mappingName));

  return dependentParams.reduce(
    (params, param) => Object.assign(params, { [param.mappingName]: values[param.mappingName] }),
    {},
  );
};

const UserDefinedParams = ({
  globalContext,
  reportId,
  queryParams,
  onChange,
  values,
  setFieldValue,
  setValues,
  getAreParamsReady,
}) => {
  const contextValues = {
    [ContextItemValue.TAX_YEAR]: globalContext.taxYear,
    [ContextItemValue.PERIOD]: globalContext.period,
    [ContextItemValue.JURISDICTION_ID]: globalContext.jurisdictionId,
    [ContextItemValue.ENTITY_ID]: globalContext.isSeparate ? globalContext.entityId : null,
    [ContextItemValue.CONSOLIDATION_ID]: globalContext.isConsolidated
      ? globalContext.consolidationId
      : null,
  };
  useEffect(() => {
    const initialValues = queryParams.reduce(
      (acc, { mappingName, type, contextItem }) => ({
        ...acc,
        [mappingName]:
          acc[mappingName] || (type === ParamTypeValue.INPUT && contextValues[contextItem]) || null,
      }),
      {},
    );
    setValues(initialValues);
  }, []);

  useEffect(() => {
    if (getAreParamsReady(values, queryParams)) {
      onChange(values);
    }
  }, [getAreParamsReady, onChange, values, queryParams]);

  return (
    <div className={`global-context ${styles.paramsContainer}`}>
      {queryParams.map(param =>
        param.type === ParamTypeValue.INPUT ? (
          <Field
            key={param.name}
            label={param.name}
            name={param.mappingName}
            component={Input}
            autoComplete="off"
            placeholder="Type..."
          />
        ) : (
          <DropdownField
            reportId={reportId}
            key={param.name}
            param={param}
            dependentParams={extractDependentParamsValues(queryParams, values, param.query)}
            value={values[param.mappingName]}
            handleChange={setFieldValue}
            defaultValue={contextValues[param.contextItem]}
          />
        ),
      )}
    </div>
  );
};

UserDefinedParams.propTypes = {
  globalContext: globalContextPropTypes,
  reportId: PropTypes.number.isRequired,
  queryParams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      mappingName: PropTypes.string.isRequired,
      contextItem: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  getAreParamsReady: PropTypes.func.isRequired,
};

export default withFormik({})(UserDefinedParams);
