import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';

import { useQueryConsolidationGlobalInfo } from '../../shared/queries/consolidationInfo';
import { GlobalConsolidationData } from '../../../../common/types';
import { useRowEditMode } from '../../shared/editMode';
import {
  useMutationUpdateGlobalConsolidationInfo,
  useMutationCopyGlobalInfomationalDataFromParentToConsolidation,
} from '../../shared/mutations/consolidationInfo';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  taxYearSelector,
  periodSelector,
  jurisdictionIdSelector,
  globalOrFirstConsolidationIdSelector,
} from '../../shared/store/selectors';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import hasTaxYearEnding from '../../entity/entityInformation/utils/hasTaxYearEnding';

import getColumnDefinitions from './consolidationInfo.columnDefinitions';

interface ConsolidationInfoUpdates {
  rowsPairsWithChanges: [{ oldRow: GlobalConsolidationData; newRow: GlobalConsolidationData }];
}

const getUniqueRowId = ({ data }: { data: { infoAccountId: string } }) => data.infoAccountId;

const ConsolidationInfo = ({ hasUserPermissionsToEdit }: { hasUserPermissionsToEdit: boolean }) => {
  const { mutateAsync: updateGlobalConsolidationInfo } = useMutationUpdateGlobalConsolidationInfo();
  const {
    mutateAsync: copyGlobalInfomationalDataFromParentToConsolidation,
    isLoading: isCopyingGlobalInfomationalDataFromParentToConsolidation,
  } = useMutationCopyGlobalInfomationalDataFromParentToConsolidation();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const consolidationId = useSelector(globalOrFirstConsolidationIdSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const { data, isFetching } = useQueryConsolidationGlobalInfo({
    enabled: Boolean(taxYear && period && consolidationId && jurisdictionId),
    params: {
      taxYear,
      period,
      consolidationId,
    },
  });

  const saveChanges = useCallback(
    async (updates: ConsolidationInfoUpdates) => {
      const values = updates.rowsPairsWithChanges.map(changes => changes.newRow);

      await updateGlobalConsolidationInfo({
        taxYear,
        period,
        consolidationId,
        values,
        jurisdictionId,
      });
    },
    [updateGlobalConsolidationInfo, taxYear, period, consolidationId, jurisdictionId],
  );

  const onCopyGlobalInfomationalDataFromParentToConsolidationClick = useCallback(async () => {
    copyGlobalInfomationalDataFromParentToConsolidation({
      taxYear,
      period,
      consolidationId,
    });
  }, [copyGlobalInfomationalDataFromParentToConsolidation, taxYear, period, consolidationId]);

  const { isInEditMode, editModeButtons, updateRow, clonedRowData, onGridReady } = useRowEditMode({
    onSave: saveChanges,
    rowData: data,
    getUniqueRowId,
    editButtonDisabled: isFetching,
    saveButtonDisabled: hasTaxYearEnding,
    isFetchAllRows: true,
  });

  const columnDefinitions = useMemo(() => getColumnDefinitions({ isInEditMode, updateRow }), [
    isInEditMode,
    updateRow,
  ]);

  return (
    <>
      {hasUserPermissionsToEdit && (
        <div className="add-button-column">
          <Button
            size="lg"
            disabled={isCopyingGlobalInfomationalDataFromParentToConsolidation}
            onClick={onCopyGlobalInfomationalDataFromParentToConsolidationClick}
          >
            Copy Global Info Data From Parent to Consolidation
          </Button>
          {editModeButtons}
        </div>
      )}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetching}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            withSearchBar
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            stopEditingWhenCellsLoseFocus
          />
        </div>
      </div>
    </>
  );
};

export default ConsolidationInfo;
