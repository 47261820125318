const {
  TAX_YEAR_2020,
  TAX_YEAR_2021,
  TAX_YEAR_2022,
  ANNUAL_PERIOD,
  EXTENSION_PERIOD,
  ESTIMATION_PERIODS,
} = require('@common-packages/shared-constants');

const isTaxYearBefore2020 = taxYear => Boolean(taxYear) && Number(taxYear) < TAX_YEAR_2020;

const isTaxYearAfterOr2021 = taxYear => Boolean(taxYear) && Number(taxYear) >= TAX_YEAR_2021;

const isTaxYearAfterOr2022 = taxYear => Boolean(taxYear) && Number(taxYear) >= TAX_YEAR_2022;

const isTaxYear2022OrBefore = taxYear => Boolean(taxYear) && Number(taxYear) <= TAX_YEAR_2022;

const isTaxYearAfter2022 = taxYear => Boolean(taxYear) && Number(taxYear) > TAX_YEAR_2022;

const isAnnualPeriod = period => Boolean(period) && String(period) === ANNUAL_PERIOD;

const isExtensionPeriod = period => Boolean(period) && String(period) === EXTENSION_PERIOD;

const isOneOfEstimationPeriods = period =>
  Boolean(period) && ESTIMATION_PERIODS.includes(String(period));

module.exports = {
  isTaxYearBefore2020,
  isTaxYearAfterOr2021,
  isTaxYearAfterOr2022,
  isTaxYearAfter2022,
  isAnnualPeriod,
  isExtensionPeriod,
  isOneOfEstimationPeriods,
  isTaxYear2022OrBefore,
};
