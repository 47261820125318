import React, { useCallback, useMemo } from 'react';
import { Button } from '@pwc/appkit-react';
import { CellValueChangedEvent, ICellEditorParams } from 'ag-grid-community';
import { useDispatch } from 'react-redux';

import SlideIn from '../../shared/displayComponents/slideIn/slideIn.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { ConsolidatedGroupReturn } from '../../shared/queries/filingGroups';
import {
  defaultColumnDefinitionWithFilterSuppressMovable,
  mapValueFormatterFactory,
  selectCellEditorParamsFactory,
} from '../../shared/columnDefinitions';
import { selectOptionCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import { defaultAgRichSelectCellEditorSelector } from '../../shared/columnDefinitions/cellEditor';
import { useRowEditMode } from '../../shared/editMode';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import Loading from '../../shared/displayComponents/loading.component';
import {
  updateReturnFilingMethodOptions,
  updateReturnFilingMethodOptionsWithoutEfile,
} from '../../shared/constants';

import styles from './updateReturnsConfigPanel.module.scss';

type UpdateReturnsConfigPanelProps = {
  data: ConsolidatedGroupReturn[];
  isLoading: boolean;
  isPanelVisible: boolean;
  hidePanel: () => void;
  handleModifiedReturns: (rows: ConsolidatedGroupReturn[]) => void;
  setToBeDeletedFilingGroupId: React.Dispatch<React.SetStateAction<string>>;
  isSaving: boolean;
};

const getUniqueRowId = ({ data: { returnId } }: { data: ConsolidatedGroupReturn }) => returnId;

export const UpdateReturnsConfigPanel = ({
  data,
  isLoading,
  isPanelVisible,
  hidePanel,
  handleModifiedReturns,
  setToBeDeletedFilingGroupId,
  isSaving,
}: UpdateReturnsConfigPanelProps) => {
  const dispatch = useDispatch();

  const {
    updateRow,
    clonedRowData: clonedUpdateReturnsData,
    onGridReady: onUpdateReturnsGridReady,
    findChanges,
    revertChanges,
  } = useRowEditMode({
    rowData: data,
    getUniqueRowId,
    saveButtonDisabled: false,
  });

  const handleClickSave = useCallback(() => {
    handleModifiedReturns(clonedUpdateReturnsData);
  }, [clonedUpdateReturnsData, handleModifiedReturns]);

  const handleClickCancel = useCallback(() => {
    if (isSaving) {
      return;
    }
    if (findChanges().rowsPairsWithChanges?.length) {
      dispatch(
        showConfirmModal({
          title: 'Member Return',
          text: `You are about to lose any changes you made. The filing group will remain the same. Do you wish to continue?`,
          confirmCallback: () => {
            setToBeDeletedFilingGroupId('');
            revertChanges();
            hidePanel();
          },
        }),
      );
    } else {
      setToBeDeletedFilingGroupId('');
      hidePanel();
    }
  }, [findChanges, revertChanges, hidePanel, dispatch, setToBeDeletedFilingGroupId, isSaving]);

  const columnDefs = useMemo(
    () => [
      {
        ...defaultColumnDefinitionWithFilterSuppressMovable,
        headerName: 'Jurisdiction',
        field: 'jurisdictionName',
        width: 140,
      },
      {
        ...defaultColumnDefinitionWithFilterSuppressMovable,
        headerName: 'Entity ID',
        field: 'entityId',
        width: 100,
      },
      {
        ...defaultColumnDefinitionWithFilterSuppressMovable,
        headerName: 'Entity Name',
        field: 'entityName',
        width: 170,
      },
      {
        ...defaultColumnDefinitionWithFilterSuppressMovable,
        headerName: 'Filing Form',
        field: 'filingForm',
        width: 120,
      },
      {
        ...defaultColumnDefinitionWithFilterSuppressMovable,
        headerName: 'Return Options',
        field: 'filingMethod',
        width: 150,
        editable: true,
        cellEditorSelector: ({ data }: ICellEditorParams) => ({
          ...defaultAgRichSelectCellEditorSelector,
          params: selectCellEditorParamsFactory(
            data?.returnDefIsEfileAllowed
              ? updateReturnFilingMethodOptions
              : updateReturnFilingMethodOptionsWithoutEfile,
            {},
            false,
          )(),
        }),
        filterParams: {
          valueFormatter: mapValueFormatterFactory(updateReturnFilingMethodOptions),
        },
        cellRenderer: selectOptionCellRendererFactory(updateReturnFilingMethodOptions),
        onCellValueChanged: ({ data }: CellValueChangedEvent) => {
          updateRow(data);
        },
      },
    ],
    [updateRow],
  );

  return (
    <SlideIn
      isOpen={isPanelVisible}
      onRequestClose={handleClickCancel}
      width="700px"
      closeIconName="close"
      title={`Update Returns`}
      titlePosition="left"
      overlayClassName="update-return-panel slide-in-overlay"
    >
      <div className={styles.updateReturnsContentContainer}>
        <p className={styles.updateReturnsContentTip}>
          {`When removing filing group members, you must decide whether to convert their non-filing returns or delete them. For each non-filing return below, choose a selection from the Return Options dropdown.`}
        </p>
        <AgGrid
          rowData={clonedUpdateReturnsData}
          columnDefs={columnDefs}
          enableRangeSelection
          enableFillHandle
          suppressClearOnFillReduction
          suppressMultiRangeSelection
          isGridLoading={isLoading}
          stopEditingWhenCellsLoseFocus
          onGridReady={onUpdateReturnsGridReady}
          fillHandleDirection="y"
        />
        <div className={styles.updateReturnsPanelButtonGroup}>
          <Button size="lg" kind="secondary" onClick={handleClickCancel} disabled={isSaving}>
            Cancel
          </Button>
          <Button size="lg" onClick={handleClickSave} disabled={isLoading}>
            <Loading isLoading={isSaving} small>
              Save
            </Loading>
          </Button>
        </div>
      </div>
    </SlideIn>
  );
};
