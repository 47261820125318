import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';

export default [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Category ID',
    field: 'columnName',
    width: 250,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Description',
    field: 'categoryDescription',
    width: 400,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Beg/End Flag',
    field: 'begEndFlag',
    width: 100,
  },
];
