import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const PULL_GENERATE_PDF_SUCCESS = 'DEVELOPMENT/PULL_GENERATE_PDF_SUCCESS';

export const fetchTaxFormTypes = getApiActionTypes('DEVELOPMENT/FETCH_TAX_FORM_V2');
export const fetchDataModelsWithGroupedDatasetsAndDataItemsTypes = getApiActionTypes(
  'DEVELOPMENT/FETCH_DATA_MODELS_WITH_GROUPED_DATASETS_AND_DATAITEMS_FOR_TAX_FORMS_V2',
);
export const fetchPdfPageDataUsageTypes = getApiActionTypes(
  'DEVELOPMENT/FETCH_PDF_PAGE_DATA_USAGE_FOR_TAX_FORMS_V2',
);
export const RESET_PDF_PAGE_DATA_USAGE = 'DEVELOPMENT/RESET_PDF_PAGE_DATA_USAGE';
export const updateTaxFormTypes = getApiActionTypes('DEVELOPMENT/UPDATE_TAX_FORM_V2');
export const deleteTaxFormTypes = getApiActionTypes('DEVELOPMENT/DELETE_TAX_FORM_V2');
