import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect, useSelector } from 'react-redux';
import { routesArray } from '@common-packages/routes-definitions';
import { Tabs, Tab } from '@pwc/appkit-react';
import PropTypes from 'prop-types';

import { customerPermissionsSelector } from '../shared/store/selectors';
import { customerPermissionsPropTypes } from '../shared/propTypes/customerPropTypes';
import config from '../config';
import { ROOT_NAVIGATOR_TAB } from '../shared/constants';

import Tree from './tree/tree.component';
import NavigatorDataProvider from './navigatorTree/navigatorDataProvider';
import NavigatorTree from './navigatorTree/navigatorTree.container';
import BinderDataProvider from './binderTree/binderDataProvider';
import BinderTree from './binderTree/binderTree.container';
import ReturnsNavigator from './returnsTree/returnsNavigator.container';
import navigationTreeAfterPermissionsApplied from './navigationTreeAfterPermissionsApplied';
import { activeTabSelector, setActiveTab } from './store';
import styles from './styles.module.scss';

const NavigatorPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById('navigator-wrapper'));
const NavigatorTabPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById('navigation-tab-wrapper'));

const tabs = [
  { type: ROOT_NAVIGATOR_TAB.NAVIGATOR, label: 'Navigator' },
  ...(config.DISPLAY_BINDERS_TAB ? [{ type: ROOT_NAVIGATOR_TAB.BINDER, label: 'Binder' }] : []),
  ...(config.DISPLAY_RETURNS_TAB ? [{ type: ROOT_NAVIGATOR_TAB.RETURNS, label: 'Returns' }] : []),
];

const GlobalContextNavigator = ({ customerPermissions, setRootActiveTab }) => {
  const activeTab = useSelector(activeTabSelector);

  const handleTabChange = useCallback(
    (_, tab) => {
      setRootActiveTab(tab);
    },
    [setRootActiveTab],
  );

  const applyPermissions = useCallback(
    tree =>
      navigationTreeAfterPermissionsApplied({
        tree,
        routes: routesArray,
        customerPermissions,
      }),
    [customerPermissions],
  );

  const renderTree = useCallback(
    props => <Tree customerPermissions={customerPermissions} {...props} />,
    [customerPermissions],
  );

  return (
    <>
      <NavigatorTabPortal>
        <div className={styles.navigatorTabWrapper}>
          <Tabs
            className={styles.navigatorTabs}
            value={activeTab}
            onChange={handleTabChange}
            size="md"
          >
            {tabs.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
        </div>
      </NavigatorTabPortal>
      <NavigatorPortal>
        <NavigatorDataProvider path="/">
          <BinderDataProvider path="/">
            {activeTab === ROOT_NAVIGATOR_TAB.NAVIGATOR && (
              <NavigatorTree
                routes={routesArray}
                applyPermissions={applyPermissions}
                renderTree={renderTree}
              />
            )}

            {activeTab === ROOT_NAVIGATOR_TAB.BINDER && (
              <BinderTree
                routes={routesArray}
                applyPermissions={applyPermissions}
                renderTree={renderTree}
              />
            )}

            {activeTab === ROOT_NAVIGATOR_TAB.RETURNS && (
              <ReturnsNavigator
                routes={routesArray}
                applyPermissions={applyPermissions}
                renderTree={renderTree}
              />
            )}
          </BinderDataProvider>
        </NavigatorDataProvider>
      </NavigatorPortal>
    </>
  );
};

GlobalContextNavigator.propTypes = {
  customerPermissions: customerPermissionsPropTypes,

  setRootActiveTab: PropTypes.func.isRequired,
};

const ConnectedGlobalContextNavigator = connect(
  state => ({
    customerPermissions: customerPermissionsSelector(state),
  }),
  { setRootActiveTab: setActiveTab },
)(GlobalContextNavigator);

export default ConnectedGlobalContextNavigator;
