import { compile } from 'path-to-regexp';
import { TOTAL_DESC } from '@common-packages/shared-constants';

import {
  defaultColumnDefinition,
  currencyColumnDefinition,
} from '../../../shared/columnDefinitions';
import {
  NumberCellEditor,
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../../shared/columnDefinitions/cellRenderers';

const isTotalRow = ({ data }) => data.accountDescription === TOTAL_DESC;

const overlinedCellClassRules = {
  'overlined-cell': isTotalRow,
  'bold-cell': isTotalRow,
};

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  sortable: false,
  cellClassRules: {
    ...defaultColumnDefinition.cellClassRules,
    ...overlinedCellClassRules,
  },
};

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const getColumnDefinitions = ({ isInEditMode, updateRow, url, location, taxYear = '' }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const DataCollectionLinkRenderer = LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: (value, data) =>
      compile(`${url}/account/:accountId`)({ accountId: data.accountId }),
    getRouterState: () => location.state,
  });

  const DataCollectionDescription = props =>
    isTotalRow(props) ? TextRenderer(props) : DataCollectionLinkRenderer(props);

  const priorYear = taxYear ? Number(taxYear) - 1 : '';

  const columnDefinitions = [
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Account',
      field: 'accountDescription',
      width: 400,
    },
    {
      ...extendedDefaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Data Collection',
      field: 'dataCollection',
      cellRenderer: DataCollectionDescription,
    },
    {
      ...extendedDefaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Adjustments',
      field: 'adjustments',
      editable: props => isInEditMode && !isTotalRow(props) && props.data.isEditable,
      cellEditor: NumberCellEditor,
      onCellValueChanged,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
    },
    {
      ...extendedDefaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: taxYear,
      field: 'currentYearValue',
    },
    {
      ...extendedDefaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: priorYear,
      field: 'priorYearValue',
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
