import { httpPost, httpPostAndParse, httpGetAndParse } from '../../../utils/fetchService';

export const fetchExpression = ({ dataItemDefId }) =>
  httpGetAndParse({
    route: `/api/development/expression-builder/${dataItemDefId}`,
    errorMessage: 'Fetching expression values failed',
  });

export const saveExpression = ({
  dataItemDefId,
  separateExpression,
  consolExpression,
  useSameExpressionForConsol,
  disallowUserOverride,
  notes,
}) =>
  httpPostAndParse({
    route: `/api/development/expression-builder/${dataItemDefId}`,
    body: {
      separateExpression,
      consolExpression,
      useSameExpressionForConsol,
      disallowUserOverride,
      notes,
    },
  });

export const saveMultipleExpressions = dirtyDataItems =>
  httpPost({
    route: '/api/development/expression-builder/multi/data-items',
    body: dirtyDataItems,
  });

export const validateExpression = ({
  dataItemDefId,
  separateExpression,
  consolExpression,
  useSameExpressionForConsol,
  notes,
}) =>
  httpPostAndParse({
    route: `/api/development/expression-builder/validation/${dataItemDefId}`,
    body: {
      separateExpression,
      consolExpression,
      useSameExpressionForConsol,
      notes,
    },
  });

export const fetchCalculationFunctions = () =>
  httpGetAndParse({
    route: `/api/development/expression-builder/calc-functions`,
    errorMessage: 'Fetching calculation functions failed',
  });

export const fetchCategories = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/expression-builder/calc-categories/${taxYear}`,
    errorMessage: 'Fetching calculation categories failed',
  });
