import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { fetchGenericSourceData } from '../../../shared/store/genericSourceData/actions';
import {
  genericSourceDataSelector,
  genericSourceDataColumnsSelector,
  isFetchingGenericSourceDataSelector,
} from '../../../shared/store/genericSourceData/selectors';
import globalContextPropTypes from '../../../shared/propTypes/globalContext';

import getColumnDefinitions from './genericSourceData.columnDefinitions';

const GenericScreen = ({
  globalContext,
  categoryId,
  accountId,
  componentId,

  genericSourceData,
  genericSourceDataColumns,
  isFetchingGenericSourceData,
  fetchGenericSourceData,
}) => {
  const { taxYear, period, businessEntityId: orgId, jurisdictionId } = globalContext.params;
  const isDataReady = categoryId && accountId;

  useEffect(() => {
    if (isDataReady) {
      fetchGenericSourceData({
        taxYear,
        period,
        orgId,
        jurisdictionId,
        categoryId,
        accountId,
        componentId,
      });
    }
  }, [
    isDataReady,
    fetchGenericSourceData,
    taxYear,
    period,
    orgId,
    jurisdictionId,
    categoryId,
    accountId,
    componentId,
  ]);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        genericSourceDataColumns,
      }),
    [genericSourceDataColumns],
  );

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={genericSourceData}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingGenericSourceData}
        />
      </div>
    </div>
  );
};

const genericSourceDataPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    accountId: PropTypes.string,
    amount: PropTypes.number,
    sourceUpdatedBy: PropTypes.string,
    sourceUpdatedPh: PropTypes.string,
    sourceUpdatedDate: PropTypes.string,
    sourceFirstName: PropTypes.string,
    sourceLastName: PropTypes.string,
    sourceMiddleInitial: PropTypes.string,
    hoLeid: PropTypes.string,
    hoReportingPeriod: PropTypes.number,
    reportingPeriod: PropTypes.number,
    cdrNo: PropTypes.string,
    scenario: PropTypes.number,
  }),
).isRequired;

const genericSourceDataColumnsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    columnName: PropTypes.string.isRequired,
    columnDesc: PropTypes.string.isRequired,
    formatFlag: PropTypes.string.isRequired,
  }),
).isRequired;

GenericScreen.propTypes = {
  globalContext: globalContextPropTypes,
  categoryId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,

  genericSourceData: genericSourceDataPropTypes,
  genericSourceDataColumns: genericSourceDataColumnsPropTypes,
  isFetchingGenericSourceData: PropTypes.bool.isRequired,
  fetchGenericSourceData: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    genericSourceData: genericSourceDataSelector(state),
    genericSourceDataColumns: genericSourceDataColumnsSelector(state),
    isFetchingGenericSourceData: isFetchingGenericSourceDataSelector(state),
  }),
  {
    fetchGenericSourceData,
  },
)(GenericScreen);
