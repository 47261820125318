import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import { columnsBlueprintPropTypes } from '../../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { globalContextSelector } from '../../shared/store/selectors';
import { clientIdSelector } from '../../shared/store/context';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { SelectOptionPropTypes } from '../../shared/forms/propTypes';
import { fetchFormsByPeriod } from '../../shared/store/actions';
import {
  taxYearSelector,
  jurisdictionIdSelector,
  isFetchingJurisdictionsSelector,
} from '../store/selectors';
import { listHierarchyItemsTypes } from '../../shared/displayComponents/listHierarchy/propTypes';
import useGridApi from '../../shared/hooks/useGridApi.hook';
import useAuthQueryString from '../../shared/hooks/useAuthQueryString.hook';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import BottomSlideIn from '../../shared/displayComponents/bottomSlideIn';
import {
  selectPDFPageDataUsageAndAttachmentConfig,
  fetchPDFFieldsByPage,
  fetchDataModels,
  fetchDatasets,
  selectDataModel,
  assignPDFDataUsage,
  fetchOverflowActionTypes,
  fetchDataItems,
  selectDataItem,
  mapPDFField,
  removePDFFieldMap,
} from '../../shared/store/dataModels/actions';
import {
  mappingFormSelector,
  dataModelsSelector,
  isFetchingDataModelsSelector,
  dataModelSelector,
  datasetsTreeSelector,
  isFetchingDatasetsSelector,
  datasetSelector,
  collapsedHierarchyListItemsSelector,
  overflowActionTypesSelector,
  pdfFieldsByPageSelector,
  isFetchingPDFFieldsByPageSelector,
  dataItemsOptionsSelector,
  isFetchingDataItemsSelector,
  dataItemSelector,
} from '../../shared/store/dataModels/selectors';

import { taxFormType } from './propTypes';
import AddEditForm from './addEditForm.component';
import getColumnDefinitions from './taxForms.columnDefinitions';
import {
  taxFormsSelector,
  taxFormSelector,
  taxFormsColumnsBlueprintSelector,
  isFetchingTaxFormsSelector,
} from './store/selectors';
import {
  fetchTaxForms,
  selectFormId,
  changeIsAddingForm,
  addTaxForm,
  updateTaxForm,
  deleteForm,
} from './store/actions';
import { profileIds, TABS_TYPES, tabsDefinitions } from './constants';

const getRowId = params => params?.data?.formId;

const TaxForms = ({
  clientId,
  globalContext,
  taxYear,
  jurisdictionId,
  isFetchingJurisdictions,

  selectFormId,
  changeIsAddingForm,
  taxForm,

  taxForms,
  fetchTaxForms,
  isFetchingTaxForms,

  taxFormsColumnsBlueprint,

  addTaxForm,
  updateTaxForm,
  deleteForm,
  fetchFormsByPeriod,

  showConfirmModal,
}) => {
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const [compactMode, setCompactMode] = useState(true);
  const { gridApi, onGridReady } = useGridApi();

  const authQueryString = useAuthQueryString();

  const isGridLoading = isFetchingTaxForms || isFetchingJurisdictions;

  const onClickCompactMode = useCallback(() => {
    setCompactMode(!compactMode);
  }, [setCompactMode, compactMode]);

  const fetchGridData = useCallback(() => {
    const isContextReady = taxYear && jurisdictionId;
    if (isContextReady) {
      fetchTaxForms({ taxYear, jurisdictionId });
    }
  }, [fetchTaxForms, jurisdictionId, taxYear]);

  useEffect(() => {
    fetchGridData();
  }, [fetchGridData]);

  useEffect(() => {
    if (gridApi && taxForm && taxForm.formId) {
      const nodeToSelect = gridApi.getRowNode(taxForm.formId);
      if (nodeToSelect) {
        nodeToSelect.setSelected(true);
      }
    }
  }, [taxForms, gridApi, taxForm]);

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete form',
        text:
          'Warning, you are about to remove this form, all related fields, all related form mappings and the form image. Note: this action does not delete SWP data. Are you sure you wish to continue?',
        confirmText: 'Yes',
        dismissText: 'No',
        confirmCallback: async () => {
          await deleteForm({
            taxYear,
            jurisdictionId,
            formId: row.formId,
            profileId: row.returnTypePartnership ? profileIds.PSHP : profileIds.DEFAULT,
          });
          fetchGridData();
          if (globalContext.isReady) {
            fetchFormsByPeriod(globalContext.params);
          }
        },
      });
    },
    [
      deleteForm,
      fetchGridData,
      fetchFormsByPeriod,
      showConfirmModal,
      globalContext.params,
      globalContext.isReady,
      taxYear,
      jurisdictionId,
    ],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: openModalForDelete,
        columnsBlueprint: taxFormsColumnsBlueprint,
        clientId,
        authQueryString,
      }),
    [openModalForDelete, taxFormsColumnsBlueprint, clientId, authQueryString],
  );

  const onAddTaxFormClick = useCallback(() => {
    selectFormId(null);
    gridApi.deselectAll();
    changeIsAddingForm(true);
    setActiveTab(TABS_TYPES.FORM);
    setCompactMode(false);
  }, [selectFormId, gridApi, setCompactMode, setActiveTab, changeIsAddingForm]);

  const renderAddButton = useCallback(
    () => (
      <Button size="lg" className="add-button" onClick={onAddTaxFormClick}>
        Add Tax Form
      </Button>
    ),
    [onAddTaxFormClick],
  );

  const handleRowSelection = useCallback(
    ({ api }) => {
      const selectedRow = api.getSelectedRows()[0];

      if (selectedRow) {
        const taxFormId = taxForm && taxForm.formId;
        changeIsAddingForm(false);

        if (taxFormId !== selectedRow.formId) {
          selectFormId(selectedRow.formId);
          setCompactMode(false);
        }
      }
    },
    [selectFormId, setCompactMode, changeIsAddingForm, taxForm],
  );

  return (
    <>
      <HeaderWithParamDropdownsWrapper
        showHeaderTaxYearDropdown
        showHeaderJurisdictionDropdown
        renderAdditionalElements={renderAddButton}
      />
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={taxForms}
            columnDefs={columnDefinitions}
            isGridLoading={isGridLoading}
            withSearchBar
            onSelectionChanged={handleRowSelection}
            onGridReady={onGridReady}
            getRowId={getRowId}
            minHeight="196px"
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
      {taxForm ? (
        <BottomSlideIn hide={onClickCompactMode} compactMode={compactMode}>
          <AddEditForm
            compactMode={compactMode}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            fetchGridData={fetchGridData}
            updateTaxForm={updateTaxForm}
            addTaxForm={addTaxForm}
          />
        </BottomSlideIn>
      ) : null}
    </>
  );
};

TaxForms.propTypes = {
  clientId: PropTypes.string.isRequired,
  fetchFormsByPeriod: PropTypes.func.isRequired,
  globalContext: globalContextPropTypes,
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingJurisdictions: PropTypes.bool.isRequired,
  taxForms: PropTypes.arrayOf(taxFormType),
  taxFormsColumnsBlueprint: columnsBlueprintPropTypes.isRequired,
  fetchTaxForms: PropTypes.func.isRequired,
  changeIsAddingForm: PropTypes.func.isRequired,
  taxForm: taxFormType,
  selectFormId: PropTypes.func.isRequired,
  isFetchingTaxForms: PropTypes.bool.isRequired,
  addTaxForm: PropTypes.func.isRequired,
  updateTaxForm: PropTypes.func.isRequired,
  deleteForm: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func.isRequired,

  selectPDFPageDataUsageAndAttachmentConfig: PropTypes.func.isRequired,
  collapsedHierarchyListItems: PropTypes.objectOf(PropTypes.bool),
  isFetchingDatasets: PropTypes.bool.isRequired,
  datasetsTree: listHierarchyItemsTypes,
  dataset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    parentId: PropTypes.string,
    contextTypeId: PropTypes.number,
  }),
  fetchDataModels: PropTypes.func.isRequired,
  fetchDatasets: PropTypes.func.isRequired,
  isFetchingDataModels: PropTypes.bool.isRequired,
  dataModels: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  dataModel: SelectOptionPropTypes,
  selectDataModel: PropTypes.func.isRequired,
  fetchOverflowActionTypes: PropTypes.func.isRequired,
  overflowActionTypes: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  fetchPDFFieldsByPage: PropTypes.func.isRequired,
  pdfFieldsByPage: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  isFetchingPDFFieldsByPage: PropTypes.bool.isRequired,
  fetchDataItems: PropTypes.func.isRequired,
  dataItem: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  dataItemsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingDataItems: PropTypes.bool.isRequired,
  selectDataItem: PropTypes.func.isRequired,
  mapPDFField: PropTypes.func.isRequired,
  removePDFFieldMap: PropTypes.func.isRequired,
  assignPDFDataUsage: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    clientId: clientIdSelector(state),
    globalContext: globalContextSelector(state),
    taxYear: taxYearSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    isFetchingJurisdictions: isFetchingJurisdictionsSelector(state),
    taxForms: taxFormsSelector(state),
    taxFormsColumnsBlueprint: taxFormsColumnsBlueprintSelector(state),
    taxForm: taxFormSelector(state),
    collapsedHierarchyListItems: collapsedHierarchyListItemsSelector(state),
    isFetchingTaxForms: isFetchingTaxFormsSelector(state),
    dataModels: dataModelsSelector(state),
    isFetchingDataModels: isFetchingDataModelsSelector(state),
    dataModel: dataModelSelector(state),
    datasetsTree: datasetsTreeSelector(state),
    isFetchingDatasets: isFetchingDatasetsSelector(state),
    dataset: datasetSelector(state),
    overflowActionTypes: overflowActionTypesSelector(state),
    pdfFieldsByPage: pdfFieldsByPageSelector(state),
    isFetchingPDFFieldsByPage: isFetchingPDFFieldsByPageSelector(state),
    dataItemsOptions: dataItemsOptionsSelector(state),
    isFetchingDataItems: isFetchingDataItemsSelector(state),
    dataItem: dataItemSelector(state),
    mappingForm: mappingFormSelector(state),
  }),
  {
    selectPDFPageDataUsageAndAttachmentConfig,
    fetchTaxForms,
    addTaxForm,
    updateTaxForm,
    showConfirmModal,
    deleteForm,
    fetchDataModels,
    fetchDatasets,
    fetchFormsByPeriod,
    selectDataModel,
    fetchOverflowActionTypes,
    fetchPDFFieldsByPage,
    mapPDFField,
    removePDFFieldMap,
    assignPDFDataUsage,
    fetchDataItems,
    selectDataItem,
    selectFormId,
    changeIsAddingForm,
  },
)(TaxForms);
