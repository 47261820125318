import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Button } from '@pwc/appkit-react/lib/Button';

import useModal from '../../shared/hooks/useModal.hook';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';

import PartnershipsModal from './partnershipsModal/partnershipsModal.component';

const AddPartnershipInformationForm = ({
  onCancelClick,
  isSubmitting,
  ownerId,
  values,
  partnershipInfoTypes,
  submitForm,
  setFieldValue,
  partnerships,
  isFetchingPartnerships,
  fetchPartnerships,
  taxYear,
  period,
}) => {
  const { showModal, modalProps } = useModal();
  const selectPartnership = useCallback(
    partnershipId => setFieldValue('partnershipId', partnershipId),
    [setFieldValue],
  );

  return (
    <>
      <form>
        <Field
          label="Owner"
          name="ownerId"
          value={ownerId || ''}
          className="form-text add-partnership-information-field"
          disabled
          component={Input}
          autoComplete="off"
        />
        <Field
          label="Partnership Id"
          name="partnershipId"
          className="form-text add-partnership-information-field"
          component={Input}
          autoComplete="off"
          readOnly
          suffix={
            <Button onClick={showModal}>
              <AppkitIcon icon="search" size={12} />
            </Button>
          }
        />
        <Select
          wrapperClassName="add-partnership-information-field"
          appkitLabel="Partnership Type"
          name="partnershipTypeCode"
          options={partnershipInfoTypes}
          value={values.partnershipTypeCode}
        />
        <Field
          className="form-text add-partnership-information-field"
          label="Owner Percentage"
          name="ownerPercentage"
          type="number"
          component={Input}
          autoComplete="off"
        />
        <Field
          className="form-text add-partnership-information-field"
          label="Percentage Owned"
          name="percentOwned"
          type="number"
          component={Input}
          autoComplete="off"
        />
        <Field
          className="form-text add-partnership-information-field"
          label="Percent of Year Owned"
          name="percentYearOwned"
          type="number"
          component={Input}
          autoComplete="off"
        />
        <Field
          className="form-text add-partnership-information-field"
          label="Effective Ownership Percentage"
          name="percentEffectiveOwnership"
          type="number"
          component={Input}
          autoComplete="off"
        />
        <ButtonsGroup
          isSubmitting={isSubmitting || !ownerId}
          submitForm={submitForm}
          onCancelClick={onCancelClick}
          submitButtonLabel="Add"
        />
      </form>
      <PartnershipsModal
        {...modalProps}
        fetchPartnerships={fetchPartnerships}
        partnerships={partnerships}
        isFetchingPartnerships={isFetchingPartnerships}
        taxYear={taxYear}
        period={period}
        selectPartnership={selectPartnership}
      />
    </>
  );
};

AddPartnershipInformationForm.propTypes = {
  values: PropTypes.shape({
    parentId: PropTypes.string.isRequired,
    partnershipId: PropTypes.string.isRequired,
    partnershipName: PropTypes.string.isRequired,
    partnershipTypeCode: PropTypes.string.isRequired,
    ownerPercentage: PropTypes.string.isRequired,
    percentEffectiveOwnership: PropTypes.string.isRequired,
    percentOwned: PropTypes.string.isRequired,
    percentYearOwned: PropTypes.string.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  ownerId: PropTypes.string,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  partnershipInfoTypes: PropTypes.arrayOf(SelectOptionPropTypes),
  partnerships: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
      entityTypeDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingPartnerships: PropTypes.bool.isRequired,
  fetchPartnerships: PropTypes.func.isRequired,
  taxYear: PropTypes.string,
  period: PropTypes.string,
};

export default AddPartnershipInformationForm;
