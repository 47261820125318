import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { ReturnDefinitionValues } from '../addEditReturnDefinitionPanel.container';
import { FederalReturnAttachments } from '../../../../shared/enums';

const useSetFilingMethodsAllowed = () => {
  const { setValues, values } = useFormikContext<ReturnDefinitionValues>();
  const [isEfileAllowedFieldDisabled, setIsEfileAllowedFieldDisabled] = useState(false);
  const [isPaperAllowedFieldDisabled, setIsPaperAllowedFieldDisabled] = useState(false);

  useEffect(() => {
    if (values.sltSchemaId === '') {
      // If sltSchemaId is '', the user selected "None" in the dropdown, meaning the return is for paper filing instead of efile.
      // Disable the E-File allowed checkbox, reset E-file attribute fields, and automatically select the Paper Allowed field
      if (values.isEfileAllowed || !values.isPaperAllowed) {
        setValues({
          ...values,
          isEfileAllowed: false,
          isPaperAllowed: true,
          isEfileReady: false,
          hasMandate: false,
          failureToEfilePenalty: '',
          federalReturnAttachment: FederalReturnAttachments.NONE,
          isFederalXmlRequired: false,
          isFederalAsFiledRequested: false,
        });
      }

      setIsEfileAllowedFieldDisabled(true);
      setIsPaperAllowedFieldDisabled(true);
    } else if (values.sltSchemaId) {
      // If sltSchemaId is not '' and is not null, the user selected an E-File schema from the dropdown, meaning the return must at least support E-file
      // Automatically select and disable the E-File checkbox, and ensure that the Paper Allowed checkbox is enabled but unselected, allowing the user to also select Paper allowed as well.
      if (!values.isEfileAllowed) {
        setValues({
          ...values,
          isEfileAllowed: true,
          federalReturnAttachment:
            values.federalReturnAttachment || FederalReturnAttachments.UNLINKED,
        });
      }

      setIsEfileAllowedFieldDisabled(true);
      setIsPaperAllowedFieldDisabled(false);
    }
  }, [setIsEfileAllowedFieldDisabled, setIsPaperAllowedFieldDisabled, setValues, values]);

  return {
    isEfileAllowedFieldDisabled,
    isPaperAllowedFieldDisabled,
  };
};

export default useSetFilingMethodsAllowed;
