import React, { useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ButtonCellRendererFactory = (handleButtonClick: (data: any) => void) => {
  const ButtonCellRenderer = ({ value, node: { data } }: ICellRendererParams) => {
    const handleOnClick = useCallback(() => {
      handleButtonClick(data);
    }, [data]);

    return (
      <button className="a-btn a-btn-transparent a-btn-sm" onClick={handleOnClick}>
        {value}
      </button>
    );
  };

  return ButtonCellRenderer;
};

export default ButtonCellRendererFactory;
