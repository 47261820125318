import flatten from 'lodash.flatten';
import { filingTypes } from '@tls/state-helpers';
import { NEW_YORK_JURISDICTION_ID } from '@common-packages/tti';
import { TAX_YEAR_2020 } from '@common-packages/shared-constants';

import {
  EFILE_ACTION,
  EFILE_GENERATE_XML_STEPS,
  EFILE_AUTHORIZE_STEPS,
  EFILE_TRANSMIT_STEPS,
  EFILE_REVIEW_STATUS_STEPS,
} from './constants';

export const getEFServiceStepsData = ({ initiateStepsValues, eFileStepsData }) =>
  initiateStepsValues &&
  eFileStepsData.filter(
    ({ efSubmissionId }) =>
      String(efSubmissionId) === String(initiateStepsValues.efServiceSubmissionId),
  );

export const getInitiateStepsData = ({ initiateValues, initiateStepsValues, eFileStepsData }) =>
  initiateValues &&
  initiateStepsValues &&
  eFileStepsData.filter(
    ({ ttiYearId, ttiCaseId, efSubmissionId }) =>
      String(ttiYearId) === String(initiateValues.ttiYearId) &&
      String(ttiCaseId) === String(initiateValues.ttiCaseId) &&
      String(efSubmissionId) === String(initiateStepsValues.efServiceSubmissionId),
  );

export const getGenerateEFileXmlStepData = ({
  initiateValues,
  initiateStepsValues,
  eFileStepsData,
  binderId: selectedBinderId,
}) =>
  (
    getInitiateStepsData({
      initiateValues,
      initiateStepsValues,
      eFileStepsData,
    }) || []
  ).filter(({ binderId }) => selectedBinderId === binderId);

export const getTransmitStepData = ({
  initiateValues,
  initiateStepsValues,
  eFileStepsData,
  binderId: selectedBinderId,
  stateSubmissionType: selectedStateSubmissionType,
}) =>
  (
    getInitiateStepsData({
      initiateValues,
      initiateStepsValues,
      eFileStepsData,
    }) || []
  ).filter(
    ({ binderId, stateSubmissionType }) =>
      selectedBinderId === Number(binderId) &&
      (selectedStateSubmissionType === null ||
        stateSubmissionType === selectedStateSubmissionType ||
        stateSubmissionType === null),
  );

export const getReviewStatusStepData = ({
  eFileStepsData,
  ttiYearId,
  ttiCaseId,
  submissionId,
  ttiSubmissionId,
}) =>
  eFileStepsData.filter(
    stepData =>
      [...Object.keys(EFILE_REVIEW_STATUS_STEPS['check-transmission-status'])].includes(
        stepData.stepName,
      ) &&
      String(stepData.ttiYearId) === String(ttiYearId) &&
      String(stepData.ttiCaseId) === String(ttiCaseId) &&
      String(stepData.efSubmissionId) === String(submissionId) &&
      String(stepData.ttiSubmissionId) === String(ttiSubmissionId),
  );

export const getActionData = (stepData, step) =>
  stepData && stepData.find(({ stepName }) => stepName === step);

export const getGenerateTransmissionSteps = ({ taxYear, filingTypeId, jurisdictionId }) => ({
  'create-state-transmission-file': {
    ...(filingTypeId === filingTypes.CONSOLIDATED &&
    jurisdictionId === NEW_YORK_JURISDICTION_ID &&
    Number(taxYear) >= TAX_YEAR_2020
      ? {
          [EFILE_ACTION.CONFIRM_NEW_YORK_FORM]: 'Attest CT-3-A/BC Forms Have Been Filed',
        }
      : {}),
    [EFILE_ACTION.CREATE_STATE_TRANSMISSION]: 'Create state transmission file',
    [EFILE_ACTION.DOWNLOAD_AND_REVIEW_TRANSMISSION_FILE_ERRORS]:
      'Download and Review Transmission File Errors',
    [EFILE_ACTION.DOWNLOAD_SIGNATURE_DECLARATION]: 'Download Signature Declaration',
  },
});

const getWizardsStepContent = steps => flatten(Object.values(steps).map(Object.keys));

export const getTopLevelStepsWithContent = ({ taxYear, filingTypeId, jurisdictionId }) => ({
  1: getWizardsStepContent(EFILE_GENERATE_XML_STEPS),
  2: getWizardsStepContent(getGenerateTransmissionSteps({ taxYear, filingTypeId, jurisdictionId })),
  3: getWizardsStepContent(EFILE_AUTHORIZE_STEPS),
  4: getWizardsStepContent(EFILE_TRANSMIT_STEPS),
  5: getWizardsStepContent(EFILE_REVIEW_STATUS_STEPS),
});
