import { createSlice } from '@reduxjs/toolkit';

const filingGroupsSlice = createSlice({
  name: 'filingGroups',
  initialState: {
    filingGroupId: null,
  },
  reducers: {
    setFilingGroupId(state, { payload: { filingGroupId } }) {
      state.filingGroupId = filingGroupId;
    },
  },
});

const { actions, reducer } = filingGroupsSlice;

export const { setFilingGroupId } = actions;

export const filingGroupIdSelector = ({ filingGroups }) => filingGroups.filingGroupId;

export default reducer;
