import React, { ReactNode } from 'react';
import { Location } from 'history';
import RouterNavigationPrompt from 'react-router-navigation-prompt';
import Modal from '@tls/ui-modal';

const compareOnlyPathName = (prevLocation?: Location, nextLocation?: Location) =>
  (prevLocation && prevLocation.pathname) !== (nextLocation && nextLocation.pathname);

interface NavigationPromptProps {
  show: boolean;
  title?: string;
  message?: ReactNode;
  dismissText?: string;
  submitText?: string;
}

const NavigationPrompt = ({
  show,
  title = 'Do you want to leave this page?',
  message = <span>Changes you made may not be saved.</span>,
  dismissText = 'Stay',
  submitText = 'Leave',
}: NavigationPromptProps) => {
  if (show) {
    return (
      <RouterNavigationPrompt when={compareOnlyPathName} renderIfNotActive>
        {({ onConfirm, onCancel, isActive }) => (
          <Modal
            title={title}
            closeAction={onCancel}
            dismissText={dismissText}
            dismissAction={onCancel}
            submitText={submitText}
            submitAction={onConfirm}
            visible={isActive}
          >
            {message}
          </Modal>
        )}
      </RouterNavigationPrompt>
    );
  }

  return null;
};

export default NavigationPrompt;
