import { TabsTypes } from './enums';

export const TABS_DEFINITIONS = [
  { type: TabsTypes.TAX_RATES, label: 'Tax Rates' },
  { type: TabsTypes.CONSTANTS, label: 'Constants' },
];

export const TaxRatesDescription = {
  GR: 'Gross Receipts',
  NM_TAX: 'NM Tax',
  LF: 'OH Litter Franchise',
  F: 'Franchise Tax',
  I: 'Income Tax',
  O: 'Other Tax',
  CAT: 'CAT',
  NET_WORTH_TAX: 'Net Worth Tax',
  ME_TAX: 'ME Tax',
  FIXED_MIN: 'Fixed Min Tax',
  AMT: 'Alt Min Tax',
  S: 'Surcharge Tax',
  NJ_GR: 'NJ Gross Receipts',
  IL_RPLTAX: 'IL Replacement Tax',
  NJ_GP: 'NJ Gross Profit',
  MN_MIN_FEE: 'MN Min Fee',
  NY_FIXED_MIN: 'NY Fixed Min',
  LI: 'OH Litter Income',
  VT_MIN_TAX: 'VT Min Tax',
  AMTBANK: 'AMT Bank',
  FDM: 'Fixed Dollar Min',
};

export const FILING_TYPE_SEPARATE = 'FILING_TYPE_SEPARATE';
