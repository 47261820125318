import { useEffect } from 'react';

import useFetch from '../shared/hooks/useFetch.hook';
import { httpGetAndParse } from '../utils/fetchService';

const action = globalContext =>
  httpGetAndParse({
    route: `/api/shared/view-print-attachment-details/attachment-details/${globalContext.apiRouteParamString}`,
    errorMessage: 'Fetching attachment details failed',
  });

const createUseFetchViewPrintAttachmentDetailsHook = useFetch => {
  const useFetchAttachmentDetails = globalContext => {
    const {
      data: attachmentDetails,
      isFetching: isFetchingAttachmentDetails,
      fetch: fetchAttachmentDetails,
    } = useFetch({ action });

    useEffect(() => {
      if (globalContext?.isReady) {
        fetchAttachmentDetails(globalContext);
      }
    }, [fetchAttachmentDetails, globalContext]);

    return {
      attachmentDetails,
      isFetchingAttachmentDetails,
    };
  };
  return useFetchAttachmentDetails;
};

export default createUseFetchViewPrintAttachmentDetailsHook(useFetch);
