import { defaultColumnDefinitionWithFilter } from '../../../../../shared/columnDefinitions';
import getColumnBlueprintBasedColumnDefinitions from '../../../../../shared/columnDefinitions/columnsBlueprint.columnDefinition';

const defaultColumnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Constant ID',
    field: 'constantId',
    pinned: true,
    lockPinned: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Filing Attributes',
    field: 'filingAttributes',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Description',
    field: 'description',
    width: 400,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Value',
    field: 'value',
    width: 70,
  },
];

export default columnsBlueprint => [
  ...defaultColumnDefinitions,
  ...getColumnBlueprintBasedColumnDefinitions({ columnsBlueprint }),
];
