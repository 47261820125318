import { combineReducers } from 'redux';

import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import { SELECT_TAX_YEAR, SELECT_PERIOD } from '../../shared/store/types';
import getFieldValue from '../../utils/getFieldValue';
import filingMembersReducer from '../filingMembers/store/reducer';

import {
  fetchFilingGroupsTypes,
  fetchFilingGroupsByFilingTypeTypes,
  SELECT_FILING_GROUP_ID,
  SELECT_JURISDICTION_ID_FOR_FILING_GROUP,
  fetchAllJurisdictionsTypes,
} from './types';

const sharedStateFilingGroupsReducer = reducerFactory({
  initialState: {
    filingGroups: [],
    isFetchingFilingGroups: false,

    filingGroupsByFilingType: [],
    isFetchingFilingGroupsByFilingType: false,

    filingGroupId: null,
    previousFilingGroupId: null,

    jurisdictionsForFilingGroups: [],
    isFetchingJurisdictionsForFilingGroups: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFilingGroupsTypes,
      payloadKey: 'filingGroups',
      fetchingKey: 'isFetchingFilingGroups',
      getAdditionalState: (state, payload) => ({
        // Set initial filingGroupId
        filingGroupId: getFieldValue({
          currentValueFieldName: 'filingGroupId',
          payloadFieldName: 'filingGroupId',
          state,
          payload,
        }),
      }),
      initialAdditionalState: {
        filingGroupId: null,
      },
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFilingGroupsByFilingTypeTypes,
      payloadKey: 'filingGroupsByFilingType',
      fetchingKey: 'isFetchingFilingGroupsByFilingType',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAllJurisdictionsTypes,
      payloadKey: 'jurisdictionsForFilingGroups',
      fetchingKey: 'isFetchingJurisdictionsForFilingGroups',
    }),

    [SELECT_TAX_YEAR]: state => ({
      ...state,
      filingGroupId: null,
      previousFilingGroupId: state.filingGroupId,
    }),
    [SELECT_PERIOD]: state => ({
      ...state,
      filingGroupId: null,
      previousFilingGroupId: state.filingGroupId,
    }),

    [SELECT_FILING_GROUP_ID]: (state, payload) => ({
      ...state,
      filingGroupId: payload,
      previousFilingGroupId: state.filingGroupId,
    }),
    [SELECT_JURISDICTION_ID_FOR_FILING_GROUP]: (state, payload) => ({
      ...state,
      filingGroupId: (
        state.filingGroups.find(filingGroup => filingGroup.jurisdictionId === payload) || {
          filingGroupId: null,
        }
      ).filingGroupId,
      previousFilingGroupId: state.filingGroupId,
    }),
  },
});

export default combineReducers({
  shared: sharedStateFilingGroupsReducer,
  filingMembers: filingMembersReducer,
});
