import React from 'react';
import { Field } from 'formik';

import Checkbox from '../checkboxFormik/checkboxFormik.component';

import styles from './calcPreferences.module.scss';

interface CheckboxPreferencesOption {
  displayName: string;
  name: string;
}
interface CheckboxPreferencesProps {
  options: CheckboxPreferencesOption[];
  columnLabel: string;
}

const MAX_LABEL_CHARACTERS = 40;

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    const subStr = text.substring(0, maxLength);
    return `${subStr}...`;
  }
  return text;
};

const CheckboxPreferences = ({ options, columnLabel }: CheckboxPreferencesProps) => (
  <div>
    <label className="a-form-label">{truncateText(columnLabel, MAX_LABEL_CHARACTERS)}</label>
    {options.map(({ displayName, name }) => (
      <Field
        wrapperClassName={styles.calcPreferencesCheckbox}
        key={displayName}
        appkitLabel={displayName}
        name={name || ''}
        label={displayName}
        component={Checkbox}
      />
    ))}
  </div>
);

export default CheckboxPreferences;
