import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';

import Loading from '../../../../shared/displayComponents/loading.component';
import ParamDropdown from '../../../../shared/displayComponents/paramDropdown/paramDropdown.component';
import FaIcon from '../../../../shared/displayComponents/faIcon/faIcon.component';
import useOpenInNewTab from '../../../../shared/hooks/useOpenInNewTab';
import {
  fetchPDFFieldsByPage,
  selectDataModel,
  selectDataset,
  selectDataItem,
} from '../../../../shared/store/dataModels/actions';
import {
  sortedPdfMappedFieldsByPageOptionsSelector,
  isFetchingPDFFieldsByPageSelector,
  pdfFieldsByPageSelector,
  dataModelsSelector,
} from '../../../../shared/store/dataModels/selectors';
import { selectFormPage, fetchForms, fetchFormHtmlImage, selectForm } from '../../../store/actions';
import { setActiveFormDataItemId } from '../../store/actions';
import {
  taxYearSelector,
  jurisdictionIdSelector,
  formIdSelector,
  formPageSelector,
  formPagesOptionsSelector,
  isFetchingFormHtmlSelector,
  formPdfIdSelector,
  formsOptionsSelector,
  isFetchingFormsSelector,
} from '../../../store/selectors';
import expressionBuilderStyles from '../../styles.module.scss';
import { activeFormDataItemIdSelector, dataItemIdSelector } from '../../store/selectors';
import useUrlParams from '../../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import CalculationFormView from './calculationFormView.container';
import styles from './styles.module.scss';

const CalculationForm = ({
  addDataItemExpression,
  addDataItemConsolExpression,
  updateDirtyItems,
  isUsingSameExpression,
}) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const pdfId = useSelector(formPdfIdSelector);
  const formId = useSelector(formIdSelector);
  const formsOptions = useSelector(formsOptionsSelector);
  const isFetchingForms = useSelector(isFetchingFormsSelector);
  const pageList = useSelector(formPagesOptionsSelector);
  const selectedPage = useSelector(formPageSelector);
  const isFetchingFormHtml = useSelector(isFetchingFormHtmlSelector);
  const isFetchingPDFFieldsByPage = useSelector(isFetchingPDFFieldsByPageSelector);
  const pdfMappedFieldsByPageOptions = useSelector(sortedPdfMappedFieldsByPageOptionsSelector);
  const selectedDataItemId = useSelector(activeFormDataItemIdSelector);
  const expressionCalculationDataItemId = useSelector(dataItemIdSelector);
  const pdfFieldsByPage = useSelector(pdfFieldsByPageSelector);
  const dataModels = useSelector(dataModelsSelector);

  const [selectedFieldName, setSelectedFieldName] = useState(null);

  const { queryParams, setParams } = useUrlParams();

  const isContextReady = Boolean(taxYear && jurisdictionId);

  useEffect(() => {
    if (isContextReady) {
      dispatch(fetchForms({ taxYear, jurisdictionId }));
    }
  }, [dispatch, isContextReady, jurisdictionId, taxYear]);

  useEffect(() => {
    if (formId && pdfId) {
      dispatch(fetchFormHtmlImage({ formId, pdfId }));
    }
  }, [dispatch, formId, pdfId]);

  useEffect(() => {
    if (pdfId && selectedPage) {
      dispatch(fetchPDFFieldsByPage({ pdfId, pageNumber: selectedPage }));
    }
  }, [dispatch, pdfId, selectedPage]);

  useEffect(() => {
    if (
      dataModels.length &&
      queryParams?.dataModelId &&
      queryParams?.datasetId &&
      queryParams?.dataItemId
    ) {
      dispatch(selectDataModel(queryParams.dataModelId));
      dispatch(selectDataset(queryParams.datasetId));
      dispatch(selectDataItem(queryParams.dataItemId));
      setParams({ queryParams: { dataModelId: null, datasetId: null, dataItemId: null } });
    }
  }, [
    dispatch,
    setParams,
    dataModels,
    queryParams?.dataItemId,
    queryParams?.dataModelId,
    queryParams?.datasetId,
  ]);

  const scrollToField = useCallback(
    fieldName => document.getElementById(fieldName)?.scrollIntoView({ block: 'nearest' }),
    [],
  );

  const getFieldByDataItemId = useCallback(
    id =>
      pdfFieldsByPage.find(
        ({ dataItemId, pageNumber }) => dataItemId === id && pageNumber === selectedPage,
      ),
    [pdfFieldsByPage, selectedPage],
  );

  const setActiveFieldBasedOnExpressionCalculationDataItem = useCallback(() => {
    const dataItemId = selectedDataItemId || expressionCalculationDataItemId;

    const foundPdfField = getFieldByDataItemId(dataItemId);

    if (foundPdfField && !selectedFieldName?.startsWith(foundPdfField.name)) {
      scrollToField(foundPdfField.name);
      setSelectedFieldName(foundPdfField.name);
    }
    // Disabled because we don't want it to react on selectedFieldName changes which is changed both locally and inside calculationExpression
  }, [getFieldByDataItemId, scrollToField, selectedDataItemId]);

  useEffect(() => {
    setActiveFieldBasedOnExpressionCalculationDataItem();
  }, [
    setActiveFieldBasedOnExpressionCalculationDataItem,
    selectedDataItemId,
    expressionCalculationDataItemId,
  ]);

  const handlePageSelect = useCallback(
    newPage => {
      if (selectedPage !== newPage) {
        dispatch(selectFormPage(newPage));
      }
    },
    [dispatch, selectedPage],
  );

  const handleSyncClick = useCallback(() => {
    if (isContextReady) {
      dispatch(fetchForms({ taxYear, jurisdictionId }));
    }
  }, [dispatch, isContextReady, taxYear, jurisdictionId]);

  const handleFormSelection = useCallback(
    newFormId => {
      if (formId !== newFormId) {
        dispatch(selectForm(newFormId));
      }
    },
    [dispatch, formId],
  );

  const handleDataItemChange = useCallback(
    (newDataItemId, fieldName) => {
      if (fieldName && fieldName !== selectedFieldName) {
        setSelectedFieldName(fieldName);
        scrollToField(fieldName);
      }
      if (selectedDataItemId !== newDataItemId) {
        dispatch(setActiveFormDataItemId(newDataItemId));
        updateDirtyItems();
      }
    },
    [
      dispatch,
      setSelectedFieldName,
      scrollToField,
      updateDirtyItems,
      selectedFieldName,
      selectedDataItemId,
    ],
  );

  const openInNewTab = useOpenInNewTab();

  const handleFormDetailsButtonClick = useCallback(() => {
    openInNewTab(Routes.developmentTaxFormEditV2.MAIN, {
      taxYear,
      jurisdictionId,
      formId,
    });
  }, [openInNewTab, taxYear, jurisdictionId, formId]);

  const disableRefresh = isFetchingForms || isFetchingPDFFieldsByPage || isFetchingFormHtml;

  return (
    <>
      <div className={styles.dropdownsContainer}>
        <div className={styles.dropdowns}>
          <ParamDropdown
            label="Forms"
            value={formId}
            options={formsOptions}
            handleChange={handleFormSelection}
            isBusy={isFetchingForms}
            hasTooltip
          />
          <ParamDropdown
            label="DataItem"
            className={styles.dataItemDropdown}
            value={selectedDataItemId || expressionCalculationDataItemId}
            options={pdfMappedFieldsByPageOptions}
            handleChange={handleDataItemChange}
            isBusy={isFetchingPDFFieldsByPage || isFetchingFormHtml}
          />
        </div>
        <div>
          <Button size="lg" kind="secondary" onClick={handleFormDetailsButtonClick}>
            Form Details...
          </Button>
          <button
            className={expressionBuilderStyles.refreshButton}
            disabled={disableRefresh}
            onClick={handleSyncClick}
          >
            <FaIcon icon="sync" size={16} />
          </button>
        </div>
        <div className={styles.pageSection}>
          <Loading small isLoading={isFetchingForms || isFetchingFormHtml}>
            <div className={styles.pageDropdown}>
              <ParamDropdown
                label="Page"
                value={selectedPage}
                options={pageList}
                handleChange={handlePageSelect}
              />
            </div>
            <span className={styles.ofPages}>of {pageList.length}</span>
          </Loading>
        </div>
      </div>
      <CalculationFormView
        selectedFieldName={selectedFieldName}
        selectedDataItemId={selectedDataItemId}
        handleDataItemChange={handleDataItemChange}
        addDataItemExpression={addDataItemExpression}
        addDataItemConsolExpression={addDataItemConsolExpression}
        isUsingSameExpression={isUsingSameExpression}
      />
    </>
  );
};

CalculationForm.propTypes = {
  addDataItemExpression: PropTypes.func.isRequired,
  addDataItemConsolExpression: PropTypes.func.isRequired,
  updateDirtyItems: PropTypes.func.isRequired,
  isUsingSameExpression: PropTypes.bool.isRequired,
};

export default CalculationForm;
