import React, { useCallback } from 'react';
import { ErrorMessage, connect } from 'formik';
import PropTypes from 'prop-types';

import Dropzone from '../dropzone/dropzone.container';

const DropzoneFormik = ({
  name,
  label,
  value,
  disabled,
  acceptTypes,
  formik: { setFieldValue },
  existingFileName,
  onFileBufferLoaded,
  onDropCallback = () => {}, // eslint-disable-line no-empty-function
  validateFile = () => ({ valid: true, msg: null }),
  className,
}) => {
  const onDrop = useCallback(
    acceptedFile => {
      onDropCallback(acceptedFile);
      setFieldValue(name, acceptedFile);
    },
    [onDropCallback, setFieldValue, name],
  );

  return (
    <div className={className}>
      <label className="a-form-label">{label}</label>
      <Dropzone
        onDrop={onDrop}
        value={value}
        disabled={disabled}
        acceptTypes={acceptTypes}
        existingFileName={existingFileName}
        onFileBufferLoaded={onFileBufferLoaded}
        onDropCallback={onDrop}
        validateFile={validateFile}
      />
      <div className="a-form-error">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

DropzoneFormik.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.instanceOf(File),
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  onFileBufferLoaded: PropTypes.func,
  acceptTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  existingFileName: PropTypes.string,
  onDropCallback: PropTypes.func,
  className: PropTypes.string,
  validateFile: PropTypes.func,
};

export default connect(DropzoneFormik);
