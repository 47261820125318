import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { SelectOptionPropTypes } from '../../propTypes/selectOption';
import ParamDropdown from '../../displayComponents/paramDropdown/paramDropdown.component';
import { selectEntity } from '../../store/actions';
import { NotApplicableContextValue } from '../../constants';
import {
  isFetchingTaxYearsDataSelector,
  isFetchingPeriodsSelector,
  isFetchingEntitiesSelector,
  entityIdSelector,
  entitiesOptionsSelector,
} from '../../store/selectors';

const EntityDropdown = ({
  selectEntity,
  entities,
  entityId = null,
  isDropdownBusy = false,
  isFetchingContext,
}) => {
  const { routeParams, setParams } = useUrlParams();
  const handleSelect = useCallback(
    newEntityId => {
      if (entityId !== newEntityId) {
        if (newEntityId !== NotApplicableContextValue) {
          selectEntity(newEntityId);
        }
        if (routeParams.entityId) {
          setParams({ routeParams: { entityId: newEntityId } });
        }
        if (routeParams.businessEntityId) {
          setParams({ routeParams: { businessEntityId: newEntityId } });
        }
      }
    },
    [entityId, routeParams.businessEntityId, routeParams.entityId, selectEntity, setParams],
  );

  useEffect(() => {
    const entityId = routeParams.entityId || routeParams.businessEntityId;
    if (!(entities.length && entities.find(({ value }) => value === entityId))) {
      return;
    }

    if (entityId) {
      handleSelect(entityId);
    }
  }, [handleSelect, entities, routeParams.entityId, routeParams.businessEntityId, isDropdownBusy]);

  return (
    <ParamDropdown
      label="Entity"
      value={entityId}
      options={entities}
      handleChange={handleSelect}
      isBusy={isDropdownBusy}
      isFetchingContext={isFetchingContext}
      hasTooltip
    />
  );
};
EntityDropdown.propTypes = {
  entities: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  period: PropTypes.string,
  entityId: PropTypes.string,
  selectEntity: PropTypes.func.isRequired,
  isDropdownBusy: PropTypes.bool,
  isFetchingContext: PropTypes.bool,
};

export default connect(
  state => ({
    entities: entitiesOptionsSelector(state),
    entityId: entityIdSelector(state),
    isDropdownBusy:
      isFetchingTaxYearsDataSelector(state) ||
      isFetchingPeriodsSelector(state) ||
      isFetchingEntitiesSelector(state),
  }),
  {
    selectEntity,
  },
)(EntityDropdown);
