import { TaxYear, Period, FormFields } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export interface EntitiesMaintenanceRequestParams {
  taxYear: TaxYear | null;
  period: Period | null;
  showFederalEntitiesOnly?: boolean;
}

export interface EntityOrgIdCountRequestParams {
  taxYear: TaxYear;
  period: Period;
  orgId?: string;
}

export interface StaticDataForFormResponse {
  businessDescriptionOptions?: Array<FormFields> | null;
  federalFilingTypeOptions?: Array<FormFields> | null;
  jurisdictionOptions?: Array<FormFields> | null;
  legalEntitiesOptions?: Array<FormFields> | null;
  orgRuleIdOptions?: Array<FormFields> | null;
}

export const useQueryMaintenanceEntities = ({
  params,
  enabled,
}: {
  params: EntitiesMaintenanceRequestParams;
  enabled: boolean;
}) =>
  useCustomQuery([QueryKeys.EntitiesMaintenance.Entities, params], {
    url: `/api/admin/maintenance/entities/${params.taxYear}/${params.period}${
      params.showFederalEntitiesOnly
        ? `?showFederalEntitiesOnly=${params.showFederalEntitiesOnly}`
        : ''
    }`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching entities failed',
  });

export const useQueryStaticDataForForm = ({
  params,
  enabled,
}: {
  params: { taxYear: TaxYear; period: Period };
  enabled: boolean;
}) =>
  useCustomQuery<StaticDataForFormResponse>([QueryKeys.EntitiesMaintenance.FormData, params], {
    url: `/api/admin/maintenance/entities/static-data/${params.taxYear}/${params.period}`,
    enabled,
    defaultData: {},
    errorMessage: 'Fetching entities static data failed',
  });

export const useQueryEntityOrgIdCountData = ({
  params,
  enabled,
}: {
  params: EntityOrgIdCountRequestParams;
  enabled: boolean;
}) =>
  useCustomQuery([QueryKeys.EntitiesMaintenance.EntityData, params], {
    url: `/api/admin/maintenance/entities/org-id-count/${params.taxYear}/${params.period}/${params.orgId}`,
    enabled,
    defaultData: {},
    errorMessage: 'Fetching entities static data failed',
  });
