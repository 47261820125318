import React, { useState, useCallback } from 'react';
import { Tabs, Tab } from '@pwc/appkit-react';

import sltReportsData from './sltReports.data';
import formReportsData from './formReports.data';
import auditReportsData from './auditReports.data';
import ReportCards from './reportCards.component';

const TABS_TYPES = {
  SLT: 'SLT',
  FORM: 'FORM',
  AUDIT: 'AUDIT',
};

const tabsDefinitions = [
  { type: TABS_TYPES.SLT, label: TABS_TYPES.SLT },
  { type: TABS_TYPES.FORM, label: 'Form' },
  { type: TABS_TYPES.AUDIT, label: 'Audit' },
];

const Reports = () => {
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.SLT:
        return <ReportCards reportsData={sltReportsData} />;
      case TABS_TYPES.FORM:
        return <ReportCards reportsData={formReportsData} />;
      case TABS_TYPES.AUDIT:
        return <ReportCards reportsData={auditReportsData} />;
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }, index) => (
              <Tab id={type} value={type} label={label} key={index} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="row grid-row row-overflow">
        <div className="col">{getActiveTabComponent()}</div>
      </div>
    </>
  );
};

export default Reports;
