import { DataTypes } from '../enums';

export const autoGroupColumnDef = {
  minWidth: 200,
  headerName: 'Group / Account',
  dimension: {
    enableRowGroup: true,
    enablePivot: true,
  },
  cellRendererParams: {
    suppressCount: true,
  },
  valueGetter: ({
    data: { dataType, checkboxGroupId, itemName, aliasName },
  }: {
    data: { dataType: DataTypes; checkboxGroupId: string; itemName: string; aliasName: string };
  }) => (dataType === DataTypes.CHECKBOX && checkboxGroupId && itemName) || aliasName,
};
