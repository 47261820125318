import { createSelector } from 'reselect';
import { parseISO, differenceInCalendarDays } from 'date-fns';

import { isFetchingGlobalContextSelector } from '../../shared/store/selectors';

export const tasksSelector = ({ tasks }) => tasks.tasks;
export const isFetchingTasksSelector = ({ tasks }) => tasks.isFetchingTasks;

export const tasksFilteredByDueDateSelector = createSelector([tasksSelector], tasks =>
  tasks.filter(({ taskDueDate }) => {
    if (!taskDueDate) {
      return false;
    }

    const parsedTaskDueDate = parseISO(taskDueDate);
    const today = new Date();

    return differenceInCalendarDays(parsedTaskDueDate, today) <= 14;
  }),
);

export const sourceCustomersOptionsSelector = createSelector(
  [state => state.tasks.sourceCustomers],
  sourceCustomers =>
    sourceCustomers.map(({ customerId, fromCustomerName }) => ({
      value: customerId,
      label: fromCustomerName,
    })),
);
export const isFetchingSourceCustomersSelector = state => state.tasks.isFetchingSourceCustomers;

export const destinationCustomersOptionsSelector = createSelector(
  [state => state.tasks.destinationCustomers],
  destinationCustomers =>
    destinationCustomers.map(({ customerId, toCustomerName }) => ({
      value: customerId,
      label: toCustomerName,
    })),
);
export const isFetchingDestinationCustomersSelector = state =>
  state.tasks.isFetchingDestinationCustomers;

export const tasksForReassignmentOptionsSelector = createSelector(
  [state => state.tasks.tasksForReassignment],
  tasksForReassignment =>
    tasksForReassignment.map(task => ({
      key: task.taskId,
      label: task.displayTask,
      details: {
        taxYear: task.taxYear,
        period: task.period,
        taskId: task.taskId,
        orgId: task.orgId,
        jurisdictionId: task.jurisdictionId,
        orgRuleId: task.orgRuleId,
      },
    })),
);
export const isFetchingTasksForReassignmentSelector = state =>
  state.tasks.isFetchingTasksForReassignment;

export const isUpdatingTasksAssignmentsSelector = state => state.tasks.isUpdatingTasksAssignments;

export const isFetchingContextSelector = createSelector(
  [isFetchingTasksSelector, isFetchingGlobalContextSelector],
  (isFetchingTasks, isFetchingGlobalContext) => isFetchingTasks || isFetchingGlobalContext,
);
