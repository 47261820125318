import React from 'react';
import { useSelector } from 'react-redux';

import { taxYearSelector } from '../../../store/selectors';
import DevelopmentTaxYearDropdown from '../../../developmentTaxYearDropdown.container';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { useQueryDisallowOverrideReportData } from '../../../../shared/queries/expressionBuilder';

import columnDefinitions from './disallowOverrideReport.columnDefinitions';

const DisallowOverrideReport = () => {
  const taxYear = useSelector(taxYearSelector);

  const {
    data: reportData,
    isFetching: isFetchingReportData,
  } = useQueryDisallowOverrideReportData({ params: { taxYear }, enabled: Boolean(taxYear) });

  return (
    <>
      <div className="row">
        <div className="col-3">
          <DevelopmentTaxYearDropdown />
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={reportData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingReportData}
            areHeaderCellBordersEnabled
            autoMaxWidth
          />
        </div>
      </div>
    </>
  );
};

export default DisallowOverrideReport;
