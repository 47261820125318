import { ColDef } from 'ag-grid-community';

import {
  defaultColumnDefinitionWithFilter,
  adminColumnDefinition,
} from '../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({
  onDeleteIconClick = () => null,
}: {
  onDeleteIconClick?: () => void;
}): ColDef[] => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: false,
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name',
      field: 'name',
      flex: 1,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: 'description',
      flex: 2,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Items',
      field: 'itemsJoined',
      flex: 3,
    },
  ];
};

export default getColumnDefinitions;
