import { apiAction } from '../../../shared/store/apiAction';
import * as api from '../store/api';

import {
  fetchAccountsTypes,
  fetchAccountGroupsTypes,
  fetchScreenGroupsTypes,
  fetchFunctionTypes,
  fetchConsFunctionTypes,
  createAccountTypes,
  updateAccountTypes,
} from './types';

export const fetchAccounts = apiAction({
  types: fetchAccountsTypes,
  apiAction: api.fetchAccounts,
});

export const fetchAccountGroups = apiAction({
  types: fetchAccountGroupsTypes,
  apiAction: api.fetchAccountGroups,
});

export const fetchFunctions = apiAction({
  types: fetchFunctionTypes,
  apiAction: api.fetchFunctions,
});

export const fetchScreenGroups = apiAction({
  types: fetchScreenGroupsTypes,
  apiAction: api.fetchScreenGroups,
});

export const fetchConsFunctions = apiAction({
  types: fetchConsFunctionTypes,
  apiAction: api.fetchConsFunctions,
});

export const createAccount = apiAction({
  types: createAccountTypes,
  apiAction: api.createAccount,
});

export const updateAccount = apiAction({
  types: updateAccountTypes,
  apiAction: api.updateAccount,
});
