import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { Collapse } from '@pwc/appkit-react';

import AppkitIcon from '../../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { Link } from '../../../../../../common/types';
import downloadFile from '../../../../utils/downloadFile';
import { DownloadLink } from '../../../../shared/displayComponents/downloadLink';

import styles from './stepInformationCard.module.scss';

export enum StepInformationCardType {
  REGULAR,
  WARNING,
  FILE,
}

interface StepInformationCardProps {
  title: string;
  subtitle?: string | null;
  fileLinks?: Link[];
  type?: StepInformationCardType;
  disabled?: boolean;
  isDownloadable?: boolean;
  hasButtonStyle?: boolean;
  shouldShowDownloadIcon?: boolean;
}

const StepInformationCard = ({
  title,
  subtitle = '',
  fileLinks = [],
  type = StepInformationCardType.REGULAR,
  disabled = false,
  isDownloadable = false,
  hasButtonStyle = false,
  shouldShowDownloadIcon = true,
}: StepInformationCardProps) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const hasListOfLinks = Boolean(
    fileLinks.length && type !== StepInformationCardType.FILE && !isDownloadable,
  );

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (hasListOfLinks) {
      setIsCollapseOpen(currentIsOpen => !currentIsOpen);
    }

    const fileUrl = fileLinks[0]?.url;
    const fileLocator = fileLinks[0]?.fileLocator;
    const fileName = fileLinks[0]?.fileName;

    if (isDownloadable) {
      downloadFile({
        route: fileUrl,
        fileLocator,
        fileName,
      });
    }
  }, [disabled, fileLinks, hasListOfLinks, isDownloadable]);

  return (
    <div
      className={classNames(styles.card, {
        [styles.inactive]: disabled,
        [styles.warning]: type === StepInformationCardType.WARNING,
        [styles.buttonStyle]: hasButtonStyle,
      })}
    >
      <div
        className={classNames(styles.cardHeader, { [styles.clickable]: fileLinks.length })}
        onClick={handleClick}
      >
        <div className={styles.cardHeaderTitleAndIcon}>
          {type === StepInformationCardType.WARNING && (
            <AppkitIcon className={styles.alertIcon} type="fill" icon="alert" size={32} />
          )}
          {type === StepInformationCardType.FILE && (
            <a href={fileLinks[0].url}>
              <AppkitIcon className={styles.newsIcon} type="fill" icon="news" size={32} />
            </a>
          )}
          <div className={styles.cardHeaderTitleSection}>
            <span className={styles.cardHeaderTitle}>{title}</span>
            {subtitle && <span className={styles.cardHeaderSubtitle}>{subtitle}</span>}
          </div>
        </div>
        <div className={styles.cardHeaderActionsSection}>
          {hasListOfLinks && (
            <>
              <div className={styles.fileCountWrapper}>
                <span className={styles.fileCountText}>{fileLinks.length} files</span>
              </div>
              <AppkitIcon
                className={styles.chevronIcon}
                type="outline"
                icon={isCollapseOpen ? 'up-chevron' : 'down-chevron'}
                size={20}
              />
            </>
          )}
          {Boolean(fileLinks.length && isDownloadable && shouldShowDownloadIcon) && (
            <>
              <AppkitIcon
                type="outline"
                className={styles.downloadIcon}
                icon="download-light"
                size={20}
              />
              <span className={styles.downloadText}>download</span>
            </>
          )}
        </div>
      </div>
      {hasListOfLinks && (
        <Collapse className={styles.cardCollapse} isOpen={isCollapseOpen}>
          <ul>
            {fileLinks.map(({ label, url, fileLocator, fileName }) => (
              <li key={`${url}-${label}`} className={styles.attachmentLink}>
                <DownloadLink fileLocator={fileLocator} url={url} fileName={fileName}>
                  {label}
                </DownloadLink>
              </li>
            ))}
          </ul>
        </Collapse>
      )}
    </div>
  );
};

export default StepInformationCard;
