import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Modal, { withModal } from '@tls/ui-modal';
import { Button } from '@pwc/appkit-react/lib/Button';

import getQueryParams from '../../utils/getQueryParams';
import Loading from '../../shared/displayComponents/loading.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Textarea from '../../shared/forms/textareaFormik/textareaFormik.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import { SelectOptionPropTypes } from '../../shared/forms/propTypes';
import { ParamTypeValue, ContextItemValue } from '../../shared/reports/enums';

import { runCustomQueryForParamOptions } from './store/actions';
import {
  customQueryForParamColumnsOptionsSelector,
  isFetchingCustomQueryForParamResultSelector,
  fetchingCustomQueryForParamResultErrorSelector,
} from './store/selectors';
import {
  ModalNames,
  AVAILABLE_PARAM_TYPES,
  paramFormIntitialValues,
  REPORT_TYPES,
} from './constants';

const CONTEXT_ITEM_OPTIONS = [
  { value: ContextItemValue.NONE, label: 'None' },
  { value: ContextItemValue.TAX_YEAR, label: 'Tax Year' },
  { value: ContextItemValue.PERIOD, label: 'Period' },
  { value: ContextItemValue.JURISDICTION_ID, label: 'Jurisdiction' },
  { value: ContextItemValue.ENTITY_ID, label: 'Entity Id' },
  { value: ContextItemValue.CONSOLIDATION_ID, label: 'Consol Id' },
  { value: ContextItemValue.USER_ID, label: 'User Id' },
];

class EditParamModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalData: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      mappingName: PropTypes.string,
      query: PropTypes.string,
      serverSide: PropTypes.bool,
      dropdownValue: PropTypes.string,
      dropdownLabel: PropTypes.string,
      isMultiSelectDropdown: PropTypes.bool,
      contextItem: PropTypes.string,
    }),
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      mappingName: PropTypes.string,
      query: PropTypes.string,
      serverSide: PropTypes.bool,
      dropdownValue: PropTypes.string,
      dropdownLabel: PropTypes.string,
      isMultiSelectDropdown: PropTypes.bool,
      contextItem: PropTypes.string,
    }).isRequired,
    saveParam: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    runCustomQueryForParamOptions: PropTypes.func.isRequired,
    customQueryForParamColumnsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
    isFetchingCustomQueryForParamResult: PropTypes.bool.isRequired,
    fetchingCustomQueryForParamResultError: PropTypes.bool.isRequired,
    existingParams: PropTypes.object.isRequired, // eslint-disable-line
    reportType: PropTypes.oneOf(Object.values(REPORT_TYPES)),
  };

  state = {
    verificationFiredForCurrentQuery: false,
  };

  componentDidUpdate(prevProps) {
    const { modalData, setValues } = this.props;
    if (prevProps.modalData !== modalData && modalData) {
      setValues({
        name: modalData.name,
        mappingName: modalData.mappingName,
        type: modalData.type,
        query: modalData.query || paramFormIntitialValues.query,
        dropdownValue: modalData.dropdownValue || paramFormIntitialValues.dropdownValue,
        dropdownLabel: modalData.dropdownLabel || paramFormIntitialValues.dropdownLabel,
        isMultiSelectDropdown:
          modalData.isMultiSelectDropdown || paramFormIntitialValues.isMultiSelectDropdown,
        contextItem: modalData.contextItem || ContextItemValue.NONE,
      });

      if (modalData.query) {
        this.verifyQuery(modalData.query, modalData.serverSide);
      } else {
        this.setState({ verificationFiredForCurrentQuery: false });
      }
    }
  }

  save = () => {
    const { saveParam, hideModal, modalData, values } = this.props;
    const { name } = modalData;
    saveParam(name || values.name, values);
    hideModal();
  };

  verifyQuery = (query, serverSide) => {
    const { runCustomQueryForParamOptions, existingParams } = this.props;
    const queryParams = getQueryParams(query);
    const dependentParams = queryParams.reduce(
      (params, paramKey) => Object.assign(params, { [paramKey]: existingParams[paramKey] }),
      {},
    );

    runCustomQueryForParamOptions({ query, serverSide, params: dependentParams });
    this.setState({ verificationFiredForCurrentQuery: true });
  };

  verifyFormQuery = () => {
    const { values, setValues } = this.props;
    setValues({
      ...values,
      dropdownValue: paramFormIntitialValues.dropdownValue,
      dropdownLabel: paramFormIntitialValues.dropdownLabel,
    });
    this.verifyQuery(values.query, values.serverSide);
  };

  handleDropdownChange = fieldName => (_, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(fieldName, value);
  };

  handleTypeChange = this.handleDropdownChange('type');
  handleDropdownValueChange = this.handleDropdownChange('dropdownValue');
  handleDropdownLabelChange = this.handleDropdownChange('dropdownLabel');
  handleContextItemChange = this.handleDropdownChange('contextItem');

  onQueryChange = () => {
    this.setState({ verificationFiredForCurrentQuery: false });
  };

  render() {
    const {
      hideModal,
      visible,
      values,
      customQueryForParamColumnsOptions,
      isFetchingCustomQueryForParamResult,
      fetchingCustomQueryForParamResultError,
      reportType,
      title,
    } = this.props;
    const { verificationFiredForCurrentQuery } = this.state;
    const isTypeDropdown = values.type === ParamTypeValue.DROPDOWN;
    const isTrgReport = reportType === REPORT_TYPES.TRG;
    const isSubmitDisabled =
      isTypeDropdown &&
      (!verificationFiredForCurrentQuery ||
        fetchingCustomQueryForParamResultError ||
        !values.dropdownValue ||
        !values.dropdownLabel);

    return (
      <Modal
        className="edit-param-modal"
        title={title}
        closeAction={hideModal}
        dismissText="Cancel"
        dismissAction={hideModal}
        submitText="Save"
        submitAction={this.save}
        visible={visible}
        submitDisabled={isSubmitDisabled}
      >
        {isTrgReport && (
          <Field
            className="mb-3"
            label="Visible name"
            name="name"
            component={Input}
            autoComplete="off"
          />
        )}
        <Field
          className="mb-3"
          label="Mapping name"
          name="mappingName"
          component={Input}
          autoComplete="off"
        />
        <Select
          wrapperClassName="mb-3"
          appkitLabel="Param type"
          name="type"
          options={AVAILABLE_PARAM_TYPES}
          value={values.type}
          onSelect={this.handleTypeChange}
        />
        {isTypeDropdown && (
          <Fragment>
            <Field label="Multi Select" name="isMultiSelectDropdown" component={Checkbox} />
            <Field
              className="mb-3 slide-in-description-field"
              label="Query"
              name="query"
              component={Textarea}
              onChange={this.onQueryChange}
            />
            <div className="verify-query-btn-container">
              {fetchingCustomQueryForParamResultError && (
                <span className="error-message">Query Failed</span>
              )}
              <Button
                size="md"
                kind="secondary"
                disabled={isFetchingCustomQueryForParamResult}
                onClick={this.verifyFormQuery}
              >
                Verify Query
              </Button>
            </div>
            {verificationFiredForCurrentQuery && (
              <Loading isLoading={isFetchingCustomQueryForParamResult}>
                {!fetchingCustomQueryForParamResultError && (
                  <Fragment>
                    <Select
                      wrapperClassName="mb-3"
                      appkitLabel="Dropdown value"
                      name="dropdownValue"
                      options={customQueryForParamColumnsOptions}
                      value={values.dropdownValue}
                      onSelect={this.handleDropdownValueChange}
                    />
                    <Select
                      wrapperClassName="mb-3"
                      appkitLabel="Dropdown label"
                      name="dropdownLabel"
                      options={customQueryForParamColumnsOptions}
                      value={values.dropdownLabel}
                      onSelect={this.handleDropdownLabelChange}
                    />
                  </Fragment>
                )}
              </Loading>
            )}
          </Fragment>
        )}
        <Select
          wrapperClassName="mb-3"
          appkitLabel="Context Item"
          name="contextItem"
          options={CONTEXT_ITEM_OPTIONS}
          value={values.contextItem}
          onSelect={this.handleContextItemChange}
        />
      </Modal>
    );
  }
}

export default connect(
  state => ({
    customQueryForParamColumnsOptions: customQueryForParamColumnsOptionsSelector(state),
    isFetchingCustomQueryForParamResult: isFetchingCustomQueryForParamResultSelector(state),
    fetchingCustomQueryForParamResultError: fetchingCustomQueryForParamResultErrorSelector(state),
  }),
  {
    runCustomQueryForParamOptions,
  },
)(withModal(EditParamModal, ModalNames.EDIT_PARAM));
