import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';
import { IndividualEntity, TaxYear } from '../../../../common/types';

import { useCustomMutation } from '.';

interface PostIndividualRequest {
  taxYear: TaxYear | null;
  individualId: string | undefined;
  values: IndividualEntity;
}

export const useMutationPostIndividual = () =>
  useCustomMutation<null, QueryError, PostIndividualRequest, IndividualEntity>({
    handleData: ({ taxYear, individualId, values }) => ({
      url: `/api/admin/maintenance/individuals/${taxYear}/${individualId}`,
      method: HttpMethods.POST,
      body: values,
    }),
    resetKey: QueryKeys.IndividualMaintenance.Fields,
    successMessage: 'Saving individual succeeded',
    errorMessage: 'Saving individual failed',
  });
