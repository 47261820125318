import { DatasetInstancesResponse } from '@tls/slt-types';

import { JurisdictionId, TaxYear } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export interface GlobalContextParams {
  taxYear: string;
  period: string;
  filingTypeId: string;
  businessEntityId: string;
  jurisdictionId: string;
}

export interface GlobalContext {
  params: GlobalContextParams;
}

export interface DatasetInstancesQueryParams {
  globalContext: GlobalContext;
  datasetDefinitionId: string;
}

export type FindDataModels = {
  name: string;
  id: string;
}[];
export type FindDataSets = FindDataModels;
export type FindDataItems = FindDataSets;

export type FindDataModelsOptions = { label: string; value: string }[];
export type FindDataSetsOptions = FindDataModelsOptions;
export type FindDataItemsOptions = FindDataSetsOptions;

interface FindDataModelsParams {
  taxYear: TaxYear;
  jurisdictionId: JurisdictionId;
}

interface FindDataSetsParams {
  taxYear: TaxYear;
  jurisdictionId: JurisdictionId;
  dataModelId: string;
}

interface FindDataItemsParams {
  taxYear: TaxYear;
  datasetId: string;
}

export const useQueryDatasetInstances = ({
  params: {
    globalContext: {
      params: { taxYear, period, filingTypeId, businessEntityId },
    },
    datasetDefinitionId,
  },
  enabled,
}: {
  params: DatasetInstancesQueryParams;
  enabled: boolean;
}) =>
  useCustomQuery<DatasetInstancesResponse>(
    [
      QueryKeys.DataModels.DatasetInstances,
      { taxYear, period, filingTypeId, businessEntityId, datasetDefinitionId },
    ],
    {
      url: `/api/shared/data-models/dataset-instances/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${datasetDefinitionId}?consolidationId=${businessEntityId}`,
      enabled,
      initialData: [],
      errorMessage: 'Fetching data models dataset instances failed',
    },
  );

export const useQueryDataModelsOptions = ({
  params,
  enabled,
}: {
  params: FindDataModelsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindDataModels, Error, FindDataModelsOptions>(
    [QueryKeys.DataModels.DataModels, params],
    {
      url: `/api/shared/data-models/${params.taxYear}/${params.jurisdictionId}`,
      enabled,
      errorMessage: 'An error occurred while fetching data models failed',
      defaultData: [],
      selector: (data: FindDataModels) =>
        (data || []).map(({ name, id }) => ({ label: name, value: id })),
    },
  );

export const useQueryDataSetsOptions = ({
  params,
  enabled,
}: {
  params: FindDataSetsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindDataSets, Error, FindDataSetsOptions>(
    [QueryKeys.DataModels.DataSets, params],
    {
      url: `/api/shared/data-models/datasets/${params.taxYear}/${params.jurisdictionId}/${params.dataModelId}`,
      enabled,
      errorMessage: 'An error occurred while fetching data sets failed',
      defaultData: [],
      selector: (data: FindDataSets) =>
        (data || []).map(({ name, id }) => ({ label: name, value: id })),
    },
  );

export const useQueryDataItemsOptions = ({
  params,
  enabled,
}: {
  params: FindDataItemsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindDataItems, Error, FindDataItemsOptions>(
    [QueryKeys.DataModels.DataItems, params],
    {
      url: `/api/shared/data-models/data-items/${params.taxYear}/${params.datasetId}`,
      enabled,
      errorMessage: 'An error occurred while fetching data items failed',
      defaultData: [],
      selector: (data: FindDataItems) =>
        (data || []).map(({ name, id }) => ({ label: name, value: id })),
    },
  );
