import React from 'react';
import Modal from '@tls/ui-modal';
import { Field, useFormikContext } from 'formik';

import DatePicker from '../../shared/forms/datePickerFormik/datePickerFormik.component';

type CheckTaxReturnTaxYearEndingModalProps = {
  entityId?: string | null;
  warningModalProps: {
    visible: boolean;
    hideModal: () => void;
    modalData: null;
  };
  submitCallback: () => void;
};

const Modals = {
  modelTitle: 'Data Missing',
  modelDescriptionBefore:
    'A tax year ending date is required prior to creating a return for entity ',
  modelDescriptionAfter: '. Add a date below to proceed.',
  submitText: 'OK',
  dismissText: 'Cancel',
};

export const CheckTaxReturnTaxYearEndingModal = ({
  entityId,
  warningModalProps,
  submitCallback,
}: CheckTaxReturnTaxYearEndingModalProps) => {
  const { values, submitForm } = useFormikContext<{ taxYearEnding: string }>();

  return (
    <Modal
      {...warningModalProps}
      title={Modals.modelTitle}
      submitText={Modals.submitText}
      dismissText={Modals.dismissText}
      dismissAction={warningModalProps.hideModal}
      closeAction={warningModalProps.hideModal}
      submitAction={submitForm}
      submitCallback={submitCallback}
    >
      <p>
        {Modals.modelDescriptionBefore}
        {entityId}
        {Modals.modelDescriptionAfter}
      </p>
      <Field
        className="form-text"
        label="Tax Year Ending"
        name="taxYearEnding"
        component={DatePicker}
        value={values.taxYearEnding}
      />
    </Modal>
  );
};
