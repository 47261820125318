import React from 'react';
import { connect } from 'react-redux';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../../shared/store/selectors';

import proformaBreadcrumbRoutes from './proformaBreadcrumbRoutes.component';

const ProformaPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: proformaBreadcrumbRoutes,
}))(GlobalContextPage);

const AuthorizedProformaRoute = props => (
  <AuthorizedCustomRoute wrapper={ProformaPage} hideGlobalContext {...props} />
);

export { AuthorizedProformaRoute };
