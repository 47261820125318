import React, { useCallback, useState, useMemo } from 'react';
import { RowNode } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import Modal from '@tls/ui-modal';

import {
  CalcPreferenceByJurisdictions,
  CalcPreference,
  JurisdictionId,
} from '../../../../../common/types';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../../shared/editMode';
import toggleAllSelectionsHeaderFactory from '../../../shared/displayComponents/toggleAllSelectionsHeaderFactory';
import { jurisdictionsSelector, isFetchingJurisdictionsSelector } from '../../store/selectors';

import styles from './addJurisdictionsModal.module.scss';
import getColumnDefinitions from './addJurisdictionsModal.columnDefinitions';

interface AddJurisdictionsModalProps {
  hideModal: () => void;
  visible: boolean;
  modalData: CalcPreferenceByJurisdictions | null;
  addJusrisdictionRow: (insertedRow: CalcPreference) => RowNode;
}

const getRowNodeId = ({ data }: { data: { jurisdictionId: JurisdictionId } }) =>
  data.jurisdictionId;

const AddJurisdictionsModal = ({
  hideModal,
  visible,
  modalData: selectedCalcPreference,
  addJusrisdictionRow,
}: AddJurisdictionsModalProps) => {
  const [selectAllUnmapState, setSelectAllUnmapState] = useState(false);
  const jurisdictions = useSelector(jurisdictionsSelector);
  const isFetchingJurisdictions = useSelector(isFetchingJurisdictionsSelector);

  const rowData = useMemo(
    () =>
      jurisdictions.map((jurisdiction: { stateCode: string }) => {
        const hasSelectedCalcPreference = selectedCalcPreference?.jurisdictions.some(
          ({ stateCode }: { stateCode: string }) => stateCode === jurisdiction.stateCode,
        );
        return {
          ...jurisdiction,
          hasSelectedCalcPreference,
          hasSelectedCalcPreferenceStatic: hasSelectedCalcPreference,
        };
      }),
    [jurisdictions, selectedCalcPreference],
  );

  const {
    updateRow,
    updateRowForSelectAll,
    onGridReady,
    gridApi,
    clonedRowData,
    findChanges,
  } = useRowEditMode({
    rowData,
    getUniqueRowId: getRowNodeId,
    saveButtonDisabled: false,
  });

  const handleOverrideAllValues = useCallback(
    (newValue: boolean) => {
      gridApi?.forEachNodeAfterFilter(({ data }) => {
        if (data.hasSelectedCalcPreference !== newValue && !data.hasSelectedCalcPreferenceStatic) {
          data.hasSelectedCalcPreference = newValue;
          updateRowForSelectAll(data);
        }
      });
      gridApi?.refreshCells({ force: true });
    },
    [updateRowForSelectAll, gridApi],
  );

  const columnDefinitions = useMemo(() => {
    const ToggleAllSelectionsHeader = toggleAllSelectionsHeaderFactory({
      togglerState: selectAllUnmapState,
      setTogglerState: setSelectAllUnmapState,
      isInEditMode: true,
      handleOverrideAllValues,
    });

    return getColumnDefinitions({
      ToggleAllSelectionsHeader,
      updateRow,
    });
  }, [handleOverrideAllValues, updateRow, selectAllUnmapState]);

  const handleSubmit = useCallback(() => {
    const { rowsPairsWithChanges } = findChanges();
    rowsPairsWithChanges.forEach(({ newRow }: { newRow: CalcPreference }) => {
      addJusrisdictionRow({
        name: newRow.name,
        taxYear: newRow.taxYear,
        description: newRow.description,
        stateCode: newRow.stateCode,
        selectionListId: newRow.selectionListId,
        jurisdictionId: newRow.jurisdictionId,
        jurisdictionDescription: newRow.jurisdictionDescription,
        defaultValue: newRow.defaultValue,
        dataType: newRow.dataType,
        inputMask: '',
        validationRegex: '',
      });
    });

    hideModal();
  }, [findChanges, addJusrisdictionRow, hideModal]);

  return (
    <Modal
      className={styles.addJurisdictionModal}
      title="Jurisdictions"
      submitText="Save"
      dismissText="Cancel"
      visible={visible}
      dismissAction={hideModal}
      closeAction={hideModal}
      submitAction={handleSubmit}
      actionButtonsSize="lg"
    >
      <div className={`row grid-row ${styles.agGridContainer}`}>
        <div className="col">
          <AgGrid
            isGridLoading={isFetchingJurisdictions}
            columnDefs={columnDefinitions}
            rowData={clonedRowData}
            areHeaderCellBordersEnabled
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddJurisdictionsModal;
