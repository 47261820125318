import { Routes, taxNames } from '@common-packages/routes-definitions';

import getContextAwareUrlParams from '../../../utils/getContextAwareUrlParams';
import { filingTypes } from '../../../shared/constants';

const getNestedMenuSchemaDefinition = (globalContext, trgReports) => [
  {
    label: Routes.root.displayMainName(),
    key: 'home',
    route: Routes.root.MAIN,
    access: Routes.root.MAIN,
    options: [],
  },
  {
    label: Routes.compliance.displayMainName(),
    key: 'compliance',
    access: Routes.compliance.MAIN,
    route: null,
    options: [
      {
        label: 'Entities',
        key: 'entities',
        route: null,
        options: [
          {
            label: 'Informational Data',
            key: 'informational_data',
            route: null,
            options: [
              {
                label: 'Entity Information',
                key: 'entity_information',
                route: Routes.entityInformation.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    businessEntityId: globalContext.entityId,
                    filingTypeId: filingTypes.SEPARATE,
                  }),
                ),
                options: [],
              },
              {
                label: 'State Account Numbers',
                key: 'state_account_numbers',
                route: Routes.stateAccountNumbers.MAIN,
                options: [],
              },
              {
                label: 'Global PDF Attachments',
                key: 'global_pdf_attachments',
                route: Routes.addEditGlobalPdfAttachments.MAIN,
                options: [],
              },
              {
                label: 'Preparer/Reviewer Notes',
                key: 'preparer_reviewer_notes',
                route: Routes.prepareReviewNotes.MAIN,
                options: [],
              },
            ],
          },
          {
            label: 'Filing Data',
            key: 'filing_data',
            route: null,
            options: [
              {
                label: 'Filing Decisions',
                key: 'filing_decisions_v2',
                route: Routes.filingDecisionsV2.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    businessEntityId: globalContext.entityId,
                    filingTypeId: filingTypes.SEPARATE,
                  }),
                ),
                options: [],
              },
              {
                label: 'Setup Tax Returns',
                key: 'setup_tax_returns',
                route: Routes.setupTaxReturns.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    businessEntityId: globalContext.entityId,
                    filingTypeId: filingTypes.SEPARATE,
                  }),
                ),
                options: [],
              },
            ],
          },
          {
            label: 'Workpapers',
            key: 'workpapers',
            route: null,
            options: [
              {
                label: 'Income Tax Summary',
                key: 'income_tax_summary',
                route: Routes.taxSummaries.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    filingTypeId: filingTypes.SEPARATE,
                    taxName: taxNames.INCOME_TAX,
                  }),
                ),
                options: [],
              },
              {
                label: 'Franchise Tax Summary',
                key: 'franchise_tax_summary',
                route: Routes.taxSummaries.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    filingTypeId: filingTypes.SEPARATE,
                    taxName: taxNames.FRANCHISE_TAX,
                  }),
                ),
                options: [],
              },
              {
                label: 'Estimated Tax Summary',
                key: 'estimated_tax_summary',
                route: Routes.taxSummaries.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    filingTypeId: filingTypes.SEPARATE,
                    taxName: taxNames.ESTIMATED_TAX,
                  }),
                ),
                options: [],
              },
              {
                label: 'Tax Summary',
                key: 'tax_summary',
                route: Routes.customScreen.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    filingTypeId: filingTypes.SEPARATE,
                    customScreenName: 'tax-summary',
                  }),
                ),
                options: [],
              },
              {
                label: 'Manually Entered Data',
                key: 'manually_entered_data',
                route: Routes.manuallyEnteredData.MAIN,
                options: [],
              },
              {
                label: 'View Flow-through Ownership',
                key: 'view_flow_through_ownership',
                route: Routes.reviewPartnershipInformation.MAIN,
                options: [],
              },
              {
                label: 'View Flow-through Amounts',
                key: 'view_flow_through_amounts',
                route: Routes.partnershipFlowThrough.MAIN,
                options: [],
              },
              {
                label: 'Flow-through K-1 Details',
                key: 'flow_through_k1_details',
                route: Routes.flowThroughK1Details.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    filingTypeId: filingTypes.SEPARATE,
                  }),
                ),
                options: [],
              },
              {
                label: 'Flow-through Allocations',
                key: 'flow_through_allocations',
                route: Routes.partnershipAllocationPercentages.MAIN,
                options: [],
              },
            ],
          },
          {
            label: 'Forms',
            key: 'forms',
            route: null,
            options: [
              {
                label: 'Federal Proforma',
                key: 'separate-federal-proforma',
                route: Routes.separateFederalProforma.compiledRoute(
                  getContextAwareUrlParams(globalContext.params),
                ),
                options: [],
              },
              {
                label: 'State Tax Forms',
                key: 'state_tax_forms',
                route: Routes.viewPrintTaxReturn.compiledRoute(
                  getContextAwareUrlParams(globalContext.params),
                ),
                options: [],
              },
            ],
          },
          {
            label: 'Reports',
            key: 'reports',
            route: null,
            options: [
              {
                label: 'Federal Reconciliation',
                key: 'federal_reconciliation',
                route: Routes.federalReconciliation.MAIN,
                options: [],
              },
              {
                label: 'Prior Year Comparison Summary',
                key: 'prior_year_comparison_summary',
                route: Routes.priorYearComparisonReportSummary.MAIN,
                options: [],
              },
              {
                label: 'High Level Planning Data',
                key: 'high_level_planning_data',
                route: Routes.highLevelPlanningData.MAIN,
                options: [],
              },
              {
                label: 'Entity Analytic Report',
                key: 'entity_analytic_report',
                route: Routes.entityAnalyticsReview.MAIN,
                options: [],
              },
              {
                label: 'Filing Positions',
                key: 'filing_positions',
                route: Routes.filingPositions.MAIN,
                options: [],
              },
              {
                label: 'Attachment Details',
                key: 'attachment_details_report',
                route: Routes.viewPrintAttachmentDetails.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    filingTypeId: filingTypes.SEPARATE,
                  }),
                ),
                options: [],
              },
            ],
          },
        ],
      },
      {
        label: 'Consolidations',
        key: 'consolidations',
        route: null,
        options: [
          {
            label: 'Filing Data',
            key: 'filing_data',
            route: null,
            options: [
              {
                label: 'Filing Decisions',
                key: 'filing_decisions_v2',
                route: Routes.filingDecisionsV2.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    businessEntityId: globalContext.entityId,
                    filingTypeId: filingTypes.CONSOLIDATED,
                  }),
                ),
                options: [],
              },
              {
                label: 'Filing Groups in this Consolidation',
                key: 'filing_groups_in_this_consolidation',
                route: Routes.consolidationFilingGroups.MAIN,
                options: [],
              },
            ],
          },
          {
            label: 'Forms',
            key: 'forms',
            route: null,
            options: [
              {
                label: 'Federal As-Filed Return',
                key: 'federal_as_filed_cons',
                route: Routes.federalAsFiled.compiledRoute(
                  getContextAwareUrlParams(globalContext.params),
                ),
                options: [],
              },
              {
                label: 'Federal Pro Forma Return',
                key: 'federal_proforma_cons',
                route: Routes.federalProforma.compiledRoute(
                  getContextAwareUrlParams(globalContext.params),
                ),
                options: [],
              },
              {
                label: 'State Tax Forms',
                key: 'state_tax_forms',
                route: Routes.viewPrintTaxReturn.compiledRoute(
                  getContextAwareUrlParams(globalContext.params),
                ),
                options: [],
              },
            ],
          },
          {
            label: 'Reports',
            key: 'reports',
            route: null,
            options: [
              {
                label: 'Affiliations Schedule',
                key: 'affiliations_schedule',
                route: Routes.state851AffiliateData.MAIN,
                options: [],
              },
              {
                label: 'Alabama Consolidated Report',
                key: 'alabama_consolidated_report',
                route: Routes.consolidatedReport.MAIN,
                options: [],
              },
              {
                label: 'Manual Adjustments Report',
                key: 'manual_adjustments_report',
                route: Routes.manualAdjustmentReport.MAIN,
                options: [],
              },
              {
                label: 'Attachment Details Report',
                key: 'attachment_details_report',
                route: Routes.viewPrintAttachmentDetails.compiledRoute(
                  getContextAwareUrlParams({
                    ...globalContext.params,
                    filingTypeId: filingTypes.CONSOLIDATED,
                  }),
                ),
                options: [],
              },
            ],
          },
          {
            label: 'Informational Data',
            key: 'information_data',
            route: null,
            options: [
              {
                label: 'Consolidation Info',
                key: 'consolidation_info',
                route: Routes.consolidationInfo.MAIN,
                options: [],
              },
            ],
          },
        ],
      },
      {
        label: 'Filing Groups',
        key: 'filing_groups',
        route: Routes.filingGroups.MAIN,
        options: [],
      },
      {
        label: 'Tasks',
        key: 'tasks',
        route: '/compliance/tasks',
        options: [],
      },
      {
        label: 'Tools',
        key: 'tools',
        route: null,
        options: [
          {
            label: 'Diagnostics',
            key: 'diagnostics',
            route: Routes.diagnostics.MAIN,
            access: Routes.diagnostics.MAIN,
            accessOnlyWithEditPermission: true,
            options: [],
          },
          {
            label: 'Generic Account Data Upload',
            key: 'generic_account_data_upload',
            route: Routes.genericAccountDataUpload.MAIN,
            access: Routes.genericAccountDataUpload.MAIN,
            accessOnlyWithEditPermission: true,
            options: [],
          },
          {
            label: 'Generic Info Data Upload',
            key: 'generic_info_data_upload',
            route: Routes.informationalDataUpload.MAIN,
            access: Routes.informationalDataUpload.MAIN,
            accessOnlyWithEditPermission: true,
            options: [],
          },
          {
            label: 'Mult Data Load',
            key: 'mult_data_load',
            route: Routes.multDataUpload.MAIN,
            access: Routes.multDataUpload.MAIN,
            accessOnlyWithEditPermission: true,
            options: [],
          },
          {
            label: 'Refresh Pro-Forma Federal Return',
            key: 'federal_data_refresh',
            route: Routes.federalDataRefresh.MAIN,
            access: Routes.federalDataRefresh.MAIN,
            accessOnlyWithEditPermission: true,
            options: [],
          },
          {
            label: 'Attachments',
            key: 'attachments',
            route: Routes.attachments.MAIN,
            access: Routes.attachments.MAIN,
            accessOnlyWithEditPermission: true,
            options: [],
          },
          {
            label: 'Ownership Allocations Upload',
            key: 'ownership_allocations_upload',
            route: Routes.ownershipAllocationsUpload.MAIN,
            access: Routes.ownershipAllocationsUpload.MAIN,
            accessOnlyWithEditPermission: true,
            options: [],
          },
        ],
      },
    ],
  },
  {
    label: Routes.reports.displayMainName(),
    key: 'planning_and_reports',
    access: Routes.reports.MAIN,
    route: null,
    options: [
      {
        label: 'SLT',
        key: 'SLT',
        route: null,
        options: [
          {
            label: 'Multi-state LE Taxable Income',
            key: 'multi_state_le_taxable_income',
            route: Routes.multiStateLeTaxableIncome.MAIN,
            options: [],
          },
          {
            label: 'High Level Planning Data',
            key: 'high_level_planning_data',
            route: Routes.highLevelPlanningData.MAIN,
            options: [],
          },
          {
            label: 'Detailed Planning Data',
            key: 'detailed_planning_data',
            route: Routes.detailedPlanningData.MAIN,
            options: [],
          },
          {
            label: 'Federal Dividend To State Amounts',
            key: 'dividend_to_state_amounts',
            route: Routes.dividendToStateAmounts.MAIN,
            options: [],
          },
          {
            label: 'State Taxable Income Reconciliation',
            key: 'state_taxable_income_reconciliation',
            route: Routes.stateTaxableIncomeReconciliation.MAIN,
            options: [],
          },
          {
            label: 'State 851 Affiliated Data',
            key: 'state_851_affiliated_data',
            route: Routes.state851AffiliateData.MAIN,
            options: [],
          },
          {
            label: 'Population Analysis',
            key: 'population_analysis',
            route: Routes.populationAnalysis.MAIN,
            options: [],
          },
          {
            label: 'Raw Data for an Entity',
            key: 'raw_data_for_an_entity',
            route: Routes.rawDataForAnEntity.MAIN,
            options: [],
          },
          {
            label: 'Effective Tax Rate',
            key: 'effective_tax_rate',
            route: Routes.effectiveTaxRate.MAIN,
            options: [],
          },
          {
            label: 'Effective Tax Rate - Tax Accounting',
            key: 'effective_tax_rate_tax_accounting',
            route: Routes.effectiveTaxRateTaxAccounting.MAIN,
            options: [],
          },
          {
            label: 'Entity Data',
            key: 'entity_data',
            route: Routes.entityData.MAIN,
            options: [],
          },
          {
            label: 'Estimate Summary',
            key: 'estimate_summary',
            route: Routes.estimateSummary.MAIN,
            options: [],
          },
          {
            label: 'Federal Filing Availability',
            key: 'eFile_federalReturn_availability',
            route: Routes.eFileFederalReturnsAvailability.MAIN,
            options: [],
          },
          {
            label: 'E-File XML Comparison Report',
            key: 'efile_xml_comparison_report',
            route: Routes.eFileXmlComparisonReport.MAIN,
            options: [],
            needCheckTtiAvailable: true,
          },
        ],
      },
      {
        label: 'Form',
        key: 'form',
        route: null,
        options: [
          {
            label: 'Everywhere (EV) Breakdown',
            key: 'everywhere_ev_breakdown',
            route: Routes.everywhereBreakdown.MAIN,
            options: [],
          },
          {
            label: 'Override Report',
            key: 'override_report',
            route: Routes.overrideReport.MAIN,
            options: [],
          },
          {
            label: 'Watermark Usage',
            key: 'watermark_usage',
            route: Routes.watermarkUsage.MAIN,
            options: [],
          },
        ],
      },
      {
        label: 'Audit',
        key: 'audit',
        route: null,
        options: [
          {
            label: 'Entity Analytics Review',
            key: 'entity_analytics_review',
            route: Routes.entityAnalyticsReview.MAIN,
            options: [],
          },
          {
            label: 'Legal Entity Audit Trail',
            key: 'legal_entity_audit_trail',
            route: Routes.legalEntityAuditTrail.MAIN,
            options: [],
          },
          {
            label: 'Intercompany Payables',
            key: 'intercompany_payables',
            route: Routes.intercompanyPayables.MAIN,
            options: [],
          },
          {
            label: 'Intercompany Interest Expense',
            key: 'intercompany_interest_expense',
            route: Routes.intercompanyInterestExpense.MAIN,
            options: [],
          },
          {
            label: 'Intercompany Interest Income',
            key: 'intercompany_interest_income',
            route: Routes.intercompanyInterestIncome.MAIN,
            options: [],
          },
          {
            label: 'Manual Adjustment Report',
            key: 'separate_manual_adjustment_report',
            route: Routes.separateManualAdjustmentReport.MAIN,
            options: [],
          },
        ],
      },
      {
        label: 'TRG Reports',
        key: 'trg_reports',
        route: null,
        options: trgReports.map(({ reportId, name }) => ({
          label: name,
          key: `trg_custom_report_${reportId}`,
          route: Routes.customTrgReport.compiledRoute({
            reportId,
          }),
          options: [],
        })),
      },
      {
        label: 'Run Custom Reports',
        key: 'run_custom_reports',
        route: Routes.runCustomReports.MAIN,
        access: Routes.runCustomReports.MAIN,
        options: [],
      },
    ],
  },
  {
    label: Routes.administration.displayMainName(),
    access: Routes.administration.MAIN,
    key: 'admin',
    route: null,
    options: [
      {
        label: 'Custom Reports',
        key: 'custom_reports',
        route: null,
        options: [
          {
            label: 'Edit Custom Reports',
            key: 'edit_custom_reports',
            route: Routes.editCustomReports.MAIN,
            access: Routes.editCustomReports.MAIN,
            options: [],
          },
          {
            label: 'Run Custom Reports',
            key: 'run_custom_reports',
            route: Routes.runCustomReports.MAIN,
            access: Routes.runCustomReports.MAIN,
            options: [],
          },
          {
            label: 'Edit TRG Reports',
            key: 'edit_trg_reports',
            route: Routes.editTrgReports.MAIN,
            access: Routes.editTrgReports.MAIN,
            options: [],
          },
          {
            label: 'Run TRG Reports',
            key: 'run_trg_reports',
            route: Routes.runTrgReports.MAIN,
            access: Routes.runTrgReports.MAIN,
            options: [],
          },
          {
            label: 'Edit Data Visualizations',
            key: 'edit_data_visualizations',
            route: Routes.editDataVisualizations.MAIN,
            access: Routes.editDataVisualizations.MAIN,
            options: [],
          },
          {
            label: 'Run Data Visualizations',
            key: 'run_data_visualizations',
            route: Routes.runDataVisualizations.MAIN,
            access: Routes.runDataVisualizations.MAIN,
            options: [],
          },
          {
            label: 'Job Queue Status',
            key: 'job_queue_status',
            route: Routes.jobQueueStatusReport.MAIN,
            access: Routes.jobQueueStatusReport.MAIN,
            options: [],
          },
        ],
      },
      {
        label: 'Maintenance',
        key: 'maintenance',
        route: null,
        options: [
          {
            label: 'Users',
            key: 'users',
            route: Routes.usersMaintenance.MAIN,
            access: Routes.usersMaintenance.MAIN,
            options: [],
          },
          {
            label: 'Roles',
            key: 'roles',
            route: Routes.roles.MAIN,
            access: Routes.roles.MAIN,
            options: [],
          },
          {
            label: 'Permissions',
            key: 'permissions',
            route: Routes.permissions.MAIN,
            access: Routes.permissions.MAIN,
            options: [],
          },
          {
            label: 'Accounts',
            key: 'accounts',
            route: Routes.accounts.MAIN,
            access: Routes.accounts.MAIN,
            options: [],
          },
          {
            label: 'Info Accounts',
            key: 'info_accounts',
            route: Routes.infoAccounts.MAIN,
            access: Routes.infoAccounts.MAIN,
            options: [],
          },
          {
            label: 'Entities',
            key: 'entities',
            route: Routes.entitiesMaintenance.MAIN,
            access: Routes.entitiesMaintenance.MAIN,
            options: [],
          },
          {
            label: `Tax Rates and Constants - ${
              globalContext.params.filingTypeId === filingTypes.SEPARATE
                ? 'Single Entity'
                : 'Consolidated'
            }`,
            key: 'tax_rates_and_constants',
            route: Routes.taxRatesAndConstantsMaintenance.MAIN,
            access: Routes.taxRatesAndConstantsMaintenance.MAIN,
            options: [],
          },
          {
            label: 'Lock Return',
            key: 'lock_return',
            route: Routes.lockReturn.MAIN,
            access: Routes.lockReturn.MAIN,
            options: [],
          },
          {
            label: 'Business Groups',
            key: 'business_groups',
            route: Routes.businessGroup.MAIN,
            access: Routes.businessGroup.MAIN,
            options: [],
          },
          {
            label: 'Businesses',
            key: 'businesses',
            route: Routes.businesses.MAIN,
            access: Routes.businesses.MAIN,
            options: [],
          },
          {
            label: 'Consolidations',
            key: 'consolidations',
            route: Routes.adminConsolidations.MAIN,
            access: Routes.adminConsolidations.MAIN,
            options: [],
          },
          {
            label: 'Constants Rules Report',
            key: 'constantsRulesReport',
            route: Routes.constantsRulesReport.MAIN,
            access: Routes.constantsRulesReport.MAIN,
            options: [],
          },
          {
            label: 'Tax Rate Report',
            key: 'tax_rate_report',
            route: Routes.taxRateReport.MAIN,
            access: Routes.taxRateReport.MAIN,
            options: [],
          },
          {
            label: 'Accounts Used in Calcs Report',
            key: 'calculated_accounts_report',
            route: Routes.calculatedAccountsReport.MAIN,
            access: Routes.calculatedAccountsReport.MAIN,
            options: [],
          },
          {
            label: 'Account Grid Based on Calc Attributes',
            key: 'account_grid_report',
            route: Routes.accountGridReport.MAIN,
            access: Routes.accountGridReport.MAIN,
            options: [],
          },
        ],
      },
      {
        label: 'Support',
        key: 'support',
        route: null,
        options: [
          {
            label: 'E-File',
            key: 'e_file_support',
            route: Routes.eFileSupport.MAIN,
            access: Routes.eFileSupport.MAIN,
            options: [],
          },
          {
            label: 'Entity Information',
            key: 'entity_information_diagnostics',
            route: Routes.entityInformationDiagnostics.MAIN,
            access: Routes.entityInformationDiagnostics.MAIN,
            options: [],
          },
          {
            label: 'Federal Data Diagnostics',
            key: 'federal_data_diagnostics',
            route: Routes.federalDataDiagnostics.MAIN,
            access: Routes.federalDataDiagnostics.MAIN,
            options: [],
          },
          {
            label: 'Utilities',
            key: 'utilities',
            route: Routes.utilities.MAIN,
            access: Routes.utilities.MAIN,
            options: [],
          },
          {
            label: 'Instance Data Cleanup',
            key: 'instance_data_cleanup',
            route: Routes.instanceDataCleanup.MAIN,
            access: Routes.instanceDataCleanup.MAIN,
            options: [],
          },
        ],
      },
      {
        label: 'E-File',
        key: 'e_file',
        route: Routes.efileSubmission.MAIN,
        access: Routes.efileSubmission.MAIN,
        options: [],
      },
    ],
  },
  {
    label: 'Development',
    key: 'dev',
    access: [
      Routes.developmentTaxFormsV2.MAIN,
      Routes.developmentGenericFunctions.MAIN,
      Routes.genericScreenDefinition.MAIN,
      Routes.developmentDataModels.MAIN,
      Routes.developmentCalcSpecs.MAIN,
      Routes.expressionBuilder.MAIN,
      Routes.developmentTaxFormEditV2.MAIN,
      Routes.compileExpressions.MAIN,
      Routes.selectionLists.MAIN,
      Routes.returnDefinitions.MAIN,
    ],
    route: null,
    options: [
      {
        label: 'Data Management',
        key: 'data_management',
        route: null,
        options: [
          {
            label: 'Return Definitions',
            key: 'return_definitions',
            route: Routes.returnDefinitions.MAIN,
            options: [],
          },
          {
            label: 'Info Accounts',
            key: 'info_accounts',
            route: Routes.infoAccounts.MAIN,
            options: [],
          },
          {
            label: 'Accounts',
            key: 'accounts',
            route: Routes.accounts.MAIN,
            hasBottomSeparator: true,
            options: [],
          },
          {
            label: 'Calc Specs',
            key: 'calc_specs',
            route: Routes.developmentCalcSpecs.MAIN,
            options: [],
          },
          {
            label: 'Calc Preferences',
            key: 'calc_preferences',
            route: Routes.calcPreferences.MAIN,
            hasBottomSeparator: true,
            options: [],
          },
          {
            label: 'Data Models',
            key: 'data_models',
            route: Routes.developmentDataModels.MAIN,
            hasBottomSeparator: true,
            options: [],
          },
          {
            label: 'Selection Lists',
            key: 'selection_lists',
            route: Routes.selectionLists.MAIN,
            options: [],
          },
          {
            label: 'Checkbox Groups',
            key: 'checkbox_groups',
            route: Routes.checkboxGroups.MAIN,
            hasBottomSeparator: true,
            options: [],
          },
          {
            label: 'Content Deployment',
            key: 'content_deployment',
            route: Routes.contentDeployment.MAIN,
            options: [],
          },
        ],
      },
      {
        label: 'Calculations',
        key: 'calculations',
        route: null,
        options: [
          {
            label: 'Generic Functions',
            key: 'generic_functions',
            route: Routes.developmentGenericFunctions.MAIN,
            hasBottomSeparator: true,
            options: [],
          },
          {
            label: 'Expression Builder',
            key: 'expression_builder',
            route: Routes.expressionBuilder.MAIN,
            options: [],
          },
          {
            label: 'Compile Expressions',
            key: 'compile_expressions',
            route: Routes.compileExpressions.MAIN,
            options: [],
          },
        ],
      },
      {
        label: 'Forms & Screens',
        key: 'forms_and_screens',
        route: null,
        options: [
          {
            label: 'Generic Screens',
            key: 'generic_screens',
            route: Routes.genericScreenDefinition.MAIN,
            hasBottomSeparator: true,
            options: [],
          },
          {
            label: 'Forms List',
            key: 'forms_list',
            route: Routes.developmentTaxFormsV2.MAIN,
            options: [],
          },
          {
            label: 'Form Details',
            key: 'form_details',
            route: Routes.developmentTaxFormEditV2.MAIN,
            hasBottomSeparator: true,
            options: [],
          },
          {
            label: 'Watermarks',
            key: 'watermarks',
            route: Routes.watermarks.MAIN,
            options: [],
          },
          {
            label: 'Attachment Definitions',
            key: 'attachment_definitions',
            route: Routes.attachmentDefinitions.MAIN,
            options: [],
          },
          {
            label: 'Default Binders',
            key: 'default_binders',
            route: Routes.defaultBinders.MAIN,
            options: [],
          },
        ],
      },
      // NOTE: SLT-14465 Turn Off E-file Element Mapping Tool
      // {
      //   label: 'E-File',
      //   key: 'e_file',
      //   route: null,
      //   options: [
      //     {
      //       label: 'Element Mappings',
      //       key: 'element_mappings',
      //       route: Routes.eFileElementMappings.MAIN,
      //       options: [],
      //     },
      //   ],
      // },
    ],
  },
];

export default getNestedMenuSchemaDefinition;
