import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ParamDropdown from '../shared/displayComponents/paramDropdown/paramDropdown.component';
import {
  EverywhereJurisdictionDropdownOption,
  AllJurisdictionDropdownOption,
} from '../shared/constants';
import { ContextTypeAndValue, JurisdictionId } from '../../../common/types';
import { ContextType } from '../shared/enums';

import {
  isFetchingContextSelector,
  jurisdictionIdSelector,
  jurisdictionsOptionsSelector,
  isFetchingJurisdictionsSelector,
  isContextChangesBlockedSelector,
} from './store/selectors';
import { selectJurisdiction } from './store/actions';

const noop = () => null;

interface DevelopmentJurisdictionDropdownProps {
  isDisabled?: boolean;
  enableEverywhereJurisdiction?: boolean;
  enableAllJurisdiction?: boolean;
  showWarningModal?: (params: ContextTypeAndValue) => unknown;
  customOnChangeAction?: (
    handleSelect: (newValue: JurisdictionId) => unknown,
  ) => (newValue: unknown) => unknown;
}

const DevelopmentJurisdictionDropdown = ({
  isDisabled = false,
  enableEverywhereJurisdiction = false,
  enableAllJurisdiction = false,
  showWarningModal = noop,
  customOnChangeAction,
}: DevelopmentJurisdictionDropdownProps) => {
  const dispatch = useDispatch();

  const jurisdictionsOptions = useSelector(jurisdictionsOptionsSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const isDropdownBusy = useSelector(isFetchingJurisdictionsSelector);
  const isFetchingContext = useSelector(isFetchingContextSelector);
  const isContextChangesBlocked = useSelector(isContextChangesBlockedSelector);

  const handleSelect = useCallback(
    (newJurisdiction: JurisdictionId) => {
      if (jurisdictionId !== newJurisdiction) {
        if (isContextChangesBlocked) {
          showWarningModal({ type: ContextType.JURISDICTION_ID_TYPE, value: newJurisdiction });
          return;
        }
        dispatch(selectJurisdiction(newJurisdiction));
      }
    },
    [showWarningModal, dispatch, isContextChangesBlocked, jurisdictionId],
  );

  const jurisdictionDropdownOptions = useMemo(() => {
    const options = enableEverywhereJurisdiction
      ? [EverywhereJurisdictionDropdownOption, ...jurisdictionsOptions]
      : [...jurisdictionsOptions];

    if (enableAllJurisdiction) {
      options.push(AllJurisdictionDropdownOption);
    }

    return options;
  }, [jurisdictionsOptions, enableEverywhereJurisdiction, enableAllJurisdiction]);

  return (
    <ParamDropdown
      label="Jurisdiction"
      value={jurisdictionId}
      options={jurisdictionDropdownOptions}
      handleChange={customOnChangeAction ? customOnChangeAction(handleSelect) : handleSelect}
      isBusy={isDropdownBusy}
      isFetchingContext={isFetchingContext}
      disabled={isDisabled}
      isExpandingOnRender
    />
  );
};

export default DevelopmentJurisdictionDropdown;
