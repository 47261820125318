const GenericScreenRowTypes = {
  BLANK: 'BLANK',
  HEADER: 'HEADER',
  TEXT: 'TEXT',
  DATA: 'DATA',
  LINE: 'LINE',
};

const GenericScreenTextTypes = {
  STANDARD: 'STANDARD',
  TITLE: 'TITLE',
  TOTAL: 'TOTAL',
};

const GenericScreenDataTypes = {
  CATEGORY: 'CATEGORY',
  ACCOUNT: 'ACCOUNT',
};

const GenericScreenDrilldownTypes = {
  BLANK: '',
  NONE: 'NONE',
  STANDARD: 'STD',
};

module.exports = {
  GenericScreenRowTypes,
  GenericScreenTextTypes,
  GenericScreenDataTypes,
  GenericScreenDrilldownTypes,
};
