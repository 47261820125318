import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchBusinessesTypes = getApiActionTypes('USERS/FETCH_BUSINESSES');
export const fetchUsersTypes = getApiActionTypes('USERS/FETCH_USERS');
export const fetchRolesTypes = getApiActionTypes('USERS/FETCH_ROLES');
export const updateUserTypes = getApiActionTypes('USERS/UPDATE_USER');
export const updateUserBusinessesTypes = getApiActionTypes('USERS/UPDATE_USER_BUSINESSES');
export const createUserTypes = getApiActionTypes('USERS/CREATE_USER');
export const deleteUserTypes = getApiActionTypes('USERS/DELETE_USER');
export const validateUserEmailTypes = getApiActionTypes('USERS/VALIDATE_USER_EMAIL');
export const RESET_VALIDATION_RESULT = 'USERS/RESET_VALIDATION_RESULT';
export const RESET_BUSINESSES = 'USERS/RESET_BUSINESSES';
