import reducerFactory from '../reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';

import {
  fetchPdfAttachmentsTypes,
  fetchPdfImageTypes,
  fetchBinderAttachmentTypes,
  createPdfAttachmentTypes,
  updateOrDeletePdfAttachmentsTypes,
} from './types';

const pdfAttachmentsReducer = reducerFactory({
  initialState: {
    pdfAttachments: [],
    pdfImage: null,
    isFetchingPdfAttachments: false,
    isFetchingPdfImage: false,
    isCreatingPdfAttachment: false,
    isUpdatingOrDeletingPdfAttachments: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchPdfAttachmentsTypes,
      payloadKey: 'pdfAttachments',
      fetchingKey: 'isFetchingPdfAttachments',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPdfImageTypes,
      payloadKey: 'pdfImage',
      fetchingKey: 'isFetchingPdfImage',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchBinderAttachmentTypes,
      payloadKey: 'pdfImage',
      fetchingKey: 'isFetchingPdfImage',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createPdfAttachmentTypes,
      fetchingKey: 'isCreatingPdfAttachment',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateOrDeletePdfAttachmentsTypes,
      fetchingKey: 'isUpdatingOrDeletingPdfAttachments',
    }),
  },
});

export default pdfAttachmentsReducer;
