import { StepStatus } from '../enums';

interface isRecheckStatusButtonDisabledParameters {
  stepStatus: StepStatus | null;
  selectedTransmissionHistoryId: string | null;
  isCurrentSubmissionSelected: boolean;
  isCheckingTransmissionStatus: boolean;
}

// stateSubmissionId is 'null' when step fails
const isRecheckStatusButtonDisabled = ({
  stepStatus,
  selectedTransmissionHistoryId,
  isCurrentSubmissionSelected,
  isCheckingTransmissionStatus,
}: isRecheckStatusButtonDisabledParameters) =>
  (selectedTransmissionHistoryId && !isCurrentSubmissionSelected) ||
  isCheckingTransmissionStatus ||
  stepStatus === StepStatus.INITIATED ||
  stepStatus === null;

export default isRecheckStatusButtonDisabled;
