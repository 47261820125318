import {
  defaultColumnDefinitionWithFilter,
  defaultCheckboxColumnDefinition,
} from '../../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ ToggleAllSelectionsHeader, updateRow, countFieldsToUnmap }) => {
  const onCellValueChanged = ({ data }) => {
    updateRow(data);
    countFieldsToUnmap();
  };

  return [
    {
      ...defaultCheckboxColumnDefinition,
      headerComponent: ToggleAllSelectionsHeader,
      headerName: '',
      field: 'toBeUnmapped',
      colId: 'toBeUnmapped',
      width: 30,
      cellStyle: {
        textAlign: 'center',
      },
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Order',
      field: 'displayOrder',
      width: 60,
      suppressSizeToFit: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Field Name',
      field: 'name',
      width: 300,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'DataSet',
      field: 'datasetName',
      width: 200,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'DataItem',
      field: 'dataItemDefinitionName',
      width: 300,
    },
  ];
};

export default getColumnDefinitions;
