import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BusinessEntityId,
  FilingTypeId,
  GlobalContext,
  JurisdictionId,
} from '../../../../common/types';
import { setGlobalContext } from '../store/actions';
import { globalContextSelector } from '../store/selectors';
import { FilingTypes } from '../enums';

const useSetContext = () => {
  const dispatch = useDispatch();
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const setContext = useCallback(
    ({
      businessEntityId,
      jurisdictionId,
      filingType,
    }: {
      businessEntityId: BusinessEntityId | null;
      jurisdictionId: JurisdictionId | null;
      filingType: FilingTypeId | null;
    }) =>
      setGlobalContext({
        taxYear: globalContext.params.taxYear,
        period: globalContext.params.period,
        entity: businessEntityId,
        consolidationId: businessEntityId,
        jurisdictionId,
        filingTypeId:
          (filingType || '').toUpperCase() === FilingTypes.SEPARATE
            ? FilingTypes.SEPARATE
            : FilingTypes.CONSOLIDATED,
        dispatch,
        globalContext,
      }),
    [dispatch, globalContext],
  );

  return { setContext };
};

export default useSetContext;
