import { currencyColumnDefinition, defaultColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Source System',
    field: 'sourceSystemId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'orgId',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Component Id',
    field: 'componentId',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Category',
    field: 'categoryDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 350,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Filing Indicator',
    field: 'processInd',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Amount',
    field: 'amt',
  },
];

export default columnDefinitions;
