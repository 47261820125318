import { getModalActions } from '@tls/ui-modal';

import { apiAction } from '../../../shared/store/apiAction';
import { ModalNames } from '../constants';
import * as api from '../../store/api';

import {
  fetchCustomReportsTypes,
  fetchCustomReportToEditTypes,
  addCustomReportTypes,
  updateCustomReportTypes,
  runCustomQueryTypes,
  runCustomQueryForParamOptionsTypes,
  fetchParamOptionsTypes,
  deleteCustomReportTypes,
} from './types';

export const fetchCustomReports = apiAction({
  types: fetchCustomReportsTypes,
  apiAction: api.fetchCustomReports,
});

export const fetchCustomReportToEdit = apiAction({
  types: fetchCustomReportToEditTypes,
  apiAction: id => api.fetchCustomReport({ id }),
});

export const addCustomReport = apiAction({
  types: addCustomReportTypes,
  apiAction: api.addCustomReport,
});

export const updateCustomReport = apiAction({
  types: updateCustomReportTypes,
  apiAction: api.updateCustomReport,
});

export const runCustomQuery = apiAction({
  types: runCustomQueryTypes,
  apiAction: ({ query, serverSide, params }) => api.runCustomQuery({ query, serverSide, params }),
});

export const deleteCustomReport = apiAction({
  types: deleteCustomReportTypes,
  apiAction: id => api.deleteCustomReport({ id }),
});

export const runCustomQueryForParamOptions = apiAction({
  types: runCustomQueryForParamOptionsTypes,
  apiAction: ({ query, serverSide, params = {} }) =>
    api.runCustomQuery({ query, serverSide, params }),
});

export const fetchParamOptions = ({ paramName, query, params = {} }) =>
  apiAction({
    types: fetchParamOptionsTypes,
    apiAction: async ({ query, params }) => {
      const result = await api.runCustomQuery({ query, params });
      return { paramName, result };
    },
    suppressOutdatedResponses: false,
  })({ paramName, query, params });

export const { showAction: showEditParamModal } = getModalActions(ModalNames.EDIT_PARAM);
