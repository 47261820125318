import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';
import globalContextParamsPropTypes from '../shared/propTypes/globalContextParams';
import getContextAwareUrlParams from '../utils/getContextAwareUrlParams';

const Reports = ({ globalContextParams }) => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Consolidated Report"
      icon="table-data"
      description="Consolidated Report"
      route={Routes.consolidatedReport.MAIN}
    />
    <AuthorizedCard
      title="Tax Forms"
      icon="table-data"
      description="Tax Forms"
      route={Routes.viewPrintTaxReturn.compiledRoute(getContextAwareUrlParams(globalContextParams))}
    />
    <AuthorizedCard
      title="Attachment Detail Report"
      icon="table-data"
      description="Attachment Detail Report"
      route={Routes.viewPrintAttachmentDetails.compiledRoute(
        getContextAwareUrlParams(globalContextParams),
      )}
    />
    <AuthorizedCard
      title="Manual Adjustment Report"
      icon="table-data"
      description="Manual Adjustment Report"
      route={Routes.manualAdjustmentReport.MAIN}
    />
    <AuthorizedCard
      title="State 851 Affiliate Data"
      icon="table-data"
      description="State 851"
      route={Routes.state851AffiliateData.MAIN}
    />
  </div>
);

Reports.propTypes = {
  globalContextParams: globalContextParamsPropTypes,
};

export default Reports;
