import { apiAction } from '../../../../shared/store/apiAction';
import * as api from '../api';
import {
  fetchTaxRatesTypes,
  createTaxRateTypes,
  updateTaxRateTypes,
  deleteTaxRateTypes,
} from '../types/taxRates';

export const fetchTaxRates = apiAction({
  types: fetchTaxRatesTypes,
  apiAction: api.fetchTaxRates,
});

export const deleteTaxRate = apiAction(
  {
    types: deleteTaxRateTypes,
    apiAction: api.deleteTaxRate,
  },
  { successNotificationMessage: () => 'Tax Rate successfully deleted' },
);

export const createTaxRate = apiAction(
  {
    types: createTaxRateTypes,
    apiAction: api.createTaxRate,
  },
  { successNotificationMessage: () => 'Tax Rate successfully created' },
);

export const updateTaxRate = apiAction(
  {
    types: updateTaxRateTypes,
    apiAction: api.updateTaxRate,
  },
  { successNotificationMessage: () => 'Tax Rate successfully updated' },
);
