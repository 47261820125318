import React, { useCallback } from 'react';

import { NavigatorLocalStorageStatus } from '../../../../common/types';

import CollapseSVG from './iconComponent/collapseSVG';
import ExpandSVG from './iconComponent/expandSVG';
import styles from './styles.module.scss';
import { treeWithActive } from './type';

interface CollapseIconProps {
  treeWithActive: treeWithActive[];
  isNavigatorFolderCollapsed: boolean;
  setLocalStorageNavigatorStatus: React.Dispatch<NavigatorLocalStorageStatus>;
}

const CollapseIcon = ({
  treeWithActive,
  isNavigatorFolderCollapsed,
  setLocalStorageNavigatorStatus,
}: CollapseIconProps) => {
  const handleClick = useCallback(() => {
    setLocalStorageNavigatorStatus({
      isNavigatorFolderCollapsed: !isNavigatorFolderCollapsed,
      items: treeWithActive.map(item => ({
        name: item?.name,
        isOpen: isNavigatorFolderCollapsed,
      })),
    });
  }, [treeWithActive, isNavigatorFolderCollapsed, setLocalStorageNavigatorStatus]);

  return (
    <div
      className={`${styles.collapseIcon} ${isNavigatorFolderCollapsed ? styles.showExpandSvg : ''}`}
      onClick={handleClick}
    >
      <div className={styles.expandSvg}>
        <ExpandSVG />
      </div>
      <div className={styles.collapseSvg}>
        <CollapseSVG />
      </div>
    </div>
  );
};

export default CollapseIcon;
