export const CalculationTypes = {
  ALLOW_CONS: 'allowCons',
  AGG_SEP_INSTANCES: 'aggregateSeparateInstances',
};

export const calculationOptions = [
  {
    label: 'Allow Cons Input',
    value: CalculationTypes.ALLOW_CONS,
  },
  {
    label: 'Aggregate Sep. Instances',
    value: CalculationTypes.AGG_SEP_INSTANCES,
  },
];

export const contextTypeOptions = [
  {
    label: 'Entity',
    value: 0,
  },
  {
    label: 'Entity and Partner',
    value: 1,
  },
];

export const WARNING_MODAL_MESSAGE =
  'This data item is being used in a generic function. Please remove from the generic function before deleting this item.';
export const WARNING_MODAL_DATA_MESSAGE = 'It is being used in the following functions:';
export const WARNING_MODAL_TITLE = 'An error occurred while deleting data item';
export const WARNING_CHECKBOX_GROUP_MODAL_TITLE = 'An error occurred while saving dataset';

export const WARNING_EDIT_DATASET_MODAL_TITLE = 'Warning';

export const WARNING_EDIT_DATASET_MODAL_MESSAGE =
  'Changing the name of a DataItem for a Common Data Model will cause any destination DataItems to be deleted next time the Data Model is propagated. Any mappings, expressions or data associated with the DataItem will also be removed.';
export const WARNING_EDIT_CHECKBOX_GROUP_MODAL_MESSAGE =
  'Checkbox Groups must be associated with at least two dataitems.';

export const WARNING_EDIT_DATASET_PRECONFIRM_MODAL_MESSAGE =
  'Only proceed if you fully understand the ramifications of this action.';
export const WARNING_EDIT_DATASET_CONFIRM_MODAL_MESSAGE = 'Do you wish to continue?';
export const WARNING_EDIT_DATASET_CHECKBOX_GROUP_MODAL_MESSAGE =
  'The following Checkbox Groups are only associated with one dataitem:';

export const FIRST_PAGE = '1';
export const CURRENCY_DATA_TYPE = '1';
export const BOOLEAN_DATA_TYPE = '5';
export const STRING_DATA_TYPE = '6';
export const DISPLAY_ORDER_VAL_TO_INCREMENT_BY = 10;
export const DEFAULT_DATA_ITEM_DATA_LENGTH_VAL_FOR_STRING_DATA_TYPE = 0;
export const DEFAULT_DATA_ITEM_DATA_LENGTH_VAL = null;
