import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import SDKSelect from '@tls/ui-select';
import { Tooltip } from '@pwc/appkit-react';

import useBooleanState from '../../hooks/useBooleanState.hook';
import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';

import resizeDropdown from './resizeDropdown';

interface SDKCustomSelectProps<TValue> {
  value?: TValue;
  options?: { label: string | null; value: TValue }[];
  onChange?: (newValueObject: { label: string; value: TValue }) => void;
  onClose?: () => void;
  className?: string;
  formikClassName?: string;
  labelClassName?: string;
  appkitLabel?: string;
  hideLabel?: boolean;
  title?: string;
  hasTooltip?: boolean;
  isClearable?: boolean;
  isExpandingOnRender?: boolean;
  [key: string]: unknown;
}

function SDKCustomSelect<TValue = string | number | boolean | null>({
  appkitLabel = '',
  hideLabel = false,
  className = '',
  title = '',
  formikClassName = '',
  labelClassName = '',
  isClearable = false,
  isExpandingOnRender = false,
  onChange = () => null,
  onClose = () => null,
  hasTooltip = false,
  value = '',
  options,
  ...rest
}: SDKCustomSelectProps<TValue | string>) {
  const selectRef = useRef<HTMLDivElement>(null);

  const [isTooltipDisabled, disableTooltip, enableTooltip] = useBooleanState(true);

  const tooltipLabel = useMemo(
    () => hasTooltip && options?.find(option => option.value === value)?.label,
    [hasTooltip, options, value],
  );

  useEffect(() => {
    if (isExpandingOnRender && selectRef.current) {
      resizeDropdown(selectRef.current);
    }
  }, [value, isExpandingOnRender]);

  useEffect(() => {
    if (!hasTooltip) {
      return;
    }
    const selectValueLabelEl = selectRef.current?.querySelector<HTMLSpanElement>(
      '.Select-value-label',
    );
    if (selectValueLabelEl?.offsetWidth === selectValueLabelEl?.scrollWidth) {
      disableTooltip();
      return;
    }
    enableTooltip();
  }, [disableTooltip, enableTooltip, hasTooltip, tooltipLabel]);

  const handleChange = useCallback(
    (valueObject: { label: string; value: TValue }) => {
      onChange(valueObject);
      if (isExpandingOnRender && selectRef.current) {
        resizeDropdown(selectRef.current);
      }
    },
    [isExpandingOnRender, onChange],
  );

  const handleOnClose = useCallback(() => {
    onClose();
    enableTooltip();
  }, [onClose, enableTooltip]);

  const renderParamDropdownSDKCustomSelect = () => (
    <SDKSelect
      className={classNames('sdk-custom-select', className, {
        'sdk-custom-select--clearable': isClearable,
      })}
      onChange={handleChange}
      onClose={handleOnClose}
      value={value}
      options={options}
      {...rest}
    />
  );

  const renderParamDropdownSDKCustomSelectWithTooltip = () => (
    <Tooltip
      content={<span className="sdk-custom-select-tooltip-content">{tooltipLabel}</span>}
      disabled={isTooltipDisabled}
      placement="top"
      mouseEnterDelay={500}
      tooltipTheme="light"
      hasArrow
      onClick={disableTooltip}
    >
      {renderParamDropdownSDKCustomSelect()}
    </Tooltip>
  );

  return (
    <>
      {hideLabel ? null : (
        <label className={`a-form-label${labelClassName ? ` ${labelClassName}` : ''}`}>
          {appkitLabel}
        </label>
      )}
      <div
        ref={selectRef}
        className={classNames(
          'sdk-custom-select-wrapper sdk-small-custom-context-wrapper',
          formikClassName,
        )}
        title={title}
      >
        {hasTooltip
          ? renderParamDropdownSDKCustomSelectWithTooltip()
          : renderParamDropdownSDKCustomSelect()}
        <AppkitIcon
          className="sdk-custom-select-appkit-icon"
          icon="down-chevron"
          type="fill"
          size={12}
        />
      </div>
    </>
  );
}

export default SDKCustomSelect;
