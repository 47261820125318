const categories = require('./genericCategoryConstants');

const WORKSPACES_NAMES = {
  ENTITY: 'entity',
  CONSOLIDATION: 'consolidation',
};

const WORKSPACE = {
  FILING_STATES: {
    namespace: 'entityFilingStatesReturnWorkspace',
    subPath: `/compliance/${WORKSPACES_NAMES.ENTITY}/details/filing-states/return-workspace`,
  },
  NON_FILING_STATES: {
    namespace: 'entityNonFilingStatesReturnWorkspace',
    subPath: `/compliance/${WORKSPACES_NAMES.ENTITY}/details/non-filing-states/return-workspace`,
  },
  ENTITY_FILING_DECISIONS: {
    namespace: 'entityFilingDecisionsReturnWorkspace',
    subPath: `/compliance/${WORKSPACES_NAMES.ENTITY}/details/filing-decisions/return-workspace`,
  },
  CONSOLIDATION: {
    namespace: 'consolidationReturnWorkspace',
    subPath: `/compliance/${WORKSPACES_NAMES.CONSOLIDATION}/details/filing-entities/return-workspace`,
  },
};

const workspaceBaseRoutes = Object.values(WORKSPACE).reduce(
  (prev, { namespace, subPath }) => ({
    ...prev,
    [namespace]: subPath,
  }),
  {},
);

const baseRoutes = {
  development: '/development',
  efile: '/efile',
  proforma: '/proforma',
  ...workspaceBaseRoutes,
};

const taxNames = {
  INCOME_TAX: 'income',
  FRANCHISE_TAX: 'franchise',
  ESTIMATED_TAX: 'estimated',
};

const isValidTaxName = taxName =>
  Object.values(taxNames).some(definedTaxName => taxName === definedTaxName);

const taxTypes = {
  [taxNames.INCOME_TAX]: 'I',
  [taxNames.FRANCHISE_TAX]: 'F',
  [taxNames.ESTIMATED_TAX]: 'E',
};

const taxSummariesMainNames = {
  [taxNames.INCOME_TAX]: 'Income Tax Summary',
  [taxNames.FRANCHISE_TAX]: 'Franchise Tax Summary',
  [taxNames.ESTIMATED_TAX]: 'Estimated Tax Summary',
};

const genericCategoryMainNames = {
  [categories.EV_NONBUSINESS_INCOME]: 'Less: NonBusiness Income',
  [categories.INCOME_MODIFICATIONS]: 'Modifications',
  [categories.FEDERAL_TAXABLE_INCOME]: 'Federal Taxable Income',
  [categories.FTAX_MODIFICATIONS]: 'Modifications to Franchise Tax Base',
  [categories.ADJUSTMENTS_TO_STI]: 'Adjustments to STI',
  [categories.STATE_NONBUSINESS_INCOME]: 'State-Specific Nonbusiness Income',
  [categories.ADJ_TO_TAX_BEFORE_CREDITS]: 'Adjustments to Tax Before Credits',
  [categories.CREDITS]: 'Less: Non-Refundable Credits',
  [categories.ADJ_TO_TAX_AFTER_CREDITS]: 'Adjustments to Tax After Credits',
  [categories.TAX_PAYMENTS]: 'Less: Tax Payments and Refundable Credits',
  [categories.FTAX_PAYMENTS]: 'Franchise Tax Payments',
  [categories.FTAX_ADJ_TO_TAX_AFTER_CRED]: 'Adjustments to Franchise Tax After Credits',
  [categories.FTAX_CREDITS]: 'Franchise Tax Credits',
  [categories.FTAX_ADJ_TO_TAX_BEFORE_CRED]: 'Adjustments to Franchise Tax Before Credits',
  [categories.FTAX_POST_APP_ADJ]: 'Adjustments to State Tax Base - Franchise',
  [categories.FTAX_BASE]: 'Franchise Tax Base',
  [categories.PAYROLL_WITHIN]: 'Payroll Within',
  [categories.PAYROLL_EV]: 'Payroll Everywhere',
  [categories.SALES_WITHIN]: 'Sales Within',
  [categories.SALES_EV]: 'Sales Everywhere',
  [categories.FTAX_PAYROLL_WITHIN]: 'Franchise Tax Payroll Within',
  [categories.FTAX_PAYROLL_EV]: 'Franchise Tax Payroll Everywhere',
  [categories.FTAX_SALES_WITHIN]: 'Franchise Tax Sales Within',
  [categories.FTAX_SALES_EV]: 'Franchise Tax Sales Everywhere',

  [categories.FTAX_BEG_PROPERTY_WI]: 'Total Property Within - Beginning',
  [categories.FTAX_END_PROPERTY_WI]: 'Total Property Within - Ending',
  [categories.FTAX_RENT_WI]: 'Uncapitalized Rent Expense',
  [categories.FTAX_BEG_PROPERTY_EV]: 'Total Property Everywhere - Beginning',
  [categories.FTAX_END_PROPERTY_EV]: 'Total Property Everywhere - Ending',
  [categories.FTAX_RENT_EV]: 'Uncapitalized Rent Expense',

  [categories.BEGINNING_PROPERTY_WITHIN]: 'Total Property Within - Beginning',
  [categories.END_PROPERTY_WITHIN]: 'Total Property Within - Ending',
  [categories.RENT_WITHIN]: 'Uncapitalized Rent Expense',
  [categories.BEGINNING_PROPERTY_EV]: 'Total Property Everywhere - Beginning',
  [categories.END_PROPERTY_EV]: 'Total Property Everywhere - Ending',
  [categories.RENT_EV]: 'Uncapitalized Rent Expense',
};

module.exports = {
  WORKSPACE,
  WORKSPACES_NAMES,
  baseRoutes,
  workspaceBaseRoutes,
  taxNames,
  isValidTaxName,
  taxTypes,
  taxSummariesMainNames,
  genericCategoryMainNames,
};
