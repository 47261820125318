import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@tls/ui-modal';

import { useMutationAddMissingDefaultForm } from '../../queries/utilities';
import { globalContextSelector } from '../../store/selectors';

interface AddDefaultFormsModalProps {
  hideModal: () => void;
  visible: boolean;
}

const AddDefaultFormsModal = ({ hideModal, visible }: AddDefaultFormsModalProps) => {
  const globalContext = useSelector(globalContextSelector);
  const { taxYear, period } = globalContext;
  const { mutateAsync: addBinderDefaultForm } = useMutationAddMissingDefaultForm();

  const onSubmitAction = useCallback(() => {
    addBinderDefaultForm({ taxYear, period });
    hideModal();
  }, [taxYear, period, addBinderDefaultForm, hideModal]);

  return (
    <Modal
      title="Add Default Forms"
      visible={visible}
      closeAction={hideModal}
      dismissText="Cancel"
      dismissAction={hideModal}
      submitText="Yes"
      submitAction={onSubmitAction}
    >
      <p>
        Are you sure you want to add the default forms to all empty returns in this instance? This
        action will not overwrite returns that currently have forms.
      </p>
    </Modal>
  );
};

export default AddDefaultFormsModal;
