import { createSelector } from 'reselect';
import { TOTAL_DESC } from '@common-packages/shared-constants';

export const isFetchingAccountLineItemDetailSelector = ({ shared }) =>
  shared.accountLineItemDetail.isFetchingAccountLineItemDetail;

export const accountLineItemDetailSelector = createSelector(
  [({ shared }) => shared.accountLineItemDetail.accountLineItemDetail],
  accountLineItemDetail => {
    if (!accountLineItemDetail.length) {
      return [];
    }

    const totals = accountLineItemDetail.reduce(
      (acc, curr) => ({
        dcsBookAmount: acc.dcsBookAmount + curr.dcsBookAmount,
        dcsAdjAmount: acc.dcsAdjAmount + curr.dcsAdjAmount,
        dcsM1Amount: acc.dcsM1Amount + curr.dcsM1Amount,
        dcsTaxAmount: acc.dcsTaxAmount + curr.dcsTaxAmount,
      }),
      {
        dcsBookAmount: 0,
        dcsAdjAmount: 0,
        dcsM1Amount: 0,
        dcsTaxAmount: 0,
      },
    );

    const totalRow = {
      accountDescription: TOTAL_DESC,
      isTotalRow: true,
      dcsBookAmount: totals.dcsBookAmount,
      dcsAdjAmount: totals.dcsAdjAmount,
      dcsM1Amount: totals.dcsM1Amount,
      dcsTaxAmount: totals.dcsTaxAmount,
    };

    return [...accountLineItemDetail, totalRow];
  },
);
