export const taxSummariesColumnKeys = {
  ACCOUNT: 'account',
  DATA_COLLECTION: 'dataCollection',
  ADJUSTMENTS: 'adjustments',
  CURRENT_YEAR: 'currentYear',
  PRIOR_YEAR: 'priorYear',
};

export const estimatedTaxSummariesColumnKeys = {
  ACCOUNT: 'account',
  PRIOR_TAX_YEAR_EXTENSION: 'priorTaxYearExtension',
  PRIOR_TAX_YEAR_RETURN: 'priorTaxYearReturn',
  FIRST_QUARTER: 'firstQuarter',
  SECOND_QUARTER: 'secondQuarter',
  THIRD_QUARTER: 'thirdQuarter',
  FOURTH_QUARTER: 'fourthQuarter',
  CURRENT_TAX_YEAR_EXTENSION: 'currentTaxYearExtension',
};

export const rowInfoKey = 'rowInfo';
