import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Banner } from '@pwc/appkit-react';

import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Loading from '../../shared/displayComponents/loading.component';
import Radio from '../../shared/forms/radioFormik/radioFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import { SelectOptionPropTypes } from '../../shared/forms/propTypes';

import yesNoOptions, { YES, NO } from './constants';
import { partnershipAllocationPercentagesPropTypes, formValuesPropTypes } from './propTypes';

const AddOwnerForm = ({
  values,
  isFetchingPercentageTypes,
  percentageTypesOptions,
  setValues,
  partnersOptions,
  isFetchingPartners,
  partnershipAllocationPercentages,
}) => {
  const onPartnerChange = useCallback(
    partner => {
      if (!partner) {
        return;
      }

      const isNewPlugOwnerPossible = !partnershipAllocationPercentages?.some(
        ({ partnerId, plugOwner }) => partnerId !== partner.value && plugOwner === YES,
      );

      setValues({
        ...values,
        plugOwner: isNewPlugOwnerPossible ? YES : NO,
        partnerId: partner.value,
      });
    },
    [setValues, values, partnershipAllocationPercentages],
  );

  return (
    <div>
      <Field
        className="modal-field"
        label="Entity Id (Partnership Return Filer)"
        name="entityId"
        component={Input}
        autoComplete="off"
        disabled
      />
      <Field
        className="modal-field"
        label="Entity Name"
        name="entityName"
        component={Input}
        autoComplete="off"
        disabled
      />
      <div className="modal-field">
        <Loading small isLoading={isFetchingPartners}>
          {partnersOptions.length === 0 && (
            <Banner
              status="warning"
              content="There are no defined partners for this context data. You will not be able to create owners."
            />
          )}
          <SDKCustomSelect
            appkitLabel="Partner Id"
            onChange={onPartnerChange}
            options={partnersOptions}
            value={values.partnerId}
            virtualized
          />
        </Loading>
      </div>
      <Field
        className="modal-field"
        label="Legal Classification"
        name="legalClassification"
        value="PSHIP"
        component={Input}
        autoComplete="off"
        disabled
      />
      <Field
        label="Plug Owner"
        name="plugOwner"
        component={Radio}
        options={yesNoOptions}
        autoComplete="off"
      />
      <div className="modal-field">
        <Loading small isLoading={isFetchingPercentageTypes}>
          <Select
            appkitLabel="Percentage Type"
            name="percentageType"
            options={percentageTypesOptions}
            value={values.percentageType}
            showSearchOnToggle
          />
        </Loading>
      </div>
      <Field
        className="modal-field"
        label="Percentage"
        name="percentage"
        component={Input}
        type="number"
        max={100}
        min={0}
        autoComplete="off"
      />
    </div>
  );
};

AddOwnerForm.propTypes = {
  partnershipAllocationPercentages: PropTypes.arrayOf(partnershipAllocationPercentagesPropTypes),
  values: formValuesPropTypes.isRequired,
  setValues: PropTypes.func.isRequired,
  isFetchingPercentageTypes: PropTypes.bool.isRequired,
  isFetchingPartners: PropTypes.bool.isRequired,
  percentageTypesOptions: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
  partnersOptions: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
};

export default AddOwnerForm;
