export const pdfTypes = {
  PDF_LIB: 'PDFLib',
  BNA: 'BNA',
};

export const profileIds = {
  PSHP: 'PSHP',
  DEFAULT: 'DEFAULT',
};

export const TABS_TYPES = {
  FORM: 'Form',
  PAGES: 'Pages',
};

export const tabsDefinitions = [
  { type: TABS_TYPES.FORM, label: 'Form' },
  { type: TABS_TYPES.PAGES, label: 'Pages' },
];
