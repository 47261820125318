import React from 'react';
import classNames from 'classnames';

import Loading from '../../displayComponents/loading.component';
import ErrorMessage from '../error/errorMessage.component';

import SDKCustomSelect from './sdkCustomSelect.component';

interface InlineSelectProps<TValue> {
  name: string;
  error?: string;
  onChange?: (newValueObject: { label: string; value: TValue }) => void;
  isClearable?: boolean;
  isBusy?: boolean;
  appkitLabel?: string;
  shouldDisplayErrorMessage?: boolean;
}

function InlineSelect<TValue = string | number | boolean | null>({
  name,
  error,
  onChange,
  isClearable = false,
  isBusy = false,
  appkitLabel,
  shouldDisplayErrorMessage,
  ...props
}: InlineSelectProps<TValue | string>) {
  return (
    <div className="global-context-dropdown sdk-custom-select-global-context-dropdown">
      {appkitLabel && <label className="a-form-label">{appkitLabel}</label>}
      <Loading small isLoading={isBusy}>
        <div className="global-context-dropdown-wrapper">
          <SDKCustomSelect
            className="global-context-select sdk-custom-select global-context-small-select"
            formikClassName={classNames({ 'with-error': Boolean(error) })}
            name={name}
            onChange={onChange}
            virtualized
            isClearable={isClearable}
            {...props}
          />
          {shouldDisplayErrorMessage && <ErrorMessage error={error} />}
        </div>
      </Loading>
    </div>
  );
}

export default InlineSelect;
