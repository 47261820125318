import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import { BreadcrumbRoute } from '../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';
import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';

import ViewFormWorkpaper from './viewFormWorkpaper.container';

const ViewFormWorkpaperWithContext = withContextWrapper()(ViewFormWorkpaper);

const ViewFormWorkpaperBreadcrumbRoute = () => <BreadcrumbRoute path={Routes.viewFormWorkpaper} />;

const ViewFormWorkpaperPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: ViewFormWorkpaperBreadcrumbRoute,
}))(GlobalContextPage);

const ViewFormWorkpaperRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.viewFormWorkpaper.MAIN}
    wrapper={ViewFormWorkpaperPage}
    component={ViewFormWorkpaperWithContext}
    hideGlobalContext
  />
);

export default ViewFormWorkpaperRoute;
