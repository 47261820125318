import { CalcPreferenceDataTypes } from '@common-packages/shared-constants';
import {
  ICellEditorParams,
  NewValueParams,
  CellClassParams,
  ColDef,
  ColGroupDef,
  ValueGetterParams,
  ValueFormatterParams,
} from 'ag-grid-community';

import { UpdateRowFunc } from '../editMode/types';
import ConditionalSelectionListEditorSelectorFactory from '../displayComponents/editorSelector/conditionalSelectionListEditorSelector.factory';
import {
  CalcPreferenceChildColumnBlueprint,
  CalcPreferenceColumnBlueprint,
  CalcPreferenceOptions,
} from '../../../../routes/shared/types';

import { NumberCellEditor } from './cellRenderers';

import { defaultNoDataColumn } from '.';

const valueSelectOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];

const getCellStyles = (
  baseStyle: string | undefined,
  dataType: string,
  cellValue: string | number,
) => {
  const isColumnBoolean =
    dataType === CalcPreferenceDataTypes.BOOLEAN ||
    (cellValue && ['YES', 'NO'].includes(cellValue?.toString().toUpperCase()));

  if (isColumnBoolean) {
    return { textAlign: 'center' };
  }

  return baseStyle;
};

const getColumnsBasedOnColumnBlueprint = ({
  updateRow,
  valueSetter,
  isInEditMode,
  columnBlueprint,
  defaultColumnConfig,
  options = [],
}: {
  updateRow: UpdateRowFunc<unknown>;
  valueSetter?: (params: NewValueParams) => boolean;
  isInEditMode: boolean;
  columnBlueprint: CalcPreferenceColumnBlueprint | CalcPreferenceChildColumnBlueprint;
  defaultColumnConfig: Partial<ColDef | ColGroupDef>;
  options?: CalcPreferenceOptions;
}): (ColDef | ColGroupDef)[] => {
  const isChildColumnBlueprint = (
    data: CalcPreferenceColumnBlueprint | CalcPreferenceChildColumnBlueprint,
  ): data is CalcPreferenceChildColumnBlueprint => {
    return !(data as CalcPreferenceColumnBlueprint).children;
  };
  if (!isChildColumnBlueprint(columnBlueprint)) {
    if (columnBlueprint.children && !columnBlueprint.hasSingleValue) {
      return columnBlueprint.children
        .map(child =>
          getColumnsBasedOnColumnBlueprint({
            updateRow,
            valueSetter,
            isInEditMode,
            columnBlueprint: child,
            defaultColumnConfig,
            options: columnBlueprint.selectOptions?.[child.displayName],
          }),
        )
        .flat();
    }
  } else {
    const isColumnBoolean = columnBlueprint.dataType === CalcPreferenceDataTypes.BOOLEAN;
    const isNumberInput = columnBlueprint.dataType === CalcPreferenceDataTypes.NUMBER;

    const displayValueGetter = ({ data }: ValueGetterParams | ValueFormatterParams) =>
      data.calcOptions?.[columnBlueprint.field];

    const displayValueFormatter = ({ data }: ValueGetterParams | ValueFormatterParams) => {
      const value = data.calcOptions?.[columnBlueprint.field];
      // for non-boolean calc preferences column, value labels are first indexed with jurisdiction id
      // because there may be different labels for the same preference
      return columnBlueprint.valueLabelsMap
        ? (value &&
            ((columnBlueprint.valueLabelsMap as Record<string, string>)?.[value] ||
              (columnBlueprint.valueLabelsMap as {
                [jurisdictionId: string]: { [key: string]: string };
              })[data?.jurisdictionId || '']?.[value])) ||
            ''
        : value;
    };
    const getColumnBlueprintSelectionList = (
      param: ICellEditorParams,
      options: CalcPreferenceOptions,
    ) => {
      let newData = {};
      if (isColumnBoolean) {
        newData = {
          selectionListItems: valueSelectOptions,
        };
      } else {
        newData = {
          selectionListItems: options.map(({ name, displayName }) => ({
            label: displayName,
            value: name,
          })),
        };
      }

      return {
        ...param,
        data: newData,
      };
    };
    const booleanColumnWidth = 85;
    const defaultColumnWidth = 125;
    const cellEditorParams = isNumberInput
      ? {
          cellEditor: NumberCellEditor,
          cellEditorParams: {
            formatValue: (value: string) => parseFloat(value),
          },
        }
      : {
          cellEditorSelector: (param: ICellEditorParams) => {
            if (isNumberInput) {
              return {
                component: 'NumberCellEditor',
                params: {
                  value: Number(param.data[columnBlueprint.field]) || 0,
                },
              };
            }
            const editorSelector = ConditionalSelectionListEditorSelectorFactory({
              isClearable: false,
            });

            return editorSelector(getColumnBlueprintSelectionList(param, options));
          },
        };

    return [
      {
        ...(isNumberInput
          ? defaultColumnConfig
          : { ...defaultColumnConfig, isSelectionList: true }),
        headerName: columnBlueprint.displayName,
        headerTooltip: columnBlueprint.displayName,
        field: columnBlueprint.field,
        valueGetter: displayValueGetter,
        valueFormatter: displayValueFormatter,
        filterValueGetter: displayValueFormatter,
        valueSetter,
        editable: ({ data }: ICellEditorParams) => isInEditMode && data.taxYearEnding,
        pinned: columnBlueprint.pinned,
        lockPinned: columnBlueprint.lockPinned,
        ...cellEditorParams,
        cellRendererParams: { isDisabled: !isInEditMode },
        onCellValueChanged: ({ data }: NewValueParams) => {
          updateRow(data);
        },
        width:
          columnBlueprint.width ||
          (isColumnBoolean ? booleanColumnWidth : columnBlueprint.width || defaultColumnWidth),
        minWidth:
          columnBlueprint.minWidth ||
          (isColumnBoolean ? booleanColumnWidth : columnBlueprint.minWidth || defaultColumnWidth),
        cellStyle: (params: CellClassParams) =>
          getCellStyles(columnBlueprint.cellStyle, columnBlueprint.dataType || '', params.value),
        headerClass: columnBlueprint.headerClass || null,
        hide: columnBlueprint.hide,
      },
    ] as (ColDef | ColGroupDef)[];
  }
  return [];
};

const columnDefinitions = ({
  updateRow = () => null,
  valueSetter,
  isInEditMode = false,
  columnsBlueprint,
  defaultColumnConfig,
}: {
  updateRow: UpdateRowFunc<unknown>;
  valueSetter?: (params: NewValueParams) => boolean;
  isInEditMode: boolean;
  columnsBlueprint: CalcPreferenceColumnBlueprint[];
  defaultColumnConfig: Partial<ColDef | ColGroupDef>;
}) => {
  // fallback for no data to show user "no rows to show"
  if (!columnsBlueprint.length) {
    return [defaultNoDataColumn];
  }

  return columnsBlueprint
    .map(columnBlueprint => {
      /*
      TODO: SLT-6426 We have to check "column.hasSingleValue" functionality for screens
        - /admin/consolidations
        - /admin/tax-rates-and-constants-maintenance
        - /:globalContextRoute/filing-decisions-v2
    */
      return getColumnsBasedOnColumnBlueprint({
        updateRow,
        valueSetter,
        isInEditMode,
        columnBlueprint,
        defaultColumnConfig,
      });
    })
    .flat();
};

export default columnDefinitions;
