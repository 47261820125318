import { adminColumnDefinition, defaultColumnDefinition } from '../../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onDeleteIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    onDeleteIconClick,
  });

  return [
    {
      ...defaultColumnDefinition,
      headerName: '',
      field: 'rowId',
      width: 30,
      rowDrag: true,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Error',
      field: 'error',
      colId: 'errorCol',
      width: 30,
      hide: true,
    },
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Row',
      field: 'rowId',
      width: 40,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Row Type',
      field: 'rowType',
      width: 100,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Category',
      field: 'categoryId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'accountId',
      width: 340,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Text',
      field: 'text',
      width: 400,
    },
  ];
};

export default getColumnDefinitions;
