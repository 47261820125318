import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';
import {
  getCheckBoxGroupNameColumnDefinition,
  getCheckBoxGroupDescriptionColumnDefinition,
  getCheckBoxGroupValueColumnDefinition,
} from '../../shared/columnDefinitions/checkBoxGroup.columnDefinition';

const getColumnDefinitions = ({
  rowData,
  checkboxGroups,
  isInEditMode,
  updateGroup,
  updateRow,
}) => [
  {
    ...getCheckBoxGroupNameColumnDefinition({ checkboxGroups }),
    suppressMenu: false,
    menuTabs: ['filterMenuTab'],
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Page No',
    field: 'page',
    width: 70,
    cellStyle: {
      textAlign: 'center',
    },
    suppressMenu: false,
    menuTabs: ['filterMenuTab'],
  },
  {
    ...getCheckBoxGroupDescriptionColumnDefinition({ checkboxGroups }),
    suppressMenu: false,
    menuTabs: ['filterMenuTab'],
    width: 400,
  },
  {
    ...getCheckBoxGroupValueColumnDefinition({
      rowData,
      isInEditMode,
      updateGroup,
      updateRow,
    }),
    suppressMenu: false,
    menuTabs: ['filterMenuTab'],
    width: 300,
  },
];

export default getColumnDefinitions;
