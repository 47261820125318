import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import { SELECT_ENTITY, SELECT_TAX_YEAR, SELECT_PERIOD } from '../../shared/store/types';
import getFieldValue from '../../utils/getFieldValue';

import {
  fetchNonFilingStatesTypes,
  ENTITIES_NON_FILING_STATES_SELECT_JURISDICTION,
  checkIfNotesExistTypes,
  checkIfPartnershipInformationExistTypes,
  getFederalDetailsLinkTypes,
  checkIsPartnershipTypeTypes,
  fetchEntitiesDataTypes,
  checkIfLegalEntityExistTypes,
} from './types';

export const initialState = {
  isFetchingNonFilingStates: false,
  nonFilingStates: [],

  nonFilingStatesJurisdictionId: null,
  previousNonFilingStatesJurisdictionId: null,

  isFetchingTaskInfo: false,

  ifNotesExist: false,
  ifPartnershipInformationExist: false,
  federalDetailsLinkWithPlaceholders: '',

  isPartnershipType: null,
  isFetchingPartnershipType: false,

  entitiesData: [],
  isFetchingEntitiesData: false,
  taxYearWithFetchedEntitiesData: null,
  periodWithFetchedEntitiesData: null,

  ifLegalEntityExist: false,
};

export default reducerFactory({
  initialState,
  actionHandlers: {
    [SELECT_ENTITY]: state => ({
      ...state,
      nonFilingStatesJurisdictionId: null,
      previousNonFilingStatesJurisdictionId: state.nonFilingStatesJurisdictionId,
    }),

    ...getApiActionTypesHandlers({
      apiTypes: checkIfLegalEntityExistTypes,
      payloadKey: 'ifLegalEntityExist',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchNonFilingStatesTypes,
      payloadKey: 'nonFilingStates',
      fetchingKey: 'isFetchingNonFilingStates',
      getAdditionalState: (state, payload) => ({
        nonFilingStatesJurisdictionId: getFieldValue({
          currentValueFieldName: 'jurisdictionId',
          payloadFieldName: 'jurisdictionId',
          state,
          payload,
        }),
      }),
      initialAdditionalState: {
        nonFilingStatesJurisdictionId: null,
      },
    }),

    ...getApiActionTypesHandlers({
      apiTypes: checkIfNotesExistTypes,
      payloadKey: 'ifNotesExist',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: checkIfPartnershipInformationExistTypes,
      payloadKey: 'ifPartnershipInformationExist',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: checkIsPartnershipTypeTypes,
      payloadKey: 'isPartnershipType',
      fetchingKey: 'isFetchingPartnershipType',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchEntitiesDataTypes,
      payloadKey: 'entitiesData',
      fetchingKey: 'isFetchingEntitiesData',
      getAdditionalState: (
        state,
        { entitiesData, taxYearWithFetchedEntitiesData, periodWithFetchedEntitiesData },
      ) => ({
        entitiesData,
        taxYearWithFetchedEntitiesData,
        periodWithFetchedEntitiesData,
      }),
      initialAdditionalState: {
        entitiesData: [],
        taxYearWithFetchedEntitiesData: null,
        periodWithFetchedEntitiesData: null,
      },
    }),
    [getFederalDetailsLinkTypes.SUCCESS]: (state, payload) => ({
      ...state,
      federalDetailsLinkWithPlaceholders: payload ? payload.federalDetailsLinkWithPlaceholders : '',
    }),
    [ENTITIES_NON_FILING_STATES_SELECT_JURISDICTION]: (state, payload) => ({
      ...state,
      nonFilingStatesJurisdictionId: payload,
      previousNonFilingStatesJurisdictionId: state.nonFilingStatesJurisdictionId,
    }),

    [SELECT_TAX_YEAR]: state => ({
      ...state,

      entityId: null,
      previousEntityId: state.entityId,

      nonFilingStatesJurisdictionId: null,
      previousNonFilingStatesJurisdictionId: state.nonFilingStatesJurisdictionId,
    }),

    [SELECT_PERIOD]: state => ({
      ...state,

      entityId: null,
      previousEntityId: state.entityId,

      nonFilingStatesJurisdictionId: null,
      previousNonFilingStatesJurisdictionId: state.nonFilingStatesJurisdictionId,
    }),
  },
});
