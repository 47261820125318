import React, { useState, useCallback, ChangeEvent } from 'react';
import { Tab, Tabs, Tooltip } from '@pwc/appkit-react';
import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';
import { useSelector } from 'react-redux';

import withPermissions from '../../shared/authorization/withPermissionsHoc.container';
import {
  ActionMenu,
  ActionMenuItem,
} from '../../shared/displayComponents/actionMenu/actionMenu.component';
import {
  customerPermissionsSelector,
  periodSelector,
  taxYearSelector,
} from '../../shared/store/selectors';
import downloadFile from '../../utils/downloadFile';

import styles from './entitiesMaintenance.module.scss';
import EntityMaintenance from './entityMaintenance.component';
import IndividualMaintenance from './individualMaintenance.component';

enum TabsTypes {
  Entities = 'Entities',
  Individuals = 'Individuals',
}

const tabsDefinitions = [
  { type: TabsTypes.Entities, label: 'Entities' },
  { type: TabsTypes.Individuals, label: 'Individuals' },
];

const getActiveTabComponent = (activeTab: TabsTypes) => {
  switch (activeTab) {
    case TabsTypes.Entities:
      return <EntityMaintenance />;
    case TabsTypes.Individuals:
      return <IndividualMaintenance />;
    default:
      throw new Error('Unsupported tab type');
  }
};

const EntitiesMaintenance = () => {
  const customerPermissions = useSelector(customerPermissionsSelector);
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  // Setting up Permissions and feature flags for individual tab
  const hasPermissionToViewIndividuals = hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.INDIVIDUAL_PARTNERS],
  });
  const individualPartnersFeatureFlagEnabled = hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.INDIVIDUAL_PARTNERS_FEATURE_FLAG],
  });
  const activeTabDefinitions = individualPartnersFeatureFlagEnabled
    ? tabsDefinitions
    : [tabsDefinitions[0]];

  // Values for button to export data
  const downloadReportXlsxUrl = `/api/admin/maintenance/entities/download-report/${taxYear}/${period}`;
  const downloadGTWEntityImportReport = useCallback(() => {
    downloadFile({
      route: downloadReportXlsxUrl,
    });
    return null;
  }, [downloadReportXlsxUrl]);

  // Setting default for tab and button
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);

  const handleTabChange = useCallback(
    (_: ChangeEvent, tab: TabsTypes) => {
      if (hasPermissionToViewIndividuals) {
        setActiveTab(tab);
      }
    },
    [hasPermissionToViewIndividuals],
  );

  return (
    <>
      <div className="row">
        <div className="col-8">
          <div className="w-50">
            <Tabs
              className="tabs-container mb-0 mt-2 p-0"
              value={activeTab}
              onChange={handleTabChange}
              size="md"
            >
              {activeTabDefinitions.map(({ label, type }) => (
                <Tab
                  id={type}
                  value={type}
                  label={
                    <div className={styles.tabToolTip}>
                      <Tooltip
                        content="You must have PII permission to access this tab"
                        placement="right"
                        disabled={hasPermissionToViewIndividuals || type === TabsTypes.Entities}
                      >
                        {label}
                      </Tooltip>
                    </div>
                  }
                  key={type}
                  disabled={!hasPermissionToViewIndividuals && type === TabsTypes.Individuals}
                />
              ))}
            </Tabs>
          </div>
        </div>
        <div className="col-4">
          <div className="add-button-column">
            <ActionMenu>
              <ActionMenuItem
                text="Generate GTW Entity Import"
                onClick={downloadGTWEntityImportReport}
              />
            </ActionMenu>
          </div>
        </div>
      </div>
      <div className="row grid-row h-100 position-relative mt-1">
        {getActiveTabComponent(activeTab)}
      </div>
    </>
  );
};

export default withPermissions(EntitiesMaintenance);
