import { OrgProfileQueryParams, OrgProfileQueryResponse } from '@tls/slt-types';

import {
  ConsolidationFilingDecisionsQueryParams,
  ConsolidationFilingDecisionsQueryResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export const useQueryOrgProfile = ({
  params,
  enabled,
}: {
  params: OrgProfileQueryParams;
  enabled: boolean;
}) =>
  useCustomQuery<OrgProfileQueryResponse>([QueryKeys.FilingDecisions.OrgProfileData, params], {
    url: `/api/filing-decisions-v2/org-profile/${params.taxYear}/${params.period}/${params.jurisdictionId}/${params.businessEntityId}`,
    enabled,
    errorMessage: 'Fetching filing decisions failed',
  });

export const useQueryConsolidationFilingDecisions = ({
  params,
  enabled,
}: {
  params: ConsolidationFilingDecisionsQueryParams;
  enabled: boolean;
}) =>
  useCustomQuery<ConsolidationFilingDecisionsQueryResponse>(
    [QueryKeys.FilingDecisions.ConsolidationData, params],
    {
      url: `/api/filing-decisions-v2/consolidation/${params.taxYear}/${params.period}/${params.jurisdictionId}/${params.businessEntityId}`,
      enabled,
      errorMessage: 'Fetching consolidation filing decisions failed',
    },
  );
