import React from 'react';

import { GtwUploadFileResponse } from '../../../../../common/types';
import { SourceId } from '../../../shared/enums/gtwFileUpload';

import FileFormatRequirementsItems from './fileFormatRequirementsItems.component';
import fileFormatStyles from './fileFormat.module.scss';

interface FileFormatTextsProps {
  sourceOption: SourceId;
  fileFormatCheckResults: GtwUploadFileResponse;
}

const FileFormatInstructions = ({ sourceOption, fileFormatCheckResults }: FileFormatTextsProps) => {
  const zipFileStructureInstructions = `Make sure you use the correct folder/file structure when trying to upload a ${sourceOption} ZIP file.`;
  const zipFileContentsInstructions = `Your ZIP file name should contain the entity id followed by _${sourceOption} (e.g. 000119_${sourceOption}) with a "main" folder inside with ${
    sourceOption === 'GTW' ? 'the same file name' : 'the submission id as its file name'
  }.  Inside that main folder there should be:`;
  const zipFileNotes =
    "Notes: \n 1. Texas does not support ZIP files. \n 2. This is the submission id we use for upload so it's imperative this is correct.";

  return (
    <>
      <span className={fileFormatStyles.zipFileStructureInstructionsText}>
        {zipFileStructureInstructions}
      </span>
      <span className={fileFormatStyles.zipFileContentsInstructions}>
        {zipFileContentsInstructions}
        <FileFormatRequirementsItems
          sourceOption={sourceOption}
          fileFormatCheckResults={fileFormatCheckResults}
        />
      </span>
      <span className={fileFormatStyles.zipFileNotes}>{zipFileNotes}</span>
    </>
  );
};

export default FileFormatInstructions;
