import { createSelector } from 'reselect';

import {
  entitiesSelector,
  entityIdSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';

export const isFetchingContextSelector = isFetchingGlobalContextSelector;

export const entitySelector = createSelector(
  [entitiesSelector, entityIdSelector],
  (entities, entityId) => entities.find(entity => entity.entityId === entityId),
);

export const isEntityPartnershipTypeSelector = createSelector([entitySelector], entity =>
  entity ? entity.isPartnershipType : null,
);

export const entityInformationBreadcrumbLabelSelector = createSelector([entitySelector], entity =>
  entity && entity.isPartnershipType ? 'Partnership Information' : 'Entity Information',
);

export const nonFilingStatesSelector = ({ entity }) => entity.root.nonFilingStates;

export const isFetchingNonFilingStatesSelector = ({ entity }) =>
  entity.root.isFetchingNonFilingStates;

export const jurisdictionIdSelector = ({ entity }) => entity.root.jurisdictionId;

export const nonFilingStatesJurisdictionIdSelector = ({ entity }) =>
  entity.root.nonFilingStatesJurisdictionId;

export const nonFilingStateByJurisdictionIdSelector = createSelector(
  [nonFilingStatesSelector, nonFilingStatesJurisdictionIdSelector],
  (nonFilingStates, nonFilingStatesJurisdictionId) =>
    [...nonFilingStates].find(state => state.jurisdictionId === nonFilingStatesJurisdictionId) ||
    null,
);

export const nonFilingStatesJurisdictionDescriptionSelector = createSelector(
  [nonFilingStateByJurisdictionIdSelector],
  state => state && state.jurisdictionDescription,
);

export const nonFilingStatesJurisdictionOptionsSelector = createSelector(
  [nonFilingStatesSelector],
  nonFilingStatesSelector =>
    [...nonFilingStatesSelector].map(({ jurisdictionId, jurisdictionDescription }) => ({
      label: jurisdictionDescription,
      value: jurisdictionId,
    })),
);

export const isFetchingTaskInfoSelector = ({ entity }) => entity.root.isFetchingTaskInfo;

export const checkIfNotesExistSelector = ({ entity }) => entity.root.ifNotesExist;

export const checkIfPartnershipInformationExistSelector = ({ entity }) =>
  entity.root.ifPartnershipInformationExist;

export const getFederalDetailsLinkSelector = ({ entity }) =>
  entity.root.federalDetailsLinkWithPlaceholders;

export const nonFilingStatesProfileIdSelector = createSelector(
  [nonFilingStateByJurisdictionIdSelector],
  state => state && state.profileId,
);

export const isPartnershipTypeSelector = ({ entity }) => entity.root.isPartnershipType;
export const isFetchingPartnershipTypeSelector = ({ entity }) =>
  entity.root.isFetchingPartnershipType;

export const entitiesDataSelector = ({ entity }) => entity.root.entitiesData;
export const isFetchingEntitiesDataSelector = ({ entity }) => entity.root.isFetchingEntitiesData;
export const taxYearWithFetchedEntitiesDataSelector = ({ entity }) =>
  entity.root.taxYearWithFetchedEntitiesData;
export const periodWithFetchedEntitiesDataSelector = ({ entity }) =>
  entity.root.periodWithFetchedEntitiesData;

export const checkIfLegalEntityExistSelector = ({ entity }) => entity.root.ifLegalEntityExist;
