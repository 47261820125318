import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { federalIdFormatter } from '../../shared/formatters';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'orgId',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Legal Entity',
    field: 'legalEntityId',
    width: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Tax Sensitivity',
    field: 'taxSensitivity',
    width: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Federal Filing Type',
    field: 'federalFilingType',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Source System',
    field: 'source',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Date of Acquisition',
    field: 'acquisitionDate',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Date of Inc',
    field: 'dateIncorporated',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'FEIN',
    field: 'federalIdNumber',
    valueFormatter: ({ value }) => federalIdFormatter(value),
  },
  {
    ...defaultColumnDefinition,
    headerName: 'State of Domicile',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Country of Inc',
    field: 'countryIncorporated',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Company Type',
    field: 'companyType',
    width: 250,
  },
];

export default columnDefinitions;
