import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';
import {
  fetchCalcSpecsOptionsTypes,
  fetchCategoriesTypes,
  BLOCK_CALC_SPEC_CONTEXT_CHANGES,
} from '../types';

export const sharedInitialState = {
  categories: [],
  isFetchingCategories: false,

  options: {
    accounts: [],
    entities: [],
    datasetDefinitions: [],
    groupedFilingAttributes: [],
  },
  isFetchingCalcSpecsOptions: false,

  isCalcSpecContextChangesBlocked: false,
};

const sharedReducer = reducerFactory({
  initialState: sharedInitialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchCalcSpecsOptionsTypes,
      payloadKey: 'options',
      fetchingKey: 'isFetchingCalcSpecsOptions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchCategoriesTypes,
      payloadKey: 'categories',
      fetchingKey: 'isFetchingCategories',
    }),
    [BLOCK_CALC_SPEC_CONTEXT_CHANGES]: (state, payload) => ({
      ...state,
      isCalcSpecContextChangesBlocked: payload,
    }),
  },
});

export default sharedReducer;
