import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ updateRow, ToggleAllSelectionsHeader }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  return [
    {
      ...defaultColumnDefinition,
      headerComponent: ToggleAllSelectionsHeader,
      headerName: '',
      field: 'isSelected',
      colId: 'isSelected',
      width: 50,
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Consolidation ID',
      field: 'consolId',
      width: 180,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Consolidation Description',
      field: 'consolDescr',
      width: 180,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Consolidation Parent Entity',
      field: 'parentEntityId',
      width: 200,
    },
  ];
};

export default getColumnDefinitions;
