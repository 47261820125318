import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TOTAL_DESC } from '@common-packages/shared-constants';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import globalContextPropTypes from '../../../shared/propTypes/globalContext';
import {
  taxYearSelector,
  periodSelector,
  entityIdSelector,
  jurisdictionIdSelector,
} from '../../../shared/store/selectors';
import { fetchFilingDecisionsAmountDetails } from '../../store/actions';
import { isFetchingAmountDetailsSelector, amountDetailsSelector } from '../../store/selectors';

import getColumnDefinitions from './filingDecisionsAmountDetails.columnDefinitions';
import './filingDecisionsAmountDetails.style.scss';

const FilingDecisionAmountDetails = ({
  globalContext,
  match,
  taxYear,
  period,
  orgId,
  jurisdictionId,
  amountDetails,
  isFetchingAmountDetails,
  fetchFilingDecisionsAmountDetails,
}) => {
  const { categoryName } = match.params;
  const isContextReady = taxYear && period && orgId && jurisdictionId;

  useEffect(() => {
    if (isContextReady) {
      fetchFilingDecisionsAmountDetails({
        taxYear,
        period,
        orgId,
        jurisdictionId,
        categoryName,
      });
    }
  }, [
    fetchFilingDecisionsAmountDetails,
    isContextReady,
    taxYear,
    period,
    orgId,
    jurisdictionId,
    categoryName,
  ]);

  const rowData = [
    ...amountDetails,
    {
      businessId: TOTAL_DESC,
      accountAmount: amountDetails.reduce((acc, curr) => acc + curr.accountAmount, 0),
    },
  ];

  const columnDefinitions = useMemo(() => getColumnDefinitions({ globalContext }), [globalContext]);
  return (
    <div className="filing-decision-amount-details row grid-row">
      <div className="col">
        <AgGrid
          rowData={rowData}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingAmountDetails}
          shouldShowDeleteIcon={false}
        />
      </div>
    </div>
  );
};

FilingDecisionAmountDetails.propTypes = {
  globalContext: globalContextPropTypes,

  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  taxYear: PropTypes.string,
  period: PropTypes.string,
  isFetchingAmountDetails: PropTypes.bool.isRequired,
  amountDetails: PropTypes.arrayOf(
    PropTypes.shape({
      businessId: PropTypes.string.isRequired,
      accountId: PropTypes.string.isRequired,
      accountDescription: PropTypes.string.isRequired,
      accountAmount: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  fetchFilingDecisionsAmountDetails: PropTypes.func.isRequired,

  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    orgId: entityIdSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    isFetchingAmountDetails: isFetchingAmountDetailsSelector(state),
    amountDetails: amountDetailsSelector(state),
  }),
  {
    fetchFilingDecisionsAmountDetails,
  },
)(FilingDecisionAmountDetails);
