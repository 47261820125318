import React from 'react';
import get from 'lodash.get';

import { dateFormatter } from '../shared/formatters';
import Loading from '../shared/displayComponents/loading.component';
import config from '../config';

const FAILED_STATUS = 'failed';

export const getLastUpdateDate = ({ updatedOn, createdOn }) =>
  dateFormatter(updatedOn || createdOn, config.AMERICAN_DATE_TIME_FORMAT);

export const getActionStatus = (data, isSubmitting, failedDisplayedStatus = false) => {
  if (isSubmitting) {
    return (
      <span>
        In Progress <Loading isLoading small />
      </span>
    );
  }

  if (!data) {
    return 'Not Started';
  }

  const message = get(data, 'ttiResponse.validation.status.message', null);

  const status = data.status.toLowerCase();

  const displayedStatus =
    failedDisplayedStatus && status === FAILED_STATUS ? failedDisplayedStatus : status;

  return `${displayedStatus}${message ? `, ${message}` : ''}`;
};

export const getActionsBatchStatus = (allSubsteps, expectedSubsteps, isSubmitting) => {
  const newestAction = allSubsteps[0];
  const allActionsPresent = expectedSubsteps.every(step =>
    allSubsteps.find(({ stepName }) => stepName === step),
  );

  const newestActionStatus = getActionStatus(newestAction, isSubmitting);

  if (!allActionsPresent && !isSubmitting && newestActionStatus.includes('completed')) {
    return 'Incomplete';
  }

  return newestActionStatus;
};
