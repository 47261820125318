import { SELECT_ALL_OPTION_VALUE } from '.././shared/reports/constants';

type ParamData = {
  label: string;
  value: string;
};

export const getAllValues = (paramData: ParamData[]) =>
  paramData ? paramData.map(param => param.value) : [];

export const mapAllSelectedPossibilitiesToSingleValue = (
  value?: string | string[],
  possibleValues?: string[],
) => {
  if (!value) {
    return [];
  }

  const valueArray = [value].flat();

  if (valueArray && possibleValues && valueArray.length === possibleValues.length) {
    return [SELECT_ALL_OPTION_VALUE];
  }

  return valueArray;
};
