const getFederalReconciliationsSections = (sectionsOrder, federalReconciliations) => {
  const federalReconciliationsSections = sectionsOrder.flatMap(section => {
    const sectionNameRow = {
      sectionName: section,
      categoryDescription: section,
    };
    const sectionData = federalReconciliations.filter(
      federalReconciliation => federalReconciliation.section === section,
    );
    if (sectionData.length) {
      sectionData.unshift(sectionNameRow);
      return sectionData;
    }
    return [sectionNameRow];
  });

  return federalReconciliationsSections;
};

export default getFederalReconciliationsSections;
