import { ConsolQueryParams, GetConsolMaintenanceResponse } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export const useQueryConsol = ({
  params,
  enabled,
}: {
  params: ConsolQueryParams;
  enabled: boolean;
}) =>
  useCustomQuery<GetConsolMaintenanceResponse>([QueryKeys.ConsolidationsMaintenance.Data, params], {
    url: `/api/admin/maintenance/consolidations/find-consolidation/${params.taxYear}/${params.period}/${params.consolidationId}/${params.jurisdictionId}`,
    enabled,
    errorMessage: 'Fetching consol failed',
  });
