import React, { useCallback, useEffect, useMemo } from 'react';
import Modal from '@tls/ui-modal';
import { useDispatch, useSelector } from 'react-redux';

import { ClientUser } from '../../../common/types';
import { clientIdSelector } from '../shared/store/context';
import { updateExpireMessageStep } from '../shared/store/actions';
import useModal from '../shared/hooks/useModal.hook';
import { customerDataSelector } from '../shared/store/customerData.selectors';

import { ExpireMessageStepEnum } from './model/enums/expireMessageStep';

const defaultClient: ClientUser = {
  id: '',
  name: '',
  shortName: '',
  gtwCLientKey: '',
  taxYearTtiTransmitterActive: '',
  expires: new Date(2000, 0, 1),
  activeFlag: '',
  clientUserId: '',
  expireMessageStep: 0,
};

const ExpireMessageModal = () => {
  const customerData = useSelector(customerDataSelector);
  const clientId = useSelector(clientIdSelector);
  const dispatch = useDispatch();
  const { showModal, modalProps } = useModal();

  const getClientUser = useMemo(() => {
    if (customerData.clients) {
      const clientsList: ClientUser[] = customerData.clients;
      const filteredClient = clientsList.find(clnt => clnt.id === clientId) ?? defaultClient;

      return filteredClient;
    }

    return defaultClient;
  }, [customerData.clients, clientId, defaultClient]);

  const getDaysRemaining = useMemo(() => {
    const expireDate = getClientUser.expires;
    const timeDifference = new Date(expireDate).valueOf() - new Date().valueOf();
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysRemaining;
  }, [getClientUser]);

  const getExpireMessageStep = useMemo(() => {
    const daysRemaining = getDaysRemaining;
    if (daysRemaining <= 0) return ExpireMessageStepEnum.STEP_5;
    if (daysRemaining <= 1) return ExpireMessageStepEnum.STEP_4;
    if (daysRemaining <= 5) return ExpireMessageStepEnum.STEP_3;
    if (daysRemaining <= 10) return ExpireMessageStepEnum.STEP_2;
    if (daysRemaining <= 30) return ExpireMessageStepEnum.STEP_1;
    return ExpireMessageStepEnum.STEP_0;
  }, [getDaysRemaining]);

  const shouldShowExpireMessage = useMemo(() => {
    const expireMessageStep = getExpireMessageStep;
    const isMessageShowed = expireMessageStep == getClientUser.expireMessageStep;
    const shouldShowExpireMessage = expireMessageStep != 0 && !isMessageShowed;

    return shouldShowExpireMessage;
  }, [getExpireMessageStep, getClientUser]);

  const dispatchUpdateExpireMessageStep = useCallback(
    (clientUserId: string, expireMessageStep: number) => {
      dispatch(
        updateExpireMessageStep({
          clientUserId,
          expireMessageStep,
        }),
      );
    },
    [dispatch],
  );

  const removeSessionTokens = () => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
  };

  const handleAccept = useCallback(() => {
    const clientUserId = getClientUser.clientUserId;
    const expireMessageStep = getExpireMessageStep;
    dispatchUpdateExpireMessageStep(clientUserId, expireMessageStep);
    modalProps.hideModal();
    if (getDaysRemaining <= 0) {
      removeSessionTokens();
    }
  }, [
    getClientUser,
    getExpireMessageStep,
    getDaysRemaining,
    dispatchUpdateExpireMessageStep,
    modalProps,
  ]);

  const getTitleText = useMemo(() => {
    return getDaysRemaining <= 0 ? 'Account expired.' : 'Your account will expire soon.';
  }, [getDaysRemaining]);

  const getMessageText = useMemo(() => {
    const daysRemaining = getDaysRemaining;
    const daysText = daysRemaining === 1 ? 'day' : 'days';

    const messageText =
      daysRemaining > 0
        ? `Your account will expire in ${daysRemaining} ${daysText}. Please contact your engagement team lead to have the expiration extended.`
        : 'Your account has expired. Please contact your engagement team lead to enable access.';
    return messageText;
  }, [getDaysRemaining]);

  useEffect(() => {
    if (getClientUser.activeFlag == 'N') {
      removeSessionTokens();
    } else if (shouldShowExpireMessage) {
      showModal();
    } else if (getDaysRemaining <= 0) {
      removeSessionTokens();
    }
  }, []);

  return (
    <Modal
      className="account-expire-message"
      closeAction={modalProps.hideModal}
      visible={modalProps.visible}
      dismissAction={modalProps.hideModal}
      submitText="Accept"
      submitAction={handleAccept}
      actionButtonsSize="lg"
      closeable={false}
    >
      <h4>{getTitleText}</h4>
      <div className="inline-line"></div>
      <p>{getMessageText}</p>
    </Modal>
  );
};

export default ExpireMessageModal;
