import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import Dropzone from '../../shared/forms/dropzoneFormik/dropzoneFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Loading from '../../shared/displayComponents/loading.component';

import { IMPORT_ACTIONS } from './store/api';

const DOCUMENT_ACCEPT_TYPES = ['.csv', '.xml'];

const options = Object.keys(IMPORT_ACTIONS).map(action => ({
  label: IMPORT_ACTIONS[action],
  value: action,
}));

const EfileForm = ({ isSubmitting, submitForm, values }) => (
  <form>
    <Select
      wrapperClassName="form-text"
      appkitLabel="Actions"
      name="action"
      options={options}
      value={values.action}
    />
    <Dropzone
      className="form-text"
      name="file"
      value={values.file}
      label="File to upload"
      acceptTypes={DOCUMENT_ACCEPT_TYPES}
    />
    <Button size="lg" disabled={isSubmitting} onClick={submitForm}>
      <Loading isLoading={isSubmitting} small>
        Run
      </Loading>
    </Button>
  </form>
);

EfileForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    file: PropTypes.instanceOf(File),
    action: PropTypes.string,
  }),
};

export default EfileForm;
