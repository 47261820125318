import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchBusinessOptionsTypes,
  fetchAttachmentDefinitionsTypes,
  createAttachmentTypes,
  updateAttachmentTypes,
} from './types';

const initialState = {
  businessOptions: [],
  isFetchingBusinessOptions: false,
  attachmentDefinitions: [],
  isFetchingAttachmentDefinitions: false,
  isCreatingAttachment: false,
  isUpdatingAttachment: false,
};

const attachmentsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchBusinessOptionsTypes,
      payloadKey: 'businessOptions',
      fetchingKey: 'isFetchingBusinessOptions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAttachmentDefinitionsTypes,
      payloadKey: 'attachmentDefinitions',
      fetchingKey: 'isFetchingAttachmentDefinitions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createAttachmentTypes,
      fetchingKey: 'isCreatingAttachment',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateAttachmentTypes,
      fetchingKey: 'isUpdatingAttachment',
    }),
  },
});

export default attachmentsReducer;
