import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createDataProvider } from '../../../shared/dataProviders';
import { fetchPartnershipTypes } from '../store/actions';

const PartnershipInformationDataProvider = ({
  children,

  fetchPartnershipTypes,
}) => {
  useEffect(() => {
    fetchPartnershipTypes();
  }, [fetchPartnershipTypes]);

  return children;
};
PartnershipInformationDataProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,

  fetchPartnershipTypes: PropTypes.func.isRequired,
};

export default createDataProvider({
  ProviderComponent: connect(null, {
    fetchPartnershipTypes,
  })(PartnershipInformationDataProvider),
});
