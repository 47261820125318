import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DrillFieldPropTypes } from '../overridableForm.propTypes';
import { createDrillRoute } from '../../../shared/drilling';
import { SAVE_SCROLL_ELEMENT_ID } from '../../../shared/store/types';
import { globalContextSelector } from '../../../shared/store/selectors';

const DrillLink = ({ fieldValues }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const globalContext = useSelector(globalContextSelector);

  const drillRoute = useMemo(() => createDrillRoute(fieldValues, globalContext.params), [
    fieldValues,
    globalContext.params,
  ]);

  const handleClick = useCallback(
    event => {
      dispatch({ type: SAVE_SCROLL_ELEMENT_ID, payload: event.target.id });
      event.preventDefault();
      return history.push(drillRoute, { returnLocation: location });
    },
    [dispatch, history, location, drillRoute],
  );

  return (
    <a
      id={fieldValues.blockId || fieldValues.baseFieldName}
      className="form-link"
      style={fieldValues.styles}
      onClick={handleClick}
      href={drillRoute}
    >
      See details
    </a>
  );
};

DrillLink.propTypes = {
  fieldValues: DrillFieldPropTypes.isRequired,
};

export default DrillLink;
