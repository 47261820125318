import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../shared/store/reducerFactory';

import {
  fetchIncomeTaxSummaryTypes,
  fetchFranchiseTaxSummaryTypes,
  fetchEstimatedTaxSummaryTypes,
} from './types';

export const initialState = {
  taxSummaries: null,
  isFetchingTaxSummaries: false,
};

const taxSummariesReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchIncomeTaxSummaryTypes,
      payloadKey: 'taxSummaries',
      fetchingKey: 'isFetchingTaxSummaries',
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchFranchiseTaxSummaryTypes,
      payloadKey: 'taxSummaries',
      fetchingKey: 'isFetchingTaxSummaries',
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchEstimatedTaxSummaryTypes,
      payloadKey: 'taxSummaries',
      fetchingKey: 'isFetchingTaxSummaries',
    }),
  },
});

export default taxSummariesReducer;
