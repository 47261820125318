import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@pwc/appkit-react';

import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';

import PdfOutline from './pdfOutline.component';
import style from './pdfDisplay.module.scss';

const PdfToolbar = ({
  page,
  handleShowHideBookmarks,
  handleDownloadPdf,
  handleExpandedMode,
  resetAnimation,
  animationEffect,
  showOutline,
  closePdfViewer,
}) => {
  const ToolbarControls = [
    {
      iconName: 'archive',
      tooltipContent: 'Bookmarks',
      clickHandler: handleShowHideBookmarks,
    },
    {
      iconName: 'expand',
      tooltipContent: 'Expand PDF',
      clickHandler: handleExpandedMode,
    },
    {
      iconName: 'download',
      tooltipContent: 'Download',
      clickHandler: handleDownloadPdf,
    },
  ];

  return (
    <div className={style.pdfViewerToolbarContainer}>
      <div className={style.pdfViewerToolbar}>
        {ToolbarControls.map(({ iconName, tooltipContent, clickHandler }) => (
          <Tooltip
            key={iconName}
            content={tooltipContent}
            placement="bottom"
            clickToClose
            className={style.toolbarElementTooltip}
          >
            <div className={style.toolbarElement} onClick={clickHandler}>
              <AppkitIcon icon={iconName} size={20} />
            </div>
          </Tooltip>
        ))}
        {closePdfViewer && (
          <Tooltip
            key="close"
            content="Close"
            placement="bottom"
            clickToClose
            className={style.toolbarElementTooltip}
          >
            <div className={style.toolbarElement} onClick={closePdfViewer}>
              <AppkitIcon icon="close" size={20} />
            </div>
          </Tooltip>
        )}
      </div>
      <PdfOutline
        animationEffect={animationEffect}
        showOutline={showOutline}
        resetAnimation={resetAnimation}
        page={page}
      />
    </div>
  );
};

PdfToolbar.propTypes = {
  page: PropTypes.object.isRequired,
  handleShowHideBookmarks: PropTypes.func.isRequired,
  handleDownloadPdf: PropTypes.func.isRequired,
  handleExpandedMode: PropTypes.func.isRequired,
  resetAnimation: PropTypes.func.isRequired,
  showOutline: PropTypes.bool.isRequired,
  animationEffect: PropTypes.string,
  closePdfViewer: PropTypes.func,
};

export default PdfToolbar;
