import { QueryError } from '../queries';
import { GlobalContext, GtwUploadFileResponse } from '../../../../common/types';
import { httpPostFileAndParse } from '../../utils/fetchService';
import { FileType, SourceId } from '../../shared/enums/gtwFileUpload';

import { useCustomMutation } from '.';

interface GtwUploadFileVariable {
  file: Blob;
  globalContext: GlobalContext;
  sourceOption: SourceId;
  fileTypeOption: FileType;
}

const gtwUploadFile = ({
  file,
  globalContext,
  sourceOption,
  fileTypeOption,
}: GtwUploadFileVariable) => {
  const body = new FormData();
  body.append('file', file);
  body.append('sourceId', sourceOption);
  body.append('fileType', fileTypeOption);

  return httpPostFileAndParse({
    route: `/api/tools/gtw-file-upload/${globalContext.apiRouteParamString}`,
    body,
    errorMessage: `Saving ${sourceOption} file failed`,
    parseResponseErrorMessage: true,
  });
};

export const useMutationGtwUploadFile = () =>
  useCustomMutation<GtwUploadFileResponse, QueryError, GtwUploadFileVariable>({
    customMutate: gtwUploadFile,
    successMessage: 'Uploaded file successfully',
  });
