import React, { useCallback, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { GridApi } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { calcPreferencesSchemas } from '@common-packages/validators';

import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useQueryCalcPreferences } from '../../shared/queries/calcPreferences';
import useGridApi from '../../shared/hooks/useGridApi.hook';
import { taxYearSelector } from '../store/selectors';
import {
  CalcPreferenceByJurisdictions,
  InitialCalcPreferenceDetails,
} from '../../../../common/types';

import EditCalcPreferences from './editCalcPreferences.container';
import generalColumnDefinitions from './generalCalcOptions.columnDefinitions';
import styles from './calcPreferences.module.scss';

const noopVoid = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

const initialCalcPreferenceDetails: InitialCalcPreferenceDetails = {
  name: '',
  description: '',
  dataType: '',
};

const CalcPreferences = () => {
  const taxYear = useSelector(taxYearSelector);
  const [
    selectedCalcPreference,
    setSelectedCalcPreference,
  ] = useState<CalcPreferenceByJurisdictions | null>(null);

  const isEditMode = Boolean(selectedCalcPreference);
  const { data: calcPreferences, isFetching: isFetchingCalcPreferences } = useQueryCalcPreferences({
    enabled: Boolean(taxYear),
    params: { taxYear },
  });

  const { gridApi, onGridReady } = useGridApi();

  useEffect(() => {
    if (!selectedCalcPreference) {
      gridApi?.deselectAll();
    }
  }, [gridApi, selectedCalcPreference]);

  useEffect(() => {
    setSelectedCalcPreference(null);
  }, [taxYear]);

  const onAddCalcPreferenceClick = useCallback(() => {
    setSelectedCalcPreference(null);
  }, []);

  const renderAddCalcPreferenceButton = useCallback(
    () => (
      <Button size="lg" className="add-button" onClick={onAddCalcPreferenceClick}>
        Add Calc Preference
      </Button>
    ),
    [onAddCalcPreferenceClick],
  );

  const handleFormFieldSelection = useCallback(({ api }: { api: GridApi }) => {
    setSelectedCalcPreference(api.getSelectedRows()?.[0]);
  }, []);

  return (
    <>
      <HeaderWithParamDropdownsWrapper
        showHeaderTaxYearDropdown
        renderAdditionalElements={renderAddCalcPreferenceButton}
      />
      <div className={styles.gridsContainer}>
        <div className={styles.generalGrid}>
          <AgGrid
            rowData={calcPreferences}
            onSelectionChanged={handleFormFieldSelection}
            rowSelection="single"
            columnDefs={generalColumnDefinitions}
            isGridLoading={isFetchingCalcPreferences}
            withSearchBar
            areHeaderCellBordersEnabled
            stopEditingWhenCellsLoseFocus
            autoMaxWidth
            onGridReady={onGridReady}
          />
        </div>
        <div className={styles.detailsSection}>
          <Formik
            initialValues={
              selectedCalcPreference
                ? {
                    taxYear,
                    name: selectedCalcPreference.name,
                    description: selectedCalcPreference.description,
                    dataType: selectedCalcPreference.dataType,
                  }
                : { taxYear, ...initialCalcPreferenceDetails }
            }
            validationSchema={calcPreferencesSchemas.getAddEditCalcPreferenceSchema({
              calcPreferences,
              isEditMode,
            })}
            enableReinitialize
            onSubmit={noopVoid} // noopVoid is needed to prevent TS property missing error
          >
            <EditCalcPreferences
              setSelectedCalcPreference={setSelectedCalcPreference}
              isEditing={isEditMode}
              selectedCalcPreference={selectedCalcPreference}
            />
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CalcPreferences;
