import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import {
  isFetchingContextSelector,
  formIdSelector,
  formsOptionsSelector,
  isFetchingFormsSelector,
} from '../store/selectors';
import { selectForm } from '../store/actions';

const TaxFormDropdown = () => {
  const dispatch = useDispatch();

  const formId = useSelector(formIdSelector) || null;
  const isDropdownBusy = useSelector(isFetchingFormsSelector) || false;
  const formsList = useSelector(formsOptionsSelector);
  const isFetchingContext = useSelector(isFetchingContextSelector);

  const handleSelect = useCallback(
    newFormId => {
      if (formId !== newFormId) {
        dispatch(selectForm(newFormId));
      }
    },
    [dispatch, formId],
  );

  return (
    <ParamDropdown
      label="Tax Form"
      value={formId}
      options={formsList}
      handleChange={handleSelect}
      isBusy={isDropdownBusy || isFetchingContext}
      isFetchingContext={isFetchingContext}
      hasTooltip
    />
  );
};

export default TaxFormDropdown;
