const TOTAL_DESC = 'TOTAL';

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const AddRemove = {
  ADD: 'ADD',
  REMOVE: 'REM',
};

const JobStatus = {
  WAITING: 'Waiting',
  PROCESSING: 'Processing',
  SUCCESS: 'Success',
  FAILED: 'Failed',
};

const JobTypes = {
  GENERATE_BINDER_PDF_STATUS: 'generate-binder-pdf-status',
};

const TAX_YEAR_2020 = 2020;
const TAX_YEAR_2021 = 2021;
const TAX_YEAR_2022 = 2022;
const TAX_YEAR_2023 = 2023;

const ANNUAL_PERIOD = '0';
const EXTENSION_PERIOD = '5';
const ESTIMATION_PERIODS = ['1', '2', '3', '4'];

const PERIOD_NAME = {
  0: 'Annual',
  1: '1st Quarter',
  2: '2nd Quarter',
  3: '3rd Quarter',
  4: '4th Quarter',
  5: 'Extension',
};

const NotificationStatus = {
  SUCCESS: 'Success',
  WARNING: 'Warning',
  INFORMATION: 'Information',
};

module.exports = {
  AddRemove,
  TOTAL_DESC,
  HttpMethods,
  JobStatus,
  JobTypes,
  TAX_YEAR_2020,
  TAX_YEAR_2021,
  TAX_YEAR_2022,
  TAX_YEAR_2023,
  ANNUAL_PERIOD,
  EXTENSION_PERIOD,
  ESTIMATION_PERIODS,
  PERIOD_NAME,
  NotificationStatus,
};
