import { TreeItem } from 'performant-array-to-tree';

import { FindDatasetDefinitions } from '../../../../../common/types';

export const getAllDatasetInstancesTobeDeleted = ({
  datasetDefinitionsTree,
}: {
  datasetDefinitionsTree: TreeItem[];
}) => {
  const datasetDefinitionsTobeDeleted: FindDatasetDefinitions = [];

  datasetDefinitionsTree.forEach(({ data, children }: TreeItem) => {
    datasetDefinitionsTobeDeleted.push(data);

    if (children) {
      datasetDefinitionsTobeDeleted.push(
        ...getAllDatasetInstancesTobeDeleted({ datasetDefinitionsTree: children }),
      );
    }
  });

  return datasetDefinitionsTobeDeleted;
};
