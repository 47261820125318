import React from 'react';
import { Button } from '@pwc/appkit-react';

import config from '../../config';

import styles from './downloadPdfPanel.module.scss';

interface PdfErrorParams {
  generatePdf: () => void;
}

const PdfError = ({ generatePdf }: PdfErrorParams) => (
  <div className={styles.panelWrapper}>
    <div className={styles.panelItemWrapper}>
      <img
        alt="pdf-error"
        src={`${config.BASE_PATH}/assets/pdf-error.png`}
        className={styles.illustration}
      />
    </div>
    <span className={styles.text}>An unexpected error occurred. Try again later.</span>
    <div className={styles.downloadButtonWrapper}>
      <Button size="xl" kind="secondary" className={styles.downloadButton} onClick={generatePdf}>
        Retry
      </Button>
    </div>
  </div>
);

export default PdfError;
