import { getApiActionTypes } from '../../shared/store/storeHelpers';

export const fetchFilingGroupsTypes = getApiActionTypes('FETCH_FILING_GROUPS');
export const fetchFilingGroupsByFilingTypeTypes = getApiActionTypes(
  'FETCH_FILING_GROUPS_BY_FILING_TYPE',
);
export const fetchAllJurisdictionsTypes = getApiActionTypes('FETCH_ALL_JURISDICTIONS');
export const updateFilingGroupTypes = getApiActionTypes('UPDATE_FILING_GROUP');
export const createFilingGroupTypes = getApiActionTypes('CREATE_FILING_GROUP');

export const SELECT_FILING_GROUP_ID = 'SELECT_FILING_GROUP_ID';
export const SELECT_JURISDICTION_ID_FOR_FILING_GROUP = 'SELECT_JURISDICTION_ID_FOR_FILING_GROUP';
