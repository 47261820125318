import {
  FederalAsFiledSubmissionId,
  ProformaSubmissionId,
  ManualFederalSubmissionId,
} from '../../../../../common/types';
import { FederalReturnOption } from '../../../shared/enums/eFileV2';

interface setFederalSubmissionIdBasedOnFederalReturnOptionProps {
  federalReturnOption: FederalReturnOption | null;
  federalAsFiledSubmissionId: FederalAsFiledSubmissionId | null;
  federalProformaSubmissionId: ProformaSubmissionId | null;
  consolidatedAsFiledSubmissionIdForEntity: FederalAsFiledSubmissionId | null;
  consolidatedProformaSubmissionIdForEntity: ProformaSubmissionId | null;
  manualFederalProformaSubmissionId: ManualFederalSubmissionId | null;
}

const setFederalSubmissionIdBasedOnFederalReturnOption = ({
  federalReturnOption,
  federalAsFiledSubmissionId,
  federalProformaSubmissionId,
  consolidatedAsFiledSubmissionIdForEntity,
  consolidatedProformaSubmissionIdForEntity,
  manualFederalProformaSubmissionId,
}: setFederalSubmissionIdBasedOnFederalReturnOptionProps) => {
  switch (federalReturnOption) {
    case FederalReturnOption.FED_AS_FILED: {
      return federalAsFiledSubmissionId;
    }
    case FederalReturnOption.CONS_AS_FILED: {
      return consolidatedAsFiledSubmissionIdForEntity;
    }
    case FederalReturnOption.FED_PROFORMA: {
      return federalProformaSubmissionId;
    }
    case FederalReturnOption.CONS_PROFORMA: {
      return consolidatedProformaSubmissionIdForEntity;
    }
    case FederalReturnOption.FED_MANUAL_PROFORMA: {
      return manualFederalProformaSubmissionId;
    }
    default: {
      return null;
    }
  }
};

export default setFederalSubmissionIdBasedOnFederalReturnOption;
