import React, { useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { OverflowAction } from '@common-packages/shared-constants';

import Loading from '../../../../../shared/displayComponents/loading.component';
import { SelectOptionPropTypes } from '../../../../../shared/propTypes/selectOption';
import Select from '../../../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Input from '../../../../../shared/forms/inputFormik/inputFormik.component';
import { showConfirmModal } from '../../../../../shared/confirmModal/store/actions';
import { PRINT_ORIENTATION_OPTIONS, PrintOrientation } from '../constants';

import styles from './datasetUsageForm.module.scss';

const DatasetUsageForm = ({
  customChangeHandler,
  overflowActionTypes,
  isLoading,
  isAttachmentOverflowAction,
  disabled,
  selectComponentsValues = {
    overflowAction: null,
    printOrientationLandscape: PrintOrientation.PORTRAIT,
  },
}) => {
  const dispatch = useDispatch();
  const { setValues, setFieldValue } = useFormikContext();

  const defaultFieldProps = {
    disabled,
    customChangeHandler,
  };

  const defaultAttachmentFieldProps = {
    ...defaultFieldProps,
    disabled: disabled || !isAttachmentOverflowAction,
  };

  const isUiLocationFieldNameInputDisabled =
    selectComponentsValues.overflowAction !== OverflowAction.ATTACHMENT;

  const handleOverflowActionChange = useCallback(
    field => {
      if (!field) {
        return;
      }

      if (
        selectComponentsValues.overflowAction === OverflowAction.ATTACHMENT &&
        field.value !== OverflowAction.ATTACHMENT
      ) {
        dispatch(
          showConfirmModal({
            title: 'Warning',
            text:
              'By changing the Overflow Action from Attachment to a different Overflow Action, the value in the Drill UI Field will be removed. Do you wish to continue?',
            confirmCallback: () => {
              setValues(values => ({
                ...values,
                uiLocationFieldName: '',
                overflowAction: field.value ?? field,
              }));
              customChangeHandler({
                uiLocationFieldName: '',
                overflowAction: field.value ?? field,
              });
            },
          }),
        );
      } else {
        setFieldValue('overflowAction', field.value ?? field);
        customChangeHandler('overflowAction', field.value ?? field);
      }
    },
    [
      dispatch,
      setFieldValue,
      setValues,
      customChangeHandler,
      selectComponentsValues.overflowAction,
    ],
  );

  return (
    <Loading isLoading={isLoading}>
      <form>
        <div className="row my-sm-3 align-items-start">
          <div
            className={classnames('col', styles.formColumn, {
              [styles.columnDisabledLabels]: disabled,
            })}
          >
            <h6>Overflow Actions</h6>
            <Field
              {...defaultFieldProps}
              className={styles.gridInputSelectField}
              label="Instances Per Page"
              name="instancesPerPage"
              type="number"
              component={Input}
            />
            <Field
              {...defaultFieldProps}
              className={styles.gridInputSelectField}
              label="Instances to Skip"
              name="instToSkip"
              type="number"
              component={Input}
            />
            <Select
              {...defaultFieldProps}
              wrapperClassName={styles.gridInputSelectField}
              appkitLabel="Overflow Action"
              name="overflowAction"
              options={overflowActionTypes}
              value={selectComponentsValues.overflowAction}
              onChange={handleOverflowActionChange}
            />
            <Field
              {...defaultFieldProps}
              className={styles.gridInputSelectField}
              label="Drill UI Field"
              name="uiLocationFieldName"
              component={Input}
              disabled={isUiLocationFieldNameInputDisabled}
            />
          </div>
          <div
            className={classnames('col', styles.formColumn, {
              [styles.columnDisabledLabels]: disabled || !isAttachmentOverflowAction,
            })}
          >
            <h6>Attachment Options</h6>
            <Select
              {...defaultAttachmentFieldProps}
              wrapperClassName={styles.gridInputSelectField}
              appkitLabel="Orientation"
              name="printOrientationLandscape"
              options={PRINT_ORIENTATION_OPTIONS}
              value={selectComponentsValues.printOrientationLandscape}
            />
            <Field
              {...defaultAttachmentFieldProps}
              className={styles.gridInputSelectField}
              label="Font Size"
              name="fontSize"
              type="number"
              component={Input}
            />
            <Field
              {...defaultAttachmentFieldProps}
              className={styles.gridInputSelectField}
              label="Stmt. Reference Field"
              name="pdfStmtRefFieldName"
              component={Input}
            />
            <Field
              {...defaultAttachmentFieldProps}
              className={styles.gridInputSelectField}
              label="Page Width %"
              name="pageWidthPercentage"
              type="number"
              component={Input}
            />
          </div>
        </div>
      </form>
    </Loading>
  );
};

DatasetUsageForm.propTypes = {
  customChangeHandler: PropTypes.func.isRequired,
  overflowActionTypes: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectComponentsValues: PropTypes.shape({
    overflowAction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    printOrientationLandscape: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  isAttachmentOverflowAction: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DatasetUsageForm;
