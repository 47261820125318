import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import Datamap from '../shared/displayComponents/datamap/datamap.highcharts.component';
import Loading from '../shared/displayComponents/loading.component';

const Heatmap = ({ data, onStateClicked, isFetchingData }) => {
  const heatmapData = useMemo(
    () => Object.keys(data).map(key => ({ code: data[key].stateCode, value: data[key].taxDue })),
    [data],
  );

  return (
    <Loading isLoading={isFetchingData}>
      <Datamap data={heatmapData} onCountryClicked={onStateClicked} />
    </Loading>
  );
};

Heatmap.propTypes = {
  data: PropTypes.array.isRequired,
  onStateClicked: PropTypes.func.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
};

export default memo(Heatmap);
