import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@pwc/appkit-react';

import ComingSoonPage from '../comingSoonPage/comingSoonPage.component';
import {
  ActionMenu,
  ActionMenuItem,
} from '../shared/displayComponents/actionMenu/actionMenu.component';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import { globalContextSelector } from '../shared/store/selectors';
import { performTaxReturnCalculation } from '../shared/store/actions';

import FilingInformation from './filingInformation.component';
import Reports from './reports.component';

const TABS_TYPES = {
  INFORMATION: 'INFORMATION',
  REPORTS: 'REPORTS',
  E_FILE: 'E_FILE',
};

const tabsDefinitions = [
  { type: TABS_TYPES.INFORMATION, label: 'Filing Information' },
  { type: TABS_TYPES.REPORTS, label: 'Reports' },
  { type: TABS_TYPES.E_FILE, label: 'e-File' },
];

const Consolidation = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();
  const globalContext = useSelector(globalContextSelector);

  const {
    taxYear,
    period,
    jurisdictionId,
    businessEntityId: consolidationId,
  } = globalContext.params;

  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.INFORMATION:
        return <FilingInformation />;
      case TABS_TYPES.REPORTS:
        return <Reports globalContextParams={globalContext.params} />;
      case TABS_TYPES.E_FILE:
        return <ComingSoonPage />;
      default:
        throw new Error('Unsupported tab type');
    }
  };

  const onConsolidatedCalculationClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Perform calculation',
        text: 'Perform tax return calculation for this consolidation?',
        confirmCallback: () =>
          dispatch(
            performTaxReturnCalculation({
              taxYear,
              period,
              consolidationId,
              jurisdictionId,
            }),
          ),
      }),
    );
  }, [dispatch, taxYear, period, consolidationId, jurisdictionId]);

  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }, index) => (
              <Tab id={type} value={type} label={label} key={index} />
            ))}
          </Tabs>
          {hasUserPermissionsToEdit && (
            <ActionMenu>
              <ActionMenuItem
                text="Consolidated Calculation"
                onClick={onConsolidatedCalculationClick}
                disabled={!consolidationId}
              />
            </ActionMenu>
          )}
        </div>
      </div>
      {getActiveTabComponent()}
    </>
  );
};

Consolidation.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default Consolidation;
