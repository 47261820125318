import React, { useCallback } from 'react';
import { SelectOption } from '@tls/slt-types';

import SDKCustomSelect from '../../forms/sdkCustomSelect/sdkCustomSelect.component';
import Loading from '../loading.component';

import './paramDropdown.style.scss';

interface ParamDropdownProps<TValue> {
  value?: TValue;
  options: SelectOption[];
  className?: string;
  label?: string;
  disabled?: boolean;
  isBusy?: boolean;
  isFetchingContext?: boolean;
  isClearable?: boolean;
  isExpandingOnRender?: boolean;
  hasTooltip?: boolean;
  handleChange?: (newValue: TValue, newLabel: string | null) => unknown;
}

function ParamDropdown<TValue = string | number | boolean | null>({
  label,
  value,
  options,
  handleChange = () => null,
  disabled = false,
  isBusy = false,
  isFetchingContext = false,
  isClearable = false,
  className = '',
  isExpandingOnRender = false,
  hasTooltip = false,
}: ParamDropdownProps<TValue>) {
  const handleDropdownSelect = useCallback(
    (option: { label: string; value: string | null }) => {
      if (option || isClearable) {
        handleChange((option?.value || null) as TValue, option?.label || null);
      }
    },
    [handleChange, isClearable],
  );

  return (
    <div
      className={`global-context-dropdown sdk-custom-select-global-context-dropdown ${className}`}
    >
      {label && <label className="a-form-label">{label}</label>}
      <Loading small isLoading={isBusy}>
        <div className="global-context-dropdown-wrapper">
          <SDKCustomSelect<string | null>
            hasTooltip={hasTooltip}
            className="global-context-select sdk-custom-select global-context-small-select"
            options={options}
            onChange={handleDropdownSelect}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={value as any}
            virtualized
            disabled={disabled || isFetchingContext}
            isClearable={isClearable}
            isExpandingOnRender={isExpandingOnRender}
          />
          <Loading isLoading={isBusy} />
        </div>
        <Loading isLoading={isBusy} />
      </Loading>
    </div>
  );
}

export default ParamDropdown;
