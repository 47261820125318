import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '@tls/ui-spinner';
import { useLocation } from 'react-router-dom';

import { setSltBinderId, sltBinderIdSelector } from '../../shared/store/context';
import { useQuerySltBinderOptions } from '../../shared/queries/binders';
import { infoNotification } from '../../shared/notification/store/actions';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import useModal from '../../shared/hooks/useModal.hook';
import PdfDocumentModal from '../../shared/pdfAttachments/pdfDocumentModal.component';
import SelectContextDataInfo from '../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import DataInfo from '../../shared/displayComponents/dataInfo/dataInfo.component';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';
import { ROOT_NAVIGATOR_TAB } from '../../shared/constants';
import {
  pdfImageSelector,
  isFetchingPdfImageSelector,
} from '../../shared/store/pdfAttachments/selectors';
import { navigatorBinderDataSelector, isFetchingNavigatorBinderDataSelector } from '../store';
import treeAfterActiveApplied from '../treeAfterActiveApplied';

import binderTreeDataFactory from './binderDataFactory';

const BinderTree = ({
  globalContext,
  routes,
  applyPermissions,
  renderTree,

  binderData,
  isFetchingBinderData,
  isFetchingGlobalContext,
  setSltBinderId,
  sltBinderId,

  pdfImage,
  isFetchingPdfImage,

  infoNotification,

  ...rest
}) => {
  const sltBinders = useQuerySltBinderOptions({
    params: globalContext.apiRouteParamString,
    enabled: globalContext.isReady,
  });

  const tree = useMemo(
    () =>
      binderTreeDataFactory({
        binderData,
        applyPermissions,
        routes,
        globalContext,
      }),
    [binderData, applyPermissions, routes, globalContext],
  );

  const location = useLocation();

  const treeWithActive = useMemo(
    () =>
      treeAfterActiveApplied({
        tree,
        location,
        activeTab: ROOT_NAVIGATOR_TAB.BINDER,
      }),
    [tree, location],
  );

  const { showModal: showAttachmentModal, modalProps } = useModal();

  const handleBinderChange = useCallback(
    sltBinderId => {
      setSltBinderId({ sltBinderId });
    },
    [setSltBinderId],
  );

  if (isFetchingBinderData || isFetchingGlobalContext || sltBinders.isFetching) {
    return <Spinner />;
  }

  if (!globalContext.isReady) {
    return <SelectContextDataInfo />;
  }

  if (!tree.length) {
    return <DataInfo textToRender="Nothing in the binder" />;
  }

  return (
    <>
      <ParamDropdown
        label="Binder"
        options={sltBinders.data.sltBinderOptions}
        value={sltBinderId}
        handleChange={handleBinderChange}
        isBusy={sltBinders.isFetching}
      />
      {renderTree({
        tree: treeWithActive,
        showAttachmentModal,

        ...rest,
      })}
      <PdfDocumentModal
        {...modalProps}
        pdfImage={pdfImage}
        isFetchingPdfImage={isFetchingPdfImage}
        infoNotification={infoNotification}
      />
    </>
  );
};

BinderTree.propTypes = {
  globalContext: globalContextPropTypes,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      routeName: PropTypes.string.isRequired,
      MAIN: PropTypes.string.isRequired,
      id: PropTypes.string,
    }).isRequired,
  ).isRequired,
  binderData: PropTypes.shape({
    forms: PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          formId: PropTypes.string.isRequired,
          formName: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    reports: PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          reportId: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    attachments: PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }),
  isFetchingBinderData: PropTypes.bool.isRequired,
  isFetchingGlobalContext: PropTypes.bool.isRequired,
  sltBinderId: PropTypes.number,
  setSltBinderId: PropTypes.func.isRequired,
  applyPermissions: PropTypes.func.isRequired,
  renderTree: PropTypes.func.isRequired,
  pdfImage: PropTypes.object, // eslint-disable-line
  isFetchingPdfImage: PropTypes.bool.isRequired,
  infoNotification: PropTypes.func.isRequired,
};

const ConnectedTree = connect(
  state => ({
    globalContext: globalContextSelector(state),
    binderData: navigatorBinderDataSelector(state),
    isFetchingBinderData: isFetchingNavigatorBinderDataSelector(state),
    isFetchingGlobalContext: isFetchingGlobalContextSelector(state),
    isFetchingPdfImage: isFetchingPdfImageSelector(state),
    pdfImage: pdfImageSelector(state),
    sltBinderId: sltBinderIdSelector(state),
  }),
  {
    setSltBinderId,
    infoNotification,
  },
)(BinderTree);

export default ConnectedTree;
