import { ValidateTaxFormAttachmentResponse, AddTaxFormAttachmentResponse } from '@tls/slt-types';

import { QueryError } from '../queries';
import { httpPutFile, httpPostFileAndParse } from '../../utils/fetchService';

import { useCustomMutation } from '.';

interface TaxFormDataWithFormIdVariable {
  formId: string;
  formData: FormData;
}

const validateTaxFormAttachment = ({ formId, formData }: TaxFormDataWithFormIdVariable) =>
  httpPutFile({
    route: `/api/development/tax-forms-v2/${formId}/pdf-attachment-validation`,
    body: formData,
  });

export const useMutationValidateTaxFormAttachment = () =>
  useCustomMutation<ValidateTaxFormAttachmentResponse, QueryError, TaxFormDataWithFormIdVariable>({
    customMutate: validateTaxFormAttachment,
    errorMessage: 'Validating tax form failed',
    parseResponseAsJson: true,
  });

const updateTaxFormAttachment = ({ formId, formData }: TaxFormDataWithFormIdVariable) =>
  httpPutFile({
    route: `/api/development/tax-forms-v2/${formId}/pdf-attachment`,
    body: formData,
  });

export const useMutationUpdateTaxFormAttachment = () =>
  useCustomMutation<null, QueryError, TaxFormDataWithFormIdVariable>({
    customMutate: updateTaxFormAttachment,
    successMessage: 'Updating tax form succeeded',
    errorMessage: 'Updating tax form failed',
  });

interface AddTaxFormAttachmentVariable {
  formData: FormData;
}

const addTaxFormAttachment = ({ formData }: AddTaxFormAttachmentVariable) =>
  httpPostFileAndParse({
    route: '/api/development/tax-forms-v2/pdf-attachment',
    body: formData,
  });

export const useMutationAddTaxFormAttachment = () =>
  useCustomMutation<AddTaxFormAttachmentResponse, QueryError, AddTaxFormAttachmentVariable>({
    customMutate: addTaxFormAttachment,
    successMessage: 'Adding tax form succeeded',
    errorMessage: 'Adding tax form failed',
  });
