import { combineReducers } from 'redux';

import sharedReducer from './shared';
import baseCalcSpecTypeReducer from './baseCalcSpec';
import consolCalcSpecsTypeReducer from './consolCalcSpecs';
import k1CalcSpecTypeReducer from './k1CalcSpec';
import conspbCalcSpecTypeReducer from './conspbCalcSpec';

export default combineReducers({
  shared: sharedReducer,
  baseCalcSpecType: baseCalcSpecTypeReducer,
  consolCalcSpecType: consolCalcSpecsTypeReducer,
  k1CalcSpecType: k1CalcSpecTypeReducer,
  conspbCalcSpecType: conspbCalcSpecTypeReducer,
});
