import { TOTAL_DESC } from '@common-packages/shared-constants';

import {
  defaultColumnDefinition,
  currencyColumnDefinition,
} from '../../../../shared/columnDefinitions';

const isTotalRow = ({ data }) => data.accountDescription === TOTAL_DESC;

const overlinedCellClassRules = {
  'overlined-cell': isTotalRow,
  'bold-cell': isTotalRow,
};

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  sortable: false,
  cellClassRules: overlinedCellClassRules,
};

const columnDefinitions = [
  {
    ...extendedDefaultColumnDefinition,
    headerName: 'Description',
    field: 'accountDescription',
    maxWidth: 350,
  },
  {
    ...extendedDefaultColumnDefinition,
    headerName: 'LEID',
    field: 'dcsLeid',
  },
  {
    ...extendedDefaultColumnDefinition,
    headerName: 'CDR',
    field: 'dcsCdr',
    maxWidth: 100,
  },
  {
    ...extendedDefaultColumnDefinition,
    headerName: 'BSLA',
    field: 'dcsBsla',
  },
  {
    ...extendedDefaultColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'dcsJurisdiction',
  },
  {
    ...extendedDefaultColumnDefinition,
    headerName: 'Account',
    field: 'dcsAccountId',
    maxWidth: 120,
  },
  {
    ...extendedDefaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Book',
    field: 'dcsBookAmount',
    maxWidth: 100,
  },
  {
    ...extendedDefaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Adjustment',
    field: 'dcsAdjAmount',
    maxWidth: 100,
  },
  {
    ...extendedDefaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Book/Tax',
    field: 'dcsM1Amount',
    maxWidth: 100,
  },
  {
    ...extendedDefaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Tax',
    field: 'dcsTaxAmount',
    maxWidth: 100,
  },
];

export default columnDefinitions;
