import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

const hasPermissionToPropagateTool = customerPermissions =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.PROPAGATE_DATA_MODEL],
  });

export default hasPermissionToPropagateTool;
