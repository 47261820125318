import React from 'react';

import { GlobalContextValues } from '../../shared/containerComponents/globalContext';
import styles from '../returnStatusTrackerDetails.module.scss';

const ReturnStatusTrackerDetailsHeader = () => {
  return (
    <div className="card-header return-status-tracker-details-header">
      <h4 className={styles.removeMargin}>Return Status Tracker Details</h4>
      <div className={`row ${styles.returnStatusTrackerDetailsHeaderGlobalContextValues}`}>
        <GlobalContextValues />
      </div>
    </div>
  );
};

export default ReturnStatusTrackerDetailsHeader;
