import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';

import { dateFormatter } from '../../../../shared/formatters';
import config from '../../../../config';
import SharedHeader from '../../../../shared/displayComponents/header/header.component';
import { useQueryFederalSyncDetails } from '../../../../shared/queries/federalDataDiagnostics';
import {
  useMutationRefreshFederalData,
  useMutationFilingGroupsSendMessage,
} from '../../../../shared/mutations/federalDataDiagnostics';
import { globalContextSelector } from '../../../../shared/store/selectors';
import { GlobalContextParams, RefreshFederalData } from '../../../../../../common/types';
import Loading from '../../../../shared/displayComponents/loading.component';
import { refreshStatusSourceMap } from '../../../../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { QueryKeys } from '../../../../shared/queries';
import { showConfirmModal } from '../../../../shared/confirmModal/store/actions';

import styles from './federalDataDetails.module.scss';
import getColumnDefinitions from './federalDataDetails.columnDefinitions';
import ReturnToFederalDataDiagnostics from './returnToFederalDataDiagnostics';

const FederalDataDetails = () => {
  const dispatch = useDispatch();
  const { groupOrEntityId, reportingPeriodId, correlationId, pageType } = useParams<{
    pageType: string;
    groupOrEntityId: string;
    reportingPeriodId: string;
    correlationId: string;
  }>();
  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);
  const queryClient = useQueryClient();
  const { taxYear }: GlobalContextParams = useSelector(globalContextSelector).params;

  enum PageTypes {
    Entity = 'entity',
    Group = 'group',
  }

  const {
    mutateAsync: refreshEntitiesOrGroupsFederalData,
    isLoading: isRefreshingFederalData,
  } = useMutationRefreshFederalData();

  const {
    mutateAsync: filingGroupsSendMessage,
    isLoading: isSendingMessage,
  } = useMutationFilingGroupsSendMessage();

  const { data: details, isFetching: isFetchingDetails } = useQueryFederalSyncDetails({
    params: {
      entityId: pageType === PageTypes.Entity ? groupOrEntityId : null,
      filingGroupId: pageType === PageTypes.Group ? groupOrEntityId : null,
      reportingPeriodId,
      taxYear,
    },
    enabled: Boolean(taxYear && groupOrEntityId && pageType),
  });

  const syncData = useCallback(
    ({ isFullSync }: { isFullSync: boolean }) => {
      const refreshFederalData: RefreshFederalData = {
        entitiesOrGroups: [
          {
            entityId: details?.entityId,
            gtwUpdatedDate: details?.gtwUpdatedDate,
            reportingPeriod: reportingPeriodId,
            filingGroupId: details?.filingGroupId,
          },
        ],
        taxYear,
        isRequestingGTWFilesOnly: !isFullSync,
      };

      if (details?.autoFedRefresh === 'Y' || pageType === PageTypes.Group) {
        refreshEntitiesOrGroupsFederalData(refreshFederalData);
      } else {
        dispatch(
          showConfirmModal({
            title: isFullSync ? 'Refresh Federal Data' : 'Refresh Federal Files',
            text: `The user has turned off automatifcally refreshing this entitiy. Are you sure you want to ${
              isFullSync
                ? 'perform a federal data refresh for both files and data?'
                : 'perform a federal data refresh for files only?'
            }`,
            confirmCallback: async () => {
              await refreshEntitiesOrGroupsFederalData(refreshFederalData);
            },
          }),
        );
      }
    },
    [
      dispatch,
      refreshEntitiesOrGroupsFederalData,
      PageTypes,
      pageType,
      reportingPeriodId,
      details,
      taxYear,
    ],
  );

  const realoadTableClick = useCallback(() => {
    queryClient.resetQueries(QueryKeys.FederalDataDiagnostics.Details);
  }, [queryClient]);

  const syncFilesClick = useCallback(() => {
    syncData({ isFullSync: false });
  }, [syncData]);

  const fullSyncClick = useCallback(() => {
    syncData({ isFullSync: true });
  }, [syncData]);

  const filingGroupSendMessageClick = useCallback(async () => {
    if (details?.filingGroupId) {
      await filingGroupsSendMessage({
        filingGroupIds: [details.filingGroupId],
        taxYear,
      });
    }
  }, [filingGroupsSendMessage, details?.filingGroupId, taxYear]);

  return (
    <>
      <SharedHeader title={groupOrEntityId} preHeaderContent={<ReturnToFederalDataDiagnostics />}>
        <div className={styles.subheader}>
          <span> Federal Data Diagnostics Details </span>
        </div>
      </SharedHeader>
      <div className={styles.informationPanel}>
        <div className={styles.informationPanelHeader}>
          <h4>Refresh Information</h4>
        </div>
        <Loading small isLoading={isFetchingDetails}>
          <div className="row">
            <div className="col">
              <div className={styles.informationField}>
                <label className={styles.informationLabel}>Updated On:</label>
                <span>
                  {details?.sltUpdatedOn
                    ? dateFormatter(details.sltUpdatedOn, config.AMERICAN_DATE_TIME_FORMAT)
                    : 'Not Recorded'}
                </span>
              </div>
            </div>

            <div className="col">
              <div className={styles.informationField}>
                <label className={styles.informationLabel}>Source Updated On:</label>
                <span>
                  {details?.gtwUpdatedDate
                    ? dateFormatter(details.gtwUpdatedDate, config.AMERICAN_DATE_TIME_FORMAT)
                    : 'Not Recorded'}
                </span>
              </div>
            </div>

            <div className="col">
              <div className={styles.informationField}>
                <label className={styles.informationLabel}>Refresh Status:</label>
                <span>{refreshStatusSourceMap.get(details?.gtwRefreshStatus ?? 0)}</span>
              </div>
            </div>
            <div className="col">
              <div className={styles.informationField}>
                <label className={styles.informationLabel}>
                  Attempts Since Last Successful Sync:
                </label>
                <span>{details?.gtwRefreshFailCount}</span>
              </div>
            </div>
            {pageType === PageTypes.Entity ? (
              <div className="col">
                <div className={styles.informationField}>
                  <label className={styles.informationLabel}>DCS LEID:</label>
                  <span>{details?.dcsLeid}</span>
                </div>
              </div>
            ) : null}
          </div>
        </Loading>
        <div className={styles.buttons}>
          <Button
            size="md"
            onClick={pageType === PageTypes.Entity ? fullSyncClick : syncFilesClick}
            className={styles.button}
            kind="secondary"
            isLoading={isRefreshingFederalData}
          >
            {pageType === PageTypes.Entity ? 'Full Sync' : 'Sync Files'}
          </Button>
          {pageType === PageTypes.Entity ? (
            <Button
              size="md"
              onClick={syncFilesClick}
              className={styles.button}
              kind="secondary"
              isLoading={isRefreshingFederalData}
            >
              File Only Sync
            </Button>
          ) : (
            <Button
              size="md"
              onClick={filingGroupSendMessageClick}
              className={styles.button}
              kind="secondary"
              isLoading={isSendingMessage}
            >
              Inform GTW Group Has Updated
            </Button>
          )}
          <Button
            size="md"
            onClick={realoadTableClick}
            disabled={isFetchingDetails}
            className={styles.button}
            kind="secondary"
          >
            Reload Table
          </Button>
        </div>
      </div>
      <div className={styles.tableFormat}>
        <AgGrid
          rowData={details?.logs}
          columnDefs={columnDefinitions}
          withSearchBar
          initialQuickFilterValue={correlationId || ''}
          autoMaxWidth
          isGridLoading={isFetchingDetails}
        />
      </div>
    </>
  );
};

export default FederalDataDetails;
