import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../../../shared/columnDefinitions/cellRenderers';

export default [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'AccountId',
    field: 'infoAccountId',
    pinned: 'left',
    lockPinned: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Information Type',
    field: 'infoType',
    width: 200,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Description',
    field: 'infoAccountDescription',
    width: 400,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Active',
    field: 'activeYn',
    cellRenderer: EditableCheckboxCellRenderer,
    cellRendererParams: {
      disabled: true,
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Global Ind',
    field: 'globalInd',
    cellRenderer: EditableCheckboxCellRenderer,
    cellRendererParams: {
      disabled: true,
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Pship Global Ind',
    field: 'pshipGlobalInd',
    cellRenderer: EditableCheckboxCellRenderer,
    cellRendererParams: {
      disabled: true,
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Data Type',
    field: 'dataType',
  },
];
