import routesDefinitions from '@common-packages/routes-definitions';
import { DRILL_TYPE } from '@common-packages/drilling';

const { Routes } = routesDefinitions;

const DEFAULT_TAX_TYPE = 'income';

export const createDrillRoute = (drillData, context) => {
  if (!drillData) {
    return;
  }

  // to support SLT and print-service interface
  const parentDatasetDefinitionId =
    drillData.parentDatasetDefinitionId || drillData.drillInitiatorDataSetDefID;
  const datasetDefinitionId = drillData.datasetDefinitionId || drillData.drillTargetDataSetDefID;
  const initialParentDatasetInstanceId =
    drillData.initialParentDatasetInstanceId || drillData.drillTargetParentDataSetInstanceID;

  // to support SLT and print-service interface
  const categoryId = drillData.categoryId || drillData.drillGScreenCategory;
  const accountId = drillData.accountId || drillData.drillGScreenAccount;

  switch (drillData.drillType) {
    case DRILL_TYPE.MULT_GRID:
      return Routes.workpaperInstance.compiledRoute({
        ...context,
        parentDatasetDefinitionId,
        datasetDefinitionId,
        initialParentDatasetInstanceId,
      });
    case DRILL_TYPE.GENERIC_DRILL:
      if (!accountId && categoryId) {
        return Routes.genericDrillCategory.compiledRoute({
          taxName: drillData.taxType || DEFAULT_TAX_TYPE,
          ...context,
          categoryId,
        });
      }

      return Routes.genericDrillAccount.compiledRoute({
        taxName: drillData.drillType.taxType || DEFAULT_TAX_TYPE,
        ...context,
        accountId,
        categoryId,
      });
    default:
      return null;
  }
};
