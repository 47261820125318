import { useMutation, useQueryClient, QueryKey, Query } from 'react-query';
import { parseAsJson } from '@tls/ui-request';

import { errorNotification, successNotification } from '../notification/store/actions';
import { httpFetch } from '../../utils/fetchService';
import store from '../../store';
import { HttpMethods } from '../enums';

export interface OnMutationSuccessCallback<TData, TVariables> {
  (data: TData, variables: TVariables): void;
}

export interface OnMutationErrorCallback<TError, TVariables> {
  (error: TError, variables: TVariables): void;
}

export interface OnMutationSettledCallback<TData, TError, TVariables> {
  (data: TData | undefined, error: TError | null, variables: TVariables): void;
}

export interface OnMutationMumate<TVariables> {
  (variables: TVariables): Promise<undefined | null | void> | null | undefined | void;
}

type ResetKey = ((query: Query<unknown, unknown, unknown, QueryKey>) => boolean) | string;

interface ResetKeyFactory<TData, TVariables> {
  (data: TData, variables: TVariables): ResetKey;
}

interface CustomMutationOptionsBase<TData, TError, TVariables> {
  invalidateKey?: QueryKey | ((query: Query) => boolean);
  resetKey?: ResetKey;
  resetKeyFactory?: ResetKeyFactory<TData, TVariables>;
  errorMessage?: string;
  successMessage?: string | ((data: TData) => string);
  onSuccess?: OnMutationSuccessCallback<TData, TVariables>;
  onError?: OnMutationErrorCallback<TError, TVariables>;
  onSettled?: OnMutationSettledCallback<TData, TError, TVariables>;
  onMutate?: OnMutationMumate<TVariables>;
  parseResponseAsJson?: boolean;
  parseResponseErrorMessage?: boolean;
}

interface CustomMutationWithHandleData<TData, TError, TVariables, TBody>
  extends CustomMutationOptionsBase<TData, TError, TVariables> {
  handleData: (variables: TVariables) => { url: string; method: HttpMethods; body?: TBody };
  customMutate?: null;
}
interface CustomMutationWithCustomMutate<TData, TError, TVariables>
  extends CustomMutationOptionsBase<TData, TError, TVariables> {
  handleData?: null;
  customMutate: (variables: TVariables) => Promise<TData>;
}

type CustomMutationOptions<TData, TError, TVariables, TBody> =
  | CustomMutationWithHandleData<TData, TError, TVariables, TBody>
  | CustomMutationWithCustomMutate<TData, TError, TVariables>;

type FetchingError = {
  isFetchingError: boolean;
  message: string;
} & Error;

type AuthorizationError = {
  isAuthorizationError: boolean;
  message: string;
} & Error;

type QueryError = Error & (FetchingError | AuthorizationError);

const isAuthorizationError = (error: Error | QueryError): error is AuthorizationError =>
  typeof (error as AuthorizationError).isAuthorizationError !== 'undefined' &&
  typeof (error as AuthorizationError).message !== 'undefined';

const isFetchingError = (error: Error | QueryError): error is FetchingError =>
  typeof (error as FetchingError).isFetchingError !== 'undefined' &&
  typeof (error as QueryError).message !== 'undefined';

const createUseCustomMutation = ({
  // disabling any here because without it it would be trying to parse it as Promise<TData>
  // while it's just a fallback
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultFetch = () => null as any,
  displaySuccessNotification = () => null,
  displayErrorNotification = () => null,
}: {
  defaultFetch: <TData>(options: {
    url: string;
    method: HttpMethods;
    body: unknown;
    errorMessage?: string;
    parseResponseErrorMessage?: boolean;
  }) => Promise<TData>;
  displaySuccessNotification: (message?: string) => unknown;
  displayErrorNotification: (message?: string) => void;
}) => <
  TData,
  TError extends Error | QueryError = QueryError,
  TVariables = unknown,
  TBody = TVariables
>({
  invalidateKey,
  resetKey,
  resetKeyFactory,
  errorMessage,
  successMessage,
  onSuccess: onSuccessCallback,
  onError: onErrorCallback,
  onSettled,
  onMutate,
  handleData,
  customMutate,
  parseResponseAsJson = false,
  parseResponseErrorMessage = false,
}: CustomMutationOptions<TData, TError, TVariables, TBody>) => {
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables>(
    (variables: TVariables) => {
      if (customMutate) {
        if (parseResponseAsJson) {
          const result = customMutate(variables);
          return result.then(parseAsJson);
        }
        return customMutate(variables);
      }

      // typescript is not able to understand that always either `customMutate` or `handleData`
      // will be present, therefore we need to provide some defaults here that won't even be used
      const { url = '', method = HttpMethods.POST, body } = handleData?.(variables) || {};
      const result = defaultFetch<TData>({
        url,
        method,
        body,
        errorMessage,
        parseResponseErrorMessage,
      });

      if (parseResponseAsJson) {
        return result.then(parseAsJson);
      }

      return result;
    },
    {
      onMutate,
      onSuccess: (data, variables) => {
        if (invalidateKey) {
          if (typeof invalidateKey === 'function') {
            queryClient.invalidateQueries({ predicate: invalidateKey });
          } else {
            queryClient.invalidateQueries(invalidateKey, { exact: true });
          }
        }
        if (resetKeyFactory) {
          resetKey = resetKeyFactory(data, variables);
        }
        if (resetKey) {
          if (typeof resetKey === 'function') {
            queryClient.resetQueries({ predicate: resetKey });
          } else {
            queryClient.resetQueries(resetKey);
          }
        }
        if (onSuccessCallback) {
          onSuccessCallback(data, variables);
        }
        if (successMessage) {
          if (typeof successMessage === 'function') {
            const message = successMessage(data);
            if (message) {
              displaySuccessNotification(message);
            }
          } else {
            displaySuccessNotification(successMessage);
          }
        }
      },
      onError: (error, variables) => {
        if (onErrorCallback) {
          onErrorCallback(error, variables);
        }
        if (isAuthorizationError(error)) {
          return;
        }
        if (isFetchingError(error) && error.message) {
          return displayErrorNotification(error.message);
        }
        throw error;
      },
      onSettled,
    },
  );
};

export const useCustomMutation = createUseCustomMutation({
  defaultFetch: ({ url, method, body, errorMessage, parseResponseErrorMessage }) =>
    httpFetch(method)({ route: url, body, errorMessage, parseResponseErrorMessage }),
  displaySuccessNotification: message => store.dispatch(successNotification(message)),
  displayErrorNotification: message => store.dispatch(errorNotification(message)),
});
