export const timePeriodOptions = [
  {
    value: 24,
    label: '24h',
  },
  {
    value: 24 * 2,
    label: '2 days',
  },
  {
    value: 24 * 3,
    label: '3 days',
  },
  {
    value: 24 * 7,
    label: '7 days',
  },
  {
    value: 24 * 30,
    label: '30 days',
  },
];
