import { ColDef } from 'ag-grid-community';

import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../../shared/columnDefinitions/cellRenderers';
import { DatasetInstancesResponseData } from '../../../../../common/types';

export const getColumnDefinitions = ({
  additionalColumns,
  updateRow,
  ToggleAllSelectionsHeader,
}: {
  additionalColumns: { name: string; id: string }[];
  updateRow: (row: DatasetInstancesResponseData[number]) => void;
  ToggleAllSelectionsHeader: ColDef['headerComponent'];
}) => {
  const onCellValueChanged = ({ data }: { data: DatasetInstancesResponseData[number] }) =>
    updateRow(data);

  return [
    {
      ...defaultColumnDefinition,
      headerComponent: ToggleAllSelectionsHeader,
      headerName: '',
      field: 'isSelected',
      colId: 'isSelected',
      width: 50,
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellStyle: {
        textAlign: 'center',
      },
      pinned: 'left',
      lockPosition: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Instance',
      field: 'instanceIdentifier',
      pinned: 'left',
      lockPosition: true,
    },
    ...additionalColumns.map(({ name, id }) => ({
      ...defaultColumnDefinitionWithFilter,
      headerName: name,
      field: id,
      autoHeaderHeight: true,
    })),
  ];
};
