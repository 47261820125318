import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { globalContextSelector } from '../../shared/store/selectors';

import { fetchFilingStates } from './actions';

export function useFetchFilingStates() {
  const globalContext = useSelector(globalContextSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    const taxYear = globalContext.params.taxYear;
    const period = globalContext.params.period;
    const businessEntityId = globalContext.params.businessEntityId;
    const isEnoughContextReady = Boolean(taxYear && period && businessEntityId);

    if (globalContext.isSeparate && isEnoughContextReady) {
      dispatch(fetchFilingStates({ taxYear, period, entityId: businessEntityId }));
    }
  }, [
    dispatch,
    globalContext.isSeparate,
    globalContext.params.taxYear,
    globalContext.params.period,
    globalContext.params.businessEntityId,
  ]);
}
