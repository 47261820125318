import { createSelector } from 'reselect';

export const eFileLastSubmissionIdSelector = ({ eFile }) => eFile.data.lastSubmissionId;

export const eFileLastBinderIdSelector = ({ eFile }) => eFile.data.lastBinderId;
export const eFileOverallStatusSelector = ({ eFile }) => eFile.data.overallStatus;
export const isFetchingEFileStepsDataSelector = ({ eFile }) => eFile.isFetchingStepsData;
export const eFileStepsDataSelector = ({ eFile }) => eFile.data.stepsData;

export const caseListSelector = ({ eFile }) => eFile.caseList;
export const isFetchingCaseListSelector = ({ eFile }) => eFile.isFetchingCaseList;

export const validationMessagesSelector = ({ eFile }) => eFile.validationMessages;
export const isFetchingValidationMessagesSelector = ({ eFile }) =>
  eFile.isFetchingValidationMessages;
export const validationMessagesErrorsSelector = createSelector(
  [validationMessagesSelector],
  validationMessages => validationMessages.filter(message => message.ValidationType === 'Error'),
);
export const validationMessagesWarningsSelector = createSelector(
  [validationMessagesSelector],
  validationMessages => validationMessages.filter(message => message.ValidationType === 'Warning'),
);

export const isFetchingSignatureDeclarationSelector = ({ eFile }) =>
  eFile.isFetchingSignatureDeclaration;
export const signatureDeclarationSelector = ({ eFile }) => eFile.signatureDeclaration;

export const federalProformaSubmissionIdSelector = ({ eFile }) => eFile.federalProformaSubmissionId;
export const isFetchingFederalProformaSubmissionIdSelector = ({ eFile }) =>
  eFile.isFetchingFederalProformaSubmissionId;

export const persistedWizardFieldsValuesSelector = ({ eFile }) =>
  eFile.data.persistedWizardFieldsValues;
