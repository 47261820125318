import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

import { CustomerPermissionsProps } from '../../shared/propTypes/customerPropTypes';

const hasIndividualPartnersPermission = (customerPermissions: CustomerPermissionsProps) =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.READ,
    routeDefinitions: [PERMISSION.INDIVIDUAL_PARTNERS],
  });

export default hasIndividualPartnersPermission;
