import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';
import { filingTypes } from '../../shared/constants';
import { BinderData, BinderAttachmentData } from '../../shared/queries/binders';
import { GlobalContext, GlobalContextParams } from '../../../../common/types';
import formatName from '../binderTree/formatName';

import { TreeItem } from './types';

interface ReturnTreeDataFactoryProps {
  returnData: BinderData;
  applyPermissions: (tree: TreeItem[]) => TreeItem[];
  globalContext: GlobalContext;
}

const returnTreeDataFactory = ({
  returnData,
  applyPermissions,
  globalContext,
}: ReturnTreeDataFactoryProps) => {
  const { filingTypeId } = globalContext;

  const withContextParams = (routeParams: GlobalContextParams) =>
    getContextAwareUrlParams(routeParams);

  const tree: TreeItem[] = [
    {
      name: returnData.forms.label,
      type: 'folder',
      children: returnData.forms.items.map(
        ({ formName, formId, taxYear, period, jurisdictionId, orgId, consolId }) => ({
          type: 'screen',
          isHandledByPermissionRules: true,

          name: filingTypeId === filingTypes.CONSOLIDATED ? formatName(formName, orgId) : formName,
          id: 'view-print-tax-return',
          routeQueryString: {
            formId,
          },
          routeParams: withContextParams({
            taxYear: `${taxYear}`,
            period: `${period}`,
            jurisdictionId,
            filingTypeId: filingTypeId ?? '',
            businessEntityId: orgId || consolId || '',
          }),
        }),
      ),
    },
    {
      name: returnData.attachments.label,
      type: 'folder',
      children: returnData.attachments.items.map((attachmentData: BinderAttachmentData) => ({
        type: 'component',
        component: 'AttachmentPreview',
        props: attachmentData,
      })),
    },
  ];

  return applyPermissions(tree);
};

export default returnTreeDataFactory;
