import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import AppkitIcon from '../../../shared/displayComponents/appkitIcon/appkitIcon.component';

import styles from './backToReturn.module.scss';

interface BackToReturnProps {
  returnId: string;
}

const BackToReturn = ({ returnId }: BackToReturnProps) => {
  return (
    <Link to={Routes.returnMaintenance.compiledRoute({ returnId })}>
      <span className={styles.backToReturn}>
        <AppkitIcon icon="left-chevron" size={14} /> BACK TO RETURN
      </span>
    </Link>
  );
};

export default BackToReturn;
