import {
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import camelCase from 'lodash.camelcase';

import {
  FilingAttributesColumnBlueprint,
  FindConsolidationMemberData,
  FindFilingOrNONFilingMemberData,
} from '../../../common/types';
import ConditionalSelectionListEditorSelectorFactory from '../shared/displayComponents/editorSelector/conditionalSelectionListEditorSelector.factory';
import {
  DisplayedAttributes,
  DisplayedTaxTypeAttributes,
  DisplayedTaxTypeAttributesForDropdown,
} from '../shared/enums';

interface SelectOptionProps {
  label: string;
  value: string | number | null;
}

export const taxTypeSelectAllOption = {
  label: 'Income & Franchise',
  value: DisplayedTaxTypeAttributesForDropdown.TAX_TYPE_ALL,
  isTaxReturnDefinitionEnabled: true,
};

export const getColumnBlueprintValue = (
  key: string,
  columnBlueprint?: FilingAttributesColumnBlueprint,
) => {
  const selectedValue = (columnBlueprint?.children || []).find(child => child.field === key);
  return selectedValue?.displayName || key;
};

export const filingAttributesCellEditor = ({
  param,
  filingType,
}: {
  param: ICellEditorParams;
  filingType: DisplayedAttributes;
}) => {
  const optionsByColumnGroups = param.data?.filingAttributesOptions?.[camelCase(filingType)] || [];

  const editorSelector = ConditionalSelectionListEditorSelectorFactory({
    isClearable: false,
  });
  return editorSelector({
    ...param,
    data: {
      selectionListItems: optionsByColumnGroups,
    },
  });
};

export const taxTypeValueGetter = ({
  data,
}: {
  data: FindFilingOrNONFilingMemberData | FindConsolidationMemberData;
}) => {
  const displayedTaxTypeAttributes = Object.values(DisplayedTaxTypeAttributes);
  const selectedTaxTypeAttributes = displayedTaxTypeAttributes.filter(
    attributeKey => data[attributeKey],
  );
  if (selectedTaxTypeAttributes.length === displayedTaxTypeAttributes.length) {
    return taxTypeSelectAllOption.value;
  }
  return selectedTaxTypeAttributes[0] || '';
};

export const taxTypeValueFormatterFactory = (
  taxTypeColumnBlueprint: FilingAttributesColumnBlueprint,
) => ({ value }: { value: string }) =>
  value === taxTypeSelectAllOption.value
    ? taxTypeSelectAllOption.label
    : getColumnBlueprintValue(value, taxTypeColumnBlueprint);
export const isFilingAttributesHasOption = (
  params: ICellRendererParams | ICellEditorParams | ValueGetterParams | ValueFormatterParams,
  filingType: DisplayedAttributes,
) =>
  Boolean(
    filingAttributesCellEditor({
      param: params as ICellEditorParams,
      filingType,
    })?.params.values.length,
  );

export const isFilingAttributesMatched = ({
  params,
  value,
  filingType,
  columnBlueprint,
}: {
  params: ICellEditorParams;
  value?: string;
  filingType: DisplayedAttributes;
  columnBlueprint: FilingAttributesColumnBlueprint;
}) => {
  const displayNameOptions = filingAttributesCellEditor({
    param: params,
    filingType,
  })?.params.values.map(
    (item: string) =>
      columnBlueprint?.children.find(child => child.field === item)?.displayName || item,
  );
  const isDisplayName = Boolean(
    columnBlueprint?.children.find(child => child.displayName === value),
  );
  const toDisplayName = (value?: string) =>
    columnBlueprint?.children.find(child => child.field === value)?.displayName || value;

  return isDisplayName
    ? Boolean(displayNameOptions?.includes(value))
    : Boolean(displayNameOptions?.includes(toDisplayName(value)));
};

export const shouldEnableEditing = ({
  params,
  value,
  filingType,
  columnBlueprint,
}: {
  params: ICellEditorParams;
  value?: string;
  filingType: DisplayedAttributes;
  columnBlueprint: FilingAttributesColumnBlueprint;
}) => {
  const filingAttributesOption = params.data.filingAttributesOptions?.[camelCase(filingType)] || [];
  if (!filingAttributesOption.length || !params.data.taxYearEnding) {
    return false;
  }
  if (filingAttributesOption.length === 1) {
    return !isFilingAttributesMatched({ params, value, filingType, columnBlueprint });
  }

  return true;
};

export const isNewValueValidOption = ({
  newValue,
  options,
}: {
  newValue: string | number;
  options: SelectOptionProps[] | undefined;
}) => Boolean(options?.find(({ value }) => value === newValue));
