const Yup = require('yup');

const getAddEditCheckboxGroupSchema = ({
  checkboxGroupsNames = [],
  isEditMode = false,
  name = '',
}) => {
  const listToCheckAgainst = isEditMode
    ? checkboxGroupsNames.filter(checkboxGroupName => checkboxGroupName !== name)
    : checkboxGroupsNames;

  return Yup.object().shape({
    name: Yup.string().max(100).required('Checkbox Group Name is required').uniqueInList({
      message: 'Checkbox Group Name must be unique',
      list: listToCheckAgainst,
    }),
    description: Yup.string().max(100).nullable(),
  });
};

module.exports = { getAddEditCheckboxGroupSchema };
