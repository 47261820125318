import { httpGetAndParse } from '../../../../utils/fetchService';

export const fetchPartnershipAmountDetails = ({
  taxYear,
  period,
  entityId,
  jurisdictionId,
  accountId,
}) =>
  httpGetAndParse({
    route: `/api/entities/partnership-amount-details/${taxYear}/${period}/${entityId}/${jurisdictionId}/${accountId}`,
    errorMessage: 'Fetching partnership amount details failed',
  });
