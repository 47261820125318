import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchFilingGroupsTypes,
  SELECT_FILING_GROUP_ID,
  fetchFilingGroupsOptionsTypes,
  addFilingGroupToConsolidationTypes,
  removeFilingGroupFromConsolidationTypes,
} from './types';

export const fetchFilingGroups = apiAction({
  types: fetchFilingGroupsTypes,
  apiAction: api.fetchFilingGroups,
});

export const selectFilingGroupId = filingGroup => ({
  type: SELECT_FILING_GROUP_ID,
  payload: filingGroup,
});

export const fetchFilingGroupsOptions = apiAction({
  types: fetchFilingGroupsOptionsTypes,
  apiAction: api.fetchFilingGroupsOptions,
});

export const addFilingGroupToConsolidation = apiAction(
  {
    types: addFilingGroupToConsolidationTypes,
    apiAction: api.addFilingGroupToConsolidation,
  },
  { successNotificationMessage: () => 'Filing group added successfully' },
);

export const removeFilingGroupFromConsolidation = apiAction(
  {
    types: removeFilingGroupFromConsolidationTypes,
    apiAction: api.removeFilingGroupFromConsolidation,
  },
  { successNotificationMessage: () => 'Filing group removed' },
);
