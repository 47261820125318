import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  entityIdSelector,
  isFetchingEntitiesSelector,
  taxYearSelector,
  periodSelector,
} from '../../../shared/store/selectors';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';

import getColumnDefinitions from './federalReconciliationVariances.columnDefinitions';
import { fetchFederalReconciliationVariances } from './store/actions';
import {
  federalReconciliationVariancesWithTotalSelector,
  isFetchingFederalReconciliationVariancesSelector,
} from './store/selectors';

import './federalReconciliationVariances.style.scss';

const FederalReconciliationVariances = ({
  match,
  taxYear,
  period,
  orgId,
  isFetchingEntities,
  federalReconciliationVariances,
  fetchFederalReconciliationVariances,
  isFetchingFederalReconciliationVariances,
}) => {
  const { accountGroup } = match.params;

  useEffect(() => {
    const isContextReady = taxYear && period && orgId && accountGroup;
    if (!isContextReady) {
      return;
    }

    fetchFederalReconciliationVariances({
      taxYear,
      period,
      orgId,
      accountGroup,
    });
  }, [fetchFederalReconciliationVariances, taxYear, period, orgId, accountGroup]);

  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);
  return (
    <>
      <div className="federal-reconciliation-variances row grid-row">
        <div className="col">
          <AgGrid
            isGridLoading={isFetchingEntities || isFetchingFederalReconciliationVariances}
            columnDefs={columnDefinitions}
            rowData={federalReconciliationVariances}
          />
        </div>
      </div>
    </>
  );
};

FederalReconciliationVariances.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  isFetchingEntities: PropTypes.bool.isRequired,
  fetchFederalReconciliationVariances: PropTypes.func.isRequired,
  isFetchingFederalReconciliationVariances: PropTypes.bool.isRequired,
  federalReconciliationVariances: PropTypes.arrayOf(
    PropTypes.shape({
      componentId: PropTypes.string.isRequired,
      federalAmount: PropTypes.number.isRequired,
      reconVariance: PropTypes.number.isRequired,
      sltAmount: PropTypes.number.isRequired,
      hasNote: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountGroup: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    orgId: entityIdSelector(state),
    isFetchingEntities: isFetchingEntitiesSelector(state),
    federalReconciliationVariances: federalReconciliationVariancesWithTotalSelector(state),
    isFetchingFederalReconciliationVariances: isFetchingFederalReconciliationVariancesSelector(
      state,
    ),
  }),
  {
    fetchFederalReconciliationVariances,
  },
)(FederalReconciliationVariances);
