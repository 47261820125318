import { ColDef } from 'ag-grid-community';
import { Routes } from '@common-packages/routes-definitions';

import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../../../shared/columnDefinitions';
import { LinkCellRendererFactory } from '../../../../shared/columnDefinitions/cellRenderers';
import downloadFile from '../../../../utils/downloadFile';

const getColumnDefinitions = (): ColDef[] => {
  const stepFileDownloadLinkRenderer = (fileType: 'input' | 'output') =>
    LinkCellRendererFactory({
      getText: ({ value }) => (value ? 'Download' : ''),
      clickHandler: (_, data) =>
        downloadFile({
          route: `${Routes.eFileSupportDownloadStepFile.compiledRoute({
            stepId: data.stepId,
            fileType,
          })}`,
        }),
    });
  const stepApiCallZipDownloadLinkRenderer = () =>
    LinkCellRendererFactory({
      getText: ({ value }) => (value ? 'Download' : ''),
      clickHandler: (_, data) =>
        downloadFile({
          route: `${Routes.eFileSupportDownloadStepFile.compiledRoute({
            stepId: data.apiLogId,
            fileType: 'input',
          })}`,
        }),
    });

  return [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Substep Name',
      field: 'stepName',
      width: 250,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Status',
      field: 'status',
      width: 90,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Response',
      field: 'ttiResponse',
      tooltipField: 'ttiResponse',
      flex: 1,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Input File',
      field: 'isInputFileAvailable',
      width: 80,
      cellRenderer: stepFileDownloadLinkRenderer('input'),
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Output File',
      field: 'isOutputFileAvailable',
      width: 80,
      cellRenderer: stepFileDownloadLinkRenderer('output'),
    },
    {
      ...defaultColumnDefinition,
      headerName: 'API Calls',
      field: 'isApiLogZipAvailable',
      width: 80,
      cellRenderer: stepApiCallZipDownloadLinkRenderer(),
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Created On',
      field: 'createdOn',
      width: 160,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Created By',
      field: 'createdEmail',
      width: 175,
    },
  ];
};

export default getColumnDefinitions;
