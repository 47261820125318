import React from 'react';
import PropTypes from 'prop-types';

import useCompileInfoTexts from '../../hooks/useCompileInfoTexts';
import { compileInfoDataPropType } from '../../propTypes';
import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';
import { TextCellRendererFactory } from '../../../../shared/columnDefinitions/cellRenderers';

const TextRenderer = TextCellRendererFactory({
  getText: ({ value }) => (value ? value : ''),
});

const CompileInfoRendererFramework = ({ data, colDef }) => {
  const { compileInfoText } = useCompileInfoTexts({ data });
  return <TextRenderer colDef={colDef} value={compileInfoText} />;
};

CompileInfoRendererFramework.propTypes = {
  data: compileInfoDataPropType.isRequired,
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }),
};

export default [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdictionName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Last Successful Compile',
    field: 'lastCompiled',
    width: 168,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Expressions',
    field: 'changedExpressions',
    width: 90,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Compile State',
    field: 'compileState',
    cellRenderer: CompileInfoRendererFramework,
  },
];
