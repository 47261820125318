import {
  GenericScreenRowTypes,
  GenericScreenDataTypes,
  GenericScreenTextTypes,
  GenericScreenDrilldownTypes,
} from '@common-packages/shared-constants';

import getOptionsListFromObjectKeys from '../../../utils/getOptionsListFromObjectKeys';

const capitalize = true;
export const rowTypesOptions = getOptionsListFromObjectKeys(GenericScreenRowTypes, capitalize);
export const dataTypesOptions = getOptionsListFromObjectKeys(GenericScreenDataTypes, capitalize);
export const textTypesOptions = getOptionsListFromObjectKeys(GenericScreenTextTypes, capitalize);
export const drilldownOptions = getOptionsListFromObjectKeys(
  GenericScreenDrilldownTypes,
  capitalize,
);
