const Yup = require('yup');

const createBusinessGroupSchema = ({ businessGroups }) =>
  Yup.object()
    .shape({
      businessGroup: Yup.string()
        .required()
        .max(10, 'Business Group cannot exceed 10 characters')
        .uniqueInList({
          message: 'Business Group already exists, a unique Id is required before saving',
          list: businessGroups,
          mapper: ({ businessGroup }) => businessGroup,
        })
        .label('Business Groups'),
      description: Yup.string()
        .required()
        .max(100, 'Business Group cannot exceed 100 characters')
        .label('Business Groups description'),
      activeInd: Yup.bool().required().label('Business Groups Active flag'),
    })
    .strict();

const updateBusinessGroupsSchema = ({ businessGroups }) =>
  Yup.object()
    .shape({
      rowsToUpdate: Yup.array()
        .of(createBusinessGroupSchema(businessGroups))
        // eslint-disable-next-line no-undefined
        .notOneOf([undefined, null]),
    })
    .strict();

module.exports = {
  createBusinessGroupSchema,
  updateBusinessGroupsSchema,
};
