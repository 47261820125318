import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import AppkitIcon from '../../../../shared/displayComponents/appkitIcon/appkitIcon.component';

import styles from './entityInformationDetails.module.scss';

const ReturnToEntityInformationDiagnostics = () => {
  return (
    <Link to={Routes.entityInformationDiagnostics.MAIN}>
      <span className={styles.backText}>
        <AppkitIcon icon="left-chevron" size={14} /> TO DIAGNOSTICS
      </span>
    </Link>
  );
};

export default ReturnToEntityInformationDiagnostics;
