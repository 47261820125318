import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

import { httpGetAndParse } from '../../utils/fetchService';
import stringify from '../../utils/stringify';
import {
  FindCheckboxGroupsParams,
  FindCheckboxGroupsResponse,
  FetchDataItemsUsingCheckboxGroupParams,
  FindDataItemsUsingCheckboxGroupResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

interface CheckboxGroupsParams extends FindCheckboxGroupsParams {
  shouldIncludeEverywhereJurisdiction?: boolean;
}

export const useQueryFindCheckboxGroups = ({
  params,
  enabled,
}: {
  params: CheckboxGroupsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindCheckboxGroupsResponse>([QueryKeys.CheckboxGroups.Data, params], {
    url: `/api/shared/checkbox-groups/all-checkbox-groups/${params.taxYear}/${
      params.jurisdictionId
    }?${stringify({
      shouldIncludeEverywhereJurisdiction: params.shouldIncludeEverywhereJurisdiction,
    })}`,
    defaultData: {
      checkboxGroups: [],
      checkboxGroupsNames: [],
    },
    enabled,
    errorMessage: 'Fetching checkbox groups failed',
  });

const fetchDataItemsUsingCheckboxGroupParams = ({ id }: FetchDataItemsUsingCheckboxGroupParams) =>
  httpGetAndParse({
    route: `/api/development/checkbox-groups/data-items-using-checkbox-group/${id}`,
  });

export const useFetchQueryDataItemsUsingCheckboxGroup = () => {
  const queryClient = useQueryClient();
  return useCallback(
    ({ id }: FetchDataItemsUsingCheckboxGroupParams) => {
      return queryClient.fetchQuery<FindDataItemsUsingCheckboxGroupResponse>(
        [QueryKeys.CheckboxGroups.DataItemsUsingCheckboxGroup, id],
        () => fetchDataItemsUsingCheckboxGroupParams({ id }),
        {
          initialData: [],
          cacheTime: 0, // setting cache time to 0 to always get the newest data
        },
      );
    },
    [queryClient],
  );
};
