import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationCreateEntity = () =>
  useCustomMutation({
    handleData: ({ period, entity }) => ({
      url: `/api/admin/maintenance/entities/${entity.taxYear}/${period}`,
      method: HttpMethods.POST,
      body: entity,
    }),
    resetKey: QueryKeys.EntitiesMaintenance.Entities,
    successMessage: 'Entity created successfully',
    errorMessage: 'Creating entity failed',
  });

export const useMutationUpdateEntity = () =>
  useCustomMutation({
    handleData: ({ period, entity }) => ({
      url: `/api/admin/maintenance/entities/${entity.taxYear}/${period}/${entity.orgId}`,
      method: HttpMethods.PUT,
      body: entity,
    }),
    resetKey: QueryKeys.EntitiesMaintenance.Entities,
    successMessage: 'Entity updated successfully',
    errorMessage: 'Updating entity failed',
  });
