import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useQueryFindCheckboxGroups } from '../shared/queries/checkboxGroups';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useRowEditMode } from '../shared/editMode';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../shared/columnDefinitions/cellRenderers';
import globalContextPropTypes from '../shared/propTypes/globalContext';
import {
  useQueryMemberInfoDataForms,
  useQueryMemberInfoDataFormFields,
  useQueryMembers,
} from '../shared/queries/memberInformationalData';
import { useMutationUpdateMemberInfoDataFields } from '../shared/mutations/memberInformationalData';
import { autoGroupColumnDef } from '../shared/columnDefinitions/booleanCheckboxBasedAgGrid.autoGroupColumnDefinition';
import { gridGroupOptions } from '../shared/displayComponents/agGrid/constants';
import useUrlParams from '../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import getColumnDefinitions from './memberInformationalData.columnDefinitions';
import styles from './memberInformationalData.module.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const MemberInformationalData = ({ globalContext, hasUserPermissionsToEdit }) => {
  const { queryParams: { formId, member } = null } = useUrlParams();
  const { taxYear, period, businessEntityId, jurisdictionId, filingTypeId } = globalContext.params;

  const [selectedFormId, selectForm] = useState(null);
  const [selectedMember, selectMember] = useState(null);

  const {
    mutateAsync: updateFormFields,
    isLoading: isUpdatingFormFields,
  } = useMutationUpdateMemberInfoDataFields();

  const {
    data: checkboxGroupsResponse,
    isFetching: isFetchingCheckboxGroups,
  } = useQueryFindCheckboxGroups({
    params: { taxYear, jurisdictionId, shouldIncludeEverywhereJurisdiction: true },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const { data: forms, isFetching: isFetchingForms } = useQueryMemberInfoDataForms({
    params: { globalContext },
    enabled: globalContext.isReady,
  });

  const { data: members, isFetching: isFetchingMembers } = useQueryMembers({
    params: { period, businessEntityId, formId: selectedFormId },
    enabled: Boolean(period && businessEntityId && selectedFormId),
  });

  const { data: formFields, isFetching: isFetchingFormFields } = useQueryMemberInfoDataFormFields({
    params: {
      period,
      businessEntityId,
      formId: selectedFormId,
      memberId: selectedMember,
    },
    enabled: Boolean(period && selectedMember && selectedFormId && businessEntityId),
  });

  useEffect(() => {
    if (forms?.length) {
      selectForm(formId || forms[0].value);
    }
  }, [formId, forms]);

  useEffect(() => {
    if (members?.length) {
      selectMember(member || members[0].value);
    }
  }, [member, members]);

  const saveChanges = useCallback(
    ({ rowsPairsWithChanges }) => {
      if (!rowsPairsWithChanges.length) {
        return;
      }

      const rows = rowsPairsWithChanges.map(({ newRow }) => newRow);
      updateFormFields({ taxYear, period, businessEntityId, jurisdictionId, filingTypeId, rows });
    },
    [updateFormFields, taxYear, period, jurisdictionId, businessEntityId, filingTypeId],
  );

  const isLoading =
    isFetchingCheckboxGroups ||
    isFetchingForms ||
    isFetchingMembers ||
    isFetchingFormFields ||
    isUpdatingFormFields;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateGroup,
    updateRow,
    gridApi,
    onGridReady,
    clonedRowData,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: formFields,
    getUniqueRowId,
    editButtonDisabled: isLoading || !formFields?.length,
  });

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    if (isLoading) {
      gridApi.showLoadingOverlay();
      return;
    }
    if (forms?.length === 0 || members?.length === 0) {
      gridApi.showNoRowsOverlay();
      return;
    }
    gridApi.hideOverlay();
  }, [isLoading, gridApi, forms, members]);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        rowData: clonedRowData,
        checkboxGroups: checkboxGroupsResponse.checkboxGroups,
        isInEditMode,
        updateGroup,
        updateRow,
        taxYear,
      }),
    [
      isInEditMode,
      updateGroup,
      updateRow,
      checkboxGroupsResponse.checkboxGroups,
      taxYear,
      clonedRowData,
    ],
  );

  const onChangeForm = useCallback(({ value }) => selectForm(value), []);
  const onChangeMember = useCallback(({ value }) => selectMember(value), []);

  return (
    <>
      {navigationPrompt}
      <div className={styles.flexSpaceBetween}>
        <div className={styles.formListDropdown}>
          <SDKCustomSelect
            className="sdk-custom-select"
            appkitLabel="Form Name"
            options={forms}
            onChange={onChangeForm}
            value={selectedFormId}
            isLoading={isFetchingForms}
            disabled={isInEditMode}
          />
        </div>
        <div className={styles.memberListDropdown}>
          <SDKCustomSelect
            className="sdk-custom-select"
            appkitLabel="Member"
            options={members}
            onChange={onChangeMember}
            value={selectedMember}
            isLoading={isFetchingMembers}
            disabled={isInEditMode}
          />
        </div>
        <div>{hasUserPermissionsToEdit && editModeButtons}</div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            autoGroupColumnDef={autoGroupColumnDef}
            isGridLoading={isLoading}
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            overlayNoRowsTemplate={
              '<span>For the selected return type there is no required member informational data.</span>'
            }
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            withSearchBar
            stopEditingWhenCellsLoseFocus
            singleClickEdit
            {...gridGroupOptions}
          />
        </div>
      </div>
    </>
  );
};

MemberInformationalData.propTypes = {
  globalContext: globalContextPropTypes,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default MemberInformationalData;
