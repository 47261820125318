import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import { fetchColumnHeadersTypes } from './types';

export const initialState = {
  columnHeaders: [],
  isFetchingColumnHeaders: false,
};

const estimateSummaryReportReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchColumnHeadersTypes,
      payloadKey: 'columnHeaders',
      fetchingKey: 'isFetchingColumnHeaders',
    }),
  },
});

export default estimateSummaryReportReducer;
