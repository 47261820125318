import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import Modal from '@tls/ui-modal';

import {
  taxYearSelector,
  propagateDataModelEverywhereJurisdictionSelector,
  jurisdictionIdSelector,
} from '../../store/selectors';
import { dataModelSelector } from '../../../shared/store/dataModels/selectors';
import { successNotification, errorNotification } from '../../../shared/notification/store/actions';
import useFetch from '../../../shared/hooks/useFetch.hook';
import { startUserJobPolling, cancelUserJobPolling } from '../../../jobs/store/actions';
import { isJobInProgress } from '../../../jobs/utils';

import styles from './propagateModelModal.module.scss';
import propagateCheckedToChildren from './propagateCheckedToChildren';
import updateCheckboxValues from './updateCheckboxValues';
import getNewCheckedValue from './getNewCheckedValue';
import prepareModalData from './prepareModalData';
import * as api from './api';
import WarningMessage from './warningMessage.component';
import PropagationForm from './propagationForm.component';

const getDestinationJurisdictions = data =>
  Object.values(data).flatMap(({ children }) =>
    Object.values(children)
      .filter(({ checked }) => checked)
      .map(({ id, stateCode }) => ({ id, stateCode })),
  );

const PropagateModelModal = ({ hideModal, visible }) => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const sourceJurisdiction = useSelector(state =>
    propagateDataModelEverywhereJurisdictionSelector(state, true),
  );
  const sourceJurisdictionId = useSelector(jurisdictionIdSelector);
  const dataModel = useSelector(dataModelSelector);
  const jurisdictions = useSelector(propagateDataModelEverywhereJurisdictionSelector);

  const { data: propagationData, fetch: fetchLastDataModelPropagationData } = useFetch({
    action: api.fetchLastDataModelPropagationData,
  });

  const jobId = propagationData?.data?.jobId;
  const jobData = useSelector(({ jobs }) => jobs[jobId] || null);

  const [
    jurisdictionsInWhichDataModelExistsWereRefetched,
    setJurisdictionsInWhichDataModelExistsWereRefetched,
  ] = useState(false);
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false);
  const [data, setData] = useState({});
  const [includeExpressions, setIncludeExpressions] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isThereNoDestinationJurisdictionsSelected = !getDestinationJurisdictions(data).length;
  const destinationJurisdictionsCount = getDestinationJurisdictions(data).length;
  const destinationJurisdictionsStateCodes = getDestinationJurisdictions(data)
    .map(({ stateCode }) => stateCode)
    .join(', ');

  const {
    data: jurisdictionsInWhichDataModelExists,
    fetch: fetchJurisdictionsInWhichDataModelExists,
  } = useFetch({ action: api.fetchJurisdictionsInWhichDataModelExists });

  useEffect(() => {
    if (taxYear && dataModel) {
      fetchJurisdictionsInWhichDataModelExists({ taxYear, dataModelName: dataModel.label });
      fetchLastDataModelPropagationData({ taxYear, dataModelName: dataModel.label });
    }
  }, [
    fetchJurisdictionsInWhichDataModelExists,
    fetchLastDataModelPropagationData,
    taxYear,
    dataModel,
  ]);

  useEffect(() => {
    let jobId;

    if (propagationData?.data) {
      const { jobType, status } = propagationData.data;
      jobId = propagationData.data.jobId;

      if (isJobInProgress(status)) {
        dispatch(
          startUserJobPolling({
            jobId,
            jobType,
            successAction: () => () => null,
            errorMessage: 'There was an error fetching progress',
          }),
        );
      }
    }

    return () => dispatch(cancelUserJobPolling(jobId));
  }, [dispatch, propagationData]);

  useEffect(() => {
    if (jobData) {
      fetchJurisdictionsInWhichDataModelExists({ taxYear, dataModelName: dataModel.label });
      setJurisdictionsInWhichDataModelExistsWereRefetched(true);

      if (!isJobInProgress(jobData.status)) {
        dispatch(cancelUserJobPolling(jobData.jobId));
      }
    }
  }, [dispatch, fetchJurisdictionsInWhichDataModelExists, jobData, taxYear, dataModel.label]);

  useEffect(() => {
    if (
      jurisdictionsInWhichDataModelExists &&
      ((!Object.keys(data).length && jurisdictions.length > 0) || // initialize data after jurisdictions load
        jurisdictionsInWhichDataModelExistsWereRefetched)
    ) {
      setData(
        prepareModalData(jurisdictions, jurisdictionsInWhichDataModelExists, sourceJurisdictionId),
      );
      setJurisdictionsInWhichDataModelExistsWereRefetched(false);
    }
  }, [
    data,
    jurisdictions,
    jurisdictionsInWhichDataModelExists,
    sourceJurisdictionId,
    jurisdictionsInWhichDataModelExistsWereRefetched,
  ]);

  const toggleIncludeExpressions = useCallback(() => {
    setIncludeExpressions(currentValue => !currentValue);
  }, []);

  const handleCheckboxClick = useCallback(
    keys => () => {
      setData(currentData => {
        const dataCopy = { ...currentData };
        const keyPath = keys.join('.');
        const currentItem = get(dataCopy, keyPath);

        currentItem.checked = getNewCheckedValue(currentItem);

        propagateCheckedToChildren(currentItem, currentItem.checked);
        updateCheckboxValues(dataCopy[keys[0]]);

        return dataCopy;
      });
    },
    [],
  );

  const handlePropagateSubmit = useCallback(() => {
    setDisplayWarningMessage(true);
  }, []);

  const handleWarningCancel = useCallback(() => {
    setDisplayWarningMessage(false);
  }, []);

  const handleContinueSubmit = useCallback(async () => {
    setDisplayWarningMessage(false);
    setIsSubmitting(true);
    const destinationJurisdictionIds = getDestinationJurisdictions(data).map(({ id }) => id);
    const payload = {
      taxYear,
      dataModelName: dataModel.label,
      dataModelId: dataModel.value,
      sourceJurisdictionId,
      destinationJurisdictionIds,
      includeExpressions,
    };

    try {
      await api.propagateDataModel(payload);
      await fetchLastDataModelPropagationData({ taxYear, dataModelName: dataModel.label });
      dispatch(
        successNotification('Successfully started propagating Data Model', { closeable: true }),
      );
      setData(
        prepareModalData(jurisdictions, jurisdictionsInWhichDataModelExists, sourceJurisdictionId),
      );
      setIncludeExpressions(false);
    } catch (error) {
      dispatch(errorNotification('Could not start propagating Data Model', { closeable: true }));
    }
    setIsSubmitting(false);
  }, [
    dispatch,
    fetchLastDataModelPropagationData,
    data,
    sourceJurisdictionId,
    taxYear,
    dataModel,
    includeExpressions,
    setData,
    jurisdictions,
    jurisdictionsInWhichDataModelExists,
  ]);

  return (
    <Modal
      zIndex={99999}
      className={styles.modal}
      title={displayWarningMessage ? '' : 'Propagate Data Model'}
      visible={visible}
      dismissAction={hideModal}
      closeAction={hideModal}
    >
      {displayWarningMessage ? (
        <WarningMessage
          taxYear={taxYear}
          dataModelName={dataModel.label}
          sourceJurisdiction={sourceJurisdiction}
          destinationJurisdictionsCount={destinationJurisdictionsCount}
          destinationJurisdictionsStateCodes={destinationJurisdictionsStateCodes}
          handleContinueSubmit={handleContinueSubmit}
          handleWarningCancel={handleWarningCancel}
        />
      ) : (
        <PropagationForm
          taxYear={taxYear}
          sourceJurisdiction={sourceJurisdiction}
          dataModel={dataModel}
          includeExpressions={includeExpressions}
          toggleIncludeExpressions={toggleIncludeExpressions}
          handleCheckboxClick={handleCheckboxClick}
          propagationData={propagationData}
          data={data}
          hideModal={hideModal}
          isSubmitting={isSubmitting}
          handlePropagateSubmit={handlePropagateSubmit}
          isThereNoDestinationJurisdictionsSelected={isThereNoDestinationJurisdictionsSelected}
          jobData={jobData}
        />
      )}
    </Modal>
  );
};

PropagateModelModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default memo(PropagateModelModal);
