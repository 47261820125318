import React from 'react';
import { Button } from '@pwc/appkit-react/lib/Button';
import { Link } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

const AccessDenied = () => (
  <div className="general-info-page page-without-secondary-menu page-content-container">
    <div className="general-info-text">Access Denied</div>
    <div className="general-button-wrapper">
      <Link to={Routes.LOGOUT}>
        <Button size="lg">SIGN OUT</Button>
      </Link>
    </div>
  </div>
);

export default AccessDenied;
