export const YES = 'Y';
export const NO = 'N';

const yesNoOptions = [
  {
    label: 'Yes',
    value: YES,
  },
  {
    label: 'No',
    value: NO,
  },
];

export default yesNoOptions;
