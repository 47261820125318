import React, { useMemo } from 'react';

import StepInformationCard, {
  StepInformationCardType,
} from '../stepInformationCard/stepInformationCard.component';
import Loading from '../../../../shared/displayComponents/loading.component';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { BinderFilingId, SubmissionId, TaxReturnResult } from '../../../../../../common/types';
import { FederalReturnOption } from '../../../../shared/enums/eFileV2';
import LoadingPage from '../../loadingPage/loadingPage.component';
import { StepStatus, TransmissionStatus } from '../../enums';

import ViewStatusTab from './viewStatusStepTab/viewStatusStepTab.component';
import useViewStatusContext from './useViewStatusStepContext.hook';
import styles from './viewStatusStep.module.scss';
import { getColumnDefinitions } from './viewStatusStep.columnDefinitions';

const FILE_NOT_FOUND_TEXT = 'File not found';

interface GetTransmissionAcknowledgementSubtitle {
  transmissionStatus: TransmissionStatus;
  isTransmissionAcknowledgementZipInfoCardDisabled: boolean;
}

const getTransmissionAcknowledgementSubtitle = ({
  transmissionStatus,
  isTransmissionAcknowledgementZipInfoCardDisabled,
}: GetTransmissionAcknowledgementSubtitle) => {
  if (transmissionStatus === TransmissionStatus.PENDING) {
    return 'Waiting for the state to process your return.';
  }

  if (isTransmissionAcknowledgementZipInfoCardDisabled) {
    return FILE_NOT_FOUND_TEXT;
  }

  return '';
};

const getWarningText = (errors: number, warnings: number) => {
  // 1 Error / 2 Errors
  const errorsMessage = `${errors} Error${errors > 1 ? 's' : ''}`;

  // and 1 Warnings / 2 Warnings
  const warningsMessage = warnings > 0 ? ` and ${warnings} Warning${warnings > 1 ? 's' : ''}` : '';

  // was ignored / were ignored
  const ending = `${errors + warnings > 1 ? ' were' : ' was'} ignored`;
  return `${errorsMessage}${warningsMessage}${ending}`;
};

interface ViewStatusStepParams {
  step5Status: StepStatus | null;
  isCheckingTransmissionStatus: boolean;
  stateSubmissionId: SubmissionId | null;
  federalSubmissionId: SubmissionId | null;
  federalReturnOption: FederalReturnOption | null;
  setTransmissionStatus: (status: TransmissionStatus) => void;
  isReadyToCheckTransmissionStatus: boolean;
  isCheckTransmissionStatusNotRunPreviously: boolean;
  checkTransmissionStatus: () => void;
  binderFilingId?: BinderFilingId;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
  taxReturn: TaxReturnResult | undefined;
  selectedTransmissionHistoryId: string;
  setSelectedTransmissionHistoryId: (id: string) => void;
}

const ViewStatusStep = ({
  step5Status,
  isCheckingTransmissionStatus,
  federalSubmissionId,
  federalReturnOption,
  stateSubmissionId,
  setTransmissionStatus,
  isReadyToCheckTransmissionStatus,
  isCheckTransmissionStatusNotRunPreviously,
  checkTransmissionStatus,
  binderFilingId,
  isFetchingTaxReturnOrTransmitterProviderTR,
  taxReturn,
  selectedTransmissionHistoryId,
  setSelectedTransmissionHistoryId,
}: ViewStatusStepParams) => {
  const {
    isLoading,
    submissionsList,
    selectedTransmissionStatusData,
    federalSubmissions,
    stateSubmissions,
    isSeparate,
    businessEntityId,
    errors,
    warnings,
    transmissionZipLink,
    transmissionAcknowledgementLink,
    isFetchingSubmissions,
    isFetchingTransmissionStatus,
    isFetchingSubmissionFiles,
    trRejectionErrors,
  } = useViewStatusContext({
    step5Status,
    isCheckingTransmissionStatus,
    federalSubmissionId,
    federalReturnOption,
    setTransmissionStatus,
    isReadyToCheckTransmissionStatus,
    isCheckTransmissionStatusNotRunPreviously,
    checkTransmissionStatus,
    binderFilingId,
    ttiSubmissionId: stateSubmissionId,
    isFetchingTaxReturnOrTransmitterProviderTR,
    taxReturn,
    selectedTransmissionHistoryId,
    setSelectedTransmissionHistoryId,
  });
  const columnDefinitions = useMemo(() => {
    return getColumnDefinitions();
  }, [isLoading]);

  if (
    isFetchingSubmissions ||
    isFetchingTransmissionStatus ||
    isFetchingSubmissionFiles ||
    isCheckingTransmissionStatus ||
    step5Status === StepStatus.INITIATED ||
    !step5Status
  ) {
    return (
      <LoadingPage
        header="Retrieving E-File Status"
        details={['Return to this screen to check the progress.']}
      />
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }

  const isTransmissionZipInfoCardDisabled = !selectedTransmissionStatusData
    ?.transmissionZipAvailability.isTransmissionZipAvailable;
  const isTransmissionAcknowledgementZipInfoCardDisabled =
    selectedTransmissionStatusData?.status === TransmissionStatus.PENDING ||
    !selectedTransmissionStatusData?.transmissionZipAvailability
      ?.isTransmissionAcknowledgementZipAvailable;

  return (
    <ViewStatusTab
      selectedTransmissionStatusData={selectedTransmissionStatusData}
      submissions={submissionsList}
      step5Status={step5Status}
      selectedTransmissionHistoryId={selectedTransmissionHistoryId}
      setSelectedTransmissionHistoryId={setSelectedTransmissionHistoryId}
    >
      {selectedTransmissionStatusData && (
        <div className={styles.transmitStepConteiner}>
          <div className={styles.transmitStepContent}>
            {
              /* do not show if there are only warnings (src: invision comment) */
              errors > 0 && (
                <StepInformationCard
                  title={getWarningText(errors, warnings)}
                  type={StepInformationCardType.WARNING}
                />
              )
            }
            <StepInformationCard
              title={isSeparate ? 'Entity ID' : 'Consolidation ID'}
              subtitle={businessEntityId}
            />
            <StepInformationCard
              title="State Submission ID"
              subtitle={selectedTransmissionStatusData.stateSubmissionId}
              fileLinks={stateSubmissions}
            />
            <StepInformationCard
              title="Federal Submission ID"
              subtitle={federalSubmissionId || 'Not included'}
              // this probably also needs to be retrieved for selected submission only (currently uses data from wizard context)
              fileLinks={federalSubmissions}
              disabled={!federalSubmissionId}
            />
            {transmissionZipLink && (
              <StepInformationCard
                title="E-File Transmission ZIP"
                fileLinks={[transmissionZipLink]}
                isDownloadable
                disabled={isTransmissionZipInfoCardDisabled}
                subtitle={isTransmissionZipInfoCardDisabled ? FILE_NOT_FOUND_TEXT : ''}
              />
            )}
            {transmissionAcknowledgementLink && (
              <StepInformationCard
                title="E-File Transmission Acknowledgement"
                subtitle={getTransmissionAcknowledgementSubtitle({
                  transmissionStatus: selectedTransmissionStatusData.status,
                  isTransmissionAcknowledgementZipInfoCardDisabled,
                })}
                fileLinks={[transmissionAcknowledgementLink]}
                disabled={isTransmissionAcknowledgementZipInfoCardDisabled}
                isDownloadable
              />
            )}
          </div>
          {trRejectionErrors.length > 0 && (
            <div className={styles.transmitStepContent}>
              <StepInformationCard
                title={'Rejection Messages'}
                type={StepInformationCardType.WARNING}
              />
              <div className={styles.trRejectionMessagesTable}>
                <AgGrid
                  rowData={trRejectionErrors}
                  columnDefs={columnDefinitions}
                  rowHeight={50}
                  rowSelection="single"
                  enableBrowserTooltips
                />
              </div>
            </div>
          )}
        </div>
      )}
    </ViewStatusTab>
  );
};

export default ViewStatusStep;
