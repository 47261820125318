import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FormView from '../../../../shared/displayComponents/formView/formView.component';
import {
  pdfMappedFieldSelector,
  pdfFieldsByPageSelector,
  isFetchingPDFFieldsByPageSelector,
} from '../../../../shared/store/dataModels/selectors';
import {
  formHtmlSelector,
  isFetchingFormsSelector,
  isFetchingFormHtmlSelector,
  formPageSelector,
  isSelectedWithDropdownSelector,
} from '../../../store/selectors';
import { datasetsOptionSelector } from '../../store/selectors';
import { selectFormPageWithScroll } from '../../../store/actions';
import generateExpression from '../generateExpression';
import getFormFieldNameWithMultCondition from '../../../../shared/forms/getFormFieldNameWithMultCondition';

import styles from './styles.module.scss';

const FIELDS_VALUE_KEY = 'name';
const FIELDS_FILTER_KEY = 'pdfFieldMappingId';

const CalculationFormView = ({
  handleDataItemChange,
  addDataItemExpression,
  addDataItemConsolExpression,
  selectedFieldName = null,
  isUsingSameExpression = false,
}) => {
  const formHTML = useSelector(formHtmlSelector);
  const isFetchingForms = useSelector(isFetchingFormsSelector);
  const isFetchingFormHtml = useSelector(isFetchingFormHtmlSelector);
  const pdfFieldsByPage = useSelector(pdfFieldsByPageSelector);
  const isFetchingPDFFieldsByPage = useSelector(isFetchingPDFFieldsByPageSelector);
  const selectedFormField = useSelector(state => pdfMappedFieldSelector(state, selectedFieldName));
  const selectedPage = useSelector(formPageSelector);
  const isSelectedWithDropdown = useSelector(isSelectedWithDropdownSelector);
  const expressionCalculationDataset = useSelector(datasetsOptionSelector);

  const handleDataItemNameSelection = useCallback(
    fieldName => {
      const dataItemId = pdfFieldsByPage.find(
        ({ name }) => name === getFormFieldNameWithMultCondition(fieldName),
      )?.dataItemId;
      if (dataItemId) {
        handleDataItemChange(dataItemId, fieldName);
      }
    },
    [handleDataItemChange, pdfFieldsByPage],
  );

  const handleDoubleClickSelection = useCallback(
    fieldName => {
      const field = pdfFieldsByPage.find(
        ({ name }) => name === getFormFieldNameWithMultCondition(fieldName),
      );
      if (field) {
        addDataItemExpression(
          generateExpression({
            dataset: { id: field.datasetId, name: field.datasetName },
            expressionCalculationDataset,
            dataItemName: field.dataItemDefinitionName,
          }),
        );
      }
    },
    [addDataItemExpression, expressionCalculationDataset, pdfFieldsByPage],
  );

  const handleCommandClick = useCallback(
    fieldName => {
      const field = pdfFieldsByPage.find(
        ({ name }) => name === getFormFieldNameWithMultCondition(fieldName),
      );
      if (field) {
        addDataItemConsolExpression(
          generateExpression({
            dataset: { id: field.datasetId, name: field.datasetName },
            expressionCalculationDataset,
            dataItemName: field.dataItemDefinitionName,
          }),
        );
      }
    },
    [addDataItemConsolExpression, expressionCalculationDataset, pdfFieldsByPage],
  );

  return (
    <FormView
      className={styles.formWrapper}
      formHTML={formHTML}
      isLoading={isFetchingForms || isFetchingFormHtml}
      selectedPage={selectedPage}
      isSelectedWithDropdown={isSelectedWithDropdown}
      selectFormPageWithScroll={selectFormPageWithScroll}
      pdfFieldsByPage={pdfFieldsByPage}
      fieldsValues={pdfFieldsByPage}
      isFetchingFieldsValues={isFetchingPDFFieldsByPage}
      selectedField={selectedFormField}
      selectedFormFieldName={selectedFieldName}
      fieldsValueKey={FIELDS_VALUE_KEY}
      fieldsFilterKey={FIELDS_FILTER_KEY}
      onFormFieldSelection={handleDataItemNameSelection}
      onDoubleClickSelection={handleDoubleClickSelection}
      handleCommandClick={handleCommandClick}
      isUsingSameExpression={isUsingSameExpression}
      isCommandOrControlUsedForSelecting
      isShiftKeyUsedForSelecting
      isUsingCustomContextMenu
    />
  );
};

CalculationFormView.propTypes = {
  selectedFieldName: PropTypes.string,
  handleDataItemChange: PropTypes.func.isRequired,
  addDataItemExpression: PropTypes.func.isRequired,
  addDataItemConsolExpression: PropTypes.func.isRequired,
  isUsingSameExpression: PropTypes.bool,
};

export default CalculationFormView;
