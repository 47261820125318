import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchFederalReconciliationsTypes,
  createFederalReconciliationsNoteTypes,
  FEDERAL_RECONCILIATIONS_SELECT_ACCOUNT_GROUP,
} from './types';

export const fetchFederalReconciliations = apiAction({
  types: fetchFederalReconciliationsTypes,
  apiAction: api.fetchFederalReconciliations,
});

export const createFederalReconciliationsNote = apiAction(
  {
    types: createFederalReconciliationsNoteTypes,
    apiAction: api.createFederalReconciliationsNote,
  },
  {
    successNotificationMessage: () => 'Note has been successfully created',
    errorNotificationMessage: () => 'Creating note has failed',
  },
);

export const selectAccountGroup = accountGroup => ({
  type: FEDERAL_RECONCILIATIONS_SELECT_ACCOUNT_GROUP,
  payload: accountGroup,
});
