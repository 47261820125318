import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';

import {
  taxYearSelector,
  periodSelector,
  isClientTtiAvailableSelector,
} from '../../shared/store/selectors';
import downloadFile from '../../utils/downloadFile';

import styles from './efileTransmissionStatus.module.scss';

const EFileTransmissionStatus = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const isClientTtiAvailable = useSelector(isClientTtiAvailableSelector);

  const onClickDownload = useCallback(
    () => downloadFile({ route: `/api/efile/transmission-status-report/${taxYear}/${period}` }),
    [period, taxYear],
  );

  return (
    <div className="row">
      <div className={`col ${styles.downloadButtonWrapper}`}>
        <Button
          disabled={!isClientTtiAvailable}
          size="lg"
          className="add-button"
          onClick={onClickDownload}
        >
          Download e-File Transmission Status Report
        </Button>
      </div>
    </div>
  );
};

export default EFileTransmissionStatus;
