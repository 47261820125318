import React, { useCallback } from 'react';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { individualSchemas } from '@common-packages/validators';

import { periodSelector, taxYearSelector } from '../../shared/store/selectors';
import Loading from '../../shared/displayComponents/loading.component';
import { fetchEntities as fetchGlobalContextEntities } from '../../shared/store/actions';
import { useQueryStaticDataForForm } from '../../shared/queries/entitiesMaintenance';
import { useMutationPostIndividual } from '../../shared/mutations/individualsMaintenance';
import { IndividualEntity } from '../../../../common/types/apiShapes';

import IndividualForm from './individualForm.component';

interface IndividualFormProps {
  entityToEdit: IndividualEntity | null;
  entities: Array<IndividualEntity> | undefined;
  isFetchingEntities: boolean;
}

const IndividualFormContainer = ({
  entityToEdit = null,
  entities = [],
  isFetchingEntities,
}: IndividualFormProps) => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const isContextReady = taxYear && period;
  const isEditMode = Boolean(entityToEdit);

  const {
    mutateAsync: postIndividual,
    isLoading: isSavingIndividual,
  } = useMutationPostIndividual();

  const { data: formData, isFetching: isFetchingFormData } = useQueryStaticDataForForm({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });

  const formInitialValues = {
    taxYear,
    orgId: '',
    legalEntityId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    partnerStateOfResidency: '',
    membership: '',
    ssn: '',
    spouseFirstName: '',
    spouseMiddleName: '',
    spouseLastName: '',
    spouseSuffix: '',
    spouseSsn: '',
  };

  const save = useCallback(
    async (entity: IndividualEntity, formikHelpers: FormikHelpers<IndividualEntity>) => {
      formikHelpers.setSubmitting(true);

      await postIndividual({
        taxYear,
        individualId: entity.orgId,
        values: entity,
      });

      formikHelpers.resetForm();
      formikHelpers.setSubmitting(false);
      dispatch(fetchGlobalContextEntities(taxYear, period));
    },
    [dispatch, postIndividual, taxYear, period],
  );

  const isLoading =
    !isContextReady || isFetchingEntities || isFetchingFormData || isSavingIndividual;

  const renderForm = useCallback(
    (formikProps: FormikProps<IndividualEntity>) => (
      <Loading isLoading={isLoading}>
        <IndividualForm
          {...formikProps}
          isEditMode={isEditMode}
          jurisdictionOptions={formData?.jurisdictionOptions}
          businessDescriptionOptions={formData?.businessDescriptionOptions}
          entities={entities}
        />
      </Loading>
    ),
    [isLoading, isEditMode, formData, entities],
  );

  return (
    <Formik
      initialValues={
        entityToEdit
          ? {
              ...formInitialValues,
              ...entityToEdit,
            }
          : formInitialValues
      }
      validationSchema={individualSchemas.getCommon}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={save}
    >
      {renderForm}
    </Formik>
  );
};

export default IndividualFormContainer;
