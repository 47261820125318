import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  isFetchingGlobalContextSelector,
  isGlobalContextReadySelector,
  taxYearOptionsSelector,
  periodOptionsSelector,
  entitiesOptionsSelector,
  separateJurisdictionsOptionsSelector,
  consolidationJurisdictionsOptionsSelector,
  consolidationsOptionsSelector,
} from '../../../shared/store/selectors';
import { filingTypesOptions, filingTypes } from '../../../shared/constants';
import { SelectOptionPropTypes } from '../../../shared/propTypes/selectOption';
import SelectContextDataInfo from '../selectContextDataInfo/selectContextDataInfo.component';
import Loading from '../loading.component';

import styles from './withContextWrapperHoc.module.scss';

const { globalContextFactory } = require('@tls/state-helpers');

const withContextWrapper = ({ isJurisdictionRequired = true } = {}) => WrappedComponent => {
  const ContextWrapper = ({
    isFetchingContext,

    hasUserPermissionsToEdit,

    taxYearOptions,
    periodOptions,
    entitiesOptions,
    jurisdictionsOptions,
    consolidationJurisdictionOption,
    consolidationsOptions,

    ...props
  }) => {
    const {
      taxYear,
      period,
      filingTypeId,
      jurisdictionId,
      customScreenName,
      taxName,
      businessEntityId,
    } = useParams();

    const globalContext = useMemo(
      () =>
        globalContextFactory({
          taxYear,
          period,
          filingTypeId,
          businessEntityId,
          jurisdictionId,
        }),
      [taxYear, period, filingTypeId, businessEntityId, jurisdictionId],
    );

    const isValidOption = (options, value) =>
      options && options.some(option => option.value === value);
    const isTaxYearValid = isValidOption(taxYearOptions, taxYear);
    const isPeriodValid = isValidOption(periodOptions, period);
    const isFilingTypeValid = isValidOption(filingTypesOptions, filingTypeId);
    const isBusinessEntityIdValid = isValidOption(
      filingTypeId === filingTypes.SEPARATE ? entitiesOptions : consolidationsOptions,
      businessEntityId,
    );

    const isJurisdictionValid =
      !isJurisdictionRequired ||
      (filingTypeId === filingTypes.SEPARATE &&
        isValidOption(jurisdictionsOptions, jurisdictionId)) ||
      (filingTypeId === filingTypes.CONSOLIDATED &&
        isValidOption(consolidationJurisdictionOption, jurisdictionId));

    const areUrlParamsValid =
      isTaxYearValid &&
      isPeriodValid &&
      isFilingTypeValid &&
      isBusinessEntityIdValid &&
      isJurisdictionValid;

    if (isFetchingContext) {
      return (
        <div className={styles.loaderContainer}>
          <Loading isLoading />
        </div>
      );
    }

    if (!areUrlParamsValid) {
      return <SelectContextDataInfo />;
    }

    return (
      <WrappedComponent
        hasUserPermissionsToEdit={hasUserPermissionsToEdit}
        taxName={taxName}
        customScreenName={customScreenName}
        globalContext={globalContext}
        {...props}
      />
    );
  };

  const selectOptionArrayPropTypes = PropTypes.arrayOf(SelectOptionPropTypes);

  ContextWrapper.propTypes = {
    isFetchingContext: PropTypes.bool,

    hasUserPermissionsToEdit: PropTypes.bool.isRequired,

    taxYearOptions: selectOptionArrayPropTypes.isRequired,
    periodOptions: selectOptionArrayPropTypes.isRequired,
    entitiesOptions: selectOptionArrayPropTypes.isRequired,
    jurisdictionsOptions: selectOptionArrayPropTypes.isRequired,
    consolidationJurisdictionOption: selectOptionArrayPropTypes,
    consolidationsOptions: selectOptionArrayPropTypes.isRequired,
  };

  return connect(state => ({
    isFetchingContext: isFetchingGlobalContextSelector(state),
    isContextReady: isGlobalContextReadySelector(state),

    taxYearOptions: taxYearOptionsSelector(state),
    periodOptions: periodOptionsSelector(state),
    entitiesOptions: entitiesOptionsSelector(state),
    jurisdictionsOptions: separateJurisdictionsOptionsSelector(state),
    consolidationJurisdictionOption: consolidationJurisdictionsOptionsSelector(state),
    consolidationsOptions: consolidationsOptionsSelector(state),
  }))(ContextWrapper);
};

export default withContextWrapper;
