export enum StepStatus {
  INITIATED = 'INITIATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  TR_REJECTED = 'TR_REJECTED',
}

export enum WizardStepName {
  STEP_1_CHOOSE_FEDERAL_RETURN = 'step1FederalReturn',
  STEP_2_VALIDATE = 'step2Validations',
  STEP_3_REVIEW = 'step3Review',
  STEP_4_AUTHORIZE_AND_TRANSMIT = 'step4Authorize',
  STEP_5_VIEW_STATUS = 'step5Transmit',
}

export enum TransmissionStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  'DENIED BY IRS' = 'Denied by IRS',
}
