import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';
import { filingTypeIdSelector } from '../shared/store/selectors';
import { filingTypes } from '../shared/constants';

const Maintenance = ({ filingTypeId }) => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Users"
      icon="community"
      description="Users maintenance screen"
      route={Routes.usersMaintenance.MAIN}
    />
    <AuthorizedCard
      title="Roles"
      icon="table-data"
      description="Roles screen"
      route={Routes.roles.MAIN}
    />
    <AuthorizedCard
      title="Permissions"
      icon="table-data"
      description="Permissions screen"
      route={Routes.permissions.MAIN}
    />
    <AuthorizedCard
      title="Accounts"
      icon="table-data"
      description="Accounts screen"
      route={Routes.accounts.MAIN}
    />
    <AuthorizedCard
      title="Entities"
      icon="table-data"
      description="Entities screen"
      route={Routes.entitiesMaintenance.MAIN}
    />
    <AuthorizedCard
      title={`Tax Rates and Constants - ${
        filingTypeId === filingTypes.SEPARATE ? 'Single Entity' : 'Consolidated'
      }`}
      icon="table-data"
      description={`Tax Rates and Constants - ${
        filingTypeId === filingTypes.SEPARATE ? 'Single Entity' : 'Consolidated'
      }`}
      route={Routes.taxRatesAndConstantsMaintenance.MAIN}
    />
    <AuthorizedCard
      title="Lock Return"
      icon="location-locked"
      description="Lock Return"
      route={Routes.lockReturn.MAIN}
    />
    <AuthorizedCard
      title="Business Groups"
      icon="table-data"
      description="Business Group Management"
      route={Routes.businessGroup.MAIN}
    />
    <AuthorizedCard
      title="Businesses"
      icon="table-data"
      description="Businesses"
      route={Routes.businesses.MAIN}
    />
    <AuthorizedCard
      title="Consolidations"
      icon="table-data"
      description="Consolidations"
      route={Routes.adminConsolidations.MAIN}
    />
  </div>
);

Maintenance.propTypes = {
  filingTypeId: PropTypes.string,
};

export default connect(state => ({
  filingTypeId: filingTypeIdSelector(state),
}))(Maintenance);
