import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const globalInformationSelector = ({ entity }) => entity.entityInformation.globalInformation;

export const isFetchingGlobalInformationSelector = ({ entity }) =>
  entity.entityInformation.isFetchingGlobalInformation;

export const isUpdatingGlobalInformationSelector = ({ entity }) =>
  entity.entityInformation.isUpdatingGlobalInformation;

export const partneshipsSelector = ({ entity }) => entity.entityInformation.partnerships;

export const partnershipsOptionsSelector = optionsSelectorFactory({
  listSelector: partneshipsSelector,
  labelKey: 'partnershipId',
  valueKey: 'partnershipId',
});

export const partnershipIdSelector = ({ entity }) => entity.entityInformation.partnershipId;

export const isFetchingPartnershipsSelector = ({ entity }) =>
  entity.entityInformation.isFetchingPartnerships;
