import { httpPost, httpPostAndParse } from '../../../utils/fetchService';

const baseURL = '/api/tools';

export const fetchValidatedInformationalData = ({ taxYear, period, rows }) =>
  httpPostAndParse({
    route: `${baseURL}/informational-data/validate`,
    errorMessage: 'Validating informational data failed',
    body: {
      taxYear,
      period,
      rows,
    },
  });

export const uploadInformationalData = ({
  taxYear,
  period,
  jurisdictionId,
  businessEntityId,
  filingTypeId,
  rows,
}) =>
  httpPost({
    route: `${baseURL}/informational-data/upload`,
    errorMessage: 'Uploading informational data failed',
    body: {
      taxYear,
      period,
      jurisdictionId,
      businessEntityId,
      filingTypeId,
      rows,
    },
  });
