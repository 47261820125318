import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import { Select as MultiSelect, SelectGroupTitle, SelectOption } from '@pwc/appkit-react';

import Loading from '../../../shared/displayComponents/loading.component';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import { SelectOptionPropTypes } from '../../../shared/forms/propTypes';
import dependentParamsAreSet from '../../../shared/reports/utils/dependentParamsAreSet';
import { fetchParamOptions } from '../store/actions';
import { paramFieldOptionsSelector, isFetchingParamFieldSelector } from '../store/selectors';

class DropdownField extends PureComponent {
  static propTypes = {
    param: PropTypes.shape({
      name: PropTypes.string.isRequired,
      mappingName: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
      isMultiSelectDropdown: PropTypes.bool,
    }).isRequired,
    value: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    editIcon: PropTypes.node.isRequired,
    getParamFieldName: PropTypes.func.isRequired,
    fetchParamOptions: PropTypes.func.isRequired,
    paramFieldOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
    isFetchingParamField: PropTypes.bool.isRequired,
    dependentParams: PropTypes.object, // eslint-disable-line
  };

  componentDidMount() {
    this.fetchParamOptions();
  }

  componentDidUpdate(prevProps) {
    const { param, dependentParams } = this.props;

    if (prevProps.param !== param || !isEqual(dependentParams, prevProps.dependentParams)) {
      this.fetchParamOptions();
    }
  }

  fetchParamOptions() {
    const { fetchParamOptions, param, dependentParams } = this.props;
    const dependentParamsReady = dependentParamsAreSet(dependentParams);

    if (dependentParamsReady) {
      fetchParamOptions({
        paramName: param.mappingName,
        query: param.query,
        params: dependentParams,
      });
    }
  }

  onMultiSelect = value => {
    const { param, getParamFieldName, handleChange } = this.props;
    const paramFieldName = getParamFieldName(param.name);
    handleChange(paramFieldName, value);
  };

  render() {
    const {
      param,
      dependentParams,
      value,
      handleChange,
      editIcon,
      getParamFieldName,
      paramFieldOptions,
      isFetchingParamField,
    } = this.props;

    const paramFieldName = getParamFieldName(param.name);
    const label = `${param.mappingName} (${[param.name]})`;
    const dependentParamsReady = dependentParamsAreSet(dependentParams);

    return (
      <Loading small isLoading={isFetchingParamField}>
        {param.isMultiSelectDropdown ? (
          <>
            <label className="a-form-label">
              {label}
              {editIcon}
            </label>
            <MultiSelect
              placeholder="Select..."
              name={paramFieldName}
              multiple
              showSelectAll
              onSelect={this.onMultiSelect}
              disabled={!dependentParamsReady}
              showSearchOnList
            >
              <SelectGroupTitle>{label}</SelectGroupTitle>
              {(paramFieldOptions || []).map(option => (
                <SelectOption key={option.value} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </MultiSelect>
          </>
        ) : (
          <div>
            <Select
              appkitLabel={
                <>
                  {label}
                  {editIcon}
                </>
              }
              name={paramFieldName}
              options={paramFieldOptions}
              value={value}
              onSelect={handleChange}
              disabled={!dependentParamsReady}
            />
          </div>
        )}
      </Loading>
    );
  }
}

export default connect(
  (state, { param }) => ({
    paramFieldOptions: paramFieldOptionsSelector(state, { param }),
    isFetchingParamField: isFetchingParamFieldSelector(state, { param }),
  }),
  {
    fetchParamOptions,
  },
)(DropdownField);
