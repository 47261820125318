import capitalize from './capitalize';

const getFieldValue = ({ currentValueFieldName, payloadFieldName, state, payload }) => {
  const previousValueFieldName = `previous${capitalize(currentValueFieldName)}`;
  const previouslySelectedValueCanBeFoundOnNewList = payload.some(
    row => row[payloadFieldName] === state[previousValueFieldName],
  );

  if (previouslySelectedValueCanBeFoundOnNewList) {
    return state[previousValueFieldName];
  }

  return payload[0] ? payload[0][payloadFieldName] : null;
};

export default getFieldValue;
