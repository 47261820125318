import { adminColumnDefinition, defaultColumnDefinition } from '../../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onEditIconClick, onDeleteIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({ onEditIconClick, onDeleteIconClick });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Report ID',
      field: 'reportId',
      width: 60,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Name',
      field: 'name',
      width: 400,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
