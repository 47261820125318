import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@pwc/appkit-react';
import Spinner from '@tls/ui-spinner';

import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { isFetchingPdfImageSelector } from '../../shared/store/pdfAttachments/selectors';
import { fetchBinderAttachment } from '../../shared/store/pdfAttachments/actions';

import styles from './tree.module.scss';

const AttachmentPreview = ({
  id,
  name,
  showAttachmentModal,

  fetchBinderAttachment,
  isFetchingPdfImage,
}) => {
  const linkDisabled = isFetchingPdfImage;
  const previewAttachment = useCallback(
    event => {
      event.preventDefault();

      if (linkDisabled) {
        return;
      }

      fetchBinderAttachment({ id });
      showAttachmentModal({
        link: `/api/shared/binder/pdf-attachments/${id}`,
        name,
      });
    },
    [fetchBinderAttachment, linkDisabled, showAttachmentModal, id, name],
  );

  return (
    <ListItem className={styles.listItem}>
      {isFetchingPdfImage ? (
        <i className={styles.listIcon}>
          <Spinner small />
        </i>
      ) : (
        <AppkitIcon
          className={styles.listIcon}
          icon="right-chevron-circle"
          size={14}
          onClick={null}
        />
      )}
      <a
        href="/"
        className={styles.listItemNavLink}
        disabled={linkDisabled}
        onClick={previewAttachment}
      >
        <ListItemText primary={name} />
      </a>
    </ListItem>
  );
};

AttachmentPreview.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showAttachmentModal: PropTypes.func.isRequired,

  fetchBinderAttachment: PropTypes.func.isRequired,
  isFetchingPdfImage: PropTypes.bool.isRequired,
};

const ConnectedAttachmentPreview = connect(
  state => ({
    isFetchingPdfImage: isFetchingPdfImageSelector(state),
  }),
  {
    fetchBinderAttachment,
  },
)(AttachmentPreview);

export default ConnectedAttachmentPreview;
