import React from 'react';

import AppkitIcon from '../shared/displayComponents/appkitIcon/appkitIcon.component';

import './comingSoonPage.style.scss';

const ComingSoonPage = () => (
  <div className="coming-soon-page">
    <div className="coming-soon-container">
      <AppkitIcon className="time-icon" icon="time" size={40} />
      <p className="coming-soon-paragraph">Coming soon ...</p>
    </div>
  </div>
);

export default ComingSoonPage;
