import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';

import FederalProforma from './federalProforma.container';

const FederalProformaWithContext = withContextWrapper()(FederalProforma);

const FederalProformaPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(GlobalContextPage);

export const SeparateFederalProformaRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.separateFederalProforma.MAIN}
    wrapper={FederalProformaPage}
    component={FederalProformaWithContext}
    hideGlobalContext
    disableBreadcrumbs
  />
);

export const FederalProformaRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.federalProforma.MAIN}
    wrapper={FederalProformaPage}
    component={FederalProformaWithContext}
    additionalProps={{
      sourceId: 'TTI',
    }}
    hideGlobalContext
    disableBreadcrumbs
  />
);

export const FederalAsFiledRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.federalAsFiled.MAIN}
    wrapper={FederalProformaPage}
    component={FederalProformaWithContext}
    additionalProps={{
      sourceId: 'GTW',
    }}
    hideGlobalContext
    disableBreadcrumbs
  />
);
