import { SelectOption } from '@tls/slt-types';

import { DatasetMappingParams, FindDatasetMappingsResponse } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export interface TaxYearJurisdictionParams {
  taxYear: string;
  jurisdictionId: string;
}

export const useQuerySchemaIdOptions = ({
  enabled,
  params: { taxYear, jurisdictionId },
  onSuccess,
}: {
  enabled: boolean;
  params: TaxYearJurisdictionParams;
  onSuccess: (schemaIdOptions: SelectOption[]) => void;
}) =>
  useCustomQuery<SelectOption[]>(
    [QueryKeys.EFileMapping.SchemaIdOptions, { taxYear, jurisdictionId }],
    {
      url: `/api/development/efile/schema-id-options/${taxYear}/${jurisdictionId}`,
      enabled,
      defaultData: [],
      errorMessage: 'Fetching eFile schema id options failed',
      onSuccess,
    },
  );

export const useQuerySltSchemaIdOptions = ({
  enabled,
  params: { taxYear, jurisdictionId },
}: {
  enabled: boolean;
  params: TaxYearJurisdictionParams;
}) =>
  useCustomQuery<SelectOption[]>(
    [QueryKeys.EFileMapping.SchemaIdOptions, { taxYear, jurisdictionId }],
    {
      url: `/api/development/efile/slt-schema-id-options/${taxYear}/${jurisdictionId}`,
      enabled,
      initialData: [],
      errorMessage: 'Fetching eFile schema id options failed',
    },
  );

export const useQueryDatasetMappings = ({
  enabled,
  params: { taxYear, jurisdictionId, sltSchemaId },
}: {
  enabled: boolean;
  params: DatasetMappingParams;
}) =>
  useCustomQuery<FindDatasetMappingsResponse>(
    [QueryKeys.EFileMapping.DatasetMappings, { sltSchemaId }],
    {
      url: `/api/development/efile/dataset-mappings/${taxYear}/${jurisdictionId}/${sltSchemaId}`,
      enabled,
      defaultData: {
        maxDepth: 1,
        datasetMappings: [],
        branchOptions: [],
      },
      errorMessage: 'Fetching eFile dataset mappings failed',
    },
  );
