import { connect } from 'react-redux';

import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import { filingGroupIdSelector } from '../store';

const FilingGroupIdValue = connect(state => ({
  label: 'Filing Group',
  value: filingGroupIdSelector(state),
  isBusy: !filingGroupIdSelector(state),
}))(GlobalContextValue);

export default FilingGroupIdValue;
