import { adminColumnDefinition, defaultColumnDefinition } from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  IconCellRendererFactory,
  RedirectCellRendererHoc,
} from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onDeleteIconClick, onEditIconClick, hasUserPermissionsToEdit }) => {
  const NotesIconRenderer = RedirectCellRendererHoc({
    getRedirect: (value, data) => (data.url ? data.url : null),
    WrappedComponent: IconCellRendererFactory({
      getName: (value, data) => (data.url ? 'news' : 'plus'),
      isSmall: (value, data) => !data.url,
      clickHandler: null, // TODO: add an action to attaching a document
    }),
  });

  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    onEditIconClick,
    shouldShowEditIcon: hasUserPermissionsToEdit,
    shouldShowDeleteIcon: hasUserPermissionsToEdit,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
      width: 150,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdiction',
      width: 100,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Task',
      field: 'taskId',
      width: 100,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Prepared by',
      field: 'preparedByWithDate',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Responded By',
      field: 'respondedByWithDate',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Status',
      field: 'statusDescription',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Doc',
      field: 'doc',
      width: 35,
      cellRenderer: NotesIconRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
