import { SourceId } from '../../../../shared/enums/gtwFileUpload';

interface FileSourceMatchVerifier {
  fileName: string | null;
  sourceOption: SourceId;
}

const fileSourceMatchVerifier = ({ fileName, sourceOption }: FileSourceMatchVerifier) =>
  fileName?.includes(SourceId.GTW) || fileName?.includes(SourceId.TTI)
    ? fileName?.includes(sourceOption === SourceId.GTW ? SourceId.GTW : SourceId.TTI)
    : true;

export default fileSourceMatchVerifier;
