import React from 'react';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';
import { DOMAIN_TYPE } from '@common-packages/shared-constants';

import config from '../../config';
import useAuthQueryString from '../../shared/hooks/useAuthQueryString.hook';

import { fileTypes, EFILE_ACTION } from './constants';

const stepsWithDownload = [
  {
    action: EFILE_ACTION.EF_SERVICE_VALIDATE_SUBMISSION,
    linkDescription: 'Submission XML',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'xml',
    hidden: () => false,
    disabled: () => false,
  },
  {
    action: EFILE_ACTION.EF_SERVICE_GENERATE_ZIP,
    linkDescription: 'Submission ZIP',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'zip',
    hidden: ({ ttiResponse }) => ttiResponse?.validation?.result?.result?.skipped,
    disabled: ({ status }) => status !== 'COMPLETED',
  },
  {
    action: EFILE_ACTION.GET_STATE_TRANSMISSION_SCHEMA_VALIDATION,
    linkDescription: 'Schema Validation',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'xml',
    hidden: () => false,
    disabled: () => false,
  },
  {
    action: EFILE_ACTION.VERIFY_AND_UPDATE_MAPPING_FILE,
    linkDescription: 'XML Mapping',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'xml',
    hidden: () => false,
    disabled: () => false,
  },
  {
    action: EFILE_ACTION.DOWNLOAD_EFILE_XML,
    linkDescription: 'E-File XML',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'zip',
    hidden: () => false,
    disabled: ({ status }) => status !== 'COMPLETED',
  },
  {
    action: EFILE_ACTION.DOWNLOAD_AND_REVIEW_TRANSMISSION_FILE_ERRORS,
    linkDescription: 'Transmission Validations',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'zip',
    hidden: () => false,
    disabled: ({ status }) => status !== 'COMPLETED',
  },
  {
    action: EFILE_ACTION.DOWNLOAD_TRANSMISSION_FILE,
    linkDescription: 'E-File Transmission Zip',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'zip',
    hidden: () => false,
    disabled: ({ status }) => status !== 'COMPLETED',
  },
  {
    action: EFILE_ACTION.DOWNLOAD_TRANSMISSION_ACK,
    linkDescription: 'E-File Transmission Acknowledgement',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'xml',
    hidden: () => false,
    disabled: ({ status }) => status !== 'COMPLETED',
  },
];

const DownloadStepFile = ({
  ttiCaseId,
  binderId,
  data,
  ttiYearId = null,
  efServiceSubmissionId = null,
}) => {
  const authQueryString = useAuthQueryString();
  if (!data) {
    return null;
  }

  const { stepName } = data;
  const stepWithDownload = stepsWithDownload.find(({ action }) => action === stepName);
  const isContextReady = ttiYearId && ttiCaseId && binderId && efServiceSubmissionId && stepName;

  if (!stepWithDownload || !isContextReady || stepWithDownload.hidden(data)) {
    return null;
  }

  return (
    <a
      href={`${config.BASE_PATH}${Routes.eFileDownloadStepFile.compiledRoute({
        ttiYearId: String(ttiYearId),
        ttiCaseId: String(ttiCaseId),
        domainType: DOMAIN_TYPE,
        binderId: String(binderId),
        submissionId: String(efServiceSubmissionId),
        stepName,
        fileType: stepWithDownload.fileType,
        fileExtension: stepWithDownload.fileExtension,
      })}?${authQueryString}`}
      disabled={stepWithDownload.disabled(data)}
    >
      {stepWithDownload.linkDescription}
    </a>
  );
};

DownloadStepFile.propTypes = {
  ttiYearId: PropTypes.string,
  ttiCaseId: PropTypes.string,
  binderId: PropTypes.number,
  efServiceSubmissionId: PropTypes.string,
  data: PropTypes.shape({
    stepName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
};

export default DownloadStepFile;
