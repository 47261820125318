const countCheckedAndIndeterminateChildren = (children = {}) =>
  Object.values(children).reduce(
    (acc, { checked, indeterminate }) => ({
      checkedCount: checked ? acc.checkedCount + 1 : acc.checkedCount,
      indeterminateCount: indeterminate ? acc.indeterminateCount + 1 : acc.indeterminateCount,
    }),
    {
      checkedCount: 0,
      indeterminateCount: 0,
    },
  );

const updateCheckboxValues = (data = {}) => {
  const childrenCount = Object.keys(data.children || {}).length;
  if (!childrenCount) {
    return;
  }

  Object.values(data.children).forEach(value => {
    updateCheckboxValues(value);
  });

  const { checkedCount, indeterminateCount } = countCheckedAndIndeterminateChildren(data.children);

  if (checkedCount === childrenCount) {
    data.checked = true;
    data.indeterminate = false;
  } else if ((checkedCount < childrenCount && checkedCount > 0) || indeterminateCount > 0) {
    data.checked = false;
    data.indeterminate = true;
  } else {
    data.checked = false;
    data.indeterminate = false;
  }

  const checkboxEl = document.getElementById(`propagate-checkbox-${data.id}`);
  if (checkboxEl) {
    checkboxEl.indeterminate = data.indeterminate;
  }
};

export default updateCheckboxValues;
