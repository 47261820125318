export const TABS_TYPES = {
  INCOME_TAX_SUMMARY: 'INCOME_TAX_SUMMARY',
  INCOME_TAX_SUMMARY_PY: 'INCOME_TAX_SUMMARY_PY',
  INCOME_BASE: 'INCOME_BASE',
  INCOME_BASE_PY: 'INCOME_BASE_PY',
  INCOME_APPT: 'INCOME_APPT',
  INCOME_APPT_PY: 'INCOME_APPT_PY',
  FRANCHISE_TAX_SUMMARY: 'FRANCHISE_TAX_SUMMARY',
  FRANCHISE_TAX_SUMMARY_PY: 'FRANCHISE_TAX_SUMMARY_PY',
  FRANCHISE_BASE: 'FRANCHISE_BASE',
  FRANCHISE_BASE_PY: 'FRANCHISE_BASE_PY',
  FRANCHISE_APPT: 'FRANCHISE_APPT',
  FRANCHISE_APPT_PY: 'FRANCHISE_APPT_PY',
};

export const tabsDefinitions = [
  { type: TABS_TYPES.INCOME_TAX_SUMMARY, label: 'Income Tax Summary' },
  { type: TABS_TYPES.INCOME_TAX_SUMMARY_PY, label: 'Income Tax Summary with PY' },
  { type: TABS_TYPES.INCOME_BASE, label: 'Income Base' },
  { type: TABS_TYPES.INCOME_BASE_PY, label: 'Income Base with PY' },
  { type: TABS_TYPES.INCOME_APPT, label: 'Income Appt' },
  { type: TABS_TYPES.INCOME_APPT_PY, label: 'Income Appt with PY' },
  { type: TABS_TYPES.FRANCHISE_TAX_SUMMARY, label: 'Franchise Tax Summary' },
  { type: TABS_TYPES.FRANCHISE_TAX_SUMMARY_PY, label: 'Franchise Tax Summary with PY' },
  { type: TABS_TYPES.FRANCHISE_BASE, label: 'Franchise Base' },
  { type: TABS_TYPES.FRANCHISE_BASE_PY, label: 'Franchise Base with PY' },
  { type: TABS_TYPES.FRANCHISE_APPT, label: 'Franchise Appt' },
  { type: TABS_TYPES.FRANCHISE_APPT_PY, label: 'Franchise Appt with PY' },
];
