import React from 'react';
import { useSelector } from 'react-redux';

import DevelopmentTaxYearDropdown from '../../developmentTaxYearDropdown.container';
import { taxYearSelector } from '../../store/selectors';
import { useQueryCompilationInfo } from '../../../shared/queries/expressionBuilder';

import CompileExpressionsReportGrid from './compileExpressionsReportGrid/compileExpressionsReportGrid.component';

const CompileExpressionsReport = () => {
  const taxYear = useSelector(taxYearSelector);

  const {
    data: compileExpressionReport,
    isFetching: isFetchingCompileExpressionReport,
  } = useQueryCompilationInfo({ enabled: Boolean(taxYear), params: { taxYear } });

  return (
    <>
      <div className="row">
        <div className="col-3">
          <DevelopmentTaxYearDropdown />
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <CompileExpressionsReportGrid
            data={compileExpressionReport}
            isLoading={isFetchingCompileExpressionReport}
          />
        </div>
      </div>
    </>
  );
};

export default CompileExpressionsReport;
