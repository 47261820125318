import React, { useCallback } from 'react';
import camelCase from 'lodash.camelcase';
import { Field } from 'formik';
import { AttributesGroups } from '@common-packages/shared-constants';

import Checkbox from '../shared/forms/checkboxFormik/checkboxFormik.component';
import { FilingAttributeGroup, FilingAttributeChild } from '../../../common/types/apiShapes';

import styles from './developmentFilingAttributes.module.scss';

const FILING_TYPE_DISPLAY_ORDER = [
  'FILING_TYPE_SEPARATE',
  'Consolidated Types',
  'FILING_TYPE_CONS_PRE_APP',
  'FILING_TYPE_CONS_POST_APP',
  'FILING_TYPE_CONS_UNITARY',
];

interface FilingAttributesProps {
  filingAttributes: FilingAttributeGroup[];
  disabledFilingAttributes?: string[];
  filingAttributeGroupClassNames?: Record<string, string>;
  handleChange?: (fieldName: string, newValue: boolean) => unknown;
}

const DevelopmentFilingAttributes = ({
  filingAttributes,
  disabledFilingAttributes,
  filingAttributeGroupClassNames = {},
  handleChange = () => null,
}: FilingAttributesProps) => {
  const renderCheckbox = useCallback(
    (item: FilingAttributeChild) => (
      <Field
        key={item.field}
        label={item.displayName}
        name={item.field}
        disabled={disabledFilingAttributes?.includes(item.field)}
        component={Checkbox}
        customChangeHandler={handleChange}
      />
    ),
    [handleChange, disabledFilingAttributes],
  );

  return (
    <>
      {filingAttributes.map(filingAttribute => (
        <div
          key={filingAttribute.groupName}
          className={filingAttributeGroupClassNames[camelCase(filingAttribute.groupName)]}
        >
          <label className="a-form-label">{filingAttribute.displayName}</label>
          <div className={styles.filingAttributesGroup}>
            {filingAttribute.displayName === AttributesGroups.FILING_TYPE
              ? FILING_TYPE_DISPLAY_ORDER.map(item => {
                  const filingItem = filingAttribute.children.find(
                    attribute => attribute.field === item,
                  );
                  if (filingItem) {
                    return renderCheckbox(filingItem);
                  }
                  return (
                    <span key={item} className={styles.separator}>
                      {item}
                    </span>
                  );
                })
              : filingAttribute.children.map(item => renderCheckbox(item))}
          </div>
        </div>
      ))}
    </>
  );
};

export default DevelopmentFilingAttributes;
