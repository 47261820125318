const DATA_TYPE_FIELD = 'dataType';

const dataTypes = {
  CHECKBOX: 'CHECKBOX',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  AMOUNT: 'AMOUNT',
  NA: null,
};

const taxReturnStatus = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  COMPLETE: 3,
};

const needFiscalYearEndErrorMessage =
  'You cannot select a due date because the entity needs a fiscal year end day in the Entity Information screen.';

const needDueDateFromFilingFormErrorMessage =
  'The values required to calculate the Due Dates for the selected filing form are missing. Create a support ticket to report this issue.';

const taxYearEndingRequiredErrorMessage = 'Tax Year Ending is a required field';
const dueDateRequiredErrorMessage = 'Due Date is a required field';

const returnStatusRequiredErrorMessage = 'Return Status is a required field';

module.exports = {
  DATA_TYPE_FIELD,
  dataTypes,
  needFiscalYearEndErrorMessage,
  needDueDateFromFilingFormErrorMessage,
  taxYearEndingRequiredErrorMessage,
  dueDateRequiredErrorMessage,
  taxReturnStatus,
  returnStatusRequiredErrorMessage,
};
