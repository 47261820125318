import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../shared/hooks/useFetch.hook';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';

import { fetchCaseListApiAction } from './api';

const CaseListDropdown = ({ value = null, onSelect = () => null, setIsFetching = () => null }) => {
  const { data: caseList, isFetching: isFetchingCaseList, fetch: fetchCaseList } = useFetch({
    action: fetchCaseListApiAction,
  });

  useEffect(() => {
    fetchCaseList();
  }, [fetchCaseList]);

  useEffect(() => setIsFetching(isFetchingCaseList), [setIsFetching, isFetchingCaseList]);

  return (
    <ParamDropdown
      label="Case ID"
      options={caseList}
      value={value}
      handleChange={onSelect}
      isBusy={isFetchingCaseList}
    />
  );
};

CaseListDropdown.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func,
  setIsFetching: PropTypes.func,
};

export default CaseListDropdown;
