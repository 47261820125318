import { httpGetAndParse, httpPut } from '../../utils/fetchService';

export const fetchFilingGroups = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/federal-pro-forma-filing-groups/${taxYear}/${period}`,
    errorMessage: 'Fetching federal pro forma filing groups failed',
  });

export const updateFilingGroup = ({ taxYear, period, filingGroupId, description }) =>
  httpPut({
    route: `/api/federal-pro-forma-filing-groups/${taxYear}/${period}/${filingGroupId}`,
    errorMessage: 'Updating federal pro forma filing group failed',
    body: {
      description,
    },
  });

export const createFilingGroup = ({ taxYear, period, filingGroupId, description }) =>
  httpPut({
    route: `/api/federal-pro-forma-filing-groups/${taxYear}/${period}/${filingGroupId}`,
    errorMessage: 'Creating federal pro forma filing group failed',
    body: {
      description,
    },
  });
