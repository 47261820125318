import {
  LinkCellRendererFactory,
  EditableCheckboxCellRenderer,
  NumberCellEditor,
  AdminCellRendererFactory,
  TextCellEditor,
} from '../columnDefinitions/cellRenderers';
import { adminColumnDefinition, defaultColumnDefinition } from '../columnDefinitions';

import { attachmentsBusinessRules } from './pdfAttachmentsBusinessRules';

const getColumnDefinitions = ({
  isInEditMode,
  updateRow,
  onDeleteIconClick,
  onDocumentNameClick,
  path,
}) => {
  const onCellValueChanged = ({ data, api, colDef, oldValue }) => {
    attachmentsBusinessRules(data, { colDef, oldValue });

    api.applyTransaction({ update: [data] });
    api.refreshCells({ force: true });

    updateRow(data);
  };
  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: isInEditMode,
  });

  const docNameLinkRenderer = LinkCellRendererFactory({
    getText: ({ value }) => value,
    getPathName: () => path,
    clickHandler: (value, data) => onDocumentNameClick(data),
  });

  const checkboxCellClassRules = {
    ...defaultColumnDefinition.cellClassRules,
    'editable-cell': () => isInEditMode,
  };

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      width: 200,
      headerName: 'Attachment Name',
      field: 'docName',
      cellRenderer: docNameLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      width: 200,
      headerName: 'Attachment Description',
      field: 'docDescription',
      editable: isInEditMode,
      cellEditor: TextCellEditor,
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Print Order',
      field: 'docPrintOrder',
      width: 60,
      cellStyle: {
        textAlign: 'center',
      },
      editable: isInEditMode,
      cellEditor: NumberCellEditor,
      onCellValueChanged,
      sort: 'asc',
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Efile Attach',
      field: 'eFile',
      cellRenderer: EditableCheckboxCellRenderer,
      width: 60,
      onCellValueChanged,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Rollover Attachment Def',
      field: 'defRollover',
      cellRenderer: EditableCheckboxCellRenderer,
      width: 90,
      onCellValueChanged,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Rollover Attachment Doc',
      field: 'docRollover',
      cellRenderer: EditableCheckboxCellRenderer,
      width: 90,
      onCellValueChanged,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
