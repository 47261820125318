const Yup = require('yup');

const percentageSchema = Yup.number().min(0).max(100).required();

const commonSchema = Yup.object().shape({
  partnershipTypeCode: Yup.string().required().label('Partnership Type'),
  partnershipId: Yup.string().required().label('Partnership Id'),
  ownerPercentage: percentageSchema.label('Owner Percentage'),
  percentEffectiveOwnership: percentageSchema.label('Effective Ownership Percentage'),
  percentOwned: percentageSchema.label('Percentage Owned'),
  percentYearOwned: percentageSchema.label('Percent of Year Owned'),
});

const updatePartnershipInformationSchema = Yup.object()
  .shape({
    rowsToUpdate: Yup.array()
      .of(
        commonSchema.concat(
          Yup.object().shape({
            parentId: Yup.string().required(),
          }),
        ),
      )
      .notOneOf([undefined, null]), // eslint-disable-line no-undefined
  })
  .strict();

const createPartnershipInformationSchema = commonSchema
  .concat(
    Yup.object().shape({
      taxYear: Yup.string().required(),
      period: Yup.string().required(),
      ownerId: Yup.string().required(),
      partnershipId: Yup.string().required(),
    }),
  )
  .strict();

module.exports = {
  commonSchema,
  updatePartnershipInformationSchema,
  createPartnershipInformationSchema,
};
