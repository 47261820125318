import keyBy from 'lodash.keyby';
import { OverflowAction } from '@common-packages/shared-constants';

const getNamesWhereAttachmentChanged = (initialOverflowActions = [], newOverflowActions = {}) => {
  const initialOverflowActionsKeyed = keyBy(initialOverflowActions, 'id');

  return Object.entries(newOverflowActions).reduce((acc, [key, { overflowAction, name }]) => {
    // when changing from "Attachment" to something else
    if (
      initialOverflowActionsKeyed[key]?.overflowAction === OverflowAction.ATTACHMENT &&
      overflowAction !== OverflowAction.ATTACHMENT
    ) {
      return [...acc, name];
    }
    return acc;
  }, []);
};

export default getNamesWhereAttachmentChanged;
