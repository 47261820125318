import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import DataInfo from '../displayComponents/dataInfo/dataInfo.component';
import { customerPermissionsPropTypes } from '../propTypes/customerPropTypes';

import withPermissions from './withPermissionsHoc.container';
import hasRequiredPermissionsToView from './hasRequiredPermissionsToView';
import hasRequiredPermissionsToEdit from './hasRequiredPermissionsToEdit';

const AuthorizedCustomRoute = ({
  customerPermissions = [],
  path,

  wrapper: Wrapper,
  component: Component,
  render,

  isProtectedComponent = true,
  additionalDropdowns = null,
  additionalValues = null,
  additionalProps = {},
  disableBreadcrumbs = false,
  hideGlobalContext = false,
  hidePeriodDropdown = false,
  hideJurisdictionDropdown = false,
  accessOnlyWithEditPermission = false,
  className,
  additionalWrapperProps = {},
  ...routeRest
}) => {
  const protectedComponent = useCallback(
    props => {
      const isAccessDenied =
        isProtectedComponent &&
        !hasRequiredPermissionsToView(customerPermissions, path, accessOnlyWithEditPermission);
      const hasUserPermissionsToEdit = hasRequiredPermissionsToEdit(customerPermissions, path);

      if (isAccessDenied && path === Routes.returnMaintenance.MAIN) {
        return <DataInfo textToRender="User does not have permission to view this page" />;
      }

      if (isAccessDenied) {
        return <DataInfo textToRender="Not found" />;
      }

      return Component ? (
        <Component
          {...additionalProps}
          {...props}
          hasUserPermissionsToEdit={hasUserPermissionsToEdit}
        />
      ) : (
        render(props)
      );
    },
    [Component, additionalProps, isProtectedComponent],
  );

  const routeRender = useCallback(
    props => (
      <Wrapper
        additionalDropdowns={additionalDropdowns}
        additionalValues={additionalValues}
        disableBreadcrumbs={disableBreadcrumbs}
        hideJurisdictionDropdown={hideJurisdictionDropdown}
        hideGlobalContext={hideGlobalContext}
        hidePeriodDropdown={hidePeriodDropdown}
        className={className}
        {...additionalWrapperProps}
      >
        {protectedComponent(props)}
      </Wrapper>
    ),
    [
      Wrapper,
      Component,
      additionalDropdowns,
      additionalValues,
      additionalProps,
      disableBreadcrumbs,
      additionalWrapperProps,
    ],
  );

  return <Route path={path} render={routeRender} {...routeRest} />;
};

AuthorizedCustomRoute.propTypes = {
  customerPermissions: customerPermissionsPropTypes,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  wrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isProtectedComponent: PropTypes.bool,
  additionalDropdowns: PropTypes.element,
  additionalValues: PropTypes.element,
  additionalProps: PropTypes.object,
  disableBreadcrumbs: PropTypes.bool,
  className: PropTypes.string,
  hideGlobalContext: PropTypes.bool,
  hidePeriodDropdown: PropTypes.bool,
  hideJurisdictionDropdown: PropTypes.bool,
  accessOnlyWithEditPermission: PropTypes.bool,
  additionalWrapperProps: PropTypes.object,
};

export default withPermissions(AuthorizedCustomRoute);
