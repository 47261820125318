import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { checkboxGroupsSchema } from '@common-packages/validators';

import { taxYearSelector, jurisdictionIdSelector } from '../../store/selectors';
import { CheckboxGroupToEdit } from '../types';
import { CheckboxGroupsNames } from '../../../../../common/types';
import {
  useMutationInsertCheckboxGroup,
  useMutationUpdateCheckboxGroup,
} from '../../../shared/mutations/checkboxGroups';

import AddEditCheckboxGroupForm from './addEditForm/addEditCheckboxGroupForm.component';

const defaultCheckboxGroupValues = {
  taxYear: '',
  jurisdictionId: '',
  id: '',
  name: '',
  description: '',
};

interface AddEditCheckboxGroupProps {
  checkboxGroupToEdit: CheckboxGroupToEdit;
  clearForm: () => void;
  checkboxGroupsNames?: CheckboxGroupsNames;
}

const AddEditCheckboxGroup = ({
  checkboxGroupToEdit,
  clearForm,
  checkboxGroupsNames = [],
}: AddEditCheckboxGroupProps) => {
  const { mutateAsync: insertCheckboxGroup } = useMutationInsertCheckboxGroup();
  const { mutateAsync: updateCheckboxGroup } = useMutationUpdateCheckboxGroup();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const isEditMode = Boolean(checkboxGroupToEdit);

  const validationSchema = checkboxGroupsSchema.getAddEditCheckboxGroupSchema({
    checkboxGroupsNames,
    isEditMode,
    name: checkboxGroupToEdit?.name,
  });

  const saveCheckboxGroup = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (checkboxGroup: CheckboxGroupToEdit, { resetForm }: any) => {
      if (!checkboxGroup) {
        return;
      }
      const { id, name, description } = checkboxGroup;
      if (!isEditMode) {
        await insertCheckboxGroup({
          name,
          description,
          taxYear,
          jurisdictionId,
          checkboxGroupsNames,
          isEditMode,
        });
        resetForm(defaultCheckboxGroupValues);
      } else {
        await updateCheckboxGroup({
          id,
          name,
          description,
          checkboxGroupsNames,
          isEditMode,
        });
      }
      clearForm();
    },
    [
      clearForm,
      insertCheckboxGroup,
      updateCheckboxGroup,
      checkboxGroupsNames,
      isEditMode,
      taxYear,
      jurisdictionId,
    ],
  );

  return (
    <Formik
      initialValues={checkboxGroupToEdit || defaultCheckboxGroupValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={saveCheckboxGroup}
    >
      <AddEditCheckboxGroupForm />
    </Formik>
  );
};

export default AddEditCheckboxGroup;
