import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../shared/editMode';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { entityIdSelector, periodSelector, taxYearSelector } from '../../shared/store/selectors';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import { isFetchingContextSelector } from '../store/selectors';

import {
  directPartnershipInfoSelector,
  indirectPartnershipInfoSelector,
  isFetchingDirectPartnershipInfoSelector,
  isFetchingIndirectPartnershipInfoSelector,
  partnershipInfoTypesSelector,
  isUpdatingPartnershipsInfoSelector,
} from './store/selectors';
import {
  fetchDirectPartnership,
  fetchIndirectPartnership,
  updateOrDeletePartnershipsInfo,
} from './store/actions';
import {
  getDirectOwnershipColumnDefinitions,
  getIndirectOwnershipColumnDefinitions,
} from './partnershipInformation.columnDefinitions';

import './partnershipInformation.style.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const PartnershipInformation = ({
  taxYear,
  period,
  entityId,
  isFetchingContext,

  directPartnershipInfo,
  fetchDirectPartnership,
  isFetchingDirectPartnershipInfo,

  indirectPartnershipInfo,
  fetchIndirectPartnership,
  isFetchingIndirectPartnershipInfo,

  partnershipInfoTypes,

  updateOrDeletePartnershipsInfo,
  isUpdatingPartnershipsInfo,

  history,
  showConfirmModal,

  hasUserPermissionsToEdit,
}) => {
  const redirectToAddPartnership = useCallback(
    () => history.push(Routes.addPartnershipInformation.MAIN),
    [history],
  );

  const reloadGrids = useCallback(() => {
    const isContextReady = taxYear && period && entityId;

    if (isContextReady) {
      fetchDirectPartnership({ taxYear, period, entityId });
      fetchIndirectPartnership({ taxYear, period, entityId });
    }
  }, [fetchDirectPartnership, fetchIndirectPartnership, taxYear, period, entityId]);

  useEffect(() => {
    reloadGrids();
  }, [reloadGrids]);

  const editPartnershipInfo = useCallback(
    async ({ rowsPairsWithChanges, rowsToDelete }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        parentId: oldRow.parentId,
        partnershipId: oldRow.partnershipId,
        partnershipTypeCode: newRow.partnershipTypeCode,
        ownerPercentage: parseFloat(newRow.ownerPercentage),
        percentOwned: parseFloat(newRow.percentOwned),
        percentYearOwned: parseFloat(newRow.percentYearOwned),
        percentEffectiveOwnership: parseFloat(newRow.percentEffectiveOwnership),
      }));

      await updateOrDeletePartnershipsInfo({
        taxYear,
        period,
        ownerId: entityId,
        rowsToUpdate,
        rowsToDelete,
      });
      reloadGrids();
    },
    [updateOrDeletePartnershipsInfo, reloadGrids, taxYear, period, entityId],
  );

  const isLoadingDirect =
    isFetchingContext || isFetchingDirectPartnershipInfo || isUpdatingPartnershipsInfo;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    deleteRow,
    onGridReady,
  } = useRowEditMode({
    onSave: editPartnershipInfo,
    rowData: directPartnershipInfo,
    getUniqueRowId,
    editButtonDisabled: isLoadingDirect || !directPartnershipInfo.length,
  });

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete Partnership',
        text: 'Are you sure you want to delete partnership?',
        confirmCallback: () => deleteRow(row),
      });
    },
    [showConfirmModal, deleteRow],
  );

  const directOwnershipColumnDefinitions = useMemo(
    () =>
      getDirectOwnershipColumnDefinitions({
        isInEditMode,
        updateRow,
        partnershipInfoTypes,
        onDeleteIconClick: openModalForDelete,
      }),
    [isInEditMode, updateRow, partnershipInfoTypes, openModalForDelete],
  );

  const indirectOwnershipColumnDefinitions = useMemo(
    () =>
      getIndirectOwnershipColumnDefinitions({
        partnershipInfoTypes,
      }),
    [partnershipInfoTypes],
  );

  return (
    <>
      {navigationPrompt}
      {hasUserPermissionsToEdit && (
        <div className="row">
          <div className="col add-button-column">
            {editModeButtons}
            <Button size="lg" onClick={redirectToAddPartnership} className="add-button">
              Add Partnership
            </Button>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">Direct Ownership</div>
      </div>
      <div className="row grid-row grid-row-direct">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={directOwnershipColumnDefinitions}
            isGridLoading={isFetchingDirectPartnershipInfo}
            onGridReady={onGridReady}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            suppressCellFocus={!isInEditMode}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Indirect Ownership (computed)</div>
      </div>
      <div className="row grid-row grid-row-indirect">
        <div className="col">
          <AgGrid
            rowData={indirectPartnershipInfo}
            columnDefs={indirectOwnershipColumnDefinitions}
            isGridLoading={isFetchingIndirectPartnershipInfo}
          />
        </div>
      </div>
    </>
  );
};

const partnershipRowPropTypesShape = {
  ownerId: PropTypes.string.isRequired,
  partnershipName: PropTypes.string.isRequired,
  partnershipId: PropTypes.string.isRequired,
  partnershipTypeCode: PropTypes.string.isRequired,
  ownerPercentage: PropTypes.number,
  percentOwned: PropTypes.number,
  percentYearOwned: PropTypes.number,
  percentEffectiveOwnership: PropTypes.number,
};

const directPartnershipInfoPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    ...partnershipRowPropTypesShape,
    parentId: PropTypes.string.isRequired,
  }).isRequired,
);

const indirectPartnershipInfoPropTypes = PropTypes.arrayOf(
  PropTypes.shape(partnershipRowPropTypesShape).isRequired,
);

PartnershipInformation.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  isFetchingContext: PropTypes.bool.isRequired,

  directPartnershipInfo: directPartnershipInfoPropTypes,
  fetchDirectPartnership: PropTypes.func.isRequired,
  isFetchingDirectPartnershipInfo: PropTypes.bool.isRequired,

  indirectPartnershipInfo: indirectPartnershipInfoPropTypes,
  fetchIndirectPartnership: PropTypes.func.isRequired,
  isFetchingIndirectPartnershipInfo: PropTypes.bool.isRequired,

  partnershipInfoTypes: PropTypes.arrayOf(SelectOptionPropTypes),
  updateOrDeletePartnershipsInfo: PropTypes.func.isRequired,
  isUpdatingPartnershipsInfo: PropTypes.bool.isRequired,

  showConfirmModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    isFetchingContext: isFetchingContextSelector(state),
    directPartnershipInfo: directPartnershipInfoSelector(state),
    isFetchingDirectPartnershipInfo: isFetchingDirectPartnershipInfoSelector(state),
    indirectPartnershipInfo: indirectPartnershipInfoSelector(state),
    isFetchingIndirectPartnershipInfo: isFetchingIndirectPartnershipInfoSelector(state),
    partnershipInfoTypes: partnershipInfoTypesSelector(state),
    isUpdatingPartnershipsInfo: isUpdatingPartnershipsInfoSelector(state),
  }),
  {
    fetchDirectPartnership,
    fetchIndirectPartnership,
    showConfirmModal,
    updateOrDeletePartnershipsInfo,
  },
)(PartnershipInformation);
