import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationUpdateFormFields = () =>
  useCustomMutation({
    handleData: ({ rows, taxYear, period, businessEntityId, jurisdictionId }) => ({
      url: '/api/entities/k1-information-v2/',
      method: HttpMethods.PUT,
      body: { rows, taxYear, period, businessEntityId, jurisdictionId },
    }),
    resetKey: QueryKeys.K1InformationV2.FormFields,
    successMessage: 'Updated k1 information form fields successfully',
    errorMessage: 'Updating k1 information form fields failed',
  });
