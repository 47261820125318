import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { StepTab } from '../stepTab';
import { globalContextSelector } from '../../../../shared/store/selectors';
import {
  getValidationErrorsInitialData,
  useQueryGetReturnValidationResults,
  useQueryGetSignatureDeclaration,
} from '../../../../shared/queries/eFileV2';
import Loading from '../../../../shared/displayComponents/loading.component';
import { FederalReturnAttachments } from '../../../../shared/enums';
import {
  EFileServiceSubmissionId,
  GetValidationErrorsResponse,
  TtiBinderId,
  TtiCaseId,
  TtiYearId,
  SubmissionId,
  GlobalContext,
} from '../../../../../../common/types';
import { FederalReturnOption } from '../../../../shared/enums/eFileV2';
import { useBinder, useGetTtiValidationErrors } from '../../hooks';
import sortValidationErrorMessages from '../step2Validations/sortValidationErrorMessages';
import StepTabRightHeader from '../stepTabRightHeader/stepTabRightHeader.component';
import { StepStatus } from '../../enums';
import LoadingPage from '../../loadingPage/loadingPage.component';

import WarningsAndErrors from './warningsAndErrors/warningsAndErrors.component';
import SignatureDeclaration from './signatureDeclaration/signatureDeclaration.component';
import isSignatureValid from './signatureValidation';

interface AuthorizeStepProps {
  isReadyForAuthorization: boolean;

  ttiYearId?: TtiYearId | null;
  ttiCaseId?: TtiCaseId | null;
  ttiBinderId?: TtiBinderId | null;
  efServiceSubmissionId?: EFileServiceSubmissionId | null;

  setIsCurrentStepReady: React.Dispatch<React.SetStateAction<boolean>>;

  setSignature: React.Dispatch<React.SetStateAction<string>>;
  signature: string;

  setAcceptedLegalVerbiage: React.Dispatch<React.SetStateAction<boolean>>;
  isLegalVerbiageAccepted: boolean;

  setShouldIgnoreErrors: React.Dispatch<React.SetStateAction<boolean>>;
  shouldIgnoreErrors: boolean;
  authorizationStatus: StepStatus | null;

  federalReturnAttachment: FederalReturnAttachments | null;
  federalSubmissionId: SubmissionId | null;
  federalReturnOption: FederalReturnOption | null;

  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
}

const AuthorizeStep = ({
  ttiYearId,
  ttiCaseId,
  ttiBinderId,
  efServiceSubmissionId,

  setSignature,
  signature,

  setShouldIgnoreErrors,
  shouldIgnoreErrors,

  setAcceptedLegalVerbiage,
  isLegalVerbiageAccepted,

  setIsCurrentStepReady,
  isReadyForAuthorization,
  authorizationStatus,

  federalReturnAttachment,
  federalSubmissionId,
  federalReturnOption,
  isFetchingTaxReturnOrTransmitterProviderTR,
}: AuthorizeStepProps) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const isBinderReady = Boolean(ttiYearId && ttiCaseId && ttiBinderId && efServiceSubmissionId);

  const [validationErrors, setValidationErrors] = useState<GetValidationErrorsResponse>(
    getValidationErrorsInitialData,
  );

  const toggleShouldIgnoreErrors = useCallback(() => {
    setShouldIgnoreErrors(currentValue => !currentValue);
  }, [setShouldIgnoreErrors]);

  const toggleAcceptedLegalVerbiage = useCallback(() => {
    setAcceptedLegalVerbiage(currentValue => !currentValue);
  }, [setAcceptedLegalVerbiage]);

  const { sltBinderId } = useBinder();

  const {
    data: signatureDeclaration,
    isFetching: isFetchingSignatureDeclaration,
  } = useQueryGetSignatureDeclaration({
    params: {
      yearOrSubPeriodId: ttiYearId,
      caseId: ttiCaseId,
      binderId: ttiBinderId,
      efSubmissionId: efServiceSubmissionId,
      returnId: sltBinderId.toString(),
    },
    enabled:
      (globalContext.taxYear &&
        globalContext.jurisdictionId &&
        isFetchingTaxReturnOrTransmitterProviderTR) ||
      isBinderReady,
  });

  const { ttiValidationErrors, isFetchingTtiValidation } = useGetTtiValidationErrors(
    !isFetchingTaxReturnOrTransmitterProviderTR,
    !isFetchingTaxReturnOrTransmitterProviderTR,
  );

  const {
    data: eFileSchemavalidationErrors,
    isFetching: isFetchingValidationErrors,
  } = useQueryGetReturnValidationResults({
    params: { sltBinderId },
    enabled: globalContext.isReady && Boolean(sltBinderId),
  });

  useEffect(() => {
    setValidationErrors({
      errors: (eFileSchemavalidationErrors?.errors || 0) + (ttiValidationErrors?.errors || 0),
      warnings: (eFileSchemavalidationErrors?.warnings || 0) + (ttiValidationErrors?.warnings || 0),
      lastRun: eFileSchemavalidationErrors?.lastRun || ttiValidationErrors?.lastRun || '',
      validationRowData: sortValidationErrorMessages([
        ...(eFileSchemavalidationErrors?.validationRowData || []),
        ...(eFileSchemavalidationErrors?.validationRowData || []),
      ]),
    });
  }, [eFileSchemavalidationErrors, ttiValidationErrors]);

  useEffect(() => {
    const areErrorsIgnoredIfThereAreAny = validationErrors?.errors ? shouldIgnoreErrors : true;
    setIsCurrentStepReady(
      Boolean(
        signature &&
          isSignatureValid(signature) &&
          isLegalVerbiageAccepted &&
          areErrorsIgnoredIfThereAreAny &&
          isReadyForAuthorization,
      ),
    );
  }, [
    setIsCurrentStepReady,
    isLegalVerbiageAccepted,
    shouldIgnoreErrors,
    validationErrors?.errors,
    signature,
    isReadyForAuthorization,
  ]);

  const areThereAnyErrorsOrWarnings = Boolean(
    validationErrors?.warnings || validationErrors?.errors,
  );
  const isSignatureDeclarationGeneratedSuccessfully = Boolean(
    !isFetchingSignatureDeclaration && signatureDeclaration,
  );

  if (authorizationStatus === StepStatus.INITIATED) {
    return (
      <LoadingPage
        header="Transmitting tax return"
        details={['Return to this screen to check the progress.']}
      />
    );
  }

  const authorizationMessage =
    authorizationStatus == StepStatus.TR_REJECTED
      ? 'Act now and open a ticket with the SLT support team to ensure prompt assistance in resolving the failed eFile return transmission. Taking immediate action is vital to minimize any potential disruptions in your filing process.'
      : 'Authorize and transmit process failed';

  return (
    <StepTab
      title="Authorize"
      subtitle="Acknowledge any alerts and authorize before transmitting the E-File"
      rightHeaderContent={
        <StepTabRightHeader
          stepStatus={authorizationStatus}
          stepFailedMessage={authorizationMessage}
        />
      }
    >
      <Loading
        isLoading={
          isFetchingValidationErrors ||
          ((isFetchingTtiValidation || !isBinderReady) &&
            !isFetchingTaxReturnOrTransmitterProviderTR)
        }
      >
        {areThereAnyErrorsOrWarnings && (
          <WarningsAndErrors
            warningsAndErrors={validationErrors}
            ignoreErrors={shouldIgnoreErrors}
            toggleIgnoreErrors={toggleShouldIgnoreErrors}
            isSignatureDeclarationGeneratedSuccessfully={
              isSignatureDeclarationGeneratedSuccessfully
            }
            federalReturnOption={federalReturnOption}
            federalReturnAttachment={federalReturnAttachment}
            federalSubmissionId={federalSubmissionId}
            isFetchingTaxReturnOrTransmitterProviderTR={isFetchingTaxReturnOrTransmitterProviderTR}
          />
        )}
        <SignatureDeclaration
          acceptedLegalVerbiage={isLegalVerbiageAccepted}
          toggleAcceptedLegalVerbiage={toggleAcceptedLegalVerbiage}
          signatureValue={signature}
          onSignatureChange={setSignature}
          isGeneratingSignatureDeclaration={isFetchingSignatureDeclaration}
          signatureDeclaration={signatureDeclaration?.declaration}
          isSignatureDeclarationGeneratedSuccessfully={isSignatureDeclarationGeneratedSuccessfully}
        />
      </Loading>
    </StepTab>
  );
};

export default AuthorizeStep;
