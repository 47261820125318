const getDataItemsOptionsBasedOnAssignedDataset = ({
  dataItems,
  datasetId,
  datasetsAssignedToFormPage,
}) => {
  const isDataSetAssigned = datasetsAssignedToFormPage?.some(({ value }) => value === datasetId);
  if (isDataSetAssigned && dataItems[datasetId]) {
    return dataItems[datasetId].map(({ id, name, dataType, dataTypeName, aliasName }) => ({
      value: id,
      label: name,
      dataType,
      dataTypeName,
      aliasName,
    }));
  }

  return [];
};

export default getDataItemsOptionsBasedOnAssignedDataset;
