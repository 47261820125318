import { httpGetAndParse, httpPost } from '../../../utils/fetchService';

export const fetchJurisdictionsInWhichDataModelExists = ({ taxYear, dataModelName }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/data-model/jurisdictions/${taxYear}/${dataModelName}`,
    errorMessage: 'Fetching jurisdictions in which Data Model exists failed',
  });

export const fetchLastDataModelPropagationData = ({ taxYear, dataModelName }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/data-model/propagation-data/${taxYear}/${dataModelName}`,
    errorMessage: 'Fetching last Data Model propagation data failed',
  });

export const propagateDataModel = body =>
  httpPost({
    route: '/api/shared/data-models/propagate',
    body,
  });
