import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  taxYearSelector,
  periodSelector,
  consolidationIdSelector,
  jurisdictionIdSelector,
} from '../../../shared/store/selectors';
import { fetchFilingGroups } from '../store/actions';
import { createDataProvider } from '../../../shared/dataProviders';

const FilingGroupsDataProvider = ({
  children,

  taxYear,
  period,
  consolidationId,
  jurisdictionId,
  fetchFilingGroups,
}) => {
  useEffect(() => {
    const isContextReady = taxYear && period && consolidationId;

    if (!isContextReady) {
      return;
    }

    fetchFilingGroups({
      taxYear,
      period,
      consolidationId,
      jurisdictionId,
    });
  }, [fetchFilingGroups, taxYear, period, consolidationId, jurisdictionId]);

  return children;
};

FilingGroupsDataProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

  taxYear: PropTypes.string,
  period: PropTypes.string,
  consolidationId: PropTypes.string,
  fetchFilingGroups: PropTypes.func.isRequired,
};

export default createDataProvider({
  ProviderComponent: connect(
    state => ({
      taxYear: taxYearSelector(state),
      period: periodSelector(state),
      consolidationId: consolidationIdSelector(state),
      jurisdictionId: jurisdictionIdSelector(state),
    }),
    {
      fetchFilingGroups,
    },
  )(FilingGroupsDataProvider),
});
