import { httpGetAndParse } from '../../../utils/fetchService';

export const fetchManualAdjustmentReportData = ({
  taxYear,
  period,
  jurisdictionId,
  consolidationId,
}) =>
  httpGetAndParse({
    route: `/api/consolidations/manual-adjustment-report/${taxYear}/${period}/${jurisdictionId}/${consolidationId}`,
    errorMessage: 'Fetching manual adjustment report data failed',
  });
