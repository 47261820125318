import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { fetchAccountAdjustment } from '../../../../shared/store/allReturnData/actions';
import {
  accountAdjustmentSelector,
  isFetchingAccountAdjustmentSelector,
} from '../../../../shared/store/allReturnData/selectors';
import { taxYearSelector, periodSelector } from '../../../../shared/store/selectors';
import SelectContextDataInfo from '../../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';

import columnDefinitions from './accountAdjustment.columnDefinitions';

const AccountAdjustment = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  fetchAccountAdjustment,
  accountAdjustment,
  isFetchingAccountAdjustment,
  isFetchingContext,
}) => {
  const isContextReady = taxYear && period && orgId && jurisdictionId;
  const isLoading = isFetchingContext || isFetchingAccountAdjustment;

  useEffect(() => {
    if (isContextReady) {
      fetchAccountAdjustment({ taxYear, period, orgId, jurisdictionId });
    }
  }, [fetchAccountAdjustment, isContextReady, taxYear, period, orgId, jurisdictionId]);

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <AgGrid
      rowData={accountAdjustment}
      columnDefs={columnDefinitions}
      isGridLoading={isLoading}
      withSearchBar
    />
  );
};

AccountAdjustment.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingAccountAdjustment: PropTypes.bool.isRequired,
  fetchAccountAdjustment: PropTypes.func.isRequired,
  accountAdjustment: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string,
      accountDescription: PropTypes.string,
      accountGroup: PropTypes.string,
      amount: PropTypes.number,
      functionName: PropTypes.string,
      screenAccountGroup: PropTypes.string,
      sourceSystemId: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isFetchingContext: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    accountAdjustment: accountAdjustmentSelector(state),
    isFetchingAccountAdjustment: isFetchingAccountAdjustmentSelector(state),
  }),
  {
    fetchAccountAdjustment,
  },
)(AccountAdjustment);
