// Match field dataTypeName to dataType
// [dataTypeName]: dataType
export enum DataTypeNames {
  Default,
  Currency,
  Ratio,
  Integer,
  Date,
  Boolean,
  String,
}
