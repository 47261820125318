import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import GenericAccountDataUpload from './genericAccountDataUpload/genericAccountDataUpload.container';
import InformationalDataUpload from './informationalDataUpload/informationalDataUpload.container';
import MultDataUpload from './multDataUpload/multDataUpload.container';
import FederalDataRefresh from './federalDataRefresh/federalDataRefresh.container';
import Attachments from './attachments/attachments.container';
import GtwFileUpload from './gtwFileUpload/gtwFileUpload.container';
import OwnershipAllocationsUpload from './ownershipAllocationsUpload/ownershipAllocationsUpload.container';
import FilingGroupMembersUpload from './filingGroupMembersUpload/filingGroupMembersUpload.container';
import Diagnostics from './diagnostics/diagnostics.container';
import { AuthorizedToolsRoute } from './routeConfiguration/toolsRoute.component';

const ToolsRoutes = () => (
  <Switch>
    <AuthorizedToolsRoute
      exact
      path={Routes.diagnostics.MAIN}
      component={Diagnostics}
      accessOnlyWithEditPermission
    />
    <AuthorizedToolsRoute
      exact
      path={Routes.genericAccountDataUpload.MAIN}
      component={GenericAccountDataUpload}
      accessOnlyWithEditPermission
    />
    <AuthorizedToolsRoute
      exact
      path={Routes.informationalDataUpload.MAIN}
      component={InformationalDataUpload}
      accessOnlyWithEditPermission
    />
    <AuthorizedToolsRoute
      exact
      path={Routes.multDataUpload.MAIN}
      component={MultDataUpload}
      accessOnlyWithEditPermission
    />
    <AuthorizedToolsRoute
      exact
      path={Routes.filingGroupMembersUpload.MAIN}
      component={FilingGroupMembersUpload}
      accessOnlyWithEditPermission
    />
    <AuthorizedToolsRoute
      exact
      path={Routes.federalDataRefresh.MAIN}
      component={FederalDataRefresh}
      accessOnlyWithEditPermission
    />
    <AuthorizedToolsRoute
      exact
      path={Routes.attachments.MAIN}
      component={Attachments}
      accessOnlyWithEditPermission
    />
    <AuthorizedToolsRoute
      exact
      path={Routes.gtwFileUpload.MAIN}
      component={GtwFileUpload}
      accessOnlyWithEditPermission
    />
    <AuthorizedToolsRoute
      exact
      path={Routes.ownershipAllocationsUpload.MAIN}
      component={OwnershipAllocationsUpload}
      accessOnlyWithEditPermission
    />
  </Switch>
);

export default ToolsRoutes;
