import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from '@pwc/appkit-react/lib/Input';
import { dataTypes } from '@common-packages/shared-constants';
import { useHistory } from 'react-router-dom';
import { taxNames, Routes } from '@common-packages/routes-definitions';

import { infoNotification } from '../shared/notification/store/actions';
import useFetch from '../shared/hooks/useFetch.hook';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import {
  fetchDatasetInstances,
  selectDatasetInstance,
  fetchInstanceDataPrintService,
  addDatasetInstance,
  deleteDatasetInstance,
  clearDatasetInstances,
  clearInstanceData,
  clearInstanceDataPrintService,
} from '../shared/store/dataModels/actions';
import { fetchDataItemsDefinitions as fetchDataItemsDefinitionsApiMethod } from '../shared/store/dataModels/api';
import {
  datasetInstancesSelector,
  isFetchingDatasetInstancesSelector,
  instanceDataPrintServiceSelector,
  isFetchingInstanceDataPrintServiceSelector,
  isAddingDatasetInstanceSelector,
} from '../shared/store/dataModels/selectors';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import Loading from '../shared/displayComponents/loading.component';
import SelectContextDataInfo from '../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import { fetchLockIndicator } from '../shared/store/actions';
import { fetchDatasetDefinitionMetaData as fetchDatasetDefinitionMetaDataApiMethod } from '../workpaperInstance/store/api';
import {
  globalContextSelector,
  isEntityLockedSelector,
  isFetchingEntityLockIndicatorSelector,
  dataModelLinksSelector,
} from '../shared/store/selectors';
import useUrlParams from '../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { components } from '../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import NavigateBackLink from '../sharedSubPages/returnWorkspace/navigateBackLink.container';

import getColumnDefinitions from './viewFormWorkpaper.columnDefinitions';
import useViewFormWorkpaperEditing from './useViewFormWorkpaperEditing';
import ViewFormWorkpaperButtons from './viewFormWorkpaperButtons.component';
import styles from './forms.module.scss';
import { RETURN_TO_INCOME_TAX_SUMMARY_TEXT } from './constants';

const ViewFormWorkpaper = ({ isFetchingContext, hasUserPermissionsToEdit }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const globalContext = useSelector(globalContextSelector);
  const datasetInstances = useSelector(datasetInstancesSelector);
  const isFetchingDatasetInstances = useSelector(isFetchingDatasetInstancesSelector);
  const isFetchingInstanceDataPrintService = useSelector(
    isFetchingInstanceDataPrintServiceSelector,
  );
  const instanceDataPrintService = useSelector(instanceDataPrintServiceSelector);
  const lockIndicator = useSelector(isEntityLockedSelector);
  const isFetchingLockIndicator = useSelector(isFetchingEntityLockIndicatorSelector);
  const isAddingDatasetInstance = useSelector(isAddingDatasetInstanceSelector);
  const dataModelLinks = useSelector(dataModelLinksSelector);

  const { queryParams, routeParams, setParams } = useUrlParams();
  const { datasetDefId } = routeParams;
  const { datasetInstanceId, formId } = queryParams;
  const {
    isReady: isContextReady,
    isConsolidated,
    params: { taxYear, period, filingTypeId, businessEntityId, jurisdictionId },
  } = globalContext;
  const consolidationId = isConsolidated ? businessEntityId : null;

  const fetchDatasetInstancesForContext = useCallback(
    () =>
      dispatch(
        fetchDatasetInstances({
          taxYear,
          period,
          entityId: businessEntityId,
          jurisdictionId,
          datasetDefId,
          filingTypeId,
          consolidationId,
        }),
      ),
    [
      dispatch,
      taxYear,
      period,
      businessEntityId,
      jurisdictionId,
      datasetDefId,
      filingTypeId,
      consolidationId,
    ],
  );

  const {
    data: datasetDefinitionMetaData,
    isFetching: isFetchingDatasetDefinitionMetaData,
    fetch: fetchDatasetDefinitionMetaData,
  } = useFetch({ action: fetchDatasetDefinitionMetaDataApiMethod });

  const {
    data: dataItemsDefinitions,
    isFetching: isFetchingDataItemsDefinitions,
    fetch: fetchDataItemsDefinitions,
  } = useFetch({
    action: fetchDataItemsDefinitionsApiMethod,
  });

  useEffect(() => {
    if (isContextReady) {
      dispatch(fetchLockIndicator({ taxYear, period, jurisdictionId, orgId: businessEntityId }));
      fetchDatasetInstancesForContext();
      fetchDatasetDefinitionMetaData({ datasetDefinitionId: datasetDefId, filingTypeId });
      fetchDataItemsDefinitions({ taxYear, period, filingTypeId, jurisdictionId, datasetDefId });
    }
  }, [
    dispatch,
    fetchDatasetInstancesForContext,
    fetchDatasetDefinitionMetaData,
    fetchDataItemsDefinitions,
    isContextReady,
    taxYear,
    period,
    businessEntityId,
    jurisdictionId,
    filingTypeId,
    datasetDefId,
  ]);

  useEffect(() => {
    const isWorkpaperInstanceValidForThisJurisdiction = dataModelLinks.some(
      dataModel => dataModel?.datasetDefId === datasetDefId,
    );

    if (!isContextReady) {
      return;
    }

    if (!isWorkpaperInstanceValidForThisJurisdiction) {
      const pathToIncomeTaxSummary = Routes.taxSummaries.compiledRoute({
        ...globalContext.params,
        taxName: taxNames.INCOME_TAX,
      });
      dispatch(infoNotification(RETURN_TO_INCOME_TAX_SUMMARY_TEXT, { duration: 5000 }));
      history.push(pathToIncomeTaxSummary);
    }
  }, [dispatch, isContextReady, globalContext, history, dataModelLinks, datasetDefId]);

  useEffect(() => {
    if (!datasetInstanceId && datasetInstances.length) {
      setParams({ queryParams: { datasetInstanceId: datasetInstances[0].value } });
    }
  }, [setParams, datasetInstances, datasetInstanceId]);

  const isDatasetInstanceIdFromUrlInDataInstances = datasetInstances.some(
    ({ value }) => value === datasetInstanceId,
  );
  const isSelectedDatasetInstanceIdValid =
    datasetInstanceId && isDatasetInstanceIdFromUrlInDataInstances;

  useEffect(() => {
    if (!isSelectedDatasetInstanceIdValid && datasetInstances.length) {
      setParams({ queryParams: { datasetInstanceId: datasetInstances[0].value } });
    }
  }, [setParams, datasetInstanceId, datasetInstances, isSelectedDatasetInstanceIdValid]);

  useEffect(() => {
    if (isContextReady && isSelectedDatasetInstanceIdValid) {
      dispatch(
        fetchInstanceDataPrintService({
          taxYear,
          period,
          businessEntityId,
          filingTypeId,
          datasetDefId,
          datasetInstanceId,
          jurisdictionId,
        }),
      );
    }
    return () => {
      dispatch(clearInstanceDataPrintService());
    };
  }, [
    dispatch,
    isContextReady,
    taxYear,
    period,
    datasetDefId,
    isSelectedDatasetInstanceIdValid,
    filingTypeId,
    businessEntityId,
    datasetInstanceId,
    jurisdictionId,
  ]);

  const dataInstanceOnChange = useCallback(
    selectedInstance => {
      setParams({ queryParams: { datasetInstanceId: selectedInstance.value } });
      dispatch(selectDatasetInstance(selectedInstance.value));
    },
    [dispatch, setParams],
  );

  const openModalForDelete = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Delete Instance',
        text: 'Delete will remove this data and the data of any child forms and/or attachments.',
        confirmCallback: async () => {
          await dispatch(
            deleteDatasetInstance({
              datasetDefId,
              datasetInstanceId,
            }),
          );
          dispatch(clearDatasetInstances());
          dispatch(clearInstanceData());
          setParams({ queryParams: { datasetInstanceId: null } });
          fetchDatasetInstancesForContext();
        },
      }),
    );
  }, [dispatch, setParams, fetchDatasetInstancesForContext, datasetDefId, datasetInstanceId]);

  const noEditableInstances = !instanceDataPrintService.some(({ isEditable }) => isEditable);
  const [invalidData, setInvalidData] = useState(false);

  const {
    gridApi,
    navigationPrompt,
    editModeButtons,
    clonedRowData,
    updateRow,
    isInEditMode,
    onGridReady,
    isInAddMode,
    enterAddingInstanceMode,
    isUpdatingInstanceData,
  } = useViewFormWorkpaperEditing({
    taxYear,
    period,
    filingTypeId,
    entityId: businessEntityId,
    jurisdictionId,
    datasetDefId,
    dataItemsDefinitions,
    formId,
    setParams,
    fetchDatasetInstancesForContext,
    addDatasetInstance,
    clearDatasetInstances,
    fetchInstanceDataPrintService,
    datasetInstanceId,
    instanceDataPrintService,
    lockIndicator,
    noEditableInstances,
    isSelectedDatasetInstanceIdValid,
    isLoading:
      isFetchingDatasetInstances ||
      isAddingDatasetInstance ||
      isFetchingInstanceDataPrintService ||
      isFetchingLockIndicator,
    saveButtonDisabled: invalidData,
  });

  const isLoadingInstances =
    isFetchingDatasetInstances || isUpdatingInstanceData || isAddingDatasetInstance;

  const showSpinnerInGrid =
    isLoadingInstances ||
    isFetchingInstanceDataPrintService ||
    isFetchingLockIndicator ||
    isFetchingDataItemsDefinitions;

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInAddMode,
        isInEditMode,
        updateRow,
        datasetDefinitionId: datasetDefId,
        datasetInstanceId: datasetInstanceId ? datasetInstanceId : '',
        context: globalContext.params,
      }),
    [updateRow, isInAddMode, isInEditMode, datasetDefId, datasetInstanceId, globalContext],
  );

  const navigateBackLink = useMemo(
    () => <NavigateBackLink taxFormInstanceId={datasetInstanceId} />,
    [datasetInstanceId],
  );

  const rowDataChanged = useCallback(() => {
    if (gridApi) {
      gridApi.forEachNode(({ data }, index) => {
        if (index === 0) {
          setInvalidData(false);
        }

        if (
          data.dataType === dataTypes.STRING &&
          data.dataLength > 0 &&
          typeof data.value === 'string' &&
          data.value.length > data.dataLength
        ) {
          setInvalidData(true);
        }
      });
    }
  }, [gridApi]);

  if (isFetchingContext) {
    return (
      <div className={styles.loaderContainer}>
        <Loading isLoading />
      </div>
    );
  }

  if (!isContextReady) {
    return <SelectContextDataInfo />;
  }

  const shouldDisplayDatasetInstanceOptions =
    datasetDefinitionMetaData?.maximumInstancesAllowed !== 1;

  return (
    <Loading isLoading={isFetchingDatasetDefinitionMetaData}>
      {navigationPrompt}
      <div className="row">
        <div className={`col ${styles.selectCol}`}>
          <Loading small isLoading={isFetchingDatasetDefinitionMetaData}>
            <Input
              label="DataSet Definition"
              name="datasetDefinition"
              autoComplete="off"
              value={datasetDefinitionMetaData ? datasetDefinitionMetaData.label : ''}
              disabled
            />
          </Loading>
        </div>
        <div className={`col ${styles.selectCol}`}>
          <Loading small isLoading={isFetchingDatasetDefinitionMetaData}>
            <Input
              label="DataSet Name"
              name="datasetDefinition"
              autoComplete="off"
              value={datasetDefinitionMetaData ? datasetDefinitionMetaData.name : ''}
              disabled
            />
          </Loading>
        </div>
        {shouldDisplayDatasetInstanceOptions && (
          <div className={`col ${styles.selectCol}`}>
            <Loading small isLoading={isLoadingInstances}>
              <SDKCustomSelect
                appkitLabel="Instance"
                className="sdk-custom-select"
                options={
                  isInAddMode
                    ? [{ value: 'NEW INSTANCE', label: 'NEW INSTANCE' }]
                    : datasetInstances
                }
                value={isInAddMode ? 'NEW INSTANCE' : datasetInstanceId}
                virtualized
                onChange={dataInstanceOnChange}
                disabled={isInEditMode || isInAddMode}
              />
            </Loading>
          </div>
        )}
        {hasUserPermissionsToEdit && (
          <ViewFormWorkpaperButtons
            disableAllButtons={isInEditMode || showSpinnerInGrid}
            datasetDefinitionMetaData={datasetDefinitionMetaData}
            datasetDefId={datasetDefId}
            isLoadingInstances={isLoadingInstances}
            enterAddingInstanceMode={enterAddingInstanceMode}
            lockIndicator={lockIndicator}
            openModalForDelete={openModalForDelete}
            editModeButtons={editModeButtons}
            datasetInstancesLength={datasetInstances.length}
            datasetInstanceId={datasetInstanceId}
            isSelectedDatasetInstanceIdValid={isSelectedDatasetInstanceIdValid}
            isInAddMode={isInAddMode}
            refetchParentGrid={fetchDatasetInstancesForContext}
          />
        )}
      </div>
      {navigateBackLink}
      <div className={`row grid-row ${styles.selectGrid}`}>
        <div className="col">
          <AgGrid
            columnDefs={columnDefinitions}
            rowData={clonedRowData}
            isGridLoading={showSpinnerInGrid}
            onGridReady={onGridReady}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            suppressCellFocus={!isInEditMode}
            components={components}
            onRowDataUpdated={rowDataChanged}
            withSearchBar
          />
        </div>
      </div>
    </Loading>
  );
};

ViewFormWorkpaper.propTypes = {
  isFetchingContext: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default ViewFormWorkpaper;
