import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../shared/editMode';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import {
  fetchProfilesOptions,
  fetchConsolidationFilingGroupsOptions,
  fetchConsolidationFilingDecisions,
  updateConsolidationFilingDecisions,
  clearNexusNote,
  createNexusNote,
  updateNexusNote,
  fetchNexusNote,
} from '../store/actions';
import {
  profileOptionsSelector,
  isFetchingProfileOptionsSelector,
  consolidationFilingGroupOptionsSelector,
  isFetchingConsolidationFilingGroupOptionsSelector,
  consolidationFilingDecisionsSelector,
  isFetchingConsolidationFilingDecisionsSelector,
  isUpdatingConsolidationFilingDecisionsSelector,
  nexusNoteSelector,
  isFetchingNexusNoteSelector,
  orgIdAsOptionsSelector,
  orgIdsWithoutNexusNotesAsOptionsSelector,
} from '../store/selectors';
import {
  taxYearSelector,
  periodSelector,
  jurisdictionIdSelector,
  jurisdictionDescriptionSelector,
  consolidationIdSelector,
} from '../../shared/store/selectors';
import { selectEntity as selectOrgId, selectFilingType } from '../../shared/store/actions';

import getColumnDefinitions from './filingDecisions.columnDefinitions';
import {
  FilingDecisionsPropTypes,
  filteredOrgIdOptionsPropTypes,
  orgIdOptionsPropTypes,
  nexusNoteToEditPropTypes,
} from './propTypes';
import AddNexusNotesModal from './addNexusNoteModal.container';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const FilingDecisions = ({
  taxYear,
  period,
  jurisdictionId,

  contextDataId,
  selectOrgId,
  selectFilingType,

  fetchConsolidationFilingDecisions,
  filingDecisions,
  isFetchingFilingDecisions,

  fetchProfilesOptions,
  profilesOptions,
  isFetchingProfilesOptions,

  fetchConsolidationFilingGroupsOptions,
  filingGroupsOptions,
  isFetchingFilingGroupsOptions,

  updateConsolidationFilingDecisions,
  isUpdatingFilingDecisions,

  clearNexusNote,
  jurisdictionDescription,
  orgIdOptions,
  filteredOrgIdOptions,
  createNexusNote,
  updateNexusNote,
  fetchNexusNote,
  nexusNoteToEdit,
  isFetchingNexusNote,

  hasUserPermissionsToEdit,
}) => {
  const isContextReady = taxYear && period && jurisdictionId && contextDataId;
  useEffect(() => {
    if (!isContextReady) {
      return;
    }
    fetchProfilesOptions({ taxYear, jurisdictionId });
    fetchConsolidationFilingGroupsOptions({ taxYear, period, jurisdictionId });
    fetchConsolidationFilingDecisions({
      taxYear,
      period,
      jurisdictionId,
      contextDataId,
    });
  }, [
    fetchProfilesOptions,
    fetchConsolidationFilingGroupsOptions,
    fetchConsolidationFilingDecisions,
    taxYear,
    period,
    jurisdictionId,
    contextDataId,
    isContextReady,
  ]);

  const reloadGrid = useCallback(() => {
    if (isContextReady) {
      fetchConsolidationFilingDecisions({
        taxYear,
        period,
        jurisdictionId,
        contextDataId,
      });
    }
  }, [
    isContextReady,
    fetchConsolidationFilingDecisions,
    taxYear,
    period,
    jurisdictionId,
    contextDataId,
  ]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        orgId: newRow.orgId,
        oldProfileId: oldRow.profileId,
        nexusInd: newRow.nexusInd,
        processInd: newRow.processInd,
        newProfileId: newRow.profileId,
        oldFinalFilingGroupId: oldRow.finalFilingGroupId,
        newFinalFilingGroupId: newRow.finalFilingGroupId,
        incomeTaxYn: newRow.incomeTaxYn,
        franchiseTaxYn: newRow.franchiseTaxYn,
      }));
      await updateConsolidationFilingDecisions({
        taxYear,
        period,
        jurisdictionId,
        rowsToUpdate,
      });
      fetchConsolidationFilingDecisions({ taxYear, period, jurisdictionId, contextDataId });
    },
    [
      taxYear,
      period,
      jurisdictionId,
      contextDataId,
      fetchConsolidationFilingDecisions,
      updateConsolidationFilingDecisions,
    ],
  );

  const isLoading =
    isFetchingFilingDecisions ||
    isFetchingProfilesOptions ||
    isFetchingFilingGroupsOptions ||
    isUpdatingFilingDecisions;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: filingDecisions,
    getUniqueRowId,
    editButtonDisabled: isLoading || !filingDecisions.length,
  });

  const showModalForEdit = useCallback(
    orgId => {
      fetchNexusNote({
        taxYear,
        orgId,
        jurisdictionId,
      });
      setModalVisibility(true);
    },
    [fetchNexusNote, taxYear, jurisdictionId],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        taxYear,
        period,
        selectOrgId,
        selectFilingType,
        isInEditMode,
        updateRow,
        profilesOptions,
        filingGroupsOptions,

        onNexusNoteClick: showModalForEdit,
      }),
    [
      selectOrgId,
      selectFilingType,
      taxYear,
      period,
      isInEditMode,
      updateRow,
      profilesOptions,
      filingGroupsOptions,
      showModalForEdit,
    ],
  );

  const [isModalVisible, setModalVisibility] = useState(false);
  const hideModal = useCallback(() => {
    setModalVisibility(false);
    clearNexusNote();
  }, [clearNexusNote]);
  const showModal = useCallback(() => setModalVisibility(true), []);

  const addNexusNote = useCallback(
    async ({ preparedBy, note, orgId, rolloverToNextYear }) => {
      await createNexusNote({
        taxYear,
        orgId,
        preparedBy,
        note,
        rolloverToNextYear,
        jurisdictionId,
      });
      reloadGrid();
    },
    [createNexusNote, reloadGrid, taxYear, jurisdictionId],
  );

  const editNexusNote = useCallback(
    async ({ note, orgId, rolloverToNextYear, shortDescription, noteId }) => {
      await updateNexusNote({
        taxYear,
        jurisdictionId,
        note,
        orgId,
        rolloverToNextYear,
        shortDescription,
        noteId,
      });
      reloadGrid();
    },
    [updateNexusNote, reloadGrid, taxYear, jurisdictionId],
  );

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col add-button-column">
          {orgIdOptions.length && hasUserPermissionsToEdit ? (
            <Button size="lg" className="add-button" onClick={showModal}>
              Add Note
            </Button>
          ) : null}
          {hasUserPermissionsToEdit ? editModeButtons : null}
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            withSearchBar
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
          />
        </div>
        <AddNexusNotesModal
          visible={isModalVisible}
          hideModal={hideModal}
          jurisdictionDescription={jurisdictionDescription}
          createNexusNote={addNexusNote}
          orgIdOptions={orgIdOptions}
          filteredOrgIdOptions={filteredOrgIdOptions}
          nexusNoteToEdit={nexusNoteToEdit}
          isFetchingNexusNote={isFetchingNexusNote}
          editNexusNote={editNexusNote}
        />
      </div>
    </>
  );
};

FilingDecisions.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  jurisdictionId: PropTypes.string,

  contextDataId: PropTypes.string,
  selectOrgId: PropTypes.func.isRequired,
  selectFilingType: PropTypes.func.isRequired,

  fetchConsolidationFilingDecisions: PropTypes.func.isRequired,
  filingDecisions: FilingDecisionsPropTypes.isRequired,
  isFetchingFilingDecisions: PropTypes.bool.isRequired,

  fetchProfilesOptions: PropTypes.func.isRequired,
  profilesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingProfilesOptions: PropTypes.bool.isRequired,

  fetchConsolidationFilingGroupsOptions: PropTypes.func.isRequired,
  filingGroupsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingFilingGroupsOptions: PropTypes.bool.isRequired,

  updateConsolidationFilingDecisions: PropTypes.func.isRequired,
  isUpdatingFilingDecisions: PropTypes.bool.isRequired,

  jurisdictionDescription: PropTypes.string,
  createNexusNote: PropTypes.func.isRequired,
  orgIdOptions: orgIdOptionsPropTypes,
  filteredOrgIdOptions: filteredOrgIdOptionsPropTypes,
  fetchNexusNote: PropTypes.func.isRequired,
  nexusNoteToEdit: nexusNoteToEditPropTypes,
  isFetchingNexusNote: PropTypes.bool.isRequired,
  clearNexusNote: PropTypes.func.isRequired,
  updateNexusNote: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    jurisdictionDescription: jurisdictionDescriptionSelector(state),

    profilesOptions: profileOptionsSelector(state),
    isFetchingProfilesOptions: isFetchingProfileOptionsSelector(state),
    filingGroupsOptions: consolidationFilingGroupOptionsSelector(state),
    isFetchingFilingGroupsOptions: isFetchingConsolidationFilingGroupOptionsSelector(state),
    isUpdatingFilingDecisions: isUpdatingConsolidationFilingDecisionsSelector(state),

    nexusNoteToEdit: nexusNoteSelector(state),
    isFetchingNexusNote: isFetchingNexusNoteSelector(state),

    contextDataId: consolidationIdSelector(state),
    filingDecisions: consolidationFilingDecisionsSelector(state),
    isFetchingFilingDecisions: isFetchingConsolidationFilingDecisionsSelector(state),

    orgIdOptions: orgIdAsOptionsSelector(state),
    filteredOrgIdOptions: orgIdsWithoutNexusNotesAsOptionsSelector(state),
  }),
  {
    fetchProfilesOptions,
    fetchConsolidationFilingGroupsOptions,
    updateConsolidationFilingDecisions,

    clearNexusNote,
    createNexusNote,
    fetchNexusNote,
    updateNexusNote,

    fetchConsolidationFilingDecisions,
    selectOrgId,
    selectFilingType,
  },
)(FilingDecisions);
