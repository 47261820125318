import PropTypes from 'prop-types';

export const filingAttributesDefinitionTypes = PropTypes.arrayOf(
  PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
);
