export const categoriesForGenericCategory = {
  PAYROLL_WITHIN: 'PAYROLL_WITHIN',
  PAYROLL_EVERYWHERE: 'PAYROLL_EV',
  SALES_WITHIN: 'SALES_WITHIN',
  SALES_EVERYWHERE: 'SALES_EV',
  PAYROLL_WITHIN_FRANCHISE: 'FTAX_PAYROLL_WITHIN',
  PAYROLL_EVERYWHERE_FRANCHISE: 'FTAX_PAYROLL_EV',
  SALES_WITHIN_FRANCHISE: 'FTAX_SALES_WITHIN',
  SALES_EVERYWHERE_FRANCHISE: 'FTAX_SALES_EV',
};

export const categoriesForPropertySummary = {
  PROPERTY_WITHIN: 'PROPERTY_WITHIN',
  PROPERTY_EVERYWHERE: 'PROPERTY_EV',
  PROPERTY_WITHIN_FRANCHISE: 'FTAX_PROPERTY_WITHIN',
  PROPERTY_EVERYWHERE_FRANCHISE: 'FTAX_PROPERTY_EV',
};
