const Yup = require('yup');

const genericFunctionFormSchema = Yup.object().shape({
  functionId: Yup.string().label('Function Id').required(),
  description: Yup.string()
    .label('Description')
    .required()
    .max(80, 'Description cannot exceed 80 characters'),
  functionType: Yup.string().label('Function Type').nullable().required(),
  operationType: Yup.string().label('Operation Type').nullable().required(),
});

const categoryIdDependentOnDataTypeSchema = Yup.string().when('dataType', {
  is: 'DATA_MODEL',
  then: Yup.string()
    .typeError('Category is required for DATA_MODEL parameter types')
    .required('Category is required for DATA_MODEL parameter types'),
  otherwise: Yup.string().nullable(),
});

const accountIdDependentOnDataTypeSchema = Yup.string().when('dataType', {
  is: 'DATA_MODEL',
  then: Yup.string()
    .typeError('Account is required for DATA_MODEL parameter types')
    .required('Account is required for DATA_MODEL parameter types'),
  otherwise: Yup.string().nullable(),
});

const jurisdictionIdDependentOnDataTypeSchema = Yup.string().when('dataType', {
  is: 'DATA_MODEL',
  then: Yup.string()
    .typeError('Jurisdiction is required for DATA_MODEL parameter types')
    .required('Jurisdiction is required for DATA_MODEL parameter types'),
  otherwise: Yup.string().nullable(),
});

const genericFunctionParamOnGridSchema = Yup.array().of(
  Yup.object().shape({
    dataType: Yup.string().required(),
    parameterType: Yup.string().required(),
    jurisdictionId: jurisdictionIdDependentOnDataTypeSchema,
    accountId: accountIdDependentOnDataTypeSchema,
    categoryId: categoryIdDependentOnDataTypeSchema,
    otherId: Yup.string().nullable(),
    multiplier: Yup.number().required(),
    roundPlaces: Yup.number().required(),
  }),
);

const createUpdateGenericFunctionSchema = Yup.object().shape({
  functionId: Yup.string().required(),
  description: Yup.string().required(),
  functionType: Yup.string().required(),
  operationType: Yup.string().required(),
  parameters: Yup.array().of(
    Yup.object().shape({
      dataType: Yup.string().required(),
      parameterType: Yup.string().required(),
      accountId: accountIdDependentOnDataTypeSchema,
      categoryId: categoryIdDependentOnDataTypeSchema,
      otherId: Yup.string().nullable(),
      multiplier: Yup.number().required(),
      roundPlaces: Yup.number().required(),
    }),
  ),
});

module.exports = {
  genericFunctionFormSchema,
  genericFunctionParamOnGridSchema,
  createUpdateGenericFunctionSchema,
};
