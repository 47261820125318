import { createSelector } from 'reselect';

export const isFetchingFederalReconciliationSelector = ({ entity }) =>
  entity.federalReconciliations.root.isFetchingFederalReconciliations;

export const federalReconciliationsSelector = ({ entity }) =>
  entity.federalReconciliations.root.federalReconciliations;

export const accountGroupSelector = ({ entity }) => entity.federalReconciliations.root.accountGroup;

export const accountGroupNameSelector = createSelector(
  [federalReconciliationsSelector, accountGroupSelector],
  (federalReconciliations, accountGroup) =>
    (
      federalReconciliations.find(
        federalReconciliation => federalReconciliation.accountGroup === accountGroup,
      ) || { categoryDescription: null }
    ).categoryDescription,
);
