import { Routes } from '@common-packages/routes-definitions';

import { currencyColumnDefinition } from '../../../shared/columnDefinitions';
import {
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../../shared/columnDefinitions/cellRenderers';

const overlinedCellClassRules = {
  'overlined-cell': ({ data }) => data.isTotalRow,
};

const accountCellClassRules = {
  'bold-cell': ({ data }) => data.isTotalRow,
};
const isTotalRow = row => row.data.isTotalRow;

const defaultColumnDefinition = {
  width: 120,
  sortable: false,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
  cellClassRules: { ...overlinedCellClassRules },
};

const LinkRenderer = LinkCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
  getPathName: () => Routes.federalReconciliation.MAIN, // TODO: SLT-90
});
const TextRenderer = TextCellRendererFactory({
  getText: ({ value }) => value,
});
const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Component Id',
    field: 'componentId',
    cellClassRules: { ...accountCellClassRules, ...overlinedCellClassRules },
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Amount',
    field: 'sltTaxAmount',
    cellRenderer: row => (isTotalRow(row) ? TextRenderer(row) : LinkRenderer(row)),
  },
];

export default columnDefinitions;
