import { httpGetAndParse, httpPost, httpPut, httpDelete } from '../../../utils/fetchService';

const baseURL = '/api/admin/maintenance/roles';

export const fetchRoles = () =>
  httpGetAndParse({
    route: baseURL,
    errorMessage: 'Fetching roles failed',
  });

export const createRole = role =>
  httpPost({
    route: baseURL,
    errorMessage: 'Creating role failed',
    body: role,
  });

export const updateRole = ({ roleName, roleId }) =>
  httpPut({
    route: `${baseURL}/${roleId}`,
    errorMessage: 'Updating role failed',
    body: {
      roleName,
    },
  });

export const deleteRole = ({ roleId }) =>
  httpDelete({
    route: `${baseURL}/${roleId}`,
    errorMessage: 'Deleting role failed',
    parseResponseErrorMessage: true,
  });
