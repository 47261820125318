import React, { useState, useEffect, useCallback } from 'react';

import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import useFetch from '../../shared/hooks/useFetch.hook';
import * as api from '../store/api';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';

import styles from './styles.module.scss';
import { timePeriodOptions } from './constants';
import columnDefinitions from './jimJobsHistory.columnDefinitions';

const JimJobsHistory = () => {
  const {
    data: jimJobsHistory,
    fetch: fetchJimJobsHistory,
    isFetching: isFetchingJimJobsHistory,
  } = useFetch({
    action: api.fetchJimJobsHistory,
  });

  const [timePeriod, setTimePeriod] = useState(24);
  const [filteredJobs, setFilteredJobs] = useState([]);

  useEffect(() => {
    fetchJimJobsHistory();
  }, [fetchJimJobsHistory]);

  const filterJobsByTimePeriod = useCallback(() => {
    const timeStamp = Math.round(new Date().getTime() / 1000);
    setFilteredJobs(
      jimJobsHistory.filter(job => {
        const createdOnTimeStamp = Math.round(new Date(job.createdOn).getTime() / 1000);
        return timeStamp - timePeriod * 3600 < createdOnTimeStamp;
      }),
    );
  }, [jimJobsHistory, timePeriod]);

  useEffect(() => {
    if (jimJobsHistory && timePeriod) {
      filterJobsByTimePeriod();
    }
  }, [filterJobsByTimePeriod, jimJobsHistory, timePeriod]);

  const onTimePeriodChange = useCallback(newTimePeriod => {
    setTimePeriod(newTimePeriod);
  }, []);

  return (
    <>
      <div className="row">
        <div className={`col ${styles.dropdown}`}>
          <ParamDropdown
            label="Show jobs for the last:"
            options={timePeriodOptions}
            value={timePeriod}
            handleChange={onTimePeriodChange}
            isBusy={isFetchingJimJobsHistory}
          />
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={filteredJobs}
            isGridLoading={isFetchingJimJobsHistory}
            columnDefs={columnDefinitions}
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
    </>
  );
};

export default JimJobsHistory;
