import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';

import { fetchBusinessesTypes, createBusinessTypes, updateBusinessTypes } from './types';

export const initialState = {
  data: {
    businesses: [],
    businessGroups: [],
    parents: [],
    customers: [],
  },
  isFetchingBusinesses: false,
  isInsertingBusiness: false,
  isUpdatingBusiness: false,
};

const businessesReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchBusinessesTypes,
      payloadKey: 'data',
      fetchingKey: 'isFetchingBusinesses',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createBusinessTypes,
      fetchingKey: 'isInsertingBusiness',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateBusinessTypes,
      fetchingKey: 'isInsertingBusiness',
    }),
  },
});

export default businessesReducer;
