import React from 'react';
import classNames from 'classnames';

import config from '../../../../config';
import { DataTypeNames } from '../../../enums';

import eFileValidationErrorIconStyles from './eFileValidationErrorIcon.module.scss';

interface EFileValidationErrorIconProps {
  dataType: DataTypeNames;
}

const EFileValidationErrorIcon = ({ dataType }: EFileValidationErrorIconProps) => (
  <img
    className={classNames(eFileValidationErrorIconStyles.baseEfileValidationError, {
      [eFileValidationErrorIconStyles.eFileValidationErrorIconForBoolean]:
        dataType === DataTypeNames.Boolean,
    })}
    src={`${config.BASE_PATH}/assets/e-filing-icon.svg`}
    alt="e_filing_icon"
  />
);

export default EFileValidationErrorIcon;
