import { useEffect } from 'react';

import useEditModeLogic from './useEditModeLogic.hook';
import useEditModeButtons from './useEditModeButtons.hook';
import useEditModeNavigationPrompt from './useEditModeNavigationPrompt.hook';

const useRowEditMode = ({
  rowData,
  getUniqueRowId,
  onCancel = () => null,
  onEnter = () => null,
  onSave = unknown => unknown,
  appendNewRows = false,
  saveButtonDisabled,
  editButtonDisabled = false,
  isPermanentlyInEditMode = false,
  showLockIcon = false,
  appendRowUnderSelectedRow = false,
  validateBeforeSave = false,
  isFetchAllRows = false,
} = {}) => {
  const {
    gridApi,
    columnApi,
    clonedRowData,
    getAllRows,
    addRow,
    updateGroup,
    updateRow,
    deleteRow,
    updateRowForSelectAll,

    isInEditMode,
    setIsInEditMode,
    isDirty,

    findChanges,
    findChangesAndSave,
    revertChanges,
    revertChangesAndCancel,
    onGridReady,
  } = useEditModeLogic({
    onCancel,
    onSave,
    appendNewRows,
    rowData,
    getUniqueRowId,
    startInEditMode: isPermanentlyInEditMode,
    appendRowUnderSelectedRow,
    turnOffEditModeOnSave: !isPermanentlyInEditMode,
  });

  const cancelClickHandler = () => {
    onCancel();
    revertChangesAndCancel();
  };

  // Refresh displayed cells when switching in and out of edit mode to
  // ensure updates to any custom cell renderers are visible
  useEffect(() => {
    gridApi?.refreshCells({ force: true });
  }, [isInEditMode, gridApi]);

  const validateChanges = () => (validateBeforeSave ? validateBeforeSave(findChanges()) : true);

  const handleEnter = () => {
    onEnter({ gridApi, updateGroup });
  };

  const { editModeButtons } = useEditModeButtons({
    isInEditMode,
    setIsInEditMode,
    onCancel: cancelClickHandler,
    onEnter: handleEnter,
    onSave: findChangesAndSave,
    saveButtonDisabled:
      (typeof saveButtonDisabled === 'function'
        ? saveButtonDisabled(getAllRows(isFetchAllRows))
        : Boolean(saveButtonDisabled)) || !isDirty,
    editButtonDisabled,
    isPermanentlyInEditMode,
    showLockIcon,
    turnOffEditModeOnSave: false,
    validateBeforeSave: validateChanges,
  });

  const { navigationPrompt } = useEditModeNavigationPrompt(isDirty);

  return {
    gridApi,
    columnApi,
    isInEditMode,
    isDirty,
    setIsInEditMode,
    editModeButtons,
    clonedRowData,
    getAllRows,
    addRow,
    updateGroup,
    updateRow,
    deleteRow,
    onGridReady,
    updateRowForSelectAll,
    revertChanges,
    findChanges,
    findChangesAndSave,
    navigationPrompt,
  };
};

export default useRowEditMode;
