export enum FileType {
  XML = 'XML',
  PDF = 'PDF',
  ZIP = 'ZIP',
}

export enum SourceId {
  GTW = 'GTW',
  TTI = 'TTI',
}
