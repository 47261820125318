import React, { ReactNode } from 'react';

import { StepTabWrapper, StepTabHeader, StepTabContent } from '../stepTab';

interface StepTabProps {
  title: string;
  wrapperClassName?: string;
  subtitle?: string;
  contentClassName?: string;
  subtitleClassName?: string;
  children?: ReactNode;
  rightHeaderContent?: ReactNode;
}

const StepTab = ({
  title,
  subtitle = '',
  contentClassName = '',
  wrapperClassName = '',
  subtitleClassName = '',
  rightHeaderContent = null,
  children = null,
}: StepTabProps) => (
  <StepTabWrapper className={wrapperClassName}>
    <StepTabHeader title={title} subtitle={subtitle} subtitleClassName={subtitleClassName}>
      {rightHeaderContent}
    </StepTabHeader>
    {children && <StepTabContent className={contentClassName}>{children}</StepTabContent>}
  </StepTabWrapper>
);

export default StepTab;
