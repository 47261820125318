import { createSlice } from '@reduxjs/toolkit';

const contextSlice = createSlice({
  name: 'context',
  initialState: {
    sltBinderId: null,
    clientId: null,
    isClientStatusInitialized: false,
  },
  reducers: {
    setSltBinderId(state, { payload: { sltBinderId } }) {
      state.sltBinderId = sltBinderId;
    },
    setClientId(state, { payload }) {
      if (payload !== state.clientId) {
        state.isClientStatusInitialized = false;
      }
      state.clientId = payload;
    },
    setIsClientStatusInitialized(state, { payload }) {
      state.isClientStatusInitialized = payload;
    },
  },
});

const { actions, reducer } = contextSlice;

export const { setSltBinderId, setClientId, setIsClientStatusInitialized } = actions;

export const sltBinderIdSelector = ({ context }) => context.sltBinderId;

export const clientIdSelector = ({ context }) => context.clientId;

export const isClientStatusInitializedSelector = ({ context }) => context.isClientStatusInitialized;

export default reducer;
