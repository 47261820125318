import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';
import classNames from 'classnames';

import Input from '../../../../shared/forms/inputFormik/inputFormik.component';
import Checkbox from '../../../../shared/forms/checkboxFormik/checkboxFormik.component';
import { showConfirmModal } from '../../../../shared/confirmModal/store/actions';
import { SelectOptionPropTypes } from '../../../../shared/forms/propTypes';
import { formFieldType } from '../../../../shared/propTypes/pdfPropTypes';
import { DataItemsTypes } from '../../../../shared/constants';
import { formPdfIdSelector } from '../../../store/selectors';
import {
  sortedPdfFieldsByPageOptionsSelector,
  isFetchingPDFFieldsByPageSelector,
} from '../../../../shared/store/dataModels/selectors';
import { groupedDataItemsSelector } from '../../store/selectors';
import { useQuerySuppressFieldDatasets } from '../../../../shared/queries/taxFormsV2';
import getDataItemsOptionsBasedOnAssignedDataset from '../../utils/getDataItemsOptionsBasedOnAssignedDataset';

import getFieldAttributesDisabled from './utils/getFieldAttributesDisabled';
import {
  checkboxOptions,
  valueIfZeroOptions,
  initialValues,
  FIELD_ATTRIBUTES,
  DEFAULT_WRAPPING_OPTIONS,
} from './constants';
import FieldAttributesHelpLink from './fieldAttributesHelpLink/fieldAttributesHelpLink.component';
import WrappedField from './wrappedField.component';
import styles from './fields.module.scss';
import { getSuppressFieldOptions } from './utils/getSuppressFieldOptions';
import WrappedSelect from './wrappedSelect.component';

const FieldsEditForm = ({
  isLoading,
  handleAttributeChange,
  onUnmapField,
  selectedFormFieldId,
  handleFormFieldChange,
  datasetsAssignedToFormPage,

  values,
  setValues,
}) => {
  const dispatch = useDispatch();

  const dataItems = useSelector(groupedDataItemsSelector);
  const pdfFieldsByPageOptions = useSelector(sortedPdfFieldsByPageOptionsSelector);
  const isFetchingPDFFieldsByPage = useSelector(isFetchingPDFFieldsByPageSelector);
  const formPdfId = useSelector(formPdfIdSelector) || null;

  const {
    data: suppressFieldDatasets,
    isFetching: isFetchingSuppressFieldDatasets,
  } = useQuerySuppressFieldDatasets({
    params: { formPdfId, datasetDefinitionId: values.datasetId },
    enabled: Boolean(formPdfId && values.datasetId),
  });

  const { suppressFieldDatasetOptions, suppressFieldDataItemOptions } = useMemo(
    () =>
      getSuppressFieldOptions({
        suppressFieldDatasets,
        dataItems,
      }),
    [dataItems, suppressFieldDatasets],
  );

  const handleDatasetChange = useCallback(
    (name, value) => {
      setValues({
        ...initialValues,
        [name]: value,
      });
      handleAttributeChange(name, value);
    },
    [handleAttributeChange, setValues],
  );

  const handleDataItemChange = useCallback(
    (name, value) => {
      setValues(previousValues => ({
        ...initialValues,
        [FIELD_ATTRIBUTES.DATASET_ID]: previousValues[FIELD_ATTRIBUTES.DATASET_ID],
        [name]: value,
      }));
      handleAttributeChange(name, value);
    },
    [handleAttributeChange, setValues],
  );

  const handleSuppressFieldDatasetChange = useCallback(
    (name, value) => {
      setValues(previousValues => ({
        ...previousValues,
        [name]: value,
        [FIELD_ATTRIBUTES.SUPPRESS_FIELD_DATA_ITEM_DEF_ID]: null,
      }));
      handleAttributeChange(name, value);
    },
    [setValues, handleAttributeChange],
  );

  const handleWrapTextChange = useCallback(
    (name, value) => {
      setValues(previousValues => ({
        ...previousValues,
        [name]: value,
        ...DEFAULT_WRAPPING_OPTIONS,
      }));
      handleAttributeChange(name, value);
    },
    [setValues, handleAttributeChange],
  );

  const handleUnmapField = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Warning',
        text: 'Are you sure you want to remove this mapping and all existing data related to it?',
        confirmCallback: onUnmapField,
      }),
    );
  }, [dispatch, onUnmapField]);

  useEffect(() => {
    const isDataSetAssigned = datasetsAssignedToFormPage?.some(
      ({ value }) => value === values.datasetId,
    );
    if (values.datasetId && !isDataSetAssigned) {
      handleDatasetChange(FIELD_ATTRIBUTES.DATASET_ID, null);
    }
  }, [handleDatasetChange, datasetsAssignedToFormPage, values.datasetId]);

  const dataItemsOptions = useMemo(
    () =>
      getDataItemsOptionsBasedOnAssignedDataset({
        dataItems,
        datasetId: values.datasetId,
        datasetsAssignedToFormPage,
      }),
    [dataItems, datasetsAssignedToFormPage, values.datasetId],
  );

  const selectedDataItem = dataItemsOptions.find(({ value }) => value === values.dataItemId);
  const dataItemType = selectedDataItem?.dataType;
  const dataItemTypeName = selectedDataItem?.dataTypeName;
  const dataItemAliasName = selectedDataItem?.aliasName;

  const disabled = isLoading || isFetchingPDFFieldsByPage;
  const isFieldReady = !disabled && values.datasetId && values.dataItemId;
  const getDisabled = getFieldAttributesDisabled({ isFieldReady, dataItemType });

  const handleFieldNameChange = useCallback(
    (_, value) => {
      const fieldName = pdfFieldsByPageOptions.find(opt => opt.value === value)?.label;
      handleFormFieldChange(value, fieldName);
    },
    [handleFormFieldChange, pdfFieldsByPageOptions],
  );

  const defaultFieldProps = {
    disabled: getDisabled,
    customChangeHandler: handleAttributeChange,
  };

  const defaultWrappingOptionsFieldProps = {
    disabled: !values.wrapText || getDisabled,
    customChangeHandler: handleAttributeChange,
  };

  return (
    <form className={styles.formWrapper}>
      <div className={`row ${styles.panel}`}>
        <div className="col">
          <div className={styles.formColumn}>
            <h6 className={styles.fieldMappingHeader}>
              <span>Field Mapping </span>
              <FieldAttributesHelpLink className={styles.helpLink} />
            </h6>
            <WrappedSelect
              name="fieldName"
              appkitLabel="Field Name"
              value={selectedFormFieldId}
              options={pdfFieldsByPageOptions}
              customChangeHandler={handleFieldNameChange}
              disabled={disabled}
            />
            <WrappedSelect
              appkitLabel="DataSet"
              name={FIELD_ATTRIBUTES.DATASET_ID}
              options={datasetsAssignedToFormPage}
              value={values.datasetId}
              disabled={disabled}
              showSearchOnToggle
              customChangeHandler={handleDatasetChange}
              isClearable
            />
            <WrappedSelect
              appkitLabel="DataItem"
              name={FIELD_ATTRIBUTES.DATAITEM_ID}
              options={dataItemsOptions}
              value={values.dataItemId}
              disabled={disabled || !values.datasetId}
              showSearchOnToggle
              customChangeHandler={handleDataItemChange}
            />
            <div className={styles.fieldMappingFooter}>
              <div className={styles.dataTypeAndAttribute}>
                <div className={styles.dataType}>
                  {isFieldReady && (
                    <>
                      <span>Data Type: </span>
                      <span>{dataItemTypeName || 'N/A'}</span>
                    </>
                  )}
                </div>
                <div className={styles.aliasName}>
                  {isFieldReady && (
                    <>
                      <span>Alias: </span>
                      <span>{dataItemAliasName || 'N/A'}</span>
                    </>
                  )}
                </div>
              </div>
              <Button
                size="lg"
                onClick={handleUnmapField}
                disabled={!isFieldReady}
                className="mt-sm-1"
              >
                Unmap Field
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.scrollingSection, styles.panel, {
          [styles.sectionDisabled]: !isFieldReady,
        })}
      >
        <div className="row">
          <div className="col">
            <h6 className={styles.header}>Field Attributes</h6>
            <div className={`row ${styles.alignStart}`}>
              <div className="col">
                <div
                  className={classNames(styles.formColumnReverse, {
                    [styles.sectionDisabled]: ![
                      DataItemsTypes.INTEGER,
                      DataItemsTypes.CURRENCY,
                      DataItemsTypes.RATIO,
                    ].includes(dataItemTypeName),
                  })}
                >
                  <p className={styles.subHeader}>Numeric Formatting</p>
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Absolute Value"
                    name={FIELD_ATTRIBUTES.ABSOLUTE_VALUES}
                    component={Checkbox}
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Flip Sign"
                    name={FIELD_ATTRIBUTES.FLIP_SIGN}
                    component={Checkbox}
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Use Parentheses if Negative"
                    name={FIELD_ATTRIBUTES.NEGATIVE}
                    component={Checkbox}
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Ratio As Percent"
                    name={FIELD_ATTRIBUTES.RATIO_AS_PERCENT}
                    component={Checkbox}
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Suppress Commas"
                    name={FIELD_ATTRIBUTES.SUPPRESS_COMMAS}
                    component={Checkbox}
                  />
                  <WrappedSelect
                    {...defaultFieldProps}
                    appkitLabel="Value If Zero"
                    name={FIELD_ATTRIBUTES.VALUE_IF_ZERO}
                    options={valueIfZeroOptions}
                    value={values.valueIfZero}
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridField}
                    label="Decimal Places"
                    name={FIELD_ATTRIBUTES.DECIMAL_PLACES}
                    min="0"
                    max="9"
                    component={Input}
                    autoComplete="off"
                    type="number"
                  />
                </div>
              </div>
              <div className="col">
                <div
                  className={classNames(styles.formColumnReverse, styles.wrappingOptions, {
                    [styles.sectionDisabled]: dataItemTypeName !== DataItemsTypes.STRING,
                  })}
                >
                  <p className={styles.subHeader}>Wrapping Options</p>
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Wrap Text"
                    name={FIELD_ATTRIBUTES.WRAP_TEXT}
                    component={Checkbox}
                    customChangeHandler={handleWrapTextChange}
                  />
                  <WrappedField
                    {...defaultWrappingOptionsFieldProps}
                    className={styles.gridField}
                    label="First Row Indent Points"
                    name={FIELD_ATTRIBUTES.FIRST_ROW_INDT}
                    component={Input}
                    autoComplete="off"
                    type="number"
                  />
                  <WrappedField
                    {...defaultWrappingOptionsFieldProps}
                    className={styles.gridField}
                    label="Full Row Count"
                    name={FIELD_ATTRIBUTES.FULL_ROW_COUNT}
                    component={Input}
                    autoComplete="off"
                    type="number"
                  />
                  <WrappedField
                    {...defaultWrappingOptionsFieldProps}
                    className={styles.gridField}
                    label="Row Spacing Increment"
                    name={FIELD_ATTRIBUTES.ROW_SPACING_INC}
                    component={Input}
                    autoComplete="off"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={`row ${styles.alignStart}`}>
              <div className="col">
                <div className={styles.formColumn}>
                  <p className={styles.subHeader}>Common Formatting</p>
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridField}
                    label="Formatting String (Mask)"
                    name={FIELD_ATTRIBUTES.FORMATTING_STRING}
                    component={Input}
                    autoComplete="off"
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridField}
                    label="Regular Expression Filter"
                    name={FIELD_ATTRIBUTES.FILTER_REG_EXP}
                    component={Input}
                    autoComplete="off"
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridField}
                    label="Append Text"
                    name={FIELD_ATTRIBUTES.APPEND_TEXT}
                    component={Input}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col">
                <div className={styles.formColumn}>
                  <p className={styles.subHeader}>Additional Formatting</p>
                  <WrappedSelect
                    {...defaultFieldProps}
                    appkitLabel="Checkbox Type"
                    name={FIELD_ATTRIBUTES.CHECK_BOX}
                    options={checkboxOptions}
                    value={values.checkBox}
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridField}
                    label="Character Spacing"
                    name={FIELD_ATTRIBUTES.CHAR_SPACING}
                    component={Input}
                    autoComplete="off"
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Upper Case"
                    name={FIELD_ATTRIBUTES.UPPER_CASE}
                    component={Checkbox}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={styles.formColumn}>
              <p className={styles.subHeader}>Suppress Field</p>
              <WrappedSelect
                {...defaultFieldProps}
                appkitLabel="DataSet"
                name={FIELD_ATTRIBUTES.SUPPRESS_DATASET_DEF_ID}
                options={suppressFieldDatasetOptions}
                value={values.suppressFieldDatasetDefId}
                disabled={
                  getDisabled(FIELD_ATTRIBUTES.SUPPRESS_DATASET_DEF_ID) ||
                  !suppressFieldDatasetOptions.length ||
                  isFetchingSuppressFieldDatasets
                }
                customChangeHandler={handleSuppressFieldDatasetChange}
                isClearable
              />
              <WrappedSelect
                {...defaultFieldProps}
                appkitLabel="DataItem"
                name={FIELD_ATTRIBUTES.SUPPRESS_FIELD_DATA_ITEM_DEF_ID}
                options={suppressFieldDataItemOptions[values.suppressFieldDatasetDefId]}
                value={values.suppressFieldDataItemDefId}
                disabled={
                  getDisabled(FIELD_ATTRIBUTES.SUPPRESS_FIELD_DATA_ITEM_DEF_ID) ||
                  !values.suppressFieldDatasetDefId ||
                  isFetchingSuppressFieldDatasets
                }
                customChangeHandler={handleAttributeChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={`row ${styles.alignStart}`}>
              <div className="col">
                <div
                  className={classNames(styles.formColumn, {
                    [styles.sectionDisabled]: dataItemTypeName === DataItemsTypes.BOOLEAN,
                  })}
                >
                  <p className={styles.subHeader}>Fullfield Testing</p>
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridField}
                    label="Fullfield Pattern"
                    name={FIELD_ATTRIBUTES.FULLFIELD_PATTERN}
                    component={Input}
                    autoComplete="off"
                  />
                </div>
                <div className={styles.formColumnReverse}>
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridField}
                    label="Fullfield Length"
                    name={FIELD_ATTRIBUTES.FULLFIELD_LENGTH}
                    component={Input}
                    autoComplete="off"
                    type="number"
                  />
                </div>
              </div>
              <div className="col">
                <div className={styles.formColumnReverse}>
                  <p className={styles.subHeader}>PDF Printing</p>
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Print First Copy Only"
                    name={FIELD_ATTRIBUTES.PRINT_FIRST_COPY_ONLY}
                    component={Checkbox}
                  />
                  <WrappedField
                    {...defaultFieldProps}
                    className={styles.gridCheckbox}
                    label="Print Last Copy Only"
                    name={FIELD_ATTRIBUTES.PRINT_LAST_COPY_ONLY}
                    component={Checkbox}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row ${styles.formGuidFooter}`}>
          <div className="col">
            <span>Data Model ID: </span>
            {values?.dataModelId && <span>{values?.dataModelId}</span>}
          </div>
          <div className="col">
            <span>DataSet ID: </span>
            {values?.datasetId && <span>{values?.datasetId}</span>}
          </div>
          <div className="col">
            <span>DataItem ID: </span>
            {values?.dataItemId && <span>{values?.dataItemId}</span>}
          </div>
        </div>
      </div>
    </form>
  );
};

FieldsEditForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleAttributeChange: PropTypes.func.isRequired,
  onUnmapField: PropTypes.func.isRequired,
  selectedFormFieldId: PropTypes.string,
  handleFormFieldChange: PropTypes.func.isRequired,
  datasetsAssignedToFormPage: PropTypes.arrayOf(SelectOptionPropTypes),

  values: formFieldType.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default FieldsEditForm;
