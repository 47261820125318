import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../../shared/store/reducerFactory';
import { SELECT_TAX_YEAR } from '../../../../shared/store/types';
import { fetchTaxRatesTypes } from '../types/taxRates';
import { SELECT_JURISDICTION_ID } from '../types/shared';

export const taxRatesInitialState = {
  taxRates: {
    columnsBlueprint: [],
    data: [],
  },
  isFetchingTaxRates: false,
};

const taxRatesReducer = reducerFactory({
  initialState: taxRatesInitialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchTaxRatesTypes,
      payloadKey: 'taxRates',
      fetchingKey: 'isFetchingTaxRates',
    }),
    [SELECT_TAX_YEAR]: state => ({
      ...state,
      taxRates: taxRatesInitialState.taxRates,
    }),
    [SELECT_JURISDICTION_ID]: state => ({
      ...state,
      taxRates: taxRatesInitialState.taxRates,
    }),
  },
});

export default taxRatesReducer;
