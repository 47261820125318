import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown, MenuList, MenuButton, SubMenuItem } from 'react-menu-list';

import NestedMenuItem from './nestedMenuItem.component';

const NestedMenu = ({
  label,
  options,
  menuItemKey,
  customActions = [],
  menuItemHighlightedStyle,
  subMenuIcon,
  ButtonComponent,
  clientId,
}) => {
  const history = useHistory();

  const handleRedirect = route => history.push(route);

  if (!menuItemKey) {
    return (
      <MenuButton
        renderButton={(domRef, _opened, onKeyPress, onMouseDown) => (
          <ButtonComponent domRef={domRef} onKeyPress={onKeyPress} onMouseDown={onMouseDown} />
        )}
        menuZIndex={10000}
        menu={
          !options?.length ? null : (
            <Dropdown>
              <MenuList>
                {options.map(menu =>
                  menu.options.length ? (
                    <NestedMenu
                      {...menu}
                      key={menu.key}
                      customActions={customActions}
                      menuItemKey={menu.key}
                      menuItemHighlightedStyle={menuItemHighlightedStyle}
                      subMenuIcon={subMenuIcon}
                      clientId={clientId}
                    />
                  ) : (
                    <NestedMenuItem
                      handleRedirect={() => handleRedirect(menu.route)}
                      customActions={customActions}
                      key={menu.key}
                      menuItemKey={menu.key}
                      menuItemHighlightedStyle={menuItemHighlightedStyle}
                      route={`/${clientId}${menu.route}`}
                    >
                      {menu.label}
                    </NestedMenuItem>
                  ),
                )}
              </MenuList>
            </Dropdown>
          )
        }
      >
        {label}
      </MenuButton>
    );
  }

  return (
    <SubMenuItem
      menuZIndex={10000}
      className="a-header-wrapper nested-menu"
      highlightedStyle={menuItemHighlightedStyle}
      menu={
        <Dropdown>
          <MenuList>
            {options.map(menu =>
              menu.options.length ? (
                <NestedMenu
                  {...menu}
                  key={`${menuItemKey}.${menu.key}`}
                  customActions={customActions}
                  menuItemKey={`${menuItemKey}.${menu.key}`}
                  menuItemHighlightedStyle={menuItemHighlightedStyle}
                  subMenuIcon={subMenuIcon}
                  clientId={clientId}
                />
              ) : (
                <NestedMenuItem
                  handleRedirect={() => handleRedirect(menu.route)}
                  key={`${menuItemKey}.${menu.key}`}
                  customActions={customActions}
                  menuItemKey={`${menuItemKey}.${menu.key}`}
                  menuItemHighlightedStyle={menuItemHighlightedStyle}
                  hasBottomSeparator={menu.hasBottomSeparator}
                  route={`/${clientId}${menu.route}`}
                >
                  {menu.label}
                </NestedMenuItem>
              ),
            )}
          </MenuList>
        </Dropdown>
      }
    >
      {label}
      {subMenuIcon && subMenuIcon}
    </SubMenuItem>
  );
};

NestedMenu.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object.isRequired),
  menuItemKey: PropTypes.string,
  customActions: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      customAction: PropTypes.func.isRequired,
    }),
  ),
  menuItemHighlightedStyle: PropTypes.object,
  subMenuIcon: PropTypes.node,
  ButtonComponent: PropTypes.func,
  clientId: PropTypes.string,
};

export default NestedMenu;
