import {
  SELECT_DATA_ITEM,
  SELECT_DATASET,
  SELECT_DATA_MODEL,
  SET_STATE_FROM_EXPRESSION_CALCULATION,
} from '../../../shared/store/dataModels/types';

import {
  SET_ACTIVE_TAB,
  RESET_ACTIVE_TAB,
  SET_ACTIVE_FORM_DATA_ITEM_ID,
  SET_SPLIT_PANE_LEFT_WIDTH,
  SET_IS_IGNORING_CALCULATION_DATA_CHANGES,
  SELECT_EXPRESSION_BUILDER_DATA_ITEM,
  SELECT_EXPRESSION_BUILDER_DATASET,
  SELECT_EXPRESSION_BUILDER_DATA_MODEL,
} from './types';

export const setActiveTab = payload => ({
  type: SET_ACTIVE_TAB,
  payload,
});

export const resetActiveTab = () => ({
  type: RESET_ACTIVE_TAB,
});

export const setSplitPaneLeftWidth = payload => ({
  type: SET_SPLIT_PANE_LEFT_WIDTH,
  payload,
});

export const setActiveFormDataItemId = activeFormDataItemId => ({
  type: SET_ACTIVE_FORM_DATA_ITEM_ID,
  payload: activeFormDataItemId,
});

export const setIsIgnoringCalculationDataChanges = isIgnoringCalculationDataChanges => (
  dispatch,
  getState,
) => {
  const {
    shared: { dataModels: sharedDataModelsState },
  } = getState();

  dispatch({
    type: SET_IS_IGNORING_CALCULATION_DATA_CHANGES,
    payload: {
      isIgnoringCalculationDataChanges,
      sharedDataModelsState,
    },
  });
};

const checkIfIgnoringThenDispatch = (dispatch, isIgnoring, callbackWithDispatches) => {
  if (isIgnoring) {
    callbackWithDispatches();
  }

  dispatch({
    type: SET_IS_IGNORING_CALCULATION_DATA_CHANGES,
    payload: {
      isIgnoringCalculationDataChanges: false,
    },
  });
};

export const selectDataItem = dataItemId => (dispatch, getState) => {
  const {
    development: { expressionBuilder: expressionBuilderState },
  } = getState();

  dispatch({
    type: SELECT_DATA_ITEM,
    payload: dataItemId,
  });

  checkIfIgnoringThenDispatch(
    dispatch,
    expressionBuilderState.isIgnoringCalculationDataChanges,
    () => {
      dispatch({
        type: SELECT_EXPRESSION_BUILDER_DATA_ITEM,
        payload: dataItemId,
      });
      dispatch({
        type: SET_STATE_FROM_EXPRESSION_CALCULATION,
        payload: {
          ...expressionBuilderState,
          dataItemId,
        },
      });
    },
  );
};

export const selectDataset = datasetId => (dispatch, getState) => {
  const {
    development: { expressionBuilder: expressionBuilderState },
  } = getState();

  dispatch({
    type: SELECT_DATASET,
    payload: datasetId,
  });

  checkIfIgnoringThenDispatch(
    dispatch,
    expressionBuilderState.isIgnoringCalculationDataChanges,
    () => {
      dispatch({
        type: SELECT_EXPRESSION_BUILDER_DATASET,
        payload: datasetId,
      });
      dispatch({
        type: SET_STATE_FROM_EXPRESSION_CALCULATION,
        payload: {
          ...expressionBuilderState,
          datasetId,
          dataItems: [],
          dataItemId: null,
        },
      });
    },
  );
};

export const selectDataModel = dataModelId => (dispatch, getState) => {
  const {
    development: { expressionBuilder: expressionBuilderState },
  } = getState();

  dispatch({
    type: SELECT_DATA_MODEL,
    payload: dataModelId,
  });

  checkIfIgnoringThenDispatch(
    dispatch,
    expressionBuilderState.isIgnoringCalculationDataChanges,
    () => {
      dispatch({
        type: SELECT_EXPRESSION_BUILDER_DATA_MODEL,
        payload: dataModelId,
      });
      dispatch({
        type: SET_STATE_FROM_EXPRESSION_CALCULATION,
        payload: {
          ...expressionBuilderState,
          dataModelId,
          datasetId: null,
          dataItems: [],
          dataItemId: null,
        },
      });
    },
  );
};
