const Yup = require('yup');

const { dataTypes } = require('../constants');

const { stateOverrideValueSchema } = require('./stateOverrideValue.validation');

const insertInfoDataSchema = Yup.object().shape({
  taxYear: Yup.string().required(),
  period: Yup.string().required(),
  orgId: Yup.string().required(),
  jurisdictionId: Yup.string().required(),
});

const updateFormFieldsSchema = Yup.object()
  .shape({
    rows: Yup.array().of(
      Yup.object()
        .shape({
          taxYear: Yup.number().required(),
          period: Yup.number().required(),
          infoAcctId: Yup.string().required(),
          orgId: Yup.string().required(),
          jurisdictionId: Yup.string().required(),
          dataType: Yup.string().nullable().oneOf(Object.values(dataTypes)),
          value: stateOverrideValueSchema,
        })
        .required(),
    ),
  })
  .strict();

module.exports = { insertInfoDataSchema, updateFormFieldsSchema };
