import React from 'react';
import {
  ColDef,
  ColGroupDef,
  ICellEditorParams,
  ICellRendererParams,
  RowNode,
  CellClassParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { Routes, taxNames } from '@common-packages/routes-definitions';
import { Tooltip } from '@pwc/appkit-react';

import { filingTypes, FILING_METHOD_OPTIONS } from '../../shared/constants';
import { FilingMethods, TaxReturnStatus } from '../../shared/enums';
import {
  adminColumnDefinition,
  currencyColumnDefinition,
  defaultLockPinnedColumnDefinitionWithFilter,
  mapValueFormatterFactory,
  selectCellEditorParamsFactory,
  disabledReadOnlyCheckboxColumnDefinitions,
} from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  LinkCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import {
  FindTaxReturnsColumnsBlueprint,
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
  FindReturnStatusDefinition,
} from '../../../../common/types';
import getColumnBlueprintBasedColumnDefinitions from '../../shared/columnDefinitions/columnsBlueprint.columnDefinition';
import { CATEGORY_NAMES } from '../../shared/enums/filingDecisions';
import { UpdateRowFunc } from '../../shared/editMode/types';
import { defaultAgRichSelectCellEditorSelector } from '../../shared/columnDefinitions/cellEditor';
import { cellRenderer } from '../../shared/columnDefinitions/cellRenderers/cellRenderer.component';
import { dueDateCellRenderer } from '../../shared/columnDefinitions/cellRenderers/dueDateCellRenderer.component';
import ParagraphTooltipRender from '../../shared/columnDefinitions/cellRenderers/ParagraphTooltip.component';
import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { getIsReturnStatusEditable } from '../../shared/taxReturns/utils/getIsReturnStatusEditable';
import getValueByPropertyPath from '../../utils/getValueByPropertyPath';

const DisplayFilingFormRenderer = ({ data }: ICellRendererParams) => (
  <>
    {data?.taxReturn?.filingMethod === FilingMethods.NON_FILING ? (
      <Tooltip
        content="Member of a consolidated filing group"
        placement="top"
        mouseEnterDelay={500}
      >
        {data?.taxReturn?.formName} - Subsidiary
      </Tooltip>
    ) : (
      data?.taxReturn?.formName
    )}
  </>
);

export const getNonFilingDisplayCellClassRules = (propertyPath: string) => {
  return {
    'italicized-cell': ({ data }: CellClassParams) =>
      getValueByPropertyPath(propertyPath, data) === FilingMethods.NON_FILING,
    'gray-cell': ({ data }: CellClassParams) =>
      getValueByPropertyPath(propertyPath, data) === FilingMethods.NON_FILING,
  };
};

export const isReturnStatusEditable = (propertyPath?: string) => ({ data }: CellClassParams) =>
  getIsReturnStatusEditable({
    filingMethod: getValueByPropertyPath(propertyPath, data)?.filingMethod,
    efileStatus: getValueByPropertyPath(propertyPath, data)?.efileStatus,
  });

export const getDropDownCellClassRules = (propertyPath?: string) => ({
  'editable-dropdown-cell': isReturnStatusEditable(propertyPath),
});

const GroupMemberRender = ({ value, data }: ICellRendererParams) =>
  value === 'Yes' && (
    <Tooltip
      content={
        <div>
          {data.taxReturn?.filingGroupsIds?.map((id: string, index: number) => (
            <p key={`groupMemberTooltip-${index}`}>{id}</p>
          ))}
        </div>
      }
      placement="top"
    >
      <AppkitIcon icon="community" type="fill" size={14} />
    </Tooltip>
  );

const AmountLinkRenderer = (categoryNameKey: string) =>
  LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
      Routes.filingDecisionAmountDetailsV2.compiledRoute({
        taxYear,
        period,
        businessEntityId: entityId,
        jurisdictionId,
        filingTypeId: filingTypes.SEPARATE,
        categoryName: categoryNameKey,
      }),
    getRouterState: () => ({
      categoryNameForDisplay:
        CATEGORY_NAMES?.find(({ key }) => key === categoryNameKey)?.name || '',
    }),
  });

const IncomeTaxSummaryLinkRender = LinkCellRendererFactory({
  getText: ({ valueFormatted, data: { incomeTax } }) => (incomeTax && valueFormatted) || '',
  getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
    Routes.taxSummaries.compiledRoute({
      taxYear,
      period,
      businessEntityId: entityId,
      jurisdictionId,
      filingTypeId: filingTypes.SEPARATE,
      taxName: taxNames.INCOME_TAX,
    }),
});

const FranchiseTaxSummaryLinkRender = LinkCellRendererFactory({
  getText: ({ valueFormatted, data: { franchiseTax } }) => (franchiseTax && valueFormatted) || '',
  getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
    Routes.taxSummaries.compiledRoute({
      taxYear,
      period,
      businessEntityId: entityId,
      jurisdictionId,
      filingTypeId: filingTypes.SEPARATE,
      taxName: taxNames.FRANCHISE_TAX,
    }),
});

const getStaticColumnDefinitions = ({
  updateRow,
  taxReturnStatusOptions,
  shouldDisplayDueDate,
  shouldDisplayReturnStatus,
}: {
  updateRow: UpdateRowFunc<TaxReturnResultRowWithCalcOptionsAndFilingAttributes>;
  taxReturnStatusOptions?: FindReturnStatusDefinition;
  shouldDisplayDueDate: boolean;
  shouldDisplayReturnStatus: boolean;
}) => {
  const onCellValueChanged = ({ data }: RowNode) => {
    updateRow(data);
  };

  return [
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Jurisdiction',
      field: 'jurisdiction',
      pinned: true,
      lockPosition: true,
      width: 205,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'State Filing Group',
      field: 'taxReturn.filingGroupsIds',
      width: 175,
      hide: true,
      cellRenderer: ParagraphTooltipRender,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Federal As-Filed Filing Group',
      field: 'federalAsFiledFilingGroupId',
      width: 175,
      hide: true,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Group Member',
      field: 'filingGroupsIds',
      width: 80,
      maxWidth: 100,
      cellRenderer: GroupMemberRender,
      valueGetter: ({
        data,
      }: ValueGetterParams<TaxReturnResultRowWithCalcOptionsAndFilingAttributes>) =>
        data?.taxReturn?.filingGroupsIds?.length ? 'Yes' : 'No',
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Filing Form',
      field: 'taxReturn.formName',
      width: 175,
      cellRendererFramework: DisplayFilingFormRenderer,
      cellClassRules: getNonFilingDisplayCellClassRules('taxReturn.filingMethod'),
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Filing Method',
      field: 'taxReturn.filingMethod',
      width: 120,
      valueFormatter: mapValueFormatterFactory(FILING_METHOD_OPTIONS),
      cellClassRules: getNonFilingDisplayCellClassRules('taxReturn.filingMethod'),
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Due Date',
      field: 'taxReturn.dueDate',
      width: 120,
      hide: !shouldDisplayDueDate,
      cellRenderer: dueDateCellRenderer,
      cellClassRules: getNonFilingDisplayCellClassRules('taxReturn.filingMethod'),
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Return Status',
      field: 'taxReturn.returnStatus',
      width: 120,
      hide: !shouldDisplayReturnStatus,
      editable: isReturnStatusEditable('taxReturn'),
      cellRenderer: (params: ICellRendererParams) =>
        cellRenderer({
          params,
          renderer: ({ data, value }: ICellRendererParams) => {
            if (data.taxReturn?.filingMethod === FilingMethods.NON_FILING) {
              return '';
            }
            return (taxReturnStatusOptions?.returnStatusOptions || []).find(
              option => option.value === value,
            )?.label;
          },
        }),
      cellEditorSelector: ({
        data,
      }: Omit<ICellEditorParams, 'data' | 'value'> & {
        data: TaxReturnResultRowWithCalcOptionsAndFilingAttributes;
        value: TaxReturnStatus | null;
      }) => {
        return {
          ...defaultAgRichSelectCellEditorSelector,
          params: selectCellEditorParamsFactory(
            data.taxReturn?.filingMethod === FilingMethods.EFILE
              ? taxReturnStatusOptions?.efileReturnStatusOptions
              : taxReturnStatusOptions?.returnStatusOptions,
            {},
            false,
          )(),
        };
      },
      filterParams: {
        valueFormatter: ({
          value,
        }: Omit<ICellEditorParams, 'value'> & {
          value: string;
        }) =>
          (taxReturnStatusOptions?.returnStatusOptions || []).find(
            option => option.value === parseInt(value, 10),
          )?.label,
      },
      cellClassRules: {
        ...getNonFilingDisplayCellClassRules('taxReturn.filingMethod'),
        ...getDropDownCellClassRules('taxReturn'),
      },
      onCellValueChanged,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Filing In State',
      field: 'filingInState',
      width: 80,
      hide: true,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Nexus',
      field: 'nexusIndicator',
      width: 85,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Income Amt',
      field: 'incomeAmount',
      cellRendererFramework: IncomeTaxSummaryLinkRender,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Franchise Amt',
      field: 'franchiseAmount',
      cellRendererFramework: FranchiseTaxSummaryLinkRender,
    },
    ...CATEGORY_NAMES.map(({ key, field, name }) => ({
      ...defaultLockPinnedColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: name,
      field,
      cellRendererFramework: AmountLinkRenderer(key),
      hide: key === 'GOVT_COP' || key === 'GOVT_MARKET',
    })),
  ];
};

const getColumnDefinitions = (
  columnsBlueprint: FindTaxReturnsColumnsBlueprint,
  shouldDisplayDueDate: boolean,
  shouldDisplayReturnStatus: boolean,
  updateRow: UpdateRowFunc<TaxReturnResultRowWithCalcOptionsAndFilingAttributes>,
  onAddIconClick: (data: ICellRendererParams['data']) => unknown,
  onEditIconClick: (data: ICellRendererParams['data']) => unknown,
  onDeleteIconClick: (data: ICellRendererParams['data']) => unknown,
  taxReturnStatusOptions?: FindReturnStatusDefinition,
  returnDefinitionJurisdictions?: string[],
): (ColDef | ColGroupDef)[] => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onAddIconClick,
    onEditIconClick,
    onDeleteIconClick,
    shouldDisableDeleteIcon: (row: TaxReturnResultRowWithCalcOptionsAndFilingAttributes) =>
      !row.taxReturn ||
      (row.taxReturn.filingMethod === FilingMethods.NON_FILING &&
        !row.hasMoreThanOneTaxReturnsInCurrentJurisdiction),
    shouldDisableAddIcon: (row: TaxReturnResultRowWithCalcOptionsAndFilingAttributes) =>
      !returnDefinitionJurisdictions?.some(jurisdictionId => jurisdictionId === row.jurisdictionId),
    shouldShowAddIcon: (row: TaxReturnResultRowWithCalcOptionsAndFilingAttributes) =>
      !row.taxReturn,
    shouldShowEditIcon: (row: TaxReturnResultRowWithCalcOptionsAndFilingAttributes) =>
      Boolean(row.taxReturn),
  });

  return [
    {
      ...adminColumnDefinition,
      cellRendererFramework: AdminCellRenderer,
      pinned: 'left',
      lockPinned: true,
      lockPosition: 'left',
      colId: 'adminColumn',
    },
    ...getStaticColumnDefinitions({
      updateRow,
      taxReturnStatusOptions,
      shouldDisplayDueDate,
      shouldDisplayReturnStatus,
    }),
    ...getColumnBlueprintBasedColumnDefinitions({
      columnsBlueprint,
      lockPinned: true,
    }),
  ];
};

export default getColumnDefinitions;
