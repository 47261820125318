import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../editMode';
import { showConfirmModal } from '../confirmModal/store/actions';
import { errorNotification, infoNotification } from '../notification/store/actions';
import { taxYearSelector, entityIdSelector, periodSelector } from '../store/selectors';
import useModal from '../hooks/useModal.hook';
import {
  pdfAttachmentsSelector,
  isFetchingPdfAttachmentsSelector,
  pdfImageSelector,
  isFetchingPdfImageSelector,
  isUpdatingOrDeletingPdfAttachmentsSelector,
} from '../store/pdfAttachments/selectors';
import {
  fetchPdfAttachments,
  fetchPdfImage,
  updateOrDeletePdfAttachments,
} from '../store/pdfAttachments/actions';
import { EVERYWHERE_JURISDICTION_ID } from '../constants';

import getColumnDefinitions from './pdfAttachments.columnDefinitions';
import { validateChanges } from './pdfAttachmentsBusinessRules';
import PdfDocumentModal from './pdfDocumentModal.component';
import AddPdfAttachmentModal from './addPdfAttachmentModal.container';

const HEADER_HEIGHT_TO_FIT_DESCRIPTIONS = 66;

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const PdfAttachments = ({
  taxYear,
  period,
  entityId,
  fetchPdfAttachments,
  isFetchingPdfAttachments,
  pdfAttachments,
  fetchPdfImage,
  isFetchingPdfImage,
  pdfImage,
  updateOrDeletePdfAttachments,
  isUpdatingOrDeletingPdfAttachments,
  showConfirmModal,
  errorNotification,
  infoNotification,

  hasUserPermissionsToEdit,

  match: { path },
}) => {
  const isContextReady = taxYear && period && entityId;
  const isLoading = isFetchingPdfAttachments || isUpdatingOrDeletingPdfAttachments;

  const reloadGrid = useCallback(() => {
    if (isContextReady) {
      fetchPdfAttachments({
        taxYear,
        period,
        entityId,
        jurisdictionId: EVERYWHERE_JURISDICTION_ID,
      });
    }
  }, [isContextReady, fetchPdfAttachments, taxYear, period, entityId]);

  useEffect(() => {
    reloadGrid();
  }, [reloadGrid]);

  const { showModal: showPdfModal, modalProps: pdfModalProps } = useModal();
  const { showModal: showPdfAttachmentModal, modalProps: pdfAttachmentModalProps } = useModal();

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges, rowsToDelete }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        documentId: oldRow.documentId,
        docPrintOrder: newRow.docPrintOrder,
        docDescription: newRow.docDescription,
        eFile: newRow.eFile,
        defRollover: newRow.defRollover,
        docRollover: newRow.docRollover,
      }));

      await updateOrDeletePdfAttachments({
        taxYear,
        period,
        entityId,
        jurisdictionId: EVERYWHERE_JURISDICTION_ID,
        rowsToUpdate,
        rowsToDelete,
      });

      reloadGrid();
    },
    [updateOrDeletePdfAttachments, taxYear, period, entityId, reloadGrid],
  );

  const validate = changes => {
    const validationResult = validateChanges(changes);

    if (!validationResult) {
      errorNotification(
        'A row exists with a rollover of an attachment without the related option to rollover the document definition. Please, select Rollover Attachment Def checkbox before saving.',
        { closeable: true },
      );
    }

    return validationResult;
  };

  const {
    navigationPrompt,
    clonedRowData,
    updateRow,
    deleteRow,
    onGridReady,
    isInEditMode,
    editModeButtons,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: pdfAttachments,
    getUniqueRowId,
    validateBeforeSave: validate,
    editButtonDisabled: !pdfAttachments.length || isLoading,
  });

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete PDF Attachment',
        text: 'Are you sure you want to delete this PDF attachment?',
        confirmCallback: () => deleteRow(row),
      });
    },
    [deleteRow, showConfirmModal],
  );

  const openModalForPdf = useCallback(
    ({ documentId }) => {
      fetchPdfImage({
        taxYear,
        period,
        entityId,
        jurisdictionId: EVERYWHERE_JURISDICTION_ID,
        documentId,
      });
      showPdfModal();
    },
    [entityId, fetchPdfImage, period, showPdfModal, taxYear],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        updateRow,
        onDeleteIconClick: openModalForDelete,
        onDocumentNameClick: openModalForPdf,
        path,
      }),
    [isInEditMode, updateRow, openModalForDelete, openModalForPdf, path],
  );

  return (
    <>
      {navigationPrompt}
      {hasUserPermissionsToEdit && (
        <div className="row">
          <div className="col add-button-column">
            <Button
              size="lg"
              className="add-button"
              onClick={showPdfAttachmentModal}
              disabled={!isContextReady || isLoading || isInEditMode}
            >
              Add
            </Button>
            {editModeButtons}
          </div>
        </div>
      )}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            headerHeight={HEADER_HEIGHT_TO_FIT_DESCRIPTIONS}
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            stopEditingWhenCellsLoseFocus
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
          />
        </div>
      </div>
      <PdfDocumentModal
        {...pdfModalProps}
        pdfImage={pdfImage}
        isFetchingPdfImage={isFetchingPdfImage}
        infoNotification={infoNotification}
      />
      <AddPdfAttachmentModal
        {...pdfAttachmentModalProps}
        pdfJurisdictionId={EVERYWHERE_JURISDICTION_ID}
      />
    </>
  );
};

PdfAttachments.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  fetchPdfAttachments: PropTypes.func.isRequired,
  fetchPdfImage: PropTypes.func.isRequired,
  pdfAttachments: PropTypes.arrayOf(
    PropTypes.shape({
      documentId: PropTypes.string,
      docName: PropTypes.string,
      docDescription: PropTypes.string,
      physicalLocation: PropTypes.string,
      docPrintOrder: PropTypes.number,
      eFile: PropTypes.bool,
      docRollover: PropTypes.bool,
    }),
  ),
  pdfImage: PropTypes.shape({
    size: PropTypes.number,
    type: PropTypes.string,
  }),
  isFetchingPdfAttachments: PropTypes.bool.isRequired,
  isFetchingPdfImage: PropTypes.bool.isRequired,
  updateOrDeletePdfAttachments: PropTypes.func.isRequired,
  isUpdatingOrDeletingPdfAttachments: PropTypes.bool.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  errorNotification: PropTypes.func.isRequired,
  infoNotification: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
  isCustomAttachment: PropTypes.bool,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }),
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    pdfAttachments: pdfAttachmentsSelector(state),
    pdfImage: pdfImageSelector(state),
    isFetchingPdfAttachments: isFetchingPdfAttachmentsSelector(state),
    isFetchingPdfImage: isFetchingPdfImageSelector(state),
    isUpdatingOrDeletingPdfAttachments: isUpdatingOrDeletingPdfAttachmentsSelector(state),
  }),
  {
    errorNotification,
    infoNotification,
    fetchPdfAttachments,
    fetchPdfImage,
    updateOrDeletePdfAttachments,
    showConfirmModal,
  },
)(PdfAttachments);
