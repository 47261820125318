import { ColDef } from 'ag-grid-community';

import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';

const getColumnDefinitions = (areAllJurisdictionsSelected: boolean): ColDef[] => [
  ...(areAllJurisdictionsSelected
    ? [{ ...defaultColumnDefinitionWithFilter, headerName: 'Jurisdiction', field: 'jurisdiction' }]
    : []),
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Data Model',
    field: 'dataModelName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'DataSet',
    field: 'dataSetName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'DataItem',
    field: 'dataItemName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Filing Type',
    field: 'expressionType',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Diagnostic Type',
    field: 'diagnosticType',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Diagnostic',
    field: 'validationMessage',
    width: 1200,
  },
];

export default getColumnDefinitions;
