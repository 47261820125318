import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { datasetMappingSchema } from '@common-packages/validators/schemas/taxFormsV2.validation';
import { Formik } from 'formik';
import { OverflowAction } from '@common-packages/shared-constants';

import {
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
  isFetchingOverflowActionTypesSelector,
  overflowActionTypesSelector,
} from '../../../../../shared/store/dataModels/selectors';
import {
  PDFAttachmentColumnPropertiesPropTypes,
  SelectedMappingFormPDFPageDatasetPropTypes,
} from '../propTypes';
import { PdfPageDataUsageTypes } from '../../../constants';
import { PrintOrientation } from '../constants';

import DatasetUsageForm from './datasetUsageForm.component';

const defaultFormValues = {
  instancesPerPage: '1',
  instToSkip: '0',
  overflowAction: OverflowAction.DATA_ERROR,
  uiLocationFieldName: null,

  printOrientationLandscape: PrintOrientation.PORTRAIT,
  fontSize: null,
  pdfStmtRefFieldName: null,
  pageWidthPercentage: null,
};

const DatasetUsage = ({
  isAttachmentOverflowAction,
  selectedDatasetFormikValues = null,
  selectedDataset = {},
  setValues,
  setIsFormDirty,
}) => {
  const formRef = useRef();

  const isFetchingOverflowActionTypes = useSelector(isFetchingOverflowActionTypesSelector);
  const isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs = useSelector(
    isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
  );

  const overflowActionTypes = useSelector(overflowActionTypesSelector);

  const isDatasetUsageSelected = selectedDataset.type === PdfPageDataUsageTypes.DATASET_USAGE;

  const initialValues = isDatasetUsageSelected ? { ...defaultFormValues, ...selectedDataset } : {};

  const isLoading =
    isFetchingOverflowActionTypes || isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs;

  const customChangeHandler = useCallback(
    (nameOrObjectToAppend, value) => {
      if (selectedDataset.id) {
        setIsFormDirty(true);
        if (typeof nameOrObjectToAppend === 'string') {
          setValues(previousValues => ({
            ...previousValues,
            overflowActions: {
              ...previousValues?.overflowActions,
              [selectedDataset.id]: {
                ...defaultFormValues,
                ...selectedDataset,
                ...selectedDatasetFormikValues,
                [nameOrObjectToAppend]: value,
              },
            },
          }));
        } else if (typeof nameOrObjectToAppend === 'object') {
          setValues(previousValues => ({
            ...previousValues,
            overflowActions: {
              ...previousValues?.overflowActions,
              [selectedDataset.id]: {
                ...defaultFormValues,
                ...selectedDataset,
                ...selectedDatasetFormikValues,
                ...nameOrObjectToAppend,
              },
            },
          }));
        }
      }
    },
    [setValues, setIsFormDirty, selectedDataset, selectedDatasetFormikValues],
  );

  useEffect(() => {
    if (formRef.current && isDatasetUsageSelected) {
      formRef.current.validateForm();
    }
  }, [isDatasetUsageSelected]);

  useEffect(() => {
    if (!selectedDatasetFormikValues && formRef.current) {
      formRef.current.resetForm();
    }
  }, [selectedDatasetFormikValues]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={selectedDatasetFormikValues || initialValues}
      validationSchema={datasetMappingSchema}
    >
      {({ values }) => (
        <DatasetUsageForm
          overflowActionTypes={overflowActionTypes}
          isAttachmentOverflowAction={isAttachmentOverflowAction}
          disabled={!isDatasetUsageSelected}
          isLoading={isLoading}
          customChangeHandler={customChangeHandler}
          selectComponentsValues={{
            overflowAction: values.overflowAction,
            printOrientationLandscape:
              values.printOrientationLandscape || defaultFormValues.printOrientationLandscape,
          }}
        />
      )}
    </Formik>
  );
};

DatasetUsage.propTypes = {
  selectedMappingFormPDFPageDataset: SelectedMappingFormPDFPageDatasetPropTypes,
  isAttachmentOverflowAction: PropTypes.bool.isRequired,
  selectedDatasetFormikValues: PDFAttachmentColumnPropertiesPropTypes,
  selectedDataset: PDFAttachmentColumnPropertiesPropTypes,
  setValues: PropTypes.func.isRequired,
  setIsFormDirty: PropTypes.func.isRequired,
};

export default DatasetUsage;
