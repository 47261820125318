import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './stepTab.module.scss';

interface StepTabWrapperProps {
  children: ReactNode;
  className?: string;
}

const StepTabWrapper = ({ className = '', children }: StepTabWrapperProps) => {
  return <div className={classnames(styles.tab, className)}>{children}</div>;
};

export default StepTabWrapper;
