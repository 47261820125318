import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Input } from '@pwc/appkit-react';
import isNil from 'lodash.isnil';
import { DOMAIN_TYPE } from '@common-packages/shared-constants';

import Loading from '../../shared/displayComponents/loading.component';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import { globalContextSelector } from '../../shared/store/selectors';
import { runTTIStep, fetchSignatureDeclaration } from '../store/actions';
import { hasPermissionToEfileRunSelector } from '../../shared/store/customerData.selectors';
import {
  eFileStepsDataSelector,
  isFetchingEFileStepsDataSelector,
  signatureDeclarationSelector,
  isFetchingSignatureDeclarationSelector,
  persistedWizardFieldsValuesSelector,
} from '../store/selectors';
import styles from '../styles.module.scss';

import { EFILE_AUTHORIZE_STEPS, stateSubmissionOptions } from './constants';
import { getGenerateTransmissionSteps } from './getStepsData';
import SubStep from './subStep.component';
import { isSubStepCompleted } from './stepValidation';
import { stepDataPropTypes, contextDebugInfoPropTypes } from './currentReturnFiling.propTypes';
import useTransmitStepAndTTISubmissionId from './useTransmitStepAndTTISubmissionId';
import { wizardStatePropTypes } from './useWizard.hook';

const DEFAULT_SIGNATURE_DECLARATION =
  'This state does not require a signature declaration approval. Enter a signature to authorize this state transmission.';

const AuthorizeStep = ({
  binderFilingId,
  globalContext,

  fetchSignatureDeclaration,
  signatureDeclaration,
  isFetchingSignatureDeclaration,

  ttiYearId,
  ttiCaseId,
  selectedTtiBinderId,
  efServiceSubmissionId,

  eFileStepsData,
  fetchStepsData,
  isFetchingEFileStepsData,
  runTTIStep,

  contextDebugInfo,
  hasPermissionToRun,

  isContextLocked,
  isFetchingTtiBinders,

  persistedWizardFieldsValues,

  wizardState,
  setStepStatus,

  stateSubmissionType,
  includeFederal,
  federalProformaSubmissionId,
  isFetchingProformaSubmissionId,
}) => {
  const [acceptedLegalVerbiage, setAcceptedLegalVerbiage] = useState(false);
  const [ignoreErrors, setIgnoreErrors] = useState(false);
  const toggleIgnoreErrors = useCallback(() => setIgnoreErrors(!ignoreErrors), [ignoreErrors]);

  const [signatureValue, setSignatureValue] = useState('');
  useEffect(() => {
    const { persistedSignatureValue } = persistedWizardFieldsValues;

    if (persistedSignatureValue) {
      setSignatureValue(persistedSignatureValue);
    }
  }, [persistedWizardFieldsValues]);

  const { currentStep, isWizardActionRunning } = wizardState;
  const { ttiSubmissionId, transmitStepsData } = useTransmitStepAndTTISubmissionId({
    ttiYearId,
    ttiCaseId,
    selectedTtiBinderId,
    efServiceSubmissionId,
    eFileStepsData,
    stateSubmissionType,
  });

  const handleSignatureChange = useCallback(value => setSignatureValue(value), []);

  const toggleAcceptedLegalVerbiage = useCallback(() => {
    setAcceptedLegalVerbiage(!acceptedLegalVerbiage);
  }, [acceptedLegalVerbiage]);

  const isSubmissionCreated = useMemo(
    () =>
      isSubStepCompleted(
        {
          actionsBatch: 'create-state-transmission-file',
          stepData: transmitStepsData,
        },
        getGenerateTransmissionSteps({
          taxYear: globalContext.taxYear,
          jurisdictionId: globalContext.jurisdictionId,
          filingTypeId: globalContext.filingTypeId,
        }),
      ),
    [
      transmitStepsData,
      globalContext.taxYear,
      globalContext.jurisdictionId,
      globalContext.filingTypeId,
    ],
  );

  const isSubmissionAuthorized = useMemo(
    () =>
      isSubStepCompleted(
        {
          actionsBatch: 'authorize-sign-and-submit',
          stepData: transmitStepsData,
        },
        EFILE_AUTHORIZE_STEPS,
      ),
    [transmitStepsData],
  );

  const setIsActionRunning = useCallback(
    value => {
      setStepStatus({
        currentStep,
        payload: {
          isStepActionRunning: value,
        },
      });
    },
    [setStepStatus, currentStep],
  );

  const [isDeclarationFetched, setIsDeclarationFetched] = useState(false);

  const fetchDeclaration = useCallback(async () => {
    const isReady =
      ttiYearId && ttiCaseId && selectedTtiBinderId && efServiceSubmissionId && isSubmissionCreated;

    if (isReady) {
      setIsActionRunning(true);
      await fetchSignatureDeclaration({
        yearOrSubPeriodId: ttiYearId,
        caseId: ttiCaseId,
        binderId: selectedTtiBinderId,
        submissionId: efServiceSubmissionId,
        domainType: DOMAIN_TYPE,
      });
      setIsDeclarationFetched(true);
      setIsActionRunning(false);
    }
  }, [
    fetchSignatureDeclaration,
    setIsActionRunning,
    ttiYearId,
    ttiCaseId,
    selectedTtiBinderId,
    efServiceSubmissionId,
    isSubmissionCreated,
  ]);

  useEffect(() => {
    fetchDeclaration();
  }, [fetchDeclaration]);

  const callSignAndSubmitAction = useCallback(
    async ({ action }) => {
      setIsActionRunning(true);
      await runTTIStep({
        action,
        globalContext,
        binderFilingId,
        ttiYearId,
        ttiCaseId,
        binderId: selectedTtiBinderId,
        submissionId: efServiceSubmissionId,
        domainType: DOMAIN_TYPE,
        stateSubmissionType,
        signatureValue,
        acceptedLegalVerbiage,
        ignoreErrors,
      });
      setIsActionRunning(false);
      fetchStepsData();
    },
    [
      runTTIStep,
      fetchStepsData,
      setIsActionRunning,
      globalContext,
      binderFilingId,
      ttiYearId,
      ttiCaseId,
      selectedTtiBinderId,
      efServiceSubmissionId,
      stateSubmissionType,
      signatureValue,
      acceptedLegalVerbiage,
      ignoreErrors,
    ],
  );

  const disabled =
    isWizardActionRunning || isFetchingEFileStepsData || !ttiCaseId || isNil(selectedTtiBinderId);

  const isRunButtonDisabled =
    disabled ||
    isSubmissionAuthorized ||
    !signatureValue ||
    !signatureDeclaration ||
    !acceptedLegalVerbiage;

  return (
    <>
      <div className={styles.returnContext}>
        <div className={styles.ttiSubmissionIdField}>
          <label className="a-form-label">State Submission ID</label>
          <input className="a-input-text" value={ttiSubmissionId || ''} disabled />
        </div>
        <ParamDropdown
          disabled
          label="State Submission Type"
          options={stateSubmissionOptions}
          value={stateSubmissionType}
          isFetchingContext={isWizardActionRunning}
        />
        <div className={styles.align}>
          <Checkbox checked={includeFederal} disabled>
            <span className="a-form-label">Include Federal</span>
          </Checkbox>
          {includeFederal && (
            <div className={styles.federalProformaSubmissionId}>
              <label className="a-form-label">Federal Submission ID</label>
              <Loading small isLoading={isFetchingProformaSubmissionId}>
                <Input disabled value={federalProformaSubmissionId} />
              </Loading>
            </div>
          )}
        </div>
      </div>

      {isSubmissionCreated && (
        <div className={styles.signatureDeclaration}>
          <label className="a-form-label">Signature Declaration</label>
          <div className={styles.wizardStepAdditionalInfo}>
            <div>
              <Loading isLoading={isFetchingSignatureDeclaration || !isDeclarationFetched}>
                {isDeclarationFetched && (
                  <>
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          // setting inner html because signatureDeclaration may contain &nsbp; chars
                          __html: signatureDeclaration || DEFAULT_SIGNATURE_DECLARATION,
                        }}
                      />
                    </div>
                    <Checkbox
                      checked={acceptedLegalVerbiage}
                      onChange={toggleAcceptedLegalVerbiage}
                      disabled={
                        !signatureDeclaration || isWizardActionRunning || isSubmissionAuthorized
                      }
                    >
                      By checking this box I confirm that I have read and agree to the above
                      statement.
                    </Checkbox>
                    <div>
                      <label className="a-form-label">Signature</label>
                      <Input
                        value={signatureValue}
                        onChange={handleSignatureChange}
                        disabled={isWizardActionRunning || isSubmissionAuthorized}
                      />
                    </div>
                  </>
                )}
              </Loading>
            </div>
          </div>
        </div>
      )}

      <SubStep
        contextDebugInfo={contextDebugInfo}
        steps={EFILE_AUTHORIZE_STEPS}
        isSubmittingAnyAction={wizardState[currentStep].isStepActionRunning}
        isLoading={isFetchingEFileStepsData || isFetchingTtiBinders}
        disableNextStep={isRunButtonDisabled}
        isFetchingEFileStepsData={isFetchingEFileStepsData}
        hasPermissionToRun={hasPermissionToRun}
        actionsBatch="authorize-sign-and-submit"
        stepData={transmitStepsData}
        callAction={callSignAndSubmitAction}
        isLocked={isContextLocked}
      >
        <div>
          <Checkbox
            checked={ignoreErrors}
            onChange={toggleIgnoreErrors}
            disabled={isWizardActionRunning || isSubmissionAuthorized}
          >
            Ignore errors
          </Checkbox>
        </div>
      </SubStep>
      {isSubmissionAuthorized && (
        <div className={styles.authorizationWarning}>The submission ID is already authorized.</div>
      )}
    </>
  );
};

AuthorizeStep.propTypes = {
  binderFilingId: PropTypes.number,
  globalContext: globalContextPropTypes,

  ttiYearId: PropTypes.string,
  ttiCaseId: PropTypes.string,
  selectedTtiBinderId: PropTypes.number,
  efServiceSubmissionId: PropTypes.string,

  eFileStepsData: PropTypes.arrayOf(stepDataPropTypes).isRequired,
  fetchStepsData: PropTypes.func.isRequired,
  isFetchingEFileStepsData: PropTypes.bool.isRequired,
  runTTIStep: PropTypes.func.isRequired,
  hasPermissionToRun: PropTypes.bool.isRequired,

  fetchSignatureDeclaration: PropTypes.func.isRequired,
  signatureDeclaration: PropTypes.arrayOf(PropTypes.string),
  isFetchingSignatureDeclaration: PropTypes.bool.isRequired,

  contextDebugInfo: contextDebugInfoPropTypes,

  isContextLocked: PropTypes.bool.isRequired,
  isFetchingTtiBinders: PropTypes.bool.isRequired,

  persistedWizardFieldsValues: PropTypes.shape({
    persistedStateSubmissionType: PropTypes.string,
    persistedIncludeFederal: PropTypes.bool,
    persistedFederalProformaSubmissionId: PropTypes.string,
    persistedSignatureValue: PropTypes.string,
    persistedIsNewYorkFormConfirmed: PropTypes.bool,
  }).isRequired,

  wizardState: wizardStatePropTypes,
  setStepStatus: PropTypes.func.isRequired,

  stateSubmissionType: PropTypes.string.isRequired,
  includeFederal: PropTypes.bool.isRequired,
  federalProformaSubmissionId: PropTypes.string.isRequired,
  isFetchingProformaSubmissionId: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    globalContext: globalContextSelector(state),

    eFileStepsData: eFileStepsDataSelector(state),
    isFetchingEFileStepsData: isFetchingEFileStepsDataSelector(state),

    signatureDeclaration: signatureDeclarationSelector(state),
    isFetchingSignatureDeclaration: isFetchingSignatureDeclarationSelector(state),
    hasPermissionToRun: hasPermissionToEfileRunSelector(state),

    persistedWizardFieldsValues: persistedWizardFieldsValuesSelector(state),
  }),
  {
    runTTIStep,
    fetchSignatureDeclaration,
  },
)(AuthorizeStep);
