import React from 'react';
import { useParams, Switch, Route } from 'react-router-dom';
import { Routes, taxNames, isValidTaxName } from '@common-packages/routes-definitions';

import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import DataInfo from '../shared/displayComponents/dataInfo/dataInfo.component';

import TaxSummaries from './taxSummaries.container';
import TaxSummariesRoute from './routeConfiguration/taxSummariesRoute.container';
import TaxRateDetails from './taxRateDetails/taxRateDetails.container';
import GenericCategory from './genericDrill/genericCategory.container';
import ApportionmentRateDetails from './apportionmentRateDetails/apportionmentRateDetails.container';
import PropertySummary from './propertySummary/propertySummary.container';
import CustomizedScreenForAmountWithContext from './genericDrill/customizedScreenForAmount/customizedScreenForAmount.container';
import CustomizedScreenForComponentDrill from './genericDrill/customizedScreenForAmount/customizedScreenForComponentDrill.container';

const TaxSummariesWithContext = withContextWrapper()(TaxSummaries);
const TaxRateDetailsWithContext = withContextWrapper()(TaxRateDetails);
const ApportionmentRateDetailsWithContext = withContextWrapper()(ApportionmentRateDetails);
const PropertySummaryWithContext = withContextWrapper()(PropertySummary);
const GenericCategoryWithContext = withContextWrapper()(GenericCategory);
const CustomizedScreenForComponentDrillWithContext = withContextWrapper()(
  CustomizedScreenForComponentDrill,
);

const TaxSummariesRoutes = () => {
  const { taxName } = useParams();

  if (!isValidTaxName(taxName)) {
    return (
      <DataInfo
        textToRender={`invalid taxName:${taxName} value in url. Please use one from allowed: ${Object.values(
          taxNames,
        )}`}
      />
    );
  }

  return (
    <Switch>
      <Route
        path={Routes.taxSummaries.MAIN}
        render={() => (
          <>
            <TaxSummariesRoute
              exact
              path={Routes.taxSummaries.MAIN}
              component={TaxSummariesWithContext}
            />
            <TaxSummariesRoute
              exact
              path={Routes.taxRateDetails.MAIN}
              component={TaxRateDetailsWithContext}
            />
            <TaxSummariesRoute
              exact
              path={Routes.apportionmentRateDetails.MAIN}
              component={ApportionmentRateDetailsWithContext}
            />
            <TaxSummariesRoute
              exact
              path={Routes.propertySummary.MAIN}
              component={PropertySummaryWithContext}
            />
            <TaxSummariesRoute
              exact
              path={Routes.genericDrillCategory.MAIN}
              component={GenericCategoryWithContext}
            />
            <TaxSummariesRoute
              exact
              path={Routes.genericDrillAccount.MAIN}
              component={CustomizedScreenForAmountWithContext}
            />
            <TaxSummariesRoute
              exact
              path={Routes.genericDrillComponent.MAIN}
              component={CustomizedScreenForComponentDrillWithContext}
            />
          </>
        )}
      />
    </Switch>
  );
};

export default TaxSummariesRoutes;
