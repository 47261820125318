import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@pwc/appkit-react';

import FaIcon from '../../../shared/displayComponents/faIcon/faIcon.component';

import styles from './cellTooltip.module.scss';

const CellTooltip = ({ message, isCellEmpty = false }) => (
  <Tooltip content={message} tooltipTheme="light" disabled={!message} offset="0, 5">
    {message && (
      <FaIcon
        className={isCellEmpty ? styles.invalidIconEmptyCell : styles.invalidIcon}
        icon="info-circle"
        size={12}
      />
    )}
  </Tooltip>
);

CellTooltip.propTypes = {
  message: PropTypes.string,
  isCellEmpty: PropTypes.bool,
};

export default CellTooltip;
