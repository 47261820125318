import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';
import {
  fetchConsolCalcSpecsTypes,
  updateConsolCalcSpecsTypes,
  createConsolCalcSpecsTypes,
} from '../types';

export const consolCalcSpecsTypeInitialState = {
  calcSpecs: {
    columnsBlueprint: [],
    data: [],
  },
  isFetchingCalcSpecs: false,

  isUpdatingCalcSpecs: false,

  isCreatingCalcSpecs: false,
};

const consolCalcSpecsTypeReducer = reducerFactory({
  initialState: consolCalcSpecsTypeInitialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchConsolCalcSpecsTypes,
      payloadKey: 'calcSpecs',
      fetchingKey: 'isFetchingCalcSpecs',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateConsolCalcSpecsTypes,
      fetchingKey: 'isUpdatingCalcSpecs',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createConsolCalcSpecsTypes,
      fetchingKey: 'isCreatingCalcSpecs',
    }),
  },
});

export default consolCalcSpecsTypeReducer;
