/* eslint-disable babel/no-invalid-this */
// we need to use `this` to access several yup properties
const Yup = require('yup');

Yup.addMethod(
  Yup.string,
  'uniqueInList',
  function ({
    message = '',
    list = [],
    mapper = value => value,
    shouldSkipTest = false,
    checkDuplicatesFunction,
  }) {
    return this.test('uniqueInList', message, function (value) {
      if (shouldSkipTest) {
        return true;
      }

      const checkDuplicates =
        checkDuplicatesFunction && typeof checkDuplicatesFunction === 'function'
          ? checkDuplicatesFunction
          : element => element === value;

      const isDuplicated = list
        .map(mapper)
        .some(element => checkDuplicates.call(this, element, value));

      if (isDuplicated) {
        throw this.createError({
          path: `${this.path}`,
        });
      }
      return true;
    });
  },
);

Yup.addMethod(
  Yup.string,
  'existsInList',
  function ({
    message = '',
    list = [],
    mapper = value => value,
    shouldSkipTest = false,
    checkDuplicatesFunction,
  }) {
    return this.test('existsInList', message, function (value) {
      if (shouldSkipTest) {
        return true;
      }

      const checkDuplicates =
        checkDuplicatesFunction && typeof checkDuplicatesFunction === 'function'
          ? checkDuplicatesFunction
          : element => element === value;

      const isDuplicated = list
        .map(mapper)
        .some(element => checkDuplicates.call(this, element, value));

      if (!isDuplicated) {
        throw this.createError({
          path: `${this.path}`,
        });
      }
      return true;
    });
  },
);

Yup.addMethod(Yup.string, 'isEmptyAfterTrim', function ({ message = '' } = {}) {
  const errorMessage = message || `${this.spec?.label || this.path} is a required field`;
  return this.test('isEmptyAfterTrim', errorMessage, function (value) {
    if (!value?.trim()) {
      throw this.createError({
        path: `${this.path}`,
        message: errorMessage,
      });
    }
    return true;
  });
});
