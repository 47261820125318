import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import Modal from '@tls/ui-modal';
import { partnershipAllocationPercentagesSchemas } from '@common-packages/validators';

import AddOwnerForm from './addOwnerForm.component';
import { YES } from './constants';
import { partnershipAllocationPercentagesPropTypes } from './propTypes';

const initialValues = {
  partnerId: '',
  percentageType: '',
  percentage: '',
  plugOwner: YES,
};

const AddOwnerModal = ({
  hideModal,
  resetForm,
  visible,
  handleSubmit,
  isFetchingPartners,
  isFetchingPercentageTypes,
  isUpdatingOwnership,
  partnershipAllocationPercentages,

  ...formProps
}) => {
  const onCancel = useCallback(() => {
    hideModal();
    resetForm();
  }, [resetForm, hideModal]);

  return (
    <Modal
      title="Add Owner"
      closeAction={onCancel}
      visible={visible}
      submitText="Save"
      submitAction={handleSubmit}
      dismissText="Cancel"
      dismissAction={onCancel}
      isBusy={isFetchingPartners || isFetchingPercentageTypes || isUpdatingOwnership}
    >
      <AddOwnerForm
        isFetchingPartners={isFetchingPartners}
        isFetchingPercentageTypes={isFetchingPercentageTypes}
        isUpdatingOwnership={isUpdatingOwnership}
        partnershipAllocationPercentages={partnershipAllocationPercentages}
        {...formProps}
      />
    </Modal>
  );
};

AddOwnerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  updateOwnership: PropTypes.func.isRequired,
  isFetchingPercentageTypes: PropTypes.bool.isRequired,
  isFetchingPartners: PropTypes.bool.isRequired,
  isUpdatingOwnership: PropTypes.bool.isRequired,
  partnershipAllocationPercentages: PropTypes.arrayOf(partnershipAllocationPercentagesPropTypes),
};

export default withFormik({
  mapPropsToValues: ({ entityId, entityName }) => ({
    ...initialValues,
    entityId,
    entityName,
  }),
  enableReinitialize: true,
  handleSubmit: async (values, formikProps) => {
    const { props, resetForm } = formikProps;
    const { taxYear, period, updateOwnership, hideModal, saveCallback } = props;
    // eslint-disable-next-line no-unused-vars
    const { entityName: _entityName, legalClassification: _legalClassification, ...owner } = values;

    await updateOwnership({ taxYear, period, owner });

    hideModal();
    resetForm();
    saveCallback();
  },
  validateOnBlur: false,
  validationSchema: ({ partnershipAllocationPercentages }) =>
    partnershipAllocationPercentagesSchemas.getOwnerSchema({ partnershipAllocationPercentages }),
})(AddOwnerModal);
