import { useEffect } from 'react';

const useAppWithoutNavigator = () =>
  useEffect(() => {
    const navigatorContainer = document.getElementById('navigation-panel-root');
    navigatorContainer.style.display = 'none';

    return () => {
      navigatorContainer.style.display = 'block';
    };
  });

export default useAppWithoutNavigator;
