import { useEffect, useMemo } from 'react';
import { StateValue } from 'xstate';

import { useQueryGetReturnValidationResults } from '../../../shared/queries/eFileV2';
import { ValidationContext, WizardStateMachineContext } from '../wizardStateMachine/types';
import { WIZARD_MACHINE_STATES } from '../constants';
import { useGetTtiValidationErrors } from '../hooks';
import { StepStatus, WizardStepName } from '../enums';

import useBinder from './useBinder.hook';

interface UseValidationStepStatusHookParams {
  isFetchingSteps: boolean;
  areStepsFetched: boolean;
  setValidationContext: (validationContext: ValidationContext) => void;
  machineStateValue: StateValue;
  machineStateContext: WizardStateMachineContext;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
}

const useValidationStepStatus = ({
  isFetchingSteps,
  areStepsFetched,
  setValidationContext,
  machineStateValue,
  machineStateContext,
  isFetchingTaxReturnOrTransmitterProviderTR,
}: UseValidationStepStatusHookParams) => {
  const { sltBinderId } = useBinder();

  const shouldFetchValidationInfoDuringInit = useMemo(
    () =>
      machineStateValue === WIZARD_MACHINE_STATES.INITIALIZING &&
      areStepsFetched &&
      !isFetchingSteps &&
      Boolean(sltBinderId) &&
      machineStateContext.eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.COMPLETED,
    [machineStateValue, sltBinderId, areStepsFetched, isFetchingSteps, machineStateContext],
  );

  const { ttiValidationErrors, areTtiValidationErrorsFetched } = useGetTtiValidationErrors(
    shouldFetchValidationInfoDuringInit && !isFetchingTaxReturnOrTransmitterProviderTR,
    !isFetchingTaxReturnOrTransmitterProviderTR,
  );

  const {
    data: eFileSchemaValidationErrors,
    isFetched: areEFileSchemaValidationErrorsFetched,
  } = useQueryGetReturnValidationResults({
    params: { sltBinderId },
    enabled: shouldFetchValidationInfoDuringInit,
  });

  useEffect(() => {
    if (
      areStepsFetched &&
      !isFetchingSteps &&
      (areTtiValidationErrorsFetched || isFetchingTaxReturnOrTransmitterProviderTR) &&
      areEFileSchemaValidationErrorsFetched
    ) {
      setValidationContext({
        hasErrors: Boolean(ttiValidationErrors?.errors || eFileSchemaValidationErrors?.errors),
        isFetching: false,
      });
    }
  }, [
    setValidationContext,
    ttiValidationErrors,
    areTtiValidationErrorsFetched || isFetchingTaxReturnOrTransmitterProviderTR,
    areStepsFetched,
    isFetchingSteps,
    areEFileSchemaValidationErrorsFetched,
    eFileSchemaValidationErrors,
  ]);

  const isValidationStepCompletedAndErrorsAreFetched = useMemo(() => {
    if (!areStepsFetched) {
      return false;
    }

    return machineStateContext.eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.COMPLETED
      ? (areTtiValidationErrorsFetched || isFetchingTaxReturnOrTransmitterProviderTR) &&
          areEFileSchemaValidationErrorsFetched &&
          machineStateContext.validation.hasErrors !== null
      : true;
  }, [
    areStepsFetched,
    machineStateContext,
    areTtiValidationErrorsFetched || isFetchingTaxReturnOrTransmitterProviderTR,
    areEFileSchemaValidationErrorsFetched,
  ]);

  return {
    isValidationStepCompletedAndErrorsAreFetched,
    shouldFetchValidationInfoDuringInit,
  };
};

export default useValidationStepStatus;
