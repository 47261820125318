import {
  defaultColumnDefinitionWithFilter,
  adminColumnDefinition,
} from '../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({
  hasPermissionToDelete = false,
  onDeleteIconClick = () => null,
}) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: false,
  });

  return [
    ...(hasPermissionToDelete
      ? [
          {
            ...adminColumnDefinition,
            cellRenderer: AdminCellRenderer,
            pinned: 'left',
            lockPinned: true,
          },
        ]
      : []),
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name',
      field: 'name',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Rotation',
      field: 'rotation',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Font',
      field: 'fontSize',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Vertical Offset',
      field: 'verticalOffset',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Text Line 1',
      field: 'line1',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Text Line 2',
      field: 'line2',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Text Line 3',
      field: 'line3',
    },
  ];
};

export default getColumnDefinitions;
