import React from 'react';
import { connect } from 'react-redux';

import CustomRoute from '../../shared/displayComponents/customRoute.component';
import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';
import { isFetchingContextSelector } from '../../development/store/selectors';
import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';

import ToolsBreadcrumbRoute from './toolsBreadcrumbRoutes.component';

const ToolsPage = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
  breadcrumbRoutes: ToolsBreadcrumbRoute,
}))(GlobalContextPage);

const ToolsRoute = props => <CustomRoute wrapper={ToolsPage} hideGlobalContext {...props} />;

const AuthorizedToolsRoute = props => (
  <AuthorizedCustomRoute wrapper={ToolsPage} hideGlobalContext {...props} />
);

export { ToolsRoute, AuthorizedToolsRoute };
