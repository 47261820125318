import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';
import { dataModelSchemas } from '@common-packages/validators';

import { useEditModeLogic } from '../../../../shared/editMode';
import {
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
  updatePdfDataUsageAndAttachmentConfig,
  fetchPDFAttachmentColumnProperties,
} from '../../../../shared/store/dataModels/actions';
import {
  selectedMappingFormPDFPageDatasetSelector,
  overflowActionTypesSelector,
  pdfAttachmentColumnPropertiesSelector,
  isFetchingPDFAttachmentColumnPropertiesSelector,
} from '../../../../shared/store/dataModels/selectors';
import { taxFormSelector } from '../../store/selectors';
import { taxYearSelector, jurisdictionIdSelector } from '../../../store/selectors';
import { SelectOptionPropTypes } from '../../../../shared/forms/propTypes';
import styles from '../styles.module.scss';

import DatasetUsageForm from './DatasetUsageForm.component';
import { OVERFLOW_ACTION_ATTACHMENT } from './constants';

const OverflowActions = ({
  taxYear,
  jurisdictionId,
  taxForm,

  selectedMappingFormPDFPageDataset,
  overflowActionTypes,

  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
  updatePdfDataUsageAndAttachmentConfig,

  fetchPDFAttachmentColumnProperties,
  PDFAttachmentColumnProperties,
  isFetchingPDFAttachmentColumnProperties,

  goToDatasetTab,
}) => {
  const isAttachmentOverflowAction =
    selectedMappingFormPDFPageDataset &&
    selectedMappingFormPDFPageDataset.overflowAction === OVERFLOW_ACTION_ATTACHMENT;
  const datasetDefId =
    selectedMappingFormPDFPageDataset && selectedMappingFormPDFPageDataset.datasetId;
  const pageNumber =
    selectedMappingFormPDFPageDataset && selectedMappingFormPDFPageDataset.pageNumber;
  const pdfId = taxForm && taxForm.pdfId;

  useEffect(() => {
    if (isAttachmentOverflowAction) {
      fetchPDFAttachmentColumnProperties({
        datasetDefId,
        pageNumber,
        pdfId,
      });
    }
  }, [
    fetchPDFAttachmentColumnProperties,
    isAttachmentOverflowAction,
    datasetDefId,
    pageNumber,
    pdfId,
  ]);

  const {
    clonedRowData,
    addRow,
    updateRow,
    deleteRow,
    onGridReady,
    findChanges,
    revertChangesAndCancel,
  } = useEditModeLogic({
    rowData: PDFAttachmentColumnProperties,
    startInEditMode: true,
    getUniqueRowId: ({ data: { id } }) => id,
  });

  const addProperty = useCallback(() => {
    const newRow = {
      id: uuid(),
      dataItemName: '',
      description: '',
      displayName: '',
      itemDisplayOrder: 0,
      columnDisplayOrder: 0,
      screenDisplay: true,
      printDisplay: true,
      printColumnTotal: false,
      relativeWidth: 1,
      fontSize: 8,
    };

    addRow(newRow);
  }, [addRow]);

  const renderDatasetUsageForm = useCallback(
    formikProps => {
      const onCancelClick = () => {
        revertChangesAndCancel();
        formikProps.resetForm();
      };

      return (
        <DatasetUsageForm
          onCancelClick={onCancelClick}
          overflowActionTypes={overflowActionTypes}
          PDFAttachmentColumnProperties={clonedRowData}
          isFetchingPDFAttachmentColumnProperties={isFetchingPDFAttachmentColumnProperties}
          onGridReady={onGridReady}
          addRow={addProperty}
          deleteRow={deleteRow}
          updateRow={updateRow}
          {...formikProps}
        />
      );
    },
    [
      revertChangesAndCancel,
      overflowActionTypes,
      updateRow,
      deleteRow,
      addProperty,
      clonedRowData,
      isFetchingPDFAttachmentColumnProperties,
      onGridReady,
    ],
  );

  const savePage = useCallback(
    async (
      {
        id: pdfDataUsageId,
        instancesPerPage,
        instToSkip,
        overflowAction,
        fontSize,
        pageWidthPercentage,
        pdfStmtRefFieldName,
        printOrientationLandscape,
        uiLocationFieldName,
        includeConsMembers,
        calcPreferenceOn,
        calcPreferenceName,
      },
      { setSubmitting },
    ) => {
      setSubmitting(true);

      const attachmentColumnPropertiesChanges = findChanges();

      await updatePdfDataUsageAndAttachmentConfig({
        taxYear,
        jurisdictionId,
        pdfDataUsageId,
        instancesPerPage,
        instToSkip,
        overflowAction,
        fontSize,
        pageWidthPercentage,
        pdfStmtRefFieldName,
        printOrientationLandscape,
        attachmentColumnPropertiesChanges,
        uiLocationFieldName,
        includeConsMembers,
        calcPreferenceOn,
        calcPreferenceName,
      });
      setSubmitting(false);
      fetchMappingFormPDFPageDataUsageAndAttachmentConfigs({ pdfId: taxForm.pdfId });
      goToDatasetTab();
    },
    [
      findChanges,
      updatePdfDataUsageAndAttachmentConfig,
      fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
      goToDatasetTab,
      taxYear,
      jurisdictionId,
      taxForm,
    ],
  );

  return (
    <div className={styles.flexGrow}>
      <div className={styles.flexGrowInner}>
        <div className={styles.datasetContainer}>
          <Formik
            onSubmit={savePage}
            initialValues={selectedMappingFormPDFPageDataset}
            enableReinitialize
            validateOnChange
            validationSchema={dataModelSchemas.datasetUsageSchema}
          >
            {renderDatasetUsageForm}
          </Formik>
        </div>
      </div>
    </div>
  );
};

OverflowActions.propTypes = {
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,

  selectedMappingFormPDFPageDataset: PropTypes.shape({
    type: PropTypes.string,
    pageNumber: PropTypes.number.isRequired,
    id: PropTypes.string,
    datasetId: PropTypes.string.isRequired,
    name: PropTypes.string,
    parentId: PropTypes.string,
    contextTypeId: PropTypes.number,
    overflowAction: PropTypes.string.isRequired,
  }),
  overflowActionTypes: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,

  taxForm: PropTypes.shape({
    pdfId: PropTypes.string.isRequired,
  }).isRequired,

  goToDatasetTab: PropTypes.func.isRequired,

  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs: PropTypes.func.isRequired,
  updatePdfDataUsageAndAttachmentConfig: PropTypes.func.isRequired,

  fetchPDFAttachmentColumnProperties: PropTypes.func.isRequired,
  PDFAttachmentColumnProperties: PropTypes.arrayOf(
    PropTypes.shape({
      dataItemName: PropTypes.string,
      description: PropTypes.string,
      displayName: PropTypes.string,
      itemDisplayOrder: PropTypes.number,
      columnDisplayOrder: PropTypes.number,
      screenDisplay: PropTypes.bool,
      printDisplay: PropTypes.bool,
      printColumnTotal: PropTypes.bool,
      relativeWidth: PropTypes.number,
      fontSize: PropTypes.number,
      includeConsMembers: PropTypes.bool,
      calcPreferenceOn: PropTypes.bool,
      calcPreferenceName: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isFetchingPDFAttachmentColumnProperties: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    selectedMappingFormPDFPageDataset: selectedMappingFormPDFPageDatasetSelector(state),
    overflowActionTypes: overflowActionTypesSelector(state),
    taxForm: taxFormSelector(state),

    PDFAttachmentColumnProperties: pdfAttachmentColumnPropertiesSelector(state),
    isFetchingPDFAttachmentColumnProperties: isFetchingPDFAttachmentColumnPropertiesSelector(state),
  }),
  {
    fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
    updatePdfDataUsageAndAttachmentConfig,
    fetchPDFAttachmentColumnProperties,
  },
)(OverflowActions);
