import { httpGetAndParse, httpPost, httpPut, httpDelete } from '../../../utils/fetchService';

export const fetchGenericFunctions = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/development/generic-functions/${taxYear}/${period}`,
    errorMessage: 'Fetching generic functions failed',
  });

export const fetchDataTypes = () =>
  httpGetAndParse({
    route: `/api/development/generic-functions/data-types`,
    errorMessage: 'Fetching data types failed',
  });

export const fetchConstants = ({ taxYear, period, dataType }) =>
  httpGetAndParse({
    route: `/api/development/generic-functions/constants/${taxYear}/${period}/${dataType}`,
    errorMessage: 'Fetching constants failed',
  });

export const fetchCategories = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/generic-functions/categories/${taxYear}`,
    errorMessage: 'Fetching categories failed',
  });

export const fetchAccounts = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/generic-functions/accounts/${taxYear}`,
    errorMessage: 'Fetching accounts failed',
  });

export const fetchJurisdictions = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/generic-functions/jurisdictions/${taxYear}`,
    errorMessage: 'Fetching jurisdictions failed',
  });

export const fetchDatasets = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/datasets/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching datasets failed',
  });

export const fetchDataItems = ({ taxYear, jurisdictionId, datasetName }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/data-items/${taxYear}/${jurisdictionId}/${datasetName}`,
    errorMessage: 'Fetching dataitems failed',
  });

export const fetchGenericFunctionParams = ({ taxYear, period, functionType, functionId }) =>
  httpGetAndParse({
    route: `/api/development/generic-functions/${taxYear}/${period}/${functionType}/${functionId}`,
    errorMessage: 'Fetching generic function params failed',
  });

export const createGenericFunctionWithParams = ({
  taxYear,
  period,
  functionId,
  description,
  functionType,
  operationType,
  parameters,
}) =>
  httpPost({
    route: `/api/development/generic-functions/${taxYear}/${period}`,
    errorMessage: 'Creating generic function failed',
    body: {
      functionId,
      description,
      functionType,
      operationType,
      parameters,
    },
  });

export const updateGenericFunctionWithParams = ({
  taxYear,
  period,
  functionId,
  description,
  functionType,
  operationType,
  parameters,
}) =>
  httpPut({
    route: `/api/development/generic-functions/${taxYear}/${period}/${functionId}`,
    errorMessage: 'Updating generic function failed',
    body: {
      description,
      functionType,
      operationType,
      parameters,
    },
  });

export const deleteGenericFunction = ({ taxYear, period, functionId, functionType }) =>
  httpDelete({
    route: `/api/development/generic-functions/${taxYear}/${period}/${functionId}/${functionType}`,
    errorMessage: 'Delete generic function failed',
  });
