const Yup = require('yup');

const { taxReturnStatus } = require('../constants');
const { getBusinessTypeSchema } = require('../getBusinessTypeSchema');
const { getReturnTypeSchema } = require('../getReturnTypeSchema');
const { FilingMethods } = require('../../../commonPackages/stateHelpers/stateHelpers');
const { getDueDateSchema } = require('../getDuedateSchema');

const getSimplifiedEditMemberReturnsSchema = ({ shouldDisplayDueDate }) =>
  Yup.object()
    .shape({
      memberReturns: Yup.array()
        .of(
          Yup.object().shape({
            returnId: Yup.number().label('Return ID').required(),
            entityId: Yup.string().label('Entity ID').required(),
            filingMethod: Yup.string()
              .oneOf([FilingMethods.EFILE, FilingMethods.PAPER, FilingMethods.NON_FILING])
              .required()
              .label('Filing Method'),
            returnStatus: Yup.number()
              .label('Return Status')
              .when('filingMethod', {
                is: FilingMethods.NON_FILING,
                then: Yup.number()
                  .oneOf([
                    taxReturnStatus.NOT_STARTED,
                    taxReturnStatus.IN_PROGRESS,
                    taxReturnStatus.COMPLETE,
                    null,
                  ])
                  .nullable(),
                otherwise: Yup.number()
                  .oneOf([
                    taxReturnStatus.NOT_STARTED,
                    taxReturnStatus.IN_PROGRESS,
                    taxReturnStatus.COMPLETE,
                  ])
                  .required(),
              }),
            nexusIndicator: Yup.boolean().label('Nexus').required(),
            calcOptions: Yup.object().label('Calc Options').required(),
            TAX_TYPE_INCOME: Yup.boolean().label('Income Tax').required(),
            TAX_TYPE_FRANCHISE: Yup.boolean().label('Franchise Tax').required(),
            ...getBusinessTypeSchema(),
            ...getReturnTypeSchema(),
            ...getDueDateSchema({ shouldDisplayDueDate }),
          }),
        )
        .notOneOf([undefined, null]), // eslint-disable-line no-undefined
    })
    .strict();

module.exports = {
  getSimplifiedEditMemberReturnsSchema,
};
