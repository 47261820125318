import { createSelector } from 'reselect';

import { isFetchingGlobalContextSelector } from '../../shared/store/selectors';

export const filingGroupsSelector = ({ federalProFormaFilingGroups }) =>
  federalProFormaFilingGroups.shared.filingGroups;
export const isFetchingFilingGroupsSelector = ({ federalProFormaFilingGroups }) =>
  federalProFormaFilingGroups.shared.isFetchingFilingGroups;
export const filingGroupIdSelector = ({ federalProFormaFilingGroups }) =>
  federalProFormaFilingGroups.shared.filingGroupId;

export const filingGroupSelector = createSelector(
  [filingGroupsSelector, filingGroupIdSelector],
  (filingGroups, filingGroupId) =>
    filingGroups.find(filingGroup => filingGroup.filingGroupId === filingGroupId) || null,
);

export const filingGroupNameSelector = createSelector([filingGroupSelector], filingGroup =>
  filingGroup ? filingGroup.description : null,
);

export const isFetchingContextSelector = createSelector(
  [isFetchingFilingGroupsSelector, isFetchingGlobalContextSelector],
  (isFetchingFilingGroups, isFetchingGlobalContext) =>
    isFetchingFilingGroups || isFetchingGlobalContext,
);
