import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Routes, taxSummariesMainNames, taxNames } from '@common-packages/routes-definitions';
import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

import withPermissions from '../../../shared/authorization/withPermissionsHoc.container';
import { customerPermissionsPropTypes } from '../../../shared/propTypes/customerPropTypes';
import {
  ActionMenu,
  ActionMenuItem,
} from '../../../shared/displayComponents/actionMenu/actionMenu.component';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import {
  periodSelector,
  taxYearSelector,
  filingTypeIdSelector,
  taxYearOptionsSelector,
  isEntityLockedSelector,
  isFetchingEntityLockIndicatorSelector,
  jurisdictionDescriptionSelector,
} from '../../../shared/store/selectors';
import {
  taxSummariesSelector,
  estimatedTaxSummarySelector,
  isFetchingTaxSummariesSelector,
} from '../../../taxSummaries/store/selectors';
import {
  selectTaxYear,
  performEntityDataCalculation,
  fetchLockIndicator,
  initiateCalculationAllEntitiesForJurisdiction,
} from '../../../shared/store/actions';
import {
  fetchIncomeTaxSummary,
  fetchFranchiseTaxSummary,
  fetchEstimatedTaxSummary,
} from '../../../taxSummaries/store/actions';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';
import Loading from '../../../shared/displayComponents/loading.component';
import { filingTypes } from '../../../shared/constants';
import { findRouteNamespace, appendEmptyRowsAfterEachSection } from '../utils';

import getIncomeFranchiseColumnDefinitions from './incomeFranchiseTaxSummaries.columnDefinitions';
import getEstimatedColumnDefinitions from './estimatedTaxSummaries.columnDefinitions';
import styles from './taxSummaries.module.scss';

const TaxSummaries = ({
  customerPermissions,

  taxYear,
  taxYears,
  period,
  filingTypeId,
  match,

  selectTaxYear,
  taxSummaries,
  isFetchingTaxSummaries,
  fetchIncomeTaxSummary,
  fetchFranchiseTaxSummary,
  fetchEstimatedTaxSummary,
  estimatedTaxSummary,

  orgId,
  jurisdictionId,
  isFetchingContext,

  showConfirmModal,
  performEntityDataCalculation,
  isEntityLocked,
  isFetchingEntityLockIndicator,
  fetchLockIndicator,
  initiateCalculationAllEntitiesForJurisdiction,
  jurisdictionDescription,

  hasUserPermissionsToEdit,
}) => {
  const { path, params } = match;
  const { taxName } = params;
  const isContextReady = Boolean(taxYear && period && orgId && jurisdictionId);

  const fetchFunctions = {
    [taxNames.INCOME_TAX]: fetchIncomeTaxSummary,
    [taxNames.FRANCHISE_TAX]: fetchFranchiseTaxSummary,
    [taxNames.ESTIMATED_TAX]: fetchEstimatedTaxSummary,
  };
  const fetchTaxSummary = fetchFunctions[taxName];

  const hasPermissionToForceEntityCalculation = useMemo(
    () =>
      hasPermissionToAccess({
        customerPermissions,
        permissionType: PERMISSION_TYPE.EDIT,
        routeDefinitions: [PERMISSION.ENTITY_DATA_CALCULATION],
      }),
    [customerPermissions],
  );

  useEffect(() => {
    if (!isContextReady) {
      return;
    }
    fetchTaxSummary({ taxYear, period, orgId, jurisdictionId });
  }, [
    fetchIncomeTaxSummary,
    fetchFranchiseTaxSummary,
    fetchEstimatedTaxSummary,
    isContextReady,
    fetchTaxSummary,
    taxYear,
    period,
    orgId,
    jurisdictionId,
    taxName,
  ]);

  useEffect(() => {
    if (filingTypeId === filingTypes.SEPARATE && isContextReady) {
      fetchLockIndicator({ taxYear, period, orgId, jurisdictionId });
    }
  }, [fetchLockIndicator, isContextReady, filingTypeId, taxYear, period, orgId, jurisdictionId]);

  const incomeFranchiseColumnDefinitions = useMemo(
    () =>
      getIncomeFranchiseColumnDefinitions({
        match,
        taxYear,
        taxYears,
        selectTaxYear,
      }),
    [selectTaxYear, taxYear, taxYears, match],
  );

  const estimatedColumnDefinitions = useMemo(
    () =>
      getEstimatedColumnDefinitions({
        taxYear,
      }),
    [taxYear],
  );

  const namespace = findRouteNamespace(path);

  const rowData = useMemo(
    () =>
      appendEmptyRowsAfterEachSection(
        taxName === taxNames.ESTIMATED_TAX ? estimatedTaxSummary : taxSummaries,
        'isSummaryRow',
      ),
    [taxName, estimatedTaxSummary, taxSummaries],
  );

  const isLoading = isFetchingContext || isFetchingTaxSummaries;

  const onEntityDataCalculationClick = useCallback(() => {
    showConfirmModal({
      title: 'Calculate Entity Data',
      text: 'Calculate the current entity in context?',
      confirmCallback: async () =>
        await performEntityDataCalculation({
          taxYear,
          period,
          jurisdictionId,
          orgId,
        }),
    });
  }, [showConfirmModal, performEntityDataCalculation, taxYear, period, orgId, jurisdictionId]);

  const onCalculateAllEntitiesForJurisdiction = useCallback(() => {
    showConfirmModal({
      title: 'Calculate All Entities For Jurisdiction',
      text: `Calculating time is dependent on number of entities in the jurisdiction. Perform calculation for all entities for ${jurisdictionDescription} ?`,
      confirmCallback: () =>
        initiateCalculationAllEntitiesForJurisdiction({
          taxYear,
          period,
          orgId,
          jurisdictionId,
        }),
    });
  }, [
    initiateCalculationAllEntitiesForJurisdiction,
    jurisdictionDescription,
    jurisdictionId,
    orgId,
    period,
    showConfirmModal,
    taxYear,
  ]);

  const isLoadingDataForActionMenu = !isContextReady || isFetchingEntityLockIndicator;
  const locked = filingTypeId === filingTypes.SEPARATE && isEntityLocked;

  if (!taxSummariesMainNames[taxName]) {
    return <Redirect to={Routes[namespace].MAIN} />;
  }

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }
  return (
    <div className="grid-borderless row grid-row">
      <div className={`col-12 ${styles.gridContainer}`}>
        {hasUserPermissionsToEdit && taxName !== taxNames.ESTIMATED_TAX && (
          <div className={`row ${styles.actionMenuRow}`}>
            <Loading isLoading={isLoadingDataForActionMenu} small>
              <ActionMenu>
                {hasPermissionToForceEntityCalculation && (
                  <ActionMenuItem
                    onClick={onEntityDataCalculationClick}
                    text="Calculate Entity Data"
                    locked={locked}
                  />
                )}
                <ActionMenuItem
                  onClick={onCalculateAllEntitiesForJurisdiction}
                  text="Calculate All Entities for Jurisdiction"
                  locked={locked}
                />
              </ActionMenu>
            </Loading>
          </div>
        )}
        <div className={`row ${styles.grid}`}>
          <div className="col">
            <AgGrid
              isGridLoading={isLoading}
              columnDefs={
                taxName === taxNames.ESTIMATED_TAX
                  ? estimatedColumnDefinitions
                  : incomeFranchiseColumnDefinitions
              }
              rowData={rowData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TaxSummaries.propTypes = {
  customerPermissions: customerPermissionsPropTypes,

  taxYear: PropTypes.string,
  taxYears: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectTaxYear: PropTypes.func.isRequired,
  period: PropTypes.string,
  filingTypeId: PropTypes.string,

  fetchIncomeTaxSummary: PropTypes.func.isRequired,
  fetchFranchiseTaxSummary: PropTypes.func.isRequired,
  taxSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string.isRequired,
      dataCollection: PropTypes.number,
      adjustments: PropTypes.number,
      currentYear: PropTypes.number,
      priorYear: PropTypes.number,
    }).isRequired,
  ),
  isFetchingTaxSummaries: PropTypes.bool.isRequired,

  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool,

  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      taxName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fetchEstimatedTaxSummary: PropTypes.func.isRequired,
  estimatedTaxSummary: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string.isRequired,
      priorTaxYearExtension: PropTypes.number,
      priorTaxYearReturn: PropTypes.number,
      firstQuarter: PropTypes.number,
      secondQuarter: PropTypes.number,
      thirdQuarter: PropTypes.number,
      fourthQuarter: PropTypes.number,
      currentTaxYearExtension: PropTypes.number,
    }).isRequired,
  ),
  showConfirmModal: PropTypes.func.isRequired,
  performEntityDataCalculation: PropTypes.func.isRequired,
  fetchLockIndicator: PropTypes.func.isRequired,
  isEntityLocked: PropTypes.bool,
  isFetchingEntityLockIndicator: PropTypes.bool.isRequired,
  jurisdictionDescription: PropTypes.string,
  initiateCalculationAllEntitiesForJurisdiction: PropTypes.func.isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default withPermissions(
  connect(
    state => ({
      taxYear: taxYearSelector(state),
      taxYears: taxYearOptionsSelector(state),
      period: periodSelector(state),
      filingTypeId: filingTypeIdSelector(state),
      taxSummaries: taxSummariesSelector(state),
      estimatedTaxSummary: estimatedTaxSummarySelector(state),
      isFetchingTaxSummaries: isFetchingTaxSummariesSelector(state),
      isEntityLocked: isEntityLockedSelector(state),
      isFetchingEntityLockIndicator: isFetchingEntityLockIndicatorSelector(state),
      jurisdictionDescription: jurisdictionDescriptionSelector(state),
    }),
    {
      selectTaxYear,
      fetchIncomeTaxSummary,
      fetchFranchiseTaxSummary,
      fetchEstimatedTaxSummary,
      showConfirmModal,
      performEntityDataCalculation,
      fetchLockIndicator,
      initiateCalculationAllEntitiesForJurisdiction,
    },
  )(TaxSummaries),
);
