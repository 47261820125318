export enum EfileSetupSteps {
  PERMANENT_INFORMATION = 'import-entity-permanent-information',
  ENTITY_INFORMATION = 'import-entity-information',
  CALCULATION_PROFILE = 'perform-setup-calculation-profile',
  GROUP_DEFINITION = 'import-consolidated-and-entity-group-definition',
  FILING_JURISDICTIONS = 'import-state-filing-jurisdictions',
}

export const EFILE_SETUP_STEP_NAMES = {
  [EfileSetupSteps.PERMANENT_INFORMATION]: 'Import Entity Permanent Information',
  [EfileSetupSteps.ENTITY_INFORMATION]: 'Import Entity Information',
  [EfileSetupSteps.CALCULATION_PROFILE]: 'Perform Calculation Profile',
  [EfileSetupSteps.GROUP_DEFINITION]: 'Import Consolidated and Entity Group Definition',
  [EfileSetupSteps.FILING_JURISDICTIONS]: 'Import State Filing Jurisdictions',
};

export const EFILE_SETUP_LINK_TEXT = {
  [EfileSetupSteps.PERMANENT_INFORMATION]: 'Permanent Information',
  [EfileSetupSteps.ENTITY_INFORMATION]: 'Entity Information',
  [EfileSetupSteps.GROUP_DEFINITION]: 'Group Definition',
  [EfileSetupSteps.FILING_JURISDICTIONS]: 'Filing Jurisdictions',
};
