import {
  currencyColumnDefinition,
  defaultColumnDefinition,
} from '../../../shared/columnDefinitions';
import { currencyCellFormatter } from '../../../shared/formatters';

const boldCellClassRules = {
  'bold-cell': ({ data }) => data.isSectionHeader || data.isReportHeader,
};

const underlineCellClassRules = {
  'underlined-cell': ({ data }) => data.isLastRowInSection || data.isReportHeader,
  'double-underlined-cell': ({ data }) => data.isLastRowInReport,
};

const amountFormatter = props => {
  if (props.data.isReportHeader) {
    return '';
  }

  return currencyCellFormatter(props);
};

const colorCellClassRules = ({ data }) =>
  data.isReportHeader && {
    backgroundColor: '#f2f2f2',
  };

const getColumnDefinitions = (columnHeaders, headerParams) => {
  if (columnHeaders) {
    const enhancedColumnHeaders = columnHeaders.map(header => {
      const headerComponentParams = headerParams.map(param => header[param.key]);

      return {
        ...defaultColumnDefinition,
        ...currencyColumnDefinition,
        valueFormatter: amountFormatter,
        field: header.id,
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        headerComponentParams: {
          params: headerComponentParams,
          columnWidth: 150,
        },
        cellClassRules: underlineCellClassRules,
      };
    });

    return [
      {
        ...defaultColumnDefinition,
        field: 'rowName',
        width: 400,
        headerComponentParams: {
          params: headerParams.map(param => param.label),
          columnWidth: 400,
        },
        cellClassRules: {
          ...boldCellClassRules,
          ...underlineCellClassRules,
          ...colorCellClassRules,
        },
        cellStyle: colorCellClassRules,
      },
      ...enhancedColumnHeaders,
    ];
  }
  return [];
};
export default getColumnDefinitions;
