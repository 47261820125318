import { httpGetAndParse, httpPut } from '../../utils/fetchService';

export const fetchWorkpaperInstanceData = ({
  taxYear,
  period,
  filingTypeId,
  entityId,
  jurisdictionId,
  parentDatasetInstanceId,
  datasetDefinitionId,
}) =>
  httpGetAndParse({
    route: `/api/shared/data-models/workpaper-instance/${taxYear}/${period}/${filingTypeId}/${entityId}/${jurisdictionId}/${parentDatasetInstanceId}/${datasetDefinitionId}`,
    errorMessage: 'Finding workpaper instance data failed',
  });

export const updateWorkpaperInstance = ({
  taxYear,
  period,
  businessEntityId,
  datasetDefinitionId,
  parentDatasetInstanceId,
  changes,
  columnsBlueprint,
  filingTypeId,
  jurisdictionId,
}) =>
  httpPut({
    route: `/api/shared/data-models/workpaper-instance/${taxYear}/${period}/${businessEntityId}/${filingTypeId}/${jurisdictionId}/${parentDatasetInstanceId}/${datasetDefinitionId}`,
    parseResponseErrorMessage: true,
    body: {
      columnsBlueprint,
      changes,
    },
  });

export const fetchDatasetDefinitionMetaData = ({ datasetDefinitionId, filingTypeId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/workpaper-instance/${datasetDefinitionId}/${filingTypeId}/meta-data`,
    errorMessage: 'Getting dataset definition meta-data failed',
  });
