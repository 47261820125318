import PropTypes from 'prop-types';

export const SelectedMappingFormPDFPageDatasetPropTypes = PropTypes.shape({
  type: PropTypes.string,
  pageNumber: PropTypes.number,
  id: PropTypes.string,
  datasetId: PropTypes.string,
  name: PropTypes.string,
  parentId: PropTypes.string,
  contextTypeId: PropTypes.number,
  overflowAction: PropTypes.string,
});

export const PDFAttachmentColumnPropertiesPropTypes = PropTypes.shape({
  dataItemName: PropTypes.string,
  description: PropTypes.string,
  displayName: PropTypes.string,
  itemDisplayOrder: PropTypes.number,
  columnDisplayOrder: PropTypes.number,
  screenDisplay: PropTypes.bool,
  printDisplay: PropTypes.bool,
  printColumnTotal: PropTypes.bool,
  relativeWidth: PropTypes.number,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  includeConsMembers: PropTypes.bool,
});
