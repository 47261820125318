import React from 'react';

import Loading from '../../../../shared/displayComponents/loading.component';

import styles from './latestCalculationCompileInfo.module.scss';
import {
  EXPRESSIONS,
  DATAITEMS,
  LAST_SUCCESSFUL_COMPILE,
  COMPILING_STATUS,
  COMPILING,
} from './constants';

interface DefaultRenderProps {
  isLoading?: boolean;
  isCompiling?: boolean;
}

const DefaultRender = ({ isLoading = false, isCompiling = false }: DefaultRenderProps) => (
  <div className={styles.grid}>
    <p>{EXPRESSIONS}</p>
    <Loading isLoading={isLoading || isCompiling} small />
    <Loading className={styles.compileInfoStatus} isLoading={isLoading || isCompiling} small />
    <p className={styles.dataItems}>{DATAITEMS}</p>
    <Loading isLoading={isLoading || isCompiling} small />
    <p className={styles.lastSuccessfulCompileLabel}>{LAST_SUCCESSFUL_COMPILE}</p>
    <Loading isLoading={isLoading || isCompiling} small />
    <p className={styles.currentCompileStatus}>
      {isCompiling && (
        <>
          <span>{COMPILING_STATUS}</span>
          <span className={styles.loadingText}>{COMPILING}</span>
        </>
      )}
    </p>
  </div>
);

export default DefaultRender;
