import { httpPost, httpPut } from '../../../utils/fetchService';

export const createInfoAccount = ({
  taxYear,
  period,
  infoAccountId,
  infoType,
  infoAccountDescription,
  activeYn,
  globalInd,
  pshipGlobalInd,
  dataType,
}) =>
  httpPost({
    route: `/api/admin/maintenance/info-accounts`,
    errorMessage: 'Creating info account failed',
    body: {
      taxYear,
      period,
      infoAccountId,
      infoType,
      infoAccountDescription,
      activeYn,
      globalInd,
      pshipGlobalInd,
      dataType,
    },
  });

export const updateInfoAccount = ({
  taxYear,
  period,
  infoAccountId,
  infoType,
  infoAccountDescription,
  activeYn,
  globalInd,
  pshipGlobalInd,
  dataType,
}) =>
  httpPut({
    route: `/api/admin/maintenance/info-accounts`,
    errorMessage: 'Updating info account failed',
    body: {
      taxYear,
      period,
      infoAccountId,
      infoType,
      infoAccountDescription,
      activeYn,
      globalInd,
      pshipGlobalInd,
      dataType,
    },
  });
