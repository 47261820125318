import React, { useCallback } from 'react';

import { ContextTypeAndValue } from '../../../../../common/types/apiShapes';

import HeaderWithParamDropdowns from './headerWithParamDropdowns.component';
import styles from './styles.module.scss';

const noop = () => null;

interface HeaderWithParamDropdownProps {
  title?: string;
  showHeaderTaxYearDropdown?: boolean;
  showHeaderPeriodDropdown?: boolean;
  showHeaderJurisdictionDropdown?: boolean;
  showHeaderTaxFormDropdown?: boolean;
  enableEverywhereJurisdiction?: boolean;
  renderAdditionalElements?: () => JSX.Element | string | number | null | undefined;
  renderAdditionalLeftSideElements?: () => JSX.Element | string | number | null | undefined;
  showWarningModal?: (params: ContextTypeAndValue) => unknown;
}

const HeaderWithParamDropdown = ({
  showWarningModal = noop,
  showHeaderTaxYearDropdown = false,
  showHeaderPeriodDropdown = false,
  showHeaderJurisdictionDropdown = false,
  showHeaderTaxFormDropdown = false,
  enableEverywhereJurisdiction = false,
  title = '',

  renderAdditionalElements = noop,
  renderAdditionalLeftSideElements = noop,
}: HeaderWithParamDropdownProps) => {
  const renderHeaderParamDropdowns = useCallback(() => {
    const shouldRenderHeaderParamDropdowns =
      showHeaderTaxYearDropdown ||
      showHeaderPeriodDropdown ||
      showHeaderJurisdictionDropdown ||
      showHeaderTaxFormDropdown;

    return shouldRenderHeaderParamDropdowns ? (
      <HeaderWithParamDropdowns
        showWarningModal={showWarningModal}
        showHeaderTaxYearDropdown={showHeaderTaxYearDropdown}
        showHeaderPeriodDropdown={showHeaderPeriodDropdown}
        showHeaderJurisdictionDropdown={showHeaderJurisdictionDropdown}
        showHeaderTaxFormDropdown={showHeaderTaxFormDropdown}
        enableEverywhereJurisdiction={enableEverywhereJurisdiction}
      />
    ) : null;
  }, [
    showWarningModal,
    showHeaderTaxYearDropdown,
    showHeaderPeriodDropdown,
    showHeaderJurisdictionDropdown,
    showHeaderTaxFormDropdown,
    enableEverywhereJurisdiction,
  ]);

  return (
    <div className={styles.flexSpaceBetween}>
      <div className={styles.leftSide}>
        {renderHeaderParamDropdowns()}
        {renderAdditionalLeftSideElements()}
      </div>
      {title ? <span className={styles.screenTitle}>{title}</span> : null}
      {renderAdditionalElements()}
    </div>
  );
};

export default HeaderWithParamDropdown;
