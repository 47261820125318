import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useEditModeButtons, useCloneDeep } from '../../shared/editMode';
import { entityIdSelector, periodSelector, taxYearSelector } from '../../shared/store/selectors';
import useGridApi from '../../shared/hooks/useGridApi.hook';

import { fetchStateAccountNumbers, updateStateAccountNumbers } from './store/actions';
import {
  stateAccountNumbersRowsSelector,
  stateAccountNumbersColumnsSelector,
  isFetchingStateAccountNumbersSelector,
  isUpdatingStateAccountNumbersSelector,
} from './store/selectors';
import getColumnDefinitions from './stateAccountNumbers.columnDefinitions';

const useFetchData = ({ taxYear, period, entityId, fetchStateAccountNumbers }) => {
  useEffect(() => {
    const isContextReady = taxYear && period && entityId;

    if (!isContextReady) {
      return;
    }
    fetchStateAccountNumbers({
      taxYear,
      period,
      entityId,
    });
  }, [fetchStateAccountNumbers, taxYear, period, entityId]);
};

const StateAccountNumbers = ({
  taxYear,
  period,
  entityId,
  fetchStateAccountNumbers,
  stateAccountNumbersRows,
  stateAccountNumbersColumns,
  isFetchingStateAccountNumbers,
  isUpdatingStateAccountNumbers,
  updateStateAccountNumbers,

  hasUserPermissionsToEdit,
}) => {
  useFetchData({
    taxYear,
    period,
    entityId,
    fetchStateAccountNumbers,
  });

  const [isInEditMode, setIsInEditMode] = useState(false);
  const { gridApi, onGridReady } = useGridApi();

  const [modifiedCells, setModifiedCells] = useState([]);

  const onLeaveEditMode = useCallback(() => {
    setModifiedCells([]);
    gridApi.setRowData(cloneDeep(stateAccountNumbersRows));
  }, [gridApi, stateAccountNumbersRows]);

  const saveChanges = useCallback(async () => {
    const modifiedCellsWithChanges = modifiedCells.filter(cell => {
      const correspondingRow = stateAccountNumbersRows.find(
        ({ jurisdictionId }) => cell.jurisdictionId === jurisdictionId,
      );
      return (correspondingRow[cell.columnId] || '') !== cell.value;
    });
    const cellsToUpdate = modifiedCellsWithChanges.map(modifiedCell => ({
      jurisdictionId: modifiedCell.jurisdictionId,
      columnId: modifiedCell.columnId,
      value: modifiedCell.value,
    }));

    setModifiedCells([]);
    await updateStateAccountNumbers({
      taxYear,
      period,
      entityId,
      values: cellsToUpdate,
    });
    fetchStateAccountNumbers({ taxYear, period, entityId });
  }, [
    updateStateAccountNumbers,
    fetchStateAccountNumbers,
    stateAccountNumbersRows,
    modifiedCells,
    taxYear,
    period,
    entityId,
  ]);

  const { editModeButtons } = useEditModeButtons({
    isInEditMode,
    setIsInEditMode,
    onCancel: onLeaveEditMode,
    onSave: saveChanges,
  });

  const columnDefinitions = useMemo(() => {
    const addModifiedValue = modifiedCell => {
      setModifiedCells([
        ...modifiedCells.filter(
          cell =>
            !(
              cell.jurisdictionId === modifiedCell.jurisdictionId &&
              cell.columnId === modifiedCell.columnId
            ),
        ),
        modifiedCell,
      ]);
    };
    return getColumnDefinitions({
      isInEditMode,
      dynamicColumns: stateAccountNumbersColumns,
      addModifiedValue,
    });
  }, [modifiedCells, isInEditMode, stateAccountNumbersColumns]);

  const rowData = useCloneDeep(stateAccountNumbersRows);

  return (
    <>
      <div className="row">
        {hasUserPermissionsToEdit && <div className="col add-button-column">{editModeButtons}</div>}
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={rowData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingStateAccountNumbers || isUpdatingStateAccountNumbers}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            stopEditingWhenCellsLoseFocus
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
};

StateAccountNumbers.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  fetchStateAccountNumbers: PropTypes.func.isRequired,
  updateStateAccountNumbers: PropTypes.func.isRequired,
  stateAccountNumbersRows: PropTypes.arrayOf(
    PropTypes.shape({
      jurisdictionId: PropTypes.string.isRequired,
      jurisdictionDescription: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  stateAccountNumbersColumns: PropTypes.arrayOf(
    PropTypes.shape({
      infoAcctId: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  isFetchingStateAccountNumbers: PropTypes.bool.isRequired,
  isUpdatingStateAccountNumbers: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    stateAccountNumbersRows: stateAccountNumbersRowsSelector(state),
    stateAccountNumbersColumns: stateAccountNumbersColumnsSelector(state),
    isFetchingStateAccountNumbers: isFetchingStateAccountNumbersSelector(state),
    isUpdatingStateAccountNumbers: isUpdatingStateAccountNumbersSelector(state),
  }),
  {
    fetchStateAccountNumbers,
    updateStateAccountNumbers,
  },
)(StateAccountNumbers);
