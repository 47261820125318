import { defaultColumnDefinition } from '../../../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Account',
    field: 'account',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Group',
    field: 'accountGroup',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Amount',
    field: 'amount',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Multiplier',
    field: 'multiplier',
    width: 75,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Function Name',
    field: 'functionName',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Screen Account Group',
    field: 'screenAccountGroup',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Display Order',
    field: 'displayOrder',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Amount Type',
    field: 'amountType',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Source System Id',
    field: 'sourceSystemId',
  },
];

export default columnDefinitions;
