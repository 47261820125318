import React, { useMemo } from 'react';
import { Field, useFormikContext as UseFormikContext } from 'formik';
import { Button } from '@pwc/appkit-react';
import { SelectOption } from '@tls/slt-types';

import {
  ReturnDefinitionStateFilingFormOptions,
  ReturnStatusDefinitionRow,
  TaxReturnDefinitionWithFilingAttributes,
} from '../../../../../common/types/apiShapes';
import Loading from '../../displayComponents/loading.component';
import Select from '../../forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Radio from '../../forms/radioFormik/radioFormik.component';
import Input from '../../forms/inputFormik/inputFormik.component';
import Switch from '../../forms/switchFormik/switchFormik.component';
import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';
import { ConsolidationFormValues } from '../utils/useFormInitialValues';
import { useShouldDisplayReturnStatusWhenNotNonFiling } from '../../hooks/useShouldDisplayReturnStatus.hook';

import styles from './addEditReturnPanel.module.scss';
import { useAddEditReturnWrapperContext } from './addEditReturnWrapperContext';
import { consolidationWizardWrapperID } from './addEditReturnPanel.container';

interface AddEditReturnOptionsFormProps {
  isEditing: boolean;
  isLoading: boolean;
  taxReturnsDefinitionsJurisdictionOptions: SelectOption[];
  stateFilingFormOptions?: ReturnDefinitionStateFilingFormOptions;
  taxReturnsDefinition?: TaxReturnDefinitionWithFilingAttributes;
  returnStatusOption?: ReturnStatusDefinitionRow[];
  onNextButtonClick: () => void;
  renderBanner: () => JSX.Element | undefined;
}

const addEditReturnOptionsTabFields = [
  'jurisdictionId',
  'returnDefinitionId',
  'consolidationId',
  'description',
  'filingMethod',
  'returnStatus',
];

const AddEditReturnOptionsForm = ({
  isEditing,
  isLoading,
  taxReturnsDefinitionsJurisdictionOptions,
  stateFilingFormOptions,
  taxReturnsDefinition,
  returnStatusOption,
  onNextButtonClick,
  renderBanner,
}: AddEditReturnOptionsFormProps) => {
  const { values, errors } = UseFormikContext<ConsolidationFormValues>();
  const {
    filingMethodOptions,
    isFilingMethodDisabled,
    filingMethodInfoMessage,
  } = useAddEditReturnWrapperContext();

  const shouldDisplayReturnStatusWhenNotNonFiling = useShouldDisplayReturnStatusWhenNotNonFiling();

  const filingInformation = useMemo(() => {
    return taxReturnsDefinition?.filingInformation;
  }, [taxReturnsDefinition]);

  const hasReturnOptionsErrors = useMemo(
    () => Object.keys(errors).some(errorKey => addEditReturnOptionsTabFields.includes(errorKey)),
    [errors],
  );

  return (
    <Loading isLoading={isLoading}>
      <div className={styles.returnOptionsTabFields}>
        <Select
          appkitLabel="Jurisdiction"
          name="jurisdictionId"
          options={taxReturnsDefinitionsJurisdictionOptions}
          value={values.jurisdictionId}
          disabled={isEditing}
        />
        <Field
          appkitLabel="Filing Form Description"
          name="returnDefinitionId"
          as={Select}
          options={stateFilingFormOptions}
        />
        <Field
          label="Consolidation ID"
          name="consolidationId"
          component={Input}
          placeholder="Enter Consolidation ID"
          disabled={isEditing}
        />
        <Field
          label="Description"
          name="description"
          component={Input}
          placeholder="Enter Description"
        />
        <Field
          label="Filing Method"
          name="filingMethod"
          component={Radio}
          options={filingMethodOptions}
          disabled={isFilingMethodDisabled}
          infoMessage={filingMethodInfoMessage}
        />
        {filingInformation ? (
          <div className={styles.fillingInformation}>
            <AppkitIcon
              className={styles.fillingInformationIcon}
              icon="information"
              type="fill"
              size={16}
            />
            <span className={styles.filingInfoHeader}>Filing Information</span>
            <p className={styles.filingInfoMessage}>{filingInformation}</p>
          </div>
        ) : null}
        {isEditing && (
          <div className={styles.editOptionContainer}>
            <span className={styles.editOptionTitle}>Edit Options</span>
            <Field
              className={styles.editOptionField}
              label="Lock return"
              name="lockedInd"
              component={Switch}
              tooltipContent={'Disables ability to calculate and efile'}
              tooltipContainerId={consolidationWizardWrapperID}
            />
            <Field
              label="Active return"
              name="active"
              component={Switch}
              tooltipContent={
                <>
                  When marking a consolidation as inactive, SLT omits its data from the
                  <strong> Context </strong>
                  navigator and reports. You can still see the data within the Consolidations data
                  table.
                </>
              }
              tooltipContainerId={consolidationWizardWrapperID}
            />
          </div>
        )}
        {shouldDisplayReturnStatusWhenNotNonFiling ? (
          <Field
            appkitLabel="Return Status"
            name="returnStatus"
            as={Select}
            value={values.returnStatus}
            disabled={Boolean(values.efileStatus)}
            options={returnStatusOption}
          />
        ) : null}
      </div>
      {renderBanner()}
      <div className={styles.singleButton}>
        <Button
          size="lg"
          title="Next"
          onClick={onNextButtonClick}
          disabled={hasReturnOptionsErrors}
        >
          Next
        </Button>
      </div>
    </Loading>
  );
};

export default AddEditReturnOptionsForm;
