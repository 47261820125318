import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { List } from 'react-virtualized';

import { SelectOptionPropTypes } from '../../propTypes/selectOption';

import styles from './virtualizedSelect.module.scss';

const VirtualizedSelectListRenderer = ({
  downshiftProps: { getItemProps, highlightedIndex },
  options = [],
  initialSelectedValue,
}) => {
  const virtualListProps = {
    rowCount: options.length,
    width: 200,
    height: 250,
    rowHeight: 30,
  };

  const rowRenderer = useCallback(
    ({ index, isScrolling, isVisible, key, style }) => {
      const item = options[index];
      const itemProps = getItemProps({ item, index });
      const content = isScrolling && !isVisible ? '...' : item?.label;
      const isSelected = initialSelectedValue === item?.value;
      const isHighlighted = highlightedIndex === index;

      return (
        <div
          key={key}
          className="ag-virtual-list-item ag-rich-select-virtual-list-item"
          style={style}
          {...itemProps}
        >
          <div
            className={classNames([
              { [styles.rowSelected]: isSelected },
              { [styles.rowHighlighted]: isHighlighted },
              styles.selectItemContent,
            ])}
          >
            <span>{content}</span>
          </div>
        </div>
      );
    },
    [getItemProps, options, initialSelectedValue, highlightedIndex],
  );

  const noRowsRenderer = useCallback(
    () => (
      <div className={styles.noRowsWrapper}>
        <span>No results found</span>
      </div>
    ),
    [],
  );

  return <List {...virtualListProps} rowRenderer={rowRenderer} noRowsRenderer={noRowsRenderer} />;
};

VirtualizedSelectListRenderer.propTypes = {
  downshiftProps: PropTypes.shape({
    getItemProps: PropTypes.func,
    highlightedIndex: PropTypes.number,
  }),
  initialSelectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(SelectOptionPropTypes),
};

export default VirtualizedSelectListRenderer;
