const Yup = require('yup');

const createOrUpdateFilingGroupSchema = Yup.object().shape({
  taxYear: Yup.string().required().label('Tax Year'),
  period: Yup.string().required().label('Period'),
  jurisdictionId: Yup.string().required().label('Jurisdiction'),
  filingGroupId: Yup.string().required().label('Filing Group Id').max(10, 'Max is 10 Characters'),
  description: Yup.string().max(100, 'Max is 100 Characters'),
  returnTask: Yup.bool().required(),
  separateLimit: Yup.bool().required(),
  efile: Yup.bool().required(),
});

module.exports = {
  createOrUpdateFilingGroupSchema,
};
