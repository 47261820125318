import { apiAction } from '../apiAction';

import * as api from './api';
import {
  fetchAccountAuditTrailsTypes,
  fetchAccountDataTypes,
  fetchAccountAdjustmentTypes,
  fetchCalcDataTypes,
} from './types';

export const fetchAccountAuditTrails = apiAction({
  types: fetchAccountAuditTrailsTypes,
  apiAction: api.fetchAccountAuditTrails,
});

export const fetchAccountData = apiAction({
  types: fetchAccountDataTypes,
  apiAction: api.fetchAccountData,
});

export const fetchAccountAdjustment = apiAction({
  types: fetchAccountAdjustmentTypes,
  apiAction: api.fetchAccountAdjustment,
});

export const fetchCalcData = apiAction({
  types: fetchCalcDataTypes,
  apiAction: api.fetchCalcData,
});
