import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../loading.component';

const BreadcrumbContentRenderer = ({ label, isLoading }) => (
  <Loading isLoading={isLoading} small>
    {label}
  </Loading>
);

BreadcrumbContentRenderer.propTypes = {
  label: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

export default BreadcrumbContentRenderer;
