import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { globalContextSelector } from '../store/selectors';
import config from '../../config';
import { FormValues } from '../taxReturns/utils/useFormInitialValues';
import { FilingMethods } from '../enums';

export const useShouldDisplayReturnStatus = () => {
  const globalContext = useSelector(globalContextSelector);
  const { taxYear } = globalContext;
  return config.DISPLAY_RETURNS_STATUS && Number(taxYear) > 2020;
};

export const useShouldDisplayReturnStatusWhenNotNonFiling = () => {
  const { values } = useFormikContext<FormValues>();
  const shouldDisplayReturnStatus = useShouldDisplayReturnStatus();
  return shouldDisplayReturnStatus && values.filingMethod !== FilingMethods.NON_FILING;
};
