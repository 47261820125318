export const DisplayLevel = {
  HEADER: 'HEADER',
  DATA: 'DATA',
  CALC: 'CALC',
};

export const LinesToFollow = {
  SINGLE: 'SINGLE',
  TOTAL: 'TOTAL',
  NONE: 'NONE',
  DOUBLE: 'DOUBLE',
};
