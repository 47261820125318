import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@tls/ui-modal';

import {
  SelectionList,
  DataItemUsingSelectionList,
  CalcPreferenceUsingSelectionList,
} from '../../../../../../common/types';
import { useFetchQueryDataUsingSelectionList } from '../../../../shared/queries/selectionLists';
import Loading from '../../../../shared/displayComponents/loading.component';
import { errorNotification } from '../../../../shared/notification/store/actions';
import { ModalState, ModalTitle, ModalButtonsText, WARNING_BEFORE_DELETE } from '../../constants';

import dataItemsColumnDefinitions from './deleteSelectionListModal.dataItems.columnDefinitions';
import calcPreferenceColumnDefinitions from './deleteSelectionListModal.calcPreferences.columnDefinitions';
import DeleteSelectionListDataGrid from './deleteSelectionList.datagrid.component';

interface DeleteSelectionListModalProps {
  hideModal: () => void;
  visible: boolean;
  modalData: SelectionList;
  onDelete: (modalData: SelectionList) => void;
}

const DeleteSelectionListModal = ({
  hideModal,
  visible,
  modalData,
  onDelete,
}: DeleteSelectionListModalProps) => {
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState(ModalState.INITIAL);
  const [dataItemsUsingSelectionList, setDataItemsUsingSelectionList] = useState<
    DataItemUsingSelectionList[]
  >([]);
  const [calcPreferencesUsingSelectionList, setCalcPreferencesUsingSelectionList] = useState<
    CalcPreferenceUsingSelectionList[]
  >([]);

  const findDataUsingSelectionList = useFetchQueryDataUsingSelectionList();

  const handleSubmit = useCallback(() => {
    if (modalState === ModalState.SELECTION_LIST_UNUSED) {
      onDelete(modalData);
    }
    hideModal();
  }, [onDelete, hideModal, modalState, modalData]);

  useEffect(() => {
    (async () => {
      setModalState(ModalState.INITIAL);
      if (!modalData?.id) {
        return;
      }
      try {
        const dataUsingSelectionList = await findDataUsingSelectionList(modalData?.id);
        const dataItemsUsingSelectionList = dataUsingSelectionList?.dataItemsUsingSelectionList;
        const calcPreferencesUsingSelectionList =
          dataUsingSelectionList?.calcPreferencesUsingSelectionList;
        setDataItemsUsingSelectionList(dataItemsUsingSelectionList);
        setCalcPreferencesUsingSelectionList(calcPreferencesUsingSelectionList);
        setModalState(
          dataItemsUsingSelectionList?.length > 0 || calcPreferencesUsingSelectionList?.length > 0
            ? ModalState.SELECTION_LIST_USED
            : ModalState.SELECTION_LIST_UNUSED,
        );
      } catch (err) {
        dispatch(errorNotification('Error fetching in-use selection list'));
      }
    })();
  }, [dispatch, findDataUsingSelectionList, modalData?.id]);

  return (
    <Modal
      title={ModalTitle[modalState]}
      submitText={ModalButtonsText[modalState].submit}
      dismissText={ModalButtonsText[modalState].dismiss}
      visible={visible}
      dismissAction={hideModal}
      closeAction={hideModal}
      submitAction={handleSubmit}
      actionButtonsSize="lg"
    >
      <Loading isLoading={modalState === ModalState.INITIAL}>
        {modalState === ModalState.SELECTION_LIST_USED && (
          <>
            <DeleteSelectionListDataGrid
              columnDefinitions={dataItemsColumnDefinitions}
              data={dataItemsUsingSelectionList}
              gridHeader="Data Item"
            />
            <DeleteSelectionListDataGrid
              columnDefinitions={calcPreferenceColumnDefinitions}
              data={calcPreferencesUsingSelectionList}
              gridHeader="Calc Preference"
            />
          </>
        )}
        {modalState === ModalState.SELECTION_LIST_UNUSED && <p>{WARNING_BEFORE_DELETE}</p>}
      </Loading>
    </Modal>
  );
};

export default DeleteSelectionListModal;
