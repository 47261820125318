const getApiActionTypesHandlers = ({
  apiTypes,
  payloadKey = null,
  fetchingKey = null,
  errorKey = null,
  ignoreChanges = () => false,
  getAdditionalState = null,
  initialAdditionalState = {},
}) => {
  const initialStateData = {
    isSet: false,
    state: null,
  };
  return {
    [apiTypes.START]: state => {
      if (ignoreChanges(state)) {
        return state;
      }
      if (!initialStateData.isSet) {
        initialStateData.state = state[payloadKey];
        initialStateData.isSet = true;
      }
      return {
        ...state,
        ...(fetchingKey ? { [fetchingKey]: true } : null),
        ...(errorKey ? { [errorKey]: false } : null),
        ...(payloadKey ? { [payloadKey]: initialStateData.state } : null),
        ...initialAdditionalState,
      };
    },

    [apiTypes.FAILURE]: state => {
      if (ignoreChanges(state)) {
        return state;
      }
      return {
        ...state,
        ...(fetchingKey ? { [fetchingKey]: false } : null),
        ...(errorKey ? { [errorKey]: true } : null),
      };
    },

    [apiTypes.SUCCESS]: (state, payload, type, params) => {
      if (ignoreChanges(state)) {
        return state;
      }
      return {
        ...state,
        ...(fetchingKey ? { [fetchingKey]: false } : null),
        ...(errorKey ? { [errorKey]: false } : null),
        ...(payloadKey ? { [payloadKey]: payload } : null),
        ...(getAdditionalState ? getAdditionalState(state, payload, type, params) : null),
      };
    },
  };
};

export default getApiActionTypesHandlers;
