import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';

export default [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Tax Year',
    field: 'taxYear',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Form Name',
    field: 'formName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Form Description',
    field: 'formDescription',
    width: 470,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Watermark Name',
    field: 'watermark',
  },
];
