import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import omit from 'lodash.omit';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../../shared/hooks/useModal.hook';
import {
  selectFilingType,
  selectMaintenanceConsolidation,
  fetchJurisdictionsOptions,
} from '../../shared/store/actions';
import {
  taxYearSelector,
  periodSelector,
  consolidationJurisdictionsOptionsSelector,
  isFetchingJurisdictionsSelector,
} from '../../shared/store/selectors';
import { useBottomSlideInEditMode } from '../../shared/editMode';
import {
  useMutationUpdateConsol,
  useMutationCreateConsolidation,
} from '../../shared/mutations/consolidationsMaintenance';
import { useQueryFetchConsolidations } from '../../shared/queries/consolidations';

import EditConsolidation from './editConsolidation.container';
import AddConsolidationModal from './addConsolidationModal.container';
import { getColumnDefinitions } from './consolidationDashboard.columnDefinitions';

const getRowId = params => params?.data?.consolidationId;

const getOnlyFormData = rowToEdit =>
  omit(rowToEdit, ['jurisdictionDescription', 'profileId', 'parentName', 'parentFein']);

const ConsolidationDashboard = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const {
    data: consolidations,
    isFetching: isFetchingConsolidations,
  } = useQueryFetchConsolidations({
    params: {
      taxYear,
      period,
    },
    enabled: Boolean(taxYear && period),
  });
  const jurisdictionsOptions = useSelector(consolidationJurisdictionsOptionsSelector);
  const isFetchingJurisdictionsOptions = useSelector(isFetchingJurisdictionsSelector);

  const handleSelectFilingType = useCallback(
    filingType => {
      dispatch(selectFilingType(filingType));
    },
    [dispatch],
  );

  const handleSelectMaintenanceConsolidation = useCallback(
    data => {
      dispatch(selectMaintenanceConsolidation(data));
    },
    [dispatch],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        selectFilingType: handleSelectFilingType,
        selectMaintenanceConsolidation: handleSelectMaintenanceConsolidation,
      }),
    [handleSelectFilingType, handleSelectMaintenanceConsolidation],
  );

  const { showModal, modalProps } = useModal();
  const openModalForAdd = useCallback(showModal, [showModal]);

  useEffect(() => {
    if (taxYear && period) {
      dispatch(fetchJurisdictionsOptions({ taxYear, period }));
    }
  }, [dispatch, taxYear, period]);

  const {
    mutateAsync: updateConsolidationAndFilingAttributes,
    isLoading: isUpdatingConsolidationAndFilingAttributes,
  } = useMutationUpdateConsol();

  const onSaveConsolidationAndFilingAttributes = useCallback(
    async ({ values }) => {
      await updateConsolidationAndFilingAttributes({ ...values });
    },
    [updateConsolidationAndFilingAttributes],
  );

  const {
    mutateAsync: createConsolidation,
    isLoading: isSavingConsolidation,
  } = useMutationCreateConsolidation();

  const onSaveConsolidation = useCallback(
    async consolidation => {
      await createConsolidation({
        taxYear,
        period,
        consolidation,
      });
    },
    [createConsolidation, taxYear, period],
  );

  const isSavingConsolidationAndFilingAttributes = false;

  const formToRender = useCallback(
    ({ values, title, onSave }) => {
      const selectedConsolidation = {
        consolidationId: values.consolidationId,
        businessEntityId: values.orgId,
        jurisdictionId: values.jurisdictionId,
      };
      return (
        <EditConsolidation
          selectedConsolidation={selectedConsolidation}
          title={title}
          saveConsolidationAndFilingAttributes={onSave}
          isSavingConsolidationAndFilingAttributes={isSavingConsolidationAndFilingAttributes}
        />
      );
    },
    [isSavingConsolidationAndFilingAttributes],
  );

  const isLoading = !taxYear || isFetchingConsolidations;

  const { agGridProps, bottomSlideInWithForm } = useBottomSlideInEditMode({
    getRowId,
    onSave: onSaveConsolidationAndFilingAttributes,
    mapRowForEditForm: getOnlyFormData,
    getTitle: ({ jurisdictionDescription, description }) =>
      `${jurisdictionDescription}${description ? ` - ${description}` : ''}`,
    formToRender,
    renderBottomSlideIn: !isLoading,
  });

  return (
    <>
      {hasUserPermissionsToEdit && (
        <div className="row">
          <div className="col add-button-column">
            <Button
              size="lg"
              className="add-button"
              onClick={openModalForAdd}
              disabled={isSavingConsolidation}
              isLoading={isSavingConsolidation}
            >
              Add Consolidation
            </Button>
          </div>
        </div>
      )}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            isGridLoading={isFetchingConsolidations || isUpdatingConsolidationAndFilingAttributes}
            columnDefs={columnDefinitions}
            rowData={consolidations}
            withSearchBar
            {...agGridProps}
          />
        </div>
      </div>
      {bottomSlideInWithForm}
      <AddConsolidationModal
        {...modalProps}
        onSaveConsolidation={onSaveConsolidation}
        isSavingConsolidation={isSavingConsolidation}
        isFetchingJurisdictionsOptions={isFetchingJurisdictionsOptions}
        jurisdictionsOptions={jurisdictionsOptions}
      />
    </>
  );
};

ConsolidationDashboard.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default ConsolidationDashboard;
