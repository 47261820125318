import React from 'react';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../../shared/displayComponents/card/authorizedCard.container';
import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';

const ReturnWorkspaceForms = ({
  workspace,
  taxYear,
  period,
  businessEntityId,
  filingTypeId,
  jurisdictionId,
}) => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Tax Forms"
      icon="table-data"
      description="Tax Forms"
      route={Routes.viewPrintTaxReturn.compiledRoute(
        getContextAwareUrlParams({
          taxYear,
          period,
          businessEntityId,
          filingTypeId,
          jurisdictionId,
        }),
      )}
    />
    <AuthorizedCard
      title="Attachment Detail Report"
      icon="table-data"
      description="Attachment Detail Report"
      route={Routes[`${workspace}ViewPrintAttachmentDetails`].MAIN}
    />
  </div>
);

ReturnWorkspaceForms.propTypes = {
  workspace: PropTypes.string.isRequired,
  taxYear: PropTypes.string,
  period: PropTypes.string,
  businessEntityId: PropTypes.string,
  filingTypeId: PropTypes.string,
  jurisdictionId: PropTypes.string,
};

export default ReturnWorkspaceForms;
