import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';

import { fetchJobQueueStatusReports } from './store/actions';
import {
  JobQueueStatusReportsSelector,
  isFetchingJobQueueStatusReportsSelector,
} from './store/selectors';
import columnDefinitions from './jobQueueStatusReport.columnDefinitions';

const JobQueueStatusReports = ({
  jobQueueStatusReports,
  isFetchingJobQueueStatusReports,
  fetchJobQueueStatusReports,
}) => {
  useEffect(() => {
    fetchJobQueueStatusReports();
  }, [fetchJobQueueStatusReports]);

  return (
    <>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={jobQueueStatusReports}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingJobQueueStatusReports}
          />
        </div>
      </div>
    </>
  );
};

JobQueueStatusReports.propTypes = {
  jobQueueStatusReports: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      jobType: PropTypes.string.isRequired,
      submitTime: PropTypes.string.isRequired,
      mailSubject: PropTypes.string.isRequired,
      note: PropTypes.string.isRequired,
    }),
  ),
  isFetchingJobQueueStatusReports: PropTypes.bool.isRequired,
  fetchJobQueueStatusReports: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    jobQueueStatusReports: JobQueueStatusReportsSelector(state),
    isFetchingJobQueueStatusReports: isFetchingJobQueueStatusReportsSelector(state),
  }),
  {
    fetchJobQueueStatusReports,
  },
)(JobQueueStatusReports);
