import React from 'react';
import Spinner from '@tls/ui-spinner';
import { Button } from '@pwc/appkit-react';

import styles from './downloadPdfPanel.module.scss';

interface GeneratePdfParams {
  hidePanel: () => void;
}

const GeneratePdf = ({ hidePanel }: GeneratePdfParams) => {
  return (
    <div className={styles.panelWrapper}>
      <div className={styles.panelItemWrapper}>
        <Spinner />
      </div>
      <span className={styles.text}>Generating your PDF.</span>
      <span className={styles.text}>
        You can close this panel. We will notify you when the file is ready for download.
      </span>
      <Button size="xl" kind="secondary" className={styles.button} onClick={hidePanel}>
        Close
      </Button>
    </div>
  );
};

export default GeneratePdf;
