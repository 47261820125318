import { dateCellFormatter } from '../shared/formatters';
import { LinkCellRendererFactory } from '../shared/columnDefinitions/cellRenderers';

const defaultColumnDefinition = {
  width: 120,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
};

const TaskLinkRenderer = LinkCellRendererFactory({
  getText: ({ value }) => value,
  getPathName: () => '', // TODO: Add path to sub tasks view - SLT-612
});

export const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Status',
    field: 'taskStatus',
    suppressMenu: false,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Tax Year',
    field: 'taxYear',
    width: 60,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
    width: 200,
    suppressMenu: false,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'EIN',
    field: 'ein',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Legal Entity ID',
    field: 'legalEntityId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
    suppressMenu: false,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'State',
    field: 'stateCode',
    width: 60,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Task',
    field: 'taskDescription',
    width: 300,
    cellRenderer: TaskLinkRenderer,
    suppressMenu: false,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Due Date',
    field: 'taskDueDate',
    valueFormatter: dateCellFormatter,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Assigned To',
    field: 'taskAssignedTo',
    width: 160,
    suppressMenu: false,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Manager',
    field: 'taskManagedBy',
    width: 160,
    suppressMenu: false,
  },
];
