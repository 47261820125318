import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const OverridablePagesWrapper = ({ children, isFormDirty, setFormDirty }) => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    if (isFormDirty !== dirty) {
      setFormDirty(dirty);
    }
  }, [setFormDirty, isFormDirty, dirty]);

  return <>{children}</>;
};

OverridablePagesWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isFormDirty: PropTypes.bool.isRequired,
  setFormDirty: PropTypes.func.isRequired,
};

export default OverridablePagesWrapper;
