import { SuppressKeyboardEventParams } from 'ag-grid-community';

export const overpaymentSelectOptions = [
  {
    label: 'Apply Overpayment to Next Year',
    value: '0',
  },
  {
    label: 'Refund Overpayment',
    value: '1',
  },
  {
    label: 'Apply Partial Overpayment, Refund Remainder',
    value: '2',
  },
];

export const firstInstallmentSelectOptions = [
  {
    label: 'Calculate Amount',
    value: '0',
  },
  {
    label: 'Use Input From Extension',
    value: '1',
  },
];

export const defaultSelectCellEditorSuppressKeyboardEvent = (
  cellEditorEvent: SuppressKeyboardEventParams,
) => {
  if (cellEditorEvent.event.key === 'Enter') {
    return true;
  }
};

export const columnBlueprintHeaderOptions = {
  headerHeight: 60,
  groupHeaderHeight: 40,
  enableBrowserTooltips: true,
};

export const partnershipSourceMap = new Map([
  ['G', 'GTW'],
  ['S', 'SLT'],
]);

export enum NodeLevel {
  ROOT,
  CHILD,
}

export enum CheckBoxGroupColumn {
  NAME,
  DESCRIPTION,
  VALUE,
}

export enum ColumnBlueprintBooleanTypeValue {
  FALSE = '0',
  TRUE = '1',
}
