import React from 'react';
import { connect } from 'react-redux';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import { isFetchingContextSelector } from '../store/selectors';
import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';

import FilingGroupBreadcrumbRoutes from './filingGroupBreadcrumbRoutes.component';

const FilingGroupPage = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
  breadcrumbRoutes: FilingGroupBreadcrumbRoutes,
}))(GlobalContextPage);

export const AuthorizedFilingGroupRoute = props => (
  <AuthorizedCustomRoute wrapper={FilingGroupPage} {...props} />
);
