import reducerFactory from '../../shared/store/reducerFactory';

import {
  ENABLE_USER_JOBS_POLLING,
  CANCEL_USER_JOBS_POLLING,
  REFRESH_USER_JOBS,
  UPDATE_USER_JOBS_POLLING_CONF,
  RESET_USER_JOBS_POLLING_CONF,
  ENABLE_USER_JOB_POLLING,
  CANCEL_USER_JOB_POLLING,
  UPDATE_USER_JOB_POLLING_CONF,
  RESET_USER_JOB_POLLING_CONF,
  ADD_JOB_DATA_TO_STORE,
  SET_SHOULD_CREATE_NOTIFICATION,
} from './types';

const initialState = {
  jobs: [],
  isPollingEnabled: false,
  pollingErrorCount: 0,
  pollingEnabledJobs: [],
  notificationEnabledJobIds: [],
  jobPollingErrorCount: {},
  jobIds: {
    updateFilingMemberJobId: null,
  },
};

const jobsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    [REFRESH_USER_JOBS]: (state, jobs) => ({
      ...state,
      jobs,
    }),
    [ENABLE_USER_JOBS_POLLING]: state => ({
      ...state,
      isPollingEnabled: true,
    }),
    [CANCEL_USER_JOBS_POLLING]: state => ({
      ...state,
      isPollingEnabled: false,
    }),
    [UPDATE_USER_JOBS_POLLING_CONF]: (state, payload) => ({
      ...state,
      pollingErrorCount: payload.newCount,
    }),
    [RESET_USER_JOBS_POLLING_CONF]: state => ({
      ...state,
      pollingErrorCount: initialState.pollingErrorCount,
    }),
    [ENABLE_USER_JOB_POLLING]: (state, jobId) => ({
      ...state,
      pollingEnabledJobs: [jobId, ...state.pollingEnabledJobs],
    }),
    [CANCEL_USER_JOB_POLLING]: (state, cancelledJobId) => ({
      ...state,
      pollingEnabledJobs: state.pollingEnabledJobs.filter(jobId => jobId !== cancelledJobId),
    }),
    [UPDATE_USER_JOB_POLLING_CONF]: (state, payload) => ({
      ...state,
      jobPollingErrorCount: {
        [payload.jobId]: payload.newCount,
        ...state.jobPollingErrorCount,
      },
    }),
    [RESET_USER_JOB_POLLING_CONF]: (state, jobId) => ({
      ...state,
      jobPollingErrorCount: {
        [jobId]: 0,
        ...state.jobPollingErrorCount,
      },
    }),
    [ADD_JOB_DATA_TO_STORE]: (state, payload) => ({
      ...state,
      [payload.jobId]: payload,
    }),
    [SET_SHOULD_CREATE_NOTIFICATION]: (state, payload) => ({
      ...state,
      notificationEnabledJobIds: payload.shouldCreateNotification
        ? [payload.jobId, ...state.notificationEnabledJobIds]
        : state.notificationEnabledJobIds.filter(jobId => jobId !== payload.jobId),
    }),
  },
});

export default jobsReducer;
