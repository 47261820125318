import store from '../store';
import {
  accessTokenExpirationDateSelector,
  setAccessTokenExpirationDate,
} from '../shared/store/auth';

export const handleAccessTokenExpirationDate = response => {
  const expirationDate = new Date(localStorage.getItem('expiration_date'));

  store.dispatch(setAccessTokenExpirationDate(expirationDate));

  return response;
};

export const getAccessTokenExpirationDate = () => {
  const expirationDate = accessTokenExpirationDateSelector(store.getState());
  const authBypassDateFallback = new Date('2099-01-01');

  if (!expirationDate) {
    return authBypassDateFallback;
  }

  return new Date(expirationDate * 1000);
};
