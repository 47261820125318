const Yup = require('yup');

const commonSchema = Yup.object().shape({
  uploadName: Yup.string()
    .required()
    .label('Attachment name')
    .max(40, 'Attachment Name cannot exceed 40 characters'),
  uploadDescription: Yup.string().max(400, 'Description cannot exceed 400 characters'),
  printOrder: Yup.number(),
  efileAttach: Yup.bool().required(),
  rolloverToNextYear: Yup.bool().required(),
});

const updatePdfAttachmentsSchema = Yup.object()
  .shape({
    rowsToUpdate: Yup.array()
      .of(
        Yup.object().shape({
          documentId: Yup.string().required(),
          docPrintOrder: Yup.number().required(),
          docDescription: Yup.string().required(),
          eFile: Yup.bool().required(),
          defRollover: Yup.bool().required(),
          docRollover: Yup.bool().required(),
        }),
      )
      .notOneOf([undefined, null]), // eslint-disable-line no-undefined
  })
  .strict();

const createPdfAttachmentSchema = commonSchema
  .concat(
    Yup.object().shape({
      taxYear: Yup.string().required(),
      period: Yup.string().required(),
      entityId: Yup.string().required(),
      documentId: Yup.string().required(),
    }),
  )
  .strict();

module.exports = {
  updatePdfAttachmentsSchema,
  createPdfAttachmentSchema,
  commonSchema,
};
