import { ColDef } from 'ag-grid-community';

import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';

export const getColumnDefinitions = () => {
  const columnDefinitions: ColDef[] = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Severity',
      field: 'severity',
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Code',
      field: 'rejectCode',
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Error Message',
      field: 'errorMessage',
      tooltipField: 'errorMessage',
      autoHeight: true,
      width: 300,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'XPath to Error',
      field: 'xpathToError',
      tooltipField: 'xpathToError',
      autoHeight: true,
      width: 300,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Message Detail',
      field: 'text',
      tooltipField: 'text',
      autoHeight: true,
      width: 300,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Record Name or No',
      field: 'recNameOrNo',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Form Occurrence No',
      field: 'formOccNo',
      width: 200,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Field Seq No',
      field: 'fieldSeqNo',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Form Number',
      field: 'formNumber',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Form Page No',
      field: 'formPageNo',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Rule Number',
      field: 'ruleNumber',
      width: 150,
    },
  ];

  return columnDefinitions;
};
