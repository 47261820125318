import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input, TextArea, Tooltip } from '@pwc/appkit-react';

import { OverridableFieldPropTypes } from '../../overridableForm.propTypes';
import { DataTypeNames } from '../../../enums';
import { getInputValueKey } from '../../utils/getInputValueKey';

import styles from './overridableFormInput.module.scss';

const NUMBER_TYPE_REGEXP = new RegExp(/^-?\d*\.?\d*$/, 'i');

const OverridableFormInput = ({
  field: { name, value },
  form: { setFieldValue },
  className = '',
  disabled = false,
  isFocused,
  handleFocus,
  handleFieldUpdateAndFormatting,
}) => {
  const { isOverridden, isEditable, formattedData, dataType, wrap } = value;

  const InputComponent = useMemo(() => (wrap ? TextArea : Input), [wrap]);
  const isNumberTypeInput = useMemo(
    () => [DataTypeNames.Currency, DataTypeNames.Integer, DataTypeNames.Ratio].includes(dataType),
    [dataType],
  );

  const handleChange = useCallback(
    newValue => {
      if (isNumberTypeInput && !NUMBER_TYPE_REGEXP.test(newValue)) {
        return;
      }
      const keyToSet = getInputValueKey({ isOverridden, isEditable });
      setFieldValue(name, {
        ...value,
        [keyToSet]: newValue,
        formattedData: newValue,
        dataType,
      });
    },
    [setFieldValue, name, value, isOverridden, isEditable, isNumberTypeInput, dataType],
  );

  const inputValueKey = getInputValueKey({ isOverridden, isEditable });

  const inputValue = isFocused ? value[inputValueKey] : formattedData;

  const onBlur = useCallback(() => {
    handleFieldUpdateAndFormatting({
      newValue: inputValue,
    });
  }, [handleFieldUpdateAndFormatting, inputValue]);

  const inputComponent = (
    <InputComponent
      className={`${styles.overridableFormInput} ${className}`}
      disabled={disabled}
      name={name}
      value={inputValue}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={handleFocus}
      maxLength={value.dataLength || null}
    />
  );

  return value.dataLength ? (
    <Tooltip
      content={`This field is limited to ${value.dataLength} characters.`}
      placement="top"
      trigger="focus"
      tooltipTheme="light"
    >
      {inputComponent}
    </Tooltip>
  ) : (
    inputComponent
  );
};

OverridableFormInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: OverridableFieldPropTypes.isRequired,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isFocused: PropTypes.bool.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleFieldUpdateAndFormatting: PropTypes.func.isRequired,
};

export default OverridableFormInput;
