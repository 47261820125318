import React, { useCallback, useMemo, useEffect } from 'react';
import camelCase from 'lodash.camelcase';
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { AttributesGroups } from '@common-packages/shared-constants';

import Checkbox from '../../../shared/forms/checkboxFormik/checkboxFormik.component';
import {
  FilingAttributeGroup,
  FilingAttributeChild,
  ConsPeriodFilingAttributes,
} from '../../../../../common/types/apiShapes';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import { useQueryReturnDefinitions } from '../../../shared/queries/returnDefinitions';
import { jurisdictionIdSelector, taxYearSelector } from '../../store/selectors';

import { ReturnDefinitionValues } from './addEditReturnDefinitionPanel.container';
import styles from './addEditReturnDefinitionPanel.module.scss';

const FILING_TYPE_DISPLAY_ORDER = [
  'FILING_TYPE_SEPARATE',
  'Consolidated Types',
  'FILING_TYPE_CONS_PRE_APP',
  'FILING_TYPE_CONS_POST_APP',
  'FILING_TYPE_CONS_UNITARY',
];

const CONS_PERIOD_TYPE = [
  'PERIOD_TYPE_ANNUAL',
  'PERIOD_TYPE_AMENDED',
  'PERIOD_TYPE_RAR',
  'PERIOD_TYPE_EXTENSION',
  'PERIOD_TYPE_SECOND_EXTENSION',
];

interface FilingAttributesProps {
  filingAttributes: FilingAttributeGroup[];
  disabledFilingAttributes?: string[];
  filingAttributeGroupClassNames?: Record<string, string>;
  handleChange?: (fieldName: string, newValue: boolean) => unknown;
}

const FilingAttributes = ({
  filingAttributes,
  disabledFilingAttributes,
  filingAttributeGroupClassNames = {},
  handleChange = () => null,
}: FilingAttributesProps) => {
  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const { values, setFieldValue } = useFormikContext<ReturnDefinitionValues>();

  const isBothConsFilingTypeAndConsPeriodType = useMemo(
    () =>
      Object.entries(values).some(
        ([key, value]) => value === true && key.startsWith('FILING_TYPE_CONS'),
      ) &&
      Object.entries(values).some(
        ([key, value]) => value === true && CONS_PERIOD_TYPE.includes(key),
      ),
    [values],
  );

  const { data: returnDefinitions } = useQueryReturnDefinitions({
    params: { taxYear, jurisdictionId },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const nonFilingSeparateReturnDefIdOptions = [{ label: 'None', value: '' }].concat(
    (returnDefinitions?.data || [])
      .filter(
        returnDefinition =>
          CONS_PERIOD_TYPE.some(
            consPeriodType => returnDefinition[consPeriodType as keyof ConsPeriodFilingAttributes],
          ) && returnDefinition.FILING_TYPE_SEPARATE,
      )
      .map(({ id: value, name: label }) => ({
        value,
        label,
      })),
  );

  const renderCheckbox = useCallback(
    (item: FilingAttributeChild) => (
      <Field
        key={item.field}
        label={item.displayName}
        name={item.field}
        disabled={disabledFilingAttributes?.includes(item.field)}
        component={Checkbox}
        customChangeHandler={handleChange}
      />
    ),
    [handleChange, disabledFilingAttributes],
  );

  const handleNonFilingSeparateReturnDefIdChange = useCallback(
    (_: unknown, value: string | { label: string; value: string | null } | null) => {
      setFieldValue('nonFilingSeparateReturnDefId', value);
    },
    [setFieldValue],
  );

  const handleFilingSeparateReturnDefIdChange = useCallback(
    (_: unknown, value: string | { label: string; value: string | null } | null) => {
      setFieldValue('filingSeparateReturnDefId', value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (
      !isBothConsFilingTypeAndConsPeriodType ||
      (!values.FILING_TYPE_CONS_POST_APP &&
        !values.FILING_TYPE_CONS_PRE_APP &&
        !values.FILING_TYPE_CONS_UNITARY)
    ) {
      setFieldValue('nonFilingSeparateReturnDefId', '');
    }
  }, [
    setFieldValue,
    values.FILING_TYPE_CONS_POST_APP,
    values.FILING_TYPE_CONS_PRE_APP,
    values.FILING_TYPE_CONS_UNITARY,
    isBothConsFilingTypeAndConsPeriodType,
  ]);

  useEffect(() => {
    if (!isBothConsFilingTypeAndConsPeriodType) {
      setFieldValue('filingSeparateReturnDefId', '');
    }
  }, [setFieldValue, isBothConsFilingTypeAndConsPeriodType]);

  const renderFilingType = useCallback(
    (filingAttribute: FilingAttributeGroup) => {
      return (
        <>
          {FILING_TYPE_DISPLAY_ORDER.map(item => {
            const filingItem = filingAttribute.children.find(attribute => attribute.field === item);
            if (filingItem) {
              return renderCheckbox(filingItem);
            }
            return (
              <span key={item} className={styles.separator}>
                {item}
              </span>
            );
          })}
          <span>
            <Select<string | null>
              hasTooltip
              wrapperClassName={styles.filingForm}
              appkitLabel="Non-Filing Separate Form"
              name="nonFilingSeparateReturnDefId"
              options={nonFilingSeparateReturnDefIdOptions}
              value={values.nonFilingSeparateReturnDefId}
              customChangeHandler={handleNonFilingSeparateReturnDefIdChange}
              disabled={!isBothConsFilingTypeAndConsPeriodType}
            />
          </span>
          <span>
            <Select<string | null>
              hasTooltip
              wrapperClassName={styles.filingForm}
              appkitLabel="Filing Separate Form"
              name="filingSeparateReturnDefId"
              options={nonFilingSeparateReturnDefIdOptions}
              value={values.filingSeparateReturnDefId}
              customChangeHandler={handleFilingSeparateReturnDefIdChange}
              disabled={!isBothConsFilingTypeAndConsPeriodType}
            />
          </span>
        </>
      );
    },
    [
      renderCheckbox,
      handleNonFilingSeparateReturnDefIdChange,
      handleFilingSeparateReturnDefIdChange,
      isBothConsFilingTypeAndConsPeriodType,
      values.nonFilingSeparateReturnDefId,
      values.filingSeparateReturnDefId,
      nonFilingSeparateReturnDefIdOptions,
    ],
  );

  return (
    <>
      {filingAttributes.map(filingAttribute => (
        <div
          key={filingAttribute.groupName}
          className={filingAttributeGroupClassNames[camelCase(filingAttribute.groupName)]}
        >
          <label className="a-form-label">{filingAttribute.displayName}</label>
          <div className={styles.filingAttributesGroup}>
            {filingAttribute.displayName === AttributesGroups.FILING_TYPE
              ? renderFilingType(filingAttribute)
              : filingAttribute.children.map(item => renderCheckbox(item))}
          </div>
        </div>
      ))}
    </>
  );
};

export default FilingAttributes;
