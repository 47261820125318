import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  taxYearSelector,
  jurisdictionIdSelector,
  periodSelector,
  entityIdSelector,
} from '../../shared/store/selectors';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportData from '../../shared/reports/useReportData.hook';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import getReportUrl from '../shared/getReportUrl';
import getExportMenuItems from '../shared/getExportMenuItems';

import columnDefinitions from './filingPositions.columnDefinitions';

const FilingPositions = ({ taxYear, period, entityId, jurisdictionId }) => {
  const endpointBasePath = getReportUrl('filing-positions');

  const { reportData, isFetchingReportData, fetchData } = useReportData({
    endpointBasePath,
    isFetchingAnyParam: false,
    params: useMemo(
      () => ({
        taxYear,
        period,
        entityId,
        jurisdictionId,
      }),
      [taxYear, period, entityId, jurisdictionId],
    ),
  });

  const isContextReady = taxYear && period && entityId && jurisdictionId;

  useEffect(() => {
    if (isContextReady) {
      fetchData();
    }
  }, [fetchData, isContextReady]);

  const getContextMenuItems = useCallback(getExportMenuItems, []);
  const { props, onGridReady } = useActionMenuForExport({ reportData });

  return (
    <>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={isContextReady ? reportData : []}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingReportData}
            getContextMenuItems={getContextMenuItems}
            onGridReady={onGridReady}
            withSearchBar
          />
        </div>
      </div>
    </>
  );
};

FilingPositions.propTypes = {
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
};

export default connect(state => ({
  taxYear: taxYearSelector(state),
  jurisdictionId: jurisdictionIdSelector(state),
  period: periodSelector(state),
  entityId: entityIdSelector(state),
}))(FilingPositions);
