import React from 'react';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';
import { DRILL_TYPE } from '@common-packages/drilling';

import { createDrillRoute } from '../shared/drilling';
import {
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../shared/columnDefinitions/cellRenderers';
import { currencyColumnDefinition, defaultColumnDefinition } from '../shared/columnDefinitions';
import { currencyCellFormatter, amountFormatter } from '../shared/formatters';
import getContextAwareUrlParams from '../utils/getContextAwareUrlParams';

import { taxSummariesColumnKeys, rowInfoKey } from './constants';

const getRowInfo = data => data[rowInfoKey];

const accountCellClassRules = {
  'bold-cell': ({ data }) => getRowInfo(data).isSummaryRow || getRowInfo(data).isHeader,
};

const underlineCellClassRules = {
  'underlined-cell': ({ data, colDef }) => {
    const rowInfo = getRowInfo(data);
    return (
      rowInfo.isLastRowInSection &&
      (rowInfo.sectionIndex === 0 ||
        colDef.field === taxSummariesColumnKeys.CURRENT_YEAR ||
        colDef.field === taxSummariesColumnKeys.PRIOR_YEAR)
    );
  },
};

const valueFormatter = props => {
  if (props.data.rowInfo.isEmptyRow) {
    return '';
  }

  return props.data.rowInfo.formatAsPercentage
    ? amountFormatter({
        value: props.value,
        props: {
          ...props.data.rowInfo,
          percentage: true,
          decimalPlaces: props.data.rowInfo.dataDecimalPlaces,
        },
      })
    : currencyCellFormatter(props);
};

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const ValueTextRenderer = TextCellRendererFactory({
  getText: ({ value }) => value,
});

const HeaderTextRenderer = TextCellRendererFactory({
  getText: ({ data }) => data.text,
});

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  ...currencyColumnDefinition,
  sortable: false,
};

const getColumnDefinitions = ({ urlParams, taxYear = '', taxYears }) => {
  const GenericCategoryCellRenderer = props => {
    const rowInfo = getRowInfo(props.data);
    const columnTaxYearValue = props.colDef.headerName;
    const isTaxYearExists = taxYears.some(
      taxYearOption => taxYearOption.value === columnTaxYearValue,
    );

    if (rowInfo.isGenericCategoryLink && isTaxYearExists) {
      const GenericCategoryLink = LinkCellRendererFactory({
        getText: ({ valueFormatted }) => valueFormatted,
        getPathName: (value, data) =>
          createDrillRoute(
            {
              drillType: DRILL_TYPE.GENERIC_DRILL,
              categoryId: data.rowInfo.categoryId,
            },
            getContextAwareUrlParams({ ...urlParams, taxYear: columnTaxYearValue }),
          ),
      });

      return <GenericCategoryLink {...props} />;
    }

    if (rowInfo.isTaxRateLink && isTaxYearExists) {
      const TaxRateDetailsLink = LinkCellRendererFactory({
        getText: ({ valueFormatted }) => valueFormatted,
        getPathName: () =>
          Routes.taxRateDetails.compiledRoute({
            ...getContextAwareUrlParams({ ...urlParams, taxYear: columnTaxYearValue }),
          }),
      });

      return <TaxRateDetailsLink {...props} />;
    }

    if (rowInfo.isApportionmentLink && isTaxYearExists) {
      const ApportionmentRateDetailsLink = LinkCellRendererFactory({
        getText: ({ valueFormatted }) => valueFormatted,
        getPathName: () =>
          Routes.apportionmentRateDetails.compiledRoute({
            ...getContextAwareUrlParams({ ...urlParams, taxYear: columnTaxYearValue }),
          }),
      });

      return <ApportionmentRateDetailsLink {...props} />;
    }

    return <TextRenderer {...props} />;
  };
  GenericCategoryCellRenderer.propTypes = {
    data: PropTypes.shape({
      [taxSummariesColumnKeys.ACCOUNT]: PropTypes.string,
    }),
    colDef: PropTypes.shape({
      headerName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };

  const AccountCellRenderer = props => {
    if (getRowInfo(props.data).isHeader) {
      return <HeaderTextRenderer {...props} />;
    } else if (getRowInfo(props.data).isLink) {
      const Link = LinkCellRendererFactory({
        getText: ({ data }) => data.text,
        getPathName: (value, data) => data.url,
        getQueryParams: (value, data) => data.queryString,
      });

      return <Link {...props} />;
    } else {
      return <ValueTextRenderer {...props} />;
    }
  };
  AccountCellRenderer.propTypes = {
    data: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      queryString: PropTypes.string,
    }),
  };

  const priorYear = taxYear ? String(Number(taxYear) - 1) : '';
  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      sortable: false,
      headerName: 'Account',
      field: taxSummariesColumnKeys.ACCOUNT,
      width: 250,
      cellClassRules: { ...accountCellClassRules },
      maxWidth: 320,
      colSpan: ({ data }) => {
        if (getRowInfo(data).isLink || getRowInfo(data).isHeader) {
          return 5;
        }
        return 1;
      },
      cellRenderer: AccountCellRenderer,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Data Collection',
      field: taxSummariesColumnKeys.DATA_COLLECTION,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Adjustments',
      field: taxSummariesColumnKeys.ADJUSTMENTS,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: taxYear,
      field: taxSummariesColumnKeys.CURRENT_YEAR,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: priorYear,
      field: taxSummariesColumnKeys.PRIOR_YEAR,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
