import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';

import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import FilingAttributes from '../../../shared/forms/filingAttributes/filingAttributes.component';
import CalcPreferencesSingleValue from '../../../shared/forms/calcPreferences/calcPreferencesSingleValue.component';
import { calcPreferencePropType } from '../../../shared/forms/calcPreferences/propTypes';
import { filingAttributesDefinitionTypes } from '../propTypes';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import TaxRatesAndConstantsFormHeader from '../taxRatesAndConstantsFormHeader.component';

import getRateTypesOptions from './getRateTypesOptions';
import styles from './taxRates.module.scss';
import { taxRateFormType } from './propTypes';

const TaxRateForm = ({ isEditMode, rowToEditValues, filingAttributes, calcPreferences }) => {
  const {
    values,
    isSubmitting,
    submitForm,
    dirty,
    initialValues,
    errors,
    resetForm,
  } = useFormikContext();
  const isLoading = false;
  const renderFilingAttributes = useCallback(
    () => (
      <FilingAttributes
        filingAttributes={filingAttributes}
        isLoading={isLoading}
        isColumnAligned
        hasHorizontalLayout
      />
    ),
    [filingAttributes, isLoading],
  );

  const onCancelClick = useCallback(() => resetForm(null, rowToEditValues), [
    rowToEditValues,
    resetForm,
  ]);

  return (
    <form>
      <TaxRatesAndConstantsFormHeader
        onCancelClick={onCancelClick}
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        dirty={dirty}
        title={`${isEditMode ? 'Edit' : 'Add'} Tax Rate`}
        errors={errors}
      />
      <div className={styles.taxRatesFormContainer}>
        <div className={styles.taxRatesFilingAttributesContainer}>
          {initialValues.jurisdictionDescription && (
            <span className="a-h5">{initialValues.jurisdictionDescription}</span>
          )}
          <div className={styles.taxRatesBottomContainer}>
            <div className={styles.taxRatesControlsContainer}>
              <div className={styles.taxRatesRangeContainer}>
                <Field
                  className={styles.taxRatesFormInput}
                  label="Rate"
                  name="rate"
                  component={Input}
                  autoComplete="off"
                  type="number"
                />
                <Select
                  className={styles.taxRatesFormInput}
                  appkitLabel="Rate Type"
                  name="taxType"
                  options={getRateTypesOptions()}
                  value={values.taxType}
                />
              </div>
              <div className={styles.taxRatesRangeContainer}>
                <Field
                  className={styles.taxRatesFormInput}
                  label="Start Range"
                  name="startRange"
                  component={Input}
                  autoComplete="off"
                  type="number"
                />
                <Field
                  className={styles.taxRatesFormInput}
                  label="Stop Range"
                  name="stopRange"
                  component={Input}
                  autoComplete="off"
                  type="number"
                  min="0"
                />
              </div>
              <div className={styles.taxRatesRangeContainer}>
                <Field
                  className={styles.taxRatesFormInput}
                  label="Exclusion Rate"
                  name="exclusionRate"
                  component={Input}
                  autoComplete="off"
                  type="number"
                />
                <Field
                  className={styles.taxRatesFormInput}
                  label="Exclusion Amount"
                  name="exclusionAmt"
                  component={Input}
                  autoComplete="off"
                  type="number"
                />
              </div>
            </div>
            <div>{renderFilingAttributes()}</div>
          </div>
        </div>

        <div className={styles.taxRatesCalcPreferencesContainer}>
          <CalcPreferencesSingleValue calcPreferences={calcPreferences} values={values} />
        </div>
      </div>
    </form>
  );
};

TaxRateForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  rowToEditValues: taxRateFormType,
  filingAttributes: filingAttributesDefinitionTypes,
  calcPreferences: calcPreferencePropType,
};

export default TaxRateForm;
