import { HttpMethods } from '../enums';
import { QueryError, QueryKeys } from '../queries';
import {
  InsertReturnDefinitionBody,
  UpdateReturnDefinitionBody,
} from '../../../../common/types/apiShapes';

import { useCustomMutation } from '.';

export const useMutationInsertReturnDefinition = () =>
  useCustomMutation<null, QueryError, InsertReturnDefinitionBody>({
    handleData: data => ({
      url: '/api/development/return-definitions',
      method: HttpMethods.POST,
      body: data,
    }),
    resetKey: QueryKeys.ReturnDefinitions.ReturnDefinitions,
    successMessage: 'Return definition added successfully',
    errorMessage: 'An error occurred while adding a return definition',
  });

export const useMutationUpdateReturnDefinition = () =>
  useCustomMutation<null, QueryError, UpdateReturnDefinitionBody>({
    handleData: data => ({
      url: '/api/development/return-definitions',
      method: HttpMethods.PUT,
      body: data,
    }),
    resetKey: QueryKeys.ReturnDefinitions.ReturnDefinitions,
    successMessage: 'Return definition updated successfully',
    errorMessage: 'An error occurred while updating a return definition',
  });
