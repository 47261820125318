import React, { useCallback } from 'react';
import { Checkbox } from '@pwc/appkit-react';
import { GridApi, ICellRendererParams } from 'ag-grid-community';

interface EditableCheckboxCellRendererProps {
  api: GridApi;
  value?: boolean;
  setValue?: ICellRendererParams['setValue'] | ((value: boolean) => void);
  disabled?: boolean;
  isDisabled?: (props: EditableCheckboxCellRendererProps) => boolean;
  label?: string;
  data: unknown;
}

const EditableCheckboxCellRenderer = (props: EditableCheckboxCellRendererProps) => {
  const { api, setValue, value, label, disabled, isDisabled } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;

      if (setValue) {
        setValue(value);
      }

      // preserve cell focus after value change
      const focusedCell = api.getFocusedCell();
      if (focusedCell) {
        api.setFocusedCell(focusedCell.rowIndex, focusedCell.column);
      }
    },
    [api, setValue],
  );

  const isCheckboxDisabled = disabled || (isDisabled && isDisabled(props));

  return (
    <Checkbox checked={Boolean(value)} disabled={isCheckboxDisabled} onChange={handleChange}>
      {label}
    </Checkbox>
  );
};

export default EditableCheckboxCellRenderer;
