import { ColDef } from 'ag-grid-community';

import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';
import { dateFormatter } from '../../../../shared/formatters';
import config from '../../../../config';
import { refreshStatusSourceMap } from '../../../../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';

const getRefreshStatusSourceLabel = (params: { data: { gtwRefreshStatus: number } }) =>
  refreshStatusSourceMap.get(params.data.gtwRefreshStatus) || null;

const getColumnDefinitions = (): ColDef[] => {
  const columnDefinition = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Correlation Id',
      field: 'correlationId',
      width: 300,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Date',
      field: 'createdOn',
      width: 150,
      valueFormatter: ({ value }: { value: null | string }) =>
        dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Refresh Status',
      field: 'gtwRefreshStatus',
      width: 100,
      valueGetter: getRefreshStatusSourceLabel,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Attempt Count',
      field: 'gtwRefreshFailCtr',
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Log Message',
      field: 'message',
      flex: 1,
    },
  ];

  return columnDefinition;
};

export default getColumnDefinitions;
