import { createSelector } from 'reselect';

import { rowInfoKey, taxSummariesColumnKeys, estimatedTaxSummariesColumnKeys } from '../constants';
import transformReportIntoRowData from '../../shared/store/utils/transformReportIntoRowData';

export const taxSummariesSelector = createSelector(
  [({ taxSummaries }) => taxSummaries.taxSummaries],
  taxSummaries => {
    const firstColumnKey = taxSummariesColumnKeys.ACCOUNT;
    const columnKeys = [
      taxSummariesColumnKeys.ADJUSTMENTS,
      taxSummariesColumnKeys.CURRENT_YEAR,
      taxSummariesColumnKeys.DATA_COLLECTION,
      taxSummariesColumnKeys.PRIOR_YEAR,
    ];

    return taxSummaries
      ? transformReportIntoRowData({
          firstColumnKey,
          rowInfoKey,
          columnKeys,
          reportData: taxSummaries,
        })
      : [];
  },
);

export const estimatedTaxSummarySelector = createSelector(
  [({ taxSummaries }) => taxSummaries.taxSummaries],
  taxSummaries => {
    const firstColumnKey = estimatedTaxSummariesColumnKeys.ACCOUNT;
    const columnKeys = [
      estimatedTaxSummariesColumnKeys.PRIOR_TAX_YEAR_RETURN,
      estimatedTaxSummariesColumnKeys.PRIOR_TAX_YEAR_EXTENSION,
      estimatedTaxSummariesColumnKeys.FIRST_QUARTER,
      estimatedTaxSummariesColumnKeys.SECOND_QUARTER,
      estimatedTaxSummariesColumnKeys.THIRD_QUARTER,
      estimatedTaxSummariesColumnKeys.FOURTH_QUARTER,
      estimatedTaxSummariesColumnKeys.CURRENT_TAX_YEAR_EXTENSION,
    ];
    return taxSummaries
      ? transformReportIntoRowData({
          firstColumnKey,
          rowInfoKey,
          columnKeys,
          reportData: taxSummaries,
        })
      : [];
  },
);

export const isFetchingTaxSummariesSelector = ({ taxSummaries }) =>
  taxSummaries.isFetchingTaxSummaries;
