import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';
import differenceBy from 'lodash.differenceby';
import once from 'lodash.once';

import {
  dataModelsOptionsSelector,
  isFetchingDataModelsWithGroupedDatasetsAndDataItemsSelector,
  isFetchingPdfPageDataUsageSelector,
} from '../store/selectors';
import { isFetchingFormsSelector } from '../../store/selectors';
import SDKCustomSelect from '../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import ListHierarchy from '../../../shared/displayComponents/listHierarchy/listHierarchy';
import AppkitIcon from '../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { infoNotification as showInfoNotification } from '../../../shared/notification/store/actions';
import WarningModal from '../../../shared/displayComponents/warningModal.component';
import useModal from '../../../shared/hooks/useModal.hook';
import {
  pdfFieldsByPageSelector,
  isFetchingPDFFieldsByPageSelector,
} from '../../../shared/store/dataModels/selectors';
import {
  assignedDatasetsTreePropTypes,
  availableDatasetsTreePropTypes,
  pdfPageDataUsagePropTypes,
  availableDatasetPropTypes,
} from '../propTypes';
import { PdfPageDataUsageTypes } from '../constants';
import findChildrenOfSelectedDataset from '../utils/findChildrenOfSelectedDataset';
import findTopMostParentOfSelectedDataset from '../utils/findTopMostParentOfSelectedDataset';

import styles from './dataset.module.scss';

const Dataset = ({
  availableDatasetsTree,
  assignedDatasetsTree,
  assignedDatasets,
  dataModelId,
  selectDataModelId,
  handleDatasetUnassign,
  handleDatasetAssign,
  selectedAvailableDatasets,
  selectedAssignedDatasets,
  handleSelectedAvailableDatasets,
  handleSelectedAssignedDatasets,
}) => {
  const dispatch = useDispatch();

  const [selectedPageNumber, setSelectedPageNumber] = useState(null);

  const dataModelsOptions = useSelector(dataModelsOptionsSelector);
  const isFetchingDataModelsWithGroupedDatasetsAndDataItems = useSelector(
    isFetchingDataModelsWithGroupedDatasetsAndDataItemsSelector,
  );
  const isFetchingPdfPageDataUsage = useSelector(isFetchingPdfPageDataUsageSelector);
  const isFetchingTaxForms = useSelector(isFetchingFormsSelector);
  const isFetchingPdfFieldsByPage = useSelector(isFetchingPDFFieldsByPageSelector);
  const pdfFieldsByPage = useSelector(pdfFieldsByPageSelector);

  const { showModal: showWarningModal, modalProps: warningModalProps } = useModal();

  useEffect(() => {
    handleSelectedAvailableDatasets([]);
  }, [handleSelectedAvailableDatasets, dataModelId]);

  useEffect(() => {
    handleSelectedAssignedDatasets([]);
    setSelectedPageNumber(null);
  }, [handleSelectedAssignedDatasets]);

  const onAvailableDatasetClick = useCallback(
    dataset => {
      handleSelectedAssignedDatasets([]);

      if (selectedAvailableDatasets.some(({ id }) => id === dataset.id)) {
        handleSelectedAvailableDatasets(
          selectedAvailableDatasets.filter(({ id }) => id !== dataset.id),
        );
        return;
      }
      handleSelectedAvailableDatasets([...selectedAvailableDatasets, dataset]);
    },
    [handleSelectedAssignedDatasets, handleSelectedAvailableDatasets, selectedAvailableDatasets],
  );

  const onAssignedDatasetClick = useCallback(
    dataset => {
      // select page, reset assigned datasets array
      if (dataset.type === PdfPageDataUsageTypes.PAGE) {
        handleSelectedAssignedDatasets([]);
        setSelectedPageNumber(pageNumber => (pageNumber === dataset.id ? null : dataset.id));
        return;
      }

      // remove clicked dataset if it's in assigned datasets
      if (selectedAssignedDatasets.some(({ id }) => id === dataset.id)) {
        const doesParentDatasetExist = selectedAssignedDatasets.some(
          selectedDataset => dataset.treeHierarchyParentId === selectedDataset.treeHierarchyId,
        );

        const assignedDatasetsExceptClickedDataset = selectedAssignedDatasets.filter(
          selectedDataset => selectedDataset.id !== dataset.id,
        );

        if (!doesParentDatasetExist) {
          handleSelectedAssignedDatasets(assignedDatasetsExceptClickedDataset);
          return;
        }

        // If a selected dataset already has parents, we remove them from assigned datasets first
        const topMostParentOfSelectedDataset = findTopMostParentOfSelectedDataset(
          dataset,
          assignedDatasets,
        );

        const allChildrenOfTopMostParent = findChildrenOfSelectedDataset(
          topMostParentOfSelectedDataset,
          assignedDatasets,
        );

        const filterParentsOfDatasetFromSelectedAssignedDatasets = differenceBy(
          selectedAssignedDatasets,
          [topMostParentOfSelectedDataset, ...allChildrenOfTopMostParent],
          'treeHierarchyId',
        );

        handleSelectedAssignedDatasets(filterParentsOfDatasetFromSelectedAssignedDatasets);
        return;
      }

      // add clicked dataset and its children to assigned datasets array
      const datasetChildren = findChildrenOfSelectedDataset(dataset, assignedDatasets);
      handleSelectedAssignedDatasets([...selectedAssignedDatasets, ...datasetChildren, dataset]);

      // reset page and available datasets
      setSelectedPageNumber(null);
      handleSelectedAvailableDatasets([]);
    },
    [
      selectedAssignedDatasets,
      handleSelectedAvailableDatasets,
      handleSelectedAssignedDatasets,
      assignedDatasets,
    ],
  );

  const handleAssign = useCallback(() => {
    const datasetsAlreadyAssigned = selectedAvailableDatasets.filter(dataset => {
      return assignedDatasets.some(
        assignedDataset =>
          assignedDataset.type === PdfPageDataUsageTypes.DATASET_USAGE &&
          assignedDataset.datasetId === dataset.id &&
          assignedDataset.pageNumber === Number(selectedPageNumber),
      );
    });

    if (datasetsAlreadyAssigned.length) {
      dispatch(
        showInfoNotification(
          `${datasetsAlreadyAssigned.length} selected dataset${
            datasetsAlreadyAssigned.length > 1 ? 's' : ''
          } already assigned`,
        ),
      );
      return;
    }
    handleDatasetAssign(selectedPageNumber);
  }, [
    dispatch,
    handleDatasetAssign,
    selectedAvailableDatasets,
    selectedPageNumber,
    assignedDatasets,
  ]);

  const triggerUnassign = useCallback(() => {
    handleDatasetUnassign();
    handleSelectedAssignedDatasets([]);
  }, [handleDatasetUnassign, handleSelectedAssignedDatasets]);

  const handleUnassign = useCallback(() => {
    const selectedAssignedDatasetsSet = new Set(selectedAssignedDatasets);
    const messages = [...selectedAssignedDatasetsSet].reduce(
      (acc, { datasetId, name, pageNumber }) => {
        const amount = pdfFieldsByPage.filter(field => field.datasetId === datasetId).length;
        if (amount) {
          return [
            ...acc,
            {
              description: `Unassigning ${name} on page ${pageNumber} will unmap ${amount} dataitems.`,
            },
          ];
        } else {
          return acc;
        }
      },
      [],
    );

    if (messages.length) {
      showWarningModal(messages);
    } else {
      triggerUnassign();
    }
  }, [showWarningModal, triggerUnassign, selectedAssignedDatasets, pdfFieldsByPage]);

  const disableAssignButton =
    !selectedAvailableDatasets.length || !selectedPageNumber || isFetchingPdfFieldsByPage;
  const disableUnassignButton = !selectedAssignedDatasets.length || isFetchingPdfFieldsByPage;

  const selectDefaultDataset = useMemo(
    () =>
      once(datasetsTree => {
        const datasets = datasetsTree[0].children;
        // Name constants required in SLT-6371
        const datasetsSelected = datasets.filter(
          ({ data }) => data.name !== 'CommonData' && data.name !== 'efileCommonData',
        );
        if (datasetsSelected.length && !isFetchingPdfPageDataUsage && !isFetchingTaxForms) {
          const datasetSelected = datasetsSelected[0];
          selectDataModelId({ value: datasetSelected.data.dataModelId });
        }
      }),
    [selectDataModelId, isFetchingPdfPageDataUsage, isFetchingTaxForms],
  );

  useEffect(() => {
    if (!isFetchingPdfPageDataUsage && !isFetchingTaxForms && assignedDatasetsTree.length) {
      selectDefaultDataset(assignedDatasetsTree);
    }
  }, [selectDefaultDataset, isFetchingPdfPageDataUsage, isFetchingTaxForms, assignedDatasetsTree]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.dataModelDropdown}>
          <SDKCustomSelect
            appkitLabel="Data Models"
            className="sdk-custom-select"
            options={dataModelsOptions}
            value={dataModelId}
            virtualized
            onChange={selectDataModelId}
            disabled={
              !dataModelsOptions.length || isFetchingDataModelsWithGroupedDatasetsAndDataItems
            }
            isLoading={isFetchingDataModelsWithGroupedDatasetsAndDataItems}
          />
        </div>
        <div className={styles.availableDatasets}>
          <p className={styles.datasetsBox}>Available DataSets</p>
          <div className={styles.topShadow} />
          <ListHierarchy
            className={styles.customListHierarchy}
            isLoading={isFetchingDataModelsWithGroupedDatasetsAndDataItems}
            items={availableDatasetsTree}
            selectedItemIds={selectedAvailableDatasets.map(({ id }) => id)}
            itemOnClick={onAvailableDatasetClick}
          />
        </div>
        <div className={styles.buttons}>
          <Button size="lg" disabled={disableAssignButton} onClick={handleAssign}>
            Assign
            <AppkitIcon className={styles.rightChevron} icon="right-chevron" size={16} />
          </Button>
          <Button size="lg" disabled={disableUnassignButton} onClick={handleUnassign}>
            <AppkitIcon className={styles.leftChevron} icon="left-chevron" size={16} />
            Unassign
          </Button>
        </div>
        <div className={styles.assignedDatasets}>
          <p className={styles.datasetsBox}>Assigned DataSets</p>
          <div className={styles.topShadow} />
          <ListHierarchy
            className={styles.customListHierarchy}
            isLoading={isFetchingPdfPageDataUsage || isFetchingTaxForms}
            items={assignedDatasetsTree}
            selectedItemIds={[...selectedAssignedDatasets.map(({ id }) => id), selectedPageNumber]}
            itemOnClick={onAssignedDatasetClick}
          />
        </div>
      </div>
      <WarningModal
        {...warningModalProps}
        title="Unassign dataset?"
        submitText="Proceed"
        submitAction={triggerUnassign}
      />
    </>
  );
};

Dataset.propTypes = {
  dataModelId: PropTypes.string,
  selectDataModelId: PropTypes.func.isRequired,
  handleDatasetUnassign: PropTypes.func.isRequired,
  handleDatasetAssign: PropTypes.func.isRequired,
  selectedAvailableDatasets: PropTypes.arrayOf(availableDatasetPropTypes),
  selectedAssignedDatasets: PropTypes.arrayOf(pdfPageDataUsagePropTypes),
  handleSelectedAvailableDatasets: PropTypes.func.isRequired,
  handleSelectedAssignedDatasets: PropTypes.func.isRequired,
  availableDatasetsTree: availableDatasetsTreePropTypes.isRequired,
  assignedDatasetsTree: assignedDatasetsTreePropTypes.isRequired,
  assignedDatasets: PropTypes.arrayOf(pdfPageDataUsagePropTypes).isRequired,
};

export default Dataset;
