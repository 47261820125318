import {
  defaultColumnDefinitionWithFilter,
  currencyColumnDefinition,
  percentageColumnDefinition,
} from '../../shared/columnDefinitions';

const getExtendedDefaultColumnDefinition = getGroupingCellClassRules => ({
  ...defaultColumnDefinitionWithFilter,
  sortable: false,
  cellClassRules: {
    ...defaultColumnDefinitionWithFilter.cellClassRules,
    ...getGroupingCellClassRules(),
  },
});

const getColumnDefinitions = ({ getGroupingCellClassRules }) => {
  const columnDefinitions = [
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Jurisdiction',
      field: 'jurisdictionDesc',
      width: 110,
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Payer Name',
      field: 'payerName',
      width: 300,
      aggFunc: 'subtotalFooter',
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Payer Id',
      field: 'payerId',
      width: 80,
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Type',
      field: 'type',
      width: 80,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Form',
      field: 'form',
      width: 80,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Schedule',
      field: 'schedule',
      width: 80,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Line',
      field: 'line',
      width: 80,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      ...percentageColumnDefinition,
      headerName: 'Ownership Percentage',
      field: 'ownershipPct',
      width: 110,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Account Id',
      field: 'accountId',
      width: 200,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Category Id',
      field: 'categoryId',
      width: 200,
      rowGroup: true,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      ...currencyColumnDefinition,
      headerName: 'Amount',
      field: 'amount',
      aggFunc: 'sum',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Multiplier',
      field: 'multiplier',
      cellStyle: {
        textAlign: 'right',
      },
      width: 80,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      ...currencyColumnDefinition,
      headerName: 'Drd Amount',
      field: 'drdAmt',
      aggFunc: 'sum',
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
