import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { createDataProvider } from '../../shared/dataProviders';
import { entityIdSelector, taxYearSelector, periodSelector } from '../../shared/store/selectors';
import { fetchNonFilingStates } from '../store/actions';

const NonFilingStatesDataProvider = ({
  children,

  taxYear,
  period,
  entityId,
  fetchNonFilingStates,
}) => {
  useEffect(() => {
    if (!taxYear || !period || !entityId) {
      return;
    }

    fetchNonFilingStates({
      taxYear,
      period,
      entityId,
    });
  }, [fetchNonFilingStates, taxYear, period, entityId]);

  return children;
};

NonFilingStatesDataProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
};

export default createDataProvider({
  ProviderComponent: connect(
    state => ({
      taxYear: taxYearSelector(state),
      period: periodSelector(state),
      entityId: entityIdSelector(state),
    }),
    {
      fetchNonFilingStates,
    },
  )(NonFilingStatesDataProvider),
});
