import {
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
  TaxReturnDefinitionWithFilingAttributes,
} from '../../../../../common/types/apiShapes';

const isReturnDefinitionUsedInAnotherReturn = (
  returnDefinition: TaxReturnDefinitionWithFilingAttributes,
  allTaxReturns: TaxReturnResultRowWithCalcOptionsAndFilingAttributes[],
) =>
  allTaxReturns.some(
    existingTaxReturn =>
      existingTaxReturn.jurisdictionId === returnDefinition.jurisdictionId &&
      existingTaxReturn.taxReturn?.returnDefinitionId === returnDefinition.id,
  );

export default isReturnDefinitionUsedInAnotherReturn;
