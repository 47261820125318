import React from 'react';

import styles from './entityInformationDetails.module.scss';

interface EntityInformationDetailsProps {
  label?: string;
  value?: string;
}

const entityInformationDetailsColumn = ({ label, value }: EntityInformationDetailsProps) => {
  return (
    <div className="col">
      <div className={styles.informationField}>
        <label className={styles.informationLabel}>{label}</label>
        <span>{value}</span>
      </div>
    </div>
  );
};

export default entityInformationDetailsColumn;
