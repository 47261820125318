import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const binderItemsSelector = ({ development }) => development.defaultBinders.binderItems;

export const isFetchingBinderItemsSelector = ({ development }) =>
  development.defaultBinders.isFetchingBinderItems;

export const isFetchingBinderItemTypesSelector = ({ development }) =>
  development.defaultBinders.isFetchingBinderItemTypes;

const binderItemTypesSelector = ({ development }) => development.defaultBinders.binderItemTypes;

export const binderItemTypeOptionsSelector = optionsSelectorFactory({
  listSelector: binderItemTypesSelector,
  labelKey: 'name',
  valueKey: 'value',
});

export const isFetchingFormsSelector = ({ development }) =>
  development.defaultBinders.isFetchingForms;

const formsSelector = ({ development }) => development.defaultBinders.forms;

export const formOptionsSelector = optionsSelectorFactory({
  listSelector: formsSelector,
  labelKey: 'name',
  valueKey: 'id',
});

export const isUpdatingBinderItemsSelector = ({ development }) =>
  development.defaultBinders.isUpdatingBinderItems;
