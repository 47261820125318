import { ColDef } from 'ag-grid-community';

import {
  defaultColumnDefinitionWithFilter,
  getGtwRefreshFailCountColumn,
} from '../../../../../shared/columnDefinitions';
import { dateFormatter } from '../../../../../shared/formatters';
import config from '../../../../../config';
import { refreshStatusSourceMap } from '../../../../../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';
import { federalDataDetailsLinkRenderer } from '../../federalDataDetails/federalDataDetailsLinkRenderer';

const getRefreshStatusSourceLabel = (params: { data: { gtwRefreshStatus: number } }) =>
  refreshStatusSourceMap.get(params.data.gtwRefreshStatus) || null;

const getColumnDefinitions = (): ColDef[] => {
  const columnDefinition = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      headerName: 'Entity ID',
      field: 'orgId',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name',
      field: 'orgName',
      width: 500,
      cellRendererFramework: federalDataDetailsLinkRenderer(),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Updated On',
      field: 'sltUpdatedOn',
      width: 200,
      valueFormatter: ({ value }: { value: null | string }) =>
        dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Source Update On',
      field: 'gtwUpdatedDate',
      width: 200,
      valueFormatter: ({ value }: { value: null | string }) =>
        dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Refresh Status',
      field: 'gtwRefreshStatus',
      width: 150,
      valueGetter: getRefreshStatusSourceLabel,
    },
    getGtwRefreshFailCountColumn('gtwRefreshFailCtr'),
  ];

  return columnDefinition;
};

export default getColumnDefinitions;
