import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../../shared/hooks/useModal.hook';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';

import { fetchBusinesses, deleteBusiness } from './store/actions';
import AddEditBusinessesFormContainer from './addEditBusinessesForm.container';
import {
  businessesSelector,
  businessGroupsSelector,
  parentsSelector,
  customersSelector,
  isFetchingBusinessesSelector,
  isCreatingBusinessSelector,
  isUpdatingBusinessSelector,
} from './store/selectors';
import getColumnDefinitions from './businesses.columnDefinitions';
import { businessesPropTypes } from './propTypes';

const Businesses = ({
  fetchBusinesses,
  showConfirmModal,
  deleteBusiness,

  businesses,
  businessGroups,
  parents,
  customers,

  isFetchingBusinesses,
  isCreatingBusiness,
  isUpdatingBusiness,
}) => {
  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  const { showModal, modalProps } = useModal();
  const [businessToEdit, setBusinessToEdit] = useState(null);

  const openModalForAdd = useCallback(() => {
    setBusinessToEdit(null);
    showModal();
  }, [showModal]);

  const openModalForEdit = useCallback(
    row => {
      setBusinessToEdit(row);
      showModal();
    },
    [showModal],
  );

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete business?',
        text: 'Are you sure you want to delete this business?',
        confirmCallback: async () => {
          await deleteBusiness({
            businessId: row.businessId,
          });
          fetchBusinesses();
        },
      });
    },
    [showConfirmModal, deleteBusiness, fetchBusinesses],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onEditIconClick: openModalForEdit,
        onDeleteIconClick: openModalForDelete,
        businessGroups,
        parents,
        customers,
      }),
    [openModalForEdit, openModalForDelete, businessGroups, parents, customers],
  );

  const isLoading = isFetchingBusinesses || isCreatingBusiness || isUpdatingBusiness;

  return (
    <>
      <div className="row">
        <div className="col add-button-column">
          <Button size="lg" className="add-button" onClick={openModalForAdd} disabled={isLoading}>
            Add Business
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col-12">
          <AgGrid
            rowData={businesses}
            isGridLoading={isLoading}
            singleClickEdit
            columnDefs={columnDefinitions}
          />
        </div>
        <AddEditBusinessesFormContainer
          {...modalProps}
          businessToEdit={businessToEdit}
          businesses={businesses}
          businessGroups={businessGroups}
          customers={customers}
        />
      </div>
    </>
  );
};

Businesses.propTypes = {
  businesses: PropTypes.arrayOf(businessesPropTypes).isRequired,
  businessGroups: PropTypes.arrayOf(SelectOptionPropTypes),
  parents: PropTypes.arrayOf(SelectOptionPropTypes),
  customers: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingBusinesses: PropTypes.bool.isRequired,
  isCreatingBusiness: PropTypes.bool.isRequired,
  isUpdatingBusiness: PropTypes.bool.isRequired,
  fetchBusinesses: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  deleteBusiness: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    businesses: businessesSelector(state),
    businessGroups: businessGroupsSelector(state),
    parents: parentsSelector(state),
    customers: customersSelector(state),
    isFetchingBusinesses: isFetchingBusinessesSelector(state),
    isCreatingBusiness: isCreatingBusinessSelector(state),
    isUpdatingBusiness: isUpdatingBusinessSelector(state),
  }),
  {
    fetchBusinesses,
    showConfirmModal,
    deleteBusiness,
  },
)(Businesses);
