import { DatasetInstancesSortOrderBody, UpdateDatasetInstancesSortOrderBody } from '@tls/slt-types';

import { HttpMethods } from '../enums';
import { QueryError, QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export interface UpdateDatasetInstancesSortOrderVariable {
  datasetInstances: DatasetInstancesSortOrderBody;
}

export const useMutationUpdateDatasetInstancesSortOrder = () =>
  useCustomMutation<
    null,
    QueryError,
    UpdateDatasetInstancesSortOrderVariable,
    UpdateDatasetInstancesSortOrderBody
  >({
    handleData: ({ datasetInstances }) => ({
      url: `/api/shared/data-models/workpaper-instance/reorder-instances`,
      method: HttpMethods.PUT,
      body: { datasetInstances },
    }),
    resetKey: QueryKeys.DataModels.DatasetInstances,
    successMessage: 'Updated dataset instances sort order successfully',
    errorMessage: 'Updating dataset instances sort order failed',
  });
