import { useRouteMatch } from 'react-router-dom';
import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TOTAL_DESC } from '@common-packages/shared-constants';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { globalContextSelector } from '../../../shared/store/selectors';
import { fetchFilingDecisionsAmountDetails } from '../../store/actions';
import { isFetchingAmountDetailsSelector, amountDetailsSelector } from '../../store/selectors';

import getColumnDefinitions from './filingDecisionsAmountDetails.columnDefinitions';
import styles from './filingDecisionsAmountDetails.module.scss';

const FilingDecisionAmountDetails = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const globalContext = useSelector(globalContextSelector);
  const isFetchingAmountDetails = useSelector(isFetchingAmountDetailsSelector);
  const amountDetails = useSelector(amountDetailsSelector);

  useEffect(() => {
    if (globalContext.isReady) {
      const { taxYear, period, jurisdictionId } = globalContext.params;
      dispatch(
        fetchFilingDecisionsAmountDetails({
          taxYear,
          period,
          orgId: globalContext.entityId,
          jurisdictionId,
          categoryName: match.params.categoryName,
        }),
      );
    }
  }, [dispatch, globalContext, match.params.categoryName]);

  const rowData = [
    ...amountDetails,
    {
      businessId: TOTAL_DESC,
      accountAmount: amountDetails.reduce((acc, curr) => acc + curr.accountAmount, 0),
    },
  ];

  const columnDefinitions = useMemo(() => getColumnDefinitions({ globalContext }), [globalContext]);

  return (
    <div className={`${styles.filingDecisionAmountDetails} row grid-row`}>
      <div className="col">
        <AgGrid
          rowData={rowData}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingAmountDetails}
        />
      </div>
    </div>
  );
};

export default FilingDecisionAmountDetails;
