import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './stepTab.module.scss';

interface StepTabContentProps {
  className?: string;
  children?: ReactNode;
}

const StepTabContent = ({ className, children }: StepTabContentProps) => {
  return <div className={classnames(styles.tabContent, className)}>{children}</div>;
};

export default StepTabContent;
