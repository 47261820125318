import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';

import styles from './styles.module.scss';

const AddEditRoleForm = ({
  isUpdatingRole,
  isCreatingRole,
  isSubmitting,
  roles = [],
  roleToEdit = null,
  values,
  onCancelClick,
  submitForm,
}) => {
  const buttonsDisabled = isUpdatingRole || isSubmitting || isCreatingRole;
  const rolesOptions = useMemo(
    () =>
      roles?.map(({ roleId, roleName }) => ({
        label: roleName,
        value: roleId,
      })),
    [roles],
  );

  return (
    <form className={styles.addRoleForm}>
      <Field
        label="Role Name"
        name="roleName"
        className="form-text modal-field"
        component={Input}
        autoComplete="off"
      />
      {!roleToEdit && (
        <Select
          appkitLabel="Create New Role Based On"
          name="basedOnRoleId"
          options={rolesOptions}
          value={values.basedOnRoleId}
        />
      )}
      <ButtonsGroup
        className={styles.buttonsGroup}
        isSubmitting={isSubmitting}
        disabled={buttonsDisabled}
        submitForm={submitForm}
        onCancelClick={onCancelClick}
        submitButtonLabel="Save"
      />
    </form>
  );
};

AddEditRoleForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleName: PropTypes.string,
      roleId: PropTypes.string,
    }),
  ),
  roleToEdit: PropTypes.shape({
    roleName: PropTypes.string,
    roleId: PropTypes.string,
  }),
  values: PropTypes.shape({
    roleName: PropTypes.string,
    basedOnRoleId: PropTypes.string,
  }),
  onCancelClick: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isUpdatingRole: PropTypes.bool.isRequired,
  isCreatingRole: PropTypes.bool.isRequired,
};

export default AddEditRoleForm;
