import React, { useCallback, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Button, Banner } from '@pwc/appkit-react';
import { SelectOption } from '@tls/slt-types';

import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../../../shared/forms/checkboxFormik/checkboxFormik.component';
import Radio from '../../../shared/forms/radioFormik/radioFormik.component';
import TextArea from '../../../shared/forms/textareaFormik/textareaFormik.component';
import {
  FilingAttributes as IFilingAttributes,
  FilingAttributeGroup,
} from '../../../../../common/types/apiShapes';
import { FederalReturnAttachments } from '../../../shared/enums';
import {
  FEDERAL_RETURN_ATTACHMENT_OPTIONS,
  DUE_DATE_DAY_OPTIONS,
  DUE_DATE_MONTH_OPTIONS,
  DUE_DATE_EXT_AND_SECOND_EXT_MONTH_OPTIONS,
} from '../constants';

import FilingAttributes from './filingAttributes.component';
import styles from './addEditReturnDefinitionPanel.module.scss';
import { ReturnDefinitionValues } from './addEditReturnDefinitionPanel.container';
import useSetFilingMethodsAllowed from './hooks/useSetFilingMethodsAllowed.hook';

interface Form extends IFilingAttributes {
  formName: string;
  formId: string;
  formDescription: string;
}

interface AddEditReturnDefinitionFormProps {
  isEditing: boolean;
  forms: Form[];
  schemaIdOptions: SelectOption[];
  filingAttributes: FilingAttributeGroup[];
  hidePanel: () => void;
}

const AddEditReturnDefinitionForm = ({
  isEditing,
  forms,
  schemaIdOptions,
  filingAttributes,
  hidePanel,
}: AddEditReturnDefinitionFormProps) => {
  const {
    values,
    initialValues,
    setFieldValue,
    setFieldTouched,
    dirty,
    isSubmitting,
    submitForm,
    isValid,
  } = useFormikContext<ReturnDefinitionValues>();

  schemaIdOptions = [{ label: 'None', value: '' }, ...schemaIdOptions];

  const renderBanner = useCallback(() => {
    if (isEditing && dirty) {
      return (
        <Banner
          className={styles.calcOptionsBanner}
          status="warning"
          content={`Filing Attributes have changed for the ${
            forms.find(({ formId }) => formId === values.filingFormId)?.formName
          } filing form. Verify Filing Attributes and change if needed.`}
          closeable
        />
      );
    }
  }, [dirty, isEditing]);

  const getNewDisabledFilingAttributes = useCallback(
    (filingForm: Form) => {
      const filingAttributesFields = filingAttributes.flatMap(({ children }) =>
        children.map(({ field }) => field),
      );

      const newDisabledFilingAttributes = filingAttributesFields.reduce(
        (acc: (keyof IFilingAttributes)[], field) => (filingForm?.[field] ? acc : [...acc, field]),
        [],
      );

      newDisabledFilingAttributes.forEach(attribute => {
        setFieldValue(attribute, false);
      });

      filingAttributes.forEach(attributeGroup => {
        const disabledChildrenCount = attributeGroup.children.reduce(
          (acc, { field }) => (newDisabledFilingAttributes.includes(field) ? acc + 1 : acc),
          0,
        );

        // if there is only enabled attribute in the group
        if (disabledChildrenCount === attributeGroup.children.length - 1) {
          const singleEnabledAttribute = attributeGroup.children.find(
            ({ field }) => !newDisabledFilingAttributes.includes(field),
          );
          // technically this if is unnecessary but TS requires it
          if (singleEnabledAttribute) {
            setFieldValue(singleEnabledAttribute.field, true);
            newDisabledFilingAttributes.push(singleEnabledAttribute.field);
          }
        }
      });

      return newDisabledFilingAttributes;
    },
    [setFieldValue, filingAttributes],
  );

  const [disabledFilingAttributes, setDisabledFilingAttributes] = useState<string[]>(() => {
    const form = forms.find(({ formId }) => formId === values.filingFormId);
    return form ? getNewDisabledFilingAttributes(form) : [];
  });

  const formsOptions = useMemo(
    () => forms.map(({ formId, formName }) => ({ label: formName, value: formId })),
    [forms],
  );

  const handleFormChange = useCallback(
    (_: unknown, newFormId: string | { label: string; value: string }) => {
      const newForm = forms.find(({ formId }) => formId === newFormId);
      if (newForm) {
        const newDisabledFilingAttributes = getNewDisabledFilingAttributes(newForm);

        setDisabledFilingAttributes(newDisabledFilingAttributes);
        setFieldValue('name', `${newForm?.formName || ''} - ${newForm?.formDescription || ''}`);
        setFieldTouched('name');
      }
    },
    [getNewDisabledFilingAttributes, setFieldValue, setFieldTouched, forms],
  );

  const handleSchemaChange = useCallback(
    (_: unknown, newValue: string | { label: string; value: string | null } | null) => {
      if (!newValue) {
        setFieldValue('filingWarning', '');
      }
    },
    [setFieldValue],
  );

  const handleLinkedReturnChange = useCallback(
    (_: unknown, newValue: FederalReturnAttachments) => {
      const booleanNewValue = newValue === FederalReturnAttachments.LINKED;
      setFieldValue('isFederalXmlRequired', booleanNewValue);
      setFieldValue('isFederalAsFiledRequested', booleanNewValue);
    },
    [setFieldValue],
  );

  const handleMandatedChange = useCallback(
    (_: unknown, newValue: boolean) => {
      if (!newValue) {
        setFieldValue('failureToEfilePenalty', '');
        setFieldValue('filingWarning', '');
      }
    },
    [setFieldValue],
  );

  const { isEfileAllowedFieldDisabled, isPaperAllowedFieldDisabled } = useSetFilingMethodsAllowed();

  const isAtLeastOneAttributePerGroupSelected = useMemo(
    () => filingAttributes.every(({ children }) => children.some(({ field }) => values[field])),
    [filingAttributes, values],
  );

  const isSaveButtonDisabled = !dirty || !isAtLeastOneAttributePerGroupSelected || !isValid;

  return (
    <>
      <div className={styles.fields}>
        <Select<string>
          wrapperClassName={styles.filingForm}
          appkitLabel="Filing Form"
          name="filingFormId"
          options={formsOptions}
          value={values.filingFormId}
          customChangeHandler={handleFormChange}
          disabled={isEditing}
        />
        <Select<string | null>
          wrapperClassName={styles.schemaId}
          appkitLabel="E-File Schema ID"
          name="sltSchemaId"
          options={schemaIdOptions}
          value={values.sltSchemaId}
          customChangeHandler={handleSchemaChange}
          disabled={Boolean(isEditing && values.isEfileAllowed && initialValues.sltSchemaId)}
        />
        <div className={styles.dueDates}>
          <label className={`${styles.dueDateLabel} a-form-label`}>Due Date</label>
          <div className={styles.dueDate}>
            <Select
              wrapperClassName={styles.dueDateSelect}
              name="dueDateDays"
              value={values.dueDateDays}
              options={DUE_DATE_DAY_OPTIONS}
            />
            <span className={styles.dueDateText}>day of</span>
            <Select
              wrapperClassName={styles.dueDateSelect}
              name="dueDateMonths"
              value={values.dueDateMonths}
              options={DUE_DATE_MONTH_OPTIONS}
            />
            <span className={styles.dueDateText}>month</span>
          </div>
          <label className={`${styles.dudeDateExtendedLabel} a-form-label`}>
            Extended Due Date
          </label>
          <div className={styles.dueDateExtended}>
            <Select
              wrapperClassName={styles.dueDateSelect}
              name="dueDateExtendedMonths"
              value={values.dueDateExtendedMonths}
              options={DUE_DATE_EXT_AND_SECOND_EXT_MONTH_OPTIONS}
            />
            <span className={styles.dueDateText}>{'month(s) after Due Date'}</span>
          </div>
          <label className={`${styles.dudeDateSecondExtendedLabel} a-form-label`}>
            2nd Extended Due Date
          </label>
          <div className={styles.dueDateSecondExtended}>
            <Select
              wrapperClassName={styles.dueDateSelect}
              name="dueDateSecondExtendedMonths"
              value={values.dueDateSecondExtendedMonths}
              options={DUE_DATE_EXT_AND_SECOND_EXT_MONTH_OPTIONS}
            />
            <span className={styles.dueDateText}>{'month(s) after Extended Due Date'}</span>
          </div>
        </div>
        <Field
          className={styles.name}
          label="Name"
          name="name"
          component={Input}
          autoComplete="off"
        />
        <div className={styles.attributes}>
          <span className={styles.generalAttributesTitle}>General Attributes</span>
          <span className={`a-form-label ${styles.returnAttributesLabel}`}>Return Attributes</span>
          <div className={styles.returnAttributes}>
            <Field label="Default Return" name="isDefault" component={Checkbox} />
            <Field label="Stacked Return" name="isStackedReturn" component={Checkbox} />
          </div>
          <span className={`a-form-label ${styles.filingMethodsLabel}`}>Filing Methods</span>
          <div className={styles.filingMethods}>
            <Field
              label="E-File Allowed"
              name="isEfileAllowed"
              component={Checkbox}
              disabled={isEfileAllowedFieldDisabled}
            />
            <Field
              className={styles.efileMandated}
              label="E-File Mandated"
              name="hasMandate"
              component={Checkbox}
              customChangeHandler={handleMandatedChange}
              disabled={!values.isEfileAllowed}
            />
            <Field
              label="Paper Allowed"
              name="isPaperAllowed"
              component={Checkbox}
              disabled={isPaperAllowedFieldDisabled}
            />
          </div>
          <span className={styles.efileAttributesTitle}>E-File Attributes</span>
          <div className={styles.linkedReturn}>
            <Field
              name="federalReturnAttachment"
              label="Federal Return Attachment"
              component={Radio}
              options={FEDERAL_RETURN_ATTACHMENT_OPTIONS}
              customChangeHandler={handleLinkedReturnChange}
              disabled={!values.isEfileAllowed}
            />
          </div>
          <div className={styles.unlinkedReturnParams}>
            <Field
              label="XML Required"
              name="isFederalXmlRequired"
              component={Checkbox}
              disabled={
                values.federalReturnAttachment !== FederalReturnAttachments.UNLINKED ||
                !values.isEfileAllowed
              }
            />
            <Field
              label="As Filed Requested"
              name="isFederalAsFiledRequested"
              component={Checkbox}
              disabled={
                values.federalReturnAttachment !== FederalReturnAttachments.UNLINKED ||
                !values.isEfileAllowed
              }
            />
          </div>
          <span className={`a-form-label ${styles.efileDevelopmentLabel}`}>E-File Development</span>
          <div className={styles.efileDevelopment}>
            <Field
              label="E-File Ready"
              name="isEfileReady"
              component={Checkbox}
              disabled={!values.isEfileAllowed}
            />
          </div>
          <span className={styles.filingAttributesTitle}>Filing Attributes</span>
          <FilingAttributes
            filingAttributes={filingAttributes}
            filingAttributeGroupClassNames={styles}
            disabledFilingAttributes={disabledFilingAttributes}
          />
        </div>
        <div className={styles.textAreas}>
          <Field label="Filing Information" name="filingInformation" component={TextArea} />
          <Field
            label="Filing Warning (If E-File is Mandated)"
            name="filingWarning"
            component={TextArea}
            disabled={!values.hasMandate}
          />
        </div>
      </div>
      {renderBanner()}
      <div className={styles.buttons}>
        <Button size="lg" kind="secondary" onClick={hidePanel}>
          Cancel
        </Button>
        <Button
          size="lg"
          disabled={isSaveButtonDisabled}
          isLoading={isSubmitting}
          onClick={submitForm}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AddEditReturnDefinitionForm;
