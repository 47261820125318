import React, { useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { Formik } from 'formik';
import { taxReturnsSchemas } from '@common-packages/validators';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import columnBlueprintStyles from '../../shared/styles/columnBlueprintStyles.module.scss';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';
import {
  useQueryReturnStatuses,
  useQueryTaxReturns,
  useQueryTaxReturnsReturnDefinitions,
  useQueryTaxReturnsReturnDefinitionsJurisdictionOptions,
} from '../../shared/queries/taxReturns';
import { useQueryFindEntityFiscalYearEnd } from '../../shared/queries/consolidations';
import useBooleanState from '../../shared/hooks/useBooleanState.hook';
import useModal from '../../shared/hooks/useModal.hook';
import {
  useMutationDeleteTaxReturn,
  useUpdateReturnStatus,
  useMutationUpdateReturn,
  useMutationUpdateGlobalInformation,
} from '../../shared/mutations/taxReturns';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import QuickFilterBadgeGroup from '../../shared/displayComponents/quickFilterBadgeGroup/quickFilterBadgeGroup.component';
import {
  GlobalContext,
  TaxReturn,
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
} from '../../../../common/types/apiShapes';
import Header from '../../shared/displayComponents/header/header.component';
import { AgGridIds, GRID_FILTERS } from '../constants';
import styles from '../styles.module.scss';
import AddEditReturnPanel from '../../shared/taxReturns/addEditReturnPanel/addEditReturnPanel.container';
import { useRowEditMode } from '../../shared/editMode';
import { useShouldDisplayReturnsDueDates } from '../../shared/hooks/useShouldDisplayReturnsDueDates.hook';
import { useShouldDisplayReturnStatus } from '../../shared/hooks/useShouldDisplayReturnStatus.hook';
import { FilingMethods } from '../../shared/enums';
import { FilingMethodsMap } from '../../shared/consolidations/constants';
import {
  TAX_YEAR_ENDING_INFO_ACCOUNT_ID,
  EVERYWHERE_JURISDICTION_ID,
} from '../../shared/constants';

import getColumnDefinitions from './setupTaxReturns.columnDefinitions';
import { CheckTaxReturnTaxYearEndingModal } from './checkTaxReturnTaxYearEndingModal';

const getUniqueRowId = ({
  data: { rowId },
}: {
  data: TaxReturnResultRowWithCalcOptionsAndFilingAttributes;
}) => rowId;

const SetupTaxReturns = () => {
  const dispatch = useDispatch();
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);
  const { taxYear, period, entityId, filingTypeId } = globalContext;
  const { showModal: showWarningModal, modalProps: warningModalProps } = useModal();

  const shouldDisplayReturnStatus = useShouldDisplayReturnStatus();
  const shouldDisplayDueDate = useShouldDisplayReturnsDueDates();

  const [taxReturnToEdit, setTaxReturnToEdit] = useState<TaxReturn | null>(null);
  const [isPanelVisible, showPanel, hidePanel] = useBooleanState(false);

  const { data: taxReturns, isLoading: isLoadingTaxReturns } = useQueryTaxReturns({
    params: { taxYear: taxYear || '', period: period || '', entityId: entityId || '' },
    enabled: Boolean(taxYear && period && entityId),
  });

  const { mutateAsync: deleteTaxReturn } = useMutationDeleteTaxReturn(taxReturns?.data || []);
  const { mutateAsync: updateReturn } = useMutationUpdateReturn();
  const { mutateAsync: updateGlobalInformation } = useMutationUpdateGlobalInformation();

  const {
    data: taxReturnsDefinitions,
    isLoading: isLoadingTaxReturnsDefinitions,
  } = useQueryTaxReturnsReturnDefinitions({
    params: {
      taxYear: taxYear || '',
      period: period || '',
      filingTypeId: filingTypeId || '',
      entityId: entityId || '',
    },
    enabled: Boolean(taxYear && period && filingTypeId && entityId),
  });

  const {
    data: taxReturnsDefinitionsJurisdictionOptions,
    isLoading: isLoadingTaxReturnsDefinitionsJurisdictionOptions,
  } = useQueryTaxReturnsReturnDefinitionsJurisdictionOptions({
    params: { taxYear: taxYear || '', filingTypeId: filingTypeId || '' },
    enabled: Boolean(taxYear && filingTypeId),
  });

  const {
    data: taxReturnStatusOptions,
    isLoading: isLoadingTaxReturnStatus,
  } = useQueryReturnStatuses();

  const {
    data: entityFiscalYearEnd,
    isLoading: isLoadingEntityFiscalYearEnd,
  } = useQueryFindEntityFiscalYearEnd({
    params: {
      taxYear: taxYear || '',
      period: period || '',
      businessEntityId: entityId || '',
    },
    enabled: Boolean(taxYear && period && entityId),
  });

  const { mutateAsync: updateReturnStatus } = useUpdateReturnStatus();

  const handleEditIconClick = useCallback(
    (taxReturn: TaxReturn) => {
      setTaxReturnToEdit(taxReturn);
      showPanel();
    },
    [showPanel],
  );

  const handleDeleteIconClick = useCallback(
    ({
      jurisdiction,
      taxReturn,
      hasMoreThanOneTaxReturnsInCurrentJurisdiction,
    }: TaxReturnResultRowWithCalcOptionsAndFilingAttributes) => {
      if (!taxReturn?.returnId) {
        return;
      }

      if (
        !hasMoreThanOneTaxReturnsInCurrentJurisdiction &&
        taxReturn.filingMethod !== FilingMethods.NON_FILING &&
        taxReturn.filingGroupsIds.length
      ) {
        dispatch(
          showConfirmModal({
            title: 'Member Return',
            text: `You cannot delete this return. ${
              taxReturn?.entityId
            } is a member of ${taxReturn.filingGroupsIds.join(
              ', ',
            )}. Would you like the system to convert this to a non-filing return?`,
            confirmCallback: async () => {
              await updateReturn({
                returnId: taxReturn?.returnId,
                period: taxReturn?.period,
                filingMethod: FilingMethodsMap[FilingMethods.NON_FILING],
              });
            },
          }),
        );
        return;
      }

      dispatch(
        showConfirmModal({
          title: 'Delete Return',
          text: `Are you sure you want to delete this return? ${jurisdiction} ${taxReturn?.formName}`,
          confirmCallback: async () => {
            try {
              await deleteTaxReturn({ returnId: taxReturn?.returnId || '' });
            } catch (e) {
              return;
            }
          },
        }),
      );
    },
    [dispatch, deleteTaxReturn, updateReturn],
  );

  const handleAddIconClick = useCallback(
    (taxReturn: TaxReturn) => {
      if (!entityFiscalYearEnd?.fiscalYearEnd && shouldDisplayDueDate) {
        showWarningModal(taxReturn);
      } else {
        setTaxReturnToEdit(taxReturn);
        showPanel();
      }
    },
    [showWarningModal, showPanel, entityFiscalYearEnd?.fiscalYearEnd, shouldDisplayDueDate],
  );

  const handleAddTaxReturn = useCallback(() => {
    setTaxReturnToEdit(null);
    if (!entityFiscalYearEnd?.fiscalYearEnd && shouldDisplayDueDate) {
      showWarningModal();
    } else {
      showPanel();
    }
  }, [showWarningModal, showPanel, entityFiscalYearEnd?.fiscalYearEnd, shouldDisplayDueDate]);

  const submitActionWithHide = useCallback(
    async ({ taxYearEnding }: { taxYearEnding: string }) => {
      const rowsToUpdate = [
        {
          taxYear: Number(taxYear),
          period: Number(period),
          infoAccountId: TAX_YEAR_ENDING_INFO_ACCOUNT_ID,
          entityId,
          accountJurisdictionId: EVERYWHERE_JURISDICTION_ID,
          partnershipId: null,
          dataType: null,
          value: taxYearEnding,
        },
      ];
      await updateGlobalInformation({
        rowsToUpdate,
        taxYear,
        jurisdictionId: null,
        period,
        entityId,
      });

      setTaxReturnToEdit(warningModalProps.modalData);
      warningModalProps.hideModal();
      showPanel();
    },
    [taxYear, period, entityId, showPanel, updateGlobalInformation, warningModalProps],
  );

  const returnDefinitionJurisdictions = useMemo(
    () => taxReturnsDefinitionsJurisdictionOptions?.map(({ value }) => value || '') || [],
    [taxReturnsDefinitionsJurisdictionOptions],
  );

  const taxReturnsData = useMemo(
    () =>
      taxReturns?.data?.filter(({ jurisdictionId }) =>
        returnDefinitionJurisdictions.includes(jurisdictionId),
      ) || [],
    [taxReturns?.data, returnDefinitionJurisdictions],
  );

  const { gridApi, onGridReady, updateRow, clonedRowData: clonedTaxReturnsData } = useRowEditMode({
    rowData: taxReturnsData,
    getUniqueRowId,
    saveButtonDisabled: false,
  });

  const handleUpdateRow = useCallback(
    (data?: TaxReturnResultRowWithCalcOptionsAndFilingAttributes) => {
      if (data) {
        gridApi?.clearFocusedCell();
        updateRow({ ...data });
        updateReturnStatus({
          returnId: Number(data.taxReturn?.returnId),
          taxReturnStatus: data.taxReturn?.returnStatus || null,
        });
      }
    },
    [updateRow, updateReturnStatus, gridApi],
  );

  const columnDefinitionsWithBlueprints = useMemo(
    () =>
      getColumnDefinitions(
        taxReturns?.columnsBlueprint || [],
        shouldDisplayDueDate,
        shouldDisplayReturnStatus,
        handleUpdateRow,
        handleAddIconClick,
        handleEditIconClick,
        handleDeleteIconClick,
        taxReturnStatusOptions,
        returnDefinitionJurisdictions,
      ),
    [
      handleAddIconClick,
      handleEditIconClick,
      handleDeleteIconClick,
      handleUpdateRow,
      taxReturns?.columnsBlueprint,
      returnDefinitionJurisdictions,
      taxReturnStatusOptions,
      shouldDisplayDueDate,
      shouldDisplayReturnStatus,
    ],
  );

  const addReturnButton = (
    <Button
      size="lg"
      onClick={handleAddTaxReturn}
      disabled={isLoadingTaxReturns || isLoadingEntityFiscalYearEnd || isFetchingGlobalContext}
    >
      Add Return
    </Button>
  );

  const isGridLoading = isLoadingTaxReturnStatus || isLoadingTaxReturns;

  return (
    <>
      <Header
        title="Setup Tax Returns"
        description="View and configure settings associated with a state tax return."
        rightHeaderContent={addReturnButton}
      />
      <div className={styles.setupTaxReturnsContainer}>
        <QuickFilterBadgeGroup filterModels={GRID_FILTERS} gridApi={gridApi} showResetFilters />
        <div className={`row ${styles.setupTaxReturnsGridWrapper}`}>
          <AgGrid
            className={columnBlueprintStyles.gridContainer}
            rowData={clonedTaxReturnsData}
            columnDefs={columnDefinitionsWithBlueprints}
            isGridLoading={isGridLoading}
            autoMaxWidth
            sideBar={defaultSideBarWithColumnsToolPanel}
            saveGridStateId={AgGridIds.SEPERATE}
            headerHeight={50}
            groupHeaderHeight={30}
            singleClickEdit
            onGridReady={onGridReady}
            stopEditingWhenGridLosesFocus
            getRowId={getUniqueRowId}
            withSearchBar
            withResetIcon
          />
        </div>
      </div>
      <AddEditReturnPanel
        taxReturnToEdit={taxReturnToEdit}
        isPanelVisible={isPanelVisible}
        hidePanel={hidePanel}
        taxReturns={clonedTaxReturnsData}
        taxReturnsColumnsBlueprint={taxReturns?.columnsBlueprint || []}
        taxReturnsDefinitions={taxReturnsDefinitions || []}
        isLoadingTaxReturnsDefinitions={isLoadingTaxReturnsDefinitions}
        taxReturnsDefinitionsJurisdictionOptions={taxReturnsDefinitionsJurisdictionOptions || []}
        isLoadingTaxReturnsDefinitionsJurisdictionOptions={
          isLoadingTaxReturnsDefinitionsJurisdictionOptions
        }
      />
      <Formik
        onSubmit={submitActionWithHide}
        initialValues={{ taxYearEnding: entityFiscalYearEnd?.fiscalYearEnd || '' }}
        validationSchema={taxReturnsSchemas.checkTaxReturnTaxYearEndingSchema}
        enableReinitialize
      >
        <CheckTaxReturnTaxYearEndingModal
          entityId={entityId}
          warningModalProps={warningModalProps}
          submitCallback={showPanel}
        />
      </Formik>
    </>
  );
};

export default SetupTaxReturns;
