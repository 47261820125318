import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export const createDataProvider = ({ ProviderComponent }) => {
  const DataProvider = ({ path, exact, children }) => (
    <Route
      path={path}
      exact={exact}
      render={() => <ProviderComponent>{children}</ProviderComponent>}
    />
  );
  DataProvider.propTypes = {
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  };
  DataProvider.displayName = `DataProvider(${ProviderComponent.displayName})`;
  DataProvider.defaultProps = {
    exact: false,
  };

  return DataProvider;
};
