import React from 'react';
import { ColDef } from 'ag-grid-community';

import { DataUsingSelectionList } from '../../../../../../common/types';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';

import styles from './deleteSelectionListModal.module.scss';

interface DeleteSelectionListDataGridProps {
  columnDefinitions: ColDef[];
  data: DataUsingSelectionList[];
  gridHeader: string;
}

const DeleteSelectionListDataGrid = ({
  columnDefinitions,
  data,
  gridHeader,
}: DeleteSelectionListDataGridProps) => {
  return (
    <>
      <p className={styles.agGridHeader}>{`${gridHeader}${data.length > 1 ? '(s)' : ''}:`}</p>
      <div className={`row grid-row`}>
        <div className="col">
          <AgGrid
            columnDefs={columnDefinitions}
            rowData={data}
            autoMaxWidth
            areHeaderCellBordersEnabled
            domLayout="autoHeight"
            className={styles.agGridContainer}
          />
        </div>
      </div>
    </>
  );
};

export default DeleteSelectionListDataGrid;
