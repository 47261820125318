import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  taxYearSelector,
  periodSelector,
  entityIdSelector,
  filingTypeIdSelector,
  consolidationIdSelector,
} from '../../shared/store/selectors';
import useDashboardEffect from '../../shared/hooks/useDashboardEffect';
import { filingTypes } from '../../shared/constants';

import { fetchFilingGroups, fetchFilingGroupsByFilingType } from './actions';

export const useFetchFilingGroups = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const entityId = useSelector(entityIdSelector);
  const filingTypeId = useSelector(filingTypeIdSelector);
  const consolidationId = useSelector(consolidationIdSelector);
  const dispatch = useDispatch();

  const action = useCallback(() => {
    const isContextReady = taxYear && period && filingTypeId && (entityId || consolidationId);

    if (isContextReady) {
      dispatch(
        fetchFilingGroups({
          taxYear,
          period,
        }),
      );
      dispatch(
        fetchFilingGroupsByFilingType({
          taxYear,
          period,
          filingTypeId,
          businessEntityId: filingTypeId === filingTypes.SEPARATE ? entityId : consolidationId,
        }),
      );
    }
  }, [dispatch, taxYear, period, filingTypeId, entityId, consolidationId]);

  useDashboardEffect(action);
};
