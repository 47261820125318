const openBrackets = ['(', '['];
const closeBrackets = [')', ']'];
const expressionOperators = ['+', '-', '*', '/', '<', '>', '=', ','];

// Function used to append '+' operator(s) to text that will be inserted into an expression
// 1. Find first valid (non-whitespace) character to the left and right of target position
// 2. Add operator to left and/or right of textToAdd based on operator presence
// 3. Insert additional whitespace as needed based on whitespace and special character presence
//    immediately adjacent to target position
// This approach preserves the integrity of the existing expression while predictably
// (from a user's perspective) appending necessary operators
const insertTextWithOperatorAtPosition = ({
  currentText,
  textToAdd,
  position,
}: {
  currentText: string;
  textToAdd: string;
  position: number;
}) => {
  if (!currentText) {
    return textToAdd;
  }
  if (!textToAdd) {
    return currentText;
  }
  const firstValidLeftChar =
    currentText
      .substring(0, position)
      .split('')
      .reverse()
      .find(ch => ch !== ' ') || '';
  const firstValidRightChar =
    currentText
      .substring(position, currentText.length)
      .split('')
      .find(ch => ch !== ' ') || '';
  const immediateLeftChar = currentText.charAt(position - 1);
  const immediateRightChar = currentText.charAt(position);

  const insertWhitespace = (ch: string) => {
    if (
      (ch === immediateRightChar && (ch === ',' || closeBrackets.includes(ch))) ||
      (ch === immediateLeftChar && openBrackets.includes(ch))
    ) {
      return '';
    }
    return ch === ' ' ? '' : ' ';
  };

  const textToAddWithOperator = () => {
    switch (position) {
      case 0:
        if ([...expressionOperators, ...closeBrackets].includes(firstValidRightChar)) {
          return `${textToAdd}${insertWhitespace(immediateRightChar)}`;
        }
        return `${textToAdd} +${insertWhitespace(immediateRightChar)}`;
      case currentText.length:
        if ([...expressionOperators, ...openBrackets].includes(firstValidLeftChar)) {
          return `${insertWhitespace(immediateLeftChar)}${textToAdd}`;
        }
        return `${insertWhitespace(immediateLeftChar)}+ ${textToAdd}`;
      default:
        if (
          [...expressionOperators, ...openBrackets].includes(firstValidLeftChar) &&
          [...expressionOperators, ...closeBrackets].includes(firstValidRightChar)
        ) {
          return `${insertWhitespace(immediateLeftChar)}${textToAdd}${insertWhitespace(
            immediateRightChar,
          )}`;
        }
        if ([...expressionOperators, ...closeBrackets].includes(firstValidRightChar)) {
          return `${insertWhitespace(immediateLeftChar)}+ ${textToAdd}${insertWhitespace(
            immediateRightChar,
          )}`;
        }
        if ([...expressionOperators, ...openBrackets].includes(firstValidLeftChar)) {
          return `${insertWhitespace(immediateLeftChar)}${textToAdd} +${insertWhitespace(
            immediateRightChar,
          )}`;
        }
        return `${insertWhitespace(immediateLeftChar)}+ ${textToAdd} +${insertWhitespace(
          immediateRightChar,
        )}`;
    }
  };

  return [
    currentText.slice(0, position),
    textToAddWithOperator(),
    currentText.slice(position),
  ].join('');
};

export default insertTextWithOperatorAtPosition;
