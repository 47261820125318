import {
  currencyColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../shared/columnDefinitions';
import {
  NumberCellEditor,
  TextCellRendererFactory,
} from '../shared/columnDefinitions/cellRenderers';
import { percentageCellFormatter } from '../shared/formatters';

const getExtendedDefaultColumnDefinition = getGroupingCellClassRules => ({
  ...defaultColumnDefinitionWithFilter,
  sortable: false,
  cellClassRules: {
    ...defaultColumnDefinitionWithFilter.cellClassRules,
    ...getGroupingCellClassRules(),
  },
});

const TextRenderer = TextCellRendererFactory({
  getText: ({ value }) => (value || value === 0 ? value : ''),
});

const getColumnDefinitions = ({
  isInEditMode,
  updateRow,
  getGroupingCellClassRules,
  isTotalRow,
}) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const columnDefinitions = [
    {
      field: 'subCategory',
      rowGroup: true,
      hide: true,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'K-1 Item Description',
      field: 'k1ItemDescription',
      width: 500,
      aggFunc: 'subtotalFooter',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Partner ID',
      field: 'partnerId',
      width: 100,
      aggFunc: 'sum',
    },
    {
      ...currencyColumnDefinition,
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Partnership Amount',
      field: 'partnershipAmount',
      cellStyle: {
        textAlign: 'right',
      },
      width: 100,
      aggFunc: 'sum',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Ownership Percentage',
      field: 'ownershipPercentage',
      valueFormatter: data =>
        data.value === 100 || !data.value ? '' : percentageCellFormatter(data),
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Apportionment Percentage',
      field: 'apportionmentPercentage',
      valueFormatter: data =>
        data.value === 100 || !data.value ? '' : percentageCellFormatter(data),
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      ...currencyColumnDefinition,
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Calculated Amount',
      field: 'calculatedAmount',
      cellStyle: {
        textAlign: 'right',
      },
      width: 100,
      aggFunc: 'sum',
    },
    {
      ...currencyColumnDefinition,
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Adjustment',
      field: 'adjustment',
      cellRenderer: TextRenderer,
      editable: props => isInEditMode && !isTotalRow(props),
      cellEditorFramework: NumberCellEditor,
      onCellValueChanged,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
      width: 100,
      cellStyle: {
        textAlign: 'right',
      },
      aggFunc: 'sum',
    },
    {
      ...currencyColumnDefinition,
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Final Amount',
      field: 'finalAmount',
      cellStyle: {
        textAlign: 'right',
      },
      width: 100,
      aggFunc: 'sum',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'K1 Calc ID',
      field: 'accountId',
      width: 200,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
