export const WIZARD_MACHINE_ID = 'eFileWizardMachine';

export const WIZARD_MACHINE_STATES = {
  INITIALIZING: 'initializing',
  STEP_1_CHOOSE_FEDERAL_RETURN: 'step1FederalReturn',
  STEP_2_VALIDATE: 'step2Validations',
  STEP_3_REVIEW: 'step3Review',
  STEP_4_AUTHORIZE_AND_TRANSMIT: 'step4Authorize',
  STEP_5_VIEW_STATUS: 'step5Transmit',
};

export const WIZARD_MACHINE_EVENTS = {
  START_WIZARD: 'START_WIZARD',
  SET_STEP_1: 'SET_STEP_1',
  SET_STEP_2: 'SET_STEP_2',
  SET_STEP_3: 'SET_STEP_3',
  SET_STEP_4: 'SET_STEP_4',
  SET_STEP_5: 'SET_STEP_5',
  NEXT: 'NEXT',
  BACK: 'BACK',
  SET_STEP_STATUS: 'SET_STEP_STATUS',
  SET_INIT_EFILE_STEPS: 'SET_INIT_EFILE_STEPS',
  SET_VALIDATION: 'SET_VALIDATION',
  SET_FEDERAL_RETURN_OPTION: 'SET_FEDERAL_RETURN_OPTION',
  SET_FEDERAL_SUBMISSION_ID: 'SET_FEDERAL_SUBMISSION_ID',
  SET_STATE_SUBMISSION_ID: 'SET_STATE_SUBMISSION_ID',
  SET_EFILE_SERVICE_SUBMISSION_ID: 'SET_EFILE_SERVICE_SUBMISSION_ID',
  SET_TRANSMISSION_STATUS: 'SET_TRANSMISSION_STATUS',
  SET_IS_TTI_SERVICE_RUNNING_RESULT: 'SET_IS_TTI_SERVICE_RUNNING_RESULT',
};

export const WIZARD_STEPS = [
  {
    label: 'STEP 1: CHOOSE FEDERAL RETURN',
    machineState: WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN,
  },
  {
    label: 'STEP 2: VALIDATE',
    machineState: WIZARD_MACHINE_STATES.STEP_2_VALIDATE,
  },
  {
    label: 'STEP 3: REVIEW',
    machineState: WIZARD_MACHINE_STATES.STEP_3_REVIEW,
  },
  {
    label: 'STEP 4: AUTHORIZE & TRANSMIT',
    machineState: WIZARD_MACHINE_STATES.STEP_4_AUTHORIZE_AND_TRANSMIT,
  },
  {
    label: 'STEP 5: VIEW STATUS',
    machineState: WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS,
  },
];

export const POLLING_STEPS = ['step2Validations', 'step4Authorize', 'step5Transmit'];

export const DENIED_STATUS = 'DENIED BY IRS';
