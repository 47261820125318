import { defaultColumnDefinition } from '../../../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'ID',
    field: 'id',
    width: 300,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Value',
    field: 'value',
    width: 300,
  },
];

export default columnDefinitions;
