import { ValueGetterParams } from 'ag-grid-community';

import { dueDateFormatter } from '../../formatters/dueDateFormatter';

export const dueDateValueGetter = ({ data }: ValueGetterParams) => {
  const text = (
    (data?.dueDateOption || []).find(
      (option: { value: number }) => String(option.value) === String(data?.dueDateType),
    ) || {
      label: '',
    }
  ).label;
  return data.taxYearEnding ? dueDateFormatter(text) : 'Missing Tax Year Ending';
};
