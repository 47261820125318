import Excel from 'exceljs';
import zipObject from 'lodash.zipobject';

const convertWorksheetToArray = worksheet => {
  const HEADER_ROW_INDEX = 1;
  const rows = [];

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber === HEADER_ROW_INDEX) {
      return;
    }
    const rowValues = row.values;
    rowValues.shift();

    rows.push(rowValues);
  });

  return rows;
};

export const loadDataFromExcelFileBuffer = async ({ fileBuffer, excelColumnNames }) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(fileBuffer);
  const worksheet = workbook.worksheets[0];
  const rows = convertWorksheetToArray(worksheet)
    .map(row => zipObject(excelColumnNames, row))
    .filter(row => {
      const isRowEmpty = Object.values(row).every(cell => !cell && cell !== 0);
      return !isRowEmpty;
    });
  return rows;
};
