import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchGenericFunctionsTypes,
  fetchDataTypesTypes,
  fetchConstantsTypes,
  fetchCategoriesTypes,
  fetchAccountsTypes,
  fetchJurisdictionsTypes,
  fetchGenericFunctionParamsTypes,
  createGenericFunctionWithParamsTypes,
  updateGenericFunctionWithParamsTypes,
} from './types';

export const initialState = {
  genericFunctions: [],
  isFetchingGenericFunctions: false,

  dataTypes: [],
  isFetchingDataTypes: false,
  constants: [],
  isFetchingConstants: false,
  categories: [],
  isFetchingCategories: false,
  accounts: [],
  isFetchingAccounts: false,
  jurisdictions: [],
  isFetchingJurisdictions: false,

  genericFunctionParams: [],
  isFetchingGenericFunctionParams: false,
  isCreatingGenericFunctionWithParams: false,
  isUpdatingGenericFunctionWithParams: false,
};

const genericFunctionsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchGenericFunctionsTypes,
      payloadKey: 'genericFunctions',
      fetchingKey: 'isFetchingGenericFunctions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataTypesTypes,
      payloadKey: 'dataTypes',
      fetchingKey: 'isFetchingDataTypes',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchConstantsTypes,
      payloadKey: 'constants',
      fetchingKey: 'isFetchingConstants',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchCategoriesTypes,
      payloadKey: 'categories',
      fetchingKey: 'isFetchingCategories',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountsTypes,
      payloadKey: 'accounts',
      fetchingKey: 'isFetchingAccounts',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchJurisdictionsTypes,
      payloadKey: 'jurisdictions',
      fetchingKey: 'isFetchingJurisdictions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchGenericFunctionParamsTypes,
      payloadKey: 'genericFunctionParams',
      fetchingKey: 'isFetchingGenericFunctionParams',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createGenericFunctionWithParamsTypes,
      fetchingKey: 'isCreatingGenericFunctionWithParams',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateGenericFunctionWithParamsTypes,
      fetchingKey: 'isUpdatingGenericFunctionWithParams',
    }),
  },
});

export default genericFunctionsReducer;
