const Yup = require('yup');

const fullGlobalContext = Yup.object().shape({
  taxYear: Yup.string().required(),
  period: Yup.string().required(),
  orgId: Yup.string().required(),
  jurisdictionId: Yup.string().required(),
});

const consolidationGlobalContext = Yup.object().shape({
  taxYear: Yup.string().required(),
  period: Yup.string().required(),
  consolidationId: Yup.string().required(),
});

const entityGlobalContext = Yup.object().shape({
  taxYear: Yup.string().required(),
  period: Yup.string().required(),
  entityId: Yup.string().required(),
  jurisdictionId: Yup.string().required(),
});

module.exports = {
  fullGlobalContext,
  consolidationGlobalContext,
  entityGlobalContext,
};
