import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchValidatedMultDataTypes,
  uploadMultDataTypes,
  RESET_VALIDATED_MULT_DATA,
} from './types';

export const fetchValidatedMultData = apiAction({
  types: fetchValidatedMultDataTypes,
  apiAction: api.fetchValidatedMultData,
});

export const uploadMultData = apiAction(
  {
    types: uploadMultDataTypes,
    apiAction: api.uploadMultData,
    throwApiErrors: true,
  },
  { successNotificationMessage: () => 'Updating mult data succeeded' },
);

export const resetValidatedMultData = () => ({
  type: RESET_VALIDATED_MULT_DATA,
});
