import React from 'react';
import PropTypes from 'prop-types';

import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../shared/columnDefinitions';
import {
  EditableCheckboxCellRenderer,
  TextCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import { dataTypes, TAX_YEAR_ENDING_INFO_ACCOUNT_ID } from '../../shared/constants';
import { defaultDateCellEditor } from '../../shared/columnDefinitions/cellEditor';

import styles from './entityInformation.module.scss';

const TextRenderer = TextCellRendererFactory({
  getText: ({ value }) => (value ? value : ''),
});

const RendererFramework = props => {
  if (props.data.dataType === dataTypes.CHECKBOX) {
    return <EditableCheckboxCellRenderer {...props} />;
  }
  if (props.data.infoAccountId === TAX_YEAR_ENDING_INFO_ACCOUNT_ID && !props.data.value) {
    return <div className={styles.redTextWrapper}>Missing Tax Year Ending</div>;
  }
  return <TextRenderer {...props} />;
};

RendererFramework.propTypes = {
  data: PropTypes.shape({
    dataType: PropTypes.string,
    infoAccountId: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

const EditorSelector = props => {
  switch (props.data.dataType) {
    case dataTypes.DATE:
      return {
        ...defaultDateCellEditor,
        params: {
          value: props.data.value,
        },
      };
    case dataTypes.NUMBER:
      return {
        component: 'NumberCellEditor',
        params: {
          value: props.data.value,
        },
      };
    default:
      return;
  }
};

const getColumnDefinitions = ({ isInEditMode, updateRow }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const checkboxCellClassRules = {
    ...defaultColumnDefinition.cellClassRules,
    'editable-cell': () => isInEditMode,
  };

  const columnDefinitions = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Information Type',
      field: 'infoType',
      width: 150,
      filter: true,
      suppressMenu: false,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Account',
      field: 'infoAccountId',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: 'description',
      width: 500,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Value',
      field: 'value',
      width: 250,
      editable: ({ data }) => isInEditMode && data.dataType !== dataTypes.CHECKBOX,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellClassRules: checkboxCellClassRules,
      cellRenderer: RendererFramework,
      cellEditorSelector: EditorSelector,
      onCellValueChanged,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
