import React from 'react';
import PropTypes from 'prop-types';

import globalContextPropTypes from '../shared/propTypes/globalContext';

import ConsolidationFilingAttributes from './consolidation/filingAttributes.container';
import EntityFilingAttributes from './entity/filingAttributes.container';

const FilingAttributes = ({ globalContext, hasUserPermissionsToEdit }) =>
  globalContext.isSeparate ? (
    <EntityFilingAttributes hasUserPermissionsToEdit={hasUserPermissionsToEdit} />
  ) : (
    <ConsolidationFilingAttributes hasUserPermissionsToEdit={hasUserPermissionsToEdit} />
  );

FilingAttributes.propTypes = {
  globalContext: globalContextPropTypes,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default FilingAttributes;
