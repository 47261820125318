import { numeralFormatter } from '../shared/formatters';

export const pdfValueFormatter = value =>
  !value || value.trim() === '' || Number.isNaN(Number(value))
    ? value
    : numeralFormatter({ value, format: '0,0.[000000]' });

export const getFields = ({ formWrapperClass }) => [
  ...document.querySelectorAll(`.${formWrapperClass} input, .${formWrapperClass} textarea`),
];
