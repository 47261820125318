import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';
import { entitiesSelector } from '../../../shared/store/selectors';

export const partnershipAllocationPercentagesSelector = createSelector(
  [({ entity }) => entity.partnershipAllocationPercentages.partnershipAllocationPercentages],
  partnershipAllocationPercentages =>
    partnershipAllocationPercentages.map((partnership, rowId) => ({
      ...partnership,
      rowId,
    })),
);

export const isFetchingPartnershipAllocationPercentagesSelector = ({ entity }) =>
  entity.partnershipAllocationPercentages.isFetchingPartnershipAllocationPercentages;

export const isUpdatingPartnershipAllocationPercentagesSelector = ({ entity }) =>
  entity.partnershipAllocationPercentages.isUpdatingPartnershipAllocationPercentages;

export const isDeletingPartnershipAllocationSelector = ({ entity }) =>
  entity.partnershipAllocationPercentages.isDeletingPartnershipAllocation;

export const entitiesIdsOptionsWithNameSelector = createSelector([entitiesSelector], entities =>
  entities.map(({ entityId, entityName }) => ({
    label: entityId,
    value: entityId,
    name: entityName,
  })),
);

export const isFetchingPercentageTypesSelector = ({ entity }) =>
  entity.partnershipAllocationPercentages.isFetchingPercentageTypes;

const percentageTypesSelector = ({ entity }) =>
  entity.partnershipAllocationPercentages.percentageTypes;

export const percentageTypesOptionsSelector = optionsSelectorFactory({
  listSelector: percentageTypesSelector,
  labelKey: 'description',
  valueKey: 'percentageType',
});

export const isFetchingPartnersSelector = ({ entity }) =>
  entity.partnershipAllocationPercentages.isFetchingPartners;

const partnersSelector = ({ entity }) => entity.partnershipAllocationPercentages.partners;

export const partnersOptionsSelector = optionsSelectorFactory({
  listSelector: partnersSelector,
  labelKey: 'partnerId',
  valueKey: 'partnerId',
});

export const isUpdatingOwnershipSelector = ({ entity }) =>
  entity.partnershipAllocationPercentages.isUpdatingOwnership;
