import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const isFetchingAccountGroupsSelector = ({ entity }) =>
  entity.manuallyEnteredAccountData.isFetchingAccountGroups;

const accountGroupsSelector = ({ entity }) => entity.manuallyEnteredAccountData.accountGroups;
export const accountGroupsOptionsSelector = optionsSelectorFactory({
  listSelector: state => accountGroupsSelector(state),
  labelKey: 'description',
  valueKey: 'accountGroup',
});

export const isFetchingAccountsSelector = ({ entity }) =>
  entity.manuallyEnteredAccountData.isFetchingAccounts;
export const isFetchingAccountDataSelector = ({ entity }) =>
  entity.manuallyEnteredAccountData.isFetchingAccountData;

export const accountsSelector = ({ entity }) => entity.manuallyEnteredAccountData.accounts;

export const selectedAccountGroupSelector = ({ entity }) =>
  entity.manuallyEnteredAccountData.selectedAccountGroup ||
  entity.manuallyEnteredAccountData.previousSelectedAccountGroup;

export const selectedAccountSelector = ({ entity }) =>
  entity.manuallyEnteredAccountData.selectedAccount;

export const accountDataSelector = createSelector(
  [({ entity }) => entity.manuallyEnteredAccountData.accountData],
  accountData =>
    accountData.map((accountDataItem, rowId) => ({
      ...accountDataItem,
      accountAmount: accountDataItem.accountAmount !== null ? accountDataItem.accountAmount : 0,
      rowId,
    })),
);

export const accountsOptionsSelector = createSelector([accountsSelector], accounts =>
  accounts.map(({ accountId, accountDesc }) => ({
    value: accountId,
    label: `${accountDesc} - ${accountId}`,
  })),
);
