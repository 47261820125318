import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { ColumnTypes } from '@common-packages/shared-constants';

import { columnsBlueprintPropTypes } from '../../../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import getFilingAttributesInitialValues from '../getFilingAttributesInitialValues';

import { taxRatePropType } from './propTypes';
import TaxRateForm from './taxRateForm.component';
import checkAreRangesOverlaps from './checkAreRangesOverlaps';

const TaxRateFormWrapper = ({ onSave, taxRates, jurisdictionId, rowToEdit, columnsBlueprint }) => {
  const isEditMode = Boolean(rowToEdit);

  const filingAttributes = columnsBlueprint
    .filter(({ columnType }) => columnType !== ColumnTypes.CALC_PREFERENCES)
    .map(filingAttributeRow => filingAttributeRow?.children?.[0] || { children: [] });

  const calcPreferences = columnsBlueprint.find(
    ({ columnType }) => columnType === ColumnTypes.CALC_PREFERENCES,
  );

  const formInitialValues = useMemo(
    () => ({
      startRange: '0',
      stopRange: null,
      rate: null,
      jurisdictionId,
      exclusionRate: '0',
      exclusionAmt: '0',
      taxType: null,
      ...getFilingAttributesInitialValues(filingAttributes),
    }),
    [jurisdictionId, filingAttributes],
  );

  const formattedRowToEdit = useMemo(
    () =>
      rowToEdit
        ? {
            ...rowToEdit,
            startRange: String(rowToEdit.startRange),
            stopRange: rowToEdit.stopRange != null ? String(rowToEdit.stopRange) : '',
            rate: String(rowToEdit.rate),
            taxType: String(rowToEdit.taxType),
            exclusionRate: String(rowToEdit.exclusionRate),
            exclusionAmt: String(rowToEdit.exclusionAmt),
          }
        : null,
    [rowToEdit],
  );

  const rowToEditValues = useMemo(
    () => ({
      ...formInitialValues,
      ...formattedRowToEdit,
    }),
    [formInitialValues, formattedRowToEdit],
  );

  const validate = existingTaxRates => ({
    startRange,
    stopRange,
    rate,
    exclusionRate,
    rowId,
    TAX_TYPE_INCOME,
    TAX_TYPE_FRANCHISE,
  }) => {
    const valuesForOverlapCheck = {
      rowId,
      startRange: startRange ? Number(startRange) : null,
      stopRange: stopRange ? Number(stopRange) : null,
      TAX_TYPE_INCOME,
      TAX_TYPE_FRANCHISE,
    };

    const errors = {};

    if (!startRange) {
      errors.startRange = 'Start Range is required';
    }

    if (startRange < 0) {
      errors.startRange = 'Start Range must be greater than or equal to 0';
    }

    if (!rate) {
      errors.rate = 'Rate is required';
    }

    if (rate < 0 || rate > 1) {
      errors.rate = 'Rate must be greater than or equal to 0 and less than or equal to 1';
    }

    if (exclusionRate < 0 || exclusionRate > 1) {
      errors.exclusionRate =
        'Exclusion Rate must be greater than or equal to 0 and less than or equal to 1';
    }

    const areRangesOverlapping = checkAreRangesOverlaps(valuesForOverlapCheck, existingTaxRates);

    if (areRangesOverlapping) {
      errors.crossFields = 'Range overlaps with others for this Tax Type';
    }

    const isStopSmallerThenStart =
      valuesForOverlapCheck.stopRange &&
      valuesForOverlapCheck.stopRange < valuesForOverlapCheck.startRange;

    if (isStopSmallerThenStart) {
      errors.crossFields = 'Stop Range is smaller then Start Range';
    }

    if (TAX_TYPE_INCOME && TAX_TYPE_FRANCHISE) {
      errors.crossFields = 'Both tax types cannot be selected at the same time';
    }

    if (!TAX_TYPE_INCOME && !TAX_TYPE_FRANCHISE) {
      errors.crossFields = 'Tax type is required';
    }

    return errors;
  };

  const onSubmit = useCallback(
    values => {
      onSave({
        ...values,
        // we're accepting '0' value
        oldStartRange:
          rowToEdit?.startRange || rowToEdit?.startRange === 0
            ? Number(rowToEdit.startRange)
            : null,
        oldStopRange:
          rowToEdit?.stopRange || rowToEdit?.stopRange === 0 ? Number(rowToEdit.stopRange) : null,
      });
    },
    [onSave, rowToEdit],
  );

  const renderForm = useCallback(
    () => (
      <TaxRateForm
        isEditMode={isEditMode}
        rowToEditValues={rowToEditValues}
        filingAttributes={filingAttributes}
        calcPreferences={calcPreferences}
      />
    ),
    [isEditMode, rowToEditValues, filingAttributes, calcPreferences],
  );

  return (
    <Formik
      initialValues={rowToEdit ? rowToEditValues : formInitialValues}
      validate={validate(taxRates)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {renderForm}
    </Formik>
  );
};

TaxRateFormWrapper.propTypes = {
  onSave: PropTypes.func.isRequired,
  rowToEdit: taxRatePropType,
  taxRates: PropTypes.arrayOf(taxRatePropType).isRequired,
  jurisdictionId: PropTypes.string.isRequired,
  columnsBlueprint: PropTypes.arrayOf(
    PropTypes.shape({
      children: columnsBlueprintPropTypes.isRequired,
    }),
  ),
};

export default TaxRateFormWrapper;
