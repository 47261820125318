import { defaultColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Account Id',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Description',
    field: 'accountDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Group',
    field: 'accountGroup',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Screen Group',
    field: 'screenGroup',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Function',
    field: 'functionName',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Consol. Function',
    field: 'consFunctionName',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Consol. PB Function',
    field: 'consPbFunctionName',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Active',
    field: 'activeFlag',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Case',
    field: 'caseYn',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Alternate Description',
    field: 'altDescription',
  },
];

export default columnDefinitions;
