import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '@tls/ui-spinner';
import { useLocation } from 'react-router-dom';

import { BinderOption, useQueryBinderData } from '../../shared/queries/binders';
import useModal from '../../shared/hooks/useModal.hook';
import PdfDocumentModal from '../../shared/pdfAttachments/pdfDocumentModal.component';
import SelectContextDataInfo from '../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';
import {
  pdfImageSelector,
  isFetchingPdfImageSelector,
} from '../../shared/store/pdfAttachments/selectors';
import { ROOT_NAVIGATOR_TAB } from '../../shared/constants';
import treeAfterActiveApplied from '../treeAfterActiveApplied';

import { RenderTreeProps, TreeItem } from './types';
import returnTreeDataFactory from './returnDataFactory';

interface ReturnsTreeProps {
  binder: BinderOption;
  applyPermissions: (tree: TreeItem[]) => TreeItem[];
  renderTree: (props: RenderTreeProps) => JSX.Element;
  isOpen: boolean;
}

const ReturnsTreeComponent = ({
  binder,
  applyPermissions,
  renderTree,
  isOpen,
  ...rest
}: ReturnsTreeProps) => {
  const globalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);
  const isFetchingPdfImage = useSelector(isFetchingPdfImageSelector);
  const pdfImage = useSelector(pdfImageSelector);

  const returnData = useQueryBinderData({
    binderId: binder.value,
    enabled: globalContext.isReady && isOpen,
  });

  const tree = useMemo(() => {
    if (!isOpen || returnData.isFetching || !returnData?.data) {
      return [];
    } else {
      return returnTreeDataFactory({
        returnData: returnData.data,
        applyPermissions,
        globalContext,
      });
    }
  }, [returnData, isOpen, applyPermissions, globalContext]);

  const location = useLocation();

  const treeWithActive = useMemo(() => {
    if (tree?.length > 0) {
      return treeAfterActiveApplied({
        tree,
        location,
        activeTab: ROOT_NAVIGATOR_TAB.RETURNS,
      });
    } else {
      return [];
    }
  }, [tree, location]);

  const { showModal: showAttachmentModal, modalProps } = useModal();

  if (isFetchingGlobalContext || returnData.isFetching) {
    return <Spinner />;
  }

  if (!globalContext.isReady) {
    return <SelectContextDataInfo />;
  }

  if (!tree?.length) {
    return <></>;
  }

  return (
    <>
      {renderTree({
        tree: treeWithActive,
        showAttachmentModal,
        ...rest,
      })}
      <PdfDocumentModal
        {...modalProps}
        pdfImage={pdfImage}
        isFetchingPdfImage={isFetchingPdfImage}
      />
    </>
  );
};

export default ReturnsTreeComponent;
