import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Modal from '@tls/ui-modal';
import { rolesSchemas } from '@common-packages/validators';

import useFetch from '../../shared/hooks/useFetch.hook';

import AddRoleForm from './addRoleForm.component';
import * as api from './store/api';
import { rolesPropTypes } from './propTypes';

const formInitialValues = {
  roleName: '',
  basedOnRoleId: '',
};

const AddRoleFormModal = ({ hideModal, visible, roles, fetchRoles, modalData }) => {
  const roleToEdit = modalData;
  const { fetch: createRole, isFetching: isCreatingRole } = useFetch({
    action: api.createRole,
    successNotificationMessage: 'Role has been successfully created',
  });

  const { fetch: updateRole, isFetching: isUpdatingRole } = useFetch({
    action: api.updateRole,
    successNotificationMessage: 'Role has been successfully updated',
  });

  const saveRole = useCallback(
    async ({ basedOnRoleId, roleName }, { resetForm }) => {
      if (!roleToEdit) {
        await createRole({ basedOnRoleId, roleName });
      } else {
        await updateRole({
          roleId: roleToEdit.roleId,
          roleName,
        });
      }
      hideModal();
      fetchRoles();
      resetForm();
    },
    [hideModal, fetchRoles, createRole, updateRole, roleToEdit],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        title={`${roleToEdit ? 'Edit' : 'Add'} Role`}
        closeAction={hideModal}
        visible={visible}
        submitAction={formikProps.submitForm}
        dismissAction={hideModal}
      >
        <AddRoleForm
          {...formikProps}
          onCancelClick={hideModal}
          isCreatingRole={isCreatingRole}
          isUpdatingRole={isUpdatingRole}
          roles={roles}
          roleToEdit={roleToEdit}
        />
      </Modal>
    ),
    [roleToEdit, visible, hideModal, roles, isCreatingRole, isUpdatingRole],
  );
  return (
    <Formik
      initialValues={roleToEdit ? { ...roleToEdit } : formInitialValues}
      validationSchema={rolesSchemas.getAddEditRoleSchema({ roles })}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={saveRole}
    >
      {renderForm}
    </Formik>
  );
};

AddRoleFormModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(rolesPropTypes),
  modalData: rolesPropTypes,
};

export default AddRoleFormModal;
