import React from 'react';
import { useLocation } from 'react-router-dom';

import AppLoading from './shared/displayComponents/appLoadingIndicator';
import { useFetchAccessTokens } from './shared/queries/auth';
import config from './config';

const AuthComponent = () => {
  const query = useLocation().search;

  useFetchAccessTokens({
    params: { query },
    onSuccess: data => {
      // data will include the access and refresh tokens
      localStorage.setItem(config.AUTH.ID_TOKEN_KEY, data.id_token);
      localStorage.setItem(config.AUTH.REFRESH_TOKEN_KEY, data.refresh_token);
      localStorage.setItem(
        config.AUTH.EXPIRES_IN_KEY,
        new Date(new Date().getTime() + data.expires_in * 1000).toString(),
      );
      // Redirect to the base url for the site
      window.location.href = '/';
    },
  });

  return <AppLoading isLoading showNoClientAccessMessage={false} />;
};

export default AuthComponent;
