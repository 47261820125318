import { ColDef } from 'ag-grid-community';

import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';

const calcPreferenceColumnDefinitions: ColDef[] = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Tax Year',
    field: 'taxYear',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'CalcPreference',
    field: 'calcPreferenceName',
    flex: 1,
  },
];

export default calcPreferenceColumnDefinitions;
