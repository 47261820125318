import flattenDeep from 'lodash.flattendeep';
import { min, max, parseISO } from 'date-fns';

import { getActionData } from './getStepsData';

const getDataForActions = (step, steps) => {
  const actions = step ? Object.keys(steps[step.actionsBatch]) : [];

  return actions.map(action => getActionData(step.stepData, action));
};

const isEveryActionCompleted = (step, steps) =>
  getDataForActions(step, steps).every(({ status } = {}) => status === 'COMPLETED');

export const isActionCompleted = data => data?.status.toLowerCase() === 'completed';
export const isSubStepCompleted = (subStep, steps) => isEveryActionCompleted(subStep, steps);

export const hasAnyEarlierSubStepNewerData = (step, earlierSteps, steps) => {
  const stepDates = getDataForActions(step, steps)
    .filter(stepData => stepData)
    .map(({ createdOn, updatedOn }) => updatedOn || createdOn)
    .map(date => parseISO(date));

  const earlierStepsDates = flattenDeep(
    earlierSteps.map(step =>
      getDataForActions(step, steps)
        .filter(stepData => stepData)
        .map(({ createdOn, updatedOn }) => updatedOn || createdOn),
    ),
  ).map(date => parseISO(date));

  return max(earlierStepsDates) > min(stepDates);
};
