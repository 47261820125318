import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import {
  taxYearSelector,
  periodSelector,
  isFetchingEntitiesSelector,
  entityIdSelector,
} from '../../shared/store/selectors';

import AddEditNoteSlideIn from './AddEditNoteSlideIn.container';
import {
  fetchNotesToReview,
  deleteNote,
  fetchJurisdictions,
  fetchStatuses,
  fetchTasks,
  createNote,
  updateNote,
} from './store/actions';
import {
  notesToReviewSelector,
  fetchingNotesToReviewSelector,
  jurisdictionsSelector,
  tasksSelector,
  statusesSelector,
  isFetchingJurisdictionsSelector,
  isFetchingStatusesSelector,
  isFetchingTasksSelector,
} from './store/selectors';
import getColumnDefinitions from './reviewerNotes.columnDefinitions';
import {
  notesToReviewPropTypes,
  jurisdictionsPropTypes,
  statusesPropTypes,
  tasksPropTypes,
} from './propTypes';

const ReviewerNotes = ({
  taxYear,
  period,
  entityId,
  fetchNotesToReview,
  notesToReview,
  isFetchingNotesToReview,
  showConfirmModal,
  deleteNote,
  fetchJurisdictions,
  fetchTasks,
  fetchStatuses,
  jurisdictions,
  tasks,
  statuses,
  createNote,
  isFetchingStatuses,
  isFetchingTasks,
  isFetchingJurisdictions,
  isFetchingEntities,
  updateNote,

  hasUserPermissionsToEdit,
}) => {
  const isContextReady = taxYear && period && entityId;

  const [isSlideInOpen, setIsSlideInOpen] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState(null);

  const openSlideIn = useCallback(() => {
    setNoteToEdit(null);
    setIsSlideInOpen(true);
  }, []);

  const openSlideInForEdit = useCallback(row => {
    setNoteToEdit(row);
    setIsSlideInOpen(true);
  }, []);

  const closeSlideIn = useCallback(() => setIsSlideInOpen(false), []);

  const reloadGrid = useCallback(() => {
    if (isContextReady) {
      fetchNotesToReview({
        taxYear,
        period,
        entityId,
      });
      fetchJurisdictions({
        taxYear,
        entityId,
      });
      fetchTasks();
      fetchStatuses();
    }
  }, [
    isContextReady,
    fetchNotesToReview,
    fetchJurisdictions,
    fetchTasks,
    fetchStatuses,
    taxYear,
    period,
    entityId,
  ]);

  useEffect(() => {
    reloadGrid();
  }, [reloadGrid]);

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete Note',
        text: 'Are you sure you want to delete this note?',
        confirmCallback: async () => {
          await deleteNote(row.noteId);
          reloadGrid();
        },
      });
    },
    [deleteNote, showConfirmModal, reloadGrid],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: openModalForDelete,
        onEditIconClick: openSlideInForEdit,
        hasUserPermissionsToEdit,
      }),
    [hasUserPermissionsToEdit, openModalForDelete, openSlideInForEdit],
  );

  const isFetchingSlideInData =
    isFetchingEntities || isFetchingTasks || isFetchingJurisdictions || isFetchingStatuses;

  const addNote = useCallback(
    async ({
      preparedBy,
      description,
      jurisdictionId,
      taskId,
      rolloverToNextYear,
      note,
      response,
      respondedBy,
      status,
    }) => {
      await createNote({
        taxYear,
        period,
        entityId,
        preparedBy,
        description,
        jurisdictionId,
        taskId,
        rolloverToNextYear,
        note,
        response,
        respondedBy,
        status,
      });
      reloadGrid();
    },
    [createNote, entityId, period, reloadGrid, taxYear],
  );

  const editNote = useCallback(
    async ({
      noteId,
      status,
      respondedBy,
      description,
      note,
      response,
      rolloverToNextYear,
      jurisdictionId,
      taskId,
    }) => {
      await updateNote({
        taxYear,
        entityId,
        noteId,
        status,
        respondedBy,
        description,
        note,
        response,
        rolloverToNextYear,
        jurisdictionId,
        taskId,
      });
      reloadGrid();
    },
    [updateNote, entityId, reloadGrid, taxYear],
  );

  return (
    <>
      <div className="row">
        {hasUserPermissionsToEdit && (
          <div className="col add-button-column">
            <Button size="lg" onClick={openSlideIn} className="add-button">
              Add Note
            </Button>
          </div>
        )}
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={notesToReview}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingNotesToReview}
          />
        </div>
      </div>

      <AddEditNoteSlideIn
        isOpen={isSlideInOpen}
        closeSlideIn={closeSlideIn}
        jurisdictions={jurisdictions}
        tasks={tasks}
        statuses={statuses}
        createNote={addNote}
        isFetchingData={isFetchingSlideInData}
        noteToEdit={noteToEdit}
        editNote={editNote}
      />
    </>
  );
};

ReviewerNotes.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  fetchNotesToReview: PropTypes.func.isRequired,
  isFetchingNotesToReview: PropTypes.bool.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  notesToReview: notesToReviewPropTypes.isRequired,
  fetchJurisdictions: PropTypes.func.isRequired,
  fetchStatuses: PropTypes.func.isRequired,
  fetchTasks: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  jurisdictions: jurisdictionsPropTypes.isRequired,
  statuses: statusesPropTypes.isRequired,
  tasks: tasksPropTypes.isRequired,
  isFetchingEntities: PropTypes.bool.isRequired,
  isFetchingJurisdictions: PropTypes.bool.isRequired,
  isFetchingStatuses: PropTypes.bool.isRequired,
  isFetchingTasks: PropTypes.bool.isRequired,
  updateNote: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    notesToReview: notesToReviewSelector(state),
    isFetchingNotesToReview: fetchingNotesToReviewSelector(state),
    jurisdictions: jurisdictionsSelector(state),
    statuses: statusesSelector(state),
    tasks: tasksSelector(state),
    isFetchingEntities: isFetchingEntitiesSelector(state),
    isFetchingTasks: isFetchingTasksSelector(state),
    isFetchingStatuses: isFetchingStatusesSelector(state),
    isFetchingJurisdictions: isFetchingJurisdictionsSelector(state),
  }),
  {
    fetchNotesToReview,
    showConfirmModal,
    deleteNote,
    fetchJurisdictions,
    fetchStatuses,
    fetchTasks,
    createNote,
    updateNote,
  },
)(ReviewerNotes);
