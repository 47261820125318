import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { SelectOptionPropTypes } from '../../propTypes/selectOption';
import ParamDropdown from '../../displayComponents/paramDropdown/paramDropdown.component';
import { selectJurisdiction } from '../../store/actions';
import {
  filingTypeIdSelector,
  jurisdictionIdSelector,
  isFetchingTaxYearsDataSelector,
  isFetchingPeriodsSelector,
  isFetchingEntitiesSelector,
  isFetchingJurisdictionsSelector,
} from '../../store/selectors';
import { isFetchingFilingStatesSelector } from '../../../filingStates/store/selectors';
import { filingTypes, NotApplicableContextValue } from '../../constants';

const JurisdictionDropdown = ({ jurisdictionsOptions, isFetchingContext }) => {
  const dispatch = useDispatch();
  const { routeParams, setParams } = useUrlParams();

  const filingTypeId = useSelector(filingTypeIdSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const isFetchingTaxYearsData = useSelector(isFetchingTaxYearsDataSelector);
  const isFetchingPeriods = useSelector(isFetchingPeriodsSelector);
  const isFetchingEntities = useSelector(isFetchingEntitiesSelector);
  const isFetchingJurisdictions = useSelector(isFetchingJurisdictionsSelector);
  const isFetchingFilingStates = useSelector(isFetchingFilingStatesSelector);

  const handleSelect = useCallback(
    newJurisdictionId => {
      if (jurisdictionId !== newJurisdictionId) {
        if (newJurisdictionId !== NotApplicableContextValue) {
          dispatch(selectJurisdiction(newJurisdictionId));
        }
        setParams({
          routeParams: { jurisdictionId: newJurisdictionId || NotApplicableContextValue },
        });
      }
    },
    [setParams, dispatch, jurisdictionId],
  );

  const isFetchingSeparateContext =
    isFetchingTaxYearsData ||
    isFetchingPeriods ||
    isFetchingEntities ||
    isFetchingJurisdictions ||
    isFetchingFilingStates;

  const isFetchingConsolidatedContext =
    isFetchingTaxYearsData || isFetchingPeriods || isFetchingJurisdictions;

  const isDropdownBusy =
    filingTypeId === filingTypes.SEPARATE
      ? isFetchingSeparateContext
      : isFetchingConsolidatedContext;

  useEffect(() => {
    if (
      !(
        jurisdictionsOptions.length &&
        jurisdictionsOptions.find(({ value }) => value === routeParams.jurisdictionId)
      )
    ) {
      return;
    }
    if (routeParams.jurisdictionId) {
      handleSelect(routeParams.jurisdictionId);
    }
  }, [handleSelect, jurisdictionsOptions, routeParams.jurisdictionId, isDropdownBusy]);

  return (
    <ParamDropdown
      label="Jurisdiction"
      value={jurisdictionId}
      options={jurisdictionsOptions}
      handleChange={handleSelect}
      isBusy={isDropdownBusy}
      isFetchingContext={isFetchingContext}
    />
  );
};
JurisdictionDropdown.propTypes = {
  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingContext: PropTypes.bool,
};

export default JurisdictionDropdown;
