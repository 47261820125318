import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { dataTypes } from '../../shared/constants';
import Loading from '../../shared/displayComponents/loading.component';

import { fetchInfoAccounts, createInfoAccount, updateInfoAccount } from './store/actions';
import EditInfoAccountForm from './editInfoAccountForm.component';
import { editInfoAccountPropTypes } from './propTypes';

const formInitialValues = {
  infoAccountId: '',
  infoType: '',
  infoAccountDescription: '',
  activeYn: false,
  globalInd: false,
  pshipGlobalInd: false,
  dataType: dataTypes.TEXT,
};

const validate = (existingInfoAccounts, isEditMode) => ({ infoAccountId }) => {
  const errors = {};

  if (!infoAccountId) {
    errors.infoAccountId = 'Info account ID is required';
  }

  const isNameConflict =
    !isEditMode &&
    existingInfoAccounts.some(infoAccount => infoAccount.infoAccountId === infoAccountId);

  if (isNameConflict) {
    errors.infoAccountId = 'Info account ID is duplicated';
  }

  return errors;
};

const EditInfoAccount = ({
  taxYear = null,
  period = null,

  isFetchingInfoAccounts = false,

  clearForm,
  infoAccountToEdit = null,
  infoAccounts = [],
}) => {
  const dispatch = useDispatch();

  const isEditMode = Boolean(infoAccountToEdit);
  const infoAccountToEditValues = {
    ...formInitialValues,
    ...infoAccountToEdit,
  };

  const saveInfoAccount = useCallback(
    async ({
      infoAccountId,
      infoAccountDescription,
      infoType,
      activeYn,
      globalInd,
      pshipGlobalInd,
      dataType,
    }) => {
      const data = {
        taxYear,
        period,
        infoAccountId,
        infoType,
        infoAccountDescription,
        activeYn,
        globalInd,
        pshipGlobalInd,
        dataType: dataType || dataTypes.TEXT,
      };
      if (isEditMode) {
        await dispatch(updateInfoAccount(data));
      } else {
        await dispatch(createInfoAccount(data));
      }

      clearForm();
      dispatch(fetchInfoAccounts({ taxYear, period }));
    },
    [dispatch, clearForm, taxYear, period, isEditMode],
  );

  const renderForm = useCallback(
    formikProps => (
      <Loading isLoading={isFetchingInfoAccounts}>
        <EditInfoAccountForm {...formikProps} isEditMode={isEditMode} taxYear={taxYear} />
      </Loading>
    ),
    [isEditMode, taxYear, isFetchingInfoAccounts],
  );

  return (
    <Formik
      initialValues={infoAccountToEditValues}
      enableReinitialize
      validate={validate(infoAccounts, isEditMode)}
      validateOnBlur={false}
      onSubmit={saveInfoAccount}
    >
      {renderForm}
    </Formik>
  );
};

EditInfoAccount.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  infoAccounts: PropTypes.arrayOf(PropTypes.shape(editInfoAccountPropTypes)),
  infoAccountToEdit: PropTypes.shape(editInfoAccountPropTypes),
  clearForm: PropTypes.func.isRequired,

  isFetchingInfoAccounts: PropTypes.bool.isRequired,
};

export default EditInfoAccount;
