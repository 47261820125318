import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
// eslint-disable-next-line import/extensions
import map from '@highcharts/map-collection/countries/us/us-all.geo.json';

import { Colors } from '../constants';

require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

const coloredMapOption = {
  chart: {
    renderTo: 'new-map',
    borderWidth: 1,
    spacing: [0, 0, 10, 0],
    borderColor: 'transparent',
    height: 620,
    map,
  },
  title: {
    text: '',
  },
  legend: {
    floating: true,
    valueDecimals: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    symbolRadius: '50%',
    symbolHeight: 14,
  },
  credits: {
    enabled: false,
  },
  colorAxis: {
    stops: [
      [0, Colors.GRAD_LIGHT],
      [0.125, Colors.GRAD_MID1],
      [0.25, Colors.GRAD_MID2],
      [0.375, Colors.GRAD_MID3],
      [0.5, Colors.GRAD_MID4],
      [0.625, Colors.GRAD_MID5],
      [0.75, Colors.GRAD_MID6],
      [0.875, Colors.GRAD_MID7],
      [1, Colors.GRAD_DARK],
    ],
  },
  plotOptions: {
    map: {
      borderColor: 'white',
    },
  },
  series: [
    {
      animation: {
        duration: 1000,
      },
      joinBy: ['postal-code', 'code'],
      dataLabels: {
        enabled: false,
        color: 'white',
        format: '{point.code}',
      },
      name: 'Tax Liability',
      tooltip: {
        headerFormat: '',
        pointFormat:
          '<span style="color:transparent">●</span>  <b>{point.name}({point.code})</b> <br><span style="color:{point.color}">●</span>  Tax Liability: {point.value}',
      },
    },
  ],
};

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const DataMap = ({ data, onCountryClicked }) => {
  coloredMapOption.series[0].data = data;
  coloredMapOption.series[0].point = {
    events: {
      click: onCountryClicked,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="mapChart"
      options={{ ...coloredMapOption }}
    />
  );
};

DataMap.propTypes = {
  data: PropTypes.array.isRequired,
  onCountryClicked: PropTypes.func.isRequired,
};

export default DataMap;
