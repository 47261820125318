import {
  defaultColumnDefinition,
  percentageColumnDefinition,
} from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'orgId',
    maxWidth: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Filing Indicator',
    field: 'processInd',
    width: 50,
    cellRenderer: EditableCheckboxCellRenderer,
    cellRendererParams: {
      disabled: true,
    },
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: 'Apportionment %',
    field: 'apportionmentPct',
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: 'Tax Rate',
    field: 'taxRate',
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: 'Effective Tax Rate',
    field: 'effectiveTaxRate',
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: 'Franchise Tax',
    field: 'ftaxTaxAfterCred',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Comments',
    field: 'notes',
    maxWidth: 250,
  },
];

export default columnDefinitions;
