import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../shared/store/reducerFactory';

import { fetchFederalProformaTypes, checkLastRefreshProFormaReturnTypes } from './types';

export const initialState = {
  pdfImage: null,
  isFetchingPdfImage: false,
  lastRefreshProFormaReturn: {},
};

const federalProformaReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFederalProformaTypes,
      payloadKey: 'pdfImage',
      fetchingKey: 'isFetchingPdfImage',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: checkLastRefreshProFormaReturnTypes,
      payloadKey: 'lastRefreshProFormaReturn',
    }),
  },
});

export default federalProformaReducer;
