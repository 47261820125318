import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchValidatedInformationalDataTypes,
  uploadInformationalDataTypes,
  RESET_VALIDATED_INFORMATIONAL_DATA,
} from './types';

const informationalDataUploadReducer = reducerFactory({
  initialState: {
    validatedInformationalData: [],
    isFetchingValidatedInformationalData: false,
    isUploadingInformationalData: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchValidatedInformationalDataTypes,
      payloadKey: 'validatedInformationalData',
      fetchingKey: 'isFetchingValidatedInformationalData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: uploadInformationalDataTypes,
      fetchingKey: 'isUploadingInformationalData',
      getAdditionalState: () => ({
        validatedInformationalData: [],
      }),
    }),
    [RESET_VALIDATED_INFORMATIONAL_DATA]: state => ({
      ...state,
      validatedInformationalData: [],
    }),
  },
});

export default informationalDataUploadReducer;
