const getTtiPeriodNames = taxYear => [
  { sltId: '0', sltDescription: 'Annual Return', ttiDescription: `${taxYear}` },
  { sltId: '1', sltDescription: 'Estimated Return - 1st Quarter', ttiDescription: `${taxYear}ES1` },
  { sltId: '2', sltDescription: 'Estimated Return - 2nd Quarter', ttiDescription: `${taxYear}ES2` },
  { sltId: '3', sltDescription: 'Estimated Return - 3rd Quarter', ttiDescription: `${taxYear}ES3` },
  { sltId: '4', sltDescription: 'Estimated Return - 4th Quarter', ttiDescription: `${taxYear}ES4` },
  { sltId: '5', sltDescription: 'Extension Return', ttiDescription: `${taxYear}EX1` },
];

const getExpectedYearSubPeriodLabel = (taxYear, period) =>
  getTtiPeriodNames(taxYear).find(({ sltId }) => sltId === period)?.ttiDescription;

const getTaxYearErrorMessage = (taxYear, period) => {
  const expectedYearSubPeriodLabel = getExpectedYearSubPeriodLabel(taxYear, period?.toString());
  return `Error finding eFile Tax Year ID matching ${expectedYearSubPeriodLabel}. Please confirm that period is set up in eFile.`;
};

const getTtiYearId = ({ taxYear, period, ttiYearList }) => {
  const expectedYearSubPeriodLabel = getExpectedYearSubPeriodLabel(taxYear, period?.toString());
  const ttiYear = ttiYearList.find(
    yearSubPeriod => yearSubPeriod.label === expectedYearSubPeriodLabel,
  );
  return ttiYear?.value || null;
};

module.exports = {
  getTtiPeriodNames,
  getTtiYearId,
  getTaxYearErrorMessage,
};
