import React, { useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';
import FaIcon from '../../displayComponents/faIcon/faIcon.component';
import config from '../../../config';

interface AdminCellRendererFactoryProps {
  onEditIconClick?: (data: ICellRendererParams['data']) => unknown;
  onDeleteIconClick?: (data: ICellRendererParams['data']) => unknown;
  onAddIconClick?: (data: ICellRendererParams['data']) => unknown;
  onExpressionBuilderIconClick?: (data: ICellRendererParams['data']) => unknown;
  onAttachmentIconClick?: (data: ICellRendererParams['data']) => unknown;
  shouldShowEditIcon?: ((data: ICellRendererParams['data']) => boolean) | boolean;
  shouldShowDeleteIcon?: ((data: ICellRendererParams['data']) => boolean) | boolean;
  shouldShowAddIcon?: ((data: ICellRendererParams['data']) => boolean) | boolean;
  shouldShowExpressionBuilderIcon?: ((data: ICellRendererParams['data']) => boolean) | boolean;
  shouldShowExpressionBuilderAddIcon?: ((data: ICellRendererParams['data']) => boolean) | boolean;
  shouldDisableAddIcon?: ((data: ICellRendererParams['data']) => boolean) | boolean;
  shouldDisableDeleteIcon?: ((data: ICellRendererParams['data']) => boolean) | boolean;
  shouldShowAttachmentIcon?: ((data: ICellRendererParams['data']) => boolean) | boolean;
  customTooltipMessage?: string;
  customIcon?: string;
}

const AdminCellRendererFactory = ({
  onEditIconClick = () => null,
  onAddIconClick = () => null,
  onDeleteIconClick = () => null,
  onExpressionBuilderIconClick = () => null,
  onAttachmentIconClick = () => null,
  shouldShowEditIcon = () => true,
  shouldShowDeleteIcon = () => true,
  shouldShowAddIcon = () => false,
  shouldShowExpressionBuilderIcon = () => false,
  shouldShowExpressionBuilderAddIcon = () => false,
  shouldDisableAddIcon = () => false,
  shouldDisableDeleteIcon = () => false,
  shouldShowAttachmentIcon = () => false,
  customTooltipMessage,
  customIcon,
}: AdminCellRendererFactoryProps) => {
  const AdminCellRenderer = ({ data }: ICellRendererParams) => {
    const editHandleClick = useCallback(() => {
      onEditIconClick(data);
    }, [data]);

    const addHandleClick = useCallback(() => {
      onAddIconClick(data);
    }, [data]);

    const deleteHandleClick = useCallback(() => {
      onDeleteIconClick(data);
    }, [data]);

    const expressionBuilderHandleClick = useCallback(() => {
      onExpressionBuilderIconClick(data);
    }, [data]);

    const attachmentHandleClick = useCallback(() => {
      onAttachmentIconClick(data);
    }, [data]);

    const checkIfShouldShowIcon = (
      shouldShowIcon: ((data: ICellRendererParams['data']) => boolean) | boolean,
    ) => {
      if (typeof shouldShowIcon === 'function') {
        return shouldShowIcon(data);
      }
      return Boolean(shouldShowIcon);
    };

    const showEditIcon = checkIfShouldShowIcon(shouldShowEditIcon);
    const showAddIcon = checkIfShouldShowIcon(shouldShowAddIcon);
    const showDeleteIcon = checkIfShouldShowIcon(shouldShowDeleteIcon);
    const showExpressionBuilderIcon = checkIfShouldShowIcon(shouldShowExpressionBuilderIcon);
    const showExpressionBuilderAddIcon = checkIfShouldShowIcon(shouldShowExpressionBuilderAddIcon);
    const showAttachmentIcon = checkIfShouldShowIcon(shouldShowAttachmentIcon);

    return (
      <div className="cell-icon-button-group">
        {showEditIcon && (
          <button className="cell-icon-button" title="Edit" onClick={editHandleClick} type="button">
            <AppkitIcon className="cell-icon" icon="edit" size={16} type="outline" />
          </button>
        )}
        {showAddIcon && (
          <button
            className="cell-icon-button"
            title="Add"
            onClick={addHandleClick}
            type="button"
            disabled={
              typeof shouldDisableAddIcon === 'function'
                ? shouldDisableAddIcon(data)
                : shouldDisableAddIcon
            }
          >
            <AppkitIcon className="cell-icon" icon="circle-plus" size={16} type="outline" />
          </button>
        )}
        {showDeleteIcon && (
          <button
            className="cell-icon-button"
            title={customTooltipMessage || 'Delete'}
            disabled={
              typeof shouldDisableDeleteIcon === 'function'
                ? shouldDisableDeleteIcon(data)
                : shouldDisableDeleteIcon
            }
            onClick={deleteHandleClick}
            type="button"
          >
            <AppkitIcon
              className="cell-icon"
              icon={customIcon || 'delete'}
              size={16}
              type="outline"
            />
          </button>
        )}
        {showAttachmentIcon && (
          <button
            className="cell-icon-button"
            title="Add an attachment"
            onClick={attachmentHandleClick}
            type="button"
          >
            <FaIcon className="cell-icon" icon="paperclip" size={16} prefix="fal" />
          </button>
        )}
        {showExpressionBuilderIcon && (
          <button
            className="cell-icon-button"
            title="Expression Builder"
            onClick={expressionBuilderHandleClick}
            type="button"
          >
            <img
              className="cell-icon-svg align-bottom"
              src={`${config.BASE_PATH}/assets/func-icon.svg`}
              alt="expr_build_icon"
            />
          </button>
        )}
        {showExpressionBuilderAddIcon && (
          <button
            className="cell-icon-button"
            title="Expression Builder"
            onClick={expressionBuilderHandleClick}
            type="button"
          >
            <FaIcon className="cell-icon" icon="plus-circle" size={16} prefix="fal" />
          </button>
        )}
      </div>
    );
  };

  return AdminCellRenderer;
};

export default AdminCellRendererFactory;
