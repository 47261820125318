import React, { useCallback, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@pwc/appkit-react';

import useModal from '../../shared/hooks/useModal.hook';
import useGridApi from '../../shared/hooks/useGridApi.hook';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import SplitPane from '../../shared/displayComponents/splitPane/splitPane.component';
import WarningModal from '../../shared/displayComponents/warningModal.component';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { jurisdictionIdSelector, taxYearSelector } from '../store/selectors';
import { selectTaxYear, selectJurisdiction, blockContextChanges } from '../store/actions';
import { useQuerySelectionLists } from '../../shared/queries/selectionLists';
import { useMutationDeleteSelectionList } from '../../shared/mutations/selectionLists';
import { ContextType } from '../../shared/enums';
import { useUpdateDevelopmentContextFromQueryParams } from '../store/hooks';

import EditSelectionList from './editSelectionList/editSelectionList.container';
import DeleteSelectionListModal from './editSelectionList/deleteSelectionListModal/deleteSelectionListModal.component';
import getColumnDefinitions from './selectionLists.columnDefinitions';

const WARNING_MODAL_TITLE = 'There are unsaved changes for current context.';
const WARNING_MODAL_MESSAGE = 'Do you want to remove changes made for current context?';

const SelectionLists = () => {
  useUpdateDevelopmentContextFromQueryParams();

  const editSelectionListRef = useRef(null);
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const { showModal: showWarningModal, modalProps } = useModal();

  const selectionLists = useQuerySelectionLists({
    params: { taxYear, jurisdictionId },
    enabled: Boolean(taxYear && jurisdictionId),
  });
  const { mutateAsync: deleteSelectionList } = useMutationDeleteSelectionList();

  const [selectionListToEdit, setSelectionListToEdit] = useState(null);
  const { gridApi, onGridReady } = useGridApi();

  const clearForm = useCallback(() => {
    editSelectionListRef.current?.resetForm();
    gridApi.deselectAll();
    setSelectionListToEdit(null);
  }, [gridApi]);

  const selectSelectionListToEdit = useCallback(
    ({ api }) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        setSelectionListToEdit(selectedRow);
      }
    },
    [setSelectionListToEdit],
  );

  const renderAdditionalHeaderElements = useCallback(
    () => (
      <Button size="lg" kind="secondary" onClick={clearForm}>
        Add Selection List
      </Button>
    ),
    [clearForm],
  );

  const submitAction = useCallback(
    context => {
      clearForm();
      dispatch(blockContextChanges(false));
      if (context?.type === ContextType.JURISDICTION_ID_TYPE) {
        dispatch(selectJurisdiction(context.value));
      } else if (context?.type === ContextType.TAX_YEAR_TYPE) {
        dispatch(selectTaxYear(context.value));
      }
    },
    [dispatch, clearForm],
  );

  // In order to enable Search for selection lists items, we need to join this array here instead of cellRenderer
  const mappedRowData = useMemo(
    () =>
      selectionLists.data.map(list => ({
        ...list,
        itemsJoined: list.items?.map(({ item }) => item).join('; ') || [],
      })),
    [selectionLists.data],
  );

  const {
    showModal: showDeleteSelectionListModal,
    modalProps: deleteSelectionListModalProps,
  } = useModal();

  const handleDeleteIconClick = useCallback(
    selectionList => {
      showDeleteSelectionListModal(selectionList);
    },
    [showDeleteSelectionListModal],
  );

  const handleDelete = useCallback(
    async ({ id }) => {
      await deleteSelectionList({ selectionListId: id });
      clearForm();
    },
    [deleteSelectionList, clearForm],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: handleDeleteIconClick,
      }),
    [handleDeleteIconClick],
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <HeaderWithParamDropdownsWrapper
            showWarningModal={showWarningModal}
            renderAdditionalElements={renderAdditionalHeaderElements}
            showHeaderTaxYearDropdown
            showHeaderJurisdictionDropdown
            enableEverywhereJurisdiction
          />
        </div>
      </div>
      <SplitPane>
        <AgGrid
          rowData={mappedRowData}
          columnDefs={columnDefinitions}
          isGridLoading={selectionLists.isFetching}
          onSelectionChanged={selectSelectionListToEdit}
          onGridReady={onGridReady}
          withSearchBar
          areHeaderCellBordersEnabled
          autoMaxWidth
          sideBar={defaultSideBarWithColumnsToolPanel}
        />
        <EditSelectionList
          ref={editSelectionListRef}
          selectionListToEdit={selectionListToEdit}
          clearForm={clearForm}
          taxYear={taxYear}
          jurisdictionId={jurisdictionId}
          selectionLists={mappedRowData.map(selectionList => ({
            id: selectionList.id,
            name: selectionList.name,
          }))}
        />
      </SplitPane>
      <WarningModal
        title={WARNING_MODAL_TITLE}
        warningMessage={WARNING_MODAL_MESSAGE}
        submitAction={submitAction}
        {...modalProps}
      />
      {deleteSelectionListModalProps.visible && (
        <DeleteSelectionListModal {...deleteSelectionListModalProps} onDelete={handleDelete} />
      )}
    </>
  );
};

export default SelectionLists;
