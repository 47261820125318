import { currencyColumnDefinition } from '../../../shared/columnDefinitions';
import {
  IconCellRendererFactory,
  TextCellRendererFactory,
} from '../../../shared/columnDefinitions/cellRenderers';

const isTotalRow = ({ data: { isTotalRow } }) => isTotalRow;

const defaultColumnDefinition = {
  width: 150,
  sortable: false,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
  cellClassRules: {
    'total-row-cell': isTotalRow,
  },
};

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const notesIconRenderer = IconCellRendererFactory({
  getName: (value, { hasNote }) => (hasNote ? 'news' : 'plus'),
  isSmall: (value, { hasNote }) => !hasNote,
  clickHandler: (value, { hasNote }) =>
    hasNote // TODO: Link proper action on note click
      ? null
      : null,
  isClickHandlerActive: false,
});

const getColumnDefinitions = () => {
  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Component Id',
      field: 'componentId',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Federal Amount',
      field: 'federalAmount',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'SLT Amount',
      field: 'sltAmount',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Variance',
      field: 'reconVariance',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Note',
      field: 'doc',
      width: 35,
      cellRenderer: row => (isTotalRow(row) ? TextRenderer(row) : notesIconRenderer(row)),
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
