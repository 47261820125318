import React, { useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { taxFormsV2Schemas } from '@common-packages/validators';

import { SelectOptionPropTypes } from '../../../../shared/forms/propTypes';
import { pdfFieldSelector } from '../../../../shared/store/dataModels/selectors';
import {
  isFetchingPdfPageDataUsageSelector,
  isFetchingTaxFormSelector,
  isUpdatingTaxFormSelector,
} from '../../store/selectors';
import { formIdSelector } from '../../../store/selectors';
import { FIELDS_TO_UPDATE } from '../../constants';
import Loading from '../../../../shared/displayComponents/loading.component';

import FieldsEditForm from './fieldsEditForm.container';
import handleAttributeChangeFactory from './utils/handleAttributeChangeFactory';
import handleFieldUnmapFactory from './utils/handleFieldUnmapFactory';
import { initialValues } from './constants';

const Fields = ({
  selectedFormFieldId,
  handleFormFieldChange,
  setIsFormDirty,
  datasetsAssignedToFormPage,
}) => {
  const formId = useSelector(formIdSelector);
  const isFetchingTaxForm = useSelector(isFetchingTaxFormSelector);
  const isUpdatingTaxForm = useSelector(isUpdatingTaxFormSelector);
  const isFetchingPdfDataUsage = useSelector(isFetchingPdfPageDataUsageSelector);
  const selectedFormField = useSelector(state => pdfFieldSelector(state, selectedFormFieldId));

  const formFieldsRef = useRef();
  const {
    setValues,
    values: { [FIELDS_TO_UPDATE]: updatedFormFields },
  } = useFormikContext();

  useEffect(() => {
    if (formFieldsRef.current) {
      formFieldsRef.current.validateForm();
    }
  }, []);

  useEffect(() => {
    if (!updatedFormFields && formFieldsRef.current) {
      formFieldsRef.current.resetForm();
    }
  }, [updatedFormFields]);

  const handleAttributeChange = useCallback(
    (name, value) => {
      setIsFormDirty(true);
      setValues(
        handleAttributeChangeFactory({
          name,
          value,
          selectedFormField,
          selectedFormFieldId,
        }),
      );
    },
    [setIsFormDirty, setValues, selectedFormFieldId, selectedFormField],
  );

  const onUnmapField = useCallback(() => {
    setIsFormDirty(true);
    setValues(
      handleFieldUnmapFactory({
        selectedFormField,
        selectedFormFieldId,
      }),
    );
    formFieldsRef.current.setValues(initialValues);
  }, [setIsFormDirty, setValues, selectedFormField, selectedFormFieldId]);

  const updatedPdfField = updatedFormFields?.[selectedFormFieldId];
  const isLoading = !formId || isFetchingTaxForm || isUpdatingTaxForm || isFetchingPdfDataUsage;

  const renderFields = useCallback(
    formikProps => (
      <FieldsEditForm
        {...formikProps}
        isLoading={isLoading}
        handleAttributeChange={handleAttributeChange}
        onUnmapField={onUnmapField}
        selectedFormFieldId={selectedFormFieldId}
        handleFormFieldChange={handleFormFieldChange}
        datasetsAssignedToFormPage={datasetsAssignedToFormPage}
      />
    ),
    [
      onUnmapField,
      handleFormFieldChange,
      handleAttributeChange,
      selectedFormFieldId,
      datasetsAssignedToFormPage,
      isLoading,
    ],
  );

  return (
    <Loading isLoading={isLoading}>
      <Formik
        innerRef={formFieldsRef}
        initialValues={updatedPdfField || selectedFormField || initialValues}
        validationSchema={taxFormsV2Schemas.editTaxFormFieldSchema}
        enableReinitialize={!updatedPdfField}
      >
        {renderFields}
      </Formik>
    </Loading>
  );
};

Fields.propTypes = {
  selectedFormFieldId: PropTypes.string,
  handleFormFieldChange: PropTypes.func.isRequired,
  setIsFormDirty: PropTypes.func.isRequired,
  datasetsAssignedToFormPage: PropTypes.arrayOf(SelectOptionPropTypes),
};

export default Fields;
