import { connect } from 'react-redux';

// TODO this file should be moved to the directory below
import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import { isFetchingEntitiesSelector, entityIdSelector } from '../../shared/store/selectors';

const EntityValue = connect((state, props) => ({
  label: props.label || 'Entity',
  value: entityIdSelector(state),
  isBusy: isFetchingEntitiesSelector(state),
}))(GlobalContextValue);

export default EntityValue;
