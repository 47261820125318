import React from 'react';
import { useSelector } from 'react-redux';

import Loading from '../../shared/displayComponents/loading.component';
import { globalContextSelector, isClientTtiAvailableSelector } from '../../shared/store/selectors';
import { useQueryEFileXmlComparisonReport } from '../../shared/queries/reports';
import DataInfo from '../../shared/displayComponents/dataInfo/dataInfo.component';

import styles from './eFileXmlComparisonReport.module.scss';

const EFileTransmissionStatus = () => {
  const globalContext = useSelector(globalContextSelector);
  const isClientTtiAvailable = useSelector(isClientTtiAvailableSelector);

  const {
    data: eFileXmlComparisonReportData,
    isFetching: isFetchingEFileXmlComparisonReport,
  } = useQueryEFileXmlComparisonReport({
    params: { apiRouteParamString: globalContext.apiRouteParamString },
    enabled: Boolean(globalContext.isReady && isClientTtiAvailable),
  });

  return !isClientTtiAvailable ? (
    <DataInfo textToRender="Not found" />
  ) : (
    <div className={`row ${styles.eFileXmlComparisonReportContainer}`}>
      <div className="col">
        <Loading isLoading={isFetchingEFileXmlComparisonReport}>
          <iframe
            sandbox="allow-scripts"
            className={styles.eFileXmlComparisonReportIframe}
            title="eFile XML Comparison Report"
            srcDoc={eFileXmlComparisonReportData?.htmlContent}
          />
        </Loading>
      </div>
    </div>
  );
};

export default EFileTransmissionStatus;
