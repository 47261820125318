import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import FaIcon from '../../displayComponents/faIcon/faIcon.component';
import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';

const DEFAULT_SIZE = 14;
const SMALL_SIZE = 8;

const IconCellRendererFactory = ({
  getName = unknown => unknown,
  getPathName = () => null,
  isSmall = () => false,
  clickHandler = null,
  isVisible = true,
  isClickHandlerActive = false,
  isFaIcon = false,
}) => {
  const IconCellRenderer = ({ value, data }) => {
    const checkIfActive = isActive => {
      if (typeof isActive === 'function') {
        return isActive(data);
      }
      return Boolean(isActive);
    };

    const showIcon = checkIfActive(isVisible);

    const pathName = getPathName(value, data);
    const iconSize = isSmall(value, data) ? SMALL_SIZE : DEFAULT_SIZE;
    const iconClassName = pathName ? 'cell-icon-with-link' : 'cell-icon';

    const clickHandlerWithDefault = clickHandler ? () => clickHandler(value, data) : () => null;
    const spannClassName = checkIfActive(isClickHandlerActive) ? 'cell-icon-with-pointer' : '';

    const renderIcon = isFaIcon ? (
      <FaIcon className={iconClassName} icon={getName(value, data)} size={iconSize} />
    ) : (
      <AppkitIcon className={iconClassName} icon={getName(value, data)} size={iconSize} />
    );

    const renderIconWithLink = (
      <Link to={{ pathname: pathName }} onClick={clickHandlerWithDefault}>
        {renderIcon}
      </Link>
    );

    const renderIconWithOnClick = (
      <span onClick={clickHandlerWithDefault} className={spannClassName}>
        {renderIcon}
      </span>
    );

    if (!showIcon) {
      return <span />;
    }

    return pathName ? renderIconWithLink : renderIconWithOnClick;
  };

  IconCellRenderer.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    data: PropTypes.object.isRequired,
  };
  return IconCellRenderer;
};

export default IconCellRendererFactory;
