const Yup = require('yup');

const { dateRegex } = require('../../sharedConstants/dateRegex');
const { taxYearEndingRequiredErrorMessage } = require('../constants');
const { getDueDateSchema } = require('../getDuedateSchema');
const { getFilingFormschema } = require('../getFilingFormschema');
const { getTaxTypeSchema } = require('../getTaxTypeSchema');

const getAddEditTaxReturnSchema = ({
  shouldDisplayDueDate,
  dueDateOption,
  stateFilingFormOptions,
  entityFiscalYearEnd,
  taxReturnDefinitionEnabledReturnTypeOptions = [],
  taxReturnDefinitionEnabledBusinessTypeOptions = [],
}) =>
  Yup.object().shape({
    returnType: Yup.string()
      .label('Federal Return Type')
      .required()
      .existsInList({
        message: 'Federal Return Type is a required field.',
        list: taxReturnDefinitionEnabledReturnTypeOptions,
        mapper: ({ value }) => value,
      }),
    businessType: Yup.string()
      .label('Business Type')
      .required()
      .existsInList({
        message: 'Business Type is a required field.',
        list: taxReturnDefinitionEnabledBusinessTypeOptions,
        mapper: ({ value }) => value,
      }),
    ...getTaxTypeSchema(),
    ...getDueDateSchema({
      shouldDisplayDueDate,
      dueDateOption,
      entityFiscalYearEnd,
    }),
    ...getFilingFormschema({ stateFilingFormOptions }),
  });

const checkTaxReturnTaxYearEndingSchema = Yup.object().shape({
  taxYearEnding: Yup.string()
    .label('Tax Year Ending')
    .matches(dateRegex, taxYearEndingRequiredErrorMessage)
    .required(),
});

module.exports = {
  getAddEditTaxReturnSchema,
  checkTaxReturnTaxYearEndingSchema,
};
