const getFileNameFromContentDisposition = (contentDisposition: string | null | undefined) => {
  if (!contentDisposition?.includes('filename')) {
    return null;
  }

  const regex = /filename[^;\n]*=\s*(?:UTF-\d['"]*)?((['"]).*?\2|[^;\n]*)/;
  const matches = regex.exec(contentDisposition);

  if (matches?.[1]) {
    const fileName = decodeURIComponent(matches[1].replace(/['"]/g, ''));
    return fileName;
  }

  return null;
};

export default getFileNameFromContentDisposition;
