const Yup = require('yup');

const { ConsolidationMethodFilingTypes } = require('../../../commonPackages/sharedConstants');

const getReturnDefinitionFrontendSchema = ({ returnDefinitions, hasDueDate }) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .label('Return Definition Name')
      .max(255)
      .required()
      .uniqueInList({
        message: 'Return definition name must be unique',
        list: returnDefinitions,
        mapper: ({ name }) => name.trim(),
      }),
    filingFormId: Yup.string().label('Filing Form').required(),
    sltSchemaId: Yup.lazy(sltSchemaId => {
      switch (typeof sltSchemaId) {
        case 'string':
          return Yup.string()
            .max(25)
            .label('E-File Schema ID')
            .nullable()
            .test(
              'isEfileSchemaSelected',
              'E-File Schema ID is a required field',
              value => value !== null,
            );
        default:
          return Yup.number()
            .label('E-File Schema ID')
            .nullable()
            .test(
              'isEfileSchemaSelected',
              'E-File Schema ID is a required field',
              value => value !== null,
            );
      }
    }),
    isDefault: Yup.bool().label('Default Return').required(),
    isStackedReturn: Yup.bool().label('Stacked Return').required(),
    dueDate: Yup.string().label('Due Date').nullable(),
    dueDateDays: hasDueDate
      ? Yup.number().label('Due Date Days').nullable().required()
      : Yup.number().label('Due Date Days').nullable(),
    dueDateMonths: hasDueDate
      ? Yup.number().label('Due Date Months').nullable().required()
      : Yup.number().label('Due Date Months').nullable(),
    dueDateExtendedMonths: Yup.number().label('Due Date Extended Months').required(),
    dueDateSecondExtendedMonths: Yup.number().label('Due Date Second Extended Months').required(),
    isEfileAllowed: Yup.bool().label('E-File Allowed').required(),
    isPaperAllowed: Yup.bool().label('Paper Allowed').required(),
    federalReturnAttachment: Yup.mixed()
      .nullable()
      .when('isEfileAllowed', {
        is: true,
        then: Yup.string().label('Federal Return Attachment').nullable().required(),
      }),
    isFederalXmlRequired: Yup.bool().label('Federal XML Required').required(),
    isFederalAsFiledRequested: Yup.bool().label('Federal As Filed Requested').required(),
    hasMandate: Yup.bool().label('E-File Mandated').required(),
    failureToEfilePenalty: Yup.string().label('Failure to E-File Penalty').max(200).nullable(),
    isEfileReady: Yup.bool().label('E-File Ready').required(),
    filingInformation: Yup.string().label('Filing Information').max(1000).nullable(),
    filingWarning: Yup.string().label('Filing Warning').max(500).nullable(),
    nonFilingSeparateReturnDefId: Yup.string()
      .nullable()
      .label('Non-filing Separate Form')
      .when(
        [
          ConsolidationMethodFilingTypes.PRE,
          ConsolidationMethodFilingTypes.POST,
          ConsolidationMethodFilingTypes.PUSHBACK,
        ],
        {
          is: (IS_PRE_APP, IS_POST_APP, IS_UNITARY) => IS_PRE_APP || IS_POST_APP || IS_UNITARY,
          then: Yup.string()
            .nullable()
            .when('filingSeparateReturnDefId', {
              is: value => Boolean(value),
              then: Yup.string().nullable(),
              otherwise: Yup.string()
                .nullable()
                .test('atLeastOneForm', `A Non-filing or Filing Form must be selected`, value =>
                  Boolean(value),
                ),
            }),
        },
      ),
    filingSeparateReturnDefId: Yup.string()
      .nullable()
      .when(
        [
          ConsolidationMethodFilingTypes.PRE,
          ConsolidationMethodFilingTypes.POST,
          ConsolidationMethodFilingTypes.PUSHBACK,
        ],
        {
          is: (IS_PRE_APP, IS_POST_APP, IS_UNITARY) => IS_PRE_APP || IS_POST_APP || IS_UNITARY,
          then: Yup.string()
            .nullable()
            .when('nonFilingSeparateReturnDefId', {
              is: value => Boolean(value),
              then: Yup.string()
                .nullable()
                .test(
                  'isSameFilingForm',
                  `The same form can't be selected in both lists. Please select a different form.`,
                  (value, context) => {
                    return value !== context.parent.nonFilingSeparateReturnDefId;
                  },
                ),
              otherwise: Yup.string()
                .nullable()
                .test('atLeastOneForm', `A Non-filing or Filing Form must be selected`, value =>
                  Boolean(value),
                ),
            }),
        },
      ),
  });

const getAddReturnDefinitionBackendSchema = ({ returnDefinitions, hasDueDate }) =>
  getReturnDefinitionFrontendSchema({
    returnDefinitions,
    hasDueDate,
  }).concat(
    Yup.object().shape({
      taxYear: Yup.string().label('Tax Year').required(),
      jurisdictionId: Yup.string().label('Jurisdiction ID').required(),
    }),
  );

const getEditReturnDefinitionBackendSchema = ({ returnDefinitions, hasDueDate }) =>
  getAddReturnDefinitionBackendSchema({
    returnDefinitions,
    hasDueDate,
  }).concat(
    Yup.object().shape({
      id: Yup.string().label('Return Definition ID').required(),
    }),
  );

module.exports = {
  getReturnDefinitionFrontendSchema,
  getAddReturnDefinitionBackendSchema,
  getEditReturnDefinitionBackendSchema,
};
