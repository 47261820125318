import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import OverridableFormInputsGroupWrapper from './overridableFormInputsGroup/overridableFormInputsGroupWrapper.container';
import { DrillFieldPropTypes, OverridableFieldPropTypes } from './overridableForm.propTypes';
import DrillLink from './drillLink/drillLink.container';
import styles from './overridableForm.module.scss';
import useFormWatermark from './useFormWatermark.hook';
import { getMapFunction } from './utils/getMapFunction';

const OverridableFormPage = ({
  fields,
  drillLinks,
  groupFields,
  pageClassNames,
  isUpdatingFormValues,
  isPageVisible = true,
  setFocusedElementName,
  focusedElementName = null,
  pageId = null,
  isLandscape,
}) => {
  const { queryParams } = useUrlParams();
  const { scrollElementId } = queryParams;

  const focusedElementIndex = fields.findIndex(({ blockId }) =>
    focusedElementName?.includes(blockId),
  );
  const startIndex = focusedElementIndex > -1 ? focusedElementIndex : 0;
  const endIndex = startIndex + 1;

  const { renderWatermark, removeWatermark } = useFormWatermark({ pageId, isLandscape });

  useEffect(() => {
    renderWatermark();

    return () => {
      removeWatermark();
    };
  }, [renderWatermark, removeWatermark]);

  const fieldToScroll = useMemo(() => fields.find(({ blockId }) => blockId === scrollElementId), [
    fields,
    scrollElementId,
  ]);

  const checkboxGroups = useMemo(
    () =>
      Object.entries(groupFields).map(([checkboxGroupId, groupField]) => (
        <OverridableFormInputsGroupWrapper
          key={`checkboxGroup-${checkboxGroupId}`}
          groupItems={groupField || []}
          isUpdatingFormValues={isUpdatingFormValues}
          setFocusedElementName={setFocusedElementName}
        />
      )),
    [groupFields, isUpdatingFormValues, setFocusedElementName],
  );

  /**
   * 1. We don't render fields for pages that are not visible for performance reasons.
   *
   * 2. We render focused field and the one after to not lose focus and to be able to
   *    go to the next one with the enter key on not visible pages.
   *
   * 3. We render first (and well, second too) field on not visible pages if there is
   *    no focused field on them. This is because we want to be able to go to it when
   *    we are on the last field on the previous page.
   *
   * 4. We render the field that we want to scroll to.
   *
   * 5. We still need to render the links in not visible pages otherwise we will not be
   *    able to go back to proper page after navigation.
   */

  return useMemo(
    () => (
      <div id={pageId} className={classNames(pageClassNames, styles.formPage)}>
        {isPageVisible
          ? fields.map(getMapFunction({ isUpdatingFormValues, setFocusedElementName }))
          : fields
              .filter(
                ({ isEditable, isOverrideable, blockId }) =>
                  isOverrideable || isEditable || focusedElementName?.includes(blockId),
              )
              .slice(startIndex, endIndex)
              .concat(fieldToScroll || [])
              .map(getMapFunction({ isUpdatingFormValues, setFocusedElementName }))}
        {drillLinks.map(link => (
          <DrillLink key={link.baseFieldName} fieldValues={link} />
        ))}
        {checkboxGroups}
      </div>
    ),
    [
      setFocusedElementName,
      drillLinks,
      endIndex,
      fields,
      focusedElementName,
      checkboxGroups,
      isPageVisible,
      isUpdatingFormValues,
      pageClassNames,
      pageId,
      startIndex,
      fieldToScroll,
    ],
  );
};

OverridableFormPage.propTypes = {
  fields: PropTypes.arrayOf(OverridableFieldPropTypes).isRequired,
  drillLinks: PropTypes.arrayOf(DrillFieldPropTypes).isRequired,
  groupFields: PropTypes.objectOf(PropTypes.arrayOf(OverridableFieldPropTypes)).isRequired,
  pageClassNames: PropTypes.string.isRequired,
  isUpdatingFormValues: PropTypes.bool.isRequired,
  isPageVisible: PropTypes.bool,
  setFocusedElementName: PropTypes.func.isRequired,
  focusedElementName: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  isLandscape: PropTypes.bool.isRequired,
};

export default OverridableFormPage;
