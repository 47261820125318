import { ColDef, GridApi } from 'ag-grid-community';
import { useEffect, useImperativeHandle, useState, useRef } from 'react';

export const useCellEditorSelect = ({
  ref,
  onCellValueChanged,
  api,
  colDef,
  stopEditing,
  value,
  data,
  mutateData,
}: {
  ref: React.ForwardedRef<unknown>;
  onCellValueChanged?: (event: { api: GridApi; data: { [key: string]: unknown } }) => void;
  api?: GridApi;
  colDef?: ColDef;
  stopEditing: () => void;
  value: string | boolean | null;
  data?: { [key: string]: unknown };
  mutateData?: boolean;
}) => {
  const [selectedValue, setSelectedValue] = useState<string | boolean | null>(null);
  const [shouldClearValue, setShouldClearValue] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectedValue || shouldClearValue) {
      stopEditing();
    }
  }, [stopEditing, selectedValue, shouldClearValue]);

  useImperativeHandle(ref, () => ({
    isPopup: () => true,
    getValue: () => {
      if (shouldClearValue) {
        return null;
      }
      const returnValue = selectedValue || value;

      if (mutateData && data && colDef && api && onCellValueChanged) {
        // Intentionally mutating data object.
        // api.applyTransaction({ update: [data] }) uses object references to detect row to update
        // https://www.ag-grid.com/javascript-grid-data-update/#updating-rows
        // 'the grid will use node ID's if you are providing your own ID's, otherwise it will use object reference to identify rows.'
        if (colDef?.field) {
          data[colDef?.field] = returnValue;
        }

        onCellValueChanged({ api, data });
      }

      return returnValue;
    },
    afterGuiAttached: () => {
      if (inputRef.current) {
        (inputRef.current as HTMLDivElement).focus();
      }
    },
  }));

  return {
    setShouldClearValue,
    setSelectedValue,
    selectedValue,
    inputRef,
  };
};
