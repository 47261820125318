import { createSelector } from 'reselect';
import { TOTAL_DESC } from '@common-packages/shared-constants';

export const isFetchingAccountDetailsSelector = ({ shared }) =>
  shared.accountDetails.isFetchingAccountDetails;

export const accountDetailsSelector = createSelector(
  [({ shared }) => shared.accountDetails.accountDetails],
  accountDetails =>
    accountDetails.length
      ? [
          ...accountDetails,
          {
            component: TOTAL_DESC,
            isTotalRow: true,
            amount: accountDetails.reduce((acc, curr) => acc + curr.amount, 0),
            name: '',
          },
        ]
      : [],
);
