import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ isInEditMode, updateRow, ToggleAllSelectionsHeader }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerComponent: ToggleAllSelectionsHeader,
      headerName: 'Assigned',
      field: 'isUserAssigned',
      colId: 'isUserAssigned',
      width: 100,
      cellStyle: {
        textAlign: 'center',
      },
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Business',
      field: 'business',
      width: 450,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
