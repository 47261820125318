import React, { Fragment, ReactNode } from 'react';
import Spinner from '@tls/ui-spinner';

interface LoadingProps {
  isLoading: boolean;
  small?: boolean;
  className?: string;
  children?: ReactNode;
}

const Loading = ({ isLoading, small = false, className = '', children }: LoadingProps) => {
  if (isLoading) {
    return <Spinner className={className} small={small} />;
  }

  return <Fragment>{children}</Fragment>;
};

export default Loading;
