import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import getFieldValue from '../../../utils/getFieldValue';

import {
  fetchFilingGroupsTypes,
  SELECT_FILING_GROUP_ID,
  fetchFilingGroupsOptionsTypes,
} from './types';

export const initialState = {
  filingGroupsData: {
    filingGroups: [],
    consolidationReturnDefinitionData: {
      returnDefinitionId: '',
      nonFilingSeparateReturnDefId: '',
      filingSeparateReturnDefId: '',
    },
  },
  isFetchingFilingGroupsData: false,

  filingGroupId: null,
  previousFilingGroupId: null,

  filingGroupsOptions: [],
  isFetchingFilingGroupsOptions: false,
};

const filingGroupsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFilingGroupsTypes,
      payloadKey: 'filingGroupsData',
      fetchingKey: 'isFetchingFilingGroupsData',
      getAdditionalState: (state, payload) => ({
        // Set initial filingGroupId
        filingGroupId: getFieldValue({
          currentValueFieldName: 'filingGroupId',
          payloadFieldName: 'filingGroupId',
          state,
          payload: payload.filingGroups,
        }),
      }),
      initialAdditionalState: {
        filingGroupId: null,
      },
    }),

    [SELECT_FILING_GROUP_ID]: (state, payload) => ({
      ...state,
      filingGroupId: payload,
      previousFilingGroupId: state.filingGroupId,
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchFilingGroupsOptionsTypes,
      payloadKey: 'filingGroupsOptions',
      fetchingKey: 'isFetchingFilingGroupsOptions',
    }),
  },
});

export default filingGroupsReducer;
