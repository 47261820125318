import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import { fetchFilterDataTypes, fetchFormFieldsTypes, updateFormFieldsTypes } from './types';

export const initialState = {
  filterData: {
    forms: [],
    partners: [],
  },
  isFetchingFilterData: false,

  formFields: [],
  isFetchingFormFields: false,

  isUpdatingFormFields: false,
};

const sharedReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFilterDataTypes,
      payloadKey: 'filterData',
      fetchingKey: 'isFetchingFilterData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFormFieldsTypes,
      payloadKey: 'formFields',
      fetchingKey: 'isFetchingFormFields',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateFormFieldsTypes,
      fetchingKey: 'isUpdatingFormFields',
    }),
  },
});

export default sharedReducer;
