import {
  FederalReturnOption,
  GetEFileSubmissionFilesResponse,
} from '../../../../../../common/types';
import { generateFederalFileUrl } from '../../utils/generateFileUrls';

interface IGetFederalSubmissions {
  submissionFiles: GetEFileSubmissionFilesResponse;
  federalReturnOption: FederalReturnOption;
  taxYear: string;
}

const getFederalSubmissions = ({
  submissionFiles,
  federalReturnOption,
  taxYear,
}: IGetFederalSubmissions) =>
  submissionFiles.federalFiles.map(
    ({ fileName, id: proformaImportId, path: filePath, fileLocator, downloadFileName }) => ({
      label: fileName,
      url: generateFederalFileUrl({
        proformaImportId,
        filePath,
        federalReturnOption,
        taxYear, // this is just to satisfy ts, above we only go in here if value is non-null
      }),
      fileLocator,
      fileName: downloadFileName,
    }),
  );

export default getFederalSubmissions;
