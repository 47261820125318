import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';

import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import { dateFormatter } from '../../shared/formatters';
import Loading from '../../shared/displayComponents/loading.component';
import { globalContextSelector } from '../../shared/store/selectors';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useQueryDiagnostics } from '../../shared/queries/diagnostics';
import { useMutationDiagnostics } from '../../shared/mutations/diagnostics';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import config from '../../config';

import columnDefinitions from './diagnostics.columnDefinitions';
import styles from './diagnostics.module.scss';

const Diagnostics = ({ hasUserPermissionsToEdit }) => {
  const globalContext = useSelector(globalContextSelector);

  const {
    mutateAsync: refreshDiagnostics,
    isLoading: isRefreshingDiagnostics,
  } = useMutationDiagnostics();

  const { data: diagnostics, isFetching: isFetchingDiagnostics } = useQueryDiagnostics({
    enabled: globalContext.isReady,
  });

  const refreshDiagnosticsFormattedDate = useMemo(
    () => dateFormatter(diagnostics?.[0]?.date, config.AMERICAN_DATE_TIME_FORMAT),
    [diagnostics],
  );

  const { props, onGridReady } = useActionMenuForExport({
    reportData: diagnostics,
    includeHiddenColumns: true,
  });

  const isLoading = isFetchingDiagnostics || isRefreshingDiagnostics;

  return (
    <>
      <div className="row">
        {hasUserPermissionsToEdit && (
          <div className="col add-button-column">
            <div className={styles.lastRunInfo}>
              <Loading small isLoading={!globalContext.isReady || isLoading}>
                <span>{`Last Run: ${refreshDiagnosticsFormattedDate}`}</span>
              </Loading>
            </div>
            <Button
              size="lg"
              onClick={refreshDiagnostics}
              disabled={!globalContext.isReady || isLoading}
              className="add-button"
            >
              Run Diagnostics
            </Button>
          </div>
        )}
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={diagnostics}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            rowSelection="multiple"
            onGridReady={onGridReady}
            sideBar={defaultSideBarWithColumnsToolPanel}
            withSearchBar
          />
        </div>
      </div>
    </>
  );
};

Diagnostics.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default Diagnostics;
