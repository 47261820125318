const onCellValueChangedFactory = dataMapper => async ({ node, oldValue, newValue, api }) => {
  const mappedData = await dataMapper({ data: node.data, oldValue, newValue });

  // WARNING - changing node here because we need reference to stay the same
  // otherwise agGrid won't know what to update/refresh
  node.data = mappedData;

  api.applyTransaction({
    update: [node],
  });
  api.refreshCells({ rowNodes: [node], force: true });
};

export default onCellValueChangedFactory;
