import { httpGetBlob } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from './index';

interface AttachmentsParams {
  taxYear: string;
  period: string;
  jurisdictionId: string;
}

interface Attachment {
  fileName: string;
  attachmentType: string;
  submissionFileName: string;
  attachmentDescription: string;
  pdfFile: Blob;
  attachmentId: string;
}

interface AttachmentsResponse {
  allAttachments: Array<Attachment>;
  businessUnitAttachments: Array<Attachment>;
}

const downloadAttachment = (link: string) =>
  httpGetBlob({
    route: link,
    errorMessage: 'Downloading attachment failed',
  });

export const useQueryDownloadAttachment = ({ link, enabled }: { link: string; enabled: boolean }) =>
  useCustomQuery<Blob>([QueryKeys.Attachments.DownloadAttachment, link], {
    enabled,
    customFetch: () => downloadAttachment(link),
  });

export const useQueryAttachments = ({
  params,
  enabled,
}: {
  params: AttachmentsParams;
  enabled: boolean;
}) =>
  useCustomQuery<AttachmentsResponse>([QueryKeys.Attachments.Attachments], {
    url: `/api/tools/attachments/${params.taxYear}/${params.period}/${params.jurisdictionId}`,
    enabled,
    errorMessage: 'Fetching attachments failed',
  });
