import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useMutationEFileCheckTransmissionStatus } from '../../../shared/mutations/efileV2';
import { globalContextSelector } from '../../../shared/store/selectors';
import {
  BinderFilingId,
  CheckTransmissionStatusBody,
  SltBinderId,
  TaxReturnResult,
  TtiBinderId,
  TtiCaseId,
  TtiYearId,
} from '../../../../../common/types';
import { WizardStateMachineContext } from '../wizardStateMachine/types';
import { StepStatus, WizardStepName } from '../enums';
import { TransmitterProvider } from '../../../shared/enums/eFileV2';

interface UseCheckTransmissionStatusParams {
  ttiYearId?: TtiYearId | null;
  ttiCaseId?: TtiCaseId | null;
  ttiBinderId?: TtiBinderId;
  sltBinderId: SltBinderId | null;
  binderFilingId?: BinderFilingId;
  wizardStateMachineContext: WizardStateMachineContext;
  taxReturn: TaxReturnResult | undefined;
}

const useCheckTransmissionStatus = ({
  ttiYearId,
  ttiCaseId,
  ttiBinderId,
  sltBinderId,
  binderFilingId,
  wizardStateMachineContext,
  taxReturn,
}: UseCheckTransmissionStatusParams) => {
  const {
    mutateAsync: checkTransmissionStatus,
    isLoading: isCheckingTransmissionStatus,
  } = useMutationEFileCheckTransmissionStatus();

  const {
    taxYear,
    period,
    filingTypeId,
    params: { businessEntityId },
    jurisdictionId,
  } = useSelector(globalContextSelector);

  const isStep4Completed =
    wizardStateMachineContext.eFileSteps[WizardStepName.STEP_4_AUTHORIZE_AND_TRANSMIT] ===
    StepStatus.COMPLETED;

  const areTtiParamsReady =
    taxReturn?.selectedTransmitter === TransmitterProvider.TR ||
    Boolean(ttiYearId && ttiCaseId && ttiBinderId);

  const isReadyToCheckTransmissionStatus = useMemo(
    () =>
      Boolean(
        areTtiParamsReady &&
          wizardStateMachineContext.eFileService.submissionId &&
          sltBinderId &&
          binderFilingId &&
          isStep4Completed,
      ),
    [
      sltBinderId,
      wizardStateMachineContext.eFileService.submissionId,
      binderFilingId,
      isStep4Completed,
      areTtiParamsReady,
    ],
  );

  const handleCheckTransmissionStatus = useCallback(
    () =>
      isReadyToCheckTransmissionStatus &&
      checkTransmissionStatus({
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
        yearOrSubPeriodId: ttiYearId,
        ttiCaseId,
        sltBinderId,
        ttiBinderId,
        efSubmissionId: wizardStateMachineContext.eFileService.submissionId,
        binderFilingId,
      } as CheckTransmissionStatusBody),
    [
      isReadyToCheckTransmissionStatus,
      checkTransmissionStatus,
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
      ttiYearId,
      ttiCaseId,
      sltBinderId,
      ttiBinderId,
      wizardStateMachineContext.eFileService.submissionId,
      binderFilingId,
    ],
  );

  return {
    handleCheckTransmissionStatus,
    isCheckingTransmissionStatus,
    isReadyToCheckTransmissionStatus,
  };
};

export default useCheckTransmissionStatus;
