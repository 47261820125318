import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import useModal from '../shared/hooks/useModal.hook';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import { periodSelector, taxYearSelector } from '../shared/store/selectors';

import ReassignTasksModal from './reassignTasksModal.container';
import { columnDefinitions } from './tasksDashboard.columnDefinitions';
import { fetchTasks } from './store/actions';
import {
  isFetchingTasksSelector,
  tasksSelector,
  tasksFilteredByDueDateSelector,
} from './store/selectors';

const TasksReview = ({
  taxYear,
  period,
  fetchTasks,
  isFetchingTasks,
  tasks,
  tasksFilteredByDueDate,

  hasUserPermissionsToEdit,
}) => {
  const isContextReady = taxYear && period;
  const [shouldFilterTasksByDueDate, setShouldFilterTasksByDueDate] = useState(false);

  useEffect(() => {
    if (isContextReady) {
      fetchTasks({ taxYear, period });
    }
  }, [fetchTasks, isContextReady, taxYear, period]);

  const toggleShouldFilterTasksByDueDate = useCallback(
    () => setShouldFilterTasksByDueDate(!shouldFilterTasksByDueDate),
    [shouldFilterTasksByDueDate],
  );

  const { showModal, modalProps } = useModal();

  return (
    <>
      <div className="row">
        <div className="col add-button-column">
          {hasUserPermissionsToEdit && (
            <Button size="lg" className="add-button" disabled={!isContextReady} onClick={showModal}>
              Reassign Task
            </Button>
          )}
          <Button size="lg" className="add-button" onClick={toggleShouldFilterTasksByDueDate}>
            {shouldFilterTasksByDueDate ? 'All Tasks' : 'Due Two Weeks'}
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={shouldFilterTasksByDueDate ? tasksFilteredByDueDate : tasks}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingTasks}
            withSearchBar
          />
        </div>
      </div>
      <ReassignTasksModal {...modalProps} />
    </>
  );
};

const taskType = PropTypes.shape({
  taskStatus: PropTypes.string.isRequired,
  taxYear: PropTypes.number.isRequired,
  orgName: PropTypes.string.isRequired,
  legalEntityId: PropTypes.string.isRequired,
  jurisdictionDescription: PropTypes.string.isRequired,
  taskDescription: PropTypes.string.isRequired,
  taskDueDate: PropTypes.string,
  taskAssignedTo: PropTypes.string,
  taskManagedBy: PropTypes.string.isRequired,
});

TasksReview.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  fetchTasks: PropTypes.func.isRequired,
  isFetchingTasks: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(taskType).isRequired,
  tasksFilteredByDueDate: PropTypes.arrayOf(taskType).isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    isFetchingTasks: isFetchingTasksSelector(state),
    tasks: tasksSelector(state),
    tasksFilteredByDueDate: tasksFilteredByDueDateSelector(state),
  }),
  {
    fetchTasks,
  },
)(TasksReview);
