import React from 'react';
import PropTypes from 'prop-types';

import SubStep from './subStep.component';
import { stepDataPropTypes, contextDebugInfoPropTypes } from './currentReturnFiling.propTypes';
import { DEFAULT_ACTION_BUTTON_TEXT } from './constants';

const AllSubSteps = ({
  contextDebugInfo,
  subSteps,
  steps,
  isSubmittingAnyAction,
  isLoading,
  disableNextStep,
  hasPermissionToRun,
  actionButtonText = DEFAULT_ACTION_BUTTON_TEXT,
  isLocked = false,
}) => (
  <>
    {subSteps.map(({ CustomComponent, ...props }, i) => (
      <SubStep
        contextDebugInfo={contextDebugInfo}
        steps={steps}
        key={i}
        isSubmittingAnyAction={isSubmittingAnyAction}
        previousSubStep={subSteps[i - 1]}
        earlierSubStepsBeforePreviousSubStep={subSteps.slice(0, i - 1 > 0 ? i - 1 : 0)}
        isLoading={isLoading}
        disableNextStep={disableNextStep}
        hasPermissionToRun={hasPermissionToRun}
        actionButtonText={actionButtonText}
        isLocked={isLocked}
        {...props}
      >
        {CustomComponent && <CustomComponent />}
      </SubStep>
    ))}
  </>
);

const SubStepPropTypes = {
  actionsBatch: PropTypes.string.isRequired,
  callAction: PropTypes.func.isRequired,
  stepData: PropTypes.arrayOf(stepDataPropTypes).isRequired,
};

AllSubSteps.propTypes = {
  steps: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmittingAnyAction: PropTypes.bool.isRequired,
  subSteps: PropTypes.arrayOf(PropTypes.shape(SubStepPropTypes)).isRequired,
  disableNextStep: PropTypes.bool,
  contextDebugInfo: contextDebugInfoPropTypes,
  hasPermissionToRun: PropTypes.bool,
  isLocked: PropTypes.bool,
  actionButtonText: PropTypes.string,
};

export default AllSubSteps;
