import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { useSelector } from 'react-redux';

import {
  EFileServiceSubmissionId,
  FederalSubmissionId,
  GetValidationErrorsResponse,
  SltBinderId,
  TtiBinderId,
  TtiCaseId,
  TtiYearId,
  ValidationErrorsMessage,
  GlobalContext,
  FindTtiGroupValidationResponse,
} from '../../../../../../common/types';
import StepTab from '../stepTab/stepTab.component';
import useBooleanState from '../../../../shared/hooks/useBooleanState.hook';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { globalContextSelector } from '../../../../shared/store/selectors';
import {
  getValidationErrorsInitialData,
  useQueryGetReturnValidationResults,
} from '../../../../shared/queries/eFileV2';
import Loading from '../../../../shared/displayComponents/loading.component';
import { ValidationContext, WizardStateMachineContext } from '../../wizardStateMachine/types';
import StepTabRightHeader from '../stepTabRightHeader/stepTabRightHeader.component';
import { useGetTtiValidationErrors, useGetFederalReturn } from '../../hooks';
import LoadingPage from '../../loadingPage/loadingPage.component';
import { StepStatus, WizardStepName } from '../../enums';

import sortValidationErrorMessages from './sortValidationErrorMessages';
import DownloadsPanel from './downloadsPanel/downloadsPanel.component';
import { getColumnDefinitions } from './validationsStep.columnDefinitions';
import styles from './validationsStep.module.scss';
import ValidationWarning from './validationWarning/validationWarning.component';
import InformationDetails from './informationDetails/informationDetails.component';

interface ValidationsStepProps {
  viewDownloadsPanel: boolean;
  hideDownloadsPanel: () => void;
  isFederalFileAvailable: boolean;
  isValidationRunning: boolean;
  setValidationContext: (context: ValidationContext) => void;
  ttiYearId: TtiYearId | null;
  ttiCaseId: TtiCaseId | null;
  ttiBinderId: TtiBinderId | null;
  sltBinderId: SltBinderId | null;
  federalSubmissionId: FederalSubmissionId | null;
  eFileServiceSubmissionId: EFileServiceSubmissionId | null;
  isReturnRequired: boolean | null;
  validationStatus: StepStatus | null;
  ttiGroupValidationData?: FindTtiGroupValidationResponse | null;
  machineStateContext: WizardStateMachineContext;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
}

const ValidationsStep = ({
  viewDownloadsPanel,
  hideDownloadsPanel,
  isFederalFileAvailable,
  isValidationRunning,
  setValidationContext,
  ttiCaseId,
  ttiYearId,
  ttiBinderId,
  sltBinderId,
  eFileServiceSubmissionId,
  federalSubmissionId,
  isReturnRequired,
  validationStatus,
  ttiGroupValidationData,
  machineStateContext,
  isFetchingTaxReturnOrTransmitterProviderTR,
}: ValidationsStepProps) => {
  const [viewLearnMorePanel, showLearnMorePanel, hideLearnMorePanel] = useBooleanState(false);
  const [
    { errors, warnings, lastRun, validationRowData },
    setValidationErrors,
  ] = useState<GetValidationErrorsResponse>(getValidationErrorsInitialData);
  // TODO: Refactor object intialization
  const [currentRowDetails, setCurrentRowDetails] = useState<ValidationErrorsMessage>({
    type: '',
    validationType: '',
    description: '',
    location: '',
    status: '',
    formId: '',
    fieldName: '',
    element: '',
  });

  const { federalReturn, isFetchingFederalReturn } = useGetFederalReturn();

  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const hasValidationFailed = validationStatus === StepStatus.FAILED;

  const { ttiValidationErrors, isFetchingTtiValidation } = useGetTtiValidationErrors(
    !isValidationRunning && !hasValidationFailed && !isFetchingTaxReturnOrTransmitterProviderTR,
    !isFetchingTaxReturnOrTransmitterProviderTR,
  );

  const {
    data: validationErrors,
    isFetching: isFetchingValidationErrors,
  } = useQueryGetReturnValidationResults({
    params: { sltBinderId },
    enabled: !isValidationRunning && Boolean(sltBinderId) && !hasValidationFailed,
  });

  useEffect(() => {
    setValidationErrors({
      errors: (validationErrors?.errors || 0) + (ttiValidationErrors?.errors || 0),
      warnings: (validationErrors?.warnings || 0) + (ttiValidationErrors?.warnings || 0),
      lastRun: ttiValidationErrors?.lastRun || validationErrors?.lastRun || '',
      validationRowData: sortValidationErrorMessages([
        ...(validationErrors?.validationRowData || []),
        ...(ttiValidationErrors?.validationRowData || []),
      ]),
    });
  }, [validationErrors, ttiValidationErrors]);

  useEffect(() => {
    if (hasValidationFailed) {
      setValidationErrors(getValidationErrorsInitialData);
    }
  }, [hasValidationFailed]);

  const hasErrors = useMemo(() => Boolean(errors || warnings), [errors, warnings]);
  const lastRunDate = useMemo(() => (lastRun ? new Date(lastRun).toLocaleString() : ''), [lastRun]);

  useEffect(() => {
    setValidationContext({
      hasErrors,
      isFetching: isFetchingTtiValidation || isFetchingValidationErrors,
    });
  }, [hasErrors, isFetchingTtiValidation, isFetchingValidationErrors, setValidationContext]);

  const columnDefinitions = useMemo(() => {
    // right now we are just displaying the same description as on the grid - maybe changed/extended later
    const handleButtonClick = (errorDetails: ValidationErrorsMessage) => {
      setCurrentRowDetails(errorDetails);
      showLearnMorePanel();
    };

    return getColumnDefinitions(handleButtonClick, globalContext.params);
  }, [showLearnMorePanel, globalContext]);

  const onGridReady = useCallback(({ api }: GridReadyEvent) => {
    api.sizeColumnsToFit();
  }, []);

  const isFederalFileRequiredAndMissing = isReturnRequired && !isFederalFileAvailable;

  if (
    !isFetchingTaxReturnOrTransmitterProviderTR &&
    ttiGroupValidationData &&
    ttiGroupValidationData?.ttiGroupId &&
    !ttiGroupValidationData?.isTtiGroupValid
  ) {
    return (
      <LoadingPage
        header="Error"
        details={[
          `Group ${ttiGroupValidationData.ttiGroupId} was not found in the e-File transmitter.`,
          'Contact support for assistance using the steps in the Resource Center.',
        ]}
        imgPath="/assets/efile-config-error.png"
      />
    );
  }

  // Validation progress ongoing - display loader image
  if (isValidationRunning) {
    return (
      <LoadingPage
        header="Validating tax returns"
        details={[
          'Return to this screen to check the progress.',
          'Changes may affect this process.',
        ]}
      />
    );
  }

  // Validation not running - waiting for fetch to get data
  if (isFetchingValidationErrors || isFetchingTtiValidation || isFetchingFederalReturn) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }

  if (
    machineStateContext.eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.FAILED &&
    federalReturn?.isValidationReRunRequired
  ) {
    return (
      <LoadingPage
        header="Validation Re-Run Required"
        details={[
          'Data is out of sync with transmitter.',
          'Please run validations again with new data.',
        ]}
        imgPath="/assets/efile-config-error.png"
      />
    );
  }

  return (
    <div>
      {isFederalFileRequiredAndMissing && <ValidationWarning />}
      <StepTab
        title="Validations"
        subtitle={lastRunDate ? `Last run: ${lastRunDate}` : ''}
        wrapperClassName={styles.validationPanel}
        subtitleClassName={styles.validationLastRun}
        rightHeaderContent={
          <StepTabRightHeader
            shouldShowErrorsAndWarnings
            errors={errors}
            warnings={warnings}
            stepStatus={validationStatus}
            stepFailedMessage="Validation process failed"
            shouldShowNotStartedMessage
            stepNotStartedMessage="Validation process has not started yet"
          />
        }
      >
        {hasErrors && (
          <div className={styles.validationTable}>
            <AgGrid
              rowData={validationRowData}
              columnDefs={columnDefinitions}
              autoMaxWidth
              rowHeight={50}
              rowSelection="single"
              onGridReady={onGridReady}
              enableBrowserTooltips
            />
          </div>
        )}
      </StepTab>
      {viewLearnMorePanel && (
        <InformationDetails
          details={currentRowDetails}
          hidePanel={hideLearnMorePanel}
          isOpen={viewLearnMorePanel}
        />
      )}
      {viewDownloadsPanel && (
        <DownloadsPanel
          title="Downloads"
          hidePanel={hideDownloadsPanel}
          ttiCaseId={ttiCaseId}
          ttiYearId={ttiYearId}
          ttiBinderId={ttiBinderId}
          sltBinderId={sltBinderId}
          isReturnRequired={isReturnRequired}
          isFederalFileAvailable={isFederalFileAvailable}
          eFileServiceSubmissionId={eFileServiceSubmissionId}
          federalSubmissionId={federalSubmissionId}
          isOpen={viewDownloadsPanel}
          isFetchingTaxReturnOrTransmitterProviderTR={isFetchingTaxReturnOrTransmitterProviderTR}
          isSubmissionXmlAvailable={federalReturn.isXmlFileAvailable}
          isSubmissionZipAvailable={federalReturn.isZipFileAvailable}
          stateEFileXmlFileLocator={federalReturn.stateEFileXmlFileLocator}
          stateEFileZipFileLocator={federalReturn.stateEFileZipFileLocator}
        />
      )}
    </div>
  );
};

export default ValidationsStep;
