import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Modal from '@tls/ui-modal';
import { Button } from '@pwc/appkit-react/lib/Button';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useQueryEFileGroupSelection } from '../../shared/queries/eFile';
import { globalContextSelector } from '../../shared/store/selectors';
import toggleAllSelectionsHeaderFactory from '../../shared/displayComponents/toggleAllSelectionsHeaderFactory';
import { useRowEditMode } from '../../shared/editMode';

import getColumnDefinitions from './groupSelectionModal.columnDefinitions';
import styles from './styles.module.scss';

const getUniqueRowId = ({ data: { consolId } }) => consolId;

const GroupSelectionModal = ({ visible, hideModal, onCallAction }) => {
  const globalContext = useSelector(globalContextSelector);
  const [areAllRowsSelectedState, setAreAllRowsSelectedState] = useState(false);

  const {
    data: eFileGroupSelection,
    isFetching: isFetchingEFileGroupSelection,
  } = useQueryEFileGroupSelection({
    params: {
      taxYear: globalContext.params.taxYear,
      period: globalContext.params.period,
    },
    enabled: globalContext.isReady && visible,
  });

  const { updateRow, onGridReady, gridApi, clonedRowData } = useRowEditMode({
    rowData: eFileGroupSelection,
    getUniqueRowId,
    isPermanentlyInEditMode: true,
  });

  const selectedRows = clonedRowData.filter(({ isSelected }) => isSelected);
  const areAllSelected = clonedRowData.every(({ isSelected }) => isSelected);
  const disabled = selectedRows.length === 0;

  useEffect(() => setAreAllRowsSelectedState(areAllSelected), [areAllSelected]);

  const handleOverrideAllValues = useCallback(
    newValue => {
      gridApi.forEachNodeAfterFilter(({ data }) => {
        data.isSelected = newValue;
        updateRow(data);
      });
      gridApi.refreshCells({ force: true });
    },
    [gridApi, updateRow],
  );

  const columnDefinitions = useMemo(() => {
    const ToggleAllSelectionsHeader = toggleAllSelectionsHeaderFactory({
      togglerState: areAllRowsSelectedState,
      setTogglerState: setAreAllRowsSelectedState,
      isInEditMode: true,
      handleOverrideAllValues,
      marginLabel: false,
    });

    return getColumnDefinitions({ updateRow, ToggleAllSelectionsHeader });
  }, [updateRow, areAllRowsSelectedState, handleOverrideAllValues]);

  const onClickRun = useCallback(() => {
    onCallAction({ additionalParams: { consolIds: selectedRows.map(({ consolId }) => consolId) } });
    hideModal();
  }, [onCallAction, hideModal, selectedRows]);

  return (
    <Modal title="Group Selection Modal" visible={visible} closeAction={hideModal}>
      <div className={styles.groupSelectionModal}>
        <div className="row grid-row">
          <div className="col">
            <AgGrid
              rowData={clonedRowData}
              isGridLoading={isFetchingEFileGroupSelection}
              columnDefs={columnDefinitions}
              withSearchBar
              onGridReady={onGridReady}
            />
            <div className={styles.buttonContainer}>
              <Button size="lg" onClick={onClickRun} disabled={disabled}>
                Run
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

GroupSelectionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onCallAction: PropTypes.func.isRequired,
};

export default GroupSelectionModal;
