import { connect } from 'react-redux';

import ReturnWorkspaceRoutes from '../sharedSubPages/returnWorkspace/returnWorkspaceRoutes.component';
import {
  entityIdSelector,
  jurisdictionIdSelector,
  filingStatesProfileIdSelector,
} from '../shared/store/selectors';

import { isFetchingContextSelector } from './store/selectors';

export default connect(state => ({
  orgId: entityIdSelector(state),
  profileId: filingStatesProfileIdSelector(state),
  jurisdictionId: jurisdictionIdSelector(state),
  isFetchingContext: isFetchingContextSelector(state),
}))(ReturnWorkspaceRoutes);
