import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';

const ModalButtonsGroup = ({
  handleSubmit,
  handleCancel,
  submitButtonLabel,
  className = '',
  disabled = false,
}) => (
  <div className={classNames('buttons-group', 'w-100', className)}>
    <div className="btn-group pr-1 w-50">
      <Button size="lg" className="w-100" disabled={disabled} onClick={handleSubmit}>
        {submitButtonLabel}
      </Button>
    </div>
    <div className="btn-group pr-1 w-50">
      <Button size="lg" className="w-100" gray onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  </div>
);

ModalButtonsGroup.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ModalButtonsGroup;
