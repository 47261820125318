import React, { useMemo } from 'react';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportData from '../../shared/reports/useReportData.hook';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import styles from '../../shared/reports/reportParam.module.scss';
import getReportUrl from '../shared/getReportUrl';

import columnDefinitions from './legalEntityAuditTrail.columnDefinitions';

const LegalEntityAuditTrail = () => {
  const endpointBasePath = getReportUrl('legal-entity-audit-trail');

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const isFetchingAnyParam = checkIsFetchingAnyParam([taxYearParam, periodParam]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });
  const { props, onGridReady } = useActionMenuForExport({ reportData });

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LegalEntityAuditTrail;
