import React, { useCallback } from 'react';
import { Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { globalContextSelector } from '../../../shared/store/selectors';
import { useMutationUpdateSelectedSubstepStatus } from '../../../shared/mutations/efileV2';
import { QueryKeys } from '../../../shared/queries';
import { StepStatus } from '../../../eFile/currentReturnFilingV2/enums';
import { BinderFilingId, GlobalContext, SltBinderId } from '../../../../../common/types';

type FailSelectedSubstepButtonProps = {
  isDisabled: boolean;
  stepId: string;
  binderFilingId: BinderFilingId | null;
  setIsFailSelectedSubstepButtonDisabled: (isDisabled: boolean) => void;
  showFailSubstepButton: boolean;
  sltBinderId?: SltBinderId;
};

const FailSelectedSubstepButton = ({
  isDisabled,
  stepId,
  binderFilingId,
  setIsFailSelectedSubstepButtonDisabled,
  showFailSubstepButton,
  sltBinderId,
}: FailSelectedSubstepButtonProps) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const queryClient = useQueryClient();
  const { mutateAsync: updateSelectedSubstepStatus } = useMutationUpdateSelectedSubstepStatus();

  const handleFailSelectedSubstepButtonClick = useCallback(async () => {
    setIsFailSelectedSubstepButtonDisabled(true);

    await updateSelectedSubstepStatus({
      stepId,
      status: StepStatus.FAILED,
      returnId: sltBinderId,
    });

    queryClient.resetQueries([QueryKeys.EFileSupport.BinderSteps, { binderFilingId }]);

    if (sltBinderId) {
      queryClient.resetQueries([QueryKeys.EFileV2.GetSteps, { globalContext, sltBinderId }]);
    }
  }, [
    stepId,
    updateSelectedSubstepStatus,
    queryClient,
    binderFilingId,
    setIsFailSelectedSubstepButtonDisabled,
    globalContext,
    sltBinderId,
  ]);

  return showFailSubstepButton ? (
    <Button
      size="lg"
      disabled={isDisabled}
      onClick={handleFailSelectedSubstepButtonClick}
      kind="secondary"
    >
      FAIL SELECTED SUBSTEP
    </Button>
  ) : (
    <></>
  );
};

export default FailSelectedSubstepButton;
