const Yup = require('yup');

const { dataTypes } = require('../constants');
const { noSpecialChar } = require('../validationRegex');

const commonSchema = Yup.object().shape({
  taxYear: Yup.string().required(),
  period: Yup.string().required(),
  infoAccountId: Yup.string().label('Info account Id').required(),
  infoType: Yup.string()
    .label('Info account information type')
    .matches(noSpecialChar.regex, { message: noSpecialChar.message, excludeEmptyString: true })
    .nullable(),
  infoAccountDescription: Yup.string().label('Info account description').nullable(),
  activeYn: Yup.boolean().label('Active').required(),
  globalInd: Yup.boolean().label('Global Ind').required(),
  pshipGlobalInd: Yup.boolean().label('Pship Global Ind').required(),
  dataType: Yup.string().oneOf(Object.values(dataTypes)).label('Data type').required(),
});

module.exports = {
  commonSchema,
};
