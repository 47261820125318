import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

const WarningModal = ({
  hideModal,
  visible,
  warningMessage,
  warningFooterMessage,
  title,
  modalData = null,
  modalDataMessage = null,
  submitText = 'Ok',
  dismissText = 'Cancel',
  submitAction,
}) => {
  const submitActionWithHide = useCallback(() => {
    if (submitAction) {
      modalData && !Array.isArray(modalData) ? submitAction(modalData) : submitAction();
      hideModal();
    }
  }, [submitAction, hideModal, modalData]);

  return (
    <Modal
      title={title}
      submitText={submitText}
      dismissText={dismissText}
      visible={visible}
      dismissAction={hideModal}
      closeAction={hideModal}
      submitAction={submitAction ? submitActionWithHide : null}
      submitCallback={submitAction ? submitActionWithHide : null}
    >
      <p>{warningMessage}</p>
      {modalDataMessage && <p>{modalDataMessage}</p>}
      {Array.isArray(modalData) && modalData?.length && (
        <ul>
          {modalData.map(({ description }) => (
            <li key={description}>{description}</li>
          ))}
        </ul>
      )}
      {warningFooterMessage && <p>{warningFooterMessage}</p>}
    </Modal>
  );
};

WarningModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  warningMessage: PropTypes.string,
  warningFooterMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
  modalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  modalDataMessage: PropTypes.string,
  submitText: PropTypes.string,
  dismissText: PropTypes.string,
  submitAction: PropTypes.func,
};

export default WarningModal;
