import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { isValid } from 'date-fns';
import Calendar from '@pwc/appkit-react-datepicker/lib/react-calendar';
import { Input } from '@pwc/appkit-react/lib/Input';

import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';
import americanDateFormat from '../../americanDateFormat';

export const emptyMask = '__/__/____';
export const emptyMaskAndKeys = {
  maskedInputValue: emptyMask,
  keys: [],
};

export const makeMask = keys => keys.reduce((mask, char) => mask.replace('_', char), emptyMask);
export const convertDateToArrayOfCharacters = date => date.split('').filter(char => char !== '/');
export const isDigit = char => /^\d{1}$/.test(char);

const Icon = ({ size, onClick }) => (
  <div onClick={onClick} className="calendar-icon">
    <AppkitIcon icon="calendar" size={size} />
  </div>
);

Icon.propTypes = {
  size: PropTypes.number,
  onClick: PropTypes.func,
};

class DatePicker extends React.Component {
  static defaultProps = {
    name: '',
    size: 18,
    className: 'date-picker',
    calendarClassName: 'calendar',
    disabled: false,
    clearable: false,
    onClearSelection: () => null,
    onBlur: () => null,
    onFocus: () => null,
    onChange: () => null,
    formattedInputValue: '',
    inputClassName: '',
    isLarge: true,
    isEditable: true,
  };

  state = {
    isOpen: false,
    keys: [],
    maskedInputValue: emptyMask,
    calendarValue: null,
  };

  calendarRef = React.createRef();

  handleClickOutside = () => this.setState({ isOpen: false });

  onDayClick = date => {
    this.setState({ ...emptyMaskAndKeys, isOpen: false, calendarValue: date });

    this.props.onChange(americanDateFormat.toString(date));
  };

  onKeyPress = ({ key, target }) => {
    const { keys } = this.state;
    const { isEditable } = this.props;

    if (!isEditable) {
      return;
    }

    if (key === 'Enter') {
      this.parseAndChange();

      this.setState({ ...emptyMaskAndKeys, isOpen: false });
      target.blur();

      return;
    }

    if (keys.length === emptyMask.length - 2 || !isDigit(key)) {
      return;
    }

    const newKeys = [...keys, key];
    const maskedInputValue = makeMask(newKeys);

    if (!isValid(americanDateFormat.fromString(maskedInputValue))) {
      this.setState({ maskedInputValue, keys: newKeys });

      return;
    }

    const date = americanDateFormat.fromString(maskedInputValue);
    const calendarValue = isValid(date) ? date : new Date();

    this.calendarRef.current.setActiveStartDate(calendarValue);
    this.setState({ calendarValue, maskedInputValue, keys: newKeys });
  };

  onKeyDown = ({ key }) => {
    const { isEditable } = this.props;

    if (key !== 'Backspace' || !isEditable) {
      return;
    }

    if (this.state.keys.length === 1) {
      this.setState({ ...emptyMaskAndKeys, calendarValue: null });

      this.props.onClearSelection(this.props.name, null);
      return;
    }

    this.setState(prevState => {
      const keys = prevState.keys.slice(0, -1);
      const maskedInputValue = makeMask(keys);
      return {
        ...prevState,
        keys,
        maskedInputValue,
      };
    });
  };

  onBlur = e => {
    const { isOpen } = this.state;

    if (isOpen) {
      return;
    }

    this.parseAndChange();

    this.setState(emptyMaskAndKeys);
    this.props.onBlur(e);
  };

  onFocus = e => {
    const onSetState = () => {
      const { value } = this.props;

      if (value) {
        const keys = convertDateToArrayOfCharacters(value);
        const maskedInputValue = makeMask(keys);
        const dateFromString = americanDateFormat.fromString(value);
        const calendarValue = isValid(dateFromString) ? dateFromString : new Date();

        this.calendarRef.current.setActiveStartDate(calendarValue);

        this.setState({ keys, maskedInputValue, calendarValue });

        return;
      }

      this.calendarRef.current.setActiveStartDate(new Date());
    };

    this.setState({ isOpen: true }, onSetState);
    this.props.onFocus(e);
  };

  onClear = () => {
    this.setState({ ...emptyMaskAndKeys, isOpen: false, calendarValue: null });

    this.props.onClearSelection(this.props.name, null);
  };

  parseAndChange = () => {
    const { maskedInputValue } = this.state;

    this.props.onChange(maskedInputValue === emptyMask ? '' : maskedInputValue);
  };

  render() {
    const {
      name,
      value,
      className,
      clearable,
      disabled,
      size,
      formattedInputValue,
      inputClassName,
      isLarge,
      isEditable,
    } = this.props;
    const { isOpen, maskedInputValue, calendarValue } = this.state;

    const showClearIcon = Boolean(value && clearable);

    const mask = maskedInputValue === emptyMask ? '' : maskedInputValue.split('_')[0];
    const inputValue = isOpen ? mask : formattedInputValue || value || '';

    const placeholder = isOpen ? americanDateFormat.toString(new Date()) : '';

    return (
      <div className="appkit-datepicker-custom-wrapper">
        <Input
          data-testid="calendar-input"
          type="text"
          name={name}
          placeholder={placeholder}
          value={inputValue}
          onKeyDown={this.onKeyDown}
          onKeyPress={this.onKeyPress}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          disabled={disabled}
          className={inputClassName}
          readonly={!isEditable}
        />
        <Icon size={size} onClick={this.onFocus} />
        {showClearIcon && (
          <AppkitIcon
            data-testid="clear-icon"
            className="clear-icon"
            icon="close"
            type="fill"
            size={8}
            onClick={this.onClear}
          />
        )}
        {isOpen && (
          <div data-testid="calendar" className="calendar-wrapper">
            <Calendar
              isLarge={isLarge}
              className={className}
              ref={this.calendarRef}
              value={calendarValue}
              onChange={this.onDayClick}
              calendarType="US" /* it changes first day of the week to Sunday
              Remove or set to "ISO 8601" to have Monday as first day of the week */
            />
          </div>
        )}
      </div>
    );
  }
}

DatePicker.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
  calendarClassName: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  onClearSelection: PropTypes.func,
  formattedInputValue: PropTypes.string,
  inputClassName: PropTypes.string,
  isLarge: PropTypes.bool,
  isEditable: PropTypes.bool,
};

export default onClickOutside(DatePicker);
