import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AppkitIcon from '../../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { DataTypeNames } from '../../../enums';
import overridableIconStyles from '../overridableIcon.module.scss';

import styles from './hoverIcon.module.scss';

const HoverIcon = ({ isOverridden, dataType }) => {
  const icon = isOverridden ? 'radio' : 'downtrend';
  const type = isOverridden ? 'fill' : 'outline';

  return (
    <AppkitIcon
      className={classNames(overridableIconStyles.overridableIcon, {
        [styles.checkboxIcon]: dataType === DataTypeNames.Boolean,
        [styles.downtrendIcon]: !isOverridden,
      })}
      icon={icon}
      size={12}
      type={type}
    />
  );
};

HoverIcon.propTypes = {
  isOverridden: PropTypes.bool.isRequired,
  dataType: PropTypes.oneOf(Object.values(DataTypeNames)).isRequired,
};

export default HoverIcon;
