import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import VirtualizedSelectWithOptionsFetchFactory from '../../../shared/forms/virtualizedSelect/virtualizedSelectWithOptionsFetchFactory';
import { fetchDatasets } from '../store/api';

const CategoryDatasetEditor = forwardRef((props, ref) => {
  const { taxYear, jurisdictionId } = props;
  const VirtualizedSelectWithOptionsFetch = VirtualizedSelectWithOptionsFetchFactory({
    fetchMethod: () => fetchDatasets({ taxYear, jurisdictionId }),
  });

  return <VirtualizedSelectWithOptionsFetch {...props} forwardedRef={ref} />;
});

CategoryDatasetEditor.displayName = 'CategoryDatasetEditor';

CategoryDatasetEditor.propTypes = {
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,
};

export default CategoryDatasetEditor;
