import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { createDataProvider } from '../../shared/dataProviders';
import { globalContextSelector } from '../../shared/store/selectors';
import {
  fetchDataModelLinks,
  clearDataModelLinks,
  fetchFormsByPeriod,
  clearFormsByPeriod,
} from '../../shared/store/actions';
import {
  fetchNavigatorGenericScreens,
  clearNavigatorGenericScreens,
  fetchNavigatorEFileData,
} from '../store';
import globalContextPropTypes from '../../shared/propTypes/globalContext';

const NavigatorDataProvider = ({
  children,

  globalContext,

  fetchFormsByPeriod,
  clearFormsByPeriod,

  fetchDataModelLinks,
  clearDataModelLinks,

  fetchGenericScreens,
  clearGenericScreens,
  fetchNavigatorEFileData,
}) => {
  useEffect(() => {
    if (!globalContext.isReady) {
      clearFormsByPeriod();
      clearDataModelLinks();
      clearGenericScreens();
    }
  }, [globalContext.isReady, clearFormsByPeriod, clearDataModelLinks, clearGenericScreens]);

  useEffect(() => {
    clearFormsByPeriod();
    clearDataModelLinks();
    clearGenericScreens();
  }, [
    globalContext.params.filingTypeId,
    clearFormsByPeriod,
    clearDataModelLinks,
    clearGenericScreens,
  ]);

  useEffect(() => {
    if (!globalContext.isReady) {
      return;
    }

    const { taxYear, period, businessEntityId: entityId, jurisdictionId } = globalContext.params;

    fetchDataModelLinks({ taxYear, jurisdictionId });
    fetchGenericScreens({ taxYear, period, entityId, jurisdictionId });
  }, [fetchDataModelLinks, fetchGenericScreens, globalContext.isReady, globalContext.params]);

  useEffect(() => {
    if (globalContext.isReady) {
      const {
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
      } = globalContext.params;

      fetchFormsByPeriod({
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
      });
      fetchNavigatorEFileData({
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
      });
    }
  }, [fetchFormsByPeriod, fetchNavigatorEFileData, globalContext.isReady, globalContext.params]);

  return children;
};

NavigatorDataProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

  globalContext: globalContextPropTypes,

  fetchFormsByPeriod: PropTypes.func.isRequired,
  clearFormsByPeriod: PropTypes.func.isRequired,

  fetchDataModelLinks: PropTypes.func.isRequired,
  clearDataModelLinks: PropTypes.func.isRequired,

  fetchGenericScreens: PropTypes.func.isRequired,
  clearGenericScreens: PropTypes.func.isRequired,
};

export default createDataProvider({
  ProviderComponent: connect(
    state => ({
      globalContext: globalContextSelector(state),
    }),
    {
      fetchFormsByPeriod,
      clearFormsByPeriod,
      fetchDataModelLinks,
      clearDataModelLinks,
      fetchGenericScreens: fetchNavigatorGenericScreens,
      clearGenericScreens: clearNavigatorGenericScreens,
      fetchNavigatorEFileData,
    },
  )(NavigatorDataProvider),
});
