import { ColDef, NewValueParams, ICellRendererParams } from 'ag-grid-community';

import {
  defaultColumnDefinitionWithFilter,
  defaultCheckboxColumnDefinition,
} from '../../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../../shared/columnDefinitions/cellRenderers';

type ColumnDefinitionParams = {
  ToggleAllSelectionsHeader: ColDef['headerComponent'];
  updateRow: (data: NewValueParams['data']) => void;
};

const getColumnDefinitions = ({
  ToggleAllSelectionsHeader,
  updateRow,
}: ColumnDefinitionParams): ColDef[] => {
  const onCellValueChanged = ({ data }: NewValueParams) => {
    updateRow(data);
  };
  return [
    {
      ...defaultCheckboxColumnDefinition,
      headerComponent: ToggleAllSelectionsHeader,
      headerName: '',
      field: 'hasSelectedCalcPreference',
      colId: 'hasSelectedCalcPreference',
      width: 30,
      cellStyle: {
        textAlign: 'center',
      },
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: ({ data }: ICellRendererParams) => ({
        disabled: data.hasSelectedCalcPreferenceStatic,
      }),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
      width: 300,
    },
  ];
};

export default getColumnDefinitions;
