import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { Checkbox, Tooltip } from '@pwc/appkit-react';

import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import { components } from '../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import { useRowEditMode } from '../shared/editMode';
import globalContextPropTypes from '../shared/propTypes/globalContext';

import { fetchCustomScreen, updateCustomScreen } from './store/actions';
import {
  customScreenSelector,
  customScreenColumnsBlueprintSelector,
  isFetchingCustomScreenSelector,
  isUpdatingCustomScreenSelector,
} from './store/selectors';
import getColumnDefinitions from './customScreen.columnDefinitions';
import styles from './customScreen.module.scss';
import useSuppressZeros from './useSuppressZeros.hook';

const getUniqueRowId = ({ data: { rowId } }) => rowId;
const isMaineApportionedScreen = ({ customScreenName, jurisdictionId }) =>
  customScreenName === 'slt-2089' && jurisdictionId === '2300000000';

const CustomScreen = ({
  globalContext,
  customScreenName,

  hasUserPermissionsToEdit,

  customScreen,
  customScreenColumnsBlueprint,
  fetchCustomScreen,
  updateCustomScreen,
  isFetchingCustomScreen,
  isUpdatingCustomScreen,
}) => {
  const { taxYear, period, filingTypeId, businessEntityId, jurisdictionId } = globalContext.params;
  const [isSuppressZeros, setIsSuppressZeros] = useState(false);
  const [gridWidth, setGridWidth] = useState(0);
  const gridRef = useRef(null);

  const fetchTaxSummaryData = useCallback(() => {
    fetchCustomScreen({ taxYear, period, businessEntityId, jurisdictionId, customScreenName });
  }, [fetchCustomScreen, taxYear, period, businessEntityId, jurisdictionId, customScreenName]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setGridWidth(entry.contentRect.width);
      }
    });
    resizeObserver.observe(gridRef.current);
  }, []);

  useEffect(() => fetchTaxSummaryData(), [fetchTaxSummaryData]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const payload = rowsPairsWithChanges.map(({ newRow }) => {
        const nonEditableColumns = Object.entries(newRow.meta)
          .filter(([, { editable }]) => !editable)
          .map(([key]) => key);
        return {
          ...omit(newRow, ['rowId', ...nonEditableColumns]),
          meta: omit(newRow.meta, nonEditableColumns),
        };
      });

      await updateCustomScreen({
        taxYear,
        period,
        jurisdictionId,
        businessEntityId,
        payload,
      });
      fetchTaxSummaryData();
    },
    [updateCustomScreen, fetchTaxSummaryData, taxYear, period, businessEntityId, jurisdictionId],
  );

  const isLoading = isFetchingCustomScreen || isUpdatingCustomScreen;

  const {
    navigationPrompt,
    gridApi,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: customScreen,
    getUniqueRowId,
    editButtonDisabled:
      isLoading || !customScreen || isMaineApportionedScreen({ customScreenName, jurisdictionId }),
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        gridApi,
        updateRow,
        isInEditMode,
        columnsBlueprint: customScreenColumnsBlueprint,
        context: {
          taxYear,
          period,
          filingTypeId,
          businessEntityId,
          jurisdictionId,
        },
      }),
    [
      gridApi,
      updateRow,
      isInEditMode,
      customScreenColumnsBlueprint,
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
    ],
  );

  const { suppressedData } = useSuppressZeros(clonedRowData);

  const toggleSuppressZeros = useCallback(() => {
    setIsSuppressZeros(!isSuppressZeros);
  }, [isSuppressZeros]);

  return (
    <>
      {navigationPrompt}
      <div className="grid-borderless row grid-row">
        <div className={`col ${styles.taxSummaryGrid}`}>
          <div
            className={styles.actionsWrapper}
            style={{
              width: gridWidth,
              visibility: isLoading ? 'hidden' : 'visible',
            }}
          >
            <Tooltip content="Hides account rows that contain all zero values" placement="right">
              <Checkbox
                className={`${styles.suppressZerosCheckbox} a-btn a-btn-lg`}
                checked={isSuppressZeros}
                onChange={toggleSuppressZeros}
              >
                Suppress Zeros
              </Checkbox>
            </Tooltip>
            {hasUserPermissionsToEdit && <div>{editModeButtons}</div>}
          </div>
          <AgGrid
            innerRef={gridRef}
            rowData={isSuppressZeros ? suppressedData : clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            singleClickEdit
            onGridReady={onGridReady}
            components={components}
            stopEditingWhenCellsLoseFocus
            suppressCellFocus={!isInEditMode}
          />
        </div>
      </div>
    </>
  );
};

CustomScreen.propTypes = {
  globalContext: globalContextPropTypes,
  customScreenName: PropTypes.string,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,

  customScreen: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  customScreenColumnsBlueprint: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      width: PropTypes.string,
    }),
  ).isRequired,
  fetchCustomScreen: PropTypes.func.isRequired,
  isFetchingCustomScreen: PropTypes.bool.isRequired,
  updateCustomScreen: PropTypes.func.isRequired,
  isUpdatingCustomScreen: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    customScreen: customScreenSelector(state),
    customScreenColumnsBlueprint: customScreenColumnsBlueprintSelector(state),
    isFetchingCustomScreen: isFetchingCustomScreenSelector(state),
    isUpdatingCustomScreen: isUpdatingCustomScreenSelector(state),
  }),
  {
    fetchCustomScreen,
    updateCustomScreen,
  },
)(CustomScreen);
