import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const getColumnDefinitions = columnHeaders => {
  const columnHeadersObj = {};
  columnHeaders.map(header => (columnHeadersObj[header.lookupCode] = header.lookupDescription));

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Entity',
      field: 'orgId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Name',
      field: 'orgDescription',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Period',
      field: 'shortYear',
      maxWidth: 50,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Return Task',
      field: 'taskyn',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: columnHeadersObj.ES_TAX_DUE,
      field: 'incometaxdue',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: columnHeadersObj.TAX_PAYMENTS,
      field: 'incometaxpayment',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: columnHeadersObj.ES_TAX_PAID,
      field: 'inctaxpaymentremitted',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: columnHeadersObj.ES_FTAX_TAX_DUE,
      field: 'franchisetaxdue',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: columnHeadersObj.FTAX_PAYMENTS,
      field: 'frachisetaxpayments',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: columnHeadersObj.ES_FTAX_TAX_PAID,
      field: 'frntaxpaymentsremitted',
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
