import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';
import { dateFormatter } from '../../../shared/formatters';

const DateTime = ({ ISOString }) => (
  <span>{dateFormatter(ISOString, config.AMERICAN_DATE_TIME_FORMAT)}</span>
);

DateTime.propTypes = {
  ISOString: PropTypes.string.isRequired,
};

export default DateTime;
