import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import {
  fetchProfilesOptionsTypes,
  fetchEntityFilingDecisionsTypes,
  updateEntityFilingDecisionsTypes,
  fetchConsolidationFilingGroupsOptionsTypes,
  fetchConsolidationFilingDecisionsTypes,
  updateConsolidationFilingDecisionsTypes,
  fetchFilingDecisionsAmountDetailsTypes,
  CLEAR_NEXUS_NOTE,
  fetchNexusNoteTypes,
  createNexusNoteTypes,
  updateNexusNoteTypes,
} from './types';

export const fetchProfilesOptions = apiAction({
  types: fetchProfilesOptionsTypes,
  apiAction: api.fetchProfilesOptions,
});

export const fetchEntityFilingDecisions = apiAction({
  types: fetchEntityFilingDecisionsTypes,
  apiAction: api.fetchEntityFilingDecisions,
});

export const updateEntityFilingDecisions = apiAction(
  {
    types: updateEntityFilingDecisionsTypes,
    apiAction: api.updateEntityFilingDecisions,
  },
  {
    successNotificationMessage: () => 'Filing decisions has been successfully updated',
  },
);

export const fetchConsolidationFilingGroupsOptions = apiAction({
  types: fetchConsolidationFilingGroupsOptionsTypes,
  apiAction: api.fetchConsolidationsFilingGroups,
});

export const fetchConsolidationFilingDecisions = apiAction({
  types: fetchConsolidationFilingDecisionsTypes,
  apiAction: api.fetchConsolidationFilingDecisions,
});

export const updateConsolidationFilingDecisions = apiAction({
  types: updateConsolidationFilingDecisionsTypes,
  apiAction: api.updateConsolidationFilingDecisions,
});

export const fetchFilingDecisionsAmountDetails = apiAction({
  types: fetchFilingDecisionsAmountDetailsTypes,
  apiAction: api.fetchFilingDecisionsAmountDetails,
});

export const clearNexusNote = () => ({
  type: CLEAR_NEXUS_NOTE,
});

export const createNexusNote = apiAction(
  {
    types: createNexusNoteTypes,
    apiAction: api.createNexusNote,
  },
  {
    successNotificationMessage: () => 'Nexus note has been successfully created',
  },
);

export const fetchNexusNote = apiAction({
  types: fetchNexusNoteTypes,
  apiAction: api.fetchNexusNote,
});

export const updateNexusNote = apiAction(
  {
    types: updateNexusNoteTypes,
    apiAction: api.updateNexusNote,
  },
  {
    successNotificationMessage: () => 'Note has been successfully updated',
  },
);
