import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import {
  fetchBinderItemsTypes,
  updateBinderItemsTypes,
  updateBinderItemsOrderTypes,
  fetchBinderFormsTypes,
  fetchBinderAttachmentsTypes,
  deleteBinderItemsTypes,
  copyBinderTypes,
  fetchBinderPdfLastGenerationDateTypes,
  startProcessToGenerateBinderPdfTypes,
  downloadBinderPdfTypes,
  RESET_COPIED_BINDER_TYPE,
} from './types';

export const fetchBinderItems = apiAction({
  types: fetchBinderItemsTypes,
  apiAction: api.fetchBinderItems,
});

export const updateBinderItemsOrder = apiAction({
  types: updateBinderItemsOrderTypes,
  apiAction: api.updateBinderItemsOrder,
});

export const updateBinderItems = apiAction({
  types: updateBinderItemsTypes,
  apiAction: api.updateBinderItems,
});

export const fetchBinderForms = apiAction({
  types: fetchBinderFormsTypes,
  apiAction: api.fetchBinderForms,
});

export const fetchBinderAttachments = apiAction({
  types: fetchBinderAttachmentsTypes,
  apiAction: api.fetchBinderAttachments,
});

export const deleteBinderItems = apiAction({
  types: deleteBinderItemsTypes,
  apiAction: api.deleteBinderItems,
});

export const copyBinder = apiAction({
  types: copyBinderTypes,
  apiAction: api.copyBinder,
});

export const fetchBinderPdfLastGenerationDate = apiAction({
  types: fetchBinderPdfLastGenerationDateTypes,
  apiAction: api.fetchBinderPdfLastGenerationDate,
});

export const generateBinderPdf = apiAction({
  types: startProcessToGenerateBinderPdfTypes,
  apiAction: api.generateBinderPdf,
});

export const downloadBinderPdf = apiAction({
  types: downloadBinderPdfTypes,
  apiAction: api.downloadBinderPdf,
});

export const resetCopiedBinder = () => ({
  type: RESET_COPIED_BINDER_TYPE,
});
