import { TextCellRendererFactory } from '.';

interface SelectOptionProps {
  label?: string;
  value?: string | number | null;
}

const selectOptionCellRendererFactory = (
  options: Array<SelectOptionProps>,
  formatter?: (value?: string) => string,
) =>
  TextCellRendererFactory({
    getText: ({ value }) => {
      const text = (
        options.find((option: SelectOptionProps) => String(option.value) === String(value)) || {
          label: '',
        }
      ).label;
      return formatter ? formatter(text) : text;
    },
  });

export default selectOptionCellRendererFactory;
