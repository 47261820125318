import React from 'react';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';
import { DRILL_TYPE } from '@common-packages/drilling';

import { createDrillRoute } from '../../../shared/drilling';
import { defaultColumnDefinition } from '../../../shared/columnDefinitions';
import {
  NumberCellEditor,
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../../shared/columnDefinitions/cellRenderers';
import { amountFormatter } from '../../../shared/formatters';

const accountCellClassRules = {
  'bold-cell': ({ data: { rowInfo } }) => rowInfo.isBold,
};

const amountCellClassRules = {
  ...accountCellClassRules,
  'border-cell': ({ data: { rowInfo } }) => rowInfo.isBorder,
  'underlined-cell': ({ data: { rowInfo } }) => rowInfo.isUnderlined,
  'double-underlined-cell': ({ data: { rowInfo } }) => rowInfo.isDoubleUnderlined,
};

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  sortable: false,
  cellStyle: {
    textAlign: 'right',
  },
  cellClassRules: {
    ...defaultColumnDefinition.cellClassRules,
    ...amountCellClassRules,
  },
};

const colSpanDefinition = ({ data: { rowInfo } }) => (rowInfo.isDataRow ? 1 : 4);

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const AccountCellRenderer = TextCellRendererFactory({
  getText: ({ data: { rowInfo, accountText } }) => (rowInfo.isBlankRow ? '' : accountText),
});

const getColumnDefinitions = ({ isInEditMode, updateRow, context }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const DataCollectionRenderer = props => {
    if (props.data.rowInfo.isWorkpaperInstanceDrilldown) {
      const WorkpaperInstanceLinkRenderer = LinkCellRendererFactory({
        getText: ({ valueFormatted }) => valueFormatted,
        getPathName: (value, { rowInfo }) =>
          Routes.workpaperInstance.compiledRoute({
            ...rowInfo.workpaperInstanceDrilldownData,
            ...context,
          }),
      });
      return <WorkpaperInstanceLinkRenderer {...props} />;
    }

    if (props.data.rowInfo.isDrilldown) {
      const GenericDrillLinkRenderer = LinkCellRendererFactory({
        getText: ({ valueFormatted }) => valueFormatted,
        getPathName: (value, data) =>
          createDrillRoute(
            {
              drillType: DRILL_TYPE.GENERIC_DRILL,
              categoryId: data.categoryId,
              accountId: data.accountId,
            },
            context,
          ),
      });
      return <GenericDrillLinkRenderer {...props} />;
    }

    return <TextRenderer {...props} />;
  };
  DataCollectionRenderer.propTypes = {
    data: PropTypes.shape({
      dataCollection: PropTypes.number,
      rowInfo: PropTypes.shape({
        isDrilldown: PropTypes.bool,
        isWorkpaperInstanceDrilldown: PropTypes.bool,
      }).isRequired,
    }),
  };

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'accountText',
      width: 300,
      maxWidth: 350,
      sortable: false,
      colSpan: colSpanDefinition,
      cellClassRules: accountCellClassRules,
      cellRenderer: AccountCellRenderer,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Data Collection',
      field: 'dataCollection',
      cellRenderer: DataCollectionRenderer,
      valueFormatter: ({ value, data }) =>
        data.hasGenericFunction && !data.rowInfo.isWorkpaperInstanceDrilldown
          ? ''
          : amountFormatter({ value, props: data }),
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Adjustment',
      field: 'adjustment',
      cellRenderer: TextRenderer,
      editable: ({ data }) => isInEditMode && data.rowInfo.isAdjustmentEditable,
      cellEditor: NumberCellEditor,
      onCellValueChanged,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
      valueFormatter: ({ value, data }) =>
        data.hasGenericFunction || !data.showAdj ? '' : amountFormatter({ value, props: data }),
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Total',
      field: 'total',
      valueFormatter: ({ value, data }) => amountFormatter({ value, props: data }),
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Account Id',
      field: 'accountId',
      cellStyle: {
        textAlign: 'left',
      },
      width: 250,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
