import { useMemo, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';

import {
  TaxReturnDefinitionWithFilingAttributes,
  FindTaxReturnsColumnsBlueprint,
  FindConsolidationsDetailsData,
} from '../../../../../../common/types/apiShapes';
import { ConsolidationFormValues } from '../../utils/useFormInitialValues';
import useSetFilingAttribute from '../../addEditReturnPanel/hooks/useSetFilingAttribute.hook';
import useSetTaxType from '../../addEditReturnPanel/hooks/useSetTaxType.hook';
import useSetFilingMethod from '../../../hooks/useSetFilingMethod.hook';
import { DisplayedAttributes } from '../../../enums';
import { getReturnDefinitionFilingAttributes } from '../../utils/getReturnDefinitionFilingAttributes';

interface UseAddEditReturnDefinitionSettingParams {
  isEditing: boolean;
  taxReturns: FindConsolidationsDetailsData[];
  taxReturnsDefinitions: TaxReturnDefinitionWithFilingAttributes[];
  columnsBlueprint: FindTaxReturnsColumnsBlueprint;
}

export const useAddEditReturnDefinitionSetting = ({
  isEditing,
  taxReturns,
  taxReturnsDefinitions,
  columnsBlueprint,
}: UseAddEditReturnDefinitionSettingParams) => {
  const { values, initialValues } = useFormikContext<ConsolidationFormValues>();
  const [isReturnDefinitionIdDirty, setIsReturnDefinitionIdDirty] = useState(false);

  const taxReturnsDefinition = useMemo(
    () => taxReturnsDefinitions?.find(({ id }) => id === values.returnDefinitionId),
    [taxReturnsDefinitions, values.returnDefinitionId],
  );

  const existingTaxReturnsForJurisdiction = useMemo(
    () => taxReturns.filter(({ jurisdictionId }) => jurisdictionId === values.jurisdictionId),
    [values.jurisdictionId, taxReturns],
  );

  const returnDefinitionFilingAttributes = useMemo(
    () => getReturnDefinitionFilingAttributes({ taxReturnsDefinition, columnsBlueprint }),
    [taxReturnsDefinition, columnsBlueprint],
  );

  const {
    isFilingMethodDisabled,
    filingMethodInfoMessage,
    filingMethodOptions,
    setDefaultFilingMethod,
  } = useSetFilingMethod({
    taxReturnsDefinition,
    isEditing,
    isNonFilingEditable: true,
  });

  const {
    taxReturnDefinitionEnabledFilingAttributeOptions: filingTypeOptions,
    isFilingAttributeDisabled: isFilingTypeSelectDisabled,
    setDefaultFilingAttribute: setDefaultFilingType,
  } = useSetFilingAttribute({
    isEditing,
    existingTaxReturnsForJurisdiction,
    returnDefinitionFilingAttributes,
    filingAttribute: DisplayedAttributes.FILING_TYPE,
  });

  const {
    taxReturnDefinitionEnabledFilingAttributeOptions: returnTypeOptions,
    isFilingAttributeDisabled: isReturnTypeSelectDisabled,
    setDefaultFilingAttribute: setDefaultReturnType,
  } = useSetFilingAttribute({
    isEditing,
    existingTaxReturnsForJurisdiction,
    returnDefinitionFilingAttributes,
    filingAttribute: DisplayedAttributes.RETURN_TYPE,
  });

  const {
    taxReturnDefinitionEnabledFilingAttributeOptions: businessTypeOptions,
    isFilingAttributeDisabled: isBusinessTypeSelectDisabled,
    setDefaultFilingAttribute: setDefaultBusinessType,
  } = useSetFilingAttribute({
    isEditing,
    existingTaxReturnsForJurisdiction,
    returnDefinitionFilingAttributes,
    filingAttribute: DisplayedAttributes.BUSINESS_TYPE,
  });

  const { areTaxTypeCheckboxesDisabled, setDefaultTaxType } = useSetTaxType({
    isEditing,
    taxReturnsDefinition,
    existingTaxReturnsForJurisdiction,
    returnDefinitionFilingAttributes,
  });

  useEffect(() => {
    if (values.returnDefinitionId !== initialValues.returnDefinitionId) {
      setIsReturnDefinitionIdDirty(true);
    }
  }, [values.returnDefinitionId, initialValues.returnDefinitionId]);

  useEffect(() => {
    if (isReturnDefinitionIdDirty && taxReturnsDefinition) {
      setDefaultFilingMethod();
      setDefaultFilingType();
      setDefaultBusinessType();
      setDefaultReturnType();
      setDefaultTaxType();
    }
  }, [
    setDefaultFilingMethod,
    setDefaultBusinessType,
    setDefaultFilingType,
    setDefaultReturnType,
    setDefaultTaxType,
    isReturnDefinitionIdDirty,
    taxReturnsDefinition,
  ]);

  return {
    taxReturnsDefinition,
    returnDefinitionFilingAttributes,
    // setting for page 1
    isFilingMethodDisabled,
    filingMethodInfoMessage,
    filingMethodOptions,

    // setting for page 3
    filingTypeOptions,
    isFilingTypeSelectDisabled,
    returnTypeOptions,
    isReturnTypeSelectDisabled,
    businessTypeOptions,
    isBusinessTypeSelectDisabled,
    areTaxTypeCheckboxesDisabled,
  };
};
