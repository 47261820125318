import React from 'react';
import { connect } from 'react-redux';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';
import { isFetchingContextSelector } from '../store/selectors';

import EntityBreadcrumbRoutes from './entityBreadcrumbRoutes.component';

const EntityPage = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
  breadcrumbRoutes: EntityBreadcrumbRoutes,
}))(GlobalContextPage);

const AuthorizedEntityRoute = props => (
  <AuthorizedCustomRoute wrapper={EntityPage} hideGlobalContext {...props} />
);

export { AuthorizedEntityRoute };
