import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../shared/store/storeHelpers';
import { PropagateDataModelEverywhereJurisdiction } from '../../shared/constants';

export const taxYearOptionsSelector = createSelector(
  [({ development }) => development.shared.taxYearsData.taxYears],
  taxYears =>
    taxYears.map(({ name, description }) => ({
      label: description,
      value: name.toString(), // parsing toString because select component does not handle 0 well
    })),
);

export const taxYearSelector = ({ development }) => development.shared.taxYear;
export const isFetchingTaxYearsDataSelector = ({ development }) =>
  development.shared.isFetchingTaxYearsData;

export const defaultTaxYearSelector = ({ shared }) => shared.root.taxYearsData.defaultTaxYear;

export const periodOptionsSelector = createSelector(
  [({ development }) => development.shared.periods],
  periods =>
    periods.map(({ name, description }) => ({
      label: description,
      value: name.toString(), // parsing toString because select component does not handle 0 well
    })),
);

export const periodSelector = ({ development }) =>
  Number.isInteger(development.shared.period) ? development.shared.period.toString() : null; // parsing toString because select component does not handle 0 well

export const isFetchingPeriodsSelector = ({ development }) => development.shared.isFetchingPeriods;

export const isFetchingConsolidationsSelector = ({ development }) =>
  development.shared.isFetchingConsolidations;
export const consolidationsSelector = createSelector(
  [
    ({ development }) => development.shared.consolidations,
    ({ development }) => development.shared.jurisdictionId,
  ],
  (consolidations, selectedJurisdictionId) =>
    consolidations.filter(({ jurisdictionId }) => jurisdictionId === selectedJurisdictionId),
);

export const consolidationsOptionsSelector = createSelector(
  [consolidationsSelector],
  consolidations =>
    consolidations.map(({ consolidationId, description }) => ({
      label: `${consolidationId}${description ? ` - ${description}` : ''}`,
      value: consolidationId,
    })),
);

export const jurisdictionIdSelector = ({ development }) => development.shared.jurisdictionId;
export const jurisdictionsSelector = ({ development }) => development.shared.jurisdictions;
export const jurisdictionsOptionsSelector = optionsSelectorFactory({
  listSelector: jurisdictionsSelector,
  labelKey: 'jurisdictionDescription',
  valueKey: 'jurisdictionId',
});

export const jurisdictionStateCodeSelector = createSelector(
  [jurisdictionsSelector, jurisdictionIdSelector],
  (jurisdictions, id) => {
    const jurisdiction = jurisdictions.find(({ jurisdictionId }) => jurisdictionId === id);
    return jurisdiction ? jurisdiction.stateCode : '';
  },
);

export const jurisdictionDescriptionSelector = createSelector(
  [jurisdictionsSelector, jurisdictionIdSelector],
  (jurisdictions, id) => {
    const jurisdiction = jurisdictions.find(({ jurisdictionId }) => jurisdictionId === id);
    return jurisdiction ? jurisdiction.jurisdictionDescription : '';
  },
);

export const propagateDataModelEverywhereJurisdictionSelector = createSelector(
  [
    jurisdictionsSelector,
    jurisdictionIdSelector,
    (_, isJurisdictionDescription = false) => isJurisdictionDescription,
  ],
  (jurisdictions, id, isJurisdictionDescription) => {
    jurisdictions.push(PropagateDataModelEverywhereJurisdiction);
    if (isJurisdictionDescription) {
      const jurisdiction = jurisdictions.find(({ jurisdictionId }) => jurisdictionId === id);
      return jurisdiction ? jurisdiction.jurisdictionDescription : '';
    } else {
      return jurisdictions;
    }
  },
);

export const isFetchingJurisdictionsSelector = ({ development }) =>
  development.shared.isFetchingJurisdictions;

export const isFetchingContextSelector = createSelector(
  [isFetchingTaxYearsDataSelector, isFetchingJurisdictionsSelector],
  (isFetchingTaxYearsData, isFetchingJurisdictions) =>
    isFetchingTaxYearsData || isFetchingJurisdictions,
);

export const genericFunctionSelector = ({ development }) => development.shared.genericFunction;

export const previousDevelopmentPathnameSelector = ({ development }) =>
  development.shared.previousDevelopmentPathname;

export const isContextChangesBlockedSelector = ({ development }) =>
  development.shared.isContextChangesBlocked;

export const formsSelector = ({ development }) => development.shared.forms.data;
export const formsColumnsBlueprintSelector = ({ development }) =>
  development.shared.forms.columnsBlueprint;
export const isFetchingFormsSelector = ({ development }) => development.shared.isFetchingForms;
export const formsOptionsSelector = optionsSelectorFactory({
  listSelector: formsSelector,
  labelKey: 'formName',
  valueKey: 'formId',
});

export const formIdSelector = ({ development }) => development.shared.formId;
export const formPdfIdSelector = createSelector(
  [formsSelector, formIdSelector],
  (forms, selectedFormId) => forms.find(({ formId }) => formId === selectedFormId)?.pdfId,
);

export const formCanBeFoundInListSelector = createSelector(
  [formsSelector, formIdSelector],
  (forms, selectedFormId) => forms.some(form => form.formId === selectedFormId),
);

export const formHtmlSelector = ({ development }) => development.shared.formHtmlImage.formImage;
export const isFetchingFormHtmlSelector = ({ development }) =>
  development.shared.isFetchingFormHtmlImage;

export const formPagesSelector = ({ development }) => development.shared.formHtmlImage.pages;
export const formPagesOptionsSelector = createSelector([formPagesSelector], pages =>
  pages
    ? Array(pages)
        .fill()
        .map((_, index) => ({ value: index + 1, label: String(index + 1) }))
    : [],
);
export const formPageSelector = ({ development }) => development.shared.selectedPage.pageNumber;
export const isSelectedWithDropdownSelector = ({ development }) =>
  development.shared.selectedPage.isSelectedWithDropdown;
