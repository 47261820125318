import { SUBPATH_TO_NAMESPACE } from './constants';

export const findRouteNamespace = path =>
  SUBPATH_TO_NAMESPACE[Object.keys(SUBPATH_TO_NAMESPACE).find(subpath => path.includes(subpath))];

export const appendEmptyRowsAfterEachSection = (rowData, propKey) => {
  const emptyRow = {
    rowInfo: {
      isEmptyRow: true,
    },
    amount: '',
  };

  return (rowData || []).reduce(
    (acc, row) => (row.rowInfo[propKey] ? [...acc, row, emptyRow] : [...acc, row]),
    [],
  );
};

export const clearQueryString = ({ location, history, parameterName }) => {
  if (location.search && location.search.includes(parameterName)) {
    history.replace();
  }
};
