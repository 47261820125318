import { httpPut, httpGetAndParse } from '../../../utils/fetchService';

export const fetchPermissions = () =>
  httpGetAndParse({
    route: '/api/admin/maintenance/permissions',
    errorMessage: 'Fetching permissions failed',
  });

export const updatePermissions = ({ permissions }) =>
  httpPut({
    route: '/api/admin/maintenance/permissions',
    errorMessage: 'Updating permissions failed',
    body: { permissions },
  });
