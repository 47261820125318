import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';
import { Routes } from '@common-packages/routes-definitions';
import { useHistory } from 'react-router-dom';

import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../shared/hooks/useModal.hook';

import getColumnDefinitions from './filingGroupGrid.columnDefinitions';
import { filingGroupPropTypes } from './propTypes';
import AddEditFilingGroupModal from './addEditFilingGroupModal.container';

const FilingGroupGrid = ({
  isFetchingFilingGroups,
  filingGroups,
  selectFilingGroupId,

  hasUserPermissionsToEdit,
}) => {
  const { showModal, modalProps } = useModal();
  const [isRowSelected, setIsRowSelected] = useState(false);
  const history = useHistory();

  const [filingGroupToEdit, setFilingGroupToEdit] = useState(null);
  const openModalForEdit = useCallback(
    row => {
      setFilingGroupToEdit(row);
      showModal();
    },
    [showModal],
  );
  const openModalForAdd = useCallback(() => {
    setFilingGroupToEdit(null);
    showModal();
  }, [showModal]);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        selectFilingGroupId,
        onEditIconClick: openModalForEdit,
        shouldShowEditIcon: hasUserPermissionsToEdit,
      }),
    [hasUserPermissionsToEdit, openModalForEdit, selectFilingGroupId],
  );

  const selectFilingGroupToEditMembers = useCallback(
    ({ api }) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        selectFilingGroupId(selectedRow.filingGroupId);
      }
      setIsRowSelected(Boolean(selectedRow));
    },
    [selectFilingGroupId],
  );

  const redirectToEditMembers = useCallback(
    () =>
      history.push({
        pathname: Routes.federalProFormaFilingMembers.MAIN,
        state: { fromDashboard: true },
      }),
    [history],
  );

  return (
    <>
      {hasUserPermissionsToEdit ? (
        <div className="row">
          <div className="col add-button-column">
            <Button
              size="lg"
              className="add-button"
              onClick={redirectToEditMembers}
              disabled={isFetchingFilingGroups || !isRowSelected}
            >
              Edit Members
            </Button>
            <Button
              size="lg"
              className="add-button"
              onClick={openModalForAdd}
              disabled={isFetchingFilingGroups}
            >
              Add Filing Group
            </Button>
          </div>
        </div>
      ) : null}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            columnDefs={columnDefinitions}
            rowData={filingGroups}
            isGridLoading={isFetchingFilingGroups}
            withSearchBar
            onSelectionChanged={selectFilingGroupToEditMembers}
          />
        </div>
      </div>
      <AddEditFilingGroupModal
        {...modalProps}
        filingGroupToEdit={filingGroupToEdit}
        filingGroups={filingGroups}
      />
    </>
  );
};
FilingGroupGrid.propTypes = {
  isFetchingFilingGroups: PropTypes.bool.isRequired,
  filingGroups: PropTypes.arrayOf(filingGroupPropTypes).isRequired,
  selectFilingGroupId: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default FilingGroupGrid;
