import PropTypes from 'prop-types';

const adjustableWatermarkStylesPropTypes = PropTypes.shape({
  verticalOffset: PropTypes.number.isRequired,
  horizontalOffset: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
});

export const watermarkToEditPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  rotation: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  verticalOffset: PropTypes.string.isRequired,
  line1: PropTypes.string,
  line2: PropTypes.string,
  line3: PropTypes.string,
});

export const watermarkPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  fontSize: PropTypes.number.isRequired,
  line1: PropTypes.string,
  line2: PropTypes.string,
  line3: PropTypes.string,
  doFontOutline: PropTypes.bool,
  rgbGrayscale: PropTypes.number,
  ...adjustableWatermarkStylesPropTypes,
});

// Watermark data returned from print-service
export const pageWatermarkPropTypes = PropTypes.shape({
  createdEmail: PropTypes.string,
  fontSize: PropTypes.number.isRequired,
  grayScale: PropTypes.number.isRequired,
  horizontalOffset: PropTypes.number.isRequired,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string,
  line3: PropTypes.string,
  name: PropTypes.string.isRequired,
  outline: PropTypes.bool.isRequired,
  rotation: PropTypes.number.isRequired,
  updatedEmail: PropTypes.string,
  verticalOffset: PropTypes.number.isRequired,
  portrait: adjustableWatermarkStylesPropTypes,
  landscape: adjustableWatermarkStylesPropTypes,
});
