import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import getFieldValue from '../../utils/getFieldValue';

import {
  fetchTaxYearsTypes,
  fetchPeriodsTypes,
  fetchJurisdictionsTypes,
  fetchJurisdictionsForAttachmentsAndBindersTypes,
  fetchConsolidationsTypes,
  SELECT_TAX_YEAR,
  SELECT_PERIOD,
  SELECT_JURISDICTION_ID,
  SELECT_GENERIC_FUNCTION,
  SET_PREVIOUS_PATHNAME,
  BLOCK_DEVELOPMENT_CONTEXT_CHANGES,
  RESET_FORM_HTML_IMAGE,
  SELECT_FORM,
  SELECT_FORM_PAGE_WITH_DROPDOWN,
  SELECT_FORM_PAGE_WITH_SCROLL,
  fetchFormsTypes,
  fetchFormHtmlImageTypes,
} from './types';

export const initialState = {
  taxYearsData: { taxYears: [], defaultTaxYear: null },
  previousTaxYear: null,
  taxYear: null,
  isFetchingTaxYearsData: false,

  periods: [],
  period: null,
  previousPeriod: null,
  isFetchingPeriods: false,

  consolidations: [],
  jurisdictionId: null,
  previousJurisdictionId: null,
  jurisdictions: [],
  isFetchingJurisdictions: false,

  genericFunction: null,
  previousDevelopmentPathname: null,

  isContextChangesBlocked: false,

  formId: null,
  previousFormId: null,

  forms: {
    columnsBlueprint: [],
    data: [],
  },
  isFetchingForms: false,

  formHtmlImage: {
    formImage: null,
    pages: null,
  },
  isFetchingFormHtmlImage: false,

  selectedPage: {
    pdfId: null,
    pageNumber: 1,
    previousPageNumber: 1,
    isSelectedWithScroll: false,
    isSelectedWithDropdown: true,
  },
};

export default reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchTaxYearsTypes,
      payloadKey: 'taxYearsData',
      fetchingKey: 'isFetchingTaxYearsData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPeriodsTypes,
      payloadKey: 'periods',
      fetchingKey: 'isFetchingPeriods',
      getAdditionalState: (state, payload) => ({
        // Set initial period
        period:
          state.period ||
          getFieldValue({
            currentValueFieldName: 'period',
            payloadFieldName: 'name',
            state,
            payload,
          }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchConsolidationsTypes,
      payloadKey: 'consolidations',
      fetchingKey: 'isFetchingConsolidations',
      getAdditionalState: (state, payload) => ({
        consolidationId:
          state.consolidationId ||
          getFieldValue({
            currentValueFieldName: 'consolidationId',
            payloadFieldName: 'consolidationId',
            state,
            payload,
          }),
        jurisdictionId:
          state.jurisdictionId ||
          getFieldValue({
            currentValueFieldName: 'jurisdictionId',
            payloadFieldName: 'jurisdictionId',
            state,
            payload,
          }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchJurisdictionsTypes,
      payloadKey: 'jurisdictions',
      fetchingKey: 'isFetchingJurisdictions',
      getAdditionalState: (state, payload) => ({
        // Set initial jurisdictionId
        jurisdictionId:
          state.jurisdictionId ||
          getFieldValue({
            currentValueFieldName: 'jurisdictionId',
            payloadFieldName: 'jurisdictionId',
            state,
            payload,
          }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchJurisdictionsForAttachmentsAndBindersTypes,
      payloadKey: 'jurisdictions',
      fetchingKey: 'isFetchingJurisdictions',
      getAdditionalState: (state, payload) => ({
        // Set initial jurisdictionId
        jurisdictionId:
          state.jurisdictionId ||
          getFieldValue({
            currentValueFieldName: 'jurisdictionId',
            payloadFieldName: 'jurisdictionId',
            state,
            payload,
          }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFormsTypes,
      payloadKey: 'forms',
      fetchingKey: 'isFetchingForms',
      getAdditionalState: (state, { data }) => ({
        // Set initial formId
        formId:
          state.formId ||
          getFieldValue({
            currentValueFieldName: 'formId',
            payloadFieldName: 'formId',
            state,
            payload: data,
          }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFormHtmlImageTypes,
      payloadKey: 'formHtmlImage',
      fetchingKey: 'isFetchingFormHtmlImage',
      getAdditionalState: (state, _payload, _type, params) => {
        const shouldResetPageNumber = params?.[0]?.pdfId !== state.selectedPage.pdfId;
        const newPageNumber =
          shouldResetPageNumber || !state.selectedPage.previousPageNumber
            ? 1
            : state.selectedPage.previousPageNumber;
        return {
          selectedPage: {
            pdfId: params?.[0]?.pdfId || null,
            pageNumber: newPageNumber,
            previousPageNumber:
              newPageNumber === 1 ? newPageNumber : state.selectedPage.previousPageNumber,
            isSelectedWithScroll: false,
            isSelectedWithDropdown: true,
          },
        };
      },
    }),

    [SELECT_JURISDICTION_ID]: (state, payload) => ({
      ...state,
      jurisdictionId: payload,
      previousJurisdictionId: payload,

      formId: null,
      previousFormId: state.formId,
    }),

    [SELECT_TAX_YEAR]: (state, payload) => ({
      ...state,
      taxYear: payload,
      previousTaxYear: payload,

      period: null,
      previousPeriod: state.period,

      jurisdictionId: null,
      previousJurisdictionId: state.jurisdictionId,
      previousDevelopmentPathname: null,

      formId: null,
      previousFormId: state.formId,
    }),

    [SELECT_PERIOD]: (state, payload) => ({
      ...state,
      period: payload ? Number(payload) : null, // parsing back to Number, see in selector why that happens
      previousPeriod: payload ? Number(payload) : null,

      jurisdictionId: null,
      previousJurisdictionId: state.jurisdictionId,
    }),
    [SELECT_GENERIC_FUNCTION]: (state, payload) => ({
      ...state,
      genericFunction: payload,
    }),
    [SET_PREVIOUS_PATHNAME]: (state, payload) => ({
      ...state,
      previousDevelopmentPathname: payload,
    }),
    [BLOCK_DEVELOPMENT_CONTEXT_CHANGES]: (state, payload) => ({
      ...state,
      isContextChangesBlocked: payload,
    }),
    [RESET_FORM_HTML_IMAGE]: state => ({
      ...state,
      formHtmlImage: {
        formImage: null,
        pages: null,
      },
      selectedPage: {
        pdfId: null,
        pageNumber: 1,
        previousPageNumber: 1,
        isSelectedWithScroll: false,
        isSelectedWithDropdown: true,
      },
    }),

    [SELECT_FORM]: (state, payload) => ({
      ...state,
      formId: payload,
      previousFormId: payload,
    }),
    [SELECT_FORM_PAGE_WITH_SCROLL]: (state, payload) => ({
      ...state,
      selectedPage: {
        ...state.selectedPage,
        pageNumber: payload,
        previousPageNumber: payload,
        isSelectedWithScroll: true,
        isSelectedWithDropdown: false,
      },
    }),
    [SELECT_FORM_PAGE_WITH_DROPDOWN]: (state, payload) => ({
      ...state,
      selectedPage: {
        ...state.selectedPage,
        pageNumber: payload,
        previousPageNumber: payload,
        isSelectedWithScroll: false,
        isSelectedWithDropdown: true,
      },
    }),
  },
});
