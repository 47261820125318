import React from 'react';
import PropTypes from 'prop-types';

import AgGrid from '../agGrid/agGrid.component';

import styles from './resizableGrid.module.scss';

const ResizableGrid = ({ className = '', ...gridProps }) => (
  <div className={`${styles.resizableGrid} ${className}`}>
    <div className={`row grid-row ${styles.resizableGridMaxHeight}`}>
      <div className={`col ${styles.resizableGridMaxHeight}`}>
        <AgGrid {...gridProps} />
      </div>
    </div>
  </div>
);

ResizableGrid.propTypes = {
  className: PropTypes.string,
};

export default ResizableGrid;
