import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { fetchAccountDetails } from '../../../shared/store/accountDetails/actions';
import {
  accountDetailsSelector,
  isFetchingAccountDetailsSelector,
} from '../../../shared/store/accountDetails/selectors';
import { periodSelector, taxYearSelector } from '../../../shared/store/selectors';

import getColumnDefinitions from './accountDetails.columnDefinitions';
import AccountLineItemDetail from './accountLineItemDetail/accountLineItemDetail.container';

const AccountDetails = ({
  match,
  taxYear,
  orgId,
  period,
  jurisdictionId,

  accountDetails,
  fetchAccountDetails,
  isFetchingAccountDetails,
}) => {
  const { url, params, isExact } = match;
  const { categoryId, accountId } = params;

  useEffect(() => {
    const isContextReady = taxYear && period && orgId && jurisdictionId && categoryId;

    if (isContextReady) {
      fetchAccountDetails({ taxYear, period, orgId, jurisdictionId, categoryId, accountId });
    }
  }, [orgId, period, taxYear, categoryId, jurisdictionId, accountId, fetchAccountDetails]);

  const columnDefinitions = useMemo(() => getColumnDefinitions(url), [url]);

  const ConnectedAccountLineItemDetail = useCallback(
    props => <AccountLineItemDetail orgId={orgId} {...props} />,
    [orgId],
  );

  if (isExact) {
    return (
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={accountDetails}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingAccountDetails}
          />
        </div>
      </div>
    );
  }

  return (
    <Route exact path={`${url}/accountLineItemDetail`} render={ConnectedAccountLineItemDetail} />
  );
};

AccountDetails.propTypes = {
  taxYear: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      accountId: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
    isExact: PropTypes.bool.isRequired,
  }).isRequired,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  accountDetails: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ).isRequired,
  fetchAccountDetails: PropTypes.func.isRequired,
  isFetchingAccountDetails: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    accountDetails: accountDetailsSelector(state),
    isFetchingAccountDetails: isFetchingAccountDetailsSelector(state),
  }),
  {
    fetchAccountDetails,
  },
)(AccountDetails);
