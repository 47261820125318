import { httpGetAndParse, httpGetBlob } from '../../utils/fetchService';

export const fetchPdfImage = ({ taxYear, period, jurisdictionId, filingTypeId, orgId, sourceId }) =>
  httpGetBlob({
    route: `/api/federal-proforma/${taxYear}/${period}/${filingTypeId}/${orgId}/${jurisdictionId}/${sourceId}`,
    errorMessage: 'Fetching pdf file failed',
  });

export const checkLastRefreshProFormaReturn = ({ taxYear, period, businessEntityId }) =>
  httpGetAndParse({
    route: `/api/federal-proforma/last-refresh-date/${taxYear}/${period}/${businessEntityId}`,
    errorMessage: 'Checking last refresh Pro Forma Return failed',
  });
