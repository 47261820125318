import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { Input as ReadOnlyInput } from '@pwc/appkit-react';

import Input from '../shared/forms/inputFormik/inputFormik.component';

const AddEditFilingGroupForm = ({ values, filingGroupToEdit }) => {
  return (
    <form>
      <ReadOnlyInput className="modal-field" label="Tax Year" value={values.taxYear} disabled />
      <ReadOnlyInput className="modal-field" label="Period" value={values.period} disabled />
      <Field
        label="Filing Group Id"
        name="filingGroupId"
        className="modal-field"
        component={Input}
        autoComplete="off"
        disabled={Boolean(filingGroupToEdit)}
      />
      <Field
        label="Description"
        name="description"
        className="modal-field"
        component={Input}
        autoComplete="off"
      />
    </form>
  );
};

AddEditFilingGroupForm.propTypes = {
  values: PropTypes.shape({
    taxYear: PropTypes.string,
    period: PropTypes.string,
    filingGroupId: PropTypes.string,
    description: PropTypes.string,
  }),
  filingGroupToEdit: PropTypes.shape({
    filingGroupId: PropTypes.string.isRequired,
  }),
};

export default AddEditFilingGroupForm;
