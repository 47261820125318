import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { customReportsSchemas } from '@common-packages/validators';
import { DEFAULT_EXCEL_FILE_FORMAT } from '@common-packages/shared-constants';

import Loading from '../../shared/displayComponents/loading.component';
import SlideIn from '../../shared/displayComponents/slideIn/slideIn.component';
import useModal from '../../shared/hooks/useModal.hook';

import AddEditTrgReportForm from './AddEditTrgReportForm.component';
import AddEditCustomReportForm from './AddEditReportForm.component';
import TestQueryModal from './testQueryModal.component';
import { REPORT_TYPES, uiLabels } from './constants';

const alwaysNullFunc = () => null;

const getAddEditReportForm = reportType =>
  ({
    [REPORT_TYPES.TRG]: AddEditTrgReportForm,
    [REPORT_TYPES.REPORT]: AddEditCustomReportForm,
    [REPORT_TYPES.CHART]: AddEditCustomReportForm,
  }[reportType]);

const AddEditReportSlideIn = ({
  reportType,
  isOpen,
  closeSlideIn,
  addCustomReport,
  editCustomReport,
  reportIdToEdit,
  reportToEdit,
  isFetchingReportToEdit,
  fetchReportToEdit,
  showEditParamModal,
}) => {
  const [isBusy, setIsBusy] = useState(false);

  const formIntitialValues = {
    name: '',
    trgId: '',
    query: '',
    serverSide: false,
    defaultOrderBy: '',
    queryParams: [],
    reportType,
    reportFileFormat: DEFAULT_EXCEL_FILE_FORMAT,
    chart: {
      type: '',
      key: '',
      value: '',
    },
  };

  useEffect(() => {
    if (reportIdToEdit && isOpen) {
      fetchReportToEdit(reportIdToEdit);
    }
  }, [fetchReportToEdit, isOpen, reportIdToEdit]);

  const { showModal, modalProps } = useModal();

  const saveQuery = useCallback(
    async (
      { name, trgId, reportFileFormat, query, chart, serverSide, defaultOrderBy },
      queryParams,
    ) => {
      setIsBusy(true);

      if (!reportIdToEdit) {
        await addCustomReport({
          name,
          trgId,
          reportFileFormat,
          query,
          queryParams,
          reportType,
          chart,
          serverSide,
          defaultOrderBy,
        });
      } else {
        await editCustomReport({
          id: reportIdToEdit,
          name,
          reportFileFormat,
          trgId,
          query,
          queryParams,
          reportType,
          chart,
          serverSide,
          defaultOrderBy,
        });
      }

      setIsBusy(false);
      closeSlideIn();
    },
    [addCustomReport, closeSlideIn, editCustomReport, reportIdToEdit, reportType],
  );

  const renderForm = useCallback(
    formikProps => {
      const AddEditReportForm = getAddEditReportForm(reportType);

      if (!AddEditReportForm) {
        return null;
      }

      return (
        <AddEditReportForm
          {...formikProps}
          closeSlideIn={closeSlideIn}
          isEditMode={Boolean(reportIdToEdit)}
          showModal={showModal}
          saveQuery={saveQuery}
          isBusy={isBusy}
          showEditParamModal={showEditParamModal}
          initialQueryParams={reportIdToEdit ? reportToEdit.queryParams : []}
        />
      );
    },
    [
      showModal,
      saveQuery,
      closeSlideIn,
      showEditParamModal,
      reportType,
      isBusy,
      reportToEdit,
      reportIdToEdit,
    ],
  );

  const getSlideInLabels = () => uiLabels[reportType.toLowerCase()].slideIn;

  const slideInTitle = reportIdToEdit ? getSlideInLabels().editTitle : getSlideInLabels().addTitle;

  return (
    <SlideIn
      title={slideInTitle}
      isOpen={isOpen}
      width="400px"
      onRequestClose={closeSlideIn}
      ariaHideApp={false}
      className="custom-reports-slidein"
    >
      <Loading isLoading={Boolean(reportIdToEdit && isFetchingReportToEdit)}>
        <Formik
          initialValues={reportIdToEdit ? reportToEdit : formIntitialValues}
          validateOnBlur={false}
          validationSchema={customReportsSchemas.createOrUpdateCustomReportSchema}
          onSubmit={alwaysNullFunc}
        >
          {renderForm}
        </Formik>
      </Loading>
      <TestQueryModal {...modalProps} />
    </SlideIn>
  );
};

AddEditReportSlideIn.propTypes = {
  reportType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeSlideIn: PropTypes.func.isRequired,
  reportIdToEdit: PropTypes.number,
  addCustomReport: PropTypes.func.isRequired,
  editCustomReport: PropTypes.func.isRequired,
  reportToEdit: PropTypes.shape({
    reportId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    queryParams: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        mappingName: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }),
  isFetchingReportToEdit: PropTypes.bool.isRequired,
  fetchReportToEdit: PropTypes.func.isRequired,
  showEditParamModal: PropTypes.func.isRequired,
};

export default AddEditReportSlideIn;
