import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import globalContextPropTypes from '../../../shared/propTypes/globalContext';
import withContextWrapper from '../../../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import Loading from '../../../shared/displayComponents/loading.component';
import GenericScreen from '../genericScreen/genericScreen.container';
import AccountDetails from '../accountDetails/accountDetails.container';
import GenericSourceData from '../genericSourceData/genericSourceData.container';
import ManualAccountsGrid from '../../../shared/displayComponents/customizedScreen/manualAccounts/manualAccountsGrid.container';
import { manualAccountsPropType } from '../../../shared/displayComponents/customizedScreen/manualAccounts/propTypes';
import CustomScreen from '../../../customScreen/customScreen.container';

import useCustomizedScreen from './useCustomizedScreen.hook';
import { screenTypes } from './constants';

const CustomizedScreenSelector = ({
  match,

  globalContext,
  categoryId,
  accountId,

  customizedScreen,

  GenericScreenComponent,
  ComponentScreenComponent,
  SourceSystemDataScreenComponent,
  CustomScreenComponent,

  hasUserPermissionsToEdit,
  taxName,
}) => {
  switch (customizedScreen.type) {
    case screenTypes.GENERIC:
      return (
        <GenericScreenComponent
          globalContext={globalContext}
          accountId={accountId}
          hasUserPermissionsToEdit={hasUserPermissionsToEdit}
        />
      );
    case screenTypes.COMPONENT:
      return (
        <ComponentScreenComponent
          match={match}
          globalContext={globalContext}
          categoryId={categoryId}
          accountId={accountId}
        />
      );
    case screenTypes.SOURCE_SYSTEM_DATA:
      return (
        <SourceSystemDataScreenComponent
          globalContext={globalContext}
          categoryId={categoryId}
          accountId={accountId}
          componentId={globalContext.params.businessEntityId}
        />
      );
    case screenTypes.CUSTOM_SCREEN:
      return (
        <CustomScreenComponent
          globalContext={globalContext}
          customScreenName={customizedScreen.customScreenName}
          hasUserPermissionsToEdit={hasUserPermissionsToEdit}
        />
      );
    case screenTypes.RETURN_TO_INCOME_TAX_SUMMARY:
      return (
        <Redirect
          to={Routes.taxSummaries.compiledRoute({
            ...globalContext.params,
            taxName,
          })}
        />
      );
    default:
      return <ManualAccountsGrid manualAccounts={customizedScreen.manualAccounts} />;
  }
};

CustomizedScreenSelector.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    isExact: PropTypes.bool.isRequired,
  }).isRequired,

  globalContext: globalContextPropTypes,
  categoryId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,

  customizedScreen: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    customScreenName: PropTypes.string,
    manualAccounts: manualAccountsPropType,
  }).isRequired,

  GenericScreenComponent: PropTypes.elementType.isRequired,
  ComponentScreenComponent: PropTypes.elementType.isRequired,
  SourceSystemDataScreenComponent: PropTypes.elementType.isRequired,
  CustomScreenComponent: PropTypes.elementType.isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
  taxName: PropTypes.string,
};

const CustomizedScreenForAmount = withContextWrapper()(props => {
  const { taxYear, period, businessEntityId, jurisdictionId } = props.globalContext.params;
  const { categoryId, accountId } = useParams();
  const allParamsArePresent = Boolean(categoryId && accountId);

  const { customizedScreen, isFetchingCustomizedScreen } = useCustomizedScreen({
    taxYear,
    period,
    orgId: businessEntityId,
    jurisdictionId,
    categoryId,
    accountId,
  });

  if (isFetchingCustomizedScreen) {
    return (
      <div className="grid-row">
        <div className="col">
          <Loading isLoading />
        </div>
      </div>
    );
  }

  if (!allParamsArePresent || !customizedScreen.type) {
    return <SelectContextDataInfo />;
  }

  return (
    <CustomizedScreenSelector
      globalContext={props.globalContext}
      categoryId={categoryId}
      accountId={accountId}
      customizedScreen={customizedScreen}
      GenericScreenComponent={GenericScreen}
      ComponentScreenComponent={AccountDetails}
      SourceSystemDataScreenComponent={GenericSourceData}
      CustomScreenComponent={CustomScreen}
      {...props}
    />
  );
});

CustomizedScreenForAmount.propTypes = {
  globalContext: globalContextPropTypes,
};

export default CustomizedScreenForAmount;
