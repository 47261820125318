import { useEffect, useState } from 'react';

import useFetch from '../hooks/useFetch.hook';
import { httpGetAndParse } from '../../utils/fetchService';

const fetchReportParam = ({ endpointBasePath, paramName, params }) =>
  httpGetAndParse({
    route: `${endpointBasePath}/param/${paramName}/${encodeURIComponent(JSON.stringify(params))}`,
    errorMessage: `Fetching report parameter data for param '${paramName}' failed`,
  });

export const createUseReportParamHook = useFetch => {
  const useReportParam = ({
    endpointBasePath,
    paramName,
    params,
    // eslint-disable-next-line no-undefined
    defaultParamValue = undefined,
  }) => {
    const { data: paramData, isFetching: isFetchingParamData, fetch: fetchParamData } = useFetch({
      action: fetchReportParam,
    });

    useEffect(() => {
      if (Object.values(params).every(param => typeof param !== 'undefined')) {
        fetchParamData({ endpointBasePath, paramName, params });
      }
    }, [fetchParamData, endpointBasePath, paramName, params]);

    useEffect(() => {
      paramData && paramData.some(({ value }) => value === defaultParamValue)
        ? setParamValue(defaultParamValue)
        : setParamValue(undefined); // eslint-disable-line no-undefined
    }, [paramData, defaultParamValue]);

    const [paramValue, setParamValue] = useState(undefined); // eslint-disable-line no-undefined

    return {
      paramData,
      isFetchingParamData,
      paramValue,
      setParamValue,
    };
  };
  return useReportParam;
};

export default createUseReportParamHook(useFetch);
