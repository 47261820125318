import React, { useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Panel, Button } from '@pwc/appkit-react';
import { useSelector } from 'react-redux';

import config from '../config';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { clientOptionsSelector } from '../shared/store/selectors';

import styles from './userMenu.module.scss';

interface UserMenuProps {
  onHide: () => void;
  customerData: { firstName: string; lastName: string };
  selectedClient: string;
}

const UserMenu = ({ selectedClient, onHide, customerData }: UserMenuProps) => {
  const clientsData = useSelector(clientOptionsSelector);
  const hasUserMoreThanOneClientsAssigned = clientsData.length > 1;

  const logoutClickHandler = useCallback(() => (window.location.pathname = '/logout'), []);
  const clientChangeHandler = useCallback(
    (selectedClient: { value: string }) => {
      window.location.replace(`${config.BASE_PATH}/${selectedClient.value}`);
      onHide();
    },
    [onHide],
  );

  const welcomeMessage = `Welcome ${customerData.firstName || ''} ${customerData.lastName || ''}`;

  return (
    <div className={styles.userMenuPopoverPanelWrapper}>
      <OutsideClickHandler onOutsideClick={onHide}>
        <Panel className={styles.userMenuPopoverPanel}>
          <div className="a-pb-10 border-bottom a-border-light">{welcomeMessage.toUpperCase()}</div>
          {hasUserMoreThanOneClientsAssigned && (
            <div className={styles.userMenuPopoverBody}>
              <label className="a-form-label">Select client</label>
              <SDKCustomSelect
                className="sdk-custom-select"
                placeholder="Select client"
                value={selectedClient}
                onChange={clientChangeHandler}
                options={clientsData}
                virtualized
                hideLabel
              />
            </div>
          )}
          <div className={styles.logoutButton}>
            <Button size="lg" onClick={logoutClickHandler}>
              SIGN OUT
            </Button>
          </div>
        </Panel>
      </OutsideClickHandler>
    </div>
  );
};

export default UserMenu;
