import { QueryKeys, useCustomQuery } from '.';

export const useQueryFieldDrillInfo = ({
  params: { formId, dataItemDefId, dataSetInstanceId, period, entityId, consolidationId },
  enabled,
}) =>
  useCustomQuery(
    [
      QueryKeys.ViewTaxReturn.FieldDrillInfo,
      { formId, dataItemDefId, dataSetInstanceId, period, entityId, consolidationId },
    ],
    {
      url: `/api/shared/view-tax-return/form-data/field-drill-info/${formId}/${dataItemDefId}/${dataSetInstanceId}/${period}/${entityId}${
        consolidationId ? `?consolidationId=${consolidationId}` : ''
      }`,
      errorMessage: 'Error fetching field drill info',
      enabled,
    },
  );
