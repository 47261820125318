import { defaultColumnDefinition } from '../../../shared/columnDefinitions';

export default [
  {
    ...defaultColumnDefinition,
    headerName: '#',
    field: 'count',
    width: 30,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Type',
    field: 'type',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Diagnostic',
    field: 'message',
    flex: 1,
    cellStyle: { whiteSpace: 'normal' },
    autoHeight: true,
  },
];
