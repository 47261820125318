import { httpGetAndParse, httpDelete, httpPost, httpPut } from '../../../utils/fetchService';

export const fetchNotesToReview = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/preparer-reviewer-notes/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching notes to review failed',
  });

export const deleteNote = ({ noteId }) =>
  httpDelete({
    route: `/api/entities/preparer-reviewer-notes/${noteId}`,
    errorMessage: 'Deleting note failed',
  });

export const fetchJurisdictions = ({ taxYear, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/preparer-reviewer-notes/${taxYear}/${entityId}`,
    errorMessage: 'Fetching jurisdictions failed',
  });

export const fetchStatuses = () =>
  httpGetAndParse({
    route: '/api/entities/preparer-reviewer-notes/statuses',
    errorMessage: 'Fetching statuses failed',
  });

export const fetchTasks = () =>
  httpGetAndParse({
    route: '/api/entities/preparer-reviewer-notes/tasks',
    errorMessage: 'Fetching tasks failed',
  });

export const createNote = ({
  taxYear,
  period,
  entityId,
  preparedBy,
  description,
  jurisdictionId,
  taskId,
  rolloverToNextYear,
  note,
  response,
  respondedBy,
  status,
}) =>
  httpPost({
    route: '/api/entities/preparer-reviewer-notes',
    errorMessage: 'Adding note failed',
    body: {
      taxYear,
      period,
      entityId,
      preparedBy,
      description,
      jurisdictionId,
      taskId,
      rolloverToNextYear,
      note,
      response,
      respondedBy,
      status,
    },
  });

export const updateNote = ({
  taxYear,
  entityId,
  noteId,
  status,
  respondedBy,
  description,
  note,
  response,
  rolloverToNextYear,
  jurisdictionId,
  taskId,
}) =>
  httpPut({
    route: `/api/entities/preparer-reviewer-notes/${taxYear}/${entityId}/${noteId}`,
    errorMessage: 'Updating note failed',
    body: {
      status,
      respondedBy,
      description,
      note,
      response,
      rolloverToNextYear,
      jurisdictionId,
      taskId,
    },
  });
