export const ModalNames = {
  SavedSearchDelete: 'SavedSearchDelete',
  SavedSearchEdit: 'SavedSearchEdit',
};

export const SaltNews = [
  {
    key: '1',
    name: 'SALT Insight',
    href:
      'https://taxsource.pwc.com/Search#?facets=%5B%7B%22field%22:%22Sub%20document%20type%22,%22fieldName%22:%22Document%20Sub%20Type%22,%22value%22:%221dbb04fe7c294ad196270bd8f565b3b5%22,%22isExclude%22:false,%22name%22:%22Insight%22,%22isSitecoreFieldName%22:true%7D,%7B%22field%22:%22PwC%20practice%20area%22,%22fieldName%22:%22computedPracticeArea%22,%22value%22:%2257ac6012a7f84771b5c4cb3a0fa1b287%22,%22isExclude%22:false,%22name%22:%22State%20%26%20Local%20Tax%20(SALT)%22,%22isSitecoreFieldName%22:true%7D%5D&docType=f8b509454929410bbea88b05be983fc2&sortCriteria=DateDescending&includeArchivedContent=false',
  },
];

export const SaltTechnicalTools = [];

export const HelpfulLinks = [
  {
    key: '1',
    name: 'PwC State Lifecycle Tool (SLT) User Guide',
    href: 'https://drive.google.com/file/d/1nT7jQXTv02wRPzZ07p_vrqJCK4GYBGOG/view',
  },
  {
    key: '2',
    name: 'Digital Lab',
    href: 'https://lab.pwc.com',
  },
  {
    key: '3',
    name: 'IST SALT Compliance Delivery Map',
    href: ' https://lab.pwc.com/collection/details/429',
  },
  {
    key: '4',
    name: 'IST Portal',
    href: 'https://ist.pwcinternal.com',
  },
  {
    key: '5',
    name: 'How to videos',
    href: 'https://video.pwc.com/upload/media',
  },
  {
    key: '6',
    name: 'Customer Support',
    href: 'https://jira.ist.pwc.com/servicedesk/customer/portal/4',
  },
];
