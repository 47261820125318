import { Routes } from '@common-packages/routes-definitions';

import { currencyColumnDefinition } from '../../shared/columnDefinitions';
import {
  IconCellRendererFactory,
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';

const defaultColumnDefinition = {
  width: 150,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
  sortable: false,
};

const colSpanDefinition = ({ data: { sectionName } }) => {
  if (sectionName) {
    return 5;
  }
  return 1;
};

const cellStyleDefinition = params => {
  if (params.data.sectionName) {
    return { 'font-weight': 'bold' };
  }
  return null;
};

const LinkRenderer = LinkCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
  getPathName: () => Routes.federalReconciliation.MAIN, // TODO: SLT-224
});

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted, value }) => valueFormatted || value,
});

const federalAmountDefinition = props => {
  if (props.data.isFederalAmountLinkVisible) {
    return LinkRenderer(props);
  }
  return TextRenderer(props);
};

const getColumnDefinitions = ({ openSlideInForAdd, selectAccountGroup }) => {
  const notesIconRenderer = IconCellRendererFactory({
    getName: (value, data) => (data.hasNote ? 'news' : 'plus'),
    isSmall: (value, data) => !data.hasNote,
    clickHandler: (value, data) =>
      data.hasNote
        ? null
        : openSlideInForAdd({
            accountGroup: data.accountGroup,
            categoryDescription: data.categoryDescription,
          }),
    isClickHandlerActive: data => !data.hasNote,
  });

  const varianceLinkRenderer = LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: (value, data) =>
      Routes.federalReconciliationVariances.compiledRoute({
        accountGroup: data.accountGroup,
      }),
    clickHandler: (value, data) => selectAccountGroup(data.accountGroup),
  });

  const sltLinkRenderer = LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: (value, data) =>
      Routes.sltAmountDetails.compiledRoute({
        accountGroup: data.accountGroup,
      }),
    clickHandler: (value, data) => selectAccountGroup(data.accountGroup),
  });

  const categoryLinkRenderer = LinkCellRendererFactory({
    getText: ({ value }) => value,
    getPathName: (value, data) =>
      Routes.federalReconciliationCategoryDetails.compiledRoute({
        accountGroup: data.accountGroup,
      }),
    clickHandler: (value, data) => selectAccountGroup(data.accountGroup),
  });

  const categoryDescriptionDefinition = props => {
    if (props.data.accountGroup) {
      return categoryLinkRenderer(props);
    }
    return TextRenderer(props);
  };

  const stringOrCurrencyFormatter = props =>
    Number.isNaN(Number(props.value))
      ? props.value
      : currencyColumnDefinition.valueFormatter(props);

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: '',
      field: 'categoryDescription',
      colSpan: colSpanDefinition,
      cellStyle: cellStyleDefinition,
      cellRenderer: categoryDescriptionDefinition,
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      valueFormatter: stringOrCurrencyFormatter,
      headerName: 'Federal',
      field: 'federalAmount',
      cellRenderer: federalAmountDefinition,
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'SLT',
      field: 'sltAmount',
      cellRenderer: sltLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      valueFormatter: stringOrCurrencyFormatter,
      headerName: 'Variance',
      field: 'reconVariance',
      cellRenderer: varianceLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Note',
      field: 'doc',
      width: 35,
      cellRenderer: notesIconRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
