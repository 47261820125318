import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';
import {
  fetchBaseCalcSpecsTypes,
  updateBaseCalcSpecTypes,
  createBaseCalcSpecTypes,
} from '../types';

export const baseCalcSpecTypeInitialState = {
  calcSpecs: {
    columnsBlueprint: [],
    data: [],
  },
  isFetchingCalcSpecs: false,

  isUpdatingCalcSpecs: false,

  isCreatingCalcSpecs: false,
};

const baseCalcSpecTypeReducer = reducerFactory({
  initialState: baseCalcSpecTypeInitialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchBaseCalcSpecsTypes,
      payloadKey: 'calcSpecs',
      fetchingKey: 'isFetchingCalcSpecs',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateBaseCalcSpecTypes,
      fetchingKey: 'isUpdatingCalcSpecs',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createBaseCalcSpecTypes,
      fetchingKey: 'isCreatingCalcSpecs',
    }),
  },
});

export default baseCalcSpecTypeReducer;
