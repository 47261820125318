const matchPermissionRoute = require('./matchPermissionRoute');

const PERMISSION_TYPE = {
  READ: 'read',
  EDIT: 'edit',
};

const hasPermissionToAccess = ({ customerPermissions, permissionType, routeDefinitions }) => {
  if (!customerPermissions) {
    throw new Error('Missing customer permissions. Unable to determine authorized routes.');
  }

  const permissions =
    permissionType === PERMISSION_TYPE.READ
      ? customerPermissions.read.concat(customerPermissions.edit)
      : customerPermissions.edit;

  const { blacklist = [] } = customerPermissions;

  return (
    !routeDefinitions.some(routeDefinition =>
      blacklist.some(blacklistedRoute =>
        matchPermissionRoute({ routeDefinition, permissionRoute: blacklistedRoute }),
      ),
    ) &&
    permissions.some(permissionRoute =>
      routeDefinitions.some(routeDefinition =>
        matchPermissionRoute({ routeDefinition, permissionRoute }),
      ),
    )
  );
};

module.exports = {
  hasPermissionToAccess,
  PERMISSION_TYPE,
};
