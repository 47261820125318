import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchAccountsTypes,
  fetchAccountGroupsTypes,
  fetchAccountDataTypes,
  SELECT_ACCOUNT_GROUP,
  SELECT_ACCOUNT,
} from './types';

export const initialState = {
  isFetchingAccountGroups: false,
  isFetchingAccounts: false,
  isFetchingAccountData: false,
  accountGroups: [],
  previousSelectedAccountGroup: null,
  selectedAccountGroup: null,
  accounts: [],
  selectedAccount: null,
  accountData: [],
};

const manuallyEnteredAccountDataReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountsTypes,
      payloadKey: 'accounts',
      fetchingKey: 'isFetchingAccounts',
      getAdditionalState: (state, payload) => ({
        selectedAccount: payload[0] && {
          value: payload[0].accountId,
          label: payload[0].accountDesc,
        },
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountDataTypes,
      payloadKey: 'accountData',
      fetchingKey: 'isFetchingAccountData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountGroupsTypes,
      payloadKey: 'accountGroups',
      fetchingKey: 'isFetchingAccountGroups',
      getAdditionalState: (state, payload) => {
        const { previousSelectedAccountGroup } = state;
        const isPreviuoslySelectedInPayload =
          previousSelectedAccountGroup &&
          payload.find(({ accountGroup }) => previousSelectedAccountGroup.value === accountGroup);

        if (!isPreviuoslySelectedInPayload) {
          return {
            previousSelectedAccountGroup: null,
          };
        }

        return {};
      },
    }),
    [SELECT_ACCOUNT_GROUP]: (state, payload) => ({
      ...state,
      previousSelectedAccountGroup: payload,
      selectedAccountGroup: payload,
      selectedAccount: null,
      accountData: [],
    }),
    [SELECT_ACCOUNT]: (state, payload) => ({
      ...state,
      selectedAccount: payload,
    }),
  },
});

export default manuallyEnteredAccountDataReducer;
