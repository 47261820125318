import { useState, useCallback } from 'react';

const useFocus = ({ disabled = false, setFocusedElementName }) => {
  const [isFocused, setFocused] = useState(false);

  const handleFocus = useCallback(
    ({ target }) => {
      if (disabled) {
        return;
      }
      setFocusedElementName(target.name);
      setFocused(true);
    },
    [setFocusedElementName, disabled],
  );

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return {
    handleFocus,
    handleBlur,
    isFocused,
  };
};

export default useFocus;
