import { saveAs } from 'file-saver';

const saveFile = (data, filename, type, isBinary) => {
  if (isBinary) {
    const bytes = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      bytes[i] = data.charCodeAt(i);
    }
    data = bytes;
  }

  const blob = new Blob([data], { type });
  saveAs(blob, filename);
};

export default saveFile;
