import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@tls/ui-spinner';

import Footer from '../footer/footer.component';

import styles from './appLoadingIndicator.module.scss';

const AppLoading = ({ children, isLoading, showNoClientAccessMessage }) => {
  if (isLoading) {
    return (
      <div className="global-spinner-wrapper">
        <div className="global-spinner">
          <Spinner big />
        </div>
        <Footer />
      </div>
    );
  }

  if (showNoClientAccessMessage) {
    return (
      <div className={styles.noClientsWrapper}>
        <p>You need to have access to at least one client to see the application.</p>
      </div>
    );
  }

  return <>{children}</>;
};

AppLoading.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  showNoClientAccessMessage: PropTypes.bool.isRequired,
};

export default AppLoading;
