import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import { refreshFederalProFormaDataTypes, refreshGtwDataTypes } from './types';

export const refreshFederalProFormaData = apiAction(
  {
    types: refreshFederalProFormaDataTypes,
    apiAction: api.refreshFederalProFormaData,
  },
  { successNotificationMessage: () => 'Refresh Federal Data succeeded' },
);

export const refreshFederalProFormaDataThrowErrors = apiAction({
  types: refreshFederalProFormaDataTypes,
  apiAction: api.refreshFederalProFormaData,
  throwApiErrors: true,
});

export const refreshGtwData = apiAction(
  {
    types: refreshGtwDataTypes,
    apiAction: api.refreshGtwData,
    errorNotificationCloseable: true,
  },
  { successNotificationMessage: () => 'Refreshing GTW data succeeded' },
);
