import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  taxYearSelector,
  periodSelector,
  filingTypeIdSelector,
  entityIdSelector,
  jurisdictionIdSelector,
  dataModelLinksSelector,
  isFetchingDataModelLinksSelector,
  consolidationJurisdictionDescriptionSelector,
  isPartnershipSelector,
  isFetchingIsPartnershipSelector,
  customerFeaturesSelector,
  consolidationIdSelector,
  formsByPeriodSelector,
  isFetchingFormsByPeriodSelector,
  customerPermissionsSelector,
  isClientTtiAvailableSelector,
} from '../../shared/store/selectors';
import { ROOT_NAVIGATOR_TAB } from '../../shared/constants';
import { STORAGE_KEY } from '../../shared/localStorage';
import { useLocalStorage } from '../../shared/hooks/useLocalStorage.hook';
import {
  navigatorGenericScreensSelector,
  isFetchingNavigatorGenericScreensSelector,
  navigatorEFileDataSelector,
} from '../store';
import treeAfterActiveApplied from '../treeAfterActiveApplied';

import navigatorDataFactory from './navigatorDataFactory';
import CollapseIcon from './collapseIcon.component';

const NavigatorCollapseIconPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById('navigation-collapse-icon'));

const NavigatorTree = ({ applyPermissions, renderTree, ...rest }) => {
  const customerFeatures = useSelector(customerFeaturesSelector);

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const filingTypeId = useSelector(filingTypeIdSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const entityId = useSelector(entityIdSelector);
  const consolidationId = useSelector(consolidationIdSelector);
  const isClientTtiAvailable = useSelector(isClientTtiAvailableSelector);

  const consolidationJurisdictionDescription = useSelector(
    consolidationJurisdictionDescriptionSelector,
  );

  const forms = useSelector(formsByPeriodSelector);
  const isFetchingForms = useSelector(isFetchingFormsByPeriodSelector);
  const navigatorEFileData = useSelector(navigatorEFileDataSelector);

  const navigatorGenericScreens = useSelector(navigatorGenericScreensSelector);
  const isFetchingNavigatorGenericScreens = useSelector(isFetchingNavigatorGenericScreensSelector);
  const dataModelLinks = useSelector(dataModelLinksSelector);
  const isFetchingDataModelLinks = useSelector(isFetchingDataModelLinksSelector);
  const isPartnership = useSelector(isPartnershipSelector);
  const isFetchingIsPartnership = useSelector(isFetchingIsPartnershipSelector);
  const customerPermissions = useSelector(customerPermissionsSelector);
  const [localStorageNavigatorStatus, setLocalStorageNavigatorStatus] = useLocalStorage(
    STORAGE_KEY.STATUS_FOR_NAVIGATOR,
    {},
  );

  const tree = useMemo(
    () =>
      navigatorDataFactory({
        applyPermissions,
        customerFeatures,

        taxYear,
        period,
        filingTypeId,
        jurisdictionId,
        entityId,
        consolidationId,
        isClientTtiAvailable,

        consolidationJurisdictionDescription,

        forms,
        navigatorEFileData,

        dataModelLinks,
        navigatorGenericScreens,
        isPartnership,
        customerPermissions,
      }),
    [
      applyPermissions,
      customerFeatures,
      taxYear,
      period,
      filingTypeId,
      jurisdictionId,
      entityId,
      consolidationId,
      consolidationJurisdictionDescription,
      forms,
      navigatorEFileData,
      dataModelLinks,
      navigatorGenericScreens,
      isPartnership,
      customerPermissions,
    ],
  );

  const location = useLocation();

  const treeWithActive = useMemo(
    () =>
      treeAfterActiveApplied({
        tree,
        location,
        activeTab: ROOT_NAVIGATOR_TAB.NAVIGATOR,
        localStorageNavigatorStatus,
      }),
    [tree, location, localStorageNavigatorStatus],
  );

  const setLocalStorage = useCallback(
    item => {
      const updatedItems = treeWithActive?.map(localStorageItem => ({
        name: localStorageItem.name,
        isOpen:
          localStorageItem.name === item.name ? !localStorageItem.isOpen : localStorageItem.isOpen,
      }));
      const newFolderCollapseStatus =
        localStorageNavigatorStatus?.isNavigatorFolderCollapsed ?? false
          ? !updatedItems?.every(folder => folder.isOpen)
          : updatedItems?.every(folder => !folder.isOpen);

      setLocalStorageNavigatorStatus({
        isNavigatorFolderCollapsed: newFolderCollapseStatus,
        items: updatedItems,
      });
    },
    [treeWithActive, localStorageNavigatorStatus, setLocalStorageNavigatorStatus],
  );

  const itemsLoading = {
    isFetchingForms,
    isFetchingWorkpapers:
      isFetchingDataModelLinks || isFetchingNavigatorGenericScreens || isFetchingIsPartnership,
  };

  const MemoizedTree = React.memo(renderTree, (prevProps, newProps) => {
    // Compare relevant props to avoid unnecessary re-renders
    return (
      prevProps.treeWithActive !== newProps.treeWithActive ||
      prevProps.itemsLoading !== newProps.itemsLoading
    );
  });

  return (
    <>
      <NavigatorCollapseIconPortal>
        <CollapseIcon
          treeWithActive={treeWithActive}
          isNavigatorFolderCollapsed={
            localStorageNavigatorStatus?.isNavigatorFolderCollapsed ?? false
          }
          setLocalStorageNavigatorStatus={setLocalStorageNavigatorStatus}
        />
      </NavigatorCollapseIconPortal>
      <MemoizedTree
        tree={treeWithActive}
        itemsLoading={itemsLoading}
        setLocalStorage={setLocalStorage}
        {...rest}
      />
    </>
  );
};

NavigatorTree.propTypes = {
  applyPermissions: PropTypes.func.isRequired,
  renderTree: PropTypes.func.isRequired,
  rest: PropTypes.shape({
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        MAIN: PropTypes.string.isRequired,
        displayMainName: PropTypes.func.isRequired,
        routeName: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
};

export default NavigatorTree;
