const getContextMenuItems = ({ api }) => [
  'copy',
  'copyWithHeaders',
  'separator',
  {
    name: 'Export',
    subMenu: [
      {
        name: 'CSV Export',
        action: () => api.exportDataAsCsv({ allColumns: true }),
      },
      {
        name: 'Excel Export (.xlsx)',
        action: () => api.exportDataAsExcel({ allColumns: true, exportMode: 'xlsx' }),
      },
      {
        name: 'Excel Export (.xml)',
        action: () => api.exportDataAsExcel({ allColumns: true, exportMode: 'xml' }),
      },
    ],
  },
];

export default getContextMenuItems;
