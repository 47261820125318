import React, { useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import camelCase from 'lodash.camelcase';
import { Button, Banner } from '@pwc/appkit-react';

import {
  ReturnDefinitionFilingAttributeOptions,
  ReturnDefinitionFilingAttributes,
  TaxReturnCalcPreferenceColumnBlueprint,
} from '../../../../../common/types/apiShapes';
import Loading from '../../displayComponents/loading.component';
import Select from '../../forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../../forms/checkboxFormik/checkboxFormik.component';
import CalcPreferences from '../../forms/calcPreferences/calcPreferences.component';
import { FormValues } from '../utils/useFormInitialValues';

import styles from './addEditReturnPanel.module.scss';
import { CALC_OPTIONS_KEY } from './addEditReturnFormWrapper.container';

interface AddEditCalcOptionsFormProps {
  isEditing: boolean;
  isLoading: boolean;
  isReturnTypeSelectDisabled: boolean;
  isBusinessTypeSelectDisabled: boolean;
  areTaxTypeCheckboxesDisabled: boolean;
  hasExistingTaxReturnsForJurisdiction: boolean;
  calcPreferences: TaxReturnCalcPreferenceColumnBlueprint[];
  returnDefinitionFilingAttributes?: ReturnDefinitionFilingAttributes;
  taxReturnDefinitionEnabledReturnTypeOptions: ReturnDefinitionFilingAttributeOptions;
  taxReturnDefinitionEnabledBusinessTypeOptions: ReturnDefinitionFilingAttributeOptions;
  onBackButtonClick: () => void;
}

const AddEditCalcOptionsForm = ({
  isEditing,
  isLoading,
  isReturnTypeSelectDisabled,
  isBusinessTypeSelectDisabled,
  areTaxTypeCheckboxesDisabled,
  hasExistingTaxReturnsForJurisdiction,
  calcPreferences,
  returnDefinitionFilingAttributes,
  taxReturnDefinitionEnabledReturnTypeOptions,
  taxReturnDefinitionEnabledBusinessTypeOptions,
  onBackButtonClick,
}: AddEditCalcOptionsFormProps) => {
  const {
    submitForm,
    setFieldTouched,
    touched,
    values,
    isValid,
    isSubmitting,
    dirty,
    errors,
  } = useFormikContext<FormValues>();

  const isSaveButtonDisabled = !isValid || !dirty;

  const renderBanner = useCallback(() => {
    if (
      (isEditing && dirty) ||
      (hasExistingTaxReturnsForJurisdiction && Object.keys(touched).length)
    ) {
      return (
        <Banner
          className={styles.calcOptionsBanner}
          status="warning"
          content="Changes affect all returns for this entity's jurisdiction."
          closeable
        />
      );
    }
  }, [dirty, touched, isEditing, hasExistingTaxReturnsForJurisdiction]);

  const renderTaxTypeError = useCallback(() => {
    if (errors.taxType) {
      return <span className="invalid-feedback">{errors.taxType as string}</span>;
    }
  }, [errors.taxType]);

  const setTouched = useCallback((name: string) => setFieldTouched(name, true), [setFieldTouched]);

  return (
    <Loading isLoading={isLoading}>
      <div className={styles.calcOptionsTabFields}>
        <Select
          wrapperClassName={styles.returnType}
          name="returnType"
          appkitLabel="Federal Return Type"
          value={values.returnType}
          options={taxReturnDefinitionEnabledReturnTypeOptions}
          disabled={isReturnTypeSelectDisabled}
        />
        <Select
          wrapperClassName={styles.businessType}
          name="businessType"
          appkitLabel="Business Type"
          value={values.businessType}
          options={taxReturnDefinitionEnabledBusinessTypeOptions}
          disabled={isBusinessTypeSelectDisabled}
        />
        <div className={styles.taxType}>
          <span className={styles.sectionTitle}>Tax Type</span>
          {returnDefinitionFilingAttributes?.TAX_TYPE?.map(taxType => (
            <Field
              key={taxType.value}
              label={taxType.label}
              name={`taxType.${camelCase(taxType.label)}`}
              value={values.taxType[taxType.value as 'incomeTax' | 'franchiseTax']}
              disabled={areTaxTypeCheckboxesDisabled}
              component={Checkbox}
            />
          ))}
          {renderTaxTypeError()}
        </div>
        <div className={styles.taxPresence}>
          <span className={styles.sectionTitle}>Tax Presence</span>
          <Field
            label="Nexus"
            name="nexusIndicator"
            value={values.nexusIndicator}
            component={Checkbox}
            customChangeHandler={setTouched}
          />
        </div>
        <div className={styles.commonCalcPreferences}>
          <CalcPreferences
            calcPreferences={calcPreferences[0]}
            title="Common"
            hasFormPanelStyle
            calcOptionsKey={CALC_OPTIONS_KEY}
            customChangeHandler={setTouched}
          />
        </div>
        <div className={styles.stateCalcPreferences}>
          <CalcPreferences
            calcPreferences={calcPreferences[1]}
            title="State"
            hasFormPanelStyle
            calcOptionsKey={CALC_OPTIONS_KEY}
            customChangeHandler={setTouched}
          />
        </div>
      </div>
      {renderBanner()}
      <div className={styles.buttons}>
        <Button size="lg" title="Back" onClick={onBackButtonClick}>
          Back
        </Button>
        <Button
          size="lg"
          title={isEditing ? 'Save' : 'Add'}
          onClick={submitForm}
          isLoading={isSubmitting}
          disabled={isSaveButtonDisabled}
        >
          {isEditing ? 'Save' : 'Add'}
        </Button>
      </div>
    </Loading>
  );
};

export default AddEditCalcOptionsForm;
