const Yup = require('yup');

const { notNegativeInteger } = require('../validationRegex');

const addEditTaxFormCommonSchema = Yup.object().shape({
  formId: Yup.string().label('formId').required(),
  taxYear: Yup.string().label('Tax Year').required(),
  jurisdictionId: Yup.string().label('Jurisdiction').required(),
  formName: Yup.string().label('Form ID').required(),
  formDescription: Yup.string().label('Form Description').required(),
  swpFormId: Yup.string().label('SWP Form ID').required(),
  swpCalcIteration: Yup.string()
    .label('SWP Calc Iterations')
    .required()
    .matches(notNegativeInteger.regex, notNegativeInteger.message),
});

const addEditPDFFieldMapping = Yup.object().shape({
  datasetId: Yup.string().label('Dataset').required(),
  dataItemId: Yup.string().label('Data Item').required(),
  suppressCommas: Yup.bool().label('Suppress Commas'),
  decimalPlaces: Yup.number().max(9).min(0).label('Decimal Places'),
  appendText: Yup.string().max(20).label('Append Text').nullable(),
  parensIfNegative: Yup.bool().label('Negative()'),
  absoluteValue: Yup.bool().label('Absolute Value'),
  ratioAsPercentage: Yup.bool().label('Ratio As Percent'),
  wrapText: Yup.bool().label('Wrap Text').required(),
  wrapFirstRowIndt: Yup.number().label('First Row Indent Chrs').required(),
  wrapFullRowcount: Yup.number().label('Full Row Count').nullable(),
  characterSpacing: Yup.string()
    .matches(/^[0-9.,]*$/, 'Only numbers, dots and commas are allowed')
    .label('Character Spacing')
    .nullable(),
  fullfieldPattern: Yup.string().label('Fullfield Pattern').nullable(),
  fullfieldLength: Yup.number().label('Fullfield Length').nullable(),
  valueIfZero: Yup.number().label('Value If Zero'),
  wrapRowspacingIncrement: Yup.string().label('Row Spacing Increment').nullable(),
  printFirstCopyOnly: Yup.bool().label('Print First Copy Only').required(),
  printLastCopyOnly: Yup.bool().label('Print Last Copy Only').required(),
  upperCase: Yup.bool().label('Upper Case').required(),
  formattingString: Yup.string().label('Formatting String').nullable(),
  checkbox: Yup.string().label('Checkbox').nullable(),
  flipSign: Yup.bool().label('Flip Sign').required(),
});

const addTaxFormPayloadSchema = addEditTaxFormCommonSchema;

const editTaxFormPayloadSchema = addEditTaxFormCommonSchema.strict();

const createFormScreenSchema = Yup.object().shape({
  screenId: Yup.string()
    .label('Screen Id')
    .required()
    .max(50, 'Screen Id cannot exceed 50 characters'),
  categoryId: Yup.string().label('Category').required(),
  accountId: Yup.string().label('Account').required(),
});

module.exports = {
  addEditTaxFormCommonSchema,
  addTaxFormPayloadSchema,
  editTaxFormPayloadSchema,
  createFormScreenSchema,
  addEditPDFFieldMapping,
};
