import { TextCellEditor } from '../../shared/columnDefinitions/cellRenderers';

export const defaultServerSideParams = {
  limit: 50,
  offset: 0,
};

export const defaultColumnPivotOptions = {
  enablePivot: true,
  enableRowGroup: true,
  editable: true,
  cellEditor: TextCellEditor,
  width: 150,
  minWidth: 150,
  cellStyle: {
    textAlign: 'left',
  },
  filter: 'agSetColumnFilter',
};

export const numberColumnPivotOptions = {
  ...defaultColumnPivotOptions,
  aggFunc: 'sum',
};
