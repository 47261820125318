import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';

import useGridApi from '../../../../shared/hooks/useGridApi.hook';
import { defaultSideBarWithColumnsToolPanel } from '../../../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import FaIcon from '../../../../shared/displayComponents/faIcon/faIcon.component';
import ParamDropdown from '../../../../shared/displayComponents/paramDropdown/paramDropdown.component';
import useFetch from '../../../../shared/hooks/useFetch.hook';
import { columnBlueprintHeaderOptions } from '../../../../shared/columnDefinitions/constants';
import columnBlueprintStyles from '../../../../shared/styles/columnBlueprintStyles.module.scss';
import useOpenInNewTab from '../../../../shared/hooks/useOpenInNewTab';
import {
  fetchBaseCalcSpecs as fetchBaseCalcSpecsApiAction,
  fetchConsolCalcSpecs as fetchConsolCalcSpecsApiAction,
  fetchConspbCalcSpecs as fetchConspbCalcSpecsApiAction,
} from '../../../store/api';
import {
  taxYearSelector,
  periodOptionsSelector,
  isFetchingPeriodsSelector,
  jurisdictionIdSelector,
} from '../../../store/selectors';
import setGridFilterValue from '../setGridFilterValue';
import {
  CalcSpecTypes as AccountTypes, // renaming because in this context they serve as account types
  FunctionNames,
  AgGridIds,
} from '../constants';
import headerStyles from '../../../../shared/displayComponents/headerWithParamDropdowns/styles.module.scss';
import expressionBuilderStyles from '../../styles.module.scss';

import styles from './calculationAccounts.module.scss';
import getColumnBlueprintBasedColumnDefinitions from './calculationAccounts.columnDefinition';

const accountTypeOptions = Object.values(AccountTypes).map(value => ({ label: value, value }));

const Accounts = ({
  addFunctionSepExpression,
  addFunctionConsolExpression,
  generateContextMenuItems,
}) => {
  const taxYear = useSelector(taxYearSelector);
  const periodOptions = useSelector(periodOptionsSelector);
  const isFetchingPeriods = useSelector(isFetchingPeriodsSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const [accountType, setAccountType] = useState(AccountTypes.BASE);
  const [period, setPeriod] = useState(null);

  const { gridApi, onGridReady } = useGridApi();
  const [filterValue, setFilterValue] = useState('');

  const {
    data: baseCalcSpecsData,
    fetch: fetchBaseCalcSpecs,
    isFetching: isFetchingBaseCalcSpecs,
  } = useFetch({ action: fetchBaseCalcSpecsApiAction });

  const {
    data: consolCalcSpecsData,
    fetch: fetchConsolCalcSpecs,
    isFetching: isFetchingConsolCalcSpecs,
  } = useFetch({ action: fetchConsolCalcSpecsApiAction });

  const {
    data: conspbCalcSpecsData,
    fetch: fetchConspbCalcSpecs,
    isFetching: isFetchingConspbCalcSpecs,
  } = useFetch({ action: fetchConspbCalcSpecsApiAction });

  const fetchAccountsData = useCallback(() => {
    if (taxYear && jurisdictionId) {
      switch (accountType) {
        case AccountTypes.BASE:
          fetchBaseCalcSpecs({
            taxYear,
            jurisdictionId,
            filterOnIndicator: true,
            isAccountFirstColumn: true,
          });
          break;
        case AccountTypes.CONSOL:
          if (period) {
            fetchConsolCalcSpecs({ taxYear, jurisdictionId, period, isAccountFirstColumn: true });
          }
          break;
        case AccountTypes.CONSPB:
          if (period) {
            fetchConspbCalcSpecs({ taxYear, jurisdictionId, period, isAccountFirstColumn: true });
          }
          break;
        default:
      }
    }
  }, [
    fetchBaseCalcSpecs,
    fetchConsolCalcSpecs,
    fetchConspbCalcSpecs,
    accountType,
    taxYear,
    jurisdictionId,
    period,
  ]);

  useEffect(() => {
    if (!isFetchingPeriods) {
      setPeriod(periodOptions[0]?.value);
    }
  }, [isFetchingPeriods, periodOptions]);

  useEffect(() => {
    fetchAccountsData();
  }, [fetchAccountsData]);

  const handleFilterChange = useCallback(() => {
    setGridFilterValue(gridApi, setFilterValue);
  }, [gridApi]);

  const openInNewTab = useOpenInNewTab();

  const handleCalcSpecsButtonClick = useCallback(() => {
    openInNewTab(Routes.developmentCalcSpecs.MAIN, {
      calcSpecType: accountType,
      search: filterValue,
      taxYear,
      period,
      jurisdictionId,
    });
  }, [openInNewTab, accountType, period, jurisdictionId, filterValue, taxYear]);

  const getAccountData = useMemo(
    () => ({
      [AccountTypes.BASE]: baseCalcSpecsData,
      [AccountTypes.CONSOL]: consolCalcSpecsData,
      [AccountTypes.CONSPB]: conspbCalcSpecsData,
    }),
    [baseCalcSpecsData, consolCalcSpecsData, conspbCalcSpecsData],
  );

  const { data, columnsBlueprint } = getAccountData[accountType] || {};

  const isGridLoading =
    isFetchingBaseCalcSpecs || isFetchingConsolCalcSpecs || isFetchingConspbCalcSpecs;

  const columnDefinitions = useMemo(
    () => getColumnBlueprintBasedColumnDefinitions({ columnsBlueprint, accountType }),
    [columnsBlueprint, accountType],
  );

  const contextMenuItems = useMemo(
    () =>
      generateContextMenuItems(
        ({ data: { categoryId, accountId } }) => {
          addFunctionSepExpression({
            functionName: FunctionNames.GET_ACCOUNT,
            paramsArray: [accountId, categoryId, accountType],
          });
        },
        ({ data: { categoryId, accountId } }) => {
          addFunctionConsolExpression({
            functionName: FunctionNames.GET_ACCOUNT,
            paramsArray: [accountId, categoryId, accountType],
          });
        },
      ),
    [generateContextMenuItems, addFunctionSepExpression, addFunctionConsolExpression, accountType],
  );

  const onAccountSelect = useCallback(
    ({ data }) => {
      if (data && accountType) {
        addFunctionSepExpression({
          functionName: FunctionNames.GET_ACCOUNT,
          paramsArray: [data.accountId, data.categoryId, accountType],
        });
      }
    },
    [addFunctionSepExpression, accountType],
  );

  const onConsolExpressionSelect = useCallback(
    ({ data, event }) => {
      if (data && accountType && (event.metaKey || event.ctrlKey)) {
        addFunctionConsolExpression({
          functionName: FunctionNames.GET_ACCOUNT,
          paramsArray: [data.accountId, data.categoryId, accountType],
        });
      }
    },
    [addFunctionConsolExpression, accountType],
  );

  return (
    <div className={styles.accountsContainer}>
      <div className={`${headerStyles.flexSpaceBetween} ${styles.accountsHeader}`}>
        <div className={headerStyles.headerParam}>
          <ParamDropdown
            label="Account Type"
            options={accountTypeOptions}
            value={accountType}
            handleChange={setAccountType}
          />
        </div>
        {accountType !== AccountTypes.BASE && (
          <div className={headerStyles.headerParam}>
            <ParamDropdown
              className={headerStyles.headerParam}
              label="Period"
              options={periodOptions}
              value={period}
              handleChange={setPeriod}
              isBusy={isFetchingPeriods}
            />
          </div>
        )}
        <div>
          <Button size="lg" kind="secondary" onClick={handleCalcSpecsButtonClick}>
            Calc Specs...
          </Button>
          <button
            className={expressionBuilderStyles.refreshButton}
            title="Refresh data"
            onClick={fetchAccountsData}
          >
            <FaIcon icon="sync" size={16} />
          </button>
        </div>
      </div>
      <div className={`row grid-row ${columnBlueprintStyles.gridContainer}`}>
        <div className="col">
          <AgGrid
            rowData={data}
            columnDefs={columnDefinitions}
            isGridLoading={isGridLoading}
            onGridReady={onGridReady}
            onFilterChanged={handleFilterChange}
            withSearchBar
            onCellClicked={onConsolExpressionSelect}
            onCellDoubleClicked={onAccountSelect}
            sideBar={defaultSideBarWithColumnsToolPanel}
            {...columnBlueprintHeaderOptions}
            enableBrowserTooltips
            areHeaderCellBordersEnabled
            getContextMenuItems={contextMenuItems}
            saveGridStateId={AgGridIds.ACCOUNTS}
          />
        </div>
      </div>
    </div>
  );
};

Accounts.propTypes = {
  addFunctionSepExpression: PropTypes.func.isRequired,
  addFunctionConsolExpression: PropTypes.func.isRequired,
  generateContextMenuItems: PropTypes.func.isRequired,
};

export default Accounts;
