import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';

import { fetchConsolidationFilingAttributesTypes, fetchEntityFilingAttributesTypes } from './types';

const filingAttributesReducer = reducerFactory({
  initialState: {
    isFetchingConsolidationFilingAttributes: false,
    consolidationFilingAttributes: {
      columnsBlueprint: [],
      data: [],
    },
    isSavingConsolidationFilingAttributes: false,

    isFetchingEntityFilingAttributes: false,
    entityFilingAttributes: {
      columnsBlueprint: [],
      data: [],
    },
    isSavingEntityFilingAttributes: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchConsolidationFilingAttributesTypes,
      payloadKey: 'consolidationFilingAttributes',
      fetchingKey: 'isFetchingConsolidationFilingAttributes',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchEntityFilingAttributesTypes,
      payloadKey: 'entityFilingAttributes',
      fetchingKey: 'isFetchingEntityFilingAttributes',
    }),
  },
});

export default filingAttributesReducer;
