import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { entityIdSelector, periodSelector, taxYearSelector } from '../../../shared/store/selectors';
import { accountIdSelector, jurisdictionIdSelector } from '../store/selectors';

import {
  isFetchingPartnershipAmountDetailsSelector,
  partnershipAmountDetailsWithTotalSelector,
} from './store/selectors';
import { fetchPartnershipAmountDetails } from './store/actions';
import columnDefinitions from './partnershipAmountDetails.columnDefinitions';
import { partnershipAmountDetailType } from './propTypes';

const PartnershipInformation = ({
  taxYear,
  period,
  entityId,
  partnershipAmountDetails,
  isFetchingPartnershipAmountDetails,
  fetchPartnershipAmountDetails,
  accountId,
  jurisdictionId,
}) => {
  useEffect(() => {
    const isContextReady = taxYear && period && entityId && accountId && jurisdictionId;

    if (isContextReady) {
      fetchPartnershipAmountDetails({ taxYear, period, entityId, accountId, jurisdictionId });
    }
  }, [fetchPartnershipAmountDetails, taxYear, period, entityId, accountId, jurisdictionId]);

  return accountId && jurisdictionId ? (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={partnershipAmountDetails}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingPartnershipAmountDetails}
        />
      </div>
    </div>
  ) : (
    <Redirect to={Routes.partnershipFlowThrough.MAIN} />
  );
};

PartnershipInformation.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  partnershipAmountDetails: PropTypes.arrayOf(partnershipAmountDetailType).isRequired,
  isFetchingPartnershipAmountDetails: PropTypes.bool.isRequired,
  fetchPartnershipAmountDetails: PropTypes.func.isRequired,
  accountId: PropTypes.string,
  jurisdictionId: PropTypes.string,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    partnershipAmountDetails: partnershipAmountDetailsWithTotalSelector(state),
    isFetchingPartnershipAmountDetails: isFetchingPartnershipAmountDetailsSelector(state),
    accountId: accountIdSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
  }),
  {
    fetchPartnershipAmountDetails,
  },
)(PartnershipInformation);
