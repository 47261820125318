import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import {
  fetchFilingGroupsTypes,
  SELECT_FILING_GROUP_ID,
  SELECT_JURISDICTION_ID_FOR_FILING_GROUP,
  updateFilingGroupTypes,
  createFilingGroupTypes,
} from './types';

export const fetchFilingGroups = apiAction({
  types: fetchFilingGroupsTypes,
  apiAction: ({ taxYear, period }) => api.fetchFilingGroups({ taxYear, period }),
});

export const selectFilingGroupId = filingGroupId => ({
  type: SELECT_FILING_GROUP_ID,
  payload: filingGroupId,
});

export const selectJurisdictionIdForFilingGroup = jurisdictionId => ({
  type: SELECT_JURISDICTION_ID_FOR_FILING_GROUP,
  payload: jurisdictionId,
});

export const updateFilingGroup = apiAction(
  {
    types: updateFilingGroupTypes,
    apiAction: api.updateFilingGroup,
  },
  { successNotificationMessage: () => 'Filing group has been successfully updated' },
);

export const createFilingGroup = apiAction(
  {
    types: createFilingGroupTypes,
    apiAction: api.createFilingGroup,
  },
  { successNotificationMessage: () => 'Filing group has been successfully created' },
);
