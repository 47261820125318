import React from 'react';
import {
  ColumnFunctionCallbackParams,
  NewValueParams,
  ColDef,
  ICellRendererParams,
} from 'ag-grid-community';

import {
  defaultColumnDefinitionWithFilter,
  defaultColumnDefinition,
} from '../../shared/columnDefinitions';
import {
  EditableCheckboxCellRenderer,
  TextCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import { dataTypes, TAX_YEAR_ENDING_INFO_ACCOUNT_ID } from '../../shared/constants';
import EditorSelectorFactory from '../../shared/displayComponents/editorSelector/editorSelector.factory';
import { GlobalConsolidationData } from '../../../../common/types';

import styles from './consolidationInfo.module.scss';

const TextRenderer = TextCellRendererFactory({
  getText: ({ value }) => value || '',
});

const cellRenderer = (params: ICellRendererParams) => {
  if (params.data.dataType === dataTypes.CHECKBOX) {
    return <EditableCheckboxCellRenderer {...params} />;
  }
  if (params.data.infoAccountId === TAX_YEAR_ENDING_INFO_ACCOUNT_ID && !params.data.value) {
    return <div className={styles.redTextWrapper}>Missing Tax Year Ending</div>;
  }
  return TextRenderer(params);
};

const getColumnDefinitions = ({
  isInEditMode,
  updateRow,
}: {
  isInEditMode: boolean;
  updateRow: (data: GlobalConsolidationData) => void;
}): ColDef[] => {
  const onCellValueChanged = ({ data }: NewValueParams) => updateRow(data);

  const checkboxCellClassRules = {
    ...defaultColumnDefinition.cellClassRules,
    'editable-cell': () => isInEditMode,
  };

  const columnDefinition = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Information Type',
      field: 'infoType',
      filter: true,
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Account',
      field: 'infoAccountId',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: 'description',
      width: 500,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Value',
      field: 'value',
      width: 250,
      editable: ({ data }: ColumnFunctionCallbackParams) =>
        isInEditMode && data.dataType !== dataTypes.CHECKBOX,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellClassRules: checkboxCellClassRules,
      cellRenderer,
      cellEditorSelector: EditorSelectorFactory(),
      onCellValueChanged,
    },
  ];

  return columnDefinition;
};

export default getColumnDefinitions;
