import { apiAction } from '../../../shared/store/apiAction';
import * as api from '../api';

import {
  fetchParamOptionsTypes,
  runTrgReportTypes,
  fetchTrgReportTypes,
  CLEAN_AFTER_TRG_REPORT,
} from './types';

export const fetchTrgReport = apiAction({
  types: fetchTrgReportTypes,
  apiAction: ({ id }) => api.fetchTrgReport({ id }),
});

export const runTrgReport = apiAction(
  {
    types: runTrgReportTypes,
    apiAction: api.runTrgReport,
  },
  {
    successNotificationMessage: () => 'Report succesfully generated on TRG',
    errorNotificationMessage: () => 'There was an error generating your report on TRG',
  },
);

export const cleanAfterTrgReport = () => ({
  type: CLEAN_AFTER_TRG_REPORT,
});

export const fetchParamOptions = ({ reportId, paramName, params = {} }) =>
  apiAction({
    types: fetchParamOptionsTypes,
    apiAction: async ({ params }) => {
      const result = await api.runParamQuery({ reportId, paramName, params });

      return { paramName, result };
    },
    suppressOutdatedResponses: false,
  })({ reportId, paramName, params });
