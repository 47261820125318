import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import Loading from '../../../../shared/displayComponents/loading.component';
import useGridApi from '../../../../shared/hooks/useGridApi.hook';

import styles from './calculationDataItems.module.scss';
import columnDefinitions from './calculationDataItemsGrid.columnDefinitions';

const CalculationDataItemsGrid = ({
  isLoading,
  dataItems = [],
  label = '',
  doubleClickHandler,
  singleClickHandler,
  commandClickHandler,
  activeDataItemId,
  generateContextMenuItems,
}) => {
  const { gridApi, onGridReady } = useGridApi();

  const handleSingleClick = useCallback(
    ({ data, event }) => {
      if (event.shiftKey) {
        singleClickHandler({ data });
      }

      if (event.metaKey || event.ctrlKey) {
        commandClickHandler({ data });
      }
    },
    [singleClickHandler, commandClickHandler],
  );
  useEffect(() => {
    gridApi?.deselectAll();
    gridApi?.forEachNode(rowNode => {
      if (rowNode.data.id === activeDataItemId) {
        rowNode.selectThisNode(true);
        return;
      }
    });
  }, [gridApi, activeDataItemId]);

  const handleDoubleClick = useCallback(
    params => {
      // Clearing focused cell because on double click `handleShiftClick` changes (because
      // `singleClickHandler` changes cause form becomes dirty) which then results in column
      // definitions change. When they change, FocusController from Ag-Grid throws an error
      // because it tries to set focus in a column that doesn't exist anymore.
      gridApi.clearFocusedCell();
      doubleClickHandler(params);
    },
    [doubleClickHandler, gridApi],
  );

  const handleGridSizeChanged = useCallback(({ api }) => {
    api.sizeColumnsToFit();
  }, []);

  const contextMenuItems = useMemo(
    () =>
      generateContextMenuItems(
        node => {
          handleDoubleClick(node);
        },
        node => {
          commandClickHandler(node);
        },
        node => {
          singleClickHandler(node);
        },
      ),
    [generateContextMenuItems, handleDoubleClick, commandClickHandler, singleClickHandler],
  );

  return (
    <Loading isLoading={isLoading} small>
      <label className={styles.listLabel}>{label}</label>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={dataItems}
            columnDefs={columnDefinitions}
            onGridReady={onGridReady}
            onCellDoubleClicked={handleDoubleClick}
            onCellClicked={handleSingleClick}
            autoMaxWidth
            suppressSizeColumnsToFit={false}
            onGridSizeChanged={handleGridSizeChanged}
            areHeaderCellBordersEnabled
            getContextMenuItems={contextMenuItems}
          />
        </div>
      </div>
    </Loading>
  );
};

CalculationDataItemsGrid.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  dataItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      dataTypeName: PropTypes.string.isRequired,
    }),
  ),
  doubleClickHandler: PropTypes.func.isRequired,
  singleClickHandler: PropTypes.func.isRequired,
  commandClickHandler: PropTypes.func.isRequired,
  activeDataItemId: PropTypes.string,
  generateContextMenuItems: PropTypes.func.isRequired,
};

export default CalculationDataItemsGrid;
