import {
  defaultColumnDefinition,
  selectCellEditorParamsFactory,
  adminColumnDefinition,
} from '../../shared/columnDefinitions';
import { defaultSelectCellEditorSuppressKeyboardEvent } from '../../shared/columnDefinitions/constants';
import {
  selectOptionCellRendererFactory,
  NumberCellEditor,
  AdminCellRendererFactory,
  SelectVirtualizedCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import { defaultAgRichSelectCellEditor } from '../../shared/columnDefinitions/cellEditor';

import { parameterTypes, parameterTypeNames, defaultRunLevel } from './constants';
import { dataModelTypeLogic } from './genericFunctionsAddEdit.utils';
import onCellValueChangedFactory from './onCellValueChanged.factory';
import CategoryDatasetEditor from './gridCells/CategoryDatasetEditor';
import AccountDataItemEditor from './gridCells/AccountDataItemEditor';
import ConstantEditor from './gridCells/ConstantEditor';
import { fetchDatasets, fetchDataItems } from './store/api';

const getColumnDefinitions = ({
  accountsOptions,
  categoriesOptions,
  constantsOptions,
  dataTypesOptions,
  jurisdictionsOptions,
  period,
  removeParameter,
  taxYear,
}) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: () => false,
    onDeleteIconClick: data => removeParameter(data),
  });

  const {
    onDataTypeChanged,

    isJurisdictionEditable,
    onJurisdictionChanged,

    isCategoryEditable,
    onCategoryChanged,
    categoryRenderer,
    categoryEditorParamsFactory,

    isAccountEditable,
    accountRenderer,
    accountEditorParamsFactory,

    isConstantEditable,
    constantEditorParamsFactory,
  } = dataModelTypeLogic({
    constantsOptions,
    categoriesOptions,
    accountsOptions,
    taxYear,
    period,
  });

  return [
    {
      ...defaultColumnDefinition,
      headerName: '',
      field: 'parameterId',
      width: 30,
      rowDrag: true,
    },
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Row',
      field: 'parameterId',
      width: 80,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Error',
      field: 'error',
      colId: 'errorCol',
      cellStyle: { color: 'red' },
      width: 200,
      hide: true,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Parameter Type',
      field: 'parameterType',
      editable: true,
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(parameterTypes),
      onCellValueChanged: ({ node }) => {
        node.data.runLevel =
          node.data.parameterType === parameterTypeNames.DESTINATION ? defaultRunLevel : null;
      },
      width: 120,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Data Type',
      field: 'dataType',
      editable: true,
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(dataTypesOptions),
      onCellValueChanged: onCellValueChangedFactory(onDataTypeChanged),
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionId',
      editable: isJurisdictionEditable,
      cellRenderer: selectOptionCellRendererFactory(jurisdictionsOptions),
      cellEditor: SelectVirtualizedCellEditor,
      cellEditorPopup: true,
      suppressKeyboardEvent: defaultSelectCellEditorSuppressKeyboardEvent,
      cellEditorParams: {
        options: jurisdictionsOptions,
      },
      onCellValueChanged: onCellValueChangedFactory(onJurisdictionChanged(fetchDatasets)),
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Category',
      field: 'categoryId',
      editable: isCategoryEditable,
      cellRenderer: categoryRenderer,
      cellEditor: CategoryDatasetEditor,
      cellEditorPopup: true,
      cellEditorParams: categoryEditorParamsFactory,
      suppressKeyboardEvent: defaultSelectCellEditorSuppressKeyboardEvent,
      onCellValueChanged: onCellValueChangedFactory(onCategoryChanged(fetchDataItems)),
      width: 210,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'accountId',
      editable: isAccountEditable,
      cellRenderer: accountRenderer,
      cellEditor: AccountDataItemEditor,
      cellEditorPopup: true,
      suppressKeyboardEvent: defaultSelectCellEditorSuppressKeyboardEvent,
      cellEditorParams: accountEditorParamsFactory,
      width: 210,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Constant',
      field: 'otherId',
      editable: isConstantEditable,
      cellEditor: ConstantEditor,
      cellEditorPopup: true,
      suppressKeyboardEvent: defaultSelectCellEditorSuppressKeyboardEvent,
      cellEditorParams: constantEditorParamsFactory,
      width: 210,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Multiplier',
      field: 'multiplier',
      editable: true,
      cellEditor: NumberCellEditor,
      width: 110,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Rounding Digits',
      field: 'roundPlaces',
      editable: true,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
      singleClickEdit: true,
      width: 110,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Run Level',
      field: 'runLevel',
      editable: ({ data }) => data.parameterType === parameterTypeNames.DESTINATION,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        formatValue: value => parseInt(value, 10),
      },
      width: 110,
    },
  ];
};

export default getColumnDefinitions;
