import { connect } from 'react-redux';

import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import {
  nonFilingStatesJurisdictionDescriptionSelector,
  isFetchingNonFilingStatesSelector,
} from '../store/selectors';

export default connect(state => ({
  label: 'Jurisdiction',
  value: nonFilingStatesJurisdictionDescriptionSelector(state),
  isBusy: isFetchingNonFilingStatesSelector(state),
}))(GlobalContextValue);
