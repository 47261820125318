import { Routes, taxNames } from '@common-packages/routes-definitions';

import { LinkCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const defaultColumnDefinition = {
  width: 80,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
};

const getColumnsDefinitions = ({ selectNonFilingStatesJurisdiction }) => {
  const FranchiseTaxLinkRenderer = LinkCellRendererFactory({
    getText: ({ data }) => (data.franchiseTaxYn ? 'Franchise' : ''),
    getPathName: () =>
      Routes.entityNonFilingStatesReturnWorkspaceTaxSummaries.compiledRoute({
        taxName: taxNames.FRANCHISE_TAX,
      }),
    clickHandler: (value, data) => selectNonFilingStatesJurisdiction(data.jurisdictionId),
  });

  const IncomeTaxLinkRenderer = LinkCellRendererFactory({
    getText: () => 'Income',
    getPathName: () =>
      Routes.entityNonFilingStatesReturnWorkspaceTaxSummaries.compiledRoute({
        taxName: taxNames.INCOME_TAX,
      }),
    clickHandler: (value, data) => selectNonFilingStatesJurisdiction(data.jurisdictionId),
  });

  const ReturnTaxLinkRenderer = LinkCellRendererFactory({
    getText: () => 'Return',
    getPathName: () => Routes.entityNonFilingStatesReturnWorkspace.MAIN,
    clickHandler: (value, data) => selectNonFilingStatesJurisdiction(data.jurisdictionId),
  });

  return [
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
      width: 400,
    },
    {
      ...defaultColumnDefinition,
      headerName: '',
      cellRenderer: IncomeTaxLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Links',
      field: 'franchiseTaxYn',
      cellRenderer: FranchiseTaxLinkRenderer,
      sortable: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: '',
      cellRenderer: ReturnTaxLinkRenderer,
    },
  ];
};

export default getColumnsDefinitions;
