import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  entityNameSelector,
  consolidationFullNameSelector,
  globalContextSelector,
  jurisdictionDescriptionSelector,
} from '../../../shared/store/selectors';
import { useMutationAuthorizeAndTransmit } from '../../../shared/mutations/efileV2';
import { filingTypes } from '../../../shared/constants';
import {
  BinderFilingId,
  GlobalContext,
  SltBinderId,
  SubmissionId,
} from '../../../../../common/types';

interface AuthorizeAndTransmitParams {
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
  ttiYearId?: string | null;
  ttiCaseId?: string | null;
  ttiBinderId?: number | null;
  efServiceSubmissionId?: string | null;
  stateSubmissionId: SubmissionId | null;
  binderFilingId?: BinderFilingId | null;
  sltBinderId?: SltBinderId | null;
}

const useAuthorizeAndTransmit = ({
  isFetchingTaxReturnOrTransmitterProviderTR,
  ttiYearId,
  ttiCaseId,
  ttiBinderId,
  efServiceSubmissionId,
  stateSubmissionId,
  binderFilingId,
  sltBinderId,
}: AuthorizeAndTransmitParams) => {
  const [signature, setSignature] = useState('');
  const [isLegalVerbiageAccepted, setAcceptedLegalVerbiage] = useState(false);
  const [shouldIgnoreErrors, setShouldIgnoreErrors] = useState(false);

  const entityName = useSelector(entityNameSelector);
  const consolidationName = useSelector(consolidationFullNameSelector);
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);

  const { mutateAsync: authorizeAndTransmit } = useMutationAuthorizeAndTransmit();

  const { taxYear, period, filingTypeId, businessEntityId, jurisdictionId } = globalContext.params;
  const { isSeparateYN } = globalContext;
  const businessEntityName = filingTypeId === filingTypes.SEPARATE ? entityName : consolidationName;

  const isTtiDataReady = ttiYearId && ttiCaseId && ttiBinderId;

  const isAuthorizationAndTransmissionAvailable =
    taxYear &&
    period &&
    filingTypeId &&
    (isFetchingTaxReturnOrTransmitterProviderTR || (isTtiDataReady && stateSubmissionId)) &&
    businessEntityId &&
    jurisdictionId &&
    efServiceSubmissionId &&
    binderFilingId &&
    businessEntityName &&
    jurisdictionDescription &&
    sltBinderId;

  const yearOrSubPeriodId = (!isFetchingTaxReturnOrTransmitterProviderTR && ttiYearId) || '';
  const caseId = (!isFetchingTaxReturnOrTransmitterProviderTR && ttiCaseId) || '';
  const binderId = (!isFetchingTaxReturnOrTransmitterProviderTR && ttiBinderId) || 0;

  const handleAuthorizationAndTransmission = useCallback(() => {
    if (isAuthorizationAndTransmissionAvailable) {
      authorizeAndTransmit({
        yearOrSubPeriodId,
        caseId,
        binderId,
        efSubmissionId: efServiceSubmissionId,
        ttiSubmissionId: stateSubmissionId,
        signatureValue: signature,
        binderFilingId,
        isSeparateYN,
        jurisdictionId,
        businessEntityId,
        taxYear,
        period,
        filingTypeId,
        isLegalVerbiageAccepted,
        shouldIgnoreErrors,
        businessEntityName,
        jurisdictionDescription,
        sltBinderId,
      });
    }
  }, [
    isAuthorizationAndTransmissionAvailable,
    authorizeAndTransmit,
    yearOrSubPeriodId,
    caseId,
    binderId,
    efServiceSubmissionId,
    stateSubmissionId,
    signature,
    isSeparateYN,
    jurisdictionId,
    businessEntityId,
    taxYear,
    period,
    filingTypeId,
    isLegalVerbiageAccepted,
    shouldIgnoreErrors,
    businessEntityName,
    jurisdictionDescription,
    sltBinderId,
    binderFilingId,
  ]);

  return {
    setSignature,
    signature,
    setAcceptedLegalVerbiage,
    isLegalVerbiageAccepted,
    setShouldIgnoreErrors,
    shouldIgnoreErrors,
    handleAuthorizationAndTransmission,
    isAuthorizationAndTransmissionAvailable,
  };
};

export default useAuthorizeAndTransmit;
