import { getApiActionTypes } from './storeHelpers';

export const fetchTaxYearsTypes = getApiActionTypes('FETCH_TAX_YEARS');
export const SELECT_TAX_YEAR = 'SELECT_TAX_YEAR';

export const fetchPeriodsTypes = getApiActionTypes('FETCH_PERIODS');
export const SELECT_PERIOD = 'SELECT_PERIOD';

export const SELECT_FILING_TYPE = 'SELECT_FILING_TYPE';

export const fetchConsolidationsTypes = getApiActionTypes('FETCH_CONSOLIDATIONS');
export const SELECT_CONSOLIDATION = 'SELECT_CONSOLIDATION';
export const SELECT_MAINTENANCE_CONSOLIDATION = 'SELECT_MAINTENANCE_CONSOLIDATION';

export const fetchEntitiesTypes = getApiActionTypes('FETCH_ENTITIES');
export const SELECT_ENTITY = 'SELECT_ENTITY';

export const fetchTrgReportsTypes = getApiActionTypes('FETCH_TRG_REPORTS');

export const SELECT_JURISDICTION = 'SELECT_JURISDICTION';

export const fetchJurisdictionsOptionsTypes = getApiActionTypes('FETCH_JURISDICTIONS_OPTIONS');

export const fetchCustomerDataTypes = getApiActionTypes('FETCH_CUSTOMER_DATA');
export const updateLastLoginTypes = getApiActionTypes('UPDATE_LAST_LOGIN');
export const updateExpireMessageStepTypes = getApiActionTypes('UPDATE_EXPIRE_MESSAGE_STEP');
export const fetchPermissionsTypes = getApiActionTypes('FETCH_USER_PERMISSIONS');
export const SELECT_CLIENT_ID = 'SELECT_CLIENT_ID';

export const fetchLockIndicatorTypes = getApiActionTypes('RETURN_WORKSPACE/FETCH_LOCK_INDICATOR');
export const entityDataCalculationTypes = getApiActionTypes('ENTITY_DATA_CALCULATION');
export const loadAllFormDataForSeparateTypes = getApiActionTypes('LOAD_ALL_FORM_DATA_FOR_SEPARATE');
export const loadAllFormDataForConsolidatedTypes = getApiActionTypes(
  'LOAD_ALL_FORM_DATA_FOR_CONSOLIDATED',
);

export const fetchDataModelLinksTypes = getApiActionTypes('FETCH_DATA_MODEL_LINKS');
export const CLEAR_DATA_MODEL_LINKS = 'CLEAR_DATA_MODEL_LINKS';

export const SAVE_SCROLL_ELEMENT_ID = 'SAVE_SCROLL_ELEMENT_ID';

export const signOutTypes = getApiActionTypes('SIGN_OUT');
export const checkAuthorizationStatusTypes = getApiActionTypes('CHECK_AUTHORIZATION_STATUS');

export const refreshFederalProFormaDataTypes = getApiActionTypes(
  'CONSOLIDATIONS_PERFORM_TAX_RETURN_CALCULATION',
);
export const performConsolidatedMembersTaxReturnCalculationTypes = getApiActionTypes(
  'CONSOLIDATIONS_MEMBERS_PERFORM_TAX_RETURN_CALCULATION',
);
export const initiateEntityBatchCalculationTypes = getApiActionTypes(
  'INITIATE_ENTITY_BATCH_CALCULATION_TYPES',
);
export const fetchJurisdictionsForWhichEntityIsLockedTypes = getApiActionTypes(
  'FETCH_JURISDICTIONS_FOR_WHICH_ENTITY_IS_LOCKED',
);
export const initiateCalculationAllEntitiesForJurisdictionTypes = getApiActionTypes(
  'INITIATE_CALCULATION_ALL_ENTITIES_FOR_JURISDICTION',
);

export const fetchIsPartnershipTypes = getApiActionTypes('FETCH_IS_PARTNERSHIP');

export const fetchFormsByPeriodTypes = getApiActionTypes('FETCH_FORMS_BY_PERIOD');
export const CLEAR_FORMS_BY_PERIOD = 'CLEAR_FORMS_BY_PERIOD';

export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';

export const refreshInfoDataTypes = getApiActionTypes('SHARED/REFRESH_INFO_DATA');
