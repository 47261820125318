import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';
import { dateFormatter } from '../../../../shared/formatters';
import config from '../../../../config';
import { refreshStatusSourceMap } from '../../../../tools/federalDataRefresh/federalDataRefresh.columnDefinitions';

const getRefreshStatusSourceLabel = (params: { data: { entityOpsRefreshStatus: number } }) =>
  refreshStatusSourceMap.get(params.data.entityOpsRefreshStatus) || null;

const columnDefinition = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Correlation Id',
    field: 'correlationId',
    width: 300,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Last Update',
    field: 'updatedOn',
    width: 150,
    valueFormatter: ({ value }: { value: null | string }) =>
      dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Refresh Status',
    field: 'refreshStatus',
    width: 100,
    valueGetter: getRefreshStatusSourceLabel,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Log Message',
    field: 'message',
    flex: 1,
  },
];

export default columnDefinition;
