import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';

import { fetchAccountLineItemDetailTypes } from './types';

const accoundLineItemDetailReducer = reducerFactory({
  initialState: {
    accountLineItemDetail: [],
    isFetchingAccountLineItemDetail: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountLineItemDetailTypes,
      payloadKey: 'accountLineItemDetail',
      fetchingKey: 'isFetchingAccountLineItemDetail',
    }),
  },
});

export default accoundLineItemDetailReducer;
