import { httpGetAndParse, httpPut, httpPost, httpDelete } from '../../../utils/fetchService';

const baseURL = '/api/admin/maintenance/users';

export const fetchUsers = () =>
  httpGetAndParse({
    route: baseURL,
    errorMessage: 'Fetching users failed',
  });

export const updateUser = userToUpdate =>
  httpPut({
    route: baseURL,
    errorMessage: 'Updating user failed',
    body: userToUpdate,
  });

export const createUser = userToCreate =>
  httpPost({
    route: baseURL,
    errorMessage: 'Creating user failed',
    body: userToCreate,
  });

export const deleteUser = userId =>
  httpDelete({
    route: `${baseURL}/${userId}`,
    errorMessage: 'Deleting user failed',
  });

export const validateUserEmail = userEmail =>
  httpGetAndParse({
    route: `${baseURL}/validate/${userEmail}`,
    errorMessage: 'Validating user email failed',
  });

export const fetchBusinesses = ({ userId }) =>
  httpGetAndParse({
    route: `${baseURL}/businesses/${userId}`,
    errorMessage: 'Fetching businesses failed',
  });

export const updateUserBusinesses = rowsToUpdate =>
  httpPut({
    route: `${baseURL}/update-businesses`,
    errorMessage: 'Updating user businesses failed',
    body: {
      payload: rowsToUpdate,
    },
  });

export const fetchRoles = () =>
  httpGetAndParse({
    route: `${baseURL}/roles`,
    errorMessage: 'Fetching roles failed',
  });

export const updateUsers = updatedUsers =>
  httpPut({
    route: `${baseURL}/updateUsers`,
    errorMessage: 'UpdateUsers failed',
    body: updatedUsers,
  });
