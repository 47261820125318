import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchTaxFormTypes,
  fetchDataModelsWithGroupedDatasetsAndDataItemsTypes,
  fetchPdfPageDataUsageTypes,
  updateTaxFormTypes,
  PULL_GENERATE_PDF_SUCCESS,
  RESET_PDF_PAGE_DATA_USAGE,
} from './types';

export const initialState = {
  taxForm: {
    formDetails: {},
    filingAttributes: [],
    watermarksList: [],
  },
  isFetchingTaxForm: false,

  isUpdatingTaxForm: false,

  dataModels: [],
  datasets: {},
  dataItems: {},
  isFetchingDataModelsWithGroupedDatasetsAndDataItems: false,

  pdfPageDataUsage: [],
  isFetchingPdfPageDataUsage: false,
};

export default reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchTaxFormTypes,
      payloadKey: 'taxForm',
      fetchingKey: 'isFetchingTaxForm',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataModelsWithGroupedDatasetsAndDataItemsTypes,
      payloadKey: 'dataModels',
      fetchingKey: 'isFetchingDataModelsWithGroupedDatasetsAndDataItems',
      getAdditionalState: (_state, payload) => ({
        dataModels: payload.dataModels,
        datasets: payload.datasets,
        dataItems: payload.dataItems,
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPdfPageDataUsageTypes,
      payloadKey: 'pdfPageDataUsage',
      fetchingKey: 'isFetchingPdfPageDataUsage',
    }),
    [RESET_PDF_PAGE_DATA_USAGE]: state => ({
      ...state,
      pdfPageDataUsage: [],
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateTaxFormTypes,
      fetchingKey: 'isUpdatingTaxForm',
    }),

    [PULL_GENERATE_PDF_SUCCESS]: (state, payload) => ({
      ...state,
      taxForm: {
        ...state.taxForm,
        formDetails: {
          ...state.taxForm.formDetails,
          ...payload,
        },
      },
    }),
  },
});
