import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationCreateDeployment = ({ onSuccess }) =>
  useCustomMutation({
    handleData: ({ taxYear, period, clientIds }) => ({
      url: `/api/content-deployment/authorize/${taxYear}/${period}`,
      method: HttpMethods.PUT,
      body: { clientIds },
    }),
    resetKey: QueryKeys.ContentDeployment.ContentDeployments,
    successMessage: 'Content deployment created successfully',
    errorMessage: 'Creating content deployment failed',
    onSuccess,
  });

export const useMutationDeploy = () =>
  useCustomMutation({
    handleData: ({ taxYear, period }) => ({
      url: `/api/content-deployment/deploy/${taxYear}/${period}`,
      method: HttpMethods.PUT,
    }),
    successMessage: responseData => {
      if (!responseData.clientErrors.length) {
        return 'Content deployment started successfully for all clients.';
      }
    },
    resetKey: QueryKeys.ContentDeployment.ContentDeployments,
    parseResponseErrorMessage: true,
    parseResponseAsJson: true,
  });

export const useMutationConvertReturns = () =>
  useCustomMutation({
    handleData: param => ({
      url: `/api/migration-utils/convert-returns/${param.taxYear}/${param.period}`,
      method: HttpMethods.POST,
    }),
    successMessage: 'Returns successfully converted',
    errorMessage: 'Converting returns failed',
  });
