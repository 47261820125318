import { LatestPdfBinderGenerationJobResponse } from '@tls/slt-types';

import { TaxYear, Period, JurisdictionId, GlobalContextParams } from '../../../../common/types';
import { httpGetBlob } from '../../utils/fetchService';
import stringify from '../../utils/stringify';
import { createQueryString } from '../../utils/routerUtils';

import { QueryKeys, useCustomQuery } from './index';

interface LatestPdfBinderGenerationJobParams {
  binderId: string;
}

interface ReturnAttachmentsParams {
  returnId: string;
  taxYear: TaxYear;
  period: Period;
  jurisdictionId: JurisdictionId;
}

type ReturnAttachmentsResponse = {
  id: string;
  fileName: string;
}[];

interface ConsolidationEntityFormsByPeriodParams extends GlobalContextParams {
  areAllEntitiesInConsolidation: boolean;
  excludeFilingForms: boolean;
}

interface FetchDownloadReturnPdfParams {
  returnId: string;
  shouldDecrypt?: boolean;
  shouldReturnMaskedIfNoPermission?: boolean;
}

type ConsolidationEntityFormsByPeriodResponse = {
  formId: string;
  formName: string;
  swpFormId: string;
}[];

export const useQueryLatestPdfBinderGenerationJob = ({
  params,
  enabled,
}: {
  params: LatestPdfBinderGenerationJobParams;
  enabled: boolean;
}) =>
  useCustomQuery<LatestPdfBinderGenerationJobResponse & { shouldDecrypt: boolean }>(
    [QueryKeys.BinderMaintenance.LatestPdfBinderGenerationJob, params],
    {
      url: `/api/binder-maintenance/latest-pdf-binder-generation-job/${params.binderId}`,
      enabled,
      errorMessage: 'Fetching latest pdf binder generation job id failed',
    },
  );

export const useQueryReturnAttachments = ({
  params,
  enabled,
}: {
  params: ReturnAttachmentsParams;
  enabled: boolean;
}) =>
  useCustomQuery<ReturnAttachmentsResponse>(
    [QueryKeys.BinderMaintenance.ReturnAttachments, params],
    {
      url: `/api/binder-maintenance/attachments/${params.returnId}/${params.taxYear}/${params.period}/${params.jurisdictionId}`,
      enabled,
      errorMessage: 'Fetching return pdf attachments failed',
    },
  );

const downloadReturnPdf = ({
  returnId,
  shouldDecrypt,
  shouldReturnMaskedIfNoPermission,
}: FetchDownloadReturnPdfParams) =>
  httpGetBlob({
    route: `/api/binder-maintenance/download-binder-pdf/${returnId}${createQueryString({
      shouldDecrypt,
      shouldReturnMaskedIfNoPermission,
    })}`,
    errorMessage: 'Downloading binder pdf failed',
  });

export const useQueryDownloadReturnPdf = ({
  params,
  enabled,
}: {
  params: FetchDownloadReturnPdfParams;
  enabled: boolean;
}) =>
  useCustomQuery<Blob>([QueryKeys.BinderMaintenance.BinderPdf, params], {
    enabled,
    customFetch: () =>
      downloadReturnPdf({
        returnId: params.returnId,
        shouldDecrypt: params?.shouldDecrypt,
        shouldReturnMaskedIfNoPermission: params.shouldReturnMaskedIfNoPermission,
      }),
  });

export const useQueryConsolidationEntityFormsByPeriod = ({
  params,
  enabled,
}: {
  params: ConsolidationEntityFormsByPeriodParams;
  enabled: boolean;
}) =>
  useCustomQuery<ConsolidationEntityFormsByPeriodResponse>(
    [QueryKeys.BinderMaintenance.ConsolidationEntityFormsByPeriod, params],
    {
      url: `/api/binder-maintenance/forms-by-period/${params.taxYear}/${params.period}/${
        params.filingTypeId
      }/${params.businessEntityId}/${params.jurisdictionId}?${stringify({
        areAllEntitiesInConsolidationQuery: params.areAllEntitiesInConsolidation,
        excludeFilingFormsQuery: params.excludeFilingForms,
      })}`,
      enabled,
      errorMessage: 'Fetching consolidation entity forms by period failed',
    },
  );
