import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'orgId',
    maxWidth: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Id',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Amount',
    field: 'amt',
  },
];

export default columnDefinitions;
