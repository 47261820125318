import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import { defaultSideBarWithColumnsToolPanel } from '../../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import {
  pdfFieldsByPageSelector,
  isFetchingPDFFieldsByPageSelector,
} from '../../../shared/store/dataModels/selectors';
import { isFetchingFormsSelector, formIdSelector } from '../../store/selectors';
import { useRowEditMode } from '../../../shared/editMode';
import { FormDetailsTabsTypes } from '../constants';
import toggleAllSelectionsHeaderFactory from '../../../shared/displayComponents/toggleAllSelectionsHeaderFactory';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';

import { handleMultipleFieldsUnmapFactory } from './utils/handleMultipleFieldsUnmapFactory';
import getColumnDefinitions from './fieldsView.columnDefinitions';
import styles from './styles.module.scss';

const getRowId = params => params?.data?.id;

const FieldsView = ({
  selectedFormFieldId,
  handleFormFieldChange,
  selectFormDetailTab,
  setIsFormDirty,
}) => {
  const dispatch = useDispatch();

  const [selectAllUnmapState, setSelectAllUnmapState] = useState(false);
  const [numberOfFieldsToUnmap, setNumberOfFieldsToUnmap] = useState(0);

  const pdfFieldsByPage = useSelector(pdfFieldsByPageSelector);
  const isFetchingPdfFieldsByPage = useSelector(isFetchingPDFFieldsByPageSelector);
  const isFetchingTaxForms = useSelector(isFetchingFormsSelector);
  const taxFormId = useSelector(formIdSelector);
  const isLoading = isFetchingTaxForms || isFetchingPdfFieldsByPage;

  const { setValues } = useFormikContext();

  const { updateRow, updateRowForSelectAll, onGridReady, gridApi, clonedRowData } = useRowEditMode({
    rowData: pdfFieldsByPage,
    getUniqueRowId: getRowId,
  });

  useEffect(() => {
    if (isFetchingPdfFieldsByPage) {
      setNumberOfFieldsToUnmap(0);
      setSelectAllUnmapState(false);
    }
  }, [isFetchingPdfFieldsByPage]);

  useEffect(() => {
    gridApi?.getRowNode(selectedFormFieldId)?.setSelected(true);
  }, [gridApi, selectedFormFieldId]);

  const handleFormFieldSelection = useCallback(
    async ({ api }) => {
      if (taxFormId) {
        const selectedRow = api.getSelectedRows()?.[0];
        if (selectedRow) {
          handleFormFieldChange(selectedRow.id);
          selectFormDetailTab(FormDetailsTabsTypes.FIELDS);
          api.ensureNodeVisible(selectedRow);
        }
      }
    },
    [handleFormFieldChange, selectFormDetailTab, taxFormId],
  );

  const countFieldsToUnmap = useCallback(() => {
    setNumberOfFieldsToUnmap(clonedRowData.filter(({ toBeUnmapped }) => toBeUnmapped).length);
    const isAllSelected = clonedRowData.every(({ toBeUnmapped }) => toBeUnmapped);
    if (isAllSelected !== selectAllUnmapState) {
      setSelectAllUnmapState(isAllSelected);
    }
  }, [clonedRowData, selectAllUnmapState]);

  const handleOverrideAllValues = useCallback(
    newValue => {
      gridApi.forEachNodeAfterFilter(({ data }) => {
        if (data.toBeUnmapped !== newValue) {
          data.toBeUnmapped = newValue;
          updateRowForSelectAll(data);
        }
      });
      countFieldsToUnmap();
      gridApi.refreshCells({ force: true });
    },
    [updateRowForSelectAll, countFieldsToUnmap, gridApi],
  );

  const unmapFields = useCallback(() => {
    const toggledFieldIds = [];
    gridApi.forEachNodeAfterFilter(({ data }) => {
      if (data.toBeUnmapped) {
        toggledFieldIds.push({
          id: data.id,
          pdfFieldMappingId: data.pdfFieldMappingId,
        });
      }
    });
    if (toggledFieldIds.length) {
      setIsFormDirty(true);
      setValues(
        handleMultipleFieldsUnmapFactory({
          selectedFormFieldIds: toggledFieldIds,
        }),
      );
    }
    gridApi.getRowNode(toggledFieldIds[0].id).setSelected(true);
  }, [setIsFormDirty, setValues, gridApi]);

  const handleUnmapFields = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Unmap fields',
        text: `Are you sure you want to unmap ${numberOfFieldsToUnmap} field(s)?`,
        confirmCallback: unmapFields,
      }),
    );
  }, [dispatch, unmapFields, numberOfFieldsToUnmap]);

  const onFirstDataRendered = useCallback(({ api }) => {
    api.sizeColumnsToFit();
  }, []);

  const onGridSizeChanged = useCallback(({ api }) => {
    api.sizeColumnsToFit();
  }, []);

  const columnDefinitions = useMemo(() => {
    const ToggleAllSelectionsHeader = toggleAllSelectionsHeaderFactory({
      togglerState: selectAllUnmapState,
      setTogglerState: setSelectAllUnmapState,
      isInEditMode: true,
      disabled: isLoading,
      handleOverrideAllValues,
    });

    return getColumnDefinitions({
      ToggleAllSelectionsHeader,
      updateRow,
      countFieldsToUnmap,
    });
  }, [handleOverrideAllValues, countFieldsToUnmap, updateRow, selectAllUnmapState, isLoading]);

  const renderUnmapButton = useCallback(
    () => (
      <Button
        size="lg"
        onClick={handleUnmapFields}
        disabled={!numberOfFieldsToUnmap}
        className="mt-sm-1 ml-0"
      >
        Unmap Fields
      </Button>
    ),
    [handleUnmapFields, numberOfFieldsToUnmap],
  );

  return (
    <div className={`row grid-row ${styles.fieldsView}`}>
      <div className="col">
        <AgGrid
          rowData={clonedRowData}
          columnDefs={columnDefinitions}
          isGridLoading={isLoading}
          onSelectionChanged={handleFormFieldSelection}
          onGridReady={onGridReady}
          withSearchBar
          componentToRenderOnSearchBar={renderUnmapButton()}
          rowSelection="single"
          getRowId={getRowId}
          sideBar={defaultSideBarWithColumnsToolPanel}
          autoMaxWidth
          onFirstDataRendered={onFirstDataRendered}
          onGridSizeChanged={onGridSizeChanged}
          areHeaderCellBordersEnabled
        />
      </div>
    </div>
  );
};

FieldsView.propTypes = {
  selectedFormFieldId: PropTypes.string,
  selectFormDetailTab: PropTypes.func.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  setIsFormDirty: PropTypes.func.isRequired,
};

export default FieldsView;
