import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';
import getFieldValue from '../../../utils/getFieldValue';
import {
  fetchDataModelsTypes,
  fetchDatasetsTypes,
  fetchDataItemsTypes,
} from '../../../shared/store/dataModels/types';
import { SELECT_TAX_YEAR, SELECT_JURISDICTION_ID } from '../../../development/store/types';

import {
  SET_ACTIVE_TAB,
  RESET_ACTIVE_TAB,
  SET_ACTIVE_FORM_DATA_ITEM_ID,
  SET_IS_IGNORING_CALCULATION_DATA_CHANGES,
  SELECT_EXPRESSION_BUILDER_DATA_ITEM,
  SELECT_EXPRESSION_BUILDER_DATASET,
  SELECT_EXPRESSION_BUILDER_DATA_MODEL,
  SET_SPLIT_PANE_LEFT_WIDTH,
} from './types';

export const initialState = {
  isIgnoringCalculationDataChanges: false,
  activeTab: null,
  activeFormDataItemId: null,
  isFetchingDataModels: false,
  dataModels: [],
  isFetchingDatasets: false,
  datasets: [],
  isFetchingDataItems: false,
  dataItems: [],
  previousDataModelId: null,
  dataModelId: null,
  previousDatasetId: null,
  datasetId: null,
  previousDataItemId: null,
  dataItemId: null,
  pdfFieldsByPage: [],
  splitPaneLeftWidth: null,
};

export default reducerFactory({
  initialState,
  actionHandlers: {
    [SET_SPLIT_PANE_LEFT_WIDTH]: (state, payload) => ({
      ...state,
      splitPaneLeftWidth: payload,
    }),
    [SET_ACTIVE_TAB]: (state, payload) => ({
      ...state,
      activeTab: payload,
    }),
    [RESET_ACTIVE_TAB]: state => ({
      ...state,
      activeTab: null,
    }),
    [SET_ACTIVE_FORM_DATA_ITEM_ID]: (state, payload) => ({
      ...state,
      activeFormDataItemId: payload,
    }),
    [SET_IS_IGNORING_CALCULATION_DATA_CHANGES]: (
      state,
      { isIgnoringCalculationDataChanges, sharedDataModelsState },
    ) => ({
      ...state,
      isIgnoringCalculationDataChanges,
      ...(isIgnoringCalculationDataChanges && !state.isIgnoringCalculationDataChanges
        ? {
            dataModels: sharedDataModelsState.dataModels,
            datasets: sharedDataModelsState.datasets,
            dataItems: sharedDataModelsState.dataItems,
            dataModelId: sharedDataModelsState.dataModelId,
            datasetId: sharedDataModelsState.datasetId,
            previousDataModelId: sharedDataModelsState.previousDataModelId,
            previousDatasetId: sharedDataModelsState.previousDatasetId,
            previousDataItemId: sharedDataModelsState.previousDataItemId,
            dataItemId: sharedDataModelsState.dataItemId,
            pdfFieldsByPage: sharedDataModelsState.pdfFieldsByPage,
          }
        : {}),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataModelsTypes,
      payloadKey: 'dataModels',
      fetchingKey: 'isFetchingDataModels',
      ignoreChanges: state => state.isIgnoringCalculationDataChanges,
      getAdditionalState: (state, payload) => ({
        // Set initial dataModelId
        dataModelId:
          state.dataModelId ||
          getFieldValue({
            currentValueFieldName: 'dataModelId',
            payloadFieldName: 'id',
            state,
            payload,
          }),
      }),
      initialAdditionalState: {
        isFetchingDataModels: true,
        datasets: [],
        dataItems: [],
      },
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDatasetsTypes,
      payloadKey: 'datasets',
      fetchingKey: 'isFetchingDatasets',
      ignoreChanges: state => state.isIgnoringCalculationDataChanges,
      getAdditionalState: (state, payload) => ({
        // Set initial datasetId
        datasetId:
          state.datasetId ||
          getFieldValue({
            currentValueFieldName: 'datasetId',
            payloadFieldName: 'id',
            state,
            payload,
          }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataItemsTypes,
      payloadKey: 'dataItems',
      fetchingKey: 'isFetchingDataItems',
      ignoreChanges: state => state.isIgnoringCalculationDataChanges,
      getAdditionalState: (state, payload) => ({
        // Set initial dataItemId
        dataItemId:
          state.dataItemId ||
          getFieldValue({
            currentValueFieldName: 'dataItemId',
            payloadFieldName: 'id',
            state,
            payload,
          }),
      }),
    }),
    [SELECT_EXPRESSION_BUILDER_DATA_MODEL]: (state, payload) => ({
      ...state,
      dataModelId: payload,
      previousDataModelId: state.dataModelId,
      datasetId: null,
      previousDatasetId: state.datasetId,
      dataItems: [],
      dataItemId: null,
      previousDataItemId: state.dataItemId,
    }),
    [SELECT_EXPRESSION_BUILDER_DATASET]: (state, payload) => ({
      ...state,
      datasetId: payload,
      previousDatasetId: state.datasetId,
      dataItems: [],
      dataItemId: null,
      previousDataItemId: state.dataItemId,
    }),
    [SELECT_EXPRESSION_BUILDER_DATA_ITEM]: (state, payload) => ({
      ...state,
      dataItemId: payload,
      previousDataItemId: state.dataItemId,
    }),
    [SELECT_TAX_YEAR]: state => ({
      ...state,
      isIgnoringCalculationDataChanges: false,
      datasetId: null,
      mappingFormPDFId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
      forms: [],
      dataModels: [],
      datasetInstances: [],
      instanceData: [],
    }),
    [SELECT_JURISDICTION_ID]: state => ({
      ...state,
      isIgnoringCalculationDataChanges: false,
      dataModels: [],
      mappingFormPDFId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
      dataModelId: null,
      previousDataModelId: state.dataModelId,
      datasetId: null,
      previousDatasetId: state.datasetId,
      dataItemId: null,
      previousDataItemId: state.dataItemId,
    }),
  },
});
