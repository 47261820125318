import React from 'react';
import { Routes } from '@common-packages/routes-definitions';
import { ICellEditorParams, ICellRendererParams, RowNode } from 'ag-grid-community';
import { Tooltip } from '@pwc/appkit-react';

import {
  FindConsolidationsDetailsData,
  FindConsolidationTaxReturnsColumnsBlueprint,
  FindReturnStatusDefinition,
  ConsolidationId,
  JurisdictionId,
} from '../../../../common/types';
import getColumnBlueprintBasedColumnDefinitions from '../../shared/columnDefinitions/columnsBlueprint.columnDefinition';
import { FILING_METHOD_OPTIONS } from '../../shared/constants';
import { FilingMethods } from '../../shared/enums';
import {
  adminColumnDefinition,
  defaultLockPinnedColumnDefinitionWithFilter,
  mapValueFormatterFactory,
  selectCellEditorParamsFactory,
  disabledReadOnlyCheckboxColumnDefinitions,
} from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  LinkCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import { UpdateRowFunc } from '../../shared/editMode/types';
import { defaultAgRichSelectCellEditorSelector } from '../../shared/columnDefinitions/cellEditor';
import { cellRenderer } from '../../shared/columnDefinitions/cellRenderers/cellRenderer.component';
import { dueDateCellRenderer } from '../../shared/columnDefinitions/cellRenderers/dueDateCellRenderer.component';
import { LinkRendererProps } from '../../shared/columnDefinitions/cellRenderers/linkCellRenderer.factory';
import styles from '../styles.module.scss';
import {
  getDropDownCellClassRules,
  isReturnStatusEditable,
} from '../separate/setupTaxReturns.columnDefinitions';

const checkboxColumnDefinition = {
  ...defaultLockPinnedColumnDefinitionWithFilter,
  width: 85,
};

type SetContextParams = {
  consolidationId: ConsolidationId | null;
  jurisdictionId: JurisdictionId | null;
};

const GroupMemberRender = ({ value }: ICellRendererParams) =>
  Boolean(value.length) && (
    <Tooltip content={<div>{value.join(', ')}</div>} placement="top">
      <p className={styles.filingGroupIdsContainer}> {value.join(', ')}</p>
    </Tooltip>
  );

const getStaticColumnDefinitions = ({
  updateRow,
  taxReturnStatusOptions,
  shouldDisplayReturnStatus,
  shouldDisplayDueDate,
  ConsolidationLinkRenderer,
}: {
  updateRow: UpdateRowFunc<FindConsolidationsDetailsData>;
  taxReturnStatusOptions?: FindReturnStatusDefinition;
  shouldDisplayReturnStatus: boolean;
  shouldDisplayDueDate: boolean;
  ConsolidationLinkRenderer: (params: LinkRendererProps) => JSX.Element | null;
}) => {
  const onCellValueChanged = ({ data }: RowNode) => {
    updateRow(data);
  };
  return [
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
      pinned: true,
      lockPosition: true,
      width: 150,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Cons Id',
      field: 'consolidationId',
      width: 140,
      cellRenderer: (params: ICellRendererParams) => {
        return params?.data.returnId && params?.data.returnDefinitionId
          ? ConsolidationLinkRenderer(params)
          : params?.data.consolidationId;
      },
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Consolidation Description',
      field: 'description',
      width: 280,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Filing Form',
      field: 'formName',
      width: 175,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Filing Method',
      field: 'filingMethod',
      width: 120,
      valueFormatter: mapValueFormatterFactory(FILING_METHOD_OPTIONS),
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Due Date',
      field: 'dueDate',
      width: 120,
      hide: !shouldDisplayDueDate,
      cellRenderer: dueDateCellRenderer,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Return Status',
      field: 'returnStatus',
      width: 120,
      hide: !shouldDisplayReturnStatus,
      editable: isReturnStatusEditable(),
      cellRenderer: (params: ICellRendererParams) =>
        cellRenderer({
          params,
          renderer: ({ data, value }: ICellRendererParams) => {
            if (data.filingMethod === FilingMethods.NON_FILING) {
              return '';
            }
            return (taxReturnStatusOptions?.returnStatusOptions || []).find(
              option => option.value === value,
            )?.label;
          },
        }),
      cellEditorSelector: ({ data }: ICellEditorParams) => {
        return {
          ...defaultAgRichSelectCellEditorSelector,
          params: selectCellEditorParamsFactory(
            data.filingMethod === FilingMethods.EFILE
              ? taxReturnStatusOptions?.efileReturnStatusOptions
              : taxReturnStatusOptions?.returnStatusOptions,
            {},
            false,
          )(),
        };
      },
      filterParams: {
        valueFormatter: ({
          value,
        }: Omit<ICellEditorParams, 'value'> & {
          value: string;
        }) =>
          (taxReturnStatusOptions?.returnStatusOptions || []).find(
            option => option.value === parseInt(value, 10),
          )?.label,
      },
      cellClassRules: getDropDownCellClassRules(),
      onCellValueChanged,
    },
    {
      ...checkboxColumnDefinition,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Active',
      field: 'active',
      width: 80,
    },
    {
      ...checkboxColumnDefinition,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Locked',
      field: 'lockedInd',
      width: 80,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Filing Group',
      field: 'filingGroupIds',
      width: 250,
      cellRenderer: GroupMemberRender,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Parent Entity',
      field: 'parentName',
      width: 180,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Parent FEIN',
      field: 'parentFein',
      width: 80,
    },

    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Federal Proforma Filing Group',
      field: 'proformaFilingGroupId',
      width: 200,
    },
    {
      ...defaultLockPinnedColumnDefinitionWithFilter,
      headerName: 'Federal As-Filed Filing Group',
      field: 'federalFilingGroupId',
      width: 200,
      hide: true,
    },
  ];
};

const getConsolidatedColumnDefinitions = (
  columnsBlueprint: FindConsolidationTaxReturnsColumnsBlueprint,
  shouldDisplayReturnStatus: boolean,
  shouldDisplayDueDate: boolean,
  onEditIconClick: (data: ICellRendererParams['data']) => unknown,
  updateRow: UpdateRowFunc<FindConsolidationsDetailsData>,
  setContext: (params: SetContextParams) => void,
  taxReturnStatusOptions?: FindReturnStatusDefinition,
) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onEditIconClick,
    shouldShowDeleteIcon: false,
    shouldShowEditIcon: true,
  });

  const ConsolidationLinkRenderer = LinkCellRendererFactory({
    getText: ({ data }) => data.consolidationId,
    getPathName: (_value, data) =>
      Routes.returnMaintenance.compiledRoute({
        returnId: Number(data.returnId),
      }),
    clickHandler: (_, { consolidationId, jurisdictionId }) => {
      setContext({ consolidationId, jurisdictionId });
    },
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
      pinned: 'left',
      lockPinned: true,
      lockPosition: 'left',
      colId: 'adminColumn',
    },
    ...getStaticColumnDefinitions({
      taxReturnStatusOptions,
      updateRow,
      shouldDisplayReturnStatus,
      shouldDisplayDueDate,
      ConsolidationLinkRenderer,
    }),
    ...getColumnBlueprintBasedColumnDefinitions({
      columnsBlueprint,
      lockPinned: true,
    }),
  ];
};

export default getConsolidatedColumnDefinitions;
