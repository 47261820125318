import {
  RecentRefreshesResponse,
  RefreshStatus,
  EntityId,
  FilingGroupId,
  TaxYear,
  Period,
  FederalDataDetailsResponse,
  FederalFilingGroup,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export interface FederalSyncDetailsParams {
  entityId: EntityId | null;
  filingGroupId: FilingGroupId | null;
  taxYear: TaxYear | null;
  reportingPeriodId: Period | null;
}

export const useQueryRecentFederalDataRefreshes = ({
  taxYear,
  enabled,
}: {
  taxYear: TaxYear | null;
  enabled: boolean;
}) =>
  useCustomQuery<RecentRefreshesResponse[]>([QueryKeys.FederalDataDiagnostics.RecentRefreshes], {
    url: `/api/admin/support/federal-data-diagnostics/recent/${taxYear}`,
    enabled,
    initialData: [],
    errorMessage: 'error fetching recent federal data refreshes',
  });

export const useQueryStatusFederalDataRefreshes = ({
  taxYear,
  enabled,
}: {
  taxYear: TaxYear | null;
  enabled: boolean;
}) =>
  useCustomQuery<RefreshStatus>([QueryKeys.FederalDataDiagnostics.Status], {
    url: `/api/admin/support/federal-data-diagnostics/status/${taxYear}`,
    enabled,
    initialData: {
      total: 0,
      inProgress: 0,
      completed: 0,
      unsuccessful: 0,
    },
    errorMessage: 'error fetching overall federal data refresh status',
  });

export const useQueryFederalSyncDetails = ({
  params,
  enabled,
}: {
  params: FederalSyncDetailsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FederalDataDetailsResponse>([QueryKeys.FederalDataDiagnostics.Details], {
    url: `/api/admin/support/federal-data-diagnostics/details/${params.entityId}/${params.filingGroupId}/${params.taxYear}/${params.reportingPeriodId}`,
    enabled,
    errorMessage: 'error fetching federal data details',
  });

export const useQueryFederalFilingGroups = ({
  taxYear,
  enabled,
}: {
  taxYear: TaxYear | null;
  enabled: boolean;
}) =>
  useCustomQuery<FederalFilingGroup[]>([QueryKeys.FederalDataDiagnostics.FilingGroup], {
    url: `/api/admin/support/federal-data-diagnostics/group/${taxYear}`,
    enabled,
    initialData: [],
    errorMessage: 'error fetching federal filing group data',
  });
