import React, { useMemo, useState } from 'react';
import { taxTypes } from '@common-packages/shared-constants';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import styles from '../../shared/reports/reportParam.module.scss';
import getReportUrl from '../shared/getReportUrl';

import columnDefinitions from './effectiveTaxRate.columnDefinitions';

const TAX_TYPE_OPTIONS = [
  { value: taxTypes.INCOME, label: 'Income' },
  { value: taxTypes.FRANCHISE, label: 'Franchise' },
];

const endpointBasePath = getReportUrl('effective-tax-rate');

const EffectiveTaxRate = () => {
  const [taxType, setTaxType] = useState(TAX_TYPE_OPTIONS[0].value);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const businessGroupParam = useReportParam({
    endpointBasePath,
    paramName: 'businessGroup',
    params: useMemo(() => ({}), []),
  });

  const allBusinessGroupsSelected =
    (businessGroupParam.paramValue || []).length === (businessGroupParam.paramData || []).length;

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        businessGroup: !allBusinessGroupsSelected ? businessGroupParam.paramValue : null,
        allBusinessGroupsSelected,
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        businessGroupParam.paramValue,
        allBusinessGroupsSelected,
      ],
    ),
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([
    taxYearParam,
    periodParam,
    businessGroupParam,
    entityIdParam,
  ]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        orgId: entityIdParam.paramValue,
        taxType,
      }),
      [taxYearParam.paramValue, periodParam.paramValue, entityIdParam.paramValue, taxType],
    ),
  });

  const { props, onGridReady } = useActionMenuForExport({ reportData });
  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown
            label="Tax type"
            param={useMemo(
              () => ({
                isFetchingParamData: false,
                paramData: TAX_TYPE_OPTIONS,
                paramValue: taxType,
                setParamValue: setTaxType,
              }),
              [taxType],
            )}
          />
          <ReportParamMultiSelect label="Business Group" param={businessGroupParam} />
          <ReportParamMultiSelect label="Entity" param={entityIdParam} />
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EffectiveTaxRate;
