import React from 'react';
import PropTypes from 'prop-types';
import { dataTypes } from '@common-packages/shared-constants';

import { createDrillRoute } from '../drilling';

import {
  EditableCheckboxCellRenderer,
  TextCellRendererFactory,
  DateCellEditor,
  NumberCellEditor,
  selectOptionCellRendererFactory,
  LinkCellRendererFactory,
  TextCellEditor,
  DateCellRendererFactory,
} from './cellRenderers';
import { overpaymentSelectOptions, firstInstallmentSelectOptions } from './constants';
import { defaultAgRichSelectCellEditorSelector, defaultDateCellEditor } from './cellEditor';

import { selectCellEditorParamsFactory } from '.';

const DateRenderer = DateCellRendererFactory();

const TextRenderer = TextCellRendererFactory({
  getText: ({ value, valueFormatted }) => (valueFormatted ? valueFormatted : value),
});

const actualColumnPropTypes = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  columnType: PropTypes.string,
  isEditable: PropTypes.bool,
  valueLabelsMap: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
  ),
  selectOptions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({}))),
  dataType: PropTypes.oneOf([...Object.values(dataTypes), 'pdfLibDownloadLinkRenderer']),
  width: PropTypes.number,
  minWidth: PropTypes.number,
  hide: PropTypes.bool,
});

export const columnsBlueprintPropTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(actualColumnPropTypes),
      locked: PropTypes.bool,
      pinned: PropTypes.string,
    }),
    actualColumnPropTypes,
  ]),
);

export const getDataTypeBasedCellRenderer = ({
  dataType,
  meta,
  valueFormatted,
  getDrillData,
  context,
  validators,
}) => {
  const LinkRenderer = LinkCellRendererFactory({
    getText: ({ value, valueFormatted }) => {
      const displayValue = valueFormatted || value;

      return displayValue || displayValue === 0 ? displayValue : 'See details';
    },
    getPathName: (value, data, colDef) => createDrillRoute(getDrillData({ data, colDef }), context),
  });

  const DataTypeBasedCellRenderer = props => {
    const { value } = props;
    const TaxSummaryOverpaymentOptions = selectOptionCellRendererFactory(overpaymentSelectOptions);
    const TaxSummaryFirstInstallmentOptions = selectOptionCellRendererFactory(
      firstInstallmentSelectOptions,
    );

    if (getDrillData(props)) {
      return <LinkRenderer {...props} valueFormatted={valueFormatted} />;
    }

    switch (dataType) {
      case dataTypes.BOOLEAN:
        return (
          <EditableCheckboxCellRenderer
            {...props}
            label={meta ? meta.config.source.label : ''}
            value={value === null ? false : value}
          />
        );
      case dataTypes.CURRENCY:
        return <TextRenderer valueFormatted={valueFormatted} {...props} />;
      case dataTypes.OVERPAYMENT_OPTION:
        return <TaxSummaryOverpaymentOptions {...props} />;
      case dataTypes.FIRST_INSTALLMENT_OPTION:
        return <TaxSummaryFirstInstallmentOptions {...props} />;
      case dataTypes.DATE:
        return <DateRenderer {...props} validators={validators} valueFormatted={valueFormatted} />;
      default:
        return <TextRenderer {...props} validators={validators} valueFormatted={valueFormatted} />;
    }
  };

  DataTypeBasedCellRenderer.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  };

  return DataTypeBasedCellRenderer;
};

export const getEditorSelector = (dataType, validators = {}) => {
  const EditorSelector = ({ value, colDef }) => {
    switch (dataType) {
      case dataTypes.DATE:
        return {
          ...defaultDateCellEditor,
          params: {
            value,
          },
        };
      case dataTypes.CURRENCY:
      case dataTypes.INTEGER:
      case dataTypes.RATIO:
        return {
          component: 'NumberCellEditor',
          params: {
            value,
            formatValue: value => value,
          },
        };
      case dataTypes.OVERPAYMENT_OPTION:
        return {
          ...defaultAgRichSelectCellEditorSelector,
          params: selectCellEditorParamsFactory(overpaymentSelectOptions)(),
        };
      case dataTypes.FIRST_INSTALLMENT_OPTION:
        return {
          ...defaultAgRichSelectCellEditorSelector,
          params: selectCellEditorParamsFactory(firstInstallmentSelectOptions)(),
        };
      default:
        return {
          component: 'TextCellEditor',
          params: {
            value,
            ...(validators[colDef.field] && {
              validate: validators[colDef.field],
            }),
          },
        };
    }
  };

  EditorSelector.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  };

  return EditorSelector;
};

export const components = {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
};
