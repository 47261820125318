export const REFRESH_USER_JOBS = 'JOBS/REFRESH_USER_JOBS';
export const ENABLE_USER_JOBS_POLLING = 'JOBS/ENABLE_USER_JOBS_POLLING';
export const CANCEL_USER_JOBS_POLLING = 'JOBS/CANCEL_USER_JOBS_POLLING';
export const UPDATE_USER_JOBS_POLLING_CONF = 'JOBS/UPDATE_USER_JOBS_POLLING_CONF';
export const RESET_USER_JOBS_POLLING_CONF = 'JOBS/RESET_USER_JOBS_POLLING_CONF';
export const ENABLE_USER_JOB_POLLING = 'JOBS/ENABLE_USER_JOB_POLLING';
export const CANCEL_USER_JOB_POLLING = 'JOBS/CANCEL_USER_JOB_POLLING';
export const UPDATE_USER_JOB_POLLING_CONF = 'JOBS/UPDATE_USER_JOB_POLLING_CONF';
export const RESET_USER_JOB_POLLING_CONF = 'JOBS/RESET_USER_JOB_POLLING_CONF';
export const ADD_JOB_DATA_TO_STORE = 'JOBS/ADD_JOB_DATA_TO_STORE';

export const SET_SHOULD_CREATE_NOTIFICATION = 'JOBS/SET_SHOULD_CREATE_NOTIFICATION';
