import { GetCalcPreferencesRequestParams, GetCalcPreferencesResponse } from '@tls/slt-types';

import { QueryKeys, useCustomQuery } from './index';

export const useQueryCalcPreferences = ({
  enabled,
  params: { taxYear },
}: {
  enabled: boolean;
  params: GetCalcPreferencesRequestParams;
}) =>
  useCustomQuery<GetCalcPreferencesResponse>(
    [QueryKeys.CalcPreferences.CalcPreferences, { taxYear }],
    {
      url: `/api/development/calc-preferences/${taxYear}`,
      enabled,
      initialData: [],
      errorMessage: 'Fetching calc preferences failed',
    },
  );
