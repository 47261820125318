import numeral from 'numeral';

export const NumeralFormats = {
  NUMBER: '0,0.0000',
  NUMBER_6_OPTIONAL_DECIMALS: '0,0.[000000]',
  NUMBER_5_OPTIONAL_DECIMALS: '0,0.[00000]',
  NUMBER_WITHOUT_DECIMALS: '(0,0)',
  PERCENTAGE: '(0%)',
  PERCENTAGE_4: '(0.0000%)',
  PERCENTAGE_6: '(0.000000%)',
  PERCENTAGE_8: '(0.00000000%)',
  DECIMAL: '(0,0.00)',
};

const numeralFormatter = ({ value, format = NumeralFormats.NUMBER }) =>
  numeral(value).format(format);

export default numeralFormatter;
