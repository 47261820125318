import React from 'react';
import classNames from 'classnames';

import AppkitIcon from '../../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { StepStatus } from '../../enums';

import styles from './stepTabRightHeader.module.scss';

interface stepTabRightHeaderProps {
  shouldShowErrorsAndWarnings?: boolean;
  shouldShowNotStartedMessage?: boolean;
  errors?: number;
  warnings?: number;
  stepStatus: StepStatus | null;
  stepFailedMessage: string;
  stepNotStartedMessage?: string;
}

const StepTabRightHeader = ({
  shouldShowErrorsAndWarnings = false,
  shouldShowNotStartedMessage = false,
  errors,
  warnings,
  stepStatus,
  stepFailedMessage = '',
  stepNotStartedMessage = '',
}: stepTabRightHeaderProps) => {
  const hasStepStarted = stepStatus !== null;
  const areThereErrorsAndOrWarnings = Boolean(errors || warnings);

  const hasStepFailed = stepStatus === StepStatus.FAILED || stepStatus === StepStatus.TR_REJECTED;

  const getStatusText = () => {
    if (shouldShowNotStartedMessage && !hasStepStarted) {
      return stepNotStartedMessage;
    }

    if (hasStepFailed) {
      return stepFailedMessage;
    }
    return areThereErrorsAndOrWarnings
      ? `${errors} errors / ${warnings} warnings`
      : 'No Errors / Warnings';
  };

  const getStatusIcon = () => {
    if (shouldShowNotStartedMessage && !hasStepStarted) {
      return <AppkitIcon className="a-text-yellow" icon="alert" type="fill" size={32} />;
    }

    if (hasStepFailed) {
      return <AppkitIcon className="a-text-red" icon="circle-minus" type="fill" size={32} />;
    }

    if (shouldShowErrorsAndWarnings) {
      return (
        <AppkitIcon
          className={areThereErrorsAndOrWarnings ? 'a-text-yellow' : 'a-text-green'}
          icon={areThereErrorsAndOrWarnings ? 'alert' : 'confirmed'}
          type="fill"
          size={32}
        />
      );
    }
  };

  const shouldShowContent = hasStepFailed || shouldShowErrorsAndWarnings;

  return (
    <>
      {shouldShowContent && (
        <div
          className={classNames(styles.stepTabRightHeaderContainer, {
            [styles.stepTabRightHeaderFailed]: hasStepFailed,
            [styles.stepTabRightHeaderNoErrors]:
              hasStepStarted && shouldShowErrorsAndWarnings && !areThereErrorsAndOrWarnings,
          })}
        >
          <div className={styles.stepTabRightHeaderContent}>
            <div className={styles.stepTabRightHeaderText}>{getStatusText()}</div>
            {getStatusIcon()}
          </div>
        </div>
      )}
    </>
  );
};

export default StepTabRightHeader;
