import React from 'react';

import AppkitIcon from '../../../../../shared/displayComponents/appkitIcon/appkitIcon.component';

import styles from './validationWarning.module.scss';

const ValidationWarning = () => {
  return (
    <div className={styles.validationWarningContainer}>
      <AppkitIcon className={styles.validationWarningIcon} icon="alert" type="fill" size={32} />
      <p>
        Currently displaying state validations only as a federal file is not yet included in this
        return.
      </p>
    </div>
  );
};

export default ValidationWarning;
