import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import DataInfo from '../displayComponents/dataInfo/dataInfo.component';
import { customerPermissionsPropTypes } from '../propTypes/customerPropTypes';

import withPermissions from './withPermissionsHoc.container';
import hasRequiredPermissionsToView from './hasRequiredPermissionsToView';
import hasRequiredPermissionsToEdit from './hasRequiredPermissionsToEdit';

const AuthorizedRoute = ({
  customerPermissions = [],

  path,
  component: Component,
  render,
  isProtectedComponent = true,

  ...routeRest
}) => {
  const protectedComponent = useCallback(
    props => {
      const isAccessDenied =
        isProtectedComponent && !hasRequiredPermissionsToView(customerPermissions, path);
      const hasUserPermissionsToEdit = hasRequiredPermissionsToEdit(customerPermissions, path);
      if (isAccessDenied) {
        return <DataInfo textToRender={'Not found'} />;
      }
      return Component ? (
        <Component {...props} hasUserPermissionsToEdit={hasUserPermissionsToEdit} />
      ) : (
        render({
          ...props,
          hasUserPermissionsToEdit,
        })
      );
    },
    [Component, render, isProtectedComponent],
  );

  const routeRender = useCallback(props => protectedComponent(props), [protectedComponent]);

  return <Route path={path} render={routeRender} {...routeRest} />;
};

AuthorizedRoute.propTypes = {
  customerPermissions: customerPermissionsPropTypes,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  isProtectedComponent: PropTypes.bool,
};

export default withPermissions(AuthorizedRoute);
