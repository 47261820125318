import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import { fetchFederalProformaTypes, checkLastRefreshProFormaReturnTypes } from './types';

export const fetchFederalProforma = apiAction({
  types: fetchFederalProformaTypes,
  apiAction: api.fetchPdfImage,
});

export const checkLastRefreshProFormaReturn = apiAction({
  types: checkLastRefreshProFormaReturnTypes,
  apiAction: api.checkLastRefreshProFormaReturn,
});
