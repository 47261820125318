import {
  currencyColumnDefinition,
  defaultColumnDefinition,
  percentageColumnDefinition,
} from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ taxYear }) => {
  const priorYear = taxYear ? Number(taxYear) - 1 : '';

  const priorYearReportColumnDefinitions = {
    ...defaultColumnDefinition,
    width: 110,
  };

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Entity ID',
      field: 'orgId',
      width: 80,
      maxWidth: 100,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Name',
      field: 'orgName',
      width: 250,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
    },
    {
      ...defaultColumnDefinition,
      ...percentageColumnDefinition,
      headerName: `${taxYear} Apportionment %`,
      field: 'curAppPct',
    },
    {
      ...defaultColumnDefinition,
      ...percentageColumnDefinition,
      headerName: `${priorYear} Apportionment %`,
      field: 'pyrAppPct',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${taxYear} Payroll Within`,
      field: 'curPayWi',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${priorYear} Payroll Within`,
      field: 'pyrPayWi',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${taxYear} Payroll Everywhere`,
      field: 'curPayEv',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${priorYear} Payroll Everywhere`,
      field: 'pyrPayEv',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${taxYear} Sales Within`,
      field: 'curSalesWi',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${priorYear} Sales Within`,
      field: 'pyrSalesWi',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${taxYear} Sales Everywhere`,
      field: 'curSalesEv',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${priorYear} Sales Everywhere`,
      field: 'pyrSalesEv',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${taxYear} Property Within`,
      field: 'curPropWi',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${priorYear} Property Within`,
      field: 'pyrPropWi',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${taxYear} Property Everywhere`,
      field: 'curPropEv',
    },
    {
      ...priorYearReportColumnDefinitions,
      ...currencyColumnDefinition,
      headerName: `${priorYear} Property Everywhere`,
      field: 'pyrPropEv',
    },
    {
      width: 100,
      headerName: `${taxYear} Filing Ind`,
      field: 'curFilInd',
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: true,
      },
      cellStyle: {
        textAlign: 'center',
      },
      suppressMenu: true,
    },
    {
      width: 100,
      headerName: `${priorYear} Filing Ind`,
      field: 'pyrFilInd',
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: true,
      },
      cellStyle: {
        textAlign: 'center',
      },
      suppressMenu: true,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
