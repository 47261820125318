const { compile } = require('path-to-regexp');

const { WORKSPACE, genericCategoryMainNames, taxSummariesMainNames } = require('./constants');

const capitalize = name => name.charAt(0).toUpperCase() + name.slice(1);

const GENERIC_FUNCTIONS_EDIT_TYPES = {
  ADD: 'add',
  EDIT: 'edit',
};

const CONTEXT_SUBPATH = '/:taxYear/:period/:filingTypeId/:businessEntityId/:jurisdictionId';

const complianceWorkspaceRoutes = {
  compliance: {
    MAIN: '/compliance',
    displayMainName: () => 'Compliance',
  },

  complianceCalendar: {
    MAIN: '/compliance/calendar',
    displayMainName: () => 'Calendar',
  },

  complianceStateFilingGroups: {
    MAIN: '/compliance/state-filing-groups',
    displayMainName: () => 'State Filing Groups',
    id: 'state-filing-groups',
  },

  complianceFederalProformaFilingGroups: {
    MAIN: '/compliance/federal-pro-forma-filing-groups',
    displayMainName: () => 'Federal Pro Forma Filing Groups',
    id: 'federal-proforma-filing-groups',
  },

  complianceTasks: {
    MAIN: '/compliance/tasks',
    displayMainName: () => 'Tasks',
  },

  complianceTools: {
    MAIN: '/compliance/tools',
    displayMainName: () => 'Tools',
  },
};

const entityRoutes = {
  entities: {
    MAIN: '/compliance/entity',
    displayMainName: () => 'Entities',
  },

  entity: {
    MAIN: '/compliance/entity/details',
    displayMainName: () => 'Entity',
    id: 'entity-details',
  },

  filingStates: {
    MAIN: `${CONTEXT_SUBPATH}/filing-states`,
    displayMainName: () => 'Filing States',
    compiledRoute: compile(`${CONTEXT_SUBPATH}/filing-states`),
    id: 'entity-filing-states',
  },

  nonFilingStates: {
    MAIN: '/compliance/entity/details/non-filing-states',
    displayMainName: () => 'Non-Filing States',
    id: 'entity-non-filing-state',
  },

  entityInformation: {
    MAIN: `/compliance/entity/details${CONTEXT_SUBPATH}/entity-information`,
    compiledRoute: compile(`/compliance/entity/details${CONTEXT_SUBPATH}/entity-information`),
    displayMainName: () => 'Entity Information',
  },

  prepareReviewNotes: {
    MAIN: '/compliance/entity/details/preparer-reviewer-notes',
    displayMainName: () => 'Preparer/Reviewer Notes',
  },

  stateAccountNumbers: {
    MAIN: '/compliance/entity/details/state-account-numbers',
    displayMainName: () => 'State account numbers',
  },

  scheduleK1Information: {
    MAIN: '/compliance/entity/details/schedule-k1-information',
    displayMainName: () => 'Schedule K1 Information',
    id: 'schedule-k1-information',
  },

  scheduleK1InformationV2: {
    MAIN: `/compliance/entity/details${CONTEXT_SUBPATH}/schedule-k1-information-v2`,
    compiledRoute: compile(
      `/compliance/entity/details${CONTEXT_SUBPATH}/schedule-k1-information-v2`,
    ),
    displayMainName: () => 'Schedule K1 Information',
    id: 'schedule-k1-information-v2',
  },

  globalScheduleK1Information: {
    MAIN: '/compliance/entity/details/global-schedule-k1-information',
    displayMainName: () => 'Global Schedule K1 Information',
    id: 'global-schedule-k1-information',
  },

  reviewPartnershipInformation: {
    MAIN: '/compliance/entity/details/review-partnership-information',
    displayMainName: () => 'Review Partnership Information',
    id: 'partnership-info',
  },

  addPartnershipInformation: {
    MAIN: '/compliance/entity/details/review-partnership-information/add-partnership',
    displayMainName: () => 'Add Partnership Information',
    id: 'add-partnership',
  },

  partnershipFlowThrough: {
    MAIN: '/compliance/entity/details/partnership-flow-through',
    displayMainName: () => 'Partnership Flow-Through Amounts',
    id: 'flowthrough-amounts',
  },

  partnershipAmountDetails: {
    MAIN: '/compliance/entity/details/partnership-flow-through/amount-details',
    displayMainName: () => 'Partnership Amount Details',
  },

  partnershipAllocationPercentages: {
    MAIN: '/compliance/entity/details/partnership-allocation-percentages',
    displayMainName: () => 'Partnership Allocation Percentages',
  },

  addEditGlobalPdfAttachments: {
    MAIN: '/compliance/entity/details/add-edit-global-pdf-attachments',
    displayMainName: () => 'Manage documents',
  },

  addGlobalPdfAttachment: {
    MAIN: '/compliance/entity/details/add-edit-global-pdf-attachments/add-attachment',
    displayMainName: () => 'Add global PDF attachment',
  },

  federalReconciliation: {
    MAIN: '/compliance/entity/details/federal-reconciliations',
    displayMainName: () => 'Federal Reconciliations',
    id: 'federal-reconciliations',
  },

  federalReconciliationVariances: {
    MAIN: '/compliance/entity/details/federal-reconciliations/variances/:accountGroup',
    compiledRoute: compile(
      '/compliance/entity/details/federal-reconciliations/variances/:accountGroup',
    ),
    displayMainName: () => 'Federal Reconciliations Variances',
  },

  sltAmountDetails: {
    MAIN: '/compliance/entity/details/federal-reconciliations/slt-amount-details/:accountGroup',
    compiledRoute: compile(
      '/compliance/entity/details/federal-reconciliations/slt-amount-details/:accountGroup',
    ),
    displayMainName: () => 'Amount Details',
  },

  federalReconciliationCategoryDetails: {
    MAIN: '/compliance/entity/details/federal-reconciliations/category-details/:accountGroup',
    compiledRoute: compile(
      '/compliance/entity/details/federal-reconciliations/category-details/:accountGroup',
    ),
    displayMainName: () => 'Federal Reconciliations Category Details',
  },

  manuallyEnteredData: {
    MAIN: '/compliance/entity/details/manually-entered-data',
    displayMainName: () => 'Account Data Entry',
    id: 'manually-entered-data',
  },

  priorYearComparisonReportDetail: {
    MAIN: '/compliance/entity/details/prior-year-comparison-report-detail',
    displayMainName: () => 'Prior Year Comparison - Detail',
  },

  priorYearComparisonReportSummary: {
    MAIN: '/compliance/entity/details/prior-year-comparison-report-summary',
    displayMainName: () => 'Prior-year Comparison Report - Summary',
    id: 'prior-year-comparison-report-summary',
  },
};

const consolidationRoutes = {
  consolidations: {
    MAIN: '/compliance/consolidation',
    displayMainName: () => 'Consolidations',
  },
  consolidation: {
    MAIN: '/compliance/consolidation/details',
    displayMainName: () => 'Consolidation',
    id: 'consolidation-details',
  },
  consolidationFilingEntities: {
    MAIN: '/compliance/consolidation/details/filing-entities',
    displayMainName: () => 'Filing Entities',
    id: 'consolidation-filing-entities',
  },
  consolidationFilingGroups: {
    MAIN: '/compliance/consolidation/details/filing-groups',
    displayMainName: () => 'Filing Groups Included in this Consolidation',
    id: 'consolidation-filing-groups',
  },

  informationalData: {
    MAIN: '/compliance/consolidation/details/informational-data',
    displayMainName: () => 'Informational Data',
    id: 'consolidation-informational-data',
  },
  informationalDataV2: {
    MAIN: '/compliance/consolidation/details/informational-data-v2',
    displayMainName: () => 'Informational Data',
    id: 'consolidation-informational-data-v2',
  },
  consolidatedReport: {
    MAIN: '/compliance/consolidation/details/consolidated-report',
    displayMainName: () => 'Consolidated Report',
    id: 'consolidation-consolidated-report',
  },
  federalDetailsByLineNumberAndEntity: {
    MAIN: '/compliance/consolidation/details/federal-details-by-line-number-and-entity',
    displayMainName: () => 'Federal Details - By Line Number and Entity',
  },
  manualAdjustmentReport: {
    MAIN: '/compliance/consolidation/details/manual-adjustment-report',
    displayMainName: () => 'Manual Adjustment Report',
    id: 'consolidation-manual-adjustment-report',
  },
  consolidationInfo: {
    MAIN: '/compliance/consolidation/details/consolidated-info',
    displayMainName: () => 'Consolidation Info',
    id: 'consolidation-info',
  },
};

const stateFilingGroupRoutes = {
  stateFilingGroups: {
    MAIN: '/compliance/state-filing-group',
    displayMainName: () => 'Filing groups',
  },

  filingMembers: {
    MAIN: '/compliance/state-filing-group/filing-members',
    displayMainName: () => 'Filing members',
  },
};

const federalProFormaFilingGroupRoutes = {
  federalProFormaFilingGroups: {
    MAIN: '/compliance/federal-pro-forma-filing-group',
    displayMainName: () => 'Federal Pro Forma Filing groups',
  },
  federalProFormaFilingMembers: {
    MAIN: '/compliance/federal-pro-forma-filing-group/filing-members',
    displayMainName: () => 'Federal Pro Forma Filing members',
  },
};

const filingGroupRoutes = {
  filingGroups: {
    MAIN: '/compliance/filing-groups',
    displayMainName: () => 'Filing Groups',
    id: 'filing-groups',
  },
  filingGroupMembers: {
    MAIN: '/compliance/filing-groups/filing-members',
    displayMainName: () => 'Filing Group Members',
  },
};

const reportRoutes = {
  reports: {
    MAIN: '/reports',
    displayMainName: () => 'Planning & Reports',
  },
  entityAnalyticsReview: {
    MAIN: '/reports/entity-analytics-review',
    displayMainName: () => 'Entity Analytics Review',
  },
  multiStateLeTaxableIncome: {
    MAIN: '/reports/multi-state-le-taxable-income',
    displayMainName: () => 'Multi-state LE Taxable Income',
    id: 'multi-state-le-taxable-income',
  },
  highLevelPlanningData: {
    MAIN: '/reports/high-level-planning-data',
    displayMainName: () => 'High Level Planning Data',
    id: 'high-level-planning-data',
  },
  detailedPlanningData: {
    MAIN: '/reports/detailed-planning-data',
    displayMainName: () => 'Detailed Planning Data',
  },
  stateTaxableIncomeReconciliation: {
    MAIN: '/reports/state-taxable-income-reconciliation',
    displayMainName: () => 'State Taxable Income Reconciliation',
  },
  state851AffiliateData: {
    MAIN: '/reports/state-851-affiliate-data',
    displayMainName: () => 'State 851 Affiliate Data',
    id: 'state-851-affiliate-data',
  },
  populationAnalysis: {
    MAIN: '/reports/population-analysis',
    displayMainName: () => 'Population Analysis',
  },
  rawData: {
    MAIN: '/reports/raw-data',
    displayMainName: () => 'Raw Data',
  },
  rawDataForAnEntity: {
    MAIN: '/reports/raw-data-for-an-entity',
    displayMainName: () => 'Raw Data for an Entity',
  },
  localityData: {
    MAIN: '/reports/locality-data',
    displayMainName: () => 'Locality Data',
  },
  effectiveTaxRate: {
    MAIN: '/reports/effective-tax-rate',
    displayMainName: () => 'Effective Tax Rate',
  },
  effectiveTaxRateTaxAccounting: {
    MAIN: '/reports/effective-tax-rate-tax-accounting',
    displayMainName: () => 'Effective Tax Rate - Tax Accounting',
  },
  entityData: {
    MAIN: '/reports/entity-data',
    displayMainName: () => 'Entity Data',
  },
  taskWaiting: {
    MAIN: '/reports/task-waiting',
    displayMainName: () => 'Task Waiting',
  },
  estimateSummary: {
    MAIN: '/reports/estimate-summary',
    displayMainName: () => 'Estimate Summary',
  },
  trgReports: {
    MAIN: '/reports/trg',
    displayMainName: () => 'TRG Reports',
  },
  customTrgReport: {
    MAIN: '/reports/trg/:reportId',
    compiledRoute: compile('/reports/trg/:reportId'),
    displayMainName: () => 'View TRG Report',
  },
  customTrgReport823: {
    MAIN: '/reports/trg/823',
    displayMainName: () => 'Consolidated Report  - TRG report 8313',
    id: 'trg-823',
  },
  GECSPriorYearComparison: {
    MAIN: '/reports/gecs-prior-year-comparison',
    displayMainName: () => 'GECS Prior Year Comparison',
  },
  infoDataSearchByValue: {
    MAIN: '/reports/info-data-search-by-value',
    displayMainName: () => 'Info Data Search by Value',
  },
  filingPositions: {
    MAIN: '/reports/filing-positions',
    displayMainName: () => 'Filing Positions',
    id: 'filing-positions-report',
  },
  categoryDetail: {
    MAIN: '/reports/category-detail',
    displayMainName: () => 'Category Detail',
  },
  everywhereBreakdown: {
    MAIN: '/reports/everywhere-breakdown',
    displayMainName: () => 'Everywhere (EV) Breakdown',
  },
  consolidatedEverywhereBreakdown: {
    MAIN: '/reports/consolidated-everywhere-breakdown',
    displayMainName: () => 'Consolidated Everywhere (EV) Breakdown',
  },
  dividendData: {
    MAIN: '/reports/dividend-data',
    displayMainName: () => 'Dividend Data',
  },
  businessAuditTrail: {
    MAIN: '/reports/business-audit-trail',
    displayMainName: () => 'Business Audit Trail',
  },
  businessCrosstab: {
    MAIN: '/reports/business-crosstab',
    displayMainName: () => 'Business Crosstab',
  },
  legalEntityAuditTrail: {
    MAIN: '/reports/legal-entity-audit-trail',
    displayMainName: () => 'Legal Entity Audit Trail',
  },
  legalEntityCrosstab: {
    MAIN: '/reports/legal-entity-crosstab',
    displayMainName: () => 'Legal Entity Crosstab',
  },
  intercompanyPayables: {
    MAIN: '/reports/intercompany-payables',
    displayMainName: () => 'Intercompany Payables',
  },
  businessCSVExport: {
    MAIN: '/reports/business-csv-export',
    displayMainName: () => 'Business CSV Export',
  },
  legalEntityCSVAuditExport: {
    MAIN: '/reports/legal-entity-csv-audit-export',
    displayMainName: () => 'Legal Entity CSV Audit Export',
  },
  legalEntityCSVExportT05Only: {
    MAIN: '/reports/legal-entity-csv-export-t05-only',
    displayMainName: () => 'Legal Entity CSV Export - T05 Only',
  },
  intercompanyInterestExpense: {
    MAIN: '/reports/intercompany-interest-expense',
    displayMainName: () => 'Intercompany Interest Expense',
  },
  intercompanyInterestIncome: {
    MAIN: '/reports/intercompany-interest-income',
    displayMainName: () => 'Intercompany Interest Income',
  },
  payablesReconciliation: {
    MAIN: '/reports/payables-reconciliation',
    displayMainName: () => 'Payables Reconciliation',
  },
  efileTransmissionStatusReport: {
    MAIN: '/reports/efile-transmission-status',
    displayMainName: () => 'E-File Transmission Status',
    id: 'efile-transmission-status-report',
  },
  eFileFederalReturnsAvailability: {
    MAIN: '/reports/federal-return-availability',
    displayMainName: () => 'Federal Filing Availability',
    id: 'efile-federal-return-availability-report',
  },
  eFileXmlComparisonReport: {
    MAIN: '/reports/efile-xml-comparison-report',
    displayMainName: () => 'E-File XML Comparison Report',
    id: 'efile-xml-comparison-report',
  },
  watermarkUsage: {
    MAIN: '/reports/watermark-usage',
    displayMainName: () => 'Watermark Usage',
    id: 'watermark-usage',
  },
  dividendToStateAmounts: {
    MAIN: '/reports/dividend-to-state-amounts',
    displayMainName: () => 'Federal Dividend To State Amounts',
    id: 'dividend-to-state-amounts',
  },
  overrideReport: {
    MAIN: '/reports/override-report',
    displayMainName: () => 'Override Report',
    id: 'override-report',
  },
  separateManualAdjustmentReport: {
    MAIN: '/reports/manual-adjustment-report',
    displayMainName: () => 'Manual Adjustment Report',
    id: 'separate-manual-adjustment-report',
  },
};

const adminRoutes = {
  administration: {
    MAIN: '/admin',
    displayMainName: () => 'Admin',
  },
  editCustomReports: {
    MAIN: '/admin/edit-custom-reports',
    displayMainName: () => 'Edit Custom Reports',
  },
  runCustomReports: {
    MAIN: '/admin/run-custom-reports',
    displayMainName: () => 'Run Custom Reports',
  },
  editTrgReports: {
    MAIN: '/admin/edit-trg-reports',
    displayMainName: () => 'Edit TRG Reports',
  },
  runTrgReports: {
    MAIN: '/admin/run-trg-reports',
    displayMainName: () => 'Run TRG Reports',
  },
  viewCustomReport: {
    MAIN: '/admin/run-custom-reports/:reportType/:reportId',
    compiledRoute: compile('/admin/run-custom-reports/:reportType/:reportId'),
    displayMainName: () => 'View Custom Report',
  },
  efileConfiguration: {
    MAIN: '/admin/efile-configuration',
    displayMainName: () => 'Efile Configuration',
  },
  efileSubmission: {
    MAIN: '/admin/efile-submission',
    displayMainName: () => 'Efile Submission',
  },
  efileMappingDownload: {
    MAIN: '/api/admin/efile/download-mapping',
    compiledRoute: compile('/api/admin/efile/download-mapping/:submissionId'),
    displayMainName: () => 'Efile Download Mapping',
  },
  efileImportValidationDownload: {
    MAIN: '/api/admin/efile/download-import-validation',
    compiledRoute: compile('/api/admin/efile/download-import-validation/:submissionId'),
    displayMainName: () => 'Efile Download Import Validation',
  },
  usersMaintenance: {
    MAIN: '/admin/users-maintenance',
    displayMainName: () => 'User Maintenance',
  },
  assignBusinesses: {
    MAIN: '/admin/users-maintenance/assign-businesses',
    displayMainName: () => 'Assign Businesses',
  },
  businessGroup: {
    MAIN: '/admin/business-groups',
    displayMainName: () => 'Business Groups',
  },
  businesses: {
    MAIN: '/admin/businesses',
    displayMainName: () => 'Businesses',
  },
  adminConsolidations: {
    MAIN: '/admin/consolidations',
    displayMainName: () => 'Consolidations',
  },
  accounts: {
    MAIN: '/admin/accounts',
    displayMainName: () => 'Accounts',
  },
  infoAccounts: {
    MAIN: '/admin/info-accounts',
    displayMainName: () => 'Info Accounts',
  },
  taxRatesAndConstantsMaintenance: {
    MAIN: '/admin/tax-rates-and-constants-maintenance',
    displayMainName: () => 'Tax Rates And Constants Maintenance',
  },
  lockReturn: {
    MAIN: '/admin/lock-return',
    displayMainName: () => 'Lock Return',
  },
  editDataVisualizations: {
    MAIN: '/admin/edit-data-visualizations',
    displayMainName: () => 'Edit Data Visualizations',
  },
  runDataVisualizations: {
    MAIN: '/admin/run-data-visualizations',
    displayMainName: () => 'Run Data Visualizations',
  },
  jobQueueStatusReport: {
    MAIN: '/admin/job-queue-status-reports',
    displayMainName: () => 'Job Queue Status Reports',
  },
  addDataCollectionReviewTask: {
    MAIN: '/admin/add-data-collection-review-task',
    displayMainName: () => 'Add Data Collection Review Task',
  },
  addReturnTask: {
    MAIN: '/admin/add-return-task',
    displayMainName: () => 'Add Return Task',
  },
  addLegalEntityReviewTask: {
    MAIN: '/admin/add-legal-entity-review-task',
    displayMainName: () => 'Add Legal Entity Review Task',
  },
  reassignTask: {
    MAIN: '/admin/reassign-task',
    displayMainName: () => 'Reassign Task',
  },
  batchTaskSignoff: {
    MAIN: '/admin/batch-task-signoff',
    displayMainName: () => 'Batch Task Signoff',
  },
  entitiesMaintenance: {
    MAIN: '/admin/entities-maintenance',
    displayMainName: () => 'Entities Maintenance',
  },
  permissions: {
    MAIN: '/admin/permissions',
    displayMainName: () => 'Permissions',
    id: 'permissions',
  },
  roles: {
    MAIN: '/admin/roles',
    displayMainName: () => 'Roles',
  },
  constantsRulesReport: {
    MAIN: '/admin/constants-rules-report',
    displayMainName: () => 'Constants Rules Report',
  },
  taxRateReport: {
    MAIN: '/admin/tax-rate-report',
    displayMainName: () => 'Tax Rate Report',
  },
  calculatedAccountsReport: {
    MAIN: '/admin/calculated-accounts-report',
    displayMainName: () => 'Accounts Used in Calcs Report',
  },
  accountGridReport: {
    MAIN: '/admin/account-grid-report',
    displayMainName: () => 'Account Grid Based on Calc Attributes',
  },
  eFileSupport: {
    MAIN: '/admin/support/efile',
    displayMainName: () => 'E-File',
  },
  entityInformationDiagnostics: {
    MAIN: '/admin/support/entity-information-diagnostics',
    displayMainName: () => 'Entity Information Diagnostics',
    id: 'entity-information-diagnostics',
  },
  entityInformationDetails: {
    MAIN:
      '/admin/support/entity-information-diagnostics/:pageType/:groupOrEntityId/:reportingPeriodId',
    compiledRoute: compile(
      '/admin/support/entity-information-diagnostics/:pageType/:groupOrEntityId/:reportingPeriodId',
    ),
    displayMainName: () => 'Entity Ops Data',
    id: 'entity-ops-data',
  },
  entityInformationDetailsWithCorrelationId: {
    MAIN:
      '/admin/support/entity-information-diagnostics/:pageType/:groupOrEntityId/:reportingPeriodId/:correlationId',
    compiledRoute: compile(
      '/admin/support/entity-information-diagnostics/:pageType/:groupOrEntityId/:reportingPeriodId/:correlationId',
    ),
    displayMainName: () => 'Entity Ops Data With Correlation',
    id: 'entity-ops-data-correlation',
  },
  federalDataDiagnostics: {
    MAIN: '/admin/support/federal-data-diagnostics',
    displayMainName: () => 'Federal Data Diagnostics',
    id: 'federal-data-diagnostics',
  },
  federalDataDetails: {
    MAIN: '/admin/support/federal-data-diagnostics/:pageType/:groupOrEntityId/:reportingPeriodId',
    compiledRoute: compile(
      '/admin/support/federal-data-diagnostics/:pageType/:groupOrEntityId/:reportingPeriodId',
    ),
    displayMainName: () => 'Entity Federal Data',
    id: 'entity-federal-data',
  },
  federalDataDetailsWithCorrelationId: {
    MAIN:
      '/admin/support/federal-data-diagnostics/:pageType/:groupOrEntityId/:reportingPeriodId/:correlationId',
    compiledRoute: compile(
      '/admin/support/federal-data-diagnostics/pageType/:groupOrEntityId/:reportingPeriodId/:correlationId',
    ),
    displayMainName: () => 'Entity Federal Data With Correlation',
    id: 'entity-federal-data-correlation',
  },
  eFileSupportDownloadStepFile: {
    MAIN: '/api/admin/support/efile/download/:stepId/:fileType',
    compiledRoute: compile('/api/admin/support/efile/download/:stepId/:fileType'),
    displayMainName: () => 'EFile Support Download Step File',
  },
  eFileSupportDownloadTaxProcessFile: {
    MAIN: '/api/admin/support/efile/download-tax-process-file/:stepId',
    compiledRoute: compile('/api/admin/support/efile/download-tax-process-file/:stepId'),
    displayMainName: () => 'EFile Support Download Tax Process File',
  },
  utilities: {
    MAIN: '/admin/support/utilities',
    displayMainName: () => 'Utilities',
    id: 'utilities',
  },
  instanceDataCleanup: {
    MAIN: '/admin/support/instance-data-cleanup',
    displayMainName: () => 'Instance Data Cleanup',
    id: 'instanceDataCleanup',
  },
};

const developmentRoutes = {
  developmentDataModels: {
    MAIN: '/development/data-models',
    displayMainName: () => 'Data Models',
  },
  developmentCalcSpecs: {
    MAIN: '/development/calc-specs',
    displayMainName: () => 'Calc Specs',
  },
  developmentTaxForms: {
    MAIN: '/development/tax-forms',
    displayMainName: () => 'Tax Forms',
  },
  developmentTaxFormsV2: {
    MAIN: '/development/tax-forms-v2',
    displayMainName: () => 'Forms List',
  },
  developmentTaxFormEditV2: {
    MAIN: '/development/tax-forms-v2/edit',
    displayMainName: () => 'Form Details',
  },
  developmentTaxFormsDownloadPdf: {
    MAIN: '/api/development/tax-forms/download/:formId/:pdfType',
    compiledRoute: compile('/api/development/tax-forms/download/:formId/:pdfType'),
    displayMainName: () => 'Development Tax Forms Download PDF',
  },
  developmentGenericFunctions: {
    MAIN: '/development/generic-functions',
    displayMainName: () => 'Generic Functions',
  },
  developmentGenericFunctionsAddEdit: {
    MAIN: '/development/generic-functions/:editType',
    ADD: `/development/generic-functions/${GENERIC_FUNCTIONS_EDIT_TYPES.ADD}`,
    EDIT: `/development/generic-functions/${GENERIC_FUNCTIONS_EDIT_TYPES.EDIT}`,
    EDIT_TYPES: GENERIC_FUNCTIONS_EDIT_TYPES,
    displayMainName: ({ params }) => capitalize(params.editType),
    displayMainNameStatic: () => 'Generic Functions Edit Types',
  },
  genericScreenDefinition: {
    MAIN: '/development/generic-screen-definitions',
    displayMainName: () => 'Generic Screen Definitions',
  },
  genericScreenDefinitionRowDetails: {
    MAIN: '/development/generic-screen-definitions/details',
    displayMainName: () => 'Generic Screen Row Detail',
  },
  defaultBinders: {
    MAIN: '/development/default-binders',
    displayMainName: () => 'Default Binders',
  },
  attachmentDefinitions: {
    MAIN: '/development/attachment-definitions',
    displayMainName: () => 'Attachment Definitions',
  },
  jimJobsHistory: {
    MAIN: '/development/jim-jobs-history',
    displayMainName: () => 'JIM Jobs History',
    id: 'jim-jobs-history',
  },
  expressionBuilder: {
    MAIN: '/development/data-models/expression-builder',
    displayMainName: () => 'Expression Builder',
    id: 'expression-builder',
  },
  compileExpressions: {
    MAIN: '/development/data-models/expression-builder/compile-expressions',
    displayMainName: () => 'Compile Expressions',
    id: 'compile-expressions',
  },
  watermarks: {
    MAIN: '/development/watermarks',
    displayMainName: () => 'Watermarks',
    id: 'watermarks',
  },
  compileExpressionsReport: {
    MAIN:
      '/development/data-models/expression-builder/compile-expressions/compile-expressions-report',
    displayMainName: () => 'Compile Expressions Report',
    id: 'compile-expressions-report',
  },
  selectionLists: {
    MAIN: '/development/data-models/selection-lists',
    displayMainName: () => 'Selection Lists',
    id: 'selection-lists',
  },
  calcPreferences: {
    MAIN: '/development/calc-preferences',
    displayMainName: () => 'Calc Preferences',
    id: 'calc-preferences',
  },
  disallowOverrideReport: {
    MAIN:
      '/development/data-models/expression-builder/compile-expressions/disallow-override-report',
    displayMainName: () => 'Disallow Expression Override Report',
    id: 'disallow-override-report',
  },
  checkboxGroups: {
    MAIN: '/development/checkbox-groups',
    displayMainName: () => 'Checkbox Groups',
    id: 'checkbox-groups',
  },
  // NOTE: SLT-14465 Turn Off E-file Element Mapping Tool
  // eFileElementMappings: {
  //   MAIN: '/development/efile-mapping',
  //   displayMainName: () => 'Element Mappings',
  // },
  returnDefinitions: {
    MAIN: '/development/return-definitions',
    displayMainName: () => 'Return Definitions',
    id: 'return-definitions',
  },
};

const toolsRoutes = {
  tools: {
    MAIN: '/compliance/tool',
    displayMainName: () => 'Tools',
  },
  federalDataRefresh: {
    MAIN: '/compliance/tool/federal-data-refresh',
    displayMainName: () => 'Data Collection - Refresh Pro-Forma Federal Return',
  },
  multDataUpload: {
    MAIN: '/compliance/tool/mult-data-upload',
    displayMainName: () => 'Data Collection - Mult Data',
    id: 'mult-data-upload',
  },
  informationalDataUpload: {
    MAIN: '/compliance/tool/informational-data-upload',
    displayMainName: () => 'Data Collection - Info Data',
    id: 'informational-data-upload',
  },
  genericAccountDataUpload: {
    MAIN: '/compliance/tool/generic-account-data-upload',
    displayMainName: () => 'Data Collection - Account Data',
    id: 'account-data-upload',
  },
  attachments: {
    MAIN: '/compliance/tool/attachments',
    displayMainName: () => 'Attachments',
    id: 'attachments',
  },
  gtwFileUpload: {
    MAIN: '/compliance/tool/gtw-file-upload',
    displayMainName: () => 'GTW file upload',
    id: 'gtw-file-upload',
  },
  ownershipAllocationsUpload: {
    MAIN: '/compliance/tool/ownership-allocations-upload',
    displayMainName: () => 'Ownership Allocations Upload',
    id: 'ownership-allocations-upload',
  },
  filingGroupMembersUpload: {
    MAIN: '/compliance/tool/filing-group-members-upload',
    displayMainName: () => 'Filing Group Members Upload',
    id: 'filing-group-members-upload',
  },
  diagnostics: {
    MAIN: '/compliance/tool/diagnostics',
    displayMainName: () => 'Diagnostics',
    id: 'diagnostics',
  },
};

const efileRoutes = {
  eFile: {
    MAIN: '/efile',
    displayMainName: () => 'E-File',
  },
  eFileSetup: {
    MAIN: '/efile/efile-setup',
    displayMainName: () => 'E-File setup',
    id: 'efile-setup',
  },
  currentReturnFiling: {
    MAIN: '/efile/current-return-filing',
    displayMainName: () => 'Current Return Filing',
    id: 'current-return-filing',
  },
  currentReturnFilingV2: {
    MAIN: '/efile/current-return-filing-v2/:returnId',
    displayMainName: () => 'Current Return Filing (New)',
    compiledRoute: compile('/efile/current-return-filing-v2/:returnId'),
    id: 'current-return-filing-v2',
  },
  eFileErrors: {
    MAIN: '/efile/efile-errors',
    displayMainName: () => 'E-File Errors',
    id: 'efile-errors',
  },
  federalAndProformaProcesses: {
    MAIN: '/efile/federal-proforma-processes',
    displayMainName: () => 'Federal and Proforma Processes',
    id: 'federal-proforma-processes',
  },
  federalAndProformaProcessesDownload: {
    MAIN: '/api/efile/federal-proforma-processes/download/:fileId',
    compiledRoute: compile('/api/efile/federal-proforma-processes/download/:fileId'),
    displayMainName: () => 'Federal and Proforma Processes Download',
  },
  eFileDownloadStepFile: {
    MAIN:
      '/api/efile/download-step-file/:ttiYearId/:ttiCaseId/:binderId/:submissionId/:stepName/:fileType/:fileExtension',
    compiledRoute: compile(
      '/api/efile/download-step-file/:ttiYearId/:ttiCaseId/:binderId/:submissionId/:stepName/:fileType/:fileExtension',
    ),
    displayMainName: () => 'Efile Download Step File',
  },
};

const proformaRoutes = {
  proforma: {
    MAIN: '/proforma',
    displayMainName: () => 'Pro-Forma',
  },
  proformaProcess: {
    MAIN: '/proforma/proforma-process',
    displayMainName: () => 'Pro-Forma Process',
    id: 'proforma-process',
  },
  proformaDownloadStepFile: {
    MAIN: `/api/proforma/download-step-file${CONTEXT_SUBPATH}/:ttiYearId/:ttiCaseId/:binderId/:stepName/:fileType/:fileExtension`,
    compiledRoute: compile(
      `/api/proforma/download-step-file${CONTEXT_SUBPATH}/:ttiYearId/:ttiCaseId/:binderId/:stepName/:fileType/:fileExtension`,
    ),
    displayMainName: () => 'Pro-Forma Process Download Step File',
  },
};

const taxSummariesRoutes = {
  taxSummaries: {
    MAIN: `${CONTEXT_SUBPATH}/tax-summaries/:taxName`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/tax-summaries/:taxName`),
    displayMainName: ({ params }) => taxSummariesMainNames[params.taxName] || '',
    displayMainNameStatic: () => 'Tax Summaries Main Names',
    id: 'tax-summaries',
  },
  taxRateDetails: {
    MAIN: `${CONTEXT_SUBPATH}/tax-summaries/:taxName/tax-rate-details`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/tax-summaries/:taxName/tax-rate-details`),
    displayMainName: () => 'Tax Rate Details',
    id: 'tax-rate-details',
  },
  genericDrillCategory: {
    MAIN: `${CONTEXT_SUBPATH}/tax-summaries/:taxName/category/:categoryId`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/tax-summaries/:taxName/category/:categoryId`),
    displayMainName: ({ params }) => genericCategoryMainNames[params.categoryId],
    displayMainNameStatic: () => 'Generic Drill Category',
    id: 'generic-drill-category',
  },
  genericDrillAccount: {
    MAIN: `${CONTEXT_SUBPATH}/tax-summaries/:taxName/category/:categoryId/account/:accountId`,
    compiledRoute: compile(
      `${CONTEXT_SUBPATH}/tax-summaries/:taxName/category/:categoryId/account/:accountId`,
    ),
    displayMainName: ({ params }) => params.accountId,
    displayMainNameStatic: () => 'Tax Summaries Category/Account',
    id: 'generic-drill-account',
  },
  genericDrillComponent: {
    MAIN: `${CONTEXT_SUBPATH}/tax-summaries/:taxName/category/:categoryId/account/:accountId/component/:componentId`,
    compiledRoute: compile(
      `${CONTEXT_SUBPATH}/tax-summaries/:taxName/category/:categoryId/account/:accountId/component/:componentId`,
    ),
    displayMainName: ({ params }) => params.accountId,
    displayMainNameStatic: () => 'Tax Summaries Categort/Account/Component',
    id: 'generic-drill-component',
  },
  apportionmentRateDetails: {
    MAIN: `${CONTEXT_SUBPATH}/tax-summaries/:taxName/apportionment-rate-details`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/tax-summaries/:taxName/apportionment-rate-details`),
    displayMainName: () => 'Apportionment Percentage',
    id: 'apportionment-rate-details',
  },
  propertySummary: {
    MAIN: `${CONTEXT_SUBPATH}/tax-summaries/:taxName/apportionment-rate-details/property-summary`,
    compiledRoute: compile(
      `${CONTEXT_SUBPATH}/tax-summaries/:taxName/apportionment-rate-details/property-summary`,
    ),
    displayMainName: () => 'Property Summary',
  },
};

const otherRoutes = {
  analytics: {
    MAIN: '/analytics',
    displayMainName: () => 'Analytics & Visualization',
  },
  manualCalculations: {
    MAIN: '/manual-calculations',
    displayMainName: () => 'Manual Calculations',
    id: 'manual-calculations',
  },
  binderMaintenance: {
    MAIN: '/binder-maintenance',
    displayMainName: () => 'Binder Maintenance',
    id: 'binder-maintenance',
  },
  returnMaintenance: {
    MAIN: '/return-maintenance/:returnId',
    compiledRoute: compile('/return-maintenance/:returnId'),
    displayMainName: () => 'Return Maintenance',
    id: 'return-maintenance',
  },
  customScreen: {
    MAIN: `${CONTEXT_SUBPATH}/:customScreenName`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/:customScreenName`),
    displayMainName: () => 'Custom Screen Name',
    id: 'custom-screen',
  },
  filingAttributes: {
    MAIN: `${CONTEXT_SUBPATH}/filing-attributes`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/filing-attributes`),
    displayMainName: () => 'Filing Attributes',
    id: 'filing-attributes',
  },
  filingDecisions: {
    MAIN: `${CONTEXT_SUBPATH}/filing-decisions`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/filing-decisions`),
    displayMainName: () => 'Filing Decisions',
    id: 'filing-decisions',
  },
  filingDecisionAmountDetails: {
    MAIN: `${CONTEXT_SUBPATH}/filing-decisions/amount-details/:categoryName`,
    displayMainName: () => 'Filing Decisions Amount Details',
    compiledRoute: compile(`${CONTEXT_SUBPATH}/filing-decisions/amount-details/:categoryName`),
  },
  filingDecisionsV2: {
    MAIN: `${CONTEXT_SUBPATH}/filing-decisions-v2`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/filing-decisions-v2`),
    displayMainName: () => 'Filing Decisions',
    id: 'filing-decisions-v2',
  },
  separateFilingDecisionsV2: {
    MAIN: `${CONTEXT_SUBPATH.replace(
      ':filingTypeId',
      ':filingTypeId(SEPARATE)',
    )}/filing-decisions-v2`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/filing-decisions-v2`),
    displayMainName: () => 'Filing Decisions',
    id: 'separate-filing-decisions-v2',
  },
  consolidatedFilingDecisionsV2: {
    MAIN: `${CONTEXT_SUBPATH.replace(
      ':filingTypeId',
      ':filingTypeId(CONSOLIDATED)',
    )}/filing-decisions-v2`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/filing-decisions-v2`),
    displayMainName: () => 'Filing Decisions',
    id: 'consolidated-filing-decisions-v2',
  },
  filingDecisionAmountDetailsV2: {
    MAIN: `${CONTEXT_SUBPATH}/setup-tax-returns/amount-details/:categoryName`,
    displayMainName: () => 'Tax Return Amount Details',
    compiledRoute: compile(`${CONTEXT_SUBPATH}/setup-tax-returns/amount-details/:categoryName`),
  },
  separateFederalProforma: {
    MAIN: `${CONTEXT_SUBPATH}/separate-federal-proforma`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/separate-federal-proforma`),
    displayMainName: () => 'Separate Federal Proforma',
    id: 'separate-federal-proforma',
  },
  federalProforma: {
    MAIN: `${CONTEXT_SUBPATH}/federal-proforma`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/federal-proforma`),
    displayMainName: () => 'Federal Proforma',
    id: 'federal-proforma',
  },
  federalAsFiled: {
    MAIN: `${CONTEXT_SUBPATH}/federal-as-filed`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/federal-as-filed`),
    displayMainName: () => 'Federal As Filed',
    id: 'federal-as-filed',
  },
  downloadFederalPdf: {
    MAIN: `/api/federal-proforma${CONTEXT_SUBPATH}/:sourceId`,
    compiledRoute: compile(`/api/federal-proforma${CONTEXT_SUBPATH}/:sourceId`),
    displayMainName: () => 'Download Federal PDF',
  },
  viewPrintTaxReturn: {
    MAIN: `${CONTEXT_SUBPATH}/view-print-tax-return`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/view-print-tax-return`),
    id: 'view-print-tax-return',
    displayMainName: () => 'View Print Tax Return',
  },
  flowThroughK1Details: {
    MAIN: `${CONTEXT_SUBPATH}/flow-through-k1-details`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/flow-through-k1-details`),
    id: 'flow-through-k1-details',
    displayMainName: () => 'Flow-through K-1 Details',
  },

  viewPrintAttachmentDetails: {
    MAIN: `${CONTEXT_SUBPATH}/view-print-attachment-details`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/view-print-attachment-details`),
    id: 'view-print-attachment-details',
    displayMainName: () => 'View/Print Attachment Details',
  },

  viewFormWorkpaper: {
    MAIN: `${CONTEXT_SUBPATH}/view-form-workpaper/:datasetDefId`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/view-form-workpaper/:datasetDefId`),
    id: 'tax-form-workpapers',
    displayMainName: () => 'Form Workpaper',
  },

  workpaperInstance: {
    MAIN: `${CONTEXT_SUBPATH}/view-form-workpaper/workpaper-instance/:parentDatasetDefinitionId/:datasetDefinitionId/:initialParentDatasetInstanceId`,
    compiledRoute: compile(
      `${CONTEXT_SUBPATH}/view-form-workpaper/workpaper-instance/:parentDatasetDefinitionId/:datasetDefinitionId/:initialParentDatasetInstanceId`,
    ),
    displayMainName: () => 'Workpaper Instance',
    id: 'workpaper-instance',
  },

  stateSpecificInformation: {
    MAIN: `${CONTEXT_SUBPATH}/state-specific-information`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/state-specific-information`),
    id: 'state-specific-information',
    displayMainName: () => 'State Specific Information',
  },

  stateSpecificInformationV2: {
    MAIN: `${CONTEXT_SUBPATH}/state-specific-information-v2`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/state-specific-information-v2`),
    id: 'state-specific-information-v2',
    displayMainName: () => 'State Specific Information',
  },

  stateCommonInformation: {
    MAIN: `${CONTEXT_SUBPATH}/state-common-information`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/state-common-information`),
    id: 'state-common-information',
    displayMainName: () => 'State Common Information',
  },

  memberInformationalData: {
    MAIN: `${CONTEXT_SUBPATH}/member-informational-data`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/member-informational-data`),
    id: 'member-informational-data',
    displayMainName: () => 'Member Informational Data',
  },

  globalMemberInformationalData: {
    MAIN: `${CONTEXT_SUBPATH}/global-member-informational-data`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/global-member-informational-data`),
    id: 'global-member-informational-data',
    displayMainName: () => 'Global Member Informational Data',
  },

  filingStates: {
    MAIN: `${CONTEXT_SUBPATH}/filing-states`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/filing-states`),
    id: 'entity-filing-states',
    displayMainName: () => 'Filing States',
  },
  logStats: {
    MAIN: '/api/log-stats',
    id: 'log-stats',
    displayMainName: () => 'Log Stats',
  },
  setupTaxReturns: {
    MAIN: `${CONTEXT_SUBPATH}/setup-tax-returns`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/setup-tax-returns`),
    displayMainName: () => 'Setup Tax Returns',
    id: 'setup-tax-returns',
  },
  memberReturns: {
    MAIN: `${CONTEXT_SUBPATH}/member-returns`,
    compiledRoute: compile(`${CONTEXT_SUBPATH}/member-returns`),
    displayMainName: () => 'Member Returns',
    id: 'member-returns',
  },
  returnStatusTracker: {
    MAIN: '/return-status-tracker',
    displayMainName: () => 'Return Status Tracker Details',
    id: 'return-status-tracker',
  },
  contentDeployment: {
    MAIN: '/content-deployment',
    displayMainName: () => 'Content Deployment',
  },
};

const homeRoute = {
  MAIN: '/',
  displayMainName: () => 'Home',
};

const authRoutes = {
  AUTH: '/auth',
  LOGOUT: '/logout',
  ACCESS_DENIED: '/access-denied',
};

const routes = {
  root: homeRoute,

  ...complianceWorkspaceRoutes,
  ...entityRoutes,
  ...consolidationRoutes,
  ...stateFilingGroupRoutes,
  ...federalProFormaFilingGroupRoutes,
  ...filingGroupRoutes,

  ...reportRoutes,

  ...adminRoutes,

  ...developmentRoutes,

  ...toolsRoutes,

  ...efileRoutes,

  ...proformaRoutes,

  ...taxSummariesRoutes,

  ...otherRoutes,
};

const returnWorkspaceRoutes = Object.values(WORKSPACE).reduce(
  (acc, { namespace, subPath }) => ({
    ...acc,
    [namespace]: {
      MAIN: subPath,
      displayMainName: () => 'Return Workspace',
      id: 'return-workspace',
    },
    [`${namespace}TaxSummaries`]: {
      MAIN: `${subPath}/tax-summaries/:taxName`,
      compiledRoute: compile(`${subPath}/tax-summaries/:taxName`),
      displayMainName: ({ params }) => taxSummariesMainNames[params.taxName] || '',
      displayMainNameStatic: () => 'Tax Summaries Main Names',
    },
    [`${namespace}AllReturnData`]: {
      MAIN: `${subPath}/tax-summaries/:taxName/all-return-data`,
      compiledRoute: compile(`${subPath}/tax-summaries/:taxName/all-return-data`),
      displayMainName: () => 'All Return Data',
    },
    [`${namespace}ViewPrintAttachmentDetails`]: {
      MAIN: `${subPath}/view-print-attachment-details`,
      displayMainName: () => 'View/Print Attachment Details',
      id: `${namespace}-view-print-attachment-details`,
    },
    [`${namespace}ViewFormWorkpaper`]: {
      MAIN: `${subPath}/view-form-workpaper/:datasetDefId`,
      compiledRoute: compile(`${subPath}/view-form-workpaper/:datasetDefId`),
      displayMainName: () => 'Form Workpaper',
      id: 'tax-form-workpapers',
    },
    [`${namespace}WorkpaperInstance`]: {
      MAIN: `${subPath}/view-form-workpaper/workpaper-instance/:parentDatasetDefinitionId/:datasetDefinitionId/:initialParentDatasetInstanceId`,
      compiledRoute: compile(
        `${subPath}/view-form-workpaper/workpaper-instance/:parentDatasetDefinitionId/:datasetDefinitionId/:initialParentDatasetInstanceId`,
      ),
      displayMainName: () => 'Workpaper Instance',
      id: 'workpaper-instance',
    },
    [`${namespace}TaxRateDetails`]: {
      MAIN: `${subPath}/tax-summaries/:taxName/tax-rate-details`,
      compiledRoute: compile(`${subPath}/tax-summaries/:taxName/tax-rate-details`),
      displayMainName: () => 'Tax Rate Details',
    },
    [`${namespace}ApportionmentRateDetails`]: {
      MAIN: `${subPath}/tax-summaries/:taxName/apportionment-rate-details`,
      compiledRoute: compile(`${subPath}/tax-summaries/:taxName/apportionment-rate-details`),
      displayMainName: () => 'Apportionment Percentage',
    },
    [`${namespace}PropertySummary`]: {
      MAIN: `${subPath}/tax-summaries/:taxName/apportionment-rate-details/property-summary`,
      compiledRoute: compile(
        `${subPath}/tax-summaries/:taxName/apportionment-rate-details/property-summary`,
      ),
      displayMainName: () => 'Property Summary',
    },

    [`${namespace}GenericCategory`]: {
      MAIN: `${subPath}/tax-summaries/:taxName/category/:categoryId`,
      compiledRoute: compile(`${subPath}/tax-summaries/:taxName/category/:categoryId`),
      displayMainName: ({ params }) => genericCategoryMainNames[params.categoryId],
      displayMainNameStatic: () => 'Generic Category Main Names Category',
      id: `return-workspace-generic-category`,
    },
    [`${namespace}GenericScreenDirectRoute`]: {
      MAIN: `${subPath}/tax-summaries/:taxName/category/:categoryId/account/:accountId`,
      compiledRoute: compile(
        `${subPath}/tax-summaries/:taxName/category/:categoryId/account/:accountId`,
      ),
      displayMainName: ({ params }) => genericCategoryMainNames[params.categoryId],
      displayMainNameStatic: () => 'Generic Category Main Names Category/Account',
      id: `navigator-generic-screen`,
    },
    [`${namespace}GenericCategoryFromApportionmentRateDetails`]: {
      MAIN: `${subPath}/tax-summaries/:taxName/apportionment-rate-details/category/:categoryId`,
      compiledRoute: compile(
        `${subPath}/tax-summaries/:taxName/apportionment-rate-details/category/:categoryId`,
      ),
      displayMainName: ({ params }) => genericCategoryMainNames[params.categoryId],
      displayMainNameStatic: () => 'Generic Category Main Names Apportionment Rate/Category',
    },
    [`${namespace}GenericCategoryFromPropertySummary`]: {
      MAIN: `${subPath}/tax-summaries/:taxName/apportionment-rate-details/property-summary/category/:categoryId`,
      compiledRoute: compile(
        `${subPath}/tax-summaries/:taxName/apportionment-rate-details/property-summary/category/:categoryId`,
      ),
      displayMainName: ({ params }) => genericCategoryMainNames[params.categoryId],
      displayMainNameStatic: () =>
        'Generic Category Main Names Apportionment Rate/Property Summary/Category',
    },
  }),
  {},
);

const allRoutes = { ...routes, ...returnWorkspaceRoutes };

const routesArray = Object.entries(allRoutes).map(([key, value]) => ({
  routeName: key,
  ...value,
}));

module.exports = {
  Routes: { ...authRoutes, ...allRoutes },
  routesArray,

  homeRoute,

  complianceWorkspaceRoutes,
  entityRoutes,
  consolidationRoutes,
  stateFilingGroupRoutes,
  federalProFormaFilingGroupRoutes,
  filingGroupRoutes,

  reportRoutes,

  adminRoutes,

  developmentRoutes,

  toolsRoutes,

  efileRoutes,

  proformaRoutes,

  taxSummariesRoutes,

  returnWorkspaceRoutes,

  otherRoutes,
};
