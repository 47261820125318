import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import GenericScreenDefinitionBreadcrumbRoutes from '../genericScreenDefinition/routeConfiguration/genericScreenDefinitionBreadcrumbRoutes.component';
import {
  BreadcrumbRoute,
  StaticHomeBreadcrumb,
} from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const DevelopmentBreadcrumbRoutes = () => {
  const { queryParams } = useUrlParams();
  const search = new URLSearchParams(queryParams).toString();

  return (
    <>
      <StaticHomeBreadcrumb routePath={Routes.root.MAIN} />
      <BreadcrumbRoute path={Routes.developmentTaxForms} />
      <BreadcrumbRoute path={Routes.developmentTaxFormsV2} />
      {/* // NOTE: SLT-14465 Turn Off E-file Element Mapping Tool
      <BreadcrumbRoute path={Routes.eFileElementMappings} /> */}
      <BreadcrumbRoute path={Routes.developmentTaxFormEditV2} />
      <BreadcrumbRoute search={search} path={Routes.developmentGenericFunctions} />
      <BreadcrumbRoute path={Routes.developmentGenericFunctionsAddEdit} />
      <BreadcrumbRoute path={Routes.developmentDataModels} />
      <GenericScreenDefinitionBreadcrumbRoutes />
      <BreadcrumbRoute path={Routes.developmentCalcSpecs} />
      <BreadcrumbRoute path={Routes.defaultBinders} />
      <BreadcrumbRoute path={Routes.attachmentDefinitions} />
      <BreadcrumbRoute path={Routes.jimJobsHistory} />
      <BreadcrumbRoute path={Routes.expressionBuilder} />
      <BreadcrumbRoute path={Routes.compileExpressions} />
      <BreadcrumbRoute path={Routes.watermarks} />
      <BreadcrumbRoute path={Routes.compileExpressionsReport} />
      <BreadcrumbRoute path={Routes.selectionLists} />
      <BreadcrumbRoute path={Routes.contentDeployment} />
      <BreadcrumbRoute path={Routes.calcPreferences} />
      <BreadcrumbRoute path={Routes.disallowOverrideReport} />
      <BreadcrumbRoute path={Routes.checkboxGroups} />
      <BreadcrumbRoute path={Routes.returnDefinitions} />
    </>
  );
};

export default DevelopmentBreadcrumbRoutes;
