import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import DataInfo from '../../../shared/displayComponents/dataInfo/dataInfo.component';
import Loading from '../../../shared/displayComponents/loading.component';

import getColumnDefinitions from './columnDefinitions';
import CustomHeader from './customHeader.component';

const HEADER_ROW_SIZE = 15;

const GridRenderer = ({ reportData, headerParams }) => {
  const columnDefinitions = useMemo(
    () => reportData && getColumnDefinitions(reportData.columnHeaders, headerParams),
    [reportData, headerParams],
  );
  if (reportData && reportData.columnHeaders.length > 1) {
    return (
      <AgGrid
        rowData={reportData.rows}
        isGridLoading={!reportData}
        columnDefs={columnDefinitions}
        components={{ agColumnHeader: CustomHeader }}
        headerHeight={headerParams.length * HEADER_ROW_SIZE}
      />
    );
  }
  if (reportData) {
    return <DataInfo textToRender="No Data" />;
  }
  return <Loading isLoading />;
};

GridRenderer.propTypes = {
  reportData: PropTypes.shape({
    columnHeaders: PropTypes.arrayOf(
      PropTypes.shape({
        groupDescription: PropTypes.string,
        id: PropTypes.string.isRequired,
        profileDescription: PropTypes.string.isRequired,
        stateCode: PropTypes.string.isRequired,
      }),
    ).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        isReportHeader: PropTypes.bool,
        rowName: PropTypes.string.isRequired,
        rowProperty: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  headerParams: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GridRenderer;
