import 'bootstrap/dist/css/bootstrap.css';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css';
import 'react-simple-dropdown/styles/Dropdown.css';

import '@tls/ui-spinner/lib/styles.css';
import '@tls/ui-search-bar/lib/styles.css';
import '@tls/ui-aggrid/lib/styles.css';
import '@tls/ui-select/lib/styles.css';
import '@tls/ui-modal/lib/styles.css';
import '@tls/ui-dropdown-menu/lib/styles.css';
import '@tls/ui-session-guard/lib/styles.css';

import '@pwc/appkit-react/style/appkit-react.default.css';
import './style/styles.scss';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import './shared/wdyr';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import AccessDenied from './shared/displayComponents/accessDenied.component';
import { LogoutPage } from './logoutPage';
import config from './config';
import ClientRoot from './clientRoot.component';
import Auth from './auth.component';

const App = () => (
  <BrowserRouter basename={config.BASE_PATH}>
    <Switch>
      <Route path={Routes.AUTH} component={Auth} />
      <Route path={Routes.LOGOUT} component={LogoutPage} />
      <Route path={Routes.ACCESS_DENIED} component={AccessDenied} />
      <Route path="/:clientId?" component={ClientRoot} />
    </Switch>
  </BrowserRouter>
);

export default App;
