import { createSelector } from 'reselect';
import { PERMISSION } from '@common-packages/customer-permissions-utils';

import hasRequiredPermissionsToView from '../authorization/hasRequiredPermissionsToView';

import { clientIdSelector } from './context';
import { optionsSelectorFactory } from './storeHelpers';
import { UserRoles } from '../enums/user';

export const taxYearSelector = ({ shared }) => shared.root.taxYear;

export const customerPermissionsSelector = ({ shared }) => shared.root.permissionsData.permissions;

export const customerRoleIdSelector = ({ shared }) => shared.root.permissionsData.roleId;

export const isEngagementAdminRoleSelector = ({ shared }) =>
  shared.root.permissionsData.roleName === UserRoles.ENGAGEMENT_ADMIN;

export const isFetchingPermissionsDataErrorSelector = ({ shared }) =>
  shared.root.isFetchingPermissionsDataError;

export const customerDataSelector = ({ shared }) => shared.root.customerData;

export const isFetchingCustomerDataErrorSelector = ({ shared }) =>
  shared.root.isFetchingCustomerDataError;

export const customerFeaturesSelector = createSelector(
  [customerDataSelector],
  customerData => customerData.features,
);

export const clientSelector = createSelector(
  [customerDataSelector, clientIdSelector],
  (customerData, clientId) => customerData.clients.find(({ id }) => id === clientId),
);

export const gtwClientKeySelector = createSelector(
  [clientSelector],
  client => client?.gtwClientKey,
);

export const isClientTtiAvailableSelector = createSelector(
  [clientSelector, taxYearSelector],
  (client, taxYear) => `${client?.taxYearTtiTransmitterActive}` === `${taxYear}`,
);

export const clientOptionsSelector = optionsSelectorFactory({
  listSelector: state => customerDataSelector(state).clients,
  labelKey: 'name',
  valueKey: 'id',
});

export const areAnyClientsAvailableSelector = createSelector(
  [clientOptionsSelector],
  clientOptions => clientOptions.length > 0,
);

export const hasPermissionToEfileRunSelector = createSelector(
  [customerPermissionsSelector],
  customerPermissions => hasRequiredPermissionsToView(customerPermissions, PERMISSION.EFILE_RUN),
);
