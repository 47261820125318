import {
  EFileErrorResponse,
  EFileGroupSelectionResponse,
  TtiCaseIdResponse,
  TtiYearIdResponse,
} from '@tls/slt-types';

import {
  GlobalContext,
  TtiBindersResponse,
  TaxYear,
  JurisdictionId,
  Period,
  SltBinderId,
} from '../../../../common/types';
import { httpGetAndParse } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from './index';

export interface EFileErrorsQueryParams {
  globalContext: GlobalContext;
}

export const useQueryEFileErrors = ({
  params: {
    globalContext: {
      params: { taxYear, period, jurisdictionId },
    },
  },
  enabled,
}: {
  params: EFileErrorsQueryParams;
  enabled: boolean;
}) =>
  useCustomQuery<EFileErrorResponse>(
    [QueryKeys.EFile.TransmissionErrors, { taxYear, period, jurisdictionId }],
    {
      url: `/api/efile/transmission-errors/${taxYear}/${period}/${jurisdictionId}`,
      enabled,
      initialData: [],
      errorMessage: 'Fetching eFile errors failed',
    },
  );

export interface EFileGroupSelectionParams {
  taxYear: TaxYear;
  period: Period;
}

export const useQueryEFileGroupSelection = ({
  params,
  enabled,
}: {
  params: EFileGroupSelectionParams;
  enabled: boolean;
}) =>
  useCustomQuery<EFileGroupSelectionResponse>([QueryKeys.EFile.GroupSelection, params], {
    url: `/api/efile/group-selection/${params.taxYear}/${params.period}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching eFile group selection filed',
  });

interface TtiCaseIdParams {
  taxYear: TaxYear | null;
  jurisdictionId: JurisdictionId | null;
}

export const useQueryTtiCaseId = ({
  params,
  enabled,
}: {
  params: TtiCaseIdParams;
  enabled: boolean;
}) =>
  useCustomQuery<TtiCaseIdResponse>([QueryKeys.EFile.TtiCaseId, params], {
    url: `/api/efile/case/${params.taxYear}/${params.jurisdictionId}`,
    enabled,
    defaultData: {
      ttiCaseId: null,
      ttiCaseName: null,
      stateCode: null,
    },
    errorMessage: 'Fetching case ID failed',
  });

interface TtiYearIdParams {
  taxYear: TaxYear | null;
  period: Period | null;
}

const fetchTtiYearId = ({ taxYear, period }: TtiYearIdParams) =>
  httpGetAndParse({
    route: `/api/efile/tti-year-id/${taxYear}/${period}`,
    parseResponseErrorMessage: true,
  }).then(({ ttiYearId }) => ttiYearId);

export const useQueryTtiYearId = ({
  params,
  enabled,
}: {
  params: TtiYearIdParams;
  enabled: boolean;
}) =>
  useCustomQuery<TtiYearIdResponse>([QueryKeys.EFile.TtiYearId, params], {
    enabled,
    defaultData: null,
    displayMessageFromError: true,
    customFetch: () => fetchTtiYearId(params),
  });

export const useQueryTtiBinders = ({
  params,
  enabled,
}: {
  params: GlobalContext & { sltBinderId: SltBinderId };
  enabled: boolean;
}) =>
  useCustomQuery<TtiBindersResponse[]>([QueryKeys.EFile.TtiBinders, params], {
    url: `/api/efile/binders/${params.apiRouteParamString}/${params.sltBinderId}`, // TODO: Tech debt - move efile binders routes to v2
    enabled,
    defaultData: [],
    errorMessage: 'Fetching efile binders failed',
  });
