import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import { selectJurisdiction } from '../shared/store/actions';
import {
  taxYearSelector,
  periodSelector,
  filingTypeIdSelector,
  entityIdSelector,
} from '../shared/store/selectors';

import { filingStatesSelector, isFetchingFilingStatesSelector } from './store/selectors';
import getColumnDefinitions from './filingStates.columnDefinitions';

function FilingStates({
  taxYear,
  period,
  entityId,
  filingTypeId,

  isFetchingFilingStates,
  filingStates,
  selectJurisdiction,
}) {
  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        taxYear,
        period,
        entityId,
        filingTypeId,
        selectJurisdiction,
      }),
    [taxYear, period, entityId, filingTypeId, selectJurisdiction],
  );

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={filingStates}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingFilingStates}
        />
      </div>
    </div>
  );
}

FilingStates.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  filingTypeId: PropTypes.string.isRequired,

  selectJurisdiction: PropTypes.func.isRequired,
  isFetchingFilingStates: PropTypes.bool.isRequired,
  filingStates: PropTypes.arrayOf(
    PropTypes.shape({
      lockedInd: PropTypes.bool.isRequired,
      filingState: PropTypes.string.isRequired,
      filingGroupDescription: PropTypes.string.isRequired,
      incomeTaxYn: PropTypes.bool.isRequired,
      franchiseTaxYn: PropTypes.bool.isRequired,
      incomeAmount: PropTypes.number,
      franchiseAmount: PropTypes.number,
      jurisdictionId: PropTypes.string.isRequired,
      jurisdictionDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    filingTypeId: filingTypeIdSelector(state),
    filingStates: filingStatesSelector(state),
    isFetchingFilingStates: isFetchingFilingStatesSelector(state),
  }),
  {
    selectJurisdiction,
  },
)(FilingStates);
