import { ColumnTypes } from '@common-packages/shared-constants';

import {
  TaxReturnDefinitionWithFilingAttributes,
  ReturnDefinitionFilingAttributes,
  FilingAttributesColumnBlueprint,
  ColumnInBlueprint,
} from '../../../../../common/types/apiShapes';
import { CalcPreferenceColumnBlueprint } from '../../../../../routes/shared/types';

import { getOptionsByColumnAndTaxReturnDefinition } from './getOptionsByColumnAndTaxReturnDefinition';

export const getOptionsByColumnGroupsAndTaxReturnDefinition = ({
  taxReturnsColumnsBlueprint,
  taxReturnsDefinition,
}: {
  taxReturnsColumnsBlueprint: (
    | FilingAttributesColumnBlueprint
    | CalcPreferenceColumnBlueprint
    | ColumnInBlueprint
  )[];
  taxReturnsDefinition?: TaxReturnDefinitionWithFilingAttributes;
}) =>
  taxReturnsColumnsBlueprint.reduce(
    (
      acc: ReturnDefinitionFilingAttributes,
      column: FilingAttributesColumnBlueprint | CalcPreferenceColumnBlueprint | ColumnInBlueprint,
    ) =>
      column.columnType === ColumnTypes.FILING_ATTRIBUTES && 'columnGroup' in column
        ? {
            ...acc,
            [column.columnGroup as string]: getOptionsByColumnAndTaxReturnDefinition({
              column,
              taxReturnsDefinition,
            }),
          }
        : acc,
    {} as ReturnDefinitionFilingAttributes,
  );
