import { connect } from 'react-redux';

import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import {
  jurisdictionDescriptionSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';

export default connect(
  state => ({
    label: 'Jurisdiction',
    value: jurisdictionDescriptionSelector(state),
    isBusy: isFetchingGlobalContextSelector(state),
  }),
  null,
)(GlobalContextValue);
