import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import saveAs from 'file-saver';

import PdfDisplay from '../pdfDisplay/pdfDisplay.component';
import { infoNotification } from '../../shared/notification/store/actions';
import { useQueryDownloadAttachment } from '../../shared/queries/attachments';

import style from './pdfDocumentModal.module.scss';

const PdfDocumentModal = ({ hideModal, visible, pdfImage, isFetchingPdfImage, modalData }) => {
  const { refetch: downloadAttachment } = useQueryDownloadAttachment(
    {
      link: modalData?.link,
      enabled: false,
    },
    [modalData],
  );
  const downloadPdf = useCallback(async () => {
    if (modalData && modalData.link) {
      const response = await downloadAttachment();
      if (!response.data) {
        return;
      }
      const data = new Blob([response.data]);
      saveAs(data, modalData.name);
    }
  }, [modalData, downloadAttachment]);

  return (
    <>
      {visible && (
        <div className={style.pdfDocumentModal}>
          <PdfDisplay
            pdfImage={pdfImage}
            isFetchingPdfImage={isFetchingPdfImage}
            onDownload={downloadPdf}
            closePdfViewer={hideModal}
            infoNotification={infoNotification}
          />
        </div>
      )}
    </>
  );
};

PdfDocumentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  isFetchingPdfImage: PropTypes.bool.isRequired,
  pdfImage: PropTypes.shape({
    size: PropTypes.number,
    type: PropTypes.string,
  }),
  modalData: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
};

export default PdfDocumentModal;
