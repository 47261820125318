import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import ReactJson from 'react-json-view';
import { efileSchemas } from '@common-packages/validators';

import { importAction, validateAction, IMPORT_ACTIONS } from './store/api';
import EfileForm from './efileForm.component';
import EfileValidationForm from './efileValidationForm.component';

import './efileConfiguration.style.scss';

const initialValues = {
  action: Object.keys(IMPORT_ACTIONS)[0],
  file: null,
};

const EFileConfiguration = () => {
  const [actionResult, setActionResult] = useState(null);
  const [validationResult, setValidationResult] = useState(null);
  const [validationAction, setValidationAction] = useState(initialValues.action);

  const renderEfileForm = useCallback(formikProps => <EfileForm {...formikProps} />, []);

  const renderEfileValidationForm = useCallback(
    formikProps => <EfileValidationForm {...formikProps} />,
    [],
  );

  const callAction = useCallback(async ({ file, action }, { setSubmitting }) => {
    try {
      setValidationResult(null);
      setActionResult(null);
      const result = await importAction({ file, action });
      setValidationAction(action);
      setActionResult(result);
    } catch (error) {
      setActionResult(error);
    }

    setSubmitting(false);
  }, []);

  const validateActionResult = useCallback(
    async ({ operationId }, { setSubmitting }) => {
      try {
        setValidationResult(null);
        const result = await validateAction({
          action: validationAction,
          operationId,
        });
        setValidationResult(result);
      } catch (error) {
        setValidationResult(error);
      }

      setSubmitting(false);
    },
    [validationAction],
  );

  return (
    <>
      <div className="efile-section">
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={efileSchemas.efileSchema}
          onSubmit={callAction}
        >
          {renderEfileForm}
        </Formik>
      </div>
      {Boolean(actionResult) && Boolean(actionResult.operationId) && (
        <div className="efile-section">
          <Formik
            initialValues={{
              operationId: actionResult.operationId.toString() || '',
            }}
            validateOnBlur={false}
            onSubmit={validateActionResult}
          >
            {renderEfileValidationForm}
          </Formik>
        </div>
      )}
      {Boolean(validationResult) && (
        <>
          <div className="efile-section">
            <h5>Status</h5>
            <ReactJson src={validationResult.status} />
          </div>
          <div className="efile-section">
            <h5>Results</h5>
            <ReactJson src={validationResult.result} />
          </div>
        </>
      )}
    </>
  );
};

export default EFileConfiguration;
