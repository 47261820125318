import { createMachine, assign, AnyEventObject } from 'xstate';

import { WIZARD_MACHINE_EVENTS, WIZARD_MACHINE_ID, WIZARD_MACHINE_STATES } from '../constants';
import { WizardStepName } from '../enums';

import { WizardStateMachineContext } from './types';
import handleEFileStepPayload from './handleEFileStepPayload';

export const wizardStateMachineInitialContext: WizardStateMachineContext = {
  stateReturn: {
    submissionId: null,
  },
  federalReturn: {
    submissionId: null,
    federalReturnOption: null,
  },
  eFileService: {
    submissionId: null,
  },
  ttiService: {
    ttiServiceRunningResult: null,
  },
  validation: {
    hasErrors: null,
    isFetching: null,
  },
  transmission: {
    status: null,
  },
  eFileSteps: {
    [WizardStepName.STEP_1_CHOOSE_FEDERAL_RETURN]: null,
    [WizardStepName.STEP_2_VALIDATE]: null,
    [WizardStepName.STEP_3_REVIEW]: null,
    [WizardStepName.STEP_4_AUTHORIZE_AND_TRANSMIT]: null,
    [WizardStepName.STEP_5_VIEW_STATUS]: null,
  },
};

const commonSteps = {
  // TODO: Investigate Warning: Event "SET_STEP_STATUS" was sent to stopped service "eFileWizardMachine". This service has already reached its final state, and will not transition.
  [WIZARD_MACHINE_EVENTS.SET_STEP_STATUS]: {
    actions: ['setEFileStep'],
  },
  [WIZARD_MACHINE_EVENTS.SET_STATE_SUBMISSION_ID]: {
    actions: ['setStateSubmissionId'],
  },
  [WIZARD_MACHINE_EVENTS.SET_EFILE_SERVICE_SUBMISSION_ID]: {
    actions: ['setEFileServiceSubmissionId'],
  },
  [WIZARD_MACHINE_EVENTS.SET_FEDERAL_RETURN_OPTION]: {
    actions: ['setFederalReturnOption'],
  },
  [WIZARD_MACHINE_EVENTS.SET_FEDERAL_SUBMISSION_ID]: {
    actions: ['setFederalSubmissionId'],
  },
  [WIZARD_MACHINE_EVENTS.SET_VALIDATION]: {
    actions: ['setValidation'],
  },
  [WIZARD_MACHINE_EVENTS.SET_IS_TTI_SERVICE_RUNNING_RESULT]: {
    actions: ['setTtiServiceRunningResult'],
  },
};

const wizardStateMachine = createMachine(
  {
    id: WIZARD_MACHINE_ID,
    initial: WIZARD_MACHINE_STATES.INITIALIZING,
    context: wizardStateMachineInitialContext,
    states: {
      [WIZARD_MACHINE_STATES.INITIALIZING]: {
        on: {
          ...commonSteps,
          [WIZARD_MACHINE_EVENTS.SET_INIT_EFILE_STEPS]: {
            actions: ['setInitEFileSteps'],
          },
          [WIZARD_MACHINE_EVENTS.START_WIZARD]: WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN,
          [WIZARD_MACHINE_EVENTS.SET_STEP_2]: WIZARD_MACHINE_STATES.STEP_2_VALIDATE,
          [WIZARD_MACHINE_EVENTS.SET_STEP_3]: WIZARD_MACHINE_STATES.STEP_3_REVIEW,
          [WIZARD_MACHINE_EVENTS.SET_STEP_4]: WIZARD_MACHINE_STATES.STEP_4_AUTHORIZE_AND_TRANSMIT,
          [WIZARD_MACHINE_EVENTS.SET_STEP_5]: WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS,
        },
      },
      [WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN]: {
        on: {
          ...commonSteps,
          [WIZARD_MACHINE_EVENTS.NEXT]: WIZARD_MACHINE_STATES.STEP_2_VALIDATE,
        },
      },
      [WIZARD_MACHINE_STATES.STEP_2_VALIDATE]: {
        on: {
          ...commonSteps,
          [WIZARD_MACHINE_EVENTS.NEXT]: WIZARD_MACHINE_STATES.STEP_3_REVIEW,
          [WIZARD_MACHINE_EVENTS.BACK]: WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN,
        },
      },
      [WIZARD_MACHINE_STATES.STEP_3_REVIEW]: {
        on: {
          ...commonSteps,
          [WIZARD_MACHINE_EVENTS.NEXT]: WIZARD_MACHINE_STATES.STEP_4_AUTHORIZE_AND_TRANSMIT,
          [WIZARD_MACHINE_EVENTS.BACK]: WIZARD_MACHINE_STATES.STEP_2_VALIDATE,
        },
      },
      [WIZARD_MACHINE_STATES.STEP_4_AUTHORIZE_AND_TRANSMIT]: {
        on: {
          ...commonSteps,
          [WIZARD_MACHINE_EVENTS.NEXT]: WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS,
          [WIZARD_MACHINE_EVENTS.BACK]: WIZARD_MACHINE_STATES.STEP_3_REVIEW,
        },
      },
      [WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS]: {
        on: {
          ...commonSteps,
          [WIZARD_MACHINE_EVENTS.SET_TRANSMISSION_STATUS]: {
            actions: ['setTransmissionStatus'],
          },
          [WIZARD_MACHINE_EVENTS.SET_STEP_1]: WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN,
          [WIZARD_MACHINE_EVENTS.SET_STEP_2]: WIZARD_MACHINE_STATES.STEP_2_VALIDATE,
        },
      },
    },
  },
  {
    actions: {
      setFederalReturnOption: assign({
        federalReturn: (context, event: AnyEventObject) => ({
          ...context.federalReturn,
          federalReturnOption: event.value,
        }),
      }),
      setFederalSubmissionId: assign({
        federalReturn: (context, event: AnyEventObject) => ({
          ...context.federalReturn,
          submissionId: event.value,
        }),
      }),
      setStateSubmissionId: assign({
        stateReturn: (context, event: AnyEventObject) => ({
          ...context.stateReturn,
          submissionId: event.value,
        }),
      }),
      setEFileServiceSubmissionId: assign({
        eFileService: (context, event: AnyEventObject) => ({
          ...context.eFileService,
          submissionId: event.value,
        }),
      }),
      setInitEFileSteps: assign({
        eFileSteps: (_, event: AnyEventObject) => event.value,
      }),
      setValidation: assign({
        validation: (_, event: AnyEventObject) => event.value,
      }),
      setEFileStep: assign((context: WizardStateMachineContext, event: AnyEventObject) => {
        const updatedContext: WizardStateMachineContext = {
          ...context,
          eFileSteps: {
            ...context.eFileSteps,
            [event.value.stepName]: event.value.status,
          },
        };
        return handleEFileStepPayload(event.value.payload, updatedContext);
      }),
      setTransmissionStatus: assign({
        transmission: (context, event: AnyEventObject) => ({
          ...context.transmission,
          status: event.value,
        }),
      }),
      setTtiServiceRunningResult: assign({
        ttiService: (context, event: AnyEventObject) => {
          return {
            ...context.ttiService,
            ttiServiceRunningResult: event.value,
          };
        },
      }),
    },
  },
);

export default wizardStateMachine;
