import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { DEFAULT_PROFILE } from '@common-packages/shared-constants';
import { Checkbox } from '@pwc/appkit-react';

import FilingAttributes from '../../shared/forms/filingAttributes/filingAttributesFilingDecisions.component';
import Loading from '../../shared/displayComponents/loading.component';
import CalcPreferences from '../../shared/forms/calcPreferences/calcPreferences.component';
import { calcPreferencesPropTypes } from '../../shared/forms/calcPreferences/propTypes';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import CheckboxFormik from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';

import styles from './editFilingDecisionsForm.module.scss';

const CALC_OPTIONS_KEY = 'calcOptions';

const EditFilingDecisionsForm = ({
  filingAttributes,
  calcPreferences,
  isSavingFilingDecisions,
  title = '',
}) => {
  const {
    setFieldValue,
    isSubmitting,
    values,
    initialValues,
    submitForm,
    resetForm,
    dirty,
    isValid,
  } = useFormikContext();

  const isSaveDisabled = !dirty || !isValid || isSavingFilingDecisions;
  const isLoading = isSubmitting || isSavingFilingDecisions;
  const commonCalcPreferences = calcPreferences[0];
  const stateCalcPreferences = calcPreferences[1];

  const onCancelClick = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const handleFilingInStChange = useCallback(
    ({ target }) => {
      if (target.checked === false) {
        setFieldValue('profileId', DEFAULT_PROFILE);
        setFieldValue('nexusInd', false);
      }
      setFieldValue('filingInState', target.checked);
    },
    [setFieldValue],
  );

  return (
    <form className={styles.filingDecisionsForm}>
      <div className={styles.stickyHeader}>
        <div className={styles.filingDecisionsFormHeader}>
          <p className={styles.filingDecisionsFormTitle}>Edit Filing Decisions</p>
          <ButtonsGroup
            className={styles.filingDecisionsButtons}
            disabled={isSaveDisabled}
            fullWidth={false}
            isSubmitting={isSubmitting}
            onCancelClick={onCancelClick}
            submitButtonLabel="Save"
            submitForm={submitForm}
          />
        </div>
        <hr />
      </div>
      {title && (
        <div className="row">
          <div className="col">
            <div className="a-h5">{title}</div>
          </div>
        </div>
      )}
      <div className="row">
        <div className={styles.filingDecisionsColumn}>
          <Field
            label="Profile"
            name="profileId"
            value={values.profileId}
            component={Input}
            disabled
          />
          <Field
            label="State Filing Group(s)"
            name="filingGroupsIds"
            value={values.filingGroupsIds || ''}
            component={Input}
            disabled
          />
          <div className={styles.filingDecisionsCheckbox}>
            <Checkbox
              checked={values.filingInState}
              onChange={handleFilingInStChange}
              disabled={Boolean(values.filingGroupsIds)}
            >
              Filing In State
            </Checkbox>
          </div>
          <Field
            className={styles.filingDecisionsCheckbox}
            label="Nexus"
            name="nexusInd"
            value={values.nexusInd}
            component={CheckboxFormik}
            disabled={!values.filingInState}
          />
        </div>
        <div className={styles.filingAttributes}>
          <FilingAttributes
            filingAttributes={filingAttributes}
            isLoading={isLoading}
            isColumnAligned
            values={values}
          />
        </div>
        <div className={`${styles.calcPreferences} ${styles.dynamicFormWithoutExcess}`}>
          <Loading isLoading={!calcPreferences} small>
            <>
              <CalcPreferences
                calcPreferences={commonCalcPreferences}
                initialValues={initialValues}
                values={values}
                title="Common Calc Preferences"
                calcOptionsKey={CALC_OPTIONS_KEY}
              />
              <CalcPreferences
                calcPreferences={stateCalcPreferences}
                initialValues={initialValues}
                values={values}
                title="State Calc Preferences"
                calcOptionsKey={CALC_OPTIONS_KEY}
              />
            </>
          </Loading>
        </div>
      </div>
    </form>
  );
};

EditFilingDecisionsForm.propTypes = {
  title: PropTypes.string,
  filingAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  calcPreferences: calcPreferencesPropTypes.isRequired,
  isSavingFilingDecisions: PropTypes.bool.isRequired,
};

export default EditFilingDecisionsForm;
