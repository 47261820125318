import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FilingAttributes from '../shared/forms/filingAttributes/filingAttributes.component';
import CalcPreferences from '../shared/forms/calcPreferences/calcPreferences.component';
import { calcPreferencesPropTypes } from '../shared/forms/calcPreferences/propTypes';
import ButtonsGroup from '../shared/forms/buttonsGroup/buttonsGroup.component';
import LoadingSpinner from '../shared/forms/loadingSpinner/loadingSpinner.component';

const EditFilingAttributesForm = ({
  filingAttributes,
  calcPreferences,
  isSavingFilingAttributes,

  isSubmitting,
  submitForm,
  resetForm,
  dirty,
}) => {
  const isLoading = isSubmitting || isSavingFilingAttributes;
  const renderFilingAttributes = useCallback(
    () => <FilingAttributes filingAttributes={filingAttributes} isLoading={isLoading} />,
    [filingAttributes, isLoading],
  );

  const renderCalcPreferences = useCallback(
    () => <CalcPreferences calcPreferences={calcPreferences} />,
    [calcPreferences],
  );

  const onCancelClick = useCallback(() => {
    resetForm();
  }, [resetForm]);

  return (
    <form>
      <LoadingSpinner isLoading={isLoading} />
      <div className="row">
        <div className="col">
          {renderFilingAttributes()}
          {renderCalcPreferences()}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ButtonsGroup
            disabled={!dirty || isSavingFilingAttributes}
            fullWidth={false}
            isSubmitting={isSubmitting}
            onCancelClick={onCancelClick}
            submitButtonLabel="Save"
            submitForm={submitForm}
          />
        </div>
      </div>
    </form>
  );
};

EditFilingAttributesForm.propTypes = {
  filingAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  calcPreferences: calcPreferencesPropTypes.isRequired,
  isSavingFilingAttributes: PropTypes.bool.isRequired,

  isSubmitting: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
};

export default EditFilingAttributesForm;
