import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { fetchCalcData } from '../../../../shared/store/allReturnData/actions';
import {
  calcDataSelector,
  isFetchingCalcDataSelector,
} from '../../../../shared/store/allReturnData/selectors';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
} from '../../../../shared/store/selectors';
import SelectContextDataInfo from '../../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';

import columnDefinitions from './calcData.columnDefinitions';

const CalcData = () => {
  const dispatch = useDispatch();

  const globalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);

  const calcData = useSelector(calcDataSelector);
  const isFetchingCalcData = useSelector(isFetchingCalcDataSelector);

  const isLoading = isFetchingGlobalContext || isFetchingCalcData;

  useEffect(() => {
    if (globalContext.isReady) {
      dispatch(fetchCalcData(globalContext));
    }
  }, [dispatch, globalContext]);

  if (!isLoading && !globalContext.isReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <AgGrid
      rowData={calcData}
      columnDefs={columnDefinitions}
      isGridLoading={isLoading}
      withSearchBar
    />
  );
};

export default CalcData;
