const apiActionFactory = ({ successNotification, errorNotification }) => {
  const requestIdentifierState = {};

  return (
    {
      types,
      apiAction,
      isFetchingSelector = null,
      throwApiErrors = false,
      successNotificationCloseable = false,
      errorNotificationCloseable = false,
      suppressOutdatedResponses = true,
      suppressErrorNotificationWhenFetchingError = false,
    },
    {
      successNotificationMessage = ({ message }) => message,
      errorNotificationMessage = ({ message }) => message,
    } = {},
    // ************** WARNING **************
    // please please please don't use the knownRequestIdentifier outside of apiActionFactory.test.js
    // it's just something to make it easier to test a race condition
    // should not  be used in production
    // ************** WARNING **************
  ) => (...params) => async (dispatch, getState, knownRequestIdentifier) => {
    const requestIdentifier = knownRequestIdentifier || Date.now();
    const setRequestIdentifier = () => {
      if (suppressOutdatedResponses) {
        requestIdentifierState[types.START] = requestIdentifier;
      }
    };
    const clearRequestIdentifier = () => {
      if (suppressOutdatedResponses) {
        delete requestIdentifierState[types.START];
      }
    };
    const shouldSuppressOutdatedResponses = () =>
      suppressOutdatedResponses && requestIdentifierState[types.START] !== requestIdentifier;

    try {
      if (isFetchingSelector && isFetchingSelector(getState(), ...params)) {
        return;
      }

      // SUPERSTATE WARNING - setting request identifier to prevent success dispatch in the future
      setRequestIdentifier();
      dispatch({
        type: types.START,
        payload: params,
      });

      const result = await apiAction(...params);

      // SUPERSTATE WARNING - checking superstate if this request is the most recent
      if (shouldSuppressOutdatedResponses()) {
        console.warn(`${types.SUCCESS} action ignored due to newer request being in progress`); // eslint-disable-line no-console
        return;
      }

      // SUPERSTATE WARNING - clearing to avoid bloating memory with actions
      clearRequestIdentifier();

      dispatch({
        type: types.SUCCESS,
        payload: result,
        params,
      });

      const successMessage = successNotificationMessage(result);
      if (successMessage) {
        dispatch(successNotification(successMessage, { closeable: successNotificationCloseable }));
      }

      return result;
    } catch (error) {
      if (error.isAuthorizationError) {
        // SUPERSTATE WARNING - clearing to avoid bloating memory with actions
        clearRequestIdentifier();
        return;
      }

      // SUPERSTATE WARNING - checking superstate if this request is the most recent
      if (shouldSuppressOutdatedResponses()) {
        // SUPERSTATE WARNING - clearing to avoid bloating memory with actions
        clearRequestIdentifier();
        console.warn(`${types.FAILURE} action ignored due to newer request being in progress`); // eslint-disable-line no-console
        return;
      }

      const errorMessage = errorNotificationMessage(error);

      if (errorMessage) {
        dispatch(errorNotification(errorMessage, { closeable: errorNotificationCloseable }));
      }

      dispatch({
        type: types.FAILURE,
        payload: params,
      });

      if (error.isFetchingError && !suppressErrorNotificationWhenFetchingError) {
        dispatch(
          errorNotification(errorMessage || error.message, {
            closeable: errorNotificationCloseable,
          }),
        );
        if (!throwApiErrors) {
          return;
        }

        throw error;
      }
      throw error;
    }
  };
};

export default apiActionFactory;
