const Yup = require('yup');

const { noSpecialChar } = require('../validationRegex');

const commonSchema = Yup.object().shape({
  noteMessage: Yup.string()
    .label('Note')
    .matches(noSpecialChar.regex, noSpecialChar.message)
    .required(),
});

const createNoteSchema = commonSchema
  .concat(
    Yup.object().shape({
      accountGroup: Yup.string()
        .label('Account Group')
        .matches(noSpecialChar.regex, noSpecialChar.message)
        .required(),
      taxYear: Yup.string().required(),
      period: Yup.string().required(),
      entityId: Yup.string().required(),
    }),
  )
  .strict();

module.exports = {
  commonSchema,
  createNoteSchema,
};
