import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { SelectOptionPropTypes } from '../../../shared/propTypes/selectOption';
import { useCellEditorSelectWithFetch } from '../../../shared/hooks/useCellEditorSelectWithFetch';

import VirtualizedSelect from './virtualizedSelect.component';

// eslint-disable-next-line no-empty-function
const noop = () => {};

const VirtualizedSelectWithOptionsFetchFactory = ({ fetchMethod }) => {
  const handleOptionsFetching = useCallback(() => fetchMethod(), [fetchMethod]);

  const VirtualizedSelectWithOptionsFetch = ({
    value,
    stopEditing = noop,
    data,
    onCellValueChanged = noop,
    colDef,
    api,
    options,
    defaultErrorMessage,
    forwardedRef,
  }) => {
    const { setSelectedValue, fetchedOptions, isFetching, inputRef } = useCellEditorSelectWithFetch(
      {
        handleOptionsFetching,
        stopEditing,
        onCellValueChanged,
        defaultErrorMessage,
        ref: forwardedRef,
        api,
        colDef,
        value,
        data,
        mutateData: true,
        options,
      },
    );

    return (
      <VirtualizedSelect
        initialSelectedValue={value}
        options={options || fetchedOptions || []}
        setSelectedValue={setSelectedValue}
        isLoading={isFetching}
        inputRef={inputRef}
      />
    );
  };

  VirtualizedSelectWithOptionsFetch.propTypes = {
    onCellValueChanged: PropTypes.func,
    colDef: PropTypes.shape({ field: PropTypes.string.isRequired }).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(SelectOptionPropTypes),
    api: PropTypes.object.isRequired,

    taxYear: PropTypes.string,
    period: PropTypes.string,
    dataType: PropTypes.string,

    stopEditing: PropTypes.func,
    defaultErrorMessage: PropTypes.string,
    forwardedRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
  };

  return VirtualizedSelectWithOptionsFetch;
};

export default VirtualizedSelectWithOptionsFetchFactory;
