import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { errorNotification } from '../../notification/store/actions';

import styles from './dropzone.module.scss';

const loadFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(file);
  });

const noop = () => {}; // eslint-disable-line no-empty-function

const showExtensionErrorNotification = () =>
  errorNotification('File extension does not match chosen file type', { closeable: true });

const showGeneralErrorNotification = () =>
  errorNotification('File has been rejected', { closeable: true });

const showValidationErrorNotification = msg => errorNotification(msg, { closeable: true });

const CustomDropzone = ({
  value: file,
  onFileBufferLoaded = noop,
  onDropCallback = noop,
  validateFile = () => ({ valid: true, msg: null }),
  disabled = false,
  acceptTypes = [],
  existingFileName = null,
  className = '',
}) => {
  const dispatch = useDispatch();
  const onDropAccepted = useCallback(
    acceptedFiles => {
      const acceptedFile = acceptedFiles[0];

      const validationResult = validateFile(acceptedFile);
      if (validationResult.valid) {
        loadFile(acceptedFile).then(acceptedFile => onFileBufferLoaded(acceptedFile));
        onDropCallback(acceptedFile);
      } else {
        dispatch(showValidationErrorNotification(validationResult.msg));
      }
    },
    [onFileBufferLoaded, onDropCallback, validateFile, dispatch],
  );

  const onDropRejected = useCallback(
    fileRejections => {
      if (fileRejections[0].errors[0].code === 'file-invalid-type') {
        dispatch(showExtensionErrorNotification());
      } else {
        dispatch(showGeneralErrorNotification());
      }
    },
    [dispatch],
  );

  return (
    <Dropzone
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      disabled={disabled}
      accept={acceptTypes}
      multiple={false}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
        <div
          {...getRootProps({
            className: classNames(styles.dropzone, className, {
              'dropzone--active': isDragActive,
              'dropzone--accept': isDragAccept,
              'dropzone--reject': isDragReject,
              [styles.dropzoneEmptyFile]: file && !file.size,
            }),
          })}
        >
          <input {...getInputProps()} />
          <span className={classNames(styles.chooseFileButton, { [styles.disabled]: disabled })}>
            {file
              ? (file.size && file.path) ||
                `The attached file "${file.path}" is empty, please provide another one.`
              : existingFileName || "Drag 'n' drop a file here, or click to select it"}
          </span>
        </div>
      )}
    </Dropzone>
  );
};

CustomDropzone.propTypes = {
  value: PropTypes.instanceOf(File),
  onFileBufferLoaded: PropTypes.func,
  onDropCallback: PropTypes.func,
  validateFile: PropTypes.func,
  disabled: PropTypes.bool,
  acceptTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  existingFileName: PropTypes.string,
  className: PropTypes.string,
};

export default CustomDropzone;
