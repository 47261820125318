import saveAs from 'file-saver';
import debounce from 'lodash.debounce';

import store from '../store';
import { errorNotification } from '../shared/notification/store/actions';

import { httpGet, httpPost } from './fetchService';
import getFileNameFromContentDisposition from './getFileNameFromContentDisposition';

type DownloadFileParams = {
  route: string;
  fileLocator?: string | null | undefined;
  fileName?: string | null;
};

const downloadFile = debounce(
  async ({ route, fileLocator, fileName: customFileName }: DownloadFileParams) => {
    try {
      const response: Response | undefined =
        fileLocator && fileLocator !== ''
          ? await httpPost({
              route: `/api/efile-v2/download-blob-file`,
              errorMessage: 'Failed to fetch file',
              body: {
                fileLocator,
              },
            })
          : await httpGet({
              route,
              errorMessage: 'Failed to fetch file',
              body: null,
            });

      const blob = await response?.blob();
      const fileName =
        customFileName ||
        getFileNameFromContentDisposition(response?.headers.get('content-disposition')) ||
        '';

      if (!fileName || !blob) {
        throw new Error('File not found');
      }

      saveAs(blob, fileName);
    } catch (error) {
      error instanceof Error
        ? store.dispatch(errorNotification(error.message))
        : store.dispatch(errorNotification('Failed to download file'));
    }
  },
  500,
);

export default downloadFile;
