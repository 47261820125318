import { apiAction } from '../apiAction';
import { filingTypes } from '../../../shared/constants';

import {
  fetchFormDataTypes,
  downloadFormPdfTypes,
  RESET_FORM_DATA,
  SELECT_MEMBER_OR_FORM_INSTANCE_ID,
} from './types';
import * as api from './api';

export const fetchFormDataFactory = ({
  populateFormsAndFetchByIdApiAction,
  fetchFieldsDataApiAction,
}) => params => async (dispatch, getState) => {
  const fetchFormDataAction = async ({
    formId,
    taxYear,
    period,
    entityId,
    jurisdictionId,
    profileId,
    formInstanceId,
    swpFormId,
    filingTypeId,
    consolidationId,
  }) => {
    // this action populates forms on the backend
    // we need to call it before calling other forms related endpoints
    // to avoid race conditions and getting old data from before population
    const formData = await populateFormsAndFetchByIdApiAction({
      taxYear,
      period,
      entityId,
      jurisdictionId,
      formId,
      swpFormId,
      filingTypeId,
      consolidationId,
    });

    const fieldsData = await fetchFieldsDataApiAction({
      taxYear,
      formId,
      period,
      entityId,
      formInstanceId,
      jurisdictionId,
      profileId,
      ...(filingTypeId === filingTypes.CONSOLIDATED ? { consolidationId } : null),
    });

    return {
      form: formData?.form || null,
      pagesNumber: formData?.pagesNumber || null,
      fieldsData,
    };
  };

  try {
    await apiAction({
      throwApiErrors: true,
      types: fetchFormDataTypes,
      apiAction: fetchFormDataAction,
    })(params)(dispatch, getState);
  } catch (error) {
    if (!error.isFetchingError) {
      throw error;
    }
  }
};

export const fetchFormData = fetchFormDataFactory({
  populateFormsAndFetchByIdApiAction: api.populateFormsAndFetchById,
  fetchFieldsDataApiAction: api.fetchFieldsData,
});

export const downloadFormPdf = apiAction({
  types: downloadFormPdfTypes,
  apiAction: api.downloadFormPdf,
});

export const resetFormData = () => ({
  type: RESET_FORM_DATA,
});

export const selectMemberOrFormInstanceId = memberOrFormInstanceId => ({
  type: SELECT_MEMBER_OR_FORM_INSTANCE_ID,
  payload: memberOrFormInstanceId,
});
