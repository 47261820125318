import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import ServerSideAgGrid, { useGridServerSideDataSource } from '@tls/ui-server-side-aggrid';

import { columnDefinitions, columnDefinitionsWithError } from './importDataGrid.columnDefinitions';
import styles from './importDataGrid.module.scss';

const rowClassRules = {
  'import-grid__row--error': ({ data }) => data?.errorMessage,
};

const ImportDataGrid = ({ fetchLastImportDataPage, showErrorColumn = false }) => {
  const columnDefs = useMemo(
    () => (showErrorColumn ? columnDefinitionsWithError : columnDefinitions),
    [showErrorColumn],
  );

  const { onGridReady } = useGridServerSideDataSource({
    fetchData: async ({ offset, sortModel }) => {
      const { data } = await fetchLastImportDataPage({
        offset,
        sort: sortModel,
      });
      return data;
    },
  });

  const onServerSideGridReady = useCallback(
    params => {
      onGridReady(params);
    },
    [onGridReady],
  );

  return (
    <div className="row grid-row">
      <div className={`col ${styles.importGrid}`}>
        <ServerSideAgGrid
          columnDefs={columnDefs}
          onGridReady={onServerSideGridReady}
          rowClassRules={rowClassRules}
        />
      </div>
    </div>
  );
};

ImportDataGrid.propTypes = {
  fetchLastImportDataPage: PropTypes.func.isRequired,
  showErrorColumn: PropTypes.bool,
};

export default memo(ImportDataGrid);
