import { StateValue } from 'xstate';

import { FederalReturnOption } from '../../../shared/enums/eFileV2';
import { WIZARD_MACHINE_STATES } from '../constants';
import { WizardStepName, StepStatus } from '../enums';
import isRecheckStatusButtonDisabled from '../utils/isRecheckStatusButtonDisabled';
import { WizardStateMachineContext } from '../wizardStateMachine/types';
import { TaxReturn, TtiGroupValidation } from '../../../shared/queries/taxReturns';

import useRetrieveAcknowledgementButton from './useRetrieveAcknowledgementButton';

interface useDisableUseWizardConditionProps {
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
  isFetchingCheckTtiServiceRunning: boolean;
  taxReturn: TaxReturn | undefined;
  machineState: { value: StateValue; context: WizardStateMachineContext };
  ttiGroupValidationData: TtiGroupValidation | null;
  isCurrentSubmissionSelected: boolean;
  isRunValidationOrParamsNotReady: boolean;
  isCheckingTransmissionStatus: boolean;
  selectedTransmissionHistoryId: string;
  handleRecheckStatus: () => Promise<void>;
  isUpdatingFederalReturnOption: boolean;
  isCurrentStepReady: boolean;
  isTransmitReturnsButtonEnabled: boolean;
  isValidationReRunRequired: boolean | null;
  ttiYearId: string | undefined;
  ttiCaseId: string | undefined | null;
}
interface IValidateIsTTIReadOnlyParams {
  isLoading?: boolean;
  originalValue: boolean | null | undefined;
}

const useCheckWizardConditions = ({
  isFetchingTaxReturnOrTransmitterProviderTR,
  isFetchingCheckTtiServiceRunning,
  taxReturn,
  machineState,
  isRunValidationOrParamsNotReady,
  ttiGroupValidationData,
  isCurrentSubmissionSelected,
  selectedTransmissionHistoryId,
  isCheckingTransmissionStatus,
  isUpdatingFederalReturnOption,
  isValidationReRunRequired,
  isCurrentStepReady,
  isTransmitReturnsButtonEnabled,
  ttiCaseId,
  ttiYearId,
  handleRecheckStatus,
}: useDisableUseWizardConditionProps) => {
  const validateIsTTIReadOnly = ({
    isLoading = false,
    originalValue,
  }: IValidateIsTTIReadOnlyParams) => {
    const isTTIReadOnly = Boolean(taxReturn?.isTTIReadOnly);

    if (isLoading) {
      return !isTTIReadOnly && !isFetchingTaxReturnOrTransmitterProviderTR ? originalValue : false;
    }

    return isTTIReadOnly && !isFetchingTaxReturnOrTransmitterProviderTR
      ? isTTIReadOnly
      : originalValue;
  };

  const isRunValidationButtonLoading =
    (!isFetchingTaxReturnOrTransmitterProviderTR && isFetchingCheckTtiServiceRunning) ||
    machineState.context.eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.INITIATED ||
    isRunValidationOrParamsNotReady ||
    machineState.context.validation.isFetching;

  const isRunValidationButtonDisabled =
    isRunValidationButtonLoading ||
    (!isFetchingTaxReturnOrTransmitterProviderTR &&
      ttiGroupValidationData &&
      !ttiGroupValidationData?.isTtiGroupValid);

  const isRecheckStatusButtonVisible =
    machineState.value === WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS;

  const areButtonsRecheckingStatusDisabled = isRecheckStatusButtonDisabled({
    stepStatus: machineState.context.eFileSteps[WizardStepName.STEP_5_VIEW_STATUS],
    selectedTransmissionHistoryId,
    isCurrentSubmissionSelected,
    isCheckingTransmissionStatus,
  });

  const isRetrieveAcknowledgementButtonDisabled = Boolean(
    useRetrieveAcknowledgementButton({
      machineStateValue: machineState.value,
      machineStateContext: machineState.context,
      handleRecheckStatus,
      isFetchingCheckTtiServiceRunning,
      areButtonsRecheckingStatusDisabled,
    }),
  );
  const isBackButtonDisabled = Boolean(
    isUpdatingFederalReturnOption ||
      machineState.context.eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.INITIATED,
  );

  const isNextButtonLoadingForStep1 =
    machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN &&
    !isFetchingTaxReturnOrTransmitterProviderTR &&
    (isFetchingCheckTtiServiceRunning || isUpdatingFederalReturnOption || !ttiCaseId || !ttiYearId);

  const isNextButtonDisableForStep1 =
    machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN &&
    machineState.context.federalReturn.federalReturnOption ===
      FederalReturnOption.FED_MANUAL_PROFORMA &&
    !machineState.context.federalReturn.submissionId;

  const isNextButtonDisableStep2 =
    machineState.context.eFileSteps[WizardStepName.STEP_2_VALIDATE] !== StepStatus.COMPLETED ||
    (!isFetchingTaxReturnOrTransmitterProviderTR &&
      ttiGroupValidationData &&
      !ttiGroupValidationData?.isTtiGroupValid) ||
    isValidationReRunRequired;

  const isTransmitButtonDisabled =
    !isCurrentStepReady ||
    machineState.context.eFileSteps[WizardStepName.STEP_4_AUTHORIZE_AND_TRANSMIT] ===
      StepStatus.INITIATED ||
    !isTransmitReturnsButtonEnabled;

  const isViewDownloadsButtonDisabled =
    machineState.context.eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.INITIATED ||
    (!machineState.context.federalReturn.submissionId &&
      !machineState.context.eFileService.submissionId);

  return {
    isViewDownloadsButtonDisabled,
    isRunValidationButtonLoading: validateIsTTIReadOnly({
      isLoading: true,
      originalValue: isRunValidationButtonLoading,
    }),
    isRunValidationButtonDisabled: validateIsTTIReadOnly({
      originalValue: isRunValidationButtonDisabled,
    }),
    isRecheckStatusButtonVisible: validateIsTTIReadOnly({
      originalValue: isRecheckStatusButtonVisible,
    }),
    areButtonsRecheckingStatusDisabled: validateIsTTIReadOnly({
      originalValue: areButtonsRecheckingStatusDisabled,
    }),
    isRetrieveAcknowledgementButtonDisabled,
    isBackButtonDisabled: validateIsTTIReadOnly({
      originalValue: isBackButtonDisabled,
    }),
    isNextButtonLoadingForStep1: validateIsTTIReadOnly({
      isLoading: true,
      originalValue: isNextButtonLoadingForStep1,
    }),
    isNextButtonDisableForStep1: validateIsTTIReadOnly({
      originalValue: isNextButtonDisableForStep1,
    }),
    isNextButtonDisableStep2: validateIsTTIReadOnly({
      originalValue: isNextButtonDisableStep2,
    }),
    isTransmitButtonDisabled: validateIsTTIReadOnly({
      originalValue: isTransmitButtonDisabled,
    }),
  };
};

export default useCheckWizardConditions;
