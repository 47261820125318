import {
  percentageColumnDefinition,
  currencyColumnDefinition,
  defaultColumnDefinition,
} from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Start Range',
    field: 'taxBracketStart',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'End Range',
    field: 'taxBracketEnd',
  },
  {
    ...defaultColumnDefinition,
    ...percentageColumnDefinition,
    headerName: 'Tax Rate',
    field: 'taxRate',
  },
];

export default columnDefinitions;
