import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../../shared/displayComponents/paramDropdown/paramDropdown.style.scss';
import config from '../../config';
import ActionBar from '../../menu/actionBar.container';

import MenuTabs from './menuTabs.container';

const Menu = () => {
  const location = useLocation();

  return (
    <div className="normal-header-container menu">
      <nav className="a-header-wrapper">
        <div className="a-brand-container">
          <Link to="/">
            <img
              alt="menu-logo"
              className="menu-logo"
              src={`${config.BASE_PATH}/assets/pwclogo.png`}
            />
          </Link>
          <div className="a-divider" />
          <span className="font-weight-medium">State Lifecycle Tool</span>
          <div className="a-divider-full" />
        </div>
        <MenuTabs pathname={location.pathname} />
        <ActionBar />
      </nav>
    </div>
  );
};

export default Menu;
