import { Routes } from '@common-packages/routes-definitions';
import { TOTAL_DESC } from '@common-packages/shared-constants';

import { currencyColumnDefinition } from '../../../shared/columnDefinitions';
import {
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../../shared/columnDefinitions/cellRenderers';

const isTotalRow = ({ data }) => data.businessId === TOTAL_DESC;

const defaultColumnDefinition = {
  width: 150,
  sortable: false,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
  cellClassRules: {
    'total-row-cell': isTotalRow,
  },
};

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const getColumnDefinitions = ({ globalContext }) => {
  const AmountLinkRenderer = LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: () => Routes.filingDecisionsV2.compiledRoute(globalContext.params),
  });

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Component',
      field: 'businessId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'accountId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'accountDescription',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Amount',
      field: 'accountAmount',
      cellRenderer: row => (isTotalRow(row) ? TextRenderer(row) : AmountLinkRenderer(row)),
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
