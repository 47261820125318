import { combineReducers } from 'redux';

import taxFormsReducer from '../taxForms/store/reducer';
import taxFormsV2Reducer from '../taxFormsV2/store/reducer';
import genericFunctionsReducer from '../genericFunctions/store/reducer';
import genericScreenDefinitionReducer from '../genericScreenDefinition/store/reducer';
import calcSpecsReducer from '../calcSpecs/store/reducer';
import defaultBindersReducer from '../defaultBinders/store/reducer';
import attachmentDefinitionsReducer from '../attachmentDefinitions/store/reducer';
import expressionBuilderReducer from '../expressionBuilder/store/reducer';

import sharedReducer from './shared.reducer';

export default combineReducers({
  shared: sharedReducer,
  taxForms: taxFormsReducer,
  taxFormsV2: taxFormsV2Reducer,
  genericFunctions: genericFunctionsReducer,
  genericScreenDefinition: genericScreenDefinitionReducer,
  calcSpecs: calcSpecsReducer,
  expressionBuilder: expressionBuilderReducer,
  defaultBinders: defaultBindersReducer,
  attachmentDefinitions: attachmentDefinitionsReducer,
});
