import {
  adminColumnDefinition,
  defaultColumnDefinition,
  percentageColumnDefinition,
  centeredColumnDefinition,
  selectCellEditorParamsFactory,
} from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  NumberCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import { getSelectOptionCellRenderer } from '../../shared/columnDefinitions/cellRenderers/selectCellRendererFrameworkWithCustomValidation.factory';
import { defaultAgRichSelectCellEditor } from '../../shared/columnDefinitions/cellEditor';

import yesNoOptions, { YES } from './constants';

const defaultPercentageColumnDefinition = {
  ...defaultColumnDefinition,
  ...percentageColumnDefinition,
};

const getColumnDefinitions = ({
  isInEditMode,
  updateRow,
  onDeleteIconClick,
  partnershipAllocationPercentages,
}) => {
  const onCellValueChanged = ({ data }) => updateRow(data);
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: !isInEditMode,
    onDeleteIconClick,
  });

  const validators = ({ partnershipAllocationPercentages = [] }) => ({
    plugOwner: (_value, data) => {
      const rowWithPlugOwner = partnershipAllocationPercentages.find(
        ({ plugOwner, partnerId }) =>
          data.plugOwner === YES && plugOwner === YES && partnerId !== data.partnerId,
      );
      if (rowWithPlugOwner) {
        return `Partner ${rowWithPlugOwner.partnerId} is a plug owner for this Entity `;
      }
    },
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Partner Id',
      field: 'partnerId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Name',
      field: 'ownerName',
      width: 300,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Percentage Type',
      field: 'percentageType',
    },
    {
      ...defaultPercentageColumnDefinition,
      headerName: 'Percentage',
      field: 'ownershipPercentage',
      editable: isInEditMode,
      onCellValueChanged,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
        min: 0,
        max: 100,
      },
    },
    {
      ...defaultColumnDefinition,
      ...centeredColumnDefinition,
      headerName: 'Plug Owner',
      field: 'plugOwner',
      ...defaultAgRichSelectCellEditor,
      editable: isInEditMode,
      onCellValueChanged,
      cellEditorParams: selectCellEditorParamsFactory(yesNoOptions),
      cellRenderer: row =>
        getSelectOptionCellRenderer(
          yesNoOptions,
          validators({ partnershipAllocationPercentages }),
        )(row),
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
