import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TreeItem, arrayToTree } from 'performant-array-to-tree';
import { Button } from '@pwc/appkit-react';

import { globalContextSelector } from '../../../shared/store/selectors';
import {
  useQueryDataModels,
  useQueryDatasetDefinitions,
  useQueryDatasetInstances,
} from '../../../shared/queries/instanceDataCleanup';
import {
  GlobalContext,
  FindDatasetDefinitions,
  DatasetInstancesResponseData,
} from '../../../../../common/types';
import ListHierarchy from '../../../shared/displayComponents/listHierarchy/listHierarchy';
import SharedHeader from '../../../shared/displayComponents/header/header.component';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import ParamDropdown from '../../../shared/displayComponents/paramDropdown/paramDropdown.component';
import toggleAllSelectionsHeaderFactory from '../../../shared/displayComponents/toggleAllSelectionsHeaderFactory';
import { useRowEditMode } from '../../../shared/editMode';
import { useMutationDeleteDatasetInstances } from '../../../shared/mutations/instanceDataCleanup';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';
import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';

import styles from './instanceDataCleanup.module.scss';
import { getColumnDefinitions } from './instanceDataCleanupColumnDefinitions';
import { getAllDatasetInstancesTobeDeleted } from './utils';

const getUniqueRowId = ({ data: { id } }: { data: DatasetInstancesResponseData[number] }) => id;

const InstanceDataCleanup = () => {
  const dispatch = useDispatch();
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const {
    taxYear,
    period,
    jurisdictionId,
    params: { businessEntityId },
  } = globalContext;
  const isContextReady = Boolean(taxYear && period && jurisdictionId && businessEntityId);

  const [selectedDataModelId, setSelectedDataModelId] = useState<string | undefined>();
  const [selectedDatasetDefinition, setSelectedDatasetDefinition] = useState<TreeItem | null>(null);
  const selectedDatasetDefinitionId = selectedDatasetDefinition?.data.id;
  const [selectAllIncluded, setSelectAllIncluded] = useState(false);

  const {
    mutateAsync: deleteDatasetInstances,
    isLoading: isDeletingDatasetInstances,
  } = useMutationDeleteDatasetInstances();

  const { data: dataModels, isFetching: isFetchingDataModels } = useQueryDataModels({
    params: {
      taxYear,
      period,
      jurisdictionId,
      businessEntityId,
    },
    enabled: Boolean(taxYear && period && jurisdictionId && businessEntityId),
  });
  const dataModelOptions = useMemo(
    () => dataModels?.map(({ id, name }) => ({ label: name, value: id })) || [],
    [dataModels],
  );

  useEffect(() => {
    if (!dataModels || isFetchingDataModels) {
      return;
    }

    if (!dataModels.length) {
      setSelectedDataModelId('');
      return;
    }

    if (selectedDataModelId && dataModels.find(({ id }) => id === selectedDataModelId)) {
      return;
    }

    setSelectedDataModelId(dataModels[0].id);
  }, [dataModels, isFetchingDataModels, selectedDataModelId]);

  const {
    data: datasetDefinitions,
    isFetching: isFetchingDatasetDefinitions,
  } = useQueryDatasetDefinitions({
    params: {
      taxYear,
      period,
      jurisdictionId,
      businessEntityId,
      dataModelId: selectedDataModelId,
    },
    enabled: Boolean(
      taxYear && period && jurisdictionId && businessEntityId && selectedDataModelId,
    ),
  });
  const datasetDefinitionsTree = arrayToTree(datasetDefinitions || [], {
    parentId: 'parentDatasetDefId',
  });

  useEffect(() => {
    if (!datasetDefinitions || isFetchingDatasetDefinitions) {
      return;
    }

    if (!datasetDefinitions.length) {
      setSelectedDatasetDefinition(null);
      return;
    }

    if (
      selectedDatasetDefinition &&
      datasetDefinitions.find(({ id }) => id === selectedDatasetDefinitionId)
    ) {
      return;
    }

    const datasetDefinitionsTree = arrayToTree(datasetDefinitions || [], {
      parentId: 'parentDatasetDefId',
    });
    setSelectedDatasetDefinition(datasetDefinitionsTree[0]);
  }, [
    datasetDefinitions,
    isFetchingDatasetDefinitions,
    selectedDatasetDefinition,
    selectedDatasetDefinitionId,
  ]);

  const {
    data: datasetInstances,
    isFetching: isFetchingDatasetInstances,
  } = useQueryDatasetInstances({
    params: {
      datasetDefinitionId: selectedDatasetDefinitionId,
      period,
      businessEntityId,
    },
    enabled: Boolean(selectedDatasetDefinitionId && period && businessEntityId),
  });

  const { updateRow, onGridReady, gridApi, clonedRowData: datasetInstancesData } = useRowEditMode({
    rowData: datasetInstances?.data || [],
    getUniqueRowId,
    saveButtonDisabled: false,
    isPermanentlyInEditMode: true,
  });
  const selectedRows: DatasetInstancesResponseData = datasetInstancesData?.filter(
    ({ isSelected }: { isSelected?: boolean }) => Boolean(isSelected),
  );
  const areAllSelected =
    Boolean(datasetInstancesData.length) &&
    datasetInstancesData.every(({ isSelected }: { isSelected?: boolean }) => Boolean(isSelected));
  const deleteButtonDisabled = !selectedRows?.length || isDeletingDatasetInstances;

  useEffect(() => setSelectAllIncluded(areAllSelected), [areAllSelected]);

  const handleDataModelChange = useCallback(
    (dataModelId: string) => {
      if (!dataModelId || selectedDataModelId === dataModelId) {
        return;
      }

      setSelectAllIncluded(false);
      setSelectedDataModelId(dataModelId);
    },
    [selectedDataModelId],
  );

  const onDatasetClick = useCallback(
    (data: FindDatasetDefinitions[number], children: TreeItem[]) => {
      if (selectedDatasetDefinitionId === data?.id) {
        return;
      }
      setSelectedDatasetDefinition({ data, children });
    },
    [selectedDatasetDefinitionId],
  );

  const handleRemoveDatasetInstance = useCallback(() => {
    if (!selectedDatasetDefinition) {
      return;
    }

    dispatch(
      showConfirmModal({
        title: 'Delete Dataset Instance',
        text: `You are sure you want to delete the data associated with dataset ${selectedDatasetDefinition?.data.name}, and all children data under it?`,
        confirmCallback: () => {
          deleteDatasetInstances({
            taxYear,
            period,
            jurisdictionId,
            businessEntityId,
            datasetDefinitionIds: getAllDatasetInstancesTobeDeleted({
              datasetDefinitionsTree: [selectedDatasetDefinition],
            }).map(({ id }) => id),
            datasetInstanceIds: selectedRows.map(({ id }) => id),
          });
        },
      }),
    );
  }, [
    dispatch,
    deleteDatasetInstances,
    taxYear,
    period,
    jurisdictionId,
    businessEntityId,
    selectedDatasetDefinition,
    selectedRows,
  ]);

  const handleOverrideAllValues = useCallback(
    (newValue: boolean) => {
      gridApi?.forEachNodeAfterFilter(
        ({ data }: { data: DatasetInstancesResponseData[number] & { isSelected: boolean } }) => {
          data.isSelected = newValue;
          updateRow(data);
        },
      );
      gridApi?.refreshCells({ force: true, columns: ['isSelected'] });
    },
    [gridApi, updateRow],
  );

  const columnDefinitions = useMemo(() => {
    const ToggleAllSelectionsHeader = toggleAllSelectionsHeaderFactory({
      togglerState: selectAllIncluded,
      setTogglerState: setSelectAllIncluded,
      isInEditMode: true,
      handleOverrideAllValues,
    });
    return getColumnDefinitions({
      additionalColumns: datasetInstances?.columns || [],
      updateRow,
      ToggleAllSelectionsHeader,
    });
  }, [handleOverrideAllValues, updateRow, datasetInstances?.columns, selectAllIncluded]);

  if (!isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <>
      <SharedHeader title="Instance Data Cleanup" />
      <div className={`${styles.datasetDefinitionsListWrapper} card`}>
        <ParamDropdown
          label="Data Model:"
          value={selectedDataModelId}
          options={dataModelOptions}
          handleChange={handleDataModelChange}
          isBusy={isFetchingDataModels || isDeletingDatasetInstances}
        />
        <Button size="lg" onClick={handleRemoveDatasetInstance} disabled={deleteButtonDisabled}>
          Remove
        </Button>
        <div>
          <p className={`${styles.datasetInstancesTitle}`}>Dataset Instances:</p>
          <ListHierarchy
            isLoading={
              isFetchingDataModels || isFetchingDatasetDefinitions || isDeletingDatasetInstances
            }
            items={datasetDefinitionsTree}
            selectedItemIds={[selectedDatasetDefinitionId]}
            itemOnClick={onDatasetClick}
          />
        </div>
        <AgGrid
          className={`${styles.datasetInstancesGrid}`}
          rowData={datasetInstancesData}
          columnDefs={columnDefinitions}
          isGridLoading={
            isFetchingDataModels ||
            isFetchingDatasetDefinitions ||
            isFetchingDatasetInstances ||
            isDeletingDatasetInstances
          }
          autoMaxWidth
          getRowId={getUniqueRowId}
          onGridReady={onGridReady}
        />
      </div>
    </>
  );
};

export default InstanceDataCleanup;
