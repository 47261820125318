import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ServerSideAgGrid, { useGridServerSideDataSource } from '@tls/ui-server-side-aggrid';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import {
  gridPivotOptions,
  excelStyles,
} from '../../../../shared/displayComponents/agGrid/constants';
import Loading from '../../../../shared/displayComponents/loading.component';
import { REPORT_TYPES } from '../../../shared/constants';
import { defaultServerSideParams } from '../../constants';
import getDynamicColumnDefinitions from '../../getDynamicColumnDefinitions';
import {
  fetchCustomReport,
  cleanAfterCustomReport,
  runCustomReport,
  cleanAfterRunCustomReport,
} from '../store/actions';
import {
  customReportSelector,
  isFetchingCustomReportSelector,
  customReportResultSelector,
  isFetchingCustomReportResultSelector,
} from '../store/selectors';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../../../shared/displayComponents/actionMenuForExport';
import { runCustomReport as runCustomReportApiAction } from '../api';
import { globalContextSelector } from '../../../../shared/store/selectors';

import { createOptions } from './visualizationHelper';
import CustomReportParam from './CustomReportParam.container';

const CustomReport = ({ reportId, reportType }) => {
  const [paramsValues, setParamsValues] = useState(null);

  const dispatch = useDispatch();

  const globalContext = useSelector(globalContextSelector);

  const customReport = useSelector(customReportSelector);
  const isFetchingCustomReport = useSelector(isFetchingCustomReportSelector);
  const customReportResult = useSelector(customReportResultSelector);
  const isFetchingCustomReportResult = useSelector(isFetchingCustomReportResultSelector);

  useEffect(() => {
    if (globalContext.isReady) {
      dispatch(fetchCustomReport({ id: reportId }));
    }
    return () => {
      setParamsValues(null);
      dispatch(cleanAfterCustomReport());
    };
  }, [dispatch, reportId, globalContext.isReady]);

  const isServerSideReport = customReport && customReport.reportDefinition.serverSide;
  useEffect(() => {
    if (!paramsValues) {
      return;
    }

    dispatch(cleanAfterRunCustomReport());
    dispatch(
      debounce(
        runCustomReport({
          reportId,
          params: {
            ...(isServerSideReport ? defaultServerSideParams : {}),
            ...paramsValues,
          },
        }),
        1000,
      ),
    );
  }, [dispatch, reportId, paramsValues, isServerSideReport]);

  const { rows, columns, types } = customReportResult || {};

  const { props, onGridReady: onGridReadyActionMenuForExport } = useActionMenuForExport({
    reportData: rows,
  });

  const { onGridReady: onGridReadyServerSideDataSource } = useGridServerSideDataSource({
    fetchData: async ({ limit, offset, sortModel, filterModel }) => {
      const { rows } = await runCustomReportApiAction({
        reportId,
        params: {
          ...paramsValues,
          limit,
          offset,
          sortModel,
          filterModel,
        },
      });
      return rows;
    },
    initialRows: rows,
  });

  const onServerSideGridReady = useCallback(
    params => {
      onGridReadyActionMenuForExport(params);
      onGridReadyServerSideDataSource(params);
    },
    [onGridReadyActionMenuForExport, onGridReadyServerSideDataSource],
  );

  const queryParams = customReport ? customReport.reportDefinition.queryParams : [];

  const columnDefinitions = useMemo(() => getDynamicColumnDefinitions(columns, types), [
    columns,
    types,
  ]);

  const ReportContent = ({ reportType }) => {
    if (reportType === REPORT_TYPES.REPORT) {
      return customReport && customReport.reportDefinition.serverSide ? (
        <ServerSideAgGrid columnDefs={columnDefinitions} onGridReady={onServerSideGridReady} />
      ) : (
        <AgGrid
          rowData={rows}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingCustomReportResult}
          onGridReady={onGridReadyActionMenuForExport}
          excelStyles={excelStyles}
          {...gridPivotOptions}
        />
      );
    }
    const chartParams = customReport
      ? {
          ...customReport.reportDefinition.chart,
          name: customReport.name,
        }
      : [];

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={createOptions(customReportResult.rows, chartParams)}
      />
    );
  };

  return (
    <Loading isLoading={isFetchingCustomReport}>
      <h4>{customReport && customReport.name}</h4>
      <div className="row">
        <div className="col">
          {customReport && (
            <CustomReportParam
              reportId={reportId}
              queryParams={queryParams}
              onChange={setParamsValues}
              actionMenu={<ActionMenuForExport {...props} />}
            />
          )}
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          {paramsValues &&
            customReportResult &&
            !isFetchingCustomReportResult &&
            ReportContent({ reportType })}
        </div>
      </div>
    </Loading>
  );
};

CustomReport.propTypes = {
  reportId: PropTypes.string.isRequired,
  reportType: PropTypes.string.isRequired,
  customReport: PropTypes.shape({
    name: PropTypes.string.isRequired,
    reportDefinition: PropTypes.shape({
      queryParams: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          mappingName: PropTypes.string.isRequired,
        }),
      ),
      chart: PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
      serverSide: PropTypes.bool.isRequired,
    }),
  }),
  isFetchingCustomReport: PropTypes.bool.isRequired,
  fetchCustomReport: PropTypes.func.isRequired,
  cleanAfterCustomReport: PropTypes.func.isRequired,
  cleanAfterRunCustomReport: PropTypes.func.isRequired,

  runCustomReport: PropTypes.func.isRequired,
  customReportResult: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
  }),
  isFetchingCustomReportResult: PropTypes.bool.isRequired,
};

export default CustomReport;
