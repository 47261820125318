import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { SelectOptionPropTypes } from '../../propTypes/selectOption';
import ParamDropdown from '../paramDropdown/paramDropdown.component';
import { NotApplicableContextValue } from '../../constants';

const PeriodDropdown = ({
  period,
  periods,
  selectPeriod,
  customOnChangeAction = false,
  isDropdownBusy,
  isFetchingContext,
}) => {
  const { routeParams, setParams } = useUrlParams();

  const handlePeriodSelect = useCallback(
    newPeriod => {
      if (period !== newPeriod) {
        if (newPeriod !== NotApplicableContextValue) {
          selectPeriod(newPeriod);
        }
        setParams({ routeParams: { period: newPeriod } });
      }
    },
    [setParams, selectPeriod, period],
  );

  useEffect(() => {
    if (!periods.length) {
      return;
    }
    if (routeParams.period && periods.find(({ value }) => value === routeParams.period)) {
      handlePeriodSelect(routeParams.period);
      return;
    }
    if (!period && !isDropdownBusy) {
      handlePeriodSelect(periods[0].value);
      return;
    }
  }, [handlePeriodSelect, periods, routeParams.period, period, isDropdownBusy]);

  return (
    <ParamDropdown
      label="Period"
      value={period}
      options={periods}
      handleChange={
        customOnChangeAction ? customOnChangeAction(handlePeriodSelect) : handlePeriodSelect
      }
      isBusy={isDropdownBusy}
      isFetchingContext={isFetchingContext}
    />
  );
};

PeriodDropdown.propTypes = {
  periods: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  period: PropTypes.string,
  selectPeriod: PropTypes.func.isRequired,
  isDropdownBusy: PropTypes.bool,
  isFetchingContext: PropTypes.bool,
  customOnChangeAction: PropTypes.func,
};

export default PeriodDropdown;
