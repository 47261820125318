import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';
import { filingTypes } from '@tls/state-helpers';

import useGridApi from '../../../../shared/hooks/useGridApi.hook';
import FaIcon from '../../../../shared/displayComponents/faIcon/faIcon.component';
import PeriodDropdown from '../../../../shared/displayComponents/contextDropdown/periodDropdown.component';
import useOpenInNewTab from '../../../../shared/hooks/useOpenInNewTab';
import {
  taxYearSelector,
  jurisdictionIdSelector,
  isFetchingPeriodsSelector,
  isFetchingTaxYearsDataSelector,
  isFetchingJurisdictionsSelector,
  periodOptionsSelector,
} from '../../../store/selectors';
import {
  constantTypeSelector,
  constantsSelector,
  constantsColumnsBlueprintSelector,
  isFetchingConstantsSelector,
  constantsPeriodSelector,
} from '../../../../shared/store/calculationConstants/selectors';
import { selectTaxYear, selectPeriod, selectFilingType } from '../../../../shared/store/actions';
import { selectJurisdiction } from '../../../../admin/taxRatesAndConstantsMaintenance/store/actions/shared';
import {
  fetchConstants,
  selectConstantsPeriod,
} from '../../../../shared/store/calculationConstants/actions';
import setGridFilterValue from '../setGridFilterValue';
import expressionBuilderStyles from '../../styles.module.scss';
import columnBlueprintStyles from '../../../../shared/styles/columnBlueprintStyles.module.scss';

import ConstantsGrid from './constantsGrid/constantsGrid.component';
import ConstantTypeDropdown from './constantTypeDropdown/constantTypeDropdown.container';
import styles from './calculationConstants.module.scss';
import { PERIOD_VALUE_FOR_SEPARATE } from './constants';

const CalculationConstants = ({
  addFunctionSepExpression,
  addFunctionConsolExpression,
  generateContextMenuItems,
}) => {
  const { gridApi, onGridReady } = useGridApi();
  const [filterValue, setFilterValue] = useState('');

  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const isFetchingTaxYearsData = useSelector(isFetchingTaxYearsDataSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const isFetchingJurisdictions = useSelector(isFetchingJurisdictionsSelector);
  const periods = useSelector(periodOptionsSelector);
  const period = useSelector(constantsPeriodSelector);
  const isFetchingPeriods = useSelector(isFetchingPeriodsSelector);
  const constantType = useSelector(constantTypeSelector);
  const constants = useSelector(constantsSelector);
  const constantsBlueprint = useSelector(constantsColumnsBlueprintSelector);
  const isFetchingConstants = useSelector(isFetchingConstantsSelector);

  const isLoading =
    isFetchingPeriods || isFetchingConstants || isFetchingTaxYearsData || isFetchingJurisdictions;

  const handleFetchConstants = useCallback(() => {
    dispatch(
      fetchConstants({
        taxYear,
        period: constantType === filingTypes.SEPARATE ? PERIOD_VALUE_FOR_SEPARATE : period,
        jurisdictionId,
        filingTypeId: constantType,
      }),
    );
  }, [dispatch, constantType, period, jurisdictionId, taxYear]);

  const handleSelectConstantsPeriod = useCallback(
    period => {
      dispatch(selectConstantsPeriod(Number(period)));
    },
    [dispatch],
  );

  useEffect(() => {
    if (taxYear && jurisdictionId && !constantsBlueprint.length) {
      handleFetchConstants();
    }
  }, [dispatch, handleFetchConstants, jurisdictionId, taxYear, period, constantsBlueprint.length]);

  const handleFilterChange = useCallback(() => {
    setGridFilterValue(gridApi, setFilterValue);
  }, [gridApi]);

  const openInNewTab = useOpenInNewTab();

  const handleConstantsButtonClick = useCallback(() => {
    dispatch(selectTaxYear(taxYear));
    dispatch(selectPeriod(period));
    dispatch(selectFilingType(constantType));
    dispatch(selectJurisdiction(jurisdictionId));
    openInNewTab(Routes.taxRatesAndConstantsMaintenance.MAIN, {
      activeTab: 'CONSTANTS',
      search: filterValue,
    });
  }, [dispatch, openInNewTab, taxYear, period, constantType, jurisdictionId, filterValue]);

  return (
    <div className={styles.wrapper}>
      <div className="row">
        <div className="col text-right">
          <Button size="lg" kind="secondary" onClick={handleConstantsButtonClick}>
            Constants...
          </Button>
          <button
            title="Refresh data"
            onClick={handleFetchConstants}
            disabled={isLoading}
            className={expressionBuilderStyles.refreshButton}
          >
            <FaIcon icon="sync" size={16} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className={styles.dropdownWrapper}>
          <ConstantTypeDropdown />
        </div>
        {constantType === filingTypes.CONSOLIDATED && (
          <div className={styles.dropdownWrapper}>
            <PeriodDropdown
              periods={periods}
              period={period}
              selectPeriod={handleSelectConstantsPeriod}
              isDropdownBusy={isFetchingTaxYearsData || isFetchingPeriods}
            />
          </div>
        )}
      </div>
      <div className={`row grid-row ${columnBlueprintStyles.gridContainer}`}>
        <div className="col">
          <ConstantsGrid
            constants={constants}
            columnsBlueprint={constantsBlueprint}
            isLoading={isLoading}
            onGridReady={onGridReady}
            onFilterChanged={handleFilterChange}
            addFunctionSepExpression={addFunctionSepExpression}
            addFunctionConsolExpression={addFunctionConsolExpression}
            generateContextMenuItems={generateContextMenuItems}
          />
        </div>
      </div>
    </div>
  );
};

CalculationConstants.propTypes = {
  addFunctionSepExpression: PropTypes.func.isRequired,
  addFunctionConsolExpression: PropTypes.func.isRequired,
  generateContextMenuItems: PropTypes.func.isRequired,
};

export default CalculationConstants;
