const Yup = require('yup');

const dataTypeSchemaPart = require('./dataTypeSchemaPart');

const commonSchema = Yup.object().shape({
  taxYear: Yup.number().required(),
  period: Yup.number().required(),
  infoAccountId: Yup.string().required(),
  entityId: Yup.string().required(),
  ...dataTypeSchemaPart,
});

const updateGlobalEntityInformation = Yup.object()
  .shape({
    rowsToUpdate: Yup.array().of(commonSchema).notOneOf([undefined, null]), // eslint-disable-line no-undefined
  })
  .strict();

const updatePartnershipInformation = Yup.object()
  .shape({
    rowsToUpdate: Yup.array()
      .of(
        commonSchema.concat(
          Yup.object().shape({
            partnershipId: Yup.string().required(),
          }),
        ),
      )
      .notOneOf([undefined, null]), // eslint-disable-line no-undefined
  })
  .strict();

module.exports = { updateGlobalEntityInformation, updatePartnershipInformation };
