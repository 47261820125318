import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import useFetch from '../../shared/hooks/useFetch.hook';
import Loading from '../../shared/displayComponents/loading.component';
import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { getActionStatus, getLastUpdateDate } from '../utils';
import { fetchStepLogsApiAction } from '../store/api';
import styles from '../styles.module.scss';

import { contextDebugInfoPropTypes } from './currentReturnFiling.propTypes';

const Action = ({
  getDownloadFileComponent,

  actionName,
  title,
  data,
  isSubmitting,
  isLoading,
  contextDebugInfo,
  failedDisplayedStatus,
}) => {
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  const { data: stepLogs, fetch: fetchStepLogs, isFetching: isFetchingStepLogs } = useFetch({
    action: fetchStepLogsApiAction,
  });

  const apiInput = data && {
    actionName,
    stepId: data.stepId,
    ...contextDebugInfo,
  };
  const apiResponse = data && {
    ...data.ttiResponse,
  };

  const toggleShowDebugInfo = useCallback(() => {
    if (!showDebugInfo) {
      fetchStepLogs({ stepId: data.stepId });
    }
    setShowDebugInfo(!showDebugInfo);
  }, [fetchStepLogs, showDebugInfo, data]);

  return (
    <div className={styles.actionBox}>
      <h6 className={styles.actionTitle}>{title}</h6>
      <div className={styles.spaceBetween}>
        <div className={styles.stepStatus}>
          Status:{' '}
          {isLoading ? (
            <Loading isLoading small />
          ) : (
            <div className={styles.capitalizeStatus}>
              {getActionStatus(data, isSubmitting, failedDisplayedStatus)}
              {data && (
                <AppkitIcon
                  icon={showDebugInfo ? 'up-chevron' : 'down-chevron'}
                  size={15}
                  onClick={toggleShowDebugInfo}
                  className={styles.debugInfoIcon}
                />
              )}
            </div>
          )}
        </div>
        {Boolean(data) && (
          <div className={styles.inline}>
            {getDownloadFileComponent &&
              getDownloadFileComponent({
                data,
              })}
            {!isLoading && (
              <div className={styles.lastUpdateDate}>Last update: {getLastUpdateDate(data)}</div>
            )}
          </div>
        )}
      </div>
      {showDebugInfo && (
        <>
          <div>
            API input:
            <pre>{JSON.stringify(apiInput, null, 2)}</pre>
          </div>
          <div>
            API call logs:
            {isFetchingStepLogs ? (
              <Loading isLoading small />
            ) : (
              stepLogs && <pre>{JSON.stringify(stepLogs, null, 2)}</pre>
            )}
          </div>
          <div>
            API response:
            <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
          </div>
        </>
      )}
    </div>
  );
};

Action.propTypes = {
  title: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  data: PropTypes.shape({
    stepId: PropTypes.string.isRequired,
    status: PropTypes.string,
    createdOn: PropTypes.string,
    updatedOn: PropTypes.string,
    ttiResponse: PropTypes.shape({
      action: PropTypes.shape({
        fileGuid: PropTypes.string,
        operationId: PropTypes.number,
      }),
      validation: PropTypes.shape({
        status: PropTypes.object,
        result: PropTypes.object,
      }),
    }),
  }),
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  contextDebugInfo: contextDebugInfoPropTypes,
  failedDisplayedStatus: PropTypes.string,

  getDownloadFileComponent: PropTypes.func,
};

export default Action;
