import { createSelector } from 'reselect';

const partnershipFlowThroughAmountsSelector = ({ entity }) =>
  entity.partnershipFlowThroughAmounts.root.partnershipFlowThroughAmounts;

export const isFetchingPartnershipFlowThroughAmountsSelector = ({ entity }) =>
  entity.partnershipFlowThroughAmounts.root.isFetchingPartnershipFlowThroughAmounts;

export const partnershipFlowThroughAmountsRowsSelector = createSelector(
  [partnershipFlowThroughAmountsSelector],
  partnershipFlowThroughAmounts =>
    partnershipFlowThroughAmounts ? partnershipFlowThroughAmounts.rowData : [],
);

export const partnershipFlowThroughAmountsColumnsSelector = createSelector(
  [partnershipFlowThroughAmountsSelector],
  partnershipFlowThroughAmounts =>
    partnershipFlowThroughAmounts ? partnershipFlowThroughAmounts.dynamicColumns : [],
);

export const accountIdSelector = ({ entity }) =>
  entity.partnershipFlowThroughAmounts.root.accountId;

export const jurisdictionIdSelector = ({ entity }) =>
  entity.partnershipFlowThroughAmounts.root.jurisdictionId;
