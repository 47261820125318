import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';

import {
  fetchSubmissionsTypes,
  fetchCaseListTypes,
  fetchYearSubPeriodListTypes,
  validateAndGenerateMappingForStateTransmissionTypes,
  stateTransmissionImportTypes,
} from './types';

export const initialState = {
  submissions: [],
  isFetchingSubmissions: false,
  caseList: [],
  isFetchingCaseList: false,
  yearSubPeriodList: [],
  isFetchingYearSubPeriodList: false,
};

const efileReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchSubmissionsTypes,
      payloadKey: 'submissions',
      fetchingKey: 'isFetchingSubmissions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchCaseListTypes,
      payloadKey: 'caseList',
      fetchingKey: 'isFetchingCaseList',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchYearSubPeriodListTypes,
      payloadKey: 'yearSubPeriodList',
      fetchingKey: 'isFetchingYearSubPeriodList',
    }),
    [validateAndGenerateMappingForStateTransmissionTypes.SUCCESS]: (state, payload) => {
      if (payload.result.operationStatus !== 'Completed') {
        return state;
      }
      return {
        ...state,
        submissions: state.submissions.map(submission =>
          submission.submissionId === payload.submissionId
            ? {
                ...submission,
                isMapUploaded: true,
                ttiMappingZipFile: payload.result.ttiMappingZipFile,
              }
            : submission,
        ),
      };
    },
    [stateTransmissionImportTypes.SUCCESS]: (state, payload) => {
      if (payload.result.operationStatus !== 'Completed') {
        return state;
      }
      return {
        ...state,
        submissions: state.submissions.map(submission =>
          submission.submissionId === payload.submissionId
            ? {
                ...submission,
                isResultAvailable: true,
                ttiImportValidationJSONFile: payload.result.ttiImportValidationJSONFile,
              }
            : submission,
        ),
      };
    },
  },
});

export default efileReducer;
