/* eslint-disable babel/no-invalid-this */
const Yup = require('yup');
const { ConsolidationMethodFilingTypes, dateRegex } = require('@common-packages/shared-constants');

const { alphanumericChar } = require('../validationRegex');
const { getDueDateSchema } = require('../getDuedateSchema');
const { getFilingTypeSchema } = require('../getFilingTypeSchema');
const { getBusinessTypeSchema } = require('../getBusinessTypeSchema');
const { taxYearEndingRequiredErrorMessage, taxReturnStatus } = require('../constants');
const { FilingMethods } = require('../../stateHelpers/stateHelpers');

const getAddConsolidationSchema = ({
  consolidationIds = [],
  shouldDisplayDueDate,
  dueDateOption,
}) =>
  Yup.object().shape({
    consolidationId: Yup.string()
      .label('Consolidation Id')
      .matches(alphanumericChar.regex, alphanumericChar.message)
      .max(20, 'Consolidation Id can hold max 20 characters')
      .uniqueInList({
        message: 'Consolidation Id already exists, a unique Id is required before saving',
        list: consolidationIds,
      })
      .required(),
    parentOrgId: Yup.string().label('Parent entity').required(),
    jurisdictionId: Yup.string().label('Jurisdiction').required(),
    description: Yup.string()
      .label('Description')
      .max(100, 'Description cannot be longer than 100 characters')
      .nullable(),
    consolidationMethod: Yup.string()
      .oneOf(Object.keys(ConsolidationMethodFilingTypes))
      .label('Consolidation method')
      .required(),
    filingAttributes: Yup.number().label('Filing Attributes').required(),
    federalFilingGroupId: Yup.string()
      .label('Federal Filing Id')
      .max(40, 'Federal Filing Group Id cannot be longer than 40 characters')
      .nullable(),
    proformaFilingGroupId: Yup.string()
      .label('Proforma Filing Group Id')
      .max(40, 'Proforma Filing Group Id cannot be longer than 40 characters')
      .nullable(),
    returnDefinitionId: Yup.string().label('Return Definition Id').required(),
    filingMethod: Yup.mixed()
      .label('Filing Method')
      .oneOf(['', null, 'efile', 'paper', 1, 2])
      .defined()
      .nullable(),
    taxYearEnding: Yup.string()
      .label('Tax Year Ending')
      .matches(dateRegex, taxYearEndingRequiredErrorMessage)
      .required(),
    ...getDueDateSchema({ shouldDisplayDueDate, dueDateOption }),
  });

// used in new consolidation wizard front end part
const getAddEditConsolidationFormSchema = ({
  consolidationIds = [],
  isEditing = false,
  shouldDisplayDueDate,
  dueDateOption,
  entitiesOption,
  filingTypeOptions = [],
  returnTypeOptions = [],
  businessTypeOptions = [],
}) =>
  Yup.object().shape({
    jurisdictionId: Yup.string().label('Jurisdiction').required(),
    returnDefinitionId: Yup.string()
      .typeError('Filing Form Description is a required field.')
      .label('Filing Form Description')
      .required(),
    ...(isEditing
      ? {}
      : {
          consolidationId: Yup.string()
            .label('Consolidation Id')
            .matches(alphanumericChar.regex, alphanumericChar.message)
            .max(20, 'Consolidation Id can hold max 20 characters')
            .uniqueInList({
              message: 'Consolidation Id already exists, a unique Id is required before saving',
              list: consolidationIds,
              shouldSkipTest: isEditing,
            })
            .required(),
        }),
    description: Yup.string()
      .label('Description')
      .max(100, 'Description cannot be longer than 100 characters')
      .nullable(),
    filingMethod: Yup.mixed()
      .label('Filing Method')
      .oneOf(['efile', 'paper', 'non-filing'])
      .required(),
    returnStatus: Yup.number()
      .label('Return Status')
      .when('filingMethod', {
        is: FilingMethods.NON_FILING,
        then: Yup.number()
          .oneOf([
            taxReturnStatus.NOT_STARTED,
            taxReturnStatus.IN_PROGRESS,
            taxReturnStatus.COMPLETE,
            null,
          ])
          .nullable(),
        otherwise: Yup.number()
          .typeError('Return Status is a required field')
          .oneOf([
            taxReturnStatus.NOT_STARTED,
            taxReturnStatus.IN_PROGRESS,
            taxReturnStatus.COMPLETE,
          ])
          .required(),
      }),
    taxYearEnding: Yup.string()
      .label('Tax Year Ending')
      .matches(dateRegex, taxYearEndingRequiredErrorMessage)
      .required(),
    ...getDueDateSchema({
      shouldDisplayDueDate,
      dueDateOption,
    }),
    parentOrgId: Yup.string()
      .label('Parent Entity')
      .required()
      .existsInList({
        message: 'Parent entity is required',
        list: entitiesOption,
        mapper: ({ entityId }) => entityId,
      }),
    filingType: Yup.string()
      .label('Filing Type')
      .required()
      .existsInList({
        message: 'Filing Type is a required field.',
        list: filingTypeOptions,
        mapper: ({ value }) => value,
      }),
    returnType: Yup.string()
      .label('Federal Return Type')
      .required()
      .existsInList({
        message: 'Federal Return Type is a required field.',
        list: returnTypeOptions,
        mapper: ({ value }) => value,
      }),
    businessType: Yup.string()
      .label('Business Type')
      .required()
      .existsInList({
        message: 'Business Type is a required field.',
        list: businessTypeOptions,
        mapper: ({ value }) => value,
      }),
  });

const updateConsolidationSchema = ({
  returnDefinitions = [],
  shouldDisplayDueDate,
  dueDateOption,
}) =>
  Yup.object().shape({
    parentOrgId: Yup.string().label('Parent Entity').required(),
    description: Yup.string()
      .label('Description')
      .max(100, 'Description cannot be longer than 100 characters')
      .nullable(),
    consolidationMethod: Yup.string()
      .oneOf(Object.keys(ConsolidationMethodFilingTypes))
      .label('Consolidation method')
      .required(),
    lockedInd: Yup.boolean().label('Locked').required(),
    active: Yup.boolean().label('Active').required(),
    allowInterCompany: Yup.boolean().label('Allow Interco.').nullable(),
    nonFiling: Yup.boolean().label('Non Filing.').nullable(),
    consolFormId: Yup.string()
      .label('Consol Form Id')
      .max(20, 'Consol Form Id cannot be longer than 20 characters'),
    federalFilingGroupId: Yup.string()
      .label('Federal Filing Id')
      .max(40, 'Federal Filing Group Id cannot be longer than 40 characters')
      .nullable(),
    proformaFilingGroupId: Yup.string()
      .label('Proforma Filing Group Id')
      .max(40, 'Proforma Filing Group Id cannot be longer than 40 characters')
      .nullable(),
    returnDefinitionId: Yup.string()
      .typeError('Return Definition Id is a required field.')
      .label('Return Definition Id')
      .required()
      .test(
        'mustHaveNonFilingOrFilingSeparateForm',
        'A non-filing separate form or a filing separate form should be associated with this consolidated state filing form. Create a support ticket to report this issue.',
        value => {
          if (!value) {
            return true;
          }

          const returnDefinition = returnDefinitions.find(({ id }) => id === value);
          return Boolean(
            returnDefinition?.nonFilingSeparateReturnDefId ||
              returnDefinition?.filingSeparateReturnDefId,
          );
        },
      ),
    filingMethod: Yup.mixed()
      .label('Filing Method')
      .oneOf(['', null, 'efile', 'paper', 'non-filing', 1, 2, 3])
      .defined()
      .nullable(),
    ...getDueDateSchema({ shouldDisplayDueDate, dueDateOption }),
    ...getFilingTypeSchema(),
    ...getBusinessTypeSchema(),
  });

// used in old/new consolidation wizard update consolidation back end part
const updateConsolidationDbSchema = ({ returnDefinitions = [] }) =>
  updateConsolidationSchema({ returnDefinitions }).concat(
    Yup.object().shape({
      taxYear: Yup.mixed().required(),
      period: Yup.mixed().required(),
      consolidationId: Yup.string().required(),
      jurisdictionId: Yup.string().required(),
      returnDefinitionId: Yup.string().required(),
    }),
  );

module.exports = {
  getAddConsolidationSchema,
  getAddEditConsolidationFormSchema,
  updateConsolidationSchema,
  updateConsolidationDbSchema,
};
