import { useEffect, useState } from 'react';
import { GenericScreenRowTypes } from '@common-packages/shared-constants';

const useSuppressZeros = originalData => {
  const [suppressedData, setSuppressedData] = useState(originalData);

  useEffect(() => {
    const groupCollection = [];
    let currentGroup = [];
    let isCurrentGroupZero = true;
    let isGrouping = false;

    for (let i = 1; i < originalData.length; i++) {
      const prevGenericScreenRowData = originalData[i - 1];
      const genericScreenRowData = originalData[i];
      if (
        genericScreenRowData.rowType === GenericScreenRowTypes.BLANK &&
        prevGenericScreenRowData.rowType !== GenericScreenRowTypes.BLANK
      ) {
        if (
          (isCurrentGroupZero && currentGroup[0]?.rowType === GenericScreenRowTypes.HEADER) ||
          !isCurrentGroupZero
        ) {
          currentGroup.push(genericScreenRowData);
          groupCollection.push(currentGroup);
        }
        currentGroup = [];
        isCurrentGroupZero = true;
        isGrouping = false;
      }

      if (
        genericScreenRowData.rowType !== GenericScreenRowTypes.BLANK &&
        prevGenericScreenRowData.rowType === GenericScreenRowTypes.BLANK
      ) {
        isGrouping = true;
      }

      if (isGrouping) {
        if (
          genericScreenRowData.rowType === GenericScreenRowTypes.DATA &&
          genericScreenRowData.dataCollection +
            genericScreenRowData.adjustment +
            genericScreenRowData.total >
            0
        ) {
          currentGroup.push(genericScreenRowData);
          isCurrentGroupZero = false;
        } else if (genericScreenRowData.rowType !== GenericScreenRowTypes.DATA) {
          currentGroup.push(genericScreenRowData);
        }
      }
    }
    setSuppressedData(groupCollection.flat(1));
  }, [originalData]);

  return {
    suppressedData,
  };
};

export default useSuppressZeros;
