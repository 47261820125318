import {
  ConsolQueryParams,
  GetConsolResponse,
  FetchConsolidationsParams,
  FetchConsolidationsResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

interface FindEntityFiscalYearEndParams {
  taxYear: string;
  period: string;
  businessEntityId: string;
}

interface FindEntityFiscalYearEndResponse {
  fiscalYearEnd: string | null;
}

export const useQueryConsol = ({
  params,
  enabled,
}: {
  params: ConsolQueryParams;
  enabled: boolean;
}) =>
  useCustomQuery<GetConsolResponse>([QueryKeys.Consolidations.Data, params], {
    url: `/api/consolidations/find-consolidation/${params.taxYear}/${params.period}/${params.consolidationId}/${params.jurisdictionId}`,
    enabled,
    errorMessage: 'Fetching consol failed',
  });

export const useQueryFetchConsolidations = ({
  params,
  enabled,
}: {
  params: FetchConsolidationsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FetchConsolidationsResponse>(
    [QueryKeys.Consolidations.FetchConsolidations, params],
    {
      url: `/api/admin/maintenance/consolidations/${params.taxYear}/${params.period}`,
      enabled,
      errorMessage: 'Fetching consolidations failed',
    },
  );

export const useQueryFindEntityFiscalYearEnd = ({
  params,
  enabled,
}: {
  params: FindEntityFiscalYearEndParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindEntityFiscalYearEndResponse>(
    [QueryKeys.ConsolidationFilingEntities.EntityFiscalYearEnd, params],
    {
      url: `/api/shared/due-date/find-fiscal-year-end/${params.taxYear}/${params.period}/${params.businessEntityId}`,
      enabled,
      errorMessage: 'Fetching entity fiscal year end failed',
    },
  );
