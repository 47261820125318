import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import {
  BreadcrumbRoute,
  homeBreadcrumb,
} from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const ReportsBreadcrumbRoutes = () => (
  <>
    <BreadcrumbRoute path={Routes.reports} showText={false} icon={homeBreadcrumb} />
    <BreadcrumbRoute path={Routes.stateTaxableIncomeReconciliation} />
    <BreadcrumbRoute path={Routes.legalEntityAuditTrail} />
    <BreadcrumbRoute path={Routes.highLevelPlanningData} />
    <BreadcrumbRoute path={Routes.filingPositions} />
    <BreadcrumbRoute path={Routes.effectiveTaxRate} />
    <BreadcrumbRoute path={Routes.effectiveTaxRateTaxAccounting} />
    <BreadcrumbRoute path={Routes.entityData} />
    <BreadcrumbRoute path={Routes.localityData} />
    <BreadcrumbRoute path={Routes.everywhereBreakdown} />
    <BreadcrumbRoute path={Routes.estimateSummary} />
    <BreadcrumbRoute path={Routes.state851AffiliateData} />
    <BreadcrumbRoute path={Routes.businessAuditTrail} />
    <BreadcrumbRoute path={Routes.intercompanyInterestExpense} />
    <BreadcrumbRoute path={Routes.intercompanyPayables} />
    <BreadcrumbRoute path={Routes.intercompanyInterestIncome} />
    <BreadcrumbRoute path={Routes.taskWaiting} />
    <BreadcrumbRoute path={Routes.rawDataForAnEntity} />
    <BreadcrumbRoute path={Routes.detailedPlanningData} />
    <BreadcrumbRoute path={Routes.populationAnalysis} />
    <BreadcrumbRoute path={Routes.entityAnalyticsReview} />
    <BreadcrumbRoute path={Routes.efileTransmissionStatusReport} />
    <BreadcrumbRoute path={Routes.eFileFederalReturnsAvailability} />
    <BreadcrumbRoute path={Routes.eFileXmlComparisonReport} />
    <BreadcrumbRoute path={Routes.watermarkUsage} />
    <BreadcrumbRoute path={Routes.dividendToStateAmounts} />
    <BreadcrumbRoute path={Routes.overrideReport} />
    <BreadcrumbRoute path={Routes.multiStateLeTaxableIncome} />
    <BreadcrumbRoute path={Routes.separateManualAdjustmentReport} />
  </>
);

export default ReportsBreadcrumbRoutes;
