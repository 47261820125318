import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@pwc/appkit-react';

import FaIcon from '../../displayComponents/faIcon/faIcon.component';

import { validateNumberCellEditorValue, handleUpOrDownArrowKeyDown } from './utils';

export default class NumberCellEditor extends PureComponent {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    formatValue: PropTypes.func.isRequired,
    colDef: PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
    }).isRequired,
    nullOnEmptyString: PropTypes.bool,
    api: PropTypes.shape({
      getFocusedCell: PropTypes.func.isRequired,
      getDisplayedRowCount: PropTypes.func.isRequired,
      ensureIndexVisible: PropTypes.func.isRequired,
      setFocusedCell: PropTypes.func.isRequired,
      clearRangeSelection: PropTypes.func.isRequired,
      addCellRange: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    step: 'any',
  };

  state = {
    value: this.props.value,
    invalid: false,
  };

  componentDidMount() {
    this.inputRef.current.select();
    this.inputRef.current.focus();
  }

  inputRef = React.createRef();

  getValue = () => {
    const { formatValue, nullOnEmptyString } = this.props;
    const { value } = this.state;

    if (nullOnEmptyString && value === '') {
      return null;
    }

    return value === null ? null : formatValue(value ? value : '0');
  };

  isCancelAfterEnd() {
    const { invalid } = this.state;
    return invalid;
  }

  handleChange = event => {
    const { min, max } = this.props;
    const value = event.target.value;
    const invalid = validateNumberCellEditorValue({ min, max, value });
    this.setState({ value, invalid });
  };

  handleKeyDown = event => {
    const { api } = this.props;
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      handleUpOrDownArrowKeyDown(api, event.key);
    }
  };

  tooltipMessage = () => {
    const { min, max, colDef } = this.props;
    const { invalid, value } = this.state;
    if (invalid && value < min) {
      return `'${colDef.headerName}' must be greater than or equal to ${min}`;
    }
    if (invalid && value > max) {
      return `'${colDef.headerName}' must be less than or equal to ${max}`;
    }
  };

  render() {
    const { min, max, step } = this.props;
    const { invalid } = this.state;
    return (
      <>
        <input
          className="input-without-arrows"
          type="number"
          ref={this.inputRef}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          style={{ width: '100%' }}
          min={min}
          max={max}
          step={step}
          required
        />
        <Tooltip content={this.tooltipMessage()} tooltipTheme="light" offset="0, 5">
          {invalid && <FaIcon className="invalid-icon" icon="info-circle" size={12} />}
        </Tooltip>
      </>
    );
  }
}
