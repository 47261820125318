import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import { AuthorizedEFileRoute } from './routeConfiguration/eFileRoute.container';
import CurrentReturnFiling from './currentReturnFiling/currentReturnFiling.container';
import CurrentReturnFilingV2 from './currentReturnFilingV2/currentReturnFiling.container';
import FederalProformaProcesses from './federalProformaProcesses/federalProformaProcesses.container';
import EFileSetup from './eFileSetup/eFileSetup.container';
import EFileErrors from './eFileErrors/eFileErrors.container';

const EFileRoutes = () => (
  <Switch>
    <AuthorizedEFileRoute exact path={Routes.eFileSetup.MAIN} component={EFileSetup} />
    <AuthorizedEFileRoute
      exact
      path={Routes.currentReturnFiling.MAIN}
      component={CurrentReturnFiling}
      checkEFileIsReadyFlag
    />
    <AuthorizedEFileRoute
      exact
      path={Routes.currentReturnFilingV2.MAIN}
      component={CurrentReturnFilingV2}
      disableBreadcrumbs
      checkEFileIsReadyFlag
    />
    <AuthorizedEFileRoute exact path={Routes.eFileErrors.MAIN} component={EFileErrors} />
    <AuthorizedEFileRoute
      exact
      path={Routes.federalAndProformaProcesses.MAIN}
      component={FederalProformaProcesses}
      disableEFileFlagChecking
    />
  </Switch>
);

export default EFileRoutes;
