import React, { useCallback, useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { Badge, Button, Checkbox, List, ListItem, ListItemText, Tooltip } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';

import Loading from '../../displayComponents/loading.component';
import Select from '../../forms/sdkCustomSelect/formikSdkCustomSelect.component';
import { globalContextSelector } from '../../store/selectors';
import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';
import SearchBar from '../../displayComponents/searchBar/searchBar.component';
import {
  useQueryFilingGroups,
  useQueryFilingGroupOverlappingEntities,
} from '../../queries/filingGroups';
import { ConsolidationFormValues } from '../utils/useFormInitialValues';
import DatePicker from '../../forms/datePickerFormik/datePickerFormik.component';
import { useShouldDisplayReturnsDueDates } from '../../hooks/useShouldDisplayReturnsDueDates.hook';
import { FindReturnDuedateOptionsResponse } from '../../../../../common/types/apiShapes';
import { Entity } from '../../queries/entities';

import styles from './addEditReturnPanel.module.scss';

interface AddEditConsolOptionsFormProps {
  isEditing: boolean;
  dueDateOptionsForConsolidation: FindReturnDuedateOptionsResponse;
  isLoadingDueDateOptionsForConsolidation: boolean;
  entitiesForFilingGroupsData: Entity[] | undefined;
  isLoadingEntitiesForFilingGroupsData: boolean;
  checkedFilingGroupIds: string[];
  setCheckedFilingGroupIds: Dispatch<SetStateAction<string[]>>;
  onBackButtonClick: () => void;
  onNextButtonClick: () => void;
  renderBanner: () => JSX.Element | undefined;
}

const addEditConsolOptionsTabFields = ['parentOrgId', 'taxYearEnding', 'dueDateType'];

const AddEditConsolOptionsForm = ({
  isEditing,
  dueDateOptionsForConsolidation,
  isLoadingDueDateOptionsForConsolidation,
  entitiesForFilingGroupsData,
  isLoadingEntitiesForFilingGroupsData,
  checkedFilingGroupIds,
  setCheckedFilingGroupIds,
  onBackButtonClick,
  onNextButtonClick,
  renderBanner,
}: AddEditConsolOptionsFormProps) => {
  const { taxYear, period } = useSelector(globalContextSelector);
  const history = useHistory();
  const {
    values,
    errors,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<ConsolidationFormValues>();
  const [searchFilter, setSearchFilter] = useState('');

  const hasConsolOptionsErrors = useMemo(
    () => Object.keys(errors).some(errorKey => addEditConsolOptionsTabFields.includes(errorKey)),
    [errors],
  );

  const proformaFilingGroupIdOptions = useMemo(() => {
    return (
      entitiesForFilingGroupsData?.find(({ entityId }) => entityId === values.parentOrgId)
        ?.proformaFilingGroupIdOptions || []
    );
  }, [entitiesForFilingGroupsData, values.parentOrgId]);

  const shouldDisplayDueDate = useShouldDisplayReturnsDueDates();

  const { data: filingGroupsData, isLoading: isLoadingFilingGroupsData } = useQueryFilingGroups({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });

  const {
    data: filingGroupOverlappingEntities = [],
    isLoading: isLoadingFilingGroupOverlappingEntities,
  } = useQueryFilingGroupOverlappingEntities({
    params: { taxYear, period, checkedFilingGroupIds },
    enabled: Boolean(taxYear && period && checkedFilingGroupIds.length > 1),
  });

  const handleSearch = useCallback(
    (newSearchFilter: string) => setSearchFilter(newSearchFilter),
    [],
  );

  useEffect(() => {
    setFieldValue('filingGroupIds', checkedFilingGroupIds, false);
    if (!isEditing && checkedFilingGroupIds.length === 1 && proformaFilingGroupIdOptions) {
      setFieldValue('proformaFilingGroupId', checkedFilingGroupIds[0]);
    }
  }, [
    setFieldValue,
    taxYear,
    period,
    checkedFilingGroupIds,
    proformaFilingGroupIdOptions,
    isEditing,
  ]);

  const handleCheckboxClick = useCallback(
    ({ target: { checked } }: { target: { checked: boolean } }, filingGroupId: string) => {
      setCheckedFilingGroupIds((previousCheckedFilingGroupIds: string[]) =>
        checked
          ? [...previousCheckedFilingGroupIds, filingGroupId]
          : [
              ...previousCheckedFilingGroupIds.filter(
                (previousCheckedFilingGroupId: string) =>
                  previousCheckedFilingGroupId !== filingGroupId,
              ),
            ],
      );
    },
    [setCheckedFilingGroupIds],
  );

  const renderFilingGroupListItem = useCallback(
    ({ filingGroupId, description }: { filingGroupId: string; description: string }) => (
      <React.Fragment key={filingGroupId}>
        <ListItem>
          <Checkbox
            checked={Boolean(
              checkedFilingGroupIds.find(
                checkedFilingGroupId => checkedFilingGroupId === filingGroupId,
              ),
            )}
            disabled={
              isLoadingFilingGroupOverlappingEntities ||
              (filingGroupOverlappingEntities.length > 0 &&
                !filingGroupOverlappingEntities.includes(filingGroupId))
            }
            value={filingGroupId}
            onChange={handleCheckboxClick}
          />
          <ListItemText
            className="filing-group-item"
            primary={[filingGroupId, description].filter(value => value).join(' - ')}
          />
        </ListItem>
      </React.Fragment>
    ),
    [
      handleCheckboxClick,
      checkedFilingGroupIds,
      isLoadingFilingGroupOverlappingEntities,
      filingGroupOverlappingEntities,
    ],
  );

  const redirectToAddFilingGroup = useCallback(
    () =>
      history.push({
        pathname: Routes.consolidationFilingGroups.MAIN,
        state: { fromDashboard: true },
      }),
    [history],
  );

  const FilingGroupListBody = useMemo(
    () =>
      filingGroupsData?.length ? (
        <List>
          {filingGroupsData
            ?.filter(({ filingGroupId, description }) =>
              [filingGroupId, description]
                .filter(value => value)
                .join(' - ')
                .toUpperCase()
                .includes(searchFilter.toUpperCase()),
            )
            .map(filingGroup => renderFilingGroupListItem(filingGroup))}
        </List>
      ) : (
        <div className="no-filing-group-container">
          <div className="no-filing-group-body">
            <p className="no-filing-group-title"> No Filing Groups</p>
            <p className="no-filing-group-desc">
              To populate this section, create one or more filing groups.
            </p>
            <Button size="lg" kind="secondary" onClick={redirectToAddFilingGroup}>
              GO TO FILING GROUPS
            </Button>
          </div>
        </div>
      ),
    [redirectToAddFilingGroup, renderFilingGroupListItem, searchFilter, filingGroupsData],
  );

  const removeFilingGroupId = useCallback(
    (filingGroupId: string) => () => {
      setCheckedFilingGroupIds((previousCheckedFilingGroupIds: string[]) => [
        ...previousCheckedFilingGroupIds.filter(
          previousCheckedFilingGroupId => previousCheckedFilingGroupId !== filingGroupId,
        ),
      ]);
    },
    [setCheckedFilingGroupIds],
  );

  const handleParentOrgIdChange = useCallback(
    (_: string, value: string) => {
      setFieldValue(
        'taxYearEnding',
        entitiesForFilingGroupsData?.find(({ entityId }) => entityId === value)?.fiscalYearEnd ||
          '',
      );

      setFieldTouched('taxYearEnding', true);
    },
    [setFieldValue, setFieldTouched, entitiesForFilingGroupsData],
  );

  return useMemo(
    () => (
      <Loading isLoading={isLoadingFilingGroupsData}>
        <div className={styles.consolOptionsTabHeader}>
          <div className={styles.headerLabelSection}>
            <label className={styles.headerLabelText}>Add Filing Groups</label>
            {Boolean(checkedFilingGroupIds.length) && (
              <div className={styles.checkedCountBadge}>{checkedFilingGroupIds.length}</div>
            )}
          </div>
          {Boolean(checkedFilingGroupIds.length) && (
            <div className={styles.filingGroupBadgeGroupContainer}>
              <div className={styles.filingGroupBadgeGroupHeader}>
                <span>Selected Filing Groups:</span>
              </div>
              <div className={`${styles.filingGroupBadgeWrapper}`}>
                {checkedFilingGroupIds.map((checkedFilingGroupId, index, { length }) =>
                  isLoadingFilingGroupOverlappingEntities && length - 1 === index ? (
                    <Loading key={`checkedFilingGroupIdsLoading${index}`} isLoading small />
                  ) : (
                    <button
                      key={`checkedFilingGroupIdsButton${index}`}
                      className={styles.filingGroupBadgeButtonWrapper}
                      onClick={removeFilingGroupId(checkedFilingGroupId)}
                    >
                      <Badge
                        key={checkedFilingGroupId}
                        name={checkedFilingGroupId}
                        type={
                          filingGroupOverlappingEntities?.includes(checkedFilingGroupId)
                            ? 'secondary'
                            : 'primary'
                        }
                      >
                        {checkedFilingGroupId}
                        <AppkitIcon icon="close" size={16} type="fill" />
                      </Badge>
                    </button>
                  ),
                )}
              </div>
              {Boolean(filingGroupOverlappingEntities.length) && (
                <p className="a-form-error">
                  These filing groups contain one or more of the same members. You can select only
                  one of the filing groups in red.
                </p>
              )}
            </div>
          )}
          <SearchBar onSearch={handleSearch} />
        </div>
        <div className={styles.consolOptionsTabFields}>
          <div className="filing-group-list">{FilingGroupListBody}</div>
          <div className="filing-group-options-select">
            <div>
              <div className="select-label-section">
                <label className="a-form-label">Parent Entity</label>
                <Tooltip
                  content="The Filing Entity for the Consolidation"
                  placement="top"
                  className={styles.addEditReturnPanelTooltip}
                >
                  <AppkitIcon
                    className={styles.nonFilingTooltipIcon}
                    icon="information"
                    type="outline"
                    size={14}
                  />
                </Tooltip>
              </div>
              <Loading isLoading={isLoadingEntitiesForFilingGroupsData} small>
                <Field
                  name="parentOrgId"
                  as={Select}
                  options={entitiesForFilingGroupsData?.map(({ entityId, entityName }) => ({
                    label: [entityId, entityName].filter(value => value).join(' - '),
                    value: entityId,
                  }))}
                  customChangeHandler={handleParentOrgIdChange}
                />
              </Loading>
            </div>
            <div>
              <div className="select-label-section">
                <label className="a-form-label">Filing Group For Federal Pro Forma Data</label>
                <Tooltip
                  content="The filing group you used when creating the federal pro forma return.  If including the federal as-filed return, leave this field blank."
                  placement="top"
                  className={styles.addEditReturnPanelTooltip}
                >
                  <AppkitIcon
                    className={styles.nonFilingTooltipIcon}
                    icon="information"
                    type="outline"
                    size={14}
                  />
                </Tooltip>
              </div>
              <Field
                name="proformaFilingGroupId"
                as={Select}
                options={proformaFilingGroupIdOptions}
                value={values.proformaFilingGroupId}
                isClearable
                shouldUnsetValueWhenNotInOptions={!isLoadingEntitiesForFilingGroupsData}
              />
            </div>
            <Field label="Tax Year Ending" name="taxYearEnding" component={DatePicker} />
            {shouldDisplayDueDate && (
              <div>
                <Select
                  appkitPlaceholder="Select a Date"
                  appkitLabel="Due Date"
                  name="dueDateType"
                  value={values.dueDateType}
                  options={dueDateOptionsForConsolidation || []}
                  isLoading={isLoadingDueDateOptionsForConsolidation}
                  shouldDisplayErrorMessage={Boolean(values?.taxYearEnding && values?.parentOrgId)}
                />
              </div>
            )}
          </div>
        </div>
        {renderBanner()}
        <div className={styles.buttons}>
          <Button
            disabled={
              isLoadingFilingGroupOverlappingEntities || filingGroupOverlappingEntities.length > 0
            }
            size="lg"
            title="Back"
            onClick={onBackButtonClick}
          >
            Back
          </Button>
          <Button
            size="lg"
            title="Next"
            onClick={onNextButtonClick}
            disabled={
              isLoadingEntitiesForFilingGroupsData ||
              isLoadingFilingGroupOverlappingEntities ||
              filingGroupOverlappingEntities.length ||
              !checkedFilingGroupIds.length ||
              hasConsolOptionsErrors
            }
          >
            Next
          </Button>
        </div>
      </Loading>
    ),
    [
      handleSearch,
      onBackButtonClick,
      onNextButtonClick,
      removeFilingGroupId,
      handleParentOrgIdChange,
      renderBanner,
      FilingGroupListBody,
      checkedFilingGroupIds,
      entitiesForFilingGroupsData,
      isLoadingEntitiesForFilingGroupsData,
      isLoadingFilingGroupsData,
      values,
      isLoadingFilingGroupOverlappingEntities,
      filingGroupOverlappingEntities,
      proformaFilingGroupIdOptions,
      dueDateOptionsForConsolidation,
      isLoadingDueDateOptionsForConsolidation,
      shouldDisplayDueDate,
      hasConsolOptionsErrors,
    ],
  );
};

export default AddEditConsolOptionsForm;
