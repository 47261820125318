import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { fetchAccountAuditTrails } from '../../../../shared/store/allReturnData/actions';
import {
  accountAuditTrailsSelector,
  isFetchingAccountAuditTrailsSelector,
} from '../../../../shared/store/allReturnData/selectors';
import { taxYearSelector, periodSelector } from '../../../../shared/store/selectors';
import SelectContextDataInfo from '../../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';

import columnDefinitions from './accountAuditTrails.columnDefinitions';

const AccountAuditTrail = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  fetchAccountAuditTrails,
  accountAuditTrails,
  isFetchingAccountAuditTrails,
  isFetchingContext,
}) => {
  const isContextReady = taxYear && period && orgId && jurisdictionId;
  const isLoading = isFetchingContext || isFetchingAccountAuditTrails;

  useEffect(() => {
    if (isContextReady) {
      fetchAccountAuditTrails({ taxYear, period, orgId, jurisdictionId });
    }
  }, [fetchAccountAuditTrails, isContextReady, taxYear, period, orgId, jurisdictionId]);

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <AgGrid
      rowData={accountAuditTrails}
      columnDefs={columnDefinitions}
      isGridLoading={isLoading}
      withSearchBar
    />
  );
};

AccountAuditTrail.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingAccountAuditTrails: PropTypes.bool.isRequired,
  fetchAccountAuditTrails: PropTypes.func.isRequired,
  accountAuditTrails: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string,
      accountDescription: PropTypes.string,
      accountGroup: PropTypes.string,
      amount: PropTypes.number,
      multiplier: PropTypes.number,
      functionName: PropTypes.string,
      screenAccountGroup: PropTypes.string,
      displayOrder: PropTypes.number,
      amountType: PropTypes.string,
      sourceSystemId: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isFetchingContext: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    accountAuditTrails: accountAuditTrailsSelector(state),
    isFetchingAccountAuditTrails: isFetchingAccountAuditTrailsSelector(state),
  }),
  {
    fetchAccountAuditTrails,
  },
)(AccountAuditTrail);
