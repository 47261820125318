import React, { ReactNode } from 'react';

import styles from './header.module.scss';

interface HeaderProps {
  title?: string | null;
  description?: string;
  children?: ReactNode;
  rightHeaderContent?: ReactNode;
  preHeaderContent?: ReactNode;
}

const Header = ({
  title,
  description,
  children = null,
  rightHeaderContent = null,
  preHeaderContent = null,
}: HeaderProps) => (
  <div className={`row ${styles.headerSection}`}>
    <div className="col">
      {preHeaderContent && <div className={`row ${styles.preHeader}`}>{preHeaderContent}</div>}
      <div className="row">
        <h4 className={styles.title}>{title}</h4>
      </div>
      {description && (
        <div className={`row ${styles.description}`}>
          <span>{description}</span>
        </div>
      )}

      {children && <div className={`row ${styles.subHeader}`}>{children}</div>}
    </div>
    {rightHeaderContent}
  </div>
);

export default Header;
