import { useEffect } from 'react';

import useFetch from '../../shared/hooks/useFetch.hook';
import { httpGetAndParse } from '../../utils/fetchService';

const action = ({ taxYear, period, entityId, jurisdictionId, taxType }) =>
  httpGetAndParse({
    route: `/api/shared/apportionment-rate-details/${taxYear}/${period}/${entityId}/${jurisdictionId}/${taxType}`,
    errorMessage: 'Fetching apportionment rate details failed',
  });

export const createUseFetchApportionmentRateDetailsHook = useFetch => {
  const useFetchApportionmentRateDetails = ({
    taxYear,
    period,
    entityId,
    jurisdictionId,
    taxType,
    isContextReady,
  }) => {
    const {
      data: apportionmentRateDetails,
      isFetching: isFetchingApportionmentRateDetails,
      fetch: fetchApportionmentRateDetails,
    } = useFetch({ action });

    useEffect(() => {
      if (isContextReady) {
        fetchApportionmentRateDetails({ taxYear, period, entityId, jurisdictionId, taxType });
      }
    }, [
      fetchApportionmentRateDetails,
      isContextReady,
      taxYear,
      period,
      entityId,
      jurisdictionId,
      taxType,
    ]);

    return {
      apportionmentRateDetails,
      isFetchingApportionmentRateDetails,
    };
  };
  return useFetchApportionmentRateDetails;
};

export default createUseFetchApportionmentRateDetailsHook(useFetch);
