const Yup = require('yup');

const binderItemShape = Yup.object().shape({
  rowId: Yup.string().required(),
  binderItemType: Yup.number().required(),
  formId: Yup.string().required(),
  displayOrder: Yup.number().required(),
  addForEachMember: Yup.boolean().required(),
});

const updateDefaultBinder = Yup.object().shape({
  rowsPairsWithChanges: Yup.array().of(
    Yup.object().shape({
      newRow: binderItemShape,
    }),
  ),
  rowsToAdd: Yup.array().of(binderItemShape),
  rowsToDelete: Yup.array().of(binderItemShape),
});

module.exports = { updateDefaultBinder };
