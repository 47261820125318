import { useState, useCallback } from 'react';

const useHover = ({ disabled = false }) => {
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (disabled) {
      return;
    }
    setHovered(true);
  }, [disabled]);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  return {
    handleMouseEnter,
    handleMouseLeave,
    isHovered,
  };
};

export default useHover;
