const Yup = require('yup');

const getTaxTypeSchema = () => {
  return {
    taxType: Yup.object()
      .shape({
        incomeTax: Yup.boolean(),
        franchiseTax: Yup.boolean(),
      })
      .test('atLeastOneTaxType', null, ({ franchiseTax, incomeTax }) =>
        franchiseTax || incomeTax
          ? true
          : new Yup.ValidationError('At least one tax type must be selected', null, 'taxType'),
      ),
  };
};

module.exports = { getTaxTypeSchema };
