import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { fetchAccountData } from '../../../../shared/store/allReturnData/actions';
import {
  accountDataSelector,
  isFetchingAccountDataSelector,
} from '../../../../shared/store/allReturnData/selectors';
import { taxYearSelector, periodSelector } from '../../../../shared/store/selectors';
import SelectContextDataInfo from '../../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';

import columnDefinitions from './accountData.columnDefinitions';

const AccountData = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  fetchAccountData,
  accountData,
  isFetchingAccountData,
  isFetchingContext,
}) => {
  const isContextReady = taxYear && period && orgId && jurisdictionId;
  const isLoading = isFetchingContext || isFetchingAccountData;

  useEffect(() => {
    if (isContextReady) {
      fetchAccountData({ taxYear, period, orgId, jurisdictionId });
    }
  }, [fetchAccountData, isContextReady, taxYear, period, orgId, jurisdictionId]);

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <AgGrid
      rowData={accountData}
      columnDefs={columnDefinitions}
      isGridLoading={isLoading}
      withSearchBar
    />
  );
};

AccountData.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingAccountData: PropTypes.bool.isRequired,
  fetchAccountData: PropTypes.func.isRequired,
  accountData: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string,
      accountDescription: PropTypes.string,
      accountGroup: PropTypes.string,
      amount: PropTypes.number,
      functionName: PropTypes.string,
      screenAccountGroup: PropTypes.string,
      amountType: PropTypes.string,
      sourceSystemId: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isFetchingContext: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    accountData: accountDataSelector(state),
    isFetchingAccountData: isFetchingAccountDataSelector(state),
  }),
  {
    fetchAccountData,
  },
)(AccountData);
