import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

export const hasPermissionToAuthorizeContentDeployment = customerPermissions =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.CONTENT_DEPLOYMENT_AUTHORIZER],
  });

export const hasPermissionToDeployContent = customerPermissions =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.CONTENT_DEPLOYER],
  });
