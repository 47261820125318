import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';

const Tasks = () => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Add Data Collection Review Task"
      icon="edit"
      description="Add Data Collection Review Task"
      route={Routes.addDataCollectionReviewTask.MAIN}
    />
    <AuthorizedCard
      title="Add Return Task"
      icon="edit"
      description="Add Return Task"
      route={Routes.addReturnTask.MAIN}
    />
    <AuthorizedCard
      title="Add Legal Entity Review Task"
      icon="edit"
      description="Add Legal Entity Review Task"
      route={Routes.addLegalEntityReviewTask.MAIN}
    />
    <AuthorizedCard
      title="Reassign Task"
      icon="edit"
      description="Reassign Task"
      route={Routes.reassignTask.MAIN}
    />
    <AuthorizedCard
      title="Batch Task Signoff"
      icon="edit"
      description="Batch Task Signoff"
      route={Routes.batchTaskSignoff.MAIN}
    />
  </div>
);

export default Tasks;
