import { adminColumnDefinition, defaultColumnDefinition } from '../../shared/columnDefinitions';
import {
  EditableCheckboxCellRenderer,
  AdminCellRendererFactory,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ isInEditMode, onDeleteIconClick, updateRow }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: !isInEditMode,
    onDeleteIconClick,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Business Group',
      field: 'businessGroup',
      width: 80,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
      width: 300,
      editable: isInEditMode,
      cellEditor: TextCellEditor,
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Active',
      field: 'activeInd',
      width: 60,
      cellStyle: {
        textAlign: 'center',
      },
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      onCellValueChanged,
    },
  ];

  return columnDefinitions;
};
export default getColumnDefinitions;
