import { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '@pwc/appkit-react/lib/Button';
import { Routes } from '@common-packages/routes-definitions';
import cloneDeep from 'lodash.clonedeep';

import {
  DateCellEditor,
  TextCellEditor,
  NumberCellEditor,
  // EditableCheckboxCellRenderer,
} from '../../shared/columnDefinitions/cellRenderers';
import {
  useEditModeButtons,
  useCloneDeep,
  useEditModeNavigationPrompt,
  useRowEditMode,
} from '../../shared/editMode';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../../shared/hooks/useModal.hook';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { reduceQueryString } from '../../utils/routerUtils';
import {
  customerDataSelector,
  isEngagementAdminRoleSelector,
} from '../../shared/store/customerData.selectors';
import useFetch from '../../shared/hooks/useFetch.hook';
import { errorNotification } from '../../shared/notification/store/actions';

import { NOT_ALLOW_EDIT_OWN_ACCOUNT_MESSAGE, isOwnInfo } from './utils';
import { resetValidationResult, deleteUser, fetchUsers } from './store/actions';
import AddUserFormModal from './addUserFormModal.container';
import {
  usersSelector,
  isFetchingUsersSelector,
  isUpdatingUserSelector,
  isCreatingUserSelector,
  isDeletingUserSelector,
} from './store/selectors';
import getColumnDefinitions from './usersMaintenance.columnDefinitions';
import * as api from './store/api';
import styles from './usersMaintenance.module.scss';

const UsersMaintenance = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const customerData = useSelector(customerDataSelector);
  const users = useSelector(usersSelector);
  const isEngagementAdminRole = useSelector(isEngagementAdminRoleSelector);
  const isFetchingUsers = useSelector(isFetchingUsersSelector);
  const isUpdatingUser = useSelector(isUpdatingUserSelector);
  const isCreatingUser = useSelector(isCreatingUserSelector);
  const isDeletingUser = useSelector(isDeletingUserSelector);
  const { isFetching: isUpdatingUsers, fetch: updateUsers } = useFetch({
    action: api.updateUsers,
    successNotificationMessage: 'Users updated successfully',
  });

  const { showModal, modalProps } = useModal();
  const [userToEdit, setUserToEdit] = useState(null);
  const [isUpdatingRows, setUdatingRows] = useState(false);

  const { queryParams, setParams } = useUrlParams();
  const { userId } = queryParams;

  const buttonsDisabled = isFetchingUsers || isUpdatingUser || isUpdatingUsers || isUpdatingRows;

  const onSave = useCallback(
    async ({ rowsPairsWithChanges }) => {
      try {
        if (rowsPairsWithChanges.length > 0) {
          const rowsToUpdate = rowsPairsWithChanges.map(row => row.newRow);
          await updateUsers({ updatedUsers: rowsToUpdate });
        }
        dispatch(fetchUsers());
      } catch (error) {
      } finally {
      }
    },
    [users, updateUsers, isFetchingUsers, setUdatingRows, isUpdatingUsers],
  );

  const redirectToAssignBusinesses = useCallback(
    () =>
      history.push({
        pathname: Routes.assignBusinesses.MAIN,
        search: reduceQueryString({ userId }),
      }),
    [history, userId],
  );

  const onCancel = useCallback(() => {}, [users]);

  const openModalForAdd = useCallback(() => {
    dispatch(resetValidationResult());
    setUserToEdit(null);
    showModal();
  }, [dispatch, showModal]);

  const openModalForEdit = useCallback(
    row => {
      dispatch(resetValidationResult());
      setUserToEdit(row);
      showModal();
    },
    [dispatch, showModal],
  );
  const onDeleteUser = useCallback(
    ({ userId }) =>
      dispatch(
        showConfirmModal({
          title: `Delete user's access?`,
          text: `Are you sure you want to remove this user's access for this client?`,
          confirmCallback: async () => {
            await dispatch(deleteUser(userId));
            dispatch(fetchUsers());
          },
        }),
      ),
    [dispatch],
  );

  const hasExpirationEmpty = dataRows => {
    return dataRows.length > 0 && dataRows.some(item => !item?.expireDate);
  };

  const getUniqueRowId = ({ data: { userId } }) => userId;
  const isLoading = isFetchingUsers || isUpdatingUser || isCreatingUser || isDeletingUser;
  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
    gridApi,
  } = useRowEditMode({
    onSave: onSave,
    onCancel: onCancel,
    rowData: users,
    getUniqueRowId,
    editButtonDisabled: buttonsDisabled,
    appendRowUnderSelectedRow: true,
    saveButtonDisabled: buttonsDisabled || hasExpirationEmpty,
    isFetchAllRows: true,
  });
  const onResetFiltersClick = useCallback(() => {
    gridApi?.setFilterModel(null);
  }, [gridApi]);

  const selectUserToAssignBusinesses = useCallback(
    ({ api }) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        setParams({ queryParams: { userId: selectedRow.userId } });
      } else {
        setParams({ queryParams: { userId: null } });
      }
    },
    [setParams],
  );

  const handleEditIconClick = useCallback(
    row => {
      if (isOwnInfo({ rowData: row, customerData })) {
        dispatch(errorNotification(NOT_ALLOW_EDIT_OWN_ACCOUNT_MESSAGE));
        return;
      }

      openModalForEdit(row);
    },
    [dispatch],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        customerData: customerData,
        updateRow,
        isInEditMode,
        users: users || [],
        onEditIconClick: handleEditIconClick,
        ...(isEngagementAdminRole ? {} : { onDeleteIconClick: onDeleteUser }),
      }),
    [openModalForEdit, onDeleteUser, updateRow, isInEditMode, users],
  );

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col add-button-column">
          <Button
            size="lg"
            className="add-button"
            onClick={openModalForAdd}
            disabled={isFetchingUsers}
          >
            Add User
          </Button>
          <Button
            size="lg"
            className="add-button"
            onClick={redirectToAssignBusinesses}
            disabled={isFetchingUsers || !userId}
          >
            Assign Businesses
          </Button>
          <div className="col add-button-column">
            <div className="add-button-column">{editModeButtons}</div>
          </div>
        </div>
      </div>
      <div className={`row grid-row ${styles.dataGridContainer}`}>
        <div className="col">
          <div className={styles.datagridHeader}>
            <Link to={{}} onClick={onResetFiltersClick}>
              Reset Filters
            </Link>
          </div>
          <AgGrid
            rowData={clonedRowData}
            isGridLoading={isLoading}
            onGridReady={onGridReady}
            columnDefs={columnDefinitions}
            withSearchBar
            enableFillHandle={isInEditMode}
            suppressMultiRangeSelection
            stopEditingWhenCellsLoseFocus
            enableRangeSelection
            fillHandleDirection={'y'}
            onSelectionChanged={selectUserToAssignBusinesses}
            groupDefaultExpanded={2}
            autoGroupColumnDef={{
              headerName: 'Name',
              field: 'name',
              minWidth: 300,
              cellRendererParams: { suppressCount: true },
            }}
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            getDataPath={({ dataPath }) => dataPath}
          />
        </div>
        <AddUserFormModal {...modalProps} userToEdit={userToEdit} />
      </div>
    </>
  );
};

export default UsersMaintenance;
