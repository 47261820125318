import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchCustomReportsTypes,
  fetchCustomReportToEditTypes,
  runCustomQueryTypes,
  runCustomQueryForParamOptionsTypes,
  fetchParamOptionsTypes,
} from './types';

export const initialState = {
  customReports: [],
  isFetchingCustomReports: false,

  customReportToEdit: null,
  isFetchingCustomReportToEdit: false,

  customQueryResult: null,
  isFetchingCustomQueryResult: false,
  fetchingCustomQueryResultError: false,

  customQueryForParamResult: null,
  isFetchingCustomQueryForParamResult: false,
  fetchingCustomQueryForParamResultError: false,

  paramFields: {},
};

const sharedReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchCustomReportsTypes,
      payloadKey: 'customReports',
      fetchingKey: 'isFetchingCustomReports',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchCustomReportToEditTypes,
      payloadKey: 'customReportToEdit',
      fetchingKey: 'isFetchingCustomReportToEdit',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: runCustomQueryTypes,
      payloadKey: 'customQueryResult',
      fetchingKey: 'isFetchingCustomQueryResult',
      errorKey: 'fetchingCustomQueryResultError',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: runCustomQueryForParamOptionsTypes,
      payloadKey: 'customQueryForParamResult',
      fetchingKey: 'isFetchingCustomQueryForParamResult',
      errorKey: 'fetchingCustomQueryForParamResultError',
    }),
    [fetchParamOptionsTypes.START]: (state, [payload]) => ({
      ...state,
      paramFields: {
        ...state.paramFields,
        [payload.paramName]: {
          isFetching: true,
          options: [],
        },
      },
    }),
    [fetchParamOptionsTypes.FAILURE]: (state, [payload]) => ({
      ...state,
      paramFields: {
        ...state.paramFields,
        [payload.paramName]: {
          isFetching: false,
          options: [],
        },
      },
    }),
    [fetchParamOptionsTypes.SUCCESS]: (state, payload) => ({
      ...state,
      paramFields: {
        ...state.paramFields,
        [payload.paramName]: {
          isFetching: false,
          result: payload.result,
        },
      },
    }),
  },
});

export default sharedReducer;
