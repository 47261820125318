import React, { useCallback } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { attachmentsSchema } from '@common-packages/validators';

import { SelectOptionPropTypes } from '../../shared/forms/propTypes';

import AddEditAttachmentForm from './attachmentsForm.component';

const formInitialValues = {
  fileName: '',
  attachmentType: '',
  submissionFileName: '',
  attachmentDescription: '',
  businessId: null,
  pdfFile: null,
};

const AddEditAttachment = ({
  taxYear,
  period,
  jurisdictionId,
  attachmentDefinitionsOptions,
  businessOptions,
  saveAttachment,
  businessUnitAttachments,
  allAttachments,
  values,
  isEditMode,
  isCreatingOrUpdatingAttachment,
  attachmentDefinitions,
}) => {
  const renderForm = useCallback(
    formikProps => (
      <AddEditAttachmentForm
        businessOptions={businessOptions}
        attachmentDefinitionsOptions={attachmentDefinitionsOptions}
        isEditMode={isEditMode}
        isCreatingOrUpdatingAttachment={isCreatingOrUpdatingAttachment}
        attachmentDefinitions={attachmentDefinitions}
        {...formikProps}
      />
    ),
    [
      attachmentDefinitions,
      attachmentDefinitionsOptions,
      businessOptions,
      isCreatingOrUpdatingAttachment,
      isEditMode,
    ],
  );

  const onSubmit = useCallback(
    async (
      { fileName, attachmentType, businessId, submissionFileName, attachmentDescription, pdfFile },
      { setSubmitting },
    ) => {
      setSubmitting(true);
      const formData = {
        formValues: {
          taxYear,
          period,
          jurisdictionId,
          fileName,
          attachmentType,
          businessId,
          submissionFileName,
          attachmentDescription,
          attachmentId: isEditMode ? values.attachmentId : null,
        },
        pdfLibPdfFile: pdfFile,
      };

      await saveAttachment(formData);
      setSubmitting(false);
    },
    [isEditMode, jurisdictionId, period, saveAttachment, taxYear, values],
  );
  return (
    <div className="row">
      <div className="col">
        <Formik
          onSubmit={onSubmit}
          initialValues={isEditMode ? values : formInitialValues}
          enableReinitialize
          validationSchema={attachmentsSchema.attachmentValidationSchema({
            businessUnitAttachments,
            allAttachments,
            isEditMode,
          })}
          validateOnBlur={false}
        >
          {renderForm}
        </Formik>
      </div>
    </div>
  );
};

const AttachmentsPropTypes = PropTypes.shape({
  fileName: PropTypes.string,
  attachmentType: PropTypes.string,
  submissionFileName: PropTypes.string,
  attachmentDescription: PropTypes.string,
  pdfFile: PropTypes.instanceOf(File),
  attachmentId: PropTypes.string,
});

AddEditAttachment.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  jurisdictionId: PropTypes.string,
  attachmentDefinitionsOptions: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
  businessOptions: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
  saveAttachment: PropTypes.func.isRequired,
  values: AttachmentsPropTypes,
  businessUnitAttachments: PropTypes.arrayOf(AttachmentsPropTypes),
  allAttachments: PropTypes.arrayOf(AttachmentsPropTypes),
  isEditMode: PropTypes.bool.isRequired,
  isCreatingOrUpdatingAttachment: PropTypes.bool.isRequired,
  attachmentDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      attachmentDefId: PropTypes.string,
      name: PropTypes.string,
      submissionFileName: PropTypes.string,
      attachmentDescription: PropTypes.string,
    }),
  ).isRequired,
};

export default AddEditAttachment;
