import { connect } from 'react-redux';

import BreadcrumbContentRenderer from '../../shared/displayComponents/breadcrumbContentRenderer/breadcrumbContentRenderer.component';
import {
  consolidationNameSelector,
  isFetchingConsolidationsSelector,
} from '../../shared/store/selectors';

export default connect(state => ({
  label: consolidationNameSelector(state),
  isLoading: isFetchingConsolidationsSelector(state),
}))(BreadcrumbContentRenderer);
