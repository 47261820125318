import { ColDef } from 'ag-grid-community';

import {
  defaultColumnDefinitionWithFilter,
  defaultColumnDefinitionWithoutWidth,
} from '../../../../shared/columnDefinitions';

const columnDefinitions: ColDef[] = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdiction',
    sortable: true,
  },
  {
    ...defaultColumnDefinitionWithoutWidth,
    headerName: 'Data Model',
    field: 'dataModel',
    flex: 1,
    sortable: true,
    suppressMenu: false,
    filter: true,
    menuTabs: ['filterMenuTab'],
  },
  {
    ...defaultColumnDefinitionWithoutWidth,
    headerName: 'Dataset',
    field: 'dataset',
    flex: 1,
    sortable: true,
    suppressMenu: false,
    filter: true,
    menuTabs: ['filterMenuTab'],
  },
  {
    ...defaultColumnDefinitionWithoutWidth,
    headerName: 'Data Item',
    field: 'dataItem',
    flex: 2,
    sortable: true,
    suppressMenu: false,
    filter: true,
    menuTabs: ['filterMenuTab'],
  },
];

export default columnDefinitions;
