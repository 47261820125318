import { connect } from 'react-redux';

import { GlobalContextDropdowns } from '../shared/containerComponents/globalContext';

import { isFetchingContextSelector } from './store/selectors';

export const FilingGroupDashboardGlobalContextDropdowns = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
}))(GlobalContextDropdowns);

export { default as FilingGroupDashboard } from './filingGroupDashboard.container';
export { default as FilingGroupRoutes } from './filingGroupRoutes.component';
