import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';

export default [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Function Name',
    field: 'functionName',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Type',
    field: 'functionType',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Syntax',
    field: 'signature',
    width: 250,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Description',
    field: 'description',
    width: 400,
  },
];
