type ValueType = string | number | null | undefined;

type UpdateRowType = Record<string, ValueType | Record<string, ValueType>>;

type AddRowType = Record<string, ValueType>;

interface RowsToUpdate {
  newRow: UpdateRowType;
  oldRow: UpdateRowType;
}

interface MapWorkapaperInstanceRowsParams {
  rowsToAdd: AddRowType[];
  rowsToUpdate: RowsToUpdate[];
  dataItemDefinitionIds: string[];
}

export const mapWorkpaperInstanceRows = ({
  rowsToAdd,
  rowsToUpdate,
  dataItemDefinitionIds,
}: MapWorkapaperInstanceRowsParams) => {
  const datasetInstancesToUpdate = rowsToUpdate.map(({ newRow }) => ({
    datasetInstanceId: newRow.datasetInstanceId,
    dataItems: Object.entries(newRow)
      .filter(([key]) => dataItemDefinitionIds.includes(key))
      .map(([key, value]) => ({
        dataItemDefinitionId: key,
        value,
      })),
  }));

  const datasetInstancesToAdd = rowsToAdd.map(row => ({
    datasetInstanceId: null,
    dataItems: Object.entries(row)
      .filter(([key]) => dataItemDefinitionIds.includes(key))
      .map(([key, value]) => ({
        dataItemDefinitionId: key,
        value,
      })),
  }));

  return {
    datasetInstancesToUpdate,
    datasetInstancesToAdd,
  };
};
