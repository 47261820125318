import React from 'react';
import { connect } from 'react-redux';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import { isFetchingGlobalContextSelector } from '../../shared/store/selectors';
import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';

import FilingGroupsBreadcrumbRoutes from './filingGroupsBreadcrumbRoutes.component';

const FilingGroupPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: FilingGroupsBreadcrumbRoutes,
}))(GlobalContextPage);

export const AuthorizedFilingGroupRoute = props => (
  <AuthorizedCustomRoute wrapper={FilingGroupPage} {...props} />
);
