import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchFilingMembers = ({ taxYear, period, filingGroupId }) =>
  httpGetAndParse({
    route: `/api/federal-pro-forma-filing-groups/filing-members/${taxYear}/${period}/${filingGroupId}`,
    errorMessage: 'Fetching filing members for filing group failed',
  });

export const updateFilingMembers = rowsToUpdate =>
  httpPut({
    route: '/api/federal-pro-forma-filing-groups/filing-members',
    errorMessage: 'Updating filing members failed',
    body: {
      payload: rowsToUpdate,
    },
  });
