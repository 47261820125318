export enum TabsTypes {
  TAX_RATES = 'TAX_RATES',
  CONSTANTS = 'CONSTANTS',
}

export enum MnemonicDisplayValues {
  PAYROLL_WEIGHT = 'Payroll Weight',
  PROPERTY_WEIGHT = 'Property Weight',
  SALES_WEIGHT = 'Sales Weight',
}
