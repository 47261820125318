export const reduceQueryString = routeQueryString => {
  const str = new URLSearchParams(routeQueryString).toString();

  return str && `?${str}`;
};

export const createQueryString = paramsObj => {
  const filteredParams = Object.entries(paramsObj).filter(
    ([, value]) => typeof value !== 'undefined',
  );
  return reduceQueryString(filteredParams);
};
