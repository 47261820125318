import { HttpMethods } from '../enums';
import { QueryError, QueryKeys } from '../queries';
import { UpdateMemberReturnsBody } from '../../../../common/types/apiShapes';

import { useCustomMutation } from '.';

export const useMutationUpdateMemberReturns = () =>
  useCustomMutation<null, QueryError, UpdateMemberReturnsBody>({
    handleData: data => ({
      url: `/api/member-returns/consolidations/${data.taxYear}/${data.period}/${data.jurisdictionId}/${data.consolidationId}`,
      method: HttpMethods.PUT,
      body: data,
    }),
    resetKey: QueryKeys.MemberReturns.Data,
    successMessage: 'Member returns updated successfully',
    errorMessage: 'An error occurred while updating member returns',
  });
