import { apiAction } from '../../../../shared/store/apiAction';
import * as api from '../api';
import { fetchJurisdictionsTypes, SELECT_JURISDICTION_ID } from '../types/shared';

export const fetchJurisdictions = apiAction({
  types: fetchJurisdictionsTypes,
  apiAction: api.fetchJurisdictions,
});

export const selectJurisdiction = jurisdictionId => ({
  type: SELECT_JURISDICTION_ID,
  payload: jurisdictionId,
});
