export const preparePdfFormDataWithJsonPayload = ({ formValues, pdfLibPdfFile, bnaPdfFile }) => {
  const formData = new FormData();
  formData.append('values', JSON.stringify(formValues));
  if (pdfLibPdfFile) {
    formData.append('pdfLibPdfFile', pdfLibPdfFile);
  }
  if (bnaPdfFile) {
    formData.append('bnaPdfFile', bnaPdfFile);
  }
  return formData;
};
