import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';

import { fetchEntityFilingDecisionsTypes, fetchFilingDecisionsAmountDetailsTypes } from './types';

export const initialState = {
  entityFilingDecisions: [],
  isFetchingEntityFilingDecisions: false,

  isFetchingAmountDetails: false,
  amountDetails: [],
};

const filingDecisionsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchEntityFilingDecisionsTypes,
      payloadKey: 'entityFilingDecisions',
      fetchingKey: 'isFetchingEntityFilingDecisions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFilingDecisionsAmountDetailsTypes,
      payloadKey: 'amountDetails',
      fetchingKey: 'isFetchingAmountDetails',
    }),
  },
});

export default filingDecisionsReducer;
