import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../shared/store/reducerFactory';

import { fetchBusinessGroupsTypes, fetchEntitiesTypes, RESET_ENTITIES } from './types';

export const initialState = {
  businessGroups: [],
  isFetchingBusinessGroups: false,
  entities: [],
  isFetchingEntities: false,
};

const toolsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchBusinessGroupsTypes,
      payloadKey: 'businessGroups',
      fetchingKey: 'isFetchingBusinessGroups',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchEntitiesTypes,
      payloadKey: 'entities',
      fetchingKey: 'isFetchingEntities',
    }),
    [RESET_ENTITIES]: state => ({
      ...state,
      entities: [],
    }),
  },
});

export default toolsReducer;
