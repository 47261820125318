const getApportionmentRateDetailsWithSections = (sections, apportionmentRateDetails) => {
  if (!apportionmentRateDetails || !apportionmentRateDetails.length) {
    return apportionmentRateDetails;
  }

  const apportionmentRateDetailsWithSections = sections.flatMap((section, index) => {
    const sectionNameRow = {
      sectionName: section,
      catDesc: section,
      isSectionHeader: true,
    };
    const sectionData = apportionmentRateDetails.filter(
      apportionmentRateDetail => apportionmentRateDetail.sectionIndex === index,
    );
    if (sectionData.length) {
      sectionData.unshift(sectionNameRow);
      return sectionData;
    }
    return [sectionNameRow];
  });

  return apportionmentRateDetailsWithSections;
};

export default getApportionmentRateDetailsWithSections;
