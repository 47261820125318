const propagateCheckedToChildren = ({ children } = {}, checked = true) => {
  if (Object.keys(children || {}).length) {
    Object.values(children).forEach(value => {
      if (!value.disabled) {
        value.checked = checked;
        propagateCheckedToChildren(value, checked);
      }
    });
  }
};

export default propagateCheckedToChildren;
