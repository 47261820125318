import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from '@pwc/appkit-react';
import classNames from 'classnames';

import AppkitIcon from '../appkitIcon/appkitIcon.component';

const Card = ({
  title,
  description,
  icon,
  route,
  redirect,
  onClick = () => null,
  tooltipContent,
  locked = false,
  disabled = false,
  bottomCardElement = null,
  useSmallCardFormatWithBottomCardElement = false,
}) => {
  const isLockedOrDisabled = locked || disabled;

  const card = (
    <div
      className={classNames('element-card card', {
        locked: tooltipContent && isLockedOrDisabled,
      })}
      onClick={onClick}
    >
      <div className="card-element">
        <div className="icon">
          <AppkitIcon icon={locked ? 'location-locked' : icon} size={60} />
        </div>
      </div>
      <div
        className={`${
          useSmallCardFormatWithBottomCardElement && bottomCardElement
            ? 'small-card-format-for-bottom-element'
            : ''
        }`}
      >
        <div className="card-element">
          <div className="title">{title}</div>
        </div>
        <div className="card-element">
          <div className="description">{description}</div>
        </div>
        <div className="card-element">
          <div className="bottom-card-element">{bottomCardElement}</div>
        </div>
      </div>
    </div>
  );
  const lockedCardWithTooltip = <Tooltip content={tooltipContent}>{card}</Tooltip>;

  if (isLockedOrDisabled && tooltipContent) {
    return (
      <div className="card-wrapper col-sm-6 col-md-4 col-lg-3 card-container">
        {lockedCardWithTooltip}
      </div>
    );
  }

  if (route) {
    return (
      <div
        className={classNames('card-wrapper col-sm-6 col-md-4 col-lg-3 card-container', {
          locked: isLockedOrDisabled,
        })}
      >
        <Link to={route} className="card-container-link">
          {card}
        </Link>
      </div>
    );
  }
  if (redirect) {
    return (
      <div className="card-wrapper col-sm-6 col-md-4 col-lg-3 card-container">
        <a href={redirect} className="card-container-link">
          {card}
        </a>
      </div>
    );
  }
  return (
    <div
      className={classNames('card-wrapper col-sm-6 col-md-4 col-lg-3 card-container', {
        locked: isLockedOrDisabled,
      })}
    >
      {card}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  route: PropTypes.string,
  onClick: PropTypes.func,
  redirect: PropTypes.string,
  tooltipContent: PropTypes.node,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  bottomCardElement: PropTypes.element,
  useSmallCardFormatWithBottomCardElement: PropTypes.bool,
};

export default Card;
