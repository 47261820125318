import { Period, BusinessEntityId, TaxYear, EntityId } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

interface QueryDREMapParams {
  taxYear: TaxYear;
  period: Period;
  businessEntityId: BusinessEntityId;
}

interface DRELastUpdated {
  lastUpdated: string | null;
  hasDREMapping: boolean;
}

interface QueryFederalInformationParams {
  taxYear: TaxYear;
  period: Period;
  entityId: EntityId;
}

interface FederalInfo {
  autoFedRefresh: boolean;
  gtwRefreshStatus: number;
  sltUpdatedOn: string | null;
  gtwUpdatedDate: string | null;
}

export const useQueryDREMap = ({
  params,
  enabled,
}: {
  enabled: boolean;
  params: QueryDREMapParams;
}) =>
  useCustomQuery<DRELastUpdated>([QueryKeys.ManualCalculations.DREMap, params], {
    url: `/api/manual-calculations/dre/${params.taxYear}/${params.period}/${params.businessEntityId}`,
    enabled,
    errorMessage: 'Fetching DRE Info Failed',
  });

export const useQueryFederalInformation = ({
  params,
  enabled,
}: {
  enabled: boolean;
  params: QueryFederalInformationParams;
}) =>
  useCustomQuery<FederalInfo>([QueryKeys.ManualCalculations.FederalInfo, params], {
    url: `/api/manual-calculations/federal-information/${params.taxYear}/${params.period}/${params.entityId}`,
    enabled,
    errorMessage: 'Fetching Federal Info Failed',
  });
