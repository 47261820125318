import { AdminCellRendererFactory } from '../shared/columnDefinitions/cellRenderers';
import { adminColumnDefinition } from '../shared/columnDefinitions';

const defaultColumnDefinition = {
  cellStyle: {
    textAlign: 'left',
  },
  suppressMenu: false,
};

const getColumnDefinitions = ({ onEditIconClick, shouldShowEditIcon }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onEditIconClick,
    shouldShowEditIcon,
    shouldShowDeleteIcon: false,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Federal Pro Forma Filing Group',
      field: 'filingGroupId',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
      width: 500,
      maxWidth: 500,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
