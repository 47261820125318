import { httpGetAndParse, httpPut, httpDelete, httpPost } from '../../../utils/fetchService';

export const fetchBusinessGroups = () =>
  httpGetAndParse({
    route: '/api/admin/maintenance/business-groups',
    errorMessage: 'Fetching Business Groups failed',
  });

export const updateBusinessGroups = ({ rowsToUpdate }) =>
  httpPut({
    route: '/api/admin/maintenance/business-groups',
    errorMessage: 'Updating business groups failed',
    body: { rowsToUpdate },
  });

export const deleteBusinessGroup = ({ businessGroup }) =>
  httpDelete({
    route: `/api/admin/maintenance/business-groups/${businessGroup}`,
    errorMessage: 'Deleting Business Group failed',
  });

export const createBusinessGroup = ({ businessGroup, description, activeInd }) =>
  httpPost({
    route: '/api/admin/maintenance/business-groups',
    errorMessage: 'Creating Business Group failed',
    body: {
      businessGroup,
      description,
      activeInd,
    },
  });
