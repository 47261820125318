import React from 'react';
import PropTypes from 'prop-types';

const MultiElementCellRendererHoc = WrappedComponents => {
  const MultiElementCellRenderer = props => (
    <div>
      {WrappedComponents.map((Component, key) => (
        <Component key={key} {...props} />
      ))}
    </div>
  );

  return MultiElementCellRenderer;
};

MultiElementCellRendererHoc.propTypes = {
  WrappedComponents: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default MultiElementCellRendererHoc;
