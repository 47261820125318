import { CompilationInfo, CompilationResult } from '@tls/slt-types';

import { WARNING_DIAGNOSTIC_TYPE } from './constants';

export const isInitialCompileNeeded = ({ lastCompiled, totalExpressions }: CompilationInfo) =>
  Boolean(!lastCompiled && totalExpressions);
export const isCompileNeeded = ({ changedExpressions, changedDataItems }: CompilationInfo) =>
  Boolean(changedExpressions || changedDataItems);
export const noExpressionsFound = ({ totalExpressions }: CompilationInfo) => !totalExpressions;
export const isUpToDate = ({ lastCompiled, changedExpressions }: CompilationInfo) =>
  Boolean(lastCompiled && !changedExpressions);

export const isEveryCompilationResultOfWarningType = (compilationResult: CompilationResult[]) =>
  Boolean(
    compilationResult.length &&
      compilationResult.every(({ diagnosticType }) => diagnosticType === WARNING_DIAGNOSTIC_TYPE),
  );
