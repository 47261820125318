import { createSelector } from 'reselect';

import { isFetchingTaxYearsDataSelector } from '../../../../shared/store/selectors';

export const jurisdictionIdSelector = ({ admin }) =>
  admin.taxRatesAndConstantsMaintenance.shared.jurisdictionId;

const jurisdictionsSelector = ({ admin }) =>
  admin.taxRatesAndConstantsMaintenance.shared.jurisdictions;

export const isFetchingJurisdictionsSelector = ({ admin }) =>
  admin.taxRatesAndConstantsMaintenance.shared.isFetchingJurisdictions;

export const jurisdictionDescriptionSelector = createSelector(
  [jurisdictionsSelector, jurisdictionIdSelector],
  (jurisdictions, id) => {
    const jurisdiction = jurisdictions.find(({ jurisdictionId }) => jurisdictionId === id);
    return jurisdiction ? jurisdiction.jurisdictionDescription : '';
  },
);

export const jurisdictionsOptionsSelector = createSelector([jurisdictionsSelector], jurisdictions =>
  jurisdictions.map(({ jurisdictionId, jurisdictionDescription }) => ({
    value: jurisdictionId,
    label: jurisdictionDescription,
  })),
);

export const isFetchingContextSelector = createSelector(
  [isFetchingTaxYearsDataSelector, isFetchingJurisdictionsSelector],
  (isFetchingTaxYearsData, isFetchingJurisdictions) =>
    isFetchingTaxYearsData || isFetchingJurisdictions,
);
