export const FIELD_ATTRIBUTES = {
  DATASET_ID: 'datasetId',
  DATAITEM_ID: 'dataItemId',
  ABSOLUTE_VALUES: 'absoluteValue',
  FLIP_SIGN: 'flipSign',
  NEGATIVE: 'parensIfNegative',
  RATIO_AS_PERCENT: 'ratioAsPercentage',
  SUPPRESS_COMMAS: 'suppressCommas',
  VALUE_IF_ZERO: 'valueIfZero',
  DECIMAL_PLACES: 'decimalPlaces',
  WRAP_TEXT: 'wrapText',
  FIRST_ROW_INDT: 'wrapFirstRowIndt',
  FULL_ROW_COUNT: 'wrapFullRowcount',
  ROW_SPACING_INC: 'wrapRowspacingIncrement',
  FORMATTING_STRING: 'formattingString',
  APPEND_TEXT: 'appendText',
  CHAR_SPACING: 'characterSpacing',
  FULLFIELD_PATTERN: 'fullfieldPattern',
  FULLFIELD_LENGTH: 'fullfieldLength',
  PRINT_FIRST_COPY_ONLY: 'printFirstCopyOnly',
  PRINT_LAST_COPY_ONLY: 'printLastCopyOnly',
  UPPER_CASE: 'upperCase',
  CHECK_BOX: 'checkBox',
  FILTER_REG_EXP: 'filterRegExp',
  SUPPRESS_DATASET_DEF_ID: 'suppressFieldDatasetDefId',
  SUPPRESS_FIELD_DATA_ITEM_DEF_ID: 'suppressFieldDataItemDefId',
};

export const initialValues = {
  [FIELD_ATTRIBUTES.DATASET_ID]: null,
  [FIELD_ATTRIBUTES.DATAITEM_ID]: null,
  [FIELD_ATTRIBUTES.ABSOLUTE_VALUES]: false,
  [FIELD_ATTRIBUTES.FLIP_SIGN]: false,
  [FIELD_ATTRIBUTES.NEGATIVE]: false,
  [FIELD_ATTRIBUTES.RATIO_AS_PERCENT]: false,
  [FIELD_ATTRIBUTES.SUPPRESS_COMMAS]: false,
  [FIELD_ATTRIBUTES.VALUE_IF_ZERO]: '0',
  [FIELD_ATTRIBUTES.DECIMAL_PLACES]: '0',
  [FIELD_ATTRIBUTES.WRAP_TEXT]: false,
  [FIELD_ATTRIBUTES.FIRST_ROW_INDT]: '0',
  [FIELD_ATTRIBUTES.FULL_ROW_COUNT]: null,
  [FIELD_ATTRIBUTES.ROW_SPACING_INC]: null,
  [FIELD_ATTRIBUTES.FORMATTING_STRING]: '',
  [FIELD_ATTRIBUTES.APPEND_TEXT]: '',
  [FIELD_ATTRIBUTES.CHAR_SPACING]: null,
  [FIELD_ATTRIBUTES.FULLFIELD_PATTERN]: null,
  [FIELD_ATTRIBUTES.FULLFIELD_LENGTH]: null,
  [FIELD_ATTRIBUTES.PRINT_FIRST_COPY_ONLY]: false,
  [FIELD_ATTRIBUTES.PRINT_LAST_COPY_ONLY]: false,
  [FIELD_ATTRIBUTES.UPPER_CASE]: false,
  [FIELD_ATTRIBUTES.CHECK_BOX]: '0',
  [FIELD_ATTRIBUTES.FILTER_REG_EXP]: '',
  [FIELD_ATTRIBUTES.SUPPRESS_DATASET_DEF_ID]: null,
  [FIELD_ATTRIBUTES.SUPPRESS_FIELD_DATA_ITEM_DEF_ID]: null,
};

export const checkboxOptions = [
  {
    label: 'N/A',
    value: '0',
  },
  {
    label: 'Standard Checkbox',
    value: '1',
  },
  {
    label: 'Checkbox-Yes',
    value: 'Y',
  },
  {
    label: 'Checkbox-No',
    value: 'N',
  },
];

export const valueIfZeroOptions = [
  {
    label: 'Blank',
    value: '0',
  },
  {
    label: 'Zero',
    value: '1',
  },
  {
    label: 'None',
    value: '2',
  },
  {
    label: 'N/A',
    value: '3',
  },
];

export const DEFAULT_WRAPPING_OPTIONS = {
  [FIELD_ATTRIBUTES.FIRST_ROW_INDT]: '0',
  [FIELD_ATTRIBUTES.FULL_ROW_COUNT]: null,
  [FIELD_ATTRIBUTES.ROW_SPACING_INC]: null,
};
