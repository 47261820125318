import { defaultColumnDefinition } from '../../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Org Id',
    field: 'orgId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Id',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction Id',
    field: 'jurisdictionId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Beg End Flag',
    field: 'begEndFlag',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Amount',
    field: 'amount',
  },
];

const columnDefinitionsWithError = [
  ...columnDefinitions,
  {
    ...defaultColumnDefinition,
    headerName: 'Error',
    field: 'errorMessage',
  },
];

export { columnDefinitions, columnDefinitionsWithError };
