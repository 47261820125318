import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { format, parse } from 'date-fns';

import { OverridableFormDateFormat } from '../../../constants';
import DatePicker from '../../../../shared/displayComponents/datePicker/datePicker.component';
import { OverridableFieldPropTypes } from '../../overridableForm.propTypes';
import { getCurrentValue } from '../../utils/getCurrentValue';

import styles from './overridableDatePicker.module.scss';

const parseDate = ({ destinationFormat, date, blockId }) => {
  if (!date) {
    return '';
  }

  const currentFormat =
    destinationFormat === OverridableFormDateFormat.Source
      ? OverridableFormDateFormat.Input
      : OverridableFormDateFormat.Source;

  try {
    return format(parse(date, currentFormat, new Date()), destinationFormat);
  } catch (error) {
    // Defensive logic for future print-service changes
    // eslint-disable-next-line no-console
    console.error(`Invalid date value for field ${blockId}`);
    return date;
  }
};

const OverridableDatePicker = ({
  style = {},
  field: { name, value },
  form: { setFieldValue },
  className = '',
  disabled = false,
  handleBlur,
  handleFocus,
  handleFieldUpdateAndFormatting,
  handleMouseLeave,
}) => {
  const { blockId, formattedData } = value;

  const datePickerRef = useRef();

  const handleClearSelection = useCallback(() => {
    setFieldValue(name, {
      ...value,
      overrideValue: '',
      formattedData: '',
    });
    handleBlur();
  }, [setFieldValue, handleBlur, name, value]);

  const handleChange = useCallback(
    newDate => {
      // Added this because of appkit datepicker bug, which is causing this behavior on clearDate
      if (newDate === '__/__/____') {
        return;
      }
      handleMouseLeave();
      const parsedDate = parseDate({
        date: newDate,
        blockId,
        destinationFormat: OverridableFormDateFormat.Source,
      });
      handleFieldUpdateAndFormatting({
        newValue: parsedDate,
      });
    },
    [handleMouseLeave, handleFieldUpdateAndFormatting, blockId],
  );

  const calendarValue = parseDate({
    date: getCurrentValue(value),
    blockId,
    destinationFormat: OverridableFormDateFormat.Input,
  });

  const handleDatePickerPosition = useCallback(() => {
    const expandedCalendar = datePickerRef.current.querySelector('.react-calendar');
    if (expandedCalendar) {
      const expandedCalendarRect = expandedCalendar.getBoundingClientRect();
      // Look for the closest page ancestor
      const pageRect = datePickerRef.current.closest('.pf').getBoundingClientRect();
      if (expandedCalendarRect.bottom > pageRect.bottom) {
        expandedCalendar.style.top = `-${
          expandedCalendarRect.bottom -
          expandedCalendarRect.top +
          datePickerRef.current.clientHeight
        }px`;
      }
      if (expandedCalendarRect.right > pageRect.right) {
        expandedCalendar.style.left = `-${expandedCalendarRect.right - pageRect.right + 10}px`;
      }
      expandedCalendar.style.opacity = '100%';
    }
  }, []);

  return useMemo(
    () => (
      <div
        className={styles.overridableDatePicker}
        style={style}
        onClick={handleDatePickerPosition}
        ref={datePickerRef}
      >
        <DatePicker
          className="date-picker-formik"
          inputClassName={className}
          name={name}
          value={calendarValue}
          disabled={disabled}
          formattedInputValue={formattedData}
          onClearSelection={handleClearSelection}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          isLarge={false}
          isEditable={false}
        />
      </div>
    ),
    [
      calendarValue,
      className,
      disabled,
      formattedData,
      handleBlur,
      handleChange,
      handleClearSelection,
      handleDatePickerPosition,
      handleFocus,
      name,
      style,
    ],
  );
};

OverridableDatePicker.propTypes = {
  style: PropTypes.shape(CSSStyleDeclaration),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: OverridableFieldPropTypes.isRequired,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleFieldUpdateAndFormatting: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
};

export default OverridableDatePicker;
