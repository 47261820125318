import React from 'react';
import PropTypes from 'prop-types';
import { Input, Tooltip } from '@pwc/appkit-react';

import { InputSizes } from '../constants';

const inputFormikFactory = ({ valueFormatter = null, displayFormatter = null } = {}) =>
  class InputFormik extends React.PureComponent {
    static propTypes = {
      className: PropTypes.string,
      type: PropTypes.string,
      size: PropTypes.oneOf(Object.values(InputSizes)),
      label: PropTypes.string,
      form: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
        setTouched: PropTypes.func.isRequired,
        touched: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
      }).isRequired,
      field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
      }).isRequired,
      disabled: PropTypes.bool,
      customChangeHandler: PropTypes.func,
      hasTooltip: PropTypes.bool,
    };

    static defaultProps = {
      className: '',
      type: 'text',
      size: InputSizes.SM,
      disabled: false,
      label: '',
      customChangeHandler: () => null,
    };

    state = {
      isFieldFocused: false,
      isTooltipDisabled: true,
      inputRef: React.createRef(),
    };

    componentDidUpdate() {
      const hasTooltip = !this.props.hasTooltip;
      if (hasTooltip) {
        return;
      }
      const inputEl = this.state.inputRef.current?.querySelector('.a-input-text');
      if (inputEl?.offsetWidth === inputEl?.scrollWidth) {
        this.setState({ isTooltipDisabled: true });
        return;
      }
      this.setState({ isTooltipDisabled: false });
    }

    handleBlur = () => {
      const {
        field: { name },
        form: { touched, setTouched },
      } = this.props;
      setTouched({
        ...touched,
        [name]: true,
      });
      this.setState({ isFieldFocused: false });
    };

    handleFocus = () => {
      this.setState({ isFieldFocused: true });
    };

    handleChange = value => {
      const {
        field: { name },
        form: { setFieldValue, touched, setFieldTouched },
        customChangeHandler,
      } = this.props;

      const formattedValue = valueFormatter ? valueFormatter(value) : value;

      if (!touched[name]) {
        setFieldTouched(name, true);
      }
      setFieldValue(name, formattedValue);
      customChangeHandler(name, formattedValue);
    };

    renderInput = () => {
      const { className, type, size, label, field, form, disabled } = this.props;
      // eslint-disable-next-line no-unused-vars
      const { customChangeHandler, ...restProps } = this.props;
      const errorMessage = form.errors[field.name];
      const touched = form.touched[field.name];
      const displayValue = displayFormatter ? displayFormatter(field.value) : field.value;
      const value = this.state.isFieldFocused ? field.value : displayValue;

      return (
        <Input
          className={className}
          type={type}
          size={size}
          label={label}
          name={field.name}
          value={value || ''}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          hasError={errorMessage && touched}
          errMsg={errorMessage}
          disabled={disabled}
          autoComplete="off"
          {...restProps}
        />
      );
    };

    renderInputWithTooltip = () => {
      const { field } = this.props;
      const displayValue = displayFormatter ? displayFormatter(field.value) : field.value;
      const value = this.state.isFieldFocused ? field.value : displayValue;

      return (
        <Tooltip
          content={<span className="sdk-custom-input-tooltip-content">{value || ''}</span>}
          disabled={this.state.isTooltipDisabled}
          placement="top"
          tooltipTheme="light"
          hasArrow
        >
          <div ref={this.state.inputRef}>{this.renderInput()}</div>
        </Tooltip>
      );
    };

    render() {
      return this.props.hasTooltip ? this.renderInputWithTooltip() : this.renderInput();
    }
  };

export default inputFormikFactory;
