import { createSelector } from 'reselect';
import { TOTAL_DESC } from '@common-packages/shared-constants';

export const isFetchingFederalReconciliationVariancesSelector = ({ entity }) =>
  entity.federalReconciliations.variances.isFetchingVariances;

export const federalReconciliationVariancesSelector = ({ entity }) =>
  entity.federalReconciliations.variances.variances;

export const federalReconciliationVariancesWithTotalSelector = createSelector(
  [federalReconciliationVariancesSelector],
  federalReconciliationVariances =>
    federalReconciliationVariances.length
      ? [
          ...federalReconciliationVariances,
          {
            componentId: TOTAL_DESC,
            isTotalRow: true,
            federalAmount: federalReconciliationVariances.reduce(
              (acc, curr) => acc + curr.federalAmount,
              0,
            ),
            reconVariance: federalReconciliationVariances.reduce(
              (acc, curr) => acc + curr.reconVariance,
              0,
            ),
            sltAmount: federalReconciliationVariances.reduce(
              (acc, curr) => acc + curr.sltAmount,
              0,
            ),
          },
        ]
      : [],
);
