import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';

import { fetchBusinessGroupsTypes, updateBusinessGroupsTypes } from './types';

export const initialState = {
  businessGroups: [],
  isFetchingBusinessGroups: false,
  isUpdatingBusinessGroups: false,
};

const businessGroupsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchBusinessGroupsTypes,
      payloadKey: 'businessGroups',
      fetchingKey: 'isFetchingBusinessGroups',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateBusinessGroupsTypes,
      fetchingKey: 'isUpdatingBusinessGroups',
    }),
  },
});

export default businessGroupsReducer;
