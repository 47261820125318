export enum ParamTypeValue {
  INPUT = 'INPUT',
  DROPDOWN = 'DROPDOWN',
}

export enum ContextItemValue {
  NONE = 'NONE',
  TAX_YEAR = 'TAX_YEAR',
  PERIOD = 'PERIOD',
  JURISDICTION_ID = 'JURISDICTION_ID',
  ENTITY_ID = 'ENTITY_ID',
  CONSOLIDATION_ID = 'CONSOLIDATION_ID',
  USER_ID = 'USER_ID',
}
