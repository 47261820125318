import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import { taxYearSelector, periodSelector } from '../../shared/store/selectors';
import { SELECT_ALL_OPTION_VALUE } from '../../shared/reports/constants';
import styles from '../../shared/reports/reportParam.module.scss';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import getReportUrl from '../shared/getReportUrl';
import { mapAllSelectedPossibilitiesToSingleValue } from '../utils';

import columnDefinitions from './detailedPlanningData.columnDefinitions';

const reportTypes = {
  ENTITY_ALL_STATES: 'ENTITY_ALL_STATES',
  MULTI_ENTITY_MULTI_STATE: 'MULTI_ENTITY_MULTI_STATE',
};

const REPORT_TYPE_OPTIONS = [
  { value: reportTypes.ENTITY_ALL_STATES, label: 'Entity, All States' },
  { value: reportTypes.MULTI_ENTITY_MULTI_STATE, label: 'Multi-Entity, Multi-State' },
];

const FILTER_VALUES = {
  SUPPRESS_COMPONENTS: 'SUPPRESS_COMPONENTS',
  FILING_ENTITIES_ONLY: 'FILING_ENTITIES_ONLY',
};

const FILTER_OPTIONS = [
  { value: FILTER_VALUES.SUPPRESS_COMPONENTS, label: 'Suppress Components' },
  { value: FILTER_VALUES.FILING_ENTITIES_ONLY, label: 'Filing Entities Only' },
];

const DetailedPlanningData = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const endpointBasePath = getReportUrl('detailed-planning-data');

  const [reportType, setReportType] = useState(REPORT_TYPE_OPTIONS[0].value);
  const [selectedFilters, setSelectedFilters] = useState([FILTER_OPTIONS[1].value]);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({
    endpointBasePath,
    defaultTaxYear: taxYear,
    defaultPeriod: Number(period),
  });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        suppressComponents: selectedFilters.includes(FILTER_VALUES.SUPPRESS_COMPONENTS),
      }),
      [taxYearParam.paramValue, periodParam.paramValue, selectedFilters],
    ),
  });

  const setEntityId = entityIdParam.setParamValue;

  useEffect(() => {
    const entities = entityIdParam.paramData;
    if (!entities) {
      return;
    } else if (reportType === reportTypes.ENTITY_ALL_STATES && entities.length) {
      setEntityId(entities[0].value);
    } else if (reportType === reportTypes.MULTI_ENTITY_MULTI_STATE) {
      setEntityId(SELECT_ALL_OPTION_VALUE);
    }
  }, [reportType, setEntityId, entityIdParam.paramData]);

  const jurisdictionIdParam = useReportParam({
    endpointBasePath,
    paramName: 'jurisdictionId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  const categoryIdParam = useReportParam({
    endpointBasePath,
    paramName: 'categoryId',
    params: useMemo(() => ({}), []),
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([
    taxYearParam,
    periodParam,
    entityIdParam,
    jurisdictionIdParam,
    categoryIdParam,
  ]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        entityId: mapAllSelectedPossibilitiesToSingleValue(
          entityIdParam.paramValue,
          entityIdParam.paramData,
        ),
        jurisdictionId: mapAllSelectedPossibilitiesToSingleValue(
          jurisdictionIdParam.paramValue,
          jurisdictionIdParam.paramData,
        ),
        categoryId: mapAllSelectedPossibilitiesToSingleValue(
          categoryIdParam.paramValue,
          categoryIdParam.paramData,
        ),
        filingInd: selectedFilters.includes(FILTER_VALUES.FILING_ENTITIES_ONLY),
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        entityIdParam.paramValue,
        entityIdParam.paramData,
        jurisdictionIdParam.paramValue,
        jurisdictionIdParam.paramData,
        categoryIdParam.paramValue,
        categoryIdParam.paramData,
        selectedFilters,
      ],
    ),
  });

  const { props, onGridReady } = useActionMenuForExport({ reportData });

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown
            label="Report Type"
            param={useMemo(
              () => ({
                isFetchingParamData: false,
                paramData: REPORT_TYPE_OPTIONS,
                paramValue: reportType,
                setParamValue: setReportType,
              }),
              [reportType],
            )}
          />
          <ReportParamMultiSelect
            label="Filter"
            showSelectAll={false}
            param={useMemo(
              () => ({
                isFetchingParamData: false,
                paramData: FILTER_OPTIONS,
                paramValue: selectedFilters,
                setParamValue: setSelectedFilters,
              }),
              [selectedFilters],
            )}
          />
          {reportType === reportTypes.ENTITY_ALL_STATES ? (
            <ReportParamDropdown label="Entity" param={entityIdParam} />
          ) : (
            <ReportParamMultiSelect
              label="Entity"
              param={entityIdParam}
              selectedAll
              ignoreSelectAllThreshold
            />
          )}
          <ReportParamMultiSelect label="Jurisdiction" param={jurisdictionIdParam} />
          <ReportParamMultiSelect label="Category" param={categoryIdParam} />
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DetailedPlanningData;
