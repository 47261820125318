const Yup = require('yup');

const { FILING_TYPES } = require('../sharedConstants/filingAttributes');

const getFilingTypeSchema = () => {
  return {
    FILING_TYPE: Yup.mixed().when(FILING_TYPES, {
      is: (
        FILING_TYPE_SEPARATE,
        FILING_TYPE_CONS_POST_APP,
        FILING_TYPE_CONS_PRE_APP,
        FILING_TYPE_CONS_UNITARY,
      ) =>
        !(
          FILING_TYPE_SEPARATE ||
          FILING_TYPE_CONS_POST_APP ||
          FILING_TYPE_CONS_PRE_APP ||
          FILING_TYPE_CONS_UNITARY
        ),
      // eslint-disable-next-line no-undefined
      then: Yup.string().notOneOf([null, undefined, ''], 'Must have a Filing Type'),
    }),
  };
};

module.exports = { getFilingTypeSchema };
