import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import { useRowEditMode } from '../../shared/editMode';
import Loading from '../../shared/displayComponents/loading.component';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import toggleAllSelectionsHeaderFactory from '../../shared/displayComponents/toggleAllSelectionsHeaderFactory';

import { fetchBusinesses, updateUserBusinesses, resetBusinesses } from './store/actions';
import {
  isFetchingBusinessesSelector,
  userOptionsSelector,
  businessesSelector,
  isUpdatingUserBusinessesSelector,
  isFetchingUsersSelector,
} from './store/selectors';
import getColumnDefinitions from './assignBusinesses.columnDefinitions';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const AssignBusinesses = ({
  userOptions,
  fetchBusinesses,
  businesses,
  isFetchingBusinesses,
  updateUserBusinesses,
  isUpdatingUserBusinesses,
  resetBusinesses,
  isFetchingUsers,

  hasUserPermissionsToEdit,
}) => {
  const [selectAllAssignedState, setSelectAllAssignedState] = useState(false);
  const { queryParams, setParams } = useUrlParams();

  useEffect(() => {
    resetBusinesses();
  }, [resetBusinesses]);
  const [userId, setUserId] = useState(queryParams.userId);

  const handleUserChange = useCallback(
    ({ value }) => {
      setParams({ queryParams: { userId: null } });
      setUserId(value);
    },
    [setParams],
  );

  useEffect(() => {
    if (userId) {
      fetchBusinesses({ userId });
    }
  }, [fetchBusinesses, userId]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        businessId: oldRow.businessId,
        isUserAssigned: newRow.isUserAssigned,
        userId,
      }));

      await updateUserBusinesses(rowsToUpdate);
      await fetchBusinesses({ userId });
    },
    [fetchBusinesses, updateUserBusinesses, userId],
  );

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
    gridApi,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: businesses,
    getUniqueRowId,
    editButtonDisabled:
      isFetchingBusinesses || !userId || isUpdatingUserBusinesses || !businesses.length,
  });

  const isAllSelected = clonedRowData.every(({ isUserAssigned }) => isUserAssigned);
  useEffect(() => setSelectAllAssignedState(isAllSelected), [isAllSelected]);

  const handleOverrideAllValues = useCallback(
    newValue => {
      gridApi.forEachNodeAfterFilter(({ data }) => {
        data.isUserAssigned = newValue;
        updateRow(data);
      });
      gridApi.refreshCells({ force: true });
    },
    [gridApi, updateRow],
  );

  const columnDefinitions = useMemo(() => {
    const ToggleAllSelectionsHeader = toggleAllSelectionsHeaderFactory({
      togglerState: selectAllAssignedState,
      setTogglerState: setSelectAllAssignedState,
      isInEditMode,
      handleOverrideAllValues,
    });

    return getColumnDefinitions({
      updateRow,
      isInEditMode,
      ToggleAllSelectionsHeader,
    });
  }, [updateRow, isInEditMode, handleOverrideAllValues, selectAllAssignedState]);

  return (
    <>
      {navigationPrompt}
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-6">
          <Loading isLoading={isFetchingUsers} small>
            <SDKCustomSelect
              className="sdk-custom-select form-select"
              options={userOptions}
              onChange={handleUserChange}
              value={userId}
              virtualized
              appkitLabel="User"
              disabled={isInEditMode}
            />
          </Loading>
        </div>
        {hasUserPermissionsToEdit && (
          <div className="col-6 add-button-column">{editModeButtons}</div>
        )}
      </div>
      <div className="row grid-row">
        <div className="col-12">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingBusinesses || isUpdatingUserBusinesses}
            onGridReady={onGridReady}
            withSearchBar
            suppressCellFocus={!isInEditMode}
          />
        </div>
      </div>
    </>
  );
};

AssignBusinesses.propTypes = {
  userOptions: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
  fetchBusinesses: PropTypes.func.isRequired,
  businesses: PropTypes.arrayOf(
    PropTypes.shape({
      business: PropTypes.string,
      businessId: PropTypes.string,
    }),
  ),
  isFetchingBusinesses: PropTypes.bool.isRequired,
  updateUserBusinesses: PropTypes.func.isRequired,
  isUpdatingUserBusinesses: PropTypes.bool.isRequired,
  resetBusinesses: PropTypes.func.isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    userOptions: userOptionsSelector(state),
    businesses: businessesSelector(state),
    isFetchingBusinesses: isFetchingBusinessesSelector(state),
    isUpdatingUserBusinesses: isUpdatingUserBusinessesSelector(state),
    isFetchingUsers: isFetchingUsersSelector(state),
  }),
  {
    fetchBusinesses,
    updateUserBusinesses,
    resetBusinesses,
  },
)(AssignBusinesses);
