import { apiAction } from '../../../shared/store/apiAction';
import * as developmentApi from '../../store/api';

import * as api from './api';
import {
  fetchBaseCalcSpecsTypes,
  updateBaseCalcSpecTypes,
  createBaseCalcSpecTypes,
  fetchConsolCalcSpecsTypes,
  updateConsolCalcSpecsTypes,
  createConsolCalcSpecsTypes,
  fetchK1CalcSpecsTypes,
  fetchK1CalcSpecsOptionsTypes,
  updateK1CalcSpecsTypes,
  createK1CalcSpecsTypes,
  fetchCalcSpecsOptionsTypes,
  fetchCategoriesTypes,
  fetchConspbCalcSpecsTypes,
  updateConspbCalcSpecsTypes,
  createConspbCalcSpecsTypes,
  BLOCK_CALC_SPEC_CONTEXT_CHANGES,
} from './types';

export const fetchCalcSpecsOptions = apiAction({
  types: fetchCalcSpecsOptionsTypes,
  apiAction: api.fetchCalcSpecsOptions,
});

export const fetchCategories = apiAction({
  types: fetchCategoriesTypes,
  apiAction: api.fetchCategories,
});

export const fetchBaseCalcSpecs = apiAction({
  types: fetchBaseCalcSpecsTypes,
  apiAction: developmentApi.fetchBaseCalcSpecs,
});

export const updateBaseCalcSpec = apiAction(
  {
    types: updateBaseCalcSpecTypes,
    apiAction: api.updateBaseCalcSpec,
  },
  {
    successNotificationMessage: () => 'Updating calc spec succeeded',
    errorNotificationMessage: () => 'Updating calc spec failed',
  },
);

export const createBaseCalcSpec = apiAction(
  {
    types: createBaseCalcSpecTypes,
    apiAction: api.createBaseCalcSpec,
  },
  {
    successNotificationMessage: () => 'Creating calc spec succeeded',
    errorNotificationMessage: () => 'Creating calc spec failed',
  },
);

export const fetchConsolCalcSpecs = apiAction({
  types: fetchConsolCalcSpecsTypes,
  apiAction: developmentApi.fetchConsolCalcSpecs,
});

export const updateConsolCalcSpecs = apiAction(
  {
    types: updateConsolCalcSpecsTypes,
    apiAction: api.updateConsolCalcSpecs,
  },
  {
    successNotificationMessage: () => 'Updating consol calc spec succeeded',
    errorNotificationMessage: () => 'Updating consol calc spec failed',
  },
);

export const createConsolCalcSpecs = apiAction(
  {
    types: createConsolCalcSpecsTypes,
    apiAction: api.createConsolCalcSpecs,
  },
  {
    successNotificationMessage: () => 'Creating consol calc spec succeeded',
    errorNotificationMessage: () => 'Creating consol calc spec failed',
  },
);

export const fetchK1CalcSpecs = apiAction({
  types: fetchK1CalcSpecsTypes,
  apiAction: api.fetchK1CalcSpecs,
});

export const fetchK1CalcSpecsOptions = apiAction({
  types: fetchK1CalcSpecsOptionsTypes,
  apiAction: api.fetchK1CalcSpecsOptions,
});

export const updateK1CalcSpecs = apiAction(
  {
    types: updateK1CalcSpecsTypes,
    apiAction: api.updateK1CalcSpecs,
  },
  {
    successNotificationMessage: () => 'Updating K1 calc spec succeeded',
    errorNotificationMessage: () => 'Updating K1 calc spec failed',
  },
);

export const createK1CalcSpecs = apiAction(
  {
    types: createK1CalcSpecsTypes,
    apiAction: api.createK1CalcSpecs,
  },
  {
    successNotificationMessage: () => 'Creating K1 calc spec succeeded',
    errorNotificationMessage: () => 'Creating K1 calc spec failed',
  },
);

export const fetchConspbCalcSpecs = apiAction({
  types: fetchConspbCalcSpecsTypes,
  apiAction: developmentApi.fetchConspbCalcSpecs,
});

export const updateConspbCalcSpecs = apiAction(
  {
    types: updateConspbCalcSpecsTypes,
    apiAction: api.updateConspbCalcSpecs,
  },
  {
    successNotificationMessage: () => 'Updating CONSPB calc spec succeeded',
    errorNotificationMessage: () => 'Updating CONSPB calc spec failed',
  },
);

export const createConspbCalcSpecs = apiAction(
  {
    types: createConspbCalcSpecsTypes,
    apiAction: api.createConspbCalcSpecs,
  },
  {
    successNotificationMessage: () => 'Creating CONSPB calc spec succeeded',
    errorNotificationMessage: () => 'Creating CONSPB calc spec failed',
  },
);

export const blockContextChanges = payload => ({
  type: BLOCK_CALC_SPEC_CONTEXT_CHANGES,
  payload,
});
