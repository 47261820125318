import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './stickyFooter.module.scss';

interface StickyFooterProps {
  className?: string;
  children?: ReactNode;
}

const StickyFooter = ({ className, children = null }: StickyFooterProps) => (
  <div className={classnames(styles.stickyFooter, className)}>{children}</div>
);

export default StickyFooter;
