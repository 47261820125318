import { parseISO, format, isValid } from 'date-fns';

import config from '../../config';

const dateFormatter = (date, dateFormat = config.DATE_FORMAT) => {
  if (!date || !date.length) {
    return '';
  }

  const parsedIsoDate = parseISO(date);

  if (!isValid(parsedIsoDate)) {
    return date;
  }

  return format(parsedIsoDate, dateFormat);
};

export default dateFormatter;
