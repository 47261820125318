import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchAccountGroups = () =>
  httpGetAndParse({
    route: '/api/entities/manually-entered-data/accounts/groups',
    errorMessage: 'Fetching account groups failed',
  });
export const fetchAccounts = ({ taxYear, period, entityId, accountGroup }) =>
  httpGetAndParse({
    route: `/api/entities/manually-entered-data/accounts/${taxYear}/${period}/${entityId}/${accountGroup}`,
    errorMessage: 'Fetching accounts failed',
  });
export const fetchAccountData = ({ taxYear, period, entityId, accountId }) =>
  httpGetAndParse({
    route: `/api/entities/manually-entered-data/account-data/${taxYear}/${period}/${entityId}/${accountId}`,
    errorMessage: 'Fetching account data failed',
  });

export const updateAccountData = ({ taxYear, period, entityId, accountId, rowsToUpdate }) =>
  httpPut({
    route: `/api/entities/manually-entered-data/account-data/${taxYear}/${period}/${entityId}/${accountId}`,
    errorMessage: 'Updating account data failed',
    body: { rowsToUpdate },
  });
