import { Routes } from '@common-packages/routes-definitions';

const getEntityInfomationData = ({
  isPartnershipType,
  notesTitle,
  ifLegalEntityExist,
  partnershipInformationTitle,
  globalContextParams,
}) => {
  const entityInformationData = [
    {
      title: `${isPartnershipType ? 'Partnership' : 'Entity'} information`,
      icon: 'table-data',
      description: `See ${isPartnershipType ? 'partnership' : 'entity'} information`,
      route: Routes.entityInformation.compiledRoute(globalContextParams),
    },
    {
      title: 'State Account Numbers',
      icon: 'table-data',
      description: 'State account numbers',
      route: Routes.stateAccountNumbers.MAIN,
    },
    {
      title: notesTitle,
      icon: 'table-data',
      description: 'Prepare or review notes',
      route: Routes.prepareReviewNotes.MAIN,
    },
    ifLegalEntityExist
      ? {
          title: 'Filing Decisions',
          icon: 'table-data',
          description: 'Filing decisions info',
          route: '/', // TODO: CARD-REMOVE-DECISION Question #1 https://confluence.ist.pwc.com/display/SLTTechdebts/Questions
        }
      : {},
    {
      title: partnershipInformationTitle,
      icon: 'table-data',
      description: 'Partnership information',
      route: Routes.reviewPartnershipInformation.MAIN,
    },
    {
      title: 'View Partnership Flow-through Amounts',
      icon: 'table-data',
      description: 'Partnership flow',
      route: Routes.partnershipFlowThrough.MAIN,
    },
    {
      title: 'Partnership Allocation Percentages',
      icon: 'table-data',
      description: 'Partnership allocation',
      route: Routes.partnershipAllocationPercentages.MAIN,
    },
    {
      title: 'Add/Edit Global PDF Attachments',
      icon: 'table-data',
      description: 'Global PDF',
      route: Routes.addEditGlobalPdfAttachments.MAIN,
    },
  ];

  return entityInformationData;
};

export default getEntityInfomationData;
