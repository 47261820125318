import React, { useCallback, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { Button, Tooltip } from '@pwc/appkit-react';
import { SelectOption } from '@tls/slt-types';

import Loading from '../../displayComponents/loading.component';
import Select from '../../forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Radio from '../../forms/radioFormik/radioFormik.component';
import useSetFilingMethod from '../../hooks/useSetFilingMethod.hook';
import {
  FindReturnDuedateOptionsResponse,
  ReturnDefinitionStateFilingFormOptions,
  ReturnStatusDefinitionRow,
  TaxReturnDefinitionWithFilingAttributes,
  DueDateOptionValuesEnum,
} from '../../../../../common/types/apiShapes';
import useModal from '../../hooks/useModal.hook';
import { FilingMethods } from '../../enums';
import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';
import { FormValues } from '../utils/useFormInitialValues';
import { useShouldDisplayReturnStatusWhenNotNonFiling } from '../../hooks/useShouldDisplayReturnStatus.hook';
import { useShouldDisplayReturnsDueDatesWhenNotNonFiling } from '../../hooks/useShouldDisplayReturnsDueDates.hook';
import { getResetValuesForFilingMethodChange } from '../utils/getResetValuesForFilingMethodChange';

import styles from './addEditReturnPanel.module.scss';
import PaperFilingConfirmationModal from './paperFilingConfirmationModal.component';

interface AddEditReturnOptionsFormProps {
  isEditing: boolean;
  isLoading: boolean;
  taxReturnsDefinitionsJurisdictionOptions: SelectOption[];
  stateFilingFormOptions?: ReturnDefinitionStateFilingFormOptions;
  taxReturnsDefinition?: TaxReturnDefinitionWithFilingAttributes;
  dueDateOption?: FindReturnDuedateOptionsResponse;
  isLoadingDueDateOption?: boolean;
  returnStatusOption?: ReturnStatusDefinitionRow[];
  onNextButtonClick: () => void;
}

const AddEditReturnOptionsForm = ({
  isEditing,
  isLoading,
  taxReturnsDefinitionsJurisdictionOptions,
  stateFilingFormOptions,
  taxReturnsDefinition,
  dueDateOption,
  isLoadingDueDateOption,
  returnStatusOption,
  onNextButtonClick,
}: AddEditReturnOptionsFormProps) => {
  const { setFieldValue, values, errors } = useFormikContext<FormValues>();

  const shouldDisplayReturnStatusWhenNotNonFiling = useShouldDisplayReturnStatusWhenNotNonFiling();
  const shouldDisplayReturnsDueDatesWhenNotNonFiling = useShouldDisplayReturnsDueDatesWhenNotNonFiling();

  const hasFilingGroup = Boolean(values?.taxReturn?.filingGroupsIds);
  const isNextButtonDisabled =
    !values.jurisdictionId ||
    !values.returnDefinitionId ||
    (shouldDisplayReturnsDueDatesWhenNotNonFiling && !values.dueDateType);

  const {
    isFilingMethodDisabled,
    filingMethodInfoMessage,
    filingMethodOptions,
  } = useSetFilingMethod({
    isEditing,
    taxReturnsDefinition,
    isNonFilingEditable: hasFilingGroup,
  });

  const {
    showModal: showPaperFilingConfirmationModal,
    modalProps: paperFilingConfirmationModalProps,
  } = useModal();

  const onFilingMethodChange = useCallback(
    (_: unknown, value: FilingMethods) => {
      if (!taxReturnsDefinition) {
        return;
      }
      const resetValues = getResetValuesForFilingMethodChange({
        filingMethod: value,
        values,
        dueDateOption,
      });
      if (resetValues) {
        Object.entries(resetValues).forEach(([field, value]) => {
          setFieldValue(field, value);
        });
      }

      const {
        paperFilingConfirmationTitle: title,
        paperFilingConfirmationMessage: message,
      } = taxReturnsDefinition;

      if (title && message) {
        showPaperFilingConfirmationModal({
          title,
          message,
        });
      }
    },
    [showPaperFilingConfirmationModal, setFieldValue, taxReturnsDefinition, dueDateOption, values],
  );

  const filingInformation = useMemo(() => {
    return taxReturnsDefinition?.filingInformation;
  }, [taxReturnsDefinition]);

  return (
    <Loading isLoading={isLoading}>
      <div className={styles.returnOptionsTabFields}>
        <Select
          appkitLabel="Jurisdiction"
          name="jurisdictionId"
          options={taxReturnsDefinitionsJurisdictionOptions}
          value={values.jurisdictionId}
          disabled={isEditing}
        />
        <Field
          appkitLabel="Filing Form Description"
          name="returnDefinitionId"
          as={Select}
          options={stateFilingFormOptions}
          disabled={Boolean(isEditing || errors.returnDefinitionId)}
        />
        {values.filingMethod === FilingMethods.NON_FILING && !hasFilingGroup ? (
          <div>
            <div className={styles.filingMethodTitle}>
              <label className="a-form-label">Filing Method</label>
            </div>
            <div className={styles.nonFilingDisplay}>
              <span>Non-Filing</span>
            </div>
          </div>
        ) : (
          <Field
            label="Filing Method"
            name="filingMethod"
            component={Radio}
            options={filingMethodOptions}
            disabled={isFilingMethodDisabled}
            labelIconToolTipContent={
              hasFilingGroup ? 'Member of a consolidated filing group' : null
            }
            infoMessage={filingMethodInfoMessage}
            customChangeHandler={onFilingMethodChange}
          />
        )}
        {filingInformation ? (
          <div className={styles.filingInformation}>
            <AppkitIcon
              className={styles.filingInformationIcon}
              icon="information"
              type="fill"
              size={16}
            />
            <span className={styles.filingInfoHeader}>Filing Information</span>
            <p className={styles.filingInfoMessage}>{filingInformation}</p>
          </div>
        ) : null}
        {shouldDisplayReturnsDueDatesWhenNotNonFiling ? (
          <div>
            <div className={styles.dueDateSection}>
              <label className="a-form-label">Due Date</label>
              <Tooltip
                content={`Due Dates are calculated using the entity’s Tax Year Ending date. To change the Tax Year Ending date, go to Compliance > Entities > Informational Data > Entity Information.`}
                placement="top"
                className={styles.addEditReturnPanelTooltip}
              >
                <AppkitIcon
                  className={styles.dueDateTooltipIcon}
                  icon="information"
                  type="outline"
                  size={14}
                />
              </Tooltip>
            </div>
            <Select<DueDateOptionValuesEnum>
              appkitPlaceholder="Select a Date"
              name="dueDateType"
              value={values.dueDateType}
              options={dueDateOption || []}
              isLoading={isLoadingDueDateOption}
              isAutoClearable
              shouldDisplayErrorMessage={Boolean(values?.returnDefinitionId)}
            />
          </div>
        ) : null}
        {shouldDisplayReturnStatusWhenNotNonFiling ? (
          <Field
            appkitLabel="Return Status"
            name="returnStatus"
            as={Select}
            options={returnStatusOption}
            disabled={Boolean(values?.efileStatus && values?.filingMethod === FilingMethods.EFILE)}
            value={values.returnStatus}
          />
        ) : null}
      </div>
      <div className={styles.buttons}>
        <Button size="lg" title="Next" onClick={onNextButtonClick} disabled={isNextButtonDisabled}>
          Next
        </Button>
      </div>
      <PaperFilingConfirmationModal {...paperFilingConfirmationModalProps} />
    </Loading>
  );
};

export default AddEditReturnOptionsForm;
