const Yup = require('yup');

const updateAssignmentsSchema = Yup.object()
  .shape({
    customerId: Yup.string().required(),
    tasks: Yup.array()
      .of(
        Yup.object().shape({
          taxYear: Yup.number().required(),
          period: Yup.number().required(),
          taskId: Yup.string().required(),
          orgId: Yup.string().required(),
          jurisdictionId: Yup.string().required(),
          orgRuleId: Yup.string().required(),
        }),
      )
      .required()
      .min(1),
  })
  .strict();

module.exports = {
  updateAssignmentsSchema,
};
