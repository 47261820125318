import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import { TEMP_STORE } from '../shared/store/storeHelpers';
import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';

import GlobalMemberInformationalData from './globalMemberInformationalData.container';

// Necessary when changing the global context to reload the whole page to prevent data old data from moving over
const GlobalMemberInformationalDataWithContext = withContextWrapper()(
  GlobalMemberInformationalData,
);

const GlobalMemberInformationalDataPage = connect((state: TEMP_STORE) => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(GlobalContextPage);

const globalMemberInformationalDataRoutes = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.globalMemberInformationalData.MAIN}
    wrapper={GlobalMemberInformationalDataPage}
    component={GlobalMemberInformationalDataWithContext}
    hideGlobalContext
    disableBreadcrumbs
  />
);

export default globalMemberInformationalDataRoutes;
