import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';

import { fetchPartnershipAmountDetailsTypes } from './types';

const partnershipAmountDetailsReducer = reducerFactory({
  initialState: {
    partnershipAmountDetails: [],
    isFetchingPartnershipAmountDetails: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnershipAmountDetailsTypes,
      payloadKey: 'partnershipAmountDetails',
      fetchingKey: 'isFetchingPartnershipAmountDetails',
    }),
  },
});

export default partnershipAmountDetailsReducer;
