import { httpGetAndParse } from '../../utils/fetchService';
import {
  ConsolidationId,
  EntityId,
  JurisdictionId,
  Period,
  TaxYear,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export interface BinderOption {
  value: number;
  label?: string;
}

export interface GetBinderOptionsResponse {
  sltBinderOptions: BinderOption[];
  defaultBinderId: string | null;
}

export interface BinderFormData {
  formId: string;
  formName: string;
  orgId: EntityId;
  consolId: ConsolidationId;
  taxYear: TaxYear | null;
  period: Period | null;
  jurisdictionId: JurisdictionId;
}

export interface BinderAttachmentData {
  id: string;
  name: string;
}

export interface BinderData {
  forms: {
    label: string;
    items: BinderFormData[];
  };
  reports: {
    label: string;
    items: [];
  };
  attachments: {
    label: string;
    items: BinderAttachmentData[];
  };
}

const fetchSltBinders = (routeParams: string) =>
  httpGetAndParse({
    route: `/api/shared/binder/binders-list/${routeParams}`,
  });

export const useQuerySltBinderOptions = ({
  params,
  enabled,
}: {
  params: string;
  enabled: boolean;
}) =>
  useCustomQuery<GetBinderOptionsResponse>([QueryKeys.Binders.SltBinderOptions, params], {
    enabled,
    defaultData: { sltBinderOptions: [], defaultBinderId: null },
    errorMessage: 'Error fetching binders list',
    customFetch: async () => {
      const { bindersList, defaultBinderId } = await fetchSltBinders(params);
      const sltBinderOptions = bindersList.map(
        ({ binderId, binderName }: { binderId: number; binderName: string }) => ({
          value: binderId,
          label: binderName,
        }),
      );
      return { sltBinderOptions, defaultBinderId };
    },
  });

export const useQueryBinderData = ({ binderId, enabled }: { binderId: number; enabled: boolean }) =>
  useCustomQuery<BinderData | null>([QueryKeys.Binders.BinderData, binderId], {
    url: `/api/shared/binder/data/${binderId}`,
    enabled,
    defaultData: null,
    errorMessage: 'Error fetching binder data',
  });
