import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const getColumnDefinitions = ({ taxYear }) => {
  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Id',
      field: 'orgId',
      width: 100,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account Group',
      field: 'accountGroup',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'accountId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account Description',
      field: 'accountDescription',
      width: 250,
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: `Current Year ${taxYear}`,
      field: 'currentAmt',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: `Prior Year ${parseInt(taxYear, 10) - 1}`,
      field: 'priorAmt',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Variance',
      field: 'variance',
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
