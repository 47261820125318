import React from 'react';
import { useSelector } from 'react-redux';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { useQueryXmlMappingElements } from '../../../../shared/queries/eFileSupport';
import { taxYearSelector } from '../../../../shared/store/selectors';

import styles from './xmlMappingElements.module.scss';
import columnDefinitions from './xmlMappingElements.columnDefinitions';

const XmlMappingElements = () => {
  const taxYear = useSelector(taxYearSelector);

  const {
    data: xmlMappingElements,
    isLoading: isLoadingXmlMappingElements,
  } = useQueryXmlMappingElements({
    params: { taxYear },
    enabled: Boolean(taxYear),
  });

  return (
    <>
      <AgGrid
        className={styles.grid}
        rowData={xmlMappingElements}
        columnDefs={columnDefinitions}
        isGridLoading={isLoadingXmlMappingElements}
        enableBrowserTooltips
        autoMaxWidth
      />
    </>
  );
};

export default XmlMappingElements;
