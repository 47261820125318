import { Routes } from '@common-packages/routes-definitions';

import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { IconCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import downloadFile from '../../utils/downloadFile';

import { uploadTypes } from './constants';

const getColumnDefinitions = ({ showModal }) => {
  const uploadIconRenderer = IconCellRendererFactory({
    getName: (value, data) => (data.isSubmissionUploaded ? 'news' : 'plus'),
    isSmall: (value, data) => !data.isSubmissionUploaded,
    isClickHandlerActive: data => data.isSubmissionUploaded,
    clickHandler: (value, data) => {
      showModal({ ...data, uploadType: uploadTypes.SUBMISSION.value });
    },
  });

  const mapIconRenderer = IconCellRendererFactory({
    getName: (value, data) => (data.isMapUploaded ? 'news' : 'plus'),
    isSmall: (value, data) => !data.isMapUploaded,
    isClickHandlerActive: data => data.isMapUploaded,
    clickHandler: (value, data) => {
      showModal({ ...data, uploadType: uploadTypes.MAPPING.value });
    },
  });

  const resultIconRenderer = IconCellRendererFactory({
    getName: (_, data) => (data.isResultAvailable ? 'news' : 'minus'),
    clickHandler: (_, data) =>
      downloadFile({
        route: `${Routes.efileImportValidationDownload.compiledRoute({
          submissionId: data.submissionId,
        })}`,
      }),
    isClickHandlerActive: data => data.isResultAvailable,
  });

  const submissionDownloadLinkRenderer = IconCellRendererFactory({
    getName: (_, data) => (data.isMapUploaded ? 'news' : 'minus'),
    clickHandler: (_, data) =>
      downloadFile({
        route: `${Routes.efileMappingDownload.compiledRoute({
          submissionId: data.submissionId,
        })}`,
      }),
    isClickHandlerActive: data => data.isMapUploaded,
  });

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Submission Id',
      field: 'submissionId',
      maxWidth: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity',
      field: 'taxPayer',
      maxWidth: 160,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdiction',
      maxWidth: 150,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Upload Submission',
      field: 'isSubmissionUploaded',
      maxWidth: 200,
      cellRenderer: uploadIconRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Submission Upload Date',
      field: 'submissionUploadDate',
      maxWidth: 100,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Download Validation / Mapping',
      field: 'isDownloadAvailable',
      maxWidth: 150,
      cellRenderer: submissionDownloadLinkRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Upload Map',
      field: 'isMapUploaded',
      maxWidth: 100,
      cellRenderer: mapIconRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Mapping Upload Date',
      field: 'mappingUploadDate',
      maxWidth: 200,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'View Results',
      field: 'isResultAvailable',
      maxWidth: 100,
      cellRenderer: resultIconRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
