// import * as api from '../api';
import { apiAction } from '../apiAction';

import { fetchGenericCategoryTypes, updateGenericCategoryTypes } from './types';
import * as api from './api';

export const fetchGenericCategory = apiAction({
  types: fetchGenericCategoryTypes,
  apiAction: api.fetchGenericCategory,
});

export const updateGenericCategory = apiAction({
  types: updateGenericCategoryTypes,
  apiAction: api.updateGenericCategory,
});
