export const PROFORMA_STEPS = [
  {
    action: 'validate-and-build-index-for-federal-transmission',
    subSteps: {
      'upload-federal-transmission': 'Upload Federal Transmission or e-File XML',
      'validate-and-build-index': 'Validate and Build Index for Federal Transmission',
      'download-and-review-schema': 'Download and Review Schema Validation and Index results',
    },
  },
  {
    action: 'federal-transmission-import',
    subSteps: {
      'verify-and-update-mapping-file': 'Verify and update mapping file',
      'upload-mapping-file': 'Upload Mapping File',
      'import-federal-transmission': 'Import Federal Transmission',
      'download-and-review-validation-report': 'Download and Review Validation Report',
      'legal-entity-creation': 'Legal Entity Creation',
    },
  },
  {
    action: 'setup-proforma-binders',
    subSteps: {
      'prepare-context-for-setup-proforma-binders': 'Prepare context for setup proforma binders',
      'create-csv-file-for-setup-proforma-binders': 'Create CSV file',
      'setup-proforma-binders': 'Setup proforma binders',
    },
  },
  {
    action: 'generate-efile-xml',
    subSteps: {
      'generate-efile-xml': 'Generate E-file XML',
      'download-efile-xml': 'Download E-file XML',
    },
  },
  {
    action: 'xml-comparison',
    subSteps: {
      'xml-comparison-report': 'Generate comparison report',
      'xml-comparison-report-download': 'Download comparison report',
      'xml-boolean-comparison': 'Generate XML boolean comparison',
      'xml-boolean-comparison-download':
        'Download and review differences of XML boolean comparison',
    },
  },
  {
    action: 'generate-pdf-binder',
    subSteps: {
      'generate-pdf-binder': 'Generate PDF Binder',
      'download-pdf-binder': 'Download PDF Binder',
    },
  },
  {
    action: 'create-federal-transmission-file',
    subSteps: {
      'create-federal-transmission-file': 'Create Federal Transmission File',
      'download-and-review-transmission-errors': 'Download and review transmission file errors',
    },
  },
  {
    action: 'get-proforma-files-for-all-binders-in-default-case',
    subSteps: {
      'get-proforma-files': 'Get and process Default Case proforma files',
    },
  },
];
