import { HttpMethods } from '@common-packages/shared-constants';

import { useCustomMutation } from '.';

export const useMutationInitiateSeparatePopulate = () =>
  useCustomMutation({
    handleData: ({ taxYear, period, entityId }) => ({
      url: '/api/reports/multi-state-le-taxable-income',
      method: HttpMethods.PUT,
      body: { taxYear, period, entityId },
    }),
    successMessage: 'Initiated Separate Populate Successfully',
    errorMessage: 'Initiating Separate Populate Failed',
  });
