/**
 * In this function we're iterating over pageContainer child nodes and for each one we're calculating it's
 * pageStartPosition (page height + bottom margin) and getting page number from DOM node attributes.
 * The reason behind this implementation is that we couldn't use intersection observer since in higher resolutions multiple pages
 * can be visible on screen and each page can have different height and margin (portrait / landscape).
 */

type MappedPages = {
  pageId: string;
  pageNumber: number;
  pageStartPosition: number;
};

type PagesAcc = {
  mappedPages: MappedPages[];
  currentPosition: number;
};

const getCalculatedPagesPositionsFromPageContainer = (pageContainer: HTMLElement): MappedPages[] =>
  Array.from(pageContainer.children).reduce(
    (acc: PagesAcc, currentPage, index) => {
      const mappedPage = {
        pageId: currentPage.id,
        pageNumber: index + 1,
        pageStartPosition: acc.currentPosition,
      };

      const bottomMargin = Number(
        window.getComputedStyle(currentPage).marginBottom.replace(/[^0-9]/g, ''),
      );

      const newPosition = acc.currentPosition + currentPage.clientHeight + bottomMargin;

      acc.mappedPages.push(mappedPage);
      acc.currentPosition = newPosition;

      return acc;
    },
    { mappedPages: [], currentPosition: 0 },
  ).mappedPages;

export default getCalculatedPagesPositionsFromPageContainer;
