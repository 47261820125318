import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'leid',
    maxWidth: 100,
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Type',
    field: 'entityType',
    maxWidth: 80,
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars BSLA',
    field: 'starsBsla',
    maxWidth: 100,
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars Account',
    field: 'starsAcct',
    width: 100,
    maxWidth: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account',
    field: 'tlCode',
    width: 150,
    maxWidth: 150,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars Description',
    field: 'starsDescription',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Book Amount',
    field: 'sumBookAmt',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Tax Amount',
    field: 'sumTaxAmt',
    width: 80,
  },
];

export default columnDefinitions;
