const ColumnTypes = {
  FILING_ATTRIBUTES: 'FILING_ATTRIBUTES',
  CALC_PREFERENCES: 'CALC_PREFERENCES',
  CHECKBOX_GROUP: 'CHECKBOX_GROUP',
  OTHER: 'OTHER',
};

module.exports = {
  ColumnTypes,
};
