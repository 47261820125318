import React from 'react';
import classNames from 'classnames';
import { Button } from '@pwc/appkit-react';

import styles from './buttonsGroup.module.scss';

interface ButtonsGroupProps {
  className?: string;
  fullWidth?: boolean;
  fixed?: boolean;
  isSubmitting: boolean;
  disabled?: boolean;
  disabledSubmit?: boolean | null;
  disabledCancel?: boolean | null;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  submitForm: () => void;
  onCancelClick: () => void;
}

const ButtonsGroup = ({
  className = '',
  fullWidth = true,
  fixed = false,
  isSubmitting,
  disabled = false,
  disabledSubmit = null,
  disabledCancel = null,
  submitButtonLabel = 'Apply',
  cancelButtonLabel = 'Cancel',
  submitForm,
  onCancelClick,
}: ButtonsGroupProps) => (
  <div
    className={classNames(
      styles.buttonsGroup,
      { [styles.fullWidth]: fullWidth, [styles.fixed]: fixed },
      className,
    )}
  >
    <div className={`${styles.buttonWrapper} btn-group`}>
      <Button
        size="lg"
        className="w-100"
        disabled={
          disabledSubmit === null ? isSubmitting || disabled : isSubmitting || disabledSubmit
        }
        onClick={submitForm}
      >
        {submitButtonLabel}
      </Button>
    </div>
    <div className={`${styles.buttonWrapper} btn-group`}>
      <Button
        size="lg"
        className="w-100"
        gray
        onClick={onCancelClick}
        disabled={disabledCancel === null ? disabled : disabledCancel}
      >
        {cancelButtonLabel}
      </Button>
    </div>
  </div>
);

export default ButtonsGroup;
