import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchGlobalInformationTypes = getApiActionTypes('FETCH_GLOBAL_INFORMATION');
export const updateGlobalInformationTypes = getApiActionTypes('UPDATE_GLOBAL_INFORMATION');
export const fetchAllPartnershipsTypes = getApiActionTypes('FETCH_ALL_PARTNERSHIPS');
export const SELECT_PARTNERSHIP_ID = 'SELECT_PARTNERSHIP_ID';
export const fetchPartnershipInformationTypes = getApiActionTypes('FETCH_PARTNERSHIP_INFORMATION');
export const updatePartnershipInformationTypes = getApiActionTypes(
  'UPDATE_PARTNERSHIP_INFORMATION',
);
