import { httpGetAndParse, httpPut } from '../../utils/fetchService';

export const fetchFlowThroughK1Details = ({
  taxYear,
  period,
  businessEntityId,
  jurisdictionId,
  screenType,
  partnerId,
}) =>
  httpGetAndParse({
    route: `/api/flow-through-k1-details/${taxYear}/${period}/${businessEntityId}/${jurisdictionId}/${screenType}/${partnerId}`,
    errorMessage: 'Fetching flow through K1 details failed',
  });

export const fetchPartners = ({ taxYear, period, businessEntityId }) =>
  httpGetAndParse({
    route: `/api/flow-through-k1-details/${taxYear}/${period}/${businessEntityId}`,
    errorMessage: 'Fetching partners failed',
  });

export const updateFlowThroughK1Details = ({
  taxYear,
  period,
  businessEntityId,
  jurisdictionId,
  rowsToUpdate,
}) =>
  httpPut({
    route: `/api/flow-through-k1-details/${taxYear}/${period}/${businessEntityId}/${jurisdictionId}`,
    errorMessage: 'Updating flow through K1 details failed',
    body: { rowsToUpdate },
  });
