import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ isInEditMode, updateRow }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const checkboxCellClassRules = {
    ...defaultColumnDefinition.cellClassRules,
    'editable-cell': () => isInEditMode,
  };

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Locked',
      field: 'isLocked',
      width: 60,
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Id',
      field: 'entityId',
      width: 100,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Name',
      field: 'entityName',
      width: 450,
    },
  ];

  return columnDefinitions;
};
export default getColumnDefinitions;
