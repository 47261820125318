const DOC_FIELD = 'docRollover';
const DEF_FIELD = 'defRollover';
const coupledFields = [DOC_FIELD, DEF_FIELD];

export const attachmentsBusinessRules = (data, { colDef: { field }, oldValue }) => {
  if (!coupledFields.includes(field)) {
    return data;
  }

  if (DOC_FIELD === field) {
    const docFieldChecked = data[DOC_FIELD] !== oldValue && data[DOC_FIELD];
    if (docFieldChecked) {
      data[DEF_FIELD] = true;
    }
  }

  if (DEF_FIELD === field) {
    const defFieldUnchecked = data[DEF_FIELD] !== oldValue && !data[DEF_FIELD];
    if (defFieldUnchecked) {
      data[DOC_FIELD] = false;
    }
  }

  return data;
};

const hasValidRolloverState = ({ newRow }) => {
  const invalid = !newRow.defRollover && newRow.docRollover;

  return !invalid;
};

export const validateChanges = ({ rowsPairsWithChanges }) =>
  rowsPairsWithChanges.every(hasValidRolloverState);
