import {
  defaultColumnDefinitionWithFilter,
  disabledReadOnlyCheckboxColumnDefinitions,
} from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 275,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Account Id',
    field: 'accountId',
    width: 190,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Source',
    field: 'source',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Category',
    field: 'category',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Category Type',
    field: 'categoryType',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Multiplier',
    field: 'multiplier',
    width: 80,
    cellStyle: {
      textAlign: 'right',
    },
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction Description',
    field: 'jurisdictionDescription',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Separate (Filing Type)',
    field: 'filingTypeSeparate',
    width: 95,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: '1120 (Return Type)',
    field: 'returnType1120',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: '1065 (Return Type)',
    field: 'returnType1065',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'DRE (Return Type)',
    field: 'returnTypeDre',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'LLC (Return Type)',
    field: 'returnTypeLlc',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'SMLLC (Return Type)',
    field: 'returnTypeSmllc',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: '1120PC (Return Type)',
    field: 'returnType1120Pc',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: '1120L (Return Type)',
    field: 'returnType1120L',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'General (Business Type)',
    field: 'businessTypeGeneral',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Financial (Business Type)',
    field: 'businessTypeFinancial',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Insurance (Business Type)',
    field: 'businessTypeInsurance',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Oil and Gas (Business Type)',
    field: 'businessTypeOilAndGas',
    width: 95,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Regulated Exchange (Business Type)',
    field: 'businessTypeRegulatedExchange',
    width: 115,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Transportation (Business Type)',
    field: 'businessTypeTransportation',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Income (Tax Type)',
    field: 'taxTypeIncome',
    width: 85,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Franchise (Tax Type)',
    field: 'taxTypeFranchise',
    width: 80,
  },
];

export default columnDefinitions;
