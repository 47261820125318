import React, { useCallback, useMemo, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { returnDefinitionsSchemas } from '@common-packages/validators';

import SlideIn from '../../../shared/displayComponents/slideIn/slideIn.component';
import { taxYearSelector, jurisdictionIdSelector } from '../../store/selectors';
import { useQueryFilingAttributesDefinitions } from '../../../shared/queries/filingAttributes';
import { useQueryForms } from '../../../shared/queries/taxFormsV2';
import { useQuerySltSchemaIdOptions } from '../../../shared/queries/eFileElementMappings';
import {
  useMutationInsertReturnDefinition,
  useMutationUpdateReturnDefinition,
} from '../../../shared/mutations/returnDefinitions';
import americanDateFormat from '../../../shared/americanDateFormat';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';
import { ReturnDefinition } from '../../../../../common/types/apiShapes';
import { FederalReturnAttachments } from '../../../shared/enums';
import { useShouldDisplayReturnsDueDates } from '../../../shared/hooks/useShouldDisplayReturnsDueDates.hook';

import AddEditReturnDefinitionForm from './addEditReturnDefinitionForm.component';
import styles from './addEditReturnDefinitionPanel.module.scss';

export type ReturnDefinitionValues = Omit<
  ReturnDefinition,
  | 'id'
  | 'filingFormName'
  | 'dueDate'
  | 'filingAttributes'
  | 'nonFilingSeparateFormName'
  | 'filingSeparateFormName'
> & {
  id?: string;
  dueDate: string | null;
  nonFilingSeparateReturnDefId: string;
  filingSeparateReturnDefId: string;
};

const EmptyReturnDefinition: ReturnDefinitionValues = {
  name: '',
  filingFormId: '',
  sltSchemaId: null,
  isDefault: false,
  isStackedReturn: false,
  dueDate: null,
  dueDateDays: null,
  dueDateMonths: null,
  dueDateExtendedMonths: 0,
  dueDateSecondExtendedMonths: 0,
  isEfileAllowed: false,
  isPaperAllowed: false,
  federalReturnAttachment: FederalReturnAttachments.NONE,
  isFederalXmlRequired: false,
  isFederalAsFiledRequested: false,
  hasMandate: false,
  failureToEfilePenalty: '',
  isEfileReady: false,
  filingInformation: '',
  filingWarning: '',
  nonFilingSeparateReturnDefId: '',
  filingSeparateReturnDefId: '',
  RETURN_TYPE_1120: false,
  RETURN_TYPE_1065: false,
  RETURN_TYPE_DRE: false,
  RETURN_TYPE_LLC: false,
  RETURN_TYPE_SMLLC: false,
  RETURN_TYPE_1120PC: false,
  RETURN_TYPE_1120L: false,
  FILING_TYPE_SEPARATE: false,
  FILING_TYPE_CONS_PRE_APP: false,
  FILING_TYPE_CONS_UNITARY: false,
  FILING_TYPE_CONS_POST_APP: false,
  BUSINESS_TYPE_GENERAL: false,
  BUSINESS_TYPE_FINANCIAL: false,
  BUSINESS_TYPE_INSURANCE: false,
  BUSINESS_TYPE_OIL_AND_GAS: false,
  BUSINESS_TYPE_REGULATED_EXCHANGE: false,
  BUSINESS_TYPE_TRANSPORTATION: false,
  PERIOD_TYPE_ANNUAL: false,
  PERIOD_TYPE_ESTIMATE1: false,
  PERIOD_TYPE_ESTIMATE2: false,
  PERIOD_TYPE_ESTIMATE3: false,
  PERIOD_TYPE_ESTIMATE4: false,
  PERIOD_TYPE_EXTENSION: false,
  PERIOD_TYPE_SECOND_EXTENSION: false,
  PERIOD_TYPE_AMENDED: false,
  PERIOD_TYPE_RAR: false,
  TAX_TYPE_INCOME: false,
  TAX_TYPE_FRANCHISE: false,
};

interface AddEditReturnDefinitionPanelProps {
  isPanelVisible: boolean;
  hidePanel: () => void;
  definitionToEdit: ReturnDefinition | null;
  returnDefinitionsForContext: ReturnDefinition[];
}

const AddEditReturnDefinitionPanel = ({
  hidePanel,
  isPanelVisible,
  definitionToEdit,
  returnDefinitionsForContext,
}: AddEditReturnDefinitionPanelProps) => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const hasDueDate = useShouldDisplayReturnsDueDates({ isDeveloperTool: true });

  const formRef = useRef<FormikProps<ReturnDefinitionValues>>(null);

  const isEditing = Boolean(definitionToEdit);

  const { data: filingAttributes } = useQueryFilingAttributesDefinitions({
    params: { taxYear },
    enabled: Boolean(taxYear),
  });

  const { data: formsData } = useQueryForms({
    params: { taxYear, jurisdictionId },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const { data: schemaIdOptions } = useQuerySltSchemaIdOptions({
    params: { taxYear, jurisdictionId },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const { mutateAsync: insertReturnDefinition } = useMutationInsertReturnDefinition();
  const { mutateAsync: updateReturnDefinition } = useMutationUpdateReturnDefinition();

  const formikInitialValues = useMemo(
    () =>
      definitionToEdit
        ? ({
            ...definitionToEdit,
            dueDate: definitionToEdit.dueDate // converting from ISO string to american date format
              ? americanDateFormat.toString(new Date(definitionToEdit.dueDate))
              : null,
            sltSchemaId: definitionToEdit.sltSchemaId || '',
            nonFilingSeparateReturnDefId: definitionToEdit.nonFilingSeparateReturnDefId || '',
            filingSeparateReturnDefId: definitionToEdit.filingSeparateReturnDefId || '',
          } as ReturnDefinitionValues)
        : EmptyReturnDefinition,
    [definitionToEdit],
  );

  const validationSchema = useMemo(
    () =>
      returnDefinitionsSchemas.getReturnDefinitionFrontendSchema({
        returnDefinitions: returnDefinitionsForContext.filter(
          ({ id }) => id !== definitionToEdit?.id,
        ),
        hasDueDate,
      }),
    [returnDefinitionsForContext, definitionToEdit, hasDueDate],
  );

  const handleHidePanel = useCallback(() => {
    if (formRef.current?.dirty) {
      dispatch(
        showConfirmModal({
          title: 'Do you want to leave this Return Definition?',
          text: 'The changes you made have not been saved.',
          confirmText: 'Leave',
          dismissText: 'Stay',
          confirmCallback: hidePanel,
        }),
      );
      return;
    }
    hidePanel();
  }, [hidePanel, dispatch]);

  const handleSubmit = useCallback(
    async (values: ReturnDefinitionValues) => {
      const data = {
        ...values,
        name: values.name.trim(),
        taxYear,
        jurisdictionId,
        dueDate: values.dueDate
          ? americanDateFormat.fromString(values.dueDate)?.toISOString() || null
          : null,
        nonFilingSeparateReturnDefId: values.nonFilingSeparateReturnDefId || null,
        filingSeparateReturnDefId: values.filingSeparateReturnDefId || null,
      };

      if (isEditing) {
        await updateReturnDefinition({
          ...data,
          id: definitionToEdit?.id || '',
        });
      } else {
        await insertReturnDefinition(data);
      }

      hidePanel();
    },
    [
      insertReturnDefinition,
      updateReturnDefinition,
      hidePanel,
      isEditing,
      taxYear,
      jurisdictionId,
      definitionToEdit?.id,
    ],
  );

  return (
    <SlideIn
      className={styles.panel}
      isOpen={isPanelVisible}
      onRequestClose={handleHidePanel}
      width="950px"
      closeIconName="close"
      title={`${isEditing ? 'Edit' : 'Add'} Return Definition`}
    >
      <Formik
        innerRef={formRef}
        initialValues={formikInitialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <AddEditReturnDefinitionForm
          isEditing={isEditing}
          forms={formsData?.data || []}
          schemaIdOptions={schemaIdOptions || []}
          filingAttributes={filingAttributes || []}
          hidePanel={handleHidePanel}
        />
      </Formik>
    </SlideIn>
  );
};

export default AddEditReturnDefinitionPanel;
