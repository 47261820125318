import { createSelector } from 'reselect';

export const taxRatesSelector = createSelector(
  [({ admin }) => admin.taxRatesAndConstantsMaintenance.taxRates.taxRates.data],
  taxRates => taxRates,
);

export const taxRatesColumnsBlueprintSelector = ({ admin }) =>
  admin.taxRatesAndConstantsMaintenance.taxRates.taxRates.columnsBlueprint;

export const isFetchingTaxRatesSelector = ({ admin }) =>
  admin.taxRatesAndConstantsMaintenance.taxRates.isFetchingTaxRates;
