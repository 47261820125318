import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { fetchAccountLineItemDetail } from '../../../../shared/store/accountLineItemDetail/actions';
import {
  accountLineItemDetailSelector,
  isFetchingAccountLineItemDetailSelector,
} from '../../../../shared/store/accountLineItemDetail/selectors';
import globalContextPropTypes from '../../../../shared/propTypes/globalContext';

import columnDefinitions from './accountLineItemDetail.columnDefinitions';

const AccountLineItemDetail = ({
  globalContext,
  accountId,

  fetchAccountLineItemDetail,
  isFetchingAccountLineItemDetail,
  accountLineItemDetail,
}) => {
  const { taxYear, period, businessEntityId: orgId } = globalContext.params;
  useEffect(() => {
    if (accountId) {
      fetchAccountLineItemDetail({ taxYear, period, orgId, accountId });
    }
  }, [orgId, period, taxYear, accountId, fetchAccountLineItemDetail]);

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={accountLineItemDetail}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingAccountLineItemDetail}
        />
      </div>
    </div>
  );
};

AccountLineItemDetail.propTypes = {
  globalContext: globalContextPropTypes,
  accountId: PropTypes.string.isRequired,

  accountLineItemDetail: PropTypes.arrayOf(
    PropTypes.shape({
      accountDescription: PropTypes.string.isRequired,
      dcsLeid: PropTypes.string.isRequired,
      dcsCdr: PropTypes.string.isRequired,
      dcsBsla: PropTypes.string.isRequired,
      dcsJurisdiction: PropTypes.string.isRequired,
      dcsAccountId: PropTypes.string.isRequired,
      dcsBookAmount: PropTypes.number.isRequired,
      dcsAdjAmount: PropTypes.number.isRequired,
      dcsM1Amount: PropTypes.number.isRequired,
      dcsTaxAmount: PropTypes.number.isRequired,
    }),
  ).isRequired,
  fetchAccountLineItemDetail: PropTypes.func.isRequired,
  isFetchingAccountLineItemDetail: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    accountLineItemDetail: accountLineItemDetailSelector(state),
    isFetchingAccountLineItemDetail: isFetchingAccountLineItemDetailSelector(state),
  }),
  {
    fetchAccountLineItemDetail,
  },
)(AccountLineItemDetail);
