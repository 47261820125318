import React, { useCallback, useEffect } from 'react';
import { Button } from '@pwc/appkit-react/lib/Button';
import { useSelector } from 'react-redux';

import { globalContextSelector } from '../../shared/store/selectors';
import AppkitIcon from '../displayComponents/appkitIcon/appkitIcon.component';
import './editMode.style.scss';

const useEditModeButtons = ({
  isInEditMode,
  isCancelAlwaysVisible = false,
  setIsInEditMode,
  validateBeforeSave = () => true,
  onCancel = () => null,
  onEnter = () => null,
  onSave = () => null,
  cancelButtonDisabled = false,
  saveButtonDisabled = false,
  editButtonDisabled = false,
  isPermanentlyInEditMode = false,
  showLockIcon = false,
  turnOffEditModeOnSave = true,
  startInEditMode = false,
} = {}) => {
  const globalContext = useSelector(globalContextSelector);

  const setEditMode = useCallback(
    value => {
      if (!isPermanentlyInEditMode) {
        setIsInEditMode(value);
      }
    },
    [setIsInEditMode, isPermanentlyInEditMode],
  );

  const onLeaveCallback = useCallback(() => {
    if (!isCancelAlwaysVisible) {
      setEditMode(false);
    }
    onCancel();
  }, [isCancelAlwaysVisible, setEditMode, onCancel]);

  const onEnterCallback = useCallback(() => {
    setEditMode(true);
    onEnter();
  }, [setEditMode, onEnter]);

  const onSaveCallback = useCallback(() => {
    if (!validateBeforeSave()) {
      return;
    }

    if (turnOffEditModeOnSave) {
      setEditMode(false);
    }
    onSave();
  }, [validateBeforeSave, setEditMode, onSave, turnOffEditModeOnSave]);

  useEffect(() => {
    if (!startInEditMode) {
      setEditMode(false);
    }
  }, [globalContext.params, startInEditMode, setEditMode]);

  const cancelButton = () =>
    !isPermanentlyInEditMode ? (
      <Button
        size="lg"
        kind="secondary"
        onClick={onLeaveCallback}
        className="edit-mode-button"
        disabled={cancelButtonDisabled}
      >
        Cancel
      </Button>
    ) : null;

  const editModeButtons = isInEditMode ? (
    <div className="button-container">
      {cancelButton()}
      <Button
        size="lg"
        onClick={onSaveCallback}
        className="edit-mode-button"
        disabled={saveButtonDisabled}
      >
        Save
      </Button>
    </div>
  ) : (
    <div className="button-container">
      <Button
        size="lg"
        onClick={onEnterCallback}
        className="edit-mode-button"
        disabled={editButtonDisabled}
      >
        {showLockIcon && (
          <AppkitIcon className="location-lock-icon" icon="location-locked" size={16} />
        )}
        Edit
      </Button>
    </div>
  );

  return { editModeButtons };
};

export default useEditModeButtons;
