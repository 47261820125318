import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { dateCellFormatter } from '../../shared/formatters/index';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Status',
    field: 'status',
    width: 160,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Job Type',
    field: 'jobType',
    width: 160,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Submit Date',
    field: 'submitTime',
    width: 100,
    valueFormatter: dateCellFormatter,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Mail Subject',
    field: 'mailSubject',
    width: 280,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Note',
    field: 'note',
    width: 590,
  },
];

export default columnDefinitions;
