export const customScreenSelector = ({ customScreen }) => customScreen.customScreen.rowData;

export const customScreenColumnsBlueprintSelector = ({ customScreen }) =>
  customScreen.customScreen.columnsBlueprint;

export const isFetchingCustomScreenSelector = ({ customScreen }) =>
  customScreen.isFetchingCustomScreen;

export const isUpdatingCustomScreenSelector = ({ customScreen }) =>
  customScreen.isUpdatingCustomScreen;
