const Yup = require('yup');

const MANAUAL_FEDERAL_SUBMISSION_ID = '7';

const efileSubmissionSchema = Yup.object().shape({
  TTIYearId: Yup.mixed().when('uploadType', {
    is: 'submission',
    then: Yup.mixed().required('Year ID is required'),
  }),
  TTICaseId: Yup.mixed().when('uploadType', {
    is: 'submission',
    then: Yup.mixed().required('Case ID is required'),
  }),
  file: Yup.mixed().when('uploadType', {
    is: 'mapping',
    then: Yup.mixed().required('File is required'),
  }),
});

const federalReturnSchema = Yup.object().shape({
  federalReturnOption: Yup.string(),
  manualFederalProformaSubmissionId: Yup.mixed().when('federalReturnOption', {
    is: MANAUAL_FEDERAL_SUBMISSION_ID,
    then: Yup.string().label('Federal Submission ID').required(),
  }),
});

module.exports = {
  efileSubmissionSchema,
  federalReturnSchema,
};
