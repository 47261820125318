import { combineReducers } from 'redux';

import getFieldValue from '../../../utils/getFieldValue';
import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import sltAmountDetailsReducer from '../sltAmountDetails/store/reducer';
import variancesReducer from '../variances/store/reducer';
import categoryDetailsReducer from '../categoryDetails/store/reducer';

import {
  fetchFederalReconciliationsTypes,
  FEDERAL_RECONCILIATIONS_SELECT_ACCOUNT_GROUP,
} from './types';

const federalReconciliationsReducer = reducerFactory({
  initialState: {
    federalReconciliations: [],
    isFetchingFederalReconciliations: false,
    accountGroup: null,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFederalReconciliationsTypes,
      payloadKey: 'federalReconciliations',
      fetchingKey: 'isFetchingFederalReconciliations',
      getAdditionalState: (state, payload) => ({
        accountGroup: getFieldValue({
          currentValueFieldName: 'accountGroup',
          payloadFieldName: 'accountGroup',
          state,
          payload,
        }),
      }),
      initialAdditionalState: {
        accountGroup: null,
      },
    }),

    [FEDERAL_RECONCILIATIONS_SELECT_ACCOUNT_GROUP]: (state, payload) => ({
      ...state,
      accountGroup: payload,
    }),
  },
});

export default combineReducers({
  root: federalReconciliationsReducer,
  sltAmountDetails: sltAmountDetailsReducer,
  variances: variancesReducer,
  categoryDetails: categoryDetailsReducer,
});
