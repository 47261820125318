import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';

import useGridApi from '../../shared/hooks/useGridApi.hook';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { taxYearSelector } from '../../shared/store/selectors';

import EditAccounts from './editAccounts.container';
import { fetchAccounts } from './store/actions';
import { accountsSelector, isFetchingAccountsSelector } from './store/selectors';
import columnDefinitions from './accounts.columnDefinitions';

const Accounts = () => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const accounts = useSelector(accountsSelector);
  const isFetchingAccounts = useSelector(isFetchingAccountsSelector);

  const accountsFormRef = useRef();

  const [accountToEdit, setAccountToEdit] = useState(null);

  const { gridApi, onGridReady } = useGridApi();
  useEffect(() => {
    dispatch(fetchAccounts({ taxYear }));
  }, [dispatch, taxYear]);

  const clearForm = useCallback(() => {
    gridApi.deselectAll();
    setAccountToEdit(null);
    accountsFormRef.current?.resetForm();
  }, [accountsFormRef, gridApi]);

  const selectAccountToEdit = useCallback(
    ({ api }) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        setAccountToEdit(selectedRow);
      }
    },
    [setAccountToEdit],
  );

  return (
    <>
      <div className="row">
        <div className="col add-button-column">
          <Button
            size="lg"
            className="add-button"
            onClick={clearForm}
            disabled={isFetchingAccounts}
          >
            Add Account
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={accounts}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingAccounts}
            onSelectionChanged={selectAccountToEdit}
            withSearchBar
            onGridReady={onGridReady}
          />
        </div>
        <div className="col-4 right-column-edit-form">
          <EditAccounts
            taxYear={taxYear}
            accountToEdit={accountToEdit}
            clearForm={clearForm}
            fetchAccounts={fetchAccounts}
            ref={accountsFormRef}
          />
        </div>
      </div>
    </>
  );
};

export default Accounts;
