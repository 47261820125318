import {
  defaultColumnDefinition,
  centeredColumnDefinition,
} from '../../../../../shared/columnDefinitions';
import {
  EditableCheckboxCellRenderer,
  NumberCellEditor,
} from '../../../../../shared/columnDefinitions/cellRenderers';

const checkboxCellClassRules = {
  ...defaultColumnDefinition.cellClassRules,
  // using as a rule instead of `cellClass` because in this case `cellClass` causes these cells
  // to render incosistently (sometimes without the class)
  'editable-cell': () => true,
};

export default [
  {
    ...defaultColumnDefinition,
    headerName: 'Data Item Name',
    field: 'dataItemName',
    pinned: 'left',
    lockPinned: true,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Description',
    field: 'description',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Display Name',
    field: 'displayName',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Item Display Order',
    field: 'itemDisplayOrder',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Col. Display Order',
    field: 'columnDisplayOrder',
    editable: true,
    cellEditor: NumberCellEditor,
    cellEditorParams: {
      formatValue: value => parseFloat(value),
    },
  },
  {
    ...defaultColumnDefinition,
    ...centeredColumnDefinition,
    headerName: 'Screen Display',
    field: 'screenDisplay',
    cellRenderer: EditableCheckboxCellRenderer,
    cellClassRules: checkboxCellClassRules,
  },
  {
    ...defaultColumnDefinition,
    ...centeredColumnDefinition,
    headerName: 'Print Display',
    field: 'printDisplay',
    cellRenderer: EditableCheckboxCellRenderer,
    cellClassRules: checkboxCellClassRules,
  },
  {
    ...defaultColumnDefinition,
    ...centeredColumnDefinition,
    headerName: 'Print Column Total',
    field: 'printColumnTotal',
    cellRenderer: EditableCheckboxCellRenderer,
    cellClassRules: checkboxCellClassRules,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Relative Width',
    field: 'relativeWidth',
    editable: true,
    cellEditor: NumberCellEditor,
    cellEditorParams: {
      formatValue: value => parseFloat(value),
    },
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Font Size',
    field: 'fontSize',
    editable: true,
    cellEditor: NumberCellEditor,
    cellEditorParams: {
      formatValue: value => parseFloat(value),
    },
  },
];
