import PropTypes from 'prop-types';

export const partnershipAmountDetailType = PropTypes.shape({
  accountId: PropTypes.string,
  componentId: PropTypes.string,
  flowthroughAmount: PropTypes.number,
  jurisdictionDescription: PropTypes.string,
  nexusYn: PropTypes.bool,
  orgId: PropTypes.string,
  ownerPercentage: PropTypes.number,
  parentOrgId: PropTypes.string,
  partnershipAmount: PropTypes.number,
  partnershipId: PropTypes.string,
  percentEffectiveOwnership: PropTypes.number,
  percentOwned: PropTypes.number,
  percentYearOwned: PropTypes.number,
});
