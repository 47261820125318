import {
  defaultColumnDefinitionWithFilter,
  defaultColumnDefinition,
} from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'ID',
    field: 'resultId',
    width: 250,
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Entity ID',
    field: 'orgId',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Consol ID',
    field: 'consolId',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Type',
    field: 'filingType',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
    width: 250,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Diag Name',
    field: 'diagnosticsName',
    width: 200,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Diag Description',
    field: 'diagnosticsDescription',
    width: 400,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Solution',
    field: 'solution',
    width: 400,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Additional Info',
    field: 'additionalInfo',
    width: 400,
  },
];

export default columnDefinitions;
