import React, {
  useEffect,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  ReactNode,
  MouseEventHandler,
} from 'react';
import debounce from 'lodash.debounce';

import clearSelection from './clearSelection';
import styles from './styles.module.scss';

const MIN_WIDTH = 0;
const SEPARATOR_WIDTH = 25;

const START_RESIZE = 'START_RESIZE';
const STOP_RESIZE = 'STOP_RESIZE';

interface SplitPaneReducerState {
  isDragging: boolean;
  initialPos: number;
  maxLeftWidth: number;
  leftWidth: number;
}

type SplitPaneAction =
  | { type: typeof START_RESIZE; payload: Partial<SplitPaneReducerState> }
  | { type: typeof STOP_RESIZE; payload: number };

const initialState: SplitPaneReducerState = {
  isDragging: false,
  initialPos: 0,
  maxLeftWidth: 0,
  leftWidth: 0,
};

const splitPaneReducer = (state: SplitPaneReducerState, { type, payload }: SplitPaneAction) => {
  switch (type) {
    case START_RESIZE:
      return {
        ...state,
        isDragging: true,
        ...payload,
      };
    case STOP_RESIZE:
      return {
        ...state,
        isDragging: false,
        initialPos: 0,
        leftWidth: state.leftWidth + payload,
      };
    default:
      throw new Error('Unsupported action type');
  }
};

interface SplitPaneProps {
  children: ReactNode;
  initialWidth?: number | null;
  onResize?: (leftProportion: number) => unknown;
}

const SplitPane = ({ children, initialWidth = null, onResize = () => ({}) }: SplitPaneProps) => {
  const [state, dispatchAction] = useReducer(splitPaneReducer, {
    ...initialState,
  });

  const { isDragging, leftWidth, maxLeftWidth, initialPos } = state;

  const leftRef = useRef<HTMLDivElement>(null);
  const splitPaneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialWidth && leftRef.current) {
      leftRef.current.style.width = `${initialWidth}%`;
      leftRef.current.style.flex = 'none';
    }
    // initial width of the pane needs to be set only once
  }, []);

  const startResize = useCallback<MouseEventHandler<HTMLDivElement>>(e => {
    if (!leftRef.current || !splitPaneRef.current) {
      return;
    }

    const leftWidth = leftRef.current.clientWidth;
    const splitPaneWidth = splitPaneRef.current.clientWidth - SEPARATOR_WIDTH;

    dispatchAction({
      type: START_RESIZE,
      payload: {
        initialPos: e.clientX,
        leftWidth,
        maxLeftWidth: splitPaneWidth,
      },
    });

    leftRef.current.style.width = `${leftWidth}px`;
    leftRef.current.style.flex = 'none';
  }, []);

  const calculateDelta = useCallback(
    (clientX: number) => {
      const delta = clientX - initialPos;
      const calculatedWidth = leftWidth + delta;

      // calculate movement delta in order to prevent exceeding window borders
      // we want to avoid situation when separator would finish outside of window.
      if (calculatedWidth <= MIN_WIDTH) {
        return MIN_WIDTH - leftWidth;
      } else if (calculatedWidth >= maxLeftWidth) {
        return maxLeftWidth - leftWidth;
      } else {
        return delta;
      }
    },
    [leftWidth, maxLeftWidth, initialPos],
  );

  const resizeDebounced = useCallback(
    () =>
      debounce(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100),
    [],
  );

  const resize = useCallback(
    (e: MouseEvent) => {
      if (!leftRef.current) {
        return;
      }

      if (isDragging) {
        clearSelection();
        const resultDelta = calculateDelta(e.clientX);
        leftRef.current.style.width = `${leftWidth + resultDelta}px`;
        resizeDebounced();
      }
    },
    [isDragging, calculateDelta, resizeDebounced, leftWidth],
  );

  const stopResize = useCallback(
    (e: MouseEvent) => {
      if (!leftRef.current) {
        return;
      }

      if (isDragging) {
        const resultDelta = calculateDelta(e.clientX);
        dispatchAction({ type: STOP_RESIZE, payload: resultDelta });
        const leftProportion = (leftRef.current.clientWidth / maxLeftWidth) * 100;
        onResize(leftProportion);
      }
    },
    [onResize, calculateDelta, isDragging, maxLeftWidth],
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', stopResize);
    document.addEventListener('mouseleave', stopResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResize);
      document.removeEventListener('mouseleave', stopResize);
    };
  }, [resize, stopResize]);

  const componentsToDisplay = useMemo(() => {
    const isChildrenArray = Array.isArray(children);
    const leftSideComponent = isChildrenArray ? children[0] : children;
    const rightSideComponent = isChildrenArray ? children[1] : null;
    return [leftSideComponent, rightSideComponent];
  }, [children]);

  return (
    <div className={`split-pane ${styles.splitPane}`} ref={splitPaneRef}>
      <div className={styles.splitPaneElement} ref={leftRef}>
        {componentsToDisplay[0]}
      </div>
      <div className={isDragging ? styles.dragging : styles.separator} onMouseDown={startResize} />
      <div className={styles.splitPaneElement}>{componentsToDisplay[1]}</div>
    </div>
  );
};

export default SplitPane;
