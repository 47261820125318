import {
  defaultColumnDefinitionWithFilter,
  disabledReadOnlyCheckboxColumnDefinitions,
} from '../../shared/columnDefinitions';
import { partnershipSourceMap } from '../../shared/columnDefinitions/constants';

const checkboxCellClassRules = {
  ...defaultColumnDefinitionWithFilter.cellClassRules,
  'editable-cell': () => false,
};

const getPartnershipSourceLabel = params =>
  partnershipSourceMap.get(params.data.partnershipSource) || null;

const getEntityColumnDefinitions = () => {
  const columnDefinitions = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Organization ID',
      field: 'orgId',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name',
      field: 'orgName',
      width: 400,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Organizational Type',
      field: 'orgType',
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Disregarded (DRE)',
      field: 'isDisregarded',
      width: 150,
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Elimination',
      field: 'elimination',
      width: 150,
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Legal Entity',
      field: 'legalEntityId',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'State of Domicile',
      field: 'domicileJurisdiction',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Business Type',
      field: 'businessDescription',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Org Rule Id',
      field: 'orgRuleId',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Company Type',
      field: 'companyType',
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Federal Filing Type',
      field: 'federalFilingType',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Partnership Source',
      field: 'partnershipSource',
      valueGetter: getPartnershipSourceLabel,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Auto. Fed. Refresh',
      field: 'autoFedRefresh',
      width: 150,
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Date Incorporated',
      field: 'dateIncorporated',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Date Acquired',
      field: 'dateAcquired',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Federal Identification Number',
      field: 'fein',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Country of Incorporation',
      field: 'countryIncorporated',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Signer Id',
      field: 'signerId',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Reviewer Id',
      field: 'pwcReviewerId',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Active',
      field: 'isActive',
      width: 150,
      cellClassRules: checkboxCellClassRules,
    },
  ];

  return columnDefinitions;
};

export default getEntityColumnDefinitions;
