import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import { fetchFilingMembersTypes, updateFilingMembersTypes } from './types';

export const fetchFilingMembers = apiAction({
  types: fetchFilingMembersTypes,
  apiAction: api.fetchFilingMembers,
});

export const updateFilingMembers = apiAction({
  types: updateFilingMembersTypes,
  apiAction: api.updateFilingMembers,
});
