import React, { useCallback, useMemo } from 'react';
import { Button, Banner } from '@pwc/appkit-react';
import { Field, useFormikContext } from 'formik';
import { ColumnTypes } from '@common-packages/shared-constants';
import camelCase from 'lodash.camelcase';

import {
  FindTaxReturnsColumnsBlueprint,
  ReturnDefinitionFilingAttributes,
} from '../../../../../common/types/apiShapes/taxReturns';
import Select from '../../forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../../forms/checkboxFormik/checkboxFormik.component';
import CalcPreferences from '../../../shared/forms/calcPreferences/calcPreferences.component';
import {
  ColumnInBlueprint,
  TaxReturnDefinitionWithFilingAttributes,
} from '../../../../../common/types/apiShapes';
import { ConsolidationFormValues } from '../utils/useFormInitialValues';
import { proformaRefreshOptions } from '../../../admin/consolidations/constants';

import styles from './addEditReturnPanel.module.scss';
import { useAddEditReturnWrapperContext } from './addEditReturnWrapperContext';

interface AddEditCalcOptionsFormProps {
  isEditing: boolean;
  taxReturnsDefinition?: TaxReturnDefinitionWithFilingAttributes;
  taxReturnColumnsBlueprint: FindTaxReturnsColumnsBlueprint;
  returnDefinitionFilingAttributes: ReturnDefinitionFilingAttributes;
  onBackButtonClick: () => void;
  renderBanner: () => JSX.Element | undefined;
}

const CALC_OPTIONS_KEY = 'calcOptions';

const AddEditCalcOptionsForm = ({
  isEditing,
  taxReturnsDefinition,
  taxReturnColumnsBlueprint,
  returnDefinitionFilingAttributes,
  onBackButtonClick,
  renderBanner,
}: AddEditCalcOptionsFormProps) => {
  const {
    values,
    isSubmitting,
    submitForm,
    errors,
    isValid,
    dirty,
  } = useFormikContext<ConsolidationFormValues>();
  const {
    areTaxTypeCheckboxesDisabled,
    filingTypeOptions,
    isFilingTypeSelectDisabled,
    returnTypeOptions,
    isReturnTypeSelectDisabled,
    businessTypeOptions,
    isBusinessTypeSelectDisabled,
  } = useAddEditReturnWrapperContext();

  const isAddSaveButtonDisabled = useMemo(
    () => !dirty || !((values.taxType.franchiseTax || values.taxType.incomeTax) && isValid),
    [values, dirty, isValid],
  );

  const calcPreferences = useMemo(
    () =>
      taxReturnColumnsBlueprint.filter(
        column => column.columnType === ColumnTypes.CALC_PREFERENCES,
      ) as ColumnInBlueprint[],
    [taxReturnColumnsBlueprint],
  );

  const commonCalcPreferences = calcPreferences[0];
  const stateCalcPreferences = calcPreferences[1];

  const renderTaxTypeError = useCallback(
    () =>
      errors.taxType ? <span className="invalid-feedback">{errors.taxType as string}</span> : null,
    [errors.taxType],
  );

  const renderCalcPreferenceBanner = useCallback(() => {
    if (
      isEditing ||
      !taxReturnsDefinition ||
      (!taxReturnsDefinition?.filingSeparateFormName &&
        !taxReturnsDefinition?.nonFilingSeparateFormName)
    ) {
      return;
    }

    const formNameContent =
      taxReturnsDefinition?.filingSeparateFormName &&
      taxReturnsDefinition?.nonFilingSeparateFormName
        ? `${taxReturnsDefinition?.filingSeparateFormName}
        return and ${taxReturnsDefinition?.nonFilingSeparateFormName} return.`
        : `${
            taxReturnsDefinition?.nonFilingSeparateFormName ||
            taxReturnsDefinition?.filingSeparateFormName
          } return.`;

    const bannerContent = (
      <div className={styles.calcPreferenceBanner}>
        <span>SLT automatically generates a return for each member of a consolidation.</span>
        <div>
          For {values?.consolidationId}
          {values?.description ? `- ${values?.description}` : ''}, SLT will generate a
          {` ${formNameContent}`}
        </div>
        <span>You will be able to modify these returns as a group on the Member Returns page.</span>
      </div>
    );
    return <Banner status="warning" content={bannerContent} />;
  }, [values?.consolidationId, values?.description, taxReturnsDefinition, isEditing]);

  return (
    <>
      <div className={styles.calcOptionsTabFields}>
        <div className={styles.calcOptionsSelectGroup}>
          <span className={styles.sectionTitle}>Return Options</span>
          <Select
            wrapperClassName={styles.filingType}
            name="filingType"
            appkitLabel="Filing Type"
            value={values.filingType}
            options={filingTypeOptions}
            disabled={isFilingTypeSelectDisabled}
          />
          <Select
            wrapperClassName={styles.returnType}
            name="returnType"
            appkitLabel="Federal Return Type"
            value={values.returnType}
            options={returnTypeOptions}
            disabled={isReturnTypeSelectDisabled}
          />
          <Select
            wrapperClassName={styles.businessType}
            name="businessType"
            appkitLabel="Business Type"
            value={values.businessType}
            options={businessTypeOptions}
            disabled={isBusinessTypeSelectDisabled}
          />
        </div>
        <div className={styles.taxType}>
          <span>Tax Type</span>
          {returnDefinitionFilingAttributes?.TAX_TYPE?.map(taxType => (
            <Field
              key={taxType.value}
              label={taxType.label}
              name={`taxType.${camelCase(taxType.label)}`}
              value={values.taxType[taxType.value as 'incomeTax' | 'franchiseTax']}
              disabled={areTaxTypeCheckboxesDisabled}
              component={Checkbox}
            />
          ))}
          {renderTaxTypeError()}
        </div>
        <div className={styles.commonCalcOptions}>
          <CalcPreferences
            calcPreferences={{ selectOptions: commonCalcPreferences?.selectOptions || {} }}
            title="Common Calc Preferences"
            hasFormPanelStyle
            calcOptionsKey={CALC_OPTIONS_KEY}
          />
        </div>
        <div className={styles.getConsolidateProformaValues}>
          <Select
            appkitLabel="Get Consolidation Proforma Value"
            name="proformaRefresh"
            value={values.proformaRefresh}
            options={proformaRefreshOptions}
          />
        </div>
        <div className={styles.stateCalcPreferences}>
          <CalcPreferences
            calcPreferences={{ selectOptions: stateCalcPreferences?.selectOptions || {} }}
            title="State Calc Preferences"
            hasFormPanelStyle
            calcOptionsKey={CALC_OPTIONS_KEY}
          />
        </div>
      </div>
      {renderBanner()}
      {renderCalcPreferenceBanner()}
      <div className={styles.buttons}>
        <Button size="lg" title="Back" onClick={onBackButtonClick}>
          Back
        </Button>
        <Button
          size="lg"
          title={isEditing ? 'Save' : 'Add'}
          onClick={submitForm}
          isLoading={isSubmitting}
          disabled={isAddSaveButtonDisabled}
        >
          {isEditing ? 'Save' : 'Add'}
        </Button>
      </div>
    </>
  );
};

export default AddEditCalcOptionsForm;
