import {
  adminColumnDefinition,
  defaultColumnDefinitionWithFilter,
  currencyColumnDefinition,
} from '../../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../../shared/columnDefinitions/cellRenderers';
import { amountFormatter } from '../../../shared/formatters';
import { TaxRatesDescription } from '../constants';

const getColumnDefinitions = ({ isInEditMode, onDeleteIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: isInEditMode,
    onDeleteIconClick,
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
      width: 40,
      maxWidth: 40,
      minWidth: 40,
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
      width: 120,
      maxWidth: 120,
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Rate',
      field: 'rate',
      valueFormatter: ({ value }) => amountFormatter({ value, props: { decimalPlaces: 5 } }),
      pinned: 'left',
      lockPinned: true,
      width: 80,
      maxWidth: 80,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Start Range',
      field: 'startRange',
      width: 80,
      maxWidth: 80,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Stop Range',
      field: 'stopRange',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Rate Type',
      field: 'taxType',
      valueFormatter: ({ value }) => TaxRatesDescription[value],
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Exclusion Rate',
      field: 'exclusionRate',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Exclusion Amount',
      field: 'exclusionAmt',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Filing Attributes',
      field: 'filingAttributes',
      minWidth: 100,
      width: 100,
      maxWidth: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Calc Preferences',
      field: 'calcPreferences',
      width: 100,
      maxWidth: 100,
      minWidth: 100,
    },
  ];
};

export default getColumnDefinitions;
