import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import {
  filingTypeIdSelector,
  taxYearSelector,
  periodSelector,
} from '../../../shared/store/selectors';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';
import { defaultSideBarWithColumnsToolPanel } from '../../../shared/displayComponents/agGrid/constants';
import {
  constantsSelector,
  isFetchingConstantsSelector,
  constantsColumnsBlueprintSelector,
} from '../../../shared/store/calculationConstants/selectors';
import { jurisdictionIdSelector, isFetchingContextSelector } from '../store/selectors/shared';
import { deleteConstant, fetchConstants } from '../../../shared/store/calculationConstants/actions';
import getColumnBlueprintBasedColumnDefinitions from '../../../shared/columnDefinitions/columnsBlueprint.columnDefinition';
import { columnBlueprintHeaderOptions } from '../../../shared/columnDefinitions/constants';
import columnBlueprintStyles from '../../../shared/styles/columnBlueprintStyles.module.scss';

import getColumnDefinitions from './constants.columnDefinitions';
import styles from './constants.module.scss';

const Constants = ({ hasUserPermissionsToEdit, ...agGridProps }) => {
  const { queryParams, setParams } = useUrlParams();

  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const filingTypeId = useSelector(filingTypeIdSelector);
  const constants = useSelector(constantsSelector);
  const isFetchingContext = useSelector(isFetchingContextSelector);
  const isFetchingConstants = useSelector(isFetchingConstantsSelector);
  const columnsBlueprint = useSelector(constantsColumnsBlueprintSelector);

  useEffect(() => {
    if (queryParams.search || queryParams.search === '') {
      setParams({ queryParams: { search: null } });
    }
  }, [setParams, queryParams.search]);

  const isContextReady = taxYear && period && jurisdictionId && filingTypeId;

  useEffect(() => {
    if (isContextReady) {
      dispatch(
        fetchConstants({
          taxYear,
          period,
          jurisdictionId,
          filingTypeId,
        }),
      );
    }
  }, [dispatch, isContextReady, taxYear, period, jurisdictionId, filingTypeId]);

  const clearSelection = useCallback(() => null, []);

  const openModalForDelete = useCallback(
    row => {
      dispatch(
        showConfirmModal({
          title: 'Delete constant?',
          text: 'Are you sure you want to delete this constant?',
          confirmCallback: async () => {
            await dispatch(
              deleteConstant({
                taxYear,
                period,
                jurisdictionId,
                filingTypeId,
                constantId: row.constantId,
                filingAttributes: row.filingAttributes,
                calcOptions: row.calcOptions,
              }),
            );
            dispatch(
              fetchConstants({
                taxYear,
                period,
                jurisdictionId,
                filingTypeId,
              }),
            );
            clearSelection();
          },
        }),
      );
    },
    [dispatch, clearSelection, taxYear, period, jurisdictionId, filingTypeId],
  );

  const columnDefinitions = useMemo(
    () => [
      ...getColumnDefinitions({
        isInEditMode: hasUserPermissionsToEdit,
        onDeleteIconClick: openModalForDelete,
      }),
      ...getColumnBlueprintBasedColumnDefinitions({ columnsBlueprint }),
    ],
    [hasUserPermissionsToEdit, openModalForDelete, columnsBlueprint],
  );

  return (
    <div
      className={`row grid-row ${styles.constantsContentWrapper} ${columnBlueprintStyles.gridContainer}`}
    >
      <div className="col">
        <AgGrid
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingContext || isFetchingConstants}
          rowData={constants}
          initialQuickFilterValue={queryParams.search || ''}
          sideBar={defaultSideBarWithColumnsToolPanel}
          {...columnBlueprintHeaderOptions}
          {...agGridProps}
          headerHeight={50}
          groupHeaderHeight={30}
          suppressColumnVirtualisation={false}
          suppressRowVirtualisation={false}
        />
      </div>
    </div>
  );
};

Constants.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default Constants;
