import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationUploadFilingGroupMembers = () =>
  useCustomMutation({
    handleData: ({ rows, taxYear, period }) => ({
      url: '/api/tools/filing-group-members/upload',
      method: HttpMethods.POST,
      body: { rows, taxYear, period },
    }),
    resetKey: QueryKeys.FilingGroups.FilingMembers,
    successMessage: 'Filing group members uploaded successfully',
    errorMessage: 'Failed to upload filing group members',
  });

export const useMutationValidateFilingGroupMembers = () =>
  useCustomMutation({
    handleData: ({ rows, taxYear, period }) => ({
      url: '/api/tools/filing-group-members/validate',
      method: HttpMethods.POST,
      body: { rows, taxYear, period },
    }),
    errorMessage: 'Failed to validate filing group members upload file',
    parseResponseAsJson: true,
  });
