import { connect } from 'react-redux';

import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import { filingStatesJurisdictionDescriptionSelector } from '../../shared/store/selectors';
import { isFetchingFilingStatesSelector } from '../../filingStates/store/selectors';

export default connect(state => ({
  label: 'Jurisdiction',
  value: filingStatesJurisdictionDescriptionSelector(state),
  isBusy: isFetchingFilingStatesSelector(state),
}))(GlobalContextValue);
