import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../shared/store/reducerFactory';
import {
  SELECT_TAX_YEAR,
  SELECT_PERIOD,
  SELECT_ENTITY,
  SELECT_JURISDICTION,
} from '../../shared/store/types';

import {
  fetchBinderItemsTypes,
  updateBinderItemsTypes,
  updateBinderItemsOrderTypes,
  copyBinderTypes,
  fetchBinderFormsTypes,
  fetchBinderAttachmentsTypes,
  fetchBinderPdfLastGenerationDateTypes,
  startProcessToGenerateBinderPdfTypes,
  downloadBinderPdfTypes,
  RESET_COPIED_BINDER_TYPE,
} from './types';

export const initialState = {
  binderItems: [],
  isFetchingBinderItems: false,

  isEditingBinderItems: false,

  binderForms: [],
  isFetchingBinderForms: false,

  binderAttachments: [],
  isFetchingBinderAttachments: false,

  binderPdfLastGenerationDate: {},
  isFetchingBinderPdfLastGenerationDate: false,
  isDownloadingBinderPdf: false,

  copiedBinder: null,

  /**
   * It can take an undetermined amount of time to generate the binder pdf since it's an async Print Service job.
   * Therefore, this is just the state of the initial call to Print Service to start the process.
   */
  isStartingProcessOfGeneratingBinderPdf: false,
};

const binderMaintenanceReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchBinderItemsTypes,
      payloadKey: 'binderItems',
      fetchingKey: 'isFetchingBinderItems',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateBinderItemsTypes,
      fetchingKey: 'isEditingBinderItems',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateBinderItemsOrderTypes,
      fetchingKey: 'isEditingBinderItems',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: copyBinderTypes,
      fetchingKey: 'isEditingBinderItems',
      payloadKey: 'copiedBinder',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchBinderFormsTypes,
      payloadKey: 'binderForms',
      fetchingKey: 'isFetchingBinderForms',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchBinderAttachmentsTypes,
      payloadKey: 'binderAttachments',
      fetchingKey: 'isFetchingBinderAttachments',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchBinderPdfLastGenerationDateTypes,
      payloadKey: 'binderPdfLastGenerationDate',
      fetchingKey: 'isFetchingBinderPdfLastGenerationDate',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: downloadBinderPdfTypes,
      fetchingKey: 'isDownloadingBinderPdf',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: startProcessToGenerateBinderPdfTypes,
      fetchingKey: 'isStartingProcessOfGeneratingBinderPdf',
    }),
    [RESET_COPIED_BINDER_TYPE]: state => ({
      ...state,
      copiedBinder: null,
    }),
    ...[SELECT_TAX_YEAR, SELECT_PERIOD, SELECT_ENTITY, SELECT_JURISDICTION].reduce(
      (actionHandlers, action) => ({
        ...actionHandlers,
        [action]: state => ({
          ...state,
          copiedBinder: null,
        }),
      }),
      {},
    ),
  },
});

export default binderMaintenanceReducer;
