import { RESET_REDUX_STATE } from './types';

const defaultActionHandler = state => state;
const reducerFactory = ({ initialState, actionHandlers }) => (
  state = initialState,
  { type, payload, params },
) => {
  if (type === RESET_REDUX_STATE) {
    return initialState;
  }

  return (actionHandlers[type] || defaultActionHandler)(state, payload, type, params);
};

export default reducerFactory;
