const { stateOverrideValueSchema } = require('./schemas/stateOverrideValue.validation');

module.exports = {
  validationRegex: require('./validationRegex'),
  customValidators: require('./customValidators'),
  federalReconciliationsSchemas: require('./schemas/federalReconciliations.validation'),
  reviewerNotesSchemas: require('./schemas/reviewerNotes.validation'),
  partnershipInformationSchemas: require('./schemas/partnershipInformation.validation'),
  customReportsSchemas: require('./schemas/customReports.validation'),
  efileSchemas: require('./schemas/efile.validation'),
  filingDecisionsSchemas: require('./schemas/filingDecisions.validation'),
  filingDecisionsV2Schemas: require('./schemas/filingDecisionsV2.validation'),
  tasksSchemas: require('./schemas/tasks.validation'),
  accountDataSchemas: require('./schemas/accountData.validation'),
  nexusNotesSchemas: require('./schemas/nexusNotes.validation'),
  genericCategoryScreenSchemas: require('./schemas/genericCategoryScreen.validation'),
  pdfAttachmentsSchema: require('./schemas/pdfAttachments.validation'),
  taxFormsSchemas: require('./schemas/taxForms.validation'),
  taxFormsV2Schemas: require('./schemas/taxFormsV2.validation'),
  usersMaintenanceSchemas: require('./schemas/usersMaintenance.validation'),
  rolesSchemas: require('./schemas/roles.validation'),
  genericScreenDefinitionSchemas: require('./schemas/genericScreenDefinition.validation'),
  genericScreenDefinitionRowSchemas: require('./schemas/genericScreenDefinitionRow.validation'),
  genericFunctionsSchemas: require('./schemas/genericFunctions.validation'),
  dataModelSchemas: require('./schemas/dataModel.validation'),
  accountSchemas: require('./schemas/accounts.validation'),
  informationalDataSchemas: require('./schemas/informationalData.validation'),
  stateSpecificInformationSchemas: require('./schemas/stateSpecificInformation.validation'),
  infoAccountDataItemSchemas: require('./schemas/infoAccountDataItemSchema.validation'),
  memberInformationalDataSchemas: require('./schemas/memberInformationalData.validation'),
  constantSchema: require('./schemas/constant.validation'),
  taxRateSchema: require('./schemas/taxRates.validation'),
  entitySchemas: require('./schemas/entity.validation'),
  individualSchemas: require('./schemas/individual.validation'),
  filingGroupsSchemas: require('./schemas/filingGroup.validation'),
  filingGroupsSchemasV2: require('./schemas/filingGroups.validation'),
  federalProFormaFilingGroupsSchemas: require('./schemas/federalProFormaFilingGroup.validation'),
  sharedSchemas: require('./schemas/shared.validation'),
  entityInformationSchemas: require('./schemas/entityInformation.validation'),
  partnershipAllocationPercentagesSchemas: require('./schemas/partnershipAllocationPercentages.validation'),
  businessGroupsSchemas: require('./schemas/businessGroups.validation'),
  businessesSchemas: require('./schemas/businesses.validation'),
  consolidationsSchemas: require('./schemas/consolidations.validation'),
  calcSpecsSchemas: require('./schemas/calcSpecs.validation'),
  binderItemsSchemas: require('./schemas/binderItems.validation'),
  bindersSchemas: require('./schemas/binders.validation'),
  attachmentsSchema: require('./schemas/attachments.validation'),
  k1InformationSchemas: require('./schemas/k1Information.validation'),
  k1InformationV2Schemas: require('./schemas/k1InformationV2.validation'),
  editInfoAccountSchemas: require('./schemas/editInfoAccount.validation'),
  viewTaxReturnSchemas: require('./schemas/viewTaxReturn.validation'),
  watermarkSchemas: require('./schemas/watermark.validation.js'),
  selectionListsSchemas: require('./schemas/selectionLists.validation.js'),
  forceEntityBatchCalculationSchema: require('./schemas/forceEntityBatchCalculation.validation'),
  checkboxGroupsSchema: require('./schemas/checkboxGroups.validation'),
  calcPreferencesSchemas: require('./schemas/calcPreferences.validation'),
  notificationSchemas: require('./schemas/notification.validation'),
  returnDefinitionsSchemas: require('./schemas/returnDefinitions.validation'),
  taxReturnsSchemas: require('./schemas/taxReturns.validation'),
  dataTypeSchemaPart: require('./schemas/dataTypeSchemaPart'),
  getDueDateSchema: require('./getDuedateSchema'),
  stateOverrideValueSchema,
  efileElementMappingSchemas: require('./schemas/efileElementMapping.validation'),
  memberReturnsSchemas: require('./schemas/memberReturns.validation'),
};
