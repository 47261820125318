import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import { SelectOptionPropTypes } from '../../../shared/forms/propTypes';
import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../../../shared/forms/checkboxFormik/checkboxFormik.component';
import ButtonsGroup from '../../../shared/forms/buttonsGroup/buttonsGroup.component';
import Loading from '../../../shared/displayComponents/loading.component';
import { fetchDataItems } from '../../../shared/store/dataModels/actions';
import {
  dataItemsOptionsSelector,
  isFetchingDataItemsSelector,
  datasetsByPageOptionsSelector,
} from '../../../shared/store/dataModels/selectors';
import formStyles from '../../../shared/forms/forms.module.scss';
import { taxYearSelector } from '../../../shared/store/selectors';

import styles from './fieldMappingAttributesForm.module.scss';

const checkboxOptions = [
  {
    label: 'N/A',
    value: '0',
  },
  {
    label: 'Standard Checkbox',
    value: '1',
  },
  {
    label: 'Checkbox-Yes',
    value: 'Y',
  },
  {
    label: 'Checkbox-No',
    value: 'N',
  },
];

const valueIfZeroOptions = [
  {
    label: 'Blank',
    value: '0',
  },
  {
    label: 'Zero',
    value: '1',
  },
  {
    label: 'None',
    value: '2',
  },
  {
    label: 'N/A',
    value: '3',
  },
];

const WrappedCheckboxField = ({ className, ...props }) => (
  <div className={className}>
    <Field {...props} />
  </div>
);

WrappedCheckboxField.propTypes = {
  className: PropTypes.string,
};

const FieldMappingAttributesForm = ({
  values,
  isSubmitting,
  onCancelClick,
  submitForm,

  fetchDataItems,
  dataItemsOptions,
  isFetchingDataItems,
  datasetsByPageOptions,

  taxYear,
}) => {
  useEffect(() => {
    if (values.datasetId && taxYear) {
      fetchDataItems({ datasetId: values.datasetId, taxYear });
    }
  }, [fetchDataItems, values.datasetId, taxYear]);

  return (
    <form>
      <div className={`row ${formStyles.formSection}`}>
        <div className="col">
          <Select
            appkitLabel="Dataset"
            name="datasetId"
            options={datasetsByPageOptions}
            value={values.datasetId}
            showSearchOnToggle
          />
        </div>
        <div className="col">
          <Loading isLoading={isFetchingDataItems} small>
            <Select
              appkitLabel="Data Item"
              name="dataItemId"
              options={dataItemsOptions}
              value={values.dataItemId}
              showSearchOnToggle
            />
          </Loading>
        </div>
      </div>

      <div className={`row ${formStyles.formSection}`}>
        <div className={styles.checkboxesGroupWithInlineLabel}>
          <label className="a-form-label">Value Formats</label>
          <div className={styles.checkboxesGroup}>
            <WrappedCheckboxField
              label="Suppress Commas"
              name="suppressCommas"
              component={Checkbox}
            />
            <WrappedCheckboxField label="Negative()" name="parensIfNegative" component={Checkbox} />
            <WrappedCheckboxField
              label="Absolute Value"
              name="absoluteValue"
              component={Checkbox}
            />
            <WrappedCheckboxField
              label="Ratio As Percent"
              name="ratioAsPercentage"
              component={Checkbox}
            />
            <Select
              wrapperClassName={`form-text ${styles.formText}`}
              appkitLabel="Value If Zero"
              name="valueIfZero"
              options={valueIfZeroOptions}
              value={values.valueIfZero}
            />
            <Field
              className={`form-text ${styles.formText}`}
              label="Decimal Places"
              name="decimalPlaces"
              min="0"
              max="9"
              component={Input}
              autoComplete="off"
              type="number"
            />
          </div>
        </div>
      </div>

      <div className={`row ${formStyles.formSection}`}>
        <div className={styles.checkboxesGroupWithInlineLabel}>
          <label className="a-form-label">Wrapping Options</label>
          <div className={styles.checkboxesGroup}>
            <WrappedCheckboxField label="Wrap Text" name="wrapText" component={Checkbox} />
            <Field
              className={`form-text ${styles.formText}`}
              label="First Row Indent Chrs"
              name="wrapFirstRowIndt"
              component={Input}
              autoComplete="off"
              type="number"
            />
            <Field
              className={`form-text ${styles.formText}`}
              label="Full Row Count"
              name="wrapFullRowcount"
              component={Input}
              autoComplete="off"
              type="number"
            />
            <Field
              className={`form-text ${styles.formText}`}
              label="Row Spacing Increment"
              name="wrapRowspacingIncrement"
              component={Input}
              autoComplete="off"
              type="number"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className={`${styles.checkboxesGroupWithInlineLabel}`}>
          <label className="a-form-label">Additional Options</label>
          <div className={styles.checkboxesGroup}>
            <WrappedCheckboxField
              label="Print First Copy Only"
              name="printFirstCopyOnly"
              component={Checkbox}
            />
            <WrappedCheckboxField
              label="Print Last Copy Only"
              name="printLastCopyOnly"
              component={Checkbox}
            />
            <WrappedCheckboxField label="Upper Case" name="upperCase" component={Checkbox} />
            <Select
              wrapperClassName={`form-text ${styles.formText}`}
              appkitLabel="Checkbox"
              name="checkBox"
              options={checkboxOptions}
              value={values.checkBox}
            />
            <Field
              className={`form-text ${styles.formText}`}
              label="Formatting String"
              name="formattingString"
              component={Input}
              autoComplete="off"
            />
            <Field
              className={`form-text ${styles.formText}`}
              label="Append Text"
              name="appendText"
              component={Input}
              autoComplete="off"
            />
            <Field
              className={`form-text ${styles.formText}`}
              label="Chr Spacing"
              name="characterSpacing"
              component={Input}
              autoComplete="off"
            />
            <Field
              className={`form-text ${styles.formText}`}
              label="Fullfield Pattern"
              name="fullfieldPattern"
              component={Input}
              autoComplete="off"
            />
            <Field
              className={`form-text ${styles.formText}`}
              label="Fullfield Length"
              name="fullfieldLength"
              component={Input}
              autoComplete="off"
              type="number"
            />
          </div>
          <WrappedCheckboxField
            className={styles.checkboxOutsideGridLayout}
            label="Flip Sign"
            name="flipSign"
            component={Checkbox}
          />
        </div>
      </div>
      <div className="row">
        <div className="col" style={{ marginTop: '16px' }}>
          <ButtonsGroup
            disabled={isFetchingDataItems}
            fullWidth={false}
            submitButtonLabel="Save"
            isSubmitting={isSubmitting}
            onCancelClick={onCancelClick}
            submitForm={submitForm}
          />
        </div>
      </div>
    </form>
  );
};

const formValuesPropTypes = PropTypes.shape({
  datasetId: PropTypes.string,
  dataItemId: PropTypes.string,
  suppressCommas: PropTypes.bool.isRequired,
  parensIfNegative: PropTypes.bool.isRequired,
  absoluteValue: PropTypes.bool.isRequired,
  ratioAsPercentage: PropTypes.bool.isRequired,
  wrapText: PropTypes.bool.isRequired,
  printFirstCopyOnly: PropTypes.bool.isRequired,
  upperCase: PropTypes.bool.isRequired,
  fullRowCount: PropTypes.number,
  numRowsPerInch: PropTypes.number,
  rowSpacingIncrement: PropTypes.number,
  wrapFirstRowIndt: PropTypes.string,
  wrapFullRowcount: PropTypes.string,
  wrapRowspacingIncrement: PropTypes.string,
  formattingString: PropTypes.string,
  checkBox: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valueIfZero: PropTypes.string,
});

FieldMappingAttributesForm.propTypes = {
  values: formValuesPropTypes.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  fetchDataItems: PropTypes.func.isRequired,
  dataItemsOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingDataItems: PropTypes.bool.isRequired,
  datasetsByPageOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  taxYear: PropTypes.string,
};

export default connect(
  state => ({
    dataItemsOptions: dataItemsOptionsSelector(state),
    isFetchingDataItems: isFetchingDataItemsSelector(state),
    datasetsByPageOptions: datasetsByPageOptionsSelector(state),
    taxYear: taxYearSelector(state),
  }),
  {
    fetchDataItems,
  },
)(FieldMappingAttributesForm);
