import { dataTypes } from '@common-packages/shared-constants';

import oracleSysGuidUuid from '../utils/oracleSysGuidUuid';

const DEFAULT_VALUES = {
  [dataTypes.CURRENCY]: '0',
  [dataTypes.RATIO]: '0',
  [dataTypes.INTEGER]: '0',
  [dataTypes.DATE]: '',
  [dataTypes.BOOLEAN]: false,
  [dataTypes.STRING]: '',
};

const prepareNewWorkpaperInstance = (columnsBlueprint, sortOrder) => {
  return columnsBlueprint.reduce(
    (row, currentColumn) =>
      Object.assign(row, {
        [currentColumn.field]: DEFAULT_VALUES[currentColumn.dataType],
      }),
    { datasetInstanceId: oracleSysGuidUuid(), sortOrder },
  );
};

export default prepareNewWorkpaperInstance;
