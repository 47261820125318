import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@pwc/appkit-react';

import {
  periodSelector,
  taxYearSelector,
  jurisdictionIdSelector,
  lockedJurisdictionsSelector,
  isFetchingLockedJurisdictionsSelector,
} from '../shared/store/selectors';
import {
  ActionMenu,
  ActionMenuItem,
} from '../shared/displayComponents/actionMenu/actionMenu.component';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import Loading from '../shared/displayComponents/loading.component';
import {
  initiateEntityBatchCalculation,
  fetchJurisdictionsForWhichEntityIsLocked,
} from '../shared/store/actions';
import TooltipContent from '../shared/displayComponents/tooltipContent/tooltipContent.component';

import { entitySelector } from './store/selectors';
import EntityInformation from './entityInformation.container';
import DataCollection from './dataCollection.component';

import './entity.style.scss';

const TABS_TYPES = {
  ENTITY_INFORMATION: 'ENTITY_INFORMATION',
  DATA_COLLECTION: 'DATA_COLLECTION',
};

const tabsDefinitions = [
  { type: TABS_TYPES.ENTITY_INFORMATION, label: 'Entity Information' },
  { type: TABS_TYPES.DATA_COLLECTION, label: 'Data Collection' },
];

const Entity = ({
  taxYear,
  period,
  entity,
  showConfirmModal,
  fetchJurisdictionsForWhichEntityIsLocked,
  lockedJurisdictions,
  isFetchingLockedJurisdictions,
  initiateEntityBatchCalculation,

  hasUserPermissionsToEdit,
}) => {
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);
  const isContextReady = taxYear && period && entity;

  useEffect(() => {
    if (isContextReady) {
      fetchJurisdictionsForWhichEntityIsLocked({
        taxYear,
        period,
        orgId: entity.entityId,
      });
    }
  }, [fetchJurisdictionsForWhichEntityIsLocked, isContextReady, taxYear, period, entity]);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.ENTITY_INFORMATION:
        return <EntityInformation />;
      case TABS_TYPES.DATA_COLLECTION:
        return <DataCollection />;
      default:
        throw new Error('Unsupported tab type');
    }
  };

  const onBatchCalculationClick = useCallback(() => {
    showConfirmModal({
      title: 'Perform calculation',
      text: 'Perform batch calculation?',
      confirmCallback: async () =>
        await initiateEntityBatchCalculation({
          taxYear,
          orgId: entity.entityId,
          period,
        }),
    });
  }, [showConfirmModal, initiateEntityBatchCalculation, taxYear, entity, period]);

  const showActionMenu = activeTab === TABS_TYPES.ENTITY_INFORMATION;

  const isLoadingDataForActionMenu = !isContextReady || isFetchingLockedJurisdictions;

  const locked = lockedJurisdictions && Boolean(lockedJurisdictions.length);

  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }, index) => (
              <Tab id={type} value={type} label={label} key={index} />
            ))}
          </Tabs>
          {hasUserPermissionsToEdit && showActionMenu && (
            <Loading isLoading={isLoadingDataForActionMenu} small>
              <ActionMenu>
                <ActionMenuItem
                  text={'Entity Batch Calculation'}
                  onClick={onBatchCalculationClick}
                  locked={locked}
                  tooltipContent={<TooltipContent lockedJurisdictions={lockedJurisdictions} />}
                />
              </ActionMenu>
            </Loading>
          )}
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">{getActiveTabComponent()}</div>
      </div>
    </>
  );
};

Entity.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entity: PropTypes.shape({
    entityId: PropTypes.string.isRequired,
    fein: PropTypes.string,
  }),
  jurisdictionId: PropTypes.string,
  showConfirmModal: PropTypes.func.isRequired,
  fetchJurisdictionsForWhichEntityIsLocked: PropTypes.func.isRequired,
  lockedJurisdictions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
  ),
  isFetchingLockedJurisdictions: PropTypes.bool.isRequired,
  initiateEntityBatchCalculation: PropTypes.func.isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entity: entitySelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    lockedJurisdictions: lockedJurisdictionsSelector(state),
    isFetchingLockedJurisdictions: isFetchingLockedJurisdictionsSelector(state),
  }),
  {
    showConfirmModal,
    initiateEntityBatchCalculation,
    fetchJurisdictionsForWhichEntityIsLocked,
  },
)(Entity);
