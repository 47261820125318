import React from 'react';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import { BreadcrumbRoute } from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

import { SUBPATH_TO_NAMESPACE } from './constants';

const returnWorkspaceBreadcrumbRoutes = ({ path }) => {
  const namespace = SUBPATH_TO_NAMESPACE[path.MAIN];

  return (
    <>
      <BreadcrumbRoute path={Routes[namespace]} />
      <BreadcrumbRoute path={Routes[`${namespace}TaxSummaries`]} />
      <BreadcrumbRoute path={Routes[`${namespace}AllReturnData`]} />
      <BreadcrumbRoute path={Routes[`${namespace}GenericCategory`]} />
      <BreadcrumbRoute path={Routes[`${namespace}TaxRateDetails`]} />
      <BreadcrumbRoute path={Routes[`${namespace}ApportionmentRateDetails`]} />
      <BreadcrumbRoute path={Routes[`${namespace}GenericCategoryFromApportionmentRateDetails`]} />
      <BreadcrumbRoute path={Routes[`${namespace}PropertySummary`]} />
      <BreadcrumbRoute path={Routes[`${namespace}GenericCategoryFromPropertySummary`]} />
      <BreadcrumbRoute path={Routes[`${namespace}ViewPrintAttachmentDetails`]} />
      <BreadcrumbRoute path={Routes[`${namespace}ViewFormWorkpaper`]} />
      <BreadcrumbRoute path={Routes[`${namespace}WorkpaperInstance`]} />
    </>
  );
};

returnWorkspaceBreadcrumbRoutes.propTypes = {
  path: PropTypes.shape({
    MAIN: PropTypes.string.isRequired,
  }).isRequired,
};

export default returnWorkspaceBreadcrumbRoutes;
