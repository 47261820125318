import { connect } from 'react-redux';

import { GlobalContextValue } from '../../../shared/containerComponents/globalContext';
import { entityNameSelector, isFetchingEntitiesSelector } from '../../../shared/store/selectors';

export default connect(state => ({
  label: 'Org Description',
  value: entityNameSelector(state),
  isBusy: isFetchingEntitiesSelector(state),
}))(GlobalContextValue);
