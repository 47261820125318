import {
  httpGetAndParse,
  httpPut,
  httpPost,
  httpDelete,
  httpPostAndParse,
} from '../../../utils/fetchService';
import stringify from '../../../utils/stringify';

export const fetchDataModels = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching data models failed',
  });

export const fetchDatasets = ({ taxYear, jurisdictionId, dataModelId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/datasets/${taxYear}/${jurisdictionId}/${dataModelId}`,
    errorMessage: 'Fetching data models data sets failed',
  });

export const fetchAllDatasets = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/datasets/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching data models data sets failed',
  });

export const fetchDataItems = ({ datasetId, taxYear }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/data-items/${taxYear}/${datasetId}`,
    errorMessage: 'Fetching data models data items failed',
  });

export const fetchMappingForms = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/mapping-forms/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching data models mapping forms failed',
  });

export const fetchWorkpaperForms = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/workpaper-forms/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching data models workpaper forms failed',
  });

export const fetchMappingFormPDFPageDataUsageAndAttachmentConfigs = ({ pdfId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/pdf-page-data-usage-and-attachment-configs/${pdfId}`,
    errorMessage: 'Fetching pdf page data usage and attachment config failed',
  });

export const fetchPDFFieldsByPage = ({ pdfId, pageNumber }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/fields/${pdfId}/${pageNumber}`,
    errorMessage: 'Fetching pdf fields by page failed',
  });

export const mapPDFField = ({
  datasetUsageId,
  pdfFieldId,
  dataItemDefId,
  checkBox,
  ynCheckBox,
  formattingString,
  upperCase,
  valueIfZero,
  absoluteValue,
  suppressCommas,
  parensIfNegative,
  ratioAsPercentage,
  decimalPlaces,
  appendText,
  wrapText,
  printFirstCopyOnly,
  printLastCopyOnly,
  wrapFirstRowIndt,
  wrapFullRowcount,
  wrapRowspacingIncrement,
  characterSpacing,
  fullfieldPattern,
  fullfieldLength,
  flipSign,
}) =>
  httpPost({
    route: `/api/shared/data-models/map-field`,
    errorMessage: 'Mapping pdf field failed',
    parseResponseErrorMessage: true,
    body: {
      datasetUsageId,
      pdfFieldId,
      dataItemDefId,
      checkBox,
      ynCheckBox,
      formattingString,
      upperCase,
      valueIfZero,
      absoluteValue,
      suppressCommas,
      parensIfNegative,
      ratioAsPercentage,
      decimalPlaces,
      appendText,
      wrapText,
      printFirstCopyOnly,
      printLastCopyOnly,
      wrapFirstRowIndt,
      wrapFullRowcount,
      wrapRowspacingIncrement,
      characterSpacing,
      fullfieldPattern,
      fullfieldLength,
      flipSign,
    },
  });

export const removePDFFieldMap = ({ pdfFieldMappingId }) =>
  httpDelete({
    route: `/api/shared/data-models/map-field`,
    errorMessage: 'Removing pdf field map failed',
    parseResponseErrorMessage: true,
    body: {
      pdfFieldMappingId,
    },
  });

export const assignPDFDataUsage = ({ id, pdfId, datasetDefinitionId, pageNumber }) =>
  httpPost({
    route: `/api/shared/data-models/pdf-data-usage`,
    errorMessage: 'Assigning PDF data usage failed',
    parseResponseErrorMessage: true,
    body: {
      id,
      pdfId,
      datasetDefinitionId,
      pageNumber,
    },
  });

export const unassignPDFDataUsage = ({ mappingId }) =>
  httpDelete({
    route: `/api/shared/data-models/pdf-data-usage/${mappingId}`,
    errorMessage: 'Unassigning PDF data usage failed',
    parseResponseErrorMessage: true,
  });

export const fetchDataTypes = () =>
  httpGetAndParse({
    route: `/api/shared/data-models/data-types`,
    errorMessage: 'Fetching data models data types failed',
  });

export const fetchOverflowActionTypes = () =>
  httpGetAndParse({
    route: `/api/shared/data-models/overflow-action-types`,
    errorMessage: 'Fetching data models overflow action types failed',
  });

export const fetchPDFAttachmentColumnProperties = ({ datasetDefId, pageNumber, pdfId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/pdf-attachment-column-properties/${pdfId}/${pageNumber}/${datasetDefId}`,
    errorMessage: 'Fetching PDF Attachment Column Properties failed',
  });

export const updatePdfDataUsageAndAttachmentConfig = ({
  taxYear,
  jurisdictionId,
  pdfDataUsageId,
  instancesPerPage,
  instToSkip,
  overflowAction,
  fontSize,
  pageWidthPercentage,
  pdfStmtRefFieldName,
  printOrientationLandscape,
  attachmentColumnPropertiesChanges,
  uiLocationFieldName,
  includeConsMembers,
  calcPreferenceOn,
  calcPreferenceName,
}) =>
  httpPut({
    route: `/api/shared/data-models/pdf-data-usage-and-attachment-config/${pdfDataUsageId}`,
    errorMessage: 'Updating PDF data usage and attachment config failed',
    body: {
      taxYear,
      jurisdictionId,
      instancesPerPage,
      instToSkip,
      overflowAction,
      fontSize,
      pageWidthPercentage,
      pdfStmtRefFieldName,
      printOrientationLandscape,
      attachmentColumnPropertiesChanges,
      uiLocationFieldName,
      includeConsMembers,
      calcPreferenceOn,
      calcPreferenceName,
    },
  });

export const updateDataModel = ({
  taxYear,
  jurisdictionId,
  dataModelId,
  datasetId,
  dataset,
  dataItems,
  dataModel,
}) =>
  httpPut({
    route: `/api/shared/data-models/${dataModelId}/data-set/${taxYear}/${jurisdictionId}/${datasetId}`,
    errorMessage: 'Updating data model, dataset and/or data items failed',
    body: { dataset, dataItems, dataModel },
  });

export const addDataModel = ({
  taxYear,
  jurisdictionId,
  id,
  name,
  description,
  contextTypeId,
  isCommon,
  shouldDisplayWorkpaperOnNavigator,
}) =>
  httpPost({
    route: `/api/shared/data-models/data-model/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Adding data model failed',
    body: { id, name, description, contextTypeId, isCommon, shouldDisplayWorkpaperOnNavigator },
  });

export const addDataset = ({
  id,
  taxYear,
  jurisdictionId,
  dataModelId,
  parentId,
  name,
  description,
  instanceName,
  instanceIdentifier,
  maxInstances,
  allowCons,
  aggregateSeparateInstances,
}) =>
  httpPost({
    route: `/api/shared/data-models/dataset/${taxYear}/${jurisdictionId}/${dataModelId}`,
    errorMessage: 'Adding dataset failed',
    body: {
      id,
      parentId,
      name,
      description,
      instanceName,
      instanceIdentifier,
      maxInstances,
      allowCons,
      aggregateSeparateInstances,
    },
  });

export const fetchDataDefinitions = ({ taxYear, period, jurisdictionId, datasetDefId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/data-item-definitions/${taxYear}/${period}/${jurisdictionId}/${datasetDefId}`,
    errorMessage: 'Fetching data item definitions failed',
  });

export const fetchDataItemsDefinitions = ({
  taxYear,
  period,
  filingTypeId,
  jurisdictionId,
  datasetDefId,
}) =>
  httpGetAndParse({
    route: `/api/shared/data-models/data-items-definitions-print-service/${taxYear}/${period}/${filingTypeId}/${jurisdictionId}/${datasetDefId}`,
    errorMessage: 'Fetching data item definitions failed',
  });

export const fetchDatasetInstances = ({
  taxYear,
  period,
  filingTypeId,
  entityId,
  consolidationId,
  datasetDefId,
  parentDatasetInstanceId,
}) =>
  httpGetAndParse({
    route: `/api/shared/data-models/dataset-instances/${taxYear}/${period}/${filingTypeId}/${entityId}/${datasetDefId}?${stringify(
      {
        parentDatasetInstanceId,
        consolidationId,
      },
    )}`,
    errorMessage: 'Fetching data models data instances failed',
  });

export const fetchInstanceData = ({
  taxYear,
  period,
  jurisdictionId,
  datasetDefId,
  datasetInstanceId,
}) =>
  httpGetAndParse({
    route: `/api/shared/data-models/data/${taxYear}/${period}/${jurisdictionId}/${datasetDefId}/${datasetInstanceId}`,
    errorMessage: 'Fetching data models data failed',
  });

export const fetchInstanceDataPrintService = ({
  taxYear,
  period,
  businessEntityId,
  jurisdictionId,
  filingTypeId,
  datasetDefId,
  datasetInstanceId,
}) =>
  httpGetAndParse({
    route: `/api/shared/data-models/data-items-instances-print-service/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}/${datasetDefId}/${datasetInstanceId}`,
    errorMessage: 'Fetching instance data print service failed',
  });

export const addDatasetInstance = ({
  taxYear,
  period,
  filingTypeId,
  orgId: businessEntityId,
  jurisdictionId,
  datasetDefId,
  dataItemInstances,
}) =>
  httpPost({
    route: `/api/shared/data-models/dataset-instance/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${datasetDefId}/?jurisdictionId=${jurisdictionId}`,
    errorMessage: 'Adding dataset instance failed',
    parseResponseErrorMessage: true,
    body: dataItemInstances,
  });

export const updateInstanceData = ({
  datasetDefId,
  datasetInstanceId,
  dataItemInstances,
  jurisdictionId,
  taxYear,
  period,
  entityId,
  filingTypeId,
}) =>
  httpPut({
    route: `/api/shared/data-models/data/${datasetDefId}/${datasetInstanceId}/?jurisdictionId=${jurisdictionId}&taxYear=${taxYear}&period=${period}&entityId=${entityId}&filingTypeId=${filingTypeId}`,
    errorMessage: 'Updating instance data failed',
    parseResponseErrorMessage: true,
    body: dataItemInstances,
  });

export const deleteDatasetInstance = ({ datasetDefId, datasetInstanceId }) =>
  httpDelete({
    route: `/api/shared/data-models/dataset-instance/${datasetDefId}/${datasetInstanceId}`,
    parseResponseErrorMessage: true,
    errorMessage: 'Deleting instance failed',
  });

export const deleteDatasetDef = ({ datasetDefId, taxYear }) =>
  httpDelete({
    route: `/api/shared/data-models/dataset-definition/${taxYear}/${datasetDefId}`,
    parseResponseErrorMessage: true,
    errorMessage: 'Deleting dataset failed',
  });

export const deleteDataItemInstancesAndDef = ({
  dataItemDefId,
  dataItemDefName,
  datasetDefId,
  taxYear,
}) =>
  httpDelete({
    route: `/api/shared/data-models/${taxYear}/${dataItemDefId}/${dataItemDefName}/${datasetDefId}`,
    parseResponseErrorMessage: true,
    errorMessage: 'Deleting dataset items and related data item instances from the dataset failed',
  });

export const validateInstanceFilteringExpression = ({
  datasetDefId,
  instanceFilteringExpression,
}) =>
  httpPostAndParse({
    route: `/api/shared/data-models/validation/${datasetDefId}`,
    body: {
      instanceFilteringExpression,
    },
    errorMessage: 'Validating instance filtering expression failed',
  });

export const fetchChildDatasets = ({ taxYear, datasetDefId }) =>
  httpGetAndParse({
    route: `/api/shared/data-models/child-datasets/${taxYear}/${datasetDefId}`,
    errorMessage: 'Fetching child datasets data failed',
  });

export const deleteDataModel = ({ taxYear, dataModelId }) =>
  httpDelete({
    route: `/api/shared/data-models/data-model/${taxYear}/${dataModelId}`,
    errorMessage: 'Deleting data model failed',
  });
