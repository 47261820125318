import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { entityIdSelector, periodSelector, taxYearSelector } from '../../../shared/store/selectors';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';

import {
  isFetchingSltAmountDetailsSelector,
  sltAmountDetailsWithTotalSelector,
} from './store/selectors';
import { fetchSltAmountDetails } from './store/actions';
import columnDefinitions from './sltAmountDetails.columnDefinitions';
import { sltAmountDetailsType } from './propTypes';

const SltAmountDetails = ({
  match,
  taxYear,
  period,
  entityId,
  fetchSltAmountDetails,
  sltAmountDetails,
  isFetchingSltAmountDetails,
}) => {
  const { accountGroup } = match.params;

  useEffect(() => {
    const isContextReady = taxYear && period && entityId && accountGroup;

    if (isContextReady) {
      fetchSltAmountDetails({ taxYear, period, entityId, accountGroup });
    }
  }, [taxYear, period, entityId, accountGroup, fetchSltAmountDetails]);

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={sltAmountDetails}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingSltAmountDetails}
        />
      </div>
    </div>
  );
};
SltAmountDetails.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  sltAmountDetails: PropTypes.arrayOf(sltAmountDetailsType).isRequired,
  isFetchingSltAmountDetails: PropTypes.bool.isRequired,
  fetchSltAmountDetails: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountGroup: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    sltAmountDetails: sltAmountDetailsWithTotalSelector(state),
    isFetchingSltAmountDetails: isFetchingSltAmountDetailsSelector(state),
  }),
  {
    fetchSltAmountDetails,
  },
)(SltAmountDetails);
