import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import { fetchEntitiesTypes, updateEntitiesTypes } from './types';

export const fetchEntities = apiAction({
  types: fetchEntitiesTypes,
  apiAction: api.fetchEntities,
});

export const updateEntities = apiAction({
  types: updateEntitiesTypes,
  apiAction: api.updateEntities,
});
