import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';
import { ENTITIES_NON_FILING_STATES_SELECT_JURISDICTION } from '../../../entity/store/types';
import { SELECT_JURISDICTION_ID_FOR_FILING_GROUP } from '../../../stateFilingGroups/store/types';
import { SELECT_JURISDICTION, SELECT_ENTITY, SELECT_TAX_YEAR, SELECT_PERIOD } from '../types';

import {
  fetchFormDataTypes,
  downloadFormPdfTypes,
  RESET_FORM_DATA,
  SELECT_MEMBER_OR_FORM_INSTANCE_ID,
} from './types';

export const initialState = {
  formData: {
    fieldsData: {
      fieldsValues: [],
      dataInstances: [],
      drillLinks: [],
      hasMemberOrPartnerDatasets: false,
      showFormInstanceDataLink: false,
      formWatermark: null,
    },
    form: null,
    pagesNumber: null,
  },
  isFetchingFormData: false,
  memberOrFormInstanceId: null,

  isDownloadingFormPdf: false,
};

const sharedReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFormDataTypes,
      payloadKey: 'formData',
      fetchingKey: 'isFetchingFormData',
      getAdditionalState: (state, payload) => {
        const newMemberOrFormInstanceId = payload.fieldsData.dataInstances.some(
          ({ id }) => id === state.memberOrFormInstanceId,
        )
          ? state.memberOrFormInstanceId
          : payload.fieldsData.dataInstances?.[0]?.id || null;

        return {
          // TODO: For ticket SLT-15283, This condition is trying to fix filcker issue
          // But we need a tech debt for here to move dataInstances to a new api
          // and get it before getting form data, then we should don't need this condition here
          isFetchingFormData: Boolean(
            newMemberOrFormInstanceId && newMemberOrFormInstanceId !== state.memberOrFormInstanceId,
          ),
          memberOrFormInstanceId: newMemberOrFormInstanceId,
        };
      },
    }),

    ...getApiActionTypesHandlers({
      apiTypes: downloadFormPdfTypes,
      fetchingKey: 'isDownloadingFormPdf',
    }),

    [RESET_FORM_DATA]: state => ({
      ...state,
      formData: initialState.formData,
    }),
    [SELECT_MEMBER_OR_FORM_INSTANCE_ID]: (state, payload) => ({
      ...state,
      memberOrFormInstanceId: payload,
    }),
    ...[
      SELECT_TAX_YEAR,
      SELECT_PERIOD,
      SELECT_ENTITY,
      SELECT_JURISDICTION,
      ENTITIES_NON_FILING_STATES_SELECT_JURISDICTION,
      SELECT_JURISDICTION_ID_FOR_FILING_GROUP,
    ].reduce(
      (actionHandlers, action) => ({
        ...actionHandlers,
        [action]: () => ({ ...initialState }),
      }),
      {},
    ),
  },
});

export default sharedReducer;
