import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import { fetchStateAccountNumbersTypes, updateStateAccountNumbersTypes } from './types';

export const fetchStateAccountNumbers = apiAction({
  types: fetchStateAccountNumbersTypes,
  apiAction: api.fetchStateAccountNumbers,
});

export const updateStateAccountNumbers = apiAction(
  {
    types: updateStateAccountNumbersTypes,
    apiAction: api.updateStateAccountNumbers,
  },
  { successNotificationMessage: () => 'State Account Numbers has been successfully updated' },
);
