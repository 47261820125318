import { connect } from 'react-redux';

import BreadcrumbContentRenderer from '../../../shared/displayComponents/breadcrumbContentRenderer/breadcrumbContentRenderer.component';
import {
  accountGroupNameSelector,
  isFetchingFederalReconciliationSelector,
} from '../store/selectors';

export default connect(state => ({
  label: accountGroupNameSelector(state),
  isLoading: isFetchingFederalReconciliationSelector(state),
}))(BreadcrumbContentRenderer);
