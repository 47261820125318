import { createSelector } from 'reselect';
import { DEFAULT_EXCEL_FILE_FORMAT } from '@common-packages/shared-constants';

import { isFetchingTaxYearsDataSelector } from '../../../shared/store/selectors';

const isNullOrUndefined = value => value === null || value === undefined; // eslint-disable-line no-undefined

export const customReportsSelector = ({ admin }) => admin.shared.customReports;

export const isFetchingCustomReportsSelector = ({ admin }) => admin.shared.isFetchingCustomReports;

export const customReportToEditSelector = ({ admin }) => {
  const { customReportToEdit } = admin.shared;
  return (
    customReportToEdit && {
      reportId: customReportToEdit.reportId,
      reportFileFormat:
        customReportToEdit?.reportDefinition.reportFileFormat || DEFAULT_EXCEL_FILE_FORMAT,
      reportType: customReportToEdit.reportType,
      name: customReportToEdit.name,
      query: customReportToEdit.reportDefinition ? customReportToEdit.reportDefinition.sql : '',
      queryParams: customReportToEdit.reportDefinition
        ? customReportToEdit.reportDefinition.queryParams
        : [],
      chart: customReportToEdit.reportDefinition ? customReportToEdit.reportDefinition.chart : [],
      serverSide: customReportToEdit.reportDefinition
        ? customReportToEdit.reportDefinition.serverSide
        : false,
      defaultOrderBy: customReportToEdit.reportDefinition
        ? customReportToEdit.reportDefinition.defaultOrderBy
        : false,
      trgId: customReportToEdit.reportDefinition ? customReportToEdit.reportDefinition.trgId : '',
    }
  );
};

export const isFetchingCustomReportToEditSelector = ({ admin }) =>
  admin.shared.isFetchingCustomReportToEdit;

export const customQueryResultSelector = ({ admin }) => admin.shared.customQueryResult;

export const customQueryResultColumnsSelector = createSelector(
  [customQueryResultSelector],
  customQueryResult => (customQueryResult || { columns: [] }).columns,
);

export const isFetchingCustomQueryResultSelector = ({ admin }) =>
  admin.shared.isFetchingCustomQueryResult;

export const fetchingCustomQueryResultErrorSelector = ({ admin }) =>
  admin.shared.fetchingCustomQueryResultError;

export const customQueryForParamColumnsOptionsSelector = ({ admin }) => {
  const customQueryForParamResult = admin.shared.customQueryForParamResult;
  return customQueryForParamResult
    ? customQueryForParamResult.columns.map(column => ({ value: column, label: column }))
    : [];
};

export const isFetchingCustomQueryForParamResultSelector = ({ admin }) =>
  admin.shared.isFetchingCustomQueryForParamResult;

export const fetchingCustomQueryForParamResultErrorSelector = ({ admin }) =>
  admin.shared.fetchingCustomQueryForParamResultError;

export const paramFieldSelector = ({ admin }, { param }) =>
  admin.shared.paramFields[param.mappingName];

export const paramFieldOptionsSelector = createSelector(
  [paramFieldSelector, (state, { param }) => param],
  (paramField, param) =>
    paramField && paramField.result
      ? paramField.result.rows.map(row => ({
          value: !isNullOrUndefined(row[param.dropdownValue])
            ? row[param.dropdownValue].toString()
            : '',
          label: !isNullOrUndefined(row[param.dropdownLabel])
            ? row[param.dropdownLabel].toString()
            : '',
        }))
      : [],
);

export const isFetchingParamFieldSelector = createSelector([paramFieldSelector], paramField =>
  paramField ? paramField.isFetching : false,
);

export const isFetchingContextSelector = createSelector(
  [isFetchingTaxYearsDataSelector],
  isFetchingTaxYearsData => isFetchingTaxYearsData,
);
