import { createSelector } from 'reselect';
import isNil from 'lodash.isnil';

export const trgReportSelector = ({ reports }) => reports.trg.trgReport;

export const isFetchingTrgReportSelector = ({ reports }) => reports.trg.isFetchingTrgReport;

export const isFetchingTrgReportResultSelector = ({ reports }) =>
  reports.trg.isFetchingTrgReportResult;

export const paramFieldSelector = ({ reports }, { param }) =>
  reports.trg.paramFields[param.mappingName];

export const paramFieldOptionsSelector = createSelector(
  [paramFieldSelector, (state, { param }) => param],
  (paramField, param) =>
    paramField && paramField.result
      ? paramField.result.rows.map(row => ({
          value: !isNil(row[param.dropdownValue]) ? row[param.dropdownValue].toString() : '',
          label: !isNil(row[param.dropdownLabel]) ? row[param.dropdownLabel].toString() : '',
        }))
      : [],
);

export const isFetchingParamFieldSelector = createSelector([paramFieldSelector], paramField =>
  paramField ? paramField.isFetching : false,
);
