import { ColDef } from 'ag-grid-community';

import { GlobalContextData, ValidationErrorsMessage } from '../../../../../../common/types';
import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../../../shared/columnDefinitions';

import ResolveLinkCellRenderer from './resolveLinkCellRenderer';

export const getColumnDefinitions = (
  handleButtonClick: (errorDetails: ValidationErrorsMessage) => void,
  { taxYear, period, businessEntityId, jurisdictionId, filingTypeId }: GlobalContextData,
) => {
  const columnDefinitions: ColDef[] = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Type',
      field: 'type',
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Validation Type',
      field: 'validationType',
      width: 150,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: 'description',
      width: 500,
      autoHeight: true,
      cellStyle: { whiteSpace: 'normal' },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Location',
      field: 'location',
      width: 100,
      hide: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Element',
      field: 'element',
      tooltipField: 'element',
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: '',
      field: 'status',
      width: 120,
      cellRenderer: ResolveLinkCellRenderer,
      cellRendererParams: {
        taxYear,
        period,
        businessEntityId,
        jurisdictionId,
        filingTypeId,
        handleButtonClick,
      },
    },
  ];

  return columnDefinitions;
};
