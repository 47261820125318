import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';

import { GlobalContext } from '../../../../common/types';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useQueryGetFederalReturnsAvailability } from '../../shared/queries/eFileV2';
import { globalContextSelector } from '../../shared/store/selectors';
import styles from '../../shared/reports/reportParam.module.scss';
import { QueryKeys } from '../../shared/queries';

import getColumnDefinitions from './eFileFederalReturnsAvailability.columnDefinitions';
import runButton from './eFileFederalReturnsAvailability.module.scss';

const EFileFederalReturnsAvailability = () => {
  const queryClient = useQueryClient();
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const { taxYear, period } = globalContext;

  const {
    data: federalReturnsAvailability,
    isLoading: isLoadingGridData,
  } = useQueryGetFederalReturnsAvailability({
    params: { taxYear: taxYear || '', period: period || '' },
    enabled: globalContext.isReady,
  });

  const handleRunReportClick = useCallback(() => {
    queryClient.resetQueries([
      QueryKeys.EFileV2.GetFederalReturnsAvailability,
      { taxYear, period },
    ]);
  }, [queryClient, taxYear, period]);

  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);

  return (
    <>
      <div className={runButton.runReportButtonContainer}>
        <Button
          size="lg"
          className={styles.runReportBtn}
          onClick={handleRunReportClick}
          disabled={!globalContext.isReady || isLoadingGridData}
        >
          RUN REPORT
        </Button>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={federalReturnsAvailability}
            columnDefs={columnDefinitions}
            isGridLoading={isLoadingGridData}
            withSearchBar
          />
        </div>
      </div>
    </>
  );
};

export default EFileFederalReturnsAvailability;
