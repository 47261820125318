import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchStateAccountNumbers = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/state-account-numbers/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching State Account Numbers info failed',
  });

export const updateStateAccountNumbers = ({ taxYear, period, entityId, values }) =>
  httpPut({
    route: `/api/entities/state-account-numbers/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Updating State Account Numbers failed',
    body: {
      values,
    },
  });
