import { getApiActionTypes } from '../storeHelpers';

export const fetchDataModelsTypes = getApiActionTypes('SHARED/FETCH_DATA_MODELS');
export const fetchDatasetsTypes = getApiActionTypes('SHARED/FETCH_DATA_SETS');
export const fetchAllDatasetsTypes = getApiActionTypes('SHARED/FETCH_ALL_DATA_SETS');
export const fetchDataItemsTypes = getApiActionTypes('SHARED/FETCH_DATA_ITEMS');
export const fetchDataTypesTypes = getApiActionTypes('SHARED/FETCH_DATA_TYPES');
export const fetchOverflowActionTypesTypes = getApiActionTypes(
  'SHARED/FETCH_OVERFLOW_ACTION_TYPES',
);
export const fetchMappingFormsTypes = getApiActionTypes('SHARED/FETCH_MAPPING_FORMS');
export const fetchWorkpaperFormsTypes = getApiActionTypes('SHARED/FETCH_WORKPAPER_FORMS');
export const fetchMappingFormPDFPageDataUsageAndAttachmentConfigsTypes = getApiActionTypes(
  'SHARED/FETCH_MAPPING_FORM_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIGS',
);
export const RESET_MAPPING_FORM_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIGS =
  'SHARED/RESET_MAPPING_FORM_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIGS';
export const fetchPDFFieldsByPageTypes = getApiActionTypes('SHARED/FETCH_PDF_FIELDS_BY_PAGE');
export const RESET_PDF_FIELDS_BY_PAGE = 'SHARED/RESET_PDF_FIELDS_BY_PAGE';
export const fetchPDFAttachmentColumnPropertiesTypes = getApiActionTypes(
  'TAX_FORMS/FETCH_PDF_ATTACHMENT_COLUMN_PROPERTIES',
);
export const mapPDFFieldTypes = getApiActionTypes('SHARED/MAP_PDF_FIELD');
export const removePDFFieldMapTypes = getApiActionTypes('SHARED/REMOVE_PDF_FIELD_MAP');
export const updateDataModelTypes = getApiActionTypes('SHARED/UPDATE_DATA_MODEL');
export const SELECT_WORKPAPER_FORM = 'SHARED/SELECT_WORKPAPER_FORM';
export const SELECT_MAPPING_FORM_PDF = 'SHARED/SELECT_MAPPING_FORM_PDF';
export const SELECT_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIG =
  'SHARED/SELECT_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIG';
export const SELECT_DATA_INSTANCE = 'SHARED/SELECT_DATA_INSTANCE';
export const SELECT_DATA_ITEM = 'SHARED/SELECT_DATA_ITEM';
export const SELECT_DATA_MODEL = 'SHARED/SELECT_DATA_MODEL';
export const SELECT_DATASET = 'SHARED/SELECT_DATASET';
export const EXPAND_COLLAPSE_HIERARCHY_LIST_ITEM = 'SHARED/EXPAND_COLLAPSE_HIERARCHY_LIST_ITEM';
export const addDataModelTypes = getApiActionTypes('SHARED/ADD_DATA_MODEL');
export const addDatasetTypes = getApiActionTypes('SHARED/ADD_DATASET');
export const assignPDFDataUsageTypes = getApiActionTypes('SHARED/ASSIGN_PDF_DATA_USAGE');
export const unassignPDFDataUsageTypes = getApiActionTypes('SHARED/UNASSIGN_PDF_DATA_USAGE');
export const updatePdfDataUsageAndAttachmentConfigTypes = getApiActionTypes(
  'SHARED/UPDATE_PDF_DATA_USAGE_AND_ATTACHMENT_CONFIG',
);
export const RESET_DATA_INSTANCE = 'SHARED/RESET_DATA_INSTANCE';

export const fetchDatasetInstancesTypes = getApiActionTypes('SHARED/FETCH_DATASET_INSTANCES');
export const SELECT_DATASET_INSTANCE = 'SHARED/SELECT_DATASET_INSTANCE';
export const fetchDataDefinitionsTypes = getApiActionTypes('SHARED/FETCH_DATA_DEFINITIONS');
export const fetchInstanceDataTypes = getApiActionTypes('SHARED/FETCH_INSTANCE_DATA');
export const CLEAR_INSTANCE_DATA_PRINT_SERVICE = 'SHARED/CLEAR_INSTANCE_DATA_PRINT_SERVICE';
export const fetchInstanceDataPrintServiceTypes = getApiActionTypes(
  'SHARED/FETCH_INSTANCE_DATA_PRINT_SERVICE',
);
export const addDatasetInstanceTypes = getApiActionTypes('SHARED/ADD_DATASET_INSTANCE');
export const deleteDatasetInstanceTypes = getApiActionTypes('SHARED/DELETE_DATASET_INSTANCE');
export const deleteDatasetDefTypes = getApiActionTypes('SHARED/DELETE_DATASET_DEF');
export const updateInstanceDataTypes = getApiActionTypes('SHARED/UPDATE_INSTANCE_DATA');
export const CLEAR_DATASET_INSTANCES = 'VIEW_FORMWORKPAPER/CLEAR_DATASET_INSTANCES';
export const CLEAR_INSTANCE_DATA = 'VIEW_FORMWORKPAPER/CLEAN_DATA';
export const deleteDataItemInstancesAndDefTypes = getApiActionTypes(
  'SHARED/DELETE_DATAITEM_INSTANCES_AND_DEF',
);

export const SET_STATE_FROM_EXPRESSION_CALCULATION = 'SHARED/SET_STATE_FROM_EXPRESSION_CALCULATION';
