import {
  httpGetAndParse,
  httpPost,
  httpPut,
  httpPostAndParse,
  httpDelete,
} from '../../utils/fetchService';

export const fetchCustomReports = ({ reportType }) =>
  httpGetAndParse({
    route: `/api/admin/custom-reports/by-report-type/${reportType}`,
    errorMessage: 'Fetching custom reports failed',
  });

export const fetchCustomReport = ({ id }) =>
  httpGetAndParse({
    route: `/api/admin/custom-reports/${id}`,
    errorMessage: 'Fetching custom report failed',
  });

export const addCustomReport = ({
  name,
  trgId,
  reportFileFormat,
  query,
  queryParams,
  reportType,
  chart,
  serverSide,
  defaultOrderBy,
}) =>
  httpPost({
    route: '/api/admin/custom-reports',
    errorMessage: 'Adding custom report failed',
    body: {
      name,
      trgId,
      reportFileFormat,
      query,
      queryParams,
      reportType,
      chart,
      serverSide,
      defaultOrderBy,
    },
  });

export const updateCustomReport = ({
  id,
  trgId,
  reportFileFormat,
  name,
  query,
  queryParams,
  reportType,
  chart,
  serverSide,
  defaultOrderBy,
}) =>
  httpPut({
    route: `/api/admin/custom-reports/${id}`,
    errorMessage: 'Updating custom report failed',
    body: {
      name,
      trgId,
      reportFileFormat,
      query,
      queryParams,
      reportType,
      chart,
      serverSide,
      defaultOrderBy,
    },
  });

export const runCustomQuery = ({ query, serverSide, params }) =>
  httpPostAndParse({
    route: '/api/admin/custom-reports/run',
    errorMessage: 'Running custom query failed',
    body: { query, serverSide, params },
  });

export const deleteCustomReport = ({ id }) =>
  httpDelete({
    route: `/api/admin/custom-reports/${id}`,
    errorMessage: 'Deleting custom report failed',
  });
