import { HttpMethods, FilingTypes } from '../enums';
import { QueryError, QueryKeys } from '../queries';
import { ReturnItemTypes } from '../../returnMaintenance/enums';
import { createQueryString } from '../../utils/routerUtils';

import { useCustomMutation } from '.';

interface GenerateReturnPdfVariable {
  returnId: string;
  shouldDecrypt: boolean;
}

interface DeleteReturnItemsBody {
  binderItemIds: number[];
}

interface UpdateReturnItemsBody {
  businessEntityId: string;
  binderItemIds: string[];
  documentType: ReturnItemTypes;
  documentLevel: FilingTypes;
  areAllEntitiesInConsolidation?: boolean;
}

interface UpdateReturnItemsVariable extends UpdateReturnItemsBody {
  returnId: string;
  apiRouteParamString: string;
}

interface ReturnItem {
  id: number;
  displayOrder: number;
}

interface ReorderReturnItemsBody {
  binderItems: ReturnItem[];
}

export interface RemoveReturnItemsParams {
  onSuccess: () => void;
}

export const useMutationGenerateReturnPdf = () =>
  useCustomMutation<null, QueryError, GenerateReturnPdfVariable>({
    handleData: ({ returnId, shouldDecrypt }) => ({
      url: `/api/binder-maintenance/generate-binder-pdf/${returnId}${createQueryString({
        shouldDecrypt,
      })}`,
      method: HttpMethods.POST,
    }),
    errorMessage: 'Generating return pdf failed',
  });

export const useMutationRemoveReturnItems = ({ onSuccess }: RemoveReturnItemsParams) =>
  useCustomMutation<null, QueryError, DeleteReturnItemsBody>({
    handleData: data => ({
      url: '/api/binder-maintenance',
      body: data,
      method: HttpMethods.DELETE,
    }),
    resetKey: QueryKeys.TaxReturns.ReturnItems,
    successMessage: 'Return items deleted successfully',
    errorMessage: 'An error occurred while deleting the return items',
    onSuccess,
  });

export const useMutationAddReturnItems = () =>
  useCustomMutation<null, QueryError, UpdateReturnItemsVariable, UpdateReturnItemsBody>({
    handleData: ({
      returnId,
      apiRouteParamString,
      businessEntityId,
      documentLevel,
      documentType,
      binderItemIds,
      areAllEntitiesInConsolidation,
    }) => ({
      url: `/api/binder-maintenance/binder-items/${returnId}/${apiRouteParamString}`,
      body: {
        businessEntityId,
        documentLevel,
        documentType,
        binderItemIds,
        areAllEntitiesInConsolidation,
      },
      method: HttpMethods.PUT,
    }),
    resetKey: query =>
      query.queryKey?.[0] === QueryKeys.Binders.BinderData ||
      query.queryKey?.[0] === QueryKeys.TaxReturns.ReturnItems,
    successMessage: 'Return items added successfully',
    errorMessage: 'An error occurred while adding the return items',
  });

export const useMutationReorderReturnItems = () =>
  useCustomMutation<null, QueryError, ReorderReturnItemsBody>({
    handleData: data => ({
      url: '/api/binder-maintenance',
      body: data,
      method: HttpMethods.PUT,
    }),
    errorMessage: 'An error occurred while reordering the return items',
  });
