import { ColDef, NewValueParams } from 'ag-grid-community';

import { defaultColumnDefinitionWithFilter } from '../shared/columnDefinitions';
import { GlobalConsolidationData } from '../../../common/types';
import StateOverrideRendererFramework from '../shared/displayComponents/stateOverrideRenderer/stateOverrideRendererFramework.component';
import ConditionalSelectionListEditorSelectorFactory from '../shared/displayComponents/editorSelector/conditionalSelectionListEditorSelector.factory';
import { IconCellRendererFactory } from '../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({
  isInEditMode,
  updateRow,
}: {
  isInEditMode: boolean;
  updateRow: (data: GlobalConsolidationData) => void;
}): ColDef[] => {
  const onCellValueChanged = ({ data }: NewValueParams) => updateRow(data);

  const columnDefinitions = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Account',
      field: 'infoAccountId',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: 'accountDescription',
      width: 400,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Value',
      field: 'value',
      width: 200,
      editable: isInEditMode,
      cellRenderer: StateOverrideRendererFramework,
      cellEditorSelector: ConditionalSelectionListEditorSelectorFactory(),
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Is State Override',
      field: 'isStateOverride',
      width: 100,
      cellRendererFramework: IconCellRendererFactory({
        getName: isAccountGlobal => (isAccountGlobal ? 'check-mark' : ''),
      }),
      cellStyle: {
        'text-align': 'center',
      },
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
