export const screenTypesOptions = [
  {
    label: 'STD',
    value: 'STD',
  },
];

export const saveButtonOptions = [
  {
    label: 'Yes',
    value: 'Y',
  },
  {
    label: 'No',
    value: 'N',
  },
];
