export const createDatasetBreadcrumbs = (currentDataset, datasetsArray, breadcrumbs = []) => {
  if (!currentDataset || !datasetsArray?.length) {
    return [];
  }

  const currentBreadcrumbs = [currentDataset.name, ...breadcrumbs];
  const parentDataset = datasetsArray.find(
    item => item.treeHierarchyId === currentDataset.treeHierarchyParentId,
  );

  return parentDataset
    ? createDatasetBreadcrumbs(parentDataset, datasetsArray, currentBreadcrumbs)
    : currentBreadcrumbs;
};
