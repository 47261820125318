import React from 'react';
import PropTypes from 'prop-types';

import { TextCellRendererFactory } from '../../columnDefinitions/cellRenderers';
import { dataTypes } from '../../constants';
import { SELECT_OPTIONS } from '../constants';
import { selectionListItemPropTypes } from '../../../development/selectionLists/selectionListsPropTypes';

const TextRenderer = TextCellRendererFactory({
  getText: ({ value }) => (value ? value : ''),
});

const StateOverrideRendererFramework = props => {
  if (props.data?.selectionListId) {
    const itemLabel = props.data.selectionListItems?.find(item => item.value === props.value);
    return <TextRenderer {...props} value={itemLabel ? itemLabel.label : ''} />;
  }

  if (props.data?.dataType === dataTypes.CHECKBOX) {
    const selectValue = SELECT_OPTIONS.find(({ value }) => value === props.data.value);
    return <TextRenderer {...props} value={selectValue ? selectValue.label : ''} />;
  }

  return <TextRenderer {...props} />;
};

StateOverrideRendererFramework.propTypes = {
  data: PropTypes.shape({
    dataType: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectionListId: PropTypes.string,
    selectionListItems: PropTypes.arrayOf(selectionListItemPropTypes),
  }).isRequired,
  value: PropTypes.string.isRequired,
};

export default StateOverrideRendererFramework;
