import {
  GlobalContext,
  EFileSupportReturnOptionsResponse,
  BinderFilingId,
  EFileSupportBinderStepsResponse,
  EFileSupportTransmitterSetupResponse,
  EFileSupportXmlMappingElementsResponse,
  TaxYear,
  Period,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export const useQueryReturnOptions = ({
  params,
  enabled,
  onSuccess,
}: {
  params: {
    apiRouteParamString: GlobalContext['apiRouteParamString'];
  };
  enabled: boolean;
  onSuccess?: (data: EFileSupportReturnOptionsResponse) => void;
}) =>
  useCustomQuery<EFileSupportReturnOptionsResponse>(
    [QueryKeys.EFileSupport.ReturnOptions, params],
    {
      url: `/api/admin/support/efile/return-options/${params.apiRouteParamString}`,
      enabled,
      defaultData: [],
      errorMessage: 'Fetching return options failed',
      onSuccess,
    },
  );

export const useQueryBinderSteps = ({
  params,
  enabled,
}: {
  params: {
    binderFilingId: BinderFilingId | null;
  };
  enabled: boolean;
}) =>
  useCustomQuery<EFileSupportBinderStepsResponse>([QueryKeys.EFileSupport.BinderSteps, params], {
    url: `/api/admin/support/efile/binder-steps/${params.binderFilingId}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching binder steps data failed',
  });

export const useQueryTransmitterSetup = ({
  params,
  enabled,
}: {
  params: {
    taxYear: TaxYear;
    period: Period;
  };
  enabled: boolean;
}) =>
  useCustomQuery<EFileSupportTransmitterSetupResponse>(
    [QueryKeys.EFileSupport.TransmitterSetup, params],
    {
      url: `/api/admin/support/efile/transmitter-setup/${params.taxYear}/${params.period}`,
      enabled,
      defaultData: [],
      errorMessage: 'Fetching transmitter setup data failed',
    },
  );

export const useQueryXmlMappingElements = ({
  params,
  enabled,
}: {
  params: {
    taxYear: TaxYear;
  };
  enabled: boolean;
}) =>
  useCustomQuery<EFileSupportXmlMappingElementsResponse>(
    [QueryKeys.EFileSupport.XmlMappingElements, params],
    {
      url: `/api/admin/support/efile/xml-mapping-elements/${params.taxYear}`,
      enabled,
      defaultData: [],
      errorMessage: 'Fetching XML mapping elements failed',
    },
  );
