import { apiAction } from '../apiAction';

import {
  insertInformationDataTypes,
  fetchFormListTypes,
  fetchFormFieldsTypes,
  updateFormFieldsTypes,
} from './types';
import * as api from './api';

export const insertInformationData = apiAction({
  types: insertInformationDataTypes,
  apiAction: api.insertInformationalData,
});

export const fetchFormList = apiAction({
  types: fetchFormListTypes,
  apiAction: api.fetchFormList,
});

export const fetchFormFields = apiAction({
  types: fetchFormFieldsTypes,
  apiAction: api.fetchFormFields,
});

export const updateFormFields = apiAction({
  types: updateFormFieldsTypes,
  apiAction: api.updateFormFields,
});
