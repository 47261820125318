import React from 'react';
import { Input } from '@pwc/appkit-react';

import { FederalReturnOption } from '../../../shared/enums/eFileV2';
import SharedHeader from '../../../shared/displayComponents/header/header.component';

import BackToReturn from './backToReturn';
import styles from './header.module.scss';

interface HeaderParams {
  stateSubmissionId: string | null;
  federalSubmissionId: string | null;
  federalReturnOption: FederalReturnOption | null;
  isWizardMachineReady: boolean;
  returnId?: string;
}

const getFederalSubmissionText = (
  federalSubmissionId: string | null,
  federalReturnOption: FederalReturnOption | null,
  isWizardMachineReady: boolean,
) => {
  if (!isWizardMachineReady) {
    return '';
  }

  if (federalReturnOption === FederalReturnOption.FED_NA) {
    return 'Not Applicable';
  }
  if (federalReturnOption === FederalReturnOption.FED_PDF) {
    return 'PDF Selected';
  }
  if (federalReturnOption === FederalReturnOption.FED_MANUAL_PROFORMA) {
    return federalSubmissionId || '';
  }

  return federalSubmissionId || 'Awaiting Federal';
};

const Header = ({
  stateSubmissionId,
  federalSubmissionId,
  federalReturnOption,
  isWizardMachineReady,
  returnId,
}: HeaderParams) => (
  <SharedHeader title="E-File" preHeaderContent={returnId && <BackToReturn returnId={returnId} />}>
    <div className={styles.subheader}>
      <span>STATE SUBMISSION ID</span>
      <Input
        className={styles.subheaderInputStateSubmissionId}
        disabled
        value={stateSubmissionId || ''}
      />

      <span>FEDERAL SUBMISSION ID</span>
      <Input
        className={styles.subheaderInput}
        disabled
        value={getFederalSubmissionText(
          federalSubmissionId,
          federalReturnOption,
          isWizardMachineReady,
        )}
      />
    </div>
  </SharedHeader>
);

export default Header;
