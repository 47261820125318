import { filingTypes } from '@tls/state-helpers';

export const CONSTANT_TYPE_DROPDOWN_OPTIONS = [
  {
    label: 'Separate',
    value: filingTypes.SEPARATE,
  },
  {
    label: 'Consolidated',
    value: filingTypes.CONSOLIDATED,
  },
];

export const PERIOD_VALUE_FOR_SEPARATE = 0;
