export const getCurrentValue = ({
  isOverridden,
  printSuppress,
  overrideValue,
  formattedData,
  defaultValue,
}) => {
  if (isOverridden) {
    return overrideValue;
  }
  if (printSuppress) {
    return formattedData;
  }
  return defaultValue;
};
