import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { dateFormatter } from '../../shared/formatters';
import config from '../../config';
import { partnershipSourceMap } from '../../shared/columnDefinitions/constants';

export const refreshStatusSourceMap = new Map([
  [0, 'Not Started'],
  [1, 'In Progress'],
  [2, 'Complete'],
  [3, 'Failed'],
  [4, 'Cancelled'],
  [5, 'Source Missing Data'],
]);

const getPartnershipSourceLabel = params =>
  partnershipSourceMap.get(params.data.partnershipSource) || null;

const getRefreshStatusSourceLabel = params =>
  refreshStatusSourceMap.get(params.data.gtwRefreshStatus) || null;

export const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: params => {
      const isNotAutoRefreshAndNotInProgress =
        (params.data.autoFedRefresh === null || !params.data.autoFedRefresh) &&
        refreshStatusSourceMap.get(params.data.gtwRefreshStatus) !== 'In Progress';
      const isAutoRefreshAndFailed =
        params.data.autoFedRefresh &&
        refreshStatusSourceMap.get(params.data.gtwRefreshStatus) === 'Failed';
      const hasNewInfoFromGTW =
        params.data.sltUpdatedOn === null || params.data.gtwUpdatedDate > params.data.sltUpdatedOn;
      const hasRetriesLeft =
        params.data.gtwRefreshFailCtr === null || params.data.gtwRefreshFailCtr < 3;

      const isEnabled =
        (isNotAutoRefreshAndNotInProgress || isAutoRefreshAndFailed) &&
        hasNewInfoFromGTW &&
        hasRetriesLeft;
      // Update selectable to stop any type of selection if false
      params.node.selectable = isEnabled;
      // Return result hides or displays the checkbox
      return isEnabled;
    },
    headerName: 'Entity ID',
    field: 'orgId',
    width: 125,
    suppressMenu: false,
    filter: true,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Name',
    field: 'orgName',
    width: 350,
    suppressMenu: false,
    filter: true,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Partnership Source',
    field: 'partnershipSource',
    width: 80,
    valueGetter: getPartnershipSourceLabel,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Updated On',
    field: 'sltUpdatedOn',
    width: 130,
    suppressMenu: false,
    filter: true,
    valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Source Update On',
    field: 'gtwUpdatedDate',
    width: 130,
    suppressMenu: false,
    filter: true,
    valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Refresh Status',
    field: 'gtwRefreshStatus',
    width: 130,
    suppressMenu: false,
    filter: true,
    valueGetter: getRefreshStatusSourceLabel,
  },
];
