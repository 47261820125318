import React, { useCallback } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import FilingAttributes from '../../shared/forms/filingAttributes/filingAttributes.component';
import Loading from '../../shared/displayComponents/loading.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Dropzone from '../../shared/forms/dropzoneFormik/dropzoneFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import formStyles from '../../shared/forms/forms.module.scss';

import styles from './taxFormForm.module.scss';
import { filingAttributesProps } from './propTypes';

const DOCUMENT_ACCEPTED_TYPES = ['.pdf'];

const TaxFormForm = ({
  isEditing,
  setFieldValue,
  isSubmitting,
  onCancelClick,
  submitForm,
  values,
  filingAttributes,
  isAddingOrEditingTaxForm,
}) => {
  const populateRelatedDropzoneField = relatedFieldName => file => {
    setFieldValue(relatedFieldName, file.name);
  };

  const renderFilingAttributes = useCallback(
    () =>
      FilingAttributes({
        filingAttributes,
        isAddingOrEditingTaxForm,
      }),
    [filingAttributes, isAddingOrEditingTaxForm],
  );

  return (
    <Loading isLoading={isAddingOrEditingTaxForm}>
      <form>
        <div className="row">
          <Field
            className={`col form-text ${formStyles.fieldWithInlineLabel}`}
            label="Form Name"
            name="formName"
            component={Input}
            autoComplete="off"
          />
          <Field
            className={`col form-text ${formStyles.fieldWithInlineLabel}`}
            label="SWP Form Id"
            name="swpFormId"
            component={Input}
            autoComplete="off"
          />
        </div>
        <div className="row">
          <Field
            className={`col form-text ${formStyles.fieldWithInlineLabel}`}
            label="Form Description"
            name="formDescription"
            component={Input}
            autoComplete="off"
          />
        </div>
        <div className="row">
          <div className="col">{renderFilingAttributes()}</div>
        </div>
        <div className="row">
          <Field
            className={`col form-text ${formStyles.fieldWithInlineLabel}`}
            label="PDF Lib File Name"
            name="pdfLibPdfFileName"
            component={Input}
            autoComplete="off"
            disabled={isEditing}
          />
        </div>
        <Dropzone
          className={`${formStyles.dropdownWithInlineLabel}`}
          name="pdfLibPdfFile"
          onDropCallback={populateRelatedDropzoneField('pdfLibPdfFileName')}
          value={values.pdfLibPdfFile}
          label="PDFLib PDF"
          acceptTypes={DOCUMENT_ACCEPTED_TYPES}
          existingFileName={values.existingPdfLibPdfName}
        />
        <div className="row">
          <Field
            className={`col form-text ${formStyles.fieldWithInlineLabel}`}
            label="SWP Calc Iterations"
            name="swpCalcIteration"
            component={Input}
            autoComplete="off"
            type="number"
          />
        </div>
        <div className={styles.buttonsRow}>
          <div>
            <ButtonsGroup
              fullWidth={false}
              submitButtonLabel="Save"
              isSubmitting={isSubmitting}
              onCancelClick={onCancelClick}
              submitForm={submitForm}
            />
          </div>
        </div>
      </form>
    </Loading>
  );
};

const formValuesPropTypes = PropTypes.shape({
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,
  formDescription: PropTypes.string.isRequired,
  pdfLibPdf: PropTypes.string,
  bnaPdf: PropTypes.instanceOf(File),
  swpFormId: PropTypes.string.isRequired,
  swpCalcIteration: PropTypes.string.isRequired,
  bnaPdfFileName: PropTypes.string,
  pdfLibPdfFileName: PropTypes.string,
  existingPdfLibPdfName: PropTypes.string,
  FILING_TYPE_CONS_PRE_APP: PropTypes.bool,
  RETURN_TYPE_1065: PropTypes.bool,
  pdfLibPdfFile: PropTypes.instanceOf(File),
});

TaxFormForm.propTypes = {
  values: formValuesPropTypes.isRequired,
  isEditing: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  filingAttributes: filingAttributesProps.isRequired,
  isAddingOrEditingTaxForm: PropTypes.bool.isRequired,
};

export default TaxFormForm;
