import { SelectOption } from '@tls/slt-types';

import { httpPostAndParse } from '../../utils/fetchService';
import stringify from '../../utils/stringify';
import { ConsolidationId, ReturnId } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

interface FindFilingGroupsParams {
  taxYear: string;
  period: string;
  filingTypeId?: string;
  businessEntityId?: string;
  jurisdictionId?: string;
}

interface FindFilingGroupMembersParams extends FindFilingGroupsParams {
  filingGroupId: string;
}

export interface FilingGroup {
  filingGroupId: string;
  description: string;
  consolidationId: ConsolidationId;
}

interface FindFilingGroupReturnOptionsParams extends FindFilingGroupsParams {
  filingGroupId: string;
}

interface FilingGroupMember {
  businessDescription: string;
  countryOfInc: string;
  dateAcquired: string;
  dateIncorporated: string;
  entityId: string;
  entityName: string;
  organizationalType: string;
  fedFilingtype: string;
  fein: string;
  filingGroupId: string;
  includedFlag: boolean;
}

interface FilingGroupOverlappingEntitiesParams {
  taxYear: string;
  period: string;
  checkedFilingGroupIds: string[];
}

interface FindConsolidationByGroupIdParams extends FindFilingGroupsParams {
  filingGroupId: string;
}

interface ConsolidationsByGroupIdResponse {
  result: string[];
  consolidationIds: string[];
}

type FindConsolidatedGroupReturnsParams = {
  consolidationId: ConsolidationId;
  filingGroupId: string;
  taxYear: string;
  period: string;
  jurisdictionId?: string;
};

export type ConsolidatedGroupReturn = {
  returnId: ReturnId;
  jurisdictionName: string;
  entityId: string;
  entityName: string;
  filingForm: string;
  filingMethod: number;
  returnDefIsEfileAllowed: boolean;
};

export const useQueryFilingGroups = ({
  params,
  enabled,
}: {
  params: FindFilingGroupsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FilingGroup[]>([QueryKeys.FilingGroups.FilingGroups, params], {
    url: `/api/filing-groups/${params.taxYear}/${params.period}?${stringify({
      filingTypeId: params.filingTypeId,
      businessEntityId: params.businessEntityId,
      jurisdictionId: params.jurisdictionId,
    })}`,
    enabled,
    errorMessage: 'Fetching filing groups failed',
  });

export const useQueryFilingMembers = ({
  params,
  enabled,
}: {
  params: FindFilingGroupMembersParams;
  enabled: boolean;
}) =>
  useCustomQuery<FilingGroupMember[]>([QueryKeys.FilingGroups.FilingMembers, params], {
    url: `/api/filing-groups/filing-members/${params.taxYear}/${params.period}/${params.filingGroupId}`,
    enabled,
    errorMessage: 'Fetching filing members for filing group failed',
  });

export const useQueryFilingGroupReturnOptions = ({
  params,
  enabled,
}: {
  params: FindFilingGroupReturnOptionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<SelectOption[]>([QueryKeys.FilingGroups.ReturnOptions, params], {
    url: `/api/filing-groups/federal-file-return-options/${params.taxYear}/${params.period}/${params.filingGroupId}`,
    enabled,
    errorMessage: 'Fetching return options for filing group failed',
  });

const fetchFilingGroupOverlappingEntities = (params: FilingGroupOverlappingEntitiesParams) =>
  httpPostAndParse({
    route: `/api/filing-groups/overlapping-entities/${params.taxYear}/${params.period}`,
    body: { filingGroupIds: params.checkedFilingGroupIds },
  });

export const useQueryFilingGroupOverlappingEntities = ({
  params,
  enabled,
}: {
  params: FilingGroupOverlappingEntitiesParams;
  enabled: boolean;
}) =>
  useCustomQuery<string[]>([QueryKeys.FilingGroups.OverlappingEntities, params], {
    enabled,
    errorMessage: 'An error occurred while fetching filing group entities',
    defaultData: [],
    customFetch: () => fetchFilingGroupOverlappingEntities(params),
  });

export const useQueryConsolidationsByGroupId = ({
  params,
  enabled,
}: {
  params: FindConsolidationByGroupIdParams;
  enabled: boolean;
}) =>
  useCustomQuery<ConsolidationsByGroupIdResponse>(
    [QueryKeys.FilingGroups.ConsolidationsByGroupId, params],
    {
      url: `/api/filing-groups/consolidations-by-group-id/${params.taxYear}/${params.period}/${params.filingGroupId}`,
      enabled,
      defaultData: { result: [], consolidationIds: [] },
      errorMessage: 'An error occurred while fetching consolidations by group id',
    },
  );

export const useQueryConsolidatedGroupReturns = ({
  params,
  enabled,
}: {
  params: FindConsolidatedGroupReturnsParams;
  enabled: boolean;
}) =>
  useCustomQuery<ConsolidatedGroupReturn[]>(
    [QueryKeys.FilingGroups.ConsolidatedGroupReturns, params],
    {
      url: `/api/consolidations/filing-groups/consolidated-group-returns/${params.consolidationId}/${params.filingGroupId}/${params.taxYear}/${params.period}/${params.jurisdictionId}`,
      enabled,
      cacheTime: 0,
      errorMessage: 'Fetching consolidated group returns failed',
    },
  );
