import PropTypes from 'prop-types';

export const binderItemPropTypes = PropTypes.shape({
  rowId: PropTypes.number.isRequired,
  displayOrder: PropTypes.number.isRequired,
  itemType: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
});
