import PropTypes from 'prop-types';

export const eFileStepDataPropTypes = PropTypes.shape({
  status: PropTypes.string,
  stepName: PropTypes.string.isRequired,
  ttiResponse: PropTypes.shape({
    action: PropTypes.shape({
      fileGuid: PropTypes.string,
      operationId: PropTypes.number,
    }),
    validation: PropTypes.shape({
      status: PropTypes.object,
      result: PropTypes.object,
    }),
  }),
  efSubmissionId: PropTypes.string,
  ttiCaseId: PropTypes.string,
  ttiYearId: PropTypes.string,
  createdOn: PropTypes.string.isRequired,
  updatedOn: PropTypes.string,
});
