import PropTypes from 'prop-types';

export const dataItemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    displayName: PropTypes.string,
    dataType: PropTypes.string,
    dataLength: PropTypes.number,
    calculationId: PropTypes.number,
    displayOrder: PropTypes.number,
    gfunctions: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
).isRequired;
