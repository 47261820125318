import { httpPost } from '../../../utils/fetchService';

export const refreshFederalProFormaData = ({ taxYear, period, orgs, jurisdictionId }) =>
  httpPost({
    route: '/api/federal-proforma/refresh',
    errorMessage: 'Refresh Federal Data failed',
    body: {
      taxYear,
      orgs,
      period,
      jurisdictionId,
    },
  });

export const refreshGtwData = globalContext =>
  httpPost({
    route: `/api/federal-proforma/refresh-gtw-data/${globalContext.apiRouteParamString}`,
    errorMessage: 'Refreshing GTW data failed',
    parseResponseErrorMessage: true,
  });
