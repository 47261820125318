const getCorrectValueIfStringNullOrStringUndefined = (value: string) =>
  value === 'null' || value === 'undefined' ? null : value;

const swapArrayItems = (items: unknown[], firstIndex: number, secondIndex: number) => {
  const indexes = [firstIndex, secondIndex];

  const anyIndexMissing = indexes.some(
    index => index === undefined || index === null, // eslint-disable-line no-undefined
  );
  const anyIndexOutOfRange = indexes.some(index => index < 0 || index >= items.length);

  if (anyIndexMissing || anyIndexOutOfRange) {
    return items;
  }

  const results = items.slice();
  const firstItem = items[firstIndex];
  results[firstIndex] = items[secondIndex];
  results[secondIndex] = firstItem;

  return results;
};

const filingTypes = {
  SEPARATE: 'SEPARATE',
  CONSOLIDATED: 'CONSOLIDATED',
};

enum StateSubmissionType {
  LINKED = 'Linked',
  UNLINKED = 'UnLinked',
}

interface GlobalContextParams {
  taxYear: string | null;
  period: string | null;
  filingTypeId: string | null;
  businessEntityId: string | null;
  jurisdictionId: string | null;
  entityId?: string | null;
  consolidationId?: string | null;
  entityBinderId?: string | null;
}

type ContextData = {
  taxYear: string | null;
  period: string | null;
  filingTypeId: string | null;
  businessEntityId: string | null;
  consolidationId?: string | null;
  entityId?: string | null;
  jurisdictionId: string | null;
  isSeparateYN?: string;
  globalContext?: GlobalContextParams;
  submissionId?: string;
  caseId?: string;
  yearOrSubPeriodId?: string;
  binderId?: number;
  stateSubmissionType?: StateSubmissionType;
  sltBinderId?: number | null;
  entityBinderId?: string | null;
  ttiCaseId?: string | null;
  ttiBinderId?: number | null;
  binderFilingId?: number | null;
};

const globalContextFactory = (contextData: ContextData) => {
  const taxYear = contextData.taxYear
    ? getCorrectValueIfStringNullOrStringUndefined(contextData.taxYear)
    : null;
  const period = contextData.period
    ? getCorrectValueIfStringNullOrStringUndefined(contextData.period)
    : null;
  const filingTypeId = contextData.filingTypeId
    ? getCorrectValueIfStringNullOrStringUndefined(contextData.filingTypeId)
    : null;
  const businessEntityId = contextData.businessEntityId
    ? getCorrectValueIfStringNullOrStringUndefined(contextData.businessEntityId)
    : null;
  const consolidationId = contextData.consolidationId
    ? getCorrectValueIfStringNullOrStringUndefined(contextData.consolidationId)
    : null;
  const entityId = contextData.entityId
    ? getCorrectValueIfStringNullOrStringUndefined(contextData.entityId)
    : null;
  const jurisdictionId = contextData.jurisdictionId
    ? getCorrectValueIfStringNullOrStringUndefined(contextData.jurisdictionId)
    : null;

  // ok, this context can already be "filtered" as in only orgId is present
  // (which will most likely happen on backend)
  // or not - either entityId or consolidationId is present
  // and entity seems like a better name for "either org or consolidation"
  // for now let's call it businessEntity
  if (businessEntityId && (consolidationId || entityId)) {
    throw new Error(
      'DONT MIX businessEntityId and entityId/consolidationId. These are two different concepts.',
    );
  }

  const isConsolidated = filingTypeId === filingTypes.CONSOLIDATED;
  const isSeparate = filingTypeId === filingTypes.SEPARATE;

  const isConsolidatedYN = isConsolidated ? 'Y' : 'N';
  const isSeparateYN = isSeparate ? 'Y' : 'N';

  const params = {
    taxYear,
    period,
    filingTypeId,
    businessEntityId: isConsolidated
      ? businessEntityId || consolidationId
      : businessEntityId || entityId,
    jurisdictionId,
  };

  const getOverridenApiRouteParamString = ({
    taxYear = params.taxYear,
    period = params.period,
    filingTypeId = params.filingTypeId,
    businessEntityId = params.businessEntityId,
    jurisdictionId = params.jurisdictionId,
  } = {}) => `${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}`;
  const apiRouteParamString = getOverridenApiRouteParamString();

  const isReady = Boolean(
    params.taxYear &&
      params.period &&
      params.filingTypeId &&
      params.businessEntityId &&
      params.jurisdictionId,
  );

  return {
    ...contextData,
    params,
    getOverridenApiRouteParamString,
    apiRouteParamString,
    isConsolidated,
    isConsolidatedYN,
    isSeparate,
    isSeparateYN,
    isReady,
  };
};

const GLOBAL_CONTEXT_ROUTE_PARAMS =
  ':taxYear/:period/:filingTypeId/:businessEntityId/:jurisdictionId';

const CalcStatuses = {
  DIRTY: 1,
  IN_PROGRESS: 2,
  CLEAN: 3,
};

const mapPeriods = (period: string | number) => {
  const periodsObj = {
    0: 'PERIOD_TYPE_ANNUAL',
    1: 'PERIOD_TYPE_ESTIMATE1',
    2: 'PERIOD_TYPE_ESTIMATE2',
    3: 'PERIOD_TYPE_ESTIMATE3',
    4: 'PERIOD_TYPE_ESTIMATE4',
    5: 'PERIOD_TYPE_EXTENSION',
  };

  return periodsObj[period as keyof typeof periodsObj];
};

export {
  swapArrayItems,
  globalContextFactory,
  filingTypes,
  GLOBAL_CONTEXT_ROUTE_PARAMS,
  CalcStatuses,
  mapPeriods,
};
