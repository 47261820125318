const Yup = require('yup');

const editWatermarkSchema = Yup.object().shape({
  name: Yup.string().label('Watermark Name').max(50).required(),
  rotation: Yup.number().label('Rotation').min(0).max(90).required(),
  fontSize: Yup.number().label('Font Size').min(20).max(100).required(),
  verticalOffset: Yup.number().label('Vertical Offset').min(-375).max(375).required(),
  line1: Yup.string().label('Text Line 1').max(25).required(),
  line2: Yup.string().label('Text Line 2').max(25).nullable(),
  line3: Yup.string().label('Text Line 3').max(25).nullable(),
});

const getAddWatermarkSchema = watermarksNames =>
  editWatermarkSchema.concat(
    Yup.object().shape({
      name: Yup.string().label('Watermark Name').max(50).required().uniqueInList({
        message: 'Watermark name must be unique',
        list: watermarksNames,
      }),
    }),
  );

module.exports = { editWatermarkSchema, getAddWatermarkSchema };
