import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import { BreadcrumbRoute } from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const EFileBreadcrumbRoutes = () => (
  <>
    <BreadcrumbRoute path={Routes.currentReturnFiling} />
    <BreadcrumbRoute path={Routes.eFileSetup} />
    <BreadcrumbRoute path={Routes.eFileErrors} />
  </>
);

export default EFileBreadcrumbRoutes;
