import { DataItemsTypes } from '../../../../../shared/constants';
import { createDatasetBreadcrumbs } from '../../../utils/createDatasetBreadcrumbs';

export const getSuppressFieldOptions = ({ suppressFieldDatasets, dataItems }) => {
  const suppressFieldsData = suppressFieldDatasets.map(dataset => ({
    ...dataset,
    dataItemsOptions: [
      ...(dataItems[dataset.id]
        ?.filter(dataItem => dataItem.dataTypeName === DataItemsTypes.BOOLEAN)
        ?.map(({ id, name }) => ({
          label: name,
          value: id,
        })) || []),
    ],
  }));

  const suppressFieldDatasetOptions = suppressFieldsData.map(dataset => ({
    value: dataset.id,
    label: createDatasetBreadcrumbs(dataset, suppressFieldDatasets).join(' > '),
  }));

  const suppressFieldDataItemOptions = suppressFieldsData.reduce(
    (acc, { id, dataItemsOptions }) => ({ ...acc, [id]: dataItemsOptions }),
    {},
  );

  return {
    suppressFieldDatasetOptions,
    suppressFieldDataItemOptions,
  };
};
