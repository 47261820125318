import { DOMAIN_TYPE } from '@common-packages/shared-constants';

import {
  httpGetAndParse,
  httpPostAndParse,
  httpPostFileAndParse,
  httpPost,
} from '../../utils/fetchService';
import { EFILE_SETUP_STEP_NAMES } from '../eFileSetup/constants';
import { LOAD_AS_FILED_JOB_TYPE, PARSE_TTI_FEDERAL_XML } from '../constants';

export const fetchEFileStepsData = ({
  taxYear,
  period,
  filingTypeId,
  businessEntityId,
  jurisdictionId,
  binderFilingId,
  sltBinderId,
}) =>
  httpGetAndParse({
    route: `/api/efile/steps/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}/${binderFilingId}/${sltBinderId}`,
    errorMessage: 'Fetching eFile steps data failed',
  });

export const fetchStepLogsApiAction = ({ stepId }) =>
  httpGetAndParse({
    route: `/api/efile/step-logs/${stepId}`,
    errorMessage: 'Fetching eFile steps logs failed',
  });

export const invokeEFileSetupStepAction = ({
  globalContext,
  action,
  ttiCaseId,
  ttiYearId,
  additionalParams,
}) =>
  httpPost({
    route: `/api/efile/${action}`,
    errorMessage: `Processing '${EFILE_SETUP_STEP_NAMES[action]}' failed`,
    body: {
      caseId: ttiCaseId,
      yearOrSubPeriodId: ttiYearId,
      domainType: DOMAIN_TYPE,
      globalContext: JSON.stringify(globalContext.params),
      ...additionalParams,
    },
  });

export const fetchFederalAttachmentOptions = ({ globalContext }) =>
  httpGetAndParse({
    route: `/api/efile/federal-attachment-options/${globalContext.apiRouteParamString}`,
    errorMessage: 'Fetching federal attachment options failed',
  });

export const runTTIStep = ({
  globalContext,
  binderFilingId,
  jurisdictionStateCode,
  action,
  submissionId,
  ttiCaseId,
  ttiYearId,
  binderId,
  stateSubmissionType,
  signatureValue,
  acceptedLegalVerbiage,
  ignoreErrors,
  includeFederal,
  federalProformaSubmissionId,
  sltBinderId,
}) =>
  httpPost({
    route: `/api/efile/${action}`,
    errorMessage: `Processing ${action} failed`,
    parseResponseErrorMessage: true,
    body: {
      submissionId,
      jurisdictionStateCode,
      caseId: ttiCaseId,
      yearOrSubPeriodId: ttiYearId,
      globalContext: globalContext.params,
      binderId,
      binderFilingId,
      stateSubmissionType,
      signatureValue,
      acceptedLegalVerbiage,
      ignoreErrors,
      includeFederal,
      federalProformaSubmissionId,
      sltBinderId,
    },
  });

export const runEFStep = ({ globalContext, sltBinderId, ttiBinderId, binderFilingId }) => {
  const { taxYear, period, filingTypeId, businessEntityId, jurisdictionId } = globalContext.params;

  return httpPostAndParse({
    route: `/api/efile/ef-service/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}/${sltBinderId}/${ttiBinderId}/${binderFilingId}`,
    errorMessage: 'Processing ef-service failed',
  });
};

export const fetchValidationMessages = ({ ttiYearId, ttiCaseId, binderId, submissionId }) =>
  httpPostAndParse({
    route: `/api/efile/get-efile-validation-messages`,
    errorMessage: 'Fetching validation messages failed',
    body: {
      ttiYearId,
      ttiCaseId,
      binderId,
      submissionId,
    },
  });

export const fetchSignatureDeclaration = ({ yearOrSubPeriodId, caseId, binderId, submissionId }) =>
  httpPostAndParse({
    route: `/api/efile/get-signature-declaration`,
    errorMessage: 'Fetching signature declaration failed',
    body: {
      yearOrSubPeriodId,
      caseId,
      binderId,
      submissionId,
    },
  });

export const runCheckTransmissionStatusApiAction = ({
  action,
  globalContext,
  ttiYearId,
  ttiCaseId,
  binderId,
  submissionId,
  binderFilingId,
  sltBiderId,
}) =>
  httpPost({
    route: `/api/efile/${action}/${globalContext.apiRouteParamString}/${ttiYearId}/${ttiCaseId}/${binderId}/${submissionId}/${binderFilingId}/${sltBiderId}`,
    errorMessage: `Processing ${action} failed`,
  });

export const fetchFederalProformaProcesses = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/efile/federal-proforma-processes/${taxYear}/${period}`,
    errorMessage: 'Fetching processes failed',
  });

export const loadFederalAsFiledData = ({ taxYear, period, clientId }) => {
  const body = new FormData();
  body.append('taxYear', taxYear);
  body.append('period', period);
  body.append('clientId', clientId);

  return httpPostFileAndParse({
    route: `/api/jim/${LOAD_AS_FILED_JOB_TYPE}`,
    errorMessage: 'Could not start As-Filed job',
    body,
  });
};

export const processProformaXML = ({ taxYear, period, clientId, proformaFilingGroupId }) => {
  const body = new FormData();
  body.append('taxYear', taxYear);
  body.append('period', period);
  body.append('clientId', clientId);
  body.append('proformaFilingGroupId', proformaFilingGroupId);

  return httpPostFileAndParse({
    route: `/api/jim/${PARSE_TTI_FEDERAL_XML}`,
    errorMessage: 'Could not start parse tti federal xml job',
    body,
  });
};

export const generatePDF = ({ fileId }) =>
  httpPost({
    route: `/api/efile/generate-pdf/${fileId}`,
    errorMessage: 'Generating PDF failed',
  });

export const generateExternalFederalTransmission = ({ fileId }) =>
  httpPost({
    route: `/api/efile/external-federal-transmission/${fileId}`,
    errorMessage: 'External federal transmission generation failed',
    parseResponseErrorMessage: true,
  });

export const fetchFederalProformaSubmissionId = ({ taxYear, period, proformaFilingGroupId }) =>
  httpGetAndParse({
    route: `/api/efile/federal-proforma-submission-id/${taxYear}/${period}/${proformaFilingGroupId}`,
    errorMessage: 'Fetching Federal Proforma Submission ID failed',
  });

export const createTtiBinder = ({
  globalContext,
  ttiCaseId,
  ttiYearId,
  jurisdictionStateCode,
  sltBinderId,
  sltBinderName,
  jurisdictionDescription,
}) =>
  httpPostAndParse({
    route: `/api/efile/binders/${globalContext.apiRouteParamString}`,
    errorMessage: 'Creating efile binder failed',
    body: {
      jurisdictionStateCode,
      ttiCaseId,
      yearOrSubPeriodId: ttiYearId,
      sltBinderId,
      sltBinderName,
      jurisdictionDescription,
    },
    parseResponseErrorMessage: true,
  });

export const fetchEFileSetupStepsData = ({
  taxYear,
  period,
  filingTypeId,
  businessEntityId,
  jurisdictionId,
}) =>
  httpGetAndParse({
    route: `/api/efile/setup-steps/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}`,
    errorMessage: 'Fetching eFile setup steps data failed',
  });
