import React from 'react';

const Footer = () => (
  <div
    className="a-footer"
    title={`© 2019 - ${new Date().getFullYear()} PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.`}
  >
    © 2019 - {new Date().getFullYear()} PwC. PwC refers to the PwC network and/or one or more of its
    member firms, each of which is a separate legal entity. Please see{' '}
    <a href="http://www.pwc.com/structure" target="_blank" rel="noopener noreferrer">
      www.pwc.com/structure
    </a>{' '}
    for further details.
  </div>
);

export default Footer;
