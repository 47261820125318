import { UpdateAccountBody } from '@tls/slt-types';

import { httpGetAndParse, httpPost, httpPut } from '../../../utils/fetchService';

const baseURL = '/api/admin/maintenance/accounts';

export const fetchAccounts = ({ taxYear }: { taxYear: unknown }) =>
  httpGetAndParse({
    route: `${baseURL}/${taxYear}`,
    errorMessage: 'Fetching accounts failed',
  });

export const fetchAccountGroups = () =>
  httpGetAndParse({
    route: `${baseURL}/accountGroups/REPORT`,
    errorMessage: 'Fetching account groups failed',
  });

export const fetchScreenGroups = () =>
  httpGetAndParse({
    route: `${baseURL}/accountGroups/SCREEN`,
    errorMessage: 'Fetching screen groups failed',
  });

export const fetchFunctions = ({ taxYear }: { taxYear: unknown }) =>
  httpGetAndParse({
    route: `${baseURL}/functions/${taxYear}/SEPARATE`,
    errorMessage: 'Fetching functions failed',
  });

export const fetchConsFunctions = ({ taxYear }: { taxYear: unknown }) =>
  httpGetAndParse({
    route: `${baseURL}/functions/${taxYear}/CONSOL`,
    errorMessage: 'Fetching cons functions failed',
  });

export const createAccount = (account: Record<string, unknown>) =>
  httpPost({
    route: baseURL,
    errorMessage: 'Creating account failed',
    body: { ...account },
    parseResponseErrorMessage: false,
  });

export const updateAccount = (account: UpdateAccountBody) =>
  httpPut({
    route: `${baseURL}/${account.accountId}`,
    errorMessage: 'Updating account failed',
    body: { ...account },
    parseResponseErrorMessage: false,
  });
