import hasRequiredPermissionsToView from '../authorization/hasRequiredPermissionsToView';

const filterMenuSchemaByPermissions = (
  customerPermissions,
  nestedMenuSchemaDefinition,
  isClientTtiAvailable = true,
) => {
  const menuSchemaWithFilteredTopLevel = nestedMenuSchemaDefinition.filter(item => {
    const permissionControl =
      !item.access ||
      hasRequiredPermissionsToView(
        customerPermissions,
        item.access,
        item.accessOnlyWithEditPermission,
      );
    const ttiAvailableControl = !(item.needCheckTtiAvailable && !isClientTtiAvailable);

    return permissionControl && ttiAvailableControl;
  });
  const menuSchemaWithFilteredChildren = menuSchemaWithFilteredTopLevel.map(item => ({
    ...item,
    options: filterMenuSchemaByPermissions(customerPermissions, item.options, isClientTtiAvailable),
  }));
  const menuSchemaWithRemovedEmptySubmenus = menuSchemaWithFilteredChildren.filter(
    item => item.route || (item.options || []).length,
  );

  return menuSchemaWithRemovedEmptySubmenus;
};

export default filterMenuSchemaByPermissions;
