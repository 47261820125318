import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Input from '../forms/inputFormik/inputFormik.component';
import CheckBox from '../forms/checkboxFormik/checkboxFormik.component';
import Dropzone from '../forms/dropzoneFormik/dropzoneFormik.component';
import ButtonsGroup from '../forms/buttonsGroup/buttonsGroup.component';

const DOCUMENT_ACCEPTED_TYPES = ['.pdf'];

const AddPdfAttachmentForm = ({
  values,
  isSubmitting,
  submitForm,
  onCancelClick,
  setFieldValue,
}) => {
  const populateUploadNameField = useCallback(
    file => {
      setFieldValue('uploadName', file.name);
    },
    [setFieldValue],
  );

  return (
    <form>
      <Field
        label="Attachment Name"
        name="uploadName"
        value={values.uploadName}
        className="form-text add-partnership-information-field"
        component={Input}
      />
      <Field
        label="Attachment Description"
        name="uploadDescription"
        value={values.uploadDescription}
        className="form-text add-partnership-information-field"
        component={Input}
      />
      <Field
        label="Print Order"
        name="printOrder"
        value={values.printOrder}
        className="form-text add-partnership-information-field"
        component={Input}
        type="number"
      />
      <div className="form-text">
        <Field
          label="Efile Attach"
          name="efileAttach"
          value={values.efileAttach}
          component={CheckBox}
        />
      </div>
      <Field
        className="form-text"
        label="Rollover"
        name="rolloverToNextYear"
        value={values.rolloverToNextYear}
        component={CheckBox}
      />
      <Dropzone
        name="pdfFile"
        onDropCallback={populateUploadNameField}
        value={values.pdfFile}
        label="Document"
        acceptTypes={DOCUMENT_ACCEPTED_TYPES}
      />
      <ButtonsGroup
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        onCancelClick={onCancelClick}
        submitButtonLabel="Add"
      />
    </form>
  );
};

AddPdfAttachmentForm.propTypes = {
  values: PropTypes.shape({
    uploadName: PropTypes.string,
    uploadDescription: PropTypes.string,
    printOrder: PropTypes.string,
    efileAttach: PropTypes.bool,
    rolloverToNextYear: PropTypes.bool,
    pdfFile: PropTypes.instanceOf(File),
  }),
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default AddPdfAttachmentForm;
