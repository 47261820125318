import {
  ConsolData,
  CreateConsolidationBody,
  CreateConsolidationParams,
  CreateConsolidationTaxReturnParams,
  CreateConsolidationTaxReturnBody,
  UpdateConsolidationTaxReturnData,
  UpdateConsolidationTaxReturnParams,
  UpdateConsolidationTaxReturnBody,
} from '../../../../common/types';
import { QueryKeys, QueryError } from '../queries';
import { HttpMethods } from '../enums';

import { OnMutationSuccessCallback, useCustomMutation } from '.';

type UpdateConsolMutationVariable = ConsolData;

export const useMutationUpdateConsol = () =>
  useCustomMutation<null, QueryError, UpdateConsolMutationVariable>({
    handleData: values => ({
      url: '/api/admin/maintenance/consolidations/update-consolidation',
      method: HttpMethods.PUT,
      body: values,
    }),
    resetKey: QueryKeys.ConsolidationsMaintenance.Data,
    successMessage: 'Updating consol succeeded',
    errorMessage: 'Updating consol failed',
    parseResponseErrorMessage: true,
  });

export const useMutationCreateConsolidation = () =>
  useCustomMutation<null, QueryError, CreateConsolidationParams, CreateConsolidationBody>({
    handleData: params => ({
      url: `/api/admin/maintenance/consolidations/${params.taxYear}/${params.period}`,
      method: HttpMethods.POST,
      body: params.consolidation,
    }),
    resetKey: QueryKeys.Consolidations.FetchConsolidations,
    successMessage: 'Creating consolidation succeeded',
    errorMessage: 'Creating consolidation failed',
    parseResponseErrorMessage: true,
  });

export const useMutationCreateConsolidationTaxReturn = ({
  onSuccess,
}: {
  onSuccess?: OnMutationSuccessCallback<null, CreateConsolidationTaxReturnParams>;
} = {}) =>
  useCustomMutation<
    null,
    QueryError,
    CreateConsolidationTaxReturnParams,
    CreateConsolidationTaxReturnBody
  >({
    handleData: params => ({
      url: `/api/tax-returns/consolidation`,
      method: HttpMethods.POST,
      body: params,
    }),
    resetKey: query =>
      query.queryKey?.[0] === QueryKeys.Consolidations.FetchConsolidations ||
      query.queryKey?.[0] === QueryKeys.TaxReturns.Data,
    successMessage: 'Creating consolidation succeeded',
    errorMessage: 'Creating consolidation failed',
    parseResponseErrorMessage: true,
    onSuccess,
  });

export const useMutationUpdateConsolidationTaxReturn = ({
  onSuccess,
}: {
  onSuccess?: OnMutationSuccessCallback<
    UpdateConsolidationTaxReturnData,
    UpdateConsolidationTaxReturnParams
  >;
} = {}) =>
  useCustomMutation<
    UpdateConsolidationTaxReturnData,
    QueryError,
    UpdateConsolidationTaxReturnParams,
    UpdateConsolidationTaxReturnBody
  >({
    handleData: params => ({
      url: `/api/tax-returns/consolidation/${params.consolidation.consolidationId}`,
      method: HttpMethods.PUT,
      body: params,
    }),
    resetKeyFactory: ({ binderId }, { consolidation }) => {
      return query =>
        query.queryKey?.[0] === QueryKeys.Consolidations.FetchConsolidations ||
        query.queryKey?.[0] === QueryKeys.TaxReturns.Data ||
        (binderId === consolidation.returnId &&
          query.queryKey?.[0] === QueryKeys.TaxReturns.TaxReturn) ||
        (binderId !== consolidation.returnId &&
          query.queryKey?.[0] === QueryKeys.TaxReturns.ReturnOptions);
    },
    successMessage: 'Updating consolidation succeeded',
    errorMessage: 'Updating consolidation failed',
    parseResponseErrorMessage: true,
    parseResponseAsJson: true,
    onSuccess,
  });
