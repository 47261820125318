import { GlobalConsolidationData } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export interface FindConsolidationGlobalInfo {
  taxYear: string;
  period: string;
  consolidationId: string;
}

export const useQueryConsolidationGlobalInfo = ({
  params,
  enabled,
}: {
  params: FindConsolidationGlobalInfo;
  enabled: boolean;
}) =>
  useCustomQuery<GlobalConsolidationData[]>([QueryKeys.ConsolidationInfo.GlobalInfo, params], {
    url: `/api/consolidations/consolidation-info/${params.taxYear}/${params.period}/${params.consolidationId}`,
    defaultData: [],
    enabled,
    errorMessage: 'Error fetching global consolidation data data',
  });
