import React from 'react';
import PropTypes from 'prop-types';

import { SelectOptionPropTypes } from '../../../shared/forms/propTypes';
import SDKCustomSelect from '../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import Loading from '../../../shared/displayComponents/loading.component';
import DatasetsTree from '../../dataModels/datasetsTree.container';

import styles from './styles.module.scss';

const DatasetSelector = ({
  disabled,
  isFetchingDataModels,
  dataModels,
  dataModel,
  onDataModelChange,
}) => (
  <>
    <div className="row">
      <div className="col">
        <Loading small isLoading={isFetchingDataModels}>
          <SDKCustomSelect
            disabled={disabled}
            appkitLabel="Data Models"
            className="sdk-custom-select"
            options={dataModels}
            value={dataModel}
            virtualized
            onChange={onDataModelChange}
          />
        </Loading>
      </div>
    </div>

    <div className={`row ${styles.gridSpacerTop}`}>
      <div className="col">
        <label className="a-form-label">Datasets</label>
      </div>
    </div>
    <div className={`row ${styles.gridSpacerBottom}`}>
      <div className="col">
        <DatasetsTree disabled={disabled} />
      </div>
    </div>
  </>
);

DatasetSelector.propTypes = {
  disabled: PropTypes.bool,
  isFetchingDataModels: PropTypes.bool.isRequired,
  dataModels: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  dataModel: SelectOptionPropTypes,
  onDataModelChange: PropTypes.func.isRequired,
};

DatasetSelector.defaultProps = {
  disabled: false,
};

export default DatasetSelector;
