import PropTypes from 'prop-types';

export const genericScreenDefinitionTypes = {
  jurisdictionId: PropTypes.string,
  screenId: PropTypes.string,
  title: PropTypes.string,
  categoryId: PropTypes.string,
  accountId: PropTypes.string,
  screenType: PropTypes.string,
  saveButton: PropTypes.string,
};
