import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { ColumnTypes } from '@common-packages/shared-constants';

import { columnsBlueprintPropTypes } from '../../../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import getFilingAttributesInitialValues from '../getFilingAttributesInitialValues';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';

import ConstantForm from './constantForm.component';
import { constantFormType } from './propTypes';

const ConstantFormWrapper = ({ onSave, rowToEdit, taxYear, jurisdictionId, columnsBlueprint }) => {
  const dispatch = useDispatch();
  const isEditMode = Boolean(rowToEdit);

  const filingAttributes = columnsBlueprint
    .filter(({ columnType }) => columnType !== ColumnTypes.CALC_PREFERENCES)
    .map(filingAttributeRow => filingAttributeRow?.children?.[0] || { children: [] });

  const calcPreferences = columnsBlueprint.find(
    ({ columnType }) => columnType === ColumnTypes.CALC_PREFERENCES,
  );

  const formInitialValues = useMemo(
    () => ({
      taxYear,
      constantId: '',
      jurisdictionId,
      description: '',
      value: '',
      mnemonic: '',
      ...getFilingAttributesInitialValues(filingAttributes),
    }),
    [filingAttributes, taxYear, jurisdictionId],
  );

  const rowToEditValues = useMemo(
    () => ({
      ...formInitialValues,
      ...rowToEdit,
    }),
    [formInitialValues, rowToEdit],
  );

  const validate = () => ({ constantId, description, value }) => {
    const errors = {};

    if (!constantId) {
      errors.crossFields = 'Constant ID is required';
    }

    if (constantId.length > 25) {
      errors.crossFields = 'Constant ID text cannot exceed 25 characters';
    }

    if (!description) {
      errors.crossFields = 'Description is required';
    }

    if (description.length > 100) {
      errors.crossFields = 'Description text cannot exceed 100 characters';
    }

    if (!value) {
      errors.crossFields = 'Value is required';
    }

    return errors;
  };

  const onSubmit = useCallback(
    (values, formikBag) => {
      function save() {
        onSave(values);
      }

      const showMnemonicWarningModal = message => {
        dispatch(
          showConfirmModal({
            title: 'Mnemonic',
            text: message,
            confirmCallback: () => {
              formikBag.setSubmitting(true);
              save();
            },
          }),
        );
        formikBag.setSubmitting(false);
      };

      const pattern = /weight/;

      if (values.mnemonic && !pattern.test(values.description?.toLowerCase())) {
        showMnemonicWarningModal(
          "Constant Mnemonics are only used for weights. The description does not have 'WEIGHT' in it. Are you sure you want to save a Constant Mnemonic with this constant?",
        );
      } else if (!values.mnemonic && pattern.test(values.description?.toLowerCase())) {
        showMnemonicWarningModal(
          "Constant Mnemonics are used for weights. The description has 'WEIGHT' in it. Are you sure you want to save this constant without a Constant Mnemonic?",
        );
      } else {
        save();
      }
    },
    [onSave, dispatch],
  );

  const renderForm = useCallback(
    () => (
      <ConstantForm
        isEditMode={isEditMode}
        rowToEditValues={rowToEditValues}
        filingAttributes={filingAttributes}
        calcPreferences={calcPreferences}
      />
    ),
    [rowToEditValues, isEditMode, filingAttributes, calcPreferences],
  );

  return (
    <Formik
      initialValues={rowToEdit ? rowToEditValues : formInitialValues}
      validate={validate()}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {renderForm}
    </Formik>
  );
};

ConstantFormWrapper.propTypes = {
  onSave: PropTypes.func.isRequired,
  rowToEdit: constantFormType,
  taxYear: PropTypes.string.isRequired,
  jurisdictionId: PropTypes.string.isRequired,
  columnsBlueprint: PropTypes.arrayOf(
    PropTypes.shape({
      children: columnsBlueprintPropTypes.isRequired,
    }),
  ),
};

export default ConstantFormWrapper;
