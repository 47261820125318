import { defaultColumnDefinition, adminColumnDefinition } from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  NumberCellEditor,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';

import { agGridSomeRows } from './utils';

const validateName = (value, data, gridApi) => {
  if (value.length === 0) {
    return 'Name cannot be empty';
  }
  const foundDuplicates = agGridSomeRows(
    gridApi,
    node =>
      node.data.name === value && node.data.attachmentDefinitionId !== data.attachmentDefinitionId,
  );
  if (foundDuplicates) {
    return 'Name must be unique';
  }
};

const getColumnDefinitions = ({ isInEditMode, updateRow, deleteRow }) => {
  const defaultEditableColumnDefinition = {
    ...defaultColumnDefinition,
    editable: isInEditMode,
    onCellValueChanged: ({ data }) => updateRow(data),
  };
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    onDeleteIconClick: deleteRow,
  });
  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultEditableColumnDefinition,
      headerName: 'Display Order',
      field: 'displayOrder',
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        formatValue: value => Number.parseInt(value, 10),
      },
    },
    {
      ...defaultEditableColumnDefinition,
      headerName: 'Name',
      field: 'name',
      cellEditor: TextCellEditor,
      cellEditorParams: {
        inputProps: {
          maxLength: 100,
        },
        validate: validateName,
      },
    },
    {
      ...defaultEditableColumnDefinition,
      headerName: 'Submission File Name',
      field: 'submissionFileName',
      cellEditor: TextCellEditor,
      cellEditorParams: {
        inputProps: {
          maxLength: 64,
        },
      },
    },
    {
      ...defaultEditableColumnDefinition,
      headerName: 'Attachment Description',
      field: 'attachmentDescription',
      cellEditor: TextCellEditor,
      cellEditorParams: {
        inputProps: {
          maxLength: 128,
        },
      },
    },
    {
      ...defaultEditableColumnDefinition,
      headerName: 'X-Path Reference',
      field: 'xPathReference',
      cellEditor: TextCellEditor,
      cellEditorParams: {
        inputProps: {
          maxLength: 260,
        },
      },
    },
  ];
};

export default getColumnDefinitions;
