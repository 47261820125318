import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './reportToggle.module.scss';

const ReportToggle = ({ value, data, api }) => {
  const [toggle, setToggle] = useState(data.showChildren);
  const changeIcon = useCallback(() => {
    data.showChildren = toggle;
    api.forEachNode(node => {
      if (node.data.parent === data.description) {
        node.data.showRow = !node.data.showRow;
      }
    });

    api.onFilterChanged();
    setToggle(!data.showChildren);
  }, [api, data, toggle]);

  if (data.displayLevel === 'HEADER') {
    return (
      <span>
        {value}
        <span
          onClick={changeIcon}
          className={`appkiticon ${
            toggle ? 'icon-circle-minus-outline' : 'icon-circle-plus-outline'
          }
              your-cls ${styles.reportToggle}`}
        />
      </span>
    );
  }

  return <span>{value}</span>;
};

ReportToggle.propTypes = {
  value: PropTypes.string.isRequired,
  data: PropTypes.shape({
    showChildren: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    displayLevel: PropTypes.string.isRequired,
  }),
  api: PropTypes.shape({
    forEachNode: PropTypes.func.isRequired,
    onFilterChanged: PropTypes.func.isRequired,
  }),
};

export default ReportToggle;
