import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';
import promiseWaterfall from 'promise.waterfall';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import Loading from '../../shared/displayComponents/loading.component';
import { errorNotification, successNotification } from '../../shared/notification/store/actions';
import {
  isClientTtiAvailableSelector,
  periodSelector,
  taxYearSelector,
} from '../../shared/store/selectors';
import { clientIdSelector } from '../../shared/store/context';
import * as api from '../store/api';
import useFetch from '../../shared/hooks/useFetch.hook';
import useLastJobOfType from '../../jobs/useLastJobOfType.hook';
import useJobFinishWatcher from '../../jobs/useJobFinishWatcher.hook';
import { startUserJobsPolling } from '../../jobs/store/actions';
import { LOAD_AS_FILED_JOB_TYPE, PARSE_TTI_FEDERAL_XML } from '../constants';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { useMutationDeleteFederalProformaFiles } from '../../shared/mutations/federalProformaProcesses';
import useGridApi from '../../shared/hooks/useGridApi.hook';

import getColumnDefinitions from './federalProformaProcesses.columnDefinitions';
import styles from './federalProformaProcesses.module.scss';

const isValidPDFRow = row => row && row.fileId && row.documentType === 'XML';
const isValidPDFRows = rows => Array.isArray(rows) && rows.some(row => isValidPDFRow(row));

const isValidTTIFederalXmlRow = row => row && row.fileId && row.documentType === 'XML';

const isValidExternalFederalTransmissionRow = row =>
  row && row.fileId && row.documentType === 'ZIP' && row.sourceId === 'GTW';
const isValidExternalFederalTransmissionRows = rows =>
  Array.isArray(rows) && rows.some(row => isValidExternalFederalTransmissionRow(row));

const getRowId = params => params?.data?.fileId;

const FederalProformaProcesses = ({
  taxYear,
  period,
  clientId,

  showSuccessNotification,
  showErrorNotification,
  startUserJobsPolling,
}) => {
  const dispatch = useDispatch();
  const { gridApi: gridApiAG, onGridReady } = useGridApi();
  const isClientTtiAvailable = useSelector(isClientTtiAvailableSelector);

  const {
    data: federalProformaProcesses,
    isFetching: isFetchingFederalProformaProcesses,
    fetch: fetchFederalProformaProcesses,
  } = useFetch({ action: api.fetchFederalProformaProcesses });

  const {
    data: loadFederalAsFiledDataJob,
    isFetching: isPreparingAsFiledDataLoadJob,
    fetch: createAsFiledDataLoadJob,
  } = useFetch({ action: api.loadFederalAsFiledData });

  const {
    data: processProformaXMLJob,
    isFetching: isPreparingProcessProformaXMLJob,
    fetch: runProcessProformaXML,
  } = useFetch({ action: api.processProformaXML });

  useEffect(() => {
    if (loadFederalAsFiledDataJob || processProformaXMLJob) {
      startUserJobsPolling();
    }
  }, [loadFederalAsFiledDataJob, processProformaXMLJob, startUserJobsPolling]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [isGeneratingAllPDFs, setIsGeneratingAllPDFs] = useState(false);
  const [
    isGeneratingAllExternalFederalTransmission,
    setIsGeneratingAllExternalFederalTransmissions,
  ] = useState(false);
  const [agGridFilterModel, setAgGridFilterModel] = useState(null);

  const { isFetching: isGeneratingPDF, fetch: generatePDF } = useFetch({
    action: api.generatePDF,
    throwError: true,
  });

  const {
    isFetching: isGeneratingExternalFederalTransmission,
    fetch: generateExternalFederalTransmission,
  } = useFetch({ action: api.generateExternalFederalTransmission, throwError: true });

  const fetchFederalProformaProcessesForCurrentContext = useCallback(async () => {
    if (taxYear && period) {
      await fetchFederalProformaProcesses({ taxYear, period });
      gridApiAG?.clearFocusedCell();
      gridApiAG?.deselectAll();
      setSelectedRows(null);
      setSelectedRow(null);
    }
  }, [fetchFederalProformaProcesses, taxYear, period, gridApiAG, setSelectedRows, setSelectedRow]);

  const onGenerateExternalFederalTransmission = useCallback(async () => {
    if (isValidExternalFederalTransmissionRow(selectedRow)) {
      try {
        await generateExternalFederalTransmission({
          fileId: selectedRow.fileId,
        });
      } catch (error) {
        showErrorNotification('External federal transmission generation failed');
      }
      await fetchFederalProformaProcessesForCurrentContext();
    }
  }, [
    generateExternalFederalTransmission,
    fetchFederalProformaProcessesForCurrentContext,
    showErrorNotification,
    selectedRow,
  ]);

  const onGenerateAllExteralFederalTransmissions = useCallback(async () => {
    if (isValidExternalFederalTransmissionRows(federalProformaProcesses)) {
      try {
        setIsGeneratingAllExternalFederalTransmissions(true);
        const successes = [];
        const failures = [];
        await promiseWaterfall(
          federalProformaProcesses
            .filter(row => isValidExternalFederalTransmissionRow(row))
            .map(row => async () => {
              try {
                await generateExternalFederalTransmission({
                  fileId: row.fileId,
                });
                showSuccessNotification(
                  `${row.entityId} External federal transmission generated successfully`,
                );
                successes.push({ row });
              } catch (error) {
                showErrorNotification(
                  `${row.entityId} External federal transmission generation failed`,
                );
                failures.push({ row });
              }
            }),
        );
        if (failures.length) {
          showErrorNotification(
            `Error generating ${failures.length} External federal transmission${
              failures.length > 1 ? 's' : ''
            }`,
          );
        } else {
          showSuccessNotification('All External federal transmissions generated successfully');
        }
        setIsGeneratingAllExternalFederalTransmissions(false);
        await fetchFederalProformaProcessesForCurrentContext();
      } catch (error) {
        setIsGeneratingAllExternalFederalTransmissions(false);
        showErrorNotification('Generating All External federal transmissions failed');
      }
    }
  }, [
    showSuccessNotification,
    showErrorNotification,
    fetchFederalProformaProcessesForCurrentContext,
    setIsGeneratingAllExternalFederalTransmissions,
    generateExternalFederalTransmission,
    federalProformaProcesses,
  ]);

  const onGeneratePDFClick = useCallback(async () => {
    if (!isClientTtiAvailable) {
      return;
    }

    if (isValidPDFRow(selectedRow)) {
      await generatePDF({ fileId: selectedRow.fileId });
      await fetchFederalProformaProcessesForCurrentContext();
    }
  }, [
    generatePDF,
    fetchFederalProformaProcessesForCurrentContext,
    selectedRow,
    isClientTtiAvailable,
  ]);

  const onGenerateAllPDFsClick = useCallback(async () => {
    if (isValidPDFRows(federalProformaProcesses) && isClientTtiAvailable) {
      try {
        setIsGeneratingAllPDFs(true);
        const successes = [];
        const failures = [];
        await promiseWaterfall(
          federalProformaProcesses
            .filter(row => isValidPDFRow(row))
            .map(row => async () => {
              try {
                await generatePDF({ fileId: row.fileId });
                showSuccessNotification(`${row.entityId} PDF generated successfully`);
                successes.push({ row });
              } catch (error) {
                showErrorNotification(`${row.entityId} PDF generation failed`);
                failures.push({ row });
              }
            }),
        );
        if (failures.length) {
          showErrorNotification(
            `Error generating ${failures.length} PDF${failures.length > 1 ? 's' : ''}`,
          );
        } else {
          showSuccessNotification('All PDFs generated successfully');
        }
        setIsGeneratingAllPDFs(false);
        await fetchFederalProformaProcessesForCurrentContext();
      } catch (error) {
        setIsGeneratingAllPDFs(false);
        showErrorNotification('Generating All PDFs failed');
      }
    }
  }, [
    showSuccessNotification,
    showErrorNotification,
    fetchFederalProformaProcessesForCurrentContext,
    setIsGeneratingAllPDFs,
    generatePDF,
    federalProformaProcesses,
    isClientTtiAvailable,
  ]);

  const onJobSuccess = useCallback(() => {
    showSuccessNotification('As-Filed data refreshed correctly');
    fetchFederalProformaProcessesForCurrentContext();
  }, [fetchFederalProformaProcessesForCurrentContext, showSuccessNotification]);

  const onJobError = useCallback(() => {
    showErrorNotification('There was an error refreshing As-Filed data', { closeable: true });
  }, [showErrorNotification]);

  const { lastJobOfType } = useLastJobOfType({
    jobType: LOAD_AS_FILED_JOB_TYPE,
    triggeredJob: loadFederalAsFiledDataJob,
  });

  useJobFinishWatcher({
    job: lastJobOfType,
    onObservableSuccess: onJobSuccess,
    onObservableError: onJobError,
  });

  const { lastJobOfType: lastJobOfProcessProformaXMLJob } = useLastJobOfType({
    jobType: PARSE_TTI_FEDERAL_XML,
    triggeredJob: processProformaXMLJob,
  });

  useJobFinishWatcher({
    job: lastJobOfProcessProformaXMLJob,
    onObservableSuccess: onJobSuccess,
    onObservableError: onJobError,
  });

  const {
    mutateAsync: deleteFederalProformaFiles,
    isLoading: isDeletingFederalFiles,
  } = useMutationDeleteFederalProformaFiles();

  const loadFederalAsFiledData = useCallback(() => {
    createAsFiledDataLoadJob({
      taxYear,
      period,
      clientId,
    });
  }, [createAsFiledDataLoadJob, taxYear, period, clientId]);

  const processProformaXML = useCallback(() => {
    runProcessProformaXML({
      taxYear,
      period,
      clientId,
      proformaFilingGroupId: selectedRow.proformaFilingGroup,
    });
  }, [runProcessProformaXML, taxYear, period, clientId, selectedRow]);

  useEffect(() => {
    fetchFederalProformaProcessesForCurrentContext();
  }, [fetchFederalProformaProcessesForCurrentContext]);

  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);

  const onCellFocused = useCallback(
    ({ rowIndex }) => {
      setSelectedRow(gridApiAG?.getDisplayedRowAtIndex(rowIndex)?.data || null);
    },
    [setSelectedRow, gridApiAG],
  );

  const onSelectionChanged = useCallback(
    ({ api: gridApi }) => {
      setSelectedRows(gridApi.getSelectedRows());
    },
    [setSelectedRows],
  );

  const doDeleteFiles = useCallback(async () => {
    await deleteFederalProformaFiles({
      tobeDeletedRows: selectedRows,
      taxYear,
      period,
    });
    setSelectedRows(null);
    setSelectedRow(null);
    gridApiAG?.clearFocusedCell();
    fetchFederalProformaProcesses({ taxYear, period });
  }, [
    setSelectedRows,
    setSelectedRow,
    deleteFederalProformaFiles,
    gridApiAG,
    taxYear,
    period,
    selectedRows,
    fetchFederalProformaProcesses,
  ]);

  const onDeleteClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Delete File',
        text: `Are you sure you want to delete the selected files?`,
        confirmCallback: async () => {
          await doDeleteFiles();
        },
      }),
    );
  }, [doDeleteFiles, dispatch]);

  const onRowDataUpdated = useCallback(
    ({ api: gridApi }) => {
      if (agGridFilterModel) {
        gridApi.setFilterModel(agGridFilterModel);
      }
      const nodeToSelect = gridApi.getRowNode(selectedRow?.fileId);
      nodeToSelect?.setSelected(true);
    },
    [selectedRow, agGridFilterModel],
  );

  const onFilterChanged = useCallback(({ api: gridApi }) => {
    const filterModel = gridApi.getFilterModel();
    if (Object.entries(filterModel).length) {
      setAgGridFilterModel(filterModel);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col add-button-column">
          <Button
            size="lg"
            className="add-button"
            onClick={processProformaXML}
            disabled={
              isPreparingProcessProformaXMLJob ||
              lastJobOfProcessProformaXMLJob.isJobInProgress ||
              !isValidTTIFederalXmlRow(selectedRow)
            }
          >
            Process Proforma XML
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col add-button-column">
          <Button
            size="lg"
            className="add-button"
            onClick={loadFederalAsFiledData}
            disabled={isPreparingAsFiledDataLoadJob || lastJobOfType.isJobInProgress}
          >
            Load Federal As-filed Data
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col add-button-column">
          <Button
            size="lg"
            className={`add-button ${styles.spinnerButton}`}
            onClick={onGenerateExternalFederalTransmission}
            disabled={
              isGeneratingExternalFederalTransmission ||
              !isValidExternalFederalTransmissionRow(selectedRow) ||
              !isClientTtiAvailable
            }
            title="Loads As-filed data into E-file and generates federal submissions for state e-file."
          >
            Generate Selected External Fed.
            <Loading
              isLoading={
                isGeneratingExternalFederalTransmission &&
                !isGeneratingAllExternalFederalTransmission
              }
              small
            />
          </Button>

          <Button
            size="lg"
            className={`add-button ${styles.spinnerButton}`}
            onClick={onGenerateAllExteralFederalTransmissions}
            disabled={
              isGeneratingAllExternalFederalTransmission ||
              !isValidExternalFederalTransmissionRows(federalProformaProcesses) ||
              !isClientTtiAvailable
            }
          >
            Generate All External Fed. Transmissions
            <Loading
              className={styles.spinnerContainer}
              isLoading={isGeneratingAllExternalFederalTransmission}
              small
            />
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col add-button-column">
          <Button
            size="lg"
            className={`add-button ${styles.spinnerButton}`}
            onClick={onDeleteClick}
            disabled={!selectedRows?.length}
          >
            Delete Federal File
            <Loading className={styles.spinnerContainer} isLoading={isDeletingFederalFiles} small />
          </Button>

          <Button
            size="lg"
            className={`add-button ${styles.spinnerButton}`}
            onClick={onGeneratePDFClick}
            disabled={isGeneratingPDF || !isValidPDFRow(selectedRow) || !isClientTtiAvailable}
          >
            Generate Selected PDF
            <Loading
              className={styles.spinnerContainer}
              isLoading={isGeneratingPDF && !isGeneratingAllPDFs}
              small
            />
          </Button>

          <Button
            size="lg"
            className={`add-button ${styles.spinnerButton}`}
            onClick={onGenerateAllPDFsClick}
            disabled={
              isGeneratingAllPDFs ||
              !isValidPDFRows(federalProformaProcesses) ||
              !isClientTtiAvailable
            }
          >
            Generate All PDFs
            <Loading isLoading={isGeneratingAllPDFs} small />
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className={`col ${styles.gridContainer}`}>
          <AgGrid
            rowData={federalProformaProcesses}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingFederalProformaProcesses}
            rowSelection="multiple"
            onCellFocused={onCellFocused}
            onSelectionChanged={onSelectionChanged}
            onRowDataUpdated={onRowDataUpdated}
            onFilterChanged={onFilterChanged}
            getRowId={getRowId}
            withSearchBar
            suppressRowClickSelection
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
};

FederalProformaProcesses.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  clientId: PropTypes.string.isRequired,

  showSuccessNotification: PropTypes.func.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
  startUserJobsPolling: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    clientId: clientIdSelector(state),
  }),
  {
    showErrorNotification: errorNotification,
    showSuccessNotification: successNotification,
    startUserJobsPolling,
  },
)(FederalProformaProcesses);
