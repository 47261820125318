const Yup = require('yup');

const updateAccountDataForEntitySchema = Yup.object()
  .shape({
    rowsToUpdate: Yup.array()
      .of(
        Yup.object().shape({
          jurisdictionId: Yup.string().required(),
          accountAmount: Yup.string().required(),
        }),
      )
      .notOneOf([undefined, null]), // eslint-disable-line no-undefined
  })
  .strict();

module.exports = {
  updateAccountDataForEntitySchema,
};
