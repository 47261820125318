import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';

import {
  defaultColumnDefinitionWithFilter,
  disabledReadOnlyCheckboxColumnDefinitions,
  adminColumnDefinition,
} from '../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import getColumnBlueprintBasedColumnDefinitions from '../../shared/columnDefinitions/columnsBlueprint.columnDefinition';
import { FilingAttributesColumnBlueprint } from '../../../../common/types';
import { FederalReturnAttachments } from '../../shared/enums';

import { FEDERAL_RETURN_ATTACHMENT_OPTIONS, DUE_DATE_COLUMN_WIDTH } from './constants';

const checkboxColumnDefinition = {
  ...defaultColumnDefinitionWithFilter,
  ...disabledReadOnlyCheckboxColumnDefinitions,
  width: 85,
};

const filingInformationAndWarningValueFormatter = (value: string | null) =>
  value && value.length > 100 ? `${value.substring(0, 100)}...` : value || '';

const staticColumnDefinitions: (ColDef | ColGroupDef)[] = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Name',
    field: 'name',
    width: 500,
    pinned: 'left',
    lockPinned: true,
  },
  { ...defaultColumnDefinitionWithFilter, headerName: 'E-File Schema ID', field: 'sltSchemaId' },
  { ...defaultColumnDefinitionWithFilter, headerName: 'Filing Form', field: 'filingFormName' },
  {
    ...checkboxColumnDefinition,
    headerName: 'Default Return',
    field: 'isDefault',
  },
  {
    ...checkboxColumnDefinition,
    headerName: 'Stacked Return',
    field: 'isStackedReturn',
  },
  {
    headerName: 'Due Date',
    children: [
      {
        ...defaultColumnDefinitionWithFilter,
        headerName: 'Day',
        field: 'dueDateDays',
        width: DUE_DATE_COLUMN_WIDTH,
      },
      {
        ...defaultColumnDefinitionWithFilter,
        headerName: 'Month',
        field: 'dueDateMonths',
        width: DUE_DATE_COLUMN_WIDTH,
      },
      {
        ...defaultColumnDefinitionWithFilter,
        headerName: 'Extended',
        field: 'dueDateExtendedMonths',
        width: DUE_DATE_COLUMN_WIDTH,
      },
      {
        ...defaultColumnDefinitionWithFilter,
        headerName: '2nd Extended',
        field: 'dueDateSecondExtendedMonths',
        width: DUE_DATE_COLUMN_WIDTH,
      },
    ],
  },
  {
    headerName: 'Filing Methods',
    children: [
      {
        ...checkboxColumnDefinition,
        headerName: 'E-File Allowed',
        field: 'isEfileAllowed',
      },
      {
        ...checkboxColumnDefinition,
        headerName: 'Paper Allowed',
        field: 'isPaperAllowed',
      },
    ],
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Federal Return Attachment',
    field: 'federalReturnAttachment',
    width: 120,
    valueFormatter: ({ value }: { value: FederalReturnAttachments | null }) =>
      FEDERAL_RETURN_ATTACHMENT_OPTIONS.find(({ value: optionValue }) => optionValue === value)
        ?.label || '',
  },
  {
    ...checkboxColumnDefinition,
    headerName: 'Federal XML Required',
    field: 'isFederalXmlRequired',
  },
  {
    ...checkboxColumnDefinition,
    headerName: 'Federal As Filed Requested',
    field: 'isFederalAsFiledRequested',
  },
  {
    ...checkboxColumnDefinition,
    headerName: 'E-File Mandated',
    field: 'hasMandate',
  },
  {
    ...checkboxColumnDefinition,
    headerName: 'E-File Ready',
    field: 'isEfileReady',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Filing Attributes',
    field: 'filingAttributes',
    width: 100,
  },
];

const filingInformationAndWarningColumnDefinitions: ColDef[] = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Filing Information',
    field: 'filingInformation',
    width: 250,
    valueFormatter: ({ value }: { value: null | string }) =>
      filingInformationAndWarningValueFormatter(value),
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Filing Warning',
    field: 'filingWarning',
    width: 250,
    valueFormatter: ({ value }: { value: null | string }) =>
      filingInformationAndWarningValueFormatter(value),
  },
];

const getColumnDefinitions = (
  columnsBlueprint: FilingAttributesColumnBlueprint[],
  onEditIconClick: (data: ICellRendererParams['data']) => unknown,
): (ColDef | ColGroupDef)[] => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onEditIconClick,
    shouldShowDeleteIcon: false,
  });

  return [
    {
      ...adminColumnDefinition,
      cellRendererFramework: AdminCellRenderer,
      pinned: 'left',
      lockPinned: true,
    },
    ...staticColumnDefinitions,
    ...getColumnBlueprintBasedColumnDefinitions({
      columnsBlueprint,
    }),
    ...filingInformationAndWarningColumnDefinitions,
  ];
};

export default getColumnDefinitions;
