import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchAttachmentDefinitions = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/development/attachment-definitions/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching attachment definitions failed',
  });

export const updateAttachmentDefinitions = ({ taxYear, jurisdictionId, changes }) =>
  httpPut({
    route: `/api/development/attachment-definitions/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Updating attachment definitions failed',
    body: changes,
  });
