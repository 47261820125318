import { RowNode } from 'ag-grid-community';

const validateSelectionListItemValue = (
  value: string,
  data: RowNode['data'],
  allRows: RowNode[],
) => {
  if (value.length === 0) {
    return 'Value cannot be empty';
  }
  if (value.length > 255) {
    return 'Value cannot be longer than 255 characters';
  }
  const foundDuplicates = allRows.some(row => row.data.item === value && row.data.id !== data.id);
  if (foundDuplicates) {
    return 'Value must be unique';
  }
  return '';
};

export default validateSelectionListItemValue;
