import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import { useFetchFilingGroups } from './store/hooks';
import { AuthorizedFilingGroupRoute } from './routeConfiguration/filingGroupRoute.component';
import FilingMembers from './filingMembers/filingMembers.container';
import FilingGroupDescriptionValue from './routeConfiguration/filingGroupDescriptionValue.container';

const FilingGroupRoutes = () => {
  useFetchFilingGroups();
  const filingGroupDescriptionAdditionalValue = <FilingGroupDescriptionValue />;

  return (
    <Switch>
      <AuthorizedFilingGroupRoute
        exact
        path={Routes.filingMembers.MAIN}
        component={FilingMembers}
        additionalValues={filingGroupDescriptionAdditionalValue}
      />
    </Switch>
  );
};

export default FilingGroupRoutes;
