import { GetAccessTokensResponse } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export const useFetchAccessTokens = ({
  params,
  onSuccess,
}: {
  params: { query: string };
  onSuccess: (stepsStatuses: GetAccessTokensResponse) => void;
}) =>
  useCustomQuery<GetAccessTokensResponse>([QueryKeys.Auth.Token, ''], {
    url: `/api/access_token${params.query}`,
    errorMessage: 'Error fetching user access tokens',
    onSuccess,
  });
