import React, { useState, useCallback, useMemo } from 'react';
import { Button } from '@pwc/appkit-react';
import { useSelector } from 'react-redux';

import useGridApi from '../../shared/hooks/useGridApi.hook';
import { useQueryWatermarks } from '../../shared/queries/watermarks';
import { useMutationDeleteWatermark } from '../../shared/mutations/watermarks';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import { customerPermissionsSelector } from '../../shared/store/selectors';
import useModal from '../../shared/hooks/useModal.hook';

import AddEditWatermark from './addEditWatermark.container';
import DeleteWatermarkModal from './deleteWatermarkModal.component';
import getColumnDefinitions from './watermarks.columnDefinitions';
import hasPermissionToInsertUpdateWatermarks from './hasPermissionToInsertUpdateWatermarks';
import hasPermissionToDeleteWatermarks from './hasPermissionToDeleteWatermarks';

const Watermarks = () => {
  const customerPermissions = useSelector(customerPermissionsSelector);
  const [watermarkToEdit, setWatermarkToEdit] = useState(null);

  const { gridApi, onGridReady } = useGridApi();
  const { data: watermarks, isFetching: isFetchingWatermarks } = useQueryWatermarks();
  const deleteWatermark = useMutationDeleteWatermark();

  const watermarksNames = useMemo(() => watermarks.map(({ name }) => name), [watermarks]);

  const { showModal: showDeleteWatermarkModal, modalProps: deleteWatermarkModalProps } = useModal();

  const hasPermissionToEdit = hasPermissionToInsertUpdateWatermarks(customerPermissions);
  const hasPermissionToDelete = hasPermissionToDeleteWatermarks(customerPermissions);

  const clearForm = useCallback(() => {
    gridApi.deselectAll();
    setWatermarkToEdit(null);
  }, [gridApi]);

  const selectWatermarkToEdit = useCallback(
    ({ api }) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        setWatermarkToEdit({
          ...selectedRow,
          rotation: String(selectedRow.rotation),
          fontSize: String(selectedRow.fontSize),
          verticalOffset: String(selectedRow.verticalOffset),
        });
      }
    },
    [setWatermarkToEdit],
  );

  const handleDeleteIconClick = useCallback(
    async watermark => {
      showDeleteWatermarkModal(watermark);
    },
    [showDeleteWatermarkModal],
  );

  const handleDelete = useCallback(
    async ({ id }) => {
      await deleteWatermark.mutateAsync({ watermarkId: id });
      clearForm();
    },
    [deleteWatermark, clearForm],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        hasPermissionToDelete,
        onDeleteIconClick: handleDeleteIconClick,
      }),
    [handleDeleteIconClick, hasPermissionToDelete],
  );

  return (
    <>
      {hasPermissionToEdit && (
        <div className="row">
          <div className="col add-button-column">
            <Button
              size="lg"
              className="add-button"
              onClick={clearForm}
              disabled={isFetchingWatermarks}
            >
              Add Watermark
            </Button>
          </div>
        </div>
      )}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={watermarks}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingWatermarks}
            onGridReady={onGridReady}
            onSelectionChanged={selectWatermarkToEdit}
            withSearchBar
            sideBar={defaultSideBarWithColumnsToolPanel}
            areHeaderCellBordersEnabled
          />
        </div>
        {hasPermissionToEdit && (
          <div className="col-4 right-column-edit-form">
            <AddEditWatermark
              watermarkToEdit={watermarkToEdit}
              clearForm={clearForm}
              watermarksNames={watermarksNames}
            />
          </div>
        )}
      </div>
      {deleteWatermarkModalProps.visible && (
        <DeleteWatermarkModal {...deleteWatermarkModalProps} onDelete={handleDelete} />
      )}
    </>
  );
};

export default Watermarks;
