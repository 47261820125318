import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchGenericFunctionsTypes = getApiActionTypes('DEVELOPMENT/FETCH_GENERIC_FUNCTIONS');
export const fetchDataTypesTypes = getApiActionTypes('DEVELOPMENT/FETCH_DATA_TYPES');
export const fetchConstantsTypes = getApiActionTypes('DEVELOPMENT/FETCH_CONSTANTS');
export const fetchCategoriesTypes = getApiActionTypes('DEVELOPMENT/FETCH_CATEGORIES');
export const fetchAccountsTypes = getApiActionTypes('DEVELOPMENT/FETCH_ACCOUNTS');
export const fetchJurisdictionsTypes = getApiActionTypes('DEVELOPMENT/FETCH_JURISDICTIONS');
export const fetchGenericFunctionParamsTypes = getApiActionTypes(
  'DEVELOPMENT/FETCH_GENERIC_FUNCTION_PARAMS',
);
export const createGenericFunctionWithParamsTypes = getApiActionTypes(
  'DEVELOPMENT/CREATE_GENERIC_FUNCTION_WITH_PARAMS',
);
export const updateGenericFunctionWithParamsTypes = getApiActionTypes(
  'DEVELOPMENT/UPDATE_GENERIC_FUNCTION_WITH_PARAMS',
);
export const deleteGenericFunctionTypes = getApiActionTypes('DEVELOPMENT/DELETE_GENERIC_FUNCTION');
