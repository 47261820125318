import React from 'react';
import { connect } from 'react-redux';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../../shared/store/selectors';

import taxSummariesBreadcrumbRoutes from './taxSummariesBreadcrumbRoutes.component';

const TaxSummariesPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: taxSummariesBreadcrumbRoutes,
}))(GlobalContextPage);

const TaxSummariesRoute = props => (
  <AuthorizedCustomRoute wrapper={TaxSummariesPage} hideGlobalContext {...props} />
);

export default TaxSummariesRoute;
