import React, { useCallback, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';
import PropTypes from 'prop-types';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';
import { navigatorEFileDataSelector } from '../../navigator/store';
import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';
import { filingTypes } from '../../shared/constants';

import eFileBreadcrumbRoutes from './eFileBreadcrumbRoutes.component';

const EFilePage = props => {
  const { returnId } = useParams();
  const history = useHistory();
  const navigatorEFileData = useSelector(navigatorEFileDataSelector);
  const globalContext = useSelector(globalContextSelector);

  const redirectToFilingDecisionsOrSetupTaxReturns = useCallback(() => {
    history.push(
      globalContext.filingTypeId === filingTypes.Separate
        ? Routes.filingDecisionsV2.compiledRoute(getContextAwareUrlParams(globalContext.params))
        : Routes.setupTaxReturns.compiledRoute(getContextAwareUrlParams(globalContext.params)),
    );
  }, [globalContext.filingTypeId, globalContext.params, history]);

  const redirectToReturnMaintenance = useCallback(
    returnId => {
      history.push(Routes.returnMaintenance.compiledRoute({ returnId }));
    },
    [history],
  );

  useEffect(() => {
    // Handle federal proforma processes using efile route component - probably to refactor later
    if (props.disableEFileFlagChecking || !globalContext.isReady) {
      return;
    }

    // If context is not efiling
    if (!navigatorEFileData.isEFiling) {
      return returnId
        ? redirectToReturnMaintenance(returnId)
        : redirectToFilingDecisionsOrSetupTaxReturns();
    }

    // If checkEFileIsReadyFlag is set to false, we return and redirect the user to the appropriate page
    if (!props.checkEFileIsReadyFlag) {
      return;
    }

    if (!navigatorEFileData.isEFileReady) {
      return returnId
        ? redirectToReturnMaintenance(returnId)
        : redirectToFilingDecisionsOrSetupTaxReturns();
    }
  }, [
    redirectToFilingDecisionsOrSetupTaxReturns,
    redirectToReturnMaintenance,
    globalContext,
    navigatorEFileData,
    props.checkEFileIsReadyFlag,
    props.disableEFileFlagChecking,
    returnId,
  ]);

  return <GlobalContextPage {...props} />;
};

EFilePage.propTypes = {
  checkEFileIsReadyFlag: PropTypes.bool,
  disableEFileFlagChecking: PropTypes.bool,
};

const ConnectedEFilePage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: eFileBreadcrumbRoutes,
}))(EFilePage);

const AuthorizedEFileRoute = props => (
  <AuthorizedCustomRoute
    wrapper={ConnectedEFilePage}
    hideGlobalContext
    additionalWrapperProps={{
      checkEFileIsReadyFlag: props.checkEFileIsReadyFlag || false,
      disableEFileFlagChecking: props.disableEFileFlagChecking || false,
    }}
    {...props}
  />
);

AuthorizedEFileRoute.propTypes = {
  checkEFileIsReadyFlag: PropTypes.bool,
  disableEFileFlagChecking: PropTypes.bool,
};

export { AuthorizedEFileRoute };
