import React, { useCallback } from 'react';

import SlideIn from '../../shared/displayComponents/slideIn/slideIn.component';
import { PanelComponents } from '../returnMaintenance.container';
import { SSN_OPTION_ENUM } from '../../shared/enums/pdf';

import styles from './downloadPdfPanel.module.scss';
import GeneratePdf from './generatePdf.component';
import DownloadRegeneratePdf from './downloadRegeneratePdf.component';
import PdfReady from './pdfReady.component';
import PdfError from './pdfError.component';

interface DownloadPdfPanelProps {
  generatePdf: (shouldDecrypt: boolean) => Promise<void>;
  downloadPdf: (shouldDecrypt: boolean) => void;
  hidePanel: () => void;
  activeComponent: PanelComponents;
  isPanelVisible: boolean;
  isDownloading: boolean;
  pdfLastGenerationDate: string | null;
  isIndividualPartnersAndHasPermission: boolean;
  shouldRevealSSNOption: string;
  setShouldRevealSSNOption: React.Dispatch<React.SetStateAction<string>>;
}

const DownloadPdfPanel = ({
  generatePdf,
  downloadPdf,
  hidePanel,
  activeComponent,
  isPanelVisible,
  isDownloading,
  pdfLastGenerationDate,
  isIndividualPartnersAndHasPermission,
  shouldRevealSSNOption,
  setShouldRevealSSNOption,
}: DownloadPdfPanelProps) => {
  const handleHidePanel = useCallback(() => {
    setShouldRevealSSNOption(SSN_OPTION_ENUM.HIDE);
    hidePanel();
  }, [hidePanel, setShouldRevealSSNOption]);

  const handleDownloadPdfClick = useCallback(() => {
    downloadPdf(shouldRevealSSNOption === SSN_OPTION_ENUM.SHOW);
  }, [downloadPdf, shouldRevealSSNOption]);

  const handleRegenerateReturn = useCallback(() => {
    return generatePdf(shouldRevealSSNOption === SSN_OPTION_ENUM.SHOW);
  }, [generatePdf, shouldRevealSSNOption]);

  const getActiveComponent = () => {
    switch (activeComponent) {
      case PanelComponents.DOWNLOAD_PDF:
        return (
          <DownloadRegeneratePdf
            handleRegenerateReturn={handleRegenerateReturn}
            latestReturnPdfGenerationDate={pdfLastGenerationDate}
            isDownloading={isDownloading}
            handleDownloadPdfClick={handleDownloadPdfClick}
            shouldRevealSSNOption={shouldRevealSSNOption}
            setShouldRevealSSNOption={setShouldRevealSSNOption}
            isIndividualPartnersAndHasPermission={isIndividualPartnersAndHasPermission}
          />
        );
      case PanelComponents.GENERATE_PDF:
        return <GeneratePdf hidePanel={handleHidePanel} />;
      case PanelComponents.PDF_READY:
        return <PdfReady isDownloading={isDownloading} downloadPdf={handleDownloadPdfClick} />;
      case PanelComponents.PDF_ERROR:
        return <PdfError generatePdf={handleRegenerateReturn} />;
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <SlideIn
      className={styles.panel}
      isOpen={isPanelVisible}
      onRequestClose={handleHidePanel}
      width="480px"
      closeIconName="close"
      title="Download PDF"
    >
      {getActiveComponent()}
    </SlideIn>
  );
};

export default DownloadPdfPanel;
