import React from 'react';
import Spinner from '@tls/ui-spinner';

const renderReturnParam = <TParam extends string | number>(
  param: TParam,
  isLoading?: boolean,
  labelsMap?: Record<TParam, string>,
) => {
  if (isLoading) {
    return <Spinner small />;
  }

  if (labelsMap) {
    return labelsMap[param] || '-';
  }

  return param || '-';
};

export default renderReturnParam;
