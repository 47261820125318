import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import { taxYearSelector, periodSelector } from '../store/selectors';

import GenericScreenDefinitionRoute from './routeConfiguration/genericScreenDefinitionRoute.container';
import GenericScreenDefinition from './genericScreenDefinition.container';
import GenericScreenDefinitionRowDetails from './rowDetailsPage/genericScreenDefinitionRowDetails.container';
import { fetchAccounts, fetchCategories, fetchGenericScreenDefinition } from './store/actions';

const GenericScreenDefinitionRoutes = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taxYear) {
      dispatch(fetchAccounts({ taxYear }));
      dispatch(fetchCategories({ taxYear }));
    }
  }, [dispatch, taxYear]);

  useEffect(() => {
    const isContextReady = taxYear && period;

    if (isContextReady) {
      dispatch(fetchGenericScreenDefinition({ taxYear, period }));
    }
  }, [dispatch, taxYear, period]);

  return (
    <Switch>
      <GenericScreenDefinitionRoute
        exact
        path={Routes.genericScreenDefinition.MAIN}
        component={GenericScreenDefinition}
        hideGlobalContext
      />
      <GenericScreenDefinitionRoute
        exact
        path={Routes.genericScreenDefinitionRowDetails.MAIN}
        component={GenericScreenDefinitionRowDetails}
        hideGlobalContext
      />
    </Switch>
  );
};

export default GenericScreenDefinitionRoutes;
