import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from '@pwc/appkit-react';

import Loading from '../../shared/displayComponents/loading.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { globalContextSelector } from '../../shared/store/selectors';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import useGridApi from '../../shared/hooks/useGridApi.hook';
import columnBlueprintStyles from '../../shared/styles/columnBlueprintStyles.module.scss';
import { useQueryConsolidatedReportDataForms } from '../../shared/queries/consolidationConsolidatedReport';

import getColumnDefinitions from './consolidatedReport.columnDefinitions';
import style from './consolidatedReport.module.scss';
import { EntityDisplayOptions } from './constants';

const isExternalFilterPresent = () => true;

const ConsolidatedReport = () => {
  const globalContext = useSelector(globalContextSelector);

  const {
    isReady: isContextReady,
    params: { taxYear, period, businessEntityId: consolidationId, jurisdictionId },
  } = globalContext;

  const { data, isFetching } = useQueryConsolidatedReportDataForms({
    params: { taxYear, period, consolidationId },
    enabled: isContextReady,
  });

  const consolidatedReportRowData = useMemo(() => data?.rowData, [data]);
  const consolidatedReportColumnsBlueprint = useMemo(
    () => data?.columnsBlueprint || [{ headerName: '', field: '' }],
    [data],
  );

  const [suppressZeros, setSuppressZeros] = useState(true);
  const { gridApi, onGridReady } = useGridApi();
  // we are using a REF for ag grid filter
  // because it saves the first doesExternalFilterPass function reference
  // that reference has a closure on `suppressZero`, which never gets updated because
  // new references of filter function are not used by ag Grid
  // so we use a ref in the initial filter function to be able to update the filter
  // results during the lifetime of the grid with changing checkbox
  const agGridFilter = useRef(null);

  const [entityDisplayType, setEntityDisplayType] = useState('ENTITY_ID');

  const onEntityDisplayChange = useCallback(
    option => setEntityDisplayType(option ? option.value : null),
    [setEntityDisplayType],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        columnsBlueprint: consolidatedReportColumnsBlueprint,
        entityDisplayType,
        taxYear,
        period,
        jurisdictionId,
      }),
    [consolidatedReportColumnsBlueprint, entityDisplayType, taxYear, period, jurisdictionId],
  );

  const toggleSuppressZeros = useCallback(() => {
    setSuppressZeros(!suppressZeros);
  }, [setSuppressZeros, suppressZeros]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    agGridFilter.current = data => {
      const hideBecauseSuppressed = suppressZeros ? data.noData : false;
      const hideBecauseChildrenHidden = !data.showRow;

      const show = !hideBecauseSuppressed && !hideBecauseChildrenHidden;

      return show;
    };

    gridApi.onFilterChanged();
  }, [gridApi, suppressZeros]);

  const doesExternalFilterPass = useCallback(({ data }) => {
    if (!agGridFilter.current) {
      return true;
    }

    return agGridFilter.current(data);
  }, []);

  return (
    <Loading isLoading={!isContextReady || isFetching}>
      <div className="row">
        <div className="col">
          <div className={style.gridOptions}>
            <Checkbox
              className={style.suppressZerosCheckbox}
              checked={suppressZeros}
              onChange={toggleSuppressZeros}
            >
              Suppress Zeros
            </Checkbox>
            <div className={style.entityDisplay}>
              <label className={style.entityDisplayLabel}>Entity Display</label>
              <SDKCustomSelect
                hideLabel
                className={`sdk-custom-select ${style.entityDisplaySelect}`}
                options={EntityDisplayOptions}
                onChange={onEntityDisplayChange}
                value={entityDisplayType}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`row grid-row grid-borderless ${columnBlueprintStyles.gridContainer}`}>
        <div className="col">
          <AgGrid
            rowData={consolidatedReportRowData}
            columnDefs={columnDefinitions}
            animateRows
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </Loading>
  );
};

export default ConsolidatedReport;
