import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectOption } from '@tls/slt-types';
import { useFormikContext } from 'formik';
import { consolidationsSchemas } from '@common-packages/validators';
import { useSelector } from 'react-redux';
import { Banner } from '@pwc/appkit-react';

import {
  TaxReturnDefinitionWithFilingAttributes,
  FindTaxReturnsColumnsBlueprint,
  FindConsolidationsDetailsData,
  CalcPreferenceDefinitions,
} from '../../../../../common/types/apiShapes';
import { ConsolidationFormValues } from '../utils/useFormInitialValues';
import TabWrapper from '../../displayComponents/tabWrapper/tabWrapper.component';
import { useQueryReturnStatus } from '../../queries/taxReturns';
import { consolidationsForValidationSelector } from '../../store/consolidations.selectors';
import { useQueryDueDateOptions } from '../../queries/dueDates';
import { getDefaultDueDateType } from '../utils/getDefaultDueDateType';
import { useShouldDisplayReturnsDueDates } from '../../hooks/useShouldDisplayReturnsDueDates.hook';
import { useQueryEntitiesForFilingGroups } from '../../queries/entities';
import { globalContextSelector } from '../../store/selectors';
import { useManualValidateForm } from '../../hooks/useManualValidateForm';

import { TabsTypes } from './addEditReturnPanel.container';
import AddEditReturnOptionsForm from './addEditReturnOptionsForm.component';
import AddEditConsolOptionsForm from './addEditConsolOptionsForm.component';
import AddEditCalcOptionsForm from './addEditCalcOptionsForm.component';
import { AddEditReturnWrapperContextProvider } from './addEditReturnWrapperContext';
import { useAddEditReturnDefinitionSetting } from './hooks/useAddEditReturnDefinitionSetting.hook';

export const CALC_OPTIONS_KEY = 'calcOptions';

const filingGroupChangeMessage =
  'Your filing group changes affect the returns of those members. When adding groups, SLT automatically creates member returns. When removing, SLT converts non-filing returns to filing.';
const returnDefinitionIdChangedMessage =
  'If you change the filing form, SLT will delete all associated returns and create new ones. For each new return, you may need to re-add forms/attachments to the Returns tab.';

interface AddEditReturnFormWrapperProps {
  isEditing: boolean;
  activeTab: string;
  isLoadingTaxReturnsDefinitionsJurisdictionOptions: boolean;
  isLoadingTaxReturnsDefinitions: boolean;
  taxReturns: FindConsolidationsDetailsData[];
  taxReturnsDefinitions: TaxReturnDefinitionWithFilingAttributes[];
  taxReturnsDefinitionsJurisdictionOptions: SelectOption[];
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  columnsBlueprint: FindTaxReturnsColumnsBlueprint;
  calcPreferencesDefinitions: CalcPreferenceDefinitions;
}

const AddEditReturnFormWrapper = ({
  isEditing,
  activeTab,
  isLoadingTaxReturnsDefinitionsJurisdictionOptions,
  isLoadingTaxReturnsDefinitions,
  taxReturns,
  taxReturnsDefinitions,
  columnsBlueprint,
  calcPreferencesDefinitions,
  taxReturnsDefinitionsJurisdictionOptions,
  onNextButtonClick,
  onBackButtonClick,
}: AddEditReturnFormWrapperProps) => {
  const { taxYear, period } = useSelector(globalContextSelector);
  const {
    values,
    setFieldValue,
    errors,
    initialValues,
  } = useFormikContext<ConsolidationFormValues>();
  const [checkedFilingGroupIds, setCheckedFilingGroupIds] = useState<string[]>(
    values.filingGroupIds,
  );
  const {
    taxReturnsDefinition,
    returnDefinitionFilingAttributes,
    isFilingMethodDisabled,
    filingMethodInfoMessage,
    filingMethodOptions,
    filingTypeOptions,
    isFilingTypeSelectDisabled,
    returnTypeOptions,
    isReturnTypeSelectDisabled,
    businessTypeOptions,
    isBusinessTypeSelectDisabled,
    areTaxTypeCheckboxesDisabled,
  } = useAddEditReturnDefinitionSetting({
    isEditing,
    taxReturns,
    taxReturnsDefinitions,
    columnsBlueprint,
  });

  const shouldDisplayDueDate = useShouldDisplayReturnsDueDates();

  const { data: returnStatusOption } = useQueryReturnStatus({
    filingMethod: values.filingMethod,
    efileStatus: values.efileStatus,
    returnStatus: values.returnStatus,
  });

  const {
    data: dueDateOptionsForConsolidation,
    isFetching: isLoadingDueDateOptionsForConsolidation,
  } = useQueryDueDateOptions({
    params: {
      taxYear,
      period,
      businessEntityId: values?.parentOrgId,
      jurisdictionId: values?.jurisdictionId,
      returnDefinitionId: values?.returnDefinitionId,
      formattedFiscalYearEnd: values?.taxYearEnding,
    },
    enabled: Boolean(
      shouldDisplayDueDate &&
        taxYear &&
        period &&
        values?.parentOrgId &&
        values?.jurisdictionId &&
        values?.returnDefinitionId &&
        values?.taxYearEnding &&
        !errors?.taxYearEnding,
    ),
  });

  const {
    data: entitiesForFilingGroupsData,
    isLoading: isLoadingEntitiesForFilingGroupsData,
  } = useQueryEntitiesForFilingGroups({
    params: { taxYear, period, filingGroupIds: checkedFilingGroupIds },
    enabled: Boolean(taxYear && period && checkedFilingGroupIds.length),
  });

  const stateFilingFormOptions = useMemo(
    () =>
      values.jurisdictionId
        ? taxReturnsDefinitions
            ?.filter(
              ({ jurisdictionId, filingAttributes }) =>
                jurisdictionId === values.jurisdictionId &&
                (filingAttributes.FILING_TYPE_CONS_POST_APP ||
                  filingAttributes.FILING_TYPE_CONS_PRE_APP ||
                  filingAttributes.FILING_TYPE_CONS_UNITARY),
            )
            .map(({ name: label, id: value, isDefault, filingAttributesNumber }) => ({
              label,
              value,
              isDefault,
              filingAttributesNumber,
            }))
        : [],
    [values.jurisdictionId, taxReturnsDefinitions],
  );

  useEffect(() => {
    if (!isEditing) {
      const calcOptions = calcPreferencesDefinitions
        .filter(({ jurisdictionId }) => {
          return jurisdictionId === values.jurisdictionId;
        })
        .reduce(
          (acc, { displayName, defaultValue }) => ({
            ...acc,
            [displayName]: defaultValue,
          }),
          {},
        );
      setFieldValue(CALC_OPTIONS_KEY, calcOptions || {});
    }
  }, [calcPreferencesDefinitions, isEditing, values.jurisdictionId]);

  useEffect(() => {
    if (
      isEditing ||
      !shouldDisplayDueDate ||
      !dueDateOptionsForConsolidation?.length ||
      values.dueDateType
    ) {
      return;
    }

    setFieldValue('dueDateType', getDefaultDueDateType(dueDateOptionsForConsolidation));
  }, [isEditing, setFieldValue, dueDateOptionsForConsolidation, shouldDisplayDueDate, values]);

  useEffect(() => {
    if (!isEditing) {
      setFieldValue('returnDefinitionId', '');
      setFieldValue('filingMethod', '');
    }
  }, [setFieldValue, isEditing, values.jurisdictionId]);

  useEffect(() => {
    if (isEditing) {
      return;
    }

    if (stateFilingFormOptions.length === 1) {
      setFieldValue('returnDefinitionId', stateFilingFormOptions[0].value);
      return;
    }

    const stateFilingForm = stateFilingFormOptions?.find(({ isDefault }) => isDefault)?.value;
    if (!stateFilingForm) {
      return;
    }

    setFieldValue(
      'returnDefinitionId',
      stateFilingFormOptions?.find(({ isDefault }) => isDefault)?.value,
    );
  }, [setFieldValue, isEditing, stateFilingFormOptions]);

  const returnDefinitionIdChanged = useMemo(
    () => (isEditing ? initialValues.returnDefinitionId !== values.returnDefinitionId : false),
    [isEditing, values.returnDefinitionId, initialValues.returnDefinitionId],
  );

  const filingGroupsIdsChanged = useMemo(() => {
    if (!isEditing) {
      return false;
    }
    return values.filingGroupIds.length !== initialValues.filingGroupIds.length
      ? true
      : values.filingGroupIds.filter(x => !initialValues.filingGroupIds.includes(x)).length > 0;
  }, [isEditing, initialValues.filingGroupIds, values.filingGroupIds]);

  const isLoadingReturnOptions =
    isLoadingTaxReturnsDefinitionsJurisdictionOptions || isLoadingTaxReturnsDefinitions;

  const consolidationIdsForValidation = useSelector(consolidationsForValidationSelector);
  useManualValidateForm(
    consolidationsSchemas.getAddEditConsolidationFormSchema({
      consolidationIds: consolidationIdsForValidation,
      isEditing,
      shouldDisplayDueDate,
      dueDateOption: dueDateOptionsForConsolidation,
      entitiesOption: entitiesForFilingGroupsData,
      filingTypeOptions,
      returnTypeOptions,
      businessTypeOptions,
    }),
  );

  const renderBanner = useCallback(() => {
    if (!isEditing || (!filingGroupsIdsChanged && !returnDefinitionIdChanged)) {
      return;
    }

    const bannerContent =
      filingGroupsIdsChanged && !returnDefinitionIdChanged
        ? filingGroupChangeMessage
        : returnDefinitionIdChangedMessage;

    return <Banner status="warning" content={bannerContent} />;
  }, [filingGroupsIdsChanged, returnDefinitionIdChanged, isEditing]);

  return (
    <AddEditReturnWrapperContextProvider
      values={{
        // field in page 1
        isFilingMethodDisabled,
        filingMethodInfoMessage,
        filingMethodOptions,

        // fields in page 3
        filingTypeOptions,
        isFilingTypeSelectDisabled,
        returnTypeOptions,
        isReturnTypeSelectDisabled,
        businessTypeOptions,
        isBusinessTypeSelectDisabled,
        areTaxTypeCheckboxesDisabled,
      }}
    >
      <>
        <TabWrapper isActiveTab={activeTab === TabsTypes.RETURN_DETAILS}>
          <AddEditReturnOptionsForm
            isEditing={isEditing}
            isLoading={isLoadingReturnOptions}
            stateFilingFormOptions={stateFilingFormOptions}
            taxReturnsDefinition={taxReturnsDefinition}
            taxReturnsDefinitionsJurisdictionOptions={taxReturnsDefinitionsJurisdictionOptions}
            returnStatusOption={returnStatusOption}
            onNextButtonClick={onNextButtonClick}
            renderBanner={renderBanner}
          />
        </TabWrapper>
        <TabWrapper isActiveTab={activeTab === TabsTypes.FILING_GROUPS}>
          <AddEditConsolOptionsForm
            isEditing={isEditing}
            dueDateOptionsForConsolidation={dueDateOptionsForConsolidation || []}
            isLoadingDueDateOptionsForConsolidation={isLoadingDueDateOptionsForConsolidation}
            entitiesForFilingGroupsData={entitiesForFilingGroupsData}
            isLoadingEntitiesForFilingGroupsData={isLoadingEntitiesForFilingGroupsData}
            checkedFilingGroupIds={checkedFilingGroupIds}
            setCheckedFilingGroupIds={setCheckedFilingGroupIds}
            onNextButtonClick={onNextButtonClick}
            onBackButtonClick={onBackButtonClick}
            renderBanner={renderBanner}
          />
        </TabWrapper>
        <TabWrapper isActiveTab={activeTab === TabsTypes.CALC_PREFERENCES}>
          <AddEditCalcOptionsForm
            isEditing={isEditing}
            taxReturnsDefinition={taxReturnsDefinition}
            taxReturnColumnsBlueprint={columnsBlueprint}
            returnDefinitionFilingAttributes={returnDefinitionFilingAttributes}
            onBackButtonClick={onBackButtonClick}
            renderBanner={renderBanner}
          />
        </TabWrapper>
      </>
    </AddEditReturnWrapperContextProvider>
  );
};

export default AddEditReturnFormWrapper;
