import { useMemo } from 'react';
import { CompilationResult, CompilationInfo } from '@tls/slt-types';

import { CompileInfoTypes } from '../constants';
import {
  isInitialCompileNeeded,
  isCompileNeeded,
  noExpressionsFound,
  isUpToDate,
  isEveryCompilationResultOfWarningType,
} from '../utils';
import {
  ExpressionsTypes,
  LastSuccessfulCompileTypes,
} from '../compileExpressions/latestCalculationCompileInfo/constants';

interface UseCompileInfoTextsProps {
  compilationInfo?: CompilationInfo | null;
  compilationResult?: CompilationResult[] | null;
  validationDate?: string;
}

const useCompileInfoTexts = ({
  compilationInfo,
  compilationResult = null,
  validationDate = '',
}: UseCompileInfoTextsProps) => {
  const compileInfoText = useMemo(() => {
    if (!compilationInfo) {
      return '';
    }

    if (compilationResult) {
      if (compilationResult.length && isEveryCompilationResultOfWarningType(compilationResult)) {
        return CompileInfoTypes.FIX_COMPILE_WARNINGS;
      }
      if (compilationResult.length) {
        return CompileInfoTypes.FIX_COMPILE_ERRORS;
      }
    }

    if (isCompileNeeded(compilationInfo)) {
      return CompileInfoTypes.COMPILE_NEEDED;
    }

    if (noExpressionsFound(compilationInfo)) {
      return CompileInfoTypes.EXPRESSION_NEEDED;
    }

    if (isInitialCompileNeeded(compilationInfo)) {
      return CompileInfoTypes.COMPILE_NEEDED;
    }

    if (isUpToDate(compilationInfo)) {
      return CompileInfoTypes.COMPILE_UP_TO_DATE;
    }
  }, [compilationInfo, compilationResult]);

  const expressionsText = useMemo(() => {
    if (!compilationInfo) {
      return '';
    }

    if (isInitialCompileNeeded(compilationInfo)) {
      return ExpressionsTypes.EXPRESSIONS_EXIST;
    }
    if (isCompileNeeded(compilationInfo)) {
      return isUpToDate(compilationInfo)
        ? ExpressionsTypes.UP_TO_DATE
        : `${compilationInfo.changedExpressions} ${ExpressionsTypes.CHANGED_SINCE_LAST_SUCCESSFUL_COMPILE}`;
    }
    if (noExpressionsFound(compilationInfo)) {
      return ExpressionsTypes.NO_EXPRESSIONS_FOUND;
    }
    if (compilationResult) {
      if (!compilationResult?.length || isEveryCompilationResultOfWarningType(compilationResult)) {
        return ExpressionsTypes.UP_TO_DATE;
      }
      if (!compilationInfo.lastCompiled && compilationInfo.totalExpressions) {
        return ExpressionsTypes.EXPRESSIONS_EXIST;
      }
      return `${compilationInfo.changedExpressions} ${ExpressionsTypes.CHANGED_SINCE_LAST_SUCCESSFUL_COMPILE}`;
    }
    if (isUpToDate(compilationInfo)) {
      return ExpressionsTypes.UP_TO_DATE;
    }

    return '';
  }, [compilationInfo, compilationResult]);

  const lastSuccessfulCompileText = useMemo(() => {
    if (!compilationInfo) {
      return '';
    }

    if (
      compilationResult?.length &&
      !isEveryCompilationResultOfWarningType(compilationResult) &&
      (compilationInfo.lastCompiled || !compilationInfo.totalExpressions)
    ) {
      return compilationInfo.lastCompiled;
    }
    if (compilationResult && isEveryCompilationResultOfWarningType(compilationResult)) {
      return validationDate || compilationInfo.lastCompiled;
    }
    if (noExpressionsFound(compilationInfo)) {
      return LastSuccessfulCompileTypes.N_A;
    }
    if (compilationResult?.length) {
      return '';
    }
    if (isInitialCompileNeeded(compilationInfo)) {
      return LastSuccessfulCompileTypes.INITIAL_COMPILE_NEEDED;
    }

    return compilationInfo.lastCompiled;
  }, [compilationInfo, compilationResult, validationDate]);

  const dataItemsText = useMemo(
    () =>
      (compilationInfo?.changedDataItems || 0) > 0
        ? `${compilationInfo?.changedDataItems} ${ExpressionsTypes.CHANGED_SINCE_LAST_SUCCESSFUL_COMPILE}`
        : ExpressionsTypes.UP_TO_DATE,
    [compilationInfo?.changedDataItems],
  );

  return { compileInfoText, expressionsText, lastSuccessfulCompileText, dataItemsText };
};

export default useCompileInfoTexts;
