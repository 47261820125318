import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import getColumnBlueprintBasedColumnDefinitions from '../../../shared/columnDefinitions/columnsBlueprint.columnDefinition';
import { defaultSideBarWithColumnsToolPanel } from '../../../shared/displayComponents/agGrid/constants';
import { columnBlueprintHeaderOptions } from '../../../shared/columnDefinitions/constants';
import columnBlueprintStyles from '../../../shared/styles/columnBlueprintStyles.module.scss';
import {
  filingTypeIdSelector,
  taxYearSelector,
  periodSelector,
} from '../../../shared/store/selectors';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';
import { fetchTaxRates, deleteTaxRate } from '../store/actions/taxRates';
import { jurisdictionIdSelector, isFetchingContextSelector } from '../store/selectors/shared';
import {
  taxRatesSelector,
  taxRatesColumnsBlueprintSelector,
  isFetchingTaxRatesSelector,
} from '../store/selectors/taxRates';

import getColumnDefinitions from './taxRates.columnDefinitions';
import styles from './taxRates.module.scss';

const TaxRates = ({ hasUserPermissionsToEdit, ...agGridProps }) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const filingTypeId = useSelector(filingTypeIdSelector);
  const taxRates = useSelector(taxRatesSelector);
  const isFetchingContext = useSelector(isFetchingContextSelector);
  const isFetchingTaxRates = useSelector(isFetchingTaxRatesSelector);
  const columnsBlueprint = useSelector(taxRatesColumnsBlueprintSelector);

  const isContextReady = taxYear && period && jurisdictionId && filingTypeId;

  useEffect(() => {
    if (isContextReady) {
      dispatch(fetchTaxRates({ taxYear, period, jurisdictionId, filingTypeId }));
    }
  }, [dispatch, isContextReady, taxYear, period, jurisdictionId, filingTypeId]);

  const clearSelection = useCallback(() => null, []);

  const openModalForDelete = useCallback(
    row => {
      dispatch(
        showConfirmModal({
          title: 'Delete tax rate?',
          text: 'Are you sure you want to delete this tax rate?',
          confirmCallback: async () => {
            await dispatch(
              deleteTaxRate({
                taxYear,
                period,
                jurisdictionId,
                filingTypeId,
                taxType: row.taxType,
                startRange: row.startRange,
                stopRange: row.stopRange,
                filingAttributes: row.filingAttributes,
                calcOptions: row.calcOptions,
              }),
            );
            dispatch(fetchTaxRates({ taxYear, period, jurisdictionId, filingTypeId }));
            clearSelection();
          },
        }),
      );
    },
    [dispatch, clearSelection, taxYear, period, jurisdictionId, filingTypeId],
  );

  const columnDefinitions = useMemo(
    () => [
      ...getColumnDefinitions({
        isInEditMode: hasUserPermissionsToEdit,
        onDeleteIconClick: openModalForDelete,
      }),
      ...getColumnBlueprintBasedColumnDefinitions({ columnsBlueprint, filingTypeId }),
    ],
    [hasUserPermissionsToEdit, openModalForDelete, columnsBlueprint, filingTypeId],
  );

  return (
    <div
      className={`row grid-row ${styles.taxRatesContentWrapper} ${columnBlueprintStyles.gridContainer}`}
    >
      <div className="col">
        <AgGrid
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingContext || isFetchingTaxRates}
          rowData={taxRates}
          sideBar={defaultSideBarWithColumnsToolPanel}
          {...columnBlueprintHeaderOptions}
          {...agGridProps}
          headerHeight={50}
          groupHeaderHeight={30}
          suppressColumnVirtualisation={false}
        />
      </div>
    </div>
  );
};

TaxRates.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default TaxRates;
