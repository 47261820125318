import React, { useCallback, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { filingTypes } from '@tls/state-helpers';
import { Routes } from '@common-packages/routes-definitions';

import {
  customerPermissionsSelector,
  taxYearSelector,
  periodSelector,
  entitiesOptionsSelector,
  isFetchingEntitiesSelector,
} from '../../shared/store/selectors';
import useFetch from '../../shared/hooks/useFetch.hook';
import { useQueryConsol } from '../../shared/queries/consolidationsMaintenance';
import { useQueryTaxReturnsReturnDefinitions } from '../../shared/queries/taxReturns';
import Loading from '../../shared/displayComponents/loading.component';
import { FilingMethods } from '../../shared/enums';
import hasRequiredPermissionsToEdit from '../../shared/authorization/hasRequiredPermissionsToEdit';
import { FilingMethodsMap } from '../../shared/consolidations/constants';

import { fetchFilingGroups as fetchFilingGroupsApiAction } from './store/api';
import EditConsolidationForm from './editConsolidationForm.component';
import { consolidationPropType } from './propTypes';
import styles from './consolidationForm.module.scss';
import { RETURN_TYPE_FIELD } from './constants';

const EditConsolidation = ({
  selectedConsolidation,
  saveConsolidationAndFilingAttributes,
  title,
  isSavingConsolidationAndFilingAttributes,
}) => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const entitiesOptions = useSelector(entitiesOptionsSelector);
  const isFetchingEntities = useSelector(isFetchingEntitiesSelector);

  const customerPermissions = useSelector(customerPermissionsSelector);
  const hasTaxReturnsPermissions = hasRequiredPermissionsToEdit(
    customerPermissions,
    Routes.setupTaxReturns.MAIN,
  );

  const { data: consolidationData, isFetching: isFetchingConsolidationData } = useQueryConsol({
    params: {
      taxYear,
      period,
      consolidationId: selectedConsolidation.consolidationId,
      jurisdictionId: selectedConsolidation.jurisdictionId,
    },
    enabled: Boolean(
      taxYear &&
        period &&
        selectedConsolidation.consolidationId &&
        selectedConsolidation.jurisdictionId,
    ),
  });

  const initialReturnTypeValue =
    consolidationData?.data &&
    Object.keys(consolidationData?.data).filter(
      key => key.includes('RETURN_TYPE') && consolidationData?.data[key] === true,
    )[0];

  const initialData = useMemo(
    () => ({
      ...(consolidationData?.data || {}),
      filingMethod: consolidationData?.data.filingMethod || FilingMethods.NONE,
      [RETURN_TYPE_FIELD]: initialReturnTypeValue,
      // included for validation - all consols are given a taxYearEnding upon creation
      taxYearEnding: true,
    }),
    [consolidationData?.data, initialReturnTypeValue],
  );

  const {
    data: returnDefinitions,
    isFetching: isFetchingReturnDefinitions,
  } = useQueryTaxReturnsReturnDefinitions({
    params: {
      taxYear,
      filingTypeId: filingTypes.CONSOLIDATED,
      jurisdictionId: selectedConsolidation.jurisdictionId,
    },
    enabled: Boolean(taxYear && selectedConsolidation.jurisdictionId && hasTaxReturnsPermissions),
  });

  const {
    data: filingGroups,
    fetch: fetchFilingGroups,
    isFetching: isFetchingFilingGroups,
  } = useFetch({ action: fetchFilingGroupsApiAction });

  const filingGroupsOptions = useMemo(
    () =>
      filingGroups
        ? filingGroups.map(({ filingGroupId }) => ({
            label: filingGroupId,
            value: filingGroupId,
          }))
        : [],
    [filingGroups],
  );

  const handleParentEntityChange = useCallback(
    parentEntity => {
      if (parentEntity && taxYear && period) {
        fetchFilingGroups({
          taxYear,
          period,
          parentEntityId: parentEntity.value,
        });
      }
    },
    [fetchFilingGroups, taxYear, period],
  );

  useEffect(() => {
    handleParentEntityChange({ value: selectedConsolidation.businessEntityId });
  }, [handleParentEntityChange, selectedConsolidation.businessEntityId]);

  const renderForm = useCallback(
    () => (
      <EditConsolidationForm
        isSavingConsolidationAndFilingAttributes={isSavingConsolidationAndFilingAttributes}
        filingAttributes={consolidationData?.filingAttributesDefintions}
        consolidationData={consolidationData}
        entitiesOptions={entitiesOptions}
        isFetchingEntities={isFetchingEntities}
        onParentEntityChange={handleParentEntityChange}
        filingGroups={filingGroupsOptions}
        isFetchingFilingGroups={isFetchingFilingGroups}
        returnDefinitions={returnDefinitions}
        hasTaxReturnsPermissions={hasTaxReturnsPermissions}
      />
    ),
    [
      isSavingConsolidationAndFilingAttributes,
      consolidationData,
      entitiesOptions,
      isFetchingEntities,
      handleParentEntityChange,
      filingGroupsOptions,
      isFetchingFilingGroups,
      returnDefinitions,
      hasTaxReturnsPermissions,
    ],
  );

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      if (!values[RETURN_TYPE_FIELD]) {
        values[RETURN_TYPE_FIELD] = initialReturnTypeValue;
      }

      const formValuesWithUpdatedReturnTypes = Object.keys(values).reduce((acc, key) => {
        if (key === RETURN_TYPE_FIELD) {
          return { ...acc };
        }
        if (key.includes('RETURN_TYPE')) {
          return { ...acc, [key]: key === values[RETURN_TYPE_FIELD] };
        }
        return { ...acc, [key]: values[key] };
      }, {});

      await saveConsolidationAndFilingAttributes({
        ...formValuesWithUpdatedReturnTypes,
        filingMethod: FilingMethodsMap[formValuesWithUpdatedReturnTypes.filingMethod],
        oldParentOrgId:
          values?.parentOrgId !== initialData?.parentOrgId ? initialData?.parentOrgId : null,
      });
      setSubmitting(false);
    },
    [saveConsolidationAndFilingAttributes, initialReturnTypeValue, initialData?.parentOrgId],
  );

  const isLoading = isFetchingConsolidationData || isFetchingReturnDefinitions;

  return (
    <>
      <div className="a-h4">Edit Consolidation</div>
      <hr />
      {title && (
        <div className="row">
          <div className="col">
            <div className="a-h5">{title}</div>
          </div>
        </div>
      )}
      <Loading isLoading={isLoading} className={styles.bottomSlideUpSpinner}>
        <div className="row">
          <div className="col">
            <Formik
              onSubmit={onSubmit}
              initialValues={initialData}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {renderForm}
            </Formik>
          </div>
        </div>
      </Loading>
    </>
  );
};

EditConsolidation.propTypes = {
  title: PropTypes.string,
  isSavingConsolidationAndFilingAttributes: PropTypes.bool.isRequired,
  saveConsolidationAndFilingAttributes: PropTypes.func.isRequired,
  selectedConsolidation: consolidationPropType.isRequired,
};

EditConsolidation.defaultProps = {
  title: '',
};

export default EditConsolidation;
