import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import { SELECT_TAX_YEAR } from '../../../shared/store/types';

import { fetchAttachmentDefinitionsTypes, updateAttachmentDefinitionsTypes } from './types';

export const initialState = {
  attachmentDefinitions: [],
  isFetchingAttachmentDefinitions: false,
  isUpdatingAttachmentDefinitions: false,
};

const attachmentDefinitionsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchAttachmentDefinitionsTypes,
      payloadKey: 'attachmentDefinitions',
      fetchingKey: 'isFetchingAttachmentDefinitions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateAttachmentDefinitionsTypes,
      fetchingKey: 'isUpdatingAttachmentDefinitions',
    }),
    [SELECT_TAX_YEAR]: () => initialState,
  },
});

export default attachmentDefinitionsReducer;
