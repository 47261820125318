import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';

import { fetchAttachmentDetailsTypes } from './types';

export const initialState = {
  attachmentDetails: [],
  isFetchingAttachmentDetails: false,
};

const viewPrintAttachmentDetailsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchAttachmentDetailsTypes,
      payloadKey: 'attachmentDetails',
      fetchingKey: 'isFetchingAttachmentDetails',
    }),
  },
});

export default viewPrintAttachmentDetailsReducer;
