import { httpGetAndParse, httpPut, httpPost } from '../../utils/fetchService';

export const fetchProfilesOptions = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/filing-decisions/profile-options/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Finding profiles failed',
  });

export const fetchEntityFilingDecisions = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/filing-decisions/entity/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Finding filing decisions failed',
  });

export const fetchEntityFilingGroups = ({ taxYear, period, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/filing-decisions/entity/filing-group-options/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Finding filing groups failed',
  });

export const updateEntityFilingDecisions = ({ taxYear, period, orgId, rowsToUpdate }) =>
  httpPut({
    route: `/api/filing-decisions/entity/${taxYear}/${period}/${orgId}`,
    errorMessage: 'Updating filing decisions for entity failed',
    body: { rowsToUpdate },
  });

export const fetchConsolidationFilingDecisions = ({
  taxYear,
  period,
  contextDataId: consolidationId,
  jurisdictionId,
}) =>
  httpGetAndParse({
    route: `/api/filing-decisions/consolidation/${taxYear}/${period}/${consolidationId}/${jurisdictionId}`,
    errorMessage: 'Fetching filing decisions for consolidation failed',
  });

export const fetchConsolidationsFilingGroups = ({ taxYear, period, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/filing-decisions/consolidation/filing-group-options/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Fetching filing groups for filing group filing decisions failed',
  });

export const updateConsolidationFilingDecisions = ({
  taxYear,
  period,
  jurisdictionId,
  rowsToUpdate,
}) =>
  httpPut({
    route: `/api/filing-decisions/consolidation/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Updating filing decisions for filing group failed',
    body: { rowsToUpdate },
  });

export const fetchFilingDecisionsAmountDetails = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  categoryName,
}) =>
  httpGetAndParse({
    route: `/api/filing-decisions/amounts/${taxYear}/${period}/${orgId}/${jurisdictionId}/${categoryName}`,
    errorMessage: 'Finding filing decisions failed',
  });

export const createNexusNote = ({
  taxYear,
  orgId,
  preparedBy,
  jurisdictionId,
  rolloverToNextYear,
  note,
}) =>
  httpPost({
    route: `/api/filing-decisions/${taxYear}/${orgId}/${jurisdictionId}/nexus-note`,
    errorMessage: 'Adding note failed',
    body: {
      preparedBy,
      rolloverToNextYear,
      note,
    },
  });

export const fetchNexusNote = ({ taxYear, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/filing-decisions/${taxYear}/${orgId}/${jurisdictionId}/nexus-note`,
    errorMessage: 'Finding nexus note failed',
  });

export const updateNexusNote = ({
  taxYear,
  orgId,
  jurisdictionId,
  noteId,
  note,
  rolloverToNextYear,
  shortDescription,
}) =>
  httpPut({
    route: `/api/filing-decisions/${taxYear}/${orgId}/${jurisdictionId}/nexus-note/${noteId}`,
    errorMessage: 'Updating nexus note failed',
    body: {
      note,
      rolloverToNextYear,
      shortDescription,
    },
  });
