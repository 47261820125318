import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { OverridableFieldPropTypes } from '../../overridableForm.propTypes';
import { CHECKBOX_CHECKED, CHECKBOX_UNCHECKED } from '../../../constants';
import { getCurrentValue } from '../../utils/getCurrentValue';

import styles from './overridableFormCheckbox.module.scss';

const OverridableFormCheckbox = ({
  field: { name, value },
  className = '',
  disabled = false,
  handleFieldUpdateAndFormatting,
  handleFocus,
}) => {
  const { formattedData, isOverridden } = value;

  const currentValue = getCurrentValue(value);

  const isChecked = currentValue === CHECKBOX_CHECKED;

  const handleChange = useCallback(
    event => {
      const { checked } = event.target;
      const newValue = checked ? CHECKBOX_CHECKED : CHECKBOX_UNCHECKED;

      handleFieldUpdateAndFormatting({
        newValue,
      });
    },
    [handleFieldUpdateAndFormatting],
  );

  return useMemo(
    () => (
      <label className={`${styles.overridableFormCheckboxWrapper} ${className}`}>
        <span className={styles.overridableCheckbox}>
          <input
            className={styles.overridableCheckboxInput}
            name={name}
            type="checkbox"
            value={currentValue}
            checked={isChecked}
            disabled={disabled}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          <span className={styles.overridableCheckboxInner}>
            <span
              className={classNames(styles.overridableCheckboxText, {
                [styles.overriddenCheckboxText]: isOverridden,
              })}
            >
              {formattedData}
            </span>
          </span>
        </span>
      </label>
    ),
    [
      className,
      currentValue,
      disabled,
      formattedData,
      handleChange,
      handleFocus,
      isChecked,
      isOverridden,
      name,
    ],
  );
};

OverridableFormCheckbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: OverridableFieldPropTypes.isRequired,
  }),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleFieldUpdateAndFormatting: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
};

export default OverridableFormCheckbox;
