import React from 'react';
import PropTypes from 'prop-types';

import DataInfo from '../../shared/displayComponents/dataInfo/dataInfo.component';

const FailedUpload = ({ isValidationError = false }) => {
  const textToRender = isValidationError
    ? 'Data import failed due to validation errors.'
    : 'Data import failed due to unexpected reason, please try again later.';
  return <DataInfo textToRender={textToRender} />;
};

FailedUpload.propTypes = {
  isValidationError: PropTypes.bool,
  isGridVisible: PropTypes.bool,
  fetchLastImportDataPage: PropTypes.func.isRequired,
};

export default FailedUpload;
