import { TEMPORARY_REDIRECT, FORBIDDEN, UNAUTHORIZED, OK, MULTIPLE_CHOICES } from 'http-status-codes';
import { useRef, useReducer, useCallback } from 'react';
import { useDispatch } from 'react-redux';

const redirectToAuthorize = (urlBase, response) => {
  const apiURL = "".concat(urlBase, "/api/authenticate");
  return window.location.replace(apiURL) || response;
};
const redirectToAccessDenied = (urlBase, response) => {
  const currentURL = window.location.href;
  const accessDeniedPageUrl = "".concat(window.location.origin).concat(urlBase, "/access-denied");
  if (currentURL !== accessDeniedPageUrl) {
    return window.location.replace(accessDeniedPageUrl);
  }
  return response;
};
const redirectToTargetUrl = async (urlBase, response) => {
  const {
    redirectUrl
  } = await response.json();
  if (typeof redirectUrl !== 'string') {
    console.error('Cannot perform redirect: redirectUrl not provided in response object.'); // eslint-disable-line no-console
    return;
  }
  if (!redirectUrl.startsWith('/')) {
    console.error('Cannot perform redirect: redirectUrl must be a relative url.'); // eslint-disable-line no-console
    return;
  }
  const currentURL = window.location.href;
  const targetUrl = "".concat(window.location.origin).concat(urlBase).concat(redirectUrl);
  if (currentURL !== targetUrl) {
    window.location.replace(targetUrl);
  }
  return response;
};
const fetchServiceCreator = function () {
  let urlBase = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let ignoreRedirectionPaths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return async function (url) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const params = {
      ...config,
      credentials: 'same-origin'
    };
    if (urlBase && !urlBase.startsWith('/')) {
      throw new Error("Invalid url base. Must start with '/'");
    }
    try {
      const response = await fetch("".concat(urlBase).concat(url), params);
      if (ignoreRedirectionPaths.some(path => window.location.href.includes(path))) {
        return response;
      }
      switch (response.status) {
        case UNAUTHORIZED:
          return redirectToAuthorize(urlBase, response);
        case FORBIDDEN:
          return redirectToAccessDenied(urlBase, response);
        case TEMPORARY_REDIRECT:
          return redirectToTargetUrl(urlBase, response);
        default:
          return response;
      }
    } catch (error) {
      if (error instanceof DOMException) {
        // Fallback case for Unauthorized responses using IE.
        // https://github.com/github/fetch/issues/409
        return redirectToAuthorize();
      }
      throw error;
    }
  };
};
const postHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};
const deleteHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

class RequestError extends Error {}
class FetchingError extends RequestError {
  constructor(message, status, details, code, hasAdditionalInfo) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchingError);
    }
    this.isFetchingError = true;
    this.status = status;
    this.details = details;
    this.code = code;
    this.hasAdditionalInfo = hasAdditionalInfo;
  }
}
class UnauthorizedError extends RequestError {
  constructor(message) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnauthorizedError);
    }
    this.isAuthorizationError = true;
  }
}
class ValidationError extends RequestError {
  constructor(message) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }
    this.isValidationError = true;
  }
}
var ErrorsFactory = {
  FetchingError: (message, status, details, code, hasAdditionalInfo) => new FetchingError(message, status, details, code, hasAdditionalInfo),
  UnauthorizedError: message => new UnauthorizedError(message),
  ValidationError: message => new ValidationError(message)
};

const parseAsJson = response => {
  if (response.status === TEMPORARY_REDIRECT) {
    return null;
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }

  // disabling because eslint thinks that default exports are the same as named exports
  // this is not the case because deafult exports are functions and named exports are classes
  // eslint-disable-next-line import/no-named-as-default-member
  throw ErrorsFactory.FetchingError('Cannot parse response as JSON', response.status);
};
const handleErrors = _ref => {
  let {
    errorMessage,
    parseResponseErrorMessage = false
  } = _ref;
  return response => {
    const responseSuccessful = response.status >= OK && response.status < MULTIPLE_CHOICES;
    if (responseSuccessful || response.status === TEMPORARY_REDIRECT) {
      return response;
    }
    if (response.status === UNAUTHORIZED) {
      // disabling because eslint thinks that default exports are the same as named exports
      // this is not the case because deafult exports are functions and named exports are classes
      // eslint-disable-next-line import/no-named-as-default-member
      throw ErrorsFactory.UnauthorizedError(errorMessage);
    }
    if (parseResponseErrorMessage) {
      return parseAsJson(response).then(responseError => {
        const hasAdditionalInfo = true;

        // disabling because eslint thinks that default exports are the same as named exports
        // this is not the case because deafult exports are functions and named exports are classes
        // eslint-disable-next-line import/no-named-as-default-member
        throw ErrorsFactory.FetchingError(responseError && responseError.message ? responseError.message : errorMessage, response.status, responseError && responseError.details, responseError && responseError.code, hasAdditionalInfo);
      });
    }

    // disabling because eslint thinks that default exports are the same as named exports
    // this is not the case because deafult exports are functions and named exports are classes
    // eslint-disable-next-line import/no-named-as-default-member
    throw ErrorsFactory.FetchingError(errorMessage, response.status, response.details, response.code);
  };
};
const extractToken = _ref2 => {
  let {
    key,
    headers,
    location = window.location,
    storage = window.localStorage
  } = _ref2;
  const params = new URLSearchParams(location.search);
  if (params.has(key)) {
    return params.get(key);
  }
  if (headers && headers.has(key)) {
    return headers.get(key);
  }
  if (key in storage) {
    return storage.getItem(key);
  }
  return null;
};
const storeToken = _ref3 => {
  let {
    key,
    storage = window.localStorage
  } = _ref3;
  return response => {
    const renewedToken = extractToken({
      key,
      storage,
      headers: response.headers
    });
    storage.setItem(key, renewedToken);
    return response;
  };
};

const FETCHING_START = 'FETCHING_START';
const FETCHING_SUCCESS = 'FETCHING_SUCCESS';
const FETCHING_FAIL = 'FETCHING_FAIL';
const RESET = 'RESET';
const initialState = {
  data: null,
  isFetching: false,
  fetchingError: false
};
const getReducer = initialData => (state, _ref) => {
  let {
    type,
    payload
  } = _ref;
  switch (type) {
    case FETCHING_START:
      return {
        ...state,
        fetchingError: false,
        isFetching: true,
        data: initialData
      };
    case FETCHING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload
      };
    case FETCHING_FAIL:
      return {
        ...state,
        fetchingError: true,
        isFetching: false,
        data: initialData
      };
    case RESET:
      return {
        ...state,
        data: initialData
      };
    default:
      throw new Error();
  }
};
const createUseFetchHook = function () {
  let {
    errorNotification = null,
    successNotification = null
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _ref2 => {
    let {
      action,
      initialData = null,
      successNotificationMessage = null,
      errorNotificationMessage = null,
      callErrorNotification = true,
      throwError = false
    } = _ref2;
    const dispatchReduxAction = useDispatch();
    const hookRequestIdentifier = useRef(null);
    const [{
      data,
      isFetching,
      fetchingError
    }, dispatchFetchAction] = useReducer(getReducer(initialData), {
      ...initialState,
      data: initialData
    });
    const fetch = useCallback(async function () {
      const requestIdentifier = Date.now();
      hookRequestIdentifier.current = requestIdentifier;
      try {
        dispatchFetchAction({
          type: FETCHING_START
        });
        const result = await action(...arguments);

        // SUPERSTATE WARNING - checking if this request is the most recent
        if (hookRequestIdentifier.current !== requestIdentifier) {
          return;
        }
        if (successNotification && successNotificationMessage) {
          dispatchReduxAction(successNotification(successNotificationMessage));
        }
        dispatchFetchAction({
          type: FETCHING_SUCCESS,
          payload: result
        });
        return result;
      } catch (error) {
        // SUPERSTATE WARNING - checking if this request is the most recent
        if (hookRequestIdentifier.current !== requestIdentifier) {
          return;
        }
        if (callErrorNotification && errorNotification) {
          dispatchReduxAction(errorNotification(errorNotificationMessage || error && error.message));
        }
        dispatchFetchAction({
          type: FETCHING_FAIL
        });
        if (throwError) {
          throw error;
        }
      }
    }, [dispatchReduxAction, action, callErrorNotification, successNotificationMessage, errorNotificationMessage]);
    const resetData = useCallback(() => dispatchFetchAction({
      type: RESET
    }), [dispatchFetchAction]);
    return {
      data,
      isFetching,
      fetchingError,
      fetch,
      resetData
    };
  };
};

export { FetchingError, RequestError, UnauthorizedError, ValidationError, createUseFetchHook, fetchServiceCreator as default, deleteHeaders, ErrorsFactory as errorsFactory, extractToken, handleErrors, parseAsJson, postHeaders, storeToken };
