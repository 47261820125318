import React, { useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { PRINT_SERVICE_PAGE_CONTAINER_ID } from '../../shared/constants';

import { getDomInputsData } from './utils/getDomInputsData';
import OverridableFormPage from './overridableFormPage.component';
import { DrillFieldPropTypes, OverridableFieldPropTypes } from './overridableForm.propTypes';
import OverridablePagesWrapper from './overridablePagesWrapper.component';
import styles from './overridableForm.module.scss';

const OverridableFormWrapper = ({
  fieldsValues,
  visiblePages = [],
  formRef,
  overridableFormWrapperRef,
  isFormDirty,
  setFormDirty,
  isUpdatingFormValues,
}) => {
  const [focusedElementName, setFocusedElementName] = useState(null);
  const htmlFormImageContainer = document.getElementById('page-container');

  const { pages, formData } = useMemo(
    () => getDomInputsData({ fieldsValues, containerId: PRINT_SERVICE_PAGE_CONTAINER_ID }),
    [fieldsValues],
  );

  const handleOverridableFormWrapperScroll = useCallback(
    ({ target }) => {
      const element = overridableFormWrapperRef.current;
      if (element) {
        element.scroll(target.scrollLeft, 0);
      }
    },
    [overridableFormWrapperRef],
  );

  const isPageVisible = useCallback(index => visiblePages.includes(index), [visiblePages]);

  useEffect(() => {
    if (htmlFormImageContainer) {
      htmlFormImageContainer.addEventListener('scroll', handleOverridableFormWrapperScroll);

      return () => {
        htmlFormImageContainer.removeEventListener('scroll', handleOverridableFormWrapperScroll);
      };
    }
  }, [handleOverridableFormWrapperScroll, htmlFormImageContainer]);

  const handleEnterPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        const enabledInputNodeList = overridableFormWrapperRef.current.querySelectorAll(
          'input:enabled',
        );

        for (const [i, node] of enabledInputNodeList.entries()) {
          if (node.name === focusedElementName) {
            const nextInput = enabledInputNodeList[i + 1];
            nextInput?.focus();
            break;
          }
        }
      }
    },
    [focusedElementName, overridableFormWrapperRef],
  );

  useEffect(() => {
    if (document) {
      document.addEventListener('keyup', handleEnterPress);

      return () => {
        document.removeEventListener('keyup', handleEnterPress);
      };
    }
  }, [handleEnterPress]);

  return (
    <div className={styles.overridableFormWrapper} ref={overridableFormWrapperRef}>
      <Formik
        initialValues={formData}
        innerRef={formRef}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <OverridablePagesWrapper isFormDirty={isFormDirty} setFormDirty={setFormDirty}>
          {Object.values(pages).map(
            ({ pageId, pageClassNames, fields, drillLinks, groupFields, isLandscape }, index) => (
              <OverridableFormPage
                key={pageId}
                pageId={pageId}
                fields={fields}
                drillLinks={drillLinks}
                groupFields={groupFields}
                pageClassNames={pageClassNames}
                isUpdatingFormValues={isUpdatingFormValues}
                isPageVisible={isPageVisible(index)}
                setFocusedElementName={setFocusedElementName}
                focusedElementName={focusedElementName}
                isLandscape={isLandscape}
              />
            ),
          )}
        </OverridablePagesWrapper>
      </Formik>
    </div>
  );
};

OverridableFormWrapper.propTypes = {
  fieldsValues: PropTypes.arrayOf(
    PropTypes.oneOfType([OverridableFieldPropTypes, DrillFieldPropTypes]),
  ),
  visiblePages: PropTypes.arrayOf(PropTypes.number),
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
    }),
  ]),
  overridableFormWrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
    }),
  ]),
  isFormDirty: PropTypes.bool.isRequired,
  setFormDirty: PropTypes.func.isRequired,
  isUpdatingFormValues: PropTypes.bool.isRequired,
};

export default OverridableFormWrapper;
