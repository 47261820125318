import {
  defaultColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../shared/columnDefinitions';
import { dateCellFormatter } from '../../shared/formatters';

const columnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Job Type',
    field: 'jobType',
    width: 200,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Status',
    field: 'status',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Created On',
    field: 'createdOn',
    valueFormatter: dateCellFormatter,
    width: 100,
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Finished On',
    field: 'finishedOn',
    valueFormatter: dateCellFormatter,
    width: 100,
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Result',
    field: 'result',
    width: 900,
  },
];

export default columnDefinitions;
