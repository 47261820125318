import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

const hasPermissionExecuteForceEntityBatchCalc = customerPermissions =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.FORCE_ENTITY_BATCH_CALC],
  });

export default hasPermissionExecuteForceEntityBatchCalc;
