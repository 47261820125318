import { Routes } from '@common-packages/routes-definitions';

import { LinkCellRendererFactory } from '../../../../shared/columnDefinitions/cellRenderers';

export const entityInformationDetailsLinkRenderer = LinkCellRendererFactory({
  getText: ({ value }) => value,
  getPathName: (_value, data) =>
    Routes.entityInformationDetails.compiledRoute({
      pageType: 'entity',
      groupOrEntityId: data.orgId,
      reportingPeriodId: data.reportingPeriod ?? '1',
    }),
});
