import React, { useCallback } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { federalReconciliationsSchemas } from '@common-packages/validators';

import Loading from '../../shared/displayComponents/loading.component';
import SlideIn from '../../shared/displayComponents/slideIn/slideIn.component';

import AddEditNoteForm from './AddEditNoteForm';

const formIntitialValues = {
  noteMessage: '',
};

const AddEditNoteSlideIn = ({
  isOpen,
  createNote,
  closeSlideIn,
  isFetchingData,
  federalReconciliationCategory,
}) => {
  const saveNote = useCallback(
    async ({ noteMessage }, { setSubmitting }) => {
      const data = {
        noteMessage,
        accountGroup: federalReconciliationCategory.accountGroup,
      };

      await createNote(data);

      setSubmitting(false);
      closeSlideIn();
    },
    [federalReconciliationCategory.accountGroup, createNote, closeSlideIn],
  );

  const renderForm = useCallback(
    formikProps => <AddEditNoteForm {...formikProps} closeSlideIn={closeSlideIn} />,
    [closeSlideIn],
  );

  const slideInTitle = 'Federal Reconciliation Note - Add';

  return (
    <SlideIn
      title={slideInTitle}
      isOpen={isOpen}
      width="420px"
      onRequestClose={closeSlideIn}
      ariaHideApp={false}
    >
      <Loading isLoading={isFetchingData}>
        <p>{federalReconciliationCategory.categoryDescription}</p>
        <Formik
          initialValues={formIntitialValues}
          validateOnBlur={false}
          validationSchema={federalReconciliationsSchemas.commonSchema}
          onSubmit={saveNote}
        >
          {renderForm}
        </Formik>
      </Loading>
    </SlideIn>
  );
};

AddEditNoteSlideIn.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  createNote: PropTypes.func.isRequired,
  closeSlideIn: PropTypes.func.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  federalReconciliationCategory: PropTypes.shape({
    accountGroup: PropTypes.string.isRequired,
    categoryDescription: PropTypes.string.isRequired,
  }),
};

export default AddEditNoteSlideIn;
