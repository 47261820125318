import {
  adminColumnDefinition,
  defaultColumnDefinitionWithFilter,
  centeredColumnDefinition,
  selectCellEditorParamsFactory,
} from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  selectOptionCellRendererFactory,
  NumberCellEditor,
  EditableCheckboxCellRenderer,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import { ALIAS_TYPE_OPTIONS } from '../../shared/constants';
import { defaultAgRichSelectCellEditor } from '../../shared/columnDefinitions/cellEditor';

import { BOOLEAN_DATA_TYPE, STRING_DATA_TYPE } from './constants';

const checkboxOptions = [
  { label: 'True', value: 1 },
  { label: 'False', value: 0 },
];

const filterFormatterFactory = options => ({ value }) =>
  (options || []).find(option => String(option?.value) === String(value))?.label || null;

const getColumnDefinitions = ({
  onDeleteIconClick,
  onExpressionBuilderIconClick,
  dataTypes,
  isFetchingDataTypes,
  selectionLists,
  isFetchingSelectionLists,
  checkboxGroups,
  isFetchingCheckboxGroups,
  hasPermissionToManageAliases,
}) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: () => false,
    shouldShowExpressionBuilderIcon: ({ hasExpression }) => hasExpression,
    shouldShowExpressionBuilderAddIcon: ({ hasExpression }) => !hasExpression,
    onDeleteIconClick,
    onExpressionBuilderIconClick,
  });

  const checkboxCellClassRules = {
    ...defaultColumnDefinitionWithFilter.cellClassRules,
    // using as a rule instead of `cellClass` because in this case `cellClass` causes these cells
    // to render inconsistently (sometimes without the class)
    'editable-cell': () => true,
  };

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Error',
      field: 'error',
      colId: 'errorCol',
      width: 100,
      hide: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Order',
      field: 'displayOrder',
      editable: true,
      cellEditor: NumberCellEditor,
      width: 60,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
      sort: 'asc',
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name',
      field: 'name',
      width: 200,
      editable: true,
      cellEditor: TextCellEditor,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: 'description',
      width: 400,
      editable: true,
      cellEditor: TextCellEditor,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Display Name',
      field: 'displayName',
      editable: true,
      cellEditor: TextCellEditor,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Data Type',
      field: 'dataType',
      editable: !isFetchingDataTypes,
      cellRenderer: selectOptionCellRendererFactory(dataTypes),
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(dataTypes),
      filterParams: {
        valueFormatter: filterFormatterFactory(dataTypes),
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Data Length',
      field: 'dataLength',
      editable: ({ data }) => data.dataType === STRING_DATA_TYPE,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Alias Name',
      field: 'aliasName',
      editable: hasPermissionToManageAliases,
      cellEditorFramework: TextCellEditor,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Alias Type',
      field: 'aliasType',
      editable: ({ data }) => hasPermissionToManageAliases && data.aliasName,
      cellRendererFramework: selectOptionCellRendererFactory(ALIAS_TYPE_OPTIONS),
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(ALIAS_TYPE_OPTIONS),
      filterParams: {
        valueFormatter: filterFormatterFactory(ALIAS_TYPE_OPTIONS),
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Checkbox Group',
      field: 'checkboxGroupId',
      editable: ({ data }) => data.dataType === BOOLEAN_DATA_TYPE && !isFetchingCheckboxGroups,
      cellRenderer: selectOptionCellRendererFactory(checkboxGroups),
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(checkboxGroups, {}, true),
      filterParams: {
        valueFormatter: filterFormatterFactory(checkboxGroups),
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Selection List',
      field: 'selectionListId',
      editable: ({ data }) => data.dataType === STRING_DATA_TYPE && !isFetchingSelectionLists,
      cellRenderer: selectOptionCellRendererFactory(selectionLists),
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(selectionLists, {}, true),
      filterParams: {
        valueFormatter: filterFormatterFactory(selectionLists),
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...centeredColumnDefinition,
      headerName: 'Hidden',
      field: 'isHidden',
      cellRenderer: EditableCheckboxCellRenderer,
      cellClassRules: checkboxCellClassRules,
      valueGetter: ({ data }) => {
        const valueStr = String(data.isHidden);
        return Number(valueStr === '1' || valueStr === 'true');
      },
      filterParams: {
        valueFormatter: filterFormatterFactory(checkboxOptions),
      },
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...centeredColumnDefinition,
      headerName: 'Inactive',
      field: 'inactive',
      cellRenderer: EditableCheckboxCellRenderer,
      cellClassRules: checkboxCellClassRules,
      valueGetter: ({ data }) => {
        const valueStr = String(data.inactive);
        return Number(valueStr === '1' || valueStr === 'true');
      },
      filterParams: {
        valueFormatter: filterFormatterFactory(checkboxOptions),
      },
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
