import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import Loading from '../../../shared/displayComponents/loading.component';
import SDKCustomSelect from '../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useRowEditMode } from '../../../shared/editMode';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../../../shared/columnDefinitions/cellRenderers';
import {
  taxYearSelector,
  periodSelector,
  filingTypeIdSelector,
} from '../../../shared/store/selectors';
import {
  insertInformationData,
  fetchFormList,
  fetchFormFields,
  updateFormFields,
} from '../../../shared/store/stateSpecificInformation/actions';
import {
  formListOptionsSelector,
  isFetchingFormListSelector,
  formFieldsSelector,
  isFetchingFormFieldsSelector,
  isUpdatingFormFieldsSelector,
} from '../../../shared/store/stateSpecificInformation/selectors';

import getColumnDefinitions from './stateSpecificInformation.columnDefinitions';
import styles from './stateSpecificInformation.module.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const StateSpecificInformation = ({
  taxYear,
  period,
  filingTypeId,

  orgId,
  jurisdictionId,

  insertInformationData,

  fetchFormList,
  isFetchingFormList,
  formListOptions,

  fetchFormFields,
  isFetchingFormFields,
  formFields,

  updateFormFields,
  isUpdatingFormFields,

  hasUserPermissionsToEdit,
}) => {
  const isContextReady = taxYear && period && orgId && jurisdictionId;

  const [selectedForm, setSelectedForm] = useState();
  useEffect(() => {
    const onContextChange = async () => {
      await insertInformationData({ taxYear, period, orgId, jurisdictionId });
      fetchFormList({ taxYear, period, filingTypeId, orgId, jurisdictionId });
    };

    if (isContextReady) {
      onContextChange();
    }
  }, [
    isContextReady,
    taxYear,
    period,
    filingTypeId,
    orgId,
    jurisdictionId,
    insertInformationData,
    fetchFormList,
  ]);

  useEffect(() => {
    if (formListOptions?.length) {
      setSelectedForm(formListOptions[0]);
    }
  }, [setSelectedForm, formListOptions]);

  useEffect(() => {
    if (isContextReady && selectedForm) {
      const { swpFormId } = selectedForm;
      fetchFormFields({ taxYear, period, orgId, jurisdictionId, swpFormId });
    }
  }, [fetchFormFields, selectedForm, isContextReady, taxYear, period, orgId, jurisdictionId]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const { swpFormId } = selectedForm;

      if (!rowsPairsWithChanges.length) {
        return;
      }

      const rows = rowsPairsWithChanges.map(({ newRow }) => newRow);

      await updateFormFields({ rows });
      fetchFormFields({ taxYear, period, orgId, jurisdictionId, swpFormId });
    },
    [updateFormFields, fetchFormFields, selectedForm, taxYear, period, orgId, jurisdictionId],
  );

  const isLoading = isFetchingFormList || isFetchingFormFields || isUpdatingFormFields;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: formFields,
    getUniqueRowId,
    editButtonDisabled: isLoading || !formFields.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        updateRow,
      }),
    [isInEditMode, updateRow],
  );
  return (
    <>
      {navigationPrompt}
      <div className={styles.flexSpaceBetween}>
        <div className={styles.formListDropdown}>
          <div className={styles.editSpinner}>
            <Loading isLoading={isFetchingFormList}>
              <SDKCustomSelect
                className="sdk-custom-select"
                virtualized
                appkitLabel="Form Name"
                options={formListOptions}
                value={selectedForm && selectedForm.value}
                onChange={useCallback(form => setSelectedForm(form), [])}
              />
            </Loading>
          </div>
        </div>
        {hasUserPermissionsToEdit && <div className="add-button-column">{editModeButtons}</div>}
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            withSearchBar
            stopEditingWhenCellsLoseFocus
          />
        </div>
      </div>
    </>
  );
};

const formListOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    formId: PropTypes.string,
  }).isRequired,
).isRequired;

const formFieldsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    taxYear: PropTypes.number,
    period: PropTypes.number,
    infoAcctId: PropTypes.string,
    logicalPageNo: PropTypes.number,
    accountDescription: PropTypes.string,
    orgId: PropTypes.string,
    jurisdictionId: PropTypes.string,
    acctJurisdictionId: PropTypes.string,
    formId: PropTypes.string,
    displayOrder: PropTypes.number,
    dataType: PropTypes.string,
    globalInd: PropTypes.string,
    mapId: PropTypes.number,
    evValue: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    dispValue: PropTypes.string,
  }).isRequired,
).isRequired;

StateSpecificInformation.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  filingTypeId: PropTypes.string,

  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,

  insertInformationData: PropTypes.func.isRequired,

  fetchFormList: PropTypes.func.isRequired,
  isFetchingFormList: PropTypes.bool.isRequired,
  formListOptions: formListOptionsPropTypes,

  fetchFormFields: PropTypes.func.isRequired,
  isFetchingFormFields: PropTypes.bool.isRequired,
  formFields: formFieldsPropTypes,

  updateFormFields: PropTypes.func.isRequired,
  isUpdatingFormFields: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    filingTypeId: filingTypeIdSelector(state),

    isFetchingFormList: isFetchingFormListSelector(state),
    formListOptions: formListOptionsSelector(state),

    isFetchingFormFields: isFetchingFormFieldsSelector(state),
    formFields: formFieldsSelector(state),

    isUpdatingFormFields: isUpdatingFormFieldsSelector(state),
  }),
  {
    insertInformationData,
    fetchFormList,
    fetchFormFields,
    updateFormFields,
  },
)(StateSpecificInformation);
