import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';
import { GetRowIdParams } from 'ag-grid-community';

import { useQueryMaintenanceEntities } from '../../shared/queries/entitiesMaintenance';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useGridWithEditForm from '../../shared/hooks/useGridWithEditForm.hook';
import { periodSelector, taxYearSelector } from '../../shared/store/selectors';

import getEntityColumnDefinitions from './entitiesMaintenance.columnDefinitions';
import EntityForm from './entityForm.container';
import styles from './entityMaintenance.module.scss';

const getRowId = ({ data: { orgId } }: GetRowIdParams) => orgId;

const EntityMaintenance = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const columnDefinitions = useMemo(() => getEntityColumnDefinitions(), []);

  const { data: entities, isFetching: isFetchingEntities } = useQueryMaintenanceEntities({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });

  // Values for the form to add and edit individuals and entities
  const [entityToEdit, setEntityToEdit] = useState(null);
  const { agGridProps, clearSelection } = useGridWithEditForm(entities, setEntityToEdit, getRowId);

  return (
    <>
      <div className={styles.addButtonGroup}>
        <Button
          size="lg"
          className="add-button"
          disabled={!(taxYear && period)}
          onClick={clearSelection}
        >
          Add New Entity
        </Button>
      </div>
      <div className="grid-row h-100">
        <div className="col-8">
          <AgGrid
            rowData={entities}
            isGridLoading={isFetchingEntities}
            columnDefs={columnDefinitions}
            {...agGridProps}
            withSearchBar
          />
        </div>
        <div className="col-4 right-column-edit-form">
          <EntityForm
            entityToEdit={entityToEdit}
            entities={entities}
            isFetchingEntities={isFetchingEntities}
          />
        </div>
      </div>
    </>
  );
};

export default EntityMaintenance;
