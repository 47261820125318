import { httpGetAndParse, httpPost } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from '.';

const fetchFilterData = ({ taxYear, period, entityId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/entities/k1-information-v2/filter-data/${taxYear}/${period}/${entityId}/${jurisdictionId}`,
  });

const insertInformationalData = ({ taxYear, period, entityId, jurisdictionId }) =>
  httpPost({
    route: '/api/entities/k1-information-v2/',
    body: { taxYear, period, entityId, jurisdictionId },
  });

export const useQueryFilterData = ({ params, enabled, onSuccess }) =>
  useCustomQuery([QueryKeys.K1InformationV2.FilterData, params], {
    enabled,
    onSuccess,
    defaultData: {
      formListOptions: [],
      partnerListOptions: [],
    },
    errorMessage: 'Fetching k1 information form list failed',
    customFetch: async () => {
      await insertInformationalData(params);
      const { formListOptions, partnerListOptions } = await fetchFilterData(params);
      return { formListOptions, partnerListOptions };
    },
  });

const fetchFormFields = ({ period, entityId, dataModelId, partnerId }) =>
  httpGetAndParse({
    route: `/api/entities/k1-information-v2/${period}/${entityId}/${dataModelId}/${partnerId}`,
  });

export const useQueryFormFields = ({ params, enabled }) =>
  useCustomQuery([QueryKeys.K1InformationV2.FormFields, params], {
    enabled,
    defaultData: [],
    errorMessage: 'Fetching k1 information form fields failed',
    customFetch: async () => {
      const formFields = await fetchFormFields(params);
      return formFields.map((field, rowId) => ({ ...field, rowId }));
    },
  });
