import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import { logClientError } from './store/api';
import { errorMessages, REFRESH_PAGE_MESSAGE } from './constants';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.refreshApp = this.refreshApp.bind(this);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    logClientError({ error: error.toString(), info });
  }

  refreshApp = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="general-info-page">
          <div className="general-info-text">{`${errorMessages.UI_ERROR_MESSAGE} ${REFRESH_PAGE_MESSAGE}`}</div>
          <div className="general-button-wrapper">
            <Button size="lg" onClick={this.refreshApp}>
              REFRESH
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
