import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchNotesToReviewTypes,
  fetchJurisdictionsTypes,
  fetchTasksTypes,
  fetchStatusesTypes,
} from './types';

const filingDecisionsReducer = reducerFactory({
  initialState: {
    isFetchingNotesToReview: false,
    notesToReview: [],
    jurisdictions: [],
    tasks: [],
    statuses: [],
    isFetchingJurisdictions: false,
    isFetchingStatuses: false,
    isFetchingTasks: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchNotesToReviewTypes,
      payloadKey: 'notesToReview',
      fetchingKey: 'isFetchingNotesToReview',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchJurisdictionsTypes,
      payloadKey: 'jurisdictions',
      fetchingKey: 'isFetchingJurisdictions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchTasksTypes,
      payloadKey: 'tasks',
      fetchingKey: 'isFetchingTasks',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchStatusesTypes,
      payloadKey: 'statuses',
      fetchingKey: 'isFetchingStatuses',
    }),
  },
});

export default filingDecisionsReducer;
