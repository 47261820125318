import PropTypes from 'prop-types';

import { Alignments } from '../constants';
import { DataTypeNames } from '../enums';

const DrillDataShape = {
  baseFieldName: PropTypes.string.isRequired,
  actualFieldName: PropTypes.string.isRequired,
  drillGScreenAccount: PropTypes.string,
  drillGScreenCategory: PropTypes.string,
  drillInitiatorDataSetDefID: PropTypes.string,
  drillTargetDataSetDefID: PropTypes.string,
  drillTargetDataSetDefName: PropTypes.string,
  drillTargetGScreenID: PropTypes.string,
  drillTargetParentDataSetInstanceID: PropTypes.string,
  drillType: PropTypes.number.isRequired,
  drillTypeName: PropTypes.string.isRequired,
  fromDSUsageUIFieldLocation: PropTypes.bool.isRequired,
  internalDrillType: PropTypes.number.isRequired,
  // PdfFieldValuesShape overrides
  blockId: PropTypes.string,
  printSuppress: PropTypes.bool,
};

const PdfFieldValuesShape = {
  alignment: PropTypes.oneOf(Object.keys(Alignments)),
  blockId: PropTypes.string.isRequired,
  checkboxGroupId: PropTypes.string,
  dataType: PropTypes.oneOf(Object.values(DataTypeNames)),
  dataTypeName: PropTypes.oneOf(Object.keys(DataTypeNames)),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  didID: PropTypes.string,
  diiID: PropTypes.string,
  dsdID: PropTypes.string,
  dsiID: PropTypes.string,
  formattedData: PropTypes.string,
  isEditable: PropTypes.bool,
  isOverridden: PropTypes.bool,
  isOverrideable: PropTypes.bool,
  isClearingOverride: PropTypes.bool,
  mappingID: PropTypes.string,
  overrideValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  printSuppress: PropTypes.bool.isRequired,
  wrap: PropTypes.shape({
    firstRowIndent: PropTypes.number,
    lineHeight: PropTypes.number,
    rows: PropTypes.number,
    topAdjustment: PropTypes.number,
  }),
  ynCheckBox: PropTypes.string,
};

export const DrillFieldPropTypes = PropTypes.shape({
  ...PdfFieldValuesShape,
  ...DrillDataShape,
});

export const OverridableFieldPropTypes = PropTypes.shape({
  ...PdfFieldValuesShape,
  styles: PropTypes.shape(CSSStyleDeclaration),
  siblings: PropTypes.arrayOf(
    PropTypes.shape({
      mappingID: PropTypes.string.isRequired,
      blockId: PropTypes.string.isRequired,
    }).isRequired,
  ),
});
