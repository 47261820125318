import { WizardStateMachineContext } from '../../wizardStateMachine/types';
import { WIZARD_MACHINE_EVENTS } from '../../constants';
import { StepStatus, WizardStepName } from '../../enums';

const chooseActiveStepEvent = ({ eFileSteps, validation }: WizardStateMachineContext) => {
  if (eFileSteps[WizardStepName.STEP_4_AUTHORIZE_AND_TRANSMIT] === StepStatus.COMPLETED) {
    return WIZARD_MACHINE_EVENTS.SET_STEP_5;
  }

  if (
    eFileSteps[WizardStepName.STEP_3_REVIEW] === StepStatus.COMPLETED ||
    eFileSteps[WizardStepName.STEP_4_AUTHORIZE_AND_TRANSMIT] === StepStatus.FAILED
  ) {
    return WIZARD_MACHINE_EVENTS.SET_STEP_4;
  }

  if (eFileSteps[WizardStepName.STEP_3_REVIEW] === StepStatus.INITIATED && !validation.hasErrors) {
    return WIZARD_MACHINE_EVENTS.SET_STEP_3;
  }

  if (
    eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.FAILED ||
    eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.INITIATED ||
    (eFileSteps[WizardStepName.STEP_2_VALIDATE] === StepStatus.COMPLETED &&
      (validation.hasErrors || eFileSteps[WizardStepName.STEP_3_REVIEW] === null))
  ) {
    return WIZARD_MACHINE_EVENTS.SET_STEP_2;
  }

  return WIZARD_MACHINE_EVENTS.START_WIZARD;
};

export default chooseActiveStepEvent;
