import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import { SELECT_TAX_YEAR } from '../../../shared/store/types';

import {
  fetchDefaultBinderItemsTypes,
  fetchBinderItemTypesTypes,
  fetchFormsTypes,
  updateBinderItemsTypes,
} from './types';

export const initialState = {
  binderItems: [],
  isFetchingBinderItems: false,
  isUpdatingBinderItems: false,

  jurisdictionId: null,
  previousJurisdictionId: null,
  jurisdictions: [],
  isFetchingJurisdictions: false,

  binderItemTypes: [],
  isFetchingBinderItemTypes: false,

  forms: [],
  isFetchingForms: false,
};

const defaultBindersReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchDefaultBinderItemsTypes,
      payloadKey: 'binderItems',
      fetchingKey: 'isFetchingBinderItems',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchBinderItemTypesTypes,
      payloadKey: 'binderItemTypes',
      fetchingKey: 'isFetchingBinderItemTypes',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFormsTypes,
      payloadKey: 'forms',
      fetchingKey: 'isFetchingForms',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateBinderItemsTypes,
      fetchingKey: 'isUpdatingBinderItems',
    }),
    [SELECT_TAX_YEAR]: () => initialState,
  },
});

export default defaultBindersReducer;
