import { ColDef } from 'ag-grid-community';

import { selectCellEditorParamsFactory } from '../../columnDefinitions';
import { defaultAgRichSelectCellEditorSelector } from '../../columnDefinitions/cellEditor';

import EditorSelectorFactory from './editorSelector.factory';

const ConditionalSelectionListEditorSelectorFactory = (
  configure: {
    isClearable?: boolean;
  } = {},
) => {
  const { isClearable = true } = configure;
  const ConditionalSelectionListEditorSelector: ColDef['cellEditorSelector'] = props => {
    if (props.data?.selectionListItems) {
      return {
        ...defaultAgRichSelectCellEditorSelector,
        params: selectCellEditorParamsFactory(props.data.selectionListItems, {}, isClearable)(),
      };
    }
    return EditorSelectorFactory()(props);
  };

  return ConditionalSelectionListEditorSelector;
};

export default ConditionalSelectionListEditorSelectorFactory;
