import React from 'react';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import Dropzone from '../../../shared/forms/dropzoneFormik/dropzoneFormik.component';
import ButtonsGroup from '../../../shared/forms/buttonsGroup/buttonsGroup.component';
import Loading from '../../../shared/displayComponents/loading.component';
import formStyles from '../../../shared/forms/forms.module.scss';

import styles from './pdfAttachment.module.scss';

const DOCUMENT_ACCEPTED_TYPES = { 'application/pdf': ['.pdf'] };

const PdfAttachmentForm = ({ onCancel: handleCancel = () => null }) => {
  const { setFieldValue, isSubmitting, values, submitForm } = useFormikContext();

  const populateRelatedDropzoneField = relatedFieldName => file => {
    setFieldValue(relatedFieldName, file.name);
  };

  return (
    <form>
      <Loading isLoading={isSubmitting}>
        <div className="row">
          <Field
            className={`col form-text ${formStyles.fieldWithInlineLabel}`}
            label="Form Name"
            name="formName"
            component={Input}
            autoComplete="off"
          />
          <Field
            className={`col form-text ${formStyles.fieldWithInlineLabel}`}
            label="SWP Form Id"
            name="swpFormId"
            component={Input}
            autoComplete="off"
          />
        </div>
        <div className="row">
          <Field
            className={`col form-text ${formStyles.fieldWithInlineLabel}`}
            label="Form Description"
            name="formDescription"
            component={Input}
            autoComplete="off"
          />
        </div>
        <Dropzone
          className={`${formStyles.dropdownWithInlineLabel}`}
          name="pdfLibPdfFile"
          onDropCallback={populateRelatedDropzoneField('pdfLibPdfFileName')}
          value={values.pdfLibPdfFile}
          label="PDF"
          acceptTypes={DOCUMENT_ACCEPTED_TYPES}
          existingFileName={values.pdfLibPdf}
        />
        <ButtonsGroup
          className={styles.buttonsRow}
          fullWidth={false}
          submitButtonLabel="Save"
          isSubmitting={isSubmitting}
          onCancelClick={handleCancel}
          submitForm={submitForm}
        />
      </Loading>
    </form>
  );
};

PdfAttachmentForm.propTypes = {
  onCancel: PropTypes.func,
};

export default PdfAttachmentForm;
