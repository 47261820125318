import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';

import FilingAttributes from './filingAttributes.container';

const FilingAttributesWithContext = withContextWrapper()(FilingAttributes);

const Wrapper = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(GlobalContextPage);

const FilingAttributesRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.filingAttributes.MAIN}
    wrapper={Wrapper}
    component={FilingAttributesWithContext}
    hideGlobalContext
    disableBreadcrumbs
  />
);

export default FilingAttributesRoute;
