import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchValidatedMultDataTypes,
  uploadMultDataTypes,
  RESET_VALIDATED_MULT_DATA,
} from './types';

const multDataUploadReducer = reducerFactory({
  initialState: {
    validatedMultData: [],
    isFetchingValidatedMultData: false,
    isUploadingMultData: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchValidatedMultDataTypes,
      payloadKey: 'validatedMultData',
      fetchingKey: 'isFetchingValidatedMultData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: uploadMultDataTypes,
      fetchingKey: 'isUploadingMultData',
      getAdditionalState: () => ({
        validatedMultData: [],
      }),
    }),
    [RESET_VALIDATED_MULT_DATA]: state => ({
      ...state,
      validatedMultData: [],
    }),
  },
});

export default multDataUploadReducer;
