import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import {
  BreadcrumbRoute,
  homeBreadcrumb,
} from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const AdminBreadcrumbRoute = () => (
  <>
    <BreadcrumbRoute path={Routes.administration} showText={false} icon={homeBreadcrumb} />
    <BreadcrumbRoute path={Routes.editCustomReports} />
    <BreadcrumbRoute path={Routes.runCustomReports} />
    <BreadcrumbRoute path={Routes.viewCustomReport} />
    <BreadcrumbRoute path={Routes.genericAccountDataUpload} />
    <BreadcrumbRoute path={Routes.informationalDataUpload} />
    <BreadcrumbRoute path={Routes.multDataUpload} />
    <BreadcrumbRoute path={Routes.efileConfiguration} />
    <BreadcrumbRoute path={Routes.efileSubmission} />
    <BreadcrumbRoute path={Routes.usersMaintenance} />
    <BreadcrumbRoute path={Routes.assignBusinesses} />
    <BreadcrumbRoute path={Routes.businessGroup} />
    <BreadcrumbRoute path={Routes.businesses} />
    <BreadcrumbRoute path={Routes.jobQueueStatusReport} />
    <BreadcrumbRoute path={Routes.taxRatesAndConstantsMaintenance} />
    <BreadcrumbRoute path={Routes.editDataVisualizations} />
    <BreadcrumbRoute path={Routes.runDataVisualizations} />
    <BreadcrumbRoute path={Routes.addDataCollectionReviewTask} />
    <BreadcrumbRoute path={Routes.addReturnTask} />
    <BreadcrumbRoute path={Routes.addLegalEntityReviewTask} />
    <BreadcrumbRoute path={Routes.reassignTask} />
    <BreadcrumbRoute path={Routes.batchTaskSignoff} />
    <BreadcrumbRoute path={Routes.accounts} />
    <BreadcrumbRoute path={Routes.infoAccounts} />
    <BreadcrumbRoute path={Routes.entitiesMaintenance} />
    <BreadcrumbRoute path={Routes.federalDataRefresh} />
    <BreadcrumbRoute path={Routes.lockReturn} />
    <BreadcrumbRoute path={Routes.adminConsolidations} />
    <BreadcrumbRoute path={Routes.permissions} />
    <BreadcrumbRoute path={Routes.roles} />
    <BreadcrumbRoute path={Routes.constantsRulesReport} />
    <BreadcrumbRoute path={Routes.taxRateReport} />
    <BreadcrumbRoute path={Routes.calculatedAccountsReport} />
    <BreadcrumbRoute path={Routes.accountGridReport} />
  </>
);

export default AdminBreadcrumbRoute;
