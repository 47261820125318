import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';
import cloneDeep from 'lodash.clonedeep';
import { genericScreenDefinitionRowSchemas } from '@common-packages/validators';

import { taxYearSelector, periodSelector } from '../../store/selectors';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { HeaderWithParamDropdownsWrapper } from '../../../shared/displayComponents/headerWithParamDropdowns';
import useGridApi from '../../../shared/hooks/useGridApi.hook';
import {
  fetchGenericScreenDefinitionRowDetails,
  createGenericScreenDefinitionRow,
} from '../store/actions';
import {
  isFetchingGenericScreenDefinitionRowDetailsSelector,
  genericScreenDefinitionRowDetailsSelector,
  screenIdSelector,
  isFetchingAccountsSelector,
  isFetchingCategoriesSelector,
  screenTitleSelector,
} from '../store/selectors';
import useEditModeErrorColumn from '../../../shared/editMode/useEditModeErrorColumn.hook';

import getColumnDefinitions from './genericScreenDefinitionRowDetails.columnDefinitions';
import { defaultBlankTypeState } from './editRowDetailsForm.defaultStates';
import EditRowDetails from './editRowDetails.container';
import styles from './genericScreenDefinitionRowDetails.module.scss';

const getRowId = params => params?.data?.rowId;

const GenericScreenDefinitionRowDetails = ({
  taxYear,
  period,
  screenId,
  fetchGenericScreenDefinitionRowDetails,
  genericScreenDefinitionRowDetails,
  isFetchingGenericScreenDefinitionRowDetails,
  createGenericScreenDefinitionRow,
  isFetchingCategories,
  isFetchingAccounts,
  title,
}) => {
  const fetchGridData = useCallback(async () => {
    const isContextReady = taxYear && period && screenId;

    if (isContextReady) {
      await fetchGenericScreenDefinitionRowDetails({
        taxYear,
        period,
        screenId,
      });
    }
  }, [fetchGenericScreenDefinitionRowDetails, taxYear, period, screenId]);

  useEffect(() => {
    fetchGridData();
  }, [fetchGridData]);

  const [rowToEdit, setRowToEdit] = useState(null);
  const [formikProps, setFormikProps] = useState(null);
  const { columnApi, gridApi, onGridReady } = useGridApi();

  const { validateEditModeColumnsAndShowPossibleErrors } = useEditModeErrorColumn({
    validationSchema: genericScreenDefinitionRowSchemas.genericScreenDefinitionRows,
    gridApi,
    columnApi,
  });

  const rowData = useMemo(
    () => (!isFetchingGenericScreenDefinitionRowDetails ? genericScreenDefinitionRowDetails : []),
    [genericScreenDefinitionRowDetails, isFetchingGenericScreenDefinitionRowDetails],
  );
  const [clonedRowData, setClonedRowData] = useState(rowData);
  const refreshClonedRowData = useCallback(() => {
    if (gridApi) {
      const newClonedRowData = cloneDeep(rowData);
      setClonedRowData(newClonedRowData);
      gridApi.setRowData(newClonedRowData);
    }
    setRowToEdit(null);
  }, [rowData, gridApi]);

  useEffect(() => {
    if (!isFetchingGenericScreenDefinitionRowDetails) {
      refreshClonedRowData();
    }
  }, [isFetchingGenericScreenDefinitionRowDetails]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    const rows = gridApi.getModel().rowsToDisplay.map(node => node.data);

    if (rows.length === 0) {
      setRowToEdit(null);
      return;
    }

    const lastRow = rows[rows.length - 1];
    const rowToSelect = rowToEdit || lastRow;
    const nodeToSelect = gridApi.getRowNode(rowToSelect.rowId);
    if (nodeToSelect) {
      nodeToSelect.setSelected(true);
    } else if (!rowToEdit) {
      setRowToEdit(null);
    }
  }, [clonedRowData, gridApi, rowToEdit]);

  const handleRowSelection = useCallback(
    async ({ api }) => {
      if (rowToEdit && formikProps) {
        const editedRow = api.getRowNode(rowToEdit.rowId);
        editedRow.setData(formikProps.values);
      }
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        setRowToEdit(selectedRow);
      }
    },
    [setRowToEdit, rowToEdit, formikProps],
  );

  const removeRow = useCallback(
    row => {
      setRowToEdit(row);
      gridApi.applyTransaction({
        remove: [row],
      });
      setRowToEdit(null);
    },
    [gridApi],
  );

  const createNewRow = useCallback(() => {
    const rowsBeforeCreatingNewRow = gridApi.getModel().rowsToDisplay.map(node => node.data);
    if (formikProps && rowsBeforeCreatingNewRow.length) {
      const editedRow = gridApi.getRowNode(rowToEdit.rowId);
      editedRow.setData(formikProps.values);
    }
    const nextRowId = !rowsBeforeCreatingNewRow.length
      ? 1
      : Math.max(...rowsBeforeCreatingNewRow.map(({ rowId }) => Number(rowId))) + 1;

    const newRow = {
      ...Object.keys(defaultBlankTypeState).reduce(
        (accumulator, key) =>
          Object.assign(accumulator, { [key]: defaultBlankTypeState[key].value }),
        {},
      ),
      rowId: nextRowId.toString(),
    };
    gridApi.applyTransaction({
      add: [newRow],
    });
    setRowToEdit(null);
    const rowsAfterCreatingNewRow = gridApi.getModel().rowsToDisplay.map(node => node.data);
    setClonedRowData(rowsAfterCreatingNewRow);
  }, [gridApi, formikProps, rowToEdit]);

  const columnDefinitions = useMemo(() => getColumnDefinitions({ onDeleteIconClick: removeRow }), [
    removeRow,
  ]);

  const saveAllRows = useCallback(async () => {
    if (formikProps && rowToEdit) {
      const editedRow = gridApi.getRowNode(rowToEdit.rowId);
      editedRow.setData(formikProps.values);
    }
    const rows = gridApi.getModel().rowsToDisplay.map(node => node.data);

    const valid = await validateEditModeColumnsAndShowPossibleErrors();
    if (valid) {
      try {
        await createGenericScreenDefinitionRow({
          taxYear,
          period,
          screenId,
          rows,
        });
        fetchGridData();
      } catch (err) {
        return;
      }
    }
  }, [
    rowToEdit,
    formikProps,
    fetchGridData,
    createGenericScreenDefinitionRow,
    validateEditModeColumnsAndShowPossibleErrors,
    gridApi,
    taxYear,
    period,
    screenId,
  ]);

  const disabled = isFetchingCategories || isFetchingAccounts;

  const renderButtons = useCallback(
    () => (
      <div className={styles.buttonGroup}>
        <Button size="lg" className="add-button" onClick={createNewRow} disabled={disabled}>
          Add Row
        </Button>
        <Button
          size="lg"
          kind="secondary"
          className="add-button"
          disabled={disabled}
          onClick={refreshClonedRowData}
        >
          Cancel
        </Button>
        <Button size="lg" className="add-button" disabled={disabled} onClick={saveAllRows}>
          Save
        </Button>
      </div>
    ),
    [createNewRow, refreshClonedRowData, saveAllRows, disabled],
  );

  return (
    <>
      <HeaderWithParamDropdownsWrapper
        showHeaderTaxYearDropdown
        showHeaderPeriodDropdown
        renderAdditionalElements={renderButtons}
        title={title}
      />
      <div className="row grid-row">
        <div className={rowToEdit ? 'col-8' : 'col-12'}>
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingGenericScreenDefinitionRowDetails}
            onSelectionChanged={handleRowSelection}
            getRowId={getRowId}
            rowDragManaged
            onGridReady={onGridReady}
            areHeaderCellBordersEnabled
          />
        </div>
        {rowToEdit ? (
          <div className="col-4 right-column-edit-form">
            <EditRowDetails
              taxYear={taxYear}
              period={period}
              screenId={screenId}
              rowToEdit={rowToEdit}
              fetchGridData={fetchGridData}
              setFormikProps={setFormikProps}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

GenericScreenDefinitionRowDetails.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  screenId: PropTypes.string,
  fetchGenericScreenDefinitionRowDetails: PropTypes.func.isRequired,
  isFetchingGenericScreenDefinitionRowDetails: PropTypes.bool.isRequired,
  genericScreenDefinitionRowDetails: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.string.isRequired,
      rowType: PropTypes.string.isRequired,
      accountId: PropTypes.string,
      categoryId: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  createGenericScreenDefinitionRow: PropTypes.func.isRequired,
  isFetchingCategories: PropTypes.bool.isRequired,
  isFetchingAccounts: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    screenId: screenIdSelector(state),
    title: screenTitleSelector(state),
    genericScreenDefinitionRowDetails: genericScreenDefinitionRowDetailsSelector(state),
    isFetchingGenericScreenDefinitionRowDetails: isFetchingGenericScreenDefinitionRowDetailsSelector(
      state,
    ),
    isFetchingAccounts: isFetchingAccountsSelector(state),
    isFetchingCategories: isFetchingCategoriesSelector(state),
  }),
  {
    fetchGenericScreenDefinitionRowDetails,
    createGenericScreenDefinitionRow,
  },
)(GenericScreenDefinitionRowDetails);
