import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import { BreadcrumbRoute } from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';
import ReturnWorkspaceBreadcrumbRoutes from '../../sharedSubPages/returnWorkspace/returnWorkspaceBreadcrumbRoutes.component';

import ConsolidationBreadcrumbContentRenderer from './consolidationBreadcrumbContentRenderer.container';

const ConsolidationBreadcrumbRoutes = () => (
  <>
    <BreadcrumbRoute
      path={Routes.consolidation}
      ContentRenderer={ConsolidationBreadcrumbContentRenderer}
    />

    <BreadcrumbRoute path={Routes.consolidationFilingEntities} />

    <BreadcrumbRoute path={Routes.consolidationFilingGroups} />
    <ReturnWorkspaceBreadcrumbRoutes path={Routes.consolidationReturnWorkspace} />

    <BreadcrumbRoute path={Routes.informationalDataV2} />

    <BreadcrumbRoute path={Routes.consolidatedReport} />

    <BreadcrumbRoute path={Routes.manualAdjustmentReport} />
  </>
);

export default ConsolidationBreadcrumbRoutes;
