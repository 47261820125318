import React, { ReactNode } from 'react';
import { CompilationResult, CompilationInfo } from '@tls/slt-types';

import useCompileInfoTexts from '../../hooks/useCompileInfoTexts';
import CompileInfoStatus from '../../compileInfoStatus/compileInfoStatus.component';
import ValidationInfoStatus from '../../validationInfoStatus/validationInfoStatus.component';

import styles from './latestCalculationCompileInfo.module.scss';
import { EXPRESSIONS, DATAITEMS, LAST_SUCCESSFUL_COMPILE } from './constants';
import DefaultRender from './defaultRender.component';

interface LatestCalculationCompileInfoProps {
  compilationInfo?: CompilationInfo | null;
  isFetchingCompilationInfo: boolean;
  compilationResult?: CompilationResult[] | null;
  validationDate?: string;
  isCompiling?: boolean;
  areAllJurisdictionsSelected?: boolean;
  children?: ReactNode;
}

const LatestCalculationCompileInfo = ({
  isFetchingCompilationInfo,
  compilationInfo = null,
  compilationResult = null,
  validationDate = '',
  isCompiling = false,
  areAllJurisdictionsSelected = false,
  children = null,
}: LatestCalculationCompileInfoProps) => {
  const {
    compileInfoText,
    expressionsText,
    lastSuccessfulCompileText,
    dataItemsText,
  } = useCompileInfoTexts({
    compilationInfo,
    compilationResult,
    validationDate,
  });

  if (isCompiling && !areAllJurisdictionsSelected) {
    return <DefaultRender isCompiling={isCompiling} />;
  }

  if ((isFetchingCompilationInfo || !compilationInfo) && !areAllJurisdictionsSelected) {
    return <DefaultRender isLoading={isFetchingCompilationInfo} />;
  }

  return (
    <>
      <div className={styles.grid}>
        {!areAllJurisdictionsSelected && (
          <>
            <p>{EXPRESSIONS}</p>
            <p>{expressionsText}</p>
            <p className={styles.compileInfoStatus}>
              <CompileInfoStatus compileInfoText={compileInfoText} />
            </p>
            <p className={styles.dataItems}>{DATAITEMS}</p>
            <p>{dataItemsText}</p>
            <p className={styles.lastSuccessfulCompileLabel}>{LAST_SUCCESSFUL_COMPILE}</p>
            <p>{lastSuccessfulCompileText}</p>
          </>
        )}
        <ValidationInfoStatus
          className={styles.currentCompileStatus}
          compilationResult={compilationResult}
          validationDate={validationDate}
          compilationInfo={compilationInfo}
          areAllJurisdictionsSelected={areAllJurisdictionsSelected}
        />
      </div>
      {children}
    </>
  );
};

export default LatestCalculationCompileInfo;
