import React, { useState, useCallback } from 'react';
import isEqual from 'lodash.isequal';

import useGridApi from '../hooks/useGridApi.hook';
import BottomSlideIn from '../displayComponents/bottomSlideIn';

const noop = () => null;

const useBottomSlideInEditMode = ({
  getRowId,
  formToRender,
  renderBottomSlideIn,
  shouldCloseOnSave = true,
  onSave = () => null,
  mapRowForEditForm = values => values,
  getTitle = () => null,
  isCompactIconSticky = false,
}) => {
  const [rowToEdit, setRowToEdit] = useState(null);
  const [showBottomSlideIn, setShowBottomSlideIn] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const { gridApi, onGridReady } = useGridApi();

  const handleRowSelection = useCallback(
    ({ api }) => {
      setAddingNew(false);
      if (addingNew) {
        setRowToEdit(null);
        return;
      }

      const selectedRow = api.getSelectedRows()[0];
      const isSelectedRowRowToEditEqual = isEqual(selectedRow, rowToEdit);

      if (!selectedRow) {
        setShowBottomSlideIn(false);
        setRowToEdit(null);
        return;
      }

      if (selectedRow || (!shouldCloseOnSave && !isSelectedRowRowToEditEqual)) {
        setRowToEdit(selectedRow);
        setShowBottomSlideIn(true);
      }
    },
    [rowToEdit, addingNew, shouldCloseOnSave],
  );

  const onAddClick = useCallback(() => {
    setAddingNew(true);
    setShowBottomSlideIn(true);

    gridApi.deselectAll();
  }, [gridApi]);

  const hideBottomSlideIn = useCallback(() => {
    if (gridApi) {
      gridApi.deselectAll();
    }
  }, [gridApi]);

  const handleSave = useCallback(
    async values => {
      const selectedNode = gridApi?.getSelectedNodes()[0];
      gridApi?.deselectAll();

      await onSave({
        values,
        isEditMode: Boolean(rowToEdit),
      });

      if (shouldCloseOnSave) {
        hideBottomSlideIn();
      }

      selectedNode?.setSelected(true);
    },
    [onSave, hideBottomSlideIn, rowToEdit, shouldCloseOnSave, gridApi],
  );

  const bottomSlideInWithForm =
    renderBottomSlideIn && showBottomSlideIn ? (
      <BottomSlideIn hide={hideBottomSlideIn} isCompactIconSticky={isCompactIconSticky}>
        {formToRender({
          onSave: handleSave,
          values: mapRowForEditForm(rowToEdit),
          isEditMode: Boolean(rowToEdit),
          title: getTitle(rowToEdit),
        })}
      </BottomSlideIn>
    ) : null;

  return {
    gridApi,
    agGridProps: {
      onSelectionChanged: handleRowSelection,
      onRowDataUpdated: shouldCloseOnSave ? hideBottomSlideIn : noop,
      getRowId,
      onGridReady,
    },
    onAddClick,
    bottomSlideInWithForm,
  };
};

export default useBottomSlideInEditMode;
