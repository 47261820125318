import { compile } from 'path-to-regexp';
import { TOTAL_DESC } from '@common-packages/shared-constants';

import {
  defaultColumnDefinition,
  currencyColumnDefinition,
} from '../../../shared/columnDefinitions';
import {
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../../shared/columnDefinitions/cellRenderers';

const isTotalRow = ({ data }) => data.component === TOTAL_DESC;

const getColumnDefinitions = url => {
  const overlinedCellClassRules = {
    'overlined-cell': isTotalRow,
    'bold-cell': isTotalRow,
  };

  const extendedDefaultColumnDefinition = {
    ...defaultColumnDefinition,
    sortable: false,
    cellClassRules: overlinedCellClassRules,
  };

  const TextRenderer = TextCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
  });

  const AmountLinkRenderer = LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: (value, data) =>
      compile(`${url}/component/:componentId`)({ componentId: data.component }),
  });

  const AmountRenderer = props =>
    isTotalRow(props) ? TextRenderer(props) : AmountLinkRenderer(props);

  const columnDefinitions = [
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Component',
      field: 'component',
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Name',
      field: 'name',
    },
    {
      ...extendedDefaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Amount',
      field: 'amount',
      cellRenderer: AmountRenderer,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
