import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './stepTab.module.scss';

interface StepTabHeaderProps {
  title: string;
  icon?: ReactNode;
  className?: string;
  subtitle?: string;
  headerTitleClassName?: string;
  subtitleClassName?: string;
  children?: ReactNode;
}

const StepTabHeader = ({
  title,
  subtitle,
  children,
  className,
  icon,
  headerTitleClassName,
  subtitleClassName,
}: StepTabHeaderProps) => (
  <div className={classnames(styles.tabHeader, className)}>
    {icon && <div className={styles.headerIcon}>{icon}</div>}
    <div className={styles.headerTitles}>
      <span className={classnames(styles.headerTitle, headerTitleClassName)}>{title}</span>
      {subtitle && (
        <span className={classnames(styles.headerSubTitle, subtitleClassName)}>{subtitle}</span>
      )}
    </div>
    {children}
  </div>
);

export default StepTabHeader;
