import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { taxTypes } from '@common-packages/routes-definitions';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import globalContextPropTypes from '../../shared/propTypes/globalContext';

import useFetchApportionmentRateDetails from './useFetchApportionmentRateDetails.hook';
import getApportionmentRateDetailsWithSections from './apportionmentRateDetails.sections';
import getColumnDefinitions from './apportionmentRateDetails.columnDefinitions';

const sections = ['PROPERTY', 'PAYROLL', 'SALES', ''];

const ApportionmentRateDetails = ({ globalContext, taxName }) => {
  const { taxYear, period, filingTypeId, businessEntityId, jurisdictionId } = globalContext.params;
  const {
    apportionmentRateDetails,
    isFetchingApportionmentRateDetails,
  } = useFetchApportionmentRateDetails({
    taxYear,
    period,
    entityId: businessEntityId,
    jurisdictionId,
    taxType: taxTypes[taxName],
    isContextReady: true,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
        taxName,
      }),
    [taxYear, period, filingTypeId, businessEntityId, jurisdictionId, taxName],
  );
  const rowData = useMemo(
    () => getApportionmentRateDetailsWithSections(sections, apportionmentRateDetails),
    [apportionmentRateDetails],
  );

  return (
    <div className="grid-borderless row grid-row">
      <div className="col">
        <AgGrid
          isGridLoading={isFetchingApportionmentRateDetails}
          columnDefs={columnDefinitions}
          rowData={rowData}
        />
      </div>
    </div>
  );
};
ApportionmentRateDetails.propTypes = {
  globalContext: globalContextPropTypes,
  taxName: PropTypes.string,
};

export default ApportionmentRateDetails;
