import PropTypes from 'prop-types';

export const compileInfoData = {
  lastCompiled: PropTypes.string,
  totalExpressions: PropTypes.number.isRequired,
  changedExpressions: PropTypes.number.isRequired,
  changedDataItems: PropTypes.number.isRequired,
};

export const compileInfoDataPropType = PropTypes.shape(compileInfoData);

export const compilationResultTypes = PropTypes.shape({
  dataItemName: PropTypes.string,
  didID: PropTypes.string,
  dataModelName: PropTypes.string,
  dmdID: PropTypes.string,
  dataSetName: PropTypes.string,
  dsdID: PropTypes.string,
  diagnosticType: PropTypes.string,
  expressionType: PropTypes.string,
  validationMessage: PropTypes.string,
});

export const diagnosticMessagePropTypes = PropTypes.shape({
  dataItemName: PropTypes.string,
  dataModelName: PropTypes.string,
  dataSetName: PropTypes.string,
  expressionType: PropTypes.oneOf(['Separate', 'Consolidated']),
  validationMessages: PropTypes.arrayOf(PropTypes.string),
});

export const compilationInfoResponsePropTypes = PropTypes.shape({
  ...compileInfoData,
  errorCount: PropTypes.number.isRequired,
  jurisdictionID: PropTypes.string.isRequired,
  jurisdictionName: PropTypes.string.isRequired,
  diagnosticGridData: PropTypes.arrayOf(compilationResultTypes).isRequired,
  diagnosticMessages: PropTypes.arrayOf(diagnosticMessagePropTypes),
  validationErrorMessages: PropTypes.arrayOf(diagnosticMessagePropTypes),
});
