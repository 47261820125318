import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

import { httpGetAndParse } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from '.';

const fetchFormsByWatermark = watermark =>
  httpGetAndParse({
    route: `/api/development/tax-forms-v2/forms-by-watermark/${watermark}`,
  });

export const useFetchQueryFormsByWatermark = () => {
  const queryClient = useQueryClient();
  return useCallback(
    watermark =>
      queryClient.fetchQuery(
        [QueryKeys.TaxFormsV2.FormsByWatermark, watermark],
        () => fetchFormsByWatermark(watermark),
        {
          initialData: [],
          cacheTime: 0, // setting cache time to 0 to always get the newest data
        },
      ),
    [queryClient],
  );
};

export const useQuerySuppressFieldDatasets = ({ params, enabled }) =>
  useCustomQuery([QueryKeys.TaxFormsV2.SuppressFieldDatasets, params], {
    url: `/api/development/tax-forms-v2/suppress-field-datasets/${params.datasetDefinitionId}/${params.formPdfId}`,
    enabled,
    defaultData: [],
    errorMessage: 'Error fetching suppress field datasets',
  });

export const useQueryForms = ({ params, enabled }) =>
  useCustomQuery([QueryKeys.TaxFormsV2.Forms, params], {
    url: `/api/development/forms/${params.taxYear}/${params.jurisdictionId}`,
    enabled,
    defaultData: {
      data: [],
      columnBlueprint: [],
    },
    errorMessage: 'Fetching forms failed',
  });
