import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const attachmentDefinitionsSelector = ({ tools }) => tools.attachments.attachmentDefinitions;
export const attachmentDefinitionsOptionsSelector = optionsSelectorFactory({
  listSelector: ({ tools }) => tools.attachments.attachmentDefinitions,
  labelKey: 'name',
  valueKey: 'attachmentDefId',
});

export const isFetchingBusinessOptionsSelector = ({ tools }) =>
  tools.attachments.isFetchingBusinessOptions;
export const businessOptionsSelector = ({ tools }) => tools.attachments.businessOptions;

export const isFetchingAttachmentsDefinitionsSelector = ({ tools }) =>
  tools.attachments.isFetchingAttachmentDefinitions;
export const isCreatingAttachmentSelector = ({ tools }) => tools.attachments.isCreatingAttachment;
export const isUpdatingAttachmentSelector = ({ tools }) => tools.attachments.isUpdatingAttachment;
