export const PrintOrientation = {
  PORTRAIT: '0',
  LANDSCAPE: '1',
};

export const PRINT_ORIENTATION_OPTIONS = [
  {
    label: 'Portrait',
    value: PrintOrientation.PORTRAIT,
  },
  {
    label: 'Landscape',
    value: PrintOrientation.LANDSCAPE,
  },
];
