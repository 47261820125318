import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

import { useFetchQueryFormsByWatermark } from '../../shared/queries/taxFormsV2';
import Loading from '../../shared/displayComponents/loading.component';
import { watermarkPropTypes } from '../../shared/propTypes/watermark.propTypes';
import { errorNotification } from '../../shared/notification/store/actions';

const WARNING_MESSAGE =
  'This watermark is being used in a tax form. Please remove it from the form before deleting this watermark.';
const WARNING_DATA_MESSAGE = 'It is being used in the following forms:';
const WARNING_BEFORE_DELETE =
  'You are about to remove this watermark. Are you sure you wish to continue?';

const ModalState = {
  INITIAL: 'initial',
  WATERMARK_USED: 'watermark_used',
  WATERMARK_UNUSED: 'watermark_unused',
};

const modalTitle = {
  [ModalState.INITIAL]: null,
  [ModalState.WATERMARK_USED]: 'An error occurred while deleting watermark',
  [ModalState.WATERMARK_UNUSED]: 'Delete watermark',
};

const modalButtonsText = {
  [ModalState.INITIAL]: { submit: null, dismiss: null },
  [ModalState.WATERMARK_USED]: { submit: 'Ok', dismiss: null },
  [ModalState.WATERMARK_UNUSED]: { submit: 'Yes', dismiss: 'No' },
};

const DeleteWatermarkModal = ({ hideModal, visible, modalData = null, onDelete = () => null }) => {
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState(ModalState.INITIAL);
  const [formsUsingWatermark, setFormsUsingWatermark] = useState([]);

  const fetchFormsByWatermark = useFetchQueryFormsByWatermark();

  const handleSubmit = useCallback(async () => {
    if (modalState === ModalState.WATERMARK_UNUSED) {
      await onDelete(modalData);
    }
    hideModal();
  }, [onDelete, hideModal, modalState, modalData]);

  useEffect(() => {
    const findFormsUsingWatermark = async () => {
      setModalState(ModalState.INITIAL);
      try {
        const formsByWatermark = await fetchFormsByWatermark(modalData.name);
        setFormsUsingWatermark(
          formsByWatermark.map(
            ({ name, taxYear, jurisdictionDescription }) =>
              `${name}, ${taxYear}, ${jurisdictionDescription}`,
          ),
        );
        setModalState(
          formsByWatermark.length ? ModalState.WATERMARK_USED : ModalState.WATERMARK_UNUSED,
        );
      } catch (err) {
        dispatch(errorNotification('Error fetching forms by watermark'));
      }
    };

    findFormsUsingWatermark();
  }, [dispatch, fetchFormsByWatermark, modalData.name]);

  return (
    <Modal
      title={modalTitle[modalState]}
      submitText={modalButtonsText[modalState].submit}
      dismissText={modalButtonsText[modalState].dismiss}
      visible={visible}
      dismissAction={hideModal}
      closeAction={hideModal}
      submitAction={handleSubmit}
    >
      <Loading isLoading={modalState === ModalState.INITIAL}>
        {modalState === ModalState.WATERMARK_USED && (
          <>
            <p>{WARNING_MESSAGE}</p>
            <p>{WARNING_DATA_MESSAGE}</p>
            {formsUsingWatermark.length && (
              <ul>
                {formsUsingWatermark.map(formDescription => (
                  <li key={formDescription}>{formDescription}</li>
                ))}
              </ul>
            )}
          </>
        )}
        {modalState === ModalState.WATERMARK_UNUSED && <p>{WARNING_BEFORE_DELETE}</p>}
      </Loading>
    </Modal>
  );
};

DeleteWatermarkModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  modalData: watermarkPropTypes,
  onDelete: PropTypes.func,
};

export default DeleteWatermarkModal;
