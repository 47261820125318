import PropTypes from 'prop-types';

export const notesToReviewPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    customerId: PropTypes.string,
    noteId: PropTypes.number,
    preparedByWithDate: PropTypes.string,
    preparedBy: PropTypes.string,
    respondedBy: PropTypes.string,
    respondedByWithDate: PropTypes.string,
    statusDescription: PropTypes.string,
    url: PropTypes.string,
    jurisdiction: PropTypes.string,
    jurisdictionId: PropTypes.string,
    task: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    note: PropTypes.string,
    rolloverToNextYear: PropTypes.bool,
  }).isRequired,
);

export const noteToEditPropTypes = PropTypes.shape({
  customerId: PropTypes.string,
  noteId: PropTypes.number,
  preparedBy: PropTypes.string,
  respondedBy: PropTypes.string,
  jurisdictionId: PropTypes.string,
  task: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  note: PropTypes.string,
  rolloverToNextYear: PropTypes.bool,
});

export const jurisdictionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    displayOrder: PropTypes.number,
    jurisdictionId: PropTypes.string.isRequired,
    noteJurisdiction: PropTypes.string.isRequired,
    statusCode: PropTypes.string,
  }),
);

export const tasksPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    noteTask: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
  }),
);

export const statusesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    noteStatus: PropTypes.string.isRequired,
    noteStatusType: PropTypes.string.isRequired,
  }),
);
