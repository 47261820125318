import React, { useCallback } from 'react';
import { Badge } from '@pwc/appkit-react';
import { GridApi } from 'ag-grid-community';

import styles from './styles.module.scss';

interface QuickFilterBadgeWrapperProps {
  badgeLabel: string;
  toggledBadge: string;
  setToggledBadge: React.Dispatch<React.SetStateAction<string>>;
  filterModels: Record<string, Record<string, Record<string, string[] | string>>>;
  gridApi: GridApi | null;
}

const QuickFilterBadgeWrapper = ({
  badgeLabel,
  toggledBadge,
  setToggledBadge,
  filterModels,
  gridApi,
}: QuickFilterBadgeWrapperProps) => {
  const handleClick = useCallback(() => {
    if (toggledBadge === badgeLabel) {
      setToggledBadge('');
      gridApi?.setFilterModel(null);
    } else {
      setToggledBadge(badgeLabel);
      gridApi?.setFilterModel(filterModels[badgeLabel]);
    }
  }, [setToggledBadge, badgeLabel, filterModels, gridApi, toggledBadge]);

  return (
    <button className={styles.quickFilterBadgeButtonWrapper} onClick={handleClick}>
      <Badge name={badgeLabel} type={badgeLabel === toggledBadge ? 'primary' : 'secondary'}>
        {badgeLabel}
      </Badge>
    </button>
  );
};

export default QuickFilterBadgeWrapper;
