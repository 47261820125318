import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const NavigateBackLink = ({ taxFormInstanceId, scrollElementId }) => {
  const location = useLocation();
  const returnLocation = location && location.state && location.state.returnLocation;

  if (!returnLocation) {
    return null;
  }

  const search = new URLSearchParams(returnLocation.search);

  if (taxFormInstanceId) {
    search.set('formInstanceId', taxFormInstanceId);
  } else {
    search.delete('formInstanceId');
  }

  if (scrollElementId) {
    search.set('scrollElementId', scrollElementId);
  } else {
    search.delete('scrollElementId');
  }

  return (
    <div className="row">
      <div className="col mt-2">
        <Link
          to={{
            pathname: returnLocation.pathname,
            search: search.toString(),
          }}
        >
          Go back
        </Link>
      </div>
    </div>
  );
};

NavigateBackLink.propTypes = {
  taxFormInstanceId: PropTypes.string,
  scrollElementId: PropTypes.string,
  resetScrollElementId: PropTypes.func,
};

export default NavigateBackLink;
