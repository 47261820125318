import { httpGetAndParse } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from '.';

export const useQueryConsolInfoDataForms = ({ params, enabled }) =>
  useCustomQuery([QueryKeys.ConsolidationInformationalData.Forms, params], {
    url: `/api/consolidations/informational-data-v2/forms/${params.globalContext.apiRouteParamString}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching forms failed',
  });

const fetchFields = ({ taxYear, period, jurisdictionId, consolidationId, formId }) =>
  httpGetAndParse({
    route: `/api/consolidations/informational-data-v2/${taxYear}/${period}/${jurisdictionId}/${consolidationId}/${formId}`,
  });

export const useQueryConsolInfoDataFormFields = ({ params, enabled }) =>
  useCustomQuery([QueryKeys.ConsolidationInformationalData.Fields, params], {
    enabled,
    defaultData: [],
    errorMessage: 'Fetching fields failed',
    customFetch: () => fetchFields(params),
  });
