import { createSelector } from 'reselect';

import { jobsSelector } from '../../../jobs/store/selectors';

export const isUploadingFileSelector = ({ tools }) =>
  tools.genericAccountDataUpload.isUploadingFile;

const triggeredJobSelector = ({ tools }) => tools.genericAccountDataUpload.triggeredJob;

export const lastGenericAccountDataUploadJobSelector = createSelector(
  triggeredJobSelector,
  jobsSelector,
  (triggeredJob, jobs) => {
    if (!triggeredJob) {
      const lastGenericAccountDataJob = jobs.find(
        ({ jobType }) => jobType === 'generic-account-data-upload',
      );
      return lastGenericAccountDataJob || null;
    }

    const updatedTriggeredJobStatus = jobs.find(({ jobId }) => jobId === triggeredJob.jobId);
    return updatedTriggeredJobStatus || triggeredJob;
  },
);
