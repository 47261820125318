import { createQueryString } from '../../../utils/routerUtils';
import { httpGetAndParse, httpGetBlob, httpPut } from '../../../utils/fetchService';
import { filingTypes } from '../../constants';

const createQueryStringParams = (filingTypeId, entityId, jurisdictionId, consolidationId) =>
  filingTypeId === filingTypes.SEPARATE
    ? createQueryString({ entityId, jurisdictionId })
    : createQueryString({ consolidationId });

export const populateFormsAndFetchById = ({
  taxYear,
  period,
  entityId,
  jurisdictionId,
  consolidationId,
  formId,
  swpFormId,
  filingTypeId,
}) =>
  httpGetAndParse({
    route: `/api/shared/view-tax-return/${taxYear}/${period}/${filingTypeId}/forms/${formId}/${swpFormId}/${createQueryStringParams(
      filingTypeId,
      entityId,
      jurisdictionId,
      consolidationId,
    )}`,
    errorMessage: 'Fetching form failed',
  });

export const fetchFieldsData = ({
  taxYear,
  formId,
  period,
  entityId,
  formInstanceId,
  consolidationId,
  jurisdictionId,
  profileId,
}) => {
  const queryString = createQueryString({ formInstanceId, consolidationId });

  return httpGetAndParse({
    route: `/api/shared/view-tax-return/forms/${formId}/fields-data/${taxYear}/${period}/${entityId}/${jurisdictionId}/${profileId}${queryString}`,
    errorMessage: 'Fetching fields values failed',
  });
};

export const downloadFormPdf = ({
  formId,
  taxYear,
  period,
  entityId,
  consolidationId,
  shouldDecrypt,
}) =>
  httpGetBlob({
    route: `/api/shared/view-tax-return/download-form-pdf/${formId}/${taxYear}/${period}/${entityId}${createQueryString(
      { shouldDecrypt, consolidationId },
    )}`,
    errorMessage: 'Downloading form pdf failed',
  });

export const updateFormValues = ({ formId, period, filingTypeId, businessEntityId, body }) =>
  httpPut({
    route: `/api/shared/view-tax-return/form-data/${formId}/${period}/${filingTypeId}/${businessEntityId}`,
    errorMessage: 'Updating form values failed',
    body,
  });
