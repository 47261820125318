const Yup = require('yup');

const createOrUpdateFilingGroupSchema = Yup.object().shape({
  taxYear: Yup.string().required().label('Tax Year'),
  period: Yup.string().required().label('Period'),
  filingGroupId: Yup.string().required().label('Filing Group Id').max(10, 'Max is 10 Characters'),
  description: Yup.string().nullable().max(100, 'Max is 100 Characters'),
  filingGroupIdOfCopiedFilingGroup: Yup.string()
    .nullable()
    .label('The Filing Group Id where the values are sourced from'),
  federalReturnType: Yup.string().oneOf(['1120', '1120PC', '1120L']),
});

const getCreateOrUpdateFilingGroupUISchema = ({ filingGroups, isEditMode }) =>
  createOrUpdateFilingGroupSchema.concat(
    Yup.object().shape({
      filingGroupId: Yup.string()
        .required()
        .label('Filing Group Id')
        .max(10, 'Max is 10 Characters')
        .uniqueInList({
          message: 'The Filing Group Id already exists, a unique Id is required before saving',
          list: filingGroups,
          mapper: ({ filingGroupId }) => filingGroupId,
          shouldSkipTest: isEditMode,
        }),
    }),
  );

module.exports = {
  createOrUpdateFilingGroupSchema,
  getCreateOrUpdateFilingGroupUISchema,
};
