import { useSelector } from 'react-redux';

import { globalContextSelector } from '../../../shared/store/selectors';
import { useQueryGetManualFederalProformaSubmissionIdOptions } from '../../../shared/queries/eFileV2';
import { GlobalContext } from '../../../../../common/types';

const useGetManualFederalProformaSubmissionIdOptions = () => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const {
    data: manualFederalProformaSubmissionIdOptions,
    isFetching: isFetchingFederalProformaSubmissionIdOption,
  } = useQueryGetManualFederalProformaSubmissionIdOptions({
    params: { taxYear: globalContext.taxYear || '' },
    enabled: Boolean(globalContext.taxYear),
  });
  return {
    manualFederalProformaSubmissionIdOptions,
    isFetchingFederalProformaSubmissionIdOption,
  };
};

export default useGetManualFederalProformaSubmissionIdOptions;
