import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@pwc/appkit-react';
import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

import withPermissions from '../../shared/authorization/withPermissionsHoc.container';
import { customerPermissionsPropTypes } from '../../shared/propTypes/customerPropTypes';
import {
  fetchLockIndicator,
  performEntityDataCalculation,
  loadAllFormDataForSeparate,
  loadAllFormDataForConsolidated,
} from '../../shared/store/actions';
import {
  periodSelector,
  taxYearSelector,
  filingTypeIdSelector,
  consolidationIdSelector,
  isEntityLockedSelector,
  isFetchingEntityLockIndicatorSelector,
} from '../../shared/store/selectors';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import Loading from '../../shared/displayComponents/loading.component';
import {
  ActionMenu,
  ActionMenuItem,
} from '../../shared/displayComponents/actionMenu/actionMenu.component';
import { filingTypes } from '../../shared/constants';

import TaxCalculation from './taxCalculation.container';
import StateSpecificInformation from './stateSpecificInformation/stateSpecificInformation.container';
import ReturnWorkspaceForms from './returnWorkspaceForms.component';
import { SUBPATH_TO_NAMESPACE } from './constants';

const TABS_TYPES = {
  TAX_CALCULATION: 'TAX_CALCULATION',
  STATE_SPECIFIC_INFORMATION: 'STATE_SPECIFIC_INFORMATION',
  FORMS: 'FORMS',
};

const tabsDefinitions = [
  { type: TABS_TYPES.TAX_CALCULATION, label: 'Tax Calculation' },
  { type: TABS_TYPES.STATE_SPECIFIC_INFORMATION, label: 'State Specific Information' },
  { type: TABS_TYPES.FORMS, label: 'Forms' },
];

const ReturnWorkspaceDashboard = ({
  customerPermissions,

  match: { path },
  history,
  location,

  taxYear,
  period,
  orgId,
  consolidationId,
  jurisdictionId,
  filingTypeId,

  showConfirmModal,

  fetchLockIndicator,
  isEntityLocked,
  isFetchingEntityLockIndicator,

  performEntityDataCalculation,
  loadAllFormDataForSeparate,
  loadAllFormDataForConsolidated,

  hasUserPermissionsToEdit,
}) => {
  const workspace = SUBPATH_TO_NAMESPACE[path];
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);
  const isContextReady = taxYear && period && orgId && jurisdictionId;

  const hasPermissionToCalculateEntityData = useMemo(
    () =>
      hasPermissionToAccess({
        customerPermissions,
        permissionType: PERMISSION_TYPE.EDIT,
        routeDefinitions: [PERMISSION.ENTITY_DATA_CALCULATION],
      }),
    [customerPermissions],
  );

  useEffect(() => {
    if (location.search && location.search.includes('state-specific-information')) {
      setActiveTab(TABS_TYPES.STATE_SPECIFIC_INFORMATION);
      history.replace();
    }
  }, [setActiveTab, location, history]);

  useEffect(() => {
    if (filingTypeId === filingTypes.SEPARATE && isContextReady) {
      fetchLockIndicator({ taxYear, period, orgId, jurisdictionId });
    }
  }, [fetchLockIndicator, isContextReady, filingTypeId, taxYear, period, orgId, jurisdictionId]);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.TAX_CALCULATION:
        return <TaxCalculation workspace={workspace} />;
      case TABS_TYPES.STATE_SPECIFIC_INFORMATION:
        return (
          <StateSpecificInformation
            orgId={orgId}
            jurisdictionId={jurisdictionId}
            hasUserPermissionsToEdit={hasUserPermissionsToEdit}
          />
        );
      case TABS_TYPES.FORMS:
        return (
          <ReturnWorkspaceForms
            workspace={workspace}
            taxYear={taxYear}
            period={period}
            filingTypeId={filingTypeId}
            businessEntityId={filingTypeId === filingTypes.SEPARATE ? orgId : consolidationId}
            jurisdictionId={jurisdictionId}
          />
        );
      default:
        throw new Error('Unsupported tab type');
    }
  };

  const initiateLoadAllFormData = useCallback(
    () =>
      showConfirmModal({
        title: 'Load All Form Data',
        text: 'Are you sure you want to load all form data for this entity?',
        confirmCallback: async () =>
          filingTypeId === filingTypes.CONSOLIDATED
            ? await loadAllFormDataForConsolidated({ taxYear, period, consolidationId })
            : await loadAllFormDataForSeparate({
                taxYear,
                period,
                entityId: orgId,
                jurisdictionId,
              }),
      }),
    [
      showConfirmModal,
      loadAllFormDataForSeparate,
      loadAllFormDataForConsolidated,
      filingTypeId,
      taxYear,
      period,
      orgId,
      consolidationId,
      jurisdictionId,
    ],
  );

  const onEntityDataCalculationClick = useCallback(() => {
    showConfirmModal({
      title: 'Calculate Entity Data',
      text: 'Calculate the current entity in context?',
      confirmCallback: async () =>
        await performEntityDataCalculation({
          taxYear,
          period,
          jurisdictionId,
          orgId,
        }),
    });
  }, [showConfirmModal, performEntityDataCalculation, taxYear, period, orgId, jurisdictionId]);

  const isLoadingDataForActionMenu = !isContextReady || isFetchingEntityLockIndicator;

  const locked = filingTypeId === filingTypes.SEPARATE && isEntityLocked;

  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }, index) => (
              <Tab id={type} value={type} label={label} key={index} />
            ))}
          </Tabs>
          <Loading isLoading={isLoadingDataForActionMenu} small>
            <ActionMenu>
              {hasUserPermissionsToEdit && hasPermissionToCalculateEntityData && (
                <ActionMenuItem
                  onClick={onEntityDataCalculationClick}
                  text="Calculate Entity Data"
                  locked={locked}
                />
              )}

              <ActionMenuItem onClick={initiateLoadAllFormData} text="Load All Form Data" />
            </ActionMenu>
          </Loading>
        </div>
      </div>
      {getActiveTabComponent()}
    </>
  );
};

ReturnWorkspaceDashboard.propTypes = {
  customerPermissions: customerPermissionsPropTypes,

  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }),
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,

  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  consolidationId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  filingTypeId: PropTypes.string,

  showConfirmModal: PropTypes.func.isRequired,

  fetchLockIndicator: PropTypes.func.isRequired,
  isEntityLocked: PropTypes.bool,
  isFetchingEntityLockIndicator: PropTypes.bool.isRequired,

  loadAllFormDataForSeparate: PropTypes.func.isRequired,
  loadAllFormDataForConsolidated: PropTypes.func.isRequired,
  performEntityDataCalculation: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default withPermissions(
  connect(
    state => ({
      taxYear: taxYearSelector(state),
      period: periodSelector(state),
      filingTypeId: filingTypeIdSelector(state),
      consolidationId: consolidationIdSelector(state),
      isEntityLocked: isEntityLockedSelector(state),
      isFetchingEntityLockIndicator: isFetchingEntityLockIndicatorSelector(state),
    }),
    {
      showConfirmModal,
      fetchLockIndicator,
      performEntityDataCalculation,
      loadAllFormDataForSeparate,
      loadAllFormDataForConsolidated,
    },
  )(ReturnWorkspaceDashboard),
);
