import numeralFormatter from './numeralFormatter';

export const getDecimalPlacesFormat = numberOfDecimals => {
  if (numberOfDecimals <= 0) {
    return '';
  }
  return `.${Array(numberOfDecimals).fill('0').join('')}`;
};

export const getValueFormat = ({ percentage = false, decimalPlaces = 0, commas = false } = {}) => {
  const decimalPlacesToUse = percentage && !decimalPlaces ? 2 : decimalPlaces;
  const decimalPlacesFormat = getDecimalPlacesFormat(decimalPlacesToUse);
  const commaSeparatorsFormat = commas ? '0,' : '';
  return `(${commaSeparatorsFormat}0${decimalPlacesFormat}${percentage ? '%' : ''})`;
};

const amountFormatter = ({ value, props }) => {
  const valueToFormat = props && props.percentage ? parseFloat(value / 100) : Number(value);
  const format = getValueFormat(props);
  return numeralFormatter({ value: valueToFormat, format });
};

export default amountFormatter;
