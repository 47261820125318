export const calcSpecTypes = {
  BASE: 'BASE',
  CONSOL: 'CONSOL',
  CONSPB: 'CONSPB',
  K1: 'K1',
};

export const WARNING_MODAL_TITLE = 'Edit mode still active';
export const WARNING_MODAL_MESSAGE =
  'Grid data edit mode is still active. Please save or cancel changes before changing context';
