import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Modal from '@tls/ui-modal';

import globalContextPropTypes from '../shared/propTypes/globalContext';
import { consolidationFullNameSelector } from '../shared/store/consolidations.selectors';
import useFetch from '../shared/hooks/useFetch.hook';
import { fetchNavigatorBinderData } from '../navigator/store';
import { filingTypes } from '../shared/constants';

import * as api from './store/api';
import { updateBinderItems } from './store/actions';
import { binderItemTypes, selectAllOption } from './constants';
import AddBinderItemForm from './addBinderItemForm.component';
import { binderItemPropTypes } from './binderMaintenance.propTypes';
import { binderItemsSelector, isFetchingBinderItemsSelector } from './store/selectors';

const AddBinderItemModal = ({
  globalContext,
  binderId,
  refetchGridData,

  hideModal,
  visible,

  consolidationFullName,

  updateBinderItems,
  fetchNavigatorBinderData,

  binderItems,
  isFetchingBinderItems,
}) => {
  const formInitialValues = useMemo(
    () => ({
      documentType: binderItemTypes.FORM,
      documentLevel: globalContext.params.filingTypeId,
      businessEntityId: globalContext.params.businessEntityId,
      forms: [],
      attachments: [],
    }),
    [globalContext],
  );

  const {
    data: binderForms,
    isFetching: isFetchingBinderForms,
    fetch: fetchBinderForms,
  } = useFetch({ action: api.fetchBinderForms });
  const {
    data: binderAttachments,
    isFetching: isFetchingBinderAttachments,
    fetch: fetchBinderAttachments,
  } = useFetch({ action: api.fetchBinderAttachments });
  const {
    data: overriddenBinderItems,
    isFetching: isFetchingOverriddenBinderItems,
    fetch: fetchOverriddenBinderItems,
    resetData: resetOverriddenBinderItems,
  } = useFetch({ action: api.fetchBinderItems, initialData: null });
  const { data: entities, isFetching: isFetchingEntities, fetch: fetchEntities } = useFetch({
    action: api.fetchEntities,
  });

  const binderFormsOptions = useMemo(
    () => (binderForms || []).map(({ formId, formName }) => ({ value: formId, label: formName })),
    [binderForms],
  );

  const binderAttachmentsOptions = useMemo(
    () => (binderAttachments || []).map(({ id, fileName }) => ({ value: id, label: fileName })),
    [binderAttachments],
  );

  const entitiesOptions = useMemo(
    () =>
      (entities || []).map(({ entityId, entityName }) => ({
        value: entityId,
        label: `${entityId} - ${entityName}`,
      })),
    [entities],
  );

  useEffect(() => {
    if (binderId && globalContext.isReady) {
      fetchBinderAttachments({
        binderId,
        taxYear: globalContext.taxYear,
        period: globalContext.period,
        jurisdictionId: globalContext.jurisdictionId,
      });
    }
  }, [fetchBinderAttachments, binderId, globalContext]);

  useEffect(() => {
    if (globalContext.isReady) {
      fetchEntities({ globalContext });
    }
  }, [fetchEntities, globalContext]);

  const fetchForms = useCallback(
    ({ filingTypeId, businessEntityId, areAllEntitiesInConsolidation }) =>
      fetchBinderForms(globalContext, {
        filingTypeId,
        businessEntityId,
        areAllEntitiesInConsolidation,
      }),
    [fetchBinderForms, globalContext],
  );

  const fetchExistingBinderItems = useCallback(
    ({ filingTypeId }) => {
      if (binderId) {
        fetchOverriddenBinderItems({ globalContext, binderId, paramsToOverride: { filingTypeId } });
      }
    },
    [fetchOverriddenBinderItems, globalContext, binderId],
  );

  const save = useCallback(
    async (
      { documentType, documentLevel, businessEntityId, forms, attachments },
      { resetForm },
    ) => {
      await updateBinderItems({
        globalContext,
        binderId,
        businessEntityId,
        documentType,
        documentLevel,
        binderItemIds: [...new Set(documentType === binderItemTypes.FORM ? forms : attachments)],
        areAllEntitiesInConsolidation:
          businessEntityId === selectAllOption.value &&
          documentType === binderItemTypes.FORM &&
          documentLevel === filingTypes.SEPARATE,
      });
      resetForm();
      hideModal();
      refetchGridData();
      fetchNavigatorBinderData({ binderId });
    },
    [
      hideModal,
      updateBinderItems,
      refetchGridData,
      fetchNavigatorBinderData,
      binderId,
      globalContext,
    ],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        title="Add Item"
        closeAction={hideModal}
        visible={visible}
        submitAction={formikProps.submitForm}
        submitText="Save"
        dismissText="Cancel"
        dismissAction={hideModal}
        submitDisabled={!formikProps.values.businessEntityId}
      >
        <AddBinderItemForm
          {...formikProps}
          entitiesOptions={entitiesOptions}
          consolidationFullName={consolidationFullName}
          isFetchingEntities={isFetchingEntities}
          isFetchingBinderForms={isFetchingBinderForms}
          binderForms={binderFormsOptions}
          isFetchingBinderAttachments={isFetchingBinderAttachments}
          binderAttachments={binderAttachmentsOptions}
          fetchForms={fetchForms}
          fetchExistingDocuments={fetchExistingBinderItems}
          existingDocuments={overriddenBinderItems || binderItems || []}
          isFetchingExistingDocuments={isFetchingBinderItems || isFetchingOverriddenBinderItems}
          resetExistingDocuments={resetOverriddenBinderItems}
          isBinderSeparate={globalContext.isSeparate}
          filingTypeId={globalContext.params.filingTypeId}
          businessEntityId={globalContext.params.businessEntityId}
        />
      </Modal>
    ),
    [
      hideModal,
      visible,
      entitiesOptions,
      consolidationFullName,
      isFetchingEntities,
      isFetchingBinderForms,
      binderFormsOptions,
      isFetchingBinderAttachments,
      binderAttachmentsOptions,
      fetchForms,
      fetchExistingBinderItems,
      binderItems,
      overriddenBinderItems,
      isFetchingBinderItems,
      isFetchingOverriddenBinderItems,
      resetOverriddenBinderItems,
      globalContext,
    ],
  );

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize
      validateOnBlur={false}
      render={renderForm}
      onSubmit={save}
    >
      {renderForm}
    </Formik>
  );
};

AddBinderItemModal.propTypes = {
  globalContext: globalContextPropTypes,
  binderId: PropTypes.number,
  refetchGridData: PropTypes.func.isRequired,

  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  consolidationFullName: PropTypes.string,

  updateBinderItems: PropTypes.func.isRequired,
  fetchNavigatorBinderData: PropTypes.func.isRequired,

  isFetchingBinderItems: PropTypes.bool,
  binderItems: PropTypes.arrayOf(binderItemPropTypes),
};

export default connect(
  state => ({
    consolidationFullName: consolidationFullNameSelector(state),
    binderItems: binderItemsSelector(state),
    isFetchingBinderItems: isFetchingBinderItemsSelector(state),
  }),
  {
    updateBinderItems,
    fetchNavigatorBinderData,
  },
)(AddBinderItemModal);
