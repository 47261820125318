import React, { useState, useMemo, useCallback, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@pwc/appkit-react';
import { RadioGroup, Radio } from '@pwc/appkit-react/lib/Radio';
import Spinner from '@tls/ui-spinner';

import SlideIn from '../../shared/displayComponents/slideIn/slideIn.component';
import { useQueryConsolidatedFilingEntitiesOptions } from '../../shared/queries/consolidationFilingEntities';
import { useQueryConsolidationEntityFormsByPeriod } from '../../shared/queries/binderMaintenance';
import { ReturnItemTypes } from '../enums';
import { GlobalContextParams } from '../../../../common/types';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { FilingTypes } from '../../shared/enums';

import styles from './addReturnItems.module.scss';
import useAddReturnItemsLogic from './useAddReturnItemsLogic';
import AddReturnItems from './addReturnItems.component';

const selectAllOption = {
  label: 'All Entities in Consolidation',
  value: 'SELECT_ALL',
};
interface AddFormsPanelProps {
  isPanelVisible: boolean;
  hidePanel: () => void;
  returnFormItemAndOrgIds?: Array<{ itemId: string; orgId: string | null }>;
  isStackedReturn?: boolean | null;
  taxReturnContext: GlobalContextParams;
}

const AddFormsPanel = ({
  hidePanel,
  isPanelVisible,
  returnFormItemAndOrgIds = [],
  isStackedReturn,
  taxReturnContext,
}: AddFormsPanelProps) => {
  const { returnId } = useParams<{ returnId: string }>();
  const [entitySelectValue, setEntitySelectValue] = useState('');
  const [filingTypeRadioValue, setFilingTypeRadioValue] = useState(FilingTypes.CONSOLIDATED);

  const isStackedConsolidatedReturn =
    isStackedReturn && taxReturnContext.filingTypeId === FilingTypes.CONSOLIDATED;

  const { data: forms, isLoading: isLoadingForms } = useQueryConsolidationEntityFormsByPeriod({
    params: {
      areAllEntitiesInConsolidation:
        filingTypeRadioValue === FilingTypes.SEPARATE &&
        entitySelectValue === selectAllOption.value,
      excludeFilingForms: false,
      ...taxReturnContext,
      ...(isStackedConsolidatedReturn && {
        filingTypeId: filingTypeRadioValue,
        businessEntityId:
          filingTypeRadioValue === FilingTypes.SEPARATE &&
          entitySelectValue &&
          entitySelectValue !== selectAllOption.value
            ? entitySelectValue
            : taxReturnContext.businessEntityId,
        jurisdictionId: taxReturnContext.jurisdictionId,
      }),
    },
    enabled: Boolean(isPanelVisible),
  });

  const {
    data: consolidatedFilingEntitiesOptions,
    isLoading: isLoadingConsolidatedFilingEntitiesOptions,
  } = useQueryConsolidatedFilingEntitiesOptions({
    params: {
      taxYear: taxReturnContext.taxYear || '',
      period: taxReturnContext.period || '',
      consolidationId: taxReturnContext.businessEntityId || '',
      jurisdictionId: taxReturnContext.jurisdictionId || '',
    },
    enabled: Boolean(
      isPanelVisible &&
        isStackedConsolidatedReturn &&
        taxReturnContext.taxYear &&
        taxReturnContext.period &&
        taxReturnContext.businessEntityId &&
        taxReturnContext.jurisdictionId,
    ),
  });

  const hidePanelAndResetState = useCallback(() => {
    hidePanel();
    setFilingTypeRadioValue(FilingTypes.CONSOLIDATED);
    setEntitySelectValue('');
  }, [hidePanel]);

  const onChangeFilingType = useCallback((_: ChangeEvent, value: FilingTypes) => {
    setFilingTypeRadioValue(value);
  }, []);

  const onChangeEntity = useCallback(({ value }: { value: string | null }) => {
    setEntitySelectValue(value || '');
  }, []);

  const filingTypeRadio = useMemo(
    () =>
      isStackedConsolidatedReturn && (
        <div className={styles.control}>
          <h6>Filing Type - Document Level</h6>
          <RadioGroup
            name="filingTypeRadio"
            defaultValue={FilingTypes.CONSOLIDATED}
            onChange={onChangeFilingType}
          >
            <Radio value={FilingTypes.CONSOLIDATED} className={styles.radioOptionWrapper}>
              Consolidated
            </Radio>
            <Radio value={FilingTypes.SEPARATE} className={styles.radioOptionWrapper}>
              Separate
            </Radio>
          </RadioGroup>
        </div>
      ),
    [onChangeFilingType, isStackedConsolidatedReturn],
  );

  const entitySelectOptions = useMemo(
    () => [selectAllOption, ...(consolidatedFilingEntitiesOptions || [])],
    [consolidatedFilingEntitiesOptions],
  );

  const entitySelect = useMemo(
    () =>
      isStackedConsolidatedReturn &&
      filingTypeRadioValue === FilingTypes.SEPARATE && (
        <div className={styles.control}>
          {isLoadingConsolidatedFilingEntitiesOptions ? (
            <Spinner isLoading />
          ) : (
            <SDKCustomSelect
              appkitLabel="ENTITY"
              name="entity"
              options={entitySelectOptions}
              value={entitySelectValue}
              onChange={onChangeEntity}
              virtualized
            />
          )}
        </div>
      ),
    [
      onChangeEntity,
      isStackedConsolidatedReturn,
      filingTypeRadioValue,
      entitySelectOptions,
      entitySelectValue,
      isLoadingConsolidatedFilingEntitiesOptions,
    ],
  );

  const noResultsMessage = useMemo(
    () =>
      isStackedConsolidatedReturn && filingTypeRadioValue === FilingTypes.SEPARATE
        ? 'No forms showing due to no selection in entity dropdown.'
        : 'All available forms have already been added.',
    [filingTypeRadioValue, isStackedConsolidatedReturn],
  );

  const existingReturnItemsIds = useMemo(
    () =>
      filingTypeRadioValue === FilingTypes.CONSOLIDATED
        ? returnFormItemAndOrgIds.map(({ itemId }) => itemId)
        : returnFormItemAndOrgIds
            .filter(returnForm => returnForm.orgId === entitySelectValue)
            .map(({ itemId }) => itemId),
    [entitySelectValue, filingTypeRadioValue, returnFormItemAndOrgIds],
  );

  const {
    visibleItems,
    selectedItemIds,
    shouldShowSelectedItems,
    isAddingReturnItems,
    isListItemSelected,
    handleSearch,
    handleToggleShowSelectedItems,
    handleCheckboxChange,
    handleHidePanel,
    handleAddReturnItems,
  } = useAddReturnItemsLogic({
    returnId,
    hidePanel: hidePanelAndResetState,
    returnItemType: ReturnItemTypes.FORM,
    businessEntityId:
      filingTypeRadioValue === FilingTypes.SEPARATE && entitySelectValue
        ? entitySelectValue
        : taxReturnContext.businessEntityId,
    filingType: isStackedConsolidatedReturn ? filingTypeRadioValue : null,
    existingReturnItemsIds,
    items: forms || [],
    itemIdKey: 'formId',
    itemLabelKey: 'formName',
    areAllEntitiesInConsolidation: entitySelectValue === selectAllOption.value,
  });

  return (
    <SlideIn
      className={styles.panel}
      isOpen={isPanelVisible}
      onRequestClose={handleHidePanel}
      width="480px"
      closeIconName="close"
      title="Add Forms"
    >
      <div className={styles.content}>
        {filingTypeRadio}
        {entitySelect}
        <AddReturnItems
          items={visibleItems}
          isLoadingItems={isLoadingForms}
          noResultsMessage={noResultsMessage}
          filterText="Show selected forms"
          handleSearch={handleSearch}
          shouldShowSelectedItems={shouldShowSelectedItems}
          handleToggleShowSelectedItems={handleToggleShowSelectedItems}
          isListItemSelected={isListItemSelected}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>
      <div className={styles.footer}>
        {selectedItemIds.length ? (
          <span className={styles.selectedItemsInformation}>
            Selected {selectedItemIds.length} Form{selectedItemIds.length > 1 && 's'}
          </span>
        ) : null}
        <Button
          className={styles.footerButton}
          size="lg"
          kind="secondary"
          onClick={handleHidePanel}
        >
          cancel
        </Button>
        <Button
          className={styles.footerButton}
          size="lg"
          disabled={!selectedItemIds.length}
          isLoading={isAddingReturnItems}
          onClick={handleAddReturnItems}
        >
          add
        </Button>
      </div>
    </SlideIn>
  );
};

export default AddFormsPanel;
