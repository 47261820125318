import { filingTypes } from '@tls/state-helpers';

import getApiActionTypesHandlers from '../getApiActionTypesHandlers';
import reducerFactory from '../reducerFactory';
import { SELECT_TAX_YEAR } from '../types';
import { SELECT_JURISDICTION_ID } from '../../../admin/taxRatesAndConstantsMaintenance/store/types/shared';
import {
  SELECT_JURISDICTION_ID as SELECT_DEVELOPMENT_JURISDICTION_ID,
  SELECT_TAX_YEAR as SELECT_DEVELOPMENT_TAX_YEAR,
} from '../../../development/store/types';

import { fetchConstantsTypes, SELECT_CONSTANT_TYPE, SELECT_CONSTANTS_PERIOD } from './types';

export const constantsInitialState = {
  constants: {
    columnsBlueprint: [],
    data: [],
  },
  isFetchingConstants: false,
  constantType: filingTypes.SEPARATE,
  constantsPeriod: 0,
};

const constantsReducer = reducerFactory({
  initialState: constantsInitialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchConstantsTypes,
      payloadKey: 'constants',
      fetchingKey: 'isFetchingConstants',
    }),
    [SELECT_TAX_YEAR]: state => ({
      ...constantsInitialState,
      constantType: state.constantType,
      constantsPeriod: state.constantsPeriod,
    }),
    [SELECT_JURISDICTION_ID]: state => ({
      ...constantsInitialState,
      constantType: state.constantType,
      constantsPeriod: state.constantsPeriod,
    }),
    [SELECT_DEVELOPMENT_TAX_YEAR]: ({ constantType, constantsPeriod }) => ({
      ...constantsInitialState,
      constantType,
      constantsPeriod,
    }),
    [SELECT_DEVELOPMENT_JURISDICTION_ID]: ({ constantType, constantsPeriod }) => ({
      ...constantsInitialState,
      constantType,
      constantsPeriod,
    }),
    [SELECT_CONSTANT_TYPE]: ({ constantsPeriod }, payload) => ({
      ...constantsInitialState,
      constantsPeriod,
      constantType: payload,
    }),
    [SELECT_CONSTANTS_PERIOD]: ({ constantType }, payload) => ({
      ...constantsInitialState,
      constantType,
      constantsPeriod: payload,
    }),
  },
});

export default constantsReducer;
