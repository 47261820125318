import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../storeHelpers';
import { formsByPeriodSelector } from '../selectors';

const formDataSelector = ({ shared: { forms } }) => forms.formData;
export const isFetchingFormDataSelector = ({ shared: { forms } }) => forms.isFetchingFormData;

export const memberOrFormInstanceIdSelector = ({ shared: { forms } }) =>
  forms.memberOrFormInstanceId;

export const formDatasetDefinitionIdSelector = createSelector(
  [formDataSelector],
  formData => formData.fieldsData.datasetDefinitionId,
);
export const formDatasetInstanceNameSelector = createSelector(
  [formDataSelector],
  formData => formData.fieldsData.datasetInstanceName,
);
export const formHTMLSelector = createSelector(
  [formDataSelector],
  formData => formData.form && formData.form.HTML_IMAGE,
);

export const formStatusSelector = createSelector(
  [formDataSelector],
  formData => formData.form && formData.form.formStatus,
);

export const inDevelopmentMessageSelector = createSelector(
  [formDataSelector],
  formData => formData.form && formData.form.inDevelopmentMessage,
);

export const fieldsValuesSelector = createSelector(
  [formDataSelector],
  formData => formData.fieldsData.fieldsValues,
);
export const watermarkSelector = createSelector(
  [formDataSelector],
  formData => formData.fieldsData.formWatermark,
);

export const formDataInstancesSelector = createSelector(
  [formDataSelector],
  formData => formData.fieldsData.dataInstances,
);

export const formInstancesOptionsSelector = optionsSelectorFactory({
  listSelector: formDataInstancesSelector,
  labelKey: 'identifier',
  valueKey: 'id',
});

export const showFormInstanceDataLinkSelector = createSelector(
  [formDataSelector],
  formData => formData.fieldsData.showFormInstanceDataLink,
);

export const swpFormIdSelector = queryString =>
  createSelector(formsByPeriodSelector, forms => {
    const query = new URLSearchParams(queryString);
    const formId = query.get('formId');

    const form = forms.find(form => form.formId === formId);

    return form ? form.swpFormId : null;
  });

export const isDownloadingFormPdfSelector = ({ shared: { forms } }) => forms.isDownloadingFormPdf;
