import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import SessionGuard from '@tls/ui-session-guard';

import AppLoading from './shared/displayComponents/appLoadingIndicator';
import Menu from './shared/menu/menu.component';
import Footer from './shared/displayComponents/footer/footer.component';
import { useFetchPermissions } from './shared/store/hooks';
import { checkAuthorizationStatus } from './shared/store/actions';
import { getAccessTokenExpirationDate } from './utils/tokenUtils';
import {
  customerDataSelector,
  customerRoleIdSelector,
  areAnyClientsAvailableSelector,
  isFetchingPermissionsDataErrorSelector,
} from './shared/store/selectors';
import InitializationError from './shared/error/initializationError.component';
import LeftNavigationPanel from './navigator/navigationPanel.container';
import SLT from './slt.component';
import { errorMessages } from './shared/error/constants';
import ExpireMessageModal from './homePage/expireMessageModal.component';

const ApplicationRoutesComponent = () => {
  useEffect(() => {
    Modal.setAppElement('body');
  }, []);
  const dispatch = useDispatch();
  const customerData = useSelector(customerDataSelector);
  const customerRoleId = useSelector(customerRoleIdSelector);
  const areAnyClientsAvailable = useSelector(areAnyClientsAvailableSelector);
  const isFetchingPermissionsDataError = useSelector(isFetchingPermissionsDataErrorSelector);

  const refreshSession = useCallback(() => {
    dispatch(checkAuthorizationStatus());
  }, [dispatch]);

  useFetchPermissions(areAnyClientsAvailable);
  const isLoading = !customerData.customerId || (!customerRoleId && areAnyClientsAvailable);
  const permissionsDataRequestErrorMessage = isFetchingPermissionsDataError
    ? errorMessages.USER_PERMISSIONS_ERROR_MESSAGE
    : '';
  const fullWidthPage = !areAnyClientsAvailable;

  if (isFetchingPermissionsDataError) {
    return <InitializationError errorMessage={permissionsDataRequestErrorMessage} />;
  }

  return (
    <>
      <Menu />
      <div className="router-root">
        <LeftNavigationPanel fullWidthPage={fullWidthPage} />
        <div className="app-container">
          <SessionGuard
            extractExpirationDate={getAccessTokenExpirationDate}
            refreshSession={refreshSession}
          />
          <AppLoading isLoading={isLoading} showNoClientAccessMessage={!areAnyClientsAvailable}>
            <SLT areAnyClientsAvailable={areAnyClientsAvailable} />
          </AppLoading>
          {!isLoading && <Footer />}
        </div>
      </div>
      <ExpireMessageModal />
    </>
  );
};

export default ApplicationRoutesComponent;
