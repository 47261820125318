import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { customerPermissionsSelector } from '../../store/selectors';
import hasRequiredPermissionsToView from '../../authorization/hasRequiredPermissionsToView';

import Card from './card.component';

const AuthorizedCard = ({
  title,
  description,
  icon,
  route,
  permissionRoute = route,
  accessOnlyWithEditPermission = false,
  onClick,
  tooltipContent,
  locked = false,
  disabled = false,
  bottomCardElement,
  useSmallCardFormatWithBottomCardElement = false,
}) => {
  const customerPermissions = useSelector(customerPermissionsSelector);

  return hasRequiredPermissionsToView(
    customerPermissions,
    permissionRoute,
    accessOnlyWithEditPermission,
  ) ? (
    <Card
      title={title}
      icon={icon}
      description={description}
      route={route}
      onClick={onClick}
      tooltipContent={tooltipContent}
      locked={locked}
      disabled={disabled}
      bottomCardElement={bottomCardElement}
      useSmallCardFormatWithBottomCardElement={useSmallCardFormatWithBottomCardElement}
    />
  ) : null;
};

AuthorizedCard.propTypes = {
  title: PropTypes.node,
  description: PropTypes.string,
  icon: PropTypes.string,
  route: PropTypes.string,
  permissionRoute: PropTypes.string,
  accessOnlyWithEditPermission: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipContent: PropTypes.node,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  bottomCardElement: PropTypes.element,
  useSmallCardFormatWithBottomCardElement: PropTypes.bool,
};

export default AuthorizedCard;
