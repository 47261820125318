export const isFetchingWorkpaperInstanceDataSelector = ({ workpaperInstance }) =>
  workpaperInstance.isFetchingData;

export const workpaperInstanceColumnsBlueprintSelector = ({ workpaperInstance }) =>
  workpaperInstance.data.columnsBlueprint;

export const workpaperInstanceDataSelector = ({ workpaperInstance }) => workpaperInstance.data.rows;

export const lockIndicatorSelector = ({ workpaperInstance }) =>
  workpaperInstance.data.lockIndicator;

export const isUpdatingWorkpaperInstanceSelector = ({ workpaperInstance }) =>
  workpaperInstance.isUpdatingWorkpaperInstance;

export const isFetchingDatasetDefinitionMetaDataSelector = ({ workpaperInstance }) =>
  workpaperInstance.isFetchingDatasetDefinitionMetaData;

export const datasetDefinitionSelector = ({ workpaperInstance }) =>
  workpaperInstance.datasetDefinition;
