import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import styles from '../../shared/reports/reportParam.module.scss';
import { globalContextSelector } from '../../shared/store/selectors';

import columnDefinitions from './stateTaxableIncomeReconciliation.columnDefinitions';

const DISPLAY_BY_VALUES = {
  ENTITY: 'ENTITY',
  FILING_GROUP: 'FILING_GROUP',
};

const DISPLAY_BY_OPTIONS = [
  { value: DISPLAY_BY_VALUES.ENTITY, label: 'Entity' },
  { value: DISPLAY_BY_VALUES.FILING_GROUP, label: 'Filing Group' },
];

const endpointBasePath = '/api/reports/state-taxable-income-reconciliation';

const StateTaxableIncomeReconciliation = () => {
  const { entityId, jurisdictionId } = useSelector(globalContextSelector);

  const [displayBy, setDisplayBy] = useState(DISPLAY_BY_OPTIONS[0].value);
  const displayByEntity = displayBy === DISPLAY_BY_VALUES.ENTITY;

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const jurisdictionIdParam = useReportParam({
    endpointBasePath,
    paramName: 'jurisdictionId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
      }),
      [taxYearParam.paramValue],
    ),
    defaultParamValue: jurisdictionId,
  });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
    defaultParamValue: entityId,
  });

  const filingGroupParam = useReportParam({
    endpointBasePath,
    paramName: 'filingGroup',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        jurisdictionId: jurisdictionIdParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue, jurisdictionIdParam.paramValue],
    ),
  });

  const allFilingGroupsSelected =
    (filingGroupParam.paramValue || []).length === (filingGroupParam.paramData || []).length;

  const businessGroupParam = useReportParam({
    endpointBasePath,
    paramName: 'businessGroup',
    params: useMemo(() => ({}), []),
  });

  const allBusinessGroupsSelected =
    (businessGroupParam.paramValue || []).length === (businessGroupParam.paramData || []).length;

  const isFetchingAnyParam = checkIsFetchingAnyParam([
    taxYearParam,
    periodParam,
    jurisdictionIdParam,
    entityIdParam,
    filingGroupParam,
    businessGroupParam,
  ]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        displayBy,
        jurisdictionId: jurisdictionIdParam.paramValue,
        ...(displayByEntity
          ? { entityId: entityIdParam.paramValue }
          : { filingGroup: filingGroupParam.paramValue }),
        ...(!displayByEntity &&
          !allBusinessGroupsSelected && { businessGroup: businessGroupParam.paramValue }),
        allBusinessGroupsSelected,
        allFilingGroupsSelected,
      }),
      [
        displayByEntity,
        taxYearParam.paramValue,
        periodParam.paramValue,
        displayBy,
        jurisdictionIdParam.paramValue,
        entityIdParam.paramValue,
        filingGroupParam.paramValue,
        businessGroupParam.paramValue,
        allBusinessGroupsSelected,
        allFilingGroupsSelected,
      ],
    ),
  });

  const { props, onGridReady } = useActionMenuForExport({ reportData });

  return (
    <>
      <div className="row">
        <div className={classNames('col', styles.reportParam)}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown
            label="Display By"
            param={useMemo(
              () => ({
                isFetchingParamData: false,
                paramData: DISPLAY_BY_OPTIONS,
                paramValue: displayBy,
                setParamValue: setDisplayBy,
              }),
              [displayBy],
            )}
          />
          <ReportParamDropdown label="Jurisdiction" param={jurisdictionIdParam} />
          {displayByEntity && <ReportParamDropdown label="Entity" param={entityIdParam} />}
          {!displayByEntity && (
            <ReportParamMultiSelect label="Filing Group" param={filingGroupParam} />
          )}
          {!displayByEntity && (
            <ReportParamMultiSelect label="Business Group" param={businessGroupParam} />
          )}
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData || []}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StateTaxableIncomeReconciliation;
