import { httpGetAndParse, httpPut, httpDelete } from '../../../utils/fetchService';

export const fetchTaxForm = ({ formId }) =>
  httpGetAndParse({
    route: `/api/development/tax-forms-v2/${formId}`,
    errorMessage: 'Fetching tax form failed',
  });

export const deleteTaxForm = ({ taxYear, jurisdictionId, formId, profileId }) =>
  httpDelete({
    route: `/api/development/tax-forms-v2/${taxYear}/${jurisdictionId}/${formId}/${profileId}`,
    errorMessage: 'Deleting form failed',
  });

export const updateTaxForm = ({ formId, ...body }) =>
  httpPut({
    route: `/api/development/tax-forms-v2/${formId}`,
    errorMessage: 'Updating tax form failed',
    body,
  });

export const fetchDataModelsWithGroupedDatasetsAndDataItems = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/development/tax-forms-v2/data-models/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching data models failed',
  });

export const fetchPdfPageDataUsage = ({ pdfId }) =>
  httpGetAndParse({
    route: `/api/development/tax-forms-v2/pdf-page-data-usage/${pdfId}`,
    errorMessage: 'Fetching pdf page data usage failed',
  });
