import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { min, max, parseISO } from 'date-fns';

import useFetch from '../../shared/hooks/useFetch.hook';
import { dateFormatter } from '../../shared/formatters';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import { globalContextSelector } from '../../shared/store/selectors';
import { eFileStepsDataSelector, isFetchingEFileStepsDataSelector } from '../store/selectors';
import { runCheckTransmissionStatusApiAction } from '../store/api';
import config from '../../config';
import styles from '../styles.module.scss';

import AllSubSteps from './allSubSteps.component';
import { EFILE_ACTION, EFILE_REVIEW_STATUS_STEPS } from './constants';
import { getActionData, getReviewStatusStepData } from './getStepsData';
import { stepDataPropTypes, contextDebugInfoPropTypes } from './currentReturnFiling.propTypes';
import useTransmitStepAndTTISubmissionId from './useTransmitStepAndTTISubmissionId';
import errorsColumnDefinitions from './reviewStatusError.columnDefinitions';
import { wizardStatePropTypes } from './useWizard.hook';

const formatValueAsDate = value => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT);
const formatAcknowledgedDateTime = value => dateFormatter(value, config.AMERICAN_DATE_FORMAT);
const formatReturnStatus = value => value || 'Transmitted';

const checkTransmitStatusTableContent = [
  {
    key: 'SubmissionID',
    display: 'State Submission ID:',
  },
  {
    key: 'TransmittedDateTime',
    display: 'Transmission Date / Time:',
    formatValue: formatValueAsDate,
  },
  {
    key: 'ReturnStatus',
    display: 'Status:',
    formatValue: formatReturnStatus,
  },
  {
    key: 'AcknowledgedDateTime',
    display: 'Acknowledged time:',
    formatValue: formatAcknowledgedDateTime,
  },
];

const downloadTransmissionAcknowledgementTableContent = [
  {
    key: 'SubmissionId',
    display: 'State Submission ID:',
  },
  {
    key: 'AcceptanceStatusTxt',
    display: 'Status:',
  },
  {
    key: 'ElectronicPostmarkTs',
    display: 'Electronic postmark timestamp:',
    formatValue: formatValueAsDate,
  },
];

const stepsToSkipInOutdatedCheck = [
  // E-File setup screen steps
  'import-entity-permanent-information',
  'import-entity-information',
  'import-consolidated-and-entity-group-definition',
  'import-state-filing-jurisdictions',
  'perform-setup-calculation-profile',
  // E-File wizard transmit screen, downloading signature declaration
  'download-signature-declaration',
  // E-File wizard review status screen
  EFILE_ACTION.DOWNLOAD_TRANSMISSION_FILE,
  EFILE_ACTION.DOWNLOAD_TRANSMISSION_ACK,
  // E-File Reviewer - generate federal proforma PDF from XML
  'upload-transmission',
  'process-uploaded-transmission',
  'get-uploaded-transmission-upload-id',
  'generate-pdf',
  'download-pdf',
  // Upload external federal transmission
  'external-federal-transmission',
  'upload-external-federal-transmission',
];

const hasAnyStepNewerData = (allStepsData, step) => {
  if (!allStepsData.length) {
    return null;
  }
  const stepData = getActionData(allStepsData, step);
  if (!stepData) {
    return null;
  }

  const stepDate = [stepData]
    .map(({ createdOn, updatedOn }) => updatedOn || createdOn)
    .map(date => parseISO(date));

  const allStepsDates = allStepsData
    .filter(({ stepName }) => !stepsToSkipInOutdatedCheck.includes(stepName))
    .map(({ createdOn, updatedOn }) => updatedOn || createdOn)
    .map(date => parseISO(date));

  return max(allStepsDates) > min(stepDate);
};

const getLastTransmissionResult = eFileStepsData => {
  const checkTransmissionStatusResponse = getActionData(
    eFileStepsData,
    EFILE_ACTION.CHECK_TRANSMISSION_STATUS,
  );
  const checkTransmissionCompleted =
    get(checkTransmissionStatusResponse, 'status', null) === 'COMPLETED';

  const downloadTransmissionAcknowledgementResponse = getActionData(
    eFileStepsData,
    EFILE_ACTION.DOWNLOAD_TRANSMISSION_ACK,
  );
  const downloadAcknowledgementCompleted =
    get(downloadTransmissionAcknowledgementResponse, 'status', null) === 'COMPLETED';

  if (!checkTransmissionCompleted && !downloadAcknowledgementCompleted) {
    return null;
  }

  const transmissionStatus = downloadAcknowledgementCompleted
    ? get(
        downloadTransmissionAcknowledgementResponse,
        'ttiResponse.validation.result.acknowledgement',
        {},
      )
    : get(checkTransmissionStatusResponse, 'ttiResponse.validation.result.result.attributes', {});

  const validationErrors = get(transmissionStatus, 'ValidationErrorList.ValidationErrorGrp', null);

  const transmitStatusTableContent = downloadAcknowledgementCompleted
    ? downloadTransmissionAcknowledgementTableContent
    : checkTransmitStatusTableContent;

  return (
    <div>
      <div className={styles.wizardStepAdditionalInfo}>
        <div>
          <h6 className="mb-3">E-File Transmission status</h6>
          <hr />
          <table className={styles.transmitStatusTable}>
            <tbody>
              {transmitStatusTableContent.map(({ key, display, formatValue = null }) => (
                <tr key={key}>
                  <td>{display}</td>
                  <td>
                    {formatValue ? formatValue(transmissionStatus[key]) : transmissionStatus[key]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {validationErrors && (
        <div className={styles.transmitStatusErrors}>
          Transmission Validation Errors
          <div className="row grid-row">
            <div className="col">
              <AgGrid
                columnDefs={errorsColumnDefinitions}
                rowData={Array.isArray(validationErrors) ? validationErrors : [validationErrors]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ReviewStatus = ({
  binderFilingId,
  globalContext,

  ttiYearId,
  ttiCaseId,
  selectedTtiBinderId,
  efServiceSubmissionId,

  eFileStepsData,
  fetchStepsData,
  isFetchingEFileStepsData,

  contextDebugInfo,
  getDownloadFileComponent,
  isFetchingTtiBinders,

  wizardState,
  setStepStatus,

  sltBinderId,
}) => {
  const { currentStep } = wizardState;

  const { ttiSubmissionId } = useTransmitStepAndTTISubmissionId({
    ttiYearId,
    ttiCaseId,
    selectedTtiBinderId,
    efServiceSubmissionId,
    eFileStepsData,
    stateSubmissionType: null,
  });

  const { fetch: runCheckTransmissionStatusStep } = useFetch({
    action: runCheckTransmissionStatusApiAction,
  });

  const reviewStatusValues = useMemo(
    () => ({
      ttiYearId,
      ttiCaseId,
      binderId: selectedTtiBinderId,
      submissionId: efServiceSubmissionId,
      ttiSubmissionId,
    }),
    [ttiYearId, ttiCaseId, selectedTtiBinderId, efServiceSubmissionId, ttiSubmissionId],
  );

  const reviewStatusStepsData = getReviewStatusStepData({
    eFileStepsData,
    ...reviewStatusValues,
  });

  const setIsActionRunning = useCallback(
    value => {
      setStepStatus({
        currentStep,
        payload: {
          isStepActionRunning: value,
        },
      });
    },
    [setStepStatus, currentStep],
  );

  const callCheckTransmissionStatusAction = useCallback(
    async ({ action }) => {
      try {
        setIsActionRunning(true);
        await runCheckTransmissionStatusStep({
          action,
          globalContext,
          binderFilingId,
          sltBinderId,
          ...reviewStatusValues,
        });
      } finally {
        setIsActionRunning(false);
        fetchStepsData();
      }
    },
    [
      runCheckTransmissionStatusStep,
      setIsActionRunning,
      fetchStepsData,
      globalContext,
      binderFilingId,
      sltBinderId,
      reviewStatusValues,
    ],
  );

  const subSteps = [
    {
      actionsBatch: 'check-transmission-status',
      stepData: reviewStatusStepsData,
      failedDisplayedStatus: 'Transmitted',
      callAction: callCheckTransmissionStatusAction,
      getDownloadFileComponent,
    },
  ];

  const isOutdated = useMemo(
    () => hasAnyStepNewerData(eFileStepsData, 'check-transmission-status'),
    [eFileStepsData],
  );

  return (
    <>
      {!isOutdated &&
        !wizardState[currentStep].isStepActionRunning &&
        Boolean(eFileStepsData.length) &&
        getLastTransmissionResult(eFileStepsData)}
      <div className={styles.returnContext}>
        {!isFetchingEFileStepsData && (
          <div className={styles.ttiSubmissionIdField}>
            <label className="a-form-label">State Submission ID</label>
            <input className="a-input-text" value={ttiSubmissionId || ''} disabled />
          </div>
        )}
      </div>
      <AllSubSteps
        contextDebugInfo={contextDebugInfo}
        subSteps={subSteps}
        steps={EFILE_REVIEW_STATUS_STEPS}
        isLoading={isFetchingEFileStepsData || isFetchingTtiBinders}
        isSubmittingAnyAction={wizardState[currentStep].isStepActionRunning}
        isFetchingEFileStepsData={isFetchingEFileStepsData}
        hasPermissionToRun
        actionButtonText="Refresh"
      />
    </>
  );
};

ReviewStatus.propTypes = {
  binderFilingId: PropTypes.number,
  globalContext: globalContextPropTypes,

  ttiYearId: PropTypes.string,
  ttiCaseId: PropTypes.string,
  selectedTtiBinderId: PropTypes.number,
  efServiceSubmissionId: PropTypes.string,

  eFileStepsData: PropTypes.arrayOf(stepDataPropTypes).isRequired,
  fetchStepsData: PropTypes.func.isRequired,
  isFetchingEFileStepsData: PropTypes.bool.isRequired,

  contextDebugInfo: contextDebugInfoPropTypes,
  getDownloadFileComponent: PropTypes.func.isRequired,
  isFetchingTtiBinders: PropTypes.bool.isRequired,

  wizardState: wizardStatePropTypes,
  setStepStatus: PropTypes.func.isRequired,

  sltBinderId: PropTypes.number,
};

export default connect(state => ({
  globalContext: globalContextSelector(state),

  eFileStepsData: eFileStepsDataSelector(state),
  isFetchingEFileStepsData: isFetchingEFileStepsDataSelector(state),
}))(ReviewStatus);
