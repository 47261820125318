import { httpGetAndParse, httpPost } from '../../utils/fetchService';

export const fetchStateSalesAmount = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/manual-calculations/state-sales-amount/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching state sales amount failed',
  });

export const forceEntityBatchCalculation = ({ taxYear, period, entityId }) =>
  httpPost({
    route: '/api/manual-calculations/force-entity-batch-calculation',
    errorMessage: 'Force Entity Batch Calc failed',
    body: { taxYear, period, entityId },
  });
