import React from 'react';
import Spinner from '@tls/ui-spinner';

import styles from './loadingSpinner.module.scss';

interface LoadingSpinnerProps {
  className?: string;
  isLoading: boolean;
  small?: boolean;
  hasColor?: boolean;
}

const LoadingSpinner = ({
  className = '',
  isLoading,
  small = false,
  hasColor = false,
}: LoadingSpinnerProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={`${styles.spinner} ${className}`}>
      <Spinner small={small} color={hasColor} />
    </div>
  );
};

export default LoadingSpinner;
