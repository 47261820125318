import {
  ConsolidatedReportRequestParams,
  ConsolidatedReportResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export const useQueryConsolidatedReportDataForms = ({
  params,
  enabled,
}: {
  params: ConsolidatedReportRequestParams;
  enabled: boolean;
}) =>
  useCustomQuery<ConsolidatedReportResponse>(
    [QueryKeys.ConsolidationConsolidatedReport.Forms, params],
    {
      url: `/api/consolidations/consolidated-report/${params.taxYear}/${params.period}/${params.consolidationId}`,
      enabled,
      errorMessage: 'Fetching consolidated report failed',
    },
  );
