import { ParamTypeValue, ContextItemValue } from '../../shared/reports/enums';

export const ModalNames = {
  EDIT_PARAM: 'EDIT_PARAM',
};

export const AVAILABLE_PARAM_TYPES = [
  { value: ParamTypeValue.INPUT, label: 'Input' },
  { value: ParamTypeValue.DROPDOWN, label: 'Dropdown' },
];

export const paramFormIntitialValues = {
  type: ParamTypeValue.INPUT,
  mappingName: '',
  query: '',
  dropdownValue: '',
  dropdownLabel: '',
  isMultiSelectDropdown: false,
  contextItem: ContextItemValue.NONE,
};

export const REPORT_TYPES = {
  REPORT: 'Report',
  CHART: 'Chart',
  TRG: 'TRG',
};

export const CHART_TYPES = [
  {
    name: 'BarChart',
    params: ['key', 'value'],
  },
  {
    name: 'PieChart',
    params: ['key', 'value'],
  },
];

export const uiLabels = {
  chart: {
    deleteModal: {
      title: 'Delete Data Visualization',
      text: 'Are you sure you want to delete this data visualization?',
    },
    addButtonText: 'Add Data Visualization',
    slideIn: {
      addTitle: 'Add Data Visualization',
      editTitle: 'Edit Data Visualization',
    },
  },
  report: {
    deleteModal: {
      title: 'Delete Custom Report?',
      text: 'Are you sure you want to delete this custom report?',
    },
    addButtonText: 'Add Custom Report',
    slideIn: {
      addTitle: 'Add Custom Report',
      editTitle: 'Edit Custom Report',
    },
  },
  trg: {
    deleteModal: {
      title: 'Delete TRG Report?',
      text: 'Are you sure you want to delete this trg report?',
    },
    addButtonText: 'Add TRG Report',
    slideIn: {
      addTitle: 'Add TRG Report',
      editTitle: 'Edit TRG Report',
    },
  },
};
