import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';
import { businessGroupsSchemas } from '@common-packages/validators';

import { fetchBusinessGroups, createBusinessGroup } from './store/actions';
import AddBusinessGroupForm from './addBusinessGroupForm.component';
import { BusinessGroupsPropTypes } from './propTypes';

const formInitialValues = {
  businessGroup: '',
  description: '',
  activeInd: false,
};

const AddBusinessGroupModal = ({
  hideModal,
  visible,
  createBusinessGroup,
  fetchBusinessGroups,
  businessGroups,
}) => {
  const saveBusinessGroup = useCallback(
    async ({ businessGroup, description, activeInd }) => {
      await createBusinessGroup({ businessGroup, description, activeInd });
      hideModal();
      fetchBusinessGroups();
    },
    [hideModal, fetchBusinessGroups, createBusinessGroup],
  );

  const renderModal = useCallback(
    formikProps => (
      <Modal
        title="Add Business Group"
        closeAction={hideModal}
        visible={visible}
        submitText="Add"
        submitAction={formikProps.submitForm}
        dismissText="Cancel"
        dismissAction={hideModal}
      >
        <AddBusinessGroupForm {...formikProps} />
      </Modal>
    ),
    [visible, hideModal],
  );
  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={saveBusinessGroup}
      validationSchema={businessGroupsSchemas.createBusinessGroupSchema({ businessGroups })}
    >
      {renderModal}
    </Formik>
  );
};

AddBusinessGroupModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  createBusinessGroup: PropTypes.func.isRequired,
  fetchBusinessGroups: PropTypes.func.isRequired,
  businessGroups: PropTypes.arrayOf(BusinessGroupsPropTypes).isRequired,
};
export default connect(null, {
  fetchBusinessGroups,
  createBusinessGroup,
})(AddBusinessGroupModal);
