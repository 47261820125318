import { connect } from 'react-redux';

import FilingAttributesDashboard from '../filingAttributes.component';
import {
  isFetchingEntityFilingAttributesSelector,
  entityFilingAttributesSelector,
  entityFilingAttributesColumnsBlueprintSelector,
  isEntityContextReadySelector,
  contextSelector,
} from '../store/selectors';
import { fetchEntityFilingAttributes } from '../store/actions';

export default connect(
  state => ({
    isContextReady: isEntityContextReadySelector(state),
    context: contextSelector(state),
    getSlideInTitle: ({ jurisdiction, filingGroup }) => `${jurisdiction} - ${filingGroup}`,

    filingAttributes: entityFilingAttributesSelector(state),
    filingAttributesColumnsBlueprint: entityFilingAttributesColumnsBlueprintSelector(state),
    isFetchingFilingAttributes: isFetchingEntityFilingAttributesSelector(state),
  }),
  {
    fetchFilingAttributes: fetchEntityFilingAttributes,
  },
)(FilingAttributesDashboard);
