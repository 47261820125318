import React from 'react';
import {
  ICellEditorParams,
  ICellRendererParams,
  NewValueParams,
  ValueGetterParams,
  CellValueChangedEvent,
  EditableCallbackParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { ColumnTypes } from '@common-packages/shared-constants';
import { Routes } from '@common-packages/routes-definitions';
import { Tooltip } from '@pwc/appkit-react';
import camelCase from 'lodash.camelcase';

import {
  defaultColumnDefinitionWithFilterSuppressMovable,
  selectCellEditorParamsFactory,
  mapValueFormatterFactory,
  defaultSelectionColumnDefinitionWithFilterSuppressMovable,
} from '../shared/columnDefinitions';
import { defaultAgRichSelectCellEditorSelector } from '../shared/columnDefinitions/cellEditor';
import {
  LinkCellRendererFactory,
  selectOptionCellRendererFactory,
} from '../shared/columnDefinitions/cellRenderers';
import {
  FilingAttributesColumnBlueprint,
  FindReturnStatusDefinition,
  FindFilingOrNONFilingMemberData,
  FindReturnDuedateOptionsResponse,
} from '../../../common/types';
import { UpdateRowFunc } from '../shared/editMode/types';
import ConditionalSelectionListEditorSelectorFactory from '../shared/displayComponents/editorSelector/conditionalSelectionListEditorSelector.factory';
import {
  DisplayedAttributes,
  DisplayedReturnTypeAttributes,
  DisplayedBusinessTypeAttributes,
  FilingMethods,
  DisplayedTaxTypeAttributesForDropdown,
  FilingTypes,
} from '../shared/enums';
import getColumnBlueprintBasedColumnDefinitions from '../shared/columnDefinitions/columnsBlueprintEditableWithoutGroup.columnDefinition';
import { CalcPreferenceColumnBlueprint, ColumnsBlueprint } from '../../../routes/shared/types';
import { cellRenderer } from '../shared/columnDefinitions/cellRenderers/cellRenderer.component';
import { FILING_METHOD_OPTIONS, FILING_METHOD_OPTIONS_WITHOUT_EFILE } from '../shared/constants';
import { getResetValuesForFilingMethodChange } from '../shared/taxReturns/utils/getResetValuesForFilingMethodChange';
import { getIsReturnStatusEditable } from '../shared/taxReturns/utils/getIsReturnStatusEditable';
import { dueDateFormatter } from '../shared/formatters/dueDateFormatter';
import { dueDateValueGetter } from '../shared/displayComponents/agGrid/valueGetter';

import {
  filingAttributesCellEditor,
  getColumnBlueprintValue,
  taxTypeValueFormatterFactory,
  taxTypeValueGetter,
  isFilingAttributesHasOption,
  shouldEnableEditing,
  isNewValueValidOption,
} from './utils';
import styles from './memberReturns.module.scss';

const mismatchedTypesMessage = 'Mismatched types.';

interface FilingAttributesOptions {
  filingType: { value: string; label: string }[];
}

interface SetContextParams {
  businessEntityId: string | null;
  jurisdictionId: string | null;
  filingType: string | null;
}

export const getMemberReturnsColumnDefinitions = ({
  updateRow = () => null,
  setContext,
  taxYear,
  period,
  filingFormName = '',
  columnsBlueprint,
  taxReturnStatusOptions,
  isInEditMode = false,
  shouldDisplayReturnsDueDates,
}: {
  updateRow: UpdateRowFunc<FindFilingOrNONFilingMemberData>;
  taxYear: string;
  period: string;
  filingFormName?: string | null;
  columnsBlueprint: ColumnsBlueprint;
  taxReturnStatusOptions?: FindReturnStatusDefinition;
  isInEditMode?: boolean;
  shouldDisplayReturnsDueDates: boolean;
  setContext: ({ businessEntityId, jurisdictionId, filingType }: SetContextParams) => void;
}) => {
  const returnTypeColumnBlueprint = columnsBlueprint.find(
    column => DisplayedAttributes.RETURN_TYPE === (column as FilingAttributesColumnBlueprint).field,
  ) as FilingAttributesColumnBlueprint;
  const businessTypeColumnBlueprint = columnsBlueprint.find(
    column =>
      DisplayedAttributes.BUSINESS_TYPE === (column as FilingAttributesColumnBlueprint).field,
  ) as FilingAttributesColumnBlueprint;
  const taxTypeColumnBlueprint = columnsBlueprint.find(
    column => DisplayedAttributes.TAX_TYPE === (column as FilingAttributesColumnBlueprint).field,
  ) as FilingAttributesColumnBlueprint;
  const commonAndStateCalcColumnsBlueprint = columnsBlueprint.filter(
    column => column.columnType === ColumnTypes.CALC_PREFERENCES,
  ) as CalcPreferenceColumnBlueprint[];

  const onCellValueChanged = ({ data }: CellValueChangedEvent) => {
    updateRow(data);
  };

  const dueDateTypeSetter = (params: NewValueParams) => {
    const { newValue, data } = params;
    const date = (data.dueDateOption || []).find(
      ({ value: optionValue }: { value: number }) => optionValue === newValue,
    )?.label;

    updateRow({
      ...data,
      dueDate: dueDateFormatter(date),
      dueDateType: newValue,
    });
    return true;
  };

  const entityIdRenderer = LinkCellRendererFactory({
    getText: ({ value }) => value,
    getPathName: (_, { returnId }) =>
      Routes.returnMaintenance.compiledRoute({
        returnId,
      }),
    clickHandler: (_, { entityId: businessEntityId, jurisdictionId, filingAttributesOptions }) => {
      const filingType =
        ((filingAttributesOptions as unknown) as FilingAttributesOptions).filingType[0].value ===
        'FILING_TYPE_SEPARATE'
          ? FilingTypes.SEPARATE
          : FilingTypes.CONSOLIDATED;
      setContext({ businessEntityId, jurisdictionId, filingType });
    },
  });

  const returnTypeValueGetter = ({ data }: ValueGetterParams | ICellEditorParams) =>
    Object.values(DisplayedReturnTypeAttributes).find(attributeKey => data[attributeKey]);
  const returnTypeValueFormatter = (params: ValueFormatterParams) =>
    isFilingAttributesHasOption(params, DisplayedAttributes.RETURN_TYPE)
      ? getColumnBlueprintValue(params.value, returnTypeColumnBlueprint)
      : mismatchedTypesMessage;
  // using filter value getter because filter value formatter didn't have data in params
  const returnTypeFilterValueGetter = (params: ValueGetterParams) => {
    const value = returnTypeValueGetter(params) || '';
    return returnTypeValueFormatter({ ...params, value });
  };

  const businessTypeValueGetter = ({ data }: ValueGetterParams | ICellEditorParams) =>
    Object.values(DisplayedBusinessTypeAttributes).find(attributeKey => data[attributeKey]);
  const businessTypeValueFormatter = (params: ValueFormatterParams) =>
    isFilingAttributesHasOption(params, DisplayedAttributes.BUSINESS_TYPE)
      ? getColumnBlueprintValue(params.value, businessTypeColumnBlueprint)
      : mismatchedTypesMessage;
  const businessTypeFilterValueGetter = (params: ValueGetterParams) => {
    const value = businessTypeValueGetter(params) || '';
    return businessTypeValueFormatter({ ...params, value });
  };

  const taxTypeValueFormatter = (params: ValueFormatterParams | ICellRendererParams) =>
    isFilingAttributesHasOption(params, DisplayedAttributes.TAX_TYPE)
      ? taxTypeValueFormatterFactory(taxTypeColumnBlueprint)(params)
      : mismatchedTypesMessage;
  const taxTypeFilterValueGetter = (params: ValueGetterParams) => {
    const value = taxTypeValueGetter(params) || '';
    return taxTypeValueFormatter({ ...params, value });
  };

  const nexusValueFormatter = ({ data }: ValueGetterParams<FindFilingOrNONFilingMemberData>) =>
    data?.nexusIndicator ? 'Yes' : 'No';

  const filingAttributeValueSetter = (
    { data, newValue, api }: NewValueParams,
    attributeName: string,
  ) => {
    const isSelectAll =
      attributeName === DisplayedAttributes.TAX_TYPE &&
      newValue === DisplayedTaxTypeAttributesForDropdown.TAX_TYPE_ALL;

    api.forEachNode(node => {
      const options: { value: string; label: string }[] =
        node.data?.filingAttributesOptions?.[camelCase(attributeName)] || [];

      const isNewValueValid = isNewValueValidOption({ newValue, options });

      if (node.data.entityId === data.entityId && isNewValueValid) {
        Object.keys(node.data)
          .filter(key => key.startsWith(attributeName))
          .forEach(attributeKey => {
            node.data[attributeKey] = isSelectAll || false;
          });
        if (!isSelectAll) {
          node.data[newValue] = true;
        }
        updateRow(node.data);
      }
    });
  };

  const genericValueSetter = ({ data, newValue, api, column }: NewValueParams) => {
    api.forEachNode(node => {
      if (node.data.entityId === data.entityId) {
        node.data[column.getId()] = newValue;
        updateRow(node.data);
      }
    });

    return true;
  };

  const calcOptionsValueSetter = ({ data, newValue, api, column }: NewValueParams) => {
    api.forEachNode(node => {
      if (node.data.entityId === data.entityId) {
        const calcOptionsKey = column.getId();
        node.data.calcOptions[calcOptionsKey] = newValue;
        updateRow(node.data);
      }
    });

    return true;
  };

  const returnStatusValueSetter = ({ data, newValue }: NewValueParams) => {
    const options =
      data.filingMethod === FilingMethods.EFILE
        ? taxReturnStatusOptions?.efileReturnStatusOptions
        : taxReturnStatusOptions?.returnStatusOptions;

    const isNewValueValid = isNewValueValidOption({ newValue, options });
    if (isNewValueValid) {
      updateRow({ ...data, returnStatus: newValue });
    }
    return true;
  };

  const returnStatusValueFormatter = ({ data }: ValueGetterParams) =>
    data?.filingMethod === FilingMethods.NON_FILING
      ? ''
      : (taxReturnStatusOptions?.returnStatusOptions || []).find(
          option => option.value === data.returnStatus,
        )?.label;

  const mismatchedTypesMsgRender = () => (
    <Tooltip
      content={<div>No results due to mismatched type for jurisdiction returns.</div>}
      placement="top"
    >
      <div className={styles.redTextWrapper}>{mismatchedTypesMessage}</div>
    </Tooltip>
  );

  const MissingTaxYearEndingRender = () => (
    <div className={styles.redTextWrapper}>Missing Tax Year Ending</div>
  );

  const columnDefinitions = [
    {
      ...defaultColumnDefinitionWithFilterSuppressMovable,
      headerName: 'Entity ID',
      field: 'entityId',
      width: 100,
      pinned: true,
      cellRendererFramework: taxYear && period && entityIdRenderer,
    },
    {
      ...defaultColumnDefinitionWithFilterSuppressMovable,
      headerName: 'Entity Name',
      field: 'orgName',
      width: 205,
      pinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilterSuppressMovable,
      headerName: 'Filing Group(s)',
      field: 'filingGroupsIds',
      width: 175,
      cellRenderer: (params: ICellRendererParams) =>
        cellRenderer({
          params,
          renderer: ({ value }: { value: Array<string> }) => (value ? value.join(', ') : ''),
        }),
      pinned: true,
    },
    {
      headerName: `Specific to ${filingFormName}`,
      children: [
        {
          ...defaultSelectionColumnDefinitionWithFilterSuppressMovable,
          headerName: 'Filing Method',
          suppressMovable: true,
          field: 'filingMethod',
          editable: ({ data }: EditableCallbackParams) => isInEditMode && data.taxYearEnding,
          valueFormatter: mapValueFormatterFactory(FILING_METHOD_OPTIONS),
          filterParams: {
            valueFormatter: mapValueFormatterFactory(FILING_METHOD_OPTIONS),
          },
          valueSetter: ({
            data,
            newValue,
          }: {
            data: FindFilingOrNONFilingMemberData;
            newValue: FilingMethods;
          }) => {
            Object.entries(
              getResetValuesForFilingMethodChange({
                filingMethod: newValue,
                values: data,
                dueDateOption: data.dueDateOption as FindReturnDuedateOptionsResponse,
                shouldResetDueDate: true,
              }),
            ).forEach(([field, value]) => {
              data[field] = value;
            });
            data.filingMethod = newValue;

            return true;
          },
          cellEditorSelector: ({ data }: ICellEditorParams) => ({
            ...defaultAgRichSelectCellEditorSelector,
            params: selectCellEditorParamsFactory(
              data?.returnDefIsEfileAllowed
                ? FILING_METHOD_OPTIONS
                : FILING_METHOD_OPTIONS_WITHOUT_EFILE,
              {},
              false,
            )(),
          }),
          onCellValueChanged,
        },
        {
          ...defaultSelectionColumnDefinitionWithFilterSuppressMovable,
          headerName: 'Return Status',
          field: 'returnStatus',
          hide: !shouldDisplayReturnsDueDates,
          editable: ({ data }: { data: FindFilingOrNONFilingMemberData }) =>
            isInEditMode && getIsReturnStatusEditable(data) && data.taxYearEnding,
          valueFormatter: returnStatusValueFormatter,
          cellEditorSelector: ({ data }: ICellEditorParams) => ({
            ...defaultAgRichSelectCellEditorSelector,
            params: selectCellEditorParamsFactory(
              data.filingMethod === FilingMethods.EFILE
                ? taxReturnStatusOptions?.efileReturnStatusOptions
                : taxReturnStatusOptions?.returnStatusOptions,
              {},
              false,
            )(),
          }),
          filterParams: {
            valueFormatter: ({
              value,
            }: Omit<ICellEditorParams, 'value'> & {
              value: string;
            }) =>
              (taxReturnStatusOptions?.returnStatusOptions || []).find(
                option => option.value === parseInt(value, 10),
              )?.label,
          },
          valueSetter: returnStatusValueSetter,
          onCellValueChanged,
        },
        {
          ...defaultSelectionColumnDefinitionWithFilterSuppressMovable,
          headerName: 'Due Date',
          field: 'dueDateType',
          hide: !shouldDisplayReturnsDueDates,
          editable: ({ data }: EditableCallbackParams) =>
            isInEditMode && !(data.filingMethod === FilingMethods.NON_FILING) && data.taxYearEnding,
          filterValueGetter: dueDateValueGetter,
          cellEditorSelector: ({ data }: ICellEditorParams) => ({
            ...defaultAgRichSelectCellEditorSelector,
            params: selectCellEditorParamsFactory(data.dueDateOption || [])(),
          }),
          cellRenderer: (params: ICellRendererParams) =>
            params.data.taxYearEnding
              ? selectOptionCellRendererFactory(params.data.dueDateOption, dueDateFormatter)(params)
              : MissingTaxYearEndingRender(),
          valueSetter: dueDateTypeSetter,
          onCellValueChanged,
        },
      ],
    },
    {
      headerName: 'Shared Between Returns',
      children: [
        {
          ...defaultSelectionColumnDefinitionWithFilterSuppressMovable,
          headerName: 'Return Type',
          width: 175,
          editable: (params: ICellEditorParams) =>
            isInEditMode &&
            shouldEnableEditing({
              params,
              value: returnTypeValueGetter(params),
              filingType: DisplayedAttributes.RETURN_TYPE,
              columnBlueprint: returnTypeColumnBlueprint,
            }),
          valueGetter: returnTypeValueGetter,
          valueFormatter: returnTypeValueFormatter,
          filterParams: {
            valueGetter: returnTypeFilterValueGetter,
          },
          valueSetter: (params: NewValueParams) => {
            filingAttributeValueSetter(params, DisplayedAttributes.RETURN_TYPE);
            return true;
          },
          cellRenderer: (params: ICellRendererParams) =>
            cellRenderer({
              params,
              renderer: ({ value }: { value: string }) =>
                isFilingAttributesHasOption(params, DisplayedAttributes.RETURN_TYPE)
                  ? getColumnBlueprintValue(value, returnTypeColumnBlueprint)
                  : mismatchedTypesMsgRender(),
            }),
          cellEditorSelector: (param: ICellEditorParams) =>
            filingAttributesCellEditor({ param, filingType: DisplayedAttributes.RETURN_TYPE }),
          onCellValueChanged,
        },
        {
          ...defaultSelectionColumnDefinitionWithFilterSuppressMovable,
          headerName: 'Business Type',
          width: 175,
          editable: (params: ICellEditorParams) =>
            isInEditMode &&
            shouldEnableEditing({
              params,
              value: businessTypeValueGetter(params),
              filingType: DisplayedAttributes.BUSINESS_TYPE,
              columnBlueprint: businessTypeColumnBlueprint,
            }),
          valueGetter: businessTypeValueGetter,
          valueFormatter: businessTypeValueFormatter,
          filterParams: {
            valueGetter: businessTypeFilterValueGetter,
          },
          valueSetter: (params: NewValueParams) => {
            filingAttributeValueSetter(params, DisplayedAttributes.BUSINESS_TYPE);
            return true;
          },
          cellRenderer: (params: ICellRendererParams) =>
            cellRenderer({
              params,
              renderer: ({ value }: { value: string }) =>
                isFilingAttributesHasOption(params, DisplayedAttributes.BUSINESS_TYPE)
                  ? getColumnBlueprintValue(value, businessTypeColumnBlueprint)
                  : mismatchedTypesMsgRender(),
            }),
          cellEditorSelector: (param: ICellEditorParams) =>
            filingAttributesCellEditor({ param, filingType: DisplayedAttributes.BUSINESS_TYPE }),
          onCellValueChanged,
        },
        {
          ...defaultSelectionColumnDefinitionWithFilterSuppressMovable,
          headerName: 'Tax Type',
          width: 175,
          editable: (params: ICellEditorParams) =>
            isInEditMode &&
            shouldEnableEditing({
              params,
              value: taxTypeValueGetter({
                data: params.data,
              }),
              filingType: DisplayedAttributes.TAX_TYPE,
              columnBlueprint: taxTypeColumnBlueprint,
            }),
          valueGetter: taxTypeValueGetter,
          valueFormatter: taxTypeValueFormatter,
          filterParams: {
            valueGetter: taxTypeFilterValueGetter,
          },
          valueSetter: (params: NewValueParams) => {
            filingAttributeValueSetter(params, DisplayedAttributes.TAX_TYPE);
            return true;
          },
          cellRenderer: (params: ICellRendererParams) =>
            cellRenderer({
              params,
              renderer: ({ value }: { value: string }) =>
                isFilingAttributesHasOption(params, DisplayedAttributes.TAX_TYPE)
                  ? taxTypeValueFormatter({ ...params, value })
                  : mismatchedTypesMsgRender(),
            }),
          cellEditorSelector: (param: ICellEditorParams) =>
            filingAttributesCellEditor({ param, filingType: DisplayedAttributes.TAX_TYPE }),
          onCellValueChanged,
        },
        {
          ...defaultSelectionColumnDefinitionWithFilterSuppressMovable,
          headerName: 'Nexus',
          field: 'nexusIndicator',
          width: 100,
          editable: ({ data }: ICellEditorParams) => isInEditMode && data.taxYearEnding,
          valueFormatter: nexusValueFormatter,
          filterParams: {
            valueGetter: nexusValueFormatter,
          },
          valueSetter: genericValueSetter,
          cellEditorSelector: (param: ICellEditorParams) => {
            const editorSelector = ConditionalSelectionListEditorSelectorFactory({
              isClearable: false,
            });
            return editorSelector({
              ...param,
              data: {
                selectionListItems: [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ],
              },
            });
          },
          onCellValueChanged,
        },
        ...getColumnBlueprintBasedColumnDefinitions({
          updateRow: updateRow as () => null,
          valueSetter: calcOptionsValueSetter,
          isInEditMode,
          columnsBlueprint: commonAndStateCalcColumnsBlueprint,
          defaultColumnConfig: defaultColumnDefinitionWithFilterSuppressMovable,
        }),
      ],
    },
  ];
  return columnDefinitions;
};
