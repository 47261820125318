import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessTokenExpirationDate: null,
    csrfToken: null,
  },
  reducers: {
    setAccessTokenExpirationDate(state, { payload }) {
      state.accessTokenExpirationDate = payload;
    },
    setCsrfToken(state, { payload }) {
      state.csrfToken = payload;
    },
  },
});

const { actions, reducer } = authSlice;

export const { setAccessTokenExpirationDate, setCsrfToken } = actions;

export const accessTokenExpirationDateSelector = ({ auth }) => auth.accessTokenExpirationDate;
export const csrfTokenSelector = ({ auth }) => auth.csrfToken;

export default reducer;
