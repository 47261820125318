import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import FaIcon from '../../shared/displayComponents/faIcon/faIcon.component';
import { eFileStepDataPropTypes } from '../eFile.propTypes';
import { getActionStatus, getLastUpdateDate } from '../utils';
import Loading from '../../shared/displayComponents/loading.component';
import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';
import useModal from '../../shared/hooks/useModal.hook';
import { EFILE_SETUP_STEP_NAMES, EFILE_SETUP_LINK_TEXT } from '../eFileSetup/constants';
import { contextDebugInfoPropTypes } from '../currentReturnFiling/currentReturnFiling.propTypes';
import styles from '../styles.module.scss';
import { DownloadLink } from '../../shared/displayComponents/downloadLink';

import GroupSelectionModal from './groupSelectionModal.container';

const StepSection = ({
  action,
  callAction,
  disabled,
  isFetchingEFileSetupStepsData,
  stepData,
  selectedTtiCaseId,
  contextDebugInfo,
  isGroupSelection,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  const { showModal: showGroupSelectionModal, modalProps: groupSelectionModalProps } = useModal();

  const errorMessage = stepData && {
    apiInput: {
      actionName: action,
      ...contextDebugInfo,
    },
    apiResponse: {
      ...stepData.ttiResponse,
    },
  };

  const toggleShowDebugInfo = useCallback(() => {
    setShowDebugInfo(!showDebugInfo);
  }, [showDebugInfo]);

  const onCallAction = useCallback(
    async ({ additionalParams }) => {
      setSubmitting(true);
      await callAction({ action, additionalParams });
      setSubmitting(false);
    },
    [action, callAction],
  );

  const renderStatusMessage = useCallback(() => {
    if (!selectedTtiCaseId) {
      return (
        <>
          <FaIcon className="info-circle-icon" icon="info-circle" size={15} />
          <span className={styles.infoMessage}>Select Case ID to persist statuses</span>
        </>
      );
    }
    return (
      <span className={styles.capitalizeStatus}>{getActionStatus(stepData, isSubmitting)}</span>
    );
  }, [isSubmitting, selectedTtiCaseId, stepData]);

  const fileLink = `/api/efile/download-step-file/efile-setup/${stepData?.stepId}`;

  return (
    <div className={styles.stepSection}>
      <h6>{EFILE_SETUP_STEP_NAMES[action]}</h6>
      <Loading isLoading={isFetchingEFileSetupStepsData} small>
        <div className={styles.stepRow}>
          <div className={styles.stepInfo}>
            <div className={styles.status}>
              Status:{' '}
              {isFetchingEFileSetupStepsData ? (
                <Loading isLoading small />
              ) : (
                <>
                  {renderStatusMessage()}
                  {stepData && (
                    <AppkitIcon
                      icon={showDebugInfo ? 'up-chevron' : 'down-chevron'}
                      size={15}
                      onClick={toggleShowDebugInfo}
                      className={styles.debugInfoIcon}
                    />
                  )}
                </>
              )}
            </div>
            {isGroupSelection ? (
              <Button size="lg" onClick={showGroupSelectionModal} disabled={disabled}>
                Open Selection
              </Button>
            ) : (
              <Button
                size="lg"
                disabled={disabled}
                onClick={onCallAction}
                className={styles.eFileRunActionButton}
              >
                Run
              </Button>
            )}
          </div>
          {stepData?.hasFile && (
            <DownloadLink url={fileLink}>{EFILE_SETUP_LINK_TEXT[stepData?.stepName]}</DownloadLink>
          )}
          <div>
            {!isFetchingEFileSetupStepsData && Boolean(stepData) && (
              <div>Last update: {getLastUpdateDate(stepData)}</div>
            )}
          </div>
        </div>
        {showDebugInfo && <pre>{JSON.stringify(errorMessage, null, 2)}</pre>}
      </Loading>
      <GroupSelectionModal {...groupSelectionModalProps} onCallAction={onCallAction} />
    </div>
  );
};

StepSection.propTypes = {
  action: PropTypes.string.isRequired,
  callAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetchingEFileSetupStepsData: PropTypes.bool.isRequired,
  stepData: eFileStepDataPropTypes,
  selectedTtiCaseId: PropTypes.string,
  contextDebugInfo: contextDebugInfoPropTypes,
  isGroupSelection: PropTypes.bool,
};

export default StepSection;
