import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';

import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../shared/editMode';
import useModal from '../../shared/hooks/useModal.hook';
import { columnBlueprintHeaderOptions } from '../../shared/columnDefinitions/constants';
import columnBlueprintStyles from '../../shared/styles/columnBlueprintStyles.module.scss';
import {
  taxYearSelector,
  periodSelector,
  jurisdictionIdSelector,
  isFetchingJurisdictionsSelector,
} from '../store/selectors';

import validateCalcSpecs from './validateCalcSpecs';
import ValidationErrorModal from './validationErrorModal.component';
import getColumnBlueprintBasedColumnDefinitions from './calcSpecs.columnDefinition';
import {
  fetchK1CalcSpecs,
  fetchK1CalcSpecsOptions,
  updateK1CalcSpecs,
  createK1CalcSpecs,
  blockContextChanges,
} from './store/actions';
import {
  k1CalcSpecsSelector,
  k1CalcSpecsColumnsBlueprintSelector,
  isFetchingK1CalcSpecsSelector,
  k1CalcSpecIdOptionsSelector,
  k1CalcSpecTypeOptionsSelector,
  groupedFilingAttributesSelector,
  isFetchingK1CalcSpecsOptionsSelector,
  isUpdatingK1CalcSpecsSelector,
  isCreatingK1CalcSpecsSelector,
} from './store/selectors';
import { calcSpecTypes } from './constants';

const NULL_OPTION = { value: null, label: '' };

const k1FunctionTypeOptions = [
  NULL_OPTION,
  { value: 'PROD', label: 'PROD' },
  { value: 'SUM', label: 'SUM' },
];
const percentageTypeOptions = [
  NULL_OPTION,
  { value: 'PROFIT', label: 'PROFIT' },
  { value: 'CAPITAL', label: 'CAPITAL' },
];
const screenTypeOptions = [
  { value: 'ATTACH', label: 'ATTACH' },
  { value: 'FORM', label: 'FORM' },
];

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const getCalcSpecInitialValue = ({ k1CalcId, k1CalcType }) => ({
  subcategory: '',
  k1CalcId,
  calcPreferences: '0',
  screenType: 'ATTACH',
  displayOrder: 100,
  k1FunctionFlag: false,
  runLevel: '0',
  k1FunctionType: null,
  attachId: '',
  percentageType: null,
  forcePlug: false,
  k1CalcType,
});

const getFormattedValues = row => ({
  ...row,
  displayOrder: Number(row.displayOrder),
  runLevel: Number(row.runLevel),
  k1FunctionFlag: Boolean(row.k1FunctionFlag),
  forcePlug: Boolean(row.forcePlug),
  calcPreferences: Number(row.calcPreferences),
});

const fillCells = params => params.initialValues[0];

const K1CalcSpecs = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const isFetchingJurisdictions = useSelector(isFetchingJurisdictionsSelector);

  const calcSpecs = useSelector(k1CalcSpecsSelector);
  const calcSpecsColumnsBlueprint = useSelector(k1CalcSpecsColumnsBlueprintSelector);
  const isFetchingCalcSpecs = useSelector(isFetchingK1CalcSpecsSelector);

  const isUpdatingK1CalcSpecs = useSelector(isUpdatingK1CalcSpecsSelector);
  const isCreatingK1CalcSpecs = useSelector(isCreatingK1CalcSpecsSelector);

  const k1CalcSpecIdOptions = useSelector(k1CalcSpecIdOptionsSelector);
  const k1CalcSpecTypeOptions = useSelector(k1CalcSpecTypeOptionsSelector);
  const groupedFilingAttributes = useSelector(groupedFilingAttributesSelector);
  const isFetchingK1CalcSpecsOptions = useSelector(isFetchingK1CalcSpecsOptionsSelector);

  const [validationResult, setValidationResult] = useState({});
  const [addedRowsCounter, setAddedRowsCounter] = useState(0);

  const { showModal: showValidationErrorModal, modalProps } = useModal();

  const isContextReady = taxYear && period && jurisdictionId;

  useEffect(() => {
    if (isContextReady) {
      dispatch(fetchK1CalcSpecs({ taxYear, period, jurisdictionId }));
      dispatch(fetchK1CalcSpecsOptions({ taxYear, period }));
    }
  }, [dispatch, isContextReady, taxYear, period, jurisdictionId]);

  const isLoading =
    !isContextReady ||
    isFetchingCalcSpecs ||
    isFetchingK1CalcSpecsOptions ||
    isUpdatingK1CalcSpecs ||
    isCreatingK1CalcSpecs ||
    isFetchingJurisdictions;

  const displayNameAndSelectOptionDictionary = useMemo(
    () => ({
      k1CalcId: k1CalcSpecIdOptions,
      k1CalcType: k1CalcSpecTypeOptions,
      screenType: screenTypeOptions,
      k1FunctionType: k1FunctionTypeOptions,
      percentageType: percentageTypeOptions,
    }),
    [k1CalcSpecIdOptions, k1CalcSpecTypeOptions],
  );

  const onSave = useCallback(
    async ({ rowsPairsWithChanges, rowsToAdd }) => {
      const formattedModifiedRows = rowsPairsWithChanges.map(({ newRow }) => {
        const formattedValues = getFormattedValues(newRow);

        return {
          ...newRow,
          ...formattedValues,
          taxYear: Number(taxYear),
          period: Number(period),
          jurisdictionId,
        };
      });

      const formattedAddedRows = rowsToAdd.map(newRow => {
        const formattedValues = getFormattedValues(newRow);

        return {
          ...newRow,
          ...formattedValues,
          taxYear: Number(taxYear),
          period: Number(period),
          jurisdictionId,
        };
      });

      const { duplicatedRowsIds, rowsWithInvalidCells, modalMessage } = validateCalcSpecs({
        addOrModifiedRows: [...formattedModifiedRows, ...formattedAddedRows],
        calcSpecs,
        displayNameAndSelectOptionDictionary,
        calcSpecType: calcSpecTypes.K1,
        groupedFilingAttributes,
      });

      if (modalMessage) {
        showValidationErrorModal(modalMessage);
        setValidationResult({ duplicatedRowsIds, rowsWithInvalidCells });
        return { shouldCancelHookStateReset: true };
      }

      if (rowsPairsWithChanges.length) {
        await dispatch(
          updateK1CalcSpecs({
            values: formattedModifiedRows,
            taxYear,
            period,
            jurisdictionId,
          }),
        );
      }

      if (rowsToAdd.length) {
        await dispatch(
          createK1CalcSpecs({ values: formattedAddedRows, taxYear, period, jurisdictionId }),
        );
      }

      setAddedRowsCounter(0);
      setValidationResult({});
      dispatch(blockContextChanges(false));
      dispatch(fetchK1CalcSpecs({ taxYear, period, jurisdictionId }));
    },
    [
      showValidationErrorModal,
      dispatch,
      jurisdictionId,
      taxYear,
      period,
      calcSpecs,
      displayNameAndSelectOptionDictionary,
      setValidationResult,
      setAddedRowsCounter,
      groupedFilingAttributes,
    ],
  );

  const onEnterEdit = useCallback(() => {
    dispatch(blockContextChanges(true));
  }, [dispatch]);

  const onCancelEdit = useCallback(() => {
    setValidationResult({});
    dispatch(blockContextChanges(false));
  }, [setValidationResult, dispatch]);

  const {
    navigationPrompt,
    isInEditMode,
    setIsInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    addRow,
    deleteRow,
    onGridReady,
  } = useRowEditMode({
    onEnter: onEnterEdit,
    onSave,
    onCancel: onCancelEdit,
    rowData: calcSpecs,
    getUniqueRowId,
    editButtonDisabled: isLoading,
    appendRowUnderSelectedRow: true,
  });

  const calcSpecInitialValues = useMemo(
    () =>
      getCalcSpecInitialValue({
        k1CalcId: k1CalcSpecIdOptions.length ? k1CalcSpecIdOptions[0].value : null,
        k1CalcType: k1CalcSpecTypeOptions.length ? k1CalcSpecTypeOptions[0].value : null,
      }),
    [k1CalcSpecIdOptions, k1CalcSpecTypeOptions],
  );

  const addCalcSpec = useCallback(() => {
    addRow({ ...calcSpecInitialValues, isAddedRow: true, rowId: String(addedRowsCounter) });
    setAddedRowsCounter(addedRowsCounter + 1);
    setIsInEditMode(true);
    dispatch(blockContextChanges(true));
  }, [
    addRow,
    setAddedRowsCounter,
    dispatch,
    setIsInEditMode,
    addedRowsCounter,
    calcSpecInitialValues,
  ]);

  const columnDefinitions = useMemo(
    () =>
      getColumnBlueprintBasedColumnDefinitions({
        updateRow,
        onDeleteIconClick: deleteRow,
        isInEditMode,
        columnsBlueprint: calcSpecsColumnsBlueprint,
        displayNameAndSelectOptionDictionary,
        validationResult,
      }),
    [
      updateRow,
      deleteRow,
      isInEditMode,
      calcSpecsColumnsBlueprint,
      displayNameAndSelectOptionDictionary,
      validationResult,
    ],
  );

  return (
    <>
      {navigationPrompt}
      <ValidationErrorModal {...modalProps} />
      <div className="add-button-column">
        {hasUserPermissionsToEdit && (
          <>
            <Button size="lg" className="add-button" onClick={addCalcSpec} disabled={isLoading}>
              Add Calc Spec
            </Button>
            {editModeButtons}
          </>
        )}
      </div>
      <div className={`row grid-row ${columnBlueprintStyles.gridContainer}`}>
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            onGridReady={onGridReady}
            withSearchBar
            enableRangeSelection
            suppressCopyRowsToClipboard
            suppressMultiRangeSelection
            enableFillHandle={isInEditMode}
            fillOperation={fillCells}
            undoRedoCellEditing
            stopEditingWhenCellsLoseFocus
            sideBar={defaultSideBarWithColumnsToolPanel}
            {...columnBlueprintHeaderOptions}
            enableBrowserTooltips
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
    </>
  );
};

K1CalcSpecs.propTypes = { hasUserPermissionsToEdit: PropTypes.bool.isRequired };

export default K1CalcSpecs;
