import { useCallback } from 'react';

import useGridApi from '../../hooks/useGridApi.hook';

const useActionMenuForExport = ({ reportData, includeHiddenColumns = false }) => {
  const { gridApi, columnApi, onGridReady } = useGridApi();
  const isExportDisabled = !reportData || !reportData.length;

  const exportCustomReportToCSV = useCallback(() => {
    gridApi.exportDataAsCsv({ allColumns: includeHiddenColumns });
  }, [gridApi, includeHiddenColumns]);

  const exportCustomReportToExcelInXLSXFormat = useCallback(() => {
    gridApi.exportDataAsExcel({
      exportMode: 'xlsx',
      allColumns: includeHiddenColumns,
    });
  }, [gridApi, includeHiddenColumns]);

  const exportCustomReportToExcelInXMLFormat = useCallback(() => {
    gridApi.exportDataAsExcel({
      exportMode: 'xml',
      allColumns: includeHiddenColumns,
    });
  }, [gridApi, includeHiddenColumns]);

  return {
    props: {
      isExportDisabled,
      exportCSV: exportCustomReportToCSV,
      exportExcelInXLSX: exportCustomReportToExcelInXLSXFormat,
      exportExcelInXML: exportCustomReportToExcelInXMLFormat,
    },
    onGridReady,
    gridApi,
    columnApi,
  };
};

export default useActionMenuForExport;
