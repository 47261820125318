import { currencyColumnDefinition, defaultColumnDefinition } from '../../shared/columnDefinitions';
import { SELECT_ALL_OPTION_VALUE } from '../../shared/reports/constants';

const getColumnDefinitions = ({ stateCodes, entityId, isComponentDetail }) => [
  {
    ...defaultColumnDefinition,
    headerName: 'Account Group',
    field: 'accountGroup',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Id',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'entityId',
    hide: entityId !== SELECT_ALL_OPTION_VALUE,
  },
  ...(isComponentDetail
    ? [
        {
          ...defaultColumnDefinition,
          headerName: 'Component Detail',
          field: 'componentId',
        },
      ]
    : []),
  {
    ...defaultColumnDefinition,
    headerName: 'Beg/End',
    field: 'begEndFlag',
    width: 80,
    cellStyle: {
      textAlign: 'center',
    },
  },
  ...stateCodes.map(stateCode => ({
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: stateCode,
    field: stateCode,
  })),
];

export default getColumnDefinitions;
