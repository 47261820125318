import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';

import {
  fetchAccountsTypes,
  fetchAccountGroupsTypes,
  fetchScreenGroupsTypes,
  fetchFunctionTypes,
  fetchConsFunctionTypes,
} from './types';

export const initialState = {
  accounts: [],
  isFetchingAccounts: false,
  accountGroups: [],
  isFetchingAccountGroups: false,
  screenGroups: [],
  isFetchingScreenGroups: false,
  functions: [],
  isFetchingFunctions: false,
  consFunctions: [],
  isFetchingConsFunctions: false,
};

const accountsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountsTypes,
      payloadKey: 'accounts',
      fetchingKey: 'isFetchingAccounts',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountGroupsTypes,
      payloadKey: 'accountGroups',
      fetchingKey: 'isFetchingAccountGroups',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchScreenGroupsTypes,
      payloadKey: 'screenGroups',
      fetchingKey: 'isFetchingScreenGroups',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFunctionTypes,
      payloadKey: 'functions',
      fetchingKey: 'isFetchingFunctions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchConsFunctionTypes,
      payloadKey: 'consFunctions',
      fetchingKey: 'isFetchingConsFunctions',
    }),
  },
});

export default accountsReducer;
