import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  UserEmailValidationStatus,
  INVALID_EMAIL_DEFAULT_MESSAGE,
} from '@common-packages/shared-constants';

import { isEngagementAdminRoleSelector, customerDataSelector } from '../../shared/store/selectors';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import Loading from '../../shared/displayComponents/loading.component';

import { addUserFormTypes } from './propTypes';

const valuesToSet = ['firstName', 'lastName', 'activeInd', 'roleId'];
const setValues = (values, keys, setField, shouldRunValidation) => {
  keys.forEach(key => {
    setField(key, values[key], shouldRunValidation);
  });
};

const USER_EXISTS_FOR_CURRENT_CLIENT_ERROR_MESSAGE =
  'User with this email already exists for this client, a unique email is required before saving';

const AddUserForm = ({
  handleBlur,
  setFieldValue,
  setFieldError,
  values,
  errors,
  isSubmitting,
  onCancelClick,
  submitForm,
  isEditMode,
  isValid,
  initialValues,
  visible,
  rolesOptions,

  isValidatingUserEmail,
  validateUserEmail,
  validationResult,
}) => {
  const [isUserInAnotherClient, setIsUserInAnotherClient] = useState(false);
  const doesUserAlreadyExist = errors.email === USER_EXISTS_FOR_CURRENT_CLIENT_ERROR_MESSAGE;
  const customerData = useSelector(customerDataSelector);
  const isCurrentUserSelected = values?.userId === customerData.customerId;
  const isEngagementAdminRole = useSelector(isEngagementAdminRoleSelector);

  const { status, userDataInAnotherClient } = validationResult;
  const handleEmailBlurWithValidation = useCallback(
    event => {
      const emailInputValue = event.currentTarget.value;
      handleBlur(event);

      if (errors.email === INVALID_EMAIL_DEFAULT_MESSAGE) {
        return;
      }

      if (emailInputValue) {
        validateUserEmail(emailInputValue);
      }
    },
    [errors, handleBlur, validateUserEmail],
  );

  useEffect(() => {
    if (!status || !visible) {
      return;
    }
    if (status === UserEmailValidationStatus.USER_EXISTS_FOR_CURRENT_CLIENT) {
      setFieldError('email', USER_EXISTS_FOR_CURRENT_CLIENT_ERROR_MESSAGE);
      setValues(initialValues, valuesToSet, setFieldValue, false);
      setIsUserInAnotherClient(false);
    }
    if (status === UserEmailValidationStatus.USER_EXISTS_FOR_ANOTHER_CLIENT) {
      setValues(userDataInAnotherClient, valuesToSet, setFieldValue, true);
      setIsUserInAnotherClient(true);
    }
    if (status === UserEmailValidationStatus.USER_NOT_FOUND && isUserInAnotherClient) {
      setValues(initialValues, valuesToSet, setFieldValue, true);
      setIsUserInAnotherClient(false);
    }
  }, [
    setFieldError,
    setFieldValue,
    status,
    userDataInAnotherClient,
    initialValues,
    visible,
    setIsUserInAnotherClient,
    isUserInAnotherClient,
  ]);

  const getExpireDate = () => {
    const currentDate = new Date();

    const month = currentDate.getMonth() + 1; //month starts in 0, adding 1 to get current
    const day = currentDate.getDate();
    const expireYear = currentDate.getFullYear() + 1;

    return month + '/' + day + '/' + expireYear;
  };

  return (
    <form>
      <div>
        <Loading isLoading={isValidatingUserEmail} small>
          <Field
            className="form-text"
            label="Email Address"
            name="email"
            component={Input}
            value={values.email || ''}
            onBlur={isEditMode ? handleBlur : handleEmailBlurWithValidation}
          />
        </Loading>
        <Field
          className="form-text"
          label="First Name"
          name="firstName"
          component={Input}
          value={values.firstName}
          disabled={isValidatingUserEmail || doesUserAlreadyExist}
        />
        <Field
          className="form-text"
          label="Last Name"
          name="lastName"
          component={Input}
          value={values.lastName}
          disabled={isValidatingUserEmail || doesUserAlreadyExist}
        />
        <Select
          wrapperClassName="form-text"
          appkitLabel="Role"
          name="roleId"
          options={rolesOptions}
          value={values.roleId}
          disabled={
            isValidatingUserEmail ||
            doesUserAlreadyExist ||
            (isCurrentUserSelected && isEngagementAdminRole)
          }
        />
        {!isEditMode && (
          <Field
            className="form-text"
            type="text"
            label="Expires"
            name="expires"
            component={Input}
            disabled
            value={getExpireDate()}
          />
        )}
        <Field
          wrapperClassName="form-text"
          name="activeInd"
          label="Active"
          component={Checkbox}
          disabled={isValidatingUserEmail || doesUserAlreadyExist}
        />
      </div>
      <div>
        <ButtonsGroup
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={onCancelClick}
          submitButtonLabel="Save"
          disabled={!isValid || isValidatingUserEmail || doesUserAlreadyExist}
        />
      </div>
    </form>
  );
};

AddUserForm.propTypes = {
  values: addUserFormTypes.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  isValidatingUserEmail: PropTypes.bool.isRequired,
  validateUserEmail: PropTypes.func.isRequired,
  validationResult: PropTypes.shape({
    status: PropTypes.string,
    userDataInAnotherClient: PropTypes.shape({
      roleId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      activeInd: PropTypes.bool,
    }),
  }).isRequired,
  initialValues: PropTypes.shape({
    roleId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    activeInd: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    email: PropTypes.string,
  }),
  isValid: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  rolesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
};

export default AddUserForm;
