import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import { BreadcrumbRoute } from '../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

import FlowThroughK1Details from './flowThroughK1Details.container';

const FlowThroughK1DetailsBreadcrumbRoute = () => (
  <>
    <BreadcrumbRoute path={Routes.flowThroughK1Details} />
  </>
);

const FlowThroughK1DetailsPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: FlowThroughK1DetailsBreadcrumbRoute,
}))(GlobalContextPage);

const FlowThroughK1DetailsRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.flowThroughK1Details.MAIN}
    wrapper={FlowThroughK1DetailsPage}
    component={FlowThroughK1Details}
    hideGlobalContext
  />
);

export default FlowThroughK1DetailsRoute;
