import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button } from '@pwc/appkit-react/lib/Button';
import { Routes } from '@common-packages/routes-definitions';
import { saveAs } from 'file-saver';
import { useQueryClient } from 'react-query';
import classnames from 'classnames';
import { JobTypes } from '@common-packages/shared-constants';

import config from '../config';
import { dateFormatter } from '../shared/formatters';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../shared/hooks/useModal.hook';
import Loading from '../shared/displayComponents/loading.component';
import toggleAllSelectionsHeaderFactory from '../shared/displayComponents/toggleAllSelectionsHeaderFactory';
import SelectContextDataInfo from '../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { isFetchingGlobalContextSelector, globalContextSelector } from '../shared/store/selectors';
import { fetchNavigatorBinderData } from '../navigator/store';
import { useRowEditMode } from '../shared/editMode';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import { QueryKeys } from '../shared/queries';
import { useQuerySltBinderOptions } from '../shared/queries/binders';
import { sltBinderIdSelector } from '../shared/store/context';
import { startUserJobPolling, cancelUserJobPolling } from '../jobs/store/actions';
import { isJobInProgress } from '../jobs/utils';
import { useQueryLatestPdfBinderGenerationJob } from '../shared/queries/binderMaintenance';

import UpdateSortOrderModal from './updateSortOrderModal.component';
import CopyBinderFormModal from './copyBinderForm.component';
import AddBinderItemModal from './addBinderItemModal.container';
import getColumnDefinitions from './binderMaintenance.columnDefinitions';
import styles from './binderMaintenance.module.scss';
import sortBinders from './sortBinders';
import {
  fetchBinderItems,
  updateBinderItemsOrder,
  fetchBinderForms,
  fetchBinderAttachments,
  deleteBinderItems,
  copyBinder,
  fetchBinderPdfLastGenerationDate,
  generateBinderPdf,
  downloadBinderPdf,
  resetCopiedBinder,
} from './store/actions';
import {
  binderItemsSelector,
  isFetchingBinderItemsSelector,
  isEditingBinderItemsSelector,
  isFetchingBinderFormsSelector,
  binderFormsSelector,
  binderAttachmentsSelector,
  isFetchingBinderAttachmentsSelector,
  binderPdfLastGenerationDateSelector,
  isFetchingBinderPdfLastGenerationDateSelector,
  isDownloadingBinderPdfSelector,
  copiedBinderSelector,
  isStartingProcessOfGeneratingBinderPdfSelector,
} from './store/selectors';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const BinderMaintenance = ({ hasUserPermissionsToEdit, history, location }) => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const globalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);
  const binderItems = useSelector(binderItemsSelector);
  const isFetchingBinderItems = useSelector(isFetchingBinderItemsSelector);
  const isEditingBinderItems = useSelector(isEditingBinderItemsSelector);
  const isFetchingBinderForms = useSelector(isFetchingBinderFormsSelector);
  const binderForms = useSelector(binderFormsSelector);
  const binderAttachments = useSelector(binderAttachmentsSelector);
  const isFetchingBinderAttachments = useSelector(isFetchingBinderAttachmentsSelector);
  const binderPdfLastGenerationDate = useSelector(binderPdfLastGenerationDateSelector);
  const isFetchingBinderPdfLastGenerationDate = useSelector(
    isFetchingBinderPdfLastGenerationDateSelector,
  );
  const isDownloadingBinderPdf = useSelector(isDownloadingBinderPdfSelector);
  const copiedBinder = useSelector(copiedBinderSelector);

  const [binderId, setBinderId] = useState(null);
  const [selectAllDeleteState, setSelectAllDeleteState] = useState(false);

  const contextSltBinderId = useSelector(sltBinderIdSelector);

  const isStartingProcessOfGeneratingBinderPdf = useSelector(
    isStartingProcessOfGeneratingBinderPdfSelector,
  );

  const sltBinders = useQuerySltBinderOptions({
    params: globalContext.apiRouteParamString,
    enabled: globalContext.isReady,
  });

  const isLoading =
    isFetchingBinderItems ||
    sltBinders.isFetching ||
    isFetchingBinderForms ||
    isFetchingBinderAttachments ||
    isEditingBinderItems ||
    isFetchingGlobalContext;

  useEffect(() => {
    setSelectAllDeleteState(false);
  }, [isLoading]);

  useEffect(() => {
    dispatch(resetCopiedBinder());
  }, [dispatch]);

  useEffect(() => {
    if (binderId && globalContext.isReady) {
      dispatch(fetchBinderAttachments({ binderId, ...globalContext.params }));
    }
  }, [dispatch, binderId, globalContext.isReady, globalContext.params]);

  useEffect(() => {
    if (globalContext.isReady) {
      dispatch(fetchBinderForms(globalContext));
    }
  }, [dispatch, binderId, globalContext]);

  useEffect(() => {
    const { sltBinderOptions } = sltBinders.data;
    if (sltBinderOptions.length) {
      if (copiedBinder) {
        setBinderId(Number(copiedBinder.id));
      } else {
        setBinderId(sltBinderOptions[0].value);
      }
    }
  }, [sltBinders.data, setBinderId, copiedBinder]);

  const {
    data: latestPdfBinderGenerationJobData,
    isFetching: isFetchingLatestPdfBinderGenerationJob,
    refetch: fetchLatestPdfBinderGenerationJob,
  } = useQueryLatestPdfBinderGenerationJob({
    params: { binderId },
    enabled: Boolean(binderId),
  });

  const latestPdfBinderGenerationJobId = latestPdfBinderGenerationJobData?.jobId;
  const generateBinderPdfJobData = useSelector(
    ({ jobs }) => jobs[latestPdfBinderGenerationJobId] || null,
  );

  useEffect(() => {
    if (!generateBinderPdfJobData) {
      return;
    }
    if (!isJobInProgress(generateBinderPdfJobData.status)) {
      dispatch(cancelUserJobPolling(generateBinderPdfJobData.jobId));
      dispatch(fetchBinderPdfLastGenerationDate({ binderId }));
    }
  }, [dispatch, binderId, generateBinderPdfJobData]);

  const isPdfProcessed = Boolean(
    binderId &&
      (!latestPdfBinderGenerationJobId ||
        generateBinderPdfJobData?.finishedOn ||
        generateBinderPdfJobData?.returnedError),
  );

  const isGenerateButtonDisabled =
    !isPdfProcessed ||
    isStartingProcessOfGeneratingBinderPdf ||
    isFetchingLatestPdfBinderGenerationJob;

  useEffect(() => {
    if (binderId) {
      fetchLatestPdfBinderGenerationJob({ objectId: binderId });
    }
  }, [fetchLatestPdfBinderGenerationJob, binderId]);

  useEffect(() => {
    if (!latestPdfBinderGenerationJobId) {
      return;
    }
    if (!isPdfProcessed) {
      dispatch(
        startUserJobPolling({
          jobType: JobTypes.GENERATE_BINDER_PDF_STATUS,
          jobId: latestPdfBinderGenerationJobId,
          successAction: () => () => null,
          errorMessage: 'There was an error getting pdf generation status',
        }),
      );
      return () => {
        dispatch(cancelUserJobPolling(latestPdfBinderGenerationJobId));
      };
    }
  }, [dispatch, latestPdfBinderGenerationJobId, isPdfProcessed]);

  useEffect(() => {
    if (binderId) {
      dispatch(fetchBinderPdfLastGenerationDate({ binderId }));
    }
  }, [dispatch, binderId]);

  const onUpdateSortOrder = useCallback(
    async sortOption => {
      const sortedBinders = sortBinders(binderItems, sortOption);

      await dispatch(updateBinderItemsOrder(sortedBinders));
      dispatch(
        fetchBinderItems({
          globalContext,
          binderId,
        }),
      );
    },
    [dispatch, binderItems, globalContext, binderId],
  );

  const fetchGridRows = useCallback(() => {
    if (globalContext.isReady && binderId) {
      dispatch(
        fetchBinderItems({
          globalContext,
          binderId,
        }),
      );
    }
  }, [dispatch, binderId, globalContext]);

  useEffect(() => {
    fetchGridRows();
  }, [fetchGridRows]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ newRow }) => ({
        id: newRow.rowId,
        displayOrder: newRow.displayOrder,
      }));
      await dispatch(updateBinderItemsOrder(rowsToUpdate));

      dispatch(
        fetchBinderItems({
          globalContext,
          binderId,
        }),
      );

      if (contextSltBinderId === binderId) {
        dispatch(fetchNavigatorBinderData({ binderId }));
      }
    },
    [dispatch, globalContext, binderId, contextSltBinderId],
  );

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    onGridReady,
    updateRow,
    gridApi,
  } = useRowEditMode({
    onSave: saveChanges,
    saveButtonDisabled: isFetchingBinderItems || isEditingBinderItems,
    rowData: binderItems,
    getUniqueRowId,
    isPermanentlyInEditMode: true,
  });

  const rowsToDelete = clonedRowData.filter(({ isDeleteSelected }) => isDeleteSelected);

  const isAllSelected = clonedRowData.every(({ isDeleteSelected }) => isDeleteSelected);
  useEffect(() => setSelectAllDeleteState(isAllSelected), [isAllSelected]);

  const handleOverrideAllValues = useCallback(
    newValue => {
      gridApi.forEachNodeAfterFilter(({ data }) => {
        data.isDeleteSelected = newValue;
        updateRow(data);
      });
      gridApi.refreshCells({ force: true });
    },
    [gridApi, updateRow],
  );

  const openModalForDelete = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Delete binder item(s)',
        text: `Are you sure you want to delete ${rowsToDelete.length} binder item(s)?`,
        confirmCallback: async () => {
          await dispatch(
            deleteBinderItems({
              binderItemIds: rowsToDelete.map(row => ({ id: row.rowId })),
            }),
          );
          dispatch(
            fetchBinderItems({
              globalContext,
              binderId,
            }),
          );
          if (contextSltBinderId === binderId) {
            dispatch(fetchNavigatorBinderData({ binderId }));
          }
        },
      }),
    );
  }, [dispatch, globalContext, binderId, contextSltBinderId, rowsToDelete]);

  const { showModal: showAddBinderItemModal, modalProps: addBinderItemModalProps } = useModal();

  const columnDefinitions = useMemo(() => {
    const ToggleAllSelectionsHeader = toggleAllSelectionsHeaderFactory({
      togglerState: selectAllDeleteState,
      setTogglerState: setSelectAllDeleteState,
      isInEditMode,
      handleOverrideAllValues,
      marginLabel: false,
    });

    return getColumnDefinitions({
      globalContext,
      isInEditMode,
      binderForms,
      binderAttachments,
      updateRow,
      hasUserPermissionsToEdit,
      ToggleAllSelectionsHeader,
    });
  }, [
    updateRow,
    globalContext,
    isInEditMode,
    binderForms,
    binderAttachments,
    hasUserPermissionsToEdit,
    selectAllDeleteState,
    handleOverrideAllValues,
  ]);

  const onBinderChange = useCallback(binder => {
    setBinderId(binder.value);
  }, []);

  const { showModal: showCopyBinderModal, modalProps: copyBinderModalProps } = useModal();
  const { showModal: showUpdateSortOrderModal, modalProps: updateSortOrderModalProps } = useModal();

  const onCopyBinder = useCallback(
    async name => {
      await dispatch(copyBinder({ name, binderId }));
      queryClient.resetQueries(QueryKeys.Binders.SltBinderOptions);
    },
    [dispatch, binderId, queryClient],
  );

  const downloadAndSave = useCallback(async () => {
    const response = await dispatch(downloadBinderPdf({ binderId }));

    if (!response) {
      return;
    }

    const data = new Blob([response]);
    saveAs(data, 'binder.pdf');
  }, [dispatch, binderId]);

  const openModalForDownload = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Download the Binder',
        text: 'Prior to download the Binder will first be generated.',
        confirmCallback: async () => {
          await dispatch(generateBinderPdf({ binderId }));
          await downloadAndSave();
          dispatch(
            fetchBinderPdfLastGenerationDate({
              binderId,
            }),
          );
        },
      }),
    );
  }, [dispatch, downloadAndSave, binderId]);

  const onDownload = useCallback(async () => {
    if (!binderPdfLastGenerationDate) {
      openModalForDownload();
    } else {
      downloadAndSave();
    }
  }, [openModalForDownload, downloadAndSave, binderPdfLastGenerationDate]);

  const onGenerateBinderPdf = useCallback(async () => {
    await dispatch(generateBinderPdf({ binderId }));
    fetchLatestPdfBinderGenerationJob({ binderId });
  }, [dispatch, fetchLatestPdfBinderGenerationJob, binderId]);

  const redirectToAttachmentPage = useCallback(() => {
    history.push(Routes.attachments.MAIN, { returnLocation: location });
  }, [history, location]);

  if (!globalContext.isReady) {
    return <SelectContextDataInfo />;
  }

  const consolLabelPrefix = globalContext.isConsolidated ? 'Consolidated ' : '';

  const renderPdfLastGenerationDate = () => {
    if (isJobInProgress(generateBinderPdfJobData?.status)) {
      return (
        <span>
          PDF is currently generating <Loading isLoading small />
        </span>
      );
    } else if (isFetchingBinderPdfLastGenerationDate) {
      return (
        <span>
          Checking PDF Last Generated Date and Time <Loading isLoading small />
        </span>
      );
    } else {
      return (
        <span>
          PDF Last Generated Date and Time:{' '}
          {dateFormatter(binderPdfLastGenerationDate, config.AMERICAN_DATE_TIME_FORMAT)}
        </span>
      );
    }
  };

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className={`col ${styles.binderInformation}`}>
          {consolLabelPrefix}Binder Information:
        </div>
      </div>
      <div className="row">
        <div className={`col ${styles.binderInformationContext}`}>
          <div className={`global-context-dropdown ${styles.binderInformationParamDropdown}`}>
            <Loading isLoading={sltBinders.isFetching || isFetchingGlobalContext}>
              <SDKCustomSelect
                appkitLabel={`${consolLabelPrefix}Binder Name:`}
                className="sdk-custom-select"
                options={sltBinders.data.sltBinderOptions}
                value={binderId}
                virtualized
                onChange={onBinderChange}
              />
            </Loading>
          </div>
          <div className={styles.binderInformationPdfDate}>
            <Loading isLoading={isLoading} small>
              {renderPdfLastGenerationDate()}
            </Loading>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`col ${styles.binderInformation}`}>
          {consolLabelPrefix}Binder Documents:
        </div>
        <div className="col add-button-column">
          {hasUserPermissionsToEdit && (
            <Button size="lg" className="add-button" onClick={showCopyBinderModal}>
              Copy binder
            </Button>
          )}
          <Button
            size="lg"
            className="add-button"
            onClick={onGenerateBinderPdf}
            disabled={isLoading || isGenerateButtonDisabled}
          >
            Generate PDF
          </Button>
          <Button
            size="lg"
            className="add-button"
            onClick={onDownload}
            disabled={isLoading || isDownloadingBinderPdf || isGenerateButtonDisabled}
          >
            Download PDF
          </Button>
          <Button size="lg" className="add-button" onClick={redirectToAttachmentPage}>
            Upload attachment
          </Button>
          {hasUserPermissionsToEdit && (
            <Button size="lg" className="add-button" onClick={showAddBinderItemModal}>
              Add Item
            </Button>
          )}
          {hasUserPermissionsToEdit && (
            <Button
              size="lg"
              className="add-button"
              onClick={openModalForDelete}
              disabled={!rowsToDelete.length}
            >
              Delete Item
            </Button>
          )}
          {hasUserPermissionsToEdit && (
            <Button
              size="lg"
              className="add-button"
              onClick={showUpdateSortOrderModal}
              disabled={!binderItems.length}
            >
              Update Sort Order
            </Button>
          )}
          {hasUserPermissionsToEdit && !sltBinders.isFetching && editModeButtons}
        </div>
      </div>
      <div className={classnames('row', 'grid-row', styles.binderGrid)}>
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            onGridReady={onGridReady}
            isGridLoading={isLoading}
            columnDefs={columnDefinitions}
            stopEditingWhenCellsLoseFocus
            withSearchBar
            singleClickEdit
            suppressCellFocus={!isInEditMode}
          />
        </div>
      </div>
      <CopyBinderFormModal
        {...copyBinderModalProps}
        onCopyBinder={onCopyBinder}
        existingBindersNames={sltBinders.data.sltBinderOptions.map(b => b.label)}
      />
      <UpdateSortOrderModal {...updateSortOrderModalProps} onUpdateSortOrder={onUpdateSortOrder} />
      <AddBinderItemModal
        {...addBinderItemModalProps}
        globalContext={globalContext}
        binderId={binderId}
        refetchGridData={fetchGridRows}
      />
    </>
  );
};

BinderMaintenance.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default BinderMaintenance;
