import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import {
  taxYearSelector,
  periodSelector,
  entityIdSelector,
  jurisdictionIdSelector,
  isFetchingGlobalContextSelector,
  isPartnershipSelector,
} from '../../../shared/store/selectors';
import SelectContextDataInfo from '../selectContextDataInfo/selectContextDataInfo.component';
import Loading from '../loading.component';

const withPartnershipGuard = WrappedComponent => {
  const PartnershipGuard = ({
    isPartnership,
    history,

    taxYear,
    period,
    entityId,
    jurisdictionId,
    isFetchingGlobalContext,

    ...props
  }) => {
    if (isFetchingGlobalContext) {
      return <Loading isLoading />;
    }

    const isContextReady = taxYear && period && entityId && jurisdictionId;
    if (!isContextReady) {
      return <SelectContextDataInfo />;
    }

    if (isPartnership === null) {
      return <Loading isLoading />;
    }

    if (!isPartnership) {
      history.push(Routes.entityFilingStatesReturnWorkspace.MAIN);
    }

    return <WrappedComponent {...props} />;
  };

  PartnershipGuard.propTypes = {
    isPartnership: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,

    taxYear: PropTypes.string,
    period: PropTypes.string,
    entityId: PropTypes.string,
    jurisdictionId: PropTypes.string,
    isFetchingGlobalContext: PropTypes.bool.isRequired,
  };

  return withRouter(
    connect(state => ({
      isPartnership: isPartnershipSelector(state),

      taxYear: taxYearSelector(state),
      period: periodSelector(state),
      entityId: entityIdSelector(state),
      jurisdictionId: jurisdictionIdSelector(state),
      isFetchingGlobalContext: isFetchingGlobalContextSelector(state),
    }))(PartnershipGuard),
  );
};

export default withPartnershipGuard;
