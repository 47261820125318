import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Modal from '@tls/ui-modal';
import { taxFormsV2Schemas } from '@common-packages/validators';

import { preparePdfFormDataWithJsonPayload } from '../../../utils/preparePdfFormDataWithJsonPayload';
import { formDetailsProps } from '../formDetails/form/propTypes';
import { formsSelector } from '../../store/selectors';
import {
  useMutationValidateTaxFormAttachment,
  useMutationAddTaxFormAttachment,
  useMutationUpdateTaxFormAttachment,
} from '../../../shared/mutations/taxFormsV2';

import PdfAttachmentForm from './pdfAttachmentForm.component';
import PdfAttachmentValidation from './pdfAttachmentValidation.component';
import styles from './pdfAttachment.module.scss';

const PdfAttachmentModal = ({ hideModal, visible, modalData, onSubmit = () => null }) => {
  const taxForms = useSelector(formsSelector);

  const [shouldDisplayFormValidation, setShouldDisplayFormValidation] = useState(false);
  const [pdfValidationDifferences, setPdfValidationDifferences] = useState([]);
  const [newPdfFileName, setNewPdfFileName] = useState(null);
  const [existingPdfFileName, setExistingPdfFileName] = useState(null);

  const { mutateAsync: validateTaxFormAttachment } = useMutationValidateTaxFormAttachment();
  const { mutateAsync: updateTaxFormAttachmentMutation } = useMutationUpdateTaxFormAttachment();
  const { mutateAsync: addTaxFormAttachmentMutation } = useMutationAddTaxFormAttachment();

  const updateAttachmentSchema = useMemo(
    () => taxFormsV2Schemas.getTaxFormAttachmentSchema({ taxForms }),
    [taxForms],
  );

  useEffect(() => {
    const pdfFileName = taxForms?.find(({ formId }) => formId === modalData?.formId)?.pdfLibPdf;

    if (pdfFileName) {
      setExistingPdfFileName(pdfFileName);
    }
  }, [taxForms, modalData]);

  const goBackToForm = useCallback(() => {
    setShouldDisplayFormValidation(false);
    setPdfValidationDifferences([]);
  }, []);

  const updateTaxFormAttachment = useCallback(
    async ({ formId, formData }) => {
      await updateTaxFormAttachmentMutation({
        formId,
        formData: preparePdfFormDataWithJsonPayload(formData),
      });
      return formId;
    },
    [updateTaxFormAttachmentMutation],
  );

  const addTaxFormAttachment = useCallback(
    async ({ formData }) => {
      const { formId } = await addTaxFormAttachmentMutation({
        formData: preparePdfFormDataWithJsonPayload(formData),
      });
      return formId;
    },
    [addTaxFormAttachmentMutation],
  );

  // this function is called from both step 1 (form) and step 2 (validation results)
  const handleSubmit = useCallback(
    async (
      {
        pdfLibPdfFile,
        pdfLibPdfFileName,
        formId,
        formName,
        formDescription,
        swpFormId,
        jurisdictionId,
        taxYear,
      },
      { setSubmitting },
    ) => {
      setSubmitting(true);
      setNewPdfFileName(pdfLibPdfFileName);
      const formValues = {
        pdfLibPdfFileName,
        formName,
        formDescription,
        swpFormId,
        jurisdictionId,
        taxYear,
      };
      const formData = {
        formValues,
        pdfLibPdfFile,
      };

      try {
        // In this `if` we run validation (comparision to existing one) of PDF. This should only
        // happen if:
        // 1. There is `formId` - we are updating a form and not adding a new one
        // 2. `shouldDisplayFormValidation` is `false` - we are on the 1st step of updating a form
        // 3. There is `pdfLibPdfFile` - PDF was updated
        // 4. `existingPdfFileName` - There exists PDF file that is going to be replaced
        // In above conditions are not met, we don't need to validate the PDF

        if (formId && !shouldDisplayFormValidation && pdfLibPdfFile && existingPdfFileName) {
          const pdfDifferences = await validateTaxFormAttachment({
            formId,
            formData: preparePdfFormDataWithJsonPayload(formData),
          });

          setPdfValidationDifferences(pdfDifferences);
          setShouldDisplayFormValidation(true);
          setSubmitting(false);
        } else {
          const taxFormId = formId
            ? await updateTaxFormAttachment({
                formId,
                formData,
              })
            : await addTaxFormAttachment({
                formData,
              });
          setSubmitting(false);
          hideModal();
          onSubmit(taxFormId);
        }
      } catch (error) {
        setSubmitting(false);
      }
    },
    [
      updateTaxFormAttachment,
      addTaxFormAttachment,
      onSubmit,
      hideModal,
      validateTaxFormAttachment,
      shouldDisplayFormValidation,
      existingPdfFileName,
    ],
  );

  return visible ? (
    <Modal
      className={styles.modal}
      closeAction={hideModal}
      visible={visible}
      title={modalData?.formId ? 'Update Form' : 'Add Form'}
    >
      <Formik
        initialValues={modalData}
        enableReinitialize
        validationSchema={updateAttachmentSchema}
        onSubmit={handleSubmit}
      >
        {shouldDisplayFormValidation ? (
          <PdfAttachmentValidation
            pdfValidationDifferences={pdfValidationDifferences}
            onCancel={goBackToForm}
            existingPdfFileName={existingPdfFileName}
            newPdfFileName={newPdfFileName}
          />
        ) : (
          <PdfAttachmentForm onCancel={hideModal} />
        )}
      </Formik>
    </Modal>
  ) : null;
};

PdfAttachmentModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  modalData: formDetailsProps,
  onSubmit: PropTypes.func,
};

export default PdfAttachmentModal;
