import { httpGetAndParse } from '../../utils/fetchService';

export const countRootEntitiesByState = async ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/home-page/count-root-entities-by-state-code/${taxYear}/${period}`,
    errorMessage: 'Counting root entities by state failed',
  });

export const incomeTaxLiability = async ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/home-page/income-tax-liability/${taxYear}/${period}`,
    errorMessage: 'Counting root entities by state failed',
  });
