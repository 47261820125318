import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { useQueryTransmitterSetup } from '../../../../shared/queries/eFileSupport';
import { taxYearSelector, periodSelector } from '../../../../shared/store/selectors';

import styles from './transmitterSetup.module.scss';
import getColumnDefinitions from './transmitterSetup.columnDefinitions';

const TransmitterSetup = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const {
    data: transmitterSetupData,
    isLoading: isLoadingTransmitterSetupData,
  } = useQueryTransmitterSetup({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period),
  });

  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);

  return (
    <>
      <AgGrid
        className={styles.grid}
        rowData={transmitterSetupData}
        columnDefs={columnDefinitions}
        isGridLoading={isLoadingTransmitterSetupData}
        enableBrowserTooltips
        autoMaxWidth
      />
    </>
  );
};

export default TransmitterSetup;
