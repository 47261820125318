import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { globalContextSelector } from '../shared/store/selectors';
import { clientIdSelector } from '../shared/store/context';
import { fetchCalcStatus, setCalcStatus } from '../shared/store/calcStatus/actions';
import { CLIENT_STATUS_KEY } from '../shared/localStorage';
import { useLocalStorage } from '../shared/hooks/useLocalStorage.hook';

const runAsyncScheduler = (callback, interval) => {
  let timer = null;
  let stopped = false;

  const iteration = async () => {
    await callback();
    if (!stopped) {
      timer = setTimeout(iteration, interval);
    }
  };

  iteration();

  const stop = () => {
    clearTimeout(timer);
    stopped = true;
  };
  return stop;
};

const NavigationPanel = ({ fullWidthPage }) => {
  const dispatch = useDispatch();
  const globalContext = useSelector(globalContextSelector);
  const clientId = useSelector(clientIdSelector);
  const [context] = useLocalStorage(CLIENT_STATUS_KEY.CONTEXT, {}, clientId);

  useEffect(() => {
    if (!globalContext.isReady) {
      return;
    }
    const stop = runAsyncScheduler(() => dispatch(fetchCalcStatus(globalContext)), 5000);
    return () => {
      stop();
      dispatch(setCalcStatus(null));
    };
  }, [dispatch, globalContext]);

  return (
    <div style={{ display: fullWidthPage ? 'none' : 'block' }} id={'navigation-panel-root'}>
      <div
        className={`navigator-tab-context-wrapper ${
          context?.isContextPinned ? 'navigator-tab-context-wrapper-pinned' : ''
        }`}
      >
        <div id="navigation-panel-context-wrapper" />
        <div id="navigation-tab-wrapper" />
        <div id="navigation-collapse-icon" />
      </div>
      <div id="navigator-wrapper" />
    </div>
  );
};

NavigationPanel.propTypes = {
  fullWidthPage: PropTypes.bool.isRequired,
};

export default NavigationPanel;
