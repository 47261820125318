import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';
import { useSelector, useDispatch } from 'react-redux';
import uniqBy from 'lodash.uniqby';
import { TOTAL_DESC } from '@common-packages/shared-constants';

import { updateGenericCategory } from '../../../shared/store/genericCategory/actions';
import { globalContextSelector } from '../../../shared/store/selectors';
import { useQueryGenericCategoryComponentOptions } from '../../../shared/queries/genericCategory';

import { genericCategoryPropTypes } from './genericCategoryPropTypes';
import AddComponentAdjustmentForm from './addComponentAdjustmentForm.component';

const initialModalData = {
  accountId: null,
  componentId: null,
  value: 0,
};

const AddComponentAdjustmentModal = ({ hideModal, visible, genericCategory, submitCallback }) => {
  const dispatch = useDispatch();

  const globalContext = useSelector(globalContextSelector);

  const {
    params: { taxYear, period, businessEntityId, jurisdictionId },
  } = globalContext;

  const {
    isFetching: isFetchingComponentOptions,
    data: componentOptions,
  } = useQueryGenericCategoryComponentOptions({
    params: { taxYear, period, businessEntityId },
    enabled: Boolean(taxYear && (period || period === 0) && businessEntityId),
  });

  const add = useCallback(
    async (values, { resetForm }) => {
      hideModal();
      resetForm();

      const genericCategoryForAccount = genericCategory.find(
        category => category.accountId === values.accountId,
      );

      await dispatch(
        updateGenericCategory({
          period,
          taxYear,
          orgId: businessEntityId,
          jurisdictionId,
          rowsToUpdate: [
            {
              ...values,
              value: Number(values.value),
              accountJurisdictionId: genericCategoryForAccount.accountJurisdictionId,
              begEndFlag: genericCategoryForAccount.begEndFlag,
            },
          ],
        }),
      );
      submitCallback();
    },
    [
      dispatch,
      submitCallback,
      hideModal,
      taxYear,
      period,
      jurisdictionId,
      businessEntityId,
      genericCategory,
    ],
  );

  const genericCategoryAccountOptions = useMemo(
    () =>
      uniqBy(genericCategory, 'accountId')
        .filter(({ isEditable, accountId }) => accountId !== TOTAL_DESC && isEditable)
        .map(({ accountId }) => ({
          value: accountId,
          label: accountId,
        })),
    [genericCategory],
  );

  const onClose = useCallback(
    ({ resetForm }) => () => {
      hideModal();
      resetForm();
    },
    [hideModal],
  );

  const renderModal = useCallback(
    formikProps => (
      <Modal
        title="Add Component Adjustment"
        closeAction={onClose(formikProps)}
        visible={visible}
        submitText="Submit"
        submitAction={formikProps.submitForm}
        submitDisabled={!formikProps.values.componentId || !formikProps.values.accountId}
        dismissText="Cancel"
        dismissAction={onClose(formikProps)}
      >
        <AddComponentAdjustmentForm
          {...formikProps}
          genericCategoryAccountOptions={genericCategoryAccountOptions}
          componentOptions={componentOptions}
          isFetchingComponentOptions={isFetchingComponentOptions}
        />
      </Modal>
    ),
    [onClose, visible, componentOptions, genericCategoryAccountOptions, isFetchingComponentOptions],
  );

  return (
    <Formik initialValues={initialModalData} enableReinitialize onSubmit={add}>
      {renderModal}
    </Formik>
  );
};

AddComponentAdjustmentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  submitCallback: PropTypes.func.isRequired,
  genericCategory: PropTypes.arrayOf(genericCategoryPropTypes).isRequired,
};

export default AddComponentAdjustmentModal;
