import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchFilingGroupsTypes = getApiActionTypes('CONSOLIDATION/FETCH_FILING_GROUPS');
export const SELECT_FILING_GROUP_ID = 'CONSOLIDATION/SELECT_FILING_GROUP_ID';

export const fetchFilingGroupsOptionsTypes = getApiActionTypes(
  'CONSOLIDATION/FETCH_FILING_GROUPS_OPTIONS',
);
export const addFilingGroupToConsolidationTypes = getApiActionTypes(
  'CONSOLIDATION/ADD_FILING_GROUP_TO_CONSOLIDATION',
);
export const removeFilingGroupFromConsolidationTypes = getApiActionTypes(
  'CONSOLIDATION/REMOVE_FILING_GROUP_FROM_CONSOLIDATION',
);
