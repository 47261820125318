import {
  FederalSubmissionId,
  ProformaSubmissionId,
  ReturnOption,
  SubmissionId,
} from '../../../../../../../common/types';
import { FilingTypes } from '../../../../../shared/enums';
import { FederalReturnOption } from '../../../../../shared/enums/eFileV2';

import { federalReturnOptions } from './constants';

const generateFederalReturnOptions = (
  filingType: FilingTypes,
  isAsFiledFederalReturnOptionAvailable: boolean,
  isProformaFederalReturnOptionAvailable: boolean,
  isPdfFederalReturnOptionAvailable: boolean,
  isConsolAsFiledFederalReturnOptionAvailable: boolean,
  isConsolProformaFederalReturnOptionAvailable: boolean,
  isManualFederalReturnOptionAvailable: boolean,
  federalSubmissionId: FederalSubmissionId | null,
  proformaSubmissionId: ProformaSubmissionId | null,
  consolidatedAsFiledSubmissionIdForEntity: SubmissionId | null,
  consolidatedProformaSubmissionIdForEntity: SubmissionId | null,
  isLinkedAndReady: boolean,
) =>
  federalReturnOptions[filingType]
    .map((option: ReturnOption) => {
      if (
        option.value === FederalReturnOption.FED_AS_FILED &&
        isAsFiledFederalReturnOptionAvailable
      ) {
        return { ...option, federalId: federalSubmissionId };
      }

      if (
        option.value === FederalReturnOption.FED_PROFORMA &&
        isProformaFederalReturnOptionAvailable &&
        !isLinkedAndReady
      ) {
        return { ...option, federalId: proformaSubmissionId };
      }

      // Option is available when entity is also a part of a consolidation
      if (
        option.value === FederalReturnOption.CONS_AS_FILED &&
        isConsolAsFiledFederalReturnOptionAvailable
      ) {
        return { ...option, federalId: consolidatedAsFiledSubmissionIdForEntity };
      }

      // Option is available when entity is also a part of a consolidation
      if (
        option.value === FederalReturnOption.CONS_PROFORMA &&
        isConsolProformaFederalReturnOptionAvailable
      ) {
        return { ...option, federalId: consolidatedProformaSubmissionIdForEntity };
      }

      if (
        option.value === FederalReturnOption.FED_PDF &&
        isPdfFederalReturnOptionAvailable &&
        !isLinkedAndReady
      ) {
        return option;
      }

      if (
        option.value === FederalReturnOption.FED_MANUAL_PROFORMA &&
        isManualFederalReturnOptionAvailable
      ) {
        return option;
      }

      return null;
    })
    .filter(option => Boolean(option));

export default generateFederalReturnOptions;
