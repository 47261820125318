import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import getFieldValue from '../../../utils/getFieldValue';
import { SELECT_TAX_YEAR, SELECT_PERIOD } from '../../../shared/store/types';
import { SELECT_JURISDICTION_ID } from '../../store/types';

import {
  fetchGenericScreenDefinitionTypes,
  fetchCategoriesTypes,
  fetchAccountsTypes,
  fetchJurisdictionsTypes,
  fetchGenericScreenDefinitionRowDetailsTypes,
  SELECT_SCREEN_ID,
} from './types';

export const initialState = {
  genericScreenDefinition: [],
  isFetchingGenericScreenDefinition: false,
  accounts: [],
  categories: [],
  allJurisdictions: [],
  isFetchingAccounts: false,
  isFetchingCategories: false,
  isFetchingAllJurisdictions: false,
  genericScreenDefinitionRowDetails: [],
  isFetchingGenericScreenDefinitionRowDetails: false,
  screenId: null,
  previousScreenId: null,
};

const genericScreenDefinitionReducer = reducerFactory({
  initialState,
  actionHandlers: {
    [SELECT_TAX_YEAR]: state => ({
      ...state,
      screenId: null,
      previousScreenId: state.screenId,
    }),
    [SELECT_PERIOD]: state => ({
      ...state,
      screenId: null,
      previousScreenId: state.screenId,
    }),
    [SELECT_JURISDICTION_ID]: (state, payload) => ({
      ...state,
      screenId: (
        state.genericScreenDefinition.find(screen => screen.jurisdictionId === payload) || {
          screenId: null,
        }
      ).screenId,
    }),
    [SELECT_SCREEN_ID]: (state, payload) => ({
      ...state,
      screenId: payload,
      previousScreenId: state.screenId,
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchGenericScreenDefinitionTypes,
      payloadKey: 'genericScreenDefinition',
      fetchingKey: 'isFetchingGenericScreenDefinition',
      getAdditionalState: (state, payload) => ({
        screenId: getFieldValue({
          currentValueFieldName: 'screenId',
          payloadFieldName: 'screenId',
          state,
          payload,
        }),
      }),
      initialAdditionalState: {
        screenId: null,
      },
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchCategoriesTypes,
      payloadKey: 'categories',
      fetchingKey: 'isFetchingCategories',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountsTypes,
      payloadKey: 'accounts',
      fetchingKey: 'isFetchingAccounts',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchJurisdictionsTypes,
      payloadKey: 'allJurisdictions',
      fetchingKey: 'isFetchingAllJurisdictions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchGenericScreenDefinitionRowDetailsTypes,
      payloadKey: 'genericScreenDefinitionRowDetails',
      fetchingKey: 'isFetchingGenericScreenDefinitionRowDetails',
    }),
  },
});

export default genericScreenDefinitionReducer;
