import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { taxTypes } from '@common-packages/routes-definitions';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import { taxYearSelector, periodSelector } from '../../../shared/store/selectors';
import useFetchPropertySummary from '../../../taxSummaries/propertySummary/useFetchPropertySummary.hook';
import { appendEmptyRowsAfterEachSection } from '../utils';

import getColumnDefinitions from './propertySummary.columnDefinitions';

const PropertySummary = ({
  match,

  taxYear,
  period,
  orgId: entityId,
  jurisdictionId,
  isFetchingContext,
}) => {
  const { taxName } = match.params;
  const isContextReady = taxYear && period && jurisdictionId && entityId && taxName;

  const { propertySummary, isFetchingPropertySummary } = useFetchPropertySummary({
    taxYear,
    period,
    entityId,
    jurisdictionId,
    taxType: taxTypes[taxName],
    isContextReady,
  });

  const isLoading = isFetchingContext || isFetchingPropertySummary;

  const columnDefinitions = useMemo(() => getColumnDefinitions({ match }), [match]);
  const rowData = useMemo(
    () => appendEmptyRowsAfterEachSection(propertySummary, 'isLastRowInSection'),
    [propertySummary],
  );

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <div className="grid-borderless row grid-row">
      <div className="col">
        <AgGrid isGridLoading={isLoading} columnDefs={columnDefinitions} rowData={rowData} />
      </div>
    </div>
  );
};

PropertySummary.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      taxName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default connect(state => ({
  taxYear: taxYearSelector(state),
  period: periodSelector(state),
}))(PropertySummary);
