import React, { useMemo, useState, useEffect, useCallback } from 'react';
import AgGrid from '@tls/ui-aggrid';

import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import styles from '../../shared/reports/reportParam.module.scss';
import getReportUrl from '../shared/getReportUrl';
import { mapAllSelectedPossibilitiesToSingleValue } from '../utils';
import { defaultSideBarWithColumnsToolPanelSelectAll } from '../../shared/displayComponents/agGrid/constants';

import getColumnDefinitions from './highLevelPlanningData.columnDefinitions';

const REPORT_TYPE_VALUES = {
  ENTITY_ALL_STATES: 'ENTITY_ALL_STATES',
  MULTI_ENTITY_MULIT_STATE: 'MULTI_ENTITY_MULIT_STATE',
  FILING_GROUP_SINGLE_STATE: 'FILING_GROUP_SINGLE_STATE',
};

const REPORT_TYPE_OPTIONS = [
  { value: REPORT_TYPE_VALUES.ENTITY_ALL_STATES, label: 'Single-Entity, All States' },
  { value: REPORT_TYPE_VALUES.MULTI_ENTITY_MULIT_STATE, label: 'Multi-Entity, Multi-State' },
];

const FILTER_VALUES = {
  FILING_ENTITIES_ONLY: 'FILING_ENTITIES_ONLY',
  SHOW_PRIOR_YEAR_INFO: 'SHOW_PRIOR_YEAR_INFO',
  SUPPRESS_LOCALITIES: 'SUPPRESS_LOCALITIES',
};

const FILTER_OPTIONS = [
  { value: FILTER_VALUES.FILING_ENTITIES_ONLY, label: 'Filing Entities Only' },
  { value: FILTER_VALUES.SHOW_PRIOR_YEAR_INFO, label: 'Show Prior Year Info' },
  { value: FILTER_VALUES.SUPPRESS_LOCALITIES, label: 'Suppress Localities' },
];

const HighLevelPlanningData = () => {
  const endpointBasePath = getReportUrl('high-level-planning-data');

  const [reportType, setReportType] = useState(REPORT_TYPE_OPTIONS[0].value);
  const [selectedFilters, setSelectedFilters] = useState([
    FILTER_OPTIONS[0].value,
    FILTER_OPTIONS[2].value,
  ]);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const jurisdictionIdParam = useReportParam({
    endpointBasePath,
    paramName: 'jurisdictionId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  useEffect(() => {
    if (entityIdParam.paramData && entityIdParam.paramData.length) {
      entityIdParam.setParamValue(entityIdParam.paramData[0].value);
    }
  }, [entityIdParam.paramData]);

  const fieldParam = useReportParam({
    endpointBasePath,
    paramName: 'field',
    params: useMemo(() => ({}), []),
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([
    taxYearParam,
    periodParam,
    jurisdictionIdParam,
    entityIdParam,
    fieldParam,
  ]);

  const showPriorYear = useMemo(
    () => selectedFilters.some(filter => filter === FILTER_VALUES.SHOW_PRIOR_YEAR_INFO),
    [selectedFilters],
  );

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        orgId: mapAllSelectedPossibilitiesToSingleValue(
          entityIdParam.paramValue,
          entityIdParam.paramData,
        ),
        jurisdictionId: mapAllSelectedPossibilitiesToSingleValue(
          jurisdictionIdParam.paramValue,
          jurisdictionIdParam.paramData,
        ),
        suppressLocalsYN: selectedFilters.some(
          filter => filter === FILTER_VALUES.SUPPRESS_LOCALITIES,
        ),
        showPriorYear,
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        entityIdParam.paramValue,
        entityIdParam.paramData,
        jurisdictionIdParam.paramValue,
        jurisdictionIdParam.paramData,
        selectedFilters,
        showPriorYear,
      ],
    ),
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        fields: fieldParam.paramData || [],
        showPriorYear,
      }),
    [fieldParam.paramData, showPriorYear],
  );

  const { props, onGridReady, columnApi } = useActionMenuForExport({ reportData });

  const onFirstDataRendered = useCallback(() => {
    columnApi.autoSizeAllColumns(false);
  }, [columnApi]);

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown
            label="Report Type"
            param={useMemo(
              () => ({
                isFetchingParamData: false,
                paramData: REPORT_TYPE_OPTIONS,
                paramValue: reportType,
                setParamValue: setReportType,
              }),
              [reportType],
            )}
          />
          <ReportParamMultiSelect
            label="Filter"
            showSelectAll={false}
            param={useMemo(
              () => ({
                isFetchingParamData: false,
                paramData: FILTER_OPTIONS,
                paramValue: selectedFilters,
                setParamValue: setSelectedFilters,
              }),
              [selectedFilters],
            )}
          />
          {reportType === REPORT_TYPE_VALUES.ENTITY_ALL_STATES ? (
            <ReportParamDropdown label="Entity" param={entityIdParam} />
          ) : (
            <ReportParamMultiSelect label="Entity" param={entityIdParam} ignoreSelectAllThreshold />
          )}
          <ReportParamMultiSelect label="Jurisdiction" param={jurisdictionIdParam} />
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              headerHeight={60}
              onGridReady={onGridReady}
              sideBar={defaultSideBarWithColumnsToolPanelSelectAll}
              onFirstDataRendered={onFirstDataRendered}
              suppressColumnVirtualisation
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HighLevelPlanningData;
