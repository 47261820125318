import PropTypes from 'prop-types';

import { compileInfoData } from '../../propTypes';

export const compilationExpressionReportRowPropType = PropTypes.shape({
  jurisdictionName: PropTypes.string.isRequired,
  lastCompiled: PropTypes.string.isRequired,
  changedExpressions: PropTypes.string.isRequired,
  ...compileInfoData,
});
