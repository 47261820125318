import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useFetch from '../../shared/hooks/useFetch.hook';
import {
  globalContextSelector,
  isClientTtiAvailableSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';
import { clientIdSelector } from '../../shared/store/context';
import { invokeEFileSetupStepAction } from '../store/actions';
import { fetchEFileSetupStepsData as fetchEFileSetupStepsDataApiAction } from '../store/api';
import { useQueryTtiCaseId, useQueryTtiYearId } from '../../shared/queries/eFile';
import LoadingSpinner from '../../shared/forms/loadingSpinner/loadingSpinner.component';
import Loading from '../../shared/displayComponents/loading.component';
import styles from '../styles.module.scss';

import { getSetupStepData } from './getSetupStepData';
import StepSection from './stepSection.component';

const EFileSetup = () => {
  const dispatch = useDispatch();

  const clientId = useSelector(clientIdSelector);
  const globalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);
  const isClientTtiAvailable = useSelector(isClientTtiAvailableSelector);

  const [isSubmittingAnyAction, setSubmittingAnyAction] = useState(false);
  const [selectedTtiCaseId, selectTtiCaseId] = useState(null);

  const {
    params: { taxYear, period, jurisdictionId },
  } = globalContext;

  const { data: ttiCaseData, isFetching: isFetchingTtiCaseId } = useQueryTtiCaseId({
    params: { taxYear, jurisdictionId },
    enabled: Boolean(taxYear && jurisdictionId && isClientTtiAvailable),
  });

  const { data: ttiYearId, isFetching: isFetchingTtiYearId } = useQueryTtiYearId({
    params: { taxYear, period },
    enabled: Boolean(taxYear && period && isClientTtiAvailable),
  });

  const {
    fetch: fetchEFileSetupStepsData,
    isFetching: isFetchingEFileSetupStepsData,
    data: eFileSetupStepsData,
  } = useFetch({
    action: fetchEFileSetupStepsDataApiAction,
  });

  const ttiCaseName = ttiCaseData?.ttiCaseName || null;

  const contextDebugInfo = {
    globalContext: globalContext.params,
    clientId,
    caseId: selectedTtiCaseId,
    yearId: ttiYearId,
  };

  useEffect(() => {
    if (taxYear && jurisdictionId) {
      selectTtiCaseId(null);
    }
  }, [taxYear, jurisdictionId]);

  useEffect(() => {
    if (ttiCaseData) {
      selectTtiCaseId(ttiCaseData.ttiCaseId);
    }
  }, [ttiCaseData]);

  const fetchStepsData = useCallback(() => {
    if (globalContext.isReady) {
      fetchEFileSetupStepsData(globalContext.params);
    }
  }, [fetchEFileSetupStepsData, globalContext]);

  useEffect(() => {
    fetchStepsData();
  }, [fetchStepsData]);

  const callSetupStepAction = useCallback(
    async ({ action, additionalParams }) => {
      setSubmittingAnyAction(true);
      await dispatch(
        invokeEFileSetupStepAction({
          globalContext,
          action,
          ttiYearId,
          ttiCaseId: selectedTtiCaseId,
          additionalParams,
        }),
      );
      setSubmittingAnyAction(false);
      fetchStepsData();
    },
    [dispatch, fetchStepsData, globalContext, selectedTtiCaseId, ttiYearId],
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <LoadingSpinner
            isLoading={isFetchingGlobalContext || isFetchingTtiCaseId || isFetchingTtiYearId}
          />
          <div className={styles.whiteBox}>
            <div className={styles.caseIdField}>
              <label className="a-form-label">Filing Group</label>
              <Loading isLoading={isFetchingTtiCaseId} small>
                <input className="a-input-text" value={ttiCaseName || ''} disabled />
              </Loading>
            </div>
            <StepSection
              key="import-entity-permanent-information"
              action="import-entity-permanent-information"
              contextDebugInfo={contextDebugInfo}
              callAction={callSetupStepAction}
              disabled={isSubmittingAnyAction || !selectedTtiCaseId}
              isFetchingEFileSetupStepsData={isFetchingEFileSetupStepsData}
              selectedTtiCaseId={selectedTtiCaseId}
              stepData={getSetupStepData({
                eFileSetupStepsData,
                action: 'import-entity-permanent-information',
              })}
            />
            <StepSection
              key="import-entity-information"
              action="import-entity-information"
              contextDebugInfo={contextDebugInfo}
              callAction={callSetupStepAction}
              disabled={isSubmittingAnyAction || !selectedTtiCaseId}
              isFetchingEFileSetupStepsData={isFetchingEFileSetupStepsData}
              selectedTtiCaseId={selectedTtiCaseId}
              stepData={getSetupStepData({
                eFileSetupStepsData,
                action: 'import-entity-information',
              })}
            />
            <StepSection
              key="perform-setup-calculation-profile"
              action="perform-setup-calculation-profile"
              contextDebugInfo={contextDebugInfo}
              callAction={callSetupStepAction}
              disabled={isSubmittingAnyAction || !selectedTtiCaseId}
              isFetchingEFileSetupStepsData={isFetchingEFileSetupStepsData}
              selectedTtiCaseId={selectedTtiCaseId}
              stepData={getSetupStepData({
                eFileSetupStepsData,
                action: 'perform-setup-calculation-profile',
              })}
            />
            <StepSection
              key="import-consolidated-and-entity-group-definition"
              action="import-consolidated-and-entity-group-definition"
              contextDebugInfo={contextDebugInfo}
              callAction={callSetupStepAction}
              disabled={isSubmittingAnyAction || !selectedTtiCaseId}
              isFetchingEFileSetupStepsData={isFetchingEFileSetupStepsData}
              selectedTtiCaseId={selectedTtiCaseId}
              stepData={getSetupStepData({
                eFileSetupStepsData,
                action: 'import-consolidated-and-entity-group-definition',
              })}
              isGroupSelection
            />
            <StepSection
              key="import-state-filing-jurisdictions"
              action="import-state-filing-jurisdictions"
              contextDebugInfo={contextDebugInfo}
              callAction={callSetupStepAction}
              disabled={isSubmittingAnyAction || !selectedTtiCaseId}
              isFetchingEFileSetupStepsData={isFetchingEFileSetupStepsData}
              selectedTtiCaseId={selectedTtiCaseId}
              stepData={getSetupStepData({
                eFileSetupStepsData,
                action: 'import-state-filing-jurisdictions',
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EFileSetup;
