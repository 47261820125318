const AttributesGroups = {
  TAX_TYPE: 'Tax Type',
  RETURN_TYPE: 'Return Type',
  FILING_TYPE: 'Filing Type',
  BUSINESS_TYPE: 'Business Type',
  PERIOD_TYPE: 'Period Type',
};

const AttributesLabels = {
  FILING_TYPE_CONS_PRE_APP: 'Pre Apportionment',
  FILING_TYPE_CONS_POST_APP: 'Post Apportionment',
  PERIOD_TYPE_ANNUAL: 'Annual',
  PERIOD_TYPE_ESTIMATE1: 'Estimate - 1st Quarter',
  PERIOD_TYPE_ESTIMATE2: 'Estimate - 2st Quarter',
  PERIOD_TYPE_ESTIMATE3: 'Estimate - 3st Quarter',
  PERIOD_TYPE_ESTIMATE4: 'Estimate - 4st Quarter',
  PERIOD_TYPE_EXTENSION: 'Extension',
  PERIOD_TYPE_SECOND_EXTENSION: '2nd Extension',
  PERIOD_TYPE_AMENDED: 'Amended',
};

const DefaultPeriodTypes = {
  PERIOD_TYPE_ANNUAL: false,
  PERIOD_TYPE_ESTIMATE1: false,
  PERIOD_TYPE_ESTIMATE2: false,
  PERIOD_TYPE_ESTIMATE3: false,
  PERIOD_TYPE_ESTIMATE4: false,
  PERIOD_TYPE_EXTENSION: false,
  PERIOD_TYPE_SECOND_EXTENSION: false,
  PERIOD_TYPE_AMENDED: false,
  PERIOD_TYPE_RAR: false,
};

const Periods = {
  PERIOD_TYPE_ANNUAL: 0,
  PERIOD_TYPE_ESTIMATE1: 1,
  PERIOD_TYPE_ESTIMATE2: 2,
  PERIOD_TYPE_ESTIMATE3: 3,
  PERIOD_TYPE_ESTIMATE4: 4,
  PERIOD_TYPE_EXTENSION: 5,
  PERIOD_TYPE_SECOND_EXTENSION: 6,
  PERIOD_TYPE_AMENDED: 7,
  PERIOD_TYPE_RAR: 8,
};

const PeriodTypeFilingAttributesValues = {
  [Periods.PERIOD_TYPE_ANNUAL]: 262144,
  [Periods.PERIOD_TYPE_ESTIMATE1]: 524288,
  [Periods.PERIOD_TYPE_ESTIMATE2]: 1048576,
  [Periods.PERIOD_TYPE_ESTIMATE3]: 2097152,
  [Periods.PERIOD_TYPE_ESTIMATE4]: 4194304,
  [Periods.PERIOD_TYPE_EXTENSION]: 8388608,
  [Periods.PERIOD_TYPE_SECOND_EXTENSION]: 16777216,
  [Periods.PERIOD_TYPE_AMENDED]: 33554432,
  [Periods.PERIOD_TYPE_RAR]: 67108864,
};

const ConsolidationMethodFilingTypes = {
  PUSHBACK: 'FILING_TYPE_CONS_UNITARY',
  POST: 'FILING_TYPE_CONS_POST_APP',
  PRE: 'FILING_TYPE_CONS_PRE_APP',
};

const displayedAttributes = {
  RETURN_TYPE: 'RETURN_TYPE',
  FILING_TYPE: 'FILING_TYPE',
  BUSINESS_TYPE: 'BUSINESS_TYPE',
  TAX_TYPE: 'TAX_TYPE',
};

const displayNames = {
  [displayedAttributes.RETURN_TYPE]: 'Return Type',
  [displayedAttributes.FILING_TYPE]: 'Filing Type',
  [displayedAttributes.BUSINESS_TYPE]: 'Business Type',
  [displayedAttributes.TAX_TYPE]: 'Tax Type',
  PERIOD_TYPE: 'Period Type',
};

// For now after SLT-6426 only for Filing Decisions screen we're using functionality to provide only
// one value instead of multiple values for Business Type and Return Type
const singleValueAttributes = [displayedAttributes.RETURN_TYPE, displayedAttributes.BUSINESS_TYPE];

const BUSINESS_TYPES = [
  'BUSINESS_TYPE_GENERAL',
  'BUSINESS_TYPE_FINANCIAL',
  'BUSINESS_TYPE_INSURANCE',
  'BUSINESS_TYPE_OIL_AND_GAS',
  'BUSINESS_TYPE_REGULATED_EXCHANGE',
  'BUSINESS_TYPE_TRANSPORTATION',
];

const RETURN_TYPES = [
  'RETURN_TYPE_1120',
  'RETURN_TYPE_1065',
  'RETURN_TYPE_DRE',
  'RETURN_TYPE_LLC',
  'RETURN_TYPE_SMLLC',
  'RETURN_TYPE_1120PC',
  'RETURN_TYPE_1120L',
];

const FILING_TYPES = [
  'FILING_TYPE_SEPARATE',
  'FILING_TYPE_CONS_POST_APP',
  'FILING_TYPE_CONS_PRE_APP',
  'FILING_TYPE_CONS_UNITARY',
];

module.exports = {
  AttributesGroups,
  AttributesLabels,
  DefaultPeriodTypes,
  ConsolidationMethodFilingTypes,
  displayedAttributes,
  displayNames,
  singleValueAttributes,
  PeriodTypeFilingAttributesValues,
  BUSINESS_TYPES,
  RETURN_TYPES,
  FILING_TYPES,
};
