import {
  httpGetAndParse,
  httpPostFile,
  httpPutFile,
  httpDelete,
} from '../../../utils/fetchService';
import { preparePdfFormDataWithJsonPayload } from '../../../utils/preparePdfFormDataWithJsonPayload';

export const fetchTaxForms = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/development/tax-forms/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching tax forms failed',
  });

export const addTaxForm = ({ formValues, pdfLibPdfFile, bnaPdfFile }) => {
  // slt-6186 removed preparePdfFormDataWithJsonPayload out of api.js
  // but it's being left here since these old taxForms will be removed in the future
  const formData = preparePdfFormDataWithJsonPayload({
    formValues,
    pdfLibPdfFile,
    bnaPdfFile,
  });

  return httpPostFile({
    route: `/api/development/tax-forms`,
    errorMessage: 'Adding tax form failed',
    body: formData,
  });
};

export const updateTaxForm = ({ formValues, pdfLibPdfFile, bnaPdfFile }) => {
  // slt-6186 removed preparePdfFormDataWithJsonPayload out of api.js
  // but it's being left here since these old taxForms will be removed in the future
  const formData = preparePdfFormDataWithJsonPayload({
    formValues,
    pdfLibPdfFile,
    bnaPdfFile,
  });

  return httpPutFile({
    route: `/api/development/tax-forms`,
    errorMessage: 'Updating tax form failed',
    body: formData,
  });
};

export const deleteForm = ({ taxYear, jurisdictionId, formId, profileId }) =>
  httpDelete({
    route: `/api/development/tax-forms/${taxYear}/${jurisdictionId}/${formId}/${profileId}`,
    errorMessage: 'Deleting form failed',
  });
