export const fetchingNotesToReviewSelector = ({ entity }) =>
  entity.reviewerNotes.isFetchingNotesToReview;

export const notesToReviewSelector = ({ entity }) => entity.reviewerNotes.notesToReview;

export const jurisdictionsSelector = ({ entity }) => entity.reviewerNotes.jurisdictions;

export const tasksSelector = ({ entity }) => entity.reviewerNotes.tasks;

export const statusesSelector = ({ entity }) => entity.reviewerNotes.statuses;

export const isFetchingJurisdictionsSelector = ({ entity }) =>
  entity.reviewerNotes.isFetchingJurisdictions;

export const isFetchingTasksSelector = ({ entity }) => entity.reviewerNotes.isFetchingTasks;

export const isFetchingStatusesSelector = ({ entity }) => entity.reviewerNotes.isFetchingStatuses;
