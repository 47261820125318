import { createSelector } from 'reselect';

export const constantsSelector = createSelector(
  [({ shared }) => shared.calculationConstants.constants.data],
  constants =>
    constants.map(constant => ({
      ...constant,
      value: constant.value.toString(),
    })),
);

export const constantsColumnsBlueprintSelector = ({ shared }) =>
  shared.calculationConstants.constants.columnsBlueprint;

export const isFetchingConstantsSelector = ({ shared }) =>
  shared.calculationConstants.isFetchingConstants;

export const constantIdExistsSelector = ({ shared }) =>
  shared.calculationConstants.constants.data.constantIdExists;

export const constantIdsSelector = createSelector(
  [({ shared }) => shared.calculationConstants.constants.data],
  constants => constants.map(constant => constant.constantId),
);

export const constantTypeSelector = ({ shared }) => shared.calculationConstants.constantType;

export const constantsPeriodSelector = ({ shared }) =>
  Number.isInteger(shared.calculationConstants.constantsPeriod)
    ? shared.calculationConstants.constantsPeriod.toString()
    : null;
