export const defaultSideBarWithColumnsToolPanel = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
  ],
};

export const defaultSideBarWithColumnsToolPanelSelectAll = {
  toolPanels: [
    {
      ...defaultSideBarWithColumnsToolPanel.toolPanels[0],
      toolPanelParams: {
        ...defaultSideBarWithColumnsToolPanel.toolPanels[0].toolPanelParams,
        suppressColumnSelectAll: false,
      },
    },
  ],
};

export const gridPivotOptions = {
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
    defaultToolPanel: '',
  },
  pivotPanelShow: 'always',
  rowGroupPanelShow: 'always',
  enableRangeSelection: true,
  enableCharts: true,
  groupIncludeFooter: true,
  autoGroupColumnDef: {
    cellRendererParams: {
      suppressCount: true,
    },
  },
  suppressAggFuncInHeader: true,
};

export const gridGroupOptions = {
  groupDefaultExpanded: true,
  rowGroupPanelShow: 'never',
  enableGroupEdit: true,
};

export const EXCEL_TEXT_FORMAT = 'excelTextFormat';
export const EXCEL_NUMBER_FORMAT = 'excelNumberFormat';

export const excelStyles = [
  {
    id: EXCEL_TEXT_FORMAT,
    dataType: 'string',
  },
  {
    id: EXCEL_NUMBER_FORMAT,
    dataType: 'number',
  },
];
