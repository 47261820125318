import { createSelector } from 'reselect';

export const isFetchingGenericCategorySelector = ({ shared }) =>
  shared.genericCategory.isFetchingGenericCategory;
export const isUpdatingGenericCategorySelector = ({ shared }) =>
  shared.genericCategory.isUpdatingGenericCategory;
export const genericCategorySelector = createSelector(
  [({ shared }) => shared.genericCategory.genericCategory],
  genericCategory =>
    genericCategory.map((genericCategoryAccount, rowId) => ({
      ...genericCategoryAccount,
      rowId,
    })),
);
export const isAnyGenericSubcategorySelector = ({ shared }) =>
  shared.genericCategory.genericCategory.some(category => Boolean(category.subCategory));
