import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../shared/store/storeHelpers';

export const flowThroughK1DetailsSelector = createSelector(
  [({ flowThroughK1Details }) => flowThroughK1Details.flowThroughK1Details],
  flowThroughK1Details => flowThroughK1Details.map((detail, rowId) => ({ rowId, ...detail })),
);
export const isFetchingFlowThroughK1DetailsSelector = ({ flowThroughK1Details }) =>
  flowThroughK1Details.isFetchingFlowThroughK1Details;

export const partnersSelector = ({ flowThroughK1Details }) => flowThroughK1Details.partners;

export const isFetchingPartnersSelector = ({ flowThroughK1Details }) =>
  flowThroughK1Details.isFetchingPartners;

export const partnersOptionsSelector = optionsSelectorFactory({
  listSelector: partnersSelector,
  labelKey: 'partnerId',
  valueKey: 'partnerId',
});

export const partnerIdSelector = ({ flowThroughK1Details }) => flowThroughK1Details.partnerId;

export const isUpdatingFlowThroughK1DetailsSelector = ({ flowThroughK1Details }) =>
  flowThroughK1Details.isUpdatingFlowThroughK1Details;

export const isAnyK1DetailsSubcategorySelector = ({ flowThroughK1Details }) =>
  flowThroughK1Details.flowThroughK1Details.some(detail => Boolean(detail.subCategory));
