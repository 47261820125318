import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';

import dependentParamsAreSet from '../../../shared/reports/utils/dependentParamsAreSet';
import AppkitIcon from '../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import { ParamTypeValue } from '../../../shared/reports/enums';

import DropdownField from './dropdownField.container';

class ParamField extends PureComponent {
  render() {
    const {
      param,
      dependentParams,
      value,
      handleChange,
      showEditParamModal,
      getParamFieldName,
    } = this.props;

    const paramFieldName = getParamFieldName(param.name);
    const label = `${param.mappingName} (${[param.name]})`;

    const editIconClasses = classNames('edit-field-icon', {
      disabled: !dependentParamsAreSet(dependentParams),
    });

    const editIcon = (
      <AppkitIcon
        className={editIconClasses}
        icon="edit"
        size={12}
        onClick={showEditParamModal(param.name)}
      />
    );

    return (
      <div className="mb-3">
        {param.type === ParamTypeValue.INPUT ? (
          <Field
            label={label}
            name={paramFieldName}
            component={Input}
            autoComplete="off"
            addonBefore={editIcon}
          />
        ) : (
          <DropdownField
            param={param}
            dependentParams={dependentParams}
            value={value}
            handleChange={handleChange}
            editIcon={editIcon}
            getParamFieldName={getParamFieldName}
          />
        )}
      </div>
    );
  }
}

ParamField.propTypes = {
  param: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mappingName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  showEditParamModal: PropTypes.func.isRequired,
  getParamFieldName: PropTypes.func.isRequired,
  dependentParams: PropTypes.object, // eslint-disable-line
};

export default ParamField;
