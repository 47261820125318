import React, { useEffect, useCallback, useMemo } from 'react';
import { Select as MultiSelect, SelectGroupTitle, SelectOption } from '@pwc/appkit-react';

import Loading from '../../../../shared/displayComponents/loading.component';
import SDKCustomSelect from '../../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useQueryRunParamQuery } from '../../../../shared/queries/customReports';

interface DropdownFieldProps {
  reportId: string;
  name: string;
  label: string;
  value: string;
  handleChange: (name: string, value: string) => void;
  isMultiSelectDropdown: boolean;
  defaultValue?: string;
  dependentParams?: { [key: string]: string };
}

const DropdownField = ({
  reportId,
  name,
  label,
  value,
  handleChange,
  isMultiSelectDropdown,
  defaultValue,
  dependentParams,
}: DropdownFieldProps) => {
  const { data: options = [], isFetching: isFetchingOptions } = useQueryRunParamQuery({
    params: { reportId, paramName: name, params: dependentParams },
    enabled: true,
  });

  const onChange = useCallback(
    ({ value }: { value: string | null }) => handleChange(name, value || ''),
    [handleChange, name],
  );

  const uniqueParams = useMemo(
    () =>
      options.filter(
        (param, index, self) =>
          index ===
          self.findIndex(
            paramToFind => paramToFind.value === param.value && paramToFind.label === param.label,
          ),
      ),
    [options],
  );

  const onMultiSelect = useCallback(
    (value: string) => {
      handleChange(name, value);
    },
    [handleChange, name],
  );

  const handleSelectChange = useCallback(
    ({ value }: { value: string }) => {
      handleChange(name, value);
    },
    [handleChange, name],
  );

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    const stringDefaultValue = `${defaultValue}`;
    if (options.find(({ value }) => value === stringDefaultValue)) {
      handleSelectChange({ value: stringDefaultValue });
    }
  }, [defaultValue, handleSelectChange, options]);

  return (
    <div className="global-context-dropdown">
      <Loading isLoading={isFetchingOptions}>
        <div className="global-context-dropdown-wrapper">
          {isMultiSelectDropdown ? (
            <>
              <label className="a-form-label">{label}</label>
              <MultiSelect
                placeholder="Select..."
                className="global-context-select sdk-custom-select custom-report-param-select"
                name={name}
                multiple
                showSelectAll
                onSelect={onMultiSelect}
                value={value}
                showSearchOnList
              >
                <SelectGroupTitle>{label}</SelectGroupTitle>
                {uniqueParams.map(option => (
                  <SelectOption key={option.value} value={option.value}>
                    {option.label}
                  </SelectOption>
                ))}
              </MultiSelect>
            </>
          ) : (
            <SDKCustomSelect
              appkitLabel={label}
              className="global-context-select sdk-custom-select custom-report-param-select"
              options={options}
              onChange={onChange}
              value={value}
              virtualized
            />
          )}
        </div>
      </Loading>
    </div>
  );
};

export default DropdownField;
