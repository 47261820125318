import React from 'react';

import styles from './helpIcon.module.scss';

const HelpIconComponent = () => (
  <span className={styles.helpIcon}>
    <span className="a-icon-container" id="globalHelpIcon">
      <i className="appkiticon icon-help-question-outline" />
    </span>
  </span>
);

export default HelpIconComponent;
