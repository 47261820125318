import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './gridHeader.module.scss';

const GridHeader = ({ displayName, dataItemName, progressSort, column }) => {
  const [sortingOption, setSortingOption] = useState(null);
  const changeSortingOption = useCallback(() => {
    const multiSort = false;
    progressSort(multiSort);
  }, [progressSort]);

  useEffect(() => {
    setSortingOption(column.getSort());

    const sortChangeListener = () => {
      setSortingOption(column.getSort());
    };
    column.addEventListener('sortChanged', sortChangeListener);

    return () => {
      column.removeEventListener('sortChanged', sortChangeListener);
    };
  }, [column]);

  return (
    <div onClick={changeSortingOption} className={styles.gridHeader}>
      <div className={styles.gridHeaderLabels}>
        <span title={`Display Name: ${displayName}`} className={styles.displayText}>
          {displayName}
        </span>
        <span title={`DataItem Name: ${dataItemName}`} className={styles.dataItemNameText}>
          {dataItemName}
        </span>
      </div>
      {sortingOption && <span className={`ag-icon ag-icon-${sortingOption}`} />}
    </div>
  );
};

GridHeader.propTypes = {
  displayName: PropTypes.string.isRequired,
  dataItemName: PropTypes.string.isRequired,
  progressSort: PropTypes.func.isRequired,
  column: PropTypes.shape({
    addEventListener: PropTypes.func.isRequired,
    removeEventListener: PropTypes.func.isRequired,
    getSort: PropTypes.func.isRequired,
  }).isRequired,
};

export default GridHeader;
