import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useFetch from '../../../../shared/hooks/useFetch.hook';
import { defaultSideBarWithColumnsToolPanel } from '../../../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import ParamDropdown from '../../../../shared/displayComponents/paramDropdown/paramDropdown.component';
import {
  CalcSpecTypes as CategoryTypes, // renaming because in this context they serve as category types
  FunctionNames,
  AgGridIds,
} from '../constants';
import headerStyles from '../../../../shared/displayComponents/headerWithParamDropdowns/styles.module.scss';
import { fetchCategories as fetchCategoriesApiAction } from '../../store/api';
import { taxYearSelector } from '../../../store/selectors';

import styles from './calculationCategories.module.scss';
import columnDefinitions from './calculationCategories.columnDefinitions';

const calcSpecTypeOptions = Object.values(CategoryTypes).map(value => ({ label: value, value }));

const CalculationCategories = ({
  addFunctionSepExpression,
  addFunctionConsolExpression,
  generateContextMenuItems,
}) => {
  const taxYear = useSelector(taxYearSelector);

  const [calcSpecType, setCalcSpecType] = useState(CategoryTypes.BASE);

  const { data: categories, fetch: fetchCategories, isFetching: isFetchingCategories } = useFetch({
    action: fetchCategoriesApiAction,
  });

  useEffect(() => {
    if (taxYear) {
      fetchCategories({ taxYear });
    }
  }, [fetchCategories, taxYear]);

  const onCategorySelect = useCallback(
    ({ data }) => {
      if (data && calcSpecType) {
        addFunctionSepExpression({
          functionName: FunctionNames.GET_CATEGORY,
          paramsArray: [data.columnName, calcSpecType],
        });
      }
    },
    [addFunctionSepExpression, calcSpecType],
  );

  const onConsolExpressionSelect = useCallback(
    ({ data, event }) => {
      if (data && calcSpecType && (event.metaKey || event.ctrlKey)) {
        addFunctionConsolExpression({
          functionName: FunctionNames.GET_CATEGORY,
          paramsArray: [data.columnName, calcSpecType],
        });
      }
    },
    [addFunctionConsolExpression, calcSpecType],
  );

  const contextMenuItems = useMemo(
    () =>
      generateContextMenuItems(
        ({ data }) => {
          addFunctionSepExpression({
            functionName: FunctionNames.GET_CATEGORY,
            paramsArray: [data.columnName, calcSpecType],
          });
        },
        ({ data }) => {
          addFunctionConsolExpression({
            functionName: FunctionNames.GET_CATEGORY,
            paramsArray: [data.columnName, calcSpecType],
          });
        },
      ),
    [generateContextMenuItems, addFunctionSepExpression, addFunctionConsolExpression, calcSpecType],
  );

  return (
    <div className={styles.categoriesContainer}>
      <div className={headerStyles.flexSpaceBetween}>
        <div className={headerStyles.headerParam}>
          <ParamDropdown
            label="Category Type"
            options={calcSpecTypeOptions}
            value={calcSpecType}
            handleChange={setCalcSpecType}
          />
        </div>
      </div>
      <div className={`row grid-row ${styles.gridContainer}`}>
        <div className="col">
          <AgGrid
            rowData={categories}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingCategories}
            onCellClicked={onConsolExpressionSelect}
            onCellDoubleClicked={onCategorySelect}
            withSearchBar
            sideBar={defaultSideBarWithColumnsToolPanel}
            areHeaderCellBordersEnabled
            getContextMenuItems={contextMenuItems}
            saveGridStateId={AgGridIds.CATEGORIES}
          />
        </div>
      </div>
    </div>
  );
};

CalculationCategories.propTypes = {
  addFunctionSepExpression: PropTypes.func.isRequired,
  addFunctionConsolExpression: PropTypes.func.isRequired,
  generateContextMenuItems: PropTypes.func.isRequired,
};

export default CalculationCategories;
