import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import Modal from '@tls/ui-modal';

import Input from '../shared/forms/inputFormik/inputFormik.component';
import ButtonsGroup from '../shared/forms/buttonsGroup/buttonsGroup.component';

const formInitialValues = {
  name: '',
};

const validateNameUniqueness = existingBindersNames => ({ name }) => {
  const isNameConflict = existingBindersNames.some(existingName => existingName === name);

  if (isNameConflict) {
    return {
      name: 'Binder name must be unique',
    };
  }

  if (!name) {
    return {
      name: 'Binder name is required',
    };
  }
  return {};
};

const CopyBinderFormContainer = ({
  onCopyBinder,
  existingBindersNames,

  hideModal,
  visible,
}) => {
  const copyBinder = useCallback(
    ({ name }) => {
      onCopyBinder(name);
      hideModal();
    },
    [hideModal, onCopyBinder],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        title={'Enter a new binder name and select Save to copy the existing binder'}
        closeAction={hideModal}
        visible={visible}
        submitAction={formikProps.submitForm}
        dismissAction={hideModal}
      >
        <form>
          <div>
            <Field
              className="form-text"
              label="Binder name"
              name="name"
              component={Input}
              value={formikProps.values.name}
              maxLength={200}
            />
            <div>
              <ButtonsGroup
                isSubmitting={formikProps.isSubmitting}
                submitForm={formikProps.submitForm}
                onCancelClick={hideModal}
                submitButtonLabel="Save"
              />
            </div>
          </div>
        </form>
      </Modal>
    ),
    [hideModal, visible],
  );
  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validate={validateNameUniqueness(existingBindersNames)}
        enableReinitialize
        validateOnBlur={false}
        onSubmit={copyBinder}
      >
        {renderForm}
      </Formik>
    </>
  );
};

CopyBinderFormContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onCopyBinder: PropTypes.func.isRequired,
  existingBindersNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CopyBinderFormContainer;
