import {
  GenericScreenRowTypes,
  GenericScreenTextTypes,
  GenericScreenDataTypes,
  GenericScreenDrilldownTypes,
} from '@common-packages/shared-constants';

const sharedState = {
  dataType: {
    value: null,
    disabled: true,
  },
  categoryId: {
    value: null,
    disabled: true,
  },
  accountId: {
    value: null,
    disabled: true,
  },
  drilldownType: {
    value: GenericScreenDrilldownTypes.NONE,
    disabled: false,
  },
};

const emptyAndDisabledFields = {
  ...sharedState,
  text: {
    value: null,
    disabled: true,
  },
  textType: {
    value: null,
    disabled: true,
  },
  percentage: {
    value: false,
    disabled: true,
  },
  commas: {
    value: false,
    disabled: true,
  },
  showAdj: {
    value: false,
    disabled: true,
  },
  borders: {
    value: false,
    disabled: true,
  },
  decimalPlaces: {
    value: null,
    disabled: true,
  },
};

export const defaultHeaderTypeState = {
  rowType: {
    value: GenericScreenRowTypes.HEADER,
  },
  ...sharedState,
  text: {
    value: null,
  },
  textType: {
    value: GenericScreenTextTypes.TITLE,
  },
  percentage: {
    value: false,
    disabled: true,
  },
  commas: {
    value: false,
    disabled: true,
  },
  showAdj: {
    value: false,
    disabled: true,
  },
  borders: {
    value: false,
  },
  decimalPlaces: {
    value: null,
    disabled: true,
  },
};

export const defaultTextTypeState = {
  rowType: {
    value: GenericScreenRowTypes.TEXT,
  },
  ...sharedState,
  text: {
    value: null,
  },
  textType: {
    value: GenericScreenTextTypes.STANDARD,
  },
  percentage: {
    value: false,
  },
  commas: {
    value: false,
  },
  showAdj: {
    value: false,
    disabled: true,
  },
  borders: {
    value: false,
  },
  decimalPlaces: {
    value: null,
  },
};

export const defaultBlankTypeState = {
  rowType: {
    value: GenericScreenRowTypes.BLANK,
  },
  ...sharedState,
  ...emptyAndDisabledFields,
  borders: {
    disabled: false,
    value: false,
  },
};

export const defaultLineTypeState = {
  rowType: {
    value: GenericScreenRowTypes.LINE,
  },
  ...sharedState,
  ...emptyAndDisabledFields,
};

export const defaultDataTypeState = {
  rowType: {
    value: GenericScreenRowTypes.DATA,
  },
  ...sharedState,
  dataType: {
    value: GenericScreenDataTypes.ACCOUNT,
  },
  categoryId: {
    value: null,
  },
  accountId: {
    value: null,
  },
  text: {
    value: null,
  },
  textType: {
    value: GenericScreenTextTypes.STANDARD,
  },
  percentage: {
    value: false,
  },
  commas: {
    value: true,
  },
  showAdj: {
    value: true,
  },
  borders: {
    value: false,
  },
  decimalPlaces: {
    value: '0',
  },
};
