import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchGenericScreenDefinitionTypes = getApiActionTypes(
  'DEVELOPMENT/FETCH_GENERIC_SCREEN_DEFINITION',
);
export const deleteGenericScreenDefinitionTypes = getApiActionTypes(
  'DEVELOPMENT/DELETE_GENERIC_SCREEN',
);
export const fetchAccountsTypes = getApiActionTypes('DEVELOPMENT/FETCH_ACCOUNTS');
export const fetchCategoriesTypes = getApiActionTypes('DEVELOPMENT/FETCH_CATEGORIES');
export const fetchJurisdictionsTypes = getApiActionTypes('DEVELOPMENT/FETCH_JURISDICTIONS');
export const createGenericScreenDefinitionTypes = getApiActionTypes(
  'DEVELOPMENT/CREATE_GENERIC_SCREEN_DEFINITION',
);
export const updateGenericScreenDefinitionTypes = getApiActionTypes(
  'DEVELOPMENT/UPDATE_GENERIC_SCREEN_DEFINITION',
);
export const fetchGenericScreenDefinitionRowDetailsTypes = getApiActionTypes(
  'DEVELOPMENT/FETCH_GENERIC_SCREEN_DEFINITION_ROW_DETAILS',
);
export const SELECT_SCREEN_ID = 'DEVELOPMENT/SELECT_SCREEN_ID';
export const createGenericScreenDefinitionRowTypes = getApiActionTypes(
  'DEVELOPMENT/CREATE_GENERIC_SCREEN_DEFINITION_ROW',
);
