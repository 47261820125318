import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';
import { isTaxYearAfterOr2021 } from '@common-packages/utils';

import { useQueryReturnStatusTrackerDetails } from '../shared/queries/returnStatusTrackerDetails';
import { globalContextSelector } from '../shared/store/selectors';
import Loading from '../shared/displayComponents/loading.component';
import { GlobalContext } from '../../../common/types';

import Header from './header/returnStatusTrackerDetailsHeader.component';
import StatusVisuals from './statusVisuals/returnStatusTrackerDetailsStatusVisuals.component';
import DataGrid from './dataGrid/returnStatusTrackerDetailsDataGrid.component';
import styles from './returnStatusTrackerDetails.module.scss';

export const ReturnStatusTrackerDetails = () => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const { taxYear, period } = globalContext;
  const history = useHistory();

  useEffect(() => {
    if (taxYear && !isTaxYearAfterOr2021(taxYear)) {
      history.push(Routes.root.MAIN);
    }
  }, [history, taxYear]);

  const {
    data: trackerDetailsData = { gridRows: [], visualData: [] },
    isLoading,
  } = useQueryReturnStatusTrackerDetails({
    params: { taxYear: taxYear || '', period: period || '' },
    enabled: Boolean(taxYear && period),
  });

  return (
    <div className={`card ${styles.returnStatusTrackerDetailsCard}`}>
      <Loading isLoading={isLoading}>
        <Header />
        <StatusVisuals data={trackerDetailsData} />
        <DataGrid data={trackerDetailsData.gridRows} />
      </Loading>
    </div>
  );
};
