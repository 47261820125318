import React, { useCallback } from 'react';
import { Button } from '@pwc/appkit-react';

import SharedHeader from '../../../shared/displayComponents/header/header.component';
import useModal from '../../../shared/hooks/useModal.hook';
import AddDefaultFormsModal from '../../../shared/displayComponents/modals/addDefaultFormsModal.component';
import DeleteBranchModal from '../../../shared/displayComponents/modals/deleteBranchModal.component';

import styles from './utilities.module.scss';

const Utilities = () => {
  const { showModal: showAddDefaultFormsModal, modalProps: addDefaultFormsModalProps } = useModal();
  const { showModal: showDeleteBranchModal, modalProps: deleteBranchModalProps } = useModal();

  const openModalForAddForm = useCallback(() => {
    showAddDefaultFormsModal();
  }, [showAddDefaultFormsModal]);

  const openDeleteBranchModal = useCallback(() => {
    showDeleteBranchModal();
  }, [showDeleteBranchModal]);

  return (
    <>
      <SharedHeader title="Utilities" />
      <div className={`${styles.utilitiesWrapper} card`}>
        <div className={styles.utilitiesReturnsWrapper}>
          <span role="heading" aria-level={5}>
            Add Default Forms to Empty Returns
          </span>
          <Button
            size="lg"
            className={styles.btnDefaultWidth}
            kind="secondary"
            onClick={openModalForAddForm}
          >
            Add Forms
          </Button>
        </div>
        <AddDefaultFormsModal {...addDefaultFormsModalProps} />
        <div className={styles.utilitiesReturnsWrapper}>
          <span role="heading" aria-level={5}>
            Delete Branch Entity Returns
          </span>
          <Button
            size="lg"
            className={styles.btnDefaultWidth}
            kind="secondary"
            onClick={openDeleteBranchModal}
          >
            Delete Returns
          </Button>
        </div>
        <DeleteBranchModal {...deleteBranchModalProps} />
      </div>
    </>
  );
};

export default Utilities;
