import { createSelector } from 'reselect';

import { isFetchingGlobalContextSelector } from '../../shared/store/selectors';
import { optionsSelectorFactory } from '../../shared/store/storeHelpers';

export const stateFilingGroupsSelector = ({ stateFilingGroups }) =>
  stateFilingGroups.shared.filingGroups;
export const stateFilingGroupsByFilingTypeSelector = ({ stateFilingGroups }) =>
  stateFilingGroups.shared.filingGroupsByFilingType;
export const isFetchingFilingGroupsSelector = ({ stateFilingGroups }) =>
  stateFilingGroups.shared.isFetchingFilingGroups;
export const filingGroupIdSelector = ({ stateFilingGroups }) =>
  stateFilingGroups.shared.filingGroupId;

export const filingGroupSelector = createSelector(
  [stateFilingGroupsSelector, filingGroupIdSelector],
  (filingGroups, filingGroupId) =>
    filingGroups.find(filingGroup => filingGroup.filingGroupId === filingGroupId) || null,
);

export const filingGroupNameSelector = createSelector([filingGroupSelector], filingGroup =>
  filingGroup ? filingGroup.description : null,
);

export const jurisdictionIdForFilingGroupSelector = createSelector(
  [filingGroupSelector],
  filingGroup => (filingGroup ? filingGroup.jurisdictionId : null),
);

export const filingGroupsByJurisdictionIdSelector = createSelector(
  [stateFilingGroupsSelector, jurisdictionIdForFilingGroupSelector],
  (filingGroups, jurisdictionId) =>
    filingGroups.filter(filingGroup => filingGroup.jurisdictionId === jurisdictionId),
);

export const filingGroupsOptionsSelector = createSelector(
  [filingGroupsByJurisdictionIdSelector],
  filingGroups =>
    filingGroups.map(({ filingGroup, filingGroupId }) => ({
      label: filingGroup,
      value: filingGroupId,
    })),
);

export const jurisdictionsAsOptionsSelector = createSelector(
  [stateFilingGroupsSelector],
  filingGroups => {
    const distinctJurisdictions = Object.values(
      filingGroups.reduce(
        (acc, filingGroup) => ({
          ...acc,
          [filingGroup.jurisdictionId]: filingGroup,
        }),
        {},
      ),
    );

    return distinctJurisdictions.map(({ jurisdictionId, jurisdiction }) => ({
      label: jurisdiction,
      value: jurisdictionId,
    }));
  },
);

export const jurisdictionDescriptionSelector = createSelector([filingGroupSelector], filingGroup =>
  filingGroup ? filingGroup.jurisdiction : null,
);

export const isFetchingContextSelector = createSelector(
  [isFetchingFilingGroupsSelector, isFetchingGlobalContextSelector],
  (isFetchingFilingGroups, isFetchingGlobalContext) =>
    isFetchingFilingGroups || isFetchingGlobalContext,
);

export const jurisdictionForFilingGroupsOptionsSelector = optionsSelectorFactory({
  listSelector: ({ stateFilingGroups }) => stateFilingGroups.shared.jurisdictionsForFilingGroups,
  labelKey: 'jurisdictionDescription',
  valueKey: 'jurisdictionId',
});

export const isFetchingJurisdictionsForFilingGroupsSelector = ({ stateFilingGroups }) =>
  stateFilingGroups.shared.isFetchingJurisdictionsForFilingGroups;
