import { createSelector } from 'reselect';

import { taxYearSelector, jurisdictionIdSelector } from '../../store/selectors';
import { pollingEnabledJobsSelector } from '../../../jobs/store/selectors';
import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';
import oracleSysGuidUuid from '../../../utils/oracleSysGuidUuid';

export const taxFormsSelector = ({ development }) => development.taxForms.taxForms.data;
export const taxFormsColumnsBlueprintSelector = ({ development }) =>
  development.taxForms.taxForms.columnsBlueprint;
export const isFetchingTaxFormsSelector = ({ development }) =>
  development.taxForms.isFetchingTaxForms;
export const isAddingOrEditingTaxFormSelector = ({ development }) =>
  development.taxForms.isAddingOrEditingTaxForm;

export const taxFormsListOptionsSelector = optionsSelectorFactory({
  listSelector: taxFormsSelector,
  labelKey: 'formName',
  valueKey: 'formId',
});

export const filingAttributesSelector = ({ development }) =>
  development.taxForms.taxForms.columnsBlueprint.filter(column => column.filingAttribute);

export const formIdSelector = ({ development }) => development.taxForms.formId;

export const isAddingFormSelector = ({ development }) => development.taxForms.isAddingForm;

const mapRowDataToForm = rowData => ({
  ...rowData,
  pdfLibPdfFileName: rowData.pdfLibPdf,
  bnaPdfFileName: rowData.bnaPdf,
  existingPdfLibPdfName: rowData.pdfLibPdf,
  existingBnaPdfName: rowData.bnaPdf,
});

export const taxFormSelector = createSelector(
  [
    taxFormsSelector,
    formIdSelector,
    isAddingFormSelector,
    filingAttributesSelector,
    taxYearSelector,
    jurisdictionIdSelector,
    pollingEnabledJobsSelector,
  ],
  (
    taxForms,
    selectedFormId,
    isAddingForm,
    filingAttributes,
    taxYear,
    jurisdictionId,
    pollingEnabledJobs,
  ) => {
    if (taxYear && jurisdictionId && filingAttributes.length && (selectedFormId || isAddingForm)) {
      const selectedForm = taxForms.find(({ formId }) => formId === selectedFormId);
      return mapRowDataToForm({
        ...((selectedForm && {
          ...selectedForm,
          isPollingGeneratePdfJob:
            selectedForm.jobId && pollingEnabledJobs.some(jobId => jobId === selectedForm.jobId),
        }) || {
          formId: oracleSysGuidUuid(),
          formName: '',
          formDescription: '',
          pdfLibPdfFileName: '',
          bnaPdfFileName: '',
          existingPdfLibPdfName: null,
          existingBnaPdfName: null,
          pdfLibPdfFile: null,
          bnaPdfFile: null,
          swpFormId: '',
          swpCalcIteration: '6',
          ...filingAttributes.reduce((acc, { children }) => {
            const filingAttributeItems = children.reduce((acc, curr) => {
              acc[curr.field] = false;
              return acc;
            }, {});
            return { ...acc, ...filingAttributeItems };
          }, {}),
        }),
        taxYear,
        jurisdictionId,
      });
    }
    return null;
  },
);
