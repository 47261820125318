import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';

import {
  fetchProfilesOptionsTypes,
  fetchEntityFilingDecisionsTypes,
  updateEntityFilingDecisionsTypes,
  fetchConsolidationFilingGroupsOptionsTypes,
  fetchConsolidationFilingDecisionsTypes,
  updateConsolidationFilingDecisionsTypes,
  fetchFilingDecisionsAmountDetailsTypes,
  CLEAR_NEXUS_NOTE,
  fetchNexusNoteTypes,
} from './types';

const filingDecisionsReducer = reducerFactory({
  initialState: {
    profileOptions: [],
    isFetchingProfileOptions: false,

    consolidationFilingGroupOptions: [],
    isFetchingConsolidationFilingGroupOptions: false,

    consolidationFilingDecisions: [],
    isFetchingConsolidationFilingDecisions: false,
    isUpdatingConsolidationFilingDecisions: false,

    entityFilingDecisions: [],
    isFetchingEntityFilingDecisions: false,
    isUpdatingEntityFilingDecisions: false,

    isFetchingAmountDetails: false,
    amountDetails: [],

    nexusNote: null,
    isFetchingNexusNote: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchProfilesOptionsTypes,
      payloadKey: 'profileOptions',
      fetchingKey: 'isFetchingProfileOptions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchConsolidationFilingGroupsOptionsTypes,
      payloadKey: 'consolidationFilingGroupOptions',
      fetchingKey: 'isFetchingconsolidationFilingGroupOptions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchConsolidationFilingDecisionsTypes,
      payloadKey: 'consolidationFilingDecisions',
      fetchingKey: 'isFetchingConsolidationFilingDecisions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateConsolidationFilingDecisionsTypes,
      fetchingKey: 'isUpdatingConsolidationFilingDecisions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchEntityFilingDecisionsTypes,
      payloadKey: 'entityFilingDecisions',
      fetchingKey: 'isFetchingEntityFilingDecisions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateEntityFilingDecisionsTypes,
      fetchingKey: 'isUpdatingEntityFilingDecisions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFilingDecisionsAmountDetailsTypes,
      payloadKey: 'amountDetails',
      fetchingKey: 'isFetchingAmountDetails',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchNexusNoteTypes,
      payloadKey: 'nexusNote',
      fetchingKey: 'isFetchingNexusNote',
    }),

    [CLEAR_NEXUS_NOTE]: state => ({
      ...state,
      nexusNote: null,
    }),
  },
});

export default filingDecisionsReducer;
