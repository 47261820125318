import { TAX_YEAR_2021 } from '@common-packages/shared-constants';

import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { IconCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import {
  getCheckBoxGroupNameColumnDefinition,
  getCheckBoxGroupDescriptionColumnDefinition,
  getCheckBoxGroupValueColumnDefinition,
} from '../../shared/columnDefinitions/checkBoxGroup.columnDefinition';

const getColumnDefinitions = ({
  rowData,
  checkboxGroups,
  isInEditMode,
  updateGroup,
  updateRow,
  taxYear,
}) => [
  { ...getCheckBoxGroupNameColumnDefinition({ checkboxGroups }) },
  {
    ...defaultColumnDefinition,
    headerName: 'Page No',
    field: 'page',
    width: 50,
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    ...getCheckBoxGroupDescriptionColumnDefinition({ checkboxGroups }),
    width: 500,
  },
  {
    ...getCheckBoxGroupValueColumnDefinition({
      rowData,
      isInEditMode,
      updateGroup,
      updateRow,
    }),
  },
  ...(taxYear < TAX_YEAR_2021
    ? [
        {
          ...defaultColumnDefinition,
          headerName: 'State Override',
          field: 'isTripletGlobal',
          width: 100,
          cellRenderer: IconCellRendererFactory({
            getName: (value, data) =>
              !data.isTripletGlobal && data.isAccountGlobal ? 'check-mark' : '',
          }),
          cellStyle: {
            textAlign: 'center',
          },
        },
        {
          ...defaultColumnDefinition,
          headerName: 'Is Global Account',
          field: 'isAccountGlobal',
          width: 100,
          cellRenderer: IconCellRendererFactory({
            getName: isAccountGlobal => (isAccountGlobal ? 'check-mark' : ''),
          }),
          cellStyle: {
            textAlign: 'center',
          },
        },
      ]
    : []),
];

export default getColumnDefinitions;
