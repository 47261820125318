import React, { useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { Tab, Tabs } from '@pwc/appkit-react';
import { SelectOption } from '@tls/slt-types';

import {
  TaxReturn,
  TaxReturnDefinitionWithFilingAttributes,
  FindTaxReturnsColumnsBlueprint,
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
} from '../../../../../common/types/apiShapes';
import SlideIn from '../../displayComponents/slideIn/slideIn.component';
import { showConfirmModal } from '../../confirmModal/store/actions';
import { globalContextSelector } from '../../store/selectors';
import { FilingMethods } from '../../enums';
import { FILING_METHOD_OPTIONS } from '../../constants';
import { useMutationInsertReturn, useMutationUpdateReturn } from '../../mutations/taxReturns';
import { FormValues, useFormInitialValues } from '../utils/useFormInitialValues';

import AddEditReturnFormWrapper from './addEditReturnFormWrapper.container';
import styles from './addEditReturnPanel.module.scss';

export const TabsTypes = {
  RETURN_OPTIONS: 'RETURN_OPTIONS',
  CALC_OPTIONS: 'CALC_OPTIONS',
};

const tabsDefinitions = [
  { type: TabsTypes.RETURN_OPTIONS, label: 'Return Details' },
  { type: TabsTypes.CALC_OPTIONS, label: 'Calc Preferences' },
];

interface AddEditReturnPanelProps {
  isPanelVisible: boolean;
  hidePanel: () => void;
  taxReturnToEdit: TaxReturn | null;
  taxReturns: TaxReturnResultRowWithCalcOptionsAndFilingAttributes[];
  taxReturnsColumnsBlueprint: FindTaxReturnsColumnsBlueprint;
  taxReturnsDefinitions: TaxReturnDefinitionWithFilingAttributes[];
  isLoadingTaxReturnsDefinitions: boolean;
  taxReturnsDefinitionsJurisdictionOptions: SelectOption[];
  isLoadingTaxReturnsDefinitionsJurisdictionOptions: boolean;
}

const AddEditReturnPanel = ({
  hidePanel,
  isPanelVisible,
  taxReturnToEdit,
  taxReturns,
  taxReturnsColumnsBlueprint,
  taxReturnsDefinitions,
  isLoadingTaxReturnsDefinitions,
  taxReturnsDefinitionsJurisdictionOptions,
  isLoadingTaxReturnsDefinitionsJurisdictionOptions,
}: AddEditReturnPanelProps) => {
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const dispatch = useDispatch();
  const globalContext = useSelector(globalContextSelector);
  const { taxYear, period, entityId } = globalContext;

  const formRef = useRef<FormikProps<FormValues> | null>(null);

  const isEditing = Boolean(taxReturnToEdit?.taxReturn?.returnId);

  const { mutateAsync: insertReturn } = useMutationInsertReturn(taxReturns);
  const { mutateAsync: updateReturn } = useMutationUpdateReturn();

  const initialValues = useFormInitialValues({
    taxReturnToEdit,
    taxYear,
    period,
    entityId,
  }) as FormValues;

  const onNextButtonClick = useCallback(() => {
    setActiveTab(TabsTypes.CALC_OPTIONS);
  }, []);

  const onBackButtonClick = useCallback(() => {
    setActiveTab(TabsTypes.RETURN_OPTIONS);
  }, [setActiveTab]);

  const hideAddReturnPanel = useCallback(() => {
    setActiveTab(TabsTypes.RETURN_OPTIONS);
    hidePanel();
  }, [hidePanel]);

  const handleHidePanel = useCallback(() => {
    if (formRef.current?.dirty) {
      dispatch(
        showConfirmModal({
          title: 'Do you want to leave this Return?',
          text: 'The changes you made have not been saved.',
          confirmText: 'Leave',
          dismissText: 'Stay',
          confirmCallback: hideAddReturnPanel,
        }),
      );
      return;
    }
    hideAddReturnPanel();
  }, [dispatch, hideAddReturnPanel, formRef]);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const existingNonfilingReturn = taxReturns.find(
        existingTaxReturn =>
          existingTaxReturn.taxReturn?.filingMethod === FilingMethods.NON_FILING &&
          existingTaxReturn.jurisdictionId === values.jurisdictionId &&
          existingTaxReturn.taxReturn?.returnDefinitionId === values.returnDefinitionId,
      );

      if (isEditing || existingNonfilingReturn) {
        const returnId =
          existingNonfilingReturn?.taxReturn?.returnId || taxReturnToEdit?.taxReturn?.returnId;

        await updateReturn({
          ...values,
          calcOptions: JSON.stringify(values.calcOptions),
          filingMethod:
            FILING_METHOD_OPTIONS.find(({ value }) => value === values?.filingMethod)
              ?.numberValue || null,
          returnId: returnId || 0,
        });
      } else {
        await insertReturn({
          ...values,
          calcOptions: JSON.stringify(values.calcOptions),
          filingMethod:
            FILING_METHOD_OPTIONS.find(({ value }) => value === values?.filingMethod)
              ?.numberValue || null,
        });
      }
      setActiveTab(TabsTypes.RETURN_OPTIONS);
      hidePanel();
    },
    [
      insertReturn,
      updateReturn,
      hidePanel,
      setActiveTab,
      isEditing,
      taxReturns,
      taxReturnToEdit?.taxReturn?.returnId,
    ],
  );

  return (
    <SlideIn
      className={styles.panel}
      isOpen={isPanelVisible}
      onRequestClose={handleHidePanel}
      width="700px"
      closeIconName="close"
      title={`${isEditing ? 'Edit' : 'Add'} Return`}
    >
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} size="md">
            {tabsDefinitions.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
        </div>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <AddEditReturnFormWrapper
          isEditing={isEditing}
          activeTab={activeTab}
          isLoadingTaxReturnsDefinitionsJurisdictionOptions={
            isLoadingTaxReturnsDefinitionsJurisdictionOptions
          }
          isLoadingTaxReturnsDefinitions={isLoadingTaxReturnsDefinitions}
          taxReturns={taxReturns}
          taxReturnsColumnsBlueprint={taxReturnsColumnsBlueprint}
          taxReturnsDefinitions={taxReturnsDefinitions}
          taxReturnsDefinitionsJurisdictionOptions={taxReturnsDefinitionsJurisdictionOptions}
          onNextButtonClick={onNextButtonClick}
          onBackButtonClick={onBackButtonClick}
        />
      </Formik>
    </SlideIn>
  );
};

export default AddEditReturnPanel;
