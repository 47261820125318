import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

import { contentDeploymentWarningModalTypes } from './propTypes';

const ContentDeploymentWarningModal = ({ isLoading, visible, hideModal, data }) => {
  const isModalEnabled = Boolean(visible && !isLoading && data?.length);
  return (
    <Modal
      title="Warning"
      dismissText="Ok"
      visible={isModalEnabled}
      dismissAction={hideModal}
      closeAction={hideModal}
    >
      <p>Content deployment encountered errors with the following clients:</p>
      <ul>
        {data?.map(issue => {
          return (
            <li key={issue.name}>
              {issue.name} -
              {issue.status === 'NOT FOUND'
                ? ' SnapLogic implementation not found'
                : ' Deployment failed'}
            </li>
          );
        })}
      </ul>
    </Modal>
  );
};

ContentDeploymentWarningModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(contentDeploymentWarningModalTypes)),
};

export default ContentDeploymentWarningModal;
