const isEveryNotDisabledChildChecked = ({ children, checked } = {}) => {
  if (Object.keys(children || {}).length) {
    return Object.values(children)
      .filter(({ disabled }) => !disabled)
      .map(value => isEveryNotDisabledChildChecked(value))
      .every(checked => checked);
  }

  return checked;
};

// if the item is not in indeterminate state - normal behavior (toggle between checked and unchecked)
// if the item is in indeterminate state and has ALL NOT DISABLED children checked - uncheck the
// item and all not disabled children
// if the item in in indeterminate state and doesn't have all not not disabled children checked -
// try to check the item and all not disabled children
const getNewCheckedValue = ({ checked, indeterminate, children } = {}) => {
  if (!indeterminate) {
    return !checked;
  }

  return !isEveryNotDisabledChildChecked({ children, checked });
};

export default getNewCheckedValue;
