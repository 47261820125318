import { Routes, taxNames } from '@common-packages/routes-definitions';

import {
  EditableCheckboxCellRenderer,
  LinkCellRendererFactory,
  IconCellRendererFactory,
  MultiElementCellRendererHoc,
} from '../../shared/columnDefinitions/cellRenderers';
import {
  yesNoOptions,
  currencyColumnDefinition,
  booleanColumnDefinition,
  mapValueFormatterFactory,
  selectCellEditorParamsFactory,
  defaultColumnDefinition,
} from '../../shared/columnDefinitions';
import { filingTypes } from '../../shared/constants';
import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';
import { defaultAgRichSelectCellEditor } from '../../shared/columnDefinitions/cellEditor';

const CATEGORY_NAMES = [
  { key: 'PROPERTY', field: 'property', name: 'Property' },
  { key: 'RENT', field: 'rent', name: 'Rent' },
  { key: 'PAYROLL', field: 'payroll', name: 'Payroll' },
  { key: 'SALES', field: 'salesOfServiceDestination', name: 'Sales of service destination' },
  { key: 'SALES_ORIGIN', field: 'salesOfServiceOrigin', name: 'Sales of service origin' },
  { key: 'SALES_TANG', field: 'tangibleSales', name: 'Tangible sales' },
];

const getColumnDefinitions = ({
  taxYear,
  period,
  isInEditMode,
  updateRow,
  profilesOptions,
  filingGroupsOptions,
  onNexusNoteClick,
}) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const orgNameRenderer = MultiElementCellRendererHoc([
    IconCellRendererFactory({
      getName: () => 'circle-plus',
      clickHandler: (value, data) => onNexusNoteClick(data.orgId),
      isVisible: data => data.hasNexusNotes,
    }),
    LinkCellRendererFactory({
      getText: ({ value }) => value,
      getPathName: (value, data) =>
        Routes.taxSummaries.compiledRoute({
          ...getContextAwareUrlParams({
            taxYear,
            period,
            filingTypeId: filingTypes.SEPARATE,
            businessEntityId: data.orgId,
            jurisdictionId: data.jurisdictionId,
            taxName: taxNames.INCOME_TAX,
          }),
        }),
    }),
  ]);

  const AmountLinkRenderer = categoryNameKey =>
    LinkCellRendererFactory({
      getText: ({ valueFormatted }) => valueFormatted,
      getPathName: (value, data) =>
        Routes.filingDecisionAmountDetails.compiledRoute({
          taxYear,
          period,
          businessEntityId: data.orgId,
          jurisdictionId: data.jurisdictionId,
          filingTypeId: filingTypes.SEPARATE,
          categoryName: categoryNameKey,
        }),
      getRouterState: () => ({
        categoryNameForDisplay: CATEGORY_NAMES.find(({ key }) => key === categoryNameKey).name,
      }),
    });

  const checkboxCellClassRules = {
    ...defaultColumnDefinition.cellClassRules,
    'editable-cell': () => isInEditMode,
  };

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Org ID',
      field: 'orgId',
      width: 120,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Org Name',
      field: 'orgName',
      width: 205,
      cellRenderer: orgNameRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Profile',
      field: 'profileId',
      width: 100,
      editable: isInEditMode,
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(profilesOptions),
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Filing Group',
      field: 'finalFilingGroupId',
      width: 175,
      editable: isInEditMode,
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(filingGroupsOptions),
      onCellValueChanged,
      valueFormatter: mapValueFormatterFactory(filingGroupsOptions),
    },
    {
      ...defaultColumnDefinition,
      ...booleanColumnDefinition,
      headerName: 'Nexus',
      field: 'nexusInd',
      width: 50,
      valueFormatter: mapValueFormatterFactory(yesNoOptions),
      editable: isInEditMode,
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(yesNoOptions),
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Frn',
      field: 'franchiseTaxYn',
      width: 50,
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Inc',
      field: 'incomeTaxYn',
      width: 50,
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
    },
    ...CATEGORY_NAMES.map(({ key, field, name }) => ({
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: name,
      field,
      cellRenderer: AmountLinkRenderer(key),
    })),
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
