import PropTypes from 'prop-types';

export const accountPropTypes = {
  accountId: PropTypes.string,
  accountDescription: PropTypes.string,
  accountGroup: PropTypes.string,
  screenGroup: PropTypes.string,
  functionName: PropTypes.string,
  consFunctionName: PropTypes.string,
  consPbFunctionName: PropTypes.string,
  activeFlag: PropTypes.string,
  caseYn: PropTypes.string,
  altDescription: PropTypes.string,
};
