import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import {
  BreadcrumbRoute,
  StaticHomeBreadcrumb,
} from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';
import ReturnWorkspaceBreadcrumbRoutes from '../../sharedSubPages/returnWorkspace/returnWorkspaceBreadcrumbRoutes.component';
import AccountGroupNameBreadcrumbContentRenderer from '../federalReconciliations/routeConfiguration/accountGroupNameBreadcrumbContentRenderer.container';

import EntityInformationBreadcrumbContentRenderer from './entityInformationBreadcrumbContentRenderer.container';
import EntityBreadcrumbContentRenderer from './entityBreadcrumbContentRenderer.container';

const EntityBreadcrumbRoutes = () => (
  <>
    <StaticHomeBreadcrumb routePath={Routes.compliance.MAIN} />
    <BreadcrumbRoute path={Routes.entity} ContentRenderer={EntityBreadcrumbContentRenderer} />

    <BreadcrumbRoute
      path={Routes.entityInformation}
      ContentRenderer={EntityInformationBreadcrumbContentRenderer}
    />
    <BreadcrumbRoute path={Routes.filingStates} />
    <BreadcrumbRoute path={Routes.nonFilingStates} />

    <ReturnWorkspaceBreadcrumbRoutes path={Routes.entityFilingStatesReturnWorkspace} />
    <ReturnWorkspaceBreadcrumbRoutes path={Routes.entityNonFilingStatesReturnWorkspace} />

    <BreadcrumbRoute path={Routes.reviewPartnershipInformation} />
    <BreadcrumbRoute path={Routes.addPartnershipInformation} />
    <BreadcrumbRoute path={Routes.partnershipFlowThrough} />
    <BreadcrumbRoute path={Routes.partnershipAmountDetails} />
    <BreadcrumbRoute path={Routes.partnershipAllocationPercentages} />
    <BreadcrumbRoute path={Routes.prepareReviewNotes} />
    <BreadcrumbRoute path={Routes.federalReconciliation} />
    <BreadcrumbRoute
      path={Routes.federalReconciliationVariances}
      ContentRenderer={AccountGroupNameBreadcrumbContentRenderer}
    />
    <BreadcrumbRoute
      path={Routes.sltAmountDetails}
      ContentRenderer={AccountGroupNameBreadcrumbContentRenderer}
    />
    <BreadcrumbRoute
      path={Routes.federalReconciliationCategoryDetails}
      ContentRenderer={AccountGroupNameBreadcrumbContentRenderer}
    />
    <BreadcrumbRoute path={Routes.stateAccountNumbers} />
    <BreadcrumbRoute path={Routes.manuallyEnteredData} />
    <BreadcrumbRoute path={Routes.addEditGlobalPdfAttachments} />
    <BreadcrumbRoute path={Routes.priorYearComparisonReportDetail} />
    <BreadcrumbRoute path={Routes.priorYearComparisonReportSummary} />
  </>
);

export default EntityBreadcrumbRoutes;
