import { useState, useCallback } from 'react';
import { GridApi, ColumnApi, GridReadyEvent } from 'ag-grid-community';

const useGridApi = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);

  const onGridReady = useCallback(({ api, columnApi }: GridReadyEvent) => {
    setGridApi(api);
    setColumnApi(columnApi);
  }, []);

  return {
    gridApi,
    columnApi,
    onGridReady,
  };
};

export default useGridApi;
