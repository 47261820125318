import React from 'react';

import { TextCellRendererFactory } from '.';

export const getSelectOptionCellRenderer = (options, validators = {}) => {
  const TextRenderer = TextCellRendererFactory({
    getText: ({ value }) =>
      (options.find(option => String(option.value) === String(value)) || { label: '' }).label,
  });

  const SelectCellRendererWithCustomValidationFramework = props => (
    <TextRenderer {...props} validators={validators} />
  );

  return SelectCellRendererWithCustomValidationFramework;
};
