import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import { globalContextSelector } from '../../shared/store/selectors';
import styles from '../../shared/reports/reportParam.module.scss';
import { getAllValues } from '../utils';

import { reportTypes } from './constants';
import columnDefinitions from './state851AffiliateData.columnDefinitions';

const REPORT_TYPE_OPTIONS = [
  { value: reportTypes.FINAL_POPULATION, label: 'Final Population' },
  { value: reportTypes.POTENTIAL_POPULATION, label: 'Potential Population' },
];

const endpointBasePath = '/api/reports/state-851-affiliate-data';

const State851AffiliateData = () => {
  const { jurisdictionId } = useSelector(globalContextSelector);

  const [reportType, setReportType] = useState(REPORT_TYPE_OPTIONS[0].value);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const jurisdictionIdParam = useReportParam({
    endpointBasePath,
    paramName: 'jurisdictionId',
    defaultParamValue: jurisdictionId,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  const consolidatedGroupParam = useReportParam({
    endpointBasePath,
    paramName: 'consolidatedGroup',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        jurisdictionId: jurisdictionIdParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue, jurisdictionIdParam.paramValue],
    ),
  });

  const isFetchingAnyParam =
    reportType === reportTypes.FINAL_POPULATION
      ? checkIsFetchingAnyParam([
          taxYearParam,
          periodParam,
          jurisdictionIdParam,
          consolidatedGroupParam,
        ])
      : checkIsFetchingAnyParam([taxYearParam, periodParam, jurisdictionIdParam]);

  const { reportData, isFetchingReportData, runReportButton } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        jurisdictionId: jurisdictionIdParam.paramValue,
        filingGroupId:
          reportType === reportTypes.FINAL_POPULATION
            ? consolidatedGroupParam.paramValue
            : getAllValues(consolidatedGroupParam.paramData),
        reportType,
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        jurisdictionIdParam.paramValue,
        consolidatedGroupParam.paramValue,
        consolidatedGroupParam.paramData,
        reportType,
      ],
    ),
  });

  const { props, onGridReady } = useActionMenuForExport({ reportData });

  return (
    <>
      <div className="row">
        <div className={classNames('col', styles.reportParam)}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown
            label="Tax type"
            param={useMemo(
              () => ({
                isFetchingParamData: false,
                paramData: REPORT_TYPE_OPTIONS,
                paramValue: reportType,
                setParamValue: setReportType,
              }),
              [reportType],
            )}
          />
          <ReportParamDropdown label="Jurisdiction" param={jurisdictionIdParam} />
          {reportType === reportTypes.FINAL_POPULATION && (
            <ReportParamMultiSelect label="Consolidated Group" param={consolidatedGroupParam} />
          )}
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {(reportData || isFetchingReportData) && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default State851AffiliateData;
