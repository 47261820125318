import React, { useCallback } from 'react';
import { Button, Radio, RadioGroup } from '@pwc/appkit-react';

import { dateFormatter } from '../../shared/formatters';
import config from '../../config';
import { SSN_MODAL_OPTION } from '../../shared/enums/pdf';

import styles from './downloadPdfPanel.module.scss';

interface DownloadRegeneratePdfParams {
  handleRegenerateReturn: () => Promise<void>;
  latestReturnPdfGenerationDate?: string | null;
  isDownloading: boolean;
  handleDownloadPdfClick: () => void;
  shouldRevealSSNOption: string;
  setShouldRevealSSNOption: React.Dispatch<React.SetStateAction<string>>;
  isIndividualPartnersAndHasPermission: boolean;
}

const DownloadRegeneratePdf = ({
  handleRegenerateReturn,
  latestReturnPdfGenerationDate,
  isDownloading,
  handleDownloadPdfClick,
  shouldRevealSSNOption,
  setShouldRevealSSNOption,
  isIndividualPartnersAndHasPermission,
}: DownloadRegeneratePdfParams) => {
  const handleOptionChange = useCallback(
    (e: Event, value: string) => {
      setShouldRevealSSNOption(value);
    },
    [setShouldRevealSSNOption],
  );

  return (
    <div className={styles.panelWrapper}>
      <div className={styles.panelItemWrapper}>
        <img
          alt="validation-loading-icon"
          src={`${config.BASE_PATH}/assets/download-regenerate-pdf-graphic.png`}
          className={styles.illustration}
        />
      </div>
      {isIndividualPartnersAndHasPermission ? (
        <RadioGroup
          onChange={handleOptionChange}
          defaultValue={shouldRevealSSNOption}
          value={shouldRevealSSNOption}
          className={styles.radioOptionWarpper}
        >
          {SSN_MODAL_OPTION?.map((option, index) => (
            <Radio key={index} value={option.value}>
              {`${option.label} SSN`}
            </Radio>
          ))}
        </RadioGroup>
      ) : (
        ''
      )}
      <span className={styles.text}>Here is your most up-to-date PDF.</span>
      <div className={styles.downloadButtonWrapper}>
        <Button
          size="xl"
          kind="secondary"
          className={styles.downloadButton}
          onClick={handleDownloadPdfClick}
          isLoading={isDownloading}
        >
          Download
        </Button>
        <span className={styles.pdfGenerationDateText}>
          Generated on{' '}
          {dateFormatter(latestReturnPdfGenerationDate, config.AMERICAN_DATE_TIME_FORMAT)}
        </span>
      </div>
      <span className={styles.text}>
        Re-generate to include changes and overwrite the previous PDF.
      </span>
      <Button size="xl" kind="secondary" onClick={handleRegenerateReturn} className={styles.button}>
        Re-Generate
      </Button>
    </div>
  );
};

export default DownloadRegeneratePdf;
