import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useQueryFindCheckboxGroups } from '../../shared/queries/checkboxGroups';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import Loading from '../../shared/displayComponents/loading.component';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useRowEditMode } from '../../shared/editMode';
import {
  globalContextSelector,
  taxYearSelector,
  periodSelector,
  consolidationIdSelector,
  jurisdictionIdSelector,
} from '../../shared/store/selectors';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import { autoGroupColumnDef } from '../../shared/columnDefinitions/booleanCheckboxBasedAgGrid.autoGroupColumnDefinition';
import {
  useQueryConsolInfoDataForms,
  useQueryConsolInfoDataFormFields,
} from '../../shared/queries/consolidationInformationalData';
import { useMutationUpdateConsolInfoDataFields } from '../../shared/mutations/consolidationInformationalData';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { gridGroupOptions } from '../../shared/displayComponents/agGrid/constants';

import getColumnDefinitions from './informationalData.columnDefinitions';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const InformationalData = ({ hasUserPermissionsToEdit }) => {
  const [selectedFormId, selectForm] = useState(null);

  const globalContext = useSelector(globalContextSelector);
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const consolidationId = useSelector(consolidationIdSelector);

  const { queryParams: { formId } = null } = useUrlParams();

  const {
    mutateAsync: updateFormFields,
    isLoading: isUpdatingFormFields,
  } = useMutationUpdateConsolInfoDataFields();

  const { data: forms, isFetching: isFetchingForms } = useQueryConsolInfoDataForms({
    params: { globalContext },
    enabled: globalContext.isReady,
  });

  const {
    data: checkboxGroupsResponse,
    isFetching: isFetchingCheckboxGroups,
  } = useQueryFindCheckboxGroups({
    params: { taxYear, jurisdictionId, shouldIncludeEverywhereJurisdiction: true },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const { data: formFields, isFetching: isFetchingFormFields } = useQueryConsolInfoDataFormFields({
    params: {
      taxYear,
      period,
      jurisdictionId,
      consolidationId,
      formId: selectedFormId,
    },
    enabled: Boolean(taxYear && period && jurisdictionId && consolidationId && selectedFormId),
  });

  useEffect(() => {
    if (forms?.length) {
      selectForm(formId || forms[0].value);
    }
  }, [forms, formId]);

  const isLoading =
    isFetchingCheckboxGroups || isFetchingForms || isFetchingFormFields || isUpdatingFormFields;

  const saveChanges = useCallback(
    ({ rowsPairsWithChanges }) => {
      if (!rowsPairsWithChanges.length) {
        return;
      }

      const rows = rowsPairsWithChanges.map(({ newRow }) => newRow);
      updateFormFields({ rows });
    },
    [updateFormFields],
  );
  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateGroup,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: formFields,
    getUniqueRowId,
    editButtonDisabled: isLoading || !formFields.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        rowData: clonedRowData,
        checkboxGroups: checkboxGroupsResponse.checkboxGroups,
        isInEditMode,
        updateGroup,
        updateRow,
      }),
    [isInEditMode, updateGroup, updateRow, checkboxGroupsResponse.checkboxGroups, clonedRowData],
  );

  const onChangeForm = useCallback(({ value }) => selectForm(value), []);

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col-9">
          <Loading isLoading={isFetchingForms} small>
            <SDKCustomSelect
              className="sdk-custom-select"
              virtualized
              appkitLabel="Consol Form"
              options={forms}
              value={selectedFormId}
              onChange={onChangeForm}
            />
          </Loading>
        </div>
        {hasUserPermissionsToEdit && (
          <div className="col-3 add-button-column">{editModeButtons}</div>
        )}
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            autoGroupColumnDef={autoGroupColumnDef}
            isGridLoading={isLoading}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            stopEditingWhenCellsLoseFocus
            withSearchBar
            {...gridGroupOptions}
          />
        </div>
      </div>
    </>
  );
};

InformationalData.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default InformationalData;
