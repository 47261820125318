import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import VirtualizedSelectWithOptionsFetchFactory from '../../../shared/forms/virtualizedSelect/virtualizedSelectWithOptionsFetchFactory';
import { fetchDataItems } from '../store/api';

const AccountDataItemEditor = forwardRef((props, ref) => {
  const { taxYear, jurisdictionId, datasetName } = props;
  const VirtualizedSelectWithOptionsFetch = VirtualizedSelectWithOptionsFetchFactory({
    fetchMethod: () => fetchDataItems({ taxYear, jurisdictionId, datasetName }),
  });

  return <VirtualizedSelectWithOptionsFetch {...props} forwardedRef={ref} />;
});

AccountDataItemEditor.displayName = 'AccountDataItemEditor';

AccountDataItemEditor.propTypes = {
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,
  datasetName: PropTypes.string,
};

export default AccountDataItemEditor;
