import React, { useState, FocusEventHandler, useCallback, useMemo, useEffect, useRef } from 'react';
import { FormikProps } from 'formik';
import Select, { SingleValue, StylesConfig } from 'react-select-new';
import { Tooltip } from '@pwc/appkit-react';
import { SelectOption } from '@tls/slt-types';

import { OverridableField } from '../../types';
import { getInputValueKey } from '../../utils/getInputValueKey';
import useBooleanState from '../../../../shared/hooks/useBooleanState.hook';

import styles from './overridableFormSelect.module.scss';

type IsMulti = false;

type WrapperStyles = {
  bottom: string;
  fontFamily: string;
  fontSize: string;
  height: string;
  left: string;
  lineHeight: string;
  position: string;
  textAlign: string;
  width: string;
};

const MAX_MENU_HEIGHT = 400;
const MENU_MARGIN = 8;
const MENU_LIST_PADDING = 4;
const OPTION_PADDING = 8;
const CONTROL_BORDER_WIDTH = 1;
interface OverridableFormSelectProps {
  options: SelectOption[];
  field: {
    name: string;
    value: OverridableField;
  };
  form: FormikProps<unknown>;
  disabled?: boolean;
  isFocused: boolean;
  isContextMenuActive: boolean;
  handleFocus: FocusEventHandler;
  handleFieldUpdateAndFormatting: (params: {
    newValue?: string | number | null;
    valueKey?: string;
    additionalPropsToChange?: { [key: string]: unknown };
  }) => Promise<void>;
  wrapperStyles: WrapperStyles;
}

const OverridableFormSelect = ({
  options,
  field: { name, value },
  form: { setFieldValue },
  disabled = false,
  isFocused,
  isContextMenuActive,
  handleFocus,
  handleFieldUpdateAndFormatting,
  wrapperStyles,
}: OverridableFormSelectProps) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [isTooltipDisabled, disableTooltip, enableTooltip] = useBooleanState(true);

  const { isEditable, isOverridden, dataType, formattedData } = value;

  const inputValueKey = getInputValueKey({ isOverridden, isEditable });
  const inputValue = isFocused ? value[inputValueKey] : formattedData;

  const [selectedValue, setSelectedValue] = useState<SingleValue<SelectOption> | undefined>(() =>
    options.find(({ value }) => value === inputValue),
  );

  const handleChange = useCallback(
    (newValue: SingleValue<SelectOption>) => {
      const keyToSet = getInputValueKey({ isOverridden, isEditable });
      setSelectedValue(newValue?.value ? newValue : null);
      setFieldValue(name, {
        ...value,
        [keyToSet]: newValue?.value,
        formattedData: newValue?.value,
        dataType,
      });
    },
    [setFieldValue, setSelectedValue, name, value, isOverridden, isEditable, dataType],
  );

  const handleBlur = useCallback(() => {
    handleFieldUpdateAndFormatting({
      newValue: inputValue,
    });
  }, [handleFieldUpdateAndFormatting, inputValue]);

  const customStyles: StylesConfig<SelectOption, IsMulti> = useMemo(
    () =>
      ({
        menu: provided => ({
          ...provided,
          marginTop: MENU_MARGIN,
          marginBottom: MENU_MARGIN,
        }),
        option: provided => ({
          ...provided,
          paddingTop: OPTION_PADDING,
          paddingBottom: OPTION_PADDING,
        }),
        menuList: provided => ({
          ...provided,
          paddingBottom: MENU_LIST_PADDING,
          paddingTop: MENU_LIST_PADDING,
        }),
        control: provided => ({
          ...provided,
          minHeight: wrapperStyles.height,
          borderWidth: CONTROL_BORDER_WIDTH,
        }),
      } as StylesConfig<SelectOption, IsMulti>),
    [wrapperStyles.height],
  );

  const optionsWithClearSelectionOption = useMemo(
    () =>
      selectedValue
        ? [
            {
              value: null,
              label: 'Clear selected value',
            },
            ...options,
          ]
        : options,
    [options, selectedValue],
  );

  useEffect(() => {
    const selectValueContainerEl = selectRef.current?.querySelector<HTMLSpanElement>(
      '.react-select__single-value',
    );
    if (selectValueContainerEl?.offsetWidth === selectValueContainerEl?.scrollWidth) {
      disableTooltip();
      return;
    }
    enableTooltip();
  }, [disableTooltip, enableTooltip, inputValue]);

  const menuPlacement = useMemo(
    () =>
      Math.floor(parseFloat(wrapperStyles.bottom.replace('px', ''))) <
      Math.min(
        MAX_MENU_HEIGHT,
        CONTROL_BORDER_WIDTH * 2 +
          MENU_MARGIN +
          MENU_LIST_PADDING * 2 +
          (OPTION_PADDING * 2 + parseFloat(wrapperStyles.lineHeight.replace('px', ''))) *
            options.length,
      )
        ? 'top'
        : 'bottom',
    [wrapperStyles, options.length],
  );

  return (
    <div className={styles.overridableFormSelect} ref={selectRef}>
      <Tooltip
        content={<span className="sdk-custom-select-tooltip-content">{inputValue}</span>}
        disabled={isTooltipDisabled}
        placement="top"
        mouseEnterDelay={500}
        tooltipTheme="light"
        hasArrow
        onClick={disableTooltip}
      >
        <Select
          value={selectedValue}
          options={optionsWithClearSelectionOption}
          classNamePrefix="react-select"
          isDisabled={disabled}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          placeholder={inputValue}
          menuPlacement={menuPlacement}
          maxMenuHeight={MAX_MENU_HEIGHT}
          menuIsOpen={isFocused && !isContextMenuActive}
          styles={customStyles}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        />
      </Tooltip>
    </div>
  );
};

export default OverridableFormSelect;
