import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';
import { GlobalConsolidationData } from '../../../../common/types';

import { useCustomMutation } from '.';

interface UpdateConsolidationInfoRequest {
  values: GlobalConsolidationData[];
  taxYear: string;
  period: string;
  consolidationId: string;
  jurisdictionId: string;
}

interface CopyGlobalInfomationalDataFromParentToConsolidationRequest {
  taxYear: string;
  period: string;
  consolidationId: string;
}

export const useMutationUpdateGlobalConsolidationInfo = () =>
  useCustomMutation<null, QueryError, UpdateConsolidationInfoRequest, GlobalConsolidationData[]>({
    handleData: ({ taxYear, period, consolidationId, values, jurisdictionId }) => ({
      url: `/api/consolidations/consolidation-info/${taxYear}/${period}/${consolidationId}/${jurisdictionId}`,
      method: HttpMethods.PUT,
      body: values,
    }),
    resetKey: QueryKeys.ConsolidationInfo.GlobalInfo,
    successMessage: 'Updating consolidation info succeeded',
    errorMessage: 'Updating consolidation info failed',
  });

export const useMutationCopyGlobalInfomationalDataFromParentToConsolidation = () =>
  useCustomMutation<null, QueryError, CopyGlobalInfomationalDataFromParentToConsolidationRequest>({
    handleData: ({ taxYear, period, consolidationId }) => ({
      url: `/api/consolidations/consolidation-info/copy-global-infomational-data-from-parent-to-consolidation/${taxYear}/${period}/${consolidationId}`,
      method: HttpMethods.POST,
    }),
    resetKey: QueryKeys.ConsolidationInfo.CopyGlobalInfomationalDataFromParentToConsolidation,
    successMessage: 'Copying global infomational data from parent to a consolidation succeeded',
    errorMessage: 'Copying global infomational data from parent to a consolidation failed',
  });
