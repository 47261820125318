import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { PRINT_SERVICE_PAGE_CONTAINER_ID } from '../../shared/constants';
import { watermarkSelector } from '../../shared/store/viewTaxReturn/selectors';

import styles from './overridableForm.module.scss';

const generateWatermarkText = ({ watermark }) => {
  const { line1, line2, line3 } = watermark;

  return [line1, line2, line3].filter(Boolean).join('\r\n');
};

const createStyledWatermarkElement = ({ watermark, isLandscape }) => {
  const { fontSize, outline, grayScale, portrait, landscape } = watermark;

  const watermarkStyles = isLandscape ? landscape : portrait;

  const { rotation, verticalOffset, horizontalOffset } = watermarkStyles;

  const watermarkElement = document.createElement('h1');

  watermarkElement.className = styles.watermarkDefaults;
  if (outline) {
    watermarkElement.classList.add(styles.watermarkOutlined);
  }

  // Increase the fontSize to match print-service preview functionality
  const calculatedFontSize = fontSize * 1.4;

  watermarkElement.style.transform = `rotate(${rotation * -1}deg)`;
  watermarkElement.style.fontSize = `${calculatedFontSize}px`;
  watermarkElement.style.color = `rgb(${grayScale},${grayScale},${grayScale})`;
  watermarkElement.style.marginTop = `${verticalOffset}px`;
  watermarkElement.style.marginLeft = `${horizontalOffset}px`;

  watermarkElement.textContent = generateWatermarkText({ watermark });

  return watermarkElement;
};

const getPcElement = ({ pageId }) => {
  const pageContainer = document.getElementById(PRINT_SERVICE_PAGE_CONTAINER_ID);

  if (!pageContainer) {
    return null;
  }
  const page = pageContainer.querySelector(`#${pageId}`);

  if (!page) {
    return null;
  }
  // .pc is added during the pdf to html generation using pdf2html library
  const pcElement = page.querySelector('.pc');

  return pcElement;
};

const appendWatermarkToPage = ({ pageId, watermark, isLandscape }) => {
  const pcElement = getPcElement({ pageId });

  if (!pcElement) {
    return;
  }

  const watermarkElement = createStyledWatermarkElement({ watermark, isLandscape });

  pcElement.insertBefore(watermarkElement, pcElement.firstChild);
};

const removeWatermarkFromPage = ({ pageId }) => {
  const pcElement = getPcElement({ pageId });

  if (!pcElement) {
    return;
  }

  const watermarkElement = pcElement.querySelector(`.${styles.watermarkDefaults}`);

  if (watermarkElement) {
    watermarkElement.remove();
  }
};

const useFormWatermark = ({ pageId = null, isLandscape = false }) => {
  const watermark = useSelector(watermarkSelector);

  const renderWatermark = useCallback(() => {
    if (!watermark || !pageId) {
      return;
    }
    appendWatermarkToPage({ watermark, pageId, isLandscape });
  }, [watermark, pageId, isLandscape]);

  const removeWatermark = useCallback(() => {
    if (!watermark || !pageId) {
      return;
    }
    removeWatermarkFromPage({ pageId });
  }, [watermark, pageId]);

  return {
    renderWatermark,
    removeWatermark,
  };
};

useFormWatermark.propTypes = {
  pageId: PropTypes.string,
  isLandscape: PropTypes.bool,
};

export default useFormWatermark;
