import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { SelectOptionPropTypes } from '../shared/propTypes/selectOption';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import ReportParamMultiSelect from '../shared/reports/reportParamMultiSelect.component';
import Radio from '../shared/forms/radioFormik/radioFormik.component';
import { filingTypes } from '../shared/constants';
import Loading from '../shared/displayComponents/loading.component';

import { binderItemTypes, selectAllOption } from './constants';

const DOCUMENT_TYPE_OPTIONS = [
  { value: binderItemTypes.FORM, label: 'Form' },
  { value: binderItemTypes.PDF_ATTACHMENT, label: 'PDF Attachment' },
];

const DOCUMENT_LEVEL_OPTIONS = [
  { value: filingTypes.SEPARATE, label: 'Separate' },
  { value: filingTypes.CONSOLIDATED, label: 'Consolidated' },
];

const getDocumentOptions = ({
  businessEntityId,
  isTypeFormSelected,
  binderForms,
  binderAttachments,
}) => {
  if (!businessEntityId) {
    return [];
  }
  return isTypeFormSelected ? binderForms : binderAttachments;
};

const AddBinderItemForm = ({
  values,
  setFieldValue,
  entitiesOptions,
  isFetchingEntities,
  consolidationFullName,

  isFetchingBinderForms,
  binderForms,
  isFetchingBinderAttachments,
  binderAttachments,
  fetchForms,

  fetchExistingDocuments,
  existingDocuments,
  isFetchingExistingDocuments,
  resetExistingDocuments,

  isBinderSeparate,
  filingTypeId,
  businessEntityId,
}) => {
  const isSeparateSelected = values.documentLevel === filingTypes.SEPARATE;
  const isTypeFormSelected = values.documentType === binderItemTypes.FORM;

  const setValueForms = useCallback(forms => setFieldValue('forms', forms), [setFieldValue]);

  const setValueAttachments = useCallback(
    attachments => setFieldValue('attachments', attachments),
    [setFieldValue],
  );

  const entitiesOptionsWithSelectAll = useMemo(
    () => [
      ...(!isBinderSeparate && values.documentType === binderItemTypes.FORM
        ? [selectAllOption]
        : []),
      ...entitiesOptions,
    ],
    [entitiesOptions, isBinderSeparate, values.documentType],
  );

  const documentOptions = useMemo(
    () =>
      getDocumentOptions({
        businessEntityId: !isSeparateSelected ? businessEntityId : values.businessEntityId,
        isTypeFormSelected,
        binderForms,
        binderAttachments,
      }),
    [
      binderAttachments,
      binderForms,
      businessEntityId,
      isSeparateSelected,
      isTypeFormSelected,
      values.businessEntityId,
    ],
  );

  useEffect(() => {
    if (values.businessEntityId) {
      const areAllEntitiesInConsolidation =
        values.businessEntityId === selectAllOption.value &&
        values.documentType === binderItemTypes.FORM &&
        values.documentLevel === filingTypes.SEPARATE;
      fetchForms({
        filingTypeId: values.documentLevel,
        areAllEntitiesInConsolidation,
        businessEntityId: areAllEntitiesInConsolidation
          ? businessEntityId
          : values.businessEntityId,
      });
      if (
        values.documentLevel !== filingTypeId ||
        (!isSeparateSelected && values.businessEntityId !== businessEntityId)
      ) {
        fetchExistingDocuments({ filingTypeId: values.documentLevel });
      } else {
        resetExistingDocuments();
      }
    }
  }, [
    fetchForms,
    fetchExistingDocuments,
    resetExistingDocuments,
    values.documentType,
    values.documentLevel,
    values.businessEntityId,
    isSeparateSelected,
    filingTypeId,
    businessEntityId,
  ]);

  const onDocumentLevelChange = useCallback(() => {
    setFieldValue('businessEntityId', null);
    setFieldValue('forms', []);
    setFieldValue('attachments', []);
  }, [setFieldValue]);

  const isFetchingDataForDocumentsField =
    isFetchingExistingDocuments ||
    (isTypeFormSelected ? isFetchingBinderForms : isFetchingBinderAttachments);

  const formParam = useMemo(() => {
    const existingForms = existingDocuments
      .filter(
        ({ orgId, consolidationId }) =>
          orgId === values.businessEntityId || consolidationId === values.businessEntityId,
      )
      .filter(({ itemType }) => itemType === binderItemTypes.FORM)
      .map(({ itemId }) => itemId);

    return {
      isFetchingParamData: isFetchingExistingDocuments,
      paramData: documentOptions.map(option => ({
        ...option,
        disabled: existingForms.includes(option.value),
      })),
      paramValue: values.forms,
      setParamValue: setValueForms,
    };
  }, [setValueForms, documentOptions, values, existingDocuments]);

  const attachmentParam = useMemo(() => {
    const existingAttachments = existingDocuments
      .filter(
        ({ orgId, consolidationId }) =>
          orgId === values.businessEntityId || consolidationId === values.businessEntityId,
      )
      .filter(({ itemType }) => itemType === binderItemTypes.PDF_ATTACHMENT)
      .map(({ itemId }) => itemId);

    return {
      isFetchingParamData: isFetchingExistingDocuments,
      paramData: documentOptions.map(option => ({
        ...option,
        disabled: existingAttachments.includes(option.value),
      })),
      paramValue: values.attachments,
      setParamValue: setValueAttachments,
    };
  }, [setValueAttachments, documentOptions, values]);

  const onEntityChange = useCallback(() => {
    setFieldValue('forms', []);
    setFieldValue('attachments', []);
  }, [setFieldValue]);

  return (
    <>
      <Field
        label="Document Type"
        name="documentType"
        component={Radio}
        options={DOCUMENT_TYPE_OPTIONS}
      />
      <Field
        label="Document Level"
        name="documentLevel"
        component={Radio}
        options={DOCUMENT_LEVEL_OPTIONS}
        customChangeHandler={onDocumentLevelChange}
        disabled={isBinderSeparate}
      />
      <Loading small isLoading={isSeparateSelected ? isFetchingEntities : false}>
        {isSeparateSelected ? (
          <SDKCustomSelect
            appkitLabel="Entity"
            name="businessEntityId"
            autoComplete="off"
            options={entitiesOptionsWithSelectAll}
            value={values.businessEntityId}
            disabled={isFetchingDataForDocumentsField}
            customChangeHandler={onEntityChange}
            virtualized
          />
        ) : (
          <label className="a-form-label">Group: {consolidationFullName}</label>
        )}
      </Loading>
      <Loading small isLoading={isFetchingDataForDocumentsField}>
        {values.documentType === binderItemTypes.FORM && (
          <ReportParamMultiSelect label="Documents" showSelectAll={false} param={formParam} />
        )}
        {values.documentType === binderItemTypes.PDF_ATTACHMENT && (
          <ReportParamMultiSelect label="Documents" showSelectAll={false} param={attachmentParam} />
        )}
      </Loading>
    </>
  );
};

AddBinderItemForm.propTypes = {
  values: PropTypes.shape({
    documentType: PropTypes.string.isRequired,
    documentLevel: PropTypes.string.isRequired,
    businessEntityId: PropTypes.string,
    forms: PropTypes.arrayOf(PropTypes.string.isRequired),
    attachments: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  entitiesOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  consolidationFullName: PropTypes.string,
  isFetchingEntities: PropTypes.func.isRequired,

  isFetchingBinderForms: PropTypes.bool.isRequired,
  binderForms: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingBinderAttachments: PropTypes.bool.isRequired,
  binderAttachments: PropTypes.arrayOf(SelectOptionPropTypes),
  fetchForms: PropTypes.func.isRequired,

  fetchExistingDocuments: PropTypes.func.isRequired,
  existingDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      itemType: PropTypes.string.isRequired,
      itemId: PropTypes.string.isRequired,
    }).isRequired,
  ),
  isFetchingExistingDocuments: PropTypes.bool.isRequired,
  resetExistingDocuments: PropTypes.func.isRequired,

  isBinderSeparate: PropTypes.bool.isRequired,
  filingTypeId: PropTypes.string,
  businessEntityId: PropTypes.string,
};

export default AddBinderItemForm;
