import React, { useCallback, useEffect, useMemo } from 'react';
import { Button } from '@pwc/appkit-react/lib/Button';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  periodSelector,
  taxYearSelector,
  jurisdictionIdSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';
import useBottomSlideInEditMode from '../../shared/editMode/useBottomSlideInEditMode.hook';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import NavigateBackLink from '../../sharedSubPages/returnWorkspace/navigateBackLink.container';
import { preparePdfFormDataWithJsonPayload } from '../../utils/preparePdfFormDataWithJsonPayload';
import { useQueryAttachments } from '../../shared/queries/attachments';

import {
  fetchBusinessOptions,
  fetchAttachmentDefinitions,
  createAttachment,
  deleteAttachment,
  updateAttachment,
} from './store/actions';
import {
  attachmentDefinitionsOptionsSelector,
  businessOptionsSelector,
  isFetchingBusinessOptionsSelector,
  isFetchingAttachmentsDefinitionsSelector,
  isCreatingAttachmentSelector,
  isUpdatingAttachmentSelector,
  attachmentDefinitionsSelector,
} from './store/selectors';
import getColumnDefinitions from './attachments.columnDefinitions';
import AddEditAttachment from './addEditAttachment.component';

const getRowId = params => params?.data.rowId;

const Attachments = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const attachmentDefinitionsOptions = useSelector(attachmentDefinitionsOptionsSelector);
  const businessOptions = useSelector(businessOptionsSelector);
  const isFetchingBusinessOptions = useSelector(isFetchingBusinessOptionsSelector);
  const isFetchingAttachmentsDefinitions = useSelector(isFetchingAttachmentsDefinitionsSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);
  const isCreatingAttachment = useSelector(isCreatingAttachmentSelector);
  const isUpdatingAttachment = useSelector(isUpdatingAttachmentSelector);
  const attachmentDefinitions = useSelector(attachmentDefinitionsSelector);

  const isContextReady = taxYear && period && jurisdictionId;
  const isLoading =
    isFetchingGlobalContext || isFetchingAttachmentsDefinitions || isFetchingBusinessOptions;

  const {
    data: attachments,
    isFetching: isFetchingAttachments,
    refetch: refetchAttachments,
  } = useQueryAttachments({
    params: {
      taxYear,
      period,
      jurisdictionId,
    },
    enabled: Boolean(taxYear && period && jurisdictionId),
  });

  useEffect(() => {
    if (!isFetchingGlobalContext && isContextReady) {
      dispatch(fetchBusinessOptions({ taxYear, period }));
      dispatch(fetchAttachmentDefinitions({ taxYear, jurisdictionId }));
    }
  }, [dispatch, isContextReady, isFetchingGlobalContext, jurisdictionId, period, taxYear]);

  const formToRender = useCallback(
    ({ isEditMode, values, onSave }) => (
      <AddEditAttachment
        attachmentDefinitionsOptions={attachmentDefinitionsOptions}
        businessOptions={businessOptions}
        saveAttachment={onSave}
        businessUnitAttachments={attachments?.businessUnitAttachments}
        allAttachments={attachments?.allAttachments}
        taxYear={taxYear}
        period={period}
        jurisdictionId={jurisdictionId}
        values={values}
        isEditMode={isEditMode}
        isCreatingOrUpdatingAttachment={isCreatingAttachment || isUpdatingAttachment}
        attachmentDefinitions={attachmentDefinitions}
      />
    ),
    [
      attachmentDefinitions,
      attachmentDefinitionsOptions,
      businessOptions,
      attachments,
      isCreatingAttachment,
      isUpdatingAttachment,
      jurisdictionId,
      period,
      taxYear,
    ],
  );

  const onSaveAttachment = useCallback(
    async ({ isEditMode, values }) => {
      const attachmentFormData = { formData: preparePdfFormDataWithJsonPayload(values) };
      isEditMode
        ? await dispatch(updateAttachment(attachmentFormData))
        : await dispatch(createAttachment(attachmentFormData));
      await refetchAttachments();
    },
    [dispatch, refetchAttachments],
  );

  const { agGridProps, bottomSlideInWithForm, onAddClick, gridApi } = useBottomSlideInEditMode({
    getRowId,
    formToRender,
    renderBottomSlideIn: !isLoading,
    onSave: onSaveAttachment,
    mapRowForEditForm: rowToEdit => rowToEdit,
  });

  const openModalForDelete = useCallback(
    row => {
      dispatch(
        showConfirmModal({
          title: 'Delete Attachment',
          text: 'Are you sure you want to delete this attachment?',
          confirmCallback: async () => {
            gridApi.deselectAll();
            dispatch(deleteAttachment({ attachmentId: row.attachmentId }));
            await refetchAttachments();
          },
        }),
      );
    },
    [dispatch, refetchAttachments, gridApi],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: openModalForDelete,
        hasUserPermissionsToEdit,
      }),
    [hasUserPermissionsToEdit, openModalForDelete],
  );
  return (
    <>
      <div className="row">
        <div className="col-6">
          <NavigateBackLink />
        </div>
        {hasUserPermissionsToEdit && (
          <div className="col add-button-column">
            <Button
              size="lg"
              onClick={onAddClick}
              className="add-button"
              disabled={isLoading || !isContextReady}
            >
              Add attachment
            </Button>
          </div>
        )}
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={attachments?.businessUnitAttachments}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingAttachments || isCreatingAttachment || isUpdatingAttachment}
            withSearchBar
            {...agGridProps}
          />
        </div>
      </div>
      {bottomSlideInWithForm}
    </>
  );
};

Attachments.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default Attachments;
