import { ColDef } from 'ag-grid-community';

import { defaultColumnDefinitionWithFilter } from '../../../../shared/columnDefinitions';

const columnDefinitions: ColDef[] = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Tax Year',
    field: 'taxYear',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
    width: 100,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Data Model',
    field: 'dataModelName',
    flex: 1,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'DataSet',
    field: 'dataSetName',
    flex: 1,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'DataItem',
    field: 'dataItemName',
    flex: 1,
  },
];

export default columnDefinitions;
