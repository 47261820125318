import { useState, useEffect, useCallback } from 'react';

const useContextMenu = ({ disabled = false, inputWrapperRef }) => {
  const overridablePdfWrapper = document.getElementById('pdfWrapper');

  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [clickPosition, setClickPosition] = useState({
    x: 0,
    y: 0,
  });
  const [clickTargetPosition, setClickTargetPosition] = useState({
    x: 0,
    y: 0,
  });
  const [menuPosition, setMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleClickOutside = useCallback(() => {
    if (isContextMenuActive) {
      setIsContextMenuActive(false);
    }
  }, [isContextMenuActive]);

  const handleContextMenu = useCallback(
    event => {
      const { x, y } = event.currentTarget.getBoundingClientRect();
      event.preventDefault();
      if (disabled) {
        return;
      }
      setMenuPosition({
        x: event.clientX,
        y: event.clientY,
      });
      setClickPosition({
        x: event.clientX,
        y: event.clientY,
      });
      setClickTargetPosition({ x, y });
      setIsContextMenuActive(true);
    },
    [disabled],
  );

  const handleContextMenuScroll = useCallback(
    (e, inputWrapperRef) => {
      const { x, y } = inputWrapperRef.current.getBoundingClientRect();
      const changedX =
        clickTargetPosition.x === x ? clickPosition.x : x - clickTargetPosition.x + clickPosition.x;
      const changedY =
        clickTargetPosition.y === y ? clickPosition.y : y - clickTargetPosition.y + clickPosition.y;

      requestAnimationFrame(() => {
        setMenuPosition({ x: changedX, y: changedY });
      });
    },
    [clickPosition, clickTargetPosition],
  );

  const handleOverridablePdfWrapperScroll = useCallback(
    e => {
      handleContextMenuScroll(e, inputWrapperRef);
    },
    [handleContextMenuScroll, inputWrapperRef],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('contextmenu', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('contextmenu', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (overridablePdfWrapper && isContextMenuActive && inputWrapperRef) {
      overridablePdfWrapper.addEventListener('scroll', handleOverridablePdfWrapperScroll);
    }

    return () => {
      if (overridablePdfWrapper) {
        overridablePdfWrapper.removeEventListener('scroll', handleOverridablePdfWrapperScroll);
      }
    };
  }, [
    handleOverridablePdfWrapperScroll,
    overridablePdfWrapper,
    isContextMenuActive,
    inputWrapperRef,
  ]);

  return {
    handleContextMenu,
    handleContextMenuScroll,
    setIsContextMenuActive,
    isContextMenuActive,
    menuPosition,
  };
};

export default useContextMenu;
