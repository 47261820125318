import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';

import {
  fetchGenericScreenTypes,
  fetchGenericScreenHeaderTypes,
  updateGenericScreenTypes,
} from './types';

export const initialState = {
  genericScreen: { data: [] },
  genericScreenHeader: { header: '' },
  isFetchingGenericScreen: false,
  isFetchingGenericScreenHeader: false,
  isUpdatingGenericScreen: false,
};

const genericScreenReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchGenericScreenTypes,
      payloadKey: 'genericScreen',
      fetchingKey: 'isFetchingGenericScreen',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchGenericScreenHeaderTypes,
      payloadKey: 'genericScreenHeader',
      fetchingKey: 'isFetchingGenericScreenHeader',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateGenericScreenTypes,
      fetchingKey: 'isUpdatingGenericScreen',
    }),
  },
});

export default genericScreenReducer;
