import React from 'react';
import PropTypes from 'prop-types';

import DataInfo from '../../shared/displayComponents/dataInfo/dataInfo.component';
import DateTime from '../../shared/displayComponents/dateTime/datetime.component';

const SuccessUpload = ({ finishedOn = '' }) => {
  const textToRender = (
    <>
      Importing data completed on <DateTime ISOString={finishedOn} />
    </>
  );

  return <DataInfo textToRender={textToRender} />;
};

SuccessUpload.propTypes = {
  finishedOn: PropTypes.string,
  isGridVisible: PropTypes.bool,
  fetchLastImportDataPage: PropTypes.func.isRequired,
};

export default SuccessUpload;
