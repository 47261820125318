const Yup = require('yup');

const drilldownValuesAllowedInDb = ['STD', 'NONE'];

const commonSchema = Yup.object().shape({
  rowId: Yup.string().label('Row Id').nullable(),
  rowType: Yup.string().label('Row Type').nullable(),
  dataType: Yup.string().label('Data Type').nullable(),
  accountId: Yup.string().label('Account Id').nullable(),
  categoryId: Yup.string().label('Category Id').nullable(),
  text: Yup.string().label('Text').nullable().max(50, 'Text cannot exceed 50 characters'),
  // transform empty string to null see https://github.com/jquense/yup/issues/298#issuecomment-519222238
  decimalPlaces: Yup.number()
    .label('Decimal Places')
    .min(0)
    .integer()
    .nullable()
    .transform((value, originalValue) => ((originalValue || '').trim() === '' ? null : value)),
  percentage: Yup.bool().label('Percentage').nullable(),
  commas: Yup.bool().label('Commas').nullable(),
  showAdj: Yup.bool().label('Show Adj').nullable(),
  borders: Yup.bool().label('Borders').nullable(),
  textType: Yup.string().label('Text Type').nullable(),
  drilldownType: Yup.string()
    .oneOf(['', ...drilldownValuesAllowedInDb])
    .label('Drilldown Type'),
});

const genericScreenDefinitionRows = Yup.array().of(commonSchema);

const createGenericScreenDefinitionRows = Yup.array()
  .of(
    Yup.object().shape({
      taxYear: Yup.string().required(),
      period: Yup.string().required(),
      screenId: Yup.string().required(),
      rowId: Yup.string().label('Row Id').nullable(),
      rowType: Yup.string().label('Row Type').nullable(),
      dataType: Yup.string().label('Data Type').nullable(),
      accountId: Yup.string().label('Account Id').nullable(),
      categoryId: Yup.string().label('Category Id').nullable(),
      text: Yup.string().label('Text').nullable().max(50, 'Text cannot exceed 50 characters'),
      decimalPlaces: Yup.number().label('Decimal Places').min(0).integer().nullable(),
      percentage: Yup.string().oneOf(['Y', 'N']).label('Percentage'),
      commas: Yup.string().oneOf(['Y', 'N']).label('Commas'),
      showAdj: Yup.string().oneOf(['Y', 'N']).label('Show Adj'),
      borders: Yup.string().oneOf(['Y', 'N']).label('Borders'),
      textType: Yup.string().label('Text Type').nullable(),
      drilldownType: Yup.mixed()
        .oneOf([null, ...drilldownValuesAllowedInDb])
        .label('Drilldown Type'),
    }),
  )
  .notOneOf([undefined, null]); // eslint-disable-line no-undefined

module.exports = {
  commonSchema,
  createGenericScreenDefinitionRows,
  genericScreenDefinitionRows,
  drilldownValuesAllowedInDb,
};
