import PropTypes from 'prop-types';

export const messages = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    count: PropTypes.number,
  }),
);

export const validator = PropTypes.shape({
  isValid: PropTypes.bool,
});
