import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { Button } from '@pwc/appkit-react/lib/Button';

import AppkitIcon from '../appkitIcon/appkitIcon.component';

import DroppableZone from './droppableZone.component';
import ItemsPropTypes from './items.propTypes';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const UNUSED_DROPPABLE_ID = 'UNUSED_DROPPABLE_ID';
const REFINED_DROPPABLE_ID = 'REFINED_DROPPABLE_ID';

class DraggableLists extends PureComponent {
  static propTypes = {
    listA: ItemsPropTypes.isRequired,
    listB: ItemsPropTypes.isRequired,
    setListA: PropTypes.func.isRequired,
    setListB: PropTypes.func.isRequired,
    listATitle: PropTypes.string.isRequired,
    listBTitle: PropTypes.string.isRequired,
    moveToListBButtonText: PropTypes.string.isRequired,
    moveToListAButtonText: PropTypes.string.isRequired,
  };

  onDragEnd = ({ destination, source }) => {
    const { listA, listB, setListA, setListB } = this.props;

    const droppedOutsideTheList = !destination;
    if (droppedOutsideTheList) {
      return;
    }

    const areBothItemsInTheSameList = source.droppableId === destination.droppableId;
    if (areBothItemsInTheSameList) {
      const list = source.droppableId === UNUSED_DROPPABLE_ID ? listA : listB;

      const listSetter = source.droppableId === UNUSED_DROPPABLE_ID ? setListA : setListB;

      const items = reorder(list, source.index, destination.index);

      listSetter(items);
      return;
    }

    this.moveItemBetweenLists(source, destination);
  };

  moveItemBetweenLists = (source, destination) => {
    const { listA, listB, setListA, setListB } = this.props;

    const sourceList = source.droppableId === UNUSED_DROPPABLE_ID ? listA : listB;

    const destinationList = destination.droppableId === UNUSED_DROPPABLE_ID ? listA : listB;

    const sourceListSetter = source.droppableId === UNUSED_DROPPABLE_ID ? setListA : setListB;

    const destinationListSetter =
      destination.droppableId === UNUSED_DROPPABLE_ID ? setListA : setListB;

    const movedItem = sourceList[source.index];

    sourceListSetter([...sourceList.slice(0, source.index), ...sourceList.slice(source.index + 1)]);
    destinationListSetter([
      ...destinationList.slice(0, destination.index),
      movedItem,
      ...destinationList.slice(destination.index),
    ]);
  };

  moveAllItemsToListB = () => {
    const { listA, listB, setListA, setListB } = this.props;

    setListA([]);
    setListB([...listA, ...listB]);
  };

  moveAllItemsToListA = () => {
    const { listA, listB, setListA, setListB } = this.props;

    setListA([...listA, ...listB]);
    setListB([]);
  };

  render() {
    const {
      listA,
      listB,
      listATitle,
      listBTitle,
      moveToListBButtonText,
      moveToListAButtonText,
    } = this.props;

    return (
      <div className="draggable-lists">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="row table-header">
            <div className="col-6">{listATitle}</div>
            <div className="col-6">{listBTitle}</div>
          </div>
          <div className="row droppable-zones-container">
            <DroppableZone
              id={UNUSED_DROPPABLE_ID}
              items={listA}
              className="col-6 droppable-zone"
              itemClassName="list-item"
            />
            <DroppableZone
              id={REFINED_DROPPABLE_ID}
              items={listB}
              className="col-6 droppable-zone"
              itemClassName="list-item"
            />
          </div>
          <div className="row table-footer">
            <div className="col-6">
              <Button size="sm" onClick={this.moveAllItemsToListB}>
                {moveToListBButtonText}&nbsp;
                <AppkitIcon icon="arrow-right" type="fill" size={12} />
              </Button>
            </div>
            <div className="col-6">
              <Button size="sm" onClick={this.moveAllItemsToListA}>
                <AppkitIcon icon="arrow-left" type="fill" size={12} />
                &nbsp;{moveToListAButtonText}
              </Button>
            </div>
          </div>
        </DragDropContext>
      </div>
    );
  }
}

export default DraggableLists;
