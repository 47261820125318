import React from 'react';

import {
  ReturnStatusDefinitionRow,
  TaxReturnResult,
} from '../../../common/types/apiShapes/taxReturns';
import { FilingMethods } from '../shared/enums';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { dueDateFormatter } from '../shared/formatters/dueDateFormatter';
import { useShouldDisplayReturnStatus } from '../shared/hooks/useShouldDisplayReturnStatus.hook';
import { useShouldDisplayReturnsDueDates } from '../shared/hooks/useShouldDisplayReturnsDueDates.hook';

import styles from './returnMaintenance.module.scss';

interface ReturnMaintenancePanelProps {
  taxReturn: TaxReturnResult;
  returnStatusOption?: ReturnStatusDefinitionRow[];
  onChangeHandler: (newValueObject: {
    label: string;
    value: string | number | boolean | null;
  }) => void;
  isReturnStatusSelectionEnabled: boolean;
}

const ReturnMaintenancePanel = ({
  taxReturn,
  returnStatusOption,
  onChangeHandler,
  isReturnStatusSelectionEnabled,
}: ReturnMaintenancePanelProps) => {
  const { returnType, businessType, returnStatus, efileStatus, filingMethod, dueDate } = taxReturn;

  const formatAcknowledgedDateTime = dueDateFormatter(dueDate);

  const shouldDisplayReturnStatus = useShouldDisplayReturnStatus();
  const displayReturnsDuedates = useShouldDisplayReturnsDueDates();
  const displayReturnsStatus = isReturnStatusSelectionEnabled && shouldDisplayReturnStatus;

  return (
    <>
      <div className={displayReturnsStatus && displayReturnsDuedates ? 'col-2' : 'col-5'}>
        <div className={styles.returnInformationField}>
          <label className={styles.returnHandlerLabel}>Return Type:</label>
          <span>{returnType}</span>
        </div>
      </div>
      <div className={displayReturnsStatus && displayReturnsDuedates ? 'col-3' : 'col'}>
        <div className={styles.returnInformationField}>
          <label className={styles.returnHandlerLabel}>Business Type:</label>
          <span>{businessType}</span>
        </div>
      </div>
      {displayReturnsDuedates && (
        <div className="col">
          <div className={styles.returnInformationField}>
            <label className={styles.returnHandlerLabel}>Due Date:</label>
            <span>{formatAcknowledgedDateTime}</span>
          </div>
        </div>
      )}
      {displayReturnsStatus && (
        <div className="col">
          <div className={styles.returnStatusFieldSDK}>
            <label className={styles.returnStatusLabel}>Return Status:</label>
            <SDKCustomSelect
              className={styles.returnStatusStyle}
              placeholder="Select Status"
              options={returnStatusOption}
              onChange={onChangeHandler}
              value={returnStatus}
              hasEllipsis
              disabled={Boolean(efileStatus && filingMethod === FilingMethods.EFILE)}
              virtualized
              hideLabel
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReturnMaintenancePanel;
