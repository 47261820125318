import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { TextCellEditor } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ isInEditMode, dynamicColumns, addModifiedValue }) => {
  const onCellValueChangedFactory = columnId => ({ data, newValue }) => {
    addModifiedValue({
      columnId,
      jurisdictionId: data.jurisdictionId,
      value: (newValue || '').trim(),
    });
  };

  return [
    {
      ...defaultColumnDefinition,
      headerName: 'State',
      width: 450,
      field: 'jurisdictionDescription',
    },
    ...dynamicColumns.map(({ description, infoAcctId }) => ({
      ...defaultColumnDefinition,
      headerName: description,
      field: infoAcctId,
      editable: isInEditMode,
      cellEditor: TextCellEditor,
      width: 350,
      onCellValueChanged: onCellValueChangedFactory(infoAcctId),
    })),
  ];
};

export default getColumnDefinitions;
