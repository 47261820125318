const Yup = require('yup');

const { noSpecialChar } = require('../validationRegex');

const emptyFieldMessage = 'Response field should be empty in order to set the status to "Opened".';

const commonSchema = Yup.object().shape({
  preparedBy: Yup.string().label('Prepared by'),
  description: Yup.string()
    .matches(noSpecialChar.regex, noSpecialChar.message)
    .label('Description')
    .required(),
  jurisdictionId: Yup.string().label('Jurisdiction').nullable(),
  taskId: Yup.string().label('Task').nullable(),
  rolloverToNextYear: Yup.bool(),
  note: Yup.string().label('Note').required().matches(noSpecialChar.regex, noSpecialChar.message),
  response: Yup.string()
    .label('Response')
    .when('status', {
      is: 'C',
      then: Yup.string().required(
        'Please enter a response to this note in order to set the status to "Cleared".',
      ),
      otherwise: Yup.string().nullable(),
    })
    .when('status', {
      is: 'O',
      then: Yup.string().length(0, emptyFieldMessage),
      otherwise: Yup.string().nullable(),
    }),
  respondedBy: Yup.string().nullable(),
  status: Yup.string().nullable().label('Status'),
});

const createNoteSchema = commonSchema
  .concat(
    Yup.object().shape({
      taxYear: Yup.string().required(),
      period: Yup.string().required(),
      entityId: Yup.string().required(),
    }),
  )
  .strict();

const updateNoteSchema = commonSchema.strict();

module.exports = {
  createNoteSchema,
  updateNoteSchema,
};
