import { httpGetAndParse } from '../../utils/fetchService';

export const fetchNavigatorGenericScreens = ({ taxYear, period, entityId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/navigator/generic-screens/${taxYear}/${period}/${entityId}/${jurisdictionId}`,
    errorMessage: 'Fetching Generic Screens for navigator failed',
  });

export const fetchNavigatorBinderData = ({ binderId }) =>
  httpGetAndParse({
    route: `/api/shared/binder/data/${binderId}`,
    errorMessage: 'Error fetching navigator binder data',
  });

export const fetchNavigatorEFileData = ({
  taxYear,
  period,
  filingTypeId,
  businessEntityId,
  jurisdictionId,
}) =>
  httpGetAndParse({
    route: `/api/efile/data/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}`,
    errorMessage: 'Error fetching navigator eFile data',
  });
