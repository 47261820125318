import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectionChangedEvent } from 'ag-grid-community';
import { useQueryClient } from 'react-query';

import Loading from '../../../../shared/displayComponents/loading.component';
import Select from '../../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import {
  useQueryBinderSteps,
  useQueryReturnOptions,
} from '../../../../shared/queries/eFileSupport';
import {
  consolidationProFormaFilingGroupIdSelector,
  customerPermissionsSelector,
  globalContextSelector,
  jurisdictionDescriptionSelector,
  jurisdictionStateCodeSelector,
} from '../../../../shared/store/selectors';
import { EFileSupportBinderStepResultRow, GlobalContext } from '../../../../../../common/types';
import hasPermissionToFailSubstep from '../../../../development/dataModels/hasPermissionToFailSubstep';
import hasPermissionToUpdateTransmitter from '../../../../development/dataModels/hasPermissionToUpdateTransmitter';
import { EFileReturnStatus, TransmitterProvider } from '../../../../shared/enums/eFileV2';
import { StepStatus } from '../../../../eFile/currentReturnFilingV2/enums';
import { showConfirmModal } from '../../../../shared/confirmModal/store/actions';
import { QueryKeys } from '../../../../shared/queries';
import {
  useMutationEFileRunValidation,
  useMutationResetReturnEFileStatus,
  useMutationResetTransmitterLocator,
} from '../../../../shared/mutations/efileV2';
import { ResetReturnEFileStatusButton } from '../resetReturnEFileStatusButton';
import FailSelectedSubstepButton from '../failSelectedSubstepButton';
import RerunValidationButton from '../rerunValidationButton';
import RefreshButton from '../refreshButton';
import { ResetLocatorButton } from '../resetLocatorButton';
import useTransmitter from '../hooks/useTransmitter/useTransmitter.hook';
import {
  useQueryEFileBinderFilingsData,
  useQueryFindBinderItemsByBinderId,
} from '../../../../shared/queries/eFileV2';

import getColumnDefinitions from './eFileByReturn.columnDefinitions';
import renderReturnParam from './renderReturnParam';
import styles from './eFileByReturn.module.scss';

const ReturnStatusLabels = {
  [EFileReturnStatus.NOT_SUBMITTED]: 'Not Submitted',
  [EFileReturnStatus.SUBMITTED]: 'Submitted',
  [EFileReturnStatus.ACCEPTED]: 'Accepted',
  [EFileReturnStatus.REJECTED]: 'Rejected',
  [EFileReturnStatus.DENIED]: 'Denied',
  [EFileReturnStatus.RETIRED]: 'Retired',
  [EFileReturnStatus.UNKNOWN]: 'N/A',
};

const EFileByReturn = () => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const customerPermissions = useSelector(customerPermissionsSelector);
  const jurisdictionStateCode = useSelector(jurisdictionStateCodeSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);
  const consolidationProFormaFilingGroupId = useSelector(
    consolidationProFormaFilingGroupIdSelector,
  );

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const showFailSubstepButton = hasPermissionToFailSubstep(customerPermissions);
  const showSelectTransmitterComponent = hasPermissionToUpdateTransmitter(customerPermissions);

  const [selectedReturnId, setSelectedReturnId] = useState<number | null>(null);
  const [
    isFailSelectedSubstepButtonDisable,
    setIsFailSelectedSubstepButtonDisabled,
  ] = useState<boolean>(true);
  const [stepId, setStepId] = useState<string>('');

  const { data: returnOptions, isLoading: isLoadingReturnOptions } = useQueryReturnOptions({
    params: globalContext,
    enabled: globalContext.isReady,
    onSuccess: options => {
      if (options.length) {
        setSelectedReturnId(options[0].value);
      }
    },
  });

  const {
    data: returnInformation,
    isLoading: isLoadingReturnInformation,
  } = useQueryEFileBinderFilingsData({
    params: {
      sltBinderId: selectedReturnId,
      apiRouteParamString: globalContext.apiRouteParamString,
    },
    enabled: Boolean(selectedReturnId) && globalContext.isReady,
  });

  const { data: binderItemsCount } = useQueryFindBinderItemsByBinderId({
    params: {
      sltBinderId: selectedReturnId,
    },
    enabled: Boolean(selectedReturnId && !isLoadingReturnInformation),
  });

  const { isLoading: isRunningValidationProcess } = useMutationEFileRunValidation();

  const {
    mutateAsync: resetReturnEFileStatus,
    isLoading: isResetingReturnEFileStatus,
  } = useMutationResetReturnEFileStatus();

  const {
    mutateAsync: resetTransmitterLocator,
    isLoading: isResetingLocator,
  } = useMutationResetTransmitterLocator();

  const { selectTransmitterComponent, setSelectedTransmitterProviderValue } = useTransmitter({
    isLoadingReturnInformation,
    eFileTransmitterProviderValue: returnInformation?.selectedTransmitter || null,
    returnId: returnInformation?.sltBinderId,
    isDisabled:
      !returnInformation?.isEFileReturnInAnInitialState ||
      isResetingReturnEFileStatus ||
      isRunningValidationProcess,
  });

  const { data: binderSteps, isLoading: isLoadingBinderSteps } = useQueryBinderSteps({
    params: { binderFilingId: returnInformation?.binderFilingId || null },
    enabled: Boolean(returnInformation?.binderFilingId),
  });

  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);

  const handleReturnChange = useCallback(
    ({ value }: { value: string | number | null }) => {
      setSelectedReturnId(Number(value) || null);
      setSelectedTransmitterProviderValue(null);
    },
    [setSelectedReturnId, setSelectedTransmitterProviderValue],
  );

  const isLoadingReturnParams = isLoadingReturnOptions || isLoadingReturnInformation;
  const isLoadingGridData =
    isLoadingReturnOptions || isLoadingReturnInformation || isLoadingBinderSteps;

  const selectRow = useCallback(
    ({ api }: SelectionChangedEvent<EFileSupportBinderStepResultRow>) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow?.status === StepStatus.INITIATED) {
        setStepId(selectedRow.stepId);
        setIsFailSelectedSubstepButtonDisabled(false);
      } else {
        setIsFailSelectedSubstepButtonDisabled(true);
      }
    },
    [setIsFailSelectedSubstepButtonDisabled],
  );

  const returnOptionsAndTransmitterOptions = showSelectTransmitterComponent ? (
    <div className={styles.returnAndTransmitterGrid}>
      <span>
        <Select
          placeholder="Select return"
          appkitLabel="Return"
          options={returnOptions}
          onChange={handleReturnChange}
          value={selectedReturnId}
          virtualized
        />
      </span>
      {selectTransmitterComponent}
    </div>
  ) : (
    <span>
      <Select
        placeholder="Select return"
        appkitLabel="Return"
        options={returnOptions}
        onChange={handleReturnChange}
        value={selectedReturnId}
        virtualized
      />
    </span>
  );

  const shouldDisableResetButton =
    !selectedReturnId ||
    isResetingReturnEFileStatus ||
    isLoadingReturnInformation ||
    isRunningValidationProcess;

  const openModalForReset = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Restart Return',
        text: 'Are you sure you want to reset the return back to Step 1?',
        confirmCallback: async () => {
          if (selectedReturnId) {
            await resetReturnEFileStatus({
              returnId: selectedReturnId,
            });
            queryClient.resetQueries([
              QueryKeys.EFileSupport.ResetReturnEFileStatus,
              {
                returnId: selectedReturnId,
              },
            ]);
          }
        },
      }),
    );
  }, [dispatch, selectedReturnId, queryClient, resetReturnEFileStatus]);

  const shouldDisableResetLocatorButton = shouldDisableResetButton || isResetingLocator;

  const openModalForResetLocator = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Reset Locator',
        text: 'Are you sure you want to reset the transmitter locator?',
        confirmCallback: async () => {
          if (returnInformation?.transmitterSubmissionId) {
            try {
              await resetTransmitterLocator({
                sltBinderId: returnInformation.sltBinderId,
              });
              queryClient.invalidateQueries([QueryKeys.EFileSupport.EFileBinderFilingsData]);
            } catch (error) {
              throw new Error('Could not reset the transmitter locator.');
            }
          }
        },
      }),
    );
  }, [dispatch, queryClient, returnInformation?.transmitterSubmissionId, resetTransmitterLocator]);

  return (
    <>
      <div className={styles.returnInformation}>
        <Loading isLoading={isLoadingReturnOptions}>{returnOptionsAndTransmitterOptions}</Loading>
        <div className={styles.additionalInformation}>
          <span className={styles.informationTitle}>Additional Return Information</span>
          <div className={styles.additionalInformationGrid}>
            <span>
              <b>Transmitter Binder Name:</b>{' '}
              {renderReturnParam(returnInformation?.ttiBinderName || '', isLoadingReturnParams)}
            </span>
            <span>
              <b>SLT Binder ID:</b>{' '}
              {renderReturnParam(returnInformation?.sltBinderId || '', isLoadingReturnParams)}
            </span>
            <span>
              <b>Transmitter Binder ID:</b>{' '}
              {renderReturnParam(returnInformation?.ttiBinderId || '', isLoadingReturnParams)}
            </span>
            <span>
              <b>Return Status:</b>{' '}
              {renderReturnParam(
                returnInformation?.eFileReturnStatus ?? EFileReturnStatus.UNKNOWN,
                isLoadingReturnParams,
                ReturnStatusLabels,
              )}
            </span>
            <span>
              <b>EF Submission ID:</b>{' '}
              {renderReturnParam(returnInformation?.efSubmissionId || '', isLoadingReturnParams)}
            </span>
            <span>
              <b>Transmitter Submission ID:</b>{' '}
              {renderReturnParam(
                returnInformation?.transmitterSubmissionId || '',
                isLoadingReturnParams,
              )}
            </span>
            <span>
              <b>Federal Option:</b>{' '}
              {renderReturnParam(
                returnInformation?.federalReturnOptionName || '',
                isLoadingReturnParams,
              )}
            </span>
            <span>
              <b>Transmitter:</b>{' '}
              {renderReturnParam(
                returnInformation?.selectedTransmitterText || '',
                isLoadingReturnParams,
              )}
            </span>
          </div>
          <div className={styles.rerunButtonContainer}>
            {returnInformation?.selectedTransmitter === TransmitterProvider.TR &&
              returnInformation?.transmitterSubmissionId && (
                <ResetLocatorButton
                  onClick={openModalForResetLocator}
                  isDisabled={shouldDisableResetLocatorButton}
                  isLoading={isResetingLocator}
                />
              )}
            {showSelectTransmitterComponent && (
              <ResetReturnEFileStatusButton
                onClick={openModalForReset}
                isDisabled={shouldDisableResetButton}
              />
            )}
            <FailSelectedSubstepButton
              isDisabled={isFailSelectedSubstepButtonDisable}
              stepId={stepId}
              binderFilingId={returnInformation?.binderFilingId || null}
              setIsFailSelectedSubstepButtonDisabled={setIsFailSelectedSubstepButtonDisabled}
              showFailSubstepButton={showFailSubstepButton}
              sltBinderId={returnInformation?.sltBinderId}
            />
            <RefreshButton
              isDisabled={!selectedReturnId || isLoadingReturnInformation || isLoadingBinderSteps}
              binderFilingId={returnInformation?.binderFilingId || null}
              setIsFailSelectedSubstepButtonDisabled={setIsFailSelectedSubstepButtonDisabled}
              sltBinderId={returnInformation?.sltBinderId}
            />
            <RerunValidationButton
              returnInformation={returnInformation}
              jurisdictionStateCode={jurisdictionStateCode}
              jurisdictionDescription={jurisdictionDescription}
              filingGroupId={consolidationProFormaFilingGroupId}
              isTransmitterProviderTR={
                returnInformation?.selectedTransmitter === TransmitterProvider.TR
              }
              hasBinderItems={Boolean(binderItemsCount?.rows)}
            />
          </div>
        </div>
      </div>

      <AgGrid
        className={styles.stepsGrid}
        rowData={binderSteps}
        columnDefs={columnDefinitions}
        isGridLoading={isLoadingGridData}
        onSelectionChanged={selectRow}
        enableBrowserTooltips
        autoMaxWidth
      />
    </>
  );
};

export default EFileByReturn;
