import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  fetchPartnershipAllocationPercentagesTypes,
  updatePartnershipAllocationPercentagesTypes,
  deletePartnershipAllocationTypes,
  fetchPartnershipPercentageTypesTypes,
  fetchPartnersTypes,
  updateOwnershipTypes,
} from './types';

export const initialState = {
  partnershipAllocationPercentages: [],
  isFetchingPartnershipAllocationPercentages: false,
  isUpdatingPartnershipAllocationPercentages: false,
  isDeletingPartnershipAllocation: false,
  isFetchingPercentageTypes: false,
  percentageTypes: [],
  isFetchingPartners: false,
  partners: [],
  isUpdatingOwnership: false,
};

const partnershipAllocationPercentagesReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnershipAllocationPercentagesTypes,
      payloadKey: 'partnershipAllocationPercentages',
      fetchingKey: 'isFetchingPartnershipAllocationPercentages',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updatePartnershipAllocationPercentagesTypes,
      fetchingKey: 'isUpdatingPartnershipAllocationPercentages',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: deletePartnershipAllocationTypes,
      fetchingKey: 'isDeletingPartnershipAllocation',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnershipPercentageTypesTypes,
      payloadKey: 'percentageTypes',
      fetchingKey: 'isFetchingPercentageTypes',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnersTypes,
      payloadKey: 'partners',
      fetchingKey: 'isFetchingPartners',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateOwnershipTypes,
      fetchingKey: 'isUpdatingOwnership',
    }),
  },
});

export default partnershipAllocationPercentagesReducer;
