import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';

import { accountPropTypes } from './accountsPropTypes';

const ACTIVE_FLAG_OPTIONS = [
  {
    value: 'Y',
    label: 'Y',
  },
  {
    value: 'N',
    label: 'N',
  },
  {
    value: 'A',
    label: 'A',
  },
];

const EditAccountsForm = ({
  isEditMode,
  onCancelClick,
  isSubmitting,
  submitForm,
  initialValues,
  values,
  accountGroupOptions,
  screenGroupOptions,
  functionNameOptions,
  consFunctionNameOptions,
  consPbFunctionNameOptions,
}) => {
  const isFormDataChanged = !isEqual(initialValues, values);

  return (
    <form>
      <div>
        <Field
          className="form-text"
          label="Account Id"
          name="accountId"
          component={Input}
          autoComplete="off"
          disabled={isEditMode}
        />
        <Field
          className="form-text"
          label="Description"
          name="accountDescription"
          component={Input}
          autoComplete="off"
        />
        <SDKCustomSelect
          appkitLabel="Account Group"
          name="accountGroup"
          autoComplete="off"
          options={accountGroupOptions}
          value={values.accountGroup}
          virtualized
        />
        <SDKCustomSelect
          appkitLabel="Screen Group"
          name="screenGroup"
          autoComplete="off"
          options={screenGroupOptions}
          value={values.screenGroup}
          virtualized
        />
        <SDKCustomSelect
          appkitLabel="Function"
          name="functionName"
          autoComplete="off"
          options={functionNameOptions}
          value={values.functionName}
          virtualized
        />
        <SDKCustomSelect
          appkitLabel="Consol. Function"
          name="consFunctionName"
          autoComplete="off"
          options={consFunctionNameOptions}
          value={values.consFunctionName}
          virtualized
        />
        <SDKCustomSelect
          appkitLabel="Consol. PB Function"
          name="consPbFunctionName"
          autoComplete="off"
          options={consPbFunctionNameOptions}
          value={values.consPbFunctionName}
          virtualized
        />
        <SDKCustomSelect
          appkitLabel="Active"
          name="activeFlag"
          autoComplete="off"
          options={ACTIVE_FLAG_OPTIONS}
          value={values.activeFlag}
        />
        <Field label="Case" name="caseYn" component={Checkbox} />
        <Field
          className="form-text"
          label="Alternate Description"
          name="altDescription"
          component={Input}
          autoComplete="off"
        />
        <ButtonsGroup
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={onCancelClick}
          submitButtonLabel="Save"
          disabled={!isFormDataChanged}
        />
      </div>
    </form>
  );
};

EditAccountsForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape(accountPropTypes),
  initialValues: PropTypes.shape({ ...accountPropTypes, caseYn: PropTypes.bool }),
  accountGroupOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  screenGroupOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  functionNameOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  consFunctionNameOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  consPbFunctionNameOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
};

export default EditAccountsForm;
