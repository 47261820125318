export const proformaRefreshOptions = [
  {
    label: 'Yes',
    value: 'Y',
  },
  {
    label: 'No',
    value: 'N',
  },
];

export const RETURN_TYPE_FIELD = 'Return Type';
