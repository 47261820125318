import React, { useMemo, useCallback } from 'react';
import { ListItem, ListItemText, ListItemAction, Avatar } from '@pwc/appkit-react';
import { NotificationStatus } from '@common-packages/shared-constants';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import Spinner from '@tls/ui-spinner';

import { Notification, ReturnId } from '../../../common/types';
import { useQueryDownloadReturnPdf } from '../shared/queries/binderMaintenance';

import styles from './notificationItem.module.scss';

interface GeneratePdfBinderStatusNotificationItemParams {
  handleClearNotification: () => void;
  notification: Notification;
  contentText: string;
  isContentTruncated: boolean;
  viewMoreOptions: JSX.Element;
  notificationDate: string;
}

const GeneratePdfBinderStatusNotificationItem = ({
  handleClearNotification,
  notification,
  contentText,
  isContentTruncated,
  viewMoreOptions,
  notificationDate,
}: GeneratePdfBinderStatusNotificationItemParams) => {
  const returnId = notification.notificationContext?.returnId as ReturnId;
  const notificationContext = notification.notificationContext;

  const { isFetching: isDownloadingPdf, refetch: downloadReturnPdf } = useQueryDownloadReturnPdf({
    params: {
      returnId: returnId.toString(),
      shouldDecrypt: Boolean(notificationContext?.shouldDecrypt),
      shouldReturnMaskedIfNoPermission: true,
    },
    enabled: false,
  });

  const downloadPdf = useCallback(async () => {
    const response = await downloadReturnPdf();
    if (!response.data || response.error?.isFetchingError) {
      return;
    }
    const data = new Blob([response.data]);
    saveAs(
      data,
      `${notificationContext?.businessEntityId} - ${notificationContext?.jurisdictionCode} - ${notificationContext?.formName} Return.pdf`,
    );
  }, [
    downloadReturnPdf,
    notificationContext?.businessEntityId,
    notificationContext?.jurisdictionCode,
    notificationContext?.formName,
  ]);

  const notificationAvatar = useMemo(() => {
    switch (notificationContext?.status) {
      case NotificationStatus.SUCCESS:
        return (
          <Avatar
            className={classNames(styles.notificationIcon, styles.notificationIconSuccess)}
            icon={<span className="icon-circle-checkmark-fill" />}
          />
        );
      case NotificationStatus.WARNING:
        return (
          <Avatar
            className={classNames(styles.notificationIcon, styles.notificationIconWarning)}
            icon={<span className="icon-alert-fill" />}
          />
        );
      default:
        return (
          <Avatar
            className={classNames(styles.notificationIcon, styles.notificationIconInfo)}
            icon={<span className="icon-information-fill" />}
          />
        );
    }
  }, [notificationContext?.status]);

  return (
    <div className={styles.notificationItemWrapper}>
      <ListItem className={styles.notificationItem}>
        {notificationAvatar}
        <ListItemText
          className={styles.notificationContent}
          primary={notification.title}
          secondary={contentText}
        />
        <ListItemAction className={styles.notificationClear} onClick={handleClearNotification}>
          <span className="appkiticon icon-close-fill" />
        </ListItemAction>
        <div className={styles.notificationFooter}>
          {isContentTruncated && viewMoreOptions}
          {isDownloadingPdf ? (
            <Spinner small />
          ) : (
            <span className={styles.notificationLink} onClick={downloadPdf}>
              {notification.notificationContext?.status === NotificationStatus.SUCCESS
                ? 'Download PDF'
                : 'Retry'}
            </span>
          )}
        </div>
        <span className={styles.notificationDate}>{notificationDate}</span>
      </ListItem>
    </div>
  );
};

export default GeneratePdfBinderStatusNotificationItem;
