import { dataTypes } from '@common-packages/shared-constants';
import { DRILL_TYPE } from '@common-packages/drilling';

import { defaultColumnDefinitionWithFilter } from '../shared/columnDefinitions';
import {
  getEditorSelector,
  getDataTypeBasedCellRenderer,
} from '../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';

const isLinkRow = row => row.data.drillDestination;

const validators = {
  value: (value, data) => {
    if (
      data.dataType === dataTypes.STRING &&
      data.dataLength > 0 &&
      value?.length > data.dataLength
    ) {
      return `Value's length should be less than or equal to ${data.dataLength}`;
    }
  },
};

const getColumnDefinitions = ({
  isInAddMode,
  isInEditMode,
  updateRow,
  datasetDefinitionId,
  datasetInstanceId,
  context,
}) => {
  const isLinkDataAvailable = datasetDefinitionId && datasetInstanceId;

  const checkboxCellClassRules = {
    ...defaultColumnDefinitionWithFilter.cellClassRules,
    'editable-cell': ({ data }) =>
      (isInEditMode || isInAddMode) && data.isEditable && !data.drillDestination,
  };

  const columnDefinitions = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name',
      field: 'displayName',
      width: 500,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'DataItem Name',
      field: 'name',
      width: 200,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Value',
      field: 'value',
      cellStyle: ({ data }) => ({
        color: data.isOverridden ? 'red' : 'unset',
        textAlign: 'center',
      }),
      width: 500,
      editable: ({ data }) =>
        (isInEditMode || isInAddMode) &&
        data.isEditable &&
        !data.isOverrideable &&
        data.dataType !== dataTypes.BOOLEAN &&
        !data.drillDestination,
      cellRenderer: row =>
        getDataTypeBasedCellRenderer({
          dataType: row.data.dataType,
          validators,
          getDrillData: () =>
            isLinkRow(row) &&
            !isInEditMode &&
            isLinkDataAvailable && {
              drillType: DRILL_TYPE.MULT_GRID,
              parentDatasetDefinitionId: datasetDefinitionId,
              datasetDefinitionId: row.data.drillDestination,
              initialParentDatasetInstanceId: datasetInstanceId,
            },
          context,
        })(row),
      cellRendererParams: {
        isDisabled: ({ data }) => !isInEditMode && !data.drillDestination,
      },
      cellEditorSelector: row => getEditorSelector(row.data.dataType, validators)(row),
      cellClassRules: checkboxCellClassRules,
      onCellValueChanged: ({ data }) => updateRow(data),
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
