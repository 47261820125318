import { httpGetAndParse, httpPut, httpPost } from '../../../utils/fetchService';

export const fetchForms = ({ taxYear, period, filingTypeId, businessEntityId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/entities/k1-information/forms/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}`,
    errorMessage: 'Fetching k1 information forms failed',
  });

export const fetchFilterData = ({ taxYear, period, entityId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/entities/k1-information/filter-data/${taxYear}/${period}/${entityId}/${jurisdictionId}`,
    errorMessage: 'Fetching k1 information form list failed',
  });

export const fetchFormFields = ({
  taxYear,
  period,
  entityId,
  jurisdictionId,
  pshipFormId,
  partnerId,
}) =>
  httpGetAndParse({
    route: `/api/entities/k1-information/${taxYear}/${period}/${entityId}/${jurisdictionId}/${pshipFormId}/${partnerId}`,
    errorMessage: 'Fetching k1 information form fields failed',
  });

export const updateFormFields = ({ rows }) =>
  httpPut({
    route: '/api/entities/k1-information/',
    errorMessage: 'Updating k1 information form fields failed',
    body: { rows },
  });

export const insertInformationalData = ({ taxYear, period, entityId, jurisdictionId }) =>
  httpPost({
    route: '/api/entities/k1-information/',
    errorMessage: 'Inserting informational data failed',
    body: { taxYear, period, entityId, jurisdictionId },
  });
