import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import {
  taxYearSelector,
  periodSelector,
  isFetchingGlobalContextSelector,
} from '../shared/store/selectors';
import { GlobalContextDropdowns } from '../shared/containerComponents/globalContext';

import Heatmap from './heatmap.component';
import { fetchIncomeTaxLiability } from './store/actions';
import {
  isFetchingIncomeTaxLiabilitySelector,
  incomeTaxLiabilitySelector,
} from './store/selectors';

import './dashboard.style.scss';

const Dashboard = ({
  history,
  taxYear,
  period,
  fetchIncomeTaxLiability,
  incomeTaxLiability,
  isFetchingIncomeTaxLiability,
  isFetchingGlobalContext,
}) => {
  const isContextReady = taxYear && period;
  useEffect(() => {
    if (!isContextReady) {
      return;
    }
    fetchIncomeTaxLiability({ taxYear, period });
  }, [fetchIncomeTaxLiability, taxYear, period, isContextReady]);

  const onStateClicked = useCallback(() => {
    history.push(Routes.complianceStateFilingGroups.MAIN);
  }, [history]);

  const isFetchingContext =
    !isContextReady || isFetchingIncomeTaxLiability || isFetchingGlobalContext;

  return (
    <div className="dashboard page-container">
      <div className="container-fluid">
        <div className="row row-with-cards top-cards">
          <div className="card-wrapper col-md">
            <div className="card heatmap">
              <div className="card-header">
                <div className="entities-table-header">
                  <span className="entities-table-header-title">Income Tax Liability</span>
                </div>
              </div>
              <Heatmap
                data={incomeTaxLiability}
                onStateClicked={onStateClicked}
                isFetchingData={isFetchingContext}
              />
              <GlobalContextDropdowns isFetchingContext={isFetchingContext} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  incomeTaxLiability: PropTypes.array.isRequired,
  isFetchingIncomeTaxLiability: PropTypes.bool.isRequired,
  fetchIncomeTaxLiability: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isFetchingGlobalContext: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    incomeTaxLiability: incomeTaxLiabilitySelector(state),
    isFetchingIncomeTaxLiability: isFetchingIncomeTaxLiabilitySelector(state),
    isFetchingGlobalContext: isFetchingGlobalContextSelector(state),
  }),
  {
    fetchIncomeTaxLiability,
  },
)(Dashboard);
