import React from 'react';

import { ContextTypeAndValue } from '../../../../../common/types/apiShapes';
import DevelopmentJurisdictionDropdown from '../../../development/developmentJurisdictionDropdown.container';
import DevelopmentTaxYearDropdown from '../../../development/developmentTaxYearDropdown.container';
import DevelopmentPeriodDropdown from '../../../development/periodDropdown.container';
import DevelopmentTaxFormsDropdown from '../../../development/taxFormsV2/taxFormsDropdown.container';

import styles from './styles.module.scss';

const noop = () => null;

interface HeaderWithParamDropdownProps {
  showHeaderTaxYearDropdown?: boolean;
  showHeaderPeriodDropdown?: boolean;
  showHeaderJurisdictionDropdown?: boolean;
  showHeaderTaxFormDropdown?: boolean;
  enableEverywhereJurisdiction?: boolean;
  showWarningModal?: (params: ContextTypeAndValue) => unknown;
}

const HeaderWithParamDropdown = ({
  showWarningModal = noop,
  showHeaderTaxYearDropdown = false,
  showHeaderPeriodDropdown = false,
  showHeaderJurisdictionDropdown = false,
  showHeaderTaxFormDropdown = false,
  enableEverywhereJurisdiction = false,
}: HeaderWithParamDropdownProps) => (
  <div className={styles.headerParam}>
    {showHeaderTaxYearDropdown && (
      <DevelopmentTaxYearDropdown showWarningModal={showWarningModal} />
    )}
    {showHeaderPeriodDropdown && <DevelopmentPeriodDropdown />}
    {showHeaderJurisdictionDropdown && (
      <DevelopmentJurisdictionDropdown
        showWarningModal={showWarningModal}
        enableEverywhereJurisdiction={enableEverywhereJurisdiction}
      />
    )}
    {showHeaderTaxFormDropdown && <DevelopmentTaxFormsDropdown />}
  </div>
);

export default HeaderWithParamDropdown;
