import { createSelector } from 'reselect';
import { TOTAL_DESC } from '@common-packages/shared-constants';

import { isFetchingContextSelector as isFetchingEntityContextSelector } from '../../../store/selectors';
import { isFetchingFederalReconciliationSelector } from '../../store/selectors';

export const categoryDetailsSelector = ({ entity }) =>
  entity.federalReconciliations.categoryDetails.categoryDetails;

export const isFetchingCategoryDetailsSelector = ({ entity }) =>
  entity.federalReconciliations.categoryDetails.isFetchingCategoryDetails;

export const categoryDetailsWithTotalRowSelector = createSelector(
  [categoryDetailsSelector],
  categoryDetails =>
    categoryDetails.length
      ? [
          ...categoryDetails,
          {
            componentId: TOTAL_DESC,
            isTotalRow: true,
            ...categoryDetails.reduce(
              (acc, curr) => ({
                ...acc,
                bookAmount: acc.bookAmount + curr.bookAmount,
                reclassAmount: acc.reclassAmount + curr.reclassAmount,
                bkTxAmount: acc.bkTxAmount + curr.bkTxAmount,
                fedTaxAmount: acc.fedTaxAmount + curr.fedTaxAmount,
              }),
              {
                bookAmount: 0,
                reclassAmount: 0,
                bkTxAmount: 0,
                fedTaxAmount: 0,
              },
            ),
          },
        ]
      : [],
);

export const isFetchingContextSelector = createSelector(
  [isFetchingEntityContextSelector, isFetchingFederalReconciliationSelector],
  (isFetchingEntityContext, isFetchingFederalReconciliation) =>
    isFetchingEntityContext || isFetchingFederalReconciliation,
);
