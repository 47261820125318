import React from 'react';

const ExpandSVG = () => (
  <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1">
    <g
      id="Icon/Outline/expand-chevron-outline"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icon" fill="#474747" fillRule="nonzero">
        <path
          d="M22.7192767,10.1126549 L11.9995,1.67034816 L1.28072332,10.1126549 C0.987729421,10.3434008 0.512739318,10.3434008 0.219745422,10.1126549 C-0.073248474,9.88190902 -0.073248474,9.50783289 0.219745422,9.27708703 L11.9995,0 L23.7802546,9.27708703 C24.0732485,9.50783289 24.0732485,9.88190902 23.7802546,10.1126549 C23.4872607,10.3434008 23.0122706,10.3434008 22.7192767,10.1126549"
          id="Fill-1"
        />
        <path
          d="M22.7192767,23.8269406 L11.9995,15.3846339 L1.28072332,23.8269406 C0.987729421,24.0576865 0.512739318,24.0576865 0.219745422,23.8269406 C-0.0732484741,23.5961947 -0.0732484741,23.2221186 0.219745422,22.9913727 L11.9995,13.7142857 L23.7802546,22.9913727 C24.0732485,23.2221186 24.0732485,23.5961947 23.7802546,23.8269406 C23.4872607,24.0576865 23.0122706,24.0576865 22.7192767,23.8269406"
          id="Fill-1"
          transform="translate(12.000000, 18.857143) rotate(-180.000000) translate(-12.000000, -18.857143) "
        />
      </g>
    </g>
  </svg>
);

export default ExpandSVG;
