import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';
import { Tab, Tabs } from '@pwc/appkit-react';

import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import styles from '../../shared/reports/reportParam.module.scss';
import { entityIdSelector } from '../../shared/store/selectors';
import getReportUrl from '../shared/getReportUrl';
import useReportParam from '../../shared/reports/useReportParam.hook';
import ComingSoonPage from '../../comingSoonPage/comingSoonPage.component';
import useReportData from '../../shared/reports/useReportData.hook';

import { TABS_TYPES, tabsDefinitions } from './entityAnalyticsReview.tabs';
import GridRenderer from './common/gridRenderer.component';

const endpointBasePath = getReportUrl('entity-analytics-review');
const incomeTaxSummaryEndpointBasePath = getReportUrl('ear-income-tax-summary');
const franchiseTaxSummaryEndpointBasePath = getReportUrl('ear-franchise-tax-summary');
const incomeBaseEndpointBasePath = getReportUrl('ear-income-base');
const franchiseBaseEndpointBasePath = getReportUrl('ear-franchise-base');

const EntityAnalyticsReview = () => {
  const entityId = useSelector(entityIdSelector);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [periodParam.paramValue, taxYearParam.paramValue],
    ),
    defaultParamValue: entityId,
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([taxYearParam, periodParam, entityIdParam]);

  const params = useMemo(
    () => ({
      taxYear: taxYearParam.paramValue,
      period: periodParam.paramValue,
      entityId: entityIdParam.paramValue,
    }),
    [taxYearParam.paramValue, periodParam.paramValue, entityIdParam.paramValue],
  );

  const incomeTaxSummaryReport = useReportData({
    endpointBasePath: incomeTaxSummaryEndpointBasePath,
    method: 'GET',
    isFetchingAnyParam,
    params,
  });

  const incomeTaxSummaryReportHeaderParams = [
    { key: 'profileDescription', label: 'Profile' },
    { key: 'filingGroupId', label: 'Filing Group' },
    { key: 'stateCode', label: 'State' },
  ];

  const fourRowsHeaderParams = [
    { key: 'taxYear', label: 'Tax Year' },
    { key: 'profileDescription', label: 'Profile' },
    { key: 'filingGroupId', label: 'Filing Group' },
    { key: 'stateCode', label: 'State' },
  ];

  const franchiseTaxSummaryReport = useReportData({
    endpointBasePath: franchiseTaxSummaryEndpointBasePath,
    method: 'GET',
    isFetchingAnyParam,
    params,
  });

  const franchiseBaseReport = useReportData({
    endpointBasePath: franchiseBaseEndpointBasePath,
    method: 'GET',
    isFetchingAnyParam,
    params,
  });

  const incomeBaseReport = useReportData({
    endpointBasePath: incomeBaseEndpointBasePath,
    method: 'GET',
    isFetchingAnyParam,
    params,
  });

  const runReports = useCallback(() => {
    incomeTaxSummaryReport.fetchData();
    franchiseTaxSummaryReport.fetchData();
    incomeBaseReport.fetchData();
    franchiseBaseReport.fetchData();
  }, [incomeTaxSummaryReport, franchiseTaxSummaryReport, incomeBaseReport, franchiseBaseReport]);

  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.INCOME_TAX_SUMMARY:
        return (
          <GridRenderer
            reportData={incomeTaxSummaryReport.reportData}
            headerParams={incomeTaxSummaryReportHeaderParams}
          />
        );
      case TABS_TYPES.INCOME_TAX_SUMMARY_PY:
        return <ComingSoonPage />;
      case TABS_TYPES.INCOME_BASE:
        return (
          <GridRenderer
            reportData={incomeBaseReport.reportData}
            headerParams={fourRowsHeaderParams}
          />
        );
      case TABS_TYPES.INCOME_BASE_PY:
        return <ComingSoonPage />;
      case TABS_TYPES.INCOME_APPT:
        return <ComingSoonPage />;
      case TABS_TYPES.INCOME_APPT_PY:
        return <ComingSoonPage />;
      case TABS_TYPES.FRANCHISE_TAX_SUMMARY:
        return (
          <GridRenderer
            reportData={franchiseTaxSummaryReport.reportData}
            headerParams={fourRowsHeaderParams}
          />
        );
      case TABS_TYPES.FRANCHISE_TAX_SUMMARY_PY:
        return <ComingSoonPage />;
      case TABS_TYPES.FRANCHISE_BASE:
        return (
          <GridRenderer
            reportData={franchiseBaseReport.reportData}
            headerParams={fourRowsHeaderParams}
          />
        );
      case TABS_TYPES.FRANCHISE_BASE_PY:
        return <ComingSoonPage />;
      case TABS_TYPES.FRANCHISE_APPT:
        return <ComingSoonPage />;
      case TABS_TYPES.FRANCHISE_APPT_PY:
        return <ComingSoonPage />;
      default:
        throw new Error('Unsupported tab type');
    }
  };

  const isFetchingAnyReportData =
    incomeTaxSummaryReport.isFetchingReportData &&
    franchiseTaxSummaryReport.isFetchingReportData &&
    incomeBaseReport.isFetchingReportData &&
    franchiseBaseReport.isFetchingReportData;

  // check one report's, because rest use the same params
  const areParamsReady = incomeBaseReport.areParamsReady;

  const showGrid =
    incomeTaxSummaryReport.showGrid &&
    franchiseTaxSummaryReport.showGrid &&
    incomeBaseReport.showGrid &&
    franchiseBaseReport.showGrid;

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} autoSelectFirstValue />
          <ReportParamDropdown label="Entity" param={entityIdParam} autoSelectFirstValue />
          <Button
            size="lg"
            onClick={runReports}
            className={styles.runReportBtn}
            disabled={!areParamsReady || isFetchingAnyReportData}
          >
            Run Report
          </Button>
        </div>
      </div>
      {showGrid && (
        <>
          <div className="navigation-tabs-wrapper">
            <div className="tabs-wrapper">
              <Tabs
                className="tabs-container"
                value={activeTab}
                onChange={handleTabChange}
                size="md"
              >
                {tabsDefinitions.map(({ label, type }, index) => (
                  <Tab id={type} value={type} label={label} key={index} />
                ))}
              </Tabs>
            </div>
          </div>
          <div className="row grid-row">
            <div className="col">{getActiveTabComponent()}</div>
          </div>
        </>
      )}
    </>
  );
};

export default EntityAnalyticsReview;
