import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchValidatedInformationalDataTypes,
  uploadInformationalDataTypes,
  RESET_VALIDATED_INFORMATIONAL_DATA,
} from './types';

export const fetchValidatedInformationalData = apiAction({
  types: fetchValidatedInformationalDataTypes,
  apiAction: ({ taxYear, period, rows }) =>
    api.fetchValidatedInformationalData({ taxYear, period, rows }),
});

export const uploadInformationalData = apiAction(
  {
    types: uploadInformationalDataTypes,
    apiAction: ({ taxYear, period, jurisdictionId, filingTypeId, businessEntityId, rows }) =>
      api.uploadInformationalData({
        taxYear,
        period,
        jurisdictionId,
        filingTypeId,
        businessEntityId,
        rows,
      }),
  },
  { successNotificationMessage: () => 'Updating informational data succeeded' },
);

export const resetValidatedInformationalData = () => ({
  type: RESET_VALIDATED_INFORMATIONAL_DATA,
});
