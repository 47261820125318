import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import useModal from '../../shared/hooks/useModal.hook';
import {
  taxYearSelector,
  periodSelector,
  consolidationIdSelector,
  jurisdictionIdSelector,
} from '../../shared/store/selectors';
import WarningModal from '../../shared/displayComponents/warningModal.component';
import useBooleanState from '../../shared/hooks/useBooleanState.hook';
import { useQueryConsolidatedGroupReturns } from '../../shared/queries/filingGroups';

import getColumnDefinitions from './filingGroups.columnDefinition';
import {
  filingGroupsSelector,
  isFetchingFilingGroupsDataSelector,
  isFetchingFilingGroupsOptionsSelector,
  filingGroupsOptionsSelector,
  consolidationReturnDefinitionDataSelector,
} from './store/selectors';
import {
  fetchFilingGroups,
  fetchFilingGroupsOptions,
  removeFilingGroupFromConsolidation,
} from './store/actions';
import AddFilingGroupFormContainer from './addFilingGroupForm.container';
import { UpdateReturnsConfigPanel } from './updateReturnsConfigPanel';

const WARNING_MODAL_MESSAGE_ON_ADD_MISSING_RETURN_DEFINITION =
  'Before adding a filing group to this consolidation, you must edit the consolidation and choose a value from the Filing Form Description field.';
const WARNING_MODAL_MESSAGE_ON_DELETE_MISSING_RETURN_DEFINITION =
  'Before removing a filing group from this consolidation, you must edit the consolidation and choose a value from the Filing Form Description field.';

const WARNING_MODAL_TITLE_ON_ADD = 'Error adding filing group';
const WARNING_MODAL_TITLE_ON_DELETE = 'Error removing filing group';

const getWarningModalMessageIncompleteReturnDefinition = consolId =>
  `You cannot save your changes because the return definition for the consolidation ${consolId} does not have a separate filing form value. Create a support ticket to report this issue.`;

const filterNotAdded = (filingGroupsToAdd, alreadyAddedFilingGroups) => {
  const alreadyAddedFilingGroupIds = alreadyAddedFilingGroups.map(
    filingGroup => filingGroup.filingGroupId,
  );

  return filingGroupsToAdd.filter(
    filingGroup => !alreadyAddedFilingGroupIds.includes(filingGroup.value),
  );
};

const ConsolidationFilingGroups = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const consolidationId = useSelector(consolidationIdSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const filingGroups = useSelector(filingGroupsSelector);
  const isFetchingFilingGroupsData = useSelector(isFetchingFilingGroupsDataSelector);
  const filingGroupsOptions = useSelector(filingGroupsOptionsSelector);
  const isFetchingFilingGroupsOptions = useSelector(isFetchingFilingGroupsOptionsSelector);
  const consolidationReturnDefinitionData = useSelector(consolidationReturnDefinitionDataSelector);

  const { showModal: showAddGroupModal, modalProps: addGroupModalProps } = useModal();
  const { showModal: showWarningModal, modalProps: warningModalProps } = useModal();
  const [isPanelVisible, showPanel, hidePanel] = useBooleanState(false);

  const [warningModalMessage, setWarningModalMessage] = useState(
    WARNING_MODAL_MESSAGE_ON_ADD_MISSING_RETURN_DEFINITION,
  );
  const [warningModalTitle, setWarningModalTitle] = useState(WARNING_MODAL_TITLE_ON_ADD);
  const [toBeDeletedFilingGroupId, setToBeDeletedFilingGroupId] = useState('');
  const [isDeletingFilingGroup, setIsDeletingFilingGroup] = useState(false);

  const {
    data: consolidatedGroupReturns,
    isLoading: isLoadingConsolidatedGroupReturns,
  } = useQueryConsolidatedGroupReturns({
    params: {
      consolidationId,
      taxYear,
      period,
      jurisdictionId,
      filingGroupId: toBeDeletedFilingGroupId,
    },
    enabled: Boolean(
      consolidationId && taxYear && period && jurisdictionId && toBeDeletedFilingGroupId,
    ),
  });

  useEffect(() => {
    if (taxYear && period) {
      dispatch(fetchFilingGroupsOptions({ taxYear, period }));
    }
  }, [dispatch, taxYear, period]);

  const onClickAddGroup = useCallback(() => {
    if (!consolidationReturnDefinitionData?.returnDefinitionId) {
      setWarningModalMessage(WARNING_MODAL_MESSAGE_ON_ADD_MISSING_RETURN_DEFINITION);
      setWarningModalTitle(WARNING_MODAL_TITLE_ON_ADD);
      showWarningModal();
      return;
    }

    if (
      !consolidationReturnDefinitionData?.nonFilingSeparateReturnDefId &&
      !consolidationReturnDefinitionData?.filingSeparateReturnDefId
    ) {
      setWarningModalMessage(getWarningModalMessageIncompleteReturnDefinition(consolidationId));
      setWarningModalTitle(WARNING_MODAL_TITLE_ON_ADD);
      showWarningModal();
      return;
    }
    showAddGroupModal();
  }, [showAddGroupModal, showWarningModal, consolidationId, consolidationReturnDefinitionData]);

  const doModifiedReturns = useCallback(
    async returns => {
      setIsDeletingFilingGroup(true);
      await dispatch(
        removeFilingGroupFromConsolidation({
          taxYear,
          period,
          filingGroupId: toBeDeletedFilingGroupId,
          consolidationId,
          body: {
            returns,
          },
        }),
      );

      setToBeDeletedFilingGroupId('');
      setIsDeletingFilingGroup(false);
      hidePanel();
      dispatch(
        fetchFilingGroups({
          taxYear,
          period,
          consolidationId,
          jurisdictionId,
        }),
      );
    },
    [
      dispatch,
      hidePanel,
      taxYear,
      period,
      jurisdictionId,
      consolidationId,
      toBeDeletedFilingGroupId,
    ],
  );

  const handleModifiedReturns = useCallback(
    async returns => {
      if (!consolidationReturnDefinitionData?.returnDefinitionId) {
        setWarningModalMessage(WARNING_MODAL_MESSAGE_ON_DELETE_MISSING_RETURN_DEFINITION);
        setWarningModalTitle(WARNING_MODAL_TITLE_ON_DELETE);
        showWarningModal();
        return;
      }

      if (
        !consolidationReturnDefinitionData?.nonFilingSeparateReturnDefId &&
        !consolidationReturnDefinitionData?.filingSeparateReturnDefId
      ) {
        setWarningModalMessage(getWarningModalMessageIncompleteReturnDefinition(consolidationId));
        setWarningModalTitle(WARNING_MODAL_TITLE_ON_DELETE);
        showWarningModal();
        return;
      }

      if (!consolidatedGroupReturns.length) {
        await doModifiedReturns(returns);
        return;
      }

      dispatch(
        showConfirmModal({
          title: 'Removing filing group from consolidation',
          text: `You are removing ${toBeDeletedFilingGroupId} from ${consolidationId}.  SLT will convert all associated returns from non-filing to filing. To delete a return, see the Setup Tax Returns screen for a specific entity.`,
          confirmCallback: async () => {
            await doModifiedReturns(returns);
          },
        }),
      );
    },
    [
      dispatch,
      showWarningModal,
      doModifiedReturns,
      consolidationReturnDefinitionData?.returnDefinitionId,
      consolidationReturnDefinitionData?.nonFilingSeparateReturnDefId,
      consolidationReturnDefinitionData?.filingSeparateReturnDefId,
      consolidationId,
      toBeDeletedFilingGroupId,
      consolidatedGroupReturns,
    ],
  );

  const openPanelForReturnsFilingMethodCheck = useCallback(
    row => {
      setToBeDeletedFilingGroupId(row.filingGroupId);
      showPanel();
    },
    [showPanel],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: openPanelForReturnsFilingMethodCheck,
      }),
    [openPanelForReturnsFilingMethodCheck],
  );

  return (
    <>
      {hasUserPermissionsToEdit ? (
        <div className="row">
          <div className="col add-button-column">
            <Button
              size="lg"
              className="add-button"
              onClick={onClickAddGroup}
              disabled={false}
              isLoading={isFetchingFilingGroupsData}
            >
              Add Group
            </Button>
          </div>
        </div>
      ) : null}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={filingGroups}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingFilingGroupsData || isFetchingFilingGroupsOptions}
          />
        </div>
        <AddFilingGroupFormContainer
          {...addGroupModalProps}
          taxYear={taxYear}
          period={period}
          filingGroupsOptions={filterNotAdded(filingGroupsOptions, filingGroups)}
          consolidationId={consolidationId}
          jurisdictionId={jurisdictionId}
        />
      </div>
      <UpdateReturnsConfigPanel
        isPanelVisible={isPanelVisible}
        hidePanel={hidePanel}
        data={consolidatedGroupReturns}
        isLoading={isLoadingConsolidatedGroupReturns}
        handleModifiedReturns={handleModifiedReturns}
        setToBeDeletedFilingGroupId={setToBeDeletedFilingGroupId}
        isSaving={isDeletingFilingGroup}
      />
      <WarningModal
        {...warningModalProps}
        title={warningModalTitle}
        warningMessage={warningModalMessage}
        dismissText="Ok"
      />
    </>
  );
};

ConsolidationFilingGroups.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default ConsolidationFilingGroups;
