import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button } from '@pwc/appkit-react/lib/Button';
import { saveAs } from 'file-saver';
import { DEFAULT_EXCEL_FILE_FORMAT } from '@common-packages/shared-constants';

import globalContextPropTypes from '../../shared/propTypes/globalContext';
import Loading from '../../shared/displayComponents/loading.component';
import styles from '../../shared/reports/reportParam.module.scss';
import {
  isFetchingGlobalContextSelector,
  globalContextSelector,
} from '../../shared/store/selectors';

import { cleanAfterTrgReport, runTrgReport, fetchTrgReport } from './store/actions';
import {
  trgReportSelector,
  isFetchingTrgReportSelector,
  isFetchingTrgReportResultSelector,
} from './store/selectors';
import UserDefinedParams from './TrgReport/userDefinedParams.container';

const getAreParamsReady = (paramsValues, queryParams) => {
  const areValuesInitialized = Object.keys(paramsValues).length === Object.keys(queryParams).length;
  const areValuesSet = Object.values(paramsValues).every(value => value !== null);

  return areValuesInitialized && areValuesSet;
};

const getParamsValuesHasEmptyArray = paramsValues =>
  Object.values(paramsValues).some(value => Array.isArray(value) && value.length === 0);

const ViewTrgReport = ({
  match,
  globalContext,
  isFetchingGlobalContext,
  runTrgReport,
  fetchTrgReport,
  cleanAfterTrgReport,
  trgReport,
  isFetchingTrgReport,
  isFetchingTrgReportResult,
}) => {
  const { reportId } = match.params;
  useEffect(() => {
    fetchTrgReport({ id: reportId });
    return () => {
      cleanAfterTrgReport();
    };
  }, [fetchTrgReport, cleanAfterTrgReport, reportId]);

  const [paramsValues, setParamsValues] = useState({});

  const { queryParams = [], trgId = '', reportFileFormat = DEFAULT_EXCEL_FILE_FORMAT } =
    trgReport?.reportDefinition || {};

  const runReport = useCallback(async () => {
    const response = await runTrgReport({ params: paramsValues, trgId, reportFileFormat });

    if (!response) {
      return;
    }

    const data = new Blob([response]);
    saveAs(data, `TRG-Report-${trgId}.${reportFileFormat}`);
  }, [runTrgReport, paramsValues, trgId, reportFileFormat]);

  const areParamsReady = useMemo(() => getAreParamsReady(paramsValues, queryParams), [
    paramsValues,
    queryParams,
  ]);

  const paramsValuesHasEmptyArray = useMemo(() => getParamsValuesHasEmptyArray(paramsValues), [
    paramsValues,
  ]);

  return (
    <Loading isLoading={isFetchingTrgReport || isFetchingGlobalContext}>
      <h4>{trgReport && trgReport.name}</h4>
      <div className="row">
        <div className={classNames('col', styles.reportParam)}>
          <UserDefinedParams
            reportId={reportId}
            queryParams={queryParams}
            onChange={setParamsValues}
            getAreParamsReady={getAreParamsReady}
            globalContext={globalContext}
          />

          <Button
            size="lg"
            onClick={runReport}
            disabled={!areParamsReady || paramsValuesHasEmptyArray}
            isLoading={isFetchingTrgReportResult}
          >
            Run Report
          </Button>
        </div>
      </div>
    </Loading>
  );
};

ViewTrgReport.propTypes = {
  globalContext: globalContextPropTypes,
  isFetchingGlobalContext: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      objectId: PropTypes.string,
      reportId: PropTypes.string,
      reportType: PropTypes.string,
    }).isRequired,
  }).isRequired,
  fetchTrgReport: PropTypes.func.isRequired,
  cleanAfterTrgReport: PropTypes.func.isRequired,
  runTrgReport: PropTypes.func.isRequired,
  isFetchingTrgReport: PropTypes.bool.isRequired,
  isFetchingTrgReportResult: PropTypes.bool.isRequired,
  trgReport: PropTypes.shape({
    reportId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    reportDefinition: PropTypes.shape({
      queryParams: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          mappingName: PropTypes.string.isRequired,
        }),
      ),
    }),
  }),
};

export default connect(
  state => ({
    globalContext: globalContextSelector(state),
    isFetchingGlobalContext: isFetchingGlobalContextSelector(state),
    trgReport: trgReportSelector(state),
    isFetchingTrgReport: isFetchingTrgReportSelector(state),
    isFetchingTrgReportResult: isFetchingTrgReportResultSelector(state),
  }),
  {
    runTrgReport,
    fetchTrgReport,
    cleanAfterTrgReport,
  },
)(ViewTrgReport);
