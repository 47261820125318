import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { entityIdSelector, periodSelector, taxYearSelector } from '../../../shared/store/selectors';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';

import {
  isFetchingCategoryDetailsSelector,
  categoryDetailsWithTotalRowSelector,
  isFetchingContextSelector,
} from './store/selectors';
import { fetchCategoryDetails } from './store/actions';
import columnDefinitions from './categoryDetails.columnDefinitions';

const SltAmountDetails = ({
  match,
  taxYear,
  period,
  entityId,
  fetchCategoryDetails,
  categoryDetails,
  isFetchingCategoryDetails,
  isFetchingContext,
}) => {
  const { accountGroup } = match.params;

  useEffect(() => {
    const isContextReady = taxYear && period && entityId && accountGroup;

    if (isContextReady) {
      fetchCategoryDetails({ taxYear, period, entityId, accountGroup });
    }
  }, [taxYear, period, entityId, accountGroup, fetchCategoryDetails]);

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={categoryDetails}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingContext || isFetchingCategoryDetails}
        />
      </div>
    </div>
  );
};
SltAmountDetails.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  categoryDetails: PropTypes.arrayOf(
    PropTypes.shape({
      componentId: PropTypes.string,
      fedTaxAmount: PropTypes.number,
      bookAmount: PropTypes.number,
      reclassAmount: PropTypes.number,
      bkTxAmount: PropTypes.number,
      accountId: PropTypes.string,
      accountDescription: PropTypes.string,
      sourceSystemId: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isFetchingCategoryDetails: PropTypes.bool.isRequired,
  fetchCategoryDetails: PropTypes.func.isRequired,
  isFetchingContext: PropTypes.bool.isRequired,
  jurisdictionId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountGroup: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    categoryDetails: categoryDetailsWithTotalRowSelector(state),
    isFetchingCategoryDetails: isFetchingCategoryDetailsSelector(state),
    isFetchingContext: isFetchingContextSelector(state),
  }),
  {
    fetchCategoryDetails,
  },
)(SltAmountDetails);
