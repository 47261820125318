import { createSelector } from 'reselect';

export const genericScreenDefinitionSelector = ({ development }) =>
  development.genericScreenDefinition.genericScreenDefinition;
export const isFetchingGenericScreenDefinitionSelector = ({ development }) =>
  development.genericScreenDefinition.isFetchingGenericScreenDefinition;
export const isFetchingCategoriesSelector = ({ development }) =>
  development.genericScreenDefinition.isFetchingCategories;
export const isFetchingAccountsSelector = ({ development }) =>
  development.genericScreenDefinition.isFetchingAccounts;
export const isFetchingAllJurisdictionsSelector = ({ development }) =>
  development.genericScreenDefinition.isFetchingAllJurisdictions;

export const categoriesOptionsSelector = ({ development }) =>
  development.genericScreenDefinition.categories.map(({ categoryId }) => ({
    label: categoryId,
    value: categoryId,
  }));

export const accountsOptionsSelector = ({ development }) =>
  development.genericScreenDefinition.accounts.map(({ accountId }) => ({
    label: accountId,
    value: accountId,
  }));

export const allJurisdictionsOptionsSelector = ({ development }) =>
  development.genericScreenDefinition.allJurisdictions.map(
    ({ jurisdictionId, jurisdictionDescription }) => ({
      label: jurisdictionDescription,
      value: jurisdictionId,
    }),
  );

const toString = value => `${value === null ? '' : value}`;
export const genericScreenDefinitionRowDetailsSelector = ({ development }) =>
  development.genericScreenDefinition.genericScreenDefinitionRowDetails.map(row => ({
    ...row,
    rowId: toString(row.rowId),
    decimalPlaces: toString(row.decimalPlaces),
  }));
export const isFetchingGenericScreenDefinitionRowDetailsSelector = ({ development }) =>
  development.genericScreenDefinition.isFetchingGenericScreenDefinitionRowDetails;

export const screenIdSelector = ({ development }) => development.genericScreenDefinition.screenId;

const screenSelector = createSelector(
  [genericScreenDefinitionSelector, screenIdSelector],
  (genericScreenDefinition, screenId) =>
    genericScreenDefinition.find(screen => screen.screenId === screenId),
);

export const screenTitleSelector = createSelector([screenSelector], screen => screen?.title);
