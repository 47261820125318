import { connect } from 'react-redux';

import ReturnWorkspaceRoutes from '../../sharedSubPages/returnWorkspace/returnWorkspaceRoutes.component';
import {
  entityIdSelector,
  jurisdictionIdSelector,
  isFetchingGlobalContextSelector,
  consolidationProfileIdSelector,
} from '../../shared/store/selectors';

export default connect(state => ({
  orgId: entityIdSelector(state),
  profileId: consolidationProfileIdSelector(state),
  jurisdictionId: jurisdictionIdSelector(state),
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(ReturnWorkspaceRoutes);
