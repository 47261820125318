import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@pwc/appkit-react';

import Loading from '../../../shared/displayComponents/loading.component';
import DataInfo from '../../../shared/displayComponents/dataInfo/dataInfo.component';
import ParamDropdown from '../../../shared/displayComponents/paramDropdown/paramDropdown.component';
import {
  formIdSelector,
  formPageSelector,
  formPagesOptionsSelector,
  isFetchingFormsSelector,
  isFetchingFormHtmlSelector,
} from '../../store/selectors';
import { isPdfProcessedSelector } from '../store/selectors';
import { selectFormPage } from '../../store/actions';
import { isFetchingPDFFieldsByPageSelector } from '../../../shared/store/dataModels/selectors';
import headerStyles from '../../../shared/displayComponents/headerWithParamDropdowns/styles.module.scss';

import TaxFormView from './taxFormView.container';
import FieldsView from './fieldsView.component';
import {
  TABS_TYPES,
  tabsDefinitions,
  displayDropdownEntries,
  defaultDisplayDropdownValue,
} from './constants';
import styles from './styles.module.scss';

const Form = ({
  selectedFormFieldId = null,
  selectedFormFieldName = null,
  handleFormFieldChange,
  selectFormDetailTab,
  setIsFormDirty,
}) => {
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const [displayDropdownValue, setDisplayDropdownValue] = useState(defaultDisplayDropdownValue);

  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);
  const dispatch = useDispatch();

  const isFetchingPDFFieldsByPage = useSelector(isFetchingPDFFieldsByPageSelector);
  const formId = useSelector(formIdSelector);
  const pageList = useSelector(formPagesOptionsSelector);
  const selectedPage = useSelector(formPageSelector);
  const isFetchingTaxForms = useSelector(isFetchingFormsSelector);
  const isFetchingTaxFormHtml = useSelector(isFetchingFormHtmlSelector);
  const isPdfProcessed = useSelector(isPdfProcessedSelector);

  const isDisplayDropdownDisabled = activeTab === TABS_TYPES.FIELDS_VIEW;

  const handlePageSelect = useCallback(
    newPage => {
      if (selectedPage !== newPage) {
        dispatch(selectFormPage(newPage));
      }
    },
    [dispatch, selectedPage],
  );

  const handleDisplayFormChange = useCallback(displayDropdownId => {
    setDisplayDropdownValue(displayDropdownId);
  }, []);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.FORM_VIEW:
        return (
          <TaxFormView
            selectedFormFieldId={selectedFormFieldId}
            selectedFormFieldName={selectedFormFieldName}
            selectedDisplayFormValue={displayDropdownValue}
            handleFormFieldChange={handleFormFieldChange}
            selectFormDetailTab={selectFormDetailTab}
          />
        );
      case TABS_TYPES.FIELDS_VIEW:
        return (
          <FieldsView
            selectedFormFieldId={selectedFormFieldId}
            handleFormFieldChange={handleFormFieldChange}
            selectFormDetailTab={selectFormDetailTab}
            setIsFormDirty={setIsFormDirty}
          />
        );
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <>
      <div className={`navigation-tabs-wrapper  ${styles.formNavigationTabsWrapper}`}>
        <div className={`tabs-wrapper ${styles.formTabsWrapper}`}>
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
        </div>
        {isPdfProcessed && formId && (
          <div className={styles.dropdownsContainer}>
            <div className={headerStyles.headerParam}>
              <ParamDropdown
                className={styles.displayDropdown}
                label="Display"
                disabled={isDisplayDropdownDisabled}
                value={displayDropdownValue}
                options={displayDropdownEntries}
                handleChange={handleDisplayFormChange}
                isBusy={isFetchingTaxForms || isFetchingPDFFieldsByPage}
              />
            </div>
            <div className={styles.pageSection}>
              <Loading small isLoading={isFetchingTaxForms || isFetchingTaxFormHtml}>
                <div className={styles.pageDropdown}>
                  <ParamDropdown
                    label="Page"
                    value={selectedPage}
                    options={pageList}
                    handleChange={handlePageSelect}
                  />
                </div>
                <span className={styles.ofPages}>of {pageList.length}</span>
              </Loading>
            </div>
          </div>
        )}
      </div>
      {isPdfProcessed ? (
        formId && getActiveTabComponent()
      ) : (
        <DataInfo showSpinner showIcon={false} textToRender="PDF is getting processed" />
      )}
    </>
  );
};

Form.propTypes = {
  selectedFormFieldId: PropTypes.string,
  selectedFormFieldName: PropTypes.string,
  handleFormFieldChange: PropTypes.func.isRequired,
  selectFormDetailTab: PropTypes.func.isRequired,
  setIsFormDirty: PropTypes.func.isRequired,
};

export default Form;
