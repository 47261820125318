import { httpGetAndParse, httpPostFileAndParse } from '../../../utils/fetchService';
import stringify from '../../../utils/stringify';

export const uploadFileForGenericAccountDataUpload = ({
  taxYear,
  period,
  file,
  shouldReplaceAccountData,
  shouldAggregateData,
}) => {
  const body = new FormData();
  body.append('file', file);
  body.append('taxYear', taxYear);
  body.append('period', period);
  body.append('shouldReplaceAccountData', shouldReplaceAccountData);
  body.append('shouldAggregateData', shouldAggregateData);

  return httpPostFileAndParse({
    route: `/api/jim/generic-account-data-upload`,
    errorMessage: 'Uploading generic account data failed',
    body,
  });
};

export const fetchLastImportData = ({ hdrId, batchId, offset, sort }) =>
  httpGetAndParse({
    route: `/api/tools/generic-account-data/last-import?${stringify({
      hdrId,
      batchId,
      offset,
      sort,
    })}`,
    errorMessage: 'Fetching last import data failed',
  });
