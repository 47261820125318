import { dataTypes, ColumnTypes } from '@common-packages/shared-constants';

import {
  SelectVirtualizedCellEditor,
  SelectWithFetchCellEditor,
  AdminCellRendererFactory,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import {
  defaultColumnDefinition,
  adminColumnDefinition,
  defaultNoDataColumn,
} from '../../shared/columnDefinitions';
import { defaultSelectCellEditorSuppressKeyboardEvent } from '../../shared/columnDefinitions/constants';

const textAlignment = {
  [dataTypes.BOOLEAN]: 'center',
  [dataTypes.STRING]: 'left',
  [dataTypes.INTEGER]: 'right',
};

const YES_NO_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Neither', label: 'Neither' },
];

const NON_EDITABLE_FIELDS = ['filingAttributes', 'calcPreferences'];

const getColumnsBasedOnColumnBlueprint = ({
  updateRow,
  isInEditMode,
  column: columnBlueprint,
  selectOptions,
  fetchSelectOptions,
  rowsWithInvalidCells,
  duplicatedRowsIds,
  onCellValueChanged = ({ data, colDef: { cellEditorParams } }) => {
    if (columnBlueprint.dataType === dataTypes.BOOLEAN) {
      data[columnBlueprint.field] = Boolean(data[columnBlueprint.field]);
    }
    if (columnBlueprint.columnType === ColumnTypes.CALC_PREFERENCES) {
      const calcOption = cellEditorParams.calcPreferencesSelectOptionsForField.find(
        option => `${option.displayGroup}_${option.name}` === columnBlueprint.field,
      )?.displayGroup;
      // if unable to assign a calc option name then don't attempt to unassign previous set values
      if (calcOption) {
        // a user can set values for only one calc preference in a row
        // if a user tries to set a value in a calc preference, unassign values from all other
        // calc preferences
        Object.assign(
          data,
          Object.entries(cellEditorParams.calcPreferencesSelectOptions).reduce(
            (acc, [displayGroup, options]) => {
              if (
                cellEditorParams.calcPreferencesSelectOptionsForField.some(
                  calcPreferencesSelectOptionForField =>
                    calcPreferencesSelectOptionForField.displayGroup === displayGroup,
                )
              ) {
                return acc;
              }

              return {
                ...options.reduce(
                  (acc, curr) => ({
                    ...acc,
                    // unassign previous set value
                    [`${curr.displayGroup}_${curr.name}`]: null,
                  }),
                  {},
                ),
                ...acc,
              };
            },
            {},
          ),
        );
      }
    }
    updateRow(data);
  },
  displayNameAndSelectOptionDictionary,
  calcPreferencesSelectOptions,
  calcPreferencesSelectOptionsForField,
  fetchSelectOptionDictionary,
  onCellValueChangedDictionary,
}) => {
  if (columnBlueprint.children) {
    return {
      headerName: columnBlueprint.displayName,
      children: columnBlueprint.children.map(child =>
        getColumnsBasedOnColumnBlueprint({
          updateRow,
          isInEditMode,
          column: child,
          selectOptions:
            (child.columnType === ColumnTypes.CALC_PREFERENCES &&
              child.dataType === dataTypes.STRING &&
              YES_NO_OPTIONS) ||
            displayNameAndSelectOptionDictionary[child.field],
          fetchSelectOptions:
            fetchSelectOptionDictionary && fetchSelectOptionDictionary[child.field],
          onCellValueChanged:
            onCellValueChangedDictionary && onCellValueChangedDictionary[child.field],
          rowsWithInvalidCells,
          duplicatedRowsIds,
          displayNameAndSelectOptionDictionary,
          calcPreferencesSelectOptions,
          calcPreferencesSelectOptionsForField:
            calcPreferencesSelectOptions?.[columnBlueprint.field],
          fetchSelectOptionDictionary,
          onCellValueChangedDictionary,
        }),
      ),
    };
  }

  return {
    ...defaultColumnDefinition,
    headerName: columnBlueprint.displayName,
    field: columnBlueprint.field,
    editable: isInEditMode && !NON_EDITABLE_FIELDS.includes(columnBlueprint.field),
    cellEditor: TextCellEditor,
    cellEditorParams: {
      calcPreferencesSelectOptions,
      calcPreferencesSelectOptionsForField,
    },
    onCellValueChanged,
    width:
      columnBlueprint.width ||
      (columnBlueprint.dataType === dataTypes.BOOLEAN ? 85 : columnBlueprint.width || 100),
    minWidth:
      columnBlueprint.minWidth ||
      (columnBlueprint.dataType === dataTypes.BOOLEAN ? 85 : columnBlueprint.minWidth || 100),
    cellStyle: ({ data }) => ({
      textAlign: textAlignment[columnBlueprint.dataType],
      backgroundColor: rowsWithInvalidCells.find(
        ({ rowId, invalidCells }) =>
          rowId === data.rowId && invalidCells.includes(columnBlueprint.field),
      )
        ? 'red'
        : null,
      border: duplicatedRowsIds.find(rowId => rowId === data.rowId) ? '2px solid red' : null,
    }),
    ...(selectOptions && {
      cellEditor: SelectVirtualizedCellEditor,
      cellEditorPopup: true,
      cellEditorParams: {
        options: selectOptions,
        calcPreferencesSelectOptions,
        calcPreferencesSelectOptionsForField,
      },
      suppressKeyboardEvent: defaultSelectCellEditorSuppressKeyboardEvent,
    }),
    ...(fetchSelectOptions && {
      cellEditor: SelectWithFetchCellEditor,
      cellEditorPopup: true,
      suppressKeyboardEvent: defaultSelectCellEditorSuppressKeyboardEvent,
      cellEditorParams: { fetchOptions: fetchSelectOptions },
    }),
    ...(columnBlueprint.dataType === dataTypes.BOOLEAN && {
      valueGetter: ({ data, colDef }) => (data[colDef.field] ? 'X' : ''),
      valueParser: ({ newValue }) => Boolean(newValue),
    }),
    hide: columnBlueprint.hide,
    suppressMenu: false,
    filter: true,
    menuTabs: ['filterMenuTab'],
  };
};

const columnDefinitions = ({
  updateRow,
  onDeleteIconClick,
  isInEditMode,
  columnsBlueprint,
  displayNameAndSelectOptionDictionary,
  fetchSelectOptionDictionary = null,
  onCellValueChangedDictionary,
  validationResult,
}) => {
  // fallback for no data to show user "no rows to show"
  if (!columnsBlueprint.length) {
    return [defaultNoDataColumn];
  }
  const { rowsWithInvalidCells = [], duplicatedRowsIds = [] } = validationResult;

  const calcPreferencesSelectOptions =
    columnsBlueprint.find(({ columnType }) => columnType === ColumnTypes.CALC_PREFERENCES)
      ?.selectOptions || [];

  const columns = columnsBlueprint.map(column => {
    return getColumnsBasedOnColumnBlueprint({
      updateRow,
      isInEditMode,
      column,
      selectOptions: displayNameAndSelectOptionDictionary[column.field],
      fetchSelectOptions: fetchSelectOptionDictionary && fetchSelectOptionDictionary[column.field],
      onCellValueChanged:
        onCellValueChangedDictionary && onCellValueChangedDictionary[column.field],
      rowsWithInvalidCells,
      duplicatedRowsIds,
      displayNameAndSelectOptionDictionary,
      calcPreferencesSelectOptions,
      fetchSelectOptionDictionary,
      onCellValueChangedDictionary,
    });
  });

  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: row => row.isAddedRow && isInEditMode,
  });

  return isInEditMode
    ? [
        {
          ...adminColumnDefinition,
          cellRenderer: AdminCellRenderer,
          editable: false,
        },
        ...columns,
      ]
    : columns;
};

export default columnDefinitions;
