import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { globalContextSelector, consolidationsSelector } from '../store/selectors';
import { setSltBinderId, sltBinderIdSelector } from '../store/context';
import { useQuerySltBinderOptions } from '../queries/binders';

const useSltBinder = () => {
  const dispatch = useDispatch();
  const globalContext = useSelector(globalContextSelector);
  const sltBinderId = useSelector(sltBinderIdSelector);
  const consolidations = useSelector(consolidationsSelector);

  const { consolidationId, isConsolidated } = globalContext;

  const isLatestConsolidationDataCorrect = useMemo(() => {
    const foundConsolidation = consolidations.find(
      consolidation => consolidation.consolidationId === consolidationId,
    );

    return Boolean((consolidationId !== 'NA' && foundConsolidation) || !isConsolidated);
  }, [consolidations, consolidationId, isConsolidated]);

  const sltBinders = useQuerySltBinderOptions({
    params: globalContext.apiRouteParamString,
    enabled: globalContext.isReady && isLatestConsolidationDataCorrect,
  });

  useEffect(() => {
    dispatch(setSltBinderId({ sltBinderId: null }));
  }, [dispatch, globalContext.apiRouteParamString]);

  useEffect(() => {
    const { defaultBinderId } = sltBinders.data;
    if (defaultBinderId && !sltBinderId) {
      dispatch(setSltBinderId({ sltBinderId: defaultBinderId }));
    }
  }, [dispatch, sltBinders.data, sltBinderId]);
};

export default useSltBinder;
