import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from '@pwc/appkit-react';

import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../shared/editMode';
import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import { SelectOptionPropTypes } from '../shared/forms/propTypes';
import useGroupingGrid from '../shared/hooks/useGroupingGrid.hook';

import {
  fetchPartners,
  fetchFlowThroughK1Details,
  selectPartnerId,
  updateFlowThroughK1Details,
} from './store/actions';
import {
  partnersOptionsSelector,
  flowThroughK1DetailsSelector,
  isFetchingFlowThroughK1DetailsSelector,
  isFetchingPartnersSelector,
  partnerIdSelector,
  isUpdatingFlowThroughK1DetailsSelector,
  isAnyK1DetailsSubcategorySelector,
} from './store/selectors';
import { flowThroughK1DetailsPropTypes } from './propTypes';
import getColumnDefinitions from './flowThroughK1Details.columnDefinitions';
import styles from './flowThroughK1Details.module.scss';

const typesOptions = [
  { value: 'FORM', label: 'Form' },
  { value: 'ATTACH', label: 'Attachment' },
];

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const FlowThroughK1Details = withContextWrapper()(
  ({
    globalContext,

    partners,
    flowThroughK1Details,
    isFetchingFlowThroughK1Details,
    isFetchingPartners,
    fetchPartners,
    partnerId,
    selectPartnerId,
    updateFlowThroughK1Details,
    fetchFlowThroughK1Details,
    isUpdatingFlowThroughK1Details,
    isAnyK1DetailsSubcategory,

    hasUserPermissionsToEdit,
  }) => {
    const { taxYear, period, businessEntityId, jurisdictionId } = globalContext.params;

    const [screenType, setScreenType] = useState(typesOptions[0].value);

    const saveChanges = useCallback(
      async ({ rowsPairsWithChanges }) => {
        const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
          taxYear: oldRow.taxYear,
          period: oldRow.period,
          entityId: oldRow.entityId,
          jurisdictionId: oldRow.jurisdictionId,
          accountJurisdictionId: oldRow.accountJurisdictionId,
          accountId: oldRow.accountId,
          begEndFlag: oldRow.begEndFlag,
          adjustment: newRow.adjustment,
          partnerId: oldRow.partnerId,
        }));
        await updateFlowThroughK1Details({
          taxYear,
          period,
          businessEntityId,
          jurisdictionId,
          rowsToUpdate,
        });
        fetchFlowThroughK1Details({
          taxYear,
          period,
          businessEntityId,
          jurisdictionId,
          screenType,
          partnerId,
        });
      },
      [
        businessEntityId,
        fetchFlowThroughK1Details,
        jurisdictionId,
        partnerId,
        period,
        screenType,
        taxYear,
        updateFlowThroughK1Details,
      ],
    );

    useEffect(() => {
      fetchPartners({
        taxYear,
        period,
        businessEntityId,
      });
    }, [businessEntityId, fetchPartners, period, taxYear]);

    useEffect(() => {
      if (screenType && partnerId) {
        fetchFlowThroughK1Details({
          taxYear,
          period,
          businessEntityId,
          jurisdictionId,
          screenType,
          partnerId,
        });
      }
    }, [
      businessEntityId,
      fetchFlowThroughK1Details,
      jurisdictionId,
      partnerId,
      period,
      screenType,
      taxYear,
    ]);

    const isLoading = isFetchingFlowThroughK1Details || isUpdatingFlowThroughK1Details;

    const {
      navigationPrompt,
      isInEditMode,
      editModeButtons,
      clonedRowData,
      updateRow,
      onGridReady,
    } = useRowEditMode({
      onSave: saveChanges,
      rowData: flowThroughK1Details,
      getUniqueRowId,
      editButtonDisabled: isLoading || isFetchingPartners,
    });

    const {
      gridKey,
      showCategorySubtotals,
      rowClassRules,
      aggFuncs,
      GroupRowRenderer,
      getGroupingCellClassRules,
      isTotalRow,
      toggleShowCategorySubtotals,
      getRowHeight,
      initialGroupOrderComparator,
    } = useGroupingGrid({ aggColumnKey: 'k1ItemDescription' });

    const columnDefinitions = useMemo(
      () =>
        getColumnDefinitions({
          updateRow,
          isInEditMode,
          getGroupingCellClassRules,
          isTotalRow,
        }),
      [isInEditMode, updateRow, getGroupingCellClassRules, isTotalRow],
    );

    const handlePartnerSelect = useCallback(
      option => {
        if (option) {
          selectPartnerId(option.value);
        }
      },
      [selectPartnerId],
    );

    return (
      <>
        {navigationPrompt}
        <div className="row">
          <div className={`col ${styles.gridOptions}`}>
            {isAnyK1DetailsSubcategory && (
              <Tooltip content="Show subcategories for displayed items" placement="top">
                <Checkbox
                  className={styles.showCategorySubtotalsCheckbox}
                  checked={showCategorySubtotals}
                  onChange={toggleShowCategorySubtotals}
                  disabled={isLoading}
                >
                  Show Category Subtotals
                </Checkbox>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4">
            <SDKCustomSelect
              className="sdk-custom-select form-select"
              options={typesOptions}
              onChange={useCallback(({ value }) => setScreenType(value), [setScreenType])}
              value={screenType}
              appkitLabel="Type"
            />
          </div>
          <div className="col-4">
            <SDKCustomSelect
              className="sdk-custom-select form-select"
              options={partners}
              onChange={handlePartnerSelect}
              value={partnerId}
              isLoading={isFetchingPartners}
              appkitLabel="Partner"
            />
          </div>
          {hasUserPermissionsToEdit && (
            <div className="col-4 add-button-column">{editModeButtons}</div>
          )}
        </div>
        <div className="row grid-row">
          <div className="col">
            <AgGrid
              className="generic-category-grid"
              key={gridKey}
              rowData={clonedRowData}
              columnDefs={columnDefinitions}
              isGridLoading={isLoading}
              onGridReady={onGridReady}
              groupIncludeFooter={showCategorySubtotals}
              aggFuncs={aggFuncs}
              getRowHeight={getRowHeight}
              rowClassRules={rowClassRules}
              initialGroupOrderComparator={initialGroupOrderComparator}
              groupRowRenderer={GroupRowRenderer}
              groupDisplayType={'groupRows'}
              groupDefaultExpanded={-1}
              suppressAggFuncInHeader
              singleClickEdit
              stopEditingWhenCellsLoseFocus
              suppressCellFocus={!isInEditMode}
            />
          </div>
        </div>
      </>
    );
  },
);

FlowThroughK1Details.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  partners: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  flowThroughK1Details: PropTypes.arrayOf(flowThroughK1DetailsPropTypes),
  isFetchingFlowThroughK1Details: PropTypes.bool.isRequired,
  isFetchingPartners: PropTypes.bool.isRequired,
  fetchPartners: PropTypes.func.isRequired,
  partnerId: PropTypes.string,
  selectPartnerId: PropTypes.func.isRequired,
  updateFlowThroughK1Details: PropTypes.func.isRequired,
  fetchFlowThroughK1Details: PropTypes.func.isRequired,
  isUpdatingFlowThroughK1Details: PropTypes.bool.isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    partners: partnersOptionsSelector(state),
    flowThroughK1Details: flowThroughK1DetailsSelector(state),
    isFetchingFlowThroughK1Details: isFetchingFlowThroughK1DetailsSelector(state),
    isFetchingPartners: isFetchingPartnersSelector(state),
    partnerId: partnerIdSelector(state),
    isUpdatingFlowThroughK1Details: isUpdatingFlowThroughK1DetailsSelector(state),
    isAnyK1DetailsSubcategory: isAnyK1DetailsSubcategorySelector(state),
  }),
  {
    fetchPartners,
    fetchFlowThroughK1Details,
    selectPartnerId,
    updateFlowThroughK1Details,
  },
)(FlowThroughK1Details);
