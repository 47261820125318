import { connect } from 'react-redux';

import { GlobalContextDropdowns } from '../shared/containerComponents/globalContext';

import { isFetchingContextSelector } from './store/selectors';

export const EntityDashboardGlobalContextDropdowns = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
}))(GlobalContextDropdowns);

export { default as EntityDashboard } from './entityDashboard.container';
export { default as EntityRoutes } from './entityRoutes.container';
