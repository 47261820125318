import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import VirtualizedSelectWithOptionsFetchFactory from '../../../shared/forms/virtualizedSelect/virtualizedSelectWithOptionsFetchFactory';
import { fetchConstants } from '../store/api';

const ConstantEditor = forwardRef((props, ref) => {
  const { taxYear, period, dataType } = props;
  const VirtualizedSelectWithOptionsFetch = VirtualizedSelectWithOptionsFetchFactory({
    fetchMethod: () => fetchConstants({ taxYear, period, dataType }),
  });

  return <VirtualizedSelectWithOptionsFetch {...props} forwardedRef={ref} />;
});

ConstantEditor.displayName = 'ConstantEditor';

ConstantEditor.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  dataType: PropTypes.string,
};

export default ConstantEditor;
