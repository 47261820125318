import React, { useEffect } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import TextArea from '../../shared/forms/textareaFormik/textareaFormik.component';
import CheckBox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';

import { orgIdOptionsPropTypes, filteredOrgIdOptionsPropTypes } from './propTypes';

const AddEditNexusNoteFormComponent = ({
  setFieldValue,
  jurisdictionDescription,
  orgIdOptions,
  filteredOrgIdOptions,
  isEditMode,
  values,
}) => {
  useEffect(() => {
    setFieldValue('jurisdictionDescription', jurisdictionDescription);
  }, [setFieldValue, jurisdictionDescription, isEditMode]);
  return (
    <form>
      <Field
        label="Prepared by"
        name="preparedBy"
        className="modal-field"
        disabled
        component={Input}
        autoComplete="off"
      />
      <Field
        label="Jurisdiction"
        name="jurisdictionDescription"
        className="modal-field"
        disabled
        component={Input}
        autoComplete="off"
      />
      <Select
        wrapperClassName="modal-field"
        appkitLabel="Org Id"
        name="orgId"
        options={isEditMode ? orgIdOptions : filteredOrgIdOptions}
        value={values.orgId}
        disabled={isEditMode}
      />
      <Field
        wrapperClassName="modal-field"
        label="Rollover to next year"
        name="rolloverToNextYear"
        component={CheckBox}
      />
      <Field className="modal-field" label="Note" name="note" component={TextArea} />
    </form>
  );
};

AddEditNexusNoteFormComponent.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  jurisdictionDescription: PropTypes.string,
  orgIdOptions: orgIdOptionsPropTypes,
  filteredOrgIdOptions: filteredOrgIdOptionsPropTypes,
  isEditMode: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    orgId: PropTypes.string,
    preparedBy: PropTypes.string,
    note: PropTypes.string,
    rolloverToNextYear: PropTypes.bool,
    noteId: PropTypes.number,
    shortDescription: PropTypes.string,
  }),
};

export default AddEditNexusNoteFormComponent;
