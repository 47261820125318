import { QueryKeys, useCustomQuery } from '.';

export const useQueryContentDeployments = ({ params, enabled, onSuccess }) =>
  useCustomQuery([QueryKeys.ContentDeployment.ContentDeployments, params], {
    url: `/api/content-deployment/${params.taxYear}/${params.period}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching content deployments failed',
    onSuccess,
  });
