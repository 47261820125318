import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../../shared/store/reducerFactory';
import getFieldValue from '../../../../utils/getFieldValue';
import { SELECT_TAX_YEAR } from '../../../../shared/store/types';
import { fetchJurisdictionsTypes, SELECT_JURISDICTION_ID } from '../types/shared';

export const sharedInitialState = {
  jurisdictionId: null,
  previousJurisdictionId: null,

  jurisdictions: [],
  isFetchingJurisdictions: false,
};

const sharedReducer = reducerFactory({
  initialState: sharedInitialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchJurisdictionsTypes,
      payloadKey: 'jurisdictions',
      fetchingKey: 'isFetchingJurisdictions',
      getAdditionalState: (state, payload) => ({
        jurisdictionId:
          state.jurisdictionId ||
          getFieldValue({
            currentValueFieldName: 'jurisdictionId',
            payloadFieldName: 'jurisdictionId',
            state,
            payload,
          }),
      }),
    }),
    [SELECT_TAX_YEAR]: state => ({
      ...state,
      jurisdictionId: null,
      previousJurisdictionId: state.jurisdictionId,
      profileId: null,
      previousProfileId: state.profileId,
    }),
    [SELECT_JURISDICTION_ID]: (state, payload) => ({
      ...state,
      jurisdictionId: payload,
      previousJurisdictionId: state.jurisdictionId,
      profileId: null,
      previousProfileId: state.profileId,
    }),
  },
});

export default sharedReducer;
