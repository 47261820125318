import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'hoLeid',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Type',
    field: 'entityType',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'LEID',
    field: 'leid',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars Account',
    field: 'starsAcct',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'TL Code',
    field: 'tlCode',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars Description',
    field: 'starsDescription',
    width: 300,
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Tax Amount',
    field: 'sumTaxAmt',
  },
];

export default columnDefinitions;
