import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import useReportData from '../../shared/reports/useReportData.hook';
import getReportUrl from '../../reports/shared/getReportUrl';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import styles from '../../shared/reports/reportParam.module.scss';
import useReportParam from '../../shared/reports/useReportParam.hook';
import { taxYearSelector } from '../../shared/store/selectors';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';

import columnDefinitions from './taxRateReport.columnDefinitions';

const reportName = 'tax-rate';
const taxYearParams = {};

const TaxRateReport = () => {
  const endpointBasePath = getReportUrl(reportName);

  const taxYear = useSelector(taxYearSelector);

  const taxYearParam = useReportParam({
    endpointBasePath,
    paramName: 'taxYear',
    defaultParamValue: taxYear,
    params: taxYearParams,
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([taxYearParam]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    isFetchingAnyParam,
    method: 'POST',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
      }),
      [taxYearParam.paramValue],
    ),
  });

  const { props, onGridReady } = useActionMenuForExport({ reportData });

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
              withSearchBar
              sideBar={defaultSideBarWithColumnsToolPanel}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TaxRateReport;
