import PropTypes from 'prop-types';

export const taxFormType = PropTypes.shape({
  formId: PropTypes.string,
  formName: PropTypes.string,
  formDescription: PropTypes.string,
  pdfLibPdf: PropTypes.string,
  bnaPdf: PropTypes.string,
  swpFormId: PropTypes.string,
  swpCalcIteration: PropTypes.string,
  createdBy: PropTypes.string,
  createdOn: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedOn: PropTypes.string,
});

export const filingAttributesProps = PropTypes.arrayOf(
  PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
);
