import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AppkitIcon from '../appkitIcon/appkitIcon.component';

import styles from './styles.module.scss';

const noop = () => null;

const BottomSlideIn = ({
  children,
  hide = noop,
  compactMode = false,
  isCompactIconSticky = false,
}) => (
  <div
    className={classNames(styles.bottomSlideInContainer, {
      [styles.bottomSlideInContainerCompact]: compactMode,
    })}
  >
    <AppkitIcon
      onClick={hide}
      className={`${styles.compactModeIcon} ${isCompactIconSticky ? styles.stickyIcon : ''}`}
      icon={compactMode ? 'up-chevron' : 'down-chevron'}
      size={14}
    />
    {children}
  </div>
);

BottomSlideIn.propTypes = {
  hide: PropTypes.func,
  compactMode: PropTypes.bool,
  isCompactIconSticky: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default BottomSlideIn;
