import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationUpdateMemberInfoDataFields = () =>
  useCustomMutation({
    handleData: ({ rows, taxYear, period, businessEntityId, jurisdictionId, filingTypeId }) => ({
      url: '/api/shared/member-informational-data',
      method: HttpMethods.PUT,
      body: { rows, taxYear, period, businessEntityId, jurisdictionId, filingTypeId },
    }),
    resetKey: QueryKeys.MemberInformationalData.Fields,
    successMessage: 'Member informational data updated successfully',
    errorMessage: 'Updating member informational data failed',
  });
