import React from 'react';
import { useSelector } from 'react-redux';

import SelectContextDataInfo from '../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import { globalContextSelector } from '../shared/store/selectors';
import { GlobalContext } from '../../../common/types/apiShapes';

import SetupTaxReturns from './separate/setupTaxReturns.container';
import ConsolidatedSetupTaxReturn from './consolidation/setupTaxReturns.container';

const SetupTaxReturnBase = () => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const { isSeparate, taxYear, period, entityId, filingTypeId } = globalContext;

  const isContextReady = Boolean(taxYear && period && filingTypeId && (!isSeparate || entityId));

  if (!isContextReady) {
    return <SelectContextDataInfo />;
  }

  return isSeparate ? <SetupTaxReturns /> : <ConsolidatedSetupTaxReturn />;
};

export default SetupTaxReturnBase;
