import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TaxYearDropdown from '../../displayComponents/contextDropdown/taxYearDropdown.component';
import { selectTaxYear } from '../../store/actions';
import { NotApplicableContextValue } from '../../constants';
import {
  taxYearOptionsSelector,
  taxYearSelector,
  isFetchingTaxYearsDataSelector,
} from '../../store/selectors';

const GlobalContextTaxYearDropdown = () => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const taxYears = useSelector(taxYearOptionsSelector);
  const isDropdownBusy = useSelector(isFetchingTaxYearsDataSelector);

  const handleSelectTaxYear = useCallback(
    newTaxYear => {
      if (newTaxYear !== NotApplicableContextValue) {
        dispatch(selectTaxYear(newTaxYear));
      }
    },
    [dispatch],
  );

  return (
    <TaxYearDropdown
      taxYears={taxYears}
      taxYear={taxYear}
      selectTaxYear={handleSelectTaxYear}
      isDropdownBusy={isDropdownBusy}
    />
  );
};

export default GlobalContextTaxYearDropdown;
