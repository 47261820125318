import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { createDataProvider } from '../../shared/dataProviders';
import { taxYearSelector } from '../../shared/store/selectors';
import { fetchJurisdictions } from '../taxRatesAndConstantsMaintenance/store/actions/shared';

const AdminDataProvider = ({ children, taxYear, fetchJurisdictions }) => {
  useEffect(() => {
    if (taxYear) {
      fetchJurisdictions({ taxYear });
    }
  }, [fetchJurisdictions, taxYear]);

  return children;
};

AdminDataProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,
};

export default createDataProvider({
  ProviderComponent: connect(
    state => ({
      taxYear: taxYearSelector(state),
    }),
    {
      fetchJurisdictions,
    },
  )(AdminDataProvider),
});
