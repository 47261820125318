import { apiAction } from '../apiAction';

import * as api from './api';
import {
  fetchPdfAttachmentsTypes,
  updateOrDeletePdfAttachmentsTypes,
  fetchPdfImageTypes,
  createPdfAttachmentTypes,
  fetchBinderAttachmentTypes,
} from './types';

export const fetchPdfAttachments = apiAction({
  types: fetchPdfAttachmentsTypes,
  apiAction: api.fetchPdfAttachments,
});

export const fetchPdfImage = apiAction({
  types: fetchPdfImageTypes,
  apiAction: api.fetchPdfImage,
});

export const updateOrDeletePdfAttachments = apiAction({
  types: updateOrDeletePdfAttachmentsTypes,
  apiAction: api.updateOrDeletePdfAttachments,
});

export const createPdfAttachment = apiAction(
  {
    types: createPdfAttachmentTypes,
    apiAction: api.createPdfAttachment,
  },
  {
    successNotificationMessage: () => 'Pdf Attachment has been successfully created',
  },
);

export const fetchBinderAttachment = apiAction({
  types: fetchBinderAttachmentTypes,
  apiAction: api.fetchBinderAttachment,
});
