import React, { useState, useMemo, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import { CalcPreferenceDataTypes } from '@common-packages/shared-constants';
import { SelectOption } from '@tls/slt-types';

import Checkbox from '../checkboxFormik/checkboxFormik.component';
import Radio from '../radioFormik/radioFormik.component';
import Select from '../sdkCustomSelect/sdkCustomSelect.component';

import styles from './calcPreferences.module.scss';

const YES_NO_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

interface CalcPreferencesSingleValueProps {
  calcPreferences: {
    selectOptions: Record<
      string,
      {
        displayName: string;
        name: string;
        dataType: string;
        displayGroup: string;
      }[]
    >;
    displayName: string;
  };
  values: { [key: string]: string };
}

const CalcPreferencesSingleValue = ({
  calcPreferences,
  values,
}: CalcPreferencesSingleValueProps) => {
  const { setFieldValue } = useFormikContext();

  const [calcOption, setCalcOption] = useState(() => {
    if (values.calcOptions) {
      return Object.keys(JSON.parse(values.calcOptions))?.[0] || '';
    }
    return '';
  });

  const calcPreferencesOptions = useMemo(() => calcPreferences?.selectOptions[calcOption] || [], [
    calcPreferences?.selectOptions,
    calcOption,
  ]);

  const clearCalcPrefencesValues = useCallback(
    (shouldSkipCurrentCalcOption = false) => {
      Object.entries(calcPreferences?.selectOptions || {}).forEach(
        ([calcPreferenceName, options]) => {
          if (shouldSkipCurrentCalcOption && calcPreferenceName === calcOption) {
            return;
          }
          options.forEach(option => {
            setFieldValue(`${option.displayGroup}_${option.name}`, null);
          });
        },
      );
    },
    [setFieldValue, calcOption, calcPreferences?.selectOptions],
  );

  const handleCalcOptionChange = useCallback(
    (option: SelectOption | null) => {
      setCalcOption(option?.value || '');
      if (!option) {
        clearCalcPrefencesValues();
      }
    },
    [clearCalcPrefencesValues],
  );

  const handleSingleFieldChange = useCallback(() => {
    clearCalcPrefencesValues(true);
  }, [clearCalcPrefencesValues]);

  return calcPreferences ? (
    <div className={styles.calcPreferencesBox}>
      <span className="a-h5">{calcPreferences.displayName}</span>
      <Select
        hideLabel
        options={Object.keys(calcPreferences.selectOptions).map(option => ({
          label: option,
          value: option,
        }))}
        value={calcOption}
        onChange={handleCalcOptionChange}
        virtualized
        isClearable
      />
      <div className={styles.calcPreferencesOptionsContainer}>
        {calcPreferencesOptions.map(item => (
          <div key={item.name} title={item.displayName}>
            {item.dataType === CalcPreferenceDataTypes.BOOLEAN ? (
              <Field
                name={`${item.displayGroup}_${item.name}`}
                component={Radio}
                options={YES_NO_OPTIONS}
                customChangeHandler={handleSingleFieldChange}
              />
            ) : (
              <Field
                label={item.displayName}
                name={`${item.displayGroup}_${item.name}`}
                component={Checkbox}
                customChangeHandler={handleSingleFieldChange}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default CalcPreferencesSingleValue;
