import { combineReducers } from 'redux';

import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import partnershipAmountDetailsReducer from '../partnershipAmountDetails/store/reducer';

import {
  fetchPartnershipFlowThroughAmountsTypes,
  FLOW_THROUGH_AMOUNTS_SET_ACCOUNT_ID,
  FLOW_THROUGH_AMOUNTS_SET_JURISDICTION_ID,
} from './types';

const partnershipFlowThroughAmountsReducer = reducerFactory({
  initialState: {
    partnershipFlowThroughAmounts: null,
    isFetchingPartnershipFlowThroughAmounts: false,
    accountId: null,
    jurisdictionId: null,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchPartnershipFlowThroughAmountsTypes,
      payloadKey: 'partnershipFlowThroughAmounts',
      fetchingKey: 'isFetchingPartnershipFlowThroughAmounts',
    }),

    [FLOW_THROUGH_AMOUNTS_SET_ACCOUNT_ID]: (state, payload) => ({
      ...state,
      accountId: payload,
    }),
    [FLOW_THROUGH_AMOUNTS_SET_JURISDICTION_ID]: (state, payload) => ({
      ...state,
      jurisdictionId: payload,
    }),
  },
});

export default combineReducers({
  root: partnershipFlowThroughAmountsReducer,
  partnershipAmountDetails: partnershipAmountDetailsReducer,
});
