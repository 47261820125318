import { createSelector } from 'reselect';

export const customReportsSelector = ({ admin }) => admin.runCustomReports.customReports;

export const isFetchingCustomReportsSelector = ({ admin }) =>
  admin.runCustomReports.isFetchingCustomReports;

export const customReportSelector = ({ admin }) => admin.runCustomReports.customReport;

export const isFetchingCustomReportSelector = ({ admin }) =>
  admin.runCustomReports.isFetchingCustomReport;

export const paramFieldSelector = ({ admin }, { name }) => admin.runCustomReports.paramFields[name];

export const paramFieldOptionsSelector = createSelector([paramFieldSelector], paramField =>
  paramField
    ? paramField.options.map(({ value, label }) => ({
        value: (value ?? '').toString(),
        label: (label ?? '').toString(),
      }))
    : [],
);

export const isFetchingParamFieldSelector = createSelector([paramFieldSelector], paramField =>
  paramField ? paramField.isFetching : false,
);

export const customReportResultSelector = ({ admin }) => admin.runCustomReports.customReportResult;

export const isFetchingCustomReportResultSelector = ({ admin }) =>
  admin.runCustomReports.isFetchingCustomReportResult;
