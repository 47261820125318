import PropTypes from 'prop-types';

export const generateTreeShapePropTypes = dataSchema => {
  const baseShape = {
    data: dataSchema,
  };
  baseShape.children = PropTypes.arrayOf(PropTypes.shape(baseShape)).isRequired;

  return PropTypes.arrayOf(PropTypes.shape(baseShape));
};
