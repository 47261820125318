import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import { BreadcrumbRoute } from '../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';
import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';

import ViewPrintAttachmentDetails from './viewPrintAttachmentDetails.container';

const ViewPrintAttachmentDetailsWithContext = withContextWrapper()(ViewPrintAttachmentDetails);

const ViewPrintAttachmentDetailsBreadcrumbRoute = () => (
  <BreadcrumbRoute path={Routes.viewPrintAttachmentDetails} />
);

const ViewPrintAttachmentDetailsPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: ViewPrintAttachmentDetailsBreadcrumbRoute,
}))(GlobalContextPage);

const ViewPrintAttachmentDetailsRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.viewPrintAttachmentDetails.MAIN}
    wrapper={ViewPrintAttachmentDetailsPage}
    component={ViewPrintAttachmentDetailsWithContext}
    hideGlobalContext
  />
);

export default ViewPrintAttachmentDetailsRoute;
