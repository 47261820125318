import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../../../config';

const FieldAttributesHelpLink = ({ className = 'float-right' }) => {
  const {
    DOCS_LINKS: { FIELD_ATTRIBUTES_HELP },
  } = config;

  return (
    <a href={FIELD_ATTRIBUTES_HELP} target="_blank" rel="noopener noreferrer" className={className}>
      Field Attributes Help
    </a>
  );
};

FieldAttributesHelpLink.propTypes = {
  className: PropTypes.string,
};

export default FieldAttributesHelpLink;
