import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { taxYearSelector, jurisdictionIdSelector } from '../store/selectors';
import {
  datasetsTreeSelector,
  dataModelSelector,
  dataModelsSelector,
} from '../../shared/store/dataModels/selectors';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import {
  fetchChildDatasets,
  fetchDataItems,
  deleteDataModel as deleteDataModelApi,
} from '../../shared/store/dataModels/api';
import { fetchDataModels, selectDataModel } from '../../shared/store/dataModels/actions';

import {
  isSomeDataItemUsedInGfunction,
  getChildDatasetsDataItems,
  areChildDatasetsUsedInForms,
} from './utils';

const aggregateDataModelData = dataModelData =>
  dataModelData.reduce(
    (acc, { rootDataset, childDatasets, someDataItemIsUsedInGfunction }) => ({
      rootDatasets: [...acc.rootDatasets, rootDataset],
      childDatasets: [...acc.childDatasets, ...childDatasets],
      someDataItemIsUsedInGfunction:
        acc.someDataItemIsUsedInGfunction || someDataItemIsUsedInGfunction,
    }),
    {
      rootDatasets: [],
      childDatasets: [],
      someDataItemIsUsedInGfunction: false,
    },
  );

const useDeleteDataModel = ({ showWarningDeleteDatasetModal, onDelete = () => null }) => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const dataModel = useSelector(dataModelSelector);
  const dataModels = useSelector(dataModelsSelector);
  const datasetsTree = useSelector(datasetsTreeSelector);

  const [isLoading, setIsLoading] = useState(false);

  const getDataModelData = useCallback(
    () =>
      Promise.all(
        datasetsTree.map(async ({ data }) => {
          const childDatasets = await fetchChildDatasets({ taxYear, datasetDefId: data.id });
          const dataItems = await fetchDataItems({ taxYear, datasetId: data.id });
          const someDataItemIsUsedInGfunction = isSomeDataItemUsedInGfunction([
            ...dataItems,
            ...getChildDatasetsDataItems(childDatasets),
          ]);

          return {
            rootDataset: { ...data, dataItems },
            childDatasets,
            someDataItemIsUsedInGfunction,
          };
        }),
      ),
    [datasetsTree, taxYear],
  );

  const deleteDataModel = useCallback(async () => {
    setIsLoading(true);
    const dataModelData = await getDataModelData();

    const { rootDatasets, childDatasets, someDataItemIsUsedInGfunction } = aggregateDataModelData(
      dataModelData,
    );
    setIsLoading(false);

    if (
      rootDatasets.some(({ forms }) => forms) ||
      someDataItemIsUsedInGfunction ||
      areChildDatasetsUsedInForms(childDatasets)
    ) {
      const datasetsWithChildDatasets = [...rootDatasets, ...childDatasets];
      showWarningDeleteDatasetModal(datasetsWithChildDatasets);
      return;
    }

    dispatch(
      showConfirmModal({
        title: 'Delete data model?',
        text: `Data Models should not be deleted if used in client data. Deleting the data model, will also remove all datasets and any data items that have been created. Are you sure ${dataModel.label} data model should be deleted?`,
        confirmCallback: async () => {
          await deleteDataModelApi({ taxYear, dataModelId: dataModel?.value });
          dispatch(
            selectDataModel(
              dataModels.find(({ value }) => value !== dataModel?.value)?.value || null,
            ),
          );
          dispatch(fetchDataModels({ taxYear, jurisdictionId }));

          onDelete();
        },
      }),
    );
  }, [
    dispatch,
    getDataModelData,
    showWarningDeleteDatasetModal,
    onDelete,
    dataModel,
    dataModels,
    taxYear,
    jurisdictionId,
  ]);

  return { deleteDataModel, isLoading };
};

export default useDeleteDataModel;
