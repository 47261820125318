import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import {
  fetchTaxYearsTypes,
  SELECT_TAX_YEAR,
  fetchPeriodsTypes,
  SELECT_PERIOD,
  fetchConsolidationsTypes,
  fetchJurisdictionsTypes,
  fetchJurisdictionsForAttachmentsAndBindersTypes,
  SELECT_JURISDICTION_ID,
  SELECT_GENERIC_FUNCTION,
  SET_PREVIOUS_PATHNAME,
  BLOCK_DEVELOPMENT_CONTEXT_CHANGES,
  SELECT_FORM,
  SELECT_FORM_PAGE_WITH_DROPDOWN,
  SELECT_FORM_PAGE_WITH_SCROLL,
  fetchFormsTypes,
  fetchFormHtmlImageTypes,
  RESET_FORM_HTML_IMAGE,
} from './types';
import { isFetchingConsolidationsSelector } from './selectors';

export const fetchTaxYears = apiAction({
  types: fetchTaxYearsTypes,
  apiAction: api.fetchTaxYears,
});

export const selectTaxYear = taxYear => ({
  type: SELECT_TAX_YEAR,
  payload: taxYear,
});

export const fetchPeriods = apiAction({
  types: fetchPeriodsTypes,
  apiAction: api.fetchPeriods,
});

export const selectPeriod = period => ({
  type: SELECT_PERIOD,
  payload: period,
});

export const fetchConsolidations = apiAction({
  types: fetchConsolidationsTypes,
  apiAction: api.fetchConsolidations,
  isFetchingSelector: isFetchingConsolidationsSelector,
});
export const fetchJurisdictions = apiAction({
  types: fetchJurisdictionsTypes,
  apiAction: api.fetchJurisdictions,
});

export const fetchJurisdictionsForAttachmentsAndBinders = apiAction({
  types: fetchJurisdictionsForAttachmentsAndBindersTypes,
  apiAction: api.fetchJurisdictionsForAttachmentsAndBinders,
});

export const selectJurisdiction = jurisdictionId => ({
  type: SELECT_JURISDICTION_ID,
  payload: jurisdictionId,
});

export const selectGenericFunction = genericFunction => ({
  type: SELECT_GENERIC_FUNCTION,
  payload: genericFunction,
});

export const setPreviousPathname = pathname => ({
  type: SET_PREVIOUS_PATHNAME,
  payload: pathname,
});

export const blockContextChanges = payload => ({
  type: BLOCK_DEVELOPMENT_CONTEXT_CHANGES,
  payload,
});

export const resetFormHtmlImage = () => ({
  type: RESET_FORM_HTML_IMAGE,
});

export const selectForm = formId => ({
  type: SELECT_FORM,
  payload: formId,
});

export const selectFormPage = pageNumber => ({
  type: SELECT_FORM_PAGE_WITH_DROPDOWN,
  payload: pageNumber,
});

export const selectFormPageWithScroll = pageNumber => ({
  type: SELECT_FORM_PAGE_WITH_SCROLL,
  payload: pageNumber,
});

export const fetchForms = apiAction({
  types: fetchFormsTypes,
  apiAction: api.fetchForms,
});
export const fetchFormHtmlImage = apiAction({
  types: fetchFormHtmlImageTypes,
  apiAction: api.fetchFormHtmlImage,
});
