import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';

import { fetchAccountDetailsTypes } from './types';

const accountDetailsReducer = reducerFactory({
  initialState: {
    accountDetails: [],
    isFetchingAccountDetails: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountDetailsTypes,
      payloadKey: 'accountDetails',
      fetchingKey: 'isFetchingAccountDetails',
    }),
  },
});

export default accountDetailsReducer;
