const noSpecialChar = {
  regex: /^[^"!#%^*~<>|@}'{]+$/,
  message: 'Field cannot contain these special characters: !#%^*~<>|@}\'"{',
};

const notNegativeInteger = {
  regex: /^(0|[1-9]\d*)$/,
  message: 'Field must contain a non-negative integer',
};

const alphanumericChar = {
  regex: /^[A-Za-z0-9-_]*$/,
  message: 'Letters, numbers, dashes and underscores are only allowed',
};

const alphanumericCharForEntity = {
  regex: /^[A-Za-z0-9_]*$/,
  message: 'Spaces and special characters are not allowed',
};

module.exports = {
  alphanumericChar,
  alphanumericCharForEntity,
  noSpecialChar,
  notNegativeInteger,
};
