import React from 'react';
import { connect } from 'react-redux';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../../shared/store/selectors';

import ConsolidationBreadcrumbRoutes from './consolidationBreadcrumbRoutes.component';

const ConsolidationPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: ConsolidationBreadcrumbRoutes,
}))(GlobalContextPage);

export const AuthorizedConsolidationRoute = props => (
  <AuthorizedCustomRoute wrapper={ConsolidationPage} {...props} />
);
