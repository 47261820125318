import React, { useCallback, useEffect, useRef } from 'react';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import TextArea from '../../../shared/forms/textareaFormik/textareaFormik.component';
import Checkbox from '../../../shared/forms/checkboxFormik/checkboxFormik.component';
import ExpressionValidationStatus from '../../../shared/displayComponents/expressionValidationStatus/expressionValidationStatus.component';

import insertTextWithOperatorAtPosition from './insertTextWithOperatorAtPosition';
import { messages, validator } from './propTypes';
import DiagnosticGrid from './diagnosticGrid.component';
import styles from './styles.module.scss';

const expressionTypes = {
  SEPARATE_EXPRESSION: 'Separate Expression',
  CONSOLIDATION_EXPRESSION: 'Consolidation Expression',
  NOTES: 'Notes',
};

const trimHighlightedExpressionText = (element, currentExpressionValue) =>
  currentExpressionValue.slice(0, element.selectionStart) +
  currentExpressionValue.slice(element.selectionEnd);

const ExpressionCalculationForm = ({
  onFormDirtyChange,
  isWarning = false,
  lastUpdated = null,
  validationDate = null,
  separateMessages = null,
  consolMessages = null,
  consolExpressionValidator = null,
  separateExpressionValidator = null,
  separateExpressionToAdd = '',
  consolExpressionToAdd = '',
  clearExpressionToAdd = () => null,
  clearConsolExpressionToAdd = () => null,
  dirtyDataItem = {},
  removeDirtyDataItem = () => null,
}) => {
  const separateExpressionRef = useRef(null);
  const consolExpressionRef = useRef(null);
  const textAreaElement = separateExpressionRef.current?.refs?.textarea;
  const consolTextAreaElement = consolExpressionRef.current?.refs?.textarea;

  const { setFieldValue, dirty, values, errors } = useFormikContext();

  const onChangeUseSameExpressionForConsol = useCallback(() => {
    setFieldValue('consolExpression', '');
  }, [setFieldValue]);

  useEffect(() => {
    if (
      consolExpressionToAdd &&
      values &&
      consolTextAreaElement &&
      !values.useSameExpressionForConsol
    ) {
      const newExpressionValue = insertTextWithOperatorAtPosition({
        currentText: trimHighlightedExpressionText(consolTextAreaElement, values.consolExpression),
        textToAdd: consolExpressionToAdd,
        position: consolTextAreaElement.selectionStart,
      });
      setFieldValue('consolExpression', newExpressionValue);
    }
    clearConsolExpressionToAdd();
  }, [
    clearConsolExpressionToAdd,
    setFieldValue,
    values,
    consolExpressionToAdd,
    consolTextAreaElement,
  ]);

  useEffect(() => {
    if (separateExpressionToAdd && values && textAreaElement) {
      const newExpressionValue = insertTextWithOperatorAtPosition({
        currentText: trimHighlightedExpressionText(textAreaElement, values.separateExpression),
        textToAdd: separateExpressionToAdd,
        position: textAreaElement.selectionStart,
      });
      setFieldValue('separateExpression', newExpressionValue);
    }
    clearExpressionToAdd();
  }, [clearExpressionToAdd, setFieldValue, values, separateExpressionToAdd, textAreaElement]);

  useEffect(() => {
    if (textAreaElement) {
      textAreaElement.selectionStart = textAreaElement.selectionEnd = textAreaElement.value.length;
    }
  }, [textAreaElement]);

  useEffect(() => {
    if (consolTextAreaElement) {
      consolTextAreaElement.selectionStart = consolTextAreaElement.selectionEnd =
        consolTextAreaElement.value.length;
    }
  }, [consolTextAreaElement]);

  useEffect(() => {
    onFormDirtyChange(dirty);
  }, [onFormDirtyChange, dirty]);

  const setDirtyFields = useCallback(() => {
    setFieldValue('useSameExpressionForConsol', dirtyDataItem.values.useSameExpressionForConsol);
    setFieldValue('separateExpression', dirtyDataItem.values.separateExpression);
    setFieldValue('consolExpression', dirtyDataItem.values.consolExpression);
    setFieldValue('notes', dirtyDataItem.values.notes);
    setFieldValue('lastUpdated', dirtyDataItem.values.lastUpdated);
    removeDirtyDataItem(dirtyDataItem.dataItemDefId);
  }, [setFieldValue, removeDirtyDataItem, dirtyDataItem]);

  useEffect(() => {
    if (values && dirtyDataItem.dataItemDefId) {
      setDirtyFields();
    }
  }, [setDirtyFields, removeDirtyDataItem, values, dirtyDataItem]);

  return (
    <form className={`col ${styles.expressions}`}>
      <div className={styles.expressionValidation}>
        <div className={styles.expressionLabels}>
          <label className="a-form-label">{expressionTypes.SEPARATE_EXPRESSION}</label>
          {separateExpressionValidator && (
            <ExpressionValidationStatus
              label="Separate Validation Status:"
              date={validationDate}
              isValid={separateExpressionValidator.isValid}
              isWarning={isWarning}
            />
          )}
        </div>
        <Field
          className="form-text"
          innerRef={separateExpressionRef}
          label={expressionTypes.SEPARATE_EXPRESSION}
          name="separateExpression"
          component={TextArea}
        />
        {separateMessages?.length > 0 && <DiagnosticGrid messages={separateMessages} />}
        <div className={styles.checkboxGroupWithErrors}>
          <div className={styles.checkboxGroup}>
            <Field
              label="Use same expression for consolidated calculation"
              name="useSameExpressionForConsol"
              component={Checkbox}
              customChangeHandler={onChangeUseSameExpressionForConsol}
            />
            <Field
              label="Do not allow expression calculation to be overridden"
              name="disallowUserOverride"
              component={Checkbox}
            />
          </div>
          {errors.useSameExpressionForConsol ? (
            <span className="invalid-feedback">{errors.useSameExpressionForConsol}</span>
          ) : null}
          {errors.disallowUserOverride ? (
            <span className="invalid-feedback">{errors.disallowUserOverride}</span>
          ) : null}
        </div>
      </div>
      <div className={styles.expressionValidation}>
        <div className={styles.expressionLabels}>
          <label className="a-form-label">{expressionTypes.CONSOLIDATION_EXPRESSION}</label>
          {consolExpressionValidator && (
            <ExpressionValidationStatus
              label="Consolidation Validation Status:"
              date={validationDate}
              isValid={consolExpressionValidator.isValid}
              isWarning={isWarning}
            />
          )}
        </div>
        <Field
          className="form-text"
          label={expressionTypes.CONSOLIDATION_EXPRESSION}
          name="consolExpression"
          innerRef={consolExpressionRef}
          component={TextArea}
          disabled={values.useSameExpressionForConsol}
        />
        {consolMessages?.length > 0 && <DiagnosticGrid messages={consolMessages} />}
      </div>
      <div className={`${styles.expressionValidation} ${styles.notes}`}>
        <div className={styles.expressionLabels}>
          <label className="a-form-label">{expressionTypes.NOTES}</label>
          {lastUpdated && (
            <span className="a-form-label">Expressions last updated: {lastUpdated}</span>
          )}
        </div>
        <Field
          className="form-text"
          label={expressionTypes.NOTES}
          name="notes"
          component={TextArea}
        />
      </div>
    </form>
  );
};

ExpressionCalculationForm.propTypes = {
  validationDate: PropTypes.string,
  lastUpdated: PropTypes.string,
  consolMessages: messages,
  separateMessages: messages,
  separateExpressionValidator: validator,
  consolExpressionValidator: validator,
  onFormDirtyChange: PropTypes.func.isRequired,
  separateExpressionToAdd: PropTypes.string,
  consolExpressionToAdd: PropTypes.string,
  clearExpressionToAdd: PropTypes.func,
  clearConsolExpressionToAdd: PropTypes.func,
  isWarning: PropTypes.bool,
  dirtyDataItem: PropTypes.shape({
    dataItemDefId: PropTypes.string,
    value: PropTypes.shape({
      consolExpression: PropTypes.string,
      lastUpdated: PropTypes.string,
      separateExpression: PropTypes.string,
      notes: PropTypes.string,
      useSameExpressionForConsol: PropTypes.bool,
    }),
  }),
  removeDirtyDataItem: PropTypes.func.isRequired,
};

export default ExpressionCalculationForm;
