import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import SelectContextDataInfo from '../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
  isEntityLockedSelector,
  isFetchingEntityLockIndicatorSelector,
  consolidationProFormaFilingGroupIdSelector,
} from '../../shared/store/selectors';
import { sltBinderIdSelector, clientIdSelector } from '../../shared/store/context';
import { useQuerySltBinderOptions } from '../../shared/queries/binders';
import {
  useQueryTtiYearId,
  useQueryTtiCaseId,
  useQueryTtiBinders,
} from '../../shared/queries/eFile';
import { fetchLockIndicator } from '../../shared/store/actions';
import {
  eFileLastSubmissionIdSelector,
  isFetchingEFileStepsDataSelector,
  eFileStepsDataSelector,
  persistedWizardFieldsValuesSelector,
  federalProformaSubmissionIdSelector,
  isFetchingFederalProformaSubmissionIdSelector,
} from '../store/selectors';
import {
  fetchEFileStepsData,
  resetContextSpecificWizardsState,
  fetchFederalProformaSubmissionId,
} from '../store/actions';
import LoadingSpinner from '../../shared/forms/loadingSpinner/loadingSpinner.component';
import Loading from '../../shared/displayComponents/loading.component';
import styles from '../styles.module.scss';

import GenerateXmlStep from './GenerateXmlStep/generateXmlStep.container';
import GenerateTransmissionStep from './generateTransmissionStep.container';
import AuthorizeStep from './authorizeStep.container';
import TransmitStep from './transmitStep.container';
import ReviewStatusStep from './reviewStatus.container';
import DownloadStepFile from './downloadStepFile.component';
// import OverallStatus from './overallStatus.container'; // TODO: Uncomment during https://jira.ist.pwc.com/browse/SLT-8888 bugfix
import useWizard from './useWizard.hook';
import { stateSubmissionOptions } from './constants';
import getUsersLastStep from './getUsersLastStep';
import setStatusForAllSteps from './setStatusForAllSteps';

const CurrentReturnFiling = () => {
  const dispatch = useDispatch();
  const globalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);

  const clientId = useSelector(clientIdSelector);

  const efServiceSubmissionId = useSelector(eFileLastSubmissionIdSelector);
  const isFetchingEFileStepsData = useSelector(isFetchingEFileStepsDataSelector);
  const eFileStepsData = useSelector(eFileStepsDataSelector);

  const sltBinderId = useSelector(sltBinderIdSelector);

  const isContextLocked = useSelector(isEntityLockedSelector);
  const isFetchingContextLockIndicator = useSelector(isFetchingEntityLockIndicatorSelector);

  const persistedWizardFieldsValues = useSelector(persistedWizardFieldsValuesSelector);

  const consolidationProFormaFilingGroupId = useSelector(
    consolidationProFormaFilingGroupIdSelector,
  );
  const importedFederalProformaSubmissionId = useSelector(federalProformaSubmissionIdSelector);
  const isFetchingProformaSubmissionId = useSelector(isFetchingFederalProformaSubmissionIdSelector);

  const [selectedSltBinderId, selectSltBinderId] = useState(null);
  const [stateSubmissionType, setStateSubmissionType] = useState(stateSubmissionOptions[0].value);
  const [includeFederal, setIncludeFederal] = useState(false);
  const [federalProformaSubmissionId, setFederalProformaSubmissionId] = useState('');

  const sltBinders = useQuerySltBinderOptions({
    params: globalContext.apiRouteParamString,
    enabled: globalContext.isReady,
  });

  const { data: ttiYearId, isFetching: isFetchingTtiYearId } = useQueryTtiYearId({
    params: { taxYear: globalContext.taxYear, period: globalContext.period },
    enabled: Boolean(globalContext.taxYear && globalContext.period),
  });

  const sltBinderName =
    sltBinders.data.sltBinderOptions.find(({ value }) => value === selectedSltBinderId)?.label ||
    null;

  const {
    data: ttiBinders,
    isFetching: isFetchingTtiBinders,
    refetch: reFetchTtiBinders,
  } = useQueryTtiBinders({
    params: { ...globalContext, sltBinderId: selectedSltBinderId || sltBinderId },
    enabled: Boolean(globalContext.isReady && (selectedSltBinderId || sltBinderId)),
  });

  const { binderFilingId = null, ttiBinderName = null, ttiBinderId: selectedTtiBinderId = null } =
    ttiBinders?.find(({ sltBinderId }) => sltBinderId === selectedSltBinderId) || {};

  const { data: ttiCaseData, isFetching: isFetchingTtiCaseId } = useQueryTtiCaseId({
    params: {
      taxYear: globalContext.params.taxYear,
      jurisdictionId: globalContext.params.jurisdictionId,
    },
    enabled: Boolean(globalContext.params.taxYear && globalContext.params.jurisdictionId),
  });

  const ttiCaseId = ttiCaseData?.ttiCaseId || null;
  const ttiCaseName = ttiCaseData?.ttiCaseName || null;
  const stateCode = ttiCaseData?.stateCode || null;

  const isLoading =
    isFetchingGlobalContext ||
    isFetchingTtiYearId ||
    isFetchingEFileStepsData ||
    isFetchingContextLockIndicator ||
    isFetchingTtiBinders ||
    isFetchingTtiCaseId ||
    isFetchingProformaSubmissionId;

  const isContextReady = Boolean(ttiYearId && ttiCaseId && selectedSltBinderId && sltBinderName);

  const { wizardButtons, wizardProgress, wizardState, setCurrentStep, setStepStatus } = useWizard({
    isLoading,
    isContextReady,
  });

  useEffect(
    () =>
      setStatusForAllSteps({
        eFileStepsData,
        setStepStatus,
        selectedTtiBinderId,
      }),
    [setStepStatus, eFileStepsData, selectedTtiBinderId],
  );

  useEffect(() => {
    const wizardsStep = getUsersLastStep({
      stepsData: eFileStepsData,
      taxYear: globalContext.taxYear,
      jurisdictionId: globalContext.jurisdictionId,
      filingTypeId: globalContext.filingTypeId,
    });
    setCurrentStep(wizardsStep);
  }, [
    setCurrentStep,
    eFileStepsData,
    globalContext.taxYear,
    globalContext.jurisdictionId,
    globalContext.filingTypeId,
  ]);

  useEffect(() => {
    if (globalContext.isReady) {
      dispatch(
        fetchLockIndicator({
          taxYear: globalContext.params.taxYear,
          period: globalContext.params.period,
          filingTypeId: globalContext.params.filingTypeId,
          orgId: globalContext.params.businessEntityId,
          jurisdictionId: globalContext.params.jurisdictionId,
        }),
      );
    }
  }, [dispatch, globalContext.isReady, globalContext.params]);

  useEffect(() => {
    selectSltBinderId(null);
    dispatch(resetContextSpecificWizardsState());

    return () => dispatch(resetContextSpecificWizardsState());
  }, [dispatch, globalContext.params]);

  useEffect(() => {
    if (sltBinderId && !sltBinders.isFetching && !selectedSltBinderId) {
      selectSltBinderId(sltBinderId);
    }
  }, [sltBinderId, sltBinders.isFetching, selectedSltBinderId]);

  const onSelectSltBinderId = useCallback(
    chosenSltBinderId => {
      selectSltBinderId(chosenSltBinderId);
    },
    [selectSltBinderId],
  );

  const fetchStepsData = useCallback(
    (binderId = binderFilingId) => {
      if (globalContext.isReady && binderId) {
        return dispatch(
          fetchEFileStepsData({
            ...globalContext.params,
            binderFilingId: binderId,
            sltBinderId: selectedSltBinderId || sltBinderId,
          }),
        );
      } else {
        dispatch(resetContextSpecificWizardsState());
      }
    },
    [dispatch, globalContext, binderFilingId, selectedSltBinderId, sltBinderId],
  );

  useEffect(() => {
    if (globalContext.isReady && isContextReady) {
      fetchStepsData();
    }
  }, [fetchStepsData, globalContext, isContextReady]);

  useEffect(() => {
    const { persistedStateSubmissionType, persistedIncludeFederal } = persistedWizardFieldsValues;

    if (persistedStateSubmissionType) {
      setStateSubmissionType(persistedStateSubmissionType);
    }

    if (persistedIncludeFederal !== null) {
      setIncludeFederal(persistedIncludeFederal);
    }
  }, [persistedWizardFieldsValues]);

  useEffect(() => {
    const { persistedFederalProformaSubmissionId } = persistedWizardFieldsValues;
    const federalProformaSubmissionId =
      persistedFederalProformaSubmissionId || importedFederalProformaSubmissionId;

    if (federalProformaSubmissionId) {
      setFederalProformaSubmissionId(federalProformaSubmissionId);
    }
  }, [
    setFederalProformaSubmissionId,
    importedFederalProformaSubmissionId,
    persistedWizardFieldsValues,
  ]);

  useEffect(() => {
    if (globalContext.isReady && consolidationProFormaFilingGroupId) {
      dispatch(
        fetchFederalProformaSubmissionId({
          taxYear: globalContext.params.taxYear,
          period: globalContext.params.period,
          proformaFilingGroupId: consolidationProFormaFilingGroupId,
        }),
      );
    }
  }, [dispatch, globalContext.isReady, globalContext.params, consolidationProFormaFilingGroupId]);

  const contextDebugInfo = {
    clientId,
    globalContext: globalContext.params,
    yearId: ttiYearId,
    caseId: ttiCaseId,
    binderId: selectedTtiBinderId,
    efServiceSubmissionId,
  };

  const getDownloadFileComponent = useCallback(
    ({ data }) => (
      <DownloadStepFile
        ttiYearId={ttiYearId}
        ttiCaseId={ttiCaseId}
        binderId={selectedTtiBinderId}
        efServiceSubmissionId={efServiceSubmissionId}
        data={data}
      />
    ),
    [ttiYearId, ttiCaseId, selectedTtiBinderId, efServiceSubmissionId],
  );

  if (!isLoading && !globalContext.isReady) {
    return <SelectContextDataInfo />;
  }

  const getActiveStepComponent = () => {
    switch (wizardState.currentStep) {
      case 1:
        return (
          <GenerateXmlStep
            binderFilingId={binderFilingId}
            ttiYearId={ttiYearId}
            ttiCaseId={ttiCaseId}
            ttiBinderName={ttiBinderName}
            reFetchTtiBinders={reFetchTtiBinders}
            jurisdictionStateCode={stateCode}
            selectedTtiBinderId={selectedTtiBinderId}
            selectedSltBinderId={selectedSltBinderId}
            sltBinderName={sltBinderName}
            efServiceSubmissionId={efServiceSubmissionId}
            fetchStepsData={fetchStepsData}
            contextDebugInfo={contextDebugInfo}
            getDownloadFileComponent={getDownloadFileComponent}
            isContextLocked={isContextLocked}
            wizardState={wizardState}
            isFetchingTtiBinders={isFetchingTtiBinders}
            setStepStatus={setStepStatus}
            isLoading={isLoading}
            isContextReady={isContextReady}
          />
        );
      case 2:
        return (
          <GenerateTransmissionStep
            binderFilingId={binderFilingId}
            ttiYearId={ttiYearId}
            ttiCaseId={ttiCaseId}
            selectedTtiBinderId={selectedTtiBinderId}
            efServiceSubmissionId={efServiceSubmissionId}
            isFetchingTtiBinders={isFetchingTtiBinders}
            fetchStepsData={fetchStepsData}
            contextDebugInfo={contextDebugInfo}
            getDownloadFileComponent={getDownloadFileComponent}
            isContextLocked={isContextLocked}
            wizardState={wizardState}
            setStepStatus={setStepStatus}
            stateSubmissionType={stateSubmissionType}
            setStateSubmissionType={setStateSubmissionType}
            includeFederal={includeFederal}
            setIncludeFederal={setIncludeFederal}
            isLoading={isLoading}
            federalProformaSubmissionId={federalProformaSubmissionId}
            setFederalProformaSubmissionId={setFederalProformaSubmissionId}
            isFetchingProformaSubmissionId={isFetchingProformaSubmissionId}
            sltBinderId={selectedSltBinderId}
          />
        );
      case 3:
        return (
          <AuthorizeStep
            binderFilingId={binderFilingId}
            ttiYearId={ttiYearId}
            ttiCaseId={ttiCaseId}
            selectedTtiBinderId={selectedTtiBinderId}
            efServiceSubmissionId={efServiceSubmissionId}
            fetchStepsData={fetchStepsData}
            contextDebugInfo={contextDebugInfo}
            isContextLocked={isContextLocked}
            wizardState={wizardState}
            isFetchingTtiBinders={isFetchingTtiBinders}
            setStepStatus={setStepStatus}
            stateSubmissionType={stateSubmissionType}
            includeFederal={includeFederal}
            federalProformaSubmissionId={federalProformaSubmissionId}
            isFetchingProformaSubmissionId={isFetchingProformaSubmissionId}
          />
        );
      case 4:
        return (
          <TransmitStep
            binderFilingId={binderFilingId}
            ttiYearId={ttiYearId}
            ttiCaseId={ttiCaseId}
            selectedTtiBinderId={selectedTtiBinderId}
            efServiceSubmissionId={efServiceSubmissionId}
            fetchStepsData={fetchStepsData}
            contextDebugInfo={contextDebugInfo}
            isContextLocked={isContextLocked}
            wizardState={wizardState}
            isFetchingTtiBinders={isFetchingTtiBinders}
            setStepStatus={setStepStatus}
            stateSubmissionType={stateSubmissionType}
            includeFederal={includeFederal}
            federalProformaSubmissionId={federalProformaSubmissionId}
            isFetchingProformaSubmissionId={isFetchingProformaSubmissionId}
          />
        );
      case 5:
        return (
          <ReviewStatusStep
            binderFilingId={binderFilingId}
            ttiYearId={ttiYearId}
            ttiCaseId={ttiCaseId}
            selectedTtiBinderId={selectedTtiBinderId}
            efServiceSubmissionId={efServiceSubmissionId}
            fetchStepsData={fetchStepsData}
            contextDebugInfo={contextDebugInfo}
            getDownloadFileComponent={getDownloadFileComponent}
            wizardState={wizardState}
            isFetchingTtiBinders={isFetchingTtiBinders}
            setStepStatus={setStepStatus}
            sltBinderId={selectedSltBinderId}
          />
        );
      default:
        throw new Error('Unsupported tab type');
    }
  };

  const isTtiBinderLoading =
    !ttiBinders || !selectedSltBinderId || isFetchingTtiCaseId || isFetchingTtiBinders;

  return (
    <div className="row">
      <div className="col">
        <LoadingSpinner isLoading={isFetchingGlobalContext} />
        <div className={styles.whiteBox}>
          <div className={`${styles.spaceBetween} ${styles.align}`}>
            <div className={styles.returnContext}>
              <div className={styles.caseIdField}>
                <label className="a-form-label">Filing Group</label>
                <Loading isLoading={isFetchingTtiCaseId} small>
                  <input className="a-input-text" value={ttiCaseName || ''} disabled />
                </Loading>
              </div>
              <ParamDropdown
                label="Binder"
                options={sltBinders.data.sltBinderOptions}
                value={selectedSltBinderId}
                handleChange={onSelectSltBinderId}
                isBusy={sltBinders.isFetching}
                isFetchingContext={wizardState.isWizardActionRunning}
              />
              <div className={styles.ttiBinderField}>
                <Loading isLoading={isTtiBinderLoading} small>
                  <input
                    className="a-input-text"
                    value={ttiBinderName || 'Efile Binder not found'}
                    disabled
                  />
                </Loading>
              </div>
              <div className={styles.submissionIdField}>
                <label className="a-form-label">EF-service ID</label>
                <Loading isLoading={isLoading} small>
                  <input className="a-input-text" value={efServiceSubmissionId || ''} disabled />
                </Loading>
              </div>
            </div>
          </div>
          {wizardProgress}
          {/* TODO: Uncomment during https://jira.ist.pwc.com/browse/SLT-8888 bugfix */}
          {/* <OverallStatus isLoading={isLoading} /> */}
          {getActiveStepComponent()}
          {wizardButtons}
        </div>
      </div>
    </div>
  );
};

export default CurrentReturnFiling;
