import { connect } from 'react-redux';

import { GlobalContextValue } from '../../../shared/containerComponents/globalContext';
import { entityIdSelector, isFetchingEntitiesSelector } from '../../../shared/store/selectors';

export default connect(state => ({
  label: 'Org Id',
  value: entityIdSelector(state),
  isBusy: isFetchingEntitiesSelector(state),
}))(GlobalContextValue);
