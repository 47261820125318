import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const accountOptionsSelector = ({ development }) =>
  development.calcSpecs.shared.options.accounts;

export const categoriesSelector = ({ development }) => development.calcSpecs.shared.categories;

export const categoryOptionsSelector = optionsSelectorFactory({
  listSelector: categoriesSelector,
  labelKey: 'categoryId',
  valueKey: 'categoryId',
});

export const isFetchingCategoriesSelector = ({ development }) =>
  development.calcSpecs.shared.isFetchingCategories;

export const entityOptionsSelector = ({ development }) =>
  development.calcSpecs.shared.options.entities;

export const datasetDefinitionOptionsSelector = ({ development }) =>
  development.calcSpecs.shared.options.datasetDefinitions;

export const groupedFilingAttributesSelector = ({ development }) =>
  development.calcSpecs.shared.options.groupedFilingAttributes;

export const isFetchingCalcSpecsOptionsSelector = ({ development }) =>
  development.calcSpecs.shared.isFetchingCalcSpecsOptions;

export const baseCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.baseCalcSpecType.calcSpecs.data;

export const baseCalcSpecsColumnsBlueprintSelector = ({ development }) =>
  development.calcSpecs.baseCalcSpecType.calcSpecs.columnsBlueprint;

export const isFetchingBaseCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.baseCalcSpecType.isFetchingCalcSpecs;

export const isUpdatingBaseCalcSpecSelector = ({ development }) =>
  development.calcSpecs.baseCalcSpecType.isUpdatingCalcSpecs;

export const isCreatingBaseCalcSpecSelector = ({ development }) =>
  development.calcSpecs.baseCalcSpecType.isCreatingCalcSpecs;

export const consolCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.consolCalcSpecType.calcSpecs.data;

export const consolCalcSpecsColumnsBlueprintSelector = ({ development }) =>
  development.calcSpecs.consolCalcSpecType.calcSpecs.columnsBlueprint;

export const isFetchingConsolCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.consolCalcSpecType.isFetchingCalcSpecs;

export const isUpdatingConsolCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.consolCalcSpecType.isUpdatingCalcSpecs;

export const isCreatingConsolCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.consolCalcSpecType.isCreatingCalcSpecs;

export const k1CalcSpecsSelector = ({ development }) =>
  development.calcSpecs.k1CalcSpecType.calcSpecs.data;

export const k1CalcSpecsColumnsBlueprintSelector = ({ development }) =>
  development.calcSpecs.k1CalcSpecType.calcSpecs.columnsBlueprint;

export const isFetchingK1CalcSpecsSelector = ({ development }) =>
  development.calcSpecs.k1CalcSpecType.isFetchingCalcSpecs;

export const isUpdatingK1CalcSpecsSelector = ({ development }) =>
  development.calcSpecs.k1CalcSpecType.isUpdatingCalcSpecs;

export const isCreatingK1CalcSpecsSelector = ({ development }) =>
  development.calcSpecs.k1CalcSpecType.isCreatingCalcSpecs;

export const k1CalcSpecIdOptionsSelector = ({ development }) =>
  development.calcSpecs.k1CalcSpecType.options.k1CalcSpecIds;

export const k1CalcSpecTypeOptionsSelector = ({ development }) =>
  development.calcSpecs.k1CalcSpecType.options.k1CalcSpecTypes;

export const isFetchingK1CalcSpecsOptionsSelector = ({ development }) =>
  development.calcSpecs.k1CalcSpecType.isFetchingCalcSpecsOptions;

export const conspbCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.conspbCalcSpecType.calcSpecs.data;

export const conspbCalcSpecsColumnsBlueprintSelector = ({ development }) =>
  development.calcSpecs.conspbCalcSpecType.calcSpecs.columnsBlueprint;

export const isFetchingConspbCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.conspbCalcSpecType.isFetchingConspbCalcSpecs;

export const isEditingConspbCalcSpecsSelector = ({ development }) =>
  development.calcSpecs.conspbCalcSpecType.isEditingCalcSpecs;

export const isCalcSpecContextChangesBlockedSelector = ({ development }) =>
  development.calcSpecs.shared.isCalcSpecContextChangesBlocked;
