import { ICellRendererParams } from 'ag-grid-community';

import { dateFormatter } from '../../shared/formatters';
import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';
import config from '../../config';

const getColumnDefinitions = () => {
  const columnDefinitions = [
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Return Type',
      field: 'returnType',
      width: 80,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Entity Id',
      field: 'orgId',
      width: 120,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Consolidation ID',
      field: 'consolId',
      width: 120,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name',
      field: 'name',
      tooltipField: 'name',
      width: 240,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'State Code',
      field: 'stateCode',
      width: 60,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Filing Form',
      field: 'filingForm',
      tooltipField: 'filingForm',
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Federal Selection',
      field: 'federalSelection',
      width: 120,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Form Available',
      field: 'federalSubmissionId',
      width: 80,
      cellRenderer: ({ value }: ICellRendererParams) => (value ? 'Yes' : 'No'),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Updated On',
      field: 'updatedOn',
      width: 120,
      cellRenderer: ({ value }: ICellRendererParams) =>
        value ? `Last: ${dateFormatter(value, config.AMERICAN_DATE_FORMAT)}` : '',
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
