import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';

import { fetchDataVisualizationsTypes } from './types';

const runDataVisualizationsReducer = reducerFactory({
  initialState: {
    dataVisualizations: [],
    isFetchingDataVisualizations: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataVisualizationsTypes,
      payloadKey: 'dataVisualizations',
      fetchingKey: 'isFetchingDataVisualizations',
    }),
  },
});

export default runDataVisualizationsReducer;
