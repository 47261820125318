import { httpGetAndParse, httpPost, httpPut } from '../../../utils/fetchService';

export const insertInformationalData = ({ taxYear, period, orgId, jurisdictionId }) =>
  httpPost({
    route: '/api/shared/state-specific-information',
    errorMessage: 'Inserting informational data failed',
    body: { taxYear, period, orgId, jurisdictionId },
  });

export const fetchFormList = ({ taxYear, period, filingTypeId, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/forms/${taxYear}/${period}/${filingTypeId}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Fetching state specific information form list failed',
  });

export const fetchFormFields = ({ taxYear, period, orgId, jurisdictionId, swpFormId }) =>
  httpGetAndParse({
    route: `/api/shared/state-specific-information/${taxYear}/${period}/${orgId}/${jurisdictionId}/${swpFormId}`,
    errorMessage: 'Fetching state specific information form fields failed',
  });

export const updateFormFields = ({ rows }) =>
  httpPut({
    route: '/api/shared/state-specific-information',
    errorMessage: 'Updating state specific information form fields failed',
    body: { rows },
  });
