import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';

import { useCustomMutation } from '.';

export const useMutationDiagnostics = () =>
  useCustomMutation<null, QueryError>({
    handleData: () => ({
      url: '/api/tools/diagnostics',
      method: HttpMethods.POST,
    }),
    resetKey: QueryKeys.Diagnostics.Data,
    errorMessage: 'Refreshing diagnostics failed',
  });
