import { connect } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { createDataProvider } from '../../shared/dataProviders';
import { fetchUsers } from '../usersMaintenance/store/actions';

const UsersDataProvider = ({ fetchUsers, children }) => {
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return children;
};

UsersDataProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  fetchUsers: PropTypes.func.isRequired,
};

export default createDataProvider({
  ProviderComponent: connect(null, {
    fetchUsers,
  })(UsersDataProvider),
});
