import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';
import { isEngagementAdminRoleSelector } from '../../../shared/store/customerData.selectors';
import { UserRoles } from '../../../shared/enums/user';

const disableRolesForEngagementAdmin = [
  UserRoles.ADMINISTRATOR,
  UserRoles.CONTENT_AUTHORIZER,
  UserRoles.CONTENT_DEPLOYER,
  UserRoles.DEVELOPER,
];

export const usersSelector = createSelector(
  [({ admin }) => admin.usersMaintenance.users, isEngagementAdminRoleSelector],
  (users, isEngagementAdminRole) =>
    users.filter(
      ({ roleName }) =>
        !(isEngagementAdminRole && disableRolesForEngagementAdmin.includes(roleName)),
    ),
);

export const isFetchingUsersSelector = ({ admin }) => admin.usersMaintenance.isFetchingUsers;

export const isUpdatingUserSelector = ({ admin }) => admin.usersMaintenance.isUpdatingUser;

export const isCreatingUserSelector = ({ admin }) => admin.usersMaintenance.isCreatingUser;

export const isDeletingUserSelector = ({ admin }) => admin.usersMaintenance.isDeletingUser;

export const validationResult = ({ admin }) => admin.usersMaintenance.validationResult;
export const isValidatingUserSelector = ({ admin }) => admin.usersMaintenance.isValidatingUser;

export const userOptionsSelector = createSelector([usersSelector], users =>
  users.map(({ userId, firstName, lastName }) => ({
    value: userId,
    label: `${firstName} ${lastName}`,
  })),
);

export const businessesSelector = createSelector(
  [({ admin }) => admin.usersMaintenance.businesses],
  businesses =>
    businesses.map((business, rowId) => ({
      ...business,
      rowId,
    })),
);

export const isFetchingBusinessesSelector = ({ admin }) =>
  admin.usersMaintenance.isFetchingBusinesses;
export const isUpdatingUserBusinessesSelector = ({ admin }) =>
  admin.usersMaintenance.isUpdatingUserBusinesses;

export const isFetchingRolesSelector = ({ admin }) => admin.usersMaintenance.isFetchingRoles;

export const rolesListSelector = createSelector(
  [({ admin }) => admin.usersMaintenance.roles, isEngagementAdminRoleSelector],
  (roles, isEngagementAdminRole) =>
    roles.filter(
      ({ roleName }) =>
        !(isEngagementAdminRole && disableRolesForEngagementAdmin.includes(roleName)),
    ),
);

export const rolesSelector = optionsSelectorFactory({
  listSelector: rolesListSelector,
  labelKey: 'roleName',
  valueKey: 'roleId',
});
