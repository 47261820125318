import { Routes, taxNames } from '@common-packages/routes-definitions';

import { filingTypes } from '../../../shared/constants';
import {
  currencyColumnDefinition,
  defaultColumnDefinitionWithFilter,
  disabledReadOnlyCheckboxColumnDefinitions,
} from '../../../shared/columnDefinitions';
import { LinkCellRendererFactory } from '../../../shared/columnDefinitions/cellRenderers';
import { CATEGORY_NAMES } from '../../../shared/enums/filingDecisions';

const AmountLinkRenderer = categoryNameKey =>
  LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
      Routes.filingDecisionAmountDetailsV2.compiledRoute({
        taxYear,
        period,
        businessEntityId: entityId,
        jurisdictionId,
        filingTypeId: filingTypes.SEPARATE,
        categoryName: categoryNameKey,
      }),
    getRouterState: () => ({
      categoryNameForDisplay: CATEGORY_NAMES.find(({ key }) => key === categoryNameKey).name,
    }),
  });

const IncomeTaxSummaryLinkRender = LinkCellRendererFactory({
  getText: ({ valueFormatted, data: { incomeTax } }) => (incomeTax && valueFormatted) || '',
  getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
    Routes.taxSummaries.compiledRoute({
      taxYear,
      period,
      businessEntityId: entityId,
      jurisdictionId,
      filingTypeId: filingTypes.SEPARATE,
      taxName: taxNames.INCOME_TAX,
    }),
});

const FranchiseTaxSummaryLinkRender = LinkCellRendererFactory({
  getText: ({ valueFormatted, data: { franchiseTax } }) => (franchiseTax && valueFormatted) || '',
  getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
    Routes.taxSummaries.compiledRoute({
      taxYear,
      period,
      businessEntityId: entityId,
      jurisdictionId,
      filingTypeId: filingTypes.SEPARATE,
      taxName: taxNames.FRANCHISE_TAX,
    }),
});

const columnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdiction',
    pinned: true,
    lockPinned: true,
    width: 205,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'State Filing Group(s)',
    field: 'filingGroupsIds',
    width: 175,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Federal As-Filed Filing Group',
    field: 'federalAsFiledFilingGroupId',
    width: 175,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Pro Forma Filing Group',
    field: 'proformaFilingGroupId',
    width: 175,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Filing In State',
    field: 'filingInSt',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...disabledReadOnlyCheckboxColumnDefinitions,
    headerName: 'Nexus',
    field: 'nexusInd',
    width: 85,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...currencyColumnDefinition,
    headerName: 'Income Amt',
    field: 'incomeAmount',
    cellRenderer: IncomeTaxSummaryLinkRender,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...currencyColumnDefinition,
    headerName: 'Franchise Amt',
    field: 'franchiseAmount',
    cellRenderer: FranchiseTaxSummaryLinkRender,
  },
  ...CATEGORY_NAMES.map(({ key, field, name }) => ({
    ...defaultColumnDefinitionWithFilter,
    ...currencyColumnDefinition,
    headerName: name,
    field,
    cellRenderer: AmountLinkRenderer(key),
    hide: key === 'GOVT_COP' || key === 'GOVT_MARKET',
  })),
];

export default columnDefinitions;
