import { DataTypeNames } from './enums';

export const FIELDS_FONT_SIZE = '12px';
export const FIELDS_FONT_FAMILY = 'Courier, monospace';

export const CHECKBOX_CHECKED = 'True';
export const CHECKBOX_UNCHECKED = 'False';

// L/R/C is format used by print-service in GET and POST requests
export const Alignments = {
  L: 'left',
  R: 'right',
  C: 'center',
};

// Match field dataType with input type
// Fields with dataType=0 have dataTypeName of null
export const InputTypes = {
  [DataTypeNames.Default]: 'text',
  [DataTypeNames.Currency]: 'number',
  [DataTypeNames.Ratio]: 'text',
  [DataTypeNames.Integer]: 'number',
  // TODO: revert back from SLT-6427
  [DataTypeNames.Date]: 'text',
  // [DataTypeNames.Date]: 'date',
  [DataTypeNames.Boolean]: 'checkbox',
  [DataTypeNames.String]: 'text',
};

export const DEFAULT_FIELD_DATA = { columnValue: '', formattedData: '', fieldAlign: 'l' };

export const POPUP_WIDTH = 200;

export const OverridableFormDateFormat = {
  Source: 'yyyy/MM/dd',
  Input: 'MM/dd/yyyy',
};

export const InputDataKeys = {
  FormattedData: 'formattedData',
  OverrideValue: 'overrideValue',
  DefaultValue: 'defaultValue',
} as const;

export const FORMATTING_ERROR = 'Error!';
