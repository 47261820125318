import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';
import { TaxTypeValues, InfoAccountScreenType } from '@common-packages/shared-constants';
import { Link } from 'react-router-dom';

import globalContextParams from '../../../shared/propTypes/globalContextParams';
import { filingTypes } from '../../../shared/constants';
import stringify from '../../../utils/stringify';

const getTaxName = category =>
  category.startsWith('FTAX_') ? TaxTypeValues.FRANCHISE : TaxTypeValues.INCOME;

const FieldDrillLink = ({ data, params, isConsolidated, formId, member }) => {
  const link = useMemo(() => {
    if (data.infoAccountScreenType) {
      switch (data.infoAccountScreenType) {
        case InfoAccountScreenType.COMMON:
          return {
            pathname: Routes.stateCommonInformation.compiledRoute({
              ...params,
              filingTypeId:
                isConsolidated && data.infoAccountPartnerOrMember
                  ? filingTypes.SEPARATE
                  : params.filingTypeId,
              businessEntityId:
                isConsolidated && data.infoAccountPartnerOrMember
                  ? data.infoAccountPartnerOrMember
                  : params.businessEntityId,
            }),
          };
        case InfoAccountScreenType.STATE_SPECIFIC:
          if (isConsolidated) {
            if (data.infoAccountPartnerOrMember) {
              return {
                pathname: Routes.stateSpecificInformationV2.compiledRoute({
                  ...params,
                  filingTypeId: filingTypes.SEPARATE,
                  businessEntityId: data.infoAccountPartnerOrMember,
                }),
                search: stringify({ formId: data.formID }),
              };
            } else if (member) {
              return {
                pathname: Routes.memberInformationalData.compiledRoute(params),
                search: stringify({ formId, member }),
              };
            } else {
              return {
                pathname: Routes.informationalDataV2.MAIN,
                search: stringify({ formId }),
              };
            }
          }
          return {
            pathname: Routes.stateSpecificInformationV2.compiledRoute(params),
            search: stringify({ formId }),
          };
        case InfoAccountScreenType.K1_OR_MEMBER:
          if (data.infoAccountPartnerOrMember) {
            if (isConsolidated) {
              return {
                pathname: Routes.memberInformationalData.compiledRoute(params),
                search: stringify({
                  formId,
                  member: data.infoAccountPartnerOrMember,
                }),
              };
            }
            return {
              pathname: Routes.scheduleK1InformationV2.compiledRoute(params),
              search: stringify({ formId, partnerId: data.infoAccountPartnerOrMember }),
            };
          }
          throw new Error('K1 Or Member Drill does not have partner or member data');
        case InfoAccountScreenType.GLOBAL:
          if (isConsolidated) {
            return {
              pathname: Routes.consolidationInfo.MAIN,
            };
          }
          return Routes.entityInformation.compiledRoute(params);
        case InfoAccountScreenType.GLOBAL_K1_OR_MEMBER:
          if (data.infoAccountPartnerOrMember) {
            return {
              pathname: Routes.entityInformation.compiledRoute({
                ...params,
                businessEntityId: data.infoAccountPartnerOrMember,
              }),
            };
          }
          throw new Error('Global K1 Or Member Drill does not have partner or member data');
        default:
          throw new Error('Unsupported Info Account Screen Type');
      }
    }

    if (data.formID) {
      return {
        pathname: Routes.viewPrintTaxReturn.compiledRoute(params),
        search: stringify({
          formId: data.formID,
          fieldName: data.fieldName,
        }),
        description: `Edit ${data.description} in form`,
      };
    }

    if (!data.account) {
      return {
        pathname: Routes.genericDrillCategory.compiledRoute({
          taxName: getTaxName(data.category),
          ...params,
          categoryId: data.category,
        }),
      };
    }

    return {
      pathname: Routes.genericDrillAccount.compiledRoute({
        taxName: getTaxName(data.category),
        ...params,
        accountId: data.account,
        categoryId: data.category,
      }),
      search: data.formID
        ? stringify({
            formId: data.formID,
            fieldName: data.fieldName,
          })
        : null,
    };
  }, [
    data.account,
    data.category,
    data.description,
    data.fieldName,
    data.formID,
    data.infoAccountPartnerOrMember,
    data.infoAccountScreenType,
    formId,
    member,
    isConsolidated,
    params,
  ]);

  return (
    <Link to={link}>
      {link?.description ? link.description : `Edit ${data.description} in workpaper`}
    </Link>
  );
};

FieldDrillLink.propTypes = {
  data: PropTypes.shape({
    account: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string.isRequired,
    fieldName: PropTypes.string,
    formID: PropTypes.string,
    infoAccountScreenType: PropTypes.number,
    infoAccountPartnerOrMember: PropTypes.string,
  }).isRequired,
  params: globalContextParams.isRequired,
  isConsolidated: PropTypes.bool,
  formId: PropTypes.string,
  member: PropTypes.string,
};

export default FieldDrillLink;
