import hasRequiredPermissionsToView from '../shared/authorization/hasRequiredPermissionsToView';

const navigationTreeAfterPermissionsApplied = ({ tree, routes, customerPermissions }) =>
  tree
    .map(item => {
      if (item.type === 'folder') {
        const children = navigationTreeAfterPermissionsApplied({
          tree: item.children,
          routes,
          customerPermissions,
        });

        if (!children.length) {
          return null;
        }

        return {
          ...item,
          children,
        };
      }

      // TODO: this is not an elegant solution, we assume that in the routes collection
      // the return workspace `filing-states` routes are placed before `nonfiling-state` and other
      // unfortunately, this would require refactoring all return workspace routes to make it better
      const route = routes.find(route => route.id === item.id);

      if (!route) {
        return null;
      }

      if (
        item.isHandledByPermissionRules &&
        !hasRequiredPermissionsToView(customerPermissions, route.MAIN)
      ) {
        return null;
      }

      return {
        ...item,
        route,
      };
    })
    .filter(Boolean);

export default navigationTreeAfterPermissionsApplied;
