import { createSelector } from 'reselect';
import { arrayToTree } from 'performant-array-to-tree';

import { optionsSelectorFactory } from '../storeHelpers';

export const collapsedHierarchyListItemsSelector = ({ shared }) =>
  shared.dataModels.collapsedHierarchyListItems;

export const dataModelIdSelector = ({ shared }) => shared.dataModels.dataModelId;

export const dataModelsSelector = optionsSelectorFactory({
  listSelector: ({ shared }) => shared.dataModels.dataModels,
  labelKey: 'name',
  valueKey: 'id',
});
export const dataModelSelector = createSelector(
  [dataModelsSelector, dataModelIdSelector],
  (dataModels, dataModelId) =>
    dataModels.find(dataModel => dataModel.value === dataModelId) || null,
);
export const isFetchingDataModelsSelector = ({ shared }) => shared.dataModels.isFetchingDataModels;
export const fullDataModelSelector = createSelector(
  [({ shared }) => shared.dataModels.dataModels, dataModelIdSelector],
  (dataModels, dataModelId) => dataModels.find(dataModel => dataModel.id === dataModelId) || null,
);

export const datasetsSelector = ({ shared }) => shared.dataModels.datasets;
export const allDatasetsSelector = ({ shared }) => shared.dataModels.allDatasets;
export const datasetIdSelector = ({ shared }) => shared.dataModels.datasetId;
export const datasetsOptionsSelector = optionsSelectorFactory({
  listSelector: datasetsSelector,
  labelKey: 'name',
  valueKey: 'id',
});
export const datasetsOptionSelector = createSelector(
  [datasetsOptionsSelector, datasetIdSelector],
  (datasetsOptions, datasetId) => datasetsOptions.find(({ value }) => value === datasetId) || null,
);

export const datasetSelector = createSelector(
  [datasetsSelector, datasetIdSelector],
  (datasets, datasetId) => datasets.find(dataset => dataset.id === datasetId) || null,
);

export const datasetsTreeSelector = ({ shared }) => arrayToTree(shared.dataModels.datasets);
export const isFetchingDatasetsSelector = ({ shared }) => shared.dataModels.isFetchingDatasets;

export const dataItemsSelector = ({ shared }) => shared.dataModels.dataItems;
export const isFetchingDataItemsSelector = ({ shared }) => shared.dataModels.isFetchingDataItems;
export const dataItemsOptionsSelector = optionsSelectorFactory({
  listSelector: dataItemsSelector,
  labelKey: 'name',
  valueKey: 'id',
});
export const dataItemIdSelector = ({ shared }) => shared.dataModels.dataItemId;

export const dataItemSelector = createSelector(
  [dataItemsOptionsSelector, dataItemIdSelector],
  (dataItems, dataItemId) => dataItems.find(dataItem => dataItem.value === dataItemId) || null,
);

export const dataItemsOptionSelector = createSelector(
  [dataItemsOptionsSelector, dataItemIdSelector],
  (dataItemsOptions, dataItemId) =>
    dataItemsOptions.find(({ value }) => value === dataItemId) || null,
);

export const dataTypesSelector = ({ shared }) => shared.dataModels.dataTypes;
export const isFetchingDataTypesSelector = ({ shared }) => shared.dataModels.isFetchingDataTypes;

export const mappingFormsSelector = ({ shared }) => shared.dataModels.mappingForms;
export const isFetchingMappingFormsSelector = ({ shared }) =>
  shared.dataModels.isFetchingMappingForms;

export const overflowActionTypesSelector = ({ shared }) => shared.dataModels.overflowActionTypes;
export const isFetchingOverflowActionTypesSelector = ({ shared }) =>
  shared.dataModels.isFetchingOverflowActionTypes;

export const mappingFormPDFIdSelector = ({ shared }) => shared.dataModels.mappingFormPDFId;

export const pdfFieldsByPageSelector = ({ shared }) => shared.dataModels.pdfFieldsByPage;
export const isFetchingPDFFieldsByPageSelector = ({ shared }) =>
  shared.dataModels.isFetchingPDFFieldsByPage;

export const pdfFieldsByPageOptionsSelector = optionsSelectorFactory({
  listSelector: pdfFieldsByPageSelector,
  labelKey: 'name',
  valueKey: 'id',
});

export const sortedPdfFieldsByPageOptionsSelector = createSelector(
  [pdfFieldsByPageOptionsSelector],
  pdfFieldsByPageOptions => {
    // we do a copy because `Array.prototype.sort` sorts in-place
    const pdfFieldsCopy = [...pdfFieldsByPageOptions];
    return pdfFieldsCopy.sort((option1, option2) => option1.label.localeCompare(option2.label));
  },
);

const pdfMappedFieldsByPageSelector = createSelector([pdfFieldsByPageSelector], pdfFieldsByPage =>
  pdfFieldsByPage.filter(({ pdfFieldMappingId }) => pdfFieldMappingId),
);

export const pdfMappedFieldsByPageOptionsSelector = optionsSelectorFactory({
  listSelector: pdfMappedFieldsByPageSelector,
  labelKey: 'dataItemDefinitionName',
  valueKey: 'dataItemId',
});

export const sortedPdfMappedFieldsByPageOptionsSelector = createSelector(
  [pdfMappedFieldsByPageOptionsSelector],
  pdfMappedFieldsByPageOptions => {
    // we do a copy because `Array.prototype.sort` sorts in-place
    const pdfMappedFieldsCopy = [...pdfMappedFieldsByPageOptions];
    return pdfMappedFieldsCopy.sort((option1, option2) =>
      option1.label.localeCompare(option2.label),
    );
  },
);

export const pdfFieldSelector = createSelector(
  [pdfFieldsByPageSelector, (_, selectedFormFieldId) => selectedFormFieldId],
  (pdfFieldsByPage, selectedFormFieldId) =>
    pdfFieldsByPage.find(({ id }) => id === selectedFormFieldId),
);

export const pdfMappedFieldSelector = createSelector(
  [pdfMappedFieldsByPageSelector, (_, selectedFieldName) => selectedFieldName],
  (pdfMappedFieldsByPage, selectedFieldName) =>
    pdfMappedFieldsByPage.find(
      ({ name }) => name === selectedFieldName || selectedFieldName?.startsWith(name),
    ),
);

export const mappingFormOptionsSelector = optionsSelectorFactory({
  listSelector: ({ shared }) => shared.dataModels.mappingForms,
  labelKey: 'name',
  valueKey: 'pdfId',
});

export const mappingFormSelector = createSelector(
  [mappingFormOptionsSelector, mappingFormPDFIdSelector],
  (mappingFormOptions, mappingFormPDFId) =>
    mappingFormOptions.find(mappingForm => mappingForm.value === mappingFormPDFId) || null,
);

export const mappingFormPDFFileNameSelector = createSelector(
  [mappingFormsSelector, mappingFormPDFIdSelector],
  (mappingForms, mappingFormPDFId) => {
    const form = mappingForms.find(form => form.pdfId === mappingFormPDFId);
    return form ? form.fileName : null;
  },
);

export const isUpdatingDataModelSelector = ({ shared }) => shared.dataModels.isUpdatingDataModel;
export const isAddingDatasetSelector = ({ shared }) => shared.dataModels.isAddingDataset;

export const dataDefinitionsSelector = ({ shared }) => shared.dataModels.dataDefinitions;
export const isFetchingDataDefinitionsSelector = ({ shared }) =>
  shared.dataModels.isFetchingDataDefinitions;

export const datasetInstancesSelector = createSelector(
  [({ shared }) => shared.dataModels.datasetInstances],
  list =>
    list.map(item => ({
      value: item.datasetInstId,
      label: item.instanceName,
    })),
);
export const datasetInstanceSelector = createSelector(
  [
    ({ shared }) => shared.dataModels.datasetInstances,
    ({ shared }) => shared.dataModels.datasetInstanceId,
  ],
  (datasetInstances, datasetInstanceId) =>
    datasetInstances.find(datasetInstance => datasetInstance.datasetInstId === datasetInstanceId) ||
    null,
);

export const isFetchingDatasetInstancesSelector = ({ shared }) =>
  shared.dataModels.isFetchingDatasetInstances;

export const instanceDataSelector = ({ shared }) => shared.dataModels.instanceData;

export const isFetchingInstanceDataSelector = ({ shared }) =>
  shared.dataModels.isFetchingInstanceData;

export const instanceDataPrintServiceSelector = ({ shared }) =>
  shared.dataModels.instanceDataPrintService;

export const isFetchingInstanceDataPrintServiceSelector = ({ shared }) =>
  shared.dataModels.isFetchingInstanceDataPrintService;

export const isAddingDatasetInstanceSelector = ({ shared }) =>
  shared.dataModels.isAddingDatasetInstance;

export const isUpdatingInstanceDataSelector = ({ shared }) =>
  shared.dataModels.isUpdatingInstanceData;

export const isUpdatingPDFDatasetAssignmentSelector = ({ shared }) =>
  shared.dataModels.isAssigningPDFDataUsage || shared.dataModels.isUnassigningPDFDataUsage;

// attachment config related selectors
export const mappingFormPDFPageDataUsageAndAttachmentConfigsSelector = ({ shared }) =>
  shared.dataModels.mappingFormPDFPageDataUsageAndAttachmentConfigs;
export const isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector = ({ shared }) =>
  shared.dataModels.isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs;

export const mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector = ({ shared }) =>
  shared.dataModels.mappingFormPDFPageDataUsageAndAttachmentConfigs.length
    ? shared.dataModels.mappingFormPDFPageDataUsageAndAttachmentConfigId
    : null;

export const mappingFormPDFPageDataUsageAndAttachmentConfigsTreeSelector = ({ shared }) =>
  arrayToTree(shared.dataModels.mappingFormPDFPageDataUsageAndAttachmentConfigs);

export const datasetsByPageSelector = createSelector(
  [
    mappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
    mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector,
  ],
  (
    mappingFormPDFPageDataUsageAndAttachmentConfigs,
    mappingFormPDFPageDataUsageAndAttachmentConfigId,
  ) =>
    mappingFormPDFPageDataUsageAndAttachmentConfigs.filter(
      item => item.parentId === mappingFormPDFPageDataUsageAndAttachmentConfigId,
    ),
);

export const datasetsByPageOptionsSelector = optionsSelectorFactory({
  listSelector: datasetsByPageSelector,
  labelKey: 'name',
  valueKey: 'datasetId',
});

export const selectedMappingFormPDFPageDatasetSelector = createSelector(
  [
    mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector,
    mappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
  ],
  (
    mappingFormPDFPageDataUsageAndAttachmentConfigId,
    mappingFormPDFPageDataUsageAndAttachmentConfigs,
  ) =>
    mappingFormPDFPageDataUsageAndAttachmentConfigs.find(
      pdfPageDataUsageAndAttachmentConfig =>
        pdfPageDataUsageAndAttachmentConfig.id === mappingFormPDFPageDataUsageAndAttachmentConfigId,
    ) || null,
);

export const selectedDatasetPdfAttachmentConfigIdSelector = createSelector(
  [selectedMappingFormPDFPageDatasetSelector],
  ({ pdfAttachmentConfigId } = {}) => pdfAttachmentConfigId,
);

export const pdfAttachmentColumnPropertiesSelector = ({ shared }) =>
  shared.dataModels.PDFAttachmentColumnProperties;

export const isFetchingPDFAttachmentColumnPropertiesSelector = ({ shared }) =>
  shared.dataModels.isFetchingPDFAttachmentColumnProperties;

export const isIgnoringCalculationDataChangesSelector = ({ shared }) =>
  shared.dataModels.isIgnoringCalculationDataChanges;
