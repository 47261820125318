import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';
import { v4 as uuid } from 'uuid';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { selectionListItemPropTypes } from '../../selectionListsPropTypes';

import getColumnDefinitions from './selectionListItemsGrid.columnDefinitions';
import styles from './selectionListItemsGrid.module.scss';

const SelectionListItemsGrid = ({
  selectionListItemsGridData,
  gridApi,
  onGridReady,
  addRow,
  updateRow,
  deleteRow,
  checkIfSelectionListItemsAreDirty,
  validateSelectionListItems,
}) => {
  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: deleteRow,
        updateRow,
      }),
    [deleteRow, updateRow],
  );

  const addItemRow = useCallback(() => {
    const newSelectionListItem = {
      id: uuid(),
      item: '',
      description: '',
    };
    const addedRow = addRow(newSelectionListItem);

    addedRow.setSelected(true);
    gridApi.ensureIndexVisible(addedRow.rowIndex);
  }, [addRow, gridApi]);

  const handleRowDataChangedOrUpdated = useCallback(() => {
    checkIfSelectionListItemsAreDirty();
    validateSelectionListItems();
  }, [checkIfSelectionListItemsAreDirty, validateSelectionListItems]);

  const handleRowDragEnd = useCallback(
    ({ api, overIndex }) => {
      // overIndex might be -1
      if (overIndex < 0) {
        return;
      }
      checkIfSelectionListItemsAreDirty();
      api.refreshCells();
      api.getModel().rowsToDisplay[overIndex].setSelected(true);
      api.ensureIndexVisible(overIndex);
    },
    [checkIfSelectionListItemsAreDirty],
  );

  const handleModelUpdated = useCallback(
    ({ api }) => {
      checkIfSelectionListItemsAreDirty();
      api.refreshCells();
      validateSelectionListItems();
    },
    [checkIfSelectionListItemsAreDirty, validateSelectionListItems],
  );

  return (
    <>
      <div className={styles.buttonsContainerFlex}>
        <Button size="lg" className="add-button" onClick={addItemRow}>
          Add Item
        </Button>
      </div>
      <div className={styles.selectionListItemsGridFlex}>
        <AgGrid
          rowData={selectionListItemsGridData}
          columnDefs={columnDefinitions}
          onGridReady={onGridReady}
          onModelUpdated={handleModelUpdated}
          suppressCellFocus={false}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onRowDragEnd={handleRowDragEnd}
          onRowDataUpdated={handleRowDataChangedOrUpdated}
          rowDragManaged
          suppressScrollOnNewData
          areHeaderCellBordersEnabled
          autoMaxWidth
        />
      </div>
    </>
  );
};

SelectionListItemsGrid.propTypes = {
  selectionListItemsGridData: PropTypes.arrayOf(selectionListItemPropTypes).isRequired,
  gridApi: PropTypes.object,
  onGridReady: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  checkIfSelectionListItemsAreDirty: PropTypes.func.isRequired,
  validateSelectionListItems: PropTypes.func.isRequired,
};

export default SelectionListItemsGrid;
