import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchPartnershipAllocationPercentagesTypes = getApiActionTypes(
  'FETCH_PARTNERSHIP_ALLOCATION_PERCENTAGES',
);
export const updatePartnershipAllocationPercentagesTypes = getApiActionTypes(
  'UPDATE_PARTNERSHIP_ALLOCATION_PERCENTAGES',
);
export const deletePartnershipAllocationTypes = getApiActionTypes('DELETE_PARTNERSHIP_ALLOCATION');
export const fetchPartnershipPercentageTypesTypes = getApiActionTypes(
  'PARTNERSHIP_ALLOCATION/FETCH_PERCENTAGE_TYPES',
);
export const fetchPartnersTypes = getApiActionTypes('PARTNERSHIP_ALLOCATION/FETCH_PARTNERS');
export const updateOwnershipTypes = getApiActionTypes('PARTNERSHIP_ALLOCATION/UPDATE_OWNERSHIP');
