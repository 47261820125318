const Yup = require('yup');

const { noSpecialChar } = require('../validationRegex');

const commonSchema = Yup.object().shape({
  orgId: Yup.string().label('Org Id').required(),
  rolloverToNextYear: Yup.bool().required(),
  note: Yup.string().label('Note').required().matches(noSpecialChar.regex, noSpecialChar.message),
});

const createNexusNoteSchema = commonSchema
  .concat(
    Yup.object().shape({
      preparedBy: Yup.string().label('Prepared by').required(),
      jurisdictionId: Yup.string().required(),
      taxYear: Yup.string().required(),
    }),
  )
  .strict();

const updateNexusNoteSchema = commonSchema
  .concat(
    Yup.object().shape({
      taxYear: Yup.string().required(),
      noteId: Yup.string().required(),
    }),
  )
  .strict();

module.exports = {
  createNexusNoteSchema,
  updateNexusNoteSchema,
  commonSchema,
};
