import React from 'react';

import { FindReturnStatusTrackerDetailsResponse } from '../../../../common/types';
import FaIcon from '../../shared/displayComponents/faIcon/faIcon.component';
import styles from '../returnStatusTrackerDetails.module.scss';

import { ReturnStatusTrackerDetailsStatusVisualsPieChart } from './returnStatusTrackerDetailsStatusVisualsPieChart.component';

const ReturnStatusTrackerDetailsStatusVisuals = ({
  data,
}: {
  data: FindReturnStatusTrackerDetailsResponse;
}) => {
  return (
    <>
      <div className={styles.statusVisualsHeader}>
        <h5 className={styles.statusVisualsHeaderTitle}>Overall Return Status</h5>
        <span className={styles.statusVisualsHeaderSubTitle}>
          {data.gridRows.length} Total Returns
        </span>
      </div>
      <div className={`card ${styles.statusVisualsChartContainer}`}>
        <div className={`col-3 ${styles.statusVisualsChartContent}`}>
          <ReturnStatusTrackerDetailsStatusVisualsPieChart
            title={`${data.gridRows.length}`}
            subTitle="Total Returns"
            data={data.visualData}
          />
        </div>
        <div className="col">
          <div className={`row ${styles.statusVisualsInfoTitleContainer}`}>
            <div className="col">
              <FaIcon className={styles.statusIcon} icon="circle" size={16} prefix="fas" />
              <span>Status</span>
            </div>
            <div className="col">Percentage of Total</div>
            <div className="col">Amount of Total</div>
          </div>
          {data.visualData.map(({ name, className, y, percentage }, index) => (
            <div key={`visualGridData${index}`} className={`row ${styles.statusRow}`}>
              <div className="col">
                <FaIcon
                  className={`${styles.statusIcon} ${className}-icon`}
                  icon="circle"
                  size={16}
                  prefix="fas"
                />
                <span>{name}</span>
              </div>
              <div className="col">{percentage}</div>
              <div className="col">{y}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReturnStatusTrackerDetailsStatusVisuals;
