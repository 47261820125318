import React from 'react';
import Modal from '@tls/ui-modal';

interface PaperFilingConfirmationModalData {
  title: string;
  message: string;
}

interface PaperFilingConfirmationModalProps {
  hideModal: () => void;
  visible: boolean;
  modalData: PaperFilingConfirmationModalData | null;
}

const PaperFilingConfirmationModal = ({
  hideModal,
  visible,
  modalData,
}: PaperFilingConfirmationModalProps) => {
  return (
    <Modal
      title={modalData?.title}
      submitText="OK"
      visible={visible}
      submitAction={hideModal}
      closeable={false}
      actionButtonsSize="lg"
    >
      {modalData?.message}
    </Modal>
  );
};

export default PaperFilingConfirmationModal;
