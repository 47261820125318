import { createSelector } from 'reselect';

export const pdfAttachmentsSelector = createSelector(
  [({ shared }) => shared.pdfAttachments.pdfAttachments],
  pdfAttachments =>
    pdfAttachments.map((pdfAttachment, rowId) => ({
      ...pdfAttachment,
      rowId,
    })),
);

export const isFetchingPdfAttachmentsSelector = ({ shared }) =>
  shared.pdfAttachments.isFetchingPdfAttachments;

export const pdfImageSelector = ({ shared }) => shared.pdfAttachments.pdfImage;

export const isFetchingPdfImageSelector = ({ shared }) => shared.pdfAttachments.isFetchingPdfImage;

export const isCreatingPdfAttachmentSelector = ({ shared }) =>
  shared.pdfAttachments.isCreatingPdfAttachment;

export const isUpdatingOrDeletingPdfAttachmentsSelector = ({ shared }) =>
  shared.pdfAttachments.isUpdatingOrDeletingPdfAttachments;
