import { createSelector } from 'reselect';

export const validatedInformationalDataSelector = ({ tools }) =>
  tools.informationalDataUpload.validatedInformationalData;
export const isFetchingValidatedInformationalDataSelector = ({ tools }) =>
  tools.informationalDataUpload.isFetchingValidatedInformationalData;
export const isUploadDataValidSelector = createSelector(
  [validatedInformationalDataSelector],
  validatedData => validatedData.length > 0 && validatedData.every(({ isValid }) => isValid),
);

export const isUploadingInformationalDataSelector = ({ tools }) =>
  tools.informationalDataUpload.isUploadingInformationalData;
