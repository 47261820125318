import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { ListItem, ListItemText, ListItemAction, Avatar } from '@pwc/appkit-react';
import { JobTypes } from '@common-packages/shared-constants';
import classNames from 'classnames';

import { Notification } from '../../../common/types';
import useBooleanState from '../shared/hooks/useBooleanState.hook';

import GeneratePdfBinderStatusNotificationItem from './generatePdfBinderStatusNotificationItem.component';
import styles from './notificationItem.module.scss';

const TRUNCATE_THRESHOLD = 100;

interface NotificationItemParams {
  notification: Notification;
  onClear: (id: string) => void;
}

const NotificationItem = ({ notification, onClear }: NotificationItemParams) => {
  const [isViewMore, viewMore, viewLess] = useBooleanState(false);
  const [contentText, setContentText] = useState('');
  const [isContentTruncated, setIsContentTruncated] = useBooleanState(false);

  useEffect(() => {
    if (notification.content.length >= TRUNCATE_THRESHOLD) {
      setIsContentTruncated();
      isViewMore
        ? setContentText(notification.content)
        : setContentText(`${notification.content.slice(0, TRUNCATE_THRESHOLD)}...`);
    } else {
      setContentText(notification.content);
    }
  }, [setIsContentTruncated, setContentText, isViewMore, notification]);

  const handleClearNotification = useCallback(() => {
    onClear(notification.id);
  }, [onClear, notification]);

  const viewMoreOptions = useMemo(
    () =>
      isViewMore ? (
        <span onClick={viewLess} className={styles.notificationViewMore}>
          View less
        </span>
      ) : (
        <span onClick={viewMore} className={styles.notificationViewMore}>
          View more
        </span>
      ),
    [viewMore, viewLess, isViewMore],
  );

  const notificationDate = useMemo(() => new Date(notification.createdAt).toLocaleString(), [
    notification.createdAt,
  ]);

  switch (notification.notificationType) {
    case JobTypes.GENERATE_BINDER_PDF_STATUS:
      return (
        <GeneratePdfBinderStatusNotificationItem
          handleClearNotification={handleClearNotification}
          notification={notification}
          contentText={contentText}
          isContentTruncated={isContentTruncated}
          viewMoreOptions={viewMoreOptions}
          notificationDate={notificationDate}
        />
      );
    default:
      return (
        <div className={styles.notificationItemWrapper}>
          <ListItem className={styles.notificationItem}>
            <Avatar
              className={classNames(styles.notificationIcon, styles.notificationIconInfo)}
              icon={<span className="icon-information-fill" />}
            />
            <ListItemText
              className={styles.notificationContent}
              primary={notification.title}
              secondary={contentText}
            />
            <ListItemAction className={styles.notificationClear} onClick={handleClearNotification}>
              <span className="appkiticon icon-close-fill" />
            </ListItemAction>
            <div className={styles.notificationFooter}>{isContentTruncated && viewMoreOptions}</div>
            <span className={styles.notificationDate}>{notificationDate}</span>
          </ListItem>
        </div>
      );
  }
};

export default NotificationItem;
