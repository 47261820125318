import {
  defaultColumnDefinitionWithFilter,
  adminColumnDefinition,
} from '../../../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../../../shared/columnDefinitions/cellRenderers';

const AdminCellRenderer = AdminCellRendererFactory({
  shouldShowEditIcon: () => false,
  shouldShowDeleteIcon: () => false,
  shouldShowExpressionBuilderIcon: ({ hasExpression }) => hasExpression,
});
const columnDefinitions = [
  {
    ...adminColumnDefinition,
    ...defaultColumnDefinitionWithFilter,
    cellRenderer: AdminCellRenderer,
    width: 100,
    suppressSizeToFit: true,
    field: 'hasExpression',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Name',
    field: 'name',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Data Type',
    field: 'dataTypeName',
    width: 82,
    suppressSizeToFit: true,
  },
];

export default columnDefinitions;
