import { Routes, taxNames } from '@common-packages/routes-definitions';

import { currencyColumnDefinition } from '../shared/columnDefinitions';
import {
  LinkCellRendererFactory,
  IconCellRendererFactory,
} from '../shared/columnDefinitions/cellRenderers';
import getContextAwareUrlParams from '../utils/getContextAwareUrlParams';

const DEFAULT_NAMESPACE = 'entityFilingStatesReturnWorkspace'; // TODO: Remove NAMESPACE when rest of drill screens would be moved to context aware url routing

const defaultColumnDefinition = {
  width: 80,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
};

const getColumnsDefinitions = ({ taxYear, period, entityId, filingTypeId, selectJurisdiction }) => {
  const LockRenderer = IconCellRendererFactory({
    getName: value => (value ? 'lock' : 'lock-open'),
    isFaIcon: true,
  });

  const FranchiseTaxLinkRenderer = LinkCellRendererFactory({
    getText: ({ data }) => (data.franchiseTaxYn ? 'Franchise' : ''),
    getPathName: (value, data) =>
      Routes.taxSummaries.compiledRoute({
        ...getContextAwareUrlParams({
          taxYear,
          period,
          filingTypeId,
          businessEntityId: entityId,
          jurisdictionId: data.jurisdictionId,
          taxName: taxNames.FRANCHISE_TAX,
        }),
      }),
    clickHandler: (value, data) => selectJurisdiction(data.jurisdictionId),
  });

  const IncomeTaxLinkRenderer = LinkCellRendererFactory({
    getText: ({ data }) => (data.incomeTaxYn ? 'Income' : ''),
    getPathName: (value, data) =>
      Routes.taxSummaries.compiledRoute({
        ...getContextAwareUrlParams({
          taxYear,
          period,
          filingTypeId,
          businessEntityId: entityId,
          jurisdictionId: data.jurisdictionId,
          taxName: taxNames.INCOME_TAX,
        }),
      }),
    clickHandler: (value, data) => selectJurisdiction(data.jurisdictionId),
  });

  const EstimatedTaxLinkRenderer = LinkCellRendererFactory({
    getText: () => 'Estimated',
    getPathName: (value, data) =>
      Routes.taxSummaries.compiledRoute({
        ...getContextAwareUrlParams({
          taxYear,
          period,
          filingTypeId,
          businessEntityId: entityId,
          jurisdictionId: data.jurisdictionId,
          taxName: taxNames.ESTIMATED_TAX,
        }),
      }),
  });

  const ReturnTaxLinkRenderer = LinkCellRendererFactory({
    getText: () => 'Return',
    getPathName: () => Routes[DEFAULT_NAMESPACE].MAIN,
    clickHandler: (value, data) => selectJurisdiction(data.jurisdictionId),
  });

  return [
    {
      ...defaultColumnDefinition,
      headerName: '',
      field: 'lockedInd',
      cellRenderer: LockRenderer,
      width: 30,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
      width: 400,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Filing Group',
      field: 'filingGroupDescription',
      width: 400,
    },
    {
      ...defaultColumnDefinition,
      headerName: '',
      field: 'incomeTaxYn',
      cellRenderer: IncomeTaxLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Links',
      field: 'franchiseTaxYn',
      cellRenderer: FranchiseTaxLinkRenderer,
      sortable: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: '',
      field: 'estimatedTaxYn',
      cellRenderer: EstimatedTaxLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: '',
      cellRenderer: ReturnTaxLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Income Amt',
      field: 'incomeAmount',
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Franchise Amt',
      field: 'franchiseAmount',
    },
  ];
};

export default getColumnsDefinitions;
