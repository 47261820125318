import { defaultColumnDefinition, mapValueFormatterFactory } from '../shared/columnDefinitions';
import {
  NumberCellEditor,
  EditableCheckboxCellRenderer,
} from '../shared/columnDefinitions/cellRenderers';

import { binderItemTypes } from './constants';

const getColumnDefinitions = ({
  globalContext,
  isInEditMode,
  binderForms,
  binderAttachments,
  updateRow,
  hasUserPermissionsToEdit,
  ToggleAllSelectionsHeader,
}) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const binderItemTypesOptions = [
    ...(binderForms.length ? [{ value: binderItemTypes.FORM, label: 'Form' }] : []),
    ...(binderAttachments.length
      ? [{ value: binderItemTypes.PDF_ATTACHMENT, label: 'PDF Attachment' }]
      : []),
  ];

  return [
    {
      ...defaultColumnDefinition,
      headerComponent: ToggleAllSelectionsHeader,
      headerName: '',
      field: 'isDeleteSelected',
      colId: 'isDeleteSelected',
      width: 100,
      onCellValueChanged,
      cellRendererParams: {
        disabled: !hasUserPermissionsToEdit,
      },
      cellRenderer: EditableCheckboxCellRenderer,
      cellStyle: {
        textAlign: 'center',
      },
    },
    ...(globalContext.isConsolidated
      ? [
          {
            ...defaultColumnDefinition,
            headerName: 'Cons Group ID',
            field: 'consolidationId',
          },
        ]
      : []),
    {
      ...defaultColumnDefinition,
      headerName: 'Entity ID',
      field: 'orgId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Name',
      field: 'orgName',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Document',
      field: 'formName',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Document Type',
      field: 'itemType',
      valueFormatter: mapValueFormatterFactory(binderItemTypesOptions),
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Order',
      field: 'displayOrder',
      editable: hasUserPermissionsToEdit && isInEditMode,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        formatValue: value => parseInt(value, 10),
      },
      onCellValueChanged,
    },
  ];
};

export default getColumnDefinitions;
