import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'leid',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Type',
    field: 'entityType',
    maxWidth: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'LEID',
    field: 'hoLeid',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Period',
    field: 'shortYearId',
    maxWidth: 50,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars BSLA',
    field: 'starsBsla',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars Account',
    field: 'starsAcct',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'TL Account',
    field: 'tlCode',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars Description',
    field: 'starsDescription',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Book Amount',
    field: 'book',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Adj Amount',
    field: 'adj',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Bk/Tx Amount',
    field: 'm1',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Tax Amount',
    field: 'taxAmt',
  },
];

export default columnDefinitions;
