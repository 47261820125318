import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';
import { Button } from '@pwc/appkit-react';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import differenceWith from 'lodash.differencewith';
import { FormDataActions } from '@common-packages/shared-constants';

import {
  loadAllFormDataForSeparate,
  loadAllFormDataForConsolidated,
} from '../shared/store/actions';
import {
  fetchFormData,
  downloadFormPdf,
  resetFormData,
  selectMemberOrFormInstanceId,
} from '../shared/store/viewTaxReturn/actions';
import useModal from '../shared/hooks/useModal.hook';
import WarningModal from '../shared/displayComponents/warningModal.component';
import { updateFormValues as updateFormValuesApiAction } from '../shared/store/viewTaxReturn/api';
import {
  formHTMLSelector,
  formStatusSelector,
  fieldsValuesSelector,
  isFetchingFormDataSelector,
  formInstancesOptionsSelector,
  swpFormIdSelector,
  formDatasetDefinitionIdSelector,
  formDatasetInstanceNameSelector,
  showFormInstanceDataLinkSelector,
  isDownloadingFormPdfSelector,
  inDevelopmentMessageSelector,
  memberOrFormInstanceIdSelector,
} from '../shared/store/viewTaxReturn/selectors';
import {
  filingTypeIdSelector,
  filingStatesProfileIdSelector,
  isFetchingGlobalContextSelector,
  consolidationProfileIdSelector,
  formsByPeriodOptionsWithFormIdSelector,
  isFetchingFormsByPeriodSelector,
} from '../shared/store/selectors';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import {
  ActionMenu,
  ActionMenuItem,
} from '../shared/displayComponents/actionMenu/actionMenu.component';
import ParamDropdown from '../shared/displayComponents/paramDropdown/paramDropdown.component';
import Loading from '../shared/displayComponents/loading.component';
import SelectContextDataInfo from '../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import DataInfo from '../shared/displayComponents/dataInfo/dataInfo.component';
import DataInfoSubText from '../shared/displayComponents/dataInfoSubText/dataInfoSubText.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import useBooleanState from '../shared/hooks/useBooleanState.hook';
import getCalculatedPagesPositionsFromPageContainer from '../shared/getPagesPositionsFromPageContainer';
import useFetch from '../shared/hooks/useFetch.hook';
import MountListenerWrapper from '../shared/displayComponents/MountListenerWrapper.component';
import useUrlParams from '../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { reduceQueryString } from '../utils/routerUtils';
import globalContextPropTypes from '../shared/propTypes/globalContext';
import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import {
  FORM_WRAPPER_CLASS,
  PRINT_SERVICE_PAGE_CONTAINER_ID,
  FORM_STATUS_IN_DEVELOPMENT,
} from '../shared/constants';
import config from '../config';

import OverridableFormWrapper from './overridableForm/overridableFormWrapper.container';
import styles from './viewPrintTaxReturn.module.scss';
import { checkIfInputIsNullable } from './overridableForm/utils/checkIfInputIsNullable';

const VISIBILITY_THRESHOLD = 0.25;
const VERTICAL_FORM_WIDTH = 1000;
const NAV_WIDTH = 400;
const PDF_HORIZONTAL_MARGINS = 35;

const getEditedFields = formRef =>
  differenceWith(
    Object.values(formRef.current.values),
    Object.values(formRef.current.initialValues),
    isEqual,
  );

const checkIsCurrentValueInOptions = (currentValue, options) =>
  options.some(({ value }) => value === currentValue);

const shouldUpdateFormData = (formId, options) =>
  options.length ? checkIsCurrentValueInOptions(formId, options) : false;

const getFieldValue = ({ dataType, isEditable, defaultValue, overrideValue, isOverridden }) => {
  const isNullable = checkIfInputIsNullable(dataType);

  if (isOverridden) {
    return isNullable ? overrideValue || null : overrideValue;
  }

  if (isEditable) {
    return isNullable ? defaultValue || null : defaultValue;
  }
};

const generateFormPayload = editedFields => {
  const getOverrideActionType = (isEditable, isOverridden, isClearingOverride) => {
    if (isOverridden && !isClearingOverride) {
      return FormDataActions.setOverride;
    }
    if (isEditable && !isClearingOverride) {
      return FormDataActions.setValue;
    }
    return FormDataActions.clearOverride;
  };

  return editedFields.map(field => {
    const {
      isOverridden,
      isEditable,
      dsdID,
      didID,
      dsiID,
      diiID,
      defaultValue,
      overrideValue,
      dataType,
      isClearingOverride,
    } = field;

    const fieldIds = {
      dsdID,
      didID,
      dsiID,
      diiID,
    };

    return {
      ...fieldIds,
      Action: getOverrideActionType(isEditable, isOverridden, isClearingOverride),
      Value: getFieldValue({ dataType, defaultValue, isEditable, overrideValue, isOverridden }),
    };
  });
};

const ViewPrintTaxReturn = withContextWrapper()(
  ({ globalContext = {}, hasUserPermissionsToEdit }) => {
    const dispatch = useDispatch();
    const { queryParams, setParams } = useUrlParams();
    const location = useLocation();
    const formRef = useRef(null);
    const overridableFormWrapperRef = useRef(null);
    const pageSectionRef = useRef(null);

    const [isFormDirty, setFormDirty] = useState(false);
    const [visiblePages, setVisiblePages] = useState([]);
    const [mappedPages, setMappedPages] = useState([]);
    const [selectedPage, selectPage] = useState(1);

    const formsOptions = useSelector(formsByPeriodOptionsWithFormIdSelector);
    const isFetchingForms = useSelector(isFetchingFormsByPeriodSelector);
    const formHtml = useSelector(formHTMLSelector);
    const formStatus = useSelector(formStatusSelector);
    const inDevelopmentMessage = useSelector(inDevelopmentMessageSelector);
    const fieldsValues = useSelector(fieldsValuesSelector);
    const isFetchingFormData = useSelector(isFetchingFormDataSelector);
    const swpFormId = useSelector(swpFormIdSelector(location.search));
    const formInstancesOptions = useSelector(formInstancesOptionsSelector);
    const filingTypeId = useSelector(filingTypeIdSelector);
    const formDatasetDefinitionId = useSelector(formDatasetDefinitionIdSelector);
    const formDatasetInstanceName = useSelector(formDatasetInstanceNameSelector);
    const showFormInstanceDataLink = useSelector(showFormInstanceDataLinkSelector);
    const profileId = useSelector(filingStatesProfileIdSelector);
    const consolidationProfileId = useSelector(consolidationProfileIdSelector);
    const isFetchingContext = useSelector(isFetchingGlobalContextSelector);
    const isDownloadingFormPdf = useSelector(isDownloadingFormPdfSelector);
    const memberOrFormInstanceId = useSelector(memberOrFormInstanceIdSelector);

    const [isFormMounted, onFormMount, onFormUnmount] = useBooleanState(false);

    const {
      showModal: showConfirmSetOverrideModal,
      modalProps: confirmSetOverrideModalProps,
    } = useModal();

    const { formId, scrollElementId, member = null } = queryParams;
    const {
      isConsolidated,
      params: { taxYear, period, businessEntityId, jurisdictionId },
    } = globalContext;
    const isLoading = isFetchingContext || isFetchingFormData;
    const isContextReady = globalContext.isSeparate
      ? profileId
      : businessEntityId && consolidationProfileId;

    const shouldShowInDevelopmentMessage =
      formStatus === FORM_STATUS_IN_DEVELOPMENT && !config.DISPLAY_IN_DEVELOPMENT_FORMS;

    const statusTextToRender =
      formStatus === FORM_STATUS_IN_DEVELOPMENT
        ? 'Form is under development.'
        : 'This report is unavailable, please select another report.';

    const textToRender = formId ? statusTextToRender : 'Please select context data';

    const { fetch: updateFormValues, isFetching: isUpdatingFormValues } = useFetch({
      action: updateFormValuesApiAction,
      successNotificationMessage: 'Successfully updated form values',
      throwError: true,
    });

    const areFormButtonsDisabled = isLoading || !isFormDirty || isUpdatingFormValues;

    const isDisableDownloadFormButton =
      isLoading || isFetchingForms || isDownloadingFormPdf || shouldShowInDevelopmentMessage;

    const profileIdForFetchingFormData = globalContext.isSeparate
      ? profileId
      : consolidationProfileId;

    const parsedImage = useMemo(() => (formHtml ? JSON.parse(formHtml) : null), [formHtml]);

    const formPagesOptions = useMemo(() => {
      if (!mappedPages.length) {
        return [];
      }

      return Array(mappedPages.length)
        .fill()
        .map((_, index) => ({ value: index + 1, label: String(index + 1) }));
    }, [mappedPages]);

    const handleFetchFormData = useCallback(() => {
      dispatch(
        fetchFormData({
          formId,
          taxYear,
          period,
          entityId: isConsolidated ? null : businessEntityId,
          jurisdictionId,
          profileId: profileIdForFetchingFormData,
          swpFormId,
          consolidationId: isConsolidated ? businessEntityId : null,
          filingTypeId,
          ...(memberOrFormInstanceId ? { formInstanceId: memberOrFormInstanceId } : {}),
        }),
      );
    }, [
      dispatch,
      formId,
      taxYear,
      period,
      isConsolidated,
      businessEntityId,
      jurisdictionId,
      profileIdForFetchingFormData,
      swpFormId,
      filingTypeId,
      memberOrFormInstanceId,
    ]);

    const handlePageIntersection = useCallback((entries, pages) => {
      setVisiblePages(currentVisiblePages => {
        const visiblePages = [...currentVisiblePages];

        entries.forEach(({ target, isIntersecting, intersectionRatio }) => {
          const pageIndex = pages.indexOf(target);
          if (pageIndex === -1) {
            return;
          }

          // checking if the page from intersection callback is in the array of visible pages
          const pageIndexInVisiblePages = visiblePages.indexOf(pageIndex);

          if (pageIndexInVisiblePages > -1 && !isIntersecting) {
            // when the page is in the array and isn't intersecting anymore
            visiblePages.splice(pageIndexInVisiblePages, 1);
          } else if (pageIndexInVisiblePages === -1 && isIntersecting) {
            // when the page isn't in the array but now it's intersecting
            visiblePages.push(pageIndex);
          }

          // put most visible page first
          if (intersectionRatio > VISIBILITY_THRESHOLD && pageIndexInVisiblePages !== 0) {
            visiblePages.splice(pageIndexInVisiblePages, 1);
            visiblePages.unshift(pageIndex);
          }
        });

        return visiblePages;
      });
    }, []);

    useEffect(() => {
      if (visiblePages.length) {
        selectPage(visiblePages[0] + 1);
      }
    }, [visiblePages]);

    useEffect(() => {
      if (!parsedImage || !overridableFormWrapperRef.current || !isFormMounted) {
        return;
      }

      setMappedPages(
        getCalculatedPagesPositionsFromPageContainer(overridableFormWrapperRef.current),
      );
    }, [parsedImage, overridableFormWrapperRef, isFormMounted]);

    useEffect(() => () => dispatch(resetFormData()), [dispatch]);

    useEffect(() => {
      if (!formId && formsOptions.length) {
        setParams({ queryParams: { formId: formsOptions[0].value } });
      }
    }, [setParams, formId, formsOptions]);

    useEffect(() => {
      if (formId && formsOptions.length) {
        const shouldUpdateFormId = !checkIsCurrentValueInOptions(formId, formsOptions);

        if (shouldUpdateFormId) {
          setParams({ queryParams: { formId: formsOptions[0].value } });
        }
      }
    }, [formsOptions, formId, setParams]);

    useEffect(() => {
      if (member !== memberOrFormInstanceId) {
        setParams({ queryParams: { member: memberOrFormInstanceId } });
      }
    }, [setParams, member, memberOrFormInstanceId]);

    useEffect(() => {
      if (formId && globalContext.isReady) {
        if (!shouldUpdateFormData(formId, formsOptions)) {
          return;
        }

        handleFetchFormData();
      }
    }, [handleFetchFormData, formId, formsOptions, globalContext.isReady, memberOrFormInstanceId]);

    useEffect(() => {
      if (
        isContextReady &&
        scrollElementId &&
        isFormMounted &&
        document.getElementById(scrollElementId)
      ) {
        document.getElementById(scrollElementId).scrollIntoView();
      }
    }, [isContextReady, isFormMounted, scrollElementId]);

    useEffect(() => {
      // determining which pages are visible
      if (!parsedImage) {
        return;
      }

      const pageContainer = document.getElementById(PRINT_SERVICE_PAGE_CONTAINER_ID);
      const pages = Array.from(pageContainer?.children || []);

      const observer = new IntersectionObserver(entries => handlePageIntersection(entries, pages), {
        threshold: [0, 0.25, 1],
      });

      pages.forEach(page => {
        observer.observe(page);
      });

      return () => {
        pages.forEach(page => {
          observer.unobserve(page);
        });
      };
    }, [handlePageIntersection, parsedImage]);

    const handlePageSelect = useCallback(
      pageNumber => {
        if (!mappedPages.length) {
          return;
        }

        const pageId = mappedPages.find(page => page.pageNumber === pageNumber)?.pageId;

        if (pageId) {
          overridableFormWrapperRef.current.querySelector(`#${pageId}`).scrollIntoView();
          selectPage(pageNumber);
        }
      },
      [mappedPages],
    );

    const resize = useCallback(() => {
      if (pageSectionRef.current) {
        if (window.innerWidth > 1500) {
          const formWithMarginsWidth = VERTICAL_FORM_WIDTH + NAV_WIDTH + PDF_HORIZONTAL_MARGINS;
          pageSectionRef.current.style.right = `${
            (window.innerWidth - formWithMarginsWidth) / 2
          }px`;
        } else {
          pageSectionRef.current.style.right = '20px';
        }
      }
    }, []);

    useEffect(() => {
      resize();

      window.addEventListener('resize', resize);

      return () => window.removeEventListener('resize', resize);
    }, [resize]);

    const onFormIdChange = useCallback(
      async option => {
        if (option) {
          setParams({ queryParams: { formId: option.value } });
          await dispatch(selectMemberOrFormInstanceId(null));
        }
      },
      [setParams, dispatch],
    );

    const onFormInstanceIdChange = useCallback(
      async option => {
        await dispatch(selectMemberOrFormInstanceId(option.value));
      },
      [dispatch],
    );

    const onDownload = useCallback(async () => {
      const response = await dispatch(
        downloadFormPdf({
          formId,
          taxYear,
          period,
          entityId: businessEntityId,
          shouldDecrypt: false,
          ...(isConsolidated ? { consolidationId: businessEntityId } : null),
        }),
      );

      if (!response) {
        return;
      }

      const formName = formsOptions.find(({ value }) => value === formId)?.label;
      const data = new Blob([response]);
      saveAs(data, `${formName}_${taxYear}_${period}_${businessEntityId}.pdf`);
    }, [dispatch, isConsolidated, formId, taxYear, period, formsOptions, businessEntityId]);

    const initiateLoadAllFormData = useCallback(
      () =>
        dispatch(
          showConfirmModal({
            title: 'Load All Form Data',
            text: 'Are you sure you want to load all form data for this entity?',
            confirmCallback: () =>
              isConsolidated
                ? dispatch(
                    loadAllFormDataForConsolidated({
                      taxYear,
                      period,
                      consolidationId: businessEntityId,
                    }),
                  )
                : dispatch(
                    loadAllFormDataForSeparate({
                      taxYear,
                      period,
                      entityId: businessEntityId,
                      jurisdictionId,
                    }),
                  ),
          }),
        ),
      [dispatch, isConsolidated, taxYear, period, businessEntityId, jurisdictionId],
    );

    const handleSaveOverrides = useCallback(async () => {
      if (!formRef.current.values) {
        return;
      }
      try {
        const editedFields = getEditedFields(formRef);

        const body = generateFormPayload(editedFields);

        await updateFormValues({
          formId,
          period,
          filingTypeId,
          businessEntityId,
          body,
        });

        handleFetchFormData();
        setFormDirty(false);
      } catch (e) {
        // Error notification is displayed by API function
        return;
      }
    }, [handleFetchFormData, updateFormValues, formId, period, businessEntityId, filingTypeId]);

    const handleCancelOverrides = useCallback(() => {
      formRef.current.resetForm();
      setFormDirty(false);
    }, []);

    const formContentClassNames = useMemo(
      () =>
        classNames(FORM_WRAPPER_CLASS, {
          visible: fieldsValues !== null && !isFetchingFormData,
        }),
      [fieldsValues, isFetchingFormData],
    );

    const renderFormTextControl = useCallback(() => {
      if (shouldShowInDevelopmentMessage) {
        return (
          <DataInfoSubText
            textToRender={statusTextToRender}
            subTextToRender={inDevelopmentMessage}
          />
        );
      }
      return <DataInfo textToRender={textToRender} />;
    }, [shouldShowInDevelopmentMessage, inDevelopmentMessage, textToRender, statusTextToRender]);

    const onConfirmSetOverride = useCallback(() => {
      handleSaveOverrides();
    }, [handleSaveOverrides]);

    const confirmSaveOverrides = useCallback(() => {
      const editedFields = getEditedFields(formRef);

      const overridableEditedFields = editedFields.filter(({ isOverrideable }) => isOverrideable);

      if (overridableEditedFields.length > 0) {
        showConfirmSetOverrideModal(
          overridableEditedFields.map(({ blockId }) => ({ description: blockId })),
        );
      } else {
        handleSaveOverrides();
      }
    }, [showConfirmSetOverrideModal, handleSaveOverrides]);

    if (!isLoading && !isContextReady) {
      return <SelectContextDataInfo />;
    }

    return (
      <>
        <div className="row">
          <div className={`col ${styles.buttonControls}`}>
            {showFormInstanceDataLink && (
              <Link
                className={styles.instanceDataLink}
                to={{
                  pathname: Routes.viewFormWorkpaper.compiledRoute({
                    ...globalContext,
                    datasetDefId: formDatasetDefinitionId,
                  }),
                  search: reduceQueryString({
                    datasetInstanceId: memberOrFormInstanceId,
                    formId,
                  }),
                  state: { returnLocation: location },
                }}
              >
                <Button size="lg" kind="secondary">
                  Form Instance Data
                </Button>
              </Link>
            )}
            <Button
              size="lg"
              kind="secondary"
              onClick={onDownload}
              disabled={isDisableDownloadFormButton}
            >
              <div className={styles.downloadButton}>
                Download Form
                <Loading small isLoading={isDownloadingFormPdf} />
              </div>
            </Button>
            <Button
              size="lg"
              kind="primary"
              onClick={confirmSaveOverrides}
              disabled={areFormButtonsDisabled}
              isLoading={isUpdatingFormValues}
            >
              Save
            </Button>
            <Button
              size="lg"
              kind="secondary"
              onClick={handleCancelOverrides}
              disabled={areFormButtonsDisabled}
            >
              Cancel
            </Button>
          </div>
        </div>
        <div className={`row ${styles.formSelectRow}`}>
          <div className={`col-3 ${styles.formSelectWrapper}`}>
            <SDKCustomSelect
              appkitLabel="Form"
              className="sdk-custom-select"
              labelClassName={styles.dropdownLabel}
              options={formsOptions}
              onChange={onFormIdChange}
              value={formId}
              isLoading={isFetchingForms}
              virtualized
            />
          </div>
          {!shouldShowInDevelopmentMessage && (
            <>
              <div className={`col-3 ${styles.formSelectWrapper}`}>
                {Boolean(formInstancesOptions.length) &&
                  !isFetchingForms &&
                  !isFetchingFormData && (
                    <SDKCustomSelect
                      appkitLabel={formDatasetInstanceName || 'Instance'}
                      className="sdk-custom-select"
                      labelClassName={styles.dropdownLabel}
                      options={formInstancesOptions}
                      onChange={onFormInstanceIdChange}
                      value={memberOrFormInstanceId}
                      virtualized
                    />
                  )}
              </div>
              <div className={styles.pageSection} ref={pageSectionRef}>
                <Loading small isLoading={isFetchingForms || isFetchingFormData}>
                  <div className={styles.pageDropdown}>
                    <ParamDropdown
                      label="Page"
                      value={selectedPage}
                      options={formPagesOptions}
                      handleChange={handlePageSelect}
                    />
                  </div>
                  <span className={styles.ofPages}>of {formPagesOptions.length}</span>
                </Loading>
              </div>
            </>
          )}
          <div className={styles.actionMenu}>
            <ActionMenu>
              <ActionMenuItem
                disabled={!isContextReady}
                onClick={initiateLoadAllFormData}
                text="Load All Form Data"
              />
            </ActionMenu>
          </div>
        </div>
        <div className={`row ${styles.pdfContainer}`}>
          <div id="pdfWrapper" className={styles.pdf}>
            <div className="col">
              <div className="form-wrapper">
                <Loading isLoading={isLoading}>
                  {formId && !shouldShowInDevelopmentMessage && parsedImage ? (
                    <>
                      <MountListenerWrapper onMount={onFormMount} onUnmount={onFormUnmount}>
                        <div
                          className={formContentClassNames}
                          dangerouslySetInnerHTML={parsedImage}
                        />
                      </MountListenerWrapper>
                      {isFormMounted && (
                        <OverridableFormWrapper
                          fieldsValues={fieldsValues}
                          visiblePages={visiblePages}
                          formRef={formRef}
                          overridableFormWrapperRef={overridableFormWrapperRef}
                          isFormDirty={isFormDirty}
                          setFormDirty={setFormDirty}
                          isUpdatingFormValues={isUpdatingFormValues}
                        />
                      )}
                    </>
                  ) : (
                    renderFormTextControl()
                  )}
                </Loading>
              </div>
            </div>
          </div>
        </div>
        <WarningModal
          title="Confirm Override"
          warningMessage="You are about to change the following return data. Do you want to continue?"
          submitText="Yes"
          submitAction={onConfirmSetOverride}
          dismissText="No"
          {...confirmSetOverrideModalProps}
        />
      </>
    );
  },
);

ViewPrintTaxReturn.propTypes = {
  globalContext: globalContextPropTypes,
  hasUserPermissionsToEdit: PropTypes.bool,
};

export default ViewPrintTaxReturn;
