import React, { ReactNode } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

interface RedirectCellRendererHocParams {
  getRedirect: (
    value: ICellRendererParams['value'],
    data: ICellRendererParams['data'],
  ) => string | null;
  getText: (params: {
    value: ICellRendererParams['value'];
    valueFormatted: ICellRendererParams['valueFormatted'];
    data: ICellRendererParams['data'];
  }) => ReactNode;
  // The wrapped component can have any props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  WrappedComponent?: React.ComponentType<any>;
}

const RedirectCellRendererHoc = ({
  getRedirect = () => null,
  getText = () => null,

  WrappedComponent,
}: RedirectCellRendererHocParams) => {
  const RedirectCellRenderer = (props: ICellRendererParams) => {
    const { data, value, valueFormatted } = props;
    const url = getRedirect(value, data);
    const textToRender = getText({ value, valueFormatted, data });

    if (url && WrappedComponent) {
      return (
        <a href={url}>
          <WrappedComponent {...props} />
        </a>
      );
    }

    if (url) {
      return <a href={url}>{textToRender}</a>;
    }

    if (WrappedComponent) {
      return <WrappedComponent {...props} />;
    }

    return null;
  };

  return RedirectCellRenderer;
};

export default RedirectCellRendererHoc;
