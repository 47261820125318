import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchNotesToReviewTypes,
  deleteNoteTypes,
  fetchJurisdictionsTypes,
  fetchStatusesTypes,
  fetchTasksTypes,
  createNoteTypes,
  updateNoteTypes,
} from './types';

export const fetchNotesToReview = apiAction({
  types: fetchNotesToReviewTypes,
  apiAction: api.fetchNotesToReview,
});

export const deleteNote = apiAction({
  types: deleteNoteTypes,
  apiAction: noteId => api.deleteNote({ noteId }),
});

export const fetchJurisdictions = apiAction({
  types: fetchJurisdictionsTypes,
  apiAction: api.fetchJurisdictions,
});

export const fetchStatuses = apiAction({
  types: fetchStatusesTypes,
  apiAction: api.fetchStatuses,
});

export const fetchTasks = apiAction({
  types: fetchTasksTypes,
  apiAction: api.fetchTasks,
});

export const createNote = apiAction(
  {
    types: createNoteTypes,
    apiAction: api.createNote,
  },
  { successNotificationMessage: () => 'Note has been successfully created' },
);

export const updateNote = apiAction(
  {
    types: updateNoteTypes,
    apiAction: api.updateNote,
  },
  { successNotificationMessage: () => 'Note has been successfully updated' },
);
