import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import SlideIn from '../../../../../shared/displayComponents/slideIn/slideIn.component';
import StepInformationCard from '../../stepInformationCard/stepInformationCard.component';
import LoadingSpinner from '../../../../../shared/forms/loadingSpinner/loadingSpinner.component';
import {
  FederalSubmissionId,
  SltBinderId,
  TtiBinderId,
  TtiCaseId,
  TtiYearId,
  EFileServiceSubmissionId,
} from '../../../../../../../common/types';
import {
  jurisdictionStateCodeSelector,
  taxYearSelector,
  businessEntityIdSelector,
} from '../../../../../shared/store/selectors';

import useGenerateFileUrl from './useGenerateFileUrl.hook';
import styles from './downloadsPanel.module.scss';

interface DownloadsPanelProps {
  title: string;
  hidePanel: () => void;
  ttiYearId: TtiYearId | null;
  ttiCaseId: TtiCaseId | null;
  ttiBinderId: TtiBinderId | null;
  sltBinderId: SltBinderId | null;
  federalSubmissionId: FederalSubmissionId | null;
  isSubmissionZipAvailable: boolean;
  isSubmissionXmlAvailable: boolean;
  eFileServiceSubmissionId: EFileServiceSubmissionId | null;
  isReturnRequired: boolean | null;
  isFederalFileAvailable: boolean;
  isOpen: boolean;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
  stateEFileXmlFileLocator: string | null;
  stateEFileZipFileLocator: string | null;
}

const TRANSMISSION_VALIDATIONS_TITLE = 'Transmission Validations';
const FEDERAL_XML_TITLE = 'Federal XML';

const DownloadsPanel = ({
  title,
  hidePanel,
  ttiCaseId,
  ttiYearId,
  ttiBinderId,
  sltBinderId,
  federalSubmissionId,
  eFileServiceSubmissionId,
  isReturnRequired,
  isFederalFileAvailable,
  isOpen,
  isFetchingTaxReturnOrTransmitterProviderTR,
  isSubmissionXmlAvailable,
  isSubmissionZipAvailable,
  stateEFileXmlFileLocator,
  stateEFileZipFileLocator,
}: DownloadsPanelProps) => {
  const {
    submissionZipFileUrl,
    submissionXmlFileUrl,
    schemaValidationsFileUrl,
    transmissionValidationsFileUrl,
    federalXmlFileUrl,
    allFilesUrl,
  } = useGenerateFileUrl({
    eFileServiceSubmissionId,
    federalSubmissionId,
    ttiCaseId: ttiCaseId,
    ttiYearId: ttiYearId,
    ttiBinderId,
    sltBinderId,
    isReturnRequired,
    isFederalFileAvailable,
    stateEFileXmlFileLocator,
    stateEFileZipFileLocator,
  });

  const jurisdictionStateCode = useSelector(jurisdictionStateCodeSelector);
  const taxYear = useSelector(taxYearSelector);
  const businessEntityId = useSelector(businessEntityIdSelector);

  const isDataReady = isFetchingTaxReturnOrTransmitterProviderTR ? Boolean(sltBinderId) : true;

  const isThereFederalAndTransmissionFile =
    transmissionValidationsFileUrl.length && federalXmlFileUrl.length;

  const renderFederalFiles = useCallback(() => {
    if (!isReturnRequired) {
      return (
        <p className={styles.noFilesToDownloadText}>
          No files to download as a Federal return was not required.
        </p>
      );
    }
    if (!isFederalFileAvailable && !isThereFederalAndTransmissionFile) {
      return (
        <p className={styles.noFilesToDownloadText}>
          No files to download as Federal return was not included.
        </p>
      );
    }
    if (
      isFederalFileAvailable &&
      !transmissionValidationsFileUrl.length &&
      federalXmlFileUrl.length
    ) {
      return (
        <div className={styles.downloadCardsWrapper}>
          <StepInformationCard
            title={TRANSMISSION_VALIDATIONS_TITLE}
            isDownloadable
            fileLinks={[
              {
                url: transmissionValidationsFileUrl,
                label: transmissionValidationsFileUrl,
              },
            ]}
            disabled
            subtitle="File not available yet."
          />
          <StepInformationCard
            title="Federal XML"
            isDownloadable
            fileLinks={[
              {
                url: federalXmlFileUrl,
                label: federalXmlFileUrl,
              },
            ]}
          />
        </div>
      );
    }
    if (
      isFederalFileAvailable &&
      transmissionValidationsFileUrl.length &&
      !federalXmlFileUrl.length
    ) {
      return (
        <div className={styles.downloadCardsWrapper}>
          <StepInformationCard
            title={TRANSMISSION_VALIDATIONS_TITLE}
            isDownloadable
            fileLinks={[
              {
                url: transmissionValidationsFileUrl,
                label: transmissionValidationsFileUrl,
              },
            ]}
          />
          <StepInformationCard
            title={FEDERAL_XML_TITLE}
            isDownloadable
            fileLinks={[
              {
                url: transmissionValidationsFileUrl,
                label: transmissionValidationsFileUrl,
              },
            ]}
            disabled
            subtitle="File not available yet."
          />
        </div>
      );
    }
    if (isFederalFileAvailable && isThereFederalAndTransmissionFile) {
      return (
        <div className={styles.downloadCardsWrapper}>
          <StepInformationCard
            title={TRANSMISSION_VALIDATIONS_TITLE}
            isDownloadable
            fileLinks={[
              {
                url: transmissionValidationsFileUrl,
                label: transmissionValidationsFileUrl,
              },
            ]}
          />
          <StepInformationCard
            title={FEDERAL_XML_TITLE}
            isDownloadable
            fileLinks={[
              {
                url: federalXmlFileUrl,
                label: federalXmlFileUrl,
              },
            ]}
          />
        </div>
      );
    }
    return null;
  }, [
    isReturnRequired,
    isFederalFileAvailable,
    isThereFederalAndTransmissionFile,
    transmissionValidationsFileUrl,
    federalXmlFileUrl,
  ]);

  return (
    <SlideIn
      className={styles.downloadsPanelContent}
      closeIconName="close"
      onRequestClose={hidePanel}
      title={title}
      isOpen={isOpen}
      width="480px"
    >
      {!isDataReady ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner className={styles.loadingSpinner} isLoading hasColor />
          <span className={styles.fetchingInformation}>Fetching downloads</span>
        </div>
      ) : (
        <>
          <div className={styles.topContentWrapper}>
            {eFileServiceSubmissionId && (
              <div>
                <h5>State Validations</h5>
                <div className={styles.downloadCardsWrapper}>
                  <StepInformationCard
                    title="Submission ZIP"
                    disabled={!isSubmissionZipAvailable}
                    isDownloadable
                    fileLinks={[
                      {
                        url: submissionZipFileUrl,
                        label: submissionZipFileUrl,
                        fileName: `${taxYear}_${jurisdictionStateCode}_${businessEntityId}_submission.zip`,
                      },
                    ]}
                  />
                  <StepInformationCard
                    title="Submission XML"
                    disabled={!isSubmissionXmlAvailable}
                    isDownloadable
                    fileLinks={[
                      {
                        url: submissionXmlFileUrl,
                        label: submissionXmlFileUrl,
                        fileName: `${taxYear}_${jurisdictionStateCode}_${businessEntityId}_submission.xml`,
                      },
                    ]}
                  />
                  {!isFetchingTaxReturnOrTransmitterProviderTR && (
                    <>
                      <StepInformationCard
                        title="Schema Validation"
                        isDownloadable
                        fileLinks={[
                          {
                            url: schemaValidationsFileUrl,
                            label: schemaValidationsFileUrl,
                          },
                        ]}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            {federalSubmissionId && (
              <div>
                <h5>Federal Files</h5>
                {renderFederalFiles()}
              </div>
            )}
          </div>
          {allFilesUrl && (
            <div className={styles.bottomContentWrapper}>
              <StepInformationCard
                title="Download All Files"
                isDownloadable
                shouldShowDownloadIcon={false}
                hasButtonStyle
                fileLinks={[
                  {
                    url: allFilesUrl,
                    label: allFilesUrl,
                  },
                ]}
              />
            </div>
          )}
        </>
      )}
    </SlideIn>
  );
};

export default DownloadsPanel;
