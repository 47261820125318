export const ExpressionsTypes = {
  EXPRESSIONS_EXIST: 'Expressions Exist',
  UP_TO_DATE: 'Up-to-date',
  CHANGED_SINCE_LAST_SUCCESSFUL_COMPILE: 'Changed',
  NO_EXPRESSIONS_FOUND: 'No Expressions Found',
};

export const LastSuccessfulCompileTypes = {
  INITIAL_COMPILE_NEEDED: 'Initial Compile Needed',
  N_A: 'N/A',
};

export const EXPRESSIONS = 'Expressions:';
export const DATAITEMS = 'DataItems:';
export const LAST_SUCCESSFUL_COMPILE = 'Last Successful Compile:';
export const COMPILING_STATUS = 'Compiling Status:';
export const COMPILING = 'Compiling...';
