import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';

import { fetchInfoAccountsTypes } from './types';

export const initialState = {
  infoAccounts: [],
  isFetchingInfoAccounts: false,
};

const infoAccountsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchInfoAccountsTypes,
      payloadKey: 'infoAccounts',
      fetchingKey: 'isFetchingInfoAccounts',
    }),
  },
});

export default infoAccountsReducer;
