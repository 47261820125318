import { httpGetAndParse, httpPut, httpDelete } from '../../../utils/fetchService';

export const fetchPartnershipAllocationPercentages = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/partnership-allocation-percentages/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching partnership allocation percentages failed',
  });

export const updatePartnershipAllocationPercentages = ({
  taxYear,
  period,
  entityId,
  rowsToUpdate,
}) =>
  httpPut({
    route: `/api/entities/partnership-allocation-percentages/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Updating partnership allocation percentages failed',
    body: { rowsToUpdate },
  });

export const deletePartnershipAllocation = ({
  taxYear,
  period,
  entityId,
  partnerId,
  percentageType,
}) =>
  httpDelete({
    route: `/api/entities/partnership-allocation-percentages/${taxYear}/${period}/${entityId}/${partnerId}/${percentageType}`,
    errorMessage: 'Deleting partnership allocation failed',
  });

export const fetchPartnershipPercentageTypes = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/entities/partnership-allocation-percentages/percentage-types/${taxYear}/${period}`,
    errorMessage: 'Fetching partnership allocation percentage types failed',
  });

export const fetchPartners = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/partnership-allocation-percentages/partners/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching partners failed',
  });

export const updateOwnership = ({ taxYear, period, owner }) =>
  httpPut({
    route: `/api/entities/partnership-allocation-percentages/ownership/${taxYear}/${period}`,
    body: owner,
    errorMessage: 'Updating ownership failed',
  });
