import { currencyColumnDefinition, defaultColumnDefinition } from '../shared/columnDefinitions';
import { cellRenderer } from '../shared/columnDefinitions/cellRenderers/cellRenderer.component';

import { rowTypes } from './constants';

const currencyColumnColumnDefinition = {
  ...defaultColumnDefinition,
  ...currencyColumnDefinition,
  sortable: false,
};

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  sortable: false,
};

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    sortable: false,
    headerName: 'Org Id',
    field: 'orgId',
    width: 250,
    colSpan: ({ data }) => {
      switch (data.rowType) {
        case rowTypes.HEADER:
        case rowTypes.EMPTY:
          return '4';
        case rowTypes.SUMMARY:
          return '3';
        default:
          return '1';
      }
    },
    cellRenderer: params =>
      cellRenderer({
        params,
        renderer: ({ data }) => (data.rowType === rowTypes.HEADER ? data.descr : data.orgId),
      }),
    valueGetter: ({ data }) => (data.rowType === rowTypes.HEADER ? data.descr : data.orgId),
    maxWidth: 320,
    cellClassRules: {
      'bold-cell': ({ data }) => data.rowType === rowTypes.HEADER,
    },
  },
  {
    ...extendedDefaultColumnDefinition,
    headerName: 'Org Name',
    field: 'orgName',
  },
  {
    ...extendedDefaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
  },
  {
    ...currencyColumnColumnDefinition,
    headerName: 'Amount',
    field: 'amt',
    cellClassRules: {
      'bold-cell': ({ data }) => data.rowType === rowTypes.SUMMARY,
    },
  },
];

export default columnDefinitions;
