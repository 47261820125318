import zip from 'lodash.zip';

const getRowDataValues = ({ columns, columnKeys, rowProperty }) => {
  const groupedData = zip(columnKeys, columns);
  return groupedData.reduce(
    (acc, [columnKey, column]) => ({
      ...acc,
      [columnKey]: column ? column[rowProperty] : null,
    }),
    {},
  );
};

const transformReportIntoRowData = ({
  firstColumnKey,
  rowInfoKey,
  columnKeys,
  reportData: { rowMappings, columns },
}) =>
  rowMappings.map(({ rowName, rowProperty, rowInfo, ...rest }) =>
    rowInfo.isHeader || rowInfo.isLink || rowInfo.isEmptyRow
      ? {
          [rowInfoKey]: rowInfo,
          ...rest,
        }
      : {
          [firstColumnKey]: rowName,
          [rowInfoKey]: rowInfo,
          ...getRowDataValues({
            columns,
            columnKeys,
            rowProperty,
          }),
        },
  );

export default transformReportIntoRowData;
