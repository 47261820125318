import { getApiActionTypes } from '../storeHelpers';

export const insertInformationDataTypes = getApiActionTypes(
  'SHARED/STATE_SPECIFIC_INFORMATION/INSERT_INFORMATION_DATA',
);
export const fetchFormListTypes = getApiActionTypes(
  'SHARED/STATE_SPECIFIC_INFORMATION/FETCH_FROM_LIST',
);
export const fetchFormFieldsTypes = getApiActionTypes(
  'SHARED/STATE_SPECIFIC_INFORMATION/FETCH_FROM_FIELDS',
);
export const updateFormFieldsTypes = getApiActionTypes(
  'SHARED/STATE_SPECIFIC_INFORMATION/UPDATE_FROM_FIELDS',
);
