import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { ExcelFileFormats } from '@common-packages/shared-constants';

import getQueryParams from '../../utils/getQueryParams';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import saveReportDefinitionFile from '../../shared/reports/utils/saveReportDefinitionFile';

import SlideInButtons from './slideInButtons.component';
import EditParamModalFormWrapper from './editParamModalFormWrapper.component';
import ParamField from './paramFields/paramField.component';

const getParamFieldName = paramName => `param-${paramName}`;

const reportFileFormatOptions = Object.values(ExcelFileFormats).map(({ extension }) => ({
  label: extension,
  value: extension,
}));

const checkIfFormIsValid = async validateForm => {
  const validationErrorsObj = await validateForm();
  return Object.keys(validationErrorsObj).length === 0;
};

const validateAndCall = async ({ callback, submitForm, validateForm }) => {
  const isValid = await checkIfFormIsValid(validateForm);
  if (isValid) {
    callback();
  }
  submitForm();
};

const getParamsValues = (queryParams, values) =>
  queryParams.reduce((acc, param) => {
    acc[param.mappingName] = values[getParamFieldName(param.name)] || '';
    return acc;
  }, {});

const extractDependentParamsValues = (currentParams, values, query) => {
  const queryParams = getQueryParams(query);
  const dependentParams = currentParams.filter(param => queryParams.includes(param.mappingName));

  return getParamsValues(dependentParams, values);
};

const createOrUpdateQueryParams = (queryParams, paramName, paramValues) => {
  const shouldCreateParam = queryParams.every(param => param.name !== paramName);
  const newParamData = {
    name: paramValues.name,
    mappingName: paramValues.mappingName,
    type: paramValues.type,
    query: paramValues.query,
    dropdownValue: paramValues.dropdownValue,
    dropdownLabel: paramValues.dropdownLabel,
    isMultiSelectDropdown: paramValues.isMultiSelectDropdown,
    contextItem: paramValues.contextItem,
  };

  if (shouldCreateParam) {
    return [...queryParams, newParamData];
  }

  return queryParams.map(param =>
    param.name === paramName ? { ...param, ...newParamData } : param,
  );
};

class AddEditTrgReportForm extends PureComponent {
  static propTypes = {
    values: PropTypes.shape({
      reportFileFormat: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      trgId: PropTypes.string,
      query: PropTypes.string,
      serverSide: PropTypes.bool,
      defaultOrderBy: PropTypes.string,
      orderBy: PropTypes.bool,
      reportType: PropTypes.string,
      chart: PropTypes.shape({
        type: PropTypes.string,
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    }).isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    saveQuery: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    closeSlideIn: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isBusy: PropTypes.bool.isRequired,
    showEditParamModal: PropTypes.func.isRequired,
    initialQueryParams: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        mappingName: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        query: PropTypes.string,
        dropdownValue: PropTypes.string,
        dropdownLabel: PropTypes.string,
      }).isRequired,
    ).isRequired,
  };

  constructor(props) {
    super(props);

    const { initialQueryParams } = props;

    this.state = {
      queryParams: initialQueryParams,
    };
  }

  downloadDefinitionFile = async () => {
    const { validateForm, submitForm, values } = this.props;
    const { queryParams } = this.state;
    const {
      name,
      trgId,
      reportFileFormat,
      query,
      reportType,
      chart,
      serverSide,
      defaultOrderBy,
    } = values;

    validateAndCall({
      callback: () =>
        saveReportDefinitionFile({
          name,
          trgId,
          reportFileFormat,
          query,
          queryParams,
          reportType,
          chart,
          serverSide,
          defaultOrderBy,
        }),
      submitForm,
      validateForm,
    });
  };

  saveQuery = async () => {
    const { saveQuery, validateForm, submitForm, values } = this.props;
    const { queryParams } = this.state;

    validateAndCall({
      callback: () => saveQuery(values, queryParams),
      submitForm,
      validateForm,
    });
  };

  showEditParamModal = paramName => () => {
    const { showEditParamModal } = this.props;
    const { queryParams } = this.state;
    showEditParamModal(queryParams.find(({ name }) => name === paramName));
  };

  addParam = async () => {
    const { showEditParamModal } = this.props;
    showEditParamModal({});
  };

  changeParam = (paramName, paramValues) => {
    this.setState(state => ({
      queryParams: createOrUpdateQueryParams(state.queryParams, paramName, paramValues),
    }));
  };

  handleParamFieldChange = (fieldName, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(fieldName, value);
  };

  render() {
    const { closeSlideIn, isEditMode, isBusy, values, isValid } = this.props;
    const { queryParams } = this.state;

    return (
      <form>
        <Select
          appkitLabel="Report Format"
          name="reportFileFormat"
          options={reportFileFormatOptions}
          value={values.reportFileFormat}
        />
        <Field
          label="TRG ID"
          name="trgId"
          component={Input}
          autoComplete="off"
          className="slide-in-field"
        />
        <Field
          label="Name"
          name="name"
          component={Input}
          autoComplete="off"
          className="slide-in-field"
        />

        {queryParams.map((param, index) => (
          <ParamField
            key={`${index}-${param.name}`}
            param={param}
            dependentParams={extractDependentParamsValues(queryParams, values, param.query)}
            value={values[getParamFieldName(param.name)]}
            handleChange={this.handleParamFieldChange}
            showEditParamModal={this.showEditParamModal}
            getParamFieldName={getParamFieldName}
          />
        ))}

        <SlideInButtons
          onSubmit={this.saveQuery}
          onAddParam={this.addParam}
          onCancel={closeSlideIn}
          onDownload={this.downloadDefinitionFile}
          isBusy={isBusy}
          submitText={isEditMode ? 'Save' : 'Add'}
          disableSubmit={!isValid}
          isSaving={isBusy}
        />

        <EditParamModalFormWrapper
          title={isEditMode ? 'Edit TRG Param' : 'Add TRG Param'}
          saveParam={this.changeParam}
          existingParams={getParamsValues(queryParams, values)}
          reportType={values.reportType}
        />
      </form>
    );
  }
}

export default AddEditTrgReportForm;
