import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { periodSelector, taxYearSelector } from '../../shared/store/selectors';
import { useRowEditMode } from '../../shared/editMode';
import toggleAllSelectionsHeaderFactory from '../../shared/displayComponents/toggleAllSelectionsHeaderFactory';
import { filingGroupIdSelector } from '../store/selectors';

import getColumnDefinitions from './filingMembers.columnDefinitions';
import { fetchFilingMembers, updateFilingMembers } from './store/actions';
import {
  filingMembersSelector,
  isFetchingFilingMembersSelector,
  isUpdatingFilingMembersSelector,
} from './store/selectors';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const FilingMembers = ({
  taxYear,
  period,
  filingGroupId,
  filingMembers,
  isFetchingFilingMembers,
  fetchFilingMembers,
  updateFilingMembers,
  isUpdatingFilingMembers,

  hasUserPermissionsToEdit,
}) => {
  const [selectAllIncluded, setSelectAllIncluded] = useState(false);
  const isContextReady = taxYear && period && filingGroupId;

  useEffect(() => {
    if (isContextReady) {
      fetchFilingMembers({ taxYear, period, filingGroupId });
    }
  }, [fetchFilingMembers, filingGroupId, isContextReady, period, taxYear]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        includedFlag: newRow.includedFlag,
        entityId: oldRow.entityId,
        taxYear,
        period,
        filingGroupId: oldRow.filingGroupId,
      }));

      await updateFilingMembers(rowsToUpdate);
      fetchFilingMembers({ taxYear, period, filingGroupId });
    },
    [fetchFilingMembers, filingGroupId, period, taxYear, updateFilingMembers],
  );

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRowForSelectAll,
    updateRow,
    onGridReady,
    gridApi,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: filingMembers,
    getUniqueRowId,
    editButtonDisabled: isFetchingFilingMembers || isUpdatingFilingMembers,
  });

  const isAllSelected = clonedRowData.every(({ includedFlag }) => includedFlag);
  useEffect(() => setSelectAllIncluded(isAllSelected), [isAllSelected]);

  const handleOverrideAllValues = useCallback(
    newValue => {
      gridApi.forEachNode(({ data }) => {
        data.includedFlag = newValue;
        updateRowForSelectAll(data);
      });
      gridApi.refreshCells({ force: true, columns: ['includedFlag'] });
    },
    [gridApi, updateRowForSelectAll],
  );

  const columnDefinitions = useMemo(() => {
    const ToggleAllSelectionsHeader = toggleAllSelectionsHeaderFactory({
      togglerState: selectAllIncluded,
      setTogglerState: setSelectAllIncluded,
      isInEditMode,
      handleOverrideAllValues,
    });

    return getColumnDefinitions({
      updateRow,
      isInEditMode,
      ToggleAllSelectionsHeader,
    });
  }, [updateRow, handleOverrideAllValues, selectAllIncluded, isInEditMode]);

  return (
    <>
      {navigationPrompt}
      {hasUserPermissionsToEdit && (
        <div className="row">
          <div className="col add-button-column">{editModeButtons}</div>
        </div>
      )}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            isGridLoading={isFetchingFilingMembers || isUpdatingFilingMembers}
            singleClickEdit
            columnDefs={columnDefinitions}
            withSearchBar
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
};

FilingMembers.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  filingGroupId: PropTypes.string,
  filingMembers: PropTypes.arrayOf(
    PropTypes.shape({
      countryOfInc: PropTypes.string,
      dateAcquired: PropTypes.string,
      dateIncorporated: PropTypes.string,
      entityId: PropTypes.string.isRequired,
      entityName: PropTypes.string.isRequired,
      fedFilingtype: PropTypes.string,
      fein: PropTypes.string,
      businessDescription: PropTypes.string.isRequired,
      includedFlag: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isFetchingFilingMembers: PropTypes.bool.isRequired,
  fetchFilingMembers: PropTypes.func.isRequired,
  updateFilingMembers: PropTypes.func.isRequired,
  isUpdatingFilingMembers: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    filingMembers: filingMembersSelector(state),
    isFetchingFilingMembers: isFetchingFilingMembersSelector(state),
    isUpdatingFilingMembers: isUpdatingFilingMembersSelector(state),
    filingGroupId: filingGroupIdSelector(state),
  }),
  {
    fetchFilingMembers,
    updateFilingMembers,
  },
)(FilingMembers);
