import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { TextCellEditor } from '../../shared/columnDefinitions/cellRenderers';
import { currencyCellFormatter } from '../../shared/formatters';

const getColumnDefinitions = ({ isInEditMode, updateRow }) => {
  const onRowValueChanged = ({ data }) => {
    updateRow(data);
  };

  return [
    {
      ...defaultColumnDefinition,
      headerName: 'State',
      field: 'stateDescription',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Amounts',
      field: 'accountAmount',
      editable: isInEditMode,
      cellEditor: TextCellEditor,
      onCellValueChanged: onRowValueChanged,
      valueFormatter: currencyCellFormatter,
      cellStyle: {
        textAlign: 'right',
      },
    },
  ];
};

export default getColumnDefinitions;
