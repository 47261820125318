import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Checkbox } from '@pwc/appkit-react';

import globalContextPropTypes from '../../../shared/propTypes/globalContext';
import { contextDebugInfoPropTypes } from '../currentReturnFiling.propTypes';
import AppkitIcon from '../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import Loading from '../../../shared/displayComponents/loading.component';
import Action from '../action.component';
import styles from '../../styles.module.scss';
import { getActionsBatchStatus } from '../../utils';
import {
  EFILE_ACTION,
  EFILE_GENERATE_XML_STEPS,
  EFILE_GENERATE_XML_AND_VALIDATIONS_STEPS,
} from '../constants';
import { getActionData } from '../getStepsData';

import ActionUpdatedTime from './actionUpdatedTime.component';

const GenerateWithValidations = ({
  subStepsData,
  globalContext,
  isStepSkippable,
  stepsToSkip,
  toggleStepSkip,
  contextDebugInfo,
  isFetchingEFileStepsData,
  isSubmittingActions,
  getDownloadFileComponent,
  onRunButtonClick,
  disabled,
  isContextLocked,
  isFetchingTtiBinders,
  isCreatingBinder,
}) => {
  const stepsToFilter = EFILE_GENERATE_XML_AND_VALIDATIONS_STEPS;
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = useCallback(() => setCollapsed(c => !c), [setCollapsed]);

  const submitting = stepsToFilter.some(actionBatch => Boolean(isSubmittingActions[actionBatch]));
  const allSubsteps = subStepsData
    .filter(({ actionsBatch }) => stepsToFilter.includes(actionsBatch))
    .flatMap(({ stepData }) => stepData)
    .sort((a, b) => (a.updatedOn < b.updatedOn ? 1 : -1));

  const expectedSubsteps = stepsToFilter
    .filter(actionsBatch => !stepsToSkip[actionsBatch])
    .map(step => EFILE_GENERATE_XML_STEPS[step])
    .flatMap(step => Object.keys(step));

  const latestAction = allSubsteps[0];
  const showDownloadLinks = Boolean(allSubsteps.length);

  return (
    <section className={styles.userStep}>
      <header>
        <h4>
          Generate XML and Schema Validations <Loading small isLoading={submitting} />
        </h4>
        <div className={styles.userStepNutshell}>
          <span>
            Status:{' '}
            {isFetchingEFileStepsData || isFetchingTtiBinders || isCreatingBinder ? (
              <Loading small isLoading />
            ) : (
              <span className={styles.capitalizeStatus}>
                {getActionsBatchStatus(allSubsteps, expectedSubsteps, submitting)}
              </span>
            )}
            <AppkitIcon
              icon={collapsed ? 'down-chevron' : 'up-chevron'}
              size={15}
              onClick={toggleCollapsed}
            />
          </span>
          <div>
            {showDownloadLinks &&
              getDownloadFileComponent({
                data: allSubsteps.find(
                  ({ stepName }) => stepName === EFILE_ACTION.EF_SERVICE_VALIDATE_SUBMISSION,
                ),
              })}
            {showDownloadLinks &&
              getDownloadFileComponent({
                data: allSubsteps.find(
                  ({ stepName }) => stepName === EFILE_ACTION.EF_SERVICE_GENERATE_ZIP,
                ),
              })}
            {showDownloadLinks &&
              getDownloadFileComponent({
                data: allSubsteps.find(
                  ({ stepName }) =>
                    stepName === EFILE_ACTION.GET_STATE_TRANSMISSION_SCHEMA_VALIDATION,
                ),
              })}
            <ActionUpdatedTime action={latestAction} />
          </div>
        </div>
      </header>

      <main className={classNames(styles.indentSubsteps, { [styles.collapsed]: collapsed })}>
        {subStepsData
          .filter(({ actionsBatch }) => stepsToFilter.includes(actionsBatch))
          .map(({ actionsBatch, stepData }, i) => (
            <div className={styles.subStepBox} key={i}>
              {globalContext.isConsolidated && isStepSkippable(actionsBatch) && (
                <Checkbox
                  name={actionsBatch}
                  checked={stepsToSkip[actionsBatch]}
                  onChange={toggleStepSkip}
                >
                  Skip
                </Checkbox>
              )}
              {Object.keys(EFILE_GENERATE_XML_STEPS[actionsBatch]).map(step => (
                <Action
                  key={step}
                  actionName={step}
                  contextDebugInfo={contextDebugInfo}
                  title={EFILE_GENERATE_XML_STEPS[actionsBatch][step]}
                  data={getActionData(stepData, step)}
                  isLoading={isFetchingEFileStepsData || isFetchingTtiBinders}
                  isSubmitting={isSubmittingActions[actionsBatch]}
                  getDownloadFileComponent={getDownloadFileComponent}
                />
              ))}
            </div>
          ))}
      </main>

      <footer>
        <Button size="lg" onClick={onRunButtonClick} disabled={disabled}>
          {isContextLocked && (
            <AppkitIcon className="location-lock-icon" icon="location-locked" size={16} />
          )}
          Run
        </Button>
      </footer>
    </section>
  );
};

GenerateWithValidations.propTypes = {
  subStepsData: PropTypes.arrayOf(PropTypes.shape({})),
  globalContext: globalContextPropTypes,
  isStepSkippable: PropTypes.func,
  stepsToSkip: PropTypes.objectOf(PropTypes.bool),
  toggleStepSkip: PropTypes.func,
  contextDebugInfo: contextDebugInfoPropTypes,
  isFetchingEFileStepsData: PropTypes.bool,
  isSubmittingActions: PropTypes.object, // eslint-disable-line
  getDownloadFileComponent: PropTypes.func.isRequired,
  onRunButtonClick: PropTypes.func,
  disabled: PropTypes.bool,
  isContextLocked: PropTypes.bool,
  isFetchingTtiBinders: PropTypes.bool.isRequired,
  isCreatingBinder: PropTypes.bool.isRequired,
};

export default GenerateWithValidations;
