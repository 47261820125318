import { FederalReturnAttachments } from '../../shared/enums';

export const FEDERAL_RETURN_ATTACHMENT_OPTIONS = [
  { label: 'Linked', value: FederalReturnAttachments.LINKED },
  { label: 'Unlinked', value: FederalReturnAttachments.UNLINKED },
  { label: 'No XML', value: FederalReturnAttachments.NO_XML },
];

// due date days options: 1 - 31
export const DUE_DATE_DAY_OPTIONS = new Array(31)
  .fill(0)
  .map((_, num) => ({ label: (num + 1).toString(), value: num + 1 }));

// due date months options: 1 - 12
export const DUE_DATE_MONTH_OPTIONS = new Array(12)
  .fill(0)
  .map((_, num) => ({ label: (num + 1).toString(), value: num + 1 }));

// extended & second extended due date months options: 0 - 12
export const DUE_DATE_EXT_AND_SECOND_EXT_MONTH_OPTIONS = new Array(13)
  .fill(0)
  .map((_, num) => ({ label: num.toString(), value: num }));

export const DUE_DATE_COLUMN_WIDTH = 85;
