import React, { useCallback, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';

import AppkitIcon from '../appkitIcon/appkitIcon.component';

const LEFT_MENU_SELECTOR = '.left-menu';
const WITH_LEFT_SLIDE_IN_CLASS = 'with-left-slide-in';

type SlidingPaneProps = Parameters<typeof SlidingPane>[0];

interface SlideInProps extends SlidingPaneProps {
  closeIconClassName?: string;
  closeIconName?: string;
  titlePosition?: 'normal' | 'left';
}

const SlideIn = ({
  children = null,
  isOpen,
  closeIconClassName = '',
  closeIconName = 'left-chevron',
  overlayClassName = 'slide-in-overlay',
  titlePosition = 'normal',
  ...rest
}: SlideInProps) => {
  const addLeftMenuShadowEffect = useCallback(() => {
    const leftMenu = document.querySelector(LEFT_MENU_SELECTOR);
    if (leftMenu) {
      leftMenu.classList.add(WITH_LEFT_SLIDE_IN_CLASS);
    }
  }, []);

  const removeLeftMenuShadowEffect = useCallback(() => {
    const leftMenu = document.querySelector(LEFT_MENU_SELECTOR);
    if (leftMenu) {
      leftMenu.classList.remove(WITH_LEFT_SLIDE_IN_CLASS);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      addLeftMenuShadowEffect();
      return;
    }

    removeLeftMenuShadowEffect();

    return removeLeftMenuShadowEffect;
  }, [addLeftMenuShadowEffect, removeLeftMenuShadowEffect, isOpen]);

  return (
    <SlidingPane
      closeIcon={
        <AppkitIcon className={closeIconClassName} icon={closeIconName} size={16} type="fill" />
      }
      isOpen={isOpen}
      overlayClassName={`${overlayClassName} ${
        titlePosition === 'normal' ? '' : 'left-title-panel'
      }`}
      {...rest}
    >
      {children}
    </SlidingPane>
  );
};

export default SlideIn;
