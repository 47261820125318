const Yup = require('yup');

const getAddEditRoleSchema = ({ roles = [] }) =>
  Yup.object().shape({
    roleName: Yup.string()
      .label('Role Name')
      .max(30, 'Name cannot exceed 30 characters')
      .uniqueInList({
        message: 'Name already exists, a unique name is required before saving',
        list: roles,
        mapper: ({ roleName }) => roleName,
      })
      .required(),
    basedOnRoleId: Yup.string(),
  });

module.exports = { getAddEditRoleSchema };
