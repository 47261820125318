import { httpGetAndParse, httpPost, httpPut, httpDelete } from '../../../utils/fetchService';

const baseURL = '/api/admin/maintenance/businesses';

export const fetchBusinesses = () =>
  httpGetAndParse({
    route: baseURL,
    errorMessage: 'Fetching businesses failed',
  });

export const createBusiness = business =>
  httpPost({
    route: baseURL,
    errorMessage: 'Creating business failed',
    body: business,
  });

export const updateBusiness = business =>
  httpPut({
    route: baseURL,
    errorMessage: 'Updating business failed',
    body: business,
  });

export const deleteBusiness = ({ businessId }) =>
  httpDelete({
    route: `${baseURL}/${businessId}`,
    errorMessage: 'Deleting business failed',
  });
