const Yup = require('yup');

const { taxTypes } = require('../../sharedConstants');
const { filingTypes } = require('../../stateHelpers');

const createTaxRate = Yup.object()
  .shape({
    taxYear: Yup.string().required(),
    period: Yup.string().required(),
    jurisdictionId: Yup.string().required(),
    filingTypeId: Yup.string().oneOf(Object.values(filingTypes)).required(),
    startRange: Yup.number().required(),
    stopRange: Yup.number().nullable(),
    rate: Yup.number().required(),
    filingAttributesValues: Yup.object().required(),
  })
  .strict();

const updateTaxRate = Yup.object()
  .shape({
    taxYear: Yup.string().required(),
    period: Yup.string().required(),
    jurisdictionId: Yup.string().required(),
    filingTypeId: Yup.string().oneOf(Object.values(filingTypes)).required(),
    startRange: Yup.number().required(),
    oldStartRange: Yup.number().required(),
    stopRange: Yup.number().nullable(),
    oldStopRange: Yup.number().nullable(),
    rate: Yup.number().required(),
    filingAttributes: Yup.number().required(),
    filingAttributesValues: Yup.object().required(),
  })
  .strict();

const deleteTaxRate = Yup.object()
  .shape({
    taxYear: Yup.string().required(),
    period: Yup.string().required(),
    jurisdictionId: Yup.string().required(),
    filingTypeId: Yup.string().oneOf(Object.values(filingTypes)).required(),
    taxType: Yup.string().oneOf(Object.values(taxTypes)).required(),
    stopRange: Yup.number().nullable(),
    startRange: Yup.number().required(),
    filingAttributes: Yup.number().required(),
    calcOptions: Yup.string().nullable(),
  })
  .strict();

module.exports = { createTaxRate, updateTaxRate, deleteTaxRate };
