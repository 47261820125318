import { NOT_FILING, DEFAULT_PROFILE } from '@common-packages/shared-constants';

const isNotFilingValue = value => value === null || value === NOT_FILING;

export const setDefaultValuesForNotFilingJurisdiction = (data, newValue, colDef) => {
  if (colDef.field !== 'filingGroup') {
    return data;
  }

  return isNotFilingValue(newValue)
    ? {
        ...data,
        profileId: DEFAULT_PROFILE,
        nexusInd: false,
      }
    : data;
};
