const filingMethod = {
  Paper: 'PF',
  NotFiling: 'NF',
  Electronic: 'EF',
};

const filingStatus = {
  NotSubmitted: 'NS',
  Submitted: 'SUB',
  Rejected: 'REJ',
  Accepted: 'ACC',
  'Denied by IRS': 'DEN',
  Retired: 'RET',
  Unknown: 'NA',
  'Ready for Pick-Up': 'SUB',
};

const NOT_FILING = 'NOT FILING';
const DEFAULT_PROFILE = 'DEFAULT';

module.exports = {
  filingStatus,
  filingMethod,
  NOT_FILING,
  DEFAULT_PROFILE,
};
