import React from 'react';
import classNames from 'classnames';

import styles from './richSelectOptionRenderer.module.scss';

type OptionValue =
  | string
  | number
  | boolean
  | Date
  | symbol
  | Record<
      string | number | symbol,
      string | number | boolean | Date | symbol | Array<OptionValue> | null | undefined
    >
  | Array<OptionValue>
  | null
  | undefined;

const RichSelectOptionRenderer = ({
  value,
  valueFormatted,
}: {
  value: OptionValue;
  valueFormatted: string;
}) => {
  const hasClearAction = value === null && valueFormatted === 'Clear selected value';

  return (
    <span
      className={classNames({
        [styles.clearOption]: hasClearAction,
      })}
    >
      {valueFormatted}
    </span>
  );
};

export default RichSelectOptionRenderer;
