import { GetNotificationsResponse } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export const useQueryNotifications = ({
  enabled,
  onSuccess,
  refetchInterval,
}: {
  enabled: boolean;
  refetchInterval?: number | false;
  onSuccess: (notifications: GetNotificationsResponse) => void;
}) =>
  useCustomQuery<GetNotificationsResponse>([QueryKeys.Notifications.Data], {
    url: `/api/notifications`,
    enabled,
    initialData: [],
    errorMessage: 'Fetching notifications failed',
    onSuccess,
    refetchInterval,
  });
