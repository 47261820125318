import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Loading from '../../shared/displayComponents/loading.component';
import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';

import InitiateStepDownloadFile from './downloadLink.container';
import { getActionStatus, getLastUpdateDate } from './utils';
import styles from './styles.module.scss';

const Action = ({
  actionName,
  title,
  data,
  isSubmitting,
  isFetchingProformaStepsData,
  contextDebugInfo,
}) => {
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const errorMessage = data && {
    apiInput: {
      actionName,
      ...contextDebugInfo,
    },
    apiResponse: {
      ...data.ttiResponse,
    },
  };

  const toggleShowDebugInfo = useCallback(() => {
    setShowDebugInfo(!showDebugInfo);
  }, [showDebugInfo]);
  return (
    <div className={styles.actionBox}>
      <h6 className={styles.actionTitle}>{title}</h6>
      <div className={styles.spaceBetween}>
        <div className={styles.stepStatus}>
          Status:{' '}
          {isFetchingProformaStepsData ? (
            <Loading isLoading small />
          ) : (
            <div className={styles.capitalizeStatus}>
              {getActionStatus(data, isSubmitting)}
              {data && (
                <AppkitIcon
                  icon={showDebugInfo ? 'up-chevron' : 'down-chevron'}
                  size={15}
                  onClick={toggleShowDebugInfo}
                  className={styles.debugInfoIcon}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.inline}>
          {!isFetchingProformaStepsData && Boolean(data) && (
            <>
              <InitiateStepDownloadFile data={data} />
              <div className={styles.lastUpdateDate}>Last update: {getLastUpdateDate(data)}</div>
            </>
          )}
        </div>
      </div>
      {showDebugInfo && <pre>{JSON.stringify(errorMessage, null, 2)}</pre>}
    </div>
  );
};

Action.propTypes = {
  title: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  data: PropTypes.shape({
    status: PropTypes.string,
    createdOn: PropTypes.string,
    updatedOn: PropTypes.string,
    ttiResponse: PropTypes.shape({
      action: PropTypes.shape({
        fileGuid: PropTypes.string,
        operationId: PropTypes.number,
      }),
      validation: PropTypes.shape({
        status: PropTypes.object,
        result: PropTypes.object,
      }),
    }),
  }),
  isSubmitting: PropTypes.bool.isRequired,
  isFetchingProformaStepsData: PropTypes.bool.isRequired,

  contextDebugInfo: PropTypes.shape({
    stepName: PropTypes.string,
    globalContext: PropTypes.objectOf(PropTypes.string),
    clientId: PropTypes.string,
    submissionId: PropTypes.string,
    caseId: PropTypes.string,
    yearId: PropTypes.string,
    binderId: PropTypes.string,
  }),
};

export default Action;
