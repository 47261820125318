import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Tab, Tabs } from '@pwc/appkit-react';
import classNames from 'classnames';
import { useMachine } from '@xstate/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import {
  useMutationResetReturnEFileStatus,
  useMutationUpdateFederalReturnOption,
} from '../../../../shared/mutations/efileV2';
import { FederalReturnAttachments } from '../../../../shared/enums';
import {
  useActiveStep,
  useAuthorizeAndTransmit,
  useBinder,
  useCheckTransmissionStatus,
  useGetFederalReturn,
  useValidation,
  useValidationStepStatus,
} from '../../hooks';
import {
  WIZARD_MACHINE_EVENTS,
  WIZARD_MACHINE_STATES,
  WIZARD_STEPS,
  DENIED_STATUS,
} from '../../constants';
import config from '../../../../config';
import {
  TtiServiceRunningResult,
  ValidationContext,
  WizardStateMachineSetFederalSubmissionId,
} from '../../wizardStateMachine/types';
import wizardStateMachine from '../../wizardStateMachine';
import StickyFooter from '../../../../shared/displayComponents/stickyFooter/stickyFooter.component';
import { StepStatus, TransmissionStatus, WizardStepName } from '../../enums';
import { FederalReturnOption, TransmitterProvider } from '../../../../shared/enums/eFileV2';
import {
  customerPermissionsSelector,
  globalContextSelector,
} from '../../../../shared/store/selectors';
import { GlobalContext } from '../../../../../../common/types';
import {
  useQueryEFileBinderFilingsData,
  useQueryGetCheckTtiServiceRunning,
  useQueryGetTtiGroupValidation,
} from '../../../../shared/queries/eFileV2';
import useModal from '../../../../shared/hooks/useModal.hook';
import { OutdatedPreparerModal } from '../../../../shared/displayComponents/modals';
import { useQueryTaxReturn } from '../../../../shared/queries/taxReturns';
import hasPermissionToTransmitReturns from '../../../../development/dataModels/hasPermissionToTransmitReturns';
import useCheckWizardConditions from '../useCheckWizardConditions.hook';
import { QueryKeys } from '../../../../shared/queries';

import chooseActiveStepEvent from './chooseActiveStepEvent';
import styles from './useWizard.module.scss';

interface UseWizardHookProps {
  showPanel: () => void;
}

const useWizard = ({ showPanel }: UseWizardHookProps) => {
  const [machineState, sendMachineEvent] = useMachine(wizardStateMachine);

  const {
    showModal: showPreparerInfoOutdatedModal,
    modalProps: preparerInfoOutdatedProps,
  } = useModal();

  const {
    isReady: isGlobalContextReady,
    isConsolidated,
    apiRouteParamString: globalContextApiRouteParamString,
    taxYear,
  }: GlobalContext = useSelector(globalContextSelector);
  const customerPermissions = useSelector(customerPermissionsSelector);

  const [isCurrentStepReady, setIsCurrentStepReady] = useState(false); // the lifted state for step 4

  const ttiServiceRunningResult = machineState.context.ttiService.ttiServiceRunningResult;

  const { returnId } = useParams<{ returnId?: string }>();
  const { data: taxReturn, isFetching: isFetchingTaxReturn } = useQueryTaxReturn({
    params: { returnId: returnId || '', apiRouteParamString: globalContextApiRouteParamString },
    enabled: Boolean(returnId),
  });
  const isFetchingTaxReturnOrTransmitterProviderTR =
    isFetchingTaxReturn || taxReturn?.selectedTransmitter === TransmitterProvider.TR;

  const binder = useBinder(!isFetchingTaxReturnOrTransmitterProviderTR);
  let { ttiCaseData, ttiYearId, ttiBinderId } = binder;
  const { sltBinderId, binderFilingId } = binder;

  const {
    data: returnInformation,
    isLoading: isLoadingReturnInformation,
  } = useQueryEFileBinderFilingsData({
    params: {
      sltBinderId: Number(returnId),
      apiRouteParamString: globalContextApiRouteParamString,
    },
    enabled: Boolean(returnId) && isGlobalContextReady,
  });

  if (ttiYearId === null || ttiBinderId === null || ttiCaseData?.ttiCaseId === null) {
    ttiYearId = returnInformation?.ttiYearId;
    ttiBinderId = returnInformation?.ttiBinderId;
    ttiCaseData = {
      ttiCaseId: returnInformation?.ttiCaseId || null,
      ttiCaseName: ttiCaseData?.ttiCaseName || null,
      stateCode: ttiCaseData?.stateCode || null,
    };
  }

  const [selectedTransmissionHistoryId, setSelectedTransmissionHistoryId] = useState<string>('');

  if (!selectedTransmissionHistoryId && returnInformation?.currentTransmissionHistoryId) {
    setSelectedTransmissionHistoryId(
      returnInformation?.currentTransmissionHistoryId?.toString() ?? '',
    );
  }

  const isCurrentSubmissionSelected =
    (returnInformation?.currentTransmissionHistoryId?.toString() ?? '') ===
    selectedTransmissionHistoryId;

  const isTransmitReturnsButtonEnabled = hasPermissionToTransmitReturns(customerPermissions);

  const setValidationContext = useCallback(
    (validation: ValidationContext) => {
      sendMachineEvent({ type: WIZARD_MACHINE_EVENTS.SET_VALIDATION, value: validation });
    },
    [sendMachineEvent],
  );

  const setTtiServiceRunningResult = useCallback(
    (ttiServiceRunningResult: TtiServiceRunningResult) => {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_IS_TTI_SERVICE_RUNNING_RESULT,
        value: ttiServiceRunningResult,
      });
    },
    [sendMachineEvent],
  );

  const {
    data: isTtiServiceRunningData,
    isFetching: isFetchingCheckTtiServiceRunning,
    refetch: refetchCheckTtiServiceRunning,
  } = useQueryGetCheckTtiServiceRunning({
    enabled: !isFetchingTaxReturnOrTransmitterProviderTR,
    cacheTime: 0,
    onSuccess: ttiServiceRunningData => setTtiServiceRunningResult(ttiServiceRunningData),
    params: { taxYear: taxYear || '' },
  });

  const validation = useValidation({
    ttiCaseId: ttiCaseData?.ttiCaseId,
    ttiYearId,
    sltBinderId,
    isFetchingTaxReturnOrTransmitterProviderTR,
    sendMachineEvent,
  });

  const {
    data: ttiGroupValidationData = null,
    isFetched: isTtiGroupValidationDataFetched,
  } = useQueryGetTtiGroupValidation({
    params: {
      globalContextApiRouteParamString,
      ttiCaseId: ttiCaseData?.ttiCaseId,
      ttiYearId,
      sltBinderId,
    },
    enabled: Boolean(
      isGlobalContextReady &&
        isConsolidated &&
        ttiCaseData?.ttiCaseId &&
        ttiYearId &&
        sltBinderId &&
        !isFetchingTaxReturnOrTransmitterProviderTR &&
        isTtiServiceRunningData?.isTtiServiceRunning,
    ),
  });

  const federalReturnResponse = useGetFederalReturn({
    isInitialWizardState: true,
  });

  const { federalReturn, isFetchingFederalReturn } = federalReturnResponse;

  const { isFetchingSteps, areStepsFetched } = useActiveStep({
    sendMachineEvent,
    sltBinderId,
    stepName: Object.values(WizardStepName).includes(machineState.value as WizardStepName)
      ? (machineState.value as WizardStepName)
      : null,
    isRunningValidation: validation.isRunningValidationProcess,
    federalReturn,
  });

  const {
    isValidationStepCompletedAndErrorsAreFetched,
    shouldFetchValidationInfoDuringInit,
  } = useValidationStepStatus({
    isFetchingSteps,
    areStepsFetched,
    setValidationContext,
    machineStateValue: machineState.value,
    machineStateContext: machineState.context,
    isFetchingTaxReturnOrTransmitterProviderTR,
  });

  const {
    handleCheckTransmissionStatus,
    isCheckingTransmissionStatus,
    isReadyToCheckTransmissionStatus,
  } = useCheckTransmissionStatus({
    ttiYearId,
    ttiCaseId: ttiCaseData?.ttiCaseId,
    sltBinderId,
    ttiBinderId,
    binderFilingId,
    wizardStateMachineContext: machineState.context,
    taxReturn,
  });

  const handleRecheckStatus = useCallback(async () => {
    if (!isFetchingTaxReturnOrTransmitterProviderTR) {
      await refetchCheckTtiServiceRunning();

      if (!ttiServiceRunningResult?.isTtiServiceRunning) {
        return;
      }
    }

    sendMachineEvent({
      type: WIZARD_MACHINE_EVENTS.SET_STEP_STATUS,
      value: {
        sltBinderId,
        stepName: WizardStepName.STEP_5_VIEW_STATUS,
        status: StepStatus.INITIATED,
      },
    });
    handleCheckTransmissionStatus();
  }, [
    refetchCheckTtiServiceRunning,
    ttiServiceRunningResult?.isTtiServiceRunning,
    sendMachineEvent,
    sltBinderId,
    handleCheckTransmissionStatus,
    isFetchingTaxReturnOrTransmitterProviderTR,
  ]);

  const isFederalReturnAttachmentValid =
    federalReturn &&
    (federalReturn.federalReturnAttachment === FederalReturnAttachments.LINKED ||
      federalReturn.federalReturnAttachment === FederalReturnAttachments.UNLINKED ||
      federalReturn.federalReturnAttachment === FederalReturnAttachments.NO_XML);

  const isWizardMachineReady = useMemo(
    () =>
      isGlobalContextReady &&
      !!sltBinderId &&
      areStepsFetched &&
      !isFetchingSteps &&
      (isFetchingTaxReturnOrTransmitterProviderTR ||
        (Boolean(ttiServiceRunningResult) &&
          (!isConsolidated ||
            !ttiServiceRunningResult?.isTtiServiceRunning ||
            isTtiGroupValidationDataFetched))) &&
      !isFetchingFederalReturn &&
      !isFetchingTaxReturn &&
      (shouldFetchValidationInfoDuringInit ? isValidationStepCompletedAndErrorsAreFetched : true) &&
      Boolean(taxReturn?.selectedTransmitter) && // only on first fetch
      !isLoadingReturnInformation,
    [
      isGlobalContextReady,
      sltBinderId,
      areStepsFetched,
      isFetchingSteps,
      shouldFetchValidationInfoDuringInit,
      isValidationStepCompletedAndErrorsAreFetched,
      isTtiGroupValidationDataFetched,
      isConsolidated,
      ttiServiceRunningResult,
      isFetchingFederalReturn,
      taxReturn?.selectedTransmitter,
      isFetchingTaxReturnOrTransmitterProviderTR,
      isFetchingTaxReturn,
    ],
  );

  useEffect(() => {
    if (machineState.value !== WIZARD_MACHINE_STATES.INITIALIZING) {
      return;
    }

    if (!isWizardMachineReady) {
      return;
    }

    const activeStepEvent = chooseActiveStepEvent(machineState.context);
    sendMachineEvent(activeStepEvent);
  }, [sendMachineEvent, isWizardMachineReady, machineState]);

  const authorization = useAuthorizeAndTransmit({
    isFetchingTaxReturnOrTransmitterProviderTR,
    ttiCaseId: ttiCaseData?.ttiCaseId,
    ttiYearId,
    ttiBinderId,
    efServiceSubmissionId: machineState.context.eFileService.submissionId,
    stateSubmissionId: machineState.context.stateReturn.submissionId,
    binderFilingId,
    sltBinderId,
  });

  const {
    mutateAsync: updateFederalReturnOption,
    isLoading: isUpdatingFederalReturnOption,
  } = useMutationUpdateFederalReturnOption();

  const currentDate = new Date();
  const pwcEINUpdateDate = new Date(config.PWC_EIN_UPDATE_DATE);

  const isPreparerInfoOutdated =
    currentDate >= pwcEINUpdateDate && !federalReturn.isPreparerInfoUpToDate;

  const handleRerunValidation = useCallback(async () => {
    if (!isFetchingTaxReturnOrTransmitterProviderTR) {
      await refetchCheckTtiServiceRunning();
    }

    if (
      federalReturn?.isLockedInd ||
      !federalReturn?.isActive ||
      (!isFetchingTaxReturnOrTransmitterProviderTR && !ttiServiceRunningResult?.isTtiServiceRunning)
    ) {
      return;
    }

    if (isPreparerInfoOutdated) {
      showPreparerInfoOutdatedModal();

      return;
    }

    sendMachineEvent({
      type: WIZARD_MACHINE_EVENTS.SET_STEP_STATUS,
      value: {
        sltBinderId,
        stepName: WizardStepName.STEP_2_VALIDATE,
        status: StepStatus.INITIATED,
      },
    });

    validation.handleRunValidation();
  }, [
    refetchCheckTtiServiceRunning,
    showPreparerInfoOutdatedModal,
    ttiServiceRunningResult?.isTtiServiceRunning,
    federalReturn?.isLockedInd,
    federalReturn?.isActive,
    sendMachineEvent,
    sltBinderId,
    isPreparerInfoOutdated,
    validation,
    isFetchingTaxReturnOrTransmitterProviderTR,
  ]);

  const {
    mutateAsync: resetReturnEFileStatus,
    isLoading: isResetingReturnEFileStatus,
  } = useMutationResetReturnEFileStatus();
  const queryClient = useQueryClient();

  const handleStartNewEFile = useCallback(async () => {
    if (!isFetchingTaxReturnOrTransmitterProviderTR) {
      await refetchCheckTtiServiceRunning();

      if (!ttiServiceRunningResult?.isTtiServiceRunning) {
        return;
      }
    }

    await resetReturnEFileStatus({
      returnId: Number(returnId),
    });
    queryClient.resetQueries([
      QueryKeys.EFileSupport.ResetReturnEFileStatus,
      {
        returnId: returnId,
      },
    ]);

    sendMachineEvent(WIZARD_MACHINE_EVENTS.SET_STEP_1);
  }, [
    resetReturnEFileStatus,
    refetchCheckTtiServiceRunning,
    sendMachineEvent,
    sltBinderId,
    ttiServiceRunningResult?.isTtiServiceRunning,
    machineState.context.transmission.status,
    isFetchingTaxReturnOrTransmitterProviderTR,
  ]);

  const handleNextButton = useCallback(async () => {
    if (isPreparerInfoOutdated) {
      showPreparerInfoOutdatedModal();

      return;
    }

    if (machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN) {
      if (!isFetchingTaxReturnOrTransmitterProviderTR) {
        await refetchCheckTtiServiceRunning();

        if (!ttiServiceRunningResult?.isTtiServiceRunning) {
          return;
        }
      }
    }

    if (machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN && sltBinderId) {
      const updateFederalReturnOptionResponse = await updateFederalReturnOption({
        federalReturnOption: machineState.context.federalReturn.federalReturnOption,
        sltBinderId,
        federalSubmissionId: machineState.context.federalReturn.submissionId,
      });

      if (updateFederalReturnOptionResponse.isDifferentFederalReturnOptionSelected) {
        sendMachineEvent({
          type: WIZARD_MACHINE_EVENTS.SET_STEP_STATUS,
          value: {
            sltBinderId,
            stepName: WizardStepName.STEP_1_CHOOSE_FEDERAL_RETURN,
            status: StepStatus.COMPLETED,
          },
        });

        await handleRerunValidation();
        sendMachineEvent(WIZARD_MACHINE_EVENTS.NEXT);

        return;
      }
    }

    // manually set step 1 and step 3 as COMPLETED when clicking the Next button (as there are no sub-steps on the backend)
    if (
      machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN ||
      machineState.value === WIZARD_MACHINE_STATES.STEP_3_REVIEW
    ) {
      machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN
        ? sendMachineEvent({
            type: WIZARD_MACHINE_EVENTS.SET_STEP_STATUS,
            value: {
              sltBinderId,
              stepName: WizardStepName.STEP_1_CHOOSE_FEDERAL_RETURN,
              status: StepStatus.COMPLETED,
            },
          })
        : sendMachineEvent({
            type: WIZARD_MACHINE_EVENTS.SET_STEP_STATUS,
            value: {
              sltBinderId,
              stepName: WizardStepName.STEP_3_REVIEW,
              status: StepStatus.COMPLETED,
            },
          });
    }

    // Trigger validation when going from step 1 to step 2 and when step 2 status is null
    if (
      machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN &&
      !machineState.context.eFileSteps[WizardStepName.STEP_2_VALIDATE]
    ) {
      handleRerunValidation();
    }

    sendMachineEvent(WIZARD_MACHINE_EVENTS.NEXT);
  }, [
    showPreparerInfoOutdatedModal,
    machineState.value,
    machineState.context.eFileSteps,
    machineState.context.federalReturn.federalReturnOption,
    machineState.context.federalReturn.submissionId,
    sltBinderId,
    sendMachineEvent,
    refetchCheckTtiServiceRunning,
    ttiServiceRunningResult?.isTtiServiceRunning,
    updateFederalReturnOption,
    handleRerunValidation,
    isPreparerInfoOutdated,
    isFetchingTaxReturnOrTransmitterProviderTR,
  ]);

  const handleBackButton = useCallback(() => {
    sendMachineEvent(WIZARD_MACHINE_EVENTS.BACK);
  }, [sendMachineEvent]);

  const handleTransmitButton = useCallback(async () => {
    if (!isFetchingTaxReturnOrTransmitterProviderTR) {
      await refetchCheckTtiServiceRunning();

      if (!ttiServiceRunningResult?.isTtiServiceRunning) {
        return;
      }
    }

    if (isPreparerInfoOutdated) {
      showPreparerInfoOutdatedModal();

      return;
    }

    sendMachineEvent({
      type: WIZARD_MACHINE_EVENTS.SET_STEP_STATUS,
      value: {
        sltBinderId,
        stepName: WizardStepName.STEP_4_AUTHORIZE_AND_TRANSMIT,
        status: StepStatus.INITIATED,
      },
    });
    authorization.handleAuthorizationAndTransmission();
  }, [
    showPreparerInfoOutdatedModal,
    authorization,
    refetchCheckTtiServiceRunning,
    sendMachineEvent,
    sltBinderId,
    ttiServiceRunningResult?.isTtiServiceRunning,
    isPreparerInfoOutdated,
    isFetchingTaxReturnOrTransmitterProviderTR,
  ]);

  const setFederalReturnOption = useCallback(
    (federalReturnOption: FederalReturnOption | null) => {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_FEDERAL_RETURN_OPTION,
        value: federalReturnOption,
      });
    },
    [sendMachineEvent],
  );

  const setFederalSubmissionId = useCallback(
    (submissionId: WizardStateMachineSetFederalSubmissionId) => {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_FEDERAL_SUBMISSION_ID,
        value: submissionId,
      });
    },
    [sendMachineEvent],
  );

  const setStateSubmissionId = useCallback(
    (submissionId: string) => {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_STATE_SUBMISSION_ID,
        value: submissionId,
      });
    },
    [sendMachineEvent],
  );

  const setTransmissionStatus = useCallback(
    (status: TransmissionStatus) => {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_TRANSMISSION_STATUS,
        value: status,
      });
    },
    [sendMachineEvent],
  );

  const handleTransmitReturnsButtonTitle = useMemo(() => {
    if (!isTransmitReturnsButtonEnabled) return 'Transmission disabled due to IRS downtime.';

    return;
  }, [isTransmitReturnsButtonEnabled]);

  const wizard = (
    <div className={styles.wizard}>
      <Tabs centered size="md" value={machineState.value}>
        {WIZARD_STEPS.map(step => (
          <Tab key={step.machineState} value={step.machineState} label={step.label} />
        ))}
      </Tabs>
    </div>
  );
  const {
    areButtonsRecheckingStatusDisabled,
    isBackButtonDisabled,
    isRecheckStatusButtonVisible,
    isRunValidationButtonDisabled,
    isRunValidationButtonLoading,
    isTransmitButtonDisabled,
    isNextButtonDisableStep2,
    isNextButtonDisableForStep1,
    isNextButtonLoadingForStep1,
    isRetrieveAcknowledgementButtonDisabled,
    isViewDownloadsButtonDisabled,
  } = useCheckWizardConditions({
    isFetchingTaxReturnOrTransmitterProviderTR,
    isFetchingCheckTtiServiceRunning,
    taxReturn,
    machineState,
    isRunValidationOrParamsNotReady: validation.isRunValidationOrParamsNotReady,
    ttiGroupValidationData,
    isCurrentSubmissionSelected,
    selectedTransmissionHistoryId,
    isCheckingTransmissionStatus,
    isUpdatingFederalReturnOption,
    handleRecheckStatus,
    isCurrentStepReady,
    isTransmitReturnsButtonEnabled,
    isValidationReRunRequired: federalReturn.isValidationReRunRequired,
    ttiCaseId: ttiCaseData?.ttiCaseId,
    ttiYearId: ttiYearId,
  });

  const wizardButtons = (
    <StickyFooter
      className={classNames({
        [styles.firstStepNextButton]:
          machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN,
        [styles.lastStepRecheckStatusButton]:
          machineState.value === WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS,
      })}
    >
      {machineState.value !== WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN &&
        machineState.value !== WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS && (
          <Button
            disabled={isBackButtonDisabled}
            size="lg"
            onClick={handleBackButton}
            kind="secondary"
            gray
          >
            Back
          </Button>
        )}
      <div className={styles.rightGroupedNavigationButtons}>
        {machineState.value === WIZARD_MACHINE_STATES.STEP_2_VALIDATE && (
          <>
            <Button
              size="lg"
              disabled={isViewDownloadsButtonDisabled}
              onClick={showPanel}
              kind="transparent"
            >
              View Downloads
            </Button>
            {/* TODO: Reuse rerun validation component from admin/efile/support */}
            <Button
              size="lg"
              isLoading={isRunValidationButtonLoading}
              disabled={isRunValidationButtonDisabled}
              onClick={handleRerunValidation}
              kind="secondary"
            >
              Rerun Validation
            </Button>
          </>
        )}
        {(machineState.value === WIZARD_MACHINE_STATES.STEP_1_CHOOSE_FEDERAL_RETURN ||
          machineState.value === WIZARD_MACHINE_STATES.STEP_3_REVIEW) && (
          <Button
            size="lg"
            onClick={handleNextButton}
            isLoading={isNextButtonLoadingForStep1}
            disabled={isNextButtonDisableForStep1}
          >
            Next
          </Button>
        )}
        {machineState.value === WIZARD_MACHINE_STATES.STEP_2_VALIDATE && (
          <Button size="lg" onClick={handleNextButton} disabled={isNextButtonDisableStep2}>
            Next
          </Button>
        )}

        {machineState.value === WIZARD_MACHINE_STATES.STEP_4_AUTHORIZE_AND_TRANSMIT && (
          <Button
            size="lg"
            onClick={handleTransmitButton}
            isLoading={isFetchingCheckTtiServiceRunning}
            disabled={isTransmitButtonDisabled}
            title={handleTransmitReturnsButtonTitle}
          >
            Transmit
          </Button>
        )}
        {isRetrieveAcknowledgementButtonDisabled}
        {isRecheckStatusButtonVisible && (
          <Button
            size="lg"
            onClick={handleRecheckStatus}
            isLoading={isFetchingCheckTtiServiceRunning}
            disabled={areButtonsRecheckingStatusDisabled}
          >
            Recheck Status
          </Button>
        )}
        {machineState.value === WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS &&
          isCurrentSubmissionSelected &&
          (machineState.context.transmission.status === TransmissionStatus[DENIED_STATUS] ||
            machineState.context.transmission.status === TransmissionStatus.REJECTED) && (
            <Button
              size="lg"
              isLoading={isResetingReturnEFileStatus}
              disabled={isRunValidationButtonDisabled}
              onClick={handleStartNewEFile}
            >
              START A NEW E-FILE
            </Button>
          )}
        {preparerInfoOutdatedProps.visible && (
          <OutdatedPreparerModal {...preparerInfoOutdatedProps} />
        )}
      </div>
    </StickyFooter>
  );

  return {
    currentStep: machineState.value,
    machineStateContext: machineState.context,
    wizard,
    wizardButtons,
    setIsCurrentStepReady,
    isWizardMachineReady,
    setStateSubmissionId,
    validation,
    authorization,
    binder: { sltBinderId, ttiCaseData, ttiYearId, ttiBinderId, binderFilingId },
    setValidationContext,
    federalReturnResponse,
    setFederalReturnOption,
    setFederalSubmissionId,
    isCheckingTransmissionStatus,
    isReadyToCheckTransmissionStatus,
    setTransmissionStatus,
    handleCheckTransmissionStatus,
    ttiServiceRunningResult,
    isFederalReturnAttachmentValid,
    sendMachineEvent,
    federalReturnAttachment: federalReturn.federalReturnAttachment,
    isFetchingTaxReturnOrTransmitterProviderTR,
    taxReturn,
    stateSubmissionId: returnInformation?.ttiSubmissionId?.toString() ?? '',
    setSelectedTransmissionHistoryId,
    selectedTransmissionHistoryId,
  };
};

export default useWizard;
