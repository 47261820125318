import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import * as api from '../../entity/entityInformation/store/api';
import { HttpMethods } from '../enums';
import { QueryError, QueryKeys } from '../queries';
import {
  FindTaxReturnResponse,
  InsertReturnBody,
  UpdateReturnBody,
  UpdatePartialReturnBody,
  UpdateReturnStatusParams,
  FindTaxReturnsResponse,
  TaxYear,
  JurisdictionId,
  Period,
  EntityId,
  TaxReturn,
  FindConsolidatedTaxReturnsResponse,
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
  FindConsolidationsDetailsData,
} from '../../../../common/types/apiShapes';
import { fetchFilingStates } from '../../filingStates/store/actions';

import { useCustomMutation } from '.';

interface DeleteTaxReturnVariable {
  returnId: string | number;
}

type ValueType = string | number | null | undefined;

interface GlobalInformationVariable {
  rowsToUpdate: Record<string, ValueType | Record<string, ValueType>>[];
  taxYear: TaxYear | null;
  jurisdictionId: JurisdictionId | null;
  period: Period | null;
  entityId: EntityId | null | undefined;
}

export const useMutationInsertReturn = (taxReturns: TaxReturn[]) => {
  const dispatch = useDispatch();
  return useCustomMutation<null, QueryError, Omit<InsertReturnBody, 'dueDate'>>({
    handleData: data => ({
      url: '/api/tax-returns',
      method: HttpMethods.POST,
      body: data,
    }),
    resetKey: query =>
      query.queryKey?.[0] === QueryKeys.TaxReturns.Data ||
      query.queryKey?.[0] === QueryKeys.TaxReturns.ReturnDefinitions,
    successMessage: 'Return added successfully',
    errorMessage: 'An error occurred while adding the return',
    onSuccess: (_, variables) => {
      const { taxYear, period, entityId, jurisdictionId } = variables;

      // if add the first return to a jurisdiction
      if (!taxReturns.find(i => i.jurisdictionId === jurisdictionId)?.filingInState) {
        dispatch(fetchFilingStates({ taxYear, period, entityId }));
      }
    },
  });
};

export const useMutationUpdateReturn = () =>
  useCustomMutation<null, QueryError, UpdatePartialReturnBody | UpdateReturnBody>({
    handleData: data => ({
      url: `/api/tax-returns/${data.returnId}`,
      method: HttpMethods.PUT,
      body: data,
    }),
    resetKey: query =>
      query.queryKey?.[0] === QueryKeys.TaxReturns.Data ||
      query.queryKey?.[0] === QueryKeys.TaxReturns.ReturnDefinitions ||
      query.queryKey?.[0] === QueryKeys.TaxReturns.TaxReturn,
    successMessage: 'Return updated successfully',
    errorMessage: 'An error occurred while updating the return',
  });

export const useUpdateReturnStatus = () => {
  const queryClient = useQueryClient();
  return useCustomMutation<null, QueryError, UpdateReturnStatusParams>({
    handleData: ({ taxReturnStatus, returnId }) => ({
      url: `/api/tax-returns/return-status/${returnId}`,
      method: HttpMethods.PUT,
      body: { taxReturnStatus, returnId },
    }),
    successMessage: 'Return status updated successfully',
    errorMessage: 'An error occurred while updating return status',
    onMutate: async variables => {
      if (variables.optimisticUpdateTaxReturnsParams) {
        const {
          taxYear,
          period,
          entityId,
          isSeparateReturn,
        } = variables.optimisticUpdateTaxReturnsParams;
        const queryParams = isSeparateReturn ? { taxYear, period, entityId } : { taxYear, period };
        await queryClient.cancelQueries([QueryKeys.TaxReturns.Data, queryParams]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        queryClient.setQueryData<any>(
          [QueryKeys.TaxReturns.Data, queryParams],
          (
            oldTaxReturns: FindTaxReturnsResponse | FindConsolidatedTaxReturnsResponse | undefined,
          ) => {
            if (oldTaxReturns) {
              return {
                ...oldTaxReturns,
                data: oldTaxReturns.data.map(taxReturnItem => {
                  const isUpdatedTaxReturn = isSeparateReturn
                    ? (taxReturnItem as TaxReturnResultRowWithCalcOptionsAndFilingAttributes)
                        ?.taxReturn?.returnId === variables.returnId
                    : (taxReturnItem as FindConsolidationsDetailsData)?.returnId ===
                      variables.returnId;
                  if (isUpdatedTaxReturn) {
                    return {
                      ...taxReturnItem,
                      ...(isSeparateReturn
                        ? {
                            taxReturn: {
                              ...(taxReturnItem as TaxReturnResultRowWithCalcOptionsAndFilingAttributes)
                                .taxReturn,
                              returnStatus: variables.taxReturnStatus,
                            },
                          }
                        : { returnStatus: variables.taxReturnStatus }),
                    };
                  }
                  return taxReturnItem;
                }),
              };
            }
            return oldTaxReturns;
          },
        );
      }
      await queryClient.cancelQueries([
        QueryKeys.TaxReturns.TaxReturn,
        { returnId: `${variables.returnId}` },
      ]);
      queryClient.setQueryData<FindTaxReturnResponse | undefined>(
        [QueryKeys.TaxReturns.TaxReturn, { returnId: `${variables.returnId}` }],
        old => {
          if (old) {
            return { ...old, returnStatus: variables.taxReturnStatus };
          }
        },
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.ReturnStatusTackerDetails.Data]);
    },
    onError: () => queryClient.resetQueries(QueryKeys.TaxReturns.Data),
  });
};

export const useMutationDeleteTaxReturn = (taxReturns: TaxReturn[]) => {
  const dispatch = useDispatch();

  return useCustomMutation<null, QueryError, DeleteTaxReturnVariable>({
    handleData: ({ returnId }) => ({
      url: `/api/tax-returns/${returnId}`,
      method: HttpMethods.DELETE,
    }),
    resetKey: query =>
      query.queryKey?.[0] === QueryKeys.TaxReturns.Data ||
      query.queryKey?.[0] === QueryKeys.TaxReturns.ReturnDefinitions,
    successMessage: 'Tax return deleted successfully',
    errorMessage: 'An error occurred while deleting a tax return',
    parseResponseErrorMessage: true,
    onSuccess: (_, variables) => {
      const { jurisdictionId, taxYear, period, entityId } =
        taxReturns.find(({ taxReturn }) => taxReturn?.returnId === variables.returnId) || {};

      // if delete the last return for a jurisdiction
      if (
        taxReturns.filter(i => i.jurisdictionId === jurisdictionId).length === 1 &&
        taxReturns.find(i => i.jurisdictionId === jurisdictionId)?.filingInState
      ) {
        dispatch(fetchFilingStates({ taxYear, period, entityId }));
      }
    },
  });
};

export const useMutationUpdateGlobalInformation = () =>
  useCustomMutation<null, QueryError, GlobalInformationVariable>({
    customMutate: api.updateGlobalInformation,
    successMessage: 'Global information has been successfully updated',
    errorMessage: 'Updating global information failed',
  });
