import {
  homeRoute,
  complianceWorkspaceRoutes,
  entityRoutes,
  consolidationRoutes,
  stateFilingGroupRoutes,
  federalProFormaFilingGroupRoutes,
  filingGroupRoutes,
  reportRoutes,
  adminRoutes,
  developmentRoutes,
  toolsRoutes,
  efileRoutes,
  proformaRoutes,
  taxSummariesRoutes,
  returnWorkspaceRoutes,
  otherRoutes,
} from '@common-packages/routes-definitions';
import { PERMISSION } from '@common-packages/customer-permissions-utils';

export default [
  {
    ...homeRoute,
  },
  {
    displayMainName: () => 'Compliance Routes',
    MAIN: 'compliance-folder',
    type: 'folder',
    children: [
      ...Object.values(complianceWorkspaceRoutes),
      {
        displayMainName: () => 'Entity Routes',
        MAIN: 'entity-folder',
        type: 'folder',
        children: Object.values(entityRoutes),
      },
      {
        displayMainName: () => 'Consolidations Routes',
        MAIN: 'consolidation-folder',
        type: 'folder',
        children: Object.values(consolidationRoutes),
      },
      {
        displayMainName: () => 'Filing groups Routes',
        MAIN: 'filing-group-folder',
        type: 'folder',
        children: Object.values(stateFilingGroupRoutes),
      },
      {
        displayMainName: () => 'Tools Routes',
        MAIN: 'tools-folder',
        type: 'folder',
        children: Object.values(toolsRoutes),
      },
      {
        displayMainName: () => 'Federal Pro Forma Filing Groups Routes',
        MAIN: 'federal-proforma-filing-groups-folder',
        type: 'folder',
        children: Object.values(federalProFormaFilingGroupRoutes),
      },
      {
        displayMainName: () => 'Filing Groups (New) Routes',
        MAIN: 'filing-groups-folder',
        type: 'folder',
        children: Object.values(filingGroupRoutes),
      },
      {
        displayMainName: () => 'Return Workspace Routes',
        MAIN: 'return-workspace-folder',
        type: 'folder',
        children: Object.values(returnWorkspaceRoutes),
      },
    ],
  },
  {
    displayMainName: () => 'Planning & Reports Routes',
    MAIN: 'planning-and-reports-folder',
    type: 'folder',
    children: Object.values(reportRoutes),
  },
  {
    displayMainName: () => 'Admin Routes',
    MAIN: 'admin-folder',
    type: 'folder',
    children: Object.values(adminRoutes),
  },
  {
    displayMainName: () => 'Development Routes',
    MAIN: 'dev-folder',
    type: 'folder',
    children: Object.values(developmentRoutes),
  },
  {
    displayMainName: () => 'E-File Routes',
    MAIN: 'e-file-folder',
    type: 'folder',
    children: Object.values(efileRoutes),
  },
  {
    displayMainName: () => 'Pro-Forma Routes',
    MAIN: 'pro-form-folder',
    type: 'folder',
    children: Object.values(proformaRoutes),
  },
  {
    displayMainName: () => 'Tax Summaries Routes',
    MAIN: 'tax-summaries-folder',
    type: 'folder',
    children: Object.values(taxSummariesRoutes),
  },
  {
    displayMainName: () => 'Other Routes',
    MAIN: 'other-routes-folder',
    type: 'folder',
    children: Object.values(otherRoutes),
  },
  {
    displayMainName: () => 'Other Permissions',
    MAIN: 'permissions-folder',
    type: 'folder',
    children: Object.entries(PERMISSION).reduce(
      (acc, [key, value]) => [
        ...acc,
        {
          MAIN: value,
          displayMainName: () => key,
          id: key,
        },
      ],
      [],
    ),
  },
];
