import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';

import StateSpecificInformation from './stateSpecificInformation.container';

const StateSpecificInformationWithContext = withContextWrapper()(StateSpecificInformation);

const StateSpecificInformationPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(GlobalContextPage);

const StateSpecificInformationRoutes = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.stateSpecificInformationV2.MAIN}
    wrapper={StateSpecificInformationPage}
    component={StateSpecificInformationWithContext}
    hideGlobalContext
    disableBreadcrumbs
  />
);

export default StateSpecificInformationRoutes;
