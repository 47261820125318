import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Dropzone from '../../shared/forms/dropzoneFormik/dropzoneFormik.component';
import Loading from '../../shared/displayComponents/loading.component';

import { uploadTypes } from './constants';

const DOCUMENT_ACCEPT_TYPES = ['.xml'];

const UploadSubmissionForm = ({
  values,
  isFetchingCaseList,
  caseList,
  isFetchingYearSubPeriodList,
  yearSubPeriodList,
}) => (
  <form>
    <Field
      label="Submission Id"
      name="submissionId"
      className="modal-field"
      disabled
      component={Input}
      autoComplete="off"
    />
    <Field
      label="Entity"
      name="taxPayer"
      className="modal-field"
      disabled
      component={Input}
      autoComplete="off"
    />
    <Field
      label="Jurisdiction"
      name="jurisdiction"
      className="modal-field"
      disabled
      component={Input}
      autoComplete="off"
    />
    <Select
      value={values.uploadType}
      appkitLabel="Upload Type"
      name="uploadType"
      wrapperClassName="modal-field"
      options={[uploadTypes.SUBMISSION, uploadTypes.MAPPING]}
    />
    {values.uploadType === 'submission' ? (
      <Loading small isLoading={isFetchingCaseList || isFetchingYearSubPeriodList}>
        <Select
          appkitLabel="Case ID"
          name="TTICaseId"
          wrapperClassName="modal-field"
          options={caseList}
          disabled={values.uploadType === uploadTypes.MAPPING.value}
        />
        <Select
          appkitLabel="Year ID"
          name="TTIYearId"
          wrapperClassName="modal-field"
          options={yearSubPeriodList}
          disabled={values.uploadType === uploadTypes.MAPPING.value}
        />
      </Loading>
    ) : null}
    {values.uploadType === uploadTypes.MAPPING.value ? (
      <Dropzone
        className="form-text"
        name="file"
        label="Select document"
        value={values.file}
        acceptTypes={DOCUMENT_ACCEPT_TYPES}
      />
    ) : null}
  </form>
);

UploadSubmissionForm.propTypes = {
  caseList: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingCaseList: PropTypes.bool.isRequired,
  yearSubPeriodList: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingYearSubPeriodList: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    uploadType: PropTypes.string,
    file: PropTypes.instanceOf(File),
  }),
};

export default UploadSubmissionForm;
