const Yup = require('yup');

const { RETURN_TYPES } = require('../sharedConstants/filingAttributes');

const getReturnTypeSchema = () => {
  return {
    RETURN_TYPE_1120: Yup.bool().required(),
    RETURN_TYPE_1065: Yup.bool().required(),
    RETURN_TYPE_DRE: Yup.bool().required(),
    RETURN_TYPE_LLC: Yup.bool().required(),
    RETURN_TYPE_SMLLC: Yup.bool().required(),
    RETURN_TYPE_1120PC: Yup.bool().required(),
    RETURN_TYPE_1120L: Yup.bool().required(),
    RETURN_TYPE: Yup.mixed().when(RETURN_TYPES, {
      is: (
        RETURN_TYPE_1065,
        RETURN_TYPE_1120,
        RETURN_TYPE_1120L,
        RETURN_TYPE_1120PC,
        RETURN_TYPE_DRE,
        RETURN_TYPE_LLC,
        RETURN_TYPE_SMLLC,
      ) =>
        !(
          RETURN_TYPE_1065 ||
          RETURN_TYPE_1120 ||
          RETURN_TYPE_1120L ||
          RETURN_TYPE_1120PC ||
          RETURN_TYPE_DRE ||
          RETURN_TYPE_LLC ||
          RETURN_TYPE_SMLLC
        ),
      // eslint-disable-next-line no-undefined
      then: Yup.string().notOneOf([null, undefined, ''], 'Must have a Return Type'),
    }),
  };
};

module.exports = { getReturnTypeSchema };
