import React from 'react';

import { GlobalContextDropdowns, GlobalContextValues } from '../containerComponents/globalContext';

import Page from './page.component';

interface GlobalContextPageProps {
  isFetchingContext: boolean;
  additionalDropdowns: JSX.Element | null | undefined;
  additionalValues: JSX.Element | null | undefined;
  hidePeriodDropdown: boolean;
  hideJurisdictionDropdown: boolean;
}

const GlobalContextPage = ({
  isFetchingContext,
  additionalDropdowns = null,
  additionalValues = null,
  hidePeriodDropdown = false,
  hideJurisdictionDropdown = false,
  ...props
}: GlobalContextPageProps) => (
  <Page
    globalContextDropdowns={
      <GlobalContextDropdowns
        additionalDropdowns={additionalDropdowns}
        isFetchingContext={isFetchingContext}
        hidePeriodDropdown={hidePeriodDropdown}
        hideJurisdictionDropdown={hideJurisdictionDropdown}
      />
    }
    globalContextValues={
      <GlobalContextValues additionalValues={additionalValues} hidePeriod={hidePeriodDropdown} />
    }
    {...props}
  />
);

export default GlobalContextPage;
