export const accountAuditTrailsSelector = ({ shared }) => shared.allReturnData.accountAuditTrails;

export const isFetchingAccountAuditTrailsSelector = ({ shared }) =>
  shared.allReturnData.isFetchingAccountAuditTrails;

export const accountDataSelector = ({ shared }) => shared.allReturnData.accountData;

export const isFetchingAccountDataSelector = ({ shared }) =>
  shared.allReturnData.isFetchingAccountData;

export const accountAdjustmentSelector = ({ shared }) => shared.allReturnData.accountAdjustment;

export const isFetchingAccountAdjustmentSelector = ({ shared }) =>
  shared.allReturnData.isFetchingAccountAdjustment;

export const calcDataSelector = ({ shared }) => shared.allReturnData.calcData;

export const isFetchingCalcDataSelector = ({ shared }) => shared.allReturnData.isFetchingCalcData;
