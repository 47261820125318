import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const ComplianceRoute = ({
  component: Component,
  globalContextDropdowns = null,

  ...routeRest
}) => {
  const routeRender = useCallback(
    props => (
      <>
        <Component {...props} />
        {globalContextDropdowns}
      </>
    ),
    [globalContextDropdowns],
  );

  return <Route render={routeRender} {...routeRest} />;
};

ComplianceRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  globalContextDropdowns: PropTypes.element,
};

export default ComplianceRoute;
