const whenAnyStopRangeIsNotNull = (newRecord, record) =>
  (newRecord.startRange >= record.startRange && newRecord.stopRange <= record.stopRange) ||
  (newRecord.startRange <= record.startRange && newRecord.stopRange >= record.stopRange) ||
  (newRecord.startRange <= record.stopRange && newRecord.startRange >= record.startRange) ||
  (newRecord.stopRange <= record.stopRange && newRecord.stopRange >= record.startRange);

const whenExistingHasNullAsStopRange = (newRecord, record) =>
  newRecord.startRange >= record.startRange || newRecord.stopRange >= record.startRange;

const whenNewHasNullAsStopRange = (newRecord, record) => newRecord.startRange <= record.stopRange;

const checkAreRangesOverlaps = (newRecord, existingRecords) =>
  existingRecords
    .filter(
      record =>
        record.TAX_TYPE_INCOME === newRecord.TAX_TYPE_INCOME &&
        record.TAX_TYPE_FRANCHISE === newRecord.TAX_TYPE_FRANCHISE,
    )
    .filter(record => record.rowId !== newRecord.rowId)
    .some(record => {
      if (newRecord.stopRange === null && record.stopRange === null) {
        return true;
      }

      if (newRecord.stopRange !== null && record.stopRange !== null) {
        return whenAnyStopRangeIsNotNull(newRecord, record);
      }

      if (record.stopRange === null) {
        return whenExistingHasNullAsStopRange(newRecord, record);
      }

      return whenNewHasNullAsStopRange(newRecord, record);
    });

export default checkAreRangesOverlaps;
