import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';
import {
  fetchK1CalcSpecsTypes,
  fetchK1CalcSpecsOptionsTypes,
  updateK1CalcSpecsTypes,
  createK1CalcSpecsTypes,
} from '../types';

export const k1CalcSpecTypeInitialState = {
  calcSpecs: {
    columnsBlueprint: [],
    data: [],
  },
  isFetchingCalcSpecs: false,
  isUpdatingCalcSpecs: false,
  isCreatingCalcSpecs: false,

  options: {
    k1CalcSpecIds: [],
    k1CalcSpecTypes: [],
  },
  isFetchingCalcSpecsOptions: false,
};

const k1CalcSpecTypeReducer = reducerFactory({
  initialState: k1CalcSpecTypeInitialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchK1CalcSpecsTypes,
      payloadKey: 'calcSpecs',
      fetchingKey: 'isFetchingCalcSpecs',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchK1CalcSpecsOptionsTypes,
      payloadKey: 'options',
      fetchingKey: 'isFetchingCalcSpecsOptions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateK1CalcSpecsTypes,
      fetchingKey: 'isUpdatingCalcSpecs',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createK1CalcSpecsTypes,
      fetchingKey: 'isCreatingCalcSpecs',
    }),
  },
});

export default k1CalcSpecTypeReducer;
