import { httpGetAndParse } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from './index';

const fetchWorkpaperInstance = ({
  taxYear,
  period,
  filingTypeId,
  businessEntityId,
  jurisdictionId,
  parentDatasetInstanceId,
  datasetDefinitionId,
}) =>
  httpGetAndParse({
    route: `/api/shared/data-models/workpaper-instance-print-service/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}/${parentDatasetInstanceId}/${datasetDefinitionId}`,
  });

export const useQueryWorkpaperInstance = ({ params, enabled }) =>
  useCustomQuery([QueryKeys.WorkpaperInstance.Data, params], {
    enabled,
    errorMessage: 'Fetching Workpaper Instance data failed',
    customFetch: () => fetchWorkpaperInstance(params),
  });
