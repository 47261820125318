import { httpGetAndParse, httpPost } from '../../utils/fetchService';

export const fetchTtiYearIdAction = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/proforma/tti-year-id/${taxYear}/${period}`,
    errorMessage: 'Fetching Tax Year Id failed',
    parseResponseErrorMessage: true,
  }).then(({ ttiYearId }) => ttiYearId);

export const fetchCaseListApiAction = () =>
  httpGetAndParse({
    route: `/api/proforma/case-list`,
    errorMessage: 'Fetching case list failed',
  });

export const fetchReturnBinderListApiAction = ({ ttiYearId, caseId }) =>
  httpGetAndParse({
    route: `/api/proforma/binder-list/${ttiYearId}/${caseId}`,
    errorMessage: 'Fetching return binder list failed',
  });

export const fetchProformaStepsDatApiAction = ({
  globalContext,
  ttiCaseId,
  ttiBinderId,
  ttiYearId,
}) =>
  httpGetAndParse({
    route: `/api/proforma/${globalContext.apiRouteParamString}/${ttiYearId}/${ttiCaseId}/${ttiBinderId}`,
    errorMessage: 'Fetching proforma steps data failed',
  });

export const runProformaStepApiAction = ({
  step,
  globalContext,
  ttiYearId,
  caseId,
  binderId,
  body,
}) =>
  httpPost({
    route: `/api/proforma/${step}/${globalContext.apiRouteParamString}/${ttiYearId}/${caseId}/${binderId}`,
    errorMessage: `Processing ${step} failed`,
    body,
  });
