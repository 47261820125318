import React, { useState, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import { Tooltip } from '@pwc/appkit-react';
import { Link } from 'react-router-dom';

import AppkitIcon from '../appkitIcon/appkitIcon.component';

const LockedItem = ({ text }) => (
  <li>
    <button className="action-menu-item" disabled>
      <AppkitIcon className="location-lock-icon" icon="location-locked" size={16} />
      {text}
    </button>
  </li>
);

const getButtonContainer = ({ button, href, link }) => {
  if (href) {
    return (
      <a className="action-menu-item-link" href={href}>
        {button}
      </a>
    );
  }

  if (link) {
    return (
      <Link className="action-menu-item-link" to={link}>
        {button}
      </Link>
    );
  }

  return button;
};

LockedItem.propTypes = {
  text: PropTypes.string.isRequired,
};

export const ActionMenuItem = ({
  text,
  disabled = false,
  locked = false,
  tooltipContent = null,
  onClick = () => null,
  href = '',
  link = '',
}) => {
  if (locked && tooltipContent) {
    return (
      <Tooltip content={tooltipContent} placement="left">
        <LockedItem text={text} />
      </Tooltip>
    );
  }

  if (locked) {
    return <LockedItem text={text} />;
  }

  const button = (
    <button className="action-menu-item" disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );

  return <li>{getButtonContainer({ button, href, link })}</li>;
};

ActionMenuItem.propTypes = {
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  text: PropTypes.string.isRequired,
  tooltipContent: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  link: PropTypes.string,
};

export const ActionMenu = props => {
  const [isMenuOpened, setMenuOpen] = useState(false);
  const hideMenu = useCallback(() => setMenuOpen(false), []);

  const handleMenuClick = useCallback(() => setMenuOpen(isOpened => !isOpened), []);

  return (
    <div className="action-menu">
      <OutsideClickHandler onOutsideClick={hideMenu}>
        <AppkitIcon icon="vertical-more" size={18} onClick={handleMenuClick} />
        {isMenuOpened ? (
          <div className="action-menu-active">
            <ul onClick={hideMenu}>{props.children}</ul>
          </div>
        ) : null}
      </OutsideClickHandler>
    </div>
  );
};

ActionMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([ActionMenuItem]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([ActionMenuItem]),
      }),
    ),
  ]).isRequired,
};
