export const WARNING_MODAL_TITLE = 'Unsaved changes';

export const WARNING_MODAL_CONTEXT_MESSAGE =
  'Expressions have unsaved changes. Please save or cancel changes before changing context.';

export const WARNING_MODAL_DATA_OPTIONS_MESSAGE =
  'Expressions have unsaved changes. Please save or cancel changes before changing data options.';

export const CompileInfoTypes = {
  COMPILE_UP_TO_DATE: 'Compile Up-to-date',
  COMPILE_NEEDED: 'Compile Needed',
  EXPRESSION_NEEDED: 'Expression Needed',
  FIX_COMPILE_ERRORS: 'Fix Compile Errors',
  FIX_COMPILE_WARNINGS: 'Fix Compile Warnings',
};

export const WARNING_DIAGNOSTIC_TYPE = 'Warning';
