import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ParamDropdown from '../../displayComponents/paramDropdown/paramDropdown.component';
import { selectConsolidation } from '../../store/actions';
import {
  isFetchingTaxYearsDataSelector,
  isFetchingPeriodsSelector,
  consolidationIdSelector,
  jurisdictionIdSelector,
  consolidationsSelector,
  consolidationsOptionsSelector,
} from '../../store/selectors';
import { filingTypes, NotApplicableContextValue } from '../../../shared/constants';
import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

const ConsolidationDropdown = ({ isFetchingContext = false }) => {
  const { routeParams, setParams } = useUrlParams();
  // we want to take consolidation Id param value on first context URL aware page load
  // after that, we have to prevent this from happening and update consolidation URL
  // based on consolidation Id from redux state via setParams method
  const [canParamValueBeTakenFormUrl, setCanParamValueBeTakenFormUrl] = useState(true);

  const dispatch = useDispatch();

  const isFetchingTaxYearsData = useSelector(isFetchingTaxYearsDataSelector);
  const isFetchingPeriods = useSelector(isFetchingPeriodsSelector);
  const consolidationId = useSelector(consolidationIdSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const consolidations = useSelector(consolidationsSelector);
  const consolidationsOptions = useSelector(consolidationsOptionsSelector);

  const isDropdownBusy = Boolean(isFetchingTaxYearsData || isFetchingPeriods);

  const handleSelect = useCallback(
    newConsolidationId => {
      if (consolidationId !== newConsolidationId) {
        if (newConsolidationId !== NotApplicableContextValue) {
          dispatch(selectConsolidation(newConsolidationId));
        }
        if (routeParams.businessEntityId && routeParams.businessEntityId !== newConsolidationId) {
          setParams({
            routeParams: {
              businessEntityId: newConsolidationId || NotApplicableContextValue,
            },
          });
        }
      }
    },
    [setParams, dispatch, consolidationId, routeParams.businessEntityId],
  );

  useEffect(() => {
    if (!consolidations.length) {
      return;
    }
    if (
      routeParams.businessEntityId &&
      routeParams.filingTypeId === filingTypes.CONSOLIDATED &&
      canParamValueBeTakenFormUrl
    ) {
      handleSelect(routeParams.businessEntityId);
      setCanParamValueBeTakenFormUrl(false);
    } else if (!consolidationId) {
      handleSelect(consolidations[0].consolidationId);
    }
  }, [
    handleSelect,
    consolidationId,
    consolidations,
    routeParams.businessEntityId,
    routeParams.filingTypeId,
    canParamValueBeTakenFormUrl,
  ]);

  useEffect(() => {
    if (!consolidations.length) {
      return;
    }
    const consolidationIdValue = consolidations.find(
      consolidation => consolidation.jurisdictionId === jurisdictionId,
    )?.consolidationId;
    if (consolidations?.length && jurisdictionId && consolidationId) {
      const isConsolidationAlreadyPresent = consolidations.some(
        consolidation => consolidation.consolidationId === consolidationId,
      );

      if (isConsolidationAlreadyPresent) {
        return;
      }
      handleSelect(consolidationIdValue);
    }
  }, [handleSelect, consolidations, jurisdictionId, consolidationId]);

  return (
    <ParamDropdown
      label="Consolidation"
      value={consolidationId}
      options={consolidationsOptions}
      handleChange={handleSelect}
      isBusy={isDropdownBusy}
      isFetchingContext={isFetchingContext}
      hasTooltip
    />
  );
};

ConsolidationDropdown.propTypes = {
  isFetchingContext: PropTypes.bool,
};

export default ConsolidationDropdown;
