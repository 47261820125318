import isEqual from 'lodash.isequal';

import { calcSpecTypes } from './constants';

const validationSchemaForCalcSpecType = {
  [calcSpecTypes.CONSOL]: {
    REQUIRED_FIELDS: ['categoryId', 'consolId', 'accountId', 'accountJurisdictionId', 'multiplier'],
    UNIQUE_FIELDS: ['categoryId', 'consolId', 'accountId', 'accountJurisdictionId', 'calcOptions'],
  },
  [calcSpecTypes.CONSPB]: {
    REQUIRED_FIELDS: ['categoryId', 'consolId', 'accountId', 'accountJurisdictionId', 'multiplier'],
    UNIQUE_FIELDS: ['categoryId', 'consolId', 'accountId', 'accountJurisdictionId', 'calcOptions'],
  },
  [calcSpecTypes.BASE]: {
    REQUIRED_FIELDS: ['categoryId', 'accountId', 'accountJurisdictionId', 'multiplier'],
    UNIQUE_FIELDS: ['categoryId', 'accountId', 'accountJurisdictionId'],
  },
  [calcSpecTypes.K1]: {
    REQUIRED_FIELDS: ['k1CalcId', 'k1CalcType', 'percentageType', 'screenType'],
    UNIQUE_FIELDS: ['k1CalcId'],
  },
};

// TODO: from SLT-10512 see comment  for more details:
// https://gitlab.ist.pwc.com/tls/state-lifecycle-tool/-/merge_requests/3697#note_151332
const areCalcSpecsEqual = (calcSpecA, calcSpecB) => {
  const calcSpecDataA = JSON.parse(calcSpecA || '{}');
  const calcSpecDataB = JSON.parse(calcSpecB || '{}');

  if (calcSpecDataA === calcSpecDataB) {
    return true;
  }
  if (
    isEqual(Object.keys(calcSpecDataA).sort(), Object.keys(calcSpecDataB).sort()) &&
    isEqual(Object.values(calcSpecDataA).flat().sort(), Object.values(calcSpecDataB).flat().sort())
  ) {
    return true;
  }

  return false;
};

const getRowValue = (key, value) => {
  if (key === 'calcOptions') {
    return value || '{}';
  }
  return value === null ? '' : String(value);
};

const checkValueDuplicate = (key, valueA, valueB) => {
  if (key === 'calcOptions') {
    return areCalcSpecsEqual(valueA, valueB);
  }
  return valueA === valueB;
};

const isRowDuplicated = (firstRow, secondRow, uniqueFields, groupedFilingAttributes) => {
  const areNormalFieldsConflicting = Object.keys(firstRow)
    .filter(key => uniqueFields.includes(key))
    .every(key =>
      checkValueDuplicate(key, getRowValue(key, firstRow[key]), getRowValue(key, secondRow[key])),
    );

  const areFilingAttributesConflicting = groupedFilingAttributes.every(attributesGroup =>
    attributesGroup.some(
      filingAttribute =>
        firstRow[filingAttribute] && firstRow[filingAttribute] === secondRow[filingAttribute],
    ),
  );

  return areNormalFieldsConflicting && areFilingAttributesConflicting;
};

const findDuplicatedRowIds = ({
  addOrModifiedRows,
  calcSpecs,
  rowsWithInvalidCells,
  uniqueFields,
  groupedFilingAttributes,
}) => {
  const modifiedRowsIdsSet = new Set(addOrModifiedRows.map(({ rowId }) => rowId));
  const allRows = [
    ...calcSpecs.filter(({ rowId }) => !modifiedRowsIdsSet.has(rowId)),
    ...addOrModifiedRows,
  ];

  const duplicatedRowsIds = new Set();
  addOrModifiedRows
    .filter(row => {
      const rowHasInvalidCell = rowsWithInvalidCells.find(
        invalidRow => invalidRow.rowId === row.rowId,
      );
      return !rowHasInvalidCell;
    })
    .forEach(row => {
      allRows.forEach(calcSpec => {
        const rowsAreDuplicated =
          row.rowId !== calcSpec.rowId &&
          isRowDuplicated(calcSpec, row, uniqueFields, groupedFilingAttributes);

        if (rowsAreDuplicated) {
          duplicatedRowsIds.add(calcSpec.rowId);
          duplicatedRowsIds.add(row.rowId);
        }
      });
    });

  return [...duplicatedRowsIds];
};

const validateCalcSpecs = ({
  addOrModifiedRows,
  calcSpecs,
  displayNameAndSelectOptionDictionary,
  calcSpecType = calcSpecTypes.BASE,
  groupedFilingAttributes,
}) => {
  const { REQUIRED_FIELDS, UNIQUE_FIELDS } = validationSchemaForCalcSpecType[calcSpecType];

  const rowsWithInvalidCells = addOrModifiedRows.reduce((acc, row) => {
    const invalidCells = [
      ...new Set([
        ...REQUIRED_FIELDS.filter(key => !row[key]),
        ...Object.entries(row)
          .filter(([key, value]) => {
            if (!value) {
              return false;
            }
            const selectOptions = displayNameAndSelectOptionDictionary[key];

            if (selectOptions) {
              return !selectOptions.find(option => option.value === value);
            }

            return false;
          })
          .map(([key]) => key),
      ]),
    ];

    return invalidCells.length ? [...acc, { rowId: row.rowId, invalidCells }] : acc;
  }, []);

  const duplicatedRowsIds = findDuplicatedRowIds({
    addOrModifiedRows,
    calcSpecs,
    rowsWithInvalidCells,
    uniqueFields: UNIQUE_FIELDS,
    groupedFilingAttributes,
  });

  const invalidValuesMessage = rowsWithInvalidCells.length
    ? `Cells with invalid values are red.\n`
    : ``;
  const duplicatedRowsMessage = duplicatedRowsIds.length ? `Duplicated rows are outlined.` : ``;

  return {
    duplicatedRowsIds,
    rowsWithInvalidCells,
    modalMessage: `${invalidValuesMessage}${duplicatedRowsMessage}`,
  };
};

export default validateCalcSpecs;
