import { currencyColumnDefinition, defaultColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'orgId',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Group',
    field: 'accountGroup',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 250,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Beg / End',
    field: 'begEndFlag',
    width: 80,
    cellStyle: {
      textAlign: 'center',
    },
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Amount',
    field: 'amt',
  },
];

export default columnDefinitions;
