import React from 'react';
import { connect } from 'react-redux';

import AuthorizedCustomRoute from '../../../shared/authorization/authorizedCustomRoute';
import Page from '../../../shared/displayComponents/page.component';
import DevelopmentBreadcrumbRoutes from '../../routeConfiguration/developmentBreadcrumbRoutes.component';
import { isFetchingContextSelector } from '../../store/selectors';

const GenericScreenDefinitionPage = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
  breadcrumbRoutes: DevelopmentBreadcrumbRoutes,
}))(Page);

const GenericScreenDefinitionRoute = props => (
  <AuthorizedCustomRoute wrapper={GenericScreenDefinitionPage} {...props} />
);

export default GenericScreenDefinitionRoute;
