import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import SelectContextDataInfo from '../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../shared/editMode';
import {
  periodSelector,
  taxYearSelector,
  jurisdictionIdSelector,
} from '../../shared/store/selectors';

import { fetchEntities, updateEntities } from './store/actions';
import {
  entitiesSelector,
  isFetchingEntitiesSelector,
  isUpdatingEntitiesSelector,
} from './store/selectors';
import getColumnDefinitions from './lockReturn.columnDefinitions';

const getUniqueRowId = ({ data: { entityId } }) => entityId;

const LockReturn = ({
  taxYear,
  period,
  jurisdictionId,
  entities,
  fetchEntities,
  isFetchingEntities,
  updateEntities,
  isUpdatingEntities,
}) => {
  const isContextReady = taxYear && period && jurisdictionId;

  useEffect(() => {
    if (isContextReady) {
      fetchEntities({ taxYear, period, jurisdictionId });
    }
  }, [fetchEntities, taxYear, period, jurisdictionId, isContextReady]);

  const isLoading = isFetchingEntities || isUpdatingEntities;

  const saveChanges = async ({ rowsPairsWithChanges }) => {
    const entitiesToUpdate = rowsPairsWithChanges.map(({ newRow }) => ({
      entityId: newRow.entityId,
      isLocked: newRow.isLocked,
    }));
    await updateEntities({ taxYear, period, jurisdictionId, entitiesToUpdate });
    fetchEntities({ taxYear, period, jurisdictionId });
  };

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: entities,
    getUniqueRowId,
    editButtonDisabled: isLoading || !entities.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        updateRow,
      }),
    [isInEditMode, updateRow],
  );

  if (!isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col add-button-column">{editModeButtons}</div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            withSearchBar
          />
        </div>
      </div>
    </>
  );
};

LockReturn.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  jurisdictionId: PropTypes.string,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      isLocked: PropTypes.bool.isRequired,
      entityId: PropTypes.string.isRequired,
      entityName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fetchEntities: PropTypes.func.isRequired,
  isFetchingEntities: PropTypes.bool.isRequired,
  updateEntities: PropTypes.func.isRequired,
  isUpdatingEntities: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    period: periodSelector(state),
    taxYear: taxYearSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    entities: entitiesSelector(state),
    isFetchingEntities: isFetchingEntitiesSelector(state),
    isUpdatingEntities: isUpdatingEntitiesSelector(state),
  }),
  {
    fetchEntities,
    updateEntities,
  },
)(LockReturn);
