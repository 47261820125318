import PropTypes from 'prop-types';

export const FilingDecisionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    jurisdiction: PropTypes.string.isRequired,
    jurisdictionId: PropTypes.string.isRequired,
    filingGroup: PropTypes.string,
    franchiseTax: PropTypes.bool.isRequired,
    incomeTax: PropTypes.bool.isRequired,
    property: PropTypes.number.isRequired,
    rent: PropTypes.number.isRequired,
    payroll: PropTypes.number.isRequired,
    salesOfServiceDestination: PropTypes.number.isRequired,
    salesOfServiceOrigin: PropTypes.number.isRequired,
    tangibleSales: PropTypes.number.isRequired,
  }).isRequired,
);
