import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

const hasPermissionToDeleteCalcPreferenceJurisdiction = (customerPermissions: {
  read: [];
  edit: [];
}) =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.DELETE_CALC_PREFERENCE_JURISDICTION],
  });

export default hasPermissionToDeleteCalcPreferenceJurisdiction;
