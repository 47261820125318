import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';

import {
  fetchTasksTypes,
  fetchSourceCustomersTypes,
  fetchDestinationCustomersTypes,
  fetchTasksForReassignmentTypes,
  updateTasksAssignmentsTypes,
} from './types';

const tasksReducer = reducerFactory({
  initialState: {
    tasks: [],
    isFetchingTasks: false,
    sourceCustomers: [],
    isFetchingSourceCustomers: false,
    destinationCustomers: [],
    isFetchingDestinationCustomers: false,
    tasksForReassignment: [],
    isFetchingTasksForReassignment: false,
    isUpdatingTasksAssignments: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchTasksTypes,
      payloadKey: 'tasks',
      fetchingKey: 'isFetchingTasks',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchSourceCustomersTypes,
      payloadKey: 'sourceCustomers',
      fetchingKey: 'isFetchingSourceCustomers',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDestinationCustomersTypes,
      payloadKey: 'destinationCustomers',
      fetchingKey: 'isFetchingDestinationCustomers',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchTasksForReassignmentTypes,
      payloadKey: 'tasksForReassignment',
      fetchingKey: 'isFetchingTasksForReassignment',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateTasksAssignmentsTypes,
      fetchingKey: 'isUpdatingTasksAssignments',
    }),
  },
});

export default tasksReducer;
