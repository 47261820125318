import { apiAction } from '../../../shared/store/apiAction';
import { SELECT_JURISDICTION_ID } from '../../store/types';

import * as api from './api';
import {
  fetchGenericScreenDefinitionTypes,
  deleteGenericScreenDefinitionTypes,
  fetchAccountsTypes,
  fetchCategoriesTypes,
  fetchJurisdictionsTypes,
  createGenericScreenDefinitionTypes,
  updateGenericScreenDefinitionTypes,
  fetchGenericScreenDefinitionRowDetailsTypes,
  SELECT_SCREEN_ID,
} from './types';

export const fetchGenericScreenDefinition = apiAction({
  types: fetchGenericScreenDefinitionTypes,
  apiAction: api.fetchGenericScreenDefinition,
});

export const deleteGenericScreenDefinition = apiAction({
  types: deleteGenericScreenDefinitionTypes,
  apiAction: api.deleteGenericScreenDefinition,
});

export const fetchAccounts = apiAction({
  types: fetchAccountsTypes,
  apiAction: api.fetchAccounts,
});

export const fetchCategories = apiAction({
  types: fetchCategoriesTypes,
  apiAction: api.fetchCategories,
});

export const fetchJurisdictions = apiAction({
  types: fetchJurisdictionsTypes,
  apiAction: api.fetchJurisdictions,
});

export const createGenericScreenDefinition = apiAction(
  {
    types: createGenericScreenDefinitionTypes,
    apiAction: api.createGenericScreenDefinition,
  },
  { successNotificationMessage: () => 'Screen has been successfully created' },
);

export const updateGenericScreenDefinition = apiAction(
  {
    types: updateGenericScreenDefinitionTypes,
    apiAction: api.updateGenericScreenDefinition,
  },
  { successNotificationMessage: () => 'Screen has been successfully updated' },
);

export const fetchGenericScreenDefinitionRowDetails = apiAction({
  types: fetchGenericScreenDefinitionRowDetailsTypes,
  apiAction: api.fetchGenericScreenDefinitionRowDetails,
});

export const selectJurisdictionId = jurisdictionId => ({
  type: SELECT_JURISDICTION_ID,
  payload: jurisdictionId,
});

export const selectScreenId = screenId => ({
  type: SELECT_SCREEN_ID,
  payload: screenId,
});

export const createGenericScreenDefinitionRow = apiAction({
  types: createGenericScreenDefinitionTypes,
  apiAction: api.createGenericScreenDefinitionRow,
  throwApiErrors: true,
  errorNotificationCloseable: true,
});
