const Yup = require('yup');

const FILE_NAME_UNIQUE_ERROR_MESSAGE = 'File name is not unique. You can rename here.';

const BH_BUSINESS_FILE_NAME_UNIQUE_ERROR_MESSAGE =
  'File name is not unique and used by another business unit. You can rename here.';

/*
https://pwc-us-tax-tech.visualstudio.com/StateLifecycleTool/_workitems/edit/1765912
SUBMISSION_FILE_NAME_REG_EXP_EXTENSION:
  - Check first if the file name has an extension of .pdf or .PDF
*/
const SUBMISSION_FILE_NAME_EXTENSION_REG_EXP = /\.(pdf|PDF)$/;
const SUBMISSION_FILE_NAME_EXTENSION_ERROR_MESSAGE = `.PDF or .pdf file extension is required to be a valid file name.`;
const SUBMISSION_FILE_NAME_PERIOD_REG_EXP = /^[^.]*\.(pdf|PDF)$/;
const SUBMISSION_FILE_NAME_PERIOD_ERROR_MESSAGE = `Period (.) is allowed only in .PDF or .pdf file extensions.`;

/*
SUBMISSION_FILE_NAME_REG_EXP:
  - Allow: Numbers, alphabet characters, single space, special characters: - (hyphen) and file extension .pdf or .PDF
  - Disallow: Double space, leading or trailing space, other special characters not mentioned above
*/
const SUBMISSION_FILE_NAME_REG_EXP = /^(?! )(?!.* $)(?!.* {2})[a-zA-Z0-9 _-]+\.(pdf|PDF)$/;
const SUBMISSION_FILE_NAME_ERROR_MESSAGE = `Valid characters include A-z, 0-9, space, period (.), hyphen (-), and underscore (_).`;

const SUBMISISON_FILE_MAX_LENGTH = 49;

const attachmentValidationSchema = ({ businessUnitAttachments, allAttachments, isEditMode }) =>
  Yup.object().shape({
    attachmentType: Yup.string().required().label('Attachment Type'),
    fileName: Yup.string()
      .uniqueInList({
        message: FILE_NAME_UNIQUE_ERROR_MESSAGE,
        list: businessUnitAttachments,
        mapper: ({ fileName }) => fileName,
        shouldSkipTest: isEditMode,
      })
      .uniqueInList({
        message: BH_BUSINESS_FILE_NAME_UNIQUE_ERROR_MESSAGE,
        list: allAttachments,
        mapper: ({ fileName }) => fileName,
        shouldSkipTest: isEditMode,
      })
      .label('File Name')
      .required(),
    submissionFileName: Yup.string()
      .label('Submission File Name')
      .matches(SUBMISSION_FILE_NAME_EXTENSION_REG_EXP, SUBMISSION_FILE_NAME_EXTENSION_ERROR_MESSAGE)
      .matches(SUBMISSION_FILE_NAME_PERIOD_REG_EXP, SUBMISSION_FILE_NAME_PERIOD_ERROR_MESSAGE)
      .matches(SUBMISSION_FILE_NAME_REG_EXP, SUBMISSION_FILE_NAME_ERROR_MESSAGE)
      .max(
        SUBMISISON_FILE_MAX_LENGTH,
        `Submission file name must be less than ${SUBMISISON_FILE_MAX_LENGTH + 1} characters`,
      )
      .required(),
    attachmentDescription: Yup.string().label('Attachment Description').nullable(),
  });

const attachmentDbValidationSchema = ({ businessUnitAttachments, allAttachments, isUpdating }) =>
  Yup.object().shape({
    taxYear: Yup.string().required(),
    period: Yup.string().required(),
    jurisdictionId: Yup.string().required(),
    attachmentType: Yup.string().required(),
    fileName: Yup.string()
      .uniqueInList({
        message: FILE_NAME_UNIQUE_ERROR_MESSAGE,
        list: businessUnitAttachments,
        mapper: ({ fileName }) => fileName,
        shouldSkipTest: isUpdating,
      })
      .uniqueInList({
        message: BH_BUSINESS_FILE_NAME_UNIQUE_ERROR_MESSAGE,
        list: allAttachments,
        mapper: ({ fileName }) => fileName,
        shouldSkipTest: isUpdating,
      })
      .label('File Name')
      .required(),
    submissionFileName: Yup.string()
      .label('Submission File Name')
      .matches(SUBMISSION_FILE_NAME_EXTENSION_REG_EXP, SUBMISSION_FILE_NAME_EXTENSION_ERROR_MESSAGE)
      .matches(SUBMISSION_FILE_NAME_PERIOD_REG_EXP, SUBMISSION_FILE_NAME_PERIOD_ERROR_MESSAGE)
      .matches(SUBMISSION_FILE_NAME_REG_EXP, SUBMISSION_FILE_NAME_ERROR_MESSAGE)
      .max(
        SUBMISISON_FILE_MAX_LENGTH,
        `Submission file name must be less than ${SUBMISISON_FILE_MAX_LENGTH + 1} characters`,
      )
      .required(),
    attachmentDescription: Yup.string().nullable(),
  });

module.exports = {
  attachmentValidationSchema,
  attachmentDbValidationSchema,
};
