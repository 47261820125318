import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { selectionListItemPropTypes } from '../selectionListsPropTypes';
import ButtonsGroup from '../../../shared/forms/buttonsGroup/buttonsGroup.component';
import { useEditModeNavigationPrompt } from '../../../shared/editMode';
import { blockContextChanges } from '../../store/actions';

import SelectionListItemsGrid from './selectionListItemsGrid/selectionListItemsGrid.container';
import EditSelectionListFormFields from './editSelectionListFormFields.component';
import styles from './editSelectionListForm.module.scss';

const EditSelectionListForm = ({
  resetSelectionListItems,
  selectionListItemsGridData,
  gridApi,
  onGridReady,
  addRow,
  updateRow,
  deleteRow,
  checkIfSelectionListItemsAreDirty,
  validateSelectionListItems,
  areSelectionListItemsDirty,
  areSelectionListItemsValid,
}) => {
  const dispatch = useDispatch();
  const { resetForm, isSubmitting, submitForm, dirty, isValid } = useFormikContext();

  const isFormOrListItemsDirty = areSelectionListItemsDirty || dirty;

  useEffect(() => {
    dispatch(blockContextChanges(isFormOrListItemsDirty));
  }, [dispatch, isFormOrListItemsDirty]);

  const onCancelClick = useCallback(() => {
    resetSelectionListItems();
    resetForm();
  }, [resetForm, resetSelectionListItems]);

  const { navigationPrompt } = useEditModeNavigationPrompt(isFormOrListItemsDirty);

  const handleSubmit = useCallback(
    // this is neccesary to allow for the column filtering tool to work inside a form
    event => {
      event.preventDefault();
    },
    [],
  );

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.editSelectionListForm}>
        <EditSelectionListFormFields />
        <SelectionListItemsGrid
          selectionListItemsGridData={selectionListItemsGridData}
          gridApi={gridApi}
          onGridReady={onGridReady}
          addRow={addRow}
          updateRow={updateRow}
          deleteRow={deleteRow}
          checkIfSelectionListItemsAreDirty={checkIfSelectionListItemsAreDirty}
          validateSelectionListItems={validateSelectionListItems}
        />
        <ButtonsGroup
          className={styles.buttonsGroup}
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={onCancelClick}
          submitButtonLabel="Save"
          disabledCancel={!isFormOrListItemsDirty}
          disabled={!isValid || !areSelectionListItemsValid || !isFormOrListItemsDirty}
        />
      </form>
      {navigationPrompt}
    </>
  );
};

EditSelectionListForm.propTypes = {
  selectionListItemsGridData: PropTypes.arrayOf(selectionListItemPropTypes).isRequired,
  resetSelectionListItems: PropTypes.func.isRequired,
  navigationPrompt: PropTypes.element.isRequired,
  gridApi: PropTypes.object,
  onGridReady: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  checkIfSelectionListItemsAreDirty: PropTypes.func.isRequired,
  validateSelectionListItems: PropTypes.func.isRequired,
  areSelectionListItemsDirty: PropTypes.bool.isRequired,
  areSelectionListItemsValid: PropTypes.bool.isRequired,
};

export default EditSelectionListForm;
