import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Outline } from 'react-pdf';
import { Panel, Animations } from '@pwc/appkit-react';

import style from './pdfDisplay.module.scss';

const PdfOutline = ({ page, animationEffect, showOutline, resetAnimation }) => {
  const [outlineAvailable, setOutlineAvailable] = useState(false);
  const scrollIntoView = useCallback((page, pageNumber) => page[pageNumber].scrollIntoView(), []);
  const checkOutlineAvailability = useCallback(
    outline => setOutlineAvailable(Boolean(outline)),
    [],
  );

  return (
    <div className={style.pdfViewerBookmarks}>
      <Animations effect={animationEffect} active={showOutline} afterAnimation={resetAnimation}>
        <div>
          {showOutline && (
            <Panel title="Bookmarks">
              <Outline
                onItemClick={({ pageNumber }) => scrollIntoView(page, pageNumber)}
                onLoadSuccess={checkOutlineAvailability}
              />
              {!outlineAvailable && (
                <div className={style.pdfNoBookmarksAvailable}>
                  This PDF file does not contain any bookmarks
                </div>
              )}
            </Panel>
          )}
        </div>
      </Animations>
    </div>
  );
};

PdfOutline.propTypes = {
  page: PropTypes.object.isRequired,
  resetAnimation: PropTypes.func.isRequired,
  showOutline: PropTypes.bool.isRequired,
  animationEffect: PropTypes.string,
};

export default PdfOutline;
