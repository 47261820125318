import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';
import { filingTypes } from '../../shared/constants';

import formatName from './formatName';

const defaultRoute = { displayMainName: () => '' };

const binderTreeDataFactory = ({ binderData, applyPermissions, routes, globalContext }) => {
  const binderMaintenanceLink = {
    name: 'Binder Maintenance',
    type: 'screen',
    id: 'binder-maintenance',
    isHandledByPermissionRules: true,
  };

  const { taxYear, period, filingTypeId, entityId, jurisdictionId } = globalContext;

  const withContextParams = routeParams =>
    getContextAwareUrlParams({
      taxYear,
      period,
      filingTypeId,
      businessEntityId: entityId,
      jurisdictionId,
      ...routeParams,
    });

  const tree = [
    binderMaintenanceLink,
    {
      name: binderData.forms.label,
      type: 'folder',
      children: binderData.forms.items.map(
        ({ formName, formId, taxYear, period, jurisdictionId, orgId, consolId }) => ({
          type: 'screen',
          isHandledByPermissionRules: true,

          name: filingTypeId === filingTypes.CONSOLIDATED ? formatName(formName, orgId) : formName,
          id: `view-print-tax-return`,
          routeQueryString: {
            formId,
          },
          routeParams: withContextParams({
            taxYear: `${taxYear}`,
            period: `${period}`,
            jurisdictionId,
            filingTypeId: orgId ? filingTypes.SEPARATE : filingTypes.CONSOLIDATED,
            businessEntityId: orgId || consolId,
          }),
        }),
      ),
    },
    {
      name: binderData.reports.label,
      type: 'folder',
      children: binderData.reports.items.map(({ reportId }) => ({
        type: 'screen',
        isHandledByPermissionRules: true,

        name: (routes.find(route => route.id === reportId) || defaultRoute).displayMainName(),
        id: reportId,
      })),
    },
    {
      name: binderData.attachments.label,
      type: 'folder',
      children: binderData.attachments.items.map(attachmentData => ({
        type: 'component',
        component: 'AttachmentPreview',
        props: attachmentData,
      })),
    },
  ];

  return applyPermissions(tree);
};

export default binderTreeDataFactory;
