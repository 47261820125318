import { optionsSelectorFactory } from '../../shared/store/storeHelpers';

export const binderItemsSelector = ({ binderMaintenance }) => binderMaintenance.binderItems;

export const isFetchingBinderItemsSelector = ({ binderMaintenance }) =>
  binderMaintenance.isFetchingBinderItems;

export const isEditingBinderItemsSelector = ({ binderMaintenance }) =>
  binderMaintenance.isEditingBinderItems;

export const binderFormsSelector = optionsSelectorFactory({
  listSelector: ({ binderMaintenance }) => binderMaintenance.binderForms,
  labelKey: 'formName',
  valueKey: 'formId',
});

export const isFetchingBinderFormsSelector = ({ binderMaintenance }) =>
  binderMaintenance.isFetchingBinderForms;

export const binderAttachmentsSelector = optionsSelectorFactory({
  listSelector: ({ binderMaintenance }) => binderMaintenance.binderAttachments,
  labelKey: 'fileName',
  valueKey: 'id',
});

export const isFetchingBinderAttachmentsSelector = ({ binderMaintenance }) =>
  binderMaintenance.isFetchingBinderAttachments;

export const copiedBinderSelector = ({ binderMaintenance }) => binderMaintenance.copiedBinder;

export const binderPdfLastGenerationDateSelector = ({ binderMaintenance }) =>
  binderMaintenance.binderPdfLastGenerationDate.pdfLastGenerationDate;

export const isFetchingBinderPdfLastGenerationDateSelector = ({ binderMaintenance }) =>
  binderMaintenance.isFetchingBinderPdfLastGenerationDate;

export const isDownloadingBinderPdfSelector = ({ binderMaintenance }) =>
  binderMaintenance.isDownloadingBinderPdf;

export const isStartingProcessOfGeneratingBinderPdfSelector = ({ binderMaintenance }) =>
  binderMaintenance.isStartingProcessOfGeneratingBinderPdf;
