import { Routes } from '@common-packages/routes-definitions';

const data = [
  {
    title: 'Business Audit Trail',
    icon: 'table-data',
    description: 'Audit trail',
    route: Routes.businessAuditTrail.MAIN,
  },
  {
    title: 'Business Crosstab',
    icon: 'table-data',
    description: 'Crosstab',
    route: Routes.businessCrosstab.MAIN,
  },
  {
    title: 'Legal Entity Audit Trail',
    icon: 'table-data',
    description: 'Audit trail',
    route: Routes.legalEntityAuditTrail.MAIN,
  },
  {
    title: 'Legal Entity Crosstab',
    icon: 'table-data',
    description: 'Entity crosstab',
    route: Routes.legalEntityCrosstab.MAIN,
  },
  {
    title: 'Intercompany Payables',
    icon: 'table-data',
    description: 'Payables',
    route: Routes.intercompanyPayables.MAIN,
  },
  {
    title: 'Business CSV Export',
    icon: 'table-data',
    description: 'CSV export',
    route: Routes.businessCSVExport.MAIN,
  },
  {
    title: 'Legal Entity CSV Audit Export',
    icon: 'table-data',
    description: 'CSV audit export',
    route: Routes.legalEntityCSVAuditExport.MAIN,
  },
  {
    title: 'Legal Entity CSV Export - T05 Only',
    icon: 'table-data',
    description: 'CSV export - T05 only',
    route: Routes.legalEntityCSVExportT05Only.MAIN,
  },
  {
    title: 'Intercompany Interest Expense',
    icon: 'table-data',
    description: 'Interest expense',
    route: Routes.intercompanyInterestExpense.MAIN,
  },
  {
    title: 'Intercompany Interest Income',
    icon: 'table-data',
    description: 'Interest income',
    route: Routes.intercompanyInterestIncome.MAIN,
  },
  {
    title: 'Payables Reconciliation',
    icon: 'table-data',
    description: 'Payables reconciliation',
    route: Routes.payablesReconciliation.MAIN,
  },
];

export default data;
