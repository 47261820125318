import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { efileSchemas } from '@common-packages/validators';

import AgGrid from '../../../../../shared/displayComponents/agGrid/agGrid.component';
import { FederalReturnOption } from '../../../../../shared/enums/eFileV2';
import { StepTab } from '../../stepTab';
import {
  FindFederalReturnResponse,
  FindManualFederalProformaSubmissionIdOptionsResponse,
  SubmissionId,
  GetTrRejectionMessagesResponse,
} from '../../../../../../../common/types';
import {
  taxYearSelector,
  filingTypeIdSelector,
  isGlobalContextReadySelector,
  globalContextSelector,
} from '../../../../../shared/store/selectors';
import { FederalReturnAttachments } from '../../../../../shared/enums';
import Loading from '../../../../../shared/displayComponents/loading.component';
import setFederalSubmissionIdBasedOnFederalReturnOption from '../../../utils/setFederalSubmissionIdBasedOnFederalReturnOption';
import { WizardStateMachineSetFederalSubmissionId } from '../../../wizardStateMachine/types';
import { useQueryGetSubmissionFiles } from '../../../../../shared/queries/eFileV2';
import { getFederalSubmissions } from '../../utils';
import { getColumnDefinitions } from '../../step5Transmit/viewStatusStep.columnDefinitions';
import transmitStepContentStyles from '../../step5Transmit/viewStatusStep.module.scss';
import StepInformationCard, {
  StepInformationCardType,
} from '../../stepInformationCard/stepInformationCard.component';

import generateFederalReturnOptions from './generateFederalReturnOptions';
import EditReturnFilingForm from './editReturnFilingForm/editReturnFilingForm.container';
import styles from './returnRequirements.module.scss';
import { ReturnFilingValues, FormInitialValues } from './types';

interface ReturnRequirementsProps {
  federalReturnOption: FederalReturnOption | null;
  setFederalReturnOption: (federalReturnOption: FederalReturnOption | null) => void;
  setFederalSubmissionId: (submissionId: WizardStateMachineSetFederalSubmissionId) => void;
  wizardStateMachineSetFederalSubmissionId: WizardStateMachineSetFederalSubmissionId | null;
  federalReturn: FindFederalReturnResponse;
  manualFederalProformaSubmissionIdOptions: FindManualFederalProformaSubmissionIdOptionsResponse;
  efSubmissionId: SubmissionId | null;
  selectedTtiSubmissionId: string | null;
  returnId: number;
  trRejectionErrors: GetTrRejectionMessagesResponse[];
  isFetchingEFileTrRejectionMessages: boolean;
}

const ReturnRequirements = ({
  federalReturnOption: selectedFederalReturnOption,
  setFederalReturnOption,
  setFederalSubmissionId,
  wizardStateMachineSetFederalSubmissionId,
  federalReturn,
  manualFederalProformaSubmissionIdOptions,
  efSubmissionId,
  selectedTtiSubmissionId,
  returnId,
  trRejectionErrors,
  isFetchingEFileTrRejectionMessages,
}: ReturnRequirementsProps) => {
  const taxYear = useSelector(taxYearSelector);
  const filingTypeId = useSelector(filingTypeIdSelector);

  const storedFederalReturnOption = federalReturn.federalReturnOption;

  const onSubmit = useCallback(async (values: ReturnFilingValues) => values, []);

  const globalContext = useSelector(globalContextSelector);
  const isGlobalContextReady = useSelector(isGlobalContextReadySelector);

  const {
    federalReturnAttachment,
    isAsFiledFederalReturnOptionAvailable,
    isProformaFederalReturnOptionAvailable,
    isPdfFederalReturnOptionAvailable,
    isConsolAsFiledFederalReturnOptionAvailable,
    isConsolProformaFederalReturnOptionAvailable,
    isManualFederalReturnOptionAvailable,
    isFileAvailable,
    federalSubmissionId,
    proformaSubmissionId,
    consolidatedAsFiledSubmissionIdForEntity,
    consolidatedProformaSubmissionIdForEntity,
    isFederalXmlRequired,
    isFederalPdfRequired,
    isFederalAsFiledRequested,
  } = federalReturn;

  const isReturnRequired = Boolean(isFederalXmlRequired || isFederalPdfRequired);

  // we only use the last manualFederalProformaSubmissionId from db if it's part of the list
  const lastManualFederalProformaSubmissionId = manualFederalProformaSubmissionIdOptions.filter(
    option => option.value === federalReturn?.manualFederalProformaSubmissionId,
  )[0]?.value;

  const initialManualFederalProformaSubmissionId =
    lastManualFederalProformaSubmissionId ||
    manualFederalProformaSubmissionIdOptions[0]?.value ||
    '';

  const setFederalReturnCallback = useCallback(
    (federalReturnOption: FederalReturnOption | null) => {
      const federalSubmissionId = setFederalSubmissionIdBasedOnFederalReturnOption({
        federalReturnOption,
        federalAsFiledSubmissionId: federalReturn?.federalSubmissionId || null,
        federalProformaSubmissionId: federalReturn?.proformaSubmissionId || null,
        consolidatedAsFiledSubmissionIdForEntity:
          federalReturn?.consolidatedAsFiledSubmissionIdForEntity || null,
        consolidatedProformaSubmissionIdForEntity:
          federalReturn?.consolidatedProformaSubmissionIdForEntity || null,
        manualFederalProformaSubmissionId: initialManualFederalProformaSubmissionId,
      });

      setFederalSubmissionId(federalSubmissionId);
      setFederalReturnOption(federalReturnOption);
    },
    [
      setFederalSubmissionId,
      setFederalReturnOption,
      federalReturn?.federalSubmissionId,
      federalReturn?.proformaSubmissionId,
      federalReturn?.consolidatedAsFiledSubmissionIdForEntity,
      federalReturn?.consolidatedProformaSubmissionIdForEntity,
      initialManualFederalProformaSubmissionId,
    ],
  );

  const { data: submissionFiles } = useQueryGetSubmissionFiles({
    params: {
      globalContext,
      stateSubmissionId: selectedTtiSubmissionId,
      federalSubmissionId,
      federalReturnOption: selectedFederalReturnOption,
      returnId,
      binderFilingId: null,
    },
    enabled: isGlobalContextReady && Boolean(efSubmissionId),
  });

  const federalFileURL = useMemo(() => {
    if (
      !isFileAvailable ||
      !federalSubmissionId ||
      !selectedFederalReturnOption ||
      !submissionFiles
    ) {
      return [];
    }

    return getFederalSubmissions({
      submissionFiles,
      federalReturnOption: selectedFederalReturnOption,
      taxYear,
    });
  }, [isFileAvailable, federalSubmissionId, selectedFederalReturnOption, submissionFiles, taxYear]);

  const isLinkedAndReady = Boolean(
    federalReturnAttachment === FederalReturnAttachments.LINKED &&
      isFileAvailable &&
      federalSubmissionId &&
      federalFileURL?.length,
  );

  const isLinkedAndNotReady =
    federalReturnAttachment === FederalReturnAttachments.LINKED &&
    (!isFileAvailable || !federalSubmissionId || federalFileURL.length === 0);

  const isUnlinked = federalReturnAttachment === FederalReturnAttachments.UNLINKED;

  const generatedFederalReturnOptions = useMemo(
    () =>
      generateFederalReturnOptions(
        filingTypeId,
        isAsFiledFederalReturnOptionAvailable,
        isProformaFederalReturnOptionAvailable,
        isPdfFederalReturnOptionAvailable,
        isConsolAsFiledFederalReturnOptionAvailable,
        isConsolProformaFederalReturnOptionAvailable,
        isManualFederalReturnOptionAvailable,
        federalSubmissionId,
        proformaSubmissionId,
        consolidatedAsFiledSubmissionIdForEntity,
        consolidatedProformaSubmissionIdForEntity,
        Boolean(isLinkedAndReady),
      ),
    [
      filingTypeId,
      isAsFiledFederalReturnOptionAvailable,
      isProformaFederalReturnOptionAvailable,
      isPdfFederalReturnOptionAvailable,
      isConsolAsFiledFederalReturnOptionAvailable,
      isConsolProformaFederalReturnOptionAvailable,
      isManualFederalReturnOptionAvailable,
      federalSubmissionId,
      proformaSubmissionId,
      consolidatedAsFiledSubmissionIdForEntity,
      consolidatedProformaSubmissionIdForEntity,
      isLinkedAndReady,
    ],
  );

  const getDefaultFederalReturnOption = useCallback(() => {
    if (isUnlinked) {
      if (isFederalAsFiledRequested && isConsolAsFiledFederalReturnOptionAvailable)
        return FederalReturnOption.CONS_AS_FILED;

      if (!isFederalAsFiledRequested && isConsolProformaFederalReturnOptionAvailable) {
        return FederalReturnOption.CONS_PROFORMA;
      }
    }

    if (generatedFederalReturnOptions.length) {
      return generatedFederalReturnOptions[0]?.value || null;
    }

    if (!isUnlinked && !isLinkedAndReady && !isLinkedAndNotReady && !isReturnRequired) {
      return FederalReturnOption.FED_NA;
    }

    return null;
  }, [
    generatedFederalReturnOptions,
    isConsolAsFiledFederalReturnOptionAvailable,
    isConsolProformaFederalReturnOptionAvailable,
    isFederalAsFiledRequested,
    isLinkedAndNotReady,
    isLinkedAndReady,
    isReturnRequired,
    isUnlinked,
  ]);

  const getInitialFederalReturnOption = useCallback(() => {
    const doesStoredFederalReturnOptionExistInGeneratedFederalReturnOptions = generatedFederalReturnOptions.some(
      option => option?.value === storedFederalReturnOption,
    );

    if (doesStoredFederalReturnOptionExistInGeneratedFederalReturnOptions) {
      return storedFederalReturnOption;
    }

    return getDefaultFederalReturnOption();
  }, [generatedFederalReturnOptions, getDefaultFederalReturnOption, storedFederalReturnOption]);

  useEffect(() => {
    setFederalReturnCallback(getInitialFederalReturnOption());
    // only want to run this once on initial render
  }, []);

  const formInitialValues: FormInitialValues = {
    federalReturnOption: getInitialFederalReturnOption(),
    manualFederalProformaSubmissionId: initialManualFederalProformaSubmissionId,
  };

  const trRejectionsMessages =
    trRejectionErrors.length > 0 ? (
      <Loading isLoading={isFetchingEFileTrRejectionMessages}>
        <div className="trRejectionsMessagesColumn">
          <div className={transmitStepContentStyles.transmitStepContent}>
            <StepInformationCard
              title={'Rejection Messages'}
              type={StepInformationCardType.WARNING}
            />
            <div className={transmitStepContentStyles.trRejectionMessagesTable}>
              <AgGrid
                rowData={trRejectionErrors}
                columnDefs={getColumnDefinitions()}
                rowHeight={50}
                rowSelection="single"
                enableBrowserTooltips
              />
            </div>
          </div>
        </div>
      </Loading>
    ) : (
      <></>
    );

  if (isUnlinked) {
    return (
      <StepTab title="Federal Return Selection">
        <div className={styles.returnRequirementsContainer}>
          <div className={styles.formikColumn}>
            <div className={styles.textContainer}>
              <span className={styles.contentTitle}>Choose the Federal return to Include</span>
              <p>
                Including a Federal return is optional. If an electronically filed Federal return is
                selected below, then it will be included.
              </p>
            </div>
            <Formik
              enableReinitialize
              onSubmit={onSubmit}
              validationSchema={efileSchemas.federalReturnSchema}
              initialValues={formInitialValues}
            >
              <EditReturnFilingForm
                setFederalReturn={setFederalReturnCallback}
                generatedFederalReturnOptions={generatedFederalReturnOptions}
                wizardStateMachineSetFederalSubmissionId={wizardStateMachineSetFederalSubmissionId}
                federalReturnOption={selectedFederalReturnOption}
                setFederalSubmissionId={setFederalSubmissionId}
                manualFederalProformaSubmissionIdOptions={manualFederalProformaSubmissionIdOptions}
              />
            </Formik>
          </div>
          {trRejectionsMessages}
        </div>
      </StepTab>
    );
  }

  if (isLinkedAndReady) {
    return (
      <StepTab title="Federal Return Selection">
        <div className={styles.returnRequirementsContainer}>
          <div className={styles.textContainer}>
            <span className={styles.contentTitle}>
              A copy of the electronically filed Federal return that was accepted by the IRS will be
              attached.
            </span>
            <p>No action is required.</p>
          </div>
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={efileSchemas.federalReturnSchema}
            initialValues={formInitialValues}
          >
            <EditReturnFilingForm
              setFederalReturn={setFederalReturnCallback}
              generatedFederalReturnOptions={generatedFederalReturnOptions}
              wizardStateMachineSetFederalSubmissionId={wizardStateMachineSetFederalSubmissionId}
              federalReturnOption={selectedFederalReturnOption}
              setFederalSubmissionId={setFederalSubmissionId}
              manualFederalProformaSubmissionIdOptions={manualFederalProformaSubmissionIdOptions}
            />
          </Formik>
          {trRejectionsMessages}
        </div>
      </StepTab>
    );
  }

  if (isLinkedAndNotReady) {
    // user has already made their choice but the federal team hasn't provided the file yet
    return (
      <StepTab title="Federal Return Selection">
        <div className={styles.returnRequirementsContainer}>
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={efileSchemas.federalReturnSchema}
            initialValues={formInitialValues}
          >
            <EditReturnFilingForm
              setFederalReturn={setFederalReturnCallback}
              generatedFederalReturnOptions={generatedFederalReturnOptions}
              wizardStateMachineSetFederalSubmissionId={wizardStateMachineSetFederalSubmissionId}
              federalReturnOption={selectedFederalReturnOption}
              setFederalSubmissionId={setFederalSubmissionId}
              manualFederalProformaSubmissionIdOptions={manualFederalProformaSubmissionIdOptions}
            />
          </Formik>
          {trRejectionsMessages}
        </div>
      </StepTab>
    );
  }

  if (!isReturnRequired) {
    return (
      <div className={styles.returnNotRequired}>
        <span className={styles.contentTitle}>
          A Federal return is not required for this filing.
        </span>
      </div>
    );
  }

  return (
    <div className={styles.returnNotRequired}>
      <span className={styles.contentTitle}>Unable to determine filing method</span>
    </div>
  );
};

export default ReturnRequirements;
