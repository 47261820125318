import React, { useCallback, Fragment } from 'react';
import { useFormikContext } from 'formik';
import { PdfPageDifference, PdfValidationDifferences } from '@tls/slt-types';

import ButtonsGroup from '../../../shared/forms/buttonsGroup/buttonsGroup.component';
import Loading from '../../../shared/displayComponents/loading.component';

import styles from './pdfAttachment.module.scss';

interface PdfAttachmentValidationProps {
  existingPdfFileName: string;
  newPdfFileName: string;
  onCancel: () => void;
  pdfValidationDifferences: PdfValidationDifferences;
}

const PdfAttachmentValidation = ({
  existingPdfFileName,
  newPdfFileName,
  onCancel,
  pdfValidationDifferences,
}: PdfAttachmentValidationProps) => {
  const { isSubmitting, submitForm } = useFormikContext();

  const { pdfDifferences, totalAdditions, totalDeletions } = pdfValidationDifferences;

  const numberOfChanges = totalAdditions + totalDeletions;

  const renderPageSpecificPdfDifferences = useCallback(
    ({ pageNumber, addedFields, deletedFields, otherDifferences }: PdfPageDifference) => (
      <Fragment key={`page-differences-${pageNumber}`}>
        <h5 className={styles.pageHeader}>Page {pageNumber}</h5>
        {addedFields.length > 0 && (
          <>
            <h6 className={styles.fieldsInformation}>The following fields will be added:</h6>
            <ul className={styles.fieldsList}>
              {addedFields.map(field => (
                <li key={`added-${pageNumber}-${field}`}>{field}</li>
              ))}
            </ul>
          </>
        )}
        {deletedFields.length > 0 && (
          <>
            <h6 className={styles.fieldsInformation}>
              The following fields will be deleted and any mappings will be removed:
            </h6>
            <ul className={styles.fieldsList}>
              {deletedFields.map(field => (
                <li key={`deleted-${pageNumber}-${field}`}>{field}</li>
              ))}
            </ul>
          </>
        )}
        {otherDifferences.length > 0 && (
          <>
            <h6 className={styles.fieldsInformation}>Other validation messages:</h6>
            <ul className={styles.fieldsList}>
              {otherDifferences.map(field => (
                <li key={`other-${pageNumber}-${field}`}>{field}</li>
              ))}
            </ul>
          </>
        )}
      </Fragment>
    ),
    [],
  );

  return (
    <Loading isLoading={isSubmitting}>
      <h5>
        {`Uploading the new file ${newPdfFileName} will replace the current file ${existingPdfFileName}. ${
          numberOfChanges > 0
            ? 'Uploading the new file will cause the following field(s) changes. '
            : ''
        }Do you want to proceed?`}
      </h5>
      {numberOfChanges > 0 && (
        <>
          <div className={styles.validationDifferences}>
            {pdfDifferences
              .filter(
                ({ addedFields, deletedFields, otherDifferences }) =>
                  addedFields.length + deletedFields.length + otherDifferences.length > 0,
              )
              .map(renderPageSpecificPdfDifferences)}
          </div>
          <h5>Additions: {totalAdditions}</h5>
          <h5>Deletions: {totalDeletions}</h5>
        </>
      )}
      <ButtonsGroup
        className={styles.buttonsRow}
        fullWidth={false}
        submitButtonLabel="Yes"
        cancelButtonLabel="No"
        isSubmitting={isSubmitting}
        onCancelClick={onCancel}
        submitForm={submitForm}
      />
    </Loading>
  );
};

export default PdfAttachmentValidation;
