import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import {
  isFetchingContextSelector,
  jurisdictionIdSelector,
  jurisdictionsOptionsSelector,
  isFetchingJurisdictionsSelector,
} from '../taxRatesAndConstantsMaintenance/store/selectors/shared';
import { selectJurisdiction } from '../taxRatesAndConstantsMaintenance/store/actions/shared';

const TaxRatesJurisdictionsDropdown = ({
  selectJurisdiction,
  jurisdictionsOptions,
  isFetchingContext,
  isBusy = false,
  jurisdiction = null,
}) => {
  const handleJurisdictionSelect = useCallback(
    newJurisdiction => {
      if (jurisdiction !== newJurisdiction) {
        selectJurisdiction(newJurisdiction);
      }
    },
    [selectJurisdiction, jurisdiction],
  );

  return (
    <ParamDropdown
      label="Jurisdiction"
      value={jurisdiction}
      options={jurisdictionsOptions}
      handleChange={handleJurisdictionSelect}
      isBusy={isBusy}
      isFetchingContext={isFetchingContext}
    />
  );
};

TaxRatesJurisdictionsDropdown.propTypes = {
  selectJurisdiction: PropTypes.func.isRequired,
  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingContext: PropTypes.bool,
  isBusy: PropTypes.bool,
  jurisdiction: PropTypes.string,
};

export default connect(
  state => ({
    jurisdiction: jurisdictionIdSelector(state),
    jurisdictionsOptions: jurisdictionsOptionsSelector(state),
    isBusy: isFetchingJurisdictionsSelector(state),
    isFetchingContext: isFetchingContextSelector(state),
  }),
  {
    selectJurisdiction,
  },
)(TaxRatesJurisdictionsDropdown);
