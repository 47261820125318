import PropTypes from 'prop-types';

const ItemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export default ItemsPropTypes;
