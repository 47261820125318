import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import omit from 'lodash.omit';
import { Routes } from '@common-packages/routes-definitions';
import { Button } from '@pwc/appkit-react';

import Loading from '../../shared/displayComponents/loading.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import Dropzone from '../../shared/forms/dropzone/dropzone.container';
import { globalContextSelector } from '../../shared/store/selectors';
import { loadDataFromExcelFileBuffer } from '../shared/excel.utils';
import styles from '../shared/dataImportPage.module.scss';

import {
  fetchValidatedInformationalData,
  uploadInformationalData,
  resetValidatedInformationalData,
} from './store/actions';
import {
  validatedInformationalDataSelector,
  isFetchingValidatedInformationalDataSelector,
  isUploadDataValidSelector,
  isUploadingInformationalDataSelector,
} from './store/selectors';
import columnDefinitions from './columnDefinitions';

const DOCUMENT_ACCEPTED_TYPES = ['.xlsx'];
const EXCEL_COLUMN_NAMES = ['infoAccountId', 'orgId', 'consolId', 'jurisdictionId', 'value'];

const convertValuesToText = rows =>
  rows.map(({ infoAccountId, orgId, consolId, jurisdictionId, value }) => ({
    infoAccountId: infoAccountId && `${infoAccountId}`,
    orgId: orgId && `${orgId}`,
    consolId: consolId && `${consolId}`,
    jurisdictionId: jurisdictionId && `${jurisdictionId}`,
    value: value && `${value}`,
  }));

const InformationalDataUpload = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [isParsing, setIsParsing] = useState(false);

  const globalContext = useSelector(globalContextSelector);
  const validatedInformationalData = useSelector(validatedInformationalDataSelector);
  const isFetchingValidatedInformationalData = useSelector(
    isFetchingValidatedInformationalDataSelector,
  );
  const isUploadDataValid = useSelector(isUploadDataValidSelector);
  const isUploadingInformationalData = useSelector(isUploadingInformationalDataSelector);

  useEffect(() => {
    dispatch(resetValidatedInformationalData());
  }, [dispatch]);

  const onDrop = useCallback(file => {
    setFile(file);
    setIsParsing(true);
  }, []);

  const onFileBufferLoaded = useCallback(
    async fileBuffer => {
      const rows = await loadDataFromExcelFileBuffer({
        fileBuffer,
        excelColumnNames: EXCEL_COLUMN_NAMES,
      });
      setIsParsing(false);
      dispatch(
        fetchValidatedInformationalData({
          taxYear: globalContext.params.taxYear,
          period: globalContext.params.period,
          rows: convertValuesToText(rows),
        }),
      );
    },
    [dispatch, globalContext.params.taxYear, globalContext.params.period],
  );

  const uploadValidatedInformationalData = useCallback(() => {
    const {
      taxYear,
      period,
      jurisdictionId,
      businessEntityId,
      filingTypeId,
    } = globalContext.params;
    dispatch(
      uploadInformationalData({
        taxYear,
        period,
        jurisdictionId,
        businessEntityId,
        filingTypeId,
        rows: convertValuesToText(validatedInformationalData).map(row =>
          omit(row, ['isValid', 'result']),
        ),
      }),
    ).then(() => setFile(null));
  }, [dispatch, globalContext.params, validatedInformationalData]);

  return (
    <>
      <div className="row">
        <div className="col">
          <Dropzone
            className={styles.dropzone}
            value={file}
            onDropCallback={onDrop}
            onFileBufferLoaded={onFileBufferLoaded}
            acceptTypes={DOCUMENT_ACCEPTED_TYPES}
          />
        </div>
      </div>
      <div className="row">
        <div className={`col ${styles.fileFormat}`}>
          <div className={styles.fileFormatHeader}>File Format:</div>
          <div>info_account_id, org_id, consol_id, jurisdiction_id, value</div>
        </div>
      </div>
      <div className="row">
        <div className="col add-button-column">
          <Link to={{ pathname: Routes.complianceTools.MAIN }} className={styles.cancelLink}>
            <Button size="lg" className="add-button" kind="secondary">
              Cancel
            </Button>
          </Link>
          <Button
            size="lg"
            className="add-button"
            disabled={
              !isUploadDataValid ||
              isFetchingValidatedInformationalData ||
              isUploadingInformationalData
            }
            onClick={uploadValidatedInformationalData}
          >
            <Loading isLoading={isUploadingInformationalData} small>
              Upload
            </Loading>
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={validatedInformationalData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingValidatedInformationalData || isParsing}
          />
        </div>
      </div>
    </>
  );
};

export default InformationalDataUpload;
