import { TaxYear, Period } from '../../../../common/types';
import { IndividualEntity } from '../../../../common/types/apiShapes';

import { QueryKeys, useCustomQuery } from '.';

export interface MaintenanceIndividualsRequestParams {
  taxYear: TaxYear | null;
  period: Period | null;
  showFederalEntitiesOnly?: boolean;
}

export const useQueryMaintenanceIndividuals = ({
  params,
  enabled,
}: {
  params: MaintenanceIndividualsRequestParams;
  enabled: boolean;
}) =>
  useCustomQuery<IndividualEntity[]>([QueryKeys.IndividualMaintenance.Fields, params], {
    url: `/api/admin/maintenance/individuals/${params.taxYear}/${params.period}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching individuals failed',
  });
