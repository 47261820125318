import { connect } from 'react-redux';

import ReturnWorkspaceRoutes from '../sharedSubPages/returnWorkspace/returnWorkspaceRoutes.component';
import { entityIdSelector } from '../shared/store/selectors';

import {
  nonFilingStatesProfileIdSelector,
  nonFilingStatesJurisdictionIdSelector,
  isFetchingContextSelector,
} from './store/selectors';

export default connect(state => ({
  orgId: entityIdSelector(state),
  profileId: nonFilingStatesProfileIdSelector(state),
  jurisdictionId: nonFilingStatesJurisdictionIdSelector(state),
  isFetchingContext: isFetchingContextSelector(state),
}))(ReturnWorkspaceRoutes);
