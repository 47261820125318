import { DRILL_TYPE } from '@common-packages/drilling';

import { createDrillRoute } from '../../shared/drilling';
import {
  currencyColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../shared/columnDefinitions';
import {
  NumberCellEditor,
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';

const getExtendedDefaultColumnDefinition = getGroupingCellClassRules => ({
  ...defaultColumnDefinitionWithFilter,
  sortable: false,
  cellClassRules: {
    ...defaultColumnDefinitionWithFilter.cellClassRules,
    ...getGroupingCellClassRules(),
  },
});

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const getColumnDefinitions = ({
  getGroupingCellClassRules,
  isInEditMode,
  updateRow,
  isTotalRow,
  location,
  taxYear = '',
  period,
  filingTypeId,
  businessEntityId,
  jurisdictionId,
  taxName,
  categoryId,
  isComponentDetail,
}) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const DataCollectionLinkRenderer = LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: (value, data) =>
      createDrillRoute(
        {
          drillType: DRILL_TYPE.GENERIC_DRILL,
          categoryId,
          accountId: data?.accountId,
        },
        {
          taxYear,
          period,
          filingTypeId,
          businessEntityId,
          jurisdictionId,
          taxName,
        },
      ),
    getRouterState: () => location.state,
  });

  const DataCollectionDescription = props =>
    isTotalRow(props) ? TextRenderer(props) : DataCollectionLinkRenderer(props);

  const priorYear = taxYear ? Number(taxYear) - 1 : '';

  const columnDefinitions = [
    {
      field: 'subCategory',
      rowGroup: true,
      hide: true,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Account',
      field: 'accountDescription',
      width: 650,
      aggFunc: 'subtotalFooter',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Component',
      field: 'componentId',
      cellStyle: {
        textAlign: 'center',
      },
      hide: !isComponentDetail,
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      ...currencyColumnDefinition,
      headerName: 'Data Collection',
      field: 'dataCollection',
      cellRenderer: DataCollectionDescription,
      aggFunc: 'sum',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      ...currencyColumnDefinition,
      headerName: 'Adjustments',
      field: 'adjustments',
      editable: props => isInEditMode && !isTotalRow(props) && props.data?.isEditable,
      cellEditor: NumberCellEditor,
      onCellValueChanged,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
      aggFunc: 'sum',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      ...currencyColumnDefinition,
      headerName: taxYear,
      field: 'currentYearValue',
      aggFunc: 'sum',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      ...currencyColumnDefinition,
      headerName: priorYear,
      field: 'priorYearValue',
      aggFunc: 'sum',
    },
    {
      ...getExtendedDefaultColumnDefinition(getGroupingCellClassRules),
      headerName: 'Account Id',
      field: 'accountId',
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
