import { DiagnosticsResponse } from '@tls/slt-types';

import { QueryKeys, useCustomQuery } from './index';

export const useQueryDiagnostics = ({ enabled }: { enabled: boolean }) =>
  useCustomQuery<DiagnosticsResponse>([QueryKeys.Diagnostics.Data], {
    url: '/api/tools/diagnostics',
    enabled,
    initialData: [],
    errorMessage: 'Fetching diagnostics failed',
  });
