import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import { BreadcrumbItem } from '@pwc/appkit-react/lib/Breadcrumb';

import AppkitIcon from '../appkitIcon/appkitIcon.component';

export const BreadcrumbRoute = ({
  path: { MAIN, displayMainName, compiledRoute },
  search,
  showText = true,
  icon = null,
  ContentRenderer = props => (
    <Fragment>
      {icon}
      {showText ? displayMainName(props) : null}
    </Fragment>
  ),
}) => (
  <Route
    path={MAIN}
    render={({ match: { isExact, params }, location }) => (
      <BreadcrumbItem separator="/">
        {isExact ? (
          <ContentRenderer params={params} location={location} />
        ) : (
          <Link to={`${compiledRoute ? compiledRoute(params) : MAIN}${search ? `?${search}` : ''}`}>
            <ContentRenderer params={params} location={location} />
          </Link>
        )}
      </BreadcrumbItem>
    )}
  />
);

BreadcrumbRoute.propTypes = {
  path: PropTypes.shape({
    MAIN: PropTypes.string.isRequired,
    displayMainName: PropTypes.func.isRequired,
    compiledRoute: PropTypes.func,
  }).isRequired,
  showText: PropTypes.bool,
  search: PropTypes.string,
  icon: PropTypes.element,
  ContentRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
};

export const homeBreadcrumb = <AppkitIcon icon="home" type="fill" size={14} />;

export const StaticHomeBreadcrumb = ({ routePath }) => (
  <BreadcrumbItem separator="/">
    <Link to={routePath}>{homeBreadcrumb}</Link>
  </BreadcrumbItem>
);
StaticHomeBreadcrumb.propTypes = {
  routePath: PropTypes.string.isRequired,
};
