const Yup = require('yup');

const YES = 'Y';

const testPlugOwner = ({ partnershipAllocationPercentages = [] }) =>
  Yup.string()
    .label('Plug Owner')
    .required()
    .test(
      'isThereExistingPlugOwnerForThisEntity',
      'Plug Owner already exists for this Entity',
      function (value, context) {
        const isNewPlugOwnerPossible = !partnershipAllocationPercentages.some(
          ({ partnerId, plugOwner }) =>
            partnerId !== context.parent?.partnerId && plugOwner === YES,
        );
        // eslint-disable-next-line babel/no-invalid-this
        const { path, createError } = this;
        if (!isNewPlugOwnerPossible && value === YES) {
          return createError({ path });
        }
        return true;
      },
    );

const getOwnerSchema = ({ partnershipAllocationPercentages = [] }) =>
  Yup.object()
    .shape({
      partnerId: Yup.string().required().label('Partner Id'),
      entityId: Yup.string().required().label('Entity Id'),
      plugOwner: testPlugOwner({ partnershipAllocationPercentages }),
      percentageType: Yup.string().required().label('Percentage Type'),
      percentage: Yup.number().min(0).max(100).required().label('Percentage'),
    })
    .required();

const updatePartnershipAllocationPercentagesSchema = ({ partnershipAllocationPercentages = [] }) =>
  Yup.object()
    .shape({
      rowsToUpdate: Yup.array()
        .of(
          Yup.object().shape({
            taxYear: Yup.string().required(),
            period: Yup.string().required(),
            entityId: Yup.string().required(),
            partnerId: Yup.string().required(),
            percentageType: Yup.string().required(),
            ownershipPercentage: Yup.number().min(0).max(100).required(),
            plugOwner: testPlugOwner({ partnershipAllocationPercentages }),
          }),
        )
        .notOneOf([undefined, null]), // eslint-disable-line no-undefined
    })
    .strict();

module.exports = {
  updatePartnershipAllocationPercentagesSchema,
  getOwnerSchema,
};
