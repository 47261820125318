import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchPartnershipFlowThroughAmountsTypes,
  FLOW_THROUGH_AMOUNTS_SET_ACCOUNT_ID,
  FLOW_THROUGH_AMOUNTS_SET_JURISDICTION_ID,
} from './types';

export const fetchPartnershipFlowThroughAmounts = apiAction({
  types: fetchPartnershipFlowThroughAmountsTypes,
  apiAction: api.fetchPartnershipFlowThroughAmounts,
});

export const setJurisdictionId = jurisdictionId => ({
  type: FLOW_THROUGH_AMOUNTS_SET_JURISDICTION_ID,
  payload: jurisdictionId,
});

export const setAccountId = accountId => ({
  type: FLOW_THROUGH_AMOUNTS_SET_ACCOUNT_ID,
  payload: accountId,
});
