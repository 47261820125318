import { Routes } from '@common-packages/routes-definitions';

import { LinkCellRendererFactory } from '../../../shared/columnDefinitions/cellRenderers';
import { defaultColumnDefinition } from '../../../shared/columnDefinitions';
import { REPORT_TYPES } from '../../shared/constants';

const ReportLinkRenderer = LinkCellRendererFactory({
  getText: ({ value }) => value,
  getPathName: (value, { reportId }) =>
    Routes.viewCustomReport.compiledRoute({ reportId, reportType: REPORT_TYPES.REPORT }),
});

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Report ID',
    field: 'reportId',
    width: 60,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Name',
    field: 'name',
    width: 400,
    cellRenderer: ReportLinkRenderer,
  },
];

export default columnDefinitions;
