export const submissionsSelector = ({ admin }) => admin.efile.submissions;

export const isFetchingSubmissionsSelector = ({ admin }) => admin.efile.isFetchingSubmissions;

export const caseListSelector = ({ admin }) => admin.efile.caseList;

export const isFetchingCaseListSelector = ({ admin }) => admin.efile.isFetchingCaseList;

export const yearSubPeriodListSelector = ({ admin }) => admin.efile.yearSubPeriodList;

export const isFetchingYearSubPeriodListSelector = ({ admin }) =>
  admin.efile.isFetchingYearSubPeriodList;
