import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { successNotification, errorNotification } from '../../shared/notification/store/actions';

import { startUserJobsPolling, cancelUserJobsPolling } from './actions';

const NOTIFICATION_DURATION = 5000;

export const useUserJobsPolling = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startUserJobsPolling());

    return () => dispatch(cancelUserJobsPolling());
  }, [dispatch]);
};
