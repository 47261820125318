import { amountFormatter } from '../../shared/formatters';
import {
  EXCEL_TEXT_FORMAT,
  EXCEL_NUMBER_FORMAT,
} from '../../shared/displayComponents/agGrid/constants';

import { defaultColumnPivotOptions, numberColumnPivotOptions } from './constants';

const getDynamicColumnDefinitions = (columns = [], types = {}) =>
  columns.map(columnName => {
    const isNumberColumn = types[columnName] && types[columnName].isNumber;

    const columnPivotOptions = isNumberColumn
      ? numberColumnPivotOptions
      : defaultColumnPivotOptions;

    const valueFormatter = isNumberColumn
      ? ({ value, data }) => amountFormatter({ value, props: data })
      : null;

    return {
      headerName: columnName,
      field: columnName,
      valueFormatter,
      cellClass: isNumberColumn ? EXCEL_NUMBER_FORMAT : EXCEL_TEXT_FORMAT,
      ...columnPivotOptions,
    };
  });

export default getDynamicColumnDefinitions;
