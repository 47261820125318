import { useEffect, useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import camelCase from 'lodash.camelcase';

import {
  TaxReturnDefinitionWithFilingAttributes,
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
  ReturnDefinitionFilingAttributes,
} from '../../../../../../common/types/apiShapes';
import { FormValues } from '../../utils/useFormInitialValues';

interface useSetReturnTypeProps {
  isEditing: boolean;
  taxReturnsDefinition?: TaxReturnDefinitionWithFilingAttributes;
  existingTaxReturnsForJurisdiction?: TaxReturnResultRowWithCalcOptionsAndFilingAttributes[];
  returnDefinitionFilingAttributes?: ReturnDefinitionFilingAttributes;
}

const useSetTaxType = ({
  isEditing,
  taxReturnsDefinition,
  existingTaxReturnsForJurisdiction = [],
  returnDefinitionFilingAttributes,
}: useSetReturnTypeProps) => {
  const { setFieldValue } = useFormikContext<FormValues>();
  const [areTaxTypeCheckboxesDisabled, setAreTaxTypeCheckboxesDisabled] = useState(false);

  const setDefaultTaxType = useCallback(() => {
    if (existingTaxReturnsForJurisdiction.length) {
      const existingTaxReturnForJurisdictionTaxTypes = returnDefinitionFilingAttributes?.TAX_TYPE?.filter(
        ({ value }) => existingTaxReturnsForJurisdiction[0][value],
      );
      if (existingTaxReturnForJurisdictionTaxTypes?.length) {
        existingTaxReturnForJurisdictionTaxTypes.forEach(
          existingTaxReturnForJurisdictionTaxType => {
            setFieldValue(
              `taxType.${camelCase(existingTaxReturnForJurisdictionTaxType.label)}`,
              true,
            );
          },
        );
      }
    }

    if (taxReturnsDefinition) {
      setFieldValue('taxType.incomeTax', taxReturnsDefinition.filingAttributes.TAX_TYPE_INCOME);
      setFieldValue(
        'taxType.franchiseTax',
        taxReturnsDefinition.filingAttributes.TAX_TYPE_FRANCHISE,
      );
    }
  }, [
    setFieldValue,
    existingTaxReturnsForJurisdiction,
    taxReturnsDefinition,
    returnDefinitionFilingAttributes,
  ]);

  useEffect(() => {
    if (isEditing) {
      return;
    }
    setDefaultTaxType();
  }, [setDefaultTaxType, isEditing]);

  useEffect(() => {
    if (
      taxReturnsDefinition?.filingAttributes.TAX_TYPE_INCOME !==
      taxReturnsDefinition?.filingAttributes.TAX_TYPE_FRANCHISE
    ) {
      setAreTaxTypeCheckboxesDisabled(true);
    } else {
      setAreTaxTypeCheckboxesDisabled(false);
    }
  }, [
    taxReturnsDefinition?.filingAttributes.TAX_TYPE_FRANCHISE,
    taxReturnsDefinition?.filingAttributes.TAX_TYPE_INCOME,
  ]);

  return { setDefaultTaxType, areTaxTypeCheckboxesDisabled };
};

export default useSetTaxType;
