import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';

import {
  selectDataModel,
  selectDataset,
  selectDataItem,
} from '../../../../shared/store/dataModels/actions';
import {
  dataModelIdSelector,
  datasetIdSelector,
} from '../../../../shared/store/dataModels/selectors';
import { setIsIgnoringCalculationDataChanges } from '../../store/actions';
import useCompileInfoTexts from '../../hooks/useCompileInfoTexts';
import Loading from '../../../../shared/displayComponents/loading.component';
import CompileInfoStatus from '../../compileInfoStatus/compileInfoStatus.component';
import CompileExpressionsGrid from '../../compileExpressions/compileExpressionsGrid/compileExpressionsGrid.component';
import { compilationResultTypes, compilationInfoResponsePropTypes } from '../../propTypes';

import styles from './calculationCompile.module.scss';

const CalculationCompile = ({
  isFetchingCompilationInfo,
  compilationInfo,
  compilationResult,
  validationDate,
  handleCompileButtonClick,
  isCompileButtonDisabled,
  isCompiling,
  updateDirtyItems,
}) => {
  const dispatch = useDispatch();
  const selectedDatasetId = useSelector(datasetIdSelector);
  const selectedDataModelId = useSelector(dataModelIdSelector);

  const {
    compileInfoText,
    expressionsText,
    lastSuccessfulCompileText,
    dataItemsText,
  } = useCompileInfoTexts({
    compilationInfo,
    compilationResult,
    validationDate,
  });

  // dmdID, dsdID, and didID represent the same values
  // as dataModelId, datasetId, and dataItemId respectively
  const openDataItem = useCallback(
    data => {
      if (data) {
        if (!data.dmdID || !data.dsdID) {
          return;
        }
        if (data.dmdID !== selectedDataModelId) {
          dispatch(setIsIgnoringCalculationDataChanges(true));
          dispatch(selectDataModel(data.dmdID));
        }
        if (data.dsdID !== selectedDatasetId) {
          dispatch(setIsIgnoringCalculationDataChanges(true));
          dispatch(selectDataset(data.dsdID));
        }
        dispatch(setIsIgnoringCalculationDataChanges(false));
        dispatch(selectDataItem(data.didID));
        updateDirtyItems();
      }
    },
    [dispatch, selectedDataModelId, selectedDatasetId, updateDirtyItems],
  );

  const handleCellClicked = useCallback(
    ({ event, data }) => {
      if (event?.shiftKey) {
        openDataItem(data);
      }
    },
    [openDataItem],
  );

  return (
    <div className={styles.compileContainer}>
      <div className={styles.compileHeader}>
        <div className={styles.compilationInformation}>
          <div className={styles.compilationSingleInformation}>
            <span>DataItems:</span>
            <Loading small isLoading={isFetchingCompilationInfo}>
              <span>{dataItemsText}</span>
            </Loading>
          </div>
          <div className={styles.compilationSingleInformation}>
            <span>Expressions:</span>
            <Loading small isLoading={isFetchingCompilationInfo}>
              <span>{expressionsText}</span>
            </Loading>
          </div>
          <Loading small isLoading={isFetchingCompilationInfo}>
            <CompileInfoStatus compileInfoText={compileInfoText} />
          </Loading>
          <div className={styles.compilationSingleInformation}>
            <span>Last Successful Compile:</span>
            <Loading small isLoading={isFetchingCompilationInfo}>
              <span>{lastSuccessfulCompileText}</span>
            </Loading>
          </div>
        </div>
        <Button size="lg" onClick={handleCompileButtonClick} disabled={isCompileButtonDisabled}>
          Compile
        </Button>
      </div>
      <CompileExpressionsGrid
        compilationResult={compilationResult}
        isLoading={isCompiling || isFetchingCompilationInfo}
        onCellClicked={handleCellClicked}
        openDataItem={openDataItem}
      />
    </div>
  );
};

CalculationCompile.propTypes = {
  compilationInfo: compilationInfoResponsePropTypes,
  isFetchingCompilationInfo: PropTypes.bool.isRequired,
  compilationResult: PropTypes.arrayOf(compilationResultTypes),
  validationDate: PropTypes.string,
  handleCompileButtonClick: PropTypes.func.isRequired,
  isCompileButtonDisabled: PropTypes.bool.isRequired,
  isCompiling: PropTypes.bool.isRequired,
  updateDirtyItems: PropTypes.func.isRequired,
};

export default CalculationCompile;
