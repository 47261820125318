import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Textarea from '../../shared/forms/textareaFormik/textareaFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import getQueryParams from '../../utils/getQueryParams';
import { ParamTypeValue } from '../../shared/reports/enums';
import saveReportDefinitionFile from '../../shared/reports/utils/saveReportDefinitionFile';

import SlideInButtons from './slideInButtons.component';
import EditParamModalFormWrapper from './editParamModalFormWrapper.component';
import ParamField from './paramFields/paramField.component';
import {
  customQueryResultColumnsSelector,
  fetchingCustomQueryResultErrorSelector,
} from './store/selectors';
import { REPORT_TYPES, CHART_TYPES } from './constants';

const chartTypesOptions = CHART_TYPES.map(({ name }) => ({
  value: name,
  label: name,
}));

const getParamFieldName = paramName => `param-${paramName}`;

const uppercaseFirstLetter = label => label[0].toUpperCase() + label.slice(1);

const checkIfFormIsValid = async validateForm => {
  const validationErrorsObj = await validateForm();
  return Object.keys(validationErrorsObj).length === 0;
};

const extractDependentParamsValues = (currentParams, values, query) => {
  const queryParams = getQueryParams(query);
  const dependentParams = currentParams.filter(param => queryParams.includes(param.mappingName));

  return getParamsValues(dependentParams, values);
};

const validateAndCall = async ({ callback, submitForm, validateForm }) => {
  const isValid = await checkIfFormIsValid(validateForm);
  if (isValid) {
    callback();
  }
  submitForm();
};

const getParamsValues = (queryParams, values) =>
  queryParams.reduce((acc, param) => {
    acc[param.name] = values[getParamFieldName(param.name)] || '';
    return acc;
  }, {});

const updateQueryParams = (queryParams, paramName, paramValues) =>
  queryParams.map(param =>
    param.name === paramName
      ? {
          ...param,
          mappingName: paramValues.mappingName,
          type: paramValues.type,
          query: paramValues.query,
          dropdownValue: paramValues.dropdownValue,
          dropdownLabel: paramValues.dropdownLabel,
          isMultiSelectDropdown: paramValues.isMultiSelectDropdown,
          contextItem: paramValues.contextItem,
        }
      : param,
  );

class AddEditReportForm extends PureComponent {
  static propTypes = {
    values: PropTypes.shape({
      name: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
      serverSide: PropTypes.bool,
      defaultOrderBy: PropTypes.string,
      orderBy: PropTypes.bool,
      reportType: PropTypes.string,
      chart: PropTypes.shape({
        type: PropTypes.string,
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    saveQuery: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    closeSlideIn: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isBusy: PropTypes.bool.isRequired,
    showEditParamModal: PropTypes.func.isRequired,
    initialQueryParams: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        mappingName: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        query: PropTypes.string,
        dropdownValue: PropTypes.string,
        dropdownLabel: PropTypes.string,
      }).isRequired,
    ).isRequired,
    customQueryResultColumns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    fetchingCustomQueryResultError: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const { initialQueryParams } = props;
    this.state = {
      queryParams: initialQueryParams,
      isQueryTested: false,
    };
  }

  componentDidMount() {
    const { values } = this.props;
    this.onQueryChange(values.query);
  }

  onQueryChange = query => {
    const queryParams = getQueryParams(query);
    this.setState(state => ({
      queryParams: queryParams.map(
        paramName =>
          state.queryParams.find(({ name }) => name === paramName) || {
            name: paramName,
            mappingName: paramName,
            type: ParamTypeValue.INPUT,
            query: '',
            dropdownValue: '',
            dropdownLabel: '',
          },
      ),
      isQueryTested: false,
    }));
  };

  testQuery = async () => {
    const { showModal, values } = this.props;
    const { queryParams } = this.state;
    showModal({
      query: values.query,
      serverSide: values.serverSide,
      defaultOrderBy: values.defaultOrderBy,
      params: getParamsValues(queryParams, values),
    });

    this.setState(() => ({
      isQueryTested: true,
    }));
  };

  downloadDefinitionFile = async () => {
    const { validateForm, submitForm, values } = this.props;
    const { queryParams } = this.state;
    const { name, query, reportType, chart, serverSide, defaultOrderBy } = values;

    validateAndCall({
      callback: () =>
        saveReportDefinitionFile({
          name,
          query,
          queryParams,
          reportType,
          chart,
          serverSide,
          defaultOrderBy,
        }),
      submitForm,
      validateForm,
    });
  };

  saveQuery = async () => {
    const { saveQuery, validateForm, submitForm, values } = this.props;
    const { queryParams } = this.state;

    validateAndCall({
      callback: () => saveQuery(values, queryParams),
      submitForm,
      validateForm,
    });
  };

  showEditParamModal = paramName => () => {
    const { showEditParamModal } = this.props;
    const { queryParams } = this.state;
    showEditParamModal(queryParams.find(({ name }) => name === paramName));
  };

  changeParam = (paramName, paramValues) => {
    this.setState(state => ({
      queryParams: updateQueryParams(state.queryParams, paramName, paramValues),
    }));
  };

  handleParamFieldChange = (fieldName, value) => {
    const { setFieldValue } = this.props;
    setFieldValue(fieldName, value);
  };

  render() {
    const {
      closeSlideIn,
      isEditMode,
      isBusy,
      values,
      customQueryResultColumns,
      fetchingCustomQueryResultError,
    } = this.props;
    const { queryParams, isQueryTested } = this.state;
    const isChartType = values.reportType === REPORT_TYPES.CHART;

    return (
      <form>
        <Field
          label="Name"
          name="name"
          component={Input}
          autoComplete="off"
          className="slide-in-field"
        />
        <Field
          onChange={this.onQueryChange}
          className="slide-in-field slide-in-description-field"
          label="Query"
          name="query"
          component={Textarea}
        />
        <Field label="Server Side Data" name="serverSide" component={Checkbox} />
        {values.serverSide && (
          <Field
            label="Default Order By"
            name="orderBy"
            component={Input}
            autoComplete="off"
            className="slide-in-field"
          />
        )}
        {queryParams.map((param, index) => (
          <ParamField
            key={`${index}-${param.name}`}
            param={param}
            value={values[getParamFieldName(param.name)]}
            handleChange={this.handleParamFieldChange}
            showEditParamModal={this.showEditParamModal}
            getParamFieldName={getParamFieldName}
            dependentParams={extractDependentParamsValues(queryParams, values, param.query)}
          />
        ))}
        {isChartType && (
          <div className="slide-in-field">
            <Select
              appkitLabel="Chart Type"
              name="chart.type"
              options={chartTypesOptions}
              value={values.chart.type}
            />
          </div>
        )}
        {isChartType &&
          values.chart.type &&
          CHART_TYPES.find(
            chartType => chartType.name === values.chart.type || { params: [] },
          ).params.map((param, index) => (
            <Select
              key={`${index}-${param}`}
              appkitLabel={uppercaseFirstLetter(param)}
              name={`chart.${param}`}
              value={values.chart[param]}
              options={
                customQueryResultColumns.length
                  ? customQueryResultColumns.map(type => ({ value: type, label: type }))
                  : [{ value: values.chart[param], label: values.chart[param] }]
              }
              disabled={!customQueryResultColumns.length}
            />
          ))}
        <SlideInButtons
          onTest={this.testQuery}
          onSubmit={this.saveQuery}
          onCancel={closeSlideIn}
          onDownload={this.downloadDefinitionFile}
          isBusy={isBusy}
          submitText={isEditMode ? 'Save' : 'Add'}
          disableSubmit={!isQueryTested || fetchingCustomQueryResultError}
          isSaving={isBusy}
        />
        <EditParamModalFormWrapper
          title="Edit Query Param"
          saveParam={this.changeParam}
          existingParams={getParamsValues(queryParams, values)}
        />
      </form>
    );
  }
}

export default connect(
  state => ({
    customQueryResultColumns: customQueryResultColumnsSelector(state),
    fetchingCustomQueryResultError: fetchingCustomQueryResultErrorSelector(state),
  }),
  null,
)(AddEditReportForm);
