import { connect } from 'react-redux';

import BreadcrumbContentRenderer from '../../shared/displayComponents/breadcrumbContentRenderer/breadcrumbContentRenderer.component';
import { entityInformationBreadcrumbLabelSelector } from '../store/selectors';
import { isFetchingEntitiesSelector } from '../../shared/store/selectors';

export default connect(state => ({
  label: entityInformationBreadcrumbLabelSelector(state),
  isLoading: isFetchingEntitiesSelector(state),
}))(BreadcrumbContentRenderer);
