import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Loading from '../../shared/displayComponents/loading.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';

const AddFilingGroupForm = ({
  filingGroupOptions,

  isSubmitting,
  hideModal,
  submitForm,
  resetForm,
  values,
  errors,
  isValidating,
  isValid,
  dirty,
}) => {
  const onCancelClick = useCallback(() => {
    hideModal();
    resetForm();
  }, [hideModal, resetForm]);

  return (
    <form>
      <div>
        <Select
          wrapperClassName="form-text"
          appkitLabel="Filing Group"
          name="selectedFilingGroup"
          options={filingGroupOptions}
          value={values.selectedFilingGroup}
        />
      </div>
      <Loading isLoading={isValidating} small>
        <div>{errors.globalError && <p className="a-form-error">{errors.globalError}</p>}</div>
      </Loading>
      <div>
        <ButtonsGroup
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={onCancelClick}
          submitButtonLabel="Save"
          disabledSubmit={!dirty || isValidating || !isValid}
        />
      </div>
    </form>
  );
};

AddFilingGroupForm.propTypes = {
  filingGroupOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  values: PropTypes.shape({
    selectedFilingGroup: PropTypes.string,
  }),
  errors: PropTypes.shape({
    globalError: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

export default AddFilingGroupForm;
