export const GRID_FILTERS = {
  'STATES FILING IN': {
    filingInState: {
      values: ['true'],
    },
  },
  'STATES NOT FILING IN': {
    filingInState: {
      values: ['false'],
    },
  },
};

export const NO_FILING_GROUPS_MODAL_TITLE = 'No Filing Groups';
export const NO_FILING_GROUPS_MODAL_MESSAGE =
  'To create a consolidation, at least one filing group must exist. Do you want to setup a filing group?';

export const AgGridIds = {
  SEPERATE: 'SeperateSetupTaxReturnDataGrid',
  CONSOLIDATION: 'ConsolidationSetupTaxReturnDataGrid',
};
