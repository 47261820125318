import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const CustomRoute = ({
  wrapper: Wrapper,
  component: Component,
  render,

  additionalDropdowns,
  additionalValues,
  additionalProps,
  disableBreadcrumbs,
  hideGlobalContext,
  hidePeriodDropdown,
  hideJurisdictionDropdown,
  className,
  ...routeRest
}) => {
  const routeRender = useCallback(
    props => (
      <Wrapper
        additionalDropdowns={additionalDropdowns}
        additionalValues={additionalValues}
        disableBreadcrumbs={disableBreadcrumbs}
        hideJurisdictionDropdown={hideJurisdictionDropdown}
        hideGlobalContext={hideGlobalContext}
        hidePeriodDropdown={hidePeriodDropdown}
        className={className}
      >
        {Component ? <Component {...additionalProps} {...props} /> : render(props)}
      </Wrapper>
    ),
    [
      Wrapper,
      Component,
      additionalDropdowns,
      additionalValues,
      additionalProps,
      disableBreadcrumbs,
    ],
  );

  return <Route render={routeRender} {...routeRest} />;
};

CustomRoute.defaultProps = {
  additionalDropdowns: null,
  additionalValues: null,
  additionalProps: {},
  disableBreadcrumbs: false,
  hidePeriodDropdown: false,
  hideGlobalContext: false,
  hideJurisdictionDropdown: false,
};

CustomRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  wrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  additionalDropdowns: PropTypes.element,
  additionalValues: PropTypes.element,
  additionalProps: PropTypes.object,
  disableBreadcrumbs: PropTypes.bool,
  className: PropTypes.string,
  hideGlobalContext: PropTypes.bool,
  hidePeriodDropdown: PropTypes.bool,
  hideJurisdictionDropdown: PropTypes.bool,
};

export default CustomRoute;
