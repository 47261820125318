import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';
import { Routes } from '@common-packages/routes-definitions';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import {
  validationMessagesErrorsSelector,
  validationMessagesWarningsSelector,
  isFetchingEFileStepsDataSelector,
  isFetchingValidationMessagesSelector,
} from '../../store/selectors';
import { fetchValidationMessages } from '../../store/actions';
import config from '../../../config';
import styles from '../../styles.module.scss';
import { fileTypes } from '../constants';
import useAuthQueryString from '../../../shared/hooks/useAuthQueryString.hook';

import errorsColumnDefinitions from './validationErrors.columnDefinitions';
import warningsColumnDefinitions from './validationWarnings.columnDefinitions';

const downloadLink = {
  action: 'download-efile-xml',
  linkDescription: 'Download XML',
  fileType: fileTypes.OUTPUT,
  fileExtension: 'zip',
};

const ValidateStep = ({
  ttiYearId,
  ttiCaseId,
  selectedTtiBinderId,
  efServiceSubmissionId,

  validationMessagesErrors,
  validationMessagesWarnings,
  fetchValidationMessages,
  isFetchingValidationMessages,

  isFetchingEFileStepsData,
  isFetchingTtiBinders,

  isLoading,
  isContextReady,
}) => {
  const authQueryString = useAuthQueryString();

  // only fetch when specific steps are completed
  useEffect(() => {
    const isReady =
      ttiYearId &&
      ttiCaseId &&
      selectedTtiBinderId &&
      efServiceSubmissionId &&
      !isFetchingEFileStepsData &&
      !isFetchingTtiBinders;

    if (isReady) {
      fetchValidationMessages({
        ttiYearId,
        ttiCaseId,
        binderId: selectedTtiBinderId,
        submissionId: efServiceSubmissionId,
      });
    }
  }, [
    fetchValidationMessages,
    ttiCaseId,
    efServiceSubmissionId,
    ttiYearId,
    selectedTtiBinderId,
    isFetchingEFileStepsData,
    isFetchingTtiBinders,
  ]);

  return (
    <>
      <div className={styles.navigationButtonsWrapper}>
        <a
          style={{ textDecoration: 'none' }}
          href={`${config.BASE_PATH}${Routes.eFileDownloadStepFile.compiledRoute({
            ttiYearId: String(ttiYearId),
            ttiCaseId: String(ttiCaseId),
            binderId: String(selectedTtiBinderId),
            submissionId: String(efServiceSubmissionId),
            stepName: downloadLink.action,
            fileType: downloadLink.fileType,
            fileExtension: downloadLink.fileExtension,
          })}?${authQueryString}`}
        >
          <Button
            size="lg"
            className="add-button"
            disabled={isLoading || !isContextReady || isFetchingValidationMessages}
          >
            {downloadLink.linkDescription}
          </Button>
        </a>
      </div>
      <div>
        <h5>Errors</h5>
        <div className="row grid-row">
          <div className="col">
            <AgGrid
              columnDefs={errorsColumnDefinitions}
              isGridLoading={isLoading || isFetchingValidationMessages}
              rowData={validationMessagesErrors}
            />
          </div>
        </div>
        <br />
        <h5>Warnings</h5>
        <div className="row grid-row">
          <div className="col">
            <AgGrid
              columnDefs={warningsColumnDefinitions}
              isGridLoading={isLoading || isFetchingValidationMessages}
              rowData={validationMessagesWarnings}
            />
          </div>
        </div>
      </div>
    </>
  );
};

ValidateStep.propTypes = {
  ttiYearId: PropTypes.string,
  ttiCaseId: PropTypes.string,
  selectedTtiBinderId: PropTypes.number,
  selectedSltBinderId: PropTypes.number,
  efServiceSubmissionId: PropTypes.string,

  isFetchingValidationMessages: PropTypes.bool.isRequired,
  validationMessages: PropTypes.arrayOf(PropTypes.shape({})),
  validationMessagesErrors: PropTypes.arrayOf(PropTypes.shape({})),
  validationMessagesWarnings: PropTypes.arrayOf(PropTypes.shape({})),
  fetchValidationMessages: PropTypes.func.isRequired,

  isFetchingEFileStepsData: PropTypes.bool.isRequired,
  isFetchingTtiBinders: PropTypes.bool.isRequired,

  isLoading: PropTypes.bool.isRequired,
  isContextReady: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    isFetchingValidationMessages: isFetchingValidationMessagesSelector(state),
    validationMessagesErrors: validationMessagesErrorsSelector(state),
    validationMessagesWarnings: validationMessagesWarningsSelector(state),

    isFetchingEFileStepsData: isFetchingEFileStepsDataSelector(state),
  }),
  {
    fetchValidationMessages,
  },
)(ValidateStep);
