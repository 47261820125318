import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import { fetchAttachmentDefinitionsTypes, updateAttachmentDefinitionsTypes } from './types';

export const fetchAttachmentDefinitions = apiAction({
  types: fetchAttachmentDefinitionsTypes,
  apiAction: api.fetchAttachmentDefinitions,
});

export const updateAttachmentDefinitions = apiAction(
  {
    types: updateAttachmentDefinitionsTypes,
    apiAction: api.updateAttachmentDefinitions,
  },
  {
    successNotificationMessage: () => 'Attachment Definitions updated successfully',
    errorNotificationMessage: () => 'Attachment Definitions update failed',
  },
);
