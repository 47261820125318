import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ColDef } from 'ag-grid-community';

interface LinkCellRendererFactoryProps {
  getText: ({
    value,
    valueFormatted,
    data,
  }: {
    value: string;
    valueFormatted: string | null | undefined;
    data: Record<string, string>;
  }) => string | null | undefined;
  getPathName?: (value: string, data: Record<string, string>, colDef?: ColDef) => string | null;
  clickHandler?: (value: string, data: Record<string, string>) => void;
  getRouterState?: (
    value: string,
    data: Record<string, string>,
  ) => Record<string, string> | undefined;
  getQueryParams?: (value: string, data: Record<string, string>) => string | undefined;
  getShowAsLink?: ({
    value,
    valueFormatted,
    data,
  }: {
    value: string;
    valueFormatted: string | null | undefined;
    data: Record<string, string>;
  }) => boolean | null | undefined;
}

export interface LinkRendererProps {
  value: string;
  data: Record<string, string>;
  valueFormatted: string | null | undefined;
  colDef?: ColDef;
}

const LinkCellRendererFactory = ({
  getText = () => null,
  getShowAsLink = () => true,
  getPathName = () => null,
  clickHandler = () => null,
  getRouterState = () => undefined, // eslint-disable-line no-undefined
  getQueryParams = () => undefined, // eslint-disable-line no-undefined
}: LinkCellRendererFactoryProps) => {
  const LinkRenderer = ({ value, data, valueFormatted, colDef }: LinkRendererProps) => {
    const linkHandleClick = useCallback(() => {
      clickHandler(value, data);
    }, [value, data]);
    const showAsLink = getShowAsLink({ value, valueFormatted, data });
    const textToRender = getText({ value, valueFormatted, data });
    if (textToRender !== null) {
      if (showAsLink) {
        return (
          <Link
            to={{
              pathname: getPathName(value, data, colDef) || '',
              search: getQueryParams(value, data),
              state: getRouterState(value, data),
            }}
            onClick={linkHandleClick}
          >
            {textToRender}
          </Link>
        );
      } else return <>{textToRender}</>;
    }

    return null;
  };

  return LinkRenderer;
};

export default LinkCellRendererFactory;
