import { PdfPageDataUsageTypes } from '../constants';

import { createDatasetBreadcrumbs } from './createDatasetBreadcrumbs';

export const pdfPageDataUsageDropdownOptionsFactory = pdfPageDataUsage =>
  pdfPageDataUsage
    .filter(({ type }) => type === PdfPageDataUsageTypes.DATASET_USAGE)
    .sort((a, b) => (a.pageNumber > b.pageNumber ? 1 : -1))
    .map(dataset => ({
      label: createDatasetBreadcrumbs(dataset, pdfPageDataUsage).join(' > '),
      value: dataset.id,
    }));
