import { FIELDS_TO_UPDATE, FIELDS_TO_UNMAP } from '../../../constants';
import { initialValues } from '../constants';

const handleFieldUnmapFactory = ({ selectedFormField, selectedFormFieldId }) => previousValues => {
  const { id, pdfFieldMappingId } = selectedFormField;
  const fieldToUnmap = pdfFieldMappingId ? [{ id, pdfFieldMappingId }] : [];

  return {
    ...previousValues,
    [FIELDS_TO_UPDATE]: {
      ...previousValues?.[FIELDS_TO_UPDATE],
      [selectedFormFieldId]: initialValues,
    },
    [FIELDS_TO_UNMAP]: [...(previousValues?.[FIELDS_TO_UNMAP] || []), ...fieldToUnmap],
  };
};

export default handleFieldUnmapFactory;
