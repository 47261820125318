import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@pwc/appkit-react';

import Item, { ItemPropTypes } from './item.component';

const Tree = ({
  tree,
  itemsLoading,

  ...rest
}) => (
  <List>
    {tree.map((item, i) => (
      <Item key={i} item={item} itemsLoading={itemsLoading} {...rest} />
    ))}
  </List>
);

Tree.propTypes = {
  tree: PropTypes.arrayOf(ItemPropTypes),
  itemsLoading: PropTypes.object, // eslint-disable-line
};

export default Tree;
