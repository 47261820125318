import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OverrideReportTypes } from '@common-packages/shared-constants';

import useReportParam from '../../shared/reports/useReportParam.hook';
import { globalContextSelector } from '../../shared/store/selectors';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';

const useOverrideReportParams = ({ endpointBasePath, isSeparate, useBusinessId }) => {
  const { jurisdictionId, businessEntityId } = useSelector(globalContextSelector);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const jurisdictionIdParam = useReportParam({
    endpointBasePath,
    paramName: 'jurisdictionId',
    defaultParamValue: jurisdictionId,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  const separateParamName =
    useBusinessId === OverrideReportTypes.BUSINESS_ID ? 'businessId' : 'entityId';

  const businessEntityIdParam = useReportParam({
    endpointBasePath,
    paramName: isSeparate ? separateParamName : 'consolId',
    defaultParamValue: businessEntityId,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        jurisdictionId: jurisdictionIdParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue, jurisdictionIdParam.paramValue],
    ),
  });

  return {
    taxYearParam,
    periodParam,
    businessEntityIdParam,
    jurisdictionIdParam,
  };
};

export default useOverrideReportParams;
