import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@pwc/appkit-react';

import { resetActiveTab } from '../store/actions';
import { activeTabSelector } from '../store/selectors';
import styles from '../styles.module.scss';
import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { compilationResultTypes, compilationInfoResponsePropTypes } from '../propTypes';

import CalculationForm from './calculationForm/calculationForm.container';
import CalculationDataItems from './calculationDataItems/calculationDataItems.container';
import CalculationFunctions from './calculationFunctions/calculationFunctions.container';
import CalculationAccounts from './calculationAccounts/calculationAccounts.container';
import CalculationCategories from './calculationCategories/calculationCategories.component';
import CalculationInfoAccounts from './calculationInfoAccounts/calculationInfoAccounts.container';
import CalculationConstants from './calculationConstants/calculationConstants.container';
import CalculationCompile from './calculationCompile/calculationCompile.container';
import { TabsTypes, TABS_DEFINITIONS } from './constants';

const Functions = ({
  addFunctionSepExpression,
  addFunctionConsolExpression,
  addDataItemExpression,
  addDataItemConsolExpression,
  compilationInfo,
  isFetchingCompilationInfo,
  compilationResult,
  validationDate,
  handleCompileButtonClick,
  isCompileButtonDisabled,
  isCompiling,
  updateDirtyItems,
  generateContextMenuItems,
  isUsingSameExpression,
}) => {
  const dispatch = useDispatch();
  const activeTabValue = useSelector(activeTabSelector);
  const {
    queryParams: { formId },
  } = useUrlParams();

  const [activeTab, setActiveTab] = useState(activeTabValue || TABS_DEFINITIONS[0].type);

  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);

  useEffect(() => {
    if (!formId) {
      dispatch(resetActiveTab());
    }
  }, [dispatch, formId]);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TabsTypes.ACCOUNTS:
        return (
          <CalculationAccounts
            addFunctionSepExpression={addFunctionSepExpression}
            addFunctionConsolExpression={addFunctionConsolExpression}
            generateContextMenuItems={generateContextMenuItems}
          />
        );
      case TabsTypes.CATEGORIES:
        return (
          <CalculationCategories
            addFunctionSepExpression={addFunctionSepExpression}
            addFunctionConsolExpression={addFunctionConsolExpression}
            generateContextMenuItems={generateContextMenuItems}
          />
        );
      case TabsTypes.COMPILE:
        return (
          <CalculationCompile
            compilationInfo={compilationInfo}
            isFetchingCompilationInfo={isFetchingCompilationInfo}
            compilationResult={compilationResult}
            validationDate={validationDate}
            handleCompileButtonClick={handleCompileButtonClick}
            isCompileButtonDisabled={isCompileButtonDisabled}
            isCompiling={isCompiling}
            updateDirtyItems={updateDirtyItems}
          />
        );
      case TabsTypes.CONSTANTS:
        return (
          <CalculationConstants
            addFunctionSepExpression={addFunctionSepExpression}
            addFunctionConsolExpression={addFunctionConsolExpression}
            generateContextMenuItems={generateContextMenuItems}
          />
        );
      case TabsTypes.DATA_ITEMS:
        return (
          <CalculationDataItems
            addDataItemExpression={addDataItemExpression}
            addDataItemConsolExpression={addDataItemConsolExpression}
            updateDirtyItems={updateDirtyItems}
            generateContextMenuItems={generateContextMenuItems}
          />
        );
      case TabsTypes.INFO_ACCOUNTS:
        return (
          <CalculationInfoAccounts
            addFunctionSepExpression={addFunctionSepExpression}
            addFunctionConsolExpression={addFunctionConsolExpression}
            generateContextMenuItems={generateContextMenuItems}
          />
        );
      case TabsTypes.FORM:
        return (
          <CalculationForm
            addDataItemExpression={addDataItemExpression}
            addDataItemConsolExpression={addDataItemConsolExpression}
            updateDirtyItems={updateDirtyItems}
            generateContextMenuItems={generateContextMenuItems}
            isUsingSameExpression={isUsingSameExpression}
          />
        );
      case TabsTypes.FUNCTIONS:
        return (
          <CalculationFunctions
            addFunctionSepExpression={addFunctionSepExpression}
            addFunctionConsolExpression={addFunctionConsolExpression}
            generateContextMenuItems={generateContextMenuItems}
          />
        );
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {TABS_DEFINITIONS.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className={styles.background}>{getActiveTabComponent()}</div>
    </>
  );
};

Functions.propTypes = {
  addFunctionSepExpression: PropTypes.func.isRequired,
  addFunctionConsolExpression: PropTypes.func.isRequired,
  addDataItemExpression: PropTypes.func.isRequired,
  addDataItemConsolExpression: PropTypes.func.isRequired,
  areExpressionsDirty: PropTypes.bool.isRequired,
  compilationInfo: compilationInfoResponsePropTypes,
  isFetchingCompilationInfo: PropTypes.bool.isRequired,
  compilationResult: PropTypes.arrayOf(compilationResultTypes),
  validationDate: PropTypes.string,
  handleCompileButtonClick: PropTypes.func.isRequired,
  isCompileButtonDisabled: PropTypes.bool.isRequired,
  isCompiling: PropTypes.bool.isRequired,
  updateDirtyItems: PropTypes.func.isRequired,
  generateContextMenuItems: PropTypes.func.isRequired,
  isUsingSameExpression: PropTypes.bool.isRequired,
};

export default Functions;
