import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { Formik } from 'formik';
import { Button } from '@pwc/appkit-react';

import useModal from '../../shared/hooks/useModal.hook';
import WarningModal from '../../shared/displayComponents/warningModal.component';
import Loading from '../../shared/displayComponents/loading.component';
import useFetch from '../../shared/hooks/useFetch.hook';
import { useFormEditMode } from '../../shared/editMode';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import SplitPane from '../../shared/displayComponents/splitPane/splitPane.component';
import {
  fetchDataModels,
  fetchDatasets,
  fetchDataItems,
} from '../../shared/store/dataModels/actions';
import { taxYearSelector, jurisdictionIdSelector } from '../store/selectors';
import {
  dataModelIdSelector,
  datasetIdSelector,
  dataModelsSelector,
} from '../../shared/store/dataModels/selectors';
import { QueryKeys } from '../../shared/queries';
import { blockContextChanges } from '../store/actions';
import { useUpdateDevelopmentContextFromQueryParams } from '../store/hooks';

import { setSplitPaneLeftWidth } from './store/actions';
import {
  splitPaneLeftWidthSelector,
  isFetchingDataModelsSelector,
  isFetchingDatasetsSelector,
  dataItemsOptionSelector,
  isFetchingDataItemsSelector,
} from './store/selectors';
import prepareExpressionFunction from './prepareExpressionFunction';
import * as api from './store/api';
import Functions from './functions/functions.component';
import ExpressionCalculation from './expressionCalculation/expressionCalculation.container';
import ExpressionCalculationForm from './expressionCalculation/expressionCalculationForm.component';
import ValidationInfoStatus from './validationInfoStatus/validationInfoStatus.component';
import { WARNING_MODAL_TITLE, WARNING_MODAL_CONTEXT_MESSAGE } from './constants';
import useCompileButtonLogic from './hooks/useCompileButtonLogic';
import styles from './styles.module.scss';

const formInitialValues = {
  consolExpression: '',
  lastUpdated: null,
  notes: '',
  separateExpression: '',
  useSameExpressionForConsol: false,
  disallowUserOverride: false,
};

const ExpressionBuilder = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [separateExpressionToAdd, setSeparateExpressionToAdd] = useState('');
  const [consolExpressionToAdd, setConsolExpressionToAdd] = useState('');
  const [dirtyDataItems, setDirtyDataItems] = useState([]);
  const [dirtyDataItem, setDirtyDataItem] = useState({});

  useUpdateDevelopmentContextFromQueryParams();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const dataModelId = useSelector(dataModelIdSelector);
  const dataModels = useSelector(dataModelsSelector);
  const datasetId = useSelector(datasetIdSelector);
  const dataItemsOption = useSelector(dataItemsOptionSelector);
  const isFetchingDataModels = useSelector(isFetchingDataModelsSelector);
  const isFetchingDatasets = useSelector(isFetchingDatasetsSelector);
  const isFetchingDataItems = useSelector(isFetchingDataItemsSelector);
  const splitPaneLeftWidth = useSelector(splitPaneLeftWidthSelector);
  const dataItemOptionValue = dataItemsOption?.value;

  const {
    data: values,
    fetch: fetchExpression,
    isFetching: isFetchingExpression,
    resetData: resetExpression,
  } = useFetch({ action: api.fetchExpression });

  const {
    data: validation,
    fetch: validateExpression,
    isFetching: isValidatingExpression,
    resetData: resetValidation,
  } = useFetch({ action: api.validateExpression });

  const { fetch: saveMultipleExpressions, isFetching: isSavingExpressions } = useFetch({
    action: api.saveMultipleExpressions,
    successNotificationMessage: 'Expressions have been successfully saved',
  });

  const isUsingSameExpression = formRef.current?.values.useSameExpressionForConsol;

  const isLoadingExpressionOrOptions =
    isFetchingExpression || isFetchingDataModels || isFetchingDatasets || isFetchingDataItems;

  const areButtonsDisabled =
    isValidatingExpression || isLoadingExpressionOrOptions || isSavingExpressions;

  const isCacheDirty = dirtyDataItems.length > 0;

  const onValidateClick = useCallback(() => {
    if (formRef.current) {
      validateExpression({
        ...formRef.current.values,
        dataItemDefId: dataItemOptionValue,
      });
    }
  }, [validateExpression, dataItemOptionValue]);

  const { showModal: showWarningModal, modalProps } = useModal();

  const onSaveMultiple = useCallback(
    async data => {
      await saveMultipleExpressions([
        ...dirtyDataItems,
        {
          dataItemDefId: dataItemOptionValue,
          values: { ...data },
        },
      ]);
      setDirtyDataItems([]);
      queryClient.resetQueries(QueryKeys.ExpressionBuilder.CompilationInfo);
      fetchExpression({ dataItemDefId: dataItemOptionValue });
    },
    [saveMultipleExpressions, dirtyDataItems, dataItemOptionValue, queryClient, fetchExpression],
  );

  const onCancel = useCallback(() => {
    setDirtyDataItems([]);
  }, [setDirtyDataItems]);

  const onResize = useCallback(
    payload => {
      dispatch(setSplitPaneLeftWidth(payload));
    },
    [dispatch],
  );

  const additionalEditModeElements = (
    <Button
      size="lg"
      onClick={onValidateClick}
      className="add-button"
      disabled={areButtonsDisabled}
    >
      Validate
    </Button>
  );

  const { editModeButtons, onFormDirtyChange, navigationPrompt, isFormDirty } = useFormEditMode({
    formRef,
    blockContextChanges,
    additionalEditModeElements,
    editButtonDisabled: areButtonsDisabled,
    cancelButtonDisabled: areButtonsDisabled,
    saveButtonDisabled: areButtonsDisabled,
    isDirty: isCacheDirty,
    onSave: onSaveMultiple,
    onCancel,
  });

  const updateDirtyItems = useCallback(() => {
    if (isFormDirty) {
      setDirtyDataItems(currentDirtyDataItems => [
        ...currentDirtyDataItems,
        { dataItemDefId: dataItemOptionValue, values: formRef.current.values },
      ]);
    }
  }, [isFormDirty, setDirtyDataItems, dataItemOptionValue]);

  const removeDirtyDataItem = useCallback(
    dataItemDefId => {
      if (dirtyDataItem.dataItemDefId === dataItemOptionValue) {
        setDirtyDataItems(currentDirtyDataItems =>
          currentDirtyDataItems.filter(dataItem => dataItem.dataItemDefId !== dataItemDefId),
        );
      }
    },
    [setDirtyDataItems, dirtyDataItem, dataItemOptionValue],
  );

  useEffect(() => {
    const newDirtyDataItem = dirtyDataItems.find(
      dirtyDataItem => dirtyDataItem.dataItemDefId === dataItemOptionValue,
    );
    if (newDirtyDataItem) {
      setDirtyDataItem(newDirtyDataItem);
    } else {
      setDirtyDataItem({});
    }
  }, [dataItemOptionValue, dirtyDataItems, setDirtyDataItem]);

  useEffect(() => {
    if (taxYear && jurisdictionId && !dataModels?.length) {
      dispatch(fetchDataModels({ taxYear, jurisdictionId }));
    }
  }, [dispatch, dataModels, taxYear, jurisdictionId]);

  useEffect(() => {
    if (taxYear && jurisdictionId && dataModelId) {
      dispatch(fetchDatasets({ taxYear, jurisdictionId, dataModelId }));
    }
  }, [dispatch, dataModelId, taxYear, jurisdictionId]);

  useEffect(() => {
    if (taxYear && datasetId) {
      dispatch(fetchDataItems({ datasetId, taxYear }));
    }
  }, [dispatch, datasetId, taxYear]);

  useEffect(() => {
    if (dataItemOptionValue) {
      resetValidation();
      fetchExpression({ dataItemDefId: dataItemOptionValue });
    }
  }, [resetValidation, fetchExpression, dataItemOptionValue]);

  const renderAdditionalHeaderElements = useCallback(() => <div>{editModeButtons}</div>, [
    editModeButtons,
  ]);

  const addFunctionSepExpression = useCallback(
    ({ functionName, paramsArray }) => {
      if (functionName) {
        const separateExpressionToAdd = prepareExpressionFunction({
          functionName,
          paramsArray,
        });
        setSeparateExpressionToAdd(separateExpressionToAdd);
      }
    },
    [setSeparateExpressionToAdd],
  );

  const addFunctionConsolExpression = useCallback(
    ({ functionName, paramsArray }) => {
      if (functionName) {
        const consolExpressionToAdd = prepareExpressionFunction({
          functionName,
          paramsArray,
        });
        setConsolExpressionToAdd(consolExpressionToAdd);
      }
    },
    [setConsolExpressionToAdd],
  );

  const addDataItemExpression = useCallback(
    valueToAppend => {
      if (valueToAppend) {
        setSeparateExpressionToAdd(`[${valueToAppend}]`);
      }
    },
    [setSeparateExpressionToAdd],
  );

  const addDataItemConsolExpression = useCallback(
    valueToAppend => {
      if (valueToAppend) {
        setConsolExpressionToAdd(`[${valueToAppend}]`);
      }
    },
    [setConsolExpressionToAdd],
  );

  const clearExpressionToAdd = useCallback(() => {
    setSeparateExpressionToAdd('');
  }, [setSeparateExpressionToAdd]);

  const clearConsolExpressionToAdd = useCallback(() => {
    setConsolExpressionToAdd('');
  }, [setConsolExpressionToAdd]);

  const generateContextMenuItems = useCallback(
    (sepItemAction, consolItemAction, dataItemAction) => {
      const getContextMenuItems = ({ node }) => [
        {
          name: 'Add to Separate Expression',
          disabled: isLoadingExpressionOrOptions,
          action: () => sepItemAction(node),
        },
        {
          name: 'Add to Consolidation Expression',
          disabled: isUsingSameExpression || isLoadingExpressionOrOptions,
          action: () => consolItemAction(node),
        },
        ...(dataItemAction
          ? [
              {
                name: 'Open DataItem',
                disabled: isLoadingExpressionOrOptions,
                action: () => dataItemAction(node),
              },
            ]
          : []),
        'separator',
        'copy',
        'copyWithHeaders',
        'export',
      ];

      return getContextMenuItems;
    },
    [isUsingSameExpression, isLoadingExpressionOrOptions],
  );

  const renderForm = useCallback(
    formikProps => (
      <Loading className="col" isLoading={isSavingExpressions || isLoadingExpressionOrOptions}>
        <ExpressionCalculationForm
          {...formikProps}
          {...validation}
          lastUpdated={values?.lastUpdated}
          separateExpressionToAdd={separateExpressionToAdd}
          consolExpressionToAdd={consolExpressionToAdd}
          clearExpressionToAdd={clearExpressionToAdd}
          clearConsolExpressionToAdd={clearConsolExpressionToAdd}
          onFormDirtyChange={onFormDirtyChange}
          dirtyDataItem={dirtyDataItem}
          removeDirtyDataItem={removeDirtyDataItem}
        />
      </Loading>
    ),
    [
      clearExpressionToAdd,
      clearConsolExpressionToAdd,
      onFormDirtyChange,
      removeDirtyDataItem,
      isSavingExpressions,
      isLoadingExpressionOrOptions,
      validation,
      values?.lastUpdated,
      separateExpressionToAdd,
      consolExpressionToAdd,
      dirtyDataItem,
    ],
  );

  const {
    compilationResult,
    validationDate,
    handleCompileButtonClick,
    isCompileButtonDisabled,
    isCompiling,
    compilationInfo,
    isFetchingCompilationInfo,
  } = useCompileButtonLogic({ taxYear, jurisdictionId });

  const renderCompilationStatus = useCallback(
    () => (
      <ValidationInfoStatus
        className={styles.validationInfoStatus}
        compilationInfo={compilationInfo}
        compilationResult={compilationResult}
        isCompiling={isCompiling}
      />
    ),
    [compilationInfo, compilationResult, isCompiling],
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <HeaderWithParamDropdownsWrapper
            showHeaderTaxYearDropdown
            showHeaderJurisdictionDropdown
            showWarningModal={showWarningModal}
            renderAdditionalElements={renderAdditionalHeaderElements}
            renderAdditionalLeftSideElements={renderCompilationStatus}
            enableEverywhereJurisdiction
          />
        </div>
      </div>
      <SplitPane initialWidth={splitPaneLeftWidth} onResize={onResize}>
        <Functions
          addFunctionSepExpression={addFunctionSepExpression}
          addFunctionConsolExpression={addFunctionConsolExpression}
          addDataItemExpression={addDataItemExpression}
          addDataItemConsolExpression={addDataItemConsolExpression}
          areExpressionsDirty={isFormDirty}
          compilationInfo={compilationInfo}
          isFetchingCompilationInfo={isFetchingCompilationInfo}
          compilationResult={compilationResult}
          validationDate={validationDate}
          handleCompileButtonClick={handleCompileButtonClick}
          isCompileButtonDisabled={isCompileButtonDisabled}
          isCompiling={isCompiling}
          updateDirtyItems={updateDirtyItems}
          generateContextMenuItems={generateContextMenuItems}
          isUsingSameExpression={isUsingSameExpression}
        />
        <ExpressionCalculation
          resetExpression={resetExpression}
          onValidateClick={onValidateClick}
          isValidateDisabled={areButtonsDisabled}
          editModeButtons={editModeButtons}
          areExpressionsDirty={isFormDirty}
          updateDirtyItems={updateDirtyItems}
        >
          <Formik enableReinitialize initialValues={values || formInitialValues} innerRef={formRef}>
            {renderForm}
          </Formik>
        </ExpressionCalculation>
      </SplitPane>
      {navigationPrompt}
      <WarningModal
        title={WARNING_MODAL_TITLE}
        warningMessage={WARNING_MODAL_CONTEXT_MESSAGE}
        {...modalProps}
      />
    </>
  );
};

export default ExpressionBuilder;
