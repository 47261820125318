import React from 'react';
import { Field, useFormikContext } from 'formik';

import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import Checkbox from '../../../shared/forms/checkboxFormik/checkboxFormik.component';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import { contextTypeOptions } from '../constants';
import styles from '../dataModels.module.scss';

const ModelForm = () => {
  const { values } = useFormikContext();

  return (
    <form>
      <Field
        className="modal-field"
        label="Name"
        name="name"
        component={Input}
        autoComplete="off"
      />
      <Field
        className="modal-field"
        label="Description"
        name="description"
        component={Input}
        autoComplete="off"
      />
      <Select
        wrapperClassName="modal-field"
        appkitLabel="Context Type"
        name="contextTypeId"
        options={contextTypeOptions}
        value={values.contextTypeId}
      />
      <div className={styles.checkboxesGroup}>
        <Field className="modal-field" label="Common" name="isCommon" component={Checkbox} />
        <Field
          className="modal-field"
          label="Display Workpaper on Navigator"
          name="shouldDisplayWorkpaperOnNavigator"
          component={Checkbox}
        />
      </div>
    </form>
  );
};

export default ModelForm;
