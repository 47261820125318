import { getApiActionTypes } from '../../shared/store/storeHelpers';

export const fetchJurisdictionsTypes = getApiActionTypes('DEVELOPMENT/FETCH_JURISDICTIONS');
export const fetchJurisdictionsForAttachmentsAndBindersTypes = getApiActionTypes(
  'DEVELOPMENT/FETCH_JURISDICTIONS_FOR_ATTACHMENT_DEFINITIONS_AND_DEFAULT_BINDERS',
);
export const fetchTaxYearsTypes = getApiActionTypes('DEVELOPMENT/FETCH_TAX_YEARS');
export const fetchPeriodsTypes = getApiActionTypes('DEVELOPMENT/FETCH_PERIOD_TYPES');
export const fetchConsolidationsTypes = getApiActionTypes('DEVELOPMENT/FETCH_CONSOLIDATIONS');
export const SELECT_JURISDICTION_ID = 'DEVELOPMENT/SELECT_JURISDICTION_ID';
export const SELECT_GENERIC_FUNCTION = 'SELECT_GENERIC_FUNCTION';
export const SET_PREVIOUS_PATHNAME = 'DEVELOPMENT/SET_PREVIOUS_PATHNAME';
export const SELECT_TAX_YEAR = 'DEVELOPMENT/SELECT_TAX_YEAR';
export const SELECT_PERIOD = 'DEVELOPMENT/SELECT_PERIOD';
export const BLOCK_DEVELOPMENT_CONTEXT_CHANGES = 'BLOCK_DEVELOPMENT_CONTEXT_CHANGES';
export const RESET_FORM_HTML_IMAGE = 'RESET_FORM_HTML_IMAGE';

export const SELECT_FORM = 'DEVELOPMENT/SELECT_FORM';
export const SELECT_FORM_PAGE_WITH_SCROLL = 'DEVELOPMENT/SELECT_FORM_PAGE_WITH_SCROLL';
export const SELECT_FORM_PAGE_WITH_DROPDOWN = 'DEVELOPMENT/SELECT_FORM_PAGE_WITH_DROPDOWN';

export const fetchFormsTypes = getApiActionTypes('DEVELOPMENT/FETCH_FORMS');
export const fetchFormHtmlImageTypes = getApiActionTypes('DEVELOPMENT/FETCH_FORM_HTML_IMAGE');
