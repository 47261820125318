import { createSelector } from 'reselect';

export const businessGroupsSelector = createSelector(
  [({ admin }) => admin.businessGroups.businessGroups],
  businessGroups =>
    businessGroups.map((businessGroup, rowId) => ({
      ...businessGroup,
      rowId,
    })),
);

export const isFetchingBusinessGroupsSelector = ({ admin }) =>
  admin.businessGroups.isFetchingBusinessGroups;

export const isUpdatingBusinessGroupsSelector = ({ admin }) =>
  admin.businessGroups.isUpdatingBusinessGroups;
