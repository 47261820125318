import { currencyColumnDefinition } from '../../../shared/columnDefinitions';

const overlinedCellClassRules = {
  'overlined-cell': ({ data }) => data.isTotalRow,
};

const accountCellClassRules = {
  'bold-cell': ({ data }) => data.isTotalRow,
};

const defaultColumnDefinition = {
  sortable: false,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
  cellClassRules: { ...overlinedCellClassRules },
};

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Component Id',
    field: 'componentId',
    cellClassRules: { ...accountCellClassRules, ...overlinedCellClassRules },
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account',
    field: 'accountId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Book Amount',
    field: 'bookAmount',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Reclass Amount',
    field: 'reclassAmount',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Book/Tax Adj Amount',
    field: 'bkTxAmount',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Amount',
    field: 'fedTaxAmount',
  },
];

export default columnDefinitions;
