import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { createDataProvider } from '../../shared/dataProviders';
import { sltBinderIdSelector } from '../../shared/store/context';
import { fetchNavigatorBinderData } from '../store';

const BinderDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const sltBinderId = useSelector(sltBinderIdSelector);

  useEffect(() => {
    if (sltBinderId) {
      dispatch(fetchNavigatorBinderData({ binderId: sltBinderId }));
    }
  }, [dispatch, sltBinderId]);

  return children;
};

BinderDataProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default createDataProvider({
  ProviderComponent: BinderDataProvider,
});
