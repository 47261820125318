import { defaultColumnDefinition, adminColumnDefinition } from '../../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onDeleteIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: row => row.pdfFieldMappingId,
    onDeleteIconClick,
    customToolTipMessage: 'Unmap',
    customIcon: 'circle-minus',
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Order',
      field: 'displayOrder',
      width: 60,
      minWidth: 60,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
      },
      sort: 'asc',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Field Name',
      field: 'name',
      width: 220,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Dataset',
      field: 'datasetName',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Data Item',
      field: 'dataItemDefinitionName',
    },
  ];
};

export default getColumnDefinitions;
