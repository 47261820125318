import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ReadonlyFormValueWithLabel = ({ label, value }) => (
  <>
    <label className="a-form-label">{label}</label>
    <label className={styles.readonlyValue}>{value}</label>
  </>
);

ReadonlyFormValueWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ReadonlyFormValueWithLabel;
