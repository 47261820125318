import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Tab, Tabs } from '@pwc/appkit-react';

import AccountAuditTrails from './accountAuditTrails/accountAuditTrails.container';
import CalcData from './calcData/calcData.container';
import AccountData from './accountData/accountData.container';
import AccountAdjustment from './accountAdjustment/accountAdjustment.container';

const TABS_TYPES = {
  ACCOUNT_AUDIT_TRAIL: 'ACCOUNT_AUDIT_TRAIL',
  ACCOUNT_DATA: 'ACCOUNT_DATA',
  ACCOUNT_ADJUSTMENT: 'ACCOUNT_ADJUSTMENT',
  CALC_DATA: 'CALC_DATA',
};

const tabsDefinitions = [
  { type: TABS_TYPES.ACCOUNT_AUDIT_TRAIL, label: 'Account Audit Trail' },
  { type: TABS_TYPES.ACCOUNT_DATA, label: 'Account Data' },
  { type: TABS_TYPES.ACCOUNT_ADJUSTMENT, label: 'Account Adjustment' },
  { type: TABS_TYPES.CALC_DATA, label: 'Calc Data' },
];

const AllReturnData = ({ orgId, jurisdictionId, isFetchingContext }) => {
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.ACCOUNT_AUDIT_TRAIL:
        return (
          <AccountAuditTrails
            orgId={orgId}
            jurisdictionId={jurisdictionId}
            isFetchingContext={isFetchingContext}
          />
        );
      case TABS_TYPES.ACCOUNT_DATA:
        return (
          <AccountData
            orgId={orgId}
            jurisdictionId={jurisdictionId}
            isFetchingContext={isFetchingContext}
          />
        );
      case TABS_TYPES.ACCOUNT_ADJUSTMENT:
        return (
          <AccountAdjustment
            orgId={orgId}
            jurisdictionId={jurisdictionId}
            isFetchingContext={isFetchingContext}
          />
        );
      case TABS_TYPES.CALC_DATA:
        return (
          <CalcData
            orgId={orgId}
            jurisdictionId={jurisdictionId}
            isFetchingContext={isFetchingContext}
          />
        );
      default:
        throw new Error('Unsupported tab type');
    }
  };
  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }, index) => (
              <Tab id={type} value={type} label={label} key={index} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">{getActiveTabComponent()}</div>
      </div>
    </>
  );
};

AllReturnData.propTypes = {
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool.isRequired,
};

export default AllReturnData;
