import { FIELDS_TO_UPDATE, FIELDS_TO_UNMAP } from '../../../constants';
import { FIELD_ATTRIBUTES, initialValues, DEFAULT_WRAPPING_OPTIONS } from '../constants';

const handleAttributeChangeFactory = ({
  name,
  value,
  selectedFormField,
  selectedFormFieldId,
}) => previousValues => {
  const previousFieldValues = previousValues?.[FIELDS_TO_UPDATE]?.[selectedFormFieldId];
  const previousDatasetId = previousFieldValues?.datasetId || selectedFormField.datasetId;
  const previousDataItemId = previousFieldValues?.dataItemId || selectedFormField.dataItemId;
  const previousFieldsToUnmap = previousValues?.[FIELDS_TO_UNMAP];

  const shouldForcedInitialValues =
    name === FIELD_ATTRIBUTES.DATASET_ID || name === FIELD_ATTRIBUTES.DATAITEM_ID;

  const clearWrappingOptions = name === FIELD_ATTRIBUTES.WRAP_TEXT;

  return {
    ...previousValues,
    [FIELDS_TO_UPDATE]: {
      ...previousValues?.[FIELDS_TO_UPDATE],
      [selectedFormFieldId]: {
        ...selectedFormField,
        ...previousFieldValues,
        ...(shouldForcedInitialValues ? initialValues : {}),
        ...(clearWrappingOptions ? DEFAULT_WRAPPING_OPTIONS : {}),
        [FIELD_ATTRIBUTES.DATASET_ID]: previousDatasetId,
        [FIELD_ATTRIBUTES.DATAITEM_ID]:
          name === FIELD_ATTRIBUTES.DATASET_ID ? null : previousDataItemId,
        [name]: value,
      },
    },
    [FIELDS_TO_UNMAP]: previousFieldsToUnmap
      ? previousFieldsToUnmap.filter(({ id }) => id !== selectedFormFieldId)
      : [],
  };
};

export default handleAttributeChangeFactory;
