import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { taxTypes } from '@common-packages/routes-definitions';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import { taxYearSelector, periodSelector } from '../../../shared/store/selectors';
import useTaxRateDetails from '../../../taxSummaries/taxRateDetails/useTaxRateDetails.hook';

import columnDefinitions from './taxRateDetails.columnDefinitions';

const TaxRateDetails = ({
  match,

  taxYear,
  period,
  orgId: entityId,
  jurisdictionId,
  isFetchingContext,
}) => {
  const { taxName } = match.params;
  const isContextReady = taxYear && taxName && entityId && jurisdictionId;
  const { taxRateDetails, isFetchingTaxRateDetails } = useTaxRateDetails({
    taxYear,
    period,
    taxType: taxTypes[taxName],
    entityId,
    jurisdictionId,
    isContextReady,
  });

  const isLoading = isFetchingContext || isFetchingTaxRateDetails;

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid isGridLoading={isLoading} columnDefs={columnDefinitions} rowData={taxRateDetails} />
      </div>
    </div>
  );
};
TaxRateDetails.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  taxType: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool,

  match: PropTypes.shape({
    params: PropTypes.shape({
      taxName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(state => ({
  taxYear: taxYearSelector(state),
  period: periodSelector(state),
}))(TaxRateDetails);
