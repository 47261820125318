import React, { useCallback } from 'react';
import ReactNewWindow from 'react-new-window';
import PropTypes from 'prop-types';

const PortalWindowPopUp = ({
  isWindowPortalOpen,
  pdfPreviewData,
  handlePreviewWatermarkClose,
  title,
}) => {
  const closePortal = useCallback(() => {
    handlePreviewWatermarkClose();
  }, [handlePreviewWatermarkClose]);

  return (
    isWindowPortalOpen && (
      <ReactNewWindow
        onUnload={closePortal}
        onBlock={closePortal}
        features={{ height: 1000, width: 750 }}
        center="parent"
        title={title}
      >
        <object
          data={`data:application/pdf;base64,${pdfPreviewData}`}
          title="previewPdfData"
          type="application/pdf"
          width="100%"
          height="100%"
        />
      </ReactNewWindow>
    )
  );
};

PortalWindowPopUp.propTypes = {
  title: PropTypes.string.isRequired,
  isWindowPortalOpen: PropTypes.bool.isRequired,
  handlePreviewWatermarkClose: PropTypes.func.isRequired,
  pdfPreviewData: PropTypes.string.isRequired,
};

export default PortalWindowPopUp;
