const Yup = require('yup');
const { INVALID_EMAIL_DEFAULT_MESSAGE } = require('@common-packages/shared-constants');

const updateUserSchema = Yup.object()
  .shape({
    roleId: Yup.string().label('Role').required(),
    firstName: Yup.string()
      .max(40, 'First Name cannot exceed 40 characters')
      .label('First name')
      .required(),
    lastName: Yup.string()
      .max(40, 'Last Name cannot exceed 40 characters')
      .label('Last name')
      .required(),
    email: Yup.string().label('Email').email(INVALID_EMAIL_DEFAULT_MESSAGE),
    activeInd: Yup.bool().required(),
  })
  .strict();

const createUserSchema = Yup.object()
  .shape({
    firstName: Yup.string()
      .max(40, 'First Name cannot exceed 40 characters')
      .label('First name')
      .required(),
    lastName: Yup.string()
      .max(40, 'Last Name cannot exceed 40 characters')
      .label('Last name')
      .required(),
    email: Yup.string().label('Email').required().email(INVALID_EMAIL_DEFAULT_MESSAGE).nullable(),
    activeInd: Yup.bool().required(),
    roleId: Yup.string().label('Role').required(),
  })
  .strict();

module.exports = { updateUserSchema, createUserSchema };
