import { Routes } from '@common-packages/routes-definitions';

import { LinkCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';
import { currencyColumnDefinition } from '../../shared/columnDefinitions';

const defaultColumnDefinition = {
  width: 160,
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
};

const AmountLinkRenderer = ({ accountId, setJurisdictionId, setAccountId }) =>
  LinkCellRendererFactory({
    getText: ({ valueFormatted }) => valueFormatted,
    getPathName: () => Routes.partnershipAmountDetails.MAIN,
    clickHandler: (_, { jurisdictionId }) => {
      setJurisdictionId(jurisdictionId);
      setAccountId(accountId);
    },
  });

const getColumnDefinitions = ({ dynamicColumns, setAccountId, setJurisdictionId }) => [
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
  },
  ...dynamicColumns.map(columnName => ({
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: columnName,
    field: columnName,
    cellRenderer: AmountLinkRenderer({
      accountId: columnName,
      setAccountId,
      setJurisdictionId,
    }),
  })),
];

export default getColumnDefinitions;
