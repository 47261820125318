import { httpGetAndParse, httpPost, httpPut } from '../../../utils/fetchService';

export const fetchDirectPartnershipInfo = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/partnership-info/direct/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching direct partnership info failed',
  });

export const fetchIndirectPartnershipInfo = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/partnership-info/indirect/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching indirect partnership info failed',
  });

export const fetchPartnershipInfoTypes = () =>
  httpGetAndParse({
    route: '/api/entities/partnership-info/partnership-types',
    errorMessage: 'Fetching partnership info types failed',
  });

export const fetchPartnerships = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/entities/partnership-info/partnership/${taxYear}/${period}`,
    errorMessage: 'Fetching partnerships failed',
  });

export const addPartnershipInfo = ({
  taxYear,
  period,
  ownerId,
  partnershipId,
  ownerPercentage,
  percentYearOwned,
  percentOwned,
  percentEffectiveOwnership,
  partnershipTypeCode,
}) =>
  httpPost({
    route: '/api/entities/partnership-info',
    errorMessage: 'Adding partnership information failed',
    body: {
      taxYear,
      period,
      ownerId,
      partnershipId,
      ownerPercentage,
      percentYearOwned,
      percentOwned,
      percentEffectiveOwnership,
      partnershipTypeCode,
    },
  });

export const updateOrDeletePartnershipsInfo = ({
  taxYear,
  period,
  ownerId,
  rowsToUpdate,
  rowsToDelete,
}) =>
  httpPut({
    route: `/api/entities/partnership-info/${taxYear}/${period}/${ownerId}`,
    errorMessage: 'Updating partnership information failed',
    body: { rowsToUpdate, rowsToDelete },
  });
