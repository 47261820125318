import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';

const FilingInformation = () => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Filing Groups Included in this Consolidation"
      icon="table-data"
      description="Filing groups"
      route={Routes.consolidationFilingGroups.MAIN}
    />
    <AuthorizedCard
      title="Filing Decisions"
      icon="table-data"
      description="Filing decisions"
      route="/" // TODO: CARD-REMOVE-DECISION Question #1 https://confluence.ist.pwc.com/display/SLTTechdebts/Questions
    />
    <AuthorizedCard
      title="Informational Data"
      icon="table-data"
      description="Informational data"
      route={Routes.informationalDataV2.MAIN}
    />
    <AuthorizedCard
      title="Federal Details - By Line Number and Entity"
      icon="table-data"
      description="Federal details"
      route={Routes.federalDetailsByLineNumberAndEntity.MAIN}
    />
  </div>
);

export default FilingInformation;
