import { ColDef } from 'ag-grid-community';

import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';

const columnDefinitions: ColDef[] = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Name',
    field: 'name',
    colId: 'name',
    width: 300,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Description',
    field: 'description',
    flex: 1,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Data Type',
    field: 'dataType',
  },
];

export default columnDefinitions;
