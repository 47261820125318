import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationInsertSelectionList = () =>
  useCustomMutation({
    handleData: ({ name, description, taxYear, jurisdictionId, items }) => ({
      url: '/api/development/selection-lists',
      method: HttpMethods.POST,
      body: { name, description, taxYear, jurisdictionId, items },
    }),
    resetKey: QueryKeys.SelectionLists.SelectionLists,
    successMessage: 'Selection list added successfully',
    errorMessage: 'An error occurred while adding a selection list',
  });

export const useMutationUpdateSelectionList = () =>
  useCustomMutation({
    handleData: ({ id, name, description, taxYear, jurisdictionId, items }) => ({
      url: `/api/development/selection-lists/${id}/${taxYear}/${jurisdictionId}`,
      method: HttpMethods.PUT,
      body: { name, description, items },
    }),
    resetKey: QueryKeys.SelectionLists.SelectionLists,
    successMessage: 'Selection list updated successfully',
    errorMessage: 'An error occurred while updating a selection list',
  });

export const useMutationDeleteSelectionList = () =>
  useCustomMutation({
    handleData: ({ selectionListId }) => ({
      url: `/api/development/selection-lists/${selectionListId}`,
      method: HttpMethods.DELETE,
    }),
    resetKey: QueryKeys.SelectionLists.SelectionLists,
    successMessage: 'Selection list deleted successfully',
    errorMessage: 'An error occurred while trying to delete a selection list',
  });
