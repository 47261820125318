import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { taxYearSelector } from '../../shared/store/selectors';
import {
  isFetchingConstantsSelector,
  constantsColumnsBlueprintSelector,
} from '../../shared/store/calculationConstants/selectors';
import { useBottomSlideInEditMode } from '../../shared/editMode';

import { jurisdictionIdSelector } from './store/selectors/shared';
import {
  taxRatesSelector,
  taxRatesColumnsBlueprintSelector,
  isFetchingTaxRatesSelector,
} from './store/selectors/taxRates';
import ConstantForm from './constants/constantFormWrapper.component';
import TaxRateForm from './taxRates/taxRateFormWrapper.component';
import { TabsTypes } from './enums';

const getRowId = params => params?.data?.gridRowId;

const useTaxRatesAndContantsBottomSlideInEditMode = ({ activeTab, saveTaxRate, saveConstant }) => {
  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const taxRates = useSelector(taxRatesSelector);
  const taxRatesColumnsBlueprint = useSelector(taxRatesColumnsBlueprintSelector);
  const constantsColumnsBlueprint = useSelector(constantsColumnsBlueprintSelector);
  const isFetchingTaxRates = useSelector(isFetchingTaxRatesSelector);
  const isFetchingConstants = useSelector(isFetchingConstantsSelector);

  const constantFormToRender = useCallback(
    ({ values, onSave }) => (
      <ConstantForm
        rowToEdit={values}
        columnsBlueprint={constantsColumnsBlueprint}
        onSave={onSave}
        taxYear={taxYear}
        jurisdictionId={jurisdictionId}
      />
    ),
    [constantsColumnsBlueprint, jurisdictionId, taxYear],
  );

  const taxRateFormToRender = useCallback(
    ({ values, onSave }) => (
      <TaxRateForm
        rowToEdit={values}
        columnsBlueprint={taxRatesColumnsBlueprint}
        onSave={onSave}
        taxRates={taxRates}
        jurisdictionId={jurisdictionId}
      />
    ),
    [taxRatesColumnsBlueprint, taxRates, jurisdictionId],
  );

  const bottomSlideInParams = useMemo(
    () =>
      activeTab === TabsTypes.TAX_RATES
        ? {
            getRowId,
            onSave: saveTaxRate,
            formToRender: taxRateFormToRender,
            renderBottomSlideIn: !isFetchingTaxRates,
            isCompactIconSticky: true,
          }
        : {
            getRowId,
            onSave: saveConstant,
            formToRender: constantFormToRender,
            renderBottomSlideIn: !isFetchingConstants,
            isCompactIconSticky: true,
          },
    [
      saveConstant,
      saveTaxRate,
      activeTab,
      constantFormToRender,
      isFetchingConstants,
      isFetchingTaxRates,
      taxRateFormToRender,
    ],
  );

  const { agGridProps, bottomSlideInWithForm, onAddClick } = useBottomSlideInEditMode(
    bottomSlideInParams,
  );

  return { agGridProps, bottomSlideInWithForm, onAddClick };
};

export default useTaxRatesAndContantsBottomSlideInEditMode;
