import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import { fetchStateAccountNumbersTypes, updateStateAccountNumbersTypes } from './types';

const stateAccountNumbersReducer = reducerFactory({
  initialState: {
    stateAccountNumbersData: null,
    isFetchingStateAccountNumbersData: false,
    isUpdatingStateAccountNumbers: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchStateAccountNumbersTypes,
      payloadKey: 'stateAccountNumbersData',
      fetchingKey: 'isFetchingStateAccountNumbersData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateStateAccountNumbersTypes,
      fetchingKey: 'isUpdatingStateAccountNumbers',
    }),
  },
});

export default stateAccountNumbersReducer;
