import React, { useCallback, useState } from 'react';
import { Input } from '@pwc/appkit-react/lib/Input';
import { Checkbox } from '@pwc/appkit-react';

import Loading from '../../../../../shared/displayComponents/loading.component';
import signatureValidation from '../signatureValidation';

import styles from './signatureDeclaration.module.scss';

interface SignatureDeclarationProps {
  signatureDeclaration: string | null | undefined;
  acceptedLegalVerbiage: boolean;
  toggleAcceptedLegalVerbiage: (params?: unknown) => unknown;
  signatureValue: string;
  onSignatureChange: React.Dispatch<React.SetStateAction<string>>;
  isGeneratingSignatureDeclaration: boolean;
  isSignatureDeclarationGeneratedSuccessfully: boolean;
}

const SignatureDeclaration = ({
  signatureDeclaration,
  isGeneratingSignatureDeclaration,
  isSignatureDeclarationGeneratedSuccessfully,

  acceptedLegalVerbiage,
  toggleAcceptedLegalVerbiage,

  signatureValue,
  onSignatureChange,
}: SignatureDeclarationProps) => {
  const [isSignatureValid, setIsSignatureValid] = useState(true);

  const handleSignatureChange = useCallback(
    (signature: string) => {
      setIsSignatureValid(signatureValidation(signature));
      onSignatureChange(signature);
    },
    [onSignatureChange, setIsSignatureValid],
  );

  return (
    <div className={styles.signatureDeclarationContainer}>
      <Loading isLoading={isGeneratingSignatureDeclaration}>
        {isSignatureDeclarationGeneratedSuccessfully && signatureDeclaration && (
          <>
            <div className={styles.signatureDeclarationTextContainer}>
              <div className={styles.signatureDeclarationText}>
                <span
                  dangerouslySetInnerHTML={{
                    // setting inner html because signatureDeclaration may contain &nsbp; chars
                    __html: signatureDeclaration,
                  }}
                />
              </div>
            </div>
            <Checkbox checked={acceptedLegalVerbiage} onChange={toggleAcceptedLegalVerbiage}>
              By checking this box I confirm that I have read and agree to the above statement
            </Checkbox>
            <div>
              <label className="a-form-label">AUTHORIZER NAME</label>
              <Input
                value={signatureValue}
                onChange={handleSignatureChange}
                hasError={!isSignatureValid}
                errMsg="Signature must contain only letters and spaces"
              />
            </div>
          </>
        )}
      </Loading>
    </div>
  );
};

export default SignatureDeclaration;
