import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectOption } from '@tls/slt-types';
import { Link } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import { filingTypes } from '../shared/constants';
import { setGlobalContext } from '../shared/store/actions';
import { useQueryGlobalMemberInfoData } from '../shared/queries/globalMemberInformationalData';
import { useRowEditMode } from '../shared/editMode';
import { useMutationUpdateGlobalMemberInformationalData } from '../shared/mutations/globalMemberInformationalData';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../shared/columnDefinitions/cellRenderers';
import {
  useQueryMemberInfoDataForms,
  useQueryMembers,
} from '../shared/queries/memberInformationalData';
import { globalContextSelector } from '../shared/store/selectors';
import { GlobalContext, GlobalMemberInfoData } from '../../../common/types';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';

import styles from './globalMemberInformationalData.module.scss';
import getColumnDefinitions from './globalMemberInformationalData.columnDefinitions';

const getUniqueRowId = ({ data: { infoAccountId } }: { data: { infoAccountId: string } }) =>
  infoAccountId;

interface GlobalMemberInfoUpdates {
  rowsPairsWithChanges: [{ oldRow: GlobalMemberInfoData; newRow: GlobalMemberInfoData }];
}

const GlobalMemberInformationalData = ({
  hasUserPermissionsToEdit,
}: {
  hasUserPermissionsToEdit: boolean;
}) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const dispatch = useDispatch();

  const {
    isReady,
    consolidationId,
    params: { businessEntityId, taxYear, period, jurisdictionId },
  } = globalContext;

  const [selectedFormId, selectForm] = useState<string | null>(null);
  const [selectedMember, selectMember] = useState<string | null>(null);
  const onChangeForm = useCallback(({ value }: { value: string | null }) => selectForm(value), []);
  const onChangeMember = useCallback(
    ({ value }: { value: string | null }) => selectMember(value),
    [],
  );

  const { data: forms, isFetching: isFetchingForms } = useQueryMemberInfoDataForms({
    params: { globalContext },
    enabled: isReady,
    onSuccess: (forms: SelectOption[]) => {
      if (forms?.length) {
        selectForm(
          forms.some(form => form.value === selectedFormId) ? selectedFormId : forms[0].value,
        );
      }
    },
  });

  const { data: members, isFetching: isFetchingMembers } = useQueryMembers({
    params: { period, businessEntityId, formId: selectedFormId },
    enabled: Boolean(period && businessEntityId && selectedFormId),
    onSuccess: (members: SelectOption[]) => {
      if (members?.length) {
        selectMember(
          members.some(member => member.value === selectedMember)
            ? selectedMember
            : members[0].value,
        );
      }
    },
  });

  const { data, isFetching: isFetchingMemberInfoData } = useQueryGlobalMemberInfoData({
    enabled: Boolean(period && selectedMember && selectedFormId),
    params: {
      period,
      businessEntityId: selectedMember,
      formId: selectedFormId,
      taxYear,
    },
  });

  const {
    mutateAsync: updateUpdateGlobalmMemberInformationalData,
    isLoading: isUpdating,
  } = useMutationUpdateGlobalMemberInformationalData();

  const isLoading = isFetchingForms || isFetchingMembers || isFetchingMemberInfoData || isUpdating;

  const saveChanges = useCallback(
    async (updates: GlobalMemberInfoUpdates) => {
      const values = updates.rowsPairsWithChanges.map(
        (changes: { newRow: GlobalMemberInfoData }) => changes.newRow,
      );

      await updateUpdateGlobalmMemberInformationalData({
        taxYear,
        period,
        consolidationId,
        values,
        jurisdictionId,
      });
    },
    [updateUpdateGlobalmMemberInformationalData, taxYear, consolidationId, period, jurisdictionId],
  );

  const {
    isInEditMode,
    navigationPrompt,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: data,
    getUniqueRowId,
    editButtonDisabled: isLoading,
    saveButtonDisabled: false,
  });

  const columnDefinitions = useMemo(() => getColumnDefinitions({ isInEditMode, updateRow }), [
    isInEditMode,
    updateRow,
  ]);

  const setContext = useCallback(
    () =>
      setGlobalContext({
        taxYear,
        period,
        filingTypeId: filingTypes.SEPARATE,
        entity: selectedMember,
        consolidationId: null,
        jurisdictionId: null,
        dispatch,
        globalContext,
      }),
    [dispatch, globalContext, taxYear, period, selectedMember],
  );

  return (
    <>
      {navigationPrompt}
      <div className={styles.flexSpaceBetween}>
        <div className={styles.formListDropdown}>
          <SDKCustomSelect
            className="sdk-custom-select"
            appkitLabel="Form Name"
            options={forms}
            onChange={onChangeForm}
            value={selectedFormId}
            isLoading={isFetchingForms}
            disabled={isInEditMode}
          />
        </div>
        <div className={styles.memberListDropdown}>
          <SDKCustomSelect
            className="sdk-custom-select"
            appkitLabel="Member"
            options={members}
            onChange={onChangeMember}
            value={selectedMember}
            isLoading={isFetchingMembers}
            disabled={isInEditMode}
          />
        </div>
        <div>{hasUserPermissionsToEdit && editModeButtons}</div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <div className={styles.tableHeaderText}>
            Changes saved here are applied as an override.
            <Link
              className={isLoading ? styles.disableLink : ''}
              to={{ pathname: Routes.entityInformation.compiledRoute(globalContext.params) }}
              onClick={setContext}
            >
              <span> Click here </span>
            </Link>
            to edit global value after selecting a member.
          </div>
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            suppressCellSelection={!isInEditMode}
            onGridReady={onGridReady}
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            withSearchBar
            stopEditingWhenCellsLoseFocus
            singleClickEdit
          />
        </div>
      </div>
    </>
  );
};

export default GlobalMemberInformationalData;
