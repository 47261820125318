import {
  BusinessEntityId,
  JurisdictionId,
  TaxYear,
  Period,
  FindDataModels,
  FindDatasetDefinitions,
  DatasetInstancesResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

type DataModelsParams = {
  taxYear: TaxYear | null;
  period: Period | null;
  businessEntityId: BusinessEntityId | null;
  jurisdictionId: JurisdictionId | null;
};

type DatasetDefinitionsParams = {
  taxYear: TaxYear | null;
  period: Period | null;
  businessEntityId: BusinessEntityId | null;
  jurisdictionId: JurisdictionId | null;
  dataModelId?: string | null;
};

type DatasetInstancesParams = {
  datasetDefinitionId: string | null;
  period: Period | null;
  businessEntityId: BusinessEntityId | null;
};

export const useQueryDataModels = ({
  params: { taxYear, period, jurisdictionId, businessEntityId },
  enabled,
}: {
  params: DataModelsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindDataModels>(
    [
      QueryKeys.InstanceDataCleanup.DataModels,
      { taxYear, period, jurisdictionId, businessEntityId },
    ],
    {
      url: `/api/admin/support/instance-data-cleanup/data-models-has-dataiteminstance/${taxYear}/${period}/${jurisdictionId}/${businessEntityId}`,
      defaultData: [],
      enabled,
      errorMessage: 'Fetching data models error',
    },
  );

export const useQueryDatasetDefinitions = ({
  params: { taxYear, period, jurisdictionId, businessEntityId, dataModelId },
  enabled,
}: {
  params: DatasetDefinitionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindDatasetDefinitions>(
    [
      QueryKeys.InstanceDataCleanup.DatasetDefinitions,
      { taxYear, period, jurisdictionId, businessEntityId, dataModelId },
    ],
    {
      url: `/api/admin/support/instance-data-cleanup/dataset-definitions-with-dataset-instances/${taxYear}/${period}/${jurisdictionId}/${businessEntityId}/${dataModelId}`,
      defaultData: [],
      enabled,
      errorMessage: 'Fetching dataset definitions error',
    },
  );

export const useQueryDatasetInstances = ({
  params: { datasetDefinitionId, period, businessEntityId },
  enabled,
}: {
  params: DatasetInstancesParams;
  enabled: boolean;
}) =>
  useCustomQuery<DatasetInstancesResponse>(
    [
      QueryKeys.InstanceDataCleanup.DatasetInstances,
      { datasetDefinitionId, period, businessEntityId },
    ],
    {
      url: `/api/admin/support/instance-data-cleanup/dataset-instances/${datasetDefinitionId}/${period}/${businessEntityId}`,
      defaultData: { data: [], columns: [] },
      enabled,
      errorMessage: 'Fetching dataset instances error',
    },
  );
