import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';

import CellTooltip from './cellTooltip.component';

interface TextCellRendererFactoryParams {
  getText: (params: GetTextParams) => unknown;
}

type ICellRendererParamsWithValidators = ICellRendererParams & {
  validators?: {
    [key: string]: (
      value: ICellRendererParams['value'],
      data: ICellRendererParams['data'],
    ) => string | null;
  };
};

interface GetTextParams {
  value: ICellRendererParams['value'];
  valueFormatted: ICellRendererParams['valueFormatted'];
  data: ICellRendererParams['data'];
}

const TextCellRendererFactory = ({ getText = () => null }: TextCellRendererFactoryParams) => {
  const TextRenderer = ({
    value,
    data,
    valueFormatted,
    colDef,
    validators = {},
  }: ICellRendererParamsWithValidators) => {
    const textToRender = getText({ value, valueFormatted, data });

    const validate = colDef?.field ? validators[colDef.field] : null;

    const tooltipMessage = validate ? validate(value, data) : '';

    if (textToRender || tooltipMessage) {
      return (
        <div>
          <span>
            <>
              {textToRender}
              <CellTooltip message={tooltipMessage} isCellEmpty={!textToRender} />
            </>
          </span>
        </div>
      );
    }

    return null;
  };

  return TextRenderer;
};

export default TextCellRendererFactory;
