import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { errorNotification } from '../../shared/notification/store/actions';

import { useCellEditorSelect } from './useCellEditorSelect';

// eslint-disable-next-line no-empty-function
const noop = () => {};

export const useCellEditorSelectWithFetch = ({
  handleOptionsFetching = noop,
  stopEditing = noop,
  defaultErrorMessage,
  ref,
  onCellValueChanged,
  api,
  colDef,
  value,
  data,
  options,
  mutateData = false,
  mapOptions = true,
}) => {
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState(null);

  const { setSelectedValue, inputRef } = useCellEditorSelect({
    ref,
    onCellValueChanged,
    api,
    colDef,
    stopEditing,
    value,
    data,
    mutateData,
  });

  const fetchOptions = useCallback(async () => {
    if (options || isFetching || fetchedOptions) {
      return;
    }

    try {
      setIsFetching(true);

      const result = await handleOptionsFetching();
      const mappedResult = mapOptions
        ? result.map(({ name }) => ({ value: name, label: name }))
        : result;

      setFetchedOptions(mappedResult);
    } catch (error) {
      dispatch(
        errorNotification(error?.message || defaultErrorMessage || 'Fetching select options error'),
      );
      stopEditing();
    } finally {
      setIsFetching(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [
    dispatch,
    setIsFetching,
    setFetchedOptions,
    stopEditing,
    handleOptionsFetching,
    defaultErrorMessage,
    isFetching,
    fetchedOptions,
    options,
    mapOptions,
    inputRef,
  ]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return {
    setSelectedValue,
    setIsFetching,
    fetchOptions,
    isFetching,
    fetchedOptions,
    inputRef,
  };
};
