import React, { useReducer, useCallback } from 'react';
import { useSelector } from 'react-redux';
import _uniqBy from 'lodash.uniqby';

import { GetNotificationsResponse, Notification } from '../../../common/types';
import { useQueryNotifications } from '../shared/queries/notifications';
import useBooleanState from '../shared/hooks/useBooleanState.hook';
import { useMutationRemoveNotifications } from '../shared/mutations/notifications';
import { areAnyClientsAvailableSelector } from '../shared/store/selectors';
import useServerSentEventSubscription from '../utils/useServerSentEventSubscription';
import { Events } from '../utils/sseService';
import config from '../config';

import NotificationIcon from './notificationIcon.component';
import NotificationPanel from './notificationPanel.component';

const SUBSCRIBE_URL = '/api/notifications/subscribe';

interface NotificationsReducerStore {
  notifications: Notification[];
}

interface NotificationsProps {
  onClick?: () => void;
}

enum NotificationsActionType {
  ADD = 'NOTIFICATION_ADD',
  REMOVE = 'NOTIFICATION_REMOVE',
}

interface NotificationsActionParams {
  type: NotificationsActionType;
  payload: {
    notifications?: Notification | Notification[];
    ids?: string[];
  };
}

const sortByCreatedDate = (a: Notification, b: Notification) =>
  a.createdAt < b.createdAt ? 1 : -1;

const addNotificationReducer = (
  store: NotificationsReducerStore,
  action: NotificationsActionParams,
): NotificationsReducerStore => {
  switch (action.type) {
    case NotificationsActionType.ADD:
      if (action.payload.notifications) {
        const newNotifications = Array.isArray(action.payload.notifications)
          ? action.payload.notifications
          : [action.payload.notifications];

        return {
          ...store,
          notifications: _uniqBy([...store.notifications, ...newNotifications], 'id').sort(
            sortByCreatedDate,
          ),
        };
      }

      return store;
    case NotificationsActionType.REMOVE:
      return {
        ...store,
        notifications: store.notifications.filter(({ id }) => !action.payload.ids?.includes(id)),
      };
    default:
      return store;
  }
};

const Notifications = ({ onClick }: NotificationsProps) => {
  const [store, dispatch] = useReducer(addNotificationReducer, {
    notifications: [],
  });
  const areAnyClientsAvailable = useSelector(areAnyClientsAvailableSelector);
  const addNotification = useCallback(
    (notifications: Notification | Notification[]) => {
      dispatch({ type: NotificationsActionType.ADD, payload: { notifications } });
    },
    [dispatch],
  );
  const removeNotification = useCallback(
    (ids: string[]) => {
      dispatch({ type: NotificationsActionType.REMOVE, payload: { ids } });
    },
    [dispatch],
  );
  const { subscribe } = useServerSentEventSubscription(
    SUBSCRIBE_URL,
    addNotification,
    Events.NOTIFICATIONS,
  );

  useQueryNotifications({
    enabled: areAnyClientsAvailable,
    refetchInterval: config.USE_SERVER_SIDE_EVENTS ? false : config.NOTIFICATIONS_POLLING_INTERVAL,
    onSuccess: (newNotifications: GetNotificationsResponse) => {
      addNotification(newNotifications);

      if (config.USE_SERVER_SIDE_EVENTS) {
        subscribe();
      }
    },
  });

  const [
    isNotificationPanelVisible,
    showNotificationPanel,
    hideNotificationPanel,
  ] = useBooleanState(false);

  const onNotificationIconClick = useCallback(() => {
    onClick?.();
    showNotificationPanel();
  }, [onClick, showNotificationPanel]);

  const { mutateAsync: clearNotifications } = useMutationRemoveNotifications();

  const clearNotification = useCallback(
    async (id: string) => {
      removeNotification([id]);
      await clearNotifications({ notifications: [id] });
    },
    [clearNotifications, removeNotification],
  );

  const clearAllNotifications = useCallback(async () => {
    const ids = store.notifications.map(({ id }) => id);

    removeNotification(ids);
    await clearNotifications({ notifications: ids });
  }, [clearNotifications, removeNotification, store]);

  return (
    <>
      <NotificationIcon
        count={store.notifications.length}
        isActive={isNotificationPanelVisible}
        onClick={onNotificationIconClick}
      />
      {isNotificationPanelVisible && (
        <NotificationPanel
          notifications={store.notifications}
          onHide={hideNotificationPanel}
          onClear={clearNotification}
          onClearAll={clearAllNotifications}
        />
      )}
    </>
  );
};

export default Notifications;
