import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';

import { fetchJobQueueStatusReportsTypes } from './types';

export const initialState = {
  jobQueueStatusReports: [],
  isFetchingJobQueueStatusReports: false,
};

const jobQueueStatusReportsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchJobQueueStatusReportsTypes,
      payloadKey: 'jobQueueStatusReports',
      fetchingKey: 'isFetchingJobQueueStatusReports',
    }),
  },
});

export default jobQueueStatusReportsReducer;
