import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { useQueryReturnDefinitions } from '../../shared/queries/returnDefinitions';
import { jurisdictionIdSelector, taxYearSelector } from '../store/selectors';
import columnBlueprintStyles from '../../shared/styles/columnBlueprintStyles.module.scss';
import useBooleanState from '../../shared/hooks/useBooleanState.hook';
import { ReturnDefinition } from '../../../../common/types/apiShapes';

import getColumnDefinitions from './returnDefinitions.columnDefinitions';
import AddEditReturnDefinitionPanel from './addEditReturnDefinitionPanel/addEditReturnDefinitionPanel.container';

const ReturnDefinitions = () => {
  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);

  const [definitionToEdit, setDefitnitionToEdit] = useState<ReturnDefinition | null>(null);
  const [isPanelVisible, showPanel, hidePanel] = useBooleanState(false);

  const { data: returnDefinitions, isFetching } = useQueryReturnDefinitions({
    params: { taxYear, jurisdictionId },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const handleEditIconClick = useCallback(
    (definition: ReturnDefinition) => {
      setDefitnitionToEdit(definition);
      showPanel();
    },
    [showPanel],
  );

  const handleAddReturnDefitnion = useCallback(() => {
    setDefitnitionToEdit(null);
    showPanel();
  }, [showPanel]);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions(
        returnDefinitions?.filingAttributesColumnsBlueprint || [],
        handleEditIconClick,
      ),
    [handleEditIconClick, returnDefinitions?.filingAttributesColumnsBlueprint],
  );

  const renderAdditionalHeaderElements = useCallback(
    () => (
      <Button size="lg" kind="secondary" onClick={handleAddReturnDefitnion}>
        Add Return Definition
      </Button>
    ),
    [handleAddReturnDefitnion],
  );

  return (
    <>
      <HeaderWithParamDropdownsWrapper
        renderAdditionalElements={renderAdditionalHeaderElements}
        showHeaderTaxYearDropdown
        showHeaderJurisdictionDropdown
      />
      <AgGrid
        className={columnBlueprintStyles.gridContainer}
        rowData={returnDefinitions?.data}
        columnDefs={columnDefinitions}
        isGridLoading={isFetching}
        withSearchBar
        areHeaderCellBordersEnabled
        autoMaxWidth
        sideBar={defaultSideBarWithColumnsToolPanel}
        headerHeight={50}
        groupHeaderHeight={30}
      />
      <AddEditReturnDefinitionPanel
        isPanelVisible={isPanelVisible}
        hidePanel={hidePanel}
        definitionToEdit={definitionToEdit}
        returnDefinitionsForContext={returnDefinitions?.data || []}
      />
    </>
  );
};

export default ReturnDefinitions;
