import {
  adminColumnDefinition,
  defaultColumnDefinitionWithFilter,
} from '../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onDeleteIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: false,
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Name',
      field: 'name',
      flex: 1,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: 'description',
      flex: 2,
    },
  ];
};

export default getColumnDefinitions;
