import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ParamDropdown from '../../../../../shared/displayComponents/paramDropdown/paramDropdown.component';
import { constantTypeSelector } from '../../../../../shared/store/calculationConstants/selectors';
import { selectConstantType } from '../../../../../shared/store/calculationConstants/actions';
import { CONSTANT_TYPE_DROPDOWN_OPTIONS } from '../constants';

const ConstantTypeDropdown = ({ isDropdownBusy = false }) => {
  const dispatch = useDispatch();

  const constantType = useSelector(constantTypeSelector);

  const handleSelectConstantType = useCallback(
    newConstantType => {
      if (newConstantType !== constantType) {
        dispatch(selectConstantType(newConstantType));
      }
    },
    [dispatch, constantType],
  );

  return (
    <ParamDropdown
      label="Constant Type"
      value={constantType}
      options={CONSTANT_TYPE_DROPDOWN_OPTIONS}
      handleChange={handleSelectConstantType}
      isBusy={isDropdownBusy}
    />
  );
};

ConstantTypeDropdown.propTypes = {
  isDropdownBusy: PropTypes.bool,
};

export default ConstantTypeDropdown;
