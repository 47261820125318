import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const defaultFilingPositionsColumnDefinition = {
  ...defaultColumnDefinition,
  suppressMenu: false,
};

const columnDefinitions = [
  {
    ...defaultFilingPositionsColumnDefinition,
    headerName: 'Profile',
    field: 'profileId',
    hide: true,
  },
  {
    ...defaultFilingPositionsColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionId',
    width: 100,
    hide: true,
  },
  {
    ...defaultFilingPositionsColumnDefinition,
    headerName: 'Category',
    field: 'categoryId',
    width: 200,
  },
  {
    ...defaultFilingPositionsColumnDefinition,
    headerName: 'Account',
    field: 'accountId',
  },
  {
    ...defaultFilingPositionsColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 450,
  },
  {
    ...defaultFilingPositionsColumnDefinition,
    headerName: 'Account Jurisdiction',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultFilingPositionsColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Mult',
    field: 'multiplier',
    width: 60,
  },
];

export default columnDefinitions;
