import {
  GlobalConsolidationData,
  Period,
  BusinessEntityId,
  TaxYear,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export interface FindGlobalMemberInfoDataParams {
  period: Period | null;
  businessEntityId?: BusinessEntityId | null;
  formId: string | null;
  taxYear: TaxYear | null;
}

export const useQueryGlobalMemberInfoData = ({
  params,
  enabled,
}: {
  enabled: boolean;
  params: FindGlobalMemberInfoDataParams;
}) =>
  useCustomQuery<GlobalConsolidationData[]>(
    [QueryKeys.GlobalMemberInformationalData.Fields, params],
    {
      url: `/api/shared/global-member-informational-data/${params.taxYear}/${params.period}/${params.businessEntityId}/${params.formId}`,
      enabled,
      defaultData: [],
      errorMessage: 'Error fetching global member informational data',
    },
  );
