import { apiAction } from '../../../../shared/store/apiAction';
import * as api from '../api';

import {
  fetchCustomReportsTypes,
  fetchCustomReportTypes,
  runCustomReportTypes,
  CLEAN_AFTER_CUSTOM_REPORT,
  CLEAN_AFTER_RUN_CUSTOM_REPORT,
} from './types';

export const fetchCustomReports = apiAction({
  types: fetchCustomReportsTypes,
  apiAction: api.fetchCustomReports,
});

export const fetchCustomReport = apiAction({
  types: fetchCustomReportTypes,
  apiAction: ({ id }) => api.fetchCustomReport({ id }),
});

export const runCustomReport = apiAction({
  types: runCustomReportTypes,
  apiAction: ({ reportId, params }) => api.runCustomReport({ reportId, params }),
});

export const cleanAfterCustomReport = () => ({
  type: CLEAN_AFTER_CUSTOM_REPORT,
});

export const cleanAfterRunCustomReport = () => ({
  type: CLEAN_AFTER_RUN_CUSTOM_REPORT,
});
