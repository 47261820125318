import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../../shared/hooks/useModal.hook';
import { useRowEditMode } from '../../shared/editMode';
import {
  entityIdSelector,
  entityNameSelector,
  periodSelector,
  taxYearSelector,
} from '../../shared/store/selectors';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';

import getColumnDefinitions from './partnershipAllocationPercentages.columnDefinitions';
import {
  fetchPartnershipPercentageTypes,
  fetchPartnershipAllocationPercentages,
  updatePartnershipAllocationPercentages,
  deletePartnershipAllocation,
  fetchPartners,
  updateOwnership,
} from './store/actions';
import {
  partnershipAllocationPercentagesSelector,
  isFetchingPartnershipAllocationPercentagesSelector,
  isDeletingPartnershipAllocationSelector,
  isUpdatingPartnershipAllocationPercentagesSelector,
  isFetchingPercentageTypesSelector,
  percentageTypesOptionsSelector,
  partnersOptionsSelector,
  isFetchingPartnersSelector,
  isUpdatingOwnershipSelector,
} from './store/selectors';
import AddOwnerModal from './addOwnerModal.component';
import { YES } from './constants';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const PartnershipAllocationPercentages = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const entityId = useSelector(entityIdSelector);
  const entityName = useSelector(entityNameSelector);
  const partnershipAllocationPercentages = useSelector(partnershipAllocationPercentagesSelector);
  const isFetchingPartnershipAllocationPercentages = useSelector(
    isFetchingPartnershipAllocationPercentagesSelector,
  );
  const isDeletingPartnershipAllocation = useSelector(isDeletingPartnershipAllocationSelector);
  const isUpdatingPartnershipAllocationPercentages = useSelector(
    isUpdatingPartnershipAllocationPercentagesSelector,
  );
  const isFetchingPercentageTypes = useSelector(isFetchingPercentageTypesSelector);
  const percentageTypesOptions = useSelector(percentageTypesOptionsSelector);
  const partnersOptions = useSelector(partnersOptionsSelector);
  const isFetchingPartners = useSelector(isFetchingPartnersSelector);
  const isUpdatingOwnership = useSelector(isUpdatingOwnershipSelector);

  const isContextReady = taxYear && period && entityId;
  const isLoading =
    isFetchingPartnershipAllocationPercentages ||
    isDeletingPartnershipAllocation ||
    isUpdatingPartnershipAllocationPercentages;

  const [isMultiplePlugOwners, setIsMultiplePlugOwners] = useState(false);

  const refetchPartnershipAllocationPercentages = useCallback(
    () =>
      dispatch(
        fetchPartnershipAllocationPercentages({
          taxYear,
          period,
          entityId,
        }),
      ),
    [dispatch, taxYear, period, entityId],
  );

  useEffect(() => {
    if (isContextReady) {
      refetchPartnershipAllocationPercentages();
    }
  }, [refetchPartnershipAllocationPercentages, isContextReady]);

  useEffect(() => {
    if (isContextReady) {
      dispatch(fetchPartners({ taxYear, period, entityId }));
    }
  }, [dispatch, taxYear, period, entityId, isContextReady]);

  useEffect(() => {
    if (taxYear && period) {
      dispatch(fetchPartnershipPercentageTypes({ taxYear, period }));
    }
  }, [dispatch, taxYear, period]);

  const handleUpdateOwnership = useCallback(
    async values => {
      await dispatch(updateOwnership(values));
      refetchPartnershipAllocationPercentages();
    },
    [dispatch, refetchPartnershipAllocationPercentages],
  );

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      if (!rowsPairsWithChanges.length) {
        return;
      }
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        taxYear,
        period,
        entityId,
        partnerId: oldRow.partnerId,
        percentageType: oldRow.percentageType,
        ownershipPercentage: newRow.ownershipPercentage,
        plugOwner: newRow.plugOwner,
      }));

      await dispatch(
        updatePartnershipAllocationPercentages({
          taxYear,
          period,
          entityId,
          rowsToUpdate,
        }),
      );
      refetchPartnershipAllocationPercentages();
    },
    [dispatch, refetchPartnershipAllocationPercentages, taxYear, period, entityId],
  );

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: partnershipAllocationPercentages,
    getUniqueRowId,
    editButtonDisabled: isLoading,
    saveButtonDisabled: isMultiplePlugOwners,
  });

  const onRowDataUpdated = useCallback(() => {
    const partnersWithPlugOwnership = clonedRowData
      ?.filter(({ plugOwner }) => plugOwner === YES)
      .map(({ partnerId }) => partnerId);

    if (partnersWithPlugOwnership?.length) {
      const distinctPlugOwners = new Set(partnersWithPlugOwnership);
      setIsMultiplePlugOwners(distinctPlugOwners.size > 1);
    }
  }, [clonedRowData]);

  const openModalForDelete = useCallback(
    row => {
      dispatch(
        showConfirmModal({
          title: 'Delete partnership allocation',
          text: 'Are you sure you want to delete this partnership allocation?',
          confirmCallback: async () => {
            await dispatch(
              deletePartnershipAllocation({
                taxYear,
                period,
                entityId,
                partnerId: row.partnerId,
                percentageType: row.percentageType,
              }),
            );
            refetchPartnershipAllocationPercentages();
          },
        }),
      );
    },
    [dispatch, refetchPartnershipAllocationPercentages, taxYear, period, entityId],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        updateRow,
        onDeleteIconClick: openModalForDelete,
        partnershipAllocationPercentages,
      }),
    [openModalForDelete, updateRow, isInEditMode, partnershipAllocationPercentages],
  );

  const { showModal: showOwnerModal, modalProps: addOwnerModalProps } = useModal();

  return (
    <>
      {navigationPrompt}
      {hasUserPermissionsToEdit && (
        <div className="add-button-column">
          {editModeButtons}
          <Button disabled={isInEditMode} size="lg" className="add-button" onClick={showOwnerModal}>
            Add Owner
          </Button>
        </div>
      )}
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            onGridReady={onGridReady}
            suppressCellFocus={!isInEditMode}
            onRowDataUpdated={onRowDataUpdated}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            withSearchBar
          />
        </div>
      </div>
      <AddOwnerModal
        {...addOwnerModalProps}
        updateOwnership={handleUpdateOwnership}
        isUpdatingOwnership={isUpdatingOwnership}
        saveCallback={refetchPartnershipAllocationPercentages}
        partnershipAllocationPercentages={partnershipAllocationPercentages}
        taxYear={taxYear}
        period={period}
        entityId={entityId}
        entityName={entityName}
        isFetchingPercentageTypes={isFetchingPercentageTypes}
        percentageTypesOptions={percentageTypesOptions}
        isFetchingPartners={isFetchingPartners}
        partnersOptions={partnersOptions}
      />
    </>
  );
};

PartnershipAllocationPercentages.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default PartnershipAllocationPercentages;
