import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import VirtualizedSelect from '../../../shared/forms/virtualizedSelect/virtualizedSelect.component';
import { useCellEditorSelectWithFetch } from '../../hooks/useCellEditorSelectWithFetch';

// eslint-disable-next-line no-empty-function
const noop = () => {};

const SelectWithFetchCellEditor = forwardRef(
  ({ value, stopEditing = noop, fetchOptions, data, defaultErrorMessage }, ref) => {
    const handleOptionsFetching = useCallback(() => fetchOptions(data), [fetchOptions, data]);

    const { setSelectedValue, fetchedOptions, isFetching, inputRef } = useCellEditorSelectWithFetch(
      {
        handleOptionsFetching,
        stopEditing,
        defaultErrorMessage,
        ref,
        value,
        data,
        mutateData: false,
        mapOptions: false,
      },
    );

    return (
      <VirtualizedSelect
        initialSelectedValue={value}
        options={fetchedOptions || []}
        setSelectedValue={setSelectedValue}
        isLoading={isFetching}
        inputRef={inputRef}
      />
    );
  },
);

SelectWithFetchCellEditor.displayName = 'SelectWithFetchCellEditor';

SelectWithFetchCellEditor.propTypes = {
  value: PropTypes.string,
  fetchOptions: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  stopEditing: PropTypes.func.isRequired,
  defaultErrorMessage: PropTypes.string,
};

export default SelectWithFetchCellEditor;
