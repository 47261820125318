import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { taxYearSelector, periodSelector } from '../../shared/store/selectors';

import useReportParam from './useReportParam.hook';

export const createUseTaxYearAndPeriodParams = useReportParam => ({
  endpointBasePath,
  defaultTaxYear = undefined, // eslint-disable-line no-undefined
  defaultPeriod = undefined, // eslint-disable-line no-undefined
}) => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const taxYearParam = useReportParam({
    endpointBasePath,
    paramName: 'taxYear',
    params: useMemo(() => ({}), []),
    defaultParamValue: typeof defaultTaxYear !== 'undefined' ? defaultTaxYear : taxYear,
  });

  const periodParam = useReportParam({
    endpointBasePath,
    paramName: 'period',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
      }),
      [taxYearParam.paramValue],
    ),
    defaultParamValue: typeof defaultPeriod !== 'undefined' ? defaultPeriod : Number(period),
  });

  return { taxYearParam, periodParam };
};

export default createUseTaxYearAndPeriodParams(useReportParam);
