import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import Loading from '../../shared/displayComponents/loading.component';
import { entityIdSelector, taxYearSelector, periodSelector } from '../../shared/store/selectors';

import {
  isFetchingAccountGroupsSelector,
  accountGroupsOptionsSelector,
  isFetchingAccountsSelector,
  selectedAccountGroupSelector,
  selectedAccountSelector,
  accountsOptionsSelector,
} from './store/selectors';
import {
  fetchAccounts,
  fetchAccountGroups,
  fetchAccountData,
  selectAccountGroup,
  selectAccount,
} from './store/actions';

const getAccountId = account => account.value;

const ManuallyEnteredAccountData = ({
  taxYear,
  period,
  entityId,
  isFetchingAccountGroups,
  accountGroupsOptions,
  selectedAccountGroup,
  selectAccountGroup,

  isFetchingAccounts,
  fetchAccountGroups,
  fetchAccounts,
  fetchAccountData,
  accountsOptions,
  selectedAccount,
  selectAccount,
}) => {
  const isContextReady = taxYear && period && entityId;

  const loadAccountGroups = useCallback(() => {
    if (isContextReady) {
      fetchAccountGroups();
    }
  }, [isContextReady, fetchAccountGroups]);

  useEffect(() => {
    loadAccountGroups();
  }, [loadAccountGroups]);

  useEffect(() => {
    if (isContextReady && selectedAccountGroup) {
      const accountGroup = selectedAccountGroup.value;
      fetchAccounts({ taxYear, period, entityId, accountGroup });
    }
  }, [fetchAccounts, isContextReady, selectedAccountGroup, taxYear, period, entityId]);

  const handleAccountGroupSelect = useCallback(
    selected => {
      selectAccountGroup(selected);
      const accountGroup = selected.value;
      fetchAccounts({ taxYear, period, entityId, accountGroup });
    },
    [fetchAccounts, selectAccountGroup, taxYear, period, entityId],
  );

  const handleAccountSelect = useCallback(
    account => {
      selectAccount(account);
      const accountId = getAccountId(account);
      fetchAccountData({ taxYear, period, entityId, accountId });
    },
    [selectAccount, fetchAccountData, taxYear, period, entityId],
  );

  return (
    <>
      <label className="a-form-label">Account Grouping</label>
      <Loading small isLoading={!isContextReady || isFetchingAccountGroups}>
        <SDKCustomSelect
          className="global-context-select sdk-custom-select custom-report-param-select"
          options={accountGroupsOptions}
          onChange={handleAccountGroupSelect}
          placeholder="Please Select..."
          value={selectedAccountGroup}
          virtualized
        />
      </Loading>
      <label className="a-form-label">Account</label>
      <Loading small isLoading={!isContextReady || isFetchingAccounts}>
        <SDKCustomSelect
          className="global-context-select sdk-custom-select custom-report-param-select"
          options={accountsOptions}
          onChange={handleAccountSelect}
          placeholder="Please Select..."
          value={selectedAccount}
          virtualized
          disabled={isFetchingAccountGroups}
        />
      </Loading>
    </>
  );
};

const optionPropType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

ManuallyEnteredAccountData.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  isFetchingAccountGroups: PropTypes.bool.isRequired,
  accountGroupsOptions: PropTypes.arrayOf(optionPropType),
  selectedAccountGroup: optionPropType,
  selectAccountGroup: PropTypes.func.isRequired,
  fetchAccountGroups: PropTypes.func.isRequired,
  isFetchingAccounts: PropTypes.bool.isRequired,
  selectedAccount: optionPropType,
  accountsOptions: PropTypes.arrayOf(optionPropType),
  selectAccount: PropTypes.func.isRequired,
  fetchAccounts: PropTypes.func.isRequired,
  fetchAccountData: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    isFetchingAccounts: isFetchingAccountsSelector(state),
    isFetchingAccountGroups: isFetchingAccountGroupsSelector(state),
    accountGroupsOptions: accountGroupsOptionsSelector(state),
    selectedAccountGroup: selectedAccountGroupSelector(state),
    selectedAccount: selectedAccountSelector(state),
    accountsOptions: accountsOptionsSelector(state),
  }),
  {
    fetchAccountGroups,
    fetchAccounts,
    fetchAccountData,
    selectAccountGroup,
    selectAccount,
  },
)(ManuallyEnteredAccountData);
