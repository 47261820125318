import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';
import {
  fetchConspbCalcSpecsTypes,
  updateConspbCalcSpecsTypes,
  createConspbCalcSpecsTypes,
} from '../types';

export const baseCalcSpecTypeInitialState = {
  calcSpecs: {
    columnsBlueprint: [],
    data: [],
  },
  isFetchingConspbCalcSpecs: false,

  isEditingCalcSpecs: false,
};

const baseCalcSpecTypeReducer = reducerFactory({
  initialState: baseCalcSpecTypeInitialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchConspbCalcSpecsTypes,
      payloadKey: 'calcSpecs',
      fetchingKey: 'isFetchingConspbCalcSpecs',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateConspbCalcSpecsTypes,
      fetchingKey: 'isEditingCalcSpecs',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createConspbCalcSpecsTypes,
      fetchingKey: 'isEditingCalcSpecs',
    }),
  },
});

export default baseCalcSpecTypeReducer;
