import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { clientIdSelector } from '../store/context';
import stringify from '../../utils/stringify';

const useOpenInNewTab = () => {
  const clientId = useSelector(clientIdSelector);

  const openInNewTab = useCallback(
    (route: string, params = {}) => {
      const queryParams = stringify(params);
      const url = queryParams ? `/${clientId}${route}?${queryParams}` : `/${clientId}${route}`;
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
    },
    [clientId],
  );

  return openInNewTab;
};

export default useOpenInNewTab;
