import React from 'react';

import { ValidationErrorsMessage } from '../../../../../../../common/types';
import SlideIn from '../../../../../shared/displayComponents/slideIn/slideIn.component';

import styles from './informationDetails.module.scss';

interface InformationDetailsProps {
  details: ValidationErrorsMessage;
  hidePanel: () => void;
  isOpen: boolean;
}

const InformationDetails = ({ details, hidePanel, isOpen }: InformationDetailsProps) => {
  const { type, description, element, detailedExplanation, additionalText } = details;

  const isAdditionalInformationSectionVisible = Boolean(detailedExplanation || additionalText);
  const getAdditionalInformationText = () =>
    detailedExplanation ? detailedExplanation : additionalText;

  return (
    <SlideIn
      className={styles.informationDetailsContent}
      title="Learn More"
      onRequestClose={hidePanel}
      isOpen={isOpen}
      width="480px"
      closeIconName="close"
    >
      <div className={styles.informationDetails}>
        <h4>{type}</h4>
        <h6>Description</h6>
        <p>{description}</p>
        <h6>Element</h6>
        <p>{element}</p>
        {isAdditionalInformationSectionVisible && (
          <div>
            <h6>Additional Information</h6>
            <p>{getAdditionalInformationText()}</p>
          </div>
        )}
      </div>
    </SlideIn>
  );
};

export default InformationDetails;
