import { Routes } from '@common-packages/routes-definitions';
import { dataTypes } from '@common-packages/shared-constants';

import {
  defaultColumnDefinition,
  adminColumnDefinition,
  defaultNoDataColumn,
} from '../../shared/columnDefinitions';
import { dateCellFormatter } from '../../shared/formatters';
import { getDataTypeBasedCellRenderer } from '../../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import {
  AdminCellRendererFactory,
  RedirectCellRendererHoc,
} from '../../shared/columnDefinitions/cellRenderers';
import config from '../../config';

import { pdfTypes } from './constants';

const getColumnDefinitions = ({
  onDeleteIconClick,
  columnsBlueprint,
  clientId,
  authQueryString,
}) => {
  if (!columnsBlueprint.length) {
    return [defaultNoDataColumn];
  }

  const pdfLibDownloadLinkRenderer = RedirectCellRendererHoc({
    getText: ({ value }) => value,
    getRedirect: (value, data) =>
      `${config.BASE_PATH}${Routes.developmentTaxFormsDownloadPdf.compiledRoute({
        formId: data.formId,
        pdfType: pdfTypes.PDF_LIB,
        clientId,
      })}?${authQueryString}`,
  });

  const getColumnDefinition = columnBlueprint => ({
    ...defaultColumnDefinition,
    headerName: columnBlueprint.displayName,
    field: columnBlueprint.field,
    editable: false,
    cellRenderer: (() => {
      switch (columnBlueprint.dataType) {
        case 'pdfLibDownloadLinkRenderer':
          return pdfLibDownloadLinkRenderer;
        default:
          return getDataTypeBasedCellRenderer({
            dataType: columnBlueprint.dataType,
            getDrillData: () => null,
          });
      }
    })(),
    valueFormatter: columnBlueprint.dataType === dataTypes.DATE ? dateCellFormatter : null,
    cellRendererParams: {
      disabled: true,
    },
    width: columnBlueprint.width ? columnBlueprint.width : null,
    minWidth: columnBlueprint.minWidth ? columnBlueprint.minWidth : null,
    cellStyle: columnBlueprint.cellStyle ? columnBlueprint.cellStyle : null,
  });

  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: false,
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    ...columnsBlueprint.map(column => {
      if (column.children) {
        return {
          headerName: column.displayName,
          children: column.children.map(getColumnDefinition),
        };
      }

      return getColumnDefinition(column);
    }),
  ];
};

export default getColumnDefinitions;
