import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';
import { connect } from 'react-redux';
import { efileSchemas } from '@common-packages/validators';

import { infoNotification } from '../../shared/notification/store/actions';
import saveFile from '../../utils/saveFile';

import UploadSubmissionForm from './uploadSubmissionForm.component';
import {
  validateAndGenerateMappingForStateTransmission,
  stateTransmissionImport,
  fetchCaseList,
  fetchYearSubPeriodList,
} from './store/actions';
import {
  yearSubPeriodListSelector,
  isFetchingYearSubPeriodListSelector,
  caseListSelector,
  isFetchingCaseListSelector,
} from './store/selectors';
import { uploadTypes, indefiniteInfoDuration } from './constants';

const UploadSubmissionModal = ({
  hideModal,
  visible,
  modalData,
  fetchCaseList,
  fetchYearSubPeriodList,
  caseList,
  yearSubPeriodList,
  isFetchingYearSubPeriodList,
  isFetchingCaseList,
  validateAndGenerateMappingForStateTransmission,
  stateTransmissionImport,
  infoNotification,
}) => {
  useEffect(() => {
    if (visible) {
      fetchCaseList();
      fetchYearSubPeriodList();
    }
  }, [visible, fetchCaseList, fetchYearSubPeriodList]);

  const upload = useCallback(
    async ({ file, TTICaseId, TTIYearId, uploadType, submissionId }, { setSubmitting }) => {
      hideModal();
      try {
        if (uploadType === uploadTypes.SUBMISSION.value) {
          infoNotification('Validating Submission', { duration: indefiniteInfoDuration });
          const response = await validateAndGenerateMappingForStateTransmission({
            file,
            submissionId,
            ttiCaseId: TTICaseId,
            ttiYearId: TTIYearId,
          });
          saveFile(
            atob(response.result.ttiMappingZipFile),
            `mapping-${submissionId}.zip`,
            'application/zip',
            true,
          );
        } else if (uploadType === uploadTypes.MAPPING.value) {
          infoNotification('Importing State Transmission', { duration: indefiniteInfoDuration });
          const response = await stateTransmissionImport({ file, submissionId });
          saveFile(
            JSON.stringify(response.result.ttiImportValidationJSONFile, null, 2),
            `import-validation-${submissionId}.json`,
            'application/json',
          );
        }
      } catch (error) {
        // TODO: validation error
      }
      setSubmitting(false);
    },
    [
      infoNotification,
      hideModal,
      stateTransmissionImport,
      validateAndGenerateMappingForStateTransmission,
    ],
  );

  const renderModal = useCallback(
    formikProps => (
      <Modal
        title={
          formikProps.values.uploadType === uploadTypes.SUBMISSION.value
            ? 'Validate Submission'
            : 'Upload Mapping'
        }
        closeAction={hideModal}
        visible={visible}
        submitText={
          formikProps.values.uploadType === uploadTypes.SUBMISSION.value
            ? 'Validate Submission'
            : 'Upload Mapping'
        }
        submitAction={formikProps.submitForm}
        dismissText="Cancel"
        dismissAction={hideModal}
      >
        <UploadSubmissionForm
          isFetchingCaseList={isFetchingCaseList}
          caseList={caseList}
          yearSubPeriodList={yearSubPeriodList}
          isFetchingYearSubPeriodList={isFetchingYearSubPeriodList}
          {...formikProps}
        />
      </Modal>
    ),
    [
      hideModal,
      visible,
      caseList,
      isFetchingCaseList,
      yearSubPeriodList,
      isFetchingYearSubPeriodList,
    ],
  );

  const initialModalData = {
    ...modalData,
    TTICaseId: '',
    TTIYearId: '',
    file: null,
  };

  return (
    <Formik
      initialValues={initialModalData}
      enableReinitialize
      validateOnBlur={false}
      validationSchema={efileSchemas.efileSubmissionSchema}
      onSubmit={upload}
    >
      {renderModal}
    </Formik>
  );
};

UploadSubmissionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  validateAndGenerateMappingForStateTransmission: PropTypes.func.isRequired,
  stateTransmissionImport: PropTypes.func.isRequired,
  fetchYearSubPeriodList: PropTypes.func.isRequired,
  fetchCaseList: PropTypes.func.isRequired,
  caseList: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  yearSubPeriodList: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingYearSubPeriodList: PropTypes.bool.isRequired,
  isFetchingCaseList: PropTypes.bool.isRequired,
  modalData: PropTypes.shape({
    submissionId: PropTypes.string.isRequired,
    taxPayer: PropTypes.string.isRequired,
    jurisdiction: PropTypes.string.isRequired,
    uploadType: PropTypes.string.isRequired,
    file: PropTypes.instanceOf(File),
    TTICaseId: PropTypes.string,
    TTIYearId: PropTypes.string,
  }),
  infoNotification: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    caseList: caseListSelector(state),
    isFetchingCaseList: isFetchingCaseListSelector(state),
    yearSubPeriodList: yearSubPeriodListSelector(state),
    isFetchingYearSubPeriodList: isFetchingYearSubPeriodListSelector(state),
  }),
  {
    validateAndGenerateMappingForStateTransmission,
    stateTransmissionImport,
    fetchCaseList,
    fetchYearSubPeriodList,
    infoNotification,
  },
)(UploadSubmissionModal);
