import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { taxYearSelector } from '../store/selectors';
import { taxYearSelector as developmentTaxYearSelector } from '../../development/store/selectors';
import { FormValues } from '../taxReturns/utils/useFormInitialValues';
import { FilingMethods } from '../enums';
import config from '../../config';

interface UseShouldDisplayReturnsDueDatesParams {
  isDeveloperTool?: boolean;
}

export const useShouldDisplayReturnsDueDates = (
  { isDeveloperTool }: UseShouldDisplayReturnsDueDatesParams = { isDeveloperTool: false },
) => {
  const globalContextTaxYear = useSelector(taxYearSelector);
  const developmentTaxYear = useSelector(developmentTaxYearSelector);

  return isDeveloperTool
    ? Number(developmentTaxYear) > 2020
    : config.DISPLAY_RETURNS_DUEDATES && Number(globalContextTaxYear) > 2020;
};

export const useShouldDisplayReturnsDueDatesWhenNotNonFiling = () => {
  const { values } = useFormikContext<FormValues>();
  const shouldDisplayDueDate = useShouldDisplayReturnsDueDates();
  return shouldDisplayDueDate && values.filingMethod !== FilingMethods.NON_FILING;
};
