const Yup = require('yup');

const { filingTypes } = require('../../stateHelpers');

const commonSchema = Yup.object().shape({
  taxYear: Yup.string().required(),
  period: Yup.string().required(),
  constantId: Yup.string().required().max(25),
  jurisdictionId: Yup.string().required(),
  filingTypeId: Yup.string().oneOf(Object.values(filingTypes)).required(),
  description: Yup.string().required().max(100),
  value: Yup.number().required(),
  filingAttributes: Yup.object().required(),
});

module.exports = {
  commonSchema,
};
