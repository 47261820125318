import React, { useEffect, useCallback, useState } from 'react';
import { Tab, Tabs, Button } from '@pwc/appkit-react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import {
  filingTypeIdSelector,
  taxYearSelector,
  periodSelector,
} from '../../shared/store/selectors';
import {
  createConstant,
  fetchConstants,
  updateConstant,
} from '../../shared/store/calculationConstants/actions';

import { fetchTaxRates, createTaxRate, updateTaxRate } from './store/actions/taxRates';
import { jurisdictionIdSelector } from './store/selectors/shared';
import Constants from './constants/constants.container';
import TaxRates from './taxRates/taxRates.container';
import styles from './taxRatesAndConstantsMaintenance.module.scss';
import useTaxRatesAndContantsBottomSlideInEditMode from './useTaxRatesAndContantsBottomSlideInEditMode.hook';
import { TABS_DEFINITIONS } from './constants';
import { TabsTypes } from './enums';

const TaxRatesAndConstants = ({ hasUserPermissionsToEdit }) => {
  const { queryParams, setParams } = useUrlParams();

  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const filingTypeId = useSelector(filingTypeIdSelector);

  const isContextReady = taxYear && period && jurisdictionId && filingTypeId;

  const [activeTab, setActiveTab] = useState(queryParams.activeTab || TABS_DEFINITIONS[0].type);

  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);

  useEffect(() => {
    if (queryParams.activeTab) {
      setParams({ queryParams: { activeTab: null } });
    }
  }, [setParams, queryParams.activeTab]);

  const saveTaxRate = useCallback(
    async ({ values, isEditMode }) => {
      const {
        oldStartRange,
        oldStopRange,
        calcPreferences,
        startRange,
        stopRange,
        rate,
        exclusionRate,
        exclusionAmt,
        filingAttributes,
        ...filingAttributesValues
      } = values;

      const data = {
        taxYear,
        period,
        jurisdictionId,
        filingTypeId,
        oldStartRange,
        oldStopRange,
        startRange: Number(startRange),
        stopRange: stopRange ? Number(stopRange) : null,
        rate: Number(rate),
        exclusionRate: Number(exclusionRate),
        exclusionAmt: Number(exclusionAmt),
        filingAttributes,
        calcPreferences: Number(calcPreferences),
        filingAttributesValues,
      };

      if (!isEditMode) {
        await dispatch(createTaxRate(data));
      } else {
        await dispatch(updateTaxRate(data));
      }

      dispatch(fetchTaxRates({ taxYear, period, jurisdictionId, filingTypeId }));
    },
    [dispatch, taxYear, period, jurisdictionId, filingTypeId],
  );

  const saveConstant = useCallback(
    async ({ values, isEditMode }) => {
      const {
        taxYear,
        constantId,
        jurisdictionId,
        description,
        value,
        mnemonic,
        displayOrder,
        ...filingAttributes
      } = values;

      const data = {
        taxYear,
        period,
        constantId,
        jurisdictionId,
        filingTypeId,
        description,
        value: Number(value),
        mnemonic,
        displayOrder,
        filingAttributes,
      };

      if (!isEditMode) {
        await dispatch(createConstant(data));
      } else {
        await dispatch(updateConstant(data));
      }

      dispatch(
        fetchConstants({
          taxYear,
          period,
          jurisdictionId,
          filingTypeId,
        }),
      );
    },
    [dispatch, period, filingTypeId],
  );

  const {
    agGridProps,
    bottomSlideInWithForm,
    onAddClick,
  } = useTaxRatesAndContantsBottomSlideInEditMode({
    activeTab,
    saveTaxRate,
    saveConstant,
  });

  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {TABS_DEFINITIONS.map(({ label, type }, index) => (
              <Tab id={type} value={type} label={label} key={index} />
            ))}
          </Tabs>
        </div>
        {hasUserPermissionsToEdit && (
          <Button
            size="lg"
            className={`add-button ${styles.addButton}`}
            onClick={onAddClick}
            disabled={!isContextReady}
          >
            {activeTab === TabsTypes.TAX_RATES ? 'Add Tax Rate' : 'Add Constant'}
          </Button>
        )}
      </div>
      {activeTab === TabsTypes.TAX_RATES ? (
        <TaxRates hasUserPermissionsToEdit={hasUserPermissionsToEdit} {...agGridProps} />
      ) : (
        <Constants hasUserPermissionsToEdit={hasUserPermissionsToEdit} {...agGridProps} />
      )}
      {hasUserPermissionsToEdit && bottomSlideInWithForm}
    </>
  );
};

TaxRatesAndConstants.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default TaxRatesAndConstants;
