import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Button } from '@pwc/appkit-react/lib/Button';

import Loading from '../../shared/displayComponents/loading.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import UpperCaseInput from '../../shared/forms/inputFormik/upperCaseInputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';

import { operationTypeOptions, functionTypeOptions } from './constants';
import styles from './genericFunctions.module.scss';

const GenericFunctionForm = ({
  isEditingExistingFunction,
  isGridLoading,
  isSavingChanges,
  addParameter,
  resetParams,

  values,
  resetForm,
  submitForm,
}) => {
  const onCancelClick = useCallback(() => {
    resetForm();
    resetParams();
  }, [resetForm, resetParams]);
  return (
    <>
      <div className="row">
        <div className="col add-button-column">
          <Button
            size="lg"
            kind="secondary"
            onClick={addParameter}
            className="add-button"
            disabled={isGridLoading || isSavingChanges}
          >
            Add Parameter
          </Button>
          <Button
            size="lg"
            kind="secondary"
            onClick={onCancelClick}
            className="add-button"
            disabled={isGridLoading || isSavingChanges}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            onClick={submitForm}
            className="add-button"
            disabled={isGridLoading || isSavingChanges}
          >
            <Loading small isLoading={isSavingChanges}>
              Save
            </Loading>
          </Button>
        </div>
      </div>
      <div className="row">
        <div className={`col ${styles.controlsRow}`}>
          <Field
            className={styles.topBarField}
            label="Function Id"
            name="functionId"
            component={UpperCaseInput}
            autoComplete="off"
            disabled={isEditingExistingFunction}
          />
          <Field
            className={styles.topBarField}
            label="Description"
            name="description"
            component={Input}
            autoComplete="off"
          />
          <Select
            wrapperClassName={styles.topBarField}
            appkitLabel="Function Type"
            name="functionType"
            options={functionTypeOptions}
            value={values.functionType}
            disabled={isEditingExistingFunction}
          />
          <Select
            wrapperClassName={styles.topBarField}
            appkitLabel="Operation Type"
            name="operationType"
            options={operationTypeOptions}
            value={values.operationType}
          />
        </div>
      </div>
    </>
  );
};

GenericFunctionForm.propTypes = {
  isEditingExistingFunction: PropTypes.bool.isRequired,
  isGridLoading: PropTypes.bool.isRequired,
  isSavingChanges: PropTypes.bool.isRequired,
  addParameter: PropTypes.func.isRequired,
  resetParams: PropTypes.func.isRequired,

  values: PropTypes.shape({
    functionId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    functionType: PropTypes.string,
    operationType: PropTypes.string,
  }).isRequired,
  resetForm: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default GenericFunctionForm;
