import React from 'react';
import { Field, useFormikContext } from 'formik';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';

import { contextTypeOptions } from './constants';
import styles from './dataModels.module.scss';

const DataModelForm = () => {
  const { values } = useFormikContext();

  return (
    <form className={styles.dataModelsForm}>
      <Field label="Description" name="description" component={Input} autoComplete="off" />
      <Select
        appkitLabel="Context Type"
        name="contextTypeId"
        options={contextTypeOptions}
        value={values?.contextTypeId}
      />
      <div className={styles.checkboxesGroup}>
        <Field label="Common" name="isCommon" component={Checkbox} />
        <Field
          label="Display Workpaper on Navigator"
          name="shouldDisplayWorkpaperOnNavigator"
          component={Checkbox}
        />
        <Field label="Inactive" name="inactive" component={Checkbox} />
      </div>
    </form>
  );
};

export default DataModelForm;
