import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { JobStatus } from '@common-packages/shared-constants';

import DateTime from '../../shared/displayComponents/dateTime/datetime.component';
import { jobPropTypes } from '../../jobs/propTypes';

import SuccessUpload from './successUpload.component';
import FailedUpload from './failedUpload.component';
import ProcessingUpload from './processingUpload.component';
import WaitingUpload from './waitingUpload.component';
import styles from './uploadStatus.module.scss';

const UploadStatus = ({ job, isValidationError = false, children = null }) => {
  const getStatusComponent = useCallback(() => {
    switch (job.status) {
      case JobStatus.WAITING:
        return <WaitingUpload />;
      case JobStatus.PROCESSING:
        return <ProcessingUpload />;
      case JobStatus.SUCCESS:
        return <SuccessUpload finishedOn={job.finishedOn} />;
      case JobStatus.FAILED:
        return <FailedUpload isValidationError={isValidationError} />;
      default:
        return null;
    }
  }, [job.status, job.finishedOn, isValidationError]);

  return (
    <div className={styles.uploadStatusContainer}>
      <p className={styles.lastFileUploadInfo}>
        Last file upload on: <DateTime ISOString={job.createdOn} />
      </p>
      {getStatusComponent()}
      {children}
    </div>
  );
};

UploadStatus.propTypes = {
  job: PropTypes.shape(jobPropTypes),
  isValidationError: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default UploadStatus;
