import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { TEMP_STORE } from '../shared/store/storeHelpers';
import { isFetchingContextSelector } from '../entity/store/selectors';

import ReturnMaintenance from './returnMaintenance.container';

const ReturnMaintenancePage = connect((state: TEMP_STORE) => ({
  isFetchingContext: isFetchingContextSelector(state),
}))(GlobalContextPage);

const ReturnMaintenanceRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.returnMaintenance.MAIN}
    wrapper={ReturnMaintenancePage}
    component={ReturnMaintenance}
    disableBreadcrumbs
    hideGlobalContext
  />
);

export default ReturnMaintenanceRoute;
