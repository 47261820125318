import React, { useMemo } from 'react';

import AgGrid from '../../../../../shared/displayComponents/agGrid/agGrid.component';
import { TabProps } from '../../types';

import getColumnDefinitions from './recentRefreshes.columnDefinitions';

const RecentRefreshes = ({ recentRefreshes, isFetchingDataRefreshLogs }: TabProps) => {
  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);

  return (
    <>
      <AgGrid
        rowData={recentRefreshes}
        columnDefs={columnDefinitions}
        withSearchBar
        autoMaxWidth
        isGridLoading={isFetchingDataRefreshLogs}
      />
    </>
  );
};

export default RecentRefreshes;
