import { defaultColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Due Date',
    field: 'dueDate',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity',
    field: 'orgId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Task',
    field: 'taskDescription',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Assigned To',
    field: 'assignedTo',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Managed By',
    field: 'managedBy',
  },
];

export default columnDefinitions;
