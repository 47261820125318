export const WIZARD_STEPS = [
  {
    name: 'Generate XML',
  },
  {
    name: 'Generate Transmission',
    warningForNextButton:
      'Warning: A state submission must be created before the return can be authorized and transmitted',
  },
  {
    name: 'Authorize Transmission',
    warningForNextButton:
      'Warning: The state submission must be authorized before the return can be transmitted',
  },
  {
    name: 'Transmit',
  },
  {
    name: 'Review Transmission Status',
  },
];

export const stateSubmissionTypes = {
  UNLINKED: 'UnLinked',
  LINKED: 'Linked',
};

export const DEFAULT_ACTION_BUTTON_TEXT = 'Run';

export const stateSubmissionOptions = [
  { label: stateSubmissionTypes.UNLINKED, value: stateSubmissionTypes.UNLINKED },
  { label: stateSubmissionTypes.LINKED, value: stateSubmissionTypes.LINKED },
];

export const EFILE_ACTION = {
  EF_SERVICE_CREATE_SUBMISSION: 'ef-service-createSubmission',
  EF_SERVICE_VALIDATE_SUBMISSION: 'ef-service-validateSubmission',
  EF_SERVICE_GENERATE_ZIP: 'ef-service-generateZip',

  VALIDATE_TRANSMISSION_UPLOAD: 'validate-state-transmission-upload',
  PROCESS_UPLOADED_STATE_TRANSMISSION: 'validate-state-transmission-process',
  GET_TRANSMISSION_UPLOAD_ID: 'validate-state-transmission-get-upload-id',
  GET_STATE_TRANSMISSION_SCHEMA_VALIDATION:
    'validate-state-transmission-get-schema-validation-error',

  UPLOAD_STATE_TRANSMISSION: 'upload-state-transmission',
  VALIDATE_AND_BUILD_INDEX: 'validate-and-build-index',
  DOWNLOAD_AND_REVIEW_SCHEMA: 'download-and-review-schema',

  VERIFY_AND_UPDATE_MAPPING_FILE: 'verify-and-update-mapping-file',
  UPLOAD_MAPPING_FILE: 'upload-mapping-file',
  IMPORT_STATE_TRANSMISSION: 'import-state-transmission',
  DOWNLOAD_AND_REVIEW_VALIDATION_REPORT: 'download-and-review-validation-report',

  PERFORM_CALCULATION_PROFILE: 'perform-calculation-profile',

  GENERATE_EFILE_XML: 'generate-efile-xml',
  DOWNLOAD_EFILE_XML: 'download-efile-xml',

  CONFIRM_NEW_YORK_FORM: 'confirm-new-york-form',
  CREATE_STATE_TRANSMISSION: 'create-state-transmission-file',
  DOWNLOAD_AND_REVIEW_TRANSMISSION_FILE_ERRORS: 'download-and-review-transmission-errors',
  DOWNLOAD_SIGNATURE_DECLARATION: 'download-signature-declaration',

  AUTHORIZE_SIGN_AND_SUBMIT: 'authorize-signed-declaration',

  TRANSMIT: 'transmit-efile-transmission',

  CHECK_TRANSMISSION_STATUS: 'check-transmission-status',
  DOWNLOAD_TRANSMISSION_FILE: 'download-transmission-file',
  DOWNLOAD_TRANSMISSION_ACK: 'download-transmission-file-acknowledgement',
};

export const EFILE_GENERATE_XML_STEPS = {
  'ef-service': {
    [EFILE_ACTION.EF_SERVICE_CREATE_SUBMISSION]: 'EF Service - create submission',
    [EFILE_ACTION.EF_SERVICE_VALIDATE_SUBMISSION]: 'EF Service - validate submission',
    [EFILE_ACTION.EF_SERVICE_GENERATE_ZIP]: 'EF Service - generate zip',
  },
  'state-transmission-validation': {
    [EFILE_ACTION.VALIDATE_TRANSMISSION_UPLOAD]: 'Upload State Transmission to validate',
    [EFILE_ACTION.PROCESS_UPLOADED_STATE_TRANSMISSION]: 'Process uploaded State Transmission',
    [EFILE_ACTION.GET_TRANSMISSION_UPLOAD_ID]: 'Get upload ID',
    [EFILE_ACTION.GET_STATE_TRANSMISSION_SCHEMA_VALIDATION]:
      'Get State Transmission Schema Validation',
  },
  'validate-and-build-index-for-state-transmission': {
    [EFILE_ACTION.UPLOAD_STATE_TRANSMISSION]: 'Upload State Transmission or e-File XML',
    [EFILE_ACTION.VALIDATE_AND_BUILD_INDEX]: 'Validate and Build Index for State Transmission',
    [EFILE_ACTION.DOWNLOAD_AND_REVIEW_SCHEMA]:
      'Download and Review Schema Validation and Index results',
  },
  'state-transmission-import': {
    [EFILE_ACTION.VERIFY_AND_UPDATE_MAPPING_FILE]: 'Verify and update mapping file',
    [EFILE_ACTION.UPLOAD_MAPPING_FILE]: 'Upload Mapping File',
    [EFILE_ACTION.IMPORT_STATE_TRANSMISSION]: 'Import State Transmission',
    [EFILE_ACTION.DOWNLOAD_AND_REVIEW_VALIDATION_REPORT]: 'Download and Review Validation Report',
  },
  'perform-calculation-profile': {
    [EFILE_ACTION.PERFORM_CALCULATION_PROFILE]: 'Perform Calculation profile',
  },
  'generate-efile-xml': {
    [EFILE_ACTION.GENERATE_EFILE_XML]: 'Generate E-file XML',
    [EFILE_ACTION.DOWNLOAD_EFILE_XML]: 'Download E-file XML',
  },
};

export const EFILE_GENERATE_XML_AND_VALIDATIONS_STEPS = [
  'ef-service',
  'state-transmission-validation',
];

export const EFILE_UPLOAD_XML_AND_GENERATED_BUSINESS_VALIDATIONS = [
  'validate-and-build-index-for-state-transmission',
  'state-transmission-import',
  'perform-calculation-profile',
  'generate-efile-xml',
];

export const EFILE_INITIATE_SKIPPABLE_STEPS = ['perform-calculation-profile'];

export const EFILE_AUTHORIZE_STEPS = {
  'authorize-sign-and-submit': {
    [EFILE_ACTION.AUTHORIZE_SIGN_AND_SUBMIT]: 'Authorize sign and submit',
  },
};

export const EFILE_TRANSMIT_STEPS = {
  transmit: {
    [EFILE_ACTION.TRANSMIT]: 'Transmit electronic return to Tax Authority',
  },
};

export const EFILE_REVIEW_STATUS_STEPS = {
  'check-transmission-status': {
    [EFILE_ACTION.CHECK_TRANSMISSION_STATUS]: 'Check Transmission Status',
    [EFILE_ACTION.DOWNLOAD_TRANSMISSION_FILE]: 'Download E-file transmission file',
    [EFILE_ACTION.DOWNLOAD_TRANSMISSION_ACK]: 'Download E-file transmission acknowledgement',
  },
};

export const fileTypes = {
  INPUT: 'INPUT',
  OUTPUT: 'OUTPUT',
};
