import { httpGetAndParse, httpPost } from '../../../utils/fetchService';

export const fetchFederalReconciliations = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/federal-reconciliations/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching federal reconciliation failed',
  });

export const createFederalReconciliationsNote = ({
  taxYear,
  period,
  entityId,
  noteMessage,
  accountGroup,
}) =>
  httpPost({
    route: '/api/entities/federal-reconciliations/notes',
    errorMessage: 'Adding note failed',
    body: {
      taxYear,
      period,
      entityId,
      noteMessage,
      accountGroup,
    },
  });
