import { Routes, taxNames } from '@common-packages/routes-definitions';

import {
  LinkCellRendererFactory,
  IconCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import { filingTypes } from '../../shared/constants';
import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';

const DEFAULT_NAMESPACE = 'entityFilingStatesReturnWorkspace'; // TODO: Remove NAMESPACE when rest of drill screens would be moved to context aware url routing

const defaultColumnDefinition = {
  suppressMenu: true,
  width: 100,
  cellStyle: {
    textAlign: 'center',
  },
};

const LockRenderer = IconCellRendererFactory({
  getName: value => (value ? 'lock' : 'lock-open'),
  isFaIcon: true,
});

const getColumnDefinitions = ({ selectOrgId, selectFilingType, taxYear, period }) => {
  const IncomeTaxLinkRenderer = LinkCellRendererFactory({
    getText: ({ data }) => (data.incomeTaxYn ? 'Income' : ''),
    getPathName: (value, data) =>
      Routes.taxSummaries.compiledRoute({
        ...getContextAwareUrlParams({
          taxYear,
          period,
          filingTypeId: filingTypes.SEPARATE,
          businessEntityId: data.orgId,
          jurisdictionId: data.jurisdictionId,
          taxName: taxNames.INCOME_TAX,
        }),
      }),
    clickHandler: (value, data) => {
      selectOrgId(data.orgId);
      selectFilingType(filingTypes.SEPARATE);
    },
  });

  const FranchiseTaxLinkRenderer = LinkCellRendererFactory({
    getText: ({ data }) => (data.franchiseTaxYn ? 'Franchise' : ''),
    getPathName: (value, data) =>
      Routes.taxSummaries.compiledRoute({
        ...getContextAwareUrlParams({
          taxYear,
          period,
          filingTypeId: filingTypes.SEPARATE,
          businessEntityId: data.orgId,
          jurisdictionId: data.jurisdictionId,
          taxName: taxNames.FRANCHISE_TAX,
        }),
      }),
    clickHandler: (value, data) => {
      selectOrgId(data.orgId);
      selectFilingType(filingTypes.SEPARATE);
    },
  });

  const ReturnTaxLinkRenderer = LinkCellRendererFactory({
    getText: () => 'Return',
    getPathName: () => Routes[DEFAULT_NAMESPACE].MAIN,
    clickHandler: (value, data) => {
      selectOrgId(data.orgId);
      selectFilingType(filingTypes.SEPARATE);
    },
  });

  return [
    {
      ...defaultColumnDefinition,
      headerName: '',
      field: 'returnLockedYn',
      cellRenderer: LockRenderer,
      width: 30,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity ID',
      field: 'orgId',
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Description',
      field: 'orgDescription',
      width: 320,
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: '',
      field: 'incomeTaxYn',
      cellRenderer: IncomeTaxLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Links',
      field: 'franchiseTaxYn',
      cellRenderer: FranchiseTaxLinkRenderer,
      sortable: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: '',
      cellRenderer: ReturnTaxLinkRenderer,
    },
  ];
};

export default getColumnDefinitions;
