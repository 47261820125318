import getApiActionTypesHandlers from '../getApiActionTypesHandlers';
import reducerFactory from '../reducerFactory';

import {
  fetchAccountAuditTrailsTypes,
  fetchAccountDataTypes,
  fetchAccountAdjustmentTypes,
  fetchCalcDataTypes,
} from './types';

export const initialState = {
  accountAuditTrails: [],
  isFetchingAccountAuditTrails: false,
  accountData: [],
  isFetchingAccountData: false,
  accountAdjustment: [],
  isFetchingAccountAdjustment: false,
  calcData: [],
  isFetchingCalcData: false,
};

const allReturnDataReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountAuditTrailsTypes,
      payloadKey: 'accountAuditTrails',
      fetchingKey: 'isFetchingAccountAuditTrails',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountDataTypes,
      payloadKey: 'accountData',
      fetchingKey: 'isFetchingAccountData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAccountAdjustmentTypes,
      payloadKey: 'accountAdjustment',
      fetchingKey: 'isFetchingAccountAdjustment',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchCalcDataTypes,
      payloadKey: 'calcData',
      fetchingKey: 'isFetchingCalcData',
    }),
  },
});

export default allReturnDataReducer;
