import { defaultColumnDefinition } from '../../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'orgId',
    width: 100,
    suppressMenu: false,
    menuTabs: ['filterMenuTab'],
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
    width: 400,
    suppressMenu: false,
    menuTabs: ['filterMenuTab'],
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Type',
    field: 'entityTypeDescription',
    width: 200,
    suppressMenu: false,
    menuTabs: ['filterMenuTab'],
  },
];

export default columnDefinitions;
