import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CLIENT_STATUS_KEY } from '../../../shared/localStorage';
import {
  filingTypeIdSelector,
  separateJurisdictionsOptionsSelector,
  consolidationJurisdictionsOptionsUsedInConsolSelector,
  globalContextSelector,
} from '../../store/selectors';
import { filingTypes } from '../../constants';
import Loading from '../../displayComponents/loading.component';
import { calcStatusDataSelector } from '../../store/calcStatus/selectors';
import { clientIdSelector } from '../../store/context';
import { useLocalStorage } from '../../hooks/useLocalStorage.hook';

import TaxYearDropdown from './taxYearDropdown.container';
import PeriodDropdown from './periodDropdown.container';
import FilingTypeDropdown from './filingTypeDropdown.container';
import ConsolidationDropdown from './consolidationDropdown.container';
import EntityDropdown from './entityDropdown.container';
import JurisdictionDropdown from './jurisdictionDropdown.container';
import CalcStatusCalcButtonIndicator from './calcStatusCalcButtonIndicator.container';
import { TaxYearValue, PeriodValue, GlobalContextValue } from './globalContextValues';
import { localStorageContextWrapper } from './localStorageContextWrapper';
import PinSVG from './iconComponent/pinSVG';
import UnpinSVG from './iconComponent/unpinSVG';

const GlobalContextDropdownsPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById('navigation-panel-context-wrapper'));

const PinIcon = () => {
  const clientId = useSelector(clientIdSelector);

  const [context, setContext] = useLocalStorage(CLIENT_STATUS_KEY.CONTEXT, {}, clientId);

  const handleClickPinIcon = useCallback(() => {
    if (!context) {
      return;
    }

    if (!context?.isContextPinned) {
      const navigatorContainer = document.getElementById('navigation-panel-root');
      navigatorContainer.scrollTop = 0;
    }

    setContext({ ...context, isContextPinned: !context?.isContextPinned });
  }, [context, setContext]);

  return (
    <>
      <div
        className={classNames('pin-icon-container', {
          'show-unpin-svg': context?.isContextPinned,
        })}
        onClick={handleClickPinIcon}
      >
        <div className="unpin-svg">
          <UnpinSVG />
        </div>
        <div className="pin-svg">
          <PinSVG />
        </div>
      </div>
    </>
  );
};

const Wrapper = ({
  isFetchingContext,
  additionalDropdowns,
  hideJurisdictionDropdown = false,
  hidePeriodDropdown = false,
  hideFilingTypeDropdown = false,
  hideContextDropdowns = false,
}) => {
  const filingTypeId = useSelector(filingTypeIdSelector);
  const separateJurisdictionsOptions = useSelector(separateJurisdictionsOptionsSelector);
  const consolidationJurisdictionsOptions = useSelector(
    consolidationJurisdictionsOptionsUsedInConsolSelector,
  );

  const globalContext = useSelector(globalContextSelector);
  const calcStatusData = useSelector(calcStatusDataSelector);

  const isCalcStatusLoading = Boolean(
    !calcStatusData ||
      (globalContext.isConsolidated &&
        calcStatusData?.calcStatus &&
        calcStatusData?.calcStatusCreatedOn &&
        !calcStatusData?.calcStatusTimestamp),
  );

  const separateWorkspaceDropdowns = (
    <>
      <EntityDropdown isFetchingContext={isFetchingContext} />
      {!hideJurisdictionDropdown && (
        <JurisdictionDropdown
          isFetchingContext={isFetchingContext}
          jurisdictionsOptions={separateJurisdictionsOptions}
        />
      )}
    </>
  );

  const consolidationWorkspaceDropdowns = (
    <>
      {!hideJurisdictionDropdown && (
        <JurisdictionDropdown
          isFetchingContext={isFetchingContext}
          jurisdictionsOptions={consolidationJurisdictionsOptions}
        />
      )}
      <ConsolidationDropdown isFetchingContext={isFetchingContext} />
    </>
  );

  return (
    <GlobalContextDropdownsPortal>
      {!hideContextDropdowns && (
        <div className="global-context-dropdowns-wrapper">
          <div className="context-row">
            <div className="a-h4">Context</div>
            <div className="context-button-wrapper">
              {globalContext.isReady && (
                <Loading small isLoading={isCalcStatusLoading}>
                  <CalcStatusCalcButtonIndicator />
                </Loading>
              )}
              <PinIcon />
            </div>
          </div>
          <hr />
          <TaxYearDropdown isFetchingContext={isFetchingContext} />
          {!hidePeriodDropdown && <PeriodDropdown isFetchingContext={isFetchingContext} />}
          {!hideFilingTypeDropdown && <FilingTypeDropdown isFetchingContext={isFetchingContext} />}
          {filingTypeId === filingTypes.CONSOLIDATED
            ? consolidationWorkspaceDropdowns
            : separateWorkspaceDropdowns}
          {additionalDropdowns}
        </div>
      )}
    </GlobalContextDropdownsPortal>
  );
};

Wrapper.propTypes = {
  isFetchingContext: PropTypes.bool,
  additionalDropdowns: PropTypes.element,
  hideJurisdictionDropdown: PropTypes.bool,
  hidePeriodDropdown: PropTypes.bool,
  hideFilingTypeDropdown: PropTypes.bool,
  hideContextDropdowns: PropTypes.bool,
};

const GlobalContextDropdowns = localStorageContextWrapper(Wrapper);

const GlobalContextValues = ({ additionalValues, hidePeriod }) => (
  <>
    <TaxYearValue />
    {!hidePeriod && <PeriodValue />}
    {additionalValues}
  </>
);

GlobalContextValues.propTypes = {
  additionalValues: PropTypes.element,
  hidePeriod: PropTypes.bool,
};

export {
  GlobalContextDropdowns,
  GlobalContextValues,
  TaxYearValue,
  PeriodValue,
  GlobalContextValue,
};
