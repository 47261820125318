export const indefiniteInfoDuration = 10000000;

export const uploadTypes = {
  SUBMISSION: {
    label: 'Submission',
    value: 'submission',
  },
  MAPPING: {
    label: 'Mapping',
    value: 'mapping',
  },
};
