import React from 'react';
import PropTypes from 'prop-types';
import { DRILL_TYPE } from '@common-packages/drilling';

import { currencyColumnDefinition, defaultColumnDefinition } from '../shared/columnDefinitions';
import { currencyCellFormatter, amountFormatter } from '../shared/formatters';
import {
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../shared/columnDefinitions/cellRenderers';
import { createDrillRoute } from '../shared/drilling';
import getContextAwareUrlParams from '../utils/getContextAwareUrlParams';

import { rowInfoKey, estimatedTaxSummariesColumnKeys } from './constants';

const getRowInfo = data => data[rowInfoKey];

const accountCellClassRules = {
  'bold-cell': ({ data }) => getRowInfo(data).isSummaryRow,
};

const underlineCellClassRules = {
  'underlined-cell': ({ data }) => getRowInfo(data).isLastRowInSection,
  'double-underlined-cell': ({ data }) => getRowInfo(data).dataDoubleUnderlined,
};

const valueFormatter = props => {
  if (props.data.rowInfo.isEmptyRow) {
    return '';
  }

  return props.data.rowInfo.formatAsPercentage
    ? amountFormatter({
        value: props.value,
        props: {
          ...props.data.rowInfo,
          percentage: true,
          decimalPlaces: props.data.rowInfo.dataDecimalPlaces,
        },
      })
    : currencyCellFormatter(props);
};

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  ...currencyColumnDefinition,
  maxWidth: 110,
  sortable: false,
};

const getColumnDefinitions = ({ urlParams, taxYear = '' }) => {
  const GenericCategoryCellRenderer = props => {
    const {
      data: {
        rowInfo: { isGenericCategoryLink, categoryId },
      },
      colDef: { taxYear, period },
    } = props;

    if (isGenericCategoryLink) {
      const GenericCategoryLink = LinkCellRendererFactory({
        getText: ({ valueFormatted }) => valueFormatted,
        getPathName: () =>
          createDrillRoute(
            {
              drillType: DRILL_TYPE.GENERIC_DRILL,
              categoryId,
            },
            getContextAwareUrlParams({ ...urlParams, taxYear, period }),
          ),
      });

      return <GenericCategoryLink {...props} />;
    }

    return <TextRenderer {...props} />;
  };

  GenericCategoryCellRenderer.propTypes = {
    data: PropTypes.shape({
      rowInfo: PropTypes.shape({
        isGenericCategoryLink: PropTypes.bool,
        categoryId: PropTypes.string,
      }),
    }),
    colDef: PropTypes.shape({
      taxYear: PropTypes.string.isRequired,
      period: PropTypes.string.isRequired,
    }),
  };

  const priorYear = taxYear ? String(Number(taxYear) - 1) : '';
  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      sortable: false,
      headerName: '',
      field: estimatedTaxSummariesColumnKeys.ACCOUNT,
      width: 210,
      cellClassRules: { ...accountCellClassRules },
      maxWidth: 320,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${priorYear} Extension`,
      taxYear: priorYear,
      period: '5',
      field: estimatedTaxSummariesColumnKeys.PRIOR_TAX_YEAR_EXTENSION,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${priorYear} Return`,
      taxYear: priorYear,
      period: '0',
      field: estimatedTaxSummariesColumnKeys.PRIOR_TAX_YEAR_RETURN,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} 1st Quarter`,
      taxYear,
      period: '1',
      field: estimatedTaxSummariesColumnKeys.FIRST_QUARTER,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} 2nd Quarter`,
      taxYear,
      period: '2',
      field: estimatedTaxSummariesColumnKeys.SECOND_QUARTER,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} 3rd Quarter`,
      taxYear,
      period: '3',
      field: estimatedTaxSummariesColumnKeys.THIRD_QUARTER,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} 4th Quarter`,
      taxYear,
      period: '4',
      field: estimatedTaxSummariesColumnKeys.FOURTH_QUARTER,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} Extension`,
      taxYear,
      period: '5',
      field: estimatedTaxSummariesColumnKeys.CURRENT_TAX_YEAR_EXTENSION,
      cellClassRules: { ...underlineCellClassRules },
      cellRenderer: GenericCategoryCellRenderer,
      valueFormatter,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
