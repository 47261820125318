import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Input from '../../../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Radio from '../../../../shared/forms/radioFormik/radioFormik.component';
import Checkbox from '../../../../shared/forms/checkboxFormik/checkboxFormik.component';
import { SelectOptionPropTypes } from '../../../../shared/forms/propTypes';
import FilingAttributes from '../../../developmentFilingAttributes.component';

import { formDetailsProps, filingAttributesProps } from './propTypes';
import styles from './form.module.scss';

const FormDetailsForm = ({ values, handleChange, filingAttributes, watermarks, formStatuses }) => (
  <form>
    <div className={styles.formDetailsWrapper}>
      <Field
        className={styles.formName}
        label="Form Name"
        name="formName"
        component={Input}
        autoComplete="off"
        customChangeHandler={handleChange}
      />
      <Select
        wrapperClassName={styles.watermark}
        appkitLabel="Watermark"
        name="watermark"
        options={watermarks}
        value={values.watermark}
        showSearchOnToggle
        customChangeHandler={handleChange}
        isClearable
      />
      <Field
        className={styles.formDescription}
        label="Form Description"
        name="formDescription"
        component={Input}
        autoComplete="off"
        customChangeHandler={handleChange}
      />
      <FilingAttributes
        filingAttributes={filingAttributes}
        filingAttributeGroupClassNames={styles}
        handleChange={handleChange}
      />
      <Field
        name="formStatus"
        label="Form Status"
        component={Radio}
        options={formStatuses}
        customChangeHandler={handleChange}
      />
      <div className={styles.inDevelopmentMessageAndefileSupported}>
        <Field
          label="In Development Message"
          name="inDevelopmentMessage"
          component={Input}
          autoComplete="off"
          customChangeHandler={handleChange}
        />
        <Field
          className={styles.efileSupported}
          label="E-file Supported"
          name="efileSupported"
          component={Checkbox}
          autoComplete="off"
          customChangeHandler={handleChange}
        />
      </div>
      <Field
        className={styles.pdfName}
        label="PDF"
        name="pdfName"
        component={Input}
        autoComplete="off"
        disabled
      />
      <Field
        className={styles.swpFormId}
        label="SWP Form Id"
        name="swpFormId"
        component={Input}
        autoComplete="off"
        customChangeHandler={handleChange}
      />
      <Field
        className={styles.swpCalc}
        label="SWP Calc Iterations"
        name="swpCalcIteration"
        component={Input}
        autoComplete="off"
        type="number"
        customChangeHandler={handleChange}
      />
    </div>
  </form>
);

FormDetailsForm.propTypes = {
  values: formDetailsProps.isRequired,
  handleChange: PropTypes.func.isRequired,
  filingAttributes: filingAttributesProps.isRequired,
  watermarks: PropTypes.arrayOf(SelectOptionPropTypes),
  formStatuses: PropTypes.arrayOf(SelectOptionPropTypes),
};

export default FormDetailsForm;
