import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CalcStatuses } from '@tls/state-helpers';
import { Routes } from '@common-packages/routes-definitions';

import { calcStatusDataSelector } from '../../store/calcStatus/selectors';
import FaIcon from '../../displayComponents/faIcon/faIcon.component';

const calcStatusLabel = {
  [CalcStatuses.CLEAN]: 'Data is up-to-date',
  [CalcStatuses.IN_PROGRESS]: 'Calculation in progress',
  [CalcStatuses.DIRTY]: 'Data is outdated',
};

const CalcStatusCalcButtonIndicator = ({
  shouldLinkToManualCalculations = true,
  className,
  calcIconSize = 18,
}) => {
  const calcStatusData = useSelector(calcStatusDataSelector);

  const indicatorTitle = useMemo(() => {
    const linkHint = shouldLinkToManualCalculations ? 'Go to manual calculations; ' : '';
    const status = calcStatusLabel[calcStatusData?.calcStatus];

    return `${linkHint}${status}`;
  }, [shouldLinkToManualCalculations, calcStatusData?.calcStatus]);

  const renderCalcStatusIndicator = () => (
    <span
      className={classNames(className, 'calc-status-indicator', {
        'has-link': shouldLinkToManualCalculations,
        'up-to-date': calcStatusData?.calcStatus === CalcStatuses.CLEAN,
        calculating: calcStatusData?.calcStatus === CalcStatuses.IN_PROGRESS,
        dirty: calcStatusData?.calcStatus === CalcStatuses.DIRTY,
      })}
      title={indicatorTitle}
    >
      <FaIcon icon="calculator" prefix="fas" size={calcIconSize} />
    </span>
  );

  return (
    <>
      {shouldLinkToManualCalculations ? (
        <Link
          to={{
            pathname: Routes.manualCalculations.MAIN,
          }}
        >
          {renderCalcStatusIndicator()}
        </Link>
      ) : (
        renderCalcStatusIndicator()
      )}
    </>
  );
};

CalcStatusCalcButtonIndicator.propTypes = {
  className: PropTypes.string,
  shouldLinkToManualCalculations: PropTypes.bool,
  calcIconSize: PropTypes.number,
};

export default CalcStatusCalcButtonIndicator;
