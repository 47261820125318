import React, { useCallback, useState } from 'react';
import { Tab, Tabs } from '@pwc/appkit-react';

import CustomReports from './customReports.component';
import Maintenance from './maintenance.container';
import Tools from './tools.component';
import Tasks from './tasks.component';

const TABS_TYPES = {
  CUSTOM_REPORTS: 'CUSTOM_REPORTS',
  MAINTENANCE: 'MAINTENANCE',
  TOOLS: 'TOOLS',
  TASKS: 'TASKS',
};

const tabsDefinitions = [
  { type: TABS_TYPES.CUSTOM_REPORTS, label: 'Custom Reports' },
  { type: TABS_TYPES.MAINTENANCE, label: 'Maintenance' },
  { type: TABS_TYPES.TOOLS, label: 'Tools' },
  { type: TABS_TYPES.TASKS, label: 'Tasks' },
];

const Admin = () => {
  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TABS_TYPES.CUSTOM_REPORTS:
        return <CustomReports />;
      case TABS_TYPES.MAINTENANCE:
        return <Maintenance />;
      case TABS_TYPES.TOOLS:
        return <Tools />;
      case TABS_TYPES.TASKS:
        return <Tasks />;
      default:
        throw new Error('Unsupported tab type');
    }
  };
  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }, index) => (
              <Tab id={type} value={type} label={label} key={index} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">{getActiveTabComponent()}</div>
      </div>
    </>
  );
};

export default Admin;
