const Yup = require('yup');

const forceEntityBatchCalculationSchema = Yup.object()
  .shape({
    taxYear: Yup.string().required(),
    period: Yup.string().required(),
    entityId: Yup.string().required(),
  })
  .strict();

module.exports = forceEntityBatchCalculationSchema;
