import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Select from '../../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';

import styles from './fields.module.scss';

const WrappedSelect = ({ className = '', name, disabled = false, ...restProps }) => {
  const isDisabled = typeof disabled === 'function' ? disabled(name) : disabled;

  return (
    <Select
      wrapperClassName={classNames(className, styles.gridField, {
        [styles.fieldDisabled]: isDisabled,
      })}
      name={name}
      disabled={isDisabled}
      {...restProps}
    />
  );
};

WrappedSelect.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  className: PropTypes.string,
};

export default WrappedSelect;
