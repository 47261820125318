import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { GenericScreenRowTypes } from '@common-packages/shared-constants';

import { SelectOptionPropTypes } from '../../../shared/propTypes/selectOption';
import SDKCustomSelect from '../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../../../shared/forms/checkboxFormik/checkboxFormik.component';
import styles from '../genericScreenDefinition.module.scss';

import { rowTypesOptions, dataTypesOptions, textTypesOptions, drilldownOptions } from './constants';
import {
  defaultHeaderTypeState,
  defaultTextTypeState,
  defaultBlankTypeState,
  defaultLineTypeState,
  defaultDataTypeState,
} from './editRowDetailsForm.defaultStates';

const WrappedCheckboxField = props => (
  <div className="form-text">
    <Field {...props} />
  </div>
);

const defaultRowTypesState = {
  [GenericScreenRowTypes.HEADER]: defaultHeaderTypeState,
  [GenericScreenRowTypes.TEXT]: defaultTextTypeState,
  [GenericScreenRowTypes.BLANK]: defaultBlankTypeState,
  [GenericScreenRowTypes.LINE]: defaultLineTypeState,
  [GenericScreenRowTypes.DATA]: defaultDataTypeState,
};

const EditRowDetailsForm = ({ categoriesOptions, accountsOptions, rowId, setFormikProps }) => {
  const formikContext = useFormikContext();
  const { setFieldValue, values } = formikContext;

  const [formDefaultState, setFormDefaultState] = useState(defaultRowTypesState[values.rowType]);

  useEffect(() => {
    setFormDefaultState(defaultRowTypesState[values.rowType]);
  }, [rowId, values.rowType]);

  useEffect(() => {
    setFormikProps(formikContext);
  }, [setFormikProps, formikContext]);

  useEffect(() => () => setFormikProps(null));

  const handleRowTypeSelect = useCallback(
    (fieldName, value) => {
      const defaultState = defaultRowTypesState[value];
      setFormDefaultState(defaultState);
      Object.keys(defaultState).map(key => setFieldValue(key, defaultState[key].value));
    },
    [setFieldValue],
  );

  const handleAccountChange = useCallback(
    option => {
      setFieldValue('accountId', option.value);
    },
    [setFieldValue],
  );

  return (
    <form className={styles.editTaxFormFieldForm}>
      <div className={styles.inputsSection}>
        <Field
          className="form-text"
          label="Row"
          name="rowId"
          component={Input}
          autoComplete="off"
          disabled
        />
        <Select
          appkitLabel="Row Type"
          name="rowType"
          autoComplete="off"
          options={rowTypesOptions}
          onSelect={handleRowTypeSelect}
          value={values.rowType}
        />
        <Select
          disabled={formDefaultState.dataType.disabled}
          appkitLabel="Data Type"
          name="dataType"
          autoComplete="off"
          options={dataTypesOptions}
          value={values.dataType}
        />
        <Select
          disabled={formDefaultState.categoryId.disabled}
          appkitLabel="Category"
          name="categoryId"
          autoComplete="off"
          options={categoriesOptions}
          value={values.categoryId}
          showSearchOnToggle
        />
        <SDKCustomSelect
          disabled={formDefaultState.accountId.disabled}
          appkitLabel="Account"
          options={accountsOptions}
          value={values.accountId}
          virtualized
          onChange={handleAccountChange}
        />
        <Field
          className="form-text"
          disabled={formDefaultState.text.disabled}
          label="Text"
          name="text"
          component={Input}
          autoComplete="off"
        />
        <Select
          disabled={formDefaultState.textType.disabled}
          appkitLabel="Text Type"
          name="textType"
          autoComplete="off"
          options={textTypesOptions}
          value={values.textType}
        />
        <div className={`form-text ${styles.checkboxesGroupLabel}`}>
          <label className="a-form-label">Format Options:</label>
        </div>
        <div className={styles.checkboxesGroup}>
          <WrappedCheckboxField
            disabled={formDefaultState.percentage.disabled}
            label="Percent"
            name="percentage"
            component={Checkbox}
          />
          <WrappedCheckboxField
            disabled={formDefaultState.commas.disabled}
            label="Commas"
            name="commas"
            component={Checkbox}
          />
          <WrappedCheckboxField
            disabled={formDefaultState.showAdj.disabled}
            label="Show Adj"
            name="showAdj"
            component={Checkbox}
          />
          <WrappedCheckboxField
            disabled={formDefaultState.borders.disabled}
            label="Borders"
            name="borders"
            component={Checkbox}
          />
        </div>
        <Field
          className="form-text"
          disabled={formDefaultState.decimalPlaces.disabled}
          label="Decimal Places"
          type="number"
          min={0}
          name="decimalPlaces"
          component={Input}
          autoComplete="off"
        />
        <Select
          disabled={formDefaultState.drilldownType.disabled}
          appkitLabel="Drilldown Type"
          name="drilldownType"
          autoComplete="off"
          options={drilldownOptions}
          value={values.drilldownType}
        />
      </div>
    </form>
  );
};

EditRowDetailsForm.propTypes = {
  accountsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  categoriesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  rowId: PropTypes.string.isRequired,
  setFormikProps: PropTypes.func.isRequired,
};

export default EditRowDetailsForm;
