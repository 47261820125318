import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button } from '@pwc/appkit-react/lib/Button';

import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { httpGetAndParse, httpPostAndParse } from '../../utils/fetchService';
import useFetch from '../hooks/useFetch.hook';

import styles from './reportParam.module.scss';

const getApiAction = ({ method }) => ({ endpointBasePath, params }) =>
  method === 'GET'
    ? httpGetAndParse({
        route: `${endpointBasePath}/main/${encodeURIComponent(JSON.stringify(params))}`,
        errorMessage: `Fetching report data failed`,
      })
    : httpPostAndParse({
        route: `${endpointBasePath}/main`,
        errorMessage: `Fetching report data failed`,
        body: params,
      });

export const createUseReportDataHook = useFetch => {
  const useReportData = ({ endpointBasePath, isFetchingAnyParam, params, method = 'GET' }) => {
    const action = useMemo(() => getApiAction({ method }), [method]);

    const { data: reportData, isFetching: isFetchingReportData, fetch, resetData } = useFetch({
      action,
    });

    const [hasFiredReportFetchAtLeastOnce, setHasFiredReportFetchAtLeastOnce] = useState(false);

    useEffect(() => {
      resetData();
    }, [resetData, params, isFetchingAnyParam]);

    const areParamsReady =
      !isFetchingAnyParam &&
      Object.values(params).every(
        paramValue =>
          !isNullOrUndefined(paramValue) && (!Array.isArray(paramValue) || paramValue.length > 0),
      );

    const fetchData = useCallback(() => {
      fetch({ endpointBasePath, params });
    }, [fetch, endpointBasePath, params]);

    const runReportButton = (
      <Button
        size="lg"
        onClick={fetchData}
        className={styles.runReportBtn}
        disabled={!areParamsReady || isFetchingReportData}
      >
        Run Report
      </Button>
    );

    useEffect(() => {
      if (areParamsReady && !hasFiredReportFetchAtLeastOnce) {
        fetchData();
        setHasFiredReportFetchAtLeastOnce(true);
      }
    }, [fetchData, areParamsReady, isFetchingAnyParam, hasFiredReportFetchAtLeastOnce]);

    return {
      reportData,
      isFetchingReportData,
      areParamsReady,
      runReportButton,
      fetchData,
      showGrid: Boolean(reportData || isFetchingReportData),
      hasFiredReportFetchAtLeastOnce,
    };
  };
  return useReportData;
};

export default createUseReportDataHook(useFetch);
