import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Routes, taxNames } from '@common-packages/routes-definitions';

import AuthorizedRoute from '../../../shared/authorization/authorizedRoute';
import { periodSelector, taxYearSelector } from '../../../shared/store/selectors';
import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import Loading from '../../../shared/displayComponents/loading.component';
import ManualAccountsGrid from '../../../shared/displayComponents/customizedScreen/manualAccounts/manualAccountsGrid.container';
import { manualAccountsPropType } from '../../../shared/displayComponents/customizedScreen/manualAccounts/propTypes';

import CustomizedScreenForComponentDrill from './customizedScreenForComponentDrill.container';
import useCustomizedScreen from './useCustomizedScreen.hook';
import { screenTypes } from './constants';

const CustomizedScreenSelector = ({
  match,

  taxYear,
  period,
  orgId,
  jurisdictionId,
  isFetchingContext,

  isContextReady,
  customizedScreen,
  isFetchingCustomizedScreen,
  namespace,

  GenericScreenComponent,
  ComponentScreenComponent,
  SourceSystemDataScreenComponent,
  CustomScreenComponent,

  hasUserPermissionsToEdit,
}) => {
  const { url, isExact, params } = match;
  const { categoryId, accountId } = params;

  const ConnectedCustomizedScreenForAmount = useCallback(
    props => (
      <CustomizedScreenForAmount
        orgId={orgId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        fetchHook={useCustomizedScreen}
        GenericScreenComponent={GenericScreenComponent}
        ComponentScreenComponent={ComponentScreenComponent}
        SourceSystemDataScreenComponent={SourceSystemDataScreenComponent}
        {...props}
      />
    ),
    [
      orgId,
      jurisdictionId,
      isFetchingContext,
      GenericScreenComponent,
      ComponentScreenComponent,
      SourceSystemDataScreenComponent,
    ],
  );

  const ConnectedCustomizedScreenForComponentDrill = useCallback(
    props => (
      <CustomizedScreenForComponentDrill
        orgId={orgId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        categoryId={categoryId}
        accountId={accountId}
        {...props}
      />
    ),
    [orgId, jurisdictionId, isFetchingContext, categoryId, accountId],
  );

  if (isExact) {
    const isLoading = isFetchingContext || isFetchingCustomizedScreen;

    if (isLoading) {
      return (
        <div className="grid-row">
          <div className="col">
            <Loading isLoading />
          </div>
        </div>
      );
    }

    if (!isContextReady || !customizedScreen.type) {
      return <SelectContextDataInfo />;
    }

    switch (customizedScreen.type) {
      case screenTypes.GENERIC:
        return (
          <GenericScreenComponent
            match={match}
            orgId={orgId}
            jurisdictionId={jurisdictionId}
            isFetchingContext={isFetchingContext}
            hasUserPermissionsToEdit={hasUserPermissionsToEdit}
          />
        );
      case screenTypes.COMPONENT:
        return (
          <ComponentScreenComponent
            match={match}
            orgId={orgId}
            jurisdictionId={jurisdictionId}
            isFetchingContext={isFetchingContext}
          />
        );
      case screenTypes.SOURCE_SYSTEM_DATA:
        return (
          <SourceSystemDataScreenComponent
            match={match}
            orgId={orgId}
            jurisdictionId={jurisdictionId}
            isFetchingContext={isFetchingContext}
            categoryId={categoryId}
            accountId={accountId}
            componentId={orgId}
          />
        );
      case screenTypes.CUSTOM_SCREEN:
        return (
          <CustomScreenComponent
            match={match}
            taxYear={taxYear}
            period={period}
            entityId={orgId}
            jurisdictionId={jurisdictionId}
            customScreenName={customizedScreen.customScreenName}
            hasUserPermissionsToEdit={hasUserPermissionsToEdit}
          />
        );
      case screenTypes.RETURN_TO_INCOME_TAX_SUMMARY:
        return (
          <Redirect
            to={Routes[`${namespace}TaxSummaries`].compiledRoute({ taxName: taxNames.INCOME_TAX })}
          />
        );
      default:
        return <ManualAccountsGrid manualAccounts={customizedScreen.manualAccounts} />;
    }
  }

  return (
    <Switch>
      <AuthorizedRoute
        exact
        path={`${url}/component/:componentId/`}
        render={ConnectedCustomizedScreenForComponentDrill}
      />
      <AuthorizedRoute
        path={`${url}/category/:categoryId/account/:accountId`}
        render={ConnectedCustomizedScreenForAmount}
      />
    </Switch>
  );
};

CustomizedScreenSelector.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      accountId: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
    isExact: PropTypes.bool.isRequired,
  }).isRequired,

  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool.isRequired,
  namespace: PropTypes.string,

  isContextReady: PropTypes.bool.isRequired,
  customizedScreen: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    customScreenName: PropTypes.string,
    manualAccounts: manualAccountsPropType,
  }).isRequired,
  isFetchingCustomizedScreen: PropTypes.bool.isRequired,

  GenericScreenComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  ComponentScreenComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  SourceSystemDataScreenComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  CustomScreenComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

const CustomizedScreen = ({
  match,

  taxYear,
  period,
  orgId,
  jurisdictionId,
  isFetchingContext,

  ...props
}) => {
  const { params } = match;
  const { categoryId, accountId } = params;
  const isContextReady = Boolean(
    taxYear && period && orgId && jurisdictionId && categoryId && accountId,
  );

  const { customizedScreen, isFetchingCustomizedScreen } = useCustomizedScreen({
    taxYear,
    period,
    orgId,
    jurisdictionId,
    categoryId,
    accountId,
  });

  return (
    <CustomizedScreenSelector
      match={match}
      orgId={orgId}
      jurisdictionId={jurisdictionId}
      isFetchingContext={isFetchingContext}
      isContextReady={isContextReady}
      customizedScreen={customizedScreen}
      isFetchingCustomizedScreen={isFetchingCustomizedScreen}
      taxYear={taxYear}
      period={period}
      {...props}
    />
  );
};

CustomizedScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      accountId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool,
};

const CustomizedScreenForAmount = connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
  }),
  null,
)(CustomizedScreen);

export default CustomizedScreenForAmount;
