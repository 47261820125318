import { configureStore } from '@reduxjs/toolkit';
import { autoRehydrate } from 'redux-phoenix';
import { reducer as agGridReducer } from '@tls/ui-aggrid';
import { reducer as modalsReducer } from '@tls/ui-modal';

import sharedReducer from './shared/store/reducer';
import notificationReducer from './shared/notification/store/reducer';
import homePageReducer from './homePage/store/reducer';
import entityReducer from './entity/store/reducer';
import adminReducer from './admin/store/reducer';
import tasksReducer from './tasks/store/reducer';
import consolidationReducer from './consolidation/store/reducer';
import stateFilingGroupsReducer from './stateFilingGroups/store/reducer';
import federalProFormaFilingGroupsReducer from './federalProFormaFilingGroups/store/reducer';
import developmentReducer from './development/store/reducer';
import reportReducer from './reports/store/reducer';
import toolsReducer from './tools/store/reducer';
import customScreenReducer from './customScreen/store/reducer';
import eFileReducer from './eFile/store/reducer';
import taxSummariesReducer from './taxSummaries/store/reducer';
import binderMaintenanceReducer from './binderMaintenance/store/reducer';
import federalProformaReducer from './federalProforma/store/reducer';
import jobsReducer from './jobs/store/reducer';
import flowThroughK1DetailsReducer from './flowThroughK1Details/store/reducer';
import filingAttributesReducer from './filingAttributes/store/reducer';
import filingStatesReducer from './filingStates/store/reducer';
import filingDecisionsReducer from './filingDecisions/store/reducer';
import filingDecisionsV2Reducer from './filingDecisionsV2/store/reducer';
import workpaperInstanceReducer from './workpaperInstance/store/reducer';
import authReducer from './shared/store/auth';
import contextReducer from './shared/store/context';
import filingGroupsReducer from './filingGroups/store';
import navigatorReducer from './navigator/store';
import eFileReducerV2 from './eFile/store';

const store = configureStore({
  reducer: {
    auth: authReducer,
    context: contextReducer,
    filingGroups: filingGroupsReducer,
    shared: sharedReducer,
    modals: modalsReducer,
    agGrid: agGridReducer,
    notification: notificationReducer,
    admin: adminReducer,
    homePage: homePageReducer,
    entity: entityReducer,
    tasks: tasksReducer,
    stateFilingGroups: stateFilingGroupsReducer,
    federalProFormaFilingGroups: federalProFormaFilingGroupsReducer,
    consolidation: consolidationReducer,
    development: developmentReducer,
    reports: reportReducer,
    tools: toolsReducer,
    customScreen: customScreenReducer,
    eFile: eFileReducer,
    binderMaintenance: binderMaintenanceReducer,
    federalProforma: federalProformaReducer,
    taxSummaries: taxSummariesReducer,
    jobs: jobsReducer,
    flowThroughK1Details: flowThroughK1DetailsReducer,
    filingAttributes: filingAttributesReducer,
    filingStates: filingStatesReducer,
    filingDecisions: filingDecisionsReducer,
    filingDecisionsV2: filingDecisionsV2Reducer,
    workpaperInstance: workpaperInstanceReducer,
    navigator: navigatorReducer,
    eFileV2: eFileReducerV2,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // disabling both checks until we are done with redux refactor
      // TODO: SLT-6348 enable both checks after complete redux refactor
      immutableCheck: false,
      serializableCheck: false,
    }),
  enhancers: [autoRehydrate],
});

export default store;
