import React from 'react';
import { CalcPreferenceDataTypes } from '@common-packages/shared-constants';

import SDKCustomSelect from '../sdkCustomSelect/formikSdkCustomSelect.component';
import {
  CalcPreferenceDefinitions,
  CalcPreferencesFormikValues,
} from '../../../../../common/types';

import styles from './calcPreferences.module.scss';
import NumericCalcPreference from './numericCalcPreference/numericCalcPreference.component';

const valueSelectOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];

const getSelectOptions = (calcPrefsOptionsWithJurisdictionContext: CalcPreferenceDefinitions) => {
  // 'BOOLEAN' datatypes from t_slt_calc_options table (that are grouped) only have one
  // row/array (versus a 'SELECTION_LIST' that can have 1+)
  // We return these generic options (valueSelectOptions) when that's the case
  if (calcPrefsOptionsWithJurisdictionContext[0]?.dataType === CalcPreferenceDataTypes.BOOLEAN) {
    return valueSelectOptions;
  }

  return calcPrefsOptionsWithJurisdictionContext.map(({ name, displayName }) => ({
    label: displayName,
    value: name,
  }));
};

const SelectPreferences = ({
  options,
  columnLabel,
  values,
  name,
  jurisdictionId,
  calcOptionsKey = '',
  customChangeHandler = () => null,
}: {
  options: CalcPreferenceDefinitions;
  columnLabel: string;
  values: CalcPreferencesFormikValues;
  name: string;
  jurisdictionId: string;
  calcOptionsKey?: string;
  customChangeHandler?: (name: string) => unknown;
}) => {
  const calcPrefsOptionsWithJurisdictionContext = options.filter(
    option => option.jurisdictionId === jurisdictionId,
  );

  if (
    calcPrefsOptionsWithJurisdictionContext[0]?.dataType === CalcPreferenceDataTypes.NUMBER &&
    calcOptionsKey
  ) {
    const label = calcPrefsOptionsWithJurisdictionContext[0]?.displayGroup;

    return (
      <NumericCalcPreference
        label={label || ''}
        name={`${calcOptionsKey}.${label}`}
        optionsKey={calcOptionsKey}
        className={styles.numericCalcPreferences}
      />
    );
  }

  return (
    <SDKCustomSelect
      hasTooltip
      wrapperClassName={styles.calcPreferenecesSelect}
      key={columnLabel}
      appkitLabel={columnLabel}
      name={name}
      value={values[columnLabel]}
      options={getSelectOptions(calcPrefsOptionsWithJurisdictionContext)}
      virtualized
      customChangeHandler={customChangeHandler}
    />
  );
};

export default SelectPreferences;
