import { InputDataKeys } from '../../constants';

interface GetInputValueKeyParams {
  isOverridden: boolean;
  isEditable: boolean;
}

export const getInputValueKey = ({ isOverridden }: GetInputValueKeyParams) => {
  return isOverridden ? InputDataKeys.OverrideValue : InputDataKeys.DefaultValue;
};
