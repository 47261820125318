const Yup = require('yup');

const SELECTION_LIST_DETAILS_MAX_LEN = 100;

const getUpdateSelectionListSchema = ({ selectionLists = [], isEditMode = false, id = '' }) =>
  Yup.object().shape({
    name: Yup.string()
      .label('Name')
      .max(
        SELECTION_LIST_DETAILS_MAX_LEN,
        `Name cannot exceed ${SELECTION_LIST_DETAILS_MAX_LEN} characters`,
      )
      .uniqueInList({
        message: 'Selection list Name already exists, a unique Name is required before saving',
        list: isEditMode ? selectionLists?.filter(list => list.id !== id) : selectionLists,
        mapper: ({ name }) => name,
      })
      .required(),
    description: Yup.string()
      .label('Description')
      .min(0)
      .max(
        SELECTION_LIST_DETAILS_MAX_LEN,
        `Description cannot exceed ${SELECTION_LIST_DETAILS_MAX_LEN} characters`,
      )
      .nullable(),
  });

const getCreateSelectionListSchema = ({ selectionLists = [] }) =>
  getUpdateSelectionListSchema({ selectionLists }).concat(
    Yup.object().shape({
      taxYear: Yup.string().required(),
      jurisdictionId: Yup.string().required(),
    }),
  );

module.exports = { getCreateSelectionListSchema, getUpdateSelectionListSchema };
