import { Routes, taxNames } from '@common-packages/routes-definitions';

import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';
import { amountFormatter } from '../../shared/formatters';
import { filingTypes } from '../../shared/constants';
import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';
import {
  HeaderLinkRenderer,
  ReportToggleRenderer,
} from '../../shared/columnDefinitions/cellRenderers';

import {
  ENTITY_ID,
  ENTITY_NAME,
  ENTITY_EIN,
  ORG,
  DisplayLevelTypes,
  LinesToFollowTypes,
} from './constants';
import DrillLinkCellRenderer from './drillLinkCellRenderer.component';

const descriptionCellClassRules = {
  'bold-cell': ({ data }) => data.displayLevel === DisplayLevelTypes.HEADER,
  'cell-with-indent': ({ data }) => data.indentLevel > 0,
};

const amountCellClassRules = {
  'underlined-cell': ({ data }) => data.linesToFollow === LinesToFollowTypes.SINGLE,
  'double-underlined-cell underlined-cell-top': ({ data }) =>
    data.linesToFollow === LinesToFollowTypes.TOTAL,
};
const valueFormatter = props => {
  if (props.data.displayLevel === DisplayLevelTypes.HEADER) {
    return '';
  }

  if (props.data.isEmptyRow) {
    return '';
  }

  switch (props.data.dataFormat) {
    case 'PERCENT':
      return amountFormatter({
        value: props.value,
        props: {
          ...props,
          percentage: true,
          decimalPlaces: props.data.dataDecimalPlaces,
        },
      });
    default:
      return currencyColumnDefinition.valueFormatter(props);
  }
};

const getOrgHeaderName = ({ entityDisplayType, orgId, orgName, fein }) =>
  new Map([
    [ENTITY_ID, orgId],
    [ENTITY_NAME, orgName],
    [ENTITY_EIN, fein],
  ]).get(entityDisplayType);

const getColumnDefinitions = ({
  columnsBlueprint,
  entityDisplayType,
  taxYear,
  period,
  jurisdictionId,
}) => {
  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
      width: 500,
      pinned: 'left',
      lockPinned: true,
      cellClassRules: { ...descriptionCellClassRules },
      sortable: false,
      cellRenderer: ReportToggleRenderer,
    },
    ...columnsBlueprint.map(({ field, orgId, orgName, fein, defaultHeaderName, columnType }) => {
      const isOrg = columnType === ORG;

      const entityHeaderName = isOrg
        ? getOrgHeaderName({
            entityDisplayType,
            orgId,
            orgName,
            fein,
          })
        : defaultHeaderName;

      return {
        ...defaultColumnDefinition,
        ...currencyColumnDefinition,
        headerName: entityHeaderName,
        ...(isOrg
          ? {
              headerComponent: HeaderLinkRenderer,
              headerComponentParams: {
                linkProps: {
                  pathname: Routes.taxSummaries.compiledRoute({
                    ...getContextAwareUrlParams({
                      taxYear,
                      period,
                      filingTypeId: filingTypes.SEPARATE,
                      businessEntityId: orgId,
                      jurisdictionId,
                      taxName: taxNames.INCOME_TAX,
                    }),
                  }),
                },
              },
            }
          : {}),
        valueFormatter,
        field,
        cellClassRules: { ...amountCellClassRules },
        valueGetter: ({ data, colDef }) => data[colDef.field] && data[colDef.field].amount,
        sortable: false,
        cellRenderer: DrillLinkCellRenderer,
        cellRendererParams: {
          isOrg,
          orgId,
          taxYear,
          period,
          jurisdictionId,
        },
      };
    }),
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
