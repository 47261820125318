import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { REPORT_TYPES } from '../../shared/constants';

import { fetchDataVisualizations } from './store/actions';
import {
  dataVisualizationsSelector,
  isFetchingdataVisualizationsSelector,
} from './store/selectors';
import columnDefinitions from './runDataVisualization.columnDefinition';

const RunDataVisualization = ({
  dataVisualizations,
  isFetchingDataVisualizations,
  fetchDataVisualizations,
}) => {
  useEffect(() => {
    fetchDataVisualizations({
      reportType: REPORT_TYPES.CHART,
    });
  }, [fetchDataVisualizations]);

  return (
    <>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={dataVisualizations}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingDataVisualizations}
          />
        </div>
      </div>
    </>
  );
};

RunDataVisualization.propTypes = {
  dataVisualizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  isFetchingDataVisualizations: PropTypes.bool.isRequired,
  fetchDataVisualizations: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    dataVisualizations: dataVisualizationsSelector(state),
    isFetchingDataVisualizations: isFetchingdataVisualizationsSelector(state),
  }),
  {
    fetchDataVisualizations,
  },
)(RunDataVisualization);
