// TO-DO: SLT-10878 move to XStore
import { createSlice } from '@reduxjs/toolkit';

import { EFileStepStatusData } from '../../../../common/types';

interface EFileV2 {
  eFileV2: {
    stepStatusData: EFileStepStatusData | null;
  };
}

const eFileV2Slice = createSlice({
  name: 'eFileV2',
  initialState: {
    stepStatusData: null,
  },
  reducers: {
    setStepStatusData(state, { payload: { stepStatusData } }) {
      state.stepStatusData = stepStatusData;
    },
  },
});

const { actions, reducer } = eFileV2Slice;

export const { setStepStatusData } = actions;

export const stepStatusSelector = ({ eFileV2 }: EFileV2) => eFileV2.stepStatusData?.status;

export default reducer;
