import {
  defaultColumnDefinitionWithFilter,
  centeredColumnDefinition,
} from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

const columnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Account Id',
    field: 'infoAccountId',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Information Type',
    field: 'infoType',
    width: 200,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Description',
    field: 'infoAccountDescription',
    width: 300,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...centeredColumnDefinition,
    headerName: 'Active',
    field: 'activeYn',
    cellRenderer: EditableCheckboxCellRenderer,
    cellRendererParams: {
      disabled: true,
    },
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...centeredColumnDefinition,
    headerName: 'Global Ind',
    field: 'globalInd',
    cellRenderer: EditableCheckboxCellRenderer,
    cellRendererParams: {
      disabled: true,
    },
  },
  {
    ...defaultColumnDefinitionWithFilter,
    ...centeredColumnDefinition,
    headerName: 'Pship Global Ind',
    field: 'pshipGlobalInd',
    cellRenderer: EditableCheckboxCellRenderer,
    cellRendererParams: {
      disabled: true,
    },
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Data Type',
    field: 'dataType',
  },
];

export default columnDefinitions;
