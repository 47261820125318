export const SET_ACTIVE_TAB = 'DEVELOPMENT/SET_ACTIVE_TAB_EXPRESSION_BUILDER';
export const RESET_ACTIVE_TAB = 'DEVELOPMENT/RESET_ACTIVE_TAB_EXPRESSION_BUILDER';
export const SET_ACTIVE_FORM_DATA_ITEM_ID = 'DEVELOPMENT/SET_ACTIVE_FORM_DATA_ITEM_ID';
export const SET_SPLIT_PANE_LEFT_WIDTH = 'DEVELOPMENT/SET_SPLIT_PANE_LEFT_WIDTH';
export const SET_IS_IGNORING_CALCULATION_DATA_CHANGES =
  'DEVELOPMENT/SET_IS_IGNORING_CALCULATION_DATA_CHANGES';
export const SELECT_EXPRESSION_BUILDER_DATA_ITEM =
  'DEVELOPMENT/SELECT_EXPRESSION_BUILDER_DATA_ITEM';
export const SELECT_EXPRESSION_BUILDER_DATASET = 'DEVELOPMENT/SELECT_EXPRESSION_BUILDER_DATASET';
export const SELECT_EXPRESSION_BUILDER_DATA_MODEL =
  'DEVELOPMENT/SELECT_EXPRESSION_BUILDER_DATA_MODEL';
