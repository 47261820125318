import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import {
  fetchWorkpaperInstanceDataTypes,
  updateWorkpaperInstanceTypes,
  fetchDatasetDefinitionMetaDataTypes,
} from './types';

export const fetchWorkpaperInstanceData = apiAction({
  types: fetchWorkpaperInstanceDataTypes,
  apiAction: api.fetchWorkpaperInstanceData,
});

export const updateWorkpaperInstance = apiAction(
  {
    types: updateWorkpaperInstanceTypes,
    apiAction: api.updateWorkpaperInstance,
  },
  {
    throwApiErrors: true,
    successNotificationMessage: () => 'Workpaper instance updated successfully',
  },
);

export const fetchDatasetDefinitionMetaData = apiAction({
  types: fetchDatasetDefinitionMetaDataTypes,
  apiAction: api.fetchDatasetDefinitionMetaData,
});
