const StepStatuses = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
};

const ValidationStatuses = {
  FAILED: 'Failed',
  COMPLETED: 'Completed',
};

const FederalAttachmentOptionValues = {
  SINGLE_ENTITY_AS_FILED: 'single_entity_as_filed',
  CONSOL_PRO_FORMA_FED: 'consol_pro_forma_fed',
  CONSOL_AS_FILED_FED: 'consol_as_filed_fed',
  SINGLE_ENTITY_AS_FILED_FED: 'single_entity_as_filed_fed',
};

const AcknowledgementTypes = {
  TEXAS: 'TXSubmissionStatus',
  CALIFORNIA: 'CA-SubmissionAcknowledgement',
};

const TexasStatusCodes = {
  '0000': 'Pending',
  1000: '1000: Packet Processed - Accepted',
  2010: 'Packet Processed with Attachment Errors Found',
  2020: 'Packet Processed with Payment Errors Found',
  2030: 'Packet Rejected with Errors in both an Attachment and Payment',
  3010: 'Packet Rejected with errors in return and/or attachment(s)',
  3020: 'Packet Rejected with errors in return and/or attachment and/or payment',
  4010: 'Packet Rejected with technical issues',
  9999: 'Submission Not Found',
};

module.exports = {
  StepStatuses,
  ValidationStatuses,
  FederalAttachmentOptionValues,
  AcknowledgementTypes,
  TexasStatusCodes,
};
