import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

const WarningEditDatasetModal = ({
  hideModal,
  visible,
  warningMessage,
  title,
  submitAction,
  submitText = 'Continue',
  dismissText = 'Cancel',
}) => {
  const submitAndClose = useCallback(() => {
    submitAction();
    hideModal();
  }, [submitAction, hideModal]);

  return (
    <Modal
      title={title}
      submitText={submitText}
      dismissText={dismissText}
      visible={visible}
      dismissAction={hideModal}
      closeAction={hideModal}
      submitAction={submitAndClose}
    >
      {warningMessage()}
    </Modal>
  );
};

WarningEditDatasetModal.propTypes = {
  submitAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  warningMessage: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  submitText: PropTypes.string,
  dismissText: PropTypes.string,
};

export default WarningEditDatasetModal;
