import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Routes, taxSummariesMainNames, taxNames } from '@common-packages/routes-definitions';
import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

import withPermissions from '../shared/authorization/withPermissionsHoc.container';
import { customerPermissionsPropTypes } from '../shared/propTypes/customerPropTypes';
import {
  ActionMenu,
  ActionMenuItem,
} from '../shared/displayComponents/actionMenu/actionMenu.component';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import SelectContextDataInfo from '../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import {
  taxYearOptionsSelector,
  isEntityLockedSelector,
  isFetchingEntityLockIndicatorSelector,
  jurisdictionDescriptionSelector,
} from '../shared/store/selectors';
import {
  performEntityDataCalculation,
  fetchLockIndicator,
  initiateCalculationAllEntitiesForJurisdiction,
} from '../shared/store/actions';
import { refreshFederalProFormaData } from '../shared/store/federalRefresh/actions';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import Loading from '../shared/displayComponents/loading.component';
import { appendEmptyRowsAfterEachSection } from '../sharedSubPages/returnWorkspace/utils';
import globalContextPropTypes from '../shared/propTypes/globalContext';

import {
  taxSummariesSelector,
  estimatedTaxSummarySelector,
  isFetchingTaxSummariesSelector,
} from './store/selectors';
import {
  fetchIncomeTaxSummary,
  fetchFranchiseTaxSummary,
  fetchEstimatedTaxSummary,
} from './store/actions';
import getIncomeFranchiseColumnDefinitions from './incomeFranchiseTaxSummaries.columnDefinitions';
import getEstimatedColumnDefinitions from './estimatedTaxSummaries.columnDefinitions';
import styles from './taxSummaries.module.scss';

const TaxSummaries = ({ globalContext, hasUserPermissionsToEdit, customerPermissions }) => {
  const dispatch = useDispatch();

  const taxYears = useSelector(taxYearOptionsSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);

  const taxSummaries = useSelector(taxSummariesSelector);
  const isFetchingTaxSummaries = useSelector(isFetchingTaxSummariesSelector);
  const estimatedTaxSummary = useSelector(estimatedTaxSummarySelector);

  const isEntityLocked = useSelector(isEntityLockedSelector);
  const isFetchingEntityLockIndicator = useSelector(isFetchingEntityLockIndicatorSelector);

  const { taxYear, period, businessEntityId: orgId, jurisdictionId } = globalContext.params;
  const isContextReady = Boolean(taxYear && period && orgId && jurisdictionId);

  const { params } = useRouteMatch();
  const { taxName, businessEntityId, filingTypeId } = useMemo(() => ({ ...params }), [params]);

  const fetchFunctions = {
    [taxNames.INCOME_TAX]: fetchIncomeTaxSummary,
    [taxNames.FRANCHISE_TAX]: fetchFranchiseTaxSummary,
    [taxNames.ESTIMATED_TAX]: fetchEstimatedTaxSummary,
  };
  const fetchTaxSummary = fetchFunctions[taxName];

  const hasPermissionToCalculateEntityData = useMemo(
    () =>
      hasPermissionToAccess({
        customerPermissions,
        permissionType: PERMISSION_TYPE.EDIT,
        routeDefinitions: [PERMISSION.ENTITY_DATA_CALCULATION],
      }),
    [customerPermissions],
  );

  useEffect(() => {
    if (!isContextReady || !fetchTaxSummary) {
      return;
    }
    dispatch(fetchTaxSummary({ taxYear, period, orgId, jurisdictionId }));
  }, [dispatch, fetchTaxSummary, isContextReady, taxYear, period, orgId, jurisdictionId, taxName]);

  useEffect(() => {
    if (globalContext.isSeparate && isContextReady) {
      dispatch(fetchLockIndicator({ taxYear, period, orgId, jurisdictionId }));
    }
  }, [dispatch, isContextReady, taxYear, period, orgId, jurisdictionId, globalContext.isSeparate]);

  const incomeFranchiseColumnDefinitions = useMemo(
    () =>
      getIncomeFranchiseColumnDefinitions({
        urlParams: { businessEntityId, filingTypeId, jurisdictionId, period, taxName, taxYear },
        taxYear,
        taxYears,
      }),
    [taxYear, taxYears, businessEntityId, filingTypeId, jurisdictionId, period, taxName],
  );

  const estimatedColumnDefinitions = useMemo(
    () =>
      getEstimatedColumnDefinitions({
        urlParams: { businessEntityId, filingTypeId, jurisdictionId, period, taxName, taxYear },
        taxYear,
      }),
    [taxYear, businessEntityId, filingTypeId, jurisdictionId, period, taxName],
  );

  const rowData = useMemo(
    () =>
      appendEmptyRowsAfterEachSection(
        taxName === taxNames.ESTIMATED_TAX ? estimatedTaxSummary : taxSummaries,
        'isSummaryRow',
      ),
    [taxName, estimatedTaxSummary, taxSummaries],
  );

  const onEntityDataCalculationClick = useCallback(
    () =>
      dispatch(
        showConfirmModal({
          title: 'Calculate Entity Data',
          text: 'Calculate the current entity in context?',
          confirmCallback: async () =>
            await dispatch(
              performEntityDataCalculation({
                taxYear,
                period,
                jurisdictionId,
                orgId,
              }),
            ),
        }),
      ),
    [dispatch, taxYear, period, orgId, jurisdictionId],
  );

  const onCalculateAllEntitiesForJurisdiction = useCallback(
    () =>
      dispatch(
        showConfirmModal({
          title: 'Calculate All Entities For Jurisdiction',
          text: `Calculating time is dependent on number of entities in the jurisdiction. Perform calculation for all entities for ${jurisdictionDescription} ?`,
          confirmCallback: () =>
            dispatch(
              initiateCalculationAllEntitiesForJurisdiction({
                taxYear,
                period,
                orgId,
                jurisdictionId,
              }),
            ),
        }),
      ),
    [dispatch, jurisdictionDescription, jurisdictionId, orgId, period, taxYear],
  );

  const onFederalRefreshClick = useCallback(
    () =>
      dispatch(
        showConfirmModal({
          title: 'Perform calculation',
          text: 'Perform Refresh Federal Data?',
          confirmCallback: async () =>
            await dispatch(
              refreshFederalProFormaData({
                taxYear,
                period,
                jurisdictionId,
                orgId,
              }),
            ),
        }),
      ),
    [dispatch, orgId, period, jurisdictionId, taxYear],
  );

  const isLoading = isFetchingTaxSummaries;
  const isLoadingDataForActionMenu = isLoading || isFetchingEntityLockIndicator;
  const locked = globalContext.isSeparate && isEntityLocked;

  if (!taxSummariesMainNames[taxName]) {
    return <Redirect to={Routes.filingStates.compiledRoute(globalContext.params)} />;
  }

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }
  return (
    <div className="grid-borderless row grid-row">
      <div className={`col-12 ${styles.gridContainer}`}>
        {hasUserPermissionsToEdit && taxName !== taxNames.ESTIMATED_TAX && (
          <div className={`row ${styles.actionMenuRow}`}>
            <Loading isLoading={isLoadingDataForActionMenu} small>
              <ActionMenu>
                {hasPermissionToCalculateEntityData && (
                  <ActionMenuItem
                    onClick={onEntityDataCalculationClick}
                    text="Calculate Entity Data"
                    locked={locked}
                  />
                )}
                <ActionMenuItem
                  onClick={onCalculateAllEntitiesForJurisdiction}
                  text="Calculate All Entities for Jurisdiction"
                  locked={locked}
                />
                <ActionMenuItem
                  onClick={onFederalRefreshClick}
                  text="Refresh Federal Data"
                  locked={locked}
                />
              </ActionMenu>
            </Loading>
          </div>
        )}
        <div className={`row ${styles.grid}`}>
          <div className="col">
            <AgGrid
              isGridLoading={isLoading}
              columnDefs={
                taxName === taxNames.ESTIMATED_TAX
                  ? estimatedColumnDefinitions
                  : incomeFranchiseColumnDefinitions
              }
              rowData={rowData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TaxSummaries.propTypes = {
  customerPermissions: customerPermissionsPropTypes,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
  globalContext: globalContextPropTypes,
};

export default withPermissions(TaxSummaries);
