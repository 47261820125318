import React from 'react';
import PropTypes from 'prop-types';

import CustomReport from './CustomReport';

const ViewCustomReport = ({ match }) => {
  const { reportId, reportType } = match.params;

  return <CustomReport reportId={reportId} reportType={reportType} />;
};

ViewCustomReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      objectId: PropTypes.string,
      reportId: PropTypes.string,
      reportType: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ViewCustomReport;
