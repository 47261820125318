import { useEffect } from 'react';
import { genericFunctionsDataTypes } from '@common-packages/shared-constants';

import { parameterTypeNames } from './constants';

export const preparePayloadForCreateOrUpdateAction = ({
  taxYear,
  period,
  newGenericFunctionObject,
  rows,
}) => {
  const parameters = rows.map(parameter => ({
    dataType: parameter.dataType,
    parameterType: parameter.parameterType,
    accountId: parameter.accountId,
    categoryId: parameter.categoryId,
    otherId: parameter.otherId,
    multiplier: parameter.multiplier,
    roundPlaces: parameter.roundPlaces,
    runLevel: parameter.runLevel,
    jurisdictionId: parameter.jurisdictionId,
    parameterId: parameter.parameterId,
  }));
  const payload = {
    taxYear,
    period,
    functionId: newGenericFunctionObject.functionId,
    description: newGenericFunctionObject.description,
    functionType: newGenericFunctionObject.functionType,
    operationType: newGenericFunctionObject.operationType,
    parameters,
  };
  return payload;
};

export const getNewRowDefaultValues = ({ dataTypesOptions, parameterTypes }) => ({
  accountId: null,
  categoryId: null,
  dataType: dataTypesOptions[0] && dataTypesOptions[0].value,
  multiplier: 1,
  otherId: null,
  parameterId: '-',
  parameterType: parameterTypes[0].value,
  roundPlaces: 0,
  runLevel: parameterTypes[0].value === parameterTypeNames.DESTINATION ? 100 : null,
});

export const getFormInitialValues = ({ isEditingExistingFunction, genericFunction }) =>
  isEditingExistingFunction
    ? {
        functionId: genericFunction.functionId,
        description: genericFunction.description,
        functionType: genericFunction.functionType,
        operationType: genericFunction.operationType,
      }
    : {
        functionId: '',
        description: '',
        functionType: null,
        operationType: null,
      };

export const useGenericFunctionData = ({
  taxYear,
  period,
  genericFunction,
  isEditingExistingFunction,
  fetchGenericFunctionParams,
  fetchDataTypes,
  fetchCategories,
  fetchAccounts,
  fetchJurisdictions,
}) => {
  useEffect(() => {
    const isContextReady = taxYear && period && genericFunction && isEditingExistingFunction;

    if (isContextReady) {
      fetchGenericFunctionParams({
        taxYear,
        period,
        functionType: genericFunction.functionType,
        functionId: genericFunction.functionId,
      });
    }
  }, [fetchGenericFunctionParams, taxYear, period, genericFunction, isEditingExistingFunction]);

  useEffect(() => {
    fetchDataTypes();
  }, [fetchDataTypes]);

  useEffect(() => {
    if (taxYear) {
      fetchCategories({ taxYear });
      fetchAccounts({ taxYear });
      fetchJurisdictions({ taxYear });
    }
  }, [fetchJurisdictions, fetchCategories, fetchAccounts, taxYear]);
};

export const dataModelTypeLogic = ({
  constantsOptions = [],
  categoriesOptions = [],
  accountsOptions = [],
  jurisdictionsOptions = [],
  taxYear = '',
  period = '',
} = {}) => {
  const isJurisdictionEditable = ({ node }) =>
    node.data.dataType === genericFunctionsDataTypes.DATA_MODEL;
  const onJurisdictionChanged = fetchDatasets => async ({ data, newValue }) => {
    if (taxYear && newValue && data.dataType === genericFunctionsDataTypes.DATA_MODEL) {
      const categoryIds = await fetchDatasets({ taxYear, jurisdictionId: newValue });
      if (categoryIds.some(({ name }) => name === data.categoryId)) {
        return data;
      }
    }

    return {
      ...data,
      categoryId: null,
    };
  };

  const onDataTypeChanged = ({ data, oldValue, newValue: dataType }) => {
    if (dataType === oldValue) {
      return data;
    }

    return {
      ...data,
      accountId: null,
      categoryId: null,
      jurisdictionId:
        dataType === genericFunctionsDataTypes.DATA_MODEL
          ? (jurisdictionsOptions[0] || { value: null }).value
          : null,
      otherId: null,
    };
  };

  const isCategoryEditable = ({ node }) => {
    if (node.data.dataType === genericFunctionsDataTypes.DATA_MODEL) {
      return Boolean(node.data.jurisdictionId);
    }

    return (
      node.data.dataType !== genericFunctionsDataTypes.CONSOL_CONSTANT &&
      node.data.dataType !== genericFunctionsDataTypes.CONSTANT
    );
  };

  const categoryRenderer = ({ value, node }) => {
    if (node.data.dataType === genericFunctionsDataTypes.DATA_MODEL) {
      return node.data.jurisdictionId ? value : 'select jurisdiction';
    }

    return value;
  };

  const onCategoryChanged = fetchDataItems => async ({ data, newValue }) => {
    if (data.dataType !== genericFunctionsDataTypes.DATA_MODEL) {
      return data;
    }

    if (taxYear && newValue && data.dataType === genericFunctionsDataTypes.DATA_MODEL) {
      const accountIds = await fetchDataItems({
        taxYear,
        jurisdictionId: data.jurisdictionId,
        datasetName: newValue,
      });
      if (accountIds.some(({ name }) => name === data.accountId)) {
        return data;
      }
    }

    return {
      ...data,
      accountId: null,
    };
  };

  const categoryEditorParamsFactory = ({ node }) => {
    const defaultCategoryEditorParams = {
      defaultErrorMessage: 'Fetching category datasets error',
    };

    if (node.data.dataType === genericFunctionsDataTypes.DATA_MODEL) {
      return {
        ...defaultCategoryEditorParams,
        jurisdictionId: node.data.jurisdictionId,
        taxYear,
      };
    }

    return {
      ...defaultCategoryEditorParams,
      options: categoriesOptions,
    };
  };

  const isAccountEditable = ({ node }) => {
    if (node.data.dataType === genericFunctionsDataTypes.DATA_MODEL) {
      return Boolean(node.data.categoryId);
    }

    return (
      node.data.dataType !== genericFunctionsDataTypes.CONSOL_CONSTANT &&
      node.data.dataType !== genericFunctionsDataTypes.CONSTANT
    );
  };

  const accountRenderer = ({ value, node }) => {
    if (node.data.dataType === genericFunctionsDataTypes.DATA_MODEL) {
      return node.data.categoryId ? value : 'select category';
    }

    return value;
  };

  const accountEditorParamsFactory = ({ node }) => {
    const defaultAccountEditorParams = {
      defaultErrorMessage: 'Fetching account data items error',
    };

    if (node.data.dataType === genericFunctionsDataTypes.DATA_MODEL) {
      return {
        ...defaultAccountEditorParams,
        datasetName: node.data.categoryId,
        jurisdictionId: node.data.jurisdictionId,
        taxYear,
        period,
      };
    }

    return {
      ...defaultAccountEditorParams,
      options: accountsOptions,
    };
  };

  const isConstantEditable = ({ node }) =>
    node.data.dataType === genericFunctionsDataTypes.CONSOL_CONSTANT ||
    node.data.dataType === genericFunctionsDataTypes.CONSTANT;

  const constantEditorParamsFactory = ({ node }) => {
    const defaultAccountEditorParams = {
      defaultErrorMessage: 'Fetching constants error',
    };

    if (
      node.data.dataType === genericFunctionsDataTypes.CONSOL_CONSTANT ||
      node.data.dataType === genericFunctionsDataTypes.CONSTANT
    ) {
      return {
        ...defaultAccountEditorParams,
        dataType: node.data.dataType,
        taxYear,
        period,
      };
    }

    return {
      ...defaultAccountEditorParams,
      options: constantsOptions,
    };
  };

  return {
    isJurisdictionEditable,
    onJurisdictionChanged,

    onDataTypeChanged,

    isCategoryEditable,
    categoryRenderer,
    onCategoryChanged,
    categoryEditorParamsFactory,

    isAccountEditable,
    accountRenderer,
    accountEditorParamsFactory,

    isConstantEditable,
    constantEditorParamsFactory,
  };
};
