import { apiAction } from '../apiAction';

import * as api from './api';
import {
  fetchDataModelsTypes,
  fetchDatasetsTypes,
  fetchAllDatasetsTypes,
  fetchDataItemsTypes,
  fetchMappingFormsTypes,
  fetchDatasetInstancesTypes,
  SELECT_DATASET_INSTANCE,
  fetchInstanceDataTypes,
  fetchInstanceDataPrintServiceTypes,
  CLEAR_INSTANCE_DATA_PRINT_SERVICE,
  fetchDataTypesTypes,
  updateDataModelTypes,
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigsTypes,
  RESET_MAPPING_FORM_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIGS,
  fetchPDFFieldsByPageTypes,
  RESET_PDF_FIELDS_BY_PAGE,
  fetchPDFAttachmentColumnPropertiesTypes,
  assignPDFDataUsageTypes,
  unassignPDFDataUsageTypes,
  fetchOverflowActionTypesTypes,
  updatePdfDataUsageAndAttachmentConfigTypes,
  SELECT_DATA_ITEM,
  mapPDFFieldTypes,
  removePDFFieldMapTypes,
  SELECT_DATA_MODEL,
  addDataModelTypes,
  addDatasetTypes,
  SELECT_DATASET,
  EXPAND_COLLAPSE_HIERARCHY_LIST_ITEM,
  SELECT_MAPPING_FORM_PDF,
  SELECT_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIG,
  updateInstanceDataTypes,
  fetchDataDefinitionsTypes,
  addDatasetInstanceTypes,
  deleteDatasetInstanceTypes,
  deleteDatasetDefTypes,
  CLEAR_DATASET_INSTANCES,
  CLEAR_INSTANCE_DATA,
  deleteDataItemInstancesAndDefTypes,
} from './types';

export const fetchDataModels = apiAction({
  types: fetchDataModelsTypes,
  apiAction: api.fetchDataModels,
});

export const fetchDatasets = apiAction({
  types: fetchDatasetsTypes,
  apiAction: api.fetchDatasets,
});

export const fetchAllDatasets = apiAction({
  types: fetchAllDatasetsTypes,
  apiAction: api.fetchAllDatasets,
});

export const fetchDataTypes = apiAction({
  types: fetchDataTypesTypes,
  apiAction: api.fetchDataTypes,
});

export const fetchDataItems = apiAction({
  types: fetchDataItemsTypes,
  apiAction: api.fetchDataItems,
});

export const fetchMappingForms = apiAction({
  types: fetchMappingFormsTypes,
  apiAction: api.fetchMappingForms,
});

export const selectDataItem = dataItemId => ({
  type: SELECT_DATA_ITEM,
  payload: dataItemId,
});

export const selectDataModel = dataModelId => ({
  type: SELECT_DATA_MODEL,
  payload: dataModelId,
});

export const selectDataset = datasetId => ({
  type: SELECT_DATASET,
  payload: datasetId,
});

export const expandCollapseHierarchyListItem = datasetId => ({
  type: EXPAND_COLLAPSE_HIERARCHY_LIST_ITEM,
  payload: datasetId,
});

export const updateDataModel = apiAction(
  {
    types: updateDataModelTypes,
    apiAction: api.updateDataModel,
    throwApiErrors: true,
  },
  {
    successNotificationMessage: () => 'Data model, dataset and/or data items successfully updated',
  },
);

export const addDataModel = apiAction(
  {
    types: addDataModelTypes,
    apiAction: api.addDataModel,
    throwApiErrors: true,
  },
  {
    successNotificationMessage: () => 'Data model successfully added',
    errorNotificationMessage: () => 'Adding data model failed',
  },
);

export const addDataset = apiAction(
  {
    types: addDatasetTypes,
    apiAction: api.addDataset,
    throwApiErrors: true,
  },
  {
    successNotificationMessage: () => 'Dataset successfully added',
    errorNotificationMessage: () => 'Adding dataset failed',
  },
);

export const fetchOverflowActionTypes = apiAction({
  types: fetchOverflowActionTypesTypes,
  apiAction: api.fetchOverflowActionTypes,
});

export const fetchMappingFormPDFPageDataUsageAndAttachmentConfigs = apiAction({
  types: fetchMappingFormPDFPageDataUsageAndAttachmentConfigsTypes,
  apiAction: api.fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
});

export const resetMappingFormPDFPageDataUsageAndAttachmentConfigs = () => ({
  type: RESET_MAPPING_FORM_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIGS,
});

export const fetchPDFFieldsByPage = apiAction({
  types: fetchPDFFieldsByPageTypes,
  apiAction: api.fetchPDFFieldsByPage,
});

export const resetPDFFieldsByPage = () => ({
  type: RESET_PDF_FIELDS_BY_PAGE,
});

export const mapPDFField = apiAction(
  {
    types: mapPDFFieldTypes,
    apiAction: api.mapPDFField,
    throwApiErrors: true,
  },
  { successNotificationMessage: () => 'Field mapped successfully' },
);

export const removePDFFieldMap = apiAction(
  {
    types: removePDFFieldMapTypes,
    apiAction: api.removePDFFieldMap,
    throwApiErrors: true,
  },
  { successNotificationMessage: () => 'Field map removed successfully' },
);

export const selectMappingFormPdf = formPDFId => ({
  type: SELECT_MAPPING_FORM_PDF,
  payload: formPDFId,
});

export const selectPDFPageDataUsageAndAttachmentConfig = pdfPageDataUsageAndAttachmentConfigId => ({
  type: SELECT_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIG,
  payload: pdfPageDataUsageAndAttachmentConfigId,
});

export const assignPDFDataUsage = apiAction(
  {
    types: assignPDFDataUsageTypes,
    apiAction: api.assignPDFDataUsage,
    throwApiErrors: true,
  },
  { successNotificationMessage: () => 'Dataset assigned successfully' },
);

export const unassignPDFDataUsage = apiAction(
  {
    types: unassignPDFDataUsageTypes,
    apiAction: api.unassignPDFDataUsage,
    throwApiErrors: true,
  },
  { successNotificationMessage: () => 'Dataset unassigned successfully' },
);

export const updatePdfDataUsageAndAttachmentConfig = apiAction(
  {
    types: updatePdfDataUsageAndAttachmentConfigTypes,
    apiAction: api.updatePdfDataUsageAndAttachmentConfig,
    throwApiErrors: true,
  },
  {
    successNotificationMessage: () => 'Dataset usage updated successfully',
    errorNotificationMessage: () => 'Dataset usage update failed',
  },
);

export const fetchDataDefinitions = apiAction({
  types: fetchDataDefinitionsTypes,
  apiAction: api.fetchDataDefinitions,
});

export const fetchDatasetInstances = apiAction({
  types: fetchDatasetInstancesTypes,
  apiAction: api.fetchDatasetInstances,
});

export const selectDatasetInstance = datasetInstanceId => ({
  type: SELECT_DATASET_INSTANCE,
  payload: datasetInstanceId,
});

export const fetchInstanceData = apiAction({
  types: fetchInstanceDataTypes,
  apiAction: api.fetchInstanceData,
});

export const fetchInstanceDataPrintService = apiAction({
  types: fetchInstanceDataPrintServiceTypes,
  apiAction: api.fetchInstanceDataPrintService,
});

export const addDatasetInstance = apiAction(
  {
    types: addDatasetInstanceTypes,
    apiAction: api.addDatasetInstance,
  },
  {
    successNotificationMessage: () => 'Successfully added new dataset instance',
  },
);

export const updateInstanceData = apiAction(
  {
    types: updateInstanceDataTypes,
    apiAction: api.updateInstanceData,
  },
  {
    successNotificationMessage: () => 'Instance data have been updated',
  },
);

export const deleteDatasetInstance = apiAction(
  {
    types: deleteDatasetInstanceTypes,
    apiAction: api.deleteDatasetInstance,
  },
  { successNotificationMessage: () => 'Dataset instance deleted successfully' },
);

export const deleteDatasetDef = apiAction(
  {
    types: deleteDatasetDefTypes,
    apiAction: api.deleteDatasetDef,
  },
  { successNotificationMessage: () => 'Dataset deleted successfully' },
);

export const clearDatasetInstances = () => ({
  type: CLEAR_DATASET_INSTANCES,
});

export const clearInstanceData = () => ({
  type: CLEAR_INSTANCE_DATA,
});

export const clearInstanceDataPrintService = () => ({
  type: CLEAR_INSTANCE_DATA_PRINT_SERVICE,
});

export const deleteDataItemInstancesAndDef = apiAction(
  {
    types: deleteDataItemInstancesAndDefTypes,
    apiAction: api.deleteDataItemInstancesAndDef,
  },
  {
    successNotificationMessage: () =>
      'Data items and related data item instances deleted successfully',
  },
);

export const fetchPDFAttachmentColumnProperties = apiAction({
  types: fetchPDFAttachmentColumnPropertiesTypes,
  apiAction: api.fetchPDFAttachmentColumnProperties,
});
