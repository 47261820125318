import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
} from '@common-packages/customer-permissions-utils';

const hasRequiredPermissionsToView = (
  customerPermissions,
  path,
  accessOnlyWithEditPermission = false,
) => {
  if (path && Array.isArray(path)) {
    return hasPermissionToAccess({
      customerPermissions,
      permissionType: accessOnlyWithEditPermission ? PERMISSION_TYPE.EDIT : PERMISSION_TYPE.READ,
      routeDefinitions: path,
    });
  }

  if (accessOnlyWithEditPermission) {
    return hasPermissionToAccess({
      customerPermissions,
      permissionType: PERMISSION_TYPE.EDIT,
      routeDefinitions: [path],
    });
  }

  return hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.READ,
    routeDefinitions: [path],
  });
};

export default hasRequiredPermissionsToView;
