import React, { useCallback, useState } from 'react';
import { Button } from '@pwc/appkit-react/lib/Button';
import PropTypes from 'prop-types';

import AppkitIcon from '../../shared/displayComponents/appkitIcon/appkitIcon.component';
import styles from '../styles.module.scss';

import { getActionData } from './getStepsData';
import Action from './action.component';
import { isSubStepCompleted, hasAnyEarlierSubStepNewerData } from './stepValidation';
import { stepDataPropTypes, contextDebugInfoPropTypes } from './currentReturnFiling.propTypes';
import { DEFAULT_ACTION_BUTTON_TEXT } from './constants';

const SubStep = ({
  children,
  getDownloadFileComponent,

  contextDebugInfo,
  actionsBatch,
  callAction,
  stepData,
  isSubmittingAnyAction,
  previousSubStep,
  earlierSubStepsBeforePreviousSubStep,
  isLoading,
  steps,
  warning,
  disableNextStep = false,
  hasPermissionToRun = true,
  actionButtonText = DEFAULT_ACTION_BUTTON_TEXT,
  failedDisplayedStatus,
  isLocked = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onCallAction = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await callAction({ action: actionsBatch });
    } finally {
      setIsSubmitting(false);
    }
  }, [callAction, actionsBatch]);

  const isPreviousSubStepIncomplete =
    previousSubStep && !isSubStepCompleted(previousSubStep, steps);
  const somePreviousSubStepHasToBeRetried =
    previousSubStep &&
    earlierSubStepsBeforePreviousSubStep &&
    hasAnyEarlierSubStepNewerData(previousSubStep, earlierSubStepsBeforePreviousSubStep, steps);

  return (
    <div className={styles.subStepBox}>
      {Object.keys(steps[actionsBatch]).map(step => (
        <Action
          key={step}
          actionName={step}
          contextDebugInfo={contextDebugInfo}
          title={steps[actionsBatch][step]}
          data={getActionData(stepData, step)}
          isSubmitting={isSubmitting}
          isLoading={isLoading}
          failedDisplayedStatus={failedDisplayedStatus}
          getDownloadFileComponent={getDownloadFileComponent}
        />
      ))}
      {children}
      <div className={styles.inline}>
        <Button
          size="lg"
          onClick={onCallAction}
          disabled={
            !hasPermissionToRun ||
            isSubmittingAnyAction ||
            isPreviousSubStepIncomplete ||
            somePreviousSubStepHasToBeRetried ||
            isLoading ||
            disableNextStep ||
            isLocked
          }
        >
          {isLocked && (
            <AppkitIcon className="location-lock-icon" icon="location-locked" size={16} />
          )}
          {actionButtonText}
        </Button>
        {Boolean(warning) && <div className={styles.runButtonWarning}>{warning}</div>}
      </div>
    </div>
  );
};

const SubStepPropTypes = {
  actionsBatch: PropTypes.string.isRequired,
  callAction: PropTypes.func.isRequired,
  stepData: PropTypes.arrayOf(stepDataPropTypes).isRequired,
};

SubStep.propTypes = {
  ...SubStepPropTypes,
  hasPermissionToRun: PropTypes.bool.isRequired,
  contextDebugInfo: contextDebugInfoPropTypes,
  isSubmittingAnyAction: PropTypes.bool.isRequired,
  previousSubStep: PropTypes.shape(SubStepPropTypes),
  earlierSubStepsBeforePreviousSubStep: PropTypes.arrayOf(PropTypes.shape(SubStepPropTypes)),
  isLoading: PropTypes.bool.isRequired,
  disableNextStep: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  getDownloadFileComponent: PropTypes.func,
  actionButtonText: PropTypes.string,
  failedDisplayedStatus: PropTypes.string,
  isLocked: PropTypes.bool,
  warning: PropTypes.string,
};

export default SubStep;
