import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

const getValidationStatus = (isValid, isWarning) => {
  if (isValid) {
    return isWarning ? (
      <span className={classnames(styles.validationStatus, styles.validationWarning)}>
        Warnings
      </span>
    ) : (
      <span className={classnames(styles.validationStatus, styles.validationPassed)}>Pass</span>
    );
  }
  return <span className={classnames(styles.validationStatus, styles.validationFailed)}>Fail</span>;
};

const getValidationDate = isoString => new Date(isoString).toLocaleString();

const ExpressionValidationStatus = ({
  date,
  isValid,
  isWarning,
  label = 'Validation Status:',
  className = '',
}) => (
  <label className={classnames('a-form-label', className)}>
    <span>{label}</span>
    {getValidationStatus(isValid, isWarning)}
    <span>{getValidationDate(date)}</span>
  </label>
);

ExpressionValidationStatus.propTypes = {
  date: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  isWarning: PropTypes.bool.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default ExpressionValidationStatus;
