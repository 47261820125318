import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { IconCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Ind.',
    field: 'isValid',
    width: 40,
    minWidth: 40,
    cellRenderer: IconCellRendererFactory({
      getName: isValid => (isValid ? 'check-mark' : 'close'),
    }),
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Info Account Id',
    field: 'infoAccountId',
    width: 160,
    minWidth: 160,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Org Id',
    field: 'orgId',
    width: 80,
    minWidth: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Consol Id',
    field: 'consolId',
    width: 100,
    minWidth: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Jurisdiction Id',
    field: 'jurisdictionId',
    width: 120,
    minWidth: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Value',
    field: 'value',
    width: 120,
    minWidth: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Result',
    field: 'result',
    width: 400,
    minWidth: 400,
  },
];

export default columnDefinitions;
