import { SYNC_REQUEST_INACTIVE_STATUS } from '@common-packages/shared-constants';

import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';
import { dateFormatter } from '../../shared/formatters';
import config from '../../config';

const inactiveStatusCellStyle = {
  // $color-pwc-appkit-grey
  color: '#9e9e9e',
  // $color-concrete
  backgroundColor: '#f2f2f2',
};

const defaultColumnDefinitionWithFilterModified = {
  ...defaultColumnDefinitionWithFilter,
  cellStyle: ({ data }) =>
    data?.overallStatus === SYNC_REQUEST_INACTIVE_STATUS ? inactiveStatusCellStyle : null,
};

const columnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'Client',
    field: 'name',
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'Authorized By',
    field: 'approvedBy',
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'Authorized Date/Time',
    field: 'approvedOn',
    valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'Deployed By',
    field: 'deployedBy',
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'Deployed Date/Time',
    field: 'deployedOn',
    valueFormatter: ({ value }) => dateFormatter(value, config.AMERICAN_DATE_TIME_FORMAT),
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'Overall Status',
    field: 'overallStatus',
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'Content',
    field: 'content',
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'SLT',
    field: 'slt',
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'Efile',
    field: 'efile',
  },
  {
    ...defaultColumnDefinitionWithFilterModified,
    headerName: 'BNA',
    field: 'bna',
  },
];

export default columnDefinitions;
