import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from '@pwc/appkit-react';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import NavigateBackLink from '../../../sharedSubPages/returnWorkspace/navigateBackLink.container';
import { useRowEditMode } from '../../../shared/editMode';
import {
  fetchGenericScreen,
  fetchGenericScreenHeader,
  updateGenericScreen,
} from '../../../shared/store/genericScreen/actions';
import {
  genericScreenSelector,
  lockIndicatorSelector,
  isFetchingGenericScreenSelector,
  isUpdatingGenericScreenSelector,
} from '../../../shared/store/genericScreen/selectors';
import globalContextPropTypes from '../../../shared/propTypes/globalContext';

import useSuppressZeros from './useSuppressZeros.hook';
import getColumnDefinitions from './genericScreen.columnDefinitions';
import styles from './genericScreen.module.scss';

const getUniqueRowId = ({ data: { rowInfo } }) => rowInfo.rowId;

const GenericScreen = ({
  globalContext,
  accountId,

  genericScreen,
  lockIndicator,
  fetchGenericScreen,
  updateGenericScreen,
  fetchGenericScreenHeader,
  isFetchingGenericScreen,
  isUpdatingGenericScreen,

  hasUserPermissionsToEdit,
}) => {
  const { taxYear, period, filingTypeId, businessEntityId, jurisdictionId } = globalContext.params;

  const isGenericScreenDataReady = Boolean(accountId && filingTypeId);
  const [isSuppressZeros, setIsSuppressZeros] = useState(false);

  useEffect(() => {
    if (isGenericScreenDataReady) {
      fetchGenericScreenHeader({ taxYear, period, jurisdictionId, accountId });
      fetchGenericScreen({
        taxYear,
        period,
        filingTypeId,
        orgId: businessEntityId,
        consolidationId: businessEntityId,
        jurisdictionId,
        accountId,
      });
    }
  }, [
    fetchGenericScreenHeader,
    fetchGenericScreen,
    isGenericScreenDataReady,
    taxYear,
    period,
    jurisdictionId,
    accountId,
    filingTypeId,
    businessEntityId,
  ]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        accountJurisdictionId: oldRow.accountJurisdictionId || jurisdictionId,
        begEndFlag: oldRow.begEndFlag || 'E',
        accountId: oldRow.accountId,
        value: newRow.adjustment,
      }));

      await updateGenericScreen({
        orgId: businessEntityId,
        period,
        taxYear,
        jurisdictionId,
        rowsToUpdate,
      });
      fetchGenericScreen({
        taxYear,
        period,
        filingTypeId,
        orgId: businessEntityId,
        consolidationId: businessEntityId,
        jurisdictionId,
        accountId,
      });
    },
    [
      updateGenericScreen,
      fetchGenericScreen,
      period,
      taxYear,
      jurisdictionId,
      accountId,
      filingTypeId,
      businessEntityId,
    ],
  );

  const isLoading = isFetchingGenericScreen || isUpdatingGenericScreen;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: genericScreen,
    getUniqueRowId,
    editButtonDisabled: lockIndicator || isLoading || !genericScreen.length,
    showLockIcon: lockIndicator,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        updateRow,
        context: {
          taxYear,
          period,
          filingTypeId,
          businessEntityId,
          jurisdictionId,
        },
      }),
    [updateRow, isInEditMode, taxYear, period, filingTypeId, businessEntityId, jurisdictionId],
  );

  const { suppressedData } = useSuppressZeros(clonedRowData);

  const toggleSuppressZeros = useCallback(() => {
    setIsSuppressZeros(!isSuppressZeros);
  }, [isSuppressZeros]);

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col-6">
          <NavigateBackLink />
        </div>
      </div>
      <div className={`${styles.contentWrapper} grid-borderless grid-row`}>
        <div className={styles.actionsWrapper}>
          <Tooltip content="Hides account rows that contain all zero values" placement="bottom">
            <Checkbox
              className={styles.suppressZerosCheckbox}
              checked={isSuppressZeros}
              onChange={toggleSuppressZeros}
            >
              Suppress Zeros
            </Checkbox>
          </Tooltip>
          <div>{hasUserPermissionsToEdit && <div>{editModeButtons}</div>}</div>
        </div>
        <AgGrid
          rowData={isSuppressZeros ? suppressedData : clonedRowData}
          columnDefs={columnDefinitions}
          isGridLoading={isLoading}
          suppressCellFocus={!isInEditMode}
          onGridReady={onGridReady}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </>
  );
};

const rowInfoPropTypes = PropTypes.shape({
  rowId: PropTypes.number.isRequired,
  isDataRow: PropTypes.bool.isRequired,
  isTitleRow: PropTypes.bool.isRequired,
  isBlankRow: PropTypes.bool.isRequired,
  isBold: PropTypes.bool.isRequired,
  isBorder: PropTypes.bool.isRequired,
  isUnderlined: PropTypes.bool.isRequired,
  isDoubleUnderlined: PropTypes.bool.isRequired,
  isDrilldown: PropTypes.bool.isRequired,
  isWorkpaperInstanceDrilldown: PropTypes.bool.isRequired,
  workpaperInstanceDrilldownData: PropTypes.shape({
    parentDatasetDefinitionId: PropTypes.string.isRequired,
    datasetDefinitionId: PropTypes.string.isRequired,
    initialParentDatasetInstanceId: PropTypes.string.isRequired,
  }),
  isAdjustmentEditable: PropTypes.bool.isRequired,
}).isRequired;

const genericScreenPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    categoryId: PropTypes.string,
    accountId: PropTypes.string,
    accountText: PropTypes.string,
    rowType: PropTypes.string,
    textType: PropTypes.string,
    dataType: PropTypes.string,
    decimalPlaces: PropTypes.number,
    percentage: PropTypes.bool,
    commas: PropTypes.bool,
    showAdj: PropTypes.bool,
    borders: PropTypes.bool,
    drilldownType: PropTypes.string,
    accountJurisdictionId: PropTypes.string,
    jurisdictionId: PropTypes.string,
    multiplier: PropTypes.number,
    begEndFlag: PropTypes.string,
    displayOrder: PropTypes.number,
    hasGenericFunction: PropTypes.bool,
    dataCollection: PropTypes.number,
    adjustment: PropTypes.number,
    total: PropTypes.number,
    rowInfo: rowInfoPropTypes,
  }),
).isRequired;

GenericScreen.propTypes = {
  globalContext: globalContextPropTypes,
  accountId: PropTypes.string,

  genericScreen: genericScreenPropTypes,
  lockIndicator: PropTypes.bool,
  fetchGenericScreen: PropTypes.func.isRequired,
  updateGenericScreen: PropTypes.func.isRequired,
  fetchGenericScreenHeader: PropTypes.func.isRequired,
  isFetchingGenericScreen: PropTypes.bool.isRequired,
  isUpdatingGenericScreen: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    genericScreen: genericScreenSelector(state),
    lockIndicator: lockIndicatorSelector(state),
    isFetchingGenericScreen: isFetchingGenericScreenSelector(state),
    isUpdatingGenericScreen: isUpdatingGenericScreenSelector(state),
  }),
  {
    fetchGenericScreen,
    updateGenericScreen,
    fetchGenericScreenHeader,
  },
)(GenericScreen);
