import { apiAction } from '../apiAction';

import {
  fetchGenericScreenTypes,
  fetchGenericScreenHeaderTypes,
  updateGenericScreenTypes,
} from './types';
import * as api from './api';

export const fetchGenericScreen = apiAction({
  types: fetchGenericScreenTypes,
  apiAction: api.fetchGenericScreen,
});

export const fetchGenericScreenHeader = apiAction({
  types: fetchGenericScreenHeaderTypes,
  apiAction: api.fetchGenericScreenHeader,
});

export const updateGenericScreen = apiAction(
  {
    types: updateGenericScreenTypes,
    apiAction: api.updateGenericScreen,
  },
  { successNotificationMessage: () => 'Adjustment value has been successfully updated' },
);
