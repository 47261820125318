import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import { fetchEntityFilingDecisionsTypes, fetchFilingDecisionsAmountDetailsTypes } from './types';

export const fetchEntityFilingDecisions = apiAction({
  types: fetchEntityFilingDecisionsTypes,
  apiAction: api.fetchEntityFilingDecisions,
});

export const fetchFilingDecisionsAmountDetails = apiAction({
  types: fetchFilingDecisionsAmountDetailsTypes,
  apiAction: api.fetchFilingDecisionsAmountDetails,
});
