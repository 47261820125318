import { ColumnTypes, ConsolidationMethodFilingTypes } from '@common-packages/shared-constants';

import {
  TaxReturnDefinitionWithFilingAttributes,
  ReturnDefinitionFilingAttributes,
  FilingAttributes,
  FindTaxReturnsColumnsBlueprint,
  FilingAttributesChildColumnBlueprint,
  ColumnInBlueprint,
  FilingAttributesColumnBlueprint,
} from '../../../../../common/types/apiShapes';
import { DisplayedAttributes } from '../../enums';

export const getIsTaxReturnDefinitionEnabled = ({
  filingAttributes,
  columnChild,
}: {
  filingAttributes?: FilingAttributes;
  columnChild: FilingAttributesChildColumnBlueprint | ColumnInBlueprint;
}) => {
  const isTaxReturnDefinitionEnabled =
    filingAttributes?.[columnChild.field as keyof FilingAttributes];
  if (columnChild.field?.startsWith(DisplayedAttributes.FILING_TYPE)) {
    return (
      isTaxReturnDefinitionEnabled &&
      [
        ConsolidationMethodFilingTypes.PUSHBACK,
        ConsolidationMethodFilingTypes.POST,
        ConsolidationMethodFilingTypes.PRE,
      ].includes(columnChild.field)
    );
  }

  return isTaxReturnDefinitionEnabled;
};

export const getMappedColumnGroup = ({
  filingAttributes,
  column,
}: {
  filingAttributes?: FilingAttributes;
  column: FilingAttributesColumnBlueprint | ColumnInBlueprint;
}) => ({
  [column.columnGroup as string]: column?.children?.map(
    (child: { field?: string; displayName: string }) => {
      return {
        value: child.field,
        label: child.displayName,
        isTaxReturnDefinitionEnabled: getIsTaxReturnDefinitionEnabled({
          filingAttributes,
          columnChild: child,
        }),
      };
    },
  ),
});

export const getReturnDefinitionFilingAttributes = ({
  taxReturnsDefinition,
  columnsBlueprint,
}: {
  taxReturnsDefinition?: TaxReturnDefinitionWithFilingAttributes;
  columnsBlueprint: FindTaxReturnsColumnsBlueprint;
}) =>
  columnsBlueprint.reduce(
    (acc, column) =>
      column.columnType === ColumnTypes.FILING_ATTRIBUTES && 'columnGroup' in column
        ? {
            ...acc,
            ...getMappedColumnGroup({
              filingAttributes: taxReturnsDefinition?.filingAttributes,
              column,
            }),
          }
        : acc,
    {} as ReturnDefinitionFilingAttributes,
  );
