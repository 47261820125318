import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';

import useFetch from '../shared/hooks/useFetch.hook';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import { columnsBlueprintPropTypes } from '../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import getColumnBlueprintBasedColumnDefinitions from '../shared/columnDefinitions/columnsBlueprint.columnDefinition';
import { columnBlueprintHeaderOptions } from '.././shared/columnDefinitions/constants';
import { useBottomSlideInEditMode } from '../shared/editMode';

import EditFilingAttributes from './editFilingAttributes.container';
import * as api from './store/api';
import columnBlueprintStyles from './../shared/styles/columnBlueprintStyles.module.scss';

const getRowId = params => params?.data.rowId;

const getOnlyFilingAttributesData = rowToEdit => omit(rowToEdit, ['filingGroup', 'jurisdiction']);

const FilingAttributesDashboard = ({
  getSlideInTitle,

  isContextReady,
  context,

  fetchFilingAttributes,
  filingAttributesColumnsBlueprint,
  filingAttributes,
  isFetchingFilingAttributes,

  hasUserPermissionsToEdit,
}) => {
  useEffect(() => {
    if (isContextReady) {
      fetchFilingAttributes({ ...context });
    }
  }, [isContextReady, fetchFilingAttributes, context]);
  const { fetch: saveFilingAttributes, isFetching: isSavingFilingAttributes } = useFetch({
    action: api.saveFilingAttributes,
  });

  const onSaveFilingAttributes = useCallback(
    async ({ values }) => {
      await saveFilingAttributes(values);
      fetchFilingAttributes({ ...context });
    },
    [saveFilingAttributes, fetchFilingAttributes, context],
  );

  const formToRender = useCallback(
    ({ values, title, onSave }) => (
      <EditFilingAttributes
        values={values}
        title={title}
        saveFilingAttributes={onSave}
        isSavingFilingAttributes={isSavingFilingAttributes}
        columnsBlueprint={filingAttributesColumnsBlueprint}
      />
    ),
    [filingAttributesColumnsBlueprint, isSavingFilingAttributes],
  );

  const isLoading = !isContextReady || isFetchingFilingAttributes;

  const { agGridProps, bottomSlideInWithForm } = useBottomSlideInEditMode({
    getRowId,
    onSave: onSaveFilingAttributes,
    mapRowForEditForm: getOnlyFilingAttributesData,
    getTitle: getSlideInTitle,
    formToRender,
    renderBottomSlideIn: !isLoading,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnBlueprintBasedColumnDefinitions({
        columnsBlueprint: filingAttributesColumnsBlueprint,
      }),
    [filingAttributesColumnsBlueprint],
  );

  return (
    <>
      <div className={`row grid-row ${columnBlueprintStyles.gridContainer}`}>
        <div className="col">
          <AgGrid
            rowData={filingAttributes}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            withSearchBar
            {...columnBlueprintHeaderOptions}
            {...agGridProps}
          />
        </div>
      </div>
      {hasUserPermissionsToEdit && bottomSlideInWithForm}
    </>
  );
};

FilingAttributesDashboard.propTypes = {
  getSlideInTitle: PropTypes.func,
  isContextReady: PropTypes.bool.isRequired,
  context: PropTypes.shape({
    taxYear: PropTypes.string,
    period: PropTypes.string,
    entityId: PropTypes.string,
    consolidationId: PropTypes.string,
  }),

  fetchFilingAttributes: PropTypes.func.isRequired,
  filingAttributesColumnsBlueprint: columnsBlueprintPropTypes.isRequired,
  filingAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetchingFilingAttributes: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

FilingAttributesDashboard.defaultProps = {
  getSlideInTitle: () => null,
};

export default FilingAttributesDashboard;
