import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { fetchTrgReports } from '../../shared/store/actions';

import AddEditReportSlideIn from './AddEditReportSlideIn.component';
import './editReport.style.scss';
import {
  addCustomReport,
  updateCustomReport,
  fetchCustomReports,
  fetchCustomReportToEdit,
  showEditParamModal,
  deleteCustomReport,
} from './store/actions';
import {
  customReportsSelector,
  isFetchingCustomReportsSelector,
  customReportToEditSelector,
  isFetchingCustomReportToEditSelector,
} from './store/selectors';

const EditReport = ({
  reportType,
  getColumnDefinitions,
  uiLabels,
  showConfirmModal,
  customReports,
  isFetchingCustomReports,
  customReportToEdit,
  isFetchingCustomReportToEdit,
  addCustomReport,
  updateCustomReport,
  fetchCustomReportToEdit,
  showEditParamModal,
  deleteCustomReport,
  fetchCustomReports,
  fetchTrgReports,
}) => {
  const [isSlideInOpen, setIsSlideInOpen] = useState(false);
  const [reportIdToEdit, setReportIdToEdit] = useState(null);

  useEffect(() => {
    fetchCustomReports({
      reportType,
    });
  }, [fetchCustomReports, reportType]);

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: uiLabels.deleteModal.title,
        text: uiLabels.deleteModal.text,
        confirmCallback: async () => {
          await deleteCustomReport(row.reportId);
          fetchCustomReports({
            reportType,
          });
          fetchTrgReports();
        },
      });
    },
    [
      showConfirmModal,
      deleteCustomReport,
      fetchCustomReports,
      fetchTrgReports,
      reportType,
      uiLabels.deleteModal.text,
      uiLabels.deleteModal.title,
    ],
  );

  const openSlideInForEdit = useCallback(row => {
    setIsSlideInOpen(true);
    setReportIdToEdit(row.reportId);
  }, []);

  const openSlideInForAdd = useCallback(() => {
    setIsSlideInOpen(true);
    setReportIdToEdit(null);
  }, []);

  const closeSlideIn = useCallback(() => {
    setIsSlideInOpen(false);
  }, []);

  const addReport = useCallback(
    async ({
      name,
      trgId,
      reportFileFormat,
      query,
      queryParams,
      reportType,
      chart,
      serverSide,
      defaultOrderBy,
    }) => {
      await addCustomReport({
        name,
        trgId,
        reportFileFormat,
        query,
        queryParams,
        reportType,
        chart,
        serverSide,
        defaultOrderBy,
      });
      fetchCustomReports({
        reportType,
      });
      fetchTrgReports();
    },
    [addCustomReport, fetchCustomReports, fetchTrgReports],
  );

  const editReport = useCallback(
    async ({
      id,
      trgId,
      reportFileFormat,
      name,
      query,
      queryParams,
      reportType,
      chart,
      serverSide,
      defaultOrderBy,
    }) => {
      await updateCustomReport({
        id,
        trgId,
        reportFileFormat,
        name,
        query,
        queryParams,
        reportType,
        chart,
        serverSide,
        defaultOrderBy,
      });
      fetchCustomReports({
        reportType,
      });
      fetchTrgReports();
    },
    [updateCustomReport, fetchCustomReports, fetchTrgReports],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: openModalForDelete,
        onEditIconClick: openSlideInForEdit,
      }),
    [openModalForDelete, openSlideInForEdit, getColumnDefinitions],
  );

  return (
    <>
      <div className="row">
        <div className="col action-buttons-header">
          <Button size="lg" onClick={openSlideInForAdd}>
            {uiLabels.addButtonText}
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={customReports}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingCustomReports}
          />
        </div>
      </div>
      <AddEditReportSlideIn
        reportType={reportType}
        isOpen={isSlideInOpen}
        closeSlideIn={closeSlideIn}
        addCustomReport={addReport}
        editCustomReport={editReport}
        reportIdToEdit={reportIdToEdit}
        reportToEdit={customReportToEdit}
        isFetchingReportToEdit={isFetchingCustomReportToEdit}
        fetchReportToEdit={fetchCustomReportToEdit}
        showEditParamModal={showEditParamModal}
      />
    </>
  );
};

EditReport.propTypes = {
  reportType: PropTypes.string.isRequired,
  getColumnDefinitions: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  customReports: PropTypes.arrayOf(
    PropTypes.shape({
      reportId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  isFetchingCustomReports: PropTypes.bool.isRequired,
  customReportToEdit: PropTypes.shape({
    reportId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
  }),
  uiLabels: PropTypes.shape({
    deleteModal: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
    addButtonText: PropTypes.string.isRequired,
  }).isRequired,
  isFetchingCustomReportToEdit: PropTypes.bool.isRequired,
  addCustomReport: PropTypes.func.isRequired,
  showEditParamModal: PropTypes.func.isRequired,
  updateCustomReport: PropTypes.func.isRequired,
  fetchCustomReportToEdit: PropTypes.func.isRequired,
  deleteCustomReport: PropTypes.func.isRequired,
  fetchCustomReports: PropTypes.func.isRequired,
  fetchTrgReports: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    customReports: customReportsSelector(state),
    isFetchingCustomReports: isFetchingCustomReportsSelector(state),
    customReportToEdit: customReportToEditSelector(state),
    isFetchingCustomReportToEdit: isFetchingCustomReportToEditSelector(state),
  }),
  {
    showConfirmModal,
    fetchCustomReports,
    fetchTrgReports,
    addCustomReport,
    updateCustomReport,
    fetchCustomReportToEdit,
    showEditParamModal,
    deleteCustomReport,
  },
)(EditReport);
