import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select, SelectGroupTitle, SelectOption } from '@pwc/appkit-react';

import Loading from '../displayComponents/loading.component';

import styles from './reportParam.module.scss';

const ReportParamMultiSelect = ({
  label,
  param,
  showSelectAll = true,
  selectAllThreshold = 1000,
  ignoreSelectAllThreshold = false,
  selectedAll = false,
  showSearchOnList = false,
  displayLabelWhenLoading = false,
  extendedWidth = false,
}) => {
  const { isFetchingParamData, paramData, setParamValue, paramValue, disabled } = param;

  const hideSelectAll = ignoreSelectAllThreshold
    ? false
    : (paramData || []).length > selectAllThreshold;

  const allValues = (paramData || []).map(data => data.value);

  return (
    <div
      className={`global-context-dropdown ${
        extendedWidth ? styles.extendedWidthParamDropdown : styles.reportParamDropdown
      }`}
    >
      {displayLabelWhenLoading && <label className="a-form-label">{label}</label>}
      <Loading isLoading={isFetchingParamData}>
        {!displayLabelWhenLoading && <label className="a-form-label">{label}</label>}
        <Select
          placeholder="Select..."
          multiple
          showSelectAll={showSelectAll && !hideSelectAll}
          onSelect={useCallback(value => setParamValue(value), [setParamValue])}
          disabled={isFetchingParamData || disabled}
          defaultValue={selectedAll ? allValues : paramValue}
          showSearchOnList={showSearchOnList}
        >
          <SelectGroupTitle>{label}</SelectGroupTitle>
          {(paramData || []).map(option => (
            <SelectOption key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </SelectOption>
          ))}
        </Select>
      </Loading>
    </div>
  );
};

ReportParamMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  param: PropTypes.shape({
    isFetchingParamData: PropTypes.bool.isRequired,
    paramData: PropTypes.array,
    paramValue: PropTypes.any,
    setParamValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }).isRequired,
  showSelectAll: PropTypes.bool,
  selectAllThreshold: PropTypes.number,
  ignoreSelectAllThreshold: PropTypes.bool,
  selectedAll: PropTypes.bool,
  showSearchOnList: PropTypes.bool,
  displayLabelWhenLoading: PropTypes.bool,
  extendedWidth: PropTypes.bool,
};

export default ReportParamMultiSelect;
