import { useRef } from 'react';

export type QueryKeysType = { [key: string]: string | QueryKeysType };
export type FlattenedKeys = { [key: string]: string };

export const validateQueryKeys = (queryKeys: QueryKeysType, path: string[] = []) => {
  Object.entries(queryKeys).forEach(([key, value]) => {
    if (typeof value === 'string') {
      const elements = value.split('_');
      const expected = [...path, elements[elements.length - 1]];
      if (
        elements.length !== expected.length ||
        !expected.every((el, index) => el === elements[index])
      ) {
        throw new Error(`Detected key without correct prefix: "${value}"`);
      }
      return;
    }
    validateQueryKeys(value, [...path, key]);
  });
};

export const flattenObject = (obj: QueryKeysType) =>
  Object.values(obj).reduce(
    (result: FlattenedKeys, queryKey): FlattenedKeys => ({
      ...result,
      ...(typeof queryKey === 'string' ? { [queryKey]: queryKey } : flattenObject(queryKey)),
    }),
    {} as FlattenedKeys,
  );

export const createValidateQueryKey = (queryKeys: QueryKeysType) => {
  const flattenedKeys = flattenObject(queryKeys);

  return (key: string): void | never => {
    if (!flattenedKeys[key]) {
      throw new Error(
        `Key "${key}" not found. Make sure you import all keys in shared/queries/index.js`,
      );
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stableValueHash = (value: any): string =>
  JSON.stringify(value, (_, val) =>
    val && typeof val === 'object'
      ? Object.keys(val)
          .sort()
          .reduce((result, key) => ({ ...result, [key]: val[key] }), {})
      : val,
  );

export const useAutoMemo = <T>(value: T): T => {
  const ref = useRef(value);
  const oldValueParsed = stableValueHash(ref.current);
  if (oldValueParsed !== stableValueHash(value)) {
    ref.current = value;
  }

  return ref.current;
};
