import {
  currencyColumnDefinition,
  defaultColumnDefinition,
} from '../../../shared/columnDefinitions';
import { currencyCellFormatter, amountFormatter } from '../../../shared/formatters';
import { rowInfoKey, estimatedTaxSummariesColumnKeys } from '../../../taxSummaries/constants';

const getRowInfo = data => data[rowInfoKey];

const accountCellClassRules = {
  'bold-cell': ({ data }) => getRowInfo(data).isSummaryRow,
};

const underlineCellClassRules = {
  'underlined-cell': ({ data }) => getRowInfo(data).isLastRowInSection,
  'double-underlined-cell': ({ data }) => getRowInfo(data).dataDoubleUnderlined,
};

const valueFormatter = props => {
  if (props.data.rowInfo.isEmptyRow) {
    return '';
  }

  return props.data.rowInfo.formatAsPercentage
    ? amountFormatter({
        value: props.value,
        props: {
          ...props.data.rowInfo,
          percentage: true,
          decimalPlaces: props.data.rowInfo.dataDecimalPlaces,
        },
      })
    : currencyCellFormatter(props);
};

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  ...currencyColumnDefinition,
  sortable: false,
};

const getColumnDefinitions = ({ taxYear = '' }) => {
  const priorYear = taxYear ? String(Number(taxYear) - 1) : '';
  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      sortable: false,
      headerName: '',
      field: estimatedTaxSummariesColumnKeys.ACCOUNT,
      width: 250,
      cellClassRules: { ...accountCellClassRules },
      maxWidth: 320,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${priorYear} Extension`,
      field: estimatedTaxSummariesColumnKeys.PRIOR_TAX_YEAR_EXTENSION,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${priorYear} Return`,
      field: estimatedTaxSummariesColumnKeys.PRIOR_TAX_YEAR_RETURN,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} 1st Quarter`,
      field: estimatedTaxSummariesColumnKeys.FIRST_QUARTER,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} 2nd Quarter`,
      field: estimatedTaxSummariesColumnKeys.SECOND_QUARTER,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} 3rd Quarter`,
      field: estimatedTaxSummariesColumnKeys.THIRD_QUARTER,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} 4th Quarter`,
      field: estimatedTaxSummariesColumnKeys.FOURTH_QUARTER,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: `${taxYear} Extension`,
      field: estimatedTaxSummariesColumnKeys.CURRENT_TAX_YEAR_EXTENSION,
      cellClassRules: { ...underlineCellClassRules },
      valueFormatter,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
