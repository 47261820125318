import React from 'react';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';

import StateOverrideRendererFramework from '../displayComponents/stateOverrideRenderer/stateOverrideRendererFramework.component';
import ConditionalSelectionListEditorSelectorFactory from '../displayComponents/editorSelector/conditionalSelectionListEditorSelector.factory';
import { DataTypes } from '../enums';
import {
  TextCellRendererFactory,
  EditableCheckboxCellRenderer,
} from '../../shared/columnDefinitions/cellRenderers';

import { NodeLevel } from './constants';
import { cellRenderer } from './cellRenderers/cellRenderer.component';

import { defaultColumnDefinitionWithFilter, getDefaultGroupColumnDefinitionWithFilter } from '.';

interface CheckboxGroup {
  id: string;
  name: string;
  description: string;
}

interface Node {
  group: boolean;
  key: string;
  level: NodeLevel;
}

interface Row {
  dataType: DataTypes;
  checkboxGroupId: string;
  aliasName: string;
  description: string;
  value: string;
  itemName: string;
  itemDescription: string;
  itemDisplayName: string;
}

interface Cell {
  node: Node;
  data: Row;
  value: string;
  oldValue: string;
  newValue: string;
}

interface CellEditorParams extends ICellEditorParams {
  colDef: {
    field: string;
  };
}

const TextRenderer = TextCellRendererFactory({
  getText: ({ value }: { value: string }) => value || '',
});

const RendererFramework = (props: CellEditorParams) => {
  if (props.data.dataType === DataTypes.CHECKBOX) {
    return <EditableCheckboxCellRenderer {...props} />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: missing properties from type 'Pick<ICellRendererParamsWithValidators
  return <TextRenderer {...props} />;
};

export const getCheckBoxGroupNameColumnDefinition = ({
  checkboxGroups,
}: {
  checkboxGroups: CheckboxGroup[];
}) => {
  const groupNameValueGetter = ({ value }: { value: string }) =>
    checkboxGroups.find(checkboxGroup => checkboxGroup.id === value)?.name || '';

  return {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Group/Account',
    field: 'checkboxGroupId',
    rowGroup: true,
    hide: true,
    cellRenderer: groupNameValueGetter,
  };
};

export const getCheckBoxGroupDescriptionColumnDefinition = ({
  checkboxGroups,
}: {
  checkboxGroups: CheckboxGroup[];
}) => {
  const groupDescriptionValueGetter = ({
    node: { group: isCheckboxGroup, key },
    data,
    value,
  }: ICellRendererParams) => {
    if (isCheckboxGroup && checkboxGroups.length > 0) {
      return checkboxGroups.find(row => row.id === key)?.description || '';
    }

    if (data?.dataType === DataTypes.CHECKBOX && data?.checkboxGroupId && data?.itemDescription) {
      return data.itemDescription;
    }

    return value;
  };

  return {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Description',
    field: 'description',
    width: 400,
    cellRenderer: (params: ICellRendererParams) =>
      cellRenderer({ params, renderer: groupDescriptionValueGetter }),
  };
};

export const getCheckBoxGroupValueColumnDefinition = ({
  rowData,
  isInEditMode,
  updateGroup,
  updateRow,
  filingTypeId,
}: {
  rowData: Row[];
  isInEditMode: boolean;
  updateGroup: (modifiedGroupRow: Array<unknown>) => void;
  updateRow: (modifiedGroupRow: { value: string }) => void;
  filingTypeId?: string;
}) => {
  const onCellValueChanged = ({ node: { group: isCheckboxGroup }, data, oldValue }: Cell) => {
    if (isCheckboxGroup) {
      const checkedRowAliasName = data?.value || oldValue;
      const checkedRow = rowData.find(row => row.aliasName === checkedRowAliasName);
      updateGroup(
        rowData
          .filter(row => row.checkboxGroupId === checkedRow?.checkboxGroupId)
          .map(row =>
            Object.assign(row, {
              value: (data?.value && row.aliasName === checkedRow?.aliasName) || false,
            }),
          ),
      );
    } else {
      updateRow(data);
    }
  };

  const getGroupRowParam = (param: CellEditorParams) => {
    const selectionListRows = rowData.filter(row => row.checkboxGroupId === param.node.key);
    const checkedRow = rowData.find(row => row.checkboxGroupId === param.node.key && !!row.value);
    const value = checkedRow ? checkedRow.aliasName : null;
    const label = checkedRow ? checkedRow.description : null;

    const newData = {
      group: true,
      dataType: DataTypes.CHECKBOX,
      value,
      selectionListId: value,
      selectionListItems: selectionListRows.map(item => ({
        value: item.aliasName,
        label: item.itemDisplayName || item.description,
      })),
    };

    return {
      ...param,
      data: newData,
      value,
      label,
    };
  };

  const checkboxCellClassRules = {
    ...defaultColumnDefinitionWithFilter.cellClassRules,
    'editable-cell': () => isInEditMode,
  };

  return {
    ...getDefaultGroupColumnDefinitionWithFilter('aliasName'),
    headerName: 'Value',
    field: 'value',
    width: 200,
    editable: (param: Cell) => param.node.level === NodeLevel.ROOT && isInEditMode,
    cellRenderer: (param: CellEditorParams) => {
      if (param.node.group) {
        return filingTypeId === 'CONSOLIDATED'
          ? RendererFramework(getGroupRowParam(param))
          : StateOverrideRendererFramework(getGroupRowParam(param));
      }

      if (param.node.level === NodeLevel.CHILD) {
        return param.value ? 'Checked' : '';
      }

      return filingTypeId === 'CONSOLIDATED'
        ? RendererFramework(param)
        : StateOverrideRendererFramework(param);
    },
    cellEditorSelector: (param: CellEditorParams) => {
      const editorSelector = ConditionalSelectionListEditorSelectorFactory();
      return param.node.group ? editorSelector(getGroupRowParam(param)) : editorSelector(param);
    },
    cellRendererParams: filingTypeId === 'CONSOLIDATED' ? { disabled: !isInEditMode } : null,
    cellClassRules:
      filingTypeId === 'CONSOLIDATED'
        ? checkboxCellClassRules
        : defaultColumnDefinitionWithFilter?.cellClassRules,
    onCellValueChanged,
  };
};
