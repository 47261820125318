import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compile } from 'path-to-regexp';

import AuthorizedRoute from '../../../shared/authorization/authorizedRoute';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import SelectContextDataInfo from '../../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import { useRowEditMode } from '../../../shared/editMode';
import { periodSelector, taxYearSelector } from '../../../shared/store/selectors';
import {
  genericCategorySelector,
  isFetchingGenericCategorySelector,
  isUpdatingGenericCategorySelector,
} from '../../../shared/store/genericCategory/selectors';
import {
  fetchGenericCategory,
  updateGenericCategory,
} from '../../../shared/store/genericCategory/actions';
import CustomizedScreenForAmount from '../customizedScreenForAmount/customizedScreenForAmount.container';
import GenericScreen from '../genericScreen/genericScreen.container';
import AccountDetails from '../accountDetails/accountDetails.container';
import GenericSourceData from '../genericSourceData/genericSourceData.container';
import CustomScreen from '../../../customScreen/customScreen.container';

import getColumnDefinitions from './genericCategory.columnDefinitions';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const GenericCategory = ({
  match,
  location,

  taxYear,
  period,
  orgId: entityId,
  jurisdictionId,
  isFetchingContext,
  namespace,

  genericCategory,
  fetchGenericCategory,
  updateGenericCategory,
  isFetchingGenericCategory,
  isUpdatingGenericCategory,

  hasUserPermissionsToEdit,
}) => {
  const { url, path, params, isExact } = match;
  const { categoryId } = params;
  const isContextReady = taxYear && period && entityId && jurisdictionId && categoryId;

  const fetchGenericCategoryData = useCallback(() => {
    if (isContextReady && isExact) {
      fetchGenericCategory({
        taxYear,
        period,
        entityId,
        jurisdictionId,
        categoryId,
      });
    }
  }, [
    fetchGenericCategory,
    isContextReady,
    taxYear,
    period,
    entityId,
    jurisdictionId,
    categoryId,
    isExact,
  ]);

  useEffect(() => {
    fetchGenericCategoryData();
  }, [fetchGenericCategoryData]);

  const ConnectedCustomizedScreenForAmount = useCallback(
    props => (
      <CustomizedScreenForAmount
        orgId={entityId}
        jurisdictionId={jurisdictionId}
        isFetchingContext={isFetchingContext}
        GenericScreenComponent={GenericScreen}
        ComponentScreenComponent={AccountDetails}
        SourceSystemDataScreenComponent={GenericSourceData}
        CustomScreenComponent={CustomScreen}
        namespace={namespace}
        {...props}
      />
    ),
    [entityId, jurisdictionId, isFetchingContext, namespace],
  );

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        accountJurisdictionId: oldRow.accountJurisdictionId,
        accountId: oldRow.accountId,
        begEndFlag: oldRow.begEndFlag,
        value: newRow.adjustments,
      }));
      await updateGenericCategory({ entityId, period, taxYear, rowsToUpdate, jurisdictionId });
      fetchGenericCategoryData();
    },
    [updateGenericCategory, fetchGenericCategoryData, taxYear, period, entityId, jurisdictionId],
  );

  const isLoading = isFetchingContext || isFetchingGenericCategory || isUpdatingGenericCategory;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: genericCategory,
    getUniqueRowId,
    editButtonDisabled: isLoading || !genericCategory.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        taxYear,
        isInEditMode,
        updateRow,
        url,
        location,
      }),
    [taxYear, isInEditMode, updateRow, url, location],
  );

  if (!isLoading && !isContextReady) {
    return <SelectContextDataInfo />;
  }

  if (isExact) {
    return (
      <>
        {navigationPrompt}
        {hasUserPermissionsToEdit && (
          <div className="row">
            <div className="col add-button-column">{editModeButtons}</div>
          </div>
        )}
        <div className="row grid-row">
          <div className="col">
            <AgGrid
              rowData={clonedRowData}
              columnDefs={columnDefinitions}
              isGridLoading={isLoading}
              singleClickEdit
              withSearchBar
              suppressCellFocus={!isInEditMode}
              onGridReady={onGridReady}
              stopEditingWhenCellsLoseFocus
            />
          </div>
        </div>
      </>
    );
  }

  const subPath = compile(path)({
    ...params,
    categoryId: ':categoryId',
  });

  return (
    <AuthorizedRoute
      path={`${subPath}/account/:accountId`}
      render={ConnectedCustomizedScreenForAmount}
    />
  );
};

GenericCategory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    isExact: PropTypes.bool.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      categoryDescription: PropTypes.string,
    }),
  }).isRequired,

  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  isFetchingContext: PropTypes.bool,
  namespace: PropTypes.string,

  genericCategory: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      accountDescription: PropTypes.string.isRequired,
      dataCollection: PropTypes.number.isRequired,
      adjustments: PropTypes.number.isRequired,
      currentYearValue: PropTypes.number.isRequired,
      priorYearValue: PropTypes.number.isRequired,
      isEditable: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  fetchGenericCategory: PropTypes.func.isRequired,
  updateGenericCategory: PropTypes.func.isRequired,
  isFetchingGenericCategory: PropTypes.bool.isRequired,
  isUpdatingGenericCategory: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    genericCategory: genericCategorySelector(state),
    isFetchingGenericCategory: isFetchingGenericCategorySelector(state),
    isUpdatingGenericCategory: isUpdatingGenericCategorySelector(state),
  }),
  {
    fetchGenericCategory,
    updateGenericCategory,
  },
)(GenericCategory);
