import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { ColumnTypes, DefaultPeriodTypes } from '@common-packages/shared-constants';
import { mapPeriods } from '@tls/state-helpers';

import { globalContextSelector } from '../shared/store/selectors';
import { fetchFormsByPeriod } from '../shared/store/actions';
import globalContextPropTypes from '../shared/propTypes/globalContext';
import { columnsBlueprintPropTypes } from '../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';

import EditFilingAttributesForm from './editFilingAttributesForm.component';

const EditFilingAttributes = ({
  globalContext,
  columnsBlueprint,
  values,
  saveFilingAttributes,
  title,

  isSavingFilingAttributes,
  fetchFormsByPeriod,
}) => {
  const filingAttributes = columnsBlueprint.filter(
    ({ columnType }) => columnType === ColumnTypes.FILING_ATTRIBUTES,
  );
  const calcPreferences = columnsBlueprint.filter(
    ({ columnType }) => columnType === ColumnTypes.CALC_PREFERENCES,
  );

  const renderForm = useCallback(
    formikProps => (
      <EditFilingAttributesForm
        isSavingFilingAttributes={isSavingFilingAttributes}
        filingAttributes={filingAttributes}
        calcPreferences={calcPreferences}
        {...formikProps}
      />
    ),
    [filingAttributes, calcPreferences, isSavingFilingAttributes],
  );

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      await saveFilingAttributes({
        ...values,
        ...DefaultPeriodTypes,
        [mapPeriods(globalContext.params.period)]: true,
        // in most cases we don't need to set the period but there are some filing attributes that
        // don't have any period set and this will let them "heal"
      });
      if (globalContext.isReady) {
        await fetchFormsByPeriod(globalContext.params);
      }
      setSubmitting(false);
    },
    [saveFilingAttributes, fetchFormsByPeriod, globalContext.isReady, globalContext.params],
  );

  return (
    <>
      <div className="a-h4">Edit Filing Attributes</div>
      <hr />
      {title && (
        <div className="row">
          <div className="col">
            <div className="a-h5">{title}</div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col">
          <Formik onSubmit={onSubmit} initialValues={values} enableReinitialize>
            {renderForm}
          </Formik>
        </div>
      </div>
    </>
  );
};

EditFilingAttributes.propTypes = {
  globalContext: globalContextPropTypes,
  title: PropTypes.string,
  columnsBlueprint: columnsBlueprintPropTypes.isRequired,
  isSavingFilingAttributes: PropTypes.bool.isRequired,
  saveFilingAttributes: PropTypes.func.isRequired,
  fetchFormsByPeriod: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired, // eslint-disable-line
};

EditFilingAttributes.defaultProps = {
  title: '',
};

export default connect(
  state => ({
    globalContext: globalContextSelector(state),
  }),
  {
    fetchFormsByPeriod,
  },
)(EditFilingAttributes);
