import { httpGetAndParse } from '../../../utils/fetchService';

export const fetchAccountAuditTrails = ({ taxYear, period, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/all-return-data/account-audit-trails/${taxYear}/${period}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Fetching account audit trails failed',
  });

export const fetchAccountData = ({ taxYear, period, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/all-return-data/account-data/${taxYear}/${period}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Fetching account data failed',
  });

export const fetchAccountAdjustment = ({ taxYear, period, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/all-return-data/account-adjustment/${taxYear}/${period}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Fetching account adjustment failed',
  });

export const fetchCalcData = ({ taxYear, period, orgId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/all-return-data/calc-data/${taxYear}/${period}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Fetching calc data failed',
  });
