import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

import { CustomerPermissionsProps } from '../../shared/propTypes/customerPropTypes';

const hasPermissionToUpdateTransmitter = (customerPermissions: CustomerPermissionsProps) =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.EFILE_UPDATE_TRANSMITTER],
  });

export default hasPermissionToUpdateTransmitter;
