import { defaultColumnDefinition, currencyColumnDefinition } from '../../../columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 450,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Account ID',
    field: 'accountId',
  },
  {
    ...currencyColumnDefinition,
    headerName: 'Amount',
    field: 'legalEntityAmt',
  },
];

export default columnDefinitions;
