import React, { useState, useCallback, useMemo } from 'react';
import { OTHER_SUBCATEGORY, TOTAL_DESC } from '@common-packages/shared-constants';

const getNodeDisplayOrder = ({ group, allLeafChildren, data }) => {
  if (group) {
    return Math.min(...allLeafChildren.map(({ data: { displayOrder } }) => displayOrder));
  }
  return data?.displayOrder;
};

const initialGroupOrderComparator = ({ nodeA, nodeB }) => {
  const nodeADisplayOrder =
    nodeA.key === OTHER_SUBCATEGORY ? Number.MAX_SAFE_INTEGER : getNodeDisplayOrder(nodeA);
  const nodeBDisplayOrder =
    nodeB.key === OTHER_SUBCATEGORY ? Number.MAX_SAFE_INTEGER : getNodeDisplayOrder(nodeB);
  if (nodeADisplayOrder < nodeBDisplayOrder || typeof nodeBDisplayOrder === 'undefined') {
    return -1;
  } else if (nodeADisplayOrder > nodeBDisplayOrder || typeof nodeADisplayOrder === 'undefined') {
    return 1;
  } else {
    return 0;
  }
};

// TODO: SLT-5478 signature for custom agg function will probably change in newer ag-grid versions
// that's a workaround to have subtotal footer
// it's done this way because normally it's shown in a "Group" column
// in this case, we don't have such column because we use `groupUseEntireRow` for the grid
// this way we show it in another column
const aggFuncs = {
  subtotalFooter: ({ rowNode: { key } }) => `${key} Subtotal`,
};

// TODO: SLT-6113 Add value to props validation
const GroupRowRenderer = ({ value }) => (
  <span className="ag-cell-wrapper ag-row-group ag-group-value ag-cell-value subcategory-header">
    {value}
  </span>
);

const useGroupingGrid = ({ aggColumnKey }) => {
  const [showCategorySubtotals, setShowCategorySubtotals] = useState(true);
  const [gridKey, setGridKey] = useState(0);

  const isTotalRow = useCallback(
    ({ data, node }) =>
      data?.[aggColumnKey] === TOTAL_DESC ||
      data?.[`${aggColumnKey}_1`] === TOTAL_DESC ||
      node.footer,
    [aggColumnKey],
  );

  const isIndentedRow = useCallback(
    ({ data }) => Boolean(data?.subCategory) && showCategorySubtotals,
    [showCategorySubtotals],
  );

  const getGroupingCellClassRules = useCallback(
    () => ({
      'overlined-cell': isTotalRow,
      'bold-cell': isTotalRow,
      'cell-with-indent': isIndentedRow,
    }),
    [isTotalRow, isIndentedRow],
  );

  const rowClassRules = useMemo(
    () => ({
      'subcategory-header-hidden': ({ node: { group, aggData } }) =>
        // Hide group root border
        (group && !showCategorySubtotals) ||
        // Hide subcategory headers and total summary border
        (group && Boolean(aggData?.[aggColumnKey] || aggData?.[`${aggColumnKey}_1`])), // AgGrid columns key can have added _1 randomly because of how agGrid sets objects from aggFuncs - it will probably be removed in next agGrid version
    }),
    [showCategorySubtotals, aggColumnKey],
  );

  const toggleShowCategorySubtotals = useCallback(() => {
    setShowCategorySubtotals(prevValue => !prevValue);
    // changing grid key will force grid rerender
    // other methods don't work because AgGrid doesn't respect changes to getRowHeight and
    // groupIncludeFooter props
    setGridKey(key => key + 1);
  }, []);

  const getRowHeight = useCallback(
    ({ node: { group, aggData } }) => {
      if (group) {
        // Hide group root header by setting its height to 0
        if (!showCategorySubtotals) {
          return 0;
        }
        // Set subcategory header and total summary row to 50
        // AgGrid columns key can have added _1 randomly because of how agGrid sets objects from aggFuncs - it will probably be removed in next agGrid version
        if (aggData?.[aggColumnKey] || aggData?.[`${aggColumnKey}_1`]) {
          return 50;
        }
      }
      // Set any other row to default 25
      return 25;
    },
    [showCategorySubtotals, aggColumnKey],
  );

  return {
    gridKey,
    setGridKey,

    showCategorySubtotals,
    rowClassRules,
    aggFuncs,

    GroupRowRenderer,
    getGroupingCellClassRules,

    isTotalRow,
    toggleShowCategorySubtotals,
    getRowHeight,
    initialGroupOrderComparator,
  };
};

export default useGroupingGrid;
