import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import { TEMP_STORE } from '../shared/store/storeHelpers';

import SetupTaxReturnBase from './setupTaxReturnBase.container';

const SetupTaxReturnsPage = connect((state: TEMP_STORE) => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(GlobalContextPage);

const TaxReturnsRoutes = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.setupTaxReturns.MAIN}
    wrapper={SetupTaxReturnsPage}
    component={SetupTaxReturnBase}
    hideGlobalContext
    disableBreadcrumbs
  />
);

export default TaxReturnsRoutes;
