const Yup = require('yup');

const commonSchema = Yup.object().shape({
  name: Yup.string().required().label('Name'),
  query: Yup.string().label('Query').when('reportType', {
    is: 'TRG',
    then: Yup.string().nullable(),
    otherwise: Yup.string().required(),
  }),
  reportType: Yup.string().required().label('Report type'),
});

const createOrUpdateCustomReportSchema = commonSchema
  .concat(
    Yup.object().shape({
      queryParams: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required(),
            mappingName: Yup.string().required(),
            type: Yup.string().oneOf(['INPUT', 'DROPDOWN']).required(),
            query: Yup.string().when('type', {
              is: 'DROPDOWN',
              then: Yup.string().required(),
            }),
            dropdownValue: Yup.string().when('type', {
              is: 'DROPDOWN',
              then: Yup.string().required(),
            }),
            dropdownLabel: Yup.string().when('type', {
              is: 'DROPDOWN',
              then: Yup.string().required(),
            }),
          }),
        )
        .notOneOf([undefined, null]), // eslint-disable-line no-undefined
      chart: Yup.object().when('reportType', {
        is: 'Chart',
        then: Yup.object().shape({
          type: Yup.string().required().label('Type'),
          key: Yup.string().required().label('Key'),
          value: Yup.string().required().label('Value'),
        }),
      }),
      trgId: Yup.string().label('TRG ID').when('reportType', {
        is: 'TRG',
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(),
      }),
    }),
  )
  .strict();

const runCustomReportSchema = Yup.object()
  .shape({
    query: Yup.string().required(),
    params: Yup.lazy(obj => {
      const mappedObj = Object.keys(obj).reduce((temp, key) => {
        temp[key] = Yup.lazy(val =>
          Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string(),
        );
        return temp;
      }, {});
      mappedObj.limit = Yup.number().nullable();
      mappedObj.offset = Yup.number().nullable();
      mappedObj.sortModel = Yup.array().nullable();
      mappedObj.filterModel = Yup.object().nullable();
      mappedObj.serverSide = Yup.bool().nullable();
      mappedObj.defaultOrderBy = Yup.string().nullable();

      return Yup.object().shape(mappedObj).required();
    }),
  })
  .strict();

module.exports = {
  commonSchema,
  createOrUpdateCustomReportSchema,
  runCustomReportSchema,
};
