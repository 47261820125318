import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TaxYearDropdown from '../shared/displayComponents/contextDropdown/taxYearDropdown.component';
import { ContextTypeAndValue, TaxYear } from '../../../common/types';

import { selectTaxYear } from './store/actions';
import {
  taxYearOptionsSelector,
  taxYearSelector,
  isFetchingTaxYearsDataSelector,
  isContextChangesBlockedSelector,
} from './store/selectors';

const noop = () => null;

interface DevelopmentTaxYearDropdownProps {
  isDisabled?: boolean;
  showWarningModal?: (params: ContextTypeAndValue) => unknown;
}

const DevelopmentTaxYearDropdown = ({
  isDisabled,
  showWarningModal = noop,
}: DevelopmentTaxYearDropdownProps) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const taxYears = useSelector(taxYearOptionsSelector);
  const isDropdownBusy = useSelector(isFetchingTaxYearsDataSelector);
  const isContextChangesBlocked = useSelector(isContextChangesBlockedSelector);

  const handleSelectTaxYear = useCallback(
    (newTaxYear: TaxYear) => {
      dispatch(selectTaxYear(newTaxYear));
    },
    [dispatch],
  );

  return (
    <TaxYearDropdown
      taxYears={taxYears}
      taxYear={taxYear}
      selectTaxYear={handleSelectTaxYear}
      isDropdownBusy={isDropdownBusy}
      isContextChangesBlocked={isContextChangesBlocked}
      isDisabled={isDisabled}
      showWarningModal={showWarningModal}
    />
  );
};

export default DevelopmentTaxYearDropdown;
