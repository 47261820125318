import { createSelector } from 'reselect';

export const validatedMultDataSelector = ({ tools }) => tools.multDataUpload.validatedMultData;
export const isFetchingValidatedMultDataSelector = ({ tools }) =>
  tools.multDataUpload.isFetchingValidatedMultData;
export const isUploadDataValidSelector = createSelector(
  [validatedMultDataSelector],
  validatedMultData =>
    validatedMultData.length > 0 && validatedMultData.every(({ isValid }) => isValid),
);

export const isUploadingMultDataSelector = ({ tools }) => tools.multDataUpload.isUploadingMultData;
