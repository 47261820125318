const { pathToRegexp } = require('path-to-regexp');

const replacePermissionRouteWildcards = require('./replacePermissionRoutesWildcard');
const isCustomScreenRoute = require('./isCustomScreenRoute');

const matchPermissionRoute = ({ routeDefinition, permissionRoute }) => {
  if (routeDefinition === permissionRoute) {
    return true;
  }
  const replacedPermissionRoute = replacePermissionRouteWildcards(permissionRoute);
  const regexp = pathToRegexp(replacedPermissionRoute);

  if (!isCustomScreenRoute(routeDefinition) && isCustomScreenRoute(permissionRoute)) {
    /**
     * This check is required because some routes match customScreenRoute
     * e.g. /:taxYear/:period/:filingTypeId/:businessEntityId/:jurisdictionId/separate-federal-proforma
     * matches /:taxYear/:period/:filingTypeId/:businessEntityId/:jurisdictionId/:customScreenName
     * and user permissions for both screens may differ
     * TODO: https://jira.ist.pwc.com/browse/SLT-5720
     */
    return false;
  }

  return regexp.test(routeDefinition);
};

module.exports = matchPermissionRoute;
