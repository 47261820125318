import { httpGetAndParse, httpPut, httpDelete, httpPost } from '../../../utils/fetchService';

const baseURL = '/api/admin/maintenance';

export const fetchJurisdictions = ({ taxYear }) =>
  httpGetAndParse({
    route: `${baseURL}/shared/jurisdictions/${taxYear}`,
    errorMessage: 'Fetching jurisdictions failed',
  });

export const fetchConstants = ({ taxYear, period, jurisdictionId, filingTypeId }) =>
  httpGetAndParse({
    route: `${baseURL}/constants/${taxYear}/${period}/${filingTypeId}/${jurisdictionId}`,
    errorMessage: 'Fetching constants failed',
  });

export const deleteConstant = ({
  taxYear,
  period,
  jurisdictionId,
  filingTypeId,
  constantId,
  filingAttributes,
  calcOptions,
}) =>
  httpDelete({
    route: `${baseURL}/constants/${taxYear}/${period}/${filingTypeId}/${jurisdictionId}/${constantId}/${filingAttributes}`,
    errorMessage: 'Deleting constant failed',
    body: {
      calcOptions,
    },
  });

export const createConstant = ({
  taxYear,
  period,
  constantId,
  jurisdictionId,
  filingTypeId,
  description,
  value,
  filingAttributes,
}) =>
  httpPost({
    route: `${baseURL}/constants`,
    errorMessage: 'Adding constant failed',
    body: {
      taxYear,
      period,
      constantId,
      jurisdictionId,
      filingTypeId,
      description,
      value,
      filingAttributes,
    },
  });

export const updateConstant = ({
  taxYear,
  period,
  constantId,
  jurisdictionId,
  filingTypeId,
  description,
  value,
  mnemonic,
  filingAttributes,
}) =>
  httpPut({
    route: `${baseURL}/constants`,
    errorMessage: 'Updating constant failed',
    body: {
      taxYear,
      period,
      constantId,
      jurisdictionId,
      filingTypeId,
      description,
      value,
      mnemonic,
      filingAttributes,
    },
  });
