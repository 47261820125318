import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from '@tls/ui-spinner';
import { List } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';

import { GlobalContext } from '../../../../common/types';
import { TaxReturnOption } from '../../shared/queries/taxReturns';
import SelectContextDataInfo from '../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';
import Item from '../tree/item.component';
import useReturns from '../../shared/hooks/useReturns.hook';

import { RenderTreeProps, TreeItem } from './types';

interface ReturnsNavigatorProps {
  globalContext: GlobalContext;
  applyPermissions: (tree: TreeItem[]) => TreeItem[];
  renderTree: (props: RenderTreeProps) => JSX.Element;
  isFetchingGlobalContext: boolean;
}

const ReturnsNavigatorComponent = ({ applyPermissions, renderTree }: ReturnsNavigatorProps) => {
  const globalContext = useSelector(globalContextSelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);
  const { returns, isFetchingReturns } = useReturns({ routePath: Routes.returnMaintenance.MAIN });

  if (isFetchingGlobalContext || isFetchingReturns) {
    return <Spinner />;
  }

  if (!globalContext.isReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <List>
      {returns?.map(({ returnId, returnName }: TaxReturnOption) => (
        <Item
          key={returnId}
          item={{
            type: 'binder',
            name: returnName ?? '',
            id: returnId,
            route: Routes.returnMaintenance.compiledRoute({ returnId }),
            children: [],
          }}
          applyPermissions={applyPermissions}
          renderTree={renderTree}
          defaultOpen={false}
        />
      ))}
    </List>
  );
};

export default ReturnsNavigatorComponent;
