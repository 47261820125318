import React from 'react';
import { CompilationResult, CompilationInfo } from '@tls/slt-types';

import { isEveryCompilationResultOfWarningType } from '../utils';

import styles from './validationInfoStatus.module.scss';

interface ValidationInfoStatusProps {
  className?: string;
  validationDate?: string;
  compilationResult?: CompilationResult[] | null;
  compilationInfo?: CompilationInfo | null;
  isCompiling?: boolean;
  areAllJurisdictionsSelected?: boolean;
}

const ValidationInfoStatus = ({
  className = '',
  compilationResult,
  validationDate,
  compilationInfo = null,
  isCompiling = false,
  areAllJurisdictionsSelected = false,
}: ValidationInfoStatusProps) => {
  const CompilationStatus = () => {
    if (!compilationResult) {
      return null;
    }
    if (!compilationResult.length) {
      return <span className={styles.passStatus}>Pass</span>;
    }
    if (isEveryCompilationResultOfWarningType(compilationResult)) {
      return <span className={styles.passWithWarningsStatus}>Pass w/ Warnings</span>;
    }
    return <span className={styles.failStatus}>Fail</span>;
  };

  return (
    <p className={className}>
      {compilationResult &&
      (compilationInfo?.totalExpressions || areAllJurisdictionsSelected) &&
      (!compilationInfo?.changedExpressions || compilationResult.length) ? (
        <span>
          Compile Status:
          {compilationResult && (compilationInfo || areAllJurisdictionsSelected) && (
            <span>
              {isCompiling ? (
                <span className={styles.loadingText}>Compiling...</span>
              ) : (
                <>
                  <CompilationStatus />
                  <span>{validationDate}</span>
                </>
              )}
            </span>
          )}
        </span>
      ) : null}
    </p>
  );
};

export default ValidationInfoStatus;
