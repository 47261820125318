import { useMemo } from 'react';
import get from 'lodash.get';
import { DOMAIN_TYPE } from '@common-packages/shared-constants';

import { EFILE_ACTION } from './constants';
import { getTransmitStepData, getActionData } from './getStepsData';

const useTransmitStepAndTtiSubmissionId = ({
  ttiYearId,
  ttiCaseId,
  selectedTtiBinderId,
  efServiceSubmissionId,
  eFileStepsData,
  stateSubmissionType,
}) =>
  useMemo(() => {
    const initiateValues = {
      ttiYearId,
      ttiCaseId,
      domainType: DOMAIN_TYPE,
    };

    const transmitStepsData = getTransmitStepData({
      initiateValues,
      initiateStepsValues: { efServiceSubmissionId },
      eFileStepsData,
      binderId: selectedTtiBinderId,
      stateSubmissionType,
    });

    const createStateTransmissionFileStepData = getActionData(
      transmitStepsData,
      EFILE_ACTION.CREATE_STATE_TRANSMISSION,
    );
    const ttiSubmissionId = get(createStateTransmissionFileStepData, 'ttiSubmissionId', null);

    return {
      transmitStepsData,
      ttiSubmissionId,
    };
  }, [
    ttiYearId,
    ttiCaseId,
    selectedTtiBinderId,
    efServiceSubmissionId,
    eFileStepsData,
    stateSubmissionType,
  ]);

export default useTransmitStepAndTtiSubmissionId;
