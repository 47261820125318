import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';
import { Button } from '@pwc/appkit-react/lib/Button';
import { useHistory } from 'react-router-dom';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { taxYearSelector, periodSelector } from '../store/selectors';
import useGridWithEditForm from '../../shared/hooks/useGridWithEditForm.hook';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import {
  fetchGenericScreenDefinition,
  deleteGenericScreenDefinition,
  selectScreenId,
} from './store/actions';
import {
  isFetchingGenericScreenDefinitionSelector,
  genericScreenDefinitionSelector,
  isFetchingCategoriesSelector,
  isFetchingAccountsSelector,
  isFetchingAllJurisdictionsSelector,
} from './store/selectors';
import getColumnDefinitions from './genericScreenDefinition.columnDefinitions';
import EditGenericScreenDefinition from './editGenericScreenDefinition.container';
import { genericScreenDefinitionTypes } from './propTypes';

const getRowId = params => params?.data?.screenId;

const GenericScreenDefinition = ({
  taxYear,
  period,
  fetchGenericScreenDefinition,
  genericScreenDefinition,
  isFetchingGenericScreenDefinition,
  showConfirmModal,
  deleteGenericScreenDefinition,
  isFetchingAccounts,
  isFetchingCategories,
  isFetchingAllJurisdictions,
  selectScreenId,
}) => {
  const [screenToEdit, setScreenToEdit] = useState(null);
  const { agGridProps, gridApi, clearSelection } = useGridWithEditForm(
    genericScreenDefinition,
    setScreenToEdit,
    getRowId,
  );
  const { queryParams, setParams } = useUrlParams();
  const history = useHistory();

  const fetchGridData = useCallback(async () => {
    const isContextReady = taxYear && period;

    if (isContextReady) {
      await fetchGenericScreenDefinition({
        taxYear,
        period,
      });
    }
  }, [fetchGenericScreenDefinition, taxYear, period]);

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete generic screen definition',
        text: 'Are you sure you want to delete this generic screen definition?',
        confirmCallback: async () => {
          await deleteGenericScreenDefinition({
            taxYear,
            period,
            jurisdictionId: row.jurisdictionId,
            screenId: row.screenId,
            accountId: row.accountId,
          });
          setScreenToEdit(null);
          fetchGridData();
        },
      });
    },
    [deleteGenericScreenDefinition, fetchGridData, period, showConfirmModal, taxYear],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: openModalForDelete,
        selectScreenId,
        queryParams: queryParams.search ? `search=${queryParams.search}` : '',
      }),
    [openModalForDelete, selectScreenId, queryParams.search],
  );

  const redirectToRowDetailsPage = useCallback(
    screenId => {
      selectScreenId(screenId);
      history.push(Routes.genericScreenDefinitionRowDetails.MAIN);
    },
    [selectScreenId, history],
  );

  const setFilterQueryParams = useCallback(() => {
    gridApi?.filterManager?.quickFilter
      ? setParams({ queryParams: { search: gridApi.filterManager.quickFilter.toLowerCase() } })
      : history.push(Routes.genericScreenDefinition.MAIN);
  }, [setParams, gridApi, history]);

  const renderAddScreenButton = useCallback(
    () => (
      <Button
        size="lg"
        className="add-button"
        onClick={clearSelection}
        disabled={isFetchingAccounts || isFetchingCategories || isFetchingAllJurisdictions}
      >
        Add Screen
      </Button>
    ),
    [clearSelection, isFetchingAccounts, isFetchingCategories, isFetchingAllJurisdictions],
  );

  return (
    <>
      <HeaderWithParamDropdownsWrapper
        showHeaderTaxYearDropdown
        showHeaderPeriodDropdown
        renderAdditionalElements={renderAddScreenButton}
      />
      <div className="row grid-row">
        <div className="col-8">
          <AgGrid
            rowData={genericScreenDefinition}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingGenericScreenDefinition}
            withSearchBar
            onFilterChanged={setFilterQueryParams}
            initialQuickFilterValue={queryParams.search || ''}
            {...agGridProps}
            areHeaderCellBordersEnabled
          />
        </div>
        <div className="col-4 right-column-edit-form">
          <EditGenericScreenDefinition
            taxYear={taxYear}
            period={period}
            screenToEdit={screenToEdit}
            clearForm={clearSelection}
            redirectToRowDetailsPage={redirectToRowDetailsPage}
          />
        </div>
      </div>
    </>
  );
};

GenericScreenDefinition.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  fetchGenericScreenDefinition: PropTypes.func.isRequired,
  isFetchingGenericScreenDefinition: PropTypes.bool.isRequired,
  genericScreenDefinition: PropTypes.arrayOf(PropTypes.shape(genericScreenDefinitionTypes)),
  showConfirmModal: PropTypes.func.isRequired,
  deleteGenericScreenDefinition: PropTypes.func.isRequired,
  isFetchingAccounts: PropTypes.bool.isRequired,
  isFetchingCategories: PropTypes.bool.isRequired,
  isFetchingAllJurisdictions: PropTypes.bool.isRequired,
  selectScreenId: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    genericScreenDefinition: genericScreenDefinitionSelector(state),
    isFetchingGenericScreenDefinition: isFetchingGenericScreenDefinitionSelector(state),
    isFetchingAccounts: isFetchingAccountsSelector(state),
    isFetchingCategories: isFetchingCategoriesSelector(state),
    isFetchingAllJurisdictions: isFetchingAllJurisdictionsSelector(state),
  }),
  {
    showConfirmModal,
    fetchGenericScreenDefinition,
    deleteGenericScreenDefinition,
    selectScreenId,
  },
)(GenericScreenDefinition);
