import { Routes } from '@common-packages/routes-definitions';

import { defaultColumnDefinition, adminColumnDefinition } from '../../shared/columnDefinitions';
import {
  LinkCellRendererFactory,
  AdminCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import { dateFormatter } from '../../shared/formatters';

const getColumnDefinitions = ({ selectGenericFunction, openModalForDelete, queryParams }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: () => false,
    onDeleteIconClick: openModalForDelete,
  });

  const genericFunctionsLinkRenderer = LinkCellRendererFactory({
    getText: ({ value }) => value,
    getPathName: () => Routes.developmentGenericFunctionsAddEdit.EDIT,
    getQueryParams: () => queryParams,
    clickHandler: (_, data) => selectGenericFunction(data),
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Function Id',
      field: 'functionId',
      width: 285,
      cellRenderer: genericFunctionsLinkRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
      width: 400,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Function Type',
      field: 'functionType',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Operation Type',
      field: 'operationType',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Last Modified',
      field: 'dateModified',
      valueFormatter: ({ value }) => dateFormatter(value),
      width: 100,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
