import { useSelector } from 'react-redux';

import { jobsSelector } from './store/selectors';
import { isJobInProgress } from './utils';

const useLastJobOfType = ({ jobType, triggeredJob }) => {
  const jobs = useSelector(jobsSelector);

  const alreadyPollingForLastTriggeredJob = triggeredJob?.jobId
    ? jobs.some(polledJob => polledJob.jobId === triggeredJob?.jobId)
    : jobs.some(polledJob => polledJob.jobType === jobType);

  const lastJobOfType = alreadyPollingForLastTriggeredJob
    ? jobs.find(polledJob => polledJob.jobType === jobType)
    : triggeredJob;

  const jobInProgress = Boolean(lastJobOfType && isJobInProgress(lastJobOfType.status));

  return {
    lastJobOfType: {
      isJobInProgress: jobInProgress,
      ...lastJobOfType,
    },
  };
};

export default useLastJobOfType;
