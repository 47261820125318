export const SelectSizes = {
  DEFAULT: 'default',
  LARGE: 'large',
};

export const SelectTabKinds = {
  PRIMARY: 'primary',
  NEGATIVE: 'negative',
  GRAY: 'gray',
};

export const SelectToggleModes = {
  SELECT: 'select',
  SEARCH: 'search',
};

export const InputSizes = {
  SM: 'sm',
  LG: 'lg',
};

export const ResizeTypes = {
  NONE: 'none',
  BOTH: 'both',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  INITIAL: 'initial',
  INHERIT: 'inherit',
};
