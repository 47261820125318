import keyBy from 'lodash.keyby';

const prepareModalData = (
  jurisdictionList = [],
  jurisdictionsInWhichDataModelExists = [],
  sourceJurisdictionId = null,
) => {
  const filteredJurisdictionsInWhichDataModelExistsMap = new Map(
    jurisdictionsInWhichDataModelExists.reduce((acc, { jurisdictionId }) => {
      if (jurisdictionId !== sourceJurisdictionId) {
        acc.push([jurisdictionId, true]);
      }
      return acc;
    }, []),
  );

  const filteredJurisdictions = jurisdictionList.reduce(
    (acc, { jurisdictionDescription, jurisdictionId, displayOrder, stateCode }) => {
      if (jurisdictionId !== sourceJurisdictionId) {
        acc.push({
          id: jurisdictionId,
          label: jurisdictionDescription,
          order: displayOrder,
          stateCode,
          checked: filteredJurisdictionsInWhichDataModelExistsMap.has(jurisdictionId),
          indeterminate: false,
          disabled: false,
        });
      }
      return acc;
    },
    [],
  );

  return {
    states: {
      id: 'states',
      label: 'States',
      checked: false,
      disabled: false,
      indeterminate: false,
      children: keyBy(filteredJurisdictions, 'id'),
    },
  };
};

export default prepareModalData;
