import React, { useMemo, useCallback } from 'react';
import { Button } from '@pwc/appkit-react';
import { StateValue } from 'xstate';

import { WIZARD_MACHINE_STATES } from '../../constants';
import { WizardStateMachineContext } from '../../wizardStateMachine/types';
import { TransmissionStatus } from '../../enums';

interface UseRetrieveAcknowledgementButton {
  machineStateValue: StateValue;
  machineStateContext: WizardStateMachineContext;
  handleRecheckStatus: () => Promise<void>;
  isFetchingCheckTtiServiceRunning: boolean;
  areButtonsRecheckingStatusDisabled: boolean;
}

const useRetrieveAcknowledgementButton = ({
  machineStateValue,
  machineStateContext,
  handleRecheckStatus,
  isFetchingCheckTtiServiceRunning,
  areButtonsRecheckingStatusDisabled,
}: UseRetrieveAcknowledgementButton) => {
  const isRetrieveAcknowledgementButtonVisible = useMemo(() => {
    if (machineStateValue !== WIZARD_MACHINE_STATES.STEP_5_VIEW_STATUS) {
      return false;
    }

    const { status: transmissionStatus } = machineStateContext.transmission;
    if (
      transmissionStatus !== TransmissionStatus.ACCEPTED &&
      transmissionStatus !== TransmissionStatus.REJECTED
    ) {
      return false;
    }

    return true;
  }, [machineStateContext.transmission, machineStateValue]);

  const retrieveAcknowledgementButton = useCallback(
    () => (
      <Button
        size="lg"
        onClick={handleRecheckStatus}
        isLoading={isFetchingCheckTtiServiceRunning}
        disabled={areButtonsRecheckingStatusDisabled}
        title="Retrieve missing transmission / acknowledgement files"
      >
        RETRIEVE ACKNOWLEDGEMENT
      </Button>
    ),
    [areButtonsRecheckingStatusDisabled, handleRecheckStatus, isFetchingCheckTtiServiceRunning],
  );

  return isRetrieveAcknowledgementButtonVisible ? retrieveAcknowledgementButton() : null;
};

export default useRetrieveAcknowledgementButton;
