const Yup = require('yup');
const { DueDateOptionValues } = require('@common-packages/shared-constants');

const { FilingMethods } = require('../../commonPackages/stateHelpers/stateHelpers');
const { dateRegex } = require('../sharedConstants/dateRegex');

const {
  needFiscalYearEndErrorMessage,
  needDueDateFromFilingFormErrorMessage,
  dueDateRequiredErrorMessage,
} = require('./constants');

const getDueDateCommonSchema = ({ dueDateOption, entityFiscalYearEnd }) =>
  Yup.number()
    .oneOf(Object.values(DueDateOptionValues), dueDateRequiredErrorMessage)
    .nullable()
    .label('Due Date')
    .test(
      'hasFiscalYearEnd',
      needFiscalYearEndErrorMessage,
      (_, context) => Boolean(entityFiscalYearEnd) || Boolean(context.parent.taxYearEnding),
    )
    .defined(dueDateRequiredErrorMessage)
    .when('returnDefinitionId', {
      is: value => Boolean(value),
      then: Yup.number()
        .typeError(dueDateRequiredErrorMessage)
        .oneOf(Object.values(DueDateOptionValues), dueDateRequiredErrorMessage)
        .test(
          'hasOptions',
          needDueDateFromFilingFormErrorMessage,
          (_, context) => dueDateOption?.length || context.parent.dueDateOption?.length,
        )
        .label('Due Date')
        .required(),
    });

const getDueDateSchema = ({ shouldDisplayDueDate, dueDateOption, entityFiscalYearEnd }) => {
  return shouldDisplayDueDate
    ? {
        dueDateType: Yup.number()
          .nullable()
          .label('Due Date')
          .when('consolidationId', {
            is: value => Boolean(value),
            then: getDueDateCommonSchema({
              dueDateOption,
              entityFiscalYearEnd,
            }),
            otherwise: Yup.number()
              .nullable()
              .label('Due Date')
              .when('filingMethod', {
                is: FilingMethods.NON_FILING,
                then: Yup.number().label('Due Date').nullable(),
                otherwise: getDueDateCommonSchema({
                  dueDateOption,
                  entityFiscalYearEnd,
                }),
              }),
          }),
      }
    : {};
};

const getFiscalYearEndSchema = Yup.object()
  .shape({
    fiscalYearEnd: Yup.string()
      .label('Tax Year Ending')
      .matches(dateRegex, 'Invalid Tax Year Ending data'),
  })
  .strict();

module.exports = { getDueDateSchema, getFiscalYearEndSchema };
