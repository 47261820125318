import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import { AuthorizedFilingGroupRoute } from './routeConfiguration/filingGroupRoute.component';
import FilingMembers from './filingMembers/filingMembers.container';
import FilingGroupIdValue from './routeConfiguration/filingGroupIdValue.container';
import FilingGroups from './filingGroups.container';

const FilingGroupRoutes = () => (
  <Switch>
    <AuthorizedFilingGroupRoute
      path={Routes.filingGroupMembers.MAIN}
      component={FilingMembers}
      additionalValues={<FilingGroupIdValue />}
    />
    <AuthorizedFilingGroupRoute path={Routes.filingGroups.MAIN} component={FilingGroups} />
  </Switch>
);

export default FilingGroupRoutes;
