import React from 'react';
import PropTypes from 'prop-types';
import { Routes, taxNames } from '@common-packages/routes-definitions';

import {
  LinkCellRendererFactory,
  TextCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import { filingTypes } from '../../shared/constants';
import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';

import { LinesToFollowTypes } from './constants';

const ValueTextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const DrillLinkCellRenderer = props => {
  const {
    isOrg,
    orgId,
    taxYear,
    period,
    jurisdictionId,
    data: { accountId, categoryId },
  } = props;

  if (categoryId && isOrg) {
    const GenericCategoryLink = LinkCellRendererFactory({
      getText: ({ valueFormatted }) => valueFormatted,
      getPathName: (_value, data) =>
        Routes.genericDrillCategory.compiledRoute({
          ...getContextAwareUrlParams({
            taxYear,
            period,
            filingTypeId: filingTypes.SEPARATE,
            businessEntityId: orgId,
            jurisdictionId,
            taxName: taxNames.INCOME_TAX,
          }),
          categoryId: data.categoryId,
        }),
      getQueryParams: (_value, data) =>
        data.linesToFollow === LinesToFollowTypes.TOTAL ? '' : `accountId=${accountId}`,
    });

    return <GenericCategoryLink {...props} />;
  }

  return <ValueTextRenderer {...props} />;
};

DrillLinkCellRenderer.propTypes = {
  data: PropTypes.shape({
    categoryId: PropTypes.string,
    accountId: PropTypes.string,
    linesToFollow: PropTypes.oneOf(Object.values(LinesToFollowTypes)),
    description: PropTypes.string.isRequired,
  }).isRequired,
  isOrg: PropTypes.bool.isRequired,
  orgId: PropTypes.string,
  taxYear: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  jurisdictionId: PropTypes.string.isRequired,
};

export default DrillLinkCellRenderer;
