import { httpGetAndParse, httpPut } from '../utils/fetchService';

export const fetchForms = ({ taxYear, period, filingTypeId, businessEntityId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/state-specific-information-v2/forms/${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}`,
    errorMessage: 'Fetching state specific information forms failed',
  });

export const fetchFormFields = ({ period, businessEntityId, formId }) =>
  httpGetAndParse({
    route: `/api/shared/state-specific-information-v2/${period}/${businessEntityId}/${formId}`,
    errorMessage: 'Fetching state specific information form fields failed',
  });

export const updateFormFields = ({ rows, taxYear, period, businessEntityId, jurisdictionId }) =>
  httpPut({
    route: '/api/shared/state-specific-information-v2',
    errorMessage: 'Updating state specific information form fields failed',
    body: { rows, taxYear, period, businessEntityId, jurisdictionId },
  });
