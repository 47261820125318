import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchBusinessesTypes,
  createBusinessTypes,
  updateBusinessTypes,
  deleteBusinessTypes,
} from './types';

export const fetchBusinesses = apiAction({
  types: fetchBusinessesTypes,
  apiAction: api.fetchBusinesses,
});

export const createBusiness = apiAction(
  {
    types: createBusinessTypes,
    apiAction: api.createBusiness,
  },
  { successNotificationMessage: () => 'Business has been successfully created' },
);

export const updateBusiness = apiAction(
  {
    types: updateBusinessTypes,
    apiAction: api.updateBusiness,
  },
  { successNotificationMessage: () => 'Business has been successfully updated' },
);

export const deleteBusiness = apiAction(
  {
    types: deleteBusinessTypes,
    apiAction: api.deleteBusiness,
  },
  { successNotificationMessage: () => 'Business has been successfully deleted' },
);
