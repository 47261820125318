import { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';

import {
  FilingAttributes,
  FilingAttributeGroup,
  TaxReturnDefinitionWithFilingAttributes,
} from '../../../../common/types/apiShapes';
import { DisplayedAttributes } from '../../shared/enums';

import { FormValues } from './editConsolidationForm.component';
import { RETURN_TYPE_FIELD } from './constants';

interface UseDisabledFilingAttributesParams {
  filingAttributes: FilingAttributeGroup[];
  returnDefinitions: TaxReturnDefinitionWithFilingAttributes[];
}

const useDisabledFilingAttributes = ({
  filingAttributes,
  returnDefinitions,
}: UseDisabledFilingAttributesParams) => {
  const { setValues, values } = useFormikContext<FormValues>();

  const getNewDisabledFilingAttributes = useCallback(
    (returnDefinitionFilingAttributes: FilingAttributes) => {
      const newValues: Partial<FormValues> = {};
      const filingAttributesFields = filingAttributes.flatMap(({ children }) =>
        children.map(({ field }) => field),
      );

      const newDisabledFilingAttributes = filingAttributesFields.reduce(
        (acc: (keyof FilingAttributes)[], field) =>
          returnDefinitionFilingAttributes?.[field] ? acc : [...acc, field],
        [],
      );

      newDisabledFilingAttributes.forEach(attribute => {
        newValues[attribute] = false;
        if (
          attribute.startsWith(DisplayedAttributes.RETURN_TYPE) &&
          values[RETURN_TYPE_FIELD] === attribute
        ) {
          newValues[RETURN_TYPE_FIELD] = null;
        }
      });

      filingAttributes.forEach(attributeGroup => {
        const disabledChildrenCount = attributeGroup.children.reduce(
          (acc, { field }) => (newDisabledFilingAttributes.includes(field) ? acc + 1 : acc),
          0,
        );

        // if there is only enabled attribute in the group
        if (disabledChildrenCount === attributeGroup.children.length - 1) {
          const singleEnabledAttribute = attributeGroup.children.find(
            ({ field }) => !newDisabledFilingAttributes.includes(field),
          );
          // technically this if is unnecessary but TS requires it
          if (singleEnabledAttribute) {
            // return type is handled by radio buttons
            if (singleEnabledAttribute.field.startsWith(DisplayedAttributes.RETURN_TYPE)) {
              newValues[RETURN_TYPE_FIELD] = singleEnabledAttribute.field;
            } else {
              newValues[singleEnabledAttribute.field] = true;
            }
            newDisabledFilingAttributes.push(singleEnabledAttribute.field);
          }
        }
      });

      setValues(currentValues => ({
        ...currentValues,
        ...newValues,
      }));
      return newDisabledFilingAttributes;
    },
    [setValues, filingAttributes, values],
  );

  const [disabledFilingAttributes, setDisabledFilingAttributes] = useState<string[]>(() => {
    const returnDefinition = returnDefinitions.find(({ id }) => id === values.returnDefinitionId);
    return returnDefinition
      ? getNewDisabledFilingAttributes(returnDefinition.filingAttributes)
      : [];
  });

  return { disabledFilingAttributes, setDisabledFilingAttributes, getNewDisabledFilingAttributes };
};

export default useDisabledFilingAttributes;
