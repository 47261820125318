import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Checkbox } from '@pwc/appkit-react';

import './transitionList.styles.scss'; // these styles can't be scoped to work with transition group

interface TransitionListProps {
  listClassName?: string;
  listItemClassName?: string;
  items: {
    id: string;
    label: string;
  }[];
  isItemSelected: (id: string) => boolean;
  onItemSelectionChange: (id: string) => unknown;
}

const TransitionList = ({
  listClassName = '',
  listItemClassName = '',
  items,
  isItemSelected,
  onItemSelectionChange,
}: TransitionListProps) => (
  <TransitionGroup className={listClassName} component="ul">
    {items.map(({ id, label }) => (
      <CSSTransition key={id} timeout={200} classNames="transition-list-item">
        <li className={listItemClassName}>
          <Checkbox checked={isItemSelected(id)} onChange={onItemSelectionChange(id)}>
            {label}
          </Checkbox>
        </li>
      </CSSTransition>
    ))}
  </TransitionGroup>
);

export default TransitionList;
