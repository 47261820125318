import { OrgProfileData } from '@tls/slt-types';

import { QueryError, QueryKeys } from '../queries';
import { HttpMethods } from '../enums';

import { useCustomMutation } from '.';

export interface UpdateOrgProfileMutationVariable {
  values: { values: OrgProfileData }[];
  taxYear?: number;
  period?: number;
  jurisdictionId?: string;
}

export const useMutationUpdateOrgProfile = () =>
  useCustomMutation<null, QueryError, UpdateOrgProfileMutationVariable>({
    handleData: ({ values }) => ({
      url: '/api/filing-decisions-v2/update-org-profile',
      method: HttpMethods.PUT,
      body: {
        values,
      },
    }),
    resetKey: query =>
      query.queryKey?.[0] === QueryKeys.FilingDecisions.OrgProfileData ||
      query.queryKey?.[0] === QueryKeys.FilingDecisions.ConsolidationData,
    successMessage: 'Updating filing decisions succeeded',
    errorMessage: 'Updating filing decisions failed',
  });
