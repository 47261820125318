import { defaultColumnDefinition, adminColumnDefinition } from '../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onEditIconClick, onDeleteIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onEditIconClick,
    onDeleteIconClick,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      width: 80,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Role',
      field: 'roleName',
      width: 250,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
