import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import useAppWithoutNavigator from '../shared/hooks/useAppWithoutNavigator.hook';

import {
  useFetchTaxYears,
  useFetchPeriods,
  useFetchConsolidations,
  useFetchJurisdictions,
} from './store/hooks';
import DevelopmentRoute from './routeConfiguration/developmentRoute.container';
import TaxForms from './taxForms/taxForms.container';
import TaxFormsV2 from './taxFormsV2/taxForms.container';
import TaxForm from './taxFormsV2/taxForm.container';
import GenericFunctionsRoutes from './genericFunctions/routes.container';
import GenericScreenDefinitionRoutes from './genericScreenDefinition/routes.container';
import DataModels from './dataModels/dataModels.container';
import CalcSpecs from './calcSpecs/calcSpecs.container';
import DefaultBinders from './defaultBinders/defaultBinders.container';
import AttachmentDefinitions from './attachmentDefinitions/attachmentDefinitions.container';
import JimJobsHistory from './jimJobsHistory/jimJobsHistory.component';
import ExpressionBuilder from './expressionBuilder/expressionBuilder.container';
import CompileExpressions from './expressionBuilder/compileExpressions/compileExpressions.container';
import Watermarks from './watermarks/watermarks.container';
import CompileExpressionsReport from './expressionBuilder/compileExpressionsReport/compileExpressionsReport.container';
import SelectionLists from './selectionLists/selectionLists.container';
import ContentDeployment from './contentDeployment/contentDeployment.component';
import CalcPreferences from './calcPreferences/calcPreferences.container';
import DisallowOverrideReport from './expressionBuilder/compileExpressions/disallowOverrideReport/disallowOverrideReport.container';
import CheckboxGroups from './checkboxGroups/checkboxGroups.component';
// import ElementMappings from './eFile/elementMappings.container';
import ReturnDefinitions from './returnDefinitions/returnDefinitions.container';

const DevelopmentRoutes = () => {
  useAppWithoutNavigator();

  useFetchTaxYears();
  useFetchPeriods();
  useFetchConsolidations();
  useFetchJurisdictions();

  return (
    <Switch>
      <DevelopmentRoute
        path={Routes.developmentTaxFormEditV2.MAIN}
        component={TaxForm}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.developmentTaxForms.MAIN}
        component={TaxForms}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.developmentTaxFormsV2.MAIN}
        component={TaxFormsV2}
        hideGlobalContext
      />
      <Route path={Routes.developmentGenericFunctions.MAIN} component={GenericFunctionsRoutes} />
      <Route path={Routes.genericScreenDefinition.MAIN} component={GenericScreenDefinitionRoutes} />
      <DevelopmentRoute
        exact
        path={Routes.developmentDataModels.MAIN}
        component={DataModels}
        hideGlobalContext
      />
      <DevelopmentRoute
        exact
        path={Routes.developmentCalcSpecs.MAIN}
        component={CalcSpecs}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.defaultBinders.MAIN}
        component={DefaultBinders}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.attachmentDefinitions.MAIN}
        component={AttachmentDefinitions}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.jimJobsHistory.MAIN}
        component={JimJobsHistory}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.expressionBuilder.MAIN}
        exact
        component={ExpressionBuilder}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.compileExpressions.MAIN}
        exact
        component={CompileExpressions}
        hideGlobalContext
      />
      <DevelopmentRoute path={Routes.watermarks.MAIN} component={Watermarks} hideGlobalContext />
      <DevelopmentRoute
        path={Routes.compileExpressionsReport.MAIN}
        component={CompileExpressionsReport}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.selectionLists.MAIN}
        component={SelectionLists}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.contentDeployment.MAIN}
        component={ContentDeployment}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.calcPreferences.MAIN}
        component={CalcPreferences}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.disallowOverrideReport.MAIN}
        component={DisallowOverrideReport}
        hideGlobalContext
      />
      <DevelopmentRoute
        path={Routes.checkboxGroups.MAIN}
        component={CheckboxGroups}
        hideGlobalContext
      />
      {/* // NOTE: SLT-14465 Turn Off E-file Element Mapping Tool
      <DevelopmentRoute
        path={Routes.eFileElementMappings.MAIN}
        component={ElementMappings}
        hideGlobalContext
      /> */}
      <DevelopmentRoute
        path={Routes.returnDefinitions.MAIN}
        component={ReturnDefinitions}
        hideGlobalContext
      />
    </Switch>
  );
};

DevelopmentRoutes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default DevelopmentRoutes;
