/* eslint-disable babel/no-invalid-this */
const Yup = require('yup');

const getAddEditBusinessSchema = ({ businesses = [], isEditMode = false }) =>
  Yup.object().shape({
    businessId: Yup.string()
      .label('Business')
      .max(10, 'Business cannot exceed 10 characters')
      .uniqueInList({
        message: 'Business Id already exists, a unique Id is required before saving',
        list: businesses,
        mapper: ({ businessId }) => businessId,
        shouldSkipTest: isEditMode,
      })
      .required(),
    businessDescription: Yup.string()
      .label('Description')
      .max(100, 'Description cannot exceed 100 characters')
      .required(),
    leadCustomerId: Yup.string().label('Lead').required(),
    businessGroup: Yup.string().label('Business Group').required(),
    tier: Yup.number().label('Tier').nullable(),
    sirYN: Yup.bool().label('SIR').required(),
    sirRollupBusId: Yup.string()
      .label('Parent')
      .max(10, 'Parent cannot exceed 10 characters')
      .nullable(),
  });

module.exports = { getAddEditBusinessSchema };
