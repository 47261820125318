import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { ColumnTypes } from '@common-packages/shared-constants';

import {
  mapValueFormatterFactory,
  defaultColumnDefinitionSuppressMovable,
} from '../shared/columnDefinitions';
import {
  FilingAttributesColumnBlueprint,
  FindReturnStatusDefinition,
  FindConsolidationMemberData,
} from '../../../common/types';
import {
  DisplayedAttributes,
  DisplayedReturnTypeAttributes,
  DisplayedBusinessTypeAttributes,
} from '../shared/enums';
import getColumnBlueprintBasedColumnDefinitions from '../shared/columnDefinitions/columnsBlueprintEditableWithoutGroup.columnDefinition';
import { CalcPreferenceColumnBlueprint, ColumnsBlueprint } from '../../../routes/shared/types';
import { cellRenderer } from '../shared/columnDefinitions/cellRenderers/cellRenderer.component';
import { dueDateCellRenderer } from '../shared/columnDefinitions/cellRenderers/dueDateCellRenderer.component';
import { FILING_METHOD_OPTIONS } from '../shared/constants';

import { getColumnBlueprintValue, taxTypeValueFormatterFactory, taxTypeValueGetter } from './utils';

export const getColumnBlueprintSelectionList = (
  param: ICellEditorParams,
  columnBlueprint?: FilingAttributesColumnBlueprint,
) => {
  const newData = {
    selectionListItems: (columnBlueprint?.children || []).map(child => ({
      value: child?.field,
      label: child?.displayName,
    })),
  };

  return {
    ...param,
    data: newData,
  };
};

export const getConsolidationDetailsColumnDefinitions = ({
  columnsBlueprint,
  taxReturnStatusOptions,
  shouldDisplayReturnsDueDates,
}: {
  columnsBlueprint: ColumnsBlueprint;
  taxReturnStatusOptions?: FindReturnStatusDefinition;
  shouldDisplayReturnsDueDates: boolean;
}) => {
  const returnTypeColumnBlueprint = columnsBlueprint.find(
    column => DisplayedAttributes.RETURN_TYPE === (column as FilingAttributesColumnBlueprint).field,
  ) as FilingAttributesColumnBlueprint;
  const businessTypeColumnBlueprint = columnsBlueprint.find(
    column =>
      DisplayedAttributes.BUSINESS_TYPE === (column as FilingAttributesColumnBlueprint).field,
  ) as FilingAttributesColumnBlueprint;
  const taxTypeColumnBlueprint = columnsBlueprint.find(
    column => DisplayedAttributes.TAX_TYPE === (column as FilingAttributesColumnBlueprint).field,
  ) as FilingAttributesColumnBlueprint;
  const commonAndStateCalcColumnsBlueprint = columnsBlueprint.filter(
    column => column.columnType === ColumnTypes.CALC_PREFERENCES,
  ) as CalcPreferenceColumnBlueprint[];

  const taxTypeValueFormatter = taxTypeValueFormatterFactory(taxTypeColumnBlueprint);

  const columnDefinitions = [
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Cons ID',
      field: 'consolidationId',
      width: 100,
      pinned: true,
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Consolidation Description',
      field: 'description',
      width: 205,
      pinned: true,
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Filing Group(s)',
      field: 'filingGroupsIds',
      width: 175,
      cellRenderer: (params: ICellRendererParams) =>
        cellRenderer({
          params,
          renderer: ({ value }: { value: Array<string> }) => (value ? value.join(', ') : ''),
        }),
      pinned: true,
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Filing Method',
      field: 'filingMethod',
      valueFormatter: mapValueFormatterFactory(FILING_METHOD_OPTIONS),
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Return Status',
      field: 'returnStatus',
      hide: !shouldDisplayReturnsDueDates,
      valueFormatter: mapValueFormatterFactory(taxReturnStatusOptions?.returnStatusOptions),
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Due Date',
      field: 'dueDate',
      hide: !shouldDisplayReturnsDueDates,
      cellRenderer: dueDateCellRenderer,
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Return Type',
      field: DisplayedAttributes.RETURN_TYPE,
      width: 175,
      valueGetter: ({ data }: { data: FindConsolidationMemberData }) =>
        getColumnBlueprintValue(
          Object.values(DisplayedReturnTypeAttributes).find(
            attributeKey => data[attributeKey],
          ) as string,
          returnTypeColumnBlueprint,
        ),
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Business Type',
      width: 175,
      valueGetter: ({ data }: { data: FindConsolidationMemberData }) =>
        getColumnBlueprintValue(
          Object.values(DisplayedBusinessTypeAttributes).find(
            attributeKey => data[attributeKey],
          ) as string,
          businessTypeColumnBlueprint,
        ),
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Tax Type',
      width: 175,
      valueGetter: taxTypeValueGetter,
      valueFormatter: taxTypeValueFormatter,
    },
    {
      ...defaultColumnDefinitionSuppressMovable,
      headerName: 'Nexus',
      field: 'nexusInd',
      width: 100,
    },
    ...getColumnBlueprintBasedColumnDefinitions({
      updateRow: () => null,
      isInEditMode: false,
      columnsBlueprint: commonAndStateCalcColumnsBlueprint,
      defaultColumnConfig: defaultColumnDefinitionSuppressMovable,
    }),
  ];
  return columnDefinitions;
};
