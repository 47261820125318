import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { filingTypes } from '@tls/state-helpers';
import { OverrideReportTypes } from '@common-packages/shared-constants';

import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import useReportData from '../../shared/reports/useReportData.hook';
import getReportUrl from '../shared/getReportUrl';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import styles from '../../shared/reports/reportParam.module.scss';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import { filingTypeIdSelector } from '../../shared/store/selectors';

import getColumnDefinitions from './overrideReport.columnDefinitions';
import useOverrideReportParams from './useOverrideReportParams.hook';

const OverrideReport = () => {
  const filingTypeId = useSelector(filingTypeIdSelector);

  const endpointBasePath = getReportUrl('override-report');

  const isSeparate = useMemo(() => filingTypeId === filingTypes.SEPARATE, [filingTypeId]);
  const [useBusinessId, setUseBusinessId] = useState(OverrideReportTypes.ENTITY_ID);

  const reportTypeLabel = useMemo(() => {
    if (filingTypeId === filingTypes.SEPARATE) {
      if (useBusinessId === OverrideReportTypes.BUSINESS_ID) {
        return 'Business Id';
      }
      return 'Entity';
    }
    return 'Consolidation';
  }, [filingTypeId, useBusinessId]);

  const handleBusinessIdOrEntityIdSelect = useCallback(
    newUseBusinessId => {
      setUseBusinessId(newUseBusinessId);
    },
    [setUseBusinessId],
  );

  const {
    taxYearParam,
    periodParam,
    businessEntityIdParam,
    jurisdictionIdParam,
  } = useOverrideReportParams({ endpointBasePath, isSeparate, useBusinessId });

  const isFetchingAnyParam = checkIsFetchingAnyParam([
    taxYearParam,
    periodParam,
    businessEntityIdParam,
    jurisdictionIdParam,
  ]);

  const {
    reportData,
    isFetchingReportData,
    runReportButton,
    showGrid,
    areParamsReady,
    fetchData,
  } = useReportData({
    endpointBasePath,
    isFetchingAnyParam,
    method: 'POST',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        filingTypeId,
        jurisdictionId: jurisdictionIdParam.paramValue,
        businessEntityId: businessEntityIdParam.paramValue,
        useBusinessId,
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        filingTypeId,
        jurisdictionIdParam.paramValue,
        businessEntityIdParam.paramValue,
        useBusinessId,
      ],
    ),
  });

  useEffect(() => {
    if (areParamsReady) {
      fetchData();
    }
  }, [fetchData, areParamsReady]);

  const columnDefinitions = useMemo(() => getColumnDefinitions({ isSeparate }), [isSeparate]);

  const { props, onGridReady } = useActionMenuForExport({ reportData });

  return (
    <>
      {isSeparate && (
        <div className="row">
          <div className={`col ${styles.reportParam}`}>
            <ParamDropdown
              label="Select Id for Report"
              value={useBusinessId}
              options={[
                {
                  value: OverrideReportTypes.BUSINESS_ID,
                  label: 'Business Id',
                },
                { value: OverrideReportTypes.ENTITY_ID, label: 'Entity Id' },
              ]}
              handleChange={handleBusinessIdOrEntityIdSelect}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown label="Jurisdiction" param={jurisdictionIdParam} />
          <ReportParamDropdown label={reportTypeLabel} param={businessEntityIdParam} />
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
              sideBar={defaultSideBarWithColumnsToolPanel}
              withSearchBar
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OverrideReport;
