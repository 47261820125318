import { pdfjs } from 'react-pdf';

const ensureHasLeadingSlash = basePath => {
  if (!basePath) {
    return '';
  }

  if (basePath.startsWith('/')) {
    return basePath;
  }

  return `/${basePath}`;
};

const eightHours = 28800;
const thirtyMinutes = 1800;
const fiveMinutes = 300;

const config = {
  AUTH: {
    SIGN_OUT_URL: '/api/logoutRedirect',
    WINDOW_NAME: 'Sign Out',
    ID_TOKEN_KEY: 'id_token',
    REFRESH_TOKEN_KEY: 'refresh_token',
    EXPIRES_IN_KEY: 'expiration_date',
    JWT_EXPIRE_IN: process.env.REACT_APP_JWT_EXPIRE_IN
      ? parseInt(process.env.REACT_APP_JWT_EXPIRE_IN, 10)
      : eightHours,
    JWT_EXPIRE_IN_INACTIVITY: process.env.REACT_APP_JWT_EXPIRE_IN_INACTIVITY
      ? parseInt(process.env.REACT_APP_JWT_EXPIRE_IN_INACTIVITY, 10)
      : thirtyMinutes,
    JWT_REFRESH_TIME: process.env.REACT_APP_JWT_REFRESH_TIME
      ? parseInt(process.env.REACT_APP_JWT_REFRESH_TIME, 10)
      : fiveMinutes,
  },

  AG_GRID_LICENSE:
    process.env.REACT_APP_AGGRID_LICENSE ||
    'Using_this_{AG_Grid}_Enterprise_key_{AG-051065}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{PricewaterhouseCoopers}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{State_Lifecycle_Tool_(SLT)}_only_for_{12}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{State_Lifecycle_Tool_(SLT)}_need_to_be_licensed___{State_Lifecycle_Tool_(SLT)}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_December_2024}____[v3]_[01]_MTczNTYwMzIwMDAwMA==a7a96a791c8b61bfc3d93e4c2a4854d6',

  DATE_FORMAT: 'M/d/yy',

  AMERICAN_DATE_FORMAT: 'MM/dd/yyyy',

  AMERICAN_DATE_TIME_FORMAT: 'MM/dd/yyyy HH:mm:ss',

  AMERICAN_DATE_TIME_FORMAT_12_HOUR: 'MM/dd/yyyy hh:mm:ss',

  PDF_JS_WORKER_SOURCE: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,

  BASE_PATH: ensureHasLeadingSlash(process.env.PUBLIC_URL),

  FEDERAL_REFRESH_CHUNK_SIZE: process.env.REACT_APP_FEDERAL_REFRESH_CHUNK_SIZE || 50,

  DOCS_LINKS: {
    FIELD_ATTRIBUTES_HELP:
      process.env.REACT_APP_DOCS_FIELD_ATTRIBUTES_HELP ||
      'https://docs.google.com/spreadsheets/d/1NZhhjmekj-nDxmi7cn5fc_Ur0rbdsW0m425cQAhXV2g/edit#gid=1072311628',
  },

  GTW_FILING_GROUPS_LINK:
    process.env.REACT_APP_GTW_FILING_GROUPS_LINK ||
    'https://gtw.pwc.com/gtw/#/workspace/state-entities-groups',

  DISPLAY_IN_DEVELOPMENT_FORMS:
    process.env.REACT_APP_DISPLAY_INDEVELOPMENT_FORMS?.toLowerCase() === 'true',

  PENDO_KEY: process.env.REACT_APP_PENDO_KEY,

  DISPLAY_RETURNS_TAB: process.env.REACT_APP_DISPLAY_RETURNS_TAB?.toLowerCase() === 'true',

  DISPLAY_BINDERS_TAB: process.env.REACT_APP_DISPLAY_BINDERS_TAB?.toLowerCase() !== 'false', // checking against 'false' instead of 'true' so that absence of the variable does not change existing behavior of the site

  ALLOW_REVALIDATION_OF_SUBMITTED_RETURN:
    process.env.REACT_APP_ALLOW_REVALIDATION_OF_SUBMITTED_RETURN?.toLowerCase() === 'true',
  USE_SERVER_SIDE_EVENTS: process.env.REACT_APP_USE_SERVER_SIDE_EVENTS?.toLowerCase() === 'true', // Use alternative approach to Server Side Events

  NOTIFICATIONS_POLLING_INTERVAL:
    Number(process.env.REACT_APP_NOTIFICATIONS_POLLING_INTERVAL) || 20000,

  EFILE_WIZARD_POLLING_INTERVAL:
    Number(process.env.REACT_APP_EFILE_WIZARD_POLLING_INTERVAL) || 40000,

  DISPLAY_SETUP_CONSOLIDATED_RETURNS:
    process.env.REACT_APP_DISPLAY_SETUP_CONSOLIDATED_RETURNS?.toLowerCase() === 'true',

  DISPLAY_RETURNS_STATUS: process.env.REACT_APP_DISPLAY_RETURNS_STATUS?.toLowerCase() === 'true',

  DISPLAY_RETURNS_DUEDATES:
    process.env.REACT_APP_DISPLAY_RETURNS_DUEDATES?.toLowerCase() === 'true',

  ALLOW_REVALIDATION_WHEN_RETURN_IS_ACCEPTED:
    process.env.REACT_APP_ALLOW_REVALIDATION_WHEN_RETURN_IS_ACCEPTED?.toLowerCase() === 'true',

  PWC_EIN_UPDATE_DATE: process.env.REACT_APP_PWC_EIN_UPDATE_DATE || '07/01/2023',
};

export default config;
