import PropTypes from 'prop-types';

export const calcPreferencePropType = PropTypes.shape({
  columnType: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  selectOptions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        dataType: PropTypes.string,
        defaultValue: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
        displayGroup: PropTypes.string,
        displayName: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  ).isRequired,
});

export const calcPreferencesPropTypes = PropTypes.shape({
  columnType: PropTypes.string,
  displayName: PropTypes.string,
  selectOptions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
});
