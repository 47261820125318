import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Tooltip } from '@pwc/appkit-react';

import stylesShared from '../../shared/reports/reportParam.module.scss';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import { entityIdSelector } from '../../shared/store/selectors';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import useReportData from '../../shared/reports/useReportData.hook';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import { useMutationInitiateSeparatePopulate } from '../../shared/mutations/multiStateLeTaxableIncome';

import getColumnDefinitions from './multiStateLeTaxableIncome.columnDefinitions';
import stylesReport from './styles.module.scss';

const isExternalFilterPresent = () => true;
const endpointBasePath = '/api/reports/multi-state-le-taxable-income';

const MultiStateLeTaxableIncome = () => {
  const entityId = useSelector(entityIdSelector);

  const [suppressZeros, setSuppressZeros] = useState(true);
  // we are using a REF for ag grid filter
  // because it saves the first doesExternalFilterPass function reference
  // that reference has a closure on `suppressZero`, which never gets updated because
  // new references of filter function are not used by ag Grid
  // so we use a ref in the initial filter function to be able to update the filter
  // results during the lifetime of the grid with changing checkbox
  const agGridFilter = useRef(null);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    defaultParamValue: entityId,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([taxYearParam, periodParam, entityIdParam]);

  const {
    mutateAsync: initiateSeparatePopulate,
    isLoading: isInitiatingSeparatePopulate,
  } = useMutationInitiateSeparatePopulate();

  const handleRefreshData = useCallback(() => {
    initiateSeparatePopulate({
      taxYear: taxYearParam.paramValue,
      period: periodParam.paramValue?.toString(),
      entityId: entityIdParam.paramValue,
    });
  }, [
    initiateSeparatePopulate,
    taxYearParam.paramValue,
    periodParam.paramValue,
    entityIdParam.paramValue,
  ]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam: isFetchingAnyParam || isInitiatingSeparatePopulate,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        entityId: entityIdParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue, entityIdParam.paramValue],
    ),
  });

  const { columnsBlueprint, rowData } = reportData || { columnsBlueprint: [], rowData: [] };

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        columnsBlueprint,
      }),
    [columnsBlueprint],
  );

  const { props, onGridReady, gridApi } = useActionMenuForExport({
    reportData: rowData,
  });

  const toggleSuppressZeros = useCallback(() => {
    setSuppressZeros(!suppressZeros);
  }, [setSuppressZeros, suppressZeros]);

  useEffect(() => {
    // reset suppress zeros after hiding grid
    if (!showGrid) {
      setSuppressZeros(true);
    }
  }, [showGrid]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    agGridFilter.current = data => {
      const isRowHiddenWithSuppress = suppressZeros ? data.noData : false;
      const areRowChildrenHidden = !data.showRow;

      const shouldShowRow = !isRowHiddenWithSuppress && !areRowChildrenHidden;

      return shouldShowRow;
    };

    gridApi.onFilterChanged();
  }, [gridApi, suppressZeros]);

  const doesExternalFilterPass = useCallback(
    ({ data }) => (!agGridFilter.current ? true : agGridFilter.current(data)),
    [],
  );

  return (
    <>
      <div className="row">
        <div className={`col ${stylesShared.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown label="Entity" param={entityIdParam} />
          <div>
            <Button
              size="lg"
              onClick={handleRefreshData}
              className={stylesReport.refreshDataButton}
              disabled={isFetchingAnyParam || isFetchingReportData || isInitiatingSeparatePopulate}
            >
              Refresh Data
            </Button>
            {runReportButton}
          </div>
        </div>
      </div>
      <ActionMenuForExport {...props} />
      {showGrid && (
        <>
          <div className="row">
            <div className="col">
              <Tooltip content="Hides account rows that contain all zero values" placement="top">
                <Checkbox
                  className={stylesReport.suppressZerosCheckbox}
                  checked={suppressZeros}
                  onChange={toggleSuppressZeros}
                >
                  Suppress Zeros
                </Checkbox>
              </Tooltip>
            </div>
          </div>
          <div className="row grid-row">
            <div className="col">
              <AgGrid
                rowData={rowData}
                columnDefs={columnDefinitions}
                isGridLoading={isFetchingReportData}
                withSearchBar
                animateRows
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                onGridReady={onGridReady}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MultiStateLeTaxableIncome;
