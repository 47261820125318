import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';
import { filingGroupsSchemas } from '@common-packages/validators';

import Loading from '../shared/displayComponents/loading.component';
import { periodNameSelector, periodSelector, taxYearSelector } from '../shared/store/selectors';
import { SelectOptionPropTypes } from '../shared/propTypes/selectOption';

import {
  jurisdictionForFilingGroupsOptionsSelector,
  isFetchingJurisdictionsForFilingGroupsSelector,
} from './store/selectors';
import { updateFilingGroup, fetchFilingGroups, createFilingGroup } from './store/actions';
import AddEditFilingGroupForm from './addEditFilingGroupForm.component';
import { filingGroupPropTypes } from './propTypes';

const AddEditFilingGroupModal = ({
  hideModal,
  visible,

  taxYear,
  periodName,
  period,
  filingGroupToEdit,
  filingGroups,
  updateFilingGroup,
  createFilingGroup,
  fetchFilingGroups,
  isFetchingJurisdictionsForFilingGroups,
  jurisdictionsOptions,
}) => {
  const formInitialValues = {
    taxYear: taxYear ? taxYear : '',
    period: periodName ? periodName : '',
    jurisdictionId: '',
    filingGroupId: '',
    description: '',
    returnTask: false,
    separateLimit: true,
    efile: false,
  };

  const saveFilingGroup = useCallback(
    async ({ jurisdictionId, filingGroupId, description, returnTask, separateLimit, efile }) => {
      const data = {
        taxYear,
        period,
        jurisdictionId,
        filingGroupId,
        description,
        returnTask,
        separateLimit,
        efile,
      };
      if (!filingGroupToEdit) {
        await createFilingGroup(data);
      } else {
        await updateFilingGroup(data);
      }
      hideModal();
      fetchFilingGroups({
        taxYear,
        period,
      });
    },
    [
      filingGroupToEdit,
      updateFilingGroup,
      createFilingGroup,
      fetchFilingGroups,
      taxYear,
      period,
      hideModal,
    ],
  );

  const renderModal = useCallback(
    formikProps => (
      <Modal
        title={`${filingGroupToEdit ? 'Edit' : 'Add'} Filing Group`}
        closeAction={hideModal}
        visible={visible}
        submitText={filingGroupToEdit ? 'Save' : 'Add'}
        submitAction={formikProps.submitForm}
        dismissText="Cancel"
        dismissAction={hideModal}
      >
        <Loading isLoading={isFetchingJurisdictionsForFilingGroups}>
          <AddEditFilingGroupForm
            {...formikProps}
            jurisdictionsOptions={jurisdictionsOptions}
            filingGroups={filingGroups}
            filingGroupToEdit={filingGroupToEdit}
          />
        </Loading>
      </Modal>
    ),
    [
      filingGroupToEdit,
      visible,
      hideModal,
      isFetchingJurisdictionsForFilingGroups,
      filingGroups,
      jurisdictionsOptions,
    ],
  );
  return (
    <Formik
      initialValues={
        filingGroupToEdit
          ? { ...filingGroupToEdit, taxYear, period: periodName }
          : formInitialValues
      }
      enableReinitialize
      validateOnBlur={false}
      onSubmit={saveFilingGroup}
      validationSchema={filingGroupsSchemas.createOrUpdateFilingGroupSchema}
    >
      {renderModal}
    </Formik>
  );
};

AddEditFilingGroupModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  taxYear: PropTypes.string,
  periodName: PropTypes.string,
  period: PropTypes.string,
  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  filingGroupToEdit: PropTypes.shape({
    filingGroupId: PropTypes.string.isRequired,
    jurisdictionId: PropTypes.string.isRequired,
    returnTask: PropTypes.bool.isRequired,
    separateLimit: PropTypes.bool.isRequired,
    efile: PropTypes.bool.isRequired,
  }),
  filingGroups: PropTypes.arrayOf(filingGroupPropTypes).isRequired,
  fetchFilingGroups: PropTypes.func.isRequired,
  updateFilingGroup: PropTypes.func.isRequired,
  createFilingGroup: PropTypes.func.isRequired,
  isFetchingJurisdictionsForFilingGroups: PropTypes.bool.isRequired,
};
export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    periodName: periodNameSelector(state),
    period: periodSelector(state),
    jurisdictionsOptions: jurisdictionForFilingGroupsOptionsSelector(state),
    isFetchingJurisdictionsForFilingGroups: isFetchingJurisdictionsForFilingGroupsSelector(state),
  }),
  {
    updateFilingGroup,
    fetchFilingGroups,
    createFilingGroup,
  },
)(AddEditFilingGroupModal);
