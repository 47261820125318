import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { selectNonFilingStatesJurisdiction } from '../store/actions';
import { nonFilingStatesSelector, isFetchingNonFilingStatesSelector } from '../store/selectors';

import getColumnDefinitions from './nonFilingStates.columnDefinitions';

function NonFilingStates({
  isFetchingNonFilingStates,
  nonFilingStates,
  selectNonFilingStatesJurisdiction,
}) {
  const columnDefinitions = useMemo(
    () => getColumnDefinitions({ selectNonFilingStatesJurisdiction }),
    [selectNonFilingStatesJurisdiction],
  );

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={nonFilingStates}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingNonFilingStates}
        />
      </div>
    </div>
  );
}

NonFilingStates.propTypes = {
  selectNonFilingStatesJurisdiction: PropTypes.func.isRequired,
  isFetchingNonFilingStates: PropTypes.bool.isRequired,
  nonFilingStates: PropTypes.arrayOf(
    PropTypes.shape({
      franchiseTaxInd: PropTypes.string,
      franchiseTaxYn: PropTypes.bool.isRequired,
      jurisdictionDescription: PropTypes.string.isRequired,
      jurisdictionId: PropTypes.string.isRequired,
      profileId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  taxSummariesRoute: PropTypes.func,
};

export default connect(
  state => ({
    nonFilingStates: nonFilingStatesSelector(state),
    isFetchingNonFilingStates: isFetchingNonFilingStatesSelector(state),
  }),
  {
    selectNonFilingStatesJurisdiction,
  },
)(NonFilingStates);
