import { Routes } from '@common-packages/routes-definitions';

import { adminColumnDefinition, defaultColumnDefinition } from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  LinkCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onDeleteIconClick, selectScreenId, queryParams }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    onDeleteIconClick,
  });

  const detailLinkRenderer = LinkCellRendererFactory({
    getText: () => 'Detail',
    getPathName: () => Routes.genericScreenDefinitionRowDetails.MAIN,
    getQueryParams: () => queryParams,
    clickHandler: (_, data) => selectScreenId(data.screenId),
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Screen Id',
      field: 'screenId',
      width: 220,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Screen Title',
      field: 'title',
      width: 250,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Category',
      field: 'categoryId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Account',
      field: 'accountId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Row',
      cellRenderer: detailLinkRenderer,
      cellStyle: {
        textAlign: 'center',
      },
      width: 70,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
