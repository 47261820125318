import { getApiActionTypes } from '../../shared/store/storeHelpers';

export const fetchFilingGroupsTypes = getApiActionTypes(
  'FEDERAL_PRO_FORMA_FILING_GROUPS/FETCH_FILING_GROUPS',
);
export const fetchAllJurisdictionsTypes = getApiActionTypes(
  'FEDERAL_PRO_FORMA_FILING_GROUPS/FETCH_ALL_JURISDICTIONS',
);
export const updateFilingGroupTypes = getApiActionTypes(
  'FEDERAL_PRO_FORMA_FILING_GROUPS/UPDATE_FILING_GROUP',
);
export const createFilingGroupTypes = getApiActionTypes(
  'FEDERAL_PRO_FORMA_FILING_GROUPS/CREATE_FILING_GROUP',
);

export const SELECT_FILING_GROUP_ID = 'FEDERAL_PRO_FORMA_FILING_GROUPS/SELECT_FILING_GROUP_ID';
export const SELECT_JURISDICTION_ID_FOR_FILING_GROUP =
  'FEDERAL_PRO_FORMA_FILING_GROUPS/SELECT_JURISDICTION_ID_FOR_FILING_GROUP';
