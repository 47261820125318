import { apiAction } from '../../../shared/store/apiAction';

import {
  insertInformationDataTypes,
  fetchFilterDataTypes,
  fetchFormFieldsTypes,
  updateFormFieldsTypes,
} from './types';
import * as api from './api';

export const insertInformationData = apiAction({
  types: insertInformationDataTypes,
  apiAction: api.insertInformationalData,
});

export const fetchFilterData = apiAction({
  types: fetchFilterDataTypes,
  apiAction: api.fetchFilterData,
});

export const fetchFormFields = apiAction({
  types: fetchFormFieldsTypes,
  apiAction: api.fetchFormFields,
});

export const updateFormFields = apiAction({
  types: updateFormFieldsTypes,
  apiAction: api.updateFormFields,
});
