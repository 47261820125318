const stringify = (records: Record<string, string | number | boolean | undefined | null>) => {
  return Object.entries(records)
    .reduce((acc, [key, value]) => {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        acc.append(`${key}`, value.toString());
      }

      return acc;
    }, new URLSearchParams())
    .toString();
};

export default stringify;
