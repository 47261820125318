import { createSelector } from 'reselect';

const stateAccountNumbersSelector = ({ entity }) =>
  entity.stateAccountNumbers.stateAccountNumbersData;

export const stateAccountNumbersRowsSelector = createSelector(
  [stateAccountNumbersSelector],
  stateAccountNumbers => (stateAccountNumbers ? stateAccountNumbers.rowData : []),
);

export const stateAccountNumbersColumnsSelector = createSelector(
  [stateAccountNumbersSelector],
  stateAccountNumbers => (stateAccountNumbers ? stateAccountNumbers.columns : []),
);

export const isFetchingStateAccountNumbersSelector = ({ entity }) =>
  entity.stateAccountNumbers.isFetchingStateAccountNumbersData;

export const isUpdatingStateAccountNumbersSelector = ({ entity }) =>
  entity.stateAccountNumbers.isUpdatingStateAccountNumbers;
