import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchGenericCategory = ({ taxYear, period, entityId, jurisdictionId, categoryId }) =>
  httpGetAndParse({
    route: `/api/shared/generic-category/${taxYear}/${period}/${entityId}/${jurisdictionId}/${categoryId}`,
    errorMessage: 'Fetching generic category failed',
  });

export const updateGenericCategory = ({ taxYear, period, orgId, jurisdictionId, rowsToUpdate }) =>
  httpPut({
    route: `/api/shared/generic-category-update/${taxYear}/${period}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Updating generic category failed',
    parseResponseErrorMessage: true,
    body: { rowsToUpdate },
  });
