import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';

import useFetch from '../../../../shared/hooks/useFetch.hook';
import useGridApi from '../../../../shared/hooks/useGridApi.hook';
import { fetchInfoAccounts as fetchInfoAccountsApiAction } from '../../../../shared/store/api';
import { defaultSideBarWithColumnsToolPanel } from '../../../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import FaIcon from '../../../../shared/displayComponents/faIcon/faIcon.component';
import headerStyles from '../../../../shared/displayComponents/headerWithParamDropdowns/styles.module.scss';
import useOpenInNewTab from '../../../../shared/hooks/useOpenInNewTab';
import { taxYearSelector, periodSelector } from '../../../store/selectors';
import { selectTaxYear } from '../../../../shared/store/actions';
import setGridFilterValue from '../setGridFilterValue';
import { FunctionNames } from '../constants';
import expressionBuilderStyles from '../../styles.module.scss';

import columnDefinitions from './calculationInfoAccounts.columnDefinitions';
import styles from './calculationInfoAccounts.module.scss';

const CalculationInfoAccounts = ({
  addFunctionSepExpression,
  addFunctionConsolExpression,
  generateContextMenuItems,
}) => {
  const { gridApi, onGridReady } = useGridApi();
  const [filterValue, setFilterValue] = useState('');

  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const {
    data: infoAccounts,
    fetch: fetchInfoAccounts,
    isFetching: isFetchingInfoAccounts,
  } = useFetch({
    action: fetchInfoAccountsApiAction,
  });

  const handleFetchInfoAccounts = useCallback(() => {
    if (taxYear && period) {
      fetchInfoAccounts({ taxYear, period });
    }
  }, [fetchInfoAccounts, period, taxYear]);

  useEffect(() => {
    handleFetchInfoAccounts();
  }, [handleFetchInfoAccounts]);

  const handleFilterChange = useCallback(() => {
    setGridFilterValue(gridApi, setFilterValue);
  }, [gridApi]);

  const openInNewTab = useOpenInNewTab();

  const handleInfoAccountsButtonClick = useCallback(() => {
    dispatch(selectTaxYear(taxYear));
    openInNewTab(Routes.infoAccounts.MAIN, { search: filterValue });
  }, [dispatch, openInNewTab, filterValue, taxYear]);

  const onInfoAccountSelect = useCallback(
    ({ data }) => {
      if (data) {
        addFunctionSepExpression({
          functionName: FunctionNames.GET_INFO_ACCOUNT,
          paramsArray: [data.infoAccountId],
        });
      }
    },
    [addFunctionSepExpression],
  );

  const onConsolInfoAccountSelect = useCallback(
    data => {
      if (data) {
        addFunctionConsolExpression({
          functionName: FunctionNames.GET_INFO_ACCOUNT,
          paramsArray: [data.infoAccountId],
        });
      }
    },
    [addFunctionConsolExpression],
  );

  const singleClickHandler = useCallback(
    ({ data, event }) => {
      if (event.metaKey || event.ctrlKey) {
        onConsolInfoAccountSelect(data);
      }
    },
    [onConsolInfoAccountSelect],
  );

  const contextMenuItems = useMemo(
    () =>
      generateContextMenuItems(
        ({ data }) => {
          addFunctionSepExpression({
            functionName: FunctionNames.GET_INFO_ACCOUNT,
            paramsArray: [data.infoAccountId],
          });
        },
        ({ data }) => {
          addFunctionConsolExpression({
            functionName: FunctionNames.GET_INFO_ACCOUNT,
            paramsArray: [data.infoAccountId],
          });
        },
      ),
    [generateContextMenuItems, addFunctionSepExpression, addFunctionConsolExpression],
  );

  return (
    <div className={styles.infoAccountsContainer}>
      <div className={`${headerStyles.flexSpaceBetween} ${styles.infoAccountsHeader}`}>
        <div>
          <Button size="lg" kind="secondary" onClick={handleInfoAccountsButtonClick}>
            Info Accounts...
          </Button>
          <button
            className={expressionBuilderStyles.refreshButton}
            title="Refresh data"
            onClick={handleFetchInfoAccounts}
            disabled={isFetchingInfoAccounts}
          >
            <FaIcon icon="sync" size={16} />
          </button>
        </div>
      </div>
      <div className={`row grid-row mt-sm-3 ${styles.gridContainer}`}>
        <div className="col">
          <AgGrid
            rowData={infoAccounts}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingInfoAccounts}
            withSearchBar
            onGridReady={onGridReady}
            onFilterChanged={handleFilterChange}
            onCellDoubleClicked={onInfoAccountSelect}
            onCellClicked={singleClickHandler}
            sideBar={defaultSideBarWithColumnsToolPanel}
            areHeaderCellBordersEnabled
            getContextMenuItems={contextMenuItems}
          />
        </div>
      </div>
    </div>
  );
};

CalculationInfoAccounts.propTypes = {
  addFunctionSepExpression: PropTypes.func.isRequired,
  addFunctionConsolExpression: PropTypes.func.isRequired,
  generateContextMenuItems: PropTypes.func.isRequired,
};

export default CalculationInfoAccounts;
