import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationUpdateDataModelFields = () =>
  useCustomMutation({
    handleData: ({ rows, taxYear, period, businessEntityId, jurisdictionId, filingTypeId }) => ({
      url: '/api/shared/state-common-information',
      method: HttpMethods.PUT,
      body: { rows, taxYear, period, businessEntityId, jurisdictionId, filingTypeId },
    }),
    resetKey: QueryKeys.StateCommonInformation.CommonDataModelFields,
    successMessage: 'State common information fields saved successfully',
    errorMessage: 'Updating state common information fields failed',
  });
