import PropTypes from 'prop-types';

export const taxRatePropType = PropTypes.shape({
  filingAttributes: PropTypes.number.isRequired,
  jurisdictionDescription: PropTypes.string.isRequired,
  startRange: PropTypes.number.isRequired,
  stopRange: PropTypes.number,
  rate: PropTypes.number.isRequired,
  exclusionAmt: PropTypes.number,
  exclusionRate: PropTypes.number,
});

export const taxRateFormType = PropTypes.shape({
  startRange: PropTypes.string.isRequired,
  stopRange: PropTypes.string,
  rate: PropTypes.string,
  jurisdictionId: PropTypes.string.isRequired,
  exclusionAmt: PropTypes.string,
  exclusionRate: PropTypes.string,
}).isRequired;
