const customActions = [
  {
    path: 'compliance.entities.forms.complete_return_with_attachments',
    customAction: () => console.log('custom action for complete_return_with_attachments'), // eslint-disable-line no-console
  },
  {
    path: 'compliance.consolidations.workpapers.tax_summary',
    customAction: () => console.log('custom action for tax_summary'), // eslint-disable-line no-console
  },
  {
    path: 'compliance.consolidations.reports.federal_details_by_line_number_and_entity',
    customAction: event =>
      console.log('custom action for federal_details_by_line_number_and_entity', event), // eslint-disable-line no-console
  },
];

export default customActions;
