const dataTypes = require('./dataTypes');
const filingInfo = require('./filingInfo');
const businessTypes = require('./businessTypes');
const filingAttributes = require('./filingAttributes');
const columnTypes = require('./columnTypes');
const taxTypes = require('./taxTypes');
const genericFunctionsDataTypes = require('./genericFunctionsDataTypes');
const efileConstants = require('./efileConstants');
const genericScreen = require('./genericScreen');
const genericCategory = require('./genericCategory');
const shared = require('./shared');
const formsConstants = require('./formsConstants');
const userEmailValidation = require('./userEmailValidation');
const contentDeploymentConstants = require('./contentDeploymentConstants');
const excelFileFormats = require('./excelFileFormats');
const printServiceConstants = require('./printServiceConstants');
const dateRegex = require('./dateRegex');
const aliasTypes = require('./aliasTypes');
const OverrideReportTypes = require('./overrideReportTypes');
const taxReturns = require('./taxReturns');

module.exports = {
  ...dataTypes,
  ...filingInfo,
  ...businessTypes,
  ...filingAttributes,
  ...columnTypes,
  ...taxTypes,
  ...genericFunctionsDataTypes,
  ...efileConstants,
  ...genericScreen,
  ...genericCategory,
  ...formsConstants,
  ...shared,
  ...userEmailValidation,
  ...contentDeploymentConstants,
  ...excelFileFormats,
  ...printServiceConstants,
  ...dateRegex,
  ...aliasTypes,
  ...OverrideReportTypes,
  ...taxReturns,
};
