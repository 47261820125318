import React from 'react';
import { useSelector } from 'react-redux';
import { CalcStatuses } from '@tls/state-helpers';

import { dateFormatter } from '../shared/formatters';
import config from '../config';
import { calcStatusDataSelector } from '../shared/store/calcStatus/selectors';

const LAST_CALCULATED = 'Last Calculated';
const CALCULATION_STARTED = 'Calculation Started';

const CalcStatusText = () => {
  const calcStatusData = useSelector(calcStatusDataSelector);

  if (!calcStatusData) {
    return null;
  }

  const { calcStatus, calcStatusTimestamp, calcStatusCreatedOn } = calcStatusData;

  if (!calcStatusCreatedOn) {
    return <span>Initial Calculation Needed</span>;
  }

  const renderCalcStatusText = statusText => (
    <span className="calc-status-text">
      {`${statusText}: ${dateFormatter(calcStatusTimestamp, config.AMERICAN_DATE_TIME_FORMAT)}`}
    </span>
  );

  switch (calcStatus) {
    case CalcStatuses.CLEAN:
      return renderCalcStatusText(LAST_CALCULATED);
    case CalcStatuses.IN_PROGRESS:
      return renderCalcStatusText(CALCULATION_STARTED);
    case CalcStatuses.DIRTY:
      return renderCalcStatusText(LAST_CALCULATED);
    default:
      return null;
  }
};

export default CalcStatusText;
