import { useEffect, useMemo } from 'react';

interface Client {
  id: string;
  name: string;
  shortName: string;
}
interface CustomerData {
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  clients: Client[];
}

const usePendo = (
  pendoKey: string | undefined,
  customerData: CustomerData,
  isLoading: boolean,
  isFetchingCustomerDataError: boolean,
) => {
  const shouldInitPendo = useMemo(
    () => window.pendo && !window.pendo.visitorId && !isLoading && !isFetchingCustomerDataError,
    [isLoading, isFetchingCustomerDataError],
  );

  useEffect(() => {
    if (pendoKey && !window.pendo) {
      const node = document.createElement('script');
      node.type = 'text/javascript'; // set the script type
      node.async = true; // makes script run asynchronously
      node.innerHTML = `(function (apiKey) {
                                      (function (p, e, n, d, o) {
                                          var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = [];
                                          v = ['initialize', 'identify', 'updateOptions', 'pageLoad']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
                                              o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
                                          })(v[w]);
                                          y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
                                          z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
                                      })(window, document, 'script', 'pendo');
                                  })('${pendoKey}');`;
      // append to head of document
      document.getElementsByTagName('body')[0].append(node);
    }
  }, [pendoKey]);

  useEffect(() => {
    if (shouldInitPendo) {
      window.pendo.initialize({
        visitor: {
          // Pendo-reserved key-names
          id: customerData.email, // Required if user is logged in
          email: customerData.email, // Recommended if using Pendo Feedback, or NPS Email
          fullName: `${customerData.firstName} ${customerData.lastName}`, // Recommended if using Pendo Feedback
          customerId: customerData.customerId,
          clients: customerData.clients?.map(({ name }) => name),
        },

        account: {
          id: customerData.email, // Highly recommended
        },
      });
    }
  }, [shouldInitPendo, customerData]);
};

export const closePendoIfVisible = () => {
  if (window.pendo?.isGuideShown()) {
    window.pendo.onGuideDismissed();
  }
};

export default usePendo;
