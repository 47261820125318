import { GridApi, RowNode } from 'ag-grid-community';
import { useCallback } from 'react';

import { BOOLEAN_DATA_TYPE } from '../constants';

interface UseValidateCheckboxGroupProps {
  gridApi: GridApi | null;
}

interface CheckboxGroupIdListObj {
  [checkboxGroupId: string]: Array<CheckboxGroupRowData | null>;
}

interface CheckboxGroupRowData {
  name?: string;
  id?: string;
}

interface CheckboxGroupsOption {
  value?: string;
  label?: string;
}

const useValidateCheckboxGroup = ({ gridApi }: UseValidateCheckboxGroupProps) => {
  const validateCheckboxGroupHasMoreThanOneDataItem = useCallback(
    (checkboxGroupsOptions: Array<CheckboxGroupsOption>) => {
      const checkboxGroupIdListObj: CheckboxGroupIdListObj = {};
      const checkboxGroupsWithSingleItem: Array<unknown> = [];

      gridApi?.forEachNode(({ data }: RowNode) => {
        const { checkboxGroupId, dataType } = data;
        if (checkboxGroupId && dataType === BOOLEAN_DATA_TYPE) {
          if (!checkboxGroupIdListObj[checkboxGroupId]) {
            checkboxGroupIdListObj[checkboxGroupId] = [];
          }
          checkboxGroupIdListObj[checkboxGroupId].push(data);
        }
      });

      Object.entries(checkboxGroupIdListObj).forEach(([checkboxGroupId, data]) => {
        const checkboxGroupOption = checkboxGroupsOptions.find(({ value }) => {
          return value === checkboxGroupId;
        });
        if (data.length < 2) {
          const modalDescriptionObj = {
            description: `${checkboxGroupOption?.label} - Dataitem ${data[0]?.name}`,
          };
          checkboxGroupsWithSingleItem.push(modalDescriptionObj);
        }
      });

      return checkboxGroupsWithSingleItem;
    },
    [gridApi],
  );

  return { validateCheckboxGroupHasMoreThanOneDataItem };
};

export default useValidateCheckboxGroup;
