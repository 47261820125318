import { SelectOptionsResponse } from '@tls/slt-types';

import { httpPostAndParse } from '../../utils/fetchService';
import stringify from '../../utils/stringify';
import {
  TaxYear,
  FilingTypeId,
  JurisdictionId,
  FindTaxReturnsParams,
  FindTaxReturnsResponse,
  FindTaxReturnDefinitionsResponse,
  FindTaxReturnParams,
  FindTaxReturnResponse,
  FindTaxReturnItemsResponse,
  Period,
  FindConsolidatedTaxReturnsResponse,
  ConsolidationFilingDecisionsQueryResponse,
  FindReturnStatusDefinition,
  ReturnStatusDefinitionRow,
  EntityId,
  FindTtiGroupValidationResponse,
} from '../../../../common/types';
import { FilingMethods, TaxReturnStatus, TaxReturnEFileStatus } from '../../shared/enums';

import { QueryError, QueryKeys, useCustomQuery } from './index';

export interface TaxReturn extends FindTaxReturnResponse {}

export interface TtiGroupValidation extends FindTtiGroupValidationResponse {}

interface FindTaxReturnDefinitionsParams {
  taxYear: TaxYear;
  period: Period;
  filingTypeId: FilingTypeId;
  jurisdictionId?: JurisdictionId;
  entityId?: EntityId;
}

interface FindTaxReturnDefinitionsJurisdictionOptionsParams {
  taxYear: TaxYear;
  filingTypeId: FilingTypeId;
}

interface FindTaxReturnItemsParams {
  returnId: string;
}

export interface TaxReturnOption {
  returnId: number;
  returnName?: string;
}

interface FindConsolidatedTaxReturnsParams {
  taxYear: TaxYear;
  period: Period;
}

interface FindFilingMembersParams {
  taxYear: TaxYear;
  period: Period;
  jurisdictionId: JurisdictionId;
  filingGroupsIds: string[];
}

export const useQueryTaxReturnOptions = ({
  params,
  enabled,
  onSuccess,
}: {
  params: string;
  enabled: boolean;
  onSuccess: (taxReturns: TaxReturnOption[]) => void;
}) =>
  useCustomQuery<TaxReturnOption[]>([QueryKeys.TaxReturns.ReturnOptions, params], {
    url: `/api/tax-returns/returns-list/${params}`,
    enabled,
    defaultData: [],
    errorMessage: 'Error fetching returns list',
    onSuccess,
  });

export const useQueryTaxReturns = ({
  params,
  enabled,
}: {
  params: FindTaxReturnsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindTaxReturnsResponse>([QueryKeys.TaxReturns.Data, params], {
    url: `/api/tax-returns/${params.taxYear}/${params.period}/${params.entityId}`,
    defaultData: { data: [], columnsBlueprint: [] },
    enabled,
    errorMessage: 'Error fetching tax returns',
  });

export interface FindOrgTypeResponse {
  orgType: string | null;
}

export const useQueryOrgType = ({
  params,
  enabled,
}: {
  params: FindTaxReturnsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindOrgTypeResponse>([QueryKeys.TaxReturns.OrgType, params], {
    url: `/api/tax-returns/org-type/${params.taxYear}/${params.period}/${params.entityId}`,
    enabled,
    errorMessage: 'Error fetching org type',
  });

export const useQueryConsolidatedTaxReturns = ({
  params,
  enabled,
}: {
  params: FindConsolidatedTaxReturnsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindConsolidatedTaxReturnsResponse>([QueryKeys.TaxReturns.Data, params], {
    url: `/api/tax-returns/consolidations/${params.taxYear}/${params.period}`,
    defaultData: { data: [], columnsBlueprint: [], calcPreferencesDefinitions: [] },
    enabled,
    errorMessage: 'Error fetching consolidations tax returns',
  });

export const useQueryReturnStatus = (params?: {
  filingMethod?: FilingMethods | null;
  efileStatus?: TaxReturnEFileStatus | null;
  returnStatus?: TaxReturnStatus | null;
}) =>
  useCustomQuery<FindReturnStatusDefinition, QueryError, ReturnStatusDefinitionRow[]>(
    [QueryKeys.TaxReturns.ReturnStatuses],
    {
      url: '/api/tax-returns/return-status-definitions',
      errorMessage: 'Error fetching tax return status',
      defaultData: [],
      selector: (data: FindReturnStatusDefinition): ReturnStatusDefinitionRow[] => {
        const filingMethod =
          params?.efileStatus === TaxReturnEFileStatus.ACCEPTED &&
          params?.returnStatus === TaxReturnStatus.COMPLETE
            ? FilingMethods.PAPER
            : params?.filingMethod;
        if (filingMethod) {
          return filingMethod === FilingMethods.EFILE
            ? data.efileReturnStatusOptions
            : data.returnStatusOptions;
        }
        return [];
      },
    },
  );

export const useQueryReturnStatuses = () =>
  useCustomQuery<FindReturnStatusDefinition, QueryError>([QueryKeys.TaxReturns.ReturnStatuses], {
    url: '/api/tax-returns/return-status-definitions',
    errorMessage: 'Error fetching tax return status',
    defaultData: { returnStatusOptions: [], efileReturnStatusOptions: [] },
  });

export const useQueryTaxReturn = ({
  params,
  enabled,
}: {
  params: FindTaxReturnParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindTaxReturnResponse>([QueryKeys.TaxReturns.TaxReturn, params], {
    url: `/api/tax-returns/${params.apiRouteParamString}/${params.returnId}`,
    enabled,
    errorMessage: 'Error fetching tax return',
  });

export const useQueryTaxReturnsReturnDefinitions = ({
  params,
  enabled,
}: {
  params: FindTaxReturnDefinitionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindTaxReturnDefinitionsResponse>(
    [QueryKeys.TaxReturns.ReturnDefinitions, params],
    {
      url: `/api/tax-returns/return-definitions/${params.taxYear}/${params.period}/${
        params.filingTypeId
      }?${stringify({ jurisdictionId: params.jurisdictionId, entityId: params.entityId })}`,
      defaultData: [],
      enabled,
      errorMessage: 'Error fetching tax returns definitions',
    },
  );

export const useQueryTaxReturnsReturnDefinitionsJurisdictionOptions = ({
  params,
  enabled,
}: {
  params: FindTaxReturnDefinitionsJurisdictionOptionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<SelectOptionsResponse>([QueryKeys.TaxReturns.ReturnDefinitionsOptions, params], {
    url: `/api/tax-returns/return-definitions-jurisdiction-options/${params.taxYear}/${params.filingTypeId}`,
    defaultData: [],
    enabled,
    errorMessage: 'Error fetching tax returns definitions jurisdiction options',
  });

export const useQueryTaxReturnItems = ({
  params,
  enabled,
}: {
  params: FindTaxReturnItemsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindTaxReturnItemsResponse>([QueryKeys.TaxReturns.ReturnItems, params], {
    url: `/api/tax-returns/return-items/${params.returnId}`,
    defaultData: [],
    enabled,
    errorMessage: 'Error fetching return items',
  });

const fetchFilingMembersByGroups = (params: FindFilingMembersParams) =>
  httpPostAndParse({
    route: `/api/tax-returns/consolidation/filing-members/${params.taxYear}/${params.period}/${params.jurisdictionId}`,
    body: { filingGroupsIds: params.filingGroupsIds },
  });

export const useQueryFilingMembersByGroups = ({
  params,
  enabled,
}: {
  params: FindFilingMembersParams;
  enabled: boolean;
}) =>
  useCustomQuery<ConsolidationFilingDecisionsQueryResponse>(
    [QueryKeys.FilingGroups.FilingMembers, params],
    {
      enabled,
      errorMessage: 'An error occurred while fetching filing group entities',
      customFetch: () => fetchFilingMembersByGroups(params),
    },
  );
