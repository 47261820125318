import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '../shared/displayComponents/loading.component';
import { signOut } from '../shared/store/actions';
import { isSigningOutSelector } from '../shared/store/selectors';
import config from '../config';

const LogoutPage = ({ signOut, isSigningOut }) => {
  useEffect(() => {
    // remove local storage token information
    localStorage.removeItem(config.AUTH.ID_TOKEN_KEY);
    localStorage.removeItem(config.AUTH.REFRESH_TOKEN_KEY);
    localStorage.removeItem(config.AUTH.EXPIRES_IN_KEY);
    window.open(config.AUTH.SIGN_OUT_URL, config.AUTH.WINDOW_NAME);
    signOut();
  }, [signOut]);

  return (
    <div className="logout-page page-container page-content-container">
      <Loading isLoading={isSigningOut}>
        <div className="logout-page__text">
          <div>You have been logged out of this application.</div>
          <div>
            You may close the browser window, or <a href="/">click here to log back in</a>.
          </div>
        </div>
      </Loading>
    </div>
  );
};

LogoutPage.propTypes = {
  signOut: PropTypes.func.isRequired,
  isSigningOut: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    isSigningOut: isSigningOutSelector(state),
  }),
  {
    signOut,
  },
)(LogoutPage);
