import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import AppkitIcon from '../shared/displayComponents/appkitIcon/appkitIcon.component';
import SelectContextDataInfo from '../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import {
  scrollElementIdSelector,
  globalContextSelector,
  consolidationIdSelector,
  isFetchingGlobalContextSelector,
} from '../shared/store/selectors';
import {
  isFetchingDatasetInstancesSelector,
  datasetInstancesSelector,
  datasetInstanceSelector,
} from '../shared/store/dataModels/selectors';
import {
  fetchDatasetInstances as fetchParentDatasetInstances,
  selectDatasetInstance,
} from '../shared/store/dataModels/actions';
import { useQueryWorkpaperInstance } from '../shared/queries/workpaperInstance';
import { useMutationUpdateWorkpaperInstance } from '../shared/mutations/workpaperInstance';
import Loading from '../shared/displayComponents/loading.component';
import DataInfo from '../shared/displayComponents/dataInfo/dataInfo.component';
import { AddDatasetInstanceButton } from '../shared/displayComponents/dataModels/datasetInstanceButtons';
import { useRowEditMode } from '../shared/editMode';
import { components } from '../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import useUrlParams from '../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import NavigateBackLink from '../sharedSubPages/returnWorkspace/navigateBackLink.container';
import styles from '../viewFormWorkpaper/forms.module.scss';
import ReorderDatasetInstancesButton from '../shared/displayComponents/workpapers/reorderDatasetInstances/reorderDatasetInstancesButton.component';

import { fetchDatasetDefinitionMetaData } from './store/actions';
import {
  lockIndicatorSelector,
  isFetchingDatasetDefinitionMetaDataSelector,
  datasetDefinitionSelector,
} from './store/selectors';
import ReadonlyFormValueWithLabel from './readonlyFormValueWithLabel.component';
import getColumnDefinitions from './workpaperInstance.columnDefinitions';
import prepareNewWorkpaperInstance from './prepareNewWorkpaperInstance';
import { mapWorkpaperInstanceRows } from './mapWorkpaperInstanceRows';

const getRowId = params => params?.data?.datasetInstanceId;

const WorkpaperInstance = ({ hasUserPermissionsToEdit }) => {
  const { routeParams, setParams } = useUrlParams();
  const {
    parentDatasetDefinitionId,
    datasetDefinitionId,
    initialParentDatasetInstanceId: parentDatasetInstanceId,
  } = routeParams;

  const dispatch = useDispatch();

  const globalContext = useSelector(globalContextSelector);
  const isFetchingContext = useSelector(isFetchingGlobalContextSelector);
  const consolidationId = useSelector(consolidationIdSelector);

  const isFetchingParentDatasetInstances = useSelector(isFetchingDatasetInstancesSelector);
  const parentDatasetInstanceOptions = useSelector(datasetInstancesSelector);
  const datasetInstance = useSelector(datasetInstanceSelector);

  const datasetDefinitionMetadata = useSelector(datasetDefinitionSelector);
  const isFetchingDatasetDefinitionMetaData = useSelector(
    isFetchingDatasetDefinitionMetaDataSelector,
  );

  const lockIndicator = useSelector(lockIndicatorSelector);

  const scrollElementId = useSelector(scrollElementIdSelector);

  const {
    isReady: isContextReady,
    params: { taxYear, period, filingTypeId, businessEntityId, jurisdictionId },
  } = globalContext;

  const {
    refetch: refetchWorkpaperInstance,
    data: workpaperInstance,
    isFetching: isFetchingWorkpaperInstance,
  } = useQueryWorkpaperInstance({
    params: {
      taxYear,
      period,
      businessEntityId,
      filingTypeId,
      jurisdictionId,
      parentDatasetInstanceId,
      datasetDefinitionId,
    },
    enabled: isContextReady,
  });

  const refetchParentGrid = useCallback(() => {
    refetchWorkpaperInstance();
  }, [refetchWorkpaperInstance]);

  const {
    mutateAsync: updateWorkpaperInstance,
    isLoading: isUpdatingWorkpaperInstance,
  } = useMutationUpdateWorkpaperInstance();

  const isThereWorkpaperInstanceData = workpaperInstance?.columnsBlueprint.length;
  const isSomeColumnEditable = workpaperInstance?.columnsBlueprint.some(
    column => column.isEditable,
  );

  const isParentDatasetInstanceIdInOptions = parentDatasetInstanceOptions.some(
    ({ value }) => value === parentDatasetInstanceId,
  );
  const shouldSelectParentDatasetInstanceId = isContextReady && !isParentDatasetInstanceIdInOptions;

  useEffect(() => {
    if (isContextReady) {
      dispatch(fetchDatasetDefinitionMetaData({ datasetDefinitionId, filingTypeId }));
    }
  }, [dispatch, isContextReady, datasetDefinitionId, filingTypeId]);

  useEffect(() => {
    if (isContextReady) {
      dispatch(
        fetchParentDatasetInstances({
          taxYear,
          period,
          filingTypeId,
          consolidationId,
          entityId: businessEntityId,
          datasetDefId: parentDatasetDefinitionId,
          parentDatasetInstanceId,
        }),
      );
    }
  }, [
    dispatch,
    isContextReady,
    taxYear,
    period,
    filingTypeId,
    consolidationId,
    businessEntityId,
    parentDatasetDefinitionId,
    parentDatasetInstanceId,
  ]);

  const navigateBackLink = useMemo(
    () => (
      <NavigateBackLink
        taxFormInstanceId={parentDatasetInstanceId}
        scrollElementId={scrollElementId}
      />
    ),
    [parentDatasetInstanceId, scrollElementId],
  );

  const onParentDataInstanceChange = useCallback(
    parentInstance => {
      if (parentInstance) {
        setParams({ routeParams: { initialParentDatasetInstanceId: parentInstance.value } });
        dispatch(selectDatasetInstance(parentInstance.value));
      }
    },
    [setParams, dispatch],
  );

  const onSave = useCallback(
    async ({ rowsPairsWithChanges, rowsToAdd, rowsToDelete }) => {
      /**
       * We need to use async/await here to wait for an update to finish.
       * Without this, the loading indicator won't be shown when an update is ongoing
       * and the navigation prompt won't show up when users try to leave the screen.
       */
      const dataItemDefinitionIds = workpaperInstance.columnsBlueprint
        .reduce((acc, column) => {
          if (column.checkboxGroupID) {
            return [
              ...acc,
              ...column.checkboxGroupItems.map(checkboxGroupItem => {
                return { dataItemDefinitionId: checkboxGroupItem.value };
              }),
            ];
          }
          return [...acc, column];
        }, [])
        .map(({ dataItemDefinitionId }) => dataItemDefinitionId)
        .filter(Boolean);

      const { datasetInstancesToAdd, datasetInstancesToUpdate } = mapWorkpaperInstanceRows({
        rowsToUpdate: rowsPairsWithChanges,
        rowsToAdd,
        dataItemDefinitionIds,
      });

      await updateWorkpaperInstance({
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
        datasetDefinitionId,
        parentDatasetInstanceId,
        datasetInstancesToDelete: rowsToDelete.map(({ datasetInstanceId }) => datasetInstanceId),
        datasetInstancesToAdd,
        datasetInstancesToUpdate,
      });
    },
    [
      updateWorkpaperInstance,
      taxYear,
      period,
      businessEntityId,
      datasetDefinitionId,
      parentDatasetInstanceId,
      filingTypeId,
      jurisdictionId,
      workpaperInstance,
    ],
  );

  const isGridLoading =
    isUpdatingWorkpaperInstance || isFetchingParentDatasetInstances || isFetchingWorkpaperInstance;

  const isEditButtonDisabled =
    lockIndicator ||
    isGridLoading ||
    !isThereWorkpaperInstanceData ||
    !isSomeColumnEditable ||
    shouldSelectParentDatasetInstanceId ||
    (datasetDefinitionMetadata.systemDefined && !isSomeColumnEditable);

  const rowData = useMemo(() => workpaperInstance?.rows || [], [workpaperInstance?.rows]);

  const {
    updateRow,
    gridApi,
    editModeButtons,
    clonedRowData,
    isInEditMode,
    setIsInEditMode,
    addRow,
    deleteRow,
    onGridReady,
    navigationPrompt,
  } = useRowEditMode({
    onSave,
    appendNewRows: true,
    rowData,
    getUniqueRowId: getRowId,
    editButtonDisabled: isEditButtonDisabled,
    saveButtonDisabled: isGridLoading,
    showLockIcon: lockIndicator,
  });

  const addWorkpaperInstance = useCallback(() => {
    const sortOrder = gridApi.getRenderedNodes().length + 1;
    addRow(prepareNewWorkpaperInstance(workpaperInstance?.columnsBlueprint, sortOrder));

    setIsInEditMode(true);
  }, [addRow, setIsInEditMode, workpaperInstance?.columnsBlueprint, gridApi]);

  const removeWorkpaperInstance = useCallback(
    (...args) => {
      deleteRow(...args);

      setIsInEditMode(true);
    },
    [deleteRow, setIsInEditMode],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        columnsBlueprint: workpaperInstance?.columnsBlueprint || [],
        isInEditMode,
        updateRow,
        datasetDefinition: {
          ...datasetDefinitionMetadata,
          pending: isFetchingDatasetDefinitionMetaData,
        },
        deleteRow: removeWorkpaperInstance,
        context: globalContext.params,
      }),
    [
      updateRow,
      removeWorkpaperInstance,
      workpaperInstance?.columnsBlueprint,
      isInEditMode,
      isFetchingDatasetDefinitionMetaData,
      datasetDefinitionMetadata,
      globalContext.params,
    ],
  );

  const [rowsQuantity, setRowsQuantity] = useState(0);

  const rowDataChanged = useCallback(() => {
    if (gridApi) {
      let rowCount = 0;
      gridApi.forEachNode(() => (rowCount += 1));
      setRowsQuantity(rowCount);
    }
  }, [gridApi]);

  const datasetDefinitionMaximumInstancesAllowed =
    datasetDefinitionMetadata.maximumInstancesAllowed;

  const maximumNumberOfInstancesReached =
    datasetDefinitionMaximumInstancesAllowed !== -1 &&
    rowsQuantity >= datasetDefinitionMaximumInstancesAllowed;

  const shouldDisplayParentDatasetInstanceOptions =
    datasetInstance &&
    datasetInstance.instanceIdentifier !== null &&
    datasetInstance.maximumInstancesAllowed !== 1;

  if (isFetchingContext || isFetchingParentDatasetInstances || isFetchingWorkpaperInstance) {
    return (
      <div className={styles.loaderContainer}>
        <Loading isLoading />
      </div>
    );
  }

  if (!isContextReady) {
    return <SelectContextDataInfo />;
  }

  const disableReorderButton =
    lockIndicator ||
    datasetDefinitionMetadata?.systemDefined ||
    !clonedRowData.length ||
    isUpdatingWorkpaperInstance ||
    isInEditMode;

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className={`col ${styles.selectCol}`}>
          <Loading small isLoading={isFetchingDatasetDefinitionMetaData}>
            <ReadonlyFormValueWithLabel
              label="Attachment"
              value={datasetDefinitionMetadata.label}
            />
          </Loading>
        </div>
        {shouldDisplayParentDatasetInstanceOptions && (
          <div className={`col ${styles.selectCol}`}>
            <Loading small isLoading={isFetchingParentDatasetInstances}>
              <SDKCustomSelect
                appkitLabel="Instance"
                className="sdk-custom-select"
                options={parentDatasetInstanceOptions}
                value={parentDatasetInstanceId}
                virtualized
                onChange={onParentDataInstanceChange}
                disabled={isInEditMode}
              />
            </Loading>
          </div>
        )}
        {hasUserPermissionsToEdit && (
          <div className={`col ${styles.buttonControls}`}>
            <div>
              {datasetDefinitionMaximumInstancesAllowed !== -1 && (
                <label className={styles.buttonMessage}>
                  {datasetDefinitionMaximumInstancesAllowed} instance/page max
                </label>
              )}
              <ReorderDatasetInstancesButton
                datasetDefinitionId={datasetDefinitionId}
                loading={isFetchingDatasetDefinitionMetaData}
                disabled={disableReorderButton}
                size="lg"
                kind="secondary"
                refetchParentGrid={refetchParentGrid}
              />
              <AddDatasetInstanceButton
                dataset={datasetDefinitionMetadata}
                datasetPending={isFetchingDatasetDefinitionMetaData}
                size="lg"
                kind="secondary"
                onClick={addWorkpaperInstance}
                className="add-button"
                disabled={
                  lockIndicator ||
                  maximumNumberOfInstancesReached ||
                  isGridLoading ||
                  shouldSelectParentDatasetInstanceId ||
                  datasetDefinitionMetadata.systemDefined
                }
              >
                {lockIndicator && (
                  <AppkitIcon className="location-lock-icon" icon="location-locked" size={16} />
                )}
                Add Instance
              </AddDatasetInstanceButton>
              {editModeButtons}
            </div>
          </div>
        )}
      </div>
      {navigateBackLink}
      {shouldSelectParentDatasetInstanceId ? (
        <DataInfo textToRender="Select valid parent dataset instance from dropdown" />
      ) : (
        <div className={`row grid-row ${styles.selectGrid}`}>
          <div className="col">
            <AgGrid
              columnDefs={columnDefinitions}
              rowData={clonedRowData}
              getRowId={getRowId}
              isGridLoading={isGridLoading}
              singleClickEdit
              suppressCellFocus={!isInEditMode}
              stopEditingWhenCellsLoseFocus
              onGridReady={onGridReady}
              components={components}
              onRowDataUpdated={rowDataChanged}
            />
          </div>
        </div>
      )}
    </>
  );
};

WorkpaperInstance.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default WorkpaperInstance;
