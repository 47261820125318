import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { OverflowAction } from '@common-packages/shared-constants';

import {
  selectedMappingFormPDFPageDatasetSelector,
  mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector,
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
} from '../../../../shared/store/dataModels/selectors';
import {
  fetchPDFAttachmentColumnProperties,
  selectPDFPageDataUsageAndAttachmentConfig,
} from '../../../../shared/store/dataModels/actions';
import {
  isUpdatingTaxFormSelector,
  isFetchingPdfPageDataUsageSelector,
} from '../../store/selectors';
import { formPdfIdSelector, isFetchingFormsSelector } from '../../../store/selectors';
import Loading from '../../../../shared/displayComponents/loading.component';
import ParamDropdown from '../../../../shared/displayComponents/paramDropdown/paramDropdown.component';
import { assignedDatasetsTreePropTypes, pdfPageDataUsagePropTypes } from '../../propTypes';
import { pdfPageDataUsageDropdownOptionsFactory } from '../../utils/pdfPageDataUsageDropdownOptionsFactory';

import PDFAttachmentPropertiesGrid from './pdfAttachmentPropertiesGrid/pdfAttachmentPropertiesGrid.container';
import DatasetUsage from './datasetUsageForm/datasetUsage.container';
import styles from './overflowActions.module.scss';

const OverflowActions = ({ setIsFormDirty, pdfPageDataUsage }) => {
  const dispatch = useDispatch();
  const { values: rootValues, setValues } = useFormikContext();

  const isUpdatingTaxForm = useSelector(isUpdatingTaxFormSelector);
  const isFetchingTaxForm = useSelector(isFetchingFormsSelector);
  const isFetchingPdfDataUsage = useSelector(isFetchingPdfPageDataUsageSelector);
  const isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs = useSelector(
    isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
  );

  const pdfId = useSelector(formPdfIdSelector);
  const selectedDatasetId = useSelector(mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector);
  const selectedMappingFormPDFPageDataset = useSelector(selectedMappingFormPDFPageDatasetSelector);

  const datasetDropdownOptions = useMemo(
    () => pdfPageDataUsageDropdownOptionsFactory(pdfPageDataUsage),
    [pdfPageDataUsage],
  );

  // Get selected dataset from already saved mappings or newly assigned datasets
  const selectedDataset = useMemo(
    () =>
      selectedMappingFormPDFPageDataset ||
      pdfPageDataUsage.find(item => item.id === selectedDatasetId),
    [selectedMappingFormPDFPageDataset, selectedDatasetId, pdfPageDataUsage],
  );

  const { id: datasetId, datasetId: datasetDefId, pageNumber } = selectedDataset || {};
  const selectedDatasetFormikValues = rootValues?.overflowActions?.[datasetId];
  const isAttachmentOverflowAction =
    (selectedDatasetFormikValues?.overflowAction || selectedDataset?.overflowAction) ===
    OverflowAction.ATTACHMENT;

  const isLoading =
    isUpdatingTaxForm ||
    isFetchingTaxForm ||
    isFetchingPdfDataUsage ||
    isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs;

  const handleDatasetSelection = useCallback(
    newDatasetId => {
      if (newDatasetId !== selectedDatasetId) {
        dispatch(selectPDFPageDataUsageAndAttachmentConfig(newDatasetId));
      }
    },
    [dispatch, selectedDatasetId],
  );

  useEffect(() => {
    if (isAttachmentOverflowAction && datasetDefId && pageNumber && pdfId) {
      dispatch(
        fetchPDFAttachmentColumnProperties({
          datasetDefId,
          pageNumber,
          pdfId,
        }),
      );
    }
  }, [dispatch, isAttachmentOverflowAction, datasetDefId, pageNumber, pdfId]);

  return (
    <Loading isLoading={isLoading}>
      <div className={styles.wrapper}>
        <div className="row">
          <div className="col">
            <ParamDropdown
              className={styles.datasetsDropdown}
              label="DataSets"
              value={selectedDatasetId}
              options={datasetDropdownOptions}
              handleChange={handleDatasetSelection}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DatasetUsage
              key={datasetId}
              isAttachmentOverflowAction={isAttachmentOverflowAction}
              selectedDatasetFormikValues={selectedDatasetFormikValues}
              setValues={setValues}
              setIsFormDirty={setIsFormDirty}
              selectedDataset={selectedDataset}
              pdfPageDataUsage={pdfPageDataUsage}
            />
          </div>
        </div>
        {isAttachmentOverflowAction && (
          <>
            <div className="row">
              <div className="col">
                <h6>Attachment Column Properties</h6>
              </div>
            </div>
            <div className="row grid-row">
              <div className={`col ${styles.gridColumn}`}>
                <PDFAttachmentPropertiesGrid
                  setIsFormDirty={setIsFormDirty}
                  selectedDataset={selectedDataset}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Loading>
  );
};

OverflowActions.propTypes = {
  setIsFormDirty: PropTypes.func.isRequired,
  assignedDatasetsTree: assignedDatasetsTreePropTypes.isRequired,
  pdfPageDataUsage: PropTypes.arrayOf(pdfPageDataUsagePropTypes).isRequired,
};

export default OverflowActions;
