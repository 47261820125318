const Yup = require('yup');

const createOrUpdateAccountSchema = Yup.object()
  .shape({
    accountId: Yup.string().required().label('Account Id'),
    accountDescription: Yup.string().required().label('Account description'),
    accountGroup: Yup.string().required().label('Account group'),
    activeFlag: Yup.string().oneOf(['Y', 'N', 'A']).required().label('Active flag'),
  })
  .strict();

module.exports = {
  createOrUpdateAccountSchema,
};
