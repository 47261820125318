import { useEffect, useState, useMemo, useCallback } from 'react';
import { useFormikContext } from 'formik';

import { FilingMethods, FilingMethodsIndex } from '../enums';
import { JurisdictionDescription } from '../../../../common/types';
import { FILING_METHOD_EDIT_OPTIONS, FILING_METHOD_OPTIONS } from '../constants';

interface FormValues {
  filingMethod: FilingMethods | null;
  returnDefinitionId: string | null;
}

interface TaxReturnDefinition {
  isEfileAllowed: boolean;
  isPaperAllowed: boolean;
  hasMandate: boolean;
  jurisdictionDescription: JurisdictionDescription | null;
  failureToEfilePenalty: string | null;
  filingWarning?: string;
}

interface useFilingMethodSelectionProps {
  taxReturnsDefinition?: TaxReturnDefinition;
  isEditing?: boolean;
  isNonFilingEditable?: boolean;
}

const getFilingMethodInfoMessage = ({
  value,
  taxReturnsDefinition,
}: {
  value: FilingMethods | null;
  taxReturnsDefinition: TaxReturnDefinition | undefined;
}) => {
  if (value !== FilingMethods.PAPER || !taxReturnsDefinition) {
    return;
  }
  const {
    isPaperAllowed,
    hasMandate,
    jurisdictionDescription,
    failureToEfilePenalty,
    filingWarning,
  } = taxReturnsDefinition;

  if (filingWarning && (hasMandate || !isPaperAllowed)) {
    return `${filingWarning}`;
  }

  if (hasMandate) {
    return `${jurisdictionDescription} mandates that returns are E-Filed. In certain circumstances, you can paper file. ${
      failureToEfilePenalty ? `The failure to E-file penalty is ${failureToEfilePenalty}` : ''
    }`;
  }

  if (!hasMandate && !isPaperAllowed) {
    return `${jurisdictionDescription} does not allow paper filing`;
  }
};

const useSetFilingMethod = ({
  taxReturnsDefinition,
  isEditing = false,
  isNonFilingEditable = false,
}: useFilingMethodSelectionProps) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [isFilingMethodDisabled, setIsFilingMethodDisabled] = useState(false);

  const filingMethodOptions = useMemo(() => {
    const options = [...(isNonFilingEditable ? FILING_METHOD_OPTIONS : FILING_METHOD_EDIT_OPTIONS)];
    if (taxReturnsDefinition) {
      const { isEfileAllowed, isPaperAllowed } = taxReturnsDefinition;
      options[FilingMethodsIndex.EFILE] = {
        ...options[FilingMethodsIndex.EFILE],
        disabled: isPaperAllowed && !isEfileAllowed,
      };
    }

    return options;
  }, [isNonFilingEditable, taxReturnsDefinition]);

  const setDefaultFilingMethod = useCallback(() => {
    if (!taxReturnsDefinition) {
      return;
    }

    let defaultFilingMethod = '';
    const { isEfileAllowed, isPaperAllowed, hasMandate } = taxReturnsDefinition;

    if (isEfileAllowed && isPaperAllowed) {
      defaultFilingMethod = hasMandate ? FilingMethods.EFILE : FilingMethods.PAPER;
    } else if (isEfileAllowed) {
      defaultFilingMethod = FilingMethods.EFILE;
    } else if (isPaperAllowed) {
      defaultFilingMethod = FilingMethods.PAPER;
    }

    if (defaultFilingMethod) {
      setTimeout(() => setFieldValue('filingMethod', defaultFilingMethod));
    }
  }, [setFieldValue, taxReturnsDefinition]);

  useEffect(() => {
    if (isEditing || !taxReturnsDefinition) {
      return;
    }

    setDefaultFilingMethod();
  }, [setDefaultFilingMethod, isEditing, taxReturnsDefinition]);

  useEffect(() => {
    if (!taxReturnsDefinition) {
      return;
    }

    const { isEfileAllowed, isPaperAllowed } = taxReturnsDefinition;

    if (
      isEditing &&
      !isEfileAllowed &&
      isPaperAllowed &&
      (!values?.filingMethod || values.filingMethod === FilingMethods.EFILE)
    ) {
      setTimeout(() => setFieldValue('filingMethod', FilingMethods.PAPER));
      return;
    }
  }, [setFieldValue, isEditing, taxReturnsDefinition, values]);

  useEffect(() => {
    if (!taxReturnsDefinition || isNonFilingEditable) {
      return;
    }
    setIsFilingMethodDisabled(
      taxReturnsDefinition.isPaperAllowed && !taxReturnsDefinition.isEfileAllowed,
    );
  }, [taxReturnsDefinition, isNonFilingEditable]);

  const filingMethodInfoMessage = useMemo(
    () => getFilingMethodInfoMessage({ value: values.filingMethod, taxReturnsDefinition }),
    [values.filingMethod, taxReturnsDefinition],
  );

  return {
    isFilingMethodDisabled,
    filingMethodInfoMessage,
    filingMethodOptions,
    setDefaultFilingMethod,
  };
};

export default useSetFilingMethod;
