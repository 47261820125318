import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import useFetch from '../shared/hooks/useFetch.hook';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useRowEditMode } from '../shared/editMode';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../shared/columnDefinitions/cellRenderers';
import globalContextPropTypes from '../shared/propTypes/globalContext';

import * as api from './api';
import getColumnDefinitions from './stateSpecificInformation.columnDefinitions';
import styles from './stateSpecificInformation.module.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const StateSpecificInformation = ({ globalContext, hasUserPermissionsToEdit }) => {
  const [selectedForm, setSelectedForm] = useState(null);

  const { isFetching: isInsertingInformationData, fetch: insertInformationData } = useFetch({
    action: api.insertInformationalData,
  });
  const { data: formList, isFetching: isFetchingFormList, fetch: fetchFormList } = useFetch({
    action: api.fetchFormList,
  });
  const {
    data: formFieldsResponse,
    isFetching: isFetchingFormFields,
    fetch: fetchFormFields,
  } = useFetch({ action: api.fetchFormFields });
  const { isFetching: isUpdatingFormFields, fetch: updateFormFields } = useFetch({
    action: api.updateFormFields,
  });

  const formListOptions = useMemo(
    () =>
      ((!isFetchingFormList && !isInsertingInformationData && formList) || []).map(item => ({
        ...item,
        value: item.formId,
        label: item.formName,
      })),
    [formList, isFetchingFormList, isInsertingInformationData],
  );

  const formFields = useMemo(
    () => (formFieldsResponse || []).map((field, rowId) => ({ ...field, rowId })),
    [formFieldsResponse],
  );

  useEffect(() => {
    const onContextChange = async () => {
      await insertInformationData(globalContext.params);
      fetchFormList(globalContext.params);
    };

    if (globalContext.isReady) {
      onContextChange();
    }
  }, [insertInformationData, fetchFormList, globalContext.isReady, globalContext.params]);

  useEffect(() => {
    if (formListOptions?.length) {
      setSelectedForm(formListOptions[0]);
    }
  }, [setSelectedForm, formListOptions]);

  useEffect(() => {
    if (globalContext.isReady && selectedForm) {
      const { swpFormId } = selectedForm;
      fetchFormFields({ ...globalContext.params, swpFormId });
    }
  }, [fetchFormFields, globalContext.isReady, globalContext.params, selectedForm]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const { swpFormId } = selectedForm;
      if (!rowsPairsWithChanges.length) {
        return;
      }

      const rows = rowsPairsWithChanges.map(({ newRow }) => newRow);
      await updateFormFields({ rows });
      fetchFormFields({ ...globalContext.params, swpFormId });
    },
    [updateFormFields, fetchFormFields, selectedForm, globalContext.params],
  );

  const isLoading =
    isInsertingInformationData ||
    isFetchingFormList ||
    isFetchingFormFields ||
    isUpdatingFormFields;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: formFields,
    getUniqueRowId,
    editButtonDisabled: isLoading || !formFields?.length,
  });

  const columnDefinitions = useMemo(() => getColumnDefinitions({ isInEditMode, updateRow }), [
    isInEditMode,
    updateRow,
  ]);

  return (
    <>
      {navigationPrompt}
      <div className={styles.flexSpaceBetween}>
        <div className={styles.formListDropdown}>
          <SDKCustomSelect
            className="sdk-custom-select"
            options={formListOptions}
            onChange={useCallback(form => setSelectedForm(form), [])}
            value={selectedForm?.value}
            isLoading={isInsertingInformationData || isFetchingFormList}
            virtualized
          />
        </div>
        <div>{hasUserPermissionsToEdit && editModeButtons}</div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            withSearchBar
            stopEditingWhenCellsLoseFocus
          />
        </div>
      </div>
    </>
  );
};

StateSpecificInformation.propTypes = {
  globalContext: globalContextPropTypes,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default StateSpecificInformation;
