import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { IconCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Ind.',
    field: 'isValid',
    width: 40,
    cellRenderer: IconCellRendererFactory({
      getName: isValid => (isValid ? 'check-mark' : 'close'),
    }),
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Filing Group Id',
    field: 'filingGroupId',
    width: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Org Id',
    field: 'orgId',
    width: 120,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Result',
    field: 'result',
    width: 600,
  },
];

export default columnDefinitions;
