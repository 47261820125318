import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import useEditModeNavigationPrompt from './useEditModeNavigationPrompt.hook';
import useEditModeButtons from './useEditModeButtons.hook';

const noop = () => null;

// eslint-disable-next-line no-unused-vars, no-empty-function
const onSaveNoop = data => {};

const useFormEditMode = ({
  formRef,
  additionalEditModeElements = null,

  onCancel = noop,
  onEnter = noop,
  onSave = onSaveNoop,
  blockContextChanges = () => ({
    type: null,
  }),

  saveButtonDisabled = false,
  cancelButtonDisabled = false,
  isPermanentlyInEditMode = false,
  isDirty = false,
  showLockIcon = false,
  validateBeforeSave = () => true,
  startInEditMode = true,
  turnOffEditModeOnSave = false,
} = {}) => {
  const dispatch = useDispatch();
  const [isInEditMode, setIsInEditMode] = useState(startInEditMode);
  const [isFormDirty, setFormDirty] = useState(false);

  const onFormDirtyChange = useCallback(
    dirty => {
      setFormDirty(dirty);
      dispatch(blockContextChanges(dirty));
    },
    [blockContextChanges, dispatch],
  );

  const findChangesAndSave = useCallback(async () => {
    const data = formRef.current.values;
    await onSave(data);

    return data;
  }, [onSave]);

  const cancelClickHandler = useCallback(() => {
    formRef.current.resetForm();
    onCancel();
  }, [onCancel]);

  const { editModeButtons } = useEditModeButtons({
    isInEditMode,
    setIsInEditMode,
    onCancel: cancelClickHandler,
    isCancelAlwaysVisible: true,
    onEnter,
    onSave: findChangesAndSave,
    saveButtonDisabled: (!isFormDirty && !isDirty) || saveButtonDisabled,
    cancelButtonDisabled: (!isFormDirty && !isDirty) || cancelButtonDisabled,
    isPermanentlyInEditMode,
    showLockIcon,
    validateBeforeSave,
    additionalEditModeElements,
    turnOffEditModeOnSave,
    startInEditMode,
  });

  const { navigationPrompt } = useEditModeNavigationPrompt(isFormDirty);

  return {
    isFormDirty,
    onFormDirtyChange,
    isInEditMode,
    setIsInEditMode,
    editModeButtons,
    findChangesAndSave,
    navigationPrompt,
  };
};
export default useFormEditMode;
