import reducerFactory from '../store/reducerFactory';

import isSameRow from './isSameRow';

export const initialState = {
  clonedRowData: [],
  modifiedRows: [],
  rowsToAdd: [],
  rowsToDelete: [],
};

const actionTypes = {
  RESET_CHANGES: 'RESET_CHANGES',
  CHANGE_ROW_DATA: 'CHANGE_ROW_DATA',
  ADD_ROW: 'ADD_ROW',
  UPDATE_GROUP: 'UPDATE_GROUP',
  UPDATE_ROW: 'UPDATE_ROW',
  DELETE_ROW: 'DELETE_ROW',
};

export const createReducer = getUniqueRowId =>
  reducerFactory({
    actionHandlers: {
      [actionTypes.RESET_CHANGES]: state => ({
        ...state,
        modifiedRows: [],
        rowsToAdd: [],
        rowsToDelete: [],
      }),
      [actionTypes.CHANGE_ROW_DATA]: (state, clonedRowData) => ({
        ...state,
        modifiedRows: [],
        rowsToAdd: [],
        rowsToDelete: [],
        clonedRowData,
      }),
      [actionTypes.ADD_ROW]: (state, { insertedRow, appendNewRows }) => ({
        ...state,
        rowsToAdd: [
          ...(appendNewRows ? [] : [insertedRow]),
          ...state.rowsToAdd,
          ...(appendNewRows ? [insertedRow] : []),
        ],
      }),
      [actionTypes.UPDATE_GROUP]: (state, modifiedGroupRows) => {
        const rowCollectionsChanges = {
          modifiedRows: [
            ...state.modifiedRows.filter(row => {
              let isSame = false;
              modifiedGroupRows.forEach(modifiedRow => {
                if (isSameRow(row, modifiedRow, getUniqueRowId)) {
                  isSame = true;
                }
              });
              return !isSame;
            }),
            ...modifiedGroupRows,
          ],
        };

        return {
          ...state,
          ...rowCollectionsChanges,
        };
      },
      [actionTypes.UPDATE_ROW]: (state, modifiedRow) => {
        const addedNode = Boolean(
          state.rowsToAdd.find(row => isSameRow(row, modifiedRow, getUniqueRowId)),
        );

        const rowCollectionsChanges = addedNode
          ? {
              rowsToAdd: [
                ...state.rowsToAdd.filter(row => !isSameRow(row, modifiedRow, getUniqueRowId)),
                modifiedRow,
              ],
            }
          : {
              modifiedRows: [
                ...state.modifiedRows.filter(row => !isSameRow(row, modifiedRow, getUniqueRowId)),
                modifiedRow,
              ],
            };

        return {
          ...state,
          ...rowCollectionsChanges,
        };
      },
      [actionTypes.DELETE_ROW]: (state, deletedRow) => ({
        ...state,
        rowsToDelete: [
          ...state.rowsToDelete,
          // deletedRow,
          ...(state.rowsToAdd.find(row => isSameRow(row, deletedRow, getUniqueRowId))
            ? []
            : [deletedRow]),
        ],
        rowsToAdd: state.rowsToAdd.filter(
          addedRow => !isSameRow(addedRow, deletedRow, getUniqueRowId),
        ),
      }),
    },
  });

export const actionCreators = {
  addRow: (insertedRow, appendNewRows) => ({
    type: actionTypes.ADD_ROW,
    payload: { insertedRow, appendNewRows },
  }),
  updateGroup: modifiedGroupRows => ({
    type: actionTypes.UPDATE_GROUP,
    payload: modifiedGroupRows,
  }),
  updateRow: modifiedRow => ({ type: actionTypes.UPDATE_ROW, payload: modifiedRow }),
  deleteRow: deletedRow => ({ type: actionTypes.DELETE_ROW, payload: deletedRow }),
  changeRowData: clonedRowData => ({ type: actionTypes.CHANGE_ROW_DATA, payload: clonedRowData }),
  resetChanges: () => ({ type: actionTypes.RESET_CHANGES }),
};
