import { taxNames } from '@common-packages/routes-definitions';
import {
  isAnnualPeriod,
  isExtensionPeriod,
  isOneOfEstimationPeriods,
  isTaxYearBefore2020,
  isTaxYearAfterOr2022,
  isTaxYearAfter2022,
  isTaxYearAfterOr2021,
} from '@common-packages/utils';

import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';
import { CONNECTICUT_JURISDICTION_ID } from '../../shared/constants';
import hasIndividualPartnersPermission from '../../development/dataModels/hasIndividualPartnersPermission';

export const shouldShowLegacyK1Information = ({ isPartnership, taxYear, period }) => {
  if (!isPartnership) {
    return false;
  }

  if (isTaxYearAfterOr2021(taxYear) && (isAnnualPeriod(period) || isExtensionPeriod(period))) {
    return false;
  }

  if (isTaxYearAfterOr2022(taxYear) && isOneOfEstimationPeriods(period)) {
    return false;
  }

  return true;
};

export const getEntityWorkspaceNavigator = ({
  forms,
  dataModelLinks,
  navigatorGenericScreens,
  taxYear,
  period,
  filingTypeId,
  entityId,
  jurisdictionId,
  isClientTtiAvailable,
  isPartnership,
  navigatorEFileData,
  customerPermissions,
}) => {
  const contextParams = {
    taxYear,
    period,
    filingTypeId,
    businessEntityId: entityId,
    jurisdictionId,
    entityId,
  };

  const withContextParams = routeParams =>
    getContextAwareUrlParams({
      ...contextParams,
      ...routeParams,
    });

  const getStateSpecificInformationLink = ({ name }) => ({
    name,
    type: 'screen',
    id: 'state-specific-information',
    isHandledByPermissionRules: true,
    routeParams: withContextParams(),
  });

  const stateSpecificInformationNewLink = {
    name: 'State Specific Information',
    type: 'screen',
    id: 'state-specific-information-v2',
    isHandledByPermissionRules: true,
    routeParams: withContextParams(),
  };

  const getStateSpecificInformationScreensLinks = ({ taxYear, period }) => {
    if (!taxYear || !period) {
      return [];
    }

    if (isTaxYearBefore2020(taxYear)) {
      return [getStateSpecificInformationLink({ name: 'State Specific Information' })];
    }

    if (isAnnualPeriod(period)) {
      return [stateSpecificInformationNewLink];
    }

    if (
      (isTaxYearAfterOr2021(taxYear) && isExtensionPeriod(period)) ||
      (isTaxYearAfterOr2022(taxYear) && isOneOfEstimationPeriods(period))
    ) {
      return [stateSpecificInformationNewLink];
    }

    return [
      stateSpecificInformationNewLink,
      getStateSpecificInformationLink({ name: 'State Specific Information (Est/Ext)' }),
    ];
  };

  const hasAccessToEfile = hasIndividualPartnersPermission(customerPermissions);

  const disabledToolTipContent =
    'Individual Partners permission is required to access this feature';

  return [
    {
      name: 'Filing Positions',
      type: 'folder',
      children: [
        {
          name: 'Filing Decisions',
          id: 'separate-filing-decisions-v2',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: true,
        },
        {
          name: 'Filing Groups',
          id: 'filing-groups',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Setup Tax Returns',
          id: 'setup-tax-returns',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: true,
        },
        isTaxYearAfterOr2021(taxYear)
          ? {
              name: 'Return Status Tracker Details',
              id: 'return-status-tracker',
              type: 'screen',
              isHandledByPermissionRules: true,
            }
          : null,
      ].filter(navigator => navigator),
    },
    {
      name: 'Workpapers',
      type: 'folder',
      loadingKey: 'isFetchingWorkpapers',
      children: [
        {
          name: 'Income Tax Summary',
          id: 'tax-summaries',
          type: 'screen',
          routeParams: withContextParams({
            taxName: taxNames.INCOME_TAX,
          }),
          isHandledByPermissionRules: true,
        },
        {
          name: 'Franchise Tax Summary',
          id: 'tax-summaries',
          type: 'screen',
          routeParams: withContextParams({
            taxName: taxNames.FRANCHISE_TAX,
          }),
          isHandledByPermissionRules: true,
        },
        ...(!isAnnualPeriod(period)
          ? [
              {
                name: 'Estimated Tax Summary',
                id: 'tax-summaries',
                type: 'screen',
                routeParams: withContextParams({
                  taxName: taxNames.ESTIMATED_TAX,
                }),
                isHandledByPermissionRules: true,
              },
            ]
          : []),
        {
          name: 'Tax Summary',
          id: 'custom-screen',
          type: 'screen',
          routeParams: withContextParams({
            customScreenName: 'tax-summary',
          }),
          isHandledByPermissionRules: true,
        },
        {
          name: 'Federal Taxable Income',
          id: 'generic-drill-category',
          type: 'screen',
          isHandledByPermissionRules: true,
          routeParams: withContextParams({
            taxName: taxNames.INCOME_TAX,
            categoryId: 'FEDERAL_TAXABLE_INCOME',
          }),
        },
        {
          name: 'State Modifications',
          type: 'screen',
          id: 'generic-drill-category',
          isHandledByPermissionRules: true,
          routeParams: withContextParams({
            taxName: taxNames.INCOME_TAX,
            categoryId: 'INCOME_MODIFICATIONS',
          }),
        },
        {
          name: 'NonBusiness Income',
          type: 'screen',
          id: 'generic-drill-category',
          isHandledByPermissionRules: true,
          routeParams: withContextParams({
            taxName: taxNames.INCOME_TAX,
            categoryId: 'EV_NONBUSINESS_INCOME',
          }),
        },
        {
          name: 'Apportionment',
          id: 'apportionment-rate-details',
          type: 'screen',
          routeParams: withContextParams({
            taxName: taxNames.INCOME_TAX,
          }),
          isHandledByPermissionRules: true,
        },
        {
          name: 'Adjustments to STI / NOL',
          type: 'screen',
          id: 'generic-drill-category',
          isHandledByPermissionRules: true,
          routeParams: withContextParams({
            taxName: taxNames.INCOME_TAX,
            categoryId: 'ADJUSTMENTS_TO_STI',
          }),
        },
        {
          name: 'State Non-business Income',
          type: 'screen',
          id: 'generic-drill-category',
          isHandledByPermissionRules: true,
          routeParams: withContextParams({
            taxName: taxNames.INCOME_TAX,
            categoryId: 'STATE_NONBUSINESS_INCOME',
          }),
        },
        {
          name: 'Tax Rates',
          id: 'tax-rate-details',
          type: 'screen',
          routeParams: withContextParams({
            taxName: taxNames.INCOME_TAX,
          }),
          isHandledByPermissionRules: true,
        },
        ...(!isTaxYearBefore2020(taxYear)
          ? [
              {
                name: 'State Common Information',
                type: 'screen',
                id: 'state-common-information',
                isHandledByPermissionRules: true,
                routeParams: withContextParams(),
              },
            ]
          : []),
        ...getStateSpecificInformationScreensLinks({ taxYear, period }),
        ...(shouldShowLegacyK1Information({ isPartnership, taxYear, period })
          ? [
              {
                name: 'Schedule K1 Information (Legacy)',
                type: 'screen',
                id: 'schedule-k1-information',
                isHandledByPermissionRules: true,
              },
            ]
          : []),
        ...(isPartnership
          ? [
              {
                name: 'Schedule K1 Information',
                type: 'screen',
                id: 'schedule-k1-information-v2',
                isHandledByPermissionRules: true,
                routeParams: withContextParams(),
              },
              {
                name: 'Global Schedule K1 Information',
                type: 'screen',
                id: 'global-schedule-k1-information',
                isHandledByPermissionRules: true,
              },
              {
                name: 'Flow-through K-1 Details',
                type: 'screen',
                id: 'flow-through-k1-details',
                isHandledByPermissionRules: true,
                routeParams: withContextParams(),
              },
            ]
          : []),
        ...dataModelLinks
          .filter(({ shouldDisplayWorkpaperOnNavigator }) => shouldDisplayWorkpaperOnNavigator)
          .map(({ name, description, datasetDefId }) => ({
            name: description || name,
            type: 'screen',
            id: 'tax-form-workpapers',
            isHandledByPermissionRules: true,
            routeParams: withContextParams({
              datasetDefId,
            }),
          })),
        ...navigatorGenericScreens
          .filter(({ categoryId, accountId }) => categoryId && accountId)
          .map(({ genericScreenId, genericScreenDisplayName, categoryId, accountId }) => ({
            name: genericScreenDisplayName || genericScreenId,
            type: 'screen',
            id: 'generic-drill-account',
            isHandledByPermissionRules: true,
            routeParams: withContextParams({
              taxName: taxNames.INCOME_TAX,
              categoryId,
              accountId,
            }),
          })),
        ...(isPartnership && jurisdictionId === CONNECTICUT_JURISDICTION_ID
          ? [
              {
                name: 'CT Partnership Schedule B',
                type: 'screen',
                id: 'custom-screen',
                isHandledByPermissionRules: true,
                routeParams: withContextParams({
                  customScreenName: 'ct-pship-sch-b',
                }),
              },
              {
                name: 'CT Partnership Schedule C',
                type: 'screen',
                id: 'custom-screen',
                isHandledByPermissionRules: true,
                routeParams: withContextParams({
                  customScreenName: 'ct-pship-sch-c',
                }),
              },
            ]
          : []),
      ],
    },
    {
      name: 'Forms',
      type: 'folder',
      loadingKey: 'isFetchingForms',
      children: [
        {
          name: 'Federal Proforma',
          id: 'separate-federal-proforma',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: false,
        },
        ...forms.map(({ formName, formId }) => ({
          name: formName,
          id: 'view-print-tax-return',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: true,
          routeQueryString: {
            formId,
          },
        })),
      ],
    },
    ...(navigatorEFileData.isEFiling
      ? [
          {
            name: 'E-File',
            type: 'folder',
            children: [
              {
                name: 'E-File Setup',
                id: 'efile-setup',
                type: 'screen',
                isHandledByPermissionRules: true,
                isDisabled: navigatorEFileData.isIndividual && !hasAccessToEfile,
                disabledToolTipContent,
              },
              {
                name: 'Current Return Filing',
                id: 'current-return-filing',
                type: 'screen',
                isHandledByPermissionRules: true,
                isDisabled:
                  !navigatorEFileData.isEFileReady ||
                  (navigatorEFileData.isIndividual && !hasAccessToEfile),
                disabledToolTipContent,
              },
              {
                name: 'E-File Errors',
                id: 'efile-errors',
                type: 'screen',
                isHandledByPermissionRules: true,
                isDisabled:
                  !navigatorEFileData.isEFileReady ||
                  (navigatorEFileData.isIndividual && !hasAccessToEfile),
                disabledToolTipContent,
              },
            ],
          },
        ]
      : []),
    {
      name: 'Proforma',
      type: 'folder',
      children: [
        {
          name: 'Pro-Forma Process',
          id: 'proforma-process',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Federal and Proforma Processes',
          id: 'federal-proforma-processes',
          type: 'screen',
          isHandledByPermissionRules: true,
          isDisabled: navigatorEFileData.isIndividual && !hasAccessToEfile,
          disabledToolTipContent,
        },
      ],
    },
    {
      name: 'Reports',
      type: 'folder',
      children: [
        {
          name: 'Federal Reconciliation',
          id: 'federal-reconciliations',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Prior Year Comparison - Summary',
          id: 'prior-year-comparison-report-summary',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'High Level Planning Data',
          id: 'high-level-planning-data',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Federal Dividend To State Amounts',
          id: 'dividend-to-state-amounts',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Filing Positions',
          id: 'filing-positions-report',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Attachment Details',
          id: 'view-print-attachment-details',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: true,
        },
        ...(isTaxYearAfter2022(taxYear)
          ? []
          : [
              {
                name: 'E-File Transmission Status',
                id: 'efile-transmission-status-report',
                type: 'screen',
                isHandledByPermissionRules: true,
              },
            ]),
        {
          name: 'Federal Filing Availability',
          id: 'efile-federal-return-availability-report',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        ...(isTaxYearAfter2022(taxYear) || !isClientTtiAvailable
          ? []
          : [
              {
                name: 'E-File XML Comparison Report',
                id: 'efile-xml-comparison-report',
                type: 'screen',
                isHandledByPermissionRules: true,
              },
            ]),
        {
          name: 'Manual Adjustment Report',
          id: 'separate-manual-adjustment-report',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
      ],
    },
    {
      name: 'Tools',
      type: 'folder',
      children: [
        {
          name: 'Diagnostics',
          id: 'diagnostics',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Generic Account Data Upload',
          id: 'account-data-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Informational Data Upload',
          id: 'informational-data-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Mult Data Upload',
          id: 'mult-data-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Filing Group Members Upload',
          id: 'filing-group-members-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Manual Calculations',
          id: 'manual-calculations',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Attachments',
          id: 'attachments',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Federal File Upload',
          id: 'gtw-file-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Ownership Allocations Upload',
          id: 'ownership-allocations-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
      ],
    },
  ];
};
