import { LinkCellRendererFactory } from '../../../../shared/columnDefinitions/cellRenderers';

export const federalDataDetailsLinkRenderer = (includeCorrelationId = false) =>
  LinkCellRendererFactory({
    getText: ({ value }) => value,
    getPathName: (_, data) =>
      `federal-data-diagnostics/${data.orgId ? 'entity' : 'group'}/${
        data.orgId ?? data.filingGroupId
      }/${data.reportingPeriod ?? '1'}${includeCorrelationId ? `/${data.correlationId}` : ''}`,
  });
