import { HttpMethods } from '@common-packages/shared-constants';

import { QueryKeys } from '../queries';

import { useCustomMutation } from '.';

export const useMutationUpdateConsolInfoDataFields = () =>
  useCustomMutation({
    handleData: ({ rows }) => ({
      url: '/api/consolidations/informational-data-v2',
      method: HttpMethods.PUT,
      body: { rows },
    }),
    resetKey: QueryKeys.ConsolidationInformationalData.Fields,
    successMessage: 'Consolidation informational data updated successfully',
    errorMessage: 'Updating consolidation informational data failed',
  });
