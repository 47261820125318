import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';

import StateCommonInformation from './stateCommonInformation.container';

const StateCommonInformationWithContext = withContextWrapper()(StateCommonInformation);

const StateCommonInformationPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(GlobalContextPage);

const StateCommonInformationRoutes = () => {
  return (
    <AuthorizedCustomRoute
      exact
      path={Routes.stateCommonInformation.MAIN}
      wrapper={StateCommonInformationPage}
      component={StateCommonInformationWithContext}
      hideGlobalContext
      disableBreadcrumbs
    />
  );
};

export default StateCommonInformationRoutes;
