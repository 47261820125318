import { SelectOption } from '@tls/slt-types';

import { MnemonicDisplayValues } from '../enums';

const getMnemonicDisplayOptions = (): SelectOption[] => [
  { label: 'None', value: '' },
  ...Object.entries(MnemonicDisplayValues).map(([key, value]) => ({
    label: value,
    value: key,
  })),
];

export default getMnemonicDisplayOptions;
