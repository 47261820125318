import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';

import { fetchFilingStatesTypes } from './types';

const initialState = {
  isFetchingFilingStates: false,
  filingStates: [],
};

const filingStatesReducer = reducerFactory({
  initialState,
  actionHandlers: getApiActionTypesHandlers({
    apiTypes: fetchFilingStatesTypes,
    payloadKey: 'filingStates',
    fetchingKey: 'isFetchingFilingStates',
  }),
});

export default filingStatesReducer;
