import { PdfPageDataUsageTypes } from '../constants';

const findTopMostParentOfSelectedDataset = (dataset, assignedDatasets) => {
  const parentOfDataset = assignedDatasets?.find(
    assignedDataset => assignedDataset.treeHierarchyId === dataset?.treeHierarchyParentId,
  );

  if (parentOfDataset?.type === PdfPageDataUsageTypes.PAGE) {
    return dataset;
  }

  return findTopMostParentOfSelectedDataset(parentOfDataset, assignedDatasets);
};

export default findTopMostParentOfSelectedDataset;
