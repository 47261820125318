import { queryParamRegex } from '@common-packages/custom-reports-helpers';

const uniq = arr => [...new Set(arr)];

const getQueryParams = query => {
  const rawParams = query.match(queryParamRegex);

  const parsedParams = rawParams
    ? uniq(rawParams.map(param => param.slice(2, -1)).filter(param => param))
    : [];

  return parsedParams;
};

export default getQueryParams;
