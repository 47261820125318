import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';

const ToolsDashboard = () => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Generic Account Data Upload"
      icon="upload"
      description="Generic Account Data Upload"
      route={Routes.genericAccountDataUpload.MAIN}
      accessOnlyWithEditPermission
    />
    <AuthorizedCard
      title="Generic Info Data Upload"
      icon="upload"
      description="Generic Info Data Upload"
      route={Routes.informationalDataUpload.MAIN}
      accessOnlyWithEditPermission
    />
    <AuthorizedCard
      title="Mult Data Upload"
      icon="upload"
      description="Mult Data Upload"
      route={Routes.multDataUpload.MAIN}
      accessOnlyWithEditPermission
    />
    <AuthorizedCard
      title="Ownership Allocations Upload"
      icon="upload"
      description="Ownership Allocations Upload"
      route={Routes.ownershipAllocationsUpload.MAIN}
      accessOnlyWithEditPermission
    />
    <AuthorizedCard
      title="Filing Group Members Upload"
      icon="upload"
      description="Filing Group Members Upload"
      route={Routes.filingGroupMembersUpload.MAIN}
      accessOnlyWithEditPermission
    />
    <AuthorizedCard
      title="Refresh Pro-Forma Federal Return"
      icon="refresh"
      description="Refresh Pro-Forma Federal Return"
      route={Routes.federalDataRefresh.MAIN}
      accessOnlyWithEditPermission
    />
    <AuthorizedCard
      title="Attachments"
      icon="file"
      description="Add or Edit Attachments"
      route={Routes.attachments.MAIN}
      accessOnlyWithEditPermission
    />
  </div>
);

export default ToolsDashboard;
