const resizeDropdown = (currentTarget: HTMLDivElement) => {
  // We create a copy of the select element and apply styling that causes the select to have
  // the width of its content while making sure the copy is not visible to the user.
  // Then we take the width of the copy and apply it on the target element.
  // Pure CSS solution (styling on hover) doesn't work because it causes a lot of flickering.
  const nodeCopy = currentTarget.cloneNode(true) as HTMLDivElement;
  nodeCopy.style.visibility = 'hidden';
  nodeCopy.style.width = 'max-content';
  const selectValueEl = nodeCopy.querySelector<HTMLDivElement>('.Select-value');
  if (selectValueEl) {
    selectValueEl.style.position = 'relative';
    selectValueEl.style.paddingRight = '0';
  }
  document.body.appendChild(nodeCopy);
  const newWidth = nodeCopy.clientWidth;
  nodeCopy.remove();

  if (newWidth > currentTarget.clientWidth) {
    currentTarget.style.width = `${newWidth}px`;
    currentTarget.style.position = 'relative';
  } else {
    currentTarget.style.width = '';
  }
};

export default resizeDropdown;
