import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchGenericFunctionsTypes,
  fetchDataTypesTypes,
  fetchConstantsTypes,
  fetchCategoriesTypes,
  fetchAccountsTypes,
  fetchJurisdictionsTypes,
  fetchGenericFunctionParamsTypes,
  createGenericFunctionWithParamsTypes,
  updateGenericFunctionWithParamsTypes,
  deleteGenericFunctionTypes,
} from './types';

export const fetchGenericFunctions = apiAction({
  types: fetchGenericFunctionsTypes,
  apiAction: api.fetchGenericFunctions,
});

export const fetchDataTypes = apiAction({
  types: fetchDataTypesTypes,
  apiAction: api.fetchDataTypes,
});

export const fetchConstants = apiAction({
  types: fetchConstantsTypes,
  apiAction: api.fetchConstants,
});

export const fetchCategories = apiAction({
  types: fetchCategoriesTypes,
  apiAction: api.fetchCategories,
});

export const fetchAccounts = apiAction({
  types: fetchAccountsTypes,
  apiAction: api.fetchAccounts,
});

export const fetchJurisdictions = apiAction({
  types: fetchJurisdictionsTypes,
  apiAction: api.fetchJurisdictions,
});

export const fetchGenericFunctionParams = apiAction({
  types: fetchGenericFunctionParamsTypes,
  apiAction: api.fetchGenericFunctionParams,
});

export const createGenericFunctionWithParams = apiAction(
  {
    types: createGenericFunctionWithParamsTypes,
    apiAction: api.createGenericFunctionWithParams,
  },
  { successNotificationMessage: () => 'Generic function has been successfully created' },
);

export const updateGenericFunctionWithParams = apiAction(
  {
    types: updateGenericFunctionWithParamsTypes,
    apiAction: api.updateGenericFunctionWithParams,
  },
  { successNotificationMessage: () => 'Generic function has been successfully updated' },
);

export const deleteGenericFunction = apiAction(
  {
    types: deleteGenericFunctionTypes,
    apiAction: api.deleteGenericFunction,
  },
  { successNotificationMessage: () => 'Generic function has been successfully deleted' },
);
