import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { ColumnTypes } from '@common-packages/shared-constants';
import { filingDecisionsV2Schemas } from '@common-packages/validators';

import Loading from '../../shared/displayComponents/loading.component';
import { globalContextSelector } from '../../shared/store/selectors';
import { fetchFormsByPeriod } from '../../shared/store/actions';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import { columnsBlueprintPropTypes } from '../../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import { useQueryOrgProfile } from '../../shared/queries/filingDecisions';

import styles from './editFilingDecisions.module.scss';
import EditFilingDecisionsForm from './editFilingDecisionsForm.component';
import EditConsolidatedFilingDecisionsForm from './editConsolidatedFilingDecisionsForm.component';
import { rowContextValuesPropTypes } from './propTypes';

const EditFilingDecisions = ({
  columnsBlueprint,
  rowContextValues,
  saveFilingDecisions,
  isSavingFilingDecisions,
  title = '',
  isConsolidated = false,
}) => {
  const dispatch = useDispatch();
  const globalContext = useSelector(globalContextSelector);

  const { taxYear, period } = globalContext.params;

  const filingAttributes = useMemo(
    () => columnsBlueprint.filter(({ columnType }) => columnType === ColumnTypes.FILING_ATTRIBUTES),
    [columnsBlueprint],
  );

  const { data: orgProfile, isFetching: isFetchingOrgProfile } = useQueryOrgProfile({
    params: {
      taxYear,
      period,
      jurisdictionId: rowContextValues.jurisdictionId,
      businessEntityId: rowContextValues.entityId,
    },
    enabled: Boolean(
      taxYear && period && rowContextValues.jurisdictionId && rowContextValues.entityId,
    ),
  });

  const calcPreferences = useMemo(
    () =>
      orgProfile?.columnsBlueprint.filter(
        ({ columnType }) => columnType === ColumnTypes.CALC_PREFERENCES,
      ) || [],
    [orgProfile],
  );

  const formattedRowToEdit = useMemo(
    () => (orgProfile && !isFetchingOrgProfile ? orgProfile : null),
    [isFetchingOrgProfile, orgProfile],
  );

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      await saveFilingDecisions(values);
      if (globalContext.isReady) {
        await dispatch(fetchFormsByPeriod(globalContext.params));
      }
      setSubmitting(false);
    },
    [saveFilingDecisions, dispatch, globalContext.isReady, globalContext.params],
  );

  return (
    <Loading
      isLoading={isFetchingOrgProfile || !filingAttributes}
      className={styles.bottomSlideUpSpinner}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={formattedRowToEdit}
        validationSchema={filingDecisionsV2Schemas.updateFilingDecisionSchema}
        enableReinitialize
      >
        {isConsolidated ? (
          <EditConsolidatedFilingDecisionsForm
            isSavingFilingDecisions={isSavingFilingDecisions}
            filingAttributes={filingAttributes}
            calcPreferences={calcPreferences}
            title={title}
            formattedRowToEdit={formattedRowToEdit}
          />
        ) : (
          <EditFilingDecisionsForm
            isSavingFilingDecisions={isSavingFilingDecisions}
            filingAttributes={filingAttributes}
            calcPreferences={calcPreferences}
            title={title}
            formattedRowToEdit={formattedRowToEdit}
          />
        )}
      </Formik>
    </Loading>
  );
};

EditFilingDecisions.propTypes = {
  title: PropTypes.string,
  columnsBlueprint: columnsBlueprintPropTypes.isRequired,
  isSavingFilingDecisions: PropTypes.bool.isRequired,
  saveFilingDecisions: PropTypes.func.isRequired,
  rowContextValues: rowContextValuesPropTypes,
  consolidatedProfilesOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  isConsolidated: PropTypes.bool,
};

export default EditFilingDecisions;
