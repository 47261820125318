import { httpDelete, httpGetAndParse, httpPost, httpPut } from '../../../utils/fetchService';

export const fetchGenericScreenDefinition = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/development/generic-screen-definitions/${taxYear}/${period}`,
    errorMessage: 'Fetching generic screen definitions failed',
  });

export const deleteGenericScreenDefinition = ({
  taxYear,
  period,
  jurisdictionId,
  screenId,
  accountId,
}) =>
  httpDelete({
    route: `/api/development/generic-screen-definitions/${taxYear}/${period}/${jurisdictionId}/${screenId}/${accountId}`,
    errorMessage: 'Deleting generic screen definition failed',
  });

export const fetchAccounts = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/generic-screen-definitions/accounts/${taxYear}`,
    errorMessage: 'Fetching accounts failed',
  });

export const fetchCategories = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/generic-screen-definitions/categories/${taxYear}`,
    errorMessage: 'Fetching categories failed',
  });

export const fetchJurisdictions = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/generic-screen-definitions/jurisdictions/${taxYear}`,
    errorMessage: 'Fetching jurisdictions failed',
  });

export const createGenericScreenDefinition = ({
  taxYear,
  period,
  screenId,
  jurisdictionId,
  categoryId,
  accountId,
  title,
  screenType,
  saveButton,
}) =>
  httpPost({
    route: `/api/development/generic-screen-definitions/${taxYear}/${period}`,
    errorMessage: 'Adding generic screen definition failed',
    body: {
      screenId,
      jurisdictionId,
      categoryId,
      accountId,
      title,
      screenType,
      saveButton,
    },
  });

export const updateGenericScreenDefinition = ({
  taxYear,
  period,
  screenId,
  jurisdictionId,
  categoryId,
  accountId,
  title,
  screenType,
  saveButton,
}) =>
  httpPut({
    route: `/api/development/generic-screen-definitions/${taxYear}/${period}`,
    errorMessage: 'Adding generic screen definition failed',
    body: {
      screenId,
      jurisdictionId,
      categoryId,
      accountId,
      title,
      screenType,
      saveButton,
    },
  });

export const fetchGenericScreenDefinitionRowDetails = ({ taxYear, period, screenId }) =>
  httpGetAndParse({
    route: `/api/development/generic-screen-definitions/${taxYear}/${period}/${screenId}`,
    errorMessage: 'Fetching generic screen definition row details failed',
  });

export const createGenericScreenDefinitionRow = ({ taxYear, period, screenId, rows }) =>
  httpPost({
    route: `/api/development/generic-screen-definitions/${taxYear}/${period}/${screenId}/details`,
    body: {
      rows,
    },
    errorMessage: 'An error occurred while saving rows, please try again.',
  });
