import { httpGetAndParse, httpPut } from '../../utils/fetchService';

export const fetchCustomScreen = ({
  taxYear,
  period,
  businessEntityId,
  jurisdictionId,
  customScreenName,
}) =>
  httpGetAndParse({
    route: `/api/custom-screens/${taxYear}/${period}/${businessEntityId}/${jurisdictionId}/${customScreenName}`,
    errorMessage: 'Fetching custom screen failed',
  });

export const updateCustomScreen = ({
  taxYear,
  period,
  businessEntityId,
  jurisdictionId,
  payload,
}) =>
  httpPut({
    route: `/api/custom-screens/${taxYear}/${period}/${businessEntityId}/${jurisdictionId}`,
    errorMessage: 'Updating custom screen failed',
    body: { payload },
  });
