import React, { ReactNode, useMemo } from 'react';

import { StepTabContent, StepTabHeader, StepTabWrapper } from '../../stepTab';
import AppkitIcon from '../../../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { SubmissionListItem } from '../useViewStatusStepContext.hook';
import formatDate from '../../../utils/formatDate';
import StepTabRightHeader from '../../stepTabRightHeader/stepTabRightHeader.component';
import { StepStatus, TransmissionStatus } from '../../../enums';
import { DENIED_STATUS } from '../../../constants';
import { GetTransmissionStatusResponse } from '../../../../../../../common/types';

import styles from './viewStatusStepTab.module.scss';
import TransmissionSelect from './transmissionSelect.component';

interface ViewStatusTabProps {
  selectedTransmissionStatusData: GetTransmissionStatusResponse | null;
  submissions: SubmissionListItem[];
  children: ReactNode;
  step5Status: StepStatus | null;
  setSelectedTransmissionHistoryId: (id: string) => void;
  selectedTransmissionHistoryId: string;
}

const getTitleByTransmission = ({
  selectedTransmissionStatusData,
}: {
  selectedTransmissionStatusData: GetTransmissionStatusResponse | null;
}) => {
  if (!selectedTransmissionStatusData) {
    return 'Missing submission status';
  }

  if (selectedTransmissionStatusData.status === TransmissionStatus.ACCEPTED) {
    return 'Your E-File has been accepted';
  }

  if (selectedTransmissionStatusData.status === TransmissionStatus.REJECTED) {
    return 'E-File submission was rejected';
  }

  if (selectedTransmissionStatusData.status === TransmissionStatus[DENIED_STATUS]) {
    return 'E-File Submission was denied';
  }

  return 'Your E-File is under review';
};

const getIconByTransmission = (transmission: GetTransmissionStatusResponse | null) => {
  if (!transmission || transmission.status === TransmissionStatus.PENDING) {
    return <AppkitIcon icon="time" size={48} type="fill" className="a-text-yellow" />;
  }

  if (transmission.status === TransmissionStatus.ACCEPTED) {
    return <AppkitIcon icon="confirmed" size={48} type="fill" className="a-text-green" />;
  }

  if (
    transmission.status === TransmissionStatus.REJECTED ||
    transmission.status === TransmissionStatus[DENIED_STATUS]
  ) {
    return <AppkitIcon icon="circle-minus" size={48} type="fill" className="a-text-red" />;
  }
};

const ViewStatusTab = ({
  children,
  selectedTransmissionStatusData,
  submissions,
  step5Status,
  selectedTransmissionHistoryId,
  setSelectedTransmissionHistoryId,
}: ViewStatusTabProps) => {
  const title = getTitleByTransmission({
    selectedTransmissionStatusData,
  });

  const lastUpdatedDate = useMemo(
    () =>
      selectedTransmissionStatusData?.date
        ? formatDate(selectedTransmissionStatusData.date)
        : 'Date not found',
    [selectedTransmissionStatusData],
  );

  const hasStepFailed = step5Status === StepStatus.FAILED;

  return (
    <StepTabWrapper>
      <div className={styles.header}>
        <StepTabHeader
          icon={getIconByTransmission(selectedTransmissionStatusData)}
          title={title}
          subtitle={`Authorized by: ${selectedTransmissionStatusData?.authorizedBy || ''}`}
        >
          <div className={styles.pastSubmissions}>
            {!hasStepFailed && (
              <>
                <div className={styles.transmissionsSelectContainer}>
                  <label>PAST SUBMISSIONS:</label>
                  <TransmissionSelect
                    className={styles.transmissionSelect}
                    submissions={submissions}
                    selectedTransmissionHistoryId={selectedTransmissionHistoryId}
                    setSelectedTransmissionHistoryId={setSelectedTransmissionHistoryId}
                  />
                </div>
                <span>Last updated: {lastUpdatedDate}</span>
              </>
            )}
            <div>
              <StepTabRightHeader
                stepStatus={step5Status}
                stepFailedMessage="View status process failed"
              />
            </div>
          </div>
        </StepTabHeader>
      </div>
      {!hasStepFailed && <StepTabContent>{children}</StepTabContent>}
    </StepTabWrapper>
  );
};

export default ViewStatusTab;
