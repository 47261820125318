import {
  FindReturnDefinitionsParams,
  FindReturnDefinitionsResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export const useQueryReturnDefinitions = ({
  params,
  enabled,
}: {
  params: FindReturnDefinitionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindReturnDefinitionsResponse>(
    [QueryKeys.ReturnDefinitions.ReturnDefinitions, params],
    {
      url: `/api/development/return-definitions/${params.taxYear}/${params.jurisdictionId}`,
      defaultData: { data: [], filingAttributesColumnsBlueprint: [] },
      enabled,
      errorMessage: 'Error fetching return definitions',
    },
  );
