import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchAccountGroupsTypes,
  fetchAccountsTypes,
  fetchAccountDataTypes,
  updateAccountDataTypes,
  SELECT_ACCOUNT_GROUP,
  SELECT_ACCOUNT,
} from './types';

export const fetchAccountGroups = apiAction({
  types: fetchAccountGroupsTypes,
  apiAction: api.fetchAccountGroups,
});

export const fetchAccounts = apiAction({
  types: fetchAccountsTypes,
  apiAction: api.fetchAccounts,
});

export const fetchAccountData = apiAction({
  types: fetchAccountDataTypes,
  apiAction: api.fetchAccountData,
});

export const updateAccountData = apiAction({
  types: updateAccountDataTypes,
  apiAction: api.updateAccountData,
});

export const selectAccountGroup = accountGroup => ({
  type: SELECT_ACCOUNT_GROUP,
  payload: accountGroup,
});

export const selectAccount = account => ({
  type: SELECT_ACCOUNT,
  payload: account,
});
