import { useMemo, useEffect, useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import camelCase from 'lodash.camelcase';

import {
  ReturnDefinitionFilingAttributes,
  FetchConsolidationsDetailsData,
  TaxReturnResultRowWithCalcOptionsAndFilingAttributes,
} from '../../../../../../common/types/apiShapes';
import { FormValues } from '../../utils/useFormInitialValues';

interface useSetFilingAttributeProps {
  isEditing: boolean;
  existingTaxReturnsForJurisdiction:
    | FetchConsolidationsDetailsData[]
    | TaxReturnResultRowWithCalcOptionsAndFilingAttributes[];
  returnDefinitionFilingAttributes?: ReturnDefinitionFilingAttributes;
  filingAttribute: keyof ReturnDefinitionFilingAttributes;
  filingAttributeFormField?: string;
}

const useSetFilingAttribute = ({
  isEditing,
  existingTaxReturnsForJurisdiction,
  returnDefinitionFilingAttributes,
  filingAttribute,
  filingAttributeFormField = camelCase(filingAttribute),
}: useSetFilingAttributeProps) => {
  const { setFieldValue } = useFormikContext<FormValues>();
  const [isFilingAttributeDisabled, setIsFilingAttributeSelectDisabled] = useState(false);

  const taxReturnDefinitionEnabledFilingAttributeOptions = useMemo(
    () =>
      returnDefinitionFilingAttributes?.[filingAttribute]?.filter(
        ({ isTaxReturnDefinitionEnabled }) => isTaxReturnDefinitionEnabled,
      ) || [],
    [filingAttribute, returnDefinitionFilingAttributes],
  );

  const hasExistingTaxReturnsForJurisdiction = existingTaxReturnsForJurisdiction.length;

  const setDefaultFilingAttribute = useCallback(() => {
    if (hasExistingTaxReturnsForJurisdiction) {
      const existingTaxReturnForJurisdictionFilingAttribute = taxReturnDefinitionEnabledFilingAttributeOptions.find(
        ({ value }) => existingTaxReturnsForJurisdiction[0][value],
      );
      if (existingTaxReturnForJurisdictionFilingAttribute) {
        setFieldValue(
          filingAttributeFormField,
          existingTaxReturnForJurisdictionFilingAttribute.value,
        );
        return;
      }
    }

    if (taxReturnDefinitionEnabledFilingAttributeOptions.length === 1) {
      setFieldValue(
        filingAttributeFormField,
        taxReturnDefinitionEnabledFilingAttributeOptions[0].value,
      );
    }
  }, [
    setFieldValue,
    taxReturnDefinitionEnabledFilingAttributeOptions,
    hasExistingTaxReturnsForJurisdiction,
    existingTaxReturnsForJurisdiction,
    filingAttributeFormField,
  ]);

  useEffect(() => {
    if (isEditing) {
      return;
    }
    setDefaultFilingAttribute();
  }, [setDefaultFilingAttribute, isEditing]);

  useEffect(() => {
    // if there is only one option and is not in edit mode, then go ahead and disable
    setIsFilingAttributeSelectDisabled(
      !isEditing && !(taxReturnDefinitionEnabledFilingAttributeOptions.length > 1),
    );
  }, [isEditing, taxReturnDefinitionEnabledFilingAttributeOptions]);

  return {
    taxReturnDefinitionEnabledFilingAttributeOptions,
    isFilingAttributeDisabled,
    setDefaultFilingAttribute,
  };
};

export default useSetFilingAttribute;
