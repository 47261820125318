import React, { useCallback, useReducer } from 'react';
import { Steps, Step, Button } from '@pwc/appkit-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

import { WIZARD_STEPS } from './constants';

export const WIZARD_INITIAL_STEP_NUMBER = 1;

export const getInitialState = wizardSteps =>
  wizardSteps.reduce(
    (acc, wizardStep, index) => ({
      ...acc,
      [index + 1]: {
        index: index + 1,
        stepName: wizardStep.name,
        warning: wizardStep.warningForNextButton,
        isStepCompleted: false,
        isStepActionRunning: false,
      },
    }),
    {
      currentStep: WIZARD_INITIAL_STEP_NUMBER,
    },
  );

const checkIsWizardActionRunning = (wizardSteps, state) =>
  wizardSteps.some((wizardStep, index) => state[index + 1].isStepActionRunning);

export const wizardStatePropTypes = PropTypes.shape({
  currentStep: PropTypes.number.isRequired,
  ...WIZARD_STEPS.reduce(
    (acc, wizardStep, index) => ({
      ...acc,
      [index + 1]: PropTypes.shape({
        index: PropTypes.number.isRequired,
        stepName: PropTypes.string.isRequired,
        warning: PropTypes.string,
        isStepCompleted: PropTypes.bool.isRequired,
        isStepActionRunning: PropTypes.bool.isRequired,
      }),
    }),
    {},
  ),
});

const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
const NEXT_STEP = 'NEXT_STEP';
const PREVIOUS_STEP = 'PREVIOUS_STEP';
const SET_STEP_STATUS = 'SET_STEP_STATUS';

const getReducer = wizardSteps => (state, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    case NEXT_STEP:
      return {
        ...state,
        currentStep:
          state.currentStep + 1 <= wizardSteps.length ? state.currentStep + 1 : state.currentStep,
      };
    case PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1 >= 1 ? state.currentStep - 1 : state.currentStep,
      };
    case SET_STEP_STATUS:
      return {
        ...state,
        [payload.index]: {
          ...state[payload.index],
          ...payload,
        },
      };
    default:
      throw new Error('Unsupported action type');
  }
};

export const createUseWizardHook = ({ wizardSteps, initialState }) => ({
  isLoading = false,
  isContextReady = true,
} = {}) => {
  const [state, dispatchWizardAction] = useReducer(getReducer(wizardSteps), initialState);
  const { currentStep } = state;
  const isWizardActionRunning = checkIsWizardActionRunning(wizardSteps, state);

  const getStepStatus = useCallback(step => state[step] || {}, [state]);

  const handleNext = useCallback(() => {
    if (currentStep < wizardSteps.length) {
      dispatchWizardAction({ type: NEXT_STEP });
    }
  }, [currentStep]);

  const handleBack = useCallback(() => {
    if (currentStep > 1) {
      dispatchWizardAction({ type: PREVIOUS_STEP });
    }
  }, [currentStep]);

  const handleStepPick = useCallback(
    step => () => {
      if (
        !isWizardActionRunning &&
        !isLoading &&
        isContextReady &&
        (step === 1 || getStepStatus(step - 1).isStepCompleted)
      ) {
        dispatchWizardAction({ type: SET_CURRENT_STEP, payload: step });
      }
    },
    [getStepStatus, isContextReady, isLoading, isWizardActionRunning],
  );

  const setCurrentStep = useCallback(
    step =>
      dispatchWizardAction({
        type: SET_CURRENT_STEP,
        payload: step,
      }),
    [],
  );

  const setStepStatus = useCallback(
    ({ currentStep, payload }) =>
      dispatchWizardAction({
        type: SET_STEP_STATUS,
        payload: {
          index: currentStep,
          ...payload,
        },
      }),
    [],
  );

  const getProgressStepClass = useCallback(
    step => {
      const isDisabled =
        step !== WIZARD_INITIAL_STEP_NUMBER && !getStepStatus(step - 1)?.isStepCompleted;

      return classNames({
        'step-disabled': isDisabled || isWizardActionRunning || isLoading || !isContextReady,
      });
    },
    [getStepStatus, isWizardActionRunning, isLoading, isContextReady],
  );

  const showWarning = useCallback(
    step =>
      !isLoading &&
      !isWizardActionRunning &&
      isContextReady &&
      !getStepStatus(step).isStepCompleted &&
      getStepStatus(step).warning,
    [isLoading, isWizardActionRunning, isContextReady, getStepStatus],
  );

  const wizardProgress = (
    <div className={styles.wizardSteps}>
      <Steps currentStep={currentStep} size="large">
        {wizardSteps.map((step, i) => (
          <Step
            key={i}
            content={String(i + 1)}
            onClick={handleStepPick(i + 1)}
            title={showWarning(i) ? getStepStatus(i).warning : ''}
            className={getProgressStepClass(i + 1)}
            data-testid={String(i + 1)}
          >
            {step.name}
          </Step>
        ))}
      </Steps>
    </div>
  );

  const wizardButtons = (
    <>
      <div className={styles.navigationButtonsWrapper}>
        <Button
          size="lg"
          className={styles.navigationButton}
          onClick={handleBack}
          disabled={currentStep <= 1 || isWizardActionRunning || isLoading || !isContextReady}
        >
          Back
        </Button>
        <Button
          size="lg"
          className={styles.navigationButton}
          onClick={handleNext}
          title={showWarning(currentStep) ? getStepStatus(currentStep).warning : ''}
          disabled={
            currentStep >= wizardSteps.length ||
            !getStepStatus(currentStep).isStepCompleted ||
            isWizardActionRunning ||
            isLoading ||
            !isContextReady
          }
        >
          Next
        </Button>
      </div>
      {showWarning(currentStep) && (
        <div data-testid="warning" className={styles.nextButtonWarning}>
          {getStepStatus(currentStep).warning}
        </div>
      )}
    </>
  );

  return {
    wizardProgress,
    wizardButtons,
    wizardState: {
      ...state,
      isWizardActionRunning,
    },
    setCurrentStep,
    setStepStatus,
  };
};

export default createUseWizardHook({
  wizardSteps: WIZARD_STEPS,
  initialState: getInitialState(WIZARD_STEPS),
});
