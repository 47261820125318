import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const HeaderLinkRenderer = ({ linkProps: { pathname }, displayName }) => {
  return (
    <div className="ag-cell-label-container">
      <div className="ag-header-cell-label">
        <div className="ag-header-cell-text">
          <Link
            to={{
              pathname,
            }}
          >
            {displayName}
          </Link>
        </div>
      </div>
    </div>
  );
};

HeaderLinkRenderer.propTypes = {
  linkProps: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  displayName: PropTypes.string.isRequired,
};

export default HeaderLinkRenderer;
