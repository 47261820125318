import React, { useCallback } from 'react';
import { Field, useFormikContext } from 'formik';

import Loading from '../../shared/displayComponents/loading.component';
import CalcPreferences from '../../shared/forms/calcPreferences/calcPreferences.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import CheckboxFormik from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import { CalcPreferenceDefinitions } from '../../../../common/types';

import styles from './editFilingDecisionsForm.module.scss';

const CALC_OPTIONS_KEY = 'calcOptions';

interface EditConsolidatedFilingDecisionsFormValues {
  filingGroupsIds: string;
  jurisdiction: string;
  nexusInd: boolean;
  filingInState: boolean;
  calcOptions: Record<string, unknown>;
}

interface EditConsolidatedFilingDecisionsFormProps {
  calcPreferences: {
    selectOptions: Record<string, CalcPreferenceDefinitions>;
  }[];
  isSavingFilingDecisions: boolean;
  title: string;
}

const EditConsolidatedFilingDecisionsForm = ({
  calcPreferences,
  isSavingFilingDecisions,
  title = '',
}: EditConsolidatedFilingDecisionsFormProps) => {
  const {
    isSubmitting,
    values,
    submitForm,
    resetForm,
    dirty,
  } = useFormikContext<EditConsolidatedFilingDecisionsFormValues>();

  const commonCalcPreferences = calcPreferences[0];
  const stateCalcPreferences = calcPreferences[1];

  const onCancelClick = useCallback(() => {
    resetForm();
  }, [resetForm]);

  return (
    <form className={styles.filingDecisionsForm}>
      <div className={styles.stickyHeader}>
        <div className={styles.filingDecisionsFormHeader}>
          <p className={styles.filingDecisionsFormTitle}>Edit Filing Decisions</p>
          <ButtonsGroup
            className={styles.filingDecisionsButtons}
            disabled={!dirty || isSavingFilingDecisions}
            fullWidth={false}
            isSubmitting={isSubmitting}
            onCancelClick={onCancelClick}
            submitButtonLabel="Save"
            submitForm={submitForm}
          />
        </div>
        <hr />
      </div>
      {title && (
        <div className="row">
          <div className="col">
            <div className="a-h5">{title}</div>
          </div>
        </div>
      )}
      <div className="row">
        <div className={styles.filingDecisionsColumn}>
          <Field
            label="Filing Group(s)"
            name="filingGroupsIds"
            value={values.filingGroupsIds || ''}
            component={Input}
            disabled
          />
          <Field
            className={styles.filingDecisionsCheckbox}
            label="Nexus"
            name="nexusInd"
            value={values.nexusInd}
            component={CheckboxFormik}
            disabled={!values.filingInState}
          />
        </div>
        <div className={`${styles.calcPreferences} ${styles.consolDynamicFormWithoutExcess}`}>
          <Loading isLoading={!calcPreferences} small>
            <>
              <CalcPreferences
                calcPreferences={commonCalcPreferences}
                title="Common Calc Preferences"
                calcOptionsKey={CALC_OPTIONS_KEY}
              />
              <CalcPreferences
                calcPreferences={stateCalcPreferences}
                title="State Calc Preferences"
                calcOptionsKey={CALC_OPTIONS_KEY}
              />
            </>
          </Loading>
        </div>
      </div>
    </form>
  );
};

export default EditConsolidatedFilingDecisionsForm;
