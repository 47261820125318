import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { taxYearSelector, filingTypeIdSelector } from '../shared/store/selectors';
import { filingTypes } from '../shared/constants';

import { fetchAllJurisdictions, selectFilingGroupId } from './store/actions';
import {
  stateFilingGroupsSelector,
  isFetchingFilingGroupsSelector,
  stateFilingGroupsByFilingTypeSelector,
} from './store/selectors';
import { useFetchFilingGroups } from './store/hooks';
import FilingGroupGrid from './filingGroupGrid.container';
import { filingGroupPropTypes } from './propTypes';

const FilingGroupDashboard = ({
  isFetchingFilingGroups,
  stateFilingGroups,
  stateFilingGroupsByFilingType,
  selectFilingGroupId,
  fetchAllJurisdictions,
  taxYear,
  filingTypeId,
  hasUserPermissionsToEdit,
}) => {
  useFetchFilingGroups();

  useEffect(() => {
    if (taxYear) {
      fetchAllJurisdictions({ taxYear });
    }
  }, [fetchAllJurisdictions, taxYear]);

  const [
    isShowFilingGroupsByFilingTypeChecked,
    setIsShowFilingGroupsByFilingTypeChecked,
  ] = useState(false);

  const toggleShowFilingGroupsByFilingType = useCallback(() => {
    setIsShowFilingGroupsByFilingTypeChecked(!isShowFilingGroupsByFilingTypeChecked);
  }, [isShowFilingGroupsByFilingTypeChecked]);

  const filingGroups = isShowFilingGroupsByFilingTypeChecked
    ? stateFilingGroupsByFilingType
    : stateFilingGroups;

  return (
    <FilingGroupGrid
      isFetchingFilingGroups={isFetchingFilingGroups}
      filingGroups={filingGroups}
      selectFilingGroupId={selectFilingGroupId}
      hasUserPermissionsToEdit={hasUserPermissionsToEdit}
      isShowFilingGroupsByFilingTypeChecked={isShowFilingGroupsByFilingTypeChecked}
      toggleShowFilingGroupsByFilingType={toggleShowFilingGroupsByFilingType}
      filingTypeId={filingTypeId}
    />
  );
};

FilingGroupDashboard.propTypes = {
  isFetchingFilingGroups: PropTypes.bool.isRequired,
  stateFilingGroups: PropTypes.arrayOf(filingGroupPropTypes),
  selectFilingGroupId: PropTypes.func.isRequired,
  fetchAllJurisdictions: PropTypes.func.isRequired,
  taxYear: PropTypes.string,
  hasUserPermissionsToEdit: PropTypes.bool,
  stateFilingGroupsByFilingType: PropTypes.arrayOf(filingGroupPropTypes),
  filingTypeId: PropTypes.oneOf(Object.values(filingTypes)),
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    stateFilingGroups: stateFilingGroupsSelector(state),
    stateFilingGroupsByFilingType: stateFilingGroupsByFilingTypeSelector(state),
    isFetchingFilingGroups: isFetchingFilingGroupsSelector(state),
    filingTypeId: filingTypeIdSelector(state),
  }),
  {
    selectFilingGroupId,
    fetchAllJurisdictions,
  },
)(FilingGroupDashboard);
