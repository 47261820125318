// TODO: SLT-6426 Merge functionality with client/src/shared/forms/filingAttributes/filingAttributes.js and remove this file

import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';

import Loading from '../../displayComponents/loading.component';
import SDKCustomSelect from '../sdkCustomSelect/formikSdkCustomSelect.component';
import Checkbox from '../checkboxFormik/checkboxFormik.component';
import Radio from '../radioFormik/radioFormik.component';
import styles from '../forms.module.scss';

export const CheckboxFieldWithTitle = props => (
  <div title={props.label}>
    <Field {...props} />
  </div>
);

CheckboxFieldWithTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

const FilingAttributes = ({
  exclusiveFilingAttributes = [],
  filingAttributes,
  defaultRadioButtonsValue,
  isLoading,
  compact = false,
  isColumnAligned = false,
  values,
}) => {
  const renderFilingAttributesGroup = useCallback(
    filingAttribute =>
      filingAttribute.children.map(item => (
        <CheckboxFieldWithTitle
          key={item.field}
          label={item.displayName}
          name={item.field}
          component={Checkbox}
          disabled={isLoading}
        />
      )),
    [isLoading],
  );

  const renderFilingAttributesSelect = useCallback(
    filingAttribute => (
      <Loading isLoading={isLoading} small>
        <SDKCustomSelect
          appkitLabel={filingAttribute.displayName}
          name={filingAttribute.columnGroup}
          options={filingAttribute.children.map(({ displayName: label, field: value }) => ({
            label,
            value,
          }))}
          value={values[filingAttribute.columnGroup]}
          virtualized
        />
      </Loading>
    ),
    [isLoading, values],
  );

  if (isColumnAligned) {
    return filingAttributes.map((filingAttribute, index) => (
      <div
        className={styles[`filingAttributesGroup${index + 1}`]}
        key={filingAttribute.displayName}
      >
        {filingAttribute.hasSingleValue ? (
          renderFilingAttributesSelect(filingAttribute)
        ) : (
          <>
            <label className="a-form-label">{filingAttribute.displayName}</label>
            <div className={styles.verticalCheckboxesGroup}>
              {renderFilingAttributesGroup(filingAttribute)}
            </div>
          </>
        )}
      </div>
    ));
  }

  return (
    <div className={styles.checkboxesGroupWithInlineLabel}>
      {exclusiveFilingAttributes.map(filingAttribute => (
        <Fragment key={filingAttribute.displayName}>
          <label className="a-form-label">{filingAttribute.displayName}</label>
          <div className={classNames(styles.checkboxesGroup, { [styles.compact]: compact })}>
            <Field
              name={filingAttribute.displayName}
              component={Radio}
              options={filingAttribute.children.map(el => ({
                label: el.displayName,
                value: el.field,
              }))}
              disabled={isLoading}
              className={styles.radioButtonsGroup}
              defaultValue={defaultRadioButtonsValue}
            />
          </div>
        </Fragment>
      ))}
      {filingAttributes.map(filingAttribute => (
        <Fragment key={filingAttribute.displayName}>
          {filingAttribute.hasSingleValue ? (
            renderFilingAttributesSelect(filingAttribute)
          ) : (
            <>
              <label className="a-form-label">{filingAttribute.displayName}</label>
              <div className={classNames(styles.checkboxesGroup, { [styles.compact]: compact })}>
                {renderFilingAttributesGroup(filingAttribute)}
              </div>
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export const filingAttributesProps = PropTypes.arrayOf(
  PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
);

FilingAttributes.propTypes = {
  defaultRadioButtonsValue: PropTypes.string,
  exclusiveFilingAttributes: filingAttributesProps,
  filingAttributes: filingAttributesProps,
  isLoading: PropTypes.bool.isRequired,
  compact: PropTypes.bool,
  isColumnAligned: PropTypes.bool,
  values: PropTypes.shape({}).isRequired,
};

export default FilingAttributes;
