import { createSelector } from 'reselect';
import { TOTAL_DESC } from '@common-packages/shared-constants';

const partnershipAmountDetailsSelector = ({ entity }) =>
  entity.partnershipFlowThroughAmounts.partnershipAmountDetails.partnershipAmountDetails;

export const isFetchingPartnershipAmountDetailsSelector = ({ entity }) =>
  entity.partnershipFlowThroughAmounts.partnershipAmountDetails.isFetchingPartnershipAmountDetails;

export const partnershipAmountDetailsWithTotalSelector = createSelector(
  [partnershipAmountDetailsSelector],
  partnershipAmountDetails => [
    ...partnershipAmountDetails,
    {
      orgId: TOTAL_DESC,
      isTotalRow: true,
      ...partnershipAmountDetails.reduce(
        (acc, next) => ({
          partnershipAmount: acc.partnershipAmount + next.partnershipAmount,
          flowthroughAmount: acc.flowthroughAmount + next.flowthroughAmount,
        }),
        {
          partnershipAmount: 0,
          flowthroughAmount: 0,
        },
      ),
    },
  ],
);
