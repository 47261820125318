const Yup = require('yup');
const { CalcPreferenceDataTypes } = require('@common-packages/shared-constants');

const getAddEditCalcPreferenceSchema = ({ calcPreferences = [], isEditMode = false }) =>
  Yup.object()
    .shape({
      name: Yup.string()
        .label('Calc Preference Name')
        .required()
        .isEmptyAfterTrim()
        .matches(/^[^']*$/, 'Calc Preference Name cannot include an apostrophe')
        .uniqueInList({
          message: 'Calc Preference Name already exists',
          list: calcPreferences,
          mapper: ({ name }) => name.toLowerCase().trim(),
          shouldSkipTest: isEditMode,
          checkDuplicatesFunction: (element, value) =>
            element === (value || '').toLowerCase().trim(),
        }),
      description: Yup.string().label('Calc Preference Description').required().isEmptyAfterTrim(),
      dataType: Yup.string()
        .oneOf(Object.values(CalcPreferenceDataTypes))
        .label('Data type')
        .required(),
    })
    .strict();

module.exports = {
  getAddEditCalcPreferenceSchema,
};
