import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

import { httpGetAndParse } from '../../utils/fetchService';
import stringify from '../../utils/stringify';
import {
  SelectionLists,
  SelectionListsByTaxYear,
  SelectionListDefaultValues,
  SelectionListId,
  FindDataUsingSelectionListResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

interface TaxYearParams {
  taxYear: string;
}

interface JurisdictionAndTaxYearParams extends TaxYearParams {
  jurisdictionId: string;
}

interface SelectionsListsParams extends JurisdictionAndTaxYearParams {
  shouldIncludeEverywhereJurisdiction: boolean;
}

export const useQuerySelectionLists = ({
  params,
  enabled,
}: {
  params: SelectionsListsParams;
  enabled: boolean;
}) =>
  useCustomQuery<SelectionLists>([QueryKeys.SelectionLists.SelectionLists, params], {
    url: `/api/development/selection-lists/${params.taxYear}/${params.jurisdictionId}?${stringify({
      shouldIncludeEverywhereJurisdiction: params.shouldIncludeEverywhereJurisdiction,
    })}`,
    defaultData: [],
    enabled,
    errorMessage: 'Error fetching selection lists',
  });

export const useQuerySelectionListsByTaxYear = ({
  params,
  enabled,
}: {
  params: TaxYearParams;
  enabled: boolean;
}) =>
  useCustomQuery<SelectionListsByTaxYear>(
    [QueryKeys.SelectionLists.SelectionListsByJurisdiction, params],
    {
      url: `/api/development/selection-lists/${params.taxYear}`,
      defaultData: [],
      enabled,
      errorMessage: 'Error fetching selection lists by jurisdiction',
    },
  );

export const useQuerySelectionListDefaultValues = ({
  params,
  enabled,
}: {
  params: TaxYearParams;
  enabled: boolean;
}) =>
  useCustomQuery<SelectionListDefaultValues>(
    [QueryKeys.SelectionLists.SelectionListsDefaultValues, params],
    {
      url: `/api/development/selection-lists/default-values/${params.taxYear}`,
      defaultData: [],
      enabled,
      errorMessage: 'Error fetching selection list items',
    },
  );

const fetchQueryDataUsingSelectionList = (id: SelectionListId) =>
  httpGetAndParse({
    route: `/api/development/selection-lists/data-using-selection-list/${id}`,
  });

export const useFetchQueryDataUsingSelectionList = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (id: SelectionListId) => {
      return queryClient.fetchQuery<FindDataUsingSelectionListResponse>(
        [QueryKeys.SelectionLists.DataUsingSelectionList, id],
        () => fetchQueryDataUsingSelectionList(id),
        {
          cacheTime: 0, // setting cache time to 0 to always get the newest data
        },
      );
    },
    [queryClient],
  );
};
