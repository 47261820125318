import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { isValid, isBefore, isAfter } from 'date-fns';
import Calendar from '@pwc/appkit-react-datepicker/lib/react-calendar';

import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';
import {
  emptyMask,
  emptyMaskAndKeys,
  makeMask,
  convertDateToArrayOfCharacters,
  isDigit,
} from '../../displayComponents/datePicker/datePicker.component';
import americanDateFormat from '../../americanDateFormat';

import { formatDate } from './utils';
import styles from './dateCellEditor.module.scss';

// eslint-disable-next-line no-empty-function
const noop = () => {};

class DateCellEditor extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    stopEditing: PropTypes.func.isRequired,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  };

  constructor(props) {
    super(props);
    this.state = {
      value: formatDate(props.value),
      keys: [],
      maskedInputValue: emptyMask,
      calendarValue: null,
      minDate: props?.minDate,
      maxDate: props?.maxDate,
    };
  }

  // Fix so selected date is shown instead of today's date, but causes grid to resize/"shake"
  componentDidMount() {
    this.inputRef.current.focus();
  }

  getValue = () => this.state.value;

  inputRef = createRef();
  calendarRef = createRef();

  afterGuiAttached = () => {
    this.inputRef.current.focus();
  };

  onDayClick = date => {
    this.setState({ value: americanDateFormat.toString(date) }, () => this.props.stopEditing());
  };

  onFocus = () => {
    const dateFromString = americanDateFormat.fromString(this.getValue());

    if (isValid(dateFromString)) {
      const keys = convertDateToArrayOfCharacters(this.getValue());
      const maskedInputValue = makeMask(keys);
      this.calendarRef.current.setActiveStartDate(dateFromString);
      this.setState({
        value: this.getValue(),
        keys,
        maskedInputValue,
        calendarValue: dateFromString,
      });

      return;
    }

    this.calendarRef.current.setActiveStartDate(new Date());
  };

  onKeyPress = ({ key }) => {
    const { keys } = this.state;

    if (keys.length === emptyMask.length - 2 || !isDigit(key)) {
      return;
    }

    const newKeys = [...keys, key];
    const maskedInputValue = makeMask(newKeys);
    const date = americanDateFormat.fromString(maskedInputValue);

    if (!isValid(date) || !this.isDateInRange(date)) {
      this.setState({ maskedInputValue, keys: newKeys });

      return;
    }

    this.calendarRef.current.setActiveStartDate(date);
    this.setState({
      calendarValue: date,
      maskedInputValue,
      value: maskedInputValue,
      keys: newKeys,
    });
  };

  onKeyDown = ({ key }) => {
    if (key !== 'Backspace') {
      return;
    }

    if (this.state.keys.length === 1) {
      this.setState({ ...emptyMaskAndKeys, calendarValue: null, value: null });
      return;
    }

    this.setState(prevState => {
      const keys = prevState.keys.slice(0, -1);
      const maskedInputValue = makeMask(keys);
      return {
        ...prevState,
        keys,
        maskedInputValue,
      };
    });
  };

  onClear = () => {
    this.inputRef.current.focus();
    this.setState({ ...emptyMaskAndKeys, calendarValue: null, value: null });
  };

  isDateInRange = date => {
    const { minDate, maxDate } = this.state;
    return (!minDate || !isBefore(date, minDate)) && (!maxDate || !isAfter(date, maxDate));
  };

  render() {
    const { maskedInputValue } = this.state;
    const inputValue = maskedInputValue === emptyMask ? '' : maskedInputValue.split('_')[0];
    const showClearIcon = Boolean(inputValue.length);
    const placeholder = americanDateFormat.toString(new Date());
    return (
      <div>
        <input
          className={styles.input}
          ref={this.inputRef}
          value={inputValue}
          placeholder={placeholder}
          onFocus={this.onFocus}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.onKeyDown}
          onChange={noop}
          data-testid="calendar-input"
        />
        {showClearIcon && (
          <AppkitIcon
            className={styles.clearIcon}
            icon="close"
            type="fill"
            size={8}
            onClick={this.onClear}
          />
        )}
        <Calendar
          value={this.state.calendarValue}
          ref={this.calendarRef}
          onChange={this.onDayClick}
          minDate={this.state.minDate}
          maxDate={this.state.maxDate}
        />
      </div>
    );
  }
}

export default DateCellEditor;
