import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

const hasPermissionToDeleteWatermarks = customerPermissions =>
  hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.DELETE_WATERMARK],
  });

export default hasPermissionToDeleteWatermarks;
