import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ListHierarchy from '../../shared/displayComponents/listHierarchy/listHierarchy';
import { SelectOptionPropTypes } from '../../shared/forms/propTypes';
import { taxYearSelector, jurisdictionIdSelector } from '../store/selectors';
import { listHierarchyItemsTypes } from '../../shared/displayComponents/listHierarchy/propTypes';
import { fetchDatasets, selectDataset } from '../../shared/store/dataModels/actions';
import {
  dataModelSelector,
  datasetsTreeSelector,
  isFetchingDatasetsSelector,
  datasetSelector,
} from '../../shared/store/dataModels/selectors';

import styles from './datasetsTree.module.scss';

const DatasetsTree = ({
  disabled,

  taxYear,
  jurisdictionId,

  fetchDatasets,
  isFetchingDatasets,
  datasetsTree,
  dataset,
  selectDataset,

  dataModel,
}) => {
  const dataModelValue = dataModel && dataModel.value;

  useEffect(() => {
    if (dataModelValue) {
      fetchDatasets({
        taxYear,
        jurisdictionId,
        dataModelId: dataModelValue,
      });
    }
  }, [dataModelValue, taxYear, jurisdictionId, fetchDatasets]);

  const onDatasetClick = useCallback(
    ({ id }) => {
      if (disabled) {
        return;
      }

      selectDataset(id);
    },
    [selectDataset, disabled],
  );

  return (
    <div
      className={classNames({
        [styles.disabled]: disabled,
      })}
    >
      <ListHierarchy
        isLoading={isFetchingDatasets}
        items={datasetsTree}
        selectedItemIds={dataset ? [dataset.id] : []}
        itemOnClick={onDatasetClick}
      />
    </div>
  );
};

DatasetsTree.propTypes = {
  disabled: PropTypes.bool,
  expandCollapseHierarchyListItem: PropTypes.func,
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,

  fetchDatasets: PropTypes.func.isRequired,
  isFetchingDatasets: PropTypes.bool.isRequired,
  datasetsTree: listHierarchyItemsTypes,
  dataset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    parentId: PropTypes.string,
    contextTypeId: PropTypes.number,
  }),
  collapsedHierarchyListItems: PropTypes.objectOf(PropTypes.bool),
  dataModel: SelectOptionPropTypes,
  selectDataset: PropTypes.func.isRequired,
};

DatasetsTree.defaultProps = {
  disabled: false,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    isFetchingDatasets: isFetchingDatasetsSelector(state),
    datasetsTree: datasetsTreeSelector(state),
    dataset: datasetSelector(state),
    dataModel: dataModelSelector(state),
  }),
  {
    fetchDatasets,
    selectDataset,
  },
)(DatasetsTree);
