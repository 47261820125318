import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import {
  uploadFileForGenericAccountDataUploadTypes,
  CLEAR_ACCOUNT_DATA_UPLOAD_RESULT,
} from './types';

const genericAccountDataUploadReducer = reducerFactory({
  initialState: {
    uploadedData: [],
    isFetchingUploadedData: false,

    triggeredJob: null,
    isUploadingFile: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: uploadFileForGenericAccountDataUploadTypes,
      payloadKey: 'triggeredJob',
      fetchingKey: 'isUploadingFile',
    }),
    [CLEAR_ACCOUNT_DATA_UPLOAD_RESULT]: state => ({
      ...state,
      triggeredJob: null,
    }),
  },
});

export default genericAccountDataUploadReducer;
