import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';

import { fetchFormListTypes, fetchFormFieldsTypes, updateFormFieldsTypes } from './types';

export const initialState = {
  formList: [],
  isFetchingFormList: false,

  formFields: [],
  isFetchingFormFields: false,

  isUpdatingFormFields: false,
};

const sharedReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchFormListTypes,
      payloadKey: 'formList',
      fetchingKey: 'isFetchingFormList',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFormFieldsTypes,
      payloadKey: 'formFields',
      fetchingKey: 'isFetchingFormFields',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateFormFieldsTypes,
      fetchingKey: 'isUpdatingFormFields',
    }),
  },
});

export default sharedReducer;
