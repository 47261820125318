import camelCase from 'lodash.camelcase';

import {
  defaultColumnDefinition,
  currencyColumnDefinition,
  percentageColumnDefinition,
} from '../../shared/columnDefinitions';
import { TextCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const TextRenderer = TextCellRendererFactory({
  getText: ({ valueFormatted }) => valueFormatted,
});

const nonPriorYearColumns = ['FEDERAL_ID_NUMBER', 'FIL_IND', 'FILING_GROUP_ID', 'LOCK_IND'];

const getColumnDefinitions = ({ fields, showPriorYear }) => {
  const fieldsWithPriorYear = showPriorYear
    ? fields.reduce((acc, curr) => {
        const priorField = nonPriorYearColumns.includes(curr.value)
          ? []
          : [
              {
                value: `PRIOR_${curr.value}`,
                label: `${curr.label}(PY)`,
              },
            ];
        return [...acc, curr, ...priorField];
      }, [])
    : fields;
  return [
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Id',
      field: 'orgid',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Entity Name',
      field: 'name',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdictionDescription',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Profile',
      field: 'profileId',
    },
    ...fieldsWithPriorYear.map(({ value: field, label: headerName }) => {
      switch (field) {
        case 'FIL_IND':
        case 'LOCK_IND':
          return {
            ...defaultColumnDefinition,
            headerName,
            field: camelCase(field),
            width: 100,
            cellStyle: {
              textAlign: 'center',
            },
          };
        case 'FEDERAL_ID_NUMBER':
          return {
            ...defaultColumnDefinition,
            headerName,
            field: camelCase(field),
            cellRenderer: row =>
              TextRenderer({
                ...row,
                valueFormatted: (row.value || '').replace(/(\d{2})(\d+)/, '$1-$2'),
              }),
          };
        case 'FILING_GROUP_ID':
          return {
            ...defaultColumnDefinition,
            headerName,
            field: camelCase(field),
          };
        case 'APPORTIONMENT_PCT':
        case 'FTAX_APPORTIONMENT_PCT':
        case 'PRIOR_APPORTIONMENT_PCT':
        case 'PRIOR_FTAX_APPORTIONMENT_PCT':
          return {
            ...defaultColumnDefinition,
            ...percentageColumnDefinition,
            headerName,
            field: camelCase(field),
          };
        case 'PAYROLL_WEIGHT':
        case 'SALES_WEIGHT':
        case 'PROPERTY_WEIGHT':
        case 'PRIOR_PAYROLL_WEIGHT':
        case 'PRIOR_SALES_WEIGHT':
        case 'PRIOR_PROPERTY_WEIGHT':
          return {
            ...defaultColumnDefinition,
            headerName,
            field: camelCase(field),
            cellStyle: {
              textAlign: 'right',
            },
          };
        default:
          return {
            ...defaultColumnDefinition,
            ...currencyColumnDefinition,
            headerName,
            field: camelCase(field),
          };
      }
    }),
  ];
};

export default getColumnDefinitions;
