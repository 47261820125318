import React, { useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { taxFormsV2Schemas } from '@common-packages/validators';

import Loading from '../../../../shared/displayComponents/loading.component';
import {
  taxFormSelector,
  isFetchingTaxFormSelector,
  isUpdatingTaxFormSelector,
  watermarkOptionsSelector,
} from '../../store/selectors';
import { isFetchingFormsSelector } from '../../../store/selectors';

import EditFormDetailsForm from './editFormDetailsForm.component';

const formName = 'formDetails';

const EditFormDetails = ({ setIsFormDirty }) => {
  const taxForm = useSelector(taxFormSelector);
  const isFetchingTaxForms = useSelector(isFetchingFormsSelector);
  const isFetchingTaxForm = useSelector(isFetchingTaxFormSelector);
  const isUpdatingTaxForm = useSelector(isUpdatingTaxFormSelector);
  const watermarks = useSelector(watermarkOptionsSelector);

  const formDetailsRef = useRef();
  const { formDetails, filingAttributes, formStatuses } = taxForm;
  const {
    setValues,
    values: { [formName]: updatedFormDetails },
  } = useFormikContext();

  useEffect(() => {
    if (formDetailsRef.current) {
      formDetailsRef.current.validateForm();
    }
  }, []);

  useEffect(() => {
    if (!updatedFormDetails && formDetailsRef.current) {
      formDetailsRef.current.resetForm();
    }
  }, [updatedFormDetails]);

  const handleChange = useCallback(
    (name, value) => {
      setIsFormDirty(true);
      setValues(prevValues => ({
        ...prevValues,
        [formName]: {
          ...taxForm[formName],
          ...prevValues[formName],
          [name]: value,
        },
      }));
    },
    [setIsFormDirty, setValues, taxForm],
  );

  const renderForm = useCallback(
    formikProps => (
      <EditFormDetailsForm
        {...formikProps}
        handleChange={handleChange}
        filingAttributes={filingAttributes}
        watermarks={watermarks}
        formStatuses={formStatuses}
      />
    ),
    [handleChange, filingAttributes, watermarks, formStatuses],
  );

  const isLoading = isFetchingTaxForms || isFetchingTaxForm || isUpdatingTaxForm;

  return (
    <Loading isLoading={isLoading}>
      <Formik
        innerRef={formDetailsRef}
        initialValues={updatedFormDetails || formDetails}
        validationSchema={taxFormsV2Schemas.editTaxFormDetailsSchema}
        enableReinitialize={!updatedFormDetails}
      >
        {renderForm}
      </Formik>
    </Loading>
  );
};

EditFormDetails.propTypes = {
  setIsFormDirty: PropTypes.func.isRequired,
};

export default EditFormDetails;
