import { FormikValues, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import { useEffect } from 'react';

export const useManualValidateForm = <TValue extends FormikValues>(schemas: unknown) => {
  const { values, setErrors } = useFormikContext<TValue>();

  useEffect(() => {
    const doValidation = async () => {
      try {
        await validateYupSchema(values, schemas);
        setErrors({});
      } catch (error) {
        setErrors(yupToFormErrors(error));
      }
    };
    doValidation();
  }, [setErrors, values, schemas]);
};
