import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import partition from 'lodash.partition';
import { ConsolidationMethodFilingTypes } from '@common-packages/shared-constants';
import { SelectOption } from '@tls/slt-types';
import { consolidationsSchemas } from '@common-packages/validators';

import {
  TaxReturnDefinitionWithFilingAttributes,
  FilingAttributeGroup,
  FilingAttributes as FilingAttributesType,
  CalcPreferencesFormikValues,
  CalcPreferenceDefinitions,
  DueDateOptionValuesEnum,
} from '../../../../common/types';
import FilingAttributes from '../../shared/forms/filingAttributes/filingAttributes.component';
import CalcPreferences from '../../shared/forms/calcPreferences/calcPreferences.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import LoadingSpinner from '../../shared/forms/loadingSpinner/loadingSpinner.component';
import Loading from '../../shared/displayComponents/loading.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Checkbox from '../../shared/forms/checkboxFormik/checkboxFormik.component';
import Radio from '../../shared/forms/radioFormik/radioFormik.component';
import {
  FilingMethods,
  DisplayedAttributes,
  TaxReturnStatus,
  TaxReturnEFileStatus,
} from '../../shared/enums';
import useSetFilingMethod from '../../shared/hooks/useSetFilingMethod.hook';
import { useQueryReturnStatus } from '../../shared/queries/taxReturns';
import { useQueryDueDateOptions } from '../../shared/queries/dueDates';
import { useShouldDisplayReturnsDueDates } from '../../shared/hooks/useShouldDisplayReturnsDueDates.hook';
import { useShouldDisplayReturnStatus } from '../../shared/hooks/useShouldDisplayReturnStatus.hook';
import { getDefaultDueDateType } from '../../shared/taxReturns/utils/getDefaultDueDateType';
import { useManualValidateForm } from '../../shared/hooks/useManualValidateForm';

import useDisabledFilingAttributes from './useDisabledFilingAttributes';
import { proformaRefreshOptions, RETURN_TYPE_FIELD } from './constants';
import styles from './consolidationForm.module.scss';

const noop = () => null;

const CALC_OPTIONS_KEY = 'calcOptions';

export interface FormValues extends FilingAttributesType {
  parentOrgId: string;
  description: string;
  federalFilingGroupId: string | null;
  proformaFilingGroupId: string | null;
  consolidationMethod: string;
  lockedInd: boolean;
  active: boolean;
  allowInterCompany: boolean;
  nonFiling: boolean;
  consolFormId: string;
  returnDefinitionId: string;
  filingMethod: FilingMethods;
  returnStatus: TaxReturnStatus | null;
  efileStatus: TaxReturnEFileStatus | null;
  dueDate: string | null;
  dueDateType: DueDateOptionValuesEnum | null;
  proformaRefresh: string;
  calcOptions: CalcPreferencesFormikValues;
  taxYear: string;
  period: string;
  consolidationId: string;
  jurisdictionId: string;
  [RETURN_TYPE_FIELD]: string | null;
}

interface EditConsolidationFormProps {
  filingAttributes: FilingAttributeGroup[];
  consolidationData: {
    selectOptions: Record<string, CalcPreferenceDefinitions>;
  };
  returnDefinitions: TaxReturnDefinitionWithFilingAttributes[];
  isSavingConsolidationAndFilingAttributes: boolean;
  onParentEntityChange: (entity?: SelectOption) => unknown;
  filingGroups: SelectOption[];
  isFetchingFilingGroups: boolean;
  entitiesOptions: SelectOption[];
  isFetchingEntities: boolean;
  hasTaxReturnsPermissions: boolean;
}

const EditConsolidationForm = ({
  filingAttributes,
  consolidationData,
  isSavingConsolidationAndFilingAttributes,
  returnDefinitions,
  onParentEntityChange = noop,
  filingGroups,
  isFetchingFilingGroups,
  isFetchingEntities,
  entitiesOptions,
  hasTaxReturnsPermissions,
}: EditConsolidationFormProps) => {
  const {
    isSubmitting,
    submitForm,
    resetForm,
    dirty,
    initialValues,
    values,
    setFieldValue,
    isValid,
    errors,
  } = useFormikContext<FormValues>();

  const isLoading = Boolean(
    isSubmitting || isSavingConsolidationAndFilingAttributes || isFetchingEntities,
  );

  const initialReturnTypeValue =
    initialValues &&
    Object.keys(initialValues).filter(
      key =>
        key.includes(DisplayedAttributes.RETURN_TYPE) &&
        initialValues[key as keyof FormValues] === true,
    )[0];

  const selectedReturnDefinition = useMemo(
    () => returnDefinitions.find(({ id }) => id === values.returnDefinitionId),
    [returnDefinitions, values.returnDefinitionId],
  );

  const shouldDisplayDueDate = useShouldDisplayReturnsDueDates();
  const shouldDisplayReturnStatus = useShouldDisplayReturnStatus();

  const {
    disabledFilingAttributes,
    setDisabledFilingAttributes,
    getNewDisabledFilingAttributes,
  } = useDisabledFilingAttributes({ returnDefinitions, filingAttributes });

  const { data: dueDateOption, isLoading: isLoadingDueDateOption } = useQueryDueDateOptions({
    params: {
      taxYear: values.taxYear,
      period: `${values.period}`,
      businessEntityId: values.consolidationId,
      jurisdictionId: values.jurisdictionId,
      returnDefinitionId: values.returnDefinitionId,
    },
    enabled: Boolean(
      shouldDisplayDueDate &&
        values.taxYear &&
        `${values.period}` &&
        values.consolidationId &&
        values.jurisdictionId &&
        values.returnDefinitionId,
    ),
  });

  const {
    isFilingMethodDisabled,
    filingMethodInfoMessage,
    filingMethodOptions,
  } = useSetFilingMethod({
    isEditing: true,
    taxReturnsDefinition: selectedReturnDefinition,
  });

  const { data: returnStatusOption } = useQueryReturnStatus({
    filingMethod: values.filingMethod,
    efileStatus: values.efileStatus,
    returnStatus: values.returnStatus,
  });

  useEffect(() => {
    if (!shouldDisplayDueDate || !dueDateOption?.length || values.nonFiling || values.dueDateType) {
      return;
    }

    setFieldValue('dueDateType', getDefaultDueDateType(dueDateOption));
  }, [setFieldValue, dueDateOption, shouldDisplayDueDate, values]);

  useManualValidateForm(
    consolidationsSchemas.updateConsolidationSchema({
      returnDefinitions,
      shouldDisplayDueDate,
      dueDateOption,
    }),
  );

  const isSaveButtonDisabled = !dirty || !isValid || isSavingConsolidationAndFilingAttributes;

  const onReturnDefinitionChange = useCallback(
    (_: string, newReturnDefinitionId: string | { label: string; value: string }) => {
      const newReturnDefinition = returnDefinitions.find(({ id }) => id === newReturnDefinitionId);
      if (newReturnDefinition) {
        const newDisabledFilingAttributes = getNewDisabledFilingAttributes(
          newReturnDefinition.filingAttributes,
        );

        setDisabledFilingAttributes(newDisabledFilingAttributes);
      }
    },
    [setDisabledFilingAttributes, getNewDisabledFilingAttributes, returnDefinitions],
  );

  const returnDefinitionsOptions = useMemo(
    () => returnDefinitions.map(({ id, name }) => ({ label: name, value: id })),
    [returnDefinitions],
  );

  const [returnTypeFilingAttributes, restFilingAttributes] = partition(filingAttributes, [
    'displayName',
    'Return Type',
  ]);

  const handleEntityChange = useCallback(
    (entity?: SelectOption) => {
      if (!entity) {
        return;
      }

      setFieldValue('parentOrgId', entity.value);

      onParentEntityChange(entity);
    },
    [setFieldValue, onParentEntityChange],
  );

  const renderFilingAttributes = useCallback(
    () => (
      <FilingAttributes
        filingAttributes={restFilingAttributes}
        exclusiveFilingAttributes={returnTypeFilingAttributes}
        defaultRadioButtonsValue={initialReturnTypeValue}
        disabledFilingAttributes={disabledFilingAttributes}
        isLoading={isLoading}
        errors={errors}
      />
    ),
    [
      restFilingAttributes,
      returnTypeFilingAttributes,
      initialReturnTypeValue,
      disabledFilingAttributes,
      errors,
      isLoading,
    ],
  );

  const renderCalcPreferences = useCallback(
    () => <CalcPreferences calcPreferences={consolidationData} calcOptionsKey={CALC_OPTIONS_KEY} />,
    [consolidationData],
  );

  const dueDateSelection = useMemo(
    () =>
      shouldDisplayDueDate &&
      !values.nonFiling && (
        <Select<DueDateOptionValuesEnum>
          appkitLabel="Set Due Date"
          appkitPlaceholder="Select a Date"
          name="dueDateType"
          options={dueDateOption || []}
          isLoading={isLoadingDueDateOption}
          useAppkit
          isAutoClearable
        />
      ),
    [dueDateOption, shouldDisplayDueDate, values.nonFiling, isLoadingDueDateOption],
  );

  const onCancelClick = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const consolidationMethodOptions = Object.keys(ConsolidationMethodFilingTypes).map(method => ({
    label: method,
    value: method,
  }));

  return (
    <form>
      <LoadingSpinner isLoading={isLoading} />
      <div className="row">
        <div className="col">
          <div className="form-text">
            <Select
              appkitLabel="Parent entity"
              name="parentOrgId"
              onChange={handleEntityChange}
              options={entitiesOptions}
              value={values.parentOrgId}
              virtualized
            />
            <Field className="form-text" label="Description" name="description" component={Input} />
            <div className={styles.formRow}>
              <Field
                className={`form-text ${styles.rightMargin}`}
                label="Federal As-Filed Filing Group"
                name="federalFilingGroupId"
                component={Input}
                disabled
              />
              <Loading small isLoading={isFetchingFilingGroups}>
                <Select
                  wrapperClassName={`form-text ${styles.rightMargin}`}
                  appkitLabel="Federal Pro Forma Filing Group"
                  name="proformaFilingGroupId"
                  options={filingGroups}
                  value={values.proformaFilingGroupId}
                  isClearable
                />
              </Loading>
              <Select
                wrapperClassName="form-text"
                appkitLabel="Consolidation method"
                name="consolidationMethod"
                options={consolidationMethodOptions}
                value={values.consolidationMethod}
              />
            </div>
            <Field
              wrapperClassName="form-text"
              label="Locked"
              name="lockedInd"
              component={Checkbox}
            />
            <Field wrapperClassName="form-text" label="Active" name="active" component={Checkbox} />
            <Field
              wrapperClassName="form-text"
              label="Allow Interco."
              name="allowInterCompany"
              component={Checkbox}
            />
            <Field
              wrapperClassName="form-text"
              label="Non Filing"
              name="nonFiling"
              component={Checkbox}
            />
            <Field
              wrapperClassName="form-text"
              label="Consol Form Id"
              name="consolFormId"
              component={Input}
              disabled
            />
            {hasTaxReturnsPermissions && (
              <Select<string>
                appkitLabel="Filing Form Description"
                name="returnDefinitionId"
                options={returnDefinitionsOptions}
                value={values.returnDefinitionId}
                customChangeHandler={onReturnDefinitionChange}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderFilingAttributes()}
          {hasTaxReturnsPermissions && (
            <Field
              label="Filing Method"
              name="filingMethod"
              component={Radio}
              options={filingMethodOptions}
              disabled={isFilingMethodDisabled}
              infoMessage={filingMethodInfoMessage}
            />
          )}
          {dueDateSelection}
          {shouldDisplayReturnStatus && !values.nonFiling ? (
            <Field
              appkitLabel="Return Status"
              name="returnStatus"
              as={Select}
              options={returnStatusOption}
              disabled={Boolean(values.efileStatus)}
              value={values.returnStatus}
            />
          ) : null}
          {renderCalcPreferences()}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Select
            appkitLabel="Proforma Refresh"
            name="proformaRefresh"
            value={values.proformaRefresh}
            options={proformaRefreshOptions}
            virtualized
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ButtonsGroup
            disabled={isSaveButtonDisabled}
            fullWidth={false}
            isSubmitting={isSubmitting}
            onCancelClick={onCancelClick}
            submitButtonLabel="Save"
            submitForm={submitForm}
          />
        </div>
      </div>
    </form>
  );
};

export default EditConsolidationForm;
