import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../../shared/editMode';
import {
  periodSelector,
  taxYearSelector,
  filingTypeIdSelector,
  consolidationIdSelector,
} from '../../../shared/store/selectors';
import {
  fetchGenericScreen,
  fetchGenericScreenHeader,
  updateGenericScreen,
} from '../../../shared/store/genericScreen/actions';
import {
  genericScreenSelector,
  lockIndicatorSelector,
  isFetchingGenericScreenSelector,
  isUpdatingGenericScreenSelector,
} from '../../../shared/store/genericScreen/selectors';
import NavigateBackLink from '../navigateBackLink.container';

import getColumnDefinitions from './genericScreen.columnDefinitions';

const getUniqueRowId = ({ data: { rowInfo } }) => rowInfo.rowId;

const GenericScreen = ({
  match,

  taxYear,
  period,
  orgId,
  jurisdictionId,
  isFetchingContext,

  filingTypeId,
  consolidationId,

  genericScreen,
  lockIndicator,
  fetchGenericScreen,
  updateGenericScreen,
  fetchGenericScreenHeader,
  isFetchingGenericScreen,
  isUpdatingGenericScreen,

  hasUserPermissionsToEdit,
}) => {
  const { url, params } = match;
  const { accountId } = params;
  const isContextReady = taxYear && period && orgId && jurisdictionId && accountId && filingTypeId;

  useEffect(() => {
    if (isContextReady) {
      fetchGenericScreenHeader({ taxYear, period, jurisdictionId, accountId });
      fetchGenericScreen({
        taxYear,
        period,
        orgId,
        jurisdictionId,
        accountId,
        filingTypeId,
        consolidationId,
      });
    }
  }, [
    fetchGenericScreenHeader,
    fetchGenericScreen,
    isContextReady,
    taxYear,
    period,
    orgId,
    jurisdictionId,
    accountId,
    filingTypeId,
    consolidationId,
  ]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        accountJurisdictionId: oldRow.accountJurisdictionId || jurisdictionId,
        begEndFlag: oldRow.begEndFlag || 'E',
        accountId: oldRow.accountId,
        value: newRow.adjustment,
      }));

      await updateGenericScreen({ orgId, period, taxYear, jurisdictionId, rowsToUpdate });
      fetchGenericScreen({
        taxYear,
        period,
        orgId,
        jurisdictionId,
        accountId,
        filingTypeId,
        consolidationId,
      });
    },
    [
      updateGenericScreen,
      fetchGenericScreen,
      orgId,
      period,
      taxYear,
      jurisdictionId,
      accountId,
      filingTypeId,
      consolidationId,
    ],
  );

  const isLoading = isFetchingContext || isFetchingGenericScreen || isUpdatingGenericScreen;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: genericScreen,
    getUniqueRowId,
    editButtonDisabled: lockIndicator || isLoading || !genericScreen.length,
    showLockIcon: lockIndicator,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        url,
        isInEditMode,
        updateRow,
      }),
    [url, isInEditMode, updateRow],
  );

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col-6">
          <NavigateBackLink />
        </div>
        {hasUserPermissionsToEdit && (
          <div className="col-6 add-button-column">{editModeButtons}</div>
        )}
      </div>

      <div className="grid-borderless row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            stopEditingWhenCellsLoseFocus
          />
        </div>
      </div>
    </>
  );
};

const rowInfoPropTypes = PropTypes.shape({
  rowId: PropTypes.number.isRequired,
  isDataRow: PropTypes.bool.isRequired,
  isTitleRow: PropTypes.bool.isRequired,
  isBlankRow: PropTypes.bool.isRequired,
  isBold: PropTypes.bool.isRequired,
  isBorder: PropTypes.bool.isRequired,
  isUnderlined: PropTypes.bool.isRequired,
  isDoubleUnderlined: PropTypes.bool.isRequired,
  isDrilldown: PropTypes.bool.isRequired,
  isWorkpaperInstanceDrilldown: PropTypes.bool.isRequired,
  workpaperInstanceDrilldownData: PropTypes.shape({
    parentDatasetDefinitionId: PropTypes.string.isRequired,
    datasetDefinitionId: PropTypes.string.isRequired,
    initialParentDatasetInstanceId: PropTypes.string.isRequired,
  }),
  isAdjustmentEditable: PropTypes.bool.isRequired,
}).isRequired;

const genericScreenPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    categoryId: PropTypes.string,
    accountId: PropTypes.string,
    accountText: PropTypes.string,
    rowType: PropTypes.string,
    textType: PropTypes.string,
    dataType: PropTypes.string,
    decimalPlaces: PropTypes.number,
    percentage: PropTypes.bool,
    commas: PropTypes.bool,
    showAdj: PropTypes.bool,
    borders: PropTypes.bool,
    drilldownType: PropTypes.string,
    accountJurisdictionId: PropTypes.string,
    jurisdictionId: PropTypes.string,
    multiplier: PropTypes.number,
    begEndFlag: PropTypes.string,
    displayOrder: PropTypes.number,
    hasGenericFunction: PropTypes.bool,
    dataCollection: PropTypes.number,
    adjustment: PropTypes.number,
    total: PropTypes.number,
    rowInfo: rowInfoPropTypes,
  }),
).isRequired;

GenericScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,

  taxYear: PropTypes.string,
  period: PropTypes.string,
  orgId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  filingTypeId: PropTypes.string,
  consolidationId: PropTypes.string,

  genericScreen: genericScreenPropTypes,
  lockIndicator: PropTypes.bool,
  fetchGenericScreen: PropTypes.func.isRequired,
  updateGenericScreen: PropTypes.func.isRequired,
  fetchGenericScreenHeader: PropTypes.func.isRequired,
  isFetchingGenericScreen: PropTypes.bool.isRequired,
  isUpdatingGenericScreen: PropTypes.bool.isRequired,
  isFetchingContext: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    genericScreen: genericScreenSelector(state),
    lockIndicator: lockIndicatorSelector(state),
    isFetchingGenericScreen: isFetchingGenericScreenSelector(state),
    isUpdatingGenericScreen: isUpdatingGenericScreenSelector(state),
    filingTypeId: filingTypeIdSelector(state),
    consolidationIdSelector: consolidationIdSelector(state),
  }),
  {
    fetchGenericScreen,
    updateGenericScreen,
    fetchGenericScreenHeader,
  },
)(GenericScreen);
