import { FederalReturnOption } from '../../../../../shared/enums/eFileV2';

export const federalReturnOptions = {
  SEPARATE: [
    {
      label: 'Separate entity federal return',
      sublabel: 'Federal ID: ',
      value: FederalReturnOption.FED_AS_FILED,
      disabled: false,
    },
    {
      label: 'As filed federal return for the consolidation',
      sublabel: 'Submission ID: ',
      value: FederalReturnOption.CONS_AS_FILED,
      disabled: false,
    },
    {
      label: 'Pro forma federal return for the consolidation',
      sublabel: 'Federal ID: ',
      value: FederalReturnOption.CONS_PROFORMA,
      disabled: false,
    },
    {
      label: 'Not including an XML federal return',
      sublabel: 'Include a PDF version of the Federal return if necessary',
      value: FederalReturnOption.FED_PDF,
      disabled: false,
    },
    {
      label: 'Manual Pro Forma Selection',
      sublabel: '',
      value: FederalReturnOption.FED_MANUAL_PROFORMA,
      disabled: false,
    },
  ],
  CONSOLIDATED: [
    {
      label: 'Consolidated as filed federal return',
      sublabel: 'Federal ID: ',
      value: FederalReturnOption.FED_AS_FILED,
      disabled: false,
    },
    {
      label: 'Consolidated pro forma federal return',
      sublabel: 'Federal ID: ',
      value: FederalReturnOption.FED_PROFORMA,
      disabled: false,
    },
    {
      label: 'Not including an XML federal return',
      sublabel: 'Include a PDF version of the Federal return if necessary',
      value: FederalReturnOption.FED_PDF,
      disabled: false,
    },
  ],
};

export const OPTIONS_WITH_FEDERAL_SUBMISSION_ID = [
  FederalReturnOption.FED_AS_FILED,
  FederalReturnOption.FED_PROFORMA,
  FederalReturnOption.CONS_AS_FILED,
  FederalReturnOption.CONS_PROFORMA,
  FederalReturnOption.FED_MANUAL_PROFORMA,
];
