import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useEventCallback, useEventListener } from 'usehooks-ts';

import { GlobalContext, NavigatorLocalStorageStatus } from '../../../../common/types';
import { IAgGridStateModel } from '../displayComponents/agGrid/types';
import {
  getClientLocalStorageItem,
  getLocalStorageItem,
  setClientLocalStorageItem,
  setLocalStorageItemByKey,
} from '../localStorage';

type LocalStorageState = IAgGridStateModel | GlobalContext | NavigatorLocalStorageStatus;

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useLocalStorage(
  key: string,
  initialValue: LocalStorageState,
  clientId?: string,
): [LocalStorageState, SetValue<LocalStorageState>] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = useCallback((): LocalStorageState => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      if (clientId) {
        return (
          getClientLocalStorageItem({
            clientId,
            clientDetailKey: key,
          }) ?? initialValue
        );
      } else {
        return (
          getLocalStorageItem({
            key,
          }) ?? initialValue
        );
      }
    } catch (error) {
      return initialValue;
    }
  }, [clientId, initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<LocalStorageState>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<LocalStorageState> = useEventCallback(value => {
    // Allow value to be a function so we have the same API as useState
    const newValue = value instanceof Function ? value(storedValue) : value;

    if (clientId) {
      setClientLocalStorageItem({
        clientId,
        clientDetailKey: key,
        data: newValue,
      });
    } else {
      setLocalStorageItemByKey({
        key,
        data: newValue as NavigatorLocalStorageStatus,
      });
    }

    // Save state
    setStoredValue(newValue);

    // We dispatch a custom event so every useLocalStorage hook are notified
    window.dispatchEvent(new Event('local-storage'));
  });

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue],
  );

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange);

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useLocalStorage()
  useEventListener('local-storage', handleStorageChange);

  return [storedValue, setValue];
}
