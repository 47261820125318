import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';

import PdfDisplay from '../shared/pdfDisplay/pdfDisplay.component';
import Loading from '../shared/displayComponents/loading.component';
import { infoNotification } from '../shared/notification/store/actions';
import {
  ActionMenu,
  ActionMenuItem,
} from '../shared/displayComponents/actionMenu/actionMenu.component';
import { consolidationIdSelector } from '../shared/store/selectors';
import { showConfirmModal } from '../shared/confirmModal/store/actions';
import { refreshFederalProFormaData, refreshGtwData } from '../shared/store/federalRefresh/actions';
import globalContextPropTypes from '../shared/propTypes/globalContext';
import { useQueryDownloadFederalPdf } from '../shared/queries/federalProforma';

import { fetchFederalProforma, checkLastRefreshProFormaReturn } from './store/actions';
import {
  pdfImageSelector,
  isFetchingPdfImageSelector,
  lastRefreshProFormaReturnSelector,
} from './store/selectors';

const noop = () => null;

const FederalProforma = ({
  globalContext,

  fetchFederalProforma,
  checkLastRefreshProFormaReturn,
  refreshFederalProFormaData,
  refreshGtwData,
  showConfirmModal,
  pdfImage,
  isFetchingPdfImage,
  lastRefreshProFormaReturn,

  infoNotification,

  hasUserPermissionsToEdit,
  sourceId = 'ALL',
}) => {
  const { params } = globalContext;
  const { taxYear, period, filingTypeId, businessEntityId, jurisdictionId } = params;

  const { refetch: downloadReturnPdf } = useQueryDownloadFederalPdf({
    params: {
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
      sourceId,
    },
    enabled: false,
  });

  const downloadPdf = useCallback(async () => {
    const response = await downloadReturnPdf();
    if (!response.data) {
      return;
    }
    const data = new Blob([response.data]);
    saveAs(
      data,
      `${businessEntityId} - Federal ${sourceId === 'GTW' ? 'As-Filed' : 'Pro Forma'}
      Return.pdf`,
    );
  }, [downloadReturnPdf, businessEntityId, sourceId]);

  useEffect(() => {
    fetchFederalProforma({
      taxYear,
      period,
      jurisdictionId,
      filingTypeId,
      orgId: businessEntityId,
      sourceId,
    });
  }, [
    fetchFederalProforma,
    taxYear,
    period,
    jurisdictionId,
    filingTypeId,
    businessEntityId,
    sourceId,
  ]);

  useEffect(() => {
    checkLastRefreshProFormaReturn({
      taxYear,
      period,
      businessEntityId,
    });
  }, [checkLastRefreshProFormaReturn, taxYear, period, businessEntityId]);

  const onRefreshFederalProFormaDataClick = useCallback(() => {
    showConfirmModal({
      title: 'Perform calculation',
      text: 'Perform refresh Pro-Forma Federal Return?',
      confirmCallback: () => refreshGtwData(globalContext),
    });
  }, [refreshGtwData, showConfirmModal, globalContext]);

  const onRefreshGtwDataClick = useCallback(() => {
    showConfirmModal({
      title: 'Refresh Federal Data',
      text: 'Do you want to refresh federal data?',
      confirmCallback: () =>
        refreshFederalProFormaData({
          taxYear,
          period,
          orgId: businessEntityId,
          jurisdictionId,
        }),
    });
  }, [
    refreshFederalProFormaData,
    showConfirmModal,
    taxYear,
    period,
    businessEntityId,
    jurisdictionId,
  ]);

  const actionMenuWithPermissions = (
    <div>
      <div className="float-right">
        <Loading isLoading={!globalContext.isReady} small>
          <ActionMenu>
            <ActionMenuItem
              text={
                lastRefreshProFormaReturn.lastRefreshDate
                  ? `Refresh Pro-Forma Federal Return (Last refreshed on ${lastRefreshProFormaReturn.lastRefreshDate.trim()})`
                  : 'Refresh Pro-Forma Federal Return'
              }
              onClick={onRefreshFederalProFormaDataClick}
            />
            <ActionMenuItem text="Refresh Federal Data" onClick={onRefreshGtwDataClick} />
          </ActionMenu>
        </Loading>
      </div>
    </div>
  );

  return (
    <>
      {hasUserPermissionsToEdit && actionMenuWithPermissions}
      <div className="row grid-row">
        <div className="col">
          <div className="form-wrapper">
            <PdfDisplay
              pdfImage={pdfImage}
              isFetchingPdfImage={isFetchingPdfImage}
              onDownload={downloadPdf}
              infoNotification={infoNotification}
              closePdfViewer={noop} // WARNING: HACKY WAY TO FIX APP CRASHING WHEN THERE'S NO PDF FILE TO DISPLAY
              noPdfMessage={
                {
                  GTW:
                    'The federal as-filed return was not found. Confirm that a Federal As-Filed Filing Group Id was entered for the consolidation.',
                  TTI:
                    'The federal pro forma return was not found. Confirm that a Federal Pro Forma Filing Group Id was entered for the consolidation.',
                }[sourceId]
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

FederalProforma.propTypes = {
  globalContext: globalContextPropTypes,

  pdfImage: PropTypes.object, // eslint-disable-line
  isFetchingPdfImage: PropTypes.bool.isRequired,
  fetchFederalProforma: PropTypes.func.isRequired,
  checkLastRefreshProFormaReturn: PropTypes.func.isRequired,
  refreshFederalProFormaData: PropTypes.func.isRequired,
  refreshGtwData: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  lastRefreshProFormaReturn: PropTypes.shape({
    lastRefreshDate: PropTypes.string,
  }).isRequired,

  infoNotification: PropTypes.func.isRequired,

  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
  sourceId: PropTypes.oneOf(['GTW', 'TTI', 'ALL']),
};

export default connect(
  state => ({
    isFetchingPdfImage: isFetchingPdfImageSelector(state),
    consolidationId: consolidationIdSelector(state),
    pdfImage: pdfImageSelector(state),
    lastRefreshProFormaReturn: lastRefreshProFormaReturnSelector(state),
  }),
  {
    fetchFederalProforma,
    checkLastRefreshProFormaReturn,
    refreshFederalProFormaData,
    refreshGtwData,
    showConfirmModal,
    infoNotification,
  },
)(FederalProforma);
