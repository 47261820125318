import React, { useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../../shared/editMode';
import { errorNotification } from '../../../shared/notification/store/actions';
import { entityIdSelector, taxYearSelector, periodSelector } from '../../../shared/store/selectors';
import { fetchFilingStates } from '../../../filingStates/store/actions';
import { fetchEntityFilingDecisions, updateEntityFilingDecisions } from '../../store/actions';
import {
  entityFilingDecisionsSelector,
  isFetchingEntityFilingDecisionsSelector,
  isUpdatingEntityFilingDecisionsSelector,
} from '../../store/selectors';

import getColumnDefinitions from './filingDecisions.columnDefinitions';
import { FilingDecisionsPropTypes } from './propTypes';

const getRowId = params => params?.data?.jurisdictionId;

const FilingDecisions = ({
  taxYear,
  period,
  entityId,
  isFetchingFilingDecisions,
  filingDecisions,
  fetchEntityFilingDecisions,
  updateEntityFilingDecisions,
  isUpdatingFilingDecisions,
  fetchFilingStates,
  showErrorNotification,

  hasUserPermissionsToEdit,
}) => {
  const isContextReady = taxYear && period && entityId;

  useEffect(() => {
    if (isContextReady) {
      fetchEntityFilingDecisions({ taxYear, period, entityId });
    }
  }, [fetchEntityFilingDecisions, isContextReady, taxYear, period, entityId]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        jurisdictionId: newRow.jurisdictionId,
        oldProfileId: oldRow.profileId,
        newProfileId: newRow.profileId,
        oldFinalFilingGroupId: oldRow.filingGroup,
        newFinalFilingGroupId: newRow.filingGroup,
        incomeTaxYn: newRow.incomeTax,
        franchiseTaxYn: newRow.franchiseTax,
        nexusIndicatorYn: newRow.nexusInd,
        federalFilingGroupId: newRow.federalFilingGroupId,
        proformaFilingGroupId: newRow.proformaFilingGroupId,
      }));

      await updateEntityFilingDecisions({
        taxYear,
        period,
        orgId: entityId,
        rowsToUpdate,
      });
      fetchEntityFilingDecisions({ taxYear, period, entityId });
      fetchFilingStates({ taxYear, period, entityId });
    },
    [
      updateEntityFilingDecisions,
      fetchEntityFilingDecisions,
      fetchFilingStates,
      taxYear,
      period,
      entityId,
    ],
  );

  const isLoading = isFetchingFilingDecisions || isUpdatingFilingDecisions;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: filingDecisions,
    getUniqueRowId: getRowId,
    editButtonDisabled: isLoading || !filingDecisions.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        updateRow,
        showErrorNotification,
        taxYear,
        period,
      }),
    [isInEditMode, updateRow, showErrorNotification, taxYear, period],
  );

  return (
    <>
      {navigationPrompt}
      <div className="row">
        <div className="col add-button-column">
          {hasUserPermissionsToEdit ? editModeButtons : null}
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            rowData={clonedRowData}
            withSearchBar
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            getRowId={getRowId}
            stopEditingWhenCellsLoseFocus
          />
        </div>
      </div>
    </>
  );
};

FilingDecisions.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  filingDecisions: FilingDecisionsPropTypes.isRequired,
  isFetchingFilingDecisions: PropTypes.bool.isRequired,
  isUpdatingFilingDecisions: PropTypes.bool.isRequired,
  fetchEntityFilingDecisions: PropTypes.func.isRequired,
  updateEntityFilingDecisions: PropTypes.func.isRequired,
  fetchFilingStates: PropTypes.func.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    isFetchingFilingDecisions: isFetchingEntityFilingDecisionsSelector(state),
    filingDecisions: entityFilingDecisionsSelector(state),
    isUpdatingFilingDecisions: isUpdatingEntityFilingDecisionsSelector(state),
  }),
  {
    fetchEntityFilingDecisions,
    updateEntityFilingDecisions,
    fetchFilingStates,
    showErrorNotification: errorNotification,
  },
)(FilingDecisions);
