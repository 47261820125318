import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from '@pwc/appkit-react/lib/Radio';
import { Tooltip } from '@pwc/appkit-react';

import FaIcon from '../../displayComponents/faIcon/faIcon.component';

import styles from './radioFormik.module.scss';

const RadioFormik = ({
  field: { value, name },
  form: { setFieldValue, errors },
  className = '',
  infoMessage = '',
  label,
  options,
  defaultValue,
  labelIconToolTipContent,
  disabled = false,
  customChangeHandler = () => {}, // eslint-disable-line no-empty-function
}) => {
  const errorMessage = errors[name];

  const handleToggle = useCallback(
    async (event, value) => {
      await setFieldValue(name, value);
      customChangeHandler(name, value);
    },
    [customChangeHandler, setFieldValue, name],
  );

  return (
    <fieldset>
      <legend className={styles.radioTitle}>
        {label}
        {labelIconToolTipContent ? (
          <Tooltip content={labelIconToolTipContent} placement="top" mouseEnterDelay={500}>
            <FaIcon className="fa-fw" icon="info-circle" color="#d04a02" size={14} />
          </Tooltip>
        ) : null}
      </legend>
      <RadioGroup
        className={className}
        value={value}
        defaultValue={defaultValue}
        onChange={handleToggle}
      >
        {options.map((option, index) => (
          <Radio key={index} disabled={disabled || option.disabled} value={option.value}>
            {option.label}
            {option.iconTitle && (
              <FaIcon
                className="fa-fw"
                icon="info-circle"
                color="#d04a02"
                size={14}
                title={option.iconTitle}
              />
            )}
          </Radio>
        ))}
      </RadioGroup>
      {Boolean(errorMessage) && <p className="a-form-error">{errorMessage}</p>}
      {Boolean(infoMessage) && <p className="a-form-error">{infoMessage}</p>}
    </fieldset>
  );
};

RadioFormik.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  infoMessage: PropTypes.string,
  label: PropTypes.string,
  labelIconToolTipContent: PropTypes.string,
  disabled: PropTypes.bool,
  customChangeHandler: PropTypes.func,
};

export default RadioFormik;
