import { connect } from 'react-redux';

import PeriodDropdown from '../shared/displayComponents/contextDropdown/periodDropdown.component';

import { selectPeriod } from './store/actions';
import {
  periodOptionsSelector,
  periodSelector,
  isFetchingTaxYearsDataSelector,
  isFetchingPeriodsSelector,
} from './store/selectors';

export default connect(
  state => ({
    periods: periodOptionsSelector(state),
    period: periodSelector(state),
    isDropdownBusy: isFetchingTaxYearsDataSelector(state) || isFetchingPeriodsSelector(state),
  }),
  {
    selectPeriod,
  },
)(PeriodDropdown);
