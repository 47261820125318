import PropTypes from 'prop-types';

export const listHierarchyItemsTypes = PropTypes.arrayOf(
  PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      contextTypeId: PropTypes.number,
    }),
    children: PropTypes.array,
  }),
).isRequired;
