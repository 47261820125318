const dataTypes = {
  CURRENCY: 1,
  RATIO: 2,
  INTEGER: 3,
  DATE: 4,
  BOOLEAN: 5,
  STRING: 6,
  OVERPAYMENT_OPTION: 666, // SLT-2021 Temporary solution until more OPTION types revealed
  FIRST_INSTALLMENT_OPTION: 667,
};

const dataKeys = {
  [dataTypes.CURRENCY]: 'numericValue',
  [dataTypes.RATIO]: 'numericValue',
  [dataTypes.INTEGER]: 'numericValue',
  [dataTypes.DATE]: 'textValue',
  [dataTypes.BOOLEAN]: 'booleanValue',
  [dataTypes.STRING]: 'textValue',
};

const dataTypesToDisplayDataValues = {
  [dataTypes.CURRENCY]: 'AMOUNT',
  [dataTypes.RATIO]: 'NUMBER',
  [dataTypes.INTEGER]: 'NUMBER',
  [dataTypes.DATE]: 'DATE',
  [dataTypes.BOOLEAN]: 'CHECKBOX',
  [dataTypes.STRING]: 'TEXT',
};

const CalcPreferenceDataTypes = {
  SELECTION_LIST: 'SELECTION_LIST',
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
};

module.exports = {
  dataTypes,
  dataKeys,
  CalcPreferenceDataTypes,
  dataTypesToDisplayDataValues,
};
