import React, { useCallback } from 'react';

import styles from './contextMenu.module.scss';

interface ClickPosition {
  x: number;
  y: number;
}

interface ContextMenuProps {
  clickPosition: ClickPosition;
  currentItemValue: string;
  selectDataItem: (value: string) => null;
  addSepExpression: (value: string) => null;
  addConsolExpression: (value: string) => null;
  isUsingSameExpression: boolean;
}

const getPopupStyles = ({ x, y }: ClickPosition) => ({
  top: `${y}px`,
  left: `${x}px`,
});

const ContextMenu = ({
  clickPosition,
  currentItemValue,
  selectDataItem,
  addSepExpression,
  addConsolExpression,
  isUsingSameExpression,
}: ContextMenuProps) => {
  const handleAddSepExpression = useCallback(() => addSepExpression(currentItemValue), [
    addSepExpression,
    currentItemValue,
  ]);
  const handleAddConsolExpression = useCallback(() => addConsolExpression(currentItemValue), [
    addConsolExpression,
    currentItemValue,
  ]);
  const handleSelectDataItem = useCallback(() => selectDataItem(currentItemValue), [
    selectDataItem,
    currentItemValue,
  ]);

  return (
    <div className={styles.popupWrapper} style={getPopupStyles(clickPosition)}>
      <ul>
        <li onClick={handleAddSepExpression}>
          <span>Add to Separate Expression</span>
        </li>
        {isUsingSameExpression ? (
          <li className={styles.itemDisabled}>
            <span>Add to Consolidation Expression</span>
          </li>
        ) : (
          <li onClick={handleAddConsolExpression}>
            <span>Add to Consolidation Expression</span>
          </li>
        )}
        <li onClick={handleSelectDataItem}>
          <span>Open DataItem</span>
        </li>
      </ul>
    </div>
  );
};

export default ContextMenu;
