import {
  defaultColumnDefinition,
  disabledReadOnlyCheckboxColumnDefinitions,
} from '../../../../shared/columnDefinitions';

const getColumnDefinitions = ({ updateRow }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  return [
    {
      ...defaultColumnDefinition,
      headerName: 'Data Item Name',
      field: 'dataItemName',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Description',
      field: 'description',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Display Name',
      field: 'displayName',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Item Display Order',
      field: 'itemDisplayOrder',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Col. Display Order',
      field: 'columnDisplayOrder',
    },
    {
      ...defaultColumnDefinition,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Screen Display',
      field: 'screenDisplay',
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinition,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Print Display',
      field: 'printDisplay',
    },
    {
      ...defaultColumnDefinition,
      ...disabledReadOnlyCheckboxColumnDefinitions,
      headerName: 'Print Column Total',
      field: 'printColumnTotal',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Relative Width',
      field: 'relativeWidth',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Font Size',
      field: 'fontSize',
    },
  ];
};

export default getColumnDefinitions;
