import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';

import Loading from '../../shared/displayComponents/loading.component';
import Dropzone from '../../shared/forms/dropzone/dropzone.container';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { globalContextSelector } from '../../shared/store/selectors';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import styles from '../shared/dataImportPage.module.scss';
import { useMutationGtwUploadFile } from '../../shared/mutations/gtwFileUpload';
import { FileType, SourceId } from '../../shared/enums/gtwFileUpload';

import FileFormatInstructions from './fileFormat/fileFormatInstructions.component';
import { fileSourceMatchVerifier } from './fileFormat/utils';
import gtwFileUpload from './gtwFileUpload.module.scss';

const SOURCE_OPTIONS = [
  { value: SourceId.GTW, label: SourceId.GTW },
  { value: SourceId.TTI, label: SourceId.TTI },
];

const FILE_TYPE_OPTIONS = Object.values(FileType).map(type => ({ value: type, label: type }));

const DOCUMENT_ACCEPTED_TYPES = {
  [FileType.XML]: ['.xml'],
  [FileType.PDF]: ['.pdf'],
  [FileType.ZIP]: ['.zip'],
};

const GtwFileUpload = ({ globalContext }) => {
  const [file, setFile] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [sourceOption, setSourceOption] = useState(SOURCE_OPTIONS[0].value);
  const [fileTypeOption, setFileTypeOption] = useState(FILE_TYPE_OPTIONS[0].value);

  const { isReady: isContextReady } = globalContext;

  const [fileFormatCheckResults, setFileFormatCheckResults] = useState(null);

  const { mutateAsync: uploadFile, isLoading: isUploadingFile } = useMutationGtwUploadFile();

  const fileSourceMatchVerifierCallBack = useCallback(
    () =>
      fileSourceMatchVerifier({
        fileName: file?.name || null,
        sourceOption,
      }),
    [file?.name, sourceOption],
  );

  const onDrop = useCallback(file => {
    setFileFormatCheckResults(null);
    setFile(file);
    setIsFileLoading(true);
  }, []);

  const onFileBufferLoaded = useCallback(() => setIsFileLoading(false), []);

  const handleSourceOptionsChange = useCallback(
    ({ value }) => {
      setSourceOption(value);
    },
    [setSourceOption],
  );

  const handleFileTypeOptionsChange = useCallback(
    ({ value }) => {
      setFile(null);
      setFileTypeOption(value);
    },
    [setFileTypeOption],
  );

  const uploadGtwFile = useCallback(async () => {
    try {
      await uploadFile({ file, globalContext, sourceOption, fileTypeOption });
    } catch (error) {
      if (error?.details?.fileFormatCheckResults) {
        setFileFormatCheckResults(error?.details?.fileFormatCheckResults);
        return;
      }
      setFileFormatCheckResults(null);
    }

    setFile(null);
  }, [uploadFile, file, globalContext, sourceOption, fileTypeOption]);

  const isLoading = !isContextReady || isUploadingFile || isFileLoading;

  return (
    <div className={`${styles.uploadFormSection}`}>
      <div className={`row ${styles.uploadFormDropdown}`}>
        <div className="col">
          <SDKCustomSelect
            appkitLabel="Source"
            className="sdk-custom-select"
            options={SOURCE_OPTIONS}
            value={sourceOption}
            onChange={handleSourceOptionsChange}
            virtualized
            disabled={isLoading}
          />
        </div>
        <div className="col">
          <SDKCustomSelect
            appkitLabel="File Type"
            className="sdk-custom-select"
            options={FILE_TYPE_OPTIONS}
            value={fileTypeOption}
            onChange={handleFileTypeOptionsChange}
            virtualized
            disabled={isLoading}
          />
        </div>
      </div>
      <span className={gtwFileUpload.fileFormatTextIncorrect}>
        {fileSourceMatchVerifierCallBack()
          ? null
          : 'Mismatch between source selection and file name found. Make sure you use the correct file with the correct source.'}
      </span>
      <div className="row">
        <div className="col">
          <Dropzone
            className={styles.dropzone}
            value={file}
            onDropCallback={onDrop}
            onFileBufferLoaded={onFileBufferLoaded}
            acceptTypes={DOCUMENT_ACCEPTED_TYPES[fileTypeOption]}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <Button size="lg" disabled={isLoading || !file || !file.size} onClick={uploadGtwFile}>
            Upload
          </Button>
        </div>
      </div>
      {Boolean(isLoading) && (
        <div className={styles.loadingOverlay}>
          <Loading isLoading />
        </div>
      )}
      <div className={gtwFileUpload.main}>
        <FileFormatInstructions
          sourceOption={sourceOption}
          fileFormatCheckResults={fileFormatCheckResults}
        />
      </div>
    </div>
  );
};

GtwFileUpload.propTypes = {
  globalContext: globalContextPropTypes,
};

export default connect(
  state => ({
    globalContext: globalContextSelector(state),
  }),
  null,
)(GtwFileUpload);
