import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createDataProvider } from '../../../shared/dataProviders';
import { entityIdSelector, taxYearSelector, periodSelector } from '../../../shared/store/selectors';
import { fetchFederalReconciliations } from '../store/actions';

const FederalReconciliationsDataProvider = ({
  children,

  taxYear,
  period,
  entityId,
  fetchFederalReconciliations,
}) => {
  useEffect(() => {
    const isContextReady = taxYear && period && entityId;

    if (isContextReady) {
      fetchFederalReconciliations({
        taxYear,
        period,
        entityId,
      });
    }
  }, [taxYear, period, entityId, fetchFederalReconciliations]);

  return children;
};
FederalReconciliationsDataProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,

  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  fetchFederalReconciliations: PropTypes.func.isRequired,
};

export default createDataProvider({
  ProviderComponent: connect(
    state => ({
      taxYear: taxYearSelector(state),
      period: periodSelector(state),
      entityId: entityIdSelector(state),
    }),
    {
      fetchFederalReconciliations,
    },
  )(FederalReconciliationsDataProvider),
});
