import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@pwc/appkit-react';

import DataInfo from '../../../shared/displayComponents/dataInfo/dataInfo.component';
import ComingSoonPage from '../../../comingSoonPage/comingSoonPage.component';
import { SelectOptionPropTypes } from '../../../shared/forms/propTypes';
import { FormDetailsTabsTypes, FORM_DETAILS_TABS_DEFINITION } from '../constants';
import { isPdfProcessedSelector } from '../store/selectors';
import {
  assignedDatasetsTreePropTypes,
  availableDatasetsTreePropTypes,
  pdfPageDataUsagePropTypes,
  availableDatasetPropTypes,
} from '../propTypes';
import styles from '../styles.module.scss';

import Form from './form/editFormDetails.container';
import Dataset from './dataset.container';
import Fields from './fields/fields.container';
import OverflowActions from './overflowActions/overflowActions.container';

const FormDetails = ({
  formId,
  setIsFormDirty,
  selectedFormFieldId,
  handleFormFieldChange,
  datasetsAssignedToFormPage,
  selectedFormDetailsTab,
  selectFormDetailTab,
  availableDatasetsTree,
  assignedDatasetsTree,
  handleDatasetUnassign,
  handleDatasetAssign,
  selectedAvailableDatasets,
  selectedAssignedDatasets,
  handleSelectedAvailableDatasets,
  handleSelectedAssignedDatasets,
  pdfPageDataUsage = [],
  ...props
}) => {
  const isPdfProcessed = useSelector(isPdfProcessedSelector);

  const handleTabChange = useCallback(
    (_, tab) => {
      selectFormDetailTab(tab);
    },
    [selectFormDetailTab],
  );

  const getActiveTabComponent = () => {
    switch (selectedFormDetailsTab) {
      case FormDetailsTabsTypes.FORM:
        return <Form setIsFormDirty={setIsFormDirty} />;
      case FormDetailsTabsTypes.DATA_SET:
        return (
          <Dataset
            assignedDatasetsTree={assignedDatasetsTree}
            availableDatasetsTree={availableDatasetsTree}
            handleDatasetUnassign={handleDatasetUnassign}
            handleDatasetAssign={handleDatasetAssign}
            selectedAvailableDatasets={selectedAvailableDatasets}
            selectedAssignedDatasets={selectedAssignedDatasets}
            handleSelectedAvailableDatasets={handleSelectedAvailableDatasets}
            handleSelectedAssignedDatasets={handleSelectedAssignedDatasets}
            {...props}
          />
        );
      case FormDetailsTabsTypes.OVERFLOW_ACTIONS:
        return (
          <OverflowActions
            pdfPageDataUsage={pdfPageDataUsage}
            assignedDatasetsTree={assignedDatasetsTree}
            setIsFormDirty={setIsFormDirty}
          />
        );
      case FormDetailsTabsTypes.FIELDS:
        return (
          <Fields
            key={selectedFormFieldId}
            setIsFormDirty={setIsFormDirty}
            selectedFormFieldId={selectedFormFieldId}
            handleFormFieldChange={handleFormFieldChange}
            datasetsAssignedToFormPage={datasetsAssignedToFormPage}
          />
        );
      case FormDetailsTabsTypes.E_FILE:
        return <ComingSoonPage />;
      case FormDetailsTabsTypes.TAB_2D:
        return <ComingSoonPage />;
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <>
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs
            className="tabs-container"
            value={selectedFormDetailsTab}
            onChange={handleTabChange}
            size="md"
          >
            {FORM_DETAILS_TABS_DEFINITION.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className={styles.formDetailsWrapper}>
        <div className={styles.formDetailsView}>
          {isPdfProcessed ? (
            formId && getActiveTabComponent()
          ) : (
            <DataInfo showSpinner showIcon={false} textToRender="PDF is getting processed" />
          )}
        </div>
      </div>
    </>
  );
};

FormDetails.propTypes = {
  formId: PropTypes.string,
  setIsFormDirty: PropTypes.func.isRequired,
  selectedFormFieldId: PropTypes.string,
  handleFormFieldChange: PropTypes.func.isRequired,
  datasetsAssignedToFormPage: PropTypes.arrayOf(SelectOptionPropTypes),
  selectedFormDetailsTab: PropTypes.string.isRequired,
  selectFormDetailTab: PropTypes.func.isRequired,
  availableDatasetsTree: availableDatasetsTreePropTypes.isRequired,
  assignedDatasetsTree: assignedDatasetsTreePropTypes.isRequired,
  handleDatasetUnassign: PropTypes.func.isRequired,
  handleDatasetAssign: PropTypes.func.isRequired,
  selectedAvailableDatasets: PropTypes.arrayOf(availableDatasetPropTypes),
  selectedAssignedDatasets: PropTypes.arrayOf(pdfPageDataUsagePropTypes),
  handleSelectedAvailableDatasets: PropTypes.func.isRequired,
  handleSelectedAssignedDatasets: PropTypes.func.isRequired,
  assignedDatasets: PropTypes.arrayOf(pdfPageDataUsagePropTypes).isRequired,
  pdfPageDataUsage: PropTypes.arrayOf(pdfPageDataUsagePropTypes),
};

export default FormDetails;
