const { FormDataActions } = require('@common-packages/shared-constants');
const Yup = require('yup');

const formDataUpdateSchema = Yup.array().of(
  Yup.object()
    .shape({
      dsdID: Yup.string().required(),
      didID: Yup.string().required(),
      dsiID: Yup.string().required(),
      diiID: Yup.string().nullable(),
      Action: Yup.string().oneOf(Object.values(FormDataActions)).required(),
      Value: Yup.lazy(value => {
        switch (typeof value) {
          case 'boolean':
            return Yup.bool();
          default:
            return Yup.string().nullable();
        }
      }),
    })
    .required(),
);

module.exports = { formDataUpdateSchema };
