import { defaultColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Error Category',
    field: 'ErrorCategoryCd',
    width: 150,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Severity',
    field: 'SeverityCd',
    width: 150,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Description',
    field: 'ErrorMessageTxt',
    width: 1500,
  },
];

export default columnDefinitions;
