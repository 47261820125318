import 'bootstrap/dist/css/bootstrap.css';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css';
import 'react-simple-dropdown/styles/Dropdown.css';

import '@tls/ui-spinner/lib/styles.css';
import '@tls/ui-search-bar/lib/styles.css';
import '@tls/ui-aggrid/lib/styles.css';
import '@tls/ui-select/lib/styles.css';
import '@tls/ui-modal/lib/styles.css';
import '@tls/ui-dropdown-menu/lib/styles.css';
import '@tls/ui-session-guard/lib/styles.css';

import '@pwc/appkit-react/style/appkit-react.default.css';
import './style/styles.scss';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Route, Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import ConfirmModal from './shared/confirmModal/confirmModal.component';
import PermissionModal from './shared/permissionModal/permissionModal.component';
import {
  useFetchTaxYears,
  useFetchPeriods,
  useFetchConsolidations,
  useFetchEntities,
  useFetchTrgReports,
  useFetchJurisdictions,
  useFetchIsPartnership,
} from './shared/store/hooks';
import useSltBinder from './shared/hooks/useSltBinder';
import { Dashboard } from './homePage';
import { EntityRoutes } from './entity';
import { FilingDecisionsRoutes } from './filingDecisions';
import { FilingDecisionsRoutes as FilingDecisionsRoutesV2 } from './filingDecisionsV2';
import { TaxReturnsRoutes } from './taxReturns';
import { MemberReturnsRoutes } from './memberReturns';
import { ReturnStatusTrackerDetailsRoutes } from './returnStatusTrackerDetails';
import { StateFilingGroupRoutes } from './stateFilingGroups';
import { StateSpecificInformationRoutes } from './stateSpecificInformation';
import { StateSpecificInformationRoutes as StateSpecificInformationV2Routes } from './stateSpecificInformationV2';
import { StateCommonInformationRoutes } from './stateCommonInformation';
import { MemberInformationalDataRoutes } from './memberInformationalData';
import { GlobalMemberInformationalDataRoutes } from './globalMemberInformationalData';
import { FilingGroupRoutes as FederalProFormaFilingGroupRoutes } from './federalProFormaFilingGroups';
import { FilingGroupsRoutes } from './filingGroups';
import { ConsolidationRoutes } from './consolidation';
import { DevelopmentRoutes } from './development';
import { AdminRoutes } from './admin';
import { ComplianceRoutes } from './compliance';
import { ReportsRoutes } from './reports';
import { ToolsRoutes } from './tools';
import { EFileRoutes } from './eFile';
import { ProformaRoutes } from './proforma';
import { CustomScreenRoute } from './customScreen';
import { ManualCalculationsRoute } from './manualCalculations';
import { BinderRoute } from './binderMaintenance';
import { ReturnRoute } from './returnMaintenance';
import {
  FederalProformaRoute,
  FederalAsFiledRoute,
  SeparateFederalProformaRoute,
} from './federalProforma';
import { FilingAttributesRoutes } from './filingAttributes';
import { TaxSummariesRoute } from './taxSummaries';
import { ViewPrintTaxReturnRoute } from './viewPrintTaxReturn';
import { ViewPrintAttachmentDetailsRoute } from './viewPrintAttachmentDetails';
import { ViewFormWorkpaperRoute } from './viewFormWorkpaper';
import { WorkpaperInstanceRoute } from './workpaperInstance';
import { FlowThroughK1DetailsRoute } from './flowThroughK1Details';
import { FilingStatesRoute } from './filingStates';
import GlobalContextNavigator from './navigator/navigator.container';
import { useUserJobsPolling } from './jobs/store/hooks';
import { useFetchFilingStates } from './filingStates/store/hooks';

const Slt = ({ areAnyClientsAvailable }) => {
  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  useFetchTaxYears();
  useFetchPeriods();
  useFetchConsolidations();
  useFetchEntities();
  useFetchTrgReports();
  useFetchFilingStates();
  useFetchJurisdictions();
  useFetchIsPartnership();
  useUserJobsPolling();
  useSltBinder();

  const fullWidthPage = !areAnyClientsAvailable;

  return (
    <>
      {fullWidthPage ? null : <GlobalContextNavigator />}
      <div className="app">
        <ConfirmModal />
        <PermissionModal />
        <div className="page-container">
          <Switch>
            <Route exact path={Routes.root.MAIN} component={Dashboard} />
            <Route path={Routes.entities.MAIN} component={EntityRoutes} />
            <Route path={Routes.consolidations.MAIN} component={ConsolidationRoutes} />
            <Route
              path={Routes.federalProFormaFilingGroups.MAIN}
              component={FederalProFormaFilingGroupRoutes}
            />
            <Route path={Routes.stateFilingGroups.MAIN} component={StateFilingGroupRoutes} />
            <Route path={Routes.filingGroups.MAIN} component={FilingGroupsRoutes} />
            <Route path={Routes.tools.MAIN} component={ToolsRoutes} />
            <Route path={Routes.eFile.MAIN} component={EFileRoutes} />
            <Route path={Routes.proforma.MAIN} component={ProformaRoutes} />
            <Route path={Routes.compliance.MAIN} component={ComplianceRoutes} />
            <Route path={Routes.administration.MAIN} component={AdminRoutes} />
            <Route path={Routes.reports.MAIN} component={ReportsRoutes} />
            <Route path={Routes.manualCalculations.MAIN} component={ManualCalculationsRoute} />
            <Route path={Routes.binderMaintenance.MAIN} component={BinderRoute} />
            <Route path={Routes.returnMaintenance.MAIN} component={ReturnRoute} />
            <Route
              path={Routes.separateFederalProforma.MAIN}
              component={SeparateFederalProformaRoute}
            />
            <Route path={Routes.federalProforma.MAIN} component={FederalProformaRoute} />
            <Route path={Routes.federalAsFiled.MAIN} component={FederalAsFiledRoute} />
            <Route path={Routes.viewPrintTaxReturn.MAIN} component={ViewPrintTaxReturnRoute} />
            <Route
              path={Routes.viewPrintAttachmentDetails.MAIN}
              component={ViewPrintAttachmentDetailsRoute}
            />
            <Route exact path={Routes.viewFormWorkpaper.MAIN} component={ViewFormWorkpaperRoute} />
            <Route path={Routes.workpaperInstance.MAIN} component={WorkpaperInstanceRoute} />
            <Route path={Routes.flowThroughK1Details.MAIN} component={FlowThroughK1DetailsRoute} />
            <Route path={Routes.filingStates.MAIN} component={FilingStatesRoute} />
            <Route path={Routes.filingDecisions.MAIN} component={FilingDecisionsRoutes} />
            <Route path={Routes.filingDecisionsV2.MAIN} component={FilingDecisionsRoutesV2} />
            <Route
              path={Routes.filingDecisionAmountDetailsV2.MAIN}
              component={FilingDecisionsRoutesV2}
            />
            <Route path={Routes.setupTaxReturns.MAIN} component={TaxReturnsRoutes} />
            <Route path={Routes.memberReturns.MAIN} component={MemberReturnsRoutes} />
            <Route
              path={Routes.returnStatusTracker.MAIN}
              component={ReturnStatusTrackerDetailsRoutes}
            />
            <Route path={Routes.filingAttributes.MAIN} component={FilingAttributesRoutes} />
            <Route
              path={Routes.stateSpecificInformation.MAIN}
              component={StateSpecificInformationRoutes}
            />
            <Route
              path={Routes.stateSpecificInformationV2.MAIN}
              component={StateSpecificInformationV2Routes}
            />
            <Route
              path={Routes.stateCommonInformation.MAIN}
              component={StateCommonInformationRoutes}
            />
            <Route
              path={Routes.memberInformationalData.MAIN}
              component={MemberInformationalDataRoutes}
            />
            <Route
              path={Routes.globalMemberInformationalData.MAIN}
              component={GlobalMemberInformationalDataRoutes}
            />
            <Route path={Routes.taxSummaries.MAIN} component={TaxSummariesRoute} />
            <Route path={Routes.customScreen.MAIN} component={CustomScreenRoute} />
            <Route component={DevelopmentRoutes} />
          </Switch>
        </div>
      </div>
    </>
  );
};

Slt.propTypes = {
  areAnyClientsAvailable: PropTypes.bool.isRequired,
};

export default Slt;
