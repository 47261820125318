import { DisallowOverrideReportDataResponse, CompilationInfoResponse } from '@tls/slt-types';

import stringify from '../../utils/stringify';

import { QueryKeys, useCustomQuery } from '.';

export const useQueryDisallowOverrideReportData = ({
  params: { taxYear },
  enabled,
}: {
  params: { taxYear: string };
  enabled: boolean;
}) =>
  useCustomQuery<DisallowOverrideReportDataResponse>(
    [QueryKeys.ExpressionBuilder.DisallowOverrideReport, { taxYear }],
    {
      url: `/api/development/expression-builder/disallow-override-report/${taxYear}`,
      defaultData: [],
      errorMessage: 'Error fetching disallow override report data',
      enabled,
    },
  );

interface CompilationInfoParams {
  taxYear?: string;
  jurisdictionId?: string;
}

export const useQueryCompilationInfo = ({
  params,
  enabled,
}: {
  params: CompilationInfoParams;
  enabled: boolean;
}) =>
  useCustomQuery<CompilationInfoResponse>([QueryKeys.ExpressionBuilder.CompilationInfo, params], {
    url: `/api/development/expression-builder/compilation-info/${params.taxYear}?${stringify({
      jurisdictionId: params.jurisdictionId,
    })}`,
    enabled,
    errorMessage: 'Fetching latest calculation compile info failed',
  });
