import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';

import Input from '../../../shared/forms/inputFormik/inputFormik.component';
import thousandsSeparatorInputFormik from '../../../shared/forms/inputFormik/thousandsSeparatorInputFormik.component';
import Select from '../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import FilingAttributes from '../../../shared/forms/filingAttributes/filingAttributes.component';
import CalcPreferencesSingleValue from '../../../shared/forms/calcPreferences/calcPreferencesSingleValue.component';
import { calcPreferencePropType } from '../../../shared/forms/calcPreferences/propTypes';
import { filingAttributesDefinitionTypes } from '../propTypes';
import TaxRatesAndConstantsFormHeader from '../taxRatesAndConstantsFormHeader.component';

import getMnemonicDisplayOptions from './getMnemonicDisplayOptions';
import styles from './constants.module.scss';
import { constantFormType } from './propTypes';

const ConstantForm = ({ rowToEditValues, isEditMode, filingAttributes, calcPreferences }) => {
  const { isSubmitting, submitForm, values, dirty, resetForm, errors } = useFormikContext();

  const renderFilingAttributes = useCallback(
    () => (
      <FilingAttributes filingAttributes={filingAttributes} isColumnAligned hasHorizontalLayout />
    ),
    [filingAttributes],
  );

  const onCancelClick = useCallback(() => resetForm(null, rowToEditValues), [
    rowToEditValues,
    resetForm,
  ]);

  return (
    <form className={styles.constantsForm}>
      <TaxRatesAndConstantsFormHeader
        onCancelClick={onCancelClick}
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        dirty={dirty}
        title={`${isEditMode ? 'Edit' : 'Add'} Constants`}
        errors={errors}
      />
      <div className={styles.constantsFormFieldsContainer}>
        <Field
          className={`form-text ${styles.constantsSmallField}`}
          label="Constant ID"
          name="constantId"
          component={Input}
          autoComplete="off"
          disabled={isEditMode}
        />
        <Field
          className={`form-text ${styles.constantsMediumField}`}
          label="Description"
          name="description"
          component={Input}
          autoComplete="off"
        />
        <Field
          className={`form-text ${styles.constantsSmallField}`}
          label="Value"
          name="value"
          component={thousandsSeparatorInputFormik}
          autoComplete="off"
        />
        <Select
          wrapperClassName={`form-text ${styles.constantsSmallField}`}
          appkitLabel="Mnemonic"
          name="mnemonic"
          options={getMnemonicDisplayOptions()}
          value={values.mnemonic}
        />
      </div>

      <div className={styles.constantsFilingAttributesAndCalcPrefsContainer}>
        <div className={styles.constantsFilingAttributesContainer}>
          <span className="a-h5">Filing Attributes</span>
          {renderFilingAttributes()}
        </div>
        <div className={styles.constantsCalcPreferencesContainer}>
          <CalcPreferencesSingleValue calcPreferences={calcPreferences} values={values} />
        </div>
      </div>
    </form>
  );
};

ConstantForm.propTypes = {
  rowToEditValues: constantFormType,
  isEditMode: PropTypes.bool.isRequired,
  calcPreferences: calcPreferencePropType,
  filingAttributes: filingAttributesDefinitionTypes,
};

export default ConstantForm;
