import React from 'react';

import { GtwUploadFileResponse } from '../../../../../common/types';
import { SourceId } from '../../../shared/enums/gtwFileUpload';

import { UploadFiles } from './enums';
import FileFormatValidityIndicator from './fileFormatValidityIndicator.component';

interface FileFormatRequirementsItemsProps {
  sourceOption: SourceId;
  fileFormatCheckResults: GtwUploadFileResponse;
}

const MANIFEST_FILE_IN_MANIFEST_FOLDER_TEXT = 'A "manifest" folder with a "manifest.xml" file.';

const GTW_SAMPLE_SUBMISSION_ID = '1414802021315genl420.xml';
const TTI_SAMPLE_SUBMISSION_ID = '1414802022326SLTE283.xml';

const xmlFileFormatRequirementDescription = (sourceOption: SourceId) =>
  `An "xml" folder with an xml file with the submission id (2) as its file name (e.g. ${
    sourceOption === SourceId.GTW ? GTW_SAMPLE_SUBMISSION_ID : TTI_SAMPLE_SUBMISSION_ID
  }).`;

const FileFormatRequirementsItems = ({
  sourceOption,
  fileFormatCheckResults,
}: FileFormatRequirementsItemsProps) => {
  const FILE_FORMAT_TEXTS = {
    [UploadFiles.MANIFEST_FILE]: MANIFEST_FILE_IN_MANIFEST_FOLDER_TEXT,
    [UploadFiles.XML_FILE]: xmlFileFormatRequirementDescription(sourceOption),
  };

  return (
    <>
      {Object.keys(FILE_FORMAT_TEXTS).map(key => {
        if (key === UploadFiles.MANIFEST_FILE || key === UploadFiles.XML_FILE) {
          const isValidFileFormat = fileFormatCheckResults
            ? fileFormatCheckResults[key]?.isValidFileFormat
            : true;

          return (
            <FileFormatValidityIndicator
              key={key}
              value={FILE_FORMAT_TEXTS[key]}
              isValidFileFormat={isValidFileFormat}
            />
          );
        }
        throw new Error('Key not found.');
      })}
    </>
  );
};

export default FileFormatRequirementsItems;
