import {
  defaultColumnDefinitionWithFilter,
  currencyColumnDefinition,
} from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Jurisdiction',
    field: 'jurisdiction',
    width: 120,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Tax Year',
    field: 'taxYear',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Tax Type',
    field: 'taxType',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Start',
    field: 'startRange',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'End',
    field: 'stopRange',
    width: 80,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'Rate',
    field: 'rate',
    width: 80,
  },
  {
    ...currencyColumnDefinition,
    headerName: 'Fixed Dollar Amount',
    field: 'fixedDollarAmount',
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'For Return Types',
    field: 'forReturnTypes',
    width: 300,
  },
  {
    ...defaultColumnDefinitionWithFilter,
    headerName: 'When Calc Preference (Or if multiple, All preferences if null)',
    field: 'whenCalcPreferences',
    width: 300,
  },
];

export default columnDefinitions;
