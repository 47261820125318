import PropTypes from 'prop-types';

export const FilingDecisionPropTypes = PropTypes.shape({
  jurisdictionId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  franchiseTaxYn: PropTypes.bool.isRequired,
  incomeTaxYn: PropTypes.bool.isRequired,
  property: PropTypes.number.isRequired,
  rent: PropTypes.number.isRequired,
  payroll: PropTypes.number.isRequired,
  salesOfServiceDestination: PropTypes.number.isRequired,
  salesOfServiceOrigin: PropTypes.number.isRequired,
  tangibleSales: PropTypes.number.isRequired,
  finalFilingGroupId: PropTypes.string.isRequired,
  hasNexusNotes: PropTypes.bool.isRequired,
  newOrgName: PropTypes.string.isRequired,
  nexusInd: PropTypes.bool.isRequired,
  orgName: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
});

export const FilingDecisionsPropTypes = PropTypes.arrayOf(FilingDecisionPropTypes.isRequired);

export const orgIdOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
).isRequired;

export const filteredOrgIdOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
).isRequired;

export const nexusNoteToEditPropTypes = PropTypes.shape({
  createCustomerId: PropTypes.string,
  jurisdictionId: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  noteId: PropTypes.number.isRequired,
  noteJurisdiction: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  preparedBy: PropTypes.string.isRequired,
  rolloverToNextYear: PropTypes.bool.isRequired,
  shortDescription: PropTypes.string,
  statusType: PropTypes.string,
});
