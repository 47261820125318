import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import ComingSoonPage from '../comingSoonPage/comingSoonPage.component';

import Reports from './reports.component';
import ReportRoute from './routeConfiguration/reportRoute.container';
import StateTaxableIncomeReconciliation from './stateTaxableIncomeReconciliation/stateTaxableIncomeReconciliation.component';
import LegalEntityAuditTrail from './legalEntityAuditTrail/legalEntityAuditTrail.component';
import EntityAnalyticsReview from './entityAnalyticsReview/entityAnalyticsReview.container';
import HighLevelPlanningData from './highLevelPlanningData/highLevelPlanningData.component';
import MultiStateLeTaxableIncome from './multiStateLeTaxableIncome/multiStateLeTaxableIncome.container';
import FilingPositions from './filingPositions/filingPositions.container';
import State851AffiliateData from './state851AffiliateData/state851AffiliateData.component';
import EffectiveTaxRate from './effectiveTaxRate/effectiveTaxRate.component';
import EffectiveTaxRateAccounting from './effectiveTaxRateTaxAccounting/effectiveTaxRateTaxAccounting.component';
import EverywhereBreakdown from './everywhereBreakdown/everywhereBreakdown.component';
import EntityData from './entityData/entityData.component';
import LocalityData from './localityData/localityData.component';
import BusinessAuditTrail from './businessAuditTrail/businessAuditTrail.container';
import IntercompanyPayables from './intercompanyPayables/intercompanyPayables.container';
import EstimateSummary from './estimateSummary/estimateSummary.container';
import TaskWaiting from './taskWaiting/taskWaiting.container';
import IntercompanyInterestExpense from './intercompanyInterestExpense/intercompanyInterestExpense.container';
import IntercompanyInterestIncome from './intercompanyInterestIncome/intercompanyInterestIncome.container';
import RawDataForAnEntity from './rawDataForAnEntity/rawDataForAnEntity.container';
import PopulationAnalysis from './populationAnalysis/populationAnalysis.component';
import DetailedPlanningData from './detailedPlanningData/detailedPlanningData.component';
import RunTrgCustomReport from './trg/viewTrgReport.container';
import EFileTransmissionStatus from './eFileTransmissionStatus/eFileTransmissionStatus.container';
import EFileFederalReturnsAvailability from './eFileFederalReturnsAvailability/eFileFederalReturnsAvailability.container';
import EFileXmlComparisonReport from './eFileXmlComparisonReport/eFileXmlComparisonReport.container';
import WatermarkUsage from './watermarkUsage/watermarkUsage.container';
import DividendToStateAmounts from './dividendToStateAmounts/dividendToStateAmounts.container';
import OverrideReport from './overrideReport/overrideReport.container';
import ManualAdjustmentReport from './manualAdjustmentReport/manualAdjustmentReport.container';

const ReportRoutes = () => (
  <Switch>
    <ReportRoute exact path={Routes.reports.MAIN} component={Reports} />
    <ReportRoute
      exact
      path={Routes.stateTaxableIncomeReconciliation.MAIN}
      component={StateTaxableIncomeReconciliation}
    />
    <ReportRoute exact path={Routes.legalEntityAuditTrail.MAIN} component={LegalEntityAuditTrail} />
    <ReportRoute exact path={Routes.entityAnalyticsReview.MAIN} component={EntityAnalyticsReview} />
    <ReportRoute
      exact
      path={Routes.multiStateLeTaxableIncome.MAIN}
      component={MultiStateLeTaxableIncome}
    />
    <ReportRoute exact path={Routes.highLevelPlanningData.MAIN} component={HighLevelPlanningData} />
    <ReportRoute exact path={Routes.filingPositions.MAIN} component={FilingPositions} />
    <ReportRoute exact path={Routes.effectiveTaxRate.MAIN} component={EffectiveTaxRate} />
    <ReportRoute
      exact
      path={Routes.effectiveTaxRateTaxAccounting.MAIN}
      component={EffectiveTaxRateAccounting}
    />
    <ReportRoute exact path={Routes.detailedPlanningData.MAIN} component={DetailedPlanningData} />
    <ReportRoute exact path={Routes.state851AffiliateData.MAIN} component={State851AffiliateData} />
    <ReportRoute exact path={Routes.populationAnalysis.MAIN} component={PopulationAnalysis} />
    <ReportRoute exact path={Routes.rawDataForAnEntity.MAIN} component={RawDataForAnEntity} />
    <ReportRoute exact path={Routes.localityData.MAIN} component={LocalityData} />
    <ReportRoute exact path={Routes.entityData.MAIN} component={EntityData} />
    <ReportRoute exact path={Routes.taskWaiting.MAIN} component={TaskWaiting} />
    <ReportRoute exact path={Routes.estimateSummary.MAIN} component={EstimateSummary} />
    <ReportRoute exact path={Routes.everywhereBreakdown.MAIN} component={EverywhereBreakdown} />
    <ReportRoute exact path={Routes.businessAuditTrail.MAIN} component={BusinessAuditTrail} />
    <ReportRoute exact path={Routes.intercompanyPayables.MAIN} component={IntercompanyPayables} />
    <ReportRoute
      exact
      path={Routes.intercompanyInterestExpense.MAIN}
      component={IntercompanyInterestExpense}
    />
    <ReportRoute
      exact
      path={Routes.intercompanyInterestIncome.MAIN}
      component={IntercompanyInterestIncome}
    />
    <ReportRoute exact path={Routes.customTrgReport.MAIN} component={RunTrgCustomReport} />
    <ReportRoute exact path={Routes.rawData.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.GECSPriorYearComparison.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.infoDataSearchByValue.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.categoryDetail.MAIN} component={ComingSoonPage} />
    <ReportRoute
      exact
      path={Routes.consolidatedEverywhereBreakdown.MAIN}
      component={ComingSoonPage}
    />
    <ReportRoute exact path={Routes.dividendData.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.businessCrosstab.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.legalEntityCrosstab.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.businessCSVExport.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.legalEntityCSVAuditExport.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.legalEntityCSVExportT05Only.MAIN} component={ComingSoonPage} />
    <ReportRoute exact path={Routes.payablesReconciliation.MAIN} component={ComingSoonPage} />
    <ReportRoute
      exact
      path={Routes.efileTransmissionStatusReport.MAIN}
      component={EFileTransmissionStatus}
    />
    <ReportRoute
      exact
      path={Routes.eFileFederalReturnsAvailability.MAIN}
      component={EFileFederalReturnsAvailability}
    />
    <ReportRoute
      exact
      path={Routes.eFileXmlComparisonReport.MAIN}
      component={EFileXmlComparisonReport}
    />
    <ReportRoute exact path={Routes.watermarkUsage.MAIN} component={WatermarkUsage} />
    <ReportRoute
      exact
      path={Routes.dividendToStateAmounts.MAIN}
      component={DividendToStateAmounts}
    />
    <ReportRoute exact path={Routes.overrideReport.MAIN} component={OverrideReport} />
    <ReportRoute
      exact
      path={Routes.separateManualAdjustmentReport.MAIN}
      component={ManualAdjustmentReport}
    />
  </Switch>
);

export default ReportRoutes;
