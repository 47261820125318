import oracleSysGuidUuid from '../../../utils/oracleSysGuidUuid';
import { PdfPageDataUsageTypes } from '../constants';

const getNewStateForAssignDatasets = (state, { datasets, pageNumber, parents }) => {
  const pdfPageDataUsage = state.pdfPageDataUsage.filter(
    dataset => dataset.type === PdfPageDataUsageTypes.DATASET_USAGE,
  );
  const filteredParents = parents.filter(
    parent => !pdfPageDataUsage.some(item => item.treeHierarchyId === `${parent.id}_${pageNumber}`),
  );

  const preparedParentsDatasets = filteredParents.map(parent => {
    const parentId = parents.find(p => p.id === parent.parentId)?.id;
    const id = oracleSysGuidUuid();
    return {
      id,
      datasetId: parent.id,
      name: parent.name,
      pageNumber: Number(pageNumber),
      type: PdfPageDataUsageTypes.DATASET_USAGE,
      treeHierarchyId: `${parent.id}_${pageNumber}`,
      treeHierarchyParentId: parentId ? `${parentId}_${pageNumber}` : pageNumber,
    };
  });

  const preparedDatasets = datasets
    .map(dataset => {
      const datasetIsAlreadyAssignedToPage = state.pdfPageDataUsage.find(
        assignedDataset =>
          assignedDataset.type === PdfPageDataUsageTypes.DATASET_USAGE &&
          assignedDataset.datasetId === dataset.id &&
          Number(assignedDataset.pageNumber) === Number(pageNumber),
      );

      if (datasetIsAlreadyAssignedToPage) {
        return null;
      }
      const id = oracleSysGuidUuid();
      return {
        id,
        datasetId: dataset.id,
        name: dataset.name,
        pageNumber: Number(pageNumber),
        parentId: String(pageNumber),
        idWithPageNumber: `${id}_${pageNumber}`,
        type: PdfPageDataUsageTypes.DATASET_USAGE,
        treeHierarchyId: `${dataset.id}_${pageNumber}`,
        treeHierarchyParentId: dataset.parentId ? `${dataset.parentId}_${pageNumber}` : pageNumber,
      };
    })
    .filter(Boolean);

  const filteredPdfDataUsageForExistingParentPageDuplicates = state.pdfPageDataUsage.filter(
    // If a dataset of a parent exists in assigned datasets, adding a child will only show under proper parent
    pdfDataUsage => {
      return !preparedParentsDatasets.some(
        preparedDatasetValue =>
          preparedDatasetValue.pageNumber === pdfDataUsage.pageNumber &&
          preparedDatasetValue.datasetId === pdfDataUsage.datasetId &&
          pdfDataUsage.type === PdfPageDataUsageTypes.DATASET_USAGE,
      );
    },
  );

  const filteredPreparedDatasetsForParentChildDuplicates = preparedDatasets.filter(
    // If a parent + child is selected for assignment, only show parent and child, not parent + parent/child
    preparedDatasetsValue => {
      return !preparedParentsDatasets.some(
        preparedParentsValue =>
          preparedDatasetsValue.pageNumber === preparedParentsValue.pageNumber &&
          preparedDatasetsValue.datasetId === preparedParentsValue.datasetId &&
          preparedDatasetsValue.type === PdfPageDataUsageTypes.DATASET_USAGE,
      );
    },
  );

  return {
    ...state,
    pdfPageDataUsage: [
      ...filteredPdfDataUsageForExistingParentPageDuplicates,
      ...filteredPreparedDatasetsForParentChildDuplicates,
      ...preparedParentsDatasets,
    ],
  };
};

export default getNewStateForAssignDatasets;
