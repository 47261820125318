import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Checkbox } from '@pwc/appkit-react';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import styles from '../../shared/reports/reportParam.module.scss';
import getReportUrl from '../shared/getReportUrl';
import { getAllValues } from '../utils';

import getColumnDefinitions from './rawDataForAnEntity.columnDefinitions';
import rawDataForAnEntityStyles from './rawDataForAnEntity.module.scss';

const AccountGroupTypeParams = {};

const BALANCE_TYPE_OPTIONS = [
  { value: 'All', label: 'Beginning and Ending Balances' },
  { value: 'E', label: 'Ending Balances Only' },
  { value: 'B', label: 'Beginning Balances Only' },
];

const endpointBasePath = getReportUrl('raw-data-for-an-entity');

const RawDataForAnEntity = () => {
  const [balanceType, setBalanceType] = useState(BALANCE_TYPE_OPTIONS[0].value);
  const [isComponentDetail, setComponentDetail] = useState(false);

  const toggleComponentDetail = useCallback(
    () => setComponentDetail(isComponentDetail => !isComponentDetail),
    [],
  );

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  const accountGroupTypeParam = useReportParam({
    endpointBasePath,
    paramName: 'accountGroupId',
    params: AccountGroupTypeParams,
  });

  const balanceTypeParam = useMemo(
    () => ({
      isFetchingParamData: false,
      paramData: BALANCE_TYPE_OPTIONS,
      paramValue: balanceType,
      setParamValue: setBalanceType,
    }),
    [balanceType],
  );

  const setEntityId = entityIdParam.setParamValue;

  useEffect(() => {
    if (entityIdParam.paramData?.length) {
      setEntityId(entityIdParam.paramData[0].value);
    }
  }, [setEntityId, entityIdParam.paramData]);

  useEffect(() => {
    if (accountGroupTypeParam.paramData) {
      accountGroupTypeParam.setParamValue(accountGroupTypeParam.paramData.map(data => data.value));
    }
  }, [accountGroupTypeParam.paramData]);

  const isFetchingAnyParam = checkIsFetchingAnyParam([taxYearParam, periodParam, entityIdParam]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        entityId: entityIdParam.paramValue,
        begEndFlag: balanceType,
        accountGroupTypeNames: getAllValues(accountGroupTypeParam.paramData),
        isComponentDetail,
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        entityIdParam.paramValue,
        balanceType,
        accountGroupTypeParam.paramData,
        isComponentDetail,
      ],
    ),
  });

  const rowData = reportData?.rows || [];

  const { props, onGridReady } = useActionMenuForExport({
    reportData: rowData,
    includeHiddenColumns: true,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        stateCodes: reportData?.stateCodes || [],
        entityId: entityIdParam.paramValue,
        isComponentDetail,
      }),
    [reportData, entityIdParam.paramValue, isComponentDetail],
  );

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown
            label="Entity"
            selectAllLabel="All Entities"
            param={entityIdParam}
            showSelectAllOption
          />
          <ReportParamDropdown label="Balance Type" param={balanceTypeParam} />
          <ReportParamMultiSelect
            label="Account Group Type"
            param={accountGroupTypeParam}
            selectedAll
          />
          <Checkbox
            className={rawDataForAnEntityStyles.componentDetailCheckbox}
            onChange={toggleComponentDetail}
            checked={isComponentDetail}
          >
            Component Detail
          </Checkbox>
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={rowData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RawDataForAnEntity;
