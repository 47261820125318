import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchDirectPartnershipInfoTypes,
  fetchIndirectPartnershipInfoTypes,
  fetchPartnershipInfoTypes,
  fetchPartnershipsTypes,
  addPartnershipInfoTypes,
  updateOrDeletePartnershipsInfoTypes,
} from './types';

export const fetchDirectPartnership = apiAction({
  types: fetchDirectPartnershipInfoTypes,
  apiAction: api.fetchDirectPartnershipInfo,
});

export const fetchIndirectPartnership = apiAction({
  types: fetchIndirectPartnershipInfoTypes,
  apiAction: api.fetchIndirectPartnershipInfo,
});

export const fetchPartnershipTypes = apiAction({
  types: fetchPartnershipInfoTypes,
  apiAction: api.fetchPartnershipInfoTypes,
});

export const fetchPartnerships = apiAction({
  types: fetchPartnershipsTypes,
  apiAction: api.fetchPartnerships,
});

export const addPartnershipInfo = apiAction(
  {
    types: addPartnershipInfoTypes,
    apiAction: api.addPartnershipInfo,
  },
  { successNotificationMessage: () => 'Partnership has been successfully added' },
);

export const updateOrDeletePartnershipsInfo = apiAction({
  types: updateOrDeletePartnershipsInfoTypes,
  apiAction: api.updateOrDeletePartnershipsInfo,
});
