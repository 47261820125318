import { httpGetAndParse } from '../../utils/fetchService';
import stringify from '../../utils/stringify';

export const fetchTaxYears = () =>
  httpGetAndParse({
    route: `/api/shared/tax-years`,
    errorMessage: 'Fetching tax years for development workspace failed',
  });

export const fetchPeriods = taxYear =>
  httpGetAndParse({
    route: `/api/shared/periods/${taxYear}`,
    errorMessage: 'Fetching periods for development workspace failed',
  });

export const fetchConsolidations = (taxYear, period) =>
  httpGetAndParse({
    route: `/api/shared/consolidations/${taxYear}/${period}`,
    errorMessage: 'Fetching consolidations for development failed',
  });

export const fetchJurisdictions = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/shared/jurisdictions/${taxYear}/${period}`,
    errorMessage: 'Fetching jurisdictions for development workspace failed',
  });

export const fetchJurisdictionsForAttachmentsAndBinders = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/jurisdictions-attachments-binders/${taxYear}`,
  });

export const fetchJimJobsHistory = () =>
  httpGetAndParse({
    route: `/api/development/jim-jobs-history`,
    errorMessage: 'Fetching JIM jobs history failed',
  });

export const fetchBaseCalcSpecs = ({
  taxYear,
  jurisdictionId,
  filterOnIndicator,
  isAccountFirstColumn,
}) =>
  httpGetAndParse({
    route: `/api/development/calc-specs/base/data/${taxYear}/${jurisdictionId}?${stringify({
      filterOnIndicator,
      isAccountFirstColumn,
    })}`,
    errorMessage: 'Fetching base calc specs failed',
  });

export const fetchConsolCalcSpecs = ({ taxYear, period, jurisdictionId, isAccountFirstColumn }) =>
  httpGetAndParse({
    route: `/api/development/calc-specs/consol/data/${taxYear}/${period}/${jurisdictionId}?${stringify(
      {
        isAccountFirstColumn,
      },
    )}`,
    errorMessage: 'Fetching consol calc specs failed',
  });

export const fetchConspbCalcSpecs = ({ taxYear, period, jurisdictionId, isAccountFirstColumn }) =>
  httpGetAndParse({
    route: `/api/development/calc-specs/conspb/data/${taxYear}/${period}/${jurisdictionId}?${stringify(
      {
        isAccountFirstColumn,
      },
    )}`,
    errorMessage: 'Fetching conspb calc specs failed',
  });

export const fetchForms = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/development/forms/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching forms failed',
  });

export const fetchFormHtmlImage = ({ formId, pdfId }) =>
  httpGetAndParse({
    route: `/api/development/forms/${formId}/${pdfId}/html-image`,
    errorMessage: 'Fetching form HTML image failed',
  });
