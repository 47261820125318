import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

const ValidationErrorModal = ({ hideModal, visible, modalData }) => (
  <Modal
    title="Validation error"
    visible={visible}
    dismissAction={hideModal}
    closeAction={hideModal}
  >
    <p>{modalData}</p>
  </Modal>
);

ValidationErrorModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  modalData: PropTypes.string,
};

export default ValidationErrorModal;
