import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';

import columnDefs from './partnershipsModal.columnDefinitions';
import styles from './partnershipsModal.module.scss';

const PartnershipModal = ({
  hideModal,
  visible,
  partnerships,
  isFetchingPartnerships,
  fetchPartnerships,
  taxYear,
  period,
  selectPartnership,
}) => {
  useEffect(() => {
    if (visible) {
      fetchPartnerships({ taxYear, period });
    }
  }, [fetchPartnerships, visible, taxYear, period]);

  const [selectedPartnershipId, setSelectedPartnershipId] = useState(null);

  const confirmSelection = useCallback(() => {
    selectPartnership(selectedPartnershipId);
    hideModal();
  }, [hideModal, selectPartnership, selectedPartnershipId]);

  const handleRowSelection = useCallback(
    ({ api }) => {
      if (api.getSelectedRows()[0]) {
        const selectedRow = api.getSelectedRows()[0];
        setSelectedPartnershipId(selectedRow.orgId);
      }
    },
    [setSelectedPartnershipId],
  );

  const deselectFilteredRows = useCallback(({ api }) => {
    const selectedRow = api.getSelectedRows()[0];
    if (!selectedRow) {
      return;
    }

    const nodesAfterFilter = [];
    api.forEachNodeAfterFilter(node => {
      nodesAfterFilter.push(node);
    });

    if (nodesAfterFilter.every(node => node.data.orgId !== selectedRow.orgId)) {
      api.deselectAll();
      setSelectedPartnershipId(null);
    }
  }, []);

  return (
    <Modal
      className={styles.partnershipsModal}
      title="Select an Entity"
      dismissText="Cancel"
      submitText="Select"
      closeAction={hideModal}
      dismissAction={hideModal}
      submitAction={confirmSelection}
      submitDisabled={!selectedPartnershipId}
      visible={visible}
    >
      <AgGrid
        rowData={partnerships}
        columnDefs={columnDefs}
        isGridLoading={isFetchingPartnerships}
        onSelectionChanged={handleRowSelection}
        onFilterChanged={deselectFilteredRows}
        withSearchBar
      />
    </Modal>
  );
};

PartnershipModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  partnerships: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
      entityTypeDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingPartnerships: PropTypes.bool.isRequired,
  fetchPartnerships: PropTypes.func.isRequired,
  taxYear: PropTypes.string,
  period: PropTypes.string,
  selectPartnership: PropTypes.func.isRequired,
};

export default PartnershipModal;
