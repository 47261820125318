import { createSelector } from 'reselect';

export const filingGroupsSelector = ({ consolidation }) =>
  consolidation.filingGroups.filingGroupsData.filingGroups;

export const isFetchingFilingGroupsDataSelector = ({ consolidation }) =>
  consolidation.filingGroups.isFetchingFilingGroupsData;

export const filingGroupIdSelector = ({ consolidation }) =>
  consolidation.filingGroups.filingGroupId;

export const filingGroupsAsOptionsSelector = createSelector([filingGroupsSelector], filingGroups =>
  filingGroups.map(({ filingGroupId, filingGroupDesc }) => ({
    label: filingGroupDesc,
    value: filingGroupId,
  })),
);

export const filingGroupDescSelector = createSelector(
  [filingGroupsSelector, filingGroupIdSelector],
  (filingGroups, filingGroupId) =>
    (
      filingGroups.find(filingDecision => filingDecision.filingGroupId === filingGroupId) || {
        filingGroupDesc: null,
      }
    ).filingGroupDesc,
);

export const filingGroupsOptionsSelector = ({ consolidation }) =>
  consolidation.filingGroups.filingGroupsOptions;

export const isFetchingFilingGroupsOptionsSelector = ({ consolidation }) =>
  consolidation.filingGroups.isFetchingFilingGroupsOptions;

export const consolidationReturnDefinitionDataSelector = ({ consolidation }) =>
  consolidation.filingGroups.filingGroupsData.consolidationReturnDefinitionData;
