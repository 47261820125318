import { RunParamQueryRequestParams, RunParamQueryRequestResponse } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './';

export const useQueryRunParamQuery = ({
  params: { paramName, reportId, params },
  enabled,
}: {
  params: RunParamQueryRequestParams;
  enabled: boolean;
}) => {
  const parameterName = encodeURIComponent(paramName);
  const urlParameters = params ? `?params=${encodeURIComponent(JSON.stringify(params))}` : ``;

  return useCustomQuery<RunParamQueryRequestResponse>(
    [QueryKeys.CustomReports.RunParamQuery, { paramName, reportId, params }],
    {
      url: `/api/admin/custom-reports/run/${reportId}/param/${parameterName}/${urlParameters}`,
      enabled,
      defaultData: [],
      errorMessage: 'Running custom query failed',
    },
  );
};
