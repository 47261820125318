import PropTypes from 'prop-types';

export const formFieldType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  datasetId: PropTypes.string,
  dataItemId: PropTypes.string,
  pageNumber: PropTypes.number,
  pdfFieldMappingId: PropTypes.string,
  absoluteValue: PropTypes.bool.isRequired,
  flipSign: PropTypes.bool.isRequired,
  parensIfNegative: PropTypes.bool.isRequired,
  ratioAsPercentage: PropTypes.bool.isRequired,
  suppressCommas: PropTypes.bool.isRequired,
  valueIfZero: PropTypes.string.isRequired,
  decimalPlaces: PropTypes.string.isRequired,
  wrapText: PropTypes.bool.isRequired,
  wrapFirstRowIndt: PropTypes.string.isRequired,
  wrapFullRowcount: PropTypes.string,
  wrapRowspacingIncrement: PropTypes.string,
  formattingString: PropTypes.string,
  appendText: PropTypes.string,
  characterSpacing: PropTypes.string,
  fullfieldPattern: PropTypes.string,
  fullfieldLength: PropTypes.string,
  printFirstCopyOnly: PropTypes.bool.isRequired,
  printLastCopyOnly: PropTypes.bool.isRequired,
  upperCase: PropTypes.bool.isRequired,
  checkBox: PropTypes.string.isRequired,
  yrCheckBox: PropTypes.string,
});
