import sortBy from 'lodash.sortby';

import { SortOrderOption } from './enums';

type BinderOption = {
  consolidationId?: string;
  displayOrder: number;
  formName?: string;
  itemId: string;
  itemType: string;
  orgId?: string;
  orgName?: string;
  rowId: number;
};

const sortBinders = (options: BinderOption[], sortOption: SortOrderOption) => {
  const sortedSltBinders =
    sortOption === SortOrderOption.ENTITY_ID_FIRST
      ? sortBy(options, ['itemType', 'consolidationId', 'orgId', 'formName'])
      : sortBy(options, ['itemType', 'consolidationId', 'formName', 'orgId']);

  // assigning new display order (10, 20, 30, ...) for sorted binders
  return sortedSltBinders.map((binder: BinderOption, index: number) => ({
    id: binder.rowId,
    displayOrder: (index + 1) * 10,
  }));
};

export default sortBinders;
