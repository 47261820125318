import { useEffect, useState } from 'react';

import { Column } from './constants';

const isString = str => typeof str === 'string' || str instanceof String;

const isEqualTo = (str1, str2) => str1.toLowerCase() === str2.toLowerCase();

const isNumberNotZero = val => {
  if (typeof val === 'number' && val !== 0) {
    return true;
  }
  if (
    isString(val) &&
    val[0] === '(' &&
    val[val.length - 1] === ')' &&
    parseFloat(val.substring(1, val.length - 2)) !== 0
  ) {
    return true;
  }
  return false;
};

const useSuppressZeros = originalData => {
  const [suppressedData, setSuppressedData] = useState(originalData);

  useEffect(() => {
    const result = [];
    let currentGroup = [];
    let isCurrentGroupZero = true;
    let isGrouping = false;
    for (let i = 0; i < originalData.length; i++) {
      const row = originalData[i];
      const { emptyRow, column2, column3, column4 } = row;
      if (!isGrouping) {
        if (
          isString(column2) &&
          isString(column3) &&
          isString(column4) &&
          isEqualTo(column2, Column.DATA_COLLECTION) &&
          (isEqualTo(column3, Column.ADJUSTMENT) || isEqualTo(column3, Column.ADJUSTMENTS)) &&
          isEqualTo(column4, Column.TOTAL)
        ) {
          isGrouping = true;
          currentGroup.push(row);
        } else {
          result.push(row);
        }
      } else if (emptyRow || i === originalData.length - 1) {
        if (!isCurrentGroupZero) {
          if (
            emptyRow ||
            isNumberNotZero(column2) ||
            isNumberNotZero(column3) ||
            isNumberNotZero(column4)
          ) {
            currentGroup.push(row);
          }
          result.push(...currentGroup);
        }
        currentGroup = [];
        isGrouping = false;
        isCurrentGroupZero = true;
      } else if (isNumberNotZero(column2) || isNumberNotZero(column3) || isNumberNotZero(column4)) {
        isCurrentGroupZero = false;
        currentGroup.push(row);
      }
    }
    setSuppressedData(result);
  }, [originalData]);

  return {
    suppressedData,
  };
};

export default useSuppressZeros;
