import React from 'react';
import { useSelector } from 'react-redux';

import { useQueryConsol } from '../../shared/queries/consolidations';
import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import {
  taxYearSelector,
  periodSelector,
  consolidationIdSelector,
  jurisdictionIdSelector,
} from '../../shared/store/selectors';

const ParentOrgIdContextValue = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const consolidationId = useSelector(consolidationIdSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const { isFetching, data: consolidationData } = useQueryConsol({
    enabled: Boolean(taxYear && period && consolidationId && jurisdictionId),
    params: {
      taxYear,
      period,
      consolidationId,
      jurisdictionId,
    },
  });

  return (
    <GlobalContextValue
      label="Parent Entity Id"
      value={consolidationData?.parentOrgId}
      isBusy={isFetching}
    />
  );
};

export default ParentOrgIdContextValue;
