import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormDataActions } from '@common-packages/shared-constants';

import useBooleanState from '../shared/hooks/useBooleanState.hook';
import { useRowEditMode } from '../shared/editMode';
import { updateFormValues as updateFormValuesApiAction } from '../shared/store/viewTaxReturn/api';
import useFetch from '../shared/hooks/useFetch.hook';

const getUniqueRowId = ({ data: { dataItemDefId } }) => dataItemDefId;

const useViewFormWorkpaperEditing = ({
  taxYear,
  period,
  filingTypeId,
  entityId,
  jurisdictionId,
  datasetDefId,
  dataItemsDefinitions,
  setParams,
  fetchDatasetInstancesForContext,
  addDatasetInstance,
  clearDatasetInstances,
  formId,

  datasetInstanceId,
  instanceDataPrintService,
  lockIndicator,
  noEditableInstances,
  isSelectedDatasetInstanceIdValid,
  isLoading,

  saveButtonDisabled,
}) => {
  const dispatch = useDispatch();

  const [isInAddMode, enterAddMode, exitAddMode] = useBooleanState(false);

  const { fetch: updateInstanceData, isFetching: isUpdatingInstanceData } = useFetch({
    action: updateFormValuesApiAction,
    successNotificationMessage: 'Successfully updated instance data values',
  });

  const onSave = useCallback(
    async ({ rowsPairsWithChanges }) => {
      if (isInAddMode) {
        await dispatch(
          addDatasetInstance({
            taxYear,
            period,
            filingTypeId,
            orgId: entityId,
            jurisdictionId,
            datasetDefId,
            dataItemInstances: rowsPairsWithChanges.map(({ newRow }) => ({
              dataItemDefId: newRow.dataItemDefId,
              value: newRow.value,
            })),
          }),
        );
        dispatch(clearDatasetInstances());
        setParams({ queryParams: { datasetInstanceId: null } });
        fetchDatasetInstancesForContext();
        exitAddMode();
        return;
      }

      const dataToUpdate = rowsPairsWithChanges.map(({ newRow }) => ({
        dsdID: datasetDefId,
        didID: newRow.dataItemDefId,
        dsiID: datasetInstanceId,
        diiID: newRow.dataItemInstanceId,
        Action: FormDataActions.setValue,
        Value: newRow.value,
      }));

      await updateInstanceData({
        formId: formId || datasetDefId,
        period,
        filingTypeId,
        businessEntityId: entityId,
        body: dataToUpdate,
      });
      fetchDatasetInstancesForContext();
    },
    [
      isInAddMode,
      updateInstanceData,
      formId,
      taxYear,
      period,
      entityId,
      jurisdictionId,
      fetchDatasetInstancesForContext,
      dispatch,
      addDatasetInstance,
      filingTypeId,
      datasetDefId,
      clearDatasetInstances,
      setParams,
      exitAddMode,
      datasetInstanceId,
    ],
  );

  const {
    gridApi,
    navigationPrompt,
    editModeButtons,
    clonedRowData,
    updateRow,
    isInEditMode,
    setIsInEditMode,
    onGridReady,
  } = useRowEditMode({
    onSave,
    saveButtonDisabled,
    rowData: isInAddMode ? dataItemsDefinitions : instanceDataPrintService,
    getUniqueRowId,
    editButtonDisabled:
      lockIndicator ||
      noEditableInstances ||
      !datasetInstanceId ||
      !isSelectedDatasetInstanceIdValid ||
      isLoading ||
      isUpdatingInstanceData,
    showLockIcon: lockIndicator,
    onCancel: exitAddMode,
  });

  const enterAddingInstanceMode = useCallback(() => {
    setIsInEditMode(true);
    enterAddMode();
  }, [setIsInEditMode, enterAddMode]);

  return {
    gridApi,
    navigationPrompt,
    editModeButtons,
    clonedRowData,
    updateRow,
    isInEditMode,
    onGridReady,
    isInAddMode,
    enterAddingInstanceMode,
    isUpdatingInstanceData,
  };
};

export default useViewFormWorkpaperEditing;
