import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import {
  fetchFlowThroughK1DetailsTypes,
  fetchPartnersTypes,
  SELECT_PARTNER_ID,
  updateFlowThroughK1DetailsTypes,
} from './types';

export const fetchFlowThroughK1Details = apiAction({
  types: fetchFlowThroughK1DetailsTypes,
  apiAction: api.fetchFlowThroughK1Details,
});

export const fetchPartners = apiAction({
  types: fetchPartnersTypes,
  apiAction: api.fetchPartners,
});

export const selectPartnerId = partnerId => ({
  type: SELECT_PARTNER_ID,
  payload: partnerId,
});

export const updateFlowThroughK1Details = apiAction(
  {
    types: updateFlowThroughK1DetailsTypes,
    apiAction: api.updateFlowThroughK1Details,
  },
  { successNotificationMessage: () => 'Flow through K1 details has been successfully updated' },
);
