export const WARNING_BEFORE_DELETE = 'You will not be able to recover it';

export enum ModalState {
  INITIAL = 'initial',
  CHECKBOX_GROUP_USED = 'checkbox_group_used',
  CHECKBOX_GROUP_UNUSED = 'checkbox_group_unused',
}

export const modalButtonsText = {
  [ModalState.INITIAL]: { submit: null, dismiss: null },
  [ModalState.CHECKBOX_GROUP_USED]: { submit: 'Ok', dismiss: null },
  [ModalState.CHECKBOX_GROUP_UNUSED]: { submit: 'Yes', dismiss: 'No' },
};
