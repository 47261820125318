import {
  isTaxYearBefore2020,
  isTaxYearAfterOr2021,
  isTaxYearAfterOr2022,
  isTaxYearAfter2022,
  isAnnualPeriod,
  isExtensionPeriod,
  isOneOfEstimationPeriods,
} from '@common-packages/utils';

import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';
import { CONNECTICUT_JURISDICTION_ID } from '../../shared/constants';
import config from '../../config';

export const getConsolidationWorkspaceNavigator = ({
  forms,
  consolidationJurisdictionDescription,
  taxYear,
  period,
  filingTypeId,
  entityId,
  jurisdictionId,
  consolidationId,
  isClientTtiAvailable,
  isPartnership,
  navigatorEFileData,
}) => {
  const contextParams = {
    taxYear,
    period,
    filingTypeId,
    entityId,
    jurisdictionId,
    businessEntityId: consolidationId,
  };

  const jurisdiction = consolidationJurisdictionDescription
    ? consolidationJurisdictionDescription
        .split(' ')
        .reduce(
          (acc, value) => [...acc, value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase()],
          [],
        )
        .join(' ')
    : '';

  const withContextParams = routeParams =>
    getContextAwareUrlParams({
      ...contextParams,
      ...routeParams,
    });

  const isLegacyInformationalDataHidden = ({ taxYear, period }) =>
    (isTaxYearAfterOr2021(taxYear) && (isAnnualPeriod(period) || isExtensionPeriod(period))) ||
    (isTaxYearAfterOr2022(taxYear) && isOneOfEstimationPeriods(period));

  return [
    {
      name: 'Filing Positions',
      type: 'folder',
      children: [
        {
          name: 'Filing Decisions',
          id: 'consolidated-filing-decisions-v2',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: true,
        },
        {
          name: 'Filing Groups in this Consolidation',
          id: 'consolidation-filing-groups',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Filing Groups',
          id: 'filing-groups',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        config.DISPLAY_SETUP_CONSOLIDATED_RETURNS
          ? {
              name: 'Setup Tax Returns',
              id: 'setup-tax-returns',
              type: 'screen',
              routeParams: withContextParams(),
              isHandledByPermissionRules: true,
            }
          : null,
        {
          name: 'Member Returns',
          id: 'member-returns',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: true,
        },
        isTaxYearAfterOr2021(taxYear)
          ? {
              name: 'Return Status Tracker Details',
              id: 'return-status-tracker',
              type: 'screen',
              isHandledByPermissionRules: true,
            }
          : null,
      ].filter(navigator => navigator),
    },
    {
      name: 'Workpapers',
      type: 'folder',
      loadingKey: 'isFetchingWorkpapers',
      children: [
        ...(!isTaxYearBefore2020(taxYear)
          ? [
              {
                name: 'State Common Information',
                type: 'screen',
                id: 'state-common-information',
                isHandledByPermissionRules: true,
                routeParams: withContextParams(),
              },
            ]
          : []),
        ...(isLegacyInformationalDataHidden({ taxYear, period })
          ? []
          : [
              {
                name: 'Informational Data (Legacy)',
                id: 'consolidation-informational-data',
                type: 'screen',
                isHandledByPermissionRules: true,
              },
            ]),

        {
          name: 'Informational Data',
          id: 'consolidation-informational-data-v2',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Global Member Informational Data',
          id: 'global-member-informational-data',
          type: 'screen',
          isHandledByPermissionRules: true,
          routeParams: withContextParams(),
        },
        {
          name: 'Member Informational Data',
          type: 'screen',
          id: 'member-informational-data',
          isHandledByPermissionRules: true,
          routeParams: withContextParams(),
        },
        ...(isPartnership && jurisdictionId === CONNECTICUT_JURISDICTION_ID
          ? [
              {
                name: 'CT Partnership Schedule B',
                type: 'screen',
                id: 'custom-screen',
                isHandledByPermissionRules: true,
                routeParams: withContextParams({
                  customScreenName: 'ct-pship-sch-b',
                }),
              },
              {
                name: 'CT Partnership Schedule C',
                type: 'screen',
                id: 'custom-screen',
                isHandledByPermissionRules: true,
                routeParams: withContextParams({
                  customScreenName: 'ct-pship-sch-c',
                }),
              },
            ]
          : []),
      ],
    },
    {
      name: 'Forms',
      type: 'folder',
      loadingKey: 'isFetchingForms',
      children: [
        {
          name: 'Federal As-Filed Return',
          id: 'federal-as-filed',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: false,
        },
        {
          name: 'Federal Pro Forma Return',
          id: 'federal-proforma',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: false,
        },
        ...forms.map(({ formName, formId }) => ({
          name: formName,
          id: 'view-print-tax-return',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: true,
          routeQueryString: {
            formId,
          },
        })),
      ],
    },
    ...(navigatorEFileData.isEFiling
      ? [
          {
            name: 'E-File',
            type: 'folder',
            children: [
              {
                name: 'Current Return Filing',
                id: 'current-return-filing',
                type: 'screen',
                isHandledByPermissionRules: true,
                isDisabled: !navigatorEFileData.isEFileReady,
              },
              {
                name: 'E-File Errors',
                id: 'efile-errors',
                type: 'screen',
                isHandledByPermissionRules: true,
                isDisabled: !navigatorEFileData.isEFileReady,
              },
            ],
          },
        ]
      : []),
    {
      name: 'Proforma',
      type: 'folder',
      children: [
        {
          name: 'Pro-Forma Process',
          id: 'proforma-process',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Federal and Proforma Processes',
          id: 'federal-proforma-processes',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
      ],
    },
    {
      name: 'Reports',
      type: 'folder',
      children: [
        {
          name: 'Affiliations Schedule',
          id: 'state-851-affiliate-data',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: `${jurisdiction} Consolidated Report`,
          id: 'consolidation-consolidated-report',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Manual Adjustments Report',
          id: 'consolidation-manual-adjustment-report',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Attachment Details Report',
          id: 'view-print-attachment-details',
          type: 'screen',
          routeParams: withContextParams(),
          isHandledByPermissionRules: true,
        },
        ...(isTaxYearAfter2022(taxYear)
          ? []
          : [
              {
                name: 'E-File Transmission Status',
                id: 'efile-transmission-status-report',
                type: 'screen',
                isHandledByPermissionRules: true,
              },
            ]),
        {
          name: 'Federal Filing Availability',
          id: 'efile-federal-return-availability-report',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        ...(isTaxYearAfter2022(taxYear) || !isClientTtiAvailable
          ? []
          : [
              {
                name: 'E-File XML Comparison Report',
                id: 'efile-xml-comparison-report',
                type: 'screen',
                isHandledByPermissionRules: true,
              },
            ]),
      ],
    },
    {
      name: 'Tools',
      type: 'folder',
      children: [
        {
          name: 'Consolidated Report  - TRG report 8313',
          id: 'trg-823',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Diagnostics',
          id: 'diagnostics',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Generic Account Data Upload',
          id: 'account-data-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Informational Data Upload',
          id: 'informational-data-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Mult Data Upload',
          id: 'mult-data-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Filing Group Members Upload',
          id: 'filing-group-members-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Manual Calculations',
          id: 'manual-calculations',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Attachments',
          id: 'attachments',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
        {
          name: 'Federal File Upload',
          id: 'gtw-file-upload',
          type: 'screen',
          isHandledByPermissionRules: true,
        },
      ],
    },
  ];
};
