import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Button } from '@pwc/appkit-react';

import { selectJurisdiction } from '../store/actions';
import {
  taxYearSelector,
  jurisdictionsOptionsSelector,
  isFetchingJurisdictionsSelector,
  jurisdictionIdSelector,
} from '../store/selectors';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../shared/editMode';
import DevelopmentTaxYearDropdown from '../developmentTaxYearDropdown.container';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import headerStyles from '../../shared/displayComponents/headerWithParamDropdowns/styles.module.scss';

import {
  fetchDefaultBinderItems,
  fetchBinderItemTypes,
  fetchForms,
  updateBinderItems,
} from './store/actions';
import {
  formOptionsSelector,
  isFetchingFormsSelector,
  binderItemTypeOptionsSelector,
  isFetchingBinderItemTypesSelector,
  binderItemsSelector,
  isUpdatingBinderItemsSelector,
  isFetchingBinderItemsSelector,
} from './store/selectors';
import getColumnDefinitions from './defaultBinders.columnsDefinitions';
import styles from './defaultBinders.module.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const DefaultBinders = ({
  taxYear,

  jurisdictionId,
  selectJurisdiction,

  jurisdictionOptions,
  isFetchingJurisdictions,

  fetchForms,
  formOptions,
  isFetchingForms,

  fetchBinderItemTypes,
  binderItemTypeOptions,
  isFetchingBinderItemTypes,

  binderItems,
  isFetchingBinderItems,
  fetchDefaultBinderItems,
  updateBinderItems,
  isUpdatingBinderItems,
}) => {
  useEffect(() => {
    fetchBinderItemTypes();
  }, [fetchBinderItemTypes]);

  const isContextReady = taxYear && jurisdictionId;

  useEffect(() => {
    if (isContextReady) {
      fetchForms({ taxYear, jurisdictionId });
      fetchDefaultBinderItems({ taxYear, jurisdictionId });
    }
  }, [fetchForms, fetchDefaultBinderItems, isContextReady, taxYear, jurisdictionId]);

  const isLoading =
    !isContextReady ||
    isFetchingForms ||
    isFetchingBinderItemTypes ||
    isFetchingBinderItems ||
    isUpdatingBinderItems ||
    isFetchingJurisdictions;

  const onSave = useCallback(
    async changes => {
      await updateBinderItems({ taxYear, jurisdictionId, changes });

      fetchDefaultBinderItems({ taxYear, jurisdictionId });
    },
    [updateBinderItems, fetchDefaultBinderItems, taxYear, jurisdictionId],
  );

  const {
    navigationPrompt,
    addRow,
    updateRow,
    deleteRow,
    clonedRowData,
    editModeButtons,
    isInEditMode,
    setIsInEditMode,
    onGridReady,
    gridApi,
  } = useRowEditMode({
    onSave,
    rowData: binderItems,
    getUniqueRowId,
    editButtonDisabled: isLoading,
    saveButtonDisabled: isLoading,
  });

  const addBinderItem = useCallback(() => {
    const getMaxDisplayOrder = gridApi => {
      let maxDisplayOrder = 0;
      gridApi.forEachNode(({ data }) => {
        maxDisplayOrder = Math.max(maxDisplayOrder, data.displayOrder);
      });
      return maxDisplayOrder;
    };

    setIsInEditMode(true);
    const newRow = {
      rowId: uuid(),
      binderItemType: 1,
      formId: formOptions.length ? formOptions[0].value : null,
      displayOrder: getMaxDisplayOrder(gridApi) + 10,
      addForEachMember: false,
    };

    addRow(newRow);
  }, [setIsInEditMode, addRow, gridApi, formOptions]);

  const removeBinderItem = useCallback(
    (...args) => {
      setIsInEditMode(true);
      deleteRow(...args);
    },
    [setIsInEditMode, deleteRow],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        isInEditMode,
        formOptions,
        binderItemTypeOptions,
        updateRow,
        deleteRow: removeBinderItem,
      }),
    [updateRow, removeBinderItem, binderItemTypeOptions, formOptions, isInEditMode],
  );

  return (
    <>
      {navigationPrompt}
      <div className={headerStyles.flexSpaceBetween}>
        <div className={headerStyles.headerParam}>
          <DevelopmentTaxYearDropdown />
          <ParamDropdown
            label="Jurisdiction"
            options={jurisdictionOptions}
            value={jurisdictionId}
            handleChange={selectJurisdiction}
            isBusy={isFetchingJurisdictions}
          />
        </div>
        <div className={styles.buttonGroup}>
          <Button size="lg" className="add-button" disabled={isLoading} onClick={addBinderItem}>
            Add Binder Item
          </Button>
          {editModeButtons}
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            isGridLoading={isLoading}
            onGridReady={onGridReady}
            columnDefs={columnDefinitions}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            areHeaderCellBordersEnabled
            suppressCellFocus={!isInEditMode}
          />
        </div>
      </div>
    </>
  );
};

DefaultBinders.propTypes = {
  taxYear: PropTypes.string,

  jurisdictionId: PropTypes.string,
  selectJurisdiction: PropTypes.func.isRequired,

  jurisdictionOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingJurisdictions: PropTypes.bool.isRequired,

  fetchBinderItemTypes: PropTypes.func.isRequired,
  binderItemTypeOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingBinderItemTypes: PropTypes.bool.isRequired,

  fetchForms: PropTypes.func.isRequired,
  formOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingForms: PropTypes.bool.isRequired,

  binderItems: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.string,
      binderItemType: PropTypes.number.isRequired,
      formId: PropTypes.string.isRequired,
      displayOrder: PropTypes.number.isRequired,
      orgId: PropTypes.string,
      orgName: PropTypes.string,
    }).isRequired,
  ).isRequired,
  isFetchingBinderItems: PropTypes.bool.isRequired,
  fetchDefaultBinderItems: PropTypes.func.isRequired,
  updateBinderItems: PropTypes.func.isRequired,
  isUpdatingBinderItems: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),

    jurisdictionOptions: jurisdictionsOptionsSelector(state),
    isFetchingJurisdictions: isFetchingJurisdictionsSelector(state),

    formOptions: formOptionsSelector(state),
    isFetchingForms: isFetchingFormsSelector(state),

    binderItemTypeOptions: binderItemTypeOptionsSelector(state),
    isFetchingBinderItemTypes: isFetchingBinderItemTypesSelector(state),

    binderItems: binderItemsSelector(state),
    isFetchingBinderItems: isFetchingBinderItemsSelector(state),
    isUpdatingBinderItems: isUpdatingBinderItemsSelector(state),
  }),
  {
    fetchDefaultBinderItems,
    fetchBinderItemTypes,
    fetchForms,
    updateBinderItems,
    selectJurisdiction,
  },
)(DefaultBinders);
