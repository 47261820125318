import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@pwc/appkit-react';

class CheckboxFormik extends React.PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.bool,
    }).isRequired,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    customChangeHandler: PropTypes.func,
    wrapperClassName: PropTypes.string,
    inverseValue: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    customChangeHandler: () => null,
    field: {
      value: false,
    },
    inverseValue: false,
  };

  handleToggle = event => {
    const {
      field: { name },
      form: { setFieldValue },
      customChangeHandler,
      inverseValue,
    } = this.props;

    const { checked } = event.target;
    setFieldValue(name, inverseValue ? !checked : checked);
    customChangeHandler(name, checked);
  };

  renderCheckbox = () => {
    const {
      field: { value },
      className,
      label,
      disabled,
      inverseValue,
    } = this.props;
    return (
      <Checkbox
        className={className}
        checked={inverseValue ? !value : value || false}
        onChange={this.handleToggle}
        disabled={disabled}
      >
        {label}
      </Checkbox>
    );
  };

  render() {
    const { wrapperClassName } = this.props;
    return wrapperClassName ? (
      <div className={wrapperClassName}>{this.renderCheckbox()}</div>
    ) : (
      this.renderCheckbox()
    );
  }
}

export default CheckboxFormik;
