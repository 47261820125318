import { useQueryClient } from 'react-query';

import { HttpMethods } from '../enums';
import { QueryError, QueryKeys } from '../queries';
import {
  RefreshFederalData,
  TaxYear,
  ResetErrorCountRequest,
  FederalFilingGroup,
  FederalEntity,
} from '../../../../common/types';

import { useCustomMutation } from '.';

interface FilingGroupsSendMessageRequest {
  filingGroupIds: string[];
  taxYear: TaxYear | null;
}

export const useMutationRefreshFederalData = () =>
  useCustomMutation<null, QueryError, RefreshFederalData>({
    handleData: ({ taxYear, entitiesOrGroups, isRequestingGTWFilesOnly }) => ({
      url: `/api/admin/support/federal-data-diagnostics/refresh/entitiesOrGroups`,
      method: HttpMethods.POST,
      body: {
        taxYear,
        entitiesOrGroups,
        isRequestingGTWFilesOnly,
      },
    }),
    successMessage: 'Refreshing federal data for federal entities',
    errorMessage: 'Refreshing federal data failed for federal entities',
  });

export const useMutationFilingGroupsSendMessage = () =>
  useCustomMutation<null, QueryError, FilingGroupsSendMessageRequest>({
    handleData: ({ filingGroupIds, taxYear }) => ({
      url: `/api/admin/support/federal-data-diagnostics/filinggroups/sendmessage`,
      method: HttpMethods.POST,
      body: {
        taxYear,
        filingGroupIds,
      },
    }),
    successMessage: 'Sent message about filing groups',
    errorMessage: 'Message about filing groups failed to send',
  });

export const useMutationResetErrorCount = () => {
  const queryClient = useQueryClient();
  return useCustomMutation<null, QueryError, ResetErrorCountRequest>({
    handleData: ({ taxYear, period, entitiesOrGroups }) => ({
      url: `/api/admin/support/federal-data-diagnostics/reset-error-count`,
      method: HttpMethods.POST,
      body: {
        taxYear,
        period,
        entitiesOrGroups,
      },
    }),
    onMutate: async variables => {
      const {
        taxYear,
        period,
        entitiesOrGroups: { orgIds, filingGroupIds },
      } = variables;
      if (orgIds) {
        const params = { taxYear, period, showFederalEntitiesOnly: true };
        await queryClient.cancelQueries([QueryKeys.EntitiesMaintenance.Entities, params]);
        queryClient.setQueryData<FederalEntity[] | undefined>(
          [QueryKeys.EntitiesMaintenance.Entities, params],
          oldEntities => {
            return oldEntities?.map(entity =>
              orgIds?.includes(entity.orgId) && entity.gtwRefreshFailCtr
                ? { ...entity, gtwRefreshFailCtr: 0 }
                : entity,
            );
          },
        );
      }
      if (filingGroupIds) {
        await queryClient.cancelQueries([QueryKeys.FederalDataDiagnostics.FilingGroup]);
        queryClient.setQueryData<FederalFilingGroup[] | undefined>(
          [QueryKeys.FederalDataDiagnostics.FilingGroup],
          oldFilingGroups => {
            return oldFilingGroups?.map(filingGroup =>
              filingGroupIds?.includes(filingGroup.filingGroupId) && filingGroup.gtwRefreshFailCount
                ? { ...filingGroup, gtwRefreshFailCount: 0 }
                : filingGroup,
            );
          },
        );
      }
    },
    onError: (error: QueryError, variables: ResetErrorCountRequest) => {
      const {
        taxYear,
        period,
        entitiesOrGroups: { orgIds, filingGroupIds },
      } = variables;
      if (orgIds) {
        const params = { taxYear, period, showFederalEntitiesOnly: true };
        queryClient.resetQueries([QueryKeys.EntitiesMaintenance.Entities, params]);
      }
      if (filingGroupIds) {
        queryClient.resetQueries([QueryKeys.FederalDataDiagnostics.FilingGroup]);
      }
    },
    successMessage: 'Error Count Reset',
    errorMessage: 'Error Count Not Reset',
  });
};
