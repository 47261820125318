import React, { useCallback, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs, Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';

import SharedHeader from '../../../shared/displayComponents/header/header.component';
import { useQueryStatusEntityInformationDiagnostics } from '../../../shared/queries/entityInformationDiagnostics';
import { globalContextSelector } from '../../../shared/store/selectors';
import Loading from '../../../shared/displayComponents/loading.component';
import { GlobalContextParams } from '../../../../../common/types';
import { QueryKeys } from '../../../shared/queries';

import styles from './entityInformationDiagnostics.module.scss';
import { AllEntities, RecentRefreshes } from './tables';

enum TabsTypes {
  RecentRefreshes = 'RecentRefreshes',
  AllEntities = 'Entities',
}

const tabsDefinitions = [
  { type: TabsTypes.RecentRefreshes, label: 'Recent Refreshes' },
  { type: TabsTypes.AllEntities, label: 'Entities' },
];

const getActiveTabComponent = (activeTab: TabsTypes) => {
  switch (activeTab) {
    case TabsTypes.RecentRefreshes:
      return <RecentRefreshes />;
    case TabsTypes.AllEntities:
      return <AllEntities />;
    default:
      throw new Error('Unsupported tab type');
  }
};

const EntityInformationDiagnostics = () => {
  const [activeTab, setActiveTab] = useState(TabsTypes.RecentRefreshes);
  const queryClient = useQueryClient();

  const handleTabChange = useCallback((_: ChangeEvent, tab: TabsTypes) => setActiveTab(tab), []);

  const { taxYear, period }: GlobalContextParams = useSelector(globalContextSelector).params;
  const { data: status, isFetching: isFetchingStatus } = useQueryStatusEntityInformationDiagnostics(
    {
      taxYear,
      enabled: Boolean(taxYear && period),
    },
  );

  const onRefreshClick = useCallback(() => {
    queryClient.resetQueries(QueryKeys.EntityInformationDiagnostics.Status);
    switch (activeTab) {
      case TabsTypes.AllEntities:
        queryClient.resetQueries(QueryKeys.EntityInformationDiagnostics.AllEntities);
        return;
      case TabsTypes.RecentRefreshes:
        queryClient.resetQueries(QueryKeys.EntityInformationDiagnostics.RecentRefreshes);
        return;
      default:
        throw new Error('Unsupported tab type');
    }
  }, [queryClient, activeTab]);

  return (
    <>
      <SharedHeader title="Refresh Entity Information Diagnostics">
        <div className={styles.subheader}>
          <span> Refreshes In The Last 24 Hours </span>
          <Loading small isLoading={isFetchingStatus}>
            <span> Total: {status?.total} </span>
            <span> Complete: {status?.completed} </span>
            <span> In Progress: {status?.inProgress} </span>
            <span> Unsuccessful: {status?.unsuccessful} </span>
          </Loading>
        </div>
      </SharedHeader>
      <div className={`row ${styles.mainPage}`}>
        <div className={styles.tabSection}>
          <Tabs className={styles.tabs} value={activeTab} size="md" onChange={handleTabChange}>
            {tabsDefinitions.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
          <Button
            size="lg"
            onClick={onRefreshClick}
            disabled={isFetchingStatus}
            className={styles.refreshDataBtn}
            kind="secondary"
          >
            Reload Table
          </Button>
        </div>
        <div className={styles.tableFormat}> {getActiveTabComponent(activeTab)}</div>
      </div>
    </>
  );
};

export default EntityInformationDiagnostics;
