import React, { PureComponent } from 'react';
import { Formik } from 'formik';

import EditParamModal from './editParamModal.container';
import { paramFormIntitialValues } from './constants';

const validate = () => null;

class EditParamModalFormWrapper extends PureComponent {
  renderModal = formikProps => <EditParamModal {...this.props} {...formikProps} />;

  render() {
    return (
      <Formik initialValues={paramFormIntitialValues} validateOnBlur={false} validate={validate}>
        {this.renderModal}
      </Formik>
    );
  }
}

export default EditParamModalFormWrapper;
