import { GetFederalSubmissionFileParams } from '../../../../../common/types';

export const generateFederalFileUrl = ({
  proformaImportId,
  filePath,
  federalReturnOption,
  taxYear,
}: GetFederalSubmissionFileParams) =>
  `/api/efile-v2/federal-submission-file/${taxYear}/${proformaImportId}/${federalReturnOption}/${encodeURIComponent(
    filePath,
  )}`;

export const generateStateFileUrl = ({ id, path }: { id: string; path: string }) =>
  `/api/efile-v2/state-submission-file/${id}/${encodeURIComponent(path)}`;
