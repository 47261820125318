const getCorrectValueIfStringNullOrStringUndefined = require('../utils/getCorrectValueIfStringNullOrStringUndefined');

const swapArrayItems = (items, firstIndex, secondIndex) => {
  const indexes = [firstIndex, secondIndex];

  const anyIndexMissing = indexes.some(
    index => index === undefined || index === null, // eslint-disable-line no-undefined
  );
  const anyIndexOutOfRange = indexes.some(index => index < 0 || index >= items.length);

  if (anyIndexMissing || anyIndexOutOfRange) {
    return items;
  }

  const results = items.slice();
  const firstItem = items[firstIndex];
  results[firstIndex] = items[secondIndex];
  results[secondIndex] = firstItem;

  return results;
};

const filingTypes = {
  SEPARATE: 'SEPARATE',
  CONSOLIDATED: 'CONSOLIDATED',
};

const globalContextFactory = contextData => {
  const taxYear = getCorrectValueIfStringNullOrStringUndefined(contextData.taxYear);
  const period = getCorrectValueIfStringNullOrStringUndefined(contextData.period);
  const filingTypeId = getCorrectValueIfStringNullOrStringUndefined(contextData.filingTypeId);
  const businessEntityId = getCorrectValueIfStringNullOrStringUndefined(
    contextData.businessEntityId,
  );
  const consolidationId = getCorrectValueIfStringNullOrStringUndefined(contextData.consolidationId);
  const entityId = getCorrectValueIfStringNullOrStringUndefined(contextData.entityId);
  const jurisdictionId = getCorrectValueIfStringNullOrStringUndefined(contextData.jurisdictionId);

  // ok, this context can already be "filtered" as in only orgId is present
  // (which will most likely happen on backend, tbh)
  // or not - either entityId or consolidationId is present
  // and entity seems like a better name for "either org or consolidation"
  // for now let's call it businessEntity
  if (businessEntityId && (consolidationId || entityId)) {
    throw new Error(
      'DONT MIX businessEntityId and entityId/consolidationId. These are two different concepts.',
    );
  }

  const isConsolidated = filingTypeId === filingTypes.CONSOLIDATED;
  const isSeparate = filingTypeId === filingTypes.SEPARATE;

  const isConsolidatedYN = isConsolidated ? 'Y' : 'N';
  const isSeparateYN = isSeparate ? 'Y' : 'N';

  const params = {
    taxYear,
    period,
    filingTypeId,
    businessEntityId: isConsolidated
      ? businessEntityId || consolidationId
      : businessEntityId || entityId,
    jurisdictionId,
  };

  const getOverridenApiRouteParamString = ({
    taxYear = params.taxYear,
    period = params.period,
    filingTypeId = params.filingTypeId,
    businessEntityId = params.businessEntityId,
    jurisdictionId = params.jurisdictionId,
  } = {}) => `${taxYear}/${period}/${filingTypeId}/${businessEntityId}/${jurisdictionId}`;
  const apiRouteParamString = getOverridenApiRouteParamString();

  const isReady = Boolean(
    params.taxYear &&
      params.period &&
      params.filingTypeId &&
      params.businessEntityId &&
      params.jurisdictionId,
  );

  return {
    ...contextData,
    params,
    getOverridenApiRouteParamString,
    apiRouteParamString,
    isConsolidated,
    isConsolidatedYN,
    isSeparate,
    isSeparateYN,
    isReady,
  };
};

const GLOBAL_CONTEXT_ROUTE_PARAMS =
  ':taxYear/:period/:filingTypeId/:businessEntityId/:jurisdictionId';

const CalcStatuses = {
  DIRTY: 1,
  IN_PROGRESS: 2,
  CLEAN: 3,
};

const mapPeriods = period => {
  const periodsObj = {
    0: 'PERIOD_TYPE_ANNUAL',
    1: 'PERIOD_TYPE_ESTIMATE1',
    2: 'PERIOD_TYPE_ESTIMATE2',
    3: 'PERIOD_TYPE_ESTIMATE3',
    4: 'PERIOD_TYPE_ESTIMATE4',
    5: 'PERIOD_TYPE_EXTENSION',
  };

  return (typeof period === 'string' || typeof period === 'number') && periodsObj[Number(period)]
    ? periodsObj[Number(period)]
    : null;
};

const FilingMethods = {
  EFILE: 'efile',
  PAPER: 'paper',
  NON_FILING: 'non-filing',
  NONE: '',
};

module.exports = {
  swapArrayItems,
  globalContextFactory,
  filingTypes,
  GLOBAL_CONTEXT_ROUTE_PARAMS,
  CalcStatuses,
  mapPeriods,
  FilingMethods,
};
