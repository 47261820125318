import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import { AuthorizedProformaRoute } from './routeConfiguration/proformaRoute.container';
import ProformaProcess from './proformaProcess/proformaProcess.container';

const ProformaRoutes = () => (
  <Switch>
    <AuthorizedProformaRoute exact path={Routes.proformaProcess.MAIN} component={ProformaProcess} />
  </Switch>
);

export default ProformaRoutes;
