import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConsolidationId,
  JurisdictionId,
  TaxYear,
  FilingTypeId,
  EntityId,
  Period,
  GlobalContext,
} from '../../../../common/types';
import {
  selectTaxYear,
  selectPeriod,
  selectFilingType,
  selectEntity,
  selectConsolidation,
  selectJurisdiction,
} from '../../shared/store/actions';
import { globalContextSelector } from '../../shared/store/selectors';
import { filingTypes } from '../../shared/constants';

interface TaxReturn {
  taxYear: TaxYear;
  period: Period;
  filingTypeId: FilingTypeId;
  entityId?: EntityId | null;
  consolidationId?: ConsolidationId | null;
  jurisdictionId: JurisdictionId;
}

export const useSyncGlobalContextWithTaxReturn = (taxReturn?: TaxReturn) => {
  const dispatch = useDispatch();
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  useEffect(() => {
    if (!taxReturn) {
      return;
    }

    if (globalContext.taxYear !== taxReturn.taxYear) {
      dispatch(selectTaxYear(taxReturn.taxYear));
    }

    if (globalContext.period !== taxReturn.period) {
      dispatch(selectPeriod(taxReturn.period));
    }

    if (globalContext.filingTypeId !== taxReturn.filingTypeId) {
      dispatch(selectFilingType(taxReturn.filingTypeId));
    }

    if (
      taxReturn.filingTypeId === filingTypes.SEPARATE &&
      globalContext.entityId !== taxReturn.entityId
    ) {
      dispatch(selectEntity(taxReturn.entityId));
    }

    if (
      taxReturn.filingTypeId === filingTypes.CONSOLIDATED &&
      globalContext.consolidationId !== taxReturn.consolidationId
    ) {
      dispatch(selectConsolidation(taxReturn.consolidationId));
    }

    if (globalContext.jurisdictionId !== taxReturn.jurisdictionId) {
      dispatch(selectJurisdiction(taxReturn.jurisdictionId));
    }
    // ignore state of Global Context for this
  }, [taxReturn, dispatch]);
};
