import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { TEMP_STORE } from '../shared/store/storeHelpers';

import { ReturnStatusTrackerDetails } from './returnStatusTrackerDetails.container';

const ReturnStatusTrackerDetailsPage = connect((state: TEMP_STORE) => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(GlobalContextPage);

const ReturnStatusTrackerDetailsRoutes = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.returnStatusTracker.MAIN}
    component={ReturnStatusTrackerDetails}
    wrapper={ReturnStatusTrackerDetailsPage}
    hideGlobalContext
  />
);

export default ReturnStatusTrackerDetailsRoutes;
