import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Panel, List } from '@pwc/appkit-react';

import { Notification } from '../../../common/types';

import NotificationItem from './notificationItem.component';
import styles from './notificationPanel.module.scss';

interface NotificationPanelParams {
  notifications: Notification[];
  onHide: () => void;
  onClear: (id: string) => void;
  onClearAll: () => void;
}

const NotificationPanelCloseButton = ({ onHide }: { onHide: () => void }) => (
  <span onClick={onHide} className="appkiticon icon-close-fill" />
);

const NotificationPanel = ({
  notifications,
  onHide,
  onClear,
  onClearAll,
}: NotificationPanelParams) => (
  <div className={styles.notificationPanelWrapper}>
    <OutsideClickHandler onOutsideClick={onHide}>
      <Panel
        className={styles.notificationPanel}
        title="Notifications"
        renderRight={() => <NotificationPanelCloseButton onHide={onHide} />}
      >
        <List className={styles.notificationList}>
          {notifications.length ? (
            notifications.map(notification => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onClear={onClear}
              />
            ))
          ) : (
            <div className={styles.notificationEmptyPage}>
              <i className={`${styles.emptyPageIcon} appkiticon icon-notification-fill`} />
              <p className={styles.emptyPageText}>No notifications</p>
            </div>
          )}
        </List>
        {Boolean(notifications.length) && (
          <div className={styles.notificationDismiss}>
            <span onClick={onClearAll}>Dismiss All</span>
          </div>
        )}
      </Panel>
    </OutsideClickHandler>
  </div>
);

export default NotificationPanel;
