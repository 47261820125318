import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import {
  BreadcrumbRoute,
  StaticHomeBreadcrumb,
} from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const ConsolidationBreadcrumbRoutes = () => (
  <>
    <StaticHomeBreadcrumb routePath={Routes.complianceStateFilingGroups.MAIN} />
    <BreadcrumbRoute path={Routes.filingMembers} />
  </>
);

export default ConsolidationBreadcrumbRoutes;
