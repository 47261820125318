import { useSelector } from 'react-redux';

import { globalContextSelector } from '../../../shared/store/selectors';
import { useQueryGetValidationErrors as useQueryGetTtiValidationResult } from '../../../shared/queries/eFileV2';
import { GlobalContext } from '../../../../../common/types';

import useBinder from './useBinder.hook';

const useGetTtiValidationErrors = (enabled?: boolean, enabledTti?: boolean) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const { taxYear, period, jurisdictionId, businessEntityId } = globalContext.params;
  const { isSeparateYN, isReady: isGlobalContextReady } = globalContext;
  const { sltBinderId } = useBinder(enabledTti);
  const {
    data: ttiValidationErrors,
    isFetching: isFetchingTtiValidation,
    isFetched: areTtiValidationErrorsFetched,
  } = useQueryGetTtiValidationResult({
    params: {
      taxYear: taxYear || '',
      period: period || '',
      jurisdictionId: jurisdictionId || '',
      isSeparateYN,
      businessEntityId: businessEntityId || '',
      sltBinderId,
    },
    enabled: enabled && Boolean(isGlobalContextReady && isSeparateYN),
  });

  return {
    ttiValidationErrors,
    isFetchingTtiValidation,
    areTtiValidationErrorsFetched,
  };
};

export default useGetTtiValidationErrors;
