import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'leid',
    width: 60,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Type',
    field: 'entityType',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Legal Entity Id',
    field: 'hoLeid',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars BSLA',
    field: 'starsBsla',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars Account',
    field: 'starsAcct',
    width: 100,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'TL / TLDET',
    field: 'tlCode',
    width: 80,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Stars Description',
    field: 'starsDescription',
    width: 350,
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Tax Amount',
    field: 'sumtaxamt',
    width: 100,
  },
];

export default columnDefinitions;
