import { useEffect } from 'react';

import useFetch from '../../shared/hooks/useFetch.hook';
import { httpGetAndParse } from '../../utils/fetchService';

const action = ({ taxYear, period, entityId, jurisdictionId, taxType }) =>
  httpGetAndParse({
    route: `/api/shared/property-summary/${taxYear}/${period}/${entityId}/${jurisdictionId}/${taxType}`,
    errorMessage: 'Fetching property summary details failed',
  });

export const createUseFetchPropertySummaryHook = useFetch => {
  const useFetchPropertySummary = ({
    taxYear,
    period,
    entityId,
    jurisdictionId,
    taxType,
    isContextReady,
  }) => {
    const {
      data: propertySummary,
      isFetching: isFetchingPropertySummary,
      fetch: fetchPropertySummary,
    } = useFetch({ action });

    useEffect(() => {
      if (isContextReady) {
        fetchPropertySummary({ taxYear, period, entityId, jurisdictionId, taxType });
      }
    }, [fetchPropertySummary, isContextReady, taxYear, period, entityId, jurisdictionId, taxType]);

    return {
      propertySummary,
      isFetchingPropertySummary,
    };
  };
  return useFetchPropertySummary;
};

export default createUseFetchPropertySummaryHook(useFetch);
