import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingContextSelector } from '../entity/store/selectors';

import ManualCalculations from './manualCalculations.container';

const ManualCalculationsPage = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
}))(GlobalContextPage);

const ManualCalculationsRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.manualCalculations.MAIN}
    wrapper={ManualCalculationsPage}
    component={ManualCalculations}
    hideGlobalContext
    disableBreadcrumbs
  />
);

export default ManualCalculationsRoute;
