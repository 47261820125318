import { dataTypes } from '@common-packages/shared-constants';

import { FIELD_ATTRIBUTES } from '../constants';

const allPossible = [
  dataTypes.CURRENCY,
  dataTypes.RATIO,
  dataTypes.INTEGER,
  dataTypes.DATE,
  dataTypes.BOOLEAN,
  dataTypes.STRING,
];

const fieldAttributesAvailability = {
  [FIELD_ATTRIBUTES.ABSOLUTE_VALUES]: [dataTypes.CURRENCY, dataTypes.INTEGER],
  [FIELD_ATTRIBUTES.FLIP_SIGN]: [dataTypes.CURRENCY, dataTypes.INTEGER],
  [FIELD_ATTRIBUTES.NEGATIVE]: [dataTypes.CURRENCY, dataTypes.RATIO, dataTypes.INTEGER],
  [FIELD_ATTRIBUTES.RATIO_AS_PERCENT]: [dataTypes.RATIO],
  [FIELD_ATTRIBUTES.SUPPRESS_COMMAS]: [dataTypes.CURRENCY, dataTypes.INTEGER],
  [FIELD_ATTRIBUTES.VALUE_IF_ZERO]: [dataTypes.CURRENCY, dataTypes.RATIO, dataTypes.INTEGER],
  [FIELD_ATTRIBUTES.DECIMAL_PLACES]: [dataTypes.RATIO],
  [FIELD_ATTRIBUTES.WRAP_TEXT]: [dataTypes.STRING],
  [FIELD_ATTRIBUTES.FIRST_ROW_INDT]: [dataTypes.STRING],
  [FIELD_ATTRIBUTES.FULL_ROW_COUNT]: [dataTypes.STRING],
  [FIELD_ATTRIBUTES.ROW_SPACING_INC]: [dataTypes.STRING],
  [FIELD_ATTRIBUTES.FORMATTING_STRING]: [
    dataTypes.CURRENCY,
    dataTypes.RATIO,
    dataTypes.INTEGER,
    dataTypes.DATE,
    dataTypes.STRING,
    dataTypes.BOOLEAN,
  ],
  [FIELD_ATTRIBUTES.APPEND_TEXT]: [
    dataTypes.CURRENCY,
    dataTypes.RATIO,
    dataTypes.INTEGER,
    dataTypes.STRING,
  ],
  [FIELD_ATTRIBUTES.CHAR_SPACING]: allPossible,
  [FIELD_ATTRIBUTES.FULLFIELD_PATTERN]: [
    dataTypes.CURRENCY,
    dataTypes.RATIO,
    dataTypes.INTEGER,
    dataTypes.DATE,
    dataTypes.STRING,
  ],
  [FIELD_ATTRIBUTES.FULLFIELD_LENGTH]: [dataTypes.CURRENCY, dataTypes.INTEGER, dataTypes.STRING],
  [FIELD_ATTRIBUTES.PRINT_FIRST_COPY_ONLY]: allPossible,
  [FIELD_ATTRIBUTES.PRINT_LAST_COPY_ONLY]: allPossible,
  [FIELD_ATTRIBUTES.UPPER_CASE]: allPossible,
  [FIELD_ATTRIBUTES.CHECK_BOX]: [dataTypes.BOOLEAN],
  [FIELD_ATTRIBUTES.FILTER_REG_EXP]: allPossible,
  [FIELD_ATTRIBUTES.SUPPRESS_DATASET_DEF_ID]: allPossible,
  [FIELD_ATTRIBUTES.SUPPRESS_FIELD_DATA_ITEM_DEF_ID]: allPossible,
};

const getFieldAttributesDisabled = ({ isFieldReady, dataItemType }) => name => {
  if (!isFieldReady || !fieldAttributesAvailability[name]) {
    return true;
  }

  return !fieldAttributesAvailability[name].includes(Number(dataItemType));
};

export default getFieldAttributesDisabled;
