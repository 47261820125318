import React from 'react';
import { connect } from 'react-redux';

import { customerPermissionsSelector } from '../store/selectors';
import { customerPermissionsPropTypes } from '../propTypes/customerPropTypes';

const withPermissions = WrappedComponent => {
  const PermissionsProvider = ({ customerPermissions, ...rest }) => (
    <WrappedComponent customerPermissions={customerPermissions} {...rest} />
  );

  PermissionsProvider.propTypes = {
    customerPermissions: customerPermissionsPropTypes,
  };

  return connect(state => ({
    customerPermissions: customerPermissionsSelector(state),
  }))(PermissionsProvider);
};

export default withPermissions;
