import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const activeTabSelector = ({ development }) => development.expressionBuilder.activeTab;
export const activeFormDataItemIdSelector = ({ development }) =>
  development.expressionBuilder.activeFormDataItemId;

export const isFetchingDataModelsSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.isFetchingDataModels
    : shared.dataModels.isFetchingDataModels;

export const dataModelsSelector = optionsSelectorFactory({
  listSelector: ({ shared, development }) =>
    development.expressionBuilder.isIgnoringCalculationDataChanges
      ? development.expressionBuilder.dataModels
      : shared.dataModels.dataModels,
  labelKey: 'name',
  valueKey: 'id',
});

export const dataModelIdSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.dataModelId
    : shared.dataModels.dataModelId;

export const dataModelSelector = createSelector(
  [dataModelsSelector, dataModelIdSelector],
  (dataModels, dataModelId) =>
    dataModels.find(dataModel => dataModel.value === dataModelId) || null,
);

export const isFetchingDatasetsSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.isFetchingDatasets
    : shared.dataModels.isFetchingDatasets;

export const datasetsSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.datasets
    : shared.dataModels.datasets;

export const datasetsOptionsSelector = optionsSelectorFactory({
  listSelector: datasetsSelector,
  labelKey: 'name',
  valueKey: 'id',
});

export const datasetIdSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.datasetId
    : shared.dataModels.datasetId;

export const datasetsOptionSelector = createSelector(
  [datasetsOptionsSelector, datasetIdSelector],
  (datasetsOptions, datasetId) => datasetsOptions.find(({ value }) => value === datasetId) || null,
);

export const dataItemsSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.dataItems
    : shared.dataModels.dataItems;

export const dataItemsOptionsSelector = optionsSelectorFactory({
  listSelector: dataItemsSelector,
  labelKey: 'name',
  valueKey: 'id',
});

export const isFetchingDataItemsSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.isFetchingDataItems
    : shared.dataModels.isFetchingDataItems;

export const dataItemIdSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.dataItemId
    : shared.dataModels.dataItemId;

export const dataItemsOptionSelector = createSelector(
  [dataItemsOptionsSelector, dataItemIdSelector],
  (dataItemsOptions, dataItemId) =>
    dataItemsOptions.find(({ value }) => value === dataItemId) || null,
);

export const dataItemDataTypeNameSelector = createSelector(
  [dataItemsSelector, dataItemIdSelector],
  (dataItems, dataItemId) =>
    dataItems.find(dataItem => dataItem.id === dataItemId)?.dataTypeName || null,
);

export const dataItemAliasNameSelector = createSelector(
  [dataItemsSelector, dataItemIdSelector],
  (dataItems, dataItemId) =>
    dataItems.find(dataItem => dataItem.id === dataItemId)?.aliasName || null,
);

export const pdfFieldsByPageSelector = ({ shared, development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges
    ? development.expressionBuilder.pdfFieldsByPage
    : shared.dataModels.pdfFieldsByPage;

export const isIgnoringCalculationDataChangesSelector = ({ development }) =>
  development.expressionBuilder.isIgnoringCalculationDataChanges;

export const splitPaneLeftWidthSelector = ({ development }) =>
  development.expressionBuilder.splitPaneLeftWidth;
