import React from 'react';
import Spinner from '@tls/ui-spinner';

import FaIcon from '../faIcon/faIcon.component';

import styles from './dataInfoSubText.module.scss';

interface DataInfoSubTextProps {
  textToRender: string;
  showIcon?: boolean;
  showSpinner?: boolean;
  subTextToRender?: string;
}

const DataInfoSubText = ({
  textToRender = '',
  showIcon = true,
  showSpinner = false,
  subTextToRender = '',
}: DataInfoSubTextProps) => (
  <div className={styles.dataInfoSubtext}>
    <div className={styles.dataInfoSubTextContainer}>
      {showIcon && (
        <FaIcon
          className={styles.infoSubTextCircleIcon}
          icon="info-circle"
          size={32}
          prefix="fal"
        />
      )}
      {showSpinner && (
        <span className={styles.infoSubTextSpinner}>
          <Spinner small />
        </span>
      )}
      <p className={styles.dataInfoSubTextParagraph}>{textToRender}</p>
    </div>
    <div>
      {Boolean(subTextToRender) && (
        <p className={styles.dataInfoSubTextParagraph}>{subTextToRender}</p>
      )}
    </div>
  </div>
);

export default DataInfoSubText;
