import { SelectOptionsResponse } from '@tls/slt-types';

import { QueryKeys, useCustomQuery } from './index';

export interface GenericCategoryQueryParams {
  taxYear: string;
  period: string;
  businessEntityId: string;
}

export const useQueryGenericCategoryComponentOptions = ({
  params: { taxYear, period, businessEntityId },
  enabled,
}: {
  params: GenericCategoryQueryParams;
  enabled: boolean;
}) =>
  useCustomQuery<SelectOptionsResponse>(
    [QueryKeys.GenericCategory.ComponentOptions, { taxYear, period, businessEntityId }],
    {
      url: `/api/shared/generic-category/component-options/${taxYear}/${period}/${businessEntityId}`,
      enabled,
      initialData: [],
      errorMessage: 'Fetching component options failed',
    },
  );
