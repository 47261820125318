import { httpGetAndParse } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from '.';

const fetchDataModelFields = ({ period, businessEntityId, dataModelId, filingTypeId }) =>
  httpGetAndParse({
    route: `/api/shared/state-common-information/${period}/${filingTypeId}/${businessEntityId}/${dataModelId}`,
  });

export const useQueryCommonDataModelFields = ({ params, enabled }) =>
  useCustomQuery([QueryKeys.StateCommonInformation.CommonDataModelFields, params], {
    enabled,
    defaultData: [],
    errorMessage: 'Fetching state common information form fields failed',
    customFetch: () => fetchDataModelFields(params),
  });
