import { connect } from 'react-redux';

import { GlobalContextValue } from '../../../shared/containerComponents/globalContext';
import { filingGroupDescSelector, isFetchingFilingGroupsDataSelector } from '../store/selectors';

export default connect(state => ({
  label: 'Filing Group',
  value: filingGroupDescSelector(state),
  isBusy: isFetchingFilingGroupsDataSelector(state),
}))(GlobalContextValue);
