import PropTypes from 'prop-types';

export const formDetailsProps = PropTypes.shape({
  formId: PropTypes.string,
  formName: PropTypes.string,
  formDescription: PropTypes.string,
  pdfName: PropTypes.string,
  swpFormId: PropTypes.string,
  swpCalcIteration: PropTypes.string,
});

export const filingAttributesProps = PropTypes.arrayOf(
  PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
);
