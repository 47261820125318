import { TOTAL_DESC } from '@common-packages/shared-constants';

const getOrgName = entityIdParam => {
  const entity = entityIdParam.paramData.find(entity => entity.value === entityIdParam.paramValue);
  return entity.orgName;
};

export const reportDataFormatter = (reportData, entityIdParam) =>
  reportData && reportData.length !== 0
    ? [
        ...reportData.map(row => ({
          ...row,
          orgId: entityIdParam.paramValue,
          orgName: getOrgName(entityIdParam),
        })),
        {
          orgId: TOTAL_DESC,
          isTotalRow: true,
          ...reportData.reduce(
            (acc, curr) => ({
              ...acc,
              amt: acc.amt + curr.amt,
            }),
            {
              amt: 0,
            },
          ),
        },
      ]
    : [];
