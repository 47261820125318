import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loading from '../loading.component';
import DataInfo from '../dataInfo/dataInfo.component';
import MountListenerWrapper from '../MountListenerWrapper.component';
import useBooleanState from '../../hooks/useBooleanState.hook';
import useFormScroll from '../../hooks/useFormScroll.hook';
import usePdfFormFieldsDetails from '../../hooks/usePdfFormFieldsDetails.hook';
import { getFields } from '../../../utils/pdfUtils';
import { FORM_WRAPPER_CLASS, NO_PDF_FORM_MESSAGE } from '../../constants';
import { formFieldType } from '../../propTypes/pdfPropTypes';
import ContextMenu from '../contextMenu/contextMenu.component';

import styles from './formView.module.scss';

const formContentClassNames = classNames(FORM_WRAPPER_CLASS, styles.pdfFormContent);
const noop = () => null;

const FormView = ({
  isSelectedWithDropdown,
  selectFormPageWithScroll,
  selectedDisplayFormValue,
  pdfFieldsByPage,
  fieldsValues = null,
  isFetchingFieldsValues = false,
  formHTMLReturnedError = '',
  className = '',
  formHTML = '',
  isLoading = false,
  selectedPage = 1,
  selectedField = {},
  selectedFormFieldName = null,
  fieldsValueKey = 'name', // setting default value to not override default value in usePdfFormFieldsDetails hook
  fieldsFilterKey = 'name', // setting default value to not override default value in usePdfFormFieldsDetails hook
  onFormFieldSelection = noop,
  onDoubleClickSelection = noop,
  handleCommandClick = noop,
  isShiftKeyUsedForSelecting = false,
  isCommandOrControlUsedForSelecting = false,
  isUsingCustomContextMenu = false,
  isUsingSameExpression = false,
}) => {
  const [isFormMounted, onFormMount, onFormUnmount] = useBooleanState(false);
  const parsedImage = formHTML ? JSON.parse(formHTML) : null;
  const disabled = !isFormMounted || isFetchingFieldsValues;

  const formRef = useFormScroll({
    isFormMounted,
    selectedPage,
    isSelectedWithDropdown,
    selectFormPageWithScroll,
  });

  const { isContextMenuActive, clickPosition, currentItemValue } = usePdfFormFieldsDetails({
    disabled,
    valueKey: fieldsValueKey,
    filterKey: fieldsFilterKey,
    fields: getFields({ formWrapperClass: FORM_WRAPPER_CLASS }),
    fieldsValues,
    selectedField,
    selectedFormFieldName,
    selectedDisplayFormValue,
    pdfFieldsByPage,
    handleFormFieldSelection: onFormFieldSelection,
    handleDoubleClickSelection: onDoubleClickSelection,
    handleCommandClick,
    isShiftKeyUsedForSelecting,
    isCommandOrControlUsedForSelecting,
    isUsingCustomContextMenu,
  });

  return (
    <div className={`${styles.formWrapper} ${className}`}>
      <div className={styles.formView}>
        <Loading isLoading={isLoading}>
          {parsedImage ? (
            <MountListenerWrapper onMount={onFormMount} onUnmount={onFormUnmount}>
              <div
                ref={formRef}
                className={formContentClassNames}
                dangerouslySetInnerHTML={parsedImage}
              />
            </MountListenerWrapper>
          ) : (
            <div className={styles.dataInfo}>
              <DataInfo
                textToRender={formHTMLReturnedError ? formHTMLReturnedError : NO_PDF_FORM_MESSAGE}
              />
            </div>
          )}
        </Loading>
        {isUsingCustomContextMenu && isContextMenuActive && (
          <ContextMenu
            clickPosition={clickPosition}
            currentItemValue={currentItemValue}
            selectDataItem={onFormFieldSelection}
            addSepExpression={onDoubleClickSelection}
            addConsolExpression={handleCommandClick}
            isUsingSameExpression={isUsingSameExpression}
          />
        )}
      </div>
    </div>
  );
};

FormView.propTypes = {
  className: PropTypes.string,
  formHTML: PropTypes.string,
  formHTMLReturnedError: PropTypes.string,
  isLoading: PropTypes.bool,
  numberOfPages: PropTypes.number,
  selectedPage: PropTypes.number,
  selectedDisplayFormValue: PropTypes.string,
  pdfFieldsByPage: PropTypes.arrayOf(formFieldType).isRequired,
  isSelectedWithDropdown: PropTypes.bool.isRequired,
  selectFormPageWithScroll: PropTypes.func.isRequired,
  fieldsValues: PropTypes.arrayOf(formFieldType),
  isFetchingFieldsValues: PropTypes.bool,
  selectedField: formFieldType,
  selectedFormFieldName: PropTypes.string,
  fieldsValueKey: PropTypes.string,
  fieldsFilterKey: PropTypes.string,
  onFormFieldSelection: PropTypes.func,
  onDoubleClickSelection: PropTypes.func,
  handleCommandClick: PropTypes.func,
  isShiftKeyUsedForSelecting: PropTypes.bool,
  isCommandOrControlUsedForSelecting: PropTypes.bool,
  isUsingCustomContextMenu: PropTypes.bool,
  isUsingSameExpression: PropTypes.bool,
};

export default FormView;
