const matchPermissionRoute = require('./matchPermissionRoute');
const { hasPermissionToAccess, PERMISSION_TYPE } = require('./pathPermissions');

const PERMISSION = {
  EFILE_RUN: '/permissions/efile/run',
  EFILE_FEDERAL_RETURN: '/permissions/efile/feredal-return',
  EFILE_FAIL_SUBSTEP: '/permissions/efile/fail-substep',
  EFILE_UPDATE_TRANSMITTER: '/permissions/efile/update_transmitter',
  EFILE_TRANSMIT_RETURNS: '/permissions/efile/transmit_returns',
  MIGRATE_EFILE_LOGS: '/permissions/migrate-efile-logs',
  PROPAGATE_DATA_MODEL: '/permissions/propagate-data-model',
  INDIVIDUAL_PARTNERS: '/permissions/individual-partners',
  INDIVIDUAL_PARTNERS_FEATURE_FLAG: '/permissions/individual-partners-feature-flag',
  INSERT_UPDATE_WATERMARK: '/permissions/watermarks/insert-update',
  DELETE_WATERMARK: '/permissions/watermarks/delete',
  COMPILE_EXPRESSIONS_ALL_JURISDICTIONS:
    '/permissions/expression-builder/compile-all-jurisdictions',
  CONTENT_DEPLOYMENT_AUTHORIZER: '/permissions/content-deployment/authorizer',
  CONTENT_DEPLOYER: '/permissions/content-deployment/deployer',
  ENTITY_DATA_CALCULATION: '/permissions/entity-data-calculation',
  FORCE_ENTITY_BATCH_CALC: '/permissions/force-entity-batch-calc',
  DELETE_CALC_PREFERENCE_JURISDICTION: '/permissions/delete-calc-preference-jurisdiction',
  MANAGE_ALIASES: '/permissions/manage-aliases',
  AZURE_BLOB_STORAGE: '/permissions/azure/blob-storage',
  AZURE_SERVICE_BUS: '/permissions/azure/service-bus',
  DELETE_FILING_GROUP: '/permissions/delete-filing-group',
};

module.exports = {
  matchPermissionRoute,
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
};
