import {
  ColumnInBlueprint,
  FilingAttributesColumnBlueprint,
  TaxReturnDefinitionWithFilingAttributes,
} from '../../../../../common/types/apiShapes';

export const getOptionsByColumnAndTaxReturnDefinition = ({
  column,
  taxReturnsDefinition,
}: {
  column?: FilingAttributesColumnBlueprint | ColumnInBlueprint | null;
  taxReturnsDefinition?: TaxReturnDefinitionWithFilingAttributes;
}) =>
  (column?.children || [])?.map(child => ({
    value: child.field,
    label: child.displayName,
    isTaxReturnDefinitionEnabled:
      taxReturnsDefinition?.filingAttributes[
        child.field as keyof typeof taxReturnsDefinition.filingAttributes
      ],
  }));
