import { ColDef } from 'ag-grid-community';

import { SelectOption, dataTypes } from '../../constants';
import { selectCellEditorParamsFactory } from '../../columnDefinitions';
import { SELECT_OPTIONS } from '../constants';
import {
  defaultAgRichSelectCellEditorSelector,
  defaultDateCellEditor,
} from '../../columnDefinitions/cellEditor';

const EditorSelectorFactory = (selectOptions: SelectOption[] = SELECT_OPTIONS) => {
  const EditorSelector: ColDef['cellEditorSelector'] = props => {
    switch (props.data?.dataType) {
      case dataTypes.DATE:
        return {
          ...defaultDateCellEditor,
          params: {
            value: props.data.value,
          },
        };
      case dataTypes.NUMBER:
        return {
          component: 'NumberCellEditor',
          params: {
            nullOnEmptyString: true,
            value: props.data.value,
          },
        };
      case dataTypes.CHECKBOX:
        return {
          ...defaultAgRichSelectCellEditorSelector,
          params: selectCellEditorParamsFactory(selectOptions)(),
        };
      default:
        return {
          component: 'TextCellEditor',
          params: {
            value: props.data?.value,
          },
        };
    }
  };

  return EditorSelector;
};

export default EditorSelectorFactory;
