import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';
import { EntityInformationRefreshRequest } from '../../../../common/types';

import { useCustomMutation } from '.';

interface RefreshEntityInformation {
  entity: string | null | undefined;
  entityInformationRefreshRequest: EntityInformationRefreshRequest;
}

export const useMutationRefreshEntityInformation = () =>
  useCustomMutation<null, QueryError, RefreshEntityInformation, EntityInformationRefreshRequest>({
    handleData: ({ entity, entityInformationRefreshRequest }) => ({
      url: `/api/admin/support/entity-information-diagnostics/refresh/${entity}`,
      method: HttpMethods.POST,
      body: entityInformationRefreshRequest,
    }),
    successMessage: 'Refreshing entity information',
    errorMessage: 'Refreshing entity information failed',
    resetKey: QueryKeys.EntityInformationDiagnostics.Refresh,
  });
