import {
  FindFilingAttributesDefinitionsParams,
  FindFilingAttributesDefinitionsResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export const useQueryFilingAttributesDefinitions = ({
  params,
  enabled,
}: {
  params: FindFilingAttributesDefinitionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindFilingAttributesDefinitionsResponse>(
    [QueryKeys.ReturnDefinitions.ReturnDefinitions, params],
    {
      url: `/api/filing-attributes/definitions/${params.taxYear}`,
      defaultData: [],
      enabled,
      errorMessage: 'Error fetching filing attributes definitions',
    },
  );
