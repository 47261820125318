import React from 'react';

import styles from './tabWrapper.module.scss';

interface TabWrapperProps {
  isActiveTab: boolean;
  children: JSX.Element;
}

const TabWrapper = ({ children, isActiveTab }: TabWrapperProps) => (
  <>
    <div className={`${styles.tabWrapper} ${isActiveTab ? '' : styles.hideTab}`}>{children}</div>
  </>
);

export default TabWrapper;
