import { GlobalContextParams } from '../../../../common/types';
import { httpGetBlob } from '../../utils/fetchService';

import { QueryKeys, useCustomQuery } from './index';

interface DownloadFederalPdfParams extends GlobalContextParams {
  sourceId: string;
}

const downloadFederalPdf = (params: DownloadFederalPdfParams) =>
  httpGetBlob({
    route: `/api/federal-proforma/${params.taxYear}/${params.period}/${params.filingTypeId}/${params.businessEntityId}/${params.jurisdictionId}/${params.sourceId}`,
    errorMessage: 'Downloading federal return pdf failed',
  });

export const useQueryDownloadFederalPdf = ({
  params,
  enabled,
}: {
  params: DownloadFederalPdfParams;
  enabled: boolean;
}) =>
  useCustomQuery<Blob>([QueryKeys.FederalProforma.FederalPdf, params], {
    enabled,
    customFetch: () => downloadFederalPdf(params),
  });
