const DefaultCase = {
  ID: '1',
  NAME: 'Default Case',
  DEFAULT_CASE_TAX_YEAR_BOUNDARY: 2019,
};

const JACOBS_ENGINEERING_DATABASE_NAME = 'Jacobs Engineering';
const GENERAL_ELECTRIC_DATABASE_NAME = 'General Electric Corporation';
// TODO SLT-8657 Add business units flag to client table in master db
const BERKSHIRE_HATHAWAY_DATABASE_NAME = 'Berkshire Hathaway';

const NEW_YORK_JURISDICTION_ID = '3600000000';
const NEW_JERSEY_JURISDICTION_ID = '3400000000';

module.exports = {
  DefaultCase,
  JACOBS_ENGINEERING_DATABASE_NAME,
  GENERAL_ELECTRIC_DATABASE_NAME,
  BERKSHIRE_HATHAWAY_DATABASE_NAME,
  NEW_YORK_JURISDICTION_ID,
  NEW_JERSEY_JURISDICTION_ID,
};
