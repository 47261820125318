import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Radio from '../../shared/forms/radioFormik/radioFormik.component';
import TextArea from '../../shared/forms/textareaFormik/textareaFormik.component';
import ExpressionValidationStatus from '../../shared/displayComponents/expressionValidationStatus/expressionValidationStatus.component';

import { calculationOptions } from './constants';
import styles from './dataModels.module.scss';

const noop = () => null;

const DatasetForm = ({
  onValidateClick = noop,
  disabled = false,
  isEditing = false,
  aggregateSepInstances = false,
  validationResults = null,
  isValidatingExpression = false,
  instanceFilteringExpression = null,
}) => (
  <form>
    <div className="row">
      <Field
        label="Name"
        name="name"
        className="col-6"
        disabled={disabled || isEditing}
        component={Input}
        autoComplete="off"
      />
      <Field
        label="Description"
        name="description"
        className="col-6"
        disabled={disabled}
        component={Input}
        autoComplete="off"
      />
      <Field
        label="Instance Name"
        name="instanceName"
        className="col-3"
        disabled={disabled}
        component={Input}
        autoComplete="off"
      />
      <Field
        label="Instance Identifier"
        name="instanceIdentifier"
        className="col-3"
        disabled={disabled}
        component={Input}
        autoComplete="off"
      />
      <Field
        label="Max. Instances"
        name="maxInstances"
        className="col-3"
        disabled={disabled}
        component={Input}
        autoComplete="off"
        type="number"
        min="-1"
      />
      <div className={`col-3 ${styles.centeredCheckbox}`}>
        <Field
          name="calculation"
          disabled={disabled}
          component={Radio}
          options={calculationOptions}
        />
      </div>
    </div>
    {aggregateSepInstances && (
      <div className="row mt-sm-2 align-items-end">
        <div className="col-9">
          <label className="a-form-label float-left">Instance Filtering Expressions</label>
          {validationResults && (
            <ExpressionValidationStatus
              isValid={validationResults.isValid}
              isWarning={validationResults.isWarning}
              date={validationResults.validationDate}
              className="float-right"
            />
          )}
          <Field
            name="instanceFilteringExpression"
            component={TextArea}
            disabled={disabled || isValidatingExpression}
          />
        </div>
        <Button
          size="lg"
          onClick={onValidateClick}
          className="add-button col-1"
          disabled={!instanceFilteringExpression}
          isLoading={isValidatingExpression}
          gray={isValidatingExpression}
        >
          Validate
        </Button>
      </div>
    )}
  </form>
);

DatasetForm.propTypes = {
  disabled: PropTypes.bool,
  aggregateSepInstances: PropTypes.bool,
  onValidateClick: PropTypes.func,
  validationResults: PropTypes.shape({
    isValid: PropTypes.bool.isRequired,
    isWarning: PropTypes.bool.isRequired,
    validationDate: PropTypes.string.isRequired,
  }),
  isValidatingExpression: PropTypes.bool,
  isEditing: PropTypes.bool,
  instanceFilteringExpression: PropTypes.string,
};

export default DatasetForm;
