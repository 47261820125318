import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useRowEditMode } from '../shared/editMode';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../shared/columnDefinitions/cellRenderers';
import globalContextPropTypes from '../shared/propTypes/globalContext';
import { useQueryFindCheckboxGroups } from '../shared/queries/checkboxGroups';
import { useQueryCommonDataModels } from '../shared/queries/commonDataModels';
import { useQueryCommonDataModelFields } from '../shared/queries/stateCommonInformation';
import { useMutationUpdateDataModelFields } from '../shared/mutations/stateCommonInformation';
import { autoGroupColumnDef } from '../shared/columnDefinitions/booleanCheckboxBasedAgGrid.autoGroupColumnDefinition';
import LoadingSpinner from '../shared/forms/loadingSpinner/loadingSpinner.component';
import { gridGroupOptions } from '../shared/displayComponents/agGrid/constants';

import getColumnDefinitions from './stateCommonInformation.columnDefinitions';
import styles from './stateCommonInformation.module.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const StateCommonInformation = ({ globalContext, hasUserPermissionsToEdit }) => {
  const [dataModelsOptions, setDataModelsOptions] = useState([]);
  const [selectedDataModel, setSelectedDataModel] = useState(null);

  const { taxYear, period, filingTypeId, jurisdictionId, businessEntityId } = globalContext.params;

  const {
    data: commonDataModels,
    isFetching: isFetchingCommonDataModels,
  } = useQueryCommonDataModels({
    params: { taxYear, jurisdictionId },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const {
    data: checkboxGroupsResponse,
    isFetching: isFetchingCheckboxGroups,
  } = useQueryFindCheckboxGroups({
    params: { taxYear, jurisdictionId, shouldIncludeEverywhereJurisdiction: true },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const {
    data: dataModelFieldsResponse,
    isFetching: isFetchingDataModelFields,
  } = useQueryCommonDataModelFields({
    params: {
      period,
      businessEntityId,
      dataModelId: selectedDataModel?.dataModelId,
      filingTypeId,
    },
    enabled: Boolean(period && businessEntityId && selectedDataModel),
  });

  const updateDataModelFields = useMutationUpdateDataModelFields();

  useEffect(() => {
    const options = commonDataModels.map(dataModel => ({
      ...dataModel,
      value: dataModel.dataModelId,
      label: dataModel.description ? dataModel.description : dataModel.name,
    }));
    setDataModelsOptions(options);
    setSelectedDataModel(options[0]);
  }, [commonDataModels]);

  const dataModelFields = useMemo(
    () => (dataModelFieldsResponse || []).map((field, rowId) => ({ ...field, rowId })),
    [dataModelFieldsResponse],
  );

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      if (!rowsPairsWithChanges.length) {
        return;
      }
      const rows = rowsPairsWithChanges.map(({ newRow }) => newRow);

      await updateDataModelFields.mutateAsync({
        rows,
        taxYear,
        period,
        jurisdictionId,
        businessEntityId,
        filingTypeId,
      });
    },
    [updateDataModelFields, taxYear, period, jurisdictionId, businessEntityId, filingTypeId],
  );

  const isLoading =
    isFetchingCheckboxGroups ||
    isFetchingCommonDataModels ||
    isFetchingDataModelFields ||
    updateDataModelFields.isLoading;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateGroup,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: dataModelFields,
    getUniqueRowId,
    editButtonDisabled: isLoading || !dataModelFields?.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        rowData: clonedRowData,
        checkboxGroups: checkboxGroupsResponse.checkboxGroups,
        isInEditMode,
        updateGroup,
        updateRow,
        filingTypeId,
        taxYear,
      }),
    [
      clonedRowData,
      updateGroup,
      updateRow,
      checkboxGroupsResponse.checkboxGroups,
      isInEditMode,
      filingTypeId,
      taxYear,
    ],
  );

  const onDataModelChange = useCallback(dataModel => setSelectedDataModel(dataModel), []);

  return (
    <>
      {navigationPrompt}
      <div className={styles.formListDropdownWrapper}>
        <div className={styles.formListDropdown}>
          <SDKCustomSelect
            className="sdk-custom-select"
            options={dataModelsOptions}
            onChange={onDataModelChange}
            value={selectedDataModel?.value}
            isLoading={isFetchingCommonDataModels}
            disabled={isInEditMode}
            virtualized
          />
        </div>
        <div>{hasUserPermissionsToEdit && editModeButtons}</div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <LoadingSpinner isLoading={isLoading} />
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            autoGroupColumnDef={autoGroupColumnDef}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            withSearchBar
            stopEditingWhenCellsLoseFocus
            {...gridGroupOptions}
          />
        </div>
      </div>
    </>
  );
};

StateCommonInformation.propTypes = {
  globalContext: globalContextPropTypes,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default StateCommonInformation;
