import React from 'react';
import PropTypes from 'prop-types';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';

const ReportCards = ({ reportsData }) => (
  <div className="row row-with-cards">
    {reportsData.map(report => (
      <AuthorizedCard
        key={report.route}
        title={report.title}
        icon={report.icon}
        description={report.description}
        route={report.route}
      />
    ))}
  </div>
);

ReportCards.propTypes = {
  reportsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }),
  ),
};

export default ReportCards;
