import { dataTypes } from '@common-packages/shared-constants';

import { EditableCheckboxCellRenderer, TextCellEditor } from './cellRenderers';

import { defaultColumnDefinitionWithFilter, defaultNoDataColumn } from '.';

const getCellStyles = (baseStyle, dataType, cellValue) => {
  const isColumnCurrencyOrRatioOrInteger = [
    dataTypes.CURRENCY,
    dataTypes.RATIO,
    dataTypes.INTEGER,
  ].includes(dataType);
  const isColumnBoolean =
    dataType === dataTypes.BOOLEAN ||
    (cellValue && ['YES', 'NO'].includes(cellValue?.toString().toUpperCase()));

  if (isColumnBoolean) {
    return { textAlign: 'center' };
  }

  if (isColumnCurrencyOrRatioOrInteger) {
    return { textAlign: 'right' };
  }

  return baseStyle;
};

const getColumnsBasedOnColumnBlueprint = ({
  updateRow,
  isInEditMode,
  column: columnBlueprint,
  lockPinned = false,
}) => {
  const isColumnBoolean = columnBlueprint.dataType === dataTypes.BOOLEAN;

  const valueGetter = columnBlueprint.valueLabelsMap
    ? ({ data }) => {
        const value = data[columnBlueprint.field];
        // for non-boolean calc preferences column, value labels are first indexed with jurisdiction id
        // because there may be different labels for the same preference
        return (
          columnBlueprint.valueLabelsMap[value] ||
          columnBlueprint.valueLabelsMap[data.jurisdictionId]?.[value]
        );
      }
    : null;
  const booleanColumnWidth = 85;
  const defaultColumnWidth = 125;

  if (columnBlueprint.children && !columnBlueprint.hasSingleValue) {
    return {
      hide: columnBlueprint.hide,
      headerName: columnBlueprint.displayName,
      children: columnBlueprint.children.map(child =>
        getColumnsBasedOnColumnBlueprint({
          updateRow,
          isInEditMode,
          column: child,
          lockPinned,
        }),
      ),
    };
  }

  return {
    ...defaultColumnDefinitionWithFilter,
    headerName: columnBlueprint.displayName,
    headerTooltip: columnBlueprint.displayName,
    field: columnBlueprint.field,
    valueGetter,
    editable: isInEditMode,
    cellEditor: TextCellEditor,
    pinned: columnBlueprint.pinned,
    lockPinned: columnBlueprint.lockPinned || lockPinned,
    cellRenderer: params =>
      // '|| null' is required here so we return something if params.value doesn't exist
      isColumnBoolean ? EditableCheckboxCellRenderer(params) : params.value || null,
    cellRendererParams: { isDisabled: () => !isInEditMode },
    onCellValueChanged: ({ data }) => updateRow(data),
    width:
      columnBlueprint.width ||
      (isColumnBoolean ? booleanColumnWidth : columnBlueprint.width || defaultColumnWidth),
    minWidth:
      columnBlueprint.minWidth ||
      (isColumnBoolean ? booleanColumnWidth : columnBlueprint.minWidth || defaultColumnWidth),
    cellStyle: params =>
      getCellStyles(columnBlueprint.cellStyle, columnBlueprint.dataType, params.value),
    headerClass: columnBlueprint.headerClass || null,
    hide: columnBlueprint.hide,
  };
};

const columnDefinitions = ({
  updateRow = () => null,
  isInEditMode = false,
  columnsBlueprint,
  lockPinned = false,
}) => {
  // fallback for no data to show user "no rows to show"
  if (!columnsBlueprint.length) {
    return [defaultNoDataColumn];
  }

  return columnsBlueprint.map(column => {
    /*
      TODO: SLT-6426 We have to check "column.hasSingleValue" functionality for screens
        - /admin/consolidations
        - /admin/tax-rates-and-constants-maintenance
        - /:globalContextRoute/filing-decisions-v2
    */
    return getColumnsBasedOnColumnBlueprint({
      updateRow,
      isInEditMode,
      column,
      lockPinned,
    });
  });
};

export default columnDefinitions;
