import { currencyColumnDefinition, defaultColumnDefinition } from '../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Id',
    field: 'orgId',
    width: 70,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
    width: 200,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'FEIN',
    field: 'fein',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Date of Acquisition',
    field: 'dateAcquired',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Date of Formation',
    field: 'dateFormed',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Company Type',
    field: 'companyType',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Federal Filing Type',
    field: 'fedFilingType',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'PY Filing Group',
    field: 'pyFilingGroup',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'CY Filing Group',
    field: 'cyFilingGroup',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Property Within',
    field: 'propertyWiAmount',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Rent Within',
    field: 'rentWiAmount',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Payroll Within',
    field: 'payrollWiAmount',
  },
  {
    ...defaultColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Sales of Service Within',
    field: 'salesWiAmount',
  },
];

export default columnDefinitions;
