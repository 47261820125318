import getFieldValue from '../../utils/getFieldValue';
import { fetchFilingStatesTypes } from '../../filingStates/store/types';
import { filingTypes } from '../constants';

import reducerFactory from './reducerFactory';
import {
  fetchTaxYearsTypes,
  SELECT_TAX_YEAR,
  fetchPeriodsTypes,
  SELECT_PERIOD,
  SELECT_FILING_TYPE,
  fetchConsolidationsTypes,
  SELECT_CONSOLIDATION,
  SELECT_MAINTENANCE_CONSOLIDATION,
  fetchEntitiesTypes,
  fetchJurisdictionsOptionsTypes,
  SELECT_ENTITY,
  SELECT_JURISDICTION,
  fetchTrgReportsTypes,
  fetchCustomerDataTypes,
  fetchPermissionsTypes,
  SELECT_CLIENT_ID,
  fetchLockIndicatorTypes,
  fetchDataModelLinksTypes,
  CLEAR_DATA_MODEL_LINKS,
  SAVE_SCROLL_ELEMENT_ID,
  signOutTypes,
  fetchJurisdictionsForWhichEntityIsLockedTypes,
  fetchIsPartnershipTypes,
  fetchFormsByPeriodTypes,
  CLEAR_FORMS_BY_PERIOD,
} from './types';
import getApiActionTypesHandlers from './getApiActionTypesHandlers';

const setPeriod = ({ previousPeriod }, periodList) => {
  // delete before for ticket 14601, but this change change some logic
  // and just add previously selected value judgement
  // and re-tested ok for 14601
  const previouslySelectedPeriodCanBeFoundOnNewList = periodList.some(
    period => period.name === previousPeriod,
  );

  return previouslySelectedPeriodCanBeFoundOnNewList ? previousPeriod : null;
};

export const initialState = {
  taxYearsData: { taxYears: [], defaultTaxYear: null },
  previousTaxYear: null,
  taxYear: null,
  isFetchingTaxYearsData: false,

  periods: [],
  period: null,
  previousPeriod: null,
  isFetchingPeriods: false,

  filingTypeId: filingTypes.SEPARATE,

  consolidations: [],
  consolidationId: null,
  previousConsolidationId: null,
  isFetchingConsolidations: false,

  entities: [],
  entityId: null,
  previousEntityId: null,
  isFetchingEntities: false,

  trgReports: { trgReports: [] },

  jurisdictionId: null,
  previousJurisdictionId: null,

  jurisdictionsOptions: [],
  isFetchingJurisdictionsOptions: false,

  dataModelLinks: [],
  isFetchingDataModelLinks: false,

  customerData: {
    customerId: null,
    email: null,
    firstName: null,
    lastName: null,
    clients: [],
  },
  clientId: null,
  isFetchingCustomerData: false,
  isFetchingCustomerDataError: false,

  permissionsData: {
    roleId: null,
    permissions: { read: [], edit: [], blacklist: [] },
  },
  isFetchingPermissionsData: false,
  isFetchingPermissionsDataError: false,

  isEntityLocked: false,
  isConsolLocked: false,
  isFetchingEntityLockIndicator: false,

  isPartnership: null,
  isFetchingIsPartnership: false,

  scrollElementId: null,

  isSigningOut: false,

  lockedJurisdictions: null,
  isFetchingLockedJurisdictions: false,

  forms: [],
  isFetchingForms: false,
};

const sharedReducer = reducerFactory({
  initialState,
  actionHandlers: {
    [fetchTaxYearsTypes.START]: state => ({ ...state, isFetchingTaxYearsData: true }),
    [fetchTaxYearsTypes.FAILURE]: state => ({ ...state, isFetchingTaxYearsData: false }),
    [fetchTaxYearsTypes.SUCCESS]: (state, payload) => ({
      ...state,
      taxYearsData: payload,
      isFetchingTaxYearsData: false,
    }),

    [fetchPeriodsTypes.START]: state => ({ ...state, isFetchingPeriods: true }),
    [fetchPeriodsTypes.FAILURE]: state => ({ ...state, isFetchingPeriods: false }),
    [fetchPeriodsTypes.SUCCESS]: (state, payload) => ({
      ...state,
      periods: payload,
      period: setPeriod(state, payload),
      isFetchingPeriods: false,
    }),

    [SAVE_SCROLL_ELEMENT_ID]: (state, payload) => ({
      ...state,
      scrollElementId: payload,
    }),

    [SELECT_TAX_YEAR]: (state, payload) => ({
      ...state,
      taxYear: payload,
      previousTaxYear: payload,

      period: null,
      previousPeriod: state.period,
      periods: [],

      consolidationId: null,
      previousConsolidationId: state.consolidationId,

      entityId: null,
      previousEntityId: state.entityId,

      jurisdictionId: null,
      previousJurisdictionId: state.jurisdictionId,
    }),

    [SELECT_PERIOD]: (state, payload) => ({
      ...state,

      period: payload ? Number(payload) : null, // parsing back to Number, see in selector why that happens
      previousPeriod: payload ? Number(payload) : null,

      consolidationId: null,
      previousConsolidationId: state.consolidationId,

      entityId: null,
      previousEntityId: state.entityId,

      jurisdictionId: null,
      previousJurisdictionId: state.jurisdictionId,
    }),

    [SELECT_FILING_TYPE]: (state, payload) => ({
      ...state,
      filingTypeId: payload,
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchConsolidationsTypes,
      payloadKey: 'consolidations',
      fetchingKey: 'isFetchingConsolidations',
      getAdditionalState: (state, payload) => ({
        consolidationId: getFieldValue({
          currentValueFieldName: 'consolidationId',
          payloadFieldName: 'consolidationId',
          state,
          payload,
        }),
        jurisdictionId: getFieldValue({
          currentValueFieldName: 'jurisdictionId',
          payloadFieldName: 'jurisdictionId',
          state,
          payload,
        }),
      }),
    }),

    // We have two different types of selecting consolidations
    // 1. On /admin/consolidations page - it's okay to change jurisdiction
    // 2. Consolidation dropdown - we shouldn't change jurisdiction on consolidation change
    [SELECT_MAINTENANCE_CONSOLIDATION]: (state, { consolidationId, orgId, jurisdictionId }) => ({
      ...state,

      consolidationId,
      previousConsolidationId: consolidationId,

      entityId: orgId,
      previousEntityId: orgId,

      jurisdictionId,
      previousJurisdictionId: jurisdictionId,
    }),

    [SELECT_CONSOLIDATION]: (state, consolidationId) => ({
      ...state,
      consolidationId,
      previousConsolidationId: consolidationId,
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchEntitiesTypes,
      payloadKey: 'entities',
      fetchingKey: 'isFetchingEntities',
      getAdditionalState: (state, payload) => ({
        entityId: getFieldValue({
          currentValueFieldName: 'entityId',
          payloadFieldName: 'entityId',
          state,
          payload,
        }),
      }),
    }),
    [SELECT_ENTITY]: (state, payload) => ({
      ...state,

      entityId: payload,
      previousEntityId: payload,
      isPartnership: null,

      jurisdictionId: null,
      previousJurisdictionId: state.jurisdictionId,
    }),

    // fetching filing states inside client/src/filingStates/store directory
    // using filing states payload to get proper jurisdictionId
    [fetchFilingStatesTypes.SUCCESS]: (state, payload) => ({
      ...state,
      jurisdictionId: getFieldValue({
        currentValueFieldName: 'jurisdictionId',
        payloadFieldName: 'jurisdictionId',
        state,
        payload,
      }),
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchTrgReportsTypes,
      payloadKey: 'trgReports',
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchJurisdictionsOptionsTypes,
      payloadKey: 'jurisdictionsOptions',
      fetchingKey: 'isFetchingJurisdictionsOptions',
    }),
    [SELECT_JURISDICTION]: (state, payload) => ({
      ...state,

      jurisdictionId: payload,
      previousJurisdictionId: payload,
      isPartnership: null,

      consolidationId: null,
      previousConsolidationId: state.consolidationId,
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchCustomerDataTypes,
      payloadKey: 'customerData',
      fetchingKey: 'isFetchingCustomerData',
      errorKey: 'isFetchingCustomerDataError',
      getAdditionalState: (state, payload) => ({
        clientId: payload.clients.find(({ id }) => id === state.clientId)
          ? state.clientId
          : payload.clients[0] && payload.clients[0].id,
      }),
    }),

    [SELECT_CLIENT_ID]: (state, payload) => ({
      ...state,
      clientId: payload,
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchPermissionsTypes,
      payloadKey: 'permissionsData',
      fetchingKey: 'isFetchingPermissionsData',
      errorKey: 'isFetchingPermissionsDataError',
    }),

    ...getApiActionTypesHandlers({
      apiTypes: fetchDataModelLinksTypes,
      payloadKey: 'dataModelLinks',
      fetchingKey: 'isFetchingDataModelLinks',
    }),

    [CLEAR_DATA_MODEL_LINKS]: state => ({
      ...state,
      dataModelLinks: [],
    }),

    ...getApiActionTypesHandlers({
      apiTypes: signOutTypes,
      fetchingKey: 'isSigningOut',
    }),

    [fetchLockIndicatorTypes.START]: state => ({
      ...state,
      isFetchingEntityLockIndicator: true,
    }),

    [fetchLockIndicatorTypes.SUCCESS]: (state, { isLocked, filingTypeId }) => ({
      ...state,
      isFetchingEntityLockIndicator: false,
      ...(filingTypeId === filingTypes.SEPARATE ? { isEntityLocked: isLocked } : {}),
      ...(filingTypeId === filingTypes.CONSOLIDATED ? { isConsolLocked: isLocked } : {}),
    }),

    [fetchLockIndicatorTypes.FAILURE]: state => ({
      ...state,
      isFetchingEntityLockIndicator: false,
      isEntityLocked: false,
      isConsolLocked: false,
    }),

    [fetchJurisdictionsForWhichEntityIsLockedTypes.START]: state => ({
      ...state,
      isFetchingLockedJurisdictions: true,
    }),

    [fetchJurisdictionsForWhichEntityIsLockedTypes.SUCCESS]: (state, { lockedJurisdictions }) => ({
      ...state,
      lockedJurisdictions,
      isFetchingLockedJurisdictions: false,
    }),

    [fetchJurisdictionsForWhichEntityIsLockedTypes.FAILURE]: state => ({
      ...state,
      lockedJurisdictions: null,
      isFetchingLockedJurisdictions: false,
    }),
    [fetchIsPartnershipTypes.START]: state => ({
      ...state,
      isFetchingIsPartnership: true,
    }),
    [fetchIsPartnershipTypes.SUCCESS]: (state, { isPartnership }) => ({
      ...state,
      isFetchingIsPartnership: false,
      isPartnership,
    }),
    [fetchIsPartnershipTypes.FAILURE]: state => ({
      ...state,
      isFetchingIsPartnership: false,
      isPartnership: false,
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchFormsByPeriodTypes,
      payloadKey: 'forms',
      fetchingKey: 'isFetchingForms',
    }),
    [CLEAR_FORMS_BY_PERIOD]: state => ({
      ...state,
      forms: [],
    }),
  },
});

export default sharedReducer;
