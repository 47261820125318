import React, { useMemo } from 'react';
import {
  RowDragCallbackParams,
  ColDef,
  ICellRendererParams,
  CellClassParams,
} from 'ag-grid-community';
import { Routes } from '@common-packages/routes-definitions';
import { Tooltip, Checkbox } from '@pwc/appkit-react';

import { GlobalContext } from '../../../common/types';
import {
  defaultColumnDefinition,
  adminColumnDefinition,
  mapValueFormatterFactory,
} from '../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  LinkCellRendererFactory,
} from '../shared/columnDefinitions/cellRenderers';
import getContextAwareUrlParams from '../utils/getContextAwareUrlParams';
import AppkitIcon from '../shared/displayComponents/appkitIcon/appkitIcon.component';
import { FilingMethods } from '../shared/enums';

import { ReturnItemTypes } from './enums';
import styles from './returnMaintenance.module.scss';

interface GetColumnDefinitionsParams {
  globalContext: GlobalContext;
  isSeparateReturn: boolean;
  filingMethod: FilingMethods | null | undefined;
}

const RETURN_ITEM_TYPES_OPTIONS = [
  { value: ReturnItemTypes.FORM, label: 'Form' },
  { value: ReturnItemTypes.PDF_ATTACHMENT, label: 'PDF' },
];

const TOOLTIP_CONTENT = {
  PRIMARYFORM_TOOLTIP_CONTENT: 'This form is required. You cannot remove or reorder it.',
  EFILE_SUPPORT_CONTENT:
    'This form is not supported by e-File and will not be included in the XML schema.',
};

const InfoIconRenderer = (isEfileSupportedTooltip: boolean) =>
  useMemo(
    () => (
      <span
        className={
          isEfileSupportedTooltip ? styles.efileSupportInfoIcon : styles.primaryFormInfoIcon
        }
      >
        <Tooltip
          content={
            isEfileSupportedTooltip
              ? TOOLTIP_CONTENT.EFILE_SUPPORT_CONTENT
              : TOOLTIP_CONTENT.PRIMARYFORM_TOOLTIP_CONTENT
          }
          placement="top"
        >
          <AppkitIcon icon="information" type="fill" size={16} />
        </Tooltip>
      </span>
    ),
    [isEfileSupportedTooltip],
  );

const getColumnDefinitions = ({
  globalContext,
  isSeparateReturn,
  filingMethod,
}: GetColumnDefinitionsParams): ColDef[] => {
  const { params } = globalContext;

  const getIsPrimaryForm = (data?: { isPrimaryForm: boolean; consolidationId: string | null }) =>
    data?.isPrimaryForm && (data.consolidationId || isSeparateReturn);

  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: () => false,
    shouldShowDeleteIcon: () => false,
  });

  const LinkCellRenderer = LinkCellRendererFactory({
    getText: ({ value }) => value,
    getPathName: (_value, data) =>
      Routes.viewPrintTaxReturn.compiledRoute({
        ...getContextAwareUrlParams(params),
        formId: data?.itemId,
      }),
    getQueryParams: (_value, data) => `formId=${data?.itemId}`,
  });

  const PrimaryFormDisabledCheckboxRenderer = (params: ICellRendererParams) => (
    <>{getIsPrimaryForm(params.data) && <Checkbox disabled />}</>
  );

  const EfileSupportedColumnRender = (params: ICellRendererParams) => {
    const efileSupportedButtonRender = <div className={styles.yesButton}>Yes</div>;

    if (params.data.itemType === ReturnItemTypes.PDF_ATTACHMENT) {
      return efileSupportedButtonRender;
    }

    return params.data.efileSupported ? (
      efileSupportedButtonRender
    ) : (
      <div className={styles.notAvailContainer}>
        <span>N/A</span>
        {InfoIconRenderer(true)}
      </div>
    );
  };

  const shouldHideEfileSupportedColumn = () => {
    const { taxYear } = params;
    if (taxYear) {
      return !(parseInt(taxYear, 10) > 2020 && filingMethod === FilingMethods.EFILE);
    }
    return false;
  };

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: (params: ICellRendererParams) =>
        getIsPrimaryForm(params.data) ? InfoIconRenderer(false) : AdminCellRenderer(params),
      rowDrag: ({ data }: RowDragCallbackParams) => !getIsPrimaryForm(data),
      rowDragText: ({ rowNode }) =>
        getIsPrimaryForm(rowNode?.data) ? null : rowNode?.data?.formName,
      width: 30,
      maxWidth: 30,
      sortable: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: '',
      width: 40,
      maxWidth: 40,
      cellRenderer: PrimaryFormDisabledCheckboxRenderer,
      checkboxSelection: true,
      cellClass: 'custom-ag-grid-checkbox',
      cellClassRules: {
        disabled: ({ data }: CellClassParams) => Boolean(getIsPrimaryForm(data)),
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...defaultColumnDefinition,
      maxWidth: 120,
      headerName: 'Cons Group ID',
      field: 'consolidationId',
      hide: isSeparateReturn,
    },
    {
      ...defaultColumnDefinition,
      maxWidth: 150,
      headerName: 'Entity ID',
      field: 'orgId',
    },
    {
      ...defaultColumnDefinition,
      maxWidth: 300,
      headerName: 'Entity',
      field: 'orgName',
    },
    {
      ...defaultColumnDefinition,
      flex: 3,
      headerName: 'Name',
      field: 'formName',
      cellRenderer: LinkCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      width: 120,
      maxWidth: 120,
      headerName: 'Type',
      field: 'itemType',
      valueFormatter: mapValueFormatterFactory(RETURN_ITEM_TYPES_OPTIONS),
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Include in E-File',
      field: 'efileSupported',
      width: 120,
      maxWidth: 120,
      hide: shouldHideEfileSupportedColumn(),
      cellRenderer: EfileSupportedColumnRender,
    },
  ];
};

export default getColumnDefinitions;
