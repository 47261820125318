const Yup = require('yup');

const commonSchema = Yup.object().shape({
  jurisdictionId: Yup.string().label('Jurisdiction').required(),
  screenId: Yup.string().label('Screen Id').required(),
  title: Yup.string()
    .label('Screen Title')
    .required()
    .max(50, 'Screen Title cannot exceed 50 characters'),
  categoryId: Yup.string().label('Category').nullable(),
  screenType: Yup.string().label('Screen Type').nullable(),
  saveButton: Yup.string().label('Save Button').nullable(),
});

const createAndUpdateGenericScreenDefinitionSchema = commonSchema.concat(
  Yup.object().shape({
    taxYear: Yup.string().required(),
    period: Yup.string().required(),
  }),
);

module.exports = {
  commonSchema,
  createAndUpdateGenericScreenDefinitionSchema,
};
