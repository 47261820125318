import { SortOrderOption } from './enums';

export const binderItemTypes = {
  FORM: '1',
  PDF_ATTACHMENT: '2',
};

export const selectAllOption = {
  label: 'All Entities in Consolidation',
  value: 'SELECT_ALL',
};

export const SORT_ORDER_OPTIONS = [
  {
    label: 'Entity ID then by Form Name',
    value: SortOrderOption.ENTITY_ID_FIRST,
  },
  {
    label: 'Form Name then by Entity ID',
    value: SortOrderOption.FORM_NAME_FIRST,
  },
];
