import reducerFactory from '../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../shared/store/getApiActionTypesHandlers';

import {
  fetchWorkpaperInstanceDataTypes,
  updateWorkpaperInstanceTypes,
  fetchDatasetDefinitionMetaDataTypes,
} from './types';

const initialState = {
  data: {
    columnsBlueprint: [],
    rows: [],
    lockIndicator: false,
  },
  isFetchingData: false,
  isUpdatingWorkpaperInstance: false,

  datasetDefinition: {
    label: '',
    maximumInstancesAllowed: -1,
    systemDefined: false,
  },
  isFetchingDatasetDefinitionMetaData: false,
};

const workpaperInstancesReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchWorkpaperInstanceDataTypes,
      payloadKey: 'data',
      fetchingKey: 'isFetchingData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateWorkpaperInstanceTypes,
      fetchingKey: 'isUpdatingWorkpaperInstance',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDatasetDefinitionMetaDataTypes,
      payloadKey: 'datasetDefinition',
      fetchingKey: 'isFetchingDatasetDefinitionMetaData',
    }),
  },
});

export default workpaperInstancesReducer;
