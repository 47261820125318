import { FilingMethods } from '../enums';

export const FilingMethodsMap = {
  [FilingMethods.EFILE]: 1,
  [FilingMethods.PAPER]: 2,
  [FilingMethods.NON_FILING]: 3,
  [FilingMethods.NONE]: null,
} as const;

export const BASE_CONSOLIDATION_FILING_ATTRIBUTES_VALUE = 197642;
