import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useQueryFindCheckboxGroups } from '../../shared/queries/checkboxGroups';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import Loading from '../../shared/displayComponents/loading.component';
import withPartnershipGuard from '../../shared/displayComponents/partnershipGuard/withPartnershipGuardHoc.container';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useRowEditMode } from '../../shared/editMode';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import { globalContextSelector, entityIdSelector } from '../../shared/store/selectors';
import { useQueryFilterData, useQueryFormFields } from '../../shared/queries/k1InformationV2';
import { useMutationUpdateFormFields } from '../../shared/mutations/k1InformationV2';
import { autoGroupColumnDef } from '../../shared/columnDefinitions/booleanCheckboxBasedAgGrid.autoGroupColumnDefinition';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { gridGroupOptions } from '../../shared/displayComponents/agGrid/constants';

import getColumnDefinitions from './k1InformationV2.columnDefinitions';
import styles from './k1InformationV2.module.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const K1InformationV2 = ({ hasUserPermissionsToEdit }) => {
  const updateFormFields = useMutationUpdateFormFields();

  const globalContext = useSelector(globalContextSelector);
  const entityId = useSelector(entityIdSelector);

  const { taxYear, period, jurisdictionId } = globalContext.params;

  const { queryParams: { formId, partnerId } = null } = useUrlParams();

  const [selectedFormValue, setSelectedForm] = useState(null);
  const [selectedPartnerValue, setSelectedPartner] = useState(null);

  // This is necessary because otherwise the filter data will be fetched in every dropdown selection
  const [fetchFilterData, setFetchFilterData] = useState(true);
  const [formListOptions, setFormListOptions] = useState([]);
  const [partnerListOptions, setPartnerListOptions] = useState([]);

  const {
    data: checkboxGroupsResponse,
    isFetching: isFetchingCheckboxGroups,
  } = useQueryFindCheckboxGroups({
    params: { taxYear, jurisdictionId, shouldIncludeEverywhereJurisdiction: true },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const { isFetching: isFetchingFilterData } = useQueryFilterData({
    params: {
      taxYear,
      period,
      entityId,
      jurisdictionId,
      dataModelId: selectedFormValue,
      partnerId: selectedPartnerValue,
    },
    enabled: globalContext.isReady && fetchFilterData,
    onSuccess: ({ formListOptions, partnerListOptions }) => {
      if (formListOptions?.length) {
        setSelectedForm(formId || formListOptions[0].value);
      }
      if (partnerListOptions?.length) {
        setSelectedPartner(partnerId || partnerListOptions[0].value);
      }
      setFormListOptions(formListOptions);
      setPartnerListOptions(partnerListOptions);
      setFetchFilterData(false);
    },
  });

  const { data: formFields, isFetching: isFetchingFormFields } = useQueryFormFields({
    params: {
      period,
      entityId,
      dataModelId: selectedFormValue,
      partnerId: selectedPartnerValue,
    },
    enabled: Boolean(period && entityId && selectedFormValue && selectedPartnerValue),
  });

  const saveChanges = useCallback(
    ({ rowsPairsWithChanges }) => {
      if (!rowsPairsWithChanges.length) {
        return;
      }
      const rows = rowsPairsWithChanges.map(({ newRow }) => newRow);
      updateFormFields.mutate({ rows, ...globalContext.params });
    },
    [updateFormFields, globalContext.params],
  );

  const isLoading =
    isFetchingCheckboxGroups ||
    isFetchingFilterData ||
    isFetchingFormFields ||
    updateFormFields.isLoading;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateGroup,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: formFields,
    getUniqueRowId,
    editButtonDisabled: isLoading || !formFields.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        rowData: clonedRowData,
        checkboxGroups: checkboxGroupsResponse.checkboxGroups,
        isInEditMode,
        updateGroup,
        updateRow,
        taxYear,
      }),
    [
      isInEditMode,
      updateGroup,
      updateRow,
      checkboxGroupsResponse.checkboxGroups,
      taxYear,
      clonedRowData,
    ],
  );

  const onFormChange = useCallback(({ value }) => setSelectedForm(value), [setSelectedForm]);

  const onPartnerChange = useCallback(({ value }) => setSelectedPartner(value), [
    setSelectedPartner,
  ]);

  return (
    <>
      {navigationPrompt}
      <div className={styles.flexSpaceBetween}>
        <div className={styles.formListDropdown}>
          <div className={styles.editSpinner}>
            <Loading isLoading={isFetchingFilterData}>
              <SDKCustomSelect
                className="sdk-custom-select"
                virtualized
                appkitLabel="Form Name"
                options={formListOptions}
                value={selectedFormValue}
                onChange={onFormChange}
              />
            </Loading>
          </div>
        </div>
        <div className={styles.formListDropdown}>
          <div className={styles.editSpinner}>
            <Loading isLoading={isFetchingFilterData}>
              <SDKCustomSelect
                className="sdk-custom-select"
                virtualized
                appkitLabel="Partner"
                options={partnerListOptions}
                value={selectedPartnerValue}
                onChange={onPartnerChange}
              />
            </Loading>
          </div>
        </div>
        {hasUserPermissionsToEdit && <div className="add-button-column">{editModeButtons}</div>}
      </div>
      <div className="row grid-row">
        <div className="col">
          <Loading isLoading={isLoading}>
            <AgGrid
              rowData={clonedRowData}
              columnDefs={columnDefinitions}
              autoGroupColumnDef={autoGroupColumnDef}
              singleClickEdit
              suppressCellFocus={!isInEditMode}
              onGridReady={onGridReady}
              components={{
                DateCellEditor,
                NumberCellEditor,
                TextCellEditor,
              }}
              withSearchBar
              stopEditingWhenCellsLoseFocus
              {...gridGroupOptions}
            />
          </Loading>
        </div>
      </div>
    </>
  );
};

K1InformationV2.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default withPartnershipGuard(K1InformationV2);
