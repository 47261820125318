export const WARNING_BEFORE_DELETE = 'You will not be able to recover it';

export const ModalState = {
  INITIAL: 'initial',
  SELECTION_LIST_USED: 'selection_list_used',
  SELECTION_LIST_UNUSED: 'selection_list_unused',
};

export const ModalButtonsText = {
  [ModalState.INITIAL]: { submit: null, dismiss: null },
  [ModalState.SELECTION_LIST_USED]: { submit: 'Ok', dismiss: null },
  [ModalState.SELECTION_LIST_UNUSED]: { submit: 'Yes', dismiss: 'No' },
};

export const ModalTitle = {
  [ModalState.INITIAL]: null,
  [ModalState.SELECTION_LIST_USED]:
    'Deletion of Selection List is not allowed since it is mapped to the following',
  [ModalState.SELECTION_LIST_UNUSED]: 'Delete selection list?',
};
