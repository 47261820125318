import React, { useEffect, useMemo, useCallback, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';
import { SelectionChangedEvent } from 'ag-grid-community';

import SharedHeader from '../../../shared/displayComponents/header/header.component';
import { useQueryMaintenanceEntities } from '../../../shared/queries/entitiesMaintenance';
import {
  useQueryStatusFederalDataRefreshes,
  useQueryFederalFilingGroups,
  useQueryRecentFederalDataRefreshes,
} from '../../../shared/queries/federalDataDiagnostics';
import {
  useMutationResetErrorCount,
  useMutationFilingGroupsSendMessage,
  useMutationRefreshFederalData,
} from '../../../shared/mutations/federalDataDiagnostics';
import { globalContextSelector } from '../../../shared/store/selectors';
import Loading from '../../../shared/displayComponents/loading.component';
import {
  FederalEntity,
  FederalFilingGroup,
  GlobalContextParams,
  EntitiesOrGroupsRequest,
} from '../../../../../common/types';
import { QueryKeys } from '../../../shared/queries';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';

import styles from './federalDataDiagnostics.module.scss';
import { AllFederalEntities, RecentRefreshes, FederalFilingGroups } from './tables';
import { TabProps } from './types';

enum TabsTypes {
  AllFederalEntities = 'AllFederalEntities',
  RecentRefreshes = 'RecentRefreshes',
  FilingGroups = 'FilingGroups',
}

const tabsDefinitions = [
  { type: TabsTypes.RecentRefreshes, label: 'Recent Refreshes' },
  { type: TabsTypes.AllFederalEntities, label: 'Federal Entities' },
  { type: TabsTypes.FilingGroups, label: 'Filing Groups' },
];

const getActiveTabComponent = (activeTab: TabsTypes, props: TabProps) => {
  switch (activeTab) {
    case TabsTypes.RecentRefreshes:
      return <RecentRefreshes {...props} />;
    case TabsTypes.AllFederalEntities:
      return <AllFederalEntities {...props} />;
    case TabsTypes.FilingGroups:
      return <FederalFilingGroups {...props} />;
    default:
      throw new Error('Unsupported tab type');
  }
};

const FederalDataDiagnostics = () => {
  const dispatch = useDispatch();
  const [selectedFilingGroups, setSelectedFilingGroups] = useState<FederalFilingGroup[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<FederalEntity[]>([]);
  const [activeTab, setActiveTab] = useState(TabsTypes.RecentRefreshes);
  const queryClient = useQueryClient();

  const handleTabChange = useCallback((_: ChangeEvent, tab: TabsTypes) => setActiveTab(tab), []);

  const { taxYear, period }: GlobalContextParams = useSelector(globalContextSelector).params;
  const { data: status, isFetching: isFetchingStatus } = useQueryStatusFederalDataRefreshes({
    taxYear,
    enabled: Boolean(taxYear && period),
  });

  const { data: entities, isFetching: isFetchingEntities } = useQueryMaintenanceEntities({
    params: { taxYear, period, showFederalEntitiesOnly: true },
    enabled: Boolean(taxYear && period && activeTab === TabsTypes.AllFederalEntities),
  });

  const { data: filingGroups, isFetching: isFetchingFilingGroups } = useQueryFederalFilingGroups({
    taxYear,
    enabled: Boolean(taxYear && period && activeTab === TabsTypes.FilingGroups),
  });

  const {
    data: recentRefreshes,
    isFetching: isFetchingDataRefreshLogs,
  } = useQueryRecentFederalDataRefreshes({
    taxYear,
    enabled: Boolean(taxYear && period && activeTab === TabsTypes.RecentRefreshes),
  });

  const {
    mutateAsync: sendFederalEntitiesOrFilingGroupsSyncMsg,
    isLoading: isSendingFederalEntitiesOrFilingGroupsSyncMsg,
  } = useMutationRefreshFederalData();

  const {
    mutateAsync: sendFilingGroupsInformGTWMsg,
    isLoading: isSendingFilingGroupsInformGTWMsg,
  } = useMutationFilingGroupsSendMessage();

  const {
    mutateAsync: resetErrorCount,
    isLoading: isResettingErrorCount,
  } = useMutationResetErrorCount();

  useEffect(() => {
    if (isFetchingEntities || isFetchingFilingGroups || isFetchingDataRefreshLogs) {
      setSelectedFilingGroups([]);
      setSelectedEntities([]);
    }
  }, [isFetchingEntities, isFetchingFilingGroups, isFetchingDataRefreshLogs]);

  const onRefreshClick = useCallback(() => {
    queryClient.resetQueries(QueryKeys.FederalDataDiagnostics.Status);
    switch (activeTab) {
      case TabsTypes.AllFederalEntities:
        queryClient.resetQueries(QueryKeys.EntitiesMaintenance.Entities);
        return;
      case TabsTypes.RecentRefreshes:
        queryClient.resetQueries(QueryKeys.FederalDataDiagnostics.RecentRefreshes);
        return;
      case TabsTypes.FilingGroups:
        queryClient.resetQueries(QueryKeys.FederalDataDiagnostics.FilingGroup);
        return;
      default:
        throw new Error('Unsupported tab type');
    }
  }, [queryClient, activeTab]);

  const fileOnlySyncForEntitiesClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'File Only Sync',
        text: 'Are you sure you want to file only sync for the entities have been updated?',
        confirmCallback: async () => {
          await sendFederalEntitiesOrFilingGroupsSyncMsg({
            taxYear,
            isRequestingGTWFilesOnly: true,
            entitiesOrGroups: selectedEntities.map(
              ({ orgId, gtwUpdatedDate, reportingPeriod }) => ({
                entityId: orgId,
                filingGroupId: null,
                gtwUpdatedDate,
                reportingPeriod,
              }),
            ),
          });
        },
      }),
    );
  }, [dispatch, sendFederalEntitiesOrFilingGroupsSyncMsg, selectedEntities, taxYear]);

  const syncFilesForFilingGroupsClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Sync Files',
        text: 'Are you sure you want to sync files for the selected filing groups?',
        confirmCallback: async () => {
          await sendFederalEntitiesOrFilingGroupsSyncMsg({
            taxYear,
            isRequestingGTWFilesOnly: true,
            entitiesOrGroups: selectedFilingGroups.map(({ filingGroupId, gtwUpdatedDate }) => ({
              entityId: null,
              filingGroupId,
              gtwUpdatedDate,
              reportingPeriod: null,
            })),
          });
        },
      }),
    );
  }, [dispatch, sendFederalEntitiesOrFilingGroupsSyncMsg, selectedFilingGroups, taxYear]);

  const informGTWForFilingGroupsClick = useCallback(async () => {
    dispatch(
      showConfirmModal({
        title: 'Inform GTW Group Has Updated',
        text: 'Are you sure you want to inform GTW that selected filing groups have been updated?',
        confirmCallback: async () => {
          await sendFilingGroupsInformGTWMsg({
            filingGroupIds: selectedFilingGroups.map(({ filingGroupId }) => filingGroupId),
            taxYear,
          });
        },
      }),
    );
  }, [dispatch, sendFilingGroupsInformGTWMsg, selectedFilingGroups, taxYear]);

  const resetErrorCountClick = useCallback(async () => {
    const entitiesOrGroups: EntitiesOrGroupsRequest = {
      orgIds: null,
      filingGroupIds: null,
    };

    if (activeTab === TabsTypes.AllFederalEntities) {
      entitiesOrGroups.orgIds = selectedEntities.map(({ orgId }) => orgId);
    }
    if (activeTab === TabsTypes.FilingGroups) {
      entitiesOrGroups.filingGroupIds = selectedFilingGroups.map(
        ({ filingGroupId }) => filingGroupId,
      );
    }

    resetErrorCount({
      taxYear,
      period,
      entitiesOrGroups,
    });
  }, [resetErrorCount, activeTab, selectedEntities, selectedFilingGroups, taxYear, period]);

  const fullSyncForEntitiesClick = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: 'Full Sync',
        text: 'Are you sure you want to full sync files for the selected entities?',
        confirmCallback: async () => {
          await sendFederalEntitiesOrFilingGroupsSyncMsg({
            taxYear,
            isRequestingGTWFilesOnly: false,
            entitiesOrGroups: selectedEntities.map(
              ({ orgId, gtwUpdatedDate, reportingPeriod }) => ({
                entityId: orgId,
                filingGroupId: null,
                gtwUpdatedDate,
                reportingPeriod,
              }),
            ),
          });
        },
      }),
    );
  }, [dispatch, sendFederalEntitiesOrFilingGroupsSyncMsg, selectedEntities, taxYear]);

  const onSelectionChanged = useCallback(
    (e: SelectionChangedEvent) => {
      if (activeTab === TabsTypes.AllFederalEntities) {
        setSelectedEntities(e.api.getSelectedRows());
      }

      if (activeTab === TabsTypes.FilingGroups) {
        setSelectedFilingGroups(e.api.getSelectedRows());
      }
    },
    [activeTab],
  );

  const isProcessing = useMemo(
    () =>
      isFetchingEntities ||
      isFetchingFilingGroups ||
      isFetchingDataRefreshLogs ||
      isFetchingStatus ||
      isSendingFederalEntitiesOrFilingGroupsSyncMsg ||
      isSendingFilingGroupsInformGTWMsg ||
      isResettingErrorCount,
    [
      isFetchingEntities,
      isFetchingFilingGroups,
      isFetchingDataRefreshLogs,
      isFetchingStatus,
      isSendingFederalEntitiesOrFilingGroupsSyncMsg,
      isSendingFilingGroupsInformGTWMsg,
      isResettingErrorCount,
    ],
  );

  return (
    <>
      <SharedHeader title="Refresh Federal Data Diagnostics">
        <div className={styles.subheader}>
          <span> Refreshes In The Last 24 Hours </span>
          <Loading small isLoading={isFetchingStatus}>
            <span> Total: {status?.total} </span>
            <span> Complete: {status?.completed} </span>
            <span> In Progress: {status?.inProgress} </span>
            <span> Unsuccessful: {status?.unsuccessful} </span>
          </Loading>
        </div>
      </SharedHeader>
      <div className={`row ${styles.mainPage}`}>
        <div className={styles.tabSection}>
          <Tabs className={styles.tabs} value={activeTab} size="md" onChange={handleTabChange}>
            {tabsDefinitions.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
          <div className={styles.buttons}>
            {activeTab === TabsTypes.AllFederalEntities ? (
              <>
                <Button
                  size="md"
                  className={styles.button}
                  onClick={fullSyncForEntitiesClick}
                  disabled={isProcessing || !selectedEntities.length}
                  kind="secondary"
                >
                  Full Sync
                </Button>
                <Button
                  size="md"
                  className={styles.button}
                  onClick={fileOnlySyncForEntitiesClick}
                  disabled={isProcessing || !selectedEntities.length}
                  kind="secondary"
                >
                  File Only Sync
                </Button>
                <Button
                  size="md"
                  className={styles.button}
                  onClick={resetErrorCountClick}
                  disabled={isProcessing || !selectedEntities.length}
                  kind="secondary"
                >
                  Reset Error Count
                </Button>
              </>
            ) : (
              ''
            )}
            {activeTab === TabsTypes.FilingGroups ? (
              <>
                <Button
                  size="md"
                  className={styles.button}
                  onClick={syncFilesForFilingGroupsClick}
                  disabled={isProcessing || !selectedFilingGroups.length}
                  kind="secondary"
                >
                  Sync Files
                </Button>
                <Button
                  size="md"
                  className={styles.button}
                  onClick={informGTWForFilingGroupsClick}
                  disabled={isProcessing || !selectedFilingGroups.length}
                  kind="secondary"
                >
                  Inform GTW Group Has Updated
                </Button>
                <Button
                  size="md"
                  className={styles.button}
                  onClick={resetErrorCountClick}
                  disabled={isProcessing || !selectedFilingGroups.length}
                  kind="secondary"
                >
                  Reset Error Count
                </Button>
              </>
            ) : (
              ''
            )}
            <Button
              size="md"
              className={styles.button}
              onClick={onRefreshClick}
              disabled={isProcessing}
              kind="secondary"
            >
              Reload Table
            </Button>
          </div>
        </div>
        <div className={styles.tableFormat}>
          {' '}
          {getActiveTabComponent(activeTab, {
            entities: entities || [],
            filingGroups: filingGroups || [],
            recentRefreshes: recentRefreshes || [],
            isFetchingEntities,
            isFetchingFilingGroups,
            isFetchingDataRefreshLogs,
            onSelectionChanged,
          })}
        </div>
      </div>
    </>
  );
};

export default FederalDataDiagnostics;
