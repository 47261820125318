import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@pwc/appkit-react/lib/Button';
import { Routes } from '@common-packages/routes-definitions';
import {
  hasPermissionToAccess,
  PERMISSION_TYPE,
  PERMISSION,
} from '@common-packages/customer-permissions-utils';

import styles from '../styles.module.scss';
import DevelopmentJurisdictionDropdown from '../../developmentJurisdictionDropdown.container';
import DevelopmentTaxYearDropdown from '../../developmentTaxYearDropdown.container';
import {
  jurisdictionIdSelector,
  taxYearSelector,
  jurisdictionsOptionsSelector,
} from '../../store/selectors';
import { useUpdateDevelopmentContextFromQueryParams } from '../../store/hooks';
import useCompileButtonLogic from '../hooks/useCompileButtonLogic';
import useCompileAllButtonLogic from '../hooks/useCompileAllButtonLogic';
import { ALL_JURISDICTION_ID } from '../../../shared/constants';
import withPermissions from '../../../shared/authorization/withPermissionsHoc.container';
import { customerPermissionsPropTypes } from '../../../shared/propTypes/customerPropTypes';

import LatestCalculationCompileInfo from './latestCalculationCompileInfo/latestCalculationCompileInfo.component';
import CompileExpressionsGrid from './compileExpressionsGrid/compileExpressionsGrid.component';

const CompileExpressions = ({ customerPermissions }) => {
  const history = useHistory();
  useUpdateDevelopmentContextFromQueryParams();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const jurisdictionsOptions = useSelector(jurisdictionsOptionsSelector);

  const areAllJurisdictionsSelected = jurisdictionId === ALL_JURISDICTION_ID;
  const areAllJurisdictionsEnabled = hasPermissionToAccess({
    customerPermissions,
    permissionType: PERMISSION_TYPE.EDIT,
    routeDefinitions: [PERMISSION.COMPILE_EXPRESSIONS_ALL_JURISDICTIONS],
  });

  const {
    compilationResult,
    validationDate,
    handleCompileButtonClick,
    isCompileButtonDisabled,
    isCompiling,
    compilationInfo,
    isFetchingCompilationInfo,
  } = useCompileButtonLogic({ taxYear, jurisdictionId });

  const {
    compilationResult: compilationResultFromAllJurisdictions,
    currentJurisdiction,
    lastJurisdictionData,
    handleCompileAllButtonClick,
    isCompiling: isCompilingAllJurisdictions,
    isCompileOrStopButtonDisabled,
    isStoppingCompilation,
    handleStopPropagationButtonClick,
  } = useCompileAllButtonLogic({
    taxYear,
    jurisdictionId,
    jurisdictionsOptions,
  });

  const handleCompileReportButtonClick = useCallback(() => {
    history.push(Routes.compileExpressionsReport.MAIN);
  }, [history]);

  const handleDisallowOverrideReportButtonClick = useCallback(() => {
    history.push(Routes.disallowOverrideReport.MAIN);
  }, [history]);

  const processingJurisdictionText = useMemo(() => {
    const jurisdictionNumber =
      jurisdictionsOptions.findIndex(({ value }) => value === currentJurisdiction?.value) + 1;
    return `Processing: ${currentJurisdiction?.label}, ${jurisdictionNumber} of ${
      jurisdictionsOptions.length
    } jurisdictions${isStoppingCompilation ? ', stopping process' : ''}`;
  }, [currentJurisdiction, jurisdictionsOptions, isStoppingCompilation]);

  return (
    <>
      <div className="row">
        <div className="col-12 text-right">
          <Button
            size="lg"
            kind="secondary"
            className="add-button"
            onClick={handleDisallowOverrideReportButtonClick}
          >
            Disallow Override Report
          </Button>
          <Button
            size="lg"
            kind="secondary"
            className={`add-button ${styles.compileReportButton}`}
            onClick={handleCompileReportButtonClick}
          >
            Compile Report
          </Button>
          {areAllJurisdictionsSelected ? (
            <Button
              size="lg"
              className="add-button"
              onClick={
                isCompilingAllJurisdictions
                  ? handleStopPropagationButtonClick
                  : handleCompileAllButtonClick
              }
              disabled={isCompileOrStopButtonDisabled}
            >
              {isCompilingAllJurisdictions ? 'Stop Processing' : 'Compile'}
            </Button>
          ) : (
            <Button
              size="lg"
              className="add-button"
              onClick={handleCompileButtonClick}
              disabled={isCompileButtonDisabled}
            >
              Compile
            </Button>
          )}
        </div>
      </div>
      <div className={`row ${styles.contextAndLatestCalcCompileInfo}`}>
        <div className="col-3">
          <DevelopmentTaxYearDropdown isDisabled={isCompiling} />
          <DevelopmentJurisdictionDropdown
            isDisabled={isCompiling}
            enableAllJurisdiction={areAllJurisdictionsEnabled}
            enableEverywhereJurisdiction
          />
        </div>
        <div className={`col-9 ${styles.latestCalcCompileInfo}`}>
          <LatestCalculationCompileInfo
            compilationInfo={compilationInfo}
            compilationResult={
              areAllJurisdictionsSelected
                ? compilationResultFromAllJurisdictions
                : compilationResult
            }
            isFetchingCompilationInfo={isFetchingCompilationInfo}
            validationDate={validationDate}
            isCompiling={areAllJurisdictionsSelected ? isCompilingAllJurisdictions : isCompiling}
            areAllJurisdictionsSelected={areAllJurisdictionsSelected}
          >
            {isCompilingAllJurisdictions && areAllJurisdictionsSelected && (
              <span className={styles.processingInformation}>{processingJurisdictionText}</span>
            )}
            {!isCompilingAllJurisdictions && areAllJurisdictionsSelected && lastJurisdictionData && (
              <span className={styles.processingInformation}>
                Last jurisdiction processed: {lastJurisdictionData.lastJurisdiction.label},{' '}
                {lastJurisdictionData.processedJurisdictions} of{' '}
                {lastJurisdictionData.totalJurisdictions} jurisdictions
              </span>
            )}
          </LatestCalculationCompileInfo>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <CompileExpressionsGrid
            compilationResult={
              areAllJurisdictionsSelected
                ? compilationResultFromAllJurisdictions
                : compilationResult
            }
            isLoading={isCompiling || isFetchingCompilationInfo}
            areAllJurisdictionsSelected={areAllJurisdictionsSelected}
          />
        </div>
      </div>
    </>
  );
};

CompileExpressions.propTypes = {
  customerPermissions: customerPermissionsPropTypes,
};

export default withPermissions(CompileExpressions);
