import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import Page from '../shared/displayComponents/page.component';
import {
  GlobalContextDropdowns,
  GlobalContextValues,
} from '../shared/containerComponents/globalContext';
import { StaticHomeBreadcrumb } from '../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';
import { EntityDashboard, EntityDashboardGlobalContextDropdowns } from '../entity';
import {
  FilingGroupDashboard,
  FilingGroupDashboardGlobalContextDropdowns,
} from '../stateFilingGroups';
import {
  FilingGroupDashboard as FederalProFormaFilingGroupDashboard,
  FilingGroupDashboardGlobalContextDropdowns as FederalProFormaDashboardGlobalContextDropdowns,
} from '../federalProFormaFilingGroups';
import { TasksDashboard, TasksDashboardGlobalContextDropdowns } from '../tasks';
import { ToolsDashboard } from '../tools';

import ComplianceRoute from './routeConfiguration/complianceRoute';
import AuthorizedComplianceRoute from './routeConfiguration/authorizedComplianceRoute';

const ComplianceBreadcrumbRoutes = () => <StaticHomeBreadcrumb routePath={Routes.root.MAIN} />;

const ComplianceRoutes = () => (
  <Page
    globalContextValues={<GlobalContextValues />}
    breadcrumbRoutes={ComplianceBreadcrumbRoutes}
    hideGlobalContext
  >
    <ComplianceRoute
      path={Routes.compliance.MAIN}
      exact
      component={EntityDashboard}
      globalContextDropdowns={<EntityDashboardGlobalContextDropdowns />}
    />

    <Switch>
      <AuthorizedComplianceRoute
        path={Routes.complianceStateFilingGroups.MAIN}
        component={FilingGroupDashboard}
        globalContextDropdowns={<FilingGroupDashboardGlobalContextDropdowns />}
      />

      <AuthorizedComplianceRoute
        path={Routes.complianceFederalProformaFilingGroups.MAIN}
        component={FederalProFormaFilingGroupDashboard}
        globalContextDropdowns={<FederalProFormaDashboardGlobalContextDropdowns />}
      />

      <AuthorizedComplianceRoute
        path={Routes.complianceTasks.MAIN}
        component={TasksDashboard}
        globalContextDropdowns={<TasksDashboardGlobalContextDropdowns />}
      />

      <ComplianceRoute
        path={Routes.complianceTools.MAIN}
        component={ToolsDashboard}
        globalContextDropdowns={<GlobalContextDropdowns isFetchingContext={false} />}
      />
    </Switch>
  </Page>
);

export default ComplianceRoutes;
