import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Document, Page } from 'react-pdf';

import Loading from '../displayComponents/loading.component';

import PdfToolbar from './pdfToolbar.component';
import style from './pdfDisplay.module.scss';

const PdfDisplay = ({
  pdfImage,
  isFetchingPdfImage,
  onDownload,
  closePdfViewer,
  infoNotification,
  noPdfMessage = 'No PDF document available',
}) => {
  const [pdfPageNumber, setPdfPageNumber] = useState(0);
  const [showOutline, setShowOutline] = useState(false);
  const [animationEffect, setAnimationEffect] = useState(null);
  const [expandedMode, setExpandedMode] = useState(false);

  useEffect(() => {
    if (pdfImage && pdfImage.size === 0) {
      infoNotification('Attachment is empty');
      closePdfViewer();
    }
  }, [infoNotification, closePdfViewer, pdfImage]);

  const pageRefs = useRef({});

  const handleShowHideBookmarks = useCallback(() => {
    if (!animationEffect) {
      setShowOutline(true);
      return setAnimationEffect('fadeInDown');
    }

    if (animationEffect === 'fadeInDown') {
      return setAnimationEffect('fadeOutUp');
    }

    return setAnimationEffect('fadeInDown');
  }, [animationEffect]);

  const resetAnimation = useCallback(() => {
    if (animationEffect === 'fadeOutUp') {
      setShowOutline(false);
      setAnimationEffect(null);
    }
  }, [animationEffect]);

  const handleDownloadPdf = useCallback(() => onDownload(), [onDownload]);

  const handleExpandedMode = useCallback(() => setExpandedMode(!expandedMode), [expandedMode]);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setPdfPageNumber(numPages);
    },
    [setPdfPageNumber],
  );

  const isPdfDocumentReady = pdfImage && pdfImage.size > 0;

  return (
    <Loading isLoading={isFetchingPdfImage}>
      <div
        className={classNames(style.pdfViewerContainer, {
          [style.pdfViewerContainerExpanded]: expandedMode,
          [style.pdfBookmarksOpen]: showOutline,
        })}
      >
        {isPdfDocumentReady ? (
          <Document file={pdfImage} onLoadSuccess={onDocumentLoadSuccess} loading="">
            <PdfToolbar
              page={pageRefs.current}
              handleShowHideBookmarks={handleShowHideBookmarks}
              handleDownloadPdf={handleDownloadPdf}
              handleExpandedMode={handleExpandedMode}
              resetAnimation={resetAnimation}
              animationEffect={animationEffect}
              showOutline={showOutline}
              closePdfViewer={closePdfViewer}
            />
            {Array.from(new Array(pdfPageNumber), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                loading=""
                className={style.pdfPage}
                inputRef={ref => (pageRefs.current[index + 1] = ref)}
                scale={1.9}
              />
            ))}
          </Document>
        ) : (
          <div className={style.noPdf}>{noPdfMessage}</div>
        )}
      </div>
    </Loading>
  );
};

PdfDisplay.propTypes = {
  isFetchingPdfImage: PropTypes.bool.isRequired,
  pdfImage: PropTypes.shape({
    size: PropTypes.number,
    type: PropTypes.string,
  }),
  onDownload: PropTypes.func.isRequired,
  closePdfViewer: PropTypes.func.isRequired,
  infoNotification: PropTypes.func.isRequired,
  noPdfMessage: PropTypes.string,
};

export default PdfDisplay;
