import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

import Loading from '../displayComponents/loading.component';
import { entityIdSelector, taxYearSelector, periodSelector } from '../store/selectors';
import { createPdfAttachment, fetchPdfAttachments } from '../store/pdfAttachments/actions';
import { isCreatingPdfAttachmentSelector } from '../store/pdfAttachments/selectors';
import { preparePdfFormDataWithJsonPayload } from '../../utils/preparePdfFormDataWithJsonPayload';

import AddPdfAttachmentForm from './addPdfAttachmentForm.component';

const { pdfAttachmentsSchema } = require('@common-packages/validators');

const formInitialValues = {
  uploadName: '',
  uploadDescription: '',
  printOrder: '1',
  efileAttach: true,
  rolloverToNextYear: false,
  pdfFile: null,
};

const AddPdfAttachmentModal = ({
  pdfJurisdictionId,

  hideModal,
  visible,
}) => {
  const dispatch = useDispatch();
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const entityId = useSelector(entityIdSelector);
  const isCreatingPdfAttachment = useSelector(isCreatingPdfAttachmentSelector);

  const savePdfAttachment = useCallback(
    async (
      { uploadName, uploadDescription, printOrder, efileAttach, rolloverToNextYear, pdfFile },
      { setSubmitting },
    ) => {
      setSubmitting(true);
      await dispatch(
        createPdfAttachment({
          formData: preparePdfFormDataWithJsonPayload({
            formValues: {
              taxYear,
              period,
              entityId,
              jurisdictionId: pdfJurisdictionId,
              uploadName,
              uploadDescription,
              printOrder: Number(printOrder),
              efileAttach,
              rolloverToNextYear,
            },
            pdfFile,
          }),
        }),
      );
      setSubmitting(false);
      dispatch(
        fetchPdfAttachments({
          taxYear,
          period,
          entityId,
          jurisdictionId: pdfJurisdictionId,
        }),
      );
      hideModal();
    },
    [dispatch, entityId, hideModal, pdfJurisdictionId, period, taxYear],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        title="Add global PDF attachment"
        closeAction={hideModal}
        visible={visible}
        submitAction={formikProps.submitForm}
        dismissAction={hideModal}
      >
        <Loading isLoading={isCreatingPdfAttachment}>
          <AddPdfAttachmentForm {...formikProps} onCancelClick={hideModal} visible={visible} />
        </Loading>
      </Modal>
    ),
    [hideModal, isCreatingPdfAttachment, visible],
  );

  return (
    <Formik
      onSubmit={savePdfAttachment}
      initialValues={formInitialValues}
      validateOnBlur={false}
      validationSchema={pdfAttachmentsSchema.commonSchema}
    >
      {renderForm}
    </Formik>
  );
};

AddPdfAttachmentModal.propTypes = {
  pdfJurisdictionId: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default AddPdfAttachmentModal;
