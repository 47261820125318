import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import omit from 'lodash.omit';
import { Routes } from '@common-packages/routes-definitions';
import { Button } from '@pwc/appkit-react/lib/Button';

import Loading from '../../shared/displayComponents/loading.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import Dropzone from '../../shared/forms/dropzone/dropzone.container';
import { periodSelector, taxYearSelector } from '../../shared/store/selectors';
import { loadDataFromExcelFileBuffer } from '../shared/excel.utils';
import styles from '../shared/dataImportPage.module.scss';

import { fetchValidatedMultData, uploadMultData, resetValidatedMultData } from './store/actions';
import {
  validatedMultDataSelector,
  isFetchingValidatedMultDataSelector,
  isUploadDataValidSelector,
  isUploadingMultDataSelector,
} from './store/selectors';
import columnDefinitions from './multDataUpload.columnDefinitions';

const DOCUMENT_ACCEPTED_TYPES = ['.xlsx'];
const EXCEL_COLUMN_NAMES = [
  'datasetDefinitionName',
  'jurisdictionId',
  'orgId',
  'consolId',
  'multId',
  'parentMultId',
  'dataItemName',
  'value',
];

const convertValuesToText = rows =>
  rows.map(
    ({
      datasetDefinitionName,
      jurisdictionId,
      orgId,
      consolId,
      multId,
      parentMultId,
      dataItemName,
      value,
    }) => ({
      datasetDefinitionName: `${datasetDefinitionName}`,
      jurisdictionId: `${jurisdictionId}`,
      orgId: orgId ? `${orgId}` : null,
      consolId: consolId ? `${consolId}` : null,
      multId: multId ? `${multId}` : '',
      parentMultId: parentMultId ? `${parentMultId}` : '',
      dataItemName: dataItemName ? `${dataItemName}` : '',
      value: value ? `${value}` : '',
    }),
  );

const MultDataUpload = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [isParsing, setIsParsing] = useState(false);

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const validatedMultData = useSelector(validatedMultDataSelector);
  const isFetchingValidatedMultData = useSelector(isFetchingValidatedMultDataSelector);
  const isUploadDataValid = useSelector(isUploadDataValidSelector);
  const isUploadingMultData = useSelector(isUploadingMultDataSelector);

  useEffect(() => {
    dispatch(resetValidatedMultData());
  }, [dispatch]);

  const onDrop = useCallback(file => {
    setFile(file);
    setIsParsing(true);
  }, []);

  const onFileBufferLoaded = useCallback(
    async fileBuffer => {
      const rows = await loadDataFromExcelFileBuffer({
        fileBuffer,
        excelColumnNames: EXCEL_COLUMN_NAMES,
      });
      setIsParsing(false);
      dispatch(fetchValidatedMultData({ taxYear, period, rows: convertValuesToText(rows) }));
    },
    [dispatch, taxYear, period],
  );

  const uploadValidatedMultData = useCallback(async () => {
    await dispatch(
      uploadMultData({
        taxYear,
        period,
        rows: validatedMultData.map(row => omit(row, ['isValid', 'result'])),
      }),
    );
    setFile(null);
  }, [dispatch, taxYear, period, validatedMultData]);

  return (
    <>
      <div className="row">
        <div className="col">
          <Dropzone
            className={styles.dropzone}
            value={file}
            onDropCallback={onDrop}
            onFileBufferLoaded={onFileBufferLoaded}
            acceptTypes={DOCUMENT_ACCEPTED_TYPES}
          />
        </div>
      </div>
      <div className="row">
        <div className={`col ${styles.fileFormat}`}>
          <div className={styles.fileFormatHeader}>File Format:</div>
          <div>
            Dataset Name, Jurisdiction Id, Org Id, Consol Id, Mult Id, Parent Mult Id, Data Item
            Name, Value
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col add-button-column">
          <Link to={{ pathname: Routes.complianceTools.MAIN }} className={styles.cancelLink}>
            <Button size="lg" className="add-button" kind="secondary">
              Cancel
            </Button>
          </Link>
          <Button
            size="lg"
            className="add-button"
            disabled={!isUploadDataValid || isFetchingValidatedMultData || isUploadingMultData}
            onClick={uploadValidatedMultData}
          >
            <Loading isLoading={isUploadingMultData} small>
              Upload
            </Loading>
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={validatedMultData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingValidatedMultData || isParsing}
          />
        </div>
      </div>
    </>
  );
};

export default MultDataUpload;
