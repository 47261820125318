import { adminColumnDefinition, defaultColumnDefinition } from '../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onDeleteIconClick, hasUserPermissionsToEdit }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowDeleteIcon: hasUserPermissionsToEdit,
    shouldShowEditIcon: false,
  });
  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Business',
      field: 'businessId',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Attachment Type',
      field: 'attachmentTypeDescription',
      width: 200,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'File Name',
      field: 'fileName',
      width: 400,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Submission File Name',
      field: 'submissionFileName',
      width: 400,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Attachment Description',
      field: 'attachmentDescription',
      width: 400,
    },
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
