import PropTypes from 'prop-types';

import { generateTreeShapePropTypes } from '../../shared/propTypes/generateTreeShapePropTypes';

import { PdfPageDataUsageTypes } from './constants';

export const dataItemType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  displayName: PropTypes.string,
  displayOrder: PropTypes.number.isRequired,
  dataType: PropTypes.string.isRequired,
  datasetId: PropTypes.string.isRequired,
  dataLength: PropTypes.number,
  calculationId: PropTypes.string,
  isHidden: PropTypes.number.isRequired,
});

export const pdfPageDataUsagePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  parentId: PropTypes.string,
  type: PropTypes.oneOf(Object.values(PdfPageDataUsageTypes)).isRequired,
  idWithPageNumber: PropTypes.string,
  datasetId: PropTypes.string,
  treeHierarchyId: PropTypes.string.isRequired,
  treeHierarchyParentId: PropTypes.string,
});

export const availableDatasetPropTypes = PropTypes.shape({
  dataModelId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string,
});

export const availableDatasetsTreePropTypes = generateTreeShapePropTypes(availableDatasetPropTypes);

export const assignedDatasetsTreePropTypes = generateTreeShapePropTypes(pdfPageDataUsagePropTypes);
