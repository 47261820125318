import React from 'react';

import config from '../../../config';

import styles from './loadingPage.module.scss';

const LoadingPage = ({
  header,
  details,
  imgPath,
}: {
  header: string;
  details: string[];
  imgPath?: string;
}) => (
  <div className={styles.validationProcessRunningContainer}>
    <div className={styles.imageContainer}>
      <img
        alt="efile-loading-icon"
        src={
          imgPath
            ? `${config.BASE_PATH}${imgPath}`
            : `${config.BASE_PATH}/assets/efile-loading-icon.png`
        }
      />
    </div>
    <div className={styles.header}>{header}</div>
    {details.map((detail, index) => (
      <span key={index}>{detail}</span>
    ))}
  </div>
);

export default LoadingPage;
