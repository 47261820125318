import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { REPORT_TYPES } from '../../shared/constants';

import { fetchCustomReports } from './store/actions';
import { customReportsSelector, isFetchingCustomReportsSelector } from './store/selectors';
import columnDefinitions from './trgReports.columnDefinitions';
import './runCustomReports.style.scss';

const RunTrgReports = ({ trgReports, isFetchingTrgReports, fetchCustomReports }) => {
  useEffect(() => {
    fetchCustomReports({ reportType: REPORT_TYPES.TRG });
  }, [fetchCustomReports]);

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={trgReports}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingTrgReports}
        />
      </div>
    </div>
  );
};

RunTrgReports.propTypes = {
  trgReports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  isFetchingTrgReports: PropTypes.bool.isRequired,
  fetchCustomReports: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    trgReports: customReportsSelector(state),
    isFetchingTrgReports: isFetchingCustomReportsSelector(state),
  }),
  {
    fetchCustomReports,
  },
)(RunTrgReports);
