import React from 'react';
import { Field } from 'formik';

import Input from '../../../shared/forms/inputFormik/inputFormik.component';

const EditSelectionListForm = () => {
  return (
    <div>
      <Field
        className="form-text"
        label="Selection List Name"
        name="name"
        component={Input}
        autoComplete="off"
      />
      <Field
        className="form-text"
        label="Selection List Description"
        name="description"
        component={Input}
        autoComplete="off"
      />
    </div>
  );
};

export default EditSelectionListForm;
