import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchCategoriesTypes = getApiActionTypes('DEVELOPMENT/CALC_SPECS/FETCH_CATEGORIES');
export const fetchCalcSpecsOptionsTypes = getApiActionTypes('DEVELOPMENT/FETCH_CALC_SPECS_OPTIONS');

export const fetchBaseCalcSpecsTypes = getApiActionTypes('DEVELOPMENT/FETCH_BASE_CALC_SPECS');
export const updateBaseCalcSpecTypes = getApiActionTypes('DEVELOPMENT/UPDATE_BASE_CALC_SPEC');
export const createBaseCalcSpecTypes = getApiActionTypes('DEVELOPMENT/CREATE_BASE_CALC_SPEC');

export const fetchConsolCalcSpecsTypes = getApiActionTypes('DEVELOPMENT/FETCH_CONSOL_CALC_SPECS');
export const updateConsolCalcSpecsTypes = getApiActionTypes('DEVELOPMENT/UPDATE_CONSOL_CALC_SPECS');
export const createConsolCalcSpecsTypes = getApiActionTypes('DEVELOPMENT/CREATE_CONSOL_CALC_SPECS');

export const fetchK1CalcSpecsTypes = getApiActionTypes('DEVELOPMENT/FETCH_K1_CALC_SPECS');
export const fetchK1CalcSpecsOptionsTypes = getApiActionTypes(
  'DEVELOPMENT/FETCH_K1_CALC_SPECS_OPTIONS',
);
export const updateK1CalcSpecsTypes = getApiActionTypes('DEVELOPMENT/UPDATE_K1_CALC_SPECS');
export const createK1CalcSpecsTypes = getApiActionTypes('DEVELOPMENT/CREATE_K1_CALC_SPECS');

export const fetchConspbCalcSpecsTypes = getApiActionTypes('DEVELOPMENT/FETCH_CONSPB_CALC_SPEC');
export const updateConspbCalcSpecsTypes = getApiActionTypes('DEVELOPMENT/UPDATE_CONSPB_CALC_SPEC');
export const createConspbCalcSpecsTypes = getApiActionTypes('DEVELOPMENT/CREATE_CONSPB_CALC_SPEC');

export const BLOCK_CALC_SPEC_CONTEXT_CHANGES = 'BLOCK_CALC_SPEC_CONTEXT_CHANGES';
