import React, { useCallback } from 'react';
import { Switch, Tooltip } from '@pwc/appkit-react';
import { FormikProps } from 'formik';

import AppkitIcon from '../../displayComponents/appkitIcon/appkitIcon.component';

import styles from './switchFormik.module.scss';

interface SwitchFormikProps {
  field: { name: string; value: string | number };
  form: FormikProps<unknown>;
  className?: string;
  onText?: string;
  offText?: string;
  defaultChecked?: boolean;
  size?: string;
  label?: string;
  disabled?: boolean;
  tooltipPlacement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end';
  tooltipContent?: string | Node;
  tooltipContainerId?: string;
  customChangeHandler?: (name?: string, value?: boolean) => unknown;
}

const SwitchFormik = ({
  field: { value, name },
  form: { setFieldValue },
  className = '',
  onText,
  offText,
  size = 'small',
  label = '',
  disabled = false,
  tooltipPlacement = 'top',
  tooltipContent,
  tooltipContainerId,
  customChangeHandler = () => null,
}: SwitchFormikProps) => {
  const handleToggle = useCallback(
    async (checked: boolean) => {
      await ((setFieldValue(name, checked) as unknown) as Promise<void>);
      customChangeHandler(name, checked);
    },
    [customChangeHandler, setFieldValue, name],
  );

  return (
    <div className={styles.switchContainer}>
      <Switch
        value={value}
        className={className}
        defaultChecked={value}
        onText={onText}
        offText={offText}
        size={size}
        label={label}
        disabled={disabled}
        onChange={handleToggle}
      />
      {tooltipContent && (
        <Tooltip
          content={tooltipContent}
          placement={tooltipPlacement}
          className={styles.switchTooltip} // resolve the tooltip content covered by the modal
          containerid={tooltipContainerId} // by default tooltip will append to body
        >
          <AppkitIcon
            icon="information"
            type="outline"
            size={16}
            className={styles.switchTooltipIcon}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default SwitchFormik;
