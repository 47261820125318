import React from 'react';
import classnames from 'classnames';

import fileFormatStyles from './fileFormat.module.scss';

interface FileFormatValidityIndicatorProps {
  value: string;
  isValidFileFormat: boolean;
}

const FileFormatValidityIndicator = ({
  value,
  isValidFileFormat,
}: FileFormatValidityIndicatorProps) => {
  const displayText = `- ${isValidFileFormat ? '' : '[Missing]'} ${value}`;

  return (
    <span
      className={classnames(fileFormatStyles.fileFormatText, {
        [fileFormatStyles.fileFormatTextIncorrect]: !isValidFileFormat,
      })}
    >
      {displayText}
    </span>
  );
};

export default FileFormatValidityIndicator;
