import { dataTypes } from '@common-packages/shared-constants';

import { defaultColumnDefinition, defaultNoDataColumn } from '../shared/columnDefinitions';
import {
  getEditorSelector,
  getDataTypeBasedCellRenderer,
} from '../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import { amountFormatter, dateCellFormatter } from '../shared/formatters';

const widthNames = {
  NARROW: 'narrow',
  NORMAL: 'normal',
  WIDE: 'wide',
};

const widths = {
  [widthNames.NARROW]: 85,
  [widthNames.NORMAL]: 150,
  [widthNames.WIDE]: 350,
};

const getCellMeta = ({ colDef, data }) => {
  const fieldName = colDef.field;
  if (!data.meta) {
    return {};
  }

  const cellMeta = data.meta[fieldName];
  return cellMeta ? cellMeta : {};
};

const getCellStylings = row => {
  const cellMeta = getCellMeta(row);
  if (!cellMeta.styling) {
    return {};
  }

  return cellMeta.styling;
};

const getCellDataType = row => getCellMeta(row).dataType;

const getColSpan = numColumns => ({ columnApi, colDef, data }) => {
  const fieldName = colDef.field;
  const firstColumnFieldName = columnApi?.columnController?.columnDefs?.[0].field;
  return data.emptyRow && fieldName === firstColumnFieldName ? numColumns : 1;
};

const getCellClassRules = isInEditMode => ({
  'bold-cell': row => getCellStylings(row).bold,
  'border-cell': row => getCellStylings(row).border,
  'underlined-cell': row => getCellStylings(row).underline,
  'double-underlined-cell': row => getCellStylings(row).doubleUnderline,
  'editable-cell': row => {
    if (getCellDataType(row) === dataTypes.BOOLEAN) {
      return isInEditMode && getCellMeta(row).editable;
    }
    return row.colDef.editable(row);
  },
});

const getValueFormatter = row => {
  const cellDataType = getCellDataType(row);
  const cellStyling = getCellStylings(row);
  const amountFormatterDataTypes = [dataTypes.CURRENCY, dataTypes.RATIO, dataTypes.INTEGER];

  if (amountFormatterDataTypes.includes(cellDataType)) {
    if (cellDataType === dataTypes.CURRENCY && row.value === null) {
      return '';
    }
    return amountFormatter({
      value: row.value,
      props: cellStyling,
    });
  }

  if (cellDataType === dataTypes.STRING && cellStyling.capitalized) {
    return row.value.toUpperCase();
  }

  if (cellDataType === dataTypes.DATE) {
    return dateCellFormatter(row);
  }

  return row.value;
};

const onCellValueChangedBusinessLogic = ({ gridApi, updateRow }) => row => {
  const { colDef, data } = row;
  updateRow(data);

  if (getCellDataType(row) === dataTypes.OVERPAYMENT_OPTION) {
    const fieldValue = data[colDef.field];

    // Reset "Amount of Overpayment to be Applied" when "Overpayment Option"
    // is not "Apply Partial Overpayment, Refund Remainder"
    if (Number(fieldValue) !== 2) {
      const { targetCoordinates = null } = getCellMeta(row);

      if (!targetCoordinates) {
        return;
      }
      targetCoordinates.forEach(({ rowId, fieldNames }) =>
        fieldNames.forEach(fieldName => {
          const rowToUpdate = gridApi.getRowNode(rowId);
          rowToUpdate.data[fieldName] = '0';
          updateRow(rowToUpdate.data);
        }),
      );
    }
  }

  if (getCellDataType(row) === dataTypes.FIRST_INSTALLMENT_OPTION) {
    const fieldValue = data[colDef.field];

    // Reset "First Installment of Estimated Tax from Extension" when "First Installment Option"
    // is not "Use Input From Extension"
    if (Number(fieldValue) !== 1) {
      const { targetCoordinates = null } = getCellMeta(row);

      if (!targetCoordinates) {
        return;
      }
      targetCoordinates.forEach(({ rowId, fieldNames }) =>
        fieldNames.forEach(fieldName => {
          const rowToUpdate = gridApi.getRowNode(rowId);
          rowToUpdate.data[fieldName] = '0';
          updateRow(rowToUpdate.data);
        }),
      );
    }
  }
};

const editableBusinessLogic = ({ gridApi, row, parentRowId, parentFieldName }) => {
  if (!getCellMeta(row).editable) {
    return false;
  }
  const parentRowData = gridApi.getRowNode(parentRowId).data;

  // Disable ability to edit "Amount of Overpayment to be Applied" when "Overpayment Option"
  // is not "Apply Partial Overpayment, Refund Remainder"
  const isDisabledOverpaymentOption =
    parentRowData.meta[parentFieldName].dataType === dataTypes.OVERPAYMENT_OPTION &&
    Number(parentRowData[parentFieldName]) !== 2;

  // Disable ability to edit First Installment of Estimated Tax from Extension" when First Installment Option"
  // is not "Use Input From Extension"
  const isDisabledFirstInstallmentOption =
    parentRowData.meta[parentFieldName].dataType === dataTypes.FIRST_INSTALLMENT_OPTION &&
    Number(parentRowData[parentFieldName]) !== 1;

  if (isDisabledOverpaymentOption || isDisabledFirstInstallmentOption) {
    return false;
  }

  return true;
};

const isCellEditable = ({ gridApi, row }) => {
  if (getCellDataType(row) === dataTypes.BOOLEAN) {
    return false;
  }

  const { parentCoordinates = null, editable } = getCellMeta(row);

  if (!parentCoordinates) {
    return editable;
  }
  const { rowId: parentRowId, fieldName: parentFieldName } = parentCoordinates;

  return editableBusinessLogic({ gridApi, row, parentRowId, parentFieldName });
};

const getDrillCellData = row => {
  const { drillData } = getCellMeta(row);

  return drillData;
};

const getColumnsBasedOnColumnBlueprint = ({
  gridApi,
  updateRow,
  isInEditMode,
  column: columnBlueprint,
  numColumns,
  context,
}) => ({
  ...defaultColumnDefinition,
  headerName: columnBlueprint.displayName,
  field: columnBlueprint.field,

  sortable: false,
  editable: row => isInEditMode && isCellEditable({ gridApi, row }),
  cellRenderer: row =>
    getDataTypeBasedCellRenderer({
      dataType: getCellDataType(row),
      meta: getCellMeta(row),
      valueFormatted: getValueFormatter(row),
      context,
      getDrillData: getDrillCellData,
    })(row),
  cellRendererParams: {
    isDisabled: row => !isInEditMode && !isCellEditable({ gridApi, row }),
  },
  cellEditorSelector: row => getEditorSelector(getCellDataType(row))(row),
  onCellValueChanged: onCellValueChangedBusinessLogic({ gridApi, updateRow }),

  colSpan: getColSpan(numColumns),
  cellClassRules: getCellClassRules(isInEditMode),
  cellStyle: row => ({
    textAlign:
      getCellDataType(row) !== dataTypes.BOOLEAN ? getCellStylings(row).align || 'left' : 'center',
  }),
  ...(columnBlueprint.width
    ? {
        width: widths[columnBlueprint.width],
        minWidth:
          columnBlueprint.width === widthNames.NARROW
            ? widths[widthNames.NARROW]
            : widths[columnBlueprint.width] - 50,
        maxWidth:
          columnBlueprint.width === widthNames.NARROW
            ? widths[widthNames.NARROW]
            : widths[columnBlueprint.width] + 150,
      }
    : {}),
});

const columnDefinitions = ({ gridApi, updateRow, isInEditMode, columnsBlueprint, context }) => {
  // fallback for no data to show user "no rows to show"
  if (!columnsBlueprint.length) {
    return [defaultNoDataColumn];
  }

  return columnsBlueprint.map(column =>
    getColumnsBasedOnColumnBlueprint({
      gridApi,
      updateRow,
      isInEditMode,
      column,
      numColumns: columnsBlueprint.length,
      context,
    }),
  );
};

export default columnDefinitions;
