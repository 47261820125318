import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@tls/ui-modal';
import { Radio, RadioGroup } from '@pwc/appkit-react';

import styles from './radioModalComponent.module.scss';

interface ModalData {
  value: string;
  label: string;
}

interface RadioModalProps {
  modalData?: ModalData[] | null;
  title: string;
  visible: boolean;
  submitText: string;
  dismissText: string;
  hideModal: () => void;
  submitAction?: (selectedOption?: string | null | undefined) => void;
  modalMessage?: string;
  defaultOption?: string;
}

const RadioModal = ({
  modalData,
  title,
  visible,
  submitText = 'Ok',
  dismissText = 'Cancel',
  hideModal,
  submitAction,
  modalMessage,
  defaultOption,
}: RadioModalProps) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const submitActionWithHide = useCallback(() => {
    if (submitAction) {
      submitAction(selectedOption);
      hideModal();
    }
  }, [submitAction, hideModal, selectedOption]);

  const handleOptionChange = useCallback(
    (e: Event, value: string) => {
      setSelectedOption(value);
    },
    [setSelectedOption],
  );

  useEffect(() => {
    if (!visible) {
      setSelectedOption(defaultOption);
    }
  }, [visible, defaultOption]);

  return (
    <Modal
      className={styles.modalContainer}
      title={title}
      hideModal={hideModal}
      visible={visible}
      closeAction={hideModal}
      dismissAction={hideModal}
      submitText={submitText}
      dismissText={dismissText}
      submitAction={submitAction ? submitActionWithHide : null}
      submitCallback={submitAction ? submitActionWithHide : null}
    >
      <fieldset>
        {modalMessage}
        <RadioGroup
          onChange={handleOptionChange}
          defaultValue={defaultOption}
          value={selectedOption}
          className={styles.radioOptionWarpper}
        >
          {modalData?.map((option, index) => (
            <Radio key={index} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </fieldset>
    </Modal>
  );
};

export default RadioModal;
