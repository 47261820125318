import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import { FormFields } from '../../../../common/types';
import { IndividualEntity } from '../../../../common/types/apiShapes';

import { individualPropTypes } from './individual.propTypes';
import styles from './individualForm.module.scss';

interface IndividualFormProps {
  initialValues: IndividualEntity | null;
  isEditMode: boolean;
  isSubmitting: boolean;
  jurisdictionOptions?: Array<FormFields> | null;
  businessDescriptionOptions?: Array<FormFields> | null;
  resetForm: () => void;
  values: IndividualEntity;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  entities: Array<IndividualEntity>;
  submitForm: () => void;
}

const IndividualForm = ({
  submitForm,
  isEditMode,
  isSubmitting,
  jurisdictionOptions,
  businessDescriptionOptions,
  resetForm,
  values,
  entities,
}: IndividualFormProps) => {
  const { dirty } = useFormikContext();

  const resetFormToInitial = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const orgIdUniquenessValidation = useCallback(
    (value: string) => {
      if (value && !isEditMode && entities.find(entity => entity.orgId === value)) {
        return 'Organizational Id must be unique';
      }
      return;
    },
    [entities, isEditMode],
  );

  return (
    <>
      <div>
        <Field
          className="form-text"
          label="Tax Year"
          name="taxYear"
          component={Input}
          autoComplete="off"
          disabled
          dirty={dirty}
        />
        <SDKCustomSelect
          wrapperClassName="form-text"
          appkitLabel="Business"
          name="businessId"
          options={businessDescriptionOptions ?? []}
          showSearchOnToggle
          value={values.businessId}
        />
        <Field
          className="form-text"
          label="Individual Id"
          name="orgId"
          component={Input}
          autoComplete="off"
          disabled={isEditMode}
          maxLength={20}
          validate={orgIdUniquenessValidation}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="First Name"
          name="firstName"
          component={Input}
          maxLength={200}
          dirty={dirty}
        />
        <Field
          className={`${styles.middleInitial}`}
          label="Middle Initial"
          name="middleName"
          component={Input}
          maxLength={1}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="Last Name"
          name="lastName"
          component={Input}
          maxLength={200}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="Name Suffix"
          name="suffix"
          component={Input}
          maxLength={200}
          dirty={dirty}
        />
        <SDKCustomSelect
          wrapperClassName="form-text"
          appkitLabel="State of Residency"
          name="partnerStateOfResidency"
          options={jurisdictionOptions ?? []}
          showSearchOnToggle
          value={values.partnerStateOfResidency}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="Member Number"
          name="membership"
          component={Input}
          maxLength={25}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="Social Security Number"
          name="ssn"
          component={Input}
          autoComplete="off"
          maxLength={20}
          dirty={dirty}
          placeholder={values?.shouldSsnMask ? 'XXX-XX-XXXX' : ''}
        />
        <Field
          className="form-text"
          label="Spouse First Name"
          name="spouseFirstName"
          component={Input}
          maxLength={200}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="Spouse Middle Name"
          name="spouseMiddleName"
          component={Input}
          maxLength={200}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="Spouse Last Name"
          name="spouseLastName"
          component={Input}
          maxLength={200}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="Spouse Name Suffix"
          name="spouseSuffix"
          component={Input}
          maxLength={200}
          dirty={dirty}
        />
        <Field
          className="form-text"
          label="Spouse Social Security Number"
          name="spouseSsn"
          component={Input}
          autoComplete="off"
          maxLength={20}
          dirty={dirty}
          placeholder={values?.shouldSpouseSsnMask ? 'XXX-XX-XXXX' : ''}
        />
      </div>
      <div>
        <ButtonsGroup
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={resetFormToInitial}
          submitButtonLabel="Save"
          disabled={!dirty}
        />
      </div>
    </>
  );
};

IndividualForm.propTypes = {
  initialValues: individualPropTypes.isRequired,
  entities: PropTypes.arrayOf(individualPropTypes),
  isEditMode: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  jurisdictionOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  resetForm: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: individualPropTypes.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default IndividualForm;
