import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEqual from 'lodash.isequal';
import { selectionListsSchemas } from '@common-packages/validators';

import {
  useMutationInsertSelectionList,
  useMutationUpdateSelectionList,
} from '../../../shared/mutations/selectionLists';
import { useRowEditMode } from '../../../shared/editMode';
import { selectionListPropTypes } from '../selectionListsPropTypes';

import EditSelectionListForm from './editSelectionListForm.component';

const newSelectionList = {
  id: '',
  name: '',
  description: '',
  items: [],
};

const getUniqueRowId = ({ data: { id } }) => id;

const EditSelectionList = forwardRef(
  ({ selectionListToEdit, taxYear, clearForm, jurisdictionId, selectionLists }, ref) => {
    const formRef = useRef(null);

    const [areSelectionListItemsValid, setAreSelectionListItemsValid] = useState(true);
    const [areSelectionListItemsDirty, setAreSelectionListItemsDirty] = useState(false);
    const isEditMode = Boolean(selectionListToEdit);
    const insertSelectionList = useMutationInsertSelectionList();
    const updateSelectionList = useMutationUpdateSelectionList();

    const initialSelectionListItems = useMemo(
      () => (isEditMode ? [...selectionListToEdit.items] : []),
      [selectionListToEdit, isEditMode],
    );

    const {
      clonedRowData: selectionListItemsGridData,
      onGridReady,
      gridApi,
      updateRow,
      addRow,
      deleteRow,
      getAllRows,
      revertChanges: revertSelectionListItemChanges,
    } = useRowEditMode({
      rowData: initialSelectionListItems,
      getUniqueRowId,
      appendRowUnderSelectedRow: true, // doesn't work in AgGrid v23.1
    });

    const validateSelectionListItems = useCallback(() => {
      const selectionListItemsRows = getAllRows();
      const isValid =
        selectionListItemsRows.length > 0 &&
        !selectionListItemsRows.some(({ item }) => item === '');
      setAreSelectionListItemsValid(isValid);
    }, [getAllRows]);

    const checkIfSelectionListItemsAreDirty = useCallback(() => {
      const selectionListItems = getAllRows().map((row, index) => ({
        ...row,
        sortOrder: index + 1,
      }));
      const areDirty = !isEqual(initialSelectionListItems, selectionListItems);
      setAreSelectionListItemsDirty(areDirty);
    }, [getAllRows, initialSelectionListItems]);

    const submitForm = useCallback(
      async ({ id, name, description }, { resetForm }) => {
        const selectionListItems = getAllRows().map((row, index) => ({
          ...row,
          sortOrder: index + 1,
        }));
        const selectionList = {
          name,
          description,
          items: selectionListItems,
        };

        if (!isEditMode) {
          await insertSelectionList.mutateAsync({ ...selectionList, taxYear, jurisdictionId });
          resetForm(newSelectionList);
          revertSelectionListItemChanges();
        } else {
          await updateSelectionList.mutateAsync({ id, ...selectionList, taxYear, jurisdictionId });
        }
        clearForm();
      },
      [
        clearForm,
        revertSelectionListItemChanges,
        insertSelectionList,
        updateSelectionList,
        getAllRows,
        isEditMode,
        taxYear,
        jurisdictionId,
      ],
    );

    useImperativeHandle(
      ref,
      () => ({
        resetForm: () => {
          formRef.current?.resetForm();
          revertSelectionListItemChanges();
        },
      }),
      [revertSelectionListItemChanges],
    );

    return (
      <Formik
        innerRef={formRef}
        initialValues={selectionListToEdit || newSelectionList}
        validationSchema={selectionListsSchemas.getUpdateSelectionListSchema({
          selectionLists,
          isEditMode,
          id: selectionListToEdit?.id,
        })}
        onSubmit={submitForm}
        enableReinitialize
      >
        <EditSelectionListForm
          resetSelectionListItems={revertSelectionListItemChanges}
          selectionListItemsGridData={selectionListItemsGridData}
          gridApi={gridApi}
          onGridReady={onGridReady}
          addRow={addRow}
          updateRow={updateRow}
          deleteRow={deleteRow}
          checkIfSelectionListItemsAreDirty={checkIfSelectionListItemsAreDirty}
          validateSelectionListItems={validateSelectionListItems}
          areSelectionListItemsDirty={areSelectionListItemsDirty}
          areSelectionListItemsValid={areSelectionListItemsValid}
        />
      </Formik>
    );
  },
);

EditSelectionList.propTypes = {
  selectionListToEdit: selectionListPropTypes,
  clearForm: PropTypes.func.isRequired,
  taxYear: PropTypes.string.isRequired,
  jurisdictionId: PropTypes.string.isRequired,
  selectionLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

EditSelectionList.displayName = 'EditSelectionList';
export default EditSelectionList;
