import PropTypes from 'prop-types';

export const editInfoAccountPropTypes = {
  infoAccountId: PropTypes.string.isRequired,
  infoAccountType: PropTypes.string,
  infoAccountDescription: PropTypes.string,
  activeYn: PropTypes.bool.isRequired,
  globalInd: PropTypes.bool.isRequired,
  pshipGlobalInd: PropTypes.bool.isRequired,
  dataType: PropTypes.string,
};
