import { getModalActions } from '@tls/ui-modal';

import { ModalNames } from '../constants';
import { apiAction } from '../../shared/store/apiAction';

import * as api from './api';
import { countRootEntitiesByStateTypes, fetchIncomeTaxLiabilityTypes } from './types';

export const countRootEntitiesByState = apiAction({
  types: countRootEntitiesByStateTypes,
  apiAction: api.countRootEntitiesByState,
});

export const fetchIncomeTaxLiability = apiAction({
  types: fetchIncomeTaxLiabilityTypes,
  apiAction: api.incomeTaxLiability,
});

export const { showAction: showSavedSearchDeleteModal } = getModalActions(
  ModalNames.SavedSearchDelete,
);

export const { showAction: showSavedSearchEditModal } = getModalActions(ModalNames.SavedSearchEdit);
