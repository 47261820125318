import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Routes, taxNames } from '@common-packages/routes-definitions';

import AuthorizedCard from '../../shared/displayComponents/card/authorizedCard.container';
import {
  taxYearSelector,
  periodSelector,
  filingTypeIdSelector,
  entityIdSelector,
  jurisdictionIdSelector,
} from '../../shared/store/selectors';
import getContextAwareUrlParams from '../../utils/getContextAwareUrlParams';

const TaxCalculation = ({ workspace, taxYear, period, entityId, filingTypeId, jurisdictionId }) => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Tax Summary"
      icon="table-data"
      description="Tax summary"
      route={Routes.customScreen.compiledRoute({
        ...getContextAwareUrlParams({
          taxYear,
          period,
          filingTypeId,
          businessEntityId: entityId,
          jurisdictionId,
          customScreenName: 'tax-summary',
        }),
      })}
    />
    <AuthorizedCard
      title="Income Tax"
      icon="table-data"
      description="Income tax summary"
      route={Routes[`${workspace}TaxSummaries`].compiledRoute({
        taxName: taxNames.INCOME_TAX,
      })}
    />
    <AuthorizedCard
      title="Franchise Tax"
      icon="table-data"
      description="Franchise tax summary"
      route={Routes[`${workspace}TaxSummaries`].compiledRoute({
        taxName: taxNames.FRANCHISE_TAX,
      })}
    />
    <AuthorizedCard
      title="Estimated Tax"
      icon="table-data"
      description="Estimated tax summary"
      route={Routes[`${workspace}TaxSummaries`].compiledRoute({
        taxName: taxNames.ESTIMATED_TAX,
      })}
    />
  </div>
);

TaxCalculation.propTypes = {
  workspace: PropTypes.string.isRequired,
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  filingTypeId: PropTypes.string.isRequired,
  jurisdictionId: PropTypes.string,
};

export default connect(state => ({
  taxYear: taxYearSelector(state),
  period: periodSelector(state),
  entityId: entityIdSelector(state),
  filingTypeId: filingTypeIdSelector(state),
  jurisdictionId: jurisdictionIdSelector(state),
}))(TaxCalculation);
