import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isValid } from 'date-fns';

import DatePicker from '../../displayComponents/datePicker/datePicker.component';
import americanDateFormat from '../../americanDateFormat';

class DatePickerFormik extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
      setTouched: PropTypes.func.isRequired,
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
    }).isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
    }).isRequired,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    clearable: false,
  };

  handleBlur = () => {
    const {
      field: { name },
      form: { touched, setTouched },
    } = this.props;
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  handleChange = newDate => {
    const {
      field: { name },
      form: { setFieldValue },
    } = this.props;

    setFieldValue(name, newDate);
  };

  handleClearSelection = () => {
    const {
      field: { name },
      form: { setFieldValue },
    } = this.props;

    setFieldValue(name, null);
  };

  render() {
    const {
      field: { value, name },
      disabled,
      label,
      clearable,
      form: { errors, touched },
    } = this.props;

    const errorMessage = errors[name];
    const hasError = Boolean(errorMessage && touched[name]);

    const validValue = isValid(americanDateFormat.fromString(value)) ? value : null;

    return (
      <div>
        <label className="a-form-label" name={name}>
          {label}
        </label>
        <DatePicker
          className={`date-picker-formik ${hasError ? 'with-error' : ''}`}
          name={name}
          value={validValue}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          disabled={disabled}
          clearable={clearable}
          onClearSelection={this.handleClearSelection}
        />
        {hasError && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    );
  }
}

export default DatePickerFormik;
