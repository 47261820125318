import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useGridApi from '../../shared/hooks/useGridApi.hook';
import {
  GlobalContext,
  ReturnStatusTrackerDetailsResultRow,
  ReturnStatusTrackerRow,
} from '../../../../common/types';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import useSetContext from '../../shared/hooks/useSetContext.hook';
import { useQueryReturnStatuses } from '../../shared/queries/taxReturns';
import { useUpdateReturnStatus } from '../../shared/mutations/taxReturns';
import styles from '../returnStatusTrackerDetails.module.scss';
import { globalContextSelector } from '../../shared/store/selectors';

import getColumnDefinitions from './returnStatusTrackerDetailsDataGrid.columnDefinitions';

export type GridColumn = {
  width: number;
  colId: string;
};

const ReturnStatusTrackerDetailsDataGrid = ({
  data,
  isLoading = false,
}: {
  data: ReturnStatusTrackerDetailsResultRow[];
  isLoading?: boolean;
}) => {
  const { setContext } = useSetContext();
  const { gridApi, onGridReady } = useGridApi();
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const { mutateAsync: updateReturnStatus } = useUpdateReturnStatus();

  const { data: returnStatusOptions, isLoading: isLoadingReturnStatus } = useQueryReturnStatuses();

  const handleUpdateRow = useCallback(
    (data?: ReturnStatusTrackerRow) => {
      if (data) {
        gridApi?.clearFocusedCell();
        updateReturnStatus({
          taxReturnStatus: data.returnStatus || null,
          returnId: Number(data.id),
        });
      }
    },
    [updateReturnStatus, gridApi],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        setContext,
        handleUpdateRow,
        isTaxYearAfterOr2023: Number(globalContext?.taxYear) >= Number('2023'),
        returnStatusOptions,
      }),
    [setContext, handleUpdateRow, returnStatusOptions],
  );

  const onResetFiltersClick = useCallback(() => {
    gridApi?.setFilterModel(null);
  }, [gridApi]);

  return (
    <>
      <div className={`row grid-row ${styles.dataGridContainer}`}>
        <div className="col">
          <div className={styles.datagridHeader}>
            <span>Return Filings</span>
            <Link to={{}} onClick={onResetFiltersClick}>
              Reset Filters
            </Link>
          </div>
          <AgGrid
            isGridLoading={isLoading || isLoadingReturnStatus}
            columnDefs={columnDefinitions}
            rowData={data}
            autoMaxWidth
            withSearchBar
            withResetIcon
            serverSideInfiniteScrolling
            sideBar={defaultSideBarWithColumnsToolPanel}
            saveGridStateId={'ReturnStatusTrackerDetailsDataGrid'}
            withScrollPagination
            singleClickEdit
            stopEditingWhenGridLosesFocus
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
};

export default ReturnStatusTrackerDetailsDataGrid;
