import { FIELDS_TO_UPDATE, FIELDS_TO_UNMAP } from '../../constants';

interface FormField {
  absoluteValue: boolean;
  appendText: string;
  characterSpacing?: string | null;
  checkBox: string;
  dataItemId?: string | null;
  datasetId?: string | null;
  decimalPlaces: string;
  filterRegExp: string;
  flipSign: boolean;
  formattingString: string;
  fullfieldLength?: string | null;
  fullfieldPattern?: string | null;
  parensIfNegative: boolean;
  printFirstCopyOnly: boolean;
  printLastCopyOnly: boolean;
  ratioAsPercentage: boolean;
  suppressCommas: boolean;
  suppressFieldDataItemDefId?: boolean | null;
  suppressFieldDatasetDefId?: boolean | null;
  upperCase: boolean;
  valueIfZero: string;
  wrapFirstRowIndt: string;
  wrapFullRowcount?: string | null;
  wrapRowspacingIncrement?: string | null;
  wrapText: boolean;
}

interface FieldsToUpdate {
  [key: string]: FormField;
}

interface PreviousValues {
  fieldsToUnmap: FormFieldIds[];
  fieldsToUpdate: FieldsToUpdate;
}

interface FormFieldIds {
  id: string;
  pdfFieldMappingId: string;
}

const initialValues: FormField = {
  absoluteValue: false,
  appendText: '',
  characterSpacing: null,
  checkBox: '0',
  dataItemId: null,
  datasetId: null,
  decimalPlaces: '0',
  filterRegExp: '',
  flipSign: false,
  formattingString: '',
  fullfieldLength: null,
  fullfieldPattern: null,
  parensIfNegative: false,
  printFirstCopyOnly: false,
  printLastCopyOnly: false,
  ratioAsPercentage: false,
  suppressCommas: false,
  suppressFieldDataItemDefId: null,
  suppressFieldDatasetDefId: null,
  upperCase: false,
  valueIfZero: '0',
  wrapFirstRowIndt: '0',
  wrapFullRowcount: null,
  wrapRowspacingIncrement: null,
  wrapText: false,
};

export const handleMultipleFieldsUnmapFactory = ({
  selectedFormFieldIds,
}: {
  selectedFormFieldIds: FormFieldIds[];
}) => (previousValues: PreviousValues) =>
  selectedFormFieldIds.reduce(
    (acc: PreviousValues, currentFieldIds: FormFieldIds) => {
      const { id, pdfFieldMappingId } = currentFieldIds;
      if (id && pdfFieldMappingId) {
        if (!acc[FIELDS_TO_UNMAP].some(field => field.id === id)) {
          const fieldToUnmap = pdfFieldMappingId ? [{ id, pdfFieldMappingId }] : [];
          acc[FIELDS_TO_UNMAP].push(...fieldToUnmap);
          acc[FIELDS_TO_UPDATE][id] = initialValues;
        }
      }
      return acc;
    },
    {
      ...previousValues,
      fieldsToUpdate: {
        ...previousValues?.[FIELDS_TO_UPDATE],
      },
      fieldsToUnmap: [...(previousValues?.[FIELDS_TO_UNMAP] || [])],
    },
  );
