const Yup = require('yup');

const { alphanumericChar } = require('../validationRegex');

const SSN_MUST_BE_9_DIGITS_MSG = 'SSN must be 9 digits';

const getCommon = () =>
  Yup.object().shape({
    taxYear: Yup.string().label('Tax Year').required(),
    orgId: Yup.string()
      .label('Individual Id')
      .matches(alphanumericChar.regex, alphanumericChar.message)
      .required('Individual Id is a required field'),
    businessId: Yup.string().label('Business').required(),
    firstName: Yup.string().label('First Name').required(),
    middleName: Yup.string().label('Middle Name').nullable(),
    lastName: Yup.string().label('Last Name').required(),
    suffix: Yup.string().label('Name Suffix').nullable(),
    spouseFirstName: Yup.string().label('Spouse First Name').nullable(),
    spouseMiddleName: Yup.string().label('Spouse Middle Name').nullable(),
    spouseLastName: Yup.string().label('Spouse Last Name').nullable(),
    spouseSuffix: Yup.string().label('Spouse Name Suffix').nullable(),
    partnerStateOfResidency: Yup.string().label('State of Residency').required(),
    membership: Yup.string().label('Member Number').max(25).required(),
    ssn: Yup.string()
      .test(
        'shouldSsnRequired',
        'Social Security Number is a required field',
        // if shouldSsnMask is true, means there already has ssn in DB for current individual entity
        (value, context) => context.parent.shouldSsnMask || Boolean(value),
      )
      .matches(/^\d{9}$/, {
        message: SSN_MUST_BE_9_DIGITS_MSG,
        excludeEmptyString: true,
      }),
    spouseSsn: Yup.string()
      .matches(/^\d{9}$/, {
        message: SSN_MUST_BE_9_DIGITS_MSG,
        excludeEmptyString: true,
      })
      .nullable(),
  });

module.exports = {
  getCommon,
};
