import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { JobTypes, JobStatus } from '@common-packages/shared-constants';
import Spinner from '@tls/ui-spinner';

import { GenerateBinderPdfStatusJob } from '../../../../common/types';
import { useMutationGenerateReturnPdf } from '../../shared/mutations/binderMaintenance';
import { useQueryLatestPdfBinderGenerationJob } from '../../shared/queries/binderMaintenance';
import { PanelComponents } from '../returnMaintenance.container';
import { dateFormatter } from '../../shared/formatters';
import { startUserJobPolling, setShouldCreateNotification } from '../../jobs/store/actions';
import { jobsDataSelector } from '../../jobs/store/selectors';
import { isJobInProgress } from '../../jobs/utils';
import config from '../../config';
import styles from '../returnMaintenance.module.scss';

interface useGenerateReturnPdfProps {
  downloadPdf: (shouldDecrypt: boolean) => void;
  setActiveComponent: React.Dispatch<React.SetStateAction<PanelComponents>>;
  isDownloadPdfPanelVisible: boolean;
  returnId: string;
}

const useGenerateReturnPdf = ({
  downloadPdf,
  setActiveComponent,
  isDownloadPdfPanelVisible,
  returnId,
}: useGenerateReturnPdfProps) => {
  const dispatch = useDispatch();
  const jobsData = useSelector(jobsDataSelector);

  const {
    data: latestGenerationJob,
    isFetching: isFetchingLatestGenerationJob,
    refetch: fetchLatestPdfBinderGenerationJob,
  } = useQueryLatestPdfBinderGenerationJob({
    params: { binderId: returnId },
    enabled: Boolean(returnId),
  });

  const {
    mutateAsync: generateReturnPdf,
    isLoading: isStartingPdfGenerationJob,
  } = useMutationGenerateReturnPdf();

  const generatePdf = useCallback(
    async (shouldDecrypt = false) => {
      setActiveComponent(PanelComponents.GENERATE_PDF);
      await generateReturnPdf({
        returnId,
        shouldDecrypt,
      });

      fetchLatestPdfBinderGenerationJob();
    },
    [fetchLatestPdfBinderGenerationJob, generateReturnPdf, setActiveComponent, returnId],
  );
  const latestPdfReturnGenerationJobId = latestGenerationJob?.jobId;
  const generateReturnPdfJobData = latestPdfReturnGenerationJobId
    ? jobsData[latestPdfReturnGenerationJobId]
    : null;

  const pdfLastGenerationDate = generateReturnPdfJobData?.finishedOn || null;

  const isPdfProcessed = Boolean(
    returnId &&
      (!latestPdfReturnGenerationJobId ||
        generateReturnPdfJobData?.finishedOn ||
        generateReturnPdfJobData?.returnedError),
  );

  const isProcessingGenerationJob =
    !isPdfProcessed || isFetchingLatestGenerationJob || isStartingPdfGenerationJob;

  useEffect(() => {
    if (generateReturnPdfJobData?.status === JobStatus.PROCESSING && !isDownloadPdfPanelVisible) {
      dispatch(
        setShouldCreateNotification({
          jobId: latestPdfReturnGenerationJobId,
          shouldCreateNotification: true,
        }),
      );
    }
  }, [
    dispatch,
    generateReturnPdfJobData?.status,
    isDownloadPdfPanelVisible,
    latestPdfReturnGenerationJobId,
  ]);

  const doJobPollingSuccess = useRef<(_: GenerateBinderPdfStatusJob) => () => null | void>(
    () => () => null,
  );

  useEffect(() => {
    doJobPollingSuccess.current = (job: GenerateBinderPdfStatusJob) => () => {
      if (
        (job.status !== JobStatus.FAILED && job.status !== JobStatus.SUCCESS) ||
        String(job.jobId) !== String(latestPdfReturnGenerationJobId)
      ) {
        return;
      }

      if (isDownloadPdfPanelVisible) {
        job.status === JobStatus.SUCCESS
          ? downloadPdf(Boolean(latestGenerationJob?.shouldDecrypt))
          : setActiveComponent(PanelComponents.PDF_ERROR);
      } else {
        setActiveComponent(PanelComponents.DOWNLOAD_PDF);
      }
    };
  }, [
    dispatch,
    downloadPdf,
    setActiveComponent,
    isDownloadPdfPanelVisible,
    latestPdfReturnGenerationJobId,
    latestGenerationJob,
  ]);

  useEffect(() => {
    if (!latestPdfReturnGenerationJobId || isPdfProcessed) {
      return;
    }
    dispatch(
      startUserJobPolling({
        jobType: JobTypes.GENERATE_BINDER_PDF_STATUS,
        jobId: latestPdfReturnGenerationJobId,
        successAction: doJobPollingSuccess,
        errorMessage: 'There was an error getting pdf generation status',
      }),
    );
  }, [dispatch, latestPdfReturnGenerationJobId, isPdfProcessed]);

  const displayPdfGenerationDate = useCallback(() => {
    if (isJobInProgress(generateReturnPdfJobData?.status)) {
      return (
        <span className={styles.pdfGenerationDate}>
          Generation started:{' '}
          {dateFormatter(generateReturnPdfJobData?.createdOn, config.AMERICAN_DATE_TIME_FORMAT)}
        </span>
      );
    }
    if (!isPdfProcessed || isFetchingLatestGenerationJob) {
      return (
        <span className={styles.pdfGenerationDate}>
          Checking PDF generation status
          <Spinner small className={styles.pdfGenerationStatusSpinner} />
        </span>
      );
    }
    if (isStartingPdfGenerationJob) {
      return (
        <span className={styles.pdfGenerationDate}>
          Processing PDF generation request
          <Spinner small className={styles.pdfGenerationStatusSpinner} />
        </span>
      );
    }
    if (generateReturnPdfJobData?.finishedOn) {
      return (
        <span className={styles.pdfGenerationDate}>
          Last generated:{' '}
          {dateFormatter(generateReturnPdfJobData.finishedOn, config.AMERICAN_DATE_TIME_FORMAT)}
        </span>
      );
    }
    return (
      <span className={styles.pdfGenerationDate}>A PDF has not been generated for this return</span>
    );
  }, [
    generateReturnPdfJobData?.status,
    generateReturnPdfJobData?.finishedOn,
    generateReturnPdfJobData?.createdOn,
    isPdfProcessed,
    isFetchingLatestGenerationJob,
    isStartingPdfGenerationJob,
  ]);

  return {
    generatePdf,
    displayPdfGenerationDate,
    latestPdfReturnGenerationJobId,
    pdfLastGenerationDate,
    isProcessingGenerationJob,
  };
};

export default useGenerateReturnPdf;
