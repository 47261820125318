import { matchPath } from 'react-router-dom';

import { ROOT_NAVIGATOR_TAB } from '../shared/constants';

const treeAfterActiveApplied = ({
  tree,
  location,
  activeTab,
  localStorageNavigatorStatus = {},
}) => {
  let isActiveMatch = false;

  const isQueryStringMatch = (locationSearch, routeQueryString) => {
    if (!routeQueryString) {
      return true;
    }
    const locationSearchParams = new URLSearchParams(locationSearch);
    return Object.keys(routeQueryString).reduce(
      (boolean, key) => (routeQueryString[key] !== locationSearchParams.get(key) ? false : boolean),
      true,
    );
  };

  let treeWithActive = tree.map(item => {
    if (item.type === 'folder') {
      const childrenWithActiveMatch = isActiveMatch
        ? item.children
        : item.children.map(child => {
            const url = child.route.compiledRoute
              ? child.route.compiledRoute(child.routeParams)
              : child.route.MAIN;

            const isActive =
              isQueryStringMatch(location.search, child.routeQueryString) &&
              matchPath(location.pathname, {
                path: url,
                exact: true,
              });

            if (!isActiveMatch && isActive) {
              isActiveMatch = true;
            }

            return {
              isActive,
              ...child,
            };
          });

      return {
        ...item,
        children: childrenWithActiveMatch,
        isActive: childrenWithActiveMatch.some(({ isActive }) => isActive),
        isOpen:
          activeTab === ROOT_NAVIGATOR_TAB.NAVIGATOR
            ? localStorageNavigatorStatus.items?.find(it => it.name === item.name)?.isOpen ?? true
            : null,
      };
    } else {
      const url = item.route.compiledRoute
        ? item.route.compiledRoute(item.routeParams)
        : item.route.MAIN;

      const isActive = matchPath(location.pathname, {
        path: url,
        exact: true,
      });

      if (!isActiveMatch && isActive) {
        isActiveMatch = true;
      }

      return {
        isActive,
        ...item,
      };
    }
  });

  if (!isActiveMatch) {
    treeWithActive = tree.map(item => {
      if (item.type === 'folder') {
        const childrenWithActiveMatch = item.children.map(child => {
          const url = child.route.compiledRoute
            ? child.route.compiledRoute(child.routeParams)
            : child.route.MAIN;

          const isActive =
            isQueryStringMatch(location.search, child.routeQueryString) &&
            location.pathname.startsWith(url);

          return {
            isActive,
            ...child,
          };
        });

        return {
          ...item,
          children: childrenWithActiveMatch,
          isActive: childrenWithActiveMatch.some(({ isActive }) => isActive),
          isOpen:
            activeTab === ROOT_NAVIGATOR_TAB.NAVIGATOR
              ? localStorageNavigatorStatus.items?.find(it => it.name === item.name)?.isOpen ?? true
              : null,
        };
      } else {
        const url = item.route.compiledRoute
          ? item.route.compiledRoute(item.routeParams)
          : item.route.MAIN;

        const isActive =
          isQueryStringMatch(location.search, item.routeQueryString) &&
          location.pathname.startsWith(url);

        return {
          isActive,
          ...item,
        };
      }
    });
  }

  return treeWithActive;
};

export default treeAfterActiveApplied;
