import React, { useCallback } from 'react';
import { useFormikContext, FieldConfig } from 'formik';
import { RadioGroup, Radio } from '@pwc/appkit-react';
import classNames from 'classnames';

import { FindManualFederalProformaSubmissionIdOptionsResponse } from '../../../../../../../../common/types';
import AppkitIcon from '../../../../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import Select from '../../../../../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import { FederalReturnOption } from '../../../../../../shared/enums/eFileV2';
import { ReturnFilingValues, Option, RenderCardHeaderRadio } from '../types';
import { WizardStateMachineSetFederalSubmissionId } from '../../../../wizardStateMachine/types';
import { OPTIONS_WITH_FEDERAL_SUBMISSION_ID } from '../constants';

import useManualFederalProformaSubmission from './hooks/useManualFederalProformaSubmission.hook';
import styles from './radioCardsGroup.module.scss';

interface RadioCardsGroupProps {
  field: FieldConfig;
  options: Option[];
  className?: string;
  label: string;
  onChange?: (value: FederalReturnOption) => void;
  wizardStateMachineSetFederalSubmissionId: WizardStateMachineSetFederalSubmissionId;
  setFederalSubmissionId: (submissionId: WizardStateMachineSetFederalSubmissionId) => void;
  manualFederalProformaSubmissionIdOptions: FindManualFederalProformaSubmissionIdOptionsResponse;
}

const RadioCardsGroup = ({
  field: { value: selectedFederalReturnOption, name },
  className = '',
  label,
  options,
  onChange = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  wizardStateMachineSetFederalSubmissionId,
  setFederalSubmissionId,
  manualFederalProformaSubmissionIdOptions,
}: RadioCardsGroupProps) => {
  const { initialValues, setFieldValue, values } = useFormikContext<ReturnFilingValues>();

  const handleFederalReturnOptionChange = useCallback(
    (event: Event, selectedFederalReturnOption: FederalReturnOption) => {
      setFieldValue(name, selectedFederalReturnOption);
      onChange(selectedFederalReturnOption);
      if (selectedFederalReturnOption === FederalReturnOption.FED_MANUAL_PROFORMA) {
        setFieldValue(
          'manualFederalProformaSubmissionId',
          initialValues?.manualFederalProformaSubmissionId,
        );
      }
    },
    [onChange, setFieldValue, name, initialValues?.manualFederalProformaSubmissionId],
  );

  const {
    handleManualFederalSubmissionIdChange,
    handleManualFederalProformaSubmissionIdOptionsClick,
  } = useManualFederalProformaSubmission({
    setFederalSubmissionId,
    wizardStateMachineSetFederalSubmissionId,
    selectedFederalReturnOption,
  });

  const renderCardHeaderRadio = useCallback(
    ({ title, subtitle, icon, isManualCard = false }: RenderCardHeaderRadio) => (
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderTitleSection}>
          <span className={styles.cardHeaderTitle}>{title}</span>
          {subtitle && <span className={styles.cardHeaderSubtitle}>{subtitle}</span>}
          {isManualCard && selectedFederalReturnOption === FederalReturnOption.FED_MANUAL_PROFORMA && (
            <div onClick={handleManualFederalProformaSubmissionIdOptionsClick}>
              <Select<string>
                className={classNames(styles.cardHeaderSelect, styles.cardHeaderSelectActive)}
                name="manualFederalProformaSubmissionId"
                options={manualFederalProformaSubmissionIdOptions}
                defaultValue={initialValues?.manualFederalProformaSubmissionId}
                customChangeHandler={handleManualFederalSubmissionIdChange}
                value={values?.manualFederalProformaSubmissionId}
                placeholder="Select Federal Submission ID"
                hideLabel
                showSearchOnList
              />
            </div>
          )}
        </div>
        {icon && <div className={styles.iconContainer}>{icon}</div>}
      </div>
    ),
    [
      selectedFederalReturnOption,
      initialValues?.manualFederalProformaSubmissionId,
      values?.manualFederalProformaSubmissionId,
      manualFederalProformaSubmissionIdOptions,
      handleManualFederalSubmissionIdChange,
      handleManualFederalProformaSubmissionIdOptionsClick,
    ],
  );

  return (
    <fieldset>
      <legend className="a-form-label">{label}</legend>
      <RadioGroup
        className={className}
        value={selectedFederalReturnOption}
        defaultValue={initialValues?.federalReturnOption}
        onChange={handleFederalReturnOptionChange}
      >
        {options.map((option, index) => {
          if (OPTIONS_WITH_FEDERAL_SUBMISSION_ID.includes(option.value as FederalReturnOption)) {
            if (option.value === FederalReturnOption.FED_MANUAL_PROFORMA) {
              return (
                <Radio
                  key={index}
                  value={option.value}
                  className={`${styles.radioWrapper} ${styles.federalIdMissing}`}
                >
                  {renderCardHeaderRadio({
                    title: option.label,
                    isManualCard: true,
                  })}
                </Radio>
              );
            }

            if (!option.federalId) {
              return (
                <Radio
                  key={index}
                  value={option.value}
                  className={`${styles.radioWrapper} ${styles.federalIdMissing}`}
                >
                  {renderCardHeaderRadio({
                    title: option.label,
                    subtitle:
                      selectedFederalReturnOption === option.value
                        ? 'The federal return is not available yet and must be included before filing, however, E-File validations may be run by clicking NEXT.'
                        : null,
                    icon: (
                      <AppkitIcon
                        className={styles.warningIcon}
                        type="fill"
                        icon="alert"
                        size={32}
                      />
                    ),
                  })}
                </Radio>
              );
            }

            return (
              <Radio key={index} value={option.value} className={styles.radioWrapper}>
                {renderCardHeaderRadio({
                  title: option.label,
                  subtitle: `Federal ID: ${option.federalId}`,
                })}
              </Radio>
            );
          }

          return (
            <Radio key={index} value={option.value} className={styles.radioWrapper}>
              {renderCardHeaderRadio({ title: option.label, subtitle: option.sublabel })}
            </Radio>
          );
        })}
      </RadioGroup>
    </fieldset>
  );
};

export default RadioCardsGroup;
