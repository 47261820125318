import React, { useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useRowEditMode } from '../../shared/editMode';
import { entityIdSelector, taxYearSelector, periodSelector } from '../../shared/store/selectors';

import {
  isFetchingAccountGroupsSelector,
  isFetchingAccountDataSelector,
  isFetchingAccountsSelector,
  accountDataSelector,
  selectedAccountSelector,
} from './store/selectors';
import { fetchAccountData, updateAccountData } from './store/actions';
import AccountSelector from './accountSelector.container';
import getColumnDefinitions from './manuallyEnteredAccountData.columnDefinitions';
import './manuallyEnteredAccountData.style.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;
const getAccountId = account => account.value;

const ManuallyEnteredAccountData = ({
  taxYear,
  period,
  entityId,
  isFetchingAccountGroups,

  isFetchingAccounts,
  isFetchingAccountData,
  fetchAccountData,
  updateAccountData,
  accountData,
  selectedAccount,

  hasUserPermissionsToEdit,
}) => {
  const isContextReady = taxYear && period && entityId;

  useEffect(() => {
    if (isContextReady && selectedAccount) {
      const accountId = getAccountId(selectedAccount);
      fetchAccountData({ taxYear, period, entityId, accountId });
    }
  }, [fetchAccountData, isContextReady, selectedAccount, taxYear, period, entityId]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      const rowsToUpdate = rowsPairsWithChanges.map(({ newRow }) => ({
        jurisdictionId: newRow.jurisdictionId,
        accountAmount: newRow.accountAmount,
      }));

      const accountId = getAccountId(selectedAccount);
      await updateAccountData({
        taxYear,
        period,
        entityId,
        accountId,
        rowsToUpdate,
      });
      fetchAccountData({ taxYear, period, entityId, accountId });
    },
    [updateAccountData, fetchAccountData, taxYear, period, entityId, selectedAccount],
  );

  const isLoading = !isContextReady;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: accountData,
    getUniqueRowId,
    editButtonDisabled:
      isLoading || isFetchingAccounts || isFetchingAccountGroups || !accountData.length,
  });

  const columnDefinitions = useMemo(() => getColumnDefinitions({ isInEditMode, updateRow }), [
    isInEditMode,
    updateRow,
  ]);

  return (
    <>
      {navigationPrompt}
      {hasUserPermissionsToEdit && (
        <div className="row">
          <div className="col add-button-column">{editModeButtons}</div>
        </div>
      )}
      <div className="row">
        <div className="col-lg-6 col-md-6 offset-lg-3 offset-md-3">
          <div className="account-section">
            <AccountSelector />
          </div>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingAccountData}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            stopEditingWhenCellsLoseFocus
            rowData={clonedRowData}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
};

const optionPropType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

ManuallyEnteredAccountData.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  isFetchingAccountGroups: PropTypes.bool.isRequired,
  isFetchingAccounts: PropTypes.bool.isRequired,
  isFetchingAccountData: PropTypes.bool.isRequired,
  accountData: PropTypes.arrayOf(
    PropTypes.shape({
      jurisdictionId: PropTypes.string,
      stateDescription: PropTypes.string,
      accountAmount: PropTypes.number,
      includedInEvTotal: PropTypes.string,
      displayOrder: PropTypes.number,
    }),
  ),
  selectedAccount: optionPropType,
  fetchAccountData: PropTypes.func.isRequired,
  updateAccountData: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    isFetchingAccounts: isFetchingAccountsSelector(state),
    isFetchingAccountData: isFetchingAccountDataSelector(state),
    isFetchingAccountGroups: isFetchingAccountGroupsSelector(state),
    selectedAccount: selectedAccountSelector(state),
    accountData: accountDataSelector(state),
  }),
  {
    fetchAccountData,
    updateAccountData,
  },
)(ManuallyEnteredAccountData);
