import React from 'react';
import { useSelector } from 'react-redux';

import { useQueryFindAllEntities } from '../../../../../shared/queries/entityInformationDiagnostics';
import { taxYearSelector } from '../../../../../shared/store/selectors';
import AgGrid from '../../../../../shared/displayComponents/agGrid/agGrid.component';

import ColumnDefinitions from './allEntities.columnDefinitions';

const AllEntities = () => {
  const columnDefinitions = ColumnDefinitions;

  const taxYear = useSelector(taxYearSelector);

  const { data: entities, isFetching: isFetchingEntities } = useQueryFindAllEntities({
    taxYear,
    enabled: Boolean(taxYear),
  });

  return (
    <>
      <AgGrid
        rowData={entities}
        columnDefs={columnDefinitions}
        withSearchBar
        autoMaxWidth
        isGridLoading={isFetchingEntities}
      />
    </>
  );
};

export default AllEntities;
