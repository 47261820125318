const taxTypes = {
  FRANCHISE: 'F',
  INCOME: 'I',
};

const TaxTypeValues = {
  INCOME: 'income',
  FRANCHISE: 'franchise',
};

const DEFAULT_TAX_TYPE = TaxTypeValues.INCOME;

module.exports = {
  taxTypes,
  TaxTypeValues,
  DEFAULT_TAX_TYPE,
};
