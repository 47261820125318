const Yup = require('yup');

const getEfileElementMappingSchema = Yup.object().shape({
  dataModelId: Yup.string()
    .when('elementName', { is: value => Boolean(value), then: Yup.string().nullable().required() })
    .nullable(),
  datasetDefId: Yup.string()
    .when('elementName', { is: value => Boolean(value), then: Yup.string().nullable().required() })
    .nullable(),
  dataitemDefId: Yup.string()
    .when('elementName', {
      is: value => Boolean(value),
      then: Yup.string()
        .nullable()
        .when('isLastNode', {
          is: value => Boolean(value),
          then: Yup.string().nullable().required(),
        }),
    })
    .nullable(),
  stackedSiblings: Yup.string()
    .when(['stacked', 'branch'], {
      is: (stacked, branch) => Boolean(stacked) && Boolean(branch),
      then: Yup.string().nullable().required(),
    })
    .nullable(),
});
module.exports = {
  getEfileElementMappingSchema,
};
