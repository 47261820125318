import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@pwc/appkit-react/lib/Button';
import { Routes } from '@common-packages/routes-definitions';

import useGridApi from '../../shared/hooks/useGridApi.hook';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { taxYearSelector, periodSelector } from '../store/selectors';
import { selectGenericFunction } from '../store/actions';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import { fetchGenericFunctions, deleteGenericFunction } from './store/actions';
import getColumnDefinitions from './genericFunctions.columnDefinitions';

const GenericFunctions = ({
  taxYear,
  period,

  genericFunctions,
  isFetchingGenericFunctions,
  selectGenericFunction,

  showConfirmModal,
  fetchGenericFunctions,
  deleteGenericFunction,
}) => {
  const { gridApi, onGridReady } = useGridApi();
  const { queryParams, setParams } = useUrlParams();
  const history = useHistory();

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete Generic Function?',
        text:
          'The gFunction, all gFunction parameters and the associated function will be deleted.',
        confirmCallback: async () => {
          await deleteGenericFunction({
            taxYear,
            period,
            ...row,
          });
          fetchGenericFunctions({ taxYear, period });
        },
      });
    },
    [showConfirmModal, deleteGenericFunction, fetchGenericFunctions, taxYear, period],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        selectGenericFunction,
        openModalForDelete,
        queryParams: queryParams.search ? `search=${queryParams.search}` : '',
      }),
    [selectGenericFunction, openModalForDelete, queryParams.search],
  );

  const renderNewButton = useCallback(
    () => (
      <Link to={{ pathname: Routes.developmentGenericFunctionsAddEdit.ADD }}>
        <Button size="lg" className="add-button">
          New
        </Button>
      </Link>
    ),
    [],
  );

  const setFilterQueryParams = useCallback(() => {
    gridApi?.filterManager?.quickFilter
      ? setParams({ queryParams: { search: gridApi.filterManager.quickFilter.toLowerCase() } })
      : history.push(Routes.developmentGenericFunctions.MAIN);
  }, [setParams, gridApi, history]);

  return (
    <>
      <HeaderWithParamDropdownsWrapper
        showHeaderTaxYearDropdown
        showHeaderPeriodDropdown
        renderAdditionalElements={renderNewButton}
      />
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={genericFunctions}
            isGridLoading={isFetchingGenericFunctions}
            withSearchBar
            columnDefs={columnDefinitions}
            onGridReady={onGridReady}
            onFilterChanged={setFilterQueryParams}
            initialQuickFilterValue={queryParams.search || ''}
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
    </>
  );
};

GenericFunctions.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,

  genericFunctions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      functionId: PropTypes.string,
      functionType: PropTypes.string,
      operationType: PropTypes.string,
      dateModified: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingGenericFunctions: PropTypes.bool.isRequired,
  selectGenericFunction: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  fetchGenericFunctions: PropTypes.func.isRequired,
  deleteGenericFunction: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
  }),
  {
    selectGenericFunction,
    showConfirmModal,
    fetchGenericFunctions,
    deleteGenericFunction,
  },
)(GenericFunctions);
