import {
  GetEFileSubmissionFilesResponse,
  FindFederalReturnResponse,
  GlobalContext,
  TtiBindersResponse,
  GetValidationErrorsResponse,
  GetValidationErrorsParams,
  GetReturnValidationParams,
  GetEFileStepsResponse,
  SubmissionId,
  GetSignatureDeclarationResponse,
  BinderId,
  TtiCaseId,
  TtiBinderId,
  TtiYearId,
  GetReturnValidationResultsResponse,
  GetTransmissionStatusResponse,
  FindFederalReturnOptionParams,
  FindFederalReturnOptionResponse,
  BinderFilingId,
  SltBinderId,
  FindTtiGroupValidationParams,
  FindTtiGroupValidationResponse,
  GetCheckTtiServiceRunningResponse,
  FindEFileFederalReturnsAvailabilityParams,
  FindEFileFederalReturnsAvailabilityResponse,
  FindManualFederalProformaSubmissionIdOptionsParams,
  FindManualFederalProformaSubmissionIdOptionsResponse,
  FindProformaImportIrsAcceptedResultParams,
  FindProformaImportIrsAcceptedResultResponse,
  GetTransmissionStatusQueryParams,
  ReturnId,
  FindTransmisionHistoryResponse,
  EFileBinderFilingsDataResponse,
  EFileBinderItemsByIdResponse,
  GetTrRejectionMessagesParams,
  GetTrRejectionMessagesResponse,
} from '../../../../common/types';
import { FederalReturnOption } from '../enums/eFileV2';
import { createQueryString } from '../../utils/routerUtils';

import { QueryKeys, useCustomQuery } from './index';

interface GetEFileSubmissionFilesParams {
  globalContext: GlobalContext;
  stateSubmissionId: SubmissionId | null;
  federalSubmissionId?: SubmissionId | null;
  federalReturnOption?: FederalReturnOption | null;
  returnId?: ReturnId | null;
  binderFilingId: BinderFilingId | null;
}

interface GetEFileStepsParams {
  globalContext: GlobalContext;
  // added this type with null/undefined to remove TS problem - in case sltBinderId is null/undefined the query will not be triggered anyway
  sltBinderId: BinderId | null | undefined;
}

export const useQueryGetSubmissionFiles = ({
  params,
  enabled,
}: {
  params: GetEFileSubmissionFilesParams;
  enabled: boolean;
}) => {
  const {
    globalContext,
    stateSubmissionId,
    federalSubmissionId,
    federalReturnOption,
    returnId,
    binderFilingId,
  } = params;

  return useCustomQuery<GetEFileSubmissionFilesResponse>(
    [QueryKeys.EFileV2.GetSubmissionFiles, params],
    {
      url: `/api/efile-v2/submission-files/${
        globalContext.apiRouteParamString
      }/${stateSubmissionId}/${binderFilingId}/${federalReturnOption}/${returnId}${createQueryString(
        {
          federalSubmissionId,
        },
      )}`,
      enabled,
      initialData: { stateFiles: [], federalFiles: [] },
      displayMessageFromError: true,
    },
  );
};

interface FindFederalReturnParams {
  globalContextApiRouteParamString: GlobalContext['apiRouteParamString'];
  returnId: string | null;
}

export const useQueryGetFederalReturn = ({
  params,
  enabled,
  isInitialWizardState,
}: {
  params: FindFederalReturnParams;
  enabled: boolean;
  isInitialWizardState?: boolean;
}) => {
  const { globalContextApiRouteParamString, returnId } = params;
  return useCustomQuery<FindFederalReturnResponse>(
    [
      isInitialWizardState
        ? QueryKeys.EFileV2.GetFederalReturn.InitialWizard
        : QueryKeys.EFileV2.GetFederalReturn.Main,
      params,
    ],
    {
      url: `/api/efile-v2/federal-return/${globalContextApiRouteParamString}/${returnId}`,
      enabled,
      displayMessageFromError: true,
    },
  );
};

export const useQueryGetEFileSteps = ({
  params,
  enabled,
  onSuccess,
}: {
  params: GetEFileStepsParams;
  enabled: boolean;
  onSuccess: (stepsStatuses: GetEFileStepsResponse) => void;
}) => {
  const { globalContext, sltBinderId } = params;
  return useCustomQuery<GetEFileStepsResponse>([QueryKeys.EFileV2.GetSteps, params], {
    url: `/api/efile-v2/steps/${globalContext.apiRouteParamString}/${sltBinderId}`,
    enabled,
    errorMessage: 'Fetching efile steps failed',
    onSuccess,
  });
};

export const useQueryEFileBinderFilingsData = ({
  params,
  enabled,
}: {
  params: {
    sltBinderId: SltBinderId | null;
    apiRouteParamString: GlobalContext['apiRouteParamString'];
  };
  enabled: boolean;
}) =>
  useCustomQuery<EFileBinderFilingsDataResponse>(
    [QueryKeys.EFileSupport.EFileBinderFilingsData, params],
    {
      url: `/api/efile-v2/binder-filings-data/${params.apiRouteParamString}/${params.sltBinderId}`,
      enabled,
      defaultData: null,
      errorMessage: 'Fetching efile binder filings data failed',
    },
  );

export const useQueryFindBinderItemsByBinderId = ({
  params,
  enabled,
}: {
  params: {
    sltBinderId: SltBinderId | null;
  };
  enabled: boolean;
}) =>
  useCustomQuery<EFileBinderItemsByIdResponse>(
    [QueryKeys.EFileSupport.EfileBinderItemsCount, params],
    {
      url: `/api/efile-v2/binder-items-count/${params.sltBinderId}`,
      enabled,
      errorMessage: 'Fetching binder items count failed',
    },
  );

export const useQueryGetEFileStepsStatus = ({
  params,
  enabled,
  refetchInterval,
  onSuccess,
}: {
  params: GetEFileStepsParams;
  enabled: boolean;
  refetchInterval: number | false;
  onSuccess: (stepsStatuses: GetEFileStepsResponse) => void;
}) => {
  const { globalContext, sltBinderId } = params;
  return useCustomQuery<GetEFileStepsResponse>([QueryKeys.EFileV2.GetStepsStatus, params], {
    url: `/api/efile-v2/steps/${globalContext.apiRouteParamString}/${sltBinderId}`,
    enabled,
    refetchInterval,
    errorMessage: 'Fetching efile steps failed',
    onSuccess,
  });
};

export const getValidationErrorsInitialData = {
  lastRun: '',
  errors: 0,
  warnings: 0,
  validationRowData: [],
  status: 'Learn more',
};

export const getTrRejectionMessagesInitialData = [];

// TODO: SLT-11537 Combine to one endpoint
// TTI Validation
export const useQueryGetValidationErrors = ({
  params,
  enabled,
}: {
  params: GetValidationErrorsParams;
  enabled?: boolean;
}) =>
  useCustomQuery<GetValidationErrorsResponse>([QueryKeys.EFileV2.GetValidationErrors, params], {
    url: `/api/efile-v2/validation-errors/${params.taxYear}/${params.period}/${params.jurisdictionId}/${params.isSeparateYN}/${params.businessEntityId}/${params.sltBinderId}`,
    enabled,
    displayMessageFromError: true,
    initialData: getValidationErrorsInitialData,
    defaultData: getValidationErrorsInitialData,
    cacheTime: 0,
  });

// TODO: SLT-11537 Combine to one endpoint
// E-File Service Validation
export const useQueryGetReturnValidationResults = ({
  params,
  enabled,
}: {
  params: GetReturnValidationParams;
  enabled: boolean;
}) => {
  const { sltBinderId } = params;
  return useCustomQuery<GetReturnValidationResultsResponse>(
    [QueryKeys.EFileV2.GetValidationErrors, params],
    {
      url: `/api/efile-v2/return-validation-results/${sltBinderId}`,
      enabled,
      displayMessageFromError: true,
      initialData: getValidationErrorsInitialData,
      defaultData: getValidationErrorsInitialData,
      cacheTime: 0,
    },
  );
};

export const useQueryGetTRStatusRejectionMessages = ({
  params,
  enabled,
}: {
  params: GetTrRejectionMessagesParams;
  enabled: boolean;
}) => {
  const { returnId, transmissionHistoryId } = params;
  return useCustomQuery<GetTrRejectionMessagesResponse[]>(
    [QueryKeys.EFileV2.GetTrRejectionMessages, params],
    {
      url: `/api/efile-v2/tr-rejection-messages/${returnId}/${transmissionHistoryId}`,
      enabled,
      displayMessageFromError: true,
      initialData: getTrRejectionMessagesInitialData,
      defaultData: getTrRejectionMessagesInitialData,
      cacheTime: 0,
    },
  );
};

export const useQueryTtiBinders = ({
  params,
  enabled,
}: {
  params: GlobalContext & { sltBinderId: SltBinderId };
  enabled: boolean;
}) =>
  useCustomQuery<TtiBindersResponse[]>([QueryKeys.EFile.TtiBinders, params], {
    url: `/api/efile/binders/${params.apiRouteParamString}/${params.sltBinderId}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching efile binders failed',
  });

interface UseQueryGetSignatureDeclarationParams {
  yearOrSubPeriodId?: TtiYearId | null;
  caseId?: TtiCaseId | null;
  binderId?: TtiBinderId | null;
  efSubmissionId?: SubmissionId | null;
  returnId: string;
}

export const useQueryGetSignatureDeclaration = ({
  params: { yearOrSubPeriodId, caseId, binderId, efSubmissionId, returnId },
  enabled,
}: {
  params: UseQueryGetSignatureDeclarationParams;
  enabled: boolean;
}) =>
  useCustomQuery<GetSignatureDeclarationResponse>(
    [
      QueryKeys.EFileV2.GetSignatureDeclaration,
      { yearOrSubPeriodId, caseId, binderId, efSubmissionId },
    ],
    {
      url: `/api/efile-v2/signature-declaration/${yearOrSubPeriodId}/${caseId}/${binderId}/${efSubmissionId}/${returnId}`,
      enabled,
      errorMessage: 'Fetching signature declaration failed',
    },
  );

export const useQueryFederalReturnOption = ({
  params,
  enabled,
}: {
  params: FindFederalReturnOptionParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindFederalReturnOptionResponse>([QueryKeys.EFileV2.FederalReturnOption, params], {
    url: `/api/efile-v2/federal-return-option/${params.sltBinderId}`,
    enabled,
    errorMessage: 'Error fetching federal return option',
  });

export const useQueryGetTransmissionStatus = ({
  params,
  enabled,
}: {
  params: GetTransmissionStatusQueryParams;
  enabled: boolean;
}) =>
  useCustomQuery<GetTransmissionStatusResponse>([QueryKeys.EFileV2.GetTransmissionStatus, params], {
    url: `/api/efile-v2/transmission-status/${params.globalContextApiRouteParamString}/${params.isSeparateYN}/${params.binderFilingId}/${params.sltBinderId}/${params.transmissionHistoryId}`,
    enabled,
    errorMessage: 'Error fetching transmission status',
  });

export const useQueryGetSubmissionsHistory = ({
  params,
  enabled,
  onSuccess,
}: {
  params: { binderFilingId?: BinderFilingId; returnId: SltBinderId };
  enabled: boolean;
  onSuccess?: (data: FindTransmisionHistoryResponse) => void;
}) =>
  useCustomQuery<FindTransmisionHistoryResponse>(
    [QueryKeys.EFileV2.GetHistoryTransmission, params],
    {
      url: `/api/efile-v2/submission-history/${params.binderFilingId}/${params.returnId}`,
      enabled,
      errorMessage: 'Error fetching submissions history',
      onSuccess,
    },
  );

export const useQueryGetTtiGroupValidation = ({
  params,
  enabled,
}: {
  params: FindTtiGroupValidationParams;
  enabled: boolean;
}) => {
  const { globalContextApiRouteParamString, ttiCaseId, ttiYearId, sltBinderId } = params;
  return useCustomQuery<FindTtiGroupValidationResponse>(
    [QueryKeys.EFileV2.TtiGroupValidation, params],
    {
      url: `/api/efile-v2-service/tti-group-validation/${globalContextApiRouteParamString}/${ttiCaseId}/${ttiYearId}/${sltBinderId}`,
      enabled,
      displayMessageFromError: true,
    },
  );
};

export const useQueryGetCheckTtiServiceRunning = ({
  params,
  enabled,
  cacheTime,
  onSuccess,
}: {
  params: { taxYear: string };
  enabled: boolean;
  cacheTime?: number;
  onSuccess?: (data: GetCheckTtiServiceRunningResponse) => void;
}) =>
  useCustomQuery<GetCheckTtiServiceRunningResponse>([QueryKeys.EFileV2.GetCheckTtiServiceRunning], {
    url: `/api/efile-v2-service/check-tti-service-running/${params.taxYear}`,
    enabled,
    cacheTime,
    onSuccess,
  });

export const useQueryGetFederalReturnsAvailability = ({
  params,
  enabled,
}: {
  params: FindEFileFederalReturnsAvailabilityParams;
  enabled: boolean;
}) => {
  const { taxYear, period } = params;
  return useCustomQuery<FindEFileFederalReturnsAvailabilityResponse>(
    [QueryKeys.EFileV2.GetFederalReturnsAvailability, params],
    {
      url: `/api/efile-v2/federal-returns-availability/${taxYear}/${period}`,
      enabled,
      displayMessageFromError: true,
    },
  );
};

export const useQueryGetManualFederalProformaSubmissionIdOptions = ({
  params,
  enabled,
}: {
  params: FindManualFederalProformaSubmissionIdOptionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindManualFederalProformaSubmissionIdOptionsResponse>(
    [QueryKeys.EFileV2.GetManualFederalProformaSubmissionIdOptions, params],
    {
      url: `/api/efile-v2/manual-federal-proforma-submission-id-options/${params.taxYear}`,
      defaultData: [],
      enabled,
      errorMessage: 'Error fetching manual federal proforma submission id options',
    },
  );

export const useQueryGetProformaImportIrsAcceptedResult = ({
  params,
  enabled,
}: {
  params: FindProformaImportIrsAcceptedResultParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindProformaImportIrsAcceptedResultResponse>(
    [QueryKeys.EFileV2.GetProformaImportIrsAcceptedResult, params],
    {
      url: `/api/efile-v2/proforma-import-irs-accepted/${params.federalSubmissionId}`,
      enabled,
      errorMessage: 'Error fetching proforma import irs acceppted result',
    },
  );
