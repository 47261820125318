import { CalcPreferenceInsertData, CalcPreferenceUpdateData } from '../../../../common/types';
import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';

import { useCustomMutation } from '.';

export const useMutationUpdateCalcPreference = () =>
  useCustomMutation<null, QueryError, CalcPreferenceUpdateData>({
    handleData: ({ values, rowsPairsWithChanges, rowsToAdd, rowsToDelete }) => ({
      url: '/api/development/calc-preferences',
      method: HttpMethods.PUT,
      body: {
        values,
        rowsPairsWithChanges,
        rowsToAdd,
        rowsToDelete,
      },
    }),
    resetKey: QueryKeys.CalcPreferences.CalcPreferences,
    successMessage: 'Calc preferences updated successfully',
    errorMessage: 'An error occurred while trying to update the calc preferences',
  });

export const useMutationAddCalcPreference = () =>
  useCustomMutation<null, QueryError, CalcPreferenceInsertData>({
    handleData: ({ values, rowsToAdd }) => ({
      url: '/api/development/calc-preferences',
      method: HttpMethods.POST,
      body: {
        values,
        rowsToAdd,
      },
    }),
    resetKey: QueryKeys.CalcPreferences.CalcPreferences,
    successMessage: 'Calc preference(s) added successfully',
    errorMessage: 'An error occurred while trying to add the calc preference(s)',
  });
