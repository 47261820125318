import { isActionCompleted } from './stepValidation';
import { EFILE_ACTION } from './constants';

const StepsNumbers = {
  GENERATE_XML: 1,
  GENERATE_TRANSMISSION: 2,
  AUTHORIZE: 3,
  TRANSMIT: 4,
};

const setStatusForAllSteps = ({ eFileStepsData, setStepStatus, selectedTtiBinderId }) => {
  const generated = isActionCompleted(
    eFileStepsData.find(
      ({ stepName, binderId }) =>
        stepName === EFILE_ACTION.GENERATE_EFILE_XML && Number(binderId) === selectedTtiBinderId,
    ),
  );
  setStepStatus({
    currentStep: StepsNumbers.GENERATE_XML,
    payload: {
      isStepCompleted: generated,
    },
  });

  const isTransmissionGenerated = isActionCompleted(
    eFileStepsData.find(({ stepName }) => stepName === EFILE_ACTION.DOWNLOAD_SIGNATURE_DECLARATION),
  );

  setStepStatus({
    currentStep: StepsNumbers.GENERATE_TRANSMISSION,
    payload: {
      isStepCompleted: isTransmissionGenerated,
    },
  });

  const isAuthorized = isActionCompleted(
    eFileStepsData.find(
      ({ stepName, binderId }) =>
        stepName === EFILE_ACTION.AUTHORIZE_SIGN_AND_SUBMIT &&
        Number(binderId) === selectedTtiBinderId,
    ),
  );
  setStepStatus({
    currentStep: StepsNumbers.AUTHORIZE,
    payload: {
      isStepCompleted: isAuthorized,
    },
  });

  const isTransmitted = isActionCompleted(
    eFileStepsData.find(
      ({ stepName, binderId }) =>
        stepName === EFILE_ACTION.TRANSMIT && Number(binderId) === selectedTtiBinderId,
    ),
  );
  setStepStatus({
    currentStep: StepsNumbers.TRANSMIT,
    payload: {
      isStepCompleted: isTransmitted,
    },
  });
};

export default setStatusForAllSteps;
