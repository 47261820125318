import { dataTypes, ColumnTypes } from '@common-packages/shared-constants';

import {
  defaultColumnDefinitionWithFilter,
  defaultNoDataColumn,
} from '../../../../shared/columnDefinitions';
import { CalcSpecTypes as AccountTypes } from '../constants'; // renaming because in this context they serve as account types

const textAlignment = {
  [dataTypes.BOOLEAN]: 'center',
  [dataTypes.STRING]: 'left',
  [dataTypes.INTEGER]: 'right',
};

const getTextAlignment = ({ dataType, columnType }) => {
  if (columnType === ColumnTypes.CALC_PREFERENCES && dataType === dataTypes.STRING) {
    return 'center';
  }
  return textAlignment[dataType];
};

const baseAccountFieldsToFilterOut = ['onOffInd', 'code', 'dataset', 'dataitem', 'orgId'];

const getColumnsBasedOnColumnBlueprint = columnBlueprint => {
  if (columnBlueprint.children && !columnBlueprint.hasSingleValue) {
    return {
      columnType: columnBlueprint.columnType,
      headerName: columnBlueprint.displayName,
      children: columnBlueprint.children.map(child =>
        getColumnsBasedOnColumnBlueprint({ ...child, columnType: columnBlueprint.columnType }),
      ),
    };
  }

  return {
    ...defaultColumnDefinitionWithFilter,
    headerName: columnBlueprint.displayName,
    field: columnBlueprint.field,
    editable: false,
    width:
      columnBlueprint.width ||
      (columnBlueprint.dataType === dataTypes.BOOLEAN ? 85 : columnBlueprint.width || 100),
    minWidth:
      columnBlueprint.minWidth ||
      (columnBlueprint.dataType === dataTypes.BOOLEAN ? 85 : columnBlueprint.minWidth || 100),
    cellStyle: {
      textAlign: getTextAlignment({
        dataType: columnBlueprint.dataType,
        columnType: columnBlueprint.columnType,
      }),
    },
    ...(columnBlueprint.dataType === dataTypes.BOOLEAN && {
      valueGetter: ({ data, colDef }) => (data[colDef.field] ? 'X' : ''),
      valueParser: ({ newValue }) => Boolean(newValue),
    }),
    hide: columnBlueprint.hide,
    pinned: columnBlueprint.field === 'accountId' ? 'left' : null,
    lockPinned: columnBlueprint.field === 'accountId',
  };
};

export default ({ columnsBlueprint = [], accountType }) => {
  // fallback for no data to show user "no rows to show"
  if (!columnsBlueprint.length) {
    return [defaultNoDataColumn];
  }

  const columns = columnsBlueprint.map(column => getColumnsBasedOnColumnBlueprint(column));

  return accountType === AccountTypes.BASE
    ? columns.filter(({ field }) =>
        baseAccountFieldsToFilterOut.every(fieldToFilterOut => field !== fieldToFilterOut),
      )
    : columns;
};
