import React from 'react';
import PropTypes from 'prop-types';

import {
  AddDatasetInstanceButton,
  DeleteDatasetInstanceButton,
} from '../shared/displayComponents/dataModels/datasetInstanceButtons';
import AppkitIcon from '../shared/displayComponents/appkitIcon/appkitIcon.component';
import ReorderDatasetInstancesButton from '../shared/displayComponents/workpapers/reorderDatasetInstances/reorderDatasetInstancesButton.component';

import styles from './forms.module.scss';

const noop = () => null;

const ViewFormWorkpaperButtons = ({
  disableAllButtons,
  datasetDefinitionMetaData,
  datasetDefId,
  isLoadingInstances,
  enterAddingInstanceMode,
  lockIndicator,
  openModalForDelete,
  editModeButtons,
  datasetInstancesLength,
  datasetInstanceId,
  isSelectedDatasetInstanceIdValid,
  isInAddMode,
  refetchParentGrid = noop,
}) => {
  const { systemDefined, maximumInstancesAllowed } = datasetDefinitionMetaData || {
    systemDefined: false,
    maximumInstancesAllowed: -1,
  };
  const maxInstancesPerPage =
    maximumInstancesAllowed !== -1 && datasetInstancesLength >= maximumInstancesAllowed;

  const disableAnyEditButton = lockIndicator || disableAllButtons;

  const disableReorderButton = disableAnyEditButton || systemDefined || !datasetInstancesLength;

  const disableDeleteButton =
    disableAnyEditButton || !datasetInstanceId || !isSelectedDatasetInstanceIdValid || isInAddMode;

  const disableAddButton = disableAnyEditButton || maxInstancesPerPage;

  return (
    <div className={`col ${styles.buttonControls}`}>
      <span>
        {maxInstancesPerPage && (
          <label className={styles.buttonMessage}>
            {maximumInstancesAllowed} instance/page max
          </label>
        )}
        <ReorderDatasetInstancesButton
          datasetDefinitionId={datasetDefId}
          loading={isLoadingInstances}
          disabled={disableReorderButton}
          size="lg"
          kind="secondary"
          refetchParentGrid={refetchParentGrid}
        />
        <AddDatasetInstanceButton
          systemDefined={systemDefined}
          datasetPending={isLoadingInstances}
          size="lg"
          kind="secondary"
          onClick={enterAddingInstanceMode}
          className="add-button"
          disabled={disableAddButton}
        >
          {lockIndicator && (
            <AppkitIcon className="location-lock-icon" icon="location-locked" size={16} />
          )}
          Add Instance
        </AddDatasetInstanceButton>
      </span>
      <DeleteDatasetInstanceButton
        systemDefined={systemDefined}
        datasetPending={isLoadingInstances}
        size="lg"
        kind="secondary"
        onClick={openModalForDelete}
        className="add-button"
        disabled={disableDeleteButton}
      >
        {lockIndicator && (
          <AppkitIcon className="location-lock-icon" icon="location-locked" size={16} />
        )}
        Delete Instance
      </DeleteDatasetInstanceButton>
      {editModeButtons}
    </div>
  );
};

ViewFormWorkpaperButtons.propTypes = {
  disableAllButtons: PropTypes.bool.isRequired,
  datasetDefinitionMetaData: PropTypes.shape({
    maximumInstancesAllowed: PropTypes.number.isRequired,
    systemDefined: PropTypes.bool.isRequired,
  }),
  datasetDefId: PropTypes.string,
  isLoadingInstances: PropTypes.bool.isRequired,
  enterAddingInstanceMode: PropTypes.func.isRequired,
  lockIndicator: PropTypes.bool.isRequired,
  openModalForDelete: PropTypes.func.isRequired,
  editModeButtons: PropTypes.node.isRequired,
  datasetInstancesLength: PropTypes.number.isRequired,
  datasetInstanceId: PropTypes.string,
  isSelectedDatasetInstanceIdValid: PropTypes.bool,
  isInAddMode: PropTypes.bool.isRequired,
  refetchParentGrid: PropTypes.func,
};

export default ViewFormWorkpaperButtons;
