import PropTypes from 'prop-types';

export const jobPropTypes = {
  jobId: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired,
  createdOn: PropTypes.string.isRequired,
  finishedOn: PropTypes.string,
  startedProcessedOn: PropTypes.string,
  status: PropTypes.string.isRequired,
  returnedError: PropTypes.string,
  returnedValue: PropTypes.string,
};
