import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { genericScreenDefinitionRowSchemas } from '@common-packages/validators';

import { SelectOptionPropTypes } from '../../../shared/propTypes/selectOption';
import Loading from '../../../shared/displayComponents/loading.component';
import {
  accountsOptionsSelector,
  categoriesOptionsSelector,
  isFetchingCategoriesSelector,
  isFetchingAccountsSelector,
} from '../store/selectors';

import EditRowDetailsForm from './editRowDetailsForm.component';
import { rowDetailsPropTypes } from './propTypes';

const EditRowDetails = ({
  isFetchingCategories,
  categoriesOptions,

  isFetchingAccounts,
  accountsOptions,

  rowToEdit,
  setFormikProps,
}) => (
  <Formik
    initialValues={rowToEdit}
    enableReinitialize
    validationSchema={genericScreenDefinitionRowSchemas.commonSchema}
    validateOnMount
  >
    <Loading isLoading={isFetchingAccounts || isFetchingCategories}>
      <EditRowDetailsForm
        categoriesOptions={categoriesOptions}
        accountsOptions={accountsOptions}
        rowId={rowToEdit.rowId}
        setFormikProps={setFormikProps}
      />
    </Loading>
  </Formik>
);

EditRowDetails.propTypes = {
  accountsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  categoriesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isFetchingCategories: PropTypes.bool.isRequired,
  isFetchingAccounts: PropTypes.bool.isRequired,
  rowToEdit: rowDetailsPropTypes.isRequired,
  setFormikProps: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    accountsOptions: accountsOptionsSelector(state),
    categoriesOptions: categoriesOptionsSelector(state),
    isFetchingAccounts: isFetchingAccountsSelector(state),
    isFetchingCategories: isFetchingCategoriesSelector(state),
  }),
  null,
)(EditRowDetails);
