import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import {
  BreadcrumbRoute,
  StaticHomeBreadcrumb,
} from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const FilingGroupsBreadcrumbRoutes = () => (
  <>
    <StaticHomeBreadcrumb routePath={Routes.filingGroups.MAIN} />
    <BreadcrumbRoute path={Routes.filingGroupMembers} />
  </>
);

export default FilingGroupsBreadcrumbRoutes;
