import { Routes, taxNames } from '@common-packages/routes-definitions';
import { NOT_FILING } from '@common-packages/shared-constants';

import { filingTypes } from '../../../shared/constants';
import {
  currencyColumnDefinition,
  defaultColumnDefinition,
} from '../../../shared/columnDefinitions';
import {
  EditableCheckboxCellRenderer,
  LinkCellRendererFactory,
  SelectWithFetchCellEditor,
} from '../../../shared/columnDefinitions/cellRenderers';
import { defaultSelectCellEditorSuppressKeyboardEvent } from '../../../shared/columnDefinitions/constants';
import * as api from '../../store/api';

import { setDefaultValuesForNotFilingJurisdiction } from './filingDecisions.businessLogic';

const CATEGORY_NAMES = [
  { key: 'PROPERTY', field: 'property', name: 'Property' },
  { key: 'RENT', field: 'rent', name: 'Rent' },
  { key: 'PAYROLL', field: 'payroll', name: 'Payroll' },
  { key: 'SALES', field: 'salesOfServiceDestination', name: 'Sales of service destination' },
  { key: 'SALES_ORIGIN', field: 'salesOfServiceOrigin', name: 'Sales of service origin' },
  { key: 'SALES_TANG', field: 'tangibleSales', name: 'Tangible sales' },
];

const getColumnDefinitions = ({
  showErrorNotification,
  isInEditMode,
  updateRow,
  taxYear,
  period,
}) => {
  const onCellValueChanged = ({ data, newValue, colDef }) =>
    updateRow(setDefaultValuesForNotFilingJurisdiction(data, newValue, colDef));

  const onFilingGroupDropdownChanged = ({ data, newValue, colDef, node, api }) => {
    updateRow(setDefaultValuesForNotFilingJurisdiction(data, newValue, colDef));
    // eslint-disable-next-line import/namespace
    api.refreshCells({
      rowNodes: [node],
      force: true,
    });
  };

  const AmountLinkRenderer = categoryNameKey =>
    LinkCellRendererFactory({
      getText: ({ valueFormatted }) => valueFormatted,
      getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
        Routes.filingDecisionAmountDetails.compiledRoute({
          taxYear,
          period,
          businessEntityId: entityId,
          jurisdictionId,
          filingTypeId: filingTypes.SEPARATE,
          categoryName: categoryNameKey,
        }),
      getRouterState: () => ({
        categoryNameForDisplay: CATEGORY_NAMES.find(({ key }) => key === categoryNameKey).name,
      }),
    });

  const IncomeTaxSummaryLinkRender = LinkCellRendererFactory({
    getText: ({ valueFormatted, data: { incomeTax } }) => (incomeTax && valueFormatted) || '',
    getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
      Routes.taxSummaries.compiledRoute({
        taxYear,
        period,
        businessEntityId: entityId,
        jurisdictionId,
        filingTypeId: filingTypes.SEPARATE,
        taxName: taxNames.INCOME_TAX,
      }),
  });

  const FranchiseTaxSummaryLinkRender = LinkCellRendererFactory({
    getText: ({ valueFormatted, data: { franchiseTax } }) => (franchiseTax && valueFormatted) || '',
    getPathName: (value, { taxYear, period, entityId, jurisdictionId }) =>
      Routes.taxSummaries.compiledRoute({
        taxYear,
        period,
        businessEntityId: entityId,
        jurisdictionId,
        filingTypeId: filingTypes.SEPARATE,
        taxName: taxNames.FRANCHISE_TAX,
      }),
  });

  const checkboxCellClassRules = {
    ...defaultColumnDefinition.cellClassRules,
    'editable-cell': () => isInEditMode,
  };

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Jurisdiction',
      field: 'jurisdiction',
      width: 205,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Profile',
      field: 'profileId',
      width: 100,
      editable: isInEditMode,
      onCellValueChanged,
      cellEditor: SelectWithFetchCellEditor,
      cellEditorPopup: true,
      suppressKeyboardEvent: defaultSelectCellEditorSuppressKeyboardEvent,
      cellEditorParams: {
        fetchOptions: async data => {
          const profiles = await api.fetchProfilesOptions({
            taxYear,
            jurisdictionId: data.jurisdictionId,
          });
          return profiles.map(({ profileId }) => ({ value: profileId, label: profileId }));
        },
        showErrorNotification,
      },
    },
    {
      ...defaultColumnDefinition,
      headerName: 'State Filing Group',
      field: 'filingGroup',
      width: 175,
      editable: isInEditMode,
      onCellValueChanged: onFilingGroupDropdownChanged,
      cellEditor: SelectWithFetchCellEditor,
      cellEditorPopup: true,
      suppressKeyboardEvent: defaultSelectCellEditorSuppressKeyboardEvent,
      cellEditorParams: {
        fetchOptions: async data => {
          const filingGroups = await api.fetchEntityFilingGroups({
            taxYear,
            period,
            jurisdictionId: data.jurisdictionId,
          });

          return [
            { value: NOT_FILING, label: NOT_FILING },
            ...filingGroups.map(({ filingGroupId, groupDescription }) => ({
              value: filingGroupId,
              label: groupDescription,
            })),
          ];
        },
        showErrorNotification,
      },
      valueFormatter: ({ value }) => value || NOT_FILING,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Federal As-Filed Filing Group',
      field: 'federalAsFiledFilingGroupId',
      width: 175,
      editable: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Pro Forma Filing Group',
      field: 'proformaFilingGroupId',
      width: 175,
      editable: false,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Frn',
      field: 'franchiseTax',
      width: 50,
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Inc',
      field: 'incomeTax',
      width: 50,
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        disabled: !isInEditMode,
      },
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Nexus',
      field: 'nexusInd',
      width: 50,
      onCellValueChanged,
      cellRenderer: EditableCheckboxCellRenderer,
      cellRendererParams: {
        isDisabled: ({ data }) =>
          !isInEditMode || !data.filingGroup || data.filingGroup === NOT_FILING,
      },
      cellStyle: {
        textAlign: 'center',
      },
      cellClassRules: checkboxCellClassRules,
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Income Amt',
      field: 'incomeAmount',
      cellRenderer: IncomeTaxSummaryLinkRender,
    },
    {
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: 'Franchise Amt',
      field: 'franchiseAmount',
      cellRenderer: FranchiseTaxSummaryLinkRender,
    },
    ...CATEGORY_NAMES.map(({ key, field, name }) => ({
      ...defaultColumnDefinition,
      ...currencyColumnDefinition,
      headerName: name,
      field,
      cellRenderer: AmountLinkRenderer(key),
    })),
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
