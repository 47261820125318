import React from 'react';
import { Field } from 'formik';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import CheckBox from '../../shared/forms/checkboxFormik/checkboxFormik.component';

import { BusinessGroupsPropTypes } from './propTypes';

const AddBusinessGroupForm = ({ values }) => (
  <form>
    <Field
      className="modal-field"
      label="Business Group"
      value={values.businessGroup}
      name="businessGroup"
      component={Input}
    />
    <Field
      label="Description"
      value={values.description}
      name="description"
      className="modal-field"
      component={Input}
      autoComplete="off"
    />
    <Field wrapperClassName="modal-field" label="Active" name="activeInd" component={CheckBox} />
  </form>
);

AddBusinessGroupForm.propTypes = {
  values: BusinessGroupsPropTypes,
};

export default AddBusinessGroupForm;
