import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@pwc/appkit-react/lib/Checkbox';

import styles from './toggleAllSelectionsHeaderComponent.module.scss';

/*
    This implementation is because of ag grid performance decrease
    with customHeader rerenders on each updateRow action

    In order to avoid that, toggler state was moved to target component
    to wrap toggleAllSelectionsHeaderFactory result into useMemo
    due to inability to wrap any hooks into other hook

    Otherwise, ToggleAllSelectionsHeader would be created on each
    component rerender that results in blinking header on each grid interaction
    due to its unmounts and mounts
*/

const Sort = {
  NO_SORT: '',
  ASC: 'asc',
  DESC: 'desc',
};

const ToggleAllSelectionsHeaderFactory = ({
  togglerState,
  setTogglerState,
  isInEditMode,
  handleOverrideAllValues,
  marginLabel = true,
}) => {
  const toggleSelection = () => {
    setTogglerState(prevState => {
      handleOverrideAllValues(!prevState);
      return !prevState;
    });
  };

  const ToggleAllSelectionsHeader = ({ displayName, setSort, column }) => {
    const [currentSort, setCurrentSort] = useState(Sort.NO_SORT);

    const onSortRequested = useCallback(
      event => {
        const getNextOrder = () => {
          if (currentSort === Sort.NO_SORT) {
            return Sort.ASC;
          }

          return currentSort === Sort.ASC ? Sort.DESC : Sort.NO_SORT;
        };

        setSort(getNextOrder(), event.shiftKey);
      },
      [setSort, currentSort],
    );

    useEffect(() => {
      const onSortChanged = () => {
        if (column.isSortAscending()) {
          setCurrentSort(Sort.ASC);
        }

        if (column.isSortDescending()) {
          setCurrentSort(Sort.DESC);
        }

        if (!column.isSortAscending() && !column.isSortDescending()) {
          setCurrentSort(Sort.NO_SORT);
        }
      };

      column.addEventListener('sortChanged', onSortChanged);

      return () => column.removeEventListener('sortChanged', onSortChanged);
    });

    return (
      <div className="ag-cell-label-container">
        <div className="ag-header-cell-label">
          <div className="ag-header-cell-text">
            <div className={marginLabel ? styles.toggleAllSelectionsHeader : null}>
              <div className={styles.checkbox}>
                {isInEditMode && (
                  <Checkbox
                    checked={togglerState}
                    disabled={!isInEditMode}
                    onChange={toggleSelection}
                  />
                )}
              </div>
              <div onClick={onSortRequested}>{displayName}</div>
              <span
                className={`ag-header-icon ag-header-label-icon ag-sort-ascending-icon ${
                  currentSort === Sort.ASC ? '' : 'ag-hidden'
                }`}
              >
                <span className="ag-icon ag-icon-asc" />
              </span>

              <span
                className={`ag-header-icon ag-header-label-icon ag-sort-descending-icon ${
                  currentSort === Sort.DESC ? '' : 'ag-hidden'
                }`}
              >
                <span className="ag-icon ag-icon-desc" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  ToggleAllSelectionsHeader.propTypes = {
    displayName: PropTypes.string.isRequired,
    setSort: PropTypes.func,
    column: PropTypes.shape({
      isSortAscending: PropTypes.func,
      isSortDescending: PropTypes.func,
      addEventListener: PropTypes.func,
      removeEventListener: PropTypes.func,
    }),
  };

  return ToggleAllSelectionsHeader;
};

ToggleAllSelectionsHeaderFactory.propTypes = {
  togglerState: PropTypes.bool.isRequired,
  setTogglerState: PropTypes.func.isRequired,
  isInEditMode: PropTypes.bool.isRequired,
  handleOverrideAllValues: PropTypes.func.isRequired,
  marginLabel: PropTypes.bool,
};

export default ToggleAllSelectionsHeaderFactory;
