import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  MutableRefObject,
} from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  ColumnApi,
  GridApi,
  GridOptions,
  DisplayedColumnsChangedEvent,
  GridReadyEvent,
  GridSizeChangedEvent,
  FilterChangedEvent,
  RowDataUpdatedEvent,
  GetContextMenuItems,
  SortChangedEvent,
  DragStoppedEvent,
  RowClassParams,
} from 'ag-grid-community';
import SdkAgGrid from '@tls/ui-aggrid';

import { clientIdSelector } from '../../../shared/store/context';
import { setClientLocalStorageItem } from '../../localStorage';

import styles from './styles.module.scss';
import appendClassOnlyToFirstVisibleColumn from './appendClassOnlyToFirstVisibleColumn';
import { setColumnStateByLocalStorage } from './utils';

const noop = () => null;

export const defaultGridWrapperId = 'grid-wrapper';

interface CustomAgGridProps extends GridOptions {
  gridWrapperId?: string;
  saveGridStateId?: string;
  innerRef?: MutableRefObject<HTMLDivElement>;
  rowData: Record<string, unknown>[];
  className?: string;
  minHeight?: string | number;
  autoMaxWidth?: boolean;
  shadeRows?: boolean;
  areHeaderCellBordersEnabled?: boolean;
  autoScrollPosition?: 'top' | 'middle' | 'bottom' | null;
  suppressColumnVirtualisation?: boolean;
  onGridReady?: (event: GridReadyEvent) => unknown;
  onGridSizeChanged?: (event: GridSizeChangedEvent) => unknown;
  onFilterChanged?: (event: FilterChangedEvent) => unknown;
  onDisplayedColumnsChanged?: (event: DisplayedColumnsChangedEvent) => unknown;
  onRowDataUpdated?: (event?: RowDataUpdatedEvent) => unknown;
  getContextMenuItems?: GetContextMenuItems;
  [additionalProp: string]: unknown; // properties from @tls/ui-aggrid
}

interface CustomAgGridHandle {
  handleResize: () => void;
}

// disabling because when using `forwardRef`, it isn't automatically detected that we don't need
// prop types when using TS types/interfaces
const AgGrid = forwardRef<CustomAgGridHandle, CustomAgGridProps>((props, ref) => {
  const {
    columnDefs,
    gridWrapperId = defaultGridWrapperId,
    saveGridStateId,
    innerRef,
    className = '',
    onGridReady,
    minHeight,
    autoMaxWidth,
    shadeRows,
    onGridSizeChanged = noop,
    onFilterChanged = noop,
    onDisplayedColumnsChanged = noop,
    onRowDataUpdated = noop,
    areHeaderCellBordersEnabled = false,
    // ! warning: by setting the above to `true`, you won't be able to use `context` property of
    // ! `HeaderClassParams` so in such case:
    // ! headerClass: (params) => ...
    // ! params.context will always be `undefined`
    autoScrollPosition = 'bottom',
    getContextMenuItems = null,
    rowBuffer,
    suppressColumnVirtualisation = true,
    rowDragManaged,
    rowDragMultiRow,
  } = props;
  const clientId = useSelector(clientIdSelector);
  const gridRef = useRef(null);
  const el = innerRef ? innerRef : gridRef;

  const [firstDataRendered, setFirstDataRendered] = useState<boolean>(false);
  const [maxWidth, setMaxWidth] = useState(0);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  useEffect(() => {
    setColumnStateByLocalStorage({
      clientId,
      saveGridStateId,
      columnApi,
      gridApi,
    });
  }, [columnDefs, clientId, saveGridStateId, columnApi, gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setFirstDataRendered(true);
  }, []);

  const saveGridState = useCallback(
    ({
      columnApi,
      api,
    }:
      | FilterChangedEvent
      | SortChangedEvent
      | DragStoppedEvent
      | { columnApi: ColumnApi; api: GridApi }) => {
      if (!saveGridStateId || !firstDataRendered) {
        return;
      }

      const columns = columnApi.getColumnState();
      const filters = api.getFilterModel();

      setClientLocalStorageItem({
        clientId,
        clientDetailKey: saveGridStateId,
        data: {
          filters,
          columns,
        },
      });
    },
    [clientId, saveGridStateId, firstDataRendered],
  );

  const onAgGridReady = useCallback(
    (event: GridReadyEvent) => {
      setColumnApi(event.columnApi);
      setGridApi(event.api);

      if (onGridReady) {
        onGridReady(event);
      }
    },
    [onGridReady],
  );

  const resizeGrid = useCallback(() => {
    if (!el.current || !columnApi) {
      return;
    }

    const agBodyViewport = el.current.querySelector<HTMLDivElement>('.ag-body-viewport');

    if (!agBodyViewport) {
      return;
    }

    // Using a dot notation is a trick because `columnController` is a private property and TS
    // doesn't compile when trying to use private properties. Haven't found a better solution
    // for now
    /* eslint-disable dot-notation */
    if (
      isNaN(columnApi['columnModel'].bodyWidth) ||
      isNaN(columnApi['columnModel'].leftWidth) ||
      isNaN(columnApi['columnModel'].rightWidth)
    ) {
      return;
    }

    const gridWidth =
      columnApi['columnModel'].bodyWidth +
      columnApi['columnModel'].leftWidth +
      columnApi['columnModel'].rightWidth;
    /* eslint-enable dot-notation */

    const scrollbarWidth = 17;
    if (agBodyViewport.scrollHeight > agBodyViewport.offsetHeight) {
      setMaxWidth(Number(gridWidth) + scrollbarWidth);
      return;
    }
    setMaxWidth(gridWidth);
  }, [columnApi, setMaxWidth, el]);

  const handleRowDataUpdated = useCallback(
    (event?: RowDataUpdatedEvent) => {
      resizeGrid();
      if (onRowDataUpdated) {
        onRowDataUpdated(event);
      }
    },
    [resizeGrid, onRowDataUpdated],
  );

  const onAgGridResizeChange = useCallback(
    (event: GridSizeChangedEvent) => {
      resizeGrid();
      onGridSizeChanged(event);

      const selectedNode = event.api.getSelectedNodes()[0];
      if (selectedNode) {
        event.api.ensureNodeVisible(selectedNode, autoScrollPosition);
      }
    },
    [resizeGrid, onGridSizeChanged, autoScrollPosition],
  );

  const handleFilterChanged = useCallback(
    (event: FilterChangedEvent) => {
      resizeGrid();
      onFilterChanged(event);
      saveGridState(event);
    },
    [resizeGrid, onFilterChanged, saveGridState],
  );

  const handleReset = useCallback(() => {
    resizeGrid();
    if (columnApi && gridApi) {
      saveGridState({ columnApi, api: gridApi });
    }
  }, [resizeGrid, saveGridState, columnApi, gridApi]);

  useImperativeHandle(
    ref,
    () => ({
      handleResize: resizeGrid,
    }),
    [resizeGrid],
  );

  const getRowClass = useCallback(
    (params: RowClassParams) => shadeRows && Number(params.node.rowIndex) % 2 === 1 && 'shaded-row',
    [shadeRows],
  );

  const handleDisplayedColumnsChanged = useCallback(
    (event: DisplayedColumnsChangedEvent) => {
      // we use old bootstrap theme that doesn't support theme customization,
      // hence the css+js solution
      if (areHeaderCellBordersEnabled) {
        appendClassOnlyToFirstVisibleColumn(
          event.columnApi.getAllGridColumns(),
          'no-border',
          event.api,
          event.columnApi,
        );
        event.api.refreshHeader();
      }

      onDisplayedColumnsChanged(event);
    },
    [onDisplayedColumnsChanged, areHeaderCellBordersEnabled],
  );

  return (
    <div
      id={gridWrapperId}
      ref={el}
      className={classNames(
        'grid-auto-sizing-wrapper',
        {
          [styles.headerBorders]: areHeaderCellBordersEnabled,
        },
        className,
      )}
      style={{ maxWidth: autoMaxWidth ? 'auto' : maxWidth, minHeight }}
    >
      <SdkAgGrid
        suppressSizeColumnsToFit
        suppressColumnVirtualisation={suppressColumnVirtualisation}
        {...props}
        onGridReady={onAgGridReady}
        rowBuffer={rowBuffer || 0}
        onGridSizeChanged={onAgGridResizeChange}
        onRowDataUpdated={handleRowDataUpdated}
        onFilterChanged={handleFilterChanged}
        onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
        getRowClass={getRowClass}
        createCustomContextMenu={getContextMenuItems}
        onSortChanged={saveGridState}
        onDragStopped={saveGridState}
        onColumnVisible={saveGridState}
        onReset={handleReset}
        // onFirstDataRendered means the grid is fully ready
        onFirstDataRendered={onFirstDataRendered}
        // this property prevents "flashing" the content during the vertical scroll
        suppressAnimationFrame
        animateRows
        rowDragManaged={rowDragManaged}
        rowDragMultiRow={rowDragMultiRow}
      />
    </div>
  );
});

AgGrid.displayName = 'AgGrid';

export default AgGrid;
