import React, { useMemo } from 'react';

import NavigationPrompt from '../displayComponents/navigatorPrompt/navigationPrompt.component';

const useEditModeNavigationPrompt = (isDirty = false) => {
  const navigationPrompt = useMemo(() => <NavigationPrompt show={isDirty} />, [isDirty]);

  return { navigationPrompt };
};

export default useEditModeNavigationPrompt;
