import config from '../config';

interface Callbacks<TData> {
  onMessage: (data: TData) => void;
  onClose?: () => void;
  onOpen?: () => void;
}

interface SSEServiceOptions {
  route: string;
  event?: Events;
  autoReconnect?: boolean;
  reconnectTimeout?: number;
}

export enum Events {
  MESSAGE = 'message',
  NOTIFICATIONS = 'notifications',
  EFILEV2STEPSTATUS = 'e-file-v2-step-status',
}

const DEFAULT_RECONNECT_TIMEOUT = 5000;

// on backend we are sending ping every 110 seconds
const DEFAULT_ALIVE_INTERVAL = 120 * 1000;

const sseServiceWrapper = <TData>(
  {
    route,
    event = Events.MESSAGE,
    autoReconnect = false,
    reconnectTimeout = DEFAULT_RECONNECT_TIMEOUT,
  }: SSEServiceOptions,
  callbacks: Callbacks<TData>,
) => {
  let events: EventSource | null = null;
  let timeout: NodeJS.Timeout | null = null;
  let lastPing: number | null = null;
  /*
  Why we need that?
  When server restarts/breaks, it is not closing connections that are still alive. And since the SSE is a one way communication,
  there is no other way to determine if connection is alive, than just saving time of the last ping
  */
  let aliveInterval: NodeJS.Timeout | null = null;

  const callbackWrapper = (event: Event) => {
    const messageEvent = event as MessageEvent;
    callbacks.onMessage(JSON.parse(messageEvent.data) as TData);
  };

  const connect = () => {
    events = new EventSource(`${config.BASE_PATH}${route}`);

    events.addEventListener(event, callbackWrapper);

    events.addEventListener('ping', () => {
      lastPing = Date.now();
    });

    events.onopen = () => {
      if (callbacks.onOpen) {
        callbacks.onOpen();
      }
    };

    events.onerror = () => {
      if (callbacks.onClose) {
        callbacks.onClose();
      }

      if (autoReconnect) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        reconnect();
      }
    };

    aliveInterval = setInterval(() => {
      if (!lastPing) {
        return;
      }
      if (Date.now() - lastPing > DEFAULT_ALIVE_INTERVAL) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        close();
      }
    }, DEFAULT_ALIVE_INTERVAL);
  };

  const reconnect = () => {
    if (!timeout) {
      timeout = setTimeout(connect, reconnectTimeout);
    }
  };

  const close = () => {
    if (!events) {
      return;
    }

    if (callbacks.onClose) {
      callbacks.onClose();
    }

    events.close();
    events = null;
    if (aliveInterval) {
      clearInterval(aliveInterval);
    }

    if (autoReconnect) {
      reconnect();
    }
  };

  connect();

  return {
    close,
  };
};

export default sseServiceWrapper;
