import React from 'react';
import { Checkbox } from '@pwc/appkit-react';

import { SubmissionId } from '../../../../../../../common/types';
import AppkitIcon from '../../../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import { useQueryGetProformaImportIrsAcceptedResult } from '../../../../../shared/queries/eFileV2';
import { FederalReturnAttachments } from '../../../../../shared/enums';
import { FederalReturnOption } from '../../../../../shared/enums/eFileV2';

import styles from './warningsAndErrors.module.scss';

interface WarningsAndErrorsTypes {
  warningsAndErrors?: { errors: number; warnings: number };
  ignoreErrors: boolean;
  toggleIgnoreErrors: (params?: unknown) => unknown;
  isSignatureDeclarationGeneratedSuccessfully: boolean;
  federalReturnOption: FederalReturnOption | null;
  federalReturnAttachment: FederalReturnAttachments | null;
  federalSubmissionId: SubmissionId | null;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
}

const WarningsAndErrors = ({
  warningsAndErrors,
  ignoreErrors,
  toggleIgnoreErrors,
  isSignatureDeclarationGeneratedSuccessfully,
  federalReturnOption,
  federalReturnAttachment,
  federalSubmissionId,
  isFetchingTaxReturnOrTransmitterProviderTR,
}: WarningsAndErrorsTypes) => {
  const { data: isReturnAcceptedByIrs } = useQueryGetProformaImportIrsAcceptedResult({
    params: { federalSubmissionId },
    enabled: Boolean(federalSubmissionId),
  });

  const isAbleToIgnoreErrors =
    !isFetchingTaxReturnOrTransmitterProviderTR && Boolean(warningsAndErrors?.errors);

  return (
    <div className={styles.warningsAndErrorsContainer}>
      <div className={styles.warningsAndErrorsIconAndTextContent}>
        <AppkitIcon className={styles.alertIcon} icon={'alert'} type="fill" size={35} />
        <div>
          {(federalReturnOption === FederalReturnOption.FED_AS_FILED ||
            federalReturnOption === FederalReturnOption.CONS_AS_FILED) &&
            federalReturnAttachment === FederalReturnAttachments.LINKED &&
            !isReturnAcceptedByIrs && (
              <>
                <span>
                  The federal return selection indicates that the federal return has not been
                  accepted by the MeF system. Submitting this state return in a &quot;Linked&quot;
                  state will result in a rejection/denial.
                </span>
                <br />
                <br />
              </>
            )}
          <span>
            There are still <strong>{warningsAndErrors?.errors} Errors </strong>
            and <strong>{warningsAndErrors?.warnings} Warnings</strong>
          </span>
          <br />
          {Boolean(warningsAndErrors?.errors) && (
            <span className={styles.warningsErrorsSubtext}>
              Errors must be cleared to avoid rejection.
            </span>
          )}
        </div>
      </div>
      {isAbleToIgnoreErrors && (
        <Checkbox
          checked={ignoreErrors}
          onChange={toggleIgnoreErrors}
          disabled={!isSignatureDeclarationGeneratedSuccessfully}
        >
          Ignore Errors
        </Checkbox>
      )}
    </div>
  );
};

export default WarningsAndErrors;
