import React, { useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import columnBlueprintStyles from '../../shared/styles/columnBlueprintStyles.module.scss';
import { globalContextSelector } from '../../shared/store/selectors';
import { setGlobalContext } from '../../shared/store/actions';
import {
  useQueryConsolidatedTaxReturns,
  useQueryReturnStatuses,
  useQueryTaxReturnsReturnDefinitions,
  useQueryTaxReturnsReturnDefinitionsJurisdictionOptions,
} from '../../shared/queries/taxReturns';
import {
  FindConsolidationsDetailsData,
  GlobalContext,
  TaxReturn,
  ConsolidationId,
  JurisdictionId,
} from '../../../../common/types/apiShapes';
import Header from '../../shared/displayComponents/header/header.component';
import styles from '../styles.module.scss';
import useModal from '../../shared/hooks/useModal.hook';
import { useQueryFilingGroups } from '../../shared/queries/filingGroups';
import WarningModal from '../../shared/displayComponents/warningModal.component';
import {
  NO_FILING_GROUPS_MODAL_TITLE,
  NO_FILING_GROUPS_MODAL_MESSAGE,
  AgGridIds,
} from '../constants';
import useBooleanState from '../../shared/hooks/useBooleanState.hook';
import AddEditReturnPanel from '../../shared/taxReturns/addEditConsolidatedReturnPanel/addEditReturnPanel.container';
import { useRowEditMode } from '../../shared/editMode';
import { FilingTypes } from '../../shared/enums';
import { useUpdateReturnStatus } from '../../shared/mutations/taxReturns';
import { useShouldDisplayReturnStatus } from '../../shared/hooks/useShouldDisplayReturnStatus.hook';
import { useShouldDisplayReturnsDueDates } from '../../shared/hooks/useShouldDisplayReturnsDueDates.hook';

import getConsolidatedColumnDefinitions from './setupTaxReturns.columnDefinitions';

const getUniqueRowId = ({ data: { consolidationId } }: { data: FindConsolidationsDetailsData }) =>
  consolidationId;

const SetupTaxReturns = () => {
  const dispatch = useDispatch();
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const { taxYear, period, filingTypeId } = globalContext;

  const shouldDisplayReturnStatus = useShouldDisplayReturnStatus();
  const shouldDisplayDueDate = useShouldDisplayReturnsDueDates();

  const history = useHistory();

  const { showModal: showNoFilingGroupsModal, modalProps: noFilingGroupsModalProps } = useModal();

  const [taxReturnToEdit, setTaxReturnToEdit] = useState<TaxReturn | null>(null);
  const [isPanelVisible, showPanel, hidePanel] = useBooleanState(false);

  const {
    data: consolidationTaxReturns,
    isLoading: isLoadingConsolidationTaxReturns,
  } = useQueryConsolidatedTaxReturns({
    params: { taxYear: taxYear || '', period: period || '' },
    enabled: Boolean(taxYear && period),
  });

  const { data: filingGroups, isLoading: isLoadingFilingGroups } = useQueryFilingGroups({
    params: {
      taxYear: taxYear || '',
      period: period || '',
    },
    enabled: Boolean(taxYear && period),
  });

  const {
    data: taxReturnStatusOptions,
    isLoading: isLoadingTaxReturnStatus,
  } = useQueryReturnStatuses();

  useQueryTaxReturnsReturnDefinitions({
    params: {
      taxYear: taxYear || '',
      period: period || '',
      filingTypeId: filingTypeId || '',
    },
    enabled: Boolean(taxYear && period && filingTypeId),
  });

  useQueryTaxReturnsReturnDefinitionsJurisdictionOptions({
    params: { taxYear: taxYear || '', filingTypeId: filingTypeId || '' },
    enabled: Boolean(taxYear),
  });

  const { mutateAsync: updateReturnStatus } = useUpdateReturnStatus();

  const handleEditIconClick = useCallback(
    (taxReturn: TaxReturn) => {
      setTaxReturnToEdit(taxReturn);
      showPanel();
    },
    [showPanel],
  );

  const handleNoFilingGroupsModalSubmit = useCallback(() => {
    history.push({ pathname: Routes.consolidationFilingGroups.MAIN });
  }, [history]);

  const handleAddTaxReturn = useCallback(() => {
    setTaxReturnToEdit(null);
    if (!filingGroups?.length) {
      showNoFilingGroupsModal();
      return;
    }
    showPanel();
  }, [showNoFilingGroupsModal, filingGroups?.length, showPanel]);

  const {
    gridApi,
    onGridReady,
    updateRow,
    clonedRowData: clonedConsolidationTaxReturns,
  } = useRowEditMode({
    rowData: consolidationTaxReturns?.data,
    getUniqueRowId,
    saveButtonDisabled: false,
  });

  const handleUpdateRow = useCallback(
    (data?: FindConsolidationsDetailsData) => {
      if (data) {
        gridApi?.clearFocusedCell();
        updateRow({ ...data });
        updateReturnStatus({
          returnId: Number(data.returnId),
          taxReturnStatus: data.returnStatus || null,
        });
      }
    },
    [updateRow, updateReturnStatus, gridApi],
  );

  const setContext = useCallback(
    ({
      consolidationId,
      jurisdictionId,
    }: {
      consolidationId: ConsolidationId | null;
      jurisdictionId: JurisdictionId | null;
    }) =>
      setGlobalContext({
        taxYear: globalContext.params.taxYear,
        period: globalContext.params.period,
        entity: consolidationId,
        consolidationId,
        jurisdictionId,
        filingTypeId: FilingTypes.CONSOLIDATED,
        dispatch,
        globalContext,
      }),
    [dispatch, globalContext],
  );

  const columnDefinitions = useMemo(
    () =>
      getConsolidatedColumnDefinitions(
        consolidationTaxReturns?.columnsBlueprint || [],
        shouldDisplayReturnStatus,
        shouldDisplayDueDate,
        handleEditIconClick,
        handleUpdateRow,
        setContext,
        taxReturnStatusOptions,
      ),

    [
      handleEditIconClick,
      handleUpdateRow,
      setContext,
      consolidationTaxReturns,
      taxReturnStatusOptions,
      shouldDisplayReturnStatus,
      shouldDisplayDueDate,
    ],
  );

  const isGridLoading = isLoadingTaxReturnStatus || isLoadingConsolidationTaxReturns;

  const addReturnButton = (
    <Button
      size="lg"
      onClick={handleAddTaxReturn}
      disabled={isLoadingConsolidationTaxReturns || isLoadingFilingGroups}
    >
      Add Return
    </Button>
  );

  const onResetFiltersClick = useCallback(() => {
    gridApi?.setFilterModel(null);
  }, [gridApi]);

  return (
    <>
      <Header
        title="Setup Tax Returns"
        description="View and configure settings associated with a state tax return."
        rightHeaderContent={addReturnButton}
      />
      <div className={styles.setupTaxReturnsContainer}>
        <div className={styles.datagridHeader}>
          <Link to={{}} onClick={onResetFiltersClick}>
            Reset Filters
          </Link>
        </div>
        <div className={`row ${styles.setupTaxReturnsGridWrapper}`}>
          <AgGrid
            className={columnBlueprintStyles.gridContainer}
            rowData={clonedConsolidationTaxReturns}
            columnDefs={columnDefinitions}
            isGridLoading={isGridLoading}
            autoMaxWidth
            sideBar={defaultSideBarWithColumnsToolPanel}
            saveGridStateId={AgGridIds.CONSOLIDATION}
            headerHeight={50}
            groupHeaderHeight={30}
            onGridReady={onGridReady}
            singleClickEdit
            stopEditingWhenGridLosesFocus
            getRowId={getUniqueRowId}
            withSearchBar
            withResetIcon
          />
        </div>
      </div>
      <AddEditReturnPanel
        hidePanel={hidePanel}
        isPanelVisible={isPanelVisible}
        taxReturnToEdit={taxReturnToEdit}
        columnsBlueprint={consolidationTaxReturns?.columnsBlueprint || []}
        taxReturns={consolidationTaxReturns}
        calcPreferencesDefinitions={consolidationTaxReturns?.calcPreferencesDefinitions || []}
      />
      <WarningModal
        title={NO_FILING_GROUPS_MODAL_TITLE}
        warningMessage={NO_FILING_GROUPS_MODAL_MESSAGE}
        submitText="Yes"
        submitAction={handleNoFilingGroupsModalSubmit}
        dismissText="No"
        {...noFilingGroupsModalProps}
      />
    </>
  );
};

export default SetupTaxReturns;
