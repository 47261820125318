import { defaultColumnDefinitionWithFilter } from '../../shared/columnDefinitions';

const getColumnDefinitions = ({ hasPermissionToAuthorize }) => {
  return [
    {
      ...defaultColumnDefinitionWithFilter,
      flex: 1,
      resizable: false,
      headerCheckboxSelection: hasPermissionToAuthorize,
      headerCheckboxSelectionFilteredOnly: hasPermissionToAuthorize,
      ...(hasPermissionToAuthorize
        ? {
            checkboxSelection: params => {
              const isEnabled = !params.data?.authorized;
              params.node.selectable = isEnabled;

              return isEnabled;
            },
          }
        : {}),
      headerName: 'Client',
      field: 'name',
    },
  ];
};

export default getColumnDefinitions;
