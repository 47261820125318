import { useEffect, useCallback } from 'react';

import useGridApi from './useGridApi.hook';

const useGridWithEditForm = (gridData, setDataToEdit, getRowId) => {
  const { onGridReady, gridApi } = useGridApi();

  useEffect(() => {
    const rowToSelect = gridData[0];
    if (!gridApi) {
      return;
    }

    if (!rowToSelect) {
      setDataToEdit(null);

      return;
    }

    const nodeToSelect = gridApi.getRowNode(getRowId({ data: rowToSelect }));

    if (nodeToSelect) {
      nodeToSelect.setSelected(true);
    }
  }, [setDataToEdit, gridData, gridApi, getRowId]);

  const clearSelection = useCallback(() => {
    if (gridApi) {
      gridApi.deselectAll();
    }
    setDataToEdit(null);
  }, [gridApi, setDataToEdit]);

  const handleRowSelection = useCallback(
    ({ api }) => {
      const selectedRow = api.getSelectedRows()[0];
      if (selectedRow) {
        setDataToEdit(selectedRow);
      }
    },
    [setDataToEdit],
  );

  return {
    agGridProps: {
      onSelectionChanged: handleRowSelection,
      onGridReady,
      getRowId,
    },
    gridApi,
    clearSelection,
  };
};

export default useGridWithEditForm;
