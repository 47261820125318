import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Button } from '@pwc/appkit-react';
import { JobStatus } from '@common-packages/shared-constants';

import { jobPropTypes } from '../../jobs/propTypes';
import { startUserJobsPolling } from '../../jobs/store/actions';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import Loading from '../../shared/displayComponents/loading.component';
import Dropzone from '../../shared/forms/dropzone/dropzone.container';
import { periodSelector, taxYearSelector } from '../../shared/store/selectors';
import styles from '../shared/dataImportPage.module.scss';
import { REPLACE_DATA, REJECT_OPTION_VALUE, DOCUMENT_ACCEPTED_TYPES } from '../constants';
import UploadStatus from '../uploadStatus/uploadStatus.component';

import ImportDataGrid from './importDataGrid/importDataGrid.component';
import { uploadFileForGenericAccountDataUpload } from './store/actions';
import {
  lastGenericAccountDataUploadJobSelector,
  isUploadingFileSelector,
} from './store/selectors';
import { fetchLastImportData } from './store/api';
import scopedStyles from './genericAccountDataUpload.module.scss';

const DATA_OPTIONS = [
  {
    label: 'Update Data',
    value: 'updateData',
  },
  {
    label: 'Replace Data',
    value: REPLACE_DATA,
  },
];

const DUPLICATE_OPTIONS = [
  { value: REJECT_OPTION_VALUE, label: 'Reject' },
  { value: 'aggregate', label: 'Aggregate' },
];

const getHdrIdFromSuccessfulImport = job => {
  // wrapping in a try-catch block since `returnedValue` may be not a valid JSON
  try {
    const { hdrId } = JSON.parse(job?.returnedValue) || {};
    return hdrId;
  } catch (error) {
    if (error instanceof SyntaxError) {
      return null;
    }
    throw error;
  }
};

const getBatchIdFromFailedImport = job => {
  // wrapping in a try-catch block since `returnedError` may be not a valid JSON
  try {
    const { batchId } = JSON.parse(job?.returnedError) || {};
    return batchId;
  } catch (error) {
    if (error instanceof SyntaxError) {
      return null;
    }
    throw error;
  }
};

const GenericAccountDataUpload = ({
  taxYear,
  period,
  uploadFileForGenericAccountDataUpload,
  showConfirmModal,
  lastGenericAccountDataUploadJob,
  isUploadingFile,
  startUserJobsPolling,
}) => {
  const [file, setFile] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [dataOption, setDataOption] = useState(DATA_OPTIONS[0].value);
  const [duplicateOption, setDuplicateOption] = useState(REJECT_OPTION_VALUE);

  // hdrId is used to get data when the import was successful
  const hdrId = getHdrIdFromSuccessfulImport(lastGenericAccountDataUploadJob);
  // batchId is used to get data when the import failed (validation errors)
  const batchId = getBatchIdFromFailedImport(lastGenericAccountDataUploadJob);
  const isValidationError = Boolean(batchId);
  const isGridVisible = hdrId || batchId;

  const fetchLastImportDataPage = useCallback(
    ({ offset, sort }) => {
      const escapedSortModel = sort.length ? encodeURIComponent(JSON.stringify(sort)) : null;
      return fetchLastImportData({
        ...(hdrId && { hdrId }),
        ...(batchId && { batchId }),
        offset,
        sort: escapedSortModel,
      });
    },
    [hdrId, batchId],
  );

  const onDrop = useCallback(file => {
    setFile(file);
    setIsFileLoading(true);
  }, []);

  const onFileBufferLoaded = useCallback(() => setIsFileLoading(false), []);

  const uploadFile = useCallback(() => {
    const shouldReplaceAccountData = dataOption === REPLACE_DATA;
    const shouldAggregateData = duplicateOption !== REJECT_OPTION_VALUE;
    const onDataUpload = async () => {
      await uploadFileForGenericAccountDataUpload({
        taxYear,
        period,
        file,
        shouldReplaceAccountData,
        shouldAggregateData,
      });
      setDataOption(DATA_OPTIONS[0].value);
      setFile(null);
      startUserJobsPolling();
    };

    if (shouldReplaceAccountData) {
      showConfirmModal({
        title: 'Replace Data?',
        text:
          'This option will delete all existing account data for tax year, period, entity and jurisdiction combinations!',
        confirmCallback: () => {
          onDataUpload();
        },
      });
      return;
    }
    onDataUpload();
  }, [
    uploadFileForGenericAccountDataUpload,
    showConfirmModal,
    dataOption,
    duplicateOption,
    taxYear,
    period,
    file,
    startUserJobsPolling,
  ]);

  const handleDataOptionsChange = useCallback(
    ({ value }) => {
      setDataOption(value);
    },
    [setDataOption],
  );

  const handleDuplicateOptionsChange = useCallback(
    ({ value }) => {
      setDuplicateOption(value);
    },
    [setDuplicateOption],
  );

  const isImportingData =
    lastGenericAccountDataUploadJob &&
    (lastGenericAccountDataUploadJob.status === JobStatus.WAITING ||
      lastGenericAccountDataUploadJob.status === JobStatus.PROCESSING);

  return (
    <div className={styles.uploadFormSection}>
      <div className="row">
        <div className={`col ${styles.fileFormat}`}>
          <p className={styles.fileFormatHeader}>File Format:</p>
          <p>org_id, account_id, jurisdiction_id, beg_end_flag, amount</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Dropzone
            className={styles.dropzone}
            value={file}
            onDropCallback={onDrop}
            onFileBufferLoaded={onFileBufferLoaded}
            acceptTypes={DOCUMENT_ACCEPTED_TYPES}
            disabled={isImportingData || isFileLoading}
          />
        </div>
      </div>
      <div className={`row ${styles.uploadFormDropdown}`}>
        <div className="col">
          <SDKCustomSelect
            appkitLabel="Data Options"
            className="sdk-custom-select"
            options={DATA_OPTIONS}
            value={dataOption}
            onChange={handleDataOptionsChange}
            disabled={isImportingData}
            virtualized
          />
        </div>
        <div className="col">
          <SDKCustomSelect
            appkitLabel="Duplicate Options"
            className="sdk-custom-select"
            options={DUPLICATE_OPTIONS}
            value={duplicateOption}
            onChange={handleDuplicateOptionsChange}
            disabled={isImportingData}
            virtualized
          />
        </div>
        <div className="col">
          <Button size="lg" disabled={isFileLoading || !file} onClick={uploadFile}>
            Upload
          </Button>
        </div>
      </div>
      {(lastGenericAccountDataUploadJob || isUploadingFile) && (
        <div className={scopedStyles.uploadSection}>
          {isUploadingFile ? (
            <Loading isLoading />
          ) : (
            <UploadStatus
              job={lastGenericAccountDataUploadJob}
              isValidationError={isValidationError}
            >
              {isGridVisible && (
                <ImportDataGrid fetchLastImportDataPage={fetchLastImportDataPage} />
              )}
            </UploadStatus>
          )}
        </div>
      )}
    </div>
  );
};

GenericAccountDataUpload.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  uploadFileForGenericAccountDataUpload: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  lastGenericAccountDataUploadJob: PropTypes.shape(jobPropTypes),
  isUploadingFile: PropTypes.bool.isRequired,
  startUserJobsPolling: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    lastGenericAccountDataUploadJob: lastGenericAccountDataUploadJobSelector(state),
    isUploadingFile: isUploadingFileSelector(state),
  }),
  {
    uploadFileForGenericAccountDataUpload,
    showConfirmModal,
    startUserJobsPolling,
  },
)(GenericAccountDataUpload);
