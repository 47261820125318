import { useEffect, useState, useCallback } from 'react';

import getFormFieldNameWithMultCondition from '../../shared/forms/getFormFieldNameWithMultCondition';
import { defaultDisplayDropdownValue } from '../../development/taxFormsV2/form/constants';
import config from '../../config';

export const FIELD_FONT_NORMAL = 'normal';
export const FIELD_FONT_BOLD = 'bold';
export const FIELD_COLOR = 'rgb(221, 228, 255)';
export const FIELD_COLOR_WHITE = 'white';
export const FIELD_COLOR_HOVER = 'rgb(255, 248, 217)';
export const FIELD_COLOR_SELECTED = 'rgb(217, 255, 221)';
export const FIELD_COLOR_SELECTED_LIGHTER = 'rgb(237, 251, 238)';
const FIELD_COLOR_TRANSITION = 'background-color 300ms linear';
const FIELD_CURSOR = 'pointer';
const FIELD_ALIGNMENT = 'center';
const FIELD_READONLY = 'readonly';
const FIELD_FONT_SIZE = '12px';
const FIELD_FONT_FAMILY = 'Courier, monospace';
const FIRST_MULT_FIELD_SUFFIX = '-1';
const FUNC_ICON_URL = `${config.BASE_PATH}/assets/func-icon-orange.svg`;

const matchFieldWithData = ({ fieldsValues, valueKey }) => fieldElement => {
  fieldElement[valueKey] = fieldElement.id;

  if (fieldsValues) {
    const defaultFieldData = { [valueKey]: '' };
    const fieldElementName = getFormFieldNameWithMultCondition(fieldElement.id);
    const fieldData =
      fieldsValues.find(({ name }) => name === fieldElementName) || defaultFieldData;

    return {
      fieldElement,
      fieldData,
    };
  }

  return {
    fieldElement,
  };
};

const getFieldEventListeners = ({
  handleFormFieldSelection,
  handleDoubleClickSelection,
  handleCommandClick,
  selectedField,
  selectedFormFieldName,
  valueKey,
  isShiftKeyUsedForSelecting,
  isCommandOrControlUsedForSelecting,
  handleContextMenu,
  handleClickOutside,
  setCurrentItemValue,
}) => ({ fieldElement, fieldData }) => {
  const clickListener = event => {
    handleClickOutside();
    if (isCommandOrControlUsedForSelecting && (event.metaKey || event.ctrlKey)) {
      handleCommandClick(fieldElement[valueKey]);
    }

    if (isShiftKeyUsedForSelecting && event.shiftKey) {
      handleFormFieldSelection(fieldElement[valueKey]);
    } else if (!isShiftKeyUsedForSelecting) {
      handleFormFieldSelection(fieldElement[valueKey]);
    }
    event.preventDefault();
  };

  const doubleClickListener = event => {
    handleDoubleClickSelection(fieldElement[valueKey]);
    event.preventDefault();
  };

  const rightClickListener = event => {
    setCurrentItemValue(fieldElement[valueKey]);
    handleContextMenu(event);
  };

  const isFieldNonSelectedField =
    fieldElement[valueKey] !== selectedField[valueKey] &&
    !fieldElement[valueKey].includes(selectedField[valueKey]);

  // keep fontWeight bold when there's a dropdown mult field selection
  // of first mult field (indicated with '-1')
  const isFirstFieldSelectedWithDropDownSelection =
    selectedFormFieldName === selectedField[valueKey] &&
    fieldElement[valueKey].includes(selectedFormFieldName) &&
    fieldElement[valueKey].slice(-2) === FIRST_MULT_FIELD_SUFFIX;

  const mouseOverListener = () => {
    fieldElement.style.fontWeight = FIELD_FONT_BOLD;
    if (isFieldNonSelectedField) {
      fieldElement.style.backgroundColor = FIELD_COLOR_HOVER;
    }
  };

  const mouseOutListener = () => {
    if (isFieldNonSelectedField) {
      fieldElement.style.fontWeight = FIELD_FONT_NORMAL;
      fieldElement.style.backgroundColor = FIELD_COLOR;
    } else if (
      !isFirstFieldSelectedWithDropDownSelection &&
      fieldElement[valueKey] !== selectedFormFieldName
    ) {
      // keep fontWeight of lighter selected mult fields normal
      fieldElement.style.fontWeight = FIELD_FONT_NORMAL;
    }
  };

  return {
    fieldElement,
    fieldData,
    clickListener,
    doubleClickListener,
    mouseOverListener,
    mouseOutListener,
    rightClickListener,
  };
};

const expandNameListener = e => {
  e.stopPropagation();
  const { target } = e;
  const element = document.createElement('div');
  element.innerHTML = target.value;
  element.style.position = 'absolute';
  element.style.top = target.style.top;
  element.style.bottom = target.style.bottom;
  element.style.left = target.style.left;
  element.style.right = target.style.right;
  element.style.fontSize = target.style.fontSize;
  element.style.lineHeight = target.style.lineHeight;
  element.style.zIndex = 1;
  element.style.backgroundColor = 'orange';
  element.style.whiteSpace = 'nowrap';
  element.style.transform = 'translateY(100%)';
  element.style.opacity = '0';
  element.style.transition = 'opacity 0.5s';
  element.style.pointerEvents = 'none';
  element.id = 'pdf-form-expand-name';
  target.parentNode.appendChild(element);

  const targetRect = target.getBoundingClientRect();
  const parentRect = target.parentNode.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  if (targetRect.width >= elementRect.width) {
    element.remove();
  } else {
    if (parentRect.right < elementRect.right) {
      const diff = elementRect.right - parentRect.right;
      element.style.transform = `translate(-${diff}px, 100%)`;
    }
    // Fade In
    element.style.opacity = '100%';
  }
};

const removeExpandNameListener = () => {
  document.querySelector('#pdf-form-expand-name')?.remove();
};

/**
 * When it comes to selecting fields, we have 2 different options:
 * 1) Selecting the field on the form
 * 2) Selecting the field in the 'Field Name' dropdown in the 'Fields' tab
 *
 * Normal fields behave the same way but mult fields don't so we can focus on those:
 *
 * If we select a mult field labeled 'SOMEMULTFIELD-1' on the form:
 *  selectedField[valueKey] has value 'SOMEMULTFIELD-'
 *  selectedFormFieldName   has value 'SOMEMULTFIELD-1'
 *
 * If we select a mult field labeled 'SOMEMULTFIELD-' on the dropdown:
 *  selectedField[valueKey] has value 'SOMEMULTFIELD-'
 *  selectedFormFieldName   has value 'SOMEMULTFIELD-'
 *
 * This distinction allows us to handle both cases:
 * 1) For selection on the form we can just check that these values differ
 * 2) For dropdown selection we check if these values are the same
 */

const usePdfFormFields = ({
  fields,
  disabled = false,
  valueKey = 'name',
  filterKey = 'name',
  fieldsValues = null,
  selectedField = {},
  selectedFormFieldName = null,
  pdfFieldsByPage,
  selectedDisplayFormValue,
  handleFormFieldSelection = () => null,
  handleDoubleClickSelection = () => null,
  handleCommandClick = () => null,
  isShiftKeyUsedForSelecting = false,
  isCommandOrControlUsedForSelecting = false,
  isUsingCustomContextMenu = false,
}) => {
  if (!selectedFormFieldName) {
    selectedFormFieldName = selectedField[valueKey];
  }

  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [clickPosition, setClickPosition] = useState({
    x: 0,
    y: 0,
  });
  const [currentItemValue, setCurrentItemValue] = useState('');

  const handleClickOutside = useCallback(() => {
    if (isContextMenuActive) {
      setCurrentItemValue('');
      setIsContextMenuActive(false);
    }
  }, [isContextMenuActive]);

  const handleContextMenu = useCallback(
    event => {
      event.preventDefault();
      if (disabled) {
        return;
      }
      setClickPosition({
        x: event.clientX,
        y: event.clientY,
      });
      setIsContextMenuActive(true);
    },
    [disabled],
  );

  useEffect(() => {
    if (!fields.length) {
      return;
    }
    if (isUsingCustomContextMenu) {
      document.addEventListener('click', handleClickOutside);
    }

    const fieldsWithListeners = fields
      .map(matchFieldWithData({ fieldsValues, valueKey }))
      .filter(({ fieldData }) => !fieldData || fieldData[filterKey])
      .map(
        getFieldEventListeners({
          handleFormFieldSelection,
          handleDoubleClickSelection,
          handleCommandClick,
          selectedField,
          selectedFormFieldName,
          valueKey,
          isShiftKeyUsedForSelecting,
          isCommandOrControlUsedForSelecting,
          handleContextMenu,
          handleClickOutside,
          setCurrentItemValue,
        }),
      );

    const displayDropdownDataSelector = name => {
      if (selectedDisplayFormValue === defaultDisplayDropdownValue) {
        return name;
      }
      const numericSuffixRegExpPattern = RegExp('-[\\d]+$');

      const pdfField = pdfFieldsByPage?.find(pdfFieldValue => {
        const fieldName = numericSuffixRegExpPattern.test(name)
          ? name.replace(numericSuffixRegExpPattern, '-')
          : name;

        return fieldName === pdfFieldValue[valueKey];
      });

      return pdfField?.dataItemDefinitionName || '';
    };

    fieldsWithListeners.forEach(
      ({
        fieldElement,
        fieldData,
        clickListener,
        doubleClickListener,
        mouseOverListener,
        mouseOutListener,
        rightClickListener,
      }) => {
        fieldElement.value = displayDropdownDataSelector(fieldElement[valueKey]);
        fieldElement.readOnly = FIELD_READONLY;

        const isFieldElMulti = fieldElement[valueKey].indexOf('-') >= 0;
        const isSelectedFieldMulti = selectedField[valueKey]?.indexOf('-') >= 0;

        /**
         * Logic for handling styling for selected normal and mult fields:
         * 1) Normal field
         *    a) Highlight selected normal field
         * 2) Mult field selected in form
         *    a) Highlight selected mult field
         *    b) Lighter highlight for rest of the mult fields sharing the same field name
         * 3) Mult field selected in dropdown
         *    a) Highlight the first mult field
         *    b) Lighter highlight for rest of the mult fields sharing the same field name
         */
        // normal field
        if (!isFieldElMulti && !isSelectedFieldMulti) {
          // change style for selected field
          if (fieldElement[valueKey] === selectedFormFieldName) {
            fieldElement.style.backgroundColor = FIELD_COLOR_SELECTED;
            fieldElement.style.fontWeight = FIELD_FONT_BOLD;
          } else {
            // set normal style for other normal fields
            fieldElement.style.backgroundColor = FIELD_COLOR;
            fieldElement.style.fontWeight = FIELD_FONT_NORMAL;
          }
        } else if (selectedField[valueKey] !== selectedFormFieldName) {
          // mult field selected in form
          if (selectedFormFieldName === fieldElement[valueKey]) {
            // selected mult field
            fieldElement.style.backgroundColor = FIELD_COLOR_SELECTED;
            fieldElement.style.fontWeight = FIELD_FONT_BOLD;
          } else if (fieldElement[valueKey].startsWith(selectedField[valueKey])) {
            // other related mult fields
            // reset fontWeight
            fieldElement.style.backgroundColor = FIELD_COLOR_SELECTED_LIGHTER;
            fieldElement.style.fontWeight = FIELD_FONT_NORMAL;
          } else {
            // set normal style for other mult fields
            fieldElement.style.backgroundColor = FIELD_COLOR;
            fieldElement.style.fontWeight = FIELD_FONT_NORMAL;
          }
        } else if (
          fieldElement[valueKey].slice(-2) === FIRST_MULT_FIELD_SUFFIX &&
          fieldElement[valueKey].startsWith(selectedField[valueKey])
        ) {
          // mult field selected in dropdown
          // highlight the first mult field of this group that ends with '-1'
          fieldElement.style.backgroundColor = FIELD_COLOR_SELECTED;
          fieldElement.style.fontWeight = FIELD_FONT_BOLD;
        } else if (fieldElement[valueKey].startsWith(selectedField[valueKey])) {
          // set others to lighter selected color
          // reset fontWeight
          fieldElement.style.backgroundColor = FIELD_COLOR_SELECTED_LIGHTER;
          fieldElement.style.fontWeight = FIELD_FONT_NORMAL;
        } else {
          // set normal style for leftover fields
          fieldElement.style.backgroundColor = FIELD_COLOR;
          fieldElement.style.fontWeight = FIELD_FONT_NORMAL;
        }
        if (fieldData?.hasExpression) {
          fieldElement.style.backgroundImage = `url(${FUNC_ICON_URL})`;
          fieldElement.style.backgroundRepeat = 'no-repeat';
          fieldElement.style.backgroundSize = '15px 15px';
          fieldElement.style.paddingLeft = '18px';
          fieldElement.style.backgroundPosition = 'left center';
          if (fieldElement.clientWidth < 30) {
            fieldElement.style.backgroundSize = '8px 8px';
            fieldElement.style.paddingLeft = '9px';
          }
        }

        fieldElement.style.transition = FIELD_COLOR_TRANSITION;
        fieldElement.style.cursor = FIELD_CURSOR;
        fieldElement.style.textAlign = FIELD_ALIGNMENT;
        fieldElement.style.fontFamily = FIELD_FONT_FAMILY;
        fieldElement.style.fontSize = FIELD_FONT_SIZE;
        fieldElement.disabled = disabled;
        fieldElement.addEventListener('click', clickListener);
        fieldElement.addEventListener('dblclick', doubleClickListener);
        fieldElement.addEventListener('mouseover', mouseOverListener);
        fieldElement.addEventListener('mouseover', expandNameListener);
        fieldElement.addEventListener('mouseout', mouseOutListener);
        fieldElement.addEventListener('mouseout', removeExpandNameListener);

        if (isUsingCustomContextMenu) {
          fieldElement.addEventListener('contextmenu', rightClickListener);
        }
      },
    );

    return () => {
      if (isUsingCustomContextMenu) {
        document.removeEventListener('click', handleClickOutside);
      }

      fieldsWithListeners.forEach(
        ({
          fieldElement,
          clickListener,
          doubleClickListener,
          mouseOverListener,
          mouseOutListener,
          rightClickListener,
        }) => {
          if (fieldsValues) {
            fieldElement.value = null;
            fieldElement.style.fontWeight = FIELD_FONT_NORMAL;
            fieldElement.style.backgroundColor = FIELD_COLOR_WHITE;
          }
          fieldElement.removeEventListener('click', clickListener);
          fieldElement.removeEventListener('dblclick', doubleClickListener);
          fieldElement.removeEventListener('mouseover', mouseOverListener);
          fieldElement.removeEventListener('mouseover', expandNameListener);
          fieldElement.removeEventListener('mouseout', mouseOutListener);
          fieldElement.removeEventListener('mouseout', removeExpandNameListener);

          if (isUsingCustomContextMenu) {
            fieldElement.removeEventListener('contextmenu', rightClickListener);
          }
        },
      );
    };
  }, [
    handleFormFieldSelection,
    handleDoubleClickSelection,
    handleCommandClick,
    handleContextMenu,
    handleClickOutside,
    pdfFieldsByPage,
    selectedDisplayFormValue,
    fields,
    selectedField,
    selectedFormFieldName,
    valueKey,
    filterKey,
    disabled,
    fieldsValues,
    isShiftKeyUsedForSelecting,
    isCommandOrControlUsedForSelecting,
    isUsingCustomContextMenu,
  ]);

  return {
    isContextMenuActive,
    clickPosition,
    currentItemValue,
  };
};

export default usePdfFormFields;
