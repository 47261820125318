import { createSelector } from 'reselect';

import {
  consolidationIdSelector,
  taxYearSelector,
  periodSelector,
  jurisdictionIdSelector,
  entityIdSelector,
} from '../../shared/store/selectors';

export const isFetchingConsolidationFilingAttributesSelector = ({ filingAttributes }) =>
  filingAttributes.isFetchingConsolidationFilingAttributes;

export const consolidationFilingAttributesSelector = createSelector(
  [({ filingAttributes }) => filingAttributes.consolidationFilingAttributes.data],
  filingAttributes =>
    filingAttributes.map((filingAttribute, rowId) => ({
      ...filingAttribute,
      rowId,
    })),
);

export const consolidationFilingAttributesColumnsBlueprintSelector = ({ filingAttributes }) =>
  filingAttributes.consolidationFilingAttributes.columnsBlueprint;

export const isFetchingEntityFilingAttributesSelector = ({ filingAttributes }) =>
  filingAttributes.isFetchingEntityFilingAttributes;

export const entityFilingAttributesSelector = createSelector(
  [({ filingAttributes }) => filingAttributes.entityFilingAttributes.data],
  filingAttributes =>
    filingAttributes.map((filingAttribute, rowId) => ({
      ...filingAttribute,
      rowId,
    })),
);

export const entityFilingAttributesColumnsBlueprintSelector = ({ filingAttributes }) =>
  filingAttributes.entityFilingAttributes.columnsBlueprint;

export const isEntityContextReadySelector = createSelector(
  [taxYearSelector, periodSelector, entityIdSelector],
  (taxYear, period, entityId) => Boolean(taxYear && period && entityId),
);

export const isConsolidatedContextReadySelector = createSelector(
  [taxYearSelector, periodSelector, consolidationIdSelector, jurisdictionIdSelector],
  (taxYear, period, consolidationId, jurisdictionId) =>
    Boolean(taxYear && period && consolidationId && jurisdictionId),
);

export const contextSelector = createSelector(
  [
    taxYearSelector,
    periodSelector,
    consolidationIdSelector,
    entityIdSelector,
    jurisdictionIdSelector,
  ],
  (taxYear, period, consolidationId, entityId, jurisdictionId) => ({
    taxYear,
    period,
    consolidationId,
    entityId,
    jurisdictionId,
  }),
);
