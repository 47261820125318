import { httpPostAndGetBlob, httpGetAndParse, httpPostAndParse } from '../../utils/fetchService';

export const runConsolidatedReport = params =>
  httpPostAndGetBlob({
    route: '/api/reports/trg/8313',
    body: params,
    errorMessage: 'Running TRG consolidated report failed.',
  });

export const fetchTrgReport = ({ id }) =>
  httpGetAndParse({
    route: `/api/reports/trg/${id}`,
    errorMessage: 'Fetching custom report failed',
  });

export const runTrgReport = ({ params, trgId, reportFileFormat }) =>
  httpPostAndGetBlob({
    route: `/api/reports/trg/user-generated/${trgId}/${reportFileFormat}`,
    body: params,
    errorMessage: 'Running TRG report failed.',
  });

export const runParamQuery = ({ reportId, paramName, params }) =>
  httpPostAndParse({
    route: `/api/shared/run-param-query/${reportId}/${paramName}`,
    errorMessage: 'Running custom query failed',
    body: { params },
  });
