import { adminColumnDefinition, defaultColumnDefinition } from '../../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../../shared/columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ onDeleteIconClick, onEditIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({ onDeleteIconClick, onEditIconClick });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Visualization ID',
      field: 'visualizationId',
      width: 80,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Name',
      field: 'name',
      width: 400,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
