import React from 'react';
import { Breadcrumb } from '@pwc/appkit-react';

interface PageProps {
  children?: JSX.Element;
  className?: string;
  disableBreadcrumbs?: boolean;
  globalContextDropdowns: JSX.Element | null;
  globalContextValues: JSX.Element | null;
  breadcrumbRoutes?: () => JSX.Element | null;
  hideGlobalContext?: boolean;
}

const Page = ({
  children,
  className = '',
  disableBreadcrumbs = false,
  globalContextDropdowns = null,
  globalContextValues = null,
  breadcrumbRoutes: BreadcrumbRoutes = () => null,
  hideGlobalContext,
}: PageProps) => (
  <div className={`page-with-grid ${className}`}>
    <div className="container-fluid overflow-auto">
      {disableBreadcrumbs ? null : (
        <div className="row">
          <div className="col">
            <Breadcrumb>
              <BreadcrumbRoutes />
            </Breadcrumb>
          </div>
        </div>
      )}

      {globalContextValues && !hideGlobalContext && (
        <div className="row">
          <div className="col">
            <div className="global-context">
              <div className="global-context-values">{globalContextValues}</div>
            </div>
          </div>
        </div>
      )}
      {children}
      {globalContextDropdowns}
    </div>
  </div>
);

export default Page;
