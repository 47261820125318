import { excludeFromFilteringColumnProperty } from '@tls/ui-aggrid';
import {
  ValueFormatterParams,
  CellClassParams,
  CellClassRules,
  Column,
  ColumnMenuTab,
  IAggFuncParams,
  ColDef,
} from 'ag-grid-community';

import { currencyCellFormatter, percentageCellFormatter } from '../formatters';

import { EditableCheckboxCellRenderer } from './cellRenderers';
import RichSelectOptionRenderer from './cellRenderers/richSelectOptionRenderer.component';
import { NodeLevel } from './constants';

export const editableCellClassRules: CellClassRules = {
  'editable-cell': (props: CellClassParams) =>
    typeof props.colDef.editable === 'function'
      ? props.colDef.editable({
          ...props,
          column: new Column(props.colDef, props.colDef, props.colDef.colId || '', false),
        })
      : Boolean(props.colDef.editable),
  'selection-cell': (props: CellClassParams) =>
    Boolean((props.colDef as ColDef & { isSelectionList: boolean }).isSelectionList),
};

export const adminColumnSharedFields = {
  headerName: '',
  minWidth: 30,
  maxWidth: 85,
  ...excludeFromFilteringColumnProperty,
};

export const currencyColumnDefinition = {
  valueFormatter: currencyCellFormatter,
  cellStyle: {
    textAlign: 'right',
  },
  maxWidth: 130,
  minWidth: 30,
};

export const adminColumnDefinition = {
  headerName: '',
  minWidth: 30,
  maxWidth: 75,
  width: 75,
  suppressMenu: true,
  suppressColumnsToolPanel: true,
  sortable: false,
};

export const percentageColumnDefinition = {
  valueFormatter: (params: ValueFormatterParams) =>
    params?.data?.isTotalRow ? '' : percentageCellFormatter(params),
  cellStyle: {
    textAlign: 'right',
  },
};

export const booleanColumnDefinition = {
  width: 50,
  minWidth: 30,
  valueFormatter: ({ value, data }: ValueFormatterParams) => {
    if (data.isTotalRow) {
      return '';
    }

    return value ? 'YES' : 'NO';
  },
};

export const yesNoOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const mapValueFormatterFactory = (
  options?: { value: string | number | null; label: string }[],
) => ({ value }: { value: string | number | null }) => {
  const option = options?.find(option => option.value === value);
  return option?.label || '';
};

export const selectCellEditorParamsFactory = (
  options?: { value: unknown; label: string }[],
  customProperties = {},
  isClearable = false,
) => {
  const allSelectOptions = [
    ...(isClearable ? [{ label: 'Clear selected value', value: null }] : []),
    ...(options ? options : []),
  ];
  const values = allSelectOptions.map(option => option.value);
  const formatValue = (value: unknown) => {
    const option = allSelectOptions.find(option => option.value === value);
    return option && option.label;
  };

  return () => ({
    cellHeight: 30,
    values,
    formatValue,
    cellRenderer: RichSelectOptionRenderer,
    ...customProperties,
  });
};

// As of our current version of ag-grid (23.2.1):
// "The flex config does not work with a width config in same column. If you need to provide a minimum
// width for a column, you should use flex and the minWidth config. Flex will also take maxWidth into account."
// https://www.ag-grid.com/react-data-grid/column-sizing/#column-flex
//
// So when we want to make use of 'flex' for "default" column properties, we need to use 'defaultColumnDefinitionWithoutWidth'
export const defaultColumnDefinitionWithoutWidth = {
  suppressMenu: true,
  cellStyle: {
    textAlign: 'left',
  },
  cellClassRules: { ...editableCellClassRules },
  resizable: true,
};

export const defaultColumnDefinition = {
  ...defaultColumnDefinitionWithoutWidth,
  width: 150,
  minWidth: 30,
};

export const defaultNoDataColumn = {
  ...defaultColumnDefinition,
  headerName: 'No data',
  width: 600,
};

export const defaultColumnDefinitionWithFilter = {
  ...defaultColumnDefinition,
  suppressMenu: false,
  filter: true,
  menuTabs: ['filterMenuTab' as ColumnMenuTab],
};

export const defaultLockPinnedColumnDefinitionWithFilter = {
  ...defaultColumnDefinitionWithFilter,
  lockPinned: true,
};

export const defaultColumnDefinitionSuppressMovable = {
  ...defaultColumnDefinition,
  suppressMovable: true,
};

export const defaultColumnDefinitionWithFilterSuppressMovable = {
  ...defaultColumnDefinitionWithFilter,
  suppressMovable: true,
};

export const defaultSelectionColumnDefinitionWithFilterSuppressMovable = {
  ...defaultColumnDefinitionWithFilterSuppressMovable,
  isSelectionList: true,
};

export const getDefaultGroupColumnDefinitionWithFilter = (valueKey: string) => {
  return {
    ...defaultColumnDefinitionWithFilter,
    initialAggFunc: ({ rowNode }: IAggFuncParams) => {
      if (rowNode.group && rowNode.level === NodeLevel.ROOT) {
        const childValue = rowNode.allLeafChildren.find(({ data }) => data?.value)?.data?.[
          valueKey
        ];
        const value = typeof childValue !== 'undefined' ? childValue : null;
        rowNode.setData({ value });
      }
    },
  };
};

export const centeredColumnDefinition = {
  cellStyle: {
    textAlign: 'center',
  },
};

export const defaultColumnDefinitionHidden = {
  ...defaultColumnDefinition,
  hide: true,
};

export const defaultColumnDefinitionWithSuppressedFilter = {
  ...defaultColumnDefinition,
  filterParams: {
    newRowsAction: 'keep',
  },
};

// SLT-10367
// The following column definition is used for columns of editableCheckboxCellRenderer
// cells in grids with the areHeaderCellBordersEnabled property
export const defaultCheckboxColumnDefinition = {
  ...defaultColumnDefinition,
  headerClass: 'no-border',
  suppressColumnsToolPanel: true,
};

export const disabledReadOnlyCheckboxColumnDefinitions = {
  cellRenderer: EditableCheckboxCellRenderer,
  cellRendererParams: {
    disabled: true,
  },
  cellStyle: {
    textAlign: 'center',
  },
};

export const getGtwRefreshFailCountColumn = (fieldName: string) => ({
  ...defaultColumnDefinitionWithFilter,
  ...currencyColumnDefinition,
  valueFormatter: ({ value }: ValueFormatterParams) => value && currencyCellFormatter({ value }),
  headerName: 'Error Count',
  field: fieldName,
  cellStyle: ({ data }: CellClassParams) => {
    if (data[fieldName] >= 3) {
      return { ...currencyColumnDefinition.cellStyle, color: 'red' };
    }
    return currencyColumnDefinition.cellStyle;
  },
});
