import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const DRAGGING_OVER_CLASS_NAME = 'is-dragging-over';
const DRAGGING_CLASS_NAME = 'is-dragging';

class DroppableZone extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  };

  render() {
    const { items, id, className, itemClassName } = this.props;

    return (
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={classNames(className, {
              [DRAGGING_OVER_CLASS_NAME]: snapshot.isDraggingOver,
            })}
          >
            {items.map((item, index) => (
              <Draggable key={item.label} draggableId={item.label} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames(itemClassName, {
                      [DRAGGING_CLASS_NAME]: snapshot.isDragging,
                    })}
                  >
                    {item.label}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

export default DroppableZone;
