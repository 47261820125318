import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DefaultPeriodTypes } from '@common-packages/shared-constants';
import { mapPeriods } from '@tls/state-helpers';

import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';
import { useBottomSlideInEditMode } from '../../../shared/editMode';
import EditFilingDecisions from '../../form/editFilingDecisions.container';
import { entityIdSelector, taxYearSelector, periodSelector } from '../../../shared/store/selectors';
import { fetchFilingStates } from '../../../filingStates/store/actions';
import { fetchEntityFilingDecisions } from '../../store/actions';
import {
  entityFilingDecisionsSelector,
  isFetchingEntityFilingDecisionsSelector,
} from '../../store/selectors';
import { defaultSideBarWithColumnsToolPanel } from '../../../shared/displayComponents/agGrid/constants';
import getColumnBlueprintBasedColumnDefinitions from '../../../shared/columnDefinitions/columnsBlueprint.columnDefinition';
import { columnBlueprintHeaderOptions } from '../../../shared/columnDefinitions/constants';
import columnBlueprintStyles from '../../../shared/styles/columnBlueprintStyles.module.scss';
import { useMutationUpdateOrgProfile } from '../../../shared/mutations/filingDecisions';
import Header from '../../../shared/displayComponents/header/header.component';
import styles from '../../styles.module.scss';

import columnDefinitions from './filingDecisions.columnDefinitions';

const getRowId = params => params?.data?.jurisdictionId;

const FilingDecisions = ({ hasUserPermissionsToEdit }) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const entityId = useSelector(entityIdSelector);
  const isFetchingFilingDecisions = useSelector(isFetchingEntityFilingDecisionsSelector);
  const filingDecisions = useSelector(entityFilingDecisionsSelector);

  const [uniqueStoredGridSelectionId, setUniqueStoredGridSelectionId] = useState(null);

  useEffect(() => {
    if (taxYear && period && entityId) {
      dispatch(fetchEntityFilingDecisions({ taxYear, period, entityId }));
    }
  }, [dispatch, taxYear, period, entityId]);

  const columnDefinitionsWithBlueprints = useMemo(
    () => [
      ...columnDefinitions,
      ...getColumnBlueprintBasedColumnDefinitions({
        columnsBlueprint: filingDecisions?.columnsBlueprint || [],
      }),
    ],
    [filingDecisions],
  );

  const {
    mutateAsync: updateOrgProfile,
    isLoading: isUpdatingOrgProfile,
  } = useMutationUpdateOrgProfile();

  const isLoading = isFetchingFilingDecisions || isUpdatingOrgProfile;

  const onSave = useCallback(
    async ({ values }) => {
      await updateOrgProfile({
        values: {
          ...values,
          ...DefaultPeriodTypes,
          [mapPeriods(period)]: true,
          // in most cases we don't need to set the period but there are some filing attributes that
          // don't have any period set and this will let them "heal" - as of now, it happens for
          // new entities
        },
      });
      dispatch(fetchEntityFilingDecisions({ taxYear, period, entityId }));
      dispatch(fetchFilingStates({ taxYear, period, entityId }));
      setUniqueStoredGridSelectionId(getRowId({ data: values }));
    },
    [dispatch, updateOrgProfile, entityId, period, taxYear],
  );

  const formToRender = useCallback(
    ({ values: { jurisdictionId, entityId }, title, onSave }) => {
      const rowContextValues = {
        jurisdictionId,
        entityId,
      };
      return (
        <EditFilingDecisions
          rowContextValues={rowContextValues}
          title={title}
          saveFilingDecisions={onSave}
          isSavingFilingDecisions={isLoading}
          columnsBlueprint={filingDecisions.columnsBlueprint || []}
        />
      );
    },
    [filingDecisions, isLoading],
  );

  const { gridApi, agGridProps, bottomSlideInWithForm } = useBottomSlideInEditMode({
    getRowId,
    onSave,
    getTitle: ({ jurisdiction, filingGroupsIds }) =>
      `${jurisdiction}${filingGroupsIds ? ` - ${filingGroupsIds}` : ''}`,
    formToRender,
    shouldCloseOnSave: false,
    renderBottomSlideIn: !isLoading,
    isCompactIconSticky: true,
  });

  useEffect(() => {
    if (bottomSlideInWithForm && uniqueStoredGridSelectionId && gridApi) {
      const node = gridApi.getRowNode(uniqueStoredGridSelectionId);
      node?.setSelected(true);
      if (node) {
        setUniqueStoredGridSelectionId(null);
      }
    }
  }, [bottomSlideInWithForm, uniqueStoredGridSelectionId, gridApi]);

  useEffect(() => {
    if (gridApi) {
      setUniqueStoredGridSelectionId(null);
      gridApi.deselectAll();
    }
  }, [taxYear, entityId, period, gridApi]);

  return (
    <>
      <Header
        title="Filing Decisions"
        description="Compare and configure all separate return settings associated with this entity."
      />
      <div
        className={`row grid-row ${styles.gridContainer} ${columnBlueprintStyles.gridContainer}`}
      >
        <div className="col">
          <AgGrid
            columnDefs={columnDefinitionsWithBlueprints}
            isGridLoading={isLoading}
            rowData={filingDecisions.data}
            withSearchBar
            singleClickEdit
            sideBar={defaultSideBarWithColumnsToolPanel}
            {...columnBlueprintHeaderOptions}
            {...agGridProps}
          />
        </div>
      </div>
      {hasUserPermissionsToEdit && bottomSlideInWithForm}
    </>
  );
};

FilingDecisions.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default FilingDecisions;
