import React, { useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react';
import { Routes } from '@common-packages/routes-definitions';

import { clientIdSelector } from '../../shared/store/context';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { columnBlueprintHeaderOptions } from '../../shared/columnDefinitions/constants';
import columnBlueprintStyles from '../../shared/styles/columnBlueprintStyles.module.scss';
import { fetchForms, selectForm } from '../store/actions';
import {
  taxYearSelector,
  jurisdictionIdSelector,
  formsSelector,
  isFetchingFormsSelector,
  formsColumnsBlueprintSelector,
} from '../store/selectors';
import useModal from '../../shared/hooks/useModal.hook';

import getColumnDefinitions from './taxForms.columnDefinitions';
import { profileIds } from './constants';
import PdfAttachmentModal from './pdfAttachmentModal/pdfAttachmentModal.container';
import { deleteTaxForm } from './store/actions';

const TaxForms = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const clientId = useSelector(clientIdSelector);

  const forms = useSelector(formsSelector);
  const isFetchingForms = useSelector(isFetchingFormsSelector);
  const formsColumnsBlueprint = useSelector(formsColumnsBlueprintSelector);

  const isContextReady = taxYear && jurisdictionId;
  const isGridLoading = !isContextReady || isFetchingForms;

  const { showModal, modalProps } = useModal();

  const fetchGridData = useCallback(() => {
    if (isContextReady) {
      dispatch(fetchForms({ taxYear, jurisdictionId }));
    }
  }, [dispatch, isContextReady, jurisdictionId, taxYear]);

  useEffect(() => {
    fetchGridData();
  }, [fetchGridData]);

  const openModalForDelete = useCallback(
    row => {
      dispatch(
        showConfirmModal({
          title: 'Delete form',
          text:
            'Warning, you are about to remove this form, all related fields, all related form mappings and the form image. Note: this action does not delete SWP data. Are you sure you wish to continue?',
          confirmText: 'Yes',
          dismissText: 'No',
          confirmCallback: async () => {
            await dispatch(
              deleteTaxForm({
                taxYear,
                jurisdictionId,
                formId: row.formId,
                profileId: row.returnTypePartnership ? profileIds.PSHP : profileIds.DEFAULT,
              }),
            );
            fetchGridData();
          },
        }),
      );
    },
    [dispatch, fetchGridData, taxYear, jurisdictionId],
  );

  const openAttachmentModal = useCallback(
    row => {
      showModal({
        ...row,
        jurisdictionId,
        taxYear: Number(taxYear),
      });
    },
    [showModal, jurisdictionId, taxYear],
  );

  const onFormSelect = useCallback(
    formId => {
      dispatch(selectForm(formId));
    },
    [dispatch],
  );

  const onAddTaxFormClick = useCallback(() => {
    openAttachmentModal();
  }, [openAttachmentModal]);

  const renderAddButton = useCallback(
    () => (
      <Button size="lg" className="add-button" onClick={onAddTaxFormClick}>
        Add Tax Form
      </Button>
    ),
    [onAddTaxFormClick],
  );

  const navigateToFormDetails = useCallback(
    formId => {
      dispatch(selectForm(formId));
      history.push(Routes.developmentTaxFormEditV2.MAIN);
    },
    [dispatch, history],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onDeleteIconClick: openModalForDelete,
        onAttachmentIconClick: openAttachmentModal,
        onFormSelect,
        taxYear,
        jurisdictionId,
        columnsBlueprint: formsColumnsBlueprint,
        clientId,
      }),
    [
      openModalForDelete,
      openAttachmentModal,
      onFormSelect,
      taxYear,
      jurisdictionId,
      formsColumnsBlueprint,
      clientId,
    ],
  );

  return (
    <>
      <HeaderWithParamDropdownsWrapper
        showHeaderTaxYearDropdown
        showHeaderJurisdictionDropdown
        renderAdditionalElements={renderAddButton}
      />
      <div className={`row grid-row ${columnBlueprintStyles.gridContainer}`}>
        <div className="col">
          <AgGrid
            rowData={forms}
            columnDefs={columnDefinitions}
            isGridLoading={isGridLoading}
            withSearchBar
            sideBar={defaultSideBarWithColumnsToolPanel}
            {...columnBlueprintHeaderOptions}
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
      {modalProps.visible && (
        <PdfAttachmentModal {...modalProps} onSubmit={navigateToFormDetails} />
      )}
    </>
  );
};

export default TaxForms;
