import { httpGetAndParse } from '../../utils/fetchService';

export const fetchNonFilingStates = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/non-filing-states/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Fetching non filing states failed',
  });

export const checkIfNotesExist = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/prepare-review-notes/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Checking notes failed',
  });

export const checkIfPartnershipInformationExist = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/review-partnership-information/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Checking partnership information failed',
  });

export const getFederalDetailsLink = () =>
  httpGetAndParse({
    route: '/api/entities/supporting-federal-details',
    errorMessage: 'Getting federal details link failed',
  });

export const checkIsPartnershipType = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/check-is-partnership-type/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Checking entity type failed',
  });

export const fetchEntitiesData = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/entities/data/${taxYear}/${period}`,
    errorMessage: 'Fetching entities data failed',
  });

export const checkIfLegalEntityExist = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/entities/exists/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Checking legal entity failed',
  });
