import { combineReducers } from 'redux';

import multDataUploadReducer from '../multDataUpload/store/reducer';
import informationalDataUploadReducer from '../informationalDataUpload/store/reducer';
import genericAccountDataUploadReducer from '../genericAccountDataUpload/store/reducer';
import attachmentsReducer from '../attachments/store/reducer';

import toolsReducer from './tools.reducer';

export default combineReducers({
  root: toolsReducer,
  genericAccountDataUpload: genericAccountDataUploadReducer,
  multDataUpload: multDataUploadReducer,
  informationalDataUpload: informationalDataUploadReducer,
  attachments: attachmentsReducer,
});
