import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from '@pwc/appkit-react';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import useReportData from '../../shared/reports/useReportData.hook';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import { globalContextSelector } from '../../shared/store/selectors';
import styles from '../../shared/reports/reportParam.module.scss';
import getReportUrl from '../shared/getReportUrl';

import columnDefinitions from './populationAnalysis.columnDefinitions';

const TABS_TYPES = {
  INCLUDED: 'INCLUDED',
  EXCLUDED: 'EXCLUDED',
};

const tabsDefinitions = [
  { type: TABS_TYPES.INCLUDED, label: 'Included' },
  { type: TABS_TYPES.EXCLUDED, label: 'Excluded' },
];

const inReportEndpointBasePath = getReportUrl('population-analysis-in');
const outReportEndpointBasePath = getReportUrl('population-analysis-out');

const PopulationAnalysis = () => {
  const { jurisdictionId } = useSelector(globalContextSelector);

  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const handleTabChange = useCallback((_, tab) => setActiveTab(tab), []);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({
    endpointBasePath: inReportEndpointBasePath,
  });

  const jurisdictionIdParam = useReportParam({
    endpointBasePath: inReportEndpointBasePath,
    paramName: 'jurisdictionId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [periodParam.paramValue, taxYearParam.paramValue],
    ),
    defaultParamValue: jurisdictionId,
  });

  const consolidatedGroupIdParam = useReportParam({
    endpointBasePath: inReportEndpointBasePath,
    paramName: 'consolidatedGroupId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        jurisdictionId: jurisdictionIdParam.paramValue,
      }),
      [jurisdictionIdParam.paramValue, periodParam.paramValue, taxYearParam.paramValue],
    ),
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([
    taxYearParam,
    periodParam,
    jurisdictionIdParam,
    consolidatedGroupIdParam,
  ]);

  const inReport = useReportData({
    endpointBasePath: inReportEndpointBasePath,
    method: 'GET',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        jurisdictionId: jurisdictionIdParam.paramValue,
        consolidatedGroupId: consolidatedGroupIdParam.paramValue,
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        jurisdictionIdParam.paramValue,
        consolidatedGroupIdParam.paramValue,
      ],
    ),
  });

  const outReport = useReportData({
    endpointBasePath: outReportEndpointBasePath,
    method: 'GET',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        jurisdictionId: jurisdictionIdParam.paramValue,
        consolidatedGroupId: consolidatedGroupIdParam.paramValue,
      }),
      [
        taxYearParam.paramValue,
        periodParam.paramValue,
        jurisdictionIdParam.paramValue,
        consolidatedGroupIdParam.paramValue,
      ],
    ),
  });

  const runReports = useCallback(() => {
    inReport.fetchData();
    outReport.fetchData();
  }, [inReport, outReport]);

  const params = {
    taxYear: taxYearParam.paramValue,
    period: periodParam.paramValue,
    jurisdictionId: jurisdictionIdParam.paramValue,
    consolidatedGroupId: consolidatedGroupIdParam.paramValue,
  };
  const areParamsReady =
    !isFetchingAnyParam &&
    Object.values(params).every(
      paramValue =>
        typeof paramValue !== 'undefined' && (!Array.isArray(paramValue) || paramValue.length > 0),
    );

  const { props, onGridReady } = useActionMenuForExport({
    reportData: activeTab === TABS_TYPES.INCLUDED ? inReport.reportData : outReport.reportData,
  });

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown label="Jurisdiction" param={jurisdictionIdParam} />
          <ReportParamDropdown label="Consolidated Group" param={consolidatedGroupIdParam} />
          <Button
            size="lg"
            onClick={runReports}
            className={styles.runReportBtn}
            disabled={
              inReport.isFetchingReportData || outReport.isFetchingReportData || !areParamsReady
            }
          >
            Run Report
          </Button>
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="navigation-tabs-wrapper">
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
            {tabsDefinitions.map(({ label, type }, index) => (
              <Tab id={type} value={type} label={label} key={index} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          {activeTab === TABS_TYPES.INCLUDED && inReport.showGrid && (
            <AgGrid
              rowData={inReport.reportData}
              columnDefs={columnDefinitions}
              isGridLoading={inReport.isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
          {activeTab === TABS_TYPES.EXCLUDED && outReport.showGrid && (
            <AgGrid
              rowData={outReport.reportData}
              columnDefs={columnDefinitions}
              isGridLoading={outReport.isFetchingReportData}
              onGridReady={onGridReady}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PopulationAnalysis;
