import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import Modal from '@tls/ui-modal';
import { PeriodTypeFilingAttributesValues } from '@common-packages/shared-constants';

import {
  taxYearSelector,
  periodSelector,
  consolidationsForValidationSelector,
} from '../../shared/store/selectors';
import useFetch from '../../shared/hooks/useFetch.hook';
import { FilingMethods } from '../../shared/enums';
import {
  BASE_CONSOLIDATION_FILING_ATTRIBUTES_VALUE,
  FilingMethodsMap,
} from '../../shared/consolidations/constants';

import {
  fetchFilingGroups as fetchFilingGroupsApiAction,
  fetchDefaultConsolidationMethod as fetchDefaultConsolidationMethodApiAction,
} from './store/api';
import AddConsolidationForm from './addConsolidationForm.component';

const initialValues = {
  filingAttributes: BASE_CONSOLIDATION_FILING_ATTRIBUTES_VALUE,
  consolidationId: '',
  parentOrgId: '',
  description: '',
  jurisdictionId: '',
  consolidationMethod: '',
  proformaFilingGroupId: '',
  active: true,
  allowInterCompany: true,
  returnDefinitionId: '',
  filingMethod: FilingMethods.NONE,
};

const AddConsolidation = ({
  hideModal,
  visible,
  jurisdictionsOptions,
  isFetchingJurisdictionsOptions,
  onSaveConsolidation,
  isSavingConsolidation,
}) => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const [formErrors, setFormErrors] = useState(null);

  const consolidationIdsForValidation = useSelector(consolidationsForValidationSelector);

  const {
    data: filingGroups,
    fetch: fetchFilingGroups,
    isFetching: isFetchingFilingGroups,
  } = useFetch({ action: fetchFilingGroupsApiAction });

  const {
    fetch: fetchDefaultConsolidationMethod,
    isFetching: isFetchingDefaultConsolidationMethod,
  } = useFetch({ action: fetchDefaultConsolidationMethodApiAction });

  const filingGroupsOptions = useMemo(
    () =>
      filingGroups
        ? filingGroups.map(({ filingGroupId }) => ({
            label: filingGroupId,
            value: filingGroupId,
          }))
        : [],
    [filingGroups],
  );

  const handleParentEntityChange = useCallback(
    parentEntity => {
      if (parentEntity) {
        fetchFilingGroups({
          taxYear,
          period,
          parentEntityId: parentEntity.value,
        });
      }
    },
    [fetchFilingGroups, taxYear, period],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        className="appkit-modal-without-footer"
        title="Add Consolidation"
        closeAction={hideModal}
        visible={visible}
      >
        <AddConsolidationForm
          {...formikProps}
          consolidationIdsForValidation={consolidationIdsForValidation}
          jurisdictionsOptions={jurisdictionsOptions}
          isFetchingJurisdictions={isFetchingJurisdictionsOptions}
          filingGroups={filingGroupsOptions}
          isFetchingFilingGroups={isFetchingFilingGroups}
          isSavingConsolidation={isSavingConsolidation}
          onCancelClick={hideModal}
          fetchDefaultConsolidationMethod={fetchDefaultConsolidationMethod}
          isFetchingDefaultConsolidationMethod={isFetchingDefaultConsolidationMethod}
          onParentEntityChange={handleParentEntityChange}
          formErrors={formErrors}
        />
      </Modal>
    ),
    [
      handleParentEntityChange,
      hideModal,
      consolidationIdsForValidation,
      visible,
      jurisdictionsOptions,
      isFetchingJurisdictionsOptions,
      filingGroupsOptions,
      isFetchingFilingGroups,
      isSavingConsolidation,
      fetchDefaultConsolidationMethod,
      isFetchingDefaultConsolidationMethod,
      formErrors,
    ],
  );

  const save = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      setFormErrors(null);

      try {
        await onSaveConsolidation({
          ...values,
          filingAttributes: values.filingAttributes + PeriodTypeFilingAttributesValues[period],
          filingMethod: FilingMethodsMap[values.filingMethod],
        });

        resetForm();
        hideModal();
      } catch (e) {
        setFormErrors(e.details || null);
      } finally {
        setSubmitting(false);
      }
    },
    [onSaveConsolidation, hideModal, period],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={save}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {renderForm}
    </Formik>
  );
};

AddConsolidation.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  jurisdictionsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      jurisdictionId: PropTypes.string,
      jurisdictionDescription: PropTypes.string,
    }),
  ),
  isFetchingJurisdictionsOptions: PropTypes.bool.isRequired,
  onSaveConsolidation: PropTypes.func.isRequired,
  isSavingConsolidation: PropTypes.bool.isRequired,
};

export default AddConsolidation;
