import { httpGetAndParse, httpPut, httpPost } from '../../../utils/fetchService';

export const fetchCalcSpecsOptions = ({ taxYear, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/development/calc-specs/options/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Fetching calc specs options failed',
  });

export const fetchDatasetItemDefinitionOptions = ({ taxYear, jurisdictionId, datasetName }) =>
  httpGetAndParse({
    route: `/api/development/calc-specs/data-item-definitions/${taxYear}/${jurisdictionId}/${datasetName}`,
    errorMessage: 'Fetching dataset item definition options failed',
  });

export const fetchCategories = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/development/calc-specs/categories/${taxYear}`,
    errorMessage: 'Fetching categories failed',
  });

export const updateBaseCalcSpec = ({ values, taxYear, period, jurisdictionId }) =>
  httpPut({
    route: `/api/development/calc-specs/base/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Updating base calc spec failed',
    body: values,
  });

export const createBaseCalcSpec = ({ values, taxYear, jurisdictionId }) =>
  httpPost({
    route: `/api/development/calc-specs/base/${taxYear}/${jurisdictionId}`,
    errorMessage: 'Creating base calc spec failed',
    body: values,
  });

export const updateConsolCalcSpecs = ({ values, taxYear, period, jurisdictionId }) =>
  httpPut({
    route: `/api/development/calc-specs/consol/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Updating consol calc spec failed',
    body: values,
  });

export const createConsolCalcSpecs = ({ values, taxYear, period, jurisdictionId }) =>
  httpPost({
    route: `/api/development/calc-specs/consol/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Creating consol calc spec failed',
    body: values,
  });

export const fetchK1CalcSpecs = ({ taxYear, period, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/development/calc-specs/k1/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Fetching K1 calc specs failed',
  });

export const fetchK1CalcSpecsOptions = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/development/calc-specs/k1/options/${taxYear}/${period}`,
  });

export const updateK1CalcSpecs = ({ values, taxYear, period, jurisdictionId }) =>
  httpPut({
    route: `/api/development/calc-specs/k1/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Updating K1 calc specs failed',
    body: values,
  });

export const createK1CalcSpecs = ({ values, taxYear, period, jurisdictionId }) =>
  httpPost({
    route: `/api/development/calc-specs/k1/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Creating K1 calc specs failed',
    body: values,
  });

export const createConspbCalcSpecs = ({ values, taxYear, period, jurisdictionId }) =>
  httpPost({
    route: `/api/development/calc-specs/conspb/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Creating conspb calc specs failed',
    body: values,
  });

export const updateConspbCalcSpecs = ({ values, taxYear, period, jurisdictionId }) =>
  httpPut({
    route: `/api/development/calc-specs/conspb/${taxYear}/${period}/${jurisdictionId}`,
    errorMessage: 'Updating conspb calc specs failed',
    body: values,
  });
