import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import useFetch from '../../shared/hooks/useFetch.hook';
import LoadingSpinner from '../../shared/forms/loadingSpinner/loadingSpinner.component';
import SelectContextDataInfo from '../../shared/displayComponents/selectContextDataInfo/selectContextDataInfo.component';
import globalContextPropTypes from '../../shared/propTypes/globalContext';
import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
} from '../../shared/store/selectors';

import { fetchTtiYearIdAction } from './api';
import ProformaSteps from './proformaSteps.container';
import CaseListDropdown from './caseListDropdown.component';
import ReturnBinderListDropdown from './returnBinderListDropdown.container';
import styles from './styles.module.scss';

const ProformaProcess = ({ globalContext, isFetchingGlobalContext, hasUserPermissionsToEdit }) => {
  const [isFetchingCaseList, setIsFetchingCaseList] = useState(false);
  const [selectedTtiCaseId, selectTtiCaseId] = useState(null);
  const [selectedReturnBinderId, selectReturnBinderId] = useState(null);

  const { data: ttiYearId, isFetching: isFetchingTtiYearId, fetch: fetchTtiYearId } = useFetch({
    action: fetchTtiYearIdAction,
  });

  useEffect(() => {
    if (globalContext.taxYear && globalContext.period) {
      fetchTtiYearId({
        taxYear: globalContext.taxYear,
        period: globalContext.period,
      });
    }
  }, [fetchTtiYearId, globalContext.taxYear, globalContext.period]);

  const onTtiCaseIdChange = useCallback(
    ttiCaseId => {
      selectReturnBinderId(null);
      selectTtiCaseId(ttiCaseId);
    },
    [selectTtiCaseId, selectReturnBinderId],
  );

  const isLoading = isFetchingGlobalContext || isFetchingTtiYearId;

  if (!isLoading && !globalContext.isReady) {
    return <SelectContextDataInfo />;
  }

  return (
    <div className="row">
      <div className="col">
        <LoadingSpinner isLoading={isFetchingGlobalContext} />
        <div className={styles.whiteBox}>
          <div className={`${styles.spaceBetween} ${styles.align}`}>
            <div className={styles.returnContext}>
              <CaseListDropdown
                value={selectedTtiCaseId}
                onSelect={onTtiCaseIdChange}
                setIsFetching={setIsFetchingCaseList}
              />
              <ReturnBinderListDropdown
                value={selectedReturnBinderId}
                onSelect={selectReturnBinderId}
                ttiYearId={ttiYearId}
                caseId={selectedTtiCaseId}
                isFetchingCaseList={isFetchingCaseList}
              />
            </div>
          </div>
          <ProformaSteps
            ttiYearId={ttiYearId}
            selectedTtiCaseId={selectedTtiCaseId}
            selectedReturnBinderId={selectedReturnBinderId}
            hasUserPermissionsToEdit={hasUserPermissionsToEdit}
          />
        </div>
      </div>
    </div>
  );
};

ProformaProcess.propTypes = {
  globalContext: globalContextPropTypes,
  isFetchingGlobalContext: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(state => ({
  globalContext: globalContextSelector(state),
  isFetchingGlobalContext: isFetchingGlobalContextSelector(state),
}))(ProformaProcess);
