import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { useBottomSlideInEditMode } from '../../shared/editMode';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import {
  taxYearSelector,
  periodSelector,
  jurisdictionIdSelector,
  consolidationIdSelector,
} from '../../shared/store/selectors';
import getColumnBlueprintBasedColumnDefinitions from '../../shared/columnDefinitions/columnsBlueprint.columnDefinition';
import { columnBlueprintHeaderOptions } from '../../shared/columnDefinitions/constants';
import columnBlueprintStyles from '../../shared/styles/columnBlueprintStyles.module.scss';
import { useMutationUpdateOrgProfile } from '../../shared/mutations/filingDecisions';
import { useQueryConsolidationFilingDecisions } from '../../shared/queries/filingDecisions';
import styles from '../styles.module.scss';
import EditFilingDecisions from '../form/editFilingDecisions.container';
import { CATEGORY_NAMES } from '../../shared/enums/filingDecisions';
import Header from '../../shared/displayComponents/header/header.component';

import getColumnDefinitions from './filingDecisions.columnDefinitions';

const getRowId = params => params?.data?.orgId || params?.data?.entityId;

const FilingDecisions = ({ hasUserPermissionsToEdit }) => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const consolidationId = useSelector(consolidationIdSelector);

  const isContextReady = Boolean(taxYear && period && jurisdictionId && consolidationId);

  const {
    data: filingDecisions,
    isFetching: isFetchingFilingDecisions,
  } = useQueryConsolidationFilingDecisions({
    params: {
      taxYear,
      period,
      jurisdictionId,
      businessEntityId: consolidationId,
    },
    enabled: isContextReady,
  });

  const {
    mutateAsync: updateOrgProfile,
    isLoading: isUpdatingOrgProfile,
  } = useMutationUpdateOrgProfile();

  const isLoading = isFetchingFilingDecisions || isUpdatingOrgProfile || !isContextReady;

  const [uniqueStoredGridSelectionId, setUniqueStoredGridSelectionId] = useState(null);

  const columnDefinitions = useMemo(
    () => [
      ...getColumnDefinitions({ taxYear, period }),
      ...getColumnBlueprintBasedColumnDefinitions({
        columnsBlueprint: filingDecisions?.columnsBlueprint || [],
      }),
    ],
    [filingDecisions, taxYear, period],
  );

  const formToRender = useCallback(
    ({ values: { jurisdictionId, orgId }, title, onSave }) => {
      const rowContextValues = {
        jurisdictionId,
        entityId: orgId,
      };
      return (
        <EditFilingDecisions
          rowContextValues={rowContextValues}
          title={title}
          saveFilingDecisions={onSave}
          isSavingFilingDecisions={isLoading}
          columnsBlueprint={filingDecisions?.columnsBlueprint || []}
          isConsolidated
        />
      );
    },
    [filingDecisions, isLoading],
  );

  const onSave = useCallback(
    async ({ values }) => {
      await updateOrgProfile({
        values: omit(values, ['orgName', ...CATEGORY_NAMES.map(({ field }) => field)]),
      });
      setUniqueStoredGridSelectionId(getRowId({ data: values }));
    },
    [updateOrgProfile],
  );

  const { gridApi, agGridProps, bottomSlideInWithForm } = useBottomSlideInEditMode({
    getRowId,
    onSave,
    getTitle: ({ orgName }) => orgName,
    formToRender,
    shouldCloseOnSave: false,
    renderBottomSlideIn: !isLoading,
    isCompactIconSticky: true,
  });

  useEffect(() => {
    if (bottomSlideInWithForm && uniqueStoredGridSelectionId && gridApi) {
      const node = gridApi.getRowNode(uniqueStoredGridSelectionId);
      node?.setSelected(true);
      if (node) {
        setUniqueStoredGridSelectionId(null);
      }
    }
  }, [bottomSlideInWithForm, uniqueStoredGridSelectionId, gridApi]);

  useEffect(() => {
    if (gridApi) {
      setUniqueStoredGridSelectionId(null);
      gridApi.deselectAll();
    }
  }, [taxYear, jurisdictionId, period, consolidationId, gridApi]);

  return (
    <>
      <Header
        title="Filing Decisions"
        description="View and configure filing group members associated with a consolidation."
      />
      <div
        className={`row grid-row ${styles.gridContainer} ${columnBlueprintStyles.gridContainer}`}
      >
        <div className="col">
          <AgGrid
            rowData={filingDecisions?.data}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            sideBar={defaultSideBarWithColumnsToolPanel}
            withSearchBar
            {...columnBlueprintHeaderOptions}
            {...agGridProps}
          />
        </div>
      </div>
      {hasUserPermissionsToEdit && bottomSlideInWithForm}
    </>
  );
};

FilingDecisions.propTypes = {
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default FilingDecisions;
