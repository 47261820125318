import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';

import { QueryKeys } from '../../shared/queries';
import {
  entityIdSelector,
  periodSelector,
  taxYearSelector,
  jurisdictionIdSelector,
} from '../../shared/store/selectors';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import { useRowEditMode } from '../../shared/editMode';
import { dataTypes, TAX_YEAR_ENDING_INFO_ACCOUNT_ID } from '../../shared/constants';
import { isEntityPartnershipTypeSelector } from '../store/selectors';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { SelectOptionPropTypes } from '../../shared/forms/propTypes';
import DataInfo from '../../shared/displayComponents/dataInfo/dataInfo.component';

import {
  fetchGlobalInformation,
  updateGlobalInformation,
  fetchAllPartnerships,
  selectPartnershipId,
  fetchPartnershipInformation,
  updatePartnershipInformation,
} from './store/actions';
import {
  globalInformationSelector,
  isFetchingGlobalInformationSelector,
  isUpdatingGlobalInformationSelector,
  partnershipsOptionsSelector,
  partnershipIdSelector,
  isFetchingPartnershipsSelector,
} from './store/selectors';
import { globalInformationType } from './propTypes';
import getColumnDefinitions from './entityInformation.columnDefinitions';
import hasTaxYearEnding from './utils/hasTaxYearEnding';

const getUniqueRowId = ({ data: { infoAccountId } }) => infoAccountId;

const valueFormatter = (dataType, value) => {
  if (dataType === dataTypes.CHECKBOX) {
    return value;
  }
  if (dataType === dataTypes.NUMBER) {
    return Number.parseFloat(value);
  }
  return value || null;
};

const EntityInformation = ({
  taxYear,
  period,
  entityId,
  jurisdictionId,
  fetchGlobalInformation,
  globalInformation,
  isFetchingGlobalInformation,
  updateGlobalInformation,
  isUpdatingGlobalInformation,

  isPartnershipType,
  fetchAllPartnerships,
  partnershipOptions,
  partnershipId,
  isFetchingPartnerships,
  selectPartnershipId,
  fetchPartnershipInformation,
  updatePartnershipInformation,
  hasUserPermissionsToEdit,
}) => {
  const queryClient = useQueryClient();
  const isContextReady = taxYear && period && entityId;

  useEffect(() => {
    if (isContextReady && isPartnershipType) {
      fetchAllPartnerships({ taxYear, period, entityId });
    }
  }, [fetchAllPartnerships, taxYear, period, entityId, isPartnershipType, isContextReady]);

  useEffect(() => {
    if (!isContextReady) {
      return;
    }
    if (isPartnershipType && partnershipId) {
      fetchPartnershipInformation({ taxYear, period, entityId, partnershipId });
      return;
    }
    if (!isPartnershipType) {
      fetchGlobalInformation({ taxYear, period, entityId });
    }
  }, [
    fetchGlobalInformation,
    entityId,
    fetchPartnershipInformation,
    isContextReady,
    isPartnershipType,
    partnershipId,
    period,
    taxYear,
  ]);

  const updateMethod = isPartnershipType ? updatePartnershipInformation : updateGlobalInformation;

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      if (!rowsPairsWithChanges.length) {
        return;
      }

      const rowsToUpdate = rowsPairsWithChanges.map(({ oldRow, newRow }) => ({
        taxYear: oldRow.taxYear,
        period: oldRow.period,
        infoAccountId: oldRow.infoAccountId,
        entityId: oldRow.entityId,
        accountJurisdictionId: oldRow.accountJurisdictionId,
        partnershipId: isPartnershipType ? oldRow.partnerId : null,
        dataType: oldRow.dataType,
        value: valueFormatter(oldRow.dataType, newRow.value),
      }));
      await updateMethod({
        rowsToUpdate,
        taxYear,
        jurisdictionId,
        period,
        entityId,
      });
      isPartnershipType
        ? fetchPartnershipInformation({ taxYear, period, entityId, partnershipId })
        : fetchGlobalInformation({ taxYear, period, entityId });
      queryClient.resetQueries([
        QueryKeys.ConsolidationFilingEntities.EntityFiscalYearEnd,
        { taxYear, period, businessEntityId: entityId || '' },
      ]);
    },
    [
      isPartnershipType,
      updateMethod,
      fetchPartnershipInformation,
      taxYear,
      period,
      entityId,
      jurisdictionId,
      partnershipId,
      fetchGlobalInformation,
      queryClient,
    ],
  );

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: globalInformation,
    getUniqueRowId,
    editButtonDisabled:
      isFetchingGlobalInformation || isUpdatingGlobalInformation || !globalInformation.length,
    saveButtonDisabled: hasTaxYearEnding,
    isFetchAllRows: true,
  });

  const columnDefinitions = useMemo(() => getColumnDefinitions({ isInEditMode, updateRow }), [
    isInEditMode,
    updateRow,
  ]);

  const changePartnershipId = useCallback(({ value }) => selectPartnershipId(value), [
    selectPartnershipId,
  ]);

  return (
    <>
      {navigationPrompt}
      {isPartnershipType && (
        <div className="row">
          <div className="col-4">
            <SDKCustomSelect
              appkitLabel="Owner"
              options={partnershipOptions}
              value={partnershipId}
              isLoading={isFetchingPartnerships}
              onChange={changePartnershipId}
            />
          </div>
        </div>
      )}
      {isPartnershipType && !isFetchingPartnerships && !partnershipOptions.length ? (
        <DataInfo textToRender="No data available." />
      ) : (
        <>
          {hasUserPermissionsToEdit && <div className="add-button-column">{editModeButtons}</div>}
          <div className="row grid-row">
            <div className="col">
              <AgGrid
                rowData={clonedRowData}
                columnDefs={columnDefinitions}
                isGridLoading={
                  isFetchingGlobalInformation ||
                  isUpdatingGlobalInformation ||
                  isFetchingPartnerships
                }
                singleClickEdit
                suppressCellFocus={!isInEditMode}
                onGridReady={onGridReady}
                withSearchBar
                components={{
                  DateCellEditor,
                  NumberCellEditor,
                  TextCellEditor,
                }}
                stopEditingWhenCellsLoseFocus
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

EntityInformation.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  fetchGlobalInformation: PropTypes.func.isRequired,
  isFetchingGlobalInformation: PropTypes.bool.isRequired,
  globalInformation: PropTypes.arrayOf(globalInformationType),
  updateGlobalInformation: PropTypes.func.isRequired,
  isUpdatingGlobalInformation: PropTypes.bool.isRequired,
  isPartnershipType: PropTypes.bool,
  fetchAllPartnerships: PropTypes.func.isRequired,
  partnershipOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  partnershipId: PropTypes.string,
  isFetchingPartnerships: PropTypes.bool.isRequired,
  selectPartnershipId: PropTypes.func.isRequired,
  fetchPartnershipInformation: PropTypes.func.isRequired,
  updatePartnershipInformation: PropTypes.func.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    globalInformation: globalInformationSelector(state),
    isFetchingGlobalInformation: isFetchingGlobalInformationSelector(state),
    isUpdatingGlobalInformation: isUpdatingGlobalInformationSelector(state),
    isPartnershipType: isEntityPartnershipTypeSelector(state),
    partnershipOptions: partnershipsOptionsSelector(state),
    partnershipId: partnershipIdSelector(state),
    isFetchingPartnerships: isFetchingPartnershipsSelector(state),
  }),
  {
    fetchGlobalInformation,
    updateGlobalInformation,
    fetchAllPartnerships,
    selectPartnershipId,
    fetchPartnershipInformation,
    updatePartnershipInformation,
  },
)(EntityInformation);
