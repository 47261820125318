import { httpGetAndParse, httpPut } from '../../../utils/fetchService';

export const fetchGenericScreen = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  accountId,
  filingTypeId,
  consolidationId,
}) =>
  httpGetAndParse({
    route: `/api/shared/generic-screen/${taxYear}/${period}/${orgId}/${jurisdictionId}/${filingTypeId}/${accountId}?consolidationId=${consolidationId}`,
    errorMessage: 'Fetching generic screen failed',
  });

export const fetchGenericScreenHeader = ({ taxYear, period, jurisdictionId, accountId }) =>
  httpGetAndParse({
    route: `/api/shared/generic-screen/find-header/${taxYear}/${period}/${jurisdictionId}/${accountId}`,
    errorMessage: 'Fetching generic screen header failed',
  });

export const updateGenericScreen = ({ taxYear, period, orgId, jurisdictionId, rowsToUpdate }) =>
  httpPut({
    route: `/api/shared/generic-screen-update/${taxYear}/${period}/${orgId}/${jurisdictionId}`,
    errorMessage: 'Updating generic screen failed',
    parseResponseErrorMessage: true,
    body: { rowsToUpdate },
  });
