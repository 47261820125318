import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Modal from '@tls/ui-modal';
import { businessesSchemas } from '@common-packages/validators';

import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';

import { businessesPropTypes } from './propTypes';
import { fetchBusinesses, createBusiness, updateBusiness } from './store/actions';
import AddEditBusinessForm from './addEditBusinessesForm.component';

const getActiveOptions = options => options.filter(({ activeYN }) => activeYN);

const formInitialValues = {
  businessId: '',
  businessDescription: '',
  leadCustomerId: '',
  businessGroup: '',
  tier: null,
  sirRollupBusId: '',
  sirYN: false,
};
const AddEditBusinessesFormContainer = ({
  hideModal,
  visible,

  fetchBusinesses,
  createBusiness,
  updateBusiness,

  businessToEdit,
  businesses,
  businessGroups,
  customers,
}) => {
  const isEditMode = Boolean(businessToEdit);
  const saveBusiness = useCallback(
    async (
      {
        businessId,
        businessDescription,
        leadCustomerId,
        businessGroup,
        tier,
        sirYN,
        sirRollupBusId,
      },
      { resetForm },
    ) => {
      const business = {
        businessId,
        businessDescription,
        leadCustomerId,
        businessGroup,
        tier: Number(tier),
        sirYN,
        sirRollupBusId,
      };
      hideModal();

      if (!isEditMode) {
        await createBusiness(business);
      } else {
        await updateBusiness(business);
      }

      fetchBusinesses();
      resetForm();
    },
    [hideModal, fetchBusinesses, createBusiness, updateBusiness, isEditMode],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        title={`${businessToEdit ? 'Edit' : 'Add'} Business`}
        closeAction={hideModal}
        visible={visible}
        submitAction={formikProps.submitForm}
        dismissAction={hideModal}
      >
        <AddEditBusinessForm
          {...formikProps}
          onCancelClick={hideModal}
          isEditMode={isEditMode}
          businesses={businesses}
          businessGroups={getActiveOptions(businessGroups)}
          customers={getActiveOptions(customers)}
        />
      </Modal>
    ),
    [businessToEdit, visible, hideModal, businesses, businessGroups, customers, isEditMode],
  );
  return (
    <Formik
      initialValues={businessToEdit ? businessToEdit : formInitialValues}
      validationSchema={businessesSchemas.getAddEditBusinessSchema({ businesses, isEditMode })}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={saveBusiness}
    >
      {renderForm}
    </Formik>
  );
};

AddEditBusinessesFormContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,

  fetchBusinesses: PropTypes.func.isRequired,
  createBusiness: PropTypes.func.isRequired,
  updateBusiness: PropTypes.func.isRequired,

  businessToEdit: businessesPropTypes,
  businesses: PropTypes.arrayOf(businessesPropTypes).isRequired,
  businessGroups: PropTypes.arrayOf(SelectOptionPropTypes),
  customers: PropTypes.arrayOf(SelectOptionPropTypes),
};

export default connect(null, {
  fetchBusinesses,
  createBusiness,
  updateBusiness,
})(AddEditBusinessesFormContainer);
