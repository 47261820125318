import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Modal from '@tls/ui-modal';

import Select from '../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import ButtonsGroup from '../shared/forms/buttonsGroup/buttonsGroup.component';

import { SORT_ORDER_OPTIONS } from './constants';
import styles from './updateSortOrderModal.module.scss';

const formInitialValues = {
  sortOption: SORT_ORDER_OPTIONS[0].value,
};

const UpdateSortOrderModal = ({
  onUpdateSortOrder,

  hideModal,
  visible,
}) => {
  const onSubmit = useCallback(
    ({ sortOption }, { resetForm }) => {
      onUpdateSortOrder(sortOption);
      hideModal();
      resetForm();
    },
    [hideModal, onUpdateSortOrder],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        title="Update Sort Order"
        closeAction={hideModal}
        visible={visible}
        submitAction={formikProps.submitForm}
        dismissAction={hideModal}
      >
        <form>
          <Select
            wrapperClassName={styles.sortOptionSelect}
            appkitLabel="Sort by"
            name="sortOption"
            options={SORT_ORDER_OPTIONS}
            value={formikProps.values.sortOption}
          />
          <ButtonsGroup
            isSubmitting={formikProps.isSubmitting}
            submitForm={formikProps.submitForm}
            onCancelClick={hideModal}
            submitButtonLabel="Save"
          />
        </form>
      </Modal>
    ),
    [hideModal, visible],
  );

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {renderForm}
    </Formik>
  );
};

UpdateSortOrderModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onUpdateSortOrder: PropTypes.func.isRequired,
};

export default UpdateSortOrderModal;
