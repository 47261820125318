import PropTypes from 'prop-types';

export const entityInformationType = PropTypes.shape({
  activeEntityYn: PropTypes.string,
  addQtrEstimatesYn: PropTypes.string,
  commercialDomicile: PropTypes.string,
  companyType: PropTypes.string,
  fedFilingType: PropTypes.string,
  partnershipEntityYn: PropTypes.string,
  partnershipType: PropTypes.string,
  roundEsPaymentsYn: PropTypes.string,
  serviceCompanyYn: PropTypes.string,
  shortYearMonths: PropTypes.number,
  sirEntityYn: PropTypes.string,
  taxSensitivity: PropTypes.string,
  dcsFilingGroup: PropTypes.string,
});

export const globalInformationType = PropTypes.shape({
  infoAccountId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  description: PropTypes.string,
});
