import { ColDef, NewValueParams, RowNode } from 'ag-grid-community';

import {
  CalcPreference,
  SelectionListsByTaxYear,
  SelectionListDefaultValues,
} from '../../../../common/types';
import {
  defaultColumnDefinitionWithFilter,
  adminColumnDefinition,
  selectCellEditorParamsFactory,
} from '../../shared/columnDefinitions';
import {
  TextCellEditor,
  TextCellRendererFactory,
  AdminCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import { defaultAgRichSelectCellEditor } from '../../shared/columnDefinitions/cellEditor';

import { CalcPreferencesDataTypes } from './constants';

const YES_NO_OPTIONS = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
];

type GetColumnDefinitionsParams = {
  onDeleteIconClick: (deletedRow: CalcPreference) => void;
  updateRow: (row: RowNode) => void;
  hasPermissionToDelete: boolean;
  selectionListsByTaxYear: SelectionListsByTaxYear | undefined;
  isFetchingSelectionLists: boolean;
  selectionListDefaultValues: SelectionListDefaultValues | undefined;
  isFetchingSelectionListDefaultValues: boolean;
  dataType: CalcPreferencesDataTypes | '';
};

const getColumnDefinitions = ({
  onDeleteIconClick,
  updateRow,
  hasPermissionToDelete,
  selectionListsByTaxYear,
  isFetchingSelectionLists,
  selectionListDefaultValues,
  isFetchingSelectionListDefaultValues,
  dataType,
}: GetColumnDefinitionsParams): ColDef[] => {
  const onCellValueChanged = ({ data }: NewValueParams) => updateRow(data);

  const onSelectionListCellValueChanged = ({ data }: NewValueParams) => {
    data.defaultValue = null;
    updateRow(data);
  };

  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: false,
  });

  const getSelectionListForJurisdiction = (jurisdictionId: string) =>
    selectionListsByTaxYear?.find(selectionList => selectionList.jurisdictionId === jurisdictionId)
      ?.selectionLists || [];

  const getDefaultValuesForSelectionList = (selectionListId: string) =>
    selectionListDefaultValues?.find(
      selectionListDefaultValue => selectionListDefaultValue.selectionListId === selectionListId,
    )?.values || [];

  const getDefaultValueOptions = ({
    data,
    dataType,
  }: {
    data: { selectionListId: string };
    dataType: CalcPreferencesDataTypes | '';
  }) => {
    switch (dataType) {
      case CalcPreferencesDataTypes.BOOLEAN:
        return YES_NO_OPTIONS;
      case CalcPreferencesDataTypes.SELECTION_LIST:
        return getDefaultValuesForSelectionList(data.selectionListId);
      default:
        return [];
    }
  };

  const validators = {
    defaultValue: (value: string) => {
      if (
        !value &&
        (dataType === CalcPreferencesDataTypes.SELECTION_LIST ||
          dataType === CalcPreferencesDataTypes.BOOLEAN)
      ) {
        return 'Default Value is required';
      }
    },
    selectionListId: (value: string) => {
      if (!value && dataType === CalcPreferencesDataTypes.SELECTION_LIST) {
        return 'Selection List is required';
      }
    },
  };

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
      width: 50,
      pinned: 'left',
      hide: !hasPermissionToDelete,
      lockPinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Jurisdiction',
      colId: 'jurisdiction',
      field: 'jurisdictionDescription',
      width: 200,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      hide: dataType !== CalcPreferencesDataTypes.SELECTION_LIST,
      headerName: 'Selection List',
      field: 'selectionListId',
      width: 250,
      editable: !isFetchingSelectionLists,
      cellRenderer: TextCellRendererFactory({
        getText: ({ value, data }: { value: string | null; data: { jurisdictionId: string } }) =>
          (
            getSelectionListForJurisdiction(data.jurisdictionId).find(
              option => String(option.value) === String(value),
            ) || null
          )?.label,
      }),
      cellRendererParams: {
        validators,
      },
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: ({ data }: RowNode) =>
        selectCellEditorParamsFactory(getSelectionListForJurisdiction(data.jurisdictionId), {})(),
      onCellValueChanged: onSelectionListCellValueChanged,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      hide: dataType === CalcPreferencesDataTypes.NUMBER,
      headerName: 'Default Value',
      field: 'defaultValue',
      width: 250,
      editable: !isFetchingSelectionListDefaultValues,
      cellRenderer: TextCellRendererFactory({
        getText: ({ value, data }: { value: string | null; data: { selectionListId: string } }) =>
          (
            getDefaultValueOptions({ data, dataType }).find(
              option => String(option.value) === String(value),
            ) || null
          )?.label,
      }),
      cellRendererParams: {
        validators,
      },
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: ({ data }: RowNode) => {
        const options = getDefaultValueOptions({ data, dataType });
        return selectCellEditorParamsFactory(options, {})();
      },
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Input Mask',
      field: 'inputMask',
      editable: true,
      cellEditor: TextCellEditor,
      onCellValueChanged,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Selection RegEx',
      field: 'validationRegex',
      editable: true,
      cellEditor: TextCellEditor,
      onCellValueChanged,
    },
  ];
};

export default getColumnDefinitions;
