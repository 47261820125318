import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { taxYearSelector, jurisdictionIdSelector } from '../store/selectors';
import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import DevelopmentTaxYearDropdown from '../developmentTaxYearDropdown.container';
import PeriodDropdown from '../periodDropdown.container';
import DevelopmentJurisdictionDropdown from '../developmentJurisdictionDropdown.container';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';
import headerStyles from '../../shared/displayComponents/headerWithParamDropdowns/styles.module.scss';
import WarningModal from '../../shared/displayComponents/warningModal.component';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import useModal from '../../shared/hooks/useModal.hook';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { useUpdateDevelopmentContextFromQueryParams } from '../store/hooks';

import {
  categoryOptionsSelector,
  isFetchingCategoriesSelector,
  isCalcSpecContextChangesBlockedSelector,
} from './store/selectors';
import { fetchCalcSpecsOptions, fetchCategories } from './store/actions';
import { calcSpecTypes, WARNING_MODAL_TITLE, WARNING_MODAL_MESSAGE } from './constants';
import BaseCalcSpecs from './baseCalcSpecs.container';
import ConsolCalcSpecs from './consolCalcSpecs.container';
import K1CalcSpecs from './k1CalcSpecs.container';
import ConspbCalcSpec from './conspbCalcSpecs.container';

const calcSpecTypeOptions = Object.values(calcSpecTypes).map(value => ({ label: value, value }));

const CalcSpecs = ({
  taxYear,
  jurisdictionId,

  fetchCalcSpecsOptions,

  categoryOptions,
  isFetchingCategories,
  fetchCategories,

  isCalcSpecContextChangesBlocked,
  hasUserPermissionsToEdit,
}) => {
  const { queryParams, setParams } = useUrlParams();
  const [calcSpecType, setCalcSpecType] = useState(queryParams.calcSpecType || calcSpecTypes.BASE);
  const [categories, setCategories] = useState([]);

  const { showModal: showWarningModal, modalProps } = useModal();

  useUpdateDevelopmentContextFromQueryParams();

  useEffect(() => {
    if (queryParams.calcSpecType || queryParams.search) {
      setParams({ queryParams: { calcSpecType: null, search: null } });
    }
  }, [setParams, queryParams.calcSpecType, queryParams.search]);

  useEffect(() => {
    if (taxYear) {
      fetchCategories({ taxYear });
    }
  }, [fetchCategories, taxYear]);

  useEffect(() => {
    if (taxYear && jurisdictionId) {
      fetchCalcSpecsOptions({ taxYear, jurisdictionId });
    }
  }, [fetchCalcSpecsOptions, taxYear, jurisdictionId]);

  useEffect(() => {
    if (categoryOptions.length) {
      setCategories(categoryOptions.map(({ value }) => value));
    }
  }, [setCategories, categoryOptions]);

  const handleCategoriesChange = useCallback(
    value => {
      if (JSON.stringify(value) !== JSON.stringify(categories)) {
        setCategories(value);
      }
    },
    [categories],
  );

  const calcSpecContextHandleChange = useCallback(
    handleChange => newValue => {
      if (isCalcSpecContextChangesBlocked) {
        showWarningModal();
        return;
      }
      handleChange(newValue);
    },
    [showWarningModal, isCalcSpecContextChangesBlocked],
  );

  const categoriesParam = useMemo(
    () => ({
      isFetchingParamData: isFetchingCategories,
      paramData: categoryOptions,
      setParamValue: handleCategoriesChange,
      disabled: isCalcSpecContextChangesBlocked,
    }),
    [
      handleCategoriesChange,
      categoryOptions,
      isFetchingCategories,
      isCalcSpecContextChangesBlocked,
    ],
  );

  const getSelectedCalcSpecTypeGrid = () => {
    switch (calcSpecType) {
      case calcSpecTypes.CONSOL:
        return (
          <ConsolCalcSpecs
            hasUserPermissionsToEdit={hasUserPermissionsToEdit}
            categories={categories}
          />
        );
      case calcSpecTypes.CONSPB:
        return (
          <ConspbCalcSpec
            hasUserPermissionsToEdit={hasUserPermissionsToEdit}
            categories={categories}
          />
        );
      case calcSpecTypes.K1:
        return <K1CalcSpecs hasUserPermissionsToEdit={hasUserPermissionsToEdit} />;
      default:
        return (
          <BaseCalcSpecs
            hasUserPermissionsToEdit={hasUserPermissionsToEdit}
            categories={categories}
          />
        );
    }
  };

  const displayPeriodDropdown = calcSpecType !== calcSpecTypes.BASE;
  const displayCategoryIdDropdown = calcSpecType !== calcSpecTypes.K1;

  return (
    <>
      <div className={headerStyles.flexSpaceBetween}>
        <div className={headerStyles.headerParam}>
          <DevelopmentTaxYearDropdown customOnChangeAction={calcSpecContextHandleChange} />
          <ParamDropdown
            label="Calc Spec Type"
            options={calcSpecTypeOptions}
            value={calcSpecType}
            handleChange={calcSpecContextHandleChange(setCalcSpecType)}
            isBusy={false}
          />
          {displayPeriodDropdown && (
            <PeriodDropdown customOnChangeAction={calcSpecContextHandleChange} />
          )}
          <DevelopmentJurisdictionDropdown customOnChangeAction={calcSpecContextHandleChange} />
          {displayCategoryIdDropdown && (
            <ReportParamMultiSelect
              label="Category ID"
              param={categoriesParam}
              selectedAll
              showSearchOnList
              displayLabelWhenLoading
              extendedWidth
            />
          )}
        </div>
      </div>
      {getSelectedCalcSpecTypeGrid()}
      <WarningModal
        title={WARNING_MODAL_TITLE}
        warningMessage={WARNING_MODAL_MESSAGE}
        {...modalProps}
      />
    </>
  );
};

CalcSpecs.propTypes = {
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,

  categoryOptions: PropTypes.arrayOf(SelectOptionPropTypes),
  isFetchingCategories: PropTypes.bool.isRequired,
  fetchCategories: PropTypes.func.isRequired,

  fetchCalcSpecsOptions: PropTypes.func.isRequired,

  isCalcSpecContextChangesBlocked: PropTypes.bool.isRequired,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),

    categoryOptions: categoryOptionsSelector(state),
    isFetchingCategories: isFetchingCategoriesSelector(state),
    isCalcSpecContextChangesBlocked: isCalcSpecContextChangesBlockedSelector(state),
  }),
  {
    fetchCalcSpecsOptions,
    fetchCategories,
  },
)(CalcSpecs);
