import PropTypes from 'prop-types';

export const compilationExpressionMessage = PropTypes.shape({
  dataItemName: PropTypes.string.isRequired,
  dataModelName: PropTypes.string.isRequired,
  dataSetName: PropTypes.string.isRequired,
  expressionType: PropTypes.string.isRequired,
  diagnosticType: PropTypes.string.isRequired,
  validationMessage: PropTypes.string.isRequired,
});
