import debounce from 'lodash.debounce';
import { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PRINT_SERVICE_PAGE_CONTAINER_ID } from '../constants';
import getCalculatedPagesPositionsFromPageContainer from '../getPagesPositionsFromPageContainer';

const useFormScrollHook = ({
  isFormMounted,
  selectedPage,
  isSelectedWithDropdown,
  selectFormPageWithScroll,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [mappedPages, setMappedPages] = useState([]);

  const pageContainer = formRef.current?.querySelector(`#${PRINT_SERVICE_PAGE_CONTAINER_ID}`);

  const updatePosition = useCallback(
    () =>
      debounce(({ target: pageContainer }) => {
        if (!mappedPages.length) {
          return;
        }

        const { clientHeight, scrollTop } = pageContainer;

        const scrollTreshold = Math.floor(clientHeight / 2);

        const { pageNumber } = [...mappedPages]
          .reverse()
          .find(page => page.pageStartPosition <= scrollTop + scrollTreshold);

        if (pageNumber !== selectedPage) {
          dispatch(selectFormPageWithScroll(pageNumber));
        }
      }, 100),
    [dispatch, selectFormPageWithScroll, selectedPage, mappedPages],
  );

  useEffect(() => {
    if (!pageContainer) {
      return setMappedPages([]);
    }

    setMappedPages(getCalculatedPagesPositionsFromPageContainer(pageContainer));
  }, [pageContainer]);

  useEffect(() => {
    if (!pageContainer) {
      return;
    }

    const scrollListener = updatePosition();

    pageContainer.addEventListener('scroll', scrollListener);

    return () => {
      pageContainer.removeEventListener('scroll', scrollListener);
    };
  }, [updatePosition, pageContainer]);

  useEffect(() => {
    if (!pageContainer) {
      return;
    }

    const pageId = mappedPages.find(({ pageNumber }) => pageNumber === selectedPage)?.pageId;
    const isReadyToScroll = Boolean(
      isSelectedWithDropdown && isFormMounted && selectedPage && pageId,
    );

    if (isReadyToScroll) {
      pageContainer.querySelector(`#${pageId}`).scrollIntoView();
    }
  }, [pageContainer, isSelectedWithDropdown, isFormMounted, selectedPage, mappedPages]);

  return formRef;
};

export default useFormScrollHook;
