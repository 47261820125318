import { Routes } from '@common-packages/routes-definitions';
import { dataTypes } from '@common-packages/shared-constants';

import {
  defaultColumnDefinitionWithFilter,
  adminColumnDefinition,
  defaultNoDataColumn,
} from '../../shared/columnDefinitions';
import { dateCellFormatter } from '../../shared/formatters';
import { getDataTypeBasedCellRenderer } from '../../shared/columnDefinitions/dataTypeBasedAgGridCells.utils';
import {
  AdminCellRendererFactory,
  LinkCellRendererFactory,
} from '../../shared/columnDefinitions/cellRenderers';
import downloadFile from '../../utils/downloadFile';

import { pdfTypes } from './constants';

const getColumnDefinitions = ({
  onDeleteIconClick,
  onAttachmentIconClick,
  onFormSelect,
  columnsBlueprint,
  clientId,
}) => {
  if (!columnsBlueprint.length) {
    return [defaultNoDataColumn];
  }

  const pdfLibDownloadLinkRenderer = () =>
    LinkCellRendererFactory({
      getText: ({ value }) => value,
      clickHandler: (_, data) =>
        downloadFile({
          route: `${Routes.developmentTaxFormsDownloadPdf.compiledRoute({
            formId: data.formId,
            pdfType: pdfTypes.PDF_LIB,
            clientId,
          })}`,
        }),
    });

  const getColumnDefinition = columnBlueprint => ({
    ...defaultColumnDefinitionWithFilter,
    headerName: columnBlueprint.displayName,
    field: columnBlueprint.field,
    editable: false,
    pinned: columnBlueprint.pinned,
    lockPinned: columnBlueprint.lockPinned,
    cellRenderer: (() => {
      switch (columnBlueprint.dataType) {
        case 'pdfLibDownloadLinkRenderer':
          return pdfLibDownloadLinkRenderer();
        case 'formLinkRenderer':
          return LinkCellRendererFactory({
            getText: ({ value }) => value,
            getPathName: () => Routes.developmentTaxFormEditV2.MAIN,
            clickHandler: (_value, { formId }) => onFormSelect(formId),
          });
        default:
          return getDataTypeBasedCellRenderer({
            dataType: columnBlueprint.dataType,
            getDrillData: () => null,
          });
      }
    })(),
    valueFormatter: columnBlueprint.dataType === dataTypes.DATE ? dateCellFormatter : null,
    cellRendererParams: {
      disabled: true,
    },
    width: columnBlueprint.width ? columnBlueprint.width : null,
    minWidth: columnBlueprint.minWidth ? columnBlueprint.minWidth : null,
    cellStyle: columnBlueprint.cellStyle ? columnBlueprint.cellStyle : null,
  });

  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    onAttachmentIconClick,
    shouldShowEditIcon: false,
    shouldShowAttachmentIcon: true,
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
      pinned: 'left',
      lockPinned: true,
    },
    ...columnsBlueprint.map(column => {
      if (column.children) {
        return {
          headerName: column.displayName,
          children: column.children.map(getColumnDefinition),
        };
      }

      return getColumnDefinition(column);
    }),
  ];
};

export default getColumnDefinitions;
