import { useState, useCallback } from 'react';

const useModal = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const hideModal = useCallback(() => setModalVisible(false), []);
  const showModal = useCallback(data => {
    setModalVisible(true);
    setModalData(data);
  }, []);

  return {
    showModal,
    modalProps: {
      visible: isModalVisible,
      hideModal,
      modalData,
    },
  };
};

export default useModal;
