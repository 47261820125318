const toString = (date: Date) =>
  (date &&
    date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })) ||
  '';

const fromString = (strValue: string) => {
  if (!strValue) {
    return null;
  }

  const [month, day, year] = strValue.split('/').map(str => Number(str));
  return new Date(year, month - 1, day);
};

const americanDateFormat = {
  toString,
  fromString,
};

export default americanDateFormat;
