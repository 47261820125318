import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';

import PdfAttachments from '../shared/pdfAttachments/pdfAttachments.container';

import Entity from './entity.container';
import PartnershipInformation from './partnershipInformation/partnershipInformation.container';
import ScheduleK1Information from './k1Information/k1Information.container';
import ScheduleK1InformationV2 from './k1InformationV2/k1InformationV2.container';
import GlobalK1Information from './globalK1Information/globalK1Information.container';
import AddPartnershipInformation from './partnershipInformation/AddPartnershipInformation.container';
import PartnershipFlowThrough from './partnershipFlowThrough/partnershipFlowThrough.container';
import NonFillingStates from './nonFilingStates/nonFilingStates.container';
import ReviewerNotes from './reviewerNotes/reviewerNotes.container';
import EntityInformation from './entityInformation/entityInformation.container';
import FederalReconciliation from './federalReconciliations/federalReconciliations.container';
import FederalReconciliationVariances from './federalReconciliations/variances/federalReconciliationVariances.container';
import SltAmountDetails from './federalReconciliations/sltAmountDetails/sltAmountDetails.container';
import CategoryDetails from './federalReconciliations/categoryDetails/categoryDetails.container';
import StateAccountNumbers from './stateAccountNumbers/stateAccountNumbers.container';
import PartnershipAmountDetails from './partnershipFlowThrough/partnershipAmountDetails/partnershipAmountDetails.container';
import ManuallyEnteredData from './manuallyEnteredAccountData/manuallyEnteredAccountData.container';
import PriorYearComparisonReportDetail from './priorYearComparisonReportDetail/priorYearComparisonReportDetail.container';
import { AuthorizedEntityRoute } from './routeConfiguration/entityRoute.container';
import EntityValue from './routeConfiguration/entityValue.container';
import FilingStatesJurisdictionContextValue from './routeConfiguration/filingStatesJurisdictionContextValue.container';
import NonFilingStatesJurisdictionContextValue from './routeConfiguration/nonFilingStatesJurisdictionContextValue.container';
import FederalReconciliationsOrgIdContextValue from './federalReconciliations/routeConfiguration/federalReconciliationsOrgIdContextValue.container';
import FederalReconciliationsOrgNameContextValue from './federalReconciliations/routeConfiguration/federalReconciliationsOrgNameContextValue.container';
import NonFilingStatesDataProvider from './dataProviders/nonFilingStatesDataProvider.container';
import FederalReconciliationsDataProvider from './federalReconciliations/dataProviders/federalReconciliationsDataProvider.container';
import PartnershipInformationDataProvider from './partnershipInformation/dataProviders/partnershipInformationDataProvider.container';
import FilingStatesReturnWorkspaceRoutes from './filingStatesReturnWorkspaceRoutes.component';
import NonFilingStatesReturnWorkspaceRoutes from './nonFilingStatesReturnWorkspaceRoutes.component';
import PriorYearComparisonReportSummary from './priorYearComparisonReportSummary/priorYearComparisonReportSummary.container';
import PartnershipAllocationPercentages from './partnershipAllocationPercentages/partnershipAllocationPercentages.container';

const EntityRoutes = () => {
  const entityIdAdditionalValue = <EntityValue />;
  const filingStatesJurisdictionContextValue = <FilingStatesJurisdictionContextValue />;
  const nonFilingStatesJurisdictionContextValue = <NonFilingStatesJurisdictionContextValue />;

  const federalReconciliationVariancesValues = (
    <>
      {entityIdAdditionalValue}
      <FederalReconciliationsOrgIdContextValue />
      <FederalReconciliationsOrgNameContextValue />
    </>
  );

  const filingStatesAdditionalValues = (
    <>
      {entityIdAdditionalValue}
      {filingStatesJurisdictionContextValue}
    </>
  );

  const nonFilingStatesAdditionalValues = (
    <>
      {entityIdAdditionalValue}
      {nonFilingStatesJurisdictionContextValue}
    </>
  );

  return (
    <Switch>
      <Route
        path={Routes.entity.MAIN}
        render={() => (
          <>
            <AuthorizedEntityRoute
              path={Routes.entity.MAIN}
              exact
              component={Entity}
              additionalValues={entityIdAdditionalValue}
            />

            <AuthorizedEntityRoute
              path={Routes.entityFilingStatesReturnWorkspace.MAIN}
              component={FilingStatesReturnWorkspaceRoutes}
              additionalValues={filingStatesAdditionalValues}
            />

            <NonFilingStatesDataProvider path={Routes.nonFilingStates.MAIN}>
              <AuthorizedEntityRoute
                path={Routes.nonFilingStates.MAIN}
                exact
                component={NonFillingStates}
                additionalValues={entityIdAdditionalValue}
              />
              <AuthorizedEntityRoute
                path={Routes.entityNonFilingStatesReturnWorkspace.MAIN}
                component={NonFilingStatesReturnWorkspaceRoutes}
                additionalValues={nonFilingStatesAdditionalValues}
              />
            </NonFilingStatesDataProvider>

            <AuthorizedEntityRoute
              exact
              path={Routes.entityInformation.MAIN}
              component={EntityInformation}
              additionalValues={entityIdAdditionalValue}
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.scheduleK1Information.MAIN}
              component={ScheduleK1Information}
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.scheduleK1InformationV2.MAIN}
              component={ScheduleK1InformationV2}
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.globalScheduleK1Information.MAIN}
              component={GlobalK1Information}
            />
            <PartnershipInformationDataProvider path={Routes.reviewPartnershipInformation.MAIN}>
              <AuthorizedEntityRoute
                exact
                path={Routes.reviewPartnershipInformation.MAIN}
                component={PartnershipInformation}
                additionalValues={entityIdAdditionalValue}
              />
              <AuthorizedEntityRoute
                exact
                path={Routes.addPartnershipInformation.MAIN}
                component={AddPartnershipInformation}
                additionalValues={entityIdAdditionalValue}
              />
            </PartnershipInformationDataProvider>

            <AuthorizedEntityRoute
              exact
              path={Routes.partnershipFlowThrough.MAIN}
              component={PartnershipFlowThrough}
              additionalValues={entityIdAdditionalValue}
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.partnershipAmountDetails.MAIN}
              component={PartnershipAmountDetails}
              additionalValues={entityIdAdditionalValue}
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.partnershipAllocationPercentages.MAIN}
              component={PartnershipAllocationPercentages}
              additionalValues={entityIdAdditionalValue}
            />

            <AuthorizedEntityRoute
              exact
              path={Routes.prepareReviewNotes.MAIN}
              component={ReviewerNotes}
              additionalValues={entityIdAdditionalValue}
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.stateAccountNumbers.MAIN}
              component={StateAccountNumbers}
              additionalValues={entityIdAdditionalValue}
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.addEditGlobalPdfAttachments.MAIN}
              component={PdfAttachments}
              additionalValues={entityIdAdditionalValue}
            />

            <FederalReconciliationsDataProvider path={Routes.federalReconciliation.MAIN}>
              <AuthorizedEntityRoute
                exact
                path={Routes.federalReconciliation.MAIN}
                component={FederalReconciliation}
                additionalValues={entityIdAdditionalValue}
              />
              <AuthorizedEntityRoute
                exact
                path={Routes.federalReconciliationVariances.MAIN}
                component={FederalReconciliationVariances}
                additionalValues={federalReconciliationVariancesValues}
              />
              <AuthorizedEntityRoute
                exact
                path={Routes.sltAmountDetails.MAIN}
                component={SltAmountDetails}
                additionalValues={entityIdAdditionalValue}
              />
              <AuthorizedEntityRoute
                exact
                path={Routes.federalReconciliationCategoryDetails.MAIN}
                component={CategoryDetails}
                additionalValues={entityIdAdditionalValue}
              />
            </FederalReconciliationsDataProvider>

            <AuthorizedEntityRoute
              exact
              path={Routes.manuallyEnteredData.MAIN}
              component={ManuallyEnteredData}
              additionalValues={entityIdAdditionalValue}
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.priorYearComparisonReportDetail.MAIN}
              component={PriorYearComparisonReportDetail}
              hideGlobalContext
            />
            <AuthorizedEntityRoute
              exact
              path={Routes.priorYearComparisonReportSummary.MAIN}
              component={PriorYearComparisonReportSummary}
              hideGlobalContext
            />
          </>
        )}
      />
    </Switch>
  );
};

export default EntityRoutes;
