// same enum in backend - make sure these match
export enum FederalReturnOption {
  FED_NA = '1',
  FED_AS_FILED = '2',
  FED_PROFORMA = '3',
  FED_PDF = '4',
  CONS_AS_FILED = '5',
  CONS_PROFORMA = '6',
  FED_MANUAL_PROFORMA = '7',
}

export enum EFileReturnStatus {
  NOT_SUBMITTED = 0,
  SUBMITTED = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  DENIED = 4,
  RETIRED = 5,
  UNKNOWN = 999,
}

export enum TransmitterProvider {
  TTI = 1,
  TTITestHarness = 2,
  TR = 3,
  All = 4,
  TRTestHarness = 5,
}

export enum TransmitterProviderLabels {
  TTI = 'TTI',
  TR = 'TR',
}
