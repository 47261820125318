import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useFetch from '../../shared/hooks/useFetch.hook';
import ParamDropdown from '../../shared/displayComponents/paramDropdown/paramDropdown.component';

import { fetchReturnBinderListApiAction } from './api';
import styles from './styles.module.scss';

const ReturnBinderListDropdown = ({
  value = null,
  onSelect = () => null,

  ttiYearId,
  caseId,
  isFetchingCaseList,
}) => {
  const {
    data: returnBinderList,
    isFetching: isFetchingReturnBinderList,
    fetch: fetchReturnBinderList,
  } = useFetch({
    action: fetchReturnBinderListApiAction,
  });

  useEffect(() => {
    if (ttiYearId && caseId) {
      fetchReturnBinderList({ ttiYearId, caseId });
    }
  }, [fetchReturnBinderList, ttiYearId, caseId]);

  return (
    <div className={styles.returnBinderDropdown}>
      <ParamDropdown
        label="Return Binder"
        options={returnBinderList}
        value={value}
        handleChange={onSelect}
        isBusy={isFetchingCaseList || isFetchingReturnBinderList}
      />
    </div>
  );
};

ReturnBinderListDropdown.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func,

  ttiYearId: PropTypes.string,
  caseId: PropTypes.string,
  isFetchingCaseList: PropTypes.bool,
};

export default ReturnBinderListDropdown;
