import { useState, useCallback, useMemo, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { useMutationAddReturnItems } from '../../shared/mutations/binderMaintenance';
import { globalContextSelector } from '../../shared/store/selectors';
import { FilingTypes } from '../../shared/enums';
import { GlobalContext, BusinessEntityId } from '../../../../common/types';
import { ReturnItemTypes } from '../enums';

type Item = {
  [key: string]: string;
};

interface UseAddReturnItemsLogicProps {
  returnId: string;
  hidePanel: () => unknown;
  returnItemType: ReturnItemTypes;
  businessEntityId?: BusinessEntityId | null;
  filingType?: FilingTypes | null;
  existingReturnItemsIds: string[];
  items: Item[];
  itemIdKey: keyof Item;
  itemLabelKey: keyof Item;
  areAllEntitiesInConsolidation?: boolean;
}

const useAddReturnItemsLogic = ({
  returnId,
  hidePanel,
  returnItemType,
  businessEntityId,
  filingType,
  existingReturnItemsIds,
  items,
  itemIdKey,
  itemLabelKey,
  areAllEntitiesInConsolidation = false,
}: UseAddReturnItemsLogicProps) => {
  const dispatch = useDispatch();
  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const [shouldShowSelectedItems, setShouldShowSelectedItems] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState('');

  const {
    mutateAsync: addReturnItems,
    isLoading: isAddingReturnItems,
  } = useMutationAddReturnItems();

  const visibleItems = useMemo(() => {
    const itemsWithoutExistingReturnItems =
      items?.filter(item => !existingReturnItemsIds.includes(item[itemIdKey])) || [];

    const selectedOrAllItems = shouldShowSelectedItems
      ? itemsWithoutExistingReturnItems.filter(item => selectedItemIds.includes(item[itemIdKey]))
      : itemsWithoutExistingReturnItems;

    const itemsToDisplay = searchFilter
      ? selectedOrAllItems.filter(item =>
          item[itemLabelKey].toLowerCase().includes(searchFilter.toLowerCase()),
        )
      : selectedOrAllItems;

    return itemsToDisplay.map(item => ({ id: item[itemIdKey], label: item[itemLabelKey] }));
  }, [
    items,
    existingReturnItemsIds,
    shouldShowSelectedItems,
    selectedItemIds,
    searchFilter,
    itemIdKey,
    itemLabelKey,
  ]);

  const isListItemSelected = useCallback((pdfId: string) => selectedItemIds.includes(pdfId), [
    selectedItemIds,
  ]);

  const handleSearch = useCallback(
    (newSearchFilter: string) => setSearchFilter(newSearchFilter),
    [],
  );

  const handleToggleShowSelectedItems = useCallback(() => {
    setShouldShowSelectedItems(currentValue => !currentValue);
  }, []);

  const handleCheckboxChange = useCallback(
    (pdfId: string) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedItemIds(currentIds => [...currentIds, pdfId]);
        return;
      }
      setSelectedItemIds(currentIds => currentIds.filter(currentId => currentId !== pdfId));
    },
    [],
  );

  const hideReturnItemsPanel = useCallback(() => {
    setShouldShowSelectedItems(false);
    setSelectedItemIds([]);
    setSearchFilter('');
    hidePanel();
  }, [hidePanel]);

  const handleHidePanel = useCallback(() => {
    if (selectedItemIds.length) {
      dispatch(
        showConfirmModal({
          title: 'Do you want to leave this screen?',
          text: 'The changes you made have not been saved.',
          confirmText: 'Leave',
          dismissText: 'Stay',
          confirmCallback: hideReturnItemsPanel,
        }),
      );
      return;
    }
    hideReturnItemsPanel();
  }, [dispatch, hideReturnItemsPanel, selectedItemIds.length]);

  const handleAddReturnItems = useCallback(async () => {
    await addReturnItems({
      returnId,
      apiRouteParamString: globalContext.apiRouteParamString,
      businessEntityId: businessEntityId || globalContext.params.businessEntityId || '',
      binderItemIds: selectedItemIds,
      documentType: returnItemType,
      documentLevel: filingType || ((globalContext.params.filingTypeId || '') as FilingTypes),
      areAllEntitiesInConsolidation,
    });
    hideReturnItemsPanel();
  }, [
    addReturnItems,
    hideReturnItemsPanel,
    globalContext.apiRouteParamString,
    globalContext.params.businessEntityId,
    globalContext.params.filingTypeId,
    selectedItemIds,
    returnId,
    businessEntityId,
    returnItemType,
    filingType,
    areAllEntitiesInConsolidation,
  ]);

  return {
    visibleItems,
    selectedItemIds,
    shouldShowSelectedItems,
    isAddingReturnItems,
    isListItemSelected,
    handleSearch,
    handleToggleShowSelectedItems,
    handleCheckboxChange,
    handleHidePanel,
    handleAddReturnItems,
  };
};

export default useAddReturnItemsLogic;
