import reducerFactory from '../../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../../shared/store/getApiActionTypesHandlers';

import { fetchCategoryDetailsTypes } from './types';

const categoryDetailsReducer = reducerFactory({
  initialState: {
    categoryDetails: [],
    isFetchingCategoryDetails: false,
  },
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchCategoryDetailsTypes,
      payloadKey: 'categoryDetails',
      fetchingKey: 'isFetchingCategoryDetails',
    }),
  },
});

export default categoryDetailsReducer;
