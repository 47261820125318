import { defaultColumnDefinition, currencyColumnDefinition } from '../../shared/columnDefinitions';

const overlinedCellClassRules = {
  'overlined-cell': ({ data }) => data.isTotalRow,
};

const boldCellClassRules = {
  'bold-cell': ({ data }) => data.isTotalRow,
};
const evBreakdownColumnDefinition = {
  ...defaultColumnDefinition,
  sortable: false,
  cellClassRules: { ...overlinedCellClassRules },
};
const columnDefinitions = [
  {
    ...evBreakdownColumnDefinition,
    headerName: 'Entity ID',
    field: 'orgId',
    cellClassRules: { ...boldCellClassRules, ...overlinedCellClassRules },
    hide: true,
  },
  {
    ...evBreakdownColumnDefinition,
    headerName: 'Entity Name',
    field: 'orgName',
    hide: true,
  },
  {
    ...evBreakdownColumnDefinition,
    headerName: 'Account ID',
    field: 'accountId',
  },
  {
    ...evBreakdownColumnDefinition,
    headerName: 'Account Description',
    field: 'accountDescription',
    width: 300,
  },
  {
    ...evBreakdownColumnDefinition,
    headerName: 'Jurisdiction',
    field: 'jurisdictionDescription',
  },
  {
    ...evBreakdownColumnDefinition,
    ...currencyColumnDefinition,
    headerName: 'Amount',
    field: 'amt',
    cellClassRules: { ...boldCellClassRules, ...overlinedCellClassRules },
  },
];

export default columnDefinitions;
