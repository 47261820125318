import { useRef, useState, useCallback, useEffect } from 'react';
import { CompilationResult, SelectOption } from '@tls/slt-types';

import { useMutationCompileExpression } from '../../../shared/mutations/expressionBuilder';

interface UseCompileAllButtonLogicProps {
  taxYear: string;
  jurisdictionId: string;
  jurisdictionsOptions: SelectOption[];
}

interface LastJurisdictionData {
  lastJurisdiction: SelectOption;
  processedJurisdictions: number;
  totalJurisdictions: number;
}

const useCompileAllButtonLogic = ({
  taxYear,
  jurisdictionId,
  jurisdictionsOptions,
}: UseCompileAllButtonLogicProps) => {
  const shouldStopCompilation = useRef(false);
  const [isStoppingCompilation, setIsStoppingCompilation] = useState(false);
  const [compilationResult, setCompilationResult] = useState<CompilationResult[] | null>(null);
  const [isCompiling, setIsCompiling] = useState(false);
  const [currentJurisdiction, setCurrentJurisdiction] = useState<SelectOption | null>(null);
  const [lastJurisdictionData, setLastJurisdictionData] = useState<LastJurisdictionData | null>(
    null,
  );

  const { mutateAsync: runCompilation } = useMutationCompileExpression();

  useEffect(() => {
    setCompilationResult(null);
    setLastJurisdictionData(null);
  }, [taxYear, jurisdictionId]);

  const handleCompileAllButtonClick = useCallback(async () => {
    setCompilationResult([]);
    setLastJurisdictionData(null);
    setIsCompiling(true);

    for (const jurisdiction of jurisdictionsOptions) {
      if (shouldStopCompilation.current) {
        break;
      }

      setCurrentJurisdiction(jurisdiction);
      const result = await runCompilation({ taxYear, jurisdictionId: jurisdiction.value || '' });
      const resultWithJurisdiction = result.map(row => ({
        ...row,
        jurisdiction: jurisdiction.label,
      }));
      setCompilationResult(currentResult => [...(currentResult || []), ...resultWithJurisdiction]);
      setLastJurisdictionData({
        lastJurisdiction: jurisdiction,
        processedJurisdictions: jurisdictionsOptions.indexOf(jurisdiction) + 1,
        totalJurisdictions: jurisdictionsOptions.length,
      });
    }

    setIsStoppingCompilation(false);
    shouldStopCompilation.current = false;
    setCurrentJurisdiction(null);
    setIsCompiling(false);
  }, [runCompilation, jurisdictionsOptions, taxYear]);

  const handleStopPropagationButtonClick = useCallback(() => {
    setIsStoppingCompilation(true);
    shouldStopCompilation.current = true;
  }, []);

  const isCompileOrStopButtonDisabled = !taxYear || isStoppingCompilation;

  return {
    compilationResult,
    lastJurisdictionData,
    isCompileOrStopButtonDisabled,
    isCompiling,
    isStoppingCompilation,
    currentJurisdiction,
    handleCompileAllButtonClick,
    handleStopPropagationButtonClick,
  };
};

export default useCompileAllButtonLogic;
