import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FormView from '../../../shared/displayComponents/formView/formView.component';
import {
  pdfFieldSelector,
  pdfFieldsByPageSelector,
  isFetchingPDFFieldsByPageSelector,
} from '../../../shared/store/dataModels/selectors';
import {
  formHtmlSelector,
  isFetchingFormsSelector,
  isFetchingFormHtmlSelector,
  formPagesSelector,
  formPageSelector,
  isSelectedWithDropdownSelector,
} from '../../store/selectors';
import { formHTMLReturnedErrorSelector } from '../store/selectors';
import { selectFormPageWithScroll } from '../../store/actions';
import { FormDetailsTabsTypes } from '../constants';
import getFormFieldNameWithMultCondition from '../../../shared/forms/getFormFieldNameWithMultCondition';

import styles from './styles.module.scss';

const TaxFormView = ({
  selectedFormFieldId = null,
  selectedFormFieldName = null,
  handleFormFieldChange,
  selectFormDetailTab,
  selectedDisplayFormValue,
}) => {
  const formHTML = useSelector(formHtmlSelector);
  const isFetchingTaxFormHtml = useSelector(isFetchingFormHtmlSelector);
  const isFetchingTaxForms = useSelector(isFetchingFormsSelector);
  const pdfFieldsByPage = useSelector(pdfFieldsByPageSelector);
  const isFetchingPDFFieldsByPage = useSelector(isFetchingPDFFieldsByPageSelector);
  const numberOfPages = useSelector(formPagesSelector);
  const selectedPage = useSelector(formPageSelector);
  const selectedFormField = useSelector(state => pdfFieldSelector(state, selectedFormFieldId));
  const isSelectedWithDropdown = useSelector(isSelectedWithDropdownSelector);
  const formHTMLReturnedError = useSelector(formHTMLReturnedErrorSelector);

  const handleFormFieldSelection = useCallback(
    fieldName => {
      const fieldId = pdfFieldsByPage.find(
        ({ name }) => name === getFormFieldNameWithMultCondition(fieldName),
      )?.id;
      if (fieldId) {
        handleFormFieldChange(fieldId, fieldName);
        selectFormDetailTab(FormDetailsTabsTypes.FIELDS);
      }
    },
    [handleFormFieldChange, selectFormDetailTab, pdfFieldsByPage],
  );

  return (
    <FormView
      className={styles.formWrapper}
      formHTML={formHTML}
      isLoading={isFetchingTaxForms || isFetchingTaxFormHtml}
      numberOfPages={numberOfPages}
      selectedPage={selectedPage}
      isSelectedWithDropdown={isSelectedWithDropdown}
      pdfFieldsByPage={pdfFieldsByPage}
      selectFormPageWithScroll={selectFormPageWithScroll}
      selectedDisplayFormValue={selectedDisplayFormValue}
      isFetchingFieldsValues={isFetchingPDFFieldsByPage}
      selectedField={selectedFormField}
      selectedFormFieldName={selectedFormFieldName}
      onFormFieldSelection={handleFormFieldSelection}
      formHTMLReturnedError={formHTMLReturnedError}
    />
  );
};

TaxFormView.propTypes = {
  selectedFormFieldId: PropTypes.string,
  selectedFormFieldName: PropTypes.string,
  handleFormFieldChange: PropTypes.func.isRequired,
  selectFormDetailTab: PropTypes.func.isRequired,
  selectedDisplayFormValue: PropTypes.string.isRequired,
};

export default TaxFormView;
