import { useState, useCallback, useEffect, useMemo, useReducer } from 'react';
import cloneDeep from 'lodash.clonedeep';

import useGridApi from '../hooks/useGridApi.hook';

import getChangedRows from './getChangedRows';
import { initialState, createReducer, actionCreators } from './editModeLogicReducer';
import useAgGridCellEditModeInfo from './useAgGridCellEditModeInfo.hook';
import isSameRow from './isSameRow';

const findRowDataChanges = ({ modifiedRows, rowsToAdd, rowsToDelete, rowData, getUniqueRowId }) => {
  const rowsPairsWithChanges = getChangedRows({
    getUniqueRowId,
    modifiedRows: modifiedRows.filter(
      modifiedRow => !rowsToDelete.find(row => isSameRow(modifiedRow, row, getUniqueRowId)),
    ),
    originalRows: rowData,
  }).filter(({ oldRow }) => Boolean(oldRow));

  return {
    rowsPairsWithChanges,
    rowsToAdd,
    rowsToDelete,
  };
};

const useEditModeLogic = ({
  appendNewRows,
  rowData,
  getUniqueRowId,
  onCancel = () => null,
  onSave = param => param,
  startInEditMode = false,
  appendRowUnderSelectedRow = false,
  turnOffEditModeOnSave = false,
} = {}) => {
  const { gridApi, columnApi, onGridReady } = useGridApi();
  const [isInEditMode, setIsInEditMode] = useState(startInEditMode);
  const [state, dispatch] = useReducer(createReducer(getUniqueRowId), initialState);
  const { clonedRowData, modifiedRows, rowsToDelete, rowsToAdd } = state;

  useEffect(() => {
    const clonedRowData = cloneDeep(rowData);
    dispatch(actionCreators.changeRowData(clonedRowData));
  }, [rowData]);

  const addRow = useCallback(
    insertedRow => {
      dispatch(actionCreators.addRow(insertedRow, appendNewRows));
      const newRowId = gridApi.getSelectedNodes().length
        ? gridApi.getSelectedNodes()[0].rowIndex + 1
        : 0;

      const { add: addedRows } = gridApi.applyTransaction({
        add: [insertedRow],
        addIndex: appendRowUnderSelectedRow ? newRowId : null,
      });
      return addedRows[0];
    },
    [appendNewRows, appendRowUnderSelectedRow, gridApi],
  );
  // for groupEdit
  const updateGroup = useCallback(
    modifiedGroupRows => {
      dispatch(actionCreators.updateGroup(modifiedGroupRows));
      gridApi.applyTransaction({ update: modifiedGroupRows });
    },
    [gridApi],
  );
  const updateRow = useCallback(
    modifiedRow => {
      dispatch(actionCreators.updateRow(modifiedRow));
      gridApi?.applyTransaction({ update: [modifiedRow] });
    },
    [gridApi],
  );
  const deleteRow = useCallback(
    deletedRow => {
      dispatch(actionCreators.deleteRow(deletedRow));
      gridApi.applyTransaction({ remove: [deletedRow] });
    },
    [gridApi],
  );

  const updateRowForSelectAll = useCallback(modifiedRow => {
    dispatch(actionCreators.updateRow(modifiedRow));
  }, []);

  const findChanges = useCallback(
    () => findRowDataChanges({ modifiedRows, rowsToAdd, rowsToDelete, rowData, getUniqueRowId }),
    [rowsToAdd, modifiedRows, rowsToDelete, rowData, getUniqueRowId],
  );

  const revertChanges = useCallback(() => {
    gridApi?.stopEditing();
    const changesToCancel = findChanges();
    const isAnyChangeToCancel =
      Boolean(changesToCancel.rowsPairsWithChanges.length) ||
      Boolean(changesToCancel.rowsToAdd.length) ||
      Boolean(changesToCancel.rowsToDelete.length);

    if (!isAnyChangeToCancel) {
      return;
    }

    // these rows are not in the clonedRowData, they are only present
    // in the internal agGrid state, so we remove them by hand,
    // just as we added them by hand
    gridApi?.applyTransaction({
      add: changesToCancel.rowsToDelete,
      remove: changesToCancel.rowsToAdd,
    });

    const clonedRowData = cloneDeep(rowData);
    dispatch(actionCreators.changeRowData(clonedRowData));
  }, [findChanges, gridApi, rowData]);

  const revertChangesAndCancel = useCallback(() => {
    revertChanges();
    onCancel();
  }, [revertChanges, onCancel]);

  const findChangesAndSave = useCallback(async () => {
    const changesToSave = findChanges();
    const response = await onSave(changesToSave);

    if (response && response.shouldCancelHookStateReset) {
      return changesToSave;
    }

    if (turnOffEditModeOnSave) {
      setIsInEditMode(false);
    }

    gridApi.stopEditing(false);
    dispatch(actionCreators.resetChanges());
    return changesToSave;
  }, [findChanges, gridApi, onSave, turnOffEditModeOnSave]);

  const isInAgGridEditMode = useAgGridCellEditModeInfo(gridApi);

  const getAllRows = useCallback(
    (isFetchAllRows = false) => {
      const rows = [];
      const forEachMethod = isFetchAllRows ? 'forEachNode' : 'forEachNodeAfterFilterAndSort';
      gridApi?.[forEachMethod](({ data }) => rows.push(data));
      return rows;
    },
    [gridApi],
  );

  const isDirty = useMemo(() => {
    const {
      dirty = false,
      rowsPairsWithChanges = [],
      rowsToAdd = [],
      rowsToDelete = [],
    } = findChanges();

    return Boolean(dirty || rowsPairsWithChanges.length || rowsToAdd.length || rowsToDelete.length);
  }, [findChanges]);

  return {
    gridApi,
    columnApi,
    clonedRowData,
    onGridReady,
    addRow,
    updateGroup,
    updateRow,
    deleteRow,
    updateRowForSelectAll,
    getAllRows,

    isInEditMode,
    setIsInEditMode,
    isInAgGridEditMode,
    isDirty,

    findChanges,
    findChangesAndSave,
    revertChanges,
    revertChangesAndCancel,
  };
};

export default useEditModeLogic;
