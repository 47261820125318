import { arrayToTree } from 'performant-array-to-tree';
import differenceBy from 'lodash.differenceby';
import uniqBy from 'lodash.uniqby';

import {
  FORM_DETAILS_TABS_DEFINITION,
  TaxFormActionTypes,
  PdfPageDataUsageTypes,
} from '../constants';

import getNewStateForAssignDatasets from './getNewStateForAssignDatasets';
import addDatasetParentsToArray from './addDatasetParentsToArray';

export const taxFormsReducerInitialState = {
  selectedFormDetailsTab: FORM_DETAILS_TABS_DEFINITION[0].type,
  isSaving: false,
  isFormDirty: false,
  selectedAvailableDatasets: [],
  selectedAssignedDatasets: [],
  selectedFormFieldId: null,
  selectedFormFieldName: null,
};

export const taxFormsReducer = (state, { type, payload }) => {
  switch (type) {
    case TaxFormActionTypes.RESET:
      return {
        ...taxFormsReducerInitialState,
        selectedFormDetailsTab: state.selectedFormDetailsTab,
      };
    case TaxFormActionTypes.SOFT_RESET:
      return {
        ...taxFormsReducerInitialState,
        ...payload,
        dataModelId: state.dataModelId || payload.dataModelId,
        selectedFormFieldId: state.selectedFormFieldId,
        selectedFormDetailsTab: state.selectedFormDetailsTab,
      };
    case TaxFormActionTypes.SET_IS_SAVING:
      return {
        ...state,
        isSaving: payload,
      };
    case TaxFormActionTypes.SET_IS_FORM_DIRTY:
      return {
        ...state,
        isFormDirty: payload,
      };
    case TaxFormActionTypes.SELECT_FORM_FIELD_ID:
      return {
        ...state,
        selectedFormFieldId: payload,
      };
    case TaxFormActionTypes.SELECT_FORM_FIELD_NAME:
      return {
        ...state,
        selectedFormFieldName: payload,
      };
    case TaxFormActionTypes.SELECT_DATA_MODEL_ID:
      return {
        ...state,
        dataModelId: payload.value,
      };
    case TaxFormActionTypes.SELECT_FORM_DETAIL_TAB:
      return {
        ...state,
        selectedFormDetailsTab: payload,
      };
    case TaxFormActionTypes.SELECT_AVAILABLE_DATASETS:
      return {
        ...state,
        selectedAvailableDatasets: payload,
      };
    case TaxFormActionTypes.SELECT_ASSIGNED_DATASETS:
      return {
        ...state,
        selectedAssignedDatasets: payload,
      };
    case TaxFormActionTypes.UNASSIGN_DATASETS:
      return getNewStateForUnassignDatasets(state, payload);
    case TaxFormActionTypes.ASSIGN_DATASETS:
      return getNewStateForAssignDatasets(state, payload);
    default:
      throw new Error('Unsupported action type');
  }
};

export const getNewStateForUnassignDatasets = (state, { datasets }) => ({
  ...state,
  pdfPageDataUsage: differenceBy(state.pdfPageDataUsage, datasets, 'treeHierarchyId'),
});

export const isFormDirtySelector = data => data.isFormDirty;
export const isSavingSelector = data => data.isSaving;
export const selectedFieldIdSelector = data => data.selectedFormFieldId;
export const selectedFieldNameSelector = data => data.selectedFormFieldName;
export const datasetsParentsSelector = data => {
  const parentsDatasets = [];
  const allDatasets = data.datasets?.[data.dataModelId];
  data.selectedAvailableDatasets.forEach(dataset => {
    addDatasetParentsToArray(dataset, allDatasets, parentsDatasets);
  });
  return uniqBy(parentsDatasets, 'id');
};
export const availableDatasetsTreeSelector = data =>
  arrayToTree(data.datasets?.[data.dataModelId] || []);
export const assignedDatasetsTreeSelector = data =>
  arrayToTree(data.pdfPageDataUsage || [], {
    id: 'treeHierarchyId',
    parentId: 'treeHierarchyParentId',
  });

export const assignedDatasetsSelector = data => data.pdfPageDataUsage || [];
export const dataModelIdSelector = data => data.dataModelId;
export const selectedFormDetailsTabSelector = data => data.selectedFormDetailsTab;
export const selectedAvailableDatasetsSelector = data => data.selectedAvailableDatasets;
export const selectedAssignedDatasetsSelector = data => data.selectedAssignedDatasets;

export const getDatasetsToAssign = (persistedPdfPageDataUsage, pdfPageDataUsageToSave) =>
  differenceBy(pdfPageDataUsageToSave, persistedPdfPageDataUsage, 'treeHierarchyId').filter(
    dataset => dataset.type === PdfPageDataUsageTypes.DATASET_USAGE,
  );

export const getDatasetsToUnassign = (persistedPdfPageDataUsage, pdfPageDataUsageToSave) =>
  differenceBy(persistedPdfPageDataUsage, pdfPageDataUsageToSave, 'treeHierarchyId').filter(
    dataset => dataset.type === PdfPageDataUsageTypes.DATASET_USAGE,
  );
