import { apiAction } from '../../../shared/store/apiAction';
import * as api from '../store/api';
import { fetchInfoAccounts as fetchInfoAccountsApi } from '../../../shared/store/api';

import { fetchInfoAccountsTypes, createInfoAccountTypes, updateInfoAccountTypes } from './types';

export const fetchInfoAccounts = apiAction({
  types: fetchInfoAccountsTypes,
  apiAction: fetchInfoAccountsApi,
});

export const createInfoAccount = apiAction({
  types: createInfoAccountTypes,
  apiAction: api.createInfoAccount,
});

export const updateInfoAccount = apiAction({
  types: updateInfoAccountTypes,
  apiAction: api.updateInfoAccount,
});
