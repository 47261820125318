import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from '../../shared/reports/reportParam.module.scss';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import ReportParamMultiSelect from '../../shared/reports/reportParamMultiSelect.component';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useReportParam from '../../shared/reports/useReportParam.hook';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import { entityIdSelector, taxYearSelector, periodSelector } from '../../shared/store/selectors';
import useReportData from '../../shared/reports/useReportData.hook';

import getColumnDefinitions from './priorYearComparisonReportSummary.columnDefinitions';

const PriorYearComparisonReportSummary = ({ taxYear, period, contextEntityId }) => {
  const endpointBasePath = '/api/entities/prior-year-comparison-report-summary';
  const columnDefinitions = getColumnDefinitions({ taxYear });

  const taxYearParam = useReportParam({
    endpointBasePath,
    paramName: 'taxYear',
    params: useMemo(() => ({}), []),
    defaultParamValue: taxYear,
  });

  const periodParam = useReportParam({
    endpointBasePath,
    paramName: 'period',
    defaultParamValue: Number(period),
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
      }),
      [taxYearParam.paramValue],
    ),
  });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
    defaultParamValue: contextEntityId,
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([taxYearParam, periodParam, entityIdParam]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    method: 'POST',
    isFetchingAnyParam,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
        entityId: entityIdParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue, entityIdParam.paramValue],
    ),
  });

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamMultiSelect label="Entity" param={entityIdParam} />
          {runReportButton}
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
            />
          )}
        </div>
      </div>
    </>
  );
};

PriorYearComparisonReportSummary.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  contextEntityId: PropTypes.string,
};

export default connect(state => ({
  taxYear: taxYearSelector(state),
  period: periodSelector(state),
  contextEntityId: entityIdSelector(state),
}))(PriorYearComparisonReportSummary);
