import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';

import Loading from '../shared/displayComponents/loading.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import DraggableLists from '../shared/displayComponents/draggableLists/draggableLists.component';
import { SelectOptionPropTypes } from '../shared/forms/propTypes';
import { taxYearSelector, periodSelector } from '../shared/store/selectors';

import {
  fetchSourceCustomers,
  fetchDestinationCustomers,
  fetchTasksForReassignment,
  updateTasksAssignments,
} from './store/actions';
import {
  sourceCustomersOptionsSelector,
  isFetchingSourceCustomersSelector,
  destinationCustomersOptionsSelector,
  isFetchingDestinationCustomersSelector,
  tasksForReassignmentOptionsSelector,
  isFetchingTasksForReassignmentSelector,
  isUpdatingTasksAssignmentsSelector,
} from './store/selectors';

import './reassignTasksModal.styles.scss';

const ReassignTasksModal = ({
  hideModal,
  visible,
  taxYear,
  period,
  sourceCustomers,
  isFetchingSourceCustomers,
  destinationCustomers,
  isFetchingDestinationCustomers,
  tasksForReassignmentOptions,
  isFetchingTasksForReassignment,
  isUpdatingTasksAssignments,
  fetchSourceCustomers,
  fetchDestinationCustomers,
  fetchTasksForReassignment,
  updateTasksAssignments,
}) => {
  const [selectedSourceCustomerId, setSelectedSourceCustomerId] = useState(null);
  const onSourceCustomerChange = useCallback(
    option => setSelectedSourceCustomerId(option ? option.value : null),
    [setSelectedSourceCustomerId],
  );

  const [selectedDestinationCustomerId, setSelectedDestinationCustomerId] = useState(null);
  const onDestinationCustomerChange = useCallback(
    option => setSelectedDestinationCustomerId(option ? option.value : null),
    [setSelectedDestinationCustomerId],
  );

  const [listA, setListA] = useState([]);
  const [listB, setListB] = useState([]);
  useEffect(() => {
    setListA(tasksForReassignmentOptions);
    setListB([]);
  }, [tasksForReassignmentOptions]);

  useEffect(() => {
    if (visible) {
      setListA([]);
      setListB([]);
      setSelectedSourceCustomerId(null);
      setSelectedDestinationCustomerId(null);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      fetchSourceCustomers({ taxYear, period });
      fetchDestinationCustomers();
    }
  }, [fetchSourceCustomers, fetchDestinationCustomers, visible, taxYear, period]);

  useEffect(() => {
    if (selectedSourceCustomerId) {
      fetchTasksForReassignment({
        taxYear,
        period,
        customerId: selectedSourceCustomerId,
      });
    }
  }, [fetchTasksForReassignment, taxYear, period, selectedSourceCustomerId]);

  const saveChanges = useCallback(async () => {
    try {
      await updateTasksAssignments({
        customerId: selectedDestinationCustomerId,
        tasks: listB.map(task => task.details),
      });
      hideModal();
    } catch (error) {
      // eslint-disable-line no-empty
    }
  }, [updateTasksAssignments, hideModal, selectedDestinationCustomerId, listB]);

  return (
    <Modal
      className="reassign-tasks-modal"
      title="Reassign Tasks"
      closeAction={hideModal}
      visible={visible}
      submitText="Save"
      submitAction={saveChanges}
      submitDisabled={!selectedDestinationCustomerId || listB.length === 0}
      dismissText="Cancel"
      dismissAction={hideModal}
      isBusy={isUpdatingTasksAssignments}
    >
      <Loading isLoading={isFetchingSourceCustomers || isFetchingDestinationCustomers}>
        <div className="row">
          <div className="col-6">
            Reassign From User:
            <SDKCustomSelect
              className="sdk-custom-select"
              options={sourceCustomers}
              onChange={onSourceCustomerChange}
              value={selectedSourceCustomerId}
              virtualized
            />
          </div>
          <div className="col-6">
            Reassign To User:
            <SDKCustomSelect
              className="sdk-custom-select"
              options={destinationCustomers}
              onChange={onDestinationCustomerChange}
              value={selectedDestinationCustomerId}
              virtualized
            />
          </div>
        </div>
        <Loading isLoading={isFetchingTasksForReassignment}>
          {selectedSourceCustomerId && tasksForReassignmentOptions.length ? (
            <DraggableLists
              listA={listA}
              listB={listB}
              setListA={setListA}
              setListB={setListB}
              listATitle=""
              listBTitle=""
              moveToListBButtonText="Add All"
              moveToListAButtonText="Remove All"
            />
          ) : (
            <div className="no-tasks-message">No tasks to display.</div>
          )}
        </Loading>
      </Loading>
    </Modal>
  );
};

ReassignTasksModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  taxYear: PropTypes.string,
  period: PropTypes.string,
  sourceCustomers: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
  isFetchingSourceCustomers: PropTypes.bool.isRequired,
  destinationCustomers: PropTypes.arrayOf(SelectOptionPropTypes.isRequired).isRequired,
  isFetchingDestinationCustomers: PropTypes.bool.isRequired,
  tasksForReassignmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      details: PropTypes.shape({
        taxYear: PropTypes.number.isRequired,
        period: PropTypes.number.isRequired,
        taskId: PropTypes.string.isRequired,
        orgId: PropTypes.string.isRequired,
        jurisdictionId: PropTypes.string.isRequired,
        orgRuleId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  isFetchingTasksForReassignment: PropTypes.bool.isRequired,
  isUpdatingTasksAssignments: PropTypes.bool.isRequired,
  fetchSourceCustomers: PropTypes.func.isRequired,
  fetchDestinationCustomers: PropTypes.func.isRequired,
  fetchTasksForReassignment: PropTypes.func.isRequired,
  updateTasksAssignments: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    sourceCustomers: sourceCustomersOptionsSelector(state),
    isFetchingSourceCustomers: isFetchingSourceCustomersSelector(state),
    destinationCustomers: destinationCustomersOptionsSelector(state),
    isFetchingDestinationCustomers: isFetchingDestinationCustomersSelector(state),
    tasksForReassignmentOptions: tasksForReassignmentOptionsSelector(state),
    isFetchingTasksForReassignment: isFetchingTasksForReassignmentSelector(state),
    isUpdatingTasksAssignments: isUpdatingTasksAssignmentsSelector(state),
  }),
  {
    fetchSourceCustomers,
    fetchDestinationCustomers,
    fetchTasksForReassignment,
    updateTasksAssignments,
  },
)(ReassignTasksModal);
