import {
  defaultColumnDefinition,
  adminColumnDefinition,
  percentageColumnDefinition,
  selectCellEditorParamsFactory,
} from '../../shared/columnDefinitions';
import {
  AdminCellRendererFactory,
  NumberCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import { defaultAgRichSelectCellEditor } from '../../shared/columnDefinitions/cellEditor';

const CATEGORY_NAMES = [
  { field: 'ownerPercentage', name: 'Owner %' },
  { field: 'percentOwned', name: '% Owned' },
  { field: 'percentYearOwned', name: '% of Year Owned' },
  { field: 'percentEffectiveOwnership', name: 'Effective Own %' },
];

const defaultPercentageColumnDefinition = {
  ...defaultColumnDefinition,
  ...percentageColumnDefinition,
};

const partnershipTypeFormatter = (value, partnershipInfoTypes) => {
  const option = partnershipInfoTypes.find(option => option.value === value);
  return option && option.label;
};

export const getIndirectOwnershipColumnDefinitions = ({ partnershipInfoTypes }) => [
  {
    ...adminColumnDefinition,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Owner',
    field: 'ownerId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Name',
    field: 'partnershipName',
    sort: 'asc',
    width: 220,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'ID',
    field: 'partnershipId',
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Partnership Type',
    field: 'partnershipTypeCode',
    valueFormatter: ({ value }) => partnershipTypeFormatter(value, partnershipInfoTypes),
  },
  ...CATEGORY_NAMES.map(({ field, name }) => ({
    ...defaultPercentageColumnDefinition,
    headerName: name,
    field,
  })),
];

export const getDirectOwnershipColumnDefinitions = ({
  isInEditMode,
  updateRow,
  partnershipInfoTypes,
  onDeleteIconClick,
}) => {
  const onCellValueChanged = ({ data }) => updateRow(data);
  const AdminCellRenderer = AdminCellRendererFactory({
    onDeleteIconClick,
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: isInEditMode,
  });

  const columnDefinitions = [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Owner',
      field: 'ownerId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Name',
      field: 'partnershipName',
      sort: 'asc',
      width: 220,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'ID',
      field: 'partnershipId',
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Partnership Type',
      field: 'partnershipTypeCode',
      valueFormatter: ({ value }) => partnershipTypeFormatter(value, partnershipInfoTypes),
      editable: isInEditMode,
      ...defaultAgRichSelectCellEditor,
      cellEditorParams: selectCellEditorParamsFactory(partnershipInfoTypes),
      onCellValueChanged,
    },
    ...CATEGORY_NAMES.map(({ field, name }) => ({
      ...defaultPercentageColumnDefinition,
      headerName: name,
      field,
      editable: isInEditMode,
      cellEditor: NumberCellEditor,
      onCellValueChanged,
      cellEditorParams: {
        formatValue: value => parseFloat(value),
        min: 0,
        max: 100,
      },
    })),
  ];
  return columnDefinitions;
};
