import PropTypes from 'prop-types';

export const selectionListItemPropTypes = PropTypes.shape({
  item: PropTypes.string,
  description: PropTypes.string,
  sortOrder: PropTypes.number,
});

export const selectionListPropTypes = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.string,
  selectionListItems: PropTypes.arrayOf(selectionListItemPropTypes),
});
