import React, { useCallback } from 'react';
import { Field } from 'formik';

import {
  ReturnOption,
  FindManualFederalProformaSubmissionIdOptionsResponse,
} from '../../../../../../../../common/types';
import { WizardStateMachineSetFederalSubmissionId } from '../../../../wizardStateMachine/types';
import { FederalReturnOption } from '../../../../../../shared/enums/eFileV2';

import RadioCardsGroup from './radioCardsGroup.component';

interface EditReturnFilingFormProps {
  setFederalReturn: (federalReturnOption: FederalReturnOption | null) => void;
  generatedFederalReturnOptions: (
    | ReturnOption
    | {
        federalId: string | null;
        label: string;
        sublabel: string;
        value: FederalReturnOption;
        disabled: boolean;
      }
    | null
  )[];
  wizardStateMachineSetFederalSubmissionId: WizardStateMachineSetFederalSubmissionId | null;
  federalReturnOption: FederalReturnOption | null;
  setFederalSubmissionId: (submissionId: WizardStateMachineSetFederalSubmissionId) => void;
  manualFederalProformaSubmissionIdOptions: FindManualFederalProformaSubmissionIdOptionsResponse;
}

const EditReturnFilingForm = ({
  setFederalReturn,
  generatedFederalReturnOptions,
  wizardStateMachineSetFederalSubmissionId,
  federalReturnOption,
  setFederalSubmissionId,
  manualFederalProformaSubmissionIdOptions,
}: EditReturnFilingFormProps) => {
  const onChange = useCallback(
    (federalReturnOption: FederalReturnOption | null) => {
      if (!federalReturnOption) {
        return;
      }

      setFederalReturn(federalReturnOption);
    },
    [setFederalReturn],
  );

  return (
    <form>
      <Field
        name="federalReturnOption"
        component={RadioCardsGroup}
        options={generatedFederalReturnOptions}
        onChange={onChange}
        wizardStateMachineSetFederalSubmissionId={wizardStateMachineSetFederalSubmissionId}
        federalReturnOption={federalReturnOption}
        setFederalSubmissionId={setFederalSubmissionId}
        manualFederalProformaSubmissionIdOptions={manualFederalProformaSubmissionIdOptions}
      />
    </form>
  );
};

export default EditReturnFilingForm;
