import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';

import { fetchManualAdjustmentReportDataTypes } from './types';

export const initialState = {
  manualAdjustmentReportData: [],
  isFetchingManualAdjustmentReportData: false,
};

const manualAdjustmentReportReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchManualAdjustmentReportDataTypes,
      payloadKey: 'manualAdjustmentReportData',
      fetchingKey: 'isFetchingManualAdjustmentReportData',
    }),
  },
});

export default manualAdjustmentReportReducer;
