import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import Page from '../../shared/displayComponents/page.component';
import { isFetchingGlobalContextSelector } from '../../shared/store/selectors';
import { GlobalContextDropdowns } from '../../shared/containerComponents/globalContext';

import ReportsBreadcrumbRoutes from './reportsBreadcrumbRoutes.component';

const ReportPage = props => (
  <Page
    breadcrumbRoutes={ReportsBreadcrumbRoutes}
    globalContextDropdowns={<GlobalContextDropdowns isFetchingContext={props.isFetchingContext} />}
    {...props}
  />
);

ReportPage.propTypes = {
  isFetchingContext: PropTypes.bool.isRequired,
};

const ConnectedReportPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(ReportPage);

const ReportRoute = props => <AuthorizedCustomRoute wrapper={ConnectedReportPage} {...props} />;

export default ReportRoute;
