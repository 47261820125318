import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SelectOption } from '@tls/slt-types';

import { GlobalContext, GlobalK1InfoData } from '../../../../common/types';
import { useRowEditMode } from '../../shared/editMode';
import { useMutationUpdateGlobalK1Info } from '../../shared/mutations/globalK1Information';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { globalContextSelector } from '../../shared/store/selectors';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../../shared/columnDefinitions/cellRenderers';
import { useQueryFilterData } from '../../shared/queries/k1InformationV2';
import { useQueryGlobalK1Information } from '../../shared/queries/globalK1Information';
import Loading from '../../shared/displayComponents/loading.component';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';

import getColumnDefinitions from './globalK1Information.columnDefinitions';
import styles from './globalK1Information.module.scss';

interface GlobalK1InfoUpdates {
  rowsPairsWithChanges: [{ oldRow: GlobalK1InfoData; newRow: GlobalK1InfoData }];
}

const getUniqueRowId = ({ data: { infoAccountId } }: { data: { infoAccountId: string } }) =>
  infoAccountId;

const GlobalK1Information = ({
  hasUserPermissionsToEdit,
}: {
  hasUserPermissionsToEdit: boolean;
}) => {
  const { mutateAsync: updateGlobalK1Info } = useMutationUpdateGlobalK1Info();

  const globalContext: GlobalContext = useSelector(globalContextSelector);

  const { taxYear, period, jurisdictionId, businessEntityId } = globalContext.params;

  const [selectedFormValue, setSelectedForm] = useState<string | null>(null);
  const [selectedPartnerValue, setSelectedPartner] = useState<string | null>(null);

  const {
    data: { partnerListOptions, formListOptions } = {},
    isFetching: isFetchingFilterData,
  } = useQueryFilterData({
    params: {
      taxYear,
      period,
      entityId: businessEntityId,
      jurisdictionId,
      dataModelId: selectedFormValue,
      partnerId: selectedPartnerValue,
    },
    enabled: globalContext.isReady,
    onSuccess: ({
      formListOptions,
      partnerListOptions,
    }: {
      formListOptions: SelectOption[];
      partnerListOptions: SelectOption[];
    }) => {
      if (formListOptions?.length) {
        setSelectedForm(
          formListOptions.some(form => form.value === selectedFormValue)
            ? selectedFormValue
            : formListOptions[0].value,
        );
      }
      if (partnerListOptions?.length) {
        setSelectedPartner(
          partnerListOptions.some(partner => partner.value === selectedPartnerValue)
            ? selectedPartnerValue
            : partnerListOptions[0].value,
        );
      }
    },
  });

  const { data: formFields, isFetching: isFetchingFormFields } = useQueryGlobalK1Information({
    params: {
      period,
      businessEntityId,
      dataModelId: selectedFormValue,
      partnerId: selectedPartnerValue,
    },
    enabled: Boolean(period && businessEntityId && selectedFormValue && selectedPartnerValue),
  });

  const saveChanges = useCallback(
    async (updates: GlobalK1InfoUpdates) => {
      const values = updates.rowsPairsWithChanges.map(
        (changes: { newRow: GlobalK1InfoData }) => changes.newRow,
      );

      await updateGlobalK1Info({
        taxYear,
        period,
        businessEntityId,
        values,
        jurisdictionId,
      });
    },
    [updateGlobalK1Info, taxYear, period, businessEntityId, jurisdictionId],
  );

  const isLoading = isFetchingFilterData || isFetchingFormFields;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: formFields,
    getUniqueRowId,
    editButtonDisabled: isLoading,
    saveButtonDisabled: false,
  });

  const columnDefinitions = useMemo(() => getColumnDefinitions({ isInEditMode, updateRow }), [
    isInEditMode,
    updateRow,
  ]);

  const onFormChange = useCallback(
    ({ value }: { value: string | null }) => setSelectedForm(value),
    [setSelectedForm],
  );
  const onPartnerChange = useCallback(
    ({ value }: { value: string | null }) => setSelectedPartner(value),
    [setSelectedPartner],
  );

  return (
    <>
      {navigationPrompt}
      <div className={styles.flexSpaceBetween}>
        <div className={styles.formListDropdown}>
          <div>
            <SDKCustomSelect
              className="sdk-custom-select"
              virtualized
              appkitLabel="Form Name"
              options={formListOptions}
              value={selectedFormValue}
              onChange={onFormChange}
              isLoading={isFetchingFilterData}
            />
          </div>
        </div>
        <div className={styles.formListDropdown}>
          <div>
            <SDKCustomSelect
              className="sdk-custom-select"
              virtualized
              appkitLabel="Partner"
              options={partnerListOptions}
              value={selectedPartnerValue}
              onChange={onPartnerChange}
              isLoading={isFetchingFilterData}
            />
          </div>
        </div>
        {hasUserPermissionsToEdit && <div className="add-button-column">{editModeButtons}</div>}
      </div>
      <div className="row grid-row">
        <div className="col">
          <div className={styles.tableHeaderText}>
            Changes saved here are applied as an override.
          </div>
          <div className={styles.tableHeaderText}>
            View/enter SSN for individuals on the Entities Maintenance screen, Individuals tab.
          </div>
          <Loading isLoading={isLoading}>
            <AgGrid
              rowData={clonedRowData}
              columnDefs={columnDefinitions}
              singleClickEdit
              suppressCellSelection={!isInEditMode}
              onGridReady={onGridReady}
              components={{
                DateCellEditor,
                NumberCellEditor,
                TextCellEditor,
              }}
              withSearchBar
              stopEditingWhenCellsLoseFocus
            />
          </Loading>
        </div>
      </div>
    </>
  );
};

export default GlobalK1Information;
