import { HttpMethods } from '../enums';
import { QueryKeys, QueryError } from '../queries';
import { GlobalMemberInfoData } from '../../../../common/types';

import { useCustomMutation } from '.';

interface UpdateGlobalMemberInformationalDataRequest {
  taxYear: string | null;
  period: string | null;
  consolidationId?: string | null;
  jurisdictionId: string | null;
  values: GlobalMemberInfoData[];
}
export const useMutationUpdateGlobalMemberInformationalData = () =>
  useCustomMutation<
    null,
    QueryError,
    UpdateGlobalMemberInformationalDataRequest,
    GlobalMemberInfoData[]
  >({
    handleData: ({ taxYear, period, consolidationId, values, jurisdictionId }) => ({
      url: `/api/shared/global-member-informational-data/${taxYear}/${period}/${consolidationId}/${jurisdictionId}`,
      method: HttpMethods.PUT,
      body: values,
    }),
    resetKey: QueryKeys.GlobalMemberInformationalData.Fields,
    successMessage: 'Updating global member informational data succeeded',
    errorMessage: 'Updating global member informational data failed',
  });
