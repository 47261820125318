const getClassStringWithoutClassName = (classString: string, className: string): string => {
  const classes = classString.split(' ');
  const classIndex = classes.indexOf(className);
  if (classIndex > -1) {
    classes.splice(classIndex, 1);
    // recursively removing all occurrences
    return getClassStringWithoutClassName(classes.join(' '), className);
  } else {
    return classString;
  }
};

export default getClassStringWithoutClassName;
