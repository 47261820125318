import React from 'react';
import { useFormikContext } from 'formik';

import {
  JurisdictionId,
  CalcPreferencesFormikValues,
  CalcPreferenceDefinitions,
} from '../../../../../common/types';

import styles from './calcPreferences.module.scss';
import SelectPreferences from './selectPreferences.component';
import CheckboxPreferences from './checkboxPreferences.component';

interface FormValuesWithoutCalcOptions {
  jurisdictionId: JurisdictionId;
}

type FormValues = FormValuesWithoutCalcOptions &
  CalcPreferencesFormikValues & {
    [key: string]: CalcPreferencesFormikValues;
  };

interface CalcPreference {
  selectOptions: Record<string, CalcPreferenceDefinitions>;
}

interface CalcPreferencesProps {
  calcPreferences?: CalcPreference;
  title?: string;
  renderAsCheckbox?: boolean;
  hasFormPanelStyle?: boolean;
  calcOptionsKey?: string;
  customChangeHandler?: (name: string) => unknown;
}

const CalcPreferences = ({
  calcPreferences,
  title = '',
  renderAsCheckbox = false,
  hasFormPanelStyle = false,
  calcOptionsKey = '', // https://formik.org/docs/guides/arrays#nested-objects
  customChangeHandler = () => null,
}: CalcPreferencesProps) => {
  const { values, initialValues } = useFormikContext<FormValues>();

  const selectOptions = calcPreferences?.selectOptions || {};
  const sortedSelectOptions = Object.entries(selectOptions).sort((a, b) =>
    a[0].localeCompare(b[0]),
  );

  const getComponentToRender = ({
    columnLabel,
    options,
  }: {
    columnLabel: string;
    options: CalcPreferenceDefinitions;
  }) => {
    if (renderAsCheckbox) {
      return <CheckboxPreferences key={columnLabel} options={options} columnLabel={columnLabel} />;
    }

    return (
      <SelectPreferences
        key={columnLabel}
        columnLabel={columnLabel}
        options={options}
        values={calcOptionsKey ? values[calcOptionsKey] : values}
        name={calcOptionsKey ? `${calcOptionsKey}.${columnLabel}` : columnLabel}
        jurisdictionId={initialValues.jurisdictionId || values.jurisdictionId}
        calcOptionsKey={calcOptionsKey}
        customChangeHandler={customChangeHandler}
      />
    );
  };

  const elementsToRender = () =>
    sortedSelectOptions
      .map(
        ([columnLabel, options]) =>
          options.some(
            ({ jurisdictionId }) =>
              initialValues.jurisdictionId === jurisdictionId ||
              values.jurisdictionId === jurisdictionId,
          ) && getComponentToRender({ columnLabel, options }),
      )
      .filter(Boolean);

  const elements = elementsToRender();

  if (title && elements.length) {
    return hasFormPanelStyle ? (
      <>
        <h6>{title}</h6>
        <div className={styles.calcPreferencesFormPanel}>{elements}</div>
      </>
    ) : (
      <div className={styles.calcPreferencesBox}>
        <h6>{title}</h6>
        <div className={styles.calcPreferencesList}>{elements}</div>
      </div>
    );
  }

  return <>{elements}</>;
};

export default CalcPreferences;
