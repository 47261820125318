import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { globalContextSelector } from '../../../shared/store/selectors';
import {
  useQueryTtiBinders,
  useQueryGetCheckTtiServiceRunning,
} from '../../../shared/queries/eFileV2';
import { useQuerySltBinderOptions } from '../../../shared/queries/binders';
import { useQueryTtiCaseId, useQueryTtiYearId } from '../../../shared/queries/eFile';

const useBinder = (enabledTti = false) => {
  const globalContext = useSelector(globalContextSelector);
  const { returnId } = useParams<{ returnId?: string }>();

  const { taxYear, period, jurisdictionId } = globalContext.params;
  const { apiRouteParamString, isReady: isGlobalContextReady } = globalContext;

  const sltBinders = useQuerySltBinderOptions({
    params: apiRouteParamString,
    enabled: isGlobalContextReady,
  });

  const sltBinderId = useMemo(() => parseInt(returnId || '', 10), [returnId]);

  const { data: isTtiServiceRunningData } = useQueryGetCheckTtiServiceRunning({
    enabled: enabledTti,
    params: { taxYear },
  });

  const { data: ttiCaseData } = useQueryTtiCaseId({
    params: { taxYear, jurisdictionId },
    enabled: Boolean(
      enabledTti && taxYear && jurisdictionId && isTtiServiceRunningData?.isTtiServiceRunning,
    ),
  });

  const { data: ttiYearId } = useQueryTtiYearId({
    params: { taxYear, period },
    enabled: Boolean(
      enabledTti && taxYear && period && isTtiServiceRunningData?.isTtiServiceRunning,
    ),
  });

  const { data: ttiBinders } = useQueryTtiBinders({
    params: { ...globalContext, sltBinderId },
    enabled: Boolean(isGlobalContextReady && sltBinderId),
  });

  return {
    sltBinders,
    ttiCaseData,
    ttiYearId,
    ttiBinders,
    sltBinderId,
    ttiBinderId: ttiBinders?.[0]?.ttiBinderId,
    binderFilingId: ttiBinders?.[0]?.binderFilingId,
  };
};

export default useBinder;
