import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import { BreadcrumbRoute } from '../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

import FilingStates from './filingStates.container';

const FilingStatesWithContext = withContextWrapper({ isJurisdictionRequired: false })(FilingStates);

const FilingStatesBreadcrumbRoute = () => (
  <>
    <BreadcrumbRoute path={Routes.filingStates} />
  </>
);

const FilingStatesPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: FilingStatesBreadcrumbRoute,
}))(GlobalContextPage);

const FilingStatesRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.filingStates.MAIN}
    component={FilingStatesWithContext}
    wrapper={FilingStatesPage}
    hideGlobalContext
  />
);

export default FilingStatesRoute;
