const Yup = require('yup');

const commonSchema = Yup.array().of(
  Yup.object().shape({
    displayOrder: Yup.number().required(),
    itemId: Yup.string().required(),
    itemType: Yup.string().required(),
  }),
);

const updateBinderItemsOrderSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.string().required,
    displayOrder: Yup.number().required(),
  }),
);

module.exports = {
  commonSchema,
  updateBinderItemsOrderSchema,
};
