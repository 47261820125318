import { useEffect } from 'react';

import useFetch from '../../shared/hooks/useFetch.hook';
import { httpGetAndParse } from '../../utils/fetchService';

const action = ({ taxYear, period, taxType, entityId, jurisdictionId }) =>
  httpGetAndParse({
    route: `/api/shared/tax-rate-details/${taxYear}/${period}/${taxType}/${entityId}/${jurisdictionId}`,
    errorMessage: 'Fetching tax rate details failed',
  });

export const createUseTaxRateDetailsHook = useFetch => {
  const useTaxRateDetails = ({
    taxYear,
    period,
    taxType,
    entityId,
    jurisdictionId,
    isContextReady,
  }) => {
    const {
      data: taxRateDetails,
      isFetching: isFetchingTaxRateDetails,
      fetch: fetchTaxRateDetails,
    } = useFetch({ action });

    useEffect(() => {
      if (isContextReady) {
        fetchTaxRateDetails({ taxYear, period, taxType, entityId, jurisdictionId });
      }
    }, [fetchTaxRateDetails, isContextReady, taxYear, period, entityId, jurisdictionId, taxType]);

    return {
      taxRateDetails,
      isFetchingTaxRateDetails,
    };
  };
  return useTaxRateDetails;
};

export default createUseTaxRateDetailsHook(useFetch);
