import { SelectOption } from '@tls/slt-types';

import { DataTypes } from '../../../../common/types/enums';

import { QueryKeys, useCustomQuery } from '.';

interface QueryFormFieldsParams {
  period: string | null;
  businessEntityId: string;
  formId: string;
  memberId: string;
}

interface QueryMembersParams {
  period: string | null;
  businessEntityId?: string | null;
  formId: string | null;
}

interface QueryMemberInfoDataFormsParams {
  globalContext: {
    apiRouteParamString: string;
  };
}

interface AliasInfoAccount {
  aliasName: string;
  checkboxGroupId?: string | null;
  context?: {
    taxYear?: number | null;
    shortYearId?: number | null;
    jurisdictionId?: string | null;
    orgId?: string | null;
    consolId?: string | null;
    binderId?: string | null;
    jobQueueId?: string | null;
    options?: string | null;
    partnerId?: string | null;
    requestInitiatedBy?: string | null;
  } | null;
  dataItemDataType: number;
  dataItemDefinitionId: string;
  dataType: DataTypes;
  datasetDefinitionId: string;
  datasetInstanceId?: string | null;
  description?: string | null;
  isAccountGlobal: boolean;
  isTripletGlobal: boolean;
  page?: number | null;
  rowId?: number | null;
  selectionListId?: string | null;
  selectionListItems?: string | null;
  sortOrder?: number | null;
  value: string;
}

export const useQueryMemberInfoDataForms = ({
  params,
  enabled,
  onSuccess,
}: {
  enabled: boolean;
  params: QueryMemberInfoDataFormsParams;
  onSuccess: (forms: SelectOption[]) => void;
}) =>
  useCustomQuery<SelectOption[]>([QueryKeys.MemberInformationalData.Forms, params], {
    url: `/api/shared/member-informational-data/forms/${params.globalContext.apiRouteParamString}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching forms failed',
    onSuccess,
  });

export const useQueryMemberInfoDataFormFields = ({
  params,
  enabled,
}: {
  enabled: boolean;
  params: QueryFormFieldsParams;
}) =>
  useCustomQuery<AliasInfoAccount[]>([QueryKeys.MemberInformationalData.Fields, params], {
    url: `/api/shared/member-informational-data/${params.period}/${params.businessEntityId}/${
      params.formId
    }${params.memberId ? `?memberId=${params.memberId}` : ''}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching fields failed',
  });

export const useQueryMembers = ({
  params,
  enabled,
  onSuccess,
}: {
  enabled: boolean;
  params: QueryMembersParams;
  onSuccess: (members: SelectOption[]) => void;
}) =>
  useCustomQuery<SelectOption[]>([QueryKeys.MemberInformationalData.Members, params], {
    url: `/api/shared/member-informational-data/members/${params.period}/${params.businessEntityId}/${params.formId}`,
    enabled,
    defaultData: [],
    errorMessage: 'Fetching members failed',
    onSuccess,
  });
