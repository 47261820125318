import { getApiActionTypes } from '../../../shared/store/storeHelpers';

export const fetchDirectPartnershipInfoTypes = getApiActionTypes('FETCH_DIRECT_PARTNERSHIP_INFO');
export const fetchIndirectPartnershipInfoTypes = getApiActionTypes(
  'FETCH_INDIRECT_PARTNERSHIP_INFO',
);
export const addPartnershipInfoTypes = getApiActionTypes('ADD_PARTNERSHIP');
export const updateOrDeletePartnershipsInfoTypes = getApiActionTypes(
  'UPDATE_OR_DELETE_PARTNERSHIPS_INFO',
);
export const fetchPartnershipInfoTypes = getApiActionTypes('FETCH_PARTNERSHIP_TYPES');
export const fetchPartnershipsTypes = getApiActionTypes('FETCH_PARTNERSHIPS');
