import CustomOptionSelector from './cellRenderers/customOptionSelector.component';

export const defaultDateCellEditor = {
  component: 'DateCellEditor',
  popup: true,
};

export const defaultAgRichSelectCellEditorSelector = {
  component: 'agRichSelectCellEditor',
  popup: true,
};

export const defaultAgRichSelectCellEditor = {
  cellEditor: 'agRichSelectCellEditor',
  cellEditorPopup: true,
};

export const defaultCustomOptionCellSelector = {
  component: CustomOptionSelector,
  popup: true,
};
