import React from 'react';
import { Tooltip } from '@pwc/appkit-react';

import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

import { INCLUDED_FLAG_FIELD, DATE_ACQUIRED_FIELD, DATE_INCORPORATED } from './constants';

const extendedDefaultColumnDefinition = {
  ...defaultColumnDefinition,
  suppressMenu: false,
};

const getColumnDefinitions = ({ isInEditMode, updateRow, ToggleAllSelectionsHeader }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  // judge if display filing groups tooltip if member included in other groups used in one consol
  const shouldDisplayFilingGroupsTooltipForIncluded = params =>
    !params.value && params.data.memberUsedInOtherFilingGroups?.length;

  const isIncludedDisabled = params =>
    !isInEditMode || shouldDisplayFilingGroupsTooltipForIncluded(params);

  const columnDefinitions = [
    {
      ...extendedDefaultColumnDefinition,
      headerComponent: ToggleAllSelectionsHeader,
      headerName: 'Included',
      field: INCLUDED_FLAG_FIELD,
      colId: INCLUDED_FLAG_FIELD,
      width: 60,
      onCellValueChanged,
      cellRenderer: params => {
        if (params.data.organizationalType === 'B' && !params.data.includedFlag) {
          return '';
        }
        const checkboxCell = EditableCheckboxCellRenderer(params);
        return shouldDisplayFilingGroupsTooltipForIncluded(params) ? (
          <Tooltip
            key={`tooltip-for-used-members-${params.data.entityId}`}
            content={params.data.memberUsedInOtherFilingGroups?.map((filingGroupName, index) => (
              <p key={`filing-group-used-${params.data.entityId}-${index}`}>{filingGroupName}</p>
            ))}
            placement="top"
            className="offset-for-grid"
          >
            {checkboxCell}
          </Tooltip>
        ) : (
          checkboxCell
        );
      },
      cellRendererParams: {
        isDisabled: isIncludedDisabled,
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Filing Group',
      field: 'filingGroupId',
      hide: true,
      getQuickFilterText: () => '',
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Entity ID',
      field: 'entityId',
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Entity Name',
      field: 'entityName',
      width: 300,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Organizational Type',
      field: 'organizationalType',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'FEIN',
      field: 'fein',
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Business',
      field: 'businessDescription',
      width: 300,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Federal Filing Type',
      field: 'fedFilingtype',
      width: 200,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Country of Incorporation',
      field: 'countryOfInc',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Acquisition Date',
      field: DATE_ACQUIRED_FIELD,
    },
    {
      ...extendedDefaultColumnDefinition,
      headerName: 'Incorporated Date ',
      field: DATE_INCORPORATED,
    },
  ];
  return columnDefinitions;
};

export default getColumnDefinitions;
