import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import {
  globalContextSelector,
  isFetchingGlobalContextSelector,
  periodNameSelector,
  businessEntityNameSelector,
  jurisdictionDescriptionSelector,
  isFetchingJurisdictionsSelector,
  filingTypeDescriptionSelector,
  isGlobalContextReadySelector,
} from '../../../../shared/store/selectors';
import { isFetchingFilingStatesSelector } from '../../../../filingStates/store/selectors';
import Loading from '../../../../shared/displayComponents/loading.component';
import { StepTabHeader } from '../stepTab';
import { FederalReturnOption, TransmitterProvider } from '../../../../shared/enums/eFileV2';
import { QueryKeys } from '../../../../shared/queries';
import {
  useQueryGetSubmissionsHistory,
  useQueryGetTRStatusRejectionMessages,
  useQueryGetTransmissionStatus,
} from '../../../../shared/queries/eFileV2';
import { WizardStateMachineSetFederalSubmissionId } from '../../wizardStateMachine/types';
import { useGetFederalReturn, useGetManualFederalProformaSubmissionIdOptions } from '../../hooks';
import formatDate from '../../utils/formatDate';
import { SubmissionId, TaxReturnResult, BinderFilingId } from '../../../../../../common/types';
import TransmissionSelect from '../step5Transmit/viewStatusStepTab/transmissionSelect.component';
import viewStatusStepTabstyles from '../step5Transmit/viewStatusStepTab/viewStatusStepTab.module.scss';

import styles from './federalReturnStep.module.scss';
import ReturnRequirements from './returnRequirements/returnRequirements.component';

interface FederalReturnStepProps {
  federalReturnOption: FederalReturnOption | null;
  setFederalReturnOption: (federalReturnOption: FederalReturnOption | null) => void;
  setFederalSubmissionId: (submissionId: WizardStateMachineSetFederalSubmissionId) => void;
  filingFormName: string | null;
  wizardStateMachineSetFederalSubmissionId: WizardStateMachineSetFederalSubmissionId | null;
  efSubmissionId: SubmissionId | null;
  selectedTtiSubmissionId: string | null;
  returnId: number;
  taxReturn: TaxReturnResult | undefined;
  selectedTransmissionHistoryId: string;
  binderFilingId?: BinderFilingId;
  setSelectedTransmissionHistoryId: (id: string) => void;
}

const FederalReturnStep = ({
  setFederalReturnOption,
  setFederalSubmissionId,
  federalReturnOption,
  filingFormName,
  wizardStateMachineSetFederalSubmissionId,
  efSubmissionId,
  selectedTtiSubmissionId,
  returnId,
  taxReturn,
  selectedTransmissionHistoryId,
  setSelectedTransmissionHistoryId,
  binderFilingId,
}: FederalReturnStepProps) => {
  const globalContext = useSelector(globalContextSelector);
  const isGlobalContextReady = useSelector(isGlobalContextReadySelector);
  const isFetchingGlobalContext = useSelector(isFetchingGlobalContextSelector);

  const periodName = useSelector(periodNameSelector);
  const businessEntityName = useSelector(businessEntityNameSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);
  const isFetchingJurisdictions = useSelector(isFetchingJurisdictionsSelector);

  const isFetchingFilingStates = useSelector(isFetchingFilingStatesSelector);
  const filingType = useSelector(filingTypeDescriptionSelector);

  const { federalReturn, isFetchingFederalReturn } = useGetFederalReturn();
  const {
    manualFederalProformaSubmissionIdOptions,
    isFetchingFederalProformaSubmissionIdOption,
  } = useGetManualFederalProformaSubmissionIdOptions();

  const {
    data: efileTrRejectionMessages,
    isFetching: isFetchingEFileTrRejectionMessages,
  } = useQueryGetTRStatusRejectionMessages({
    params: { returnId, transmissionHistoryId: selectedTransmissionHistoryId },
    enabled:
      isGlobalContextReady &&
      Boolean(returnId) &&
      Boolean(selectedTransmissionHistoryId) &&
      taxReturn?.selectedTransmitter === TransmitterProvider.TR,
  });

  const trRejectionErrors = efileTrRejectionMessages || [];

  const queryClient = useQueryClient();
  const {
    data: submissions,
    isFetched: isSubmissionsHistoryFetched,
  } = useQueryGetSubmissionsHistory({
    params: {
      binderFilingId,
      returnId: returnId || parseInt('0', 10),
    },
    enabled: Boolean(binderFilingId && returnId),
    onSuccess: transmissionData => {
      if (transmissionData && transmissionData.length > 0) {
        setSelectedTransmissionHistoryId(transmissionData[0].id);
        queryClient.resetQueries([QueryKeys.EFileSupport.EFileBinderFilingsData]);
      }
    },
  });

  const isGetTransmissionStatusQueryEnabled =
    Boolean(binderFilingId) &&
    isGlobalContextReady &&
    Boolean(returnId) &&
    isSubmissionsHistoryFetched &&
    Boolean(selectedTransmissionHistoryId);

  const {
    data: transmissionStatusData,
    isFetching: isFetchingTransmissionStatus,
  } = useQueryGetTransmissionStatus({
    params: {
      binderFilingId: String(binderFilingId),
      globalContextApiRouteParamString: globalContext.apiRouteParamString,
      sltBinderId: String(returnId),
      transmissionHistoryId: selectedTransmissionHistoryId,
      isSeparateYN: globalContext.isSeparateYN,
    },
    enabled: Boolean(isGetTransmissionStatusQueryEnabled),
  });

  const selectedTransmissionStatusData = transmissionStatusData || null;

  const lastUpdatedDate = useMemo(
    () =>
      selectedTransmissionStatusData?.date
        ? formatDate(selectedTransmissionStatusData.date)
        : 'Date not found',
    [selectedTransmissionStatusData],
  );

  return (
    <>
      <StepTabHeader
        title="Filing for the following"
        className={styles.stepTabHeaderContainer}
        headerTitleClassName={styles.headerTitle}
      >
        <div className={`row grid-row ${styles.filingHeader}`}>
          <div className={styles.contextCol}>
            <span>
              <b>Tax Year: </b>
              <Loading small isLoading={isFetchingGlobalContext}>
                {globalContext.taxYear}
              </Loading>
            </span>
            <span>
              <b>Period: </b>
              <Loading small isLoading={isFetchingGlobalContext}>
                {periodName}
              </Loading>
            </span>
            <div>
              <span>
                <b>Entity: </b>
                <Loading small isLoading={isFetchingGlobalContext}>
                  {businessEntityName}
                </Loading>
              </span>
            </div>
          </div>
          <div className={styles.contextCol}>
            <span>
              <b>Filing Type: </b>
              <Loading small isLoading={isFetchingGlobalContext}>
                {filingType}
              </Loading>
            </span>
            <span className={styles.jurisdiction}>
              <b>Jurisdiction: </b>
              <Loading
                small
                isLoading={
                  isFetchingGlobalContext || isFetchingFilingStates || isFetchingJurisdictions
                }
              >
                {jurisdictionDescription?.toLowerCase()}
              </Loading>
            </span>
            <span>
              <b>Return: </b>
              <Loading small isLoading={isFetchingFederalReturn}>
                {filingFormName}
              </Loading>
            </span>
          </div>
          {submissions && submissions.length > 0 && (
            <>
              <div className={styles.pastSubmissionsColumn}>
                <div className={viewStatusStepTabstyles.pastSubmissions}>
                  <div className={viewStatusStepTabstyles.transmissionsSelectContainer}>
                    <label>PAST SUBMISSIONS:</label>
                    <TransmissionSelect
                      className={viewStatusStepTabstyles.transmissionSelect}
                      submissions={submissions}
                      selectedTransmissionHistoryId={selectedTransmissionHistoryId}
                      setSelectedTransmissionHistoryId={setSelectedTransmissionHistoryId}
                    />
                  </div>
                  <Loading isLoading={isFetchingTransmissionStatus}>
                    <span>Last updated: {lastUpdatedDate}</span>
                  </Loading>
                </div>
              </div>
            </>
          )}
        </div>
      </StepTabHeader>
      <div className={styles.returnRequirementsContainer}>
        <Loading
          isLoading={
            !globalContext.isReady ||
            isFetchingFederalReturn ||
            isFetchingFederalProformaSubmissionIdOption
          }
        >
          <ReturnRequirements
            setFederalReturnOption={setFederalReturnOption}
            setFederalSubmissionId={setFederalSubmissionId}
            federalReturnOption={federalReturnOption}
            wizardStateMachineSetFederalSubmissionId={wizardStateMachineSetFederalSubmissionId}
            federalReturn={federalReturn}
            manualFederalProformaSubmissionIdOptions={
              manualFederalProformaSubmissionIdOptions || []
            }
            efSubmissionId={efSubmissionId}
            selectedTtiSubmissionId={selectedTtiSubmissionId}
            returnId={returnId}
            trRejectionErrors={trRejectionErrors}
            isFetchingEFileTrRejectionMessages={isFetchingEFileTrRejectionMessages}
          />
        </Loading>
      </div>
    </>
  );
};

export default FederalReturnStep;
