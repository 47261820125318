import { httpGetAndParse } from '../../utils/fetchService';

export const fetchEntityFilingDecisions = ({ taxYear, period, entityId }) =>
  httpGetAndParse({
    route: `/api/filing-decisions-v2/entity/${taxYear}/${period}/${entityId}`,
    errorMessage: 'Finding filing decisions failed',
  });

export const fetchFilingDecisionsAmountDetails = ({
  taxYear,
  period,
  orgId,
  jurisdictionId,
  categoryName,
}) =>
  httpGetAndParse({
    route: `/api/filing-decisions-v2/amounts/${taxYear}/${period}/${orgId}/${jurisdictionId}/${categoryName}`,
    errorMessage: 'Finding filing decision amount details failed',
  });
