import React, { useState, useCallback } from 'react';
import { Button } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { isDisabled } from '@testing-library/user-event/dist/utils';

import { globalContextSelector } from '../../../shared/store/selectors';
import { QueryKeys } from '../../../shared/queries';
import { useMutationEFileRunValidation } from '../../../shared/mutations/efileV2';
import { FilingMethods, TaxReturnEFileStatus } from '../../../shared/enums';
import { StepStatus } from '../../../eFile/currentReturnFilingV2/enums';
import {
  FilingGroupId,
  GlobalContext,
  JurisdictionDescription,
  JurisdictionStateCode,
  EFileBinderFilingsDataResponse,
} from '../../../../../common/types';
import {
  useQueryGetFederalReturn,
  useQueryGetEFileStepsStatus,
} from '../../../shared/queries/eFileV2';
import config from '../../../config';
import useModal from '../../../shared/hooks/useModal.hook';
import { OutdatedPreparerModal } from '../../../shared/displayComponents/modals';

type RerunValidationButtonProps = {
  filingGroupId: FilingGroupId;
  isTransmitterProviderTR?: boolean;
  jurisdictionStateCode: JurisdictionStateCode;
  jurisdictionDescription: JurisdictionDescription;
  returnInformation?: EFileBinderFilingsDataResponse;
  hasBinderItems: boolean;
};

const RerunValidationButton = ({
  isTransmitterProviderTR,
  filingGroupId,
  jurisdictionStateCode,
  jurisdictionDescription,
  returnInformation,
  hasBinderItems,
}: RerunValidationButtonProps) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const queryClient = useQueryClient();
  const [
    isEFileStepsRefetchIntervalEnabled,
    setIsEFileStepsRefetchIntervalEnabled,
  ] = useState<boolean>(false);

  const {
    showModal: showPreparerInfoOutdatedModal,
    modalProps: preparerInfoOutdatedProps,
  } = useModal();

  const {
    mutateAsync: runValidation,
    isLoading: isRunningValidationProcess,
  } = useMutationEFileRunValidation();

  const { data: eFileStepsData, isFetched: areStepsFetched } = useQueryGetEFileStepsStatus({
    params: { globalContext, sltBinderId: returnInformation?.sltBinderId },
    enabled: Boolean(returnInformation?.sltBinderId),
    refetchInterval: isEFileStepsRefetchIntervalEnabled
      ? config.EFILE_WIZARD_POLLING_INTERVAL
      : false,
    onSuccess: eFileStepsData => {
      const step2EFileStatus = eFileStepsData?.steps?.step2Validations;
      if (step2EFileStatus) {
        if (
          (step2EFileStatus === StepStatus.FAILED || step2EFileStatus === StepStatus.COMPLETED) &&
          isEFileStepsRefetchIntervalEnabled
        ) {
          setIsEFileStepsRefetchIntervalEnabled(false);
          queryClient.resetQueries([
            QueryKeys.EFileSupport.BinderSteps,
            { binderFilingId: returnInformation?.binderFilingId },
          ]);
        }

        if (step2EFileStatus === StepStatus.INITIATED) {
          setIsEFileStepsRefetchIntervalEnabled(true);
        }
      }
    },
  });

  const {
    taxYear,
    period,
    params: { filingTypeId, businessEntityId },
    jurisdictionId,
    isConsolidated,
    isSeparate,
    isSeparateYN,
  } = globalContext;

  const {
    data: federalReturn = {
      isLockedInd: false,
      isActive: true,
      isPreparerInfoUpToDate: false,
    },
    isFetching: isFetchingFederalReturn,
  } = useQueryGetFederalReturn({
    params: {
      globalContextApiRouteParamString: globalContext.apiRouteParamString,
      returnId: returnInformation?.sltBinderId?.toString() || '',
    },
    enabled: Boolean(globalContext.isReady && returnInformation?.sltBinderId),
  });

  const areTTiParamsAvailable = Boolean(
    (!isTransmitterProviderTR && returnInformation?.ttiCaseId && returnInformation?.ttiYearId) ||
      isTransmitterProviderTR,
  );

  const areParamsAvailable =
    areTTiParamsAvailable &&
    returnInformation?.sltBinderId &&
    taxYear &&
    period &&
    filingTypeId &&
    businessEntityId &&
    jurisdictionId &&
    !isFetchingFederalReturn;

  const isRunValidationButtonLoading =
    isFetchingFederalReturn ||
    isRunningValidationProcess ||
    !areStepsFetched ||
    eFileStepsData?.steps?.step2Validations === StepStatus.INITIATED;

  const isNotSupportTTI = !isTransmitterProviderTR && returnInformation?.isTTIReadOnly;

  const isRerunValidationDisabled =
    (!config.ALLOW_REVALIDATION_WHEN_RETURN_IS_ACCEPTED &&
      returnInformation?.eFileStatus === TaxReturnEFileStatus.ACCEPTED) ||
    (!config.ALLOW_REVALIDATION_OF_SUBMITTED_RETURN &&
      returnInformation?.eFileStatus === TaxReturnEFileStatus.TRANSMITTED);

  const handleRerunValidationButtonTitle = useCallback(() => {
    if (isRunningValidationProcess || isFetchingFederalReturn) return;

    if (
      !config.ALLOW_REVALIDATION_WHEN_RETURN_IS_ACCEPTED &&
      returnInformation?.eFileStatus === TaxReturnEFileStatus.ACCEPTED
    )
      return 'Return is Accepted.';

    if (
      !config.ALLOW_REVALIDATION_OF_SUBMITTED_RETURN &&
      returnInformation?.eFileStatus === TaxReturnEFileStatus.TRANSMITTED
    )
      return 'Return is already transmitted.';

    if (!areParamsAvailable) return 'This return is locked. Rerun validation is not allowed.';

    if (returnInformation?.filingMethod !== FilingMethods.EFILE)
      return `Disabled for ${returnInformation?.filingMethod} return`;

    if (federalReturn?.isLockedInd)
      return 'This return is locked. Rerun validation is not allowed.';

    if (!federalReturn?.isActive)
      return 'This return is not activated. Rerun validation is not allowed.';

    if (isNotSupportTTI) return 'Not supported for this client.';

    return;
  }, [
    isDisabled,
    areParamsAvailable,
    isRunningValidationProcess,
    isFetchingFederalReturn,
    returnInformation?.filingMethod,
    isNotSupportTTI,
    federalReturn,
  ]);

  const handleRerunValidation = useCallback(async () => {
    const currentDate = new Date();
    const pwcEINUpdateDate = new Date(config.PWC_EIN_UPDATE_DATE);

    const isPreparerInfoOutdated =
      currentDate >= pwcEINUpdateDate && !federalReturn.isPreparerInfoUpToDate;

    if (isPreparerInfoOutdated) {
      showPreparerInfoOutdatedModal();

      return;
    }

    if (areParamsAvailable && !federalReturn?.isLockedInd && federalReturn?.isActive) {
      setIsEFileStepsRefetchIntervalEnabled(true);
      await runValidation({
        ttiCaseId: returnInformation?.ttiCaseId || '',
        yearOrSubPeriodId: returnInformation?.ttiYearId || '',
        sltBinderId: returnInformation?.sltBinderId || 0,
        jurisdictionStateCode,
        jurisdictionDescription,
        globalContext,
        taxYear: taxYear || '',
        period: period || '',
        filingTypeId: filingTypeId || '',
        businessEntityId: businessEntityId || '',
        jurisdictionId: jurisdictionId || '',
        isConsolidated,
        isSeparate,
        isSeparateYN,
        consolidationProFormaFilingGroupId: filingGroupId,
        returnId: returnInformation?.sltBinderId?.toString() || '',
        isTransmitterProviderTR: isTransmitterProviderTR,
      });
      queryClient.resetQueries([
        QueryKeys.EFileSupport.BinderSteps,
        { binderFilingId: returnInformation?.binderFilingId },
      ]);
      queryClient.resetQueries([
        QueryKeys.EFileV2.GetStepsStatus,
        { sltBinderId: returnInformation?.sltBinderId },
      ]);
      queryClient.resetQueries([
        QueryKeys.EFileSupport.EFileBinderFilingsData,
        { sltBinderId: returnInformation?.sltBinderId },
      ]);
    }
  }, [
    showPreparerInfoOutdatedModal,
    areParamsAvailable,
    runValidation,
    returnInformation?.ttiCaseId,
    returnInformation?.ttiYearId,
    returnInformation?.sltBinderId,
    returnInformation?.binderFilingId,
    jurisdictionStateCode,
    jurisdictionDescription,
    globalContext,
    taxYear,
    period,
    filingTypeId,
    businessEntityId,
    jurisdictionId,
    isConsolidated,
    isSeparate,
    isSeparateYN,
    filingGroupId,
    federalReturn,
    queryClient,
    setIsEFileStepsRefetchIntervalEnabled,
    isTransmitterProviderTR,
  ]);

  return (
    <>
      <Button
        size="lg"
        isLoading={isRunValidationButtonLoading}
        disabled={
          !hasBinderItems ||
          isRerunValidationDisabled ||
          isNotSupportTTI ||
          !areParamsAvailable ||
          isRunValidationButtonLoading ||
          isFetchingFederalReturn ||
          returnInformation?.filingMethod !== FilingMethods.EFILE ||
          federalReturn?.isLockedInd ||
          !federalReturn?.isActive
        }
        onClick={handleRerunValidation}
        kind="secondary"
        title={handleRerunValidationButtonTitle()}
      >
        RERUN VALIDATION
      </Button>
      {preparerInfoOutdatedProps.visible && (
        <OutdatedPreparerModal {...preparerInfoOutdatedProps} />
      )}
    </>
  );
};

export default RerunValidationButton;
