import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OTHER_SUBCATEGORY } from '@common-packages/shared-constants';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  ActionMenuForExport,
  useActionMenuForExport,
} from '../../shared/displayComponents/actionMenuForExport';
import useReportData from '../../shared/reports/useReportData.hook';
import getReportUrl from '../shared/getReportUrl';
import checkIsFetchingAnyParam from '../../shared/reports/checkIsFetchingAnyParam';
import ReportParamDropdown from '../../shared/reports/reportParamDropdown.component';
import styles from '../../shared/reports/reportParam.module.scss';
import useTaxYearAndPeriodParams from '../../shared/reports/useTaxYearAndPeriodParams.hook';
import useReportParam from '../../shared/reports/useReportParam.hook';
import { jurisdictionIdSelector, entityIdSelector } from '../../shared/store/selectors';
import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import useGroupingGrid from '../../shared/hooks/useGroupingGrid.hook';

import getColumnDefinitions from './dividendToStateAmounts.columnDefinitions';

const endpointBasePath = getReportUrl('dividend-to-state-amounts');

const DividendToStateAmounts = () => {
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const entityId = useSelector(entityIdSelector);

  const { taxYearParam, periodParam } = useTaxYearAndPeriodParams({ endpointBasePath });

  const jurisdictionIdParam = useReportParam({
    endpointBasePath,
    paramName: 'jurisdictionId',
    defaultParamValue: jurisdictionId,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  const entityIdParam = useReportParam({
    endpointBasePath,
    paramName: 'entityId',
    defaultParamValue: entityId,
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        period: periodParam.paramValue,
      }),
      [taxYearParam.paramValue, periodParam.paramValue],
    ),
  });

  const isFetchingAnyParam = checkIsFetchingAnyParam([
    taxYearParam,
    periodParam,
    jurisdictionIdParam,
    entityIdParam,
  ]);

  const { reportData, isFetchingReportData, runReportButton, showGrid } = useReportData({
    endpointBasePath,
    isFetchingAnyParam,
    method: 'POST',
    params: useMemo(
      () => ({
        taxYear: taxYearParam.paramValue,
        jurisdictionId: jurisdictionIdParam.paramValue,
        entityId: entityIdParam.paramValue,
      }),
      [jurisdictionIdParam.paramValue, taxYearParam.paramValue, entityIdParam.paramValue],
    ),
  });

  const { props, onGridReady, gridApi } = useActionMenuForExport({
    reportData,
  });

  useEffect(() => {
    // set initial filter for category column
    if (gridApi && reportData) {
      const filterInstance = gridApi.getFilterInstance('categoryId');
      filterInstance?.setModel({ values: ['INCOME_MODIFICATIONS'] });
      gridApi.onFilterChanged();
    }
  }, [gridApi, reportData]);

  const {
    gridKey,
    rowClassRules,
    aggFuncs,
    GroupRowRenderer,
    getGroupingCellClassRules,
    getRowHeight,
    initialGroupOrderComparator,
  } = useGroupingGrid({ aggColumnKey: 'payerName' });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        getGroupingCellClassRules,
      }),
    [getGroupingCellClassRules],
  );

  const reportDataWithoutNullCategories = useMemo(
    () =>
      reportData
        ? reportData.map(item => ({
            ...item,
            categoryId: item.categoryId ? item.categoryId : OTHER_SUBCATEGORY,
          }))
        : [],
    [reportData],
  );

  return (
    <>
      <div className="row">
        <div className={`col ${styles.reportParam}`}>
          <ReportParamDropdown label="Tax Year" param={taxYearParam} />
          <ReportParamDropdown label="Period" param={periodParam} />
          <ReportParamDropdown
            label="Jurisdiction"
            param={jurisdictionIdParam}
            showSelectAllOption
          />
          <ReportParamDropdown label="Entity" param={entityIdParam} />
          {runReportButton}
        </div>
      </div>
      <ActionMenuForExport {...props} />
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              key={gridKey}
              rowData={reportDataWithoutNullCategories}
              columnDefs={columnDefinitions}
              groupDisplayType={'groupRows'}
              groupRowRenderer={GroupRowRenderer}
              groupIncludeFooter
              suppressAggFuncInHeader
              groupDefaultExpanded={-1}
              aggFuncs={aggFuncs}
              initialGroupOrderComparator={initialGroupOrderComparator}
              getRowHeight={getRowHeight}
              rowClassRules={rowClassRules}
              onGridReady={onGridReady}
              isGridLoading={isFetchingReportData}
              withSearchBar
              sideBar={defaultSideBarWithColumnsToolPanel}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DividendToStateAmounts;
