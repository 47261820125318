import { GlobalK1InfoData } from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export interface FindGlobalK1InfoParams {
  period: string | null;
  businessEntityId: string | null;
  dataModelId: string | null;
  partnerId: string | null;
}

export const useQueryGlobalK1Information = ({
  params,
  enabled,
}: {
  params: FindGlobalK1InfoParams;
  enabled: boolean;
}) =>
  useCustomQuery<GlobalK1InfoData[]>([QueryKeys.GlobalK1Information.FormData, params], {
    url: `/api/entities/global-k1-information/${params.period}/${params.businessEntityId}/${params.dataModelId}/${params.partnerId}`,
    defaultData: [],
    enabled,
    errorMessage: 'Error fetching global k1 information',
  });
