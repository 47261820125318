import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';
import { useQueryClient } from 'react-query';
import { SYNC_REQUEST_APPROVED_STATUS } from '@common-packages/shared-constants';

import { customerPermissionsSelector } from '../../shared/store/selectors';
import { taxYearSelector, periodSelector } from '../store/selectors';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { HeaderWithParamDropdownsWrapper } from '../../shared/displayComponents/headerWithParamDropdowns';
import { QueryKeys } from '../../shared/queries';
import { useQueryContentDeployments } from '../../shared/queries/contentDeployment';
import {
  useMutationCreateDeployment,
  useMutationDeploy,
} from '../../shared/mutations/contentDeployment';
import useModal from '../../shared/hooks/useModal.hook';

import getClientColumnDefinitions from './deploymentClient.columnDefinitions';
import requestColumnDefinitions from './deploymentRequest.columnDefinitions';
import ContentDeploymentWarningModal from './contentDeploymentWarningModal.component';
import {
  hasPermissionToAuthorizeContentDeployment,
  hasPermissionToDeployContent,
} from './permissions';

const ContentDeployments = () => {
  const queryClient = useQueryClient();
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const customerPermissions = useSelector(customerPermissionsSelector);
  const [selectedClientIds, setSelectedClientIds] = useState([]);

  const hasPermissionToAuthorize = hasPermissionToAuthorizeContentDeployment(customerPermissions);
  const hasPermissionToDeploy = hasPermissionToDeployContent(customerPermissions);

  const { showModal, modalProps } = useModal();

  const { data: deployments, isFetching: isFetchingDeployments } = useQueryContentDeployments({
    params: { taxYear, period },
    enabled: Boolean(taxYear) && Boolean(period),
    onSuccess: () => setSelectedClientIds([]),
  });

  const isAllClientsApprovedForTaxYearAndPeriod = useMemo(
    () => !deployments?.clients?.some(({ hasApproved }) => !hasApproved),
    [deployments],
  );

  const isDeploymentApprovedForTaxYearAndPeriod = useMemo(
    () =>
      deployments?.requests?.some(
        ({ overallStatus }) => overallStatus === SYNC_REQUEST_APPROVED_STATUS,
      ),
    [deployments],
  );

  const clientColumnDefinitions = useMemo(
    () => getClientColumnDefinitions({ hasPermissionToAuthorize }),
    [hasPermissionToAuthorize],
  );

  const {
    mutateAsync: createDeployment,
    isLoading: isLoadingCreateDeployment,
  } = useMutationCreateDeployment({
    onSuccess: () => setSelectedClientIds([]),
  });

  const {
    data: responseData,
    mutateAsync: deploy,
    isLoading: isLoadingDeploy,
  } = useMutationDeploy();

  const onNewDeploymentClick = useCallback(() => {
    createDeployment({ taxYear, period, clientIds: selectedClientIds });
  }, [createDeployment, taxYear, period, selectedClientIds]);

  const onSelectedClientChanged = useCallback(({ api }) => {
    const selectedClientIds = api.getSelectedRows().map(({ id }) => id);
    setSelectedClientIds(selectedClientIds);
  }, []);

  const onDeployClick = useCallback(() => {
    deploy({ taxYear, period });
    showModal();
  }, [deploy, showModal, taxYear, period]);

  const onRefreshClick = useCallback(() => {
    queryClient.resetQueries(QueryKeys.ContentDeployment.ContentDeployments);
  }, [queryClient]);

  const isLoading = isFetchingDeployments || isLoadingCreateDeployment || isLoadingDeploy;

  return (
    <>
      <HeaderWithParamDropdownsWrapper showHeaderTaxYearDropdown showHeaderPeriodDropdown />
      <div className="row">
        <div className="col-2 add-button-column">
          <div>
            {hasPermissionToAuthorize && (
              <Button
                size="lg"
                className="add-button"
                onClick={onNewDeploymentClick}
                disabled={
                  !hasPermissionToAuthorize ||
                  isLoading ||
                  isAllClientsApprovedForTaxYearAndPeriod ||
                  !selectedClientIds.length
                }
              >
                Authorize Deployment
              </Button>
            )}
          </div>
        </div>
        <div className="col-10 add-button-column">
          {hasPermissionToDeploy && (
            <Button
              size="lg"
              className="add-button"
              onClick={onDeployClick}
              disabled={isLoading || !isDeploymentApprovedForTaxYearAndPeriod}
            >
              Deploy
            </Button>
          )}
          <Button size="lg" className="add-button" onClick={onRefreshClick} disabled={isLoading}>
            Refresh
          </Button>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col-2">
          <AgGrid
            rowData={deployments?.clients}
            columnDefs={clientColumnDefinitions}
            isGridLoading={isLoading}
            withSearchBar
            areHeaderCellBordersEnabled
            onSelectionChanged={onSelectedClientChanged}
            rowSelection={'multiple'}
            autoMaxWidth
          />
        </div>
        <div className="col-10">
          <AgGrid
            rowData={deployments?.requests}
            columnDefs={requestColumnDefinitions}
            isGridLoading={isLoading}
            withSearchBar
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
      <ContentDeploymentWarningModal
        isLoading={isLoading}
        data={responseData?.clientErrors}
        {...modalProps}
      />
    </>
  );
};

export default ContentDeployments;
