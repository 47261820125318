import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@pwc/appkit-react';

import { InputSizes, ResizeTypes } from '../constants';

class TextareaFormik extends React.PureComponent {
  static propTypes = {
    innerRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(InputSizes)),
    form: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
      setTouched: PropTypes.func.isRequired,
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired,
    }).isRequired,
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
    }).isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    resize: PropTypes.oneOf(Object.values(ResizeTypes)),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    size: InputSizes.SM,
    disabled: false,
    resize: ResizeTypes.VERTICAL,
    onChange: () => null,
    label: null,
  };

  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }

  handleBlur = () => {
    const {
      field: { name },
      form: { touched, setTouched },
    } = this.props;
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  handleChange = value => {
    const {
      field: { name },
      form: { setFieldValue },
      onChange,
    } = this.props;
    setFieldValue(name, value);
    onChange(value);
  };

  render() {
    const { className, size, label, field, form, disabled, resize, innerRef } = this.props;
    const _ref = this._ref;
    const errorMessage = form.errors[field.name];
    const fieldTouched = form.touched[field.name];
    const shouldDisplayError = errorMessage && fieldTouched;

    return (
      <div ref={this._ref}>
        <TextArea
          className={`${className} ${
            shouldDisplayError ? 'textarea-with-error' : 'shouldDisplayError'
          }`}
          size={size}
          label={label}
          name={field.name}
          value={field.value || ''}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          disabled={disabled}
          resize={resize}
          ref={ref => {
            if (!innerRef) {
              return;
            }

            innerRef.current = ref;

            if (ref) {
              innerRef.current.refs = {
                textarea: _ref?.current?.querySelector('textarea'),
              };
            }
          }}
          autoComplete="off"
        />
        {shouldDisplayError && <p className="a-form-error">{errorMessage}</p>}
      </div>
    );
  }
}

export default TextareaFormik;
