const Yup = require('yup');

const { dateRegex } = require('../../sharedConstants/dateRegex');
const { DATA_TYPE_FIELD, dataTypes } = require('../constants');

const dataTypeSchemaPart = {
  value: Yup.mixed()
    .when(DATA_TYPE_FIELD, {
      is: dataTypes.TEXT,
      then: Yup.string().nullable().notOneOf([undefined]), // eslint-disable-line no-undefined
    })
    .when(DATA_TYPE_FIELD, {
      is: dataTypes.CHECKBOX,
      then: Yup.bool().nullable(),
    })
    .when(DATA_TYPE_FIELD, {
      is: dataTypes.DATE,
      then: Yup.string().matches(dateRegex).nullable().notOneOf([undefined]), // eslint-disable-line no-undefined
    })
    .when(DATA_TYPE_FIELD, {
      is: dataTypes.NUMBER,
      then: Yup.number().notOneOf([undefined]), // eslint-disable-line no-undefined
    })
    .when(DATA_TYPE_FIELD, {
      is: dataTypes.NA,
      then: Yup.string().nullable().notOneOf([undefined]), // eslint-disable-line no-undefined
    }),
};

module.exports = dataTypeSchemaPart;
