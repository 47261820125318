import flattenDeep from 'lodash.flattendeep';
import omit from 'lodash.omit';

export default tree => {
  const flattenNestedTree = (nodes, path) =>
    (nodes || []).map(node => {
      const dataPath = [...path, node.MAIN];
      return [
        {
          ...omit(node, 'children'),
          dataPath,
          level: path.length,
        },
        ...flattenNestedTree(node.children, dataPath),
      ];
    });
  return flattenDeep(flattenNestedTree(tree, []));
};
