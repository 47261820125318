import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import { globalContextSelector } from '../../shared/store/selectors';
import { DownloadLink } from '../../shared/displayComponents/downloadLink';

const fileTypes = {
  INPUT: 'INPUT',
  OUTPUT: 'OUTPUT',
};

const stepsWithDownload = [
  {
    action: 'upload-federal-transmission',
    linkDescription: 'GTW XML',
    fileType: fileTypes.INPUT,
    fileExtension: 'xml',
    disabled: () => false,
  },
  {
    action: 'download-and-review-schema',
    linkDescription: 'Mapping ZIP',
    fileType: fileTypes.OUTPUT,
    fileExtension: 'zip',
    disabled: () => false,
  },
  {
    action: 'verify-and-update-mapping-file',
    linkDescription: 'Mapping XML',
    fileType: fileTypes.INPUT,
    fileExtension: 'xml',
    disabled: ({ status }) => status !== 'COMPLETED',
  },
  {
    action: 'upload-mapping-file',
    linkDescription: 'Updated mapping XML',
    fileType: fileTypes.INPUT,
    fileExtension: 'xml',
    disabled: () => false,
  },
  {
    action: 'import-federal-transmission',
    linkDescription: 'Updated mapping XML',
    fileType: fileTypes.INPUT,
    fileExtension: 'xml',
    disabled: ({ status }) => status !== 'COMPLETED',
  },
  {
    action: 'create-csv-file-for-setup-proforma-binders',
    linkDescription: 'CSV File',
    fileType: fileTypes.INPUT,
    fileExtension: 'csv',
    disabled: () => false,
  },
  // {
  //   action: 'generate-efile-xml', // TODO: test this link, when fetching steps data gets fixed (proper filtering)
  //   linkDescription: 'File',
  //   fileType: fileTypes.OUTPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'download-efile-xml', // TODO: test this link, when fetching steps data gets fixed (proper filtering)
  //   linkDescription: 'File',
  //   fileType: fileTypes.OUTPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'xml-comparison-report', // TODO: test this link, when fetching steps data gets fixed (proper filtering)
  //   linkDescription: 'File',
  //   fileType: fileTypes.OUTPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'xml-comparison-report-download', // TODO: test this link when it will be possible to successfully run this step
  //   linkDescription: 'File',
  //   fileType: fileTypes.INPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'xml-boolean-comparison', // TODO: test this link when it will be possible to successfully run this step
  //   linkDescription: 'File',
  //   fileType: fileTypes.INPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'xml-boolean-comparison-download', // TODO: test this link when it will be possible to successfully run this step
  //   linkDescription: 'File',
  //   fileType: fileTypes.INPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'generate-pdf-binder', // TODO: test this link, when fetching steps data gets fixed (proper filtering)
  //   linkDescription: 'File',
  //   fileType: fileTypes.OUTPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'download-pdf-binder', // TODO: test this link when it will be possible to successfully run this step
  //   linkDescription: 'File',
  //   fileType: fileTypes.INPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'create-federal-transmission-file', // TODO: test this link, when fetching steps data gets fixed (proper filtering)
  //   linkDescription: 'File',
  //   fileType: fileTypes.OUTPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
  // {
  //   action: 'download-and-review-transmission-errors', // TODO: test this link when it will be possible to successfully run this step
  //   linkDescription: 'File',
  //   fileType: fileTypes.INPUT,
  //   fileExtension: 'xml',
  //   disabled: () => false,
  // },
];

const InitiateStepDownloadFile = ({ data }) => {
  const globalContext = useSelector(globalContextSelector);

  const { stepName, ttiYearId, ttiCaseId, binderId } = data || {};

  const stepWithDownload = stepsWithDownload.find(({ action }) => action === stepName);
  const isContextReady = globalContext.isReady && ttiYearId && ttiCaseId && binderId && stepName;

  if (!stepWithDownload || !isContextReady) {
    return null;
  }

  return (
    <DownloadLink
      url={`${Routes.proformaDownloadStepFile.compiledRoute({
        ...globalContext.params,
        ttiYearId: String(ttiYearId),
        ttiCaseId: String(ttiCaseId),
        binderId: String(binderId),
        stepName,
        fileType: stepWithDownload.fileType,
        fileExtension: stepWithDownload.fileExtension,
      })}`}
      disabled={stepWithDownload.disabled(data)}
    >
      {stepWithDownload.linkDescription}
    </DownloadLink>
  );
};

InitiateStepDownloadFile.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string.isRequired,
    stepName: PropTypes.string.isRequired,
    ttiYearId: PropTypes.string.isRequired,
    ttiCaseId: PropTypes.string.isRequired,
    binderId: PropTypes.string.isRequired,
  }),
};

export default InitiateStepDownloadFile;
