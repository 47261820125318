import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { SelectOptionPropTypes } from '../../propTypes/selectOption';
import VirtualizedSelect from '../../../shared/forms/virtualizedSelect/virtualizedSelect.component';
import { useCellEditorSelect } from '../../hooks/useCellEditorSelect';

const SelectVirtualizedCellEditor = forwardRef(({ value, options, stopEditing }, ref) => {
  const { setSelectedValue, inputRef } = useCellEditorSelect({
    ref,
    stopEditing,
    options,
    value,
  });

  return (
    <VirtualizedSelect
      initialSelectedValue={value}
      options={options}
      setSelectedValue={setSelectedValue}
      inputRef={inputRef}
    />
  );
});

SelectVirtualizedCellEditor.displayName = 'SelectVirtualizedCellEditor';

SelectVirtualizedCellEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(SelectOptionPropTypes),
  stopEditing: PropTypes.func.isRequired,
};

export default SelectVirtualizedCellEditor;
