import React, { useCallback } from 'react';

import { SubmissionListItem } from '../useViewStatusStepContext.hook';
import Select from '../../../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import formatDate from '../../../utils/formatDate';
interface SubmissionSelectProps {
  submissions: SubmissionListItem[];
  className?: string;
  setSelectedTransmissionHistoryId: (transmissionId: string) => void;
  selectedTransmissionHistoryId: string;
}

const SubmissionSelect = ({
  submissions,
  className,
  setSelectedTransmissionHistoryId,
  selectedTransmissionHistoryId,
}: SubmissionSelectProps) => {
  const handleChange = useCallback(
    ({ value }: { value: string }) => {
      setSelectedTransmissionHistoryId(value);
    },
    [selectedTransmissionHistoryId, setSelectedTransmissionHistoryId],
  );

  return (
    <Select
      className={className}
      onChange={handleChange}
      options={submissions.map(submission => ({
        label: formatDate(submission.date),
        value: submission.id,
      }))}
      value={selectedTransmissionHistoryId}
      hideLabel
    />
  );
};

export default SubmissionSelect;
