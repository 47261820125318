import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from '@tls/ui-modal';
import { useDispatch, useSelector } from 'react-redux';
import { dataModelSchemas } from '@common-packages/validators';

import { taxYearSelector, jurisdictionIdSelector } from '../../store/selectors';
import { addDataset, fetchAllDatasets } from '../../../shared/store/dataModels/actions';
import oracleSysGuidUuid from '../../../utils/oracleSysGuidUuid';
import { allDatasetsSelector } from '../../../shared/store/dataModels/selectors';
import DatasetForm from '../DatasetForm.component';
import { CalculationTypes } from '../constants';

const AddDatasetModal = ({ hideModal, visible, submitCallback, modalData = null }) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const allDatasets = useSelector(allDatasetsSelector);

  useEffect(() => {
    if (visible) {
      dispatch(fetchAllDatasets({ taxYear, jurisdictionId }));
    }
  }, [dispatch, jurisdictionId, taxYear, visible]);

  const add = useCallback(
    async (
      { name, description, instanceName, instanceIdentifier, maxInstances, calculation },
      { resetForm },
    ) => {
      const dataset = {
        id: oracleSysGuidUuid(),
        taxYear,
        jurisdictionId,
        dataModelId: modalData?.dataModel?.value || null,
        parentId: modalData?.dataset?.id || null,
        name,
        description,
        instanceName,
        instanceIdentifier,
        maxInstances,
        allowCons: calculation === CalculationTypes.ALLOW_CONS,
        aggregateSeparateInstances: calculation === CalculationTypes.AGG_SEP_INSTANCES,
      };
      // wait for a success, if error submitCallback is not called
      await dispatch(addDataset(dataset));
      submitCallback(dataset.id);
      resetForm();
      hideModal();
    },
    [dispatch, modalData, taxYear, jurisdictionId, hideModal, submitCallback],
  );

  const renderModal = useCallback(
    ({ submitForm, isValid }) => (
      <Modal
        title={`Add Dataset to Model: ${modalData && modalData.dataModel.label}`}
        closeAction={hideModal}
        visible={visible}
        submitText={'Submit'}
        submitDisabled={!modalData || !isValid}
        submitAction={submitForm}
        dismissText="Cancel"
        dismissAction={hideModal}
      >
        <DatasetForm />
      </Modal>
    ),
    [hideModal, modalData, visible],
  );

  const initialModalData = {
    name: '',
    description: '',
    instanceName: '',
    instanceIdentifier: '',
    maxInstances: '-1',
    calculation: CalculationTypes.ALLOW_CONS,
  };

  return (
    <Formik
      initialValues={initialModalData}
      enableReinitialize
      validateOnBlur={false}
      validationSchema={dataModelSchemas.getAddEditDatasetSchema({ allDatasets })} // here
      onSubmit={add}
    >
      {renderModal}
    </Formik>
  );
};

AddDatasetModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  submitCallback: PropTypes.func.isRequired,
  modalData: PropTypes.shape({
    dataModel: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    dataset: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
};

export default AddDatasetModal;
