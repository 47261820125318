import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

import { httpGetAndParse } from '../../utils/fetchService';

import { QueryKeys } from '.';

const fetchCsrfToken = () =>
  httpGetAndParse({
    route: '/api/csrf',
  });

export const useFetchQueryCsrfToken = () => {
  const queryClient = useQueryClient();

  return useCallback(
    () =>
      queryClient.fetchQuery(QueryKeys.Csrf.CsrfToken, fetchCsrfToken, {
        initialData: null,
        cacheTime: 0,
      }),
    [queryClient],
  );
};
