import { GlobalContext, NavigatorLocalStorageStatus } from '../../../common/types';

import { IAgGridStateModel } from './displayComponents/agGrid/types';

export enum STORAGE_KEY {
  STATUS_FOR_CLIENT = 'statusForClient',
  STATUS_FOR_NAVIGATOR = 'statusForNavigator',
}

export enum NAVIGATOR_STATUS_KEY {
  ITEMS = 'items',
  IS_NAVIGATOR_FOLDER_COLLAPSED = 'isNavigatorFolderCollapsed',
}

export enum CLIENT_STATUS_KEY {
  CONTEXT = 'context',
}

type GetLocalStorageItemParams = {
  key: string | STORAGE_KEY;
};

export const getLocalStorageItem = ({ key }: GetLocalStorageItemParams) => {
  const item = localStorage?.getItem(key);
  return item ? JSON.parse(item) : {};
};

export const getClientLocalStorageItem = ({
  clientId,
  clientDetailKey,
}: {
  clientId?: string;
  clientDetailKey?: string | CLIENT_STATUS_KEY;
}) => {
  if (!clientId || !clientDetailKey) {
    return null;
  }

  const item = getLocalStorageItem({ key: STORAGE_KEY.STATUS_FOR_CLIENT });
  return item?.[clientId]?.[clientDetailKey] || null;
};

export const setClientLocalStorageItem = ({
  clientId,
  clientDetailKey,
  data,
}: {
  clientId: string;
  clientDetailKey: string;
  data: IAgGridStateModel | GlobalContext | NavigatorLocalStorageStatus;
}) => {
  if (!clientId) {
    return;
  }
  const item = getLocalStorageItem({ key: STORAGE_KEY.STATUS_FOR_CLIENT });
  item[clientId] = {
    ...(item[clientId] || {}),
    [clientDetailKey]: data,
  };
  localStorage.setItem(STORAGE_KEY.STATUS_FOR_CLIENT, JSON.stringify(item));
};

export const setLocalStorageItemByKey = ({
  key,
  data,
}: {
  key: string;
  data: NavigatorLocalStorageStatus;
}) => {
  if (!key || !data) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(data));
};
