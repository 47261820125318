const getFormFieldNameWithMultCondition = fieldName => {
  /**
   * Mult fields are fields that share the same name,
   * are distinguished with '-' and incremented
   * (SOMEMULTFIELDNAME-1, SOMEMULTFIELDNAME-2...)
   * (SOMEOTHERMULTFIELDNAME-1, SOMEOTHERMULTFIELDNAME-2...)
   */
  const lastDashPosition = fieldName.lastIndexOf('-');
  // If the selected field is mult, find id of 'SOMEMULTFIELDNAME-' not 'SOMEMULTFIELDNAME-1'
  // because that doesn't exist
  return lastDashPosition === -1 ? fieldName : fieldName.substring(0, lastDashPosition + 1);
};

export default getFormFieldNameWithMultCondition;
