import { QueryKeys, useCustomQuery } from '.';

interface EntitiesParams {
  taxYear: string;
  period: string;
  filingTypeId?: string;
  businessEntityId?: string;
  jurisdictionId?: string;
}

interface FindFilingGroupMembersParams extends EntitiesParams {
  filingGroupIds: string[];
}
export interface Entity {
  entityId: string;
  entityName: string;
  proformaFilingGroupIdOptions: { label: string; value: string }[];
  fiscalYearEnd: string;
}

export const useQueryEntities = ({
  params,
  enabled,
}: {
  params: EntitiesParams;
  enabled: boolean;
}) =>
  useCustomQuery<Entity[]>([QueryKeys.FilingGroups.FilingGroups, params], {
    url: `/api/shared/entities/${params.taxYear}/${params.period}`,
    enabled,
    errorMessage: 'Fetching entities failed',
  });

export const useQueryEntitiesForFilingGroups = ({
  params,
  enabled,
}: {
  params: FindFilingGroupMembersParams;
  enabled: boolean;
}) =>
  useCustomQuery<Entity[]>([QueryKeys.FilingGroups.FilingGroups, params], {
    url: `/api/shared/entities/${params.taxYear}/${params.period}/${params.filingGroupIds.join(
      ',',
    )}`,
    enabled,
    errorMessage: 'Fetching entities by filingGroupIds failed',
  });
