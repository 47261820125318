import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import ComingSoonPage from '../comingSoonPage/comingSoonPage.component';
import { globalContextSelector } from '../shared/store/selectors';

import AdminRoute from './routeConfiguration/adminRoute.container';
import Admin from './admin.component';
import EditCustomReports from './customReports/editCustomReports/editCustomReports.component';
import RunCustomReports from './customReports/runCustomReports/runCustomReports.container';
import ViewCustomReport from './customReports/runCustomReports/viewCustomReport.container';
import EditTrgReports from './customReports/editTrgReports/editTrgReports.component';
import RunTrgReports from './customReports/runCustomReports/runTrgReports.container';
import EFileConfiguration from './efile/efileConfiguration.component';
import EFileSubmission from './efile/efileSubmission.container';
import EditDataVisualization from './dataVisualization/editDataVisualization/editDataVisualization.component';
import Accounts from './accounts/accounts.container';
import InfoAccounts from './infoAccounts/infoAccounts.container';
import UsersMaintenance from './usersMaintenance/usersMaintenance.container';
import BusinessGroups from './businessGroups/businessGroups.container';
import Businesses from './businesses/businesses.container';
import ConsolidationsDashboard from './consolidations/consolidationDashboard.container';
import RunDataVisualization from './dataVisualization/runDataVisualization/runDataVisualization.container';
import JobQueueStatusReports from './jobQueueStatusReport/jobQueueStatusReport.container';
import TaxRatesAndConstantsMaintenance from './taxRatesAndConstantsMaintenance/taxRatesAndConstantsMaintenance.container';
import LockReturn from './lockReturn/lockReturn.container';
import EntitiesMaintenance from './entitiesMaintenance/entitiesMaintenance.container';
import JurisdictionsContextValue from './routeConfiguration/jurisdictionContextValue.container';
import TaxRatesJurisdictionsDropdown from './routeConfiguration/taxRatesJurisdictionsDropdown.container';
import AdminDataProvider from './routeConfiguration/adminDataProvider';
import UsersDataProvider from './routeConfiguration/usersDataProvider';
import AssignBusinesses from './usersMaintenance/assignBusinesses.container';
import Permissions from './permissions/permissions.component';
import Roles from './roles/roles.container';
import ConstantsRulesReport from './constantsRulesReport/constantsRulesReport.container';
import TaxRateReport from './taxRateReport/taxRateReport.container';
import CalculatedAccountsReport from './calculatedAccountsReport/calculatedAccountsReport.container';
import AccountGridReport from './accountGridReport/accountGridReport.container';
import EFileSupport from './support/eFileSupport/eFileSupport.component';
import FederalDataDiagnostics from './support/federalDataDiagnostics/federalDataDiagnostics.container';
import entityInformationDiagnostics from './support/entityInformationDiagnostics/entityInformationDiagnostics.container';
import FederalDataDetails from './support/federalDataDiagnostics/federalDataDetails/federalDataDetails.container';
import EntityInformationDetails from './support/entityInformationDiagnostics/entityInformationDetails/entityInformationDetails.container';
import Utilities from './support/utilities/utilities.container';
import InstanceDataCleanup from './support/instanceDataCleanup/instanceDataCleanup.container';

const AdminRoutes = () => {
  const globalContext = useSelector(globalContextSelector);

  const taxRatesAndConstantsAdditionalDropdowns = (
    <>
      <TaxRatesJurisdictionsDropdown />
    </>
  );

  return (
    <Switch>
      <AdminRoute exact path={Routes.administration.MAIN} component={Admin} />
      <AdminRoute
        exact
        path={Routes.editCustomReports.MAIN}
        component={EditCustomReports}
        className="edit-reports"
      />
      <AdminRoute
        exact
        path={Routes.runCustomReports.MAIN}
        component={RunCustomReports}
        className="run-custom-reports"
      />
      <AdminRoute
        exact
        path={Routes.editTrgReports.MAIN}
        component={EditTrgReports}
        className="edit-reports"
      />
      <AdminRoute
        exact
        path={Routes.runTrgReports.MAIN}
        component={RunTrgReports}
        className="run-custom-reports"
      />
      <AdminRoute
        exact
        path={Routes.viewCustomReport.MAIN}
        component={ViewCustomReport}
        className="run-custom-report"
      />
      <AdminRoute
        exact
        path={Routes.editDataVisualizations.MAIN}
        component={EditDataVisualization}
        className="edit-reports"
      />
      <AdminRoute
        exact
        path={Routes.runDataVisualizations.MAIN}
        component={RunDataVisualization}
        className="run-data-visualization"
      />
      <AdminRoute exact path={Routes.jobQueueStatusReport.MAIN} component={JobQueueStatusReports} />
      <UsersDataProvider path={Routes.usersMaintenance.MAIN}>
        <AdminRoute exact path={Routes.usersMaintenance.MAIN} component={UsersMaintenance} />
        <AdminRoute exact path={Routes.assignBusinesses.MAIN} component={AssignBusinesses} />
      </UsersDataProvider>
      <AdminRoute exact path={Routes.businessGroup.MAIN} component={BusinessGroups} />
      <AdminRoute exact path={Routes.businesses.MAIN} component={Businesses} />
      <AdminRoute
        exact
        path={Routes.adminConsolidations.MAIN}
        component={ConsolidationsDashboard}
      />
      <AdminDataProvider exact path={Routes.taxRatesAndConstantsMaintenance.MAIN}>
        <AdminRoute
          exact
          path={Routes.taxRatesAndConstantsMaintenance.MAIN}
          component={TaxRatesAndConstantsMaintenance}
          hidePeriodDropdown={globalContext.isSeparate}
          hideJurisdictionDropdown
          additionalDropdowns={taxRatesAndConstantsAdditionalDropdowns}
        />
      </AdminDataProvider>
      <AdminRoute
        exact
        path={Routes.lockReturn.MAIN}
        component={LockReturn}
        additionalValues={<JurisdictionsContextValue />}
      />
      <AdminRoute exact path={Routes.accounts.MAIN} component={Accounts} hidePeriodDropdown />
      <AdminRoute exact path={Routes.infoAccounts.MAIN} component={InfoAccounts} />
      <AdminRoute exact path={Routes.efileConfiguration.MAIN} component={EFileConfiguration} />
      <AdminRoute exact path={Routes.efileSubmission.MAIN} component={EFileSubmission} />
      <AdminRoute exact path={Routes.addDataCollectionReviewTask.MAIN} component={ComingSoonPage} />
      <AdminRoute exact path={Routes.addReturnTask.MAIN} component={ComingSoonPage} />
      <AdminRoute exact path={Routes.addLegalEntityReviewTask.MAIN} component={ComingSoonPage} />
      <AdminRoute exact path={Routes.reassignTask.MAIN} component={ComingSoonPage} />
      <AdminRoute exact path={Routes.batchTaskSignoff.MAIN} component={ComingSoonPage} />
      <AdminRoute exact path={Routes.entitiesMaintenance.MAIN} component={EntitiesMaintenance} />
      <AdminRoute exact path={Routes.roles.MAIN} component={Roles} />
      <AdminRoute exact path={Routes.permissions.MAIN} component={Permissions} />
      <AdminRoute exact path={Routes.constantsRulesReport.MAIN} component={ConstantsRulesReport} />
      <AdminRoute exact path={Routes.taxRateReport.MAIN} component={TaxRateReport} />
      <AdminRoute
        exact
        path={Routes.calculatedAccountsReport.MAIN}
        component={CalculatedAccountsReport}
      />
      <AdminRoute exact path={Routes.accountGridReport.MAIN} component={AccountGridReport} />
      <AdminRoute
        exact
        path={Routes.eFileSupport.MAIN}
        component={EFileSupport}
        disableBreadcrumbs
      />
      <AdminRoute
        exact
        path={Routes.federalDataDiagnostics.MAIN}
        component={FederalDataDiagnostics}
        disableBreadcrumbs
      />
      <AdminRoute
        exact
        path={Routes.federalDataDetailsWithCorrelationId.MAIN}
        component={FederalDataDetails}
        disableBreadcrumbs
      />
      <AdminRoute
        exact
        path={Routes.entityInformationDiagnostics.MAIN}
        component={entityInformationDiagnostics}
        disableBreadcrumbs
      />
      <AdminRoute
        exact
        path={Routes.entityInformationDetails.MAIN}
        component={EntityInformationDetails}
        disableBreadcrumbs
      />
      <AdminRoute
        exact
        path={Routes.entityInformationDetailsWithCorrelationId.MAIN}
        component={EntityInformationDetails}
        disableBreadcrumbs
      />
      <AdminRoute
        exact
        path={Routes.federalDataDetails.MAIN}
        component={FederalDataDetails}
        disableBreadcrumbs
      />
      <AdminRoute exact path={Routes.utilities.MAIN} component={Utilities} disableBreadcrumbs />
      <AdminRoute
        exact
        path={Routes.instanceDataCleanup.MAIN}
        component={InstanceDataCleanup}
        disableBreadcrumbs
      />
    </Switch>
  );
};

export default AdminRoutes;
