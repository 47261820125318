const Yup = require('yup');

const { noSpecialChar } = require('../validationRegex');

const baseSchema = Yup.array().of(
  Yup.object().shape({
    rowId: Yup.string().nullable(),
    calcOptions: Yup.string().nullable(),
    categoryId: Yup.string().label('Category').required(),
    accountId: Yup.string().label('Account').required(),
    accountJurisdictionId: Yup.string().label('Account Jurisdiction').required(),
    multiplier: Yup.number().label('Multiplier').required(),
    displayOrder: Yup.number().label('Display Order').required(),
    overrideFlag: Yup.bool().label('Entity Override'),
    orgId: Yup.mixed()
      .when('overrideFlag', {
        is: true,
        then: Yup.string().nullable().required(),
      })
      .when('overrideFlag', {
        is: false,
        then: Yup.string()
          .nullable()
          .oneOf([null], 'Overriding Entity must be null')
          .notOneOf([undefined]), // eslint-disable-line no-undefined
      })
      .label('Overriding Entity'),
    subcategory: Yup.string()
      .matches(noSpecialChar.regex, {
        message: noSpecialChar.message,
        excludeEmptyString: true,
      })
      .nullable(),
  }),
);

const consolSchema = Yup.array().of(
  Yup.object().shape({
    rowId: Yup.string().nullable(),
    taxYear: Yup.number().required(),
    jurisdictionId: Yup.string().required(),
    period: Yup.number().required(),
    calcOptions: Yup.string().nullable(),
    categoryId: Yup.string().label('Category').required(),
    consolId: Yup.string().label('Consolidation'),
    accountId: Yup.string().label('Account').required(),
    accountJurisdictionId: Yup.string().label('Account Jurisdiction').required(),
    multiplier: Yup.number().label('Multiplier').required(),
    displayOrder: Yup.number().label('Display Order').required(),
    subcategory: Yup.string()
      .matches(noSpecialChar.regex, {
        message: noSpecialChar.message,
        excludeEmptyString: true,
      })
      .nullable(),
  }),
);

const conspbSchema = Yup.array().of(
  Yup.object().shape({
    rowId: Yup.string().nullable(),
    calcOptions: Yup.string().nullable(),
    categoryId: Yup.string().label('Category').required(),
    consolId: Yup.string().label('Consolidation'),
    accountId: Yup.string().label('Account').required(),
    accountJurisdictionId: Yup.string().label('Account Jurisdiction').required(),
    multiplier: Yup.number().label('Multiplier').required(),
    displayOrder: Yup.number().label('Display Order').required(),
    subcategory: Yup.string()
      .matches(noSpecialChar.regex, {
        message: noSpecialChar.message,
        excludeEmptyString: true,
      })
      .nullable(),
  }),
);

const k1Schema = Yup.array().of(
  Yup.object().shape({
    rowId: Yup.string(),
    taxYear: Yup.number().required(),
    jurisdictionId: Yup.string().required(),
    period: Yup.number().required(),
    displayOrder: Yup.number().required(),
    runLevel: Yup.number().required(),
    forcePlug: Yup.bool().required(),
    k1FunctionFlag: Yup.bool().required(),
    k1FunctionType: Yup.string().nullable(),
    percentageType: Yup.string().nullable(),
    k1CalcId: Yup.string().required(),
    screenType: Yup.string().required(),
    attachId: Yup.string().nullable(),
    calcOptions: Yup.string().nullable(),
    subcategory: Yup.string()
      .matches(noSpecialChar.regex, {
        message: noSpecialChar.message,
        excludeEmptyString: true,
      })
      .nullable(),
  }),
);

module.exports = {
  baseSchema,
  consolSchema,
  conspbSchema,
  k1Schema,
};
