import { GridApi } from 'ag-grid-community';

export const validateNumberCellEditorValue = ({
  min,
  max,
  value,
}: {
  min: number;
  max: number;
  value: number | string;
}) => (typeof min !== 'undefined' && value < min) || (typeof max !== 'undefined' && value > max);

export const formatDate = (date: string) => {
  if (!date) {
    return date;
  }
  const [firstDatePart, secondDatePart, thirdDatePart] = date.split('/');

  // TODO: Update temporary solution in https://jira.ist.pwc.com/browse/SLT-8669
  const [month, day, year] =
    firstDatePart.length === 4
      ? [secondDatePart, thirdDatePart, firstDatePart] // Assuming YYYY/MM/DD format - changing order
      : [firstDatePart, secondDatePart, thirdDatePart]; // Assuming MM/DD/YYYY format - leaving the same order

  return [month, day, year].join('/');
};

export const handleUpOrDownArrowKeyDown = (gridApi: GridApi, eventKey: KeyboardEvent['key']) => {
  if (eventKey !== 'ArrowDown' && eventKey !== 'ArrowUp') {
    return;
  }

  const focusedCell = gridApi.getFocusedCell();

  if (!focusedCell) {
    return;
  }

  const isMovingDown = eventKey === 'ArrowDown';
  const newIndex = isMovingDown ? focusedCell.rowIndex + 1 : focusedCell.rowIndex - 1;
  const rowCount = gridApi.getDisplayedRowCount();

  if ((isMovingDown && newIndex < rowCount) || (!isMovingDown && newIndex >= 0)) {
    gridApi.ensureIndexVisible(newIndex);
    gridApi.setFocusedCell(newIndex, focusedCell.column);

    gridApi.clearRangeSelection();
    gridApi.addCellRange({
      rowStartIndex: newIndex,
      rowEndIndex: newIndex,
      columnStart: focusedCell.column,
      columnEnd: focusedCell.column,
    });
  }
};
