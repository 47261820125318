import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AppkitIcon from '../../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import Loading from '../../../../shared/displayComponents/loading.component';
import SDKCustomSelect from '../../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import ListHierarchy from '../../../../shared/displayComponents/listHierarchy/listHierarchy';
import useOpenInNewTab from '../../../../shared/hooks/useOpenInNewTab';
import {
  fetchDataModels,
  selectDataModel,
  selectDataset,
  selectDataItem,
} from '../../../../shared/store/dataModels/actions';
import { setIsIgnoringCalculationDataChanges } from '../../store/actions';
import { taxYearSelector, jurisdictionIdSelector } from '../../../store/selectors';
import {
  dataModelSelector,
  dataModelsSelector,
  datasetSelector,
  datasetsTreeSelector,
  isFetchingDataModelsSelector,
  isFetchingDatasetsSelector,
  isFetchingDataItemsSelector,
  dataItemsSelector,
} from '../../../../shared/store/dataModels/selectors';
import { datasetsOptionSelector, dataItemIdSelector } from '../../store/selectors';
import generateExpression from '../generateExpression';

import styles from './calculationDataItems.module.scss';
import CalculationDataItemsGrid from './calculationDataItemsGrid.component';

const CalculationDataItems = ({
  addDataItemExpression,
  addDataItemConsolExpression,
  updateDirtyItems,
  generateContextMenuItems,
}) => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const dataModel = useSelector(dataModelSelector);
  const dataModels = useSelector(dataModelsSelector);
  const dataset = useSelector(datasetSelector);
  const datasetsTree = useSelector(datasetsTreeSelector);
  const dataItems = useSelector(dataItemsSelector);
  const isFetchingDataModels = useSelector(isFetchingDataModelsSelector);
  const isFetchingDatasets = useSelector(isFetchingDatasetsSelector);
  const isFetchingDataItems = useSelector(isFetchingDataItemsSelector);
  const expressionCalculationDataset = useSelector(datasetsOptionSelector);
  const activeDataItemId = useSelector(dataItemIdSelector);

  const datasetId = dataset?.id;

  useEffect(() => () => dispatch(setIsIgnoringCalculationDataChanges(false)), [dispatch]);

  const onDataModelChange = useCallback(
    selectedDataModel => {
      if (!selectedDataModel || dataModel === selectedDataModel) {
        return;
      }
      dispatch(setIsIgnoringCalculationDataChanges(true));
      dispatch(selectDataModel(selectedDataModel.value));
    },
    [dispatch, dataModel],
  );

  const openInNewTab = useOpenInNewTab();

  const handleDataModelsButtonClick = useCallback(() => {
    openInNewTab(Routes.developmentDataModels.MAIN, {
      taxYear,
      jurisdictionId,
    });
  }, [openInNewTab, taxYear, jurisdictionId]);

  const onDatasetClick = useCallback(
    ({ id }) => {
      if (datasetId === id) {
        return;
      }
      dispatch(setIsIgnoringCalculationDataChanges(true));
      dispatch(selectDataset(id));
    },
    [dispatch, datasetId],
  );

  const refresh = useCallback(() => {
    updateDirtyItems();
    if (taxYear && jurisdictionId) {
      dispatch(selectDataModel(null));
      dispatch(fetchDataModels({ taxYear, jurisdictionId }));
    }
  }, [dispatch, updateDirtyItems, taxYear, jurisdictionId]);

  const selectToExpression = useCallback(
    ({ data: dataItem }) => {
      if (!dataItem) {
        return;
      }
      addDataItemExpression(
        generateExpression({ dataset, expressionCalculationDataset, dataItemName: dataItem.name }),
      );
    },
    [addDataItemExpression, dataset, expressionCalculationDataset],
  );

  const selectToConsolExpression = useCallback(
    ({ data: dataItem }) => {
      if (!dataItem) {
        return;
      }
      addDataItemConsolExpression(
        generateExpression({ dataset, expressionCalculationDataset, dataItemName: dataItem.name }),
      );
    },
    [addDataItemConsolExpression, dataset, expressionCalculationDataset],
  );

  const onDataItemClick = useCallback(
    ({ data }) => {
      if (data) {
        dispatch(setIsIgnoringCalculationDataChanges(false));
        dispatch(selectDataItem(data.id));
        updateDirtyItems();
      }
    },
    [dispatch, updateDirtyItems],
  );

  return (
    <div className={styles.dataItemsView}>
      <div className={styles.dataItemsButtons}>
        <Button size="lg" kind="secondary" onClick={handleDataModelsButtonClick}>
          Data Models
        </Button>
        <button title="Refresh data" className={styles.refreshDataButton} onClick={refresh}>
          <AppkitIcon icon="refresh" size={16} />
        </button>
      </div>
      <div className={styles.dataTypes}>
        <div className={styles.dataModelsAndDatasets}>
          <Loading small isLoading={isFetchingDataModels}>
            <SDKCustomSelect
              appkitLabel="Data Model:"
              className={`sdk-custom-select ${styles.dataModelSelect}`}
              options={dataModels}
              value={dataModel}
              virtualized
              onChange={onDataModelChange}
            />
          </Loading>
          <div className={styles.datasetsContainer}>
            <ListHierarchy
              label="DataSets:"
              isLoading={isFetchingDatasets || isFetchingDataModels}
              items={datasetsTree}
              selectedItemIds={[datasetId]}
              itemOnClick={onDatasetClick}
              smallSpinner
            />
          </div>
        </div>
        <div className={styles.gridContainer}>
          <CalculationDataItemsGrid
            label="DataItems:"
            isLoading={isFetchingDataItems || isFetchingDatasets || isFetchingDataModels}
            dataItems={dataItems}
            commandClickHandler={selectToConsolExpression}
            doubleClickHandler={selectToExpression}
            singleClickHandler={onDataItemClick}
            activeDataItemId={activeDataItemId}
            generateContextMenuItems={generateContextMenuItems}
          />
        </div>
      </div>
    </div>
  );
};

CalculationDataItems.propTypes = {
  addDataItemExpression: PropTypes.func.isRequired,
  addDataItemConsolExpression: PropTypes.func.isRequired,
  updateDirtyItems: PropTypes.func.isRequired,
  generateContextMenuItems: PropTypes.func.isRequired,
};

export default CalculationDataItems;
