import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../../shared/hooks/useModal.hook';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import useFetch from '../../shared/hooks/useFetch.hook';

import AddRoleFormModal from './addRoleFormModal.container';
import getColumnDefinitions from './roles.columnDefinitions';
import * as api from './store/api';

const Roles = ({ showConfirmModal }) => {
  const { data: roles, fetch: fetchRoles, isFetching: isFetchingRoles } = useFetch({
    action: api.fetchRoles,
  });

  const { fetch: deleteRole } = useFetch({
    action: api.deleteRole,
    successNotificationMessage: 'Role has been successfully deleted',
  });

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const { showModal, modalProps } = useModal();

  const openModalForAdd = useCallback(() => {
    showModal(null);
  }, [showModal]);

  const openModalForEdit = useCallback(
    row => {
      showModal(row);
    },
    [showModal],
  );

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete role?',
        text:
          'All permissions on this role will be lost. Are you sure you want to delete this role?',
        confirmCallback: async () => {
          await deleteRole({
            roleId: row.roleId,
          });
          fetchRoles();
        },
      });
    },
    [showConfirmModal, deleteRole, fetchRoles],
  );

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        onEditIconClick: openModalForEdit,
        onDeleteIconClick: openModalForDelete,
      }),
    [openModalForEdit, openModalForDelete],
  );

  return (
    <>
      <div className="col add-button-column">
        <Button
          size="lg"
          className="add-button"
          onClick={openModalForAdd}
          disabled={isFetchingRoles}
        >
          Add Role
        </Button>
      </div>

      <div className="row grid-row">
        <div className="col-12">
          <AgGrid
            rowData={roles}
            isGridLoading={isFetchingRoles}
            singleClickEdit
            columnDefs={columnDefinitions}
          />
        </div>
        <AddRoleFormModal {...modalProps} roles={roles} fetchRoles={fetchRoles} />
      </div>
    </>
  );
};

Roles.propTypes = {
  showConfirmModal: PropTypes.func.isRequired,
};

export default connect(null, {
  showConfirmModal,
})(Roles);
