import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';

const DataCollection = () => (
  <div className="row row-with-cards">
    <AuthorizedCard
      title="Federal Reconciliation"
      icon="table-data"
      description="Federal Reconciliation"
      route={Routes.federalReconciliation.MAIN}
    />
    <AuthorizedCard
      title="Manually-entered Data"
      icon="table-data"
      description="Manually-entered Data"
      route={Routes.manuallyEnteredData.MAIN}
    />
    <AuthorizedCard
      title="Prior-year Comparison Report - Detail"
      icon="table-data"
      description="Prior-year Comparison Report - Detail"
      route={Routes.priorYearComparisonReportDetail.MAIN}
    />
    <AuthorizedCard
      title="Prior-year Comparison Report - Summary"
      icon="table-data"
      description="Prior-year Comparison Report - Summary"
      route={Routes.priorYearComparisonReportSummary.MAIN}
    />
  </div>
);

export default DataCollection;
