import { useState, useEffect, useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { useMutationCompileExpression } from '../../../shared/mutations/expressionBuilder';
import { QueryKeys } from '../../../shared/queries';
import { useQueryCompilationInfo } from '../../../shared/queries/expressionBuilder';
import { ALL_JURISDICTION_ID } from '../../../shared/constants';

interface UseCompileButtonLogicProps {
  taxYear?: string;
  jurisdictionId?: string;
}

const useCompileButtonLogic = ({ taxYear, jurisdictionId }: UseCompileButtonLogicProps) => {
  const queryClient = useQueryClient();

  const [validationDate, setValidationDate] = useState('');

  const { data: compilationInfo, isFetching: isFetchingCompilationInfo } = useQueryCompilationInfo({
    enabled: Boolean(taxYear && jurisdictionId && jurisdictionId !== ALL_JURISDICTION_ID),
    params: { taxYear, jurisdictionId },
  });

  const {
    mutateAsync: runCompilation,
    isLoading: isCompiling,
    data: compilationResultData,
    reset: resetResultData,
  } = useMutationCompileExpression();

  const compilationResult = useMemo(() => {
    if (compilationResultData) {
      return compilationResultData;
    }
    if (compilationInfo) {
      return compilationInfo.diagnosticGridData;
    }
    return null;
  }, [compilationResultData, compilationInfo]);

  useEffect(() => {
    resetResultData();
  }, [resetResultData, taxYear, jurisdictionId]);

  const handleCompileButtonClick = useCallback(async () => {
    if (taxYear && jurisdictionId) {
      setValidationDate(new Date().toLocaleString());
      await runCompilation({ taxYear, jurisdictionId });

      queryClient.resetQueries(QueryKeys.ExpressionBuilder.CompilationInfo);
    }
  }, [runCompilation, taxYear, jurisdictionId, queryClient]);

  const isCompileButtonDisabled = useMemo(
    () =>
      isCompiling ||
      isFetchingCompilationInfo ||
      !taxYear ||
      !jurisdictionId ||
      !compilationInfo?.totalExpressions,
    [
      isCompiling,
      isFetchingCompilationInfo,
      taxYear,
      jurisdictionId,
      compilationInfo?.totalExpressions,
    ],
  );

  return {
    compilationInfo,
    isFetchingCompilationInfo,
    compilationResult,
    validationDate,
    isCompiling,
    handleCompileButtonClick,
    isCompileButtonDisabled,
  };
};

export default useCompileButtonLogic;
