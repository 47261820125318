import { createSelector } from 'reselect';

export const businessesSelector = createSelector(
  [({ admin }) => admin.businesses.data.businesses],
  businesses =>
    businesses.map(business => ({
      ...business,
      tier: business.tier ? business.tier.toString() : null,
    })),
);

export const businessGroupsSelector = createSelector(
  [({ admin }) => admin.businesses.data.businessGroups],
  businessGroups => [{ value: 'N/A', label: 'N/A', activeYN: true }, ...businessGroups],
);

export const parentsSelector = ({ admin }) => admin.businesses.data.parents;

export const customersSelector = ({ admin }) => admin.businesses.data.customers;

export const isFetchingBusinessesSelector = ({ admin }) => admin.businesses.isFetchingBusinesses;

export const isCreatingBusinessSelector = ({ admin }) => admin.businesses.isInsertingBusiness;

export const isUpdatingBusinessSelector = ({ admin }) => admin.businesses.isUpdatingBusiness;
