import { ICellRendererParams } from 'ag-grid-community';

type CellRendererFunc = (params: ICellRendererParams) => string | null | JSX.Element | undefined;

interface CellRendererParams {
  params: ICellRendererParams;
  renderer: string | null | JSX.Element | CellRendererFunc;
}

export const cellRenderer = ({ params, renderer }: CellRendererParams) => {
  if (typeof renderer === 'function') {
    return renderer(params) || null;
  }
  return renderer || null;
};
