import PropTypes from 'prop-types';

export const rowDetailsPropTypes = PropTypes.shape({
  rowId: PropTypes.string,
  rowType: PropTypes.string.isRequired,
  dataType: PropTypes.string,
  categoryId: PropTypes.string,
  accountId: PropTypes.string,
  text: PropTypes.string,
  textType: PropTypes.string,
  percentage: PropTypes.bool.isRequired,
  commas: PropTypes.bool.isRequired,
  showAdj: PropTypes.bool.isRequired,
  borders: PropTypes.bool.isRequired,
  decimalPlaces: PropTypes.string,
  drilldownType: PropTypes.string,
});
