import { HttpMethods } from '../enums';
import { QueryError } from '../queries';

import { useCustomMutation } from './index';

interface RemoveNotificationsVariable {
  notifications: string[];
}

export const useMutationRemoveNotifications = () =>
  useCustomMutation<null, QueryError, RemoveNotificationsVariable>({
    handleData: ({ notifications }) => ({
      url: '/api/notifications',
      method: HttpMethods.DELETE,
      body: { notifications },
    }),
  });
