import { EFileStepStatusPayload } from '../../../../../common/types';

import { WizardStateMachineContext } from './types';

const handleEFileStepPayload = (
  payload: EFileStepStatusPayload | null,
  context: WizardStateMachineContext,
) => {
  return {
    ...context,
    ...(payload?.efSubmissionId ? { efServiceSubmissionId: payload?.efSubmissionId } : {}),
    ...(payload?.stateSubmissionId
      ? {
          stateReturn: {
            ...context.stateReturn,
            submissionId: payload?.stateSubmissionId,
          },
        }
      : {}),
  };
};

export default handleEFileStepPayload;
