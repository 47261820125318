import React from 'react';

import StepTab from '../stepTab/stepTab.component';
import Loading from '../../../../shared/displayComponents/loading.component';
import StepInformationCard, {
  StepInformationCardType,
} from '../stepInformationCard/stepInformationCard.component';
import { SubmissionId } from '../../../../../../common/types';
import { FederalReturnOption } from '../../../../shared/enums/eFileV2';

import useReviewStepContext from './useReviewStepContext.hook';
import styles from './reviewStep.module.scss';

interface ReviewStepProps {
  stateSubmissionId: SubmissionId | null;
  federalSubmissionId: SubmissionId | null;
  federalReturnOption: FederalReturnOption | null;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
}

const ReviewStep = ({
  stateSubmissionId,
  federalSubmissionId,
  federalReturnOption,
  isFetchingTaxReturnOrTransmitterProviderTR,
}: ReviewStepProps) => {
  const reviewStepContext = useReviewStepContext({
    stateSubmissionId,
    federalSubmissionId,
    federalReturnOption,
    isFetchingTaxReturnOrTransmitterProviderTR,
  });

  const shouldShowFederalReturnWarnningMessage =
    federalReturnOption === FederalReturnOption.FED_AS_FILED ||
    federalReturnOption === FederalReturnOption.CONS_AS_FILED ||
    federalReturnOption === FederalReturnOption.CONS_PROFORMA ||
    federalReturnOption === FederalReturnOption.FED_PROFORMA;

  return (
    <StepTab
      title="Review your transmission"
      wrapperClassName={styles.tab}
      contentClassName={styles.content}
    >
      <Loading isLoading={reviewStepContext.isLoading}>
        <span className={styles.contentTitle}>
          The following will be submitted to the{' '}
          <span className={styles.jurisdiction}>
            {reviewStepContext.jurisdictionDescription?.toLowerCase()}
          </span>{' '}
          agency
        </span>

        {reviewStepContext.isMissingFederalReturn && shouldShowFederalReturnWarnningMessage && (
          <StepInformationCard
            title="Federal return is not available yet"
            subtitle="Your federal return must be included before filing, continuing will lead to a rejection."
            type={StepInformationCardType.WARNING}
          />
        )}
        <StepInformationCard
          title={reviewStepContext.isSeparate ? 'Entity ID' : 'Consolidation ID'}
          subtitle={reviewStepContext.businessEntityId}
        />
        <StepInformationCard
          title="State Submission ID"
          subtitle={reviewStepContext.stateSubmissionId || ''}
          fileLinks={reviewStepContext.stateSubmissions}
        />
        {!reviewStepContext.isMissingFederalReturn && (
          <StepInformationCard
            title="Federal Submission ID"
            subtitle={reviewStepContext.federalSubmissionId || 'Not included'}
            fileLinks={reviewStepContext.federalSubmissions}
            disabled={!reviewStepContext.federalSubmissionId}
          />
        )}
      </Loading>
    </StepTab>
  );
};

export default ReviewStep;
