import React, { useMemo, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Tab, Tabs } from '@pwc/appkit-react';
import camelCase from 'lodash.camelcase';
import omit from 'lodash.omit';
import { ConsolidationMethodFilingTypes, ColumnTypes } from '@common-packages/shared-constants';

import {
  GlobalContext,
  TaxReturn,
  FindTaxReturnsColumnsBlueprint,
  FindConsolidatedTaxReturnsResponse,
  FilingAttributes,
  CreateConsolidationTaxReturn,
  CalcPreferenceDefinitions,
  UpdateConsolidationTaxReturnData,
  UpdateConsolidationTaxReturnParams,
} from '../../../../../common/types/apiShapes';
import { globalContextSelector } from '../../store/selectors';
import SlideIn from '../../displayComponents/slideIn/slideIn.component';
import { showConfirmModal } from '../../confirmModal/store/actions';
import {
  useQueryTaxReturnsReturnDefinitions,
  useQueryTaxReturnsReturnDefinitionsJurisdictionOptions,
} from '../../queries/taxReturns';
import {
  useMutationCreateConsolidationTaxReturn,
  useMutationUpdateConsolidationTaxReturn,
} from '../../mutations/consolidationsMaintenance';
import {
  ConsolidationFormValues,
  ConsolidationAddEditFormValues,
  useFormInitialValues,
} from '../utils/useFormInitialValues';
import { fetchConsolidations } from '../../store/actions';

import AddEditReturnFormWrapper from './addEditReturnFormWrapper.container';
import styles from './addEditReturnPanel.module.scss';

export const TabsTypes = {
  RETURN_DETAILS: 'RETURN_DETAILS',
  FILING_GROUPS: 'FILING_GROUPS',
  CALC_PREFERENCES: 'CALC_PREFERENCES',
};

export const consolidationWizardWrapperID = 'consol-wizard-wrapper-id';

const tabsDefinitions = [
  { type: TabsTypes.RETURN_DETAILS, label: 'Return Details' },
  { type: TabsTypes.FILING_GROUPS, label: 'Filing Groups' },
  { type: TabsTypes.CALC_PREFERENCES, label: 'Calc Preferences' },
];

interface AddEditReturnPanelProps {
  hidePanel: () => void;
  isPanelVisible: boolean;
  taxReturnToEdit: TaxReturn | null;
  taxReturns?: FindConsolidatedTaxReturnsResponse;
  columnsBlueprint: FindTaxReturnsColumnsBlueprint;
  calcPreferencesDefinitions: CalcPreferenceDefinitions;
  onUpdateConsolidationSuccess?: (
    data: UpdateConsolidationTaxReturnData,
    params: UpdateConsolidationTaxReturnParams,
  ) => void;
}

const AddEditReturnPanel = ({
  hidePanel,
  isPanelVisible,
  taxReturnToEdit,
  taxReturns,
  columnsBlueprint,
  calcPreferencesDefinitions,
  onUpdateConsolidationSuccess,
}: AddEditReturnPanelProps) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const { taxYear = '', period = '', filingTypeId } = globalContext;
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0); // TODO: consolidation in tax return
  const dispatch = useDispatch();

  const formRef = useRef<FormikProps<ConsolidationFormValues>>(null);

  const initialValues: ConsolidationFormValues = useFormInitialValues({
    taxReturnToEdit,
    taxYear,
    period,
    isConsolidation: true,
  }) as ConsolidationFormValues;

  const activeTab = useMemo(() => tabsDefinitions[activeTabIndex].type, [activeTabIndex]);
  const setActiveTab = useCallback((tabName: string) => {
    const _activeTabIndex = tabsDefinitions.findIndex(
      tabDefinition => tabDefinition.type === tabName,
    );
    setActiveTabIndex(_activeTabIndex || 0);
  }, []);

  const doFetchConsolidations = useCallback(() => {
    if (taxYear && period) {
      dispatch(fetchConsolidations(taxYear, period));
    }
  }, [dispatch, taxYear, period]);

  const onMutationConsolidationTaxReturnSuccess = useCallback(() => {
    setActiveTab(TabsTypes.RETURN_DETAILS);
    hidePanel();
  }, [setActiveTab, hidePanel]);

  const { mutateAsync: createConsolidation } = useMutationCreateConsolidationTaxReturn({
    onSuccess: () => {
      doFetchConsolidations();
      onMutationConsolidationTaxReturnSuccess();
    },
  });

  const { mutateAsync: updateConsolidation } = useMutationUpdateConsolidationTaxReturn({
    onSuccess: (data, variables) => {
      const { consolidation, taxReturnsData } = variables;
      const originalConsolReturn = taxReturnsData.filter(
        taxReturn => taxReturn.consolidationId === consolidation.consolidationId,
      );
      // if active is not changed avoid update consolidation context
      if (originalConsolReturn[0].active !== consolidation.active) {
        doFetchConsolidations();
      }
      onMutationConsolidationTaxReturnSuccess();
      if (onUpdateConsolidationSuccess) {
        onUpdateConsolidationSuccess(data, variables);
      }
    },
  });

  const isEditing = Boolean(taxReturnToEdit?.consolidationId);

  const onNextButtonClick = useCallback(() => {
    if (activeTabIndex < tabsDefinitions.length) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  }, [activeTabIndex]);

  const onBackButtonClick = useCallback(() => {
    if (activeTabIndex > 0) {
      setActiveTabIndex(activeTabIndex - 1);
    }
  }, [setActiveTabIndex, activeTabIndex]);

  const hideAddReturnPanel = useCallback(() => {
    setActiveTab(TabsTypes.RETURN_DETAILS);
    hidePanel();
  }, [setActiveTab, hidePanel]);

  const {
    data: taxReturnsDefinitions,
    isLoading: isLoadingTaxReturnsDefinitions,
  } = useQueryTaxReturnsReturnDefinitions({
    params: {
      taxYear: taxYear || '',
      period: period || '',
      filingTypeId: filingTypeId || '',
    },
    enabled: Boolean(taxYear && period && filingTypeId),
  });

  const {
    data: taxReturnsDefinitionsJurisdictionOptions,
    isLoading: isLoadingTaxReturnsDefinitionsJurisdictionOptions,
  } = useQueryTaxReturnsReturnDefinitionsJurisdictionOptions({
    params: { taxYear: taxYear || '', filingTypeId: filingTypeId || '' },
    enabled: Boolean(taxYear && filingTypeId),
  });

  const returnDefinitionJurisdictions = useMemo(
    () => taxReturnsDefinitionsJurisdictionOptions?.map(({ value }) => value || '') || [],
    [taxReturnsDefinitionsJurisdictionOptions],
  );

  const taxReturnsData = useMemo(
    () =>
      taxReturns?.data.filter(({ jurisdictionId }) =>
        returnDefinitionJurisdictions.includes(jurisdictionId),
      ) || [],
    [taxReturns?.data, returnDefinitionJurisdictions],
  );

  const handleHidePanel = useCallback(() => {
    if (formRef.current?.dirty) {
      dispatch(
        showConfirmModal({
          title: 'Do you want to leave this Return?',
          text: 'The changes you made have not been saved.',
          confirmText: 'Leave',
          dismissText: 'Stay',
          confirmCallback: hideAddReturnPanel,
        }),
      );
      return;
    }
    hideAddReturnPanel();
  }, [dispatch, hideAddReturnPanel]);

  const onSubmit = useCallback(
    async (
      values: ConsolidationFormValues,
      { setSubmitting, resetForm }: FormikHelpers<ConsolidationFormValues>,
    ) => {
      const consolData = {
        ...values,
        oldParentOrgId:
          values?.parentOrgId !== initialValues?.parentOrgId ? initialValues?.parentOrgId : null,
      } as ConsolidationAddEditFormValues;

      const filingGroupIds = values.filingGroupIds;
      const filingAttributesColumnsBlueprint = columnsBlueprint.filter(
        ({ columnType }) => columnType === ColumnTypes.FILING_ATTRIBUTES,
      );

      filingAttributesColumnsBlueprint.forEach(({ children, field: columnField }) => {
        (children || []).forEach(({ field = '' }) => {
          if (columnField === 'PERIOD_TYPE') {
            return;
          }
          if (field === 'TAX_TYPE_INCOME') {
            consolData[field] = consolData.taxType.incomeTax;
            return;
          }
          if (field === 'TAX_TYPE_FRANCHISE') {
            consolData[field] = consolData.taxType.franchiseTax;
            return;
          }
          if (columnField) {
            consolData[field as keyof FilingAttributes] =
              field === consolData[camelCase(columnField) as keyof ConsolidationAddEditFormValues];
          }
        });
      });
      consolData.consolidationMethod =
        Object.entries(ConsolidationMethodFilingTypes).find(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([key, filingType]) => filingType === consolData.filingType,
        )?.[0] || '';
      const addEditConsol = omit(consolData, [
        'filingType',
        'filingGroupIds',
        ...filingAttributesColumnsBlueprint.map(({ field }) => camelCase(field)),
      ]) as CreateConsolidationTaxReturn | UpdateConsolidationTaxReturnParams['consolidation'];

      !isEditing
        ? await createConsolidation({
            consolidation: addEditConsol as CreateConsolidationTaxReturn,
            filingGroupIds,
          })
        : await updateConsolidation({
            consolidation: addEditConsol as UpdateConsolidationTaxReturnParams['consolidation'],
            filingGroupIds,
            taxReturnsData,
          });

      setSubmitting(false);
      resetForm();
    },
    [
      createConsolidation,
      updateConsolidation,
      hidePanel,
      setActiveTab,
      taxReturnsData,
      columnsBlueprint,
      isEditing,
      initialValues,
    ],
  );

  return (
    <SlideIn
      className={styles.panel}
      isOpen={isPanelVisible}
      onRequestClose={handleHidePanel}
      width="700px"
      closeIconName="close"
      title={`${isEditing ? 'Edit' : 'Add'} Return`}
    >
      <div className="navigation-tabs-wrapper" id={consolidationWizardWrapperID}>
        <div className="tabs-wrapper">
          <Tabs className="tabs-container" value={activeTab} size="md">
            {tabsDefinitions.map(({ label, type }) => (
              <Tab id={type} value={type} label={label} key={type} />
            ))}
          </Tabs>
        </div>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <AddEditReturnFormWrapper
          isEditing={isEditing}
          activeTab={activeTab}
          isLoadingTaxReturnsDefinitionsJurisdictionOptions={
            isLoadingTaxReturnsDefinitionsJurisdictionOptions
          }
          isLoadingTaxReturnsDefinitions={isLoadingTaxReturnsDefinitions}
          taxReturns={taxReturnsData}
          columnsBlueprint={columnsBlueprint}
          calcPreferencesDefinitions={calcPreferencesDefinitions}
          taxReturnsDefinitions={taxReturnsDefinitions || []}
          taxReturnsDefinitionsJurisdictionOptions={taxReturnsDefinitionsJurisdictionOptions || []}
          onNextButtonClick={onNextButtonClick}
          onBackButtonClick={onBackButtonClick}
        />
      </Formik>
    </SlideIn>
  );
};

export default AddEditReturnPanel;
