import React from 'react';
import PropTypes from 'prop-types';

const AppkitIcon = ({ icon, size, className = '', type = 'outline', ...rest }) => (
  <i className={`${className} icon-${icon}-${type} icon-size-${size}`} {...rest} />
);

AppkitIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['outline', 'fill', 'full']),
  size: PropTypes.number.isRequired,
};

export default AppkitIcon;
