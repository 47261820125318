import {
  adminColumnDefinition,
  defaultColumnDefinitionWithFilter,
  currencyColumnDefinition,
} from '../../../shared/columnDefinitions';
import { AdminCellRendererFactory } from '../../../shared/columnDefinitions/cellRenderers';
import {
  currencyCellFormatter,
  NumeralFormats,
  numeralFormatter,
} from '../../../shared/formatters';
import { MnemonicDisplayValues } from '../enums';

const getColumnDefinitions = ({ isInEditMode, onDeleteIconClick }) => {
  const AdminCellRenderer = AdminCellRendererFactory({
    shouldShowEditIcon: false,
    shouldShowDeleteIcon: isInEditMode,
    onDeleteIconClick,
  });

  return [
    {
      ...adminColumnDefinition,
      cellRenderer: AdminCellRenderer,
      width: 40,
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Constant ID',
      field: 'constantId',
      sort: 'asc',
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Description',
      field: 'description',
      width: 400,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Value',
      field: 'value',
      width: 70,
      minWidth: 70,
      valueFormatter: ({ value }) =>
        Number(value) % 1 === 0
          ? currencyCellFormatter({ value })
          : numeralFormatter({ value, format: NumeralFormats.NUMBER_5_OPTIONAL_DECIMALS }),
    },
    {
      ...defaultColumnDefinitionWithFilter,
      headerName: 'Constant Mnemonic',
      field: 'mnemonic',
      width: 100,
      valueFormatter: ({ value }) => MnemonicDisplayValues[value],
      hide: true,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Filing Attributes',
      field: 'filingAttributes',
      minWidth: 100,
      width: 100,
    },
    {
      ...defaultColumnDefinitionWithFilter,
      ...currencyColumnDefinition,
      headerName: 'Calc Preferences',
      field: 'calcPreferences',
      minWidth: 100,
      width: 100,
    },
  ];
};

export default getColumnDefinitions;
