import getAvailableDatasetsForPage from './getAvailableDatasetsForPage';
import getDataItemsOptionsBasedOnAssignedDataset from './getDataItemsOptionsBasedOnAssignedDataset';

const mapFieldsToUpdate = ({ newFields, fieldsToUnmap, newPdfPageDataUsage, dataItems }) =>
  Object.keys(newFields).reduce((acc, fieldId) => {
    const fieldData = newFields[fieldId];

    const isFieldToUnmap = fieldsToUnmap.some(({ id }) => id === fieldId);
    if (isFieldToUnmap) {
      return acc;
    }

    const datasetsAssignedToFormPage = getAvailableDatasetsForPage(
      newPdfPageDataUsage,
      fieldData.pageNumber,
    );
    const isDataSetAssigned = datasetsAssignedToFormPage?.some(
      ({ value }) => value === fieldData.datasetId,
    );
    if (!isDataSetAssigned) {
      return acc;
    }

    const dataItemsOptions = getDataItemsOptionsBasedOnAssignedDataset({
      dataItems,
      datasetId: fieldData.datasetId,
      datasetsAssignedToFormPage,
    });
    const isDataItemAvailable = dataItemsOptions.some(
      ({ value }) => value === fieldData.dataItemId,
    );
    if (!isDataItemAvailable) {
      return acc;
    }

    const datasetUsageId = newPdfPageDataUsage.find(
      ({ datasetId, pageNumber }) =>
        datasetId === fieldData.datasetId && pageNumber === fieldData.pageNumber,
    ).id;

    const fieldDataToUpdate = {
      datasetUsageId,
      dataItemDefId: fieldData.dataItemId,
      pdfFieldId: fieldData.id,
      pdfFieldMappingId: fieldData.pdfFieldMappingId,
      upperCase: Number(fieldData.upperCase),
      valueIfZero: Number(fieldData.valueIfZero),
      absoluteValue: Number(fieldData.absoluteValue),
      suppressCommas: Number(fieldData.suppressCommas),
      parensIfNegative: Number(fieldData.parensIfNegative),
      ratioAsPercentage: Number(fieldData.ratioAsPercentage),
      decimalPlaces: Number(fieldData.decimalPlaces),
      appendText: fieldData.appendText,
      wrapText: Number(fieldData.wrapText),
      printFirstCopyOnly: Number(fieldData.printFirstCopyOnly),
      printLastCopyOnly: Number(fieldData.printLastCopyOnly),
      wrapFirstRowIndt: fieldData.wrapFirstRowIndt,
      wrapFullRowcount: fieldData.wrapFullRowcount,
      wrapRowspacingIncrement: fieldData.wrapRowspacingIncrement,
      characterSpacing: fieldData.characterSpacing,
      fullfieldPattern: fieldData.fullfieldPattern,
      fullfieldLength: Number(fieldData.fullfieldLength),
      formattingString: fieldData.formattingString,
      checkBox: fieldData.checkBox === '1' ? 1 : 0,
      ynCheckBox:
        fieldData.checkBox === 'Y' || fieldData.checkBox === 'N' ? fieldData.checkBox : null,
      flipSign: Number(fieldData.flipSign),
      filterRegExp: fieldData.filterRegExp,
      suppressFieldDataItemDefId: fieldData.suppressFieldDataItemDefId,
      suppressFieldDatasetDefId: fieldData.suppressFieldDatasetDefId,
    };

    return {
      ...acc,
      [fieldId]: fieldDataToUpdate,
    };
  }, {});

export default mapFieldsToUpdate;
