import React from 'react';
import { Button } from '@pwc/appkit-react';

type ResetReturnEFileStatusButtonProps = {
  isDisabled: boolean;
  onClick: () => void;
};

export const ResetReturnEFileStatusButton: React.FC<ResetReturnEFileStatusButtonProps> = ({
  isDisabled,
  onClick,
}) => {
  return (
    <Button
      size="lg"
      className="add-button"
      disabled={isDisabled}
      onClick={onClick}
      kind="secondary"
    >
      RESTART RETURN
    </Button>
  );
};
