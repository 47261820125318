import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { taxYearSelector, periodSelector } from '../../shared/store/selectors';
import useDashboardEffect from '../../shared/hooks/useDashboardEffect';

import { fetchFilingGroups } from './actions';

export const useFetchFilingGroups = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const dispatch = useDispatch();

  const action = useCallback(() => {
    const isContextReady = taxYear && period;

    if (isContextReady) {
      dispatch(
        fetchFilingGroups({
          taxYear,
          period,
        }),
      );
    }
  }, [taxYear, period, dispatch]);

  useDashboardEffect(action);
};
