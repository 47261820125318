import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@pwc/appkit-react';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { taxYearSelector, periodSelector } from '../../shared/store/selectors';
import useGridWithEditForm from '../../shared/hooks/useGridWithEditForm.hook';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import { infoAccountsSelector, isFetchingInfoAccountsSelector } from './store/selectors';
import { fetchInfoAccounts } from './store/actions';
import columnDefinitions from './infoAccounts.columnDefinitions';
import EditInfoAccount from './editInfoAccount.component';

const getRowId = params => params?.data?.infoAccountId;

const InfoAccounts = () => {
  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const infoAccounts = useSelector(infoAccountsSelector);
  const isFetchingInfoAccounts = useSelector(isFetchingInfoAccountsSelector);

  const { queryParams, setParams } = useUrlParams();
  const [infoAccountToEdit, setInfoAccountToEdit] = useState(null);
  const { agGridProps, clearSelection } = useGridWithEditForm(
    infoAccounts,
    setInfoAccountToEdit,
    getRowId,
  );

  useEffect(() => {
    if (queryParams.search || queryParams.search === '') {
      setParams({ queryParams: { search: null } });
    }
  }, [setParams, queryParams.search]);

  useEffect(() => {
    if (taxYear && period) {
      dispatch(fetchInfoAccounts({ taxYear, period }));
    }
  }, [dispatch, taxYear, period]);

  return (
    <div className="row grid-row">
      <div className="col-8">
        <AgGrid
          rowData={infoAccounts}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingInfoAccounts}
          withSearchBar
          initialQuickFilterValue={queryParams.search || ''}
          {...agGridProps}
        />
      </div>
      <div className="col-4 right-column-add-button">
        <div className="add-button-column">
          <Button
            size="lg"
            className="add-button"
            onClick={clearSelection}
            disabled={isFetchingInfoAccounts}
          >
            Add Screen
          </Button>
        </div>
        <div className="right-column-edit-form edit-form-add-button">
          <EditInfoAccount
            taxYear={taxYear}
            period={period}
            infoAccounts={infoAccounts}
            infoAccountToEdit={infoAccountToEdit}
            clearForm={clearSelection}
            isFetchingInfoAccounts={isFetchingInfoAccounts}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoAccounts;
