import reducerFactory from '../../../shared/store/reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';

import { fetchGenericCategoryTypes, updateGenericCategoryTypes } from './types';

export const initialState = {
  genericCategory: [],
  isFetchingGenericCategory: false,
  isUpdatingGenericCategory: false,
};

const genericCategoryReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchGenericCategoryTypes,
      payloadKey: 'genericCategory',
      fetchingKey: 'isFetchingGenericCategory',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateGenericCategoryTypes,
      fetchingKey: 'isUpdatingGenericCategory',
    }),
  },
});

export default genericCategoryReducer;
