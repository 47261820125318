import { defaultColumnDefinition } from '../../../columnDefinitions';
import { NumberCellEditor } from '../../../columnDefinitions/cellRenderers';

const getColumnDefinitions = ({ updateRow }) => {
  const onCellValueChanged = ({ data }) => updateRow(data);

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      headerName: 'Instance Name',
      field: 'instanceName',
      flex: 1,
    },
    {
      ...defaultColumnDefinition,
      headerName: 'Display Order',
      field: 'sortOrder',
      type: 'numericColumn',
      width: 100,
      editable: true,
      cellEditor: NumberCellEditor,
      cellEditorParams: {
        formatValue: value => Number(value),
      },
      onCellValueChanged,
    },
  ];

  return columnDefinitions;
};
export default getColumnDefinitions;
