import { FilingMethods, TaxReturnEFileStatus, TaxReturnStatus } from '../../enums';
import {
  FindFilingOrNONFilingMemberData,
  FindReturnDuedateOptionsResponse,
} from '../../../../../common/types/apiShapes';

import { FormValues } from './useFormInitialValues';
import { getDefaultDueDateType } from './getDefaultDueDateType';

type GetResetValuesForFilingMethodChangeParams = {
  filingMethod: FilingMethods;
  values: FormValues | FindFilingOrNONFilingMemberData;
  dueDateOption?: FindReturnDuedateOptionsResponse;
  shouldResetDueDate?: boolean;
};

const emptyValues = {};

export const getResetValuesForFilingMethodChange = ({
  filingMethod,
  values,
  dueDateOption,
  shouldResetDueDate = false,
}: GetResetValuesForFilingMethodChangeParams) => {
  if (filingMethod === FilingMethods.EFILE && values?.efileStatus) {
    return {
      returnStatus:
        values.efileStatus === TaxReturnEFileStatus.ACCEPTED
          ? TaxReturnStatus.COMPLETE
          : TaxReturnStatus.IN_PROGRESS,
      ...(shouldResetDueDate && dueDateOption
        ? { dueDateType: getDefaultDueDateType(dueDateOption) }
        : {}),
    };
  }

  if (filingMethod) {
    // add return if filing method is non-filing for member returns page.
    // if other place have issue, please check or pass an params to not return dueDateType/returnStatus as null
    return filingMethod !== FilingMethods.NON_FILING
      ? {
          returnStatus: TaxReturnStatus.NOT_STARTED,
          ...(shouldResetDueDate && dueDateOption
            ? { dueDateType: getDefaultDueDateType(dueDateOption) }
            : {}),
        }
      : {
          dueDateType: null,
          returnStatus: null,
        };
  }

  return emptyValues;
};
