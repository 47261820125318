import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Button } from '@pwc/appkit-react/lib/Button';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Loading from '../../shared/displayComponents/loading.component';

const EfileValidationForm = ({ isSubmitting, submitForm, values }) => (
  <form>
    <Field
      className="form-text"
      label="Operation Id"
      name="operationId"
      disabled
      component={Input}
    />
    <Button size="lg" disabled={isSubmitting || !values.operationId} onClick={submitForm}>
      <Loading isLoading={isSubmitting} small>
        Validate
      </Loading>
    </Button>
  </form>
);

EfileValidationForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    operationId: PropTypes.string.isRequired,
  }),
};

export default EfileValidationForm;
