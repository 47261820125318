import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ListItem, List, ListItemText, ListItemAction, Collapse } from '@pwc/appkit-react';
import { Button } from '@pwc/appkit-react/lib/Button';

import AppkitIcon from '../../../shared/displayComponents/appkitIcon/appkitIcon.component';
import Loading from '../../../shared/displayComponents/loading.component';

import styles from './listHierarchy.module.scss';

const ListHierarchy = ({
  isLoading,
  items,
  itemOnClick,
  label = '',
  selectedItemIds = [],
  className = '',
  smallSpinner = false,
}) => {
  const [collapsedItems, setCollapsedItems] = useState({});

  const collapseOnClick = useCallback(id => {
    setCollapsedItems(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }, []);

  const renderListItem = useCallback(
    ({ data, children }) => {
      const itemOnClickCb = (data, children) => () => {
        itemOnClick(data, children);
      };

      const collapseOnClickCb = id => evt => {
        evt.stopPropagation();
        collapseOnClick(id);
      };

      const renderCollapse = item => (
        <Collapse className={styles.listCollapse} isOpen={!item.collapsed}>
          {item.data.map(({ data, children }) => renderListItem({ data, children }))}
        </Collapse>
      );

      return (
        <React.Fragment key={data.id}>
          <ListItem
            className={selectedItemIds.includes(data.id) ? null : styles.listItem}
            onClick={itemOnClickCb(data, children)}
          >
            <AppkitIcon
              className={styles.listIcon}
              icon={collapsedItems[data.id] || !children.length ? 'folder-closed' : 'folder-opened'}
              size={14}
              onClick={null}
            />
            <ListItemText primary={data.name} />
            {children.length ? (
              <ListItemAction className="a-mr-10" onClick={collapseOnClickCb(data.id)}>
                <Button kind="secondary" className={styles.collapseBtn}>
                  <AppkitIcon
                    className=""
                    icon={collapsedItems[data.id] ? 'down-chevron' : 'up-chevron'}
                    size={14}
                  />
                </Button>
              </ListItemAction>
            ) : null}
          </ListItem>
          {children.length
            ? renderCollapse({ data: children, collapsed: collapsedItems[data.id] })
            : null}
        </React.Fragment>
      );
    },
    [collapsedItems, collapseOnClick, itemOnClick, selectedItemIds],
  );

  return (
    <>
      <Loading isLoading={isLoading} small={smallSpinner}>
        {label ? <label className={styles.listLabel}>{label}</label> : null}
        <List className={`${styles.selectableList} ${className}`}>{items.map(renderListItem)}</List>
      </Loading>
    </>
  );
};

ListHierarchy.propTypes = {
  isLoading: PropTypes.bool,

  items: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
      }),
      children: PropTypes.array,
    }),
  ).isRequired,
  selectedItemIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  itemOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  smallSpinner: PropTypes.bool,
};

export default ListHierarchy;
