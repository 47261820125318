const DEFAULT_EXCEL_FILE_FORMAT = 'xlsx';

const ExcelFileFormats = {
  xlsx: {
    extension: 'xlsx',
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  xlsm: {
    extension: 'xlsm',
    contentType: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  },
};

module.exports = {
  DEFAULT_EXCEL_FILE_FORMAT,
  ExcelFileFormats,
};
