import React from 'react';
import { connect } from 'react-redux';

import AuthorizedCustomRoute from '../../shared/authorization/authorizedCustomRoute';
import Page from '../../shared/displayComponents/page.component';
import { isFetchingContextSelector } from '../store/selectors';

import DevelopmentBreadcrumbRoutes from './developmentBreadcrumbRoutes.component';

const DevelopmentPage = connect(state => ({
  isFetchingContext: isFetchingContextSelector(state),
  breadcrumbRoutes: DevelopmentBreadcrumbRoutes,
}))(Page);

const DevelopmentRoute = props => <AuthorizedCustomRoute wrapper={DevelopmentPage} {...props} />;

export default DevelopmentRoute;
