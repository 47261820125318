import hasRequiredPermissionsToView from '../../shared/authorization/hasRequiredPermissionsToView';
import hasRequiredPermissionsToEdit from '../../shared/authorization/hasRequiredPermissionsToEdit';

export default ({ tree, permissions }) =>
  tree.map(item => ({
    ...item,
    ...(item.type === 'folder'
      ? {}
      : permissions.reduce((acc, { rolePermissions, id }) => {
          const { read = [], edit = [], blacklist = [] } = rolePermissions
            ? JSON.parse(rolePermissions)
            : {};
          const customerPermissions = { read, edit, blacklist };
          return {
            [`${id}Read`]: hasRequiredPermissionsToView(customerPermissions, item.MAIN),
            [`${id}Edit`]: hasRequiredPermissionsToEdit(customerPermissions, item.MAIN),
            ...acc,
          };
        }, {})),
  }));
