import getApiActionTypesHandlers from '../../../shared/store/getApiActionTypesHandlers';
import reducerFactory from '../../../shared/store/reducerFactory';

import {
  fetchUsersTypes,
  updateUserTypes,
  createUserTypes,
  deleteUserTypes,
  validateUserEmailTypes,
  RESET_VALIDATION_RESULT,
  fetchBusinessesTypes,
  updateUserBusinessesTypes,
  RESET_BUSINESSES,
  fetchRolesTypes,
} from './types';

export const initialState = {
  users: [],
  isFetchingUsers: false,
  isUpdatingUser: false,
  isCreatingUser: false,
  isDeletingUser: false,

  validationResult: {},
  isValidatingUser: false,

  businesses: [],
  isFetchingBusinesses: false,
  isUpdatingUserBusinesses: false,

  roles: [],
  isFetchingRoles: false,
};

const usersMaintenanceReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchUsersTypes,
      payloadKey: 'users',
      fetchingKey: 'isFetchingUsers',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateUserTypes,
      fetchingKey: 'isUpdatingUser',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: createUserTypes,
      fetchingKey: 'isCreatingUser',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: deleteUserTypes,
      fetchingKey: 'isDeletingUser',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: validateUserEmailTypes,
      fetchingKey: 'isValidatingUser',
      payloadKey: 'validationResult',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchBusinessesTypes,
      fetchingKey: 'isFetchingBusinesses',
      payloadKey: 'businesses',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateUserBusinessesTypes,
      fetchingKey: 'isUpdatingUserBusinesses',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchRolesTypes,
      payloadKey: 'roles',
      fetchingKey: 'isFetchingRoles',
    }),
    [validateUserEmailTypes.START]: state => ({
      ...state,
      isValidatingUser: true,
      validationResult: {},
    }),
    [RESET_VALIDATION_RESULT]: state => ({
      ...state,
      validationResult: {},
    }),
    [RESET_BUSINESSES]: state => ({
      ...state,
      businesses: [],
    }),
  },
});

export default usersMaintenanceReducer;
