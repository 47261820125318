import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import useReportData from '../../shared/reports/useReportData.hook';
import getReportUrl from '../shared/getReportUrl';
import {
  taxYearSelector,
  periodSelector,
  jurisdictionIdSelector,
  entityIdSelector,
  jurisdictionDescriptionSelector,
  periodNameSelector,
  isGlobalContextReadySelector,
} from '../../shared/store/selectors';

import columnDefinitions from './manualAdjustmentReport.columnDefinitions';

const endpointBasePath = getReportUrl('manual-adjustment-report');

const ManualAdjustmentReport = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const periodName = useSelector(periodNameSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);
  const entityId = useSelector(entityIdSelector);
  const isGlobalContextReady = useSelector(isGlobalContextReadySelector);

  const { reportData, isFetchingReportData, showGrid, fetchData } = useReportData({
    endpointBasePath,
    isFetchingAnyParam: !isGlobalContextReady,
    method: 'POST',
    params: useMemo(
      () => ({
        taxYear,
        period,
        jurisdictionId,
        entityId,
      }),
      [taxYear, period, jurisdictionId, entityId],
    ),
    fetchOnParamsChange: true,
  });

  useEffect(() => {
    if (isGlobalContextReady) {
      fetchData();
    }
  }, [fetchData, isGlobalContextReady]);

  const headerRowInfo = useMemo(
    () => [
      {
        label: 'Tax Year',
        value: taxYear,
      },
      {
        label: 'Period',
        value: periodName,
      },
      {
        label: 'Entity',
        value: entityId,
      },
      {
        label: 'Jurisdiction',
        value: jurisdictionDescription,
      },
    ],
    [taxYear, periodName, entityId, jurisdictionDescription],
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="global-context">
            <div className="global-context-values">
              {headerRowInfo.map(({ label, value }) => (
                <GlobalContextValue key={label} label={label} value={value} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row grid-row">
        <div className="col">
          {showGrid && (
            <AgGrid
              rowData={reportData}
              columnDefs={columnDefinitions}
              isGridLoading={isFetchingReportData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ManualAdjustmentReport;
