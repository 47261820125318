import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import { globalContextSelector } from '../shared/store/selectors';
import globalContextPropTypes from '../shared/propTypes/globalContext';

import columnDefinitions from './viewPrintAttachmentDetails.columnDefinitions';
import useFetchAttachmentDetails from './useFetchViewPrintAttachmentDetails.hook';

const ViewPrintAttachmentDetails = ({ globalContext, isFetchingContext }) => {
  const { attachmentDetails, isFetchingAttachmentDetails } = useFetchAttachmentDetails(
    globalContext,
  );

  return (
    <div className="row grid-row pdf">
      <div className="col">
        {globalContext.isReady ? (
          <AgGrid
            isGridLoading={isFetchingAttachmentDetails || isFetchingContext}
            columnDefs={columnDefinitions}
            rowData={attachmentDetails}
          />
        ) : null}
      </div>
    </div>
  );
};

ViewPrintAttachmentDetails.propTypes = {
  globalContext: globalContextPropTypes,
  isFetchingContext: PropTypes.bool,
};

export default connect(state => ({
  globalContext: globalContextSelector(state),
}))(ViewPrintAttachmentDetails);
