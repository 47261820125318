import React, { useMemo } from 'react';

import AgGrid from '../../../../../shared/displayComponents/agGrid/agGrid.component';
import { FederalFilingGroup } from '../../../../../../../common/types';
import { TabProps } from '../../types';

import getColumnDefinitions from './federalFilingGroups.columnDefinitions';

const FederalFilingGroups = ({
  filingGroups,
  isFetchingFilingGroups,
  onSelectionChanged,
}: TabProps) => {
  const columnDefinitions = useMemo(() => getColumnDefinitions(), []);
  const getRowId = useMemo(
    () => ({ data }: { data: FederalFilingGroup }) => data?.filingGroupId,
    [],
  );

  return (
    <>
      <AgGrid
        rowData={filingGroups}
        columnDefs={columnDefinitions}
        withSearchBar
        autoMaxWidth
        isGridLoading={isFetchingFilingGroups}
        rowSelection={'multiple'}
        onSelectionChanged={onSelectionChanged}
        getRowId={getRowId}
      />
    </>
  );
};

export default FederalFilingGroups;
