import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@tls/ui-modal';

import { CheckboxGroupsData, DataItemsUsingCheckboxGroup } from '../../../../../../common/types';
import { useFetchQueryDataItemsUsingCheckboxGroup } from '../../../../shared/queries/checkboxGroups';
import Loading from '../../../../shared/displayComponents/loading.component';
import { errorNotification } from '../../../../shared/notification/store/actions';
import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';
import { ModalState, modalButtonsText, WARNING_BEFORE_DELETE } from '../constants';

import styles from './deleteCheckboxGroupModal.module.scss';
import columnDefinitions from './deleteCheckboxGroupModal.columnDefinitions';

interface DeleteCheckboxGroupModalProps {
  hideModal: () => void;
  visible: boolean;
  modalData: CheckboxGroupsData | null;
  onDelete: (modalData: CheckboxGroupsData | null) => void;
}

const DeleteCheckboxGroupModal = ({
  hideModal,
  visible,
  modalData,
  onDelete,
}: DeleteCheckboxGroupModalProps) => {
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState(ModalState.INITIAL);
  const [dataItemsUsingCheckboxGroup, setDataItemsUsingCheckboxGroup] = useState<
    DataItemsUsingCheckboxGroup[]
  >([]);

  const findDataItemsUsingCheckboxGroup = useFetchQueryDataItemsUsingCheckboxGroup();

  const handleSubmit = useCallback(() => {
    if (modalState === ModalState.CHECKBOX_GROUP_UNUSED) {
      onDelete(modalData);
    }
    hideModal();
  }, [onDelete, hideModal, modalState, modalData]);

  useEffect(() => {
    (async () => {
      setModalState(ModalState.INITIAL);
      if (!modalData?.id) {
        return;
      }
      try {
        const dataItemsUsingCheckboxGroups = await findDataItemsUsingCheckboxGroup({
          id: modalData.id,
        });
        setDataItemsUsingCheckboxGroup(dataItemsUsingCheckboxGroups);
        setModalState(
          dataItemsUsingCheckboxGroups.length
            ? ModalState.CHECKBOX_GROUP_USED
            : ModalState.CHECKBOX_GROUP_UNUSED,
        );
      } catch (err) {
        dispatch(errorNotification('Error fetching in-use checkbox groups'));
      }
    })();
  }, [dispatch, findDataItemsUsingCheckboxGroup, modalData?.id]);

  const modalTitle = {
    [ModalState.INITIAL]: null,
    [ModalState.CHECKBOX_GROUP_USED]: `Can't delete checkbox group "${modalData?.name}"`,
    [ModalState.CHECKBOX_GROUP_UNUSED]: 'Delete checkbox group?',
  };

  return (
    <Modal
      title={modalTitle[modalState]}
      submitText={modalButtonsText[modalState].submit}
      dismissText={modalButtonsText[modalState].dismiss}
      visible={visible}
      dismissAction={hideModal}
      closeAction={hideModal}
      submitAction={handleSubmit}
      actionButtonsSize="lg"
    >
      <Loading isLoading={modalState === ModalState.INITIAL}>
        {modalState === ModalState.CHECKBOX_GROUP_USED && (
          <>
            <p>
              {`It's being used in the following dataitem${
                dataItemsUsingCheckboxGroup.length > 1 ? '(s)' : ''
              }:`}
            </p>
            <div className={`row grid-row ${styles.agGridContainer}`}>
              <div className="col">
                <AgGrid
                  columnDefs={columnDefinitions}
                  rowData={dataItemsUsingCheckboxGroup}
                  autoMaxWidth
                  areHeaderCellBordersEnabled
                  domLayout="autoHeight"
                />
              </div>
            </div>
          </>
        )}
        {modalState === ModalState.CHECKBOX_GROUP_UNUSED && <p>{WARNING_BEFORE_DELETE}</p>}
      </Loading>
    </Modal>
  );
};

export default DeleteCheckboxGroupModal;
