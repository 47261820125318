import React from 'react';
import PropTypes from 'prop-types';

import AppkitIcon from '../../../shared/displayComponents/appkitIcon/appkitIcon.component';

import ModalButtonsGroup from './modalButtonsGroup.component';
import styles from './propagateModelModal.module.scss';

const WarningMessage = ({
  taxYear,
  dataModelName,
  sourceJurisdiction,
  destinationJurisdictionsCount,
  destinationJurisdictionsStateCodes,
  handleContinueSubmit,
  handleWarningCancel,
}) => (
  <div className={styles.warningMessage}>
    <div className={styles.alertIcon}>
      <p className={styles.warningHeader}>This process can not be reverted.</p>
      <AppkitIcon className={styles.alertIcon} icon="alert" size={16} />
      <p className={styles.warningHeader}>Do you wish to propagate Data Model?</p>
    </div>
    <div className={styles.warningDetails}>
      <p>The data model:</p>
      <p>
        {taxYear} {dataModelName} in {sourceJurisdiction}
      </p>
      <p>will be propagated to the following {destinationJurisdictionsCount} jurisdictions:</p>
      <p>{destinationJurisdictionsStateCodes}</p>
      <p>
        This will override any existing data model with the name of {dataModelName} in the selected
        jurisdictions. All datasets, dataitems and expressions will be updated or deleted to match
        the contents in the selected data model.
      </p>
      <p>Do you wish to continue?</p>
    </div>
    <ModalButtonsGroup
      className={styles.buttons}
      handleSubmit={handleContinueSubmit}
      handleCancel={handleWarningCancel}
      submitButtonLabel="Continue"
    />
  </div>
);

WarningMessage.propTypes = {
  taxYear: PropTypes.string.isRequired,
  dataModelName: PropTypes.string.isRequired,
  sourceJurisdiction: PropTypes.string.isRequired,
  destinationJurisdictionsCount: PropTypes.number.isRequired,
  destinationJurisdictionsStateCodes: PropTypes.string,
  handleContinueSubmit: PropTypes.func.isRequired,
  handleWarningCancel: PropTypes.func.isRequired,
};

export default WarningMessage;
