import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchQueryCsrfToken } from '../queries/csrf';
import { CLIENT_STATUS_KEY, getClientLocalStorageItem } from '../localStorage';

import {
  fetchCustomerData,
  fetchPermissions,
  fetchTaxYears,
  fetchPeriods,
  fetchConsolidations,
  fetchEntities,
  fetchTrgReports,
  fetchJurisdictionsOptions,
  fetchIsPartnership,
  selectTaxYear,
} from './actions';
import {
  taxYearSelector,
  taxYearOptionsSelector,
  periodSelector,
  globalContextSelector,
  defaultTaxYearSelector,
} from './selectors';
import { setCsrfToken, csrfTokenSelector } from './auth';
import { clientIdSelector, setClientId } from './context';

export const useFetchTaxYears = () => {
  const dispatch = useDispatch();

  const clientId = useSelector(clientIdSelector);
  const taxYears = useSelector(taxYearOptionsSelector);
  const taxYear = useSelector(taxYearSelector);
  const defaultTaxYear = useSelector(defaultTaxYearSelector);

  useEffect(() => {
    if (taxYears.length === 0) {
      dispatch(fetchTaxYears());
    }
  }, [dispatch, taxYears]);

  useEffect(() => {
    const context = getClientLocalStorageItem({
      clientId,
      clientDetailKey: CLIENT_STATUS_KEY.CONTEXT,
    });
    if (!taxYear && !context?.taxYear) {
      dispatch(selectTaxYear(defaultTaxYear));
    }
  }, [dispatch, defaultTaxYear, taxYear, clientId]);
};

export const useFetchPeriods = () => {
  const taxYear = useSelector(taxYearSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taxYear) {
      dispatch(fetchPeriods(taxYear));
    }
  }, [dispatch, taxYear]);
};

export const useFetchConsolidations = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taxYear && period) {
      dispatch(fetchConsolidations(taxYear, period));
    }
  }, [dispatch, taxYear, period]);
};

export const useFetchEntities = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (taxYear && period) {
      dispatch(fetchEntities(taxYear, period));
    }
  }, [dispatch, period, taxYear]);
};

export const useFetchTrgReports = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTrgReports());
  }, [dispatch]);
};

export const useFetchJurisdictions = () => {
  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (taxYear && period) {
      dispatch(fetchJurisdictionsOptions({ taxYear, period }));
    }
  }, [dispatch, taxYear, period]);
};

export const useFetchCustomerData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomerData());
  }, [dispatch]);
};

export const useFetchPermissions = enabled => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (enabled) {
      dispatch(fetchPermissions());
    }
  }, [dispatch, enabled]);
};

export const useFetchIsPartnership = () => {
  const globalContext = useSelector(globalContextSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (globalContext.isSeparate && globalContext.isReady) {
      const { taxYear, period, businessEntityId: entityId, jurisdictionId } = globalContext.params;

      dispatch(fetchIsPartnership({ taxYear, period, entityId, jurisdictionId }));
    }
  }, [dispatch, globalContext]);
};

export const useCsrfToken = () => {
  const dispatch = useDispatch();
  const csrfToken = useSelector(csrfTokenSelector);

  const fetchCsrfToken = useFetchQueryCsrfToken();

  useEffect(() => {
    const fetchCsrfTokenAsync = async () => {
      const data = await fetchCsrfToken();
      if (data?.csrfToken) {
        dispatch(setCsrfToken(data.csrfToken));
      }
    };

    fetchCsrfTokenAsync();
  }, [fetchCsrfToken, dispatch]);

  return csrfToken;
};

export const useClientId = clientId => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setClientId(clientId));
  }, [dispatch, clientId]);
};
