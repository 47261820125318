import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  EFileServiceSubmissionId,
  FederalSubmissionId,
  SltBinderId,
  TtiBinderId,
  TtiCaseId,
  TtiYearId,
} from '../../../../../../../common/types';
import { globalContextSelector } from '../../../../../shared/store/selectors';

interface UseGenerateFileUrlParams {
  eFileServiceSubmissionId: EFileServiceSubmissionId | null;
  federalSubmissionId: FederalSubmissionId | null;
  ttiYearId: TtiYearId | null;
  ttiCaseId: TtiCaseId | null;
  ttiBinderId: TtiBinderId | null;
  sltBinderId: SltBinderId | null;
  isReturnRequired: boolean | null;
  isFederalFileAvailable: boolean;
  stateEFileXmlFileLocator: string | null;
  stateEFileZipFileLocator: string | null;
}

const useGenerateFileUrl = ({
  eFileServiceSubmissionId,
  federalSubmissionId,
  ttiCaseId,
  ttiYearId,
  ttiBinderId,
  sltBinderId,
  isReturnRequired,
  isFederalFileAvailable,
  stateEFileXmlFileLocator,
  stateEFileZipFileLocator,
}: UseGenerateFileUrlParams) => {
  const returnId = sltBinderId;

  const globalContext = useSelector(globalContextSelector);

  const { taxYear } = globalContext;

  // in the future we want to open this up to TTI as well
  const submissionZipFileBlobStorageUrl = useMemo(() => {
    // under the assumption new arch flag is turned on
    return `/api/efile-v2/files/state/submission-zip/${returnId}`;
  }, [returnId]);

  const submissionZipFileUrl = useMemo(() => {
    if (eFileServiceSubmissionId && ttiCaseId && ttiYearId && ttiBinderId) {
      return `/api/efile-v2/files/state/submission-zip/${eFileServiceSubmissionId}/${ttiCaseId}/${ttiYearId}/${ttiBinderId}`;
    }
  }, [eFileServiceSubmissionId, ttiBinderId, ttiCaseId, ttiYearId]);

  const submissionXmlFileUrl = useMemo(() => {
    if (stateEFileXmlFileLocator) {
      return `/api/efile-v2/files/state/submission-xml/${returnId}`;
    } else if (eFileServiceSubmissionId && ttiCaseId && ttiYearId && ttiBinderId) {
      return `/api/efile-v2/files/state/submission-xml/${eFileServiceSubmissionId}/${ttiCaseId}/${ttiYearId}/${ttiBinderId}`;
    }
  }, [eFileServiceSubmissionId, ttiBinderId, ttiCaseId, ttiYearId]);

  const schemaValidationsFileUrl = useMemo(() => {
    if (eFileServiceSubmissionId && ttiCaseId && ttiYearId && ttiBinderId) {
      return `/api/efile-v2/files/state/schema-validation/${eFileServiceSubmissionId}/${ttiCaseId}/${ttiYearId}/${ttiBinderId}`;
    }
  }, [eFileServiceSubmissionId, ttiBinderId, ttiCaseId, ttiYearId]);

  const transmissionValidationsFileUrl = useMemo(() => {
    if (eFileServiceSubmissionId && ttiCaseId && ttiYearId && ttiBinderId) {
      return `/api/efile-v2/files/federal/transmission-validations/${eFileServiceSubmissionId}/${ttiCaseId}/${ttiYearId}/${ttiBinderId}`;
    }
  }, [eFileServiceSubmissionId, ttiBinderId, ttiCaseId, ttiYearId]);

  const federalXmlFileUrl = useMemo(() => {
    if (taxYear && federalSubmissionId && sltBinderId) {
      return `/api/efile-v2/files/federal/submission-xml/${taxYear}/${federalSubmissionId}/${sltBinderId}`;
    }
  }, [federalSubmissionId, sltBinderId, taxYear]);

  const allFilesUrl = useMemo(() => {
    const isFederalXmlAvailable = Boolean(taxYear && federalSubmissionId && sltBinderId);
    const isTransmissionValidationAvailable = Boolean(
      eFileServiceSubmissionId && ttiCaseId && ttiYearId && ttiBinderId,
    );

    const areOnlyStateFilesAvailable = Boolean(eFileServiceSubmissionId && !federalSubmissionId);
    const areFilesRequiredParamsAvailable = areOnlyStateFilesAvailable
      ? eFileServiceSubmissionId && ttiCaseId && ttiYearId && ttiBinderId
      : eFileServiceSubmissionId &&
        ttiCaseId &&
        ttiYearId &&
        ttiBinderId &&
        taxYear &&
        federalSubmissionId &&
        sltBinderId;

    if (areFilesRequiredParamsAvailable) {
      const params = `${taxYear}/${federalSubmissionId}/${sltBinderId}/${eFileServiceSubmissionId}/${ttiCaseId}/${ttiYearId}/${ttiBinderId}`;
      const flags = areOnlyStateFilesAvailable
        ? `${isReturnRequired}/false/false/false`
        : `${isReturnRequired}/${isFederalFileAvailable}/${isFederalXmlAvailable}/${isTransmissionValidationAvailable}`;

      return `/api/efile-v2/files/all-files/${params}/${flags}`;
    }
  }, [
    taxYear,
    federalSubmissionId,
    sltBinderId,
    eFileServiceSubmissionId,
    ttiCaseId,
    ttiYearId,
    ttiBinderId,
    isReturnRequired,
    isFederalFileAvailable,
  ]);

  return {
    submissionZipFileUrl: stateEFileZipFileLocator
      ? submissionZipFileBlobStorageUrl || ''
      : submissionZipFileUrl || '',
    submissionXmlFileUrl: submissionXmlFileUrl || '',
    schemaValidationsFileUrl: schemaValidationsFileUrl || '',
    transmissionValidationsFileUrl: transmissionValidationsFileUrl || '',
    federalXmlFileUrl: federalXmlFileUrl || '',
    allFilesUrl: allFilesUrl || '',
  };
};

export default useGenerateFileUrl;
