import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { entityIdSelector, periodSelector, taxYearSelector } from '../../shared/store/selectors';

import getColumnDefinitions from './partnershipFlowThrough.columnDefinitions';
import {
  partnershipFlowThroughAmountsRowsSelector,
  partnershipFlowThroughAmountsColumnsSelector,
  isFetchingPartnershipFlowThroughAmountsSelector,
} from './store/selectors';
import {
  fetchPartnershipFlowThroughAmounts,
  setAccountId,
  setJurisdictionId,
} from './store/actions';

const PartnershipInformation = ({
  taxYear,
  period,
  entityId,
  partnershipFlowThroughAmountsRows,
  partnershipFlowThroughAmountsColumns,
  isFetchingPartnershipFlowThroughAmounts,
  fetchPartnershipFlowThroughAmounts,
  setJurisdictionId,
  setAccountId,
}) => {
  useEffect(() => {
    const isContextReady = taxYear && period && entityId;

    if (isContextReady) {
      fetchPartnershipFlowThroughAmounts({ taxYear, period, entityId });
    }
  }, [fetchPartnershipFlowThroughAmounts, taxYear, period, entityId]);

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        dynamicColumns: partnershipFlowThroughAmountsColumns,
        setJurisdictionId,
        setAccountId,
      }),
    [partnershipFlowThroughAmountsColumns, setJurisdictionId, setAccountId],
  );

  return (
    <div className="row grid-row">
      <div className="col">
        <AgGrid
          rowData={partnershipFlowThroughAmountsRows}
          columnDefs={columnDefinitions}
          isGridLoading={isFetchingPartnershipFlowThroughAmounts}
        />
      </div>
    </div>
  );
};

PartnershipInformation.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  partnershipFlowThroughAmountsRows: PropTypes.arrayOf(
    PropTypes.shape({
      jurisdictionDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  partnershipFlowThroughAmountsColumns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isFetchingPartnershipFlowThroughAmounts: PropTypes.bool.isRequired,
  fetchPartnershipFlowThroughAmounts: PropTypes.func.isRequired,
  setAccountId: PropTypes.func.isRequired,
  setJurisdictionId: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    partnershipFlowThroughAmountsRows: partnershipFlowThroughAmountsRowsSelector(state),
    partnershipFlowThroughAmountsColumns: partnershipFlowThroughAmountsColumnsSelector(state),
    isFetchingPartnershipFlowThroughAmounts: isFetchingPartnershipFlowThroughAmountsSelector(state),
  }),
  {
    fetchPartnershipFlowThroughAmounts,
    setAccountId,
    setJurisdictionId,
  },
)(PartnershipInformation);
