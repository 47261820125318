import { connect } from 'react-redux';

import { GlobalContextValue } from '../../shared/containerComponents/globalContext';
import {
  consolidationJurisdictionDescriptionSelector,
  isFetchingConsolidationsSelector,
} from '../../shared/store/selectors';

export default connect(
  state => ({
    label: 'Jurisdiction',
    value: consolidationJurisdictionDescriptionSelector(state),
    isBusy: isFetchingConsolidationsSelector(state),
  }),
  null,
)(GlobalContextValue);
