import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TtiYearIdResponse } from '@tls/slt-types';
import { useParams } from 'react-router-dom';

import { errorNotification } from '../../../shared/notification/store/actions';
import { useMutationEFileRunValidation } from '../../../shared/mutations/efileV2';
import {
  useQueryGetTtiGroupValidation,
  useQueryGetCheckTtiServiceRunning,
} from '../../../shared/queries/eFileV2';
import {
  globalContextSelector,
  jurisdictionDescriptionSelector,
  jurisdictionStateCodeSelector,
  consolidationProFormaFilingGroupIdSelector,
} from '../../../shared/store/selectors';
import { BinderId } from '../../../../../common/types';
import { WIZARD_MACHINE_EVENTS } from '../constants';
import { StepStatus, WizardStepName } from '../enums';

interface UseValidationParams {
  ttiCaseId?: string | null;
  ttiYearId?: TtiYearIdResponse;
  sltBinderId?: BinderId | null;
  isFetchingTaxReturnOrTransmitterProviderTR: boolean;
  sendMachineEvent: ({ type, value }: { type: string; value: unknown }) => void;
}

const useValidation = ({
  ttiCaseId,
  ttiYearId,
  sltBinderId,
  isFetchingTaxReturnOrTransmitterProviderTR,
  sendMachineEvent,
}: UseValidationParams) => {
  const dispatch = useDispatch();

  const {
    mutateAsync: runValidation,
    isLoading: isRunningValidationProcess,
  } = useMutationEFileRunValidation();

  const globalContext = useSelector(globalContextSelector);
  const jurisdictionDescription = useSelector(jurisdictionDescriptionSelector);
  const jurisdictionStateCode = useSelector(jurisdictionStateCodeSelector);

  const { returnId = null } = useParams<{ returnId?: string }>();

  const consolidationProFormaFilingGroupId = useSelector(
    consolidationProFormaFilingGroupIdSelector,
  );

  const { isConsolidated, isSeparate, isSeparateYN } = globalContext;
  const { taxYear, period, filingTypeId, businessEntityId, jurisdictionId } = globalContext.params;

  const areValidationParamsReady =
    ttiCaseId &&
    ttiYearId &&
    sltBinderId &&
    jurisdictionStateCode &&
    jurisdictionDescription &&
    globalContext.isReady;

  const { data: isTtiServiceRunningData } = useQueryGetCheckTtiServiceRunning({
    enabled: !isFetchingTaxReturnOrTransmitterProviderTR,
    params: { taxYear },
  });

  const {
    data: ttiGroupValidationData = null,
    isFetching: isFetchingTtiGroupValidationData,
    isFetched: isTtiGroupValidationDataFetched,
  } = useQueryGetTtiGroupValidation({
    params: {
      globalContextApiRouteParamString: globalContext.apiRouteParamString,
      ttiCaseId,
      ttiYearId,
      sltBinderId,
    },
    enabled: Boolean(
      globalContext.isReady &&
        isConsolidated &&
        ttiCaseId &&
        ttiYearId &&
        sltBinderId &&
        isTtiServiceRunningData?.isTtiServiceRunning,
    ),
  });

  const isRunValidationOrParamsNotReady = !isFetchingTaxReturnOrTransmitterProviderTR
    ? isFetchingTtiGroupValidationData || isRunningValidationProcess || !areValidationParamsReady
    : false;

  const handleRunValidation = useCallback(() => {
    if (
      !isFetchingTaxReturnOrTransmitterProviderTR &&
      ttiGroupValidationData &&
      !ttiGroupValidationData?.isTtiGroupValid
    ) {
      return;
    }
    const isValidationReadyToRerun =
      sltBinderId &&
      jurisdictionStateCode &&
      jurisdictionDescription &&
      taxYear &&
      period &&
      filingTypeId &&
      businessEntityId &&
      jurisdictionId &&
      (isConsolidated || (isSeparate && isSeparateYN)) &&
      returnId;

    const isTtiReadyOrSikp = isFetchingTaxReturnOrTransmitterProviderTR
      ? true
      : ttiCaseId && ttiYearId;

    if (!isValidationReadyToRerun || !isTtiReadyOrSikp) {
      sendMachineEvent({
        type: WIZARD_MACHINE_EVENTS.SET_STEP_STATUS,
        value: {
          sltBinderId,
          stepName: WizardStepName.STEP_2_VALIDATE,
          status: StepStatus.FAILED,
        },
      });
      // TODO: SLT-6313 Can be hit when TTI service is down
      return dispatch(errorNotification('Error running validation, please try again later'));
    }
    runValidation({
      ttiCaseId: ttiCaseId ? ttiCaseId : '',
      yearOrSubPeriodId: ttiYearId ? ttiYearId : '',
      sltBinderId,
      jurisdictionStateCode,
      jurisdictionDescription,
      globalContext,
      taxYear,
      period,
      filingTypeId,
      businessEntityId,
      jurisdictionId,
      isConsolidated,
      isSeparate,
      isSeparateYN,
      consolidationProFormaFilingGroupId,
      returnId,
      isTransmitterProviderTR: isFetchingTaxReturnOrTransmitterProviderTR,
    });
  }, [
    dispatch,
    runValidation,
    ttiCaseId,
    ttiYearId,
    sltBinderId,
    jurisdictionStateCode,
    jurisdictionDescription,
    taxYear,
    period,
    filingTypeId,
    businessEntityId,
    jurisdictionId,
    isConsolidated,
    isSeparate,
    isSeparateYN,
    globalContext,
    consolidationProFormaFilingGroupId,
    returnId,
    sendMachineEvent,
    ttiGroupValidationData,
    isFetchingTaxReturnOrTransmitterProviderTR,
  ]);

  return {
    handleRunValidation,
    isRunningValidationProcess,
    isRunValidationOrParamsNotReady,
    isFetchingTtiGroupValidationData,
    ttiGroupValidationData,
    isTtiGroupValidationDataFetched,
  };
};

export default useValidation;
