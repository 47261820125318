import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import isEqual from 'lodash.isequal';

import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';
import SDKCustomSelect from '../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import Input from '../../shared/forms/inputFormik/inputFormik.component';
import Select from '../../shared/forms/sdkCustomSelect/formikSdkCustomSelect.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';

import styles from './genericScreenDefinition.module.scss';
import { screenTypesOptions, saveButtonOptions } from './constants';
import { genericScreenDefinitionTypes } from './propTypes';

const EditGenericScreenDefinitionForm = ({
  onCancelClick,
  isSubmitting,
  submitForm,
  accountsOptions,
  categoriesOptions,
  jurisdictionsOptions,
  isEditMode,
  values,
  initialValues,
  setFieldValue,
}) => {
  const handleAccountChange = useCallback(
    option => {
      setFieldValue('accountId', option.value);
    },
    [setFieldValue],
  );

  const isFormDataChanged = !isEqual(initialValues, values);

  return (
    <form className={styles.editTaxFormFieldForm}>
      <div className={styles.inputsSection}>
        <Select
          wrapperClassName="form-text"
          appkitLabel="Jurisdiction"
          name="jurisdictionId"
          options={jurisdictionsOptions}
          value={values.jurisdictionId}
          disabled={isEditMode}
          showSearchOnToggle
        />
        <Field
          className="form-text"
          label="Screen Id"
          name="screenId"
          component={Input}
          autoComplete="off"
          disabled={isEditMode}
        />
        <Field
          className="form-text"
          label="Screen Title"
          name="title"
          component={Input}
          autoComplete="off"
        />
        <div>
          <Select
            appkitLabel="Category"
            name="categoryId"
            autoComplete="off"
            options={categoriesOptions}
            value={values.categoryId}
            showSearchOnToggle
          />
        </div>
        <div>
          <label className="a-form-label">Account</label>
          <SDKCustomSelect
            className="sdk-custom-select"
            options={accountsOptions}
            value={values.accountId}
            virtualized
            onChange={handleAccountChange}
          />
        </div>
        <div>
          <Select
            appkitLabel="Screen Type"
            name="screenType"
            autoComplete="off"
            options={screenTypesOptions}
            value={values.screenType}
          />
        </div>
        <div>
          <Select
            appkitLabel="Save Button"
            name="saveButton"
            autoComplete="off"
            options={saveButtonOptions}
            value={values.saveButton}
          />
        </div>
      </div>
      <div>
        <ButtonsGroup
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={onCancelClick}
          submitButtonLabel="Save"
          disabled={!isFormDataChanged}
        />
      </div>
    </form>
  );
};

EditGenericScreenDefinitionForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  accountsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  categoriesOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  jurisdictionsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  isEditMode: PropTypes.bool.isRequired,
  values: PropTypes.shape(genericScreenDefinitionTypes),
  initialValues: PropTypes.shape(genericScreenDefinitionTypes),
  setFieldValue: PropTypes.func.isRequired,
};

export default EditGenericScreenDefinitionForm;
