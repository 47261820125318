import reducerFactory from '../reducerFactory';
import getApiActionTypesHandlers from '../getApiActionTypesHandlers';
import {
  SELECT_JURISDICTION,
  SELECT_ENTITY,
  SELECT_TAX_YEAR,
  SELECT_PERIOD,
} from '../../../shared/store/types';
import { SELECT_FILING_GROUP_ID } from '../../../stateFilingGroups/store/types';
import getFieldValue from '../../../utils/getFieldValue';
import {
  SELECT_TAX_YEAR as DEVELOPMENT_SELECT_TAX_YEAR,
  SELECT_PERIOD as DEVELOPMENT_SELECT_PERIOD,
  SELECT_JURISDICTION_ID,
} from '../../../development/store/types';
import { FIRST_PAGE } from '../../../development/dataModels/constants';

import {
  fetchDataModelsTypes,
  fetchDatasetsTypes,
  fetchAllDatasetsTypes,
  fetchDataItemsTypes,
  fetchDataTypesTypes,
  fetchDatasetInstancesTypes,
  SELECT_DATASET_INSTANCE,
  fetchInstanceDataTypes,
  fetchInstanceDataPrintServiceTypes,
  CLEAR_INSTANCE_DATA_PRINT_SERVICE,
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigsTypes,
  RESET_MAPPING_FORM_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIGS,
  fetchPDFFieldsByPageTypes,
  RESET_PDF_FIELDS_BY_PAGE,
  fetchOverflowActionTypesTypes,
  updateDataModelTypes,
  addDatasetTypes,
  SELECT_WORKPAPER_FORM,
  fetchMappingFormsTypes,
  SELECT_MAPPING_FORM_PDF,
  SELECT_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIG,
  SELECT_DATA_ITEM,
  SELECT_DATA_MODEL,
  SELECT_DATASET,
  EXPAND_COLLAPSE_HIERARCHY_LIST_ITEM,
  updateInstanceDataTypes,
  fetchDataDefinitionsTypes,
  addDatasetInstanceTypes,
  CLEAR_DATASET_INSTANCES,
  CLEAR_INSTANCE_DATA,
  assignPDFDataUsageTypes,
  unassignPDFDataUsageTypes,
  deleteDatasetDefTypes,
  fetchPDFAttachmentColumnPropertiesTypes,
  SET_STATE_FROM_EXPRESSION_CALCULATION,
} from './types';

export const initialState = {
  dataModels: [],
  isFetchingDataModels: false,
  datasets: [],
  datasetId: null,
  previousDatasetId: null,
  isFetchingDatasets: false,
  allDatasets: [],
  isFetchingAllDatasets: false,
  dataItems: [],
  isFetchingDataItems: false,
  dataItemId: null,
  previousDataItemId: null,
  dataTypes: [],
  overflowActionTypes: [],
  isFetchingOverflowActionTypes: false,
  isFetchingDatasetInstances: false,
  datasetInstances: [],
  datasetInstanceId: null,
  isFetchingInstanceData: false,
  instanceData: [],
  isFetchingInstanceDataPrintService: false,
  instanceDataPrintService: [],
  isFetchingDataTypes: false,
  isUpdatingDataModel: false,
  dataModelId: null,
  previousDataModelId: null,
  previousDataInstanceId: null,
  isAddingDataset: false,
  collapsedHierarchyListItems: {},
  isFetchingMappingForms: false,
  mappingForms: [],
  mappingFormPDFId: null,
  mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs: false,
  mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
  pdfFieldsByPage: [],
  isFetchingPDFFieldsByPage: false,
  isUpdatingInstanceData: false,
  isFetchingDataDefinitions: false,
  dataDefinitions: [],
  isAddingDatasetInstance: false,
  isAssigningPDFDataUsage: false,
  isUnassigningPDFDataUsage: false,
  PDFAttachmentColumnProperties: [],
  isFetchingPDFAttachmentColumnProperties: false,
};

const dataModelsReducer = reducerFactory({
  initialState,
  actionHandlers: {
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataModelsTypes,
      payloadKey: 'dataModels',
      fetchingKey: 'isFetchingDataModels',
      getAdditionalState: (state, payload) => ({
        // Set initial dataModelId
        dataModelId:
          state.dataModelId ||
          getFieldValue({
            currentValueFieldName: 'dataModelId',
            payloadFieldName: 'id',
            state,
            payload,
          }),
      }),
      initialAdditionalState: {
        isFetchingDataModels: true,
        datasets: [],
        dataItems: [],
      },
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDatasetsTypes,
      payloadKey: 'datasets',
      fetchingKey: 'isFetchingDatasets',
      getAdditionalState: (state, payload) => ({
        // Set initial datasetId
        datasetId:
          state.datasetId ||
          getFieldValue({
            currentValueFieldName: 'datasetId',
            payloadFieldName: 'id',
            state,
            payload,
          }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchAllDatasetsTypes,
      payloadKey: 'allDatasets',
      fetchingKey: 'isFetchingAllDatasetsNames',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataItemsTypes,
      payloadKey: 'dataItems',
      fetchingKey: 'isFetchingDataItems',
      getAdditionalState: (state, payload) => ({
        // Set initial dataItemId
        dataItemId:
          state.dataItemId ||
          getFieldValue({
            currentValueFieldName: 'dataItemId',
            payloadFieldName: 'id',
            state,
            payload,
          }),
      }),
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataTypesTypes,
      payloadKey: 'dataTypes',
      fetchingKey: 'isFetchingDataTypes',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchOverflowActionTypesTypes,
      payloadKey: 'overflowActionTypes',
      fetchingKey: 'isFetchingOverflowActionTypes',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchMappingFormsTypes,
      payloadKey: 'mappingForms',
      fetchingKey: 'isFetchingMappingForms',
      getAdditionalState: (state, payload) => ({
        // Set initial mappingFormPDFId
        mappingFormPDFId:
          state.mappingFormPDFId ||
          getFieldValue({
            currentValueFieldName: 'pdfId',
            payloadFieldName: 'pdfId',
            state,
            payload,
          }),
      }),
      initialAdditionalState: {
        mappingFormPDFId: null,
      },
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchMappingFormPDFPageDataUsageAndAttachmentConfigsTypes,
      payloadKey: 'mappingFormPDFPageDataUsageAndAttachmentConfigs',
      fetchingKey: 'isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs',
      getAdditionalState: () => ({
        // Set initial mappingFormPDFPageDataUsageAndAttachmentConfigId
        mappingFormPDFPageDataUsageAndAttachmentConfigId: FIRST_PAGE,
      }),
      initialAdditionalState: {
        mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
      },
    }),
    [RESET_MAPPING_FORM_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIGS]: state => ({
      ...state,
      mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
      mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPDFFieldsByPageTypes,
      payloadKey: 'pdfFieldsByPage',
      fetchingKey: 'isFetchingPDFFieldsByPage',
    }),
    [RESET_PDF_FIELDS_BY_PAGE]: state => ({
      ...state,
      pdfFieldsByPage: [],
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDatasetInstancesTypes,
      payloadKey: 'datasetInstances',
      fetchingKey: 'isFetchingDatasetInstances',
      getAdditionalState: (state, payload) => ({
        datasetInstanceId: getFieldValue({
          currentValueFieldName: 'datasetInstId',
          payloadFieldName: 'datasetInstId',
          state,
          payload,
        }),
      }),
    }),
    [SELECT_DATASET_INSTANCE]: (state, payload) => ({
      ...state,
      datasetInstanceId: payload,
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchInstanceDataTypes,
      payloadKey: 'instanceData',
      fetchingKey: 'isFetchingInstanceData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchInstanceDataPrintServiceTypes,
      payloadKey: 'instanceDataPrintService',
      fetchingKey: 'isFetchingInstanceDataPrintService',
    }),
    [CLEAR_INSTANCE_DATA_PRINT_SERVICE]: state => ({
      ...state,
      instanceDataPrintService: [],
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateDataModelTypes,
      fetchingKey: 'isUpdatingDataModel',
      getAdditionalState: (
        state,
        _payload,
        _key,
        [
          {
            dataModelId,
            dataModel: { description, contextTypeId, isCommon, shouldDisplayWorkpaperOnNavigator },
          },
        ],
      ) => {
        // TODO: SLT-6348 make it simplier with immer
        const dataModelsCopy = JSON.parse(JSON.stringify(state.dataModels));
        const dataModelIdx = dataModelsCopy.findIndex(({ id }) => id === dataModelId);

        if (dataModelIdx > -1) {
          dataModelsCopy[dataModelIdx] = {
            ...dataModelsCopy[dataModelIdx],
            description,
            contextTypeId,
            isCommon,
            shouldDisplayWorkpaperOnNavigator,
          };
        }
        return {
          dataModels: dataModelsCopy,
        };
      },
    }),
    ...getApiActionTypesHandlers({
      apiTypes: addDatasetTypes,
      fetchingKey: 'isAddingDataSet',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: updateInstanceDataTypes,
      fetchingKey: 'isUpdatingInstanceData',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchDataDefinitionsTypes,
      payloadKey: 'dataDefinitions',
      fetchingKey: 'isFetchingDataDefinitions',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: addDatasetInstanceTypes,
      fetchingKey: 'isAddingDatasetInstance',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: assignPDFDataUsageTypes,
      fetchingKey: 'isAssigningPDFDataUsage',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: unassignPDFDataUsageTypes,
      fetchingKey: 'isUnassigningPDFDataUsage',
    }),
    ...getApiActionTypesHandlers({
      apiTypes: fetchPDFAttachmentColumnPropertiesTypes,
      payloadKey: 'PDFAttachmentColumnProperties',
      fetchingKey: 'isFetchingPDFAttachmentColumnProperties',
    }),
    [deleteDatasetDefTypes.SUCCESS]: state => ({
      ...state,
      datasetId: getFieldValue({
        currentValueFieldName: 'id',
        payloadFieldName: 'id',
        state,
        payload: state.datasets,
      }),
    }),

    [SELECT_DATA_MODEL]: (state, payload) => ({
      ...state,
      dataModelId: payload,
      previousDataModelId: state.dataModelId,
      datasetId: null,
      previousDatasetId: state.datasetId,
      dataItems: [],
      dataItemId: null,
      previousDataItemId: state.dataItemId,
    }),
    [SELECT_DATASET]: (state, payload) => ({
      ...state,
      datasetId: payload,
      previousDatasetId: state.datasetId,
      dataItems: [],
      dataItemId: null,
      previousDataItemId: state.dataItemId,
    }),
    [EXPAND_COLLAPSE_HIERARCHY_LIST_ITEM]: (state, payload) => ({
      ...state,
      collapsedHierarchyListItems: {
        ...state.collapsedHierarchyListItems,
        [payload]: !state.collapsedHierarchyListItems[payload],
      },
    }),
    [SELECT_MAPPING_FORM_PDF]: (state, payload) => ({
      ...state,
      mappingFormPDFId: payload,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: FIRST_PAGE,
    }),
    [SELECT_PDF_PAGE_DATA_USAGE_AND_ATTACHMENT_CONFIG]: (state, payload) => ({
      ...state,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: payload,
    }),
    [SELECT_WORKPAPER_FORM]: (state, payload) => ({
      ...state,
      dataModelId: payload,
    }),
    [SELECT_ENTITY]: state => ({
      ...state,
      forms: [],
      datasetInstances: [],
      instanceData: [],
    }),
    [SELECT_TAX_YEAR]: state => ({
      ...state,
      dataModelId: null,
      previousDataModelId: state.dataModelId,
      datasetId: null,
      previousDatasetId: state.datasetId,
      dataItemId: null,
      previousDataItemId: state.dataItemId,
      mappingFormPDFId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
      forms: [],
      dataModels: [],
      datasetInstances: [],
      instanceData: [],
    }),
    [DEVELOPMENT_SELECT_TAX_YEAR]: state => ({
      ...state,
      datasetId: null,
      mappingFormPDFId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
      forms: [],
      dataModels: [],
      datasetInstances: [],
      instanceData: [],
    }),
    [SELECT_JURISDICTION_ID]: state => ({
      ...state,
      dataModels: [],
      mappingFormPDFId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
      dataModelId: null,
      previousDataModelId: state.dataModelId,
      datasetId: null,
      previousDatasetId: state.datasetId,
      dataItemId: null,
      previousDataItemId: state.dataItemId,
    }),
    [SELECT_JURISDICTION]: state => ({
      ...state,
      forms: [],
      datasetInstances: [],
      instanceData: [],
    }),
    [SELECT_PERIOD]: state => ({
      ...state,
      datasetId: null,
      mappingFormPDFId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
      forms: [],
      datasetInstances: [],
      instanceData: [],
    }),
    [DEVELOPMENT_SELECT_PERIOD]: state => ({
      ...state,
      datasetId: null,
      mappingFormPDFId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigId: null,
      mappingFormPDFPageDataUsageAndAttachmentConfigs: [],
      forms: [],
      datasetInstances: [],
      instanceData: [],
    }),
    [SELECT_FILING_GROUP_ID]: state => ({
      ...state,
      forms: [],
      datasetInstances: [],
      instanceData: [],
    }),
    [SELECT_DATA_ITEM]: (state, payload) => ({
      ...state,
      dataItemId: payload,
      previousDataItemId: state.dataItemId,
    }),
    [CLEAR_DATASET_INSTANCES]: state => ({
      ...state,
      datasetInstances: [],
    }),
    [CLEAR_INSTANCE_DATA]: state => ({
      ...state,
      instanceData: [],
      instanceDataPrintService: [],
    }),
    [SET_STATE_FROM_EXPRESSION_CALCULATION]: (state, expressionCalculationDataModelsState) => ({
      ...state,
      dataModels: expressionCalculationDataModelsState.dataModels,
      datasets: expressionCalculationDataModelsState.datasets,
      dataItems: expressionCalculationDataModelsState.dataItems,
      dataModelId: expressionCalculationDataModelsState.dataModelId,
      datasetId: expressionCalculationDataModelsState.datasetId,
      previousDataModelId: expressionCalculationDataModelsState.previousDataModelId,
      previousDatasetId: expressionCalculationDataModelsState.previousDatasetId,
      previousDataItemId: expressionCalculationDataModelsState.previousDataItemId,
      dataItemId: expressionCalculationDataModelsState.dataItemId,
      pdfFieldsByPage: expressionCalculationDataModelsState.pdfFieldsByPage,
    }),
  },
});

export default dataModelsReducer;
