import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import useModal from '../../shared/hooks/useModal.hook';
import { taxYearSelector, periodSelector } from '../../shared/store/selectors';

import UploadSubmissionModal from './uploadSubmissionModal.component';
import getColumnDefinitions from './efileSubmission.columnDefinitions';
import { fetchSubmissions } from './store/actions';
import { submissionsSelector, isFetchingSubmissionsSelector } from './store/selectors';

const EFileSubmission = ({
  taxYear,
  period,
  submissions,
  isFetchingSubmissions,
  fetchSubmissions,
}) => {
  const { showModal, modalProps } = useModal();

  const columnDefinitions = useMemo(() => getColumnDefinitions({ showModal }), [showModal]);

  const fetchGridData = useCallback(() => {
    const isContextReady = taxYear && period;
    if (isContextReady) {
      fetchSubmissions({ taxYear, period });
    }
  }, [fetchSubmissions, taxYear, period]);

  useEffect(() => {
    fetchGridData();
  }, [fetchGridData]);

  return (
    <>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={submissions}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingSubmissions}
          />
        </div>
      </div>
      <UploadSubmissionModal {...modalProps} />
    </>
  );
};

EFileSubmission.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  submissions: PropTypes.arrayOf(
    PropTypes.shape({
      submissionId: PropTypes.string.isRequired,
      taxPayer: PropTypes.string.isRequired,
      jurisdiction: PropTypes.string.isRequired,
      isSubmissionUploaded: PropTypes.bool.isRequired,
      submissionUploadDate: PropTypes.string,
      isMapUploaded: PropTypes.bool.isRequired,
      mappingUploadDate: PropTypes.string.isRequired,
      isResultAvailable: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isFetchingSubmissions: PropTypes.bool.isRequired,
  fetchSubmissions: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    submissions: submissionsSelector(state),
    isFetchingSubmissions: isFetchingSubmissionsSelector(state),
  }),
  {
    fetchSubmissions,
  },
)(EFileSubmission);
