import React from 'react';
import { Routes } from '@common-packages/routes-definitions';

import {
  BreadcrumbRoute,
  StaticHomeBreadcrumb,
} from '../../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

const ToolsBreadcrumbRoutes = () => (
  <>
    <StaticHomeBreadcrumb routePath={Routes.complianceTools.MAIN} />
    <BreadcrumbRoute path={Routes.genericAccountDataUpload} />
    <BreadcrumbRoute path={Routes.informationalDataUpload} />
    <BreadcrumbRoute path={Routes.multDataUpload} />
    <BreadcrumbRoute path={Routes.federalDataRefresh} />
    <BreadcrumbRoute path={Routes.gtwFileUpload} />
    <BreadcrumbRoute path={Routes.ownershipAllocationsUpload} />
    <BreadcrumbRoute path={Routes.filingGroupMembersUpload} />
    <BreadcrumbRoute path={Routes.diagnostics} />
  </>
);

export default ToolsBreadcrumbRoutes;
