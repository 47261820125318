import { SelectOptionsResponse } from '@tls/slt-types';

import {
  FilingEntitiesRequestParams,
  FilingEntitiesResponse,
  FilingEntitiesOptionsParams,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from '.';

export const useQueryConsolidatedFilingEntities = ({
  params,
  enabled,
}: {
  params: FilingEntitiesRequestParams;
  enabled: boolean;
}) => {
  const { taxYear, period, consolidationId, jurisdictionId } = params;
  return useCustomQuery<FilingEntitiesResponse>(
    [QueryKeys.ConsolidationFilingEntities.Data, params],
    {
      url: `/api/consolidations/filing-entities/${taxYear}/${period}/${consolidationId}/${jurisdictionId}`,
      enabled,
      errorMessage: 'Fetching filing entities for consolidation failed',
    },
  );
};

export const useQueryConsolidatedFilingEntitiesOptions = ({
  params,
  enabled,
}: {
  params: FilingEntitiesOptionsParams;
  enabled: boolean;
}) =>
  useCustomQuery<SelectOptionsResponse>(
    [QueryKeys.ConsolidationFilingEntities.EntitiesOptions, params],
    {
      url: `/api/consolidations/filing-entities/options/${params.taxYear}/${params.period}/${params.consolidationId}/${params.jurisdictionId}`,
      enabled,
      errorMessage: 'Fetching filing entity options for consolidation failed',
    },
  );
