import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const cleanLocationState = history => history.replace();

const useDashboardEffect = effectAction => {
  const history = useHistory();
  const { state } = useLocation();

  const navigatedFromDashboard = state && state.fromDashboard;

  useEffect(() => {
    if (navigatedFromDashboard) {
      cleanLocationState(history);
      return;
    }

    effectAction();
    // navigatedFromDashboard is omitted on purpose
    // we don't want to trigger the effectAction when it changes back to false/undefined
  }, [effectAction, history]);
};

export default useDashboardEffect;
