const Yup = require('yup');

const { getBusinessTypeSchema } = require('../getBusinessTypeSchema');
const { getReturnTypeSchema } = require('../getReturnTypeSchema');

const updateFilingDecisionSchema = Yup.object()
  .shape({
    jurisdiction: Yup.string().required(),
    taxYear: Yup.string().required(),
    period: Yup.string().required(),
    entityId: Yup.string().required(),
    profileId: Yup.string().required(),
    jurisdictionId: Yup.string().required(),
    filingAttributes: Yup.number().required(),
    calcPreferences: Yup.number().required(),
    franchiseTaxInd: Yup.bool().required(),
    incomeTaxInd: Yup.bool().required(),
    nexusInd: Yup.bool().required(),
    federalAsFiledFilingGroupId: Yup.string().nullable(),
    FILING_TYPE_SEPARATE: Yup.bool().required(),
    FILING_TYPE_CONS_PRE_APP: Yup.bool().required(),
    FILING_TYPE_CONS_UNITARY: Yup.bool().required(),
    FILING_TYPE_CONS_POST_APP: Yup.bool().required(),
    PERIOD_TYPE_ANNUAL: Yup.bool().required(),
    PERIOD_TYPE_ESTIMATE1: Yup.bool().required(),
    PERIOD_TYPE_ESTIMATE2: Yup.bool().required(),
    PERIOD_TYPE_ESTIMATE3: Yup.bool().required(),
    PERIOD_TYPE_ESTIMATE4: Yup.bool().required(),
    PERIOD_TYPE_EXTENSION: Yup.bool().required(),
    PERIOD_TYPE_SECOND_EXTENSION: Yup.bool().required(),
    PERIOD_TYPE_AMENDED: Yup.bool().required(),
    PERIOD_TYPE_RAR: Yup.bool().required(),
    TAX_TYPE_INCOME: Yup.bool().required(),
    TAX_TYPE_FRANCHISE: Yup.bool().required(),
    ...getBusinessTypeSchema(),
    ...getReturnTypeSchema(),
  })
  .notOneOf([undefined, null]) // eslint-disable-line no-undefined
  .strict();

module.exports = {
  updateFilingDecisionSchema,
};
