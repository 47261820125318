import { PRINT_SERVICE_PAGE_CONTAINER_ID } from '../../../shared/constants';
import { Alignments } from '../../constants';
import { DataTypeNames } from '../../enums';

const findSiblings = (currentField, fieldsArray) =>
  fieldsArray
    .filter(field => field.diiID === currentField.diiID && field.blockId !== currentField.blockId)
    .map(({ blockId, mappingID }) => ({
      blockId,
      mappingID,
    }));

const matchFieldValuesWithNodeStyles = (field, node) => {
  const {
    style: { position, left, bottom, width, height, lineHeight, fontSize, fontFamily },
  } = node;

  const { alignment } = field;

  return {
    ...field,
    styles: {
      position,
      left,
      bottom,
      width,
      height,
      lineHeight,
      fontFamily: fontFamily || 'inherit',
      fontSize: fontSize || 'inherit',
      textAlign: Alignments[alignment] || 'left',
    },
  };
};

const checkIfPageIsLandscape = ({ page }) => {
  const { offsetWidth, offsetHeight } = page;

  return offsetWidth > offsetHeight;
};

export const getDomInputsData = ({
  fieldsValues = [],
  containerId = PRINT_SERVICE_PAGE_CONTAINER_ID,
}) => {
  const pageContainer = document.getElementById(containerId);
  const pages = Array.from(pageContainer?.children || []);

  pageContainer.querySelectorAll('input').forEach(input => {
    input.disabled = true;
  });

  const computedPages = pages.reduce((acc, page) => {
    const { id } = page;
    const pageClassNames = page.classList.value;
    const isLandscape = checkIfPageIsLandscape({ page });

    acc[id] = {
      pageId: id,
      pageClassNames,
      fields: [],
      drillLinks: [],
      groupFields: {},
      isLandscape,
    };

    return acc;
  }, {});

  const mappedPagesWithFields = fieldsValues.reduce((acc, field) => {
    const { blockId, checkboxGroupId, dataType, drillTypeName, baseFieldName } = field;
    const fieldNode = document.getElementById(blockId || baseFieldName);

    if (fieldNode) {
      const parentId = fieldNode.parentElement.id;

      fieldNode.parentNode.removeChild(fieldNode);

      const fieldData = matchFieldValuesWithNodeStyles(field, fieldNode);

      if (checkboxGroupId && dataType === DataTypeNames.Boolean) {
        const groupFields = acc[parentId]?.groupFields;
        if (!groupFields[checkboxGroupId]) {
          groupFields[checkboxGroupId] = [];
        }
        groupFields[checkboxGroupId].push(fieldData);
      } else if (drillTypeName) {
        acc[parentId]?.drillLinks.push(fieldData);
      } else {
        acc[parentId]?.fields.push(fieldData);
      }

      return acc;
    }

    return acc;
  }, computedPages);

  const siblingCandidates = fieldsValues.filter(field => field.diiID);

  const formData = fieldsValues.reduce((acc, curr) => {
    const { blockId } = curr;

    const siblings = findSiblings(curr, siblingCandidates);

    return {
      ...acc,
      [blockId]: {
        ...curr,
        ...(siblings.length ? { siblings } : {}),
      },
    };
  }, {});

  return {
    pages: mappedPagesWithFields,
    formData,
  };
};
