import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';

import { defaultSideBarWithColumnsToolPanel } from '../../shared/displayComponents/agGrid/constants';
import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { errorNotification } from '../../shared/notification/store/actions';
import { useRowEditMode } from '../../shared/editMode';
import useModal from '../../shared/hooks/useModal.hook';
import useUrlParams from '../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { columnBlueprintHeaderOptions } from '../../shared/columnDefinitions/constants';
import columnBlueprintStyles from '../../shared/styles/columnBlueprintStyles.module.scss';
import {
  periodSelector,
  taxYearSelector,
  jurisdictionIdSelector,
  isFetchingJurisdictionsSelector,
} from '../store/selectors';

import { calcSpecTypes } from './constants';
import validateCalcSpecs from './validateCalcSpecs';
import ValidationErrorModal from './validationErrorModal.component';
import getColumnBlueprintBasedColumnDefinitions from './calcSpecs.columnDefinition';
import {
  fetchBaseCalcSpecs,
  updateBaseCalcSpec,
  createBaseCalcSpec,
  blockContextChanges,
} from './store/actions';
import {
  baseCalcSpecsSelector,
  baseCalcSpecsColumnsBlueprintSelector,
  isFetchingBaseCalcSpecsSelector,
  isFetchingCalcSpecsOptionsSelector,
  accountOptionsSelector,
  categoryOptionsSelector,
  datasetDefinitionOptionsSelector,
  entityOptionsSelector,
  groupedFilingAttributesSelector,
  isUpdatingBaseCalcSpecSelector,
  isCreatingBaseCalcSpecSelector,
} from './store/selectors';
import { fetchDatasetItemDefinitionOptions } from './store/api';

const calcSpecInitialValues = {
  categoryId: '',
  subcategory: '',
  accountId: '',
  accountJurisdictionId: '',
  multiplier: '1',
  displayOrder: '100',
  orgId: '',
  onOffInd: false,
  calcPreferences: '0',
};

const getFormattedValues = row => ({
  ...row,
  multiplier: parseFloat(row.multiplier),
  onOffInd: Boolean(row.onOffInd),
  displayOrder: Number(row.displayOrder),
  calcPreferences: Number(row.calcPreferences),
  accountJurisdictionId: String(row.accountJurisdictionId),
});

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const fillCells = params => params.initialValues[0];

const BaseCalcSpecs = ({ categories = [], hasUserPermissionsToEdit }) => {
  const { queryParams, setParams } = useUrlParams();
  const [validationResult, setValidationResult] = useState({});
  const [addedRowsCounter, setAddedRowsCounter] = useState(0);

  const dispatch = useDispatch();

  const taxYear = useSelector(taxYearSelector);
  const period = useSelector(periodSelector);
  const jurisdictionId = useSelector(jurisdictionIdSelector);
  const isFetchingJurisdictions = useSelector(isFetchingJurisdictionsSelector);

  const calcSpecs = useSelector(baseCalcSpecsSelector);
  const calcSpecsColumnsBlueprint = useSelector(baseCalcSpecsColumnsBlueprintSelector);
  const isFetchingCalcSpecs = useSelector(isFetchingBaseCalcSpecsSelector);

  const accountOptions = useSelector(accountOptionsSelector);
  const categoryOptions = useSelector(categoryOptionsSelector);
  const entityOptions = useSelector(entityOptionsSelector);
  const datasetDefinitionOptions = useSelector(datasetDefinitionOptionsSelector);
  const groupedFilingAttributes = useSelector(groupedFilingAttributesSelector);
  const isFetchingCalcSpecsOptions = useSelector(isFetchingCalcSpecsOptionsSelector);

  const isUpdatingCalcSpecs = useSelector(isUpdatingBaseCalcSpecSelector);
  const isCreatingCalcSpecs = useSelector(isCreatingBaseCalcSpecSelector);

  useEffect(() => {
    if (queryParams.search || queryParams.search === '') {
      setParams({ queryParams: { search: null } });
    }
  }, [setParams, queryParams.search]);

  const { showModal: showValidationErrorModal, modalProps } = useModal();

  const isContextReady = taxYear && jurisdictionId;

  const displayNameAndSelectOptionDictionary = useMemo(
    () => ({
      accountId: accountOptions,
      categoryId: categoryOptions,
      orgId: [{ value: '', label: 'NO OVERRIDE' }, ...entityOptions],
      dataset: datasetDefinitionOptions,
    }),
    [accountOptions, categoryOptions, entityOptions, datasetDefinitionOptions],
  );

  const fetchDataItems = useCallback(
    ({ dataset: datasetName }) => {
      if (!datasetName) {
        return [];
      }
      return fetchDatasetItemDefinitionOptions({ taxYear, jurisdictionId, datasetName });
    },
    [taxYear, jurisdictionId],
  );

  const fetchSelectOptionDictionary = useMemo(
    () => ({
      dataitem: fetchDataItems,
    }),
    [fetchDataItems],
  );

  useEffect(() => {
    if (isContextReady) {
      dispatch(fetchBaseCalcSpecs({ taxYear, jurisdictionId }));
    }
  }, [dispatch, isContextReady, taxYear, jurisdictionId]);

  const isLoading =
    !isContextReady ||
    isFetchingCalcSpecs ||
    isFetchingCalcSpecsOptions ||
    isUpdatingCalcSpecs ||
    isCreatingCalcSpecs ||
    isFetchingJurisdictions;

  const onSaveCalcSpec = useCallback(
    async ({ rowsPairsWithChanges, rowsToAdd }) => {
      const formattedModifiedRows = rowsPairsWithChanges.map(({ newRow }) => {
        const formattedValues = getFormattedValues(newRow);

        return { ...newRow, ...formattedValues };
      });

      const formattedAddedRows = rowsToAdd.map(newRow => {
        const formattedValues = getFormattedValues(newRow);

        return {
          ...newRow,
          ...formattedValues,
          taxYear: Number(taxYear),
          jurisdictionId,
        };
      });

      const { duplicatedRowsIds, rowsWithInvalidCells, modalMessage } = validateCalcSpecs({
        addOrModifiedRows: [...formattedModifiedRows, ...formattedAddedRows],
        calcSpecs,
        displayNameAndSelectOptionDictionary,
        calcSpecType: calcSpecTypes.BASE,
        groupedFilingAttributes,
      });

      if (modalMessage) {
        showValidationErrorModal(modalMessage);
        setValidationResult({ duplicatedRowsIds, rowsWithInvalidCells });
        return { shouldCancelHookStateReset: true };
      }

      if (rowsPairsWithChanges.length) {
        await dispatch(
          updateBaseCalcSpec({
            values: formattedModifiedRows,
            taxYear,
            period,
            jurisdictionId,
          }),
        );
      }

      if (rowsToAdd.length) {
        await dispatch(createBaseCalcSpec({ values: formattedAddedRows, taxYear, jurisdictionId }));
      }

      setAddedRowsCounter(0);
      setValidationResult({});
      dispatch(blockContextChanges(false));
      dispatch(fetchBaseCalcSpecs({ taxYear, jurisdictionId }));
    },
    [
      setValidationResult,
      setAddedRowsCounter,
      showValidationErrorModal,
      dispatch,
      jurisdictionId,
      taxYear,
      calcSpecs,
      displayNameAndSelectOptionDictionary,
      period,
      groupedFilingAttributes,
    ],
  );

  const onEnterEdit = useCallback(() => {
    dispatch(blockContextChanges(true));
  }, [dispatch]);

  const onCancelEdit = useCallback(() => {
    setValidationResult({});
    dispatch(blockContextChanges(false));
  }, [dispatch]);

  const filteredRowData = useMemo(() => {
    const selectedCategoriesSet = new Set(categories);
    return calcSpecs.filter(({ categoryId }) => selectedCategoriesSet.has(categoryId));
  }, [calcSpecs, categories]);

  const {
    navigationPrompt,
    isInEditMode,
    setIsInEditMode,
    editModeButtons,
    clonedRowData,
    updateRow,
    addRow,
    deleteRow,
    onGridReady,
  } = useRowEditMode({
    onEnter: onEnterEdit,
    onSave: onSaveCalcSpec,
    onCancel: onCancelEdit,
    rowData: filteredRowData,
    getUniqueRowId,
    editButtonDisabled: isLoading || !filteredRowData,
    appendRowUnderSelectedRow: true,
  });

  const addCalcSpec = useCallback(() => {
    addRow({ ...calcSpecInitialValues, isAddedRow: true, rowId: String(addedRowsCounter) });
    setAddedRowsCounter(addedRowsCounter + 1);
    setIsInEditMode(true);
    dispatch(blockContextChanges(true));
  }, [addRow, setIsInEditMode, dispatch, addedRowsCounter]);

  const columnDefinitions = useMemo(
    () =>
      getColumnBlueprintBasedColumnDefinitions({
        updateRow,
        onDeleteIconClick: deleteRow,
        isInEditMode,
        columnsBlueprint: calcSpecsColumnsBlueprint,
        displayNameAndSelectOptionDictionary,
        fetchSelectOptionDictionary,
        onCellValueChangedDictionary: {
          dataset: ({ data }) => {
            data.dataitem = null;
            updateRow(data);
          },
        },
        validationResult,
        showErrorNotification: dispatch(errorNotification),
      }),
    [
      dispatch,
      updateRow,
      deleteRow,
      isInEditMode,
      calcSpecsColumnsBlueprint,
      displayNameAndSelectOptionDictionary,
      fetchSelectOptionDictionary,
      validationResult,
    ],
  );

  return (
    <>
      {navigationPrompt}
      <ValidationErrorModal {...modalProps} />
      <div className="add-button-column">
        {hasUserPermissionsToEdit && (
          <>
            <Button size="lg" className="add-button" onClick={addCalcSpec} disabled={isLoading}>
              Add Calc Spec
            </Button>
            {editModeButtons}
          </>
        )}
      </div>
      <div className={`row grid-row ${columnBlueprintStyles.gridContainer}`}>
        <div className="col">
          <AgGrid
            rowData={categories.length ? clonedRowData : []}
            columnDefs={columnDefinitions}
            isGridLoading={isLoading}
            onGridReady={onGridReady}
            initialQuickFilterValue={queryParams.search || ''}
            withSearchBar
            enableRangeSelection
            suppressCopyRowsToClipboard
            suppressMultiRangeSelection
            enableFillHandle={isInEditMode}
            fillOperation={fillCells}
            undoRedoCellEditing
            stopEditingWhenCellsLoseFocus
            sideBar={defaultSideBarWithColumnsToolPanel}
            {...columnBlueprintHeaderOptions}
            enableBrowserTooltips
            areHeaderCellBordersEnabled
          />
        </div>
      </div>
    </>
  );
};

BaseCalcSpecs.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string.isRequired),
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default BaseCalcSpecs;
