import { createSelector } from 'reselect';

export type TEMP_STORE = {
  shared: unknown;
};

export const getApiActionTypes = (type: string) => ({
  START: type,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
});

export const optionsSelectorFactory = ({
  listSelector,
  labelKey,
  valueKey,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listSelector: (store: TEMP_STORE) => any;
  labelKey: string;
  valueKey: string;
}) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createSelector([listSelector], (list: { [key: string]: any }[]) =>
    list.map(item => ({ value: item[valueKey], label: String(item[labelKey]) })),
  );
