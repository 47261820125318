import React from 'react';
import { Field, useFormikContext } from 'formik';

import Input from '../../../../shared/forms/inputFormik/inputFormik.component';
import ButtonsGroup from '../../../../shared/forms/buttonsGroup/buttonsGroup.component';

import styles from './addEditCheckboxGroupForm.module.scss';

const AddEditCheckboxGroupForm = () => {
  const { isSubmitting, resetForm, submitForm, dirty } = useFormikContext();

  return (
    <form className={styles.editCheckboxGroupForm}>
      <div className={styles.editCheckboxGroupFormFields}>
        <Field className="form-text" label="Checkbox Group Name" name="name" component={Input} />
        <Field
          className="form-text"
          label="Checkbox Group Description"
          name="description"
          component={Input}
        />
      </div>
      <ButtonsGroup
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        onCancelClick={resetForm}
        submitButtonLabel="Save"
        disabled={!dirty}
      />
    </form>
  );
};

export default AddEditCheckboxGroupForm;
