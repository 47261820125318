import { connect } from 'react-redux';

import {
  isFetchingConsolidationFilingAttributesSelector,
  consolidationFilingAttributesSelector,
  consolidationFilingAttributesColumnsBlueprintSelector,
  isConsolidatedContextReadySelector,
  contextSelector,
} from '../store/selectors';
import { fetchConsolidationFilingAttributes } from '../store/actions';
import FilingAttributesDashboard from '../filingAttributes.component';

export default connect(
  state => ({
    isContextReady: isConsolidatedContextReadySelector(state),
    context: contextSelector(state),
    getSlideInTitle: ({ organizationName }) => organizationName,

    filingAttributes: consolidationFilingAttributesSelector(state),
    filingAttributesColumnsBlueprint: consolidationFilingAttributesColumnsBlueprintSelector(state),
    isFetchingFilingAttributes: isFetchingConsolidationFilingAttributesSelector(state),
  }),
  {
    fetchFilingAttributes: fetchConsolidationFilingAttributes,
  },
)(FilingAttributesDashboard);
