import { useEffect } from 'react';
import PropTypes from 'prop-types';

const MountListenerWrapper = ({ children, onMount, onUnmount }) => {
  useEffect(() => {
    onMount();
    return () => {
      onUnmount();
    };
  }, [onMount, onUnmount]);
  return children;
};

MountListenerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onMount: PropTypes.func,
  onUnmount: PropTypes.func,
};

export default MountListenerWrapper;
