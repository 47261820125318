import omit from 'lodash.omit';

import { defaultColumnDefinition } from '../../shared/columnDefinitions';
import { EditableCheckboxCellRenderer } from '../../shared/columnDefinitions/cellRenderers';

import getHasAllChildrenChecked from './getHasAllChildrenChecked';

const refreshParent = ({ api, node, field, value }) => {
  const parent = node.parent;
  if (node?.parent && parent.level >= 0) {
    parent.data = omit(parent.data, field);
    api.refreshCells({ rowNodes: [parent], force: true });
    refreshParent({
      api,
      node: parent,
      field,
      value,
    });
  }
};

const refreshChildren = ({ api, node, field, value }) => {
  const rowNodes = [];
  node.allLeafChildren.forEach(leafNode => {
    leafNode.data = {
      ...leafNode.data,
      [field]: value,
    };
    rowNodes.push(leafNode);
  });
  api.refreshCells({ rowNodes, force: true });
};

const getColumnDefinitions = ({ updateRow, isInEditMode, permissions }) => {
  const onCellValueChanged = ({
    data,
    node,
    api,
    column: {
      colDef: { field },
    },
  }) => {
    refreshChildren({ api, node, field, value: data[field] });
    refreshParent({ api, node, field, value: data[field] });
    updateRow();
  };

  const columnDefinitions = [
    {
      ...defaultColumnDefinition,
      width: 600,
      field: 'name',
      cellRenderer: 'agGroupCellRenderer',
      hide: true,
    },
    {
      ...defaultColumnDefinition,
      field: 'MAIN',
      headerName: 'Route',
      width: 400,
      valueGetter: ({ data, colDef }) => (data.type !== 'folder' ? data[colDef.field] : ''),
    },
    ...permissions.map(({ id, name }) => ({
      ...defaultColumnDefinition,
      headerName: name,
      children: [
        {
          ...defaultColumnDefinition,
          headerName: 'Read',
          columnGroupShow: 'open',
          field: `${id}Read`,
          width: 60,
          cellStyle: {
            textAlign: 'center',
          },
          cellRenderer: EditableCheckboxCellRenderer,
          cellRendererParams: {
            isDisabled: ({ data, node }) => {
              const editColDefField = `${id}Edit`;
              if (
                data.type === 'folder' &&
                getHasAllChildrenChecked({ node, data }, editColDefField)
              ) {
                return true;
              }
              return !isInEditMode || data[editColDefField];
            },
          },
          valueGetter: ({ data, colDef, node }) => {
            const editColDefField = `${id}Edit`;
            if (data.type === 'folder') {
              return (
                getHasAllChildrenChecked({ node, data }, colDef.field) ||
                getHasAllChildrenChecked({ node, data }, editColDefField) ||
                data[colDef.field] ||
                data[editColDefField]
              );
            }
            return data[colDef.field] || data[editColDefField];
          },
          onCellValueChanged,
        },
        {
          ...defaultColumnDefinition,
          headerName: 'Edit',
          columnGroupShow: 'open',
          field: `${id}Edit`,
          width: 60,
          cellStyle: {
            textAlign: 'center',
          },
          cellRenderer: EditableCheckboxCellRenderer,
          cellRendererParams: {
            disabled: !isInEditMode,
          },
          valueGetter: ({ data, colDef, node }) => {
            if (data.type === 'folder') {
              return getHasAllChildrenChecked({ data, node }, colDef.field) || data[colDef.field];
            }
            return data[colDef.field];
          },
          onCellValueChanged,
        },
      ],
    })),
  ];

  return columnDefinitions;
};

export default getColumnDefinitions;
