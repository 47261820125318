import { createSelector } from 'reselect';

import { optionsSelectorFactory } from '../../../shared/store/storeHelpers';

export const taxFormSelector = ({ development }) => development.taxFormsV2.taxForm;

export const isFetchingTaxFormSelector = ({ development }) =>
  development.taxFormsV2.isFetchingTaxForm;

export const isPdfProcessedSelector = createSelector(
  [taxFormSelector, isFetchingTaxFormSelector],
  ({ formDetails: { formId, jobId, finishedOn, returnedError } }, isFetchingTaxForm) =>
    isFetchingTaxForm || Boolean(formId && (!jobId || finishedOn || returnedError)),
);

export const formHTMLReturnedErrorSelector = createSelector(
  [taxFormSelector],
  ({ formDetails: { returnedError } }) => {
    const startErrorMessageIndex = returnedError?.indexOf('{');

    if (!startErrorMessageIndex || startErrorMessageIndex === -1) {
      return null;
    }

    const errorMessageJson = returnedError.slice(startErrorMessageIndex);
    const errorMessageObj = JSON.parse(errorMessageJson);

    return errorMessageObj?.error || null;
  },
);

export const taxFormJobIdSelector = createSelector(
  [taxFormSelector],
  ({ formDetails: { jobId } }) => jobId,
);

export const isFetchingDataModelsWithGroupedDatasetsAndDataItemsSelector = ({ development }) =>
  development.taxFormsV2.isFetchingDataModelsWithGroupedDatasetsAndDataItems;

const dataModelsSelector = ({ development }) => development.taxFormsV2.dataModels;

export const dataModelsOptionsSelector = optionsSelectorFactory({
  listSelector: dataModelsSelector,
  labelKey: 'name',
  valueKey: 'id',
});

export const groupedDatasetsSelector = ({ development }) => development.taxFormsV2.datasets;
export const groupedDataItemsSelector = ({ development }) => development.taxFormsV2.dataItems;

export const pdfPageDataUsageSelector = ({ development }) =>
  development.taxFormsV2.pdfPageDataUsage;
export const isFetchingPdfPageDataUsageSelector = ({ development }) =>
  development.taxFormsV2.isFetchingPdfPageDataUsage;

export const isUpdatingTaxFormSelector = ({ development }) =>
  development.taxFormsV2.isUpdatingTaxForm;

export const watermarkOptionsSelector = optionsSelectorFactory({
  listSelector: ({ development }) => development.taxFormsV2.taxForm.watermarksList,
  labelKey: 'watermark',
  valueKey: 'watermark',
});
