import React, { useMemo } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { ReturnStatusTrackerDetailsVisualData } from '../../../../common/types';

export const ReturnStatusTrackerDetailsStatusVisualsPieChart = ({
  title = '',
  subTitle = '',
  data,
}: {
  title?: string;
  subTitle?: string;
  data: Array<ReturnStatusTrackerDetailsVisualData>;
}) => {
  const pieChartOption = useMemo(
    () => ({
      chart: {
        renderTo: 'return-status-tracker-details-statusvisuals-pie-chart',
        type: 'pie',
        height: '100%',
        spacing: [0, 0, 0, 0],
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: title,
        verticalAlign: 'middle',
        style: {
          color: '#2D2D2D',
          fontFamily: 'Helvetica Neue',
          fontSize: '28px',
          lineHeight: '28px',
          fontWeight: 500,
          transform: 'translateY(-14px)',
        },
      },
      subtitle: {
        text: subTitle,
        verticalAlign: 'middle',
        style: {
          color: '#7D7D7D',
          fontFamily: 'Helvetica Neue',
          fontSize: '12px',
          lineHeight: '12px',
          fontWeight: 500,
          transform: 'translateY(14px)',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Value',
          innerSize: '80%',
          data,
        },
      ],
    }),
    [data, title, subTitle],
  );

  return <HighchartsReact highcharts={Highcharts} options={{ ...pieChartOption }} />;
};
