export const accountsSelector = ({ admin }) => admin.accounts.accounts;

export const isFetchingAccountsSelector = ({ admin }) => admin.accounts.isFetchingAccounts;

export const accountGroupsSelector = ({ admin }) => admin.accounts.accountGroups;

export const isFetchingAccountGroupsSelector = ({ admin }) =>
  admin.accounts.isFetchingAccountGroups;

export const screenGroupsSelector = ({ admin }) => admin.accounts.screenGroups;

export const isFetchingScreenGroupsSelector = ({ admin }) => admin.accounts.isFetchingScreenGroups;

export const functionsSelector = ({ admin }) => admin.accounts.functions;

export const isFetchingFunctionsSelector = ({ admin }) => admin.accounts.isFetchingFunctions;

export const consFunctionsSelector = ({ admin }) => admin.accounts.consFunctions;

export const isFetchingConsFunctionsSelector = ({ admin }) =>
  admin.accounts.isFetchingConsFunctions;
