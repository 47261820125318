import { apiAction } from '../../../shared/store/apiAction';

import * as api from './api';
import {
  fetchDefaultBinderItemsTypes,
  fetchBinderItemTypesTypes,
  fetchFormsTypes,
  updateBinderItemsTypes,
} from './types';

export const fetchDefaultBinderItems = apiAction({
  types: fetchDefaultBinderItemsTypes,
  apiAction: api.fetchDefaultBinderItems,
});

export const fetchBinderItemTypes = apiAction({
  types: fetchBinderItemTypesTypes,
  apiAction: api.fetchBinderItemTypes,
});

export const fetchForms = apiAction({
  types: fetchFormsTypes,
  apiAction: api.fetchForms,
});

export const updateBinderItems = apiAction(
  {
    types: updateBinderItemsTypes,
    apiAction: api.updateBinderItems,
  },
  {
    successNotificationMessage: () => 'Updating the binder items succeeded',
    errorNotificationMessage: () => 'Updating the binder items failed',
  },
);
