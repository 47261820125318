import { useCallback, useMemo } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { compile } from 'path-to-regexp';

const getValuesFromUrl = (urlParams: URLSearchParams) =>
  Array.from(urlParams.entries()).reduce((acc: Record<string, string>, [paramName, paramValue]) => {
    acc[paramName] = paramValue;
    return acc;
  }, {});

const useUrlParams = <
  TParams extends { [K in keyof TParams]?: string | undefined } = Record<string, string>
>() => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<TParams>();

  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const queryParams = getValuesFromUrl(urlParams);
  const routeParams = match.params;
  const routeUrl = match.url;

  const setParams = useCallback(
    ({ queryParams: newQueryParams = {}, routeParams: newRouteParams = {} } = {}) => {
      Object.entries(newQueryParams).forEach(([paramName, paramValue]) => {
        if (paramValue !== null && typeof paramValue === 'string') {
          urlParams.set(paramName, paramValue);
          return;
        }

        urlParams.delete(paramName);
      });

      const mergedRouteParams = { ...routeParams, ...newRouteParams };
      const compiledRoute = compile(match.path)(mergedRouteParams);

      history.push({
        state: location.state,
        pathname: compiledRoute,
        search: urlParams.toString(),
      });
    },
    [history, location, match, routeParams, urlParams],
  );

  return { queryParams, routeParams, routeUrl, setParams };
};

export default useUrlParams;
