import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '@pwc/appkit-react';

import useBooleanState from '../shared/hooks/useBooleanState.hook';
import { customerDataSelector, clientSelector } from '../shared/store/selectors';
import Notifications from '../notifications/notifications.container';
import { closePendoIfVisible } from '../shared/hooks/usePendo.hook';

import UserMenu from './userMenu.component';
import HelpIcon from './helpIcon/helpIcon.component';

const ActionBarComponent = () => {
  const customerData = useSelector(customerDataSelector);
  const client = useSelector(clientSelector);
  const [isUserMenuVisible, showUserMenu, hideUserMenu] = useBooleanState(false);

  const onUserMenuClick = useCallback(() => {
    closePendoIfVisible();
    showUserMenu();
  }, [showUserMenu]);

  const customerInitials = `${(customerData.firstName || '').charAt(0)}${(
    customerData.lastName || ''
  ).charAt(0)}`;

  const clientAvatarIconUrl = client && client.icon && `data:image/png;base64, ${client.icon}`;

  return (
    <div className="action-bar">
      <div className="a-actions-container actions-container">
        <HelpIcon />
        <Notifications onClick={closePendoIfVisible} />
        <Avatar
          className="user-menu-popover-avatar"
          label={customerInitials}
          onMouseDown={onUserMenuClick}
        />
        {isUserMenuVisible && (
          <UserMenu
            customerData={customerData}
            onHide={hideUserMenu}
            selectedClient={client && client.id}
          />
        )}
        <Avatar
          className="user-menu-client-avatar"
          label={client && client.shortName.substring(0, 3)}
          src={clientAvatarIconUrl}
          noBorder
          shape="square"
        />
      </div>
    </div>
  );
};

export default ActionBarComponent;
