import React, { useCallback, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react';

import Input from '../../shared/forms/inputFormik/inputFormik.component';
import ButtonsGroup from '../../shared/forms/buttonsGroup/buttonsGroup.component';
import { useQueryWatermarkPreview } from '../../shared/queries/watermarks';

import styles from './watermarks.module.scss';
import PortalWindowPopUp from './portalWindowPopUp.component';

const AddEditWatermarkForm = ({ isEditMode }) => {
  const [pdfPortalInformation, setPdfPortalInformation] = useState({
    pdfPreviewData: '',
    isWindowPortalOpen: false,
  });

  const { isSubmitting, resetForm, submitForm, dirty, values } = useFormikContext();

  const {
    refetch: refetchWatermarkPreview,
    isFetching: isWatermarkPreviewFetching,
  } = useQueryWatermarkPreview({
    params: values,
    enabled: false,
    onSuccess: ({ pdfPreviewData }) => {
      setPdfPortalInformation({
        isWindowPortalOpen: true,
        pdfPreviewData,
      });
    },
  });

  const previewWatermark = useCallback(async () => {
    await refetchWatermarkPreview();
  }, [refetchWatermarkPreview]);

  const handlePreviewWatermarkClose = useCallback(() => {
    setPdfPortalInformation({ ...pdfPortalInformation, isWindowPortalOpen: false });
  }, [pdfPortalInformation]);

  return (
    <>
      <form className={styles.editWatermarkForm}>
        <div className={styles.editWatermarkFormFields}>
          <Field
            className="form-text"
            label="Watermark Name"
            name="name"
            component={Input}
            autoComplete="off"
            disabled={isEditMode}
          />
          <div className="row">
            <Field
              className="form-text col-4"
              label="Rotation"
              name="rotation"
              component={Input}
              autoComplete="off"
              type="number"
            />
            <Field
              className="form-text col-4"
              label="Font Size"
              name="fontSize"
              component={Input}
              autoComplete="off"
              type="number"
            />
            <Field
              className="form-text col-4"
              label="Vertical Offset"
              name="verticalOffset"
              component={Input}
              autoComplete="off"
              type="number"
            />
          </div>
          <Field
            className="form-text"
            label="Text Line 1"
            name="line1"
            component={Input}
            autoComplete="off"
          />
          <Field
            className="form-text"
            label="Text Line 2"
            name="line2"
            component={Input}
            autoComplete="off"
          />
          <Field
            className="form-text"
            label="Text Line 3"
            name="line3"
            component={Input}
            autoComplete="off"
          />
          <Button
            className={styles.previewButton}
            disabled={!isEditMode || isWatermarkPreviewFetching}
            isLoading={isWatermarkPreviewFetching}
            onClick={previewWatermark}
            size="lg"
          >
            Preview Watermark
          </Button>
        </div>
        <ButtonsGroup
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onCancelClick={resetForm}
          submitButtonLabel="Save"
          disabled={!dirty}
        />
      </form>
      <PortalWindowPopUp
        isWindowPortalOpen={pdfPortalInformation.isWindowPortalOpen}
        handlePreviewWatermarkClose={handlePreviewWatermarkClose}
        title={values.name}
        pdfPreviewData={pdfPortalInformation.pdfPreviewData}
      />
    </>
  );
};

AddEditWatermarkForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
};

export default AddEditWatermarkForm;
