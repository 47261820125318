import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@pwc/appkit-react/lib/Button';

const DatasetInstanceButton = ({
  systemDefined,
  datasetPending,
  message,
  disabled,
  title,
  ...props
}) => {
  const disableButton = datasetPending || systemDefined || disabled;

  const buttonTitle = systemDefined ? message : title;

  return <Button title={buttonTitle} disabled={disableButton} {...props} />;
};

DatasetInstanceButton.propTypes = {
  systemDefined: PropTypes.bool,
  datasetPending: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

DatasetInstanceButton.defaultProps = {
  datasetPending: false,
  disabled: false,
  title: '',
};

export const AddDatasetInstanceButton = props => (
  <DatasetInstanceButton
    message="This is a system defined Dataset, instances cannot be added"
    {...props}
  />
);

export const DeleteDatasetInstanceButton = props => (
  <DatasetInstanceButton
    message="This is a system defined Dataset, instances cannot be deleted"
    {...props}
  />
);
