import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import promiseWaterfall from 'promise.waterfall';
import chunk from 'lodash.chunk';
import { connect } from 'react-redux';
import { RequestError } from '@tls/ui-request';
import { Button } from '@pwc/appkit-react/lib/Button';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import {
  entitiesSelector,
  taxYearSelector,
  periodSelector,
  jurisdictionIdSelector,
  isFetchingEntitiesSelector,
} from '../../shared/store/selectors';
import { useRowEditMode } from '../../shared/editMode';
import { successNotification } from '../../shared/notification/store/actions';
import { refreshFederalProFormaDataThrowErrors } from '../../shared/store/federalRefresh/actions';
import { showConfirmModal } from '../../shared/confirmModal/store/actions';
import { useQueryMaintenanceEntities } from '../../shared/queries/entitiesMaintenance';
import config from '../../config';

import { columnDefinitions } from './federalDataRefresh.columnDefinitions';
import styles from './federalDataRefresh.module.scss';

const FederalDataRefresh = ({
  period,
  taxYear,
  jurisdictionId,
  showConfirmModal,
  refreshFederalProFormaDataThrowErrors,
  successNotification,
}) => {
  const isContextReady = taxYear && period;

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);

  const { data: entities, isFetching: isFetchingEntities } = useQueryMaintenanceEntities({
    params: { taxYear, period, showFederalEntitiesOnly: true },
    enabled: Boolean(taxYear && period),
  });

  const getUniqueRowId = ({ data: { orgId } }) => orgId;

  const { clonedRowData, onGridReady, updateRow, gridApi } = useRowEditMode({
    rowData: entities,
    getUniqueRowId,
  });

  const getSelectedEntities = useCallback(() => gridApi.getSelectedRows(), [gridApi]);

  const handleUpdatingRefreshStatus = useCallback(
    selectedOrgs => {
      selectedOrgs.forEach(org => {
        org.gtwRefreshStatus = 1; // in progress
        updateRow(org);
        return org;
      });
    },
    [updateRow],
  );

  const checkIfRowSelected = useCallback(() => {
    const isSelected = Boolean(gridApi?.getSelectedRows()?.length);

    setIsRowSelected(isSelected);
  }, [gridApi]);

  const refresh = useCallback(
    async ({ taxYear, selectedOrgs, period, jurisdictionId }) => {
      const splitOrgs = chunk(selectedOrgs, config.FEDERAL_REFRESH_CHUNK_SIZE);

      setIsRefreshing(true);
      try {
        await promiseWaterfall(
          splitOrgs.map(orgs => () =>
            refreshFederalProFormaDataThrowErrors({
              taxYear,
              orgs,
              period,
              jurisdictionId,
            }),
          ),
        );
        successNotification('Scheduling refreshing federal pro forma data succeeded');
        // Deselect the user choices and the updated data
        handleUpdatingRefreshStatus(selectedOrgs);
        gridApi.deselectAll();
        gridApi.refreshCells({ force: true });
      } catch (error) {
        if (!(error instanceof RequestError)) {
          // Rethrowing not request related errors. Request error has error message that is handled
          // by errorNotification by default. It may be confusing why do we even use try catch block
          // here but we want to show a success notification only when ALL requests succeeded.
          throw error;
        }
      }
      setIsRowSelected(false);
      setIsRefreshing(false);
    },
    [
      refreshFederalProFormaDataThrowErrors,
      successNotification,
      gridApi,
      handleUpdatingRefreshStatus,
    ],
  );

  const onRefreshClick = useCallback(() => {
    showConfirmModal({
      title: 'Perform calculation',
      text: 'Perform federal proforma data refresh?',
      confirmCallback: () =>
        refresh({
          taxYear,
          selectedOrgs: getSelectedEntities(),
          period,
          jurisdictionId,
        }),
    });
  }, [getSelectedEntities, refresh, showConfirmModal, jurisdictionId, period, taxYear]);

  return (
    <>
      <div className="row">
        <Button
          size="lg"
          onClick={onRefreshClick}
          disabled={!isContextReady || isFetchingEntities || !entities.length || !isRowSelected}
          className={styles.refreshDataBtn}
        >
          Refresh Data
        </Button>
      </div>
      <div className="row grid-row">
        <div className="col-12">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            isGridLoading={isFetchingEntities || isRefreshing}
            rowSelection="multiple"
            onGridReady={onGridReady}
            onSelectionChanged={checkIfRowSelected}
            withSearchBar
          />
        </div>
      </div>
    </>
  );
};

FederalDataRefresh.propTypes = {
  period: PropTypes.string,
  taxYear: PropTypes.string,
  jurisdictionId: PropTypes.string,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      entityName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingEntities: PropTypes.bool.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  refreshFederalProFormaDataThrowErrors: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    period: periodSelector(state),
    taxYear: taxYearSelector(state),
    jurisdictionId: jurisdictionIdSelector(state),
    entities: entitiesSelector(state),
    isFetchingEntities: isFetchingEntitiesSelector(state),
  }),
  {
    refreshFederalProFormaDataThrowErrors,
    showConfirmModal,
    successNotification,
  },
)(FederalDataRefresh);
