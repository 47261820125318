import React from 'react';
import { Routes } from '@common-packages/routes-definitions';
import { ColDef, ICellRendererParams } from 'ag-grid-community';

import { LinkCellRendererFactory } from '../../../../shared/columnDefinitions/cellRenderers';
import getContextAwareUrlParams from '../../../../utils/getContextAwareUrlParams';
import {
  BusinessEntityId,
  JurisdictionId,
  Period,
  TaxYear,
  ValidationErrorsMessage,
} from '../../../../../../common/types';
import ButtonCellRendererFactory from '../../../../shared/columnDefinitions/cellRenderers/buttonCellRenderer.factory';

interface ResolveLinkCellRendererProps extends ICellRendererParams {
  taxYear: TaxYear;
  period: Period;
  jurisdictionId: JurisdictionId;
  businessEntityId: BusinessEntityId;
  filingTypeId: string;
  handleButtonClick: (errorDetails: ValidationErrorsMessage) => void;
  colDef: ColDef;
}

const ResolveLinkCellRenderer = (props: ResolveLinkCellRendererProps) => {
  const {
    businessEntityId,
    taxYear,
    period,
    jurisdictionId,
    data: { formId = '' },
    filingTypeId,
    handleButtonClick,
  } = props;

  if (formId) {
    const ResolveLink = LinkCellRendererFactory({
      getText: ({ value }) => value,
      getPathName: () =>
        Routes.viewPrintTaxReturn.compiledRoute({
          ...getContextAwareUrlParams({
            taxYear,
            period,
            filingTypeId,
            businessEntityId,
            jurisdictionId,
          }),
        }),
      getRouterState: () => ({
        formId,
      }),
      getQueryParams: (_, data) => (data.fieldName ? `scrollElementId=${data.fieldName}` : ''),
    });

    return <ResolveLink {...props} />;
  }
  const ButtonCellRenderer = ButtonCellRendererFactory(handleButtonClick);
  return <ButtonCellRenderer {...props} />;
};

export default ResolveLinkCellRenderer;
