import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';
import { BreadcrumbRoute } from '../shared/displayComponents/breadcrumbRoute/breadcrumbRoute.component';

import ViewPrintTaxReturn from './viewPrintTaxReturn.container';

const ViewPrintTaxReturnBreadcrumbRoute = () => (
  <BreadcrumbRoute path={Routes.viewPrintTaxReturn} />
);

const ViewPrintTaxReturnPage = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
  breadcrumbRoutes: ViewPrintTaxReturnBreadcrumbRoute,
}))(GlobalContextPage);

const ViewPrintTaxReturnRoute = () => (
  <AuthorizedCustomRoute
    exact
    path={Routes.viewPrintTaxReturn.MAIN}
    wrapper={ViewPrintTaxReturnPage}
    component={ViewPrintTaxReturn}
    hideGlobalContext
  />
);

export default ViewPrintTaxReturnRoute;
