import React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Routes } from '@common-packages/routes-definitions';

import withContextWrapper from '../shared/displayComponents/contextWrapper/withContextWrapperHoc.container';
import AuthorizedCustomRoute from '../shared/authorization/authorizedCustomRoute';
import GlobalContextPage from '../shared/displayComponents/globalContextPage.component';
import { isFetchingGlobalContextSelector } from '../shared/store/selectors';

import FilingDecisions from './filingDecisions.container';
import FilingDecisionAmountDetails from './entity/filingDecisionsAmountDetails/filingDecisionsAmountDetails.container';

const FilingDecisionsWithContext = withContextWrapper({ isJurisdictionRequired: false })(
  FilingDecisions,
);

const FilingDecisionAmountDetailsWithContext = withContextWrapper({
  isJurisdictionRequired: false,
})(FilingDecisionAmountDetails);

const Wrapper = connect(state => ({
  isFetchingContext: isFetchingGlobalContextSelector(state),
}))(GlobalContextPage);

const FilingDecisionsRoute = () => (
  <Switch>
    <AuthorizedCustomRoute
      exact
      path={Routes.separateFilingDecisionsV2.MAIN}
      wrapper={Wrapper}
      component={FilingDecisionsWithContext}
      hideGlobalContext
      disableBreadcrumbs
    />
    <AuthorizedCustomRoute
      exact
      path={Routes.consolidatedFilingDecisionsV2.MAIN}
      wrapper={Wrapper}
      component={FilingDecisionsWithContext}
      hideGlobalContext
      disableBreadcrumbs
    />
    <AuthorizedCustomRoute
      exact
      path={Routes.filingDecisionAmountDetailsV2.MAIN}
      wrapper={Wrapper}
      component={FilingDecisionAmountDetailsWithContext}
      hideGlobalContext
      disableBreadcrumbs
    />
  </Switch>
);

export default FilingDecisionsRoute;
