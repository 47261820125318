import React, { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Routes } from '@common-packages/routes-definitions';

import AppkitIcon from '../shared/displayComponents/appkitIcon/appkitIcon.component';
import Card from '../shared/displayComponents/card/card.component';
import {
  entityIdSelector,
  periodSelector,
  taxYearSelector,
  globalContextSelector,
} from '../shared/store/selectors';
import AuthorizedCard from '../shared/displayComponents/card/authorizedCard.container';

import putDynamicValuesToLink from './putDynamicValuesToLink';
import {
  checkIfNotesExistSelector,
  checkIfPartnershipInformationExistSelector,
  getFederalDetailsLinkSelector,
  isEntityPartnershipTypeSelector,
  checkIfLegalEntityExistSelector,
} from './store/selectors';
import {
  checkIfNotesExist,
  checkIfPartnershipInformationExist,
  getFederalDetailsLink,
  checkIfLegalEntityExist,
} from './store/actions';
import getEntityInfomationData from './entityInformation.data';

const EntityInformation = props => {
  const {
    taxYear,
    period,
    checkIfNotesExist,
    entityId,
    checkIfLegalEntityExist,
    checkIfPartnershipInformationExist,
    ifNotesExist,
    ifLegalEntityExist,
    ifPartnershipInformationExist,
    getFederalDetailsLink,
    federalDetailsLinkWithPlaceholders,
    isPartnershipType,
  } = props;

  const globalContext = useSelector(globalContextSelector);

  useEffect(() => {
    getFederalDetailsLink();
  }, [getFederalDetailsLink]);

  useEffect(() => {
    const isContextReady = taxYear && period && entityId;

    if (!isContextReady) {
      return;
    }

    checkIfNotesExist({
      taxYear,
      period,
      entityId,
    });

    checkIfLegalEntityExist({
      taxYear,
      period,
      entityId,
    });

    checkIfPartnershipInformationExist({
      taxYear,
      period,
      entityId,
    });
  }, [
    checkIfNotesExist,
    checkIfLegalEntityExist,
    checkIfPartnershipInformationExist,
    taxYear,
    period,
    entityId,
  ]);
  const data = {
    REP_ID: '4004',
    ORG_ID: entityId,
    TAX_YEAR: taxYear,
  };
  const federalLinkWithValues = federalDetailsLinkWithPlaceholders
    ? putDynamicValuesToLink(federalDetailsLinkWithPlaceholders, data)
    : '';

  const notesTitle = useMemo(
    () =>
      ifNotesExist ? (
        <p className="title-with-icon">
          Preparer/Reviewer Notes <AppkitIcon icon="play" size={16} />
        </p>
      ) : (
        Routes.prepareReviewNotes.displayMainName()
      ),
    [ifNotesExist],
  );
  const partnershipInformationTitle = useMemo(
    () =>
      ifPartnershipInformationExist ? (
        <p className="title-with-icon">
          Review Partnership Information <AppkitIcon icon="play" size={16} />
        </p>
      ) : (
        Routes.reviewPartnershipInformation.displayMainName()
      ),
    [ifPartnershipInformationExist],
  );

  const entityInformationData = useMemo(
    () =>
      getEntityInfomationData({
        isPartnershipType,
        notesTitle,
        ifLegalEntityExist,
        partnershipInformationTitle,
        globalContextParams: globalContext.params,
      }),
    [isPartnershipType, notesTitle, ifLegalEntityExist, partnershipInformationTitle, globalContext],
  );

  return (
    <div className="entity-information row row-with-cards">
      {entityInformationData.map((card, index) => (
        <AuthorizedCard
          key={index}
          title={card.title}
          icon={card.icon}
          description={card.description}
          route={card.route}
        />
      ))}
      <Card
        title="Supporting Federal Details"
        icon="table-data"
        description="Federal details"
        redirect={federalLinkWithValues}
      />
    </div>
  );
};

EntityInformation.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  entityId: PropTypes.string,
  ifNotesExist: PropTypes.bool.isRequired,
  checkIfNotesExist: PropTypes.func.isRequired,
  checkIfLegalEntityExist: PropTypes.func.isRequired,
  ifLegalEntityExist: PropTypes.bool.isRequired,
  checkIfPartnershipInformationExist: PropTypes.func.isRequired,
  ifPartnershipInformationExist: PropTypes.bool.isRequired,
  getFederalDetailsLink: PropTypes.func.isRequired,
  federalDetailsLinkWithPlaceholders: PropTypes.string.isRequired,
  isPartnershipType: PropTypes.bool,
};

export default connect(
  state => ({
    taxYear: taxYearSelector(state),
    period: periodSelector(state),
    entityId: entityIdSelector(state),
    ifNotesExist: checkIfNotesExistSelector(state),
    ifLegalEntityExist: checkIfLegalEntityExistSelector(state),
    ifPartnershipInformationExist: checkIfPartnershipInformationExistSelector(state),
    federalDetailsLinkWithPlaceholders: getFederalDetailsLinkSelector(state),
    isPartnershipType: isEntityPartnershipTypeSelector(state),
  }),
  {
    checkIfNotesExist,
    checkIfLegalEntityExist,
    checkIfPartnershipInformationExist,
    getFederalDetailsLink,
  },
)(EntityInformation);
