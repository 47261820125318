const getHasAllChildrenChecked = (
  { node, data },
  permission,
  childrenKey = 'childrenAfterFilter',
) => {
  if (data[permission] === false) {
    return false;
  }
  return (node?.[childrenKey] || []).every(child =>
    getHasAllChildrenChecked(child, permission, childrenKey),
  );
};

export default getHasAllChildrenChecked;
