import { defaultColumnDefinition } from '../../../shared/columnDefinitions';

const columnDefinitions = [
  {
    ...defaultColumnDefinition,
    headerName: 'Type',
    field: 'Type',
    width: 180,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Field Name',
    field: 'FieldNameIdentification',
    width: 650,
  },
  {
    ...defaultColumnDefinition,
    headerName: 'Warning',
    field: 'Message',
    width: 4200,
  },
];

export default columnDefinitions;
