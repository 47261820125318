import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '@common-packages/routes-definitions';
import { TAX_YEAR_2020 } from '@common-packages/shared-constants';
import { SelectOption } from '@tls/slt-types';

import useUrlParams from '../../../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';
import { ContextTypeAndValue } from '../../../../../common/types';
import { ContextType } from '../../../shared/enums';
import ParamDropdown from '../paramDropdown/paramDropdown.component';
import { NotApplicableContextValue } from '../../constants';

const noop = () => null;

interface TaxYearDropdownProps {
  taxYears: SelectOption[];
  taxYear?: string | null;
  selectTaxYear: (newTaxYear: string) => unknown;
  isDisabled?: boolean;
  isDropdownBusy?: boolean;
  isFetchingContext?: boolean;
  isContextChangesBlocked?: boolean;
  showWarningModal?: (params: ContextTypeAndValue) => unknown;
  customOnChangeAction?: (
    handleSelect: (newValue: string) => unknown,
  ) => (newValue: unknown) => unknown;
}

const TaxYearDropdown = ({
  taxYears,
  taxYear = null,
  selectTaxYear,
  isDisabled = false,
  isDropdownBusy = false,
  isFetchingContext = false,
  isContextChangesBlocked = false,
  showWarningModal = noop,
  customOnChangeAction,
}: TaxYearDropdownProps) => {
  const { routeParams, setParams } = useUrlParams();
  const location = useLocation();
  const history = useHistory();

  const handleTaxYearSelect = useCallback(
    (newTaxYear: string | null) => {
      if (taxYear !== newTaxYear) {
        if (isContextChangesBlocked) {
          showWarningModal({ type: ContextType.TAX_YEAR_TYPE, value: newTaxYear });
          return;
        }
        if (newTaxYear !== NotApplicableContextValue) {
          selectTaxYear(newTaxYear || '');
        }
        setParams({ routeParams: { taxYear: newTaxYear } });

        if (location.pathname.includes('state-specific-information')) {
          const targetUrl =
            Number(newTaxYear) < TAX_YEAR_2020
              ? Routes.stateSpecificInformation.compiledRoute({
                  ...routeParams,
                  taxYear: newTaxYear,
                })
              : Routes.stateSpecificInformationV2.compiledRoute({
                  ...routeParams,
                  taxYear: newTaxYear,
                });

          history.push(targetUrl);
        }
      }
    },
    [
      setParams,
      showWarningModal,
      selectTaxYear,
      taxYear,
      isContextChangesBlocked,
      history,
      location.pathname,
      routeParams,
    ],
  );

  useEffect(() => {
    if (!(taxYears.length && taxYears.find(({ value }) => value === routeParams.taxYear))) {
      return;
    }
    if (routeParams.taxYear) {
      handleTaxYearSelect(routeParams.taxYear);
    }
  }, [handleTaxYearSelect, taxYears, routeParams.taxYear, isDropdownBusy]);

  return (
    <ParamDropdown
      label="Tax Year"
      value={taxYear}
      options={taxYears}
      handleChange={
        customOnChangeAction ? customOnChangeAction(handleTaxYearSelect) : handleTaxYearSelect
      }
      isBusy={isDropdownBusy}
      isFetchingContext={isFetchingContext}
      disabled={isDisabled}
    />
  );
};

export default TaxYearDropdown;
