import React from 'react';

import EditReport from '../../shared/editReport.component';
import { REPORT_TYPES, uiLabels } from '../../shared/constants';

import getColumnDefinitions from './dataVisualization.columnDefinitions';

const EditDataVisualization = () => (
  <EditReport
    reportType={REPORT_TYPES.CHART}
    getColumnDefinitions={getColumnDefinitions}
    uiLabels={uiLabels.chart}
  />
);

export default EditDataVisualization;
