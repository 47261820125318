import React from 'react';
import PropTypes from 'prop-types';

import AgGrid from '../../../../shared/displayComponents/agGrid/agGrid.component';

import columnDefinitions from './compileExpressionsReportGrid.columnDefinitions';
import { compilationExpressionReportRowPropType } from './compileExpressionsReportGrid.propTypes';

const CompileExpressionsGrid = ({ data = null, isLoading }) => (
  <AgGrid
    rowData={data || []}
    columnDefs={columnDefinitions}
    isGridLoading={isLoading}
    areHeaderCellBordersEnabled
  />
);

CompileExpressionsGrid.propTypes = {
  data: PropTypes.arrayOf(compilationExpressionReportRowPropType),
  isLoading: PropTypes.bool.isRequired,
};

export default CompileExpressionsGrid;
