import { createSelector } from 'reselect';

export const formListOptionsSelector = createSelector(
  [({ shared }) => shared.stateSpecificInformation.formList],
  formList => formList.map(item => ({ ...item, value: item.formId, label: item.formName })),
);
export const isFetchingFormListSelector = ({ shared }) =>
  shared.stateSpecificInformation.isFetchingFormList;

export const formFieldsSelector = createSelector(
  [({ shared }) => shared.stateSpecificInformation.formFields],
  formFields => formFields.map((field, rowId) => ({ ...field, rowId })),
);

export const isFetchingFormFieldsSelector = ({ shared }) =>
  shared.stateSpecificInformation.isFetchingFormFields;

export const isUpdatingFormFieldsSelector = ({ shared }) =>
  shared.stateSpecificInformation.isUpdatingFormFields;
