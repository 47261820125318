import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Tabs, Button } from '@pwc/appkit-react';
import { Formik } from 'formik';
import { taxFormsSchemas } from '@common-packages/validators';

import useGridApi from '../../../shared/hooks/useGridApi.hook';
import { showConfirmModal } from '../../../shared/confirmModal/store/actions';
import oracleSysGuidUuid from '../../../utils/oracleSysGuidUuid';
import {
  selectDataModel,
  assignPDFDataUsage,
  unassignPDFDataUsage,
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
  removePDFFieldMap,
  mapPDFField,
  fetchPDFFieldsByPage,
} from '../../../shared/store/dataModels/actions';
import {
  mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector,
  dataModelsSelector,
  dataModelSelector,
  isFetchingDataModelsSelector,
  datasetSelector,
  isFetchingPDFFieldsByPageSelector,
  pdfFieldsByPageSelector,
  datasetsByPageSelector,
  selectedMappingFormPDFPageDatasetSelector,
  isUpdatingPDFDatasetAssignmentSelector,
  isFetchingDatasetsSelector,
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector,
} from '../../../shared/store/dataModels/selectors';
import { SelectOptionPropTypes } from '../../../shared/forms/propTypes';
import AgGrid from '../../../shared/displayComponents/agGrid/agGrid.component';

import DatasetSelector from './datasetSelector.component';
import FieldMappingAttributesForm from './fieldMappingAttributesForm.container';
import PDFPagesFieldMappingTree from './pdfPagesFieldMappingTree.container';
import getColumnDefinitions from './columnDefinitions';
import OverflowActions from './overflowActions/overflowActions.container';
import styles from './styles.module.scss';

export const TAB_TYPES = {
  DATASET: 'Dataset',
  OVERFLOW_ACTIONS: 'Overflow Actions',
  FIELDS: 'Fields',
};

export const tabsDefinitions = [
  { type: TAB_TYPES.DATASET, label: 'Dataset' },
  { type: TAB_TYPES.OVERFLOW_ACTIONS, label: 'Overflow Actions' },
  { type: TAB_TYPES.FIELDS, label: 'Fields' },
];

const getRowId = params => params?.data?.id;

const selectDatasetIdToUnassign = ({
  dataset,
  datasetsByPage,
  selectedMappingFormPDFPageDataset,
}) => {
  if (!dataset || !selectedMappingFormPDFPageDataset) {
    return null;
  }

  if (datasetsByPage.length) {
    const datasetFromDatasetsTree =
      datasetsByPage.find(({ datasetId }) => datasetId === dataset.id) || null;

    return datasetFromDatasetsTree ? datasetFromDatasetsTree.id : null;
  }

  return selectedMappingFormPDFPageDataset.datasetId === dataset.id
    ? selectedMappingFormPDFPageDataset.id
    : null;
};

const PDFPageMapping = ({
  formPDFId,

  selectedMappingFormPDFPageDataset,
  mapPDFField,
  datasetsByPage,
  pdfFieldsByPage,
  selectDataModel,
  showConfirmModal,
  removePDFFieldMap,
  isFetchingDataModels,
  dataModels,
  dataModel,
  dataset,
  mappingFormPDFPageDataUsageAndAttachmentConfigId,
  isFetchingPDFFieldsByPage,
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
  assignPDFDataUsage,
  unassignPDFDataUsage,
  fetchPDFFieldsByPage,
  isUpdatingPDFDatasetAssignment,
  isFetchingDatasets,
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs,
}) => {
  const selectedDataset =
    selectedMappingFormPDFPageDataset && selectedMappingFormPDFPageDataset.datasetId;
  const selectedPage =
    selectedMappingFormPDFPageDataset && selectedMappingFormPDFPageDataset.pageNumber;

  const selectedDatasetIdToUnassign = selectDatasetIdToUnassign({
    dataset,
    datasetsByPage,
    selectedMappingFormPDFPageDataset,
  });

  const shouldDisableDatasetButtons =
    isFetchingDatasets ||
    isUpdatingPDFDatasetAssignment ||
    isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs;

  const [activeTab, setActiveTab] = useState(tabsDefinitions[0].type);
  const [selectedFieldMapping, setSelectedFieldMapping] = useState(null);

  const { gridApi, onGridReady } = useGridApi();

  const isTabDisabled = useCallback(
    tab =>
      (selectedDataset && [TAB_TYPES.DATASET, TAB_TYPES.FIELDS].includes(tab)) ||
      (!selectedDataset && tab === TAB_TYPES.OVERFLOW_ACTIONS),
    [selectedDataset],
  );

  const handleTabChange = useCallback((_, tab) => isTabDisabled(tab) || setActiveTab(tab), [
    setActiveTab,
    isTabDisabled,
  ]);

  const goToDatasetTab = useCallback(() => setActiveTab(TAB_TYPES.DATASET), [setActiveTab]);
  const goToOverflowTab = useCallback(() => setActiveTab(TAB_TYPES.OVERFLOW_ACTIONS), [
    setActiveTab,
  ]);

  const onDataModelChange = useCallback(
    selectedDataModel => {
      if (!selectedDataModel || dataModel === selectedDataModel) {
        return;
      }
      selectDataModel(selectedDataModel.value);
    },
    [dataModel, selectDataModel],
  );

  const assign = useCallback(async () => {
    const id = oracleSysGuidUuid();
    const data = {
      id,
      pdfId: formPDFId,
      datasetDefinitionId: dataset.id,
      pageNumber: mappingFormPDFPageDataUsageAndAttachmentConfigId,
    };
    await assignPDFDataUsage(data);
    await fetchMappingFormPDFPageDataUsageAndAttachmentConfigs({ pdfId: formPDFId });
  }, [
    assignPDFDataUsage,
    dataset,
    mappingFormPDFPageDataUsageAndAttachmentConfigId,
    fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
    formPDFId,
  ]);

  const unassign = useCallback(async () => {
    await unassignPDFDataUsage({
      mappingId: selectedDatasetIdToUnassign,
    });
    await fetchMappingFormPDFPageDataUsageAndAttachmentConfigs({ pdfId: formPDFId });
    selectDataModel(null);
  }, [
    selectDataModel,
    unassignPDFDataUsage,
    fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
    formPDFId,
    selectedDatasetIdToUnassign,
  ]);

  const openModalForDelete = useCallback(
    row => {
      showConfirmModal({
        title: 'Delete field mapping?',
        text:
          'Are you sure you want to remove this mapping and all existing data related to this mapping?',
        confirmCallback: async () => {
          await removePDFFieldMap({
            pdfFieldMappingId: row.pdfFieldMappingId,
          });

          fetchPDFFieldsByPage({
            pdfId: formPDFId,
            pageNumber: selectedPage,
          });
        },
      });
    },
    [showConfirmModal, removePDFFieldMap, fetchPDFFieldsByPage, formPDFId, selectedPage],
  );

  const pdfFieldsByPageColumnDefinitions = useMemo(
    () => getColumnDefinitions({ onDeleteIconClick: openModalForDelete }),
    [openModalForDelete],
  );

  const handleFieldMappingRowSelection = useCallback(
    ({ api }) => {
      if (api.getSelectedRows()[0]) {
        const selectedRow = api.getSelectedRows()[0];
        setSelectedFieldMapping({
          ...selectedRow,
          datasetId: selectedRow.datasetId ? selectedRow.datasetId : '',
          dataItemId: selectedRow.dataItemId ? selectedRow.dataItemId : '',
        });
      }
    },
    [setSelectedFieldMapping],
  );

  const renderFieldMappingAttributesForm = useCallback(formikProps => {
    // TODO move inside form component
    const onCancelClick = () => {
      formikProps.resetForm();
    };

    return <FieldMappingAttributesForm onCancelClick={onCancelClick} {...formikProps} />;
  }, []);

  useEffect(() => {
    if (gridApi && selectedFieldMapping) {
      const nodeToSelect = gridApi.getRowNode(selectedFieldMapping.id);
      if (nodeToSelect) {
        nodeToSelect.setSelected(true);
      }
    }
    // it's not clear to me why pdfFieldsByPage needs to be here even though it's not used by this effect
  }, [gridApi, pdfFieldsByPage, selectedFieldMapping]);

  const submitFieldMappingAttributesForm = useCallback(
    async (values, { setSubmitting }) => {
      const datasetUsage = datasetsByPage.find(
        datasetByPage => datasetByPage.datasetId === values.datasetId,
      );

      setSubmitting(true);

      await mapPDFField({
        datasetUsageId: datasetUsage.id,
        pdfFieldId: values.id,
        dataItemDefId: values.dataItemId,
        pdfFieldMappingId: values.pdfFieldMappingId,
        upperCase: Number(values.upperCase),
        valueIfZero: Number(values.valueIfZero),
        absoluteValue: Number(values.absoluteValue),
        suppressCommas: Number(values.suppressCommas),
        parensIfNegative: Number(values.parensIfNegative),
        ratioAsPercentage: Number(values.ratioAsPercentage),
        decimalPlaces: Number(values.decimalPlaces),
        appendText: values.appendText,
        wrapText: Number(values.wrapText),
        printFirstCopyOnly: Number(values.printFirstCopyOnly),
        printLastCopyOnly: Number(values.printLastCopyOnly),
        wrapFirstRowIndt: values.wrapFirstRowIndt,
        wrapFullRowcount: values.wrapFullRowcount,
        wrapRowspacingIncrement: values.wrapRowspacingIncrement,
        characterSpacing: values.characterSpacing,
        fullfieldPattern: values.fullfieldPattern,
        fullfieldLength: Number(values.fullfieldLength),
        formattingString: values.formattingString,
        checkBox: values.checkBox === '1' ? 1 : 0,
        ynCheckBox: values.checkBox === 'Y' || values.checkBox === 'N' ? values.checkBox : null,
        flipSign: Number(values.flipSign),
      });

      fetchPDFFieldsByPage({
        pdfId: formPDFId,
        pageNumber: selectedPage,
      });
      setSubmitting(false);
    },
    [mapPDFField, datasetsByPage, fetchPDFFieldsByPage, selectedPage, formPDFId],
  );

  useEffect(() => {
    if (selectedPage && formPDFId) {
      fetchPDFFieldsByPage({
        pdfId: formPDFId,
        pageNumber: selectedPage,
      });
    }
  }, [fetchPDFFieldsByPage, selectedPage, formPDFId]);

  useEffect(() => {
    if (!selectedDataset) {
      goToDatasetTab();
    }
  }, [goToDatasetTab, selectedDataset]);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case TAB_TYPES.DATASET:
        return (
          <div className={styles.datasetContainer}>
            <DatasetSelector
              disabled={Boolean(selectedDataset)}
              isFetchingDataModels={isFetchingDataModels}
              dataModels={dataModels}
              dataModel={dataModel}
              onDataModelChange={onDataModelChange}
            />

            <Button
              size="lg"
              disabled={shouldDisableDatasetButtons || Boolean(selectedDatasetIdToUnassign)}
              className="add-button"
              onClick={assign}
            >
              Assign
            </Button>

            <Button
              size="lg"
              disabled={shouldDisableDatasetButtons || !selectedDatasetIdToUnassign}
              className={`add-button ${styles.unassignButton}`}
              onClick={unassign}
            >
              Unassign
            </Button>
          </div>
        );
      case TAB_TYPES.OVERFLOW_ACTIONS:
        return <OverflowActions goToDatasetTab={goToDatasetTab} />;
      case TAB_TYPES.FIELDS:
        return (
          <div className={`row grid-row ${styles.fieldsContainer}`}>
            <div className="col">
              <AgGrid
                rowData={pdfFieldsByPage}
                columnDefs={pdfFieldsByPageColumnDefinitions}
                isGridLoading={isFetchingPDFFieldsByPage}
                onSelectionChanged={handleFieldMappingRowSelection}
                onGridReady={onGridReady}
                getRowId={getRowId}
                areHeaderCellBordersEnabled
              />
            </div>
            {selectedFieldMapping ? (
              <div className={`col ${styles.fieldsForm}`}>
                <Formik
                  onSubmit={submitFieldMappingAttributesForm}
                  initialValues={selectedFieldMapping}
                  enableReinitialize
                  validationSchema={taxFormsSchemas.addEditPDFFieldMapping}
                  validateOnBlur={false}
                >
                  {renderFieldMappingAttributesForm}
                </Formik>
              </div>
            ) : null}
          </div>
        );
      default:
        throw new Error('Unsupported tab type');
    }
  };

  return (
    <div className={`row ${styles.pagesWrapper}`}>
      <div className={`col ${styles.pdfPagesFieldMappingTreeContainer}`}>
        <PDFPagesFieldMappingTree
          formPDFId={formPDFId}
          onPageSelected={goToDatasetTab}
          onDatasetSelected={goToOverflowTab}
        />
      </div>
      <div className={`col ${styles.pagesContainer}`}>
        <div className="navigation-tabs-wrapper">
          <div className="tabs-wrapper">
            <Tabs className="tabs-container" value={activeTab} onChange={handleTabChange} size="md">
              {tabsDefinitions.map(({ label, type }, index) => (
                <Tab
                  disabled={isTabDisabled(type)}
                  id={type}
                  value={type}
                  label={label}
                  key={index}
                />
              ))}
            </Tabs>
          </div>
        </div>
        {getActiveTabComponent()}
      </div>
    </div>
  );
};

PDFPageMapping.propTypes = {
  formPDFId: PropTypes.string.isRequired,
  mappingFormPDFPageDataUsageAndAttachmentConfigId: PropTypes.string,
  dataset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    parentId: PropTypes.string,
    contextTypeId: PropTypes.number,
  }),
  isFetchingDataModels: PropTypes.bool,
  dataModels: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
  dataModel: SelectOptionPropTypes,
  selectedMappingFormPDFPageDataset: PropTypes.shape({
    type: PropTypes.string,
    pageNumber: PropTypes.number.isRequired,
    id: PropTypes.string,
    datasetId: PropTypes.string,
    name: PropTypes.string,
    parentId: PropTypes.string,
    contextTypeId: PropTypes.number,
  }),
  pdfFieldsByPage: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  isFetchingPDFFieldsByPage: PropTypes.bool.isRequired,
  selectDataModel: PropTypes.func,
  showConfirmModal: PropTypes.func.isRequired,
  removePDFFieldMap: PropTypes.func.isRequired,
  assignPDFDataUsage: PropTypes.func,
  unassignPDFDataUsage: PropTypes.func,
  fetchMappingFormPDFPageDataUsageAndAttachmentConfigs: PropTypes.func.isRequired,
  fetchPDFFieldsByPage: PropTypes.func,
  datasetsByPage: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      datasetId: PropTypes.string.isRequired,
    }),
  ),
  mapPDFField: PropTypes.func,
  isUpdatingPDFDatasetAssignment: PropTypes.bool.isRequired,
  isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs: PropTypes.bool.isRequired,
  isFetchingDatasets: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    dataModel: dataModelSelector(state),
    isFetchingDataModels: isFetchingDataModelsSelector(state),
    dataModels: dataModelsSelector(state),
    dataset: datasetSelector(state),
    mappingFormPDFPageDataUsageAndAttachmentConfigId: mappingFormPDFPageDataUsageAndAttachmentConfigIdSelector(
      state,
    ),
    pdfFieldsByPage: pdfFieldsByPageSelector(state),
    isFetchingPDFFieldsByPage: isFetchingPDFFieldsByPageSelector(state),
    datasetsByPage: datasetsByPageSelector(state),
    selectedMappingFormPDFPageDataset: selectedMappingFormPDFPageDatasetSelector(state),
    isUpdatingPDFDatasetAssignment: isUpdatingPDFDatasetAssignmentSelector(state),
    isFetchingDatasets: isFetchingDatasetsSelector(state),
    isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigs: isFetchingMappingFormPDFPageDataUsageAndAttachmentConfigsSelector(
      state,
    ),
  }),
  {
    selectDataModel,
    assignPDFDataUsage,
    unassignPDFDataUsage,
    fetchMappingFormPDFPageDataUsageAndAttachmentConfigs,
    showConfirmModal,
    mapPDFField,
    removePDFFieldMap,
    fetchPDFFieldsByPage,
  },
)(PDFPageMapping);
