import React, { useCallback, useState } from 'react';
import { Button, Tooltip } from '@pwc/appkit-react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import Select from '../../../../../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { QueryKeys } from '../../../../../shared/queries';
import { globalContextSelector } from '../../../../../shared/store/selectors';
import {
  TransmitterProvider,
  TransmitterProviderLabels,
} from '../../../../../shared/enums/eFileV2';
import { useMutationUpdateSelectedTransmitter } from '../../../../../shared/mutations/efileV2';
import { GlobalContext, SltBinderId } from '../../../../../../../common/types';

import styles from './useTransmitter.module.scss';

interface UseTransmitterProps {
  isLoadingReturnInformation: boolean;
  eFileTransmitterProviderValue: TransmitterProvider | null;
  returnId?: SltBinderId;
  isDisabled: boolean;
}

const placeholder = 'Select transmitter';
const appkitLabel = 'Transmitter';
const TransmitterProviderOptions = [
  {
    label: TransmitterProviderLabels.TTI,
    value: TransmitterProvider.TTI,
  },
  {
    label: TransmitterProviderLabels.TR,
    value: TransmitterProvider.TR,
  },
];

const useTransmitter = ({
  isLoadingReturnInformation,
  eFileTransmitterProviderValue,
  returnId,
  isDisabled,
}: UseTransmitterProps) => {
  const globalContext: GlobalContext = useSelector(globalContextSelector);
  const queryClient = useQueryClient();

  const [
    selectedTransmitterProviderValue,
    setSelectedTransmitterProviderValue,
  ] = useState<TransmitterProvider | null>(null);

  const handleTransmitterChange = useCallback(
    ({ value }: { value: string | TransmitterProvider | null }) =>
      setSelectedTransmitterProviderValue(Number(value) || null),
    [setSelectedTransmitterProviderValue],
  );

  const {
    mutateAsync: updateSelectedTransmitter,
    isLoading: isUpdatingSelectedTransmitter,
  } = useMutationUpdateSelectedTransmitter();

  const isTransmitterLoading = isLoadingReturnInformation || isUpdatingSelectedTransmitter;

  const transmitterProviderOptionValue = returnId
    ? selectedTransmitterProviderValue || eFileTransmitterProviderValue
    : null;

  const handleSaveTransmitter = useCallback(async () => {
    if (returnId && transmitterProviderOptionValue) {
      await updateSelectedTransmitter({
        selectedTransmitter: transmitterProviderOptionValue,
        returnId,
      });

      queryClient.resetQueries([
        QueryKeys.EFileSupport.EFileBinderFilingsData,
        {
          sltBinderId: returnId,
          apiRouteParamString: globalContext.apiRouteParamString,
        },
      ]);
    }
  }, [
    transmitterProviderOptionValue,
    returnId,
    globalContext.apiRouteParamString,
    queryClient,
    updateSelectedTransmitter,
  ]);

  const eFileProcessingToolTip =
    'The eFile process is in progress for this return, please restart the return to change the transmitter.';
  const selectComponent = (
    <Select
      placeholder={placeholder}
      appkitLabel={appkitLabel}
      options={TransmitterProviderOptions}
      onChange={handleTransmitterChange}
      disabled={isTransmitterLoading || isDisabled}
      value={transmitterProviderOptionValue}
      virtualized
    />
  );

  const selectTransmitterComponent = (
    <div className={styles.transmitter}>
      <span>
        {isTransmitterLoading || isDisabled ? (
          <Tooltip content={eFileProcessingToolTip} placement="top">
            {selectComponent}
          </Tooltip>
        ) : (
          selectComponent
        )}
      </span>
      <span>
        <Button
          size="lg"
          className="add-button"
          disabled={
            isTransmitterLoading || eFileTransmitterProviderValue === transmitterProviderOptionValue
          }
          onClick={handleSaveTransmitter}
        >
          Save
        </Button>
      </span>
    </div>
  );

  return {
    selectTransmitterComponent,
    setSelectedTransmitterProviderValue,
  };
};

export default useTransmitter;
