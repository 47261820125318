import { createSelector } from 'reselect';

export const isFetchingFilingMembersSelector = ({ federalProFormaFilingGroups }) =>
  federalProFormaFilingGroups.filingMembers.isFetchingFilingMembers;

export const filingMembersSelector = createSelector(
  [({ federalProFormaFilingGroups }) => federalProFormaFilingGroups.filingMembers.filingMembers],
  filingGroups =>
    filingGroups.map((filingGroup, rowId) => ({
      ...filingGroup,
      rowId,
    })),
);

export const isUpdatingFilingMembersSelector = ({ federalProFormaFilingGroups }) =>
  federalProFormaFilingGroups.filingMembers.isUpdatingFilingMembers;
