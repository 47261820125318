import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import zip from 'lodash.zip';
import isEqual from 'lodash.isequal';
import { useFormikContext } from 'formik';

import AgGrid from '../../../../../shared/displayComponents/agGrid/agGrid.component';
import {
  pdfAttachmentColumnPropertiesSelector,
  isFetchingPDFAttachmentColumnPropertiesSelector,
  selectedDatasetPdfAttachmentConfigIdSelector,
} from '../../../../../shared/store/dataModels/selectors';
import { useEditModeLogic } from '../../../../../shared/editMode';
import { PDFAttachmentColumnPropertiesPropTypes } from '../propTypes';

import columnDefinitions from './pdfAttachmentColumnProperties.columnDefinitions';

const getUniqueRowId = ({ data: { id } }) => id;

const PDFAttachmentPropertiesGrid = ({ setIsFormDirty, selectedDataset }) => {
  const { values, setValues } = useFormikContext();

  const pdfAttachmentConfigId = useSelector(selectedDatasetPdfAttachmentConfigIdSelector);
  const pdfAttachmentColumnProperties = useSelector(pdfAttachmentColumnPropertiesSelector);
  const isFetchingPDFAttachmentColumnProperties = useSelector(
    isFetchingPDFAttachmentColumnPropertiesSelector,
  );

  const rowData = useMemo(
    () =>
      values?.pdfAttachmentColumnProperties?.[selectedDataset.id] || pdfAttachmentColumnProperties,
    [pdfAttachmentColumnProperties, selectedDataset.id, values?.pdfAttachmentColumnProperties],
  );

  const { onGridReady, clonedRowData, getAllRows } = useEditModeLogic({
    appendNewRows: false,
    rowData,
    getUniqueRowId,
    startInEditMode: true,
  });

  const onCellValueChanged = useCallback(
    ({ oldValue, newValue }) => {
      if (!oldValue && !newValue) {
        return;
      }

      const allRows = getAllRows();

      const isDataChanged = zip(pdfAttachmentColumnProperties, allRows).some(
        ([row, originalRow]) => !isEqual(row, originalRow),
      );
      if (isDataChanged) {
        setIsFormDirty(isDataChanged);
      }

      setValues(previousValues => ({
        ...previousValues,
        pdfAttachmentColumnProperties: {
          ...previousValues?.pdfAttachmentColumnProperties,
          [selectedDataset.id]: allRows.map(row => ({ ...row, pdfAttachmentConfigId })),
        },
      }));
    },
    [
      setIsFormDirty,
      setValues,
      getAllRows,
      pdfAttachmentColumnProperties,
      selectedDataset.id,
      pdfAttachmentConfigId,
    ],
  );

  return (
    <AgGrid
      rowData={clonedRowData}
      columnDefs={columnDefinitions}
      onGridReady={onGridReady}
      isGridLoading={isFetchingPDFAttachmentColumnProperties}
      onCellValueChanged={onCellValueChanged}
      stopEditingWhenCellsLoseFocus
      singleClickEdit
      suppressScrollOnNewData
      areHeaderCellBordersEnabled
      suppressCellFocus={false}
    />
  );
};

PDFAttachmentPropertiesGrid.propTypes = {
  setIsFormDirty: PropTypes.func.isRequired,
  selectedDataset: PDFAttachmentColumnPropertiesPropTypes,
};

export default PDFAttachmentPropertiesGrid;
