import React from 'react';

import EditReport from '../../shared/editReport.component';
import { REPORT_TYPES, uiLabels } from '../../shared/constants';

import getColumnDefinitions from './reports.columnDefinitions';

const EditTrgReports = () => (
  <EditReport
    reportType={REPORT_TYPES.TRG}
    getColumnDefinitions={getColumnDefinitions}
    uiLabels={uiLabels.trg}
  />
);

export default EditTrgReports;
