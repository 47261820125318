import React, { useEffect, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { accountSchemas } from '@common-packages/validators';

import Loading from '../../shared/displayComponents/loading.component';

import EditAccountsForm from './editAccountsForm.component';
import { accountPropTypes } from './accountsPropTypes';
import {
  accountGroupsSelector,
  screenGroupsSelector,
  functionsSelector,
  consFunctionsSelector,
} from './store/selectors';
import {
  fetchAccounts,
  fetchAccountGroups,
  fetchScreenGroups,
  fetchFunctions,
  fetchConsFunctions,
  createAccount,
  updateAccount,
} from './store/actions';

const newAccount = {
  accountId: '',
  accountDescription: '',
  accountGroup: '',
  activeFlag: '',
  altDescription: '',
  caseYn: false,
  consFunctionName: '',
  consPbFunctionName: '',
  functionName: '',
  screenGroup: '',
};

const yesNoToBoolean = value =>
  value ? value.toUpperCase() === 'Y' || value.toUpperCase() === 'YES' : value;

const EditAccounts = forwardRef(({ accountToEdit, taxYear, clearForm }, ref) => {
  const dispatch = useDispatch();

  const accountGroups = useSelector(accountGroupsSelector);
  const screenGroups = useSelector(screenGroupsSelector);
  const functions = useSelector(functionsSelector);
  const consFunctions = useSelector(consFunctionsSelector);

  const isEditMode = Boolean(accountToEdit);

  useEffect(() => {
    dispatch(fetchAccountGroups());
    dispatch(fetchScreenGroups());
    dispatch(fetchFunctions({ taxYear }));
    dispatch(fetchConsFunctions({ taxYear }));
  }, [dispatch, taxYear]);

  const saveAccount = useCallback(
    async ({
      accountId,
      accountDescription,
      accountGroup,
      activeFlag,
      altDescription,
      caseYn,
      consFunctionName,
      consPbFunctionName,
      functionName,
      screenGroup,
    }) => {
      const account = {
        accountId,
        accountDescription,
        accountGroup,
        activeFlag,
        altDescription,
        caseYn,
        consFunctionName,
        consPbFunctionName,
        functionName,
        screenGroup,
        taxYear,
      };

      if (!isEditMode) {
        await dispatch(createAccount(account));
      } else {
        await dispatch(updateAccount(account));
      }

      clearForm();
      dispatch(fetchAccounts({ taxYear }));
    },
    [dispatch, clearForm, isEditMode, taxYear],
  );

  const renderForm = useCallback(
    formikProps => (
      <Loading isLoading={!accountGroups || !screenGroups || !functions || !consFunctions}>
        <EditAccountsForm
          {...formikProps}
          isEditMode={isEditMode}
          onCancelClick={formikProps.resetForm}
          accountGroupOptions={accountGroups}
          screenGroupOptions={screenGroups}
          functionNameOptions={functions}
          consFunctionNameOptions={consFunctions}
          consPbFunctionNameOptions={consFunctions}
        />
      </Loading>
    ),
    [isEditMode, accountGroups, screenGroups, functions, consFunctions],
  );

  return (
    <Formik
      initialValues={
        accountToEdit
          ? { ...accountToEdit, caseYn: yesNoToBoolean(accountToEdit.caseYn) }
          : newAccount
      }
      validationSchema={accountSchemas.createOrUpdateAccountSchema}
      enableReinitialize
      onSubmit={saveAccount}
      innerRef={ref}
    >
      {renderForm}
    </Formik>
  );
});

EditAccounts.displayName = 'EditAccounts';

EditAccounts.propTypes = {
  taxYear: PropTypes.string.isRequired,
  accountToEdit: PropTypes.shape(accountPropTypes),
  clearForm: PropTypes.func.isRequired,
};

export default EditAccounts;
