import { filingTypes } from '../../shared/constants';

import { getEntityWorkspaceNavigator } from './entityWorkspaceNavigator.data';
import { getConsolidationWorkspaceNavigator } from './consolidationWorkspaceNavigator.data';

const navigatorDataFactory = ({
  filingTypeId,
  applyPermissions,

  ...props
}) => {
  const tree =
    filingTypeId === filingTypes.SEPARATE
      ? getEntityWorkspaceNavigator({ filingTypeId, ...props })
      : getConsolidationWorkspaceNavigator({ filingTypeId, ...props });

  return applyPermissions(tree);
};

export default navigatorDataFactory;
