const FormDataActions = {
  setValue: 'SetValue',
  setOverride: 'SetOverride',
  clearOverride: 'ClearOverride',
};

const OverflowAction = {
  DATA_ERROR: '0',
  ATTACHMENT: '1',
  REPEAT_CURRENT_PAGE: '2',
  REPEAT_FORM: '3',
  IGNORE_REMAINING: '4',
};

module.exports = { FormDataActions, OverflowAction };
