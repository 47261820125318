import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useQueryFindCheckboxGroups } from '../shared/queries/checkboxGroups';
import useFetch from '../shared/hooks/useFetch.hook';
import AgGrid from '../shared/displayComponents/agGrid/agGrid.component';
import SDKCustomSelect from '../shared/forms/sdkCustomSelect/sdkCustomSelect.component';
import { useRowEditMode } from '../shared/editMode';
import {
  DateCellEditor,
  NumberCellEditor,
  TextCellEditor,
} from '../shared/columnDefinitions/cellRenderers';
import globalContextPropTypes from '../shared/propTypes/globalContext';
import { autoGroupColumnDef } from '../shared/columnDefinitions/booleanCheckboxBasedAgGrid.autoGroupColumnDefinition';
import { gridGroupOptions } from '../shared/displayComponents/agGrid/constants';
import useUrlParams from '../sharedSubPages/returnWorkspace/hooks/useUrlParams.hook';

import * as api from './api';
import getColumnDefinitions from './stateSpecificInformation.columnDefinitions';
import styles from './stateSpecificInformation.module.scss';

const getUniqueRowId = ({ data: { rowId } }) => rowId;

const StateSpecificInformation = ({ globalContext, hasUserPermissionsToEdit }) => {
  const { queryParams: { formId } = null } = useUrlParams();
  const [selectedFormId, selectForm] = useState(null);

  const { taxYear, period, businessEntityId, jurisdictionId } = globalContext.params;

  const {
    data: checkboxGroupsResponse,
    isFetching: isFetchingCheckboxGroups,
  } = useQueryFindCheckboxGroups({
    params: { taxYear, jurisdictionId, shouldIncludeEverywhereJurisdiction: true },
    enabled: Boolean(taxYear && jurisdictionId),
  });

  const {
    data: formFieldsResponse,
    isFetching: isFetchingFormFields,
    fetch: fetchFormFields,
  } = useFetch({ action: api.fetchFormFields });
  const { isFetching: isUpdatingFormFields, fetch: updateFormFields } = useFetch({
    action: api.updateFormFields,
    successNotificationMessage: 'State Specific Information saved successfully',
  });

  const { data: formListOptions, isFetching: isFetchingFormList, fetch: fetchForms } = useFetch({
    action: api.fetchForms,
  });

  const formFields = useMemo(
    () => (formFieldsResponse || []).map((field, rowId) => ({ ...field, rowId })),
    [formFieldsResponse],
  );

  useEffect(() => {
    if (formListOptions?.length) {
      selectForm(formId || formListOptions[0].value);
    }
  }, [formId, formListOptions]);

  useEffect(() => {
    if (globalContext.isReady) {
      fetchForms(globalContext.params);
    }
  }, [fetchForms, globalContext.isReady, globalContext.params]);

  useEffect(() => {
    if (period && businessEntityId && selectedFormId) {
      fetchFormFields({ period, businessEntityId, formId: selectedFormId });
    }
  }, [fetchFormFields, period, businessEntityId, selectedFormId]);

  const saveChanges = useCallback(
    async ({ rowsPairsWithChanges }) => {
      if (!rowsPairsWithChanges.length) {
        return;
      }

      const rows = rowsPairsWithChanges.map(({ newRow }) => newRow);
      await updateFormFields({ taxYear, period, businessEntityId, jurisdictionId, rows });
      fetchFormFields({
        period,
        businessEntityId,
        formId: selectedFormId,
      });
    },
    [
      updateFormFields,
      fetchFormFields,
      selectedFormId,
      taxYear,
      period,
      jurisdictionId,
      businessEntityId,
    ],
  );

  const isLoading =
    isFetchingCheckboxGroups || isFetchingFormList || isFetchingFormFields || isUpdatingFormFields;

  const {
    navigationPrompt,
    isInEditMode,
    editModeButtons,
    updateGroup,
    updateRow,
    clonedRowData,
    onGridReady,
  } = useRowEditMode({
    onSave: saveChanges,
    rowData: formFields,
    getUniqueRowId,
    editButtonDisabled: isLoading || !formFields?.length,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        rowData: clonedRowData,
        checkboxGroups: checkboxGroupsResponse.checkboxGroups,
        isInEditMode,
        updateGroup,
        updateRow,
        taxYear,
      }),
    [
      isInEditMode,
      updateGroup,
      updateRow,
      checkboxGroupsResponse.checkboxGroups,
      taxYear,
      clonedRowData,
    ],
  );

  return (
    <>
      {navigationPrompt}
      <div className={styles.flexSpaceBetween}>
        <div className={styles.formListDropdown}>
          <SDKCustomSelect
            className="sdk-custom-select"
            options={formListOptions}
            onChange={useCallback(({ value }) => selectForm(value), [])}
            value={selectedFormId}
            isLoading={isFetchingFormList}
            disabled={isInEditMode}
            virtualized
          />
        </div>
        <div>{hasUserPermissionsToEdit && editModeButtons}</div>
      </div>
      <div className="row grid-row">
        <div className="col">
          <AgGrid
            rowData={clonedRowData}
            columnDefs={columnDefinitions}
            autoGroupColumnDef={autoGroupColumnDef}
            isGridLoading={isLoading}
            singleClickEdit
            suppressCellFocus={!isInEditMode}
            onGridReady={onGridReady}
            components={{
              DateCellEditor,
              NumberCellEditor,
              TextCellEditor,
            }}
            withSearchBar
            stopEditingWhenCellsLoseFocus
            {...gridGroupOptions}
          />
        </div>
      </div>
    </>
  );
};

StateSpecificInformation.propTypes = {
  globalContext: globalContextPropTypes,
  hasUserPermissionsToEdit: PropTypes.bool.isRequired,
};

export default StateSpecificInformation;
