import { UpdateWatermarkBody } from '@tls/slt-types';

import { HttpMethods } from '../enums';
import { QueryError, QueryKeys } from '../queries';

import { useCustomMutation } from '.';

interface InsertWatermarkMutationVariable {
  name: string;
  fontSize: number;
  rotation: number;
  verticalOffset: number;
  line1: string;
  line2?: string;
  line3?: string;
}

interface UpdateWatermarkMutationVariable {
  watermarkId: string;
  name: string;
  fontSize: number;
  rotation: number;
  verticalOffset: number;
  line1: string;
  line2?: string;
  line3?: string;
}

interface DeleteWatermarkMutationVariable {
  watermarkId: string;
}

export const useMutationInsertWatermark = () =>
  useCustomMutation<null, QueryError, InsertWatermarkMutationVariable>({
    handleData: ({ name, fontSize, rotation, verticalOffset, line1, line2, line3 }) => ({
      url: '/api/development/watermarks',
      method: HttpMethods.POST,
      body: { name, fontSize, rotation, verticalOffset, line1, line2, line3 },
    }),
    resetKey: QueryKeys.Watermarks.Watermarks,
    successMessage: 'Watermark added successfully',
    errorMessage: 'An error occurred while adding a watermark',
  });

export const useMutationUpdateWatermark = () =>
  useCustomMutation<null, QueryError, UpdateWatermarkMutationVariable, UpdateWatermarkBody>({
    handleData: ({
      watermarkId,
      name,
      fontSize,
      rotation,
      verticalOffset,
      line1,
      line2,
      line3,
    }) => ({
      url: `/api/development/watermarks/${watermarkId}`,
      method: HttpMethods.PUT,
      body: { name, fontSize, rotation, verticalOffset, line1, line2, line3 },
    }),
    resetKey: QueryKeys.Watermarks.Watermarks,
    successMessage: 'Watermark updated successfully',
    errorMessage: 'An error occurred while updating a watermark',
  });

export const useMutationDeleteWatermark = () =>
  useCustomMutation<null, QueryError, DeleteWatermarkMutationVariable>({
    handleData: ({ watermarkId }) => ({
      url: `/api/development/watermarks/${watermarkId}`,
      method: HttpMethods.DELETE,
    }),
    resetKey: QueryKeys.Watermarks.Watermarks,
    successMessage: 'Watermark deleted successfully',
    errorMessage: 'An error occurred while deleting a watermark',
  });
