import { httpGetAndParse, httpPut } from '../../utils/fetchService';

export const fetchFilingGroups = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/state-filing-groups/${taxYear}/${period}`,
    errorMessage: 'Fetching state filing groups failed',
  });

export const fetchFilingGroupsByFilingType = ({
  taxYear,
  period,
  filingTypeId,
  businessEntityId,
}) =>
  httpGetAndParse({
    route: `/api/state-filing-groups/${taxYear}/${period}/${filingTypeId}/${businessEntityId}`,
    errorMessage: 'Fetching state filing groups by filing type failed',
  });

export const fetchAllJurisdictions = ({ taxYear }) =>
  httpGetAndParse({
    route: `/api/state-filing-groups/${taxYear}`,
    errorMessage: 'Fetching all jurisdictions failed',
  });

export const updateFilingGroup = ({
  taxYear,
  period,
  jurisdictionId,
  filingGroupId,
  description,
  returnTask,
  separateLimit,
  efile,
}) =>
  httpPut({
    route: `/api/state-filing-groups/${taxYear}/${period}/${jurisdictionId}/${filingGroupId}`,
    errorMessage: 'Updating state filing group failed',
    body: {
      description,
      returnTask,
      separateLimit,
      efile,
    },
  });

export const createFilingGroup = ({
  taxYear,
  period,
  jurisdictionId,
  filingGroupId,
  description,
  returnTask,
  separateLimit,
  efile,
}) =>
  httpPut({
    route: `/api/state-filing-groups/${taxYear}/${period}/${jurisdictionId}/${filingGroupId}`,
    errorMessage: 'Creating state filing group failed',
    body: {
      description,
      returnTask,
      separateLimit,
      efile,
    },
  });
