import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { taxTypes } from '@common-packages/routes-definitions';

import AgGrid from '../../shared/displayComponents/agGrid/agGrid.component';
import { appendEmptyRowsAfterEachSection } from '../../sharedSubPages/returnWorkspace/utils';
import globalContextPropTypes from '../../shared/propTypes/globalContext';

import useFetchPropertySummary from './useFetchPropertySummary.hook';
import getColumnDefinitions from './propertySummary.columnDefinitions';

const PropertySummary = ({ globalContext, taxName }) => {
  const { taxYear, period, filingTypeId, businessEntityId, jurisdictionId } = globalContext.params;
  const { propertySummary, isFetchingPropertySummary } = useFetchPropertySummary({
    taxYear,
    period,
    entityId: businessEntityId,
    jurisdictionId,
    taxType: taxTypes[taxName],
    isContextReady: true,
  });

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions({
        taxYear,
        period,
        filingTypeId,
        businessEntityId,
        jurisdictionId,
        taxName,
      }),
    [taxYear, period, filingTypeId, businessEntityId, jurisdictionId, taxName],
  );
  const rowData = useMemo(
    () => appendEmptyRowsAfterEachSection(propertySummary, 'isLastRowInSection'),
    [propertySummary],
  );

  return (
    <div className="grid-borderless row grid-row">
      <div className="col">
        <AgGrid
          isGridLoading={isFetchingPropertySummary}
          columnDefs={columnDefinitions}
          rowData={rowData}
        />
      </div>
    </div>
  );
};

PropertySummary.propTypes = {
  globalContext: globalContextPropTypes,
  taxName: PropTypes.string,
};

export default PropertySummary;
