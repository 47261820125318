import PropTypes from 'prop-types';

export const genericCategoryPropTypes = PropTypes.shape({
  accountDescription: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  accountJurisdictionId: PropTypes.string.isRequired,
  adjustments: PropTypes.number.isRequired,
  begEndFlag: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  currentYearValue: PropTypes.number.isRequired,
  dataCollection: PropTypes.number.isRequired,
  displayOrder: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  priorYearValue: PropTypes.number.isRequired,
  subCategory: PropTypes.string.isRequired,
});
