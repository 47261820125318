import { httpGetAndParse, httpPut } from '../../utils/fetchService';

export const fetchTasks = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/tasks/${taxYear}/${period}`,
    errorMessage: 'Fetching tasks failed',
  });

export const fetchSourceCustomers = ({ taxYear, period }) =>
  httpGetAndParse({
    route: `/api/tasks/source-customers/${taxYear}/${period}`,
    errorMessage: 'Fetching source customers failed',
  });

export const fetchDestinationCustomers = () =>
  httpGetAndParse({
    route: `/api/tasks/destination-customers`,
    errorMessage: 'Fetching destination customers failed',
  });

export const fetchTasksForReassignment = ({ taxYear, period, customerId }) =>
  httpGetAndParse({
    route: `/api/tasks/for-reassignment/${taxYear}/${period}/${customerId}`,
    errorMessage: 'Fetching tasks for reassignment failed',
  });

export const updateTasksAssignments = ({ customerId, tasks }) =>
  httpPut({
    route: `/api/tasks/assignments`,
    errorMessage: 'Updating tasks assignments failed',
    body: {
      customerId,
      tasks,
    },
  });
