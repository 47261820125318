import PropTypes from 'prop-types';

const entityPropTypes = PropTypes.shape({
  orgId: PropTypes.string,
  legalEntityId: PropTypes.string,
  orgName: PropTypes.string,
  orgType: PropTypes.string,
  businessId: PropTypes.string,
  domicileJurisdictionId: PropTypes.string,
  dateAcquired: PropTypes.string,
  dateIncorporated: PropTypes.string,
  fein: PropTypes.string,
  federalFilingType: PropTypes.string,
  countryIncorporated: PropTypes.string,
  orgRuleId: PropTypes.string,
});

export default entityPropTypes;
