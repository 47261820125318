import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { clientIdSelector } from '../store/context';
import stringify from '../../utils/stringify';

const useAuthQueryString = () => {
  const clientId = useSelector(clientIdSelector);

  const authQueryString = useMemo(
    () =>
      stringify({
        clientId,
      }),
    [clientId],
  );

  return authQueryString;
};

export default useAuthQueryString;
