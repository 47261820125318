import React from 'react';
import PropTypes from 'prop-types';

import { getLastUpdateDate } from '../../utils';

const ActionUpdatedTime = ({ action }) =>
  action ? (
    <time dateTime={action.updatedOn || action.createdOn}>
      Last update: {getLastUpdateDate(action)}
    </time>
  ) : (
    'Last update: N/A'
  );

ActionUpdatedTime.propTypes = {
  action: PropTypes.shape({
    createdOn: PropTypes.string,
    updatedOn: PropTypes.string,
  }),
};

export default ActionUpdatedTime;
