import {
  FindReturnStatusTrackerDetailsParams,
  FindReturnStatusTrackerDetailsResponse,
} from '../../../../common/types';

import { QueryKeys, useCustomQuery } from './index';

export const useQueryReturnStatusTrackerDetails = ({
  params,
  enabled,
}: {
  params: FindReturnStatusTrackerDetailsParams;
  enabled: boolean;
}) =>
  useCustomQuery<FindReturnStatusTrackerDetailsResponse>(
    [QueryKeys.ReturnStatusTackerDetails.Data, params],
    {
      url: `/api/return-status-tracker/${params.taxYear}/${params.period}`,
      defaultData: { gridRows: [], visualData: [] },
      enabled,
      errorMessage: 'Error fetching return status tracker details',
    },
  );
