import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import Modal from '@tls/ui-modal';

import { SelectOptionPropTypes } from '../../shared/propTypes/selectOption';

import { addFilingGroupToConsolidation, fetchFilingGroups } from './store/actions';
import AddFilingGroupForm from './addFilingGroupForm.component';
import { fetchDuplicateEntities } from './api';
import styles from './addFilingGroupForm.module.scss';

const AddFilingGroupFormContainer = ({
  taxYear,
  period,
  filingGroupsOptions,
  consolidationId,
  jurisdictionId,

  hideModal,
  visible,
}) => {
  const dispatch = useDispatch();

  const validate = useCallback(
    async ({ selectedFilingGroup }) => {
      const errors = {};

      try {
        const duplicateEntities = await fetchDuplicateEntities({
          taxYear,
          period,
          consolidationId,
          selectedFilingGroup,
        });

        if (duplicateEntities.length > 0) {
          errors.globalError = `The selected group cannot be added as it includes the following entities that are already in the consolidation: ${duplicateEntities
            .map(({ entityId }) => entityId)
            .join(', ')}`;
        }
      } catch (error) {
        errors.globalError =
          'An error occurred when validating filing group selection, please try again later.';
      }

      return errors;
    },
    [taxYear, period, consolidationId],
  );

  const onAddGroupClick = useCallback(
    async ({ selectedFilingGroup }, { resetForm }) => {
      await dispatch(
        addFilingGroupToConsolidation({
          filingGroupId: selectedFilingGroup,
          taxYear,
          period,
          consolidationId,
        }),
      );

      hideModal();
      resetForm();
      await dispatch(
        fetchFilingGroups({
          taxYear,
          period,
          consolidationId,
          jurisdictionId,
        }),
      );
    },
    [dispatch, hideModal, taxYear, period, consolidationId, jurisdictionId],
  );

  const renderForm = useCallback(
    formikProps => (
      <Modal
        title="Add filing group to consolidation"
        className={styles.consolidationFilingGroupModal}
        closeAction={hideModal}
        visible={visible}
        submitAction={formikProps.submitForm}
        dismissAction={hideModal}
      >
        <AddFilingGroupForm
          {...formikProps}
          hideModal={hideModal}
          filingGroupOptions={filingGroupsOptions}
        />
      </Modal>
    ),
    [hideModal, filingGroupsOptions, visible],
  );

  return (
    <>
      <Formik
        initialValues={{ selectedFilingGroup: null }}
        validate={validate}
        onSubmit={onAddGroupClick}
      >
        {renderForm}
      </Formik>
    </>
  );
};

AddFilingGroupFormContainer.propTypes = {
  taxYear: PropTypes.string,
  period: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  consolidationId: PropTypes.string,
  jurisdictionId: PropTypes.string,
  filingGroupsOptions: PropTypes.arrayOf(SelectOptionPropTypes).isRequired,
};

export default AddFilingGroupFormContainer;
