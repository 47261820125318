export const TaxFormActionTypes = {
  RESET: 'RESET',
  SOFT_RESET: 'SOFT_RESET',
  SET_IS_SAVING: 'SET_IS_SAVING',
  SET_IS_FORM_DIRTY: 'SET_IS_FORM_DIRTY',
  SELECT_FORM_FIELD_ID: 'SELECT_FORM_FIELD_ID',
  SELECT_FORM_FIELD_NAME: 'SELECT_FORM_FIELD_NAME',
  ASSIGN_DATASETS: 'ASSIGN_DATASETS',
  UNASSIGN_DATASETS: 'UNASSIGN_DATASETS',
  SELECT_DATA_MODEL_ID: 'SELECT_DATA_MODEL_ID',
  SELECT_FORM_DETAIL_TAB: 'SELECT_FORM_DETAIL_TAB',
  SELECT_AVAILABLE_DATASETS: 'SELECT_AVAILABLE_DATASETS',
  SELECT_ASSIGNED_DATASETS: 'SELECT_ASSIGNED_DATASETS',
};

export const FIELDS_TO_UPDATE = 'fieldsToUpdate';
export const FIELDS_TO_UNMAP = 'fieldsToUnmap';

export const FormDetailsTabsTypes = {
  FORM: 'FORM',
  DATA_SET: 'DATA_SET',
  OVERFLOW_ACTIONS: 'OVERFLOW_ACTIONS',
  FIELDS: 'FIELDS',
  E_FILE: 'E_FILE',
  TAB_2D: 'TAB_2D',
};

export const FORM_DETAILS_TABS_DEFINITION = [
  { type: FormDetailsTabsTypes.FORM, label: 'Form' },
  { type: FormDetailsTabsTypes.DATA_SET, label: 'Dataset' },
  { type: FormDetailsTabsTypes.OVERFLOW_ACTIONS, label: 'Overflow Actions' },
  { type: FormDetailsTabsTypes.FIELDS, label: 'Fields' },
  { type: FormDetailsTabsTypes.E_FILE, label: 'E-File' },
  { type: FormDetailsTabsTypes.TAB_2D, label: '2D' },
];

export const pdfTypes = {
  PDF_LIB: 'PDFLib',
  BNA: 'BNA',
};

export const profileIds = {
  PSHP: 'PSHP',
  DEFAULT: 'DEFAULT',
};

export const PdfPageDataUsageTypes = {
  DATASET_USAGE: 'dataset_usage',
  PAGE: 'page',
};

export const WARNING_MODAL_REFRESH_TITLE = 'Unsaved changes';

export const WARNING_MODAL_REFRESH_MESSAGE =
  'You have unsaved changes. Please save or cancel them before refreshing data.';
