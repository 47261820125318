import React from 'react';
import Modal from '@tls/ui-modal';

interface OutdatedPreparerModalProps {
  hideModal: () => void;
  visible: boolean;
}

const OutdatedPreparerModal = ({ hideModal, visible }: OutdatedPreparerModalProps) => (
  <Modal title="Warning - Preparer Info Outdated" visible={visible} closeAction={hideModal}>
    <p>
      {`The preparer name and preparer EIN for PwC changed as of July 1st, 2023.  You are using the old value(s).  Confirm the information has been updated using the menu Compliance > Entities > Informational Data page.  Confirm the information has been updated on the current forms. Confirm the calculation of the return is up-to-date.`}
    </p>
  </Modal>
);

export default OutdatedPreparerModal;
